var consolo;
(function (consolo) {
    'use strict';
    angular
        .module('consolo', [
        'checklist-model',
        'ngAnimate',
        'ngMaterial',
        'ngMessages',
        'ngResource',
        'ngSanitize',
        'toastr',
        'ui.router',
        'yaru22.angular-timeago',
        'angular.filter',
        'ui.scroll',
        'debounce',
        'dndLists',
        'ngQuill',
        'csg',
        'ngFileUpload',
        'ngCookies',
        'mdSteppers',
        'duScroll',
    ])
        .constant('ROLESYMBOL', 'hospice_aide')
        .config([
        '$compileProvider',
        '$locationProvider',
        '$provide',
        'csgSettingsProvider',
        '$mdInkRippleProvider',
        '$mdThemingProvider',
        '$httpProvider',
        'timeAgoSettings',
        '$mdIconProvider',
        function ($compileProvider, $locationProvider, $provide, csgSettings, $mdInkRipple, $mdThemingProvider, $httpProvider, timeAgoSettings, $mdIconProvider) {
            $compileProvider.debugInfoEnabled(false);
            $locationProvider.html5Mode(true);
            csgSettings.imagePath = '/y/assets/images/';
            try {
                var settings = JSON.parse(localStorage.getItem('settings_global'));
                var animations = true;
                if (settings) {
                    animations = settings.enable_animations;
                    if (!animations && animations !== null && animations !== undefined) {
                        $mdInkRipple.disableInkRipple();
                    }
                }
            }
            catch (e) {
                console.info('Unable to disable mdInkRipple', e);
            }
            $provide.decorator('$state', [
                '$delegate',
                '$rootScope',
                'NetworkManager',
                function ($delegate, $rootScope, NetworkManager) {
                    $rootScope.$on('$stateChangeStart', function (event, state, params) {
                        $delegate.next = state;
                        $delegate.toParams = params;
                        var online = NetworkManager.online;
                        // route all old emr-web patient dashboard pages to nuEMR patient dashboard page.
                        if (state.name === "patients.dashboard" && online) {
                            event.preventDefault();
                            var subdomain = location.hostname.split('.')[0];
                            var host = "";
                            if (!subdomain) {
                                host = location.host;
                            }
                            var hostArray = location.host.split('.');
                            hostArray.splice(0, 1, subdomain);
                            host = hostArray.join('.');
                            if (location.port) {
                                host += ':' + location.port;
                            }
                            var patientId = params.patientId;
                            var landingUrl = location.protocol + "//" + host + "/emr/" + subdomain + "/patients/" + patientId + "/dashboard";
                            window.location.href = landingUrl;
                        }
                    });
                    return $delegate;
                },
            ]);
            $httpProvider.interceptors.push('apiErrorHandlerInjector');
            $httpProvider.interceptors.push('authTokenInjector');
            $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            timeAgoSettings.allowFuture = true;
            $mdIconProvider
                .iconSet('action', '/y/assets/images/svg-icons/svg-sprite-action.svg', 24)
                .iconSet('alert', '/y/assets/images/svg-icons/svg-sprite-alert.svg', 24)
                .iconSet('av', '/y/assets/images/svg-icons/svg-sprite-av.svg', 24)
                .iconSet('communication', '/y/assets/images/svg-icons/svg-sprite-communication.svg', 24)
                .iconSet('content', '/y/assets/images/svg-icons/svg-sprite-content.svg', 24)
                .iconSet('device', '/y/assets/images/svg-icons/svg-sprite-device.svg', 24)
                .iconSet('editor', '/y/assets/images/svg-icons/svg-sprite-editor.svg', 24)
                .iconSet('file', '/y/assets/images/svg-icons/svg-sprite-file.svg', 24)
                .iconSet('hardware', '/y/assets/images/svg-icons/svg-sprite-hardware.svg', 24)
                .iconSet('image', '/y/assets/images/svg-icons/svg-sprite-image.svg', 24)
                .iconSet('maps', '/y/assets/images/svg-icons/svg-sprite-maps.svg', 24)
                .iconSet('navigation', '/y/assets/images/svg-icons/svg-sprite-navigation.svg', 24)
                .iconSet('notification', '/y/assets/images/svg-icons/svg-sprite-notification.svg', 24)
                .iconSet('places', '/y/assets/images/svg-icons/svg-sprite-places.svg', 24)
                .iconSet('social', '/y/assets/images/svg-icons/svg-sprite-social.svg', 24)
                .iconSet('toggle', '/y/assets/images/svg-icons/svg-sprite-toggle.svg', 24)
                .iconSet('misc', '/y/assets/images/svg-icons/svg-sprite-misc.svg', 24);
            var styleCSS = '%%GULP_INJECT_CUSTOM_THEME_STYLES%%';
            if (styleCSS.indexOf('GULP_INJECT_CUSTOM_THEME_STYLES') < 0) {
                $mdThemingProvider.registerStyles(styleCSS);
            }
            $mdThemingProvider.definePalette('blue-3', {
                '50': 'edf3f9',
                '100': 'd3e2f0',
                '200': 'b6cfe7',
                '300': '98bcdd',
                '400': '82add5',
                '500': '6c9fce',
                '600': '6497c9',
                '700': '598dc2',
                '800': '4f83bc',
                '900': '3d72b0',
                A100: 'ffffff',
                A200: 'cde3ff',
                A400: '9ac7ff',
                A700: '80b9ff',
                contrastDefaultColor: 'light',
                contrastDarkColors: [
                    '50',
                    '100',
                    '200',
                    '300',
                    '400',
                    '500',
                    '600',
                    '700',
                    '800',
                    'A100',
                    'A200',
                    'A400',
                    'A700',
                ],
                contrastLightColors: ['900'],
            });
            $mdThemingProvider.definePalette('ws-big-stone', {
                '50': '#e4e7e9',
                '100': '#bbc3c8',
                '200': '#8d9ba3',
                '300': '#e4f0f1',
                '400': '#3d5463',
                '500': '#1b3647',
                '600': '#183040',
                '700': '#142937',
                '800': '#10222f',
                '900': '#081620',
                A100: '#5eb3ff',
                A200: '#2b9bff',
                A400: '#0083f7',
                A700: '#0075de',
                contrastDefaultColor: 'light',
                contrastDarkColors: ['50', '100', 'A100'],
            });
            $mdThemingProvider.definePalette('ws-elm', {
                '50': '#e4f0f1',
                '100': '#bdd9dc',
                '200': '#91c0c4',
                '300': '#64a7ac',
                '400': '#43949b',
                '500': '#228189',
                '600': '#1e7981',
                '700': '#196e76',
                '800': '#14646c',
                '900': '#0c5159',
                A100: '#8ef2ff',
                A200: '#5becff',
                A400: '#28e6ff',
                A700: '#0ee3ff',
                contrastDefaultColor: 'light',
                contrastDarkColors: ['50', '100', '200', 'A100', 'A200', 'A400', 'A700'],
            });
            $mdThemingProvider.definePalette('ws-tuscany', {
                '50': '#f7e9e5',
                '100': '#ecc9be',
                '200': '#dfa593',
                '300': '#d28168',
                '400': '#c96647',
                '500': '#bf4b27',
                '600': '#b94423',
                '700': '#b13b1d',
                '800': '#a93317',
                '900': '#9b230e',
                A100: '#ffd1ca',
                A200: '#ffa497',
                A400: '#ff7764',
                A700: '#ff614b',
                contrastDefaultColor: 'light',
                contrastDarkColors: ['50', '100', 'A100', 'A200'],
            });
            // Neutral pallette not fully defined as of 6/17/2021 so can't use yet (dummy values for 200-800 and AX00)
            $mdThemingProvider.definePalette('ws-neutral', {
                '50': '#ffffff',
                '100': '#f7f7f7',
                '200': '#eceff1',
                '300': '#000000',
                '400': '#000000',
                '500': '#000000',
                '600': '#000000',
                '700': '#000000',
                '800': '#000000',
                '900': '#000000',
                A100: '#000000',
                A200: '#000000',
                A400: '#000000',
                A700: '#000000',
                contrastDefaultColor: 'light',
                contrastDarkColors: ['50, 100'],
            });
            $mdThemingProvider.generateThemesOnDemand(true);
            $mdThemingProvider.definePalette('light-grey', {
                '50': 'fdfdfd',
                '100': 'f9f9f9',
                '200': 'f6f6f6',
                '300': 'f2f2f2',
                '400': 'efefef',
                '500': 'ececec',
                '600': 'b5b5b5',
                '700': '828282',
                '800': '777777',
                '900': '595959',
                A100: 'ffffff',
                A200: '3b3b3b',
                A400: '2e2e2e',
                A700: '252525',
                contrastDefaultColor: 'light',
                contrastDarkColors: [
                    '50',
                    '100',
                    '200',
                    '300',
                    '400',
                    '500',
                    '600',
                    '700',
                    '800',
                    '900',
                    'A100',
                    'A200',
                ],
                contrastLightColors: ['A200', 'A400', 'A700'],
            });
            var defaultTheme = $mdThemingProvider
                .theme('default')
                .primaryPalette('ws-big-stone', {
                default: '500',
                'hue-1': '400',
                'hue-2': '500',
                'hue-3': '700',
            })
                .accentPalette('ws-elm', {
                default: '500',
                'hue-1': '400',
                'hue-2': '500',
                'hue-3': '700',
            })
                .warnPalette('ws-tuscany', {
                default: '500',
            })
                .backgroundPalette('light-grey', {
                default: '100',
            });
            $mdThemingProvider
                .theme('palliative')
                .primaryPalette('ws-big-stone', {
                default: '500',
                'hue-1': '300',
                'hue-2': '500',
                'hue-3': '700',
            })
                .accentPalette('ws-elm', {
                default: '500',
                'hue-1': '300',
                'hue-2': '500',
                'hue-3': '700',
            })
                .warnPalette('ws-tuscany', {
                default: '500',
            });
            $mdThemingProvider.alwaysWatchTheme(false);
        },
    ])
        .run([
        'Store',
        'ServiceWorkerUpdater',
        'SessionEnforcer',
        'cacheSweeper',
        'cacheWarmer',
        'ChartingTemplateService',
        'EmbeddedHelper',
        'WkhtmltopdfHelper',
        'AccessTokenService',
        'RememberTokenExtension',
        'SessionService',
        'OidcService',
        'EncryptionKeyPrefetch',
        'DraftSyncService',
        'ChartingPrefetch',
        'NetworkMonitor',
        'PubSubSessionExtension',
        'SessionDataMungerExtension',
        'PermissionsSessionExtension',
        'SystemAlertsService',
        'NotificationsService',
        'SessionTimerService',
        'HipaaSessionLifecycle',
        'ConsoloSsoTimeoutService',
        'OfflineSessionExtension',
        'OfflineAccessTokenExtension',
        'BackButtonBlocker',
        'DebugLogService',
        'logger',
        'BeaconService',
        'DeviceSettingsService',
        'ChartUtilService',
        'docusignEnvelopeDataTableService',
        '$mdTheming',
        'PendoService',
        function (Store, ServiceWorkerUpdater, SessionEnforcer, cacheSweeper, cacheWarmer, ChartingTemplateService, EmbeddedHelper, WkhtmltopdfHelper, AccessTokenService, RememberTokenExtension, SessionService, OidcService, EncryptionKeyPrefetch, DraftSyncService, ChartingPrefetch, NetworkMonitor, PubSubSessionExtension, SessionDataMungerExtension, PermissionsSessionExtension, SystemAlertsService, NotificationsService, SessionTimer, HipaaSessionLifecycle, ConsoloSsoTimeoutService, OfflineSessionExtension, OfflineAccessTokenExtension, BackButtonBlocker, debugLog, logger, BeaconService, DeviceSettingsService, ChartUtilService, DocusignEnvelopeDataTableService, $mdTheming, PendoService) {
            SessionService.init({
                identityEndpoint: '/api/v2/authentication/identity',
                sessionEndpoint: '/api/v2/session',
                smartCheckTTL: 1000 * 60 * 5,
                extensions: [
                    EncryptionKeyPrefetch,
                    cacheWarmer,
                    ChartingTemplateService,
                    DraftSyncService,
                    ChartingPrefetch,
                    NetworkMonitor,
                    PubSubSessionExtension,
                    SessionDataMungerExtension,
                    PermissionsSessionExtension,
                    SystemAlertsService,
                    NotificationsService,
                    SessionTimer,
                    OfflineSessionExtension,
                    BeaconService,
                    DeviceSettingsService,
                    ChartUtilService,
                    DocusignEnvelopeDataTableService,
                    PendoService,
                ],
            });
            OidcService.init({
                configEndpoint: '/api/v2/global_auth/emr/authentication/config',
                callbackPath: '/y/login/callback',
                silentAuthPath: '/y/login/silent',
                scopesFn: function (config) {
                    return [
                        "api.wellsky.consolo-emr." + config.environment,
                        'openid',
                        'profile',
                        'email',
                        'offline_access:cookie',
                        'user:read',
                        'wellsky:user:read:employee_roles',
                        'wellsky:user:read:memberships',
                        'wellsky:user:read:federation',
                        "api.wellsky.messenger." + config.environment,
                        'hhh.consolo.profile',
                        'hhh.consolo.member-groups',
                        'hhh.consolo.admin-groups',
                        'hhh.consolo.employee-groups',
                        'hhh.consolo-staging.profile',
                        'hhh.consolo-staging.member-groups',
                        'hhh.consolo-staging.admin-groups',
                        'hhh.consolo-staging.employee-groups',
                    ];
                },
                mobileAppOverrides: {
                    clientIdCookie: 'client_id',
                    refreshTokenCookie: 'refresh_token',
                },
            });
            AccessTokenService.init({
                refreshStrategy: 'silentAuth',
                extensions: [
                    OfflineAccessTokenExtension,
                    RememberTokenExtension,
                ]
            });
            ServiceWorkerUpdater.initialize();
            SessionEnforcer.initialize();
            cacheSweeper.initialize();
            ChartingTemplateService.initialize();
            BackButtonBlocker.initialize();
            EmbeddedHelper.initialize();
            WkhtmltopdfHelper.initialize();
            logger.initToasts();
            SessionTimer.init({
                ssoTimeout: ConsoloSsoTimeoutService,
                lifecycle: HipaaSessionLifecycle,
            });
            // Replace the default timeout dialog with an offline-enabled one
            HipaaSessionLifecycle.modals.login = {
                templateUrl: '/y/templates/common/session/resume-session-modal.html',
                controller: 'ResumeSessionModalController',
                controllerAs: 'loginVm',
                hasBackdrop: true,
                clickOutsideToClose: false,
                escapeToClose: false,
                multiple: true,
            };
            $mdTheming.generateTheme('default');
        },
    ]);
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('AideManagerController', [
        'SessionService',
        'PermissionsService',
        '$state',
        Controller,
    ]);
    function Controller(Session, Permissions, $state) {
        var vm = this;
        vm.showTemplatesTab = false;
        vm.showAidesTab = false;
        if ($state.current.data.tabIndex) {
            vm.currentTab = $state.current.data.tabIndex;
        }
        if (Permissions.can('show', 'recurring_task_templates')) {
            vm.showTemplatesTab = true;
        }
        var sess = Session.get();
        if (sess.agency.has_visit_tracker || sess.agency.has_aide_app) {
            vm.showAidesTab = true;
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Routes]);
    function Routes($stateProvider) {
        $stateProvider
            .state('aide-manager', {
            url: '/aide_manager',
            data: { tabIndex: -1, title: 'Hospice Aide Manager' },
            views: {
                sidebar: {
                    templateUrl: '/y/templates/aide-manager/sidebar.html',
                    controller: 'AideManagerSidebarController as vm',
                },
                'main-content': {
                    templateUrl: '/y/templates/aide-manager/aide-manager.html',
                    controller: 'AideManagerController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('show', 'hospice_aide_manager');
                    },
                ],
            },
        })
            .state('aide-manager.patients-search', {
            url: '/patients/search',
            data: { tabIndex: 0 },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/aide-manager/search.html',
                    controller: 'AideManagerSidebarController as vm',
                },
                'tab-content@aide-manager': {
                    template: '<md-card><md-card-content>Please select a patient using the search box.</md-card-content></md-card>',
                },
            },
        })
            .state('aide-manager.patients-empty', {
            url: '/patients',
            data: { tabIndex: 0 },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/aide-manager/search.html',
                    controller: 'AideManagerSidebarController as vm',
                },
                'tab-content@aide-manager': {
                    template: '<md-card><md-card-content>Please select a patient using the search box.</md-card-content></md-card>',
                },
            },
        })
            .state('aide-manager.patients', {
            url: '/patients/:patientId',
            data: { tabIndex: 0, backAction: 'aide-manager.patients-search' },
            abstract: true,
            resolve: {
                currentPatient: [
                    '$state',
                    '$stateParams',
                    'CurrentPatient',
                    function ($state, $params, CurrentPatient) {
                        return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                            return CurrentPatient.patient;
                        }, function (e) {
                            console.log('Error Resolving currentPatient');
                            console.log(e);
                        });
                    },
                ],
            },
        })
            .state('aide-manager.patients.tasks', {
            url: '/tasks',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/aide-manager/tasks.html',
                    controller: 'TasksController as vm',
                },
            },
        })
            .state('aide-manager.patients.assignees', {
            url: '/assignees',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/aide-manager/assignments/assignee.html',
                    controller: 'AssigneeController as vm',
                },
            },
        })
            .state('aide-manager.patients.apply-template', {
            url: '/apply-template',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/aide-manager/apply-template.html',
                    controller: 'ApplyTemplateController as vm',
                },
            },
        })
            .state('aide-manager.patients.assignment-history', {
            url: '/assignment-history',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/aide-manager/assignments/assignment-history.html',
                    controller: 'AssignmentHistoryController as vm',
                },
            },
        })
            .state('aide-manager.patients.settings', {
            url: '/settings',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/aide-manager/assignments/settings.html',
                    controller: 'AssignmentSettingsController as vm',
                },
            },
        })
            .state('aide-manager.recurring-task-templates', {
            url: '/recurring-task-templates',
            data: { tabIndex: 2 },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/recurring-task-sets-editor/sidebar.html',
                    controller: 'AideManagerSidebarController as vm',
                },
            },
            resolve: {
                template_auth: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('show', 'recurring_task_templates');
                    },
                ],
            },
        })
            .state('aide-manager.recurring-task-templates.template-editor', {
            url: '/template-editor',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/recurring-task-sets-editor/template-editor.html',
                    controller: 'TemplateEditorController as vm',
                },
            },
        })
            .state('aide-manager.recurring-task-templates.suggested-templates', {
            url: '/suggested-templates',
            views: {
                'tab-content@aide-manager': {
                    templateUrl: '/y/templates/recurring-task-sets-editor/suggested-templates/suggested-templates.html',
                    controller: 'SuggestedTemplatesController as vm',
                },
            },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('ApplyTemplateController', [
        '$state',
        'RecurringTaskSetTemplate',
        'ROLESYMBOL',
        ApplyTemplateController,
    ]);
    function ApplyTemplateController($state, RecurringTaskSetTemplate, ROLESYMBOL) {
        var vm = this;
        //members
        vm.patientId = $state.params.patientId;
        vm.roleSymbol = ROLESYMBOL;
        vm.taskSets = [];
        vm.templateApplied = templateApplied;
        vm.loadingSpinner = true;
        activate();
        function activate() {
            vm.taskSets = RecurringTaskSetTemplate.query({ role_symbol: vm.roleSymbol }, function () {
                vm.loadingSpinner = false;
            });
        }
        function templateApplied() {
            $state.go('aide-manager.patients.tasks', { patientId: vm.patientId });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('AideManagerSidebarController', [
        '$mdMedia',
        '$mdSidenav',
        '$state',
        'Patient',
        'cache',
        'Lookup',
        Controller,
    ]);
    function Controller($mdMedia, $mdSidenav, $state, Patient, cache, Lookup) {
        var vm = this;
        //members
        vm.searchTerm = '';
        vm.selectedPatient = $state.params.patientId
            ? Patient.get({ id: $state.params.patientId })
            : null;
        vm.state = $state;
        //functions
        vm.loadPatient = loadPatient;
        vm.filterPatients = filterPatients;
        activate();
        function activate() {
            if (!vm.selectedPatient && $mdMedia('sm')) {
                $mdSidenav('sidebar').open();
            }
        }
        function loadPatient(item) {
            if (item) {
                vm.selectedPatient = item;
                return $state.go('aide-manager.patients.tasks', { patientId: item.id });
            }
        }
        function filterPatients(term) {
            return cache(Lookup, 'lookup', { force: true, encrypt: true })
                .fetch('patients', {
                limit: -1,
                active: true,
                deceased: true,
                pending_never_admitted: true,
            })
                .then(function (results) {
                if (results) {
                    var lowerTerm = term.toLowerCase();
                    return results.filter(function (result) {
                        return result.name.toLowerCase().search(lowerTerm) > -1;
                    });
                }
                else {
                    return Lookup.patients({ q: term, all_offices: true }).$promise.then(function (results) {
                        return results;
                    });
                }
            }, function () {
                console.warn('Unable to fetch cached results');
                return Lookup.patients({ q: term, all_offices: true }).$promise.then(function (results) {
                    return results;
                });
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('TasksController', [
        '$state',
        'currentPatient',
        'RecurringTaskSet',
        'Assignee',
        'ROLESYMBOL',
        TasksController,
    ]);
    function TasksController($state, currentPatient, RecurringTaskSet, Assignee, ROLESYMBOL) {
        var vm = this;
        //members
        vm.selectedPatient = currentPatient;
        vm.taskSets = [];
        vm.roleSymbol = ROLESYMBOL;
        vm.loadingTasks = true;
        vm.loadingAssignees = true;
        vm.loadingSpinner = true;
        activate();
        function activate() {
            vm.taskSets = RecurringTaskSet.query({ patient_id: vm.selectedPatient.id }, function () {
                vm.loadingTasks = false;
                vm.loadingSpinner = vm.loadingTasks || vm.loadingAssignees;
            });
            loadAides();
        }
        function loadAides() {
            Assignee.query({ patient_id: vm.selectedPatient.id, role_symbol: ROLESYMBOL }, function (assignees) {
                vm.primaryAides = assignees.filter(function (a) {
                    return a.primary === true;
                });
                vm.alternateAides = assignees.filter(function (a) {
                    return a.primary === false;
                });
                vm.loadingAssignees = false;
                vm.loadingSpinner = vm.loadingTasks || vm.loadingAssignees;
            });
        }
    }
})();

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AllergiesService = /** @class */ (function () {
            function AllergiesService($http) {
                this.$http = $http;
                this.apiUrl = '/api/v1/allergies';
                this.screenedAllergyLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        { title: 'Description', field: 'description', canSort: true },
                        { title: 'Reaction Severity', field: 'severity', canSort: true },
                        { title: 'Reaction/Side Effects', field: 'side_effects', canSort: true },
                        {
                            title: 'Code Type',
                            field: 'code_type',
                            formatValueFn: function (data, col, value) {
                                switch (value) {
                                    case 'C':
                                        return 'PAR Class';
                                    case 'M':
                                        return 'Moiety';
                                    case 'T':
                                        return 'Trade';
                                    case 'E':
                                        return 'Environmental/Food';
                                    default:
                                        return 'N/A';
                                }
                            },
                            canSort: true,
                        },
                        {
                            title: 'Name Type',
                            field: 'name_type',
                            formatValueFn: function (data, col, value) {
                                switch (value) {
                                    case '1':
                                        return 'Primary Name';
                                    case '2':
                                        return 'Secondary/Synonym Name';
                                    case '0':
                                        return 'Undetermined';
                                    default:
                                        return 'N/A';
                                }
                            },
                            canSort: true,
                        },
                        {
                            title: 'Code Category',
                            field: 'code_category',
                            formatValueFn: function (data, col, value) {
                                switch (value) {
                                    case '1':
                                        return 'Active Ingredient';
                                    case '2':
                                        return 'Significant Inactive Ingredient';
                                    case '3':
                                        return 'Food Allergy';
                                    case '4':
                                        return 'Environmental Allergy';
                                    case '0':
                                        return 'Undetermined';
                                    default:
                                        return 'N/A';
                                }
                            },
                            canSort: true,
                        },
                    ],
                };
            }
            AllergiesService.prototype.getAllergies = function (patientId) {
                return this.$http.get(this.apiUrl, { params: { patient_id: patientId } }).then(function (response) {
                    return response.data;
                });
            };
            AllergiesService.$inject = ['$http'];
            return AllergiesService;
        }());
        services.AllergiesService = AllergiesService;
        angular.module('consolo').service('AllergiesService', AllergiesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('assigneeForm', {
            bindings: { readonly: '<', assignee: '<' },
            templateUrl: '/y/templates/assignments-and-defaults/assignee.html',
            controller: 'AssigneeFormController as $ctrl',
        });
        var AssigneeFormController = /** @class */ (function () {
            function AssigneeFormController(AssignmentsService, $http) {
                this.AssignmentsService = AssignmentsService;
                this.$http = $http;
            }
            AssigneeFormController.prototype.findUsersByRole = function (role_id, query) {
                return this.$http
                    .get('/api/v1/users', { params: { role_id: role_id, q: query } })
                    .then(function (response) {
                    return response.data;
                });
            };
            AssigneeFormController.$inject = ['AssignmentsService', '$http'];
            return AssigneeFormController;
        }());
        common.AssigneeFormController = AssigneeFormController;
        angular.module('consolo').controller('AssigneeFormController', AssigneeFormController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('assignment', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/assignments-and-defaults/assignment.html',
            controller: 'AssignmentController as $ctrl',
        });
        var AssignmentController = /** @class */ (function () {
            function AssignmentController(logger, Session, CurrentPatient, $state, $stateParams, $http, AssignmentsService) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$http = $http;
                this.AssignmentsService = AssignmentsService;
                if ($stateParams.assignmentId) {
                    this.AssignmentsService.get($stateParams.assignmentId).then(function (assignment) {
                        _this.assignment = assignment;
                    });
                }
                this.daysOfWeek = [
                    { id: 'Monday' },
                    { id: 'Tuesday' },
                    { id: 'Wednesday' },
                    { id: 'Thursday' },
                    { id: 'Friday' },
                    { id: 'Saturday' },
                    { id: 'Sunday' },
                ];
                this.visitFrequencies = [
                    { id: 'daily', description: 'Daily' },
                    { id: 'weekly', description: 'Weekly' },
                    { id: 'biweekly', description: 'Bi-Weekly' },
                    { id: 'monthly', description: 'Monthly' },
                    { id: 'quarterly', description: 'Quarterly' },
                ];
                this.biWeekly = [
                    { id: '0', description: 'Week 1 - Sunday' },
                    { id: '1', description: 'Week 1 - Monday' },
                    { id: '2', description: 'Week 1 - Tuesday' },
                    { id: '3', description: 'Week 1 - Wednesday' },
                    { id: '4', description: 'Week 1 - Thursday' },
                    { id: '5', description: 'Week 1 - Friday' },
                    { id: '6', description: 'Week 1 - Saturday' },
                    { id: '7', description: 'Week 2 - Sunday' },
                    { id: '8', description: 'Week 2 - Monday' },
                    { id: '9', description: 'Week 2 - Tuesday' },
                    { id: '10', description: 'Week 2 - Wednesday' },
                    { id: '11', description: 'Week 2  - Thursday' },
                    { id: '12', description: 'Week 2 - Friday' },
                    { id: '13', description: 'Week 2 - Saturday' },
                ];
            }
            AssignmentController.prototype.findUsersByRole = function (role_id, query) {
                return this.$http
                    .get('/api/v1/users', { params: { role_id: role_id, q: query } })
                    .then(function (response) {
                    return response.data;
                });
            };
            AssignmentController.prototype.deleteAssignee = function (assignee) {
                this.assignment.assignees.splice(this.assignment.assignees.indexOf(assignee), 1);
            };
            AssignmentController.prototype.setFrequency = function (assignee, frequency_key) {
                assignee.frequency = assignee[frequency_key];
                ['daily', 'weekly', 'biweekly', 'monthly', 'quarterly'].forEach(function (key) {
                    assignee['frequency_' + key] = null;
                });
                assignee[frequency_key] = assignee.frequency;
                assignee.frequency = new Array(assignee.frequency);
            };
            AssignmentController.$inject = [
                'logger',
                'SessionService',
                'CurrentPatient',
                '$state',
                '$stateParams',
                '$http',
                'AssignmentsService',
            ];
            return AssignmentController;
        }());
        common.AssignmentController = AssignmentController;
        angular.module('consolo').controller('AssignmentController', AssignmentController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('assignmentsIndex', {
            bindings: {},
            templateUrl: '/y/templates/assignments-and-defaults/assignments-index.html',
            controller: 'AssignmentsIndexController as $ctrl',
        });
        var AssignmentsIndexController = /** @class */ (function () {
            function AssignmentsIndexController(logger, Session, CurrentPatient, AssignmentsService, PatientRecordDefaultsService, $state, $window, WRMSsoAuthService, $mdSidenav, Assignee) {
                this.logger = logger;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.AssignmentsService = AssignmentsService;
                this.PatientRecordDefaultsService = PatientRecordDefaultsService;
                this.$state = $state;
                this.$window = $window;
                this.WRMSsoAuthService = WRMSsoAuthService;
                this.$mdSidenav = $mdSidenav;
                this.Assignee = Assignee;
                this.visitFrequencyCurrentAssignment = [];
                this.currentFrequency = [];
                this.historyAssignment = [];
                this.historyFrequency = [];
                this.historyPatientHistoryRecordDefaults = [];
                this.visitCount = 0;
                this.hasScheduler = false;
                this.hasCreateAssignmentPermission = false;
                this.disableEdit = false;
                this.hasEditAssignmentPermission = false;
                this.disableProviderClinician = true;
                this.frequencies = [];
                this.saving = false;
                this.isEditMode = false;
                this.addFrequencyLabel = 'Add Another Frequency';
                this.addFrequencyVisible = false;
                this.today = new Date();
                this.generalDetailValidationPassed = true;
                this.users = [];
                this.physicianDisabled = false;
                this.start_date = null;
                this.end_date = null;
                this.duration = null;
                this.display_start_date = null;
                this.display_end_date = null;
                this.isPhysician = false;
                this.patientSidenavOpen = false;
                this.disableRemove = false;
                this.mandatoryField = 'Please enter mandatory field values';
                this.NonZeroField = ' & Non-zero whole number value(s) in number fields';
                this.displayCreateOrder = true;
                this.dailyIntervalString = '$x$ time(s) a day';
                this.weeklyIntervalString = '$x$ times a week';
                this.monthlyIntervalString = '$x$ times a month';
                this.frequenciesList = [
                    {
                        id: 'daily',
                        name: 'Daily',
                        valueLimit: 10,
                    },
                    {
                        id: 'weekly',
                        name: 'Weekly',
                        valueLimit: 7,
                    },
                    {
                        id: 'monthly',
                        name: 'Monthly',
                        valueLimit: 28,
                    },
                ];
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.hasVisitFrequencyEnabled = this.Session.get().agency.has_visit_frequency;
                this.hasResourceManagerEnabled = this.Session.get().agency['has_resource_manager'];
                this.hasScheduler = Session.get().agency.has_new_scheduler;
                if (this.hasResourceManagerEnabled) {
                    this.hasCreateAssignmentPermission = true;
                    this.hasEditAssignmentPermission = true;
                }
                else {
                    this.checkWRMToken();
                    this.hasCreateAssignmentPermission = false;
                    this.disableEdit = true;
                    this.hasEditAssignmentPermission = false;
                }
                this.frequencyList = Assignee.frequencyTypes.filter(function (a) {
                    return a.field !== 'biweekly' && a.field !== 'quarterly';
                });
                this.initialMethodCalls();
            }
            AssignmentsIndexController.prototype.initialMethodCalls = function () {
                this.getPatientRecordDefaults();
                this.getAssignments();
                this.initTableLayout();
                this.assignmentOperations();
            };
            AssignmentsIndexController.prototype.assignmentOperations = function () {
                var _a;
                this.currentUser = this.Session.getUser();
                var today = new Date();
                this.patientAssignment = new consolo.domain.PatientAssignment();
                this.patientAssignment.assignment.start_date = today;
                this.minEndDate = this.patientAssignment.assignment.start_date;
                this.getDescipline();
                this.getPriorities();
                if (!((_a = this.users) === null || _a === void 0 ? void 0 : _a.length)) {
                    this.users.push({ id: this.currentUser.id, description: this.currentUser.name });
                }
                this.isPhysician =
                    this.currentUser.physician !== null && this.currentUser.physician.id !== null;
            };
            AssignmentsIndexController.prototype.hasUpdatePermission = function () {
                var _this = this;
                var self = this;
                self.AssignmentsService.hasUpdatePermission(this.CurrentPatient.patient_id).then(function (res) {
                    var _a;
                    if ((_a = res) === null || _a === void 0 ? void 0 : _a.isExists) {
                        self.disableEdit = true;
                    }
                    else {
                        self.disableEdit = false;
                    }
                }).catch(function (e) {
                    var _a, _b, _c, _d, _e;
                    if (((_a = e) === null || _a === void 0 ? void 0 : _a.status) === 403 && ((_c = (_b = e) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors[0]) === 'Patient not synced to wrm') {
                        _this.logger.info(e.data.errors[0]);
                    }
                    else if (((_d = e) === null || _d === void 0 ? void 0 : _d.status) === 500) {
                        _this.logger.error(((_e = e) === null || _e === void 0 ? void 0 : _e.status) + ':Internal server error');
                    }
                    else {
                        _this.logger.error('Something went wrong');
                    }
                });
            };
            AssignmentsIndexController.prototype.initTableLayout = function () {
                var _this = this;
                if (this.hasVisitFrequencyEnabled) {
                    this.currentAssigneeTableLayout = {
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                            cssClass: 'assignment-tbl-cls',
                            cssClassFn: function (assignee) {
                                if (assignee.isDiscipline) {
                                    return 'discipline-lbl';
                                }
                                return 'assignee-lbl';
                            },
                        },
                        columns: [
                            {
                                title: 'Name',
                                field: 'name',
                                type: csg.datatable.ColumnTypes.short_text,
                                cellCssClassFn: function (assignee) {
                                    if (assignee.isDiscipline) {
                                        return 'primary-assignee discipline-lbl';
                                    }
                                    return 'assignee-lbl';
                                },
                                formatValueFn: function (assignee) {
                                    return assignee.name;
                                },
                            },
                            {
                                title: 'Effective date',
                                field: "effective_date",
                                cellCssClassFn: function (assignee) {
                                    if (assignee.isDiscipline) {
                                        return 'discipline-lbl';
                                    }
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }, {
                                title: 'Rank',
                                field: "status",
                                cellCssClassFn: function (assignee) {
                                    if (assignee.isDiscipline) {
                                        return 'discipline-lbl';
                                    }
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }, {
                                title: 'Status',
                                field: "WRMOrderStatus",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }
                        ],
                    };
                    this.currentFrequencyTableLayout = {
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                            cssClass: 'assignment-tbl-cls',
                            cssClassFn: function (assignee) {
                                return 'assignee-lbl';
                            },
                        },
                        columns: [
                            {
                                title: 'Discipline',
                                field: 'name',
                                type: csg.datatable.ColumnTypes.short_text,
                                cellCssClassFn: function (assignee) {
                                    return 'primary-assignee assignee-lbl';
                                },
                                formatValueFn: function (assignee) {
                                    return assignee.name;
                                },
                            },
                            {
                                title: 'Effective date',
                                field: "effective_date",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }, {
                                title: 'Frequency',
                                field: "frequency",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }
                        ]
                    };
                    this.historyFrequencyTableLayout = {
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                            cssClass: 'assignment-tbl-cls',
                            cssClassFn: function (assignee) {
                                return 'assignee-lbl';
                            },
                        },
                        columns: [
                            {
                                title: 'Discipline',
                                field: 'name',
                                type: csg.datatable.ColumnTypes.short_text,
                                cellCssClassFn: function (assignee) {
                                    return 'primary-assignee assignee-lbl';
                                },
                                formatValueFn: function (assignee) {
                                    return assignee.name;
                                },
                            },
                            {
                                title: 'Effective date',
                                field: "effective_date",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }, {
                                title: 'Frequency',
                                field: "frequency",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            }
                        ],
                    };
                    this.historyAssignmentTableLayout = {
                        id_field: 'id',
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                        },
                        columns: [
                            { title: 'Discipline', field: 'discipline_name' },
                            {
                                title: 'Name',
                                field: 'name',
                            },
                            { title: 'Effective Date', field: 'effective_date' },
                            {
                                title: "Status",
                                field: "status"
                            }
                        ],
                    };
                    this.currentPatientRecordTableLayout = {
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                            cssClass: 'assignment-tbl-cls',
                            cssClassFn: function (assignee) {
                                return 'assignee-lbl';
                            },
                        },
                        columns: [
                            {
                                title: 'Effective Date',
                                field: "effective_date",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            },
                            {
                                title: 'DME Provider',
                                field: "dme_provider",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                                formatValueFn: function (record) {
                                    return (record.dme_provider && record.dme_provider.name) || "-";
                                },
                            },
                            {
                                title: 'Medication Administered By',
                                field: "administered_by_default_string",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            },
                            {
                                title: 'Pharmacy',
                                field: "pharmacy",
                                cellCssClassFn: function (assignee) {
                                    return 'assignee-lbl';
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                                formatValueFn: function (record) {
                                    return (record.pharmacy && record.pharmacy.name) || "-";
                                },
                            }
                        ]
                    };
                    this.historyPatientRecordTableLayout = {
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                        },
                        columns: [
                            {
                                title: "Type",
                                field: "type",
                            },
                            {
                                title: "Value",
                                field: "value",
                            },
                            {
                                title: "Effective Date",
                                field: "effective_date"
                            }
                        ]
                    };
                }
                else {
                    this.currentAssignmentTableLayout = {
                        id_field: 'id',
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                        },
                        columns: [
                            {
                                title: 'Assignee',
                                field: 'description',
                                cellCssClassFn: function (assignee) {
                                    if (assignee.primary) {
                                        return 'primary-assignee';
                                    }
                                },
                                formatValueFn: function (assignee) {
                                    if (assignee.primary) {
                                        return 'Primary: ' + assignee.description;
                                    }
                                    else {
                                        return assignee.description;
                                    }
                                },
                                type: csg.datatable.ColumnTypes.short_text,
                            },
                        ],
                    };
                    this.assignmentsTableLayout = {
                        id_field: 'id',
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                        },
                        rowMenu: {
                            items: [
                                {
                                    label: 'View',
                                    icon: 'remove_red_eye',
                                    callbackFn: function (assignment) {
                                        _this.$window.location.href =
                                            '/patients/' + _this.CurrentPatient.patient_id + '/assignments/' + assignment.id;
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.AssignmentsService.viewPermission;
                                    },
                                },
                                {
                                    label: 'Edit',
                                    icon: 'edit',
                                    callbackFn: function (assignment) {
                                        _this.$window.location.href =
                                            '/patients/' +
                                                _this.CurrentPatient.patient_id +
                                                '/assignments/' +
                                                assignment.id +
                                                '/edit';
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.AssignmentsService.updatePermission;
                                    },
                                },
                                {
                                    label: 'Delete',
                                    icon: 'delete',
                                    callbackFn: function (assignment) {
                                        return _this.AssignmentsService.delete(assignment.id).then(function (deletedAssignment) {
                                            _this.getAssignments();
                                            _this.logger.success('The record has been deleted.');
                                            return true;
                                        });
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.AssignmentsService.deletePermission;
                                    },
                                },
                            ],
                        },
                        columns: [
                            { title: 'Effective Date', field: 'effective_date' },
                            {
                                title: 'Assignees',
                                field: 'assignees',
                                formatValueFn: function (assignment) {
                                    return assignment.assignees.map(function (assignee) {
                                        return assignee.description;
                                    });
                                },
                            },
                        ],
                    };
                    this.recordDefaultsTableLayout = {
                        id_field: 'id',
                        headerMenu: {
                            csvContents: true,
                            items: [],
                        },
                        options: {
                            borderStyle: 3,
                            defaultPageSize: 100,
                            hideFooter: true,
                            showExpandCollapseAll: false,
                            hideTextFilter: true,
                        },
                        rowMenu: {
                            items: [
                                {
                                    label: 'View',
                                    icon: 'remove_red_eye',
                                    callbackFn: function (patientRecordDefault) {
                                        return _this.$state.go('patients.assignments-and-defaults.view', {
                                            patientId: patientRecordDefault.patient_id,
                                            patientRecordDefaultId: patientRecordDefault.id,
                                        });
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.PatientRecordDefaultsService.viewPermission;
                                    },
                                },
                                {
                                    label: 'Edit',
                                    icon: 'edit',
                                    callbackFn: function (patientRecordDefault) {
                                        return _this.$state.go('patients.assignments-and-defaults.edit', {
                                            patientId: patientRecordDefault.patient_id,
                                            patientRecordDefaultId: patientRecordDefault.id,
                                        });
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.PatientRecordDefaultsService.updatePermission;
                                    },
                                },
                                {
                                    label: 'Delete',
                                    icon: 'delete',
                                    callbackFn: function (patientRecordDefault) {
                                        return _this.PatientRecordDefaultsService.delete(patientRecordDefault.id).then(function (deletedPatientRecordDefault) {
                                            _this.getPatientRecordDefaults();
                                            _this.logger.success('The record has been deleted.');
                                            return true;
                                        });
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.PatientRecordDefaultsService.deletePermission;
                                    },
                                },
                            ],
                        },
                        columns: [
                            {
                                title: 'Effective Date',
                                field: 'effective_date',
                                type: csg.datatable.ColumnTypes.date,
                            },
                            {
                                title: 'MRN',
                                field: 'medical_record_no',
                            },
                        ],
                    };
                }
            };
            AssignmentsIndexController.prototype.deleteCurrentDefault = function () {
                var _this = this;
                this.PatientRecordDefaultsService.delete(this.currentPatientRecordDefault[0].id).then(function (deletedPatientRecordDefault) {
                    _this.getPatientRecordDefaults();
                    _this.logger.success('The record has been deleted.');
                    return true;
                });
            };
            AssignmentsIndexController.prototype.refreshWRMToken = function () {
                var _this = this;
                this.WRMSsoAuthService.getToken().then(function (response) {
                    var _a, _b;
                    if ((_b = (_a = response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.token) {
                        _this.wrmToken = response.data.token;
                        _this.wrmScheduler();
                    }
                    else {
                        _this.wrmToken = null;
                    }
                }).catch(function (err) {
                    var _a;
                    var error = ((_a = err.error) === null || _a === void 0 ? void 0 : _a.errors[0]) || err.error;
                    console.log("Error: " + error);
                    _this.wrmToken = null;
                });
            };
            AssignmentsIndexController.prototype.openWRMorScheduler = function () {
                if (this.assignmentButtonText === 'Open in Resource Manager') {
                    this.refreshWRMToken();
                }
                else {
                    this.goToSchedulerPatientAssignment();
                }
            };
            AssignmentsIndexController.prototype.goToSchedulerPatientAssignment = function () {
                var schedulerUrl = '';
                if (this.$window.location.href.indexOf('localhost') > -1) {
                    schedulerUrl = this.$window.location.protocol + "//" + this.$window.location.host.replace('3000', '8080') + "/scheduler/patients/" + this.CurrentPatient.patient_id + "/assignments";
                }
                else {
                    schedulerUrl = this.$window.location.protocol + "//" + this.$window.location.host + "/scheduler/patients/" + this.CurrentPatient.patient_id + "/assignments"; // tslint:disable-line
                }
                this.$window.open(schedulerUrl);
            };
            AssignmentsIndexController.prototype.checkWRMToken = function () {
                var _this = this;
                if (this.hasResourceManagerEnabled) {
                    this.WRMSsoAuthService.getToken().then(function (response) {
                        var _a, _b;
                        if ((_b = (_a = response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.token) {
                            _this.wrmToken = response.data.token;
                            if (_this.CurrentPatient.patient.office.use_wellsky_resource_manager) {
                                _this.assignmentButtonText = 'Open in Resource Manager';
                            }
                            else {
                                _this.assignmentButtonText = 'Open in Scheduler';
                            }
                            if (_this.visitCount > 0) {
                                _this.wrmScheduler();
                            }
                        }
                        else {
                            _this.wrmToken = null;
                            _this.assignmentButtonText = 'Open in Scheduler';
                        }
                    }).catch(function (err) {
                        var _a;
                        var error = ((_a = err.error) === null || _a === void 0 ? void 0 : _a.errors[0]) || err.error;
                        console.log("Error: " + error);
                        _this.wrmToken = null;
                        _this.assignmentButtonText = 'Open in Scheduler';
                    });
                }
                else {
                    this.wrmToken = null;
                    this.assignmentButtonText = 'Open in Scheduler';
                }
            };
            AssignmentsIndexController.prototype.redirectToWRMScheduler = function () {
                if (this.visitCount === 0) {
                    this.visitCount++;
                    this.wrmScheduler();
                }
                else {
                    this.visitCount++;
                    this.checkWRMToken();
                }
            };
            AssignmentsIndexController.prototype.wrmScheduler = function () {
                this.WRMSsoAuthService.setWRMUrl();
                window.open("" + this.WRMSsoAuthService.wrmSchedulerURL + this.wrmToken);
            };
            AssignmentsIndexController.prototype.redirectAssignment = function (item) {
                switch (item) {
                    case 'addNew':
                        this.isEditMode = false;
                        this.displayCreateOrder = true;
                        this.clear();
                        this.pageTitle = 'New Assignment and Assignees';
                        if (this.currentAssignment) {
                            this.disableProviderClinician = false;
                            this.getAssignmentCarryData();
                        }
                        this.$mdSidenav('patient-assignment').open();
                        break;
                    case 'Edit':
                        this.pageTitle = 'Edit Assignment and Assignees';
                        this.isEditMode = true;
                        this.currentAssignmentId = this.currentAssignmentId;
                        this.disableProviderClinician = true;
                        this.getAssignmentData();
                        break;
                }
            };
            AssignmentsIndexController.prototype.getAssignments = function () {
                var _this = this;
                this.assignments = [];
                if (this.AssignmentsService.viewPermission) {
                    var self_1 = this;
                    self_1.AssignmentsService.getAssignments(self_1.CurrentPatient.patient_id).then(function (assignments) {
                        var _a;
                        if (assignments && assignments.length) {
                            self_1.currentAssignment = assignments[0];
                            self_1.currentAssignmentId = (_a = self_1.currentAssignment) === null || _a === void 0 ? void 0 : _a.id;
                            self_1.hasVisitFrequencyEnabled && self_1.formatCurrentAssignment();
                            assignments.splice(0, 1);
                            self_1.checkWRMToken();
                        }
                        else {
                            self_1.disableEdit = true;
                            self_1.checkWRMToken();
                        }
                        self_1.assignments = assignments;
                        self_1.hasVisitFrequencyEnabled && self_1.formatHistoryAssignment();
                    }).catch(function (e) {
                        _this.logger.error('Error while fetching the assignment(s).');
                        _this.checkWRMToken();
                    });
                }
                else {
                    this.disableEdit = true;
                    this.checkWRMToken();
                    this.logger.error('You do not have permission to view Assignments.');
                }
            };
            AssignmentsIndexController.prototype.getPatientRecordDefaults = function () {
                var _this = this;
                this.recordDefaults = [];
                if (this.PatientRecordDefaultsService.viewPermission) {
                    this.PatientRecordDefaultsService.getRecordDefaults(this.CurrentPatient.patient_id).then(function (recordDefaults) {
                        _this.currentPatientRecordDefault = [];
                        if (recordDefaults.length) {
                            _this.currentPatientRecordDefault = [].concat(recordDefaults[0]);
                            recordDefaults.splice(0, 1);
                        }
                        _this.recordDefaults = [].concat(recordDefaults);
                        _this.hasVisitFrequencyEnabled && _this.formatPatientHistoryRecord();
                    });
                }
                else {
                    this.logger.error('You do not have permission to view Patient Record Defaults.');
                }
            };
            AssignmentsIndexController.prototype.formatCurrentAssignment = function () {
                var currentAssignment = this.currentAssignment;
                if (currentAssignment && currentAssignment.assignees.length) {
                    var _currentAssign = this.getFormatedAssignments([].concat(currentAssignment));
                    this.visitFrequencyCurrentAssignment = [];
                    this.currentFrequency = [];
                    for (var id in _currentAssign) {
                        if (_currentAssign.hasOwnProperty(id)) {
                            var discipline = {
                                name: _currentAssign[id].discipline_name,
                                effective_date: "",
                                status: "",
                                isDiscipline: true
                            };
                            this.visitFrequencyCurrentAssignment.push(discipline);
                            for (var assignee_id in _currentAssign[id].data) {
                                if (_currentAssign[id].data.hasOwnProperty(assignee_id)) {
                                    var assignee = _currentAssign[id].data[assignee_id];
                                    if (assignee.primary_effective_date) {
                                        this.visitFrequencyCurrentAssignment.push({
                                            name: assignee.name,
                                            effective_date: assignee.primary_effective_date,
                                            status: "Primary"
                                        });
                                    }
                                    if (assignee.secondary_effective_date) {
                                        this.visitFrequencyCurrentAssignment.push({
                                            name: assignee.name,
                                            effective_date: assignee.secondary_effective_date,
                                            status: "Secondary"
                                        });
                                    }
                                }
                            }
                            for (var frequency in _currentAssign[id].frequencyData) {
                                if (_currentAssign[id].frequencyData.hasOwnProperty(frequency)) {
                                    this.currentFrequency.push({
                                        name: _currentAssign[id].discipline_name,
                                        effective_date: _currentAssign[id].frequencyData[frequency],
                                        frequency: frequency
                                    });
                                }
                            }
                        }
                    }
                }
            };
            AssignmentsIndexController.prototype.getFormatedAssignments = function (assignmentList) {
                var assignments = assignmentList;
                if (assignments && assignments.length) {
                    var assignmentData_1 = {};
                    var lastIndex = assignments.length - 1;
                    for (var i = lastIndex; i >= 0; i--) {
                        assignments[i].assignees.forEach(function (assignee) {
                            var _a, _b, _c;
                            var role_id = assignee.role_id, assigned_person_id = assignee.assigned_person_id, primary = assignee.primary, assignment_effective_date = assignee.assignment_effective_date;
                            var effective_date = moment(assignment_effective_date).format('MM/DD/YYYY');
                            var frequency_str = ((assignee.visit_frequency_string || '') + " " + (assignee.prn_frequency_string || '')).trim();
                            frequency_str = frequency_str || "N/A";
                            if (!assignmentData_1[role_id]) {
                                assignmentData_1[role_id] = {
                                    "discipline_name": assignee.role_name,
                                    "data": {},
                                    "frequencyData": (_a = {},
                                        _a[frequency_str] = effective_date,
                                        _a)
                                };
                                assignmentData_1[role_id].data[assignee.assigned_person_id] = (_b = {
                                        "name": assignee.assigned_person.name
                                    },
                                    _b[primary ? "primary_effective_date" : "secondary_effective_date"] = effective_date,
                                    _b);
                            }
                            else {
                                if (!assignmentData_1[role_id].data[assignee.assigned_person_id]) {
                                    assignmentData_1[role_id].data[assignee.assigned_person_id] = (_c = {
                                            "name": assignee.assigned_person.name
                                        },
                                        _c[primary ? "primary_effective_date" : "secondary_effective_date"] = effective_date,
                                        _c.primary = primary,
                                        _c);
                                }
                                else {
                                    var date = assignmentData_1[role_id].data[assignee.assigned_person_id][primary ? "primary_effective_date" : "secondary_effective_date"];
                                    if (date) {
                                        date = moment(date) < moment(effective_date) ? date : effective_date;
                                    }
                                    else {
                                        date = effective_date;
                                    }
                                    assignmentData_1[role_id].data[assignee.assigned_person_id][primary ? "primary_effective_date" : "secondary_effective_date"] = date;
                                }
                                if (assignmentData_1[role_id].frequencyData[frequency_str]) {
                                    assignmentData_1[role_id].frequencyData[frequency_str] =
                                        moment(assignmentData_1[role_id].frequencyData[frequency_str]) < moment(effective_date) ?
                                            assignmentData_1[role_id].frequencyData[frequency_str] : effective_date;
                                }
                                else {
                                    assignmentData_1[role_id].frequencyData[frequency_str] = effective_date;
                                }
                            }
                        });
                    }
                    return assignmentData_1;
                }
            };
            AssignmentsIndexController.prototype.formatHistoryAssignment = function () {
                var assignments = this.assignments;
                var historyData = this.getFormatedAssignments(assignments);
                this.historyAssignment = [];
                this.historyFrequency = [];
                for (var id in historyData) {
                    if (historyData.hasOwnProperty(id)) {
                        for (var assignee_id in historyData[id].data) {
                            if (historyData[id].data.hasOwnProperty(assignee_id)) {
                                var assignee = historyData[id].data[assignee_id];
                                if (assignee.primary_effective_date) {
                                    this.historyAssignment.push({
                                        discipline_name: historyData[id]["discipline_name"],
                                        name: assignee.name,
                                        effective_date: assignee.primary_effective_date,
                                        status: "Primary"
                                    });
                                }
                                if (assignee.secondary_effective_date) {
                                    this.historyAssignment.push({
                                        discipline_name: historyData[id]["discipline_name"],
                                        name: assignee.name,
                                        effective_date: assignee.secondary_effective_date,
                                        status: "Secondary"
                                    });
                                }
                            }
                        }
                        for (var frequency in historyData[id].frequencyData) {
                            if (historyData[id].frequencyData.hasOwnProperty(frequency)) {
                                this.historyFrequency.push({
                                    name: historyData[id].discipline_name,
                                    effective_date: historyData[id].frequencyData[frequency],
                                    frequency: frequency
                                });
                            }
                        }
                    }
                }
            };
            AssignmentsIndexController.prototype.formatPatientHistoryRecord = function () {
                var patientRecord = {
                    pharmacy: { type: "Pharmacy", data: {} },
                    funeral_home: { type: "Funeral Home", data: {} },
                    dme_provider: { type: "DME Provider", data: {} }
                };
                for (var _i = 0, _a = this.recordDefaults; _i < _a.length; _i++) {
                    var record = _a[_i];
                    var fields = ["pharmacy", "funeral_home", "dme_provider"];
                    for (var _b = 0, fields_1 = fields; _b < fields_1.length; _b++) {
                        var field = fields_1[_b];
                        if (record[field]) {
                            if (!patientRecord[field]["data"][record[field + "_id"]]) {
                                patientRecord[field]["data"][record[field + "_id"]] = {
                                    name: record[field].name,
                                    address: [record[field].address_1, record[field].address_2, record[field].city, record[field].state, record[field].zipcode],
                                    effective_date: record.effective_date
                                };
                            }
                            else {
                                var date = patientRecord[field]["data"][record[field + "_id"]].effective_date;
                                patientRecord[field]["data"][record[field + "_id"]].effective_date = (moment(record.effective_date) < moment(date)) ?
                                    record.effective_date : date;
                            }
                        }
                    }
                }
                this.historyPatientHistoryRecordDefaults = [];
                for (var type in patientRecord) {
                    if (patientRecord.hasOwnProperty(type)) {
                        for (var value in patientRecord[type].data) {
                            if (patientRecord[type].data.hasOwnProperty(value)) {
                                var record = patientRecord[type].data[value];
                                this.historyPatientHistoryRecordDefaults.push({
                                    type: patientRecord[type].type,
                                    value: record.name,
                                    effective_date: moment(record.effective_date).format('MM/DD/YYYY')
                                });
                            }
                        }
                    }
                }
            };
            // ------------ Assignment Functions -----------
            AssignmentsIndexController.prototype.updateDate = function (type) {
                if (type === 'start') {
                    this.minEndDate = this.patientAssignment.assignment.start_date;
                    if (this.patientAssignment.assignment.start_date > this.patientAssignment.assignment.end_date) {
                        this.patientAssignment.assignment.end_date = null;
                    }
                }
                var oneDay = 24 * 60 * 60 * 1000;
                if (this.patientAssignment.assignment.start_date && this.patientAssignment.assignment.end_date) {
                    this.patientAssignment.assignment.duration = Math.round(Math.abs((this.start_date - this.end_date) / oneDay));
                }
            };
            AssignmentsIndexController.prototype.getAssignmentData = function () {
                var _this = this;
                var self = this;
                self.AssignmentsService.getAssignmentData(self.CurrentPatient.patient_id, self.currentAssignmentId).then(function (response) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    self.patientAssignment = response;
                    if (((_b = (_a = self.patientAssignment) === null || _a === void 0 ? void 0 : _a.assignment) === null || _b === void 0 ? void 0 : _b.frequencies) && ((_d = (_c = self.patientAssignment) === null || _c === void 0 ? void 0 : _c.assignment) === null || _d === void 0 ? void 0 : _d.frequencies.length)) {
                        self.frequencies = (_f = (_e = self.patientAssignment) === null || _e === void 0 ? void 0 : _e.assignment) === null || _f === void 0 ? void 0 : _f.frequencies;
                        for (var k = 0; k < self.frequencies.length; k++) {
                            self.frequencyValuesChanged(self.frequencies[k].frequency, k, false);
                        }
                    }
                    else {
                        self.frequencies = [];
                    }
                    if ((_g = self.patientAssignment) === null || _g === void 0 ? void 0 : _g['create_order']) {
                        self.displayCreateOrder = false;
                        if ((_j = (_h = self.patientAssignment) === null || _h === void 0 ? void 0 : _h.assignment) === null || _j === void 0 ? void 0 : _j.generalDetail) {
                            self.generalDetailSection = self.patientAssignment.assignment.generalDetail;
                            if ((_k = self.generalDetailSection) === null || _k === void 0 ? void 0 : _k.read_back) {
                                self.generalDetailSection.verbalReadBack = 'readBack';
                            }
                            else if ((_l = self.generalDetailSection) === null || _l === void 0 ? void 0 : _l.non_verbal_order) {
                                self.generalDetailSection.verbalReadBack = 'nonVerbal';
                            }
                            else {
                                self.generalDetailSection.verbalReadBack = null;
                            }
                        }
                        else {
                            self.generalDetailSection = null;
                        }
                    }
                    else {
                        self.displayCreateOrder = true;
                        self.generalDetailSection = null;
                        self.patientAssignment.assignment['generalDetail'] = {};
                    }
                    _this.$mdSidenav('patient-assignment').open();
                }).catch(function (error) {
                    _this.logger.error('Something went wrong.');
                });
            };
            AssignmentsIndexController.prototype.getAssignmentCarryData = function () {
                var _this = this;
                this.AssignmentsService.getAssignmentData(this.CurrentPatient.patient_id, this.currentAssignmentId).then(function (response) {
                    var _a, _b, _c, _d, _e, _f;
                    if (((_b = (_a = response) === null || _a === void 0 ? void 0 : _a.assignment) === null || _b === void 0 ? void 0 : _b.frequencies) && ((_d = (_c = response) === null || _c === void 0 ? void 0 : _c.assignment) === null || _d === void 0 ? void 0 : _d.frequencies.length)) {
                        _this.frequencies = (_f = (_e = response) === null || _e === void 0 ? void 0 : _e.assignment) === null || _f === void 0 ? void 0 : _f.frequencies;
                        for (var k = 0; k < _this.frequencies.length; k++) {
                            _this.frequencies[k].id = null;
                            _this.frequencyValuesChanged(_this.frequencies[k].frequency, k, false);
                        }
                    }
                    else {
                        _this.frequencies = [];
                    }
                }).catch(function (error) {
                    _this.logger.error('Something went wrong.');
                });
            };
            AssignmentsIndexController.prototype.setPhysicianAutoComplete = function (section, i) {
                switch (section) {
                    case 'frequency':
                        this.disableProviderClinician = false;
                        this.frequencies[i].provider = this.frequencies[i].selected_provider = null;
                        break;
                    case 'generalDetail':
                        this.generalDetailSection.physician_id = this.generalDetailSection.selected_provider = null;
                        break;
                }
            };
            AssignmentsIndexController.prototype.getDescipline = function () {
                var _this = this;
                this.AssignmentsService.getDescipline().then(function (data) {
                    _this.desciplineList = data;
                }).catch(function (err) {
                    _this.desciplineList = [];
                });
            };
            AssignmentsIndexController.prototype.getPriorities = function () {
                this.priorityList = this.AssignmentsService.getPriorities();
            };
            AssignmentsIndexController.prototype.addFrequency = function () {
                this.frequencies.push(new consolo.domain.Frequencies());
            };
            AssignmentsIndexController.prototype.saveAssignment = function () {
                var _this = this;
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                if (this.AssignAssignmentValidation()) {
                    this.patientAssignment.patient_id = this.CurrentPatient.patient_id;
                    this.checkGeneralDetailValidation();
                    if (this.generalDetailValidationPassed) {
                        var self_2 = this;
                        if (typeof ((_a = this.generalDetailSection) === null || _a === void 0 ? void 0 : _a.physician_id) === 'object') {
                            self_2.generalDetailSection.physician_id = (_b = self_2.generalDetailSection) === null || _b === void 0 ? void 0 : _b.physician_id['id'];
                        }
                        this.patientAssignment.assignment.generalDetail = this.generalDetailSection;
                        if (this.checkFrequenciesValidation()) {
                            this.patientAssignment.assignment.frequencies = this.frequencies;
                            this.assignment = JSON.parse(JSON.stringify(this.patientAssignment));
                            if ((_e = (_d = (_c = this.assignment) === null || _c === void 0 ? void 0 : _c.assignment) === null || _d === void 0 ? void 0 : _d.generalDetail) === null || _e === void 0 ? void 0 : _e.selected_provider) {
                                delete this.assignment.assignment.generalDetail.selected_provider;
                            }
                            var self_3 = this;
                            (_h = (_g = (_f = this.assignment) === null || _f === void 0 ? void 0 : _f.assignment) === null || _g === void 0 ? void 0 : _g.frequencies) === null || _h === void 0 ? void 0 : _h.forEach(function (e, i) {
                                delete self_3.assignment.assignment.frequencies[i].frequencyValues;
                                delete self_3.assignment.assignment.frequencies[i].selected_provider;
                                if (typeof (e.provider) === 'object') {
                                    self_3.assignment.assignment.frequencies[i].provider = self_3.assignment.assignment.frequencies[i].provider['id'];
                                }
                            });
                            if ((_k = (_j = self_3.assignment) === null || _j === void 0 ? void 0 : _j.assignment) === null || _k === void 0 ? void 0 : _k.start_date) {
                                self_3.assignment.assignment.start_date = this.changeDateFormat(self_3.assignment.assignment.start_date);
                            }
                            if ((_m = (_l = self_3.assignment) === null || _l === void 0 ? void 0 : _l.assignment) === null || _m === void 0 ? void 0 : _m.end_date) {
                                self_3.assignment.assignment.end_date = this.changeDateFormat(self_3.assignment.assignment.end_date);
                            }
                            if ((_q = (_p = (_o = self_3.assignment) === null || _o === void 0 ? void 0 : _o.assignment) === null || _p === void 0 ? void 0 : _p.generalDetail) === null || _q === void 0 ? void 0 : _q.assessment_date) {
                                var assesment_date = this.changeDateFormat(self_3.assignment.assignment.generalDetail.assessment_date);
                                self_3.assignment.assignment.generalDetail.assessment_date = assesment_date;
                            }
                            this.AssignmentsService.savePatientAssignment(self_3.assignment).then(function (response) {
                                _this.clear();
                                var successMessage = 'Assignment Saved Successfully';
                                if (_this.isEditMode) {
                                    successMessage = 'Assignment Updated Successfully';
                                }
                                _this.logger.success(successMessage);
                                _this.$mdSidenav('patient-assignment').close();
                                location.reload();
                            }).catch(function (error) {
                                var _a, _b, _c;
                                if (((_a = error) === null || _a === void 0 ? void 0 : _a.status) === 422 && ((_c = (_b = error) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors[0])) {
                                    _this.logger.error(error.data.errors[0]);
                                }
                                else {
                                    console.log(JSON.stringify(error));
                                    _this.logger.error('Error saving assignment');
                                }
                            });
                        }
                        else {
                            this.logger.error('Assignee Error: ' + this.mandatoryField + this.NonZeroField);
                        }
                    }
                    else {
                        this.logger.error('General Detail Error: ' + this.mandatoryField + this.NonZeroField);
                    }
                }
                else {
                    this.logger.error('Assignment Error: ' + this.mandatoryField + this.NonZeroField);
                }
            };
            AssignmentsIndexController.prototype.changeDateFormat = function (date) {
                return new Date(date).toDateString();
            };
            AssignmentsIndexController.prototype.clear = function () {
                this.patientAssignment.create_order = false;
                this.patientAssignment.assignment = {
                    id: null,
                    start_date: null,
                    end_date: null,
                    priority: null,
                    duration: null,
                    generalDetail: {},
                    frequencies: []
                };
                this.frequencies = [];
                this.generalDetailSection = null;
                this.disableProviderClinician = true;
            };
            AssignmentsIndexController.prototype.checkboxChanged = function () {
                if (this.patientAssignment.create_order) {
                    if (this.AssignAssignmentValidation()) {
                        this.generalDetailSection = new consolo.domain.GeneralDetail();
                        this.generalDetailSection.assessment_date = this.today;
                        this.generalDetailSection.nurse_id = this.currentUser.id;
                        this.disableRemove = false;
                    }
                    else {
                        this.logger.error(this.mandatoryField + this.NonZeroField);
                        this.patientAssignment.create_order = false;
                    }
                }
                else {
                    this.generalDetailSection = null;
                    this.patientAssignment.assignment.generalDetail = {};
                }
            };
            AssignmentsIndexController.prototype.closeSideNav = function () {
                this.disableProviderClinician = true;
                this.clear();
                this.$mdSidenav('patient-assignment').close();
            };
            AssignmentsIndexController.prototype.AssignAssignmentValidation = function () {
                var _a, _b;
                if (!this.patientAssignment ||
                    !((_a = this.patientAssignment.assignment) === null || _a === void 0 ? void 0 : _a.start_date) ||
                    !((_b = this.patientAssignment.assignment) === null || _b === void 0 ? void 0 : _b.end_date)) {
                    return false;
                }
                return true;
            };
            AssignmentsIndexController.prototype.checkGeneralDetailValidation = function () {
                if (this.generalDetailSection) {
                    if (!this.generalDetailSection.assessment_date || !this.generalDetailSection.physician_id) {
                        this.logger.error(this.mandatoryField);
                        return false;
                    }
                    if (!this.generalDetailSection.verbalReadBack) {
                        this.logger.error('You must select Non Verbal or Read Back');
                        return false;
                    }
                }
                return true;
            };
            AssignmentsIndexController.prototype.checkFrequenciesValidation = function () {
                var _a;
                if ((_a = this.frequencies) === null || _a === void 0 ? void 0 : _a.length) {
                    for (var i = 0; i < this.frequencies.length; i++) {
                        var assignee = this.frequencies[i];
                        if (!assignee.resource_type || !assignee.provider || (assignee.frequency && !assignee.every_x_days_frequency)) {
                            return false;
                        }
                    }
                }
                return true;
            };
            AssignmentsIndexController.prototype.setOrderVerbalReadBack = function (verbalReadBack, index) {
                if (verbalReadBack === 'readBack') {
                    this.generalDetailSection.read_back = true;
                    this.generalDetailSection.non_verbal_order = false;
                }
                else {
                    this.generalDetailSection.read_back = false;
                    this.generalDetailSection.non_verbal_order = true;
                }
            };
            AssignmentsIndexController.prototype.removeFrequency = function (index) {
                this.frequencies.splice(index, 1);
            };
            AssignmentsIndexController.prototype.selectedUser = function (item, section, i) {
                switch (section) {
                    case 'frequency':
                        if (item) {
                            this.frequencies[i].provider = item.id;
                            this.frequencies[i].selected_provider = item;
                        }
                        else {
                            this.frequencies[i].provider = null;
                            this.frequencies[i].selected_provider = null;
                        }
                        break;
                    case 'generalDetail':
                        if (item) {
                            this.generalDetailSection.physician_id = item.id;
                            this.generalDetailSection.selected_provider = item;
                        }
                        else {
                            this.generalDetailSection.physician_id = null;
                            this.generalDetailSection.selected_provider = null;
                        }
                        break;
                }
            };
            AssignmentsIndexController.prototype.frequencyValuesChanged = function (selectedFrequency, i, dropdownChanged) {
                if (dropdownChanged) {
                    this.frequencies[i].every_x_days_frequency = null;
                    this.frequencies[i].interval = null;
                    this.frequencies[i].every_x_days_prn = null;
                    this.frequencies[i].number_of_visits = null;
                }
                this.frequencies[i].frequencyValues = [];
                this.frequencies[i].intervalValues = [];
                if (selectedFrequency) {
                    var index = this.frequenciesList.findIndex(function (e) { return e.id === selectedFrequency; });
                    if (index > -1) {
                        var selectedFrequencyLimit = this.frequenciesList[index].valueLimit;
                        var intervalString = this.getIntervalString(selectedFrequency);
                        var intervalVisitAmount = this.getIntervalVisitAmount(selectedFrequency);
                        for (var j = 0; j < selectedFrequencyLimit; j++) {
                            this.frequencies[i].frequencyValues.push({ value: j + 1 });
                            var idxStr = (j + 1).toString();
                            this.frequencies[i].intervalValues.push({ value: j + 1, label: intervalString.replace('$x$', idxStr) });
                        }
                    }
                }
            };
            AssignmentsIndexController.prototype.isProviderReadOnly = function (index) {
                if (this.frequencies[index].resource_type === null) {
                    return true;
                }
                else {
                    return false;
                }
            };
            AssignmentsIndexController.prototype.isNumberOfVisitsReadOnly = function (index) {
                if (this.frequencies[index].frequency === null) {
                    return true;
                }
                else {
                    return false;
                }
            };
            AssignmentsIndexController.prototype.numberOfVisitsChanged = function (i) {
                if (this.frequencies[i].number_of_visits !== null) {
                    this.frequencies[i].every_x_days_prn = this.getIntervalVisitAmount(this.frequencies[i].frequency);
                }
            };
            AssignmentsIndexController.prototype.isPrnFrequencyReadOnly = function (index) {
                if (!(this.frequencies[index].number_of_visits !== null && this.frequencies[index].every_x_days_frequency === null)) {
                    return true;
                }
                else {
                    return false;
                }
            };
            AssignmentsIndexController.prototype.getIntervalString = function (frequency) {
                switch (frequency) {
                    case 'daily':
                        return this.dailyIntervalString;
                    case 'weekly':
                        return this.weeklyIntervalString;
                    case 'monthly':
                        return this.monthlyIntervalString;
                    default:
                        return '';
                }
            };
            AssignmentsIndexController.prototype.getIntervalVisitAmount = function (frequency) {
                switch (frequency) {
                    case 'daily':
                        return 1;
                    case 'weekly':
                        return 7;
                    case 'monthly':
                        return 28;
                    default:
                        return null;
                }
            };
            AssignmentsIndexController.prototype.isReadOnly = function (index) {
                if (this.frequencies[index].resource_type === null) {
                    return true;
                }
                else {
                    return false;
                }
            };
            // ------------ Assignment Variables -----------
            AssignmentsIndexController.$inject = [
                'logger',
                'SessionService',
                'CurrentPatient',
                'AssignmentsService',
                'PatientRecordDefaultsService',
                '$state',
                '$window',
                'WRMSsoAuthService',
                '$mdSidenav',
                'Assignee'
            ];
            return AssignmentsIndexController;
        }());
        common.AssignmentsIndexController = AssignmentsIndexController;
        angular
            .module('consolo')
            .controller('AssignmentsIndexController', AssignmentsIndexController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AssignmentsService = /** @class */ (function () {
            function AssignmentsService($http, PermissionsService, options, Lookup, cache) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.options = options;
                this.Lookup = Lookup;
                this.cache = cache;
                this.assignmentsAPIUrl = '/api/v1/assignments';
                this.daysOfWeek = [
                    { id: 'Monday' },
                    { id: 'Tuesday' },
                    { id: 'Wednesday' },
                    { id: 'Thursday' },
                    { id: 'Friday' },
                    { id: 'Saturday' },
                    { id: 'Sunday' },
                ];
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.base_roles = options.get('base_roles');
                this.updatePermission = this.PermissionsService.can('update', 'assignments');
                this.createPermission = this.PermissionsService.can('create', 'assignments');
                this.deletePermission = this.PermissionsService.can('destroy', 'assignments');
                this.viewPermission = this.PermissionsService.can('show', 'assignments');
                this.verbalReadBackOptions = [
                    { id: 'readBack', description: 'Read Back to Physician' },
                    { id: 'nonVerbal', description: 'Non-Verbal Order' },
                ];
                this.priorities = [
                    { code: 'stat', description: '1 - Stat' },
                    { code: 'urgent', description: '2 - Urgent' },
                    { code: 'asap', description: '3 - ASAP' },
                    { code: 'routine', description: '4 - Routine' },
                ];
            }
            AssignmentsService.prototype.physicianLookup = function (q) {
                var cachedLookup = this.cache(this.Lookup, 'physicians', { maxAge: 10 });
                return cachedLookup['physicians']({ limit: -1 }).$promise.then(function (physicians) {
                    if (q) {
                        q = q.toLowerCase();
                        return physicians.filter(function (x) { return x.name.toLowerCase().indexOf(q) >= 0; });
                    }
                    return physicians;
                });
            };
            AssignmentsService.prototype.getDescipline = function () {
                return this.options.get('base_roles').$promise.then(function (data) {
                    return data;
                });
            };
            AssignmentsService.prototype.getPriorities = function () {
                return this.priorities;
            };
            AssignmentsService.prototype.delete = function (id) {
                return this.$http.delete(this.assignmentsAPIUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.get = function (assignmentId) {
                return this.$http.get(this.assignmentsAPIUrl + '/' + assignmentId).then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.getAssignments = function (patientId) {
                return this.$http
                    .get(this.assignmentsAPIUrl, { params: { patient_id: patientId } })
                    .then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.getPatientAssignment = function (patientId) {
                return this.$http
                    .get(this.assignmentsAPIUrl + '/getpatientassignment', { params: { patient_id: patientId } })
                    .then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.savePatientAssignment = function (patientAssigments) {
                return this.$http.post(this.assignmentsAPIUrl, patientAssigments).then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.getAssignmentData = function (patient_id, id) {
                return this.$http.get(this.assignmentsAPIUrl + '/edit', { params: { patient_id: patient_id, id: id } }).then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.hasUpdatePermission = function (patient_id) {
                return this.$http
                    .get('/api/v1/wrm/is-order-exists', { params: { patient_id: patient_id } })
                    .then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.prototype.findUsersByRole = function (role_id, query) {
                return this.$http
                    .get('/api/v1/users', { params: { role_id: role_id, q: query } })
                    .then(function (response) {
                    return response.data;
                });
            };
            AssignmentsService.$inject = ['$http', 'PermissionsService', 'options', 'Lookup', 'cache',];
            return AssignmentsService;
        }());
        services.AssignmentsService = AssignmentsService;
        angular.module('consolo').service('AssignmentsService', AssignmentsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('patients.assignments-and-defaults', {
                url: '/assignments',
                data: { title: 'Assignment Details' },
                views: {
                    'main-content@': {
                        template: '<assignments-index></assignments-index>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.assignments-and-defaults.new', {
                url: '/patient_record_defaults/new',
                data: { title: 'Patient Settings Details' },
                views: {
                    'main-content@': {
                        template: '<patient-record-default readonly="false"></patient-record-default>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.assignments-and-defaults.edit', {
                url: '/patient_record_defaults/:patientRecordDefaultId/edit',
                data: { title: 'Patient Settings Details' },
                views: {
                    'main-content@': {
                        template: '<patient-record-default readonly="false"></patient-record-default>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.assignments-and-defaults.view', {
                url: '/patient_record_defaults/:patientRecordDefaultId',
                data: { title: 'Patient Settings Details' },
                views: {
                    'main-content@': {
                        template: '<patient-record-default readonly="true"></patient-record-default>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.assignments-and-defaults.assignments-new', {
                url: '/new',
                data: { title: 'Patient Settings Details' },
                views: {
                    'main-content@': {
                        template: '<assignment readonly="false"></assignment>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.assignments-and-defaults.assignments-edit', {
                url: '/:assignmentId/edit',
                data: { title: 'Patient Settings Details' },
                views: {
                    'main-content@': {
                        template: '<assignment readonly="false"></assignment>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.assignments-and-defaults.assignments-view', {
                url: '/:assignmentId',
                data: { title: 'Patient Settings Details' },
                views: {
                    'main-content@': {
                        template: '<assignment readonly="false"></assignment>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('patientRecordDefault', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/assignments-and-defaults/patient-record-default.html',
            controller: 'PatientRecordDefaultController as $ctrl',
        });
        var PatientRecordDefaultController = /** @class */ (function () {
            function PatientRecordDefaultController(logger, Session, CurrentPatient, $state, $stateParams, PatientRecordDefaultsService, FiltersService, PharmacyService, PatientSidebarSections) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.PatientRecordDefaultsService = PatientRecordDefaultsService;
                this.FiltersService = FiltersService;
                this.PharmacyService = PharmacyService;
                this.PatientSidebarSections = PatientSidebarSections;
                this.saving = false;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                if ($stateParams.patientRecordDefaultId) {
                    this.PatientRecordDefaultsService.get($stateParams.patientRecordDefaultId).then(function (patientRecordDefault) {
                        _this.patientRecordDefault = patientRecordDefault;
                    });
                }
                else {
                    this.PatientRecordDefaultsService.getPatientCurrent(CurrentPatient.patient_id).then(function (patientRecordDefault) {
                        _this.patientRecordDefault = patientRecordDefault;
                        _this.patientRecordDefault.id = null;
                        _this.patientRecordDefault.effective_date = new Date(new Date().toDateString());
                    }),
                        function () {
                            _this.patientRecordDefault = {
                                patient_id: CurrentPatient.patient_id,
                                effective_date: new Date(new Date().toDateString()),
                            };
                        };
                }
            }
            PatientRecordDefaultController.prototype.save = function () {
                var _this = this;
                if (!this.patientRecordDefault.effective_date) {
                    this.logger.error('You must enter Effective Date.');
                    this.errors = ['You must enter Effective Date.'];
                }
                else if (!this.patientRecordDefault.mrn_generator_id &&
                    !this.patientRecordDefault.medical_record_no) {
                    this.logger.error('You must enter a MRN or select a MRN generator.');
                    this.errors = ['You must enter a MRN or select a MRN generator.'];
                }
                else if (!this.saving) {
                    this.errors = [];
                    if (!this.patientRecordDefault.patient_id) {
                        this.patientRecordDefault.patient_id = this.CurrentPatient.fetchingPatientId
                            ? this.CurrentPatient.fetchingPatientId
                            : this.CurrentPatient.patient_id;
                    }
                    this.saving = true;
                    if (this.patientRecordDefault.dme_provider) {
                        this.patientRecordDefault.dme_provider_id = this.patientRecordDefault.dme_provider.id;
                    }
                    if (this.patientRecordDefault.funeral_home) {
                        this.patientRecordDefault.funeral_home_id = this.patientRecordDefault.funeral_home.id;
                    }
                    if (this.patientRecordDefault.default_medication_physician) {
                        this.patientRecordDefault.default_medication_physician_id = this.patientRecordDefault.default_medication_physician.id;
                    }
                    this.PatientRecordDefaultsService.upsert(this.patientRecordDefault)
                        .then(function (patientRecordDefault) {
                        _this.CurrentPatient.refreshPatient().then(function () {
                            _this.PatientSidebarSections.loadPermissions().then(function () {
                                _this.PatientSidebarSections.loadSections();
                            });
                        });
                        _this.$state.go('patients.assignments-and-defaults', {
                            patientId: _this.patientRecordDefault.patient_id,
                        });
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            PatientRecordDefaultController.prototype.deselectDmeProvider = function (item) {
                if (!item) {
                    this.patientRecordDefault.dme_provider = null;
                    this.patientRecordDefault.dme_provider_id = null;
                }
            };
            PatientRecordDefaultController.prototype.deselectFuneralHome = function (item) {
                if (!item) {
                    this.patientRecordDefault.funeral_home = null;
                    this.patientRecordDefault.funeral_home_id = null;
                }
            };
            PatientRecordDefaultController.prototype.deselectDefaultPhysician = function (item) {
                if (!item) {
                    this.patientRecordDefault.default_medication_physician = null;
                    this.patientRecordDefault.default_medication_physician_id = null;
                }
            };
            PatientRecordDefaultController.$inject = [
                'logger',
                'SessionService',
                'CurrentPatient',
                '$state',
                '$stateParams',
                'PatientRecordDefaultsService',
                'FiltersService',
                'PharmacyService',
                'ConsoloPatientSectionManagerService',
            ];
            return PatientRecordDefaultController;
        }());
        common.PatientRecordDefaultController = PatientRecordDefaultController;
        angular
            .module('consolo')
            .controller('PatientRecordDefaultController', PatientRecordDefaultController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PatientRecordDefaultsService = /** @class */ (function () {
            function PatientRecordDefaultsService($http, PermissionsService, logger) {
                var _this = this;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.logger = logger;
                this.recordDefaultsApiUrl = '/api/v1/patient_record_defaults';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.$http.get('/api/v1/medical_record_number_generators').then(function (response) {
                    _this.medicalRecordNumberGenerators = response.data;
                });
                this.updatePermission = this.PermissionsService.can('update', 'patient_record_defaults');
                this.createPermission = this.PermissionsService.can('create', 'patient_record_defaults');
                this.deletePermission = this.PermissionsService.can('destroy', 'patient_record_defaults');
                this.viewPermission = this.PermissionsService.can('show', 'patient_record_defaults');
            }
            PatientRecordDefaultsService.prototype.delete = function (id) {
                return this.$http.delete(this.recordDefaultsApiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            PatientRecordDefaultsService.prototype.get = function (id) {
                return this.$http.get(this.recordDefaultsApiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            PatientRecordDefaultsService.prototype.getPatientCurrent = function (patientId) {
                return this.$http
                    .get(this.recordDefaultsApiUrl + '/current', { params: { patient_id: patientId } })
                    .then(function (response) {
                    return response.data;
                });
            };
            PatientRecordDefaultsService.prototype.getRecordDefaults = function (patientId) {
                return this.$http
                    .get(this.recordDefaultsApiUrl, { params: { patient_id: patientId } })
                    .then(function (response) {
                    return response.data;
                })
                    .catch(function (errors) {
                    return errors.data.errors;
                });
            };
            PatientRecordDefaultsService.prototype.lookupDmeProviders = function (q) {
                return this.$http
                    .get('/api/v1/lookup/services', { params: { 'types[]': ['DmeProvider'], q: q } })
                    .then(function (response) {
                    return response.data;
                });
            };
            PatientRecordDefaultsService.prototype.lookupFuneralHomes = function (q) {
                return this.$http
                    .get('/api/v1/lookup/services', { params: { 'types[]': ['FuneralHome'], q: q } })
                    .then(function (response) {
                    return response.data;
                });
            };
            PatientRecordDefaultsService.prototype.lookupPhysicians = function (q) {
                return this.$http.get('/api/v1/lookup/physicians', { params: { q: q } }).then(function (response) {
                    return response.data;
                });
            };
            PatientRecordDefaultsService.prototype.upsert = function (patientRecordDefault) {
                var _this = this;
                if (patientRecordDefault.id) {
                    return this.$http
                        .patch(this.recordDefaultsApiUrl + '/' + patientRecordDefault.id, patientRecordDefault)
                        .then(function (response) {
                        _this.logger.success('The Record Default has been updated.');
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.recordDefaultsApiUrl, patientRecordDefault).then(function (response) {
                        _this.logger.success('The Record Default has been created.');
                        return response.data;
                    });
                }
            };
            PatientRecordDefaultsService.$inject = ['$http', 'PermissionsService', 'logger'];
            return PatientRecordDefaultsService;
        }());
        services.PatientRecordDefaultsService = PatientRecordDefaultsService;
        angular
            .module('consolo')
            .service('PatientRecordDefaultsService', PatientRecordDefaultsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('agencyLocationSearch', ['Lookup', '$timeout', Directive]);
    function Directive(Lookup, $timeout) {
        return {
            restrict: 'E',
            scope: { selected: '=', onSelect: '&', placeholder: '@', limit: '@', focus: '=' },
            templateUrl: '/y/templates/autocomplete/remote_lookup.html',
            link: linkFunc,
        };
        /* jshint unused: false */
        function linkFunc($scope, el, attr, ctrl) {
            $scope.focused = $scope.focus;
            $scope.getResults = function (searchTerm) {
                return Lookup.agency_locations({ q: searchTerm, limit: $scope.limit }).$promise;
            };
            $scope.resultChanged = function (someString) {
                if (someString === '') {
                    $scope.selected = null;
                }
            };
            $scope.resultSelected = function ($item) {
                $scope.selected = $item;
                $timeout($scope.onSelect, 0);
            };
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').directive('consoloAutocomplete', ['$timeout', Directive]);
    /*
     * lookup: name of a method on the Lookup factory
     * on-select: callback that will be passed "item" - on-select="vm.select(item)"
     * placeholder: label/placeholder text
     * limit: max number of results to return
     * focus: focus this field
     * ng-model Optional. Will be set to "dirty" on changes.
     */
    function Directive($timeout) {
        return {
            restrict: 'E',
            require: '?ngModel',
            scope: {
                field: '=ngModel',
                lookup: '=',
                onSelect: '&',
                placeholder: '@',
                limit: '@',
                focus: '@',
                selectedItem: '=?',
                searchTerm: '=?',
                readonly: '=?',
                minLength: '=?',
                template: '@',
                isRequired: '=?',
                hospiceItemSet: '=?',
            },
            templateUrl: function (elem, attr) {
                var prefix = '/y/templates/autocomplete/consolo-autocomplete';
                var suffix = '.html';
                if (attr.template) {
                    return prefix + '-' + attr.template + suffix;
                }
                else {
                    return prefix + suffix;
                }
            },
            link: linkTo,
            controller: ['$scope', 'Lookup', 'cache', AutocompleteController],
            controllerAs: 'vm',
        };
        function AutocompleteController($scope, Lookup, cache) {
            var vm = $scope, query = Lookup[vm.lookup], cachedLookup = cache(Lookup, 'lookup', { force: true, readOnly: true });
            if (vm.minLength === undefined) {
                vm.minLength = 2;
            }
            vm.term = '';
            vm.getResults = getResults;
            activate();
            function activate() {
                if (typeof query !== 'function') {
                    console.error('consolo-autocomplete: "' + vm.lookup + '" is not a valid method on the Lookup factory');
                }
            }
            function getResults(term) {
                return cachedLookup.fetch(vm.lookup, { limit: -1 }).then(function (results) {
                    if (results) {
                        // All lookup results were pre-cached - filter then and return
                        var lowerTerm = term.toLowerCase();
                        return results.filter(function (r) {
                            return r.name.toLowerCase().search(lowerTerm) > -1;
                        });
                    }
                    else {
                        // Hit the server but *don't* cache results
                        return query({ q: term, limit: vm.limit }).$promise;
                    }
                }, function () {
                    console.warn('Unable to fetch cached results');
                    return query({ q: term, limit: vm.limit }).$promise;
                });
            }
        }
        function linkTo($scope, element, attrs, ngModel) {
            if ($scope.vm.focus === 'true') {
                element.find('input').focus();
            }
            if (ngModel) {
                $scope.$watch('field', function (newVal, oldVal) {
                    if (oldVal !== newVal) {
                        ngModel.$setDirty();
                    }
                });
            }
            $scope.select = function (item) {
                if (ngModel) {
                    ngModel.$setDirty();
                }
                $timeout(function () {
                    $scope.onSelect({ item: item });
                });
            };
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Lookup', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/lookup', null, {
            agency_locations: {
                method: 'GET',
                url: '/api/v1/lookup/agency_locations',
                isArray: true,
            },
            saved_locations: {
                method: 'GET',
                url: '/api/v1/lookup/saved_locations',
                isArray: true,
            },
            users: {
                method: 'GET',
                url: '/api/v1/lookup/users',
                isArray: true,
            },
            assigned_patients: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/patients/assigned_lookup',
            },
            patients: {
                method: 'GET',
                url: '/api/v1/patients',
                isArray: true,
            },
            facilities: {
                method: 'GET',
                url: '/api/v1/lookup/facilities',
                isArray: true,
            },
            pharmacies: {
                method: 'GET',
                url: '/api/v1/lookup/pharmacies',
                isArray: true,
            },
            fax_compliant_services: {
                method: 'GET',
                url: '/api/v1/lookup/fax_compliant_services',
                isArray: true,
            },
            physicians: {
                method: 'GET',
                url: '/api/v1/lookup/physicians',
                isArray: true,
            },
            non_physician_assistant_physicians: {
                method: 'GET',
                url: '/api/v1/lookup/non_physician_assistant_physicians',
                isArray: true,
            },
            priorities: {
                method: 'GET',
                url: '/api/v1/lookup/priorities',
                isArray: true,
            },
            meds: {
                method: 'GET',
                url: '/api/v1/medispan/meds',
                isArray: true,
            },
            med_names: {
                method: 'GET',
                url: '/api/v1/medispan/med_names',
                isArray: true,
                transformResponse: function (data) {
                    var t = [];
                    data = angular.fromJson(data);
                    data.forEach(function (item) {
                        t.push({ name: item });
                    });
                    return t;
                },
            },
            teams: {
                method: 'GET',
                url: '/api/v1/teams',
                isArray: true,
            },
            languages: {
                method: 'GET',
                url: '/api/v1/lookup/languages',
                isArray: true,
            },
            transportation_providers: {
                method: 'GET',
                url: '/api/v1/lookup/transportation_providers',
                isArray: true,
            },
            snomed_signs_and_symptoms: {
                method: 'GET',
                url: '/api/v1/lookup/snomed_signs_and_symptoms',
                isArray: true,
            },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('consoloPatientSearch', ['Lookup', '$timeout', PatientSearch]);
    function PatientSearch(Lookup, $timeout) {
        return {
            restrict: 'E',
            scope: {
                selected: '=',
                patientName: '=',
                onSelect: '&',
                placeholder: '@',
                limit: '@',
                focus: '=',
            },
            templateUrl: '/y/templates/autocomplete/remote_lookup.html',
            link: linkFunc,
        };
        /* jshint unused: false */
        function linkFunc($scope, el, attr, ctrl) {
            $scope.focused = $scope.focus;
            $scope.getResults = function (searchTerm) {
                return Lookup.patients({ q: searchTerm, limit: $scope.limit }).$promise;
            };
            $scope.resultChanged = function (someString) {
                if (someString === '') {
                    $scope.selected = null;
                }
            };
            $scope.resultSelected = function ($item) {
                $scope.selected = $item;
                $timeout($scope.onSelect, 0);
                // Using $timeout seems quite hackish, but was the best way I found:
                // http://stackoverflow.com/questions/24754005/how-to-implement-an-ng-change-for-a-custom-directive
            };
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('savedLocationSearch', ['Lookup', '$timeout', Directive]);
    function Directive(Lookup, $timeout) {
        return {
            restrict: 'E',
            scope: { selected: '=', onSelect: '&', placeholder: '@', limit: '@', focus: '=' },
            templateUrl: '/y/templates/autocomplete/remote_lookup.html',
            link: linkFunc,
        };
        /* jshint unused: false */
        function linkFunc($scope, el, attr, ctrl) {
            $scope.focused = $scope.focus;
            $scope.getResults = function (searchTerm) {
                return Lookup.saved_locations({ q: searchTerm, limit: $scope.limit }).$promise;
            };
            $scope.resultChanged = function (someString) {
                if (someString === '') {
                    $scope.selected = null;
                }
            };
            $scope.resultSelected = function ($item) {
                $scope.selected = $item;
                $timeout($scope.onSelect, 0);
            };
        }
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('billableCode', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/billable-codes/billable-code.html',
            controller: 'BillableCodeController as $ctrl',
        });
        var BillableCodeController = /** @class */ (function () {
            function BillableCodeController(PayerService, $state, PayerGroupService, BillableCodesService, logger, FilterSearchService, SidebarService) {
                var _this = this;
                this.PayerService = PayerService;
                this.$state = $state;
                this.PayerGroupService = PayerGroupService;
                this.BillableCodesService = BillableCodesService;
                this.logger = logger;
                this.FilterSearchService = FilterSearchService;
                this.SidebarService = SidebarService;
                this.saving = false;
                if ($state.params.billableCodeId) {
                    BillableCodesService.get($state.params.billableCodeId).then(function (billableCode) {
                        _this.billableCode = billableCode;
                        _this.billableCode.amount = Number(_this.billableCode.amount);
                    });
                }
                SidebarService.close();
            }
            BillableCodeController.prototype.clearOutPayers = function () {
                var _this = this;
                [
                    'medicare_payer',
                    'medicaid_payer',
                    'private_insurance_payer',
                    'charitable_care_payer',
                ].forEach(function (payerType) {
                    _this.billableCode[payerType + 's'] = [];
                    _this.billableCode[payerType + '_ids'] = [];
                });
            };
            BillableCodeController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.billableCode.code) {
                    this.errors.push('You must enter a code.');
                }
                if (!this.billableCode.insurance_carrier_id) {
                    this.errors.push('You must enter an insurance type.');
                }
                if (!this.billableCode.amount) {
                    this.errors.push('You must enter an amount.');
                }
                if (!this.saving && this.errors.length === 0) {
                    this.saving = true;
                    this.BillableCodesService.upsert(this.billableCode)
                        .then(function (response) {
                        _this.logger.success('The Billable Code has been saved.');
                        _this.$state.go('billable-codes');
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            BillableCodeController.$inject = [
                'PayerService',
                '$state',
                'PayerGroupService',
                'BillableCodesService',
                'logger',
                'FilterSearchService',
                'SidebarService',
            ];
            return BillableCodeController;
        }());
        common.BillableCodeController = BillableCodeController;
        angular.module('consolo').controller('BillableCodeController', BillableCodeController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var BillableCodesDataTableService = /** @class */ (function (_super) {
            __extends(BillableCodesDataTableService, _super);
            function BillableCodesDataTableService($http, $interval, BillableCodesService, $state, logger) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.BillableCodesService = BillableCodesService;
                _this.$state = $state;
                _this.logger = logger;
                _this.loadingData = false;
                _this.title = 'Billable Codes';
                _this.showFilters = true;
                _this.hideSearchTerm = true;
                _this.searchOnReset = true;
                _this.activate();
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        var billableCodes = data.data.map(function (code) {
                            return new consolo.domain.BillableCode(code);
                        });
                        _this.data = billableCodes;
                        return billableCodes;
                    });
                };
                return _this;
            }
            BillableCodesDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/billable_codes';
                this.filters = new consolo.domain.BillableCodesFilter();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Code',
                            field: 'code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Amount',
                            field: 'amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (billableCode) {
                                return consolo.common.moneyDisplay(billableCode.amount);
                            },
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            type: 'short-text',
                        },
                        {
                            title: 'Payer Type',
                            field: 'insurance_carrier.name',
                            type: 'short-text',
                        },
                        {
                            title: 'Payer Name(s)',
                            field: 'insurance',
                            type: 'short-text',
                            formatValueFn: function (billableCode) {
                                var payers = billableCode.getPayers();
                                if (payers && payers.length) {
                                    return payers
                                        .map(function (payer) {
                                        return payer.name;
                                    })
                                        .join(', ');
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                        {
                            title: 'Office(s)',
                            field: 'offices',
                            type: 'short-text',
                            formatValueFn: function (billableCode) {
                                if (billableCode.offices && billableCode.offices.length) {
                                    return billableCode.offices
                                        .map(function (office) {
                                        return office.name;
                                    })
                                        .join(', ');
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (billableCode) {
                                    return _this.$state.go('billable-codes.edit', { billableCodeId: billableCode.id });
                                },
                                conditionalHideFn: function (billableCode) {
                                    return !_this.BillableCodesService.updatePermission;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (billableCode) {
                                    return _this.$state.go('billable-codes.view', { billableCodeId: billableCode.id });
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (billableCode) {
                                    return _this.BillableCodesService.delete(billableCode)
                                        .then(function (deletedPhysicianCharge) {
                                        _this.search();
                                        _this.logger.success('The code has been deleted.');
                                    })
                                        .catch(function (errors) {
                                        _this.logger.error('There were errors encountered');
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.BillableCodesService.deletePermission;
                                },
                            },
                        ],
                    },
                };
            };
            BillableCodesDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.params = this.filters.getFilters();
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                            _this.loadingData = true;
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            BillableCodesDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.BillableCodesFilter();
                this.search();
            };
            BillableCodesDataTableService.$inject = ['$http', '$interval', 'BillableCodesService', '$state', 'logger'];
            return BillableCodesDataTableService;
        }(csg.datatable.ApiService));
        services.BillableCodesDataTableService = BillableCodesDataTableService;
        angular
            .module('consolo')
            .service('BillableCodesDataTableService', BillableCodesDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('billableCodes', {
            bindings: {},
            templateUrl: '/y/templates/billable-codes/billable-codes.html',
            controller: 'BillableCodesIndexController as $ctrl',
        });
        var BillableCodesIndexController = /** @class */ (function () {
            function BillableCodesIndexController(BillableCodesDataTableService, BillableCodesService, S3UploadFactory, UploadQueue, CSV, logger) {
                this.BillableCodesDataTableService = BillableCodesDataTableService;
                this.BillableCodesService = BillableCodesService;
                this.S3UploadFactory = S3UploadFactory;
                this.UploadQueue = UploadQueue;
                this.CSV = CSV;
                this.logger = logger;
                this.saving = false;
                this.showImport = false;
                this.completeStatus = consolo.uploads.UploadState.Complete;
            }
            BillableCodesIndexController.prototype.handleFiles = function (files) {
                var _this = this;
                files.forEach(function (file) {
                    // TODO throw warning if file too large?
                    var upload = _this.S3UploadFactory.build(file);
                    _this.UploadQueue.push(upload);
                    _this.upload = upload;
                });
            };
            BillableCodesIndexController.prototype.cancel = function () {
                this.saving = false;
                this.upload = null;
            };
            BillableCodesIndexController.prototype.csvTemplate = function () {
                var csv = 'data:text/csv;charset-utf-8,' +
                    this.CSV.generate([], ['Code', 'Amount', 'Payer Type', 'Payer Name', 'Description']);
                var data = encodeURI(csv);
                var link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', 'BillableCodesImport.csv');
                link.click();
            };
            BillableCodesIndexController.prototype.import = function () {
                var _this = this;
                if (this.saving) {
                    return;
                }
                else {
                    this.saving = true;
                    this.errors = null;
                    this.upload.promise.then(function () {
                        var fileKey = _this.upload.s3Key;
                        _this.BillableCodesService.import(fileKey)
                            .then(function (resp) {
                            _this.logger.success('Your import has been queued up to import.  You will receive a notification when complete.');
                            _this.showImport = false;
                        }, function (resp) {
                            _this.logger.error(resp.data.errors.join(', '));
                        })
                            .finally(function () {
                            _this.upload = null;
                            _this.saving = false;
                        });
                    });
                }
            };
            BillableCodesIndexController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            BillableCodesIndexController.$inject = [
                'BillableCodesDataTableService',
                'BillableCodesService',
                'S3UploadFactory',
                'UploadQueue',
                'CSV',
                'logger',
            ];
            return BillableCodesIndexController;
        }());
        common.BillableCodesIndexController = BillableCodesIndexController;
        angular
            .module('consolo')
            .controller('BillableCodesIndexController', BillableCodesIndexController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var BillableCodesService = /** @class */ (function () {
            function BillableCodesService($http, PermissionsService, $mdDialog) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.$mdDialog = $mdDialog;
                this.apiUrl = '/api/v1/cpt_codes';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.updatePermission = this.PermissionsService.can('update', 'billable_codes');
                this.createPermission = this.PermissionsService.can('create', 'billable_codes');
                this.deletePermission = this.PermissionsService.can('destroy', 'billable_codes');
                this.viewPermission = this.PermissionsService.can('show', 'billable_codes');
            }
            BillableCodesService.prototype.delete = function (billableCode) {
                return this.$http.delete(this.apiUrl + '/' + billableCode.id).then(function (response) {
                    return new consolo.domain.BillableCode(response.data);
                });
            };
            BillableCodesService.prototype.upsert = function (billableCode) {
                if (billableCode.id) {
                    return this.$http
                        .patch(this.apiUrl + '/' + billableCode.id, billableCode)
                        .then(function (response) {
                        return new consolo.domain.BillableCode(response.data);
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, billableCode).then(function (response) {
                        return new consolo.domain.BillableCode(response.data);
                    });
                }
            };
            BillableCodesService.prototype.import = function (s3Key) {
                return this.$http.post(this.apiUrl + '/import', { s3_tmp_key: s3Key }).then(function (response) {
                    return response.data;
                });
            };
            BillableCodesService.prototype.lookupCodes = function (q, patientId, effectiveDate) {
                var params = { q: q };
                if (patientId) {
                    params['patient_id'] = patientId;
                }
                if (effectiveDate) {
                    params['effective_date'] = effectiveDate;
                }
                return this.$http.get(this.apiUrl, { params: params }).then(function (response) {
                    return response.data.map(function (code) {
                        return new consolo.domain.BillableCode(code);
                    });
                });
            };
            BillableCodesService.prototype.lookupCharitableCareCodes = function (q, charitable_care_payer_id) {
                return this.$http
                    .get(this.apiUrl, {
                    params: { q: q, charitable_care_payer_id: charitable_care_payer_id },
                })
                    .then(function (response) {
                    return response.data.map(function (code) {
                        return new consolo.domain.BillableCode(code);
                    });
                });
            };
            BillableCodesService.prototype.lookupMedicareCodes = function (q, medicare_payer_id) {
                return this.$http
                    .get(this.apiUrl, { params: { q: q, medicare_payer_id: medicare_payer_id } })
                    .then(function (response) {
                    return response.data.map(function (code) {
                        return new consolo.domain.BillableCode(code);
                    });
                });
            };
            BillableCodesService.prototype.lookupMedicaidCodes = function (q, medicaid_payer_id) {
                return this.$http
                    .get(this.apiUrl, { params: { q: q, medicaid_payer_id: medicaid_payer_id } })
                    .then(function (response) {
                    return response.data.map(function (code) {
                        return new consolo.domain.BillableCode(code);
                    });
                });
            };
            BillableCodesService.prototype.lookupPrivateInsuranceCodes = function (q, private_insurance_payer_id) {
                return this.$http
                    .get(this.apiUrl, {
                    params: { q: q, private_insurance_payer_id: private_insurance_payer_id },
                })
                    .then(function (response) {
                    return response.data.map(function (code) {
                        return new consolo.domain.BillableCode(code);
                    });
                });
            };
            BillableCodesService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    return new consolo.domain.BillableCode(response.data);
                });
            };
            BillableCodesService.prototype.getCategories = function (patientId) {
                return this.$http.get(this.apiUrl + '/categories', { params: { patientId: patientId } }).then(function (response) {
                    return response.data;
                });
            };
            BillableCodesService.$inject = ['$http', 'PermissionsService', '$mdDialog'];
            return BillableCodesService;
        }());
        services.BillableCodesService = BillableCodesService;
        angular.module('consolo').service('BillableCodesService', BillableCodesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('cpt-codes', {
                url: '/billable_codes',
                data: { title: 'Billable Codes', hideSidebar: false },
                views: {
                    'main-content@': {
                        template: '<cpt-codes></cpt-codes>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.CptCodesDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
            })
                .state('cpt-codes.new', {
                url: '/new',
                data: { title: 'New Billable Code', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<cpt-code readonly="false"><cpt-code>',
                    },
                },
            })
                .state('cpt-codes.edit', {
                url: '/:cptCodeId/edit',
                data: { title: 'Edit Billable Code', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<cpt-code readonly="false"><cpt-code>',
                    },
                },
            })
                .state('cpt-codes.view', {
                url: '/:cptCodeId',
                data: { title: 'View Billable Code', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<cpt-code readonly="true"><cpt-code>',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CahpsTrackingFilter = /** @class */ (function () {
            function CahpsTrackingFilter() {
            }
            return CahpsTrackingFilter;
        }());
        services.CahpsTrackingFilter = CahpsTrackingFilter;
        angular.module('consolo').service('CahpsTrackingFilter', CahpsTrackingFilter);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CahpsTrackingFilterService = /** @class */ (function () {
            function CahpsTrackingFilterService(cahpsTrackingService, filter, $rootScope) {
                this.cahpsTrackingService = cahpsTrackingService;
                this.filter = filter;
                this.$rootScope = $rootScope;
                this.updateCahps = 0;
            }
            // Set the patients and let the main controller know it needs to update it's patients
            CahpsTrackingFilterService.prototype.setCahpsData = function () {
                var _this = this;
                this.cahpsTrackingService.get().query(this.filter, function (data) {
                    _this.cahps = data;
                    _this.updateCahps += 1;
                });
            };
            //Function for the main controller to call and get the current filtered patients
            CahpsTrackingFilterService.prototype.getCahps = function () {
                return this.cahps;
            };
            CahpsTrackingFilterService.$inject = ['CahpsTrackingService', 'CahpsTrackingFilter', '$rootScope'];
            return CahpsTrackingFilterService;
        }());
        services.CahpsTrackingFilterService = CahpsTrackingFilterService;
        angular.module('consolo').service('CahpsTrackingFilterService', CahpsTrackingFilterService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CahpsTrackingSidebarController = /** @class */ (function () {
            function CahpsTrackingSidebarController(filterService) {
                this.filterService = filterService;
            }
            CahpsTrackingSidebarController.prototype.filter = function () {
                this.filterService.setCahpsData();
            };
            CahpsTrackingSidebarController.$inject = ['CahpsTrackingFilterService'];
            return CahpsTrackingSidebarController;
        }());
        angular
            .module('consolo')
            .controller('CahpsTrackingSidebarController', CahpsTrackingSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CahpsTrackingController = /** @class */ (function () {
            function CahpsTrackingController(filterService, $scope) {
                var _this = this;
                this.filterService = filterService;
                this.$scope = $scope;
                $scope.$watch('vm.filterService.updateCahps', function () {
                    _this.cahpsData = filterService.getCahps();
                });
            }
            CahpsTrackingController.$inject = ['CahpsTrackingFilterService', '$scope'];
            return CahpsTrackingController;
        }());
        angular.module('consolo').controller('CahpsTrackingController', CahpsTrackingController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var about;
    About.$inject = ['AboutService'];
    function About(AboutService) {
        AboutService.get().then(function (a) {
            about = a;
        });
    }
    var CahpsTrackingState = /** @class */ (function () {
        function CahpsTrackingState($stateProvider) {
            $stateProvider.state('cahps_tracking', {
                url: '/cahps_tracking',
                data: { title: 'CAHPS Survey History' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/cahps-tracking/main.html',
                        controller: 'CahpsTrackingController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/cahps-tracking/cahps-tracking-sidebar.html',
                        controller: 'CahpsTrackingSidebarController as vm',
                    },
                },
            });
        }
        CahpsTrackingState.$inject = ['$stateProvider'];
        return CahpsTrackingState;
    }());
    angular
        .module('consolo')
        .config(CahpsTrackingState)
        .run(About);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var CarePlanProblemCurrentController = /** @class */ (function () {
            function CarePlanProblemCurrentController($stateParams, Session, CurrentPatient, $state, CarePlanProblemsService, CarePlanProblemFilterService, $mdDialog, logger, $scope) {
                var _this = this;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.$state = $state;
                this.CarePlanProblemsService = CarePlanProblemsService;
                this.CarePlanProblemFilterService = CarePlanProblemFilterService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.$scope = $scope;
                this.CarePlanProblemsService.isLoading = true;
                this.patientId = $stateParams.patientId;
                this.user_dashboard_url = this.Session.getUser().user_dashboard_url;
                this.patient = this.CurrentPatient.patient;
                this.cppId = $stateParams.id;
                this.currentTab = $state.current.data.tabIndex;
                this.CarePlanProblemsService.getAllPatientCPPs(this.patientId).then(function (cpps) {
                    _this.problems = cpps;
                    _this.activeProblems = _this.problems.filter(function (problem) { return problem.care_plan_status === "Active"; });
                    _this.expiredProblems = _this.problems.filter(function (problem) { return problem.care_plan_status === "Expired"; });
                    _this.initLayouts();
                    _this.CarePlanProblemsService.isLoading = false;
                }).catch(function (error) {
                    console.log(error);
                    _this.CarePlanProblemsService.isLoading = false;
                });
            }
            CarePlanProblemCurrentController.prototype.initLayouts = function () {
                this.interventionsLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Due', field: 'due_date', canSort: true, type: csg.datatable.ColumnTypes.date },
                        {
                            title: 'Dates Active', field: 'date_range',
                            formatValueFn: function (cpp) {
                                return (cpp.start_date ? consolo.common.dateFormatMonthDayYear(cpp.start_date) : " ") +
                                    " - " +
                                    (cpp.end_date ? consolo.common.dateFormatMonthDayYear(cpp.end_date) : " ");
                            }, canSort: true
                        },
                        { title: 'Associated Section', field: 'chart_section_display', canSort: true },
                        { title: 'Updated', field: 'created_at', canSort: true, type: csg.datatable.ColumnTypes.dateTime },
                    ],
                };
                this.updateInterventionsLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Due', field: 'due_date', canSort: true, type: csg.datatable.ColumnTypes.date },
                        { title: 'Action Performed?', field: 'action_performed', canSort: true, type: csg.datatable.ColumnTypes.boolean },
                        { title: 'Outcome', field: 'outcome', canSort: true },
                        { title: 'P/F Engagement', field: 'patient_family_engagement', canSort: true },
                        { title: 'Resolved?', field: 'resolved', canSort: true, type: csg.datatable.ColumnTypes.boolean },
                        { title: 'Comments', field: 'comments', canSort: true },
                    ],
                };
            };
            CarePlanProblemCurrentController.prototype.addChartEntry = function (patient) {
                this.$state.go('patients.clinical-chart-entry-new.section', {
                    patientId: patient.id,
                    sectionName: 'general',
                });
            };
            CarePlanProblemCurrentController.$inject = [
                '$stateParams',
                'SessionService',
                'CurrentPatient',
                '$state',
                'CarePlanProblemsService',
                'CarePlanProblemFilterService',
                '$mdDialog',
                'logger',
                '$scope'
            ];
            return CarePlanProblemCurrentController;
        }());
        angular
            .module('consolo')
            .controller('CarePlanProblemCurrentController', CarePlanProblemCurrentController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CarePlanProblemDataTableService = /** @class */ (function (_super) {
            __extends(CarePlanProblemDataTableService, _super);
            function CarePlanProblemDataTableService($httpService, $interval, CarePlanProblemFilterService, $state, $filter, CarePlanProblemsService, $mdDialog, logger, SectionManagerService, Session, PersistedItem, ClinicalChartEntry, ReleaseVersion) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.$interval = $interval;
                _this.CarePlanProblemFilterService = CarePlanProblemFilterService;
                _this.$state = $state;
                _this.$filter = $filter;
                _this.CarePlanProblemsService = CarePlanProblemsService;
                _this.$mdDialog = $mdDialog;
                _this.logger = logger;
                _this.SectionManagerService = SectionManagerService;
                _this.Session = Session;
                _this.PersistedItem = PersistedItem;
                _this.ClinicalChartEntry = ClinicalChartEntry;
                _this.ReleaseVersion = ReleaseVersion;
                _this.activate();
                return _this;
            }
            CarePlanProblemDataTableService.prototype.activate = function () {
                var _this = this;
                this.params = this.CarePlanProblemFilterService.filter;
                this.apiSelectUrl = '/api/v2/care_plan_problem_search';
                this.layout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (cpp) { return _this.$state.go('patients.care-plan-problem.view', { patientId: cpp.patient_id, id: cpp.id }); },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (cpp) { return _this.$state.go('patients.care-plan-problem.edit', { patientId: cpp.patient_id, id: cpp.id }); },
                                conditionalHideFn: function (cpp) {
                                    return !cpp.can_edit;
                                },
                            },
                            {
                                label: 'Discontinue',
                                icon: 'cancel',
                                callbackFn: function (cpp, item) { return _this.$state.go('patients.care-plan-problem.discontinue', { patientId: cpp.patient_id, id: cpp.id }); },
                                conditionalHideFn: function (cpp) {
                                    return !cpp.can_edit || cpp.care_plan_status === 'Inactive';
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (cpp, item) { return _this.removeCPP(cpp, item); },
                                conditionalHideFn: function (cpp) {
                                    return !cpp.can_delete;
                                },
                            },
                            {
                                label: 'Print',
                                icon: 'print',
                                callbackFn: function (cpp) { return _this.CarePlanProblemsService.createCppPDF(cpp.id, cpp.patient_id); },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Patient', field: 'name', canSort: true,
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({patientId: $patient_id})',
                        },
                        { title: 'Problem', field: 'problem', canSort: true },
                        {
                            title: 'Disciplines',
                            field: 'disciplines',
                            type: csg.datatable.ColumnTypes.medium_text
                        },
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true
                        },
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true
                        },
                        {
                            title: 'End Date', field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true
                        },
                        {
                            title: 'Last Updated', field: 'last_updated',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true
                        },
                        {
                            title: 'Status', field: 'care_plan_status',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: false,
                            cellCssClassFn: function (cpp) {
                                if (cpp.care_plan_status === 'Active') {
                                    return 'cpp-active';
                                }
                                else if (cpp.care_plan_status === 'Inactive') {
                                    return 'cpp-inactive';
                                }
                                else {
                                    return 'cpp-expired';
                                }
                            },
                        },
                    ],
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Additional Information',
                                    field: 'cpp.interventions',
                                    type: csg.datatable.ColumnTypes.long_text,
                                    formatValueFn: function (cpp) {
                                        var subTableHtml = '';
                                        subTableHtml = "<div class='cpp-subtable'>" +
                                            _this.additionalInfoTable(cpp) +
                                            _this.interventionsTable(cpp) +
                                            "</div>";
                                        return subTableHtml;
                                    },
                                },
                            ],
                        },
                    ],
                };
            };
            CarePlanProblemDataTableService.prototype.additionalInfoTable = function (cpp) {
                return "<div class='additional-info-container'>" +
                    "<h3>Additional Information</h3>" +
                    "<div class='sub-header'>" +
                    "<div>Participants</div>" +
                    "<div>Office</div>" +
                    "<div>Source</div>" +
                    "<div>Category</div>" +
                    "<div>Outcome</div>" +
                    "<div>P/F Engagement</div>" +
                    "</div>" +
                    "<div class='sub-row'>" +
                    ("<div>" + cpp.participants.map(function (participant) {
                        return participant.description;
                    }) + "</div>") +
                    ("<div>" + cpp.office_name + "</div>") +
                    ("<div>" + cpp.source + "</div>") +
                    ("<div>" + (cpp.category ? cpp.category : " ") + "</div>") +
                    ("<div>" + (cpp.outcome ? cpp.outcome : " ") + "</div>") +
                    ("<div>" + (cpp.patient_family_engagement ? cpp.patient_family_engagement : " ") + "</div>") +
                    "</div>" +
                    "</div>";
            };
            CarePlanProblemDataTableService.prototype.interventionsTable = function (cpp) {
                var _this = this;
                if (cpp.interventions.length > 0) {
                    return "<div class='intervention-container'>" +
                        "<h3>Interventions</h3>" +
                        "<div class='sub-header'>" +
                        "<div>Name</div>" +
                        "<div>Due</div>" +
                        "<div>Dates Active</div>" +
                        "<div>Associated Sections</div>" +
                        "<div>Updated</div>" +
                        "<div>Comments</div>" +
                        "</div>" +
                        "<div class='body'>" +
                        cpp.interventions.map(function (intervention) {
                            return "<div class='sub-row'>" +
                                ("<div>" + intervention.name + "</div>") +
                                ("<div>" + (intervention.due_date ? consolo.common.dateFormatMonthDayYear(intervention.due_date) : " ") + "</div>") +
                                ("<div>" + (intervention.start_date ?
                                    consolo.common.dateFormatMonthDayYear(intervention.start_date)
                                    : " ") + " - " + (intervention.end_date ? consolo.common.dateFormatMonthDayYear(intervention.end_date) : " ") + "</div>") +
                                ("<div>" + (intervention.chart_section_display ? intervention.chart_section_display : " ") + "</div>") +
                                ("<div>" + (intervention.updated_at ? _this.$filter('date')(new Date(intervention.updated_at), 'MM/dd/yyyy h:mm a') : " ") + "</div>") +
                                ("<div>" + (intervention.comments ? intervention.comments : " ") + "</div>") +
                                "</div>";
                        }) +
                        "</div>" +
                        "</div>";
                }
                else {
                    return "";
                }
            };
            CarePlanProblemDataTableService.prototype.removeCPP = function (cpp, item) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to delete this care plan problem?')
                    .ariaLabel('Remove Care Plan Problem Confirmation')
                    .ok('Remove Care Plan Problem')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.CarePlanProblemsService.isLoading = true;
                    _this.CarePlanProblemsService.deleteCPP(cpp.id).then(function (response) {
                        _this.CarePlanProblemsService.isLoading = false;
                        _this.SectionManagerService.refreshMenu(response.patient_id); // Refresh Thumbs up/down for CPPs
                        _this.search();
                        _this.logger.success('You successfully deleted ' + cpp.problem, 'Success:');
                    }).catch(function (error) {
                        _this.CarePlanProblemsService.isLoading = false;
                        _this.logger.error(error.errors.join(', '), 'Error:');
                    });
                });
            };
            CarePlanProblemDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.params = this.CarePlanProblemFilterService.filter;
                    this.loadingData = true;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            CarePlanProblemDataTableService.prototype.reset = function () {
                this.CarePlanProblemFilterService.clearFilters();
                this.search();
            };
            CarePlanProblemDataTableService.$inject = [
                '$http',
                '$interval',
                'CarePlanProblemFilterService',
                '$state',
                '$filter',
                'CarePlanProblemsService',
                '$mdDialog',
                'logger',
                'ConsoloPatientSectionManagerService',
                'SessionService',
                'PersistedItem',
                'ClinicalChartEntry',
                'EmrWebRelease',
            ];
            return CarePlanProblemDataTableService;
        }(csg.datatable.ApiService));
        services.CarePlanProblemDataTableService = CarePlanProblemDataTableService;
        angular.module('consolo').service('CarePlanProblemDataTableService', CarePlanProblemDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CarePlanProblemFilterService = /** @class */ (function () {
            function CarePlanProblemFilterService(OfficeService, options, SidebarService) {
                var _this = this;
                this.OfficeService = OfficeService;
                this.options = options;
                this.SidebarService = SidebarService;
                this.searchTerm = "";
                this.carePlanStatuses = [
                    'Active',
                    'Inactive',
                    'Expired',
                ];
                this.filter = {
                    office_id: null,
                    discipline: '',
                    care_plan_source: '',
                    care_plan_outcome: '',
                    care_plan_status: '',
                    patient_id: null,
                    sort_by: 'name',
                    sort_dir: csg.datatable.ISort.asc,
                    page: 1,
                    page_size: 50,
                };
                this.OfficeService.get().then(function (offices) {
                    _this.offices = offices.data;
                    _this.disciplines = _this.options.get('base_roles');
                    _this.carePlanSources = _this.options.get('care_plan_template_sources');
                    _this.carePlanOutcomes = _this.options.get('outcomes');
                });
            }
            CarePlanProblemFilterService.prototype.clearFilters = function () {
                this.filter = {
                    office_id: null,
                    discipline: '',
                    care_plan_source: '',
                    care_plan_outcome: '',
                    care_plan_status: '',
                    patient_id: !this.searchTerm ? this.filter.patient_id : null,
                    sort_by: this.filter.patient_id ? 'last_updated' : 'name',
                    sort_dir: this.filter.patient_id ? csg.datatable.ISort.desc : csg.datatable.ISort.asc,
                    page: 1,
                    page_size: 50,
                };
                this.searchTerm = "";
            };
            CarePlanProblemFilterService.prototype.toggleSidebar = function () {
                this.SidebarService.toggle();
            };
            CarePlanProblemFilterService.$inject = ['officeService', 'options', 'SidebarService'];
            return CarePlanProblemFilterService;
        }());
        services.CarePlanProblemFilterService = CarePlanProblemFilterService;
        angular.module('consolo').service('CarePlanProblemFilterService', CarePlanProblemFilterService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ViewCarePlanProblemComponent = /** @class */ (function () {
            function ViewCarePlanProblemComponent() {
                this.templateUrl = '/y/templates/care-plans/care-plan-problem-history.html';
                this.controller = CarePlanProblemHistoryController;
                this.controllerAs = 'vm';
                this.bindings = {
                    carePlanProblemId: '<',
                    patientId: '<',
                    isPartial: '<',
                };
            }
            return ViewCarePlanProblemComponent;
        }());
        var CarePlanProblemHistoryController = /** @class */ (function () {
            function CarePlanProblemHistoryController($stateParams, Session, CurrentPatient, $state, CarePlanProblemsService, CarePlanProblemFilterService, $mdDialog, logger, $scope) {
                var _this = this;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.$state = $state;
                this.CarePlanProblemsService = CarePlanProblemsService;
                this.CarePlanProblemFilterService = CarePlanProblemFilterService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.$scope = $scope;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.patientId = this.patientId || $stateParams.patientId;
                this.patient = this.CurrentPatient.patient;
                this.cppId = this.carePlanProblemId || $stateParams.id;
                this.print = $stateParams.print;
                console.log(this.print, $stateParams);
                this.setCurrentView(this.$state.current.name);
                this.CarePlanProblemsService.getCPPHistory(this.cppId)
                    .then(function (cpp) {
                    _this.problem = cpp;
                    if (_this.print) {
                        for (var _i = 0, _a = _this.problem.care_plan_problem_updates; _i < _a.length; _i++) {
                            var i = _a[_i];
                            i['toggle'] = true;
                        }
                    }
                    _this.initLayouts();
                })
                    .catch(function (error) {
                    console.log(error);
                });
            }
            CarePlanProblemHistoryController.prototype.initLayouts = function () {
                this.interventionsLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Due', field: 'due_date', canSort: true, type: csg.datatable.ColumnTypes.date },
                        {
                            title: 'Dates Active',
                            field: 'date_range',
                            formatValueFn: function (cpp) {
                                return ((cpp.start_date ? consolo.common.dateFormatMonthDayYear(cpp.start_date) : ' ') +
                                    ' - ' +
                                    (cpp.end_date ? consolo.common.dateFormatMonthDayYear(cpp.end_date) : ' '));
                            },
                            canSort: true,
                        },
                        { title: 'Associated Sections', field: 'chart_section_display', canSort: true },
                        {
                            title: 'Updated',
                            field: 'updated_at',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                    ],
                };
                this.updatesLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'Created By', field: 'created_by_name', canSort: true },
                        { title: 'Outcome', field: 'outcome', canSort: true },
                        { title: 'P/F Engagement', field: 'patient_family_engagement', canSort: true },
                        {
                            title: 'Resolved?',
                            field: 'resolved',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        { title: 'Comments', field: 'comments', canSort: true },
                    ],
                };
                this.updateInterventionsLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Due', field: 'due_date', canSort: true, type: csg.datatable.ColumnTypes.date },
                        {
                            title: 'Action Performed?',
                            field: 'action_performed',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        { title: 'Outcome', field: 'outcome', canSort: true },
                        { title: 'P/F Engagement', field: 'patient_family_engagement', canSort: true },
                        {
                            title: 'Resolved?',
                            field: 'resolved',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        { title: 'Comments', field: 'comments', canSort: true },
                    ],
                };
            };
            CarePlanProblemHistoryController.prototype.setCurrentView = function (viewName) {
                if (viewName === 'patients.care-plan-problem.view') {
                    this.currentView = 'view';
                }
                else if (viewName === 'patients.care-plan-problem.history') {
                    this.currentView = 'history';
                }
            };
            CarePlanProblemHistoryController.prototype.addChartEntry = function (patient) {
                this.$state.go('patients.clinical-chart-entry-new.section', {
                    patientId: patient.id,
                    sectionName: 'general',
                });
            };
            CarePlanProblemHistoryController.$inject = [
                '$stateParams',
                'SessionService',
                'CurrentPatient',
                '$state',
                'CarePlanProblemsService',
                'CarePlanProblemFilterService',
                '$mdDialog',
                'logger',
                '$scope',
            ];
            return CarePlanProblemHistoryController;
        }());
        angular
            .module('consolo')
            .component('viewCarePlanProblem', new ViewCarePlanProblemComponent);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CarePlanProblemIntervention = /** @class */ (function () {
            function CarePlanProblemIntervention(id, name, due_date, uuid, chart_section, ordinal, name_editable) {
                this.id = id;
                this.name = name;
                this.due_date = due_date;
                this.uuid = uuid;
                this.chart_section = chart_section;
                this.ordinal = ordinal;
                this.name_editable = name_editable;
            }
            return CarePlanProblemIntervention;
        }());
        domain.CarePlanProblemIntervention = CarePlanProblemIntervention;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var CarePlanProblemListController = /** @class */ (function () {
            function CarePlanProblemListController($stateParams, Session, CurrentPatient, $state, CarePlanProblemsService, CarePlanProblemFilterService, $mdDialog, $scope, CarePlanProblemDataTableService, $mdMedia) {
                var _this = this;
                this.$stateParams = $stateParams;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.$state = $state;
                this.CarePlanProblemsService = CarePlanProblemsService;
                this.CarePlanProblemFilterService = CarePlanProblemFilterService;
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                this.CarePlanProblemDataTableService = CarePlanProblemDataTableService;
                this.$mdMedia = $mdMedia;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.patientId = this.$stateParams.patientId;
                this.problems = [];
                if (this.patientId) {
                    this.CarePlanProblemFilterService.filter.patient_id = this.patientId;
                    this.CarePlanProblemFilterService.filter.sort_by = 'last_updated';
                    this.CarePlanProblemFilterService.filter.sort_dir = csg.datatable.ISort.desc;
                    this.patient = this.CurrentPatient.patient;
                }
                this.CarePlanProblemDataTableService.activate();
                this.$scope.$on('$destroy', function () {
                    _this.CarePlanProblemFilterService.filter.patient_id = null;
                    _this.CarePlanProblemFilterService.clearFilters();
                });
            }
            CarePlanProblemListController.prototype.viewCarePlanProblem = function ($event, cpp) {
                this.showDialog($event, cpp);
            };
            CarePlanProblemListController.prototype.showDialog = function ($event, cpp) {
                var url = '/y/templates/care-plans/care-plan-problem.html'; //tslint ignore:line
                return this.$mdDialog.show({
                    targetEvent: $event,
                    template: "\n          <md-dialog flex=\"80\" aria-label=\"Care Plan Problem Dialog\">\n            <md-dialog-content>\n             <div ng-include=\"'" + url + "'\"/>\n            </md-dialog-content>\n          </md-dialog>\n        ",
                    controller: 'CarePlanProblemController as vm',
                    bindToController: true,
                    locals: { problem: cpp, modal: true },
                    fullscreen: true,
                });
            };
            CarePlanProblemListController.prototype.addChartEntry = function (patient) {
                this.$state.go('patients.clinical-chart-entry-new.section', {
                    patientId: patient.id,
                    sectionName: 'general',
                });
            };
            CarePlanProblemListController.$inject = [
                '$stateParams',
                'SessionService',
                'CurrentPatient',
                '$state',
                'CarePlanProblemsService',
                'CarePlanProblemFilterService',
                '$mdDialog',
                '$scope',
                'CarePlanProblemDataTableService',
                '$mdMedia'
            ];
            return CarePlanProblemListController;
        }());
        angular
            .module('consolo')
            .controller('CarePlanProblemListController', CarePlanProblemListController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CarePlanProblemModalController = /** @class */ (function () {
            function CarePlanProblemModalController($mdDialog, ChartingSectionManager, CarePlanTemplate, cache, intervention, templateId, ChartingTemplateService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.ChartingSectionManager = ChartingSectionManager;
                this.CarePlanTemplate = CarePlanTemplate;
                this.cache = cache;
                this.intervention = intervention;
                this.templateId = templateId;
                this.ChartingTemplateService = ChartingTemplateService;
                this.sectionOptions = [];
                this.templateInterventions = [];
                this.intervention = this.intervention || { name_editable: true };
                this.sectionOptions = __spreadArrays([
                    { name: null, title: '' }
                ], this.ChartingTemplateService.getScreens('tablet_optimized'));
                this.sectionOptions = this.sectionOptions.filter(function (section) { return !section.deprecated; });
                cache(CarePlanTemplate, 'care_plan_templates', {
                    force: true,
                })
                    .query()
                    .$promise.then(function (templates) {
                    _this.carePlanTemplates = templates;
                    if (_this.templateId) {
                        _this.template = templates.filter(function (template) { return template.id === _this.templateId; });
                    }
                });
            }
            CarePlanProblemModalController.prototype.getResults = function (searchText) {
                return this.carePlanTemplates.filter(function (r) {
                    if (!searchText) {
                        return true;
                    }
                    return r.name.toLowerCase().search(searchText.toLowerCase()) > -1;
                });
            };
            CarePlanProblemModalController.prototype.selectTemplate = function (template) {
                var _this = this;
                this.templateInterventions = [];
                if (template && template.intervention_templates) {
                    template.intervention_templates.forEach(function (intervention) {
                        _this.templateInterventions.push({
                            name: intervention.description,
                            chart_section: intervention.chart_section,
                            name_editable: true,
                        });
                    });
                }
            };
            CarePlanProblemModalController.prototype.selectTemplateIntervention = function (intervention) {
                this.intervention = intervention;
            };
            CarePlanProblemModalController.prototype.save = function () {
                // needed on save
                this.intervention.new_intervention = {
                    name: this.intervention.name,
                    due_date: this.intervention.due_date,
                    chart_section: this.intervention.chart_section,
                };
                this.$mdDialog.hide(this.intervention);
            };
            CarePlanProblemModalController.$inject = [
                '$mdDialog',
                'ChartingSectionManager',
                'CarePlanTemplate',
                'cache',
                'intervention',
                'templateId',
                'ChartingTemplateService'
            ];
            return CarePlanProblemModalController;
        }());
        angular
            .module('consolo')
            .controller('CarePlanProblemModalController', CarePlanProblemModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CarePlanProblemParticipant = /** @class */ (function () {
            function CarePlanProblemParticipant(id, description, user_id, family_id, other, participantType) {
                this.id = id;
                this.description = description;
                this.user_id = user_id;
                this.family_id = family_id;
                this.other = other;
                this.participantType = participantType;
            }
            return CarePlanProblemParticipant;
        }());
        domain.CarePlanProblemParticipant = CarePlanProblemParticipant;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var CarePlanProblemController = /** @class */ (function () {
            function CarePlanProblemController($filter, $mdDialog, $stateParams, Assignee, cache, CarePlanTemplate, FamilyMember, logger, options, Session, PersistedItem, CarePlanProblemEntry, CurrentPatient, NetworkManager, $timeout, ChartingTemplateService, $state) {
                var _this = this;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.$stateParams = $stateParams;
                this.Assignee = Assignee;
                this.cache = cache;
                this.CarePlanTemplate = CarePlanTemplate;
                this.FamilyMember = FamilyMember;
                this.logger = logger;
                this.options = options;
                this.Session = Session;
                this.PersistedItem = PersistedItem;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.CurrentPatient = CurrentPatient;
                this.NetworkManager = NetworkManager;
                this.$timeout = $timeout;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$state = $state;
                this.loading = false;
                this.categories = [];
                this.participantOptions = [
                    { value: 'user', text: 'User' },
                    { value: 'family', text: 'Family' },
                    { value: 'other', text: 'Other' },
                ];
                this.patientId = $stateParams.patientId;
                this.patient = this.CurrentPatient.patient;
                // Check if the CPPID comes from the modal on the clinical chart
                if (this.problem) {
                    this.problem.effective_date = this.getDateFromString(this.problem.effective_date);
                    this.fixupParticipants(this.problem);
                    this.item = PersistedItem('care_plan_problem', new CarePlanProblemEntry(this.problem));
                    this.problem = this.item.model;
                    this.problem.locked = !!this.item.model.id;
                }
                else {
                    this.carePlanProblemId = $stateParams.id;
                    if (this.carePlanProblemId) {
                        this.problem = CarePlanProblemEntry.get({ patient_id: this.patientId, id: this.carePlanProblemId }, function (data) {
                            data.effective_date = _this.getDateFromString(data.effective_date);
                            _this.fixupParticipants(data);
                            _this.item = PersistedItem('care_plan_problem', data);
                            _this.problem = _this.item.model;
                            if (_this.$state.current.name !== 'patients.care-plan-problem.edit') {
                                _this.problem.locked = !!_this.item.model.id;
                            }
                        }, function () {
                            logger.error('offline');
                        });
                    }
                    else {
                        this.problem = new CarePlanProblemEntry(new consolo.domain.CarePlanProblem(null, this.patientId, null, null, "Agency Generated", // source field
                        null, null, null, null, null, null, null, null, null, null, false, [], null, null));
                        this.item = PersistedItem('care_plan_problem', this.problem);
                        this.problem = this.item.model;
                        this.problem.locked = !!this.item.model.id;
                    }
                }
                this.categories = options.get('care_plan_category');
                this.sourceList = options.get('care_plan_template_sources');
                this.baseRoles = options.get('base_roles');
                this.carePlanTemplates = cache(CarePlanTemplate, 'care_plan_templates', {
                    force: true,
                }).query();
                cache(Assignee, 'assignees', { encrypt: true, force: true })
                    .query({ patient_id: this.patientId })
                    .$promise.then(function (assignees) {
                    assignees.sort(function (a, b) {
                        if (a.assigned_person.name === b.assigned_person.name) {
                            return 0;
                        }
                        return a.assigned_person.name > b.assigned_person.name ? 1 : -1;
                    });
                    _this.assigneeList = [];
                    //assigned_person_id must be unique
                    assignees.forEach(function (a) {
                        var include = true;
                        if (a.assigned_person_type === 'Physician') {
                            if (a.assigned_person.user_id) {
                                a.assigned_person_id = a.assigned_person.user_id;
                                a.assigned_person_name = a.assigned_person.user_name;
                            }
                            else {
                                include = false;
                            }
                        }
                        else {
                            a.assigned_person_name = a.assigned_person.name;
                        }
                        _this.assigneeList.forEach(function (b) {
                            if (a.assigned_person_id === b.assigned_person_id) {
                                include = false;
                            }
                        });
                        if (include) {
                            _this.assigneeList.push(a);
                        }
                    });
                    _this.assigneeList = [].concat(_this.assigneeList);
                });
                this.familyList = cache(FamilyMember, 'family_members', { encrypt: true }).query({
                    patient_id: this.patientId,
                });
                // Get both tablet optimized and base encounter sections
                this.sections = ChartingTemplateService.getScreens('base_encounter').filter(function (screen) { return !screen.deprecated; });
                var tablet_optimized = ChartingTemplateService.getScreens('tablet_optimized').filter(function (screen) { return !screen.deprecated; });
                // Ensure that any sections in tablet optimized and not base encounter are added to section dropdown
                var baseSectionNames = this.sections.map(function (s) { return s.name; });
                tablet_optimized.forEach(function (s) {
                    if (!baseSectionNames.includes(s.name)) {
                        _this.sections.push(s);
                    }
                });
                // Sort the sections for better view-ability in dropdown
                this.sections.sort(function (a, b) { return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0); });
                this.setUserDashboardUrl();
            }
            CarePlanProblemController.prototype.setUserDashboardUrl = function () {
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
            };
            CarePlanProblemController.prototype.addIntervention = function (id, name, dueDate, uuid, chart_section, ordinal, nameEditable) {
                ordinal =
                    ordinal !== null && ordinal !== undefined ? ordinal : this.problem.interventions.length;
                this.problem.interventions.push(new consolo.domain.CarePlanProblemIntervention(id, name, dueDate, this.generateUUID(), chart_section, ordinal, nameEditable));
            };
            CarePlanProblemController.prototype.generateUUID = function () {
                var d = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
                });
                return uuid;
            };
            CarePlanProblemController.prototype.getDateFromString = function (myDate) {
                if (myDate) {
                    var tempDate = new Date(myDate);
                    return new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());
                }
            };
            CarePlanProblemController.prototype.addParticipant = function () {
                this.problem.participants.push(new consolo.domain.CarePlanProblemParticipant(null, null, null, null, null, 'user'));
            };
            CarePlanProblemController.prototype.applyTemplate = function (template) {
                var _this = this;
                if (template) {
                    this.templateGoal = template.goal;
                    this.problem.problem = template.problem;
                    this.problem.category = template.category;
                    this.problem.role_ids = template.role_ids;
                    template.intervention_templates.forEach(function (item, index) {
                        _this.addIntervention(null, item.description, null, _this.generateUUID(), item.chart_section, null, null);
                    });
                }
            };
            CarePlanProblemController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            CarePlanProblemController.prototype.confirmationDialog = function ($event) {
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure?')
                    .ariaLabel('Removal Confirmation')
                    .targetEvent($event)
                    .ok('Ok')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirm);
            };
            CarePlanProblemController.prototype.fixupParticipants = function (cpp) {
                var _this = this;
                cpp.interventions.forEach(function (intervention) {
                    intervention.due_date = _this.getDateFromString(intervention.due_date);
                });
                cpp.participants.forEach(function (participant) {
                    if (participant.user_id) {
                        participant.participantType = 'user';
                    }
                    else if (participant.family_id) {
                        participant.participantType = 'family';
                    }
                    else if (participant.other) {
                        participant.participantType = 'other';
                    }
                });
            };
            CarePlanProblemController.prototype.getResults = function (searchText) {
                return this.carePlanTemplates.filter(function (r) {
                    if (!searchText) {
                        return true;
                    }
                    return r.name.toLowerCase().search(searchText.toLowerCase()) > -1;
                });
            };
            CarePlanProblemController.prototype.participantTypeChanged = function (item) {
                item.description = null;
                item.family_id = null;
                item.user_id = null;
                item.other = null;
            };
            CarePlanProblemController.prototype.removeIntervention = function (ordinal, $event) {
                var _this = this;
                var index = this.problem.interventions.findIndex(function (intervention) { return intervention.ordinal === ordinal; });
                if (this.modal) {
                    this.problem.interventions.splice(index, 1);
                    return;
                }
                this.confirmationDialog($event).then(function () {
                    var removedOrdinal = _this.problem.interventions[index].ordinal;
                    _this.problem.interventions.splice(index, 1);
                    for (var _i = 0, _a = _this.problem.interventions; _i < _a.length; _i++) {
                        var it_1 = _a[_i];
                        if (it_1.ordinal > removedOrdinal) {
                            it_1.ordinal -= 1;
                        }
                    }
                });
            };
            CarePlanProblemController.prototype.removeParticipant = function ($index, $event) {
                var _this = this;
                if (this.modal) {
                    this.problem.participants.splice($index, 1);
                    return;
                }
                this.confirmationDialog($event).then(function () {
                    _this.problem.participants.splice($index, 1);
                });
            };
            CarePlanProblemController.prototype.save = function (redirect) {
                var _this = this;
                this.loading = true;
                if (!this.item.model.uuid && !this.item.model.id) {
                    this.item.model.local = true;
                    this.item.model.uuid = this.generateUUID();
                }
                if (!this.item.model.goal || !this.item.model.problem || !this.item.model.source) {
                    this.item.model.invalidForm = true;
                }
                else {
                    this.item.model.invalidForm = false;
                }
                var draft = this.item.model.invalidForm;
                if (!this.item.model.role_ids) {
                    this.item.model.role_ids = [];
                }
                var save = draft ? this.item.saveDraft() : this.item.save();
                save.then(function (data) {
                    _this.item.model = data;
                    _this.item.model.local = data.local;
                    var message = _this.NetworkManager.isOnline() ? 'Incomplete Care Plan Problem' : 'Offline';
                    data.local
                        ? _this.logger.info('Saved on device: ' + message, 'Draft Saved')
                        : _this.logger.success('Saved Care Plan Problem:' + _this.item.model.id);
                    _this.CurrentPatient.setConfigured(consolo.services.PatientConfigureTopics.care_plan_problem);
                    _this.$mdDialog.hide(data);
                    if (!data.local && redirect) {
                        _this.$state.go('patients.care-plan-problem.view', {
                            patientId: _this.patientId,
                            id: _this.item.model.id
                        });
                    }
                    _this.$timeout(function () { return (_this.loading = false); }, 2500);
                }, function (data) {
                    _this.loading = false;
                    _this.logger.error(data.errors.join(', '), 'Error Saving');
                });
            };
            CarePlanProblemController.prototype.updateOrder = function (item, val, $event) {
                var newOrdinal = item.ordinal + val;
                var replace = this.problem.interventions.filter(function (intervention) { return intervention.ordinal === newOrdinal; })[0];
                replace.ordinal = item.ordinal;
                item.ordinal = newOrdinal;
                this.$timeout(function () {
                    $event.path[4].scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'nearest' });
                });
            };
            CarePlanProblemController.$inject = [
                '$filter',
                '$mdDialog',
                '$stateParams',
                'Assignee',
                'cache',
                'CarePlanTemplate',
                'FamilyMember',
                'logger',
                'options',
                'SessionService',
                'PersistedItem',
                'CarePlanProblemEntry',
                'CurrentPatient',
                'NetworkManager',
                '$timeout',
                'ChartingTemplateService',
                '$state'
            ];
            return CarePlanProblemController;
        }());
        angular
            .module('consolo')
            .controller('CarePlanProblemController', CarePlanProblemController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CarePlanProblem = /** @class */ (function () {
            function CarePlanProblem(id, patient_id, problem, category, source, goal, patient_familiy_engagement, effective_date, start_date, end_date, created_at, updated_at, role_ids, interventions, participants, locked, resolved_interventions, care_plan_status, care_plan_problem_updates) {
                this.id = id;
                this.patient_id = patient_id;
                this.problem = problem;
                this.category = category;
                this.source = source;
                this.goal = goal;
                this.patient_familiy_engagement = patient_familiy_engagement;
                this.effective_date = effective_date;
                this.start_date = start_date;
                this.end_date = end_date;
                this.created_at = created_at;
                this.updated_at = updated_at;
                this.role_ids = role_ids;
                this.interventions = interventions;
                this.participants = participants;
                this.locked = locked;
                this.resolved_interventions = resolved_interventions;
                this.care_plan_status = care_plan_status;
                this.care_plan_problem_updates = care_plan_problem_updates;
                this.interventions = interventions || [];
                this.participants = participants || [];
                this.resolved_interventions = resolved_interventions || [];
                this.care_plan_problem_updates = care_plan_problem_updates || [];
                this.role_ids = role_ids || [];
            }
            return CarePlanProblem;
        }());
        domain.CarePlanProblem = CarePlanProblem;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CarePlanProblemsService = /** @class */ (function () {
            function CarePlanProblemsService($http, logger) {
                this.$http = $http;
                this.logger = logger;
                this.isLoading = false;
            }
            CarePlanProblemsService.prototype.getAllPatientCPPs = function (patientId) {
                return this.$http.get('/api/v1/care_plan_problems/', {
                    params: { patient_id: patientId },
                }).then(function (response) {
                    return response.data;
                });
            };
            CarePlanProblemsService.prototype.getSingleCPP = function (cppId) {
                return this.$http.get('/api/v1/care_plan_problems/' + cppId).then(function (response) {
                    return response.data;
                });
            };
            CarePlanProblemsService.prototype.getCPPHistory = function (cppId) {
                return this.$http.get('/api/v1/care_plan_problems/' + cppId + '/history').then(function (response) {
                    return response.data;
                });
            };
            CarePlanProblemsService.prototype.deleteCPP = function (patientId) {
                return this.$http.delete('/api/v1/care_plan_problems/' + patientId).then(function (response) {
                    return response.data;
                });
            };
            CarePlanProblemsService.prototype.createCppPDF = function (cppId, patientId) {
                var _this = this;
                var headerDict = {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type',
                };
                var requestOptions = {
                    headers: headerDict
                };
                var url = "/patients/" + patientId + "/care_plan_problems/" + cppId + "/pdf";
                var data = {
                    care_plan_problems: [cppId],
                    pdf_options: {
                        field_use: 1,
                    }
                };
                return this.$http.post(url, data, requestOptions).then(function (response) {
                    _this.logger.info('Your PDF is being generated and you will be notified when it is complete.');
                });
            };
            CarePlanProblemsService.prototype.createCurrentCppPDF = function (patientId) {
                var _this = this;
                var params = {
                    current_care_plan_problems: true,
                    patient_id: patientId,
                    pdf_options: {
                        field_use: 1,
                    },
                };
                this.$http.post('/api/v1/pdf', params).then(function (response) {
                    _this.logger.info('Your PDF is being generated and you will be notified when it is complete.');
                });
            };
            CarePlanProblemsService.$inject = ['$http', 'logger'];
            return CarePlanProblemsService;
        }());
        services.CarePlanProblemsService = CarePlanProblemsService;
        angular.module('consolo').service('CarePlanProblemsService', CarePlanProblemsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('cppTemplates', {
                url: '/care_plan_problems/templates',
                data: { title: 'Care Plan Templates', backAction: 'cpp', hideSidebar: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/care-plans/templates/template-list.html',
                        controller: 'CarePlanProblemTemplateListController as $ctrl',
                    },
                },
            })
                .state('cppTemplates.edit', {
                url: '/:id/edit',
                data: { title: 'Care Plan Template Edit', backAction: 'cppTemplates', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/templates/template-edit.html',
                        controller: 'CarePlanProblemTemplateEditController as $ctrl',
                    },
                },
                resolve: {
                    template: [
                        'CarePlanProblemTemplateService',
                        '$stateParams',
                        function (cppService, $stateParams) {
                            return cppService.get(parseInt($stateParams['id'], 10));
                        },
                    ],
                    readOnly: function () {
                        return false;
                    },
                    modal: function () {
                        return false;
                    },
                },
            })
                .state('cppTemplates.new', {
                url: '/new',
                data: { title: 'Create Care Plan Template', backAction: 'cppTemplates', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/templates/template-edit.html',
                        controller: 'CarePlanProblemTemplateEditController as $ctrl',
                    },
                },
                resolve: {
                    template: function () {
                        return { intervention_templates: [] };
                    },
                    readOnly: function () {
                        return false;
                    },
                    modal: function () {
                        return false;
                    },
                },
            })
                .state('cpp', {
                url: '/care_plan_problems',
                data: { title: 'Care Plan Problems' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/care-plan-problem-list.html',
                        controller: 'CarePlanProblemListController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/care-plans/sidebar.html',
                        controller: 'CarePlanSidebarController as vm',
                    },
                },
            })
                .state('patients.care-plan-problem', {
                url: '/care_plan_problems',
                data: { title: 'Care Plan Problems' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/care-plan-problem-list.html',
                        controller: 'CarePlanProblemListController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/care-plans/sidebar.html',
                        controller: 'CarePlanSidebarController as vm',
                    },
                },
            })
                .state('patients.care-plan-problem.new', {
                url: '/new',
                data: { title: 'Care Plan Problems', backAction: 'patients.care-plan-problem' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/care-plan-problem.html',
                        controller: 'CarePlanProblemController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.care-plan-problem.view', {
                url: '/:id/view?:print',
                data: { title: 'Care Plan Problems', backAction: 'patients.care-plan-problem' },
                views: {
                    'main-content@': {
                        template: '<view-care-plan-problem care-plan-problem-id="care_plan_problem_id" />',
                        controller: [
                            '$scope',
                            '$stateParams',
                            function ($scope, $stateParams) {
                                $scope.care_plan_problem_id = $stateParams.id;
                            }
                        ],
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.care-plan-problem.discontinue', {
                url: '/:id/discontinue',
                data: { title: 'Care Plan Problem Discontinue', backAction: 'patient.care-plan-problems' },
                views: {
                    'main-content@': {
                        template: '<discontinue-care-plan-problem care-plan-problem-id="care_plan_problem_id" flex layout="column" />',
                        controller: [
                            '$scope',
                            '$stateParams',
                            function ($scope, $stateParams) {
                                $scope.care_plan_problem_id = $stateParams.id;
                            }
                        ],
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                }
            })
                .state('patients.care-plan-problem.current', {
                url: '/current',
                data: { title: 'Current Care Plan Problems', backAction: 'patients.care-plan-problem' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/care-plan-problem-current.html',
                        controller: 'CarePlanProblemCurrentController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.care-plan-problem.edit', {
                url: '/:id/edit',
                data: { title: 'Care Plan Problems', backAction: 'patients.care-plan-problem' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/care-plan-problem.html',
                        controller: 'CarePlanProblemController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.care-plan-problem.history', {
                url: '/:id/history',
                data: { title: 'Care Plan Problems', backAction: 'patients.care-plan-problem' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/care-plans/care-plan-problem-history.html',
                        controller: 'CarePlanProblemHistoryController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var CarePlanSidebarController = /** @class */ (function () {
            function CarePlanSidebarController(CarePlanProblemsService, CarePlanProblemFilterService, CarePlanProblemDataTableService, cache, Lookup, $stateParams) {
                this.CarePlanProblemsService = CarePlanProblemsService;
                this.CarePlanProblemFilterService = CarePlanProblemFilterService;
                this.CarePlanProblemDataTableService = CarePlanProblemDataTableService;
                this.cache = cache;
                this.Lookup = Lookup;
                this.$stateParams = $stateParams;
                this.patientId = this.$stateParams.patientId;
            }
            CarePlanSidebarController.prototype.getResults = function (term) {
                var _this = this;
                return this.cache(this.Lookup, 'lookup', { force: true, encrypt: true })
                    .fetch('patients', { limit: -1, active: true, deceased: true })
                    .then(function (results) {
                    if (results) {
                        // All lookup results were pre-cached - filter them and return
                        var lowerTerm = term.toLowerCase();
                        return results.filter(function (r) {
                            return r.name.toLowerCase().search(lowerTerm) > -1;
                        });
                    }
                    else {
                        // Hit the server but *don't* cache results
                        return _this.Lookup.patients({ q: term }).$promise.then(function (results) {
                            return results;
                        });
                    }
                }, function () {
                    console.warn('Unable to fetch cached results');
                    return _this.Lookup.patients({ q: term }).$promise.then(function (results) {
                        return results;
                    });
                });
            };
            CarePlanSidebarController.$inject = [
                'CarePlanProblemsService',
                'CarePlanProblemFilterService',
                'CarePlanProblemDataTableService',
                'cache',
                'Lookup',
                '$stateParams',
            ];
            return CarePlanSidebarController;
        }());
        controllers.CarePlanSidebarController = CarePlanSidebarController;
        angular
            .module('consolo')
            .controller('CarePlanSidebarController', CarePlanSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var ConsoloCareProgramEditComponent = /** @class */ (function () {
        function ConsoloCareProgramEditComponent() {
            this.templateUrl = '/y/templates/care-programs/care-program-edit.component.html';
            this.controller = CareProgramEditController;
        }
        return ConsoloCareProgramEditComponent;
    }());
    var CareProgramEditController = /** @class */ (function () {
        function CareProgramEditController($state, careProgramService) {
            this.$state = $state;
            this.careProgramService = careProgramService;
            this.isLoading = false;
        }
        CareProgramEditController.prototype.$onInit = function () {
            var _this = this;
            var id = this.$state.params['id'];
            if (id) {
                this.careProgramService.get(id).then(function (r) { return (_this.item = r); });
            }
            else {
                this.item = {};
            }
        };
        CareProgramEditController.prototype.save = function () {
            var _this = this;
            return this.careProgramService.upsert(this.item).then(function (r) { return _this.$state.go('care-programs'); });
        };
        CareProgramEditController.$inject = ['$state', 'CareProgramService'];
        return CareProgramEditController;
    }());
    angular
        .module('consolo')
        .component('consoloCareProgramEdit', new ConsoloCareProgramEditComponent());
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var ConsoloCareProgramListComponent = /** @class */ (function () {
        function ConsoloCareProgramListComponent() {
            this.templateUrl = '/y/templates/care-programs/care-program-list.component.html';
            this.controller = CareProgramListController;
        }
        return ConsoloCareProgramListComponent;
    }());
    var CareProgramListController = /** @class */ (function () {
        function CareProgramListController($mdDialog, $state, careProgramService) {
            this.$mdDialog = $mdDialog;
            this.$state = $state;
            this.careProgramService = careProgramService;
            this.includeDeleted = false;
            this.isLoading = true;
        }
        CareProgramListController.prototype.$onInit = function () {
            var _this = this;
            this.layout = {
                id_field: 'id',
                headerMenu: {
                    csvContents: true,
                    items: [],
                },
                callbackFn: function (data, $event) {
                    if (_this.careProgramService.canUpdate) {
                        _this.$state.go('care-programs.edit', { id: data.id });
                    }
                },
                rowMenu: {
                    items: [
                        {
                            label: 'Edit',
                            icon: 'edit',
                            conditionalHideFn: function (data) { return !_this.careProgramService.canUpdate; },
                            callbackFn: function (data) { return _this.$state.go('care-programs.edit', { id: data.id }); },
                        },
                        {
                            label: 'Delete',
                            icon: 'delete',
                            conditionalHideFn: function (data) { return !_this.careProgramService.canDelete || data.deleted_at; },
                            callbackFn: function (data) { return _this.delete(data); },
                        },
                        {
                            label: 'Undelete',
                            icon: 'delete_forever',
                            conditionalHideFn: function (data) { return !_this.careProgramService.canDelete || !data.deleted_at; },
                            callbackFn: function (data) { return _this.undelete(data); },
                        },
                    ],
                },
                columns: [
                    {
                        title: 'Name',
                        field: 'name',
                        canSort: true,
                        formatValueFn: function (u) {
                            return u.deleted_at ? u.name + "<span class=\"basic-badge\">Deleted</span>" : u.name;
                        },
                    },
                    {
                        title: 'Creator',
                        field: 'created_by',
                        formatValueFn: function (u) { return u.created_by.name; },
                        canSort: true,
                    },
                    { title: 'Created', field: 'created_at', canSort: true, type: 'dateTime' },
                ],
            };
            this.refresh();
        };
        CareProgramListController.prototype.delete = function (item) {
            var _this = this;
            var confirm = this.$mdDialog
                .confirm()
                .title('Confirmation')
                .htmlContent('Delete this care program?')
                .ariaLabel('Removal Confirmation')
                .ok('Ok')
                .cancel('Cancel');
            this.$mdDialog
                .show(confirm)
                .then(function (r) { return _this.careProgramService.delete(item).then(function (s) { return _this.refresh(); }); });
        };
        CareProgramListController.prototype.undelete = function (item) {
            var _this = this;
            this.careProgramService.undelete(item).then(function (s) { return _this.refresh(); });
        };
        CareProgramListController.prototype.refresh = function () {
            var _this = this;
            this.isLoading = true;
            this.careProgramService
                .select(this.includeDeleted)
                .then(function (r) { return (_this.items = r); })
                .finally(function () { return (_this.isLoading = false); });
        };
        CareProgramListController.$inject = ['$mdDialog', '$state', 'CareProgramService'];
        return CareProgramListController;
    }());
    angular
        .module('consolo')
        .component('consoloCareProgramList', new ConsoloCareProgramListComponent());
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var CareProgramService = /** @class */ (function () {
        function CareProgramService($http, PermissionsService) {
            this.$http = $http;
            this.PermissionsService = PermissionsService;
            this.apiUrl = '/api/v2/care_programs';
            this.canUpdate = false;
            this.canView = false;
            this.canDelete = false;
            this.canUpdate = this.PermissionsService.can('update', 'care_programs');
            this.canDelete = this.PermissionsService.can('destroy', 'care_programs');
            this.canView = this.PermissionsService.can('show', 'care_programs');
        }
        CareProgramService.prototype.delete = function (careProgram) {
            return this.$http.delete(this.apiUrl + "/" + careProgram.id);
        };
        CareProgramService.prototype.undelete = function (careProgram) {
            return this.$http.patch(this.apiUrl + "/" + careProgram.id + "/restore", careProgram);
        };
        CareProgramService.prototype.upsert = function (careProgram) {
            if (careProgram.id) {
                return this.$http.patch(this.apiUrl + "/" + careProgram.id, careProgram);
            }
            else {
                return this.$http.post(this.apiUrl, careProgram);
            }
        };
        CareProgramService.prototype.get = function (id) {
            return this.$http.get(this.apiUrl + "/" + id).then(function (response) {
                return response.data;
            });
        };
        CareProgramService.prototype.select = function (includeDeleted) {
            return this.$http
                .get(this.apiUrl, { params: { include_deleted: includeDeleted } })
                .then(function (r) {
                return r.data;
            });
        };
        CareProgramService.$inject = ['$http', 'PermissionsService'];
        return CareProgramService;
    }());
    consolo.CareProgramService = CareProgramService;
    angular.module('consolo').service('CareProgramService', CareProgramService);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var CareProgramStates = /** @class */ (function () {
        function CareProgramStates($stateProvider) {
            $stateProvider
                .state('care-programs', {
                url: '/care_programs',
                data: { title: 'Care Programs', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<consolo-care-program-list></consolo-care-program-list>',
                    },
                },
            })
                .state('care-programs.new', {
                url: '/new',
                data: { title: 'Care Programs', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<consolo-care-program-edit></consolo-care-program-edit>',
                    },
                },
            })
                .state('care-programs.edit', {
                url: '/:id/edit',
                data: { title: 'Care Programs', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<consolo-care-program-edit></consolo-care-program-edit>',
                    },
                },
            });
        }
        CareProgramStates.$inject = ['$stateProvider'];
        return CareProgramStates;
    }());
    angular.module('consolo').config(CareProgramStates);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var CertificationService = /** @class */ (function () {
            function CertificationService($http, PdfGenerator, logger, $state) {
                var _this = this;
                this.$http = $http;
                this.PdfGenerator = PdfGenerator;
                this.logger = logger;
                this.$state = $state;
                this.unsignedCertificationTable = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Patient',
                            field: 'patient.name',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({ patientId: $patient_id })',
                            canSort: true,
                        },
                        {
                            title: 'Office',
                            field: 'office.name',
                            canSort: true,
                        },
                        {
                            title: 'Benefit Period',
                            field: 'benefit_period',
                            canSort: true,
                        },
                        {
                            title: 'Certification Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Benefit Start Date',
                            field: 'benefit_start_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Benefit End Date',
                            field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Admission',
                            field: 'admission_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                    ],
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Created At',
                                    field: 'created_at',
                                    type: csg.datatable.ColumnTypes.dateTime,
                                },
                                {
                                    title: 'Created By',
                                    field: 'created_by.name',
                                },
                            ],
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                disableOffline: true,
                                icon: 'remove_red_eye',
                                callbackFn: function (certification) {
                                    return (document.location.href =
                                        '/patients/' + certification.patient.id + '/certifications/' + certification.id);
                                },
                            },
                            {
                                label: 'Print',
                                disableOffline: true,
                                icon: 'print',
                                callbackFn: function (certification) {
                                    _this.PdfGenerator.print({ certifications: certification.id });
                                },
                            },
                            {
                                label: 'Edit',
                                disableOffline: true,
                                icon: 'edit',
                                callbackFn: function (certification) {
                                    return (document.location.href =
                                        '/patients/' +
                                            certification.patient.id +
                                            '/certifications/' +
                                            certification.id +
                                            '/edit');
                                },
                            },
                        ],
                    },
                };
            }
            CertificationService.prototype.getUnsignedCertifications = function () {
                var _this = this;
                return this.$http.get('/api/v1/certifications/unsigned_for_user').then(function (data) {
                    _this.unsignedCertifications = data.data;
                    return data.data;
                });
            };
            CertificationService.prototype.getCertifications = function (patientId) {
                return this.$http
                    .get('/api/v1/certifications', { params: { patient_id: patientId } })
                    .then(function (data) {
                    return data.data;
                });
            };
            CertificationService.prototype.get = function (certificationId) {
                return this.$http.get("/api/v1/certifications/" + certificationId).then(function (data) {
                    return data.data;
                });
            };
            CertificationService.prototype.delete = function (id) {
                var _this = this;
                this.$http.delete('/api/v1/certifications/' + id).then(function (data) {
                    _this.logger('The Certification has been deleted');
                    if (_this.unsignedCertifications) {
                        _this.unsignedCertifications.every(function (certification, index) {
                            if (id === certification.id) {
                                _this.unsignedCertifications.splice(index, 1);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            };
            CertificationService.$inject = ['$http', 'PdfGenerator', 'logger', '$state'];
            return CertificationService;
        }());
        services.CertificationService = CertificationService;
        angular.module('consolo').service('CertificationService', CertificationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClaimViewController = /** @class */ (function () {
            function ClaimViewController(claim) {
                this.claim = claim;
            }
            ClaimViewController.$inject = ['claim'];
            return ClaimViewController;
        }());
        angular.module('consolo').controller('ClaimViewController', ClaimViewController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var ClaimService = /** @class */ (function () {
            function ClaimService($http, PermissionsService, Session) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.Session = Session;
                this.url = '/api/v1/claims';
                this.viewPermission = false;
                this.updatePermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.updatePermission = this.PermissionsService.can('update', 'claims');
                this.createPermission = this.PermissionsService.can('create', 'claims');
                this.deletePermission = this.PermissionsService.can('destroy', 'claims');
                this.viewPermission = this.PermissionsService.can('show', 'claims');
            }
            ClaimService.prototype.get = function (id) {
                return this.$http.get(this.url + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            ClaimService.prototype.getClaimsByPatientId = function (patientId) {
                return this.$http
                    .get(this.url, { params: { patient_id: patientId } })
                    .then(function (response) {
                    return response.data;
                });
            };
            ClaimService.$inject = ['$http', 'PermissionsService'];
            return ClaimService;
        }());
        services.ClaimService = ClaimService;
        angular.module('consolo').service('ClaimService', ClaimService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var about;
    About.$inject = ['AboutService'];
    function About(AboutService) {
        AboutService.get().then(function (a) {
            about = a;
        });
    }
    var ClaimState = /** @class */ (function () {
        function ClaimState($stateProvider) {
            $stateProvider
                .state('patients.claims', {
                url: '/claims',
                data: { title: 'Claims' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/claims/claims.html',
                        controller: 'ClaimsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    global: [
                        'ClaimsDataTableService',
                        function (ClaimsDataTableService) {
                            ClaimsDataTableService.global = false;
                            return false;
                        },
                    ],
                },
            })
                .state('patients.claims.view', {
                url: '/:claimId',
                data: { title: 'Claim' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/claims/view.html',
                        controller: 'ClaimViewController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    claim: [
                        '$stateParams',
                        'ClaimService',
                        function ($stateParams, ClaimService) {
                            var test = '';
                            return ClaimService.get($stateParams.claimId).then(function (claim) {
                                return claim;
                            });
                        },
                    ],
                },
            })
                .state('patients.claims.view.claim-line-items', {
                url: '/claim_line_items',
                data: { title: 'Claim Line Items' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/claims/claim-line-items/claim-line-items.html',
                        controller: 'ClaimLineItemsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    claim: [
                        '$stateParams',
                        'claim',
                        function ($stateParams, claim) {
                            return claim;
                        },
                    ],
                },
            })
                .state('patients.claims.view.claim-line-items.edit', {
                url: '/:claimLineItemId',
                data: { title: 'Claim Line Items' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/claims/claim-line-items/edit.html',
                        controller: 'ClaimLineItemEditController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    claimLineItem: [
                        '$stateParams',
                        'claim',
                        '$filter',
                        function ($stateParams, claim, $filter) {
                            return $filter('filter')(claim.claim_line_items, function (claim_line_item) {
                                return (parseInt(claim_line_item.id, 10) === parseInt($stateParams.claimLineItemId, 10));
                            })[0];
                        },
                    ],
                    revenueCodes: [
                        'options',
                        function (options) {
                            return options.get('level_of_cares').$promise.then(function (data) {
                                return data;
                            });
                        },
                    ],
                },
            })
                .state('patients.claims.view.claim-line-items.new', {
                url: '/new',
                data: { title: 'New Claim Line Item' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/claims/claim-line-items/edit.html',
                        controller: 'ClaimLineItemEditController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    claimLineItem: [
                        function () {
                            return null;
                        },
                    ],
                    revenueCodes: [
                        'options',
                        function (options) {
                            return options.get('level_of_cares').$promise.then(function (data) {
                                return data;
                            });
                        },
                    ],
                },
            });
        }
        ClaimState.$inject = ['$stateProvider'];
        return ClaimState;
    }());
    angular
        .module('consolo')
        .config(ClaimState)
        .run(About);
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ClaimsDataTableService = /** @class */ (function (_super) {
            __extends(ClaimsDataTableService, _super);
            function ClaimsDataTableService($http, $interval, ClaimService, $state, CurrentPatient) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.ClaimService = ClaimService;
                _this.$state = $state;
                _this.CurrentPatient = CurrentPatient;
                _this.loadingData = false;
                _this.showFilters = true;
                _this.activate();
                return _this;
            }
            ClaimsDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/claims';
                this.filters = new consolo.domain.ClaimsFilter();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: true,
                        hideTextFilter: true,
                        allowRowSelect: true,
                    },
                    columns: [
                        {
                            title: 'Id',
                            field: 'id',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                };
            };
            ClaimsDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = this.filters.getFilters();
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            ClaimsDataTableService.prototype.reset = function () {
                var _this = this;
                if (!this.global) {
                    if (this.patientId !== this.CurrentPatient.patient_id || !this.data) {
                        //  Only search again if the patient changes
                        this.patientId = this.CurrentPatient.patient_id;
                        this.filters = new consolo.domain.ClaimsFilter();
                        this.filters.patient_id = this.CurrentPatient.patient_id;
                        this.search();
                    }
                    else {
                        this.apiInterval = this.$interval(function () {
                            if (_this.tableControl) {
                                _this.$interval.cancel(_this.apiInterval);
                                _this.filters.page = 1;
                                _this.params = _this.filters;
                                _this.tableControl.loadCurrentData();
                            }
                        }, 500, 20);
                    }
                }
                else {
                    //Always search when it's on global
                    this.search();
                }
            };
            ClaimsDataTableService.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            ClaimsDataTableService.$inject = ['$http', '$interval', 'ClaimService', '$state', 'CurrentPatient'];
            return ClaimsDataTableService;
        }(csg.datatable.ApiService));
        services.ClaimsDataTableService = ClaimsDataTableService;
        angular.module('consolo').service('ClaimsDataTableService', ClaimsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClaimsController = /** @class */ (function () {
            function ClaimsController(ClaimsDataTableService) {
                this.ClaimsDataTableService = ClaimsDataTableService;
                this.ClaimsDataTableService.tableControl = null;
                this.ClaimsDataTableService.reset();
                this.ClaimsDataTableService.showFilters = false;
            }
            ClaimsController.$inject = ['ClaimsDataTableService'];
            return ClaimsController;
        }());
        angular.module('consolo').controller('ClaimsController', ClaimsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AddAddendumModal = /** @class */ (function () {
            function AddAddendumModal($mdDialog) {
                this.$mdDialog = $mdDialog;
            }
            AddAddendumModal.prototype.show = function (chart, $event, sectionId) {
                var dialog;
                dialog = {
                    templateUrl: '/y/templates/clinical-charts/add-addendum.html',
                    targetEvent: $event,
                    controller: AddAddendumDialogController,
                    controllerAs: '$ctrl',
                    parent: angular.element(document.body),
                    locals: { chart: chart, sectionId: sectionId },
                };
                this.$mdDialog.show(dialog);
            };
            AddAddendumModal.$inject = ['$mdDialog'];
            return AddAddendumModal;
        }());
        services.AddAddendumModal = AddAddendumModal;
        var AddAddendumDialogController = /** @class */ (function () {
            function AddAddendumDialogController($mdDialog, chart, ClinicalChartEntry, logger, ChartingTemplateService, Permissions, options, sectionId) {
                this.$mdDialog = $mdDialog;
                this.chart = chart;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.logger = logger;
                this.ChartingTemplateService = ChartingTemplateService;
                this.Permissions = Permissions;
                this.addendumReasons = options.get('addendum_reasons');
                this.chartSections = [];
                var template = this.ChartingTemplateService.getTemplateName(chart);
                for (var _i = 0, _a = chart.completed_sections; _i < _a.length; _i++) {
                    var completed = _a[_i];
                    var s = this.ChartingTemplateService.sectionSearchBy(completed.type, 'name', template);
                    if (s && this.Permissions.canAny([['update', s.model], ['create', s.model]])) {
                        this.chartSections.push(s);
                    }
                }
                if (sectionId) {
                    this.chartSection = chart.completed_sections.filter(function (a) {
                        return a.id === sectionId;
                    })[0];
                }
                else {
                    this.chartSection = { id: null, type: null };
                }
            }
            AddAddendumDialogController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            AddAddendumDialogController.prototype.save = function () {
                var _this = this;
                this.ClinicalChartEntry.addendum({
                    id: this.chart.id,
                    addendum_reason_id: this.addendumReasonId,
                    note: this.note,
                    section_id: this.chartSection.id,
                    section_type: this.chartSection.type,
                }).$promise.then(function (data) {
                    _this.logger.success('Addendum added');
                    _this.$mdDialog.hide();
                }, function (data) {
                    _this.logger.error(data.data.errors.join());
                });
            };
            AddAddendumDialogController.$inject = [
                '$mdDialog',
                'chart',
                'ClinicalChartEntry',
                'logger',
                'ChartingTemplateService',
                'PermissionsService',
                'options',
                'sectionId',
            ];
            return AddAddendumDialogController;
        }());
        angular.module('consolo').service('AddAddendumModal', AddAddendumModal);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        function DisciplineFilter() {
            return function (items, role_id) {
                var filtered = [];
                items.forEach(function (a) {
                    if (a.role_ids.indexOf(role_id) > -1 || !a.role_ids.length) {
                        filtered.push(a);
                    }
                });
                return filtered;
            };
        }
        common.DisciplineFilter = DisciplineFilter;
        angular.module('consolo').filter('discipline', DisciplineFilter);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartDeviceDraftsService = /** @class */ (function () {
            function ChartDeviceDraftsService(DraftSyncService, ChartDraftsSearchFilterService, ChartingTemplateService, $interval, $timeout, $q, ClinicalChartDraftsService, persistenceStore, PersistedItem, ClinicalChartEntry) {
                this.DraftSyncService = DraftSyncService;
                this.ChartDraftsSearchFilterService = ChartDraftsSearchFilterService;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$interval = $interval;
                this.$timeout = $timeout;
                this.$q = $q;
                this.ClinicalChartDraftsService = ClinicalChartDraftsService;
                this.persistenceStore = persistenceStore;
                this.PersistedItem = PersistedItem;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.drafts = [];
                this.newDraft = null;
                this.syncMessage = '';
            }
            ChartDeviceDraftsService.prototype.resetDrafts = function () {
                this.drafts = [];
                this.loadingDrafts = true;
                this.newDraft = null;
                this.syncingInProgress = false;
            };
            ChartDeviceDraftsService.prototype.getDrafts = function (patientId) {
                var _this = this;
                this.loadingDrafts = true;
                this.syncingInProgress = true;
                this.syncMessage = 'Draft Syncing In Progress';
                this.DraftSyncService.getDrafts('clinical_chart_entry').then(function (charts) {
                    if (charts.every(_this.isSynced)) {
                        // Must sort drafts programatically since using ng-hide, filter and track by in the template
                        // causes strange behavior with sortBy
                        _this.sortDrafts(charts);
                        _this.ChartDraftsSearchFilterService.deviceDrafts = charts;
                        _this.ChartDraftsSearchFilterService.tempDeviceDrafts = charts;
                        var patients = [];
                        for (var _i = 0, charts_1 = charts; _i < charts_1.length; _i++) {
                            var c = charts_1[_i];
                            if (!c.model.id) {
                                _this.newDraft = c;
                            }
                            _this.getClientName(c.model);
                            var patientExists = false;
                            for (var _a = 0, patients_1 = patients; _a < patients_1.length; _a++) {
                                var p = patients_1[_a];
                                if (c.model.patient_id === p.patient.id) {
                                    patientExists = true;
                                    p.drafts.push(c);
                                }
                            }
                            if (!patientExists) {
                                patients.push({ patient: c.model.patient, drafts: [c] });
                            }
                        }
                        _this.drafts = patients;
                        _this.getServerDrafts(patientId)
                            .then(function (serverDrafts) {
                            // Must sort drafts programatically since using ng-hide, filter and track by in the template
                            // causes strange behavior with sortBy
                            _this.sortDrafts(serverDrafts);
                            _this.checkAllDrafts(_this.ChartDraftsSearchFilterService.deviceDrafts, _this.ChartDraftsSearchFilterService.serverDrafts)
                                .then(function (response) {
                                _this.refreshDeviceDrafts().then(function () {
                                    _this.syncingInProgress = false;
                                });
                            })
                                .catch(function (error) {
                                console.log(error.errors);
                                _this.refreshDeviceDrafts().then(function () {
                                    _this.syncingInProgress = false;
                                });
                            });
                        })
                            .catch(function (error) {
                            console.log(error.errors);
                            _this.syncingInProgress = false;
                        });
                    }
                    else {
                        _this.$timeout(function () {
                            _this.getDrafts(patientId);
                        }, 1000);
                    }
                });
            };
            ChartDeviceDraftsService.prototype.checkAllDrafts = function (deviceDrafts, serverDrafts) {
                var _this = this;
                // After pulling down server drafts and loading up device drafts,
                // we now compare to make sure the device drafts are up to date
                this.syncMessage = 'Making sure all device draft are up to date...';
                var promiseChain = [];
                deviceDrafts.forEach(function (deviceDraft) {
                    promiseChain.push(_this.checkDraft(deviceDraft, serverDrafts));
                });
                return this.$q.all(promiseChain);
            };
            ChartDeviceDraftsService.prototype.getServerDrafts = function (patientId) {
                var _this = this;
                return this.ClinicalChartDraftsService.getUnconvertedDrafts(patientId, false)
                    .then(function (response) {
                    var serverDrafts = response.data;
                    serverDrafts = serverDrafts
                        .map(function (chart) {
                        if (chart.clinical_chart_id === null) {
                            return chart.data;
                        }
                    })
                        .filter(function (chart) {
                        return chart;
                    });
                    _this.ChartDraftsSearchFilterService.serverDrafts = serverDrafts;
                    _this.loadingDrafts = false;
                    return serverDrafts;
                })
                    .catch(function (error) {
                    _this.loadingDrafts = false;
                    console.log(error);
                });
            };
            ChartDeviceDraftsService.prototype.getClientName = function (model) {
                if (!model.charting_client) {
                    model.charting_client = 'bootstrap_charting';
                }
                if (!model.charting_client_name) {
                    var client = this.ChartingTemplateService.getChartingClient(model.charting_client);
                    if (client) {
                        model.charting_client_name = client.description;
                    }
                }
                if (!model.encounter_type_name && model.encounter_type_id) {
                    if (model.encounter_type) {
                        model.encounter_type_name = isNaN(model.encounter_type_id)
                            ? ''
                            : model.encounter_type.name;
                    }
                }
                model.isEncounterCharting = this.ChartingTemplateService.isEncounterCharting(model.charting_client);
            };
            ChartDeviceDraftsService.prototype.isSynced = function (chart) {
                return chart.model.syncing === false || chart.model.syncing === undefined;
            };
            ChartDeviceDraftsService.prototype.deviceDraftIds = function () {
                return this.ChartDraftsSearchFilterService.deviceDrafts.map(function (chart) {
                    return chart.model.temp_uuid;
                });
            };
            ChartDeviceDraftsService.prototype.deviceDraftPatientIds = function () {
                return this.ChartDraftsSearchFilterService.deviceDrafts.map(function (chart) {
                    return chart.model.patient.id;
                });
            };
            ChartDeviceDraftsService.prototype.checkDraft = function (deviceDraft, serverDrafts) {
                var _this = this;
                // 1. Check to see if the device draft has a corresponding server draft
                var serverDraftIndex = serverDrafts
                    .map(function (serverDraft) {
                    return serverDraft.model.temp_uuid;
                })
                    .indexOf(deviceDraft.model.temp_uuid);
                if (serverDraftIndex !== -1) {
                    // 2. If it does, then compare the dates of the drafts and use the newest
                    var serverDraft_1 = serverDrafts[serverDraftIndex];
                    var serverIsNewer = this.compareDates(deviceDraft, serverDraft_1);
                    if (serverIsNewer) {
                        return this.removeDeviceDraft(deviceDraft).then(function () {
                            return _this.saveServerToDevice(serverDraft_1).then(function () {
                                // console.log('Server draft is newer');
                            });
                        });
                    }
                    else {
                        // console.log('Device draft is newer or up to date');
                    }
                }
                else {
                    // 3. If it doen't make a call to check and see if it has been finalized
                    return this.checkFinalized(deviceDraft)
                        .then(function (isFinalized) {
                        if (isFinalized) {
                            // 4. If it has been finalized, then clear it off the device
                            return _this.removeDeviceDraft(deviceDraft).then(function () {
                                // console.log('Draft on this device has already been finalized on another device');
                            });
                        }
                        else {
                            // 5. If it hasn't then do nothing (Draft was created when user had no internet connection)
                            // console.log('Draft is new and hasnt been synced to the server yet');
                        }
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            };
            ChartDeviceDraftsService.prototype.compareDates = function (deviceDraft, serverDraft) {
                var deviceDraftDate = new Date(deviceDraft.model.updated_at);
                var serverDraftDate = new Date(serverDraft.model.updated_at);
                if (deviceDraftDate < serverDraftDate) {
                    serverDraft.model.server_draft_updated_at = serverDraftDate;
                    return true;
                }
                else if (deviceDraftDate >= serverDraftDate) {
                    deviceDraft.model.server_draft_updated_at = serverDraftDate;
                    return false;
                }
            };
            ChartDeviceDraftsService.prototype.checkFinalized = function (deviceDraft) {
                return this.ClinicalChartDraftsService.getSingleClinicalChartDraft(deviceDraft.model.temp_uuid)
                    .then(function (response) {
                    if (response.data.clinical_chart_id) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                    .catch(function (error) {
                    return false;
                });
            };
            ChartDeviceDraftsService.prototype.removeDeviceDraft = function (draft) {
                var _this = this;
                var index = this.ChartDraftsSearchFilterService.deviceDrafts
                    .map(function (deviceDraft) {
                    return deviceDraft.model.temp_uuid;
                })
                    .indexOf(draft.model.temp_uuid);
                return this.persistenceStore.remove('clinical_chart_entry', draft.model).then(function () {
                    if (!draft.model.id) {
                        _this.newDraft = null;
                    }
                    var id = draft.model.id ? draft.model.id.toString() : 'NEW';
                    var message = 'Draft ' + id + ' for ' + draft.model.patient.name + ' has been deleted';
                    _this.ChartDraftsSearchFilterService.deviceDrafts.splice(index, 1);
                }, function (e) {
                    console.log('Chart couldnt be deleted');
                });
            };
            ChartDeviceDraftsService.prototype.saveServerToDevice = function (draft) {
                var persistedServerDraft = this.PersistedItem('clinical_chart_entry', new this.ClinicalChartEntry(draft.model));
                persistedServerDraft.model.server = true;
                persistedServerDraft.model.syncing = false;
                persistedServerDraft.model.server_draft_updated_at = draft.model.updated_at;
                return persistedServerDraft.saveOnDevice(true);
            };
            ChartDeviceDraftsService.prototype.refreshDeviceDrafts = function () {
                var _this = this;
                return this.DraftSyncService.getDrafts('clinical_chart_entry').then(function (charts) {
                    _this.sortDrafts(charts);
                    _this.ChartDraftsSearchFilterService.deviceDrafts = charts;
                    _this.ChartDraftsSearchFilterService.tempDeviceDrafts = charts;
                    var patients = [];
                    for (var _i = 0, charts_2 = charts; _i < charts_2.length; _i++) {
                        var c = charts_2[_i];
                        if (!c.model.id) {
                            _this.newDraft = c;
                        }
                        _this.getClientName(c.model);
                        var patientExists = false;
                        for (var _a = 0, patients_2 = patients; _a < patients_2.length; _a++) {
                            var p = patients_2[_a];
                            if (c.model.patient_id === p.patient.id) {
                                patientExists = true;
                                p.drafts.push(c);
                            }
                        }
                        if (!patientExists) {
                            patients.push({ patient: c.model.patient, drafts: [c] });
                        }
                    }
                    _this.drafts = patients;
                });
            };
            ChartDeviceDraftsService.prototype.sortDrafts = function (drafts) {
                if (!drafts) {
                    return [];
                }
                return drafts.sort(function (a, b) {
                    var date1 = new Date(a.model.updated_at);
                    var date2 = new Date(b.model.updated_at);
                    if (date1 > date2) {
                        return -1;
                    }
                    else if (date1 < date2) {
                        return 1;
                    }
                    return 0;
                });
            };
            ChartDeviceDraftsService.$inject = [
                'DraftSyncService',
                'ChartDraftsSearchFilterService',
                'ChartingTemplateService',
                '$interval',
                '$timeout',
                '$q',
                'ClinicalChartDraftsService',
                'persistenceStore',
                'PersistedItem',
                'ClinicalChartEntry',
            ];
            return ChartDeviceDraftsService;
        }());
        charting.ChartDeviceDraftsService = ChartDeviceDraftsService;
        angular
            .module('consolo')
            .service('ChartDeviceDraftsService', ChartDeviceDraftsService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartDraftsSearchFilterService = /** @class */ (function () {
            function ChartDraftsSearchFilterService(ClinicalChartDraftsService, Session, $filter, logger) {
                var _a, _b;
                this.ClinicalChartDraftsService = ClinicalChartDraftsService;
                this.Session = Session;
                this.$filter = $filter;
                this.logger = logger;
                this.deviceDrafts = [];
                this.tempDeviceDrafts = [];
                this.serverDrafts = [];
                this.chartTypeFilterTerm = '';
                this.userId = (_b = (_a = this.Session) === null || _a === void 0 ? void 0 : _a.getUser()) === null || _b === void 0 ? void 0 : _b.id;
                this.filterByUser = false;
            }
            ChartDraftsSearchFilterService.prototype.filterDraftsByPatient = function (patient) {
                var _this = this;
                this.serverDrafts = [];
                var patientId = patient ? patient.id : null;
                this.ClinicalChartDraftsService.getUnconvertedDrafts(patientId, false)
                    .then(function (response) {
                    var serverDrafts = response.data
                        .map(function (chart) {
                        if (chart.clinical_chart_id === null) {
                            return chart.data;
                        }
                    })
                        .filter(function (chart) {
                        return chart;
                    });
                    _this.serverDrafts = serverDrafts;
                })
                    .catch(function (error) {
                    console.log(error);
                });
                if (patient) {
                    this.deviceDrafts = this.$filter('filter')(this.deviceDrafts, function (deviceDraft) {
                        return deviceDraft.model.patient_id === patientId;
                    });
                }
                else {
                    this.deviceDrafts = this.tempDeviceDrafts;
                }
            };
            ChartDraftsSearchFilterService.prototype.filterByChartType = function (chartType) {
                if (this.chartTypeFilterTerm !== chartType) {
                    this.chartTypeFilterTerm = chartType;
                }
                else {
                    this.chartTypeFilterTerm = '';
                }
            };
            ChartDraftsSearchFilterService.prototype.filterDraftsByUser = function () {
                var _this = this;
                // Every time the function is called this.filterByUser is going to change to false or true
                // This will trigger the call to load only my drafts or everyone drafts 
                this.loadingDrafts = true;
                this.serverDrafts = [];
                this.filterByUser = !this.filterByUser;
                var byUser = this.filterByUser === true ? this.userId : null;
                this.ClinicalChartDraftsService.getUnconvertedDraftsByUser(byUser, false)
                    .then(function (response) {
                    var serverDrafts = response.data
                        .map(function (chart) {
                        if (chart.clinical_chart_id === null) {
                            return chart.data;
                        }
                    })
                        .filter(function (chart) {
                        return chart;
                    });
                    _this.serverDrafts = serverDrafts;
                    _this.loadingDrafts = false;
                })
                    .catch(function (error) {
                    console.log(error);
                    _this.loadingDrafts = false;
                    _this.logger.error('There was an error filtering the drafts. Try again.');
                });
                if (this.userId) {
                    this.deviceDrafts = this.$filter('filter')(this.deviceDrafts, function (deviceDraft) {
                        return deviceDraft.model.user_id === _this.userId;
                    });
                    this.serverDrafts = this.$filter('filter')(this.serverDrafts, function (serverDraft) {
                        return serverDraft.model.user_id === _this.userId;
                    });
                }
                else {
                    this.deviceDrafts = this.tempDeviceDrafts;
                }
            };
            ChartDraftsSearchFilterService.prototype.resetDrafts = function () {
                this.deviceDrafts = [];
                this.tempDeviceDrafts = [];
                this.serverDrafts = [];
            };
            ChartDraftsSearchFilterService.$inject = ['ClinicalChartDraftsService', 'SessionService', '$filter', 'logger'];
            return ChartDraftsSearchFilterService;
        }());
        charting.ChartDraftsSearchFilterService = ChartDraftsSearchFilterService;
        angular
            .module('consolo')
            .service('ChartDraftsSearchFilterService', ChartDraftsSearchFilterService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

// import pdfMake from '../../node_modules/pdfmake/build/pdfmake';
// import pdfFonts from '../../node_modules/pdfmake/build/vfs_fonts';
var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartingPrintService = /** @class */ (function () {
            function ChartingPrintService(ClinicalChartEntry, PersistedItem, SectionManager, ChartingTemplateService, $filter, $timeout, $mdDialog, //: ng.material.IDialogService,
            jsPDF, EncounterTypes, $q, ChartUtilService, cache, lookupFactory, FamilyMember, $http, logger, CarePlanProblemEntry, pdfer, faxGenerator, Session) {
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.PersistedItem = PersistedItem;
                this.SectionManager = SectionManager;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$filter = $filter;
                this.$timeout = $timeout;
                this.$mdDialog = $mdDialog;
                this.jsPDF = jsPDF;
                this.EncounterTypes = EncounterTypes;
                this.$q = $q;
                this.ChartUtilService = ChartUtilService;
                this.cache = cache;
                this.lookupFactory = lookupFactory;
                this.FamilyMember = FamilyMember;
                this.$http = $http;
                this.logger = logger;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.pdfer = pdfer;
                this.faxGenerator = faxGenerator;
                this.Session = Session;
                this.loading = false;
                this.blockerEnabled = false;
                this.underline = {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 550,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#757575',
                    lineCap: 'round',
                };
                this.hasVisitFrequencyEnabled = false;
                this.hasVisitFrequencyEnabled = this.Session.get().agency.has_visit_frequency;
            }
            ChartingPrintService.prototype.fetchAndPrintChart = function (id, download) {
                var _this = this;
                return this.ChartUtilService.getChartStoreById(id).then(function (chartStore) {
                    return _this.printChartClient(chartStore, download);
                });
            };
            ChartingPrintService.prototype.sectionExists = function (chartStore, key) {
                if (chartStore.model[key]) {
                    if (Array.isArray(chartStore.model[key]) && chartStore.model[key].length) {
                        return true;
                    }
                    else if (!angular.equals(chartStore.model[key], {})) {
                        return true;
                    }
                }
                return false;
            };
            ChartingPrintService.prototype.printChart = function (chartId, patientId, draft, download) {
                if (draft === void 0) { draft = false; }
                if (this.pdfer.enabled && !draft) {
                    return this.pdfer.generatePdfFromStates(['patients.clinical-chart-view'], [{ patientId: patientId, id: chartId }], chartId, 'ClinicalChart', download, {}, false, false, '', '', patientId, false, '#chart-wait-for-selector');
                }
                else {
                    return this.fetchAndPrintChart(chartId, download);
                }
            };
            ChartingPrintService.prototype.faxChart = function (chartId, patientId) {
                return this.faxGenerator.fax(patientId, { clinical_charts: [chartId], id: patientId });
            };
            ChartingPrintService.prototype.printChartClient = function (store, download) {
                var _this = this;
                var printObject = {};
                printObject.chartStore = {
                    model: null,
                    draft: false,
                };
                printObject.chartStore.model = store.model ? store.model : store;
                var hasVitasEncounterCharting = this.Session.get().agency.has_vitas_encounter_charting;
                if (hasVitasEncounterCharting && printObject.chartStore.model && printObject.chartStore.model.encounter_type && printObject.chartStore.model.encounter_type.is_pae_template) {
                    this.printPAEViewChart(store, printObject, download);
                    return;
                }
                this.buildTemplate(printObject);
                //Check for lookups / queries
                var promiseChain = [];
                // let fieldDefinitions = this.ChartingTemplateService.getFieldDefinitions();
                var fieldDefinitions = printObject.formDefinition;
                // let modelKeys = Object.keys(fieldDefinitions);
                printObject.lookups = {};
                var lookupCache = this.cache(this.lookupFactory, 'lookup', { maxAge: 10 });
                for (var _i = 0, _a = printObject.formDefinition; _i < _a.length; _i++) {
                    var screen_1 = _a[_i];
                    for (var _b = 0, _c = screen_1.field_definition; _b < _c.length; _b++) {
                        var section = _c[_b];
                        var _loop_1 = function (f) {
                            if (f.type === 'autocomplete') {
                                if (!printObject.lookups[f.lookup]) {
                                    promiseChain.push(lookupCache[f.lookup]({ limit: -1 }).$promise.then(function (data) {
                                        printObject.lookups[f.lookup] = data;
                                        return _this.$q.when(true);
                                    }));
                                }
                            }
                            else {
                                promiseChain.push(this_1.ChartingTemplateService.setFieldOptions(f, printObject.chartStore.model.patient.id));
                            }
                        };
                        var this_1 = this;
                        for (var _d = 0, _e = section.fields; _d < _e.length; _d++) {
                            var f = _e[_d];
                            _loop_1(f);
                        }
                    }
                }
                if (this.hasCPP(printObject)) {
                    this.loadCarePlanProblems(promiseChain, printObject);
                }
                // let formKeys = Object.keys(this.formModel);
                // if (this.sectionExists(['bereavement_assessments', 'social_assessments', 'teaching_and_learning_assessments', 'visit_notes'])) {
                //   //get family members
                //   this.cache('family_members', 'family_members', { maxAge: 10 })
                //       .query({ patient_id: this.chartStore.model.patient.id })
                //       .$promise.then(data => {
                //         this.lookups['family_members'] = data;
                //         data.concat({
                //           id: this.chartStore.model.patient.name,
                //           description: this.chartStore.model.patient.name,
                //         });
                //       });
                // }
                // if (this.sectionExists(['supervisory_visits', 'teaching_and_learning_assessments'])) {
                //   //get users
                // if (this.sectionExists(['face_to_face_visits'])) {
                //   //get physicians
                //   promiseChain.push(
                //     lookupCache['physicians']({ limit: -1 }).$promise.then(data => {
                //       this.lookups['physicians'] = data;
                //       return this.$q.when(true);
                //     })
                //   );
                // }
                // if (this.sectionExists(['complementary_alternative_medicine_requests'])) {
                //   //get facilities
                //   promiseChain.push(
                //     lookupCache['facilities']({ limit: -1 }).$promise.then(data => {
                //       this.lookups['facilities'] = data;
                //       return this.$q.when(true);
                //     })
                //   );
                // }
                this.$q.all(promiseChain).finally(function () {
                    _this.$timeout(function () {
                        var w = printObject.chartStore.draft ? 'DRAFT' : null;
                        var idString = printObject.chartStore.model.id
                            ? '#' + printObject.chartStore.model.id.toString()
                            : 'DRAFT';
                        var name = printObject.chartStore.model.patient.name + ' Clinical Chart ' + idString;
                        var docDefinition = _this.jsPDF.getBaseDoc(name, printObject.chartStore.model.user.name, w);
                        _this.jsPDF.addFooter(docDefinition);
                        var model = printObject.chartStore.model;
                        var dobDisplay = new Date(new Date(model.patient.dob).toDateString());
                        var dob = model.patient.dob
                            ? dobDisplay.getMonth() +
                                1 +
                                '/' +
                                dobDisplay.getDate() +
                                '/' +
                                dobDisplay.getFullYear()
                            : 'N/A';
                        var header = printObject.chartStore.model.patient.last_name +
                            ', ' +
                            model.patient.first_name +
                            ' (ID: ' +
                            model.patient.id +
                            ', MRN: ' +
                            model.patient.mrn +
                            ', DOB: ' +
                            dob +
                            ')';
                        if (model.patient.current_office) {
                            header += ' Office: ' + model.patient.current_office.name;
                        }
                        _this.jsPDF.addHeader(docDefinition, header);
                        _this.jsPDF.addPatientHeader(docDefinition, printObject.chartStore.model.patient);
                        _this.addGeneralSection(printObject.chartStore.model, docDefinition);
                        if (printObject.chartStore.model['care_plan_problem_updates'] &&
                            printObject.chartStore.model['care_plan_problem_updates'].length) {
                            var cppModel = _this.ChartingTemplateService.getCarePlanProblemSection();
                            printObject.formDefinition.splice(0, 0, cppModel);
                        }
                        for (var _i = 0, _a = printObject.formDefinition; _i < _a.length; _i++) {
                            var page = _a[_i];
                            if (page.name === 'electronic_visit_verification' &&
                                model.electronic_visit_verification) {
                                _this.addEvvSection(model, docDefinition);
                            }
                            if ((page.name === 'visit_task_lists' || page.name === 'visit_task_list') &&
                                model.visit_task_lists) {
                                _this.addVisitTaskListsSection(model, docDefinition);
                            }
                            if (page.name === 'review_of_systems' &&
                                model.review_of_systems &&
                                model.review_of_systems.length) {
                                _this.addReviewOfSystemsSection(model.review_of_systems[0], page, docDefinition);
                            }
                            if (!_this.isCustomPrintSection(page.name)) {
                                var fmArray = _this.ChartingTemplateService.getChartStoreSectionModel(page, printObject.chartStore);
                                var fm = void 0;
                                if (!Array.isArray(fmArray)) {
                                    fmArray = [fmArray];
                                }
                                var idx = 0;
                                for (var _b = 0, fmArray_1 = fmArray; _b < fmArray_1.length; _b++) {
                                    fm = fmArray_1[_b];
                                    if (!fm.isEmpty && printObject.chartStore.model[page.model]) {
                                        idx++;
                                        if (page.allowMultiple) {
                                            docDefinition.content.push({
                                                text: page.title + ' #' + idx,
                                                style: 'sectionHeader',
                                            });
                                            if (page.name === 'care_plan_problem_update') {
                                                _this.addCarePlanProblemSection(model, fm, docDefinition);
                                            }
                                        }
                                        else {
                                            docDefinition.content.push({ text: page.title, style: 'sectionHeader' });
                                        }
                                        for (var _c = 0, _d = page.field_definition; _c < _d.length; _c++) {
                                            var section = _d[_c];
                                            if (section.isEmpty) {
                                                continue;
                                            }
                                            var columnizer = [];
                                            var fieldDisplay = void 0;
                                            for (var _e = 0, _f = section.fields; _e < _f.length; _e++) {
                                                var field = _f[_e];
                                                var title = field.label;
                                                if (fm[field.field] !== null &&
                                                    fm[field.field] !== undefined &&
                                                    !Array.isArray(fm[field.field])) {
                                                    fm[field.field] =
                                                        field.type !== 'checkbox'
                                                            ? fm[field.field].toString()
                                                            : (fm[field.field] = !!fm[field.field]);
                                                }
                                                var customComponents = ["controlled_substance_drug_data"];
                                                if ((customComponents.indexOf(field.field) > -1) &&
                                                    fm[field.field] !== null &&
                                                    fm[field.field] !== undefined &&
                                                    Array.isArray(fm[field.field])) {
                                                    var checkIfDataExist = function (tableData) {
                                                        var data_exists = false;
                                                        if (tableData.length) {
                                                            tableData.forEach(function (row) {
                                                                Object.keys(row).forEach(function (key) {
                                                                    if (row[key]) {
                                                                        data_exists = true;
                                                                    }
                                                                });
                                                            });
                                                        }
                                                        return data_exists;
                                                    };
                                                    if (checkIfDataExist(fm[field.field])) {
                                                        if (field.field === "controlled_substance_drug_data") {
                                                            columnizer.push([
                                                                { text: field.label, style: 'formInputHeader', colSpan: 4 },
                                                                _this.addControlledSubstanceDrugData(fm[field.field], docDefinition)
                                                            ]);
                                                        }
                                                    }
                                                }
                                                if ((customComponents.indexOf(field.field) === -1) &&
                                                    fm[field.field] ||
                                                    fm[field.field + '_unable'] ||
                                                    (field.type === 'booltext' && fm['note_' + field.field]) ||
                                                    (field.type === 'rating' &&
                                                        fm[field.field] !== null &&
                                                        fm[field.field] !== undefined)) {
                                                    var val = fm[field.field + '_unable']
                                                        ? 'Unable to assess'
                                                        : fm[field.field];
                                                    var colSpan = 1;
                                                    if (page.name === "body_systems_activity_musculoskeletal_finding" && field.optionsType === "level_of_functionings") {
                                                        val = _this.getOptionsValue(field, Number(val));
                                                    }
                                                    switch (field.type) {
                                                        case 'date':
                                                            val = _this.jsPDF.dateFilter(val);
                                                            break;
                                                        case 'datetime':
                                                            val = _this.jsPDF.dateTimeFilter(val);
                                                            break;
                                                        case 'rating':
                                                            if (field.minZero &&
                                                                field.options &&
                                                                field.options.length &&
                                                                field.options[val]) {
                                                                val = field.options[val].description;
                                                            }
                                                            else if (!field.minZero &&
                                                                field.options &&
                                                                field.options.length &&
                                                                field.options[val - 1]) {
                                                                val = field.options[val - 1].description;
                                                            }
                                                            break;
                                                        case 'select':
                                                            val = _this.getOptionsValue(field, val);
                                                            break;
                                                        case 'checkbox':
                                                            val = 'Yes';
                                                            break;
                                                        case 'booltext':
                                                            val = fm['note_' + field.field];
                                                            break;
                                                        case 'autocomplete':
                                                            //query //users, facilities, physicians, family_members
                                                            val = _this.getLookupValue(printObject, field, val);
                                                            break;
                                                        case 'checklist':
                                                            val = _this.getOptionsValue(field, val);
                                                            break;
                                                        case 'phone':
                                                            val = _this.$filter('phone')(val);
                                                            break;
                                                        case 'text':
                                                            colSpan = 4;
                                                            break;
                                                        case 'multiselect':
                                                            val = _this.getOptionsValue(field, val);
                                                            break;
                                                    }
                                                    columnizer.push([
                                                        { text: field.label, style: 'formInputHeader', colSpan: colSpan },
                                                        { text: val, style: 'formInput', colSpan: colSpan },
                                                    ]);
                                                }
                                            }
                                            if (section.label !== page.title && columnizer.length) {
                                                docDefinition.content.push({
                                                    text: section.label,
                                                    style: 'subsectionHeader',
                                                });
                                                docDefinition.content.push({
                                                    canvas: [angular.copy(_this.underline)],
                                                    margin: [0, 0, 0, 4],
                                                });
                                            }
                                            var i = 0, row = [], remainingColumns = 4, totalColumns = 4;
                                            while (i < columnizer.length) {
                                                //space remaining, add the column to the row
                                                if (remainingColumns - columnizer[i][0].colSpan >= 0) {
                                                    row.push(columnizer[i]);
                                                    remainingColumns -= columnizer[i][0].colSpan;
                                                    i++;
                                                }
                                                else {
                                                    //not enough space remaining, fill previous row, add to the document and start new row
                                                    for (remainingColumns; remainingColumns > 0; remainingColumns--) {
                                                        row.push([{}]);
                                                    }
                                                    docDefinition.content.push({ columns: row, style: 'fields' });
                                                    row = [];
                                                    remainingColumns = totalColumns;
                                                }
                                            }
                                            //take care of the final row
                                            for (remainingColumns; remainingColumns > 0; remainingColumns--) {
                                                row.push([{}]);
                                            }
                                            docDefinition.content.push({ columns: row, style: 'fields' });
                                            // for (let i = 0; i < columnizer.length; i += 4) {
                                            //   let column = [];
                                            //   // let colspan = columnizer[i][1].expand;
                                            //   // Always add the first column
                                            //   column.push(columnizer[i]);
                                            //   //Add the 2nd column, if we're out of columns add a 2nd to keep format clean
                                            //   if (i + 1 < columnizer.length) {
                                            //     column.push(columnizer[i + 1]);
                                            //   } else if (i + 1 >= columnizer.length) {
                                            //     column.push([{}]);
                                            //   }
                                            //   //Add the 3rd column, if we're out of columns add a 3rd to keep format clean
                                            //   if (i + 2 < columnizer.length) {
                                            //     column.push(columnizer[i + 2]);
                                            //   } else if (i + 2 >= columnizer.length) {
                                            //     column.push([{}]);
                                            //   }
                                            //   //Add the 4th column, if we're out of columns add a 3rd to keep format clean
                                            //   if (i + 3 < columnizer.length) {
                                            //     column.push(columnizer[i + 3]);
                                            //   } else if (i + 3 >= columnizer.length) {
                                            //     column.push([{}]);
                                            //   }
                                            //   docDefinition.content.push({ columns: column, style: 'fields' });
                                            // }
                                        }
                                        //Check for and add content from fields with 'viewSection'
                                        // console.log(page.model, page.name);
                                        var readOnlySections = _this.ChartUtilService.getReadOnlyFields(page.model, printObject.chartStore);
                                        var s = void 0;
                                        for (var _g = 0, readOnlySections_1 = readOnlySections; _g < readOnlySections_1.length; _g++) {
                                            s = readOnlySections_1[_g];
                                            for (var _h = 0, _j = s.field_definition; _h < _j.length; _h++) {
                                                var fd = _j[_h];
                                                for (var _k = 0, _l = fd.fields; _k < _l.length; _k++) {
                                                    var f = _l[_k];
                                                    console.log('label', f.label, 'value', s.values[f.field]);
                                                    docDefinition.content.push([
                                                        { text: f.label, style: 'formInputHeader', colSpan: 4 },
                                                        { text: s.values[f.field], style: 'formInput', colSpan: 4 },
                                                    ]);
                                                }
                                            }
                                        }
                                        _this.addSectionAddendums(page.name, model, page, docDefinition);
                                    }
                                }
                            }
                        }
                        if (printObject.chartStore.model.signatures) {
                            _this.jsPDF.addSignatures(docDefinition, printObject.chartStore.model.signatures);
                        }
                        if (download) {
                            return _this.jsPDF.downloadPdf(docDefinition, _this.generateDefaultFileName(printObject));
                        }
                        else {
                            _this.jsPDF.showPdf(docDefinition);
                            return _this.$q.when(true);
                        }
                    }, 500);
                });
            };
            ChartingPrintService.prototype.printPAEViewChart = function (chartStore, printObject, download) {
                var _this = this;
                var formDefinition = [];
                this.ChartingTemplateService.getPAEViewFormDefinition(chartStore).then(function (formDef) {
                    formDefinition = formDef;
                    buildPdf();
                });
                var addTable = function (fieldData, docDefinition) {
                    var dataTable = {
                        layout: {
                            hLineWidth: function (i, node) {
                                return 2;
                            },
                            vLineWidth: function (i, node) {
                                return 2;
                            },
                            hLineColor: function (i, node) {
                                return '#f4f4f4';
                            },
                            vLineColor: function (i, node) {
                                return '#f4f4f4';
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex === 0) ? '#e6e6e6' : null;
                            }
                        },
                        table: {
                            headerRows: 1,
                            widths: ['auto', 'auto', 'auto', 'auto'],
                            body: [],
                        },
                        fontSize: 12,
                    };
                    // Load data into table body
                    var colArray = [];
                    var width = [];
                    fieldData.cols.forEach(function (col) {
                        colArray.push({ text: col, bold: true, fontSize: 12 });
                        width.push("auto");
                    });
                    dataTable.table.widths = width;
                    dataTable.table.body.push(colArray);
                    fieldData.rows.forEach(function (row, index) {
                        if (!row.isEmpty) {
                            var temp = [];
                            for (var key in row) {
                                if (key !== "isEmpty" && key !== "$$hashKey") {
                                    (key == "label" || fieldData.custom) ? temp.push({ text: row[key], bold: (key == "label" ? true : false) } || " ") : temp.push(row[key]["displayValue"] || " ");
                                }
                            }
                            dataTable.table.body.push(temp);
                        }
                    });
                    return dataTable;
                };
                var buildPdf = function () {
                    _this.$timeout(function () {
                        var w = printObject.chartStore.draft ? 'DRAFT' : null;
                        var idString = printObject.chartStore.model.id
                            ? '#' + printObject.chartStore.model.id.toString()
                            : 'DRAFT';
                        var name = printObject.chartStore.model.patient.name + ' Clinical Chart ' + idString;
                        var docDefinition = _this.jsPDF.getBaseDoc(name, printObject.chartStore.model.user.name, w);
                        _this.jsPDF.addFooter(docDefinition);
                        var model = printObject.chartStore.model;
                        var dobDisplay = new Date(new Date(model.patient.dob).toDateString());
                        var dob = model.patient.dob
                            ? dobDisplay.getMonth() +
                                1 +
                                '/' +
                                dobDisplay.getDate() +
                                '/' +
                                dobDisplay.getFullYear()
                            : 'N/A';
                        var header = printObject.chartStore.model.patient.last_name +
                            ', ' +
                            model.patient.first_name +
                            ' (ID: ' +
                            model.patient.id +
                            ', MRN: ' +
                            model.patient.mrn +
                            ', DOB: ' +
                            dob +
                            ')';
                        if (model.patient.current_office) {
                            header += ' Office: ' + model.patient.current_office.name;
                        }
                        _this.jsPDF.addHeader(docDefinition, header);
                        var addGeneralSection = function (page, clinicalChart, docDefinition) {
                            docDefinition.content.push({
                                style: 'paeSectionHeader',
                                table: {
                                    height: '42',
                                    widths: '*',
                                    body: [
                                        [{
                                                border: [false, false, false, false],
                                                fillColor: '#bbc3c8',
                                                text: 'General Details',
                                            }]
                                    ]
                                }
                            });
                            if (!clinicalChart.created_by) {
                                clinicalChart.created_by = { name: '' };
                            }
                            var patient = clinicalChart.patient;
                            var address = '';
                            if (patient.current_address) {
                                address =
                                    patient.current_address.address_1 +
                                        '\n' +
                                        patient.current_address.city +
                                        ', ' +
                                        patient.current_address.state +
                                        ' ' +
                                        patient.current_address.zipcode;
                            }
                            page.field_definition[0].fields.unshift({
                                label: 'Status',
                                displayValue: patient.care_status
                            }, {
                                label: 'Patient Information',
                                displayValue: address
                            });
                            var noOfColumns = 3;
                            for (var _i = 0, _a = page.field_definition; _i < _a.length; _i++) {
                                var subSection = _a[_i];
                                var cols = [];
                                for (var _b = 0, _c = subSection.fields; _b < _c.length; _b++) {
                                    var field = _c[_b];
                                    if (field.displayValue) {
                                        cols.push([
                                            { text: field.label, style: 'formInputHeader', alignment: 'left' },
                                            { text: field.displayValue, style: 'formInput', alignment: 'left' },
                                        ]);
                                    }
                                    noOfColumns--;
                                    if (noOfColumns == 0) {
                                        noOfColumns = 3;
                                        docDefinition.content.push({
                                            columns: cols,
                                            style: 'fields',
                                        });
                                        cols = [];
                                    }
                                }
                                docDefinition.content.push({
                                    columns: cols,
                                    style: 'fields',
                                });
                            }
                            var columnArray = [];
                            if (clinicalChart.facility) {
                                columnArray.push([
                                    { text: 'Facility', style: 'formInputHeader', alignment: 'left' },
                                    { text: clinicalChart.facility.name, style: 'formInput', alignment: 'left' },
                                ]);
                            }
                            if (clinicalChart.visit_classification) {
                                columnArray.push([
                                    { text: 'Visit Classification', style: 'formInputHeader', alignment: 'left' },
                                    { text: clinicalChart.visit_classification, style: 'formInput', alignment: 'left' },
                                ]);
                            }
                            if (columnArray.length) {
                                docDefinition.content.push({
                                    columns: columnArray,
                                    style: 'fields',
                                });
                            }
                            if (clinicalChart.addendums && clinicalChart.addendums.length) {
                                docDefinition.content.push({
                                    text: 'Addendums',
                                    style: 'sectionHeader',
                                });
                                var addendums_data_1 = [['Date', 'Signed By', 'Reason', 'Section', 'Note']];
                                clinicalChart.addendums.forEach(function (addendum) {
                                    addendums_data_1.push([
                                        addendum.created_at,
                                        addendum.signed_by,
                                        addendum.reason,
                                        addendum.section_name,
                                        addendum.note,
                                    ]);
                                });
                                docDefinition.content.push({
                                    table: {
                                        body: addendums_data_1,
                                    },
                                });
                            }
                        };
                        for (var _i = 0, formDefinition_1 = formDefinition; _i < formDefinition_1.length; _i++) {
                            var page = formDefinition_1[_i];
                            if (page.name === 'general') {
                                addGeneralSection(page, printObject.chartStore.model, docDefinition);
                            }
                            if (page.name === 'electronic_visit_verification' &&
                                model.electronic_visit_verification && !page.isEmpty) {
                                _this.addEvvSection(model, docDefinition, true);
                            }
                            if ((page.name === 'visit_task_lists' || page.name === 'visit_task_list') &&
                                model.visit_task_lists) {
                                _this.addVisitTaskListsSection(model, docDefinition, true);
                            }
                            if (page.name === 'review_of_systems' &&
                                model.review_of_systems &&
                                model.review_of_systems.length) {
                                _this.addReviewOfSystemsSection(model.review_of_systems[0], page, docDefinition, true);
                            }
                            if (!_this.isCustomPrintSection(page.name)) {
                                var fmArray = page.modelData;
                                var fm = void 0;
                                if (!Array.isArray(fmArray)) {
                                    fmArray = [fmArray];
                                }
                                var idx = 0;
                                for (var _a = 0, fmArray_2 = fmArray; _a < fmArray_2.length; _a++) {
                                    fm = fmArray_2[_a];
                                    if (printObject.chartStore.model[page.model] && !page.isEmpty) {
                                        idx++;
                                        docDefinition.content.push({
                                            style: 'paeSectionHeader',
                                            table: {
                                                height: '42',
                                                widths: '*',
                                                body: [
                                                    [{
                                                            border: [false, false, false, false],
                                                            fillColor: '#bbc3c8',
                                                            text: page.title,
                                                        }]
                                                ]
                                            }
                                        });
                                        for (var _b = 0, _c = page.field_definition; _b < _c.length; _b++) {
                                            var section = _c[_b];
                                            var columnizer = [];
                                            var fieldDisplay = void 0;
                                            if (section.isEmpty) {
                                                continue;
                                            }
                                            if (section.type === "table") {
                                                columnizer.push([
                                                    { text: '', style: 'formInputHeader', colSpan: 4 },
                                                    addTable(section, docDefinition)
                                                ]);
                                            }
                                            if (section.fields && section.fields.length) {
                                                for (var _d = 0, _e = section.fields; _d < _e.length; _d++) {
                                                    var field = _e[_d];
                                                    var title = field.label;
                                                    if (fm[field.field] !== null &&
                                                        fm[field.field] !== undefined &&
                                                        !Array.isArray(fm[field.field])) {
                                                        fm[field.field] =
                                                            field.type !== 'checkbox'
                                                                ? fm[field.field].toString()
                                                                : (fm[field.field] = !!fm[field.field]);
                                                    }
                                                    if (fm[field.field] ||
                                                        fm[field.field + '_unable'] ||
                                                        (field.type === 'booltext' && fm['note_' + field.field]) ||
                                                        (field.type === 'rating' &&
                                                            fm[field.field] !== null &&
                                                            fm[field.field] !== undefined)) {
                                                        var colSpan = 2;
                                                        if (field.type !== 'body site') {
                                                            columnizer.push([
                                                                { text: field.label, style: 'formInputHeader', colSpan: colSpan },
                                                                { text: field.displayValue, style: 'formInput', colSpan: colSpan },
                                                            ]);
                                                        }
                                                    }
                                                }
                                            }
                                            if (section.label !== page.title && columnizer.length && section.label) {
                                                docDefinition.content.push({
                                                    style: 'paeSubsectionHeader',
                                                    table: {
                                                        height: '42',
                                                        widths: '*',
                                                        body: [
                                                            [{
                                                                    border: [false, false, false, false],
                                                                    fillColor: '#F0F0F0',
                                                                    bold: true,
                                                                    text: section.label,
                                                                }]
                                                        ]
                                                    }
                                                });
                                            }
                                            var i = 0, row = [], remainingColumns = 4, totalColumns = 4;
                                            while (i < columnizer.length) {
                                                //space remaining, add the column to the row
                                                if (remainingColumns - columnizer[i][0].colSpan >= 0) {
                                                    row.push(columnizer[i]);
                                                    remainingColumns -= columnizer[i][0].colSpan;
                                                    i++;
                                                }
                                                else {
                                                    //not enough space remaining, fill previous row, add to the document and start new row
                                                    for (remainingColumns; remainingColumns > 0; remainingColumns--) {
                                                        row.push([{}]);
                                                    }
                                                    docDefinition.content.push({ columns: row, style: 'fields' });
                                                    row = [];
                                                    remainingColumns = totalColumns;
                                                }
                                            }
                                            //take care of the final row
                                            for (remainingColumns; remainingColumns > 0; remainingColumns--) {
                                                row.push([{}]);
                                            }
                                            docDefinition.content.push({ columns: row, style: 'fields' });
                                        }
                                        _this.addSectionAddendums(page.name, model, page, docDefinition);
                                    }
                                }
                            }
                        }
                        if (printObject.chartStore.model.signatures) {
                            _this.jsPDF.addSignatures(docDefinition, printObject.chartStore.model.signatures);
                        }
                        if (download) {
                            return _this.jsPDF.downloadPdf(docDefinition, _this.generateDefaultFileName(printObject));
                        }
                        else {
                            _this.jsPDF.showPdf(docDefinition);
                            return _this.$q.when(true);
                        }
                    }, 500);
                };
            };
            ChartingPrintService.prototype.isCustomPrintSection = function (sectionName) {
                var customSections = [
                    'general',
                    'electronic_visit_verification',
                    'visit_task_lists',
                    'visit_task_list',
                    'review_of_systems',
                ];
                return customSections.indexOf(sectionName) >= 0;
            };
            ChartingPrintService.prototype.addSectionAddendums = function (pageName, model, formDefinition, docDefinition) {
                switch (pageName) {
                    case 'face_to_face_visit':
                        this.addFaceToFaceVisitAttestation(model, formDefinition, docDefinition);
                        break;
                    default:
                        break;
                }
            };
            ChartingPrintService.prototype.addGeneralSection = function (clinicalChart, docDefinition, isPAE) {
                var _this = this;
                if (isPAE === void 0) { isPAE = false; }
                var header = isPAE ? {
                    style: 'paeSectionHeader',
                    table: {
                        height: '42',
                        widths: '*',
                        body: [
                            [{
                                    border: [false, false, false, false],
                                    fillColor: '#bbc3c8',
                                    text: 'General Clinical Chart Details',
                                }]
                        ]
                    }
                } : {
                    text: 'General Clinical Chart Details',
                    style: 'sectionHeader',
                };
                docDefinition.content.push(header);
                if (!clinicalChart.created_by) {
                    clinicalChart.created_by = { name: '' };
                }
                var columnArray = [
                    [
                        { text: 'Effective Date', style: 'formInputHeader', alignment: 'left' },
                        {
                            text: this.jsPDF.dateFilter(clinicalChart.effective_date),
                            style: 'formInput',
                            alignment: 'left',
                        },
                    ],
                    [
                        { text: 'Discipline', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.role_name, style: 'formInput', alignment: 'left' },
                    ],
                    [
                        { text: 'Chart Owner', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.user.name, style: 'formInput', alignment: 'left' },
                    ],
                    [
                        { text: 'Created By', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.created_by.name, style: 'formInput', alignment: 'left' },
                    ],
                ];
                if (this.hasVisitFrequencyEnabled) {
                    columnArray.shift();
                }
                if (clinicalChart.facility) {
                    columnArray.push([
                        { text: 'Facility', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.facility.name, style: 'formInput', alignment: 'left' },
                    ]);
                }
                if (clinicalChart.visit_classification) {
                    columnArray.push([
                        { text: 'Visit Classification', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.visit_classification, style: 'formInput', alignment: 'left' },
                    ]);
                }
                docDefinition.content.push({
                    columns: columnArray,
                    style: 'fields',
                });
                var columnArray2 = [];
                if (clinicalChart.source_of_information) {
                    var text_1 = "";
                    clinicalChart.source_of_information.forEach(function (value) {
                        for (var i = 0; i < _this.ChartingTemplateService.source_of_information.length; i++) {
                            if (_this.ChartingTemplateService.source_of_information[i].value === value) {
                                if (text_1) {
                                    text_1 += ', ';
                                }
                                text_1 += _this.ChartingTemplateService.source_of_information[i].text;
                            }
                        }
                    });
                    columnArray2.push([
                        { text: 'Source of Information', style: 'formInputHeader', alignment: 'left' },
                        { text: text_1, style: 'formInput', alignment: 'left' },
                    ]);
                }
                if (clinicalChart.other) {
                    columnArray2.push([
                        { text: 'Other', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.other, style: 'formInput', alignment: 'left' },
                    ]);
                }
                if (clinicalChart.purpose_of_visit) {
                    columnArray2.push([
                        { text: 'Purpose of Visit', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.purpose_of_visit.join(', '), style: 'formInput', alignment: 'left' },
                    ]);
                }
                if (clinicalChart.purpose_of_visit_other) {
                    columnArray2.push([
                        { text: 'Purpose of Visit Other', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.purpose_of_visit_other, style: 'formInput', alignment: 'left' },
                    ]);
                }
                if (clinicalChart.two_patient_identifiers_used) {
                    columnArray2.push([
                        { text: 'Two Patient Identifiers Used', style: 'formInputHeader', alignment: 'left' },
                        { text: clinicalChart.two_patient_identifiers_used, style: 'formInput', alignment: 'left' },
                    ]);
                }
                docDefinition.content.push({
                    columns: columnArray2,
                    style: 'fields',
                });
                if (clinicalChart.addendums && clinicalChart.addendums.length) {
                    docDefinition.content.push({
                        text: 'Addendums',
                        style: 'sectionHeader',
                    });
                    var addendums_data_2 = [['Date', 'Signed By', 'Reason', 'Section', 'Note']];
                    clinicalChart.addendums.forEach(function (addendum) {
                        addendums_data_2.push([
                            addendum.created_at,
                            addendum.signed_by,
                            addendum.reason,
                            addendum.section_name,
                            addendum.note,
                        ]);
                    });
                    docDefinition.content.push({
                        table: {
                            body: addendums_data_2,
                        },
                    });
                }
            };
            ChartingPrintService.prototype.addFaceToFaceVisitAttestation = function (model, formDefinition, docDefinition) {
                var _a;
                console.log('model', model);
                // columnizer.push([
                //   { text: field.label, style: 'formInputHeader', colSpan: colSpan },
                //   { text: val, style: 'formInput', colSpan: colSpan },
                // ]);
                var f2f, physician;
                if (!model.face_to_face_visits && !model.face_to_face_visit.length) {
                    return;
                }
                else {
                    f2f = model.face_to_face_visits[0];
                    physician = f2f.physician;
                }
                // if (f2f.signatures && !f2f.signatures.length) {
                //   console.log('no signatures', f2f);
                //   return;
                // }
                docDefinition.content.push({
                    text: 'Face to Face Physician Attestation',
                    style: 'formInputHeader',
                });
                var attestationText = "I confirm that I had a face-to-face encounter with " + model.patient.name + " " +
                    ("on " + f2f.assessment_date + " and that the clinical findings of that encounter have been ");
                if ((_a = physician) === null || _a === void 0 ? void 0 : _a.is_medical_director) {
                    attestationText += 'used in determining continued eligibility for hospice care.';
                }
                else {
                    attestationText +=
                        'provided to the certifying physician for use in determining continued eligibility for hospice care.';
                }
                docDefinition.content.push({ text: attestationText, style: 'formInput' });
                for (var _i = 0, _b = f2f.signatures; _i < _b.length; _i++) {
                    var s = _b[_i];
                    this.jsPDF.addSignatures(docDefinition, f2f.signatures, false);
                }
                if (!f2f.signatures.length) {
                    docDefinition.content.push({
                        text: 'Signature:  _________________________________________________________________    Date:  _________________________',
                        style: 'formInput',
                    });
                }
            };
            ChartingPrintService.prototype.addReviewOfSystemsSection = function (model, formDefinition, docDefinition, isPAE) {
                if (isPAE === void 0) { isPAE = false; }
                var header = isPAE ? {
                    style: 'paeSectionHeader',
                    table: {
                        height: '42',
                        widths: '*',
                        body: [
                            [{
                                    border: [false, false, false, false],
                                    fillColor: '#bbc3c8',
                                    text: 'Review of Systems',
                                }]
                        ]
                    }
                } : {
                    text: 'Review of Systems',
                    style: 'sectionHeader',
                };
                docDefinition.content.push(header);
                var reasonText = model.reason || 'No reason given';
                if (model.unable_to_obtain) {
                    docDefinition.content.push({
                        text: 'Unable to Obtain',
                        style: 'subsectionHeader',
                    }, {
                        text: reasonText,
                    });
                }
                for (var _i = 0, _a = formDefinition.field_definition; _i < _a.length; _i++) {
                    var field = _a[_i];
                    if (!model[field.name]) {
                        model[field.name] = { display_text: 'Not reviewed' };
                    }
                    else {
                        for (var _b = 0, _c = field.fields; _b < _c.length; _b++) {
                            var item = _c[_b];
                            if (model[field.name][item.field] !== undefined) {
                                item.value = !!model[field.name][item.field];
                            }
                        }
                        this.ChartUtilService.updateDisplayText(field.name, model, true);
                    }
                    if (model[field.name].display_text !== 'Not reviewed') {
                        docDefinition.content.push({
                            text: field.label,
                            style: 'subsectionHeader',
                        }, {
                            text: model[field.name].display_text,
                        });
                        if (model[field.name].comment_array) {
                            for (var _d = 0, _e = model[field.name].comment_array; _d < _e.length; _d++) {
                                var c = _e[_d];
                                docDefinition.content.push({
                                    text: c,
                                    margin: [0, 8, 0, 0],
                                });
                            }
                        }
                    }
                }
            };
            ChartingPrintService.prototype.addVisitTaskListsSection = function (chart, docDefinition, isPAE) {
                var _this = this;
                if (isPAE === void 0) { isPAE = false; }
                var visit_task_lists = chart.visit_task_lists;
                if (visit_task_lists && visit_task_lists.length) {
                    var header = isPAE ? {
                        style: 'paeSectionHeader',
                        table: {
                            height: '42',
                            widths: '*',
                            body: [
                                [{
                                        border: [false, false, false, false],
                                        fillColor: '#bbc3c8',
                                        text: 'Visit Task Lists',
                                    }]
                            ]
                        }
                    } : {
                        text: 'Visit Task Lists',
                        style: 'sectionHeader',
                    };
                    docDefinition.content.push(header);
                    visit_task_lists.forEach(function (task) {
                        docDefinition.content.push({
                            text: task.description,
                            style: { fontSize: 12, bold: true, margin: [0, 4, 0, 4] },
                        });
                        if (task.recurring_task_actions.length) {
                            docDefinition.content.push({
                                columns: [
                                    [{ text: 'Description', style: 'formInput' }],
                                    [{ text: 'Acknowledged', style: 'formInput' }],
                                    [{ text: 'Completed', style: 'formInput' }],
                                ],
                                style: 'fields',
                            });
                            docDefinition.content.push({
                                canvas: [angular.copy(_this.underline)],
                                margin: [0, 0, 0, 4],
                            });
                            task.recurring_task_actions.forEach(function (action) {
                                docDefinition.content.push({
                                    columns: [
                                        [{ text: action.description, style: 'formInput' }],
                                        [{ text: consolo.common.yesNo(action.acknowledged), style: 'formInput' }],
                                        [{ text: consolo.common.yesNo(action.completed), style: 'formInput' }],
                                    ],
                                    style: 'fields',
                                });
                            });
                        }
                    });
                }
            };
            ChartingPrintService.prototype.addEvvSection = function (chart, docDefinition, isPAE) {
                if (isPAE === void 0) { isPAE = false; }
                var electronic_visit_verification = chart.electronic_visit_verification;
                if (electronic_visit_verification) {
                    var header = isPAE ? {
                        style: 'paeSectionHeader',
                        table: {
                            height: '42',
                            widths: '*',
                            body: [
                                [{
                                        border: [false, false, false, false],
                                        fillColor: '#bbc3c8',
                                        text: 'Electronic Visit Verification',
                                    }]
                            ]
                        }
                    } : {
                        text: 'Electronic Visit Verification',
                        style: 'sectionHeader',
                    };
                    docDefinition.content.push(header);
                    var columnArray = [
                        [
                            { text: 'Latitude', style: 'formInputHeader' },
                            { text: electronic_visit_verification.latitude, style: 'formInput' },
                        ],
                        [
                            { text: 'Longitude', style: 'formInputHeader' },
                            { text: electronic_visit_verification.longitude, style: 'formInput' },
                        ],
                        [
                            { text: 'Accuracy', style: 'formInputHeader' },
                            { text: electronic_visit_verification.accuracy, style: 'formInput' },
                        ],
                    ];
                    if (electronic_visit_verification.signature_url) {
                        columnArray.push([
                            { text: 'Signature', style: 'formInputHeader' },
                            { text: 'E-Signed by: ' + chart.user.name, style: 'formInput' },
                        ]);
                    }
                    docDefinition.content.push({
                        columns: columnArray,
                        style: 'fields',
                    });
                }
            };
            ChartingPrintService.prototype.addCarePlanProblemSection = function (model, fm, docDefinition) {
                var cpp = fm;
                // Set CPP Heading, CPP Problem and Goal
                var cppHeaders = [
                    {
                        columns: [
                            {
                                width: '25%',
                                text: 'Care Plan Problem',
                                style: 'formInputHeader',
                            },
                            {
                                width: '25%',
                                text: 'Care Plan Goal',
                                style: 'formInputHeader',
                            },
                            {
                                width: '25%',
                                text: 'Participants',
                                style: 'formInputHeader',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                width: '25%',
                                text: cpp.problem,
                                style: 'formInput',
                            },
                            {
                                width: '25%',
                                text: cpp.goal,
                                style: 'formInput',
                            },
                            {
                                width: '25%',
                                text: cpp.participants
                                    .map(function (participant) {
                                    return participant['description'];
                                })
                                    .join('; '),
                                style: 'formInput',
                            },
                        ],
                    },
                    {
                        text: 'Care Plan Problem Interventions',
                        style: 'subsectionHeader',
                    },
                ];
                // Load all CPP heading sections into document
                cppHeaders.forEach(function (header) {
                    docDefinition.content.push(header);
                });
                // Setup Intervention Table
                var interventionTable = {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        widths: [150, '*', 'auto', '*', '*', 'auto', 'auto'],
                        body: [
                            [
                                'Name',
                                'Due Date',
                                'Action Performed',
                                'Outcome',
                                'P/F Engagement',
                                'Resolved?',
                                'Comments',
                            ],
                        ],
                    },
                    fontSize: 10,
                };
                // Load Interventions into table body
                cpp.interventions.forEach(function (intervention, index) {
                    interventionTable.table.body.push([
                        intervention.name,
                        intervention.due_date,
                        cpp.current_care_plan_problem_update.interventions[index].action_performed,
                        cpp.current_care_plan_problem_update.interventions[index].outcome,
                        cpp.current_care_plan_problem_update.interventions[index].patient_family_engagement,
                        cpp.current_care_plan_problem_update.interventions[index].resolved ? 'Yes' : 'No',
                        cpp.current_care_plan_problem_update.interventions[index].comments,
                    ]);
                });
                // Load Intervention Table
                docDefinition.content.push(interventionTable);
                // Setup CPP Update Section
                var cppUpdate = [
                    {
                        text: 'Care Plan Problem Update',
                        style: 'subsectionHeader',
                    },
                    {
                        canvas: [angular.copy(this.underline)],
                        margin: [0, 0, 0, 4],
                    },
                    {
                        columns: [
                            {
                                width: '25%',
                                text: 'Effective Date',
                                style: 'formInputHeader',
                            },
                            {
                                width: '25%',
                                text: 'Created By',
                                style: 'formInputHeader',
                            },
                            {
                                width: '25%',
                                text: 'Outcome',
                                style: 'formInputHeader',
                            },
                            {
                                width: '25%',
                                text: 'P/F Engagement',
                                style: 'formInputHeader',
                            },
                        ],
                    },
                    {
                        columns: [
                            {
                                width: '25%',
                                text: cpp.effective_date,
                                style: 'formInput',
                            },
                            {
                                width: '25%',
                                text: cpp.current_care_plan_problem_update.created_by_name,
                                style: 'formInput',
                            },
                            {
                                width: '25%',
                                text: cpp.current_care_plan_problem_update.outcome,
                                style: 'formInput',
                            },
                            {
                                width: '25%',
                                text: cpp.patient_family_engagement,
                                style: 'formInput',
                            },
                        ],
                    },
                ];
                // Load Update Info
                docDefinition.content.push(cppUpdate);
                // Load Comments
                docDefinition.content.push({ text: 'Comments', style: 'formInputHeader' }, { text: cpp.current_care_plan_problem_update.comments, style: 'formInput' });
            };
            ChartingPrintService.prototype.addControlledSubstanceDrugData = function (rowData, docDefinition) {
                var controlledSubstanceDrugTable = {
                    layout: 'lightHorizontalLines',
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto'],
                        body: [['Controlled Substance Drug', 'Controlled Substance Count at Shift Start', 'Controlled Substance Count at Shift End']],
                    },
                    fontSize: 8,
                };
                // Load data into table body
                rowData.forEach(function (row, index) {
                    controlledSubstanceDrugTable.table.body.push([row.controlled_substance_drug, row.controlled_substance_count_start, row.controlled_substance_count_end]);
                });
                return controlledSubstanceDrugTable;
            };
            ChartingPrintService.prototype.loadCarePlanProblems = function (promiseChain, printObject) {
                var _this = this;
                var cpp = printObject.chartStore.model.care_plan_problem_updates;
                cpp.forEach(function (cppItem, index) {
                    promiseChain.push(_this.CarePlanProblemEntry.get({ id: cppItem.care_plan_problem_id }).$promise.then(function (response) {
                        cpp[index] = response;
                    }));
                });
            };
            ChartingPrintService.prototype.hasCPP = function (printObject) {
                if (printObject.chartStore.model.care_plan_problem_updates !== undefined) {
                    return true;
                }
                else {
                    return false;
                }
            };
            ChartingPrintService.prototype.generateDefaultFileName = function (printObject) {
                var now = new Date();
                var s = printObject.chartStore.model.patient.name
                    .trim()
                    .replace(/,/, '')
                    .replace(/ /, '_');
                s += '_clinical_chart_' + printObject.chartStore.model.id;
                s += this.$filter('date')(now, 'YYYYMMDD_HHmm') + '.pdf';
                return s;
            };
            ChartingPrintService.prototype.getOptionsValue = function (field, value) {
                var lookup = field.value_field ? field.value_field : 'id';
                if (!field.options || !field.options.length) {
                    return value;
                }
                if (Array.isArray(value)) {
                    var retValues = value.map(function (id) {
                        var item = null;
                        field.options.some(function (option) {
                            if (option[lookup] === id) {
                                item = option.description;
                                return true;
                            }
                        });
                        return item;
                    });
                    return retValues ? retValues.join(', ') : value;
                }
                else {
                    var retVal = field.options.filter(function (item) {
                        return item[lookup] === value;
                    })[0];
                    return retVal ? retVal.description : value;
                }
            };
            ChartingPrintService.prototype.getLookupValue = function (printObject, field, value) {
                var retVal = printObject.lookups[field.lookup].filter(function (item) {
                    return item.id.toString() === value;
                })[0];
                return retVal ? retVal.description || retVal.name : value;
            };
            ChartingPrintService.prototype.buildTemplate = function (printObject) {
                printObject.screenList = [];
                printObject.sectionList = [];
                printObject.missingSections = [];
                printObject.chartEntry = this.PersistedItem('clinical_chart_entry', printObject.chartStore.model);
                printObject.model = printObject.chartEntry.model;
                var templateName = this.ChartingTemplateService.getTemplateName(printObject.model);
                if (this.SectionManager.isEncounterCharting) {
                    if (!isNaN(printObject.model.encounter_type_id)) {
                        printObject.missingSections = this.ChartingTemplateService.getMissingSections(printObject.model.encounter_type);
                    }
                    else {
                        printObject.model.encounter_type_id = 'encounter_charting';
                    }
                }
                printObject.screenList = this.ChartingTemplateService.getScreens(templateName);
                var flattened = this.ChartingTemplateService.getFlattenedSections(templateName);
                var modelList = [];
                printObject.sectionList = [];
                for (var _i = 0, flattened_1 = flattened; _i < flattened_1.length; _i++) {
                    var section = flattened_1[_i];
                    if (section.rootNode) {
                        printObject.sectionList.push(section);
                    }
                    else if (printObject.model[section.model]) {
                        if (section.singleNode || printObject.model[section.model].length) {
                            if (modelList.indexOf(section.model) < 0) {
                                modelList.push(section.model);
                                section.active = true;
                                printObject.sectionList.push(section);
                            }
                        }
                    }
                }
                for (var _a = 0, _b = printObject.missingSections; _a < _b.length; _a++) {
                    var section = _b[_a];
                    if (!section.rootNode && printObject.model[section.model]) {
                        if (section.singleNode || printObject.model[section.model].length) {
                            if (modelList.indexOf(section.model) < 0) {
                                modelList.push(section.model);
                                section.active = true;
                                printObject.sectionList.push(section);
                            }
                        }
                    }
                }
                var definitions = [];
                var def;
                for (var _c = 0, _d = printObject.sectionList; _c < _d.length; _c++) {
                    var section = _d[_c];
                    def = this.ChartingTemplateService.getFormDefinition(section.name, printObject.model.encounter_type_id, printObject.model.patient.id, printObject.model.encounter_type);
                    if (def) {
                        definitions.push(def[0]);
                    }
                }
                printObject.formDefinition = definitions;
                printObject.formModel = printObject.model;
                this.setModelEmptyState(printObject);
            };
            ChartingPrintService.prototype.pageIsEmpty = function (printObject, page) {
                // general properties exist on root of formModel and always exist
                if (page.model === 'general') {
                    return false;
                }
                if (!printObject.formModel[page.model]) {
                    return true;
                }
                return Array.isArray(printObject.formModel[page.model])
                    ? printObject.formModel[page.model].every(function (model) { return model.isEmpty; })
                    : printObject.formModel[page.model].isEmpty;
            };
            ChartingPrintService.prototype.setModelEmptyState = function (printObject) {
                // const modelIsEmpty = (model, fieldDef) =>
                //   Array.isArray(fieldDef) ? fieldDef.every(section => section.isEmpty) : fieldDef.isEmpty;
                for (var _i = 0, _a = printObject.formDefinition; _i < _a.length; _i++) {
                    var page = _a[_i];
                    if (printObject.formModel[page.model]) {
                        // evv does not follow conventional pattern, can assume not empty if exists
                        if (page.model === 'electronic_visit_verification') {
                            printObject.formModel[page.model].isEmpty = false;
                            continue;
                        }
                        if (Array.isArray(printObject.formModel[page.model])) {
                            for (var _b = 0, _c = printObject.formModel[page.model]; _b < _c.length; _b++) {
                                var model = _c[_b];
                                model.isEmpty = this.ChartUtilService.checkEmptySections(page, model);
                            }
                        }
                        else {
                            printObject.formModel[page.model].isEmpty = this.ChartUtilService.checkEmptySections(page, printObject.formModel[page.model]);
                        }
                    }
                }
            };
            // checkAdBlock() {
            //   let tempId = 'adTestDiv';
            //   let adTest = document.createElement('div');
            //   let banner = document.createElement('div');
            //   adTest.id = tempId;
            //   banner.classList.add('adBanner');
            //   adTest.appendChild(banner);
            //   document.body.appendChild(adTest);
            //   let exists = angular.element(document.getElementById(tempId));
            //   this.blockerEnabled = !(exists.height() > 0);
            //   document.body.removeChild(adTest);
            // }
            // createPdfBinary(pdfDoc, callback) {
            //   // var fontDescriptors = {
            //   //   Roboto: {
            //   //     normal: path.join(__dirname, '..', 'examples', '/fonts/Roboto-Regular.ttf'),
            //   //     bold: path.join(__dirname, '..', 'examples', '/fonts/Roboto-Medium.ttf'),
            //   //     italics: path.join(__dirname, '..', 'examples', '/fonts/Roboto-Italic.ttf'),
            //   //     bolditalics: path.join(__dirname, '..', 'examples', '/fonts/Roboto-MediumItalic.ttf')
            //   //   }
            //   // };
            //   var printer = new pdfMakePrinter();
            //   var doc = printer.createPdfKitDocument(pdfDoc);
            //   var chunks = [];
            //   var result;
            //   doc.on('data', function (chunk) {
            //     chunks.push(chunk);
            //   });
            //   doc.on('end', function () {
            //     result = Buffer.concat(chunks);
            //     callback('data:application/pdf;base64,' + result.toString('base64'));
            //   });
            //   doc.end();
            // }
            ChartingPrintService.prototype.printCombinedPdf = function (chartIds) {
                var _this = this;
                return this.$http
                    .post('/api/v1/clinical_charts/print', { chart_ids: chartIds })
                    .then(function (response) {
                    _this.logger.success('Job has been queued for processing');
                    console.log(response);
                    return response;
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            // private lookups = {};
            // private options = {};
            ChartingPrintService.$inject = [
                'ClinicalChartEntry',
                'PersistedItem',
                'ChartingSectionManager',
                'ChartingTemplateService',
                '$filter',
                '$timeout',
                '$mdDialog',
                'jsPDF',
                'EncounterTypes',
                '$q',
                'ChartUtilService',
                'cache',
                'Lookup',
                'FamilyMember',
                '$http',
                'logger',
                'CarePlanProblemEntry',
                'pdfer',
                'FaxGenerator',
                'SessionService'
            ];
            return ChartingPrintService;
        }());
        charting.ChartingPrintService = ChartingPrintService;
        angular.module('consolo').service('ChartingPrintService', ChartingPrintService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartUtilService = /** @class */ (function () {
            function ChartUtilService($mdDialog, $q, Permissions, ClinicalChartEntry, CarePlanProblemEntry, AddAddendumModal, PersistedItem, EncounterTypes, logger, Session, options, ChartingTemplateService, $filter, $sce, cache) {
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.Permissions = Permissions;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.AddAddendumModal = AddAddendumModal;
                this.PersistedItem = PersistedItem;
                this.EncounterTypes = EncounterTypes;
                this.logger = logger;
                this.Session = Session;
                this.options = options;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$filter = $filter;
                this.$sce = $sce;
                this.cache = cache;
                this.extensionName = 'ChartUtilService';
            }
            ChartUtilService.prototype.onSessionVisible = function (sess) {
                var _this = this;
                return ['async', this.options.get('base_roles').$promise.then(function (data) {
                        _this.base_roles = data;
                    })];
            };
            ChartUtilService.prototype.checkChartPermissions = function (chart, force_readonly) {
                if (force_readonly) {
                    return false;
                }
                var user = this.Session.getUser();
                var editable = false;
                var permission_role_symbol = chart.role_name
                    ? this.base_roles.filter(function (role) {
                        return role.description === chart.role_name;
                    })[0]['role_symbol']
                    : '';
                var permission_action = 'edit_any_' + permission_role_symbol + '_chart';
                if (this.Permissions.canAny([
                    [permission_action, 'clinical_charts'],
                    ['edit_any_clinical_chart', 'clinical_charts'],
                ])) {
                    editable = true;
                }
                else if ((chart.user && user.id.toString() === chart.user.id.toString()) ||
                    !chart.created_by ||
                    user.id.toString() === chart.created_by.id.toString()) {
                    editable = !chart.created_at || this.within24Hours(chart.created_at);
                }
                return editable;
            };
            ChartUtilService.prototype.within24Hours = function (created_at) {
                var now_time = new Date().getTime();
                var created_time = new Date(created_at).getTime();
                console.log('within 24 hours: ', now_time - created_time < 24 * 60 * 60 * 1000);
                return now_time - created_time < 24 * 60 * 60 * 1000;
            };
            ChartUtilService.prototype.checkChartEditable = function (chart, force_readonly) {
                if (chart.locked) {
                    return false;
                }
                return this.checkChartPermissions(chart, force_readonly);
            };
            ChartUtilService.prototype.voidChart = function (chart, $event) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Void Chart Entry')
                    .targetEvent($event)
                    .textContent('Are you sure you wish to void this chart entry?')
                    .ok('Okay')
                    .multiple(true)
                    .cancel('Cancel');
                return this.$mdDialog.show(confirmation).then(function () {
                    return _this.ClinicalChartEntry.voidChart({ id: chart.id }).$promise.then(function () {
                        _this.logger.success('Chart #' + chart.id + ' has been voided.');
                        return _this.$q.resolve(true);
                    }, function (e) {
                        _this.logger.error('Chart could not be voided: ' + e.split(';'));
                        return _this.$q.reject(false);
                    });
                });
            };
            ChartUtilService.prototype.recoverChart = function (chart, $event) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Recover Chart Entry')
                    .targetEvent($event)
                    .textContent('Are you sure you wish to recover this chart entry?')
                    .ok('Okay')
                    .multiple(true)
                    .cancel('Cancel');
                return this.$mdDialog.show(confirmation).then(function () {
                    return _this.ClinicalChartEntry.recoverChart({ chart_id: chart.id }).$promise.then(function () {
                        _this.logger.success('Chart #' + chart.id + ' has been recovered.');
                        return _this.$q.resolve(true);
                    }, function (e) {
                        _this.logger.error('Chart could not be recovered: ' + e.split(';'));
                        return _this.$q.reject(false);
                    });
                });
            };
            ChartUtilService.prototype.addSignature = function (chart, $event) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Add Your Signature')
                    .targetEvent($event)
                    .textContent('Signing this Clinical Chart will lock it and prevent future editing.')
                    .ok('Sign')
                    .cancel('No Thanks');
                return this.$mdDialog.show(confirmation).then(function () {
                    return _this.ClinicalChartEntry.addSignature({ id: chart.id }).$promise.then(function (chart) {
                        _this.logger.success('Chart ' + chart.id + ' signed.', 'Chart Updated');
                    }, function (data) {
                        _this.logger.error(data.data.errors.join());
                    });
                });
            };
            ChartUtilService.prototype.addPhysicianSignature = function (chart, $event) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/clinical-charts/physician-signature-dialog.html',
                    controller: 'PhysicianSignatureDialogController as vm',
                    bindToController: true,
                    locals: {
                        physicians: chart.physicians,
                    },
                })
                    .then(function (signatureResult) {
                    return signatureResult
                        ? _this.ClinicalChartEntry.addSignature({
                            id: chart.id,
                            physician_id: signatureResult.physician,
                            effective_date: signatureResult.date,
                        }).$promise.then(function (chart) {
                            _this.logger.success("Chart " + chart.id + " signed.", 'Chart Updated');
                        }, function (data) {
                            _this.logger.error(data.data.errors.join());
                        })
                        : _this.$q.resolve();
                });
            };
            ChartUtilService.prototype.tagAsComprehensive = function (chart) {
                var _this = this;
                return this.ClinicalChartEntry.tagAsComprehensive({ id: chart.id }).$promise.then(function (chart) {
                    _this.logger.success('Chart ' + chart.id + ' tagged as comprehensive.', 'Chart Updated');
                }, function (data) {
                    _this.logger.error(data.data.errors.join());
                });
            };
            ChartUtilService.prototype.addAddendum = function (chart, $event, sectionId) {
                this.AddAddendumModal.show(chart, $event, sectionId);
            };
            ChartUtilService.prototype.createChartStoreFromChart = function (chartEntry) {
                var chartStore;
                if (chartEntry.chartingClient === 'encounter_charting') {
                    chartEntry.encounter_type = {
                        lookup_name: chartEntry.charting_client,
                        name: chartEntry.charting_client.replace(/_/g, ' '),
                        id: 'encounter_charting',
                    };
                    chartEntry.encounter_type_id = 'encounter_charting';
                }
                chartStore = this.PersistedItem('clinical_chart_entry', new this.ClinicalChartEntry(chartEntry));
                return chartStore;
            };
            ChartUtilService.prototype.getChartStoreById = function (id) {
                var _this = this;
                var chartStore;
                return this.ClinicalChartEntry.getById(id).then(function (chartEntry) {
                    if (chartEntry.encounter_type_id) {
                        return _this.EncounterTypes.get(chartEntry.encounter_type_id).then(function (et) {
                            chartEntry.encounter_type = et;
                            chartStore = _this.PersistedItem('clinical_chart_entry', chartEntry);
                            return chartStore;
                        });
                    }
                    else if (chartEntry.charting_client === 'encounter_charting') {
                        chartEntry.encounter_type = {
                            lookup_name: chartEntry.charting_client,
                            name: chartEntry.charting_client.replace(/_/g, ' '),
                            id: 'encounter_charting',
                        };
                        chartEntry.encounter_type_id = 'encounter_charting';
                        chartStore = _this.PersistedItem('clinical_chart_entry', chartEntry);
                        return chartStore;
                    }
                    else {
                        chartStore = _this.PersistedItem('clinical_chart_entry', chartEntry);
                        return chartStore;
                    }
                });
            };
            ChartUtilService.prototype.viewChartModal = function (chartStore, $event) {
                var hasVitasEncounterCharting = this.Session.get().agency.has_vitas_encounter_charting;
                var isPae = chartStore.model ? (hasVitasEncounterCharting && chartStore.model.encounter_type && chartStore.model.encounter_type.is_pae_template) : false;
                var component = isPae ? "<clinical-charts-pae-view\n                                chart-store=\"vm.chartStore\"\n                                form-model=\"vm.formModel\"\n                                show-unmodified-sections=\"vm.showUnmodifiedSections\"\n                              />" : "<consolo-charting-view-chart\n                              chart-store=\"vm.chartStore\"\n                              form-model=\"vm.formModel\"\n                              show-unmodified-sections=\"vm.showUnmodifiedSections\"\n                            />";
                return this.$mdDialog
                    .show({
                    targetEvent: $event,
                    clickOutsideToClose: true,
                    fullscreen: true,
                    template: "\n          <md-dialog class=\"md-hue-1\" aria-label=\"Clinical Chart\" flex=\"75\">\n            <md-toolbar>\n              <div class=\"md-toolbar-tools\">\n                <csg-checkbox\n                  label=\"Show Unmodified Sections\"\n                  ng-model=\"vm.showUnmodifiedSections\">\n                </csg-checkbox>\n                <span flex></span>\n                <md-button ng-if=\"::!vm.chartStore.draft\" class=\"md-icon-button\"\n                  copy-to-clipboard=\"vm.getViewUrl()\" ng-click=\"vm.linkCopied()\" aria-label=\"Copy Link\">\n                  <md-icon md-svg-icon=\"content:link\"></md-icon>\n                </md-button>\n                <md-button class=\"md-icon-button\" ng-click=\"vm.$mdDialog.cancel();\">\n                  <md-icon class=\"material-icons\" aria-label=\"Close dialog\" md-svg-icon=\"navigation:close\"></md-icon>\n                </md-button>\n              </div>\n            </md-toolbar>\n            <md-dialog-content>\n              " + component + "\n            </md-dialog-content>\n            <md-dialog-actions>\n              <md-button \n                ng-if=\"!vm.chartStore.model.locked && vm.chartStore.model.patient_time && vm.chartStore.model.user_id === vm.user.id\" \n                class=\"md-raised md-primary\"\n                ng-click=\"vm.$mdDialog.hide(vm.endOfVisit($event))\"\n              >Record End of Patient Time</md-button>\n              <span flex></span>\n              <md-button ng-click=\"vm.$mdDialog.cancel()\">Close</md-button>\n            </md-dialog-actions>\n          </md-dialog>",
                    controller: 'ClinicalChartsCommonSectionController',
                    controllerAs: 'vm',
                    bindToController: true,
                    locals: {
                        chartStore: chartStore,
                        formModel: chartStore.model,
                        currentPatient: null,
                        showSectionDetails: false,
                    },
                })
                    .then(function (val) { return val; })
                    .catch(function (val) { return val; });
            };
            ChartUtilService.prototype.getChartModal = function (id, draft, $event) {
                var _this = this;
                var chartStore;
                var p = this.$q.defer();
                if (draft) {
                    chartStore = draft;
                    p.resolve(draft);
                }
                else {
                    this.getChartStoreById(id).then(function (chartStore) { return p.resolve(chartStore); });
                }
                return p.promise.then(function (chart) {
                    return _this.viewChartModal(chart, $event);
                });
            };
            ChartUtilService.prototype.defaultSectionIsEmpty = function (section, model) {
                var isEmpty = true;
                for (var _i = 0, _a = section.fields; _i < _a.length; _i++) {
                    var field = _a[_i];
                    if (field.showOnEmpty) {
                        isEmpty = false;
                        break;
                    }
                    var fieldVariations = [field.field, "has_" + field.field, "note_" + field.field];
                    fieldVariations.forEach(function (variation) {
                        if (model[variation]) {
                            isEmpty = false;
                        }
                    });
                    if (!isEmpty) {
                        break;
                    }
                }
                return isEmpty;
            };
            ChartUtilService.prototype.checkEmptySections = function (section, formModel) {
                var isEmpty = true;
                for (var _i = 0, _a = section.field_definition; _i < _a.length; _i++) {
                    var fieldDef = _a[_i];
                    isEmpty =
                        typeof section.sectionIsEmpty === 'function'
                            ? section.sectionIsEmpty(fieldDef, formModel)
                            : this.defaultSectionIsEmpty(fieldDef, formModel);
                    if (!isEmpty) {
                        break;
                    }
                }
                // Document Upload is a unique section
                if (section.model === 'document_upload') {
                    if (formModel['uploads']) {
                        isEmpty = formModel['uploads'].length === 0;
                    }
                    else {
                        isEmpty = true;
                    }
                }
                return isEmpty;
            };
            ChartUtilService.prototype.checkEmptySubsection = function (section, subsection, formModel) {
                return typeof section.sectionIsEmpty === 'function'
                    ? section.sectionIsEmpty(subsection, formModel)
                    : this.defaultSectionIsEmpty(subsection, formModel);
            };
            ChartUtilService.prototype.pageIsEmpty = function (model, page) {
                // general properties exist on root of formModel and always exist
                if (page.model === 'general') {
                    return false;
                }
                if (!model[page.model]) {
                    return true;
                }
                return Array.isArray(model[page.model])
                    ? model[page.model].every(function (model) { return model.isEmpty; })
                    : model[page.model].isEmpty;
            };
            ChartUtilService.prototype.patientTimeDialog = function (chartStore) {
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    fullscreen: true,
                    template: "\n        <md-dialog flex=\"75\">\n          <md-dialog-content>\n            <csg-spinner size=\"lg\" centered=\"true\" ng-if=\"$ctrl.loading\"></csg-spinner>\n            <consolo-charting-patient-time\n              chart-store=\"$ctrl.chartStore\"\n              form-model=\"$ctrl.formModel\"\n              view-only=\"false\"\n              show-chart-info=\"true\"\n            >\n            </consolo-charting-patient-time>\n          </md-dialog-content>\n          <md-dialog-actions>\n            <md-button class=\"md-warn\" ng-click=\"$ctrl.cancelDialog()\">Cancel</md-button>\n            <md-button class=\"md-primary md-raised\" ng-click=\"$ctrl.save()\" ng-disabled=\"$ctrl.saveDisabled()\">Save</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n        ",
                    controller: 'PatientTimeDialogController as $ctrl',
                    locals: {
                        chartStore: chartStore,
                    },
                })
                    .then(function (pt) {
                    return pt;
                });
            };
            ChartUtilService.prototype.loadCarePlanProblem = function (formModel, cppid, cpp) {
                var _this = this;
                var isOffline = isNaN(cppid);
                if (isOffline) {
                    var cppResource = new this.CarePlanProblemEntry({ uuid: cppid });
                    var cppPi = new this.PersistedItem('care_plan_problem', cppResource);
                    return cppPi.getWorkingCopy().then(function (data) {
                        _this.setupCPPInterventions(formModel, data.model, cpp);
                        return;
                    });
                }
                else {
                    if (!cppid) {
                        this.logger.info('Invalid care plan ID');
                    }
                    else {
                        var cppCache = this.cache(this.CarePlanProblemEntry, 'care_plan_problems', {
                            encrypt: true,
                            force: true,
                        });
                        return cppCache.get({ id: cppid }).$promise.then(function (data) {
                            _this.setupCPPInterventions(formModel, data, cpp);
                            //TODO: need to account for new section create permission.
                            // var canEdit = (this.SectionManager.cppUpdatePermissions.update && !!this.chartStore.model.id) ||
                            //   (this.SectionManager.cppUpdatePermissions.create && !this.chartStore.model.id);
                            //Just checking permissions, not if chart is locked
                            cpp.readonly =
                                !_this.Permissions.can('create', 'care_plan_problems') &&
                                    !_this.Permissions.can('update', 'care_plan_problems');
                            formModel.readonly = cpp.readonly;
                            return;
                        });
                    }
                }
            };
            ChartUtilService.prototype.setupCPPInterventions = function (formModel, data, cpp) {
                var _this = this;
                // Go through list of interventions for this CPP and add the ones they've already filled out,
                // otherwise, add a blank intervention to fill out.
                data.interventions.forEach(function (intervention) {
                    var actingId = intervention.id ? intervention.id : intervention.uuid;
                    var interventionData = _this.$filter('filter')(formModel.interventions, function (a) {
                        return !!a.care_plan_problem_intervention_id
                            ? a.care_plan_problem_intervention_id === actingId
                            : a.care_plan_problem_intervention_uuid === actingId;
                    })[0];
                    if (interventionData) {
                        interventionData.name = intervention.name;
                        interventionData.care_plan_problem_update_id = formModel.id;
                        interventionData.care_plan_problem_intervention_id = intervention.id;
                        interventionData.care_plan_problem_intervention_uuid = intervention.uuid;
                        interventionData.due_date = intervention.due_date;
                        interventionData.chart_section = intervention.chart_section;
                        interventionData.name_editable = intervention.name_editable;
                    }
                    else {
                        if (!intervention.end_date) {
                            if (!formModel.arrInterventions) {
                                formModel.arrInterventions = [];
                            }
                            formModel.arrInterventions.push({
                                care_plan_problem_update_id: formModel.id,
                                name: intervention.name,
                                care_plan_problem_intervention_id: intervention.id,
                                care_plan_problem_intervention_uuid: intervention.uuid,
                                due_date: intervention.due_date,
                                chart_section: intervention.chart_section,
                            });
                        }
                    }
                });
                angular.copy(data, cpp);
            };
            ChartUtilService.prototype.formatText = function (str) {
                return str.replace(/_/g, ' ').replace(/(\b[a-z](?!\s))/g, function (x) {
                    return x.toUpperCase();
                });
            };
            ChartUtilService.prototype.updateDisplayText = function (section, formModel, print) {
                formModel[section].display_text = '';
                var tempArr = [];
                for (var val in formModel[section]) {
                    if (val !== 'display_text') {
                        if (formModel[section][val] === 0) {
                            if (val === 'normal') {
                                tempArr.push('Normal');
                            }
                            else {
                                tempArr.push('No ' + this.formatText(val));
                            }
                        }
                        else if (formModel[section][val] === 1) {
                            tempArr.push(this.formatText(val));
                        }
                        if (formModel[section].comments) {
                            var t = formModel[section].comments.replace(/[\r\n|\r|\n]/g, '<br/>');
                            formModel[section].comment_array = t.split('<br/>');
                            formModel[section].comment_display = this.$sce.trustAsHtml(t);
                        }
                        else {
                            formModel[section].comment_array = [];
                        }
                    }
                }
                if (!tempArr.length) {
                    if (formModel[section].comments) {
                        tempArr.push('Comments only');
                    }
                    else {
                        tempArr.push('Not reviewed');
                    }
                }
                var tempString = tempArr.join('; ');
                tempString = this.$filter('capitalize')(tempString.toLowerCase());
                formModel[section].display_text = tempString;
            };
            ChartUtilService.prototype.getReadOnlyFields = function (modelName, chartStore) {
                var _a;
                var fields = this.ChartingTemplateService.getFieldDefinitions();
                var screens = this.ChartingTemplateService.isEncounterCharting(chartStore.model.charting_client)
                    ? this.ChartingTemplateService.getScreens('base_encounter')
                    : this.ChartingTemplateService.getScreens('tablet_optimized');
                var readOnlySections = [];
                for (var sectionModel in fields) {
                    if (fields.hasOwnProperty(sectionModel)) {
                        var newReadOnlySection = void 0; // consolo.charting.IChartingSection;
                        for (var _i = 0, _b = fields[sectionModel]; _i < _b.length; _i++) {
                            var subSection = _b[_i];
                            for (var _c = 0, _d = subSection.fields; _c < _d.length; _c++) {
                                var field = _d[_c];
                                if (((_a = field.viewSection) === null || _a === void 0 ? void 0 : _a.indexOf(modelName)) >= 0) {
                                    var value = this.getModelValue(chartStore.model, sectionModel, field);
                                    if (value) {
                                        if (!newReadOnlySection) {
                                            var s = this.ChartingTemplateService.getSectionFromScreens(sectionModel, screens, 'model');
                                            newReadOnlySection = {
                                                name: s.name,
                                                title: s.title,
                                                model: s.model,
                                                field_definition: [{ label: '', fields: [] }],
                                                values: {},
                                            };
                                        }
                                        // if (!newReadOnlySection.values[sectionModel]) {
                                        //   newReadOnlySection.values[sectionModel] = {};
                                        // }
                                        newReadOnlySection.values[field.field] = value;
                                        field.readonly = true;
                                        field.label = newReadOnlySection.title + ': ' + field.label;
                                        newReadOnlySection.field_definition[0].fields.push(field);
                                    }
                                }
                            }
                        }
                        if (newReadOnlySection) {
                            readOnlySections.push(newReadOnlySection);
                        }
                    }
                }
                return readOnlySections;
            };
            ChartUtilService.prototype.modelHasValue = function (data, modelName, field) {
                if (!data[modelName]) {
                    return;
                }
                var m = Array.isArray(data[modelName]) ? data[modelName][0] : data[modelName];
                return field.type === 'booltext' ? !!m['has_' + field.field] : !!m[field.field];
            };
            ChartUtilService.prototype.getModelValue = function (data, modelName, field) {
                var m = Array.isArray(data[modelName]) ? data[modelName][0] : data[modelName];
                return !m ? null : field.type === 'booltext' ? m['note_' + field.field] : m[field.field];
            };
            ChartUtilService.prototype.getClientName = function (model) {
                if (!model.charting_client) {
                    model.charting_client = 'bootstrap_charting';
                }
                if (!model.charting_client_name) {
                    var client = this.ChartingTemplateService.getChartingClient(model.charting_client);
                    if (client) {
                        model.charting_client_name = client.description;
                    }
                }
                if (!model.encounter_type_name && model.encounter_type_id) {
                    if (model.encounter_type) {
                        model.encounter_type_name = isNaN(model.encounter_type_id)
                            ? ''
                            : model.encounter_type.name;
                    }
                }
                model.isEncounterCharting = this.ChartingTemplateService.isEncounterCharting(model.charting_client);
            };
            ChartUtilService.$inject = [
                '$mdDialog',
                '$q',
                'PermissionsService',
                'ClinicalChartEntry',
                'CarePlanProblemEntry',
                'AddAddendumModal',
                'PersistedItem',
                'EncounterTypes',
                'logger',
                'SessionService',
                'options',
                'ChartingTemplateService',
                '$filter',
                '$sce',
                'cache',
            ];
            return ChartUtilService;
        }());
        charting.ChartUtilService = ChartUtilService;
        angular.module('consolo').service('ChartUtilService', ChartUtilService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var IChartingFieldOption = /** @class */ (function () {
            function IChartingFieldOption() {
            }
            return IChartingFieldOption;
        }());
        var ChartingTemplateService = /** @class */ (function () {
            function ChartingTemplateService(EncounterTypes, $filter, $mdDialog, $q, options, cache, Lookup, EncounterType, FamilyMember, ProblemListItemsFactory, MedicationsFactory, CurrentPatient, logger, cdsVitalSignsService, PermissionsService, patientMainService, visitTaskList, sessionService, chartSameQuestionInteraction) {
                this.EncounterTypes = EncounterTypes;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.options = options;
                this.cache = cache;
                this.Lookup = Lookup;
                this.EncounterType = EncounterType;
                this.FamilyMember = FamilyMember;
                this.ProblemListItemsFactory = ProblemListItemsFactory;
                this.MedicationsFactory = MedicationsFactory;
                this.CurrentPatient = CurrentPatient;
                this.logger = logger;
                this.cdsVitalSignsService = cdsVitalSignsService;
                this.PermissionsService = PermissionsService;
                this.patientMainService = patientMainService;
                this.visitTaskList = visitTaskList;
                this.sessionService = sessionService;
                this.chartSameQuestionInteraction = chartSameQuestionInteraction;
                this.chartingFields = {};
                this.chartingScreens = {};
                this.templateFieldDefinitions = {};
                this.additionalChartingSections = [];
                this.baseSections = [];
                this.problemListEnabled = false;
                this.cqmsEnabled = false;
                this.pediatricAssessmentEnabled = false;
                this.oxygenAssessmentEnabled = false;
                this.encounterChartingClients = [];
                this.optimizedChartingClients = [];
                this.veteranAssessmentEnabled = true;
                this.chartingClients = [
                    { client: 'encounter_charting', description: 'Encounter Charting', is_encounter: true },
                    {
                        client: 'encounter_aide_charting',
                        description: 'Encounter Aide Charting',
                        is_encounter: true,
                    },
                    { client: 'custom', description: 'Encounter Charting', is_encounter: true },
                    { client: 'tablet_optimized', description: 'Optimized Charting', is_encounter: true },
                    { client: 'ios_aide_app', description: 'iOS Aide Charting', is_encounter: true },
                    { client: 'bootstrap_charting', description: 'Classic Charting', is_encounter: true },
                    {
                        client: 'bootstrap_aide_charting',
                        description: 'Classic Aide Charting',
                        is_encounter: true,
                    },
                ];
                this.source_of_information = [
                    { "text": "Patient", "value": "1" },
                    { "text": "Physician", "value": "2" },
                    { "text": "Chart", "value": "3" },
                    { "text": "Family", "value": "4" },
                    { "text": "Facility", "value": "5" },
                    { "text": "Other", "value": "6" },
                ];
                this.today = new Date();
                this.cdsItemId = '236';
                this.cdsItemUrl = null;
                this.extensionName = 'ChartingTemplate';
                this.checkIfDataExistinTable = function (tableData) {
                    var data_exists = false;
                    if (tableData.length) {
                        tableData.forEach(function (row) {
                            Object.keys(row).forEach(function (key) {
                                if (row[key]) {
                                    data_exists = true;
                                }
                            });
                        });
                    }
                    return data_exists;
                };
                for (var _i = 0, _a = this.chartingClients; _i < _a.length; _i++) {
                    var client = _a[_i];
                    if (client.is_encounter) {
                        this.encounterChartingClients.push(client.client);
                    }
                    else {
                        this.optimizedChartingClients.push(client.client);
                    }
                }
                var testScreen = { name: 'blah', title: 'blah' };
                testScreen.sections = [];
                this.queryMap = {
                    family_members: this.FamilyMember,
                    problem_list_items: this.ProblemListItemsFactory,
                    patient_medications: this.MedicationsFactory,
                };
                this.getCdsItemLink();
            }
            ChartingTemplateService.prototype.onSessionVisible = function (sess) {
                var _this = this;
                this.user = sess.user;
                this.serviceLine = sess.agency.service_line;
                this.problemListEnabled = sess.agency.problem_list_enabled;
                this.cqmsEnabled = sess.agency.has_cqm;
                this.cdsEnabled = sess.agency.has_cds;
                this.oxygenAssessmentEnabled =
                    sess.agency.oxygen_safety_assessment &&
                        (this.PermissionsService.can('show', 'oxygen_safety_assessment') ||
                            this.PermissionsService.can('update', 'oxygen_safety_assessment') ||
                            this.PermissionsService.can('create', 'oxygen_safety_assessment'));
                this.pediatricAssessmentEnabled =
                    sess.agency.pediatric_fall_risk_assessment &&
                        (this.PermissionsService.can('show', 'pediatric_fall_risk_assessment') ||
                            this.PermissionsService.can('update', 'pediatric_fall_risk_assessment') ||
                            this.PermissionsService.can('create', 'pediatric_fall_risk_assessment'));
                return ['async', this.getCdsItemLink().then(function () { return _this.loadTemplates(); })];
            };
            ChartingTemplateService.prototype.getCdsItemLink = function () {
                var _this = this;
                if (this.cdsEnabled) {
                    return this.cdsVitalSignsService
                        .getCdsForVitalSigns(this.cdsItemId)
                        .then(function (response) {
                        var cdsItem = response.data;
                        _this.cdsItemUrl = cdsItem.doc_link;
                    })
                        .catch(function () {
                        return _this.$q.when(true);
                    });
                }
                else {
                    return this.$q.when(true);
                }
            };
            ChartingTemplateService.prototype.initialize = function () {
                this.options.updateCachedOptions(this.getOptions());
            };
            ChartingTemplateService.prototype.isEncounterCharting = function (client) {
                return true;
            };
            ChartingTemplateService.prototype.isAdditionalSection = function (sectionName) {
                return this.additionalChartingSections.indexOf(sectionName) >= 0;
            };
            ChartingTemplateService.prototype.loadTemplates = function () {
                this.setBaseTemplates();
                this.buildBasicTemplates();
                this.trimServiceLine();
                this.checkFieldDependencies();
                this.buildEncounterChartingSections();
                this.loadCustomSections();
            };
            ChartingTemplateService.prototype.getScreens = function (template_name) {
                return angular.copy(this.chartingScreens[template_name]);
            };
            ChartingTemplateService.prototype.loadCustomSections = function () {
                var _this = this;
                this.EncounterTypes.select(null, null, 'custom').then(function (data) {
                    var _loop_1 = function (et) {
                        // this.buildETSections(et, et.section_template.screens);
                        _this.fixET(et).then(function (ret) {
                            if (!et.section_template) {
                                et.section_template = { screens: [] };
                            }
                            _this.buildETSections(ret, et.section_template.screens);
                        });
                    };
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var et = data_1[_i];
                        _loop_1(et);
                    }
                });
            };
            ChartingTemplateService.prototype.fixET = function (et) {
                // return this.fixAdditionalRequired(et).then(result => {
                //   return this.fixPatientTime(result);
                // });
                return this.fixVisitTaskList(et);
            };
            ChartingTemplateService.prototype.fixVisitTaskList = function (et) {
                if (et.section_template && et.section_template.screens) {
                    var oldVisitTaskList = et.section_template.screens.find(function (s) { return s.name === 'visit_task_lists'; });
                    if (oldVisitTaskList) {
                        oldVisitTaskList.name = 'visit_task_list';
                        return this.EncounterTypes.update(et);
                    }
                }
                return this.$q.when(et);
            };
            ChartingTemplateService.prototype.fixAdditionalRequired = function (et) {
                if (et.required_fields && !angular.equals(et.required_fields, {})) {
                    var doupdate = false;
                    for (var name_1 in et.required_fields) {
                        if (et.required_fields.hasOwnProperty(name_1)) {
                            if (this.isAdditionalSection(name_1)) {
                                doupdate = true;
                                delete et.required_fields[name_1];
                            }
                        }
                    }
                    if (doupdate) {
                        return this.EncounterTypes.update(et).then(function (data) {
                            return data;
                        });
                    }
                    else {
                        return this.$q.when(et);
                    }
                }
                else {
                    return this.$q.when(et);
                }
            };
            ChartingTemplateService.prototype.fixPatientTime = function (et) {
                var pt, doupdate;
                if (et.field_logic && et.field_logic.patient_time) {
                    pt = et.field_logic.patient_time;
                    if (pt.clinical_care_type) {
                        pt.clinical_care_type_id = angular.copy(pt.clinical_care_type);
                        delete pt.clinical_care_type;
                        doupdate = true;
                    }
                    if (et.required_fields.patient_time) {
                        pt = et.required_fields.patient_time;
                        var idx = pt.indexOf('clinical_care_type');
                        if (idx >= 0 && pt[idx] === 'clinical_care_type') {
                            pt[idx] = 'clinical_care_type_id';
                            doupdate = true;
                        }
                    }
                }
                if (doupdate) {
                    return this.EncounterTypes.update(et).then(function (data) {
                        return data;
                    });
                }
                else {
                    return this.$q.when(et);
                }
            };
            ChartingTemplateService.prototype.fixLockedRequired = function (et) {
                var doUpdate = false;
                if (et.required_fields || et.field_logic) {
                    for (var sectionName in et.required_fields) {
                        if (et.required_fields.hasOwnProperty(sectionName)) {
                            var s = this.getSectionFromScreens(sectionName, this.baseSections, 'name');
                            for (var i = et.required_fields[sectionName].length - 1; i >= 0; i--) {
                                var f = this.getSectionField(et.required_fields[sectionName][i], s.model);
                                if (f && f.requiredFn) {
                                    et.required_fields[sectionName].splice(i, 1);
                                    doUpdate = true;
                                }
                            }
                        }
                    }
                    for (var sectionName in et.field_logic) {
                        if (et.field_logic.hasOwnProperty(sectionName)) {
                            var s = this.getSectionFromScreens(sectionName, this.baseSections, 'name');
                            for (var fieldName in et.field_logic[sectionName]) {
                                if (et.field_logic[sectionName].hasOwnProperty(fieldName)) {
                                    if (et.field_logic[sectionName][fieldName].required) {
                                        var baseField = this.getSectionField(fieldName, s.model);
                                        if (baseField && baseField.requiredFn) {
                                            et.field_logic[sectionName][fieldName].required = false;
                                            doUpdate = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (doUpdate) {
                        return this.EncounterTypes.update(et).then(function (data) {
                            return data;
                        });
                    }
                    else {
                        return this.$q.when(et);
                    }
                }
            };
            ChartingTemplateService.prototype.fixChartingType = function () {
                var _this = this;
                var names = [];
                var dupes = [];
                this.EncounterTypes.select(null, null, null).then(function (data) {
                    for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
                        var et = data_2[_i];
                        if (names.indexOf(et.name) >= 0) {
                            dupes.push(et.name);
                        }
                        else {
                            names.push(et.name);
                        }
                    }
                    for (var _a = 0, data_3 = data; _a < data_3.length; _a++) {
                        var et = data_3[_a];
                        if (et.charting_type === 'optimized_charting' && et.role_ids.length) {
                            if (dupes.indexOf(et.name) >= 0) {
                                var i = 0;
                                var name_2 = et.name + ' #' + i;
                                while (i < 10 && names.indexOf(name_2) >= 0 && dupes.indexOf(name_2) >= 0) {
                                    i++;
                                    name_2 = et.name + ' #' + i;
                                }
                                et.name = name_2;
                                dupes.push(et.name);
                            }
                            et.charting_type = 'tablet_optimized';
                            _this.EncounterTypes.update(et);
                        }
                    }
                });
            };
            //this is to handle if anything changes in how the et object is stored
            ChartingTemplateService.prototype.fixFieldLogic = function (et) {
                var newFieldLogic = {};
                var doupdate = false;
                if (et.field_logic) {
                    for (var section in et.field_logic) {
                        if (et.field_logic.hasOwnProperty(section)) {
                            var newSection = {};
                            if (Array.isArray(et.field_logic[section])) {
                                doupdate = true;
                                for (var _i = 0, _a = et.field_logic[section]; _i < _a.length; _i++) {
                                    var f = _a[_i];
                                    newSection[f.field] = {
                                        hidden: f.hidden,
                                        required: f.required,
                                        hideFn: f.hideFn,
                                        requiredFn: f.requiredFn,
                                    };
                                }
                            }
                            else {
                                newSection = section;
                            }
                            newFieldLogic[section] = newSection;
                        }
                    }
                    if (doupdate) {
                        et.field_logic = newFieldLogic;
                        return this.EncounterTypes.update(et);
                    }
                    else {
                        return this.$q.when(true);
                    }
                }
            };
            ChartingTemplateService.prototype.trimServiceLine = function () {
                var _this = this;
                var fieldTemplate = this.chartingFields;
                var keys = Object.keys(fieldTemplate);
                for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    var screen_1 = keys_1[_i];
                    var section = void 0;
                    for (var sectionIndex = fieldTemplate[screen_1].length - 1; sectionIndex >= 0; sectionIndex--) {
                        section = fieldTemplate[screen_1][sectionIndex];
                        if (section.serviceLines && section.serviceLines.indexOf(this.serviceLine) < 0) {
                            fieldTemplate[screen_1].splice(sectionIndex, 1);
                        }
                        else {
                            var field = void 0;
                            for (var fieldIndex = section.fields.length - 1; fieldIndex >= 0; fieldIndex--) {
                                field = section.fields[fieldIndex];
                                if (field.serviceLines && field.serviceLines.indexOf(this.serviceLine) < 0) {
                                    section.fields.splice(fieldIndex, 1);
                                }
                                if (this.serviceLine === 'palliative') {
                                    field.hospice_item_set = false;
                                    field.service_line_tag = field.mips;
                                }
                                else if (this.serviceLine === 'hospice') {
                                    field.mips = false;
                                    field.service_line_tag = field.hospice_item_set;
                                }
                            }
                        }
                    }
                }
                var hasServiceLineTag = function (modelName) {
                    return _this.chartingFields[modelName]
                        ? _this.chartingFields[modelName].some(function (section) {
                            section.has_service_line_tag = section.fields.some(function (field) { return field.service_line_tag; });
                            return section.has_service_line_tag;
                        })
                        : false;
                };
                var hasRequired = function (modelName) {
                    return _this.chartingFields[modelName]
                        ? _this.chartingFields[modelName].some(function (section) {
                            section.has_required = section.fields.some(function (field) { return field.required || !!field.requiredFn; });
                            return section.has_required;
                        })
                        : false;
                };
                var baseSections = ['tablet_optimized', 'base_encounter'];
                for (var _a = 0, baseSections_1 = baseSections; _a < baseSections_1.length; _a++) {
                    var s = baseSections_1[_a];
                    var screens = this.chartingScreens[s];
                    var len = screens.length;
                    var screen_2 = void 0;
                    var fieldSections = void 0;
                    for (var i = len - 1; i >= 0; i--) {
                        screen_2 = screens[i];
                        if (screen_2.serviceLines && screen_2.serviceLines.indexOf(this.serviceLine) < 0) {
                            screens.splice(i, 1);
                        }
                        else {
                            screen_2.has_service_line_tag = hasServiceLineTag(screen_2.model);
                            screen_2.has_required = hasRequired(screen_2.model);
                        }
                    }
                }
            };
            ChartingTemplateService.prototype.buildETSections = function (encounter_type, screens) {
                // const hasServiceLineTag = modelName =>
                //   this.chartingFields[modelName]
                //     ? this.chartingFields[modelName].some(section =>
                //         section.has_service_line_tag
                //       )
                //     : false;
                var newSectionList = [];
                var base = encounter_type.charting_type === 'custom' ||
                    encounter_type.charting_type === 'encounter_charting'
                    ? this.chartingScreens['base_encounter']
                    : this.chartingScreens['tablet_optimized'];
                var screenDescriptions = [];
                if (!screens.length) {
                    console.log('buildETSections', '!screens.length', encounter_type.charting_type);
                    screens = base;
                }
                for (var _i = 0, screens_1 = screens; _i < screens_1.length; _i++) {
                    var screen_3 = screens_1[_i];
                    if (screen_3.sections) {
                        screen_3.has_service_line_tag = screen_3.sections.some(function (section) { return section.has_service_line_tag; });
                        var newSections = [];
                        screenDescriptions = [];
                        for (var _a = 0, _b = screen_3.sections; _a < _b.length; _a++) {
                            var section = _b[_a];
                            var s = this.getBaseSection(section);
                            if (s) {
                                s.parent_name = screen_3.name;
                                s.parent_title = screen_3.title;
                                if (s.allowMultiple) {
                                    screen_3.allowMultiple = true;
                                }
                                if (encounter_type.required_fields && encounter_type.required_fields[s.name]) {
                                    s.has_required = true;
                                    s.has_et_required = true;
                                }
                                screen_3.has_required = screen_3.has_required || s.has_required;
                                screen_3.has_et_required = screen_3.has_et_required || s.has_et_required;
                                s.active = screen_3.active || false;
                                if (s.description) {
                                    screenDescriptions.push(s.title + ' (' + s.description.trim() + ')');
                                }
                                else {
                                    screenDescriptions.push(s.title);
                                }
                                newSections.push(s);
                            }
                            else {
                                console.error('Section not found in screen.sections:', encounter_type, screen_3, section, screens);
                            }
                        }
                        screen_3.sections = newSections;
                        screen_3.description = 'Contains: ' + screenDescriptions.join('; ').trim();
                    }
                    else if (screen_3.childSections) {
                        var newChildren = [];
                        for (var _c = 0, _d = screen_3.childSections; _c < _d.length; _c++) {
                            var child = _d[_c];
                            if (child) {
                                child.has_service_line_tag = child.sections
                                    ? child.sections.some(function (section) { return section.has_service_line_tag; })
                                    : child.has_service_line_tag;
                                child.parent_name = screen_3.name;
                                child.parent_title = screen_3.title;
                                if (child.sections) {
                                    screenDescriptions = [];
                                    var newSections = [];
                                    for (var _e = 0, _f = child.sections; _e < _f.length; _e++) {
                                        var section = _f[_e];
                                        var s = this.getBaseSection(section);
                                        if (s) {
                                            s.parent_name = child.name;
                                            s.parent_title = child.title;
                                            s.grandparent_name = screen_3.name;
                                            s.grandparent_title = screen_3.title;
                                            s.active = child.active || false;
                                            if (s.allowMultiple) {
                                                child.allowMultiple = true;
                                            }
                                            if (encounter_type.required_fields && encounter_type.required_fields[s.name]) {
                                                s.has_required = true;
                                                s.has_et_required = true;
                                            }
                                            s.has_required = s.has_required || child.has_required;
                                            s.has_et_required = s.has_et_required || child.has_et_required;
                                            if (s.description) {
                                                screenDescriptions.push(s.title + ' (' + s.description.trim() + ')');
                                            }
                                            else {
                                                screenDescriptions.push(s.title);
                                            }
                                            newSections.push(s);
                                        }
                                        else {
                                            console.error('Section not found in child.sections', encounter_type, child, section, screens);
                                        }
                                    }
                                    child.sections = newSections;
                                    child.description = 'Contains: ' + screenDescriptions.join('; ').trim();
                                    newChildren.push(child);
                                }
                                else {
                                    var s = this.getBaseSection(child);
                                    if (s) {
                                        s.parent_name = screen_3.name;
                                        s.parent_title = screen_3.title;
                                        s.active = screen_3.active || false;
                                        if (encounter_type.required_fields && encounter_type.required_fields[s.name]) {
                                            s.has_required = true;
                                            s.has_et_required = true;
                                        }
                                        newChildren.push(s);
                                    }
                                    else {
                                    }
                                }
                            }
                            else {
                                screen_3.has_service_line_tag = screen_3.has_service_line_tag;
                            }
                        }
                        screen_3.childSections = newChildren;
                    }
                    else {
                        var tempScreen = this.getBaseSection(screen_3);
                        if (tempScreen) {
                            tempScreen.title = screen_3.title ? screen_3.title : tempScreen.title;
                            if (encounter_type.required_fields && encounter_type.required_fields[screen_3.name]) {
                                tempScreen.has_required = true;
                                tempScreen.has_et_required = true;
                            }
                            tempScreen.active = screen_3.active || false;
                        }
                        screen_3 = tempScreen;
                    }
                    if (screen_3) {
                        newSectionList.push(screen_3);
                    }
                }
                this.chartingScreens[encounter_type.lookup_name] = newSectionList;
            };
            ChartingTemplateService.prototype.getBaseSection = function (section) {
                var base = this.$filter('filter')(this.chartingScreens['base_encounter'], function (s) {
                    return section.name === s.name;
                });
                if (base.length) {
                    return angular.copy(base[0]);
                }
                else {
                    return null;
                }
            };
            ChartingTemplateService.prototype.getAllBaseSections = function (encounter_charting) {
                var x = encounter_charting
                    ? angular.copy(this.chartingScreens['base_encounter'])
                    : angular.copy(this.chartingScreens['tablet_optimized']);
                return x;
            };
            // getSectionTemplate(template_name: string) {
            //   return angular.copy(this.template[template_name]);
            // }
            ChartingTemplateService.prototype.getFieldDefinitions = function () {
                return angular.copy(this.chartingFields);
            };
            ChartingTemplateService.prototype.getTemplateName = function (model) {
                if (!this.isEncounterCharting(model.charting_client)) {
                    return 'tablet_optimized';
                }
                else if (!model.encounter_type_id ||
                    isNaN(model.encounter_type_id) ||
                    model.encounter_type_id === 0) {
                    return 'encounter_charting';
                }
                else {
                    return model.encounter_type_id;
                }
            };
            ChartingTemplateService.prototype.getSectionTemplateBy = function (text, by) {
                var returnSection;
                var keys = Object.keys(this.chartingFields);
                for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
                    var key = keys_2[_i];
                    if (text === this.chartingFields[key][by]) {
                        returnSection = this.chartingFields[key];
                        break;
                    }
                }
                return angular.copy(returnSection);
            };
            ChartingTemplateService.prototype.cleanDraft = function (item) {
                item.additional_sections = {};
                for (var _i = 0, _a = this.additionalChartingSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    if (item[s]) {
                        item.additional_sections[s] = item[s];
                        delete item[s];
                    }
                }
            };
            ChartingTemplateService.prototype.checkSections = function () {
                var t = this.getFieldDefinitions();
                var s = this.getScreens('encounter_charting');
                var found = false;
                s.forEach(function (section) {
                    if (section.childSections) {
                        section.childSections.forEach(function (child) {
                            if (!t[child.model]) {
                                console.log('Could not find section: ' +
                                    child.title +
                                    ' - ' +
                                    child.model +
                                    ' in ' +
                                    section.title);
                            }
                        });
                    }
                    else if (section.model && !t[section.model]) {
                        console.log('Could not find section: ' + section.title + ' - ' + section.model);
                    }
                });
            };
            ChartingTemplateService.prototype.screenExists = function (screenName, template_name) {
                for (var _i = 0, _a = this.chartingScreens[template_name]; _i < _a.length; _i++) {
                    var screen_4 = _a[_i];
                    if (screen_4.childSections) {
                        for (var _b = 0, _c = screen_4.childSections; _b < _c.length; _b++) {
                            var child = _c[_b];
                            if (child.name === screenName) {
                                return true;
                            }
                        }
                    }
                    else if (screen_4.name === screenName) {
                        return true;
                    }
                }
                return false;
            };
            ChartingTemplateService.prototype.sectionSearchBy = function (text, by, template_name, default_template) {
                var searchSection;
                // let keys = Object.keys(this.chartingScreens[template_name]);
                for (var _i = 0, _a = this.chartingScreens[template_name]; _i < _a.length; _i++) {
                    var screen_5 = _a[_i];
                    if (searchSection) {
                        break;
                    }
                    if (screen_5[by] === text) {
                        searchSection = screen_5;
                        break;
                    }
                    else if (text === 'custom_general_details' && (screen_5[by] === 'general')) {
                        searchSection = screen_5;
                        break;
                    }
                    else if (screen_5.sections) {
                        for (var _b = 0, _c = screen_5.sections; _b < _c.length; _b++) {
                            var subsection = _c[_b];
                            if (subsection[by] === text) {
                                searchSection = subsection;
                                break;
                            }
                        }
                    }
                    else if (screen_5.childSections) {
                        for (var _d = 0, _e = screen_5.childSections; _d < _e.length; _d++) {
                            var child = _e[_d];
                            if (child[by] === text) {
                                searchSection = child;
                                break;
                            }
                            else if (child.sections) {
                                for (var _f = 0, _g = child.sections; _f < _g.length; _f++) {
                                    var sub = _g[_f];
                                    if (sub[by] === text) {
                                        searchSection = child;
                                        break;
                                    }
                                }
                            }
                            else if (child[by] === text) {
                                searchSection = child;
                                break;
                            }
                        }
                    }
                }
                if (!searchSection && default_template) {
                    searchSection = this.sectionSearchBy(text, by, default_template);
                }
                return angular.copy(searchSection);
            };
            ChartingTemplateService.prototype.getSectionFromScreens = function (text, arrScreens, key) {
                if (!key) {
                    key = 'name';
                }
                if (!text) {
                    return;
                }
                for (var _i = 0, arrScreens_1 = arrScreens; _i < arrScreens_1.length; _i++) {
                    var screen_6 = arrScreens_1[_i];
                    if (screen_6[key] === text) {
                        return screen_6;
                    }
                    else if (screen_6.sections) {
                        for (var _a = 0, _b = screen_6.sections; _a < _b.length; _a++) {
                            var section = _b[_a];
                            if (section[key] === text) {
                                section.parent_name = screen_6.name;
                                section.parent_title = screen_6.title;
                                return section;
                            }
                        }
                    }
                    else if (screen_6.childSections) {
                        for (var _c = 0, _d = screen_6.childSections; _c < _d.length; _c++) {
                            var child = _d[_c];
                            if (child[key] === text) {
                                child.parent_name = screen_6.name;
                                child.parent_title = screen_6.title;
                                return child;
                            }
                            else if (child.sections) {
                                for (var _e = 0, _f = child.sections; _e < _f.length; _e++) {
                                    var sub = _f[_e];
                                    if (sub[key] === text) {
                                        sub.parent_name = child.name;
                                        sub.parent_title = child.title;
                                        sub.grandparent_name = screen_6.name;
                                        sub.grandparent_title = screen_6.title;
                                        return sub;
                                    }
                                }
                            }
                        }
                    }
                }
            };
            ChartingTemplateService.prototype.getParent = function (model, template_name) {
                var parent;
                for (var _i = 0, _a = this.chartingScreens[template_name]; _i < _a.length; _i++) {
                    var section = _a[_i];
                    if (section.childSections) {
                        for (var _b = 0, _c = section.childSections; _b < _c.length; _b++) {
                            var child = _c[_b];
                            if (child.model === model) {
                                parent = section;
                            }
                        }
                    }
                }
                return parent;
            };
            //todo: IChartModel
            ChartingTemplateService.prototype.getChartStoreSectionModel = function (section, chartStore) {
                var model;
                if (section.singleNode && chartStore.model[section.model]) {
                    model = chartStore.model[section.model];
                }
                else if (!section.rootNode && section.model) {
                    if (chartStore.model[section.model] && chartStore.model[section.model].length) {
                        if (section.allowMultiple) {
                            model = chartStore.model[section.model];
                        }
                        else {
                            model = chartStore.model[section.model][0];
                        }
                    }
                }
                else if (section.rootNode) {
                    model = chartStore.model;
                }
                return model;
            };
            ChartingTemplateService.prototype.chartStoreSectionInit = function (section, chartStore, currentPatient) {
                if (section.singleNode) {
                    // Don't initialize a singleNode section (patient time, evv) in the editor if the chart is locked
                    // This prevents old tablet optimized charts that are locked from showing a blank PT/EVV section when viewing in the editor
                    if (!chartStore.model[section.model] && !chartStore.model.locked) {
                        chartStore.model[section.model] = this.getDefault(section, currentPatient, chartStore);
                        if (section.sectionDefaultFn) {
                            section.sectionDefaultFn(chartStore);
                        }
                    }
                    else {
                        return false;
                    }
                }
                else if (!section.rootNode && section.model) {
                    if (!chartStore.model[section.model]) {
                        chartStore.model[section.model] = [];
                    }
                    if (!chartStore.model[section.model].length) {
                        chartStore.model[section.model][0] = this.getDefault(section, currentPatient, chartStore);
                        if (section.sectionDefaultFn) {
                            section.sectionDefaultFn(chartStore);
                        }
                    }
                    else {
                        return false;
                    }
                }
                else if (section.rootNode && !chartStore.model.role_id) {
                    this.getDefault(section, currentPatient, chartStore);
                }
                else {
                    return false;
                }
                return true;
            };
            ChartingTemplateService.prototype.formModelInit = function (chartStore, sectionName) {
                console.log('formModelInit', sectionName);
                var section_template_name;
                var default_template;
                var retVal = {};
                if (!chartStore.model.charting_client) {
                    chartStore.model.charting_client = 'encounter_charting';
                }
                if (chartStore.model.charting_client === 'custom') {
                    default_template = 'encounter_charting';
                }
                section_template_name = this.getTemplateName(chartStore.model);
                var section = this.sectionSearchBy(sectionName, 'name', section_template_name, default_template);
                if (section.sections) {
                    for (var _i = 0, _a = section.sections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        if (s.singleNode || s.allowMultiple) {
                            retVal[s.model] = chartStore.model[s.model];
                        }
                        else if (!s.rootNode && chartStore.model[s.model]) {
                            retVal[s.model] = chartStore.model[s.model][0];
                        }
                        else if (s.rootNode) {
                            retVal[s.model] = chartStore.model;
                        }
                    }
                    return retVal;
                }
                else {
                    if (section.rootNode) {
                        retVal[section.model] = chartStore.model;
                    }
                    else if (section.singleNode || section.allowMultiple) {
                        retVal[section.model] = chartStore.model[section.model];
                    }
                    else {
                        retVal[section.model] = chartStore.model[section.model][0];
                    }
                }
                return retVal;
            };
            ChartingTemplateService.prototype.getSectionField = function (fieldName, sectionModel, fieldTemplate) {
                if (!fieldTemplate) {
                    fieldTemplate = this.chartingFields;
                }
                var sections = fieldTemplate[sectionModel];
                if (!sections) {
                    return;
                }
                for (var _i = 0, sections_1 = sections; _i < sections_1.length; _i++) {
                    var s = sections_1[_i];
                    for (var _a = 0, _b = s.fields; _a < _b.length; _a++) {
                        var f = _b[_a];
                        if (f.field === fieldName) {
                            return f;
                        }
                    }
                }
            };
            ChartingTemplateService.prototype.checkFieldDependencies = function () {
                var keys = Object.keys(this.chartingFields);
                var re;
                var dependencies = [];
                var fieldDependencies = [];
                var fnArray = ['hideFn', 'requiredFn', 'labelFn'];
                for (var _i = 0, keys_3 = keys; _i < keys_3.length; _i++) {
                    var key = keys_3[_i];
                    var section = this.chartingFields[key];
                    dependencies = [];
                    for (var _a = 0, section_1 = section; _a < section_1.length; _a++) {
                        var subsection = section_1[_a];
                        for (var _b = 0, _c = subsection.fields; _b < _c.length; _b++) {
                            var field = _c[_b];
                            for (var _d = 0, fnArray_1 = fnArray; _d < fnArray_1.length; _d++) {
                                var fnType = fnArray_1[_d];
                                if (field[fnType]) {
                                    fieldDependencies = [];
                                    var fn = field[fnType].toString();
                                    var fnParam = fn.match(/function\s*\(\s*(.*?)\s*\)/);
                                    if (fnParam) {
                                        var p = fnParam[1].split(',')[0]; // only check the first param which is formModel
                                        re = new RegExp(p + '\\.(\\w*)', 'g');
                                        var matches = fn.match(re) || [];
                                        for (var _e = 0, matches_1 = matches; _e < matches_1.length; _e++) {
                                            var m = matches_1[_e];
                                            m = m.replace(p + '.', '');
                                            if (m !== 'id') {
                                                if (dependencies.indexOf(m) < 0) {
                                                    dependencies.push(m);
                                                }
                                                var baseField = this.getSectionField(m, key);
                                                if (baseField) {
                                                    this.checkDependencyList(field, baseField);
                                                }
                                                else {
                                                    console.groupCollapsed('Could not find dependency "' + m + '" in "' + key + '"');
                                                    console.log('Field', field);
                                                    console.log(fnType, fn);
                                                    console.log('match', m);
                                                    console.groupEnd();
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (var _f = 0, section_2 = section; _f < section_2.length; _f++) {
                        var subsection = section_2[_f];
                        for (var _g = 0, _h = subsection.fields; _g < _h.length; _g++) {
                            var field = _h[_g];
                            if (dependencies.indexOf(field.field) >= 0) {
                                field.isDependency = true;
                            }
                        }
                    }
                }
            };
            ChartingTemplateService.prototype.checkDependencyList = function (field, dependencyField) {
                if (!field.dependencies) {
                    field.dependencies = [];
                }
                var found = false;
                for (var _i = 0, _a = field.dependencies; _i < _a.length; _i++) {
                    var d = _a[_i];
                    if (d.field === dependencyField.field) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    field.dependencies.push({ label: dependencyField.label, field: dependencyField.field });
                }
            };
            ChartingTemplateService.prototype.getFirstSection = function (section_template) {
                // if (this.isEncounterCharting(charting_client)) {
                //   if (this.chartingScreens[section_template]) {
                return angular.copy(this.chartingScreens[section_template][0]);
                //   } else {
                //     return angular.copy(this.chartingScreens['encounter_charting'][0]);
                //   }
                // } else {
                //   return angular.copy(this.chartingScreens['tablet_optimized'][0]);
                // }
            };
            ChartingTemplateService.prototype.getMissingSections = function (et) {
                var etSections = this.getScreens(et.id);
                var base = this.getScreens('base_encounter');
                var missing = [];
                var found;
                for (var _i = 0, base_1 = base; _i < base_1.length; _i++) {
                    var s = base_1[_i];
                    found = this.sectionSearchBy(s.name, 'name', et.id);
                    if (!found) {
                        missing.push(s);
                    }
                }
                if (missing.length) {
                    missing.sort(function (a, b) {
                        if (a.title < b.title) {
                            return -1;
                        }
                        else if (a.title > b.title) {
                            return 1;
                        }
                        else {
                            return 0;
                        }
                    });
                }
                return missing;
            };
            ChartingTemplateService.prototype.getFlattenedSections = function (template) {
                var screens = this.getScreens(template);
                var flat = [];
                for (var _i = 0, screens_2 = screens; _i < screens_2.length; _i++) {
                    var section = screens_2[_i];
                    if (section.sections) {
                        for (var _a = 0, _b = section.sections; _a < _b.length; _a++) {
                            var sub = _b[_a];
                            flat.push(sub);
                        }
                    }
                    else if (section.childSections) {
                        for (var _c = 0, _d = section.childSections; _c < _d.length; _c++) {
                            var child = _d[_c];
                            if (child.sections) {
                                for (var _e = 0, _f = child.sections; _e < _f.length; _e++) {
                                    var sub = _f[_e];
                                    flat.push(sub);
                                }
                            }
                            else {
                                flat.push(child);
                            }
                        }
                    }
                    else {
                        flat.push(section);
                    }
                }
                return flat;
            };
            ChartingTemplateService.prototype.applyOptions = function (field, options) {
                if (!field.defaultOptions) {
                    field.defaultOptions = [];
                }
                if (field.denormalized) {
                    field.options = options.map(function (a) {
                        return { id: a.name, description: a.name };
                    });
                }
                else {
                    field.options = options.map(function (a) {
                        return { id: a.id, description: a.name };
                    });
                }
                field.options = field.options.concat(field.defaultOptions);
            };
            ChartingTemplateService.prototype.getFormDefinition = function (sectionName, encounterTypeId, patientId, encounterType) {
                try {
                    var sections = [];
                    var template_name = encounterType && encounterType.lookup_name ? encounterType.lookup_name
                        : encounterTypeId === 'encounter_charting'
                            ? 'encounter_charting'
                            : 'encounter_charting';
                    var default_template = encounterType && this.isEncounterCharting(encounterType.charting_type)
                        ? '_flat_encounter_charting'
                        : null;
                    var chartingSection = this.sectionSearchBy(sectionName, 'name', template_name, default_template);
                    if (!chartingSection) {
                        return;
                    }
                    if (!chartingSection.sections) {
                        chartingSection.sections = [angular.copy(chartingSection)];
                    }
                    var enc_required_fields = {};
                    var field_logic = {};
                    var fieldDefinitions = void 0;
                    if (encounterType && encounterType.required_fields) {
                        enc_required_fields = encounterType.required_fields;
                    }
                    if (encounterType && encounterType.field_logic) {
                        field_logic = encounterType.field_logic;
                    }
                    if (encounterType && this.isEncounterCharting(encounterType.charting_type)) {
                        fieldDefinitions = this.templateFieldDefinitions[template_name];
                    }
                    // if (encounterType && !isNaN(encounterType.id)) {
                    //   sectionCopy = this.chartingScreens[template_name].field_definition;
                    // }
                    if (!fieldDefinitions) {
                        fieldDefinitions = this.getFieldDefinitions();
                    }
                    for (var _i = 0, _a = chartingSection.sections; _i < _a.length; _i++) {
                        var sec = _a[_i];
                        if (!fieldDefinitions[sec.model]) {
                            console.error('[ChartingTemplateService - getFormDefinition] No form definition found for section "' +
                                sec.model +
                                '".');
                            // throw '[ChartingTemplateService - getFormDefinition] No form definition found for section "' + name + '".';
                            return null;
                        }
                        // encType = SectionManager.encounterType ? SectionManager.encounterType : '';
                        // let s = SectionManager.getByModel(name, true);
                        // let enc_required_fields = $parse('required_fields.' + s.name)(encType);
                        // let hidden_fields;
                        // if (encounterType.field_logic) {
                        //   for (let (key) of Object.keys(encounterType.field_logic)) {
                        //     for (let i = encounterType.field_logic[key].length - 1; i >= 0; --i) {
                        //       for (let k = encounterType.field_logic[key][i].fields.length - 1; i >= 0; --i ) {
                        //       }
                        //     }
                        //   }
                        // }
                        sec.required_fields = [];
                        for (var _b = 0, _c = fieldDefinitions[sec.model]; _b < _c.length; _b++) {
                            var section = _c[_b];
                            for (var _d = 0, _e = section.fields; _d < _e.length; _d++) {
                                var field = _e[_d];
                                if (enc_required_fields[sec.name] &&
                                    (enc_required_fields[sec.name].indexOf(field.field) > -1 ||
                                        enc_required_fields[sec.name].indexOf(field.encounter_type_field) > -1)) {
                                    field.required = true;
                                    field.et_required = true;
                                }
                                else if (enc_required_fields[sec.name] &&
                                    enc_required_fields[sec.name].indexOf(field.field.replace('_id', '')) > -1 &&
                                    field.field.indexOf('_id') === field.field.length - 3) {
                                    field.required = true;
                                    field.et_required = true;
                                }
                                if (field_logic[sec.name] && field_logic[sec.name][field.field]) {
                                    field.required = field_logic[sec.name][field.field].required;
                                    field.et_required = field_logic[sec.name][field.field].required;
                                    field.hidden = field_logic[sec.name][field.field].hidden;
                                    field.common = field_logic[sec.name][field.field].common;
                                }
                                this.setFieldOptions(field, patientId);
                                if (field.required || field.requiredFn) {
                                    section.has_required = true;
                                    sec.has_required = true;
                                    sec.required_fields.push(field);
                                }
                                sec.has_et_required = sec.has_et_required || field.et_required;
                            }
                        }
                        var tempSection = angular.copy(sec);
                        tempSection.field_definition = fieldDefinitions[sec.model];
                        sections.push(tempSection);
                    }
                    return sections;
                }
                catch (e) {
                    var message = e.message || e;
                    this.logger.error(message);
                    throw e;
                }
            };
            ChartingTemplateService.prototype.getLookups = function () {
                var lookups = [];
                var sectionCopy = this.getFieldDefinitions();
                for (var name_3 in sectionCopy) {
                    if (sectionCopy.hasOwnProperty(name_3)) {
                        for (var i = 0; i < sectionCopy[name_3].length; i++) {
                            for (var ii = 0; ii < sectionCopy[name_3][i].fields.length; ii++) {
                                var field = sectionCopy[name_3][i].fields[ii];
                                if (field.lookup && lookups.indexOf(field.lookup) === -1) {
                                    lookups.push(field.lookup);
                                }
                            }
                        }
                    }
                }
                return lookups;
            };
            ChartingTemplateService.prototype.setFieldOptions = function (field, patientId) {
                var _this = this;
                if (field.type === 'select' || field.type === 'rating' ||
                    field.type === 'checklist' || field.type === 'multiselect') {
                    if (field.query && patientId) {
                        return this.cache(this.queryMap[field.query], field.query, { maxAge: 10, force: true })
                            .query({ patient_id: patientId })
                            .$promise.then(function (data) {
                            _this.applyOptions(field, data);
                            if (field.includes_patient_name) {
                                field.options = field.options.concat({
                                    id: _this.CurrentPatient.patient.name,
                                    description: _this.CurrentPatient.patient.name,
                                });
                            }
                        });
                    }
                    else if (field.lookup) {
                        var cachedLookup = this.cache(this.Lookup, field.lookup, { maxAge: 10 });
                        return cachedLookup[field.lookup]().$promise.then(function (data) {
                            _this.applyOptions(field, data);
                        });
                    }
                    else {
                        if (field.type === 'rating') {
                            field.options = [];
                        }
                        else {
                            field.options = null;
                        }
                        return this.options.get(field.optionsType).$promise.then(function (data) {
                            field.options = data;
                        });
                    }
                }
                else {
                    return this.$q.when(true);
                }
            };
            ChartingTemplateService.prototype.getOptions = function () {
                /* jshint forin:false */
                var optionsType = [];
                var sectionCopy = this.getFieldDefinitions();
                for (var name_4 in sectionCopy) {
                    if (sectionCopy.hasOwnProperty(name_4)) {
                        for (var i = 0; i < sectionCopy[name_4].length; i++) {
                            for (var ii = 0; ii < sectionCopy[name_4][i].fields.length; ii++) {
                                var field = sectionCopy[name_4][i].fields[ii];
                                if (field.optionsType && optionsType.indexOf(field.optionsType) === -1) {
                                    optionsType.push(field.optionsType);
                                }
                            }
                        }
                    }
                }
                return optionsType;
            };
            /* Returns an array of [Model, 'name'] pairs */
            ChartingTemplateService.prototype.getQueries = function () {
                var queries = [], added = [];
                var sectionCopy = this.getFieldDefinitions();
                /* jshint forin:false */
                for (var name_5 in sectionCopy) {
                    if (sectionCopy.hasOwnProperty(name_5)) {
                        for (var i = 0; i < sectionCopy[name_5].length; i++) {
                            for (var ii = 0; ii < sectionCopy[name_5][i].fields.length; ii++) {
                                var field = sectionCopy[name_5][i].fields[ii];
                                if (field.query && added.indexOf(field.query) === -1) {
                                    queries.push([this.queryMap[field.query], field.query]);
                                    added.push(field.query);
                                }
                            }
                        }
                    }
                }
                return queries;
            };
            //TODO formManager.setErrors, pass activeSections
            ChartingTemplateService.prototype.setErrors = function (errors, sections) {
                var sectionCopy = this.getFieldDefinitions();
                for (var _i = 0, sections_2 = sections; _i < sections_2.length; _i++) {
                    var section = sections_2[_i];
                    section.errorCount = 0;
                    if (errors[section.model] && errors[section.model][0] !== {}) {
                        var model = section.singleNode ? errors[section.model] : errors[section.model][0];
                        section.errorCount = Object.keys(model).length;
                        if (section.controller) {
                            return;
                        }
                        for (var _a = 0, _b = sectionCopy[section.model]; _a < _b.length; _a++) {
                            var subsection = _b[_a];
                            for (var _c = 0, _d = subsection.fields; _c < _d.length; _c++) {
                                var field = _d[_c];
                                if (field.type === 'booltext') {
                                    if (model['has_' + field.field]) {
                                        field.invalid = true;
                                    }
                                    else {
                                        field.invalid = false;
                                    }
                                }
                                else {
                                    if (model[field.field]) {
                                        field.invalid = true;
                                    }
                                    else {
                                        field.invalid = false;
                                    }
                                }
                            }
                        }
                    }
                }
            };
            ChartingTemplateService.prototype.getDefault = function (section, currentPatient, chartStore) {
                var _this = this;
                var sectionModel = section.model;
                var sectionName = section.name;
                var defaultObj = {};
                var sectionCopy = this.getFieldDefinitions();
                var model = chartStore.model;
                if (sectionModel === 'general') {
                    model.role_id = !model.role_id
                        ? this.user.default_role
                            ? this.user.default_role.id
                            : null
                        : model.role_id;
                    model.user = !model.user ? this.user : model.user;
                    model.user_id = !model.user_id ? this.user.id : model.user_id;
                    return;
                }
                if (sectionModel === 'visit_task_lists') {
                    var tasksPromise = !(model.visit_task_lists &&
                        model.visit_task_lists.length &&
                        (model.visit_task_lists[0].id || model.visit_task_lists[0].task_assignment_id))
                        ? this.visitTaskList.loadByPatientId(currentPatient.id).$promise.then(function (visitTasks) {
                            var today = new Date();
                            defaultObj = [];
                            visitTasks.forEach(function (v, i) {
                                var visitTask = {
                                    open: false,
                                    patient_id: v.patient_id,
                                    task_assignment_id: v.task_assignment_id,
                                    user_id: _this.user.id,
                                    effective_date: today.getFullYear() + "-" + (today.getMonth() +
                                        1) + "-" + today.getDate(),
                                    description: v.description,
                                    recurring_task_actions: v.tasks.map(function (t) {
                                        return {
                                            recurring_task_id: t.id,
                                            description: t.description,
                                            completed: false,
                                            acknowledged: false,
                                        };
                                    }),
                                };
                                if (i === 0 && defaultObj[0]) {
                                    defaultObj[i] = Object.assign(defaultObj[i], visitTask);
                                }
                                else {
                                    defaultObj.push(visitTask);
                                }
                            });
                            return defaultObj;
                        })
                        : Promise.resolve(model.visit_task_lists);
                    return Promise.all([tasksPromise].map(function (p) { return p.catch(function (e) { return e; }); })).then(function (resolvedPromises) {
                        var visitTasks = resolvedPromises[0];
                        visitTasks.forEach(function (taskList) {
                            taskList.open = false;
                            if (!taskList.description &&
                                taskList.recurring_task_actions &&
                                taskList.recurring_task_actions.length) {
                                taskList.description = taskList.recurring_task_actions[0].set_description;
                            }
                        });
                        model.visit_task_lists = visitTasks;
                        return visitTasks;
                    });
                }
                if (!sectionCopy[sectionModel]) {
                    throw '[ChartingTemplateService - getDefault] No form definition found for section "' +
                        sectionModel +
                        '".';
                }
                var field_logic = {};
                if (model &&
                    model.encounter_type &&
                    this.isEncounterCharting(model.charting_client) &&
                    model.encounter_type.field_logic) {
                    if (model.encounter_type.field_logic[sectionName]) {
                        field_logic = model.encounter_type.field_logic[sectionName];
                    }
                }
                for (var _i = 0, _a = sectionCopy[sectionModel]; _i < _a.length; _i++) {
                    var section_3 = _a[_i];
                    for (var _b = 0, _c = section_3.fields; _b < _c.length; _b++) {
                        var fieldDefault = _c[_b];
                        var f = field_logic[fieldDefault.field] || {};
                        var field = angular.merge({}, fieldDefault, f);
                        if (field.hideFn) {
                            field.hidden = field.hidden || field.hideFn(model, currentPatient);
                        }
                        if (field.default && !field.hidden) {
                            // if (field.field === 'code_status') {
                            //   defaultObj.code_status = currentPatient.code_status;
                            //   defaultObj.has_living_will = currentPatient.has_living_will;
                            //   defaultObj.note_living_will = currentPatient.note_living_will;
                            //   defaultObj.has_dpa = currentPatient.has_dpa;
                            //   defaultObj.note_dpa = currentPatient.note_dpa;
                            //   defaultObj.has_designated_surrogate = currentPatient.has_designated_surrogate;
                            //   defaultObj.note_designated_surrogate = currentPatient.note_designated_surrogate;
                            // } else
                            if (field.defaultValue) {
                                defaultObj[field.field] = field.defaultValue;
                            }
                            else if (field.defaultValueFn) {
                                var val = field.defaultValueFn(model, currentPatient);
                                if (field.type === 'booltext') {
                                    defaultObj['note_' + field.field] = val;
                                    defaultObj['has_' + field.field] = !!(val && val.length);
                                }
                                else {
                                    defaultObj[field.field] = val;
                                }
                            }
                            else if (field.lookup === 'users') {
                                defaultObj.user = this.user;
                                defaultObj.user_id = this.user.id;
                            }
                            else if (field.type === 'booltext') {
                                defaultObj['has_' + field.field] = true;
                            }
                            else if (field.type === 'checkbox') {
                                defaultObj[field.field] = true;
                            }
                            else if (field.lookup === 'physicians') {
                                if (this.user.physician) {
                                    defaultObj.physician = this.user.physician;
                                    defaultObj.physician_id = this.user.physician.id;
                                }
                            }
                        }
                    }
                }
                return defaultObj;
            };
            //TODO formManager.clearErrors pass activeSections
            ChartingTemplateService.prototype.clearErrors = function (activeSections) {
                var sectionCopy = this.getFieldDefinitions();
                for (var _i = 0, activeSections_1 = activeSections; _i < activeSections_1.length; _i++) {
                    var section = activeSections_1[_i];
                    section.error_count = 0;
                    //TODO - should we still handle component errors in editor?
                    if (section.componentName) {
                        return;
                    }
                    for (var _a = 0, _b = sectionCopy[section.model]; _a < _b.length; _a++) {
                        var subsection = _b[_a];
                        for (var _c = 0, _d = subsection.fields; _c < _d.length; _c++) {
                            var field = _d[_c];
                            field.invalid = false;
                        }
                    }
                }
            };
            ChartingTemplateService.prototype.getChartingClient = function (client) {
                var retVal = this.chartingClients.filter(function (c) { return c.client === client; })[0];
                if (!retVal) {
                    return {
                        client: client,
                        description: 'Unkown: ' + client,
                        is_encounter: true,
                    };
                }
                return retVal;
            };
            ChartingTemplateService.prototype.getCarePlanProblemSection = function (title) {
                var cppSection = {
                    name: 'care_plan_problem_update',
                    model: 'care_plan_problem_updates',
                    allowMultiple: true,
                    title: title || 'Care Plan Problem',
                    componentName: 'consolo-charting-care-plan-problem-update',
                    field_definition: [],
                    required_fields: [],
                    has_required: false,
                };
                return cppSection;
            };
            // This should only used for those old Encounter Types that were converted for use with Encounter Charting
            // as they do not have a title by default
            // We do this here because of circular dependency issues
            ChartingTemplateService.prototype.setDefaultSectionTitle = function (section) {
                if (!section.title) {
                    var baseSection = this.sectionSearchBy(section.name, 'name', 'base_encounter');
                    section.title = baseSection ? baseSection.title : 'Unknown';
                }
            };
            ChartingTemplateService.prototype.buildEncounterChartingSections = function () {
                var cpy = angular.copy(this.chartingScreens['base_encounter']);
                var _thisService = this;
                var encounterScreens = [
                    {
                        name: 'encounter_charting',
                        title: 'General Details',
                        sections: [
                            { name: 'general' },
                            { name: 'reason_for_visit' },
                            { name: 'patient_time' },
                            { name: 'electronic_visit_verification' },
                            { name: 'document_upload' }
                        ],
                    },
                    // { name: 'patient_diagnosis' },
                    { name: 'chief_complaint' },
                    { name: 'history_of_present_illness' },
                    { name: 'review_of_systems' },
                    { name: 'patient_history' },
                    { name: 'veterans_assessment' },
                    {
                        title: 'Physical Exam',
                        name: 'physical_exam',
                        childSections: [
                            { name: 'vital_sign_measurement' },
                            { name: 'body_systems_constitutional' },
                            { name: 'body_systems_heent' },
                            { name: 'body_systems_respiratory_oxygenation_finding' },
                            { name: 'body_systems_cardiac_circulatory_finding' },
                            { name: 'body_systems_elimination_finding' },
                            { name: 'body_systems_genitourinary' },
                            { name: 'body_systems_hli' },
                            { name: 'body_systems_skin_integrity_circulation_finding' },
                            { name: 'body_systems_endocrine' },
                            { name: 'body_systems_activity_musculoskeletal_finding' },
                            { name: 'body_systems_neurology_finding' },
                            { name: 'body_systems_self_esteem_mental_status_finding' },
                        ],
                    },
                    // { name: 'assessment_plan' },
                    // { name: 'visit_summary' },
                    {
                        title: 'Instruments',
                        name: 'instruments',
                        childSections: [
                            { name: 'mini_nutritional_assessment' },
                            { name: 'skin_integrity_assessment' },
                            { name: 'wound_assessment' },
                            { name: 'morse_fall_assessment' },
                            { name: 'bereavement_assessment' },
                            { name: 'social_assessment' },
                            { name: 'spiritual_assessment' },
                            {
                                name: 'teaching_and_learning_assessment',
                            },
                        ],
                    },
                    {
                        title: 'Additional Charting Options',
                        name: 'additional',
                        childSections: [
                            { name: 'nursing_related_observation' },
                            { name: 'nursing_pain_history' },
                            { name: 'fall_event' },
                            { name: 'edmonton_assessment' },
                            { name: 'explanation_of_service' },
                            { name: 'nursing_summary' },
                            { name: 'complementary_alternative_medicine_request' },
                            { name: 'inpatient_unit_note' },
                            { name: 'visit_note' },
                            { name: 'visit_task_list' },
                            { name: 'body_systems_communication_finding' },
                            { name: 'body_systems_nutrition_hydration_finding' },
                            { name: 'coordination_of_care' },
                            { name: 'nursing_health_history' },
                            { name: 'face_to_face_visit' },
                            { name: 'pain_observation' },
                            { name: 'supervisory_visit' },
                            { name: 'pediatric_fall_risk_assessment' },
                            { name: 'oxygen_safety_assessment' },
                        ],
                    },
                ];
                cpy.forEach(function (section) {
                    if (section.checkIfAgencyEnabled &&
                        _thisService[section.checkIfAgencyEnabled] &&
                        !section.additionalSection) {
                        encounterScreens.push({ name: section.name });
                    }
                });
                this.buildETSections({
                    name: 'Encounter Charting',
                    lookup_name: 'encounter_charting',
                    display_name: 'Default Encounter Charting',
                }, encounterScreens);
                this.chartingScreens['_flat_encounter_charting'] = this.getFlattenedSections('encounter_charting');
            };
            ChartingTemplateService.prototype.buildBasicTemplates = function () {
                var etSections = [
                    {
                        title: 'General Details',
                        name: 'general',
                    },
                    {
                        title: 'Patient Time',
                        name: 'patient_time',
                    },
                    {
                        title: 'Reason For Visit',
                        name: 'reason_for_visit',
                    },
                    {
                        title: 'Electronic Visit Verification',
                        name: 'electronic_visit_verification',
                    },
                    {
                        title: 'Chief Complaint',
                        name: 'chief_complaint',
                    },
                    {
                        title: 'History of Present Illness (HPI)',
                        name: 'history_of_present_illness',
                    },
                    {
                        title: 'Review of Systems (ROS)',
                        name: 'review_of_systems',
                    },
                    {
                        title: 'Patient History (PSH/PMH/PFH)',
                        name: 'patient_history',
                    },
                    {
                        title: 'Vital Sign Measurement',
                        name: 'vital_sign_measurement',
                    },
                    {
                        title: 'Constitutional',
                        name: 'body_systems_constitutional',
                    },
                    {
                        title: 'HEENT',
                        name: 'body_systems_heent',
                    },
                    {
                        title: 'Respiratory',
                        name: 'body_systems_respiratory_oxygenation_finding',
                        description: 'aka "Body Systems Respiratory Oxygenation Finding"',
                    },
                    {
                        title: 'Cardiovascular',
                        name: 'body_systems_cardiac_circulatory_finding',
                        description: 'aka "Body Systems Cardiac Circulatory Finding"',
                    },
                    {
                        title: 'Gastrointestinal',
                        name: 'body_systems_elimination_finding',
                        description: 'aka "Body Systems Elimination Finding"',
                    },
                    {
                        title: 'Genitourinary',
                        name: 'body_systems_genitourinary',
                    },
                    {
                        title: 'Hematologic / Lymphatic / Immunologic / Oncology',
                        name: 'body_systems_hli',
                    },
                    {
                        title: 'Skin',
                        name: 'body_systems_skin_integrity_circulation_finding',
                        description: 'aka "Body Systems Skin Integrity Circulation Finding"',
                    },
                    {
                        title: 'Endocrine',
                        name: 'body_systems_endocrine',
                    },
                    {
                        title: 'Musculoskeletal',
                        name: 'body_systems_activity_musculoskeletal_finding',
                        description: 'aka "Body Systems Activity Musculoskeletal Finding"',
                    },
                    {
                        title: 'Neurological',
                        name: 'body_systems_neurology_finding',
                        description: 'aka "Body Systems Neurology Finding"',
                    },
                    {
                        title: 'Psychiatric',
                        name: 'body_systems_self_esteem_mental_status_finding',
                        description: 'aka "Body Systems Self Esteem Mental Status"',
                    },
                    {
                        title: 'Mini Nutritional',
                        name: 'mini_nutritional_assessment',
                        description: 'aka "Mini Nutritional Assessment"',
                    },
                    {
                        title: 'Wound',
                        name: 'wound_assessment',
                        description: 'aka "Wound Assessment"',
                    },
                    {
                        title: 'Morse Fall',
                        name: 'morse_fall_assessment',
                        description: 'aka "Morse Fall Assessment"',
                    },
                    {
                        title: 'Bereavement',
                        name: 'bereavement_assessment',
                        description: 'aka "Bereavement Assessment"',
                    },
                    {
                        title: 'Psychosocial',
                        name: 'social_assessment',
                        description: 'aka "Social Assessment"',
                    },
                    {
                        title: 'Spiritual',
                        name: 'spiritual_assessment',
                        description: 'aka "Spiritual Assessment"',
                    },
                    {
                        title: 'Teaching & Learning',
                        name: 'teaching_and_learning_assessment',
                        description: 'aka "Teaching and Learning Assessment"',
                    },
                    {
                        title: 'Nursing Related Observation',
                        name: 'nursing_related_observation',
                    },
                    {
                        title: 'Nursing Pain History',
                        name: 'nursing_pain_history',
                    },
                    {
                        title: 'Fall Event',
                        name: 'fall_event',
                    },
                    {
                        title: 'Explanation of Service',
                        name: 'explanation_of_service',
                    },
                    {
                        title: 'Nursing Summary',
                        name: 'nursing_summary',
                    },
                    {
                        title: 'Complementary Alternative Medicine Request',
                        name: 'complementary_alternative_medicine_request',
                    },
                    {
                        title: 'Inpatient Unit Note',
                        name: 'inpatient_unit_note',
                    },
                    {
                        title: 'Visit Task List',
                        name: 'visit_task_list',
                    },
                    {
                        title: 'Visit Note',
                        name: 'visit_note',
                    },
                    {
                        title: 'Communication',
                        name: 'body_systems_communication_finding',
                        description: 'aka "Body Systems Communication Finding"',
                    },
                    {
                        title: 'Nutrition \\ Hydration',
                        name: 'body_systems_nutrition_hydration_finding',
                        description: 'aka "Body Systems Nutrition Hydration Finding"',
                    },
                    {
                        title: 'Coordination of Care',
                        name: 'coordination_of_care',
                    },
                    {
                        title: 'Supervisory Visit',
                        name: 'supervisory_visit',
                    },
                    {
                        title: 'Face to Face Visit',
                        name: 'face_to_face_visit',
                    },
                    {
                        title: 'Nursing Health History',
                        name: 'nursing_health_history',
                    },
                    {
                        title: 'Pain Observation',
                        name: 'pain_observation',
                    },
                    {
                        title: 'Problem Assessment',
                        name: 'reviewed_problem_list_item',
                        checkIfAgencyEnabled: 'problemListEnabled',
                    },
                    {
                        title: 'Edmonton Assessment (ESAS)',
                        name: 'edmonton_assessment',
                        description: 'aka "Edmonton System Assessment Scale (ESAS)"',
                    },
                    {
                        title: 'MIPS / CQM',
                        name: 'cqms',
                        checkIfAgencyEnabled: 'cqmsEnabled',
                    },
                    {
                        title: 'Pediatric Fall Risk Assessment',
                        name: 'pediatric_fall_risk_assessment',
                        checkIfAgencyEnabled: 'pediatricAssessmentEnabled',
                    },
                    {
                        title: 'Oxygen Safety Assessment',
                        name: 'oxygen_safety_assessment',
                        checkIfAgencyEnabled: 'oxygenAssessmentEnabled',
                    },
                    {
                        title: 'Document Upload',
                        name: 'document_upload'
                    },
                    {
                        title: 'Veterans Assessment',
                        name: 'veterans_assessment',
                    }
                ];
                var chartingSection;
                for (var _i = 0, _a = this.baseSections; _i < _a.length; _i++) {
                    var base = _a[_i];
                    var sections = this.chartingFields[base.model];
                    if (sections) {
                        for (var _b = 0, sections_3 = sections; _b < sections_3.length; _b++) {
                            var sub = sections_3[_b];
                            for (var _c = 0, _d = sub.fields; _c < _d.length; _c++) {
                                var field = _d[_c];
                                if (field.required || field.requiredFn) {
                                    base.has_required = true;
                                }
                            }
                        }
                    }
                    //set defaults
                    base.icon = base.icon ? base.icon : '';
                    base.componentName = base.componentName ? base.componentName : '';
                    base.rootNode = base.rootNode ? base.rootNode : false;
                    base.common = base.common ? base.common : false;
                    base.noPermissions = base.noPermissions ? base.noPermissions : false;
                    base.ordinal = base.ordinal ? base.ordinal : 999;
                    base.active = base.active ? base.active : false;
                    base.singleNode = base.singleNode ? base.singleNode : false;
                    base.allowMultiple = base.allowMultiple ? base.allowMultiple : false;
                }
                this.chartingScreens['base_encounter'] = [];
                this.chartingScreens['tablet_optimized'] = [];
                //Tablet Optimized Sections - default sections
                var tablet = angular.copy(this.baseSections);
                for (var _e = 0, tablet_1 = tablet; _e < tablet_1.length; _e++) {
                    var section = tablet_1[_e];
                    if (!section.chartingClients || section.chartingClients.indexOf('tablet_optimized') >= 0) {
                        this.chartingScreens['tablet_optimized'].push(section);
                    }
                    if (section.additionalSection) {
                        this.additionalChartingSections.push(section.name);
                    }
                }
                //Encounter Charting Sections have different names
                var encounter = angular.copy(this.baseSections);
                var _thisService = this;
                var _loop_2 = function (section) {
                    chartingSection = encounter.filter(function (s) {
                        return (s.name === section.name &&
                            (!s.chartingClients || s.chartingClients.indexOf('encounter_charting') >= 0));
                    })[0];
                    if (chartingSection &&
                        (!chartingSection.checkIfAgencyEnabled ||
                            _thisService[chartingSection.checkIfAgencyEnabled])) {
                        angular.extend(chartingSection, section);
                        this_1.chartingScreens['base_encounter'].push(chartingSection);
                    }
                };
                var this_1 = this;
                for (var _f = 0, etSections_1 = etSections; _f < etSections_1.length; _f++) {
                    var section = etSections_1[_f];
                    _loop_2(section);
                }
            };
            ChartingTemplateService.prototype.setBaseTemplates = function () {
                this.baseSections = [
                    {
                        title: 'General Details',
                        name: 'general',
                        icon: 'action:assignment',
                        model: 'general',
                        componentName: 'consolo-charting-general',
                        rootNode: true,
                        common: true,
                        noPermissions: true,
                        ordinal: 0.1,
                        active: true,
                        sectionIsEmpty: function (section, model) {
                            return false;
                        },
                    },
                    {
                        title: 'Reason For Visit',
                        name: 'reason_for_visit',
                        model: 'reason_for_visit',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'Care Plan Problems',
                        name: 'care_plan_problem_update',
                        model: 'care_plan_problem_updates',
                        componentName: 'consolo-charting-care-plan-problem-update',
                        allowMultiple: true,
                        active: false,
                        hidden: true,
                    },
                    {
                        title: 'Patient Time',
                        name: 'patient_time',
                        icon: 'action:alarm',
                        model: 'patient_time',
                        componentName: 'consolo-charting-patient-time',
                        singleNode: true,
                        noPermissions: true,
                        ordinal: 0.2,
                        sectionIsEmpty: function (section, model) {
                            return false;
                        },
                    },
                    {
                        title: 'Electronic Visit Verification',
                        name: 'electronic_visit_verification',
                        icon: 'device:gps_fixed',
                        componentName: 'consolo-charting-evv',
                        model: 'electronic_visit_verification',
                        singleNode: true,
                        noPermissions: true,
                        ordinal: 1,
                        sectionIsEmpty: function (section, model) {
                            return false;
                        },
                    },
                    {
                        title: 'Bereavement Assessment',
                        name: 'bereavement_assessment',
                        allowMultiple: true,
                        icon: 'social:poll',
                        model: 'bereavement_assessments',
                    },
                    {
                        title: 'Body Systems Cardiac Circulatory Finding',
                        name: 'body_systems_cardiac_circulatory_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_cardiac_circulatory_findings',
                        ordinal: 6,
                    },
                    {
                        title: 'Body Systems Communication Finding',
                        name: 'body_systems_communication_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_communication_findings',
                        ordinal: 5,
                    },
                    {
                        title: 'Body Systems Elimination Finding',
                        name: 'body_systems_elimination_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_elimination_findings',
                        ordinal: 9,
                    },
                    {
                        title: 'Body Systems Neurology Finding',
                        name: 'body_systems_neurology_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_neurology_findings',
                        ordinal: 3,
                    },
                    {
                        title: 'Body Systems Nutrition Hydration Finding',
                        name: 'body_systems_nutrition_hydration_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_nutrition_hydration_findings',
                        ordinal: 8,
                    },
                    {
                        title: 'Body Systems Respiratory Oxygenation Finding',
                        name: 'body_systems_respiratory_oxygenation_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_respiratory_oxygenation_findings',
                        ordinal: 7,
                    },
                    {
                        title: 'Body Systems Self Esteem Mental Status Finding',
                        name: 'body_systems_self_esteem_mental_status_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_self_esteem_mental_status_findings',
                        ordinal: 4,
                    },
                    {
                        title: 'Body Systems Skin Integrity Circulation Finding',
                        name: 'body_systems_skin_integrity_circulation_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_skin_integrity_circulation_findings',
                        ordinal: 10,
                    },
                    {
                        title: 'Body Systems Activity/Musculoskeletal Finding',
                        name: 'body_systems_activity_musculoskeletal_finding',
                        icon: 'action:accessibility',
                        model: 'body_systems_activity_musculoskeletal_findings',
                        ordinal: 11,
                    },
                    {
                        title: 'Complementary Alternative Medicine Request',
                        name: 'complementary_alternative_medicine_request',
                        icon: 'maps:local_pharmacy',
                        model: 'complementary_alternative_medicine_requests',
                    },
                    {
                        title: 'Coordination of Care',
                        name: 'coordination_of_care',
                        icon: 'content:content_paste',
                        model: 'coordination_of_cares',
                        ordinal: 22,
                    },
                    {
                        title: 'Edmonton Assessment (ESAS)',
                        name: 'edmonton_assessment',
                        icon: 'social:poll',
                        model: 'edmonton_assessments',
                        chartingClients: ['encounter_charting'],
                        ordinal: 24,
                        componentName: 'consolo-charting-edmonton-assessment',
                    },
                    {
                        title: 'Explanation of Service',
                        name: 'explanation_of_service',
                        icon: 'content:content_paste',
                        model: 'explanation_of_services',
                        ordinal: 23,
                    },
                    {
                        title: 'Face to Face Visit',
                        name: 'face_to_face_visit',
                        icon: 'action:face',
                        model: 'face_to_face_visits',
                        serviceLines: ['hospice'],
                        componentName: 'consolo-charting-face-to-face-visit',
                    },
                    // {
                    //   title: 'Family Diagnosis History',
                    //   name: 'family_diagnosis_history',
                    //   icon: 'action:assignment',
                    //   componentName: 'consolo-charting-family-diagnosis-history',
                    //   model: 'family_diagnosis_histories',
                    //   additionalSection: true,
                    //   noPermissions: true,
                    //   singleNode: true,
                    // },
                    {
                        title: 'Fall Event',
                        name: 'fall_event',
                        icon: 'maps:directions_walk',
                        model: 'fall_events',
                        allowMultiple: true,
                        ordinal: 19,
                    },
                    {
                        title: 'Inpatient Unit Note',
                        name: 'inpatient_unit_note',
                        icon: 'action:assignment',
                        model: 'inpatient_unit_notes',
                        componentName: 'consolo-charting-inpatient-unit-note',
                    },
                    {
                        title: 'Mini Nutritional Assessment',
                        name: 'mini_nutritional_assessment',
                        icon: 'maps:restaurant_menu',
                        componentName: 'consolo-charting-mini-nutritional-assessment',
                        model: 'mini_nutritional_assessments',
                        ordinal: 13,
                    },
                    {
                        title: 'Morse Fall Assessment',
                        name: 'morse_fall_assessment',
                        icon: 'social:poll',
                        model: 'morse_fall_assessments',
                        ordinal: 18,
                    },
                    {
                        title: 'Nursing Health History',
                        name: 'nursing_health_history',
                        icon: 'action:alarm',
                        model: 'nursing_health_histories',
                        ordinal: 21,
                    },
                    {
                        title: 'Nursing Pain History',
                        name: 'nursing_pain_history',
                        icon: 'action:alarm',
                        model: 'nursing_pain_histories',
                        allowMultiple: true,
                        ordinal: 14,
                    },
                    {
                        title: 'Nursing Related Observation',
                        name: 'nursing_related_observation',
                        icon: 'maps:local_hospital',
                        model: 'nursing_related_observations',
                        ordinal: 12,
                    },
                    {
                        title: 'Nursing Summary',
                        name: 'nursing_summary',
                        icon: 'maps:local_hospital',
                        model: 'nursing_summaries',
                        ordinal: 20,
                    },
                    {
                        title: 'Pain Observation',
                        name: 'pain_observation',
                        icon: 'image:healing',
                        componentName: 'consolo-charting-pain-observation',
                        model: 'pain_observations',
                        hasBodySite: true,
                        allowMultiple: true,
                        ordinal: 15,
                        sectionIsEmpty: function (section, model) {
                            return false;
                        },
                    },
                    {
                        title: 'Problem Assessment',
                        name: 'reviewed_problem_list_item',
                        singleNode: false,
                        allowMultiple: true,
                        icon: 'image:healing',
                        componentName: 'consolo-charting-problem-list-item',
                        model: 'reviewed_problem_list_items',
                        chartingClients: ['encounter_charting'],
                        noPermissions: true,
                        ordinal: 25,
                        checkIfAgencyEnabled: 'problemListEnabled',
                        sectionIsEmpty: function (section, model) {
                            return (model.reviewed_problem_list_items === undefined ||
                                model.reviewed_problem_list_items.length === 0);
                        },
                    },
                    {
                        title: 'Social Assessment',
                        name: 'social_assessment',
                        icon: 'communication:chat',
                        model: 'social_assessments',
                    },
                    {
                        title: 'Spiritual Assessment',
                        name: 'spiritual_assessment',
                        icon: 'social:poll',
                        // componentName: 'consolo-charting-spiritual-assessment',
                        model: 'spiritual_assessments',
                    },
                    {
                        title: 'Supervisory Visit',
                        name: 'supervisory_visit',
                        icon: 'social:person',
                        model: 'supervisory_visits',
                        serviceLines: ['hospice'],
                    },
                    {
                        title: 'Teaching and Learning Assessment',
                        name: 'teaching_and_learning_assessment',
                        icon: 'maps:local_library',
                        model: 'teaching_and_learning_assessments',
                    },
                    {
                        title: 'Vital Sign Measurement',
                        name: 'vital_sign_measurement',
                        icon: 'action:assignment',
                        model: 'vital_sign_measurements',
                        allowMultiple: true,
                        componentName: 'consolo-charting-vital-sign-measurement',
                        ordinal: 2,
                    },
                    {
                        title: 'Visit Task List',
                        name: 'visit_task_list',
                        icon: 'action:list',
                        model: 'visit_task_lists',
                        componentName: 'consolo-charting-visit-task-list',
                        singleNode: false,
                    },
                    {
                        title: 'Visit Note',
                        name: 'visit_note',
                        icon: 'action:assignment',
                        model: 'visit_notes',
                    },
                    {
                        title: 'Wound Assessment',
                        name: 'wound_assessment',
                        icon: 'image:healing',
                        model: 'wound_assessments',
                        hasBodySite: true,
                        allowMultiple: true,
                        ordinal: 17,
                    },
                    // {
                    //   title: 'Patient Diagnoses',
                    //   name: 'patient_diagnosis',
                    //   model: 'patient_diagnosis',
                    //   icon: 'action:accessibility',
                    //   componentName: 'consolo-charting-diagnosis',
                    //   chartingClients: ['encounter_charting'],
                    //   additionalSection: true,
                    //   noPermissions: true,
                    //   sectionIsEmpty: (section, model) => {
                    //     return false;
                    //   },
                    // },
                    {
                        title: 'Chief Complaint',
                        name: 'chief_complaint',
                        model: 'chief_complaint',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'History of Present Illness (HPI)',
                        name: 'history_of_present_illness',
                        model: 'history_of_present_illness',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'Review of Systems (ROS)',
                        name: 'review_of_systems',
                        model: 'review_of_systems',
                        icon: 'action:accessibility',
                        componentName: 'consolo-charting-review-of-systems',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                        cannot_require: true,
                        sectionIsEmpty: function (section, model) {
                            return !(model[section.name] && model[section.name].display_text !== 'Not reviewed');
                        },
                    },
                    {
                        title: 'Patient History (PSH/PMH/PFH)',
                        name: 'patient_history',
                        model: 'patient_history',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                        canSync: true,
                    },
                    {
                        title: 'Constitutional',
                        name: 'body_systems_constitutional',
                        model: 'body_systems_constitutional',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'HEENT',
                        name: 'body_systems_heent',
                        model: 'body_systems_heent',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'Genitourinary',
                        name: 'body_systems_genitourinary',
                        model: 'body_systems_genitourinary',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'Hematologic / Lymphatic / Immunologic / Oncology',
                        name: 'body_systems_hli',
                        model: 'body_systems_hli',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'Endocrine',
                        name: 'body_systems_endocrine',
                        model: 'body_systems_endocrine',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                    },
                    {
                        title: 'MIPS / CQM',
                        name: 'cqms',
                        componentName: 'clinical-chart-cqms',
                        model: 'cqms',
                        chartingClients: ['encounter_charting'],
                        noPermissions: true,
                        ordinal: 26,
                        checkIfAgencyEnabled: 'cqmsEnabled',
                        sectionIsEmpty: function (section, model) {
                            return model.cqms === undefined || model.cqms.length === 0;
                        },
                    },
                    {
                        title: 'Oxygen Safety Assessment',
                        name: 'oxygen_safety_assessment',
                        model: 'oxygen_safety_assessment',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        checkIfAgencyEnabled: 'oxygenAssessmentEnabled',
                    },
                    {
                        title: 'Pediatric Fall Risk Assessment',
                        name: 'pediatric_fall_risk_assessment',
                        componentName: 'pediatric-fall-risk-assessment',
                        model: 'pediatric_fall_risk_assessment',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        //noPermissions: true,
                        singleNode: true,
                        checkIfAgencyEnabled: 'pediatricAssessmentEnabled',
                    },
                    {
                        title: 'Document Upload',
                        name: 'document_upload',
                        model: 'document_upload',
                        chartingClients: ['encounter_charting'],
                        componentName: 'consolo-charting-document-upload',
                        additionalSection: true,
                        noPermissions: true,
                        singleNode: true
                    },
                    // {
                    //   title: 'Assessment / Plan',
                    //   name: 'assessment_plan',
                    //   model: 'assessment_plan',
                    //   componentName: 'consolo-charting-assessment-plan',
                    //   chartingClients: ['encounter_charting'],
                    //   additionalSection: true,
                    //   noPermissions: true,
                    // },
                    // {
                    //   title: 'Visit Summary',
                    //   name: 'visit_summary',
                    //   model: 'visit_summary',
                    //   chartingClients: ['encounter_charting'],
                    // },
                    // {
                    //   title: 'Coding',
                    //   name: 'cpt_coding',
                    //   model: 'cpt_coding',
                    //   componentName: 'consolo-charting-cpt-coding',
                    //   chartingClients: ['encounter_charting'],
                    //   additionalSection: true,
                    //   noPermissions: true,
                    // },
                    {
                        title: 'Veterans Assessment',
                        name: 'veterans_assessment',
                        icon: 'content:content_paste',
                        model: 'veterans_assessment',
                        componentName: 'consolo-charting-veterans-assessment',
                        chartingClients: ['encounter_charting'],
                        additionalSection: true,
                        noPermissions: true,
                        canSync: true,
                    },
                ];
                this.chartingFields = {
                    bereavement_assessments: [
                        {
                            label: 'Survivor',
                            // serviceLines: null, //can be an array like with sections and fields
                            fields: [
                                { field: 'family_id', label: 'Name', type: 'select', query: 'family_members' },
                                { field: 'assessment_type', label: 'Assessment Type', type: 'select', optionsType: 'assessment_type_options' },
                                { field: 'bereavement_services_declined', label: 'Bereavement Services Declined', type: 'checkbox' },
                                { field: 'no_bereavement_symptoms_identified', label: 'Bereavment Assessed, no Bereavement Symptoms Identified-NA', type: 'checkbox' },
                            ],
                        },
                        {
                            label: 'Other Family',
                            fields: [
                                { field: 'other_family', label: 'Other Significant Family Members', type: 'text' },
                            ],
                        },
                        {
                            label: 'Influences',
                            fields: [
                                {
                                    field: 'depend_members',
                                    label: 'Dependent members in the house (children under 18, elderly, or handicapped)',
                                    type: 'booltext',
                                },
                                { field: 'losses', label: 'Other significant or multiple losses', type: 'booltext' },
                                {
                                    field: 'unexpected',
                                    label: 'Unexpected traumatic circumstances surrounding terminal condition',
                                    type: 'booltext',
                                },
                                {
                                    field: 'emotional_affects_behavioral_manifestations',
                                    label: 'Emotional Affects/Behavioral Manifestations',
                                    type: 'booltext',
                                },
                                {
                                    field: 'depression',
                                    label: 'Severe symptoms of depression, withdrawal, anger, anxiety or guilt',
                                    type: 'booltext',
                                },
                                {
                                    field: 'isolation',
                                    label: 'Social isolation/lack of support system',
                                    type: 'booltext',
                                },
                                {
                                    field: 'deny',
                                    label: 'Denying reality of illness and approaching death',
                                    type: 'booltext',
                                },
                                {
                                    field: 'complicated',
                                    label: 'Highly complicated relationship with the patient (excessive anger, guilt, resentment)',
                                    type: 'booltext',
                                },
                                {
                                    field: 'stresses',
                                    label: 'Multiple life stresses (housing problems, illness, need for employment)',
                                    type: 'booltext',
                                },
                                {
                                    field: 'mental',
                                    label: 'History of mental illness and/or substance abuse (drugs, alcohol) for family member or patient',
                                    type: 'booltext',
                                },
                                { field: 'financial', label: 'Financial status', type: 'booltext' },
                                { field: 'suicide', label: 'Reference to suicide', type: 'booltext' },
                                { field: 'other', label: 'Other', type: 'booltext' },
                                { field: 'abuse_neglect_domestic_violence', label: 'Abuse/neglect/domestic violence', type: 'booltext' },
                                { field: 'complicated_anticipatory_grief', label: 'Complicated anticipatory grief ', type: 'booltext' },
                                { field: 'caregiver_dependent_on_patient', label: 'Caregiver is/was Dependent on Patient', type: 'booltext' },
                                { field: 'caregiver_stress', label: 'Caregiver Stress', type: 'booltext' },
                                { field: 'concurrent_life_stressors', label: 'Concurrent life stressors ', type: 'booltext' },
                                { field: 'defense_mechanisms', label: 'Defense mechanisms', type: 'booltext' },
                                { field: 'ineffective_communication', label: 'Ineffective communication', type: 'booltext' },
                                { field: 'living_arrangement_change', label: 'Living arrangement change', type: 'booltext' },
                                { field: 'loss_of_long_term_relationship', label: 'Loss of long term relationship', type: 'booltext' },
                                { field: 'need_for_assurance', label: 'Need for assurance', type: 'booltext' },
                                { field: 'need_for_forgiveness', label: 'Need for forgiveness', type: 'booltext' },
                                { field: 'patient_is_child', label: 'Patient is a child', type: 'booltext' },
                                { field: 'psychiatric_history', label: 'Psychiatric history', type: 'booltext' },
                                { field: 'sexual_intimacy_issues', label: 'Sexual/intimacy issues', type: 'booltext' },
                                { field: 'death_burial_mourning_customs_desired', label: 'Death/Burial/Mourning Customs Desired ', type: 'text' },
                                {
                                    field: 'social_spiritual_cultural_factors',
                                    label: 'Social, Spiritual, and Cultural Factors',
                                    type: 'text',
                                },
                                { field: 'other_social_risk_factors_symptoms', label: 'Other social risk factors/symptoms', type: 'text' },
                            ],
                        },
                        {
                            label: 'How will the bereaved cope?',
                            fields: [
                                {
                                    field: 'cope_well',
                                    label: 'Well, normal grief and recovery without special help',
                                    type: 'booltext',
                                },
                                {
                                    field: 'cope_fair',
                                    label: 'Fair, probably will get by without special help',
                                    type: 'booltext',
                                },
                                {
                                    field: 'cope_doubtful',
                                    label: 'Doubtful, may need special help',
                                    type: 'booltext',
                                },
                                { field: 'cope_bad', label: 'Badly, requires special help', type: 'booltext' },
                                { field: 'cope_urgent', label: 'Very badly, requires urgent help', type: 'booltext' },
                                { field: 'grief_or_service_concerns', label: 'Briefly summarize any grief or service concerns', type: 'text' },
                                {
                                    field: 'comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'cope_risk_level',
                                    label: 'Risk Level of Complications to Normal Grief Process',
                                    optionsType: 'cope_risk_levels',
                                    type: 'select',
                                },
                            ],
                        },
                    ],
                    body_systems_activity_musculoskeletal_findings: [
                        {
                            label: 'General',
                            fields: [
                                {
                                    field: 'admission_overall_level_of_functioning_id',
                                    label: 'Level of Overall Function on Admission',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'note_activity_on_admission_overall_level_of_functioning',
                                    label: 'Level of Overall Function on Admission Notes',
                                },
                            ],
                        },
                        {
                            label: 'Complaints or No Report',
                            fields: [
                                { field: 'activity_no_report', label: 'Musculoskeletal - Assessed, No Musculoskeletal Symptoms Identified-NA', type: 'booltext' },
                                {
                                    field: 'activity_patient_complaint',
                                    label: 'Patient / Family / Caregiver / Staff Complaint',
                                    type: 'booltext',
                                },
                            ],
                        },
                        {
                            label: 'Activities in Daily Living',
                            fields: [
                                {
                                    field: "feeding_history",
                                    label: "Feeding History",
                                    optionsType: 'level_of_functionings',
                                    type: "select",
                                },
                                {
                                    field: 'activity_feeding_level_of_functioning_id',
                                    label: 'Feeding',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                { field: 'note_activity_feeding_level_of_functioning', label: 'Feeding Notes' },
                                {
                                    field: 'activity_dressing_level_of_functioning_id',
                                    label: 'Dressing',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'dressing_history',
                                    label: 'Dressing 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'level_of_functionings'
                                },
                                { field: 'note_activity_dressing_level_of_functioning', label: 'Dressing Notes' },
                                {
                                    field: 'activity_bathing_level_of_functioning_id',
                                    label: 'Bathing',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'bathing_history',
                                    label: 'Bathing 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'level_of_functionings'
                                },
                                { field: 'note_activity_bathing_level_of_functioning', label: 'Bathing Notes' },
                                {
                                    field: 'activity_toileting_level_of_functioning_id',
                                    label: 'Toileting',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'toileting_history',
                                    label: 'Toileting 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'level_of_functionings'
                                },
                                { field: 'note_activity_toileting_level_of_functioning', label: 'Toileting Notes' },
                                {
                                    field: 'activity_ambulation_level_of_functioning_id',
                                    label: 'Ambulation',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                { field: 'note_activity_ambulation_level_of_functioning', label: 'Ambulation Notes' },
                                {
                                    field: 'activity_transfers_level_of_functioning_id',
                                    label: 'Transfers',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'transfers_history',
                                    label: 'Transfers 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'level_of_functionings'
                                },
                                { field: 'note_activity_transfers_level_of_functioning', label: 'Transfers Notes' },
                            ],
                        },
                        {
                            label: 'Other Activities',
                            fields: [
                                {
                                    field: "laundry_history", label: "Laundry History", type: "select", optionsType: "level_of_functionings",
                                },
                                {
                                    field: "laundry_current", label: "Laundry Current", type: "select", optionsType: "level_of_functionings"
                                },
                                { field: "laundry_notes", label: "Laundry Notes", type: "text" },
                                { field: "light_housekeeping_history", label: "Light Housekeeping History", type: "select", optionsType: "level_of_functionings" },
                                { field: "light_housekeeping_current", label: "Light Housekeeping Current", type: "select", optionsType: "level_of_functionings" },
                                { field: "light_housekeeping_notes", label: "Light Housekeeping Notes", type: "text" },
                                { field: "marketing_history", label: "Marketing History", type: "select", optionsType: "level_of_functionings" },
                                { field: "marketing_current", label: "Marketing Current", type: "select", optionsType: "level_of_functionings" },
                                { field: "marketing_notes", label: "Marketing Notes", type: "text" },
                                {
                                    field: 'activity_money_handling_level_of_functioning_id',
                                    label: 'Money Handling',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'note_activity_money_handling_level_of_functioning',
                                    label: 'Money Handling Notes',
                                },
                                {
                                    field: 'activity_medications_level_of_functioning_id',
                                    label: 'Medications',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: "medications_history",
                                    label: "Medications History",
                                    type: "select",
                                    optionsType: 'level_of_functionings'
                                },
                                {
                                    field: 'note_activity_medications_level_of_functioning',
                                    label: 'Medications Notes',
                                },
                                {
                                    field: 'activity_medication_administration_level_of_functioning_id',
                                    label: 'Medication Administration',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'note_activity_medication_administration_level_of_functioning',
                                    label: 'Medication Administration Notes',
                                },
                                { field: "preparing_meals_history", label: "Preparing Meals History", type: "select", optionsType: 'level_of_functionings' },
                                { field: "preparing_meals_current", label: "Preparing Meals Current", type: "select", optionsType: 'level_of_functionings' },
                                { field: "preparing_meals_notes", label: "Preparing Meals Notes", type: "text" },
                                { field: "telephone_history", label: "Telephone History", type: "select", optionsType: 'level_of_functionings' },
                                { field: "telephone_current", label: "Telephone Current", type: "select", optionsType: 'level_of_functionings' },
                                { field: "telephone_notes", label: "Telephone Notes", type: "text" },
                            ],
                        },
                        {
                            label: 'Mobility',
                            fields: [
                                {
                                    field: 'activity_age_appropriate_mobility',
                                    label: 'Age Appropriate Mobility',
                                    type: 'booltext',
                                },
                                { field: 'has_activity_fall_risk', label: 'Fall Risk', type: 'checkbox' },
                                {
                                    field: 'has_activity_morse_fall_assessment',
                                    label: 'See Morse Fall Assessment',
                                    type: 'checkbox',
                                },
                                { field: "out_of_bed_with_help", label: "Out of Bed with help", type: "checkbox" },
                                { field: "out_of_bed_without_help", label: "Out of Bed without help", type: "checkbox" },
                                {
                                    field: 'activity_bed_mobility_assist_level_of_functioning_id',
                                    label: 'Bed Mobility Assist',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'note_activity_bed_mobility_assist_level_of_functioning',
                                    label: 'Bed Mobility Assist Notes',
                                },
                                { field: "turning_in_bed_history", label: "Turning in Bed History", type: "booltext" },
                                { field: "turning_in_bed_current", label: "Turning in Bed Current", type: "booltext" },
                                {
                                    field: 'activity_wheelchair_mobility_assist_level_of_functioning_id',
                                    label: 'Wheelchair Mobility Assist',
                                    optionsType: 'level_of_functionings',
                                    type: 'select',
                                },
                                {
                                    field: 'note_activity_wheelchair_mobility_assist_level_of_functioning',
                                    label: 'Wheelchair Mobility Assist Notes',
                                },
                                {
                                    field: 'activity_alteration_in_endurance',
                                    label: 'Alteration in Endurance',
                                    type: 'booltext',
                                },
                                {
                                    field: "poor_coordination_and_balance_history",
                                    label: "Poor Coordination and Balance History",
                                    type: "booltext"
                                },
                                {
                                    field: 'activity_poor_coordination_and_balance',
                                    label: 'Poor Coordination and Balance',
                                    type: 'booltext',
                                },
                                { field: 'activity_gait_disturbance', label: 'Gait Disturbance', type: 'booltext' },
                                {
                                    field: 'activity_bed_or_chair_bound',
                                    label: 'Bed or Chair Bound',
                                    type: 'booltext',
                                },
                                { field: "bedbound_history", label: "Bedbound History", type: "booltext" },
                                { field: "bedbound_current", label: "Bedbound Current", type: "booltext" },
                                { field: "chair_bound_history", label: "Chair bound History", type: "booltext" },
                                { field: "chair_bound_current", label: "Chair bound Current", type: "booltext" },
                                {
                                    field: 'mobility_current',
                                    label: 'Mobility Current',
                                    type: 'select',
                                    optionsType: 'level_of_functionings'
                                },
                                {
                                    field: 'mobility_history',
                                    label: 'Mobility 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'level_of_functionings'
                                },
                                {
                                    field: 'note_mobility',
                                    label: 'Mobility Notes'
                                },
                            ],
                        },
                        {
                            label: 'Developmental/Pediatric Level of Function',
                            fields: [
                                {
                                    field: 'activity_developmental_pediatric_level_of_functioning',
                                    label: 'Developmental/Pediatric Level of Function',
                                    optionsType: 'developmental_pediatric_levels',
                                    type: 'select',
                                },
                                {
                                    field: "pediatric_mobility",
                                    label: "Pediatric Mobility",
                                    type: "multiselect",
                                    optionsType: 'pediatric_mobility_options',
                                },
                                {
                                    field: "pediatric_mobility_notes",
                                    label: "Pediatric Mobility Notes",
                                    type: "text"
                                }
                            ],
                        },
                        {
                            label: 'Other',
                            fields: [
                                { field: "drooping_of_nasolabial_folds", label: "Drooping of nasolabial folds", type: "checkbox" },
                                { field: "hyperextension_of_the_neck", label: "Hyperextension of the neck", type: "checkbox" },
                                { field: 'activity_plegia', label: 'Plegia', type: 'booltext' },
                                { field: 'activity_missing_limbs', label: 'Missing Limbs', type: 'booltext' },
                                { field: "amputation_history", label: "Amputation History", type: "booltext" },
                                { field: "amputation_current", label: "Amputation Current", type: "booltext" },
                                {
                                    field: 'activity_history_of_fractures',
                                    label: 'History of Fractures',
                                    type: 'booltext',
                                },
                                { field: "contractures_history", label: "Contractures History", type: "booltext" },
                                { field: 'activity_contractures', label: 'Contractures', type: 'booltext' },
                                {
                                    field: 'activity_painful_swollen_joints',
                                    label: 'Painful / Swollen Joints',
                                    type: 'booltext',
                                },
                                {
                                    field: 'activity_limited_range_of_motion',
                                    label: 'Limited Range of Motion',
                                    type: 'booltext',
                                },
                                { field: 'activity_casts_splints', label: 'Casts / Splints', type: 'booltext' },
                                { field: 'activity_muscle_wasting', label: 'Muscle Wasting', type: 'booltext' },
                                { field: 'activity_muscle_cramping', label: 'Muscle Cramping', type: 'booltext' },
                                { field: "muscle_spasm_history", label: "Muscle Spasm History", type: "booltext" },
                                { field: "muscle_spasm_current", label: "Muscle Spasm Current", type: "booltext" },
                                {
                                    field: 'note_fractures_history',
                                    label: 'Fractures 3-6 Months Ago'
                                },
                                {
                                    field: 'note_fractures_current',
                                    label: 'Fractures Current'
                                },
                                {
                                    field: 'other_musculoskeletal_history',
                                    label: 'Other Musculoskeletal History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'other_musculoskeletal_current',
                                    label: 'Other Musculoskeletal Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'note_other_musculoskeletal',
                                    label: 'Other Musculoskeletal Notes',
                                },
                            ],
                        },
                        {
                            label: 'Patient Used Mobility Equipment and Devices',
                            fields: [
                                { field: "cane_history", label: "Cane History", type: "booltext" },
                                { field: 'activity_cane', label: 'Cane', type: 'booltext' },
                                {
                                    field: 'activity_hoyer_lift',
                                    label: 'Mechanical Lift (all brands)',
                                    type: 'booltext',
                                },
                                { field: "walker_history", label: "Walker History", type: "booltext" },
                                { field: 'activity_walker', label: 'Walker', type: 'booltext' },
                                { field: 'activity_crutches', label: 'Crutches', type: 'booltext' },
                                { field: 'activity_recliner', label: 'Recliner', type: 'booltext' },
                                { field: "wheelchair_history", label: "Wheelchair History", type: "booltext" },
                                { field: 'activity_wheelchair', label: 'Wheelchair', type: 'booltext' },
                                { field: 'activity_shower_seat', label: 'Shower Seat', type: 'booltext' },
                                { field: 'activity_bedside_commode', label: 'Bedside Commode', type: 'booltext' },
                                { field: 'activity_adjustable_bed', label: 'Adjustable Bed', type: 'booltext' },
                                { field: 'activity_toilet_seat_riser', label: 'Toilet Seat Riser', type: 'booltext' },
                                { field: 'activity_bed_rails', label: 'Bed Rails', type: 'booltext' },
                                { field: 'activity_trapeze', label: 'Trapeze', type: 'booltext' },
                                {
                                    field: 'activity_special_mattress',
                                    label: 'Specialty Mattress',
                                    type: 'booltext',
                                },
                                { field: 'activity_lift_chair', label: 'Lift Chair', type: 'booltext' },
                                {
                                    field: 'activity_other_mobility_equipment',
                                    label: 'Other Mobility Equipment',
                                    type: 'booltext',
                                },
                                {
                                    field: 'note_equipment_and_devices',
                                    label: 'Equipment and Devices Notes'
                                },
                            ],
                        },
                        {
                            label: 'Has at Location of Care',
                            fields: [
                                { field: 'has_bedside_commode_at_location_of_care', label: 'Bedside Commode', type: 'checkbox' },
                                {
                                    field: 'note_bedside_commode_at_location_of_care',
                                    label: 'Bedside Commode Notes'
                                },
                                { field: 'has_hospital_bed_at_location_of_care', label: 'Hospital Bed', type: 'checkbox' },
                                {
                                    field: 'note_hospital_bed_at_location_of_care',
                                    label: 'Hospital Bed Notes'
                                },
                                { field: 'has_other_mobility_equipment_1_at_location_of_care', label: 'Other Mobility Equipment 1', type: 'checkbox' },
                                {
                                    field: 'note_other_mobility_equipment_1_at_location_of_care',
                                    label: 'Other Mobility Equipment 1 Notes'
                                },
                                { field: 'has_other_mobility_equipment_2_at_location_of_care', label: 'Other Mobility Equipment 2', type: 'checkbox' },
                                {
                                    field: 'note_other_mobility_equipment_2_at_location_of_care',
                                    label: 'Other Mobility Equipment 2 Notes'
                                },
                                { field: 'has_walker_at_location_of_care', label: 'Walker', type: 'checkbox' },
                                {
                                    field: 'note_walker_at_location_of_care',
                                    label: 'Walker Notes'
                                },
                                { field: 'has_wheelchair_at_location_of_care', label: 'Wheelchair', type: 'checkbox' },
                                {
                                    field: 'has_at_location_of_care_notes',
                                    label: 'Has at Location of Care Notes',
                                    type: 'text'
                                },
                                {
                                    field: 'note_wheelchair_at_location_of_care',
                                    label: 'Wheelchair Notes'
                                },
                            ]
                        },
                        {
                            label: 'Needs at Location of Care',
                            fields: [
                                { field: 'needs_bedside_commode_at_location_of_care', label: 'Bedside Commode', type: 'checkbox' },
                                {
                                    field: 'note_needs_bedside_commode_at_location_of_care',
                                    label: 'Bedside Commode Notes'
                                },
                                { field: 'needs_hospital_bed_needs_at_location_of_care', label: 'Hospital Bed', type: 'checkbox' },
                                {
                                    field: 'note_needs_hospital_bed_at_location_of_care',
                                    label: 'Hospital Bed Notes'
                                },
                                { field: 'needs_other_mobility_equipment_1_at_location_of_care', label: 'Other Mobility Equipment 1', type: 'checkbox' },
                                {
                                    field: 'note_needs_other_mobility_equipment_1_at_location_of_care',
                                    label: 'Other Mobility Equipment 1 Notes'
                                },
                                { field: 'needs_other_mobility_equipment_2_at_location_of_care', label: 'Other Mobility Equipment 2', type: 'checkbox' },
                                {
                                    field: 'note_needs_other_mobility_equipment_2_at_location_of_care',
                                    label: 'Other Mobility Equipment 2 Notes'
                                },
                                { field: 'needs_walker_at_location_of_care', label: 'Walker', type: 'checkbox' },
                                {
                                    field: 'note_needs_walker_at_location_of_care',
                                    label: 'Walker Notes'
                                },
                                { field: 'needs_wheelchair_at_location_of_care', label: 'Wheelchair', type: 'checkbox' },
                                {
                                    field: 'note_needs_wheelchair_at_location_of_care',
                                    label: 'Wheelchair Notes'
                                },
                                {
                                    field: 'note_needs_at_location_of_care',
                                    label: 'Needs at Location of Care Notes'
                                },
                            ]
                        },
                        {
                            label: 'Summary',
                            fields: [
                                { field: 'activity_pt_ot_referral', label: 'PT/OT Referral', type: 'booltext' },
                                { field: 'note_activity_therapy_goals', label: 'Therapy Goals' },
                                {
                                    field: 'note_activity_dependence_on_assistance_indicated',
                                    label: 'Dependence on Assistance Indicated (Care Plan filed)',
                                },
                                {
                                    field: 'note_activity_barriers_for_providing_assistance',
                                    label: 'Barriers For Providing Assistance',
                                },
                                {
                                    field: 'note_activity_adl_care_provided_by',
                                    label: 'ADL Support/Care Provided By',
                                },
                                {
                                    field: 'note_activity_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    body_systems_cardiac_circulatory_findings: [
                        {
                            label: 'Cardiac / Circulatory',
                            fields: [
                                { field: 'has_cardiac_no_problem', label: 'Cardiovascular Assessed, No Cardiac Symptoms Identified - NA', type: 'checkbox' },
                                { field: 'cregular', label: 'Regular', type: 'booltext' },
                                { field: 'cirregular', label: 'Irregular', type: 'booltext' },
                                {
                                    field: 'chest_pain_history',
                                    label: 'Chest Pain History',
                                    type: 'booltext'
                                },
                                { field: 'cchestpain', label: 'Chest Pain', type: 'booltext' },
                                { field: 'murmur', label: 'Murmur', type: 'booltext' },
                                { field: 'other', label: 'Other', type: 'booltext' },
                                { field: 'anasarca', label: 'Anasarca', type: 'booltext' },
                                {
                                    field: 'jugular_vein_distention',
                                    label: 'Jugular Vein Distention',
                                    type: 'booltext',
                                },
                                {
                                    field: 'dyspnea_history',
                                    label: 'Dyspnea History',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_dyspnea_history;
                                        var note_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_dyspnea_history;
                                        var items = [
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['has_dyspnea_history']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['note_dyspnea_history']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'cdyspnea',
                                    label: 'Dyspnea',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_cdyspnea;
                                        var note_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_cdyspnea;
                                        var items = [
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['has_dyspnea']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['note_dyspnea']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'dyspnea_with_exertion',
                                    label: 'Dyspnea With Exertion',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_dyspnea_with_exertion;
                                        var note_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_dyspnea_with_exertion;
                                        var items = [
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['has_dyspnea_with_exertion']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['note_dyspnea_with_exertion']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'dyspnea_without_exertion',
                                    label: 'Dyspnea Without Exertion',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_dyspnea_without_exertion;
                                        var note_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_dyspnea_without_exertion;
                                        var items = [
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['has_dyspnea_without_exertion']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['note_dyspnea_without_exertion']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'functioning_dyspnea_intensity',
                                    label: 'Dyspnea Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_cardiac_circulatory_findings[0].functioning_dyspnea_intensity;
                                        var items = [
                                            {
                                                section: "['body_systems_respiratory_oxygenation_findings']",
                                                field: "[0]['dyspnea_intensity']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                { field: 'ccyanosis', label: 'Cyanosis', type: 'booltext' },
                                { field: 'cpacemaker', label: 'Pacemaker and/or Defibrillator', type: 'booltext' },
                                { field: 'cardiac_device_status', label: 'Cardiac Device Status', type: 'booltext' },
                                {
                                    field: 'edema_history',
                                    label: 'Edema History',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_edema = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_edema_history;
                                        var edema_note = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_edema_history;
                                        var items = [
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['has_edema_history']",
                                                value: has_edema
                                            },
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['note_edema_history']",
                                                value: edema_note
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'cedema',
                                    label: 'Edema / Location',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_edema = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_cedema;
                                        var edema_note = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_cedema;
                                        var items = [
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['has_skin_edema']",
                                                value: has_edema
                                            },
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['note_skin_edema']",
                                                value: edema_note
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'functioning_edema_intensity',
                                    label: 'Edema Intensity Current',
                                    type: 'rating',
                                    optionsType: 'edema_intensities',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_cardiac_circulatory_findings[0].functioning_edema_intensity;
                                        var items = [
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['edema_intensity_current']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'functioning_edema_intensity_before',
                                    label: 'Edema Intensity 3-6 Months Ago',
                                    type: 'rating',
                                    optionsType: 'edema_intensities',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_cardiac_circulatory_findings[0].functioning_edema_intensity_before;
                                        var items = [
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['edema_intensity_history']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'note_functioning_edema',
                                    label: 'Edema Notes',
                                    type: 'text',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_functioning_edema;
                                        var items = [
                                            {
                                                section: "['body_systems_skin_integrity_circulation_findings']",
                                                field: "[0]['edema_notes']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                { field: 'cpedal', label: 'Pedal Pulses', type: 'booltext' },
                                {
                                    field: 'cbleeding_current',
                                    label: 'Bleeding Current',
                                    type: 'multiselect',
                                    optionsType: 'cbleeding_options'
                                },
                                {
                                    field: 'cbleeding_before',
                                    label: 'Bleeding 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'cbleeding_options'
                                },
                                { field: 'note_cbleeding', label: 'Bleeding Notes' },
                                { field: 'has_cstage_d', label: 'Stage D', type: 'checkbox' },
                                { field: 'ejection_fraction_percentage', label: 'Ejection Fraction Percentage' },
                                {
                                    field: 'cheart_failure_type',
                                    label: 'Heart Failure Type',
                                    type: 'select',
                                    optionsType: 'cheart_failure_options',
                                },
                                { field: 'has_cheart_failure', label: 'Heart Failure?', type: 'checkbox' },
                                { field: 'cstroke_current', label: 'Stroke Current' },
                                { field: 'cstroke_before', label: 'Stroke 3-6 Months Ago' },
                                { field: 'cstroke_notes', label: 'Stroke Notes' },
                                {
                                    field: 'note_ccomments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'other_cardiovascular_history',
                                    label: 'Other Cardiovascular History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'other_cardiovascular_current',
                                    label: 'Other Cardiovascular Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'cardiac_devices_identified',
                                    label: 'Devices Assessed, No Cardiac Devices Identified',
                                    type: 'checkbox'
                                },
                                {
                                    field: 'pacemaker_tech_support',
                                    label: 'Pacemaker Tech Support name/phone number',
                                    type: 'text'
                                },
                                {
                                    field: 'pacemaker_competent',
                                    label: 'Pacemaker Competent in Use',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'pacemaker_plan_deactivate',
                                    label: 'Pacemaker Plan to Deactivate or Discontinue',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'patient_understanding_device',
                                    label: 'Pacemaker Patient/Caregiver Understanding Purpose of Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'patient_understanding_discontinue_device',
                                    label: 'Pacemaker Understanding Consequences of Discontinuing the Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'pacer_tech_support',
                                    label: 'Pacer/Defibrillator Tech Support Name/Phone Number',
                                    type: 'text'
                                },
                                {
                                    field: 'pacer_competent',
                                    label: 'Pacer/Defibrillator Competent in Use',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'pacer_plan_deactivate',
                                    label: 'Pacer/Defibrillator Plan to Deactivate or Discontinue',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'pacer_understanding_device',
                                    label: 'Pacer/Defibrillator Patient/Caregiver Understanding Purpose of Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'pacer_understanding_discontinue_device',
                                    label: 'Pacer/Defibrillator Understanding Consequences of Discontinuing the Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'implanted_defibrillator_tech_support',
                                    label: 'Implanted Defibrillator Tech Support Name/Phone Number',
                                    type: 'text'
                                },
                                {
                                    field: 'implanted_defibrillator_competent',
                                    label: 'Implanted Defibrillator Competent in Use',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'implanted_defibrillator_plan_deactivate',
                                    label: 'Implanted Defibrillator Plan to Deactivate or Discontinue',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'implanted_defibrillator_understanding_device',
                                    label: 'Implanted Defibrillator Patient/Caregiver Understanding Purpose of Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'implanted_defibrillator_understanding_discontinue_device',
                                    label: 'Implanted Defibrillator Understanding Consequences of Discontinuing the Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'left_ventricular_assist_device',
                                    label: 'Left Ventricular Assist Device',
                                    type: 'checkbox'
                                },
                                {
                                    field: 'left_ventricular_assist_device_support',
                                    label: 'Left Ventricular Assist Device Tech Support Name/Phone number',
                                    type: 'text',
                                },
                                {
                                    field: 'left_ventricular_assist_device_competent',
                                    label: 'Left Ventricular Assist Device Competent in Use',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'left_ventricular_plan_deactivate',
                                    label: 'Left Ventricular Assist Device Plan to Deactivate or Discontinue',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'left_ventricular_understanding_device',
                                    label: 'Left Ventricular Assist Device Patient/Caregiver Understanding Purpose of Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'left_ventricular_understanding_discontinue_device',
                                    label: 'Left Ventricular Assist Device Understanding Consequences of Discontinuing the Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'bipap_tech_support',
                                    label: 'BiPap Tech Support Name/Phone Number',
                                    type: 'text',
                                },
                                {
                                    field: 'bipap_competent',
                                    label: 'BiPap Competent in Use',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'bipap_plan_deactivate',
                                    label: 'BiPap Plan to Deactivate or Discontinue',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'bipap_understanding_device',
                                    label: 'BiPap Patient/Caregiver Understanding Purpose of Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'bipap_understanding_discontinue_device',
                                    label: 'BiPap Understanding Consequences of Discontinuing the Device',
                                    type: 'select',
                                    optionsType: 'understanding_device',
                                },
                                {
                                    field: 'diastolic_heart_failure_history',
                                    label: 'Diastolic Heart Failure History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'diastolic_heart_failure_current',
                                    label: 'Diastolic Heart Failure Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'systolic_heart_failure_history',
                                    label: 'Systolic Heart Failure History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'systolic_heart_failure_current',
                                    label: 'Systolic Heart Failure Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'syncope_history',
                                    label: 'Syncope History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'syncope_current',
                                    label: 'Syncope Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'inotropic_drug_therapy',
                                    label: 'Inotropic Drug Therapy Assessed, No Inotropic Drug Therapy Identified',
                                    type: 'checkbox'
                                },
                                {
                                    field: 'name_inotrope',
                                    label: 'Name of Inotrope',
                                    type: 'text'
                                },
                                {
                                    field: 'inotrope_dose',
                                    label: 'Inotrope Dose in mcg/kg/min',
                                    type: 'text'
                                },
                                {
                                    field: 'patient_weight',
                                    label: 'Patient Weight kg/lbs',
                                    type: 'text'
                                },
                                {
                                    field: 'peripheral_pulses',
                                    label: 'Peripheral Pulses',
                                    type: 'multiselect',
                                    optionsType: 'peripheral_pulses'
                                },
                                {
                                    field: 'absent_peripheral_pulses_history',
                                    label: 'Absent Peripheral Pulses History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'absent_peripheral_pulses_current',
                                    label: 'Absent Peripheral Pulses Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'cardiovascular_s_s_imminence',
                                    label: 'Absent Radial Pulse',
                                    type: 'checkbox'
                                },
                                {
                                    field: 'cardiovascular_treatments',
                                    label: 'Cardiovascular Labs/Tests/Treatments',
                                    type: 'text'
                                }
                            ],
                        },
                    ],
                    body_systems_communication_findings: [
                        {
                            label: 'Communication',
                            fields: [
                                { field: 'has_communication_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'orientation_person', label: 'Oriented to Person', type: 'booltext' },
                                { field: 'orientation_place', label: 'Oriented to Place', type: 'booltext' },
                                { field: 'orientation_time', label: 'Oriented to Time', type: 'booltext' },
                                { field: 'orientation_situation', label: 'Oriented to Situations', type: 'booltext' },
                                { field: 'communication_disoriented', label: 'Disoriented', type: 'booltext' },
                                { field: 'comm_vision_impaired', label: 'Vision Impaired', type: 'booltext' },
                                { field: 'communication_glasses', label: 'Glasses', type: 'booltext' },
                                { field: 'comm_speech_impaired', label: 'Speech Impaired', type: 'booltext' },
                                { field: 'communication_hoh', label: 'Hard of Hearing', type: 'booltext' },
                                { field: 'communication_hearing_aid', label: 'Hearing Aide(s)', type: 'booltext' },
                                { field: 'communication_deaf', label: 'Deaf', type: 'booltext' },
                                { field: 'communication_sign_language', label: 'Sign Language', type: 'booltext' },
                                {
                                    field: 'communication_interpretor_needed',
                                    label: 'Interpreter/Translator Needed',
                                    type: 'booltext',
                                },
                                {
                                    field: 'communication_nearing_death_awareness',
                                    label: 'Nearing Death Awareness',
                                    type: 'booltext',
                                },
                                {
                                    field: 'patient_reponds_to_communication',
                                    label: 'Patient Responds To',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'communication_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'communication_ability_current',
                                    label: 'Ability to Communicate Current',
                                    type: 'multiselect',
                                    optionsType: 'communication_ability',
                                },
                                {
                                    field: 'communication_ability_before',
                                    label: 'Ability to Communicate 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'communication_ability',
                                },
                                { field: 'note_communication_ability', label: 'Ability to Communicate Notes' },
                            ],
                        },
                    ],
                    body_systems_elimination_findings: [
                        {
                            label: 'Elimination',
                            fields: [
                                { field: 'has_elimination_no_problem', label: 'Gastrointestinal Assessed, no Gastrointestinal Symptoms Identified-NA', type: 'checkbox' },
                                { field: 'incontinence_history', label: 'Incontinence History', type: 'booltext' },
                                { field: 'has_elimination_incontinent', label: 'Incontinent', type: 'checkbox' },
                                {
                                    field: 'note_elimination_incontinent',
                                    label: 'Incontinence Type',
                                    type: 'select',
                                    optionsType: 'incontinence',
                                },
                                { field: 'bladder', label: 'Bladder', type: 'booltext' },
                                { field: 'elimination_burning', label: 'Burning', type: 'booltext' },
                                { field: 'hematuria_history', label: 'Hematuria History', type: 'booltext' },
                                { field: 'elimination_hematuria', label: 'Hematuria', type: 'booltext' },
                                { field: 'retention_history', label: 'Retention History', type: 'booltext' },
                                { field: 'elimination_retention', label: 'Retention', type: 'booltext' },
                                { field: 'elimination_hx_of_uti', label: 'Hx of UTI', type: 'booltext' },
                                {
                                    field: 'elimination_usual_urinary_pattern',
                                    label: 'Usual Urinary Pattern',
                                    type: 'booltext',
                                },
                                {
                                    field: 'elimination_present_urinary_pattern',
                                    label: 'Present Urinary Pattern',
                                    type: 'booltext',
                                },
                                { field: 'has_potty_trained', label: 'Potty Trained', type: 'checkbox' },
                                { field: 'urgency_current', label: 'Urgency Current', type: 'booltext' },
                                { field: 'urgency_history', label: 'Urgency History', type: 'booltext' },
                                { field: 'polyuria_history', label: 'Polyuria History', type: 'booltext' },
                                { field: 'polyuria_current', label: 'Polyuria Current', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Catheter',
                            fields: [
                                { field: 'has_elimination_catheter', label: 'Catheter', type: 'checkbox' },
                                {
                                    field: 'note_elimination_catheter',
                                    label: 'Catheter Type',
                                    type: 'select',
                                    value_field: 'code',
                                    optionsType: 'elimination_catheters',
                                },
                                {
                                    field: 'catheter_change_date',
                                    label: 'Catheter Last Changed',
                                    type: 'date',
                                    maxDate: this.today,
                                },
                            ],
                        },
                        {
                            label: 'Bowel',
                            fields: [
                                { field: 'constipation_history', label: 'Constipation History', type: 'booltext' },
                                { field: 'elimination_constipation', label: 'Constipation', type: 'booltext' },
                                {
                                    field: 'elimination_constipation_intensity',
                                    label: 'Constipation Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                { field: 'diarrhea_history', label: 'Diarrhea History', type: 'booltext' },
                                { field: 'elimination_diarrhea', label: 'Diarrhea', type: 'booltext' },
                                {
                                    field: 'elimination_diarrhea_intensity',
                                    label: 'Diarrhea Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                { field: 'elimination_blood_stool', label: 'Blood in Stool', type: 'booltext' },
                                {
                                    field: 'elimination_blood_stool_intensity',
                                    label: 'Blood in Stool Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                { field: 'bowel', label: 'Abdomen/Bowel Notes', type: 'booltext' },
                                { field: 'hemorrhoids', label: 'Hemorrhoids', type: 'booltext' },
                                { field: 'fecal_impaction', label: 'Fecal Impaction', type: 'booltext' },
                                { field: 'fecal_impaction_history', label: 'Fecal Impaction History', type: 'booltext' },
                                { field: 'lbm', label: 'Last Bowel Movement', type: 'booltext' },
                                { field: 'fecal_incontinence_history', label: 'Fecal Incontinence History', type: 'booltext' },
                                { field: 'elimination_location_size', label: 'Last Bowel Movement Color / Size', type: 'text' },
                                { field: 'date_lbm', label: 'Last Bowel Movement Date', type: 'date' },
                                { field: 'present_bowel_pattern', label: 'Present Bowel Pattern', type: 'booltext' },
                                { field: 'usual_bowel_pattern', label: 'Usual Bowel Pattern', type: 'booltext' },
                                { field: 'elimination_colostomy', label: 'Colostomy', type: 'booltext' },
                                { field: 'ileostomy', label: 'Ileostomy', type: 'booltext' },
                                { field: 'jejunostomy', label: 'Jejunostomy', type: 'booltext' },
                                { field: 'gastric_bypass', label: 'Gastric Bypass', type: 'booltext' },
                                { field: 'gastric_sleeve', label: 'Gastric Sleeve', type: 'booltext' },
                                { field: 'ostomy_history', label: 'Ostomy History', type: 'booltext' },
                                { field: 'umbilical_cord', label: 'Umbilical Cord', type: 'multiselect', optionsType: 'umbilical_cord_options', },
                                { field: 'tube_feeding_history', label: 'Tube Feeding History', type: 'booltext' },
                                { field: 'tube_feeding_current', label: 'Tube Feeding Current', type: 'booltext' },
                                { field: 'abdominal_distention_history', label: 'Abdominal Distention History', type: 'booltext' },
                                { field: 'abdominal_distention_current', label: 'Abdominal Distention Current', type: 'booltext' },
                                { field: 'abdominal_pain_history', label: 'Abdominal Pain History', type: 'booltext' },
                                { field: 'abdominal_pain_current', label: 'Abdominal Pain Current', type: 'booltext' },
                                { field: 'bowel_sounds', label: 'Bowel Sounds', type: 'multiselect', optionsType: 'bowel_sounds_options' },
                                { field: 'elimination_bowel_sounds', label: 'Bowel Sounds Notes', type: 'text' },
                                {
                                    field: 'abdominal_symptoms',
                                    label: 'Abdominal Symptoms',
                                    type: 'multiselect',
                                    optionsType: 'abdominal_symptoms_options',
                                },
                                {
                                    field: 'elimination_abdominal_assessment',
                                    label: 'Abdominal Assessment',
                                    type: 'text',
                                },
                                { field: 'aspiration_history', label: 'Aspiration History', type: 'booltext' },
                                { field: 'aspiration_current', label: 'Aspiration Current', type: 'booltext' },
                                { field: 'reflux_history', label: 'Reflux History', type: 'booltext' },
                                { field: 'reflux_current', label: 'Reflux Current', type: 'booltext' },
                                { field: 'nausea_history', label: 'Nausea History', type: 'booltext' },
                                { field: 'nausea_current', label: 'Nausea Current', type: 'booltext' },
                                { field: 'vomiting_history', label: 'Vomiting History', type: 'booltext' },
                                { field: 'vomiting_current', label: 'Vomiting Current', type: 'booltext' },
                                { field: 'ascites_history', label: 'Ascites History', type: 'booltext' },
                                {
                                    field: 'was_an_opioid_initiated_or_continued',
                                    label: 'Was An Opioid Initiated Or Continued',
                                    type: 'select',
                                    optionsType: 'opioid_initiated',
                                    hospice_item_set: true,
                                    changeFn: function (formModel, chartingFormField) {
                                        if (formModel.was_an_opioid_initiated_or_continued === 'Yes, Scheduled') {
                                            formModel.date_opioid_prn_initiated_or_continued = null;
                                        }
                                        else if (formModel.was_an_opioid_initiated_or_continued === 'Yes, PRN') {
                                            formModel.date_opioid_scheduled_initiated_or_continued = null;
                                        }
                                        else if (formModel.was_an_opioid_initiated_or_continued === 'No') {
                                            formModel.date_opioid_scheduled_initiated_or_continued = null;
                                            formModel.date_opioid_prn_initiated_or_continued = null;
                                        }
                                    },
                                },
                                {
                                    field: 'date_opioid_scheduled_initiated_or_continued',
                                    label: 'Date Opioid Scheduled Initiated or Continued',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_an_opioid_initiated_or_continued === 'Yes, Scheduled' ||
                                            formModel.was_an_opioid_initiated_or_continued === 'Yes, Scheduled & PRN');
                                    },
                                    hideFn: function (formModel) {
                                        return (formModel.was_an_opioid_initiated_or_continued === 'No' ||
                                            formModel.was_an_opioid_initiated_or_continued === 'Yes, PRN');
                                    },
                                },
                                {
                                    field: 'date_opioid_prn_initiated_or_continued',
                                    label: 'Date Opioid Prn Initiated or Continued',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_an_opioid_initiated_or_continued === 'Yes, PRN' ||
                                            formModel.was_an_opioid_initiated_or_continued === 'Yes, Scheduled & PRN');
                                    },
                                    hideFn: function (formModel) {
                                        return (formModel.was_an_opioid_initiated_or_continued === 'No' ||
                                            formModel.was_an_opioid_initiated_or_continued === 'Yes, Scheduled');
                                    },
                                },
                                {
                                    field: 'was_bowel_regimen_initiated_or_continued',
                                    label: 'Was Bowel Regimen Initiated Or Continued',
                                    type: 'select',
                                    optionsType: 'bowel_regimen_initiated',
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_an_opioid_initiated_or_continued &&
                                            formModel.was_an_opioid_initiated_or_continued !== 'No');
                                    },
                                },
                                {
                                    field: 'date_bowel_regimen_initiated_or_continued',
                                    label: 'Date Bowel Regimen Initiated or Continued',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return formModel.was_bowel_regimen_initiated_or_continued === 'Yes';
                                    },
                                    hideFn: function (formModel) {
                                        return (formModel.was_bowel_regimen_initiated_or_continued === 'No' ||
                                            formModel.was_bowel_regimen_initiated_or_continued ===
                                                'No, But there is documentation of why');
                                    },
                                },
                            ],
                        },
                        {
                            label: 'Additional Findings & Comments',
                            fields: [
                                { field: 'elimination_aids', label: 'Elimination Aids', type: 'booltext' },
                                {
                                    field: 'has_elimination_adult_briefs_pullups',
                                    label: 'Adult Briefs / Pull-Ups',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'elimination_number_of_briefs_pullups',
                                    label: 'Number of Adult Briefs / Pull-Ups / 24 Hours',
                                    type: 'booltext',
                                },
                                {
                                    field: 'elimination_number_of_diapers',
                                    label: 'Number of Diapers / 24 Hours',
                                    type: 'booltext',
                                },
                                {
                                    field: 'other_gastrointestinal_current',
                                    label: 'Other Gastrointestinal Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'other_gastrointestinal_history',
                                    label: 'Other Gastrointestinal History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'gastrointestinal_labs_tests_treatments_chemo_radiation',
                                    label: 'Gastrointestinal Labs/Tests/Treatments/Chemo/Radiation',
                                    type: 'text',
                                },
                                {
                                    field: 'alcohol_consumption_history',
                                    label: 'Alcohol Consumption History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'alcohol_consumption_current',
                                    label: 'Alcohol Consumption Current',
                                    type: 'booltext'
                                },
                                { field: 'elimination_urinary_frequency', label: 'Urinary Frequency', type: 'text' },
                                {
                                    field: 'elimination_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    body_systems_nutrition_hydration_findings: [
                        {
                            label: 'Nutrition / Hydration',
                            fields: [
                                { field: 'has_nutrition_no_problem', label: 'Assessed, No Nutrition/Hydration Problems Identified', type: 'checkbox' },
                                { field: 'symptoms_associated_with_pain', label: 'Are Any of the Following Symptoms Associated with Pain?', type: 'multiselect', optionsType: 'symptoms_associated_with_pain_options' },
                                { field: 'nutrition_abd_pain', label: 'Abdominal Pain', type: 'booltext' },
                                { field: 'albumin_history', label: 'Albumin 3-6 Months Ago' },
                                { field: 'albumin_current', label: 'Albumin Current' },
                                { field: 'albumin_notes', label: 'Albumin Notes', type: 'text' },
                                { field: 'nutrition_nausea', label: 'Nausea', type: 'booltext' },
                                {
                                    field: 'nutrition_nausea_intensity',
                                    label: 'Nausea Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                { field: 'nutrition_vomiting', label: 'Vomiting', type: 'booltext' },
                                {
                                    field: 'nutrition_vomiting_intensity',
                                    label: 'Vomiting Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                { field: 'nutrition_dysphagia', label: 'Dysphagia', type: 'booltext' },
                                {
                                    field: 'nutrition_dysphagia_intensity',
                                    label: 'Dysphagia Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                { field: 'nutrition_distention', label: 'Distention', type: 'booltext' },
                                { field: 'nutrition_ascites', label: 'Ascites', type: 'booltext' },
                                { field: 'nutrition_dentures', label: 'Dentures', type: 'booltext' },
                                { field: 'nutrition_chewing', label: 'Difficulty Chewing', type: 'booltext' },
                                { field: 'has_nutrition_carcinoma', label: 'Carcinoma of the Head, Neck, Thyroid, Esophagus, or Upper Stomach', type: 'checkbox' },
                                { field: 'has_nutrition_radiation_therapy', label: 'Radiation Therapy in the Past Month', type: 'checkbox' },
                                {
                                    field: 'nutrition_medications_or_chemotherapy',
                                    label: 'Is the Patient Taking any Medications or has had Chemotherapy in the Past Month Which May Contribute To:',
                                    type: 'multiselect',
                                    optionsType: 'nutrition_medications_or_chemotherapy_options'
                                },
                                { field: 'nutrition_stomatitis', label: 'Stomatitis', type: 'booltext' },
                                { field: 'nutrition_anorexia_history', label: 'Anorexia History', type: 'booltext' },
                                { field: 'nutrition_anorexia', label: 'Anorexia Current', type: 'booltext' },
                                { field: 'nutrition_cachexia', label: 'Cachexia', type: 'booltext' },
                                { field: 'nutrition_esophageal_bleed', label: 'Esophageal Bleed', type: 'booltext' },
                                { field: 'nutrition_dyspepsia', label: 'Dyspepsia', type: 'booltext' },
                                { field: 'nutrition_hiccoughs', label: 'Hiccoughs', type: 'booltext' },
                                {
                                    field: 'eating_history',
                                    label: 'Eating 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'eating_options',
                                },
                                {
                                    field: 'eating_current',
                                    label: 'Eating Current',
                                    type: 'select',
                                    optionsType: 'eating_options',
                                },
                                {
                                    field: 'eating_notes',
                                    label: 'Eating Notes',
                                    type: 'text',
                                },
                                {
                                    field: 'indications_of_weight_loss_history',
                                    label: 'Indications of Weight loss 3-6 Months',
                                    type: 'multiselect',
                                    optionsType: 'indications_of_weight_loss_options',
                                },
                                {
                                    field: 'indications_of_weight_loss',
                                    label: 'Indications of Weight Loss',
                                    type: 'multiselect',
                                    optionsType: 'indications_of_weight_loss_options',
                                },
                                { field: 'other_indications_of_weight_loss', label: 'Other Indications of Weight Loss' },
                            ],
                        },
                        {
                            label: 'Diet',
                            fields: [
                                { field: 'nutrition_eating_pleasurable', label: 'Is Eating Pleasurable?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'nutrition_eating_more_pleasurable', label: 'Could Eating be More Pleasurable?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'nutrition_eating_what_more_pleasurable', label: 'What Would Make it More Pleasurable?' },
                                { field: 'nutrition_eating_time', label: 'Eating Times' },
                                { field: 'nutrition_eating_where', label: 'Where Do You Eat?' },
                                { field: 'nutrition_eating_whom', label: 'With Whom Do You Eat?' },
                                { field: 'nutrition_eating_how_much', label: 'How Much Do You Eat?' },
                                { field: 'nutrition_allergies_reactions', label: 'Food Allergies and Reactions Notes (Informational Only)', type: 'booltext' },
                                { field: 'nutrition_dislike', label: 'Foods you Dislike' },
                                { field: 'nutrition_cannot_tolerate', label: 'Foods You Cannot Tolerate', type: 'multiselect', optionsType: 'nutrition_cannot_tolerate_options' },
                                { field: 'nutrition_cannot_tolerate_describe', label: 'Foods You Cannot Tolerate Describe', type: 'text' },
                                { field: 'nutrition_cultural_or_religious', label: 'Cultural or Religious Food Preferences', type: 'text' },
                                {
                                    field: 'nutrition_diet',
                                    label: 'Diet',
                                    optionsType: 'nutrition_diets',
                                    value_field: 'code',
                                    type: 'select',
                                },
                                { field: 'note_nutrition_diet', label: 'Diet Notes' },
                                {
                                    field: 'nutrition_appetite',
                                    label: 'Appetite / Amount Consumed',
                                    optionsType: 'nutrition_appetites',
                                    value_field: 'code',
                                    type: 'select',
                                },
                                { field: 'percent_of_meals_consumed_history', label: 'Percent of Meals Consumed 3-6 Months' },
                                { field: 'percent_of_meals_consumed', label: 'Percent of Meals Consumed Current' },
                                { field: 'food_consistency', label: 'Food Consistency', type: 'select', optionsType: 'food_consistency_options', value_field: 'code' },
                                { field: 'percent_of_meals_consumed_notes', label: 'Percent of Meals Consumed Notes', type: 'text' },
                                { field: 'percent_of_breakfast_consumed', label: 'Breakfast Percent Consumed' },
                                { field: 'percent_of_lunch_consumed', label: 'Lunch Percent Consumed' },
                                { field: 'percent_of_dinner_consumed', label: 'Dinner Percent Consumed' },
                                { field: 'percent_of_snacks_supplements_consumed', label: 'Snacks/Supplements Percent Consumed' },
                                { field: 'note_nutrition_appetite_current',
                                    label: 'Appetite Current',
                                    type: 'select',
                                    optionsType: 'nutrition_appetites_options'
                                },
                                { field: 'note_nutrition_appetite', label: 'Appetite Notes' },
                                { field: 'note_nutrition_appetite_history',
                                    label: 'Appetite 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'nutrition_appetites_options'
                                },
                                { field: 'note_nutrition_appetite_notes_history', label: 'Appetite 3-6 Months Ago Notes' },
                                { field: 'nutrition_liquid', label: 'Liquid Intake/24 Hours', type: 'booltext' },
                                { field: 'amount_of_time_to_feed_history', label: 'Amount of Time to Feed 3-6 Months Ago' },
                                { field: 'amount_of_time_to_feed', label: 'Amount of Time to Feed Current' },
                                { field: 'amount_of_time_to_feed_notes', label: 'Amount of Time to Feed Notes' },
                                {
                                    field: 'inactivity_decreased_activity_contributing',
                                    label: 'Is Inactivity or Decreased Activity Contributing to:',
                                    type: 'multiselect',
                                    optionsType: 'inactivity_decreased_activity_contributing_options'
                                },
                                { field: 'nutrition_weight_delta', label: 'Weight Loss/Gain', type: 'text' },
                                { field: 'nutritional_score', label: 'Nutritional Score', type: 'text' },
                                {
                                    field: 'nutrition_infant_oral_feeding_method',
                                    label: 'Infant Oral Feeding Method',
                                    type: 'booltext',
                                },
                                { field: 'nutrition_requirements', label: 'Requirements & Comments', type: 'text' },
                                { field: 'patient_how_well_is_patient_eating', label: 'Patient: How Well Do You Think You Are Eating?', type: 'select', optionsType: 'understanding_device' },
                                { field: 'patient_is_the_amount_you_eat_acceptable', label: 'Patient: Is the Amount You Eat Acceptable to You?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'patient_information_on_community_services', label: 'Patient: Would You Like Information on Community Services?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'patient_assistance_in_conveying_nutritional_concerns', label: 'Patient: Would You Like Assistance in Conveying Nutritional Concerns?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'patient_nutritional_concerns_describe', label: 'Patient: Conveying Nutritional Concerns Describe', type: 'text' },
                                { field: 'caregiver_how_well_is_patient_eating', label: 'Caregiver: How Well Do You Think the Patient is Eating?', type: 'select', optionsType: 'understanding_device' },
                                { field: 'caregiver_is_the_amount_you_eat_acceptable', label: 'Caregiver: Is the Amount the Patient is Eating Acceptable to You?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'caregiver_information_on_community_services', label: 'Caregiver: Would You Like Information on Community Services?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'caregiver_assistance_in_conveying_nutritional_concerns', label: 'Caregiver: Would You Like Assistance in Conveying Nutritional Concerns?', type: 'select', optionsType: 'yes_no_blank' },
                                { field: 'caregiver_nutritional_concerns_describe', label: 'Caregiver: Conveying Nutritional Concerns Describe', type: 'text' },
                            ],
                        },
                        {
                            label: 'Additional Findings & Comments',
                            fields: [
                                { field: 'has_nutrition_tube', label: 'Feeding Tube', type: 'checkbox' },
                                {
                                    field: 'note_nutrition_tube',
                                    label: 'Feeding Tube Current',
                                    optionsType: 'feeding_tubes',
                                    value_field: 'code',
                                    type: 'multiselect',
                                },
                                {
                                    field: 'note_nutrition_tube_history',
                                    label: 'Feeding Tube 3-6 Months Ago',
                                    optionsType: 'feeding_tubes',
                                    value_field: 'code',
                                    type: 'multiselect',
                                },
                                {
                                    field: 'note_nutrition_tube_discussion',
                                    label: 'Feeding Tube Discussion',
                                    type: 'text',
                                },
                                {
                                    field: 'nutrition_feeding_tube_patency',
                                    label: 'Feeding Tube Patency',
                                    type: 'select',
                                    optionsType: 'feeding_tube_patencies',
                                },
                                { field: 'type_of_feeding', label: 'Type of Feeding', type: 'text' },
                                { field: 'amount_of_feeding', label: 'Amount of Feeding', type: 'text' },
                                {
                                    field: 'feeding_delivery',
                                    label: 'Feeding Delivery',
                                    optionsType: 'feeding_deliveries',
                                    value_field: 'code',
                                    type: 'select',
                                },
                                {
                                    field: 'has_feeding_provided_by_sn',
                                    label: 'Feeding Provided by Skilled Nurse',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_feeding_provided_by_caregiver',
                                    label: 'Feeding Provided by Caregiver',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_feeding_provided_by_facility',
                                    label: 'Feeding Provided by Facility',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_feeding_provided_by_other',
                                    label: 'Feeding Provided by Other',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'nutrition_feeding_related_problems',
                                    label: 'Feeding Related Problems',
                                    type: 'checklist',
                                    optionsType: 'feeding_related_problems',
                                },
                                {
                                    field: 'nutrition_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    body_systems_neurology_findings: [
                        {
                            label: '',
                            fields: [
                                {
                                    field: 'has_neuro_no_problem',
                                    label: 'Neurosensory Assessed, No Neurology Symptoms Identified - N/A',
                                    type: 'checkbox'
                                }
                            ],
                        },
                        {
                            label: 'Level of Consciousness / Presentation',
                            fields: [
                                {
                                    field: 'neuro_level_of_consciousness',
                                    label: '',
                                    type: 'checklist',
                                    optionsType: 'neuro_level_of_consciousness',
                                },
                            ],
                        },
                        {
                            label: 'Neurology',
                            fields: [
                                {
                                    field: 'has_neuro_pupils_reactive',
                                    label: 'Pupils Equal / Reactive',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_neuro_pupils_dialated',
                                    label: 'Pupils Fixed / Dilated',
                                    type: 'checkbox',
                                },
                                { field: 'neuro_grasps_equal', label: 'Grasps Equal', type: 'booltext' },
                                { field: 'neuro_grasps_unequal', label: 'Grasps Unequal', type: 'booltext' },
                                {
                                    field: "seizures_history",
                                    label: "Seizures/Tremors History",
                                    type: "booltext"
                                },
                                { field: 'neuro_seizure', label: 'Seizure', type: 'booltext' },
                                { field: 'neuro_tremors', label: 'Tremors', type: 'booltext' },
                                { field: 'neuro_quad', label: 'Para / Quadriplegia', type: 'booltext' },
                                { field: 'neuro_fatigue', label: 'Fatigue', type: 'booltext' },
                                { field: 'neuro_coordination', label: 'Coordination', type: 'booltext' },
                                { field: 'neuro_balance', label: 'Balance', type: 'booltext' },
                                { field: 'neuro_sensory_loss', label: 'Sensory Loss', type: 'booltext' },
                                {
                                    field: "sensory_dysfunction_history",
                                    label: "Sensory Dysfunction History",
                                    type: "booltext"
                                },
                                {
                                    field: "sensory_dysfunction_current",
                                    label: "Sensory Dysfunction Current",
                                    type: "booltext"
                                },
                                { field: 'neuro_headache', label: 'Headache', type: 'booltext' },
                                {
                                    field: "dizziness_history",
                                    label: "Dizziness/Vertigo History",
                                    type: "booltext"
                                },
                                { field: 'neuro_dizzyness', label: 'Dizziness / Vertigo Current', type: 'booltext' },
                                { field: 'neuro_neuropathy', label: 'Neuropathy', type: 'booltext' },
                                { field: 'neuro_numbness', label: 'Numbness', type: 'booltext' },
                                {
                                    field: "aphasia_history",
                                    label: "Aphasia History",
                                    type: "booltext"
                                },
                                { field: 'neuro_aphasia', label: 'Aphasia Current', type: 'booltext' },
                                { field: 'neuro_muscle_tone', label: 'Muscle Tone', type: 'booltext' },
                                {
                                    field: "paresis_history",
                                    label: "Paresis History",
                                    type: "booltext"
                                },
                                {
                                    field: "paresis_current",
                                    label: "Paresis Current",
                                    type: "booltext"
                                },
                                {
                                    field: "delirium_history",
                                    label: "Delirium History",
                                    type: "booltext"
                                },
                                {
                                    field: "delirium_current",
                                    label: "Delirium Current",
                                    type: "booltext"
                                },
                                {
                                    field: "delusions_history",
                                    label: "Delusions History",
                                    type: "booltext"
                                },
                                {
                                    field: "delusions_current",
                                    label: "Delusions Current",
                                    type: "booltext"
                                },
                                {
                                    field: "paranoia_history",
                                    label: "Paranoia History",
                                    type: "booltext"
                                },
                                {
                                    field: "paranoia_current",
                                    label: "Paranoia Current",
                                    type: "booltext"
                                },
                                {
                                    field: "hallucinations_history",
                                    label: "Hallucinations History",
                                    type: "booltext"
                                },
                                {
                                    field: "hallucinations_current",
                                    label: "Hallucinations Current",
                                    type: "booltext"
                                },
                                {
                                    field: 'has_neuro_speech_unintelligible',
                                    label: 'Speech Unintelligible',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_neuro_inappropriate_verbal_response',
                                    label: 'Inappropriate Response To Verbal Stimuli',
                                    type: 'checkbox',
                                },
                                { field: 'neuro_other', label: 'Other', type: 'booltext' },
                                {
                                    field: "decreased_response_to_verbal_stimuli",
                                    label: "Decreased Response to Verbal Stimuli",
                                    type: "checkbox"
                                },
                                {
                                    field: "decreased_response_to_visual_stimuli",
                                    label: "Decreased Response to Visual Stimuli",
                                    type: "checkbox"
                                },
                                {
                                    field: "inability_to_close_eyelids",
                                    label: "Inability to Close Eyelids",
                                    type: "checkbox"
                                },
                                {
                                    field: "nonreactive_pupils",
                                    label: "Nonreactive Pupils",
                                    type: "checkbox"
                                },
                                {
                                    field: "grunting_of_vocal_cords",
                                    label: "Grunting of Vocal Cords",
                                    type: "checkbox"
                                },
                                {
                                    field: "terminal_restlessness_history",
                                    label: "Terminal Restlessness History",
                                    type: "booltext"
                                },
                                {
                                    field: "terminal_restlessness_current",
                                    label: "Terminal Restlessness Current",
                                    type: "booltext"
                                },
                                {
                                    field: "non_responsive_history",
                                    label: "Non Responsive History",
                                    type: "booltext"
                                },
                                {
                                    field: "non_responsive_current",
                                    label: "Non Responsive Current",
                                    type: "booltext"
                                },
                                {
                                    field: 'note_neuro_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                { field: 'has_neuro_dementia',
                                    label: 'Patient has Advanced Dementia?',
                                    type: 'checkbox',
                                },
                                { field: 'note_neuro_dementia', label: 'Patient has Advanced Dementia Notes' },
                                {
                                    field: "dementia_related_agitation_history",
                                    label: "Dementia Related Agitation History",
                                    type: "booltext"
                                },
                                {
                                    field: "dementia_related_agitation_current",
                                    label: "Dementia Related Agitation Current",
                                    type: "booltext"
                                },
                            ],
                        },
                        {
                            label: 'Pediatric Neurology/Development',
                            fields: [
                                {
                                    field: 'neuro_pediatric_physical',
                                    label: 'Physical',
                                    type: 'select',
                                    optionsType: 'pediatric_neuro_development',
                                },
                                { field: 'neuro_pediatric_physical_notes', label: 'Physical Notes' },
                                {
                                    field: 'neuro_pediatric_motor',
                                    label: 'Motor',
                                    type: 'select',
                                    optionsType: 'pediatric_neuro_development',
                                },
                                { field: 'neuro_pediatric_motor_notes', label: 'Motor Notes' },
                                {
                                    field: 'neuro_pediatric_mental',
                                    label: 'Mental',
                                    type: 'select',
                                    optionsType: 'pediatric_neuro_development',
                                },
                                { field: 'neuro_pediatric_mental_notes', label: 'Mental Notes' },
                                {
                                    field: 'neuro_pediatric_emotional',
                                    label: 'Emotional',
                                    type: 'select',
                                    optionsType: 'pediatric_neuro_development',
                                },
                                { field: 'neuro_pediatric_emotional_notes', label: 'Emotional Notes' },
                                {
                                    field: "cry",
                                    label: "Cry",
                                    type: "select",
                                    optionsType: "neurology_cry"
                                },
                                {
                                    field: "pediatric_neurology_notes",
                                    label: "Pediatric Neurology / Development Notes",
                                    type: "text"
                                }
                            ],
                        },
                        {
                            label: 'Infant Reflexes',
                            fields: [
                                { field: 'neuro_pediatric_infant_blink', label: 'Blink', type: 'booltext' },
                                { field: 'neuro_pediatric_infant_root', label: 'Root', type: 'booltext' },
                                { field: 'neuro_pediatric_infant_suck', label: 'Suck Notes', type: 'booltext' },
                                {
                                    field: 'neuro_pediatric_infant_moro_startle',
                                    label: 'Moro/Startle',
                                    type: 'booltext',
                                },
                                {
                                    field: 'neuro_pediatric_infant_palmar_grasp',
                                    label: 'Palmar Grasp',
                                    type: 'booltext',
                                },
                                {
                                    field: "suck",
                                    label: "Suck",
                                    type: "select",
                                    optionsType: "neurology_suck"
                                },
                                {
                                    field: 'neuro_pediatric_infant_reflex_notes',
                                    label: 'Infant Reflex Notes',
                                    type: 'text',
                                },
                                {
                                    field: "neurology_treatments",
                                    label: "Neurology Labs/Tests/Treatments",
                                    type: "text"
                                },
                            ],
                        },
                    ],
                    body_systems_respiratory_oxygenation_findings: [
                        {
                            label: 'Shortness of Breath',
                            fields: [
                                {
                                    field: 'shortness_of_breath',
                                    label: 'Shortness of Breath',
                                    optionsType: 'yes_no',
                                    type: 'select',
                                    hospice_item_set: true,
                                    changeFn: function (formModel, chartingFormField) {
                                        if (formModel.shortness_of_breath !== 'Yes') {
                                            formModel.was_treatment_for_shortness_of_breath_initiated = null;
                                            formModel.date_treatment_for_shortness_of_breath_initiated = null;
                                        }
                                    },
                                },
                                {
                                    field: 'shortness_of_breath_severity',
                                    label: 'Shortness of Breath Severity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return formModel.shortness_of_breath === 'Yes';
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.was_treatment_for_shortness_of_breath_initiated !== 'Yes';
                                    },
                                },
                                {
                                    field: 'was_treatment_for_shortness_of_breath_initiated',
                                    label: 'Was Treatment for Shortness of Breath Initiated',
                                    optionsType: 'yes_no_patient_declined',
                                    type: 'select',
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return formModel.shortness_of_breath === 'Yes';
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.shortness_of_breath !== 'Yes';
                                    },
                                },
                                {
                                    field: 'date_treatment_for_shortness_of_breath_initiated',
                                    label: 'Date Treatment for Shortness of Breath Initiated',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return formModel.was_treatment_for_shortness_of_breath_initiated === 'Yes';
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.was_treatment_for_shortness_of_breath_initiated !== 'Yes';
                                    },
                                },
                                {
                                    field: 'respiratory_treatment_type_ids',
                                    label: 'Type of Treatment Initiated',
                                    optionsType: 'respiratory_treatment_types',
                                    type: 'checklist',
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return formModel.was_treatment_for_shortness_of_breath_initiated === 'Yes';
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.shortness_of_breath !== 'Yes';
                                    },
                                },
                                {
                                    field: 'treatment_comments',
                                    label: 'Treatment Comments',
                                    type: 'text',
                                    hideFn: function (formModel) {
                                        return formModel.was_treatment_for_shortness_of_breath_initiated !== 'Yes';
                                    },
                                },
                                { field: 'shortness_of_breath_history',
                                    label: 'Shortness of Breath 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'shortness_of_breath_options'
                                },
                                { field: 'shortness_of_breath_current',
                                    label: 'Shortness of Breath Current',
                                    type: 'multiselect',
                                    optionsType: 'shortness_of_breath_options'
                                },
                                { field: 'shortness_of_breath_notes', label: 'Shortness of Breath Notes', type: 'text' },
                            ],
                        },
                        {
                            label: 'Respiratory Findings',
                            fields: [
                                { field: 'has_oxygenation_no_problem', label: 'Respiratory Assessed, No Respiratory Symptoms Identified - NA', type: 'checkbox' },
                                { field: 'trach', label: 'Trach', type: 'booltext' },
                                {
                                    field: 'dyspnea_history',
                                    label: 'Dyspnea History',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].has_dyspnea_history;
                                        var note_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].note_dyspnea_history;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['has_dyspnea_history']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_dyspnea_history']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'dyspnea',
                                    label: 'Dyspnea',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].has_dyspnea;
                                        var note_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].note_dyspnea;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['has_cdyspnea']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_cdyspnea']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'dyspnea_with_exertion',
                                    label: 'Dyspnea With Exertion',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].has_dyspnea_with_exertion;
                                        var note_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].note_dyspnea_with_exertion;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['has_dyspnea_with_exertion']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_dyspnea_with_exertion']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'dyspnea_without_exertion',
                                    label: 'Dyspnea Without Exertion',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].has_dyspnea_without_exertion;
                                        var note_value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].note_dyspnea_without_exertion;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['has_dyspnea_without_exertion']",
                                                value: has_value
                                            },
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_dyspnea_without_exertion']",
                                                value: note_value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: 'dyspnea_intensity',
                                    label: 'Dyspnea Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_respiratory_oxygenation_findings[0].dyspnea_intensity;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['functioning_dyspnea_intensity']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                { field: 'oxygenation_character', label: 'Character', type: 'booltext' },
                                { field: 'has_diaphoretic', label: 'Diaphoretic', type: 'checkbox' },
                                {
                                    field: 'orthopnea_history',
                                    label: 'Orthopnea History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'orthopnea_current',
                                    label: 'Orthopnea Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'tachypnea',
                                    label: 'Tachypnea',
                                    type: 'booltext',
                                },
                                { field: 'oxygenation_color', label: 'Skin Color', type: 'booltext' },
                                { field: 'cyanosis', label: 'Cyanosis', type: 'booltext' },
                                { field: 'diminished', label: 'Diminished', type: 'booltext' },
                                { field: 'crackles', label: 'Crackles', type: 'booltext' },
                                { field: 'rales', label: 'Rales', type: 'booltext' },
                                { field: 'rhonchi', label: 'Rhonchi', type: 'booltext' },
                                {
                                    field: 'congestion_history',
                                    label: 'Congestion History',
                                    type: 'booltext',
                                },
                                { field: 'lung_congestion', label: 'Congestion', type: 'booltext' },
                                {
                                    field: 'wheezing_history',
                                    label: 'Wheezing History',
                                    type: 'booltext',
                                },
                                { field: 'lung_wheezing', label: 'Wheezing', type: 'booltext' },
                                {
                                    field: 'apnea_history',
                                    label: 'Apnea History',
                                    type: 'booltext',
                                },
                                { field: 'apnea', label: 'Apnea (sec/min)', type: 'booltext' },
                                {
                                    field: 'oxygenation_abdominal_respirations',
                                    label: 'Abdominal Respirations',
                                    type: 'booltext',
                                },
                                {
                                    field: 'oxygenation_accessory_muscles',
                                    label: 'Use of Accessory Muscles',
                                    type: 'booltext',
                                },
                                {
                                    field: 'oxygenation_diaphragmatic_breathing',
                                    label: 'Diaphragmatic Breathing',
                                    type: 'booltext',
                                },
                                { field: 'oxygenation_nasal_flaring', label: 'Nasal Flaring', type: 'booltext' },
                                { field: 'oxygenation_nasal_grunting', label: 'Nasal Grunting', type: 'booltext' },
                                {
                                    field: 'oxygenation_barrel_chested',
                                    label: 'Barrel Chested / Pulmonary Hyperinflation',
                                    type: 'booltext',
                                },
                                { field: 'oxygenation_stridor', label: 'Stridor', type: 'booltext' },
                                {
                                    field: 'respiratory_infections_history',
                                    label: 'Respiratory Infections History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'oxygenation_respiratory_infections',
                                    label: 'Recurrent Respiratory Infections',
                                    type: 'booltext',
                                },
                                {
                                    field: 'oxygenation_diminished_endurance',
                                    label: 'Diminished Endurance / Fatigue',
                                    type: 'booltext',
                                },
                                { field: 'oxygenation_retractions', label: 'Retractions', type: 'booltext' },
                                {
                                    field: 'has_cheyne_stokes_breathing',
                                    label: 'Cheyne-Stokes Breathing',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_death_rattle',
                                    label: 'Terminal Secretions',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_jaw_depression_with_inspiration',
                                    label: 'Depression of the Jaw with Inspiration',
                                    type: 'checkbox',
                                },
                                { field: 'has_forced_expiratory_volume', label: 'Forced Expiratory Volume (FEV) <= 30% Predicted', type: 'checkbox' },
                                {
                                    field: 'other_respiratory_history',
                                    label: 'Other Respiratory History',
                                    type: 'booltext',
                                },
                                { field: 'other_respiratory_notes', label: 'Other Respiratory Notes', type: 'text' },
                            ],
                        },
                        {
                            label: 'Location of Congestion',
                            fields: [
                                { field: 'lung_cong_rul', label: 'Right Upper Lobe', type: 'booltext' },
                                { field: 'lung_cong_rml', label: 'Right Middle Lobe', type: 'booltext' },
                                { field: 'lung_cong_rll', label: 'Right Lower Lobe', type: 'booltext' },
                                { field: 'lung_cong_lul', label: 'Left Upper Lobe', type: 'booltext' },
                                { field: 'lung_cong_lll', label: 'Left Lower Lobe', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Cough',
                            fields: [
                                { field: 'cough_history', label: 'Cough History', type: 'booltext' },
                                { field: 'oxygenation_cough', label: 'Cough', type: 'booltext' },
                                { field: 'coughnonproductive', label: 'Non-Productive', type: 'booltext' },
                                { field: 'coughproductive', label: 'Productive', type: 'booltext' },
                                { field: 'coughcolor', label: 'Productive Cough Color' },
                                { field: 'coughamount', label: 'Productive Cough Amount' },
                                { field: 'coughfrequency', label: 'Productive Cough Frequency' },
                                { field: 'oxygenation_sputum', label: 'Sputum', type: 'booltext' },
                                { field: 'hemoptysis_current', label: 'Hemoptysis Current', type: 'booltext' },
                                { field: 'hemoptysis_history', label: 'Hemoptysis History', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Other',
                            fields: [
                                { field: 'hob_up', label: 'HOB - Up', type: 'booltext' },
                                { field: 'has_inhalers', label: 'Inhalers', type: 'checkbox' },
                                { field: 'has_nebulizers', label: 'Nebulizers', type: 'checkbox' },
                                { field: 'pursed_lip_breathing', label: 'Pursed Lip Breathing', type: 'booltext' },
                                {
                                    field: 'oxygenation_steroid_dependent',
                                    label: 'Steroid Dependent',
                                    type: 'booltext',
                                },
                                { field: 'oxygenation_hx_of_mi', label: 'History of Myocardial Infarction', type: 'booltext' },
                                { field: 'oxygenation_hx_of_stroke', label: 'History of Stroke', type: 'booltext' },
                                { field: 'tobacco_use_history', label: 'Tobacco Use History', type: 'booltext' },
                                {
                                    field: 'smoking',
                                    label: 'Smoking (packs/day)',
                                    type: 'booltext',
                                },
                                {
                                    field: 'smoking_status',
                                    type: 'static',
                                    label: 'Current Smoking Status',
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.current_smoking_status;
                                    },
                                },
                                { field: 'oxygenation_other', label: 'Other', type: 'booltext' },
                                {
                                    field: 'respiratory_devices_history',
                                    label: 'Respiratory Devices History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'oxygenation_respiratory_equipment',
                                    label: 'Respiratory Equipment In Use',
                                    type: 'checklist',
                                    optionsType: 'oxygenation_respiratory_equipment',
                                },
                                {
                                    field: 'oxygenation_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'respiratory_tests_treatments',
                                    label: 'Respiratory Labs/Tests/Treatments',
                                    type: 'text',
                                }
                            ],
                        },
                    ],
                    body_systems_self_esteem_mental_status_findings: [
                        {
                            label: 'General',
                            fields: [
                                { field: 'has_coping_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'coping_oriented', label: 'Oriented', type: 'booltext' },
                                { field: 'coping_forgetful', label: 'Forgetful', type: 'booltext' },
                                { field: 'coping_disoriented', label: 'Disoriented', type: 'booltext' },
                                { field: 'coping_comatose', label: 'Comatose', type: 'booltext' },
                                { field: 'coping_agitated', label: 'Agitated', type: 'booltext' },
                                { field: 'coping_depressed', label: 'Depressed', type: 'booltext' },
                                { field: 'coping_lethargic', label: 'Lethargic', type: 'booltext' },
                                { field: 'coping_anger', label: 'Anger', type: 'booltext' },
                                { field: 'coping_fear', label: 'Fear', type: 'booltext' },
                                { field: 'coping_sadness', label: 'Sadness', type: 'booltext' },
                                { field: 'coping_anxiety', label: 'Anxiety', type: 'booltext' },
                                {
                                    field: 'coping_difficulty_contentrating',
                                    label: 'Difficulty Concentrating',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coping_body_image_alteration',
                                    label: 'Body Image Alteration',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coping_difficulty_sleeping',
                                    label: 'Difficulty Sleeping',
                                    type: 'booltext',
                                },
                                { field: 'coping_behavior_changes', label: 'Behavior Changes', type: 'booltext' },
                                {
                                    field: 'altered_consciousness',
                                    label: 'Altered Consciousness',
                                    optionsType: 'level_of_consciousnesses',
                                    type: 'select',
                                    value_field: 'code',
                                },
                                {
                                    field: 'agitation_restlessness_history',
                                    label: 'Agitation/Restlessness 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'agitation_restlessness_options',
                                },
                                {
                                    field: 'agitation_restlessness_current',
                                    label: 'Agitation/Restlessness Current',
                                    type: 'multiselect',
                                    optionsType: 'agitation_restlessness_options',
                                },
                                {
                                    field: 'agitation_restlessness_notes',
                                    label: 'Agitation/Restlessness Notes',
                                },
                                {
                                    field: 'alertness_attention_history',
                                    label: 'Alertness/Attention 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'alertness_attention_options',
                                },
                                {
                                    field: 'alertness_attention_current',
                                    label: 'Alertness/Attention Current',
                                    type: 'multiselect',
                                    optionsType: 'alertness_attention_options',
                                },
                                {
                                    field: 'alertness_attention_notes',
                                    label: 'Alertness/Attention Notes',
                                },
                                {
                                    field: 'hours_asleep_history',
                                    label: 'Hours Asleep in 24 3-6 Months Ago',
                                    type: 'select',
                                    optionsType: 'hours_asleep_options',
                                },
                                {
                                    field: 'hours_asleep_current',
                                    label: 'Hours Asleep in 24 Current',
                                    type: 'select',
                                    optionsType: 'hours_asleep_options',
                                },
                                {
                                    field: 'hours_asleep_notes',
                                    label: 'Hours Asleep in 24 Notes',
                                    type: 'text',
                                },
                                {
                                    field: 'interactivity_history',
                                    label: 'Interactivity 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'interactivity_options',
                                },
                                {
                                    field: 'interactivity_current',
                                    label: 'Interactivity Current',
                                    type: 'multiselect',
                                    optionsType: 'interactivity_options',
                                },
                                {
                                    field: 'interactivity_notes',
                                    label: 'Interactivity Notes',
                                },
                                {
                                    field: 'personality_change',
                                    label: 'Personality Change',
                                },
                            ],
                        },
                        {
                            label: 'Comments and Intensities',
                            fields: [
                                {
                                    field: 'coping_agitated_intensity',
                                    label: 'Agitated Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                {
                                    field: 'coping_depressed_intensity',
                                    label: 'Depressed Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                {
                                    field: 'coping_anger_intensity',
                                    label: 'Anger Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                {
                                    field: 'coping_fear_intensity',
                                    label: 'Fear Intensity',
                                    type: 'rating',
                                    optionsType: 'intensities',
                                },
                                {
                                    field: 'coping_previous_illness',
                                    label: 'Previous Experience with Cancer, Illness or Loss',
                                    type: 'text',
                                },
                                {
                                    field: 'coping_usual_method',
                                    label: 'Usual Method of Handling Problems',
                                    type: 'text',
                                },
                                {
                                    field: 'coping_specific_problems',
                                    label: 'Identify Specific Problems / Needs Unique To This Patient / Family',
                                    type: 'text',
                                },
                                {
                                    field: 'coping_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    body_systems_skin_integrity_circulation_findings: [
                        {
                            label: 'General',
                            fields: [
                                { field: 'has_skin_no_problem', label: 'Assessed, No Skin Symptoms Identified - N/A', type: 'checkbox' },
                                {
                                    field: "cold_extremeties",
                                    label: "Cold Extremities",
                                    type: "checkbox"
                                },
                                {
                                    field: "diaphoresis",
                                    label: "Diaphoresis",
                                    type: "checkbox"
                                },
                                {
                                    field: "fever",
                                    label: "Fever",
                                    type: "checkbox"
                                },
                                {
                                    field: "impaired_sensation",
                                    label: "Impaired Sensation",
                                    type: "checkbox"
                                },
                                {
                                    field: "pruritis_itching",
                                    label: "Pruritus/Itching",
                                    type: "checkbox"
                                },
                                { field: 'skin_color', label: 'Color', type: 'booltext' },
                                { field: 'skin_turgor', label: 'Skin Turgor', type: 'booltext' },
                                { field: 'skin_wound', label: 'Wound / Drain', type: 'booltext' },
                                { field: 'skin_breakdown', label: 'Skin Breakdown', type: 'text' },
                                { field: 'skin_edema',
                                    label: 'Edema',
                                    type: 'booltext',
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_edema = chartStore.model.body_systems_skin_integrity_circulation_findings[0].has_skin_edema;
                                        var edema_note = chartStore.model.body_systems_skin_integrity_circulation_findings[0].note_skin_edema;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['has_cedema']",
                                                value: has_edema
                                            },
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_cedema']",
                                                value: edema_note
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: "edema_history",
                                    label: "Edema History",
                                    type: "booltext",
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var has_edema = chartStore.model.body_systems_skin_integrity_circulation_findings[0].has_edema_history;
                                        var edema_note = chartStore.model.body_systems_skin_integrity_circulation_findings[0].note_edema_history;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['has_edema_history']",
                                                value: has_edema
                                            },
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_edema_history']",
                                                value: edema_note
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: "edema_intensity_current",
                                    label: "Edema Intensity Current",
                                    type: "rating",
                                    optionsType: "edema_intensities",
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_skin_integrity_circulation_findings[0].edema_intensity_current;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['functioning_edema_intensity']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: "edema_intensity_history",
                                    label: "Edema Intensity 3-6 Months Ago",
                                    type: "rating",
                                    optionsType: "edema_intensities",
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_skin_integrity_circulation_findings[0].edema_intensity_history;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['functioning_edema_intensity_before']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                {
                                    field: "edema_notes",
                                    label: "Edema Notes",
                                    type: "text",
                                    changeFn: function (formModel, chartingFormField) {
                                        var chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore;
                                        var value = chartStore.model.body_systems_skin_integrity_circulation_findings[0].edema_notes;
                                        var items = [
                                            {
                                                section: "['body_systems_cardiac_circulatory_findings']",
                                                field: "[0]['note_functioning_edema']",
                                                value: value
                                            }
                                        ];
                                        chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items);
                                    }
                                },
                                { field: 'skin_bruises', label: 'Bruises', type: 'booltext' },
                                { field: 'skin_rash', label: 'Rash', type: 'booltext' },
                                { field: 'skin_ascites', label: 'Ascites', type: 'booltext' },
                                { field: 'skin_hair_changes', label: 'Hair Changes', type: 'booltext' },
                                { field: 'capillary_refill', label: 'Capillary Refill', type: 'booltext' },
                                { field: 'clubbing', label: 'Clubbing', type: 'booltext' },
                                { field: 'nail_beds', label: 'Nail Beds', type: 'booltext' },
                                { field: 'skin_moisture', label: 'Moisture', type: 'booltext' },
                                {
                                    field: 'has_see_wound_assessment',
                                    label: 'See Wound Assessment',
                                    type: 'checkbox',
                                },
                                { field: 'skin_lesions_history',
                                    label: 'Skin Lesions/Wounds 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'wound_options',
                                    value_field: 'code',
                                },
                                { field: 'skin_lesions_current',
                                    label: 'Skin Lesions/Wounds Current',
                                    type: 'multiselect',
                                    optionsType: 'wound_options',
                                    value_field: 'code',
                                },
                                { field: 'note_skin_lesions',
                                    label: 'Skin Lesions/Wounds Notes',
                                },
                                {
                                    field: 'skin_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    complementary_alternative_medicine_requests: [
                        {
                            label: 'General Complementary Alternative Medicine Request Details',
                            fields: [
                                {
                                    field: 'facility_id',
                                    object: 'facility',
                                    label: 'Facility',
                                    type: 'autocomplete',
                                    lookup: 'facilities',
                                },
                                {
                                    field: 'comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                { field: 'approved', label: 'Approved?', type: 'booltext' },
                                { field: 'has_acknowledged', label: 'Acknowledged?', type: 'checkbox' },
                                { field: 'therapist_assigned', label: 'Therapist Assigned', type: 'text' },
                            ],
                        },
                        {
                            label: 'Service Requested',
                            fields: [
                                { field: 'has_service_massage', label: 'Massage Therapy', type: 'checkbox' },
                                { field: 'has_service_aromatherapy', label: 'Aromatherpy', type: 'checkbox' },
                                { field: 'has_service_acupuncture', label: 'Acupuncture', type: 'checkbox' },
                                {
                                    field: 'has_service_music_thanatology',
                                    label: 'Music Thanatology',
                                    type: 'checkbox',
                                },
                                { field: 'musical_background', label: 'Musical Background', type: 'text' },
                                { field: 'musical_preferences', label: 'Musical Preferences', type: 'text' },
                                { field: 'personally_significant_music', label: 'Personally Significant Music', type: 'text' },
                                { field: 'available_music_resources', label: 'Available Music Resources', type: 'text' },
                                { field: 'activity_preferences', label: 'Activity Preferences', type: 'text' },
                                { field: 'music_therapy_assessment', label: 'Music Therapy Assessment Other Describe', type: 'text' },
                                { field: 'note_service_other', label: 'Other' },
                            ],
                        },
                        {
                            label: 'If requesting a massage, please check all Indications that apply:',
                            fields: [
                                { field: 'has_indication_arthritis', label: 'Arthritis', type: 'checkbox' },
                                { field: 'has_indication_fibrositis', label: 'Fibrositis', type: 'checkbox' },
                                { field: 'massage_therapy_history', label: 'History/Experience with Massage Therapy', type: 'text' },
                                { field: 'area_body_desired', label: 'Area of Body Desired', type: 'text' },
                                { field: 'technique_use', label: 'Techniques to Use', type: 'text' },
                                { field: 'desired_strength', label: 'Desired Strength', type: 'text' },
                                { field: 'special_requests', label: 'Special Requests', type: 'text' },
                                { field: 'has_indication_sprains', label: 'Sprains', type: 'checkbox' },
                                { field: 'has_indication_contusions', label: 'Contusions', type: 'checkbox' },
                                { field: 'has_indication_back_pains', label: 'Back Pain', type: 'checkbox' },
                                { field: 'lotion_oil_powder', label: 'Lotion, Oils, Powders', type: 'text' },
                                { field: 'has_indication_muscle_spasms', label: 'Muscle Spasm', type: 'checkbox' },
                                {
                                    field: 'has_indication_orthopedic',
                                    label: 'Orthopedic Conditions',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_indication_rheumatic',
                                    label: 'Rheumatic Conditions',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_indication_neurologic',
                                    label: 'Nerve Injuries and Neurologic Disorders',
                                    type: 'checkbox',
                                },
                                { field: 'has_indication_tension', label: 'Nervous Tension', type: 'checkbox' },
                                { field: 'has_indication_stress', label: 'Emotional Stress', type: 'checkbox' },
                                { field: 'has_indication_headaches', label: 'Headaches', type: 'checkbox' },
                                { field: 'has_indication_trigger_points', label: 'Trigger Points', type: 'checkbox' },
                                { field: 'has_indication_radiculitis', label: 'Radiculitis', type: 'checkbox' },
                                {
                                    field: 'has_indication_joint_stiffness',
                                    label: 'Joint Stiffness',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_indication_traumatic_edema',
                                    label: 'Reduce Post Traumatic Edema',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_indication_dependent_edema',
                                    label: 'Reduce Dependent Edema',
                                    type: 'checkbox',
                                },
                                { field: 'has_indication_strains', label: 'Strains', type: 'checkbox' },
                                { field: 'note_indication_other', label: 'Other' },
                                { field: 'massage_therapy_assessment', label: 'Massage Therapy Assessment Other', type: 'text' },
                            ],
                        },
                        {
                            label: 'If requesting a massage, please check all Contraindications that apply:',
                            fields: [
                                {
                                    field: 'has_contraindication_inflame',
                                    label: 'Acutely Inflamed Joints and Soft Tissue',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_malignancy',
                                    label: 'Suspicion of Local Malignancy',
                                    type: 'checkbox',
                                },
                                { field: 'has_contraindication_phlebitis', label: 'Phlebitis', type: 'checkbox' },
                                {
                                    field: 'has_contraindication_lymphangitis',
                                    label: 'Lymphangitis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_pains',
                                    label: 'Increased Pain, Swelling or Stiffness After Massage',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_burns',
                                    label: 'Acute Burns or Acute Dermatitis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_osteomyelitis',
                                    label: 'Suspicion of Osteomyelitis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_tuberculosis',
                                    label: 'Suspicion of Tuberculosis Lesions of Bone',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_diseases',
                                    label: 'Infectious Diseases',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_arteriosclerosis',
                                    label: 'Advanced Arteriosclerosis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_contraindication_hematoma',
                                    label: 'Suspicion of Hematoma',
                                    type: 'checkbox',
                                },
                                { field: 'has_contraindication_vertigo', label: 'Vertigo', type: 'checkbox' },
                                {
                                    field: 'has_contraindication_abdominal_disease',
                                    label: 'Presence of Intra Abdominal Organic Disease',
                                    type: 'checkbox',
                                },
                                { field: 'note_contraindication_other', label: 'Other' },
                            ],
                        },
                    ],
                    coordination_of_cares: [
                        {
                            label: 'Coordination of Care',
                            fields: [
                                { field: 'preferences_changed', label: 'Have Any of Your Preferences Changed?', type: 'select', optionsType: 'preferences_changed' },
                                {
                                    field: 'are_you_satisfied_current_pain',
                                    label: 'Are You Satisfied with Your Current Pain and Symptom Management',
                                    type: 'select',
                                    optionsType: 'yes_no_blank'
                                },
                                { field: 'area_we_need_to_focus_on', label: 'If No, Which Areas Do We Need to Focus On?' },
                                { field: 'confirmed_schedule', label: 'Confirmed Schedule for Next Visit', type: 'checkbox' },
                                {
                                    field: 'contact_agency_reviewed_with',
                                    label: 'How to Contact Agency Reviewed With',
                                    type: 'multiselect',
                                    optionsType: 'contact_agency_reviewed'
                                },
                                {
                                    field: 'other_contact_agency_reviewed_with_describe',
                                    label: 'How to Contact Agency Reviewed with Other ',
                                    type: 'text',
                                },
                                { field: 'coc_rn', label: 'RN/CM', type: 'booltext' },
                                { field: 'coc_lpnlvn', label: 'LPN/LVN', type: 'booltext' },
                                { field: 'coc_sw', label: 'SW', type: 'booltext' },
                                {
                                    field: 'social_work_evaluation',
                                    label: 'Social Work Evaluation',
                                    type: 'select',
                                    optionsType: 'evaluation'
                                },
                                { field: 'coc_champlain', label: 'Chaplain', type: 'booltext' },
                                {
                                    field: 'chaplain_evaluation',
                                    label: 'Chaplain Evaluation',
                                    type: 'select',
                                    optionsType: 'evaluation'
                                },
                                { field: 'coc_hha', label: 'Hospice Aide', type: 'booltext' },
                                { field: 'coc_volunteer', label: 'Volunteer', type: 'booltext' },
                                { field: 'coc_medical_director', label: 'Medical Director', type: 'booltext' },
                                { field: 'coc_rph_consult', label: 'RPh Consult', type: 'booltext' },
                                { field: 'coc_patient', label: 'Patient', type: 'booltext' },
                                { field: 'coc_family', label: 'Family', type: 'booltext' },
                                { field: 'coc_caregiver', label: 'Caregiver', type: 'booltext' },
                                {
                                    field: 'coc_facility_represenative',
                                    label: 'Facility Representative',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coc_physical_therapy',
                                    label: 'Physical Therapy',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coc_respiratory_therapy',
                                    label: 'Respiratory Therapy',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coc_speech_therapy',
                                    label: 'Speech Therapy',
                                    type: 'booltext',
                                },
                                { field: 'coc_other', label: 'Other', type: 'booltext' },
                                { field: 'reviewed_cc_gip_staff_notes', label: 'Reviewed CC/GIP Staff Notes and/or Received Report', type: 'checkbox' },
                                {
                                    field: 'report_received_from',
                                    label: 'Report Received From',
                                    type: 'multiselect',
                                    optionsType: 'report_received_from'
                                }
                            ],
                        },
                        {
                            label: 'Discussion',
                            fields: [
                                {
                                    field: 'note_coc_discussion',
                                    label: 'Discussion',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'follow_up_bereavement',
                                    label: 'Follow-Up Needed from the Bereavement Team for a Grief Concern?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank'
                                },
                                {
                                    field: 'volunteer_services',
                                    label: 'Volunteer Services',
                                    type: 'multiselect',
                                    optionsType: 'aide_services'
                                },
                                {
                                    field: 'aide_services',
                                    label: 'Aide Services',
                                    type: 'multiselect',
                                    optionsType: 'aide_services'
                                },
                                {
                                    field: 'other_services',
                                    label: 'Other Services',
                                    type: 'multiselect',
                                    optionsType: 'aide_services'
                                },
                                {
                                    field: 'other_services_describe',
                                    label: 'Other Services Offered/Requested Describe',
                                },
                                {
                                    field: 'community_resource_needs_history',
                                    label: 'Community Resource Needs History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'community_resource_needs_current',
                                    label: 'Community Resource Needs Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coc_med_record_updated',
                                    label: 'Medication Record Updated',
                                    type: 'booltext',
                                },
                                {
                                    field: 'has_coc_med_record_reviewed',
                                    label: 'Medication Record Reviewed',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'coc_lab_imaging_studies_reviewed',
                                    label: 'Labs & Imaging Studies Reviewed',
                                    type: 'booltext',
                                },
                                {
                                    field: 'coc_record_reviewed',
                                    label: 'Records Reviewed',
                                    type: 'booltext',
                                },
                                {
                                    field: 'people_involved',
                                    label: 'The Following Were Involved In and Verbalized Understanding and Agreement with the Plan of Care',
                                    type: 'multiselect',
                                    optionsType: 'people_involved'
                                },
                                {
                                    field: 'other_involved_describe',
                                    label: 'Others Were Involved In and Verbalized Understanding and Agreement with the Plan of Care, Describe',
                                    type: 'text'
                                },
                                { field: 'coc_poc_updated', label: 'POC Updated', type: 'booltext' },
                                { field: 'coc_referrals_made', label: 'Referrals Made', type: 'booltext' },
                                {
                                    field: 'supplies_left',
                                    label: 'Supplies Left During Visit',
                                    type: 'booltext',
                                },
                                {
                                    field: 'expected_to_extend',
                                    label: 'If Continuous Care Expected to Extend Beyond 5 Days, IDG Discussed Temporary Alternative Placement',
                                    type: 'select',
                                    optionsType: 'yes_no_blank'
                                },
                                {
                                    field: 'ipu_discharge_plan',
                                    label: 'IPU Discharge Plan',
                                    type: 'select',
                                    optionsType: 'ipu_discharge_plan'
                                },
                                {
                                    field: 'hospice_training_reviewed',
                                    label: 'Prior to Starting Continuous Care, CC Hospice Training Reviewed with Family/Caregiver Including Possibility of Temporary Alternate Placement If CC Greater than 5 Days',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'discharge_planning_history',
                                    label: 'Discharge Planning/Placement History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'discharge_planning_current',
                                    label: 'Discharge Planning/Placement Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'type_of_discharge',
                                    label: 'Type of Discharge',
                                    type: 'select',
                                    optionsType: 'types_of_discharge'
                                },
                                {
                                    field: 'has_coc_vol_service_offered_weekly',
                                    label: 'Volunteer Services Offered Weekly',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_from_facility_record',
                                    label: 'Diagnosis Information Obtained from Facility Record',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_from_office',
                                    label: 'Diagnosis Information Obtained from Physician / Provider Office',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_from_patient_caregiver',
                                    label: 'Diagnosis Information Obtained from Patient / Caregiver',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_discharge_order',
                                    label: 'See Attached Discharge Orders',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_diagnoses_list',
                                    label: 'See Attached Current Diagnoses List',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_facility_order',
                                    label: 'See Attached Facility Orders',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_physician_note',
                                    label: 'See Attached Last Physician Progress Note',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_history_physical',
                                    label: 'See Attached History and Physical',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_labs',
                                    label: 'See Attached Labs',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_mar',
                                    label: 'See Attached MAR',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_medication_list',
                                    label: 'See Attached Current Medication List',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_attached_other',
                                    label: 'See Attached Other',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_coc_final_review',
                                    label: 'Pre Admission Final Review',
                                    type: 'checkbox',
                                },
                            ],
                        },
                        {
                            label: 'Physician And Medical Director Contact',
                            fields: [
                                { field: 'coc_physician_contacted', label: 'Physician Contacted', type: 'booltext' },
                                {
                                    field: 'coc_medical_director_contacted',
                                    label: 'Medical Director Contacted',
                                    type: 'booltext',
                                },
                                { field: 'note_coc_communication', label: 'Communication', type: 'text' },
                                { field: 'coc_orders_received', label: 'Orders Received', type: 'booltext' },
                                { field: 'coc_awaiting_response', label: 'Awaiting Response', type: 'booltext' },
                                {
                                    field: 'coc_consults_ordered',
                                    label: 'Consults Ordered',
                                    type: 'booltext',
                                },
                            ],
                        },
                    ],
                    edmonton_assessments: [
                        {
                            label: 'General',
                            fields: [
                                {
                                    field: 'pain',
                                    label: 'Pain Level',
                                },
                                {
                                    field: 'tiredness',
                                    label: 'Tiredness Level',
                                },
                                {
                                    field: 'drowsiness',
                                    label: 'Drowsiness Level',
                                },
                                {
                                    field: 'nausea',
                                    label: 'Nausea Level',
                                },
                                {
                                    field: 'lack_of_appetite',
                                    label: 'Lack of Appetite Level',
                                },
                                {
                                    field: 'shortness_of_breath',
                                    label: 'Shortness of Breath Level',
                                },
                                {
                                    field: 'depression',
                                    label: 'Depression Level',
                                },
                                {
                                    field: 'anxiety',
                                    label: 'Anxiety Level',
                                },
                                {
                                    field: 'wellbeing',
                                    label: 'Wellbeing Level',
                                },
                                {
                                    field: 'constipation',
                                    label: 'Constipation Level',
                                },
                                {
                                    field: 'completed_by',
                                    label: 'Completed By',
                                    type: 'checklist',
                                    optionsType: 'edmonton_completed_by_options',
                                },
                            ],
                        },
                    ],
                    explanation_of_services: [
                        {
                            label: 'General Explanation of Service Details',
                            fields: [
                                {
                                    field: 'people_present',
                                    label: 'Present at Meeting (list all and roles)',
                                    type: 'text',
                                },
                                {
                                    field: 'participating_events', label: 'Why Hospice Now? Precipitating Events', type: 'text'
                                },
                                {
                                    field: 'goal_of_care', label: 'Goals of Care', type: 'text'
                                },
                                {
                                    field: 'hospitalizations_current', label: 'Hospitalizations Current', type: 'text'
                                },
                                {
                                    field: 'hospitalizations_history', label: 'Hospitalizations 3-6 Months Ago', type: 'text'
                                },
                                {
                                    field: 'patient_has_medicare_part_d', label: 'Patient has Medicare Part D', type: 'checkbox'
                                },
                                { field: 'services', label: 'Hospice Services', type: 'text' },
                                { field: 'phone_24_hour', label: '24 Hour Number', type: 'text' },
                                { field: 'benefits', label: 'Medicare/Medicaid Benefits', type: 'text' },
                                { field: 'directive', label: 'Advance Directives ', type: 'text' },
                                { field: 'info_pack', label: 'Patient Info Pack', type: 'text' },
                                { field: 'team_role', label: 'Role of Team', type: 'text' },
                                { field: 'responsibilities', label: 'Rights/Responsibilities', type: 'text' },
                                { field: 'privacy', label: 'HIPAA/Privacy', type: 'text' },
                                { field: 'phone_customer_relations', label: 'Agency Customer Relations Phone', type: 'text' },
                                { field: 'phone_state_compliant', label: 'State Complaint Phone', type: 'text' },
                                {
                                    field: 'was_patient_asked_about_cpr',
                                    label: 'Was the patient/responsible party asked about preference regarding the use of cardiopulmonary resuscitation (CPR)?',
                                    optionsType: 'discussion_options',
                                    type: 'select',
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'date_patient_was_asked_about_cpr',
                                    label: 'Date Asked About CPR',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_patient_asked_about_cpr === 'Yes & Discussion Occurred' ||
                                            formModel.was_patient_asked_about_cpr ===
                                                'Yes But Patient/Responsible Party Refused to Discuss');
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.was_patient_asked_about_cpr === 'No';
                                    },
                                },
                                {
                                    field: 'was_patient_asked_about_treatments_other_than_cpr',
                                    label: 'Was the patient/responsible party asked about preferences regarding life-sustaining treatments other than CPR?',
                                    optionsType: 'discussion_options',
                                    type: 'select',
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'date_patient_was_asked_about_treatments_other_than_cpr',
                                    label: 'Date Asked About Treatment Other than CPR',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_patient_asked_about_treatments_other_than_cpr ===
                                            'Yes & Discussion Occurred' ||
                                            formModel.was_patient_asked_about_treatments_other_than_cpr ===
                                                'Yes But Patient/Responsible Party Refused to Discuss');
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.was_patient_asked_about_treatments_other_than_cpr === 'No';
                                    },
                                },
                                {
                                    field: 'was_patient_asked_about_hospitalization',
                                    label: 'Was the patient/responsible party asked about preference regarding hospitalization?',
                                    optionsType: 'discussion_options',
                                    maxDate: this.today,
                                    type: 'select',
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'date_patient_was_asked_about_hospitalization',
                                    label: 'Date Patient Was Asked About Hospitalization',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_patient_asked_about_hospitalization ===
                                            'Yes & Discussion Occurred' ||
                                            formModel.was_patient_asked_about_hospitalization ===
                                                'Yes But Patient/Responsible Party Refused to Discuss');
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.was_patient_asked_about_hospitalization === 'No';
                                    },
                                },
                                {
                                    field: 'hospital_avoidance',
                                    label: 'Requests Avoidance of Hospitalization',
                                    type: 'booltext',
                                },
                                { field: 'other', label: 'Other', type: 'text' },
                                {
                                    field: 'has_understanding',
                                    label: 'Patient/Family verbalize full understanding of Hospice Care, ' +
                                        'particularly the Palliative rather than Curative nature of treatment',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'no_understanding',
                                    label: 'Summary/Discussion',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'family_confidence',
                                    label: 'Family Confidence In Ability To Safely Care For Loved One At Home',
                                    type: 'text',
                                },
                                {
                                    field: 'special_preferences',
                                    label: 'Special Preferences For Life Closure',
                                    type: 'text',
                                },
                                {
                                    field: 'consent',
                                    label: 'Consent/Election of Benefits Signed/Witnessed',
                                    type: 'booltext',
                                },
                            ],
                        },
                    ],
                    face_to_face_visits: [
                        {
                            label: 'General Face to Face Visit Details',
                            fields: [
                                {
                                    field: 'physician_id',
                                    object: 'physician',
                                    label: 'Physician',
                                    type: 'autocomplete',
                                    lookup: 'non_physician_assistant_physicians',
                                    required: true,
                                    locked: true,
                                    default: true,
                                },
                                {
                                    field: 'comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    fall_events: [
                        {
                            label: 'General Fall Event Details',
                            fields: [
                                {
                                    field: 'fall_type',
                                    label: 'Fall Type',
                                    type: 'select',
                                    optionsType: 'fall_types',
                                    value_field: 'code',
                                },
                                { field: 'fall_date', label: 'Fall Date', type: 'date' },
                                { field: 'fall_time', label: 'Fall Time', type: 'time' },
                                {
                                    field: 'fall_location',
                                    label: 'Fall Location',
                                    type: 'select',
                                    optionsType: 'fall_locations',
                                    value_field: 'code',
                                },
                                {
                                    field: 'description',
                                    label: 'Description',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                { field: 'comments', label: 'Comments', type: 'text' },
                                { field: 'restraints', label: 'Restraints', type: 'checkbox' },
                                { field: 'restraints_comments', label: 'Restraints Comments' },
                                { field: 'outcome', label: 'Outcome', optionsType: 'fall_outcomes', type: 'select' },
                                { field: 'intrinsic', label: 'Intrinsic', type: 'checkbox' },
                                { field: 'anticipated', label: 'Anticipated', type: 'checkbox' },
                                {
                                    field: 'needs_followup_nursing_assessment',
                                    label: 'Needs Followup Nursing Assessment',
                                    type: 'checkbox',
                                },
                                { field: 'activities', label: 'Activities', type: 'text' },
                            ],
                        },
                        {
                            label: 'Reasons',
                            fields: [
                                { field: 'has_reasons_environment', label: 'Environment', type: 'checkbox' },
                                {
                                    field: 'has_reasons_visual_impairment',
                                    label: 'Visual Impairment',
                                    type: 'checkbox',
                                },
                                { field: 'has_reasons_muscle_weakness', label: 'Muscle Weakness', type: 'checkbox' },
                                {
                                    field: 'has_reasons_gait_disturbance',
                                    label: 'Gait Disturbance',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_reasons_cognitive_impairment',
                                    label: 'Cognitive Impairment',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_reasons_assistive_devices',
                                    label: 'Assistive Devices',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_reasons_medications',
                                    label: 'More than 4 Medications',
                                    type: 'checkbox',
                                },
                                { field: 'reasons_other', label: 'Other', type: 'booltext' },
                                { field: 'reasons_other_comments', label: 'Reasons/Other Comments' },
                            ],
                        },
                    ],
                    family_diagnosis_histories: [],
                    inpatient_unit_notes: [
                        {
                            label: 'Inpatient Unit Note General Details',
                            sectionDefaultFn: function (chartStore) {
                                consolo.services.ClinicalChartDateTimeInteraction.initializeInpatientUnitNote(chartStore);
                            },
                            fields: [
                                {
                                    field: 'in_datetime',
                                    label: 'In Date',
                                    type: 'datetime',
                                    maxDate: this.today,
                                    required: true,
                                    locked: true,
                                },
                                {
                                    field: 'out_datetime',
                                    label: 'Out Date',
                                    type: 'datetime',
                                    maxDate: this.today,
                                    required: true,
                                    locked: true,
                                },
                                {
                                    field: 'inpatient_unit_note_activity_ids',
                                    label: 'Activity',
                                    optionsType: 'inpatient_unit_note_activities',
                                    type: 'checklist',
                                },
                                { field: 'notes', label: 'Notes', type: 'text' },
                                {
                                    field: 'inpatient_unit_note_rationale_ids',
                                    label: 'Rationale for Inpatient Level of Care',
                                    optionsType: 'inpatient_unit_note_rationales',
                                    type: 'checklist',
                                },
                                { field: 'rationale_notes', label: 'Rationale Notes', type: 'text' },
                            ],
                        },
                    ],
                    morse_fall_assessments: [
                        {
                            label: 'Morse Fall Assessment General Details',
                            fields: [
                                {
                                    field: 'ambulatory_aid',
                                    label: 'Ambulatory Aid',
                                    type: 'rating',
                                    max: 3,
                                    optionsType: 'ambulatory_ratings',
                                },
                                {
                                    field: 'gait_transferring',
                                    label: 'Gait/Transferring',
                                    type: 'rating',
                                    max: 3,
                                    optionsType: 'gait_ratings',
                                },
                                {
                                    field: 'mental_status',
                                    label: 'Mental Status',
                                    type: 'rating',
                                    max: 2,
                                    optionsType: 'mental_ratings',
                                },
                                { field: 'has_history_of_falling', label: 'History of Falling', type: 'checkbox' },
                                { field: 'has_secondary_diagnosis', label: 'Secondary Diagnosis', type: 'checkbox' },
                                { field: 'has_iv_heparin_lock', label: 'IV/Heparin Lock', type: 'checkbox' },
                                {
                                    field: 'total_score',
                                    label: 'Total Score',
                                    readonly: true,
                                    requiredFn: function (formModel) {
                                        var t = 0;
                                        t += formModel.has_history_of_falling ? 25 : 0;
                                        t += formModel.has_secondary_diagnosis ? 15 : 0;
                                        t +=
                                            formModel.ambulatory_aid && formModel.ambulatory_aid > 1
                                                ? (formModel.ambulatory_aid - 1) * 15
                                                : 0;
                                        t += formModel.has_iv_heparin_lock ? 20 : 0;
                                        t +=
                                            formModel.gait_transferring && formModel.gait_transferring > 1
                                                ? (formModel.gait_transferring - 1) * 10
                                                : 0;
                                        t += formModel.mental_status == 2 ? 15 : 0;
                                        formModel.total_score = t;
                                        console.log(t);
                                        return t > 0;
                                    },
                                },
                            ],
                        },
                    ],
                    nursing_health_histories: [
                        {
                            label: 'Patient Family Interview',
                            fields: [
                                { field: 'interview_source', label: 'Interview Source' },
                                { field: 'interview_source_notes', label: 'Interview Source Notes' },
                                { field: 'patient_health_history', label: 'Patient Health History', type: 'text' },
                                {
                                    field: 'prenatal_and_birth_history',
                                    label: 'Prenatal and Birth History',
                                    type: 'text',
                                },
                                {
                                    field: 'parent_sibling_child_interaction',
                                    label: 'Parent/Sibling/Child Interaction',
                                    type: 'text',
                                },
                                { field: 'family_health_history', label: 'Family Health History', type: 'text' },
                                {
                                    field: 'family_knowledge_of_prognosis',
                                    label: 'Family Knowledge of Prognosis',
                                    type: 'text',
                                },
                                {
                                    field: 'problems_identified_by_family',
                                    label: 'Problems Identified by Family',
                                    type: 'text',
                                },
                                { field: 'expectations_of_hospice', label: 'Expectations of Hospice', type: 'text' },
                            ],
                        },
                        {
                            label: 'Patient has Arrangements',
                            fields: [
                                {
                                    field: 'code_status',
                                    label: 'Code Status',
                                    default: true,
                                    readonly: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.code_status;
                                    },
                                },
                                {
                                    field: 'living_will',
                                    label: 'Living Will',
                                    type: 'booltext',
                                    readonly: true,
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.note_living_will;
                                    },
                                },
                                {
                                    field: 'dpa',
                                    label: 'DPA',
                                    type: 'booltext',
                                    readonly: true,
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.note_dpa;
                                    },
                                },
                                {
                                    field: 'designated_surrogate',
                                    label: 'Designated Surrogate',
                                    type: 'booltext',
                                    readonly: true,
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.note_designated_surrogate;
                                    },
                                },
                            ],
                        },
                    ],
                    nursing_pain_histories: [
                        {
                            label: 'General Pain History',
                            fields: [
                                { field: 'primary_site', label: 'Primary Pain Site', type: 'text' },
                                { field: 'patient_description', label: "Brief pain history (patient's viewpoint)", type: 'text' },
                                { field: 'description_of_onset', label: 'Description of Onset', type: 'text' },
                                { field: 'cause', label: 'Describe Cause of Pain', type: 'text' },
                                { field: 'feeling', label: 'Describe How Pain Feels to Patient', type: 'text' },
                                {
                                    field: 'breakthrough_pain_quantity',
                                    label: 'Breakthrough Pain Quantity',
                                    optionsType: 'breakthrough_pain_quantities',
                                    type: 'select',
                                },
                                {
                                    field: 'breakthrough_pain_frequency',
                                    label: 'Breakthrough Pain Frequency',
                                    optionsType: 'breakthrough_pain_frequencies',
                                    type: 'select',
                                },
                            ],
                        },
                        {
                            label: 'Times When Pain is Worse',
                            fields: [
                                { field: 'worse_early', label: 'Early Morning (pre-dawn)', type: 'booltext' },
                                { field: 'worse_morning', label: 'Morning', type: 'booltext' },
                                { field: 'worse_afternoon', label: 'Afternoon', type: 'booltext' },
                                { field: 'worse_evening', label: 'Evening', type: 'booltext' },
                                { field: 'worse_night', label: 'Night', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Pain Relief Methods Tried',
                            fields: [
                                { field: 'tried_medication', label: 'Medication', type: 'booltext' },
                                { field: 'tried_deep_relaxation', label: 'Deep Relaxation', type: 'booltext' },
                                { field: 'tried_heat', label: 'Heat', type: 'booltext' },
                                { field: 'tried_massage', label: 'Massage', type: 'booltext' },
                                { field: 'tried_cold', label: 'Cold', type: 'booltext' },
                                { field: 'tried_music', label: 'Music', type: 'booltext' },
                                { field: 'tried_visual_imagery', label: 'Visual Imagery', type: 'booltext' },
                                { field: 'tried_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Patient Activities',
                            fields: [
                                { field: 'activities_prevented', label: 'Activities Prevented', type: 'text' },
                                { field: 'associated_symptoms', label: 'Associated Symptoms', type: 'text' },
                                { field: 'has_relief_desire', label: 'Patient Desires Relief', type: 'checkbox' },
                                { field: 'note_relief_desire', label: 'Explain Patient does not Desire Relief' },
                            ],
                        },
                        {
                            label: 'Notes on Pain',
                            fields: [
                                {
                                    field: 'pain_assessment_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                        {
                            label: 'IV/SC Delivery',
                            fields: [
                                { field: 'ivsc_collection_date', label: 'Data Collection Date', type: 'date' },
                                { field: 'ivsc_residual_volume', label: 'Residual Volume (ml)', type: 'text' },
                                { field: 'ivsc_concentration', label: 'Concentration (mg/ml)', type: 'booltext' },
                                { field: 'ivsc_rate', label: 'Rate (mg/hr)', type: 'text' },
                                {
                                    field: 'ivsc_bolus_dose',
                                    label: 'Bolus Dose (incremental) (mg)',
                                    optionsType: 'bolus_doses',
                                    type: 'select',
                                },
                                { field: 'ivsc_inc_doses_allowed', label: 'Incremental Doses Allowed/hr', type: 'text' },
                                { field: 'ivsc_ic_doses_given', label: 'Incremental Doses Given', type: 'text' },
                                { field: 'ivsc_ic_doses_attempted', label: 'Incremental Doses Attempted', type: 'text' },
                                {
                                    field: 'ivsc_iv_type',
                                    label: 'IV Type',
                                    type: 'select',
                                    optionsType: 'iv_types',
                                    value_field: 'code',
                                },
                                { field: 'ivsc_iv_flush_reminder_date', label: 'IV Flush Reminder', type: 'date' },
                                { field: 'venous_access', label: 'Venous Access', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'SC Site Assessment',
                            fields: [
                                {
                                    field: 'has_ivsc_charged_battery',
                                    label: 'Charged Battery in Home',
                                    type: 'checkbox',
                                },
                                { field: 'ivsc_charged_battery_freq', label: 'Frequency of Battery Change', type: 'text' },
                                { field: 'has_ivsc_changed_battery', label: 'Battery Changed', type: 'checkbox' },
                                { field: 'has_ivsc_cassette_changed', label: 'Cassette Changed', type: 'checkbox' },
                                {
                                    field: 'ivsc_cassette_changed_date',
                                    label: 'Cassette Change Date',
                                    type: 'date',
                                    maxDate: this.today,
                                },
                                {
                                    field: 'has_ivsc_extra_cassette',
                                    label: 'Extra Cassette in Home',
                                    type: 'checkbox',
                                },
                                { field: 'has_ivsc_pump_cleared', label: 'Pump Cleared', type: 'checkbox' },
                                {
                                    field: 'has_ivsc_extra_iv_tubing',
                                    label: 'Extra IV Tubing in Home',
                                    type: 'checkbox',
                                },
                                { field: 'has_ivsc_iv_tubing_changed', label: 'IV Tubing Changed', type: 'checkbox' },
                                { field: 'has_ivsc_dressing_changed', label: 'Dressing Changed', type: 'checkbox' },
                            ],
                        },
                    ],
                    nursing_related_observations: [
                        {
                            label: "Emergency Preparedness",
                            fields: [
                                {
                                    field: 'disaster_acuity',
                                    type: 'static',
                                    label: 'Disaster Acuity',
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.disaster_acuity ? patient.disaster_acuity : '~';
                                    },
                                },
                                {
                                    field: "homebound_status",
                                    label: "Homebound Status",
                                    type: "select",
                                    optionsType: "homebound_status"
                                }, {
                                    field: "electrical_equipment",
                                    label: "Electrical Equipment",
                                    type: "select",
                                    optionsType: "electrical_equipment"
                                },
                                {
                                    field: "medications",
                                    label: "Medications",
                                    type: "select",
                                    optionsType: "medications"
                                },
                                {
                                    field: "treatments",
                                    label: "Treatments",
                                    type: "select",
                                    optionsType: "nursing_treatments"
                                }, {
                                    field: "caregiver",
                                    label: "Caregiver",
                                    type: "select",
                                    optionsType: "caregiver"
                                },
                                {
                                    field: "evacuation",
                                    label: "Evacuation",
                                    type: "select",
                                    optionsType: "evacuation"
                                },
                                {
                                    field: "activities_of_daily_living",
                                    label: "Activities of Daily Living",
                                    type: "select",
                                    optionsType: "activities_of_daily_living"
                                },
                            ]
                        },
                        {
                            label: 'Safety / Security',
                            fields: [
                                { field: 'safety_seizures', label: 'Seizures', type: 'booltext' },
                                { field: 'safety_fall_risk', label: 'Fall Risk', type: 'booltext' },
                                {
                                    field: 'safety_comprehension',
                                    label: 'Able to Comprehend and Follow Safety Instructions',
                                    type: 'booltext',
                                },
                                { field: 'safety_infection_risk', label: 'Infection Risk', type: 'booltext' },
                                {
                                    field: "safety",
                                    label: "Safety",
                                    type: "multiselect",
                                    optionsType: "nursing_safety"
                                },
                                {
                                    field: 'safety_infection_history',
                                    label: 'Infections 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'infection_statuses',
                                },
                                {
                                    field: 'safety_infection_current',
                                    label: 'Infections Current',
                                    type: 'multiselect',
                                    optionsType: 'infection_statuses',
                                },
                                {
                                    field: 'note_safety_infection',
                                    label: 'Infections Notes',
                                },
                                {
                                    field: "administration_safety_issues",
                                    label: "Medication Administration Safety Issues",
                                    type: "multiselect",
                                    optionsType: "administration_safety_issues"
                                },
                                {
                                    field: "demonstrated_medication_administration",
                                    label: "Demonstrated/Verbalized Understanding of Safe Medication Administration",
                                    type: "multiselect",
                                    optionsType: "role_options"
                                },
                                {
                                    field: "medications_reviewed",
                                    label: "Medications Reviewed for Effectiveness, Side Effects, Drug Interactions and Availability During the Visit",
                                    type: "checkbox"
                                },
                                {
                                    field: "counted_controlled_substance",
                                    label: "Reviewed/Counted Controlled Substance(s) to Ensure Accuracy of Administration Since Last Visit",
                                    type: "checkbox"
                                },
                                {
                                    field: "medications_ordered_dispensed",
                                    label: "Medications Ordered to be Dispensed",
                                    type: "select",
                                    optionsType: "yes_no_blank"
                                },
                                {
                                    field: 'controlled_substance_drug_data',
                                    type: 'custom',
                                    customComponentName: "consolo-charting-controlled-substance-count",
                                    label: 'Controlled Substance Count'
                                },
                                {
                                    field: "location_of_care_environmental_safety",
                                    label: "Location of Care Environmental Safety",
                                    type: "multiselect",
                                    optionsType: "location_of_care_environmental_safety"
                                },
                                {
                                    field: "environmental_safety_notes",
                                    label: "Other Location of Care / Environmental Safety Notes",
                                    type: "text"
                                },
                                { field: 'safety_home_problems', label: 'Home Management Problems', type: 'text' },
                                {
                                    field: 'has_poc_identified_as_fall_risk',
                                    label: 'Plan of Care Identified as Fall Risk Factor',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'safety_environment',
                                    label: 'Concerns related to environment, safety, cleanliness, accessibility',
                                    type: 'text',
                                },
                                {
                                    field: 'has_poc_identified_as_infection_risk',
                                    label: 'Plan of Care Identified as Infection Risk Factor',
                                    type: 'checkbox',
                                },
                                { field: 'safety_financial', label: 'Financial Concerns/Needs', type: 'text' },
                                {
                                    field: 'safety_comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                        {
                            label: 'Sexuality / Body Image Concerns',
                            fields: [
                                { field: 'note_sexuality_appearance', label: 'Appearance' },
                                { field: 'note_sexuality_functioning', label: 'Functioning' },
                                { field: 'note_sexuality_role_change', label: 'Role Change' },
                                { field: 'sexuality_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Labs Performed',
                            fields: [
                                { field: 'has_no_labs_performed', label: 'No Labs Performed', type: 'checkbox' },
                                { field: 'lab_sodium', label: 'Sodium' },
                                { field: 'lab_site', label: 'Lab Site', type: 'text' },
                                {
                                    field: 'lab_attempts',
                                    label: 'Attempts Made',
                                    optionsType: 'lab_attempts',
                                    type: 'select',
                                },
                                { field: 'lab_utilized', label: 'Utilized (Needle)', type: 'text' },
                                { field: 'lab_pressure_applied', label: 'Pressure Applied', type: 'booltext' },
                                { field: 'lab_no_bleeding', label: 'No Residual Bleeding', type: 'booltext' },
                                { field: 'lab_no_bruising', label: 'No Bruising', type: 'booltext' },
                                { field: 'lab_gauze_applied', label: 'Band-Aid/Gauze Applied', type: 'booltext' },
                                { field: 'lab_patient_tolerated', label: 'Patient Tolerated Well', type: 'booltext' },
                                { field: 'lab_delivered_to', label: 'Lab Delivered To', type: 'text' },
                            ],
                        },
                        {
                            label: 'Universal Precautions Followed',
                            fields: [
                                { field: 'has_upf_handwashing', label: 'Handwashing', type: 'checkbox' },
                                { field: 'has_upf_sharps_disposal', label: 'Sharps Disposal', type: 'checkbox' },
                                { field: 'has_upf_gloves_worn', label: 'Gloves Worn', type: 'checkbox' },
                                {
                                    field: "airborne_precautions",
                                    label: "Airborne Precautions",
                                    type: "checkbox"
                                }, {
                                    field: "contact_precautions",
                                    label: "Contact Precautions",
                                    type: "checkbox"
                                },
                                {
                                    field: "droplet_precautions",
                                    label: "Droplet Precautions",
                                    type: "checkbox"
                                },
                                {
                                    field: 'has_upf_environment',
                                    label: 'Environment Assessed for New Safety Needs',
                                    type: 'checkbox',
                                },
                                { field: 'upf_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                    ],
                    cqms: [
                        {
                            label: 'MIPS/CQM',
                            fields: [
                                {
                                    field: 'cqm_id',
                                    label: 'CQM',
                                    type: 'text',
                                },
                            ],
                        },
                    ],
                    oxygen_safety_assessment: [
                        {
                            label: '',
                            fields: [
                                {
                                    field: 'patient_smoke',
                                    label: 'Does patient smoke/vape?',
                                    type: 'booltext',
                                },
                                {
                                    field: 'no_smoking_with_oxygen',
                                    label: 'Patient and/or caregiver understand and agree not to smoke or allow others not to smoke in same room that oxygen is in use',
                                    type: 'booltext',
                                },
                                {
                                    field: 'in_household_smoke',
                                    label: 'Does anyone in househould smoke/vape?',
                                    type: 'booltext',
                                },
                                {
                                    field: 'evidence_of_smoking_materials',
                                    label: 'Evidence of smoking materials ex. Lighter, cigarettes',
                                    type: 'booltext',
                                },
                                {
                                    field: 'uses_gas_stove',
                                    label: 'Cooks/heats with gas stove',
                                    type: 'booltext',
                                },
                                {
                                    field: 'uses_open_flames',
                                    label: 'Open flames, ex. Candles present, wood burning stove or fireplace',
                                    type: 'booltext',
                                },
                                {
                                    field: 'functioning_smoke_detector',
                                    label: 'Functioning smoke detector present',
                                    type: 'booltext',
                                },
                                {
                                    field: 'oxygen_cylinder_stored',
                                    label: 'Oxygen cylinders either in a stand or lying flat and not stored next to heat source or in a confined space',
                                    type: 'booltext',
                                },
                                {
                                    field: 'no_smoking_sign',
                                    label: 'No smoking sign posted on front exterior door',
                                    type: 'booltext',
                                },
                                {
                                    field: 'fire_risks_neighboring',
                                    label: 'Any fire risks for neighboring residences and buildings',
                                    type: 'booltext',
                                },
                                {
                                    field: 'fire_extinguishers_not_expired',
                                    label: 'Home has fire extinguisher that is not expired according to patient and/or caregiver',
                                    type: 'booltext',
                                },
                                {
                                    field: 'safe_oxygen_tubing',
                                    label: 'Oxygen extension tubing is in safe place to prevent falls avoiding the potential for tripping hazard',
                                    type: 'booltext',
                                },
                                {
                                    field: 'oxygen_concentration_in_good_location',
                                    label: 'Oxygen concentrator is placed in area of good ventilation at least 12 inches from furniture or walls or draperies and heating outlets',
                                    type: 'booltext',
                                },
                                {
                                    field: 'other',
                                    label: 'Other',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Education Provided',
                            fields: [
                                {
                                    field: 'smoke_info_provided',
                                    label: 'Do not smoke/vape, have open flames, nor use gas stove near oxygen',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'no_smoking_sign_provided',
                                    label: "Post 'No Smoking' sign",
                                    type: 'checkbox',
                                },
                                {
                                    field: 'obtain_smoke_detector_provided',
                                    label: 'Obtain smoke detector',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'change_battery_provided',
                                    label: 'Change Batteries',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'oxygen_cylinder_provided',
                                    label: 'Place oxygen cylinder in approriate and secure location',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'fire_risk_provided',
                                    label: 'Fire risk for neighbors',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'written_education_reviewed',
                                    label: 'Written Education Reviewed',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Action Plan for Non-Adherence',
                            fields: [
                                {
                                    field: 'patient_advised_of_danger',
                                    label: 'Patient Advised of Dangers & Consequences',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'oxygen_saftey_contract',
                                    label: 'Oxygen Safety Contract',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'medical_staff_contacted',
                                    label: 'Medical Staff Contacted',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'action_plan_comments',
                                    label: 'Action Plan Comments',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Comprehension',
                            fields: [
                                {
                                    field: 'verbalized_understanding',
                                    label: 'Verbalized understanding',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'teach_back',
                                    label: 'Teach Back',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'benefit_from_further_info',
                                    label: 'Would benefit from further education on next visit',
                                    type: 'checkbox',
                                },
                            ],
                        },
                    ],
                    reviewed_problem_list_items: [
                        {
                            label: 'Problem Assessment',
                            fields: [
                                {
                                    field: 'problem_list_item_id',
                                    label: 'Problem Reviewed',
                                    type: 'select',
                                    required: true,
                                    locked: true,
                                    query: 'problem_list_items',
                                },
                                {
                                    field: 'resolved',
                                    label: 'Problem Resolved',
                                    type: 'checkbox',
                                },
                            ],
                        },
                        {
                            label: 'Problem Treatment',
                            fields: [
                                {
                                    field: 'current_treatment',
                                    label: 'Current Treatment',
                                    type: 'text',
                                },
                                {
                                    field: 'medication_ids',
                                    label: 'Medications',
                                    type: 'checklist',
                                    query: 'patient_medications',
                                },
                                {
                                    field: 'effectiveness_of_treatment',
                                    label: 'Effectiveness of Treatment',
                                    type: 'text',
                                },
                                {
                                    field: 'current_interventions',
                                    label: 'Current Interventions',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Impact on Patient',
                            fields: [
                                {
                                    field: 'problem_severity',
                                    label: 'Problem Severity',
                                    type: 'select',
                                    optionsType: 'problem_list_item_severity',
                                },
                                {
                                    field: 'quality_of_life_impact',
                                    label: 'Quality of Life Impact',
                                    type: 'text',
                                },
                                {
                                    field: 'current_prognosis',
                                    label: 'Current Prognosis',
                                    type: 'text',
                                },
                                {
                                    field: 'impact_comments',
                                    label: 'Comments',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Problem Care Coordination',
                            fields: [
                                {
                                    field: 'consults_referrals',
                                    label: 'Consults / Referrals',
                                    type: 'text',
                                },
                                {
                                    field: 'coordination_comments',
                                    label: 'Comments',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Transportation Coordination',
                            fields: [
                                {
                                    field: 'transportation_provider_contacted_id',
                                    type: 'autocomplete',
                                    lookup: 'transportation_providers',
                                    object: 'transportation_provider',
                                    label: 'Transporation Provider Contacted',
                                },
                                {
                                    field: 'transportation_arrangements',
                                    label: 'Transportation Arrangements',
                                    type: 'text',
                                },
                                {
                                    field: 'transportation_comments',
                                    label: 'Comments',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Problem Follow-up',
                            fields: [
                                {
                                    field: 'labs_ordered',
                                    label: 'Labs Ordered',
                                    type: 'text',
                                },
                                {
                                    field: 'goals',
                                    label: 'Goals',
                                    type: 'text',
                                },
                                {
                                    field: 'follow_up_comments',
                                    label: 'Additional Comments',
                                    type: 'text',
                                },
                            ],
                        },
                    ],
                    pediatric_fall_risk_assessment: [
                        {
                            label: 'Pediatric Fall Risk Assessment',
                            fields: [
                                {
                                    field: 'change_in_mental_status',
                                    type: 'checkbox',
                                    label: 'Change in Mental Status',
                                },
                                {
                                    field: 'history_of_falls',
                                    type: 'checkbox',
                                    label: 'Hx of falls',
                                },
                                {
                                    field: 'age_less_than_three',
                                    type: 'checkbox',
                                    label: 'Age less than 3 years',
                                },
                                {
                                    field: 'mobility_impairment',
                                    type: 'checkbox',
                                    label: 'Mobility Impairment',
                                },
                                {
                                    field: 'prevention_interventions',
                                    type: 'text',
                                    label: 'Prevention Interventions',
                                },
                                {
                                    field: 'reeducate_out_of_bed',
                                    type: 'booltext',
                                    label: 'Re-educate out of bed',
                                },
                                {
                                    field: 'comments',
                                    type: 'text',
                                    label: 'Comments',
                                },
                                {
                                    field: 'reeducate_crib_rails',
                                    type: 'text',
                                    label: 'Crib Rails',
                                },
                                {
                                    field: 'correct_use_of_rails',
                                    type: 'text',
                                    label: 'Correct use of rails',
                                },
                                {
                                    label: 'Room free of clutter.',
                                    field: 'free_of_clutter',
                                    type: 'booltext',
                                },
                            ],
                        },
                    ],
                    nursing_summaries: [
                        {
                            label: 'Imminence of Death',
                            fields: [
                                {
                                    field: 'prognosis_terminal',
                                    label: 'Imminence of Death',
                                    type: 'booltext',
                                },
                            ],
                        },
                        {
                            label: 'Nursing Summary Notes',
                            fields: [
                                {
                                    field: 'visit_notes',
                                    label: 'Visit Notes/Ongoing Comprehensive Assessment',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                                {
                                    field: 'visit_goal',
                                    label: 'Changes In Condition and Progress Towards Goals',
                                    type: 'text',
                                },
                                {
                                    field: 'continuous_care_symptoms_current',
                                    label: 'Current symptom(s) for Continuous Care',
                                    type: 'text',
                                },
                                {
                                    field: 'general_impatient_care_symptoms_current',
                                    label: 'Current symptom(s) for General Inpatient Care',
                                    type: 'text',
                                },
                                { field: 'teaching_and_feedback', label: 'Teaching And Feedback', type: 'text' },
                                {
                                    field: 'other_people_goals',
                                    label: 'Patient/Family/Representative/CG Goals',
                                    type: 'text',
                                },
                                {
                                    field: 'barriers_to_achieving_goals',
                                    label: 'Barriers To Achieving Goals',
                                    type: 'text',
                                },
                                { field: 'visit_plan', label: 'Plan', type: 'text' },
                                { field: 'volunteer_services', label: 'Volunteer Services', type: 'text' },
                                { field: 'supervisory_visit', label: 'Supervisory Visit', type: 'text' },
                            ],
                        },
                        {
                            label: 'Patient Ability to Understand and Participate in Own Care',
                            fields: [
                                {
                                    field: 'has_ability_own_care_no_problem',
                                    label: 'Problem Not Apparent',
                                    type: 'checkbox',
                                },
                                { field: 'has_ability_own_care_concerns', label: 'Concerns', type: 'checkbox' },
                                { field: 'note_ability_own_care_concerns', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Family/Representative/CG Willingness and Capability to Care For Patient',
                            fields: [
                                {
                                    field: 'has_other_people_to_care_no_problem',
                                    label: 'Problem Not Apparent',
                                    type: 'checkbox',
                                },
                                { field: 'has_other_people_to_care_concerns', label: 'Concerns', type: 'checkbox' },
                                { field: 'note_other_people_to_care_concerns', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Visit Frequency Discussion',
                            fields: [
                                { field: 'discussed_visit_frequency', label: 'Discussed Visit Frequency', type: 'text' },
                                { field: 'approximate_next_visit_due', label: 'Approximate Next Visit Due', type: 'text' },
                            ],
                        },
                        {
                            label: 'Discharge Planning',
                            fields: [
                                { field: 'dp_goals_met', label: 'When Goals are Met', type: 'booltext' },
                                { field: 'dp_patient_expires', label: 'When Patient Expires', type: 'booltext' },
                                { field: 'dp_other', label: 'Other', type: 'booltext' },
                                {
                                    field: 'dp_5_day_notice',
                                    label: '5 day written notice of planned discharge given to patient/physician',
                                    type: 'booltext',
                                },
                                {
                                    field: 'dp_transfer_form_complete',
                                    label: 'Transfer Forms Completed',
                                    type: 'booltext',
                                },
                                {
                                    field: 'dp_revocation_form_complete',
                                    label: 'Revocation Forms Completed',
                                    type: 'booltext',
                                },
                                {
                                    field: 'dp_continuity_of_care_transition',
                                    label: 'Continuity of Care Transition Needs',
                                    type: 'booltext',
                                },
                                { field: 'note_dp_discussion', label: 'Discussion', type: 'text' },
                            ],
                        },
                    ],
                    patient_time: [
                        {
                            label: 'Patient Time',
                            fields: [
                                {
                                    field: 'in_datetime',
                                    label: 'In Date',
                                    type: 'datetime',
                                    maxDate: this.today,
                                    required: true,
                                    locked: true,
                                },
                                { field: 'out_datetime', label: 'Out Date', type: 'datetime', required: true, locked: true },
                                {
                                    field: 'clinical_care_type_id',
                                    label: 'Clinical Care Type',
                                    type: 'select',
                                    optionsType: 'clinical_care_types',
                                },
                                {
                                    field: 'travel_start_datetime',
                                    label: 'Travel Start Time',
                                    type: 'datetime',
                                    maxDate: this.today,
                                    locked: true,
                                },
                                {
                                    field: 'travel_stop_datetime',
                                    label: 'Travel Stop Time',
                                    type: 'datetime',
                                    maxDate: this.today,
                                    locked: true,
                                },
                                { field: 'mileage', label: 'Mileage', locked: true },
                                { field: 'out_of_pocket', label: 'Out of Pocket Expense' },
                                { field: 'comments', label: 'Comments', type: 'text' },
                            ],
                        },
                    ],
                    skin_integrity_assessments: [
                        {
                            label: 'Skin Integrity Assessment General Details',
                            fields: [
                                {
                                    field: 'sensory_perception',
                                    label: 'Sensory Perception',
                                    type: 'rating',
                                    optionsType: 'sensory_ratings',
                                },
                                {
                                    field: 'moisture',
                                    label: 'Moisture',
                                    type: 'rating',
                                    optionsType: 'moisture_ratings',
                                },
                                {
                                    field: 'activity',
                                    label: 'Activity',
                                    type: 'rating',
                                    optionsType: 'activity2_ratings',
                                },
                                {
                                    field: 'mobility',
                                    label: 'Mobility',
                                    type: 'rating',
                                    optionsType: 'mobility_ratings',
                                },
                                {
                                    field: 'nutrition',
                                    label: 'Nutrition',
                                    type: 'rating',
                                    optionsType: 'nutrition_ratings',
                                },
                                {
                                    field: 'friction',
                                    label: 'Friction and Shear',
                                    type: 'rating',
                                    max: 3,
                                    optionsType: 'friction_ratings',
                                },
                                {
                                    field: 'total_score',
                                    label: 'Total Score',
                                    readonly: true,
                                    defaultValue: 0,
                                    requiredFn: function (formModel) {
                                        var sensory_perception = formModel.sensory_perception
                                            ? parseInt(formModel.sensory_perception, 10)
                                            : 0;
                                        var moisture = formModel.moisture ? parseInt(formModel.moisture, 10) : 0;
                                        var mobility = formModel.mobility ? parseInt(formModel.mobility, 10) : 0;
                                        var activity = formModel.activity ? parseInt(formModel.activity, 10) : 0;
                                        var nutrition = formModel.nutrition ? parseInt(formModel.nutrition, 10) : 0;
                                        var friction = formModel.friction ? parseInt(formModel.friction, 10) : 0;
                                        formModel.total_score =
                                            sensory_perception + moisture + mobility + activity + nutrition + friction;
                                        console.log(formModel.total_score);
                                        return formModel.total_score > 0;
                                    },
                                },
                            ],
                        },
                    ],
                    social_assessments: [
                        {
                            label: 'General Social Assessment Details',
                            fields: [
                                {
                                    field: 'assessed_no_psychosocial_symptoms_identified',
                                    label: 'Assessed, No Psychosocial Symptoms Identified - N/A',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'expired_before_completed',
                                    label: 'Patient Expired Before Assessment Could Be Completed?',
                                    type: 'checkbox',
                                },
                                { field: 'mental_status', label: 'Mental Status', type: 'text' },
                                {
                                    field: 'pcg_id',
                                    label: 'Primary Care Giver',
                                    type: 'select',
                                    query: 'family_members',
                                },
                            ],
                        },
                        {
                            label: 'Symptoms',
                            fields: [
                                {
                                    field: 'has_sym_appetite_disturbance',
                                    label: 'Appetite Disturbances',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_sym_obsessions_compulsions',
                                    label: 'Obsessions/Compulsions',
                                    type: 'checkbox',
                                },
                                { field: 'has_sym_sleep_disturbance', label: 'Sleep Disturbances', type: 'checkbox' },
                                {
                                    field: 'has_sym_somatic_complaints',
                                    label: 'Somatic Complaints',
                                    type: 'checkbox',
                                },
                                { field: 'has_sym_binge_purge', label: 'Binge/Purge', type: 'checkbox' },
                                {
                                    field: 'has_sym_sexual_dysfunction',
                                    label: 'Sexual Dysfunction',
                                    type: 'checkbox',
                                },
                                { field: 'has_sym_depressed_mood', label: 'Depressed Mood', type: 'checkbox' },
                                { field: 'has_sym_sexual_acting_out', label: 'Sexual Acting Out', type: 'checkbox' },
                                { field: 'has_sym_self_mutilation', label: 'Self Mutilation', type: 'checkbox' },
                                { field: 'has_sym_fatigue_lethargy', label: 'Fatigue/Lethargy', type: 'checkbox' },
                                {
                                    field: 'has_sym_poor_concentration',
                                    label: 'Poor Concentration',
                                    type: 'checkbox',
                                },
                                { field: 'has_sym_aggression', label: 'Aggression', type: 'checkbox' },
                                {
                                    field: 'has_sym_hopelessness',
                                    label: 'Hopelessness/Helplessness',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_sym_interpersonal_conflict',
                                    label: 'Interpersonal Conflict',
                                    type: 'checkbox',
                                },
                                { field: 'has_sym_anxiety_panic', label: 'Anxiety/Panic', type: 'checkbox' },
                                { field: 'has_sym_poor_self_care', label: 'Poor Self Care Skills', type: 'checkbox' },
                                {
                                    field: 'has_sym_poor_child_care',
                                    label: 'Poor Child Care Skills',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_sym_poor_impulse_control',
                                    label: 'Poor Impulse Control',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_sym_decreased_interest',
                                    label: 'Decreased Interest',
                                    type: 'checkbox',
                                },
                                { field: 'has_sym_poor_insight', label: 'Poor Insight', type: 'checkbox' },
                                { field: 'has_sym_anhedonia', label: 'Anhedonia', type: 'checkbox' },
                                { field: 'sym_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Appearance',
                            fields: [
                                { field: 'has_ms_appearance_well_groomed', label: 'Well Groomed', type: 'checkbox' },
                                { field: 'has_ms_appearance_bizarre', label: 'Bizarre', type: 'checkbox' },
                                { field: 'has_ms_appearance_normal', label: 'Normal', type: 'checkbox' },
                                {
                                    field: 'has_ms_appearance_inappropriate',
                                    label: 'Inappropriate',
                                    type: 'checkbox',
                                },
                                { field: 'has_ms_appearance_disheveled', label: 'Disheveled', type: 'checkbox' },
                                { field: 'ms_appearance_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Attitude',
                            fields: [
                                { field: 'has_ms_attitude_cooperative', label: 'Cooperative', type: 'checkbox' },
                                { field: 'has_ms_attitude_uncooperative', label: 'Uncooperative', type: 'checkbox' },
                                { field: 'has_ms_attitude_guarded', label: 'Guarded', type: 'checkbox' },
                                { field: 'has_ms_attitude_belligerant', label: 'Belligerant', type: 'checkbox' },
                                { field: 'has_ms_attitude_suspicious', label: 'Suspicious', type: 'checkbox' },
                                {
                                    field: 'has_ms_attitude_suicide_potential',
                                    label: 'Suicide Potential',
                                    type: 'checkbox',
                                },
                                { field: 'ms_attitude_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Motor Activity',
                            fields: [
                                { field: 'has_ms_motor_calm', label: 'Calm', type: 'checkbox' },
                                { field: 'has_ms_motor_tremors', label: 'Tremors', type: 'checkbox' },
                                { field: 'has_ms_motor_hyperactive', label: 'Hyperactive', type: 'checkbox' },
                                { field: 'has_ms_motor_spasms', label: 'Spasms', type: 'checkbox' },
                                { field: 'has_ms_motor_agitated', label: 'Agitated', type: 'checkbox' },
                                { field: 'has_ms_motor_slowed', label: 'Slowed', type: 'checkbox' },
                                { field: 'ms_motor_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Affect',
                            fields: [
                                { field: 'has_ms_affect_appropriate', label: 'Appropriate', type: 'checkbox' },
                                { field: 'has_ms_affect_constricted', label: 'Constricted', type: 'checkbox' },
                                { field: 'has_ms_affect_labile', label: 'Labile', type: 'checkbox' },
                                { field: 'has_ms_affect_blunted', label: 'Blunted', type: 'checkbox' },
                                { field: 'has_ms_affect_expansive', label: 'Expansive', type: 'checkbox' },
                                { field: 'has_ms_affect_flat', label: 'Flat', type: 'checkbox' },
                                { field: 'has_ms_affect_incongruent', label: 'Incongruent', type: 'checkbox' },
                                { field: 'ms_affect_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Mood',
                            fields: [
                                { field: 'has_ms_mood_normal', label: 'Normal', type: 'checkbox' },
                                { field: 'has_ms_mood_euphoric', label: 'Euphoric', type: 'checkbox' },
                                { field: 'has_ms_mood_guilty', label: 'Guilty', type: 'checkbox' },
                                { field: 'has_ms_mood_depressed', label: 'Depressed', type: 'checkbox' },
                                { field: 'has_ms_mood_sad', label: 'Sad', type: 'checkbox' },
                                { field: 'has_ms_mood_grief', label: 'Grief', type: 'checkbox' },
                                { field: 'has_ms_mood_anxious', label: 'Anxious', type: 'checkbox' },
                                { field: 'has_ms_mood_angry', label: 'Angry', type: 'checkbox' },
                                { field: 'ms_mood_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Speech',
                            fields: [
                                { field: 'has_ms_speech_normal', label: 'Normal', type: 'checkbox' },
                                { field: 'has_ms_speech_soft', label: 'Soft', type: 'checkbox' },
                                { field: 'has_ms_speech_excessive', label: 'Excessive', type: 'checkbox' },
                                { field: 'has_ms_speech_incoherent', label: 'Incoherent', type: 'checkbox' },
                                { field: 'has_ms_speech_depressed', label: 'Depressed', type: 'checkbox' },
                                { field: 'has_ms_speech_loud', label: 'Loud', type: 'checkbox' },
                                { field: 'has_ms_speech_pressured', label: 'Pressured', type: 'checkbox' },
                                {
                                    field: 'has_ms_speech_flight_of_ideas',
                                    label: 'Flight Of Ideas',
                                    type: 'checkbox',
                                },
                                { field: 'has_ms_speech_anxious', label: 'Anxious', type: 'checkbox' },
                                { field: 'has_ms_speech_slurred', label: 'Slurred', type: 'checkbox' },
                                { field: 'has_ms_speech_perseverating', label: 'Perseverating', type: 'checkbox' },
                                { field: 'ms_speech_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Thought Process',
                            fields: [
                                { field: 'has_ms_process_intact', label: 'Intact', type: 'checkbox' },
                                {
                                    field: 'has_ms_process_loss_of_associations',
                                    label: 'Loosening of Associations',
                                    type: 'checkbox',
                                },
                                { field: 'has_ms_process_circumstantial', label: 'Circumstantial', type: 'checkbox' },
                                { field: 'has_ms_process_tangential', label: 'Tangential', type: 'checkbox' },
                                {
                                    field: 'has_ms_process_flight_of_ideas',
                                    label: 'Flight Of Ideas',
                                    type: 'checkbox',
                                },
                                { field: 'ms_process_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Hallucinations',
                            fields: [
                                { field: 'has_ms_content_hal_not_present', label: 'Not Present', type: 'checkbox' },
                                { field: 'has_ms_content_hal_visual', label: 'Visual', type: 'checkbox' },
                                { field: 'has_ms_content_hal_present', label: 'Present', type: 'checkbox' },
                                { field: 'has_ms_content_hal_olfactory', label: 'Olfactory', type: 'checkbox' },
                                { field: 'has_ms_content_hal_auditory', label: 'Auditory', type: 'checkbox' },
                                { field: 'has_ms_content_hal_command', label: 'Command', type: 'checkbox' },
                                { field: 'ms_content_hal_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Delusions',
                            fields: [
                                { field: 'has_ms_content_del_not_present', label: 'Not Present', type: 'checkbox' },
                                { field: 'has_ms_content_del_controlled', label: 'Controlled', type: 'checkbox' },
                                { field: 'has_ms_content_del_present', label: 'Present', type: 'checkbox' },
                                { field: 'has_ms_content_del_grandiose', label: 'Grandiose', type: 'checkbox' },
                                { field: 'has_ms_content_del_persecutory', label: 'Persecutory', type: 'checkbox' },
                                { field: 'has_ms_content_del_bizarre', label: 'Bizarre', type: 'checkbox' },
                                { field: 'ms_content_del_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Memory',
                            fields: [
                                { field: 'has_ms_memory_normal', label: 'Normal', type: 'checkbox' },
                                {
                                    field: 'has_ms_memory_short_term_loss',
                                    label: 'Short Term Loss',
                                    type: 'checkbox',
                                },
                                { field: 'has_ms_memory_recent_loss', label: 'Recent Loss', type: 'checkbox' },
                                { field: 'has_ms_memory_past_loss', label: 'Past Loss', type: 'checkbox' },
                                { field: 'ms_memory_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Judgment',
                            fields: [
                                { field: 'has_ms_judgment_normal', label: 'Normal', type: 'checkbox' },
                                { field: 'has_ms_judgment_common', label: 'Common Sensical', type: 'checkbox' },
                                { field: 'has_ms_judgment_fair', label: 'Fair', type: 'checkbox' },
                                { field: 'has_ms_judgment_poor', label: 'Poor', type: 'checkbox' },
                                { field: 'has_ms_judgment_dangerous', label: 'Dangerous', type: 'checkbox' },
                                { field: 'ms_judgment_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Insight',
                            fields: [
                                {
                                    field: 'has_ms_insight_uses_connections',
                                    label: 'Uses Connections',
                                    type: 'checkbox',
                                },
                                { field: 'has_ms_insight_gaps', label: 'Gaps', type: 'checkbox' },
                                { field: 'has_ms_insight_flashes', label: 'Flashes', type: 'checkbox' },
                                { field: 'has_ms_insight_unaware', label: 'Unaware', type: 'checkbox' },
                                { field: 'has_ms_insight_denial', label: 'Denial', type: 'checkbox' },
                                { field: 'ms_insight_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Orientation',
                            fields: [
                                { field: 'has_ms_orientation_time', label: 'Time', type: 'checkbox' },
                                { field: 'has_ms_orientation_person', label: 'Person', type: 'checkbox' },
                                { field: 'has_ms_orientation_place', label: 'Place', type: 'checkbox' },
                                { field: 'has_ms_orientation_situation', label: 'Situation', type: 'checkbox' },
                                { field: 'has_ms_orientation_object', label: 'Object', type: 'checkbox' },
                                { field: 'ms_orientation_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Intelligence',
                            fields: [
                                { field: 'has_ms_intelligence_average', label: 'Average', type: 'checkbox' },
                                {
                                    field: 'has_ms_intelligence_below_average',
                                    label: 'Below Average',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_ms_intelligence_above_average',
                                    label: 'Above Average',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_ms_intelligence_needs_investigation',
                                    label: 'Needs Investigation',
                                    type: 'checkbox',
                                },
                                { field: 'ms_intelligence_other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'History',
                            fields: [
                                {
                                    field: 'danger_to_others_current',
                                    label: 'Danger to Others Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'danger_to_others_history',
                                    label: 'Danger to Others History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'suicidal_ideation',
                                    label: 'Suicidal Ideation',
                                    type: 'multiselect',
                                    optionsType: 'role_options',
                                },
                                {
                                    field: 'suicidal_ideation_current',
                                    label: 'Suicidal Ideation Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'suicidal_ideation_history',
                                    label: 'Suicidal Ideation History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'mental_health_history',
                                    label: 'Mental Health History/Issues',
                                    type: 'text',
                                },
                                {
                                    field: 'substance_abuse_history',
                                    label: 'Substance Abuse History/Issues',
                                    type: 'text',
                                },
                                {
                                    field: 'treatment_adherence_issues_current',
                                    label: 'Treatment Adherence Issues Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'treatment_adherence_issues_history',
                                    label: 'Treatment Adherence Issues History',
                                    type: 'booltext',
                                },
                                { field: 'family_history', label: 'Family History', type: 'text' },
                                {
                                    field: 'cultural_considerations_history',
                                    label: 'Cultural Considerations History',
                                    type: 'booltext'
                                },
                                {
                                    field: 'cultural_considerations_current',
                                    label: 'Cultural Considerations Current',
                                    type: 'booltext'
                                },
                                {
                                    field: 'cultural_history',
                                    label: 'Cultural History or Barrier that Affects Care',
                                    type: 'text',
                                },
                                {
                                    field: 'safety_weapons_history',
                                    label: 'Safety/Weapons History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'safety_weapons_current',
                                    label: 'Safety/Weapons Current',
                                    type: 'booltext',
                                },
                                { field: 'educational_history', label: 'Educational History', type: 'text' },
                                { field: 'work_history', label: 'Work History', type: 'text' },
                                { field: 'hobbies', label: 'Hobbies', type: 'text' },
                                { field: 'activities_desired', label: 'Activities Desired' },
                                {
                                    field: 'other_pediatric_assessment',
                                    label: 'Other Pediatric Assessment',
                                    type: 'text'
                                },
                                {
                                    field: 'coping',
                                    label: 'How is the patient coping with the illness?',
                                    type: 'text',
                                },
                                {
                                    field: 'adjustment_to_terminal_illness_history',
                                    label: 'Adjustment to Terminal Illness History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'adjustment_to_terminal_illness_current',
                                    label: 'Adjustment to Terminal Illness Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'hardest_part',
                                    label: 'What is the hardest part of illness for the patient?',
                                    type: 'text',
                                },
                                {
                                    field: 'ivs',
                                    label: 'IV\'s',
                                    type: 'select',
                                    optionsType: 'decision_options',
                                },
                                {
                                    field: 'dnr',
                                    label: 'DNR',
                                    type: 'select',
                                    optionsType: 'decision_options',
                                },
                                {
                                    field: 'hospitalization',
                                    label: 'Hospitalization',
                                    type: 'select',
                                    optionsType: 'decision_options',
                                },
                                { field: 'comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Financial and Legal Information',
                            fields: [
                                {
                                    field: 'financial_legal_insurance_concerns',
                                    label: 'Financial/Legal/Insurance Concerns',
                                    type: 'multiselect',
                                    optionsType: 'role_options',
                                },
                                {
                                    field: 'financial_legal_insurance_concerns_current',
                                    label: 'Financial/Legal/Insurance Current',
                                    type: 'booltext',
                                },
                                {
                                    field: 'financial_legal_insurance_concerns_history',
                                    label: 'Financial/Legal/Insurance History',
                                    type: 'booltext',
                                },
                                { field: 'financial_concerns', label: 'Financial Concerns', type: 'text' },
                                {
                                    field: 'need_for_community_resources',
                                    label: 'Need for Community Resources',
                                    type: 'multiselect',
                                    optionsType: 'role_options',
                                },
                                {
                                    field: 'need_for_community_resources_history',
                                    label: 'Need for Community Resources History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'need_for_community_resources_current',
                                    label: 'Need for Community Resources Current',
                                    type: 'booltext',
                                },
                                { field: 'financial_comments', label: 'Financial Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Family Information',
                            fields: [
                                {
                                    field: 'social_family_support_history',
                                    label: 'Social/Family Support History',
                                    type: 'booltext',
                                },
                                {
                                    field: 'social_family_support_current',
                                    label: 'Social/Family Support Current',
                                    type: 'booltext',
                                },
                                { field: 'family_coping', label: 'Family Coping', type: 'text' },
                                {
                                    field: 'available_resources',
                                    label: 'Available Resources',
                                    type: 'booltext',
                                },
                                {
                                    field: 'involvement_of_support_system',
                                    label: 'Involvement of Support System (Including School)',
                                    type: 'text',
                                },
                                { field: 'family_dynamics', label: 'Family Dynamics', type: 'text' },
                            ],
                        },
                        {
                            label: "Primary Care Giver's Evaluation",
                            fields: [
                                {
                                    field: 'nature_of_relationship_with_patient',
                                    label: 'Nature of Relationship with Patient',
                                    type: 'text',
                                },
                                {
                                    field: 'caregiver_factors',
                                    label: "Factors affecting caregiver's ability to provide care",
                                    type: 'text',
                                },
                                {
                                    field: 'caregiver_coping',
                                    label: 'How is caregiver coping with role?',
                                    type: 'text',
                                },
                                { field: 'caregiver_loses', label: 'Significant or multiple losses', type: 'text' },
                                { field: 'caregiver_grief', label: "Assessment of caregiver's grief", type: 'text' },
                                { field: 'assessment', label: 'Social Worker Assessment', type: 'text' },
                            ],
                        },
                    ],
                    spiritual_assessments: [
                        {
                            label: 'General Spiritual Assessment Details',
                            fields: [
                                {
                                    field: "has_no_spiritual_symtpoms_identified",
                                    label: "Spiritual Assessed, No Spiritual Symptoms Identified-N/A",
                                    type: "checkbox"
                                },
                                {
                                    field: 'has_no_assess',
                                    label: 'Unable to Assess Due to Condition, Patient, or Family Refusal',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_patient_expired',
                                    label: 'Patient has Expired Before Assessment Could be Completed',
                                    type: 'checkbox',
                                },
                                {
                                    field: "spiritual_services",
                                    label: "Spiritual Services",
                                    type: "select",
                                    optionsType: "services_options"
                                },
                                {
                                    field: 'religion',
                                    type: 'static',
                                    label: 'Religion (can be changed in patient details)',
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        return patient.religion;
                                    },
                                },
                                { field: 'affiliation', label: 'Affiliation' },
                                { field: 'clergy', label: "Clergy's Name" },
                                { field: 'clergy_phone', label: "Clergy's Phone", type: 'phone' },
                                { field: 'clergy_visits', label: 'Clergy Visits Patient', type: 'booltext' },
                                {
                                    field: 'contact_clergy',
                                    label: 'Patient/Family Gives Consent for Hospice Chaplain to Contact Clergy',
                                    type: 'booltext',
                                },
                                {
                                    field: 'was_patient_asked_about_spiritual_concerns',
                                    label: 'Was Patient and/or Caregiver Asked About Spiritual/Existential Concerns?',
                                    optionsType: 'discussion_options',
                                    type: 'select',
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'date_patient_was_patient_asked_about_spiritual_concerns',
                                    label: 'Date Patient was Asked About Spiritual/Existential Concerns',
                                    type: 'date',
                                    maxDate: this.today,
                                    hospice_item_set: true,
                                    requiredFn: function (formModel) {
                                        return (formModel.was_patient_asked_about_spiritual_concerns ===
                                            'Yes & Discussion Occurred' ||
                                            formModel.was_patient_asked_about_spiritual_concerns ===
                                                'Yes But Patient/Responsible Party Refused to Discuss');
                                    },
                                    hideFn: function (formModel) {
                                        return formModel.was_patient_asked_about_spiritual_concerns === 'No';
                                    },
                                },
                                {
                                    field: 'significant_others',
                                    label: "Significant Others in Patient's Life",
                                    type: 'text',
                                },
                                {
                                    field: "religious_spiritual_beliefs_history",
                                    label: "Religious/Spiritual Beliefs History",
                                    type: "booltext"
                                },
                                {
                                    field: "religious_spiritual_beliefs_current",
                                    label: "Religious/Spiritual Beliefs Current",
                                    type: "booltext"
                                },
                                {
                                    field: "imp_religion_spirituality_history",
                                    label: "Importance of Religion/Spirituality History",
                                    type: "booltext"
                                },
                                {
                                    field: "imp_religion_spirituality_current",
                                    label: "Importance of Religion/Spirituality Current",
                                    type: "booltext"
                                },
                                { field: 'religious_utilization', label: 'Religious Utilization', type: 'text' },
                                {
                                    field: 'resources_utilized',
                                    label: 'Spiritual Resource Utilization',
                                    type: 'text',
                                },
                                { field: 'god_image', label: 'Image of God' },
                                { field: 'god_relationship', label: 'Relationship/Style with God' },
                                {
                                    field: "spiritual_issues_history",
                                    label: "Spiritual Issues History",
                                    type: "booltext"
                                },
                                {
                                    field: "spiritual_issues_current",
                                    label: "Spiritual Issues Current",
                                    type: "booltext"
                                },
                                { field: 'issues', label: 'Spiritual Issues to Address', type: 'text' },
                                {
                                    field: "religious_conflicts_family",
                                    label: "Religious Conflicts in Family",
                                    type: "multiselect",
                                    optionsType: "person_type_options"
                                },
                                {
                                    field: "religious_conflicts_family_history",
                                    label: "Religious Conflicts in Family History",
                                    type: "booltext"
                                },
                                {
                                    field: "religious_conflicts_family_current",
                                    label: "Religious Conflicts in Family Current",
                                    type: "booltext"
                                },
                                {
                                    field: "need_reconciliation",
                                    label: "Need for Reconciliation",
                                    type: "multiselect",
                                    optionsType: "person_type_options"
                                },
                                {
                                    field: "need_reconciliation_current",
                                    label: "Need for Reconciliation Current",
                                    type: "booltext"
                                },
                                {
                                    field: "need_reconciliation_history",
                                    label: "Need for Reconciliation History",
                                    type: "booltext"
                                },
                                {
                                    field: "unresolved_ethical_issues_current",
                                    label: "Unresolved Ethical Issues Current",
                                    type: "booltext"
                                },
                                {
                                    field: "unresolved_ethical_issues_history",
                                    label: "Unresolved Ethical Issues History",
                                    type: "booltext"
                                },
                                {
                                    field: "nearing_death_awareness_history",
                                    label: "Nearing Death Awareness History",
                                    type: "booltext"
                                },
                                {
                                    field: "nearing_death_awareness_current",
                                    label: "Nearing Death Awareness Current",
                                    type: "booltext"
                                },
                                { field: 'referral_strategies', label: 'Referral Strategies Used', type: 'text' },
                                {
                                    field: 'comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    supervisory_visits: [
                        {
                            label: 'Supervisory Visit General Details',
                            fields: [
                                {
                                    field: 'user_id',
                                    object: 'user',
                                    label: 'Form Completed By',
                                    type: 'autocomplete',
                                    lookup: 'users',
                                    default: true,
                                    requiredFn: function (formModel) {
                                        return !formModel.not_assessed;
                                    },
                                },
                                {
                                    field: 'not_assessed',
                                    label: 'Not Assessed',
                                    type: 'checkbox',
                                    changeFn: function (formModel, chartingFormField) {
                                        if (formModel.not_assessed) {
                                            formModel.user_id = null;
                                            formModel.user = null;
                                            formModel.visit_type = null;
                                            formModel.has_hospice_aide_visit = null;
                                            formModel.has_bathing = null;
                                            formModel.has_self_medications = null;
                                            formModel.has_personal_care = null;
                                            formModel.has_simple_dressing = null;
                                            formModel.has_ambulation = null;
                                            formModel.has_positioning = null;
                                            formModel.has_exercise = null;
                                            formModel.has_ostomy_appliances = null;
                                            formModel.has_meal_preparation = null;
                                            formModel.has_recording_input_output = null;
                                            formModel.has_grocery_shopping = null;
                                            formModel.has_tpr = null;
                                            formModel.has_washing_clothes = null;
                                            formModel.has_blood_pressure = null;
                                            formModel.has_homemaking = null;
                                            formModel.has_enema_admin = null;
                                            formModel.has_other = null;
                                            formModel.note_bathing = null;
                                            formModel.note_self_medications = null;
                                            formModel.note_personal_care = null;
                                            formModel.note_simple_dressing = null;
                                            formModel.note_ambulation = null;
                                            formModel.note_positioning = null;
                                            formModel.note_exercise = null;
                                            formModel.note_ostomy_appliances = null;
                                            formModel.note_meal_preparation = null;
                                            formModel.note_recording_input_output = null;
                                            formModel.note_grocery_shopping = null;
                                            formModel.note_tpr = null;
                                            formModel.note_washing_clothes = null;
                                            formModel.note_blood_pressure = null;
                                            formModel.note_homemaking = null;
                                            formModel.note_enema_admin = null;
                                            formModel.note_other = null;
                                            formModel.hospice_aide_name = null;
                                            formModel.has_aide_present = null;
                                            formModel.quality = null;
                                            formModel.has_hospice_aide_goal_met = null;
                                            formModel.quality_source = null;
                                            formModel.note = null;
                                            formModel.lpn_name = null;
                                            formModel.has_lpn_present = null;
                                            formModel.lpn_quality = null;
                                            formModel.has_lpn_goal_met = null;
                                            formModel.lpn_note = null;
                                            formModel.volunteer_name = null;
                                            formModel.has_volunteer_present = null;
                                            formModel.volunteer_quality = null;
                                            formModel.has_volunteer_goal_met = null;
                                            formModel.volunteer_note = null;
                                        }
                                    },
                                },
                                {
                                    field: 'visit_type',
                                    label: 'Visit Type',
                                    type: 'select',
                                    optionsType: 'visit_types',
                                    value_field: 'code',
                                },
                                { field: 'has_hospice_aide_visit', label: 'Hospice Aide Visit?', type: 'checkbox' },
                            ],
                        },
                        {
                            label: 'Hospice Aide Responsibilities',
                            fields: [
                                { field: 'bathing', label: 'Bathing', type: 'booltext' },
                                {
                                    field: 'self_medications',
                                    label: 'Self-Administered Medications',
                                    type: 'booltext',
                                },
                                { field: 'personal_care', label: 'Personal Care', type: 'booltext' },
                                { field: 'simple_dressing', label: 'Simple Non-Sterile Dressing', type: 'booltext' },
                                { field: 'ambulation', label: 'Ambulation', type: 'booltext' },
                                { field: 'positioning', label: 'Positioning', type: 'booltext' },
                                { field: 'exercise', label: 'Exercise', type: 'booltext' },
                                { field: 'ostomy_appliances', label: 'Ostomy Appliances', type: 'booltext' },
                                { field: 'meal_preparation', label: 'Meal Preparation', type: 'booltext' },
                                {
                                    field: 'recording_input_output',
                                    label: 'Recording Input-Output',
                                    type: 'booltext',
                                },
                                { field: 'grocery_shopping', label: 'Grocery Shopping', type: 'booltext' },
                                { field: 'tpr', label: 'Temperature, Pulse, Respiration', type: 'booltext' },
                                { field: 'washing_clothes', label: 'Washing Clothes', type: 'booltext' },
                                { field: 'blood_pressure', label: 'Blood Pressure', type: 'booltext' },
                                { field: 'homemaking', label: 'Homemaking', type: 'booltext' },
                                {
                                    field: 'enema_admin',
                                    label: 'Non-Medicated Enema Administration',
                                    type: 'booltext',
                                },
                                { field: 'other', label: 'Other', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Hospice Aide Care',
                            fields: [
                                { field: 'hospice_aide_name', label: 'Hospice Aide Name' },
                                {
                                    field: 'hospice_aide_supervised_name',
                                    label: 'Other Being Supervised Name',
                                },
                                { field: 'has_aide_present', label: 'Hospice Aide Present?', type: 'checkbox' },
                                {
                                    field: 'hospice_aide_supervision_type',
                                    label: 'Type of Supervision',
                                    type: 'select',
                                    optionsType: 'supervision_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'quality',
                                    label: 'Follow-Through on the Plan of Care Has Been',
                                    type: 'select',
                                    optionsType: 'qualities',
                                    value_field: 'code',
                                },
                                {
                                    field: 'has_hospice_aide_goal_met',
                                    label: 'Goals Have Been Met on a Consistent Basis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'quality_source',
                                    label: 'Care Quality According To',
                                    type: 'select',
                                    optionsType: 'quality_sources',
                                    value_field: 'code',
                                },
                                {
                                    field: 'hospice_aide_interpersonal_relations',
                                    label: 'Creates Successful Interpersonal Relationships with Patient/Family',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'hospice_aide_infection_control',
                                    label: 'Complies with Infection Control Policies and Procedures',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'hospice_aide_report_changes',
                                    label: 'Reports Changes in the Patient Condition',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                { field: 'note', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'LPN/LVN Information',
                            fields: [
                                { field: 'lpn_name', label: 'LPN/LVN Name' },
                                {
                                    field: 'lpn_supervised_name',
                                    label: 'Other Being Supervised Name',
                                },
                                { field: 'has_lpn_present', label: 'LPN/LVN Present?', type: 'checkbox' },
                                {
                                    field: 'lpn_supervision_type',
                                    label: 'Type of Supervision',
                                    type: 'select',
                                    optionsType: 'supervision_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'lpn_quality',
                                    label: 'Follow-Through on the Plan of Care Has Been',
                                    type: 'select',
                                    optionsType: 'qualities',
                                    value_field: 'code',
                                },
                                {
                                    field: 'has_lpn_goal_met',
                                    label: 'Goals Have Been Met on a Consistent Basis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'lpn_interpersonal_relations',
                                    label: 'Creates Successful Interpersonal Relationships with Patient/Family',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'lpn_infection_control',
                                    label: 'Complies with Infection Control Policies and Procedures',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'lpn_report_changes',
                                    label: 'Reports Changes in the Patient Condition',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                { field: 'lpn_note', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Volunteer Information',
                            fields: [
                                { field: 'volunteer_name', label: 'Volunteer Name' },
                                {
                                    field: 'volunteer_supervised_name',
                                    label: 'Other Being Supervised Name',
                                },
                                { field: 'has_volunteer_present', label: 'Volunteer Present?', type: 'checkbox' },
                                {
                                    field: 'volunteer_supervision_type',
                                    label: 'Type of Supervision',
                                    type: 'select',
                                    optionsType: 'supervision_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'volunteer_quality',
                                    label: 'Follow-Through on the Plan of Care Has Been',
                                    type: 'select',
                                    optionsType: 'qualities',
                                    value_field: 'code',
                                },
                                {
                                    field: 'has_volunteer_goal_met',
                                    label: 'Goals Have Been Met on a Consistent Basis',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'volunteer_interpersonal_relations',
                                    label: 'Creates Successful Interpersonal Relationships with Patient/Family',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'volunteer_infection_control',
                                    label: 'Complies with Infection Control Policies and Procedures',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                {
                                    field: 'volunteer_report_changes',
                                    label: 'Reports Changes in the Patient Condition',
                                    type: 'select',
                                    optionsType: 'supervisory_visit_options',
                                    value_field: 'code',
                                },
                                { field: 'volunteer_note', label: 'Comments', type: 'text' },
                            ],
                        },
                    ],
                    teaching_and_learning_assessments: [
                        {
                            label: 'General Teaching and Learning Assessment Details',
                            fields: [
                                {
                                    field: 'user_id',
                                    object: 'user',
                                    label: 'Taught by',
                                    type: 'autocomplete',
                                    lookup: 'users',
                                    required: true,
                                    locked: true,
                                    default: true,
                                },
                                {
                                    field: 'taught_to',
                                    label: 'Taught to',
                                    includes_patient_name: true,
                                    query: 'family_members',
                                    type: 'checklist',
                                    required: true,
                                    locked: true,
                                    defaultOptions: [
                                        { id: 'Facility Staff', description: 'Facility Staff' },
                                        { id: 'Immediate family', description: 'Immediate family' },
                                    ],
                                    denormalized: true,
                                },
                                {
                                    field: 'teaching_learning_topic_ids',
                                    label: 'Topics',
                                    optionsType: 'teaching_learning_topics',
                                    type: 'checklist',
                                    required: true,
                                    locked: true,
                                },
                            ],
                        },
                        {
                            label: 'Fields Taught',
                            fields: [
                                { field: 'has_teach_patient', label: 'Patient', type: 'checkbox' },
                                { field: 'has_teach_family', label: 'Family/Caregiver', type: 'checkbox' },
                                { field: 'has_teach_facility', label: 'Facility CG', type: 'checkbox' },
                                { field: 'teach_dme_use', label: 'Use & Proper Management of DME', type: 'booltext' },
                                { field: 'teach_medication', label: 'Medication', type: 'booltext' },
                                { field: 'has_teach_disease_process', label: 'Disease Process', type: 'checkbox' },
                                {
                                    field: 'has_teach_signs',
                                    label: 'Signs and Symptoms of Impending Death',
                                    type: 'checkbox',
                                },
                                { field: 'has_teach_pain', label: 'Pain Management', type: 'checkbox' },
                                { field: 'has_teach_skin', label: 'Skin Care', type: 'checkbox' },
                                { field: 'teach_symptom_management', label: 'Symptom Management', type: 'booltext' },
                                { field: 'has_teach_positioning', label: 'Positioning', type: 'checkbox' },
                                { field: 'note_teach_response', label: 'Response', type: 'text' },
                                { field: 'has_teach_hydration', label: 'Hydration', type: 'checkbox' },
                                {
                                    field: 'teach_rights_education',
                                    label: 'Education of Patients Rights/Responsibilities',
                                    type: 'booltext',
                                },
                                {
                                    field: 'teach_proper_med',
                                    label: 'Proper Medication Usage/Disposal',
                                    type: 'booltext',
                                },
                                {
                                    field: 'note_teach_med_assessment',
                                    label: 'Assessment of Patient/PCG Ability of Administer Medications',
                                    type: 'text',
                                },
                                { field: 'teach_nutrition', label: 'Nutrition', type: 'booltext' },
                                {
                                    field: 'general_comments',
                                    label: 'General Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    visit_notes: [
                        {
                            label: 'General Visit Note Information',
                            fields: [
                                { field: 'time_of_event', label: 'Time of Event', type: 'time' },
                                { field: 'call_report', label: 'Call Report', type: 'checkbox' },
                                {
                                    field: 'visit_type',
                                    label: 'Visit Type',
                                    type: 'select',
                                    optionsType: 'visit_types',
                                    encounter_type_field: 'note_visit_type',
                                    value_field: 'code',
                                },
                                {
                                    field: 'therapy_type_id',
                                    label: 'Therapy Type',
                                    type: 'select',
                                    optionsType: 'therapy_types',
                                },
                                { field: 'note', label: 'Note', type: 'text', viewSection: ['inpatient_unit_notes'] },
                            ],
                        },
                        {
                            label: 'Supplementary Visit Note Information/Ongoing Comprehensive Assessment',
                            fields: [
                                { field: 'goals', label: 'Goals', type: 'text' },
                                { field: 'suplementary_assessment', label: 'Assessment', type: 'text' },
                                { field: 'plans', label: 'Plans', type: 'text' },
                                { field: 'interventions', label: 'Interventions', type: 'text' },
                                { field: 'pcg_response', label: 'PCG response', type: 'text' },
                                { field: 'symptom_notes', label: 'Symptom Notes and Health Concerns', type: 'text' },
                                { field: 'safety_notes', label: 'Safety Notes', type: 'text' },
                            ],
                        },
                        {
                            label: 'Spiritual Concerns',
                            fields: [
                                {
                                    field: 'family_id',
                                    label: 'Primary Care Giver',
                                    type: 'select',
                                    query: 'family_members',
                                },
                                {
                                    field: 'concerns',
                                    label: 'Spiritual Concerns (Progress, New Concerns)',
                                    type: 'text',
                                },
                                { field: 'family_contact', label: 'Additional Contact with Family', type: 'text' },
                                { field: 'assessment', label: 'Assessment / Insight', type: 'text' },
                                { field: 'alerts', label: 'Alerts', type: 'text' },
                            ],
                        },
                    ],
                    vital_sign_measurements: [
                        {
                            label: 'General',
                            fields: [{ field: 'time_of_measurement', label: 'Event Time', type: 'time' }],
                        },
                        {
                            label: 'Blood Pressure',
                            fields: [
                                {
                                    field: 'vitals_bp',
                                    label: 'Blood Pressure - Systolic / Diastolic',
                                    infoLink: "'" + this.cdsItemUrl + "'",
                                    auditEvent: 'Clinical Decision Support Item',
                                    auditMessage: "Clicked Blood Pressure - Systolic / Diastolic CDS Item: " + this.cdsItemUrl,
                                },
                                {
                                    field: 'blood_pressure_arm',
                                    label: 'Blood Pressure Arm',
                                    optionsType: 'left_right',
                                    type: 'select',
                                },
                                {
                                    field: 'blood_pressure_position',
                                    label: 'Blood Pressure Position',
                                    optionsType: 'postural_positions',
                                    type: 'select',
                                },
                                {
                                    field: 'blood_pressure_leg',
                                    label: 'Blood Pressure Leg',
                                    optionsType: 'left_right',
                                    type: 'select',
                                },
                            ],
                        },
                        {
                            label: 'Oxygen',
                            fields: [
                                { field: 'oxygenation_sats', label: 'Oxygen Saturation', type: 'booltext' },
                                { field: 'o2_sat_on', label: 'Oxygen Saturation On', type: 'text' },
                                { field: 'oxygenation_o2', label: 'Oxygen (Liters/Minute)', type: 'booltext' },
                                {
                                    field: 'oxygen_concentration',
                                    label: 'Oxygen Concentration (%)',
                                },
                                { field: 'has_o2_prn', label: 'Oxygen PRN?', type: 'checkbox' },
                                { field: 'vitals_o2_usage', label: 'Oxygen Usage', type: 'checklist', optionsType: 'o2_usage' },
                                { field: 'has_o2_at_location_care', label: 'Has Oxygen at Location of Care', type: 'checkbox' },
                                { field: 'o2_needed_at_location_care', label: 'Oxygen Needed at Location of Care', type: 'checkbox' },
                                { field: 'vitals_r', label: 'Respiration' },
                            ],
                        },
                        {
                            label: 'Pulse',
                            fields: [
                                { field: 'apical_pulse', label: 'Apical Pulse' },
                                { field: 'has_apical_reg', label: 'Regular Apical Pulse?', type: 'checkbox' },
                                { field: 'has_apical_irreg', label: 'Irregular Apical Pulse?', type: 'checkbox' },
                                { field: 'radial_pulse', label: 'Radial Pulse' },
                                { field: 'has_radial_reg', label: 'Regular Radial Pulse?', type: 'checkbox' },
                                { field: 'has_radial_irreg', label: 'Irregular Radial Pulse?', type: 'checkbox' },
                                { field: 'note_vitals_brachial_pulse', label: 'Brachial Pulse' },
                                {
                                    field: 'has_vitals_brachial_reg',
                                    label: 'Regular Brachial Pulse?',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'has_vitals_brachial_irreg',
                                    label: 'Irregular Brachial Pulse?',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'femoral_pulse',
                                    label: 'Femoral Pulse',
                                },
                                {
                                    field: 'regular_femoral_pulse',
                                    label: 'Regular Femoral Pulse?',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'irregular_femoral_pulse',
                                    label: 'Irregular Femoral Pulse?',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'radial_pulse_quality',
                                    label: 'Pulse Quality',
                                    optionsType: 'pulse_qualities',
                                    type: 'select',
                                },
                                { field: 'has_vitals_bradycardia', label: 'Bradycardia', type: 'checkbox' },
                                { field: 'has_vitals_tachycardia', label: 'Tachycardia', type: 'checkbox' },
                            ],
                        },
                        {
                            label: 'Postural Vital Signs',
                            fields: [
                                {
                                    field: 'postural_position_1',
                                    label: 'Position 1',
                                    optionsType: 'postural_positions',
                                    type: 'select',
                                },
                                { field: 'postural_blood_pressure_1', label: 'Blood Pressure 1' },
                                { field: 'postural_apical_pulse_1', label: 'Apical Pulse 1' },
                                { field: 'postural_radial_pulse_1', label: 'Peripheral Pulse 1' },
                                {
                                    field: 'postural_position_2',
                                    label: 'Position 2',
                                    optionsType: 'postural_positions',
                                    type: 'select',
                                },
                                { field: 'postural_blood_pressure_2', label: 'Blood Pressure 2' },
                                { field: 'postural_apical_pulse_2', label: 'Apical Pulse 2' },
                                { field: 'postural_radial_pulse_2', label: 'Peripheral Pulse 2' },
                            ],
                        },
                        {
                            label: 'Temperature and Blood Sugar',
                            fields: [
                                { field: 'vitals_temp', label: 'Temperature' },
                                {
                                    field: 'temp_type',
                                    label: 'Method',
                                    optionsType: 'temp_types',
                                    type: 'select',
                                    value_field: 'code',
                                },
                                { field: 'blood_sugar', label: 'CAP Blood Sugar (mg/dL)' },
                            ],
                        },
                        {
                            label: 'Skin Vitals',
                            fields: [
                                {
                                    field: 'skin_color',
                                    label: 'Skin Color',
                                    type: 'select',
                                    optionsType: 'skin_colors',
                                },
                                { field: 'skin_color_notes', label: 'Skin Color Notes' },
                                {
                                    field: 'skin_moisture',
                                    label: 'Skin Moisture',
                                    type: 'select',
                                    optionsType: 'skin_moisture',
                                },
                                { field: 'skin_moisture_notes', label: 'Skin Moisture Notes' },
                                {
                                    field: 'skin_temperature',
                                    label: 'Skin Temperature',
                                    type: 'select',
                                    optionsType: 'skin_temperature',
                                },
                                { field: 'skin_temperature_notes', label: 'Skin Temperature Notes' },
                            ],
                        },
                        {
                            label: 'Performance Scales',
                            fields: [
                                {
                                    field: 'karnofsky',
                                    label: 'Karnofsky',
                                    type: 'select',
                                    optionsType: 'karnofsky_scale',
                                },
                                { field: 'fast', label: 'FAST', type: 'select', optionsType: 'fast_scale' },
                                {
                                    field: 'palliative',
                                    label: 'Palliative Performance Scale Current',
                                    type: 'select',
                                    optionsType: 'palliative_scale',
                                },
                                {
                                    field: 'palliative_performance_history',
                                    label: 'Palliative Performance Scale 3-6 Months Ago ',
                                    type: 'select',
                                    optionsType: 'palliative_scale',
                                },
                                {
                                    field: 'nyha_history',
                                    label: 'NYHA History',
                                    type: 'select',
                                    optionsType: 'nyha_scale',
                                },
                                { field: 'nyha', label: 'NYHA', type: 'select', optionsType: 'nyha_scale' },
                                {
                                    field: 'mitchel_risk',
                                    label: 'Mitchel Mortality Risk Index',
                                    type: 'select',
                                    optionsType: 'mitchel_scale',
                                },
                                { field: 'ecog', label: 'ECOG', type: 'select', optionsType: 'ecog_scale' },
                                { field: 'performance_scale_notes', label: 'Performance Scale Notes', type: 'text' },
                            ],
                        },
                        {
                            label: 'Patient Measurements',
                            fields: [
                                {
                                    // Ideally this goes in general but it is not auto updating height/weight fields on change
                                    field: 'has_vitals_under_two_years_old',
                                    label: 'Patient < 3 Years Old',
                                    type: 'checkbox',
                                    default: true,
                                    defaultValueFn: function (model, patient) {
                                        var bday = new Date(patient.dob_display).getTime();
                                        var now = new Date(model.effective_date).getTime();
                                        var three_years = 9.467e10;
                                        var two_days = 1.728e8;
                                        return model.id
                                            ? model.has_vitals_under_two_years_old
                                            : now - bday < three_years + two_days; //add 2 days to account for leap years
                                    },
                                    hideFn: function (model, patient) {
                                        //hide this if the patient is over 5, can adjust as needed
                                        var bday = new Date(patient.dob_display).getTime();
                                        var now = new Date().getTime();
                                        var five_years = 1.577e11;
                                        return model.id ? !model.has_vitals_under_two_years_old : now - bday > five_years;
                                    },
                                },
                                {
                                    field: 'height',
                                    label: 'Height (Use only inches)',
                                    labelFn: function (formModel) {
                                        return formModel.has_vitals_under_two_years_old
                                            ? 'Height/Length (inches) (< 3 years)'
                                            : 'Height (Use only inches)';
                                    },
                                },
                                {
                                    field: 'weight',
                                    label: 'Weight (pounds)',
                                    labelFn: function (formModel) {
                                        return formModel.has_vitals_under_two_years_old
                                            ? 'Weight (pounds) (< 3 years)'
                                            : 'Weight (pounds)';
                                    },
                                },
                                {
                                    field: 'weight_length_percentile',
                                    label: 'Weight for Length Percentile',
                                    labelFn: function (formModel) {
                                        return formModel.has_vitals_under_two_years_old
                                            ? 'Weight for Length Percentile (< 3 years)'
                                            : 'Weight for Length Percentile';
                                    },
                                },
                                {
                                    field: 'bmi',
                                    label: 'BMI',
                                    type: 'bmi',
                                    labelFn: function (formModel) {
                                        return formModel.has_vitals_under_two_years_old ? 'BMI (< 3 years)' : 'BMI';
                                    },
                                },
                                {
                                    field: 'bmi_percentile',
                                    label: 'BMI Percentile (3-20 years)',
                                    labelFn: function (formModel) {
                                        return formModel.has_vitals_under_two_years_old ? 'BMI Percentile (< 3 years)' : 'BMI Percentile (3-20 years)';
                                    },
                                },
                                {
                                    field: 'height_history',
                                    label: 'Height 3-6 Months Ago (Use only inches)',
                                },
                                {
                                    field: 'weight_history',
                                    label: 'Weight 3-6 Months Ago (pounds)',
                                },
                                {
                                    field: 'bmi_history',
                                    label: 'BMI 3-6 Months Ago',
                                    type: 'bmi',
                                    bmiHeightModel: 'height_history',
                                    bmiWeightModel: 'weight_history'
                                },
                                { field: 'vitals_birth_weight', label: 'Birth Weight (lb)', type: 'text' },
                                {
                                    field: 'vitals_head_circumference',
                                    label: 'Head Circumference (cm)',
                                    type: 'booltext',
                                    inputType: 'number',
                                },
                                {
                                    field: 'head_circumference_percentile',
                                    label: 'Head Circumference Percentile',
                                    labelFn: function (formModel) {
                                        return formModel.has_vitals_under_two_years_old ? 'Head Circumference Percentile (< 3 years)' : 'Head Circumference Percentile';
                                    },
                                    inputType: 'number',
                                },
                                {
                                    field: 'vitals_chest_circumference',
                                    label: 'Chest Circumference (cm)',
                                    type: 'booltext',
                                    inputType: 'number',
                                },
                                { field: 'vitals_abdominal_girth', label: 'Abdominal Girth (cm)', type: 'booltext', inputType: 'number' },
                                { field: 'mac', label: 'Mid-Arm Circumference (cm)', inputType: 'number' },
                                {
                                    field: 'mac_arm',
                                    label: 'Mid-Arm Circumference Arm',
                                    optionsType: 'left_right',
                                    type: 'select',
                                },
                                { field: 'tsf', label: 'Tricep Skin Fold (mm)', inputType: 'number' },
                                {
                                    field: 'tsf_arm',
                                    label: 'Tricep Skin Fold Arm',
                                    optionsType: 'left_right',
                                    type: 'select',
                                },
                                { field: 'mid_thigh_circumference', label: 'Mid-Thigh Circumference (cm)', inputType: 'number' },
                                {
                                    field: 'mid_thigh_circumference_leg',
                                    label: 'Mid-Thigh Circumference Leg',
                                    optionsType: 'left_right',
                                    type: 'select',
                                },
                                { field: 'mid_muscle_area_history',
                                    label: 'Mid Muscle Area 3-6 Months Ago (cm²)',
                                    inputType: 'number',
                                },
                                {
                                    field: 'mid_muscle_area',
                                    label: 'Mid Muscle Area Current (cm²)',
                                    inputType: 'number',
                                },
                                {
                                    field: 'note_vitals',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    wound_assessments: [
                        {
                            label: 'General Details',
                            fields: [
                                {
                                    field: 'assessed_no_symptoms_na',
                                    label: 'Assessed, No Wound Symptoms Identified - N/A',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'wound_type',
                                    label: 'Wound Type',
                                    optionsType: 'wound_options',
                                    type: 'select',
                                    value_field: 'code',
                                },
                                {
                                    field: 'drainage_amount',
                                    label: 'Drainage Amount',
                                    optionsType: 'drainage_amounts',
                                    type: 'select',
                                },
                                { field: 'length', label: 'Length' },
                                { field: 'width', label: 'Width' },
                                { field: 'depth', label: 'Depth' },
                                {
                                    field: 'stage',
                                    label: 'Stage/Classification',
                                    optionsType: 'wound_stage_options',
                                    type: 'select',
                                },
                            ],
                        },
                        {
                            label: 'Conditions',
                            fields: [
                                { field: 'has_sinus', label: 'Has Sinus', type: 'checkbox' },
                                { field: 'sinus_percent', label: 'Sinus Percent' },
                                { field: 'has_tunneling', label: 'Has Tunneling', type: 'checkbox' },
                                { field: 'tunneling_percent', label: 'Tunneling Percent' },
                                { field: 'has_undermining', label: 'Has Undermining', type: 'checkbox' },
                                { field: 'undermining_percent', label: 'Undermining Percent' },
                                { field: 'has_necrotic', label: 'Has Necrotic', type: 'checkbox' },
                                { field: 'neurotic_percent', label: 'Necrotic Percent' },
                                { field: 'has_slough', label: 'Has Slough / Fibrin', type: 'checkbox' },
                                { field: 'slough_percent', label: 'Slough / Fibrin Percent' },
                                { field: 'has_eschar', label: 'Has Eschar', type: 'checkbox' },
                                { field: 'eschar_percent', label: 'Eschar Percent' },
                                { field: 'has_exposed_bone', label: 'Has Exposed Bone', type: 'checkbox' },
                                { field: 'exposed_bone_percent', label: 'Exposed Bone Percent' },
                                { field: 'has_granulation', label: 'Has Granulation', type: 'checkbox' },
                                { field: 'granulation_percent', label: 'Granulation Percent' },
                                { field: 'has_epithe', label: 'Has Epithelialization', type: 'checkbox' },
                                { field: 'epithelialization_percent', label: 'Epithelialization Percent' },
                                { field: 'has_exudate', label: 'Has Exudate', type: 'checkbox' },
                                { field: 'has_serous', label: 'Has Serous', type: 'checkbox' },
                                { field: 'has_serosan', label: 'Has Serosanguineous', type: 'checkbox' },
                                { field: 'has_purulent', label: 'Has Purulent', type: 'checkbox' },
                                { field: 'has_pain', label: 'Has Pain', type: 'checkbox' },
                                { field: 'has_odor', label: 'Has Odor', type: 'checkbox' },
                                { field: 'other_conditions', label: 'Other', type: 'text' },
                            ],
                        },
                        {
                            label: 'Surrounding Skin',
                            fields: [
                                { field: 'has_erythema', label: 'Has Erythema', type: 'checkbox' },
                                { field: 'has_maceration', label: 'Has Maceration', type: 'checkbox' },
                                { field: 'has_induration', label: 'Has Induration', type: 'checkbox' },
                                { field: 'has_intact', label: 'Is Intact', type: 'checkbox' },
                                { field: 'treatment_reminder', label: 'Treatment Reminder', type: 'date' },
                                { field: 'treatment', label: 'Site Treatment', type: 'text' },
                                { field: 'resolved_date', label: 'Date Resolved', type: 'date', maxDate: this.today },
                                {
                                    field: 'comments',
                                    label: 'Comments',
                                    type: 'text',
                                    viewSection: ['inpatient_unit_notes'],
                                },
                            ],
                        },
                    ],
                    pain_observations: [
                        {
                            label: 'General',
                            fields: [
                                {
                                    label: 'Scale Type',
                                    field: 'scale_type',
                                    type: 'select',
                                    optionsType: 'scale_type',
                                },
                                { label: 'Pain Type', field: 'pain_type', type: 'select', optionsType: 'pain_types' },
                                { label: 'Comments', field: 'comments', type: 'text' },
                                {
                                    label: 'Needs Followup Nursing Assessment',
                                    field: 'needs_followup_nursing_assessment',
                                    type: 'checkbox',
                                },
                                { label: 'CM Notified of Pain/Symptom?', field: 'has_cm_notified', type: 'checkbox' },
                                { label: 'Pain Verbal/Non-Verbal Assessment Caregiver Input', field: 'has_pain_assessment_caregiver_input', type: 'checkbox' },
                                { field: 'resolved_date', label: 'Date Resolved', maxDate: this.today },
                                {
                                    label: 'Is Pain an Active Problem for the Patient?',
                                    field: 'active_pain',
                                    type: 'select',
                                    optionsType: 'yes_no',
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'pain_ratings',
                                    label: 'Pain 3-6 Months Ago',
                                    type: 'rating',
                                    optionsType: 'pain_ratings',
                                    minZero: true,
                                    max: 11,
                                },
                                {
                                    field: 'other_indications_of_pain',
                                    label: 'Other Indications of Pain',
                                    type: 'multiselect',
                                    optionsType: 'other_indications_of_pain',
                                },
                            ],
                        },
                        {
                            label: 'Verbal Details',
                            fields: [
                                {
                                    field: 'present_intensity',
                                    label: 'Present Intensity',
                                    type: 'rating',
                                    optionsType: 'pain_ratings',
                                    minZero: true,
                                    max: 11,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'best_intensity',
                                    label: 'Best Intensity',
                                    type: 'rating',
                                    optionsType: 'pain_ratings',
                                    minZero: true,
                                    max: 11,
                                },
                                {
                                    field: 'worst_intensity',
                                    label: 'Worst Intensity',
                                    type: 'rating',
                                    optionsType: 'pain_ratings',
                                    minZero: true,
                                    max: 11,
                                },
                                {
                                    field: 'one_hour_intensity',
                                    label: 'One Hour Intensity Post-Medicated',
                                    type: 'rating',
                                    optionsType: 'pain_ratings',
                                    minZero: true,
                                    max: 11,
                                },
                                {
                                    field: 'three_hours_intensity',
                                    label: 'Three Hour Intensity Post-Medicated',
                                    type: 'rating',
                                    optionsType: 'pain_ratings',
                                    minZero: true,
                                    max: 11,
                                },
                                {
                                    field: 'verbal_patient_acceptable_level_of_pain',
                                    label: 'Verbal Patient Acceptable Level of Pain',
                                    optionsType: 'pain_ratings',
                                    type: 'select',
                                    max: 11,
                                },
                                {
                                    label: 'Uncomfortable because of pain?',
                                    field: 'discomfort',
                                    type: 'rating',
                                    optionsType: 'discomfort',
                                },
                                {
                                    label: 'Reason unable to answer',
                                    field: 'discomfort_no_answer_reason',
                                    type: 'rating',
                                    optionsType: 'discomfort_no_answer_reasons',
                                },
                                {
                                    label: 'Other reason',
                                    field: 'note_discomfort_no_answer_other_reason',
                                    type: 'text',
                                },
                                {
                                    label: 'Pain under control w/i 48 hrs of admission',
                                    field: 'pain_in_control',
                                    type: 'rating',
                                    optionsType: 'yes_no',
                                },
                                {
                                    field: 'follow_up_reminder',
                                    label: 'Follow Up Reminder',
                                    type: 'date',
                                    minDate: this.today,
                                },
                            ],
                        },
                        {
                            label: 'PAINAD Details',
                            fields: [
                                {
                                    label: 'Reason unable to answer',
                                    field: 'painad_no_answer_reason',
                                    type: 'rating',
                                    optionsType: 'discomfort_no_answer_reasons',
                                },
                                { label: 'Other reason', field: 'painad_no_answer_other_reason', type: 'text' },
                                {
                                    field: 'painad_breathing',
                                    label: 'PAINAD Breathing',
                                    type: 'rating',
                                    optionsType: 'painadd_breathing_ratings',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'painad_negative_vocalization',
                                    label: 'PAINAD Negative Vocalization',
                                    type: 'rating',
                                    optionsType: 'painadd_neg_voc',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'painad_facial_expression',
                                    label: 'PAINAD Facial Expression',
                                    type: 'rating',
                                    optionsType: 'painadd_facial',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'painad_body_language',
                                    label: 'PAINAD Body Language',
                                    type: 'rating',
                                    optionsType: 'painad_body_lang',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'painad_consolability',
                                    label: 'PAINAD Consolability',
                                    type: 'rating',
                                    optionsType: 'painadd_consolability',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                            ],
                        },
                        // {
                        //   label: 'Encounter Diagnoses',
                        //   fields: [{ field: 'diagnoses', label: 'Encounter Diagnoses' }],
                        // },
                        {
                            label: 'FLACC Details',
                            fields: [
                                {
                                    label: 'Reason unable to answer',
                                    field: 'flacc_no_answer_reason',
                                    type: 'rating',
                                    optionsType: 'discomfort_no_answer_reasons',
                                },
                                { label: 'Other reason', field: 'flacc_no_answer_other_reason', type: 'text' },
                                {
                                    field: 'flacc_face',
                                    label: 'FLACC Face',
                                    type: 'rating',
                                    optionsType: 'flacc_face',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'flacc_legs',
                                    label: 'FLACC Legs',
                                    type: 'rating',
                                    optionsType: 'flacc_legs',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'flacc_activity',
                                    label: 'FLACC Activity',
                                    type: 'rating',
                                    optionsType: 'flacc_activity',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'flacc_cry',
                                    label: 'FLACC Cry',
                                    type: 'rating',
                                    optionsType: 'flacc_cry',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                                {
                                    field: 'flacc_consolability',
                                    label: 'FLACC Consolability',
                                    type: 'rating',
                                    optionsType: 'flacc_consolability',
                                    minZero: true,
                                    max: 3,
                                    hospice_item_set: true,
                                },
                            ],
                        },
                        {
                            label: 'Comprehensive',
                            fields: [
                                {
                                    label: 'Was Comprehensive Pain Assessment Done?',
                                    field: 'was_comprehensive_pain_assessment_done',
                                    type: 'rating',
                                    optionsType: 'yes_no',
                                    hospice_item_set: true,
                                },
                                { label: 'Location', field: 'location', type: 'text', hospice_item_set: true },
                                { label: 'Severity', field: 'severity', type: 'text', hospice_item_set: true },
                                { label: 'Character', field: 'character', type: 'text', hospice_item_set: true },
                                { label: 'Duration', field: 'duration', type: 'text', hospice_item_set: true },
                                {
                                    label: 'Frequency',
                                    field: 'frequency',
                                    type: 'select',
                                    optionsType: 'pain_frequencies',
                                    hospice_item_set: true,
                                },
                                {
                                    label: 'Pain is relieved by',
                                    field: 'relieved_by',
                                    type: 'text',
                                    hospice_item_set: true,
                                },
                                {
                                    label: 'Pain is worsened by',
                                    field: 'worsened_by',
                                    type: 'text',
                                    hospice_item_set: true,
                                },
                                {
                                    label: 'Effect on Function/Quality of Life?',
                                    field: 'effect_on_quality_of_life_note',
                                    type: 'rating',
                                    optionsType: 'yes_no',
                                    hospice_item_set: true,
                                },
                            ],
                        },
                    ],
                    mini_nutritional_assessments: [
                        {
                            label: 'Mini Nutritional Assessment',
                            fields: [
                                { field: 'height', label: 'Height', required: true, locked: true },
                                { field: 'weight', label: 'Weight', required: true, locked: true },
                                { field: 'mac', label: 'MAC', required: true, locked: true },
                            ],
                        },
                    ],
                    electronic_visit_verification: [
                        {
                            label: 'General',
                            fields: [
                                { field: 'has_location', label: 'Location' },
                                { field: 'has_signature', label: 'Signature' },
                            ],
                        },
                    ],
                    general: [
                        {
                            label: 'General Details',
                            fields: [
                                { field: 'effective_date', label: 'Effective Date', required: true, locked: true },
                                { field: 'user_id', label: 'Owner', required: true, locked: true },
                                { field: 'role_id', label: 'Discipline', required: true, locked: true },
                                { field: 'encounter_type', label: 'Encounter Type', required: false, locked: true },
                                { field: 'facility_id', label: 'Facility' },
                                { field: 'two_patient_identifiers_used', label: 'Two Patient Identifiers Used' },
                                { field: 'source_of_information', label: 'Source of Information' },
                                { field: 'other', label: 'Other' },
                                { field: 'purpose_of_visit', label: 'Purpose of Visit' },
                                { field: 'purpose_of_visit_other', label: 'Purpose of Visit Other' },
                            ],
                        },
                    ],
                    patient_diagnosis: [
                        {
                            label: 'General Patient Diagnosis (TBD)',
                            fields: [{ field: 'waiting_data', label: 'Awaiting Data' }],
                        },
                    ],
                    chief_complaint: [
                        {
                            label: '',
                            fields: [
                                { field: 'chief_complaint', label: 'Chief Complaint', type: 'text' },
                            ],
                        },
                    ],
                    reason_for_visit: [
                        {
                            label: '',
                            fields: [
                                {
                                    field: 'snomed_code',
                                    label: 'Reason (SNOMED Description)',
                                    type: 'autocomplete',
                                    lookup: 'snomed_signs_and_symptoms',
                                    object: 'snomed_concept',
                                },
                                {
                                    field: 'reason_for_visit',
                                    label: "Reason For Visit (Provider's Description)",
                                    type: 'text',
                                },
                            ],
                        },
                    ],
                    cpt_coding: [
                        {
                            label: '',
                            fields: [
                                { field: 'chief_complaint', label: 'Chief Complaint', type: 'text' },
                            ],
                        },
                    ],
                    patient_history: [
                        {
                            label: 'General Patient History',
                            fields: [
                                { field: 'surgical_history', label: 'Past Surgical History', type: 'text' },
                                { field: 'medical_history', label: 'Past Medical History', type: 'text' },
                                { field: 'family_medical_history_non‒contributory', label: 'Family Medical History Non-Contributory', type: 'checkbox' },
                                { field: 'family_history', label: 'Past Family History', type: 'text' },
                                { field: 'social_history', label: 'Social History', type: 'text' },
                            ],
                        },
                    ],
                    assessment_plan: [
                        {
                            label: 'General Assessment / Plan (TBD)',
                            fields: [{ field: 'mortality_risk', label: 'Risk for Mortality / Morbidity' }],
                        },
                    ],
                    visit_summary: [
                        {
                            label: 'General Visit Summary (TBD)',
                            fields: [{ field: 'waiting_data', label: 'Awaiting Data' }],
                        },
                    ],
                    history_of_present_illness: [
                        {
                            label: 'General HPI',
                            fields: [
                                { field: 'locations', label: 'Location', type: 'text' },
                                { field: 'quality', label: 'Quality', type: 'text' },
                                { field: 'severity', label: 'Severity', type: 'text' },
                                { field: 'duration', label: 'Duration', type: 'text' },
                                { field: 'timing', label: 'Timing', type: 'text' },
                                { field: 'context', label: 'Context', type: 'text' },
                                { field: 'modifying_factors', label: 'Modifying Factors', type: 'text' },
                                { field: 'signs', label: 'Associated Signs / Symptoms', type: 'text' },
                                { field: 'associated_signs_3_to_6_months_ago', label: 'Associated Signs / Symptoms 3-6 Months Ago', type: 'text' },
                                { field: 'current_medications_not_at_location_of_care', label: 'Current Medications Not at Location of Care (Informational only)', type: "text" },
                                { field: 'current_medications_at_location_of_care', label: 'Current Medications at Location of Care (Informational only)', type: "text" },
                                { field: 'current_diagnoses', label: 'Current Diagnoses', type: 'text' },
                                { field: 'diagnosis_information_obtained_from', label: 'Diagnosis Information Obtained from', type: 'multiselect', optionsType: 'diagnosis_information_obtained_options' },
                                { field: 'taking_supplements', label: 'Taking Supplements', type: 'checkbox' },
                            ],
                        },
                    ],
                    body_systems_hli: [
                        {
                            label: 'Hematologic',
                            fields: [
                                { field: 'has_hematalogic_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'paleness', label: 'Paleness', type: 'booltext' },
                                {
                                    field: 'abnormal_blood_vessels',
                                    label: 'Abnormal blood vessels',
                                    type: 'booltext',
                                },
                                { field: 'bruises', label: 'Bruises', type: 'booltext' },
                                { field: 'rashes', label: 'Rashes', type: 'booltext' },
                                {
                                    field: 'joint_swelling_or_tenderness',
                                    label: 'Joint swelling or tenderness',
                                    type: 'booltext',
                                },
                                {
                                    field: 'enlarged_spleen_or_liver',
                                    label: 'Enlarged spleen or liver',
                                    type: 'booltext',
                                },
                                { field: 'blood_in_stool', label: 'Blood in stool', type: 'booltext' },
                                { field: 'bilirubin', label: 'Bilirubin mg/dl' },
                                { field: 'hematologic_pt', label: 'PT', type: 'number' },
                                { field: 'hematologic_inr', label: 'INR', type: 'number' },
                                { field: 'history_of_gi_bleed', label: 'History of GI Bleed', type: 'checkbox' },
                                { field: 'history_of_gi_bleed_describe', label: 'History of GI Bleed Describe' },
                                { field: 'history_of_ascites', label: 'History of Ascites', type: 'checkbox' },
                                { field: 'history_of_ascites_describe', label: 'History of Ascites Describe' },
                                { field: 'hematalogic_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Lymphatic',
                            fields: [
                                { field: 'has_lymphatic_no_problem', label: 'No Problem', type: 'checkbox' },
                                {
                                    field: 'palpations_of_lymph_nodes_(neck_axillae_groin_other',
                                    label: 'Palpations of lymph nodes (neck, axillae, groin, other)	',
                                    type: 'booltext',
                                },
                                { field: 'lymphatic_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Allergic/Immunologic',
                            fields: [
                                { field: 'has_allergic_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'macular_rashes', label: 'Macular rashes', type: 'booltext' },
                                { field: 'vesicles', label: 'Vesicles', type: 'booltext' },
                                { field: 'pyoderma', label: 'Pyoderma', type: 'booltext' },
                                { field: 'eczema', label: 'Eczema', type: 'booltext' },
                                { field: 'petechiae', label: 'Petechiae', type: 'booltext' },
                                { field: 'alopecia', label: 'Alopecia', type: 'booltext' },
                                { field: 'telangiectasia', label: 'Telangiectasia', type: 'booltext' },
                                { field: 'hiv_cd4_count_current', label: 'HIV CD4 Count cells/mm³ Current' },
                                { field: 'hiv_cd4_count_before', label: 'HIV CD4 Count cells/mm³ 3-6 Months Ago' },
                                { field: 'hiv_therapy_current', label: 'HIV Highly Active Antiretroviral Therapy Current' },
                                { field: 'hiv_therapy_before', label: 'HIV Highly Active Antiretroviral Therapy 3-6 Months Ago' },
                                { field: 'hiv_viral_load_current', label: 'HIV Viral Load copies/ml Current' },
                                { field: 'hiv_viral_load_before', label: 'HIV Viral Load copies/ml 3-6 Months Ago' },
                                {
                                    field: 'hiv_opportunistic_infections_current',
                                    label: 'HIV Opportunistic Infections Current',
                                    type: 'multiselect',
                                    optionsType: 'hiv_opportunistic_infections',
                                },
                                {
                                    field: 'hiv_opportunistic_infections_before',
                                    label: 'HIV Opportunistic Infections 3-6 Months Ago',
                                    type: 'multiselect',
                                    optionsType: 'hiv_opportunistic_infections',
                                },
                                { field: 'hiv_opportunistic_infections_describe', label: 'HIV Opportunistic Infections Describe' },
                                { field: 'allergic_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Oncology',
                            fields: [
                                { field: 'has_oncology_no_symptoms', label: 'Assessed, No Cancer Symptoms Identified - N/A', type: 'checkbox' },
                                { field: 'cancer_primary_site', label: 'Cancer Primary Site' },
                                {
                                    field: 'cancer_stage',
                                    label: 'Cancer Stage',
                                    type: 'select',
                                    optionsType: 'cancer_stages',
                                },
                                { field: 'cancer_metastasis', label: 'Cancer Metastasis' },
                                {
                                    field: 'cancer_recent_treatment',
                                    label: 'Cancer Recent Treatment',
                                    type: 'multiselect',
                                    optionsType: 'cancer_treatments',
                                },
                                { field: 'cancer_other', label: 'Cancer Other' },
                                { field: 'cancer_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                    ],
                    body_systems_constitutional: [
                        {
                            label: 'General Appearance',
                            fields: [
                                { field: 'nourished', label: 'Nourished', type: 'booltext' },
                                { field: 'developed', label: 'Developed', type: 'booltext' },
                                { field: 'orientation', label: 'Orientation', type: 'booltext' },
                                { field: 'alertness', label: 'Alertness', type: 'booltext' },
                                { field: 'distressed', label: 'Distressed', type: 'booltext' },
                                { field: 'comments', label: 'Comments', type: 'text' },
                            ],
                        },
                    ],
                    body_systems_heent: [
                        {
                            label: 'Head',
                            fields: [
                                { field: 'has_head_no_problem', label: 'No Problem', type: 'checkbox' },
                                {
                                    field: 'microcephalic_or_macrocephalic',
                                    label: 'Microcephalic or Macrocephalic',
                                    type: 'booltext',
                                },
                                {
                                    field: 'temporal_artery_tenderness',
                                    label: 'Temporal Artery Tenderness',
                                    type: 'booltext',
                                },
                                {
                                    field: 'swelling_lesions_or_tenderness_to_scalp',
                                    label: 'Swelling, lesions or tenderness to scalp',
                                    type: 'booltext',
                                },
                                {
                                    field: 'abnormalities_to_external_appearance_of_face_and_or_asymmetry',
                                    label: 'Abnormalities to external appearance of face and/or asymmetry',
                                    type: 'booltext',
                                },
                                {
                                    field: 'newborn_fontanels',
                                    label: 'Newborn / Infant Fontanels',
                                    type: 'select',
                                    optionsType: 'newborn_fontanels',
                                },
                                { field: 'head_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Eyes',
                            fields: [
                                { field: 'has_eyes_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'ptosis', label: 'Ptosis', type: 'booltext' },
                                {
                                    field: 'exopthalmos_or_enopthalmus',
                                    label: 'Exopthalmos or Enopthalmus',
                                    type: 'booltext',
                                },
                                { field: 'scleral_icterus', label: 'Scleral icterus', type: 'booltext' },
                                { field: 'drainage', label: 'Drainage', type: 'booltext' },
                                { field: 'pupils', label: 'Pupils', type: 'booltext' },
                                { field: 'nystagmus', label: 'Nystagmus', type: 'booltext' },
                                { field: 'foreign_body', label: 'Foreign Body', type: 'booltext' },
                                { field: 'eyelids', label: 'Eyelids', type: 'booltext' },
                                { field: 'cataracts', label: 'Cataracts', type: 'booltext' },
                                { field: 'eye_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Ears',
                            fields: [
                                { field: 'has_ears_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'external_tenderness', label: 'External tenderness', type: 'booltext' },
                                {
                                    field: 'swelling_lesions_drainage',
                                    label: 'Swelling, lesions, drainage',
                                    type: 'booltext',
                                },
                                { field: 'tympanic_membrane', label: 'Tympanic Membrane', type: 'booltext' },
                                { field: 'ear_canal', label: 'Ear Canal', type: 'booltext' },
                                { field: 'ear_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Nose',
                            fields: [
                                { field: 'has_nose_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'lesions', label: 'Lesions', type: 'booltext' },
                                { field: 'signs_of_trauma', label: 'Signs of trauma', type: 'booltext' },
                                { field: 'discharge', label: 'Discharge', type: 'booltext' },
                                { field: 'flaring', label: 'Flaring', type: 'booltext' },
                                { field: 'septal_deviation', label: 'Septal deviation', type: 'booltext' },
                                { field: 'polyps', label: 'Polyps', type: 'booltext' },
                                { field: 'foreign_body', label: 'Foreign body', type: 'booltext' },
                                { field: 'nose_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                        {
                            label: 'Throat/Mouth',
                            fields: [
                                { field: 'has_throat_no_problem', label: 'No Problem', type: 'checkbox' },
                                {
                                    field: 'cracking_dehydration',
                                    label: 'Dry / Cracking / Dehydration',
                                    type: 'booltext',
                                },
                                {
                                    field: 'missing_loose_decayed_or_abnormally_positioned_teeth',
                                    label: 'Missing, loose, decayed or abnormally positioned teeth',
                                    type: 'booltext',
                                },
                                {
                                    field: 'intraoral_lesions_sores',
                                    label: 'Intraoral Lesions / Sores',
                                    type: 'booltext',
                                },
                                { field: 'dentures', label: 'Dentures', type: 'booltext' },
                                {
                                    field: 'swelling_and_or_exudate_to_tonsils',
                                    label: 'Swelling and/or Exudate to Tonsils',
                                    type: 'booltext',
                                },
                                { field: 'braces_retainers', label: 'Braces / Retainers', type: 'booltext' },
                                { field: 'throat_comments', label: 'Comments', type: 'text' },
                            ],
                        },
                    ],
                    body_systems_endocrine: [
                        {
                            label: 'General',
                            fields: [
                                { field: 'has_endocrine_no_problem', label: 'No Problem', type: 'checkbox' },
                                { field: 'hair_loss', label: 'Hair Loss', type: 'booltext' },
                                { field: 'polydipsia', label: 'Polydipsia', type: 'booltext' },
                                {
                                    field: 'temperature_intolerance',
                                    label: 'Temperature Intolerance',
                                    type: 'booltext',
                                },
                                { field: 'muscle_weakness', label: 'Muscle Weakness', type: 'booltext' },
                                { field: 'comments', label: 'Comments', type: 'text' },
                            ],
                        },
                    ],
                    body_systems_genitourinary: [
                        {
                            label: 'Elimination',
                            fields: [
                                { field: 'has_genitourinary_no_problem', label: 'Genitourinary Assessed, No Genitourinary Symptoms Identified-NA', type: 'checkbox' },
                                { field: 'decreased_urinary_output', label: 'Decreased Urinary Output', type: 'checkbox' },
                                { field: 'has_genitourinary_incontinent', label: 'Incontinent', type: 'checkbox' },
                                {
                                    field: 'genitourinary_incontinent_type',
                                    label: 'Incontinence Type',
                                    type: 'select',
                                    optionsType: 'incontinence',
                                },
                                { field: 'genitourinary_bladder', label: 'Bladder', type: 'booltext' },
                                { field: 'genitourinary_burning_history', label: 'Burning History', type: 'booltext' },
                                { field: 'genitourinary_burning', label: 'Burning Current', type: 'booltext' },
                                { field: 'genitourinary_hematuria', label: 'Hematuria', type: 'booltext' },
                                { field: 'genitourinary_retention_history', label: 'Retention History', type: 'booltext' },
                                { field: 'genitourinary_retention', label: 'Retention Current', type: 'booltext' },
                                { field: 'genitourinary_polyuria_history', label: 'Polyuria History', type: 'booltext' },
                                { field: 'genitourinary_polyuria_current', label: 'Polyuria Current', type: 'booltext' },
                                { field: 'genitourinary_history_of_uti', label: 'History of Urinary Tract Infection', type: 'booltext' },
                                { field: 'genitourinary_usual_urinary_pattern', label: 'Usual Urinary Pattern', type: 'booltext' },
                                { field: 'genitourinary_present_urinary_pattern', label: 'Present Urinary Pattern', type: 'booltext' },
                                { field: 'urinary_frequency_history', label: 'Urinary Frequency History', type: 'booltext' },
                                { field: 'urinary_frequency_current', label: 'Urinary Frequency Current', type: 'booltext' },
                                { field: 'urinary_urgency_history', label: 'Urinary Urgency History', type: 'booltext' },
                                { field: 'urinary_urgency_current', label: 'Urinary Urgency Current', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'Catheter',
                            fields: [
                                { field: 'has_genitourinary_catheter', label: 'Catheter', type: 'checkbox' },
                                {
                                    field: 'genitourinary_catheter_type',
                                    label: 'Catheter Type',
                                    type: 'select',
                                    value_field: 'code',
                                    optionsType: 'elimination_catheters',
                                },
                                {
                                    field: 'genitourinary_catheter_last_change',
                                    label: 'Catheter Last Changed',
                                    type: 'date',
                                    maxDate: this.today,
                                },
                                { field: 'urinary_appliance_history', label: 'Urinary Appliance History', type: 'booltext' },
                                { field: 'urinary_appliance_current', label: 'Urinary Appliance Current', type: 'booltext' },
                            ],
                        },
                        {
                            label: 'General',
                            fields: [
                                { field: 'hesitancy', label: 'Hesitancy', type: 'booltext' },
                                { field: 'dysfunction', label: 'Erectile Dysfunction', type: 'booltext' },
                                { field: 'nocturia', label: 'Nocturia', type: 'booltext' },
                                { field: 'dysuria_history', label: 'Dysuria History', type: 'booltext' },
                                { field: 'dysuria', label: 'Dysuria', type: 'booltext' },
                                { field: 'testicular_mass', label: 'Testicular Mass', type: 'booltext' },
                                { field: 'circumcised', label: 'Circumcised', type: 'checkbox' },
                                { field: 'discharge', label: 'Urethral Discharge', type: 'booltext' },
                                { field: 'vaginal_discharge', label: 'Vaginal Discharge', type: 'checkbox' },
                                { field: 'urinary_vaginal_bleeding', label: 'Urinary/Vaginal Bleeding', type: 'checkbox' },
                                { field: 'comments', label: 'Comments', type: 'text' },
                                { field: 'genitourinary_labs_tests_treatments', label: 'Genitourinary Labs/Tests/Treatments', type: 'text' },
                                { field: 'blood_urea_nitrogen_level_3‒6_months_ago', label: 'Blood Urea Nitrogen (BUN) Level 3-6 Months Ago', type: 'text' },
                                { field: 'blood_urea_nitrogen_level_current', label: 'Blood Urea Nitrogen (BUN) Level Current', type: 'text' },
                                { field: 'creatinine_mg/dl', label: 'Creatinine mg/dL', type: 'text' },
                                { field: 'creatinine_mg/dl_3‒6_months_ago', label: 'Creatinine mg/dL 3-6 Months Ago', type: 'text' },
                                { field: 'creatinine_clearance_ml/min_3‒6_months_ago', label: 'Creatinine Clearance ml/min 3-6 Months Ago', type: 'text' },
                                { field: 'creatinine_clearance', label: 'Creatinine Clearance ml/min', type: 'text' },
                                { field: 'dialysis_3‒6_months_ago', label: 'Dialysis 3-6 Months Ago', type: 'checkbox' },
                                { field: 'dialysis_current', label: 'Dialysis Current', type: 'checkbox' },
                                { field: 'dialysis_notes', label: 'Dialysis Notes', type: 'text' },
                                { field: 'glomerular_filtration_rate_3‒6_months_ago', label: 'Glomerular Filtration Rate (GFR) 3-6 Months Ago', type: 'text' },
                                { field: 'glomerular_filtration_rate_current', label: 'Glomerular Filtration Rate (GFR) Current', type: 'text' },
                                { field: 'other_renal_issue', label: 'Other Renal Issue', type: 'text' },
                                { field: 'renal_other_3‒6_months_ago', label: 'Renal Other 3-6 Months Ago', type: 'text' },
                                { field: 'renal_other_current', label: 'Renal Other Current', type: 'text' },
                            ],
                        },
                    ],
                    clinical_findings: [{ label: 'Clinical Findings', fields: [] }],
                    review_of_systems: [
                        {
                            label: null,
                            name: 'unable_to_obtain',
                            fields: [
                                { label: 'Unable to Obtain', field: 'unable_to_obtain' },
                                { label: 'Reason', field: 'reason' },
                            ],
                        },
                        {
                            label: 'Constitutional',
                            name: 'constitutional',
                            fields: [
                                { label: 'Lack of Energy', field: 'lack_of_energy' },
                                {
                                    label: 'Unexpected Weight Gain or Weight Loss',
                                    field: 'unexpected_weight_gain_or_weight_loss',
                                },
                                { label: 'Loss of Appetite', field: 'loss_of_appetite' },
                                { label: 'Fever', field: 'fever' },
                                { label: 'Night Sweats', field: 'night_sweats' },
                                { label: 'Pain in Jaws When Eating', field: 'pain_in_jaws_when_eating' },
                                { label: 'Scalp Tenderness', field: 'scalp_tenderness' },
                                { label: 'Prior Diagnosis of Cancer', field: 'prior_diagnosis_of_cancer' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Ears, Nose, Throat & Mouth',
                            name: 'ears,_nose,_throat_&_mouth',
                            fields: [
                                { label: 'Difficulty with Hearing', field: 'difficulty_with_hearing' },
                                { label: 'Sinus Problems', field: 'sinus_problems' },
                                { label: 'Runny Nose', field: 'runny_nose' },
                                { label: 'Post-Nasal Drip', field: 'post-nasal_drip' },
                                { label: 'Ringing in Ears', field: 'ringing_in_ears' },
                                { label: 'Mouth Sores', field: 'mouth_sores' },
                                { label: 'Loose Teeth', field: 'loose_teeth' },
                                { label: 'Ear Pain', field: 'ear_pain' },
                                { label: 'Nosebleeds', field: 'nosebleeds' },
                                { label: 'Sore Throat', field: 'sore_throat' },
                                { label: 'Facial', field: 'facial' },
                                { label: 'Pain or Numbness', field: 'pain_or_numbness' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Cardiovascular',
                            name: 'cardiovascular',
                            fields: [
                                { label: 'Irregular Heartbeat', field: 'irregular_heartbeat' },
                                { label: 'Heart Palpitations', field: 'heart_palpitations' },
                                { label: 'Racing Heart', field: 'racing_heart' },
                                { label: 'Chest Pains', field: 'chest_pains' },
                                { label: 'Swelling of Feet or Legs', field: 'swelling_of_feet_or_legs' },
                                { label: 'Pain in Legs With Walking', field: 'pain_in_legs_with_walking' },
                                { label: 'Peripheral Edema', field: 'peripheral_edema' },
                                { label: 'Dyspnea When Lying Down', field: 'dyspnea_when_lying_down' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Gastrointestinal',
                            name: 'gastrointestinal',
                            fields: [
                                { label: 'Heartburn', field: 'heartburn' },
                                { label: 'Constipation', field: 'constipation' },
                                { label: 'Intolerance to Certain Foods', field: 'intolerance_to_certain_foods' },
                                { label: 'Diarrhea', field: 'diarrhea' },
                                { label: 'Abdominal Pain', field: 'abdominal_pain' },
                                { label: 'Difficulty Swallowing', field: 'difficulty_swallowing' },
                                { label: 'Nausea', field: 'nausea' },
                                { label: 'Vomiting', field: 'vomiting' },
                                { label: 'Blood in Stools', field: 'blood_in_stools' },
                                {
                                    label: 'Unexplained Change in Bowel Habits',
                                    field: 'unexplained_change_in_bowel_habits',
                                },
                                { label: 'Incontinence', field: 'incontinence' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Genitourinary',
                            name: 'genitourinary',
                            fields: [
                                { label: 'Painful Urination', field: 'painful_urination' },
                                { label: 'Frequent Urination', field: 'frequent_urination' },
                                { label: 'Blood in Urine', field: 'blood_in_urine' },
                                { label: 'Urgency', field: 'urgency' },
                                { label: 'Prostate Problems', field: 'prostate_problems' },
                                { label: 'Bladder Problems', field: 'bladder_problems' },
                                { label: 'Impotence', field: 'impotence' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Musculoskeletal',
                            name: 'musculoskeletal',
                            fields: [
                                { label: 'Joint Pain', field: 'joint_pain' },
                                { label: 'Aching Muscles', field: 'aching_muscles' },
                                { label: 'Shoulder Pain', field: 'shoulder_pain' },
                                { label: 'Swelling of Joints', field: 'swelling_of_joints' },
                                { label: 'Joint Deformities', field: 'joint_deformities' },
                                { label: 'Back Pain', field: 'back_pain' },
                                { label: 'Restricted Range of Motion', field: 'restricted_range_of_motion' },
                                { label: 'Bed or Chair Bound', field: 'bed_or_chair_bound' },
                                { label: 'Gait Disturbance', field: 'gait_disturbance' },
                                { label: 'Plegia', field: 'plegia' },
                                {
                                    label: 'Use of Mobility Equipment/Devices',
                                    field: 'use_of_mobility_equipment_devices',
                                },
                                { label: 'Missing Limbs', field: 'missing_limbs' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Skin, Hair & Breast',
                            name: 'skin_hair_&_breast',
                            fields: [
                                { label: 'Persistent Rash', field: 'persistent_rash' },
                                { label: 'Itching', field: 'itching' },
                                { label: 'New Skin Lesion', field: 'new_skin_lesion' },
                                { label: 'Change in Existing Skin Lesion', field: 'change_in_existing_skin_lesion' },
                                { label: 'Color', field: 'color' },
                                { label: 'Wounds/Drainage', field: 'wounds_drainage' },
                                { label: 'Bruises', field: 'bruises' },
                                { label: 'Sores', field: 'sores' },
                                { label: 'Blisters', field: 'blisters' },
                                { label: 'Growths', field: 'growths' },
                                { label: 'Hair Loss or Increase', field: 'hair_loss_or_increase' },
                                { label: 'Breast Changes', field: 'breast_changes' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Neurologic',
                            name: 'neurologic',
                            fields: [
                                { label: 'Frequent Headaches', field: 'frequent_headaches' },
                                { label: 'Double Vision', field: 'double_vision' },
                                { label: 'Weakness', field: 'weakness' },
                                { label: 'Change in Sensation', field: 'change_in_sensation' },
                                {
                                    label: 'Problems With Walking or Balance',
                                    field: 'problems_with_walking_or_balance',
                                },
                                { label: 'Dizziness', field: 'dizziness' },
                                { label: 'Tremor', field: 'tremor' },
                                { label: 'Loss of Consciousness', field: 'loss_of_consciousness' },
                                { label: 'Uncontrolled motions', field: 'uncontrolled_motions' },
                                { label: 'Episodes of Visual Loss', field: 'episodes_of_visual_loss' },
                                { label: 'Burning', field: 'burning' },
                                { label: 'Seizures', field: 'seizures' },
                                { label: 'Lack of Energy/Fatigue', field: 'lack_of_energy_fatigue' },
                                { label: 'Neuropathy', field: 'neuropathy' },
                                { label: 'Unintelligible Speech', field: 'unintelligible_speech' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Psychiatric',
                            name: 'psychiatric',
                            fields: [
                                { label: 'Insomnia', field: 'insomnia' },
                                { label: 'Irritability', field: 'irritability' },
                                { label: 'Depression', field: 'depression' },
                                { label: 'Nervousness', field: 'nervousness' },
                                { label: 'Anxiety', field: 'anxiety' },
                                { label: 'Recurrent Bad Thoughts', field: 'recurrent_bad_thoughts' },
                                { label: 'Mood Swings', field: 'mood_swings' },
                                { label: 'Hallucinations', field: 'hallucinations' },
                                { label: 'Disoriented', field: 'disoriented' },
                                { label: 'Compulsions', field: 'compulsions' },
                                { label: 'Comatose', field: 'comatose' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Endocrinologic',
                            name: 'endocrinologic',
                            fields: [
                                { label: 'Intolerance to Heat and Cold', field: 'intolerance_to_heath_and_cold' },
                                { label: 'Menstrual Irregularities', field: 'menstrual_irregularities' },
                                {
                                    label: 'Frequent Hunger/Urination/Thirst',
                                    field: 'frequent_hunger_urination_thirst',
                                },
                                { label: 'Changes in Sex Drive', field: 'changes_in_sex_drive' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Hematologic',
                            name: 'hematologic',
                            fields: [
                                { label: 'Easy Bleeding', field: 'easy_bleeding' },
                                { label: 'Easy Bruising', field: 'easy_bruising' },
                                { label: 'Anemia', field: 'anemia' },
                                { label: 'Abnormal Blood Tests', field: 'abnormal_blood_tests' },
                                { label: 'Leukemia', field: 'leukemia' },
                                { label: 'Unexplained Swollen Areas', field: 'unexplained_swollen_areas' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Allergic/Immunologic',
                            name: 'allergic_immunologic',
                            fields: [
                                { label: 'Seasonal Allergies', field: 'seasonal_allergies' },
                                { label: 'Hay Fever Symptoms', field: 'hay_fever_symptoms' },
                                { label: 'Itching', field: 'itching' },
                                { label: 'Frequent Infections', field: 'frequent_infections' },
                                { label: 'Exposure to HIV', field: 'exposure_to_hiv' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                        {
                            label: 'Respiratory',
                            name: 'respiratory',
                            fields: [
                                { label: 'Cough', field: 'cough' },
                                { label: 'Dyspnea', field: 'dyspnea' },
                                { label: 'Wheezing', field: 'wheezing' },
                                { label: 'Other', field: 'other' },
                            ],
                        },
                    ],
                    cqm_template: [
                        {
                            label: 'MIPS / CQM Measure',
                            fields: [
                                {
                                    field: 'title',
                                    label: 'Title',
                                    type: 'select',
                                    required: true,
                                    query: 'cqm_templates',
                                },
                                {
                                    field: 'quality_id',
                                    label: 'MIPS Code',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Template Info',
                            fields: [
                                {
                                    field: 'descriptiohn',
                                    label: 'Description',
                                    type: 'text',
                                },
                                {
                                    field: 'data_source_url',
                                    label: 'Data Source URL',
                                    type: 'text',
                                },
                                {
                                    field: 'additional_information',
                                    label: 'Additional Information',
                                    type: 'text',
                                },
                            ],
                        },
                    ],
                    care_plan_problem_updates: [],
                    visit_task_lists: [],
                    document_upload: [],
                    veterans_assessment: [
                        {
                            label: 'Did you (or your spouse or family member) serve in the military?',
                            fields: [
                                {
                                    field: 'patient',
                                    label: 'Patient',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'active_duty_service',
                                    label: 'Did You Serve on Active Duty?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'combat_assignment',
                                    label: 'Did Your Service Include Combat, Dangerous, or Traumatic Assignments?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'dd214_copy',
                                    label: 'Do You Have a Copy of Your DD214 Discharge Papers?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'spouse_active_duty',
                                    label: 'Did Your Spouse Serve on Active Duty?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                                {
                                    field: 'family_military_service',
                                    label: 'Do You Have Any Immediate Family Members That Served or Are Serving in the Military?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank',
                                },
                            ],
                        },
                        {
                            label: '',
                            fields: [
                                {
                                    field: 'ptsd_diagnosis_date',
                                    label: 'PTSD Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'ptsd_medications_and_how_helpful',
                                    label: 'PTSD Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'ptsd_mental_health_counseling_when_and_how_helpful_reconciliation',
                                    label: 'PTSD Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'anxiety_diagnosis_date',
                                    label: 'Anxiety Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'anxiety_medications_and_how_helpful',
                                    label: 'Anxiety Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'anxiety_mental_health_counseling_when_and_how_helpful',
                                    label: 'Anxiety Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'depression_diagnosis_date',
                                    label: 'Depression Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'depression_medications_and_how_helpful',
                                    label: 'Depression Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'depression_mental_health_counseling_when_and_how_helpful',
                                    label: 'Depression Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'psychosis_diagnosis_date',
                                    label: 'Psychosis Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'psychosis_medications_and_how_helpful',
                                    label: 'Psychosis Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'psychosis_mental_health_counseling_when_and_how_helpful',
                                    label: 'Psychosis Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'suicidal_ideation_diagnosis_date',
                                    label: 'Suicidal Ideation Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'suicidal_ideation_medications_and_how_helpful',
                                    label: 'Suicidal Ideation Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'suicidal_ideation_mental_health_counseling_when_and_how_helpful',
                                    label: 'Suicidal Ideation Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'substance_abuse_diagnosis_date',
                                    label: 'Substance Abuse Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'substance_abuse_medications_and_how_helpful',
                                    label: 'Substance Abuse Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'substance_abuse_mental_health_counseling_when_and_how_helpful',
                                    label: 'Substance Abuse Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'military_related_sexual_trauma_diagnosis_date',
                                    label: 'Military Related Sexual Trauma Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'military_related_sexual_trauma_medications_and_how_helpful',
                                    label: 'Military Related Sexual Trauma Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'military_related_sexual_trauma_mental_health_counseling_when_and_how_helpful',
                                    label: 'Military Related Sexual Trauma Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'frostbite_cold_injury_diagnosis_date',
                                    label: 'Frostbite/Cold Injury Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'frostbite_cold_injury_medications_and_how_helpful',
                                    label: 'Frostbite/Cold Injury Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'frostbite_cold_injury_mental_health_counseling_when_and_how_helpful',
                                    label: 'Frostbite/Cold Injury Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'malignant_diseases_diagnosis_date',
                                    label: 'Malignant Diseases Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'malignant_diseases_medications_and_how_helpful',
                                    label: 'Malignant Diseases Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'malignant_diseases_mental_health_counseling_when_and_how_helpful',
                                    label: 'Malignant Diseases Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'traumatic_injuries_diagnosis_date',
                                    label: 'Traumatic Injuries Diagnosis Date',
                                    type: 'text',
                                },
                                {
                                    field: 'traumatic_injuries_medications_and_how_helpful',
                                    label: 'Traumatic Injuries Medications and How Helpful',
                                    type: 'text',
                                },
                                {
                                    field: 'traumatic_injuries_mental_health_counseling_when_and_how_helpful',
                                    label: 'Traumatic Injuries Mental Health Counseling – When and How Helpful',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Services Issues',
                            fields: [
                                {
                                    field: 'psychological_service_related_issues_history_describe',
                                    label: 'Psychological Service Related Issues History, Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'psychological_service_related_issues_current_describe',
                                    label: 'Psychological Service Related Issues Current, Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'physical_service_related_issues_history_describe',
                                    label: 'Physical Service Related Issues History, Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'physical_service_related_issues_current_describe',
                                    label: 'Physical Service Related Issues Current, Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'spiritual_service_related_issues_history_describe',
                                    label: 'Spiritual Service Related Issues History, Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'spiritual_service_related_issues_current_describe',
                                    label: 'Spiritual Service Related Issues Current, Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'if_no_which_areas_do_we_need_to_focus_on',
                                    label: 'If No, Which Areas Do We Need To Focus On?',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'War Details',
                            fields: [
                                {
                                    field: 'war_era_period',
                                    label: 'War Era/Period',
                                    type: 'select',
                                    optionsType: 'war_era_options',
                                },
                                {
                                    field: 'war_era_period_other_describe',
                                    label: 'War Era/Period Other Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'how_did_you_come_to_serve',
                                    label: 'How Did You Come To Serve',
                                    type: 'select',
                                    optionsType: 'veteran_assessment_come_to_serve_options',
                                },
                                {
                                    field: 'length_of_service',
                                    label: 'Length of service',
                                    type: 'text',
                                },
                                {
                                    field: 'highest_rank',
                                    label: 'Highest Rank',
                                    type: 'text',
                                },
                                {
                                    field: 'any_exposures',
                                    label: 'Any Exposures',
                                    type: 'select',
                                    optionsType: 'any_exposure_options',
                                },
                                {
                                    field: 'prisoner_of_war_duration_of_captivity',
                                    label: 'Prisoner of War Duration of Captivity',
                                    type: 'text',
                                },
                                {
                                    field: 'prisoner_of_war_location_of_captivity',
                                    label: 'Prisoner of War Location of Captivity',
                                    type: 'text',
                                },
                                {
                                    field: 'prisoner_of_war_solitary_or_with_others',
                                    label: 'Prisoner of War Solitary or With Others',
                                    type: 'select',
                                    optionsType: 'prisoner_of_war_solitary_with_others_options',
                                },
                                {
                                    field: 'overall_how_do_you_view_your_experience_in_the_military',
                                    label: 'Overall, How Do You View Your Experience in the Military?',
                                    type: 'text',
                                },
                            ],
                        },
                        {
                            label: 'Additional Information',
                            fields: [
                                {
                                    field: 'additional_veteran_assessment_symptoms',
                                    label: 'Additional Veteran Assessment Symptoms',
                                    type: 'multiselect',
                                    optionsType: 'additional_veteran_assessment_symptom_options',
                                },
                                {
                                    field: 'additional_veteran_assessment_symptoms_other_describe',
                                    label: 'Additional Veteran Assessment Symptoms Other Describe',
                                    type: 'text',
                                },
                                {
                                    field: 'receiving_va_compensation_benefits',
                                    label: 'Receiving VA Compensation Benefits',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'applied_for_va_benefits_and_denied',
                                    label: 'Applied for VA Benefits and Denied',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'homeless_veteran',
                                    label: 'Homeless Veteran',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'in_contact_with_other_war_veterans_from_troop',
                                    label: 'In Contact With Other War Veterans From Troop',
                                    type: 'checkbox',
                                },
                                {
                                    field: 'service_connected_condition',
                                    label: 'Do You Have a Service-Connected Condition?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank'
                                },
                                {
                                    field: 'va_medications',
                                    label: 'Do You Get Your Medications from VA?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank'
                                },
                                {
                                    field: 'va_hospital_or_clinic',
                                    label: 'What Is the Name of Your VA Hospital or Clinic?',
                                },
                                {
                                    field: 'va_physician_info',
                                    label: 'What Is the Name and Contact Information of Your VA Physician or Primary Care Provider?',
                                },
                                {
                                    field: 'benefits_eligibility',
                                    label: 'Would You Like to Talk with Someone About Benefits You or Your Family Might Be Eligible to Receive?',
                                    type: 'select',
                                    optionsType: 'yes_no_blank'
                                },
                                {
                                    field: 'staff_volunteers_to_have_military_experience',
                                    label: 'If Available, Would You Like Your Staff/Volunteers To Have Military Experience?',
                                    type: 'select',
                                    optionsType: 'military_experience_options',
                                },
                                {
                                    field: 'veteran_resources_needed',
                                    label: 'Veteran Resources Needed',
                                    type: 'text',
                                },
                            ],
                        },
                    ],
                };
            };
            ChartingTemplateService.prototype.getPAEViewFormDefinition = function (chartStore) {
                var _this = this;
                var formDefinition = [];
                var fieldDefinition = this.getFieldDefinitions();
                var formModel = chartStore.model;
                var promiseChain = [];
                var paeFormDefinition = [
                    {
                        title: 'General Details',
                        model: 'general',
                        name: 'general',
                        componentName: 'consolo-charting-general',
                        modelData: true,
                        field_definition: [{
                                fields: [
                                    { field: 'time_of_visit', type: 'datetime', label: 'Time of Visit', required: true, locked: true },
                                    { field: 'effective_date', type: 'datetime', label: 'Effective Date', required: true, locked: true },
                                    { field: 'user', displayField: "name", label: 'Owner', required: true, locked: true },
                                    { field: 'role_id', label: 'Discipline', type: 'select', optionsType: 'base_roles', required: true, locked: true },
                                    { field: 'encounter_type', displayField: "display_name", label: 'Encounter Type', required: false, locked: true },
                                    { field: 'facility_id', label: 'Facility' },
                                    { field: 'two_patient_identifiers_used', label: 'Two Patient Identifiers Used' },
                                    { field: 'source_of_information', optionsType: 'source_of_information', value_field: 'value', text_field: "text", type: "select", options: this.source_of_information, label: 'Source of Information' },
                                    { field: 'other', label: 'Other' },
                                    { field: 'purpose_of_visit', type: 'multiselect', optionsType: 'purpose_of_visit_options', label: 'Purpose of Visit' },
                                    { field: 'purpose_of_visit_other', label: 'Purpose of Visit Other' },
                                ]
                            }],
                        isEmpty: false
                    },
                    {
                        title: 'Patient Time',
                        model: 'patient_time',
                        name: 'patient_time',
                        componentName: 'consolo-charting-patient-time',
                        field_definition: fieldDefinition['patient_time'],
                        isEmpty: false
                    },
                    {
                        title: 'Electronic Visit Verification',
                        componentName: 'consolo-charting-evv',
                        name: 'electronic_visit_verification',
                        model: 'electronic_visit_verification',
                        field_definition: [
                            {
                                fields: [
                                    { field: 'longitude', label: 'Location' },
                                    { field: 'latitude', label: 'Location' },
                                    { field: 'signature_pad_png_base64', label: 'Signature' },
                                ],
                            },
                        ],
                        isEmpty: false
                    },
                    {
                        title: 'History of Present Illness (HPI)',
                        model: 'history_of_present_illness',
                        customComponent: true,
                        additionalSection: true,
                        field_definition: [
                            {
                                label: 'General HPI',
                                fields: [
                                    { field: 'locations', label: 'Location', type: 'text' },
                                    { field: 'quality', label: 'Quality', type: 'text' },
                                    { field: 'severity', label: 'Severity', type: 'text' },
                                    { field: 'duration', label: 'Duration', type: 'text' },
                                    { field: 'timing', label: 'Timing', type: 'text' },
                                    { field: 'context', label: 'Context', type: 'text' },
                                    { field: 'modifying_factors', label: 'Modifying Factors', type: 'text' },
                                    { field: 'signs', label: 'Associated Signs / Symptoms', type: 'text' },
                                    { field: 'associated_signs_3_to_6_months_ago', label: 'Associated Signs / Symptoms 3-6 Months Ago', type: 'text' },
                                    { field: 'current_medications_not_at_location_of_care', label: 'Current Medications Not at Location of Care (Informational only)', type: 'text' },
                                    { field: 'current_medications_at_location_of_care', label: 'Current Medications at Location of Care (Informational only)', type: 'text' },
                                    { field: 'current_diagnoses', label: 'Current Diagnoses', type: 'text' },
                                    { field: 'diagnosis_information_obtained_from', label: 'Diagnosis Information Obtained from', type: 'multiselect', optionsType: 'diagnosis_information_obtained_options' },
                                    { field: 'taking_supplements', label: 'Taking Supplements', type: 'checkbox' },
                                    { model: 'patient_history', field: 'family_medical_history_non‒contributory', label: 'Family Medical History Non-Contributory', type: 'checkbox' },
                                ],
                            },
                            {
                                field: 'allergy_table',
                                type: 'table',
                                cols: ['Allergies Reported', 'Allergy Reactions Report'],
                                rows: { model: 'allergy_table' }
                            }
                        ]
                    },
                    {
                        title: 'Explanation of Service',
                        model: 'explanation_of_services',
                        customComponent: true,
                        field_definition: [
                            {
                                fields: [
                                    {
                                        field: 'people_present',
                                        label: 'Present at Meeting (list all and roles)',
                                        type: 'text',
                                    },
                                    {
                                        field: 'participating_events', label: 'Why Hospice Now? Precipitating Events', type: 'text'
                                    },
                                    {
                                        field: 'goal_of_care', label: 'Goals of Care', type: 'text'
                                    },
                                    {
                                        field: 'hospitalizations_current', label: 'Hospitalizations Current', type: 'text'
                                    },
                                    {
                                        field: 'hospitalizations_history', label: 'Hospitalizations 3-6 Months Ago', type: 'text'
                                    },
                                    {
                                        field: 'patient_has_medicare_part_d', label: 'Patient has Medicare Part D', type: 'checkbox'
                                    },
                                    { field: 'services', label: 'Hospice Services', type: 'text' },
                                    { field: 'phone_24_hour', label: '24 Hour Number', type: 'text' },
                                    { field: 'benefits', label: 'Medicare/Medicaid Benefits', type: 'text' },
                                    { field: 'directive', label: 'Advance Directives ', type: 'text' },
                                    { field: 'info_pack', label: 'Patient Info Pack', type: 'text' },
                                    { field: 'team_role', label: 'Role of Team', type: 'text' },
                                    { field: 'responsibilities', label: 'Rights/Responsibilities', type: 'text' },
                                    { field: 'privacy', label: 'HIPAA/Privacy', type: 'text' },
                                    { field: 'phone_customer_relations', label: 'Agency Customer Relations Phone', type: 'text' },
                                    { field: 'phone_state_compliant', label: 'State Complaint Phone', type: 'text' },
                                    {
                                        field: 'was_patient_asked_about_cpr',
                                        label: 'Was the patient/responsible party asked about preference regarding the use of cardiopulmonary resuscitation (CPR)?',
                                        type: 'select',
                                        optionsType: 'discussion_options',
                                    },
                                    {
                                        field: 'date_patient_was_asked_about_cpr',
                                        label: 'Date Asked About CPR',
                                        type: 'date',
                                    },
                                    {
                                        field: 'was_patient_asked_about_treatments_other_than_cpr',
                                        label: 'Was the patient/responsible party asked about preferences regarding life-sustaining treatments other than CPR?',
                                        type: 'select',
                                        optionsType: 'discussion_options',
                                    },
                                    {
                                        field: 'date_patient_was_asked_about_treatments_other_than_cpr',
                                        label: 'Date Asked About Treatment Other than CPR',
                                        type: 'date',
                                    },
                                    {
                                        field: 'was_patient_asked_about_hospitalization',
                                        label: 'Was the patient/responsible party asked about preference regarding hospitalization?',
                                        type: 'select',
                                        optionsType: 'discussion_options',
                                    },
                                    {
                                        field: 'date_patient_was_asked_about_hospitalization',
                                        label: 'Date Patient Was Asked About Hospitalization',
                                        type: 'date',
                                    },
                                    {
                                        field: 'hospital_avoidance',
                                        label: 'Requests Avoidance of Hospitalization',
                                        type: 'booltext',
                                    },
                                    { field: 'other', label: 'Other', type: 'text' },
                                    {
                                        field: 'has_understanding',
                                        label: 'Patient/Family verbalize full understanding of Hospice Care, ' +
                                            'particularly the Palliative rather than Curative nature of treatment',
                                        type: 'checkbox',
                                    },
                                    {
                                        field: 'no_understanding',
                                        label: 'Summary/Discussion',
                                        type: 'text',
                                    },
                                    {
                                        field: 'family_confidence',
                                        label: 'Family Confidence In Ability To Safely Care For Loved One At Home',
                                        type: 'text',
                                    },
                                    {
                                        field: 'special_preferences',
                                        label: 'Special Preferences For Life Closure',
                                        type: 'text',
                                    },
                                    {
                                        field: 'consent',
                                        label: 'Consent/Election of Benefits Signed/Witnessed',
                                        type: 'booltext',
                                    },
                                ],
                            },
                            {
                                label: 'History of Hospitalizations',
                                field: 'hospitalizations_history_table',
                                type: 'table',
                                cols: ['Hospitalization Date', 'Hospitalization Location', 'Hospitalization Reason', 'Hospitalizations Comment'],
                                rows: { model: 'hospitalizations_history_table' }
                            }
                        ],
                    },
                    {
                        title: 'Nutrition / Hydration',
                        model: 'body_systems_nutrition_hydration_findings',
                        customComponent: true,
                        field_definition: [
                            {
                                type: 'table',
                                cols: ['Nutrition / Hydration', 'Curent', '3-6 months ago', 'Notes'],
                                rows: [
                                    {
                                        label: 'Albumin',
                                        current: { model: 'albumin_current' },
                                        history: { model: 'albumin_history' },
                                        notes: { model: 'albumin_notes' }
                                    },
                                    {
                                        label: 'Eating',
                                        current: {
                                            model: 'eating_current', type: 'select',
                                            optionsType: 'eating_options',
                                        },
                                        history: {
                                            model: 'eating_history', type: 'select',
                                            optionsType: 'eating_options',
                                        },
                                        notes: { model: 'eating_notes' }
                                    },
                                    {
                                        label: 'Percent of Meals Consumed',
                                        current: { model: 'percent_of_meals_consumed' },
                                        history: { model: 'percent_of_meals_consumed_history' },
                                        notes: { model: 'percent_of_meals_consumed_notes' },
                                    },
                                    {
                                        label: 'Indications of Weight Loss',
                                        current: {
                                            model: 'indications_of_weight_loss',
                                            type: 'multiselect',
                                            optionsType: 'indications_of_weight_loss_options',
                                        },
                                        history: {
                                            model: 'indications_of_weight_loss_history',
                                            type: 'multiselect',
                                            optionsType: 'indications_of_weight_loss_options',
                                        },
                                        notes: { model: 'other_indications_of_weight_loss' },
                                    },
                                ],
                            },
                            {
                                type: 'table',
                                cols: ['Diet', 'Curent', '3-6 months ago', 'Notes'],
                                rows: [
                                    {
                                        label: 'Amount of Time to Feed',
                                        current: { model: 'amount_of_time_to_feed' },
                                        history: { model: 'amount_of_time_to_feed_history' },
                                        notes: { model: 'amount_of_time_to_feed_notes' }
                                    },
                                    {
                                        label: 'Appetite',
                                        current: {
                                            model: 'note_nutrition_appetite_current',
                                            type: 'select',
                                            optionsType: 'nutrition_appetites_options'
                                        },
                                        history: {
                                            model: 'note_nutrition_appetite_history',
                                            type: 'select',
                                            optionsType: 'nutrition_appetites_options'
                                        },
                                        notes: { model: 'note_nutrition_appetite' },
                                    },
                                ]
                            },
                            {
                                type: 'table',
                                cols: ['Additional Findings', 'Current', '3-6 months ago', 'Discussion'],
                                rows: [
                                    {
                                        label: 'Feeding Tube',
                                        current: {
                                            model: 'note_nutrition_tube',
                                            optionsType: 'feeding_tubes',
                                            value_field: 'code',
                                            type: 'multiselect',
                                        },
                                        history: {
                                            model: 'note_nutrition_tube_history',
                                            optionsType: 'feeding_tubes',
                                            value_field: 'code',
                                            type: 'multiselect',
                                        },
                                        notes: { model: 'note_nutrition_tube_discussion' },
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Pain Observation',
                        model: 'pain_observations',
                        customComponent: true,
                        allowMultiple: true,
                        field_definition: [
                            {
                                label: 'General',
                                fields: [
                                    {
                                        label: 'Pain Verbal/Non-Verbal Assessment Caregiver Input',
                                        field: 'has_pain_assessment_caregiver_input',
                                        type: 'checkbox'
                                    },
                                    {
                                        field: 'other_indications_of_pain',
                                        label: 'Other Indications of Pain',
                                        type: 'multiselect',
                                        optionsType: 'other_indications_of_pain',
                                    }, {
                                        field: 'pain_ratings',
                                        label: 'Pain 3-6 Months Ago',
                                        type: 'rating',
                                        optionsType: 'pain_ratings',
                                        minZero: true,
                                        max: 11,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Neurology',
                        model: 'body_systems_neurology_findings',
                        customComponent: true,
                        field_definition: [
                            {
                                fields: [
                                    {
                                        field: 'has_neuro_dementia',
                                        label: 'Patient has Advanced Dementia?',
                                        type: 'checkbox',
                                    },
                                    { field: 'note_neuro_dementia', label: 'Patient has Advanced Dementia Notes' },
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Nursing Related Observation',
                        model: 'nursing_related_observations',
                        customComponent: true,
                        field_definition: [
                            {
                                fields: [
                                    {
                                        field: 'safety_infection_current',
                                        label: 'Infections Current',
                                        type: 'multiselect',
                                        optionsType: 'infection_statuses',
                                    },
                                    {
                                        field: 'safety_infection_history',
                                        label: 'Infections 3-6 Months Ago',
                                        type: 'multiselect',
                                        optionsType: 'infection_statuses',
                                    },
                                    {
                                        field: 'note_safety_infection',
                                        label: 'Infections Notes',
                                    },
                                ],
                            },
                            {
                                label: 'Labs Performed',
                                fields: [
                                    { field: 'has_no_labs_performed', label: 'No Labs Performed', type: 'checkbox' },
                                    { field: 'lab_sodium', label: 'Sodium' },
                                    { field: 'lab_site', label: 'Lab Site', type: 'text' },
                                ],
                            },
                        ]
                    },
                    {
                        title: 'Hematologic / Lymphatic / Immunologic',
                        model: 'body_systems_hli',
                        customComponent: true,
                        field_definition: [{
                                fields: [
                                    { field: 'hematologic_pt', label: 'PT', type: 'number' },
                                    { field: 'hematologic_inr', label: 'INR', type: 'number' },
                                    { field: 'history_of_gi_bleed', label: 'History of GI Bleed', type: 'checkbox' },
                                    { field: 'history_of_gi_bleed_describe', label: 'History of GI Bleed Describe' },
                                    { field: 'history_of_ascites', label: 'History of Ascites', type: 'checkbox' },
                                    { field: 'history_of_ascites_describe', label: 'History of Ascites Describe' },
                                    { field: 'bilirubin', label: 'Bilirubin mg/dl' },
                                ]
                            }, {
                                type: 'table',
                                cols: ['Allergic / Immunologic', 'Current', '3-6 months ago'],
                                rows: [
                                    {
                                        label: 'HIV CD4 Count cells/mm³',
                                        current: { model: 'hiv_cd4_count_current' },
                                        history: { model: 'hiv_cd4_count_before' },
                                    },
                                    {
                                        label: 'HIV Viral Load copies/ml',
                                        current: { model: 'hiv_viral_load_current' },
                                        history: { model: 'hiv_viral_load_before' },
                                    },
                                    {
                                        label: 'HIV Highly Active Antiretroviral Therapy',
                                        current: { model: 'hiv_therapy_current' },
                                        history: { model: 'hiv_therapy_before' },
                                    },
                                    {
                                        label: 'HIV Opportunistic Infections',
                                        current: {
                                            model: 'hiv_opportunistic_infections_current',
                                            type: 'multiselect',
                                            optionsType: 'hiv_opportunistic_infections',
                                        },
                                        history: {
                                            model: 'hiv_opportunistic_infections_before',
                                            type: 'multiselect',
                                            optionsType: 'hiv_opportunistic_infections',
                                        },
                                    }
                                ]
                            }]
                    },
                    {
                        title: 'Oncology',
                        model: 'body_systems_hli',
                        customComponent: true,
                        field_definition: [{
                                fields: [
                                    {
                                        field: 'cancer_stage',
                                        label: 'Cancer Stage',
                                        type: 'select',
                                        optionsType: 'cancer_stages',
                                    },
                                    { field: 'cancer_metastasis', label: 'Cancer Metastasis' },
                                    {
                                        field: 'cancer_recent_treatment',
                                        label: 'Cancer Recent Treatment',
                                        type: 'multiselect',
                                        optionsType: 'cancer_treatments',
                                    },
                                    { field: 'cancer_primary_site', label: 'Cancer Primary Site' },
                                    { field: 'cancer_other', label: 'Cancer Other' },
                                    { field: 'cancer_comments', label: 'Comments', type: 'text' },
                                ]
                            }]
                    },
                    {
                        title: 'Cardiovascular',
                        model: 'body_systems_cardiac_circulatory_findings',
                        customComponent: true,
                        field_definition: [{
                                type: 'table',
                                cols: ['Cardiovascular', 'Current', '3-6 months ago', 'Notes'],
                                rows: [{
                                        label: 'Bleeding',
                                        current: {
                                            model: 'cbleeding_current',
                                            type: 'multiselect',
                                            optionsType: 'cbleeding_options'
                                        },
                                        history: {
                                            model: 'cbleeding_before',
                                            type: 'multiselect',
                                            optionsType: 'cbleeding_options'
                                        },
                                        notes: { model: 'note_cbleeding' }
                                    }, {
                                        label: 'Edema Intensity',
                                        current: {
                                            model: 'functioning_edema_intensity',
                                            type: 'rating',
                                            optionsType: 'edema_intensities'
                                        },
                                        history: {
                                            model: 'functioning_edema_intensity_before',
                                            type: 'rating',
                                            optionsType: 'edema_intensities',
                                        },
                                        notes: { model: 'note_functioning_edema' }
                                    }, {
                                        label: 'Stroke',
                                        current: { model: 'cstroke_current' },
                                        history: { model: 'cstroke_before' },
                                        notes: { model: 'cstroke_notes' }
                                    }]
                            }, {
                                fields: [
                                    { field: 'has_cheart_failure', label: 'Heart Failure?', type: 'checkbox' },
                                    {
                                        field: 'cheart_failure_type',
                                        label: 'Heart Failure Type',
                                        type: 'select',
                                        optionsType: 'cheart_failure_options',
                                    },
                                    { field: 'ejection_fraction_percentage', label: 'Ejection Fraction Percentage' },
                                    { field: 'has_cstage_d', label: 'Stage D', type: 'checkbox' },
                                ]
                            }, {
                                label: 'Edema',
                                fields: [{
                                        type: 'body site',
                                        field: 'body_site',
                                        style: {
                                            width: "100%"
                                        }
                                    }]
                            }]
                    },
                    {
                        title: 'Vital Sign Measurement',
                        model: 'vital_sign_measurements',
                        allowMultiple: true,
                        customComponent: true,
                        field_definition: [{
                                label: 'Patient Measurements',
                                fields: [
                                    {
                                        field: 'height_history',
                                        label: 'Height 3-6 Months Ago',
                                    },
                                    {
                                        field: 'weight_history',
                                        label: 'Weight 3-6 Months Ago',
                                    },
                                    {
                                        field: 'bmi_history',
                                        label: 'BMI 3-6 Months Ago',
                                        type: 'bmi',
                                    },
                                    {
                                        field: 'mid_muscle_area',
                                        label: 'Mid Muscle Area Current (cm²)'
                                    },
                                    {
                                        field: 'mid_muscle_area_history',
                                        label: 'Mid Muscle Area 3-6 Months Ago (cm²)'
                                    }
                                ],
                            }, {
                                label: 'O2',
                                fields: [
                                    { field: 'o2_needed_at_location_care', label: 'Oxygen Needed at Location of Care', type: 'checkbox' },
                                    { field: 'has_o2_at_location_care', label: 'Has Oxygen at Location of Care', type: 'checkbox' },
                                ],
                            }, {
                                label: 'Performance Scales',
                                fields: [
                                    {
                                        field: 'palliative_performance_history',
                                        label: 'Palliative Performance Scale 3-6 Months Ago ',
                                        type: 'select',
                                        optionsType: 'palliative_scale',
                                    },
                                    { field: 'performance_scale_notes', label: 'Performance Scale Notes', type: 'text' },
                                ],
                            }],
                    },
                    {
                        title: 'Musculoskeletal',
                        model: 'body_systems_activity_musculoskeletal_findings',
                        customComponent: true,
                        field_definition: [
                            {
                                type: 'table',
                                cols: ['Activities in Daily Living', 'Current', '3-6 months ago', 'Notes'],
                                rows: [{
                                        label: 'Bathing',
                                        current: { model: 'activity_bathing_level_of_functioning_id', type: 'select', optionsType: 'level_of_functionings' },
                                        history: { model: 'bathing_history', type: 'select', optionsType: 'level_of_functionings' },
                                        notes: { model: 'note_activity_bathing_level_of_functioning' }
                                    }, {
                                        label: 'Dressing',
                                        current: { model: 'activity_dressing_level_of_functioning_id', type: 'select', optionsType: 'level_of_functionings' },
                                        history: { model: 'dressing_history', type: 'select', optionsType: 'level_of_functionings' },
                                        notes: { model: 'note_activity_dressing_level_of_functioning' }
                                    }, {
                                        label: 'Transfers',
                                        current: { model: 'activity_transfers_level_of_functioning_id', type: 'select', optionsType: 'level_of_functionings' },
                                        history: { model: 'transfers_history', type: 'select', optionsType: 'level_of_functionings' },
                                        notes: { model: 'note_activity_transfers_level_of_functioning' }
                                    }, {
                                        label: 'Toileting',
                                        current: { model: 'activity_toileting_level_of_functioning_id', type: 'select', optionsType: 'level_of_functionings' },
                                        history: { model: 'toileting_history', type: 'select', optionsType: 'level_of_functionings' },
                                        notes: { model: 'note_activity_toileting_level_of_functioning' }
                                    }]
                            },
                            {
                                type: 'table',
                                cols: ['Other', 'Current', '3-6 months ago', 'Notes'],
                                rows: [{
                                        label: 'Fractures',
                                        current: { model: 'note_fractures_current' },
                                        history: { model: 'note_fractures_history' },
                                        notes: { model: 'note_activity_history_of_fractures' }
                                    }, {
                                        label: 'Other Musculoskeletal',
                                        current: { model: 'note_other_musculoskeletal_current' },
                                        history: { model: 'note_other_musculoskeletal_history' },
                                        notes: { model: 'note_other_musculoskeletal' }
                                    }]
                            },
                            {
                                type: 'table',
                                cols: ['Mobility', 'Current', '3-6 months ago', 'Notes'],
                                rows: [{
                                        label: 'Mobility',
                                        current: { model: 'mobility_current', type: 'select', optionsType: 'level_of_functionings' },
                                        history: { model: 'mobility_history', type: 'select', optionsType: 'level_of_functionings' },
                                        notes: { model: 'note_mobility' }
                                    }]
                            },
                            {
                                label: 'Patient Used Mobility Equipment',
                                fields: [{
                                        field: 'note_equipment_and_devices',
                                        label: 'Equipment and Devices Notes'
                                    }]
                            },
                            {
                                label: 'Needs at Location of Care',
                                fields: [
                                    { field: 'needs_hospital_bed_needs_at_location_of_care', label: 'Hospital Bed', type: 'checkbox' },
                                    {
                                        field: 'note_needs_hospital_bed_at_location_of_care',
                                        label: 'Hospital Bed Notes'
                                    },
                                    { field: 'needs_other_mobility_equipment_1_at_location_of_care', label: 'Other Mobility Equipment 1', type: 'checkbox' },
                                    {
                                        field: 'note_needs_other_mobility_equipment_1_at_location_of_care',
                                        label: 'Other Mobility Equipment 1 Notes'
                                    },
                                    { field: 'needs_other_mobility_equipment_2_at_location_of_care', label: 'Other Mobility Equipment 2', type: 'checkbox' },
                                    {
                                        field: 'note_needs_other_mobility_equipment_2_at_location_of_care',
                                        label: 'Other Mobility Equipment 2 Notes'
                                    },
                                    { field: 'needs_walker_at_location_of_care', label: 'Walker', type: 'checkbox' },
                                    {
                                        field: 'note_needs_walker_at_location_of_care',
                                        label: 'Walker Notes'
                                    },
                                    { field: 'needs_wheelchair_at_location_of_care', label: 'Wheelchair', type: 'checkbox' },
                                    {
                                        field: 'note_needs_wheelchair_at_location_of_care',
                                        label: 'Wheelchair Notes'
                                    },
                                    { field: 'needs_bedside_commode_at_location_of_care', label: 'Bedside Commode', type: 'checkbox' },
                                    {
                                        field: 'note_needs_bedside_commode_at_location_of_care',
                                        label: 'Bedside Commode Notes'
                                    },
                                    {
                                        field: 'note_needs_at_location_of_care',
                                        label: 'Needs at Location of Care Notes'
                                    },
                                ]
                            },
                            {
                                label: 'Has at Location of Care',
                                fields: [
                                    { field: 'has_hospital_bed_at_location_of_care', label: 'Hospital Bed', type: 'checkbox' },
                                    {
                                        field: 'note_hospital_bed_at_location_of_care',
                                        label: 'Hospital Bed Notes'
                                    },
                                    { field: 'has_other_mobility_equipment_1_at_location_of_care', label: 'Other Mobility Equipment 1', type: 'checkbox' },
                                    {
                                        field: 'note_other_mobility_equipment_1_at_location_of_care',
                                        label: 'Other Mobility Equipment 1 Notes'
                                    },
                                    { field: 'has_other_mobility_equipment_2_at_location_of_care', label: 'Other Mobility Equipment 2', type: 'checkbox' },
                                    {
                                        field: 'note_other_mobility_equipment_2_at_location_of_care',
                                        label: 'Other Mobility Equipment 2 Notes'
                                    },
                                    { field: 'has_walker_at_location_of_care', label: 'Walker', type: 'checkbox' },
                                    {
                                        field: 'note_walker_at_location_of_care',
                                        label: 'Walker Notes'
                                    },
                                    { field: 'has_wheelchair_at_location_of_care', label: 'Wheelchair', type: 'checkbox' },
                                    {
                                        field: 'has_at_location_of_care_notes',
                                        label: 'Has at Location of Care Notes',
                                        type: 'text'
                                    },
                                    { field: 'has_bedside_commode_at_location_of_care', label: 'Bedside Commode', type: 'checkbox' },
                                    {
                                        field: 'note_bedside_commode_at_location_of_care',
                                        label: 'Bedside Commode Notes'
                                    },
                                    {
                                        field: 'note_wheelchair_at_location_of_care',
                                        label: 'Wheelchair Notes'
                                    },
                                ]
                            },
                            {
                                label: 'Fracture',
                                fields: [{
                                        type: 'body site',
                                        field: 'body_site',
                                        style: {
                                            width: "100%"
                                        }
                                    }]
                            }
                        ]
                    }, {
                        title: 'Genitourinary',
                        model: 'body_systems_genitourinary',
                        additionalSection: true,
                        customComponent: true,
                        field_definition: [{
                                type: 'table',
                                cols: ['General', 'Current', '3-6 months ago'],
                                rows: [{
                                        label: 'Blood Urea Nitrogen (BUN) Level',
                                        current: { model: 'blood_urea_nitrogen_level_current' },
                                        history: { model: 'blood_urea_nitrogen_level_3‒6_months_ago' },
                                    }, {
                                        label: 'Creatinine mg/dL ',
                                        current: { model: 'creatinine_mg/dl' },
                                        history: { model: 'creatinine_mg/dl_3‒6_months_ago' },
                                    }, {
                                        label: 'Creatinine Clearance ml/min',
                                        current: { model: 'creatinine_clearance' },
                                        history: { model: 'creatinine_clearance_ml/min_3‒6_months_ago' },
                                    }, {
                                        label: 'Glomerular Filtration Rate (GFR)',
                                        current: { model: 'glomerular_filtration_rate_current' },
                                        history: { model: 'glomerular_filtration_rate_3‒6_months_ago' },
                                    }]
                            }, {
                                fields: [
                                    { field: 'renal_other_current', label: 'Renal Other Current', type: 'text' },
                                    { field: 'renal_other_3‒6_months_ago', label: 'Renal Other 3-6 Months Ago', type: 'text' },
                                    { field: 'other_renal_issue', label: 'Other Renal Issue', type: 'text' },
                                    { field: 'dialysis_current', label: 'Dialysis Current', type: 'checkbox' },
                                    { field: 'dialysis_3‒6_months_ago', label: 'Dialysis 3-6 Months Ago', type: 'checkbox' },
                                    { field: 'dialysis_notes', label: 'Dialysis Notes', type: 'text' },
                                ]
                            }]
                    }, {
                        title: 'Coordination of Care',
                        model: 'coordination_of_cares',
                        customComponent: true,
                        field_definition: [{
                                fields: [
                                    {
                                        field: 'has_coc_from_facility_record',
                                        label: 'Diagnosis Information Obtained from Facility Record',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_from_patient_caregiver',
                                        label: 'Diagnosis Information Obtained from Patient / Caregiver',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_from_office',
                                        label: 'Diagnosis Information Obtained from Physician / Provider Office',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_final_review',
                                        label: 'Pre Admission Final Review',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_diagnoses_list',
                                        label: 'See Attached Current Diagnoses List',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_medication_list',
                                        label: 'See Attached Current Medication List',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_facility_order',
                                        label: 'See Attached Facility Orders',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_history_physical',
                                        label: 'See Attached History and Physical',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_discharge_order',
                                        label: 'See Attached Discharge Orders',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_labs',
                                        label: 'See Attached Labs',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_physician_note',
                                        label: 'See Attached Last Physician Progress Note',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_mar',
                                        label: 'See Attached MAR',
                                        type: 'checkbox',
                                    }, {
                                        field: 'has_coc_attached_other',
                                        label: 'See Attached Other',
                                        type: 'checkbox',
                                    },
                                ]
                            }]
                    },
                    {
                        title: 'Skin Lesions/Wounds',
                        model: 'body_systems_skin_integrity_circulation_findings',
                        customComponent: true,
                        field_definition: [{
                                fields: [
                                    {
                                        field: 'skin_lesions_current',
                                        label: 'Skin Lesions/Wounds Current',
                                        type: 'multiselect',
                                        optionsType: 'wound_options',
                                        value_field: 'code',
                                    }, {
                                        field: 'skin_lesions_history',
                                        label: 'Skin Lesions/Wounds 3-6 Months Ago',
                                        type: 'multiselect',
                                        optionsType: 'wound_options',
                                        value_field: 'code',
                                    },
                                    {
                                        field: 'note_skin_lesions',
                                        label: 'Skin Lesions/Wounds Notes',
                                    },
                                ]
                            }, {
                                fields: [{
                                        type: 'body site',
                                        field: 'body_site',
                                        style: {
                                            width: "100%"
                                        }
                                    }]
                            }]
                    },
                    {
                        title: 'Psychiatric',
                        model: 'body_systems_self_esteem_mental_status_findings',
                        customComponent: true,
                        field_definition: [{
                                type: 'table',
                                cols: ['Activities in Daily Living', 'Current', '3-6 months ago', 'Notes'],
                                rows: [{
                                        label: 'Agitation/Restlessness',
                                        current: {
                                            model: 'agitation_restlessness_current',
                                            type: 'multiselect',
                                            optionsType: 'agitation_restlessness_options'
                                        },
                                        history: {
                                            model: 'agitation_restlessness_history',
                                            type: 'multiselect',
                                            optionsType: 'agitation_restlessness_options'
                                        },
                                        notes: { model: 'agitation_restlessness_notes' }
                                    },
                                    {
                                        label: 'Alertness/Attention',
                                        current: {
                                            model: 'alertness_attention_current',
                                            type: 'multiselect',
                                            optionsType: 'alertness_attention_options'
                                        },
                                        history: {
                                            model: 'alertness_attention_history',
                                            type: 'multiselect',
                                            optionsType: 'alertness_attention_options'
                                        },
                                        notes: { model: 'alertness_attention_notes' }
                                    }, {
                                        label: 'Hours Asleep in 24',
                                        current: {
                                            model: 'hours_asleep_current',
                                            type: 'select',
                                            optionsType: 'hours_asleep_options'
                                        },
                                        history: {
                                            model: 'hours_asleep_history',
                                            type: 'select',
                                            optionsType: 'hours_asleep_options'
                                        },
                                        notes: { model: 'hours_asleep_notes' }
                                    }, {
                                        label: 'Interactivity',
                                        current: {
                                            model: 'interactivity_current',
                                            type: 'multiselect',
                                            optionsType: 'interactivity_options'
                                        },
                                        history: {
                                            model: 'interactivity_history',
                                            type: 'multiselect',
                                            optionsType: 'interactivity_options'
                                        },
                                        notes: { model: 'interactivity_notes' }
                                    }]
                            }, {
                                fields: [{
                                        field: 'personality_change',
                                        label: 'Personality Change',
                                    }]
                            }]
                    },
                    {
                        title: 'Communication',
                        model: 'body_systems_communication_findings',
                        customComponent: true,
                        field_definition: [{
                                fields: [{
                                        field: 'communication_ability_current',
                                        label: 'Ability to Communicate Current',
                                        type: 'multiselect',
                                        optionsType: 'communication_ability',
                                    },
                                    {
                                        field: 'communication_ability_before',
                                        label: 'Ability to Communicate 3-6 Months Ago',
                                        type: 'multiselect',
                                        optionsType: 'communication_ability',
                                    },
                                    { field: 'note_communication_ability', label: 'Ability to Communicate Notes' }]
                            }]
                    },
                    {
                        title: 'Respiratory',
                        model: 'body_systems_respiratory_oxygenation_findings',
                        customComponent: true,
                        field_definition: [{
                                label: 'Shortness of Breath',
                                fields: [
                                    {
                                        field: 'shortness_of_breath_current',
                                        label: 'Shortness of Breath Current',
                                        type: 'multiselect',
                                        optionsType: 'shortness_of_breath_options'
                                    },
                                    {
                                        field: 'shortness_of_breath_history',
                                        label: 'Shortness of Breath 3-6 Months Ago',
                                        type: 'multiselect',
                                        optionsType: 'shortness_of_breath_options'
                                    },
                                    { field: 'shortness_of_breath_notes', label: 'Shortness of Breath Notes', type: 'text' },
                                ]
                            },
                            {
                                label: 'Respiratory Findings',
                                fields: [
                                    { field: 'oxygenation_character', label: 'Character', type: 'booltext' },
                                    { field: 'rhonchi', label: 'Rhonchi', type: 'booltext' },
                                    { field: 'lung_congestion', label: 'Congestion', type: 'booltext' },
                                    {
                                        field: 'oxygenation_accessory_muscles',
                                        label: 'Use of Accessory Muscles',
                                        type: 'booltext',
                                    },
                                    { field: 'has_forced_expiratory_volume', label: 'Forced Expiratory Volume (FEV) <= 30% Predicted', type: 'checkbox' },
                                    { field: 'other_respiratory_notes', label: 'Other Respiratory Notes', type: 'text' },
                                ]
                            }, {
                                label: 'Location of Congestion',
                                fields: [{ field: 'oxygenation_cough', label: 'Cough', type: 'booltext' },]
                            }, {
                                label: 'Other',
                                fields: [{ field: 'has_inhalers', label: 'Inhalers', type: 'checkbox' },
                                    { field: 'has_nebulizers', label: 'Nebulizers', type: 'checkbox' },]
                            }]
                    },
                ];
                var getModelData = function (modelName) {
                    var modelData = formModel[modelName];
                    if (modelData && Array.isArray(modelData)) {
                        return modelData.length ? modelData : null;
                    }
                    return modelData ? modelData : null;
                };
                var processSections = function (paeFormDefinition) {
                    paeFormDefinition.forEach(function (section, oindex) {
                        if (section.model !== "general") {
                            var modelData = getModelData(section.model);
                            processMultipleSections(section, modelData);
                        }
                        else {
                            formDefinition.push(angular.copy(section));
                        }
                    });
                    formDefinition.forEach(function (section) {
                        processSubsections(section);
                    });
                };
                var processMultipleSections = function (section, modelData) {
                    var title = section.title;
                    var len = modelData ? modelData.length : false;
                    if (len) {
                        modelData.forEach(function (model, index) {
                            var _section = angular.copy(section);
                            _section.modelData = model;
                            _section.title = title + (len > 1 ? " #" + (index + 1) : '');
                            formDefinition.push(_section);
                        });
                    }
                    else {
                        var _section = angular.copy(section);
                        _section.modelData = modelData;
                        formDefinition.push(_section);
                    }
                };
                var processSubsections = function (section) {
                    section.isEmpty = section.model == "general" ? false : true;
                    section.field_definition.forEach(function (subsection) {
                        subsection.isEmpty = true;
                        if (!subsection.type) {
                            processFields(section, subsection, section.modelData);
                        }
                        else if (subsection.type == 'table' && section.modelData) {
                            processTableSubsection(section, subsection, section.modelData);
                        }
                    });
                };
                var processFields = function (section, subsection, modelData) {
                    modelData = section.model == "general" ? formModel : modelData;
                    subsection.fields.forEach(function (field) {
                        if (field.model) {
                            modelData = getModelData(field.model);
                            modelData = modelData && modelData.length && modelData[0];
                        }
                        field.isEmpty = true;
                        promiseChain.push(getFieldDisplayValue(modelData, field).then(function (val) {
                            field.displayValue = val;
                            field.isEmpty = isFieldEmpty(val);
                            if (!field.isEmpty) {
                                subsection.isEmpty = false;
                                section.isEmpty = false;
                            }
                        }));
                    });
                };
                var processTableSubsection = function (section, subsection, modelData) {
                    if (Array.isArray(subsection.rows)) {
                        subsection.isEmpty = true;
                        subsection.rows.forEach(function (row) {
                            row.isEmpty = true;
                            var _loop_3 = function (data) {
                                if (data !== "label" && data !== "isEmpty") {
                                    var rowData_1 = row[data];
                                    var value = modelData[rowData_1.model];
                                    rowData_1.field = rowData_1.model;
                                    promiseChain.push(getFieldDisplayValue(modelData, rowData_1).then(function (val) {
                                        rowData_1.displayValue = (Array.isArray(val) && typeof val[0] === 'string') ? val.join(', ') : val;
                                        rowData_1.isEmpty = isFieldEmpty(val);
                                        if (!rowData_1.isEmpty) {
                                            row.isEmpty = false;
                                            rowData_1.isEmpty = false;
                                            subsection.isEmpty = false;
                                            section.isEmpty = false;
                                        }
                                    }));
                                }
                            };
                            for (var data in row) {
                                _loop_3(data);
                            }
                        });
                    }
                    else {
                        subsection.rows = modelData[subsection.rows.model];
                        subsection.isEmpty = true;
                        if (subsection.rows && subsection.rows.length) {
                            subsection.rows.forEach(function (row) {
                                row.isEmpty = true;
                                for (var key in row) {
                                    if (key !== "label" && key !== "isEmpty" && key !== "$$hashKey") {
                                        if (row[key]) {
                                            var date = moment(row[key]);
                                            if (date.isValid() && !Array.isArray(row[key])) {
                                                row[key] = date.format("" + 'MM/DD/YYYY');
                                            }
                                            row[key] = Array.isArray(row[key]) ? row[key].join(', ') : row[key];
                                            subsection.isEmpty = false;
                                            section.isEmpty = false;
                                            row.isEmpty = false;
                                        }
                                    }
                                }
                            });
                        }
                        subsection.custom = true;
                    }
                };
                var isFieldEmpty = function (val) {
                    var value = val;
                    return typeof value === "undefined" || value === null || value === "" || (Array.isArray(value) && value.length === 0);
                };
                var getFieldDisplayValue = function (modelData, field) { return __awaiter(_this, void 0, void 0, function () {
                    var value, displayVal, displayVal;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                value = null;
                                if (!modelData) return [3 /*break*/, 5];
                                value = modelData[field.field];
                                if (field.type === "checkbox") {
                                    return [2 /*return*/, value ? "Yes" : undefined];
                                }
                                if (field.type === "booltext") {
                                    return [2 /*return*/, modelData["note_" + field.field]];
                                }
                                if (field.type === "datetime" && value) {
                                    return [2 /*return*/, moment(value).tz(this.sessionService.getUser().timezone).format('MM/DD/YYYY hh:mm A' + " (z)")];
                                }
                                if (field.type === "date" && value) {
                                    return [2 /*return*/, moment(value).format("" + 'MM/DD/YYYY')];
                                }
                                if (!((field.type === "select" || field.type == "multiselect") && value && field.optionsType)) return [3 /*break*/, 2];
                                return [4 /*yield*/, getDropdownDisplayValue(field, value).catch(function () {
                                    })];
                            case 1:
                                displayVal = _a.sent();
                                value = displayVal ? displayVal : ((Array.isArray(value) && value.length) ? value.join(', ') : value);
                                _a.label = 2;
                            case 2:
                                if (!(field.type == "rating" && field.optionsType)) return [3 /*break*/, 4];
                                return [4 /*yield*/, getDropdownDisplayValue(field, value).catch(function () {
                                    })];
                            case 3:
                                _a.sent();
                                field.value = value;
                                if (value) {
                                    if (field.minZero) {
                                        value = field.options[value]["description"];
                                    }
                                    else {
                                        value = field.options[Number(value) - 1]["description"];
                                    }
                                }
                                _a.label = 4;
                            case 4:
                                if (field.type === "body site") {
                                    if (typeof value !== "object") {
                                        value = undefined;
                                    }
                                }
                                else if (value && typeof value === "object" && !Array.isArray(value)) {
                                    displayVal = value[field.displayField];
                                    value = displayVal || undefined;
                                }
                                _a.label = 5;
                            case 5: return [2 /*return*/, value];
                        }
                    });
                }); };
                var getDropdownDisplayValue = function (field, value) {
                    return new Promise(function (resolve, reject) {
                        var resolveDisplayValue = function () {
                            var valueField = field.value_field ? field.value_field : "id";
                            var text_field = field.text_field ? field.text_field : "description";
                            if (Array.isArray(value)) {
                                var dispValueArr = value.map(function (val) {
                                    var displayVal = field.options.find(function (option) { return option[valueField] === val; });
                                    return displayVal ? displayVal[text_field] : val;
                                });
                                resolve(dispValueArr.join(', '));
                            }
                            else {
                                var val = field.options.find(function (option) { return option[valueField] === value; });
                                resolve(val ? val[text_field] : value);
                            }
                        };
                        if (field.options) {
                            resolveDisplayValue();
                        }
                        else {
                            _this.options.get(field.optionsType).$promise.then(function (data) {
                                field.options = data;
                                resolveDisplayValue();
                            }, function () { return reject(value); });
                        }
                    });
                };
                processSections(paeFormDefinition);
                return new Promise(function (resolve, reject) {
                    _this.$q.all(promiseChain).finally(function () {
                        resolve(formDefinition);
                    }, function () { return reject(formDefinition); });
                });
            };
            ChartingTemplateService.$inject = [
                'EncounterTypes',
                '$filter',
                '$mdDialog',
                '$q',
                'options',
                'cache',
                'Lookup',
                'EncounterType',
                'FamilyMember',
                'ProblemListItemsFactory',
                'Medication',
                'CurrentPatient',
                'logger',
                'cdsVitalSignsService',
                'PermissionsService',
                'PatientMainService',
                'VisitTaskList',
                'SessionService',
                'ClinicalChartSameQuestionInteraction'
            ];
            return ChartingTemplateService;
        }());
        charting.ChartingTemplateService = ChartingTemplateService;
        angular.module('consolo').service('ChartingTemplateService', ChartingTemplateService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ClinicalChartDateTimeInteraction = /** @class */ (function () {
            function ClinicalChartDateTimeInteraction() {
            }
            //Purpose: To put the cascading effects of changing model.effective_date, model.patient_time & model.inpatient_unit_notes
            //  in one place
            //Changing the Travel Start/Stop on the Patient Time is NOT addressed here, see patient-time.controller.ts
            //  The effects changing other fields have on Travel Start/Stop ARE addressed here
            //This is probably overly verbose
            //Update functions get their date from model.effective_date
            //Basic rules:
            //model.effective_date, patient_time.effective_date and inpatient_unit_notes[0].effective_date should always be the same
            //patient_time.in_datetime = inpatient_unit_notes[0].in_datetime if they both exist
            //patient_time.out_datetime = inpatient_unit_notes[0].out_datetime if they both exist
            //Changing model.effective_date directly resets the time portion of other datetime fields
            //See documentation for more other rules
            ClinicalChartDateTimeInteraction.changeToGeneralEffectiveDate = function (chartStore) {
                this.updatePatientTimeEffectiveDate(chartStore);
                this.updatePatientTimeIn(chartStore, this.emptyTime);
                this.updatePatientTimeOut(chartStore, this.emptyTime);
                this.updatePatientTimeTravelStart(chartStore, this.emptyTime);
                this.updatePatientTimeTravelStop(chartStore, this.emptyTime);
                this.updateInpatientUnitNoteEffectiveDate(chartStore);
                this.updateInpatientUnitNoteTimeIn(chartStore, this.emptyTime);
                this.updateInpatientUnitNoteTimeOut(chartStore, this.emptyTime);
                chartStore.saveDraft();
            };
            ClinicalChartDateTimeInteraction.changeToTimeOfVisit = function (chartStore) {
                this.updateGeneralEffectiveDate(chartStore, chartStore.model.time_of_visit);
                this.updatePatientTimeEffectiveDate(chartStore);
                this.updatePatientTimeIn(chartStore, chartStore.model.time_of_visit);
                this.updatePatientTimeOut(chartStore, this.emptyTime);
                this.updatePatientTimeTravelStart(chartStore, this.emptyTime);
                this.updatePatientTimeTravelStop(chartStore, this.emptyTime);
                this.updateInpatientUnitNoteEffectiveDate(chartStore);
                this.updateInpatientUnitNoteTimeIn(chartStore, chartStore.model.time_of_visit);
                this.updateInpatientUnitNoteTimeOut(chartStore, this.emptyTime);
            };
            ClinicalChartDateTimeInteraction.checkEffectiveDate = function (chartStore) {
                if (!chartStore.model.effective_date) {
                    chartStore.model.effective_date = new Date();
                }
            };
            ClinicalChartDateTimeInteraction.changeToPatientTimeIn = function (chartStore) {
                if (consolo.common.dateOnly(chartStore.model.patient_time.in_datetime).getTime() !==
                    consolo.common.dateOnly(chartStore.model.effective_date).getTime()) {
                    this.updateGeneralEffectiveDate(chartStore, chartStore.model.patient_time.in_datetime);
                    this.updatePatientTimeOut(chartStore, this.emptyTime);
                    this.updatePatientTimeTravelStart(chartStore, this.emptyTime);
                    this.updatePatientTimeTravelStop(chartStore, this.emptyTime);
                    if (chartStore.model.inpatient_unit_notes && chartStore.model.inpatient_unit_notes.length) {
                        this.updateInpatientUnitNoteTimeOut(chartStore, this.emptyTime);
                    }
                }
                if (chartStore.model.time_of_visit) {
                    chartStore.model.time_of_visit = chartStore.model.patient_time.in_datetime;
                }
                if (chartStore.model.inpatient_unit_notes && chartStore.model.inpatient_unit_notes.length) {
                    chartStore.model.inpatient_unit_notes[0].in_datetime =
                        chartStore.model.patient_time.in_datetime;
                }
                chartStore.saveDraft();
            };
            ClinicalChartDateTimeInteraction.changeToPatientTimeOut = function (chartStore) {
                if (!chartStore.model.inpatient_unit_notes || !chartStore.model.inpatient_unit_notes.length) {
                    return;
                }
                chartStore.model.inpatient_unit_notes[0].out_datetime =
                    chartStore.model.patient_time.out_datetime;
                chartStore.saveDraft();
            };
            ClinicalChartDateTimeInteraction.changeToInpatientUnitNoteTimeIn = function (chartStore) {
                if (consolo.common.dateOnly(chartStore.model.inpatient_unit_notes[0].in_datetime).getTime() !==
                    consolo.common.dateOnly(chartStore.model.effective_date).getTime()) {
                    this.updateGeneralEffectiveDate(chartStore, chartStore.model.inpatient_unit_notes[0].in_datetime);
                    if (chartStore.model.patient_time) {
                        this.updatePatientTimeOut(chartStore, this.emptyTime);
                        this.updatePatientTimeTravelStart(chartStore, this.emptyTime);
                        this.updatePatientTimeTravelStop(chartStore, this.emptyTime);
                    }
                    this.updateInpatientUnitNoteTimeOut(chartStore, this.emptyTime);
                }
                if (chartStore.model.time_of_visit) {
                    chartStore.model.time_of_visit = chartStore.model.inpatient_unit_notes[0].in_datetime;
                }
                if (chartStore.model.patient_time) {
                    chartStore.model.patient_time.in_datetime =
                        chartStore.model.inpatient_unit_notes[0].in_datetime;
                }
                chartStore.saveDraft();
            };
            ClinicalChartDateTimeInteraction.changeToInpatientUnitNoteTimeOut = function (chartStore) {
                if (!chartStore.model.patient_time) {
                    return;
                }
                chartStore.model.patient_time.out_datetime =
                    chartStore.model.inpatient_unit_notes[0].out_datetime;
                chartStore.saveDraft();
            };
            ClinicalChartDateTimeInteraction.updateGeneralEffectiveDate = function (chartStore, date) {
                chartStore.model.effective_date = consolo.common.dateOnly(date);
                this.updatePatientTimeEffectiveDate(chartStore);
                this.updateInpatientUnitNoteEffectiveDate(chartStore);
            };
            ClinicalChartDateTimeInteraction.updatePatientTimeEffectiveDate = function (chartStore) {
                if (chartStore.model.patient_time) {
                    chartStore.model.patient_time.effective_date = chartStore.model.effective_date;
                }
            };
            ClinicalChartDateTimeInteraction.updatePatientTimeIn = function (chartStore, newTime) {
                if (!chartStore.model.patient_time) {
                    return;
                }
                chartStore.model.patient_time.in_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime ? newTime : chartStore.model.patient_time.in_datetime);
            };
            ClinicalChartDateTimeInteraction.updatePatientTimeOut = function (chartStore, newTime) {
                if (!chartStore.model.patient_time) {
                    return;
                }
                chartStore.model.patient_time.out_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime ? newTime : chartStore.model.patient_time.out_datetime);
            };
            ClinicalChartDateTimeInteraction.updatePatientTimeTravelStart = function (chartStore, newTime) {
                if (!chartStore.model.patient_time) {
                    return;
                }
                if (!chartStore.model.patient_time.travel_start_datetime) {
                    return;
                }
                chartStore.model.patient_time.travel_start_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime ? newTime : chartStore.model.patient_time.travel_start_datetime);
            };
            ClinicalChartDateTimeInteraction.updatePatientTimeTravelStop = function (chartStore, newTime) {
                if (!chartStore.model.patient_time) {
                    return;
                }
                if (!chartStore.model.patient_time.travel_stop_datetime) {
                    return;
                }
                chartStore.model.patient_time.travel_stop_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime ? newTime : chartStore.model.patient_time.travel_stop_datetime);
            };
            ClinicalChartDateTimeInteraction.updateInpatientUnitNoteEffectiveDate = function (chartStore) {
                if (chartStore.model.inpatient_unit_notes && chartStore.model.inpatient_unit_notes.length) {
                    chartStore.model.inpatient_unit_notes[0].effective_date = chartStore.model.effective_date;
                }
            };
            ClinicalChartDateTimeInteraction.updateInpatientUnitNoteTimeIn = function (chartStore, newTime) {
                if (!chartStore.model.inpatient_unit_notes || !chartStore.model.inpatient_unit_notes.length) {
                    return;
                }
                chartStore.model.inpatient_unit_notes[0].in_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime ? newTime : chartStore.model.inpatient_unit_notes[0].in_datetime);
            };
            ClinicalChartDateTimeInteraction.updateInpatientUnitNoteTimeOut = function (chartStore, newTime) {
                if (!chartStore.model.inpatient_unit_notes || !chartStore.model.inpatient_unit_notes.length) {
                    return;
                }
                chartStore.model.inpatient_unit_notes[0].out_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime ? newTime : chartStore.model.inpatient_unit_notes[0].out_datetime);
            };
            ClinicalChartDateTimeInteraction.initializePatientTime = function (chartStore) {
                this.checkEffectiveDate(chartStore);
                if (chartStore.model.inpatient_unit_notes && chartStore.model.inpatient_unit_notes.length) {
                    //use inpatient unit note if it exists
                    if (chartStore.model.inpatient_unit_notes[0].in_datetime) {
                        chartStore.model.patient_time.in_datetime =
                            chartStore.model.inpatient_unit_notes[0].in_datetime;
                    }
                    if (chartStore.model.inpatient_unit_notes[0].out_datetime) {
                        chartStore.model.patient_time.out_datetime =
                            chartStore.model.inpatient_unit_notes[0].out_datetime;
                    }
                }
                else if (chartStore.model.time_of_visit) {
                    chartStore.model.patient_time.in_datetime = new Date(chartStore.model.time_of_visit);
                }
                var newTime = new Date();
                if (chartStore.model.effective_date &&
                    consolo.common.dateOnly(chartStore.model.effective_date).getTime() !==
                        consolo.common.dateOnly(newTime).getTime()) {
                    newTime = consolo.common.dateOnly(newTime);
                }
                if (!chartStore.model.patient_time.in_datetime) {
                    chartStore.model.patient_time.in_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime);
                }
                if (!chartStore.model.patient_time.out_datetime) {
                    chartStore.model.patient_time.out_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, this.emptyTime);
                }
            };
            ClinicalChartDateTimeInteraction.initializeInpatientUnitNote = function (chartStore) {
                this.checkEffectiveDate(chartStore);
                if (chartStore.model.patient_time) {
                    //use patient time in/out if it exists
                    if (chartStore.model.patient_time.in_datetime) {
                        chartStore.model.inpatient_unit_notes[0].in_datetime =
                            chartStore.model.patient_time.in_datetime;
                    }
                    if (chartStore.model.patient_time.out_datetime) {
                        chartStore.model.inpatient_unit_notes[0].out_datetime =
                            chartStore.model.patient_time.out_datetime;
                    }
                }
                else if (chartStore.model.time_of_visit) {
                    chartStore.model.inpatient_unit_notes[0].in_datetime = new Date(chartStore.model.time_of_visit);
                    chartStore.model.inpatient_unit_notes[0].in_datetime = consolo.common.dateOnly(new Date(chartStore.model.time_of_visit));
                }
                var newTime = new Date();
                if (consolo.common.dateOnly(chartStore.model.effective_date).getTime() !==
                    consolo.common.dateOnly(newTime).getTime()) {
                    newTime = consolo.common.dateOnly(newTime);
                }
                if (!chartStore.model.inpatient_unit_notes[0].in_datetime) {
                    chartStore.model.inpatient_unit_notes[0].in_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, newTime);
                }
                if (!chartStore.model.inpatient_unit_notes[0].out_datetime) {
                    chartStore.model.inpatient_unit_notes[0].out_datetime = consolo.common.combineDateTime(chartStore.model.effective_date, this.emptyTime);
                }
            };
            ClinicalChartDateTimeInteraction.emptyPatientTimeTravelStart = function (chartStore) {
                if (chartStore.model.patient_time) {
                    if (chartStore.model.patient_time.travel_start_datetime) {
                        delete chartStore.model.patient_time.travel_start_datetime;
                    }
                }
            };
            ClinicalChartDateTimeInteraction.emptyPatientTimeTravelStop = function (chartStore) {
                if (chartStore.model.patient_time) {
                    if (chartStore.model.patient_time.travel_stop_datetime) {
                        delete chartStore.model.patient_time.travel_stop_datetime;
                    }
                }
            };
            ClinicalChartDateTimeInteraction.emptyTime = new Date('2016/09/16');
            ClinicalChartDateTimeInteraction.$inject = [];
            return ClinicalChartDateTimeInteraction;
        }());
        services.ClinicalChartDateTimeInteraction = ClinicalChartDateTimeInteraction;
        angular
            .module('consolo')
            .service('ChartDateTimeInteraction', ClinicalChartDateTimeInteraction);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ClinicalChartSameQuestionInteraction = /** @class */ (function () {
            function ClinicalChartSameQuestionInteraction() {
            }
            /*
              Purpose: To put the cascading effects of changing of chartStore fields, targeting multiple items on sametime
              
              Example of usage from app/clinical-charts/charting-template.service.ts:
                // `that` variable is `this` from app/common/form-manager/consolo-charting-form-field.component.ts
                let chartStore = chartingFormField.$scope.$parent.$parent.$parent.$ctrl.chartStore
                let has_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].has_dyspnea_history
                let note_value = chartStore.model.body_systems_cardiac_circulatory_findings[0].note_dyspnea_history
                let items = [
                  {
                    section: "['body_systems_respiratory_oxygenation_findings']",
                    field: "[0]['has_dyspnea_history']",
                    value: has_value
                  },
                  {
                    section: "['body_systems_respiratory_oxygenation_findings']",
                    field: "[0]['note_dyspnea_history']",
                    value: note_value
                  }
                ]
                chartingFormField.clinicalChartSameQuestionInteraction.constructor.change(chartStore, items)
            */
            ClinicalChartSameQuestionInteraction.change = function (chartStore, items) {
                this.update(chartStore, items);
                chartStore.saveDraft();
            };
            ClinicalChartSameQuestionInteraction.update = function (chartStore, items) {
                items.forEach(function (item) {
                    var field = item.field;
                    var section = item.section;
                    var value = item.value;
                    var field_name = 'chartStore.model' + section;
                    var exists = new Function('chartStore', 'return ' + field_name);
                    if (exists(chartStore)) {
                        field_name += field;
                        if (typeof value == 'string') {
                            value = "'" + value + "'";
                        }
                        var set_value = new Function('chartStore', field_name + "=" + value);
                        set_value(chartStore);
                    }
                });
            };
            ClinicalChartSameQuestionInteraction.$inject = [];
            return ClinicalChartSameQuestionInteraction;
        }());
        services.ClinicalChartSameQuestionInteraction = ClinicalChartSameQuestionInteraction;
        angular
            .module('consolo')
            .service('ClinicalChartSameQuestionInteraction', ClinicalChartSameQuestionInteraction);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    'use strict';
    var ClinicalChartStates = /** @class */ (function () {
        function ClinicalChartStates($stateProvider) {
            $stateProvider
                .state('clinical-chart', {
                url: '/clinical_chart?patientId&set_charting_default&chartId',
                data: { title: 'Clinical Charts' },
                reloadOnSearch: false,
                views: {
                    'sidebar@': {
                        template: "<csg-chart-search-sidebar flex=\"\" layout=\"column\" query-filters=\"queryFilters\" current-patient=\"currentPatient\">\n                </csg-chart-search-sidebar>",
                        controller: [
                            '$scope',
                            'queryFilters',
                            'currentPatient',
                            function ($scope, queryFilters, currentPatient) {
                                $scope.queryFilters = queryFilters;
                                $scope.currentPatient = currentPatient;
                            },
                        ],
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/search/chart-search.html',
                        controller: 'ChartSearchController as $ctrl',
                    },
                },
                onEnter: [
                    '$stateParams',
                    'NetworkManager',
                    'DeviceSettingsService',
                    function ($stateParams, NetworkManager, DeviceSettings) {
                        if ($stateParams && $stateParams.set_charting_default) {
                            //if online and default flag exists, set the cookie to use tablet charting
                            if (NetworkManager.isOnline()) {
                                DeviceSettings.setValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode, true);
                            }
                        }
                    },
                ],
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'clinical_charts');
                        },
                    ],
                    queryFilters: [
                        '$state',
                        '$location',
                        function ($state, $location) {
                            var paramObj = $location.search();
                            if (!angular.equals(paramObj, {})) {
                                paramObj.patient_id = paramObj.patientId;
                                paramObj.user_id = paramObj.chart_owner;
                                var t = new consolo.domain.ChartSearchFilters();
                                for (var key in paramObj) {
                                    if (!t.hasOwnProperty(key)) {
                                        delete paramObj[key];
                                    }
                                    else if (paramObj[key] && !isNaN(paramObj[key])) {
                                        paramObj[key] = parseInt(paramObj[key], 10);
                                    }
                                }
                            }
                            else {
                                paramObj = null;
                            }
                            return paramObj;
                        },
                    ],
                    currentPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        'logger',
                        function ($params, CurrentPatient, logger) {
                            if (!$params.patientId) {
                                return null;
                            }
                            return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                                return CurrentPatient.patient;
                            }, function (e) {
                                console.log('Error Resolving currentPatient');
                                console.log(e);
                                logger.error('Patient id ' +
                                    $params.patientId +
                                    ' could not be retrieved from the server and is not in the cache', 'Error retrieving patient');
                                throw e;
                            });
                        },
                    ],
                },
            })
                .state('clinical-chart-drafts', {
                url: '/clinical_chart/drafts',
                data: {
                    title: 'Clinical Charts',
                },
                views: {
                    'sidebar@': {
                        templateUrl: '/y/templates/clinical-charts/sidebars/charting-patient-search.html',
                        controller: 'ClinicalChartsPatientSearchSidebarController as vm',
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/dashboard.html',
                        controller: 'ClinicalChartsDashboardController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'clinical_charts');
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-search', {
                url: '/clinical_chart/search?discipline',
                data: { title: 'Clinical Charts' },
                reloadOnSearch: false,
                views: {
                    'sidebar@': {
                        template: "<csg-chart-search-sidebar flex=\"\" layout=\"column\" query-filters=\"queryFilters\" current-patient=\"currentPatient\">\n                </csg-chart-search-sidebar>",
                        controller: [
                            '$scope',
                            'queryFilters',
                            'currentPatient',
                            function ($scope, queryFilters, currentPatient) {
                                $scope.queryFilters = queryFilters;
                                $scope.currentPatient = currentPatient;
                            },
                        ],
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/search/chart-search.html',
                        controller: 'ChartSearchController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'clinical_charts');
                        },
                    ],
                    currentPatient: ['currentPatient', function (currentPatient) { return currentPatient; }],
                    queryFilters: [
                        '$location',
                        '$stateParams',
                        'currentPatient',
                        function ($location, $stateParams, currentPatient) {
                            var paramObj = $location.search();
                            angular.extend(paramObj, $stateParams);
                            if (!angular.equals(paramObj, {})) {
                                paramObj.patient_id = paramObj.patientId;
                                paramObj.user_id = paramObj.chart_owner;
                                var t = new consolo.domain.ChartSearchFilters();
                                for (var key in paramObj) {
                                    if (!t.hasOwnProperty(key)) {
                                        delete paramObj[key];
                                    }
                                    else if (paramObj[key] && !isNaN(paramObj[key])) {
                                        paramObj[key] = parseInt(paramObj[key], 10);
                                    }
                                }
                            }
                            paramObj.patient_id = currentPatient.id;
                            return paramObj;
                        },
                    ],
                },
            })
                .state('patients.clinical-chart', {
                url: '/clinical_chart?set_charting_default',
                data: { title: 'Clinical Charts', backAction: 'clinical-chart' },
                views: {
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/dashboard.html',
                        controller: 'ClinicalChartsDashboardController as vm',
                    },
                },
                onEnter: [
                    '$stateParams',
                    'NetworkManager',
                    'DeviceSettingsService',
                    function ($stateParams, NetworkManager, DeviceSettings) {
                        if ($stateParams && $stateParams.set_charting_default) {
                            //if online and default flag exists, set the cookie to use tablet charting
                            if (NetworkManager.isOnline()) {
                                DeviceSettings.setValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode, true);
                            }
                        }
                    },
                ],
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'clinical_charts');
                        },
                    ],
                    currentPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        'logger',
                        function ($params, CurrentPatient, logger) {
                            if (!$params.patientId) {
                                return null;
                            }
                            return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                                return CurrentPatient.patient;
                            }, function (e) {
                                console.log('Error Resolving currentPatient');
                                console.log(e);
                                logger.error('Patient id ' +
                                    $params.patientId +
                                    ' could not be retrieved from the server and is not in the cache', 'Error retrieving patient');
                                throw e;
                            });
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-view', {
                url: '/clinical_chart/:id?set_charting_default&include_last_page_signature_line',
                data: { title: 'Clinical Chart', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/mini-view.html',
                        controller: 'ClinicalChartsCommonSectionController as $ctrl',
                    },
                },
                onEnter: [
                    '$stateParams',
                    'NetworkManager',
                    'DeviceSettingsService',
                    function ($stateParams, NetworkManager, DeviceSettings) {
                        if ($stateParams && $stateParams.set_charting_default) {
                            //if online and default flag exists, set the cookie to use tablet charting
                            if (NetworkManager.isOnline()) {
                                DeviceSettings.setValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode, true);
                            }
                        }
                    },
                ],
                resolve: {
                    showSectionDetails: function () { return false; },
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'clinical_charts');
                        },
                    ],
                    chartStore: [
                        '$stateParams',
                        'ClinicalChartEntry',
                        'PersistedItem',
                        'EncounterTypes',
                        function ($params, ClinicalChartEntry, PersistedItem, EncounterTypes) {
                            return ClinicalChartEntry.getById($params.id).then(function (chartEntry) {
                                if (chartEntry.encounter_type_id) {
                                    return EncounterTypes.get(chartEntry.encounter_type_id).then(function (et) {
                                        chartEntry.encounter_type = et;
                                        return PersistedItem('clinical_chart_entry', chartEntry);
                                    });
                                }
                                else if (chartEntry.charting_client === 'encounter_charting') {
                                    chartEntry.encounter_type = {
                                        lookup_name: chartEntry.charting_client,
                                        name: 'Default ' + chartEntry.charting_client.replace(/_/g, ' '),
                                        display_name: 'Default Encounter Charting',
                                        id: 'encounter_charting',
                                    };
                                    chartEntry.encounter_type_id = 'encounter_charting';
                                }
                                else {
                                    var def_name = void 0;
                                    switch (chartEntry.charting_client) {
                                        case 'bootstrap_charting':
                                            def_name = 'Classic Charting';
                                            break;
                                        case 'bootstrap_aide_charting':
                                            def_name = 'Classic Aide Charting';
                                            break;
                                        case 'ios_aide_app':
                                            def_name = 'Aide App Charting';
                                            break;
                                        default:
                                            def_name = chartEntry.charting_client;
                                    }
                                    chartEntry.encounter_type = {
                                        lookup_name: 'tablet_optimized',
                                        id: 'encounter_charting',
                                        name: 'Default ' + def_name.replace(/_/g, ' '),
                                        role_ids: []
                                    };
                                    chartEntry.encounter_type_id = 'encounter_charting';
                                }
                                return PersistedItem('clinical_chart_entry', chartEntry);
                            }, function (error) {
                                console.log('Error resolving chartStore');
                                console.log(error);
                            });
                        },
                    ],
                    formModel: [
                        '$stateParams',
                        function ($stateParams) {
                            return { patient_id: $stateParams.patientId };
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-entry-edit', {
                abstract: true,
                url: '/clinical_chart/:chartEntryId/edit?set_charting_default',
                data: {
                    title: 'Clinical Chart Entry',
                    action: 'edit',
                    backAction: 'patients.clinical-chart',
                },
                views: {
                    'sidebar@': {
                        templateUrl: '/y/templates/clinical-charts/sidebar.html',
                        controller: 'ClinicalChartsSidebarController as $ctrl',
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/editor.html',
                        controller: 'ClinicalChartEditorController as $ctrl',
                    },
                },
                onEnter: [
                    '$stateParams',
                    'NetworkManager',
                    'DeviceSettingsService',
                    function ($stateParams, NetworkManager, DeviceSettings) {
                        if ($stateParams && $stateParams.set_charting_default) {
                            //if online and default flag exists, set the cookie to use tablet charting
                            if (NetworkManager.isOnline()) {
                                DeviceSettings.setValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode, true);
                            }
                        }
                    },
                ],
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'clinical_charts');
                        },
                    ],
                    chartStore: [
                        '$stateParams',
                        'ClinicalChartEntry',
                        'PersistedItem',
                        'EncounterTypes',
                        function ($params, ClinicalChartEntry, PersistedItem, EncounterTypes) {
                            return ClinicalChartEntry.getById($params.chartEntryId).then(function (chartEntry) {
                                delete chartEntry.model;
                                if (!chartEntry.charting_client) {
                                    chartEntry.charting_client = 'tablet_optimized';
                                }
                                if (chartEntry.encounter_type_id) {
                                    return EncounterTypes.get(chartEntry.encounter_type_id).then(function (et) {
                                        chartEntry.encounter_type = et;
                                        return PersistedItem('clinical_chart_entry', chartEntry);
                                    });
                                }
                                else {
                                    if (chartEntry.charting_client === 'encounter_charting') {
                                        chartEntry.encounter_type = {
                                            lookup_name: chartEntry.charting_client,
                                            name: chartEntry.charting_client.replace(/_/g, ' '),
                                            id: 'encounter_charting',
                                            display_name: 'Default Encounter Charting',
                                        };
                                        chartEntry.encounter_type_id = 'encounter_charting';
                                    }
                                    else {
                                        var def_name = void 0;
                                        switch (chartEntry.charting_client) {
                                            case 'bootstrap_charting':
                                                def_name = 'Classic Charting';
                                                break;
                                            case 'bootstrap_aide_charting':
                                                def_name = 'Classic Aide Charting';
                                                break;
                                            case 'ios_aide_app':
                                                def_name = 'Aide App Charting';
                                                break;
                                            default:
                                                def_name = chartEntry.charting_client;
                                        }
                                        chartEntry.encounter_type = {
                                            lookup_name: 'tablet_optimized',
                                            id: 'encounter_charting',
                                            name: 'Default ' + def_name.replace(/_/g, ' '),
                                            role_ids: []
                                        };
                                    }
                                    return PersistedItem('clinical_chart_entry', chartEntry);
                                }
                            }, function (error) {
                                console.log('Error resolving chartStore');
                                console.log(error);
                            });
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-entry-new', {
                abstract: true,
                url: '/clinical_chart/new?set_charting_default',
                params: {
                    initObj: null,
                    role_id: null,
                    encounter_type_id: null,
                    mileage_chart_init: null,
                },
                data: {
                    title: 'Clinical Chart Entry',
                    action: 'new',
                    backAction: 'patients.clinical-chart',
                },
                onEnter: [
                    '$stateParams',
                    'NetworkManager',
                    'DeviceSettingsService',
                    function ($stateParams, NetworkManager, DeviceSettings) {
                        if ($stateParams && $stateParams.set_charting_default) {
                            //if online and default flag exists, set the cookie to use tablet charting
                            if (NetworkManager.isOnline()) {
                                DeviceSettings.setValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode, true);
                            }
                        }
                    },
                ],
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'clinical_charts');
                        },
                    ],
                    chartStore: [
                        '$stateParams',
                        '$location',
                        'ClinicalChartEntry',
                        'CurrentPatient',
                        'PersistedItem',
                        '$resource',
                        'SessionService',
                        'EmrWebRelease',
                        'logger',
                        'ChartingTemplateService',
                        function ($params, $location, ClinicalChartEntry, CurrentPatient, PersistedItem, $resource, Session, ReleaseVersion, logger, ChartingTemplateService) {
                            return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                                if (parseInt($params.patientId, 10) !== parseInt(CurrentPatient.patient_id, 10)) {
                                    logger.error('Current Patient does not match the patient id sent in the params. ' +
                                        CurrentPatient.patient_id +
                                        ' compared to ' +
                                        $params.patientId +
                                        '. Please try again.', 'Patient ID Mismatch');
                                    throw 'Current Patient does not match the patient id in the params: ' +
                                        CurrentPatient.patient_id +
                                        ' - ' +
                                        $params.patientId;
                                }
                                var chartEntry = {
                                    patient_id: parseInt($params.patientId, 10),
                                    patient: CurrentPatient.patient,
                                };
                                var stopPointId = $location.search().clinical_trip_stop_point_id;
                                if (stopPointId) {
                                    chartEntry.clinical_trip_stop_point_id = parseInt(stopPointId, 10);
                                }
                                if ($params.initObj) {
                                    if ($params.initObj.clinical_trip_stop_point_id) {
                                        chartEntry.clinical_trip_stop_point_id =
                                            $params.initObj.clinical_trip_stop_point_id;
                                    }
                                    if ($params.initObj.patient_time) {
                                        chartEntry.patient_time = $params.initObj.patient_time;
                                    }
                                    if ($params.initObj.patient_time_uuid) {
                                        chartEntry.patient_time_uuid = $params.initObj.patient_time_uuid;
                                    }
                                    if ($params.initObj.electronic_visit_verification) {
                                        chartEntry.electronic_visit_verification =
                                            $params.initObj.electronic_visit_verification;
                                    }
                                    if ($params.initObj.evv_uuid) {
                                        chartEntry.evv_uuid = $params.initObj.evv_uuid;
                                    }
                                    if ($params.initObj.care_plan_problem) {
                                        chartEntry.care_plan_problem = $params.initObj.care_plan_problem;
                                    }
                                    if ($params.initObj.create_new_care_plan_problem) {
                                        chartEntry.create_new_care_plan_problem = $params.initObj.create_new_care_plan_problem;
                                    }
                                    chartEntry.charting_client = $params.initObj.charting_client;
                                    chartEntry.effective_date = $params.initObj.effective_date;
                                    chartEntry.time_of_visit = $params.initObj.time_of_visit;
                                    chartEntry.role_id = $params.initObj.role_id;
                                    chartEntry.role_name = $params.initObj.role_name;
                                    chartEntry.user = $params.initObj.user;
                                    chartEntry.user_id = $params.initObj.user ? $params.initObj.user.id : null;
                                    chartEntry.role_name = $params.initObj.role_name;
                                    chartEntry.encounter_type_id = $params.initObj.encounter_type
                                        ? $params.initObj.encounter_type.id
                                        : 0;
                                    chartEntry.encounter_type = $params.initObj.encounter_type;
                                    chartEntry.initial_section_name = $params.initObj.initial_section_name;
                                }
                                else {
                                    var user = Session.getUser();
                                    if (user.flags.has_encounter_charting) {
                                        chartEntry.charting_client = 'encounter_charting';
                                    }
                                    else {
                                        chartEntry.charting_client = 'tablet_optimized';
                                    }
                                }
                                chartEntry.charting_client_version = ReleaseVersion();
                                var chartStore = PersistedItem('clinical_chart_entry', new ClinicalChartEntry(chartEntry));
                                // Initialize the IPU Note section on chart creation when needed
                                if (CurrentPatient.patient.ipu) {
                                    var section_template_name = ChartingTemplateService.getTemplateName(chartStore.model);
                                    var default_template = null;
                                    if (chartStore.model.charting_client === 'custom') {
                                        default_template = 'encounter_charting';
                                    }
                                    var section = ChartingTemplateService.sectionSearchBy('inpatient_unit_note', 'name', section_template_name, default_template);
                                    ChartingTemplateService.chartStoreSectionInit(section, chartStore, CurrentPatient.patient);
                                }
                                return chartStore;
                            }, function (e) {
                                console.log('Error Resolving currentPatient');
                                console.log(e);
                                logger.error('Patient id ' +
                                    $params.patientId +
                                    ' could not be retrieved from the server and is not in the cache', 'Error retrieving patient');
                                throw e;
                            });
                        },
                    ],
                },
                views: {
                    'sidebar@': {
                        templateUrl: '/y/templates/clinical-charts/sidebar.html',
                        controller: 'ClinicalChartsSidebarController as $ctrl',
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/editor.html',
                        controller: 'ClinicalChartEditorController as $ctrl',
                    },
                },
            })
                .state('patients.clinical-chart-entry-edit.care-plan-problem', {
                url: '/care_plan_problem/:cppid',
                views: {
                    editor: {
                        templateUrl: '/y/templates/clinical-charts/sections/care-plan-problem.html',
                        controller: 'ClinicalChartsCarePlanProblemController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            // TODO: should this be care_plan_problem_updates?
                            return RouteAuth.canOrAlert('update', 'care_plan_problem_updates');
                        },
                    ],
                    formModel: [
                        'chartStore',
                        '$filter',
                        '$stateParams',
                        'ChartingSectionManager',
                        'ChartingTemplateService',
                        function (chartStore, $filter, $stateParams, ChartingSectionManager, ChartingTemplateService) {
                            var isOffline = isNaN($stateParams.cppid);
                            var item;
                            if (chartStore.model.care_plan_problem_updates) {
                                item = $filter('filter')(chartStore.model.care_plan_problem_updates, function (b) {
                                    if (isOffline) {
                                        return b.care_plan_problem_uuid === $stateParams.cppid;
                                    }
                                    return (parseInt(b.care_plan_problem_id, 10) === parseInt($stateParams.cppid, 10));
                                })[0];
                            }
                            else {
                                chartStore.model.care_plan_problem_updates = [{}];
                                item = chartStore.model.care_plan_problem_updates[0];
                                if (isOffline) {
                                    item.care_plan_problem_uuid = $stateParams.cppid;
                                }
                                else {
                                    item.care_plan_problem_id = $stateParams.cppid;
                                }
                            }
                            // ChartingSectionManager.currentSection = ChartingTemplateService.getCarePlanProblemSection(
                            //   item ? item.problem : null
                            // );
                            return item;
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-entry-new.care-plan-problem', {
                url: '/care_plan_problem/:cppid',
                views: {
                    editor: {
                        templateUrl: '/y/templates/clinical-charts/sections/care-plan-problem.html',
                        controller: 'ClinicalChartsCarePlanProblemController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'care_plan_problem_updates');
                        },
                    ],
                    formModel: [
                        'chartStore',
                        '$filter',
                        '$stateParams',
                        'ChartingSectionManager',
                        'ChartingTemplateService',
                        function (chartStore, $filter, $stateParams, ChartingSectionManager, ChartingTemplateService) {
                            var isOffline = isNaN($stateParams.cppid);
                            var item;
                            if (chartStore.model.care_plan_problem_updates) {
                                item = $filter('filter')(chartStore.model.care_plan_problem_updates, function (b) {
                                    if (isOffline) {
                                        return b.care_plan_problem_uuid === $stateParams.cppid;
                                    }
                                    return (parseInt(b.care_plan_problem_id, 10) === parseInt($stateParams.cppid, 10));
                                })[0];
                            }
                            else {
                                chartStore.model.care_plan_problem_updates = [{}];
                                item = chartStore.model.care_plan_problem_updates[0];
                                if (isOffline) {
                                    item.care_plan_problem_uuid = $stateParams.cppid;
                                }
                                else {
                                    item.care_plan_problem_id = $stateParams.cppid;
                                }
                            }
                            // ChartingSectionManager.currentSection = ChartingTemplateService.getCarePlanProblemSection(
                            //   item ? item.problem : null
                            // );
                            return item;
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-entry-new.section', {
                url: '/:sectionName?role_id&encounter_type_id&mileage_chart_init',
                views: {
                    editor: {
                        templateUrl: function ($stateParams) {
                            return $stateParams.templateUrl;
                        },
                        controller: 'ClinicalChartsCommonSectionController as vm',
                    },
                },
                resolve: {
                    showSectionDetails: function () { return false; },
                    chartStore: [
                        '$stateParams',
                        'chartStore',
                        'CurrentPatient',
                        'ChartingSectionManager',
                        '$stateParams',
                        'ChartingTemplateService',
                        'SessionService',
                        'logger',
                        function ($params, chartStore, CurrentPatient, SectionManager, $stateParams, ChartingTemplateService, Session, logger) {
                            return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                                if ($stateParams.encounter_type_id) {
                                    chartStore.model.encounter_type_id = parseInt($stateParams.encounter_type_id, 10);
                                }
                                if ($stateParams.role_id) {
                                    chartStore.model.role_id = parseInt($stateParams.role_id, 10);
                                }
                                var section_template_name;
                                var default_template = null;
                                if (chartStore.model.charting_client === 'custom') {
                                    default_template = 'encounter_charting';
                                }
                                section_template_name = ChartingTemplateService.getTemplateName(chartStore.model);
                                $stateParams.section_template_name = section_template_name;
                                console.log('aide charting debug', $stateParams.sectionName, section_template_name, default_template);
                                var section = ChartingTemplateService.sectionSearchBy($stateParams.sectionName, 'name', section_template_name, default_template);
                                if (!section) {
                                    var user = Session.getUser();
                                    $stateParams.draft_redirect = $stateParams.sectionName;
                                    if (user.flags.has_encounter_charting) {
                                        section_template_name = 'encounter_charting';
                                        chartStore.model.charting_client = 'encounter_charting';
                                        $stateParams.sectionName = 'encounter_charting';
                                    }
                                    else {
                                        section_template_name = 'tablet_optimized';
                                        chartStore.model.charting_client = 'tablet_optimized';
                                        $stateParams.sectionName = 'general';
                                    }
                                    section = ChartingTemplateService.sectionSearchBy($stateParams.sectionName, 'name', section_template_name);
                                }
                                if (section.allowMultiple &&
                                    !ChartingTemplateService.isEncounterCharting(chartStore.model.charting_client)) {
                                    $stateParams.templateUrl =
                                        '/y/templates/clinical-charts/sections/common-multiple-section.html';
                                }
                                else {
                                    $stateParams.templateUrl =
                                        '/y/templates/clinical-charts/sections/common-section.html';
                                }
                                if (section.sections) {
                                    for (var _i = 0, _a = section.sections; _i < _a.length; _i++) {
                                        var s = _a[_i];
                                        ChartingTemplateService.chartStoreSectionInit(s, chartStore, CurrentPatient.patient);
                                    }
                                }
                                else {
                                    ChartingTemplateService.chartStoreSectionInit(section, chartStore, CurrentPatient.patient);
                                }
                                if (chartStore.model.care_plan_problem) {
                                    if (chartStore.model.care_plan_problem_updates) {
                                        if (!chartStore.model.care_plan_problem_updates.some(function (x) { return x.care_plan_problem_id === chartStore.model.care_plan_problem.id; })) {
                                            SectionManager.addCarePlanProblemUpdate(chartStore.model.care_plan_problem, chartStore);
                                        }
                                    }
                                    else {
                                        SectionManager.addCarePlanProblemUpdate(chartStore.model.care_plan_problem, chartStore);
                                    }
                                }
                                return chartStore;
                            }, function (error) {
                                console.log('Error Resolving currentPatient');
                                console.log(error);
                                logger.error('Patient id ' +
                                    $params.patientId +
                                    ' could not be retrieved from the server and is not in the cache', 'Error retrieving patient');
                                throw error;
                            });
                        },
                    ],
                    formModel: [
                        'chartStore',
                        '$stateParams',
                        'ChartingTemplateService',
                        function (chartStore, $stateParams, ChartingTemplateService) {
                            return ChartingTemplateService.formModelInit(chartStore, $stateParams.sectionName);
                        },
                    ],
                },
            })
                .state('patients.clinical-chart-entry-edit.section', {
                url: '/:sectionName',
                views: {
                    editor: {
                        templateUrl: function ($stateParams) {
                            return $stateParams.templateUrl;
                        },
                        controller: 'ClinicalChartsCommonSectionController as vm',
                    },
                },
                resolve: {
                    showSectionDetails: function () { return false; },
                    chartStore: [
                        '$stateParams',
                        'chartStore',
                        'CurrentPatient',
                        'ChartingSectionManager',
                        '$stateParams',
                        'ChartingTemplateService',
                        'SessionService',
                        'logger',
                        function ($params, chartStore, CurrentPatient, SectionManager, $stateParams, ChartingTemplateService, Session, logger) {
                            return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                                var section_template_name;
                                var default_template;
                                if (!chartStore.model.charting_client) {
                                    chartStore.model.charting_client = 'tablet_optimized';
                                }
                                if (chartStore.model.charting_client === 'custom') {
                                    default_template = 'encounter_charting';
                                }
                                section_template_name = ChartingTemplateService.getTemplateName(chartStore.model);
                                $stateParams.section_template_name = section_template_name;
                                var section = ChartingTemplateService.sectionSearchBy($stateParams.sectionName, 'name', section_template_name, default_template);
                                if (!section) {
                                    section = ChartingTemplateService.getFirstSection(section_template_name);
                                    $stateParams.sectionName = section.name;
                                }
                                if (section.allowMultiple &&
                                    !ChartingTemplateService.isEncounterCharting(chartStore.model.charting_client)) {
                                    $stateParams.templateUrl =
                                        '/y/templates/clinical-charts/sections/common-multiple-section.html';
                                }
                                else {
                                    $stateParams.templateUrl =
                                        '/y/templates/clinical-charts/sections/common-section.html';
                                }
                                if (section.sections) {
                                    for (var _i = 0, _a = section.sections; _i < _a.length; _i++) {
                                        var s = _a[_i];
                                        ChartingTemplateService.chartStoreSectionInit(s, chartStore, CurrentPatient.patient);
                                    }
                                }
                                else {
                                    ChartingTemplateService.chartStoreSectionInit(section, chartStore, CurrentPatient.patient);
                                }
                                return chartStore;
                            }, function (e) {
                                console.log('Error Resolving currentPatient');
                                console.log(e);
                                logger.error('Patient id ' +
                                    $params.patientId +
                                    ' could not be retrieved from the server and is not in the cache', 'Error retrieving patient');
                                throw e;
                            });
                        },
                    ],
                    formModel: [
                        'chartStore',
                        '$stateParams',
                        'ChartingTemplateService',
                        function (chartStore, $stateParams, ChartingTemplateService) {
                            return ChartingTemplateService.formModelInit(chartStore, $stateParams.sectionName);
                        },
                    ],
                },
            });
        }
        ClinicalChartStates.$inject = ['$stateProvider'];
        return ClinicalChartStates;
    }());
    angular.module('consolo').config(ClinicalChartStates);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ClinicalChartsDashboardESignController = /** @class */ (function () {
            function ClinicalChartsDashboardESignController($mdDialog, ClinicalChartEntry, logger, PersistedItem, NetworkManager, DraftSyncService, syncedCharts, $http) {
                this.$mdDialog = $mdDialog;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.logger = logger;
                this.PersistedItem = PersistedItem;
                this.NetworkManager = NetworkManager;
                this.DraftSyncService = DraftSyncService;
                this.syncedCharts = syncedCharts;
                this.$http = $http;
                this.modalText = "The following charts were successfully synced.\n      Please select the charts you wish to sign below or uncheck the boxes of any\n      charts you want to review further.";
                if (NetworkManager.isOffline()) {
                    this.modalText = "The following charts will be synced when you go online.\n          Please select the charts you wish to sign when you sync below or uncheck the\n          boxes of any charts you want to review further.";
                }
            }
            ClinicalChartsDashboardESignController.prototype.eSignAll = function () {
                var _this = this;
                this.syncedCharts.forEach(function (chart) {
                    if (chart.addSignature) {
                        if (_this.NetworkManager.isOffline()) {
                            _this.DraftSyncService.setSignatureFlag(chart);
                        }
                        else {
                            var esig = {};
                            var chartCopy = { model: angular.copy(chart.model) };
                            _this.$http
                                .post('/api/v1/clinical_charts/' + chartCopy.model.id + '/signatures', esig)
                                .then(function () {
                                _this.logger.success('Chart ' + chart.model.id + ' Signed');
                                chart.signed = true;
                            }, function (errors) {
                                _this.logger.error('Unable to sign Chart ' + chart.model.id);
                                _this.errors = errors.errors;
                                var chartStore = _this.ClinicalChartEntry.getById(chart.model.id).then(function (chartEntry) {
                                    return new _this.PersistedItem('clinical_chart_entry', chartEntry);
                                });
                                chartStore.model.errors = errors.errors;
                                chart.errorCount = errors.errors.length;
                            });
                        }
                    }
                });
            };
            ClinicalChartsDashboardESignController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            ClinicalChartsDashboardESignController.$inject = [
                '$mdDialog',
                'ClinicalChartEntry',
                'logger',
                'PersistedItem',
                'NetworkManager',
                'DraftSyncService',
                'syncedCharts',
                '$http',
            ];
            return ClinicalChartsDashboardESignController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsDashboardESignController', ClinicalChartsDashboardESignController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ClinicalChartsDashboardController = /** @class */ (function () {
            function ClinicalChartsDashboardController($filter, $mdDialog, $mdMedia, $q, $scope, $state, cache, ClinicalChartEntry, CarePlanProblemEntry, logger, CurrentPatient, persistenceStore, PersistedItem, Session, PdfGenerator, DraftSyncService, NetworkManager, DeviceSettings, Permissions, options, ChartingTemplateService, EncounterTypes, ChartingPrintService, ChartUtilService, ClinicalChartDraftsService, ChartDraftsSearchFilterService, ChartDeviceDraftsService, $timeout, $interval, timeAgoFilter, $window, $http, pdfer) {
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.$mdMedia = $mdMedia;
                this.$q = $q;
                this.$scope = $scope;
                this.$state = $state;
                this.cache = cache;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.persistenceStore = persistenceStore;
                this.PersistedItem = PersistedItem;
                this.Session = Session;
                this.PdfGenerator = PdfGenerator;
                this.DraftSyncService = DraftSyncService;
                this.NetworkManager = NetworkManager;
                this.DeviceSettings = DeviceSettings;
                this.Permissions = Permissions;
                this.options = options;
                this.ChartingTemplateService = ChartingTemplateService;
                this.EncounterTypes = EncounterTypes;
                this.ChartingPrintService = ChartingPrintService;
                this.ChartUtilService = ChartUtilService;
                this.ClinicalChartDraftsService = ClinicalChartDraftsService;
                this.ChartDraftsSearchFilterService = ChartDraftsSearchFilterService;
                this.ChartDeviceDraftsService = ChartDeviceDraftsService;
                this.$timeout = $timeout;
                this.$interval = $interval;
                this.timeAgoFilter = timeAgoFilter;
                this.$window = $window;
                this.$http = $http;
                this.pdfer = pdfer;
                this.errorMessage = '';
                this.hideHeader = true;
                this.serverCharts = [];
                this.syncedCharts = [];
                this.assignedPatients = [];
                this.syncing = false;
                this.user_dashboard_url = '';
                this.base_roles = [];
                this.loadingTransition = false;
                this.showname = true;
                this.user = {};
                this.currentPage = { page: 0, totalPages: 0, items: [] };
                this.online = false;
                this.patientId = this.$state.params.patientId;
                this.userId = this.Session.getUser().id;
                this.title = this.$state.current.data.title;
                this.online = this.NetworkManager.isOnline();
                this.codeRyteEnabled = this.Session.get().agency.has_coderyte;
                this.agencyEncounterCharting = this.Session.get().agency.has_encounter_charting;
                this.printClassicText = this.agencyEncounterCharting ? 'Print (Classic)' : 'Print';
                this.printEncounterText = this.pdfer.enabled ? 'Print' : 'Print (Encounter)';
                this.activate();
            }
            ClinicalChartsDashboardController.prototype.activate = function () {
                var _this = this;
                this.useTabletOptimized = this.DeviceSettings.getValue(this.DeviceSettings.pages.cookie, this.DeviceSettings.settings.charting_tablet_mode);
                this.base_roles = this.options.get('base_roles');
                this.NetworkManager.offlineHandler.onEvent('ClinicalChartsDashboardController', function () {
                    _this.online = false;
                });
                this.NetworkManager.onlineHandler.onEvent('ClinicalChartsDashboardController', function () {
                    _this.online = true;
                    _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                    _this.getServerCharts(_this.currentPage.page);
                });
                this.DraftSyncService.autoSyncHandler.onEvent('ClinicalChartsDashboardController', function () {
                    _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                    _this.getServerCharts(_this.currentPage.page);
                });
                // So the dashboard link in the breadcrumbs will respect user's preferred dashboard
                this.user = this.Session.getUser();
                this.user_dashboard_url = this.user.dashboard_url;
                this.ChartDeviceDraftsService.getDrafts(this.patientId);
                if (this.NetworkManager.isOnline()) {
                    this.getServerCharts(this.currentPage.page);
                }
                this.$scope.$watch(function () {
                    return _this.$mdMedia('gt-sm');
                }, function (small) {
                    _this.showname = !small;
                });
                this.$scope.$on('$destroy', function () {
                    _this.ChartDeviceDraftsService.resetDrafts();
                });
            };
            ClinicalChartsDashboardController.prototype.getServerCharts = function (page) {
                var _this = this;
                if (!page) {
                    page = 1;
                }
                if (this.$state.params.patientId) {
                    this.patient = this.CurrentPatient.patient;
                    this.loadingCharts = true;
                    this.ClinicalChartEntry.getHeadersByPatientId(this.patient.id, page, 50)
                        .$promise.then(function (data) {
                        _this.errorMessage = 'No previously synced chart entries exist.';
                        _this.base_roles.$promise.then(function () {
                            for (var _i = 0, _a = data.items; _i < _a.length; _i++) {
                                var chart = _a[_i];
                                chart.editable = _this.ChartUtilService.checkChartPermissions(chart);
                                chart.canCreateAddendum = _this.Permissions.can('create', 'addendums');
                                _this.ChartUtilService.getClientName(chart);
                            }
                        });
                        _this.currentPage = {
                            page: data.page,
                            totalPages: data.pages,
                            items: data.items,
                        };
                        _this.serverCharts = _this.currentPage.items;
                    }, function (error) {
                        _this.errorMessage = 'Existing chart entries unavailable';
                    })
                        .finally(function () {
                        _this.loadingCharts = false;
                    });
                }
                else {
                    this.loadingCharts = false;
                }
            };
            // Download server draft to device
            ClinicalChartsDashboardController.prototype.downloadChartDraft = function (index, draft) {
                var _this = this;
                if (this.ChartDeviceDraftsService.deviceDraftPatientIds().indexOf(draft.model.patient_id) === -1) {
                    var serverDraft = this.ChartDraftsSearchFilterService.serverDrafts[index];
                    var persistedServerDraft = this.PersistedItem('clinical_chart_entry', new this.ClinicalChartEntry(serverDraft.model));
                    persistedServerDraft.model.server = true;
                    persistedServerDraft.model.syncing = false;
                    persistedServerDraft.model.server_draft_updated_at = draft.model.updated_at;
                    persistedServerDraft
                        .saveOnDevice(true)
                        .then(function () {
                        _this.ChartDeviceDraftsService.loadingDrafts = true;
                        _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                    })
                        .catch(function (error) {
                        _this.logger.error(error);
                        throw error;
                    });
                }
                else {
                    this.logger.error('Only one server draft can be downloaded to the device at a time. Please complete current chart draft and try again.', 'Unable To Download Server Draft');
                }
            };
            ClinicalChartsDashboardController.prototype.syncChartDraft = function (draft) {
                var _this = this;
                var persistedServerDraft = this.PersistedItem('clinical_chart_entry', draft.model);
                persistedServerDraft.model.updated_at = draft.updated_at;
                persistedServerDraft
                    .saveDraft(true)
                    .then(function () {
                    _this.ChartDeviceDraftsService.loadingDrafts = true;
                    _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                })
                    .catch(function (error) {
                    _this.logger.error(error);
                    throw error;
                });
            };
            ClinicalChartsDashboardController.prototype.orderGroups = function (arr) {
                return this.$filter('orderBy')(this.$filter('map')(arr, 'model.patient.name'));
            };
            ClinicalChartsDashboardController.prototype.addAddendum = function (entry, $event) {
                this.ChartUtilService.addAddendum(entry, $event);
            };
            ClinicalChartsDashboardController.prototype.addChartEntry = function (id) {
                this.$state
                    .go('patients.clinical-chart-entry-new.section', {
                    patientId: id,
                    sectionName: 'general',
                })
                    .catch(angular.noop);
            };
            ClinicalChartsDashboardController.prototype.goToPatient = function (patient) {
                this.loadingTransition = true;
                this.$state.go('patients.clinical-chart', { patientId: patient.id });
            };
            ClinicalChartsDashboardController.prototype.navigateTo = function (model, $event) {
                if (model) {
                    if (model.id) {
                        if (!this.useTabletOptimized &&
                            this.online &&
                            !this.ChartingTemplateService.isEncounterCharting(model.charting_client)) {
                            var link = '/patients/' + this.patientId.toString() + '/clinical_charts/' + model.id;
                            if (!model.locked) {
                                link += '/edit';
                            }
                            document.location.href = link;
                            return;
                        }
                        var template = this.ChartingTemplateService.getTemplateName(model);
                        var initialSection = this.ChartingTemplateService.getFirstSection(template);
                        this.$state.go('patients.clinical-chart-entry-edit.section', {
                            patientId: model.patient_id,
                            chartEntryId: model.id,
                            sectionName: initialSection.name || 'general',
                        });
                    }
                    else {
                        if (!model.isEncounterCharting) {
                            this.$state.go('patients.clinical-chart-entry-new.section', {
                                patientId: model.patient_id,
                                sectionName: 'general',
                            });
                        }
                        else {
                            if (isNaN(model.encounter_type_id)) {
                                model.encounter_type_id = 'encounter_charting';
                            }
                            if (model.initial_section_name === '_ec_general') {
                                model.initial_section_name = 'encounter_charting';
                            }
                            var obj = {
                                selectedPatient: model.patient,
                                role_id: model.role_id,
                                encounter_type_id: model.encounter_type_id,
                                encounter_type: model.encounter_type,
                                charting_client: model.encounter_type.charting_type,
                                initial_section_name: model.initial_section_name || 'encounter_charting',
                            };
                            this.$state.go('patients.clinical-chart-entry-new.section', {
                                patientId: model.patient_id,
                                sectionName: obj.initial_section_name,
                                initObj: obj,
                            });
                        }
                    }
                }
                else {
                    if (this.user.flags.has_encounter_charting) {
                        if (!this.ChartDeviceDraftsService.newDraft) {
                            this.encounterModal($event);
                        }
                        else {
                            this.$state.go('patients.clinical-chart-entry-new.section', {
                                patientId: this.patientId,
                                sectionName: 'encounter_charting',
                            });
                        }
                    }
                    else {
                        this.$state.go('patients.clinical-chart-entry-new.section', {
                            patientId: this.patientId,
                            sectionName: 'general',
                        });
                    }
                }
            };
            ClinicalChartsDashboardController.prototype.createNew = function ($event) {
                if (this.patientId) {
                    if (this.user.flags.has_encounter_charting && !this.ChartDeviceDraftsService.newDraft) {
                        this.encounterModal($event);
                    }
                    else {
                        this.navigateTo(null, $event);
                    }
                    // if (this.user.flags.has_encounter_charting && !this.newDraft) {
                    //   this.encounterModal($event);
                    // } else if (!this.useTabletOptimized && this.online) {
                    //   document.location.href =
                    //     '/patients/' + this.patientId.toString() + '/clinical_charts/new';
                    // } else {
                    //   this.navigateTo(null, $event);
                    // }
                }
            };
            ClinicalChartsDashboardController.prototype.printClinicalChart = function (clinicalChartId) {
                this.PdfGenerator.printClinicalCharts([clinicalChartId]);
            };
            ClinicalChartsDashboardController.prototype.printClientClinicalChart = function (clinicalChartId) {
                if (this.pdfer.enabled) {
                    this.pdfer.generatePdfFromStates(['patients.clinical-chart-view'], [{ patientId: this.patientId, id: clinicalChartId }], clinicalChartId, 'ClinicalChart', false, {}, false, false, '', '', this.patientId, false, '#chart-wait-for-selector');
                }
                else {
                    this.ChartingPrintService.fetchAndPrintChart(clinicalChartId);
                }
            };
            ClinicalChartsDashboardController.prototype.faxChart = function (chartId, patientId) {
                this.ChartingPrintService.faxChart(chartId, patientId);
            };
            ClinicalChartsDashboardController.prototype.signSingle = function (chart, $event) {
                var _this = this;
                if (this.syncing) {
                    this.logger.info('Currently syncing drafts', 'Please Wait');
                    return;
                }
                this.syncing = true;
                this.$http.post('/api/v1/clinical_charts/' + chart.id + '/signatures', {}).then(function (resp) {
                    console.log(resp);
                    _this.logger.success('Chart ' + chart.id + ' Signed');
                    chart.signed = true;
                    chart.editable = false;
                    _this.syncing = false;
                }, function (errors) {
                    _this.logger.error('Unable to sign Chart ' + chart.model.id);
                    _this.syncing = false;
                });
                $event.stopPropagation();
            };
            ClinicalChartsDashboardController.prototype.syncSingle = function (index, chart, $event) {
                var _this = this;
                if (this.syncing) {
                    this.logger.info('Currently syncing drafts', 'Please Wait');
                    return;
                }
                this.syncing = true;
                this.syncedCharts = [];
                var newEntry = !chart.model.id;
                if (chart.draft) {
                    this.DraftSyncService.syncChart(chart)
                        .then(function (newChart) {
                        if (!newChart.errorCount) {
                            _this.syncedCharts.push(newChart);
                            if (_this.online) {
                                if (newEntry) {
                                    _this.ChartDeviceDraftsService.newDraft = null;
                                    _this.ChartUtilService.getClientName(newChart.model);
                                    _this.serverCharts.push(newChart.model);
                                }
                                var pos = _this.ChartDeviceDraftsService.drafts[index].drafts.indexOf(chart);
                                _this.ChartDeviceDraftsService.drafts[index].drafts.splice(pos, 1);
                                if (_this.ChartDeviceDraftsService.drafts[index].drafts.length === 0) {
                                    _this.ChartDeviceDraftsService.drafts.splice(index, 1);
                                }
                            }
                            _this.eSignConfirm();
                        }
                        _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                        _this.syncing = false;
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
                else {
                    this.syncedCharts.push(chart);
                    this.eSignConfirm();
                    this.syncing = false;
                }
                $event.stopPropagation();
            };
            ClinicalChartsDashboardController.prototype.syncAll = function () {
                var _this = this;
                if (this.syncing) {
                    this.logger.info('Currently syncing drafts', 'Please Wait');
                    return;
                }
                this.syncing = true;
                this.syncedCharts = [];
                if (this.online) {
                    this.DraftSyncService.syncAllCharts()
                        .then(function (charts) {
                        _this.syncedCharts = charts;
                        _this.eSignConfirm().then(function () {
                            _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                        });
                        for (var _i = 0, _a = _this.syncedCharts; _i < _a.length; _i++) {
                            var c = _a[_i];
                            if (c.newEntry) {
                                _this.serverCharts.push(c);
                            }
                        }
                    })
                        .finally(function () {
                        _this.syncing = false;
                    });
                }
                else {
                    for (var _i = 0, _a = this.ChartDeviceDraftsService.drafts; _i < _a.length; _i++) {
                        var d = _a[_i];
                        this.DraftSyncService.setSyncFlag(d);
                    }
                    this.syncing = false;
                    this.eSignConfirm();
                }
            };
            ClinicalChartsDashboardController.prototype.openMenu = function ($mdMenu, $event) {
                $mdMenu.open($event);
                $event.stopPropagation();
            };
            ClinicalChartsDashboardController.prototype.encounterModal = function ($event) {
                this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/clinical-charts/encounter-types/selector-modal.html',
                    controller: 'EncounterSelectorModalController as $ctrl',
                    locals: { currentPatient: this.CurrentPatient.patient, clinical_trip_stop_point_id: null },
                });
            };
            ClinicalChartsDashboardController.prototype.eSignConfirm = function () {
                var _this = this;
                if (this.syncedCharts.length > 0) {
                    return this.$mdDialog
                        .show({
                        clickOutsideToClose: false,
                        templateUrl: '/y/templates/clinical-charts/dashboard-esign-modal.html',
                        controller: 'ClinicalChartsDashboardESignController as vm',
                        locals: { syncedCharts: this.syncedCharts },
                    })
                        .then(function () {
                        //clean up any charts that were just signed but not saved
                        if (_this.online) {
                            var signedCharts = _this.$filter('filter')(_this.ChartDeviceDraftsService.drafts, function (d) {
                                return !!d.signed;
                            });
                            for (var _i = 0, signedCharts_1 = signedCharts; _i < signedCharts_1.length; _i++) {
                                var chart = signedCharts_1[_i];
                                for (var _a = 0, _b = _this.ChartDeviceDraftsService.drafts; _a < _b.length; _a++) {
                                    var patient = _b[_a];
                                    patient.drafts.splice(patient.drafts.indexOf(chart), 1);
                                }
                            }
                        }
                    })
                        .finally(function () {
                        _this.getServerCharts(_this.currentPage.page);
                        return true;
                    });
                }
                else {
                    return false;
                }
            };
            ClinicalChartsDashboardController.prototype.goToChartSearch = function () {
                this.loadingTransition = true;
                if (this.patient) {
                    document.location.href = '/patients/' + this.patient.id + '/clinical_charts';
                }
                else {
                    document.location.href = '/clinical_charts';
                }
            };
            ClinicalChartsDashboardController.prototype.tagAsComprehensive = function (chart) {
                var _this = this;
                this.ChartUtilService.tagAsComprehensive(chart).then(function () {
                    for (var _i = 0, _a = _this.serverCharts; _i < _a.length; _i++) {
                        var entry = _a[_i];
                        if (entry.is_comprehensive && entry.patient_id === chart.patient_id) {
                            entry.show_tag_as_comprehensive = true;
                            entry.is_comprehensive = false;
                        }
                    }
                    chart.show_tag_as_comprehensive = false;
                    chart.is_comprehensive = true;
                });
            };
            ClinicalChartsDashboardController.prototype.voidChart = function (chart, $event) {
                var _this = this;
                this.ChartUtilService.voidChart(chart, $event).then(function (success) {
                    if (success) {
                        _this.serverCharts.splice(_this.serverCharts.indexOf(chart), 1);
                    }
                });
            };
            ClinicalChartsDashboardController.prototype.viewCodeRyteReport = function (chart, $event) {
                var url = document.location.origin + "/patients/" + chart.patient_id + "/clinical_charts/" + chart.id + "/coderyte_report";
                this.$window.open(url, '_blank');
            };
            ClinicalChartsDashboardController.prototype.recoverChart = function (chart, $event) {
                this.ChartUtilService.recoverChart(chart, $event).then(function (success) {
                    if (success && chart.deleted) {
                        chart.deleted = false;
                    }
                });
            };
            ClinicalChartsDashboardController.prototype.deleteDeviceDraft = function (index, draft, $event) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Delete Chart Draft')
                    .targetEvent($event)
                    .textContent('Are you sure you wish to delete this chart draft?')
                    .ok('Okay')
                    .cancel('Cancel');
                $event.stopPropagation();
                return this.$mdDialog.show(confirmation).then(function () {
                    _this.completeDeviceDraftDelete(index, draft, $event);
                });
            };
            ClinicalChartsDashboardController.prototype.completeDeviceDraftDelete = function (index, draft, $event) {
                var _this = this;
                var chart_id = draft.model.id !== undefined ? draft.model.id : 'NEW:' + draft.model.patient_id;
                if (this.DraftSyncService.draftErrorExists('clinical_chart_entry', chart_id)) {
                    this.DraftSyncService.removeDraftError('clinical_chart_entry', chart_id);
                }
                this.persistenceStore.remove('clinical_chart_entry', draft.model).then(function () {
                    if (!draft.model.id) {
                        _this.ChartDeviceDraftsService.newDraft = null;
                    }
                    var id = draft.model.id ? draft.model.id.toString() : 'NEW';
                    var message = 'Draft ' + id + ' for ' + draft.model.patient.name + ' has been deleted';
                    _this.logger.success(message);
                    _this.ChartDraftsSearchFilterService.deviceDrafts.splice(index, 1);
                }, function (e) {
                    _this.logger.error(e);
                    _this.logger.error('Chart not deleted');
                });
            };
            ClinicalChartsDashboardController.prototype.deleteServerDraft = function (index, draft, $event) {
                var _this = this;
                var draftId = draft.model.temp_uuid;
                var hasDeviceDraft = this.ChartDeviceDraftsService.deviceDraftIds().indexOf(draftId) !== -1;
                var deviceDraftIndex = this.ChartDeviceDraftsService.deviceDraftIds().indexOf(draftId);
                var title = '';
                var textContent = '';
                title = 'Delete Server Chart Draft?';
                textContent = 'Are you sure you wish to delete this server side chart draft?';
                var confirmation = this.$mdDialog
                    .confirm()
                    .title(title)
                    .targetEvent($event)
                    .textContent(textContent)
                    .ok('Delete')
                    .cancel('Cancel');
                $event.stopPropagation();
                return this.$mdDialog.show(confirmation).then(function (response) {
                    _this.loadingCharts = true;
                    _this.ClinicalChartDraftsService.deleteClinicalChartDraft(draft.model.temp_uuid)
                        .then(function (response) {
                        _this.ChartDraftsSearchFilterService.serverDrafts.splice(index, 1);
                        var deletedDraft = response.data.data;
                        var id = deletedDraft.model.id ? deletedDraft.model.id.toString() : 'NEW';
                        var message = 'Draft ' + id + ' for ' + deletedDraft.model.patient.name + ' has been deleted';
                        _this.logger.success(message);
                        if (hasDeviceDraft) {
                            var chartStore = _this.ChartDraftsSearchFilterService.deviceDrafts[deviceDraftIndex];
                            delete chartStore.model.temp_uuid;
                            chartStore.model.server = false;
                            chartStore.model.syncing = false;
                            var deviceDraft = _this.PersistedItem('clinical_chart_entry', new _this.ClinicalChartEntry(chartStore.model));
                            deviceDraft.saveChartDraftOnDevice(true).then(function () {
                                // console.log('Device draft reset');
                                _this.loadingCharts = false;
                            });
                        }
                        else {
                            _this.loadingCharts = false;
                        }
                    })
                        .catch(function (error) {
                        console.log(error);
                        _this.loadingCharts = false;
                    });
                });
            };
            ClinicalChartsDashboardController.prototype.viewChartModal = function (id, draft, $event) {
                var _this = this;
                var _a, _b, _c, _d;
                if (draft) {
                    var chartStore = draft.model.server === true ?
                        this.ChartDraftsSearchFilterService.deviceDrafts[id] : this.ChartDraftsSearchFilterService.serverDrafts[id];
                    var currentDraft_1 = this.PersistedItem('clinical_chart_entry', new this.ClinicalChartEntry(chartStore.model));
                    var timeIn_1 = (_b = (_a = chartStore.model) === null || _a === void 0 ? void 0 : _a.patient_time) === null || _b === void 0 ? void 0 : _b.in_datetime;
                    var timeOut_1 = (_d = (_c = chartStore.model) === null || _c === void 0 ? void 0 : _c.patient_time) === null || _d === void 0 ? void 0 : _d.out_datetime;
                    this.ChartUtilService.viewChartModal(currentDraft_1, $event).then(function (sync) {
                        var _a, _b, _c, _d;
                        if (sync) {
                            if (currentDraft_1.pendingChartDraftSave) {
                                var pendingSync_1 = _this.$interval(function () {
                                    _this.loadingCharts = true;
                                    if (!currentDraft_1.pendingChartDraftSave) {
                                        _this.$interval.cancel(pendingSync_1);
                                        _this.loadingCharts = false;
                                        _this.syncSingle(id, draft, $event);
                                    }
                                }, 1000);
                            }
                            else {
                                _this.syncSingle(id, draft, $event);
                            }
                        }
                        else {
                            if (timeIn_1 !== ((_b = (_a = currentDraft_1.model) === null || _a === void 0 ? void 0 : _a.patient_time) === null || _b === void 0 ? void 0 : _b.in_datetime) ||
                                timeOut_1 !== ((_d = (_c = currentDraft_1.model) === null || _c === void 0 ? void 0 : _c.patient_time) === null || _d === void 0 ? void 0 : _d.out_datetime)) {
                                // console.log('Time changed');
                                _this.ChartDeviceDraftsService.getDrafts(_this.patientId);
                            }
                            else {
                                // console.log('Time is the same');
                            }
                        }
                    });
                }
                else {
                    this.loadingCharts = true;
                    this.ChartUtilService.getChartStoreById(id).then(function (cs) {
                        _this.loadingCharts = false;
                        _this.ChartUtilService.viewChartModal(cs, $event);
                    });
                }
                localStorage.setItem('ViewEncounterChart', 'true');
            };
            ClinicalChartsDashboardController.prototype.stopProp = function ($event) {
                $event.stopPropagation();
                this.$mdDialog.hide();
            };
            ClinicalChartsDashboardController.prototype.getCloudIcon = function (draftType, draft) {
                if (draftType === 'device') {
                    if (draft.model.server) {
                        return 'file:cloud_done';
                    }
                    else {
                        return 'file:cloud_off';
                    }
                }
                else if (draftType === 'server') {
                    if (this.ChartDeviceDraftsService.deviceDraftIds().indexOf(draft.model.temp_uuid) !== -1) {
                        return 'file:cloud_done';
                    }
                    else {
                        return 'file:cloud_download';
                    }
                }
            };
            ClinicalChartsDashboardController.prototype.getChartingClient = function (client) {
                var description;
                switch (client) {
                    case 'encounter_charting':
                        description = 'Encounter Charting';
                        break;
                    case 'encounter_aide_charting':
                        description = 'Encounter Aide Charting';
                        break;
                    case 'custom':
                        description = 'Encounter Charting';
                        break;
                    case 'tablet_optimized':
                        description = 'Optimized Charting';
                        break;
                    case 'ios_aide_app':
                        description = 'iOS Aide Charting';
                        break;
                    case 'bootstrap_charting':
                        description = 'Classic Charting';
                        break;
                    case 'bootstrap_aide_charting':
                        description = 'Classic Aide Charting';
                        break;
                }
                return description;
            };
            ClinicalChartsDashboardController.$inject = [
                '$filter',
                '$mdDialog',
                '$mdMedia',
                '$q',
                '$scope',
                '$state',
                'cache',
                'ClinicalChartEntry',
                'CarePlanProblemEntry',
                'logger',
                'CurrentPatient',
                'persistenceStore',
                'PersistedItem',
                'SessionService',
                'PdfGenerator',
                'DraftSyncService',
                'NetworkManager',
                'DeviceSettingsService',
                'PermissionsService',
                'options',
                'ChartingTemplateService',
                'EncounterTypes',
                'ChartingPrintService',
                'ChartUtilService',
                'ClinicalChartDraftsService',
                'ChartDraftsSearchFilterService',
                'ChartDeviceDraftsService',
                '$timeout',
                '$interval',
                'timeAgoFilter',
                '$window',
                '$http',
                'pdfer',
            ];
            return ClinicalChartsDashboardController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsDashboardController', ClinicalChartsDashboardController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ClinicalChartsEditorController = /** @class */ (function () {
            function ClinicalChartsEditorController($filter, $mdDialog, $parse, $q, $scope, $state, $timeout, $window, AddAddendumModal, cache, CarePlanProblemEntry, chartStore, EncounterType, ChartingTemplateService, logger, Patient, PersistedItem, persistenceStore, SectionManager, Session, Permissions, NetworkManager, DraftSyncService, DeviceSettings, ChartDateTimeInteraction, $http, $mdSidenav, $interval, $mdMedia, ChartUtilService, ChartDeviceDraftsService, MileageAppIntegrationService, $mdPanel, pdfer) {
                var _this = this;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.$parse = $parse;
                this.$q = $q;
                this.$scope = $scope;
                this.$state = $state;
                this.$timeout = $timeout;
                this.$window = $window;
                this.AddAddendumModal = AddAddendumModal;
                this.cache = cache;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.chartStore = chartStore;
                this.EncounterType = EncounterType;
                this.ChartingTemplateService = ChartingTemplateService;
                this.logger = logger;
                this.Patient = Patient;
                this.PersistedItem = PersistedItem;
                this.persistenceStore = persistenceStore;
                this.SectionManager = SectionManager;
                this.Session = Session;
                this.Permissions = Permissions;
                this.NetworkManager = NetworkManager;
                this.DraftSyncService = DraftSyncService;
                this.DeviceSettings = DeviceSettings;
                this.ChartDateTimeInteraction = ChartDateTimeInteraction;
                this.$http = $http;
                this.$mdSidenav = $mdSidenav;
                this.$interval = $interval;
                this.$mdMedia = $mdMedia;
                this.ChartUtilService = ChartUtilService;
                this.ChartDeviceDraftsService = ChartDeviceDraftsService;
                this.MileageAppIntegrationService = MileageAppIntegrationService;
                this.$mdPanel = $mdPanel;
                this.pdfer = pdfer;
                this.saving = false;
                this.menuOpen = false;
                this.showSummary = false;
                this.agencySignature = false;
                this.roleSignature = false;
                this.requireSignature = false;
                this.summarySection = 'chart';
                this.autoSyncEnabled = false;
                this.showFilters = false;
                this.chartStore.disableSave = true;
                this.online = this.NetworkManager.isOnline();
                this.NetworkManager.onlineHandler.onEvent('ClinicalChartsEditorController', function () {
                    _this.online = true;
                });
                this.NetworkManager.offlineHandler.onEvent('ClinicalChartsEditorController', function () {
                    _this.online = false;
                });
                this.SectionManager.resetSectionManager();
                this.currentSection = this.SectionManager.currentSection;
                this.errors = [];
                this.encounterTypes = [];
                this.autoSyncEnabled = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.global, consolo.deviceSettings.Settings.charting_sync_mode);
                this.templateType = this.SectionManager.getTemplateType();
                this.isEncounterCharting = this.ChartingTemplateService.isEncounterCharting(this.chartStore.model.charting_client);
                this.locked = this.chartStore.model.locked;
                this.session = Session.get();
                this.user = Session.getUser();
                this.serviceLine = this.session.agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                this.agencySignature = this.session.agency.require_signatures;
                this.roleSignature = this.Permissions.has('require_signature', 'clinical_charts');
                this.requireSignature = this.agencySignature || this.roleSignature;
                this.$timeout(function () {
                    _this.menuOpen = true;
                    _this.$scope.$watch('vm.menuOpen', function () {
                        _this.menuOpen = true;
                    });
                }, 1500);
                this.activate();
                this.$scope.$on('$destroy', function () {
                    if (_this.requiredInterval) {
                        _this.$interval.cancel(_this.requiredInterval);
                        _this.MileageAppIntegrationService.message.editor_loaded = false;
                        _this.MileageAppIntegrationService.sendNotification();
                    }
                });
                this.editorPane = angular.element(document.getElementById('editorPane'));
                this.$scope.$on('$viewContentLoaded', function () {
                    if (!_this.MileageAppIntegrationService.message.editor_loaded) {
                        _this.MileageAppIntegrationService.message.editor_loaded = true;
                        _this.MileageAppIntegrationService.sendNotification();
                    }
                });
                this.$window.sendTime = function (message) {
                    var parsedMessage = JSON.parse(message);
                    _this.$scope.$apply(function () {
                        _this.MileageAppIntegrationService.appendTime(_this.chartStore, parsedMessage);
                        _this.chartStore.saveDraft();
                    });
                };
                $timeout(function () {
                    _this.chartStore.disableSave = false;
                }, 3000);
            }
            ClinicalChartsEditorController.prototype.goTo = function (section) {
                this.SectionManager.navigateTo(section, this.chartStore);
                this.scrollToTop();
            };
            ClinicalChartsEditorController.prototype.activate = function () {
                var _this = this;
                this.chartStore.model.disable_evv = true;
                this.encTypePromise = this.cache(this.EncounterType, 'encounter_types', {
                    maxAge: 10,
                })
                    .query({ active: true })
                    .$promise.then(function (encTypes) {
                    _this.encounterTypes = encTypes;
                    return _this.$q.when(true);
                });
                this.chartStore.getWorkingCopy().then(function (copy) {
                    // Check to see if a more up to date draft exists on the server
                    if (copy.draft) {
                        _this.chartStore
                            .checkForUpdated(copy)
                            .then(function (newerDraftExists) {
                            if (newerDraftExists) {
                                // If newer draft exists from server, reload to pull it down onto the device
                                _this.reloadChart();
                            }
                            else {
                                // If none exists or device copy is newest, just use draft already on the device
                                return _this.askToUseDraft(copy);
                            }
                        })
                            .catch(function (error) {
                            console.log(error.errors);
                            return _this.askToUseDraft(copy);
                        });
                    }
                    else {
                        var m = _this.chartStore.useCopy(copy);
                        _this.initializeChart();
                        return m;
                    }
                }, function () {
                    if (!_this.chartStore.model.id &&
                        _this.user.flags.has_encounter_charting &&
                        !_this.chartStore.model.encounter_type) {
                        _this.$mdDialog.show({
                            clickOutsideToClose: false,
                            bindToController: true,
                            templateUrl: '/y/templates/clinical-charts/encounter-types/selector-modal.html',
                            controller: 'EncounterSelectorModalController as $ctrl',
                            locals: {
                                currentPatient: _this.chartStore.model.patient,
                                clinical_trip_stop_point_id: _this.chartStore.model.clinical_trip_stop_point_id,
                            },
                        });
                    }
                    else {
                        _this.initializeChart();
                    }
                });
                this.DraftSyncService.autoSyncHandler.onEvent('ClinicalChartsDashboardController', function () {
                    if (_this.$state.current.name.indexOf('patients.clinical-chart') >= 0) {
                        _this.postAutoSync();
                    }
                    else {
                    }
                });
            };
            ClinicalChartsEditorController.prototype.postAutoSync = function () {
                var _this = this;
                this.logger.info('Charts have been auto synced. Returning to dashboard.');
                this.$timeout(function () {
                    _this.$state.go('patients.clinical-chart', {
                        patientId: _this.chartStore.model.patient_id,
                    });
                }, 1000);
            };
            ClinicalChartsEditorController.prototype.addAddendum = function ($event) {
                var sectionId = this.chartStore.model[this.currentSection.model][0]
                    ? this.chartStore.model[this.currentSection.model][0].id
                    : null;
                this.AddAddendumModal.show(this.chartStore.model, $event, sectionId);
            };
            ClinicalChartsEditorController.prototype.askToUseDraft = function (draft) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Draft Found')
                    .textContent('A draft was found for this chart entry. Do you want to use it?')
                    .ok('Yes')
                    .cancel('No, discard it');
                return this.$mdDialog.show(confirmation).then(function () {
                    _this.reviewCarePlanProblemsIfNeeded(draft).then(function () {
                        draft.model.effective_date = new Date(draft.model.effective_date);
                        var reload = _this.chartStore.model.charting_client !== draft.model.charting_client;
                        _this.chartStore.useCopy(draft);
                        _this.isEncounterCharting = _this.ChartingTemplateService.isEncounterCharting(_this.chartStore.model.charting_client);
                        if (reload) {
                            if (!_this.isEncounterCharting) {
                                reload = !_this.ChartingTemplateService.screenExists(_this.$state.params.sectionName, 'tablet_optimized');
                            }
                            else {
                                reload = !_this.ChartingTemplateService.screenExists(_this.$state.params.sectionName, _this.chartStore.model.encounter_type_id || 'encounter_charting');
                            }
                        }
                        _this.SectionManager.init(_this.chartStore);
                        _this.pinSummary = _this.isEncounterCharting;
                        _this.chartStore.model.disable_evv = false;
                        if (_this.chartStore.model.encounter_type_id) {
                            var encType = _this.$filter('filter')(_this.encounterTypes, function (b) {
                                return b.id === _this.chartStore.model.encounter_type_id;
                            });
                            _this.SectionManager.updateHasEncounterType(_this.chartStore.model.encounter_type_id, encType[0]);
                            _this.templateType = _this.SectionManager.getTemplateType();
                            if (_this.templateType === 'encounter_charting' || _this.templateType === 'custom') {
                                _this.activateEncounterTypeSections(_this.chartStore);
                            }
                        }
                        else {
                            _this.SectionManager.updateHasEncounterType(null);
                        }
                        _this.$timeout(function () {
                            var x = _this.SectionManager.checkRequiredFields(_this.chartStore);
                        }, 250);
                        _this.$timeout(function () {
                            if (_this.$state.params.draft_redirect) {
                                _this.SectionManager.navigateToByName(_this.$state.params.draft_redirect, _this.chartStore);
                            }
                            else if (reload) {
                                var firstSection = _this.ChartingTemplateService.getFirstSection(_this.chartStore.model.encounter_type_id);
                                _this.SectionManager.navigateToByName(firstSection.name, _this.chartStore);
                            }
                            else {
                                _this.$scope.$broadcast('reloadFormDefinition', {
                                    errors: draft.model.errors,
                                });
                            }
                        });
                    });
                }, function () {
                    var reallySure = _this.$mdDialog
                        .confirm()
                        .title('Are you sure?')
                        .textContent('Do you really want to delete this draft? This action cannot be undone.')
                        .ok('Yes')
                        .cancel('No');
                    return _this.$mdDialog.show(reallySure).then(function () {
                        var chart_id = draft.model.id !== undefined ? draft.model.id : 'NEW:' + draft.model.patient_id;
                        if (_this.DraftSyncService.draftErrorExists('clinical_chart_entry', chart_id)) {
                            _this.DraftSyncService.removeDraftError('clinical_chart_entry', chart_id);
                        }
                        var client = draft.model.charting_client;
                        _this.chartStore.removeCopy(draft).then(function () {
                            if (_this.$state.current.name === 'patients.clinical-chart-entry-new.section' &&
                                (client === 'custom' ||
                                    client === 'encounter_charting' ||
                                    _this.user.flags.has_encounter_charting)) {
                                _this.$mdDialog.show({
                                    clickOutsideToClose: false,
                                    bindToController: true,
                                    templateUrl: '/y/templates/clinical-charts/encounter-types/selector-modal.html',
                                    controller: 'EncounterSelectorModalController as $ctrl',
                                    locals: {
                                        currentPatient: _this.chartStore.model.patient,
                                        clinical_trip_stop_point_id: _this.chartStore.model
                                            .clinical_trip_stop_point_id,
                                    },
                                });
                            }
                            else {
                                _this.initializeChart();
                            }
                        });
                    }, function () {
                        return _this.askToUseDraft(draft);
                    });
                });
            };
            ClinicalChartsEditorController.prototype.reviewCarePlanProblemsIfNeeded = function (draft) {
                var _this = this;
                if (!draft.model.care_plan_problem_updates) {
                    return this.$q.resolve();
                }
                var promises = [];
                var hasPendingInterventions = false;
                draft.model.care_plan_problem_updates.forEach(function (cpp) {
                    if (cpp.care_plan_problem_id) {
                        promises.push(_this.CarePlanProblemEntry.get({
                            patient_id: _this.chartStore.model.patient_id,
                            id: cpp.care_plan_problem_id,
                        }).$promise);
                    }
                });
                return this.$q
                    .all(promises)
                    .then(function (updatedCpps) {
                    updatedCpps.forEach(function (updatedCpp) {
                        var cpp = draft.model.care_plan_problem_updates.filter(function (c) { return c.care_plan_problem_id === updatedCpp.id; })[0];
                        cpp.pending_interventions = [];
                        cpp.resolved_interventions = [];
                        cpp.problem = updatedCpp.problem;
                        cpp.updated_at = updatedCpp.updated_at;
                        updatedCpp.interventions.forEach(function (updatedIntervention) {
                            var exists = cpp.interventions.filter(function (intervention) {
                                return intervention.care_plan_problem_intervention_id === updatedIntervention.id;
                            })[0];
                            if (!exists) {
                                exists = updatedCpp.resolved_interventions.filter(function (intervention_id) { return intervention_id === updatedIntervention.id; })[0];
                            }
                            if (!exists) {
                                hasPendingInterventions = true;
                                cpp.pending_interventions.push(updatedIntervention);
                            }
                        });
                    });
                    return;
                })
                    .catch(function () {
                    return _this.$q.resolve();
                })
                    .then(function () {
                    if (!hasPendingInterventions) {
                        return _this.$q.resolve();
                    }
                    return _this.$mdDialog
                        .show({
                        controller: 'CarePlanProblemReviewModalController as vm',
                        templateUrl: '/y/templates/clinical-charts/sections/care-plan-problem-review-modal.html',
                        locals: { carePlanProblems: draft.model.care_plan_problem_updates },
                    })
                        .then(function (carePlanProblems) {
                        carePlanProblems.forEach(function (cpp) {
                            if (cpp._updated) {
                                for (var i = 0; i < draft.model.care_plan_problem_updates.length; i++) {
                                    if (draft.model.care_plan_problem_updates[i].care_plan_problem_id ===
                                        cpp.care_plan_problem_id) {
                                        for (var ii = 0; ii < cpp.pending_interventions.length; ii++) {
                                            draft.model.care_plan_problem_updates[i].interventions.push({
                                                care_plan_problem_intervention_id: cpp.pending_interventions[ii].id,
                                                care_plan_problem_intervention_uuid: cpp.pending_interventions[ii].uuid,
                                                care_plan_problem_update_id: cpp.care_plan_problem_id,
                                                name: cpp.pending_interventions[ii].name,
                                                due_date: cpp.pending_interventions[ii].due_date,
                                                chart_section: cpp.pending_interventions[ii].chart_section,
                                            });
                                        }
                                        break;
                                    }
                                }
                            }
                        });
                    })
                        .catch(function () {
                        return _this.$q.resolve();
                    });
                });
            };
            ClinicalChartsEditorController.prototype.addMultipleSection = function () {
                if (this.currentSection.allowMultiple) {
                    this.chartStore.model[this.currentSection.model].push({ showItem: true });
                }
            };
            ClinicalChartsEditorController.prototype.deleteSection = function ($event) {
                var _this = this;
                var cannotDelete = this.$mdDialog
                    .alert()
                    .title('Error')
                    .textContent('This section is required by the selected encounter type and cannot be deleted.')
                    .ok('OK')
                    .targetEvent($event);
                if (this.chartStore.model.encounter_type_id) {
                    var encType = this.$filter('filter')(this.encounterTypes, function (b) {
                        return b.id === _this.chartStore.model.encounter_type_id;
                    })[0];
                    if (this.$parse('required_fields.' + this.SectionManager.currentSection.name)(encType)) {
                        this.$mdDialog.show(cannotDelete);
                    }
                    else {
                        this.deleteConfirm($event);
                    }
                }
                else {
                    this.deleteConfirm($event);
                }
            };
            ClinicalChartsEditorController.prototype.isCarePlanProblem = function () {
                return (this.$state.includes('patients.clinical-chart-entry-new.care-plan-problem') ||
                    this.$state.includes('patients.clinical-chart-entry-edit.care-plan-problem'));
            };
            ClinicalChartsEditorController.prototype.removeCarePlanProblem = function ($event) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Are you sure?')
                    .textContent('Do you really want to delete this section? This action cannot be undone.')
                    .ok('Yes')
                    .cancel('No')
                    .targetEvent($event);
                this.$mdDialog.show(confirmation).then(function () {
                    _this.SectionManager.removeFromClinicalChart(_this.chartStore);
                });
            };
            ClinicalChartsEditorController.prototype.sectionCanDelete = function (idx) {
                var noID = true;
                if (this.currentSection.model === 'general' ||
                    (this.currentSection.model === 'electronic_visit_verification' &&
                        this.chartStore.model.id !== undefined) ||
                    this.currentSection.allowMultiple ||
                    this.locked ||
                    this.chartStore.model[this.currentSection.model] === undefined) {
                    return false;
                }
                if (angular.isArray(this.chartStore.model[this.currentSection.model]) &&
                    this.chartStore.model[this.currentSection.model].length > 0) {
                    noID = this.chartStore.model[this.currentSection.model][idx].id === undefined;
                }
                else {
                    noID = this.chartStore.model[this.currentSection.model].id === undefined;
                }
                return noID;
            };
            ClinicalChartsEditorController.prototype.checkSection = function (modelName, idx) {
                if (idx === void 0) { idx = 0; }
                var noDelete = false;
                if (modelName === 'general' ||
                    (modelName === 'electronic_visit_verification' && this.chartStore.model.id !== undefined) ||
                    this.currentSection.allowMultiple ||
                    this.locked ||
                    this.chartStore.model[modelName] === undefined) {
                    return true;
                }
                if (angular.isArray(this.chartStore.model[modelName]) &&
                    this.chartStore.model[modelName].length > 0) {
                    noDelete = this.chartStore.model[modelName][idx].id !== undefined;
                }
                else {
                    noDelete = this.chartStore.model[modelName].id !== undefined;
                }
            };
            ClinicalChartsEditorController.prototype.encounterSectionCanDelete = function () {
                var noDelete = false;
                if (this.currentSection.has_et_required) {
                    return false;
                }
                if (this.currentSection.sections) {
                    for (var _i = 0, _a = this.currentSection.sections; _i < _a.length; _i++) {
                        var sub = _a[_i];
                        if (sub.allowMultiple &&
                            this.chartStore.model[sub.model] &&
                            this.chartStore.model[sub.model].length > 1) {
                            return true;
                        }
                        noDelete = noDelete || this.checkSection(sub.model);
                    }
                }
                return !noDelete;
            };
            // encounterSectionCanDelete() {
            //   if (this.currentSection.has_required) {
            //     if (this.currentSection.allowMultiple) {
            //       if (this.currentSection.sections) {
            //         for (let sub of this.currentSection.sections) {
            //           if (
            //             sub.allowMultiple &&
            //             this.chartStore.model[sub.model] &&
            //             this.chartStore.model[sub.model].length > 1
            //           ) {
            //             return true;
            //           }
            //         }
            //       } else {
            //         if (
            //           this.chartStore.model[this.currentSection.model] &&
            //           this.chartStore.model[this.currentSection.model].length > 1
            //         ) {
            //           return true;
            //         }
            //       }
            //     } else {
            //       return false;
            //     }
            //   } else if (this.currentSection.allowMultiple) {
            //     return true;
            //   }
            //   if (this.currentSection.sections) {
            //     let hasID = false;
            //     if (
            //       angular.isArray(this.chartStore.model[this.currentSection.sections[0].model]) &&
            //       this.chartStore.model[this.currentSection.sections[0].model].length > 0
            //     ) {
            //       return this.chartStore.model[this.currentSection.sections[0].model][0].id === undefined;
            //     } else {
            //       return this.chartStore.model[this.currentSection.sections[0].model].id === undefined;
            //     }
            //   } else {
            //     if (
            //       angular.isArray(this.chartStore.model[this.currentSection.model]) &&
            //       this.chartStore.model[this.currentSection.model].length > 0
            //     ) {
            //       return this.chartStore.model[this.currentSection.model][0].id === undefined;
            //     } else if (!this.chartStore.model[this.currentSection.model]) {
            //       return this.chartStore.model[this.currentSection.model] === undefined;
            //     } else {
            //       return this.chartStore.model[this.currentSection.model].id === undefined;
            //     }
            //   }
            // }
            ClinicalChartsEditorController.prototype.deleteConfirm = function ($event) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Are you sure?')
                    .textContent('Do you really want to delete this section? This action cannot be undone.')
                    .ok('Yes')
                    .cancel('No')
                    .targetEvent($event);
                this.$mdDialog.show(confirmation).then(function () {
                    _this.SectionManager.deleteSection(_this.chartStore);
                });
            };
            ClinicalChartsEditorController.prototype.goClassic = function ($event) {
                var _this = this;
                this.chartStore.getWorkingCopy().then(function (copy) {
                    if (copy.draft) {
                        _this.$mdDialog.show(_this.$mdDialog
                            .alert()
                            .title('Draft Found')
                            .textContent('A draft was found for this chart entry. Please sync or discard the draft before switching to classic mode.')
                            .ok('OK')
                            .targetEvent($event));
                    }
                }, function () {
                    var target = '/patients/' + _this.$state.params.patientId + '/clinical_charts/';
                    if (_this.$state.params.chartEntryId) {
                        _this.$window.location.href = target + _this.$state.params.chartEntryId + '/edit';
                    }
                    else {
                        _this.$window.location.href = target + 'new';
                    }
                });
            };
            ClinicalChartsEditorController.prototype.initializeChart = function () {
                var _this = this;
                this.chartStore.model.disable_evv = false;
                this.pinSummary = this.isEncounterCharting;
                this.isEncounterCharting = this.ChartingTemplateService.isEncounterCharting(this.chartStore.model.charting_client);
                if (this.$state.current.data.action === 'new') {
                    if (!this.chartStore.model.user) {
                        this.chartStore.model.user = this.user;
                    }
                    if (!this.chartStore.model.effective_date) {
                        this.chartStore.model.effective_date = new Date(new Date().toDateString());
                    }
                    this.SectionManager.getChartLocked(this.chartStore);
                }
                else {
                    this.SectionManager.getChartLocked(this.chartStore);
                    if (this.chartStore.model.effective_date) {
                        this.chartStore.model.effective_date = new Date(this.chartStore.model.effective_date);
                    }
                }
                if (this.chartStore.model.encounter_type_id) {
                    this.encTypePromise.then(function () {
                        var encType = _this.$filter('filter')(_this.encounterTypes, function (b) {
                            return b.id === _this.chartStore.model.encounter_type_id;
                        });
                        _this.SectionManager.updateHasEncounterType(_this.chartStore.model.encounter_type_id, encType[0]);
                        _this.templateType = _this.SectionManager.getTemplateType();
                        _this.activateEncounterTypeSections(_this.chartStore);
                        _this.$timeout(function () {
                            var x = _this.SectionManager.checkRequiredFields(_this.chartStore);
                        }, 250);
                    });
                }
                else {
                    this.SectionManager.updateHasEncounterType(null);
                    this.$timeout(function () {
                        var x = _this.SectionManager.checkRequiredFields(_this.chartStore);
                    }, 250);
                }
            };
            ClinicalChartsEditorController.prototype.eSignConfirm = function (f2f_visit_id) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Add Your Signature?')
                    .clickOutsideToClose(false)
                    .textContent("Please remember to sign this Clinical Chart.\n          You may do so now, or review the chart and then sign it. Signing this chart will lock it and prevent future editing.")
                    .ok('Sign')
                    .cancel('No Thanks');
                this.$mdDialog.show(confirmation).then(function () {
                    var esig = {};
                    if (_this.user.physician) {
                        esig.physician_id = _this.user.physician.id;
                    }
                    _this.addFaceToFaceVisitSignature(_this.user, f2f_visit_id).then(function () {
                        var chartCopy = { model: angular.copy(_this.chartStore.model) };
                        _this.$http
                            .post('/api/v1/clinical_charts/' + _this.chartStore.model.id + '/signatures', esig)
                            .then(function () {
                            _this.logger.success('Chart Signed');
                            _this.reloadChart();
                        }, function (errors) {
                            _this.logger.error('Unable to sign chart');
                            _this.errors = errors.errors;
                            _this.ChartingTemplateService.setErrors(errors, _this.SectionManager.activeSections);
                            if (_this.SectionManager.currentSection.componentName) {
                                // Custom section, show errors
                                _this.$scope.$broadcast('reloadFormDefinition', { errors: errors });
                            }
                        });
                    });
                });
            };
            ClinicalChartsEditorController.prototype.addFaceToFaceVisitSignature = function (user, f2f_visit_id) {
                var _this = this;
                var defer = this.$q.defer();
                if (this.chartStore.model.face_to_face_visits &&
                    this.chartStore.model.face_to_face_visits.length) {
                    if (user.physician &&
                        this.chartStore.model.face_to_face_visits[0].physician_id === user.physician.id) {
                        var physician_signature = {
                            user_id: user.id,
                            physician_id: user.physician.id,
                            effective_date: new Date(new Date().toDateString()),
                        };
                        this.$http
                            .post('/api/v1/clinical_charts/' +
                            this.chartStore.model.id +
                            '/face_to_face_visit_signature/' +
                            f2f_visit_id, physician_signature)
                            .then(function () {
                            _this.logger.success('Face to Face Visit signed');
                            defer.resolve();
                        }, function (errors) {
                            _this.logger.error('Unable to sign Face to Face visit');
                            defer.reject(errors);
                        });
                    }
                    else {
                        defer.resolve();
                    }
                }
                else {
                    defer.resolve();
                }
                return defer.promise;
            };
            ClinicalChartsEditorController.prototype.openMenu = function ($mdOpenMenu, $event) {
                $mdOpenMenu($event);
            };
            ClinicalChartsEditorController.prototype.reloadChart = function () {
                this.$state
                    .go('patients.clinical-chart', {
                    patientId: this.$state.params.patientId,
                })
                    .catch(angular.noop);
            };
            ClinicalChartsEditorController.prototype.save = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var role_symbol_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!this.checkRequired()) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.SectionManager.parseRoleSymbol(this.chartStore.model.role_name).then(function (rs) {
                                        role_symbol_1 = rs;
                                    })];
                            case 1:
                                _a.sent();
                                if (role_symbol_1 === 'hospice_aide') {
                                    this.syncSignAndFax();
                                }
                                else {
                                    this.requireSignature ? this.syncAndSign() : this.runSave();
                                }
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                });
            };
            ClinicalChartsEditorController.prototype.syncAndSign = function () {
                var _this = this;
                var text = this.agencySignature
                    ? 'Your agency requires a signature when syncing charts.'
                    : 'One of your roles requires a signature when syncing charts.';
                text += " If you sync this chart now, you will be signing it as well. Signing this chart will lock it and prevent future editing.";
                if (this.NetworkManager.isOffline()) {
                    text += "You are currently offline! If you have auto-sync enabled, the chart will be automatically synced when you go back online.";
                }
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Sync and Sign?')
                    .clickOutsideToClose(false)
                    .textContent(text)
                    .ok('Sync and Sign')
                    .cancel('Cancel');
                this.$mdDialog.show(confirmation).then(function () {
                    _this.chartStore.model.apply_signature = true;
                    _this.runSave();
                });
            };
            ClinicalChartsEditorController.prototype.syncSignAndFax = function () {
                var _this = this;
                this.saving = true;
                this.$http
                    .get("/api/v1/patients/" + this.chartStore.model.patient_id + "/fax_information?auto_fax=true")
                    .then(function (resp) {
                    _this.autoFaxInformation = resp.data;
                    if (_this.autoFaxInformation) {
                        var text = "If you sync this chart now, you will be signing it and auto generating a fax to the patient's facility.";
                        if (_this.NetworkManager.isOffline()) {
                            text += "You are currently offline! If you have auto-sync enabled, the chart will be automatically synced and faxed when you go back online.";
                        }
                        var confirmation = _this.$mdDialog
                            .confirm()
                            .title('Sign and Fax?')
                            .clickOutsideToClose(false)
                            .textContent(text)
                            .ok('Sign and Fax')
                            .cancel('Cancel');
                        _this.$mdDialog
                            .show(confirmation)
                            .then(function () {
                            _this.chartStore.model.apply_signature = true;
                            _this.runSave();
                        })
                            .catch(function () {
                            _this.saving = false;
                        });
                    }
                    else {
                        _this.requireSignature ? _this.syncAndSign() : _this.runSave();
                    }
                })
                    .catch(function () {
                    _this.saving = false;
                });
            };
            ClinicalChartsEditorController.prototype.autoFax = function () {
                var _this = this;
                this.pdfer.generatePdfFromDocumentList(this.chartStore.model.patient_id, { clinical_charts: [this.chartStore.model.id] }, { include_cover_sheet: 1 }, { faxable_id: this.autoFaxInformation.facility_id, faxable_type: 'Facility' }, true).then(function () { return _this.reloadChart(); });
            };
            ClinicalChartsEditorController.prototype.runSave = function () {
                var _this = this;
                this.saving = true;
                var createTask = false;
                if (this.NetworkManager.isOnline()) {
                    this.syncCarePlans()
                        .then(function () {
                        var chart_id = _this.chartStore.model.id !== undefined
                            ? _this.chartStore.model.id
                            : 'NEW:' + _this.chartStore.model.patient_id;
                        _this.chartStore.save().then(function (item) {
                            _this.syncIds(_this.chartStore.model, item);
                            console.log(_this.chartStore.model);
                            // get face to face visit id if exists.  if it is set to null, it means there are no f2f visits.
                            var f2f_visit_id = item.face_to_face_visits && item.face_to_face_visits.length > 0
                                ? item.face_to_face_visits[0].id
                                : null;
                            _this.saving = false;
                            if (_this.$state.current.data.action === 'new') {
                                var target;
                                var nextSection = _this.$state.params.sectionName;
                                if (_this.$state.current.name ===
                                    'patients.clinical-chart-entry-new.care-plan-problem') {
                                    target = 'patients.clinical-chart-entry-edit.section';
                                    nextSection = _this.ChartingTemplateService.getFirstSection(_this.ChartingTemplateService.getTemplateName(_this.chartStore.model));
                                }
                                else {
                                    var newText = 'clinical-chart-entry-new';
                                    var editText = 'clinical-chart-entry-edit';
                                    createTask = true;
                                    target = _this.$state.current.name.replace(newText, editText);
                                }
                                _this.chartStore.model.id = item.id;
                                _this.$state.go(target, {
                                    patientId: item.patient_id,
                                    chartEntryId: item.id,
                                    sectionName: nextSection,
                                });
                            }
                            _this.logger.success('Saved');
                            if (_this.DraftSyncService.draftErrorExists('clinical_chart_entry', chart_id)) {
                                _this.DraftSyncService.removeDraftError('clinical_chart_entry', chart_id);
                            }
                            _this.errors.length = 0;
                            _this.ChartingTemplateService.clearErrors(_this.SectionManager.activeSections);
                            if (createTask) {
                                if (_this.Permissions.can('create', 'tasks')) {
                                    _this.$mdDialog
                                        .show({
                                        clickOutsideToClose: false,
                                        bindToController: true,
                                        templateUrl: '/y/templates/patients/sections/tasks/task.html',
                                        controller: 'PatientTaskController as vm',
                                    })
                                        .then(function () {
                                        if (_this.autoFaxInformation) {
                                            _this.autoFax();
                                        }
                                        else {
                                            if (!_this.requireSignature) {
                                                _this.eSignConfirm(f2f_visit_id);
                                            }
                                            else {
                                                _this.reloadChart();
                                            }
                                        }
                                    });
                                }
                                else {
                                    if (_this.autoFaxInformation) {
                                        _this.autoFax();
                                    }
                                    else if (!_this.requireSignature) {
                                        _this.eSignConfirm(f2f_visit_id);
                                    }
                                }
                            }
                            else {
                                if (_this.autoFaxInformation) {
                                    _this.autoFax();
                                }
                                else if (!_this.requireSignature) {
                                    _this.eSignConfirm(f2f_visit_id);
                                }
                            }
                        }, function (errors) {
                            _this.logger.error('Unable to save chart');
                            _this.saving = false;
                            _this.errors = errors.errors.filter(_this.onlyUnique);
                            _this.ChartingTemplateService.setErrors(errors, _this.SectionManager.activeSections);
                            if (!_this.DraftSyncService.draftErrorExists('clinical_chart_entry', chart_id)) {
                                _this.DraftSyncService.draftErrors.push({
                                    type: 'clinical_chart_entry',
                                    id: chart_id,
                                });
                                _this.DraftSyncService.setDraftErrors();
                                _this.DraftSyncService.draftErrorCount.clinical_chart_entry = _this.DraftSyncService.getDraftErrorCount('clinical_chart_entry');
                            }
                            if (_this.SectionManager.currentSection.componentName) {
                                // Custom section, show errors
                                _this.$scope.$broadcast('reloadFormDefinition', { errors: errors });
                            }
                        });
                    })
                        .catch(function (e) {
                        console.log(e);
                        _this.saving = false;
                    });
                }
                else {
                    this.saving = false;
                    this.DraftSyncService.setSyncFlag(this.chartStore);
                    if (!this.requireSignature) {
                        this.offlineEsign();
                    }
                    else {
                        this.logger.info('Chart will be automatically synced when online');
                    }
                }
            };
            ClinicalChartsEditorController.prototype.onlyUnique = function (value, index, self) {
                return self.indexOf(value) === index;
            };
            ClinicalChartsEditorController.prototype.syncIds = function (chart, savedChart) {
                var modelNames = [];
                console.log('syncIds activeSections', this.SectionManager.activeSections);
                console.log('syncIds sectionManager', this.SectionManager);
                if (chart.charting_client === 'tablet_optimized') {
                    this.SectionManager.activeSections.forEach(function (section) {
                        if (!section.rootNode) {
                            modelNames.push(section.model);
                        }
                    });
                }
                else {
                    this.SectionManager.flattened_sections.forEach(function (section) {
                        if (!section.rootNode && chart[section.model]) {
                            modelNames.push(section.model);
                        }
                    });
                }
                console.log('syncIds chart', chart);
                console.log('syncIds savedChart:', savedChart);
                console.log('syncIds modelNames: ', modelNames);
                console.log('syncIds chart keys', Object.keys(chart));
                Object.keys(chart).forEach(function (key) {
                    if (modelNames.indexOf(key) >= 0) {
                        if (Array.isArray(chart[key]) && chart[key].length) {
                            for (var i = 0; i < chart[key].length; i++) {
                                if (!chart[key][i].id && savedChart[key][i]) {
                                    chart[key][i].id = savedChart[key][i].id;
                                }
                            }
                        }
                        else if (!chart[key].id && savedChart[key]) {
                            chart[key].id = savedChart[key].id;
                        }
                    }
                });
            };
            ClinicalChartsEditorController.prototype.offlineEsign = function () {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Sign Chart when online?')
                    .clickOutsideToClose(false)
                    .textContent("You are currently offline. Please remember to sign this Clinical Chart.\n          You may do so now and it will be synced the next time you go online, or review the chart and then sign it.\n          Signing this chart will lock it and prevent future editing once it is synced.")
                    .ok('Sign')
                    .cancel('Cancel');
                this.$mdDialog
                    .show(confirmation)
                    .then(function () {
                    _this.DraftSyncService.setSignatureFlag(_this.chartStore, 'clinical_chart_entry');
                    _this.logger.info('Chart will be automatically synced and signed when online');
                    _this.saving = false;
                })
                    .catch(function () {
                    _this.logger.info('Chart will be automatically synced when online');
                });
            };
            ClinicalChartsEditorController.prototype.syncCarePlans = function () {
                var _this = this;
                // TODO: Refactor out of this file
                return this.persistenceStore.count('care_plan_problem').then(function (count) {
                    if (count > 0) {
                        _this.logger.info('Saving offline care plan problems');
                        return _this.persistenceStore.forEach('care_plan_problem', function (item) {
                            if (!item.model.invalidForm) {
                                return _this.processCpp(item);
                            }
                        });
                    }
                });
            };
            ClinicalChartsEditorController.prototype.processCpp = function (item) {
                var _this = this;
                var cppResource = new this.CarePlanProblemEntry(item.model);
                var cppPi = new this.PersistedItem('care_plan_problem', cppResource);
                return cppPi
                    .save()
                    .then(function (data) {
                    item.model.local = false;
                    if (!item.model.id) {
                        //Attach the ID to any clinical charts in the persistenceStore that use it, update this.SectionManager & this.chartStore
                        item.model.id = data.id;
                        _this.SectionManager.carePlanProblemList.forEach(function (section) {
                            if (!section.id && section.uuid === item.model.uuid) {
                                section.id = item.model.id;
                            }
                        });
                        var csItem = _this.$filter('filter')(_this.chartStore.model.care_plan_problem_updates, function (b) {
                            return b.care_plan_problem_uuid === item.model.uuid;
                        })[0];
                        if (csItem) {
                            csItem.care_plan_problem_id = item.model.id;
                            //Now Interventions
                            var dataItem;
                            csItem.interventions.forEach(function (csItemIntervention) {
                                dataItem = _this.$filter('filter')(data.interventions, function (b) {
                                    return csItemIntervention.care_plan_problem_intervention_uuid === b.uuid;
                                })[0];
                                if (dataItem) {
                                    csItemIntervention.care_plan_problem_intervention_id = dataItem.id;
                                }
                            });
                        }
                        _this.persistenceStore.forEach('clinical_chart_entry', function (cce) {
                            if (parseInt(cce.model.patient_id, 10) === parseInt(item.model.patient_id, 10)) {
                                cce.model.care_plan_problem_updates.forEach(function (cpp) {
                                    if (cpp.care_plan_problem_uuid === item.model.uuid) {
                                        cpp.care_plan_problem_id = item.model.id;
                                        cpp.interventions.forEach(function (cppIntervention) {
                                            var dataIntervention = _this.$filter('filter')(data.interventions, function (b) {
                                                return cppIntervention.care_plan_problem_intervention_uuid === b.uuid;
                                            })[0];
                                            if (dataIntervention) {
                                                cppIntervention.id = dataIntervention.id;
                                            }
                                        });
                                        return _this.persistenceStore.set('clinical_chart_entry', cce.model, cce.draft);
                                    }
                                });
                            }
                        });
                    }
                })
                    .catch(function (e) {
                    _this.logger.error('Unable to save Care Plan problem');
                    console.log(e);
                    _this.saving = false;
                });
            };
            ClinicalChartsEditorController.prototype.scrollToTop = function () {
                this.editorPane[0].scrollTop = 0;
            };
            ClinicalChartsEditorController.prototype.scrollToBottom = function () {
                var bottomDiv = angular.element(document.getElementById('editorPaneBottom'));
                this.editorPane[0].scrollTop += bottomDiv.offset().top - this.editorPane.offset().top;
            };
            ClinicalChartsEditorController.prototype.scrollUp = function () {
                var loc = this.SectionManager.getUpLocation();
                var next = angular.element(document.getElementById(this.SectionManager.getUpLocation()));
                var pos = next.offset().top;
                this.SectionManager.goUp();
                this.editorPane[0].scrollTop += next.offset().top - this.editorPane.offset().top;
            };
            ClinicalChartsEditorController.prototype.scrollDown = function () {
                var loc = this.SectionManager.getDownLocation();
                var next = angular.element(document.getElementById(this.SectionManager.getDownLocation()));
                var pos = next.offset().top;
                this.SectionManager.goDown();
                this.editorPane[0].scrollTop += next.offset().top - this.editorPane.offset().top;
            };
            ClinicalChartsEditorController.prototype.scrollTo = function (sectionName, idx) {
                var _this = this;
                this.$timeout(function () {
                    var ele;
                    if (!idx) {
                        ele = angular.element(document.getElementById('_' + sectionName));
                    }
                    else {
                        ele = angular.element(document.getElementById('_' + sectionName + '_' + idx));
                    }
                    if (angular.equals(ele, {})) {
                        scrollTo(sectionName, idx);
                    }
                    else {
                        _this.editorPane[0].scrollTop += ele.offset().top - _this.editorPane.offset().top;
                    }
                }, 100);
            };
            ClinicalChartsEditorController.prototype.view = function ($event) {
                var _this = this;
                this.$mdDialog.show({
                    clickOutsideToClose: true,
                    title: 'Clinical Summary',
                    targetEvent: $event,
                    template: "\n          <md-dialog class=\"md-hue-1\" flex=\"75\">\n            <md-toolbar><div class=\"md-toolbar-tools\"><h2>Previous Chart</h2><span flex></span>\n              <md-dialog-actions>\n                <md-button class=\"md-icon-button\" ng-click=\"closeDialog()\">\n                  <md-icon class=\"material-icons\" aria-label=\"Close dialog\" md-svg-icon=\"navigation:close\"></md-icon>\n                </md-button>\n              </md-dialog-actions>\n            </md-toolbar>\n            <md-dialog-content><div class=\"md-title\"></div>\n              {{summary}}\n              <form name=\"dialogForm\" novalidate consolo-form form-definition=\"formDefinition[0]\"\n                form-model=\"summary[previousSectionName]\" view-only=\"true\"/>\n            </md-dialog-content>\n          </md-dialog>",
                    controller: [
                        '$scope',
                        '$mdDialog',
                        function ($scope, $mdDialog) {
                            $scope.formDefinition = _this.ChartingTemplateService.getFormDefinition(_this.SectionManager.currentSection.name, null, _this.chartStore.model.patient_id, _this.chartStore.model.encounter_type);
                            $scope.summary = _this.Patient.getClinicalSummary(_this.$state.params.patientId, [
                                _this.SectionManager.currentSection.model,
                            ]);
                            $scope.previousSectionName = _this.SectionManager.currentSection.name;
                            $scope.closeDialog = function () {
                                _this.$mdDialog.hide();
                            };
                        },
                    ],
                });
            };
            ClinicalChartsEditorController.prototype.viewChartModal = function ($event) {
                var _this = this;
                this.ChartUtilService.viewChartModal(this.chartStore, $event).then(function (val) {
                    if (val) {
                        _this.saving = true;
                        _this.attemptSave();
                    }
                });
            };
            ClinicalChartsEditorController.prototype.attemptSave = function () {
                var _this = this;
                if (!this.chartStore.model.server) {
                    this.$timeout(function () { return _this.attemptSave(); }, 500);
                }
                else {
                    this.runSave();
                }
            };
            ClinicalChartsEditorController.prototype.showPreviousChartSectionDialog = function ($event) {
                var _this = this;
                var url, controller;
                url = this.currentSection.templateUrl;
                controller = this.currentSection.controller;
                if (!url) {
                    if (this.currentSection.allowMultiple && !this.isEncounterCharting) {
                        url = '/y/templates/clinical-charts/sections/common-multiple-section.html';
                    }
                    else {
                        url = '/y/templates/clinical-charts/sections/common-section.html';
                    }
                }
                else {
                    url = '' + url + '';
                }
                if (!controller) {
                    controller = 'ClinicalChartsCommonSectionController';
                }
                var sectionNameArray = [];
                var sectionModelArray = [];
                var current = angular.copy(this.currentSection);
                if (current.sections) {
                    for (var _i = 0, _a = current.sections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        if (!s.rootNode) {
                            // if (this.ChartingTemplateService.isAdditionalSection(s.name)) {
                            //   if (sectionNameArray.indexOf('additional_sections') < 0) {
                            //     sectionNameArray.push('additional_sections');
                            //   }
                            //   s.name = 'additional_sections';
                            //   sectionModelArray.push(s);
                            // } else {
                            sectionNameArray.push(s.name);
                            sectionModelArray.push(s);
                            // }
                        }
                    }
                }
                else if (!current.rootNode) {
                    // if (this.ChartingTemplateService.isAdditionalSection(current.name)) {
                    //   sectionNameArray.push('additional_sections');
                    //   current.name = 'additional_sections';
                    //   sectionModelArray.push(current);
                    // } else {
                    sectionNameArray.push(current.name);
                    sectionModelArray.push(current);
                    // }
                }
                else {
                    this.logger.error('Cannot retrieve clinical summary for ' + current.name);
                    return;
                }
                this.Patient.getClinicalSummary(this.$state.params.patientId, sectionNameArray).$promise.then(function (summary) {
                    var formModel;
                    //TODO: rootNode shouldnt get here if (this.currentSection.rootNode) { formModel = summary.model; }
                    //TODO: Multiple sections, currently only returns 1
                    summary.model = {
                        patient_id: _this.$state.params.patientId,
                        charting_client: _this.chartStore.model.charting_client,
                        locked: true,
                        type: 'clinical_chart_entry',
                        encounter_type_id: _this.chartStore.model.encounter_type_id,
                        encounter_type: _this.chartStore.model.encounter_type,
                    };
                    for (var _i = 0, sectionModelArray_1 = sectionModelArray; _i < sectionModelArray_1.length; _i++) {
                        var s = sectionModelArray_1[_i];
                        var m = void 0;
                        if (summary[s.name] || (s.additionalSection && summary.additional_sections)) {
                            if (_this.ChartingTemplateService.isAdditionalSection(s.name)) {
                                if (summary.additional_sections[s.model]) {
                                    m = summary.additional_sections[s.model];
                                }
                            }
                            else {
                                m = summary[s.name];
                            }
                            m = m ? m : {};
                            if (s.singleNode) {
                                summary.model[s.model] = m;
                            }
                            else {
                                summary.model[s.model] = [m];
                            }
                        }
                        else {
                            if (s.singleNode) {
                                summary.model[s.model] = {};
                            }
                            else {
                                summary.model[s.model] = [{}];
                            }
                        }
                    }
                    var tempFormModel = _this.ChartingTemplateService.formModelInit(summary, _this.currentSection.name);
                    return _this.$mdDialog.show({
                        targetEvent: $event,
                        clickOutsideToClose: true,
                        template: "\n            <md-dialog class=\"md-hue-1\" aria-label=\"Previous Chart Dialog\" style=\"min-width:75%\">\n              <md-toolbar>\n                <div class=\"md-toolbar-tools\">\n                  <h2>Previous Chart Information</h2>\n                  <span flex></span>\n                  <md-button class=\"md-icon-button\" ng-click=\"vm.$mdDialog.hide();\">\n                    <md-icon class=\"material-icons\" aria-label=\"Close dialog\" md-svg-icon=\"navigation:close\"></md-icon>\n                  </md-button>\n                </div>\n              </md-toolbar>\n              <md-dialog-content>\n                <div ng-include=\"'" + url + "'\"/>\n              </md-dialog-content>\n             </md-dialog>",
                        controller: controller,
                        controllerAs: 'vm',
                        bindToController: true,
                        fullScreen: true,
                        locals: { formModel: tempFormModel, chartStore: summary, showSectionDetails: true },
                    });
                });
            };
            ClinicalChartsEditorController.prototype.getChartId = function () {
                if (this.chartStore.model.id) {
                    return this.chartStore.model.id;
                }
                else {
                    return 'New';
                }
            };
            ClinicalChartsEditorController.prototype.checkRequired = function () {
                var _this = this;
                var encType = this.$filter('filter')(this.encounterTypes, function (b) {
                    return b.id === _this.chartStore.model.encounter_type_id;
                });
                var errors = [];
                if (!this.chartStore.model.effective_date) {
                    errors.push('General: Effective Date is required');
                }
                if (!this.chartStore.model.role_id) {
                    errors.push('General: Discipline is required');
                }
                if (!this.chartStore.model.user) {
                    errors.push('General: User is required');
                }
                var formDefinitionSections = this.ChartingTemplateService.getFieldDefinitions();
                var checkSections = [];
                if (this.isEncounterCharting) {
                    this.SectionManager.checkRequiredFields(this.chartStore);
                    if (this.SectionManager.chartProgress.requiredComplete ===
                        this.SectionManager.chartProgress.requiredTotal) {
                        return true;
                    }
                    else {
                        this.logger.error('Not all screens are complete. Please check the summary.', 'Error Saving Chart');
                        return false;
                    }
                }
                else {
                    this.SectionManager.activeSections;
                }
                var model;
                checkSections.forEach(function (active_section) {
                    active_section.errorCount = 0;
                    var definition = formDefinitionSections[active_section.model];
                    var enc_required_fields;
                    if (encType[0]) {
                        enc_required_fields = _this.$parse('required_fields.' + active_section.name)(encType[0]);
                    }
                    definition.forEach(function (section) {
                        section.fields.forEach(function (field) {
                            if (enc_required_fields) {
                                if (enc_required_fields &&
                                    (enc_required_fields.indexOf(field.field) > -1 ||
                                        enc_required_fields.indexOf(field.encounter_type_field) > -1)) {
                                    field.required = true;
                                }
                                else if (enc_required_fields &&
                                    enc_required_fields.indexOf(field.field + '_id') > -1) {
                                    field.required = true;
                                }
                            }
                            if (field.required) {
                                model = _this.ChartingTemplateService.getChartStoreSectionModel(active_section, _this.chartStore);
                                if (active_section.allowMultiple) {
                                    for (var _i = 0, model_1 = model; _i < model_1.length; _i++) {
                                        var item = model_1[_i];
                                        if (field.type !== 'booltext' &&
                                            field.type !== 'checklist' &&
                                            (item[field.field] === null || item[field.field] === undefined)) {
                                            errors.push(active_section.title + ': ' + field.label + ' is required');
                                            _this.updateErrorCount(active_section);
                                        }
                                        else if (field.type === 'booltext' && !item['has_' + field.field]) {
                                            errors.push(active_section.title + ' : ' + field.label + ' is required');
                                            _this.updateErrorCount(active_section);
                                        }
                                        else if (field.type === 'checklist' &&
                                            (!item[field.field] || !item[field.field].length)) {
                                            errors.push(active_section.title + ': ' + field.label + ' is required');
                                            _this.updateErrorCount(active_section);
                                        }
                                    }
                                }
                                else {
                                    if (field.type !== 'booltext' &&
                                        field.type !== 'checklist' &&
                                        !model[field.field]) {
                                        errors.push(active_section.title + ': ' + field.label + ' is required');
                                        _this.updateErrorCount(active_section);
                                    }
                                    else if (field.type === 'booltext' && !model['has_' + field.field]) {
                                        errors.push(active_section.title + ' : ' + field.label + ' is required');
                                        _this.updateErrorCount(active_section);
                                    }
                                    else if (field.type === 'checklist' &&
                                        (!model[field.field] || !model[field.field].length)) {
                                        errors.push(active_section.title + ': ' + field.label + ' is required');
                                        _this.updateErrorCount(active_section);
                                    }
                                }
                            }
                        });
                    });
                });
                if (errors.length) {
                    this.errors = errors;
                    return false;
                }
                else {
                    this.errors = [];
                    return true;
                }
            };
            ClinicalChartsEditorController.prototype.updateErrorCount = function (section) {
                if (!section.errorCount) {
                    section.errorCount = 0;
                }
                section.errorCount++;
            };
            ClinicalChartsEditorController.prototype.activateEncounterTypeSections = function (chartStore) {
                var _this = this;
                if (this.SectionManager.encounterType && this.SectionManager.encounterType.required_fields) {
                    var doUpdate_1 = false;
                    Object.getOwnPropertyNames(this.SectionManager.encounterType.required_fields).forEach(function (a) {
                        var section = _this.SectionManager.getByName(a);
                        _this.SectionManager.activateSection(section, false, _this.chartStore);
                        doUpdate_1 =
                            _this.ChartingTemplateService.chartStoreSectionInit(section, _this.chartStore, _this.chartStore.model.patient) || doUpdate_1;
                    });
                    if (doUpdate_1) {
                        this.chartStore.saveDraft();
                    }
                }
                if (this.SectionManager.encounterType &&
                    this.SectionManager.encounterType.patient_time_fields) {
                    Object.getOwnPropertyNames(this.SectionManager.encounterType.patient_time_fields).forEach(function (a) {
                        var section = _this.SectionManager.getByName(a);
                        _this.SectionManager.activateSection(section, false, _this.chartStore);
                        if (!_this.chartStore.model[section.model]) {
                            _this.chartStore.model[section.model] = {};
                            _this.SectionManager.encounterType.patient_time_fields[a].forEach(function (b) {
                                if (b === 'in_date' || b === 'in_time') {
                                    _this.chartStore.model[section.model]['in_datetime'] = '';
                                }
                                if (b === 'out_date' || b === 'out_time') {
                                    _this.chartStore.model[section.model]['out_datetime'] = '';
                                }
                            });
                            _this.chartStore.saveDraft();
                        }
                    });
                }
                if (this.SectionManager.encounterType && this.SectionManager.encounterType.evv_fields) {
                    Object.getOwnPropertyNames(this.SectionManager.encounterType.evv_fields).forEach(function (a) {
                        var section = _this.SectionManager.getByName(a);
                        _this.SectionManager.activateSection(section, false, _this.chartStore);
                        if (!_this.chartStore.model[section.model]) {
                            _this.chartStore.model[section.model] = { signature_pad_png_base64: '' };
                            _this.chartStore.saveDraft();
                        }
                    });
                }
                if (this.SectionManager.getTemplateType() === 'encounter_charting') {
                    this.SectionManager.activateAllEncounterChartingSections(this.chartStore);
                }
            };
            ClinicalChartsEditorController.prototype.addAdditionalSectionModal = function ($event) {
                var _this = this;
                if (this.currentSection.sections) {
                    var sectionArray = [];
                    for (var _i = 0, _a = this.currentSection.sections; _i < _a.length; _i++) {
                        var section = _a[_i];
                        if (section.allowMultiple) {
                            var s = angular.copy(section);
                            sectionArray.push(s);
                        }
                    }
                    this.$mdDialog
                        .show({
                        targetEvent: $event,
                        clickOutsideToClose: true,
                        controller: 'MultipleSectionModalController as $ctrl',
                        templateUrl: '/y/templates/clinical-charts/sections/multiple-section-modal.html',
                        locals: { sections: sectionArray, style: 'add' },
                    })
                        .then(function (val) {
                        if (val) {
                            var newObj = _this.ChartingTemplateService.getDefault(val, _this.chartStore.model.patient, _this.chartStore);
                            _this.chartStore.model[val.model].push(newObj);
                            _this.SectionManager.checkRequiredFields(_this.chartStore, _this.currentSection);
                            _this.SectionManager.setUpDown(_this.chartStore);
                            _this.scrollTo(val.name, _this.chartStore.model[val.model].length - 1);
                        }
                    });
                }
                else {
                    var newObj = this.ChartingTemplateService.getDefault(this.currentSection, this.chartStore.model.patient, this.chartStore);
                    this.chartStore.model[this.currentSection.model].push(newObj);
                    this.SectionManager.checkRequiredFields(this.chartStore, this.currentSection);
                    this.SectionManager.setUpDown(this.chartStore);
                    this.scrollTo(this.currentSection.name, this.chartStore.model[this.currentSection.model].length - 1);
                }
            };
            ClinicalChartsEditorController.prototype.deleteSectionModal = function ($event) {
                var _this = this;
                if (this.currentSection.allowMultiple) {
                    var sectionArray = [];
                    if (this.currentSection.sections) {
                        for (var _i = 0, _a = this.currentSection.sections; _i < _a.length; _i++) {
                            var section = _a[_i];
                            if (section.allowMultiple) {
                                var models = this.chartStore.model[section.model];
                                if (models.length > 1) {
                                    for (var i = 0; i < models.length; i++) {
                                        var s = angular.copy(section);
                                        s.title = s.title + ' #' + (i + 1).toString();
                                        s.selected_index = i;
                                        sectionArray.push(s);
                                    }
                                }
                            }
                        }
                    }
                    else {
                        var models = this.chartStore.model[this.currentSection.model];
                        if (models.length > 1) {
                            for (var i = 0; i < models.length; i++) {
                                var s = angular.copy(this.currentSection);
                                s.title = s.title + ' #' + (i + 1).toString();
                                s.selected_index = i;
                                sectionArray.push(s);
                            }
                        }
                    }
                    if (sectionArray.length) {
                        if (!this.currentSection.has_required) {
                            sectionArray.splice(0, 0, {
                                title: 'Entire ' + this.currentSection.title + ' section',
                                selected_index: -1,
                            });
                        }
                        this.$mdDialog
                            .show({
                            targetEvent: $event,
                            clickOutsideToClose: true,
                            controller: 'MultipleSectionModalController as $ctrl',
                            templateUrl: '/y/templates/clinical-charts/sections/multiple-section-modal.html',
                            locals: { sections: sectionArray, style: 'delete' },
                        })
                            .then(function (val) {
                            if (val.selected_index >= 0) {
                                _this.SectionManager.deleteMultipleSection(_this.chartStore, val.selected_index, val);
                            }
                            else {
                                _this.SectionManager.deleteSection(_this.chartStore);
                            }
                        });
                    }
                    else if (!this.currentSection.allowMultiple) {
                        this.deleteConfirm($event);
                    }
                    else {
                        this.$mdDialog.show(this.$mdDialog.alert({
                            targetEvent: $event,
                            clickOutsideToClose: true,
                            textContent: 'You cannot delete the last section if it has required fields.',
                            title: 'Unable to Delete',
                            ok: 'OK',
                        }));
                    }
                }
                else {
                    this.deleteConfirm($event);
                }
            };
            ClinicalChartsEditorController.prototype.deleteSectionDialogController = function (items) { };
            ClinicalChartsEditorController.prototype.showSummarySidebar = function ($event) {
                this.$mdSidenav('chartSummary').open();
                this.pinSummary = true;
            };
            ClinicalChartsEditorController.prototype.showChartOptionsSidebar = function ($event) {
                this.$mdSidenav('chartOptions').open();
            };
            ClinicalChartsEditorController.prototype.closeOptionsMenuAndToggleFilters = function ($event) {
                this.$mdSidenav('chartOptions').close();
                this.toggleFilters();
            };
            ClinicalChartsEditorController.prototype.closeOptionsMenuAndShowSummarySidebar = function ($event) {
                this.$mdSidenav('chartOptions').close();
                this.showSummarySidebar($event);
            };
            ClinicalChartsEditorController.prototype.lockSummary = function ($event) {
                this.pinSummary = !this.pinSummary;
                if (!this.pinSummary) {
                    this.$mdSidenav('chartSummary').close();
                }
            };
            ClinicalChartsEditorController.prototype.setUpDownButtons = function () {
                if (!this.isEncounterCharting) {
                    return;
                }
                var header;
                var paneTop = this.editorPane.offset().top;
                var headerTop;
                var currentTop = this.editorPane[0].scrollTop;
                if (currentTop < this.SectionManager.lastPosition) {
                    var loc = this.SectionManager.getUpLocation();
                    header = angular.element(document.getElementById(loc));
                    headerTop = header.offset().top;
                    if (paneTop < headerTop) {
                        this.SectionManager.goUp();
                    }
                }
                else {
                    var loc = this.SectionManager.getDownLocation();
                    header = angular.element(document.getElementById(loc));
                    headerTop = header.offset().top;
                    if (paneTop > headerTop) {
                        this.SectionManager.goDown();
                    }
                }
                this.SectionManager.lastPosition = this.editorPane[0].scrollTop;
            };
            ClinicalChartsEditorController.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
                if (!this.showFilters) {
                    this.clearFilters();
                }
            };
            ClinicalChartsEditorController.prototype.clearFilters = function () {
                this.SectionManager.filterRequired = false;
                this.SectionManager.filterHIS = false;
            };
            ClinicalChartsEditorController.prototype.openErrorPanel = function ($event) {
                var errorPanelTemplate = "\n      <md-card >\n        <md-card-title>Chart Errors: {{ chartStore.model.errors.length }}</md-card-title>\n        <md-card-content style=\"padding:8px;overflow-y: auto;height: 200px;\">\n          <div ng-repeat=\"error in chartStore.model.errors track by $index\" style=\"margin-bottom: 10px\">\n            0{{ $index + 1 }}. {{ error }}\n          </div>\n        </md-card-content>\n      </md-card>";
                var panelPosition = this.$mdPanel
                    .newPanelPosition()
                    .relativeTo('#chart-error-button')
                    .addPanelPosition('offset-start', 'align-tops');
                this.$mdPanel.open({
                    targetEvent: $event,
                    id: 'painObservationInfoPanel',
                    attachTo: angular.element(document.body),
                    controller: [
                        'chartStore',
                        '$scope',
                        function (chartStore, $scope) {
                            $scope.chartStore = chartStore;
                        },
                    ],
                    locals: {
                        chartStore: this.chartStore,
                    },
                    template: errorPanelTemplate,
                    position: panelPosition,
                    panelClass: 'chart-error-panel-container',
                    zIndex: 100,
                    hasBackdrop: false,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                });
            };
            ClinicalChartsEditorController.$inject = [
                '$filter',
                '$mdDialog',
                '$parse',
                '$q',
                '$scope',
                '$state',
                '$timeout',
                '$window',
                'AddAddendumModal',
                'cache',
                'CarePlanProblemEntry',
                'chartStore',
                'EncounterType',
                'ChartingTemplateService',
                'logger',
                'Patient',
                'PersistedItem',
                'persistenceStore',
                'ChartingSectionManager',
                'SessionService',
                'PermissionsService',
                'NetworkManager',
                'DraftSyncService',
                'DeviceSettingsService',
                'ChartDateTimeInteraction',
                '$http',
                '$mdSidenav',
                '$interval',
                '$mdMedia',
                'ChartUtilService',
                'ChartDeviceDraftsService',
                'MileageAppIntegrationService',
                '$mdPanel',
                'pdfer',
            ];
            return ClinicalChartsEditorController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartEditorController', ClinicalChartsEditorController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MileageAppIntegrationService = /** @class */ (function () {
            function MileageAppIntegrationService($http) {
                this.$http = $http;
                this.message = {
                    editor_loaded: false,
                    chart_draft: null,
                    final_chart_id: null,
                };
            }
            MileageAppIntegrationService.prototype.appendTime = function (chartStore, timeObj) {
                chartStore.model.patient_time.in_datetime = timeObj.in_datetime;
                chartStore.model.patient_time.out_datetime = timeObj.out_datetime;
                chartStore.model.patient_time.travel_start_datetime = timeObj.travel_start_datetime;
                chartStore.model.patient_time.travel_stop_datetime = timeObj.travel_stop_datetime;
            };
            MileageAppIntegrationService.prototype.sendNotification = function () {
                console.log(this.message);
                if (window['webkit'] &&
                    window['webkit'].messageHandlers &&
                    window['webkit'].messageHandlers.observe) {
                    window['webkit'].messageHandlers.observe.postMessage(this.message);
                }
            };
            MileageAppIntegrationService.$inject = ['$http'];
            return MileageAppIntegrationService;
        }());
        services.MileageAppIntegrationService = MileageAppIntegrationService;
        angular.module('consolo').service('MileageAppIntegrationService', MileageAppIntegrationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var PhysicianSignatureDialogController = /** @class */ (function () {
            function PhysicianSignatureDialogController($mdDialog, physicians) {
                this.$mdDialog = $mdDialog;
                this.physicians = physicians;
                this.today = new Date();
            }
            PhysicianSignatureDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    return this.$mdDialog.cancel();
                }
                return this.$mdDialog.hide({
                    date: this.date,
                    physician: this.physician,
                });
            };
            PhysicianSignatureDialogController.$inject = ['$mdDialog', 'physicians'];
            return PhysicianSignatureDialogController;
        }());
        angular
            .module('consolo')
            .controller('PhysicianSignatureDialogController', PhysicianSignatureDialogController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ChartingPrefetchService = /** @class */ (function () {
            function ChartingPrefetchService($q, cache, Lookup, Patient, CarePlanTemplate, EncounterType, FamilyMember, Cpp, ChartingTemplateService, SyncManager, Medication, logger, $interval, $window, BodySite, ServiceWorkerUpdater, EmbeddedHelper, Store) {
                var _this = this;
                this.$q = $q;
                this.cache = cache;
                this.Lookup = Lookup;
                this.Patient = Patient;
                this.CarePlanTemplate = CarePlanTemplate;
                this.EncounterType = EncounterType;
                this.FamilyMember = FamilyMember;
                this.Cpp = Cpp;
                this.ChartingTemplateService = ChartingTemplateService;
                this.SyncManager = SyncManager;
                this.Medication = Medication;
                this.logger = logger;
                this.$interval = $interval;
                this.$window = $window;
                this.BodySite = BodySite;
                this.ServiceWorkerUpdater = ServiceWorkerUpdater;
                this.EmbeddedHelper = EmbeddedHelper;
                this.Store = Store;
                this.extensionName = 'ChartingPrefetch';
                this.redirectListener = function (event) {
                    if (_this.last_clicked &&
                        _this.last_clicked.href &&
                        _this.last_clicked.href.indexOf('/x') === -1) {
                        event.returnValue =
                            'The offline patient cache is still being loaded, are you sure you want to leave this page?';
                        return event.returnValue;
                    }
                    else {
                        return;
                    }
                };
                this.clickListener = function (e) {
                    _this.last_clicked = e.target;
                    if (_this.last_clicked &&
                        _this.last_clicked.href &&
                        _this.last_clicked.href.indexOf('/x') === -1) {
                        alert('The offline patient cache is still being loaded, you will be asked by your browser to confirm if you want to leave this page.');
                    }
                    return true;
                };
                this.noop = function () { };
                this.noopArray = function () {
                    return [];
                };
                this.cacheStatus = function () {
                    _this.offlineDashboardCache.fetchTimestamp('query', {}).then(function (ts) {
                        if (!ts) {
                            _this.status = 'Expired';
                            _this.statusClass = 'label-warning';
                        }
                        else {
                            _this.status = 'Good';
                            _this.statusClass = 'label-success';
                            var expireDate = new Date(ts);
                            _this.expires = expireDate.setHours(expireDate.getHours() + 12);
                        }
                        _this.updateLocalStorage();
                    }, function (e) {
                        console.log('ChartingPrefetch.cacheStatus failed');
                        console.log(e);
                    });
                };
                this.store = Store.sync();
                this.status = '';
                this.expires = null;
                this.status = 'Loading...';
                this.statusClass = 'label-info';
                this.cancelCaching = false;
                this.last_clicked = null;
                this.updateLocalStorage();
                this.cpTemplateCache = cache(CarePlanTemplate, 'care_plan_templates', { maxAge: 60 });
                this.cppCache = cache(Cpp, 'care_plan_problems', { encrypt: true, maxAge: 10 });
                this.encTypeCache = cache(EncounterType, 'encounter_types', { maxAge: 10 });
                this.familyCache = cache(FamilyMember, 'family_members', { encrypt: true, maxAge: 10 });
                this.patientCache = cache(Patient, 'patients', {
                    encrypt: true,
                    force: false,
                    maxAge: 12 * 60,
                });
                this.lookupCache = cache(Lookup, 'lookup', { maxAge: 10 });
                this.lookupEncryptedCache = cache(Lookup, 'lookup', { encrypt: true });
                this.medicationCache = cache(Medication, 'medications', {
                    encrypt: true,
                    force: true,
                    maxAge: 12,
                });
                this.formLookups = ChartingTemplateService.getLookups().map(function (l) {
                    return _this.lookupCache[l];
                });
                this.offlineDashboardCache = cache(null, 'offline_dashboard', {
                    force: false,
                    maxAge: 12 * 60,
                    encrypt: true,
                });
                this.bodySiteCache = this.cache(this.BodySite, 'body_site', {
                    encrypt: true,
                    maxAge: 10,
                });
            }
            ChartingPrefetchService.prototype.onSessionReady = function (sess) {
                this.cacheStatus();
                this.checkStatusInt = this.$interval(this.cacheStatus, 15 * 60 * 1000); //update the cache status every 15 minutes
            };
            ChartingPrefetchService.prototype.onSessionClear = function () {
                if (this.checkStatusInt) {
                    this.$interval.cancel(this.checkStatusInt);
                    this.checkStatusInt = null;
                }
            };
            ChartingPrefetchService.prototype.initialize = function () {
                var _this = this;
                this.ServiceWorkerUpdater.preventReload('prefetch-init');
                return this.$q
                    .all([
                    // Cache encounter types
                    this.encTypeCache.query({ active: true }).$promise.catch(function () {
                        _this.rescueFailure('Encounter Types', []);
                    }),
                    // Cache patient lookup
                    this.lookupEncryptedCache
                        .patients({ limit: -1, active: true, deceased: true, pending_never_admitted: true })
                        .$promise.catch(function () {
                        _this.rescueFailure('Patient Lookup', []);
                    }),
                    // Cache care plan templates
                    this.cpTemplateCache.query().$promise.catch(function () {
                        _this.rescueFailure('Care Plan Templates', []);
                    }),
                ].concat(this.formLookups.map(function (lookup) {
                    return lookup({ limit: -1 }).$promise.catch(_this.noopArray);
                })))
                    .finally(function () {
                    _this.ServiceWorkerUpdater.allowReload('prefetch-init');
                });
            };
            ChartingPrefetchService.prototype.checkUpdatePatientCache = function () {
                var _this = this;
                if (this.EmbeddedHelper.skipCache) {
                    return false;
                }
                return this.SyncManager.activate().then(function (syncMgr) {
                    if (syncMgr.data.alwaysRefresh) {
                        return true;
                    }
                    else {
                        return _this.offlineDashboardCache.fetchTimestamp('query', {}).then(function (ts) {
                            if (!ts) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                });
            };
            ChartingPrefetchService.prototype.beginRedirectListener = function () {
                this.ServiceWorkerUpdater.preventReload('prefetch-cache');
                this.$window.addEventListener('beforeunload', this.redirectListener);
                this.$window.addEventListener('click', this.clickListener);
            };
            ChartingPrefetchService.prototype.stopRedirectListener = function () {
                this.ServiceWorkerUpdater.allowReload('prefetch-cache');
                this.$window.removeEventListener('beforeunload', this.redirectListener);
                this.$window.removeEventListener('click', this.clickListener);
            };
            ChartingPrefetchService.prototype.cachePatients = function (callback, force) {
                var _this = this;
                if (this.EmbeddedHelper.isAccessToken) {
                    return this.$q.when(true);
                }
                var patientIds = [];
                var sm;
                this.status = 'Loading';
                this.statusClass = 'label-info';
                this.expires = null;
                this.updateLocalStorage();
                this.cancelCaching = false;
                var startTime, endTime, endTime2;
                return this.SyncManager.activate()
                    .then(function (syncMgr) {
                    if (callback) {
                        callback('Updating patient list...');
                    }
                    _this.status = 'Updating patient list...';
                    _this.statusClass = 'label-info';
                    _this.updateLocalStorage();
                    //TODO: For testing only, should be removed.  Leaving for now to see how prod handles
                    startTime = new Date();
                    sm = syncMgr;
                    _this.patientCache.options.force = force || syncMgr.data.alwaysRefresh;
                    _this.beginRedirectListener();
                    _this.offlineDashboardCache.options.force = force || syncMgr.data.alwaysRefresh;
                    return _this.offlineDashboardCache.fetch('query', {}).then(function (item) {
                        _this.offlineDashboardCache.options.force = false;
                        if (!item) {
                            return syncMgr.updateList();
                        }
                        else {
                            _this.stopRedirectListener();
                            _this.status = 'Good';
                            _this.statusClass = 'label-success';
                            _this.offlineDashboardCache.fetchTimestamp('query', {}).then(function (ts) {
                                var expireDate = new Date(ts);
                                _this.expires = expireDate.setHours(expireDate.getHours() + 12);
                                _this.updateLocalStorage();
                            });
                            _this.logger.success('Offline cache loaded successfully');
                            return _this.$q.reject('Success');
                        }
                    });
                })
                    .then(function (patientList) {
                    endTime2 = new Date();
                    var seconds = (endTime2.getTime() - startTime.getTime()) / 1000;
                    console.log('Offline patient query only: ' + seconds + ' seconds total.');
                    _this.status = 'Downloading Patients';
                    _this.updateLocalStorage();
                    if (callback) {
                        callback('Downloading Patients');
                    }
                    return _this.patientCache.getOfflinePatients(sm.params).$promise.then(function (patients) {
                        if (patients.length === 0) {
                            _this.status = 'No Patients!';
                            _this.statusClass = 'label-danger';
                            _this.expires = null;
                            _this.updateLocalStorage();
                            _this.stopRedirectListener();
                            return _this.$q.reject('No Patients!');
                        }
                        patientIds = patients.map(function (p) { return p.id; });
                        return patients
                            .reduce(function (chain, patient) {
                            return chain.then(function () {
                                if (callback) {
                                    callback('Caching Patient: ' + patient.name);
                                }
                                _this.status = 'Caching Patient: ' + patient.name;
                                return _this.patientCache.set('get', { id: patient.id }, patient);
                            });
                        }, _this.$q.when(true))
                            .catch(_this.noop);
                    });
                })
                    .then(function () {
                    //TODO: For testing only.   Leaving for now to see how prod handles
                    endTime = new Date();
                    var seconds = (endTime.getTime() - startTime.getTime()) / 1000;
                    console.log('Offline patient query & cache : ' + seconds + ' seconds total.');
                    var size = 500, getQueries = [], getValues = [];
                    var promise = _this.$q.when(true);
                    var current = 1, total = Math.ceil(patientIds.length / size);
                    if (callback) {
                        callback('Loading Care Plan Group: ' + current + '/' + total);
                    }
                    _this.status = 'Loading Care Plans';
                    _this.updateLocalStorage();
                    startTime = new Date();
                    // Cache Care Plan Problems
                    patientIds.forEachSlice(size, function (slice) {
                        promise = promise
                            .then(function () {
                            if (_this.cancelCaching) {
                                return _this.$q.reject('Canceled');
                            }
                            return _this.Cpp.list({ patient_ids: slice }).$promise.then(function (cpps) {
                                // Cache each CPP
                                getQueries = getQueries.concat(cpps.map(function (b) {
                                    return { id: b.id };
                                }));
                                getValues = getValues.concat(cpps.map(function (b) {
                                    return b;
                                }));
                                current++;
                                if (callback) {
                                    callback('Loading Care Plans Group ' + current + '/' + total);
                                }
                            });
                        })
                            .catch(_this.noop);
                    });
                    promise.then(function () {
                        return _this.cppCache.setBatch('get', getQueries, getValues).then(function () {
                            // Cache CPPs by patient
                            var queries = patientIds.map(function (id) {
                                return { patient_id: id };
                            });
                            var values = patientIds.map(function (patientId) {
                                return getValues.filter(function (x) {
                                    return x.patient_id === patientId;
                                });
                            });
                            return _this.cppCache.setBatch('query', queries, values);
                        });
                    });
                    return promise;
                    // Test to make sure correctly value is coming back.
                    // .then(() => {
                    //   cache(Cpp, 'care_plan_problems', {encrypt: true})
                    //   .query({patient_id: 6}).$promise.then((data) => {
                    //     console.log(data);
                    //   });
                    // });
                })
                    .then(function () {
                    endTime = new Date();
                    var seconds = (endTime.getTime() - startTime.getTime()) / 1000;
                    console.log('Offline Care Plan load: ' + seconds + ' seconds');
                    startTime = new Date();
                    if (_this.cancelCaching) {
                        return _this.$q.reject('Canceled');
                    }
                    _this.status = 'Loading Family Members';
                    _this.updateLocalStorage();
                    if (callback) {
                        callback('Loading Family Members');
                    }
                    return _this.FamilyMember.batch({ patient_ids: patientIds }).$promise.then(function (data) {
                        if (_this.cancelCaching) {
                            return _this.$q.reject('Canceled');
                        }
                        var queries = data.map(function (x) {
                            return { patient_id: x.patient_id };
                        });
                        var values = data.map(function (x) { return x.family_members; });
                        return _this.familyCache.setBatch('query', queries, values);
                    });
                })
                    .then(function () {
                    if (_this.cancelCaching) {
                        return _this.$q.reject('Canceled');
                    }
                    _this.status = 'Loading Body Sites';
                    _this.updateLocalStorage();
                    if (callback) {
                        callback('Loading Body Sites');
                    }
                    return _this.BodySite.batch({ patient_ids: patientIds }).$promise.then(function (data) {
                        if (_this.cancelCaching) {
                            return _this.$q.reject('Canceled');
                        }
                        var queries = data.map(function (x) {
                            return { patient_id: x.patient_id };
                        });
                        var values = data.map(function (x) { return x.body_sites; });
                        return _this.bodySiteCache.setBatch('query', queries, values);
                    });
                })
                    .then(function () {
                    endTime = new Date();
                    var seconds = (endTime.getTime() - startTime.getTime()) / 1000;
                    console.log('Offline Care Plan load: ' + seconds + ' seconds');
                    var promise = _this.$q.when(true);
                    if (sm.data.includeMedications) {
                        if (callback) {
                            callback('Loading Patient Medications');
                        }
                        _this.status = 'Loading Patient Medications';
                        _this.updateLocalStorage();
                        startTime = new Date();
                        var _loop_1 = function (id) {
                            promise = promise.then(function () {
                                if (_this.cancelCaching) {
                                    promise = _this.$q.reject('Canceled');
                                    return promise;
                                }
                                if (callback) {
                                    callback('Loading Patient Medications');
                                }
                                return _this.medicationCache.loadAllByPatientId(id).$promise;
                            });
                        };
                        for (var _i = 0, patientIds_1 = patientIds; _i < patientIds_1.length; _i++) {
                            var id = patientIds_1[_i];
                            _loop_1(id);
                        }
                        return promise.then(function () {
                            _this.stopRedirectListener();
                            endTime = new Date();
                            var seconds = (endTime.getTime() - startTime.getTime()) / 1000;
                            console.log('Offline Medications load: ' + seconds + ' seconds');
                            var expireDate = new Date();
                            if (sm.data.alwaysRefresh) {
                                _this.expires = 'Next Login';
                            }
                            else {
                                _this.expires = expireDate.setHours(expireDate.getHours() + 12);
                            }
                            _this.status = 'Good';
                            _this.statusClass = 'label-success';
                            _this.updateLocalStorage();
                            _this.logger.success('Offline cache loaded successfully');
                            return promise;
                        });
                    }
                    else {
                        _this.stopRedirectListener();
                        var expireDate = new Date();
                        if (sm.data.alwaysRefresh) {
                            _this.status = 'Good';
                            _this.statusClass = 'label-success';
                            _this.expires = 'Next Login';
                        }
                        else {
                            _this.status = 'Good ';
                            _this.statusClass = 'label-success';
                            _this.expires = expireDate.setHours(expireDate.getHours() + 12);
                        }
                        _this.updateLocalStorage();
                        _this.logger.success('Offline cache loaded successfully');
                        return promise;
                    }
                })
                    .catch(function (e) {
                    if (e === 'Canceled') {
                        _this.logger.error('Offline Patient Cache did not finish loading', 'Warning!');
                        _this.status = 'Canceled';
                        _this.statusClass = 'label-danger';
                        _this.updateLocalStorage();
                        throw e;
                    }
                    else if (e !== 'Success' && e !== 'No Patients!') {
                        console.log(e);
                        _this.logger.error(e);
                        _this.status = 'Error';
                        _this.statusClass = 'label-danger';
                        _this.updateLocalStorage();
                        throw e;
                    }
                });
            };
            ChartingPrefetchService.prototype.rescueFailure = function (dataName, defaultData) {
                return function (e) {
                    console.warn('Unable to prefetch ' + dataName + ':');
                    console.log(e);
                    return defaultData;
                };
            };
            ChartingPrefetchService.prototype.cancelPatientCaching = function () {
                this.cancelCaching = true;
                this.offlineDashboardCache.removeItem('query', {});
            };
            ChartingPrefetchService.prototype.updateLocalStorage = function () {
                this.store.setItem('enterprise:cache-status', { status: this.status, expires: this.expires, statusClass: this.statusClass });
            };
            ChartingPrefetchService.$inject = [
                '$q',
                'cache',
                'Lookup',
                'Patient',
                'CarePlanTemplate',
                'EncounterType',
                'FamilyMember',
                'CarePlanProblemEntry',
                'ChartingTemplateService',
                'SyncManager',
                'Medication',
                'logger',
                '$interval',
                '$window',
                'BodySite',
                'ServiceWorkerUpdater',
                'EmbeddedHelper',
                'Store',
            ];
            return ChartingPrefetchService;
        }());
        angular.module('consolo').service('ChartingPrefetch', ChartingPrefetchService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ClinicalChartsPrintController = /** @class */ (function () {
            function ClinicalChartsPrintController(ChartingTemplateService, SectionManager, PersistedItem, options) {
                this.ChartingTemplateService = ChartingTemplateService;
                this.SectionManager = SectionManager;
                this.PersistedItem = PersistedItem;
                this.options = options;
                this.scale = {};
                this.scales = [
                    { description: '', link: '' },
                    { description: 'ALSFRS', link: '/performance_scales/alsfrs' },
                    { description: 'ECOG', link: '/performance_scales/ecog' },
                    { description: 'Edmonton', link: '/performance_scales/edmonton' },
                    { description: 'FAST', link: '/performance_scales/fast' },
                    { description: 'Geriatric Depression', link: '/performance_scales/gds' },
                    { description: 'Glasgow Coma', link: '/performance_scales/glasgow' },
                    { description: 'Karnofsky', link: '/performance_scales/karnofsky' },
                    { description: 'Mitchel Mortality Risk', link: '/performance_scales/mitchel' },
                    { description: 'Morse', link: '/performance_scales/morse' },
                    { description: 'NYHA', link: '/performance_scales/nyha' },
                    { description: 'Palliative', link: '/performance_scales/palliative' },
                ];
                this.careTypes = options.get('clinical_care_types');
                this.discomfort = options.get('discomfort');
                this.discomfortNoAnswerReasons = options.get('discomfort_no_answer_reasons');
                this.flaccFace = options.get('flacc_face');
                this.flaccLegs = options.get('flacc_legs');
                this.flaccActivity = options.get('flacc_activity');
                this.flaccCry = options.get('flacc_cry');
                this.flaccConsolability = options.get('flacc_consolability');
                this.painadBodyLang = options.get('painad_body_lang');
                this.painadBreathingRatings = options.get('painadd_breathing_ratings');
                this.painadConsolability = options.get('painadd_consolability');
                this.painadFacial = options.get('painadd_facial');
                this.painadNegVoc = options.get('painadd_neg_voc');
                this.painFrequencies = options.get('pain_frequencies');
                this.painRatings = options.get('pain_ratings');
                this.painTypes = options.get('pain_types');
                this.scaleTypes = [{ id: 'Verbal' }, { id: 'PAINAD' }, { id: 'FLACC' }];
                this.yesNo = [{ id: '' }, { id: 'Yes' }, { id: 'No' }];
            }
            ClinicalChartsPrintController.prototype.activate = function (chart) {
                var _this = this;
                this.clinicalChart = chart;
                this.sections = this.ChartingTemplateService.getFieldDefinitions();
                this.formDefinitions = {};
                this.formModels = {};
                Object.keys(this.sections).forEach(function (key) {
                    _this.formModels[key] = _this.clinicalChart[key];
                    _this.formDefinitions[key] = _this.ChartingTemplateService.getFormDefinition(key, null, _this.clinicalChart.patient_id, null);
                });
            };
            ClinicalChartsPrintController.$inject = ['ChartingTemplateService', 'SectionManager', 'PersistedItem', 'options'];
            return ClinicalChartsPrintController;
        }());
        controllers.ClinicalChartsPrintController = ClinicalChartsPrintController;
        angular
            .module('consolo')
            .controller('ClinicalChartsPrintController', ClinicalChartsPrintController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ChartingSectionManagerService = /** @class */ (function () {
            function ChartingSectionManagerService($filter, $mdSidenav, $state, CarePlanProblemEntry, persistenceStore, cache, Session, options, logger, ChartingTemplateService, Permissions, $timeout, $rootScope, ChartUtilService) {
                var _this = this;
                this.$filter = $filter;
                this.$mdSidenav = $mdSidenav;
                this.$state = $state;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.persistenceStore = persistenceStore;
                this.cache = cache;
                this.Session = Session;
                this.options = options;
                this.logger = logger;
                this.ChartingTemplateService = ChartingTemplateService;
                this.Permissions = Permissions;
                this.$timeout = $timeout;
                this.$rootScope = $rootScope;
                this.ChartUtilService = ChartUtilService;
                this.ClinicalChartSections = [];
                this.activeSections = [];
                this.carePlanProblemList = [];
                this.otherSections = [];
                this.currentSection = { name: '' };
                this.painIndicatorsAddressed = [];
                this.base_roles = [];
                this.flattened_sections = [];
                this.allFlattened_sections = [];
                this.hasOther = false;
                this.sectionActions = ['show', 'create', 'update', 'destroy'];
                this.canGoUp = false;
                this.canGoDown = false;
                this.cppPermissions = {};
                this.cppUpdatePermissions = {};
                this.currentActiveCarePlanProblem = {};
                this.today = consolo.common.dateOnly(new Date());
                this.checkingRequired = false;
                this.tagOnly = false;
                this.filterRequired = false;
                this.filterHIS = false;
                this.requiredFilterChangeCallbacks = [];
                this.hisFilterChangeCallbacks = [];
                this.hasFilter = false;
                this.forwardBack = { forwardSection: null, backSection: null };
                this.activity2_ratings = this.options.get('activity2_ratings');
                this.ambulatory_ratings = this.options.get('ambulatory_ratings');
                this.base_roles = this.options.get('base_roles');
                this.bowel_regimen_initiated = this.options.get('bowel_regimen_initiated');
                this.breakthrough_pain_frequencies = this.options.get('breakthrough_pain_frequencies');
                this.breakthrough_pain_quantities = this.options.get('breakthrough_pain_quantities');
                this.clinical_care_types = this.options.get('clinical_care_types');
                this.cope_risk_levels = this.options.get('cope_risk_levels');
                this.developmental_pediatric_levels = this.options.get('developmental_pediatric_levels');
                this.discomfort = this.options.get('discomfort');
                this.discussion_options = this.options.get('discussion_options');
                this.edema_intensities = this.options.get('edema_intensities');
                this.elimination_catheters = this.options.get('elimination_catheters');
                this.fall_locations = this.options.get('fall_locations');
                this.fall_types = this.options.get('fall_types');
                this.feeding_deliveries = this.options.get('feeding_deliveries');
                this.feeding_related_problems = this.options.get('feeding_related_problems');
                this.feeding_tube_patencies = this.options.get('feeding_tube_patencies');
                this.feeding_tubes = this.options.get('feeding_tubes');
                this.friction_ratings = this.options.get('friction_ratings');
                this.gait_ratings = this.options.get('gait_ratings');
                this.incontinence = this.options.get('incontinence');
                this.inpatient_unit_note_activites = this.options.get('inpatient_unit_note_activities');
                this.inpatient_unit_note_rationales = this.options.get('inpatient_unit_note_rationales');
                this.intensities = this.options.get('intensities');
                this.iv_types = this.options.get('iv_types');
                this.lab_attempts = this.options.get('lab_attempts');
                this.level_of_consciousnesses = this.options.get('level_of_consciousnesses');
                this.level_of_functionings = this.options.get('level_of_functionings');
                this.mental_ratings = this.options.get('mental_ratings');
                this.mobility_ratings = this.options.get('mobility_ratings');
                this.moisture_ratings = this.options.get('moisture_ratings');
                this.neuro_level_of_consciousness = this.options.get('neuro_level_of_consciousness');
                this.nutrition_appetites = this.options.get('nutrition_appetites');
                this.nutrition_diets = this.options.get('nutrition_diets');
                this.nutrition_ratings = this.options.get('nutrition_ratings');
                this.o2_usage = this.options.get('o2_usage');
                this.opioid_initiated = this.options.get('opioid_initiated');
                this.oxygenation_respiratory_equipment = this.options.get('oxygenation_respiratory_equipment');
                this.pain_ratings = this.options.get('pain_ratings');
                this.pediatric_neuro_development = this.options.get('pediatric_neuro_development');
                this.respiratory_treatment_types = this.options.get('respiratory_treatment_types');
                this.sensory_ratings = this.options.get('sensory_ratings');
                this.infection_statuses = this.options.get('infection_statuses');
                this.agitation_restlessness_options = this.options.get('agitation_restlessness_options');
                this.alertness_attention_options = this.options.get('alertness_attention_options');
                this.hours_asleep_options = this.options.get('hours_asleep_options');
                this.interactivity_options = this.options.get('interactivity_options');
                this.shortness_of_breath_options = this.options.get('shortness_of_breath_options');
                this.communication_ability = this.options.get('communication_ability');
                this.hiv_opportunistic_infections = this.options.get('hiv_opportunistic_infections');
                this.cancer_stages = this.options.get('cancer_stages');
                this.cancer_treatments = this.options.get('cancer_treatments');
                this.cbleeding_options = this.options.get('cbleeding_options');
                this.cheart_failure_options = this.options.get('cheart_failure_options');
                this.other_indications_of_pain = this.options.get('other_indications_of_pain');
                this.nutrition_appetites_options = this.options.get('nutrition_appetites_options');
                this.eating_options = this.options.get('eating_options');
                this.indications_of_weight_loss_options = this.options.get('indications_of_weight_loss_options');
                this.diagnosis_information_obtained_options = this.options.get('diagnosis_information_obtained_options');
                this.assessment_type_options = this.options.get('assessment_type_options');
                this.umbilical_cord_options = this.options.get('umbilical_cord_options');
                this.abdominal_symptoms_options = this.options.get('abdominal_symptoms_options');
                this.bowel_sounds_options = this.options.get('bowel_sounds_options');
                this.decision_options = this.options.get('decision_options');
                this.role_options = this.options.get('role_options');
                this.preferences_changed = this.options.get('preferences_changed');
                this.evaluation = this.options.get('evaluation');
                this.types_of_discharge = this.options.get('types_of_discharge');
                this.report_received_from = this.options.get('report_received_from');
                this.ipu_discharge_plan = this.options.get('ipu_discharge_plan');
                this.aide_services = this.options.get('aide_services');
                this.people_involved = this.options.get('people_involved');
                this.contact_agency_reviewed = this.options.get('contact_agency_reviewed');
                this.neurology_suck = this.options.get('neurology_suck');
                this.neurology_cry = this.options.get('neurology_cry');
                this.symptoms_associated_with_pain_options = this.options.get('symptoms_associated_with_pain_options');
                this.nutrition_medications_or_chemotherapy_options = this.options.get('nutrition_medications_or_chemotherapy_options');
                this.nutrition_cannot_tolerate_options = this.options.get('nutrition_cannot_tolerate_options');
                this.inactivity_decreased_activity_contributing_options = this.options.get('inactivity_decreased_activity_contributing_options');
                this.nursing_treatments = this.options.get('nursing_treatments');
                this.location_of_care_environmental_safety = this.options.get('location_of_care_environmental_safety');
                this.nursing_safety = this.options.get('nursing_safety');
                this.electrical_equipment = this.options.get('electrical_equipment');
                this.homebound_status = this.options.get('homebound_status');
                this.activities_of_daily_living = this.options.get('activities_of_daily_living');
                this.caregiver = this.options.get('caregiver');
                this.evacuation = this.options.get('evacuation');
                this.administration_safety_issues = this.options.get('administration_safety_issues');
                this.medications = this.options.get('medications');
                this.peripheral_pulses = this.options.get('peripheral_pulses');
                this.purpose_of_visit_options = this.options.get('purpose_of_visit_options');
                this.person_type_options = this.options.get('person_type_options');
                this.services_options = this.options.get('services_options');
                this.wound_options = this.options.get('wound_options');
                this.food_consistency_options = this.options.get('food_consistency_options');
                this.pediatric_mobility_options = this.options.get('pediatric_mobility_options');
                this.any_exposure_options = this.options.get('any_exposure_options');
                this.war_era_options = this.options.get('war_era_options');
                this.additional_veteran_assessment_symptom_options = this.options.get('additional_veteran_assessment_symptom_options');
                this.veteran_assessment_come_to_serve_options = this.options.get('veteran_assessment_come_to_serve_options');
                this.military_experience_options = this.options.get('military_experience_options');
                this.prisoner_of_war_solitary_with_others_options = this.options.get('prisoner_of_war_solitary_with_others_options');
                this.prisoner_of_war_duration_of_captivity_days = this.options.get('prisoner_of_war_duration_of_captivity_days');
                this.prisoner_of_war_duration_of_captivity_months = this.options.get('prisoner_of_war_duration_of_captivity_months');
                this.prisoner_of_war_duration_of_captivity_years = this.options.get('prisoner_of_war_duration_of_captivity_years');
                this.wound_stage_options = this.options.get('wound_stage_options');
                $rootScope.$watch(function () {
                    return _this.filterRequired;
                }, function (newVal, oldVal) {
                    _this.hasFilter = _this.filterRequired || _this.filterHIS;
                    _this.requiredFilterChangeCallbacks.forEach(function (callback) { return callback(); });
                });
                $rootScope.$watch(function () {
                    return _this.filterHIS;
                }, function (newVal, oldVal) {
                    _this.hasFilter = _this.filterRequired || _this.filterHIS;
                    _this.hisFilterChangeCallbacks.forEach(function (callback) { return callback(); });
                });
            }
            ChartingSectionManagerService.prototype.resetSectionManager = function () {
                this.currentSection = { name: '' };
                this.ClinicalChartSections = [];
                this.activeSections = [];
                this.carePlanProblemList = [];
                this.otherSections = [];
                this.cppPermissions = {};
                this.cppUpdatePermissions = {};
                this.currentActiveCarePlanProblem = {};
                this.encounterType = null;
                this.has_encounter_type_id = false;
                this.templateType = null;
                this.templateName = null;
                this.painIndicatorsAddressed = [];
                this.flattened_sections = [];
                this.allFlattened_sections = [];
                this.hasOther = false;
                this.currentSectionName = '';
                this.currentChildName = '';
            };
            ChartingSectionManagerService.prototype.init = function (chartStore) {
                var _this = this;
                this.chartingFields = this.ChartingTemplateService.getFieldDefinitions();
                this.chartProgress = {
                    requiredComplete: 0,
                    requiredTotal: 0,
                    errors: 0,
                    requiredFields: [],
                    completedFields: [],
                    incompleteScreens: [],
                };
                this.hasOther = false;
                if (!chartStore.model.charting_client) {
                }
                this.isEncounterCharting = this.ChartingTemplateService.isEncounterCharting(chartStore.model.charting_client);
                this.templateType = !this.isEncounterCharting ? 'tablet_optimized' : 'encounter_charting';
                this.templateName = this.ChartingTemplateService.getTemplateName(chartStore.model);
                this.ClinicalChartSections = this.ChartingTemplateService.getScreens(this.templateName);
                // Remove deprecated skin_integrity_assessment (braden)
                if (chartStore.status === 'Unsaved') {
                    this.ClinicalChartSections = this.ClinicalChartSections.map(function (section) {
                        var _a;
                        if (section.name === 'instruments') {
                            section.childSections = (_a = section.childSections) === null || _a === void 0 ? void 0 : _a.filter(function (child) { return child.name !== 'skin_integrity_assessment'; });
                        }
                        return section;
                    });
                }
                this.updateHasEncounterType(chartStore.model.encounter_type_id, null);
                this.otherSections.length = 0;
                this.activeSections.length = 0;
                this.carePlanProblemList.length = 0;
                this.painIndicatorsAddressed = [];
                var cppActions = ['show', 'create', 'update', 'destroy'];
                for (var _i = 0, cppActions_1 = cppActions; _i < cppActions_1.length; _i++) {
                    var action = cppActions_1[_i];
                    this.cppPermissions[action] = this.Permissions.can(action, 'care_plan_problems');
                    this.cppUpdatePermissions[action] = this.Permissions.can(action, 'care_plan_problem_updates');
                }
                if (this.cppPermissions.show) {
                    var cppCache = this.cache(this.CarePlanProblemEntry, 'care_plan_problems', {
                        encrypt: true,
                        maxAge: 10,
                        force: true,
                    });
                    cppCache.query({ patient_id: chartStore.model.patient_id }).$promise.then(function (data) {
                        var _loop_1 = function (item) {
                            _this.carePlanProblemList.push(item);
                            var activeItem = _this.$filter('filter')(chartStore.model.care_plan_problem_updates, function (b) {
                                return parseInt(b.care_plan_problem_id, 10) === item.id;
                            });
                            if (activeItem && activeItem.length) {
                                item.active = true;
                                _this.addCarePlanProblemInterventionSections(activeItem[0], item, chartStore);
                            }
                        };
                        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                            var item = data_1[_i];
                            _loop_1(item);
                        }
                        _this.persistenceStore.forEach('care_plan_problem', function (item) {
                            if (parseInt(item.model.patient_id, 10) === parseInt(chartStore.model.patient_id, 10)) {
                                var activeItem = _this.$filter('filter')(chartStore.model.care_plan_problem_updates, function (b) {
                                    return b.care_plan_problem_uuid === item.model.uuid;
                                });
                                if (activeItem && activeItem.length) {
                                    item.model.active = true;
                                    _this.addCarePlanProblemInterventionSections(activeItem[0], item.model, chartStore);
                                }
                                item.model.local = true;
                                if (item.model.id) {
                                    _this.carePlanProblemList.forEach(function (cpp, idx) {
                                        if (cpp.id === item.model.id) {
                                            _this.carePlanProblemList.splice(idx, 1); //TODO: item loaded from server shows up temporarily then disappears
                                        }
                                    });
                                }
                                _this.carePlanProblemList.push(item.model);
                            }
                        });
                        // Remove care plan problems that have been removed from the server
                        var missing = _this.checkForMissingCarePlanProblems(chartStore);
                        if (missing.length) {
                            for (var _a = 0, missing_2 = missing; _a < missing_2.length; _a++) {
                                var item = missing_2[_a];
                                var index = chartStore.model.care_plan_problem_updates.indexOf(item);
                                if (index > -1) {
                                    chartStore.model.care_plan_problem_updates.splice(index, 1);
                                }
                                _this.removeCarePlanProblemFromChart(item, chartStore);
                            }
                        }
                    });
                }
                for (var _a = 0, _b = this.ClinicalChartSections; _a < _b.length; _a++) {
                    var section = _b[_a];
                    this.checkSectionPermissions(section, chartStore);
                }
                if (chartStore.model.charting_client === 'custom') {
                    var missing = this.ChartingTemplateService.getMissingSections(chartStore.model.encounter_type);
                    if (missing.length) {
                        var missingSections = [];
                        for (var _c = 0, missing_1 = missing; _c < missing_1.length; _c++) {
                            var section = missing_1[_c];
                            section.parent_name = '_menu_all_other';
                            section.parent_title = 'All Other Sections';
                            missingSections.push(section);
                            if (chartStore.model[section.model]) {
                                // document_upload is a unique section when showing in "All Other Sections". Check specifically for it.
                                if (section.common || section.rootNode || chartStore.model[section.model].length || section.model === "document_upload") {
                                    this.hasOther = true;
                                }
                            }
                        }
                        if (this.hasOther) {
                            var newSection = {
                                title: 'All Other Screens',
                                name: '_menu_all_other',
                                childSections: missingSections,
                            };
                            this.otherSections.push(newSection);
                            this.checkSectionPermissions(newSection, chartStore);
                        }
                    }
                }
                this.setFlattenedSections();
                // this.setForwardBack();
            };
            ChartingSectionManagerService.prototype.updateHasEncounterType = function (encounter_type_id, encounter_type) {
                this.has_encounter_type_id = encounter_type_id && encounter_type_id > -1 ? true : false;
                if (encounter_type && this.has_encounter_type_id) {
                    this.encounterType = encounter_type;
                }
                else {
                    this.encounterType = null;
                }
            };
            ChartingSectionManagerService.prototype.checkSectionPermissions = function (section, chartStore) {
                section.errorCount = 0;
                section.hasCPP = false;
                if (chartStore.model[section.model] && chartStore.model[section.model].arrInterventions) {
                    section.hasCPP = true;
                }
                var curState = this.$state.params.sectionName;
                for (var _i = 0, _a = this.sectionActions; _i < _a.length; _i++) {
                    var action = _a[_i];
                    if (section.childSections) {
                        for (var _b = 0, _c = section.childSections; _b < _c.length; _b++) {
                            var child = _c[_b];
                            if (child.sections) {
                                for (var _d = 0, _e = child.sections; _d < _e.length; _d++) {
                                    var subChild = _e[_d];
                                    this.checkIndividualSection(subChild, action);
                                    child.show = child.show || subChild[action];
                                    child[action] = child[action] || subChild[action];
                                }
                            }
                            else {
                                this.checkIndividualSection(child, action);
                            }
                            section.show = section.show || child[action];
                            section[action] = section[action] || child[action];
                        }
                    }
                    else if (section.sections) {
                        for (var _f = 0, _g = section.sections; _f < _g.length; _f++) {
                            var child = _g[_f];
                            this.checkIndividualSection(child, action);
                            section.show = section.show || child[action];
                            section[action] = section[action] || child[action];
                        }
                    }
                    else {
                        this.checkIndividualSection(section, action);
                    }
                }
                if (section.show) {
                    if (curState === section.name) {
                        this.setActiveSection(section, null, chartStore);
                    }
                    else if (section.childSections) {
                        for (var _h = 0, _j = section.childSections; _h < _j.length; _h++) {
                            var child = _j[_h];
                            if (curState === child.name) {
                                this.setActiveSection(child, section, chartStore);
                                break;
                            }
                        }
                    }
                    if (this.templateType === 'encounter_charting') {
                        this.activateEncounterChartingSection(section, chartStore);
                    }
                    else {
                        if (this.sectionExists(chartStore, section) ||
                            section.common ||
                            this.currentSection.model === section.model) {
                            this.activeSections.push(section);
                            if (section.allowMultiple) {
                                this.otherSections.push(section);
                            }
                        }
                        else {
                            if (section.create) {
                                this.otherSections.push(section);
                            }
                        }
                    }
                }
            };
            ChartingSectionManagerService.prototype.checkIndividualSection = function (section, action) {
                section[action] =
                    section.noPermissions ||
                        (section.model && this.Permissions.can(action, section.model));
            };
            ChartingSectionManagerService.prototype.setActiveSection = function (section, chartStore, parent) {
                angular.copy(section, this.currentSection);
                this.setUpDown(chartStore);
                this.currentSectionName = parent ? parent.name : section.name;
                this.currentChildName = parent ? section.name : null;
                if (!this.currentSection.has_service_line_tag) {
                    this.tagOnly = false;
                }
            };
            ChartingSectionManagerService.prototype.activateSection = function (section, makeCurrent, chartStore, parent) {
                if (makeCurrent) {
                    this.setActiveSection(section, chartStore, parent);
                }
                if (this.templateType === 'encounter_charting' && !section.childSections) {
                    section.active = true;
                }
                else {
                    if (this.activeSections.indexOf(section) >= 0 || section.common || section.childSections) {
                        return;
                    }
                    if (this.$state.current.data &&
                        this.$state.current.data.action === 'edit' &&
                        section.model === 'electronic_visit_verification') {
                        return;
                    }
                    this.activeSections.push(section);
                    if (!section.allowMultiple) {
                        this.otherSections.splice(this.otherSections.indexOf(section), 1);
                    }
                }
            };
            ChartingSectionManagerService.prototype.setActiveSectionsEditable = function () {
                for (var _i = 0, _a = this.activeSections; _i < _a.length; _i++) {
                    var section = _a[_i];
                    section.show = true;
                    section.update = true;
                }
            };
            ChartingSectionManagerService.prototype.setAllSectionsEditable = function () {
                for (var _i = 0, _a = this.activeSections; _i < _a.length; _i++) {
                    var section = _a[_i];
                    for (var _b = 0, _c = this.sectionActions; _b < _c.length; _b++) {
                        var action = _c[_b];
                        section[action] = true;
                    }
                }
                for (var _d = 0, _e = this.otherSections; _d < _e.length; _d++) {
                    var section = _e[_d];
                    for (var _f = 0, _g = this.sectionActions; _f < _g.length; _f++) {
                        var action = _g[_f];
                        section[action] = true;
                    }
                }
            };
            ChartingSectionManagerService.prototype.createPainObservationIndicatorArray = function (index) {
                this.painIndicatorsAddressed[index] = [];
            };
            ChartingSectionManagerService.prototype.getPainIndicatorsAddressed = function () {
                return this.painIndicatorsAddressed;
            };
            ChartingSectionManagerService.prototype.addCarePlanProblemUpdate = function (cpp, chartStore) {
                cpp.active = true;
                var isOffline = !!!cpp.id;
                var newItem = { interventions: [], legacy: false };
                newItem.care_plan_template_id = cpp.care_plan_template_id;
                if (!isOffline) {
                    newItem.care_plan_problem_id = cpp.id;
                }
                else {
                    newItem.care_plan_problem_uuid = cpp.uuid;
                }
                for (var _i = 0, _a = cpp.interventions; _i < _a.length; _i++) {
                    var intervention = _a[_i];
                    // let end_date = intervention.end_date ? new Date(intervention.end_date) : this.today;
                    if (!intervention.end_date) {
                        newItem.interventions.push({
                            care_plan_problem_update_id: newItem.care_plan_problem_id === undefined
                                ? newItem.care_plan_problem_uuid
                                : newItem.care_plan_problem_id,
                            name: intervention.name,
                            care_plan_problem_intervention_id: intervention.id,
                            care_plan_problem_intervention_uuid: intervention.uuid,
                            due_date: intervention.due_date,
                            chart_section: intervention.chart_section,
                        });
                    }
                }
                if (!chartStore.model.care_plan_problem_updates) {
                    chartStore.model.care_plan_problem_updates = [];
                }
                chartStore.model.care_plan_problem_updates.push(newItem);
                this.addCarePlanProblemInterventionSections(chartStore.model.care_plan_problem_updates[chartStore.model.care_plan_problem_updates.length - 1], cpp, chartStore);
                if (this.templateType === 'encounter_charting') {
                    this.activateAllEncounterChartingSections(chartStore);
                }
                chartStore.saveDraft();
            };
            ChartingSectionManagerService.prototype.addCarePlanProblemInterventionSections = function (update, cpp, chartStore) {
                var hasSections = this.$filter('filter')(cpp.interventions, function (b) {
                    //if the chart already has interventions we need to display them even if resolved by another chart
                    var exists = false;
                    for (var _i = 0, _a = chartStore.model.care_plan_problem_updates; _i < _a.length; _i++) {
                        var u = _a[_i];
                        if (u.care_plan_problem_id === update.care_plan_problem_id) {
                            for (var _b = 0, _c = u.interventions; _b < _c.length; _b++) {
                                var i = _c[_b];
                                if (b.id === i.care_plan_problem_intervention_id && b.chart_section) {
                                    exists = true;
                                }
                            }
                        }
                    }
                    return exists || (!!b.chart_section && !b.end_date);
                });
                // Pull up missing sections in case Encounter Charting is being used with an ET
                var missing;
                if ((chartStore.model.charting_client === 'encounter_charting' ||
                    chartStore.model.charting_client === 'custom') &&
                    chartStore.model.encounter_type) {
                    missing = this.ChartingTemplateService.getMissingSections(chartStore.model.encounter_type);
                    for (var _i = 0, missing_3 = missing; _i < missing_3.length; _i++) {
                        var section = missing_3[_i];
                        section.parent_title = 'All Other Screens';
                        section.parent_name = '_menu_all_other';
                    }
                }
                for (var _a = 0, hasSections_1 = hasSections; _a < hasSections_1.length; _a++) {
                    var interventionData = hasSections_1[_a];
                    var section = this.getByName(interventionData.chart_section, chartStore, missing);
                    //Check if the formModel returned by router is always chartStore.model[section][0] so we know to put/check interventions there
                    //There has to be a better way
                    var onZero = !section.rootNode && !section.singleNode && !section.allowMultiple;
                    section.hasCPP = true;
                    if (section.parent_name) {
                        var parent_1 = this.getByName(section.parent_name);
                        parent_1.hasCPP = true;
                    }
                    this.activateSection(section, false, chartStore);
                    if (!chartStore.model[section.model]) {
                        if (section.singleNode) {
                            chartStore.model[section.model] = {};
                        }
                        else {
                            chartStore.model[section.model] = [];
                            chartStore.model[section.model].push({});
                        }
                    }
                    if (onZero) {
                        if (!chartStore.model[section.model].length) {
                            chartStore.model[section.model].push({});
                        }
                        if (!angular.isArray(chartStore.model[section.model][0].arrInterventions)) {
                            chartStore.model[section.model][0].arrInterventions = [];
                        }
                    }
                    else {
                        if (!angular.isArray(chartStore.model[section.model].arrInterventions)) {
                            chartStore.model[section.model].arrInterventions = [];
                        }
                    }
                    var _loop_2 = function (intervention) {
                        intervention.problem = cpp.problem;
                        var iid = !!interventionData.id ? interventionData.id : interventionData.uuid;
                        var compid = !!intervention.care_plan_problem_intervention_id
                            ? intervention.care_plan_problem_intervention_id
                            : intervention.care_plan_problem_intervention_uuid;
                        if (iid === compid) {
                            //check to see if it is already part of the section (drafts)
                            intervention.chart_section_title = section.title;
                            intervention.chart_section_icon = section.icon;
                            var iExists = void 0;
                            if (onZero) {
                                //again, must find better way
                                iExists = this_1.$filter('filter')(chartStore.model[section.model][0].arrInterventions, function (b) {
                                    return !!b.care_plan_problem_intervention_id
                                        ? b.care_plan_problem_intervention_id === compid
                                        : b.care_plan_problem_intervention_uuid === compid;
                                })[0];
                            }
                            else {
                                iExists = this_1.$filter('filter')(chartStore.model[section.model].arrInterventions, function (b) {
                                    return !!b.care_plan_problem_intervention_id
                                        ? b.care_plan_problem_intervention_id === compid
                                        : b.care_plan_problem_intervention_uuid === compid;
                                })[0];
                            }
                            if (iExists) {
                                var idx = void 0;
                                if (onZero) {
                                    //...
                                    idx = chartStore.model[section.model][0].arrInterventions.indexOf(iExists);
                                    chartStore.model[section.model][0].arrInterventions.splice(idx, 1, intervention);
                                }
                                else {
                                    idx = chartStore.model[section.model].arrInterventions.indexOf(iExists);
                                    chartStore.model[section.model].arrInterventions.splice(idx, 1, intervention);
                                }
                            }
                            else {
                                intervention.name = interventionData.name;
                                intervention.chart_section = interventionData.chart_section;
                                if (onZero) {
                                    chartStore.model[section.model][0].arrInterventions.push(intervention);
                                }
                                else {
                                    chartStore.model[section.model].arrInterventions.push(intervention);
                                }
                            }
                        }
                    };
                    var this_1 = this;
                    for (var _b = 0, _c = update.interventions; _b < _c.length; _b++) {
                        var intervention = _c[_b];
                        _loop_2(intervention);
                    }
                }
            };
            // This function checks for any care plans that have been removed server side but still persists locally.
            // The return value is an array of the indexes of the care plans that are missing from the server.
            ChartingSectionManagerService.prototype.checkForMissingCarePlanProblems = function (chartStore) {
                var _this = this;
                var missing = [];
                if (!chartStore.model.care_plan_problem_updates) {
                    return missing;
                }
                chartStore.model.care_plan_problem_updates.forEach(function (cpp) {
                    var exists = _this.$filter('filter')(_this.carePlanProblemList, function (b) {
                        return b.id === cpp.care_plan_problem_id;
                    });
                    if (!exists.length) {
                        missing.push(cpp);
                    }
                });
                return missing;
            };
            ChartingSectionManagerService.prototype.removeCarePlanProblemFromChart = function (cpp, chartStore) {
                var _loop_3 = function (intervention) {
                    var sectionNameSingular = intervention.chart_section.replace('-', '_');
                    var sectionNamePlural = sectionNameSingular + 's';
                    var possibleSectionNames = [sectionNameSingular, sectionNamePlural];
                    if (intervention.chart_section) {
                        possibleSectionNames.forEach(function (sectionName) {
                            // Check if the section is a single node or an array with one value
                            var onZero = !intervention.chart_section.rootNode && !intervention.chart_section.singleNode && !intervention.chart_section.allowMultiple;
                            if (onZero) {
                                if (chartStore.model[sectionName] &&
                                    chartStore.model[sectionName].length &&
                                    chartStore.model[sectionName][0].arrInterventions &&
                                    chartStore.model[sectionName][0].arrInterventions.length) {
                                    var index = chartStore.model[sectionName][0].arrInterventions.indexOf(intervention);
                                    if (index > -1) {
                                        chartStore.model[sectionName][0].arrInterventions.splice(index, 1);
                                        if (chartStore.model[sectionName][0].arrInterventions.length === 0) {
                                            chartStore.model[sectionName] = null;
                                        }
                                    }
                                }
                            }
                            else {
                                if (chartStore.model[sectionName] &&
                                    chartStore.model[sectionName].arrInterventions &&
                                    chartStore.model[sectionName].arrInterventions.length) {
                                    index = chartStore.model[sectionName].arrInterventions.indexOf(intervention);
                                    if (index > -1) {
                                        chartStore.model[sectionName].arrInterventions.splice(index, 1);
                                        if (chartStore.model[sectionName].arrInterventions.length === 0) {
                                            chartStore.model[sectionName] = null;
                                        }
                                    }
                                }
                            }
                        });
                    }
                };
                for (var _i = 0, _a = cpp.interventions; _i < _a.length; _i++) {
                    var intervention = _a[_i];
                    _loop_3(intervention);
                }
            };
            ChartingSectionManagerService.prototype.removeFromClinicalChart = function (chartStore) {
                if (this.currentActiveCarePlanProblem) {
                    var index = chartStore.model.care_plan_problem_updates.indexOf(this.currentActiveCarePlanProblem);
                    if (index > -1) {
                        chartStore.model.care_plan_problem_updates.splice(index, 1);
                    }
                    this.removeCarePlanProblemFromChart(this.currentActiveCarePlanProblem, chartStore);
                    var current_id = this.currentActiveCarePlanProblem.care_plan_problem_id;
                    for (var _i = 0, _a = this.carePlanProblemList; _i < _a.length; _i++) {
                        var carePlanProblem = _a[_i];
                        if (carePlanProblem.id === current_id) {
                            carePlanProblem.active = false;
                        }
                    }
                    this.currentActiveCarePlanProblem = null;
                    chartStore.saveDraft();
                    this.navigateTo(this.activeSections[0], chartStore);
                }
            };
            ChartingSectionManagerService.prototype.closeNav = function () {
                this.$mdSidenav('sidebar').close();
                this.$mdSidenav('chartSummary').close();
            };
            ChartingSectionManagerService.prototype.deleteSection = function (chartStore) {
                if (this.currentSection.model === 'general' ||
                    this.currentSection.name === 'encounter_charting') {
                    return;
                }
                if (!this.isEncounterCharting) {
                    for (var _i = 0, _a = this.activeSections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        if (s.model === this.currentSection.model) {
                            if (!this.currentSection.common) {
                                this.activeSections.splice(this.activeSections.indexOf(s), 1);
                                this.otherSections.push(s);
                            }
                            delete chartStore.model[s.model];
                            chartStore.saveDraft();
                            this.navigateTo(this.activeSections[0], chartStore);
                        }
                    }
                }
                else {
                    //TODO HANDLE ROOTNODE AND SINGLE SECTIONS
                    for (var _b = 0, _c = this.otherSections; _b < _c.length; _b++) {
                        var s = _c[_b];
                        if (s.name === this.currentSection.name) {
                            if (s.sections) {
                                for (var _d = 0, _e = s.sections; _d < _e.length; _d++) {
                                    var sub = _e[_d];
                                    delete chartStore.model[sub.model];
                                }
                            }
                            else {
                                delete chartStore.model[s.model];
                            }
                            s.active = false;
                        }
                        else if (s.childSections) {
                            for (var _f = 0, _g = s.childSections; _f < _g.length; _f++) {
                                var child = _g[_f];
                                if (child.name === this.currentSection.name) {
                                    child.active = false;
                                    delete chartStore.model[child.model];
                                }
                            }
                        }
                    }
                    this.removeIncompleteScreen(this.currentSection.name);
                    chartStore.saveDraft();
                    this.checkRequiredFields(chartStore);
                    this.navigateToByName(this.otherSections[0].name, chartStore);
                }
            };
            ChartingSectionManagerService.prototype.removeIncompleteScreen = function (screenName) {
                var pos = -1;
                this.chartProgress.incompleteScreens.forEach(function (s, idx) {
                    if (s.screen.name === screenName) {
                        pos = idx;
                    }
                });
                if (pos > -1) {
                    this.chartProgress.incompleteScreens.splice(pos, 1);
                }
            };
            ChartingSectionManagerService.prototype.deleteMultipleSection = function (chartStore, idx, deleteSection) {
                var _this = this;
                if (!this.currentSection.allowMultiple) {
                    return;
                }
                if (this.isEncounterCharting) {
                    for (var _i = 0, _a = this.otherSections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        if (s.name === deleteSection.name) {
                            if (s.sections) {
                                for (var _b = 0, _c = s.sections; _b < _c.length; _b++) {
                                    var sub = _c[_b];
                                    if (sub.model === 'pain_observations') {
                                        this.painIndicatorsAddressed[idx] = [];
                                    }
                                    chartStore.model[sub.model].splice(idx, 1);
                                }
                            }
                            else {
                                if (s.model === 'pain_observations') {
                                    this.painIndicatorsAddressed[idx] = [];
                                }
                                chartStore.model[s.model].splice(idx, 1);
                            }
                            s.active = false;
                        }
                        else if (s.childSections) {
                            for (var _d = 0, _e = s.childSections; _d < _e.length; _d++) {
                                var child = _e[_d];
                                if (child.name === deleteSection.name) {
                                    if (child.sections) {
                                        for (var _f = 0, _g = child.sections; _f < _g.length; _f++) {
                                            var subChild = _g[_f];
                                            if (subChild.name === deleteSection.name) {
                                                chartStore.model[s.model].splice(idx, 1);
                                                if (subChild.model === 'pain_observations') {
                                                    this.painIndicatorsAddressed[idx] = [];
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        child.active = false;
                                        chartStore.model[child.model].splice(idx, 1);
                                        if (child.model === 'pain_observations') {
                                            this.painIndicatorsAddressed[idx] = [];
                                        }
                                    }
                                }
                            }
                        }
                    }
                    chartStore.saveDraft();
                }
                else {
                    this.activeSections.forEach(function (s, sIdx) {
                        if (s.model === _this.currentSection.model) {
                            chartStore.model[s.model].splice(idx, 1);
                            if (s.model === 'pain_observations') {
                                _this.painIndicatorsAddressed[idx] = [];
                            }
                            if (chartStore.model[s.model].length === 0) {
                                delete chartStore.model[s.model];
                                _this.activeSections.splice(sIdx, 1);
                                _this.navigateTo(_this.activeSections[0], chartStore);
                            }
                            chartStore.saveDraft();
                        }
                    });
                }
            };
            ChartingSectionManagerService.prototype.getByModel = function (sectionModel, allSections) {
                var section = this.$filter('filter')(!!allSections ? this.ClinicalChartSections : this.otherSections, function (a) {
                    return a.model === sectionModel;
                });
                if (!section.length) {
                    section = this.$filter('filter')(this.allFlattened_sections, function (a) {
                        return a.model === sectionModel;
                    });
                }
                if (!section.length) {
                    section = this.$filter('filter')(this.activeSections, function (a) {
                        return a.model === sectionModel;
                    });
                }
                if (section) {
                    return section[0];
                }
            };
            ChartingSectionManagerService.prototype.getByName = function (sectionName, chartStore, missingSections) {
                // Check flattened sections
                var section = this.$filter('filter')(this.allFlattened_sections, function (a) {
                    return a.name === sectionName;
                });
                if (!section.length) {
                    // Check currently active sections
                    section = this.$filter('filter')(this.activeSections, function (a) {
                        return a.name === sectionName;
                    });
                    // If no active sections are returned, encounter charting with an ET is being used.
                    // This pulls out the missing sections from above and sticks all associated sections
                    // with no parent inside of the All Other Screens tab similar to using the Section Finder
                    if (!section.length && missingSections) {
                        if (sectionName === 'care_plan_problem_update') {
                            section[0] = this.ChartingTemplateService.getCarePlanProblemSection();
                        }
                        else {
                            section = this.$filter('filter')(missingSections, function (a) {
                                return a.name === sectionName;
                            });
                        }
                        var parent_2;
                        if (section[0] && section[0].parent_name) {
                            parent_2 = this.ChartingTemplateService.getSectionFromScreens(section[0].parent_name, this.otherSections, 'name');
                        }
                        if (!parent_2 && sectionName !== 'care_plan_problem_update') {
                            parent_2 = {
                                title: 'All Other Screens',
                                name: '_menu_all_other',
                                childSections: missingSections,
                            };
                            this.otherSections.push(parent_2);
                            section[0].screen = this.ChartingTemplateService.getSectionFromScreens(section[0].name, missingSections);
                            this.hasOther = true;
                            this.checkSectionPermissions(parent_2, chartStore);
                        }
                        if (parent_2) {
                            parent_2.active = parent_2.expanded = true;
                        }
                        this.setFlattenedSections();
                    }
                }
                if (section) {
                    return section[0];
                }
            };
            ChartingSectionManagerService.prototype.navigateTo = function (section, chartStore, parent) {
                var _this = this;
                try {
                    this.$state
                        .go('patients.clinical-chart-entry-' + this.$state.current.data.action + '.section', {
                        patientId: this.$state.params.patientId,
                        chartEntryId: this.$state.params.chartEntryId,
                        sectionName: section.name,
                    })
                        .finally(function () {
                        _this.closeNav();
                        _this.activateSection(section, true, chartStore, parent);
                        _this.setForwardBack();
                    });
                }
                catch (e) {
                    var message = e.message || e;
                    this.logger.error(message);
                    throw e;
                }
            };
            ChartingSectionManagerService.prototype.navigateToByName = function (sectionName, chartStore) {
                var pos = this.getIndexByName({ name: sectionName }, this.flattened_sections);
                this.navigateTo(this.flattened_sections[pos], chartStore);
            };
            ChartingSectionManagerService.prototype.sectionExists = function (store, section) {
                if (!store) {
                    return false;
                }
                else if (section.rootNode) {
                    return true;
                }
                else if (store.model[section.model]) {
                    if (section.singleNode) {
                        return true;
                    }
                    else {
                        return store.model[section.model].length > 0;
                    }
                }
                else {
                    return false;
                }
            };
            ChartingSectionManagerService.prototype.getChartLocked = function (chartStore) {
                var _a;
                if (!chartStore.model.locked) {
                    var user = this.Session.getUser();
                    //not the creator, not the assigned user and not a super user
                    if (chartStore.model.user &&
                        user.id !== chartStore.model.user.id &&
                        user.id !== ((_a = chartStore.model.created_by) === null || _a === void 0 ? void 0 : _a.id)) {
                        var permission_role_symbol = this.parseRoleSymbol(chartStore.model.role_name);
                        var permission_action = 'edit_any_' + permission_role_symbol + '_chart';
                        if (this.Permissions.can(permission_action, 'clinical_charts') ||
                            this.Permissions.can('edit_any_clinical_chart', 'clinical_charts')) {
                            this.setAllSectionsEditable(); //edit any can edit all sections
                        }
                        else if (this.$state.current.data.action !== 'new') {
                            chartStore.model.locked = true;
                        }
                    }
                    else {
                        this.setActiveSectionsEditable();
                    }
                }
            };
            ChartingSectionManagerService.prototype.setForwardBack = function () {
                if (this.templateType === 'tablet_optimized') {
                    this.forwardBack.backSection = null;
                    this.forwardBack.forwardSection = null;
                }
                else {
                    this.forwardBack.backSection = this.forwardBack.forwardSection = null;
                    // let flat = getFlattenedSections();
                    var pos = this.getIndexByName(this.currentSection, this.flattened_sections);
                    if (pos) {
                        this.forwardBack.backSection = this.flattened_sections[pos - 1];
                    }
                    if (pos < this.flattened_sections.length - 1) {
                        this.forwardBack.forwardSection = this.flattened_sections[pos + 1];
                    }
                }
            };
            ChartingSectionManagerService.prototype.setUpDown = function (chartStore) {
                this.screenLocations = [];
                this.screenLocationNames = [];
                this.screenLocations.push('editorPaneTop');
                this.screenLocationNames.push('Top');
                if (this.currentSection.sections && this.currentSection.sections.length) {
                    for (var _i = 0, _a = this.currentSection.sections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        if (s.allowMultiple) {
                            var i = 0;
                            if (chartStore && chartStore.model && chartStore.model[s.model]) {
                                for (var _b = 0, _c = chartStore.model[s.model]; _b < _c.length; _b++) {
                                    var m = _c[_b];
                                    this.screenLocations.push('_' + s.name + '_' + i);
                                    this.screenLocationNames.push(s.title + ' #' + i);
                                    i++;
                                }
                            }
                            else {
                                this.screenLocations.push('_' + s.name + '_' + i);
                                this.screenLocationNames.push(s.title + ' #' + i);
                            }
                        }
                        else {
                            this.screenLocations.push('_' + s.name);
                            this.screenLocationNames.push(s.title);
                        }
                    }
                }
                else if (this.currentSection.allowMultiple) {
                    var i = 0;
                    if (chartStore && chartStore.model && chartStore.model[this.currentSection.model]) {
                        for (var _d = 0, _e = chartStore.model[this.currentSection.model]; _d < _e.length; _d++) {
                            var m = _e[_d];
                            this.screenLocations.push('_' + this.currentSection.name + '_' + i);
                            this.screenLocationNames.push(this.currentSection.title + ' #' + i);
                            i++;
                        }
                    }
                    else {
                        this.screenLocations.push('_' + this.currentSection.name + '_' + i);
                        this.screenLocationNames.push(this.currentSection.title + ' #' + i);
                    }
                }
                else {
                    this.screenLocations.push('_' + this.currentSection.name);
                    this.screenLocationNames.push(this.currentSection.title);
                }
                this.screenLocations.push('editorPaneBottom');
                this.screenLocationNames.push('Bottom');
                this.currentScreenLocation = this.screenLocations[1];
                this.upName = this.screenLocationNames[0];
                this.downName = this.screenLocationNames[2];
                this.lastPosition = 0;
            };
            ChartingSectionManagerService.prototype.getUpLocation = function (name) {
                var idx;
                idx = name
                    ? this.screenLocations.indexOf('_' + name)
                    : this.screenLocations.indexOf(this.currentScreenLocation);
                if (idx <= 0) {
                    this.canGoUp = false;
                    return this.screenLocations[0];
                }
                else {
                    this.canGoUp = true;
                    return this.screenLocations[idx - 1];
                }
            };
            ChartingSectionManagerService.prototype.getDownLocation = function (name) {
                var idx;
                idx = name
                    ? this.screenLocations.indexOf('_' + name)
                    : this.screenLocations.indexOf(this.currentScreenLocation);
                if (idx >= this.screenLocations.length - 1) {
                    this.canGoDown = false;
                    return this.screenLocations[this.screenLocations.length - 1];
                }
                else {
                    this.canGoDown = true;
                    return this.screenLocations[idx + 1];
                }
            };
            ChartingSectionManagerService.prototype.goUp = function (name) {
                var temp = this.getUpLocation(name);
                if (temp !== 'editorPaneTop') {
                    this.currentScreenLocation = temp;
                    var idx = this.screenLocations.indexOf(this.currentScreenLocation);
                    this.downLocation = this.getDownLocation();
                    this.upLocation = this.getUpLocation();
                    this.upName = this.screenLocationNames[idx - 1];
                    this.downName = this.screenLocationNames[idx + 1];
                }
            };
            ChartingSectionManagerService.prototype.goDown = function (name) {
                var temp = this.getDownLocation(name);
                if (temp !== 'editorPaneBottom') {
                    this.currentScreenLocation = temp;
                    this.downLocation = this.getDownLocation();
                    this.upLocation = this.getUpLocation();
                    var idx = this.screenLocations.indexOf(this.currentScreenLocation);
                    this.upName = this.screenLocationNames[idx - 1];
                    this.downName = this.screenLocationNames[idx + 1];
                }
            };
            ChartingSectionManagerService.prototype.parseRoleSymbol = function (name) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, this.base_roles.$promise.then(function () {
                                return name
                                    ? _this.base_roles.filter(function (role) {
                                        return role.description === name;
                                    })[0]['role_symbol']
                                    : '';
                            })];
                    });
                });
            };
            ChartingSectionManagerService.prototype.getIndexByName = function (section, arr) {
                for (var i = 0; i < arr.length; i++) {
                    if (section.name === arr[i].name) {
                        return i;
                    }
                }
            };
            ChartingSectionManagerService.prototype.setFlattenedSections = function () {
                var f = this.getFlattenedSections();
                this.flattened_sections = f.active;
                this.allFlattened_sections = f.all;
                this.setForwardBack();
            };
            ChartingSectionManagerService.prototype.getFlattenedSections = function () {
                var len = this.otherSections.length;
                var tempActive = [];
                var tempAll = [];
                for (var i = 0; i < len; i++) {
                    if (this.otherSections[i].childSections) {
                        var childLength = this.otherSections[i].childSections.length;
                        tempAll.push(this.otherSections[i]);
                        for (var j = 0; j < childLength; j++) {
                            tempAll.push(this.otherSections[i].childSections[j]);
                            if (this.otherSections[i].childSections[j].active) {
                                tempActive.push(this.otherSections[i].childSections[j]);
                            }
                            if (this.otherSections[i].childSections[j].sections) {
                                for (var _i = 0, _a = this.otherSections[i].childSections[j].sections; _i < _a.length; _i++) {
                                    var childSub = _a[_i];
                                    tempAll.push(childSub);
                                }
                            }
                        }
                    }
                    else {
                        tempActive.push(this.otherSections[i]);
                        if (this.otherSections[i].sections) {
                            for (var _b = 0, _c = this.otherSections[i].sections; _b < _c.length; _b++) {
                                var subSection = _c[_b];
                                tempAll.push(subSection);
                            }
                        }
                        tempAll.push(this.otherSections[i]);
                    }
                }
                return { active: tempActive, all: tempAll };
            };
            ChartingSectionManagerService.prototype.activateAllEncounterChartingSections = function (chartStore) {
                this.setFlattenedSections();
                for (var i = this.otherSections.length - 1; i >= 0; --i) {
                    this.activateEncounterChartingSection(this.otherSections[i], chartStore);
                }
            };
            ChartingSectionManagerService.prototype.activateEncounterChartingSection = function (section, chartStore) {
                if (this.otherSections.indexOf(section) < 0) {
                    this.otherSections.push(section);
                }
                if (!section.childSections &&
                    !section.sections &&
                    (this.sectionExists(chartStore, section) ||
                        section.common ||
                        this.currentSection.model === section.model)) {
                    section.active = true;
                }
                else if (section.sections) {
                    for (var _i = 0, _a = section.sections; _i < _a.length; _i++) {
                        var sub = _a[_i];
                        if (this.sectionExists(chartStore, sub)) {
                            section.active = true;
                            break;
                        }
                        else if (section.active || sub.active) {
                            this.ChartingTemplateService.chartStoreSectionInit(sub, chartStore, chartStore.model.patient);
                        }
                    }
                }
                else if (section.childSections) {
                    for (var _b = 0, _c = section.childSections; _b < _c.length; _b++) {
                        var child = _c[_b];
                        if (child.sections) {
                            var isActive = false;
                            for (var _d = 0, _e = child.sections; _d < _e.length; _d++) {
                                var childSub = _e[_d];
                                if (this.sectionExists(chartStore, childSub)) {
                                    child.active = true;
                                    section.active = true;
                                    section.expanded = true;
                                    break;
                                }
                                else if (childSub.active) {
                                    isActive = true;
                                }
                            }
                            if (isActive) {
                                this.ChartingTemplateService.chartStoreSectionInit(child, chartStore, chartStore.model.patient);
                            }
                        }
                        else {
                            if (this.sectionExists(chartStore, child)) {
                                child.active = true;
                                section.active = true;
                                section.expanded = true;
                            }
                            else if (child.active) {
                                this.ChartingTemplateService.chartStoreSectionInit(child, chartStore, chartStore.model.patient);
                            }
                        }
                    }
                }
                else if (section.active && !this.sectionExists(chartStore, section)) {
                    this.ChartingTemplateService.chartStoreSectionInit(section, chartStore, chartStore.model.patient);
                }
            };
            ChartingSectionManagerService.prototype.getTemplateType = function () {
                return this.templateType;
            };
            ChartingSectionManagerService.prototype.checkEmptySections = function (section, formModel) {
                return this.ChartUtilService.checkEmptySections(section, formModel);
            };
            ChartingSectionManagerService.prototype.checkRequiredFields = function (chartStore, chartScreen) {
                var _this = this;
                var _a, _b;
                if (!this.isEncounterCharting) {
                    return;
                }
                if (!this.checkingRequired) {
                    // let checkET: boolean = this.encounterType; // && this.encounterType.name !== 'encounter_charting';
                    if (!this.encounterType) {
                        return;
                    }
                    this.checkingRequired = true;
                }
                else {
                    if (this.checkingRequiredTimeout) {
                        this.$timeout.cancel(this.checkingRequiredTimeout);
                    }
                    this.checkingRequiredTimeout = this.$timeout(function () {
                        _this.checkRequiredFields(chartStore, chartScreen);
                    }, 500);
                }
                if (this.checkingRequiredTimeout) {
                    this.$timeout.cancel(this.checkingRequiredTimeout);
                }
                var sections;
                var tempCS = { model: angular.copy(chartStore.model) };
                var incompleteScreens = [];
                chartStore = tempCS;
                var required = 0, requiredComplete = 0;
                var fm = null;
                var allScreens = false;
                var screens;
                var hasValue;
                if (chartScreen) {
                    if (chartScreen.parent_name) {
                        var parentScreen = this.ChartingTemplateService.getSectionFromScreens(chartScreen.parent_name, this.otherSections, 'name');
                        if (parentScreen && !parentScreen.childSections) {
                            screens = [parentScreen];
                        }
                        else {
                            screens = [chartScreen];
                        }
                    }
                    else {
                        screens = [chartScreen];
                    }
                }
                else {
                    screens = this.flattened_sections;
                    allScreens = true;
                }
                for (var _i = 0, screens_1 = screens; _i < screens_1.length; _i++) {
                    var screen_1 = screens_1[_i];
                    if (screen_1.has_required && (screen_1.active || !allScreens)) {
                        sections = [];
                        sections = this.ChartingTemplateService.getFormDefinition(screen_1.name, chartStore.model.encounter_type_id, chartStore.model.patient_id, chartStore.model.encounter_type);
                        var newScreen = { screen: screen_1, fields: [], required: 0, requiredComplete: 0 };
                        fm = this.ChartingTemplateService.formModelInit(chartStore, screen_1.name);
                        // if (!Array.isArray(fm)) {
                        //   fm = [fm];
                        // }
                        for (var _c = 0, sections_1 = sections; _c < sections_1.length; _c++) {
                            var section = sections_1[_c];
                            if (section.required_fields && section.required_fields.length) {
                                for (var _d = 0, _e = section.required_fields; _d < _e.length; _d++) {
                                    var field = _e[_d];
                                    if (field.required) {
                                        var model = void 0;
                                        if (!Array.isArray(fm[section.model])) {
                                            model = [fm[section.model]];
                                        }
                                        else {
                                            model = fm[section.model];
                                        }
                                        for (var _f = 0, model_1 = model; _f < model_1.length; _f++) {
                                            var m = model_1[_f];
                                            newScreen.required++;
                                            if (section.name == 'edmonton_assessment') {
                                                hasValue = m[field.field] || m[field.field + '_unable'];
                                            }
                                            else if (field.type === 'booltext') {
                                                hasValue = m['has_' + field.field] || m['note_' + field.field];
                                            }
                                            else if (field.type === 'checklist') {
                                                hasValue = m[field.field] && m[field.field].length;
                                            }
                                            else if (field.type === 'rating' && field.minZero) {
                                                hasValue = m[field.field] !== null && m[field.field] !== undefined;
                                            }
                                            else if (section.name === 'electronic_visit_verification') {
                                                if (field.field === 'has_signature') {
                                                    hasValue = m.signature_pad_png_base64 ? true : false;
                                                }
                                                if (field.field === 'has_location') {
                                                    hasValue = !!((_a = m) === null || _a === void 0 ? void 0 : _a.latitude) && !!((_b = m) === null || _b === void 0 ? void 0 : _b.longitude);
                                                }
                                            }
                                            else {
                                                hasValue = m[field.field] ? true : false;
                                            }
                                            if (hasValue) {
                                                newScreen.requiredComplete++;
                                            }
                                            else {
                                                newScreen.fields.push(field.label);
                                            }
                                        }
                                    }
                                    else if (field.requiredFn) {
                                        for (var _g = 0, fm_1 = fm; _g < fm_1.length; _g++) {
                                            var m = fm_1[_g];
                                            if (field.requiredFn(m[section.model])) {
                                                newScreen.required++;
                                                if (m[section.model][field.field]) {
                                                    newScreen.requiredComplete++;
                                                }
                                                else {
                                                    newScreen.fields.push(field.label);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (newScreen.required) {
                            incompleteScreens.push(newScreen);
                        }
                    }
                }
                var _loop_4 = function (s) {
                    this_2.chartProgress.incompleteScreens.forEach(function (ics, idx) {
                        if (s.screen.name === ics.screen.name) {
                            s.found = true;
                            _this.chartProgress.incompleteScreens[idx] = s;
                        }
                    });
                    if (!s.found) {
                        this_2.chartProgress.incompleteScreens.push(s);
                    }
                    else {
                        delete s.found;
                    }
                };
                var this_2 = this;
                for (var _h = 0, incompleteScreens_1 = incompleteScreens; _h < incompleteScreens_1.length; _h++) {
                    var s = incompleteScreens_1[_h];
                    _loop_4(s);
                }
                required = requiredComplete = 0;
                this.chartProgress.hasIncomplete = false;
                this.chartProgress.incompleteScreens.forEach(function (s, idx) {
                    required += s.required;
                    requiredComplete += s.requiredComplete;
                    if (s.required === s.requiredComplete) {
                        s.isComplete = true;
                    }
                    else {
                        s.isComplete = false;
                        _this.chartProgress.hasIncomplete = true;
                    }
                });
                this.chartProgress.requiredTotal = required;
                this.chartProgress.requiredComplete = requiredComplete;
                this.checkingRequired = false;
                return true;
            };
            ChartingSectionManagerService.prototype.toggleTag = function () {
                this.tagOnly = !this.tagOnly;
            };
            ChartingSectionManagerService.prototype.onRequiredFilterChange = function (callback) {
                this.requiredFilterChangeCallbacks.push(callback);
            };
            ChartingSectionManagerService.prototype.onHisFilterChange = function (callback) {
                this.hisFilterChangeCallbacks.push(callback);
            };
            ChartingSectionManagerService.$inject = [
                '$filter',
                '$mdSidenav',
                '$state',
                'CarePlanProblemEntry',
                'persistenceStore',
                'cache',
                'SessionService',
                'options',
                'logger',
                'ChartingTemplateService',
                'PermissionsService',
                '$timeout',
                '$rootScope',
                'ChartUtilService',
            ];
            return ChartingSectionManagerService;
        }());
        charting.ChartingSectionManagerService = ChartingSectionManagerService;
        angular.module('consolo').service('ChartingSectionManager', ChartingSectionManagerService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClinicalChartsSidebarController = /** @class */ (function () {
            function ClinicalChartsSidebarController($state, $timeout, $mdDialog, chartStore, currentPatient, SectionManager, ChartingTemplateService) {
                this.$state = $state;
                this.$timeout = $timeout;
                this.$mdDialog = $mdDialog;
                this.chartStore = chartStore;
                this.currentPatient = currentPatient;
                this.SectionManager = SectionManager;
                this.ChartingTemplateService = ChartingTemplateService;
                this.filter = { title: '' };
                this.hasOther = false;
                this.missingSections = [];
                this.gotMissing = false;
                //members
                this.action = $state.current.data.action;
                this.activeSections = SectionManager.activeSections;
                this.carePlanProblemList = SectionManager.carePlanProblemList;
                this.otherSections = SectionManager.otherSections;
                this.patient = currentPatient;
                this.chartStore = chartStore;
                this.activate();
            }
            ClinicalChartsSidebarController.prototype.activate = function () {
                this.SectionManager.init(this.chartStore);
                this.template = this.SectionManager.isEncounterCharting
                    ? 'encounter_charting'
                    : 'tablet_optimized';
                this.getMissing();
            };
            ClinicalChartsSidebarController.prototype.getMissing = function () {
                var _this = this;
                if (this.chartStore.model.charting_client === 'custom') {
                    if (this.chartStore.model.encounter_type && this.chartStore.model.encounter_type.id) {
                        this.missingSections = this.ChartingTemplateService.getMissingSections(this.chartStore.model.encounter_type);
                    }
                    else {
                        this.$timeout(function () {
                            _this.getMissing();
                        }, 500);
                    }
                }
            };
            ClinicalChartsSidebarController.prototype.closeNav = function () {
                this.SectionManager.closeNav();
            };
            ClinicalChartsSidebarController.prototype.navigateTo = function (section, addNew, parent, $event) {
                if (section && section.childSections) {
                    if (section.active) {
                        section.expanded = !!!section.expanded;
                    }
                    else {
                        this.sectionModal(section, $event);
                    }
                    return;
                }
                else if (!section) {
                    var s = {
                        title: 'All Other Screens',
                        childSections: this.missingSections,
                    };
                    // let allSections = this.otherSections;
                    // allSections.push(s);
                    this.sectionModal(this.otherSections, $event, s);
                    return;
                }
                //TODO: Refactor this & clinical-charts.states.js to do all the pushing/object creation here
                if (addNew && this.chartStore.model[section.model]) {
                    if (section.allowMultiple && !this.SectionManager.isEncounterCharting) {
                        this.chartStore.model[section.model].push({ showItem: true });
                    }
                }
                // let parentName = parent ? parent.name : null;
                // this.setActiveSection(section.name, parentName);
                this.SectionManager.navigateTo(section, this.chartStore, parent);
                this.scrollToTop();
                if (this.filter) {
                    this.filter.title = '';
                }
            };
            ClinicalChartsSidebarController.prototype.scrollToTop = function () {
                this.$timeout(function () {
                    var myDiv = document.getElementById('editorPane');
                    myDiv.scrollTop = 0;
                });
            };
            ClinicalChartsSidebarController.prototype.giveFocus = function (elem) {
                this.$timeout(function () {
                    var element = document.getElementById(elem);
                    if (element) {
                        element.focus();
                    }
                }, 0);
            };
            ClinicalChartsSidebarController.prototype.filterFocus = function () {
                var e = document.getElementById('txtFilterSections');
                e.select();
            };
            ClinicalChartsSidebarController.prototype.searchModal = function ($event) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    targetEvent: $event,
                    clickOutsideToClose: true,
                    bindToController: true,
                    templateUrl: '/y/templates/clinical-charts/components/section-search.html',
                    controller: 'ClinicalChartingScreenSearchController as $ctrl',
                    fullscreen: true,
                    locals: {
                        allScreens: this.SectionManager.otherSections,
                        encounter_type: this.chartStore.model.encounter_type,
                    },
                })
                    .then(function (returnSection) {
                    var parent;
                    if (returnSection.isChild) {
                        parent = _this.ChartingTemplateService.getSectionFromScreens(returnSection.screen.parent_name, _this.SectionManager.otherSections, 'name');
                        if (!parent) {
                            var missing = _this.ChartingTemplateService.getMissingSections(_this.chartStore.model.encounter_type);
                            for (var _i = 0, missing_1 = missing; _i < missing_1.length; _i++) {
                                var m = missing_1[_i];
                                m.parent_name = '_menu_all_other';
                                m.parent_title = 'All Other Screens';
                            }
                            parent = {
                                title: 'All Other Screens',
                                name: '_menu_all_other',
                                childSections: missing,
                            };
                            _this.SectionManager.otherSections.push(parent);
                            returnSection.screen = _this.ChartingTemplateService.getSectionFromScreens(returnSection.screen.name, missing);
                            _this.SectionManager.hasOther = true;
                            _this.SectionManager.checkSectionPermissions(parent, _this.chartStore);
                        }
                        parent.active = parent.expanded = true;
                        _this.SectionManager.setFlattenedSections();
                    }
                    _this.navigateTo(returnSection.screen, true, parent, $event);
                });
                // controller: [
                //   'allScreens',
                //   'flattenedScreens',
                //   'missingScreens',
                //   (allScreens, flattenedScreens, missingScreens) => {},
                // ],
                // controllerAs: '$ctrl',
                // template: `
                // <md-dialog flex="75" style="height:80%">
                //   <md-dialog-content>
                //     <consolo-charting-screen-search
                //       all-screens="$ctrl.allScreens"
                //       flattened-screens="$ctrl.flattened-screens"
                //       missing-screens="$ctrl.missingScreens"
                //     />
                //   </md-dialog-content>
                // </md-dialog>`,
            };
            ClinicalChartsSidebarController.prototype.sectionModal = function (section, event, missingSections) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    targetEvent: event,
                    clickOutsideToClose: true,
                    bindToController: true,
                    templateUrl: '/y/templates/clinical-charts/sections/section-picker-modal.html',
                    controller: 'ClinicalChartsSectionPickerModalController as $ctrl',
                    fullscreen: true,
                    locals: { section: section, encounterType: null, missingSections: missingSections },
                })
                    .then(function (newSections) {
                    //TODO: Find out why this would ever be undefined
                    if (newSections && newSections.length) {
                        for (var _i = 0, newSections_1 = newSections; _i < newSections_1.length; _i++) {
                            var section_1 = newSections_1[_i];
                            _this.ChartingTemplateService.chartStoreSectionInit(section_1, _this.chartStore, _this.chartStore.model.patient);
                            _this.SectionManager.checkRequiredFields(_this.chartStore, section_1);
                        }
                        if (missingSections) {
                            _this.otherSections.push(section);
                            _this.SectionManager.hasOther = true;
                            _this.SectionManager.checkSectionPermissions(section, _this.chartStore);
                        }
                    }
                    _this.SectionManager.setFlattenedSections();
                });
            };
            ClinicalChartsSidebarController.prototype.isCurrentSection = function (section) {
                var _this = this;
                return (this.SectionManager.currentSection.name === section.name ||
                    (section.childSections &&
                        section.childSections.some(function (child) { return child.name === _this.SectionManager.currentSection.name; })));
            };
            ClinicalChartsSidebarController.$inject = [
                '$state',
                '$timeout',
                '$mdDialog',
                'chartStore',
                'currentPatient',
                'ChartingSectionManager',
                'ChartingTemplateService',
            ];
            return ClinicalChartsSidebarController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsSidebarController', ClinicalChartsSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var directives;
    (function (directives) {
        'use strict';
        var SignaturePadDirective = /** @class */ (function () {
            // link: IDirectiveLinkFn;
            // static $inject: Array<string> = ['$window', '$timeout'];
            function SignaturePadDirective($window, $timeout) {
                var _this = this;
                this.$window = $window;
                this.$timeout = $timeout;
                this.link = function ($scope, element) {
                    $scope.clear = function () {
                        pad.clear();
                    };
                    var writeSignature = function () {
                        _this.$timeout(function () {
                            $scope.signature = pad.toDataURL();
                            if ($scope.callback) {
                                $scope.callback();
                            }
                        });
                    };
                    var pad = new _this.$window.SignaturePad(element.find('canvas')[0], {
                        onEnd: writeSignature,
                    });
                    if ($scope.signature) {
                        pad.fromDataURL($scope.signature);
                    }
                };
                this.restrict = 'E';
                this.scope = { signature: '=', callback: '=' };
                this.templateUrl = '/y/templates/clinical-charts/signature-pad.html';
            }
            return SignaturePadDirective;
        }());
        angular
            .module('consolo')
            .directive('consoloSignaturePad', [
            '$window',
            '$timeout',
            function ($window, $timeout) { return new SignaturePadDirective($window, $timeout); },
        ]);
    })(directives = consolo.directives || (consolo.directives = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ClinicalChartsViewController = /** @class */ (function () {
            function ClinicalChartsViewController(ChartingTemplateService, SectionManager, PersistedItem, options, Session) {
                this.ChartingTemplateService = ChartingTemplateService;
                this.SectionManager = SectionManager;
                this.PersistedItem = PersistedItem;
                this.options = options;
                this.Session = Session;
                this.problemListEnabled = false;
                this.scale = {};
                this.scales = [
                    { description: '', link: '' },
                    { description: 'ALSFRS', link: '/performance_scales/alsfrs' },
                    { description: 'ECOG', link: '/performance_scales/ecog' },
                    { description: 'Edmonton', link: '/performance_scales/edmonton' },
                    { description: 'FAST', link: '/performance_scales/fast' },
                    { description: 'Geriatric Depression', link: '/performance_scales/gds' },
                    { description: 'Glasgow Coma', link: '/performance_scales/glasgow' },
                    { description: 'Karnofsky', link: '/performance_scales/karnofsky' },
                    { description: 'Mitchel Mortality Risk', link: '/performance_scales/mitchel' },
                    { description: 'Morse', link: '/performance_scales/morse' },
                    { description: 'NYHA', link: '/performance_scales/nyha' },
                    { description: 'Palliative', link: '/performance_scales/palliative' },
                ];
                this.careTypes = options.get('clinical_care_types');
                this.discomfort = options.get('discomfort');
                this.discomfortNoAnswerReasons = options.get('discomfort_no_answer_reasons');
                this.flaccFace = options.get('flacc_face');
                this.flaccLegs = options.get('flacc_legs');
                this.flaccActivity = options.get('flacc_activity');
                this.flaccCry = options.get('flacc_cry');
                this.flaccConsolability = options.get('flacc_consolability');
                this.painadBodyLang = options.get('painad_body_lang');
                this.painadBreathingRatings = options.get('painadd_breathing_ratings');
                this.painadConsolability = options.get('painadd_consolability');
                this.painadFacial = options.get('painadd_facial');
                this.painadNegVoc = options.get('painadd_neg_voc');
                this.painFrequencies = options.get('pain_frequencies');
                this.painRatings = options.get('pain_ratings');
                this.painTypes = options.get('pain_types');
                this.scaleTypes = [{ id: 'Verbal' }, { id: 'PAINAD' }, { id: 'FLACC' }];
                this.yesNo = [{ id: '' }, { id: 'Yes' }, { id: 'No' }];
                this.problemListEnabled = Session.get().agency.problem_list_enabled;
            }
            ClinicalChartsViewController.prototype.activate = function (chart) {
                var _this = this;
                this.clinicalChart = chart;
                this.chartStore = this.PersistedItem('clinical_chart_entry', chart);
                //this.sections = this.ChartingTemplateService.getFieldDefinitions();
                this.sections = this.ChartingTemplateService.getAllBaseSections(true);
                this.formDefinitions = {};
                this.formModels = {};
                var encounter_type = this.clinicalChart.model
                    ? this.clinicalChart.model.encounter_type
                    : this.clinicalChart.encounter_type;
                this.sections.forEach(function (section) {
                    if (section.name !== 'problem_list' || _this.problemListEnabled) {
                        _this.formModels[section.model] = _this.clinicalChart[section.model];
                        _this.formDefinitions[section.model] = _this.ChartingTemplateService.getFormDefinition(section.name, null, _this.clinicalChart.patient_id, encounter_type);
                    }
                });
            };
            ClinicalChartsViewController.$inject = [
                'ChartingTemplateService',
                'ChartingSectionManager',
                'PersistedItem',
                'options',
                'SessionService',
            ];
            return ClinicalChartsViewController;
        }());
        controllers.ClinicalChartsViewController = ClinicalChartsViewController;
        angular
            .module('consolo')
            .controller('ClinicalChartsViewController', ClinicalChartsViewController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClinicalTripsViewController = /** @class */ (function () {
            function ClinicalTripsViewController($state, $mdDialog, $interval, logger, clinicalTripsService, authorized, $window) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$interval = $interval;
                this.logger = logger;
                this.clinicalTripsService = clinicalTripsService;
                this.authorized = authorized;
                this.$window = $window;
                this.stopPoints = [];
                this.tripLatLng = [];
                this.tripId = $state.params['tripId'] ? +$state.params['tripId'] : null;
                this.$onInit();
            }
            ClinicalTripsViewController.prototype.$onInit = function () {
                if (!this.authorized) {
                    this.logger.error('You do not have permission to view this page');
                    this.$state.go('unauthorized');
                }
                this.getSingleClinicalTrip(this.tripId);
                this.initializeClinicalTripGrid();
            };
            ClinicalTripsViewController.prototype.getSingleClinicalTrip = function (tripId) {
                var _this = this;
                this.clinicalTripsService.getSingleClinicalTrip(tripId).then(function (response) {
                    _this.clinicalTrip = response.data;
                    _this.sortStopPoints(_this.clinicalTrip.stop_points);
                    _this.setStopPoints(response.data);
                    _this.setLatLng(response.data);
                });
            };
            ClinicalTripsViewController.prototype.sortStopPoints = function (stopPoints) {
                var timeInStops = this.sortTimeInStops(stopPoints);
                var noTimeStops = this.sortPositionStops(stopPoints);
                this.clinicalTrip.stop_points = timeInStops.concat(noTimeStops);
                this.clinicalTrip.stop_points.forEach(function (stop, index) {
                    stop.displayIndex = index + 1;
                });
            };
            ClinicalTripsViewController.prototype.sortTimeInStops = function (stop_points) {
                return stop_points
                    .filter(function (point) {
                    return (point.billable_time && point.billable_time.in_datetime) || point.arrived_at;
                })
                    .sort();
            };
            ClinicalTripsViewController.prototype.sortPositionStops = function (stop_points) {
                return stop_points
                    .filter(function (point) {
                    return !point.billable_time && !point.arrived_at;
                })
                    .sort(function (a, b) {
                    return a.position - b.position;
                });
            };
            ClinicalTripsViewController.prototype.setStopPoints = function (clinicalTrip) {
                var stopPoints = [];
                stopPoints = clinicalTrip.stop_points.map(function (point) {
                    return point.address_1 + ', ' + point.city + ', ' + point.state + ', ' + point.zipcode;
                });
                if (clinicalTrip.origin_latitude && clinicalTrip.origin_longitude) {
                    stopPoints.unshift(new google.maps.LatLng(clinicalTrip.origin_latitude, clinicalTrip.origin_longitude));
                }
                this.stopPoints = stopPoints;
            };
            ClinicalTripsViewController.prototype.setLatLng = function (clinicalTrip) {
                var latLngPoints = [];
                latLngPoints = clinicalTrip.stop_points.map(function (point) {
                    return new google.maps.LatLng(point.latitude, point.longitude);
                });
                if (clinicalTrip.origin_latitude && clinicalTrip.origin_longitude) {
                    latLngPoints.unshift(new google.maps.LatLng(clinicalTrip.origin_latitude, clinicalTrip.origin_longitude));
                }
                this.tripLatLng = latLngPoints;
            };
            ClinicalTripsViewController.prototype.exportClinicalTripsToCSV = function () {
                console.log('Exported to CSV');
            };
            ClinicalTripsViewController.prototype.initializeClinicalTripGrid = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.clinicalTripLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                        defaultSortField: 'position',
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    _this.exportClinicalTripsToCSV();
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data) {
                                    var timeSegment = data.billable_time.type === 'PatientTime'
                                        ? '/patient_times/'
                                        : '/non_clinical_times/';
                                    var editPath = "/users/" + _this.clinicalTrip.user.id + timeSegment + data.billable_time.id + "/edit";
                                    return _this.$window.open(editPath, '_blank');
                                },
                                conditionalHideFn: function (data) {
                                    return !(data.billable_time &&
                                        !data.billable_time.deleted_at &&
                                        (data.billable_time.type === 'PatientTime' ||
                                            data.billable_time.type === 'NonClinicalTime'));
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Stop #',
                            field: 'displayIndex',
                            type: csg.datatable.ColumnTypes.short_text,
                            cssClass: 'stop-number',
                        },
                        {
                            title: 'Destination',
                            field: 'destination_name',
                            type: csg.datatable.ColumnTypes.link,
                            callbackFn: function (data) {
                                var path = '';
                                if (data.billable_time && data.clinical_chart_id) {
                                    path =
                                        '/patients/' +
                                            data.patient_id +
                                            '/clinical_charts/' +
                                            data.billable_time.clinical_chart_id;
                                    return _this.$window.open(path, '_blank');
                                }
                                else if (data.billable_time && !data.clinical_chart_id) {
                                    path =
                                        '/users/' +
                                            data.billable_time.user_id +
                                            '/billable_times/' +
                                            data.billable_time.id;
                                    return _this.$window.open(path, '_blank');
                                }
                                else {
                                    return false;
                                }
                            },
                            cssClassFn: function (data) {
                                if (data.billable_time && data.clinical_chart_id) {
                                    return 'destination-clickable';
                                }
                                else if (data.billable_time && !data.clinical_chart_id) {
                                    return 'destination-clickable';
                                }
                                else {
                                    return 'destination-non-clickable';
                                }
                            },
                        },
                        {
                            title: 'Travel Time*',
                            field: 'billable_time.travel_time',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    value = Number(value);
                                    var m = Math.floor(value / 60);
                                    var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' min ') : '0';
                                    return mDisplay;
                                }
                                else {
                                    return '0';
                                }
                            },
                        },
                        {
                            title: 'Time In*',
                            field: 'billable_time.in_datetime',
                            type: csg.datatable.ColumnTypes.date,
                            formatValueFn: function (data, col, value) {
                                if (data.billable_time && data.billable_time.in_datetime) {
                                    var billedTimeArrival = data.billable_time.in_datetime;
                                    var d = new Date(billedTimeArrival), timeStr = void 0;
                                    var hour = d.getHours();
                                    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
                                    if (hour > 12) {
                                        return (timeStr = hour - 12 + ':' + minutes + 'pm');
                                    }
                                    else if (hour === 12) {
                                        return (timeStr = '12:' + minutes + 'pm');
                                    }
                                    else if (hour === 0) {
                                        return (timeStr = '12:' + minutes + 'am');
                                    }
                                    else {
                                        return (timeStr = hour + ':' + minutes + 'am');
                                    }
                                }
                                else if (data.arrived_at) {
                                    var unbilledArrival = data.arrived_at;
                                    var d = new Date(unbilledArrival), timeStr = void 0;
                                    var hour = d.getHours();
                                    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
                                    if (hour > 12) {
                                        return (timeStr = hour - 12 + ':' + minutes + 'pm');
                                    }
                                    else if (hour === 12) {
                                        return (timeStr = '12:' + minutes + 'pm');
                                    }
                                    else if (hour === 0) {
                                        return (timeStr = '12:' + minutes + 'am');
                                    }
                                    else {
                                        return (timeStr = hour + ':' + minutes + 'am');
                                    }
                                }
                                else {
                                    return 'N/A';
                                }
                            },
                        },
                        {
                            title: 'Time Out*',
                            field: 'billable_time.out_datetime',
                            type: csg.datatable.ColumnTypes.date,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    var d = new Date(value), timeStr = void 0;
                                    var hour = d.getHours();
                                    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
                                    if (hour > 12) {
                                        return (timeStr = hour - 12 + ':' + minutes + 'pm');
                                    }
                                    else if (hour === 12) {
                                        return (timeStr = '12:' + minutes + 'pm');
                                    }
                                    else if (hour === 0) {
                                        return (timeStr = '12:' + minutes + 'am');
                                    }
                                    else {
                                        return (timeStr = hour + ':' + minutes + 'am');
                                    }
                                }
                                else {
                                    return 'N/A';
                                }
                            },
                        },
                        {
                            title: 'Billable',
                            field: 'billable',
                            type: csg.datatable.ColumnTypes.boolean,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return 'Yes';
                                }
                                else {
                                    return 'No';
                                }
                            },
                        },
                        {
                            title: 'Map Mileage',
                            field: 'raw_mileage',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return value;
                                }
                                else {
                                    return 'Pending';
                                }
                            },
                        },
                        {
                            title: 'GPS Mileage',
                            field: 'gps_mileage',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return value;
                                }
                                else {
                                    return 'N/A';
                                }
                            },
                        },
                        {
                            title: 'Adj. Mileage',
                            field: 'calculated_mileage',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return value;
                                }
                                else {
                                    return 'N/A';
                                }
                            },
                        },
                        {
                            title: 'Billed Mileage',
                            field: 'billable_mileage',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return value;
                                }
                                else {
                                    return 'N/A';
                                }
                            },
                        },
                        {
                            title: 'Variance',
                            field: 'variance',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Flagged',
                            field: 'flagged_display',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                };
                this.isLoading = false;
            };
            ClinicalTripsViewController.$inject = [
                '$state',
                '$mdDialog',
                '$interval',
                'logger',
                'clinicalTripsService',
                'authorized',
                '$window',
            ];
            return ClinicalTripsViewController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalTripsViewController', ClinicalTripsViewController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClinicalTripsController = /** @class */ (function () {
            function ClinicalTripsController($state, $mdDialog, $interval, logger, clinicalTripsService, RouteManager, authorized) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$interval = $interval;
                this.logger = logger;
                this.clinicalTripsService = clinicalTripsService;
                this.RouteManager = RouteManager;
                this.authorized = authorized;
                this.$onInit();
            }
            ClinicalTripsController.prototype.$onInit = function () {
                if (!this.authorized) {
                    this.logger.error('You do not have permission to view this page');
                    this.$state.go('unauthorized');
                }
                console.log('Clinical Trips main screen works');
                this.getAllClinicalTrips();
            };
            ClinicalTripsController.prototype.getAllClinicalTrips = function () {
                var _this = this;
                this.clinicalTripsService.getAllClinicalTrips().then(function (response) {
                    console.log(response.data);
                    _this.clinicalTrips = response.data;
                    _this.initializeClinicalTripsGrid();
                });
            };
            ClinicalTripsController.prototype.exportClinicalTripsToCSV = function () {
                console.log('Exported to CSV');
            };
            ClinicalTripsController.prototype.initializeClinicalTripsGrid = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.clinicalTripsLayout = {
                    id_field: 'id',
                    options: { hideTextFilter: true },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    _this.exportClinicalTripsToCSV();
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'clinical-trips.view({tripId: $id})',
                        },
                        { title: 'User', field: 'user.name', type: csg.datatable.ColumnTypes.short_text },
                        {
                            title: 'Billable Mileage',
                            field: 'billable_mileage',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return value;
                                }
                                else {
                                    return 'Pending';
                                }
                            },
                        },
                    ],
                };
                this.isLoading = false;
            };
            ClinicalTripsController.$inject = [
                '$state',
                '$mdDialog',
                '$interval',
                'logger',
                'clinicalTripsService',
                'RouteManagerService',
                'authorized',
            ];
            return ClinicalTripsController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalTripsController', ClinicalTripsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var ClinicalTripsStates = /** @class */ (function () {
            function ClinicalTripsStates($stateProvider) {
                $stateProvider
                    .state('clinical-trips', {
                    url: '/clinical-trips',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/clinical-trips/clinical-trips.html',
                            controller: 'ClinicalTripsController as vm',
                        },
                    },
                    resolve: {
                        authorized: [
                            'PermissionsService',
                            'SessionService',
                            function (Permissions, Session) {
                                var session = Session.get();
                                var p = Permissions.can('show', 'clinical_trips');
                                return ((session.agency.has_visit_tracker || session.agency.has_aide_app) &&
                                    Permissions.can('show', 'clinical_trips'));
                            },
                        ],
                    },
                })
                    .state('clinical-trips.view', {
                    url: '/:tripId',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/clinical-trips/clinical-trips-view.html',
                            controller: 'ClinicalTripsViewController as vm',
                        },
                    },
                    resolve: {
                        authorized: [
                            'PermissionsService',
                            'SessionService',
                            function (Permissions, Session) {
                                var session = Session.get();
                                return ((session.agency.has_visit_tracker || session.agency.has_aide_app) &&
                                    Permissions.can('show', 'clinical_trips'));
                            },
                        ],
                    },
                });
            }
            ClinicalTripsStates.$inject = ['$stateProvider'];
            return ClinicalTripsStates;
        }());
        angular.module('consolo').config(ClinicalTripsStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var AboutService = /** @class */ (function () {
            function AboutService($q, $http) {
                this.$q = $q;
                this.$http = $http;
            }
            AboutService.prototype.get = function () {
                var _this = this;
                if (!this.cachedAbout) {
                    this.cachedAbout = this.$http.get('/api/v1/about').then(function (resp) { return resp.data; }, function (resp) {
                        _this.cachedAbout = null;
                        return _this.$q.reject(resp);
                    });
                }
                return this.cachedAbout;
            };
            AboutService.prototype.menus = function () {
                return this.$http.get('/api/v1/menus').then(function (resp) { return resp.data; });
            };
            AboutService.prototype.mapquest_sdk = function (params) {
                return this.$http
                    .get('/api/v1/about/mapquest_sdk', { params: params || {} })
                    .then(function (resp) { return resp.data; });
            };
            AboutService.prototype.citus_web_url = function () {
                return this.$http.get('/api/v1/electronic_documents/citus_web_url').then(function (resp) { return resp.data; });
            };
            AboutService.prototype.createNewAuditLog = function (event, message, patient_id) {
                return this.$http.post('/api/v2/audits/entry', { event: event, message: message, patient_id: patient_id }).then(function (resp) { return resp.data; });
            };
            AboutService.$inject = ['$q', '$http'];
            return AboutService;
        }());
        common.AboutService = AboutService;
        angular.module('consolo').service('AboutService', AboutService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AlertsDashboardDataTableService = /** @class */ (function (_super) {
            __extends(AlertsDashboardDataTableService, _super);
            function AlertsDashboardDataTableService($http) {
                var _this = _super.call(this, $http) || this;
                _this.apiSelectUrl = '/api/v1/dashboard/alerts';
                return _this;
            }
            AlertsDashboardDataTableService.$inject = ['$http'];
            return AlertsDashboardDataTableService;
        }(csg.datatable.ApiService));
        services.AlertsDashboardDataTableService = AlertsDashboardDataTableService;
        angular
            .module('consolo')
            .service('alertsDashboardDataTableService', AlertsDashboardDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AlertsService = /** @class */ (function () {
            function AlertsService($http) {
                this.$http = $http;
            }
            AlertsService.prototype.select = function () {
                return this.$http({
                    url: '/api/v1/dashboard/alerts',
                    method: 'GET',
                });
            };
            AlertsService.$inject = ['$http'];
            return AlertsService;
        }());
        services.AlertsService = AlertsService;
        angular.module('consolo').service('alertsService', AlertsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var AnyYesNoComponent = /** @class */ (function () {
            function AnyYesNoComponent() {
                this.bindings = {
                    ngModel: '<',
                };
                this.require = {
                    ngModelCtrl: 'ngModel',
                };
                this.controller = AnyYesNoController;
                this.template = "\n      <div class='any-yes-no' layout=\"row\">\n        <md-button ng-click=\"$ctrl.clickButton('any')\" class=\"any\" ng-class=\"$ctrl.buttonClasses.any\">\n          Any\n        </md-button>\n        <md-button ng-click=\"$ctrl.clickButton('yes')\" class=\"yes\" ng-class=\"$ctrl.buttonClasses.yes\">\n          Yes\n        </md-button>\n        <md-button ng-click=\"$ctrl.clickButton('no')\" class=\"no\" ng-class=\"$ctrl.buttonClasses.no\">\n          No\n        </md-button>\n      </div>\n    ";
            }
            return AnyYesNoComponent;
        }());
        var AnyYesNoController = /** @class */ (function () {
            function AnyYesNoController() {
                this.buttonClasses = { yes: [], no: [], any: [] };
            }
            AnyYesNoController.prototype.$onInit = function () { };
            AnyYesNoController.prototype.$onChanges = function () {
                if (this.ngModel && this.buttonClasses[this.ngModel]) {
                    this.updateClasses(this.ngModel);
                }
            };
            AnyYesNoController.prototype.clickButton = function (val) {
                this.ngModel = val;
                this.ngModelCtrl.$setViewValue(val);
                this.updateClasses(val);
            };
            AnyYesNoController.prototype.updateClasses = function (val) {
                this.buttonClasses.yes = [];
                this.buttonClasses.no = [];
                this.buttonClasses.any = [];
                this.buttonClasses[val].splice(0, 0, ['md-primary', 'md-raised']);
            };
            return AnyYesNoController;
        }());
        angular.module('consolo').component('anyYesNo', new AnyYesNoComponent());
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('apiErrorHandlerInjector', ['$q', '$rootScope', Interceptor]);
    function Interceptor($q, $root) {
        return {
            responseError: function (response) {
                switch (response.status) {
                    case 403: //Forbidden
                        var errorText = 'You do not have permission to perform this action';
                        if (response.data.errors) {
                            errorText = response.data.errors[0];
                        }
                        $root.$broadcast('http_server_error', errorText);
                        break;
                    case 404: //Not Found
                        $root.$broadcast('http_server_error', 'Page not found');
                        break;
                    case 500: // Unknown server error
                        $root.$broadcast('http_server_error', 'Unknown error (code 500)');
                        break;
                    case 502: // Bad Gateway
                    case 503: // Server Unavailable
                    case 504: // Gateway Timeout
                        $root.$broadcast('http_server_error', 'Consolo is temporarily unavailable; try again soon (code ' + response.status + ')');
                        break;
                    case 0: // No network connection
                        if (!response.data) {
                            response.data = {};
                        }
                        break;
                }
                // Lots of our code doesn't check for .errors being null, so fix it here instead
                if (!response.data) {
                    response.data = {};
                }
                if (!response.data.errors && typeof (response.data) === 'object') {
                    response.data.errors = [];
                }
                return $q.reject(response);
            },
        };
    }
})();

Object.defineProperty(Array.prototype, 'forEachSlice', {
    value: function (size, callback) {
        'use strict';
        for (var i = 0; i < this.length; i += size) {
            var slice = this.slice(i, i + size);
            callback(slice);
        }
    },
});

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('authTokenInjector', ['AccessTokenService', Interceptor]);
    /*
     * X-Amz-Algorithm query param | Signature query param | Authorization header are mutually exclusive
     * causing an AWS presigned url to be rejected if more than one is present
     * If the Authorization header needs to be used instead of either X-Amz-Algorithn
     * or Signature, simply remove them from the params before making the API call
     * This MIGHT be true for pre-signed posts and config.data but unsure
     */
    var publicUrls = [
        'X-Amz-Algorithm',
        'Signature',
        '/api/v1/about',
        '/y/assets/',
        '/y/templates/',
    ];
    function Interceptor(AccessTokenService) {
        return {
            request: function (config) {
                var isPublic = !!config.url && !!publicUrls.find(function (pub) { return config.url.indexOf(pub) >= 0; });
                if (isPublic) {
                    return config;
                }
                return AccessTokenService.getToken().then(function (token) {
                    config.headers.Authorization = 'Bearer ' + token;
                    return config;
                }, function () { return config; });
            },
        };
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        /**
         * Blocks the Back button in certain situations. NOTE this will only work for one very particular case
         * until we stop using "#" based navigation:
         *
         * If a full page happens with a route like "/y/some/route#no-back" angular-ui-router will remove the #no-back,
         * pusing it onto the history. When the user hits back the onhashchange event will catch it and move the user
         * forward again.
         */
        var BackButtonBlocker = /** @class */ (function () {
            function BackButtonBlocker() {
            }
            BackButtonBlocker.prototype.initialize = function () {
                window.addEventListener('hashchange', function () {
                    if (location.hash === '#no-back') {
                        history.go(1);
                    }
                }, false);
            };
            BackButtonBlocker.$inject = [];
            return BackButtonBlocker;
        }());
        common.BackButtonBlocker = BackButtonBlocker;
        angular.module('consolo').service('BackButtonBlocker', BackButtonBlocker);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var BeaconService = /** @class */ (function () {
            function BeaconService($http, pubSub) {
                this.$http = $http;
                this.pubSub = pubSub;
                this.extensionName = 'BeaconService';
                this.beaconUrl = '/beacon/';
            }
            BeaconService.prototype.onSessionVisible = function (session) {
                var _this = this;
                if (session.agency.has_messenger_pro) {
                    this.hasBeacon = true;
                    this.getUnreadCount(session.agency.code)
                        .then(function (resp) {
                        _this.unreadMessages = resp.data.unread;
                    })
                        .catch(function (error) {
                        console.log('Failed to fetch Beacon unread count.', error);
                    });
                    this.pubSub.onOpen(function () {
                        _this.pubSub
                            .channel("messenger:user:" + session.user.uuid)
                            .then(function (channel) {
                            channel.on('unread', function (response) {
                                if (response.hasOwnProperty(session.agency.code)) {
                                    _this.unreadMessages = response[session.agency.code];
                                }
                            });
                        })
                            .catch(function (error) {
                            console.log('Failed to connect to Beacon channel.', error);
                        });
                    });
                }
            };
            BeaconService.prototype.getPatientMessages = function (patientId, page) {
                var _this = this;
                if (page === void 0) { page = 1; }
                return this.getMessengerJwt(patientId).then(function (res) {
                    return _this.$http.get("/beacon/api/v1/patient_record/messages?patient_jwt=" + res.data.jwt + "&page=" + page);
                });
            };
            BeaconService.prototype.getUnreadCount = function (agency) {
                return this.$http.get("/beacon/api/v1/count?agency=" + agency + "&touch=false");
            };
            BeaconService.prototype.getMessengerJwt = function (patientId) {
                return this.$http.get("/api/v1/patients/" + patientId + "/jwt?target=messenger");
            };
            BeaconService.$inject = ['$http', 'PubSubService'];
            return BeaconService;
        }());
        common.BeaconService = BeaconService;
        angular.module('consolo').service('BeaconService', BeaconService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('boundModel', function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                scope.$watch(attrs.boundModel, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        ngModel.$setViewValue(newValue);
                        ngModel.$render();
                    }
                });
            },
        };
    });
})();

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('breadcrumbNav', {
            bindings: {
                patient: '<',
                title: '@',
                version: '<',
            },
            transclude: true,
            templateUrl: '/y/templates/common/breadcrumb-nav.html',
            controller: function () { },
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var BrowserDetectionService = /** @class */ (function () {
            function BrowserDetectionService($http) {
                this.$http = $http;
            }
            BrowserDetectionService.prototype.getBrowser = function () {
                if (this.isSafari()) {
                    return 'Safari';
                }
                else if (this.isFirefox()) {
                    return 'Firefox';
                }
                else if (this.isOpera()) {
                    return 'Opera';
                }
                else if (this.isInternetExplorer()) {
                    return 'Internet';
                }
                else if (this.isEdge()) {
                    return 'Edge';
                }
                else if (this.isChrome()) {
                    return 'Chrome';
                }
                else if (this.isBlink()) {
                    return 'Blink';
                }
                else {
                    return 'Undefined';
                }
            };
            BrowserDetectionService.prototype.isSafari = function () {
                return (/constructor/i.test(window.HTMLElement.toString()) ||
                    (function (p) {
                        return p.toString() === '[object SafariRemoteNotification]';
                    })(!window['safari'] || window['safari'].pushNotification));
            };
            BrowserDetectionService.prototype.isFirefox = function () {
                return typeof InstallTrigger !== 'undefined';
            };
            BrowserDetectionService.prototype.isOpera = function () {
                return ((!!window['opr'] && !!window['opr'].addons) ||
                    !!window['opera'] ||
                    navigator.userAgent.indexOf(' OPR/') >= 0);
            };
            BrowserDetectionService.prototype.isInternetExplorer = function () {
                return /*@cc_on!@*/ false || !!document['documentMode'];
            };
            BrowserDetectionService.prototype.isEdge = function () {
                return !this.isInternetExplorer() && !!window['StyleMedia'];
            };
            BrowserDetectionService.prototype.isChrome = function () {
                return !!window['chrome'] && !!window['chrome'].webstore;
            };
            BrowserDetectionService.prototype.isBlink = function () {
                return (this.isChrome() || this.isOpera()) && !!window['CSS'];
            };
            BrowserDetectionService.$inject = ['$http'];
            return BrowserDetectionService;
        }());
        services.BrowserDetectionService = BrowserDetectionService;
        angular.module('consolo').service('browserDetectionService', BrowserDetectionService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CPEnrollmentService = /** @class */ (function () {
            function CPEnrollmentService($http) {
                this.$http = $http;
            }
            CPEnrollmentService.prototype.getAllCarePrograms = function () {
                return this.$http.get('/api/v2/care_programs');
            };
            CPEnrollmentService.prototype.getCPEnrollments = function (patientId) {
                return this.$http.get('/api/v2/care_program_enrollments/' + patientId);
            };
            CPEnrollmentService.prototype.addCPEnrollment = function (cpeObj) {
                return this.$http.post('/api/v2/care_program_enrollments', cpeObj);
            };
            CPEnrollmentService.prototype.updateCPEnrollment = function (cpeObj) {
                return this.$http.patch('/api/v2/care_program_enrollments', cpeObj);
            };
            CPEnrollmentService.prototype.deleteIndicator = function (cpeId) {
                return this.$http.delete('/api/v2/care_program_enrollments/' + cpeId);
            };
            CPEnrollmentService.$inject = ['$http'];
            return CPEnrollmentService;
        }());
        services.CPEnrollmentService = CPEnrollmentService;
        angular.module('consolo').service('CPEnrollmentService', CPEnrollmentService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CdsMedicationAllergiesService = /** @class */ (function () {
            function CdsMedicationAllergiesService($http) {
                this.$http = $http;
            }
            CdsMedicationAllergiesService.prototype.getCdsForMedicationAllergies = function (id) {
                return this.$http({
                    url: "/api/v1/clinical_decision_support_items/medication_allergies/" + id,
                    method: 'GET',
                });
            };
            CdsMedicationAllergiesService.$inject = ['$http'];
            return CdsMedicationAllergiesService;
        }());
        services.CdsMedicationAllergiesService = CdsMedicationAllergiesService;
        angular.module('consolo').service('cdsMedicationAllergiesService', CdsMedicationAllergiesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CdsPatientDemographicsService = /** @class */ (function () {
            function CdsPatientDemographicsService($http) {
                this.$http = $http;
            }
            CdsPatientDemographicsService.prototype.getCdsForPatientDemographics = function (id) {
                return this.$http({
                    url: "/api/v1/clinical_decision_support_items/patient_demographics/" + id,
                    method: 'GET',
                });
            };
            CdsPatientDemographicsService.$inject = ['$http'];
            return CdsPatientDemographicsService;
        }());
        services.CdsPatientDemographicsService = CdsPatientDemographicsService;
        angular.module('consolo').service('cdsPatientDemographicsService', CdsPatientDemographicsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CdsService = /** @class */ (function () {
            function CdsService($http) {
                this.$http = $http;
            }
            CdsService.prototype.getGlobalCdsTemplate = function (id) {
                return this.$http({
                    url: "/api/v1/clinical_decision_support_items/" + id,
                    method: 'GET',
                });
            };
            CdsService.$inject = ['$http'];
            return CdsService;
        }());
        services.CdsService = CdsService;
        angular.module('consolo').service('cdsService', CdsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CdsVitalSignsService = /** @class */ (function () {
            function CdsVitalSignsService($http) {
                this.$http = $http;
            }
            CdsVitalSignsService.prototype.getCdsForVitalSigns = function (id) {
                return this.$http({
                    url: "/api/v1/clinical_decision_support_items/vital_signs/" + id,
                    method: 'GET',
                });
            };
            CdsVitalSignsService.$inject = ['$http'];
            return CdsVitalSignsService;
        }());
        services.CdsVitalSignsService = CdsVitalSignsService;
        angular.module('consolo').service('cdsVitalSignsService', CdsVitalSignsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

/**
 * Checklist-model
 * AngularJS directive for list of checkboxes
 */
angular.module('checklist-model', []).directive('checklistModel', [
    '$parse',
    '$compile',
    function ($parse, $compile) {
        'use strict';
        // contains
        function contains(arr, item, comparator) {
            if (angular.isArray(arr)) {
                for (var i = arr.length; i--;) {
                    if (comparator(arr[i], item)) {
                        return true;
                    }
                }
            }
            return false;
        }
        // add
        function add(arr, item, comparator) {
            arr = angular.isArray(arr) ? arr : [];
            if (!contains(arr, item, comparator)) {
                arr.push(item);
            }
            return arr;
        }
        // remove
        function remove(arr, item, comparator) {
            if (angular.isArray(arr)) {
                for (var i = arr.length; i--;) {
                    if (comparator(arr[i], item)) {
                        arr.splice(i, 1);
                        break;
                    }
                }
            }
            return arr;
        }
        // http://stackoverflow.com/a/19228302/1458162
        function postLinkFn(scope, elem, attrs) {
            // compile with `ng-model` pointing to `checked`
            $compile(elem)(scope);
            // getter / setter for original model
            var getter = $parse(attrs.checklistModel);
            var setter = getter.assign;
            var checklistChange = $parse(attrs.checklistChange);
            // value added to list
            var value = $parse(attrs.checklistValue)(scope.$parent);
            var comparator = angular.equals;
            if (attrs.hasOwnProperty('checklistComparator')) {
                comparator = $parse(attrs.checklistComparator)(scope.$parent);
            }
            // watch UI checked change
            scope.$watch('checked', function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                var current = getter(scope.$parent);
                if (newValue === true) {
                    setter(scope.$parent, add(current, value, comparator));
                }
                else {
                    setter(scope.$parent, remove(current, value, comparator));
                }
                if (checklistChange) {
                    checklistChange(scope);
                }
            });
            // declare one function to be used for both $watch functions
            function setChecked(newArr) {
                scope.checked = contains(newArr, value, comparator);
            }
            // watch original model change
            // use the faster $watchCollection method if it's available
            if (angular.isFunction(scope.$parent.$watchCollection)) {
                scope.$parent.$watchCollection(attrs.checklistModel, setChecked);
            }
            else {
                scope.$parent.$watch(attrs.checklistModel, setChecked, true);
            }
        }
        return {
            restrict: 'A',
            priority: 1000,
            terminal: true,
            scope: true,
            compile: function (tElement, tAttrs) {
                //      if (tElement[0].tagName !== 'INPUT' || tAttrs.type !== 'checkbox') {
                //        throw 'checklist-model should be applied to `input[type="checkbox"]`.';
                //      }
                if (!tAttrs.checklistValue) {
                    throw 'You should provide `checklist-value`.';
                }
                // exclude recursion
                tElement.removeAttr('checklist-model');
                // local scope var storing individual checkbox model
                tElement.attr('ng-model', 'checked');
                return postLinkFn;
            },
        };
    },
]);

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ClinicalChartDraftsService = /** @class */ (function () {
            function ClinicalChartDraftsService($http) {
                this.$http = $http;
                this.draftEndpoint = '/api/v2/clinical_chart_drafts';
                this.validateEndpoint = '/api/v1/clinical_chart_drafts';
            }
            ClinicalChartDraftsService.prototype.getAllClinicalChartDrafts = function (patientId) {
                var query = {
                    patient_id: patientId
                };
                return this.$http.get(this.draftEndpoint, { params: query }).then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.getUnconvertedDrafts = function (patientId, hasAssociatedChart) {
                var query = {
                    patient_id: patientId,
                    has_associated_chart: 'false',
                };
                return this.$http.get(this.draftEndpoint, { params: query }).then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.getUnconvertedDraftsByUser = function (userId, hasAssociatedChart) {
                var query = {
                    user_id: userId,
                    has_associated_chart: 'false',
                };
                return this.$http.get(this.draftEndpoint, { params: query }).then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.getSingleClinicalChartDraft = function (draftId) {
                return this.$http.get(this.draftEndpoint + '/' + draftId).then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.validateClinicalChartDraft = function (draftId, draft) {
                return this.$http
                    .post(this.validateEndpoint + '/' + draftId + '/validate', draft)
                    .then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.createChartFromDraft = function (draftId) {
                return this.$http
                    .post(this.draftEndpoint + '/' + draftId + '/finalize', { draft_id: draftId })
                    .then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.updateClinicalChartDraft = function (chart) {
                var newChart = {
                    user_id: chart.user_id,
                    patient_id: chart.patient_id,
                    role_id: chart.role_id,
                    id: chart.temp_uuid,
                    data: chart,
                    effective_date: chart.effective_date
                };
                return this.$http.put(this.draftEndpoint, newChart).then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.prototype.deleteClinicalChartDraft = function (draftId) {
                return this.$http.delete(this.draftEndpoint + '/' + draftId).then(function (response) {
                    return response;
                });
            };
            ClinicalChartDraftsService.$inject = ['$http'];
            return ClinicalChartDraftsService;
        }());
        services.ClinicalChartDraftsService = ClinicalChartDraftsService;
        angular.module('consolo').service('ClinicalChartDraftsService', ClinicalChartDraftsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ClinicalTripsService = /** @class */ (function () {
            function ClinicalTripsService($http) {
                this.$http = $http;
            }
            ClinicalTripsService.prototype.getAllClinicalTrips = function () {
                return this.$http.get('/api/v1/clinical_trips').then(function (response) {
                    return response;
                });
            };
            ClinicalTripsService.prototype.getSingleClinicalTrip = function (tripId) {
                return this.$http.get('/api/v1/clinical_trips/' + tripId).then(function (response) {
                    return response;
                });
            };
            ClinicalTripsService.prototype.getClinicalTripBreadcrumbs = function (userId, date) {
                var query = {
                    user_id: userId,
                    start_date: date,
                    end_date: date,
                    resolution: 90,
                };
                return this.$http.get('/api/v1/breadcrumbs', { params: query }).then(function (response) {
                    return response;
                });
            };
            ClinicalTripsService.prototype.getCurrentClinicalTripBreadcrumbs = function (userIds, maxHours) {
                var query = {
                    max_hours: maxHours,
                };
                if (userIds instanceof Array) {
                    query['user_ids'] = userIds;
                }
                else {
                    query['user_id'] = userIds;
                }
                console.log('Query sent to server:');
                console.log(query);
                return this.$http.get('/api/v1/breadcrumbs/current', { params: query }).then(function (response) {
                    return response;
                });
            };
            ClinicalTripsService.$inject = ['$http'];
            return ClinicalTripsService;
        }());
        services.ClinicalTripsService = ClinicalTripsService;
        angular.module('consolo').service('clinicalTripsService', ClinicalTripsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        function timeOnly(d) {
            return this.addZero(d.getHours()).toString() + ':' + this.addZero(d.getMinutes()).toString();
        }
        common.timeOnly = timeOnly;
        function addZero(i) {
            if (i < 10) {
                i = '0' + i;
            }
            return i;
        }
        common.addZero = addZero;
        function dateOnly(datetime) {
            //Strip time from date
            datetime = new Date(datetime.toString());
            return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
        }
        common.dateOnly = dateOnly;
        function dateFormatMonthDayYear(date) {
            date = new Date(date);
            return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        }
        common.dateFormatMonthDayYear = dateFormatMonthDayYear;
        function dateStringFormat(date) {
            date = new Date(date);
            return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
        }
        common.dateStringFormat = dateStringFormat;
        function combineDateTime(date, time) {
            date = new Date(date.toString());
            time = new Date(time.toString());
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
        }
        common.combineDateTime = combineDateTime;
        function yesNo(value) {
            return !value ? 'No' : 'Yes';
        }
        common.yesNo = yesNo;
        function moneyDisplay(value) {
            if (value) {
                var roundedValue = Math.ceil(value * 1000) / 1000;
                return '$' + roundedValue.toFixed(2).toString();
            }
            else {
                return '';
            }
        }
        common.moneyDisplay = moneyDisplay;
        function isChecked(id, list) {
            return list.indexOf(id) > -1;
        }
        common.isChecked = isChecked;
        function toggle(id, list) {
            var idx = list.indexOf(id);
            if (idx > -1) {
                list.splice(idx, 1);
            }
            else {
                list.push(id);
            }
        }
        common.toggle = toggle;
        angular.module('consolo');
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', '$urlRouterProvider', Router]);
    function Router($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.when('', '/dashboard');
        $urlRouterProvider.when('/', '/dashboard');
        $urlRouterProvider.otherwise('/404');
        $stateProvider
            .state('release', {
            url: '/release',
            data: { hideSidebar: true },
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/common/release.html',
                    controller: 'ReleaseController as vm',
                },
            },
            public: true,
        })
            .state('unauthorized', {
            url: '/unauthorized',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/common/unauthorized.html',
                },
            },
            public: true,
        })
            .state('not-found', {
            url: '/404',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/common/404.html',
                },
            },
            public: true,
        })
            .state('server-error', {
            url: '/50x',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/common/50x.html',
                },
            },
            public: true,
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('ConfirmModalController', ['$mdDialog', 'model', 'confirmText', Controller]);
    function Controller($mdDialog, model, confirmText) {
        var vm = this;
        vm.confirmText = confirmText;
        vm.model = model;
        vm.closeDialog = function () {
            $mdDialog.hide();
        };
        vm.cancelDialog = function () {
            $mdDialog.cancel();
        };
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('ConfirmModal', ['$mdDialog', Factory]);
    function Factory($mdDialog) {
        return {
            show: show,
        };
        function show(model, templateUrl, event, confirmText) {
            return $mdDialog.show({
                targetEvent: event,
                templateUrl: templateUrl,
                locals: { model: model, confirmText: confirmText },
                controller: 'ConfirmModalController as vm',
            });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').directive('consoloDatetimePicker', ['$filter', DatetimePicker]);
    function DatetimePicker($filter) {
        return {
            restrict: 'E',
            replace: true,
            require: 'ngModel',
            scope: {
                dateOnly: '=',
                label: '@',
                readonly: '=',
                isRequired: '=',
                maxDate: '=',
                selected: '=ngModel',
                timeOnly: '=',
            },
            templateUrl: '/y/templates/common/consolo-datetime-picker.html',
            controller: Controller,
            controllerAs: 'vm',
            bindToController: true,
            link: link,
        };
        function link(scope, element, attrs, ngModel) {
            scope.vm.setDirty = function () {
                ngModel.$setDirty();
            };
            ngModel.$render = function () {
                if (ngModel.$modelValue) {
                    var tempValue;
                    if (ngModel.$modelValue.toString().length <= 10) {
                        tempValue = new Date(ngModel.$modelValue
                            .toString()
                            .replace(/-/g, '/')
                            .replace('T', ' '));
                    }
                    else {
                        tempValue = new Date(ngModel.$modelValue.toString());
                    }
                    scope.vm.datePart = tempValue;
                    scope.vm.timePart = $filter('date')(tempValue, 'HHmm');
                }
                else {
                    scope.vm.datePart = null;
                    scope.vm.timePart = null;
                }
            };
        }
    }
    function Controller() {
        var vm = this;
        vm.regex = /^([01]\d|2[0-3]):?([0-5]\d)$/;
        vm.modelChanged = modelChanged;
        vm.resetDate = resetDate;
        function modelChanged() {
            if (vm.timePart && !vm.datePart) {
                vm.datePart = new Date();
            }
            if (vm.dateOnly) {
                vm.selected = new Date(vm.datePart.toDateString());
            }
            else {
                if (vm.datePart && vm.timePart) {
                    vm.selected = new Date(vm.datePart.getFullYear(), vm.datePart.getMonth(), vm.datePart.getDate(), vm.timePart.substr(0, 2), vm.timePart.substr(2, 2));
                }
            }
        }
        function resetDate() {
            vm.selected = null;
            vm.setDirty();
        }
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').directive('copyToClipboard', function () {
            return {
                restrict: 'A',
                scope: { copyToClipboard: '&' },
                link: linkClipboard,
            };
        });
        function linkClipboard($scope, el) {
            var board = new Clipboard(el[0], { text: $scope.copyToClipboard });
            $scope.$on('$destroy', function () {
                board.destroy();
            });
        }
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var CSV = /** @class */ (function () {
            function CSV() {
            }
            CSV.prototype.generate = function (rows, headers) {
                var data = headers ? [headers].concat(rows) : rows;
                return data
                    .map(function (row) {
                    return row
                        .map(function (col) {
                        if (col === null || col === undefined) {
                            return '';
                        }
                        else {
                            var strCol = col
                                .toString()
                                .replace(/(\r?\n)+/gm, '')
                                .replace(/"/g, '""');
                            return strCol.match(/["',]/) ? '"' + strCol + '"' : strCol;
                        }
                    })
                        .join(',');
                })
                    .join('\n');
            };
            CSV.prototype.date = function (value) {
                if (value) {
                    return new Date(value).toISOString().slice(0, 10);
                }
            };
            CSV.prototype.yn = function (value) {
                if (value === true) {
                    return 'Yes';
                }
                else if (value === false) {
                    return 'No';
                }
            };
            CSV.$inject = [];
            return CSV;
        }());
        common.CSV = CSV;
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));
angular.module('consolo').service('CSV', consolo.common.CSV);

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CustomAlertService = /** @class */ (function () {
            function CustomAlertService($http, CurrentPatient, $mdDialog, logger) {
                this.$http = $http;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.today = new Date();
                this.url = '/api/v1/patient_alerts';
            }
            CustomAlertService.prototype.get = function (id) {
                return this.$http.get(this.url + '/' + id + '?visible=true');
            };
            CustomAlertService.prototype.create = function (customAlert) {
                // create a new custom alert with:
                // id: number
                // expiration_date: date
                // user_id: number
                // patient_id: number
                // role_id: number
                // message: string
                return this.$http.post(this.url, customAlert);
            };
            CustomAlertService.prototype.patch = function (customAlert) {
                return this.$http.patch(this.url + '/' + customAlert.id, customAlert);
            };
            CustomAlertService.prototype.delete = function (customAlert) {
                return this.$http.delete(this.url + '/' + customAlert.id);
            };
            CustomAlertService.prototype.loadByPatientId = function (patient_id, all_alerts) {
                return this.$http
                    .get(this.url, {
                    params: { patient_id: patient_id, all_alerts: all_alerts },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            CustomAlertService.prototype.setDataTableFields = function (alert) {
                alert.isExpired =
                    consolo.common.dateOnly(alert.expiration_date) < consolo.common.dateOnly(this.today);
                alert.created_by_name = alert.user.name;
                if (alert.message.length > 50) {
                    alert.synopsis = alert.message.substring(0, 47) + '...';
                }
                else {
                    alert.synopsis = alert.message;
                }
            };
            CustomAlertService.prototype.deleteCustomAlert = function ($event, item) {
                var _this = this;
                var alerts = this.CurrentPatient.patient.dashboard.patient_alerts;
                var logger = this.logger;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirm Alert Deletion')
                    .textContent('Are you sure you want to delete the custom alert ' + item.message + '?')
                    .ok('Delete')
                    .cancel('Keep Custom Alert');
                this.$mdDialog.show(confirm).then(function () {
                    _this.delete(item).then(function () {
                        alerts.splice(alerts.indexOf(item), 1);
                        logger.success('Deleted custom alert ' + item.message);
                    });
                }, function () {
                    logger.success('Kept custom alert ' + item.message);
                });
            };
            CustomAlertService.$inject = ['$http', 'CurrentPatient', '$mdDialog', 'logger'];
            return CustomAlertService;
        }());
        services.CustomAlertService = CustomAlertService;
        angular.module('consolo').service('CustomAlertService', CustomAlertService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('deferredResult', Factory);
    // Returns a deferred result with the promise stored in $promise, and the status in $resolved
    function Factory() {
        return deferredResult;
        function deferredResult(promise) {
            var deferred = {
                $resolved: false,
                $promise: promise.then(function (result) {
                    applyDeferredResult(result, deferred);
                    return deferred;
                }),
            };
            return deferred;
        }
        function applyDeferredResult(result, deferred) {
            for (var attr in result) {
                if (result.hasOwnProperty(attr)) {
                    deferred[attr] = result[attr];
                }
            }
            /* jshint ignore:start */
            if (typeof result.length !== 'undefined') {
                deferred.length = result.length;
                deferred.__proto__ = Array.prototype;
            }
            /* jshint ignore:end */
            deferred.$resolved = true;
        }
    }
})();

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var DiagnosticImageResultService = /** @class */ (function () {
            function DiagnosticImageResultService($http) {
                this.$http = $http;
                this.url = '/api/v1/diagnostic_image_results';
            }
            DiagnosticImageResultService.prototype.lookupDiagnosticImageResults = function (filter) {
                return this.$http.get(this.url, {
                    params: filter
                });
            };
            DiagnosticImageResultService.prototype.createDiagnosticImageResult = function (result) {
                return this.$http({
                    url: this.url,
                    method: 'POST',
                    data: result,
                });
            };
            DiagnosticImageResultService.prototype.updateDiagnosticImageResult = function (result) {
                return this.$http({
                    url: this.url + "/" + result.id,
                    method: 'PATCH',
                    data: result,
                });
            };
            DiagnosticImageResultService.prototype.deleteDiagnosticImageResult = function (result) {
                return this.$http({
                    url: this.url + "/" + result.id,
                    method: 'DELETE',
                });
            };
            DiagnosticImageResultService.$inject = ['$http'];
            return DiagnosticImageResultService;
        }());
        services.DiagnosticImageResultService = DiagnosticImageResultService;
        angular.module('consolo').service('DiagnosticImageResultService', DiagnosticImageResultService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var DocusignEnvelopeDataTableService = /** @class */ (function (_super) {
            __extends(DocusignEnvelopeDataTableService, _super);
            function DocusignEnvelopeDataTableService(pubSub, $httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.pubSub = pubSub;
                _this.$httpService = $httpService;
                _this.electronicDocuments = [];
                _this.extensionName = 'ElectronicDocuments';
                _this.apiSelectUrl = '/api/v1/electronic_documents/envelopes';
                return _this;
            }
            DocusignEnvelopeDataTableService.prototype.onSessionVisible = function (sess) {
                var _this = this;
                console.log('Calling onSessionVisible');
                this.pubSub.channel("emr:user:" + sess.user.uuid + ":agency:" + sess.agency.code).then(function (chan) {
                    _this.channel = chan;
                    chan.on('electronic_document', function (n) {
                        console.log('Found an electronic document update');
                        var documentId = _this.electronicDocuments
                            .map(function (e) {
                            return e.envelope_id;
                        })
                            .indexOf(n.envelope_id);
                        var previousDocument = _this.electronicDocuments[documentId];
                        _this.electronicDocuments[documentId] = n;
                        console.log('PubSub - electronic documents updated');
                        console.log(_this.electronicDocuments);
                        var loadAttempts = 0;
                        if (documentId !== -1 && previousDocument.status !== _this.electronicDocuments[documentId].status) {
                            var intervalId_1 = setInterval(function () {
                                loadAttempts++;
                                console.log("Refreshing document list after doc status change, attempt #" + loadAttempts + " / Doc ID: " + documentId);
                                console.log("Previous Document Status " + previousDocument.status +
                                    " / Current Document Status " + _this.electronicDocuments[documentId].status);
                                _this.loadEnvelopes();
                                if ((_this.electronicDocuments[documentId].patient_document_id != null) || loadAttempts >= 10) {
                                    clearInterval(intervalId_1);
                                }
                            }, 1000);
                        }
                    });
                });
            };
            DocusignEnvelopeDataTableService.prototype.onSessionClear = function () {
                this.electronicDocuments = [];
                if (this.channel) {
                    this.channel.leave();
                    this.channel = null;
                }
            };
            DocusignEnvelopeDataTableService.prototype.loadEnvelopes = function () {
                var _this = this;
                console.log('Running loadSelectData');
                return this.tableControl.loadSelectData().then(function (resp) {
                    _this.electronicDocuments = resp.data;
                    console.log('PubSub - electronic documents loaded');
                    console.log(_this.electronicDocuments);
                    return _this.electronicDocuments;
                });
            };
            DocusignEnvelopeDataTableService.$inject = ['PubSubService', '$http'];
            return DocusignEnvelopeDataTableService;
        }(csg.datatable.ApiService));
        services.DocusignEnvelopeDataTableService = DocusignEnvelopeDataTableService;
        angular
            .module('consolo')
            .service('docusignEnvelopeDataTableService', DocusignEnvelopeDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var DocusignTemplateDataTableService = /** @class */ (function (_super) {
            __extends(DocusignTemplateDataTableService, _super);
            function DocusignTemplateDataTableService($httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.apiSelectUrl = '/api/v1/electronic_documents/templates';
                return _this;
            }
            DocusignTemplateDataTableService.$inject = ['$http'];
            return DocusignTemplateDataTableService;
        }(csg.datatable.ApiService));
        services.DocusignTemplateDataTableService = DocusignTemplateDataTableService;
        angular
            .module('consolo')
            .service('docusignTemplateDataTableService', DocusignTemplateDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var DocusignService = /** @class */ (function () {
            function DocusignService($http) {
                this.$http = $http;
            }
            DocusignService.prototype.get_templates = function (patientId) {
                return this.$http({
                    url: "/api/v1/electronic_documents/templates",
                    method: 'GET',
                    params: { patientId: patientId },
                });
            };
            DocusignService.prototype.refresh_templates = function (patientId) {
                return this.$http({
                    url: "/api/v1/electronic_documents/templates/refresh",
                    method: 'POST',
                    params: { patient_id: patientId },
                });
            };
            DocusignService.prototype.get_template = function (patientId, id) {
                return this.$http({
                    url: "/api/v1/electronic_documents/templates/" + id,
                    method: 'GET',
                    params: { patientId: patientId },
                });
            };
            DocusignService.prototype.get_envelopes = function (filter) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes",
                    method: 'GET',
                    params: { filter: filter },
                });
            };
            DocusignService.prototype.get_envelope = function (patientId, id) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes/" + id,
                    method: 'GET',
                    params: { patient_id: patientId },
                });
            };
            DocusignService.prototype.get_envelope_templates = function (patientId, id) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes/" + id + "/templates",
                    method: 'GET',
                    params: { patient_id: patientId },
                });
            };
            DocusignService.prototype.create_envelope = function (templateOptions) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes",
                    method: 'POST',
                    data: templateOptions,
                });
            };
            DocusignService.prototype.get_envelope_url = function (envelope, signer_id) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes/" + envelope.envelope_id + "/signing_url",
                    method: 'GET',
                    data: envelope,
                    params: { patient_id: envelope.patient_id, signer_id: signer_id },
                });
            };
            DocusignService.prototype.resend_envelope = function (envelope) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes/" + envelope.envelope_id + "/resend",
                    method: 'POST',
                    data: envelope,
                });
            };
            DocusignService.prototype.delete_envelope = function (envelope) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes/" + envelope.envelope_id,
                    method: 'DELETE',
                    params: { patient_id: envelope.patient_id },
                });
            };
            DocusignService.prototype.void_envelope = function (envelope) {
                return this.$http({
                    url: "/api/v1/electronic_documents/envelopes/" + envelope.envelope_id + "/void",
                    method: 'PATCH',
                    params: { patient_id: envelope.patient_id },
                });
            };
            DocusignService.prototype.postPatientDemographics = function (patientId) {
                return this.$http.post('/api/v1/electronic_documents/patient_demographics', {
                    patient_id: patientId,
                });
            };
            DocusignService.$inject = ['$http'];
            return DocusignService;
        }());
        services.DocusignService = DocusignService;
        angular.module('consolo').service('docusignService', DocusignService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').directive('downloadFile', [
            '$timeout',
            function ($timeout) {
                return {
                    restrict: 'A',
                    scope: { downloadFile: '&', filename: '=', mime: '@' },
                    link: linkDownload,
                };
                function linkDownload($scope, el) {
                    var a = document.createElement('a');
                    a.style.setProperty('display', 'none');
                    a['download'] = $scope.filename;
                    document.body.appendChild(a);
                    $scope.$on('$destroy', function () {
                        a.remove();
                    });
                    el.on('click', function () {
                        var blob = new Blob([$scope.downloadFile()], { type: $scope.mime });
                        var url = URL.createObjectURL(blob);
                        a.href = url;
                        a.click();
                        $timeout(function () {
                            URL.revokeObjectURL(url);
                        }, 30 * 1000);
                    });
                }
            },
        ]);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var DraftSyncService = /** @class */ (function () {
            function DraftSyncService($rootScope, $q, $state, $filter, PersistedItem, PersistenceStore, $mdDialog, logger, NetworkManager, Session, Store, persistedItemConfig, DeviceSettings, ClinicalChartEntry, CarePlanProblemEntry, PhysicianOrderEntry, ChartingTemplateService, $http, ClinicalChartDraftsService, debugLog) {
                this.$rootScope = $rootScope;
                this.$q = $q;
                this.$state = $state;
                this.$filter = $filter;
                this.PersistedItem = PersistedItem;
                this.PersistenceStore = PersistenceStore;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.NetworkManager = NetworkManager;
                this.Session = Session;
                this.Store = Store;
                this.persistedItemConfig = persistedItemConfig;
                this.DeviceSettings = DeviceSettings;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.PhysicianOrderEntry = PhysicianOrderEntry;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$http = $http;
                this.ClinicalChartDraftsService = ClinicalChartDraftsService;
                this.debugLog = debugLog;
                this.settingsEnum = consolo.deviceSettings.Settings;
                this.extensionName = 'DraftSync';
                this.draftErrors = [];
                this.chartDraftErrors = [];
                this.autoSyncHandler = new consolo.domain.ConsoloEventHandler('autoSync');
                this.draftErrorCount = {};
            }
            DraftSyncService.prototype.onSessionReady = function (session) {
                var _this = this;
                console.info('Activating Draft Sync Service');
                this.getDraftErrors().then(function (e) {
                    if (e) {
                        _this.draftErrors = e;
                        _this.cleanDraftErrors();
                    }
                    _this.getAllDraftErrorCounts();
                });
                this.doAutoSync = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.global, this.settingsEnum.charting_sync_mode);
                this.NetworkManager.onlineHandler.onEvent('DraftSyncService', function () {
                    return _this.autoSync();
                });
                this.checkDrafts();
                this.autoSync();
            };
            DraftSyncService.prototype.checkDrafts = function () {
                var _this = this;
                var allDrafts = {};
                return this.persistedItemConfig.config().forEach(function (key) {
                    _this.getDrafts(key, 'checkDrafts').then(function (drafts) {
                        switch (key) {
                            case 'clinical_chart_entry':
                                _this.checkChartDrafts(drafts);
                                break;
                            default:
                                break;
                        }
                    });
                });
            };
            DraftSyncService.prototype.checkChartDrafts = function (drafts) {
                for (var _i = 0, drafts_1 = drafts; _i < drafts_1.length; _i++) {
                    var d = drafts_1[_i];
                    if (d.model) {
                        if (!d.model.charting_client) {
                            this.debugLog.warn('Invalid Draft, no charting client');
                            console.log('Invalid Draft: ', d);
                            // this.removeDraft('clinical_chart_entry', d);
                        }
                        var today = new Date().getTime();
                        var outDated = 2.592e+9; // 30 days in MS
                        if (today - d.timestamp > outDated) {
                            this.debugLog.warn('Device Draft created over 30 days ago, removing. Timestamp: ' +
                                new Date(d.timestamp));
                            this.removeDraft('clinical_chart_entry', d);
                        }
                    }
                    else {
                        this.debugLog.warn('Invalid Draft, no model');
                        console.log('Invalid Draft No Model: ', d);
                        this.removeDraft('clinical_chart_entry', d);
                    }
                }
            };
            DraftSyncService.prototype.removeDraft = function (type, item) {
                return this.PersistenceStore.remove(type, item);
            };
            DraftSyncService.prototype.getDrafts = function (type, caller) {
                var _this = this;
                var d = [];
                return this.PersistenceStore.forEach(type, function (item) {
                    if (!_this.$state.params.patientId ||
                        (item.model &&
                            item.model.patient_id &&
                            parseInt(_this.$state.params.patientId, 10) === item.model.patient_id)) {
                        d.push(item);
                    }
                }).then(function () {
                    return d;
                });
            };
            DraftSyncService.prototype.getDraftErrors = function () {
                return this.Store.local('app')
                    .getItem('draftErrors')
                    .then(function (data) {
                    return data;
                });
            };
            DraftSyncService.prototype.cleanDraftErrors = function () {
                var _this = this;
                var errorLength = this.draftErrors.length;
                var error;
                for (var i = errorLength - 1; i >= 0; i--) {
                    error = this.draftErrors[i];
                    var obj = error.id.toString().indexOf(':') > 0
                        ? { patient_id: error.id.split(':')[1] }
                        : { id: error.id };
                    try {
                        this.$q.when(this.PersistenceStore.get(error.type, obj)).catch(function () {
                            _this.removeDraftError(error.type, error.id);
                        });
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            };
            DraftSyncService.prototype.setDraftErrors = function () {
                var _this = this;
                return this.Store.local('app')
                    .setItem('draftErrors', this.draftErrors)
                    .then(function (data) {
                    _this.getAllDraftErrorCounts();
                    return data;
                });
            };
            DraftSyncService.prototype.removeDraftError = function (type, id) {
                this.draftErrors.splice(this.draftErrors.indexOf({ id: id, type: type }), 1);
                this.setDraftErrors();
            };
            DraftSyncService.prototype.getDraftErrorCount = function (type) {
                var count = 0;
                this.draftErrors.forEach(function (e) {
                    if (!type || type === e.type) {
                        count++;
                    }
                });
                return count;
            };
            DraftSyncService.prototype.getAllDraftErrorCounts = function () {
                var _this = this;
                this.persistedItemConfig.config().forEach(function (key) {
                    _this.draftErrorCount[key] = _this.getDraftErrorCount(key);
                });
            };
            DraftSyncService.prototype.draftErrorExists = function (type, id) {
                var exists = this.$filter('filter')(this.draftErrors, function (e) {
                    return e.type === type && e.id === id;
                });
                return exists.length > 0;
            };
            DraftSyncService.prototype.setSyncFlag = function (item) {
                item.model.syncFlag = true;
                item.saveDraft();
            };
            DraftSyncService.prototype.setSignatureFlag = function (item, type) {
                item.model.signatureFlag = true;
                item.saveDraft();
            };
            DraftSyncService.prototype.setModelFlag = function (item, flag, value) {
                item.model[flag] = value;
                item.saveDraft();
            };
            DraftSyncService.prototype.syncAllCharts = function () {
                var _this = this;
                var syncedCharts = [];
                var chain = [];
                this.draftErrors = [];
                return this.getDrafts('clinical_chart_entry').then(function (charts) {
                    charts.forEach(function (chart) {
                        if (chart.draft) {
                            chain.push(_this.syncChart(chart).then(function (c) {
                                if (!c.errorCount) {
                                    syncedCharts.push(chart);
                                }
                            }));
                        }
                    });
                    return _this.$q.all(chain).then(function () {
                        return syncedCharts;
                    });
                });
            };
            DraftSyncService.prototype.syncChart = function (chartEntry) {
                var _this = this;
                var chartStore;
                var p = this.$q.defer();
                var newEntry = !chartEntry.model.id;
                chartStore = this.$q.when(new this.PersistedItem('clinical_chart_entry', new this.ClinicalChartEntry(chartEntry.model)));
                chartStore.then(function (store) {
                    if (_this.NetworkManager.isOffline()) {
                        _this.setSyncFlag(store);
                        p.resolve(store);
                    }
                    else {
                        store.getWorkingCopy().then(function (copy) {
                            store.useCopy(copy);
                            var chart_id = chartEntry.model.id !== undefined
                                ? chartEntry.model.id
                                : 'NEW:' + chartEntry.model.patient_id;
                            _this.syncCarePlans(store).then(function () {
                                store.save().then(function (newModel) {
                                    _this.logger.success('Chart ' + newModel.id + ' Synced');
                                    chartEntry.draft = false;
                                    chartEntry.model = newModel;
                                    chartEntry.newChart = newEntry;
                                    chartEntry.errorCount = 0;
                                    store.model.errors = null;
                                    if (_this.draftErrorExists('clinical_chart_entry', chart_id)) {
                                        _this.removeDraftError('clinical_chart_entry', chart_id);
                                    }
                                    p.resolve(chartEntry);
                                }, function (errors) {
                                    _this.logger.error(errors.errors.toString(), "Chart Sync failed for " + chartEntry.model.patient.name);
                                    store.model.errors = errors.errors;
                                    chartEntry.errorCount = errors.errors.length;
                                    store.saveDraft();
                                    if (!_this.draftErrorExists('clinical_chart_entry', chart_id)) {
                                        _this.draftErrors.push({ type: 'clinical_chart_entry', id: chart_id });
                                        _this.setDraftErrors();
                                        _this.draftErrorCount['clinical_chart_entry'] = _this.getDraftErrorCount('clinical_chart_entry');
                                    }
                                    p.resolve(chartEntry); //Have to resolve because of $q.all, check for errors on chartEntry
                                });
                            });
                        });
                    }
                });
                return p.promise;
            };
            DraftSyncService.prototype.syncCarePlans = function (chartStore) {
                var _this = this;
                return this.PersistenceStore.count('care_plan_problem').then(function (count) {
                    if (count > 0) {
                        _this.logger.info('Saving offline care plan problems');
                        return _this.PersistenceStore.forEach('care_plan_problem', function (item) {
                            if (!item.model.invalidForm) {
                                return _this.processCpp(item, chartStore);
                            }
                        });
                    }
                });
            };
            DraftSyncService.prototype.processCpp = function (item, chartStore) {
                var _this = this;
                var cppResource = new this.CarePlanProblemEntry(item.model);
                var cppPi = new this.PersistedItem('care_plan_problem', cppResource);
                return cppPi.save().then(function (d) {
                    item.model.local = false;
                    //Attach the ID to any clinical charts in the persistenceStore that use it, update SectionManager & chartStore
                    if (!item.model.id) {
                        item.model.id = d.id;
                        var csItem = _this.$filter('filter')(chartStore.model.care_plan_problem_updates, function (b) {
                            return b.care_plan_problem_uuid === item.model.uuid;
                        })[0];
                        if (csItem) {
                            csItem.care_plan_problem_id = item.model.id;
                            //Now Interventions
                            var dataItem;
                            csItem.interventions.forEach(function (csItemIntervention) {
                                dataItem = _this.$filter('filter')(d.interventions, function (b) {
                                    return csItemIntervention.care_plan_problem_intervention_uuid === b.uuid;
                                })[0];
                                if (dataItem) {
                                    csItemIntervention.care_plan_problem_intervention_id = dataItem.id;
                                }
                            });
                        }
                        _this.PersistenceStore.forEach('clinical_chart_entry', function (cce) {
                            if (parseInt(cce.model.patient_id, 10) === parseInt(item.model.patient_id, 10)) {
                                cce.model.care_plan_problem_updates.forEach(function (cpp) {
                                    if (cpp.care_plan_problem_uuid === item.model.uuid) {
                                        cpp.care_plan_problem_id = item.model.id;
                                        cpp.interventions.forEach(function (cppIntervention) {
                                            var dataIntervention = _this.$filter('filter')(d.interventions, function (b) {
                                                return cppIntervention.care_plan_problem_intervention_uuid === b.uuid;
                                            })[0];
                                            if (dataIntervention) {
                                                cppIntervention.id = dataIntervention.id;
                                            }
                                        });
                                        return _this.PersistenceStore.set('clinical_chart_entry', cce.model, cce.draft);
                                    }
                                });
                            }
                        });
                    }
                });
            };
            DraftSyncService.prototype.syncPhysicianOrder = function (order) {
                var _this = this;
                var orderStore;
                var p = this.$q.defer();
                var newEntry = !order.model.id;
                orderStore = this.$q.when(new this.PersistedItem('physician_order_entry', new this.PhysicianOrderEntry(order.model)));
                return orderStore.then(function (store) {
                    if (store.model.syncSplitOrder) {
                        return store.model.$splitOutOrder({}).then(function (data) {
                            var ids = [];
                            data.orders.forEach(function (po) {
                                if (po.physician_order) {
                                    ids.push(po.physician_order.id);
                                }
                                else {
                                    ids.push(po.id);
                                }
                            });
                            _this.logger.success('Physician Order split into: ' + ids.join(', '));
                        });
                    }
                    else {
                        return store.save().then(function (newModel) {
                            _this.logger.success('Physician Order: ' + newModel.id + ' Synced');
                        });
                    }
                });
            };
            DraftSyncService.prototype.autoSync = function () {
                var _this = this;
                var chain = [];
                chain.push(this.autoSyncPhysicianOrders());
                chain.push(this.autoSyncCharts());
                this.$q.all(chain).then(function () {
                    _this.autoSyncHandler.eventFired();
                });
            };
            DraftSyncService.prototype.autoSyncPhysicianOrders = function () {
                var _this = this;
                if (!this.doAutoSync) {
                    return this.$q.resolve();
                }
                var chain = [], numOrders = 0;
                return this.getDrafts('physician_order_entry').then(function (po) {
                    po.forEach(function (po) {
                        if (po.model.syncFlag) {
                            numOrders++;
                            chain.push(_this.syncPhysicianOrder(po));
                        }
                    });
                    return _this.$q.all(chain).then(function () {
                        if (numOrders) {
                            _this.logger.success('Physician Orders synced');
                            _this.$rootScope.$broadcast('PhysicianOrdersSynced');
                        }
                    });
                });
            };
            DraftSyncService.prototype.autoSyncCharts = function () {
                var _this = this;
                //sync all drafts that have not been sync'd to the server
                return this.getDrafts('clinical_chart_entry').then(function (charts) {
                    var chartItemChain = [];
                    //create persisteditems of all the chart drafts that haven't been synced to the server
                    charts.forEach(function (chart) {
                        if (!chart.model.server) {
                            var chartItem = _this.$q.when(new _this.PersistedItem('clinical_chart_entry', new _this.ClinicalChartEntry(chart.model)));
                            chartItemChain.push(chartItem);
                        }
                    });
                    return _this.$q.all(chartItemChain).then(function (chartItems) {
                        var draftSyncChain = [];
                        for (var _i = 0, chartItems_1 = chartItems; _i < chartItems_1.length; _i++) {
                            var c = chartItems_1[_i];
                            draftSyncChain.push(c.saveDraftOnServer());
                        }
                        _this.$q.all(draftSyncChain).then(function (syncedDrafts) {
                            if (_this.doAutoSync) {
                                var chain = [];
                                var syncedCharts = [];
                                var signatureFlag = false;
                                charts.forEach(function (chart) {
                                    if (chart.model.syncFlag) {
                                        signatureFlag = chart.model.signatureFlag;
                                        if (!chart.model.errorCount) {
                                            chain.push(_this.syncChart(chart).then(function (c) {
                                                if (!c.errorCount) {
                                                    if (signatureFlag) {
                                                        var chartCopy = angular.copy(chart);
                                                        chain.push(_this.$http
                                                            .post('/api/v1/clinical_charts/' + chartCopy.model.id + '/signatures', {})
                                                            .then(function () {
                                                            _this.logger.success('Chart ' + chart.model.id + ' Signed');
                                                        }));
                                                    }
                                                    syncedCharts.push(chart);
                                                }
                                                else {
                                                    _this.logger.error(chart.model.errors.toString(), "Auto Chart Sync failed for " + chart.model.patient.name);
                                                }
                                            }));
                                        }
                                        else {
                                            _this.logger.error(chart.model.errors.toString(), "Auto Chart Sync failed for " + chart.model.patient.name);
                                        }
                                    }
                                });
                                return _this.$q.all(chain).then(function () {
                                    return syncedCharts;
                                });
                            }
                            else {
                                return _this.$q.resolve();
                            }
                        });
                    });
                });
            };
            DraftSyncService.$inject = [
                '$rootScope',
                '$q',
                '$state',
                '$filter',
                'PersistedItem',
                'persistenceStore',
                '$mdDialog',
                'logger',
                'NetworkManager',
                'SessionService',
                'Store',
                'persistedItemConfig',
                'DeviceSettingsService',
                'ClinicalChartEntry',
                'CarePlanProblemEntry',
                'PhysicianOrderEntry',
                'ChartingTemplateService',
                '$http',
                'ClinicalChartDraftsService',
                'DebugLogService',
            ];
            return DraftSyncService;
        }());
        services.DraftSyncService = DraftSyncService;
        angular.module('consolo').service('DraftSyncService', DraftSyncService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var EmbeddedHelper = /** @class */ (function () {
            function EmbeddedHelper($location, tokenService, sessionTimer) {
                this.$location = $location;
                this.tokenService = tokenService;
                this.sessionTimer = sessionTimer;
                this.isEmbedded = false;
                this.isAccessToken = false;
                this.skipCache = true;
            }
            EmbeddedHelper.prototype.initialize = function () {
                this.pullAuthFromUrl();
                this.pullEmbeddedFromUrl();
                this.isEmbedded = sessionStorage.getItem('embedded') === 'true';
                this.isAccessToken = sessionStorage.getItem('is_token') === 'true';
                this.skipCache = this.isAccessToken; // || this.isEmbedded
            };
            EmbeddedHelper.prototype.pullAuthFromUrl = function () {
                if (this.$location.search().access_token) {
                    sessionStorage.setItem('is_token', 'true');
                    this.tokenService.setToken(this.$location.search().access_token);
                    this.sessionTimer.set();
                }
            };
            EmbeddedHelper.prototype.pullEmbeddedFromUrl = function () {
                if (this.$location.search().embedded === 'true') {
                    sessionStorage.setItem('embedded', 'true');
                }
            };
            EmbeddedHelper.$inject = ['$location', 'AccessTokenService', 'SessionTimerService'];
            return EmbeddedHelper;
        }());
        common.EmbeddedHelper = EmbeddedHelper;
        angular.module('consolo').service('EmbeddedHelper', EmbeddedHelper);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('errors', function () {
        return {
            restrict: 'E',
            scope: { errors: '=source', message: '@' },
            template: '<div ng-if="errors && errors.length > 0 == true" class="content-panel alert-panel">' +
                '<strong ng-show="!!message">{{message}}</strong>' +
                '<ul><li ng-repeat="error in errors track by $index">{{error}}</li></ul></div>',
        };
    });
})();

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var ConsoloEventHandler = /** @class */ (function () {
            function ConsoloEventHandler(name) {
                var _this = this;
                this.onEvent = function (controller, fn) {
                    //Controllers aren't singletons, remove the existing handler and re-add
                    for (var idx = 0; idx < _this.handlers.length; idx++) {
                        if (_this.handlers[idx].fn.toString() === fn.toString() &&
                            _this.handlers[idx].controller === controller) {
                            _this.handlers.splice(idx, 1);
                        }
                    }
                    _this.handlers.push({ controller: controller, fn: fn });
                };
                this.eventFired = function () {
                    _this.handlers.forEach(function (h) {
                        h.fn();
                    });
                };
                this.name = name;
                this.handlers = new Array();
            }
            return ConsoloEventHandler;
        }());
        domain.ConsoloEventHandler = ConsoloEventHandler;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('focus', ['$timeout', '$parse', Directive]);
    // http://stackoverflow.com/questions/14833326/how-to-set-focus-on-input-field
    function Directive($timeout, $parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var model = $parse(attrs.focus);
                scope.$watch(model, function (value) {
                    if (value === true) {
                        $timeout(function () {
                            element[0].focus();
                        });
                    }
                });
                element.bind('blur', function () {
                    model.assign(scope, false);
                });
            },
        };
    }
})();

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloGoogleMapsButton = /** @class */ (function () {
            function ConsoloGoogleMapsButton() {
                this.bindings = {
                    address: '<',
                };
                this.templateUrl = '/y/templates/common/google-maps-button.html';
            }
            return ConsoloGoogleMapsButton;
        }());
        angular
            .module('consolo')
            .component('consoloGoogleMapsButton', new ConsoloGoogleMapsButton());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var HijackFindService = /** @class */ (function () {
            function HijackFindService($window) {
                this.$window = $window;
            }
            HijackFindService.prototype.register = function (scrollToSelector, focusOnSelector) {
                if (this.handler) {
                    throw new Error('Another element is already registered!');
                }
                this.handler = this.generateHandler(scrollToSelector, focusOnSelector);
                this.$window.addEventListener('keydown', this.handler);
            };
            HijackFindService.prototype.unregister = function () {
                this.$window.removeEventListener('keydown', this.handler);
                this.handler = null;
            };
            HijackFindService.prototype.generateHandler = function (scrollToSelector, focusOnSelector) {
                return function (e) {
                    if (e.keyCode === 70 && (e.ctrlKey || e.metaKey)) {
                        var scrollTo_1 = document.querySelector(scrollToSelector);
                        if (!scrollTo_1) {
                            throw new Error('scrollToSelector not found!');
                        }
                        var focusOn = document.querySelector(focusOnSelector);
                        if (!focusOn) {
                            throw new Error('focusOnSelector not found!');
                        }
                        e.preventDefault();
                        scrollTo_1.scrollIntoView();
                        focusOn.focus();
                        focusOn.select();
                    }
                };
            };
            HijackFindService.$inject = ['$window'];
            return HijackFindService;
        }());
        services.HijackFindService = HijackFindService;
        angular.module('consolo').service('hijackFindService', HijackFindService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var Hosts = /** @class */ (function () {
            function Hosts($location) {
                this.$location = $location;
                this.env = this.getEnv();
                if (this.env === 'development') {
                    this.client = $location.protocol() + "://" + $location.host() + ":" + $location.port();
                    this.api = this.client;
                }
                else {
                    this.client = $location.protocol() + "://" + $location.host();
                    this.api = this.client;
                }
            }
            Hosts.prototype.getEnv = function () {
                var domains = this.$location.host().split('.');
                if (domains.length === 3 && domains[1] !== 'docker') {
                    return 'production';
                }
                else if (['localhost', '192', '172', '10', 'acme', 'host'].indexOf(domains[0]) > -1) {
                    return 'development';
                }
                else {
                    return domains[1];
                }
            };
            Hosts.$inject = ['$location'];
            return Hosts;
        }());
        common.Hosts = Hosts;
        angular.module('consolo').service('Hosts', Hosts);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var HumanResourcesDashboardExpiredDataTableService = /** @class */ (function (_super) {
            __extends(HumanResourcesDashboardExpiredDataTableService, _super);
            function HumanResourcesDashboardExpiredDataTableService($http) {
                var _this = _super.call(this, $http) || this;
                _this.apiSelectUrl = '/api/v1/dashboard/human_resource?expired_flag=expired';
                return _this;
            }
            HumanResourcesDashboardExpiredDataTableService.$inject = ['$http'];
            return HumanResourcesDashboardExpiredDataTableService;
        }(csg.datatable.ApiService));
        services.HumanResourcesDashboardExpiredDataTableService = HumanResourcesDashboardExpiredDataTableService;
        var HumanResourcesDashboardExpiringDataTableService = /** @class */ (function (_super) {
            __extends(HumanResourcesDashboardExpiringDataTableService, _super);
            function HumanResourcesDashboardExpiringDataTableService($http) {
                var _this = _super.call(this, $http) || this;
                _this.apiSelectUrl = '/api/v1/dashboard/human_resource?expired_flag=expiring';
                return _this;
            }
            HumanResourcesDashboardExpiringDataTableService.$inject = ['$http'];
            return HumanResourcesDashboardExpiringDataTableService;
        }(csg.datatable.ApiService));
        services.HumanResourcesDashboardExpiringDataTableService = HumanResourcesDashboardExpiringDataTableService;
        angular
            .module('consolo')
            .service('humanResourcesDashboardExpiredDataTableService', HumanResourcesDashboardExpiredDataTableService)
            .service('humanResourcesDashboardExpiringDataTableService', HumanResourcesDashboardExpiringDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('errSrc', function () {
        return {
            link: function (scope, element, attrs) {
                element.bind('error', function () {
                    if (attrs.src !== attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });
            },
        };
    });
})();

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ImplantableDeviceService = /** @class */ (function () {
            function ImplantableDeviceService($http) {
                this.$http = $http;
                this.url = '/api/v2/devices';
            }
            ImplantableDeviceService.prototype.lookupImplantableDevice = function (params) {
                return this.$http.get(this.url + "/lookup", { params: params });
            };
            ImplantableDeviceService.prototype.lookupImplantableDeviceSnomed = function (params) {
                return this.$http.get(this.url + "/snomed", { params: params });
            };
            ImplantableDeviceService.prototype.getAllImplantableDevices = function (patientId, includeInactive) {
                return this.$http.get(this.url, {
                    params: {
                        patient_id: patientId,
                        include_inactive: includeInactive
                    }
                });
            };
            ImplantableDeviceService.prototype.createImplantableDevice = function (deviceObj) {
                return this.$http.post(this.url, deviceObj);
            };
            ImplantableDeviceService.prototype.updateImplantableDevice = function (deviceObj) {
                return this.$http.patch(this.url + "/" + deviceObj.id, deviceObj);
            };
            ImplantableDeviceService.prototype.deleteImplantableDevice = function (deviceId) {
                return this.$http.delete(this.url + "/" + deviceId);
            };
            ImplantableDeviceService.prototype.restoreImplantableDevice = function (deviceId) {
                return this.$http.patch(this.url + "/" + deviceId + "/restore", null);
            };
            ImplantableDeviceService.$inject = ['$http'];
            return ImplantableDeviceService;
        }());
        services.ImplantableDeviceService = ImplantableDeviceService;
        angular.module('consolo').service('ImplantableDeviceService', ImplantableDeviceService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var IndicatorsService = /** @class */ (function () {
            function IndicatorsService($http, options) {
                this.$http = $http;
                this.options = options;
                this.baseRoles = options.get('base_roles');
            }
            IndicatorsService.prototype.getIndicators = function (patientId) {
                return this.$http.get('/api/v1/indicators/?patient_id=' + patientId);
            };
            IndicatorsService.prototype.getSingleIndicator = function (indicatorId, patientId) {
                return this.$http.get('/api/v1/indicators/' + indicatorId + '?patient_id=' + patientId);
            };
            IndicatorsService.prototype.addIndicator = function (patientId, indicatorObj) {
                return this.$http.post('/api/v1/indicators' + '?patient_id=' + patientId, indicatorObj);
            };
            IndicatorsService.prototype.updateIndicator = function (indicatorId, patientId, indicatorObj) {
                return this.$http.patch('/api/v1/indicators/' + indicatorId + '?patient_id=' + patientId, indicatorObj);
            };
            IndicatorsService.prototype.getCurrentIndicator = function (patientId) {
                return this.$http
                    .get('/api/v1/indicators/current', { params: { patient_id: patientId } })
                    .then(function (response) {
                    return response.data;
                });
            };
            IndicatorsService.prototype.getIndicatorBeforeOrOn = function (patientId, effectiveDate) {
                return this.$http
                    .get('/api/v1/indicators/before_or_on', {
                    params: { patient_id: patientId, effective_date: effectiveDate },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            IndicatorsService.prototype.getIndicatorIcds = function (indicatorId, patientId) {
                return this.$http.get('/api/v1/indicators/' + indicatorId + '/icd10s?patient_id=' + patientId);
            };
            IndicatorsService.prototype.deleteIndicator = function (indicatorId, patientId) {
                return this.$http.delete('/api/v1/indicators/' + indicatorId + '?patient_id=' + patientId);
            };
            IndicatorsService.prototype.getIcdTabularSearch = function () {
                return this.$http.get('/api/v1/icds/icd10_chapters');
            };
            IndicatorsService.prototype.getIcdTabularSearchSection = function (chapterId) {
                return this.$http.get('/api/v1/icds/icd10_sections?icd10_chapter_id=' + chapterId);
            };
            IndicatorsService.prototype.getIcdTabularSearchIcd = function (effectiveDate, sectionId, chapterId) {
                return this.$http.get('/api/v1/icds/icd10s?effective_date=' +
                    effectiveDate +
                    '&icd10_chapter_id=' +
                    chapterId +
                    '&icd10_section_id=' +
                    sectionId);
            };
            IndicatorsService.prototype.getIcdTabularSearchNestedIcd = function (effectiveDate, chapterId, icd10Id) {
                return this.$http.get('/api/v1/icds/icd10s?effective_date=' +
                    effectiveDate +
                    '&icd10_chapter_id=' +
                    chapterId +
                    '&icd10_id=' +
                    icd10Id);
            };
            IndicatorsService.prototype.lookupIcd10 = function (query, effective_date) {
                if (!effective_date) {
                    return this.$http.get('/api/v1/icds/icd10s?q=' + query).then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http
                        .get('/api/v1/icds/icd10s?q=' + query + '&effective_date=' + effective_date)
                        .then(function (response) {
                        return response.data;
                    });
                }
            };
            IndicatorsService.prototype.lookupIcd9 = function (query, effective_date) {
                return this.$http.get('/api/v1/lookup/icd9s?q=' + query + '&effective_date=' + effective_date);
            };
            IndicatorsService.prototype.getDiagnosisTemplates = function () {
                return this.$http.get('/api/v1/diagnosis_templates');
            };
            IndicatorsService.prototype.addLCDWorksheet = function (patientId, indicatorId, lcdWorksheetObj) {
                return this.$http.post('/api/v1/diagnoses', lcdWorksheetObj);
            };
            IndicatorsService.prototype.addCurrentUserSignature = function (indicatorId, patientId, signatureObj) {
                return this.$http.post('/api/v1/indicators/' + indicatorId + '/signatures' + '?patient_id=' + patientId, signatureObj);
            };
            IndicatorsService.prototype.addPhysicianSignature = function (indicatorId, patientId, signatureObj) {
                return this.$http.post('/api/v1/indicators/' + indicatorId + '/physician_signature' + '?patient_id=' + patientId, signatureObj, signatureObj);
            };
            IndicatorsService.prototype.createIndicatorPDF = function (indicatorId) {
                var params = {
                    indicators: [indicatorId],
                    pdf_options: {
                        field_use: 1,
                    },
                };
                return this.$http.post('/api/v1/pdf', params);
            };
            IndicatorsService.$inject = ['$http', 'options'];
            return IndicatorsService;
        }());
        services.IndicatorsService = IndicatorsService;
        angular.module('consolo').service('IndicatorsService', IndicatorsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloInfoPanel = /** @class */ (function () {
            function ConsoloInfoPanel() {
                this.bindings = {
                    panelType: '<',
                    header: '<',
                    messages: '<',
                    customIcon: '<',
                };
                this.templateUrl = '/y/templates/common/info-panel.html';
                this.template = "";
                this.controller = ConsoloInfoPanelController;
            }
            return ConsoloInfoPanel;
        }());
        var ConsoloInfoPanelController = /** @class */ (function () {
            function ConsoloInfoPanelController() {
                // textStyle: any;
                // panelStyle: any;
                this.icons = {
                    warning: 'alert:warning',
                    success: 'action:done',
                    info: 'action:info',
                };
            }
            ConsoloInfoPanelController.prototype.$onInit = function () { };
            ConsoloInfoPanelController.prototype.$onChanges = function () {
                switch (this.panelType) {
                    case 'info':
                    case 'success':
                    case 'warning':
                        break;
                    default:
                        this.panelType = 'info';
                        break;
                }
                if (this.customIcon) {
                    this.panelIcon = this.customIcon;
                }
                else {
                    this.panelIcon = this.icons[this.panelType];
                }
            };
            // panelStyles = {
            //   warning: {
            //     background: '#F44336',
            //     border: '1px solid #F44336',
            //   },
            // };
            // textStyles = {
            //   warning: {
            //     background: '#FFEBEE',
            //   },
            // };
            ConsoloInfoPanelController.$inject = [];
            return ConsoloInfoPanelController;
        }());
        angular.module('consolo').component('consoloInfoPanel', new ConsoloInfoPanel());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('loadingSpinner', {
            bindings: {
                size: '@',
                centered: '<',
            },
            template: "\n    <md-progress-circular ng-if=\"::$ctrl.diameter\"\n      md-mode=\"indeterminate\"\n      md-diameter=\"{{$ctrl.diameter}}\"\n      ng-class=\"{ 'absolute-centered': $ctrl.centered}\"\n      class=\"consolo-spinner\">\n    </md-progress-circular>\n    ",
            controller: function () {
                var sizePixels;
                switch (this.size) {
                    case 'lg':
                        this.sizePixels = '96x96';
                        this.diameter = 96;
                        break;
                    case 'xlg':
                        this.sizePixels = '194x194';
                        this.diameter = 194;
                        break;
                    default:
                        this.diameter = 32;
                        this.sizePixels = '32x32';
                }
            },
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var Logger = /** @class */ (function () {
            function Logger(toastr, $mdToast, Store, debugLog) {
                this.toastr = toastr;
                this.$mdToast = $mdToast;
                this.Store = Store;
                this.debugLog = debugLog;
                //TODO: Handle these via manager
                this.initilizationToasts = {
                    '4_15_14_drafts': {
                        title: 'Consolo Web Client Update Complete',
                        text: 'Your Consolo Web Client has been successfully updated.  You may once again save Clinical Chart Drafts',
                        type: 'success',
                        end_date: '06/13/2017',
                    },
                };
            }
            Logger.prototype.success = function (text, title) {
                if (title === void 0) { title = 'Success'; }
                this.toastr.success(text, title);
                this.debugLog.info(title + ": " + text);
            };
            Logger.prototype.info = function (text, title) {
                if (title === void 0) { title = 'Info'; }
                this.toastr.info(text, title);
                this.debugLog.info(title + ": " + text);
            };
            Logger.prototype.error = function (text, title) {
                if (title === void 0) { title = 'Error'; }
                this.toastr.error(text, title);
                this.debugLog.warn(title + ": " + text);
            };
            Logger.prototype.errorResponse = function (response, title) {
                if (title === void 0) { title = 'Error'; }
                var text = 'Unknown error';
                if (response.data.errors) {
                    text = response.data.errors[0];
                }
                this.toastr.error(text, title);
            };
            Logger.prototype.actionToast = function (text, actionText, actionFnName, controller, toastType, closeText) {
                if (toastType === void 0) { toastType = 'info'; }
                if (closeText === void 0) { closeText = 'IGNORE'; }
                var options = {
                    template: "'<md-toast class=\"toast-" + toastType + "\">\n            <span class=\"md-toast-text\" flex>" + text + "</span>\n            <md-button ng-click=\"toast." + actionFnName + "($event)\">" + actionText + "</md-button>\n            <md-button ng-click=\"toast.$mdToast.hide()\">" + closeText + "</md-button>\n          </md-toast>'",
                    // highlightAction: true,
                    // highlightClass: 'md-accent',
                    position: 'bottom right',
                    hideDelay: 0,
                    controller: controller + ' as toast',
                };
                this.$mdToast.show(options);
            };
            Logger.prototype.persistentToast = function (text, title, type, key) {
                var _this = this;
                if (type === void 0) { type = 'success'; }
                var config = {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    closeButton: true,
                    closeHtml: '<button>&times;</button>',
                    tapToDismiss: false,
                    progressBar: false,
                    allowHtml: false,
                    onHidden: function (clicked, toast) { return _this.toastHiddenCallback(clicked, toast, key); },
                    onShown: null,
                    onTapped: null,
                    callbackKey: key,
                };
                switch (type) {
                    case 'info':
                        this.toastr.info(text, title, config);
                        break;
                    case 'error':
                        this.toastr.error(text, title, config);
                        break;
                    default:
                        this.toastr.success(text, title, config);
                }
            };
            Logger.prototype.toastHiddenCallback = function (clicked, toast, key) {
                if (clicked && key) {
                    var seenToasts = this.Store.sync().getItem('settings_toasts') || {};
                    seenToasts[key] = true;
                    this.Store.sync().setItem('settings_toasts', seenToasts);
                }
            };
            Logger.prototype.initToasts = function () {
                var _this = this;
                var seenToasts = this.Store.sync().getItem('settings_toasts') || {};
                var today = new Date();
                Object.keys(this.initilizationToasts).forEach(function (key) {
                    var seen = seenToasts[key];
                    var obj = _this.initilizationToasts[key];
                    var end = obj.end_date ? new Date(obj.end_date) : new Date('01/01/2031');
                    if (!seen && end.getTime() >= today.getTime()) {
                        _this.persistentToast(obj.text, obj.title, obj.type, key);
                    }
                });
            };
            Logger.$inject = ['toastr', '$mdToast', 'Store', 'DebugLogService'];
            return Logger;
        }());
        common.Logger = Logger;
        angular.module('consolo').service('logger', Logger);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MissedVisitService = /** @class */ (function () {
            function MissedVisitService($http, CurrentPatient, $mdDialog, logger, PatientTimeService) {
                this.$http = $http;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.PatientTimeService = PatientTimeService;
                this.today = new Date();
                this.patient = CurrentPatient.patient;
                this.url = '/api/v1/missed_visits';
            }
            MissedVisitService.prototype.get = function (id) {
                return this.$http.get(this.url + '/' + id);
            };
            MissedVisitService.prototype.create = function (missedVisit) {
                this.setPatientTimes(missedVisit);
                return this.$http.post(this.url, missedVisit);
            };
            MissedVisitService.prototype.update = function (missedVisit) {
                this.setPatientTimes(missedVisit);
                return this.$http.put(this.url + '/' + missedVisit.id, missedVisit);
            };
            MissedVisitService.prototype.delete = function (missedVisit) {
                return this.$http.delete(this.url + '/' + missedVisit.id);
            };
            MissedVisitService.prototype.sign = function (missedVisit) {
                return this.$http.post(this.url + '/' + missedVisit.id + '/signatures', {});
            };
            MissedVisitService.prototype.loadByPatientId = function (patient_id) {
                return this.$http.get(this.url + '?patient_id=' + patient_id);
            };
            MissedVisitService.prototype.deleteMissedVisit = function ($event, item) {
                var _this = this;
                if (this.patient.misc_data) {
                    var missed_visits = this.patient.misc_data.missed_visits;
                }
                var logger = this.logger;
                var confirm = this.$mdDialog.confirm({
                    title: 'Confirm Missed Visit Deletion',
                    textContent: 'Are you sure you want to delete this missed visit?',
                    ok: 'Delete',
                    cancel: 'Keep Missed Visit',
                });
                this.$mdDialog.show(confirm).then(function () {
                    _this.delete(item).then(function () {
                        var index = missed_visits.indexOf(item);
                        missed_visits.splice(index, 1);
                        logger.success('Deleted missed visit.');
                    });
                }, function () {
                    logger.success('Kept missed visit.');
                });
            };
            MissedVisitService.prototype.addUserSignature = function ($event, item) {
                var _this = this;
                if (this.patient.misc_data) {
                    var missed_visits = this.patient.misc_data.missed_visits;
                }
                var logger = this.logger;
                var confirm = this.$mdDialog.confirm({
                    title: 'Sign Missed Visit',
                    textContent: 'Are you sure you want to sign this missed visit? Once signed, the record cannot be edited.',
                    ok: 'Sign',
                    cancel: 'Cancel',
                });
                this.$mdDialog.show(confirm).then(function () {
                    _this.sign(item).then(function (signature) {
                        var index = missed_visits.indexOf(item);
                        missed_visits[index].signatures.push(signature.data); // triggers conditionalHideFn
                        _this.patient.misc_data.missed_visits[index].data_table_signatures = _this.formatSignatures(missed_visits[index].signatures);
                        logger.success('Signed missed visit.');
                    });
                });
            };
            MissedVisitService.prototype.setDataTableFields = function (missed_visit) {
                missed_visit.created_by_name = missed_visit.user.name;
                if (missed_visit.patient_time) {
                    // subtable 1
                    missed_visit.patient_time_in_date = consolo.common.dateOnly(new Date(missed_visit.patient_time.in_datetime));
                    missed_visit.patient_time_in = consolo.common.timeOnly(new Date(missed_visit.patient_time.in_datetime));
                    missed_visit.patient_time_out = consolo.common.timeOnly(new Date(missed_visit.patient_time.out_datetime));
                    // subtable 2
                    if (missed_visit.patient_time.travel_start_datetime) {
                        missed_visit.patient_time_travel_start = consolo.common.timeOnly(new Date(missed_visit.patient_time.travel_start_datetime));
                    }
                    if (missed_visit.patient_time.travel_stop_datetime) {
                        missed_visit.patient_time_travel_stop = consolo.common.timeOnly(new Date(missed_visit.patient_time.travel_stop_datetime));
                    }
                    missed_visit.patient_time_clinical_care_type = missed_visit.patient_time.clinical_care_type;
                    // subtable 3
                    missed_visit.patient_time_mileage = missed_visit.patient_time.mileage.toString();
                    missed_visit.patient_time_out_of_pocket = missed_visit.patient_time.out_of_pocket.toString();
                    missed_visit.patient_time_comments = missed_visit.patient_time.comments;
                    // subtable 4
                    missed_visit.data_table_signatures = this.formatSignatures(missed_visit.signatures);
                }
            };
            MissedVisitService.prototype.setPatientTimes = function (missed_visit) {
                // getHours() etc will only work on a JS Date object, not a string
                missed_visit.effective_date = new Date(missed_visit.effective_date.toString());
                // patient time effective date must always match missed visit effective date.
                missed_visit.patient_time.effective_date = missed_visit.effective_date;
                if (!missed_visit.patient_time.in_datetime) {
                    var pt_in_datetime = missed_visit.effective_date;
                    pt_in_datetime.setHours(0, 0, 0);
                    missed_visit.patient_time.in_datetime = pt_in_datetime;
                }
                if (!missed_visit.patient_time.out_datetime) {
                    var pt_out_datetime = missed_visit.effective_date;
                    pt_out_datetime.setHours(0, 0, 0);
                    missed_visit.patient_time.out_datetime = pt_out_datetime;
                }
                if (missed_visit.patient_time.travel_start_datetime) {
                    var travel_start_datetime = new Date();
                    travel_start_datetime.setTime(missed_visit.effective_date.getTime());
                    travel_start_datetime.setHours(missed_visit.patient_time.travel_start_datetime.getHours());
                    travel_start_datetime.setMinutes(missed_visit.patient_time.travel_start_datetime.getMinutes());
                    travel_start_datetime.setSeconds(missed_visit.patient_time.travel_start_datetime.getSeconds());
                    missed_visit.patient_time.travel_start_datetime = travel_start_datetime;
                }
                if (missed_visit.patient_time.travel_stop_datetime) {
                    var travel_stop_datetime = new Date();
                    travel_stop_datetime.setTime(missed_visit.effective_date.getTime());
                    travel_stop_datetime.setHours(missed_visit.patient_time.travel_stop_datetime.getHours());
                    travel_stop_datetime.setMinutes(missed_visit.patient_time.travel_stop_datetime.getMinutes());
                    travel_stop_datetime.setSeconds(missed_visit.patient_time.travel_stop_datetime.getSeconds());
                    missed_visit.patient_time.travel_stop_datetime = travel_stop_datetime;
                }
            };
            MissedVisitService.prototype.formatSignatures = function (signatures) {
                if (signatures.length === 0) {
                    return '';
                }
                var sigs = [];
                signatures.forEach(function (sig) {
                    sigs.push(sig.pretty_html);
                });
                return sigs.join('<br>');
            };
            MissedVisitService.$inject = [
                '$http',
                'CurrentPatient',
                '$mdDialog',
                'logger',
                'PatientTimeService',
            ];
            return MissedVisitService;
        }());
        services.MissedVisitService = MissedVisitService;
        angular.module('consolo').service('MissedVisitService', MissedVisitService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var NetworkManagerService = /** @class */ (function () {
            function NetworkManagerService(sessionTimer, sessionService, debugLog, $timeout, $q) {
                this.sessionTimer = sessionTimer;
                this.sessionService = sessionService;
                this.debugLog = debugLog;
                this.$timeout = $timeout;
                this.$q = $q;
                this.forceOffline = false;
                this.wentOffline = false;
                this.online = true;
                this.onlineHandler = new consolo.domain.ConsoloEventHandler('online');
                this.offlineHandler = new consolo.domain.ConsoloEventHandler('offline');
            }
            NetworkManagerService.prototype.isOffline = function () {
                return !this.online;
            };
            NetworkManagerService.prototype.isOnline = function () {
                return this.online;
            };
            NetworkManagerService.prototype.goOffline = function () {
                if (this.online) {
                    this.debugLog.info('Network Manager: goOffline called');
                    this.wentOffline = true;
                    this.online = false;
                    this.offlineHandler.eventFired();
                }
            };
            NetworkManagerService.prototype.goOnline = function () {
                if ((!this.online || this.wentOffline) && !this.forceOffline) {
                    this.debugLog.info('Network Manager: goOnline called');
                    this.online = true;
                    this.wentOffline = false;
                    this.onlineHandler.eventFired();
                    if (this.sessionTimer.ssoTimeout.ttl() <= 0) {
                        return this.sessionService.check().catch(function () { return true; });
                    }
                    else {
                        return this.$q.when(true);
                    }
                }
                else {
                    return this.$q.when(true);
                }
            };
            NetworkManagerService.prototype.forceOfflineStatus = function () {
                this.forceOffline = !this.forceOffline;
                this.forceOffline ? this.goOffline() : this.goOnline();
            };
            NetworkManagerService.$inject = ['SessionTimerService', 'SessionService', 'DebugLogService', '$timeout', '$q'];
            return NetworkManagerService;
        }());
        services.NetworkManagerService = NetworkManagerService;
        angular.module('consolo').service('NetworkManager', NetworkManagerService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        /**
         * Intelligent network monitoring using PubSub with HTTP polling as a fallback. If the PubSub
         * connection dies, it will start doing HTTP polling against /speedtest until PubSub comes back up.
         *
         * If HTTP polling fails then it will put NetworkManager into OFFLINE mode after a short wait. Polling
         * will continue, and if one succeeds it will go back ONLINE. Likewise if PubSub re-connects it will
         * go back online and stop polling.
         *
         * If the tab goes into the background, any polling will stop until the tab is in the foreground again.
         */
        var NetworkMonitorService = /** @class */ (function () {
            function NetworkMonitorService(NetworkManager, debugLog, pubSub, $http, $timeout, $q) {
                var _this = this;
                this.NetworkManager = NetworkManager;
                this.debugLog = debugLog;
                this.pubSub = pubSub;
                this.$http = $http;
                this.$timeout = $timeout;
                this.$q = $q;
                this.running = false;
                this.extensionName = 'NetworkMonitor';
                this.handleVisibilityChange = function () {
                    if (_this.mode === 'polling') {
                        if (document.hidden) {
                            _this.cancelPoll();
                        }
                        else {
                            _this.cancelOffline();
                            _this.cancelPoll();
                            _this.schedulePoll(0);
                        }
                    }
                };
            }
            NetworkMonitorService.prototype.onSessionVisible = function (sess) {
                this.start();
                document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
            };
            NetworkMonitorService.prototype.onSessionClear = function () {
                this.stop();
                document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
            };
            NetworkMonitorService.prototype.start = function () {
                var _this = this;
                this.running = true;
                this.debugLog.info('NetworkMonitor: starting');
                this.pubSub.onOpen(function () {
                    if (_this.running && _this.mode !== 'websocket') {
                        _this.mode = 'websocket';
                        _this.debugLog.info('NetworkMonitor: switching to websocket mode');
                        _this.isOnline();
                        _this.cancelPoll();
                        _this.addConnectionTest();
                    }
                });
                this.pubSub.onClose(function () {
                    if (_this.running && _this.mode !== 'polling') {
                        _this.mode = 'polling';
                        _this.debugLog.info('NetworkMonitor: switching to polling mode');
                        _this.schedulePoll(5 * 1000);
                        _this.removeConnectionTest();
                    }
                });
            };
            NetworkMonitorService.prototype.stop = function () {
                this.debugLog.info('NetworkMonitor: stopping');
                this.running = false;
                this.cancelPoll();
                this.cancelOffline();
            };
            NetworkMonitorService.prototype.poll = function () {
                var _this = this;
                this.$http
                    .get('/speedtest/', { timeout: 10 * 1000 })
                    .then(function () {
                    _this.isOnline();
                    _this.schedulePoll(10 * 1000);
                }, function (resp) {
                    if (resp.status && resp.status >= 200) {
                        _this.isOnline();
                        _this.schedulePoll(10 * 1000);
                    }
                    else {
                        _this.scheduleOffline(13 * 1000);
                        _this.schedulePoll(10 * 1000);
                    }
                });
            };
            NetworkMonitorService.prototype.isOnline = function () {
                this.cancelOffline();
                this.NetworkManager.goOnline();
            };
            NetworkMonitorService.prototype.schedulePoll = function (sec) {
                var _this = this;
                if (!this.nextPoll) {
                    this.nextPoll = this.$timeout(function () {
                        _this.nextPoll = null;
                        _this.poll();
                    }, sec);
                }
            };
            NetworkMonitorService.prototype.cancelPoll = function () {
                if (this.nextPoll) {
                    this.$timeout.cancel(this.nextPoll);
                    this.nextPoll = null;
                }
            };
            NetworkMonitorService.prototype.scheduleOffline = function (sec) {
                var _this = this;
                if (!this.pendingOffline) {
                    this.pendingOffline = this.$timeout(function () {
                        _this.pendingOffline = null;
                        _this.NetworkManager.goOffline();
                    }, sec);
                }
            };
            NetworkMonitorService.prototype.cancelOffline = function () {
                if (this.pendingOffline) {
                    this.$timeout.cancel(this.pendingOffline);
                    this.pendingOffline = null;
                }
            };
            NetworkMonitorService.prototype.addConnectionTest = function () {
                var testDiv = document.createElement('div');
                testDiv.setAttribute('id', 'websocket-test');
                document.body.appendChild(testDiv);
            };
            NetworkMonitorService.prototype.removeConnectionTest = function () {
                var testDiv = document.getElementById('websocket-test');
                if (testDiv) {
                    testDiv.remove();
                }
            };
            NetworkMonitorService.$inject = [
                'NetworkManager',
                'DebugLogService',
                'PubSubService',
                '$http',
                '$timeout',
                '$q',
            ];
            return NetworkMonitorService;
        }());
        common.NetworkMonitorService = NetworkMonitorService;
        angular.module('consolo').service('NetworkMonitor', NetworkMonitorService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var NonPlayerCharactersServiceWrapper = /** @class */ (function () {
            function NonPlayerCharactersServiceWrapper(referralIndividualService, physicianService, familyMembersService) {
                this.referralIndividualService = referralIndividualService;
                this.physicianService = physicianService;
                this.familyMembersService = familyMembersService;
            }
            NonPlayerCharactersServiceWrapper.prototype.create = function (type, npcData) {
                return this.getService(type).create(npcData);
            };
            NonPlayerCharactersServiceWrapper.prototype.isRequired = function (type, field) {
                return this.getService(type).requiredFields.indexOf(field) >= 0;
            };
            NonPlayerCharactersServiceWrapper.prototype.getService = function (type) {
                var npcService;
                switch (type) {
                    case 'Physician':
                        npcService = this.physicianService;
                        break;
                    case 'Family':
                        npcService = this.familyMembersService;
                        break;
                    case 'Referral Individual':
                        npcService = this.referralIndividualService;
                        break;
                }
                return npcService;
            };
            NonPlayerCharactersServiceWrapper.prototype.newModel = function (type) {
                switch (type) {
                    case 'Physician':
                        return new consolo.domain.PhysicianCreation();
                    default:
                        return new consolo.domain.NonPlayerCharacter();
                }
            };
            NonPlayerCharactersServiceWrapper.$inject = ['referralIndividualService', 'physicianService', 'familyMembersService'];
            return NonPlayerCharactersServiceWrapper;
        }());
        services.NonPlayerCharactersServiceWrapper = NonPlayerCharactersServiceWrapper;
        angular
            .module('consolo')
            .service('nonPlayerCharactersService', NonPlayerCharactersServiceWrapper);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var OfficeFilterServiceInstance = /** @class */ (function () {
            function OfficeFilterServiceInstance(officeService, orgService) {
                this.officeService = officeService;
                this.orgService = orgService;
                this.offices = new Array();
                this.corporateOptions = new Array();
                this.regionOptions = new Array();
                this.areaOptions = new Array();
            }
            OfficeFilterServiceInstance.prototype.initialize = function () {
                var _this = this;
                var orgPromise = this.orgService
                    .select()
                    .then(function (response) {
                    var data = response.data;
                    _this.corporateOptions = _this.orgService.generateDropdownOptions('Corporate', data);
                    _this.regionOptions = _this.orgService.generateDropdownOptions('Region', data);
                    _this.areaOptions = _this.orgService.generateDropdownOptions('Area', data);
                })
                    .catch(function (err) { return console.log(err); });
                var officesPromise = this.officeService
                    .select()
                    .then(function (response) {
                    _this.offices = response.data.map(function (officeData) { return new consolo.domain.Office(officeData); });
                })
                    .catch(function (err) { return console.log(err); });
                return Promise.all([orgPromise, officesPromise]).then(function () { return _this; });
            };
            OfficeFilterServiceInstance.prototype.officeFilterChange = function (field) {
                var _this = this;
                this.officeFilter = field;
                ['corporate', 'region', 'area'].forEach(function (option) {
                    if (option !== field) {
                        _this[option] = null;
                    }
                });
                this.offices.forEach(function (office) { return (office.selected = office[field] === _this[field]); });
            };
            OfficeFilterServiceInstance.prototype.showOffice = function (office) {
                var officeFilterShow = !this.officeFilter || this[this.officeFilter] === office[this.officeFilter];
                var quickFilterShow = !this.officeQuickFilterText ||
                    office.name.toUpperCase().indexOf(this.officeQuickFilterText.toUpperCase()) > -1;
                return officeFilterShow && quickFilterShow;
            };
            OfficeFilterServiceInstance.prototype.getSelected = function () {
                return this.offices.filter(function (office) { return office.selected; });
            };
            OfficeFilterServiceInstance.prototype.getSelectedIds = function () {
                return this.getSelected().map(function (office) { return office.id.toString(); });
            };
            OfficeFilterServiceInstance.prototype.anySelected = function () {
                return !!this.offices.filter(function (office) { return office.selected; }).length;
            };
            OfficeFilterServiceInstance.prototype.reset = function () {
                this.offices.forEach(function (office) { return (office.selected = false); });
                this.officeFilter = '';
                this.officeQuickFilterText = '';
                this.corporate = null;
                this.region = null;
                this.area = null;
            };
            return OfficeFilterServiceInstance;
        }());
        services.OfficeFilterServiceInstance = OfficeFilterServiceInstance;
        var OfficeFilterService = /** @class */ (function () {
            function OfficeFilterService(officeService, orgService) {
                this.officeService = officeService;
                this.orgService = orgService;
            }
            OfficeFilterService.prototype.getInstance = function () {
                return new OfficeFilterServiceInstance(this.officeService, this.orgService).initialize();
            };
            OfficeFilterService.$inject = ['officeService', 'orgService'];
            return OfficeFilterService;
        }());
        services.OfficeFilterService = OfficeFilterService;
        angular.module('consolo').service('officeFilterService', OfficeFilterService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var OfficeSelectionList = /** @class */ (function () {
            function OfficeSelectionList() {
                this.bindings = { parentObject: '<' };
                (this.templateUrl = '/y/templates/common/office-selection-list.html'),
                    (this.controller = OfficeSelectionListController);
            }
            return OfficeSelectionList;
        }());
        var OfficeSelectionListController = /** @class */ (function () {
            function OfficeSelectionListController(officeFilterService) {
                this.officeFilterService = officeFilterService;
                this.corporates = [];
                this.corporateOffices = {};
                this.regions = [];
                this.regionOffices = {};
                this.areas = [];
                this.areaOffices = {};
                this.offices = [];
                this.selectedOffices = [];
            }
            OfficeSelectionListController.prototype.$onInit = function () {
                var _this = this;
                this.officeFilterService
                    .getInstance()
                    .then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                })
                    .catch(function (err) { return console.log(err); });
                if (!this.parentObject.office_ids) {
                    this.parentObject.office_ids = [];
                }
            };
            OfficeSelectionListController.prototype.selectOffice = function (office) {
                this.selectedOffices.push(office);
                this.parentObject.office_ids.push(office.id);
            };
            OfficeSelectionListController.prototype.removeFromIdArrayById = function (array, id) {
                var index = array.indexOf(id);
                if (index > -1) {
                    array.splice(index, 1);
                }
            };
            OfficeSelectionListController.prototype.removeFromCategory = function (category, categoryOffices, office) {
                if (categoryOffices.hasOwnProperty(office[category])) {
                    var removeIndex = null;
                    var offices = categoryOffices[office[category]];
                    for (var index = 0; index < offices.length; index++) {
                        if (offices[index].id === office.id) {
                            removeIndex = index;
                            break;
                        }
                    }
                    offices.splice(removeIndex, 1);
                }
            };
            OfficeSelectionListController.prototype.deselectOffice = function (office) {
                this.removeFromIdArrayById(this.parentObject.office_ids, office.id);
                this.removeFromIdArrayById(this.offices, office.id);
                this.removeFromCategory('corporate', this.corporateOffices, office);
                this.removeFromCategory('region', this.regionOffices, office);
                this.removeFromCategory('area', this.areaOffices, office);
            };
            OfficeSelectionListController.prototype.buildSelectedOffices = function () {
                var _this = this;
                this.selectedOffices.length = 0;
                this.parentObject.office_ids.length = 0;
                this.officeFilterServiceInstance.offices.forEach(function (office) {
                    if (_this.offices.lastIndexOf(office.id) >= 0) {
                        _this.selectOffice(office);
                    }
                });
                this.buildFromCategory(this.areaOffices);
                this.buildFromCategory(this.regionOffices);
                this.buildFromCategory(this.corporateOffices);
            };
            OfficeSelectionListController.prototype.buildFromCategory = function (categoryOffices) {
                var _this = this;
                Object.keys(categoryOffices).forEach(function (category_id) {
                    categoryOffices[category_id].forEach(function (office) {
                        if (_this.parentObject.office_ids.indexOf(office.id) === -1) {
                            _this.selectOffice(office);
                        }
                    });
                });
            };
            OfficeSelectionListController.prototype.selectForCategory = function (category, selectedIds, categoryOffices) {
                var _this = this;
                selectedIds.forEach(function (group) {
                    if (!categoryOffices.hasOwnProperty(group)) {
                        categoryOffices[group] = [];
                        _this.officeFilterServiceInstance.offices.forEach(function (office) {
                            if (office[category] === group) {
                                categoryOffices[group].push(office);
                            }
                        });
                    }
                });
                Object.keys(categoryOffices).forEach(function (group) {
                    if (selectedIds.indexOf(parseInt(group, 10)) === -1) {
                        delete categoryOffices[group];
                    }
                });
                this.buildSelectedOffices();
            };
            OfficeSelectionListController.prototype.onCorporatesChange = function () {
                this.selectForCategory('corporate', this.corporates, this.corporateOffices);
            };
            OfficeSelectionListController.prototype.onRegionsChange = function () {
                this.selectForCategory('region', this.regions, this.regionOffices);
            };
            OfficeSelectionListController.prototype.onAreasChange = function () {
                this.selectForCategory('area', this.areas, this.areaOffices);
            };
            OfficeSelectionListController.prototype.onOfficesChange = function () {
                this.buildSelectedOffices();
            };
            OfficeSelectionListController.$inject = ['officeFilterService'];
            return OfficeSelectionListController;
        }());
        angular.module('consolo').component('officeSelectionList', new OfficeSelectionList());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('officeSelection', {
            bindings: {
                parentObject: '<',
                isRequired: '<',
                readonly: '<',
            },
            transclude: true,
            templateUrl: '/y/templates/common/office-selection.html',
            controller: [
                'officeFilterService',
                function (officeFilterService) {
                    this.$onInit = function () {
                        var _this = this;
                        this.backgroundColor = 'background:#ededed;';
                        if (this.isRequired) {
                            this.backgroundColor = 'background:#E1F5FE;';
                        }
                        officeFilterService
                            .getInstance()
                            .then(function (instance) {
                            _this.officeFilterServiceInstance = instance;
                            if (_this.parentObject.office_ids) {
                                _this.officeFilterServiceInstance.offices.forEach(function (office) {
                                    var indexOf = _this.parentObject.office_ids.indexOf(office.id);
                                    if (indexOf > -1) {
                                        office.selected = true;
                                    }
                                });
                            }
                            else {
                                _this.parentObject.office_ids = [];
                                _this.parentObject.offices = [];
                            }
                        })
                            .catch(function (err) { return console.log(err); });
                        this.keepOfficeInSync = keepOfficeInSync;
                        this.officeFilterChange = officeFilterChange;
                    };
                    function keepOfficeInSync(office) {
                        var indexOf = this.parentObject.office_ids.indexOf(office.id);
                        if (indexOf === -1) {
                            this.parentObject.office_ids.push(office.id);
                            this.parentObject.offices.push(office);
                        }
                        else {
                            this.parentObject.office_ids.splice(indexOf, 1);
                            this.parentObject.offices.splice(indexOf, 1);
                        }
                    }
                    function officeFilterChange(officeType) {
                        this.officeFilterServiceInstance.officeFilterChange(officeType);
                        this.parentObject.office_ids = this.officeFilterServiceInstance.getSelectedIds();
                        this.parentObject.offices = this.officeFilterServiceInstance.getSelected();
                    }
                },
            ],
            controllerAs: 'vm',
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var OfficeService = /** @class */ (function () {
            function OfficeService($http) {
                this.$http = $http;
            }
            OfficeService.prototype.select = function () {
                return this.$http({
                    url: '/api/v1/offices',
                    method: 'GET',
                    params: { org_tree: true },
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                });
            };
            OfficeService.prototype.get = function () {
                return this.$http.get('/api/v1/offices');
            };
            OfficeService.$inject = ['$http'];
            return OfficeService;
        }());
        services.OfficeService = OfficeService;
        angular.module('consolo').service('officeService', OfficeService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var OrgService = /** @class */ (function () {
            function OrgService($http) {
                this.$http = $http;
            }
            OrgService.prototype.select = function () {
                return this.$http({
                    url: '/api/v1/org',
                    method: 'GET',
                });
            };
            OrgService.prototype.generateDropdownOptions = function (type, orgs) {
                return orgs
                    .filter(function (org) { return org.type === type; })[0]
                    .items.map(function (item) {
                    return {
                        name: item.name,
                        value: item.id,
                        parent_id: item.parent_id,
                    };
                });
            };
            OrgService.$inject = ['$http'];
            return OrgService;
        }());
        services.OrgService = OrgService;
        angular.module('consolo').service('orgService', OrgService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ReconciliationService = /** @class */ (function () {
            function ReconciliationService($http, $timeout, $q) {
                this.$http = $http;
                this.$timeout = $timeout;
                this.$q = $q;
            }
            ReconciliationService.prototype.getAllPatientImports = function () {
                return this.$http
                    .get('/api/v1/patient_imports')
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.getSinglePatientImport = function (importId) {
                return this.$http
                    .get('/api/v1/patient_imports/' + importId)
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.getPatientImportMatch = function (patientImportObj) {
                return this.$http
                    .post('/api/v1/patients/matches', patientImportObj)
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.updatePatientImport = function (importObject, importId) {
                return this.$http
                    .patch('/api/v1/patient_imports/' + importId, importObject)
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.setPatientId = function (importId, patientId) {
                return this.$http
                    .patch('/api/v1/patient_imports/' + importId + '/patient', { patient_id: patientId })
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.reconcilePatientImport = function (importId) {
                return this.$http
                    .get('/api/v1/patient_imports/' + importId + '/reconcile')
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.importPatientData = function (importId, newPatient) {
                return this.$http
                    .post('/api/v1/patient_imports/' + importId + '/import?new_patient=' + newPatient, null)
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.deletePatientImport = function (importId) {
                return this.$http.delete('/api/v1/patient_imports/' + importId).then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.checkIOHubUpdates = function (patientId) {
                return this.$http
                    .get('/api/v2/io_hub/patients/' + patientId + '/clinical_data/check')
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.prototype.createIOHubImport = function (patientId) {
                return this.$http
                    .post('/api/v2/io_hub/patients/' + patientId + '/clinical_data/import', null)
                    .then(function (response) {
                    return response;
                });
            };
            ReconciliationService.$inject = ['$http', '$timeout', '$q'];
            return ReconciliationService;
        }());
        services.ReconciliationService = ReconciliationService;
        angular.module('consolo').service('reconciliationService', ReconciliationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PatientTimeService = /** @class */ (function () {
            function PatientTimeService($http) {
                var _this = this;
                this.$http = $http;
                this.create = function (patientTime, force) {
                    var pt = patientTime;
                    pt.force = force;
                    return _this.$http.post(_this.url, pt);
                };
                this.get = function (id) {
                    return _this.$http.get(_this.url + '/' + id);
                };
                this.update = function (patientTime, force) {
                    var pt = patientTime;
                    pt.force = force;
                    return _this.$http.patch(_this.url + '/' + patientTime.id, pt);
                };
                this.url = '/api/v1/patient_times';
            }
            PatientTimeService.$inject = ['$http'];
            return PatientTimeService;
        }());
        services.PatientTimeService = PatientTimeService;
        angular.module('consolo').service('PatientTimeService', PatientTimeService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PatientService = /** @class */ (function () {
            function PatientService($http, options, cache, Patient, $q) {
                this.$http = $http;
                this.options = options;
                this.cache = cache;
                this.Patient = Patient;
                this.$q = $q;
                this.fetchingData = {};
            }
            PatientService.prototype.get = function (id) {
                return this.$http({
                    url: "/api/v1/patients/" + id,
                    method: 'GET',
                });
            };
            PatientService.prototype.getExtra = function (id) {
                var query = { extra: true };
                return this.$http.get('/api/v1/patients/' + id, { params: query });
            };
            PatientService.prototype.getDefaultPhysician = function (id) {
                return this.$http.get('/api/v1/patients/' + id + '/default_physician');
            };
            PatientService.prototype.lookup = function (query) {
                return this.$http({
                    url: "/api/v1/lookup/patients?q=" + query,
                    method: 'GET',
                });
            };
            PatientService.prototype.lookupAllergens = function (query) {
                return this.$http({
                    url: "/api/v1/medispan/allergens?q=" + query,
                    method: 'GET',
                });
            };
            PatientService.prototype.getPatient = function (id) {
                return this.$http.get('/api/v1/patients/' + id);
            };
            PatientService.prototype.getPatientAllergies = function (id) {
                return this.$http({
                    url: "/api/v1/allergies?patient_id=" + id,
                    method: 'GET',
                });
            };
            PatientService.prototype.getSideEffects = function (query) {
                return this.$http({
                    url: "/api/v1/snomed_side_effects",
                    method: 'GET',
                    params: { q: query },
                });
            };
            PatientService.prototype.addPatientAllergy = function (allergyObj) {
                return this.$http.post('/api/v1/allergies', allergyObj);
            };
            PatientService.prototype.updateUnscreenedAllergies = function (allergyObj) {
                return this.$http.patch('/api/v1/allergies/unscreened', allergyObj);
            };
            PatientService.prototype.deletePatientAllergy = function (id) {
                return this.$http.delete('/api/v1/allergies/' + id);
            };
            PatientService.prototype.exportAllergensToCSV = function (id) {
                return this.$http.get('/api/v1/allergies/export?patient_id=' + id);
            };
            PatientService.prototype.getPatientMillitaryServiceInfo = function (id) {
                return this.$http.get('/api/v1/patients/' + id + '/military_service_info');
            };
            PatientService.prototype.getPatientVisitFrequency = function (id) {
                var _this = this;
                var _a;
                var promiseId = "visitFrequency" + id;
                if (!this.fetchingData[promiseId]) {
                    this.fetchingData[promiseId] = { fetching: false, deffered: null };
                }
                if ((_a = this.fetchingData[promiseId]) === null || _a === void 0 ? void 0 : _a.fetching) {
                    return this.fetchingData[promiseId].deffered.promise;
                }
                else {
                    this.fetchingData[promiseId].fetching = true;
                    this.fetchingData[promiseId].deffered = this.$q.defer();
                    this.cache(this.Patient, 'patients', {
                        encrypt: true,
                        force: true,
                    })
                        .visit_frequency({ id: id })
                        .$promise.then(function (vf) {
                        _this.fetchingData[promiseId].fetching = false;
                        _this.fetchingData[promiseId].deffered.resolve(vf);
                        return vf;
                    });
                    return this.fetchingData[promiseId].deffered.promise;
                }
            };
            PatientService.prototype.getPatientVisitFrequencies = function (id, startDate, endDate, roleId) {
                return this.$http({
                    url: '/api/v1/patients/' + id + '/current_visit_frequencies',
                    method: 'GET',
                    params: { start_date: startDate, end_date: endDate, role_id: roleId },
                });
            };
            PatientService.$inject = ['$http', 'options', 'cache', 'Patient', '$q'];
            return PatientService;
        }());
        services.PatientService = PatientService;
        angular.module('consolo').service('patientService', PatientService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var pendoService;
    (function (pendoService) {
        'use strict';
        var PendoService = /** @class */ (function () {
            function PendoService(Hosts) {
                this.Hosts = Hosts;
                this.extensionName = 'PendoService';
            }
            PendoService.prototype.onSessionVisible = function (sess) {
                if (this.Hosts.env === 'sigma' ||
                    this.Hosts.env === 'staging' ||
                    this.Hosts.env === 'production') {
                    var identity = {
                        visitor: {
                            id: sess.user.email,
                            HOS_defaultRole: sess.user.default_role.discipline,
                        },
                        account: {
                            id: sess.agency.description,
                            HOS_description: sess.agency.description,
                            HOS_serviceLine: sess.agency.service_line,
                            HOS_application: 'emr-web',
                            HOS_browserSize: window.innerWidth + 'px x ' + window.innerHeight + 'px',
                        },
                    };
                    pendo.initialize(identity);
                }
            };
            PendoService.$inject = ['Hosts'];
            return PendoService;
        }());
        pendoService.PendoService = PendoService;
        angular.module('consolo').service('PendoService', PendoService);
    })(pendoService = consolo.pendoService || (consolo.pendoService = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('Permission', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/permissions/:id', { id: '@id' }, {
            current: {
                method: 'GET',
                url: '/api/v1/permissions/current',
                isArray: true,
            },
        });
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('physicianSignatureLine', {
            templateUrl: '/y/templates/common/physician-signature-line.html',
        });
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PhysicianService = /** @class */ (function () {
            function PhysicianService($http, $q) {
                this.$http = $http;
                this.$q = $q;
                this.requiredFields = ['first_name', 'last_name', 'npi', 'work_phone'];
            }
            PhysicianService.prototype.create = function (npcData) {
                var _this = this;
                var physician;
                return this.$http({
                    url: '/api/v1/physicians',
                    method: 'POST',
                    data: npcData,
                }).then(function (response) {
                    physician = response.data;
                    var physicianDocuments = npcData.physician_documents;
                    if (physicianDocuments && physicianDocuments.length > 0) {
                        return _this.$q.all(_this.buildUploadRequests(physician.id, physicianDocuments)).then(function (response) {
                            return physician;
                        }, function (errorResponse) {
                            return _this.$q.resolve(physician);
                        });
                    }
                    else {
                        return physician;
                    }
                });
            };
            PhysicianService.prototype.buildUploadRequests = function (physicianId, documents) {
                var uploadRequests = [];
                for (var _i = 0, documents_1 = documents; _i < documents_1.length; _i++) {
                    var document_1 = documents_1[_i];
                    var formData = new FormData();
                    formData.append('file', document_1.file);
                    if (document_1.name) {
                        formData.append('name', document_1.name);
                    }
                    uploadRequests.push(this.$http({
                        url: "/api/v1/physicians/" + physicianId + "/physician_documents",
                        method: 'POST',
                        data: formData,
                        headers: { 'Content-Type': undefined },
                        transformRequest: angular.identity,
                    }));
                }
                return uploadRequests;
            };
            PhysicianService.$inject = ['$http', '$q'];
            return PhysicianService;
        }());
        services.PhysicianService = PhysicianService;
        angular.module('consolo').service('physicianService', PhysicianService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ReferralContactService = /** @class */ (function () {
            function ReferralContactService($http) {
                this.$http = $http;
            }
            ReferralContactService.prototype.referralContactLookup = function (referralAccountId, query) {
                var params = { q: query };
                if (referralAccountId) {
                    params.referral_account_id = referralAccountId;
                }
                return this.$http({
                    url: '/api/v1/lookup/referral_contacts',
                    params: params,
                    method: 'GET',
                }).then(function (response) {
                    return response.data.map(function (contactData) { return new consolo.domain.ReferralContact(contactData); });
                });
            };
            ReferralContactService.prototype.communityLiasonLookup = function (query) {
                return this.$http({
                    url: "/api/v1/lookup/community_liasons?q=" + query,
                    method: 'GET',
                }).then(function (response) {
                    return response.data.map(function (userData) { return new CommunityLiason(userData); });
                });
            };
            ReferralContactService.$inject = ['$http'];
            return ReferralContactService;
        }());
        services.ReferralContactService = ReferralContactService;
        var CommunityLiason = /** @class */ (function () {
            function CommunityLiason(data) {
                this.id = data.id;
                this.name = data.name;
            }
            return CommunityLiason;
        }());
        services.CommunityLiason = CommunityLiason;
        angular.module('consolo').service('referralContactService', ReferralContactService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ReferralIndividualService = /** @class */ (function () {
            function ReferralIndividualService($http) {
                this.$http = $http;
                this.requiredFields = ['first_name', 'last_name'];
            }
            ReferralIndividualService.prototype.create = function (npcData) {
                return this.$http({
                    url: '/api/v1/referral_individuals',
                    method: 'POST',
                    data: npcData,
                }).then(function (response) {
                    return response.data;
                });
            };
            ReferralIndividualService.$inject = ['$http'];
            return ReferralIndividualService;
        }());
        services.ReferralIndividualService = ReferralIndividualService;
        angular.module('consolo').service('referralIndividualService', ReferralIndividualService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var ReleaseController = /** @class */ (function () {
            function ReleaseController($resource) {
                this.Release = $resource('/api/v1/about/release');
                this.release = this.Release.get({ yes_i_asked_someone_about_this: true });
            }
            ReleaseController.$inject = ['$resource'];
            return ReleaseController;
        }());
        angular.module('consolo').controller('ReleaseController', ReleaseController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').service('EmrWebRelease', [
            function () {
                return function () {
                    return '%%GULP_INJECT_VERSION%%';
                };
            },
        ]);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('require', ['$q', Factory]);
    var cache = {};
    /* jshint browser: true */
    function Factory($q) {
        return require;
        function require(src) {
            if (cache[src] === true) {
                return $q(function (resolve) {
                    return resolve();
                });
            }
            else {
                return $q(function (resolve) {
                    var scriptTag = document.createElement('script');
                    scriptTag.onload = function () {
                        cache[src] = true;
                        resolve();
                    };
                    scriptTag.src = src;
                    document.body.appendChild(scriptTag);
                });
            }
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('ScheduledVisit', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/patients/scheduled_visits');
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('SelectOption', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/options');
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var ServiceWorkerUpdater = /** @class */ (function () {
            function ServiceWorkerUpdater($root) {
                this.$root = $root;
                this.preventReloadStack = [];
            }
            ServiceWorkerUpdater.prototype.initialize = function () {
                var _this = this;
                this.$root.$on('$stateChangeSuccess', function (e, toState, toParams, fromState, fromParams) {
                    if (_this.pendingUpdate() && _this.safeToReload(toState)) {
                        setTimeout(function () { return location.reload(); }, 1000);
                    }
                });
            };
            ServiceWorkerUpdater.prototype.pendingUpdate = function () {
                return window['swUpdateReady'] === true;
            };
            ServiceWorkerUpdater.prototype.safeToReload = function (toState) {
                return !!toState.data.allowSwUpdate && !this.preventReloadStack.length;
            };
            ServiceWorkerUpdater.prototype.preventReload = function (processId) {
                this.preventReloadStack.push(processId);
            };
            ServiceWorkerUpdater.prototype.allowReload = function (processId) {
                var pos = this.preventReloadStack.indexOf(processId);
                if (pos >= 0) {
                    this.preventReloadStack.splice(pos, 1);
                }
            };
            ServiceWorkerUpdater.$inject = ['$rootScope'];
            return ServiceWorkerUpdater;
        }());
        common.ServiceWorkerUpdater = ServiceWorkerUpdater;
        angular.module('consolo').service('ServiceWorkerUpdater', ServiceWorkerUpdater);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ServiceService = /** @class */ (function () {
            function ServiceService($http) {
                this.$http = $http;
                this.SERVICE_TYPE_NAMESPACE_MAP = {
                    Facility: 'facilities',
                    'Community Group': 'community_groups',
                    'Medical Company': 'medical_companies',
                    'Referral Account': 'referral_accounts',
                };
            }
            ServiceService.prototype.lookup = function (query) {
                return this.$http({
                    url: "/api/v1/lookup/services?q=" + query,
                    method: 'GET',
                });
            };
            ServiceService.prototype.create = function (serviceType, data) {
                return this.$http.post("/api/v1/" + this.SERVICE_TYPE_NAMESPACE_MAP[serviceType], data);
            };
            ServiceService.prototype.getFacility = function (id) {
                return this.$http.get("/api/v1/facilities/" + id);
            };
            ServiceService.prototype.getCurrentFacility = function (patient_id, effective_date) {
                return this.$http.get("/api/v1/facilities?patient_id=" + patient_id + "&effective_date=" + effective_date);
            };
            ServiceService.$inject = ['$http'];
            return ServiceService;
        }());
        services.ServiceService = ServiceService;
        angular.module('consolo').service('serviceService', ServiceService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('consoloStarRating', ['$timeout', Directive]);
    function Directive($timeout) {
        return {
            restrict: 'E',
            templateUrl: '/y/templates/common/star-rating.html',
            require: '?ngModel',
            scope: {
                ratingValue: '=ngModel',
                max: '=?',
                onRatingSelected: '&?',
                readonly: '=?',
                label: '@',
                values: '=',
                minZero: '=?',
            },
            link: function (scope, el, at, ngModel) {
                scope.offset = !!scope.minZero ? 0 : 1;
                if (scope.max === undefined) {
                    scope.max = 4;
                }
                if (scope.values === undefined) {
                    scope.values = [];
                }
                function setDescription() {
                    if (!scope.values.length) {
                        $timeout(setDescription, 500);
                        scope.description = '';
                        return;
                    }
                    if (scope.values[scope.ratingValue - scope.offset]) {
                        scope.description = ' - ' + scope.values[scope.ratingValue - scope.offset].description;
                    }
                    else {
                        scope.description = '';
                    }
                }
                function updateStars() {
                    setDescription();
                    scope.stars = [];
                    for (var i = scope.offset; i < scope.max + scope.offset; i++) {
                        scope.stars.push({
                            filled: scope.ratingValue !== null &&
                                scope.ratingValue !== undefined &&
                                scope.ratingValue > -1 &&
                                i <= scope.ratingValue,
                            value: 'text here',
                        });
                    }
                }
                scope.clear = function () {
                    scope.ratingValue = -1;
                };
                scope.mouseleave = function () {
                    setDescription();
                };
                scope.mouseover = function (index) {
                    scope.description = scope.values[index] ? ' - ' + scope.values[index].description : '';
                };
                scope.toggle = function (index) {
                    if (scope.readonly === undefined || scope.readonly === false) {
                        scope.ratingValue = index + scope.offset;
                        if (scope.onRatingSelected) {
                            scope.onRatingSelected({
                                rating: index + scope.offset,
                            });
                        }
                    }
                };
                scope.$watch('ratingValue', function (newVal, oldVal) {
                    if (newVal !== undefined && newVal !== null && newVal !== oldVal) {
                        ngModel.$setDirty();
                        updateStars();
                    }
                });
                updateStars();
            },
        };
    }
})();

(function () {
    'use strict';
    angular.module('consolo').directive('stateSelect', Directive);
    function Directive() {
        return {
            restrict: 'E',
            template: template(),
            scope: { model: '=' },
            link: link,
            controller: ['$scope', Controller],
        };
    }
    function template() {
        return ('<md-select placeholder="{{promptText}}" ng-model="model">' +
            '<md-option ng-value="state.abbreviation" ng-repeat="state in states">{{state.name}}</md-option>' +
            '</md-select>');
    }
    function link($scope, element, attrs) {
        $scope.promptText = attrs.promptText || 'State';
    }
    function Controller($scope) {
        $scope.states = [
            {
                name: 'Alabama',
                abbreviation: 'AL',
            },
            {
                name: 'Alaska',
                abbreviation: 'AK',
            },
            {
                name: 'Arizona',
                abbreviation: 'AZ',
            },
            {
                name: 'Arkansas',
                abbreviation: 'AR',
            },
            {
                name: 'California',
                abbreviation: 'CA',
            },
            {
                name: 'Colorado',
                abbreviation: 'CO',
            },
            {
                name: 'Connecticut',
                abbreviation: 'CT',
            },
            {
                name: 'Delaware',
                abbreviation: 'DE',
            },
            {
                name: 'District Of Columbia',
                abbreviation: 'DC',
            },
            {
                name: 'Florida',
                abbreviation: 'FL',
            },
            {
                name: 'Georgia',
                abbreviation: 'GA',
            },
            {
                name: 'Hawaii',
                abbreviation: 'HI',
            },
            {
                name: 'Idaho',
                abbreviation: 'ID',
            },
            {
                name: 'Illinois',
                abbreviation: 'IL',
            },
            {
                name: 'Indiana',
                abbreviation: 'IN',
            },
            {
                name: 'Iowa',
                abbreviation: 'IA',
            },
            {
                name: 'Kansas',
                abbreviation: 'KS',
            },
            {
                name: 'Kentucky',
                abbreviation: 'KY',
            },
            {
                name: 'Louisiana',
                abbreviation: 'LA',
            },
            {
                name: 'Maine',
                abbreviation: 'ME',
            },
            {
                name: 'Maryland',
                abbreviation: 'MD',
            },
            {
                name: 'Massachusetts',
                abbreviation: 'MA',
            },
            {
                name: 'Michigan',
                abbreviation: 'MI',
            },
            {
                name: 'Minnesota',
                abbreviation: 'MN',
            },
            {
                name: 'Mississippi',
                abbreviation: 'MS',
            },
            {
                name: 'Missouri',
                abbreviation: 'MO',
            },
            {
                name: 'Montana',
                abbreviation: 'MT',
            },
            {
                name: 'Nebraska',
                abbreviation: 'NE',
            },
            {
                name: 'Nevada',
                abbreviation: 'NV',
            },
            {
                name: 'New Hampshire',
                abbreviation: 'NH',
            },
            {
                name: 'New Jersey',
                abbreviation: 'NJ',
            },
            {
                name: 'New Mexico',
                abbreviation: 'NM',
            },
            {
                name: 'New York',
                abbreviation: 'NY',
            },
            {
                name: 'North Carolina',
                abbreviation: 'NC',
            },
            {
                name: 'North Dakota',
                abbreviation: 'ND',
            },
            {
                name: 'Ohio',
                abbreviation: 'OH',
            },
            {
                name: 'Oklahoma',
                abbreviation: 'OK',
            },
            {
                name: 'Oregon',
                abbreviation: 'OR',
            },
            {
                name: 'Pennsylvania',
                abbreviation: 'PA',
            },
            {
                name: 'Rhode Island',
                abbreviation: 'RI',
            },
            {
                name: 'South Carolina',
                abbreviation: 'SC',
            },
            {
                name: 'South Dakota',
                abbreviation: 'SD',
            },
            {
                name: 'Tennessee',
                abbreviation: 'TN',
            },
            {
                name: 'Texas',
                abbreviation: 'TX',
            },
            {
                name: 'Utah',
                abbreviation: 'UT',
            },
            {
                name: 'Vermont',
                abbreviation: 'VT',
            },
            {
                name: 'Virginia',
                abbreviation: 'VA',
            },
            {
                name: 'Washington',
                abbreviation: 'WA',
            },
            {
                name: 'West Virginia',
                abbreviation: 'WV',
            },
            {
                name: 'Wisconsin',
                abbreviation: 'WI',
            },
            {
                name: 'Wyoming',
                abbreviation: 'WY',
            },
        ];
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var Store = /** @class */ (function () {
            function Store($q) {
                this.$q = $q;
                this.localStores = {};
                this.sync();
            }
            Store.prototype.sync = function () {
                if (!this.syncStorage) {
                    this.syncStorage = new SyncStore();
                }
                return this.syncStorage;
            };
            Store.prototype.async = function (name) {
                if (!this.localStores[name]) {
                    this.localStores[name] = localforage.createInstance({ name: 'consolo.' + name });
                }
                return this.localStores[name];
            };
            Store.prototype.local = function (name) {
                return this.async(name);
            };
            Store.prototype.checkSafariUpgrade = function () {
                var _this = this;
                console.warn('Upgraded to localforage 1.5.0');
                var isSafari = typeof openDatabase !== 'undefined' &&
                    /(Safari|iPhone|iPad|iPod)/.test(navigator.userAgent) &&
                    !/Chrome/.test(navigator.userAgent) &&
                    !/BlackBerry/.test(navigator.platform);
                var hasFetch = typeof fetch === 'function' && fetch.toString().indexOf('[native code') !== -1;
                if (isSafari && hasFetch) {
                    var dbNames = ['app', 'persistence', 'cache'];
                    var updateArray_1 = [];
                    //check for existence of webSql db
                    dbNames.forEach(function (name) {
                        var dbPromise = _this.$q.defer();
                        updateArray_1.push(dbPromise.promise);
                        var oldDb = localforage.createInstance({
                            name: 'consolo.' + name,
                            driver: localforage.WEBSQL,
                            storeName: 'keyvaluepairs',
                        });
                        oldDb
                            .keys()
                            .then(function (keys) {
                            if (keys.length) {
                                var newDb_1 = localforage.createInstance({ name: 'consolo.' + name });
                                var keyArray_1 = [];
                                console.warn('Moving consolo.' + name + ' from webSQL to IndexedDb');
                                keys.forEach(function (key) {
                                    var keyPromise = _this.$q.defer();
                                    keyArray_1.push(keyPromise.promise);
                                    oldDb.getItem(key).then(function (value) {
                                        newDb_1
                                            .setItem(key, value)
                                            .then(function () {
                                            keyPromise.resolve();
                                        })
                                            .catch(function (error) {
                                            console.warn('Error setting key: ' + key);
                                            console.warn(error);
                                            keyPromise.resolve();
                                        });
                                    });
                                });
                                _this.$q.all(keyArray_1).then(function () {
                                    console.log('All keys for consolo.' + name + ' migrated, clearing old db');
                                    oldDb
                                        .clear()
                                        .then(function () {
                                        console.log('consolo.' + name + ' cleared');
                                        dbPromise.resolve();
                                    })
                                        .catch(function (error) {
                                        console.warn('Error clearing consolo.' + name);
                                        console.warn(error);
                                        dbPromise.resolve();
                                    });
                                });
                            }
                            else {
                                dbPromise.resolve();
                            }
                        })
                            .catch(function (error) {
                            console.warn('Error retrieving keys for consolo.' + name);
                            console.warn(error);
                            dbPromise.resolve();
                        });
                    });
                    return this.$q.all(updateArray_1).then(function () {
                        return _this.$q.when(true);
                    });
                }
                else {
                    return this.$q.when(true);
                }
            };
            Store.$inject = ['$q'];
            return Store;
        }());
        common.Store = Store;
        var SyncStore = /** @class */ (function () {
            function SyncStore() {
            }
            SyncStore.prototype.getItem = function (name) {
                if (!window.localStorage) {
                    return null;
                }
                try {
                    return JSON.parse(localStorage.getItem(name));
                }
                catch (e) {
                    console.warn(e);
                    return null;
                }
            };
            SyncStore.prototype.setItem = function (name, value) {
                if (!window.localStorage) {
                    return null;
                }
                try {
                    localStorage.setItem(name, JSON.stringify(value));
                    return localStorage.getItem(name);
                }
                catch (e) {
                    console.warn(e);
                    return null;
                }
            };
            SyncStore.prototype.removeItem = function (name) {
                if (!window.localStorage) {
                    return null;
                }
                try {
                    var temp = this.getItem(name);
                    localStorage.removeItem(name);
                    return temp;
                }
                catch (e) {
                    console.warn(e);
                    return null;
                }
            };
            return SyncStore;
        }());
        angular.module('consolo').service('Store', Store);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var directives;
    (function (directives) {
        var SyncHeight = /** @class */ (function () {
            function SyncHeight($interval, $window) {
                this.$interval = $interval;
                this.$window = $window;
                this.restrict = 'A';
                this.scope = {
                    baseSelector: '@',
                    targetSelector: '@',
                };
            }
            SyncHeight.prototype.link = function ($scope, element, attributes) {
                var _this = this;
                var baseElem = element[0].querySelector($scope.baseSelector);
                var targetElem = element[0].querySelector($scope.targetSelector);
                var setEqualHeight = function (baseElem, targetElem) {
                    targetElem.style.height = baseElem.offsetHeight > 0 ? baseElem.offsetHeight + "px" : 'auto';
                };
                var intervalPromise = this.$interval(function () { return setEqualHeight(baseElem, targetElem); }, 250, 4);
                var resizeHandler = function (e) {
                    setEqualHeight(baseElem, targetElem);
                };
                this.$window.addEventListener('resize', resizeHandler);
                element.on('$destroy', function () {
                    _this.$window.removeEventListener('resize', resizeHandler);
                    _this.$interval.cancel(intervalPromise);
                });
            };
            return SyncHeight;
        }());
        angular
            .module('consolo')
            .directive('syncHeight', [
            '$interval',
            '$window',
            function ($interval, $window) { return new SyncHeight($interval, $window); },
        ]);
    })(directives = consolo.directives || (consolo.directives = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var TeamsDataTableService = /** @class */ (function (_super) {
            __extends(TeamsDataTableService, _super);
            function TeamsDataTableService($http) {
                var _this = _super.call(this, $http) || this;
                _this.apiSelectUrl = '/api/v1/teams';
                return _this;
            }
            TeamsDataTableService.$inject = ['$http'];
            return TeamsDataTableService;
        }(csg.datatable.ApiService));
        services.TeamsDataTableService = TeamsDataTableService;
        angular.module('consolo').service('TeamsDataTableService', TeamsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var TeamsService = /** @class */ (function () {
            function TeamsService($http) {
                this.$http = $http;
            }
            TeamsService.prototype.getAllTeams = function () {
                return this.$http
                    .get('/api/v1/teams')
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.getSingleTeam = function (teamId) {
                return this.$http
                    .get('/api/v1/teams/' + teamId)
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.addNewTeam = function (name) {
                var nameObj = { name: name };
                return this.$http
                    .post('/api/v1/teams', nameObj)
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.updateTeam = function (teamId, name) {
                var nameObj = { name: name };
                return this.$http
                    .patch('/api/v1/teams/' + teamId, nameObj)
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.deleteTeam = function (teamId) {
                return this.$http
                    .delete('/api/v1/teams/' + teamId)
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.addTeamMember = function (teamId, userId) {
                var userObj = { user_id: userId };
                return this.$http
                    .post('/api/v1/teams/' + teamId + '/add_user', userObj)
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.removeTeamMember = function (teamId, userId) {
                var userObj = { user_id: userId };
                return this.$http
                    .post('/api/v1/teams/' + teamId + '/remove_user', userObj)
                    .then(function (response) {
                    return response;
                });
            };
            TeamsService.prototype.exportTeamsToCSV = function () {
                return this.$http.get('/api/v1/teams/export');
            };
            TeamsService.$inject = ['$http'];
            return TeamsService;
        }());
        services.TeamsService = TeamsService;
        angular.module('consolo').service('TeamsService', TeamsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var TextLimitComponent = /** @class */ (function () {
            function TextLimitComponent() {
                this.transclude = true;
                this.bindings = {
                    text: '<',
                    limit: '@',
                };
                this.controller = TextLimitController;
                this.template = "\n      <div class=\"text-limit\">\n        <ng-transclude></ng-transclude>\n        <div\n          layout=\"row\"\n          layout-align=\"end center\"\n          class=\"margin-top-sm\"\n          ng-style=\"$ctrl.overLimit && {'color': 'rgb(244,67,54)'}\"\n          ng-if=\"$ctrl.text.length\"\n        >\n          <span class=\"margin-right-xs\">Characters Remaining:</span>\n          <span>{{ $ctrl.remaining }}</span>\n        </div>\n      </div>\n    ";
            }
            return TextLimitComponent;
        }());
        var TextLimitController = /** @class */ (function () {
            function TextLimitController() {
                this.text = '';
            }
            TextLimitController.prototype.$onInit = function () {
                this.update();
            };
            TextLimitController.prototype.$onChanges = function () {
                this.update();
            };
            TextLimitController.prototype.update = function () {
                this.remaining = this.limit - this.text.length;
                this.overLimit = this.remaining < 0;
            };
            return TextLimitController;
        }());
        angular.module('consolo').component('textLimit', new TextLimitComponent());
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('User', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/users/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            current: {
                method: 'GET',
                url: '/api/v1/users/current',
            },
        });
    }
})();

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var UserService = /** @class */ (function () {
            function UserService($http) {
                this.$http = $http;
            }
            UserService.prototype.lookup = function (query) {
                return this.$http
                    .get('/api/v1/lookup/users?q=' + query)
                    .then(function (response) {
                    return response;
                });
            };
            UserService.$inject = ['$http'];
            return UserService;
        }());
        services.UserService = UserService;
        angular.module('consolo').service('UserService', UserService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        /*
         * Core uses wkhtmltopdf to render PDFs of web-client. Since wkhtmltopdf can't inspect angular to see when a route
         * is ready, it (optionally) looks at the value of "window.status" to determine when it's okay to render the PDF.
         */
        var WindowStatus = /** @class */ (function () {
            function WindowStatus($timeout) {
                this.$timeout = $timeout;
            }
            WindowStatus.prototype.readyOnPromise = function (promise) {
                this.cancelTimeout();
                this.readyOnTimeout(2 * 60 * 1000); // in case something goes wrong
                promise.then(this.ready.bind(this), this.ready.bind(this));
            };
            WindowStatus.prototype.readyOnTimeout = function (ms) {
                this.cancelTimeout();
                this.readyTimeout = this.$timeout(this.ready.bind(this), ms);
            };
            WindowStatus.prototype.cancelTimeout = function () {
                if (this.readyTimeout) {
                    this.$timeout.cancel(this.readyTimeout);
                    this.readyTimeout = null;
                }
            };
            WindowStatus.prototype.ready = function () {
                window.status = 'ready';
            };
            WindowStatus.$inject = ['$timeout'];
            return WindowStatus;
        }());
        common.WindowStatus = WindowStatus;
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));
angular.module('consolo').service('WindowStatus', consolo.common.WindowStatus);

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var WkhtmltopdfHelper = /** @class */ (function () {
            function WkhtmltopdfHelper($root, WindowStatus) {
                this.$root = $root;
                this.WindowStatus = WindowStatus;
            }
            WkhtmltopdfHelper.prototype.initialize = function () {
                this.tellWkhtmltopdfWhenWindowIsReady();
            };
            WkhtmltopdfHelper.prototype.tellWkhtmltopdfWhenWindowIsReady = function () {
                var _this = this;
                // Triggered *before* any route "resolves" are resolved. This gives routes 45 sec to resolve before PDF-ing (in case there are
                // long-running HTTP requests in the resolvers). Also, it ensures that the doc is marked "ready" even in the event that a
                // resolver fails.
                this.$root.$on('$stateChangeStart', function () {
                    _this.WindowStatus.readyOnTimeout(45 * 1000);
                });
                // Triggered *after* any route "resolves" are resolved and gives controllers 10 sec to render their views. So in many cases this
                // will "just work." But if your controller has a bunch of async stuff inside it, you might want to use WindowStatus.waitOnPromise
                // in your controller.
                this.$root.$on('$stateChangeSuccess', function () {
                    _this.WindowStatus.readyOnTimeout(10 * 1000);
                });
            };
            WkhtmltopdfHelper.$inject = ['$rootScope', 'WindowStatus'];
            return WkhtmltopdfHelper;
        }());
        common.WkhtmltopdfHelper = WkhtmltopdfHelper;
        angular.module('consolo').service('WkhtmltopdfHelper', WkhtmltopdfHelper);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    'use strict';
    var ContactUsStates = /** @class */ (function () {
        function ContactUsStates($stateProvider) {
            $stateProvider.state('contact-us', {
                url: '/contact_us',
                data: { title: 'Contact Us', hideSidebar: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/contact-us/contact-us.html',
                    },
                },
            });
        }
        ContactUsStates.$inject = ['$stateProvider'];
        return ContactUsStates;
    }());
    angular.module('consolo').config(ContactUsStates);
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AgencyCqmDataTableService = /** @class */ (function (_super) {
            __extends(AgencyCqmDataTableService, _super);
            function AgencyCqmDataTableService($httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.apiSelectUrl = '/api/v1/cqm_templates/agency';
                return _this;
            }
            AgencyCqmDataTableService.$inject = ['$http'];
            return AgencyCqmDataTableService;
        }(csg.datatable.ApiService));
        services.AgencyCqmDataTableService = AgencyCqmDataTableService;
        angular
            .module('consolo')
            .service('agencyCqmDataTableService', AgencyCqmDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AgencyCqmController = /** @class */ (function () {
            function AgencyCqmController($state, $location, $timeout, CqmService, agencyCqmDataTableService, globalCqmTemplateDataTableService, $interval, logger, Session, $q, $mdDialog, globalCqmTemplates, selectedTemplates) {
                this.$state = $state;
                this.$location = $location;
                this.$timeout = $timeout;
                this.CqmService = CqmService;
                this.agencyCqmDataTableService = agencyCqmDataTableService;
                this.globalCqmTemplateDataTableService = globalCqmTemplateDataTableService;
                this.$interval = $interval;
                this.logger = logger;
                this.Session = Session;
                this.$q = $q;
                this.$mdDialog = $mdDialog;
                this.globalCqmTemplates = globalCqmTemplates;
                this.selectedTemplates = selectedTemplates;
                this.permissions = {};
                this.globalCqmTemplates = this.globalCqmTemplates || [];
                this.selectedTemplates = this.selectedTemplates || [];
                this.filters = this.filters || new consolo.domain.CqmTemplateFilter();
                this.filters.year = this.filters.year || new Date().getFullYear();
                this.filters.active = true;
                this.loadPermissions();
                this.initializeAgencyCqmGrid();
                this.query();
                this.yearOptions = [
                    { year: 2020 },
                    { year: 2021 },
                    { year: 2022 },
                    { year: 2023 }
                ];
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
            }
            AgencyCqmController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.agencyCqmDataTableService.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    _this.$interval.cancel(_this.apiInterval);
                    _this.CqmService.getAgencyCqmTemplates(_this.filters).finally(function () { });
                    _this.isLoading = false;
                    if (_this.agencyCqmDataTableService.tableControl &&
                        _this.agencyCqmDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.agencyCqmDataTableService.tableControl.loadSelectData().finally(function () {
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            AgencyCqmController.prototype.reset = function () {
                this.query();
            };
            AgencyCqmController.prototype.loadPermissions = function () {
                var permission_chain = [];
                var defer = this.$q.defer();
                this.$q.all(permission_chain).finally(function () {
                    defer.resolve(true);
                });
                return defer.promise;
            };
            AgencyCqmController.prototype.closeDialog = function (send) {
                this.$mdDialog.hide(send);
            };
            AgencyCqmController.prototype.templateActive = function (template) {
                var agency_id = this.Session.get().agency.id;
                var active = false;
                template.agency_cqm_template_relationships.forEach(function (r) {
                    if (r.agency_id === agency_id) {
                        active = r.active;
                    }
                });
                return active;
            };
            AgencyCqmController.prototype.showViewModal = function (template, $event) {
                var url = '/y/templates/cqms/global-cqm-template-view-modal.html';
                var dialogOptions = {
                    controller: 'GlobalCqmTemplateController as vm',
                    templateUrl: url,
                    targetEvent: $event,
                    fullscreen: true,
                    clickOutsideToClose: true,
                    bindToController: true,
                    locals: { global_cqm_template: template, cqm_template_option: null, readonly: true },
                };
                this.$mdDialog.show(dialogOptions);
            };
            AgencyCqmController.prototype.showAddModal = function ($event) {
                var _this = this;
                if (!this.CqmService.agencyCreatePermission) {
                    this.logger.error('Please contact your administrator to grant permissions.', 'You must have the "Assign CQM Templates" permission to add additional clinical quality measures for tracking in your agency.');
                }
                else {
                    var agency_id_1 = this.Session.get().agency.id;
                    var templates_1 = [];
                    var year_1 = this.filters.year;
                    this.CqmService.getGlobalCqmTemplates(this.filters).then(function (response) {
                        _this.globalCqmTemplates = [];
                        response.data.forEach(function (template) {
                            if ((!template.agency_cqm_template_relationships ||
                                !template.agency_cqm_template_relationships.length ||
                                !template.agency_cqm_template_relationships.some(function (r) { return r.agency_id === agency_id_1 && r.year === year_1; }))
                                && template.active) {
                                templates_1.push(template);
                            }
                        });
                        _this.globalCqmTemplates = templates_1;
                    }).finally(function () {
                        _this.selectedTemplates = [];
                        _this.$mdDialog
                            .show({
                            targetEvent: $event,
                            templateUrl: '/y/templates/cqms/agency-cqm-add-modal.html',
                            controller: 'AgencyCqmController as vm',
                            fullscreen: true,
                            clickOutsideToClose: true,
                            bindToController: true,
                            locals: {
                                globalCqmTemplates: templates_1,
                                selectedTemplates: _this.selectedTemplates,
                                filters: _this.filters,
                                screen: screen
                            },
                        });
                    });
                }
            };
            AgencyCqmController.prototype.addAgencyCqmTemplates = function (templates) {
                var _this = this;
                if (templates.length) {
                    var template_ids = templates.map(function (m) { return m.id; });
                    this.CqmService.addAgencyCqmTemplates(template_ids, this.filters.year || new Date().getFullYear()).then(function (response) {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template(s) added.', 'Success:');
                    }).catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    });
                }
                this.$mdDialog.hide();
            };
            AgencyCqmController.prototype.activateAgencyCqmTemplate = function (template) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to activate this MIPS Template?')
                    .ariaLabel('Activate MIPS Templates')
                    .ok('Activate')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.isLoading = true;
                    var arr = [];
                    arr.push(template.id);
                    _this.CqmService
                        .activateAgencyCqmTemplates(arr)
                        .then(function () {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template ' + template.title + ' has been activated.', 'Success:');
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error(error.errors.join(', '), 'Error:');
                    });
                });
            };
            AgencyCqmController.prototype.deactivateAgencyCqmTemplate = function (template) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to deactivate this MIPS Template?')
                    .ariaLabel('Deactivate MIPS Templates')
                    .ok('Deactivate')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.isLoading = true;
                    var arr = [];
                    arr.push(template.id);
                    _this.CqmService
                        .deactivateAgencyCqmTemplates(arr)
                        .then(function () {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template ' + template.title + ' has been deactivated.', 'Success:');
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error(error.errors.join(', '), 'Error:');
                    });
                });
            };
            AgencyCqmController.prototype.deleteAgencyCqmTemplate = function (template) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to delete this MIPS Template?')
                    .ariaLabel('Delete MIPS Templates')
                    .ok('Delete')
                    .cancel('Cancel');
                var arr = [];
                arr.push(template.id);
                this.$mdDialog.show(confirm).then(function () {
                    _this.isLoading = true;
                    _this.CqmService
                        .deleteAgencyCqmTemplates(arr)
                        .then(function () {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template ' + template.title + ' has been deleted.', 'Success:');
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error(error.errors.join(', '), 'Error:');
                    });
                });
            };
            AgencyCqmController.prototype.initializeAgencyCqmGrid = function () {
                var _this = this;
                this.agencyCqmLayout = {
                    id_field: 'id',
                    columns: [
                        { title: 'MIPS Code', field: 'quality_id' },
                        {
                            title: 'Type',
                            field: 'encounter_based',
                            formatValueFn: function (data, col, value) {
                                return _this.CqmService.typeDescription(value);
                            },
                        },
                        { title: 'Name', field: 'title' },
                        { title: 'Description', field: 'description' },
                        {
                            title: 'Status',
                            field: 'active',
                            formatValueFn: function (status) {
                                return _this.templateActive(status) ? 'Active' : 'Inactive';
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (template, $event) {
                                    _this.showViewModal(template, $event);
                                },
                            },
                            {
                                label: 'Activate',
                                icon: 'check_circle',
                                callbackFn: function (template) {
                                    return _this.activateAgencyCqmTemplate(template);
                                },
                                conditionalHideFn: function (data) {
                                    return !_this.CqmService.agencyCreatePermission || _this.templateActive(data);
                                },
                            },
                            {
                                label: 'Deactivate',
                                icon: 'delete',
                                callbackFn: function (template) {
                                    return _this.deactivateAgencyCqmTemplate(template);
                                },
                                conditionalHideFn: function (data) {
                                    return !_this.CqmService.agencyCreatePermission || !_this.templateActive(data);
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete_forever',
                                callbackFn: function (template) {
                                    return _this.deleteAgencyCqmTemplate(template);
                                },
                                conditionalHideFn: function (data) {
                                    return !_this.CqmService.agencyCreatePermission || !_this.templateActive(data);
                                },
                            },
                        ],
                    },
                };
                this.cqmSelectLayout = {
                    id_field: 'id',
                    options: {
                        allowRowSelect: true,
                    },
                    columns: [
                        { title: 'MIPS Code', field: 'quality_id' },
                        {
                            title: 'Type',
                            field: 'encounter_based',
                            formatValueFn: function (data, col, value) {
                                return _this.CqmService.typeDescription(value);
                            },
                        },
                        { title: 'Name', field: 'title' },
                        { title: 'Description', field: 'description' },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (agencyCqmTemplate, $event) {
                                    _this.showViewModal(agencyCqmTemplate, $event);
                                },
                            },
                        ],
                    },
                };
            };
            AgencyCqmController.$inject = [
                '$state',
                '$location',
                '$timeout',
                'CqmService',
                'agencyCqmDataTableService',
                'globalCqmTemplateDataTableService',
                '$interval',
                'logger',
                'SessionService',
                '$q',
                '$mdDialog',
                'globalCqmTemplates',
                'selectedTemplates',
            ];
            return AgencyCqmController;
        }());
        angular.module('consolo').controller('AgencyCqmController', AgencyCqmController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CqmService = /** @class */ (function () {
            function CqmService($http, PermissionsService) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.updatePermission = false;
                this.viewPermission = false;
                this.signPermission = false;
                this.agencyDestroyPermission = false;
                this.agencyCreatePermission = false;
                this.agencyViewPermission = false;
                this.global = false;
                this.updatePermission = this.PermissionsService.can('update', 'cqm_templates');
                this.viewPermission = this.PermissionsService.can('show', 'cqm_templates');
                this.signPermission = this.PermissionsService.can('sign', 'cqms');
                this.agencyDestroyPermission = this.PermissionsService.can('destroy', 'agency_cqm_template_relationships');
                this.agencyCreatePermission = this.PermissionsService.can('create', 'agency_cqm_template_relationships');
                this.agencyViewPermission = this.PermissionsService.can('show', 'agency_cqm_template_relationships');
                this.cqmTemplateTypes = [
                    { id: 0, description: 'Patient-Based' },
                    { id: 1, description: 'Encounter-Based' },
                ];
            }
            CqmService.prototype.getGlobalCqmTemplates = function (filter) {
                return this.$http({
                    url: "/api/v1/cqm_templates",
                    method: 'GET',
                    params: { filter: filter },
                });
            };
            CqmService.prototype.createGlobalCqmTemplate = function (templateOptions) {
                return this.$http({
                    url: "/api/v1/cqm_templates",
                    method: 'POST',
                    data: templateOptions,
                });
            };
            CqmService.prototype.getGlobalCqmTemplate = function (id) {
                return this.$http({
                    url: "/api/v1/cqm_templates/" + id,
                    method: 'GET',
                });
            };
            CqmService.prototype.updateGlobalCqmTemplate = function (template) {
                return this.$http({
                    url: "/api/v1/cqm_templates/" + template.id,
                    method: 'PATCH',
                    data: template,
                });
            };
            CqmService.prototype.activateGlobalCqmTemplate = function (template) {
                return this.$http({
                    url: "/api/v1/cqm_templates/" + template.id + "/activate",
                    method: 'PATCH',
                });
            };
            CqmService.prototype.deactivateGlobalCqmTemplate = function (template) {
                return this.$http({
                    url: "/api/v1/cqm_templates/" + template.id + "/deactivate",
                    method: 'PATCH',
                });
            };
            CqmService.prototype.deleteGlobalCqmTemplate = function (template) {
                return this.$http({
                    url: "/api/v1/cqm_templates/" + template.id,
                    method: 'DELETE',
                });
            };
            CqmService.prototype.getAgencyCqmTemplates = function (filter) {
                return this.$http({
                    url: "/api/v1/cqm_templates/agency",
                    method: 'GET',
                    params: { filter: filter },
                });
            };
            CqmService.prototype.addAgencyCqmTemplates = function (cqmTemplateIds, year) {
                return this.$http({
                    url: "/api/v1/cqm_templates/agency",
                    method: 'PATCH',
                    params: { 'cqm_template_ids[]': cqmTemplateIds, year: year },
                });
            };
            CqmService.prototype.activateAgencyCqmTemplates = function (cqmTemplateIds) {
                return this.$http({
                    url: "/api/v1/cqm_templates/agency/activate",
                    method: 'PATCH',
                    params: { 'cqm_template_ids[]': cqmTemplateIds, year: new Date().getFullYear() },
                });
            };
            CqmService.prototype.deactivateAgencyCqmTemplates = function (cqmTemplateIds) {
                return this.$http({
                    url: "/api/v1/cqm_templates/agency/deactivate",
                    method: 'PATCH',
                    params: { 'cqm_template_ids[]': cqmTemplateIds, year: new Date().getFullYear() },
                });
            };
            CqmService.prototype.deleteAgencyCqmTemplates = function (cqmTemplateIds) {
                return this.$http({
                    url: "/api/v1/cqm_templates/agency",
                    method: 'DELETE',
                    params: { 'cqm_template_ids[]': cqmTemplateIds, year: new Date().getFullYear() },
                });
            };
            CqmService.prototype.getPatientCqmTemplates = function (patientId) {
                return this.$http({
                    url: "/api/v1/cqm_templates/patient",
                    method: 'GET',
                    params: { patient_id: patientId, year: new Date().getFullYear() },
                });
            };
            CqmService.prototype.addPatientCqm = function (measure, patientId, year) {
                return this.$http({
                    url: "/api/v1/cqms/",
                    method: 'POST',
                    params: { cqm_template_id: measure.id, patient_id: patientId, year: new Date().getFullYear() }
                });
            };
            CqmService.prototype.updatePatientCqm = function (measure) {
                return this.$http({
                    url: "/api/v1/cqms/" + measure.id,
                    method: 'PATCH',
                    params: { comments: measure.comments, cqm_template_option_id: measure.cqm_template_option_id }
                });
            };
            CqmService.prototype.attachSignature = function (cqmId) {
                return this.$http({
                    url: "/api/v1/cqms/" + cqmId + "/signatures",
                    method: 'POST'
                });
            };
            CqmService.prototype.findUsersByRole = function (role_id, query) {
                return this.$http
                    .get('/api/v1/users', { params: { role_id: role_id, q: query } })
                    .then(function (response) {
                    return response.data;
                });
            };
            CqmService.prototype.getDhitReportsUrl = function (agency_id, user_id) {
                return this.$http
                    .get('/api/v1/cqms/dhit/dhit_reporting_url', { params: { agency_id: agency_id, user_id: user_id } }).then(function (response) {
                    return response.data;
                });
            };
            CqmService.prototype.typeDescription = function (encounter_based) {
                return encounter_based ? this.cqmTemplateTypes[1].description : this.cqmTemplateTypes[0].description;
            };
            CqmService.$inject = ['$http', 'PermissionsService'];
            return CqmService;
        }());
        services.CqmService = CqmService;
        angular.module('consolo').service('CqmService', CqmService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular.module('consolo').config(Routes);
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('cqms', {
                url: '/cqms',
                data: { title: 'CQMs', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/cqms/global-cqm-templates.html',
                        controller: 'GlobalCqmTemplateController as vm',
                    },
                },
                resolve: {
                    globalCqmTemplates: function () { return false; },
                    global_cqm_template: function () { return false; },
                    cqm_template_option: function () { return false; },
                    readonly: function () { return false; },
                }
            })
                .state('cqms.new', {
                url: '/new',
                data: { title: 'CQMs', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/cqms/global-cqm-template-edit.html',
                        controller: 'GlobalCqmTemplateController as vm',
                    },
                },
                resolve: {
                    globalCqmTemplates: function () { return false; },
                    global_cqm_template: function () { return false; },
                    cqm_template_option: function () { return false; },
                    readonly: function () { return false; },
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'cqm_templates');
                        },
                    ],
                }
            })
                .state('cqms.edit', {
                url: '/:id/edit',
                data: { title: 'CQMs', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/cqms/global-cqm-template-edit.html',
                        controller: 'GlobalCqmTemplateController as vm',
                    },
                },
                resolve: {
                    globalCqmTemplates: function () { return false; },
                    global_cqm_template: function () { return false; },
                    cqm_template_option: function () { return false; },
                    readonly: function () { return false; },
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'cqm_templates');
                        },
                    ],
                    template: [
                        '$stateParams',
                        'CqmService',
                        function ($stateParams, CqmService) {
                        },
                    ],
                },
            })
                .state('agency-cqms', {
                url: '/agency_cqms',
                data: { title: 'Agency CQMs', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/cqms/agency-cqm.html',
                        controller: 'AgencyCqmController as vm',
                    },
                },
                resolve: {
                    globalCqmTemplates: function () { return false; },
                    selectedTemplates: function () { return false; }
                }
            })
                .state('patients.cqms', {
                url: '/cqms',
                data: { title: 'MIPS / CQM' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/cqms/patient-cqm.html',
                        controller: 'PatientCqmController as vm'
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.PatientCqmDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    }
                },
                resolve: {
                    measureSets: function () { return false; }
                }
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var GlobalCqmTemplateDataTableService = /** @class */ (function (_super) {
            __extends(GlobalCqmTemplateDataTableService, _super);
            function GlobalCqmTemplateDataTableService($httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.apiSelectUrl = '/api/v1/cqm_templates';
                return _this;
            }
            GlobalCqmTemplateDataTableService.$inject = ['$http'];
            return GlobalCqmTemplateDataTableService;
        }(csg.datatable.ApiService));
        services.GlobalCqmTemplateDataTableService = GlobalCqmTemplateDataTableService;
        angular
            .module('consolo')
            .service('globalCqmTemplateDataTableService', GlobalCqmTemplateDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var GlobalCqmTemplateController = /** @class */ (function () {
            function GlobalCqmTemplateController($state, $location, $timeout, CqmService, globalCqmTemplateDataTableService, options, $interval, logger, $q, $mdDialog, //ng.material.IDialogService,
            Session, $stateParams, global_cqm_template, cqm_template_option, readonly) {
                var _this = this;
                this.$state = $state;
                this.$location = $location;
                this.$timeout = $timeout;
                this.CqmService = CqmService;
                this.globalCqmTemplateDataTableService = globalCqmTemplateDataTableService;
                this.options = options;
                this.$interval = $interval;
                this.logger = logger;
                this.$q = $q;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.$stateParams = $stateParams;
                this.global_cqm_template = global_cqm_template;
                this.cqm_template_option = cqm_template_option;
                this.readonly = readonly;
                this.permissions = {};
                this.loadPermissions();
                this.initializeGlobalCqmTemplateGrid();
                this.query();
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                if ($stateParams.id) {
                    this.CqmService.getGlobalCqmTemplate($stateParams.id).then(function (response) {
                        _this.global_cqm_template = response.data;
                    });
                }
                else {
                    this.global_cqm_template = this.global_cqm_template || {};
                }
            }
            GlobalCqmTemplateController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.filters = new consolo.domain.CqmTemplateFilter();
                this.globalCqmTemplateDataTableService.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    _this.$interval.cancel(_this.apiInterval);
                    _this.CqmService.getGlobalCqmTemplates(_this.filters).finally(function () { });
                    _this.isLoading = false;
                    if (_this.globalCqmTemplateDataTableService.tableControl &&
                        _this.globalCqmTemplateDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.globalCqmTemplateDataTableService.tableControl.loadSelectData().finally(function () {
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            GlobalCqmTemplateController.prototype.reset = function () {
                this.globalCqmTemplateDataTableService.params = this.filters;
                this.query();
            };
            GlobalCqmTemplateController.prototype.loadPermissions = function () {
                var permission_chain = [];
                var defer = this.$q.defer();
                this.$q.all(permission_chain).finally(function () {
                    defer.resolve(true);
                });
                return defer.promise;
            };
            GlobalCqmTemplateController.prototype.templateActive = function (template) {
                return template.active ? 'Active' : 'Inactive';
            };
            GlobalCqmTemplateController.prototype.yearsActive = function (template) {
                if (template.start_year) {
                    if (template.end_year) {
                        return template.start_year + "-" + template.end_year;
                    }
                    else {
                        return template.start_year + "-";
                    }
                }
                else {
                    return '';
                }
            };
            GlobalCqmTemplateController.prototype.closeTemplateDialog = function (val) {
                this.$mdDialog.hide(val);
            };
            GlobalCqmTemplateController.prototype.closeOptionDialog = function (val) {
                this.$mdDialog.hide(val);
            };
            GlobalCqmTemplateController.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                // toIndex determines where the object is moving based on whether up or down arrows are clicked
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
                this.setPosition(array);
                return [].concat(array);
            };
            GlobalCqmTemplateController.prototype.setPosition = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i + 1;
                }
            };
            GlobalCqmTemplateController.prototype.showViewModal = function (template, $event) {
                var url = '/y/templates/cqms/global-cqm-template-view-modal.html';
                var dialogOptions = {
                    controller: 'GlobalCqmTemplateController as vm',
                    templateUrl: url,
                    targetEvent: $event,
                    fullscreen: true,
                    clickOutsideToClose: true,
                    bindToController: true,
                    locals: { global_cqm_template: template, cqm_template_option: null, readonly: true },
                };
                this.$mdDialog.show(dialogOptions);
            };
            GlobalCqmTemplateController.prototype.editGlobalCqmTemplate = function (template, $event) {
                this.global_cqm_template = template;
                if (this.global_cqm_template) {
                    this.$state.go('cqms.edit', { id: this.global_cqm_template.id });
                }
                else {
                    this.$state.go('cqms.new');
                }
            };
            GlobalCqmTemplateController.prototype.editCqmTemplateOption = function (option, $event) {
                var _this = this;
                var template_options = this.global_cqm_template.cqm_template_options;
                var url = '/y/templates/cqms/global-cqm-template-option-edit.html';
                var dialogOptions = {
                    controller: 'GlobalCqmTemplateController as vm',
                    templateUrl: url,
                    targetEvent: $event,
                    fullscreen: true,
                    bindToController: true,
                    locals: { global_cqm_template: this.global_cqm_template, cqm_template_option: option, readonly: false },
                };
                this.$mdDialog.show(dialogOptions).then(function (option) {
                    if (option.position) {
                        _this.global_cqm_template.cqm_template_options = [];
                        _this.global_cqm_template.cqm_template_options =
                            _this.global_cqm_template.cqm_template_options.concat(template_options.slice(0, option.position - 1));
                        _this.global_cqm_template.cqm_template_options =
                            _this.global_cqm_template.cqm_template_options.concat(option);
                        _this.global_cqm_template.cqm_template_options =
                            _this.global_cqm_template.cqm_template_options.concat(template_options.slice(option.position));
                    }
                    else {
                        _this.global_cqm_template.cqm_template_options = [];
                        if (template_options) {
                            _this.global_cqm_template.cqm_template_options =
                                _this.global_cqm_template.cqm_template_options.concat(template_options);
                        }
                        _this.global_cqm_template.cqm_template_options =
                            _this.global_cqm_template.cqm_template_options.concat(option);
                        option.position = _this.global_cqm_template.cqm_template_options.length;
                    }
                    _this.setPosition(_this.global_cqm_template.cqm_template_options);
                    console.log(_this.global_cqm_template.cqm_template_options);
                });
            };
            GlobalCqmTemplateController.prototype.save = function () {
                var _this = this;
                if (this.global_cqm_template.id) {
                    this.CqmService.updateGlobalCqmTemplate(this.global_cqm_template).then(function (response) {
                        _this.isLoading = false;
                        _this.logger.success('MIPS Template ' + _this.global_cqm_template.title + ' has been updated.', 'Success:');
                        _this.$state.go('cqms', null, { reload: true });
                    });
                }
                else {
                    this.global_cqm_template.active = false;
                    this.CqmService.createGlobalCqmTemplate(this.global_cqm_template).then(function (response) {
                        _this.isLoading = false;
                        _this.logger.success('MIPS Template ' + _this.global_cqm_template.title + ' has been created.', 'Success:');
                        _this.$state.go('cqms', null, { reload: true });
                    });
                }
            };
            GlobalCqmTemplateController.prototype.activateGlobalCqmTemplate = function (template) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to activate this MIPS Template?')
                    .ariaLabel('Activate MIPS Template')
                    .ok('Activate')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.isLoading = true;
                    _this.CqmService
                        .activateGlobalCqmTemplate(template)
                        .then(function (response) {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template ' + template.title + ' has been activated.', 'Success:');
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error(error.data.errors.join(', '), 'Error:');
                    });
                });
            };
            GlobalCqmTemplateController.prototype.deactivateGlobalCqmTemplate = function (template) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to deactivate this MIPS Template?')
                    .ariaLabel('Deactivate MIPS Template')
                    .ok('Deactivate')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.isLoading = true;
                    _this.CqmService
                        .deactivateGlobalCqmTemplate(template)
                        .then(function (response) {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template ' + template.title + ' has been deactivated.', 'Success:');
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error(error.data.errors.join(', '), 'Error:');
                    });
                });
            };
            GlobalCqmTemplateController.prototype.deleteGlobalCqmTemplate = function (template) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to delete this MIPS Template?')
                    .ariaLabel('Delete MIPS Template')
                    .ok('Delete')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.isLoading = true;
                    _this.CqmService
                        .deleteGlobalCqmTemplate(template)
                        .then(function (response) {
                        _this.isLoading = false;
                        _this.query();
                        _this.logger.success('MIPS Template ' + template.title + ' has been deleted.', 'Success:');
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error(error.data.errors.join(', '), 'Error:');
                    });
                });
            };
            GlobalCqmTemplateController.prototype.deleteCqmTemplateOption = function (option) {
                var _this = this;
                var template_options = this.global_cqm_template.cqm_template_options;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to delete this Template Option?')
                    .ariaLabel('Delete Template Option')
                    .ok('Delete')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.isTemplateLoading = true;
                    _this.global_cqm_template.cqm_template_options = [];
                    _this.global_cqm_template.cqm_template_options =
                        _this.global_cqm_template.cqm_template_options.concat(template_options.slice(0, option.position - 1));
                    _this.global_cqm_template.cqm_template_options =
                        _this.global_cqm_template.cqm_template_options.concat(template_options.slice(option.position));
                    _this.setPosition(_this.global_cqm_template.cqm_template_options);
                    _this.isTemplateLoading = false;
                });
            };
            GlobalCqmTemplateController.prototype.clearCqmTemplate = function () {
                this.global_cqm_template.active = false;
                this.global_cqm_template.title = '';
                this.global_cqm_template.description = '';
                this.global_cqm_template.data_source_url = '';
                this.global_cqm_template.additional_information = '';
                this.global_cqm_template.cqm_template_options = [];
                this.global_cqm_template.cqm_template_rules = [];
                this.global_cqm_template.agency_cqm_template_relationships = [];
            };
            GlobalCqmTemplateController.prototype.initializeGlobalCqmTemplateGrid = function () {
                var _this = this;
                this.globalCqmTemplateLayout = {
                    id_field: 'id',
                    columns: [
                        { title: 'MIPS Code', field: 'quality_id' },
                        {
                            title: 'Type',
                            field: 'encounter_based',
                            formatValueFn: function (data, col, value) {
                                return _this.CqmService.typeDescription(value);
                            },
                        },
                        { title: 'Name', field: 'title' },
                        { title: 'Description', field: 'description' },
                        {
                            title: 'Status',
                            field: 'active',
                            formatValueFn: function (status) {
                                return _this.templateActive(status);
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (globalCqmTemplate, $event) {
                                    _this.editGlobalCqmTemplate(globalCqmTemplate, $event);
                                },
                                conditionalHideFn: function (data) {
                                    // Eventually we'll need to fix this so you can edit years on existing templates
                                    return !_this.CqmService.updatePermission || data.agency_cqm_template_relationships.length;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (globalCqmTemplate, $event) {
                                    _this.showViewModal(globalCqmTemplate, $event);
                                },
                            },
                            {
                                label: 'Activate',
                                icon: 'check_circle',
                                callbackFn: function (globalCqmTemplate) {
                                    return _this.activateGlobalCqmTemplate(globalCqmTemplate);
                                },
                                conditionalHideFn: function (data) {
                                    return !_this.CqmService.updatePermission || data.active || data.agency_cqm_template_relationships.length;
                                },
                            },
                            {
                                label: 'Deactivate',
                                icon: 'delete',
                                callbackFn: function (globalCqmTemplate) {
                                    return _this.deactivateGlobalCqmTemplate(globalCqmTemplate);
                                },
                                conditionalHideFn: function (data, $event) {
                                    return !_this.CqmService.updatePermission || !data.active || data.agency_cqm_template_relationships.length;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete_forever',
                                callbackFn: function (globalCqmTemplate) {
                                    return _this.deleteGlobalCqmTemplate(globalCqmTemplate);
                                },
                                conditionalHideFn: function (data, $event) {
                                    // Need to check if the Template is in use
                                    return !_this.CqmService.updatePermission || data.agency_cqm_template_relationships.length;
                                },
                            },
                        ],
                    },
                };
                this.templateOptionLayout = {
                    id_field: 'id',
                    columns: [
                        { title: 'Description', field: 'description' },
                        { title: 'Numerator', field: 'numerator' },
                        { title: 'Modifier', field: 'numerator_modifier' },
                        {
                            title: 'Move Up',
                            field: 'move_up',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (templateOptionItem) {
                                return templateOptionItem.position > 1 ? '<span class="material-icons">expand_less</span>' : '';
                            },
                            callbackFn: function (templateOptionItem) {
                                _this.global_cqm_template.cqm_template_options = _this.moveObjectInArray(_this.global_cqm_template.cqm_template_options, templateOptionItem.position - 1, 'up');
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.CqmService.updatePermission || _this.readonly;
                            },
                        },
                        {
                            title: 'Move Down',
                            field: 'move_down',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (templateOptionItem) {
                                return templateOptionItem.position < _this.global_cqm_template.cqm_template_options.length
                                    ? '<span class="material-icons">expand_more</span>' : '';
                            },
                            callbackFn: function (templateOptionItem) {
                                _this.global_cqm_template.cqm_template_options = _this.moveObjectInArray(_this.global_cqm_template.cqm_template_options, templateOptionItem.position - 1, 'down');
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.CqmService.updatePermission || _this.readonly;
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (cqmTemplateOption, $event) {
                                    _this.editCqmTemplateOption(cqmTemplateOption, $event);
                                },
                                conditionalHideFn: function () {
                                    return !_this.CqmService.updatePermission || _this.readonly;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete_forever',
                                callbackFn: function (cqmTemplateOption) {
                                    _this.deleteCqmTemplateOption(cqmTemplateOption);
                                },
                                conditionalHideFn: function () {
                                    return !_this.CqmService.updatePermission || _this.readonly;
                                },
                            },
                        ],
                    },
                };
                this.templateYearLayout = {
                    id_field: 'id',
                    columns: [
                        { title: 'Year', field: 'year' },
                    ],
                };
            };
            // global_cqm_template: CqmTemplate;
            GlobalCqmTemplateController.$inject = [
                '$state',
                '$location',
                '$timeout',
                'CqmService',
                'globalCqmTemplateDataTableService',
                'options',
                '$interval',
                'logger',
                '$q',
                '$mdDialog',
                'SessionService',
                '$stateParams',
                'global_cqm_template',
                'cqm_template_option',
                'readonly'
            ];
            return GlobalCqmTemplateController;
        }());
        angular.module('consolo').controller('GlobalCqmTemplateController', GlobalCqmTemplateController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PatientCqmDataTableService = /** @class */ (function (_super) {
            __extends(PatientCqmDataTableService, _super);
            function PatientCqmDataTableService($http) {
                var _this = _super.call(this, $http) || this;
                _this.apiSelectUrl = '/api/v1/cqm_templates/patient';
                _this.filters = new consolo.domain.CqmTemplateFilter();
                return _this;
            }
            PatientCqmDataTableService.$inject = ['$http'];
            return PatientCqmDataTableService;
        }(csg.datatable.ApiService));
        services.PatientCqmDataTableService = PatientCqmDataTableService;
        angular
            .module('consolo')
            .service('PatientCqmDataTableService', PatientCqmDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientCqmModalController = /** @class */ (function () {
            function PatientCqmModalController($mdDialog, measure, selectedTemplateOptionId) {
                this.$mdDialog = $mdDialog;
                this.measure = measure;
                this.selectedTemplateOptionId = selectedTemplateOptionId;
                this.selectedTemplateOptionId = (measure && measure.cqm_template_option) ?
                    measure.cqm_template_option.id : null;
            }
            PatientCqmModalController.prototype.cancel = function () {
                this.$mdDialog.hide(null);
            };
            PatientCqmModalController.prototype.save = function (measure) {
                measure.cqm_template_option_id = this.selectedTemplateOptionId;
                this.$mdDialog.hide(measure);
            };
            PatientCqmModalController.$inject = [
                '$mdDialog',
                'measure'
            ];
            return PatientCqmModalController;
        }());
        angular.module('consolo').controller('PatientCqmModalController', PatientCqmModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var contollers;
    (function (contollers) {
        var PatientCqmController = /** @class */ (function () {
            function PatientCqmController($window, $mdDialog, logger, CurrentPatient, CqmService, measureSets) {
                this.$window = $window;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.CqmService = CqmService;
                this.measureSets = measureSets;
                this.selectedYear = new Date().getFullYear();
                this.getTemplates();
                this.getPatientCqms();
                this.initiateTableLayout();
                this.yearOptions = [
                    { year: 2020 },
                    { year: 2021 },
                    { year: 2022 },
                    { year: 2023 }
                ];
            }
            PatientCqmController.prototype.refresh = function () {
                var _this = this;
                this.CurrentPatient.refreshPatient().then(function () {
                    _this.getTemplates();
                    _this.getPatientCqms();
                });
            };
            PatientCqmController.prototype.getPatientCqms = function () {
                var _this = this;
                if (this.CurrentPatient.patient.cqms.length) {
                    this.pendingMeasures = this.CurrentPatient.patient.cqms.filter(function (measure) { return measure.status === "Pending" && measure.year === _this.selectedYear; });
                    this.completedMeasures = this.CurrentPatient.patient.cqms.filter(function (measure) { return measure.status === "Completed" && measure.year === _this.selectedYear; });
                }
                else {
                    this.pendingMeasures = [];
                    this.completedMeasures = [];
                }
            };
            PatientCqmController.prototype.getTemplates = function () {
                var _this = this;
                this.CqmService.getPatientCqmTemplates(this.CurrentPatient.patient_id).then(function (response) {
                    _this.measureSets = [
                        // {
                        //   type: "Eligible",
                        //   measures: response.data.filter(measure => measure.status === "Eligible")
                        // },
                        // {
                        //   type: "Possible",
                        //   measures: response.data.filter(measure => measure.status === "Possible")
                        // },
                        {
                            type: "All",
                            measures: response.data.filter(function (measure) { return measure.status === "Available"; })
                        }
                    ];
                });
            };
            PatientCqmController.prototype.addPendingMeasure = function (measure) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Add Measure')
                    .textContent('Are you sure you want to add this Measure?')
                    .ok('Confirm')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirm).then(function () {
                    if (measure) {
                        _this.CqmService.addPatientCqm(measure, _this.CurrentPatient.patient_id, '').finally(function () {
                            _this.refresh();
                            _this.logger.success('Measure added.', 'Success:');
                        });
                    }
                });
            };
            PatientCqmController.prototype.openInfo = function (link) {
                this.$window.open(link);
            };
            PatientCqmController.prototype.openEditModal = function (measure) {
                var _this = this;
                this.$mdDialog.show({
                    templateUrl: '/y/templates/cqms/patient-cqm-modal.html',
                    controller: 'PatientCqmModalController as vm',
                    bindToContoller: true,
                    locals: { measure: measure }
                }).then(function (updates) {
                    if (updates) {
                        _this.CqmService.updatePatientCqm(updates).then(function () {
                            _this.refresh();
                            _this.logger.success('Measure updated.', 'Success:');
                        });
                    }
                });
            };
            PatientCqmController.prototype.addSignature = function (measure_id) {
                var _this = this;
                if (!this.CqmService.signPermission) {
                    this.logger.error('You must have the "Sign Patient CQM documentation" permission to sign a CQM. Please contact your administrator to grant permissions.');
                }
                else {
                    var confirm = this.$mdDialog
                        .confirm()
                        .title('Add Your Signature')
                        .textContent('Are you sure you want to sign this Measure?')
                        .ok('Sign')
                        .cancel('Cancel');
                    return this.$mdDialog.show(confirm).then(function () {
                        _this.CqmService.attachSignature(measure_id).then(function () {
                            _this.refresh();
                            _this.logger.success('Signature added.', 'Success:');
                        });
                    });
                }
            };
            PatientCqmController.prototype.initiateTableLayout = function () {
                var _this = this;
                this.completedMeasuresLayout = {
                    id_field: 'id',
                    options: {
                        borderStyle: 3
                    },
                    columns: [
                        {
                            title: 'Description',
                            field: 'description',
                            formatValueFn: function (measure) {
                                return measure.cqm_template.quality_id + ' ' + measure.cqm_template.title;
                            }
                        },
                        {
                            title: 'Signed',
                            field: 'signed',
                            formatValueFn: function (measure) {
                                if (measure.signer) {
                                    return "Yes";
                                }
                                else {
                                    return "Pending";
                                }
                            }
                        },
                        {
                            title: 'Completed',
                            field: 'signed_at',
                            type: 'date',
                            formatValueFn: function (measure) {
                                if (measure.signed_at) {
                                    var date = new Date(measure.signed_at);
                                    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).toDateString();
                                }
                                else {
                                    return "";
                                }
                            },
                            conditionalHideFn: function (measure) {
                                return measure.status !== "Completed";
                            }
                        },
                        {
                            title: 'Completed by',
                            field: 'signer.name'
                        }
                    ],
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Description:',
                                    field: 'cqm_template.description'
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    title: 'Results:',
                                    field: 'cqm_template_option',
                                    formatValueFn: function (measure) {
                                        if (measure.cqm_template_option) {
                                            return measure.cqm_template_option.description + ' (' + measure.cqm_template_option.numerator + ')';
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    title: 'Comments:',
                                    field: 'comments'
                                }
                            ]
                        }
                    ]
                };
                this.pendingMeasuresLayout = Object.create(this.completedMeasuresLayout);
                this.pendingMeasuresLayout.rowMenu = {
                    items: [
                        {
                            label: 'Edit',
                            callbackFn: function (measure) {
                                _this.openEditModal(measure);
                            }
                        },
                        {
                            label: 'Add Signature',
                            callbackFn: function (measure) {
                                _this.addSignature(measure.id);
                            },
                            conditionalDisableFn: function (measure) {
                                return !measure.cqm_template_option;
                            }
                        }
                    ]
                };
            };
            PatientCqmController.$inject = [
                '$window',
                '$mdDialog',
                'logger',
                'CurrentPatient',
                'CqmService',
                'PatientCqmDataTableService',
                'measureSets'
            ];
            return PatientCqmController;
        }());
        angular.module('consolo').controller('PatientCqmController', PatientCqmController);
    })(contollers = consolo.contollers || (consolo.contollers = {}));
})(consolo || (consolo = {}));

var csg;
(function (csg) {
    var session;
    (function (session) {
        var PubSubService = /** @class */ (function () {
            function PubSubService() {
                this.multiplexers = {};
                this.conHandlers = { open: [], error: [], close: [] };
            }
            /**
             * Opens a connection to the Phoenix socket.
             *
             * @param {String} URL to Phoenix socket
             * @param {String} token Authentication token for user
             */
            PubSubService.prototype.connect = function (url, token) {
                var _this = this;
                if (this.socket) {
                    return;
                }
                this.socket = new Socket(url, {
                    params: { token: token },
                    heartbeatIntervalMs: 1000 * 10,
                    reconnectAfterMs: function (tries) {
                        return [1000, 2000, 3000, 3000, 3000, 5000, 5000, 5000][tries - 1] || 10000;
                    },
                });
                this.socket.connect();
                this.socket.onOpen(function () { return _this.fireCon('open'); });
                this.socket.onError(function (e) { return _this.fireCon('error', e); });
                this.socket.onClose(function () { return _this.fireCon('close'); });
            };
            PubSubService.prototype.onOpen = function (handler) {
                this.conHandlers['open'].push(handler);
                return this;
            };
            PubSubService.prototype.onError = function (handler) {
                this.conHandlers['error'].push(handler);
                return this;
            };
            PubSubService.prototype.onClose = function (handler) {
                this.conHandlers['close'].push(handler);
                return this;
            };
            /**
             * Open a channel to the topic. Returns a promise that resolves with a virtual channel, or rejects with a version string.
             *
             * @param {String} topic
             * @return {Promise<PubSubChannel>}
             */
            PubSubService.prototype.channel = function (topic) {
                var _this = this;
                return new Promise(function (resolve, reject) {
                    // open channel now
                    if (_this.socket) {
                        _this.resolveChannel(topic, resolve, reject);
                        // open channel once the socket is connected
                    }
                    else {
                        var chanOnOpen_1 = function () {
                            _this.resolveChannel(topic, resolve, reject);
                            // Only fire this callback once. NOTE this is undocumented API!
                            var idx = _this.socket.stateChangeCallbacks.open.indexOf(chanOnOpen_1);
                            _this.socket.stateChangeCallbacks.open.splice(idx, 1);
                        };
                        _this.onOpen(chanOnOpen_1);
                    }
                });
            };
            /**
             * Closes the socket.
             */
            PubSubService.prototype.disconnect = function () {
                if (this.socket) {
                    this.socket.disconnect();
                    this.socket = null;
                }
            };
            PubSubService.prototype.resolveChannel = function (topic, resolve, reject) {
                var _this = this;
                if (this.multiplexers[topic]) {
                    var plexer = this.multiplexers[topic];
                    resolve(plexer.createInstance());
                }
                else {
                    var plexer_1 = new TopicMultiplexer(this.socket.channel(topic));
                    this.multiplexers[topic] = plexer_1;
                    plexer_1.channel
                        .join()
                        .receive('ok', function () {
                        console.info("Joined channel for " + topic);
                        resolve(plexer_1.createInstance());
                    })
                        .receive('error', function (reason) {
                        console.warn("Failed to join channel for " + topic, reason);
                        reject(reason);
                    })
                        .receive('timeout', function () {
                        console.warn("Timed out joining channel for " + topic);
                        reject('Network timeout');
                    });
                    plexer_1.channel.onClose(function () {
                        console.info("Left channel for " + topic);
                        delete _this.multiplexers[topic];
                    });
                }
            };
            PubSubService.prototype.fireCon = function (event) {
                var _a;
                var args = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    args[_i - 1] = arguments[_i];
                }
                for (var i = 0; i < this.conHandlers[event].length; i++) {
                    (_a = this.conHandlers[event])[i].apply(_a, args);
                }
            };
            PubSubService.$inject = [];
            return PubSubService;
        }());
        session.PubSubService = PubSubService;
        /**
         * A virtual Phoenix channel. Unlike the underlying Phoenix lib, you may have multiple channels to
         * a given topic open at once. Whean calling "leave" on one the others are unaffected.
         */
        var PubSubChannel = /** @class */ (function () {
            function PubSubChannel(multiplexer) {
                this.multiplexer = multiplexer;
                this.handlers = {};
            }
            /**
             * Listen for an event on the channel.
             *
             * @param {String} event
             * @param {ChanelEventHander} handler
             */
            PubSubChannel.prototype.on = function (event, handler) {
                if (!this.handlers[event]) {
                    this.handlers[event] = [];
                }
                this.handlers[event].push(handler);
                this.multiplexer.listen(event);
                return this;
            };
            /**
             * Close this virtual channel (other instances will be unaffected).
             */
            PubSubChannel.prototype.leave = function () {
                this.handlers = {};
                this.multiplexer.remove(this);
            };
            PubSubChannel.prototype.fire = function (event, msg) {
                var handlers = this.handlers[event] || [];
                for (var i = 0; i < handlers.length; i++) {
                    handlers[i](msg);
                }
            };
            return PubSubChannel;
        }());
        session.PubSubChannel = PubSubChannel;
        /**
         * Wraps a Phoenix Channel to enable multiple joins and leaves of the same topic. With plain
         * Phoenix channels this is not possible, as opening a channel with a given topic closes any
         * previous channels with that topic.
         */
        var TopicMultiplexer = /** @class */ (function () {
            function TopicMultiplexer(channel) {
                this.channel = channel;
                this.instances = [];
                this.events = [];
            }
            TopicMultiplexer.prototype.listen = function (event) {
                var _this = this;
                if (this.events.indexOf(event) === -1) {
                    this.events.push(event);
                    this.channel.on(event, function (msg) {
                        for (var i = 0; i < _this.instances.length; i++) {
                            _this.instances[i].fire(event, msg);
                        }
                    });
                }
            };
            TopicMultiplexer.prototype.createInstance = function () {
                var chan = new PubSubChannel(this);
                this.instances.push(chan);
                return chan;
            };
            TopicMultiplexer.prototype.remove = function (instance) {
                this.instances.splice(this.instances.indexOf(instance), 1);
                if (this.instances.length === 0) {
                    this.channel.leave();
                }
            };
            return TopicMultiplexer;
        }());
        angular.module('csg').service('PubSubService', PubSubService);
    })(session = csg.session || (csg.session = {}));
})(csg || (csg = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ConsoloDashboardFiltersService = /** @class */ (function () {
            function ConsoloDashboardFiltersService($rootScope, $timeout, $q, $filter, DeviceSettings, PatientMainService, debounce, logger, WebCache, ServiceWorkerUpdater) {
                var _this = this;
                this.$rootScope = $rootScope;
                this.$timeout = $timeout;
                this.$q = $q;
                this.$filter = $filter;
                this.DeviceSettings = DeviceSettings;
                this.PatientMainService = PatientMainService;
                this.debounce = debounce;
                this.logger = logger;
                this.WebCache = WebCache;
                this.ServiceWorkerUpdater = ServiceWorkerUpdater;
                this.active = { active: true, count: null };
                this.discharged = { active: false, count: null };
                this.bereavement = { active: false, count: null };
                this.nonteam = { active: false, count: null };
                this.team = { active: false, count: null };
                this.loginRefresh = false;
                this.showAlphabetNavigation = true;
                this.allItems = [];
                this.filteredItems = [];
                this.loadingItems = [];
                this.offices = [];
                this.hasLoaded = false;
                this.showSpinner = false;
                this.offlineLoading = false;
                this.isLoading = false;
                this.pageSize = 5;
                this.textFilter = '';
                this.scrollAdapter = {};
                this.hasUpdates = false;
                this.hasNew = false;
                // public hasNewFilter: boolean = false;
                // public refreshMessage: string = '';
                this.cacheEmpty = false;
                this.disableFilters = false;
                this.emptyId = 0;
                this.alphabetDirectory = [];
                this.refreshTimeout = 1000 * 60 * 60 * 12;
                this.online = true;
                this.removeIds = [];
                this.addTimeout = 0;
                this.debounceItems = function (items) {
                    var now = new Date().getTime();
                    var valid = items.filter(function (item) {
                        if (!item.contentLoading) {
                            if (!item.__last_timestamp || now - item.__last_timestamp.getTime() > 1000 * 60 * 5) {
                                item.contentLoading = true;
                                return true;
                            }
                        }
                    });
                    _this.debounceArray = [];
                    if (valid.length) {
                        _this.fetchItems(valid.map(function (item) {
                            return item.id;
                        }));
                    }
                };
                this.selectedOrg = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.office);
                this.active.active = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_active);
                this.bereavement.active = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_bereavement);
                this.discharged.active = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_discharged);
                this.team.active = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_team);
                this.nonteam.active = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_nonteam);
                this.showAlphabetNavigation = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.alphabetNavBar);
                this.loginRefresh =
                    this.DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.loginRefresh) || this.loginRefresh;
                if (this.loginRefresh) {
                    this.refreshTimeout = 1000 * 60 * 30;
                }
                if (this.showAlphabetNavigation === null || this.showAlphabetNavigation === undefined) {
                    this.showAlphabetNavigation = true;
                    this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.alphabetNavBar, this.showAlphabetNavigation);
                }
                //default active to true if no filters are set on page load
                if (!this.active.active &&
                    !this.bereavement.active &&
                    !this.discharged.active &&
                    !this.team.active &&
                    !this.nonteam.active) {
                    this.active.active = true;
                    this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_active, true);
                }
                var charA = 'A'.charCodeAt(0), charZ = 'Z'.charCodeAt(0);
                for (charA; charA <= charZ; ++charA) {
                    this.alphabetDirectory.push({ letter: String.fromCharCode(charA) });
                }
                this.debounceWrapper = this.debounce(this.debounceOnScroll, 500, false, false);
                if (window.location.href.toString().indexOf('localhost:3') >= 0) {
                    console.warn('Dashboard loading deliberately slowed down for development');
                    this.addTimeout = 0;
                }
            }
            ConsoloDashboardFiltersService.prototype.loadDashboard = function (force) {
                //Placeholder if we decide to refresh the dashboard on a set interval
                // if (!this.lastLoadTime) {
                //   this.lastLoadTime = new Date();
                // }
                var _this = this;
                // let timeDiff = (new Date()).getTime() - this.lastLoadTime.getTime();
                // if (timeDiff > this.loadTimeout * 1000 * 60  || (!this.hasLoaded && !this.isLoading)) {
                this.ServiceWorkerUpdater.preventReload('classic-dashboard-cache');
                if (!this.isLoading && !this.hasLoaded) {
                    this.hasLoaded = false; //placeholder, remove if update on an interval
                    this.offlineLoading = false;
                    this.loadingItems = [];
                    this.allItems = [];
                    this.filteredItems = [];
                    this.LoadCachedPatients();
                }
                else if (!this.isLoading && this.online) {
                    this.isLoading = true;
                    this.logger.info('Checking for new patients...');
                    this.PatientMainService.getClassicDashboardInfo(this.loginRefresh).then(function (dashboard) {
                        _this.patient_count = dashboard.patient_count;
                        _this.numPages = dashboard.pages;
                        _this.checkPatientIds(dashboard.patient_ids).then(function () {
                            _this.loadingComplete(false);
                            _this.debounceWrapper();
                        }, function () {
                            _this.ServiceWorkerUpdater.allowReload('classic-dashboard-cache');
                        });
                    }, function () {
                        _this.ServiceWorkerUpdater.allowReload('classic-dashboard-cache');
                    });
                }
                else {
                    this.ServiceWorkerUpdater.allowReload('classic-dashboard-cache');
                }
            };
            ConsoloDashboardFiltersService.prototype.LoadCachedPatients = function () {
                var _this = this;
                this.showSpinner = true;
                this.PatientMainService.getClassicDashboardInfo(true).then(function (dashboard) {
                    var tempPatients = [];
                    var item = null;
                    _this.PatientMainService.getAllFromCache(dashboard.patient_ids)
                        .then(function (d) {
                        var tempPatients = [];
                        if (!d || !d.length) {
                            _this.cacheEmpty = true;
                            _this.disableFilters = true;
                            _this.updateFunction = _this.addItems;
                        }
                        else {
                            angular.copy(d, tempPatients);
                            for (var _a = 0, tempPatients_1 = tempPatients; _a < tempPatients_1.length; _a++) {
                                var item_1 = tempPatients_1[_a];
                                item_1.__last_timestamp = new Date(item_1.__alerts_timestamp || item_1.__timestamp);
                                if (!item_1.filters) {
                                    item_1.filters = {};
                                }
                            }
                            _this.allItems = tempPatients.sort(_this.nameSort);
                            _this.setFilterCounts();
                            _this.updateFunction = _this.updateItems;
                        }
                        if (_this.online) {
                            _this.numPages = dashboard.pages;
                            _this.patient_count = dashboard.patient_count;
                            if (_this.cacheEmpty) {
                                _this.logger.info('Loading dashboard from the server', 'Initializing Patient Dashboard');
                                _this.LoadServerPatients();
                            }
                            else if (_this.loginRefresh) {
                                _this.logger.info('Refreshing dashboard based on user settings', 'Refreshing Dashboard');
                                _this.LoadServerPatients();
                            }
                            else {
                                _this.$timeout(function () {
                                    _this.filteredItems = _this.filteredItems.concat(_this.allItems.filter(function (item) {
                                        return _this.showItem(item);
                                    }));
                                    _this.checkLetters();
                                    if (_this.scrollAdapter && _this.scrollAdapter.reload) {
                                        _this.scrollAdapter.reload();
                                    }
                                    _this.checkPatientIds(dashboard.patient_ids).then(function () {
                                        _this.loadingComplete(false);
                                        _this.debounceWrapper();
                                    });
                                });
                            }
                        }
                    })
                        .catch(function (e) {
                        _this.logger.error('Error retrieving from cache:');
                        _this.logger.error(e);
                    });
                });
            };
            ConsoloDashboardFiltersService.prototype.checkPatientIds = function (ids) {
                var tempPatients = [];
                var newPatients = [];
                var removedPatients = [];
                var oldCachePatients = [];
                var cachedIds = [];
                var now = Date.now();
                for (var _a = 0, _b = this.allItems; _a < _b.length; _a++) {
                    var patient = _b[_a];
                    if (ids.indexOf(patient.id) < 0) {
                        removedPatients.push(patient);
                    }
                    else {
                        cachedIds.push(patient.id);
                        if (!patient.__timestamp || now - patient.__timestamp > this.refreshTimeout) {
                            oldCachePatients.push(patient.id);
                        }
                    }
                }
                newPatients = ids.filter(function (id) {
                    return cachedIds.indexOf(id) < 0;
                });
                this.updateItems(removedPatients, 'removed');
                if (newPatients.length || oldCachePatients.length) {
                    // this.refreshMessage = '';
                    if (newPatients.length) {
                        this.hasNew = true;
                        this.logger.info('Fetching newly assigned patients', 'Updating Dashboard');
                        // this.refreshMessage = 'New Patients Loaded from Server';
                    }
                    if (oldCachePatients.length) {
                        // this.refreshMessage ? 'New & Updated Patients Loaded from Server' : 'Patient Updates Loaded from Server';
                        this.logger.info('Updating ' + oldCachePatients.length.toString() + ' patients', 'Updating Dashboard');
                    }
                    return this.fetchItems(newPatients.concat(oldCachePatients), 'new');
                }
                else {
                    return this.$q.when(true);
                }
            };
            ConsoloDashboardFiltersService.prototype.ManualRefresh = function () {
                var _this = this;
                this.logger.info('Refreshing Dashboard', 'Manual Refresh Requested');
                this.isLoading = true;
                this.PatientMainService.getClassicDashboardInfo(true)
                    .then(function (dashboard) {
                    _this.patient_count = dashboard.patient_count;
                    _this.numPages = dashboard.pages;
                    _this.LoadServerPatients('new');
                })
                    .catch(function () {
                    _this.isLoading = false;
                });
            };
            ConsoloDashboardFiltersService.prototype.LoadServerPatients = function (applyStatus) {
                var _this = this;
                this.isLoading = true;
                this.showSpinner = true;
                if (this.cacheEmpty) {
                    var tempArr = [];
                    for (var i = 1, len = this.patient_count; i <= len; i++) {
                        var obj = { contentLoading: true, id: -1 * i, filters: {}, name: '_' };
                        tempArr.push(obj);
                    }
                    this.allItems = [].concat(tempArr);
                    angular.copy(this.allItems, this.filteredItems);
                }
                else {
                    this.disableFilters = true;
                    if (!this.filteredItems || !this.filteredItems.length) {
                        this.filteredItems = [].concat(this.allItems.filter(function (item) {
                            return _this.showItem(item);
                        }));
                    }
                    this.filteredItems.forEach(function (item) {
                        item.contentLoading = true;
                    });
                    this.checkLetters();
                }
                this.scrollAdapter.reload();
                var promise = this.$q.when(true);
                var _loop_1 = function (_i) {
                    // this.pagePromises.push(this.$q.defer());
                    promise = promise.then(function () {
                        // return this.fetchPage(_i, applyStatus).then(() => { if (this.cacheEmpty) { this.checkLetters(); }});
                        // For local testing
                        return _this.$timeout(function () {
                            return _this.fetchPage(_i, applyStatus).then(function () {
                                if (_this.cacheEmpty) {
                                    _this.checkLetters();
                                }
                            });
                        }, _this.addTimeout);
                    });
                };
                for (var _i = 1; _i <= this.numPages; _i++) {
                    _loop_1(_i);
                }
                promise.then(function () {
                    _this.loadingComplete(true);
                });
                return promise;
            };
            ConsoloDashboardFiltersService.prototype.fetchPage = function (index, applyStatus) {
                var _this = this;
                return this.$timeout(function () {
                    return _this.PatientMainService.getClassicDashboardPage(index).then(function (data) {
                        _this.updateFunction(data, applyStatus);
                    });
                }, this.addTimeout);
            };
            ConsoloDashboardFiltersService.prototype.updateItems = function (items, applyStatus) {
                // let pushItems = [];
                var idx;
                var doUpdate = false;
                var doRemove = false;
                var doInsert = false;
                var show = false;
                var newItem = false;
                var _loop_2 = function (i) {
                    doUpdate = false;
                    doRemove = false;
                    doInsert = false;
                    show = false;
                    newItem = false;
                    idx = csg.util.indexOfKey(this_1.allItems, 'id', i.id);
                    i.__last_timestamp = applyStatus !== 'error' ? new Date() : i.__last_timestamp;
                    if (i.contentLoading) {
                        delete i.contentLoading;
                    }
                    if (this_1.showItem(i)) {
                        show = true;
                    }
                    if (idx >= 0) {
                        if (this_1.allItems[idx].isNew) {
                            i.isNew = true;
                        }
                        if (this_1.allItems[idx].isUnassigned) {
                            i.isUnassigned = true;
                        }
                        this_1.allItems[idx] = i;
                    }
                    else {
                        if (show) {
                            newItem = true;
                            if (applyStatus) {
                                i.isNew = true;
                                i.__new_timestamp = Date.now();
                                this_1.PatientMainService.cache(i);
                            }
                        }
                        this_1.insertSorted(i, this_1.allItems);
                    }
                    var filteredIdx = -1;
                    idx = !newItem ? csg.util.indexOfKey(this_1.filteredItems, 'id', i.id) : -1;
                    if (idx >= 0) {
                        this_1.filteredItems[idx] = i;
                        if (!show) {
                            doRemove = true;
                        }
                        else {
                            doUpdate = true;
                        }
                    }
                    else if (show) {
                        idx = this_1.insertLocation(i, this_1.filteredItems);
                        filteredIdx = idx - 1;
                        doInsert = true;
                        this_1.scrollAdapter.applyUpdates(function (item) {
                            if (item.id === i.id) {
                                doInsert = false;
                            }
                        });
                        if (doInsert) {
                            if (filteredIdx < 0) {
                                // console.log( 'Updating [' + i.name + ' & ' + this.filteredItems[1].name + '] at 0');
                                this_1.scrollAdapter.applyUpdates(0, [i, this_1.filteredItems[1]]);
                            }
                            else {
                                // console.log( 'Updating [' + this.filteredItems[filteredIdx].name + ' & ' + i.name + '] at '  + (filteredIdx));
                                this_1.scrollAdapter.applyUpdates(filteredIdx, [this_1.filteredItems[filteredIdx], i]);
                            }
                        }
                        this_1.filteredItems.splice(idx, 0, i);
                    }
                    if ((doUpdate || (doRemove && applyStatus === 'remove')) &&
                        this_1.scrollAdapter.applyUpdates) {
                        this_1.scrollAdapter.applyUpdates(function (item) {
                            if (item.id === i.id) {
                                if (doRemove) {
                                    i.isRemoved = true;
                                }
                                return [i];
                            }
                        });
                    }
                    else if (doRemove && this_1.scrollAdapter.applyUpdates && applyStatus !== 'remove') {
                        this_1.filteredItems.splice(idx, 1);
                        this_1.scrollAdapter.applyUpdates(function (item) {
                            if (item.id === i.id) {
                                return [];
                            }
                        });
                    }
                    else if (doInsert && this_1.scrollAdapter.applyUpdates) {
                        // idx = this.insertLocation(i, this.filteredItems) + 1;
                        // this.scrollAdapter.applyUpdates(idx, [this.filteredItems[idx], i]);
                        // let isAdded = false;
                        // this.scrollAdapter.applyUpdates( (item) => {
                        //   if (!isAdded && i.name.toUpperCase() >= item.name.toUpperCase()) {
                        //     isAdded = true;
                        //     return [i, item];
                        //   }
                        // });
                    }
                };
                var this_1 = this;
                for (var _a = 0, items_1 = items; _a < items_1.length; _a++) {
                    var i = items_1[_a];
                    _loop_2(i);
                }
                this.lastIndex = Math.min(this.lastIndex, Math.floor(this.filteredItems.length));
                this.loadingItems = this.loadingItems.concat(items);
            };
            ConsoloDashboardFiltersService.prototype.addItems = function (items) {
                var _this = this;
                var idx = csg.util.indexOfKey(this.filteredItems, 'id', this.emptyId);
                this.allItems.splice(idx, items.length, items);
                var len = this.filteredItems.length;
                var newItems = items.filter(function (item) {
                    return _this.showItem(item);
                });
                var _loop_3 = function (i) {
                    this_2.emptyId--;
                    i.__searchId = this_2.emptyId;
                    i.__last_timestamp = new Date();
                    var idx_1 = csg.util.indexOfKey(this_2.filteredItems, 'id', this_2.emptyId);
                    this_2.filteredItems.splice(idx_1, 1, i);
                    if (idx_1 >= 0 && this_2.scrollAdapter.applyUpdates) {
                        this_2.scrollAdapter.applyUpdates(function (item) {
                            if (item.id === i.__searchId) {
                                return [i];
                            }
                        });
                    }
                };
                var this_2 = this;
                for (var _a = 0, newItems_1 = newItems; _a < newItems_1.length; _a++) {
                    var i = newItems_1[_a];
                    _loop_3(i);
                }
                this.loadingItems = this.loadingItems.concat(items);
            };
            ConsoloDashboardFiltersService.prototype.loadingComplete = function (loadedFromServer) {
                this.ServiceWorkerUpdater.allowReload('classic-dashboard-cache');
                this.hasLoaded = true;
                this.cacheEmpty = false;
                this.disableFilters = false;
                this.showSpinner = false;
                this.isLoading = false;
                this.updateFunction = this.updateItems;
                if (loadedFromServer) {
                    this.allItems = [].concat(this.loadingItems);
                    this.loadingItems = [];
                    var removeFromBuffer_1 = [];
                    for (var i = this.filteredItems.length - 1; i >= 0; i--) {
                        if (this.filteredItems[i].contentLoading) {
                            removeFromBuffer_1.push(this.filteredItems[i].id);
                            this.filteredItems.splice(i, 1);
                        }
                    }
                    this.filteredItems = [].concat(this.filteredItems);
                    if (this.scrollAdapter.applyUpdates) {
                        this.scrollAdapter.applyUpdates(function (item) {
                            if (removeFromBuffer_1.length) {
                                var pos = removeFromBuffer_1.indexOf(item.id);
                                if (pos >= 0) {
                                    removeFromBuffer_1.splice(pos, 1);
                                    return [];
                                }
                            }
                        });
                    }
                    this.logger.success('Dashboard Update Complete');
                    this.checkLetters();
                }
                if (this.hasNew) {
                    this.hasNew = false;
                    this.scrollAdapter.reload();
                }
                this.lastLoadTime = new Date();
                this.setFilterCounts();
                this.$rootScope.$broadcast('dashboardResize');
            };
            ConsoloDashboardFiltersService.prototype.filterResults = function () {
                var _this = this;
                this.filteredItems = this.allItems.filter(function (item) {
                    return _this.showItem(item);
                });
                this.applyTextFilter();
                this.checkLetters();
                return true;
            };
            ConsoloDashboardFiltersService.prototype.applyTextFilter = function () {
                this.filteredItems = this.$filter('filter')(this.filteredItems, this.textFilter);
            };
            ConsoloDashboardFiltersService.prototype.saveFilters = function () {
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.office, this.selectedOrg);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_active, this.active.active);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_bereavement, this.bereavement.active);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_discharged, this.discharged.active);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_team, this.team.active);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.filter_nonteam, this.nonteam.active);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.alphabetNavBar, this.showAlphabetNavigation);
                // this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard,
                //   consolo.deviceSettings.Settings.loginRefresh, this.loginRefresh
                // );
            };
            ConsoloDashboardFiltersService.prototype.filterAndGotoTop = function (refreshFilterCounts) {
                this.saveFilters();
                this.filterResults();
                this.topIndex = 0;
                this.scrollAdapter.reload(0);
                if (refreshFilterCounts) {
                    this.setFilterCounts();
                }
                this.$rootScope.$broadcast('dashboardResize');
            };
            ConsoloDashboardFiltersService.prototype.showItem = function (item) {
                if (item.contentLoading || !item.filters) {
                    return true;
                }
                if (this.selectedOrg) {
                    if (item.office.id !== this.selectedOrg) {
                        return false;
                    }
                }
                if (this.active.active && item.filters.indexOf('active_status') >= 0) {
                    return true;
                }
                if (this.discharged.active && item.filters.indexOf('discharged') >= 0) {
                    return true;
                }
                if (this.bereavement.active && item.filters.indexOf('bereavement') >= 0) {
                    return true;
                }
                if (this.team.active && item.filters.indexOf('team') >= 0) {
                    return true;
                }
                if (this.nonteam.active && item.filters.indexOf('nonteam') >= 0) {
                    return true;
                }
                return false;
            };
            ConsoloDashboardFiltersService.prototype.get = function (index, count, success) {
                var _this = this;
                this.$timeout(function () {
                    var result = [];
                    if (index < 0) {
                        count = count + index;
                        index = 0;
                    }
                    var page = Math.floor(index / _this.pageSize);
                    page = page + 1 > _this.numPages ? page : page + 1;
                    _this.lastIndex = Math.min(index, Math.floor(_this.filteredItems.length));
                    result = _this.filteredItems.slice(index, index + count);
                    success(result);
                });
            };
            ConsoloDashboardFiltersService.prototype.debounceOnScroll = function () {
                if (this.scrollAdapter && this.scrollAdapter.topVisible && this.online) {
                    var top_idx = Math.max(0, csg.util.indexOfKey(this.filteredItems, 'id', this.scrollAdapter.topVisible.id) - 2);
                    var bottom_idx = csg.util.indexOfKey(this.filteredItems, 'id', this.scrollAdapter.bottomVisible.id) + 2;
                    if (!top_idx) {
                        bottom_idx += 2;
                    }
                    this.debounceItems(this.filteredItems.slice(top_idx, bottom_idx));
                }
            };
            ConsoloDashboardFiltersService.prototype.fetchIndividual = function ($event, item) {
                var _this = this;
                $event.stopPropagation();
                if (this.online) {
                    item.contentLoading = true;
                    this.$timeout(function () {
                        _this.fetchItems([item.id]);
                    }, this.addTimeout);
                }
            };
            ConsoloDashboardFiltersService.prototype.fetchItems = function (patientIds, applyStatus) {
                var _this = this;
                return this.$timeout(function () {
                    return _this.PatientMainService.getClassicDashboardById(patientIds)
                        .then(function (data) {
                        _this.updateItems(data, applyStatus);
                    })
                        .catch(function (e) {
                        var unloadArray = [];
                        unloadArray = _this.allItems.filter(function (item) {
                            if (patientIds.indexOf(item.id) >= 0) {
                                return true;
                            }
                        });
                        _this.updateItems(unloadArray, 'error');
                    });
                }, this.addTimeout);
            };
            ConsoloDashboardFiltersService.prototype.deactivateAllLetters = function () {
                this.alphabetDirectory.forEach(function (letter) {
                    letter.active = false;
                });
            };
            ConsoloDashboardFiltersService.prototype.gotoLetter = function (letter) {
                for (var i = 0; i < this.filteredItems.length; i++) {
                    if (this.filteredItems[i].name.charAt(0) === letter) {
                        this.scrollAdapter.reload(i);
                        return;
                    }
                }
            };
            ConsoloDashboardFiltersService.prototype.checkLetters = function () {
                var _this = this;
                this.$timeout(function () {
                    _this.deactivateAllLetters();
                    var a = 'A'.charCodeAt(0);
                    var letter;
                    for (var _a = 0, _b = _this.filteredItems; _a < _b.length; _a++) {
                        var item = _b[_a];
                        if (item.name) {
                            letter = _this.alphabetDirectory[item.name.toUpperCase().charCodeAt(0) - a];
                            if (letter) {
                                letter.active = true;
                            }
                        }
                    }
                });
            };
            // refreshNew() {
            //   this.allItems.sort(this.nameSort);
            //   this.filterAndGotoTop();
            //   this.hasNew = false;
            // }
            ConsoloDashboardFiltersService.prototype.nameSort = function (a, b) {
                var nameA = a.name.toUpperCase(), nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            };
            ConsoloDashboardFiltersService.prototype.setFilterCounts = function () {
                var _this = this;
                this.$timeout(function () {
                    _this.active.count = 0;
                    _this.bereavement.count = 0;
                    _this.discharged.count = 0;
                    _this.team.count = 0;
                    _this.nonteam.count = 0;
                    for (var _a = 0, _b = _this.offices; _a < _b.length; _a++) {
                        var office = _b[_a];
                        office.patient_count = 0;
                    }
                    for (var _c = 0, _d = _this.allItems; _c < _d.length; _c++) {
                        var item = _d[_c];
                        if (item.office.id) {
                            var office_idx = csg.util.indexOfKey(_this.offices, 'id', item.office.id);
                            if (office_idx >= 0) {
                                _this.offices[office_idx].patient_count++;
                            }
                        }
                        if (!_this.selectedOrg || _this.selectedOrg === item.office.id) {
                            for (var _e = 0, _f = item.filters; _e < _f.length; _e++) {
                                var filter = _f[_e];
                                switch (filter) {
                                    case 'active_status':
                                        _this.active.count++;
                                        break;
                                    case 'bereavement':
                                        _this.bereavement.count++;
                                        break;
                                    case 'discharged':
                                        _this.discharged.count++;
                                        break;
                                    case 'team':
                                        _this.team.count++;
                                        break;
                                    case 'nonteam':
                                        _this.nonteam.count++;
                                        break;
                                }
                            }
                        }
                    }
                });
            };
            ConsoloDashboardFiltersService.prototype.insertSorted = function (element, array) {
                array.splice(this.insertLocation(element, array), 0, element);
                return array;
            };
            ConsoloDashboardFiltersService.prototype.insertLocation = function (element, array, start, end) {
                start = start || 0;
                end = end || array.length;
                var pivot = parseInt(start + (end - start) / 2, 10);
                if (end - start <= 1 || array[pivot].name.toUpperCase() === element.name.toUpperCase()) {
                    pivot++;
                    return pivot;
                }
                if (array[pivot].name.toUpperCase() < element.name.toUpperCase()) {
                    return this.insertLocation(element, array, pivot, end);
                }
                else {
                    return this.insertLocation(element, array, start, pivot);
                }
            };
            /* Testing functions */
            ConsoloDashboardFiltersService.prototype.Clear = function () {
                var _this = this;
                this.WebCache.reset().then(function () {
                    _this.allItems = [];
                    _this.filteredItems = [];
                    _this.scrollAdapter.reload();
                    _this.hasLoaded = false;
                    _this.loadDashboard(true);
                });
            };
            ConsoloDashboardFiltersService.prototype.remove3 = function () {
                var _this = this;
                var i = 0;
                this.WebCache.fetchMatches('patient').then(function (data) {
                    for (var _a = 0, data_1 = data; _a < data_1.length; _a++) {
                        var item = data_1[_a];
                        if (i < 3) {
                            i++;
                            _this.WebCache.remove(_this.WebCache.buildUserKey('patient:' + item.id));
                            console.log('Removing ' + item.name);
                        }
                    }
                });
            };
            ConsoloDashboardFiltersService.$inject = [
                '$rootScope',
                '$timeout',
                '$q',
                '$filter',
                'DeviceSettingsService',
                'PatientMainService',
                'debounce',
                'logger',
                'WebCache',
                'ServiceWorkerUpdater',
            ];
            return ConsoloDashboardFiltersService;
        }());
        services.ConsoloDashboardFiltersService = ConsoloDashboardFiltersService;
        angular
            .module('consolo')
            .service('ConsoloDashboardFiltersService', ConsoloDashboardFiltersService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var RootDashboardController = /** @class */ (function () {
            function RootDashboardController($state, $timeout, $window, $scope, cache, dataAccessService, dashboardFilters, NetworkManager, DeviceSettings, HijackFindService, Session, $mdDialog, persistenceStore) {
                var _this = this;
                this.$state = $state;
                this.$timeout = $timeout;
                this.$window = $window;
                this.$scope = $scope;
                this.cache = cache;
                this.dataAccessService = dataAccessService;
                this.dashboardFilters = dashboardFilters;
                this.NetworkManager = NetworkManager;
                this.DeviceSettings = DeviceSettings;
                this.HijackFindService = HijackFindService;
                this.$mdDialog = $mdDialog;
                this.persistenceStore = persistenceStore;
                this.dashboardModes = consolo.deviceSettings.ViewMode;
                this.virtualListStyle = {};
                this.virtualAlphabetStyle = {};
                this.hideEmptyLetters = false;
                this.has_empi_manager_flag = false;
                this.onResize = function () {
                    var extra = _this.dashboardFilters.showSpinner ||
                        !_this.online ||
                        !_this.dashboardFilters.filteredItems ||
                        !_this.dashboardFilters.filteredItems.length
                        ? 72
                        : 0;
                    _this.virtualListStyle.height = _this.$window.innerHeight - (extra + 124) + 'px';
                    _this.virtualAlphabetStyle.height = _this.$window.innerHeight - 123 + 'px';
                    if (_this.$scope.$root && !_this.$scope.$root.$$phase) {
                        _this.$scope.$digest();
                    }
                };
                this.online = this.NetworkManager.isOnline();
                this.chartingCookie = DeviceSettings.getValue(consolo.deviceSettings.Pages.cookie, DeviceSettings.settings.charting_tablet_mode);
                var mode = DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.view_mode);
                this.user = Session.getUser();
                this.agency = Session.get().agency;
                this.has_empi_manager_flag = this.agency['has_empi_manager'];
                this.hideEmptyLetters =
                    DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.hideEmptyLetters) || false;
                this.detailMode = mode === consolo.deviceSettings.ViewMode.list ? false : true;
                this.dashboardFilters.textFilter = '';
                this.HijackFindService.register('#quickFilter', '#quickFilter');
                this.$scope.$on('$destroy', function () {
                    _this.HijackFindService.unregister();
                    _this.$window.removeEventListener('resize', _this.onResize);
                    _this.$window.removeEventListener('focus', _this.dashboardFilters.debounceWrapper);
                });
                // TODO: make this into an object w/ icons & colors
                this.icons = {
                    Charts: 'action:assignment',
                    'Care Plans': 'social:poll',
                    Claims: 'editor:attach_money',
                    Medications: 'maps:local_pharmacy',
                    Volunteer: 'action:assignment_ind',
                    Bereavement: 'maps:local_florist',
                };
                this.colors = {
                    Charts: '#FFC107',
                    'Care Plans': '#BA68C8',
                    Claims: '#81C784',
                    Medications: '#64B5F6',
                    Volunteer: '#FF8A65',
                    Bereavement: '#AED581',
                };
                this.background_colors = {
                    Charts: '#FFF8E1',
                    'Care Plans': '#F3E5f5',
                    Claims: '#E8F5E9',
                    Medications: '#E3F2FD',
                    Volunteer: '#FBE9E7',
                    Bereavement: '#F1F8E9',
                };
                this.assignedPatientCount = 0;
                this.cache(this.dataAccessService.getAssignedPatientCount(), 'assigned_patient_count', {
                    force: true,
                })
                    .query()
                    .$promise.then(function (data) {
                    _this.assignedPatientCount = data[0].count;
                    _this.dashboardFilters.maxIndex = _this.assignedPatientCount;
                });
                this.NetworkManager.onlineHandler.onEvent('RootDashboardController', function () {
                    _this.online = true;
                    _this.dashboardFilters.online = true;
                    _this.updateChartingLink();
                    _this.dashboardFilters.loadDashboard();
                });
                this.NetworkManager.offlineHandler.onEvent('RootDashboardController', function () {
                    _this.online = false;
                    _this.updateChartingLink();
                    _this.dashboardFilters.online = false;
                    // this.dashboardFilters.loadDashboard(this.online);
                });
                this.updateChartingLink();
                this.dashboardFilters.loadDashboard();
                this.onResize();
                this.$window.addEventListener('resize', this.onResize);
                this.$window.addEventListener('focus', this.dashboardFilters.debounceWrapper);
                this.$scope.$on('dashboardResize', function () {
                    _this.onResize();
                });
                angular.element(document.getElementById('dashboard-patients-viewer')).on('scroll', function () {
                    _this.dashboardFilters.debounceWrapper();
                });
            }
            RootDashboardController.prototype.updateChartingLink = function () {
                if (this.user.flags.has_encounter_charting) {
                    this.createChartTitle = 'Create Encounter Chart';
                    this.createChartLink = '';
                }
                else {
                    this.createChartTitle = 'Create Clinical Chart';
                    if (!this.online || this.chartingCookie || this.agency.has_encounter_charting) {
                        this.createChartLink = '/y/patients/:patientId/clinical_chart/new/general';
                    }
                    else {
                        this.createChartLink = '/patients/:patientId/clinical_charts/new';
                    }
                }
            };
            RootDashboardController.prototype.goToPatient = function (patient) {
                this.$state.go('patients.dashboard', { patientId: patient.id });
            };
            RootDashboardController.prototype.parseCarePlan = function (text, isType) {
                var pos = text.indexOf(" '");
                if (isType) {
                    if (pos > 0) {
                        return text.substring(0, pos);
                    }
                    else {
                        return text;
                    }
                }
                else {
                    return text.substring(pos);
                }
            };
            RootDashboardController.prototype.navigateTo = function (page, patient) {
                this.$state.go('patients.' + page, { patientId: patient.id });
            };
            RootDashboardController.prototype.encounterModal = function ($event, patient) {
                var _this = this;
                if (this.user.flags.has_encounter_charting) {
                    this.persistenceStore
                        .get('clinical_chart_entry', { patient_id: patient.id })
                        .then(function (item) {
                        var model = item.model;
                        var obj = {
                            selectedPatient: model.patient,
                            encounter_type_id: model.encounter_type_id,
                            encounter_type: model.encounter_type,
                            charting_client: model.encounter_type.charting_type,
                            initial_section_name: model.initial_section_name || 'encounter_charting',
                        };
                        _this.$state.go('patients.clinical-chart-entry-new.section', {
                            patientId: model.patient_id,
                            sectionName: obj.initial_section_name,
                            initObj: obj,
                        });
                    })
                        .catch(function () {
                        _this.$mdDialog.show({
                            targetEvent: $event,
                            clickOutsideToClose: false,
                            bindToController: true,
                            templateUrl: '/y/templates/clinical-charts/encounter-types/selector-modal.html',
                            controller: 'EncounterSelectorModalController as $ctrl',
                            locals: { currentPatient: patient, clinical_trip_stop_point_id: null },
                        });
                    });
                }
            };
            RootDashboardController.prototype.urlTo = function (url, patient) {
                document.location.href = '/patients/' + patient.id + '/' + url;
            };
            RootDashboardController.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            RootDashboardController.prototype.setListMode = function (mode) {
                this.detailMode = mode === consolo.deviceSettings.ViewMode.list ? false : true;
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.view_mode, mode);
                this.dashboardFilters.scrollAdapter.reload();
            };
            RootDashboardController.prototype.quickFilterChanged = function () {
                if (this.dashboardFilters.textFilter.length !== 1) {
                    this.dashboardFilters.filterResults();
                    this.dashboardFilters.scrollAdapter.reload(0);
                }
            };
            RootDashboardController.prototype.toggleHideEmptyLetters = function () {
                this.hideEmptyLetters = !this.hideEmptyLetters;
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.hideEmptyLetters, this.hideEmptyLetters);
            };
            RootDashboardController.prototype.openIOHubModal = function (patient, $event) {
                this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/reconciliation/io-hub/io-hub-updates-modal.html',
                    controller: 'IOHubUpdatesModal as vm',
                    locals: { patient: patient },
                });
            };
            RootDashboardController.$inject = [
                '$state',
                '$timeout',
                '$window',
                '$scope',
                'cache',
                'dataAccessService',
                'ConsoloDashboardFiltersService',
                'NetworkManager',
                'DeviceSettingsService',
                'hijackFindService',
                'SessionService',
                '$mdDialog',
                'persistenceStore',
            ];
            return RootDashboardController;
        }());
        angular.module('consolo').controller('RootDashboardController', RootDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        'use strict';
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var DashboardState = /** @class */ (function () {
        function DashboardState($stateProvider) {
            $stateProvider
                .state('dashboard', {
                url: '/dashboard',
                data: { title: 'Dashboard', allowSwUpdate: false },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/dashboard.html',
                        controller: 'RootDashboardController as vm',
                    },
                    sidebar: {
                        templateUrl: '/y/templates/dashboard/sidebar.html',
                        controller: 'RootDashboardSidebarController as vm',
                    },
                },
            })
                .state('war-board', {
                url: '/dashboard/war_board',
                data: { title: 'War Board Dashboard', allowSwUpdate: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/war-board/war-board.html',
                        controller: 'WarBoardDashboardController as vm',
                    },
                    sidebar: {
                        templateUrl: '/y/templates/dashboard/war-board/war-board-sidebar.html',
                        controller: 'WarBoardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('war_board', 'home');
                        },
                    ],
                },
            })
                .state('offline-dashboard', {
                url: '/dashboard/offline',
                data: { title: 'Offline Data Dashboard', allowSwUpdate: false },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/offline/dashboard.html',
                        controller: 'OfflineDashboardController as vm',
                    },
                    sidebar: {
                        templateUrl: '/y/templates/dashboard/offline/sidebar.html',
                        controller: 'OfflineDashboardSidebarController as vm',
                    },
                },
            })
                .state('physician', {
                url: '/dashboard/physician?anchor',
                data: { title: 'Physician Dashboard', hideSidebar: true, allowSwUpdate: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/physician/physician-dashboard.html',
                        controller: 'PhysicianDashboardController as vm',
                    },
                },
                resolve: {
                    currentUser: [
                        'SessionService',
                        function (Session) {
                            return Session.getUser();
                        },
                    ],
                },
            })
                .state('medical-director', {
                url: '/dashboard/medical_director',
                data: { title: 'Medical Director Dashboard', allowSwUpdate: false },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/medical-director/md-dashboard.html',
                        controller: 'MedicalDirectorDashboardController as vm',
                    },
                    sidebar: {
                        templateUrl: '/y/templates/dashboard/medical-director/md-dashboard-sidebar.html',
                        controller: 'MedicalDirectorDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    patientCount: [
                        'MDDashboardFilterService',
                        function (mddFilter) {
                            mddFilter.filteredPatients = [];
                            mddFilter.loading = true;
                            return mddFilter.getOverallPatientCounts().then(function () {
                                return mddFilter.filterCounts.patients.count;
                            });
                        },
                    ],
                },
            })
                .state('human-resources', {
                url: '/dashboard/human_resources?organization_type&organization_ids',
                reloadOnSearch: false,
                data: { title: 'Human Resources Dashboard', hideSidebar: true, allowSwUpdate: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/human-resources/hr-dashboard.html',
                        controller: 'HumanResourcesDashboardController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('index', 'human_resources_dashboard');
                        },
                    ],
                },
            })
                .state('alerts', {
                url: '/dashboard/alerts',
                data: { title: 'Alerts', hideSidebar: true, allowSwUpdate: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/alerts/alerts-dashboard.html',
                        controller: 'AlertsDashboardController as vm',
                    },
                },
            })
                .state('emar', {
                url: '/dashboard/emar',
                data: { title: 'eMAR Dashboard', hideSidebar: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/dashboard/emar/emar-dashboard.html',
                        controller: 'EmarDashboardController as vm',
                    },
                },
            })
                .state('tracker', {
                url: '/dashboard/tracker',
                data: { title: 'Tracker Dashboard' },
                views: {
                    'main-content': {
                        template: '<tracker-dashboard flex=""></tracker-dashboard>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.TrackerDashboardDataTableService" layout="column" flex="" ></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('index', 'dashboard_tracker');
                        },
                    ],
                },
            })
                .state('eprescribe', {
                url: '/dashboard/eprescribe',
                data: { title: 'ePrescribe Dashboard', hideSidebar: true },
                views: {
                    'main-content': {
                        template: '<eprescribe-dashboard flex=""></eprescribe-dashboard>',
                    },
                },
            });
        }
        DashboardState.$inject = ['$stateProvider'];
        return DashboardState;
    }());
    angular.module('consolo').config(DashboardState);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var RootDashboardSidebarController = /** @class */ (function () {
            function RootDashboardSidebarController($http, filters, NetworkManager, $rootScope, DeviceSettings) {
                var _this = this;
                this.filters = filters;
                this.$rootScope = $rootScope;
                $http.get('/api/v1/offices').then(function (data) {
                    _this.filters.offices = data.data;
                });
                this.online = NetworkManager.isOnline();
                NetworkManager.onlineHandler.onEvent('RootDashboardSidebarController', function () {
                    _this.online = true;
                });
                NetworkManager.offlineHandler.onEvent('RootDashboardSidebarController', function () {
                    _this.online = false;
                });
                this.filters.loginRefresh = DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.loginRefresh);
            }
            RootDashboardSidebarController.$inject = [
                '$http',
                'ConsoloDashboardFiltersService',
                'NetworkManager',
                '$rootScope',
                'DeviceSettingsService',
            ];
            return RootDashboardSidebarController;
        }());
        angular
            .module('consolo')
            .controller('RootDashboardSidebarController', RootDashboardSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var DataGeneratorStates = /** @class */ (function () {
            function DataGeneratorStates($stateProvider) {
                $stateProvider.state('patient', {
                    url: '/fake-patient-generator',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/data-generators/patient/patient-generator.html',
                            controller: 'PatientGeneratorController as vm',
                        },
                    },
                });
            }
            DataGeneratorStates.$inject = ['$stateProvider'];
            return DataGeneratorStates;
        }());
        angular.module('consolo').config(DataGeneratorStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DeleteGeneratorDataDialog = /** @class */ (function () {
            function DeleteGeneratorDataDialog($mdDialog, generatorType) {
                this.$mdDialog = $mdDialog;
                this.dataTypeOptions = [
                    { text: 'Generated Patients', value: 'generated' },
                ];
                this.generatorType = generatorType;
            }
            DeleteGeneratorDataDialog.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.$mdDialog.hide(this.dataType);
            };
            DeleteGeneratorDataDialog.$inject = ['$mdDialog', 'generatorType'];
            return DeleteGeneratorDataDialog;
        }());
        angular.module('consolo').controller('DeleteGeneratorDataDialog', DeleteGeneratorDataDialog);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DeviceSettingsController = /** @class */ (function () {
            function DeviceSettingsController(DeviceSettings, ChartingPrefetch, PincodeModal, Store, cacheWarmer, $mdDialog, logger, SyncManager, $q, $timeout, Session, $mdTheming, $rootScope) {
                this.DeviceSettings = DeviceSettings;
                this.ChartingPrefetch = ChartingPrefetch;
                this.PincodeModal = PincodeModal;
                this.Store = Store;
                this.cacheWarmer = cacheWarmer;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.SyncManager = SyncManager;
                this.$q = $q;
                this.$timeout = $timeout;
                this.Session = Session;
                this.$mdTheming = $mdTheming;
                this.$rootScope = $rootScope;
                this.tabletChartingLocked = false;
                this.themes = [];
                this.themeName = this.$rootScope.theme;
                // console.log(this.$mdTheming.THEMES);
                for (var t in this.$mdTheming.THEMES) {
                    if (this.$mdTheming.THEMES.hasOwnProperty(t)) {
                        var theme = this.$mdTheming.THEMES[t];
                        if (!theme.name) {
                            continue;
                        }
                        if (theme.name === 'default') {
                            this.themes.push({
                                id: theme.name,
                                name: 'Hospice',
                            });
                        }
                        else {
                            this.themes.push({
                                id: theme.name,
                                name: theme.name.replace(/_/g, ' '),
                            });
                        }
                    }
                }
                console.log(this.themes);
                // console.log(this.$mdTheming.PALETTES);
                this.tabletCharting = DeviceSettings.getValue(consolo.deviceSettings.Pages.cookie, consolo.deviceSettings.Settings.charting_tablet_mode);
                if (this.Session.get().agency.has_encounter_charting) {
                    this.tabletCharting = true;
                    this.tabletChartingLocked = true;
                    DeviceSettings.setValue(consolo.deviceSettings.Pages.cookie, consolo.deviceSettings.Settings.charting_tablet_mode, true);
                }
                this.autoSync = DeviceSettings.getValue(consolo.deviceSettings.Pages.global, consolo.deviceSettings.Settings.charting_sync_mode);
                this.loginRefresh = DeviceSettings.getValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.loginRefresh);
                this.enableAnimations = DeviceSettings.getValue(consolo.deviceSettings.Pages.global, consolo.deviceSettings.Settings.enable_animations);
                this.previousEnableAnimations = this.enableAnimations;
                this.initialEnableAnimations = this.enableAnimations;
                var session = Session.get();
                this.serviceLine = session.agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
            }
            DeviceSettingsController.prototype.resetPincode = function () {
                this.PincodeModal.show(true);
            };
            DeviceSettingsController.prototype.confirmResetDrafts = function () {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Are you sure?')
                    .textContent('ALL drafts will be removed from this device, including those of any other users who may use this device')
                    .ariaLabel('Confirm Draft Reset')
                    .ok('Reset Drafts')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.resetDrafts();
                });
            };
            DeviceSettingsController.prototype.resetDrafts = function () {
                var _this = this;
                this.Store.local('persistence').clear(function () {
                    _this.logger.success('Drafts Reset');
                });
            };
            DeviceSettingsController.prototype.confirmResetCache = function () {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Are you sure?')
                    .textContent('All cache data will be reset for this device. You will need to go to the Offline Dashboard and click "Refresh"' +
                    'to reload cached patients for Offline use')
                    .ariaLabel('Confirm Cache Reset')
                    .ok('Reset Cache')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.resetCache();
                });
            };
            DeviceSettingsController.prototype.resetCache = function () {
                var _this = this;
                this.Store.local('cache').clear(function () {
                    var chain = [];
                    chain.push(_this.cacheWarmer.loadCache());
                    var od = _this.SyncManager.activate().then(function () {
                        return _this.SyncManager.getPatientList();
                    });
                    chain.push(od);
                    _this.$q.all(chain).then(function () {
                        _this.logger.success('Local Cache Reset');
                    });
                });
            };
            DeviceSettingsController.prototype.save = function () {
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.cookie, consolo.deviceSettings.Settings.charting_tablet_mode, this.tabletCharting);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.global, consolo.deviceSettings.Settings.charting_sync_mode, this.autoSync);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.classic_dashboard, consolo.deviceSettings.Settings.loginRefresh, this.loginRefresh);
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.global, consolo.deviceSettings.Settings.enable_animations, this.enableAnimations);
                if (this.enableAnimations !== this.previousEnableAnimations) {
                    this.DeviceSettings.animationsChanged(this.enableAnimations);
                }
                this.previousEnableAnimations = this.enableAnimations;
            };
            DeviceSettingsController.prototype.themeChange = function () {
                // let x = this.$mdTheming.registered(this.themeName);
                // console.log(x);
                // if (!this.$mdTheming.registered(this.themeName)) {
                this.$mdTheming.generateTheme(this.themeName);
                // }
                this.$rootScope.theme = this.themeName;
            };
            DeviceSettingsController.$inject = [
                'DeviceSettingsService',
                'ChartingPrefetch',
                'PincodeModal',
                'Store',
                'cacheWarmer',
                '$mdDialog',
                'logger',
                'SyncManager',
                '$q',
                '$timeout',
                'SessionService',
                '$mdTheming',
                '$rootScope',
            ];
            return DeviceSettingsController;
        }());
        angular.module('consolo').controller('DeviceSettingsController', DeviceSettingsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var deviceSettings;
    (function (deviceSettings) {
        'use strict';
        var DeviceSettingsService = /** @class */ (function () {
            function DeviceSettingsService($cookies, Store, $q, $animate) {
                this.$cookies = $cookies;
                this.Store = Store;
                this.$q = $q;
                this.$animate = $animate;
                this.settings = consolo.deviceSettings.Settings;
                this.pages = consolo.deviceSettings.Pages;
                this.extensionName = 'DeviceSettings';
                this.store = Store.sync();
                this.removeOldValues();
            }
            DeviceSettingsService.prototype.onSessionVisible = function (sess) {
                console.log('Device Settings onSessionVisible');
                var animate = this.getValue(Pages.global, Settings.enable_animations);
                if (animate === null || animate === undefined) {
                    animate = true;
                    this.setValue(Pages.global, Settings.enable_animations, animate);
                }
                this.animationsChanged(animate);
            };
            DeviceSettingsService.prototype.animationsChanged = function (newValue) {
                console.log('Animations Enabled: ', newValue);
                this.$animate.enabled(newValue);
            };
            DeviceSettingsService.prototype.setValue = function (page, setting, value) {
                if (page === Pages.cookie) {
                    var today = new Date();
                    var nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
                    this.$cookies.putObject(Settings[setting], value, { expires: nextYear, path: '/' });
                    return value;
                }
                else {
                    var obj = this.store.getItem('settings_' + Pages[page]);
                    if (!obj) {
                        obj = {};
                    }
                    obj[setting] = value;
                    this.store.setItem('settings_' + Pages[page], obj);
                    return value;
                }
            };
            DeviceSettingsService.prototype.getValue = function (page, setting) {
                if (page === Pages.cookie) {
                    return this.$cookies.getObject(Settings[setting]);
                }
                else {
                    var obj = this.store.getItem('settings_' + Pages[page]);
                    if (obj) {
                        return obj[setting];
                    }
                    return null;
                }
            };
            DeviceSettingsService.prototype.removeOldValues = function () {
                try {
                    // convert legacy cookies to localStorage
                    var chart_sync = this.$cookies.getObject('charting_sync_mode');
                    var dashboard_view = this.$cookies.getObject('dashboard_view_mode');
                    if (chart_sync) {
                        this.setValue(Pages.global, Settings.charting_sync_mode, chart_sync);
                        this.$cookies.remove('charting_sync_mode', { path: '/' });
                    }
                    if (dashboard_view) {
                        this.setValue(Pages.classic_dashboard, Settings.view_mode, dashboard_view);
                        this.$cookies.remove('dashboard_view_mode', { path: '/' });
                    }
                }
                catch (e) {
                    console.warn(e);
                    return null;
                }
            };
            DeviceSettingsService.$inject = ['$cookies', 'Store', '$q', '$animate'];
            return DeviceSettingsService;
        }());
        deviceSettings.DeviceSettingsService = DeviceSettingsService;
        var Pages;
        (function (Pages) {
            // when a page is referenced as 'cookie' save/read from cookie.
            // everything else is stored in localStorage as settings_{page name}.setting
            Pages[Pages["cookie"] = 'cookie'] = "cookie";
            Pages[Pages["global"] = 'global'] = "global";
            Pages[Pages["classic_dashboard"] = 'classic_dashboard'] = "classic_dashboard";
            Pages[Pages["md_dashboard"] = 'md_dashboard'] = "md_dashboard";
            Pages[Pages["patient_search"] = 'patient_search'] = "patient_search";
            Pages[Pages["chart_search"] = 'chart_search'] = "chart_search";
            Pages[Pages["physician_order_search"] = 'physician_order_search'] = "physician_order_search";
            Pages[Pages["tracker_dashboard"] = 'tracker_dashboard'] = "tracker_dashboard";
        })(Pages = deviceSettings.Pages || (deviceSettings.Pages = {}));
        var Settings;
        (function (Settings) {
            Settings[Settings["charting_tablet_mode"] = 'charting_tablet_mode'] = "charting_tablet_mode";
            Settings[Settings["charting_sync_mode"] = 'charting_sync_mode'] = "charting_sync_mode";
            Settings[Settings["view_mode"] = 'view_mode'] = "view_mode";
            Settings[Settings["card_mode"] = 'card_mode'] = "card_mode";
            Settings[Settings["office"] = 'office'] = "office";
            Settings[Settings["filter_active"] = 'filter_active'] = "filter_active";
            Settings[Settings["filter_bereavement"] = 'filter_bereavement'] = "filter_bereavement";
            Settings[Settings["filter_discharged"] = 'filter_discharged'] = "filter_discharged";
            Settings[Settings["filter_team"] = 'filter_team'] = "filter_team";
            Settings[Settings["filter_nonteam"] = 'filter_nonteam'] = "filter_nonteam";
            Settings[Settings["alphabetNavBar"] = 'alphabetNavBar'] = "alphabetNavBar";
            Settings[Settings["loginRefresh"] = 'loginRefresh'] = "loginRefresh";
            Settings[Settings["hideEmptyLetters"] = 'hideEmptyLetters'] = "hideEmptyLetters";
            Settings[Settings["previous_filters"] = 'previous_filters'] = "previous_filters";
            Settings[Settings["display_toggles"] = 'display_toggles'] = "display_toggles";
            Settings[Settings["enable_animations"] = 'enable_animations'] = "enable_animations";
        })(Settings = deviceSettings.Settings || (deviceSettings.Settings = {}));
        var ViewMode;
        (function (ViewMode) {
            ViewMode[ViewMode["detail"] = 1] = "detail";
            ViewMode[ViewMode["list"] = 2] = "list";
        })(ViewMode = deviceSettings.ViewMode || (deviceSettings.ViewMode = {}));
        angular.module('consolo').service('DeviceSettingsService', DeviceSettingsService);
    })(deviceSettings = consolo.deviceSettings || (consolo.deviceSettings = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        var DeviceSettingsStates = /** @class */ (function () {
            function DeviceSettingsStates($stateProvider) {
                $stateProvider.state('device_settings', {
                    data: { title: 'Device Settings' },
                    url: '/device_settings',
                    views: {
                        'sidebar@': {
                            templateUrl: '/y/templates/device-settings/sidebar.html',
                        },
                        'main-content': {
                            templateUrl: '/y/templates/device-settings/device-settings.html',
                            controller: 'DeviceSettingsController as $ctrl',
                        },
                    },
                });
            }
            DeviceSettingsStates.$inject = ['$stateProvider'];
            return DeviceSettingsStates;
        }());
        angular.module('consolo').config(DeviceSettingsStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('diagnosisModal', {
            bindings: { diagnosis: '<' },
            templateUrl: '/y/templates/diagnosis/diagnosis-modal.html',
            controller: 'DiagnosisModalController as $ctrl',
        });
        var DiagnosisModalController = /** @class */ (function () {
            function DiagnosisModalController($mdDialog, IcdService, logger) {
                this.$mdDialog = $mdDialog;
                this.IcdService = IcdService;
                this.logger = logger;
                if (!this.diagnosis) {
                    this.diagnosis = IcdService.currentHealthCondition;
                }
                if (!this.diagnosis.start_date) {
                    this.diagnosis.start_date = new Date();
                }
            }
            DiagnosisModalController.prototype.submit = function () {
                if (this.diagnosis.start_date &&
                    this.diagnosis.end_date &&
                    new Date(this.diagnosis.end_date) < new Date(this.diagnosis.start_date)) {
                    this.errors = ['The end date must be after the start date.'];
                    this.logger.error('The end date must be after the start date.');
                }
                else {
                    return this.$mdDialog.hide(this.diagnosis);
                }
            };
            DiagnosisModalController.$inject = ['$mdDialog', 'IcdService', 'logger'];
            return DiagnosisModalController;
        }());
        common.DiagnosisModalController = DiagnosisModalController;
        angular.module('consolo').controller('DiagnosisModalController', DiagnosisModalController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('diagnosisSearch', {
            bindings: { showIcd9: '<' },
            templateUrl: '/y/templates/diagnosis/diagnosis-search-dialog.html',
            controller: 'DiagnosisSearchDialogController as $ctrl',
        });
        var DiagnosisSearchDialogController = /** @class */ (function () {
            function DiagnosisSearchDialogController(options, $mdDialog, $http, IcdService) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.IcdService = IcdService;
                this.icdUrl = '/api/v1/icds';
                this.IcdService.selectedIcd = null;
            }
            DiagnosisSearchDialogController.prototype.setDisplay = function (icd) {
                icd.display = icd.code + ' - ' + icd.description;
            };
            DiagnosisSearchDialogController.prototype.submit = function () {
                return this.$mdDialog.hide(this.IcdService.selectedIcd);
            };
            DiagnosisSearchDialogController.$inject = ['options', '$mdDialog', '$http', 'IcdService'];
            return DiagnosisSearchDialogController;
        }());
        common.DiagnosisSearchDialogController = DiagnosisSearchDialogController;
        angular
            .module('consolo')
            .controller('DiagnosisSearchDialogController', DiagnosisSearchDialogController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var IcdService = /** @class */ (function () {
            function IcdService($http) {
                var _this = this;
                this.$http = $http;
                this.apiUrl = '/api/v1/icds';
                this.$http.get(this.apiUrl + '/icd10_chapters').then(function (response) {
                    _this.icd10Chapters = response.data;
                });
            }
            IcdService.prototype.lookupIcd10 = function (query) {
                return this.$http
                    .get(this.apiUrl + '/icd10s', { params: { q: query, billable: true } })
                    .then(function (response) {
                    return response.data;
                });
            };
            IcdService.prototype.getIcd10Sections = function (icd10ChapterId) {
                return this.$http
                    .get(this.apiUrl + '/icd10_sections', { params: { icd10_chapter_id: icd10ChapterId } })
                    .then(function (response) {
                    return response.data;
                });
            };
            IcdService.prototype.setIcd10 = function (icd10) {
                if (this.selectedIcd === null || this.selectedIcd.id !== icd10.id) {
                    this.selectedIcd = icd10;
                }
                else {
                    this.selectedIcd = null;
                }
            };
            IcdService.prototype.isSelected = function (icd10) {
                if (this.selectedIcd && this.selectedIcd.id === icd10.id) {
                    return true;
                }
                else {
                    return false;
                }
            };
            IcdService.prototype.setIcd10Sections = function (icd10Chapter) {
                if (!icd10Chapter.icd10_sections) {
                    this.getIcd10Sections(icd10Chapter.id)
                        .then(function (icd10Sections) {
                        icd10Chapter.icd10_sections = icd10Sections;
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            };
            IcdService.prototype.displayAdditionalInformation = function (icd10Chapter) {
                var a = icd10Chapter.use_additional_code;
                var b = icd10Chapter.excludes_1;
                var c = icd10Chapter.excludes_2;
                var d = icd10Chapter.includes;
                if (a || b || c || d) {
                    return true;
                }
                else {
                    return false;
                }
            };
            IcdService.prototype.setSectionIcd10s = function (icd10Section) {
                if (!icd10Section.icd10s) {
                    this.$http
                        .get(this.apiUrl + '/icd10s', { params: { icd10_section_id: icd10Section.id } })
                        .then(function (response) {
                        icd10Section.icd10s = response.data;
                    });
                }
            };
            IcdService.prototype.setIcd10s = function (icd10) {
                if (!icd10.icd10s) {
                    this.$http
                        .get(this.apiUrl + '/icd10s', { params: { icd10_id: icd10.id } })
                        .then(function (response) {
                        icd10.icd10s = response.data;
                    });
                }
            };
            IcdService.$inject = ['$http'];
            return IcdService;
        }());
        services.IcdService = IcdService;
        angular.module('consolo').service('IcdService', IcdService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('icd10TabularDisplay', {
            bindings: { icd10: '<' },
            templateUrl: '/y/templates/diagnosis/icd10-tabular-display.html',
            controller: 'Icd10TabularDisplayController as $ctrl',
        });
        var Icd10TabularDisplayController = /** @class */ (function () {
            function Icd10TabularDisplayController(IcdService) {
                this.IcdService = IcdService;
            }
            Icd10TabularDisplayController.$inject = ['IcdService'];
            return Icd10TabularDisplayController;
        }());
        common.Icd10TabularDisplayController = Icd10TabularDisplayController;
        angular
            .module('consolo')
            .controller('Icd10TabularDisplayController', Icd10TabularDisplayController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var DischargeSummaryViewComponent = /** @class */ (function () {
            function DischargeSummaryViewComponent() {
                this.templateUrl = '/y/templates/discharge-summary/discharge-summary-view.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    dischargeId: '<',
                    currentSummary: '<',
                    isPartial: '<',
                };
                this.controller = DischargeSummaryViewController;
            }
            return DischargeSummaryViewComponent;
        }());
        var DischargeSummaryViewController = /** @class */ (function () {
            function DischargeSummaryViewController(dischargeSummary, currentPatient, $timeout, AllergiesService) {
                this.dischargeSummary = dischargeSummary;
                this.currentPatient = currentPatient;
                this.$timeout = $timeout;
                this.AllergiesService = AllergiesService;
            }
            DischargeSummaryViewController.prototype.$onInit = function () {
                var _this = this;
                if (!this.currentSummary) {
                    this.dischargeSummary.getById(this.patientId, this.dischargeId).$promise.then(function (ds) {
                        _this.currentSummary = ds;
                        _this.startTimer();
                    });
                }
                else {
                    this.startTimer();
                }
                if (this.patientId !== this.currentPatient.patient_id) {
                    this.currentPatient.getPatient(this.patientId, true).then(function (patient) {
                        _this.allergies = patient.patient_allergies;
                        _this.unscreenedAllergies = patient.patient_allergies_unscreened;
                    });
                }
                else {
                    this.allergies = this.currentPatient.patient.patient_allergies;
                    this.unscreenedAllergies = this.currentPatient.patient.patient_allergies_unscreened;
                }
                console.log(this.allergies, this.unscreenedAllergies);
            };
            DischargeSummaryViewController.prototype.startTimer = function () {
                var _this = this;
                // Not ideal, no easy/quick way to track that every component has been fully loaded
                this.$timeout(function () {
                    _this.summaryLoaded = true;
                }, 22000);
            };
            DischargeSummaryViewController.$inject = ['DischargeSummary', 'CurrentPatient', '$timeout', 'AllergiesService'];
            return DischargeSummaryViewController;
        }());
        components.DischargeSummaryViewController = DischargeSummaryViewController;
        angular.module('consolo').component('dischargeSummaryView', new DischargeSummaryViewComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('DischargeSummaryController', ['$state', 'currentSummary', 'items', Controller]);
    function Controller($state, currentSummary, items) {
        var vm = this;
        //members
        vm.items = [];
        vm.currentSummary = currentSummary;
        vm.patientId = $state.params.patientId;
        vm.save = save;
        activate();
        function activate() {
            vm.items = items;
        }
        function save() {
            //currentSummary.$update();
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('DischargeSummary', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/discharge_summaries/:id', { id: '@id' }, {
            update: {
                method: 'PUT',
            },
        });
        var loadedId, dataPromise;
        return {
            loadByPatientId: loadByPatientId,
            getById: getById,
            getSummaries: getSummaries,
        };
        function getById(patientId, id) {
            return res.get({ id: id, patient_id: patientId });
        }
        function getSummaries(patientId) {
            return res.query({ patient_id: patientId });
        }
        function loadByPatientId(id) {
            if (loadedId !== id || !dataPromise) {
                loadedId = id;
                dataPromise = res.query({ patient_id: id }).$promise.then(function (data) {
                    return data[0];
                });
            }
            return dataPromise;
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Routes]);
    function Routes($stateProvider) {
        $stateProvider
            .state('patients.discharge_summaries', {
            data: { title: 'Discharge Summaries' },
            url: '/discharge_summaries',
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/patients/patient-sidebar.html',
                    controller: 'PatientDashboardSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/discharge-summary/index.html',
                    controller: 'DischargeSummaryIndexController as vm',
                },
            },
            resolve: {
                summaries: [
                    '$stateParams',
                    'DischargeSummary',
                    function ($stateParams, DischargeSummary) {
                        return DischargeSummary.getSummaries($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.discharge-summary', {
            data: { title: 'Discharge Summary' },
            abstract: true,
            url: '/discharge_summary/:dischargeId',
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/discharge-summary/sidebar.html',
                    controller: 'DischargeSummarySidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/discharge-summary/editor.html',
                    controller: 'DischargeSummaryEditorController as vm',
                },
            },
            resolve: {
                currentSummary: [
                    '$stateParams',
                    'DischargeSummary',
                    function ($stateParams, DischargeSummary) {
                        return DischargeSummary.getById($stateParams.patientId, $stateParams.dischargeId)
                            .$promise;
                    },
                ],
            },
        })
            .state('patients.discharge-summary.discharge-summary-view', {
            url: '/view?layout',
            data: {},
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/patients/patient-sidebar.html',
                    controller: 'PatientDashboardSidebarController as vm',
                },
                'main-content@': {
                    template: "<discharge-summary-view\n                patient-id=\"currentPatient.id\"\n                current-summary=\"currentSummary\"\n                discharge-id=\"currentSummary.id\"\n                is-partial=\"isPartial\"\n              />",
                    controller: [
                        '$scope',
                        'currentSummary',
                        'currentPatient',
                        function ($scope, currentSummary, currentPatient) {
                            $scope.currentSummary = currentSummary;
                            $scope.currentPatient = currentPatient;
                            $scope.isPartial = true;
                        },
                    ],
                },
            },
            resolve: {
                formPatient: [
                    '$stateParams',
                    'CurrentPatient',
                    function ($params, CurrentPatient) {
                        return CurrentPatient.getFormPatient($params.patientId);
                    },
                ],
            },
        })
            .state('patients.discharge-summary.main', {
            url: '/main',
            views: {
                editor: {
                    templateUrl: '/y/templates/discharge-summary/sections/main/main.html',
                    controller: 'DischargeMainController as $ctrl',
                },
            },
        })
            .state('patients.discharge-summary.medications', {
            url: '/medications',
            views: {
                editor: {
                    templateUrl: '/y/templates/discharge-summary/sections/medications/medications.html',
                    controller: 'DischargeSummaryController as vm',
                },
            },
            resolve: {
                items: [
                    'Medication',
                    '$stateParams',
                    function (Medication, $stateParams) {
                        return Medication.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.discharge-summary.indicators', {
            url: '/indicators',
            views: {
                editor: {
                    templateUrl: '/y/templates/discharge-summary/sections/indicators/indicators.html',
                    controller: 'DischargeSummaryController as vm',
                },
            },
            resolve: {
                items: [
                    'Indicator',
                    '$stateParams',
                    function (Indicator, $stateParams) {
                        return Indicator.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.discharge-summary.care-plan-problems', {
            url: '/care_plan_problems',
            views: {
                editor: {
                    templateUrl: '/y/templates/discharge-summary/sections/care-plan-problems/care-plan-problems.html',
                    controller: 'DischargeSummaryController as vm',
                },
            },
            resolve: {
                items: [
                    'CarePlanProblemEntry',
                    '$stateParams',
                    function (CarePlanProblemEntry, $stateParams) {
                        return CarePlanProblemEntry.query({ patient_id: $stateParams.patientId }).$promise;
                    },
                ],
            },
        })
            .state('patients.discharge-summary.clinical-charts', {
            url: '/clinical_charts',
            views: {
                editor: {
                    templateUrl: '/y/templates/discharge-summary/sections/clinical-charts/clinical-charts.html',
                    controller: 'DischargeSummaryController as vm',
                },
            },
            resolve: {
                items: [
                    'ClinicalChartEntry',
                    '$stateParams',
                    '$filter',
                    'currentSummary',
                    function (ClinicalChartEntry, $stateParams, $filter, currentSummary) {
                        var since = new Date(currentSummary.effective_date);
                        since.setDate(since.getDate() - 30);
                        return ClinicalChartEntry.getByPatientId($stateParams.patientId, {
                            start_date: $filter('date')(since, 'yyyy-MM-dd'),
                        });
                    },
                ],
            },
        })
            .state('patients.discharge-summary.physician-orders', {
            url: '/physician_orders',
            views: {
                editor: {
                    templateUrl: '/y/templates/discharge-summary/sections/physician-orders/physician-orders.html',
                    controller: 'DischargeSummaryController as vm',
                },
            },
            resolve: {
                items: [
                    'PhysicianOrderEntry',
                    '$stateParams',
                    function (PhysicianOrderEntry, $stateParams) {
                        return PhysicianOrderEntry.loadByPatientId($stateParams.patientId).$promise;
                    },
                ],
            },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('DischargeSummaryEditorController', [
        'currentSummary',
        'logger',
        Controller,
    ]);
    function Controller(currentSummary, logger) {
        var vm = this;
        vm.save = save;
        activate();
        function activate() { }
        function save() {
            currentSummary.$update().then(function () {
                logger.success('Saved');
            }, function (data) {
                logger.error('Error saving discharge summary: ' + data);
            });
        }
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DischargeSummaryIndexController = /** @class */ (function () {
            function DischargeSummaryIndexController($state, $stateParams, summaries, PdfGenerator) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.summaries = summaries;
                this.PdfGenerator = PdfGenerator;
                this.patientId = $stateParams.patientId;
                this.summaries = summaries;
                this.formModelDS = {};
                this.loadFormModel();
            }
            DischargeSummaryIndexController.prototype.createPdf = function (id) {
                this.PdfGenerator.printDischargeSummaries([id], this.patientId);
            };
            DischargeSummaryIndexController.prototype.loadFormModel = function () {
                var _this = this;
                this.formModelDS = {
                    options: {},
                    header: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            isDate: true,
                            itemClass: 'no-wrap',
                        },
                        {
                            title: 'Last Updated',
                            field: 'updated_at',
                            isDate: true,
                            itemClass: 'no-wrap',
                        },
                        {
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            itemMenuItems: [
                                {
                                    text: 'View',
                                    icon: 'action:visibility',
                                    callbackFn: function ($event, item) {
                                        _this.$state.go('patients.discharge-summary.discharge-summary-view', {
                                            dischargeId: item.id,
                                            patientId: _this.patientId,
                                        });
                                    },
                                },
                                {
                                    text: 'Edit',
                                    icon: 'editor:mode_edit',
                                    callbackFn: function ($event, item) {
                                        _this.$state.go('patients.discharge-summary.main', {
                                            patientId: _this.patientId,
                                            dischargeId: item.id,
                                        });
                                    },
                                },
                                {
                                    text: 'Print',
                                    icon: 'action:print',
                                    callbackFn: function ($event, item) {
                                        _this.createPdf(item.id);
                                    },
                                },
                            ],
                        },
                    ],
                };
            };
            DischargeSummaryIndexController.$inject = ['$state', '$stateParams', 'summaries', 'PdfGenerator'];
            return DischargeSummaryIndexController;
        }());
        angular
            .module('consolo')
            .controller('DischargeSummaryIndexController', DischargeSummaryIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('DischargeSummarySidebarController', [Controller]);
    function Controller() { }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('DischargeSummaryViewController', [
        '$http',
        '$stateParams',
        '$q',
        'WindowStatus',
        'currentSummary',
        'CurrentPatient',
        'AllergiesService',
        Controller,
    ]);
    function Controller($http, $stateParams, $q, WindowStatus, currentSummary, CurrentPatient, AllergiesService) {
        var vm = this;
        vm.carePlanProblems = [];
        vm.clinicalCharts = [];
        vm.dischargeSummary = currentSummary;
        vm.indicators = [];
        vm.medications = [];
        vm.patientData = '';
        vm.physicianOrders = [];
        vm.allergies = CurrentPatient.patient.patient_allergies;
        vm.unscreened_allergies = CurrentPatient.patient.patient_allergies_unscreened;
        vm.AllergiesService = AllergiesService;
        activate();
        function activate() {
            var x = [];
            x.push($http.get('/patients/' + $stateParams.patientId + '.html').then(function (data) {
                vm.patientData = data.data;
            }));
            currentSummary.care_plan_problem_ids.forEach(function (id) {
                x.push($http
                    .get('/patients/' + $stateParams.patientId + '/care_plan_problems/' + id + '.html')
                    .then(function (data) {
                    vm.carePlanProblems.push(data.data);
                }));
            });
            currentSummary.medication_ids.forEach(function (id) {
                x.push($http
                    .get('/patients/' + $stateParams.patientId + '/medications/' + id + '.html')
                    .then(function (data) {
                    vm.medications.push(data.data);
                }));
            });
            currentSummary.indicator_ids.forEach(function (id) {
                x.push($http
                    .get('/patients/' + $stateParams.patientId + '/indicators/' + id + '.html')
                    .then(function (data) {
                    vm.indicators.push(data.data);
                }));
            });
            currentSummary.clinical_chart_ids.forEach(function (id) {
                x.push($http
                    .get('/patients/' + $stateParams.patientId + '/clinical_charts/' + id + '.html')
                    .then(function (data) {
                    vm.clinicalCharts.push(data.data);
                }));
            });
            currentSummary.physician_order_ids.forEach(function (id) {
                x.push($http
                    .get('/patients/' +
                    $stateParams.patientId +
                    '/physician_orders/' +
                    id +
                    '.html?layout=' +
                    $stateParams.layout)
                    .then(function (data) {
                    vm.clinicalCharts.push(data.data);
                }));
            });
            WindowStatus.readyOnPromise($q.all(x));
        }
    }
})();

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var DispatchViewService = /** @class */ (function () {
            function DispatchViewService($filter, $http, $state, $q, $window, logger, options, User, ClinicalTrip, Breadcrumb) {
                var _this = this;
                this.$filter = $filter;
                this.$http = $http;
                this.$state = $state;
                this.$q = $q;
                this.$window = $window;
                this.logger = logger;
                this.options = options;
                this.User = User;
                this.ClinicalTrip = ClinicalTrip;
                this.Breadcrumb = Breadcrumb;
                this.currentLocations = {};
                this.allSelected = false;
                this.calculating = false;
                this.selectedDate = new Date();
                this.needsToSetRole = false;
                this.loadingUsers = false;
                this.locations = {};
                this.currentStopPoints = [];
                this.currentStop = {};
                this.breadcrumbs = [];
                this.timeFilter = [
                    { value: 'In Last 48 Hours', id: '48' },
                    { value: 'In Last Week', id: '168' },
                    { value: 'In Last Month', id: '720' },
                    { value: 'In Last Year', id: '8760' },
                ];
                this.selectedTime = this.timeFilter[0];
                this.baseRoles = null;
                this.selectedRole = null;
                this.options.get('base_roles').$promise.then(function (roles) {
                    _this.baseRoles = roles;
                    _this.baseRoles.unshift({ id: null, description: '' });
                    if (_this.selectedRole && _this.needsToSetRole) {
                        _this.setRole(_this.selectedRole);
                    }
                });
                this.$http.get('/api/v1/org').then(function (data) {
                    _this.orgList = data.data;
                });
                if (!this.userList) {
                    this.getUsers();
                }
            }
            DispatchViewService.prototype.userEdit = function (user) {
                var _this = this;
                this.selectedUser = user;
                this.getRoute().then(function () {
                    _this.$state.go('dispatch-view.routes', { userId: user.id });
                });
            };
            DispatchViewService.prototype.setRole = function (role) {
                var _this = this;
                if (this.baseRoles) {
                    this.baseRoles.forEach(function (baseRole) {
                        if (baseRole.description === role) {
                            _this.selectedRole = baseRole.id;
                            _this.needsToSetRole = false;
                        }
                    });
                }
                else {
                    this.selectedRole = role;
                    this.needsToSetRole = true;
                }
            };
            DispatchViewService.prototype.userSelect = function (user) {
                this.locations[user.id] = user;
                user.isSelected = true;
            };
            DispatchViewService.prototype.userToggle = function (user) {
                if (user.isSelected) {
                    this.userUnselect(user);
                }
                else {
                    this.userSelect(user);
                }
                this.allSelected =
                    this.userList.filter(function (a) {
                        return !a.isSelected;
                    }).length === 0;
            };
            DispatchViewService.prototype.userUnselect = function (user) {
                user.isSelected = false;
                delete this.locations[user.id];
            };
            DispatchViewService.prototype.cancelEdit = function () {
                this.selectedUser = null;
                this.$state.go('dispatch-view');
            };
            DispatchViewService.prototype.getUserById = function (id) {
                return this.userList.filter(function (a) {
                    return a.id === parseInt(id, 10);
                })[0];
            };
            DispatchViewService.prototype.getRoute = function () {
                var _this = this;
                this.clinicalTrip = null;
                this.calculating = true;
                var dateOnly = this.$filter('date')(this.selectedDate, 'yyyy-MM-dd');
                return this.$q
                    .all([
                    this.ClinicalTrip.query({
                        user_id: this.selectedUser.id,
                        start_date: dateOnly,
                        end_date: dateOnly,
                    }).$promise,
                    this.Breadcrumb.query({
                        'user_ids[]': this.selectedUser.id,
                        start_date: dateOnly,
                        end_date: dateOnly,
                        resolution: 20,
                    }).$promise,
                ])
                    .then(function (result) {
                    _this.clinicalTrip = result[0][0];
                    var data = result[0][0] && result[0][0].stop_points.length
                        ? result[0][0].stop_points.map(_this.toAddress)
                        : [];
                    _this.currentStopPoints = data;
                    _this.breadcrumbs = result[1];
                }, function () {
                    _this.logger.error('Unable to connect to server.');
                })
                    .finally(function () {
                    _this.calculating = false;
                });
            };
            DispatchViewService.prototype.searchAddress = function () {
                this.addressSearchTerm = this.addressSearchText;
            };
            DispatchViewService.prototype.getUsers = function () {
                var _this = this;
                var sortOrder = ['-last_seen', 'last_name'];
                var organizationType = null;
                var organizationId = null;
                if (this.selectedOrg) {
                    var a = this.selectedOrg.split(',');
                    organizationType = a[0];
                    organizationId = a[1];
                }
                this.loadingUsers = true;
                return this.User.query({
                    role_id: this.selectedRole,
                    last_seen_since: this.selectedTime.id,
                    organization_type: organizationType,
                    organization_id: organizationId,
                    only_with_evv: true,
                }, function (result) {
                    var _list = _this.$filter('orderBy')(result, sortOrder);
                    _list.forEach(function (user, index) {
                        user.poiLabel = index + 1;
                    });
                    _this.loadingUsers = false;
                    _this.userList = _list;
                    if (_this.$state.params.userId && !_this.selectedUser) {
                        _this.selectedUser = _this.getUserById(_this.$state.params.userId);
                        if (!_this.selectedUser) {
                            _this.User.get({ id: _this.$state.params.userId }, function (user) {
                                _this.selectedUser = user;
                                _this.getRoute();
                            });
                        }
                        else {
                            _this.getRoute();
                        }
                    }
                });
            };
            DispatchViewService.prototype.officeFilter = function () {
                var _this = this;
                var keys = Object.keys(this.locations);
                keys.forEach(function (key) {
                    delete _this.locations[key];
                });
                this.getUsers();
            };
            DispatchViewService.prototype.selectAll = function () {
                var _this = this;
                this.userList.forEach(function (user) {
                    if (_this.allSelected) {
                        _this.userUnselect(user);
                    }
                    else {
                        _this.userSelect(user);
                    }
                });
            };
            DispatchViewService.prototype.selectStop = function (stop, index) {
                stop._index = index;
                this.currentStop = stop;
            };
            DispatchViewService.prototype.viewChart = function (patientId, clinicalChartId) {
                this.$window.open('/patients/' + patientId + '/clinical_charts/' + clinicalChartId, '_blank');
            };
            DispatchViewService.prototype.toAddress = function (point) {
                return {
                    id: point.id,
                    destination_name: point.destination_name,
                    street: point.address_1,
                    city: point.city,
                    state: point.state,
                    postalCode: point.zipcode,
                    fullAddress: point.address_1 + ", " + point.city + ", " + point.state + " " + point.zipcode,
                    lat: point.latitude,
                    lng: point.longitude,
                    position: point.position,
                };
            };
            DispatchViewService.$inject = [
                '$filter',
                '$http',
                '$state',
                '$q',
                '$window',
                'logger',
                'options',
                'User',
                'ClinicalTrip',
                'Breadcrumb',
            ];
            return DispatchViewService;
        }());
        services.DispatchViewService = DispatchViewService;
        angular.module('consolo').service('DispatchViewService', DispatchViewService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Routes]);
    function Routes($stateProvider) {
        $stateProvider
            .state('dispatch-view', {
            data: { title: 'Dispatch View' },
            url: '/dispatch_view?role',
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/dispatch-view/sidebar-overview.html',
                    controller: 'DispatchViewSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/dispatch-view/overview.html',
                    controller: 'DispatchViewOverviewController as vm',
                },
            },
            resolve: {
                selectedRole: [
                    '$stateParams',
                    function ($stateParams) {
                        return $stateParams.role ? $stateParams.role : null;
                    },
                ],
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('dispatch_view', 'visit_tracker_admin');
                    },
                ],
            },
        })
            .state('dispatch-view.routes', {
            data: { backAction: 'dispatch-view' },
            url: '/:userId',
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/dispatch-view/sidebar-routes.html',
                    controller: 'DispatchViewSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/dispatch-view/routes.html',
                    controller: 'DispatchViewOverviewController as vm',
                },
            },
        });
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var DispatchViewOverviewController = /** @class */ (function () {
            function DispatchViewOverviewController(DispatchViewService, $scope) {
                this.DispatchViewService = DispatchViewService;
                this.$scope = $scope;
                this.calculating = false;
                this.address = this.DispatchViewService.addressSearchTerm;
                this.locations = this.DispatchViewService.locations;
            }
            DispatchViewOverviewController.$inject = ['DispatchViewService', '$scope'];
            return DispatchViewOverviewController;
        }());
        angular
            .module('consolo')
            .controller('DispatchViewOverviewController', DispatchViewOverviewController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DispatchViewSidebarController = /** @class */ (function () {
            function DispatchViewSidebarController(DispatchViewService, selectedRole) {
                this.DispatchViewService = DispatchViewService;
                this.selectedRole = selectedRole;
                if (selectedRole && this.DispatchViewService.baseRoles) {
                    this.DispatchViewService.setRole(selectedRole);
                }
            }
            DispatchViewSidebarController.$inject = ['DispatchViewService', 'selectedRole'];
            return DispatchViewSidebarController;
        }());
        angular
            .module('consolo')
            .controller('DispatchViewSidebarController', DispatchViewSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));



var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var AgencyCqmTemplateRelationship = /** @class */ (function () {
            function AgencyCqmTemplateRelationship() {
            }
            return AgencyCqmTemplateRelationship;
        }());
        domain.AgencyCqmTemplateRelationship = AgencyCqmTemplateRelationship;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var BillableCode = /** @class */ (function () {
            function BillableCode(billableCode) {
                if (billableCode) {
                    for (var _i = 0, _a = Object.keys(billableCode); _i < _a.length; _i++) {
                        var key = _a[_i];
                        this[key] = billableCode[key];
                    }
                }
            }
            BillableCode.prototype.getPayers = function () {
                if (this.medicaid_payers.length) {
                    return this.medicaid_payers;
                }
                else if (this.medicare_payers.length) {
                    return this.medicare_payers;
                }
                else if (this.private_insurance_payers.length) {
                    return this.private_insurance_payers;
                }
                else if (this.charitable_care_payers.length) {
                    return this.charitable_care_payers;
                }
            };
            return BillableCode;
        }());
        domain.BillableCode = BillableCode;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));



var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CareProgramEnrollment = /** @class */ (function () {
            function CareProgramEnrollment(json) {
                this.id = json ? json.id : null;
                this.care_program_id = json ? json.care_program_id : '';
                this.patient_id = json ? json.patient_id : null;
                this.started_by_id = json.started_by ? json.started_by.id : null;
                this.start_date = json ? json.start_date : null;
                this.ended_by_id = json.ended_by ? json.ended_by.id : null;
                this.end_date = json ? json.end_date : null;
                this.comments = json ? json.comments : '';
            }
            return CareProgramEnrollment;
        }());
        domain.CareProgramEnrollment = CareProgramEnrollment;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CdsItem = /** @class */ (function () {
            function CdsItem() {
            }
            return CdsItem;
        }());
        domain.CdsItem = CdsItem;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));






var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CompoundIngredient = /** @class */ (function () {
            function CompoundIngredient(ingredient) {
                for (var _i = 0, _a = Object.keys(ingredient); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = ingredient[key];
                }
                if (ingredient.source) {
                    this.authoritative_source = ingredient.source;
                }
            }
            CompoundIngredient.prototype.fromFormulary = function () {
                return this.creation_source === 'formulary' || this.authoritative_source === 'formulary';
            };
            CompoundIngredient.prototype.fromMedispan = function () {
                return this.creation_source === 'medispan' || this.authoritative_source === 'medispan';
            };
            CompoundIngredient.prototype.fromFavorites = function () {
                return this.creation_source === 'favorites' || this.authoritative_source === 'favorites';
            };
            CompoundIngredient.prototype.isControlledSubstance = function () {
                return this.dea_class === '2' || this.controlled_substance_code === '2';
            };
            CompoundIngredient.prototype.dosage = function () {
                return this.strength + ' ' + this.strength_unit + ' ' + this.form;
            };
            return CompoundIngredient;
        }());
        domain.CompoundIngredient = CompoundIngredient;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));




var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CPTGroup = /** @class */ (function () {
            function CPTGroup() {
                this.visit_type = '';
                this.setting = '';
                this.key_components = {
                    key_components: [],
                };
            }
            return CPTGroup;
        }());
        domain.CPTGroup = CPTGroup;
        var CPTCode = /** @class */ (function () {
            function CPTCode() {
                this.cpt_group_id = '';
                this.description = '';
                this.payer = '';
                this.code = '';
                this.duration = '';
                this.rate = null;
                this.rate_effective_date = '';
                this.key_components = {
                    key_components: [],
                };
                this.unified_payer_ids = [];
                this.insurance_carrier_id = null;
                this.cpt_code_rates = [];
                this.cpt_code_rate_ids = [];
                this.modifier = '';
            }
            return CPTCode;
        }());
        domain.CPTCode = CPTCode;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CqmTemplateOption = /** @class */ (function () {
            function CqmTemplateOption() {
            }
            return CqmTemplateOption;
        }());
        domain.CqmTemplateOption = CqmTemplateOption;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CqmTemplateRule = /** @class */ (function () {
            function CqmTemplateRule() {
            }
            return CqmTemplateRule;
        }());
        domain.CqmTemplateRule = CqmTemplateRule;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var ICqmTemplate = /** @class */ (function () {
            function ICqmTemplate() {
            }
            return ICqmTemplate;
        }());
        domain.ICqmTemplate = ICqmTemplate;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));



var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var DiagnosticImageResult = /** @class */ (function () {
            function DiagnosticImageResult(id, patient_id, examination_date, examination, examination_text, technique, indication, comparison, encounter, findings, impression, performed_by, created_at, updated_at) {
                this.id = id;
                this.patient_id = patient_id;
                this.examination_date = examination_date;
                this.examination = examination;
                this.examination_text = examination_text;
                this.technique = technique;
                this.indication = indication;
                this.comparison = comparison;
                this.encounter = encounter;
                this.findings = findings;
                this.impression = impression;
                this.performed_by = performed_by;
                this.created_at = created_at;
                this.updated_at = updated_at;
            }
            return DiagnosticImageResult;
        }());
        domain.DiagnosticImageResult = DiagnosticImageResult;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var DocusignUser = /** @class */ (function () {
            function DocusignUser() {
            }
            return DocusignUser;
        }());
        domain.DocusignUser = DocusignUser;
        var DocusignRecipient = /** @class */ (function () {
            function DocusignRecipient() {
            }
            return DocusignRecipient;
        }());
        domain.DocusignRecipient = DocusignRecipient;
        var DocusignTemplate = /** @class */ (function () {
            function DocusignTemplate(patientId, templateId) {
                this.patient_id = patientId;
                this.template_id = templateId;
            }
            return DocusignTemplate;
        }());
        domain.DocusignTemplate = DocusignTemplate;
        var DocusignTemplateOptions = /** @class */ (function () {
            function DocusignTemplateOptions(patientId, templateIds) {
                this.patient_id = patientId;
                this.template_ids = templateIds;
            }
            return DocusignTemplateOptions;
        }());
        domain.DocusignTemplateOptions = DocusignTemplateOptions;
        var DocusignEnvelope = /** @class */ (function () {
            function DocusignEnvelope(patientId, envelopeId) {
                this.patient_id = patientId;
                this.envelope_id = envelopeId;
            }
            return DocusignEnvelope;
        }());
        domain.DocusignEnvelope = DocusignEnvelope;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var DosageDate = /** @class */ (function () {
            function DosageDate(dosageDate) {
                for (var _i = 0, _a = Object.keys(dosageDate); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'dosage_times') {
                        this[key] = dosageDate[key].map(function (dosageTime) {
                            return new domain.DosageTime(dosageTime);
                        });
                    }
                    else if (dosageDate[key]) {
                        this[key] = dosageDate[key];
                    }
                }
            }
            return DosageDate;
        }());
        domain.DosageDate = DosageDate;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var DosageTime = /** @class */ (function () {
            function DosageTime(dosageTime) {
                for (var _i = 0, _a = Object.keys(dosageTime); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'effective_datetime') {
                        this[key] = new Date(dosageTime[key]);
                    }
                    else if (dosageTime[key]) {
                        this[key] = dosageTime[key];
                    }
                }
            }
            Object.defineProperty(DosageTime.prototype, "timeZone", {
                get: function () {
                    return this._timeZone;
                },
                set: function (timeZone) {
                    this._timeZone = timeZone;
                },
                enumerable: true,
                configurable: true
            });
            DosageTime.prototype.getHours = function () {
                return this.effective_datetime.getHours();
                if (this._timeZone) {
                    var adjustedDate = moment
                        .tz(this.effective_datetime, this.timeZone)
                        .format()
                        .split('-');
                    var without_timezone = adjustedDate[0] + '-' + adjustedDate[1] + '-' + adjustedDate[2];
                    return new Date(without_timezone).getHours();
                }
                else {
                    return this.effective_datetime.getHours();
                }
            };
            return DosageTime;
        }());
        domain.DosageTime = DosageTime;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        function optionToDropdownItem(option, valueField) {
            return {
                name: option.description,
                value: option[valueField],
            };
        }
        domain.optionToDropdownItem = optionToDropdownItem;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));



var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var DateRangeSearchFilter = /** @class */ (function () {
            function DateRangeSearchFilter() {
            }
            DateRangeSearchFilter.prototype.update_range = function () {
                var today = new Date();
                switch (this.range_type) {
                    case 'today':
                        this.start_date = new Date(today.toDateString());
                        this.end_date = new Date(today.toDateString());
                        break;
                    case 'last_7':
                        this.end_date = new Date(today.toDateString());
                        this.start_date = new Date(this.end_date.toDateString());
                        this.start_date.setDate(this.start_date.getDate() - 7);
                        break;
                    case 'last_14':
                        this.end_date = new Date(today.toDateString());
                        this.start_date = new Date(this.end_date.toDateString());
                        this.start_date.setDate(this.start_date.getDate() - 14);
                        break;
                    case 'last_30':
                        this.end_date = new Date(today.toDateString());
                        this.start_date = new Date(this.end_date.toDateString());
                        this.start_date.setDate(this.start_date.getDate() - 30);
                        break;
                    case 'month_to_date':
                        this.end_date = new Date(today.toDateString());
                        this.start_date = new Date(today.toDateString());
                        this.start_date.setDate(1);
                        break;
                    case 'previous_month':
                        this.end_date = new Date(new Date().setDate(0)); //last day of previous month
                        this.start_date = new Date(new Date(this.end_date.toDateString()).setDate(1));
                        break;
                    case 'year_to_date':
                        this.end_date = new Date(new Date().toDateString());
                        this.start_date = new Date(new Date().toDateString());
                        this.start_date.setDate(1);
                        this.start_date.setMonth(0);
                        break;
                    case 'all_after':
                    case 'all_before':
                    case 'custom':
                        //Handle these on a case by case basis.
                        //all_before / all_after could have limits on how far in the past/future to set
                        break;
                }
            };
            return DateRangeSearchFilter;
        }());
        var Filter = /** @class */ (function (_super) {
            __extends(Filter, _super);
            function Filter() {
                var _this = _super.call(this) || this;
                _this.active = false;
                _this.cancelled = false;
                _this.deceased = false;
                _this.postponed = false;
                _this.pending_never_admitted = false;
                _this.pending_discharged = false;
                _this.mrn = '';
                _this.patient_id = undefined;
                _this.name = '';
                _this.office_id = undefined;
                _this.office_ids = new Array();
                _this.page = 1;
                _this.page_size = 25;
                _this.start_date = undefined;
                _this.end_date = undefined;
                _this.only_deleted = false;
                _this.include_deleted = false;
                _this.care_plan_oversight = false;
                _this.has_assignment = false;
                _this.drug_schedules = [];
                _this.physician_order_status = '';
                _this.team_id = undefined;
                _this.address_search = '';
                _this.payer_type = '';
                _this.pbm_configuration_id = undefined;
                _this.method_added = undefined;
                _this.only_local_rates = false;
                _this.only_global_rates = false;
                _this.filterDisplayInformation = {
                    patient_id: {
                        displayName: 'name',
                        lookupValue: 'patients',
                        selectedItem: 'patient',
                        lookupKey: 'id',
                        usesLookup: true,
                    },
                    office_id: {
                        displayName: 'name',
                        lookupValue: 'offices',
                        lookupKey: 'id',
                        selectedItem: 'office',
                        usesLookup: true,
                    },
                    team_id: {
                        displayName: 'name',
                        lookupValue: 'teams',
                        lookupKey: 'id',
                        selectedItem: 'team',
                        usesLookup: true,
                    },
                    physician_id: {
                        displayName: 'name',
                        lookupValue: 'physicians',
                        lookupKey: 'id',
                        selectedItem: 'physician',
                        usesLookup: true,
                    },
                    nurse_id: {
                        displayName: 'name',
                        lookupValue: 'users',
                        lookupKey: 'id',
                        selectedItem: 'nurse',
                        usesLookup: true,
                    },
                    include_deleted: {
                        chipDisplay: 'Include Deleted',
                        appendValue: false,
                    },
                    only_deleted: {
                        chipDisplay: 'Only Deleted',
                        appendValue: false,
                    },
                    has_medications: {
                        chipDisplay: 'Includes Medications',
                        appendValue: false,
                    },
                    has_controlled_medications: {
                        chipDisplay: 'Includes Schedule 2 Medications',
                        appendValue: false,
                    },
                    drug_schedules: {
                        chipDisplay: 'Drug Schedule(s): ',
                        isArray: true,
                    },
                    eprescribed: {
                        chipDisplay: 'E-Prescribed?',
                        appendValue: false,
                    },
                    care_plan_oversight: {
                        chipDisplay: 'Only Physician Care Plan Oversight',
                        appendValue: false,
                    },
                    has_assignment: {
                        chipDisplay: 'Has Assignment',
                        appendValue: false,
                    },
                    date_range: {
                        selectedItems: ['start_date', 'end_date', 'date_range', 'range_type'],
                    },
                    physician_order_status: {
                        selectValues: 'signatureSelection',
                        selectedItem: 'signatureStatus',
                        lookupKey: 'description',
                    },
                    county_id: {
                        displayName: 'description',
                        lookupValue: 'counties',
                        selectedItem: 'county',
                        lookupKey: 'id',
                        usesLookup: true,
                    },
                    medicaid_payer_uuid: {
                        displayName: 'name',
                        lookupValue: 'medicaidPayers',
                        selectedItem: 'medicaidPayer',
                        lookupKey: 'id',
                        usesLookup: true,
                    },
                    private_insurance_payer_uuid: {
                        displayName: 'name',
                        lookupValue: 'privateInsurancePayers',
                        selectedItem: 'privateInsurancePayer',
                        lookupKey: 'id',
                        usesLookup: true,
                    },
                    hospice_payer_id: {
                        displayName: 'description',
                        lookupValue: 'payerSelection',
                        selectedItem: 'hospicePayer',
                        lookupKey: 'id',
                        usesLookup: true,
                    },
                    only_local_rates: {
                        chipDisplay: 'Only Local Rates',
                        appendValue: false,
                    },
                    only_global_rates: {
                        chipDisplay: 'Only Global Rates',
                        appendValue: false,
                    },
                };
                return _this;
            }
            Filter.prototype.getFilters = function () {
                var validFilter = {};
                for (var _i = 0, _a = Object.keys(this.applicableFilters); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (this[key]) {
                        validFilter[key] = this[key];
                    }
                }
                if (this.patient_id && !validFilter['patient_id']) {
                    validFilter['patient_id'] = this.patient_id;
                }
                else if (this.patient && !validFilter['patient_id']) {
                    this.patient_id = this.patient.id;
                    validFilter['patient_id'] = this.patient_id;
                }
                if (this.start_date) {
                    validFilter['start_date'] = this.start_date;
                }
                if (this.end_date) {
                    validFilter['end_date'] = this.end_date;
                }
                return validFilter;
            };
            Filter.prototype.showPatientSearch = function () {
                return this.applicableFilters.patientSearch || this.applicableFilters.patient_id;
            };
            Filter.prototype.showAttributes = function () {
                return (this.applicableFilters.discipline ||
                    this.applicableFilters.team_id ||
                    this.applicableFilters.nurse_id);
            };
            Filter.prototype.showStatus = function () {
                return (this.applicableFilters.include_deleted ||
                    this.applicableFilters.only_deleted ||
                    this.applicableFilters.physician_order_status ||
                    this.applicableFilters.has_medications ||
                    this.applicableFilters.has_controlled_medications);
            };
            Filter.prototype.showCorporate = function () {
                return this.applicableFilters.office_ids;
            };
            return Filter;
        }(DateRangeSearchFilter));
        domain.Filter = Filter;
        var PlanOfCareFilter = /** @class */ (function (_super) {
            __extends(PlanOfCareFilter, _super);
            function PlanOfCareFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    dateRange: true,
                    office: true,
                    patientSearch: true,
                    hasSignature: true,
                    hasPhysicianSignature: true,
                    team: true,
                    createdBy: true,
                    includesSections: true,
                };
                _this.title = 'Plan of Care Filters';
                return _this;
            }
            return PlanOfCareFilter;
        }(Filter));
        domain.PlanOfCareFilter = PlanOfCareFilter;
        var MedicationKitFilter = /** @class */ (function (_super) {
            __extends(MedicationKitFilter, _super);
            function MedicationKitFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = { office: true };
                _this.title = 'Medication Kits Filters';
                return _this;
            }
            return MedicationKitFilter;
        }(Filter));
        domain.MedicationKitFilter = MedicationKitFilter;
        var TrackerDashboardFilter = /** @class */ (function (_super) {
            __extends(TrackerDashboardFilter, _super);
            function TrackerDashboardFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = { office_id: true };
                _this.title = 'Tracker Dashboard Filters';
                return _this;
            }
            return TrackerDashboardFilter;
        }(Filter));
        domain.TrackerDashboardFilter = TrackerDashboardFilter;
        var LocalPayersFilter = /** @class */ (function (_super) {
            __extends(LocalPayersFilter, _super);
            function LocalPayersFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = { office: true, payer_type: true, name: true, address_search: true };
                _this.title = 'Insurance Payers Filters';
                return _this;
            }
            return LocalPayersFilter;
        }(Filter));
        domain.LocalPayersFilter = LocalPayersFilter;
        var ClaimsFilter = /** @class */ (function (_super) {
            __extends(ClaimsFilter, _super);
            function ClaimsFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    office: true,
                    payer_type: true,
                    patient_id: true,
                    patientSearch: true,
                };
                _this.title = 'Claims Filters';
                return _this;
            }
            return ClaimsFilter;
        }(Filter));
        domain.ClaimsFilter = ClaimsFilter;
        var ChartSearchFilters = /** @class */ (function (_super) {
            __extends(ChartSearchFilters, _super);
            function ChartSearchFilters() {
                var _this = _super.call(this) || this;
                _this.page_size = 25;
                _this.page = 1;
                _this.sort_by = 'effective_date';
                _this.sort_dir = csg.datatable.ISort.desc;
                _this.range_type = 'last_30';
                _super.prototype.update_range.call(_this);
                _this.has_evv = 'any';
                _this.has_patient_time = 'any';
                _this.has_physician_signature = 'any';
                _this.has_postmortem = 'any';
                _this.has_signature = 'any';
                _this.deleted = 'no';
                _this.submitted_to_coderyte = 'any';
                _this.processed_by_coderyte = 'any';
                _this.encounter_types = [];
                _this.clinical_chart_types = [];
                _this.office_ids = [];
                _this.user_id = null;
                _this.patient_id = null;
                _this.team_id = null;
                _this.created_by_id = null;
                _this.discipline = null;
                _this.role_symbol = null;
                return _this;
            }
            return ChartSearchFilters;
        }(DateRangeSearchFilter));
        domain.ChartSearchFilters = ChartSearchFilters;
        var BillingDemographicsFilter = /** @class */ (function (_super) {
            __extends(BillingDemographicsFilter, _super);
            function BillingDemographicsFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = { date_range: true, patient_id: true };
                _this.title = 'Billing Demographics Filters';
                return _this;
            }
            return BillingDemographicsFilter;
        }(Filter));
        domain.BillingDemographicsFilter = BillingDemographicsFilter;
        var EdmontonAssessmentsFilter = /** @class */ (function (_super) {
            __extends(EdmontonAssessmentsFilter, _super);
            function EdmontonAssessmentsFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = { date_range: true };
                _this.title = 'Edmonton Assessments Filters';
                return _this;
            }
            return EdmontonAssessmentsFilter;
        }(Filter));
        domain.EdmontonAssessmentsFilter = EdmontonAssessmentsFilter;
        var PatientSearchFilters = /** @class */ (function () {
            function PatientSearchFilters() {
                this.active = true;
                this.cancelled = false;
                this.deceased = false;
                this.postponed = false;
                this.pending_never_admitted = true;
                this.pending_discharged = true;
                this.mrn = '';
                this.patientCareStatus = [];
                this.patient_id = undefined;
                this.name = '';
                this.office_id = undefined;
                this.office_ids = new Array();
                this.org_ids = new Array();
                this.region_ids = new Array();
                this.area_ids = new Array();
                this.page_size = 25;
            }
            return PatientSearchFilters;
        }());
        domain.PatientSearchFilters = PatientSearchFilters;
        var FamilyMemberFilters = /** @class */ (function () {
            function FamilyMemberFilters(patientId) {
                this.patient_id = undefined;
                this.name = '';
                this.address = '';
                this.email = '';
                this.referral_type = '';
                this.include_deleted = false;
                this.office_ids = new Array();
                this.page_size = 25;
                if (patientId) {
                    this.patient_id = patientId;
                }
            }
            return FamilyMemberFilters;
        }());
        domain.FamilyMemberFilters = FamilyMemberFilters;
        var DocusignFilters = /** @class */ (function () {
            function DocusignFilters(patientId) {
                this.sort_by = 'description';
                this.sort_dir = csg.datatable.ISort.asc;
                this.page = 1;
                this.page_size = 25;
                if (patientId) {
                    this.patient_id = patientId;
                }
            }
            return DocusignFilters;
        }());
        domain.DocusignFilters = DocusignFilters;
        var HumanResourcesDashboardFilters = /** @class */ (function () {
            function HumanResourcesDashboardFilters() {
                this.organization_type = '';
                this.organization_ids = new Array();
                this.page_size = 25;
            }
            return HumanResourcesDashboardFilters;
        }());
        domain.HumanResourcesDashboardFilters = HumanResourcesDashboardFilters;
        var PhysicianOrderFilter = /** @class */ (function (_super) {
            __extends(PhysicianOrderFilter, _super);
            function PhysicianOrderFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    date_range: true,
                    office_id: true,
                    patientSearch: true,
                    include_deleted: true,
                    only_deleted: true,
                    physician_id: true,
                    team_id: true,
                    physician_order_status: true,
                    care_plan_oversight: true,
                    has_assignment: true,
                    drug_schedules: true,
                    nurse_id: true,
                    has_medications: true,
                    patient_id: true,
                    eprescribed: true,
                };
                _this.title = 'Physician Order Filters';
                return _this;
            }
            return PhysicianOrderFilter;
        }(Filter));
        domain.PhysicianOrderFilter = PhysicianOrderFilter;
        var HospiceRatesFilter = /** @class */ (function (_super) {
            __extends(HospiceRatesFilter, _super);
            function HospiceRatesFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    start_date: true,
                    office_id: true,
                    hospice_payer_id: true,
                    medicaid_payer_uuid: true,
                    county_id: true,
                    private_insurance_payer_uuid: true,
                    only_local_rates: true,
                    only_global_rates: true,
                };
                _this.title = 'Hospice Rates Filters';
                return _this;
            }
            return HospiceRatesFilter;
        }(Filter));
        domain.HospiceRatesFilter = HospiceRatesFilter;
        var MedicationFormularyFilter = /** @class */ (function (_super) {
            __extends(MedicationFormularyFilter, _super);
            function MedicationFormularyFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    date_range: true,
                    drug_schedules: true,
                    method_added: true,
                };
                return _this;
            }
            return MedicationFormularyFilter;
        }(Filter));
        domain.MedicationFormularyFilter = MedicationFormularyFilter;
        var FormularyExceptionRequestsFilter = /** @class */ (function (_super) {
            __extends(FormularyExceptionRequestsFilter, _super);
            function FormularyExceptionRequestsFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    date_range: true,
                    physician_id: true,
                    patient_id: true,
                    office_id: true,
                };
                return _this;
            }
            return FormularyExceptionRequestsFilter;
        }(Filter));
        domain.FormularyExceptionRequestsFilter = FormularyExceptionRequestsFilter;
        var TeamsFilters = /** @class */ (function () {
            function TeamsFilters(teamId) {
                this.name = '';
                this.teamCount = 0;
                this.page_size = 25;
            }
            return TeamsFilters;
        }());
        domain.TeamsFilters = TeamsFilters;
        var BillableCodesFilter = /** @class */ (function (_super) {
            __extends(BillableCodesFilter, _super);
            function BillableCodesFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    date_range: true,
                    unified_payer_id: true,
                    hospice_payer_id: true,
                };
                return _this;
            }
            return BillableCodesFilter;
        }(Filter));
        domain.BillableCodesFilter = BillableCodesFilter;
        var PhysicianChargesFilter = /** @class */ (function (_super) {
            __extends(PhysicianChargesFilter, _super);
            function PhysicianChargesFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    date_range: true,
                    physician_id: true,
                    office_id: true,
                    patient_id: true,
                    unified_payer_id: true,
                    billable_code: true,
                };
                return _this;
            }
            return PhysicianChargesFilter;
        }(Filter));
        domain.PhysicianChargesFilter = PhysicianChargesFilter;
        var PriorAuthorizationsFilter = /** @class */ (function (_super) {
            __extends(PriorAuthorizationsFilter, _super);
            function PriorAuthorizationsFilter() {
                var _this = _super.call(this) || this;
                _this.applicableFilters = {
                    date_range: true,
                    patient_id: true,
                };
                return _this;
            }
            return PriorAuthorizationsFilter;
        }(Filter));
        domain.PriorAuthorizationsFilter = PriorAuthorizationsFilter;
        var IndicatorFilters = /** @class */ (function () {
            function IndicatorFilters(patient_id) {
                this.patient_id = patient_id;
                this.page = 1;
                this.page_size = 10;
            }
            return IndicatorFilters;
        }());
        domain.IndicatorFilters = IndicatorFilters;
        var CqmTemplateFilter = /** @class */ (function () {
            function CqmTemplateFilter() {
                this.page = 1;
                this.page_size = 10;
            }
            return CqmTemplateFilter;
        }());
        domain.CqmTemplateFilter = CqmTemplateFilter;
        var DiagnosticImageResultFilter = /** @class */ (function () {
            function DiagnosticImageResultFilter() {
                this.page = 1;
                this.page_size = 10;
            }
            return DiagnosticImageResultFilter;
        }());
        domain.DiagnosticImageResultFilter = DiagnosticImageResultFilter;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var ForumThread = /** @class */ (function () {
            function ForumThread(forumThread) {
                for (var _i = 0, _a = Object.keys(forumThread); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'effective_date' && forumThread[key]) {
                        this[key] = new Date(forumThread[key]);
                    }
                    else {
                        this[key] = forumThread[key];
                    }
                }
                if (!this.effective_date) {
                    this.effective_date = new Date();
                }
                var dateManipulation = this.effective_date
                    .toJSON()
                    .slice(0, 10)
                    .split('-');
                this.noteDisplay =
                    dateManipulation[1] +
                        '/' +
                        dateManipulation[2] +
                        '/' +
                        dateManipulation[0] +
                        ' - ' +
                        this.created_by.name;
            }
            return ForumThread;
        }());
        domain.ForumThread = ForumThread;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));



var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var HumanResourcesDashboard = /** @class */ (function () {
            function HumanResourcesDashboard() {
                this.user = new domain.User();
            }
            return HumanResourcesDashboard;
        }());
        domain.HumanResourcesDashboard = HumanResourcesDashboard;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var ImplantableDevice = /** @class */ (function () {
            function ImplantableDevice(json) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                this.id = (_b = (_a = json) === null || _a === void 0 ? void 0 : _a.id, (_b !== null && _b !== void 0 ? _b : ''));
                this.patient_id = (_d = (_c = json) === null || _c === void 0 ? void 0 : _c.patient_id, (_d !== null && _d !== void 0 ? _d : ''));
                this.description = (_f = (_e = json) === null || _e === void 0 ? void 0 : _e.description, (_f !== null && _f !== void 0 ? _f : ''));
                this.udi = (_h = (_g = json) === null || _g === void 0 ? void 0 : _g.udi, (_h !== null && _h !== void 0 ? _h : ''));
                this.snomed_code = (_k = (_j = json) === null || _j === void 0 ? void 0 : _j.snomed_code, (_k !== null && _k !== void 0 ? _k : ''));
                this.active = (_m = (_l = json) === null || _l === void 0 ? void 0 : _l.active, (_m !== null && _m !== void 0 ? _m : false));
                this.lookup_data = (_p = (_o = json) === null || _o === void 0 ? void 0 : _o.lookup_data, (_p !== null && _p !== void 0 ? _p : null));
                this.snomed_data = (_r = (_q = json) === null || _q === void 0 ? void 0 : _q.snomed_data, (_r !== null && _r !== void 0 ? _r : null));
                this.implant_date = (_t = (_s = json) === null || _s === void 0 ? void 0 : _s.implant_date, (_t !== null && _t !== void 0 ? _t : ''));
            }
            return ImplantableDevice;
        }());
        domain.ImplantableDevice = ImplantableDevice;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Indicator = /** @class */ (function () {
            function Indicator(json) {
                this.user_id = json ? json.user_id : null;
                this.patient_id = json ? json.patient_id : null;
                this.effective_date = json ? json.effective_date : new Date();
                this.treatments_to_date = json ? json.treatments_to_date : '';
                this.other_health_problems = json ? json.other_health_problems : '';
                this.other_comorbid = json ? json.other_comorbid : '';
                this.icd10_health_conditions = json ? json.icd10_health_conditions : [];
                this.diagnoses = json ? json.diagnoses : [];
                this.icd10s = json ? json.icd10s : [];
                if (json && json.icd10s.length > 0) {
                    this.icd10_health_conditions = json.icd10s.map(function (icd10, index) {
                        return {
                            icd10_id: icd10.id ? icd10.id : icd10.icd10_id,
                            position: index,
                        };
                    });
                }
                if (json && json.diagnoses.length > 0) {
                    this.diagnoses = json.diagnoses.map(function (diagnosis) {
                        return {
                            diagnosis_template_id: diagnosis.diagnosis_template.id,
                            narrative: diagnosis.narrative,
                            icd10_health_conditions: diagnosis.icd10_health_conditions.map(function (icd10, index) {
                                return {
                                    icd10_id: diagnosis.icd10s[index] ? diagnosis.icd10s[index].id : icd10.icd10_id,
                                    position: index,
                                    diagnosis_id: icd10.diagnoses_id ? icd10.diagnoses_id : null,
                                };
                            }),
                            symptoms: diagnosis.symptoms.map(function (symptom) {
                                return {
                                    symptom_template_id: symptom.symptom_template_id,
                                };
                            }),
                        };
                    });
                }
            }
            return Indicator;
        }());
        domain.Indicator = Indicator;
        var LCDWorksheet = /** @class */ (function () {
            function LCDWorksheet(diagnosisTemplate) {
                this.icd10s = [];
                this.icd10_health_conditions = [];
                this.narrative = '';
                this.symptom_descriptions = [];
                this.symptoms = [];
                this.diagnosis_template = diagnosisTemplate;
                this.diagnosis_template_id = diagnosisTemplate.id;
                this.name = diagnosisTemplate.name;
            }
            return LCDWorksheet;
        }());
        domain.LCDWorksheet = LCDWorksheet;
        var Icd10HealthCondition = /** @class */ (function () {
            function Icd10HealthCondition(id, icd10_id, index, diagnosisId) {
                this.id = id ? id : null;
                this.icd10_id = icd10_id ? icd10_id : null;
                this.diagnosis_id = diagnosisId ? diagnosisId : null;
                this.position = index ? index : 0;
            }
            return Icd10HealthCondition;
        }());
        domain.Icd10HealthCondition = Icd10HealthCondition;
        var Icd = /** @class */ (function () {
            function Icd(json) {
                this.id = json.id ? json.id : null;
                this.code = json.code ? json.code : '';
                this.description = json.description ? json.description : '';
            }
            return Icd;
        }());
        domain.Icd = Icd;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var InsurancePolicy = /** @class */ (function () {
            function InsurancePolicy(payerGroup) {
                for (var _i = 0, _a = Object.keys(payerGroup); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'forum_threads') {
                        this[key] = payerGroup[key].map(function (forumThread) {
                            return new domain.ForumThread(forumThread);
                        });
                    }
                    else {
                        this[key] = payerGroup[key];
                    }
                }
                if (!this.forum_threads) {
                    this.forum_threads = [];
                }
                if (!this.will_pay) {
                    this.will_pay = [];
                }
                this.errors = [];
            }
            InsurancePolicy.prototype.clearOutExistingPayerInfo = function () {
                if (this.insurance_carrier_id !== 1) {
                    this.medicare_payer_uuid = null;
                    this.medicare_payer = null;
                }
                if (this.insurance_carrier_id !== 2) {
                    this.medicaid_payer_uuid = null;
                    this.medicaid_payer = null;
                }
                if (this.insurance_carrier_id !== 3) {
                    this.private_insurance_payer_uuid = null;
                    this.private_insurance_payer = null;
                }
                if (this.insurance_carrier_id !== 4) {
                    this.charitable_care_payer_uuid = null;
                    this.charitable_care_payer = null;
                }
            };
            InsurancePolicy.prototype.setPaysRoomAndBoard = function () {
                if (this.will_pay.indexOf('primary_room_and_board') > -1 ||
                    this.will_pay.indexOf('secondary_room_and_board') > -1) {
                    this.paysRoomAndBoard = true;
                }
                else {
                    this.paysRoomAndBoard = false;
                }
            };
            InsurancePolicy.prototype.sortForumThreads = function () {
                this.forum_threads = this.forum_threads.sort(function (a, b) {
                    if (a.effective_date < b.effective_date) {
                        return 1;
                    }
                    if (a.effective_date > b.effective_date) {
                        return -1;
                    }
                    return 0;
                });
            };
            InsurancePolicy.prototype.setName = function () {
                if (this.medicaid_payer) {
                    this.name = this.medicaid_payer.name;
                }
                else if (this.medicare_payer) {
                    this.name = this.medicare_payer.name;
                }
                else if (this.charitable_care_payer) {
                    this.name = this.charitable_care_payer.name;
                }
                else if (this.private_insurance_payer) {
                    this.name = this.private_insurance_payer.name;
                }
            };
            InsurancePolicy.prototype.getPayer = function () {
                if (this.medicaid_payer) {
                    return this.medicaid_payer;
                }
                else if (this.medicare_payer) {
                    return this.medicare_payer;
                }
                else if (this.charitable_care_payer) {
                    return this.charitable_care_payer;
                }
                else if (this.private_insurance_payer) {
                    return this.private_insurance_payer;
                }
            };
            return InsurancePolicy;
        }());
        domain.InsurancePolicy = InsurancePolicy;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var LabPanel = /** @class */ (function () {
            function LabPanel(labPanel) {
                for (var _i = 0, _a = Object.keys(labPanel); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if ((key === 'collected_time' || key === 'received_time') && labPanel[key]) {
                        this[key] = new Date(labPanel[key]);
                    }
                    else if (key === 'labResults') {
                        this.lab_results = labPanel[key].map(function (result) {
                            return new domain.LabResult(result);
                        });
                    }
                    else {
                        this[key] = labPanel[key];
                    }
                }
                if (!this.lab_results) {
                    this.lab_results = [];
                }
            }
            // Validate the lab panel to ensure we have all we need
            LabPanel.prototype.isValid = function () {
                var errors = [];
                if (!this.location_name) {
                    errors.push('Location Name is required.');
                }
                if (!this.specimen) {
                    errors.push('Specimen is required.');
                }
                if (!this.report_time) {
                    errors.push('Report Date/Time is required.');
                }
                else {
                    if (this.collected_time && this.collected_time > this.report_time) {
                        errors.push('Collected Date/Time must be on or before Report Date/Time.');
                    }
                    if (this.received_time && this.received_time > this.report_time) {
                        errors.push('Received Date/Time must be on or before Report Date/Time.');
                    }
                    if (this.collected_time && this.received_time) {
                        if (this.collected_time > this.received_time) {
                            errors.push('Collected Date/Time must be on or before Received Date/Time.');
                        }
                    }
                }
                this.errors = errors;
                if (errors.length) {
                    return false;
                }
                return true;
            };
            return LabPanel;
        }());
        domain.LabPanel = LabPanel;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var LabPanelTemplate = /** @class */ (function () {
            function LabPanelTemplate(labPanelTemplate) {
                for (var _i = 0, _a = Object.keys(labPanelTemplate); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if ((key === 'collected_time' || key === 'received_time') && labPanelTemplate[key]) {
                        this[key] = new Date(labPanelTemplate[key]);
                    }
                    else if (key === 'labResultTemplates') {
                        this.lab_result_templates = labPanelTemplate[key].map(function (result) {
                            return new domain.LabResultTemplate(result);
                        });
                    }
                    else {
                        this[key] = labPanelTemplate[key];
                    }
                }
                if (!this.lab_result_templates) {
                    this.lab_result_templates = [];
                }
            }
            return LabPanelTemplate;
        }());
        domain.LabPanelTemplate = LabPanelTemplate;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var LabResult = /** @class */ (function () {
            function LabResult(labResult) {
                for (var _i = 0, _a = Object.keys(labResult); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = labResult[key];
                }
            }
            // Validate the lab result to ensure we have all we need
            LabResult.prototype.isValid = function () {
                var errors = [];
                if (!this.test_value && !this.data_absent_reason) {
                    errors.push('Either a Value or a Data Absence Reason must be provided.');
                }
                if (this.test_value && this.data_absent_reason) {
                    errors.push('Both a Value and a Data Absence Reason should not be provided.');
                }
                // if (this.test_value && Number(this.test_value) && !this.units) {
                //   errors.push('If Value is numeric, Units must be provided.');
                // }
                if (this.abnormal && !this.abnormal_range) {
                    errors.push('Abnormal Range is required if Abnormal is selected.');
                }
                if (!this.abnormal && this.abnormal_range) {
                    errors.push('Abnormal Range provided, but Abnormal was not selected.');
                }
                this.errors = errors;
                if (errors.length) {
                    return false;
                }
                return true;
            };
            return LabResult;
        }());
        domain.LabResult = LabResult;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var LabResultTemplate = /** @class */ (function () {
            function LabResultTemplate(labResultTemplate) {
                for (var _i = 0, _a = Object.keys(labResultTemplate); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = labResultTemplate[key];
                }
            }
            // Really just a placeholder for now
            LabResultTemplate.prototype.isValid = function () {
                var errors = [];
                this.errors = errors;
                return true;
            };
            return LabResultTemplate;
        }());
        domain.LabResultTemplate = LabResultTemplate;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var LabTemplate = /** @class */ (function () {
            function LabTemplate(labDefault) {
                for (var _i = 0, _a = Object.keys(labDefault); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = labDefault[key];
                }
            }
            return LabTemplate;
        }());
        domain.LabTemplate = LabTemplate;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Loinc = /** @class */ (function () {
            function Loinc(loinc) {
                for (var _i = 0, _a = Object.keys(loinc); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = loinc[key];
                }
            }
            return Loinc;
        }());
        domain.Loinc = Loinc;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var LoincWithLabTemplate = /** @class */ (function () {
            function LoincWithLabTemplate(loinc) {
                for (var _i = 0, _a = Object.keys(loinc); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = loinc[key];
                }
            }
            return LoincWithLabTemplate;
        }());
        domain.LoincWithLabTemplate = LoincWithLabTemplate;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));




var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var IMedicationFormularyEntry = /** @class */ (function () {
            function IMedicationFormularyEntry() {
            }
            return IMedicationFormularyEntry;
        }());
        domain.IMedicationFormularyEntry = IMedicationFormularyEntry;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var MedicationTransaction = /** @class */ (function () {
            function MedicationTransaction(medicationTransaction) {
                for (var _i = 0, _a = Object.keys(medicationTransaction); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'effective_datetime') {
                        this[key] = new Date(medicationTransaction[key]);
                    }
                    else {
                        this[key] = medicationTransaction[key];
                    }
                }
            }
            MedicationTransaction.prototype.isNewRx = function () {
                return this.transaction_type === 'newrx';
            };
            MedicationTransaction.prototype.isRxRenewalRequest = function () {
                return this.transaction_type === 'rxRenewalRequest';
            };
            MedicationTransaction.prototype.isRxChangeRequest = function () {
                return this.transaction_type === 'rxChangeRequest';
            };
            MedicationTransaction.prototype.isRxChangeResponse = function () {
                return this.transaction_type === 'rxChangeResponse';
            };
            MedicationTransaction.prototype.isRxCancelResponse = function () {
                return this.transaction_type === 'rxCancelResponse';
            };
            MedicationTransaction.prototype.isPriorAuthorizationRequest = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 'p';
            };
            MedicationTransaction.prototype.isPrescriberAuthorization = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 'u';
            };
            MedicationTransaction.prototype.isGenericSubstituion = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 'g';
            };
            MedicationTransaction.prototype.isDrugUseEvaulation = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 'd';
            };
            MedicationTransaction.prototype.isTherapeuticInterchange = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 't';
            };
            MedicationTransaction.prototype.isOutOfStock = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 'os';
            };
            MedicationTransaction.prototype.isScriptClarification = function () {
                return this.message_status_code && this.message_status_code.toLowerCase() === 's';
            };
            MedicationTransaction.prototype.messageDisplay = function () {
                if (this.isPrescriberAuthorization()) {
                    return 'Prescriber Authorization ';
                }
                else if (this.isPriorAuthorizationRequest()) {
                    return 'Prior Authorization ';
                }
                else if (this.isGenericSubstituion()) {
                    return 'Generic Substitution ';
                }
                else if (this.isDrugUseEvaulation()) {
                    return 'Drug Use Evaluation ';
                }
                else if (this.isOutOfStock()) {
                    return 'Out of Stock ';
                }
                else if (this.isTherapeuticInterchange()) {
                    return 'Therapeutic Interchange ';
                }
                else if (this.isScriptClarification()) {
                    return 'Script Clarification ';
                }
                else {
                    return '';
                }
            };
            MedicationTransaction.prototype.changeRequestAllowedToApprove = function () {
                return ((this.isRxChangeRequest() && !this.medication.dea_class) ||
                    this.medication.dea_class.length === 0 ||
                    this.isGenericSubstituion() ||
                    this.isDrugUseEvaulation() ||
                    this.isTherapeuticInterchange());
            };
            return MedicationTransaction;
        }());
        domain.MedicationTransaction = MedicationTransaction;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Medication = /** @class */ (function () {
            function Medication(medication) {
                for (var _i = 0, _a = Object.keys(medication); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'dosage_date') {
                        this[key] = new domain.DosageDate(medication[key]);
                    }
                    else if (key === 'current_medication_transaction' && medication[key] !== null) {
                        this.current_medication_transaction = new domain.MedicationTransaction(medication[key]);
                    }
                    else if (key === 'medication_transactions' && medication[key].length) {
                        this.medication_transactions = medication[key].map(function (transaction) {
                            return new domain.MedicationTransaction(transaction);
                        });
                    }
                    else if (key === 'sliding_scale' && medication[key] !== null) {
                        this[key] = new domain.SlidingScale(medication[key]);
                    }
                    else if (key !== 'dc' &&
                        key !== 'infection_control_reminder_date' &&
                        key !== 'ic_physician_notified_date' &&
                        key !== 'ic_culture_date' &&
                        key !== 'last_refill_date' &&
                        key !== 'disposed' &&
                        key !== 'deleted' &&
                        key !== 'created_at' &&
                        key !== 'next_dosage') {
                        this[key] = medication[key];
                    }
                    else if (medication[key]) {
                        this[key] = new Date(medication[key]);
                    }
                }
                this.full_dosage_list = [];
                if (this.frequency) {
                    this.display_name = this.name + ' ' + this.frequency;
                }
                else {
                    this.frequency = '';
                    if (this.strength && this.strength_unit) {
                        this.frequency = this.strength + ' ' + this.strength_unit;
                    }
                    if (this.route) {
                        this.frequency += ' ' + this.route;
                    }
                    if (this.form) {
                        this.frequency += ' ' + this.form;
                    }
                    this.display_name = this.name + ' ' + this.frequency;
                }
                if (this.sliding_scale) {
                    var dose_level = ((this.sliding_scale.dose_level || this.sliding_scale.dose_level != null) ? this.sliding_scale.dose_level : "");
                    this.display_name += ' Per Sliding Scale ' + dose_level;
                }
                var uniquePharmacies = [];
                this.surescriptPharmacies = '';
                if (this.medication_transactions && this.medication_transactions.length) {
                    this.medication_transactions.forEach(function (medicationTransaction) {
                        if (medicationTransaction.surescripts_pharmacy &&
                            uniquePharmacies.indexOf(medicationTransaction.surescripts_pharmacy.name) === -1) {
                            uniquePharmacies.push(medicationTransaction.surescripts_pharmacy.name);
                        }
                    });
                    this.surescriptPharmacies = uniquePharmacies.join(', ');
                }
                this.fullDescription = this.name + ' ' + this.description + ' - ' + this.ndc;
                this.instructions = this.notes && this.notes.length ? this.notes : '';
                if (this.eprescribe_instructions) {
                    this.instructions += (this.instructions.length ? ' | ' : '') + this.eprescribe_instructions;
                }
                if (this.ingredients) {
                    this.ingredients = this.ingredients.map(function (ingredient) {
                        return new domain.CompoundIngredient(ingredient);
                    });
                }
                this.setRefillsDisabled();
                if (this.quantity) {
                    this.quantity = Number(this.quantity);
                }
                if (!this.dosage_times || !this.dosage_times.length) {
                    this.dosage_times = [];
                }
                if (!this.prn_reason_ids) {
                    this.prn_reason_ids = [];
                }
                if (!this.prn_reasons) {
                    this.prn_reasons = [];
                }
                if (!this.order_status_description) {
                    if (this.order_status === 0) {
                        this.order_status_description = 'Current';
                    }
                    else if (this.order_status === 1) {
                        this.order_status_description = 'Needs Physician Order';
                    }
                    else if (this.order_status === 2) {
                        this.order_status_description = 'Needs Nurse Signature';
                    }
                    else if (this.order_status === 3) {
                        this.order_status_description = 'Needs Physician Signature';
                    }
                }
                if (!this.time_on_edit_screen_in_seconds) {
                    this.time_on_edit_screen_in_seconds = 0;
                }
                else {
                    this.time_on_edit_screen_in_seconds = parseFloat(this.time_on_edit_screen_in_seconds);
                }
                //this.setDosageTimesTimeZone();
            }
            Medication.prototype.newSlidingScale = function () {
                var sliding_scale = new domain.SlidingScale({});
                sliding_scale.sliding_scale_ranges = [];
                // Per ticket, start with 5 blank rows/ranges
                for (var i = 0; i < 4; i++) {
                    sliding_scale.sliding_scale_ranges.push(new domain.SlidingScaleRange({ order: i, low_range: 0, high_range: 0, units: 0, comments: "" }));
                }
                // The last has no low range
                sliding_scale.sliding_scale_ranges.push(new domain.SlidingScaleRange({ order: 4, low_range: null, high_range: 0, units: 0, comments: "" }));
                sliding_scale.reorderRanges();
                return sliding_scale;
            };
            Medication.prototype.canCancel = function () {
                var cancel = false;
                if (this.medication_transactions) {
                    this.medication_transactions.some(function (transaction) {
                        if (['newrx', 'split_rx', 'reorder'].indexOf(transaction.transaction_type) > -1 &&
                            transaction.surescripts_pharmacy_id &&
                            transaction.is_eprescribe) {
                            cancel = true;
                            return true;
                        }
                    });
                }
                return cancel;
            };
            Medication.prototype.setDosageTimesTimeZone = function () {
                var _this = this;
                if (this.timeZone) {
                    this.dosage_date.dosage_times.forEach(function (dosageTime) {
                        dosageTime.timeZone = _this.timeZone;
                    });
                }
            };
            Medication.prototype.setRefillsDisabled = function () {
                this.refills_disabled = !this.refillsAllowed();
            };
            Medication.prototype.refillsDisplay = function () {
                if (this.refills_remaining === null) {
                    return 'Not Entered';
                }
                else {
                    return this.refills_remaining.toString();
                }
            };
            Medication.prototype.ingredientsNames = function () {
                return this.ingredients
                    .map(function (ingredient) {
                    return ingredient.name;
                })
                    .join(', ');
            };
            Medication.prototype.quantityDisplay = function () {
                if (this.quantity === null) {
                    return 'Not Entered';
                }
                else {
                    return this.quantity.toString();
                }
            };
            Medication.prototype.fromFormulary = function () {
                return this.creation_source === 'formulary' || this.authoritative_source === 'formulary';
            };
            Medication.prototype.fromMedispan = function () {
                return this.creation_source === 'medispan' || this.authoritative_source === 'medispan';
            };
            Medication.prototype.fromFavorites = function () {
                return this.creation_source === 'favorites' || this.authoritative_source === 'favorites';
            };
            Medication.prototype.isDisposed = function () {
                var today = new Date();
                if (this.disposed_string && this.disposed <= today) {
                    return true;
                }
                return false;
            };
            Medication.prototype.isFileOnly = function () {
                return this.status === 'fileOnly';
            };
            Medication.prototype.isValid = function () {
                var errors = [];
                if (this.is_scheduled &&
                    this.dosage_times.length &&
                    !this.prn &&
                    ['minute', 'continuous', 'one_time_dose'].indexOf(this.dosage_frequency_unit) === -1) {
                    //Check that all times have been entered
                    this.dosage_times.some(function (dosageTime) {
                        if (!dosageTime || !dosageTime.time || dosageTime.time.length < 4) {
                            errors.push('All Administer Times are required when scheduling a medication, please fill out all times correctly.');
                            return true;
                        }
                    });
                }
                if (this.reason_required_to_add &&
                    (!this.formulary_explanation || this.formulary_explanation.length === 0)) {
                    errors.push('This medication requires a formulary reason.');
                }
                if (this.is_scheduled && !this.dosage_frequency_unit) {
                    errors.push('You must select a Frequency (Day/Hour/Month) when scheduling a medication.');
                }
                if (this.is_scheduled &&
                    !this.dosage_frequency &&
                    ['continuous', 'one_time_dose'].indexOf(this.dosage_frequency_unit) === -1) {
                    errors.push('You must enter how often a medication is scheduled to be administered.');
                }
                if (this.is_scheduled && !this.dosage_frequency_start_datetime) {
                    errors.push('You must enter the start time for a medication when scheduling.');
                }
                if (this.is_scheduled &&
                    !this.dosage_frequency_end_datetime &&
                    this.dosage_frequency_unit !== 'one_time_dose') {
                    errors.push('You must enter the end time for a medication when scheduling.');
                }
                if (this.is_scheduled && this.dosage_frequency_unit === 'one_time_dose') {
                    this.dosage_frequency_end_datetime = this.dosage_frequency_start_datetime;
                }
                if (!this.dosage_unit_id && !this.sliding_scale) {
                    errors.push('You must select a Dosage Unit.');
                }
                // validation for dosage frequency vs emar schedule
                if (this.is_scheduled && this.dosage_times.length &&
                    (this.dosage_frequency_unit == 'hour' && this.dosage_frequency != Math.ceil(24 / this.dosage_times.length)) ||
                    (this.dosage_frequency_unit != 'hour' && this.dosage_frequency != this.dosage_times.length)) {
                    // check if there are scheduled dosage times, if so, the schedule doesn't match the frequency
                    this.dosage_times.some(function (dosageTime) {
                        if (dosageTime && dosageTime.time) {
                            errors.push('Scheduled dosage times do not match dosage frequency.');
                            return true;
                        }
                    });
                }
                if (this.sliding_scale) {
                    this.sliding_scale.isValid(errors);
                }
                if (errors.length) {
                    this.errors = errors;
                    return false;
                }
                return true;
            };
            Medication.prototype.needsPhysicianOrder = function () {
                return this.order_status === domain.OrderStatus.needsPhysicianOrder;
            };
            Medication.prototype.isDiscontinued = function () {
                if (!this.dc) {
                    return false;
                }
                else {
                    return this.dc < new Date();
                }
            };
            Medication.prototype.isDeleted = function () {
                if (this.deleted && this.deleted < new Date()) {
                    return true;
                }
                return false;
            };
            Medication.prototype.isActive = function () {
                return !(this.isDisposed() || this.isDiscontinued() || this.isDeleted());
            };
            Medication.prototype.isCurrent = function () {
                return this.order_status === domain.OrderStatus.current;
            };
            Medication.prototype.isRxRenewalRequest = function () {
                return (this.current_medication_transaction &&
                    this.current_medication_transaction.transaction_type === 'rxRenewalRequest');
            };
            Medication.prototype.isRxChangeRequest = function () {
                return (this.current_medication_transaction &&
                    this.current_medication_transaction.transaction_type === 'rxChangeRequest');
            };
            Medication.prototype.isValidForRefill = function () {
                return this.order_status === domain.OrderStatus.current && !this.is_eprescribe;
            };
            Medication.prototype.showFrequencyAndScheduling = function () {
                if (this.dosage_frequency_start_datetime || this.dosage_frequency_end_datetime) {
                    return true;
                }
                return false;
            };
            Medication.prototype.refillsAllowed = function () {
                return ((!this.is_compound && this.dea_class !== '2') ||
                    (this.is_compound &&
                        this.ingredients.filter(function (a) {
                            return a.dea_class === '2' || a.controlled_substance_code === '2';
                        }).length === 0));
            };
            Medication.prototype.isContinuous = function () {
                return this.dosage_frequency_unit === 'continuous';
            };
            Medication.prototype.isRoutine = function () {
                return this.dosage_frequency_unit !== 'continuous' && !this.prn;
            };
            Medication.prototype.isControlledSubstance = function () {
                return this.dea_class === '2' || this.controlled_substance_code === '2';
            };
            Medication.prototype.isNonControlledSubstance = function () {
                return ((!this.is_compound && (this.dea_class === undefined || this.dea_class === null)) ||
                    (this.is_compound &&
                        this.ingredients.filter(function (a) {
                            return (a.controlled_substance_code !== undefined && a.controlled_substance_code !== null);
                        }).length === 0));
            };
            Medication.prototype.needsUtilizationOrder = function () {
                return this.order_status === domain.OrderStatus.needsUtilizationOrder;
            };
            Medication.prototype.needsDistributionOrder = function () {
                return this.order_status === domain.OrderStatus.needsDistributionOrder;
            };
            Medication.prototype.showAdministeredBy = function () {
                if (this.has_administered_by_family ||
                    this.has_administered_by_staff ||
                    this.has_administered_by_nurse ||
                    this.has_administered_by_patient ||
                    this.has_administered_by_other) {
                    return true;
                }
                return false;
            };
            Medication.prototype.compoundHasAnyControlled = function () {
                var hasControlledIngredient = false;
                if (!this.is_compound) {
                    return false;
                }
                else {
                    this.ingredients.some(function (ingredient) {
                        if (ingredient.controlled_substance_code && ingredient.controlled_substance_code.length) {
                            hasControlledIngredient = true;
                            return true;
                        }
                    });
                }
                return hasControlledIngredient;
            };
            Medication.prototype.showInfectionSignsAndSymptoms = function () {
                if (this.is_anti_infective &&
                    (this.ic_fever ||
                        this.ic_diarrhea ||
                        this.ic_drainage ||
                        this.ic_erythema ||
                        this.ic_redness ||
                        this.ic_sputum ||
                        this.ic_urine ||
                        this.ic_dysuria ||
                        this.ic_mouth ||
                        this.ic_other ||
                        this.ic_other_text ||
                        this.has_cough ||
                        this.has_sore_throat ||
                        this.has_shortness_of_breath ||
                        this.has_fatigue ||
                        this.has_new_confusion ||
                        this.has_traveled_to_confirmed_case ||
                        this.note_traveled_to_confirmed_case ||
                        this.has_been_in_contact_with_confirmed_case)) {
                    return true;
                }
                return false;
            };
            Medication.prototype.showInfectionSupsectedSite = function () {
                if (this.is_anti_infective &&
                    (this.ic_site_urinary ||
                        this.ic_site_foley ||
                        this.ic_site_iv ||
                        this.ic_site_respiratory ||
                        this.ic_site_blood ||
                        this.ic_site_wound ||
                        this.ic_site_gastro ||
                        this.ic_site_other ||
                        this.ic_site_other_text)) {
                    return true;
                }
                return false;
            };
            Medication.prototype.showInfectionActivity = function () {
                if (this.is_anti_infective &&
                    (this.infection_control_reminder_date ||
                        this.ic_physician_notified ||
                        this.ic_physician_notified_date ||
                        this.ic_action ||
                        this.ic_culture ||
                        this.ic_culture_date ||
                        this.ic_culture_source ||
                        this.ic_diagnosis ||
                        this.ic_pathogen)) {
                    return true;
                }
                return false;
            };
            Medication.prototype.showInfectionResults = function () {
                if (this.is_anti_infective && (this.ic_results || this.ic_cqi_followup)) {
                    return true;
                }
                return false;
            };
            return Medication;
        }());
        domain.Medication = Medication;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Ndc = /** @class */ (function () {
            function Ndc() {
            }
            return Ndc;
        }());
        domain.Ndc = Ndc;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var NonPlayerCharacter = /** @class */ (function () {
            function NonPlayerCharacter() {
                this.referral_representations = [];
            }
            return NonPlayerCharacter;
        }());
        domain.NonPlayerCharacter = NonPlayerCharacter;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Office = /** @class */ (function () {
            function Office(data) {
                this.id = data.id;
                this.name = data.name;
                this.corporate = data.org_ids[2];
                this.region = data.org_ids[1];
                this.area = data.org_ids[0];
            }
            return Office;
        }());
        domain.Office = Office;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));




var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PatientAssignment = /** @class */ (function () {
            function PatientAssignment() {
                this.patient_id = null;
                this.create_order = false;
                this.assignment = {
                    id: null,
                    start_date: null,
                    end_date: null,
                    priority: null,
                    duration: null,
                    frequencies: []
                };
            }
            return PatientAssignment;
        }());
        domain.PatientAssignment = PatientAssignment;
        var GeneralDetail = /** @class */ (function () {
            function GeneralDetail() {
                this.order_id = null;
                this.assessment_date = null;
                this.physician_id = null;
                this.time_of_event = null;
                this.nurse_id = null;
                this.related = null;
                this.oversight = null;
                this.read_back = null;
                this.non_verbal_order = null;
                this.verbalReadBack = null;
                this.start_date = null,
                    this.end_date = null,
                    this.selected_provider = null;
            }
            return GeneralDetail;
        }());
        domain.GeneralDetail = GeneralDetail;
        var Frequencies = /** @class */ (function () {
            function Frequencies() {
                this.id = null;
                this.resource_type = null,
                    this.provider = null,
                    this.selected_provider = null,
                    this.frequency = null;
                this.frequencyValues = [];
                this.every_x_days_frequency = null;
                this.number_of_visits = null;
                this.every_x_days_prn = null;
                this.frequency_notes = null;
                this.interval = null;
                this.intervalValues = [];
                this.primary = false;
            }
            return Frequencies;
        }());
        domain.Frequencies = Frequencies;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PatientGenerator = /** @class */ (function () {
            function PatientGenerator() {
                this.number_patients = 0;
                this.office = null;
                this.admission = '';
                this.cdc_race = '';
                this.payer_group = '';
                this.assignment = false;
                this.indicator = false;
                this.certification = false;
                this.care_plan_problem = false;
                this.patient_record_default = false;
                this.religion = false;
                this.dme_order = false;
                this.comprehensive_nursing_assessment = false;
                this.physician_order = false;
                this.eligibility = false;
                this.home_health = false;
                this.o2 = false;
                this.pain_observation = false;
                this.social_assessment = false;
                this.bereavement_assessment = false;
                this.missed_visit = false;
                this.allergy = false;
                this.medication = false;
                this.notice_of_election = false;
                this.eligibility_inquiry = false;
            }
            return PatientGenerator;
        }());
        domain.PatientGenerator = PatientGenerator;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var IPatientMedicationKitTransaction = /** @class */ (function () {
            function IPatientMedicationKitTransaction() {
            }
            return IPatientMedicationKitTransaction;
        }());
        domain.IPatientMedicationKitTransaction = IPatientMedicationKitTransaction;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PatientMedicationKit = /** @class */ (function () {
            function PatientMedicationKit(patientMedicationKit) {
                var _loop_1 = function (key) {
                    if (key === 'medications') {
                        var meds_1 = [];
                        patientMedicationKit[key].forEach(function (medication) {
                            meds_1.push(new domain.Medication(medication));
                        });
                        this_1[key] = meds_1;
                    }
                    else {
                        this_1[key] = patientMedicationKit[key];
                    }
                };
                var this_1 = this;
                for (var _i = 0, _a = Object.keys(patientMedicationKit); _i < _a.length; _i++) {
                    var key = _a[_i];
                    _loop_1(key);
                }
            }
            PatientMedicationKit.prototype.statusDescription = function () {
                if (this.needsDistributionOrder()) {
                    return 'Needs Distribution Order';
                }
                else if (this.needsUtilizationOrder()) {
                    return 'Needs Utilization Order';
                }
                else if (this.needsPhysicianSignature()) {
                    return 'Needs Physician Signature';
                }
            };
            PatientMedicationKit.prototype.needsUtilizationOrder = function () {
                return this.status === 'needs_utilization_order';
            };
            PatientMedicationKit.prototype.needsDistributionOrder = function () {
                return this.status === 'needs_distribution_order';
            };
            PatientMedicationKit.prototype.needsPhysicianSignature = function () {
                return this.status === 'needs_physician_signature';
            };
            PatientMedicationKit.prototype.medicationsRemainingCount = function () {
                var count = 0;
                this.medications.forEach(function (medication) {
                    if (medication.needsUtilizationOrder() || medication.needsDistributionOrder()) {
                        count += 1;
                    }
                });
                return count;
            };
            return PatientMedicationKit;
        }());
        domain.PatientMedicationKit = PatientMedicationKit;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PatientImportDocument = /** @class */ (function () {
            function PatientImportDocument() {
                this.tags = ['Clinical'];
            }
            return PatientImportDocument;
        }());
        domain.PatientImportDocument = PatientImportDocument;
        var PatientImport = /** @class */ (function () {
            function PatientImport(json) {
                this.filter_all = false;
                this.dob = json.dob || json.dob_display ? json.dob || json.dob_display : '';
                this.ssn = json.ssn ? json.ssn : '';
                this.given_name = json.givenName || json.name ? json.givenName || json.name : '';
                this.family_name = json.familyName ? json.familyName : '';
                this.gender = json.gender ? json.gender : '';
                this.address_1 = json.address_1 || json.addr1 ? json.address_1 || json.addr1 : '';
                this.address_2 = json.address_2 ? json.address_2 : '';
                this.city = json.city ? json.city : '';
                this.state = json.state ? json.state : '';
                this.zipcode = json.zipcode ? json.zipcode : '';
                this.id = json.id ? json.id : null;
            }
            return PatientImport;
        }());
        domain.PatientImport = PatientImport;
        var Allergen = /** @class */ (function () {
            function Allergen() {
            }
            return Allergen;
        }());
        domain.Allergen = Allergen;
        var Diagnosis = /** @class */ (function () {
            function Diagnosis() {
            }
            return Diagnosis;
        }());
        domain.Diagnosis = Diagnosis;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Patient = /** @class */ (function () {
            function Patient() {
            }
            return Patient;
        }());
        domain.Patient = Patient;
        var Address = /** @class */ (function () {
            function Address() {
                this.address_1 = null;
                this.address_2 = null;
                this.city = null;
                this.state = null;
                this.zipcode = null;
            }
            return Address;
        }());
        domain.Address = Address;
        var FamilyMember = /** @class */ (function (_super) {
            __extends(FamilyMember, _super);
            function FamilyMember() {
                var _this = _super.call(this) || this;
                _this.address = new Address();
                _this.offices = new Array();
                _this.office_ids = new Array();
                _this.patient_family_relationships = new Array();
                _this.referral_representations = new Array();
                _this.family_diagnoses = new Array();
                return _this;
            }
            return FamilyMember;
        }(domain.NonPlayerCharacter));
        domain.FamilyMember = FamilyMember;
        var FamilyDiagnosis = /** @class */ (function () {
            function FamilyDiagnosis() {
            }
            return FamilyDiagnosis;
        }());
        domain.FamilyDiagnosis = FamilyDiagnosis;
        var Relationship = /** @class */ (function () {
            function Relationship() {
            }
            return Relationship;
        }());
        domain.Relationship = Relationship;
        var ReferralAccount = /** @class */ (function () {
            function ReferralAccount(_a) {
                var _b = _a.service_id, service_id = _b === void 0 ? null : _b;
                this.service_id = service_id;
            }
            return ReferralAccount;
        }());
        domain.ReferralAccount = ReferralAccount;
        var Service = /** @class */ (function () {
            function Service(data) {
                this.id = data.id;
                this.name = data.name;
                this.type = data.type;
            }
            return Service;
        }());
        domain.Service = Service;
        var CareLevelChange = /** @class */ (function () {
            function CareLevelChange() {
                this.effective_datetime = new Date(Date.now());
            }
            return CareLevelChange;
        }());
        domain.CareLevelChange = CareLevelChange;
        var FormPatient = /** @class */ (function () {
            function FormPatient(patient) {
                this.care_level_change = new CareLevelChange();
                if (patient) {
                    for (var key in patient) {
                        if (patient.hasOwnProperty(key)) {
                            var element = patient[key];
                            this[key] = patient[key];
                        }
                    }
                }
                else {
                    this.mailing_same_as_physical = true;
                }
                if (this.dob_display) {
                    this.dob = new Date(this.dob_display);
                }
                if (this.death_date_display) {
                    this.death_date = new Date(this.death_date_display);
                }
                if (this.retirement_date_display) {
                    this.retirement_date = new Date(this.retirement_date_display);
                }
                if (this.spouse_retirement_date_display) {
                    this.spouse_retirement_date = new Date(this.spouse_retirement_date_display);
                }
            }
            return FormPatient;
        }());
        domain.FormPatient = FormPatient;
        var Allergy = /** @class */ (function () {
            function Allergy() {
                this.patient_id = null;
                this.user_id = null;
                this.allergen_id = null;
                this.code_type = '';
                this.code_value = '';
                this.description = '';
                this.name_type = '';
                this.code_category = '';
                this.severity = '';
                this.side_effects = '';
            }
            return Allergy;
        }());
        domain.Allergy = Allergy;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PayerGroup = /** @class */ (function () {
            function PayerGroup(payerGroup) {
                var _loop_1 = function (key) {
                    if ((key === 'expiration_date' || key === 'effective_date') && payerGroup[key]) {
                        this_1[key] = new Date(payerGroup[key]);
                    }
                    else if (key === 'insurance_policies') {
                        var count_1 = 0;
                        this_1.insurance_policies = payerGroup[key].map(function (policy) {
                            policy.position = count_1;
                            count_1 += 1;
                            return new domain.InsurancePolicy(policy);
                        });
                    }
                    else {
                        this_1[key] = payerGroup[key];
                    }
                };
                var this_1 = this;
                for (var _i = 0, _a = Object.keys(payerGroup); _i < _a.length; _i++) {
                    var key = _a[_i];
                    _loop_1(key);
                }
                this.willPay = this.getDefaultWillPayHash();
                if (!this.effective_date) {
                    this.effective_date = new Date();
                }
                if (!this.insurance_policies) {
                    this.insurance_policies = [];
                }
                this.initializeWillPay();
            }
            //Delete an existing insurance policy
            PayerGroup.prototype.deleteInsurancePolicy = function (index) {
                var insurancePolicy = this.insurance_policies[index];
                if (insurancePolicy.id) {
                    var indexOf = this.insurance_policy_ids.indexOf(insurancePolicy.id);
                    if (indexOf > -1) {
                        this.insurance_policy_ids.splice(indexOf, 1);
                    }
                }
                this.insurance_policies.splice(index, 1);
                insurancePolicy = null;
                this.initializeWillPay();
                // Loop through insurance policies and set NOE/NOT/Pre-Election/Vaccine
                this.ensureWillPay('pre_election');
                this.ensureWillPay('notice_of_election');
                this.ensureWillPay('notice_of_termination');
                this.ensureWillPay('vaccine');
                this.ensureWillPay('medicare_advantage');
            };
            PayerGroup.prototype.initializeWillPay = function () {
                var _this = this;
                this.willPay = this.getDefaultWillPayHash();
                this.insurance_policies.forEach(function (insurancePolicy) {
                    if (insurancePolicy && insurancePolicy.will_pay) {
                        insurancePolicy.will_pay.forEach(function (payObject) {
                            _this.willPay[payObject] = true;
                        });
                        insurancePolicy.setPaysRoomAndBoard();
                    }
                });
            };
            //Keeps the PayerGroup in sync with insurance policies when changing from defaults
            //Returns true if it's a valid selection, false if it's already been selected
            PayerGroup.prototype.syncWillPay = function (updatedInsurancePolicy) {
                var _this = this;
                this.willPay = this.getDefaultWillPayHash();
                //Check what the current is, make sure no other insurance policies have it
                for (var i = 0; i < this.insurance_policies.length; i++) {
                    var insurancePolicy = this.insurance_policies[i];
                    if (!insurancePolicy.insurance_carrier_id) {
                        this.deleteInsurancePolicy(i);
                    }
                    else if (insurancePolicy !== updatedInsurancePolicy) {
                        insurancePolicy.will_pay.forEach(function (payObject) {
                            _this.willPay[payObject] = true;
                        });
                    }
                }
                var duplicateSelection = false;
                //At this point we have updated all other insurance policies
                updatedInsurancePolicy.will_pay.forEach(function (payObject) {
                    if (!_this.willPay[payObject]) {
                        _this.willPay[payObject] = true;
                    }
                    else {
                        var indexOf = updatedInsurancePolicy.will_pay.indexOf(payObject);
                        if (indexOf > -1) {
                            duplicateSelection = true;
                            updatedInsurancePolicy.will_pay.splice(indexOf, 1);
                        }
                    }
                });
                updatedInsurancePolicy.setPaysRoomAndBoard();
                // Loop through insurance policies and set NOE/NOT/Pre-Election/Vaccine
                this.ensureWillPay('pre_election');
                this.ensureWillPay('notice_of_election');
                this.ensureWillPay('notice_of_termination');
                this.ensureWillPay('vaccine');
                this.ensureWillPay('medicare_advantage');
                return !duplicateSelection;
            };
            //Updates the Insurance Policy will_pay based on the payer selected
            PayerGroup.prototype.updateInsurancePolicy = function (insurancePolicy, payerConfiguration) {
                insurancePolicy.will_pay = [];
                this.syncWillPay(insurancePolicy);
                if (payerConfiguration.claim_configurations) {
                    this.setWillPayForBillingStack('pre_election', insurancePolicy, payerConfiguration.claim_configurations);
                    this.setWillPayForBillingStack('notice_of_election', insurancePolicy, payerConfiguration.claim_configurations);
                    this.setWillPayForBillingStack('notice_of_termination', insurancePolicy, payerConfiguration.claim_configurations);
                    this.setWillPayForBillingStack('vaccine', insurancePolicy, payerConfiguration.claim_configurations);
                    this.setWillPayForBillingStack('medicare_advantage', insurancePolicy, payerConfiguration.claim_configurations);
                    this.setPrimarySecondaryWillPay('encounter', insurancePolicy, payerConfiguration.claim_configurations);
                    if (!payerConfiguration.claim_configurations['combined_hospice_room_and_board']) {
                        this.setPrimarySecondaryWillPay('hospice', insurancePolicy, payerConfiguration.claim_configurations);
                        this.setPrimarySecondaryWillPay('room_and_board', insurancePolicy, payerConfiguration.claim_configurations);
                    }
                    else {
                        //Has combined on it, need to check if primary_hospice or room_and_board is present, don't want combined if so
                        if (this.willPay['primary_hospice'] || this.willPay['primary_room_and_board']) {
                            this.setPrimarySecondaryWillPay('hospice', insurancePolicy, payerConfiguration.claim_configurations);
                            this.setPrimarySecondaryWillPay('room_and_board', insurancePolicy, payerConfiguration.claim_configurations);
                        }
                        else {
                            this.setWillPayForCombinedHospiceRoomAndBoard(insurancePolicy, payerConfiguration.claim_configurations);
                        }
                    }
                }
                insurancePolicy.setPaysRoomAndBoard();
            };
            PayerGroup.prototype.setWillPayForBillingStack = function (stack, insurancePolicy, claimConfigurations) {
                if (!this.willPay[stack] && claimConfigurations[stack] === true) {
                    //Only one allowed per payer group
                    this.willPay[stack] = true;
                    insurancePolicy.will_pay.push(stack);
                }
            };
            PayerGroup.prototype.setWillPayForCombinedHospiceRoomAndBoard = function (insurancePolicy, claimConfigurations) {
                if (!this.willPay['primary_hospice'] && !this.willPay['primary_room_and_board']) {
                    this.willPay['combined_hospice_room_and_board'] = true;
                    insurancePolicy.will_pay.push('combined_hospice_room_and_board');
                }
                else if (this.willPay['primary_room_and_board'] && !this.willPay['primary_hospice']) {
                    this.willPay['primary_hospice'];
                    insurancePolicy.will_pay.push('primary_hospice');
                    this.willPay['secondary_room_and_board'] = true;
                    insurancePolicy.will_pay.push('secondary_room_and_board');
                }
                else if (this.willPay['primary_hospice'] && !this.willPay['primary_room_and_board']) {
                    this.willPay['primary_room_and_board'] = true;
                    insurancePolicy.will_pay.push('primary_room_and_board');
                    this.willPay['secondary_hospice'];
                    insurancePolicy.will_pay.push('secondary_hospice');
                }
            };
            PayerGroup.prototype.setPrimarySecondaryWillPay = function (stack, insurancePolicy, claimConfigurations) {
                if (claimConfigurations[stack]) {
                    var primaryKey = 'primary_' + stack;
                    var secondaryKey = 'secondary_' + stack;
                    if (!this.willPay[primaryKey]) {
                        this.willPay[primaryKey] = true;
                        insurancePolicy.will_pay.push(primaryKey);
                    }
                    else if (!this.willPay[secondaryKey]) {
                        this.willPay[secondaryKey] = true;
                        insurancePolicy.will_pay.push(secondaryKey);
                    }
                }
            };
            PayerGroup.prototype.ensureWillPay = function (stack) {
                var _this = this;
                this.insurance_policies.forEach(function (insurancePolicy) {
                    if (insurancePolicy.medicare_payer) {
                        _this.setWillPayForBillingStack(stack, insurancePolicy, insurancePolicy.medicare_payer.payer_configuration.claim_configurations);
                    }
                    if (insurancePolicy.medicaid_payer) {
                        _this.setWillPayForBillingStack(stack, insurancePolicy, insurancePolicy.medicaid_payer.payer_configuration.claim_configurations);
                    }
                    if (insurancePolicy.private_insurance_payer) {
                        _this.setWillPayForBillingStack(stack, insurancePolicy, insurancePolicy.private_insurance_payer.payer_configuration.claim_configurations);
                    }
                    if (insurancePolicy.charitable_care_payer) {
                        _this.setWillPayForBillingStack(stack, insurancePolicy, insurancePolicy.charitable_care_payer.payer_configuration.claim_configurations);
                    }
                });
            };
            //Checks that all insurance policies that pay room and board have patient liability entered
            PayerGroup.prototype.isValid = function () {
                var _this = this;
                var errors = [];
                var willPayCount = {};
                var _loop_2 = function (i) {
                    var insurancePolicy = this_2.insurance_policies[i];
                    insurancePolicy.errors = [];
                    if (!insurancePolicy.insurance_carrier_id) {
                        this_2.deleteInsurancePolicy(i);
                    }
                    else {
                        if (insurancePolicy.paysRoomAndBoard &&
                            insurancePolicy.patient_liability &&
                            !insurancePolicy.patient_liability.length) {
                            errors.push('Payer ' + (i + 1) + ': You must enter patient liability for any room and board payer');
                        }
                        if (insurancePolicy.insurance_carrier_id !== 4 && !insurancePolicy.payer_number) {
                            errors.push('Payer ' + (i + 1) + ': You must enter a payer number');
                        }
                        if (!insurancePolicy.will_pay) {
                            insurancePolicy.will_pay = [];
                        }
                        insurancePolicy.will_pay.forEach(function (payObject) {
                            if (!willPayCount[payObject]) {
                                willPayCount[payObject] = i + 1;
                            }
                            else {
                                errors.push('Payer ' +
                                    willPayCount[payObject] +
                                    ' and Payer ' +
                                    (i + 1) +
                                    ' both have ' +
                                    payObject +
                                    ' selected and only one is allowed.');
                            }
                        });
                    }
                };
                var this_2 = this;
                for (var i = 0; i < this.insurance_policies.length; i++) {
                    _loop_2(i);
                }
                ['encounter', 'hospice', 'room_and_board'].forEach(function (key) {
                    if (_this.willPay['secondary_' + key] && !_this.willPay['primary_' + key]) {
                        errors.push('You must have primary selected if a secondary is present for ' +
                            key.split('_').join(' '));
                    }
                });
                var willPaySet = false;
                for (var _i = 0, _a = Object.keys(this.willPay); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (this.willPay[key]) {
                        willPaySet = true;
                    }
                }
                if (!willPaySet) {
                    errors.push('You must have at least one Will Pay selected across all Payers.');
                }
                if (this.willPay['combined_hospice_room_and_board'] && this.willPay['primary_hospice']) {
                    errors.push('You may not have combined chosen with primary hospice.');
                }
                if (this.willPay['combined_hospice_room_and_board'] &&
                    this.willPay['primary_room_and_board']) {
                    errors.push('You may not have combined chosen with primary room and board.');
                }
                if (!this.insurance_policies.length) {
                    errors.push('You must have at least one payer.');
                }
                return errors;
            };
            PayerGroup.prototype.getDefaultWillPayHash = function () {
                return {
                    primary_hospice: false,
                    secondary_hospice: false,
                    primary_room_and_board: false,
                    secondary_room_and_board: false,
                    primary_encounter: false,
                    secondary_encounter: false,
                    notice_of_election: false,
                    notice_of_termination: false,
                    pre_election: false,
                    combined_hospice_room_and_board: false,
                    vaccine: false,
                    medicare_advantage: false
                };
            };
            return PayerGroup;
        }());
        domain.PayerGroup = PayerGroup;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Payer = /** @class */ (function () {
            function Payer(payer) {
                if (payer) {
                    for (var _i = 0, _a = Object.keys(payer); _i < _a.length; _i++) {
                        var key = _a[_i];
                        this[key] = payer[key];
                    }
                }
            }
            return Payer;
        }());
        domain.Payer = Payer;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var OrderStatus;
        (function (OrderStatus) {
            OrderStatus[OrderStatus["current"] = 0] = "current";
            OrderStatus[OrderStatus["needsPhysicianOrder"] = 1] = "needsPhysicianOrder";
            OrderStatus[OrderStatus["needsNurseSignature"] = 2] = "needsNurseSignature";
            OrderStatus[OrderStatus["needsPhysicianSignature"] = 3] = "needsPhysicianSignature";
            OrderStatus[OrderStatus["ePrescribe"] = 4] = "ePrescribe";
            OrderStatus[OrderStatus["transmissionFailed"] = 7] = "transmissionFailed";
            OrderStatus[OrderStatus["needsUtilizationOrder"] = 8] = "needsUtilizationOrder";
            OrderStatus[OrderStatus["needsDistributionOrder"] = 9] = "needsDistributionOrder";
        })(OrderStatus = domain.OrderStatus || (domain.OrderStatus = {}));
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PhysicianDocument = /** @class */ (function () {
            function PhysicianDocument() {
            }
            return PhysicianDocument;
        }());
        domain.PhysicianDocument = PhysicianDocument;
        var PhysicianCreation = /** @class */ (function () {
            function PhysicianCreation() {
                this.referral_representations = [];
                this.dea_numbers = [];
                this.physician_documents = [];
            }
            return PhysicianCreation;
        }());
        domain.PhysicianCreation = PhysicianCreation;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var PlanOfCare = /** @class */ (function () {
            function PlanOfCare(planOfCare) {
                for (var _i = 0, _a = Object.keys(planOfCare); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key !== 'created_at' && key !== 'deleted_at' && key !== 'effective_date') {
                        this[key] = planOfCare[key];
                    }
                    else if (planOfCare[key]) {
                        this[key] = new Date(planOfCare[key]);
                    }
                }
                if (!this.effective_date) {
                    this.effective_date = new Date(new Date().toDateString());
                }
                this.setSignatureString();
            }
            PlanOfCare.prototype.setSignatureString = function () {
                if (!this.signatures) {
                    this.signatures = [];
                }
                var str = '', len = this.signatures.length;
                for (var i = 0; i < len; ++i) {
                    str += this.signatures[i].pretty_html;
                    if (i !== len - 1) {
                        str += '<br>';
                    }
                }
                this.signatureString = str;
            };
            PlanOfCare.prototype.isPastPlanOfCare = function () {
                if (!this.effective_date) {
                    return true;
                }
                return this.effective_date <= new Date() && !this.is_current;
            };
            PlanOfCare.prototype.createEmptyArrays = function () {
                var _this = this;
                [
                    'assignment_ids',
                    'care_plan_problem_ids',
                    'clinical_chart_ids',
                    'dme_order_ids',
                    'indicator_ids',
                    'medication_ids',
                    'medication_note_ids',
                    'physician_order_ids',
                    'recurring_task_set_ids',
                    'volunteer_care_plan_ids',
                    'unrelated_explanation_ids',
                ].forEach(function (section) {
                    if (!_this[section]) {
                        _this[section] = [];
                    }
                });
            };
            return PlanOfCare;
        }());
        domain.PlanOfCare = PlanOfCare;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var Procedure = /** @class */ (function () {
            function Procedure() {
            }
            return Procedure;
        }());
        domain.Procedure = Procedure;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var QueryStringFilter = /** @class */ (function () {
            function QueryStringFilter() {
                this.shouldUpdateFilterQueryString = false;
                this.hasAppliedInitialFilters = false;
                this.isFiltered = false;
            }
            QueryStringFilter.prototype.filterExcluded = function (key, val) {
                return ['sort_by', 'sort_dir', 'page', 'page_size'].indexOf(key) === -1;
            };
            QueryStringFilter.prototype.applyInitialFiltersIfNeeded = function () {
                if (this.hasAppliedInitialFilters) {
                    return;
                }
                this.applyInitialFilters();
                this.hasAppliedInitialFilters = true;
            };
            QueryStringFilter.prototype.applyInitialFilters = function () {
                var _this = this;
                var showFilters = true;
                var searchParams = this.$location.search();
                Object.keys(searchParams).forEach(function (key) {
                    if (searchParams[key]) {
                        _this.isFiltered = true;
                        showFilters = false;
                        if (_this.filters[key] instanceof Array) {
                            _this.filters[key] = decodeURIComponent(searchParams[key]).split(',');
                        }
                        else {
                            _this.filters[key] = decodeURIComponent(searchParams[key]);
                        }
                    }
                });
                this.showFilters = showFilters;
            };
            QueryStringFilter.prototype.updateFilterQueryStringIfNeeded = function () {
                if (!this.shouldUpdateFilterQueryString) {
                    this.shouldUpdateFilterQueryString = true;
                    return;
                }
                this.updateFilterQueryString();
            };
            QueryStringFilter.prototype.updateFilterQueryString = function () {
                var _this = this;
                var isFiltered = false;
                // for this to work, all properties on filters object need to have a default value
                var filterOptions = {};
                Object.keys(this.filters)
                    .filter(function (key) { return _this.filterExcluded(key, _this.filters[key]); })
                    .forEach(function (key) {
                    var item = _this.filters[key];
                    var val;
                    if (item instanceof Array) {
                        val = item.length ? encodeURIComponent(item.toString()) : null;
                    }
                    else if (item) {
                        val = encodeURIComponent(item);
                    }
                    else {
                        val = null;
                    }
                    if (val) {
                        isFiltered = true;
                    }
                    filterOptions[key] = val;
                });
                this.$state.transitionTo(this.$state.current.name, filterOptions, { location: 'replace' });
                this.isFiltered = isFiltered;
            };
            QueryStringFilter.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            return QueryStringFilter;
        }());
        domain.QueryStringFilter = QueryStringFilter;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var ReferralContact = /** @class */ (function () {
            function ReferralContact(data) {
                this.id = data.id;
                this.name = data.name;
                this.type = data.type;
            }
            return ReferralContact;
        }());
        domain.ReferralContact = ReferralContact;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var SlidingScaleRange = /** @class */ (function () {
            function SlidingScaleRange(slidingRange) {
                for (var _i = 0, _a = Object.keys(slidingRange); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'order') {
                        this.order = slidingRange[key];
                    }
                    else if (key === 'low_range') {
                        this.low_range = slidingRange[key];
                    }
                    else if (key === 'high_range') {
                        this.high_range = slidingRange[key];
                    }
                    else if (key === 'units') {
                        this.units = slidingRange[key];
                    }
                    else if (key === 'comments') {
                        this.comments = slidingRange[key];
                    }
                }
            }
            SlidingScaleRange.prototype.isValid = function (errors) {
                if (this.low_range < 0 || this.high_range < 0) {
                    errors.push('Invalid blood sugar value(s)');
                }
                else if (this.low_range && this.low_range >= this.high_range) {
                    errors.push('Invalid range');
                }
                else {
                    return true;
                }
                // TODO Add a validation to the enclosing medication that the last entry in the array of these has a comment, if that is required
                return true;
            };
            return SlidingScaleRange;
        }());
        domain.SlidingScaleRange = SlidingScaleRange;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var SlidingScale = /** @class */ (function () {
            function SlidingScale(slidingScale) {
                this.dose_level = null;
                this.sliding_scale_ranges = [];
                for (var _i = 0, _a = Object.keys(slidingScale); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if (key === 'dose_level') {
                        this.dose_level = slidingScale[key];
                    }
                    if (key === 'sliding_scale_ranges') {
                        var ranges = slidingScale[key];
                        for (var i = 0; i < ranges.length; i++) {
                            this.sliding_scale_ranges.push(new domain.SlidingScaleRange(ranges[i]));
                        }
                    }
                }
            }
            SlidingScale.prototype.reorderRanges = function () {
                for (var i = 0; i < this.sliding_scale_ranges.length; i++) {
                    this.sliding_scale_ranges[i].order = i;
                }
            };
            SlidingScale.prototype.isValid = function (errors) {
                var isValid = true;
                for (var _i = 0, _a = this.sliding_scale_ranges; _i < _a.length; _i++) {
                    var range = _a[_i];
                    if (!range.isValid(errors)) {
                        isValid = false;
                    }
                }
                // TODO if required add a check that the last entry in the array of ranges has a comment
                return isValid;
            };
            return SlidingScale;
        }());
        domain.SlidingScale = SlidingScale;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var SmokingStatus = /** @class */ (function () {
            function SmokingStatus() {
                if (!this.effective_date) {
                    this.effective_date = new Date(new Date().toDateString());
                }
            }
            return SmokingStatus;
        }());
        domain.SmokingStatus = SmokingStatus;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));




var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var TeamMember = /** @class */ (function () {
            function TeamMember(json) {
                if (json) {
                    this.id = json.id ? json.id : null;
                    this.name = json.name ? json.name : '';
                    this.default_role_name = json.default_role_name ? json.default_role_name : '';
                    // Corrects spacing issue for users who have more than one role name
                    if (json.role_names.length > 1) {
                        this.role_names = json.role_names ? this.spaceRoleNames(json.role_names) : '';
                    }
                    else if (json.role_names.length === 1) {
                        this.role_names = json.role_names ? json.role_names[0] : '';
                    }
                }
                else {
                    this.id = null;
                    this.name = '';
                    this.default_role_name = '';
                    this.role_names = [];
                }
            }
            TeamMember.prototype.spaceRoleNames = function (roleNames) {
                return roleNames.join(', ');
            };
            return TeamMember;
        }());
        domain.TeamMember = TeamMember;
        var Team = /** @class */ (function () {
            function Team(json, showMembers) {
                if (json && showMembers === true) {
                    this.id = json.id ? json.id : null;
                    this.name = json.name ? json.name : '';
                    this.user_count = json.user_count ? json.user_count : 0;
                    this.users = json.users.length ? json.users.map(function (user) { return new TeamMember(user); }) : [];
                }
                else if (json && showMembers === false) {
                    this.id = json.id ? json.id : '';
                    this.name = json.name ? json.name : '';
                    this.user_count = json.user_count ? json.user_count : 0;
                }
                else {
                    this.id = null;
                    this.name = '';
                    this.user_count = 0;
                    this.users = [];
                }
            }
            return Team;
        }());
        domain.Team = Team;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var User = /** @class */ (function () {
            function User() {
                this.roles = new Array();
                this.role_names = new Array();
                this.extended_roles = new Array();
                this.extended_role_names = new Array();
                this.user_credentials = new Array();
                this.dea_numbers = new Array();
            }
            return User;
        }());
        domain.User = User;
        var UserCredential = /** @class */ (function () {
            function UserCredential() {
                this.role_names = new Array();
            }
            return UserCredential;
        }());
        domain.UserCredential = UserCredential;
        var DeaNumber = /** @class */ (function () {
            function DeaNumber() {
            }
            return DeaNumber;
        }());
        domain.DeaNumber = DeaNumber;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));





var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var WhitelistIpAddress = /** @class */ (function () {
            function WhitelistIpAddress() {
            }
            return WhitelistIpAddress;
        }());
        domain.WhitelistIpAddress = WhitelistIpAddress;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var EpcsRootState = /** @class */ (function () {
        function EpcsRootState($stateProvider) {
            $stateProvider
                .state('epcs', {
                abstract: true,
                url: '/epcs',
            })
                .state('epcs.users', {
                url: '/users',
                data: { title: 'EPCS User Configuration' },
                views: {
                    'sidebar@': {
                        templateUrl: '/y/templates/epcs/users/sidebar.html',
                        controller: 'EpcsUsersSidebarController as vm',
                    },
                    'main-content@': {
                        templateUrl: '/y/templates/epcs/users/users.html',
                        controller: 'EpcsUsersController as vm',
                    },
                },
                resolve: {
                    access: ['RouteAuthService', function (a) { return a.canOrAlert('view', 'user_epcs_settings'); }],
                },
            })
                .state('epcs.users.view', {
                url: '/:userId',
                data: { title: 'EPCS User Configuration', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/epcs/users/edit.html',
                        controller: 'EpcsEditUserController as vm',
                    },
                },
                resolve: {
                    readOnly: function () { return true; },
                    access: ['RouteAuthService', function (a) { return a.canOrAlert('view', 'user_epcs_settings'); }],
                },
            })
                .state('epcs.users.edit', {
                url: '/:userId/edit',
                data: { title: 'EPCS User Configuration', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/epcs/users/edit.html',
                        controller: 'EpcsEditUserController as vm',
                    },
                },
                resolve: {
                    readOnly: function () { return false; },
                    access: ['RouteAuthService', function (a) { return a.canOrAlert('update', 'user_epcs_settings'); }],
                },
            })
                .state('epcs.users.confirm', {
                url: '/:userId/confirm',
                data: { title: 'EPCS Physician Confirmation', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/epcs/users/confirm.html',
                        controller: 'EpcsConfirmUserController as vm',
                    },
                },
                resolve: {
                    access: ['RouteAuthService', function (a) { return a.canOrAlert('confirm', 'user_epcs_settings'); }],
                },
            });
        }
        EpcsRootState.$inject = ['$stateProvider'];
        return EpcsRootState;
    }());
    angular.module('consolo').config(EpcsRootState);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ForumThreadController = /** @class */ (function () {
            function ForumThreadController(forumThread, options, $mdDialog) {
                var _this = this;
                this.forumThread = forumThread;
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.noteCategories = [
                    { id: 'Administrative' },
                    { id: 'Claims' },
                    { id: 'Insurance' },
                    { id: 'General' },
                    { id: 'CommunityEducation' },
                    { id: 'Homemaker' },
                ];
                this.options.get('note_sub_categories').$promise.then(function (categories) {
                    _this.noteSubCategories = categories;
                });
            }
            ForumThreadController.prototype.save = function () {
                this.$mdDialog.hide(new consolo.domain.ForumThread(this.forumThread));
            };
            ForumThreadController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ForumThreadController.$inject = ['forumThread', 'options', '$mdDialog'];
            return ForumThreadController;
        }());
        angular.module('consolo').controller('ForumThreadController', ForumThreadController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('forumThreads', {
            bindings: {
                parentObject: '<',
                primary: '@',
                readonly: '<',
            },
            templateUrl: '/y/templates/forum-thread/forum-threads.html',
            controller: [
                '$mdDialog',
                'SessionService',
                'CurrentPatient',
                'PermissionsService',
                function ($mdDialog, Session, CurrentPatient, PermissionsService) {
                    this.$mdDialog = $mdDialog;
                    this.Session = Session;
                    this.editAnyPermission = PermissionsService.can('edit_any', 'forum_threads');
                    this.currentUser = Session.get().user;
                    this.CurrentPatient = CurrentPatient;
                    this.addNewForumThread = addNewForumThread;
                    this.editForumThread = editForumThread;
                    this.sortForumThreads = sortForumThreads;
                    function addNewForumThread() {
                        var _this = this;
                        var newForumThread = {
                            created_by: this.currentUser,
                            patient_id: CurrentPatient.patient_id,
                            effective_date: new Date(new Date().toDateString()),
                            primary_tag: this.primary,
                        };
                        return this.$mdDialog
                            .show({
                            clickOutsideToClose: true,
                            templateUrl: '/y/templates/forum-thread/edit.html',
                            controller: 'ForumThreadController as $ctrl',
                            bindToController: true,
                            multiple: true,
                            skipHide: true,
                            preserveScope: true,
                            locals: { forumThread: newForumThread },
                        })
                            .then(function (forumThread) {
                            if (!_this.parentObject.forum_threads) {
                                _this.parentObject.forum_threads = [];
                            }
                            _this.parentObject.forum_threads.push(forumThread);
                            _this.sortForumThreads();
                        });
                    }
                    function editForumThread(forumThread) {
                        var _this = this;
                        var tempForumThread = angular.copy(forumThread);
                        return this.$mdDialog
                            .show({
                            clickOutsideToClose: true,
                            templateUrl: '/y/templates/forum-thread/edit.html',
                            controller: 'ForumThreadController as $ctrl',
                            bindToController: true,
                            multiple: true,
                            skipHide: true,
                            preserveScope: true,
                            locals: { forumThread: tempForumThread },
                        })
                            .then(function (updatedForumThread) {
                            for (var i = 0; i < _this.parentObject.forum_threads.length; i++) {
                                if (updatedForumThread.id === _this.parentObject.forum_threads[i].id) {
                                    _this.parentObject.forum_threads[i] = updatedForumThread;
                                    i = _this.parentObject.forum_threads.length;
                                }
                            }
                            _this.sortForumThreads();
                        });
                    }
                    function sortForumThreads() {
                        this.parentObject.forum_threads = this.parentObject.forum_threads.sort(function (a, b) {
                            if (a.effective_date < b.effective_date) {
                                return 1;
                            }
                            if (a.effective_date > b.effective_date) {
                                return -1;
                            }
                            return 0;
                        });
                    }
                },
            ],
            controllerAs: '$ctrl',
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('hospiceRate', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/hospice-rates/hospice-rate.html',
            controller: 'HospiceRateController as $ctrl',
        });
        var HospiceRateController = /** @class */ (function () {
            function HospiceRateController(logger, Session, $http, PayerService, $stateParams, HospiceRatesService, FilterSearchService, $state) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.PayerService = PayerService;
                this.$stateParams = $stateParams;
                this.HospiceRatesService = HospiceRatesService;
                this.FilterSearchService = FilterSearchService;
                this.$state = $state;
                this.global = true;
                this.errors = [];
                this.saving = false;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                if ($stateParams.hospiceRateId) {
                    this.saving = true;
                    this.HospiceRatesService.get($stateParams.hospiceRateId)
                        .then(function (hospiceRate) {
                        _this.hospiceRate = hospiceRate;
                        _this.global = _this.hospiceRate.agency_id ? false : true;
                        var _loop_1 = function (key) {
                            _this.HospiceRatesService.revenueCodes.some(function (code) {
                                if (code.ub92_code === _this.hospiceRate.rates[key].revenue_code &&
                                    (!_this.hospiceRate.rates[key].description ||
                                        _this.hospiceRate.rates[key].description === code.description)) {
                                    _this.hospiceRate.rates[key]['revenueCode'] = code;
                                    return true;
                                }
                            });
                        };
                        for (var _i = 0, _a = Object.keys(_this.hospiceRate.rates); _i < _a.length; _i++) {
                            var key = _a[_i];
                            _loop_1(key);
                        }
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.initialCodes = {
                        '551': {
                            code: 'REGISTEREDNURSE',
                            description: '551-Registered Nurse',
                            short_code: 'RN551',
                            ub92_code: '551',
                        },
                        '561': {
                            code: 'SOCIALSERVICES',
                            description: '561-Social Services',
                            short_code: 'SS561',
                            ub92_code: '561',
                        },
                        '652': {
                            code: 'CONT',
                            description: '652-Continuous Care',
                            short_code: 'CC',
                            ub92_code: '652',
                        },
                        '655': {
                            code: 'RESPITE',
                            description: '655-Respite Care',
                            short_code: 'RES',
                            ub92_code: '655',
                        },
                        '651': {
                            code: 'ROUTINE',
                            description: '651-Routine Home Care',
                            short_code: 'RHC',
                            ub92_code: '651',
                        },
                        '656': {
                            code: 'INPATIENT',
                            description: '656-Inpatient Care',
                            short_code: 'INP',
                            ub92_code: '656',
                        },
                    };
                    this.hospiceRate = {
                        agency_id: this.Session.get().agency.id,
                        rates: {
                            SIA_RN: {
                                revenue_code: '551',
                                level_of_care: 'SIA',
                                hcpcs_code: 'G0299',
                                description: '551-Registered Nurse',
                                revenueCode: this.initialCodes['551'],
                            },
                            SIA_SW: {
                                revenue_code: '561',
                                level_of_care: 'SIA',
                                hcpcs_code: 'G0155',
                                revenueCode: this.initialCodes['561'],
                                description: '561-Social Services',
                            },
                            CONT: {
                                revenue_code: '652',
                                level_of_care: 'CONT',
                                description: '652-Continuous Care',
                                revenueCode: this.initialCodes['652'],
                            },
                            RESPITE: {
                                revenue_code: '655',
                                level_of_care: 'RESPITE',
                                revenueCode: this.initialCodes['655'],
                                description: '655-Respite Care',
                            },
                            ROUTINE_HIGH: {
                                revenue_code: '651',
                                level_of_care: 'ROUTINE',
                                description: '651-Routine Home Care',
                                revenueCode: this.initialCodes['651'],
                            },
                            ROUTINE_LOW: {
                                revenue_code: '651',
                                level_of_care: 'ROUTINE',
                                revenueCode: this.initialCodes['651'],
                                description: '651-Routine Home Care',
                            },
                            INPATIENT: {
                                revenue_code: '656',
                                level_of_care: 'INPATIENT',
                                revenueCode: this.initialCodes['656'],
                                description: '656-Inpatient Care',
                            },
                        },
                    };
                    this.global = false;
                }
            }
            HospiceRateController.prototype.setCode = function (revenueCode, rate) {
                if (revenueCode) {
                    rate.description = revenueCode.description;
                }
                else {
                    rate.short_code = null;
                }
            };
            HospiceRateController.prototype.setAgencyId = function () {
                if (this.global) {
                    this.hospiceRate.agency_id = null;
                }
                else {
                    this.hospiceRate.agency_id = this.Session.get().agency.id;
                }
            };
            HospiceRateController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.hospiceRate.insurance_carrier_id) {
                    this.errors.push('The Insurance Carrier must be selected.');
                }
                if (!this.hospiceRate.effective_date) {
                    this.errors.push('The Effective Date must be selected.');
                }
                if (!this.hospiceRate.expiration_date) {
                    this.errors.push('The Expiration Date must be selected.');
                }
                if (this.errors.length) {
                    this.logger.error(this.errors.join(','));
                }
                else {
                    return this.HospiceRatesService.upsert(this.hospiceRate).then(function (hospiceRate) {
                        return _this.$state.go('hospice-rates');
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(','));
                    });
                }
            };
            HospiceRateController.$inject = [
                'logger',
                'SessionService',
                '$http',
                'PayerService',
                '$stateParams',
                'HospiceRatesService',
                'FilterSearchService',
                '$state',
            ];
            return HospiceRateController;
        }());
        common.HospiceRateController = HospiceRateController;
        angular.module('consolo').controller('HospiceRateController', HospiceRateController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('hospice-rates', {
                url: '/hospice_rates',
                data: { title: 'Hospice Rates' },
                views: {
                    'main-content@': {
                        template: '<hospice-rates flex=""></hospice-rates>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.HospiceRatesDataTableService" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
            })
                .state('hospice-rates.new', {
                url: '/new',
                data: { title: 'New Hospice Rate', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<hospice-rate readonly="false" flex=""></hospice-rate>',
                    },
                },
            })
                .state('hospice-rates.edit', {
                url: '/:hospiceRateId/edit',
                data: { title: 'Edit Hospice Rate', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<hospice-rate readonly="false" flex=""></hospice-rate>',
                    },
                },
            })
                .state('hospice-rates.view', {
                url: '/:hospiceRateId',
                data: { title: 'View Hospice Rate', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<hospice-rate readonly="true" flex=""></hospice-rate>',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var HospiceRatesDataTableService = /** @class */ (function (_super) {
            __extends(HospiceRatesDataTableService, _super);
            function HospiceRatesDataTableService($http, $interval, HospiceRatesService, $state, logger, Session) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.HospiceRatesService = HospiceRatesService;
                _this.$state = $state;
                _this.logger = logger;
                _this.Session = Session;
                _this.loadingData = false;
                _this.title = 'Hospice Rates';
                _this.showFilters = true;
                _this.activate();
                _this.currentUser = Session.getUser();
                return _this;
            }
            HospiceRatesDataTableService.prototype.usersAllowedToEditGlobal = function () {
                var allowedUsers;
                allowedUsers = [
                    'julia.quigley@wellsky.com',
                    'angelia.ingram@wellsky.com',
                    'brittany.hickey@wellsky.com',
                ];
                return allowedUsers.includes(this.currentUser.email);
            };
            HospiceRatesDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/hospice_rates';
                this.filters = new consolo.domain.HospiceRatesFilter();
                this.filters.start_date = new Date();
                this.tableLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Payer',
                            field: 'insurance_carrier.name',
                            formatValueFn: function (hospiceRate) {
                                if (hospiceRate.medicaid_payer) {
                                    return hospiceRate.medicaid_payer.name;
                                }
                                else if (hospiceRate.private_insurance_payer) {
                                    return hospiceRate.private_insurance_payer.name;
                                }
                                else {
                                    return hospiceRate.insurance_carrier.name;
                                }
                            },
                        },
                        {
                            title: '',
                            field: 'global',
                            type: csg.datatable.ColumnTypes.boolean,
                            formatValueFn: function (hospiceRate) {
                                var result = '<span class="chart-discipline blue">';
                                result += hospiceRate.agency_id ? 'Local' : 'Global';
                                result += '</span>';
                                return result;
                            },
                        },
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Expiration Date',
                            field: 'expiration_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'County',
                            field: 'county',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                            formatValueFn: function (hospiceRate) {
                                return hospiceRate.county ? hospiceRate.county.description : '';
                            },
                        },
                        {
                            title: 'Office',
                            field: 'office_name',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                            formatValueFn: function (hospiceRate) {
                                return hospiceRate.office ? hospiceRate.office.name : '';
                            },
                        },
                        {
                            title: 'CBSA',
                            field: 'cbsa',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Use Medicare HCPCS?',
                            field: 'use_medicare_hcpcs',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        {
                            title: 'Rates',
                            field: 'rates',
                            formatValueFn: function (hospiceRate) {
                                var result = '';
                                [
                                    'ROUTINE_HIGH',
                                    'ROUTINE_LOW',
                                    'ROUTINE',
                                    'CONT',
                                    'RESPITE',
                                    'INPATIENT',
                                    'SIA RN',
                                    'SIA SW',
                                    'SIA',
                                ].forEach(function (key) {
                                    if (hospiceRate.rates[key] && hospiceRate.rates[key].rate) {
                                        var code = hospiceRate.rates[key].hcpcs_code
                                            ? ' (' + hospiceRate.rates[key].hcpcs_code + ')</span>'
                                            : '</span>';
                                        result +=
                                            '<span class="chart-discipline blue margin-bottom-sm">' +
                                                key.replace('_', ' ') +
                                                ' ' +
                                                hospiceRate.rates[key].rate +
                                                code;
                                    }
                                });
                                return result;
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit Hospice Rate',
                                disableOffline: false,
                                icon: 'edit',
                                callbackFn: function (hospiceRate) {
                                    _this.$state.go('hospice-rates.edit', { hospiceRateId: hospiceRate.id });
                                    return true;
                                },
                                conditionalHideFn: function (hospiceRate) {
                                    return (!_this.HospiceRatesService.updatePermission ||
                                        (!hospiceRate.agency_id && !_this.usersAllowedToEditGlobal()));
                                },
                            },
                            {
                                label: 'View Hospice Rate',
                                icon: 'remove_red_eye',
                                callbackFn: function (hospiceRate) {
                                    _this.$state.go('hospice-rates.view', { hospiceRateId: hospiceRate.id });
                                },
                                conditionalHideFn: function () {
                                    return !_this.HospiceRatesService.viewPermission;
                                },
                            },
                            {
                                label: 'Delete Hospice Rate',
                                icon: 'delete',
                                callbackFn: function (hospiceRate) {
                                    _this.HospiceRatesService.delete(hospiceRate).then(function (deletedHospiceRate) {
                                        _this.logger.success('The hospice rate has been deleted.');
                                        _this.search();
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                },
                                conditionalHideFn: function (hospiceRate) {
                                    return (!_this.HospiceRatesService.deletePermission ||
                                        (!hospiceRate.agency_id && !_this.usersAllowedToEditGlobal()));
                                },
                            },
                        ],
                    },
                };
            };
            HospiceRatesDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.params = this.filters.getFilters();
                    this.loadingData = true;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            HospiceRatesDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.HospiceRatesFilter();
                this.filters.page = 1;
                this.search();
            };
            HospiceRatesDataTableService.$inject = [
                '$http',
                '$interval',
                'HospiceRatesService',
                '$state',
                'logger',
                'SessionService',
            ];
            return HospiceRatesDataTableService;
        }(csg.datatable.ApiService));
        services.HospiceRatesDataTableService = HospiceRatesDataTableService;
        angular.module('consolo').service('HospiceRatesDataTableService', HospiceRatesDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('hospiceRates', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/hospice-rates/hospice-rates.html',
            controller: 'HospiceRatesController as $ctrl',
        });
        var HospiceRatesController = /** @class */ (function () {
            function HospiceRatesController(logger, Session, $http, HospiceRatesDataTableService, HospiceRatesService, SidebarService, $state) {
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.HospiceRatesDataTableService = HospiceRatesDataTableService;
                this.HospiceRatesService = HospiceRatesService;
                this.SidebarService = SidebarService;
                this.$state = $state;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                SidebarService.close();
            }
            HospiceRatesController.$inject = [
                'logger',
                'SessionService',
                '$http',
                'HospiceRatesDataTableService',
                'HospiceRatesService',
                'SidebarService',
                '$state',
            ];
            return HospiceRatesController;
        }());
        common.HospiceRatesController = HospiceRatesController;
        angular.module('consolo').controller('HospiceRatesController', HospiceRatesController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var HospiceRatesService = /** @class */ (function () {
            function HospiceRatesService($http, $interval, PermissionsService, options, logger) {
                this.$http = $http;
                this.$interval = $interval;
                this.PermissionsService = PermissionsService;
                this.options = options;
                this.logger = logger;
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.importPermission = false;
                this.apiUrl = '/api/v1/hospice_rates';
                this.updatePermission = this.PermissionsService.can('update', 'hospice_rates');
                this.createPermission = this.PermissionsService.can('create', 'hospice_rates');
                this.deletePermission = this.PermissionsService.can('destroy', 'hospice_rates');
                this.viewPermission = this.PermissionsService.can('show', 'hospice_rates');
                this.importPermission = this.PermissionsService.can('import', 'hospice_rates');
                this.insuranceCarriers = [
                    { id: 1, name: 'Medicare' },
                    { id: 2, name: 'Medicaid' },
                    { id: 3, name: 'Private Insurance' },
                    { id: 4, name: 'Charitable Care/Non-Reimbursable Services' },
                ];
                this.counties = this.options.get('counties');
                this.revenueCodes = this.options.get('level_of_cares');
                this.rates = [
                    { key: 'ROUTINE_HIGH', label: 'Routine High' },
                    { key: 'ROUTINE_LOW', label: 'Routine Low' },
                    { key: 'CONT', label: 'Cont' },
                    { key: 'RESPITE', label: 'Respite' },
                    { key: 'INPATIENT', label: 'Inpatient' },
                    { key: 'SIA_RN', label: 'SIA RN' },
                    { key: 'SIA_SW', label: 'SIA SW' },
                ];
            }
            HospiceRatesService.prototype.isReady = function () {
                return this.revenueCodes.$promise.then(function () {
                    return true;
                });
            };
            HospiceRatesService.prototype.delete = function (hospiceRate) {
                return this.$http.delete(this.apiUrl + '/' + hospiceRate.id).then(function (response) {
                    return response.data;
                });
            };
            HospiceRatesService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            HospiceRatesService.prototype.upsert = function (hospiceRate) {
                var _this = this;
                if (hospiceRate.id) {
                    return this.$http.patch(this.apiUrl + '/' + hospiceRate.id, hospiceRate).then(function (response) {
                        _this.logger.success('The Hospice Rate has been updated successfully.');
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, hospiceRate).then(function (response) {
                        _this.logger.success('The Hospice Rate has been created successfully.');
                        return response.data;
                    });
                }
            };
            HospiceRatesService.$inject = ['$http', '$interval', 'PermissionsService', 'options', 'logger'];
            return HospiceRatesService;
        }());
        services.HospiceRatesService = HospiceRatesService;
        angular.module('consolo').service('HospiceRatesService', HospiceRatesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var HQRPSidebarController = /** @class */ (function () {
            function HQRPSidebarController($mdDialog, LogiService) {
                this.$mdDialog = $mdDialog;
                this.LogiService = LogiService;
                var app = 'hqrp';
                this.LogiService.getLogiUrl(app).then(function (resp) {
                    EmbeddedReporting.create('gauge1', {
                        applicationUrl: resp.data['url'],
                        secureKey: resp.data['secure_key'],
                        report: 'Embedded.censusGauge',
                    });
                });
                this.LogiService.getLogiUrl(app).then(function (resp) {
                    EmbeddedReporting.create('gauge2', {
                        applicationUrl: resp.data['url'],
                        secureKey: resp.data['secure_key'],
                        report: 'Embedded.admissionsTrend',
                    });
                });
                this.LogiService.getLogiUrl(app).then(function (resp) {
                    EmbeddedReporting.create('gauge3', {
                        applicationUrl: resp.data['url'],
                        secureKey: resp.data['secure_key'],
                        report: 'Embedded.chtTopICDs',
                    });
                });
                this.LogiService.getLogiUrl(app).then(function (resp) {
                    EmbeddedReporting.create('gauge4', {
                        applicationUrl: resp.data['url'],
                        secureKey: resp.data['secure_key'],
                        report: 'Embedded.levelOfCareCht',
                    });
                });
                this.LogiService.getLogiUrl(app).then(function (resp) {
                    EmbeddedReporting.create('gauge5', {
                        applicationUrl: resp.data['url'],
                        secureKey: resp.data['secure_key'],
                        report: 'Embedded.alosGauge',
                    });
                });
                this.LogiService.getLogiUrl(app).then(function (resp) {
                    EmbeddedReporting.create('gauge6', {
                        applicationUrl: resp.data['url'],
                        secureKey: resp.data['secure_key'],
                        report: 'Embedded.dischargesTrend',
                    });
                });
            }
            HQRPSidebarController.prototype.gaugePopup = function ($event, report, title) {
                this.$mdDialog.show({
                    template: "\n          <md-dialog style=\"width:750px;\">\n            <md-toolbar>\n              <div class=\"md-toolbar-tools\">\n                <h2>{{$ctrl.title}}</h2>\n                <span flex></span>\n                <md-button class=\"md-icon-button flat-button\" ng-click=\"$ctrl.close()\">\n                  <md-icon class=\"material-icons\" aria-label=\"Close dialog\" md-svg-icon=\"navigation:close\"></md-icon>\n                </md-button>\n              </div>\n            </md-toolbar>\n            <md-dialog-content>\n              <div id=\"gaugeModal\" class=\"gaugeLarge\" />\n            </md-dialog-content>\n          </md-dialog>\n        ",
                    clickOutsideToClose: true,
                    controllerAs: '$ctrl',
                    controller: HQRPModalCtrl,
                    locals: { report: report, title: title },
                    targetEvent: $event,
                    escapeToClose: true,
                });
            };
            HQRPSidebarController.$inject = ['$mdDialog', 'LogiService'];
            return HQRPSidebarController;
        }());
        controllers.HQRPSidebarController = HQRPSidebarController;
        var HQRPModalCtrl = /** @class */ (function () {
            function HQRPModalCtrl($mdDialog, $timeout, report, title, LogiService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.LogiService = LogiService;
                var app = 'hqrp';
                this.title = title;
                $timeout(function () {
                    _this.LogiService.getLogiUrl(app).then(function (resp) {
                        EmbeddedReporting.create('gaugeModal', {
                            applicationUrl: resp.data['url'],
                            secureKey: resp.data['secure_key'],
                            report: report,
                        });
                    });
                }, 0);
            }
            HQRPModalCtrl.prototype.close = function () {
                this.$mdDialog.hide();
            };
            HQRPModalCtrl.$inject = ['$mdDialog', '$timeout', 'report', 'title', 'LogiService'];
            return HQRPModalCtrl;
        }());
        angular.module('consolo').controller('HQRPSidebarController', HQRPSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var HQRPController = /** @class */ (function () {
            function HQRPController(LogiService) {
                this.LogiService = LogiService;
                LogiService.getLogiUrl('hqrp').then(function (resp) {
                    var options = {
                        applicationUrl: resp.data['url'],
                        report: 'DropPage.DashboardButtons',
                        secureKey: resp.data['secure_key'],
                    };
                    EmbeddedReporting.create('rightcontent', options);
                });
            }
            HQRPController.$inject = ['LogiService'];
            return HQRPController;
        }());
        controllers.HQRPController = HQRPController;
        angular.module('consolo').controller('HQRPController', HQRPController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        var HqrpStates = /** @class */ (function () {
            function HqrpStates($stateProvider) {
                $stateProvider.state({
                    name: 'hqrp',
                    data: { title: 'Overview (12 months)' },
                    url: '/hqrp',
                    views: {
                        'sidebar@': {
                            templateUrl: '/y/templates/hqrp/hqrp-sidebar.html',
                            controller: 'HQRPSidebarController as $ctrl',
                        },
                        'main-content': {
                            templateUrl: '/y/templates/hqrp/hqrp.html',
                            controller: 'HQRPController as $ctrl',
                        },
                    },
                    resolve: {
                        auth: [
                            '$q',
                            'SessionService',
                            'logger',
                            function ($q, Session, logger) {
                                var sess = Session.get();
                                if (sess.agency.has_hqrp) {
                                    if (sess.user.flags.has_hqrp) {
                                        return true;
                                    }
                                    else {
                                        logger.error("You don't have permissions to access Lighthouse");
                                        return $q.reject();
                                    }
                                }
                                else {
                                    window.open('https://info3.consoloservices.com/ConsoloLighthouse', '_blank');
                                    // logger.error('Agency doesnt have Lighthouse');
                                    return $q.reject();
                                }
                            },
                        ],
                    },
                });
            }
            HqrpStates.$inject = ['$stateProvider'];
            return HqrpStates;
        }());
        angular.module('consolo').config(HqrpStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var logi;
    (function (logi) {
        'use strict';
        var LogiService = /** @class */ (function () {
            function LogiService($http) {
                this.$http = $http;
            }
            LogiService.prototype.getLogiUrl = function (app) {
                return this.$http.get('/api/v1/logi_url?app=' + app);
            };
            LogiService.$inject = ['$http'];
            return LogiService;
        }());
        logi.LogiService = LogiService;
        angular.module('consolo').service('LogiService', LogiService);
    })(logi = consolo.logi || (consolo.logi = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        var DashboardController = /** @class */ (function () {
            function DashboardController(idProofingService, $timeout) {
                this.idProofingService = idProofingService;
                this.$timeout = $timeout;
                this.activeProofs = [];
                this.loading = true;
                this.refresh();
            }
            DashboardController.prototype.refresh = function () {
                var _this = this;
                this.idProofingService
                    .getAll({ active: true, vendor: 'exostar', refresh: true })
                    .then(function (proofs) { return (_this.activeProofs = proofs); }, function (errors) { return (_this.errors = errors); })
                    .then(function () {
                    _this.loading = false;
                    if (_this.anyNotComplete()) {
                        _this.$timeout(function () { return _this.refresh(); }, 5 * 1000);
                    }
                });
            };
            DashboardController.prototype.anyNotComplete = function () {
                return this.activeProofs.filter(function (p) { return !p.completed; }).length > 0;
            };
            DashboardController.$inject = ['IdentityProofService', '$timeout'];
            return DashboardController;
        }());
        idProofing.DashboardController = DashboardController;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('IdProofingDashboardController', consolo.idProofing.DashboardController);

var consolo;
(function (consolo) {
    var EpcsPasswordController = /** @class */ (function () {
        function EpcsPasswordController(logger, $http) {
            this.logger = logger;
            this.$http = $http;
            this.saving = false;
            this.checkIfSet();
        }
        EpcsPasswordController.prototype.set = function () {
            var _this = this;
            this.saving = true;
            this.$http.patch('/api/v2/epcs/password', {
                password: this.password,
                password_confirmation: this.confirmation,
            }).then(function (resp) {
                _this.saving = false;
                _this.password = null;
                _this.confirmation = null;
                _this.logger.success('Your EPCS password has been successfully changed');
            }, function (resp) {
                _this.logger.errorResponse(resp);
                _this.saving = false;
            });
        };
        EpcsPasswordController.prototype.checkIfSet = function () {
            var _this = this;
            this.$http.get('/api/v2/epcs/password').then(function (resp) { return _this.isSet = resp.data['exists'] === true; }, function (resp) { return _this.isSet = false; });
        };
        EpcsPasswordController.$inject = ['logger', '$http'];
        return EpcsPasswordController;
    }());
    consolo.EpcsPasswordController = EpcsPasswordController;
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('EpcsPasswordController', consolo.EpcsPasswordController);

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        var HistoryController = /** @class */ (function () {
            function HistoryController(IdentityProofService) {
                this.IdentityProofService = IdentityProofService;
                this.proofs = [];
                this.ngOnInit();
            }
            HistoryController.prototype.ngOnInit = function () {
                var _this = this;
                this.loading = true;
                this.IdentityProofService.getAll({ limit: 1000 }).then(function (proofs) { return (_this.proofs = proofs); }, function (errors) { return (_this.errors = errors); }).then(function () { return _this.loading = false; });
            };
            HistoryController.$inject = ['IdentityProofService'];
            return HistoryController;
        }());
        idProofing.HistoryController = HistoryController;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('IdProofingHistoryController', consolo.idProofing.HistoryController);

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        function idProofStatusIconFactory() {
            return function idProofStatusIcon(proof) {
                if (proof.proven) {
                    return 'check_circle';
                }
                else if (proof.completed) {
                    return 'block';
                }
                else {
                    return 'priority_high';
                }
            };
        }
        idProofing.idProofStatusIconFactory = idProofStatusIconFactory;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .filter('idProofStatusIcon', consolo.idProofing.idProofStatusIconFactory);

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Routes]);
    function Routes($stateProvider) {
        $stateProvider
            .state('id-proofing', {
            abstract: true,
            url: '/id-proofing',
            data: { title: 'ID Proofing' },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/id-proofing/sidebar.html',
                },
            },
        })
            .state('id-proofing.dashboard', {
            url: '',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/id-proofing/dashboard.html',
                    controller: 'IdProofingDashboardController as vm',
                },
            },
        })
            .state('id-proofing.history', {
            url: '/history',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/id-proofing/history.html',
                    controller: 'IdProofingHistoryController as vm',
                },
            },
        })
            .state('id-proofing.exostar', {
            abstract: true,
            url: '/exostar',
        })
            .state('id-proofing.exostar.device', {
            url: '/device',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/id-proofing/exostar/start/pick-device.html',
                    controller: 'IdProofingExostarPickDeviceController as vm',
                },
            },
        })
            .state('id-proofing.exostar.device.proof', {
            url: '/:device/proof',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/id-proofing/exostar/start/pick-proof.html',
                    controller: 'IdProofingExostarPickProofController as vm',
                },
            },
        })
            .state('id-proofing.exostar.device.proof.start', {
            url: '/:proof/start',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/id-proofing/exostar/start/start.html',
                    controller: 'IdProofingExostarStartController as vm',
                },
            },
        })
            .state('epcs-password', {
            url: '/epcs-password',
            data: { title: 'EPCS Password', hideSidebar: true },
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/id-proofing/epcs-password.html',
                    controller: 'EpcsPasswordController as $ctrl',
                }
            }
        });
    }
})();

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        var IdentityProofService = /** @class */ (function () {
            function IdentityProofService($http, $q) {
                this.$http = $http;
                this.$q = $q;
            }
            // Fetch all of a user's identity proof records and make sure they're "fresh". The promise will reject
            // on api errors OR if there are no proven records found.
            IdentityProofService.prototype.getProven = function () {
                var _this = this;
                return this.$http
                    .get('/api/v1/identity_proofs', { params: { proven: true, refresh: true } })
                    .then(function (resp) {
                    if (resp.data.length > 0) {
                        return resp.data;
                    }
                    else {
                        return _this.$q.reject(['You have not completed identity proving']);
                    }
                }, function (resp) { return (resp.data ? resp.data.errors : ['Unknown error']); });
            };
            IdentityProofService.prototype.getAll = function (params) {
                if (params === void 0) { params = {}; }
                return this.$http.get('/api/v1/identity_proofs', { params: params }).then(function (resp) {
                    return resp.data;
                }, function (resp) {
                    return resp.data ? resp.data.errors : ['Unknown error'];
                });
            };
            IdentityProofService.$inject = ['$http', '$q'];
            return IdentityProofService;
        }());
        idProofing.IdentityProofService = IdentityProofService;
        angular.module('consolo').service('IdentityProofService', IdentityProofService);
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('labResultItem', {
            bindings: { labResultTemplateItem: '<' },
            templateUrl: '/y/templates/labs/lab-result-template.html',
            controller: 'LabResultTemplateController as $ctrl',
        });
        var LabResultTemplateController = /** @class */ (function () {
            function LabResultTemplateController($mdDialog, LabService, options) {
                this.$mdDialog = $mdDialog;
                this.LabService = LabService;
                this.options = options;
                this.reasons = [];
                this.errors = [];
                this.isLoading = false;
                this.isLoading = true;
                if (!this.labResultTemplate) {
                    this.labResultTemplate = this.LabService.currentLabResultTemplate;
                }
                this.isLoading = false;
            }
            LabResultTemplateController.prototype.submit = function () {
                var _this = this;
                if (this.labResultTemplate.units) {
                    this.LabService.isValidUCUM(this.labResultTemplate.units).then(function (response) {
                        var validUnits = (response.data === "true") ? true : false;
                        if (_this.isValid(validUnits)) {
                            return _this.$mdDialog.hide(_this.labResultTemplate);
                        }
                    });
                }
                else {
                    if (this.isValid(true)) {
                        return this.$mdDialog.hide(this.labResultTemplate);
                    }
                }
            };
            LabResultTemplateController.prototype.isValid = function (validUnits) {
                this.errors = [];
                if (!validUnits) {
                    this.errors.push('Unit of Measure must be a valid UCUM code.');
                }
                if (this.errors.length > 0) {
                    return false;
                }
                else {
                    return true;
                }
            };
            LabResultTemplateController.$inject = ['$mdDialog', 'LabService', 'options'];
            return LabResultTemplateController;
        }());
        common.LabResultTemplateController = LabResultTemplateController;
        angular.module('consolo').controller('LabResultTemplateController', LabResultTemplateController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('labResultItem', {
            bindings: { labResultItem: '<' },
            templateUrl: '/y/templates/labs/lab-result.html',
            controller: 'LabResultController as $ctrl',
        });
        var LabResultController = /** @class */ (function () {
            function LabResultController($mdDialog, LabService, options) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.LabService = LabService;
                this.options = options;
                this.reasons = [];
                this.errors = [];
                this.units = [];
                this.isLoading = false;
                this.isLoading = true;
                this.options.get('data_absent_reasons').$promise.then(function (data) {
                    _this.reasons = data;
                    if (!_this.labResult) {
                        _this.labResult = _this.LabService.currentLabResult;
                        _this.selectedUnit = _this.labResult.units;
                    }
                    _this.isLoading = false;
                });
                this.options.get('administered_units').$promise.then(function (data) {
                    _this.units = data.filter(function (unit) {
                        return !unit.code.includes("["); // filter out unneeded units
                    });
                });
            }
            LabResultController.prototype.submit = function () {
                var _this = this;
                if (this.labResult.units) {
                    this.LabService.isValidUCUM(this.labResult.units).then(function (response) {
                        var validUnits = (response.data === "true") ? true : false;
                        if (_this.isValid(validUnits)) {
                            return _this.$mdDialog.hide(_this.labResult);
                        }
                    });
                }
                else {
                    if (this.isValid(true)) {
                        return this.$mdDialog.hide(this.labResult);
                    }
                }
            };
            LabResultController.prototype.isValid = function (validUnits) {
                this.errors = [];
                if (!this.labResult.isValid()) {
                    this.errors = this.labResult.errors;
                }
                if (!validUnits) {
                    this.errors.push('Unit of Measure must be a valid UCUM code.');
                }
                if (this.errors.length > 0) {
                    return false;
                }
                else {
                    return true;
                }
            };
            LabResultController.prototype.UnitsLookup = function (q) {
                return this.units.filter(function (unit) {
                    return unit.description && (q.length === 0 ||
                        unit.description.toLowerCase().includes(q.toLowerCase()) ||
                        unit.code.toLowerCase().includes(q.toLowerCase()));
                });
            };
            LabResultController.$inject = ['$mdDialog', 'LabService', 'options'];
            return LabResultController;
        }());
        common.LabResultController = LabResultController;
        angular.module('consolo').controller('LabResultController', LabResultController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var LabController = /** @class */ (function () {
            function LabController($stateParams, $state, currentPatient, LabService, options, logger, $filter, $mdDialog, adding, readonly) {
                var _this = this;
                this.$stateParams = $stateParams;
                this.$state = $state;
                this.currentPatient = currentPatient;
                this.LabService = LabService;
                this.options = options;
                this.logger = logger;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.adding = adding;
                this.readonly = readonly;
                this.saving = false;
                this.labPanel = null;
                this.labResults = [];
                if (adding) {
                    this.chooseALoincWithSearchDialog();
                }
                else {
                    this.loadData($stateParams.labPanelId);
                }
                // Load state codes
                this.selectOptions = {};
                this.options.get('states').$promise.then(function (data) {
                    _this.selectOptions['states'] = data;
                });
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'LOINC',
                            field: 'loinc_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Test Name',
                            field: 'long_common_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Values',
                            field: 'test_value',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Reason',
                            field: 'data_absent_reason',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Units',
                            field: 'units',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Reference Range Low',
                            field: 'reference_range_low',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Reference Range High',
                            field: 'reference_range_high',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Abnormal',
                            field: 'abnormal',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        {
                            title: 'High or Low',
                            field: 'abnormal_range',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Move Up',
                            field: 'move_up',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (labResultItem) {
                                return labResultItem.sequence > 1 ? '<span class="material-icons">expand_less</span>' : '';
                            },
                            callbackFn: function (labResultItem) {
                                _this.labResults = _this.moveObjectInArray(_this.labResults, labResultItem.sequence - 1, 'up');
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.LabService.hasUpdatePermission || _this.readonly;
                            },
                        },
                        {
                            title: 'Move Down',
                            field: 'move_down',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (labResultItem) {
                                return labResultItem.sequence < _this.labResults.length ? '<span class="material-icons">expand_more</span>' : '';
                            },
                            callbackFn: function (labResultItem) {
                                _this.labResults = _this.moveObjectInArray(_this.labResults, labResultItem.sequence - 1, 'down');
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.LabService.hasUpdatePermission || _this.readonly;
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Enter/Edit Result',
                                icon: 'edit',
                                callbackFn: function (currentLabResult) {
                                    return _this.editLabResult(currentLabResult);
                                },
                                conditionalHideFn: function (currentLabResult) {
                                    return !_this.LabService.hasUpdatePermission || _this.readonly;
                                },
                            },
                            {
                                label: 'Clear Result',
                                icon: 'delete',
                                callbackFn: function (currentLabResult) {
                                    _this.labResults = _this.clearLabResult(currentLabResult);
                                },
                                conditionalHideFn: function (currentLabResult) {
                                    return !_this.LabService.hasDestroyPermission || _this.readonly;
                                },
                            },
                        ],
                    },
                };
            }
            LabController.prototype.chooseALoincWithSearchDialog = function () {
                var _this = this;
                if (this.labPanel && this.labPanel.loinc_number) {
                    var confirm = this.$mdDialog
                        .confirm()
                        .ok('OK')
                        .cancel('Cancel')
                        .textContent('Choosing a new Lab Panel will remove any existing tests. Are you sure you want to continue?');
                    this.$mdDialog.show(confirm).then(function () {
                        _this.labResults = null;
                        _this.selectALoinc();
                    });
                }
                else {
                    this.selectALoinc();
                }
            };
            LabController.prototype.selectALoinc = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/labs/loinc-search-dialog.html',
                    controller: 'LoincSearchDialogController as $ctrl',
                    bindToController: true,
                })
                    .then(function (selectedLoinc) {
                    if (selectedLoinc) {
                        console.log(selectedLoinc);
                        var newLabPanel_1 = new consolo.domain.LabPanel({
                            agency_id: _this.LabService.agency.id,
                            loinc_number: selectedLoinc.loinc_number, patient_id: _this.currentPatient.id,
                            long_common_name: selectedLoinc.long_common_name, lab_results: []
                        });
                        _this.LabService.getPotentialPanelAndResults(_this.LabService.agency.id, selectedLoinc.loinc_number, _this.currentPatient.id).then(function (response) {
                            // From an abundance of caution re: raw LOINC data, ensure positions are sequential from 1
                            var sequence = 1;
                            _this.labPanel = newLabPanel_1;
                            _this.labResults = [];
                            _this.labPanel.lab_results = response.data;
                            _this.labPanel.lab_results.forEach(function (item) {
                                item.sequence = sequence;
                                sequence += 1;
                                _this.labResults.push(new consolo.domain.LabResult(item));
                            });
                            _this.LabService.isLoading = false;
                        });
                    }
                });
            };
            LabController.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                // toIndex determines where the object is moving based on whether up or down arrows are clicked
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
                this.setPosition(array);
                return [].concat(array);
            };
            LabController.prototype.setPosition = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].sequence = i + 1;
                }
            };
            LabController.prototype.loadData = function (labPanelId) {
                var _this = this;
                if (labPanelId) {
                    this.LabService.getLabPanel(labPanelId).then(function (currentLabPanel) {
                        _this.labPanel = currentLabPanel;
                        _this.LabService.getLoinc(_this.labPanel.loinc_number).then(function (loinc_brief) {
                            _this.LabService.isLoading = false;
                            _this.labPanel.long_common_name = loinc_brief.long_common_name;
                        }).catch(function (error) {
                            console.log(error);
                            _this.LabService.isLoading = false;
                        });
                    }).catch(function (error) {
                        console.log(error);
                        _this.LabService.isLoading = false;
                    });
                    this.LabService.getLabResults(labPanelId).then(function (labResults) {
                        var sequence = 1;
                        // From an abundance of caution, ensure the positions are sequential from 1
                        labResults.forEach(function (item) {
                            item.sequence = sequence;
                            sequence += 1;
                        });
                        _this.labResults = labResults;
                        _this.LabService.isLoading = false;
                    }).catch(function (error) {
                        console.log(error);
                        _this.LabService.isLoading = false;
                    });
                }
            };
            LabController.prototype.editLabResult = function (labResult) {
                var _this = this;
                return this.editLabResultDialog(labResult).then(function (updatedLabResult) {
                    for (var index = 0; index < _this.labResults.length; index++) {
                        if (_this.labResults[index].sequence === updatedLabResult.sequence) {
                            _this.labResults[index] = updatedLabResult;
                            _this.labResults = [].concat(_this.labResults);
                            return;
                        }
                    }
                    _this.logger.error("Internal error: could not update lab result.");
                });
            };
            LabController.prototype.editLabResultDialog = function (labResult) {
                this.LabService.currentLabResult = labResult;
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/labs/lab-result.html',
                    controller: 'LabResultController as $ctrl',
                    bindToController: true,
                });
            };
            LabController.prototype.labResultHasValue = function (labResult) {
                if ((labResult.test_value != null && labResult.test_value !== '') ||
                    (labResult.data_absent_reason != null && labResult.data_absent_reason !== '')) {
                    return true;
                }
                return false;
            };
            LabController.prototype.clearLabResult = function (labResult) {
                labResult.test_value = '';
                labResult.data_absent_reason = '';
                labResult.abnormal = null;
                labResult.abnormal_range = '';
                labResult.units = '';
                labResult.reference_range_low = '';
                labResult.reference_range_high = '';
                for (var index = 0; index < this.labResults.length; index++) {
                    if (this.labResults[index].id === labResult.id) {
                        this.labResults[index] = labResult;
                        return [].concat(this.labResults);
                    }
                }
                this.logger.error("Internal error: could not clear lab result.");
            };
            LabController.prototype.save = function () {
                var _this = this;
                if (this.labPanel.isValid()) {
                    this.saving = true;
                    var newLabPanel_2 = this.labPanel.id ? false : true;
                    // Save labpanel separately from labresults to apply the uuid from the panel to the results
                    this.LabService.save(this.labPanel)
                        .then(function (response) {
                        var returnedLabPanel = new consolo.domain.LabPanel(response.data); // To get the uuid
                        _this.labPanel.id = returnedLabPanel.id;
                        _this.logger.success('Lab Panel ' + _this.labPanel.long_common_name + ' was saved.');
                        if (newLabPanel_2) {
                            _this.labResults.forEach(function (labResult) {
                                labResult.lab_panel_id = _this.labPanel.id;
                                _this.LabService.createResult(labResult.lab_panel_id, labResult);
                            });
                        }
                        else {
                            _this.labResults.forEach(function (labResult) {
                                _this.LabService.updateResult(labResult.lab_panel_id, labResult.id, labResult);
                            });
                        }
                        _this.errors = null;
                        _this.adding = false;
                        _this.saving = false;
                        _this.$state.go('patients.labs', {});
                    });
                }
                else {
                    this.errors = this.labPanel.errors;
                    this.logger.error(this.errors.join(', '));
                }
            };
            LabController.$inject = [
                '$stateParams', '$state', 'currentPatient', 'LabService',
                'options', 'logger', '$filter', '$mdDialog', 'adding', 'readonly'
            ];
            return LabController;
        }());
        angular.module('consolo').controller('LabController', LabController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var LabService = /** @class */ (function () {
            function LabService($mdDialog, $http, logger, options, Session, PermissionsService) {
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.logger = logger;
                this.options = options;
                this.Session = Session;
                this.PermissionsService = PermissionsService;
                this.url = '/api/v1/labs';
                this.loincUrl = "/api/v1/loincs";
                this.isLoading = false;
                this.hasUpdatePermission = false;
                this.hasCreatePermission = false;
                this.hasDestroyPermission = false;
                this.hasViewPermission = false;
                this.hasImportPermission = false;
                // Edited entities
                this.currentLabResult = null;
                this.currentLabPanelTemplate = null;
                this.currentLabResultTemplate = null;
                this.selectOptions = {};
                var sess = this.Session.get();
                this.hasViewPermission = this.PermissionsService.can('show', 'labs');
                this.hasUpdatePermission = this.PermissionsService.can('update', 'labs');
                this.hasCreatePermission = this.PermissionsService.can('create', 'labs');
                this.hasDestroyPermission = this.PermissionsService.can('destroy', 'labs');
                this.hasImportPermission = this.PermissionsService.can('import', 'LOINCs');
                this.agency = Session.get().agency;
                // With two exceptions, only Laboratory panel categories are uncommented. Could delete the other rows but
                // I feel like we'll eventually want them, and they're all here, so ...
                this.panelCategories = [
                    // { name: 'PANEL.ADMIN', displayName: 'Administrative information panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.ADVAULT', displayName: 'ADVault survey set', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.AHRQ', displayName: 'Agency for Healthcare Research and Quality(AHRQ) survey panels', panelLoincs : [] },
                    { name: 'PANEL.ALLERGY', displayName: 'Allergy Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.AMNART', displayName: 'American National Adult Reading Test(AmNART) set', panelLoincs : [] },
                    // { name: 'PANEL.APTA', displayName: 'American Physical Therapy Association(APTA) panels', panelLoincs : [] },
                    { name: 'PANEL.ABXBACT', displayName: 'Antibiotic Susceptibility Panels', panelLoincs: [] },
                    // { name: 'PANEL.ART', displayName: 'Antiretroviral therapy Panels', panelLoincs : [] },
                    // { name: 'PANEL.BLDBK.GENOTYPE', displayName: 'Blood bank genotyping panel', panelLoincs : [] },
                    { name: 'PANEL.BLDBK', displayName: 'Blood Bank Panels', panelLoincs: [] },
                    // { name: 'PANEL.BP', displayName: 'Blood pressure Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.BIMS', displayName: 'Brief Interview for Mental Status(BIMS) panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.BPI', displayName: 'Brief Pain Inventory(BPI) set', panelLoincs : [] },
                    // { name: 'PANEL.CARDIAC', displayName: 'Cardiac Panels', panelLoincs : [] },
                    { name: 'PANEL.CELLMARK', displayName: 'Cellmarker Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.CDC', displayName: 'Centers for Disease Control Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.CMS', displayName: 'Centers for Medicaid and Medicare(CMS) Pnl', panelLoincs : [] },
                    { name: 'PANEL.CHAL', displayName: 'Challenge Bank Panels', panelLoincs: [] },
                    { name: 'PANEL.CHEM', displayName: 'Chemistry Panels', panelLoincs: [] },
                    // { name: 'PANEL.CLIN', displayName: 'Clinical panels', panelLoincs : [] },
                    // { name: 'PANEL.CLIN.RISK', displayName: 'Clinical Risk Panel', panelLoincs : [] },
                    { name: 'PANEL.COAG', displayName: 'Coagulation Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.CAM', displayName: 'Confusion Assessment Method(CAM) panels', panelLoincs : [] },
                    // { name: 'PANEL.PHENX', displayName: 'Consensus measures for Phenotypes and eXposures(PhenX) panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.CARE', displayName: 'Continuity Assessment Record and Evaluation(CARE) panels', panelLoincs : [] },
                    // { name: 'PANEL.CV', displayName: 'CV Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.COOP', displayName: 'Dartmouth Cooperative Functional Status Test(COOP) set', panelLoincs : [] },
                    // { name: 'PANEL.DOC', displayName: 'Document panels', panelLoincs : [] },
                    { name: 'PANEL.DRUG / TOX', displayName: 'Drug and Toxicology Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.EPDS', displayName: 'Edinburgh Postnatal Depression Scale(EPDS) Pnl', panelLoincs : [] },
                    // { name: 'PANEL.ED', displayName: 'Emergency Department(DEEDS) panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.ESRD', displayName: 'End Stage Renal Disease(ESRD) panels', panelLoincs : [] },
                    { name: 'PANEL.FERT', displayName: 'Fertility Panels', panelLoincs: [] },
                    // { name: 'PANEL.FUNCTION', displayName: 'Function panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.GNHLTH', displayName: 'General Health Survey panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.GPCOG', displayName: 'General Practitioner Assessment of Cognition(GPCOG) set', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.GDS', displayName: 'Geriatric Depression Scale(GDS) panels', panelLoincs : [] },
                    // { name: 'PANEL.H & P', displayName: 'HandP panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.HHS', displayName: 'Health and Human Services(HHS) panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.HAQ', displayName: 'Health Assessment Questionnaire(HAQ) set', panelLoincs : [] },
                    { name: 'PANEL.HEDIS', displayName: 'HEDIS panels', panelLoincs: [] },
                    { name: 'PANEL.HEM/BC', displayName: 'Hematology and Cell Count Panels', panelLoincs: [] },
                    // { name: 'PANEL.ATTACH.MOD', displayName: 'HIPAA attachment request modifier code sets', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.HIV - SSC', displayName: 'HIV - Signs and Symptoms Checklist(SSC) panel', panelLoincs : [] },
                    // { name: 'PANEL.HL7.CCDA', displayName: 'HL7 Consolidated Clinical Document Architecture(CCDA) set', panelLoincs : [] },
                    // { name: 'PANEL.HL7.CYTOGEN', displayName: 'HL7 cytogenetics panels', panelLoincs : [] },
                    // { name: 'PANEL.HL7.GENETICS', displayName: 'HL7 genetics panels', panelLoincs : [] },
                    { name: 'PANEL.HLA', displayName: 'HLA Panels', panelLoincs: [] },
                    { name: 'PANEL.HNA', displayName: 'HNA panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.HHCC', displayName: 'Home Health Care Classification(HHCC) panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.howRu', displayName: 'howRu panels', panelLoincs : [] },
                    { name: 'PANEL.HPA', displayName: 'HPA Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.HARK', displayName: '"Humiliation', displayName: ' Afraid', displayName: ' Rape', displayName: ' and Kick (HARK) survey"', panelLoincs : [] },
                    // { name: 'PANEL.IEEE ROSETTA', displayName: 'IEEE Rosetta panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.IPAQ', displayName: 'International Physical Activity Questionnaire(IPAQ) set', panelLoincs : [] },
                    // { name: 'PANEL.IO', displayName: 'IO Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.LIV - HIV', displayName: 'Living with HIV panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.MTLHLTH', displayName: 'Mental Health Survey panel', panelLoincs : [] },
                    { name: 'PANEL.MICRO', displayName: 'Microbiology Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.MDS', displayName: 'Minimum Data Set(MDS) for Nursing Home Resident Assessment and Care Screening Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.MLHFQ', displayName: 'Minnesota Living with Heart Failure Questionnaire(MLHFQ) set', panelLoincs : [] },
                    { name: 'PANEL.MISC', displayName: 'Miscellaneous lab panels', panelLoincs: [] },
                    { name: 'PANEL.MOLPATH', displayName: 'Molecular Pathology Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.MFS', displayName: 'Morse Fall Scale(MFS) panels', panelLoincs : [] },
                    // { name: 'PANEL.NEMSIS', displayName: 'National Emergency Medical Services Information System(NEMSIS) panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NHANES', displayName: 'National Health and Nutrition Examination Survey(NHANES) set', panelLoincs : [] },
                    // { name: 'PANEL.NEONAT', displayName: 'Neonatal Apgar Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NSRAS', displayName: 'Neonatal Skin Risk Assessment Scale panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NEURO', displayName: 'Neurological Survey panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NORTON', displayName: 'Norton Scale panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NMMDS', displayName: 'Nursing Management Minimum Data Set(NMMDS) panels', panelLoincs : [] },
                    // { name: 'PANEL.NUTRITION & DIET', displayName: 'Nutrition and Dietetics panels', panelLoincs : [] },
                    // { name: 'PANEL.OB.US', displayName: 'Ob Ultrasound Panel', panelLoincs : [] },
                    { name: 'PANEL.OBS', displayName: 'Obstetrical Panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.OMAHA', displayName: 'OMAHA panels', panelLoincs : [] },
                    // { name: 'PANEL.ONCO', displayName: 'Oncology panels', panelLoincs : [] },
                    // { name: 'PANEL.EYE', displayName: 'Ophthalmic panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.OASIS', displayName: 'Outcome and Assessment Information Set(OASIS) set', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.OPTIMAL', displayName: 'Outpatient physical therapy improvement in movement assessment log(OPTIMAL) panel', panelLoincs : [] },
                    { name: 'PANEL.DEVICES', displayName: 'Panel.devices', panelLoincs: [] },
                    // { name: 'PANEL.MEDS', displayName: 'PANEL.MEDS', panelLoincs : [] },
                    // { name: 'PANEL.NIH.COGNITIVE', displayName: 'PANEL.NIH.COGNITIVE', panelLoincs : [] },
                    // { name: 'PANEL.NIH.MOTOR', displayName: 'PANEL.NIH.MOTOR', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.ACT', displayName: 'PANEL.SURVEY.ACT', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.ALSFRSR', displayName: 'PANEL.SURVEY.ALSFRSR', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.BDI', displayName: 'PANEL.SURVEY.BDI', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.CESD', displayName: 'PANEL.SURVEY.CESD', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.CSSRS', displayName: 'PANEL.SURVEY.CSSRS', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.DAST', displayName: 'PANEL.SURVEY.DAST', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.DUKE - AD', displayName: 'PANEL.SURVEY.DUKE - AD', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NIH.EMO', displayName: 'PANEL.SURVEY.NIH.EMO', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.SEEK', displayName: 'PANEL.SURVEY.SEEK', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.WELLRX', displayName: 'PANEL.SURVEY.WELLRX', panelLoincs : [] },
                    // { name: 'PANEL.UPDRS', displayName: 'PANEL.UPDRS', panelLoincs : [] },
                    { name: 'PANEL.PATH', displayName: 'Pathology panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.PAS', displayName: 'Patient Activity Scale(PAS) set', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.PASII', displayName: 'Patient Activity Scale II(PASII) set', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.PCORNET', displayName: 'Patient Centered Outcomes Research Network(PCORnet) Common Data Model(CDM) set', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.PHQ', displayName: 'Patient Health Questionnaire(PHQ)panels', panelLoincs : [] },
                    // { name: 'PANEL.PHR', displayName: 'Patient Health Record Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.PROMIS', displayName: 'Patient Reported Outcomes Information System(PROMIS) panels', panelLoincs : [] },
                    // { name: 'PANEL.PATIENT SAFETY', displayName: 'Patient Safety panel', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.PCORI', displayName: 'Patient - Centered Outcomes Research Institute(PCORI) set', panelLoincs : [] },
                    // { name: 'PANEL.MOLPATH.PHARMG', displayName: 'Pharmacogenomics Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.PRAPARE', displayName: "Protocol for Responding to and Assessing Patients' Assets, Risks, and Experiences (PRAPARE) panel", panelLoincs : [] },
                    // { name: 'PANEL.PULM', displayName: 'Pulmonary panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.QAM', displayName: 'Quality Audit Marker panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.NEUROQ', displayName: 'Quality of Life Outcomes in Neurological Disorders(NEURO - Qol) panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.QRDA', displayName: 'Qualty Reporting Document Architecture(QRDA) panels', panelLoincs : [] },
                    // { name: 'PANEL.RAD', displayName: 'Radiology Panels', panelLoincs : [] },
                    // { name: 'PANEL.SURVEY.RFC', displayName: 'Residual Functional Capacity Assessment(RFC) Set', panelLoincs : [] },
                    { name: 'PANEL.SERO', displayName: 'Serology Panels', panelLoincs: [] },
                    { name: 'PANEL.SPEC', displayName: 'Specimen panels', panelLoincs: [] },
                    // { name: 'PANEL.SURVEY.SAMHSA', displayName: 'Substance Abuse and Mental Health Services Administration(SAMHSA) panels', panelLoincs : [] },
                    // { name: 'PANEL.SURG', displayName: 'Surgery panels', panelLoincs : [] },
                    // { name: 'PANEL.BDYTMP', displayName: 'Temperature Panel', panelLoincs : [] },
                    // { name: 'PANEL.TIMP', displayName: 'Test of Infant Motor Performance(TIMP) panels', panelLoincs : [] },
                    // { name: 'PANEL.TRAUMA', displayName: 'Trauma panel sets', panelLoincs : [] },
                    // { name: 'PANEL.TUMRRGT', displayName: 'Tumor Registry Panels', panelLoincs : [] },
                    { name: 'PANEL.UA', displayName: 'Urinalysis Panels', panelLoincs: [] },
                ];
            }
            LabService.prototype.lookupCategory = function (panel) {
                for (var _i = 0, _a = this.panelCategories; _i < _a.length; _i++) {
                    var cat = _a[_i];
                    if (cat.name === panel) {
                        return cat.displayName;
                    }
                }
                return null;
            };
            LabService.prototype.editDetails = function (labPanel) {
                var dialog = {
                    clickOutsideToClose: true,
                    bindToController: true,
                    templateUrl: '/y/templates/labs/edit.html',
                    controller: [
                        '$mdDialog',
                        'LabService',
                        function ($mdDialog, LabService) {
                            this.$mdDialog = $mdDialog;
                            this.LabService = LabService;
                        },
                    ],
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                    controllerAs: 'vm',
                };
                this.$mdDialog.show(dialog);
            };
            LabService.prototype.remove = function (id) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete this Lab Panel and any associated Test Results?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.delete(id);
                });
            };
            LabService.prototype.clearLabResultTemplate = function (labResultTemplate) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to clear this Lab Result Template?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.clearResultTemplate(labResultTemplate);
                });
            };
            LabService.prototype.save = function (labPanel) {
                if (labPanel.id) {
                    return this.update(labPanel);
                }
                else {
                    return this.create(labPanel);
                }
            };
            LabService.prototype.generateLabPanelAndResults = function (patient_id, loinc) {
                // Get the child (tests) of this panel from LOINC
                return this.getTestsForLoinc(loinc.loinc_number)
                    .then(function (loincChildren) {
                    // Generate the lab panel's labResults from the LOINC children
                    var newLabPanel = new consolo.domain.LabPanel({
                        loinc_number: loinc.loinc_number, patient_id: patient_id, long_common_name: loinc.long_common_name, lab_results: []
                    });
                    var sequenceNumber = 0;
                    loincChildren.forEach(function (loincChild) {
                        var newLabResult = new consolo.domain.LabResult({
                            lab_panel_id: newLabPanel.id,
                            patient_id: patient_id,
                            loinc_number: loincChild.loinc_number,
                            long_common_name: loincChild.long_common_name,
                            sequence: sequenceNumber,
                        });
                        sequenceNumber = sequenceNumber + 1;
                        newLabPanel.lab_results.push(newLabResult);
                    });
                    return newLabPanel;
                })
                    .catch(function (error) {
                    console.log(error);
                    return null;
                });
            };
            // Back-end API calls
            LabService.prototype.getLabPanelsByPatient = function (patientId) {
                return this.$http.get(this.url, {
                    params: { patient_id: patientId }
                }).then(function (response) {
                    var labPanels = response.data.map(function (labPanel) {
                        return new consolo.domain.LabPanel(labPanel);
                    });
                    return labPanels;
                });
            };
            LabService.prototype.getLabPanel = function (labId) {
                return this.$http.get(this.url + "/" + labId, {}).then(function (response) {
                    return new consolo.domain.LabPanel(response.data);
                });
            };
            LabService.prototype.getLabResults = function (labId) {
                return this.$http.get(this.url + "/" + labId + "/results").then(function (response) {
                    var labResults = response.data.map(function (labResult) {
                        return new consolo.domain.LabResult(labResult);
                    });
                    return labResults;
                });
            };
            LabService.prototype.getLoinc = function (loinc_number) {
                return this.$http.get(this.loincUrl + "/" + loinc_number).then(function (response) {
                    return new consolo.domain.Loinc(response.data);
                });
            };
            LabService.prototype.getLoincsByPanelCategory = function (categoryName) {
                return this.$http.get(this.loincUrl, {
                    params: { loinc_class: categoryName } // , class_type: 1 }
                }).then(function (response) {
                    var loincs = response.data.map(function (loinc) {
                        return new consolo.domain.Loinc(loinc);
                    });
                    return loincs;
                });
            };
            LabService.prototype.getTestsForLoinc = function (loinc_number) {
                return this.$http.get(this.loincUrl + "/children", { params: { loinc_number: loinc_number } }).then(function (response) {
                    var loincs = response.data.map(function (loinc) {
                        return new consolo.domain.Loinc(loinc);
                    });
                    return loincs;
                });
            };
            LabService.prototype.create = function (labPanel) {
                return this.$http.post(this.url, labPanel);
            };
            LabService.prototype.update = function (labPanel) {
                return this.$http.patch(this.url + '/' + labPanel.id, labPanel);
            };
            LabService.prototype.delete = function (id) {
                return this.$http.delete(this.url + '/' + id);
            };
            LabService.prototype.createResult = function (panelId, labResult) {
                return this.$http.post(this.url + "/" + panelId + "/results", labResult);
            };
            LabService.prototype.updateResult = function (panelId, resultId, labResult) {
                return this.$http.patch(this.url + "/" + panelId + "/results/" + resultId, labResult);
            };
            LabService.prototype.deleteResult = function (panelId, resultId) {
                return this.$http.delete(this.url + '/' + panelId + '/results/' + resultId);
            };
            LabService.prototype.getLoincsWithTemplates = function () {
                return this.$http.get(this.url + "/templates/possible").then(function (response) {
                    return response.data;
                });
            };
            LabService.prototype.lookupLoincs = function (query) {
                return this.$http.get(this.loincUrl + "?q=" + query + "&brief=true&is_panel=TRUE").then(function (response) {
                    return response.data;
                });
            };
            LabService.prototype.getLoincPanels = function (panelCategory) {
                return this.$http
                    .get(this.loincUrl + "?brief=true&loinc_class=" + panelCategory).then(function (response) {
                    return response.data;
                });
            };
            LabService.prototype.getGlobalLabPanelTemplates = function () {
                return this.$http.get(this.url + "/templates", {}).then(function (response) {
                    var labTemplates = response.data.map(function (labPanelTemplate) {
                        return new consolo.domain.LabPanelTemplate(labPanelTemplate);
                    });
                    return labTemplates;
                });
            };
            LabService.prototype.getAgencyLabPanelTemplates = function (agency_id) {
                return this.$http.get(this.url + "/templates", { params: { agency_id: agency_id } }).then(function (response) {
                    var labTemplates = response.data.map(function (labPanelTemplate) {
                        return new consolo.domain.LabPanelTemplate(labPanelTemplate);
                    });
                    return labTemplates;
                });
            };
            LabService.prototype.getPotentialPanelAndResults = function (agency_id, loinc_number, patient_id) {
                return this.$http.get(this.url + "/potential_result_templates", { params: { agency_id: agency_id, loinc_number: loinc_number, patient_id: patient_id } });
            };
            // Get the template for the LOINC panel. First try agency-specific, then if necessary look for a global one
            LabService.prototype.getLoincTemplate = function (agency_id, loinc_number) {
                var _this = this;
                this.$http.get(this.url + "/templates", { params: { agency_id: agency_id, loinc_number: loinc_number } }).then(function (response) {
                    var labTemplates = response.data.map(function (labPanelTemplate) {
                        return new consolo.domain.LabPanelTemplate(labPanelTemplate);
                    });
                    if (labTemplates.length > 0) {
                        return labTemplates;
                    }
                    else {
                        _this.$http.get(_this.url + "/templates", { params: { agency_id: agency_id, loinc_number: loinc_number } }).then(function (response) {
                            var labTemplates = response.data.map(function (labPanelTemplate) {
                                return new consolo.domain.LabPanelTemplate(labPanelTemplate);
                            });
                            return labTemplates;
                        });
                    }
                });
            };
            LabService.prototype.createTemplate = function (loinc_number) {
                return this.$http.post(this.url + "/templates", { loinc_number: loinc_number });
            };
            LabService.prototype.createAgencyTemplate = function (agency_id, loinc_number) {
                return this.$http.post(this.url + "/templates", { agency_id: agency_id, loinc_number: loinc_number });
            };
            LabService.prototype.deleteTemplate = function (labPanelTemplate) {
                return this.$http.delete(this.url + "/templates/" + labPanelTemplate.id);
            };
            LabService.prototype.updateResultTemplate = function (labResultTemplate) {
                return this.$http.patch(this.url + "/templates/" + labResultTemplate.lab_panel_template_id + "/" + labResultTemplate.id, labResultTemplate);
            };
            LabService.prototype.clearResultTemplate = function (labResultTemplate) {
                labResultTemplate.units = '';
                labResultTemplate.reference_range_low = '';
                labResultTemplate.reference_range_high = '';
                return this.updateResultTemplate(labResultTemplate);
            };
            LabService.prototype.isValidUCUM = function (code) {
                return this.$http.get(this.url + "/lookup/ucum", { params: { ucum_code: code } }).then(function (response) {
                    return response;
                });
            };
            LabService.$inject = [
                '$mdDialog',
                '$http',
                'logger',
                'options',
                'SessionService',
                'PermissionsService',
            ];
            return LabService;
        }());
        services.LabService = LabService;
        angular.module('consolo').service('LabService', LabService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var LabsGlobalTemplatesIndexController = /** @class */ (function () {
            function LabsGlobalTemplatesIndexController($state, LabService, Session, $filter, logger, $mdDialog, adding, readonly, global) {
                var _this = this;
                this.$state = $state;
                this.LabService = LabService;
                this.Session = Session;
                this.$filter = $filter;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.adding = adding;
                this.readonly = readonly;
                this.global = global;
                this.saving = false;
                this.labPanelTemplates = null;
                // Variables for importing LOINC data
                this.uploadPath = '/api/v1/loincs/import';
                this.showImport = false;
                this.title = 'Import LOINCs';
                this.csvFields = [
                    "LOINC_NUM", "COMPONENT", "PROPERTY", "TIME_ASPCT", "SYSTEM", "SCALE_TYP", "METHOD_TYP", "CLASS", "VersionLastChanged",
                    "CHNG_TYPE", "DefinitionDescription", "STATUS", "CONSUMER_NAME", "CLASSTYPE", "FORMULA", "SPECIES", "EXMPL_ANSWERS",
                    "SURVEY_QUEST_TEXT", "SURVEY_QUEST_SRC", "UNITSREQUIRED", "SUBMITTED_UNITS", "RELATEDNAMES2", "SHORTNAME", "ORDER_OBS",
                    "CDISC_COMMON_TESTS", "HL7_FIELD_SUBFIELD_ID", "EXTERNAL_COPYRIGHT_NOTICE", "EXAMPLE_UNITS", "LONG_COMMON_NAME", "UnitsAndRange",
                    "EXAMPLE_UCUM_UNITS", "EXAMPLE_SI_UCUM_UNITS", "STATUS_REASON", "STATUS_TEXT", "CHANGE_REASON_PUBLIC", "COMMON_TEST_RANK",
                    "COMMON_ORDER_RANK", "COMMON_SI_TEST_RANK", "HL7_ATTACHMENT_STRUCTURE", "EXTERNAL_COPYRIGHT_LINK", "PanelType",
                    "AskAtOrderEntry", "AssociatedObservations", "VersionFirstReleased", "ValidHL7AttachmentRequest", "DisplayName"
                ];
                if (this.global === false) {
                    this.agencyId = this.Session.get().agency.id;
                }
                this.loadData();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Test Name',
                            field: 'loinc.long_common_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'LOINC',
                            field: 'loinc_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Units',
                            field: 'units',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Reference Range Low',
                            field: 'reference_range_low',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Reference Range High',
                            field: 'reference_range_high',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Move Up',
                            field: 'move_up',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (labResultTemplate) {
                                return labResultTemplate.sequence > 1 ? '<span class="material-icons">expand_less</span>' : '';
                            },
                            callbackFn: function (labResultTemplate) {
                                var labPanelTemplate = _this.labPanelTemplates.filter(function (lpt) { return lpt.id === labResultTemplate.lab_panel_template_id; })[0];
                                labPanelTemplate.lab_result_templates = _this.moveObjectInArray(labPanelTemplate.lab_result_templates, labResultTemplate.sequence - 1, 'up');
                                labPanelTemplate.lab_result_templates.forEach(function (resultTemplate) {
                                    return _this.LabService.updateResultTemplate(resultTemplate);
                                });
                                _this.loadData();
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.LabService.hasUpdatePermission || _this.readonly;
                            },
                        },
                        {
                            title: 'Move Down',
                            field: 'move_down',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (labResultTemplate) {
                                var labPanelTemplate = _this.labPanelTemplates.filter(function (lpt) { return lpt.id === labResultTemplate.lab_panel_template_id; })[0];
                                return labResultTemplate.sequence < labPanelTemplate.lab_result_templates.length
                                    ? '<span class="material-icons">expand_more</span>' : '';
                            },
                            callbackFn: function (labResultTemplate) {
                                var labPanelTemplate = _this.labPanelTemplates.filter(function (lpt) { return lpt.id === labResultTemplate.lab_panel_template_id; })[0];
                                labResultTemplate.lab_result_templates = _this.moveObjectInArray(labPanelTemplate.lab_result_templates, labResultTemplate.sequence - 1, 'down');
                                labPanelTemplate.lab_result_templates.forEach(function (resultTemplate) {
                                    return _this.LabService.updateResultTemplate(resultTemplate);
                                });
                                _this.loadData();
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.LabService.hasUpdatePermission || _this.readonly;
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (currentLabResultTemplate) {
                                    return _this.editLabResultTemplate(currentLabResultTemplate).then(function (response) {
                                        _this.loadData();
                                        _this.logger.success('You have successfully edited the Lab Test Result Template for ' +
                                            currentLabResultTemplate.loinc.long_common_name, 'Success:');
                                    }).catch(function (error) {
                                        _this.logger.error(error.data.errors.join(', '));
                                        console.log(error);
                                    });
                                },
                                conditionalHideFn: function (currentLabResultTemplate) {
                                    return !_this.LabService.hasUpdatePermission || _this.readonly;
                                },
                            },
                            {
                                label: 'Clear',
                                icon: 'delete',
                                callbackFn: function (currentLabResultTemplate) {
                                    return _this.LabService.clearLabResultTemplate(currentLabResultTemplate).then(function (response) {
                                        _this.loadData();
                                        _this.logger.success('You have successfully cleared the Lab Result Template for ' +
                                            currentLabResultTemplate.loinc.long_common_name, 'Success:');
                                    }).catch(function (error) {
                                        _this.logger.error(error.data.errors.join(', '));
                                        console.log(error);
                                    });
                                },
                                conditionalHideFn: function (currentLabResultTemplate) {
                                    return !_this.LabService.hasDestroyPermission || _this.readonly;
                                },
                            },
                        ],
                    },
                };
            }
            LabsGlobalTemplatesIndexController.prototype.panelName = function (labPanelTemplate) {
                return labPanelTemplate.loinc.long_common_name + ", " + labPanelTemplate.loinc_number;
            };
            LabsGlobalTemplatesIndexController.prototype.resultTemplates = function (labPanelTemplate) {
                return labPanelTemplate.lab_result_templates.sort(function (a, b) {
                    return (a.sequence > b.sequence) ? 1 : -1;
                });
            };
            LabsGlobalTemplatesIndexController.prototype.addLabPanelTemplate = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/labs/loinc-search-dialog.html',
                    controller: 'LoincSearchDialogController as $ctrl',
                    bindToController: true,
                })
                    .then(function (selectedLoinc) {
                    if (selectedLoinc) {
                        var existingTemplates = _this.labPanelTemplates.filter(function (lt) { return lt.loinc_number === selectedLoinc.loinc_number; });
                        if (existingTemplates.length > 0) {
                            var errorstring = 'A Lab Template has already been created for ' + selectedLoinc.long_common_name +
                                ((_this.global === false) ? ' on this agency.' : '.');
                            _this.logger.error(errorstring);
                        }
                        else {
                            console.log(selectedLoinc);
                            if (_this.global === false) {
                                _this.LabService.createAgencyTemplate(_this.agencyId, selectedLoinc.loinc_number).then(function (response) {
                                    _this.loadData();
                                });
                            }
                            else {
                                _this.LabService.createTemplate(selectedLoinc.loinc_number).then(function (response) {
                                    _this.loadData();
                                });
                            }
                        }
                    }
                });
            };
            LabsGlobalTemplatesIndexController.prototype.deleteLabPanelTemplate = function (labPanelTemplate) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete this Lab Template?');
                return this.$mdDialog.show(confirm).then(function () {
                    _this.LabService.deleteTemplate(labPanelTemplate).then(function (response) {
                        _this.loadData();
                    });
                });
            };
            LabsGlobalTemplatesIndexController.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                // toIndex determines where the object is moving based on whether up or down arrows are clicked
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
                this.setPosition(array);
                return [].concat(array);
            };
            LabsGlobalTemplatesIndexController.prototype.setPosition = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].sequence = i + 1;
                }
            };
            LabsGlobalTemplatesIndexController.prototype.loadData = function () {
                var _this = this;
                if (this.global === true) {
                    this.LabService.getGlobalLabPanelTemplates().then(function (globalLabPanelTemplates) {
                        _this.labPanelTemplates = globalLabPanelTemplates;
                        _this.LabService.isLoading = false;
                    }).catch(function (error) {
                        console.log(error);
                        _this.LabService.isLoading = false;
                    });
                }
                else {
                    this.LabService.getAgencyLabPanelTemplates(this.agencyId).then(function (globalLabPanelTemplates) {
                        _this.labPanelTemplates = globalLabPanelTemplates;
                        _this.LabService.isLoading = false;
                    }).catch(function (error) {
                        console.log(error);
                        _this.LabService.isLoading = false;
                    });
                }
            };
            LabsGlobalTemplatesIndexController.prototype.editLabResultTemplate = function (labResultTemplate) {
                var _this = this;
                return this.editLabResultTemplateDialog(labResultTemplate).then(function (updatedLabResultTemplate) {
                    if (!_this.adding) {
                        return _this.LabService.updateResultTemplate(updatedLabResultTemplate).then(function (response) {
                        });
                    }
                });
            };
            LabsGlobalTemplatesIndexController.prototype.editLabResultTemplateDialog = function (labResultTemplate) {
                this.LabService.currentLabResultTemplate = labResultTemplate;
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/labs/lab-result-template.html',
                    controller: 'LabResultTemplateController as $ctrl',
                    bindToController: true,
                });
            };
            LabsGlobalTemplatesIndexController.$inject = ['$state', 'LabService', 'SessionService', '$filter', 'logger',
                '$mdDialog', 'adding', 'readonly', 'global'];
            return LabsGlobalTemplatesIndexController;
        }());
        angular.module('consolo').controller('LabsGlobalTemplatesIndexController', LabsGlobalTemplatesIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var LabsIndexController = /** @class */ (function () {
            function LabsIndexController($state, LabService, currentPatient, logger, $filter, Session, $window, AboutService) {
                var _this = this;
                this.$state = $state;
                this.LabService = LabService;
                this.currentPatient = currentPatient;
                this.logger = logger;
                this.$filter = $filter;
                this.Session = Session;
                this.$window = $window;
                this.AboutService = AboutService;
                this.cdsEnabled = this.Session.get().agency.has_cds;
                this.loadData();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'LOINC',
                            field: 'loinc_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Test Name',
                            field: 'long_common_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'CDS',
                            field: '_addSomeText',
                            columnHideFn: function () {
                                return !_this.cdsEnabled;
                            },
                            formatValueFn: function (labListItem) {
                                if (labListItem.cds_url) {
                                    var cds_url = labListItem.cds_url;
                                    return '<div class="cds-column"><span class="material-icons">info</span></div>';
                                }
                                else {
                                    return '';
                                }
                            },
                            callbackFn: function (labListItem, $this, $event) {
                                if (labListItem.cds_url) {
                                    var event_1 = "Clinical Decision Support Item";
                                    var message = "Clicked Lab CDS Item: " + labListItem.cds_url;
                                    var patient_id = _this.currentPatient.id;
                                    _this.AboutService.createNewAuditLog(event_1, message, patient_id)
                                        .then(function () {
                                        _this.$window.open(labListItem.cds_url, '_blank');
                                    });
                                    $event.stopPropagation();
                                }
                            },
                        },
                        {
                            title: 'Category',
                            field: 'loinc_class',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (labListItem) {
                                var className = LabService.lookupCategory(labListItem.loinc_class) || labListItem.loinc_class;
                                return className;
                            }
                        },
                        {
                            title: 'Location',
                            field: 'location_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Report Date/Time',
                            field: 'report_time',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Collected',
                            field: 'collected_time',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Received',
                            field: 'received_time',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (currentLabPanel) {
                                    return _this.$state.go('patients.labs.view', {
                                        labPanelId: currentLabPanel.id,
                                        LabService: _this.LabService,
                                    });
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (currentLabPanel) {
                                    return _this.$state.go('patients.labs.edit', {
                                        labPanelId: currentLabPanel.id,
                                        LabService: _this.LabService,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.LabService.hasUpdatePermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (currentLabPanel) {
                                    return _this.LabService.remove(currentLabPanel.id)
                                        .then(function (response) {
                                        _this.loadData();
                                        _this.logger.success('You have successfully removed Lab Results: ' +
                                            currentLabPanel.long_common_name, 'Success:');
                                    })
                                        .catch(function (error) {
                                        _this.logger.error(error.data.errors.join(', '));
                                        console.log(error);
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.LabService.hasDestroyPermission;
                                },
                            },
                        ],
                    },
                };
            }
            LabsIndexController.prototype.loadData = function () {
                var _this = this;
                this.LabService.getLabPanelsByPatient(this.currentPatient.id)
                    .then(function (panels) {
                    _this.labPanels = panels;
                    _this.LabService.isLoading = false;
                })
                    .catch(function (error) {
                    console.log(error);
                    _this.LabService.isLoading = false;
                });
            };
            LabsIndexController.prototype.newLabPanel = function () {
                this.$state.go('patients.labs.new');
            };
            LabsIndexController.$inject = [
                '$state',
                'LabService',
                'currentPatient',
                'logger',
                '$filter',
                'SessionService',
                '$window',
                'AboutService',
            ];
            return LabsIndexController;
        }());
        angular.module('consolo').controller('LabsIndexController', LabsIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var LabsState = /** @class */ (function () {
        function LabsState($stateProvider) {
            $stateProvider
                .state('patients.labs', {
                url: '/labs',
                data: { title: 'Lab Results', hideSidebar: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/labs/index.html',
                        controller: 'LabsIndexController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.labs.new', {
                url: '/new',
                data: { title: 'Add Lab Results', hideSidebar: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/labs/edit.html',
                        controller: 'LabController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    labPanel: [
                        'currentPatient',
                        'LabService',
                        function (currentPatient, LabService) {
                        },
                    ],
                    readonly: function () {
                        return false;
                    },
                    adding: function () {
                        return true;
                    },
                },
            })
                .state('patients.labs.edit', {
                url: '/:labPanelId/edit',
                data: { title: 'Edit Lab Results', hideSidebar: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/labs/edit.html',
                        controller: 'LabController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    labPanel: [
                        '$stateParams',
                        'LabService',
                        function ($stateParams, LabService) {
                        },
                    ],
                    readonly: function () {
                        return false;
                    },
                    adding: function () {
                        return false;
                    },
                },
            })
                .state('patients.labs.view', {
                url: '/:labPanelId/view',
                data: { title: 'View Lab Results', hideSidebar: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/labs/edit.html',
                        controller: 'LabController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    labPanel: [
                        '$stateParams',
                        'LabService',
                        function ($stateParams, LabService) {
                        },
                    ],
                    readonly: function () {
                        return true;
                    },
                    adding: function () {
                        return false;
                    },
                }
            })
                .state('labsGlobalTemplates', {
                url: '/labs/templates',
                data: { title: 'Global Lab Templates', backAction: '', hideSidebar: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/labs/labs-global-templates-index.html',
                        controller: 'LabsGlobalTemplatesIndexController as vm',
                    },
                },
                resolve: {
                    globalLabPanelTemplates: [
                        '$stateParams',
                        'LabService',
                        function ($stateParams, LabService) {
                        },
                    ],
                    readonly: function () {
                        return false;
                    },
                    adding: function () {
                        return false;
                    },
                    global: function () {
                        return true;
                    },
                },
            })
                .state('labsAgencyTemplates', {
                url: '/labs/agency_templates',
                data: { title: 'Lab Templates', backAction: '', hideSidebar: true },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/labs/labs-templates-index.html',
                        controller: 'LabsGlobalTemplatesIndexController as vm',
                    },
                },
                resolve: {
                    labPanelTemplates: [
                        '$stateParams',
                        'LabService',
                        function ($stateParams, LabService) {
                        },
                    ],
                    readonly: function () {
                        return false;
                    },
                    adding: function () {
                        return false;
                    },
                    global: function () {
                        return false;
                    },
                },
            });
        }
        LabsState.$inject = ['$stateProvider'];
        return LabsState;
    }());
    angular.module('consolo').config(LabsState);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var LoincSearchDialogController = /** @class */ (function () {
            function LoincSearchDialogController(options, $mdDialog, LabService, $timeout, Session, $filter) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.LabService = LabService;
                this.$timeout = $timeout;
                this.Session = Session;
                this.$filter = $filter;
                this.loincSearchFilter = '';
                this.selectedLoinc = null;
                this.loincSearchLoaded = false;
                this.$onInit();
            }
            LoincSearchDialogController.prototype.$onInit = function () {
            };
            LoincSearchDialogController.prototype.prettyLoinc = function (item) {
                return item.loinc_number + " " + item.long_common_name;
            };
            LoincSearchDialogController.prototype.clearPendingLookup = function () {
                this.loincSearchLoaded = false;
                this.$timeout.cancel(this.pendingSearch);
                this.pendingSearch = null;
                this.selectedLoinc = null;
            };
            LoincSearchDialogController.prototype.setSelected = function (loinc) {
                if (this.selectedLoinc === null || this.selectedLoinc.loinc_number !== loinc.loinc_number) {
                    this.selectedLoinc = loinc;
                }
                else {
                    this.selectedLoinc = null;
                }
            };
            LoincSearchDialogController.prototype.isSelected = function (loinc) {
                if (this.selectedLoinc && this.selectedLoinc.loinc_number === loinc.loinc_number) {
                    return true;
                }
                else {
                    return false;
                }
            };
            LoincSearchDialogController.prototype.clearSearch = function () {
                this.selectedLoinc = null;
            };
            LoincSearchDialogController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            LoincSearchDialogController.prototype.submit = function () {
                this.$mdDialog.hide(this.selectedLoinc);
            };
            LoincSearchDialogController.prototype.setPanelCategory = function (panelCategory) {
                if (panelCategory.panelLoincs.length === 0) {
                    this.LabService.getLoincsByPanelCategory(panelCategory.name)
                        .then(function (panelLoincs) {
                        panelCategory.panelLoincs = panelLoincs;
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            };
            LoincSearchDialogController.$inject = [
                'options',
                '$mdDialog',
                'LabService',
                '$timeout',
                'SessionService',
                '$filter',
            ];
            return LoincSearchDialogController;
        }());
        angular
            .module('consolo')
            .controller('LoincSearchDialogController', LoincSearchDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var LocalPayerController = /** @class */ (function () {
            function LocalPayerController(LocalPayersService, $state, PayerService, localPayer, readonly) {
                this.LocalPayersService = LocalPayersService;
                this.$state = $state;
                this.PayerService = PayerService;
                this.localPayer = localPayer;
                this.readonly = readonly;
                this.paymentAdjustmentValues = [
                    { id: 'SA', description: 'Sequestration Adjustment' },
                    { id: 'QRA', description: 'Quality Reporting Adjustment' }
                ];
                this.adjustmentTypeValues = [
                    { id: 'CA', description: 'Contractual Adjustment' },
                    { id: 'D', description: 'Discount' }
                ];
                this.chargeTypeValues = [
                    { id: 'G', description: 'Gross' },
                    { id: 'N', description: 'Net' },
                ];
                this.shouldShowChargeType = false;
                if (this.localPayer.id) {
                    this.setPayer(this.localPayer.payer_type, this.localPayer.payer);
                }
                this.minDate = new Date();
                this.minEndDate = this.minDate;
                this.shouldShowChargeType = LocalPayersService.has_updated_agency_fee_schedule;
                var date = new Date();
                date.setHours(0, 0, 0, 0);
                this.formattedDate = date.toISOString();
            }
            LocalPayerController.prototype.addPaymentAdjustment = function () {
                if (!this.localPayer.payment_adjustments || !Array.isArray(this.localPayer.payment_adjustments)) {
                    this.localPayer.payment_adjustments = [];
                }
                var newAdjustment = {
                    start_date: this.formattedDate,
                    adjustment: '',
                    value: null,
                    adjustment_type: '',
                    carc: null,
                    end_date: null
                };
                this.localPayer.payment_adjustments.push(newAdjustment);
            };
            LocalPayerController.prototype.removePaymentAdjustment = function (paymentAdjustmentKey) {
                this.localPayer.payment_adjustments = this.localPayer.payment_adjustments.filter(function (adjustment) { return adjustment.$$hashKey !== paymentAdjustmentKey; });
            };
            LocalPayerController.prototype.onSelectionChange = function (payment_adjustment) {
                if (payment_adjustment.adjustment === 'SA') {
                    this.setDefaultValues(payment_adjustment, 1);
                }
                else if (payment_adjustment.adjustment === 'QRA') {
                    this.setDefaultValues(payment_adjustment, 2);
                }
            };
            LocalPayerController.prototype.fieldsCompleted = function () {
                if (this.localPayer.payment_adjustments.length === 0) {
                    return false;
                }
                return !this.localPayer.payment_adjustments.every(function (obj) {
                    return Object.keys(obj).every(function (key) {
                        if (key === 'end_date')
                            return true;
                        var value = obj[key];
                        return value !== undefined && value !== null && value !== '';
                    });
                });
            };
            LocalPayerController.prototype.setDefaultValues = function (payment_adjustment, inputValue) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                var newStartDate = new Date(payment_adjustment.start_date);
                // - We Iterate over the array to find the previous payment adjustment with the same adjustment -
                for (var i = 0; i < this.localPayer.payment_adjustments.length; i++) {
                    var adj = this.localPayer.payment_adjustments[i];
                    if (adj.adjustment === payment_adjustment.adjustment && adj !== payment_adjustment) {
                        // - We Set the end date of the previous adjustment to one day before the start date of the new adjustment -
                        var newEndDate = new Date(newStartDate.getTime());
                        newEndDate.setDate(newStartDate.getDate() - 1);
                        if (adj.end_date == null || adj.end_date == '') {
                            adj.end_date = newEndDate.toISOString();
                            break;
                        }
                    }
                }
                switch (inputValue) {
                    case 1:
                        payment_adjustment.adjustment = 'SA';
                        payment_adjustment.value = (_a = payment_adjustment.value, (_a !== null && _a !== void 0 ? _a : 2));
                        payment_adjustment.adjustment_type = (_b = payment_adjustment.adjustment_type, (_b !== null && _b !== void 0 ? _b : 'CA'));
                        payment_adjustment.carc = 253;
                        payment_adjustment.start_date = (_c = payment_adjustment.start_date, (_c !== null && _c !== void 0 ? _c : this.formattedDate));
                        payment_adjustment.end_date = (_d = payment_adjustment.end_date, (_d !== null && _d !== void 0 ? _d : ""));
                        break;
                    case 2:
                        payment_adjustment.adjustment = 'QRA';
                        payment_adjustment.value = (_e = payment_adjustment.value, (_e !== null && _e !== void 0 ? _e : 4));
                        payment_adjustment.adjustment_type = (_f = payment_adjustment.adjustment_type, (_f !== null && _f !== void 0 ? _f : 'D'));
                        payment_adjustment.carc = 45;
                        payment_adjustment.start_date = (_g = payment_adjustment.start_date, (_g !== null && _g !== void 0 ? _g : this.formattedDate));
                        payment_adjustment.end_date = (_h = payment_adjustment.end_date, (_h !== null && _h !== void 0 ? _h : ""));
                        break;
                }
            };
            LocalPayerController.prototype.hasDuplicateAdjustments = function (payment_adjustment) {
                for (var i = 0; i < payment_adjustment.length; i++) {
                    for (var j = i + 1; j < payment_adjustment.length; j++) {
                        if (payment_adjustment[i].adjustment === payment_adjustment[j].adjustment) {
                            return true; // duplicate found
                        }
                    }
                }
                return false; // no duplicates found
            };
            LocalPayerController.prototype.setPayer = function (type, payer) {
                this.payerString = type + '_payer';
                var id = this.localPayer[this.payerString + '_id'];
                if (payer) {
                    this.localPayer.payer = payer;
                }
                else if (type !== 'private_insurance') {
                    this.localPayer.payer = this.LocalPayersService.getPayerByIdType(id, type);
                    this.localPayer[this.payerString] = this.localPayer.payer;
                }
                if (this.localPayer.payer) {
                    this.claimConfigurations = this.localPayer.payer.payer_configuration.claim_configurations;
                    this.setFlags();
                }
                else {
                    this.booleanFlags = [];
                    this.inputFlags = [];
                }
            };
            LocalPayerController.prototype.setFlags = function () {
                var _this = this;
                this.booleanFlags = [];
                this.inputFlags = [];
                Object.keys(this.localPayer.payer.flags).forEach(function (key) {
                    if (_this.localPayer.payer.flags[key] === 'true') {
                        _this.booleanFlags.push(key);
                    }
                    else if (_this.localPayer.payer.flags[key] &&
                        _this.localPayer.payer.flags[key] !== 'false') {
                        _this.inputFlags.push(key);
                    }
                });
            };
            LocalPayerController.prototype.checkDateRanges = function (paymentAdjustments) {
                // Group adjustments by their 'adjustment' property
                var adjustmentsByType = paymentAdjustments.reduce(function (acc, adjustment) {
                    if (!acc[adjustment.adjustment]) {
                        acc[adjustment.adjustment] = [];
                    }
                    acc[adjustment.adjustment].push(adjustment);
                    return acc;
                }, {});
                // Iterate over each group of adjustments
                for (var adjustmentType in adjustmentsByType) {
                    var adjustments = adjustmentsByType[adjustmentType];
                    var adjustmentDescription = adjustmentType === 'QRA' ? 'Quality Reporting Adjustment' : 'Sequestration Adjustment';
                    var parsedAdjustments = adjustments.map(function (adjustment) { return (__assign(__assign({}, adjustment), { startDate: new Date(adjustment.start_date), endDate: adjustment.end_date ? new Date(adjustment.end_date) : null })); });
                    // Sort adjustments by start date
                    parsedAdjustments.sort(function (a, b) { return a.startDate - b.startDate; });
                    // Iterate through the array to check for gaps or overlaps
                    for (var i = 0; i < parsedAdjustments.length; i++) {
                        var current = parsedAdjustments[i];
                        // Check for invalid date ranges in the same adjustment
                        if (current.endDate && current.startDate > current.endDate) {
                            alert("Invalid date range detected for adjustment type " + adjustmentDescription);
                            return false;
                        }
                        if (i < parsedAdjustments.length - 1) {
                            var next = parsedAdjustments[i + 1];
                            // Check for overlap, treat null endDate as ongoing adjustment
                            if (!current.endDate || (current.endDate && current.endDate >= next.startDate)) {
                                alert("Overlap detected between payment adjustment dates for adjustment type " + adjustmentDescription);
                                return false;
                            }
                            // Check for gap only if next start date is not same as or one day after current end date
                            if (current.endDate) {
                                var currentEndDateNextDay = new Date(current.endDate);
                                currentEndDateNextDay.setDate(currentEndDateNextDay.getDate() + 1);
                                if (next.startDate > currentEndDateNextDay) {
                                    alert("Gap detected between payment adjustment dates for adjustment type " + adjustmentDescription);
                                    return false;
                                }
                            }
                        }
                    }
                }
                return true;
            };
            LocalPayerController.prototype.save = function () {
                var _this = this;
                if (this.localPayer.payment_adjustments && this.localPayer.payment_adjustments.length > 0) {
                    this.localPayer.payment_adjustments.forEach(function (adjustment) {
                        adjustment.percent = true;
                    });
                    if (!this.checkDateRanges(this.localPayer.payment_adjustments)) {
                        return;
                    }
                }
                if (!this.localPayer[this.payerString + '_id']) {
                    this.errors = ['You must select a Payer'];
                }
                else if (!this.localPayer.payer_type) {
                    this.errors = ['You must select a Payer Type'];
                }
                else {
                    this.LocalPayersService.upsert(this.localPayer).then(function () {
                        _this.$state.go('local-payers');
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                    });
                }
            };
            LocalPayerController.$inject = [
                'LocalPayersService',
                '$state',
                'PayerService',
                'localPayer',
                'readonly',
            ];
            return LocalPayerController;
        }());
        angular.module('consolo').controller('LocalPayerController', LocalPayerController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var LocalPayersDataTableService = /** @class */ (function (_super) {
            __extends(LocalPayersDataTableService, _super);
            function LocalPayersDataTableService($http, $interval, $state, $window, LocalPayersService) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.$window = $window;
                _this.LocalPayersService = LocalPayersService;
                _this.loadingData = false;
                _this.showFilters = true;
                _this.apiSelectUrl = '/api/v1/local_payers';
                _this.filters = new consolo.domain.LocalPayersFilter();
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        var localPayerData = data.data.map(function (localPayer) {
                            LocalPayersService.setPayer(localPayer);
                            return localPayer;
                        });
                        _this.data = localPayerData;
                        return localPayerData;
                    });
                };
                _this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'payer.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Address 1',
                            field: 'payer.address_1',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Address 2',
                            field: 'payer.address_2',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'City',
                            field: 'payer.city',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'State',
                            field: 'payer.state',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Zipcode',
                            field: 'payer.zipcode',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Phone',
                            field: 'payer.phone',
                            type: csg.datatable.ColumnTypes.phone,
                        },
                        {
                            title: 'Fax',
                            field: 'payer.fax',
                            type: csg.datatable.ColumnTypes.phone,
                        },
                        {
                            title: 'Service Type',
                            field: 'service_type',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (localPayer) {
                                return localPayer.paying_claims;
                            },
                        },
                        {
                            title: 'Offices',
                            field: 'offices',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (localPayer) {
                                if (localPayer.offices && localPayer.offices.length) {
                                    return localPayer.offices
                                        .map(function (office) {
                                        return office.name;
                                    })
                                        .join(', ');
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (localPayer) {
                                    _this.$state.go('local-payers.view', { id: localPayer.id });
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (localPayer) {
                                    _this.$state.go('local-payers.edit', { id: localPayer.id });
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (localPayer) {
                                    return _this.LocalPayersService.remove(localPayer).then(function (deletedLocalPayer) {
                                        _this.search();
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                },
                            },
                        ],
                    },
                };
                return _this;
            }
            LocalPayersDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.LocalPayersFilter();
                this.search();
            };
            LocalPayersDataTableService.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            LocalPayersDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            LocalPayersDataTableService.$inject = ['$http', '$interval', '$state', '$window', 'LocalPayersService'];
            return LocalPayersDataTableService;
        }(csg.datatable.ApiService));
        services.LocalPayersDataTableService = LocalPayersDataTableService;
        angular
            .module('consolo')
            .service('LocalPayersDataTableService', LocalPayersDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var LocalPayersController = /** @class */ (function () {
            function LocalPayersController(LocalPayersDataTableService, LocalPayersService, $state, PayerService) {
                this.LocalPayersDataTableService = LocalPayersDataTableService;
                this.LocalPayersService = LocalPayersService;
                this.$state = $state;
                this.PayerService = PayerService;
                this.LocalPayersDataTableService.search();
            }
            LocalPayersController.$inject = [
                'LocalPayersDataTableService',
                'LocalPayersService',
                '$state',
                'PayerService',
            ];
            return LocalPayersController;
        }());
        angular.module('consolo').controller('LocalPayersController', LocalPayersController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var LocalPayersService = /** @class */ (function () {
            function LocalPayersService($http, PermissionsService, logger, $mdDialog, PayerService, sessionService) {
                var _this = this;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.PayerService = PayerService;
                this.sessionService = sessionService;
                this.hasUpdatePermission = false;
                this.hasCreatePermission = false;
                this.hasDestroyPermission = false;
                this.loadingData = false;
                this.has_updated_agency_fee_schedule = false;
                this.url = '/api/v1/local_payers';
                this.has_updated_agency_fee_schedule = this.sessionService.get().agency.has_updated_agency_fee_schedule;
                this.hasUpdatePermission = this.PermissionsService.can('update', 'private_insurance_companies');
                this.hasCreatePermission = this.PermissionsService.can('create', 'private_insurance_companies');
                this.hasDestroyPermission = this.PermissionsService.can('destroy', 'private_insurance_companies');
                this.payerTypes = [
                    { id: null, description: '' },
                    { id: 'medicare', description: 'Medicare' },
                    { id: 'medicaid', description: 'Medicaid' },
                    { id: 'private_insurance', description: 'Private Insurance' },
                    { id: 'charitable_care', description: 'Charitable Care' },
                ];
                this.PayerService.getMedicarePayers().then(function (payers) {
                    _this.medicarePayers = payers;
                });
                this.PayerService.getMedicaidPayers().then(function (payers) {
                    _this.medicaidPayers = payers;
                });
                this.PayerService.getCharitableCarePayers().then(function (payers) {
                    _this.charitableCarePayers = payers;
                });
            }
            LocalPayersService.prototype.remove = function (localPayer) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete this insurance payer?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.delete(localPayer);
                });
            };
            LocalPayersService.prototype.delete = function (localPayer) {
                var _this = this;
                return this.$http.delete(this.url + '/' + localPayer.id).then(function (data) {
                    _this.logger.success('Insurance Payer deleted.');
                    return data.data;
                });
            };
            LocalPayersService.prototype.get = function (id) {
                var _this = this;
                return this.$http.get(this.url + '/' + id).then(function (data) {
                    _this.setPayer(data.data);
                    return data.data;
                });
            };
            LocalPayersService.prototype.getPayerByIdType = function (id, type) {
                if (type === 'medicare') {
                    return this.medicarePayers.filter(function (payer) {
                        return payer.id === id;
                    })[0];
                }
                else if (type === 'medicaid') {
                    return this.medicaidPayers.filter(function (payer) {
                        return payer.id === id;
                    })[0];
                }
                else {
                    return this.charitableCarePayers.filter(function (payer) {
                        return payer.id === id;
                    })[0];
                }
            };
            LocalPayersService.prototype.setPayer = function (localPayer) {
                // Modify sort of payment adjustments when is read
                localPayer.payment_adjustments.sort(function (a, b) {
                    if (a.adjustment < b.adjustment)
                        return -1;
                    if (a.adjustment > b.adjustment)
                        return 1;
                    if (new Date(a.start_date) < new Date(b.start_date))
                        return -1;
                    if (new Date(a.start_date) > new Date(b.start_date))
                        return 1;
                    return 0;
                });
                localPayer.payer = localPayer[localPayer.payer_type + '_payer'];
            };
            LocalPayersService.prototype.update = function (localPayer) {
                var _this = this;
                return this.$http.patch(this.url + '/' + localPayer.id, localPayer).then(function (data) {
                    _this.logger.success('Insurance Payer updated.');
                    return data.data;
                });
            };
            LocalPayersService.prototype.create = function (localPayer) {
                var _this = this;
                return this.$http.post(this.url, localPayer).then(function (data) {
                    _this.logger.success('Insurance Payer created.');
                    return data.data;
                });
            };
            LocalPayersService.prototype.upsert = function (localPayer) {
                if (localPayer.id) {
                    return this.update(localPayer);
                }
                else {
                    return this.create(localPayer);
                }
            };
            LocalPayersService.prototype.getClaimTitle = function (claimType) {
                if (claimType === 'hospice') {
                    return 'Hospice';
                }
                else if (claimType === 'pre_election') {
                    return 'Pre Election';
                }
                else if (claimType === 'notice_of_election') {
                    return 'Notice of Election';
                }
                else if (claimType === 'notice_of_termination') {
                    return 'Notice of Termination';
                }
                else if (claimType === 'combined_hospice_room_and_board') {
                    return 'Combined Hospice/Room and Board';
                }
                else if (claimType === 'encounter') {
                    return 'Encounter';
                }
                else {
                    return 'Room and Board';
                }
            };
            LocalPayersService.prototype.getApprovedPayers = function (payers) {
                return payers.filter(function (payer) {
                    return payer.approved;
                });
            };
            LocalPayersService.$inject = ['$http', 'PermissionsService', 'logger', '$mdDialog', 'PayerService', 'SessionService'];
            return LocalPayersService;
        }());
        services.LocalPayersService = LocalPayersService;
        angular.module('consolo').service('LocalPayersService', LocalPayersService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var LocalPayersState = /** @class */ (function () {
        function LocalPayersState($stateProvider) {
            $stateProvider
                .state('local-payers', {
                url: '/local_payers',
                data: { title: 'Insurance Payers', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/local-payers/index.html',
                        controller: 'LocalPayersController as vm',
                    },
                },
            })
                .state('local-payers.new', {
                url: '/new',
                data: { title: 'Add Insurance Payer', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/local-payers/local-payer.html',
                        controller: 'LocalPayerController as vm',
                    },
                },
                resolve: {
                    localPayer: [
                        function () {
                            return {
                                charge_type: 'G',
                            };
                        },
                    ],
                    readonly: [
                        function () {
                            return false;
                        },
                    ],
                },
            })
                .state('local-payers.edit', {
                url: '/:id/edit',
                data: { title: 'Edit Insurance Payer', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/local-payers/local-payer.html',
                        controller: 'LocalPayerController as vm',
                    },
                },
                resolve: {
                    localPayer: [
                        '$stateParams',
                        'LocalPayersService',
                        'PayerService',
                        function ($stateParams, LocalPayersService, PayerService) {
                            return LocalPayersService.get($stateParams.id).then(function (localPayer) {
                                return localPayer;
                            });
                        },
                    ],
                    readonly: [
                        function () {
                            return false;
                        },
                    ],
                },
            })
                .state('local-payers.view', {
                url: '/:id/view',
                data: { title: 'View Insurance Payer', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/local-payers/local-payer.html',
                        controller: 'LocalPayerController as vm',
                    },
                },
                resolve: {
                    localPayer: [
                        '$stateParams',
                        'LocalPayersService',
                        function ($stateParams, LocalPayersService) {
                            return LocalPayersService.get($stateParams.id).then(function (localPayer) {
                                return localPayer;
                            });
                        },
                    ],
                    readonly: [
                        function () {
                            return true;
                        },
                    ],
                },
            });
        }
        LocalPayersState.$inject = ['$stateProvider'];
        return LocalPayersState;
    }());
    angular.module('consolo').config(LocalPayersState);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        var AuthCheckController = /** @class */ (function () {
            function AuthCheckController($state, $rootScope, $location, tokenService, oidcService, sessionService) {
                var _this = this;
                this.$state = $state;
                this.$rootScope = $rootScope;
                this.$location = $location;
                this.tokenService = tokenService;
                this.oidcService = oidcService;
                this.sessionService = sessionService;
                this.status = 'Loading';
                this.authorizing = true;
                this.working = false;
                var toState = this.$rootScope['_toState'] || 'dashboard';
                var toParams = this.$rootScope['_toParams'] || {};
                var toSearch = this.$rootScope['_toSearch'] || {};
                this.tokenService.getToken().then(function () {
                    return _this.sessionService.smartCheck().then(function () {
                        _this.$rootScope['_authChecked'] = true;
                        _this.$state.go(toState, toParams);
                        if (Object.keys(toSearch).length > 0) {
                            _this.$location.search(toSearch);
                        }
                    }, function (resp) {
                        _this.authorizing = false;
                        _this.status = 'Error';
                        var err = resp.data && resp.data.errors ? resp.data.errors.join(',') : '';
                        _this.errors = ["Session smart check failed during navigation to " + toState + ".", err];
                    });
                }, function (err) {
                    _this.oidcService.setGoTo(null, toState, toParams);
                    _this.oidcService.start();
                });
            }
            AuthCheckController.$inject = [
                '$state',
                '$rootScope',
                '$location',
                'AccessTokenService',
                'OidcService',
                'SessionService',
            ];
            return AuthCheckController;
        }());
        login.AuthCheckController = AuthCheckController;
        angular.module('consolo').controller('AuthCheckController', AuthCheckController);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        var LoginCallbackController = /** @class */ (function () {
            function LoginCallbackController($location, $timeout, $state, oidcService, tokenService, sessionService, loginFlow, logger) {
                this.$location = $location;
                this.$timeout = $timeout;
                this.$state = $state;
                this.oidcService = oidcService;
                this.tokenService = tokenService;
                this.sessionService = sessionService;
                this.loginFlow = loginFlow;
                this.logger = logger;
                this.authorizing = false;
                this.ngOnInit();
            }
            LoginCallbackController.prototype.ngOnInit = function () {
                var _this = this;
                this.authorizing = true;
                this.status = 'Authenticating';
                var params = this.$location.search();
                if (this.sessionService.get()) {
                    this.$location.search({});
                    this.goToUrl(this.sessionService.get().user.dashboard_url);
                }
                else if (!params['code'] && !params['state']) {
                    this.$location.search({});
                    this.$state.go('unauthenticated');
                }
                else {
                    this.oidcService.exchangeTokens(params['code'], params['state']).then(function (tokenPack) { return _this.start(tokenPack); }, function (resp) { return _this.fail(resp); });
                }
            };
            LoginCallbackController.prototype.start = function (tokenPack) {
                var _this = this;
                this.$location.search({});
                this.status = 'Starting session';
                this.tokenService.setToken(tokenPack.access_token, tokenPack.expires_in);
                this.sessionService
                    .identity(tokenPack.id_token)
                    .then(function (sess) { return _this.sessionService.start(sess, _this.oidcService.sessionWaitFor); })
                    .then(function (sess) { return _this.loginFlow.complete(sess); })
                    .catch(function (e) {
                    // TODO better error handling
                    console.log('FAILED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
                    console.log(e);
                    _this.authorizing = false;
                    _this.errors = e && e.data && e.data.errors ? e.data.errors : ["Unknown error: " + e];
                });
            };
            LoginCallbackController.prototype.fail = function (resp) {
                var errors = resp.data && resp.data.errors ? resp.data.errors : ['Unknown error'];
                if (errors.filter(function (e) { return e.match(/Invalid or expired state/i); }).length > 0) {
                    // Most likely, the user sat on the login screen for too long and the state expired. Try again.
                    this.oidcService.start();
                    this.logger.error(errors.join('; '));
                }
                else {
                    this.authorizing = false;
                    this.status = 'Error authenticating';
                    this.errors = errors;
                }
            };
            LoginCallbackController.prototype.goToUrl = function (url) {
                var _this = this;
                this.$timeout(function () {
                    if (url.indexOf('/y/') > -1) {
                        _this.$location.url(url.replace('/y/', ''));
                    }
                    else {
                        window.location.href = url;
                    }
                });
            };
            LoginCallbackController.$inject = [
                '$location',
                '$timeout',
                '$state',
                'OidcService',
                'AccessTokenService',
                'SessionService',
                'LoginFlowService',
                'logger',
            ];
            return LoginCallbackController;
        }());
        login.LoginCallbackController = LoginCallbackController;
        angular.module('consolo').controller('LoginCallbackController', LoginCallbackController);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        var LoginFlowService = /** @class */ (function () {
            function LoginFlowService($location, $timeout, $q, $state, $mdDialog, oidcService, pincodeService, pincodeModal, chartingPrefetch) {
                this.$location = $location;
                this.$timeout = $timeout;
                this.$q = $q;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.oidcService = oidcService;
                this.pincodeService = pincodeService;
                this.pincodeModal = pincodeModal;
                this.chartingPrefetch = chartingPrefetch;
            }
            LoginFlowService.prototype.complete = function (sess) {
                var _this = this;
                return this.ensurePincode().then(function () { return _this.goHome(sess); });
            };
            LoginFlowService.prototype.ensurePincode = function () {
                var _this = this;
                return this.pincodeService.getPincode().then(function (data) {
                    if (!data) {
                        return _this.pincodeModal.show();
                    }
                });
            };
            LoginFlowService.prototype.goHome = function (sess) {
                var storedTarget = this.oidcService.getGoTo();
                var target;
                if (sess.user.default_role &&
                    sess.user.default_role.name === 'Unassigned') {
                    target = { state: 'unassigned' };
                }
                else if (storedTarget) {
                    target = storedTarget;
                }
                else if (!sess.user.dashboard_url || sess.user.dashboard_url === '/') {
                    target = { state: 'dashboard' };
                }
                else {
                    target = { url: sess.user.dashboard_url };
                }
                this.allowRedirect(target).then(function (redirect) {
                    if (redirect) {
                        redirect();
                    }
                });
            };
            LoginFlowService.prototype.allowRedirect = function (target) {
                var _this = this;
                // we're transitioning to an Angular state, so it's safe to redirect right away
                if (target.state) {
                    this.chartingPrefetch.cachePatients(null, false, true);
                    return this.$q.when(function () { return _this.$state.go(target.state, target.stateParams); });
                    // we're transitioning to a URL that's actually an Angular state, so it's safe to redirect right away
                }
                else if (target.url.indexOf('/y/') > -1) {
                    this.chartingPrefetch.cachePatients(null, false, true);
                    return this.$q.when(function () {
                        _this.$timeout(function () { return _this.$location.url(target.url.replace('/y/', '')); }, 0);
                    });
                    // we're transitioning to Rails, so we have to wait until everything is cached before redirecting
                }
                else {
                    return this.chartingPrefetch.checkUpdatePatientCache().then(function (val) {
                        if (val) {
                            _this.showRedirectModal(target.url);
                            return null;
                        }
                        else {
                            return _this.$q.when(function () {
                                _this.$timeout(function () { return (window.location.href = target.url); }, 0);
                            });
                        }
                    });
                }
            };
            LoginFlowService.prototype.showRedirectModal = function (path) {
                var dialog = {
                    templateUrl: '/y/templates/login/redirect-modal.html',
                    controller: 'RailsRedirectModalController',
                    controllerAs: '$ctrl',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    locals: { path: path },
                };
                this.$mdDialog.show(dialog);
            };
            LoginFlowService.$inject = [
                '$location',
                '$timeout',
                '$q',
                '$state',
                '$mdDialog',
                'OidcService',
                'PincodeService',
                'PincodeModal',
                'ChartingPrefetch',
            ];
            return LoginFlowService;
        }());
        login.LoginFlowService = LoginFlowService;
        angular.module('consolo').service('LoginFlowService', LoginFlowService);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        var LoginController = /** @class */ (function () {
            function LoginController(oidcService, $location) {
                this.oidcService = oidcService;
                this.$location = $location;
                this.working = false;
                this.ngOnInit();
            }
            LoginController.prototype.ngOnInit = function () {
                this.startOauth2();
            };
            LoginController.prototype.startOauth2 = function () {
                var _this = this;
                this.working = true;
                this.oidcService.start(this.$location.search().back).catch(function (resp) {
                    _this.working = false;
                    if (resp.data) {
                        _this.errors = resp.data.errors || [
                            'Unknown network or server error (HTTP status ' + resp.status + ')',
                        ];
                    }
                    else {
                        _this.errors = ['Unexpected error'];
                    }
                });
            };
            LoginController.$inject = ['OidcService', '$location'];
            return LoginController;
        }());
        login.LoginController = LoginController;
        angular.module('consolo').controller('LoginController', LoginController);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Router]);
    function Router($stateProvider) {
        $stateProvider
            .state('unauthenticated', {
            url: '/login',
            data: { hideSidebar: true },
            views: {
                'main-content': {
                    templateUrl: '/y/templates/login/login.html',
                    controller: 'LoginController as vm',
                },
            },
            public: true,
        })
            .state('auth-check', {
            url: '/auth-check',
            data: { hideSidebar: true },
            views: {
                'main-content': {
                    templateUrl: '/y/templates/login/auth-check.html',
                    controller: 'AuthCheckController as vm',
                }
            },
            public: true,
        })
            .state('unassigned', {
            url: '/unassigned',
            data: { hideSidebar: true },
            views: {
                'main-content': {
                    templateUrl: '/y/templates/login/unassigned.html',
                },
            },
            public: false,
        })
            .state('oauth2-callback', {
            url: '/login/callback',
            data: { hideSidebar: true },
            views: {
                'main-content': {
                    templateUrl: '/y/templates/login/callback.html',
                    controller: 'LoginCallbackController as vm',
                },
            },
            public: true,
        })
            .state('oauth2-silent', {
            url: '/login/silent',
            data: { hideSidebar: true },
            views: {
                'main-content': {
                    template: '<div></div>',
                    controller: 'SilentLoginController as vm',
                },
            },
            public: true,
        });
    }
})();

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        'use strict';
        var PincodeModal = /** @class */ (function () {
            function PincodeModal($q, $mdDialog) {
                this.$q = $q;
                this.$mdDialog = $mdDialog;
            } //: ng.material.IDialogService) {}
            PincodeModal.prototype.hide = function () {
                var _this = this;
                if (this.modal) {
                    return this.$mdDialog.hide(this.modal).then(function () {
                        _this.modal = null;
                    });
                }
                else {
                    return this.$q.when(true);
                }
            };
            PincodeModal.prototype.show = function (reset) {
                if (this.modal) {
                    return;
                }
                this.modal = {
                    templateUrl: '/y/templates/login/pincode.html',
                    controller: 'PincodeController',
                    controllerAs: 'vm',
                    hasBackdrop: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    fullscreen: true,
                    locals: { resetPincode: reset },
                };
                return this.$mdDialog.show(this.modal);
            };
            PincodeModal.$inject = ['$q', '$mdDialog'];
            return PincodeModal;
        }());
        login.PincodeModal = PincodeModal;
        angular.module('consolo').service('PincodeModal', PincodeModal);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PincodeController = /** @class */ (function () {
            function PincodeController(PincodeService, PincodeModal, resetPincode) {
                this.PincodeService = PincodeService;
                this.PincodeModal = PincodeModal;
                this.resetPincode = resetPincode;
                this.confirmPincode = '';
                this.pincode = '';
            }
            PincodeController.prototype.setPincode = function () {
                var _this = this;
                if (this.pincode !== this.confirmPincode) {
                    this.invalid = true;
                    this.badFormat = false;
                }
                else if (!this.pincode.match(/^\d{8}$/)) {
                    this.invalid = false;
                    this.badFormat = true;
                }
                else {
                    this.PincodeService.setPincode(this.pincode).then(function (data) {
                        _this.PincodeModal.hide();
                    });
                }
            };
            PincodeController.$inject = ['PincodeService', 'PincodeModal', 'resetPincode'];
            return PincodeController;
        }());
        angular.module('consolo').controller('PincodeController', PincodeController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        'use strict';
        var PincodeService = /** @class */ (function () {
            function PincodeService(Store, Session, crypto, $q) {
                this.Store = Store;
                this.Session = Session;
                this.crypto = crypto;
                this.$q = $q;
            }
            PincodeService.prototype.checkPincode = function (pincode) {
                //TODO: Update to use a hash
                return this.getPincode().then(function (storedPincode) {
                    return pincode === storedPincode;
                });
            };
            PincodeService.prototype.getPincode = function () {
                var _this = this;
                var u = this.Session.getUser();
                if (u) {
                    return this.Store.local('app')
                        .getItem('pincode:' + u.id)
                        .then(function (data) {
                        if (data) {
                            return _this.crypto.decrypt(data).then(function (unencryptedItem) {
                                return unencryptedItem;
                            }, function (e) {
                                console.log(e);
                                return null;
                            });
                        }
                        else {
                            return null;
                        }
                    });
                }
                else {
                    return this.$q.reject();
                }
            };
            PincodeService.prototype.setPincode = function (pincode) {
                var _this = this;
                //TODO: This should be hashed, not encrypted
                var u = this.Session.getUser();
                if (u) {
                    return this.crypto.encrypt(pincode).then(function (encryptedItem) {
                        return _this.Store.local('app')
                            .setItem('pincode:' + u.id, encryptedItem)
                            .then(function (data) {
                            return data;
                        });
                    });
                }
                else {
                    return this.$q.reject();
                }
            };
            PincodeService.$inject = ['Store', 'SessionService', 'crypto', '$q'];
            return PincodeService;
        }());
        login.PincodeService = PincodeService;
        angular.module('consolo').service('PincodeService', PincodeService);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var RailsRedirectModalController = /** @class */ (function () {
            function RailsRedirectModalController($q, $scope, SyncManager, ChartingPrefetch, path, $mdDialog) {
                var _this = this;
                this.$q = $q;
                this.$scope = $scope;
                this.SyncManager = SyncManager;
                this.ChartingPrefetch = ChartingPrefetch;
                this.path = path;
                this.$mdDialog = $mdDialog;
                this.showProgress = false;
                this.progressComplete = false;
                this.retry = false;
                this.redirecting = false;
                this.percent = 0;
                SyncManager.activate().then(function (sync) {
                    _this.syncData = sync.data;
                    _this.patientList = sync.patientList;
                });
            }
            RailsRedirectModalController.prototype.sync = function ($event) {
                var _this = this;
                var promise;
                var counter = 1;
                this.percent = 0;
                this.retry = false;
                this.progressComplete = false;
                this.showProgress = true;
                if (!this.patientList.length) {
                    promise = this.SyncManager.updateList().then(function () {
                        _this.patientList = _this.SyncManager.patientList;
                        _this.status = 'Retrieving patient list...';
                    });
                }
                else {
                    promise = this.$q.when(true);
                }
                promise.then(function () {
                    var total = _this.patientList.length + Math.ceil(_this.patientList.length / 5) + 5;
                    if (_this.syncData.includeMedications) {
                        total += _this.patientList.length;
                    }
                    _this.ChartingPrefetch.cachePatients(function (status) {
                        _this.status = status;
                        _this.percent = Math.round((counter / total) * 100);
                        counter++;
                    }, true).then(function () {
                        _this.progressComplete = true;
                    }, function (reason) {
                        _this.status = 'Error: ' + reason;
                        _this.retry = true;
                        _this.progressComplete = true;
                    });
                });
            };
            RailsRedirectModalController.prototype.redirect = function () {
                var _this = this;
                this.redirecting = true;
                setTimeout(function () {
                    window.location.href = _this.path;
                    _this.$scope.$apply();
                    _this.$mdDialog.close();
                });
            };
            RailsRedirectModalController.prototype.cancel = function () {
                this.ChartingPrefetch.cancelPatientCaching();
            };
            RailsRedirectModalController.$inject = [
                '$q',
                '$scope',
                'SyncManager',
                'ChartingPrefetch',
                'path',
                '$mdDialog',
            ];
            return RailsRedirectModalController;
        }());
        angular
            .module('consolo')
            .controller('RailsRedirectModalController', RailsRedirectModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var login;
    (function (login) {
        var TokenParamsKey = 'oidc:tokenParams';
        var SilentLoginController = /** @class */ (function () {
            function SilentLoginController(oidcService, $location) {
                this.oidcService = oidcService;
                this.$location = $location;
                this.targetOrigin = window.location.protocol + "//" + window.location.host;
                this.ngOnInit();
            }
            SilentLoginController.prototype.ngOnInit = function () {
                var _this = this;
                var params = this.$location.search();
                if (params['error']) {
                    this.sendError(params['error'] + ": " + params['error_description']);
                }
                else if (!params['code'] && !params['state']) {
                    if (params['tokenParams']) {
                        sessionStorage.setItem(TokenParamsKey, params['tokenParams']);
                    }
                    this.oidcService.start(null, true);
                }
                else {
                    var tokenParams = JSON.parse(sessionStorage.getItem(TokenParamsKey) || '{}');
                    sessionStorage.removeItem(TokenParamsKey);
                    this.oidcService
                        .exchangeTokens(params['code'], params['state'], tokenParams)
                        .then(function (tokenPack) { return _this.sendTokenPack(tokenPack); }, function (resp) { return _this.sendErrorResp(resp); });
                }
            };
            SilentLoginController.prototype.sendTokenPack = function (tokenPack) {
                window.parent.postMessage({ type: 'tokenPack', body: tokenPack }, this.targetOrigin);
            };
            SilentLoginController.prototype.sendErrorResp = function (resp) {
                var error;
                if (resp.status === 403 && this.oidcService.forbiddenPath) {
                    error = 'Forbidden';
                }
                else if (resp.data && resp.data.errors) {
                    error = resp.data.errors.join(', ');
                }
                else if (resp.data && resp.data.error) {
                    error = resp.data.error;
                    if (resp.data.error_description) {
                        error += ': ' + resp.data.error_description;
                    }
                }
                else {
                    error = "Unknown error (" + (resp.status || resp.message) + ")";
                }
                this.sendError(error);
            };
            SilentLoginController.prototype.sendError = function (error) {
                window.parent.postMessage({ type: 'error', body: error }, this.targetOrigin);
            };
            SilentLoginController.$inject = [
                'OidcService',
                '$location',
            ];
            return SilentLoginController;
        }());
        login.SilentLoginController = SilentLoginController;
        angular.module('consolo').controller('SilentLoginController', SilentLoginController);
    })(login = consolo.login || (consolo.login = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('Breadcrumb', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/breadcrumbs', null, {
            current: {
                method: 'GET',
                url: '/api/v1/breadcrumbs/current',
                isArray: true,
            },
        });
    }
})();

(function () {
    'use strict';
    angular.module('consolo').directive('routeMap', ['GoogleMapsService', Directive]);
    function Directive(GoogleMapsService) {
        return {
            restrict: 'E',
            replace: true,
            scope: { addresses: '=', checkAddresses: '=?', route: '=', routeType: '=', calculating: '=' },
            template: '<div flex class="hidden-print"></div>',
            link: link,
        };
        /* jshint browser: true */
        function link(scope, element) {
            var gms = new GoogleMapsService(element[0]);
            gms.then(function (gms) {
                scope.$watch('addresses', drawRoute);
                function drawRoute() {
                    if (scope.addresses.length > 1) {
                        scope.calculating = true;
                        var googleMapsCoords = [gmapsOriginPoint()].concat(scope.addresses.map(toGmapsStopPoints));
                        gms.getRoute(googleMapsCoords).then(function (route) {
                            scope.route.distance = route.distance;
                            scope.route.time = route.time;
                            scope.route.stops = route.stops;
                            scope.calculating = false;
                        }, function (errors) {
                            alert(errors);
                        });
                    }
                }
            });
        }
        function gmapsOriginPoint() {
            // @ts-ignore
            return new google.maps.LatLng(element[0].origin_latitude, element[0].origin_longitude); //jshint ignore:line
        }
        function toGmapsStopPoints(point) {
            // return new google.maps.LatLng(point.latitude, point.longitude);
            return point.address_1 + ', ' + point.city + ', ' + point.state + ', ' + point.zipcode;
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').filter('secondsToTime', function () {
        return filter;
    });
    // Convert seconds to a pretty string
    function filter(seconds) {
        var times = [];
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - hours * 3600) / 60);
        if (hours > 0) {
            times.push(hours + ' hr');
        }
        times.push(minutes + ' min');
        return times.join(' ');
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var IncomingMessagesController = /** @class */ (function () {
            function IncomingMessagesController($state, logger, $mdDialog, messagingService, fileTypes, selectedMessage, receiveDate, patientService) {
                this.$state = $state;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.messagingService = messagingService;
                this.fileTypes = fileTypes;
                this.selectedMessage = selectedMessage;
                this.receiveDate = receiveDate;
                this.patientService = patientService;
                this.incomingMessages = this.incomingMessages || [];
                this.isLoading = true;
                this.initLayout();
                this.query();
            }
            IncomingMessagesController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.messagingService.getIncomingMessages(this.startDate, this.endDate).then(function (response) {
                    _this.incomingMessages = response.data;
                    _this.isLoading = false;
                });
            };
            IncomingMessagesController.prototype.patientLookup = function (query) {
                return this.messagingService.patientLookup(query).then(function (results) {
                    return results.data;
                });
            };
            IncomingMessagesController.prototype.selectPatient = function (patient) {
                if (patient) {
                    this.selectedMessage.patient_id = patient.id;
                }
            };
            IncomingMessagesController.prototype.attachToPatient = function (message) {
                var _this = this;
                this.$mdDialog.hide();
                this.messagingService.moveToPatientDocs(message).then(function () {
                    _this.logger.success('Message moved.', 'Success:');
                    _this.query();
                });
            };
            IncomingMessagesController.prototype.resetStartDate = function () {
                this.startDate = null;
                this.query();
            };
            IncomingMessagesController.prototype.resetEndDate = function () {
                this.endDate = null;
                this.query();
            };
            IncomingMessagesController.prototype.getDate = function (date) {
                return new Date(date).toLocaleDateString();
            };
            IncomingMessagesController.prototype.initLayout = function () {
                var _this = this;
                this.incomingMessagesLayout = {
                    id_field: 'id',
                    options: {
                        defaultPageSize: 20,
                        hideFooter: true
                    },
                    columns: [
                        {
                            title: 'Received',
                            field: 'created_at',
                            type: 'date'
                        },
                        {
                            title: 'Recipient',
                            field: 'data.message.toAddress'
                        },
                        {
                            title: 'Sender',
                            field: 'data.message.fromAddress'
                        },
                        {
                            title: 'Message',
                            field: 'data.message.body'
                        },
                        {
                            title: 'Attachments',
                            field: 'attachments',
                            formatValueFn: function (message) {
                                var files = '';
                                message.attachments.forEach(function (attachment) {
                                    files += '<a href="/api/v2/direct_secure_messages/' +
                                        message.id + '/download?attachment_id=' +
                                        attachment.id +
                                        '">' + attachment.filename +
                                        '</a>';
                                });
                                return files;
                            }
                        }
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Move to Patient',
                                icon: 'person',
                                callbackFn: function (message) {
                                    _this.receiveDate = new Date(message.created_at).toDateString();
                                    var types = [];
                                    // Create list of file types from attachments
                                    if (message.attachments.length) {
                                        message.attachments.forEach(function (attachment) {
                                            var type = attachment.filename.split('.').pop();
                                            if (!types.filter(function (t) { return t === type; }).length) {
                                                types.push(type);
                                            }
                                        });
                                        _this.fileTypes = types.join(', ');
                                    }
                                    _this.selectedMessage = message;
                                    _this.$mdDialog.show({
                                        templateUrl: '/y/templates/messaging/move-to-patient-docs.html',
                                        controller: 'IncomingMessagesController as vm',
                                        bindToController: true,
                                        locals: {
                                            selectedMessage: _this.selectedMessage,
                                            fileTypes: _this.fileTypes,
                                            receiveDate: _this.receiveDate
                                        },
                                        clickOutsideToClose: true
                                    });
                                }
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (message) {
                                    var confirm = _this.$mdDialog
                                        .confirm()
                                        .title('Confirmation')
                                        .htmlContent('Are you sure you want to delete this Direct Secure message without attaching to a patient chart?')
                                        .ariaLabel('Removal Confirmation')
                                        .ok('Yes, Delete the Data')
                                        .cancel('No, Cancel and Review');
                                    _this.$mdDialog.show(confirm).then(function () {
                                        _this.messagingService.deleteMessage(message.id).then(function () {
                                            _this.logger.success('Message deleted.', 'Success:');
                                            _this.query();
                                        });
                                    });
                                }
                            }
                        ]
                    }
                };
            };
            IncomingMessagesController.$inject = [
                '$state',
                'logger',
                '$mdDialog',
                'messagingService',
                'fileTypes',
                'selectedMessage',
                'receiveDate',
                'patientService'
            ];
            return IncomingMessagesController;
        }());
        angular.module('consolo').controller('IncomingMessagesController', IncomingMessagesController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MessagingService = /** @class */ (function () {
            function MessagingService($http, PermissionsService) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.viewPermission = false;
                this.createPermission = false;
                this.viewPermission = this.PermissionsService.can('show', 'direct_secure_messages');
                this.createPermission = this.PermissionsService.can('create', 'direct_secure_messages');
            }
            MessagingService.prototype.patientLookup = function (query) {
                return this.$http({
                    url: "/api/v1/patients",
                    method: 'GET',
                    params: { q: query, all_offices: true }
                });
            };
            MessagingService.prototype.getIncomingMessages = function (startDate, endDate) {
                return this.$http({
                    url: "/api/v2/direct_secure_messages/unstructured",
                    method: 'GET',
                    params: { start_date: startDate, end_date: endDate }
                });
            };
            MessagingService.prototype.getFileUrl = function (dsmId, attachId) {
                return this.$http({
                    url: "/api/v2/direct_secure_messages/" + dsmId + "/download",
                    method: 'GET',
                    params: { attachment_id: attachId }
                });
            };
            MessagingService.prototype.moveToPatientDocs = function (dsm) {
                return this.$http({
                    url: "/api/v2/direct_secure_messages/" + dsm.id + "/move",
                    method: 'POST',
                    data: { patient_id: dsm.patient_id }
                });
            };
            MessagingService.prototype.deleteMessage = function (dsmId) {
                return this.$http({
                    url: "/api/v2/direct_secure_messages/" + dsmId + "/archive",
                    method: 'POST',
                    data: { message_id: dsmId }
                });
            };
            MessagingService.$inject = ['$http', 'PermissionsService'];
            return MessagingService;
        }());
        services.MessagingService = MessagingService;
        angular.module('consolo').service('messagingService', MessagingService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MileageManagerController = /** @class */ (function () {
            function MileageManagerController(Session) {
                this.Session = Session;
                var agency = this.Session.get().agency;
                if (agency.has_sisense_mileage) {
                    this.visitTrackerReportURL = "/emr/" + agency.subdomain + "/bi/visit-tracker";
                }
                else {
                    this.visitTrackerReportURL = '/y/visit_tracker/reports';
                }
            }
            MileageManagerController.$inject = ['SessionService'];
            return MileageManagerController;
        }());
        angular.module('consolo').controller('MileageManagerController', MileageManagerController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var MileageManagerStates = /** @class */ (function () {
            function MileageManagerStates($stateProvider) {
                $stateProvider.state('mileage-manager', {
                    url: '/mileage_manager',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/mileage-manager/mileage-manager.html',
                            controller: 'MileageManagerController as vm',
                        },
                    },
                });
            }
            MileageManagerStates.$inject = ['$stateProvider'];
            return MileageManagerStates;
        }());
        angular.module('consolo').config(MileageManagerStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').filter('navLink', ['$sce', Filter]);
    function Filter($sce) {
        return function (link) {
            var html;
            if (link[1]) {
                var options = link[2] || {};
                var url;
                if (typeof link[1] === 'string') {
                    url = link[1];
                }
                else {
                    url = '/' + link[1].controller;
                    if (link[1].id) {
                        url += '/' + link[1].id;
                    }
                    if (link[1].action !== 'index') {
                        url += '/' + link[1].action;
                    }
                }
                html = '<a href="' + url + '"';
                for (var attr in link[2]) {
                    if (options.hasOwnProperty(attr)) {
                        html += ' ' + attr + '="' + link[2][attr] + '"';
                    }
                }
                html += '>' + link[0] + '</a>';
            }
            else {
                html = link[0];
            }
            return $sce.trustAsHtml(html);
        };
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        function Directive() {
            return {
                restrict: 'E',
                scope: true,
                templateUrl: '/y/templates/navbar/navbar.html',
                controller: 'NavbarController as vm',
            };
        }
        var NavbarController = /** @class */ (function () {
            function NavbarController($document, $rootScope, $timeout, $mdSidenav, $mdComponentRegistry, AboutService, logger, Session, PermissionsService, NetworkManager, $mdTheming, $state, $window, $q, BeaconService, SidebarService, 
            // We need to refactor this - if we do it this way CQM permissions will break
            // private CqmService: consolo.services.CqmService,
            $interval, $location, $scope, $http, WRMSsoAuthService) {
                var _this = this;
                this.$document = $document;
                this.$rootScope = $rootScope;
                this.$timeout = $timeout;
                this.$mdSidenav = $mdSidenav;
                this.$mdComponentRegistry = $mdComponentRegistry;
                this.AboutService = AboutService;
                this.logger = logger;
                this.Session = Session;
                this.PermissionsService = PermissionsService;
                this.NetworkManager = NetworkManager;
                this.$mdTheming = $mdTheming;
                this.$state = $state;
                this.$window = $window;
                this.$q = $q;
                this.BeaconService = BeaconService;
                this.SidebarService = SidebarService;
                this.$interval = $interval;
                this.$location = $location;
                this.$scope = $scope;
                this.$http = $http;
                this.WRMSsoAuthService = WRMSsoAuthService;
                this.patientSidenavReady = false;
                this.isOnline = false;
                this.mainMenuOpen = false;
                this.dashboard_url = '/y/dashboard';
                this.showSidebar = false;
                this.visible = false;
                this.menus = [];
                this.appTitle = 'Home';
                this.agencyHasCareInsights = false;
                this.agencyHasValueBasedInsights = false;
                this.userHasCareInsights = false;
                this.userHasValueBasedInsights = false;
                this.isOnline = NetworkManager.isOnline();
                // check to hide sidebar initially and on state change
                this.showSidebar = $state.current.data ? $state.current.data.hideSidebar !== true : true;
                $rootScope.$on('$stateChangeSuccess', function (event, toState) {
                    _this.activeLink = null;
                    _this.applyActiveMenuLink();
                    var tempSidebar = toState.data ? toState.data.hideSidebar !== true : true;
                    if (tempSidebar !== _this.showSidebar) {
                        _this.$timeout(function () {
                            _this.showSidebar = tempSidebar;
                        });
                    }
                    _this.userHasCareInsights = _this.PermissionsService.can('access', 'careinsights');
                    _this.userHasValueBasedInsights = _this.PermissionsService.can('access', 'value_based_insights')
                        || _this.PermissionsService.can('admin_access', 'value_based_insights');
                    // this.showSidebar = toState.data ? toState.data.hideSidebar !== true : true;
                });
                if (this.$window.location.href.indexOf('localhost') > -1) {
                    this.schedulerUrl = this.$window.location.protocol + "//" + this.$window.location.host.replace('3000', '8080') + "/scheduler";
                }
                else {
                    this.schedulerUrl = this.$window.location.protocol + "//" + this.$window.location.host + "/scheduler";
                }
                Session.subscribe(function (sess) {
                    if (sess) {
                        _this.visible = true;
                        AboutService.menus().then(function (menus) {
                            _this.menus = menus;
                            _this.applyActiveMenuLink();
                        });
                        _this.orgName = sess.user.organization.name;
                        _this.agencyId = sess.agency.id;
                        _this.userId = sess.user.id;
                        if (sess.agency.service_line === 'palliative') {
                            _this.appTitle = 'Palliative';
                        }
                        else {
                            _this.appTitle = 'Hospice';
                        }
                        _this.dashboard_url = sess.user.dashboard_url;
                        if (!_this.dashboard_url || _this.dashboard_url === '/') {
                            _this.dashboard_url = '/y/#/dashboard';
                        }
                        _this.hasScheduler = sess.agency.has_new_scheduler;
                        _this.hasARDashboard = sess.user.flags.has_ar_dashboard;
                        _this.hasCQM = sess.agency.has_cqm;
                        _this.agencyHasCareInsights = sess.agency.has_careinsights;
                        _this.agencyHasValueBasedInsights = sess.agency.has_value_based_insights || sess.agency.has_vbi_comprehensive_assessment_guide;
                        _this.arDashboardUrl = _this.$window.location.protocol + "//" + _this.$window.location.host.replace('3000', '8080') + "/ardashboard/";
                    }
                    else {
                        _this.activate();
                    }
                });
            }
            Object.defineProperty(NavbarController.prototype, "hasCareInsights", {
                get: function () {
                    return this.agencyHasCareInsights && this.userHasCareInsights;
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(NavbarController.prototype, "hasValueBasedInsights", {
                get: function () {
                    return this.agencyHasValueBasedInsights && this.userHasValueBasedInsights;
                },
                enumerable: true,
                configurable: true
            });
            NavbarController.prototype.openCareInsights = function () {
                var _this = this;
                this.$http.get('/api/v2/careinsights/url/patients').then(function (data) {
                    if (data.data.url) {
                        _this.$window.open(data.data.url, '_blank');
                    }
                });
            };
            NavbarController.prototype.openValueBasedInsights = function () {
                var _this = this;
                this.$http.get('/api/v2/value_based_insights/url/patients').then(function (data) {
                    if (data.data.url) {
                        _this.$window.open(data.data.url, '_blank');
                    }
                });
            };
            NavbarController.prototype.applyActiveMenuLink = function () {
                var _this = this;
                var currentPagePath = '/y' + this.$location.path();
                this.menus.forEach(function (menuItem) {
                    //menuItem[1] contains the list of links
                    var currentPageMatch = menuItem[1].find(function (link) {
                        //link[1] contains the templateUrl
                        var menuLinkTemplateUrl = link[1];
                        if (!menuLinkTemplateUrl) {
                            return false;
                        }
                        return menuLinkTemplateUrl.toString() === currentPagePath;
                    });
                    if (currentPageMatch) {
                        _this.activeLink = currentPageMatch[1];
                        return;
                    }
                });
            };
            NavbarController.prototype.menuHasActiveLink = function (menu) {
                var _this = this;
                if (!menu || !this.activeLink) {
                    return false;
                }
                return menu[1].find(function (link) { return link[1] === _this.activeLink; });
            };
            NavbarController.prototype.itemHasActiveLink = function (linkItem) {
                if (!linkItem || !this.activeLink) {
                    return false;
                }
                return linkItem[1] === this.activeLink;
            };
            NavbarController.prototype.openDhitReports = function () {
                // We need to refactor this - if we do it this way CQM permissions will break
                // this.CqmService.getDhitReportsUrl(this.agencyId, this.userId).then(resp => {
                //   if (resp.url != null && resp.url[0] != null) {
                //     let dhitUrl = resp.url[0];
                //     this.$window.open(dhitUrl, '_blank');
                //   } else {
                //     this.logger.error("DHIT Reporting is currently unavailable.");
                //   }
                // });
            };
            NavbarController.prototype.menuIsOpen = function (index) {
                return this.activeMenu === index + 1;
            };
            NavbarController.prototype.getBackground = function (index) {
                if (this.activeMenu === index + 1) {
                    return { background: 'primary-700' };
                }
                else {
                    return { background: 'primary-700-0' };
                }
            };
            NavbarController.prototype.topMenuIsActive = function (index) {
                console.log("Index");
                console.log(index);
                console.log("Open Menu");
                console.log(this.activeMenu);
                console.log("End");
                return this.activeMenu === index + 1;
            };
            NavbarController.prototype.openMenu = function (index, deviceType, event) {
                event.stopPropagation();
                if (deviceType === 'desktop') {
                    this.activeMenu = this.activeMenu === index + 1 ? null : index + 1;
                }
                else if (deviceType === 'mobile') {
                    this.activeMenu = index + 1;
                }
            };
            NavbarController.prototype.getIcon = function (menu) {
                var currentMenu = menu.toLowerCase();
                var icon = '';
                switch (currentMenu) {
                    case 'main':
                        icon = '/y/assets/images/wellsky-small-white-logo.png';
                        break;
                    case 'patients':
                        icon = 'social:person';
                        break;
                    case 'claims':
                        icon = 'editor:attach_money';
                        break;
                    case 'services':
                        icon = 'places:business_center';
                        break;
                    case 'admin':
                        icon = 'action:supervisor_account';
                        break;
                    case 'agency':
                        icon = 'action:account_balance';
                        break;
                    case 'help':
                        icon = 'action:help';
                        break;
                }
                return icon;
            };
            NavbarController.prototype.toggle = function (sidebar) {
                this.$mdSidenav(sidebar).toggle();
            };
            NavbarController.prototype.checkPatientSidenav = function (sidebar) {
                if (this.patientSidenavReady) {
                    return this.$mdSidenav(sidebar).isOpen() || this.$mdSidenav(sidebar).isLockedOpen();
                }
                else {
                    return true;
                }
            };
            NavbarController.prototype.activate = function () {
                var _this = this;
                this.offlineMenu = this.getOfflineMenu();
                this.$mdComponentRegistry.when('patient-sidebar').then(function () {
                    _this.patientSidenavReady = true;
                });
                this.AboutService.get().then(function (metadata) {
                    _this.$rootScope.env = metadata['env'];
                    _this.$rootScope.envRelease = _this.getEnvRelease(metadata['env'], metadata['release']);
                    _this.$rootScope.service_line_text = metadata['agency']['service_line']
                        ? metadata['agency']['service_line']
                        : 'hospice';
                    // this.$rootScope.service_line_text = 'palliative';
                    _this.$rootScope.envLabel = 'staging'; // this.envLabel(this.$rootScope.env);
                    _this.envBarStyle(_this.$rootScope.env);
                    // this.$rootScope.envBarStyle = 'env-' + this.$rootScope.env + '-gradient'; // this.envBarStyle(this.$rootScope.env);
                    _this.$rootScope.theme =
                        _this.$rootScope.service_line_text === 'hospice'
                            ? 'default'
                            : _this.$rootScope.service_line_text;
                    if (_this.$rootScope.theme !== 'default') {
                        _this.$mdTheming.generateTheme(_this.$rootScope.theme);
                    }
                    _this.$mdTheming.setBrowserColor({ theme: _this.$rootScope.theme });
                    _this.orgName = metadata['agency']['description'];
                });
                this.$document[0].body.addEventListener('click', function (e) {
                    _this.closeMenus(e);
                });
                this.NetworkManager.onlineHandler.onEvent('consoloNavbar', function () {
                    _this.isOnline = true;
                });
                this.NetworkManager.offlineHandler.onEvent('consoloNavbar', function () {
                    _this.isOnline = false;
                });
            };
            NavbarController.prototype.closeMenus = function (e) {
                var _this = this;
                if (this.activeMenu && !e.target.attributes.getNamedItem('ignore-click')) {
                    this.$timeout(function () {
                        _this.activeMenu = null;
                        _this.mainMenuOpen = false;
                    });
                }
            };
            NavbarController.prototype.getOfflineMenu = function () {
                return [
                    [
                        'Offline Menu',
                        [
                            ['Classic Dashboard', '/y/dashboard'],
                            ['Clinical Charts', '/y/clinical_chart'],
                        ],
                    ],
                ];
            };
            NavbarController.prototype.getEnvRelease = function (env, release) {
                switch (env) {
                    case 'development':
                    case 'preview':
                    case 'mainline':
                        var q = this.getYearQuarter(true);
                        return release + " " + q;
                        break;
                    default:
                        return release;
                        break;
                }
            };
            NavbarController.prototype.envLabel = function (s) {
                var envLabel = '';
                switch (s) {
                    case 'production':
                        envLabel = '';
                        break;
                    case 'preprod':
                        envLabel = 'testing environment';
                        break;
                    case 'sigma':
                        envLabel = s + ' Σ';
                        break;
                    case 'development':
                        envLabel = s + ' Δ';
                        break;
                    case 'mainline':
                    case 'preview':
                        var q = this.getYearQuarter();
                        envLabel = "Preview " + q;
                        break;
                    default:
                        envLabel = s + ' environment';
                        break;
                }
                return envLabel;
            };
            NavbarController.prototype.envBarStyle = function (s) {
                var bgColor = '';
                var bgColor2 = '';
                var bgBottom;
                var color = 'white';
                var minHeight = '35px';
                var maxHeight = '35px;';
                var minStyle = {};
                switch (s) {
                    case 'production':
                        bgColor = 'blue';
                        bgColor2 = 'blue';
                        break;
                    case 'development':
                        minHeight = '15px';
                        maxHeight = '15px';
                        minStyle['font-size'] = '14px';
                        bgColor = '#90A4AE';
                        bgColor2 = '#90A4AE';
                        break;
                    case 'preprod':
                    case 'testing':
                    // bgColor = '#7E57C2';
                    // bgColor2 = '#512DA8';
                    // break;
                    case 'mainline':
                    // bgColor = '#795548';
                    // bgColor2 = '#3E2723';
                    // bgBottom = '#795548';
                    // break;
                    case 'auditor':
                    // bgColor = '#757575';
                    // bgColor2 = '#212121';
                    // break;
                    case 'sigma':
                        bgColor = '#B94423';
                        bgColor2 = '#9B230E';
                        bgBottom = '#9B230E';
                        break;
                    case 'training':
                    case 'preview':
                    case 'staging':
                        bgColor = '#F2C46B';
                        bgColor2 = '#F0BF93';
                        bgBottom = '#F2C46B';
                        color = 'rgba(0,0,0,.87)';
                        break;
                    default:
                        bgColor = '#B94423';
                        bgColor2 = '#9B230E';
                        bgBottom = '#9B230E';
                        break;
                }
                if (!bgBottom) {
                    bgBottom = bgColor;
                }
                this.$rootScope.envBarTextStyle = minStyle;
                this.$rootScope.envBarStyleBottom =
                    s === 'development'
                        ? {
                            'min-height': '0px',
                            'max-height': '0px',
                        }
                        : {
                            'background-color': bgBottom,
                            color: color,
                        };
                this.$rootScope.envBarStyleTop =
                    s === 'development'
                        ? {
                            'min-height': '18px',
                            'max-height': '18px',
                            //background: bgBottom
                            background: "repeating-linear-gradient(-45deg, " + bgColor + ", " + bgColor + " 10px, " + bgColor2 + " 10px, " + bgColor2 + " 20px)",
                        }
                        : {
                            //background: bgBottom
                            background: "repeating-linear-gradient(-45deg, " + bgColor + ", " + bgColor + " 10px, " + bgColor2 + " 10px, " + bgColor2 + " 20px)",
                            color: color,
                        };
                // return s === 'development'
                //   ? {
                //       'background-color': bgColor,
                //       color: color,
                //       'min-height': minHeight,
                //       'max-height': maxHeight,
                //     }
                //   : {
                //       'background-color': bgColor,
                //       color: color,
                //     };
            };
            NavbarController.prototype.getYearQuarter = function (quarterFirst) {
                var d = new Date();
                var y = d.getFullYear();
                var q = Math.floor(d.getMonth() / 3) + 1;
                return quarterFirst ? "Q" + q + " " + y : y + " Q" + q;
            };
            NavbarController.prototype.mobileMainMenuClass = function (s) {
                var env = s;
                var menuClass = '';
                switch (env) {
                    case 'development':
                        menuClass = 'dev';
                    case '':
                        break;
                    default:
                        menuClass = 'non-prod';
                }
                return menuClass;
            };
            NavbarController.$inject = [
                '$document',
                '$rootScope',
                '$timeout',
                '$mdSidenav',
                '$mdComponentRegistry',
                'AboutService',
                'logger',
                'SessionService',
                'PermissionsService',
                'NetworkManager',
                '$mdTheming',
                '$state',
                '$window',
                '$q',
                'BeaconService',
                'SidebarService',
                // 'CqmService',
                '$interval',
                '$location',
                '$scope',
                '$http',
                'WRMSsoAuthService'
            ];
            return NavbarController;
        }());
        angular
            .module('consolo')
            .directive('consoloNavbar', ['$mdSidenav', 'AboutService', 'SessionService', Directive]);
        angular.module('consolo').controller('NavbarController', NavbarController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var RootController = /** @class */ (function () {
            function RootController(EmbeddedHelper) {
                this.EmbeddedHelper = EmbeddedHelper;
                this.embedded = false;
                this.embedded = EmbeddedHelper.isEmbedded;
            }
            RootController.$inject = ['EmbeddedHelper'];
            return RootController;
        }());
        common.RootController = RootController;
        angular.module('consolo').controller('RootController', RootController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('consoloSidebar', Directive);
    function Directive() {
        return {
            restrict: 'E',
            templateUrl: '/y/templates/navbar/sidebar.html',
            controller: [
                '$scope',
                'cache',
                '$mdSidenav',
                '$rootScope',
                '$state',
                '$timeout',
                '$window',
                'CurrentPatient',
                'SidebarService',
                'EmbeddedHelper',
                'SessionService',
                Controller,
            ],
            controllerAs: 'sideVm',
        };
    }
    function Controller($scope, cache, $mdSidenav, $rootScope, $state, $timeout, $window, CurrentPatient, SidebarService, EmbeddedHelper, Session) {
        var vm = this;
        vm.patientId = $state.params.patientId;
        vm.patient = null;
        vm.dashboardUrl = null;
        vm.isLoading = false;
        vm.SidebarService = SidebarService;
        vm.title = title();
        vm.embedded = EmbeddedHelper.isEmbedded;
        vm.close = close;
        vm.goBack = goBack;
        vm.getEnv = getEnv;
        vm.checkPatient = checkPatient;
        vm.getPatient = getPatient;
        activate();
        Session.subscribe(function (session) {
            vm.session = session;
            if (session) {
                vm.serviceLine = session.agency.service_line;
                vm.isPalliative = vm.serviceLine === 'palliative';
                vm.dashboardUrl = session.user.dashboard_url;
            }
        });
        $scope.$watchCollection(function () {
            return $state.params;
        }, function () {
            getPatient();
        });
        function activate() {
            $timeout(function () {
                $rootScope.$on('$stateChangeStart', function () {
                    vm.isLoading = true;
                });
                $rootScope.$on('$stateChangeSuccess', function (e, current) {
                    vm.title = title();
                    $window.document.title =
                        current.data && current.data.title
                            ? current.data.title + ' - WellSky'
                            : 'WellSky';
                    vm.isLoading = false;
                });
                $rootScope.$on('$stateChangeError', function () {
                    vm.isLoading = false;
                });
                vm.title = title();
            }, 100);
            getPatient();
        }
        function close() {
            $mdSidenav('sidebar').close();
        }
        function goBack() {
            if (vm.backParams) {
                $state.go(vm.backAction, vm.backParams);
            }
            else {
                $state.go(vm.backAction);
            }
        }
        function getEnv(s) {
            var envLabel = '';
            switch (s) {
                case 'production':
                    envLabel = '';
                    break;
                case 'preprod':
                    envLabel = 'testing';
                    break;
                case 'sigma':
                    envLabel = s + ' Σ';
                    break;
                case 'development':
                    envLabel = s + ' Δ';
                    break;
                default:
                    envLabel = s;
                    break;
            }
            return envLabel;
        }
        function title() {
            if ($state.current.data) {
                var tempSidebar = $state.current.data ? $state.current.data.hideSidebar !== true : true;
                if (tempSidebar !== vm.SidebarService.showSidebar) {
                    $timeout(function () {
                        vm.SidebarService.showSidebar = tempSidebar;
                    });
                }
                vm.backAction = $state.current.data.backAction;
                vm.backParams = $state.current.data.backParams;
                return $state.current.data.title;
            }
            else {
                vm.SidebarService.showSidebar = true;
                vm.backAction = undefined;
                vm.backParams = undefined;
                return vm.isPalliative ? 'WellSky Palliative' : 'WellSky Hospice';
            }
        }
        function checkPatient() {
            return !!$state.params.patientId;
        }
        function getPatient() {
            vm.patientId = $state.params.patientId;
            if (vm.patientId) {
                vm.patient = CurrentPatient.patient;
            }
            else {
                vm.patient = null;
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var SidebarService = /** @class */ (function () {
            function SidebarService() {
            }
            SidebarService.prototype.toggle = function () {
                this.showSidebar = !this.showSidebar;
            };
            SidebarService.prototype.close = function () {
                this.showSidebar = false;
            };
            SidebarService.prototype.open = function () {
                this.showSidebar = true;
            };
            SidebarService.$inject = [];
            return SidebarService;
        }());
        services.SidebarService = SidebarService;
        angular.module('consolo').service('SidebarService', SidebarService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var navbarBottom;
    (function (navbarBottom) {
        var DebugController = /** @class */ (function () {
            function DebugController(debugLog, CSV, session, sessionTimer, $mdDialog, emrWebRelease, $scope, $interval, $http) {
                var _this = this;
                this.debugLog = debugLog;
                this.CSV = CSV;
                this.session = session;
                this.sessionTimer = sessionTimer;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.entries = [];
                this.jsBuildNumber = emrWebRelease();
                this.htmlBuildNumber = this.readHtmlBuildNumber();
                this.client = this.buildClientString();
                this.tabId = debugLog.tabId;
                this.refresh();
                var refresh = $interval(function () {
                    _this.refresh();
                }, 2 * 1000);
                $scope.$on('$destroy', function () { return $interval.cancel(refresh); });
            }
            DebugController.prototype.csvContents = function () {
                var cssNum = this.readCssBuildNumber();
                return ([
                    "Build: JS " + this.jsBuildNumber + "/CSS " + cssNum + "/HTML " + this.htmlBuildNumber,
                    "Client: " + this.client,
                    "Tab: " + this.tabId,
                    "Session: " + this.sessionStatus,
                ].join('\n') +
                    '\n\n' +
                    this.CSV.generate(this.entries.map(function (e) { return [e.tabId, e.logLevel, e.user, e.timestamp, e.message]; }), ['Tab', 'Log Level', 'User', 'Timestamp', 'Message']));
            };
            DebugController.prototype.close = function () {
                this.$mdDialog.hide();
            };
            DebugController.prototype.pingWebmethds = function () {
                console.log("Attempting to ping Webmethods...");
                return this.$http.get('/api/v1/about/wm_ping').then(function (response) {
                    console.log("WM ping response: " + response.data);
                });
            };
            DebugController.prototype.refresh = function () {
                var _this = this;
                this.sessionStatus = this.buildSessionStatus();
                this.debugLog.read().then(function (entries) { return (_this.entries = entries); });
            };
            DebugController.prototype.buildClientString = function () {
                var ua = new UAParser();
                var x = ua.getResult();
                var parts = [x.browser.name + " " + x.browser.version];
                if (x.device && x.device.model) {
                    parts.push(x.device.model);
                }
                if (x.os.version) {
                    parts.push(x.os.name + " " + x.os.version);
                }
                else {
                    parts.push(x.os.name);
                }
                return parts.join(', ');
            };
            DebugController.prototype.buildSessionStatus = function () {
                var parts = [];
                var sess = this.session.get();
                if (sess) {
                    parts.push(sess.user.email);
                    parts.push(this.sessionTimer.ssoTimeout.ttl() + " local ttl");
                }
                else {
                    parts.push('no data');
                }
                return parts.join(', ');
            };
            DebugController.prototype.readCssBuildNumber = function () {
                var el = document.getElementById('css-version');
                var after = el ? window.getComputedStyle(el, ':after') : null;
                return after ? JSON.parse(after.getPropertyValue('content')) : '?';
            };
            DebugController.prototype.readHtmlBuildNumber = function () {
                var metas = document.getElementsByTagName('meta');
                for (var i = 0; i < metas.length; i++) {
                    var meta = metas[i];
                    if (meta.getAttribute('name') === 'consolo-version') {
                        return meta.getAttribute('content');
                    }
                }
                return '?';
            };
            DebugController.$inject = [
                'DebugLogService',
                'CSV',
                'SessionService',
                'SessionTimerService',
                '$mdDialog',
                'EmrWebRelease',
                '$scope',
                '$interval',
                '$http',
            ];
            return DebugController;
        }());
        navbarBottom.DebugController = DebugController;
        angular.module('consolo').controller('DebugController', DebugController);
    })(navbarBottom = consolo.navbarBottom || (consolo.navbarBottom = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('consoloHipaaTimer', {
            bindings: {},
            template: "\n      <span hide-sm hide-xs>Session&nbsp;&nbsp;</span>\n      <span ng-class=\"$ctrl.statusClass()\" class=\"label\">{{$ctrl.lifecycle.status[0]}}</span>\n    ",
            controller: 'HipaaTimerController as $ctrl',
        });
        var HipaaTimerController = /** @class */ (function () {
            function HipaaTimerController(lifecycle) {
                this.lifecycle = lifecycle;
            }
            HipaaTimerController.prototype.statusClass = function () {
                switch (this.lifecycle.status[0]) {
                    case 'Good':
                        return 'label-success';
                    case 'Warning':
                        return 'label-warning';
                    case 'Imminent':
                        return 'label-danger';
                    case 'Expired':
                        return 'label-danger';
                    default:
                        return 'label-default';
                }
            };
            HipaaTimerController.$inject = ['SessionTimerService'];
            return HipaaTimerController;
        }());
        common.HipaaTimerController = HipaaTimerController;
        angular.module('consolo').controller('HipaaTimerController', HipaaTimerController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .service('LoginModal', ['$q', '$mdDialog', '$state', LoginModal]);
    function LoginModal($q, $mdDialog, $state) {
        var modal;
        var modalPromise;
        this.hide = hide;
        this.show = show;
        this.cancel = cancel;
        this.showReconnectModal = showReconnectModal;
        function hide(val) {
            if (modal) {
                return $mdDialog.hide(val).then(function () {
                    modal = null;
                });
            }
            else {
                return $q.when(true);
            }
        }
        function cancel() {
            if (modal) {
                return $mdDialog.cancel.then(function () {
                    modal = null;
                });
            }
            else {
                try {
                    return $mdDialog.hide().then(function () {
                        modal = null;
                    });
                }
                catch (e) {
                    return $q.when(true);
                }
            }
        }
        function showReconnectModal() {
            if (modal) {
                return modalPromise;
            }
            modal = $mdDialog
                .confirm()
                .title('Online Connection Detected')
                .textContent('Would you like to log back into the server or continue working in Limited Functionality Mode?')
                .ok('Login')
                .cancel('Limited Functionality');
            modalPromise = $mdDialog
                .show(modal)
                .then(function () {
                modal = null;
                return show();
            })
                .catch(function () {
                modal = null;
                return $q.reject();
            });
            return modalPromise;
        }
        function show() {
            if (modal) {
                return $q.reject();
            }
            modal = {
                templateUrl: '/y/templates/common/session/resume-session-modal.html',
                controller: 'ResumeSessionModalController',
                controllerAs: 'loginVm',
                hasBackdrop: true,
                clickOutsideToClose: false,
                escapeToClose: false,
                bindToController: true,
            };
            if ($state.current) {
                if ($state.current.name !== 'unauthenticated') {
                    return $mdDialog.show(modal);
                }
                else {
                    modal = null;
                    return $q.reject();
                }
            }
            else {
                return $mdDialog.show(modal);
            }
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').directive('consoloNavbarBottom', Directive);
    function Directive() {
        return {
            restrict: 'E',
            templateUrl: '/y/templates/navbar-bottom/navbar-bottom.html',
            controller: [
                '$state',
                '$http',
                '$window',
                '$rootScope',
                '$mdDialog',
                '$mdPanel',
                'LoginModal',
                'NetworkManager',
                'SessionService',
                'SsoService',
                'RememberTokenExtension',
                'logger',
                'ChartingPrefetch',
                'SystemAlertsService',
                '$mdMedia',
                Controller,
            ],
            controllerAs: 'navbarBottomVm',
        };
    }
    function Controller($state, $http, $window, $root, $mdDialog, $mdPanel, LoginModal, NetworkManager, Session, SsoService, RememberTokenExtension, logger, ChartingPrefetch, SystemAlertsService, $mdMedia) {
        var vm = this;
        vm.$mdMedia = $mdMedia;
        vm.session = null;
        vm.wentOffline = false;
        vm.ChartingPrefetch = ChartingPrefetch;
        vm.logout = logout;
        vm.reconnect = reconnect;
        vm.openDebug = openDebug;
        vm.refresh = refresh;
        vm.cacheClick = cacheClick;
        vm.numAlerts = numAlerts;
        vm.openAlertPanel = openAlertPanel;
        vm.NetworkManager = NetworkManager;
        vm.isDevEnv = isDevEnv;
        Session.subscribe(function (session) {
            vm.session = session;
        });
        NetworkManager.onlineHandler.onEvent('consoloNavbarBottom', getOfflineStatus);
        NetworkManager.offlineHandler.onEvent('consoloNavbarBottom', getOfflineStatus);
        function getOfflineStatus() {
            vm.wentOffline = NetworkManager.wentOffline;
        }
        $root.$on('http_server_error', function (e, msg) {
            logger.error(msg);
        });
        function numAlerts() {
            return SystemAlertsService.all.length;
        }
        var alertPanelTemplate = '' +
            '<div class="message-panel" md-whiteframe="6" flex-xs="100" flex-gt-xs="75" flex-lg="50">' +
            '<div class="system-alert padding-top-sm padding-bottom-sm" layout="row" layout-align="start start" ' +
            'ng-repeat="alert in $ctrl.alerts" ng-class="::alert.category">' +
            '<section class="padding-right-sm padding-left-sm">' +
            '<md-icon class="alert-icon" md-svg-icon="{{::alert.icon}}"></md-icon>' +
            '</section>' +
            '<section class="padding-right-sm">' +
            '<div class="md-subhead">{{::alert.subject}}</div>' +
            '<div class="body" ng-bind-html="::alert.html_body"></div>' +
            '</section>' +
            '<div>' +
            '</div>';
        function openAlertPanel($event) {
            var panelPosition = $mdPanel
                .newPanelPosition()
                .absolute()
                .right('10px')
                .bottom('30px');
            var config = {
                targetEvent: $event,
                id: 'toolbar_systemAlerts',
                attachTo: angular.element(document.body),
                controller: AlertPanelController,
                controllerAs: '$ctrl',
                template: alertPanelTemplate,
                position: panelPosition,
                panelClass: 'menu-panel-container',
                locals: {
                    alerts: SystemAlertsService.all,
                },
                zIndex: 100,
                hasBackdrop: false,
                clickOutsideToClose: true,
                escapeToClose: true,
            };
            $mdPanel.open(config);
        }
        function logout() {
            var dialog = $mdDialog
                .confirm()
                .title('Logout')
                .textContent('Are you sure you want to log out?')
                .ok('Yes')
                .cancel('No');
            $mdDialog.show(dialog).then(function () {
                RememberTokenExtension.clear();
                return $http.get('/logout').catch(function () { });
            }).then(function () {
                SsoService.logout();
            });
        }
        function reconnect() {
            LoginModal.show();
        }
        function refresh() {
            Session.check(true);
        }
        function isDevEnv() {
            var loc = location.hostname.split('.');
            return loc.length < 2 || loc[1] === 'sigma' || loc[1] === 'testing';
        }
        function openDebug() {
            $mdDialog.show({
                clickOutsideToClose: true,
                bindToController: true,
                templateUrl: '/y/templates/navbar-bottom/debug.html',
                controller: 'DebugController as debugVm',
            });
        }
        function cacheClick() {
            if (this.ChartingPrefetch.status === 'Expired' ||
                this.ChartingPrefetch.status === 'Canceled') {
                var dialog = $mdDialog
                    .confirm()
                    .title('Refresh Cache')
                    .textContent('Are you sure you want to refresh your cache?')
                    .ok('Yes')
                    .cancel('No');
                $mdDialog.show(dialog).then(function () {
                    vm.ChartingPrefetch.cachePatients(function () { }, true);
                });
            }
            else {
                $state.go('device_settings');
            }
        }
    }
    function AlertPanelController() { }
})();

//import { PubSubService, PubSubChannel } from '../common/pubsub.service';
var consolo;
(function (consolo) {
    function Directive() {
        return {
            restrict: 'A',
            controller: 'NotificationController as notificationVm',
        };
    }
    var NotificationController = /** @class */ (function () {
        function NotificationController($timeout, $mdBottomSheet, notificationsService) {
            this.$timeout = $timeout;
            this.$mdBottomSheet = $mdBottomSheet;
            this.notificationsService = notificationsService;
            this.notifications = [];
        }
        NotificationController.prototype.numUnread = function () {
            return this.notificationsService.numUnread();
        };
        NotificationController.prototype.show = function () {
            var _this = this;
            this.$timeout(function () { return _this.notificationsService.acknowledgeAll(); }, 5100);
            this.$mdBottomSheet.show({
                templateUrl: '/y/templates/navbar-bottom/notifications.html',
                controller: 'NotificationsController',
                controllerAs: 'nVm',
                resolve: {
                    notifications: function () {
                        return _this.notificationsService.notifications.slice(0, 5);
                    },
                },
            });
        };
        NotificationController.$inject = ['$timeout', '$mdBottomSheet', 'NotificationsService'];
        return NotificationController;
    }());
    angular.module('consolo').directive('consoloNotificationsList', Directive);
    angular
        .module('consolo')
        .controller('NotificationController', NotificationController);
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('NotificationsController', [
        '$mdBottomSheet',
        'PdfPrinter',
        'logger',
        'notifications',
        Controller,
    ]);
    function Controller($mdBottomSheet, PdfPrinter, logger, notifications) {
        var vm = this;
        vm.notifications = notifications;
        vm.close = close;
        vm.print = print;
        function close() {
            $mdBottomSheet.hide();
        }
        function print(url) {
            logger.info('Preparing to print...');
            PdfPrinter.print(url);
        }
    }
})();

var consolo;
(function (consolo) {
    'use strict';
    var NotificationsService = /** @class */ (function () {
        function NotificationsService(pubSub, $http) {
            this.pubSub = pubSub;
            this.$http = $http;
            this.notifications = [];
            this.extensionName = 'Notifications';
        }
        NotificationsService.prototype.onSessionVisible = function (sess) {
            var _this = this;
            this.pubSub.channel("emr:user:" + sess.user.uuid + ":agency:" + sess.agency.code).then(function (chan) {
                _this.channel = chan;
                chan.on('notification', function (n) { return _this.notifications.unshift(n); });
            });
            this.refresh();
        };
        NotificationsService.prototype.onSessionClear = function () {
            this.notifications = [];
            if (this.channel) {
                this.channel.leave();
                this.channel = null;
            }
        };
        NotificationsService.prototype.numUnread = function () {
            return this.notifications.filter(function (n) { return !n.read; }).length;
        };
        NotificationsService.prototype.refresh = function () {
            var _this = this;
            return this.$http.get('/api/v1/notifications').then(function (resp) {
                _this.notifications = resp.data;
                return _this.notifications;
            });
        };
        NotificationsService.prototype.acknowledgeAll = function () {
            var unreadIds = this.notifications.filter(function (n) {
                if (!n.read) {
                    n.read = true;
                    return true;
                }
                else {
                    return false;
                }
            });
            if (unreadIds.length > 0) {
                return this.$http.post('/api/v1/notifications/acknowledge', { notifications: unreadIds });
            }
            else {
                return Promise.resolve();
            }
        };
        NotificationsService.$inject = ['PubSubService', '$http'];
        return NotificationsService;
    }());
    consolo.NotificationsService = NotificationsService;
    angular.module('consolo').service('NotificationsService', NotificationsService);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var navbarBottom;
    (function (navbarBottom) {
        var PdfPrinter = /** @class */ (function () {
            function PdfPrinter($document, $timeout, $window) {
                this.$document = $document;
                this.$timeout = $timeout;
                this.$window = $window;
            }
            PdfPrinter.prototype.print = function (pdfUrl) {
                var printer = new Printer(this.$document, this.$timeout, this.$window, pdfUrl);
                printer.print();
            };
            PdfPrinter.$inject = ['$document', '$timeout', '$window'];
            return PdfPrinter;
        }());
        navbarBottom.PdfPrinter = PdfPrinter;
        /*
         * A crazy system to print PDFs:
         *
         * 1. Download the PDF as a Blob (likely from S3)
         * 2. Create an ObjectURL from the Blob
         * 3. Open that ObjectURL in a hidden iframe
         * 4. call print() on the iframe
         * 5. When Firefox invariably fails in step 4, just open the ObjectURL in a new tab and let them click Print manually
         *
         * You might think "Just put the S3 url in an iframe and call print!". We tried that, but you can't access an iframe
         * from a different domain, even with CORS enabled. So we must download it locally first, then open it in an iframe from the
         * local host.
         */
        var Printer = /** @class */ (function () {
            function Printer($document, $timeout, $window, pdfUrl) {
                this.$document = $document;
                this.$timeout = $timeout;
                this.$window = $window;
                this.pdfUrl = pdfUrl;
                this.frameName = "print-frame-" + new Date().getTime() + "-" + (Math.random() * 1000).toFixed(0);
            }
            Printer.prototype.print = function () {
                var _this = this;
                var xhr = new XMLHttpRequest();
                xhr.open('GET', this.pdfUrl, true);
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    var blob = xhr.response;
                    var localBlobUrl = URL.createObjectURL(blob);
                    var iframeEl = _this.createIframe(localBlobUrl);
                    _this.$timeout(function () {
                        URL.revokeObjectURL(localBlobUrl);
                    }, 60 * 1000);
                    iframeEl.addEventListener('load', function () {
                        _this.tryPrint(iframeEl);
                    });
                };
                xhr.onerror = function () {
                    // Safari craps out on the download; probably some bug in CORS
                    _this.$window.open(_this.pdfUrl, _this.frameName);
                };
                xhr.send();
            };
            Printer.prototype.tryPrint = function (iframeEl) {
                var iframe = window.frames[this.frameName];
                iframe.focus();
                try {
                    iframe.print();
                    this.$timeout(function () {
                        iframeEl.remove();
                    }, 60 * 1000);
                }
                catch (e) {
                    // catch Firefox iframe print bug - https://bugzilla.mozilla.org/show_bug.cgi?id=911444
                    iframeEl.remove();
                    this.$window.open(iframeEl.getAttribute('src'), this.frameName);
                }
            };
            Printer.prototype.createIframe = function (url) {
                this.$document
                    .find('body')
                    .append("<iframe style=\"display:none\" src=\"" + url + "\" id=\"" + this.frameName + "\" name=\"" + this.frameName + "\"></iframe>");
                return document.getElementById(this.frameName);
            };
            return Printer;
        }());
    })(navbarBottom = consolo.navbarBottom || (consolo.navbarBottom = {}));
})(consolo || (consolo = {}));
angular.module('consolo').service('PdfPrinter', consolo.navbarBottom.PdfPrinter);

(function () {
    'use strict';
    angular.module('consolo').directive('consoloSidebarBottom', Directive);
    function Directive() {
        return {
            restrict: 'E',
            templateUrl: '/y/templates/navbar-bottom/sidebar-bottom.html',
            controller: [
                '$state',
                '$http',
                '$rootScope',
                '$mdDialog',
                'SessionService',
                'NetworkManager',
                'persistenceStore',
                'persistedItemConfig',
                'DeviceSettingsService',
                'DraftSyncService',
                '$interval',
                'Store',
                Controller,
            ],
            controllerAs: 'sidebarBottomVm',
        };
    }
    function Controller($state, $http, $root, $mdDialog, Session, NetworkManager, persistenceStore, persistedItemConfig, DeviceSettings, DraftSyncService, $interval, Store) {
        var vm = this;
        vm.draftCount = 0;
        vm.session = null;
        vm.wentOffline = false;
        vm.syncMode = false;
        vm.syncText = 'Off';
        vm.errorCount = 0;
        vm.store = Store.sync();
        vm.getErrorCount = getErrorCount;
        vm.activate = activate;
        vm.getSyncMode = getSyncMode;
        activate();
        Session.subscribe(function (session) {
            vm.session = session;
            if (session) {
                getDraftCounts();
            }
        });
        NetworkManager.onlineHandler.onEvent('consoloSidebarBottom', getOfflineStatus);
        NetworkManager.offlineHandler.onEvent('consoloSidebarBottom', getOfflineStatus);
        function getOfflineStatus() {
            vm.wentOffline = NetworkManager.wentOffline;
        }
        function activate() {
            try {
                vm.draftInfo = vm.store.getItem('enterprise:draft-info');
                if (!vm.draftInfo) {
                    vm.draftInfo = { draftCount: 0, errorCount: 0, syncText: 'Unknown' };
                    vm.store.setItem('enterprise:draft-info', vm.draftInfo);
                }
            }
            catch (e) {
                vm.draftInfo = { draftCount: 0, errorCount: 0, syncText: 'Unknown' };
                vm.store.setItem('enterprise:draft-info', vm.draftInfo);
            }
            vm.errorCount = vm.getErrorCount();
            vm.getSyncMode();
            $interval(function () {
                vm.errorCount = vm.getErrorCount();
                vm.getSyncMode();
            }, 2000);
        }
        function getDraftCounts() {
            //needs to run both on activate & when session starts
            return persistenceStore.setCounts().then(function () {
                vm.draftCount = persistenceStore.getCounts();
                updateStore(vm.draftCount, null, null);
            });
        }
        function getSyncMode() {
            vm.syncMode = DeviceSettings.getValue(DeviceSettings.pages.global, DeviceSettings.settings.charting_sync_mode);
            vm.syncText = vm.syncMode ? 'Auto' : 'Off';
            updateStore(null, vm.syncText, null);
        }
        function updateStore(draftCount, syncText, errorCount) {
            vm.draftInfo.draftCount = draftCount || vm.draftInfo.draftCount;
            vm.draftInfo.syncText = syncText || vm.draftInfo.syncText;
            if (errorCount === 0) {
                vm.draftInfo.errorCount = 0;
            }
            if (errorCount !== null && errorCount !== undefined && errorCount !== 0) {
                vm.draftInfo.errorCount = errorCount || vm.draftInfo.errorCount;
            }
            vm.store.setItem('enterprise:draft-info', vm.draftInfo);
        }
        function getErrorCount() {
            updateStore(null, null, DraftSyncService.draftErrorCount.clinical_chart_entry);
            return DraftSyncService.draftErrorCount.clinical_chart_entry;
        }
    }
})();

var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var BaseDocumentsController = /** @class */ (function () {
            function BaseDocumentsController($timeout, uploadQueue, S3UploadFactory) {
                this.$timeout = $timeout;
                this.uploadQueue = uploadQueue;
                this.S3UploadFactory = S3UploadFactory;
                this.filterPrepends = [];
                this.uploads = [];
            }
            BaseDocumentsController.prototype.handleFiles = function (files) {
                var _this = this;
                files.forEach(function (file) {
                    // TODO throw warning if file too large?
                    var upload = _this.S3UploadFactory.build(file);
                    _this.uploadQueue.push(upload);
                    _this.uploads.push(upload);
                    upload.promise.catch(function (e) {
                        _this.removeUpload(upload, 5000);
                    });
                });
            };
            BaseDocumentsController.prototype.afterCancel = function (upload) {
                this.removeUpload(upload, 500);
            };
            BaseDocumentsController.prototype.afterSave = function (upload, doc) {
                this.removeUpload(upload, 100);
                this.filterPrepends.unshift(doc);
            };
            BaseDocumentsController.prototype.removeUpload = function (upload, time) {
                var _this = this;
                this.$timeout(function () {
                    var idx = _this.uploads.indexOf(upload);
                    if (idx > -1) {
                        _this.uploads.splice(idx, 1);
                    }
                }, time);
            };
            BaseDocumentsController.$inject = ['$timeout', 'UploadQueue', 'S3UploadFactory'];
            return BaseDocumentsController;
        }());
        officeDocs.BaseDocumentsController = BaseDocumentsController;
        angular
            .module('consolo')
            .controller('BaseDocumentsController', BaseDocumentsController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var BereavementDocumentsController = /** @class */ (function (_super) {
            __extends(BereavementDocumentsController, _super);
            function BereavementDocumentsController() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.apiRoot = '/api/v1/bereavement_documents';
                _this.editSref = 'office-docs.bereavement.edit';
                _this.title = 'Bereavement Documents';
                return _this;
            }
            return BereavementDocumentsController;
        }(officeDocs.BaseDocumentsController));
        officeDocs.BereavementDocumentsController = BereavementDocumentsController;
        angular
            .module('consolo')
            .controller('BereavementDocumentsController', BereavementDocumentsController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var ClaimPaymentDocumentsController = /** @class */ (function (_super) {
            __extends(ClaimPaymentDocumentsController, _super);
            function ClaimPaymentDocumentsController() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.apiRoot = '/api/v1/claim_payment_documents';
                _this.editSref = 'office-docs.claim-payment.edit';
                _this.title = 'Claim Payment Documents';
                return _this;
            }
            return ClaimPaymentDocumentsController;
        }(officeDocs.BaseDocumentsController));
        officeDocs.ClaimPaymentDocumentsController = ClaimPaymentDocumentsController;
        angular
            .module('consolo')
            .controller('ClaimPaymentDocumentsController', ClaimPaymentDocumentsController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var BaseEditController = /** @class */ (function () {
            function BaseEditController($http, $filter, $state, logger, options, $params) {
                var _this = this;
                this.$http = $http;
                this.$filter = $filter;
                this.$state = $state;
                this.logger = logger;
                this.options = options;
                this.$params = $params;
                this.$http.get(this.apiRoot() + "/" + this.$params.documentId).then(function (resp) {
                    _this.doc = resp.data;
                    var dateChunks = _this.doc.document_date.split('-').map(function (str) {
                        return parseInt(str, 10);
                    });
                    _this.documentDate = new Date(dateChunks[0], dateChunks[1] - 1, dateChunks[2]);
                });
                if (this.hasTags()) {
                    this.tags = this.options.get('office_document_types');
                }
            }
            BaseEditController.prototype.save = function () {
                var _this = this;
                if (this.documentDate) {
                    this.doc.document_date = this.$filter('date')(this.documentDate, 'yyyy-MM-dd');
                }
                this.$http.patch(this.apiRoot() + "/" + this.doc.id, this.doc).then(function (resp) {
                    _this.logger.success('Document saved');
                    _this.goBack();
                }, function (resp) {
                    _this.errors = (resp.data ? resp.data['errors'] : null) || [
                        "Unknown error (status " + resp.status + ")",
                    ];
                });
            };
            BaseEditController.prototype.apiRoot = function () {
                return '';
            };
            BaseEditController.prototype.cancel = function () {
                this.goBack();
            };
            BaseEditController.prototype.hasTags = function () {
                return false;
            };
            BaseEditController.prototype.goBack = function () { };
            BaseEditController.prototype.setOfficeIds = function (ids) {
                this.doc['office_ids'] = ids;
            };
            BaseEditController.$inject = [
                '$http',
                '$filter',
                '$state',
                'logger',
                'options',
                '$stateParams',
            ];
            return BaseEditController;
        }());
        officeDocs.BaseEditController = BaseEditController;
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var EditBereavementDocumentController = /** @class */ (function (_super) {
            __extends(EditBereavementDocumentController, _super);
            function EditBereavementDocumentController() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            EditBereavementDocumentController.prototype.apiRoot = function () {
                return '/api/v1/bereavement_documents';
            };
            EditBereavementDocumentController.prototype.goBack = function () {
                this.$state.go('office-docs.bereavement');
            };
            return EditBereavementDocumentController;
        }(officeDocs.BaseEditController));
        officeDocs.EditBereavementDocumentController = EditBereavementDocumentController;
        angular
            .module('consolo')
            .controller('EditBereavementDocumentController', EditBereavementDocumentController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var EditClaimPaymentDocumentController = /** @class */ (function (_super) {
            __extends(EditClaimPaymentDocumentController, _super);
            function EditClaimPaymentDocumentController() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            EditClaimPaymentDocumentController.prototype.apiRoot = function () {
                return '/api/v1/claim_payment_documents';
            };
            EditClaimPaymentDocumentController.prototype.goBack = function () {
                this.$state.go('office-docs.claim-payment');
            };
            return EditClaimPaymentDocumentController;
        }(officeDocs.BaseEditController));
        officeDocs.EditClaimPaymentDocumentController = EditClaimPaymentDocumentController;
        angular
            .module('consolo')
            .controller('EditClaimPaymentDocumentController', EditClaimPaymentDocumentController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var EditHRDocumentController = /** @class */ (function (_super) {
            __extends(EditHRDocumentController, _super);
            function EditHRDocumentController() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            EditHRDocumentController.prototype.apiRoot = function () {
                return '/api/v1/hr_documents';
            };
            EditHRDocumentController.prototype.goBack = function () {
                this.$state.go('office-docs.hr');
            };
            return EditHRDocumentController;
        }(officeDocs.BaseEditController));
        officeDocs.EditHRDocumentController = EditHRDocumentController;
        angular.module('consolo').controller('EditHRDocumentController', EditHRDocumentController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var EditOfficeDocumentController = /** @class */ (function (_super) {
            __extends(EditOfficeDocumentController, _super);
            function EditOfficeDocumentController() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            EditOfficeDocumentController.prototype.apiRoot = function () {
                return '/api/v1/office_documents';
            };
            EditOfficeDocumentController.prototype.goBack = function () {
                this.$state.go('office-docs.office');
            };
            EditOfficeDocumentController.prototype.hasTags = function () {
                return true;
            };
            return EditOfficeDocumentController;
        }(officeDocs.BaseEditController));
        officeDocs.EditOfficeDocumentController = EditOfficeDocumentController;
        angular
            .module('consolo')
            .controller('EditOfficeDocumentController', EditOfficeDocumentController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var EditPHIDocumentController = /** @class */ (function (_super) {
            __extends(EditPHIDocumentController, _super);
            function EditPHIDocumentController() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            EditPHIDocumentController.prototype.apiRoot = function () {
                return '/api/v1/phi_documents';
            };
            EditPHIDocumentController.prototype.goBack = function () {
                this.$state.go('office-docs.phi');
            };
            return EditPHIDocumentController;
        }(officeDocs.BaseEditController));
        officeDocs.EditPHIDocumentController = EditPHIDocumentController;
        angular.module('consolo').controller('EditPHIDocumentController', EditPHIDocumentController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        angular.module('consolo').component('officeDocumentsFilter', {
            bindings: { apiRoot: '@', editSref: '@', prepends: '<?', pageSize: '<?', hasTags: '@' },
            templateUrl: '/y/templates/office-docs/filter.html',
            controller: 'OfficeDocumentsFilterController as vm',
        });
        var OfficeDocumentsFilterController = /** @class */ (function () {
            function OfficeDocumentsFilterController($http, $httpParamSerializer, $filter, $mdDialog, options, logger, tokenService) {
                var _this = this;
                this.$http = $http;
                this.$httpParamSerializer = $httpParamSerializer;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.options = options;
                this.logger = logger;
                this.tokenService = tokenService;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
                this.loading = false;
                this.filter = {};
                this.tokenService.getToken().then(function (tok) {
                    _this.token = tok;
                });
            }
            OfficeDocumentsFilterController.prototype.$onInit = function () {
                this.loadPage(1);
                if (this.hasTags) {
                    this.tags = this.options.get('office_document_types');
                }
            };
            OfficeDocumentsFilterController.prototype.allDocs = function () {
                this.prepends.forEach(function (doc) {
                    doc['justUploaded'] = true;
                });
                return this.prepends.concat(this.currentPage.items);
            };
            OfficeDocumentsFilterController.prototype.delete = function (doc) {
                var _this = this;
                var dialog = this.$mdDialog
                    .confirm()
                    .title('Delete document?')
                    .textContent("Are you sure you want to delete '" + doc.name + "'?")
                    .ok('Yes')
                    .cancel('No');
                this.$mdDialog.show(dialog).then(function () {
                    _this.$http.delete(_this.apiRoot + "/" + doc.id).then(function () {
                        _this.logger.success('Document deleted');
                        if (_this.currentPage.items.indexOf(doc) > -1) {
                            _this.currentPage.items.splice(_this.currentPage.items.indexOf(doc), 1);
                        }
                        else if (_this.prepends.indexOf(doc) > -1) {
                            _this.prepends.splice(_this.prepends.indexOf(doc), 1);
                        }
                    }, function (resp) {
                        _this.logger.error(resp.data.errors.join('; '));
                    });
                });
            };
            OfficeDocumentsFilterController.prototype.setFilterOfficeIds = function (ids) {
                this.filter['office_ids'] = ids;
                this.loadPage(1);
            };
            OfficeDocumentsFilterController.prototype.sort = function (col, dir) {
                this.filter['sort_by'] = col;
                this.filter['sort_dir'] = dir;
                this.loadPage(this.currentPage.page);
            };
            OfficeDocumentsFilterController.prototype.setLink = function (doc) {
                var _this = this;
                return this.tokenService.getToken().then(function (tok) {
                    return _this.newLink(doc, tok);
                });
            };
            OfficeDocumentsFilterController.prototype.getFile = function (doc) {
                var _this = this;
                this.tokenService.getToken().then(function (tok) {
                    window.location.href = _this.newLink(doc, tok);
                });
            };
            OfficeDocumentsFilterController.prototype.newLink = function (doc, token) {
                return this.apiRoot + "/" + doc.id + "/download?access_token=" + token;
            };
            OfficeDocumentsFilterController.prototype.loadPage = function (n) {
                var _this = this;
                this.loading = true;
                var query = this.$httpParamSerializer(this.filter);
                this.$http.get(this.apiRoot + "?page_size=" + this.pageSize + "&page=" + n + "&" + query).then(function (resp) {
                    _this.currentPage = {
                        page: parseInt(resp.headers('X-Page'), 10),
                        totalPages: parseInt(resp.headers('X-Total-Pages'), 10),
                        items: resp.data,
                    };
                    if (_this.prepends) {
                        _this.prepends.splice(0, _this.prepends.length);
                    }
                    _this.loading = false;
                }, function (resp) {
                    _this.loading = false;
                });
            };
            OfficeDocumentsFilterController.$inject = [
                '$http',
                '$httpParamSerializer',
                '$filter',
                '$mdDialog',
                'options',
                'logger',
                'AccessTokenService',
            ];
            return OfficeDocumentsFilterController;
        }());
        officeDocs.OfficeDocumentsFilterController = OfficeDocumentsFilterController;
        angular
            .module('consolo')
            .controller('OfficeDocumentsFilterController', OfficeDocumentsFilterController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var HRDocumentsController = /** @class */ (function (_super) {
            __extends(HRDocumentsController, _super);
            function HRDocumentsController() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.apiRoot = '/api/v1/hr_documents';
                _this.editSref = 'office-docs.hr.edit';
                _this.title = 'Human Resources Documents';
                return _this;
            }
            return HRDocumentsController;
        }(officeDocs.BaseDocumentsController));
        officeDocs.HRDocumentsController = HRDocumentsController;
        angular.module('consolo').controller('HRDocumentsController', HRDocumentsController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var OfficeDocumentsController = /** @class */ (function (_super) {
            __extends(OfficeDocumentsController, _super);
            function OfficeDocumentsController() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.apiRoot = '/api/v1/office_documents';
                _this.editSref = 'office-docs.office.edit';
                _this.hasTags = true;
                _this.title = 'Office Documents';
                return _this;
            }
            return OfficeDocumentsController;
        }(officeDocs.BaseDocumentsController));
        officeDocs.OfficeDocumentsController = OfficeDocumentsController;
        angular
            .module('consolo')
            .controller('OfficeDocumentsController', OfficeDocumentsController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var OfficeDocRootStates = /** @class */ (function () {
            function OfficeDocRootStates($stateProvider) {
                $stateProvider
                    .state('office-docs', {
                    abstract: true,
                    url: '/office-documents',
                    views: {
                        'sidebar@': {
                            templateUrl: '/y/templates/office-docs/sidebar.html',
                        },
                    },
                })
                    .state('office-docs.office', {
                    url: '',
                    data: { title: 'Office Documents' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/docs.html',
                            controller: 'OfficeDocumentsController as vm',
                        },
                    },
                })
                    .state('office-docs.office.edit', {
                    url: '/:documentId/edit',
                    data: { title: 'Edit Document' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/edit.html',
                            controller: 'EditOfficeDocumentController as vm',
                        },
                    },
                })
                    .state('office-docs.claim-payment', {
                    url: '/claim-payments',
                    data: { title: 'Claim Payment Documents' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/docs.html',
                            controller: 'ClaimPaymentDocumentsController as vm',
                        },
                    },
                })
                    .state('office-docs.claim-payment.edit', {
                    url: '/:documentId/edit',
                    data: { title: 'Edit Document' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/edit.html',
                            controller: 'EditClaimPaymentDocumentController as vm',
                        },
                    },
                })
                    .state('office-docs.hr', {
                    url: '/human-resources',
                    data: { title: 'Human Resources Documents' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/docs.html',
                            controller: 'HRDocumentsController as vm',
                        },
                    },
                })
                    .state('office-docs.hr.edit', {
                    url: '/:documentId/edit',
                    data: { title: 'Edit Document' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/edit.html',
                            controller: 'EditHRDocumentController as vm',
                        },
                    },
                })
                    .state('office-docs.phi', {
                    url: '/phi',
                    data: { title: 'PHI Documents' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/docs.html',
                            controller: 'PHIDocumentsController as vm',
                        },
                    },
                })
                    .state('office-docs.phi.edit', {
                    url: '/:documentId/edit',
                    data: { title: 'Edit Document' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/edit.html',
                            controller: 'EditPHIDocumentController as vm',
                        },
                    },
                })
                    .state('office-docs.bereavement', {
                    url: '/bereavement',
                    data: { title: 'Bereavement Documents' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/docs.html',
                            controller: 'BereavementDocumentsController as vm',
                        },
                    },
                })
                    .state('office-docs.bereavement.edit', {
                    url: '/:documentId/edit',
                    data: { title: 'Edit Document' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/office-docs/edit.html',
                            controller: 'EditBereavementDocumentController as vm',
                        },
                    },
                })
                    .state('office-docs.direct-secure-messages', {
                    url: '/direct-secure-messages',
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/messaging/incoming-messages.html',
                            controller: 'IncomingMessagesController as vm'
                        }
                    },
                    resolve: {
                        fileTypes: function () { return false; },
                        selectedMessage: function () { return false; },
                        receiveDate: function () { return false; }
                    }
                });
            }
            OfficeDocRootStates.$inject = ['$stateProvider'];
            return OfficeDocRootStates;
        }());
        angular.module('consolo').config(OfficeDocRootStates);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        var PHIDocumentsController = /** @class */ (function (_super) {
            __extends(PHIDocumentsController, _super);
            function PHIDocumentsController() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.apiRoot = '/api/v1/phi_documents';
                _this.editSref = 'office-docs.phi.edit';
                _this.title = 'PHI Documents';
                return _this;
            }
            return PHIDocumentsController;
        }(officeDocs.BaseDocumentsController));
        officeDocs.PHIDocumentsController = PHIDocumentsController;
        angular
            .module('consolo')
            .controller('PHIDocumentsController', PHIDocumentsController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var officeDocs;
    (function (officeDocs) {
        angular.module('consolo').component('officeDocumentUpload', {
            bindings: { upload: '<', afterCancel: '&', afterSave: '&', api: '@', hasTags: '@' },
            templateUrl: '/y/templates/office-docs/upload.html',
            controller: 'OfficeDocumentUploadController as vm',
        });
        var OfficeDocumentUploadController = /** @class */ (function () {
            function OfficeDocumentUploadController($filter, $http, options, logger) {
                this.$filter = $filter;
                this.$http = $http;
                this.options = options;
                this.logger = logger;
                this.documentDate = new Date();
                this.saving = false;
            }
            OfficeDocumentUploadController.prototype.$onInit = function () {
                this.model = {
                    name: this.upload.name.replace(/\.\w+$/, ''),
                    office_ids: [],
                    tags: [],
                };
                if (this.hasTags) {
                    this.tags = this.options.get('office_document_types');
                }
            };
            OfficeDocumentUploadController.prototype.setOfficeIds = function (officeIds) {
                this.model['office_ids'] = officeIds;
            };
            OfficeDocumentUploadController.prototype.save = function () {
                var _this = this;
                if (this.saving || this.model['id']) {
                    return;
                }
                this.saving = true;
                this.upload.promise.then(function () {
                    _this.model.s3_tmp_key = _this.upload.s3Key;
                    if (_this.documentDate) {
                        _this.model['document_date'] = _this.$filter('date')(_this.documentDate, 'yyyy-MM-dd');
                    }
                    _this.$http.post(_this.api, _this.model).then(function (resp) {
                        _this.model['id'] = resp.data['id'];
                        _this.saving = false;
                        _this.afterSave({ upload: _this.upload, doc: resp.data });
                    }, function (resp) {
                        _this.saving = false;
                        _this.logger.error(resp.data.errors.join(', '));
                    });
                });
            };
            OfficeDocumentUploadController.prototype.cancel = function () {
                this.saving = false;
                this.upload.cancel();
                this.afterCancel(this.upload);
            };
            OfficeDocumentUploadController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            OfficeDocumentUploadController.$inject = ['$filter', '$http', 'options', 'logger'];
            return OfficeDocumentUploadController;
        }());
        officeDocs.OfficeDocumentUploadController = OfficeDocumentUploadController;
        angular
            .module('consolo')
            .controller('OfficeDocumentUploadController', OfficeDocumentUploadController);
    })(officeDocs = consolo.officeDocs || (consolo.officeDocs = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('appCache', ['$timeout', Directive]);
    /*
     * Loads a application cache manifest in an iframe. Note that the parent window will *not* use the cache.
     * But an subsequently opened tabs/windows will.
     */
    function Directive($timeout) {
        return {
            restrict: 'E',
            scope: { manifest: '@', ready: '&' },
            templateUrl: '/y/templates/offline/app-cache.html',
            link: Link,
            controller: ['$scope', Controller],
        };
        /* jshint browser: true */
        function Link(scope) {
            scope.appLoaded = false;
            scope.percent = 0;
            // The iframe's window.applicationCache will hook into these handlers
            // @ts-ignore
            window.applicationCacheHandler = {
                cached: ready,
                error: error,
                noupdate: ready,
                progress: progress,
            };
            scope.$on('$destroy', function () {
                // @ts-ignore
                delete window.applicationCacheHandler;
            });
            function error() {
                alert('There was an error while downloading files for offline use.');
            }
            function progress(e) {
                if (e.lengthComputable) {
                    $timeout(function () {
                        scope.percent = ((e.loaded / e.total) * 100).toFixed(0);
                    });
                }
            }
            function ready() {
                $timeout(function () {
                    scope.appLoaded = true;
                    scope.percent = 100;
                    if (scope.ready) {
                        scope.ready();
                    }
                });
            }
        }
        function Controller($scope) {
            $scope.iframeSource = '/api/v1/offline/manifest.html?manifest=' + $scope.manifest;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('cacheSweeper', ['$q', 'promiseTimer', 'NetworkManager', 'Store', Factory]);
    function Factory($q, log, NetworkManager, Store) {
        return {
            initialize: initialize,
        };
        function initialize() {
            var store = Store.local('app');
            return store.getItem('lastCacheSweep').then(function (lastSweep) {
                var needsSweep = !lastSweep || Date.now() - lastSweep > 1 * 24 * 60 * 60 * 1000;
                if (needsSweep && NetworkManager.isOnline()) {
                    store.setItem('lastCacheSweep', Date.now());
                    return log('Swept cache', sweep)();
                }
            });
        }
        function sweep() {
            var store = Store.local('cache');
            var now = Date.now(), maxCacheLife = 48 * 60 * 60 * 1000, deletions = [];
            return store
                .iterate(function (item, key) {
                if (now - item.timestamp > maxCacheLife) {
                    deletions.push(store.removeItem(key));
                }
            })
                .then(function () {
                return $q.all(deletions);
            })
                .catch(function (error) {
                console.warn('Error sweeping cache');
                console.warn(error);
                throw error;
            });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('cacheWarmer', [
        '$q',
        'SessionService',
        'OfflineSessionService',
        'PermissionsService',
        'options',
        'ChartingPrefetch',
        'promiseTimer',
        'cache',
        'Lookup',
        'Patient',
        Factory,
    ]);
    function Factory($q, Session, offlineSession, Permissions, options, ChartingPrefetch, log, cache, Lookup, Patient) {
        return {
            extensionName: 'CacheWarmer',
            onSessionReady: onSessionReady,
            loadCache: loadCache,
        };
        function onSessionReady(session) {
            return [
                'async',
                loadCache().then(function () {
                    console.log('Warmed cache');
                }),
            ];
        }
        function loadCache() {
            return offlineSession
                .set(Session.get())
                .catch(noop)
                .then(function () {
                return options.warmCache();
            })
                .catch(noop)
                .then(prefetchCharting)
                .catch(noop);
        }
        // Precache clinical charting data *if* they have charting permissions
        function prefetchCharting() {
            if (Permissions.can('create', 'clinical_charts')) {
                return ChartingPrefetch.initialize();
            }
            else {
                fetchPatients(); // is this right?? it's what it was doing before the refactor...
                return $q.when(true);
            }
        }
        function fetchPatients() {
            cache(Lookup, 'lookup', { encrypt: true })
                .patients({ limit: -1, active: true, deceased: true, pending_never_admitted: true })
                .$promise.catch(noopArray);
            cache(Patient, 'patients', { encrypt: true, force: true });
        }
        function noop() { }
        function noopArray() {
            return [];
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('cache', [
        '$q',
        'SessionService',
        'NetworkManager',
        'Store',
        'crypto',
        'deferredResult',
        Factory,
    ]);
    function Factory($q, Session, NetworkManager, Store, crypto, deferredResult) {
        return resourceCacheWrapper;
        function defaultOptions() {
            return { encrypt: false, maxAge: 60, force: false, expireOffline: false };
        }
        /*
         * Wrap a $resource Factory in a cache handler. If the query has been cached recently (within maxAge), the
         * data will be returned from the cache. Otherwise, it will be queried and cached for next time. NOTE If the
         * cache is expired BUT we're offline, the cache will still be returned (old data is better than no data).
         *
         * Example: cache(ClinicalChart, 'clinical_charts', options)
         *
         * options:
         *   encrypt (boolean) Encrypt the data before writing to cache (default false)
         *   maxAge (integer) Maximum allowed age of cached data in minutes (default 60)
         *   force (boolean) Force query to be cached again (same as maxAge=0) (default false)
         *   expireOffline (boolean) Expire data even when offline (default false)
         *   readOnly (boolean) Only check the cache; don't cache the results
         */
        function resourceCacheWrapper(resource, resourceName, options) {
            return new Cache(resource, resourceName, options);
        }
        function Cache(resource, resourceName, options) {
            var cache = this;
            cache.options = angular.extend(defaultOptions(), options || {});
            cache.resourceName = resourceName;
            cache.store = Store.local('cache');
            // Fetch an item. If it's not found, it *won't* try to query it.
            cache.fetch = fetch;
            cache.fetchTimestamp = fetchTimestamp;
            // Pre-set an item in the cache
            cache.set = set;
            cache.setBatch = setBatch;
            // Remove an item from the cache
            cache.removeItem = removeItem;
            // Copy all queries from resource to cache-wrapper versions (e.g. query, get)
            for (var attr in resource) {
                if (typeof resource[attr] === 'function') {
                    cache[attr] = cachableQuery(attr, resource[attr]);
                }
            }
            function buildKey(queryName, params) {
                var sess = Session.get();
                if (!sess) {
                    return $q.reject(new Error('No session'));
                }
                if (typeof params === 'object') {
                    params = JSON.stringify(params);
                }
                var key = [
                    sess.agency.code,
                    sess.user.id,
                    cache.resourceName + '.' + queryName,
                    params,
                ].join(':');
                return $q.when(key);
            }
            function cachableQuery(queryName, oldQuery) {
                return function () {
                    var params = arguments[0] || {};
                    var keylessParams = {};
                    if (arguments.length > 1) {
                        keylessParams = arguments[1];
                    }
                    var promise = buildKey(queryName, params).then(function (key) {
                        return fetchOrQuery(key, function query() {
                            return oldQuery(params);
                        }, queryName, angular.extend(params, keylessParams));
                    });
                    return deferredResult(promise);
                };
            }
            function fetch(queryName, params, ignoreExpired) {
                return buildKey(queryName, params)
                    .then(function (key) {
                    return cache.store.getItem(key);
                })
                    .then(function (item) {
                    if (item && (!!ignoreExpired || !isExpired(item))) {
                        return item.data.encryptedKey ? crypto.decrypt(item.data) : item.data;
                    }
                }, function (e) {
                    console.warn('Offline storage might be disabled in this browser: ' + e.message);
                    return $q.reject(e);
                });
            }
            function fetchTimestamp(queryName, params) {
                return buildKey(queryName, params)
                    .then(function (key) {
                    return cache.store.getItem(key);
                })
                    .then(function (item) {
                    if (item && !isExpired(item)) {
                        return item.timestamp;
                    }
                    else {
                        return 0;
                    }
                });
            }
            function removeItem(queryName, params) {
                return buildKey(queryName, params).then(function (key) {
                    return cache.store.removeItem(key);
                });
            }
            function isExpired(item) {
                var expired = cache.options.force || Date.now() - item.timestamp > cache.options.maxAge * 60 * 1000;
                return expired && (NetworkManager.isOnline() || cache.options.expireOffline);
            }
            function fetchOrQuery(key, query, queryName, params) {
                return cache.store.getItem(key).then(function (item) {
                    if (item) {
                        if (isExpired(item)) {
                            return queryAndCache(key, query).catch(function () {
                                return item.data.encryptedKey ? crypto.decrypt(item.data) : item.data;
                            });
                        }
                        else {
                            return item.data.encryptedKey ? crypto.decrypt(item.data) : item.data;
                        }
                    }
                    else {
                        return fetchFromBatch(queryName, params).then(function (item) {
                            return item ? item : queryAndCache(key, query);
                        });
                    }
                }, function (e) {
                    console.warn('Offline storage might be disabled in this browser: ' + e.message);
                    return query().$promise;
                });
            }
            function fetchFromBatch(queryName, params) {
                return buildKey(queryName, 'batch').then(function (batchKey) {
                    return cache.store.getItem(batchKey).then(function (batch) {
                        if (!batch || isExpired(batch)) {
                            return null;
                        }
                        var item = batch ? batch.data[JSON.stringify(params)] : null;
                        if (item) {
                            return item.encryptedKey ? crypto.decrypt(item) : item.data;
                        }
                        else {
                            return null;
                        }
                    }, function (e) {
                        console.warn('Offline storage might be disabled in this browser: ' + e.message);
                        return $q.reject(e);
                    });
                });
            }
            // Runs the query and caches the result
            function queryAndCache(key, query) {
                if (NetworkManager.isOnline()) {
                    return query().$promise.then(function (data) {
                        return cache.options.readOnly ? data : setKey(key, data);
                    });
                }
                else {
                    return $q(function (resolve, reject) {
                        console.warn("Offline: Item '" + key + "' not found in cache");
                        reject("Offline: Item '" + key + "' not found in cache");
                    });
                }
            }
            /*
             * Pre-set a key in the cache. Example:
             *
             * var patientCache = cache(Patient, 'patients');
             * patientCache.set('get', {id: 42}, {id: 42, name: "Bob"});
             * var patient = patientCache.get({id: 42}); // "Bob" is returned from cache
             */
            function set(queryName, params, data) {
                return buildKey(queryName, params).then(function (key) {
                    return setKey(key, data);
                });
            }
            /*
             * Same as set, but sets an array of objects at once. It's ~225x faster.
             */
            function setBatch(queryName, paramsCallbackOrArray, objects) {
                if (cache.options.encrypt) {
                    return crypto.encryptBatch(objects).then(function (cryptedObjects) {
                        return _setBatch(queryName, paramsCallbackOrArray, cryptedObjects, objects);
                    });
                }
                else {
                    return _setBatch(queryName, paramsCallbackOrArray, objects, objects);
                }
            }
            // Saves an array of objects. They're all stored in 1 key instead of N keys, hence the speed improvement.
            function _setBatch(queryName, paramsCallbackOrArray, objects, plaintextObjs) {
                return buildKey(queryName, 'batch').then(function (key) {
                    var data = {};
                    plaintextObjs.forEach(function (obj, i) {
                        var key;
                        if (typeof paramsCallbackOrArray === 'function') {
                            key = JSON.stringify(paramsCallbackOrArray(obj));
                        }
                        else {
                            key = JSON.stringify(paramsCallbackOrArray[i]);
                        }
                        data[key] = objects[i];
                    });
                    return cache.store.setItem(key, { data: data, timestamp: Date.now() }).catch(function (e) {
                        console.warn('Unable to cache batch: ' + e.message);
                        return data;
                    });
                });
            }
            // Takes a key and data. Encrypts data if necessary, then caches it using the given key.
            function setKey(key, plaintextData) {
                var promise = cache.options.encrypt
                    ? crypto.encrypt(plaintextData)
                    : $q(function (resolve) {
                        resolve(plaintextData);
                    });
                return promise
                    .then(function (data) {
                    delete data.$promise;
                    delete data.$resolved;
                    return cache.store.setItem(key, { data: data, timestamp: Date.now() });
                })
                    .then(function () {
                    return plaintextData;
                }, function (e) {
                    console.warn('Unable to cache query for: ' + key + ' ' + e.message);
                    return plaintextData;
                });
            }
        }
    }
})();

/* global Worker */
(function () {
    'use strict';
    angular.module('consolo').factory('crypto', ['$q', 'EncryptionKeyPrefetch', Factory]);
    function Factory($q, EncryptionKeyPrefetch) {
        var _callbacks = {}, _failures = {}, _worker;
        return {
            encrypt: encrypt,
            encryptBatch: encryptBatch,
            decrypt: decrypt,
        };
        // Encrypts the data using AES with a random password, then encrypts the password with your RSA public key.
        // Resolves the promise with {encryptedKey: encryptedKey, data: encryptedData}. To decrypt, pass it to "decrypt."
        function encrypt(data) {
            return $q(function (resolve, reject) {
                return EncryptionKeyPrefetch.publicKey().then(function (key) {
                    try {
                        var uid = newUid();
                        _callbacks[uid] = resolve;
                        _failures[uid] = reject;
                        worker().postMessage({
                            cmd: 'encrypt',
                            callback_id: uid,
                            contents: JSON.stringify(data),
                            key: key,
                        });
                    }
                    catch (e) {
                        reject(e);
                    }
                }, reject);
            });
        }
        // Same as encrypt, but accepts an array of data. Resolves the promsie with an array of encrypted objects (like above).
        // Use case is encrypting for large numbers of objects (100s - 1000s) - it's faster to encrypt them in one go.
        function encryptBatch(objects) {
            return $q(function (resolve, reject) {
                return EncryptionKeyPrefetch.publicKey().then(function (key) {
                    try {
                        var uid = newUid();
                        _callbacks[uid] = resolve;
                        _failures[uid] = reject;
                        var stringObjects = objects.map(function (obj) {
                            return JSON.stringify(obj);
                        });
                        worker().postMessage({
                            cmd: 'encryptBatch',
                            callback_id: uid,
                            batch: stringObjects,
                            key: key,
                        });
                    }
                    catch (e) {
                        reject(e);
                    }
                }, reject);
            });
        }
        // Decrypts the object, then resolves the promise with the decrypted data
        function decrypt(encryptedObject) {
            return $q(function (resolve, reject) {
                if (encryptedObject.encryptedKey) {
                    return EncryptionKeyPrefetch.privateKey().then(function (key) {
                        try {
                            var uid = newUid();
                            _callbacks[uid] = resolve;
                            _failures[uid] = reject;
                            worker().postMessage({
                                cmd: 'decrypt',
                                callback_id: uid,
                                contents: encryptedObject.data,
                                encrypted_key: encryptedObject.encryptedKey,
                                key: key,
                            });
                        }
                        catch (e) {
                            reject(e);
                        }
                    }, reject);
                }
                else {
                    resolve(encryptedObject);
                }
            });
        }
        function newUid() {
            return Math.random()
                .toString(16)
                .substring(2);
        }
        function onMessage(e) {
            var callback;
            switch (e.data.cmd) {
                case 'encrypted':
                    callback = _callbacks[e.data.callback_id];
                    callback({ encryptedKey: e.data.encrypted_key, data: e.data.contents });
                    delete _callbacks[e.data.callback_id];
                    delete _failures[e.data.callback_id];
                    break;
                case 'encryptedBatch':
                    callback = _callbacks[e.data.callback_id];
                    callback(e.data.batch.map(function (data) {
                        return { encryptedKey: e.data.encrypted_key, data: data };
                    }));
                    delete _callbacks[e.data.callback_id];
                    delete _failures[e.data.callback_id];
                    break;
                case 'decrypted':
                    callback = _callbacks[e.data.callback_id];
                    callback(JSON.parse(e.data.contents));
                    delete _callbacks[e.data.callback_id];
                    delete _failures[e.data.callback_id];
                    break;
                case 'failed':
                    callback = _failures[e.data.callback_id];
                    callback(new Error(e.data.error));
                    delete _callbacks[e.data.callback_id];
                    delete _failures[e.data.callback_id];
                    break;
            }
        }
        function worker() {
            if (!_worker) {
                if (typeof Worker === 'undefined') {
                    throw 'Browser does not support WebWorkers';
                }
                _worker = new Worker('/y/assets/javascripts/standalone/encryption-worker.js');
                _worker.addEventListener('message', onMessage.bind(this));
            }
            return _worker;
        }
    }
})();

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        var DebugLogService = /** @class */ (function () {
            function DebugLogService(session, storeService) {
                var _this = this;
                this.session = session;
                this.storageKey = 'debug.log';
                this.maxLogLength = 500;
                this.buffer = [];
                this.tabId = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                this.store = storeService.local('system');
                setInterval(function () { return _this.flushLog(); }, 5 * 1000);
            }
            /**
             * Read out the current log. Newest entries at top.
             */
            DebugLogService.prototype.read = function () {
                return this.store.getItem(this.storageKey).then(function (data) {
                    var entries = data ? JSON.parse(data) : [];
                    return entries.map(function (e) {
                        return {
                            timestamp: e[1],
                            user: e[2],
                            logLevel: e[3],
                            message: e[4],
                            tabId: e[5],
                        };
                    });
                });
            };
            /**
             * Write a new info message to the log. It will be written to disk unencrypted, so it must NOT contain PHI!!!
             */
            DebugLogService.prototype.info = function (msg) {
                console.info(msg);
                this.log('info', msg);
            };
            /**
             * Write a new warning message to the log. It will be written to disk unencrypted, so it must NOT contain PHI!!!
             */
            DebugLogService.prototype.warn = function (msg, error) {
                if (error === void 0) { error = null; }
                console.warn(msg, error);
                this.log('warn', msg);
            };
            DebugLogService.prototype.log = function (level, msg) {
                var entry = this.newEntry(level, msg);
                this.buffer.unshift(entry);
            };
            DebugLogService.prototype.newEntry = function (level, msg) {
                var t = new Date();
                var ts = this.pad(t.getMonth() + 1) + "/" + this.pad(t.getDate()) + " " +
                    (this.pad(t.getHours()) + ":" + this.pad(t.getMinutes()) + ":" + this.pad(t.getSeconds()));
                var sess = this.session.get();
                var user = sess ? sess.user.email : null;
                return [t.getTime(), ts, user, level, msg, this.tabId];
            };
            // NOTE race conditions are possible and data from other tabs *could* be overwritten
            DebugLogService.prototype.flushLog = function () {
                var _this = this;
                if (this.buffer.length === 0) {
                    return;
                }
                this.store.getItem(this.storageKey).then(function (oldData) {
                    var oldEntries;
                    if (oldData) {
                        try {
                            oldEntries = JSON.parse(oldData);
                        }
                        catch (e) {
                            var readError = _this.newEntry('warn', "Unable to retrieve debug log prior to this (" + e.message + ")");
                            oldEntries = [readError];
                        }
                    }
                    else {
                        oldEntries = [];
                    }
                    var newEntries = _this.buffer.splice(0, _this.buffer.length);
                    var entries = newEntries
                        .concat(oldEntries)
                        .slice(0, _this.maxLogLength)
                        .sort(function (a, b) {
                        if (a[0] > b[0]) {
                            return -1;
                        }
                        else if (a[0] < b[0]) {
                            return 1;
                        }
                        else {
                            return 0;
                        }
                    });
                    var newData = JSON.stringify(entries);
                    _this.store.setItem(_this.storageKey, newData);
                });
            };
            // See? You don't need an npm package for this.
            DebugLogService.prototype.pad = function (n) {
                var nStr = n.toString();
                return n < 10 ? '0' + nStr : nStr;
            };
            DebugLogService.$inject = ['SessionService', 'Store'];
            return DebugLogService;
        }());
        offline.DebugLogService = DebugLogService;
        angular.module('consolo').service('DebugLogService', DebugLogService);
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        var EncryptionKeyPrefetch = /** @class */ (function () {
            function EncryptionKeyPrefetch($q, $http, $cookies, Store, networkManager, debugLog) {
                this.$q = $q;
                this.$http = $http;
                this.$cookies = $cookies;
                this.Store = Store;
                this.networkManager = networkManager;
                this.debugLog = debugLog;
                this.extensionName = 'EncryptionKeyPrefetch';
            }
            EncryptionKeyPrefetch.prototype.onSessionStart = function (sess) {
                var _this = this;
                if (this.networkManager.isOnline()) {
                    this.debugLog.info('Downloading encryption keys');
                    return [
                        'sync',
                        this.fetch().catch(function (e) {
                            _this.debugLog.warn('Encryption key prefetch failed!', e);
                        }),
                    ];
                }
                else {
                    this.debugLog.info('Skipping encryption key download because Consolo is OFFLINE (they should already be present)');
                }
            };
            EncryptionKeyPrefetch.prototype.onSessionClear = function () {
                this.debugLog.info('Clearing encryption keys');
                this.clear();
            };
            EncryptionKeyPrefetch.prototype.clear = function () {
                this.$cookies.remove('private_key', { path: '/y/' });
                this.Store.sync().removeItem('public_key');
            };
            EncryptionKeyPrefetch.prototype.fetch = function () {
                this.clear();
                return this.$q.all([this.publicKey.bind(this), this.privateKey.bind(this)]);
            };
            // Returns a promise that returns the private key from cache.
            // If it's not cached, it's fetched from the server and cached.
            EncryptionKeyPrefetch.prototype.privateKey = function () {
                var _this = this;
                var key = this.$cookies.get('private_key');
                if (key) {
                    return this.$q.when(JSON.parse(key));
                }
                else {
                    return this.$http.get('/api/v1/offline/private_key').then(function (resp) {
                        var key = resp.data;
                        _this.$cookies.put('private_key', JSON.stringify(key), { path: '/y/' });
                        return key;
                    });
                }
            };
            // Returns a promise that returns the public key from cache.
            // If it's not cached, it's fetched from the server and cached.
            EncryptionKeyPrefetch.prototype.publicKey = function () {
                var _this = this;
                var key = this.Store.sync().getItem('public_key');
                if (key) {
                    return this.$q.resolve(key);
                }
                else {
                    return this.$http.get('/api/v1/offline/public_key').then(function (resp) {
                        var key = resp.data;
                        _this.Store.sync().setItem('public_key', { e: key['e'], n: key['n'] });
                        return key;
                    });
                }
            };
            EncryptionKeyPrefetch.$inject = [
                '$q',
                '$http',
                '$cookies',
                'Store',
                'NetworkManager',
                'DebugLogService',
            ];
            return EncryptionKeyPrefetch;
        }());
        offline.EncryptionKeyPrefetch = EncryptionKeyPrefetch;
        angular.module('consolo').service('EncryptionKeyPrefetch', EncryptionKeyPrefetch);
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('OfflineStore', ['crypto', Factory]);
    function Factory(crypto) {
        /* jshint browser: true */
        function OfflineStore(namespace) {
            this.namespace = namespace;
            this.store = window.localStorage;
        }
        OfflineStore.prototype.enableEncryption = enableEncryption;
        OfflineStore.prototype.forEach = forEach;
        OfflineStore.prototype.get = get;
        OfflineStore.prototype.keys = keys;
        OfflineStore.prototype.remove = remove;
        OfflineStore.prototype.set = set;
        return OfflineStore;
        /* Enable encryption and decryption powered by a WebWorker */
        function enableEncryption() {
            this.encrypted = true;
        }
        /*
         * Loops through each key, passing each key and its contents to the callback.
         * NOTE Decryption can be expensive, so pass decrypt as false if you don't need the decrypted data.
         */
        function forEach(callback, decrypt) {
            var keys = this.keys();
            for (var i = 0; i < keys.length; i++) {
                this.get(keys[i], callback, decrypt);
            }
        }
        function genKey() {
            return Math.round(new Date().getTime() / 1000).toString();
        }
        /*
         * Get the contents of the key, then pass the key and contents to the callback.
         * NOTE Decryption can be expensive, so pass decrypt as false if you don't need the decrypted data.
         */
        function get(key, callback, decrypt) {
            decrypt = typeof decrypt === 'undefined' ? true : decrypt;
            var contents = JSON.parse(this.store.getItem(key));
            if (contents.encrypted && this.encrypted && decrypt) {
                crypto
                    .decrypt({ encryptedKey: contents.encrypted_key, data: contents.data })
                    .then(function (decryptedData) {
                    if (decryptedData) {
                        contents.data = decryptedData;
                        contents.encrypted = false;
                    }
                    callback(key, contents);
                });
            }
            else {
                callback(key, contents);
            }
        }
        /* Return an array of all keys */
        function keys() {
            var keyList = this.store.getItem(this.namespace);
            return keyList ? keyList.split(',') : [];
        }
        /* Remove a key from the store. */
        function remove(key) {
            var keys = this.keys(), i = keys.indexOf(key);
            if (i >= 0) {
                keys.splice(i, 1);
                this.store.setItem(this.namespace, keys);
            }
            this.store.removeItem(key);
        }
        /*
         * Pass in an object to store. The callback will be passed the storage key.
         * If you've enabled encryption, your object *must* have a "data" element which is what will be encrypted.
         */
        function set(contents, callback) {
            if (this.encrypted && !contents.encrypted) {
                crypto.encrypt(contents.data).then(function (result) {
                    contents.data = result.data;
                    contents.encrypted_key = result.encryptedKey;
                    contents.encrypted = true;
                    this.set(contents, callback);
                }.bind(this));
            }
            else {
                var keys = this.keys(), newKey = genKey();
                keys.push(newKey);
                this.store.setItem(this.namespace, keys);
                this.store.setItem(newKey, JSON.stringify(contents));
                if (callback) {
                    callback(newKey);
                }
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        var PersistedItemConfigService = /** @class */ (function () {
            function PersistedItemConfigService(CarePlanProblemEntry, ClinicalChartEntry, PhysicianOrderEntry) {
                var _this = this;
                this.supportedTypes = {
                    clinical_chart_entry: {
                        resource: ClinicalChartEntry,
                        encrypt: true,
                        offlineKey: function (item, user, agency) {
                            return item.id
                                ? _this.keyAddUserInfo('clinical_chart_entry:id:' + item.id, user, agency)
                                : _this.keyAddUserInfo('clinical_chart_entry:patient_id:' + item.patient_id, user, agency);
                        },
                        cleanDraft: function (model) {
                            // var model = item.model;
                            var additional_sections = [
                                'patient_diagnosis',
                                'chief_complaint',
                                'history_of_present_illness',
                                'review_of_systems',
                                'patient_history',
                                'assessment_plan',
                                'body_systems_constitutional',
                                'body_systems_heent',
                                'body_systems_genitourinary',
                                'body_systems_hli',
                                'body_systems_endocrine',
                                'cpt_coding',
                                'reason_for_visit',
                                'pediatric_fall_risk_assessment',
                                'oxygen_safety_assessment',
                                'document_upload',
                                'veterans_assessment',
                            ];
                            delete model.additional_sections;
                            model.model = _this.cleanModel(model);
                            // if (!model.additional_sections) {
                            model.additional_sections = {};
                            // }
                            if (model.charting_client === 'encounter_charting') {
                                model.encounter_type_id = null;
                                model.encounter_type = null;
                            }
                            var additionalSection;
                            for (var _i = 0, additional_sections_1 = additional_sections; _i < additional_sections_1.length; _i++) {
                                var section = additional_sections_1[_i];
                                if (model[section]) {
                                    if (Array.isArray(model[section])) {
                                        additionalSection = model[section][0];
                                    }
                                    else {
                                        additionalSection = model[section];
                                    }
                                    if (!angular.equals(additionalSection, {})) {
                                        model.additional_sections[section] = model[section];
                                    }
                                }
                            }
                            if (angular.equals(model.additional_sections, {})) {
                                delete model.additional_sections;
                            }
                            // the below code is to convert array to string as the "Feeding Tube Current" was coverted to multiselect from single select
                            if (model.body_systems_nutrition_hydration_findings && model.body_systems_nutrition_hydration_findings.length) {
                                model.body_systems_nutrition_hydration_findings.forEach(function (body_systems_nutrition_hydration_finding) {
                                    var note_nutrition_tube = body_systems_nutrition_hydration_finding.note_nutrition_tube;
                                    if (note_nutrition_tube && !note_nutrition_tube.length && typeof note_nutrition_tube !== "string") {
                                        delete body_systems_nutrition_hydration_finding.note_nutrition_tube;
                                    }
                                    else if (note_nutrition_tube && note_nutrition_tube.length && typeof note_nutrition_tube !== "string") {
                                        body_systems_nutrition_hydration_finding.note_nutrition_tube = note_nutrition_tube.join(",");
                                    }
                                });
                            }
                            //TODO: Remove after next regular releaschare.
                            //This is to fix a bug with booltext defaulting that caused some drafts to not be able to be saved
                            //Bug itself is now fixed but this should allow those drafts to save
                            if (model.nursing_health_histories && model.nursing_health_histories.length) {
                                if (model.nursing_health_histories[0].has_living_will === null ||
                                    model.nursing_health_histories[0].has_living_will === undefined) {
                                    model.nursing_health_histories[0].has_living_will = false;
                                }
                                if (model.nursing_health_histories[0].has_dpa === null ||
                                    model.nursing_health_histories[0].has_dpa === undefined) {
                                    model.nursing_health_histories[0].has_dpa = false;
                                }
                                if (model.nursing_health_histories[0].has_designated_surrogate === null ||
                                    model.nursing_health_histories[0].has_designated_surrogate === undefined) {
                                    model.nursing_health_histories[0].has_designated_surrogate = false;
                                }
                            }
                            return model;
                        },
                    },
                    care_plan_problem: {
                        resource: CarePlanProblemEntry,
                        encrypt: true,
                        offlineKey: function (item, user, agency) {
                            if (!item.id && !item.uuid) {
                                throw 'Invalid Care Plan Problem draft item';
                            }
                            return item.id
                                ? _this.keyAddUserInfo('care_plan_problem:id:' + item.id, user, agency)
                                : _this.keyAddUserInfo('care_plan_problem:id:' + item.uuid, user, agency);
                        },
                    },
                    physician_order_entry: {
                        resource: PhysicianOrderEntry,
                        encrypt: true,
                        offlineKey: function (item, user, agency) {
                            return item.id
                                ? _this.keyAddUserInfo('physician_order_entry:id:' + item.id, user, agency)
                                : _this.keyAddUserInfo('physician_order_entry:patient_id:' + item.patient_id, user, agency);
                        },
                    },
                };
            }
            // return config;
            PersistedItemConfigService.prototype.keyAddUserInfo = function (item, user, agency) {
                return item + ':user_id:' + user + ':agency_id:' + agency;
            };
            PersistedItemConfigService.prototype.config = function (type) {
                if (type) {
                    if (this.supportedTypes[type]) {
                        return this.supportedTypes[type];
                    }
                    else {
                        throw 'Unsupported persisted type "' + type + '"';
                    }
                }
                else {
                    return Object.keys(this.supportedTypes);
                }
            };
            PersistedItemConfigService.prototype.cleanModel = function (model) {
                var temp = angular.copy(model);
                delete temp.$promise;
                delete temp.$resolved;
                for (var prop in temp) {
                    if (!temp.hasOwnProperty(prop)) {
                        continue;
                    }
                    if (temp[prop]) {
                        if (prop.indexOf('$') === 0) {
                            delete temp[prop];
                        }
                        else {
                            delete temp[prop].$promise;
                            delete temp[prop].$resolved;
                        }
                    }
                }
                return temp;
            };
            PersistedItemConfigService.$inject = [
                'CarePlanProblemEntry',
                'ClinicalChartEntry',
                'PhysicianOrderEntry',
            ];
            return PersistedItemConfigService;
        }());
        offline.PersistedItemConfigService = PersistedItemConfigService;
        angular.module('consolo').service('persistedItemConfig', PersistedItemConfigService);
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        angular.module('consolo').service('PersistedItem', [
            '$timeout',
            '$q',
            'NetworkManager',
            'persistedItemConfig',
            'persistenceStore',
            '$mdDialog',
            'DebugLogService',
            'ClinicalChartDraftsService',
            'MileageAppIntegrationService',
            function ($timeout, $q, NetworkManager, persistedItemConfig, persistenceStore, $mdDialog, DebugLogService, ClinicalChartDraftsService, MileageAppIntegrationService) {
                return function (type, model) {
                    return new PersistedItemObject($timeout, $q, NetworkManager, persistedItemConfig, persistenceStore, $mdDialog, DebugLogService, ClinicalChartDraftsService, MileageAppIntegrationService, type, model);
                };
            },
        ]);
        var PersistedItemObject = /** @class */ (function () {
            function PersistedItemObject($timeout, $q, NetworkManager, persistedItemConfig, persistenceStore, $mdDialog, DebugLogService, ClinicalChartDraftsService, MileageAppIntegrationService, type, model) {
                var _this = this;
                this.$timeout = $timeout;
                this.$q = $q;
                this.NetworkManager = NetworkManager;
                this.persistedItemConfig = persistedItemConfig;
                this.persistenceStore = persistenceStore;
                this.$mdDialog = $mdDialog;
                this.DebugLogService = DebugLogService;
                this.ClinicalChartDraftsService = ClinicalChartDraftsService;
                this.MileageAppIntegrationService = MileageAppIntegrationService;
                this.disableSave = false;
                this.savingOnDevice = false;
                this.pendingChartDraftSave = null;
                this.reattemptCount = 0;
                this.saveOnlineError = function (resp) {
                    if (resp.status === 0 || resp.status === -1) {
                        return _this.saveOnDevice(false);
                    }
                    else {
                        _this.status = 'Unsaved';
                        throw resp.data;
                    }
                };
                this.type = type;
                this.model = angular.copy(model);
                this.updateBodySystemsNutritionHydrationFindings();
                this.config = this.persistedItemConfig.config(type);
                this.status = model.id ? 'Saved' : 'Unsaved';
                this.noServerDraft = false;
            }
            PersistedItemObject.prototype.getWorkingCopy = function () {
                return this.persistenceStore.get(this.type, this.model);
            };
            PersistedItemObject.prototype.removeCopy = function (copy) {
                return this.persistenceStore.remove(copy.type, copy.model);
            };
            PersistedItemObject.prototype.save = function () {
                this.status = 'Saving Chart';
                return this.NetworkManager.isOnline() ? this.saveOnServer() : this.saveOnDevice(false);
            };
            // Save to local storage. It will be automatically synced to the server if it's a clinical chart entry.
            PersistedItemObject.prototype.saveDraft = function () {
                var _this = this;
                this.status = 'Saving Draft';
                if (this.type === 'clinical_chart_entry' && !this.noServerDraft) {
                    this.model.syncing = true;
                    if (!this.model.temp_uuid) {
                        this.model.temp_uuid = this.generateUUID();
                    }
                    return this.saveChartDraftOnDevice(true)
                        .then(function (response) {
                        _this.model.validating = true;
                        _this.saveDraftOnServerLater().finally(function () {
                            _this.pendingChartDraftSave = null;
                            _this.model.syncing = false;
                            _this.saveChartDraftOnDevice(true);
                        });
                    })
                        .catch(function (error) {
                        console.log(error);
                        throw error;
                    });
                }
                else {
                    return this.saveOnDevice(true);
                }
            };
            PersistedItemObject.prototype.saveDraftOnServerLater = function () {
                var _this = this;
                if (!this.pendingChartDraftSave) {
                    // console.log('Timer started for draft sync');
                    // Timer started for draft sync
                    this.model.server = false;
                    return this.$q(function (resolve, reject) {
                        var delay = _this.NetworkManager.isOnline() ? 5000 : 0;
                        _this.pendingChartDraftSave = _this.$timeout(function () {
                            if (_this.NetworkManager.isOnline()) {
                                _this.saveDraftOnServer()
                                    .then(function (response) {
                                    return resolve(response);
                                })
                                    .catch(function (error) {
                                    return reject(error);
                                });
                            }
                            else {
                                return reject('Device is offline');
                            }
                        }, delay);
                    });
                }
                else {
                    return this.$q(function (resolve) {
                        // Timer is already going for draft sync
                        // console.log('Timer is already going for server draft sync');
                    });
                }
            };
            PersistedItemObject.prototype.saveDraftOnServer = function (lastUpdated) {
                var _this = this;
                if (this.NetworkManager.isOnline()) {
                    this.status = 'Saving Draft to Server';
                    var originalItem = angular.copy(this.model);
                    if (this.config.cleanDraft) {
                        originalItem = this.config.cleanDraft(originalItem);
                    }
                    var updatedAt = new Date();
                    originalItem.updated_at = lastUpdated ? lastUpdated : updatedAt;
                    originalItem.model.updated_at = lastUpdated ? lastUpdated : updatedAt;
                    // PUT call to create and update chart draft
                    return this.ClinicalChartDraftsService.updateClinicalChartDraft(originalItem)
                        .then(function (response) {
                        _this.model.server = true;
                        _this.model.server_error = false;
                        _this.model.updated_at = response.data.data.model.updated_at;
                        _this.model.server_draft_updated_at = response.data.data.model.updated_at;
                        if (_this.model.charting_client === 'encounter_charting' ||
                            _this.model.charting_client === 'custom') {
                            _this.validateDraft(response.data).finally(function () {
                                _this.model.validating = false;
                                _this.saveChartDraftOnDevice(true)
                                    .then(function () { })
                                    .catch(function (error) {
                                    throw error;
                                });
                            });
                        }
                        _this.sendNotification(response.data.id, 'draft_update');
                        return response;
                    })
                        .catch(function (error) {
                        _this.model.server = false;
                        _this.model.server_error = true;
                        _this.model.validating = false;
                        return error;
                    });
                }
                else {
                    this.model.server = false;
                    this.model.server_error = true;
                }
            };
            PersistedItemObject.prototype.sendNotification = function (draftId, type) {
                this.MileageAppIntegrationService.message.chart_draft = {
                    name: type,
                    id: draftId,
                    patient_id: this.model.patient_id,
                    errors: this.model.errors,
                    errorCount: this.model.errorCount,
                };
                this.MileageAppIntegrationService.sendNotification();
            };
            PersistedItemObject.prototype.saveChartDraftOnDevice = function (isDraft, reattempt) {
                var _this = this;
                if (reattempt === void 0) { reattempt = false; }
                if (this.savingOnDevice) {
                    return this.saveChartDraftOnDeviceLater(isDraft, reattempt);
                }
                else {
                    this.savingOnDevice = true;
                    this.status = 'Saving on Device';
                    return this.persistenceStore
                        .set(this.type, this.model, isDraft)
                        .then(function () {
                        // console.log('Saved to device');
                        _this.model.local = true;
                        if (_this.model.server && _this.model.local) {
                            _this.status = 'Saved on server and device';
                        }
                        else if (_this.model.local && !_this.model.server) {
                            _this.status = 'Saved on device only';
                            _this.model.updated_at = new Date();
                        }
                        else {
                            _this.status = 'Unknown';
                        }
                        _this.savingOnDevice = false;
                        return _this.model;
                    })
                        .catch(function (error) {
                        _this.savingOnDevice = false;
                        _this.status = 'Unsaved';
                        console.log(error);
                        throw ['Unable to save to your device'];
                    });
                }
            };
            PersistedItemObject.prototype.saveOnDevice = function (isDraft) {
                var _this = this;
                if (this.savingOnDevice) {
                    this.saveOnDeviceLater(isDraft);
                }
                else {
                    this.savingOnDevice = true;
                    return this.persistenceStore.set(this.type, this.model, isDraft).then(function () {
                        _this.savingOnDevice = false;
                        _this.status = 'Saved on device';
                        _this.model.local = true;
                        return _this.model;
                    }, function (e) {
                        _this.savingOnDevice = false;
                        _this.status = 'Unsaved';
                        var errorString = 'An error occurred while trying to save the draft to your device. ' +
                            'To prevent further errors you are being returned to the dashboard. Navigate back to your draft to resume work. ' +
                            'If this problem persists please contact your administrator.';
                        var alert = _this.$mdDialog
                            .alert()
                            .clickOutsideToClose(false)
                            .title('Error Saving Draft')
                            .textContent(errorString)
                            .ok('Ok');
                        _this.DebugLogService.info('Error saving draft: ' + e);
                        _this.$mdDialog.show(alert).then(function () {
                            location.href = '/';
                        });
                        throw ['Unable to save to your device'];
                    });
                }
            };
            PersistedItemObject.prototype.saveOnDeviceLater = function (isDraft) {
                var _this = this;
                if (this.pendingDeviceSave) {
                    this.$timeout.cancel(this.pendingDeviceSave);
                }
                return this.$q(function (resolve) {
                    var delay = 3000;
                    _this.pendingDeviceSave = _this.$timeout(function () {
                        _this.pendingDeviceSave = null;
                        resolve(_this.saveOnDevice(isDraft));
                    }, delay);
                });
            };
            PersistedItemObject.prototype.saveChartDraftOnDeviceLater = function (isDraft, reattempt) {
                var _this = this;
                if (reattempt === void 0) { reattempt = false; }
                if (this.pendingDeviceSave) {
                    this.$timeout.cancel(this.pendingDeviceSave);
                }
                return this.$q(function (resolve) {
                    var delay = 3000;
                    _this.pendingDeviceSave = _this.$timeout(function () {
                        _this.pendingDeviceSave = null;
                        if (reattempt) {
                            _this.reattemptCount++;
                            if (_this.reattemptCount > 3) {
                                console.warn('Chart draft taking long amount of time to save, retrying');
                                _this.reattemptCount = 0;
                                _this.savingOnDevice = false;
                            }
                        }
                        resolve(_this.saveChartDraftOnDevice(isDraft, true));
                    }, delay);
                });
            };
            PersistedItemObject.prototype.saveOnServer = function () {
                var _this = this;
                //Making a copy of the model so the form doesn't think it's dirty
                //It returns the originalItem which won't have the ID that just came back from the server
                //this is okay currently, because the model re-loads when going from new -> edit mode.
                //Might cause problems for future uses.
                var originalItem = angular.copy(this.model);
                var save;
                if (this.type === 'clinical_chart_entry') {
                    originalItem.draft_id = originalItem.temp_uuid;
                }
                save = this.model.id ? originalItem.$update() : originalItem.$save();
                if (this.config.cleanDraft) {
                    originalItem = this.config.cleanDraft(originalItem);
                }
                return save.then(function (response) {
                    _this.MileageAppIntegrationService.message.final_chart_id = response.id;
                    _this.MileageAppIntegrationService.sendNotification();
                    if (!originalItem.id) {
                        //Ensure the id gets attached to the item (it should be, this may be redundant)
                        originalItem.id = response.data.id;
                    }
                    return _this.saveOnlineSuccess(_this.model).then(function () {
                        originalItem.local = false;
                        return originalItem;
                    });
                }, this.saveOnlineError);
            };
            PersistedItemObject.prototype.saveOnlineSuccess = function (data) {
                this.status = 'Saved';
                return this.persistenceStore.remove(this.type, data);
            };
            // Merges the given copy's model into the current model
            PersistedItemObject.prototype.useCopy = function (copy) {
                this.type = copy.type;
                this.draft = copy.draft;
                this.timestamp = copy.timestamp;
                this.agency_id = copy.agency_id;
                this.user_id = copy.user_id;
                this.encrypted = copy.encrypted;
                this.model.user = null;
                this.model = angular.merge(this.model, copy.model);
                this.updateBodySystemsNutritionHydrationFindings();
                return this.model;
            };
            // since we store multiple value as ',' seprated string the below function is to convert them back to array 
            PersistedItemObject.prototype.updateBodySystemsNutritionHydrationFindings = function () {
                if (this.model.body_systems_nutrition_hydration_findings && this.model.body_systems_nutrition_hydration_findings.length) {
                    this.model.body_systems_nutrition_hydration_findings.forEach(function (body_systems_nutrition_hydration_finding) {
                        var note_nutrition_tube = body_systems_nutrition_hydration_finding.note_nutrition_tube;
                        if (note_nutrition_tube && !note_nutrition_tube.length && typeof note_nutrition_tube === "string") {
                            body_systems_nutrition_hydration_finding.note_nutrition_tube = [];
                        }
                        else if (note_nutrition_tube && note_nutrition_tube.length && typeof note_nutrition_tube === "string") {
                            body_systems_nutrition_hydration_finding.note_nutrition_tube = note_nutrition_tube.split(",");
                        }
                    });
                }
            };
            PersistedItemObject.prototype.validateDraft = function (data) {
                var _this = this;
                var draftId = data.id;
                // let draft = data.data;
                return this.ClinicalChartDraftsService.validateClinicalChartDraft(draftId, data)
                    .then(function (response) {
                    _this.model.validating = false;
                    var errors = response.data.errors;
                    if (errors === undefined) {
                        _this.model.errorCount = 0;
                        _this.model.errors = null;
                    }
                    else if (errors.length > 0) {
                        _this.model.errors = errors;
                        _this.model.errorCount = errors.length;
                    }
                    _this.sendNotification(draftId, 'draft_update');
                    return _this;
                })
                    .catch(function (error) {
                    _this.model.validating = false;
                    console.log('validateDraft', error);
                    throw error;
                });
            };
            PersistedItemObject.prototype.checkForUpdated = function (draft) {
                return this.ClinicalChartDraftsService.getSingleClinicalChartDraft(draft.model.temp_uuid)
                    .then(function (response) {
                    if (response.data.clinical_chart_id) {
                        return true;
                    }
                    else {
                        var deviceDraftDate = new Date(draft.model.updated_at);
                        var serverDraftDate = new Date(response.data.data.model.updated_at);
                        if (deviceDraftDate >= serverDraftDate) {
                            return false;
                        }
                        else if (deviceDraftDate < serverDraftDate) {
                            return true;
                        }
                    }
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            PersistedItemObject.prototype.sleep = function (seconds) {
                return new Promise(function (resolve) { return setTimeout(resolve, seconds * 1000); });
            };
            PersistedItemObject.prototype.generateUUID = function () {
                var d = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
                });
                return uuid;
            };
            return PersistedItemObject;
        }());
        /*
         * Wraps an instance of a Resource in a layer that ensure it's persisted, either to the server or the device.
         *
         * "type" must be listed in the persistedItemConfig factory.
         */
        // function Factory($timeout, $q, NetworkManager, persistedItemConfig, persistenceStore) {
        //   return PersistedItem;
        //   function PersistedItem(type, model) {
        //     // Throws an exception if type is not supported
        //     var item = this;
        //     item.config = persistedItemConfig.config(type);
        //     item.model = model;
        //     item.status = model.id ? 'Saved' : 'Unsaved';
        //     item.type = type;
        //     item.getWorkingCopy = getWorkingCopy;
        //     item.removeCopy = removeCopy;
        //     item.save = save;
        //     item.saveDraft = saveDraft;
        //     item.useCopy = useCopy;
        //     // Returns a promise that resolves to a working copy, or rejects if one isn't present.
        //     function getWorkingCopy() {
        //       return persistenceStore.get(type, model);
        //     }
        //     // Deletes the copy from local storage
        //     function removeCopy(copy) {
        //       return persistenceStore.remove(copy.type, copy.model);
        //     }
        //     // Try to save to the server. If that fails, so locally and try to sync it later.
        //     function save() {
        //       item.status = 'Saving';
        //       return NetworkManager.isOnline() ? saveOnServer() : saveOnDevice();
        //     }
        //     // Save to local storage. It will *not* be automatically synced to the server.
        //     function saveDraft() {
        //       item.status = 'Saving';
        //       return saveOnDevice(true);
        //     }
        //     function saveOnDevice(isDraft) {
        //       if (item.savingOnDevice) {
        //         saveOnDeviceLater(isDraft);
        //       } else {
        //         item.savingOnDevice = true;
        //         return persistenceStore.set(type, model, isDraft).then(
        //           function() {
        //             item.savingOnDevice = false;
        //             item.status = 'Saved on device';
        //             model.local = true;
        //             return model;
        //           },
        //           function() {
        //             item.savingOnDevice = false;
        //             item.status = 'Unsaved';
        //             throw ['Unable to save to your device'];
        //           }
        //         );
        //       }
        //     }
        //     function saveOnDeviceLater(isDraft) {
        //       if (item.pendingDeviceSave) {
        //         $timeout.cancel(item.pendingDeviceSave);
        //       }
        //       return $q(function(resolve) {
        //         item.pendingDeviceSave = $timeout(function() {
        //           item.pendingDeviceSave = null;
        //           resolve(saveOnDevice(isDraft));
        //         }, 2000);
        //       });
        //     }
        //     function saveOnServer() {
        //       //Making a copy of the model so the form doesn't think it's dirty
        //       //It returns the originalItem which won't have the ID that just came back from the server
        //       //this is okay currently, because the model re-loads when going from new -> edit mode.
        //       //Might cause problems for future uses.
        //       var originalItem = angular.copy(model);
        //       var save = model.id ? originalItem.$update() : originalItem.$save();
        //       if (item.config.cleanDraft) {
        //         originalItem = item.config.cleanDraft(originalItem);
        //       }
        //       return save.then(function(response) {
        //         if (!originalItem.id) {
        //           //Ensure the id gets attached to the item (it should be, this may be redundant)
        //           originalItem.id = response.id;
        //         }
        //         return saveOnlineSuccess(model).then(function() {
        //           originalItem.local = false;
        //           return originalItem;
        //         });
        //       }, saveOnlineError);
        //     }
        //     function saveOnlineError(resp) {
        //       if (resp.status === 0 || resp.status === -1) {
        //         return saveOnDevice();
        //       } else {
        //         item.status = 'Unsaved';
        //         throw resp.data;
        //       }
        //     }
        //     function saveOnlineSuccess(data) {
        //       item.status = 'Saved';
        //       return persistenceStore.remove(type, data);
        //     }
        //     // Merges the given copy's model into the current model
        //     function useCopy(copy) {
        //       model = angular.merge(model, copy.model);
        //       return model;
        //     }
        //   }
        // }
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        /*
         * Holds items that should be pushed to the server ASAP, or working copies that *might* need pushed at some point.
         */
        var PersistenceStore = /** @class */ (function () {
            function PersistenceStore($timeout, $q, crypto, Store, persistedItemConfig, Session) {
                this.$timeout = $timeout;
                this.$q = $q;
                this.crypto = crypto;
                this.Store = Store;
                this.persistedItemConfig = persistedItemConfig;
                this.Session = Session;
                this.draftCounts = {};
            }
            PersistenceStore.prototype.initialize = function () { };
            PersistenceStore.prototype.setCounts = function () {
                var _this = this;
                this.draftCounts.total = 0;
                var chain = [];
                this.persistedItemConfig.config().forEach(function (key) {
                    chain.push(_this.count(key).then(function (c) {
                        if (c) {
                            _this.draftCounts[key] = c;
                        }
                        else {
                            _this.draftCounts[key] = 0;
                        }
                        _this.draftCounts.total += c;
                    }));
                });
                return this.$q.all(chain);
            };
            PersistenceStore.prototype.getCounts = function () {
                return this.draftCounts;
            };
            PersistenceStore.prototype.count = function (type) {
                var _this = this;
                var i = 0;
                var store = this.Store.local('persistence');
                return this.$q(function (resolve) {
                    var sess = _this.Session.get();
                    if (sess) {
                        var user_id_1 = sess.user.id;
                        var agency_id_1 = sess.agency.id;
                        store
                            .iterate(function (item) {
                            if ((!type || type === item.type) &&
                                item.user_id === user_id_1 &&
                                item.agency_id === agency_id_1) {
                                i++;
                            }
                        })
                            .then(function () {
                            resolve(i);
                        })
                            .catch(function (error) {
                            console.warn('Error retrieving persistence store counts');
                            console.warn(error);
                            throw error;
                        });
                    }
                });
            };
            /*
             * Iterates through each item and passes it to your handler.
             */
            PersistenceStore.prototype.forEach = function (type, handler) {
                var _this = this;
                var store = this.Store.local('persistence');
                var itemPromises = [];
                var handlerPromises = [];
                var p = this.$q.defer();
                var sess = this.Session.get();
                if (sess) {
                    var user_id_2 = sess.user.id;
                    var agency_id_2 = sess.agency.id;
                    store
                        .iterate(function (item, key) {
                        if ((type !== item.type && type !== null) ||
                            item.user_id !== user_id_2 ||
                            item.agency_id !== agency_id_2) {
                            return;
                        }
                        if (item.encrypted) {
                            try {
                                itemPromises.push(_this.crypto.decrypt(item.model).then(function (model) {
                                    item.model = model;
                                    handlerPromises.push(handler(item));
                                }, function (e) {
                                    console.log(key, e);
                                    // throw (e);
                                    return _this.$q.when(true);
                                }));
                            }
                            catch (e) {
                                console.log(key, e);
                            }
                        }
                        else {
                            itemPromises.push(handler(item));
                        }
                    })
                        .then(function () {
                        if (handlerPromises.length) {
                            return _this.$q.all(itemPromises, handlerPromises);
                        }
                        return _this.$q.all(itemPromises);
                    })
                        .catch(function (error) {
                        console.warn('Error looping through persistence store');
                        console.warn(error);
                        p.resolve();
                        // throw error;
                    })
                        .then(function () {
                        p.resolve();
                    });
                }
                else {
                    p.reject();
                }
                return p.promise;
            };
            // Gets an item. Returns a promise that resolves to the item, or rejects if not found. The item is a wrapper object.
            // It contains the type, timestamp, and model (the object you passed to "set").
            PersistenceStore.prototype.get = function (type, item) {
                var _this = this;
                var store = this.Store.local('persistence');
                var sess = this.Session.get();
                if (sess) {
                    var user_id_3 = sess.user.id;
                    var agency_id_3 = sess.agency.id;
                    return store.getItem(this.getKey(type, item, user_id_3, agency_id_3)).then(function (offlineItem) {
                        if (offlineItem.agency_id !== agency_id_3 || offlineItem.user_id !== user_id_3) {
                            offlineItem = null;
                        }
                        if (offlineItem) {
                            if (offlineItem.encrypted) {
                                return _this.crypto.decrypt(offlineItem.model).then(function (model) {
                                    offlineItem.model = model;
                                    return offlineItem;
                                }, function (e) {
                                    console.log(e);
                                    throw e;
                                });
                            }
                            return offlineItem;
                        }
                        else {
                            throw 'No item found';
                        }
                    });
                }
                else {
                }
            };
            PersistenceStore.prototype.getKey = function (type, item, user_id, agency_id) {
                return this.persistedItemConfig.config(type).offlineKey(item, user_id, agency_id);
            };
            PersistenceStore.prototype.remove = function (type, item) {
                var _this = this;
                var store = this.Store.local('persistence');
                var sess = this.Session.get();
                if (sess) {
                    var user_id = sess.user.id;
                    var agency_id = sess.agency.id;
                    var key = this.getKey(type, item, user_id, agency_id);
                    var exists = store.getItem(key);
                    if (exists) {
                        return store.removeItem(key, function () {
                            _this.draftCounts[type]--;
                            _this.draftCounts.total--;
                        });
                    }
                    else {
                        return this.$q.when(true);
                    }
                }
                else {
                    return this.$q.reject();
                }
            };
            // Saves item to offline storage. If isDraft is passed as true, the item will *not* be automatically synced.
            PersistenceStore.prototype.set = function (type, item, isDraft) {
                var _this = this;
                var sess = this.Session.get();
                if (sess) {
                    var user_id = sess.user.id;
                    var agency_id = sess.agency.id;
                    var store_1 = this.Store.local('persistence'), key_1 = this.getKey(type, item, user_id, agency_id);
                    var offlineItem_1 = {
                        timestamp: Date.now(),
                        type: type,
                        draft: !!isDraft,
                        agency_id: agency_id,
                        user_id: user_id,
                        model: null,
                        encrypted: false,
                    };
                    var newItem_1 = false;
                    var itemcopy_1 = angular.extend({}, item); //this is what $Resource.toJSON() does but item is not always a resource
                    delete itemcopy_1.$promise;
                    delete itemcopy_1.$resolved;
                    for (var prop in itemcopy_1) {
                        if (!itemcopy_1.hasOwnProperty(prop)) {
                            continue;
                        }
                        if (itemcopy_1[prop]) {
                            delete itemcopy_1[prop].$promise;
                            delete itemcopy_1[prop].$resolved;
                        }
                    }
                    store_1.getItem(key_1).then(function (i) {
                        if (i === null) {
                            newItem_1 = true;
                        }
                    });
                    if (this.persistedItemConfig.config(type).encrypt) {
                        return this.crypto.encrypt(itemcopy_1).then(function (cryptedItem) {
                            offlineItem_1.encrypted = true;
                            offlineItem_1.model = cryptedItem;
                            return store_1
                                .setItem(key_1, offlineItem_1)
                                .then(function () {
                                if (newItem_1) {
                                    _this.draftCounts[type]++;
                                    _this.draftCounts.total++;
                                }
                            })
                                .catch(function (error) {
                                console.log('Error saving item to persistence store', error, item);
                                return _this.$q.reject(error);
                            });
                        }, function (error) {
                            console.log('Error encrypting PersistedItem');
                            console.log(error);
                            console.log(itemcopy_1);
                            return _this.$q.reject();
                        });
                    }
                    else {
                        offlineItem_1.encrypted = false;
                        offlineItem_1.model = itemcopy_1; // JSON.parse(JSON.stringify(item)); is a hackish way but item is not always a resource item.toJSON();
                        return store_1
                            .setItem(key_1, offlineItem_1)
                            .then(function () {
                            if (newItem_1) {
                                _this.draftCounts[type]++;
                                _this.draftCounts.total++;
                            }
                        })
                            .catch(function (error) {
                            console.log('Error saving item', error);
                            return _this.$q.reject(error);
                        });
                    }
                }
                else {
                    console.log('No session found');
                    return this.$q.reject('Session not found');
                }
            };
            PersistenceStore.$inject = [
                '$timeout',
                '$q',
                'crypto',
                'Store',
                'persistedItemConfig',
                'SessionService',
            ];
            return PersistenceStore;
        }());
        offline.PersistenceStore = PersistenceStore;
        angular.module('consolo').service('persistenceStore', PersistenceStore);
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('promiseTimer', ['$q', Factory]);
    function Factory($q) {
        return timer;
        function timer(label, promise) {
            return function (val) {
                return $q(function (resolve, reject) {
                    var start = Date.now();
                    if (!promise.then) {
                        promise = promise(val);
                    }
                    promise.then(function (val2) {
                        var seconds = (Date.now() - start) / 1000;
                        console.info(label + ':  ' + seconds.toFixed(2) + ' seconds');
                        resolve(val2);
                    }, function (e) {
                        var seconds = (Date.now() - start) / 1000;
                        console.warn(label + ' failed after ' + seconds.toFixed(2) + ' seconds');
                        reject(e);
                    });
                });
            };
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('offlineSync', [
        '$timeout',
        '$q',
        'NetworkManager',
        'persistedItemConfig',
        'persistenceStore',
        'Store',
        'logger',
        Factory,
    ]);
    /*
     * Syncing layer for PersistedItem.
     */
    function Factory($timeout, $q, NetworkManager, persistedItemConfig, persistenceStore, Store, logger) {
        return {
            initialize: initialize,
        };
        function initialize() {
            NetworkManager.onlineHandler.onEvent('offlineSync', syncAll);
            if (NetworkManager.isOnline()) {
                $timeout(syncAll, 5000);
            }
        }
        function sync(item) {
            var Resource = persistedItemConfig.config(item.type).resource;
            var model = new Resource(item.model);
            var save = model.id ? model.$update() : model.$save();
            return save.then(function () {
                return persistenceStore.remove(item.type, item.model);
            });
        }
        function syncAll() {
            return withLock(function () {
                var syncs = [];
                return persistenceStore
                    .forEach(function (item) {
                    syncs.push(sync(item));
                })
                    .then(function () {
                    return $q.all(syncs);
                })
                    .then(function () {
                    console.info('Offline sync completed');
                }, function () {
                    logger.error('Offline sync failed to sync one or more items');
                });
            });
        }
        function withLock(doSync) {
            var appStore = Store.local('app');
            return appStore.getItem('offlineSyncLock').then(function (lockedAt) {
                var fiveMinAgo = Date.now() - 5 * 60 * 1000;
                if (!lockedAt || fiveMinAgo > lockedAt) {
                    return setLock()
                        .then(doSync)
                        .then(removeLock, removeLock);
                }
                else {
                    return $q.reject(new Error('Offline sync already in progress'));
                }
            });
            function removeLock() {
                return appStore.removeItem('offlineSyncLock');
            }
            function setLock() {
                return appStore.setItem('offlineSyncLock', Date.now());
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        var WebCacheConfig = /** @class */ (function () {
            function WebCacheConfig(Session, Patient) {
                this.Session = Session;
                this.Patient = Patient;
                this.types = {
                    patient: {
                        name: 'patient',
                        cache_timeout: 5 * 60 * 1000,
                        force: true,
                        service: this.Patient,
                        buildKey: function (id) {
                            return 'patient:' + id.toString();
                        },
                    },
                };
                // let x = new (this.createInstance(PatientService))();
            }
            WebCacheConfig.prototype.keyAddUserInfo = function (type, id) {
                //TODO: Make this user based
                // return 'mockagency' + ':' + 'mock_user_id' + ':' + type + ':' + id.toString();
            };
            WebCacheConfig.$inject = ['SessionService', 'Patient'];
            return WebCacheConfig;
        }());
        angular.module('consolo').service('WebCacheConfig', WebCacheConfig);
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var offline;
    (function (offline) {
        'use strict';
        var ivLen = 16;
        var WebCache = /** @class */ (function () {
            function WebCache(Store, OldCache, Session, webCacheConfig, $q, crypto, $http, NetworkManager, logger) {
                this.webCacheConfig = webCacheConfig;
                this.$q = $q;
                this.$http = $http;
                this.NetworkManager = NetworkManager;
                this.logger = logger;
                this.online = true;
                this.nonsupportedBrowser = false;
                this.aesKeyParams = { name: 'AES-CBC', length: 256 };
                this.hmacImportParams = { name: 'AES-CBC', length: 256, hash: null };
                this.OldCache = OldCache;
                this.Session = Session;
                this.crypto = crypto;
                this.webCache = Store.async('webCache');
                if (!Uint8Array.prototype.slice) {
                    Object.defineProperty(Uint8Array.prototype, 'slice', {
                        value: Array.prototype.slice,
                    });
                }
                var deferred = this.$q.defer();
                this.ready = deferred.promise;
                this.initializingKeyDeferred = this.$q.defer();
                this.initializingKey = false;
                // navigator.userAgent.match(/Version\/(\d*)\.?\d*\.?\d* (Safari|Mobile.* Safari)/);
                this.fetchKey();
            }
            WebCache.prototype.getType = function (type) {
                return this.webCacheConfig.types[type];
            };
            WebCache.prototype.destroy = function () {
                this.key = null;
                this.initializingKey = false;
                this.initializingKeyDeferred = this.$q.defer();
            };
            WebCache.prototype.init = function () {
                return this.fetchKey();
            };
            WebCache.prototype.fetchKey = function () {
                var _this = this;
                if (!this.Session.get()) {
                    return this.$q.reject('Session not available');
                }
                if (!this.initializingKey) {
                    this.initializingKey = true;
                    this.fetch('webKey')
                        .then(function (data) {
                        if (data) {
                            _this.crypto
                                .decrypt(data)
                                .then(function (jwkKey) {
                                window.crypto.subtle
                                    .importKey('jwk', jwkKey.keydata, _this.hmacImportParams, false, [
                                    'encrypt',
                                    'decrypt',
                                ])
                                    .then(function (key) {
                                    _this.key = key;
                                    _this.initializingKeyDeferred.resolve(true);
                                }, function (e) {
                                    console.warn(e);
                                    _this.initializingKeyDeferred.reject(e);
                                });
                            })
                                .catch(function (error) {
                                console.warn('Error fetching webKey', error);
                                console.warn('Generating new webKey');
                                _this.generateKey();
                            });
                        }
                        else {
                            _this.generateKey();
                        }
                    })
                        .catch(function (e) {
                        if (e) {
                            console.warn(e);
                            _this.initializingKeyDeferred.reject(e);
                        }
                    });
                }
                return this.initializingKeyDeferred.promise;
            };
            WebCache.prototype.generateKey = function () {
                var _this = this;
                window.crypto.subtle
                    .generateKey(this.hmacImportParams, true, ['encrypt', 'decrypt'])
                    .then(function (key) {
                    window.crypto.subtle.exportKey('jwk', key).then(function (keydata) {
                        var data = { keydata: keydata };
                        _this.crypto
                            .encrypt(data)
                            .then(function (encrypted) {
                            delete encrypted.$promise;
                            delete encrypted.$resolved;
                            _this.cache('webKey', encrypted, { expires: 0 });
                        })
                            .then(function () {
                            _this.key = key;
                            _this.initializingKeyDeferred.resolve(true);
                        });
                    });
                });
            };
            WebCache.prototype.ab2str = function (buf) {
                var uintArray = new Uint16Array(buf);
                var converted = [];
                for (var i = 0, len = uintArray.length; i < len; i++) {
                    converted.push(String.fromCharCode(uintArray[i]));
                }
                return converted.join('');
            };
            WebCache.prototype.str2ab = function (str) {
                var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
                var bufView = new Uint16Array(buf);
                for (var i = 0, strLen = str.length; i < strLen; i++) {
                    bufView[i] = str.charCodeAt(i);
                }
                return buf;
            };
            WebCache.prototype.addCacheAttributesToData = function (item) {
                for (var attr in item) {
                    if (item.hasOwnProperty(attr) && attr !== 'data') {
                        item.data['__' + attr] = item[attr];
                    }
                }
                return item.data;
            };
            WebCache.prototype.clearCacheAttributesFromData = function (item) {
                for (var attr in item) {
                    if (item.hasOwnProperty(attr) && attr !== 'data') {
                        delete item.data['__' + attr];
                    }
                }
                return item.data;
            };
            WebCache.prototype.extend = function (key, data, options) {
                var _this = this;
                return this.fetch(key)
                    .then(function (item) {
                    item = item || {};
                    angular.extend(item, data);
                    return _this.cache(key, item, options)
                        .then(function (cachedItem) {
                        return cachedItem;
                    })
                        .catch(function (e) {
                        throw e;
                    });
                })
                    .catch(function (e) {
                    console.warn('Could not fetch ' + key + ' for extending - replacing.');
                    _this.cache(key, data, options);
                    return data;
                });
            };
            WebCache.prototype.extendArray = function (key_name, key_field, data, options) {
                var items = [];
                var arr = [];
                var key = '';
                for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                    var d = data_1[_i];
                    key = key_name + ':' + d[key_field];
                    arr.push(this.extend(key, d, options));
                }
                return this.$q.all(arr).then(function (data) {
                    return data;
                });
            };
            WebCache.prototype.fetch = function (key, params, ignoreExpired, nonUserKey) {
                var _this = this;
                if (!nonUserKey) {
                    key = this.buildUserKey(key);
                }
                if (arguments.length === 1) {
                    return this.webCache.getItem(key).then(function (item) {
                        if (!item) {
                            return null;
                        }
                        if (item.encrypt) {
                            _this.decrypt(item.data)
                                .then(function (unencrypted) {
                                item.data = unencrypted;
                                _this.addCacheAttributesToData(item);
                                return item.data;
                            })
                                .catch(function (e) {
                                console.warn('Error decrypting item ' + key + ': ' + e.message);
                                return null;
                            });
                        }
                        else {
                            _this.addCacheAttributesToData(item);
                            return item.data;
                        }
                    });
                }
            };
            WebCache.prototype.cache = function (key, data, options, params) {
                var _this = this;
                var deferred = this.$q.defer();
                var item = { data: data, encrypt: false, timestamp: new Date().getTime() };
                if (options) {
                    item.encrypt = options.encrypt ? options.encrypt : false;
                    item.expires = options.expires ? options.expires * 1000 * 60 + Date.now() : 0;
                    if (options.partial) {
                        item[options.partial + '_timetamp'] = item.timestamp;
                    }
                    if (!options.nonUserKey) {
                        key = this.buildUserKey(key);
                    }
                    else if (options.encrypt) {
                        throw 'Can\'t encrypt data without using a user key';
                    }
                }
                else {
                    key = this.buildUserKey(key);
                }
                if (arguments.length > 3) {
                }
                this.clearCacheAttributesFromData(item);
                if (item.encrypt) {
                    this.encrypt(item.data)
                        .then(function (encrypted) {
                        var copy = {};
                        angular.copy(item, copy);
                        copy.data = encrypted;
                        _this.webCache
                            .setItem(key, copy)
                            .then(function (cachedItem) {
                            _this.addCacheAttributesToData(item);
                            deferred.resolve(item.data);
                        })
                            .catch(function (e) {
                            console.warn('Error caching item ' + key + ': ' + e);
                            deferred.reject(e);
                        });
                    })
                        .catch(function (e) {
                        console.warn('Error encrypting item ' + key + ': ' + e);
                        deferred.reject(e);
                    });
                }
                else {
                    this.webCache
                        .setItem(key, item)
                        .then(function (cachedItem) {
                        _this.addCacheAttributesToData(cachedItem);
                        deferred.resolve(cachedItem.data);
                    })
                        .catch(function (e) {
                        console.warn('Error caching item ' + key + ': ' + e);
                        deferred.reject(e);
                    });
                }
                return deferred.promise;
            };
            WebCache.prototype.decrypt = function (data) {
                var _this = this;
                var params = { name: 'AES-CBC', iv: data.iv };
                return this.fetchKey().then(function () {
                    return window.crypto.subtle.decrypt(params, _this.key, _this.str2ab(data.data)).then(function (decrypted) {
                        return JSON.parse(_this.ab2str(decrypted));
                    }, function (e) {
                        throw e;
                    });
                });
            };
            WebCache.prototype.encrypt = function (data) {
                var _this = this;
                var initializationVector = new Uint8Array(ivLen);
                crypto.getRandomValues(initializationVector);
                var params = { name: 'AES-CBC', iv: initializationVector };
                var abData = this.str2ab(JSON.stringify(data));
                return this.fetchKey().then(function () {
                    return window.crypto.subtle.encrypt(params, _this.key, abData).then(function (encrypted) {
                        return { iv: initializationVector, data: _this.ab2str(encrypted) };
                    }, function (e) {
                        throw e;
                    });
                });
            };
            WebCache.prototype.encryptLegacy = function (data) {
                return this.crypto.encrypt(data);
            };
            WebCache.prototype.decryptLegacy = function (key) {
                return this.crypto.decrypt(key);
            };
            WebCache.prototype.fetchMatches = function (match, ids) {
                var _this = this;
                match = this.buildUserKey(match);
                var matches = [];
                var matchList;
                if (ids) {
                    matchList = ids.map(function (id) {
                        return match + ':' + id.toString();
                    });
                }
                var arrDecrypts = [];
                return this.webCache
                    .iterate(function (value, key, iterationNumber) {
                    if ((matchList && matchList.indexOf(key) >= 0) ||
                        (!matchList && key.indexOf(match) >= 0)) {
                        if (value.encrypt) {
                            arrDecrypts.push(_this.decrypt(value.data).then(function (decrypted) {
                                value.data = decrypted;
                                _this.addCacheAttributesToData(value);
                                matches.push(value.data);
                            }, function (e) {
                                console.warn('Could not decrypt ' + match + ' : ' + e);
                            }));
                        }
                        else {
                            _this.addCacheAttributesToData(value);
                            matches.push(value.data);
                        }
                    }
                })
                    .then(function () {
                    return _this.$q.all(arrDecrypts).then(function () {
                        return matches;
                    });
                });
            };
            WebCache.prototype.buildQueryKey = function (query, params) {
                var key = query.replace(/\/?api\/v\d\//, '').replace(/\//g, ':');
                key += params ? ':' + JSON.stringify(params) : '';
                return key;
            };
            WebCache.prototype.buildUserKey = function (key) {
                var sess = this.Session.get();
                return sess.agency.code + ':' + sess.user.id + ':' + key;
            };
            WebCache.prototype.reset = function () {
                var _this = this;
                return this.webCache.clear().then(function () {
                    _this.destroy();
                    return _this.fetchKey();
                });
            };
            WebCache.prototype.remove = function (key) {
                return this.webCache.removeItem(key);
            };
            WebCache.prototype.cachableQuery = function (endpoint, keyParams, keylessParams, options) {
                var _this = this;
                var query_key = this.buildQueryKey(endpoint, keyParams);
                options = options || {};
                keyParams = keyParams || {};
                if (keylessParams) {
                    angular.extend(keyParams, keylessParams);
                }
                if (options.force && this.NetworkManager.isOnline()) {
                    return this.runQueryAndCache(endpoint, keyParams, query_key, options);
                }
                else {
                    return this.fetch(query_key).then(function (data) {
                        if (_this.NetworkManager.isOnline() &&
                            (!data || (data.expires && data.expires < Date.now()))) {
                            return _this.runQueryAndCache(endpoint, keyParams, query_key, options).catch(function (e) {
                                throw e;
                            });
                        }
                        else {
                            return data;
                        }
                    });
                }
            };
            WebCache.prototype.runQueryAndCache = function (endpoint, params, key, options) {
                var _this = this;
                return this.$http
                    .get(endpoint, { params: params })
                    .then(function (resp) {
                    return _this.cache(key, resp.data, options);
                })
                    .catch(function (e) {
                    _this.logger.error(e.data.errors[0], 'Error retrieving data');
                    throw e;
                });
            };
            WebCache.$inject = [
                'Store',
                'cache',
                'SessionService',
                'WebCacheConfig',
                '$q',
                'crypto',
                '$http',
                'NetworkManager',
                'logger',
            ];
            return WebCache;
        }());
        offline.WebCache = WebCache;
        angular.module('consolo').service('WebCache', WebCache);
    })(offline = consolo.offline || (consolo.offline = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var options;
    (function (options_1) {
        angular.module('consolo').component('miniOfficesSelect', {
            bindings: { onSelect: '&', selectedIds: '<?' },
            templateUrl: '/y/templates/options/mini-offices-select.html',
            controller: 'MiniOfficesSelectController as vm',
        });
        var MiniOfficesSelectController = /** @class */ (function () {
            function MiniOfficesSelectController($http, options) {
                this.$http = $http;
                this.options = options;
                this.selectedOrgs = [];
            }
            MiniOfficesSelectController.prototype.$onInit = function () {
                var _this = this;
                this.orgs = this.options.memCache.get('org') || [];
                this.offices = this.options.memCache.get('offices') || [];
                if (this.orgs.length === 0) {
                    this.$http.get('/api/v1/org').then(function (resp) {
                        _this.orgs = resp['data']
                            .filter(function (group) {
                            return group['type'] !== 'Office';
                        })
                            .reduce(function (a, group) {
                            return a.concat(group['items']);
                        }, []);
                        _this.options.memCache.put('org', _this.orgs);
                    });
                }
                if (this.offices.length === 0) {
                    this.$http.get('/api/v1/offices?org_tree=true').then(function (resp) {
                        _this.offices = resp['data'];
                        _this.options.memCache.put('offices', _this.offices);
                    });
                }
            };
            MiniOfficesSelectController.prototype.selectOffices = function () {
                this.onSelect({ ids: this.selectedIds });
            };
            MiniOfficesSelectController.prototype.selectOrgs = function () {
                var _this = this;
                this.selectedIds = this.selectedOrgs
                    .reduce(function (a, org) {
                    var officeIds = _this.offices
                        .filter(function (o) {
                        return o.org_ids.indexOf(org.id) > -1;
                    })
                        .filter(function (o) {
                        return a.indexOf(o.id) === -1;
                    });
                    return a.concat(officeIds);
                }, [])
                    .map(function (o) {
                    return o.id;
                });
                this.onSelect({ ids: this.selectedIds });
            };
            MiniOfficesSelectController.$inject = ['$http', 'options'];
            return MiniOfficesSelectController;
        }());
        options_1.MiniOfficesSelectController = MiniOfficesSelectController;
        angular.module('consolo').controller('MiniOfficesSelectController', MiniOfficesSelectController);
    })(options = consolo.options || (consolo.options = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('options', ['$resource', 'deferredResult', 'cache', '$cacheFactory', '$q', Factory]);
    function Factory($resource, deferredResult, cache, $cacheFactory, $q) {
        var resource = $resource('/api/v1/options', null, { query: { method: 'GET', isArray: false } }), cacheHours = 6;
        var memCache = $cacheFactory('options');
        var warmCachePromise;
        return {
            get: get,
            warmCache: warmCache,
            updateCachedOptions: updateCachedOptions,
            memCache: memCache,
            warmCachePromise: warmCachePromise,
        };
        function updateCachedOptions(alsoInclude) {
            for (var i = 0; i < alsoInclude.length; i++) {
                if (cachedOptions.indexOf(alsoInclude[i]) === -1) {
                    console.info(alsoInclude[i] + ' missing in cachedOptions!!');
                    cachedOptions.push(alsoInclude[i]);
                }
            }
        }
        // Returns a deferred result with the promise stored in $promise. The promise resolves to an array of types.
        function get(type, force) {
            var promise;
            if (force) {
                promise = getFromServer(type, true);
            }
            else {
                promise =
                    cachedOptions.indexOf(type) > -1 ? getFromCache(type, false) : getFromServer(type, false);
            }
            return deferredResult(promise);
        }
        function getFromCache(type, forceCache) {
            return cache(resource, 'options', { maxAge: 60 * cacheHours, force: forceCache })
                .query({ 'types[]': cachedOptions })
                .$promise.then(function (options) {
                if (options[type] || forceCache) {
                    return options[type];
                }
                else {
                    // An option may have been added to cachedOptions *after* it was cached - so we need to refresh
                    return getFromCache(type, true);
                }
            });
        }
        function getFromServer(type, force) {
            if (memCache.get(type) && !force) {
                return $q.when(memCache.get(type));
            }
            else {
                return resource.query({ 'types[]': [type] }).$promise.then(function (options) {
                    memCache.put(type, options[type]);
                    return options[type];
                });
            }
        }
        function warmCache() {
            warmCachePromise = cache(resource, 'options', { maxAge: 60 * cacheHours }).query({
                'types[]': cachedOptions,
            }).$promise;
            return warmCachePromise;
        }
        // This is everything available at /api/v1/options, excluding those that have
        //   100's or 1000's of members. Uncomment them as you need to precache them.
        // NOTE Precaching is intended only for offline use or for options that are used *all over the
        //   place*. Cache judiciously.
    }
    var cachedOptions = [
        'activity_ratings',
        'activity2_ratings',
        'administration_sites',
        //'addendum_reasons',
        //'adjustment_selections',
        'ambulatory_ratings',
        //'audit_event_types',
        'base_roles',
        //'bereavement_intervention_contact_types',
        //'billing_overrides',
        //'billing_receivers',
        'bolus_doses',
        'bowel_regimen_initiated',
        'breakthrough_pain_frequencies',
        'breakthrough_pain_quantities',
        //'care_change_checklists',
        //'care_change_types',
        'care_plan_category',
        //'care_plan_template_constants',
        'care_plan_template_sources',
        //'cellular_providers',
        //'cdc_races',
        //'claim_taxonomies',
        'clinical_care_types',
        //'code_statuses',
        //'comorbids',
        // 'contact_type',
        'cope_risk_levels',
        'developmental_pediatric_levels',
        //'diagnosis_templates',
        //'disaster_acuities',
        'discomfort_no_answer_reasons',
        'discussion_options',
        //'document_types',
        'dosage_forms',
        //'dosage_units',
        'drainage_amounts',
        //'durations',
        'ecog_scale',
        'edema_intensities',
        'elimination_catheters',
        //'employment_statuses',
        //'era_payment_noncovered_codes',
        //'ethnicities',
        //'facility_types',
        'fall_locations',
        'fall_outcomes',
        'fall_types',
        'family_relationships',
        'fast_scale',
        'feeding_deliveries',
        'feeding_related_problems',
        'feeding_tube_patencies',
        'feeding_tubes',
        'friction_ratings',
        'gait_ratings',
        //'genders',
        //'generic_medication_fill_hcpcs_codes',
        //'hospice_aide_task_categories',
        //'hospice_aide_tasks',
        //'human_resource_topics',
        'immunization_registry_statuses',
        'immunization_information_sources',
        'incontinence',
        'inpatient_unit_note_activities',
        'inpatient_unit_note_rationales',
        //'insurance_carriers',
        'intensities',
        //'intervention_template_constants',
        'iv_types',
        'karnofsky_scale',
        'lab_attempts',
        //'languages',
        'left_right',
        'level_of_cares',
        'level_of_consciousnesses',
        'level_of_functionings',
        'location_types',
        //'mailable_reports',
        'mar_reasons',
        //'marital_statuses',
        //'medicare_caps',
        //'medicare_secondary_value_codes',
        //'medication_classification_names',
        'mental_ratings',
        //'military_branches',
        //'military_eras',
        'missed_visit_reasons',
        'mitchel_scale',
        'mobility_ratings',
        'moisture_ratings',
        //'months',
        //'mrn_chunks',
        //'non_clinical_care_types',
        'neuro_level_of_consciousness',
        'newborn_fontanels',
        'nutrition_appetites',
        'nutrition_diets',
        'nutrition_ratings',
        'nyha_scale',
        //'office_associations',
        //'office_document_types',
        'o2_usage',
        'opioid_initiated',
        'oxygenation_respiratory_equipment',
        'outcomes',
        'pain_frequencies',
        //'pain_intensities',
        'pain_types',
        'palliative_scale',
        // 'patient_care_status',
        'patient_family_engagements',
        //'payment_types',
        'pediatric_neuro_development',
        'postural_positions',
        //'priorities',
        //'prn_reasons',
        //'procedure_code_descriptions',
        //'professional_payer_types',
        'publicity_codes',
        'pulse_qualities',
        'qualities',
        'quality_sources',
        //'races',
        //'referral_account_types',
        //'referral_contact_types',
        //'referral_statuses',
        //'referral_types',
        //'relationships',
        //'religions',
        //'reports',
        'respiratory_treatment_types',
        'scale_type',
        'sensory_ratings',
        'skin_colors',
        'skin_moisture',
        'skin_temperature',
        //'states',
        'teaching_learning_topics',
        'temp_types',
        // 'title',
        'therapy_types',
        'topical_sites',
        //'training_tags',
        //'transaction_types',
        //'type_of_bills',
        'visit_classification',
        'vaccination_routes',
        'visit_types',
        'wound_stages',
        //'write_off_selections',
        'discomfort',
        'discomfort_no_answer_reasons',
        'flacc_face',
        'flacc_legs',
        'flacc_activity',
        'flacc_cry',
        'flacc_consolability',
        'painad_body_lang',
        'painadd_breathing_ratings',
        'painadd_consolability',
        'painadd_facial',
        'painadd_neg_voc',
        'pain_frequencies',
        'pain_ratings',
        'pain_types',
        'wound_types',
        'yes_no_patient_declined',
        'yes_no',
        'edmonton_completed_by_options',
        'administered_units',
        'action_codes',
        'substance_manufacturer_names',
        'substance_refusal_reasons',
        'observation_identifiers',
        'observation_value_types',
        'patient_eligibility_categories',
        'observation_value_sets',
        'vis_barcodes',
        'cvx_codes',
        'infection_statuses',
        'agitation_restlessness_options',
        'alertness_attention_options',
        'hours_asleep_options',
        'interactivity_options',
        'shortness_of_breath_options',
        'communication_ability',
        'will_pay',
        'will_pay_readonly',
        'hiv_opportunistic_infections',
        'cancer_stages',
        'cancer_treatments',
        'cbleeding_options',
        'cheart_failure_options',
        'other_indications_of_pain',
        'nutrition_appetites_options',
        'eating_options',
        'indications_of_weight_loss_options',
        'diagnosis_information_obtained_options',
        'assessment_type_options',
        'umbilical_cord_options',
        'abdominal_symptoms_options',
        'bowel_sounds_options',
        'decision_options',
        'role_options',
        'preferences_changed',
        'evaluation',
        'types_of_discharge',
        'report_received_from',
        'ipu_discharge_plan',
        'aide_services',
        'people_involved',
        'contact_agency_reviewed',
        "neurology_suck",
        "neurology_cry",
        'symptoms_associated_with_pain_options',
        'nutrition_medications_or_chemotherapy_options',
        'nutrition_cannot_tolerate_options',
        'inactivity_decreased_activity_contributing_options',
        "nursing_treatments",
        "location_of_care_environmental_safety",
        "nursing_safety",
        "electrical_equipment",
        "homebound_status",
        "activities_of_daily_living",
        "caregiver",
        "evacuation",
        "administration_safety_issues",
        "medications",
        'peripheral_pulses',
        'purpose_of_visit_options',
        'person_type_options',
        'services_options',
        'wound_options',
        'food_consistency_options',
        'pediatric_mobility_options',
        'any_exposure_options',
        'war_era_options',
        'additional_veteran_assessment_symptom_options',
        'veteran_assessment_come_to_serve_options',
        'military_experience_options',
        'prisoner_of_war_solitary_with_others_options',
        'prisoner_of_war_duration_of_captivity_days',
        'prisoner_of_war_duration_of_captivity_months',
        'prisoner_of_war_duration_of_captivity_years',
        'wound_stage_options',
    ];
})();

var consolo;
(function (consolo) {
    var patientSignupWindows;
    (function (patientSignupWindows) {
        var PatientSignupSidebarController = /** @class */ (function () {
            function PatientSignupSidebarController(windowService, logger, lookup) {
                this.windowService = windowService;
                this.logger = logger;
                this.lookup = lookup;
                this.adding = false;
                this.saving = false;
            }
            PatientSignupSidebarController.prototype.query = function (term) {
                return this.lookup.patients({ q: term, all_offices: true }).$promise;
            };
            PatientSignupSidebarController.prototype.selectPatient = function (patient, $event) {
                if (patient) {
                    this.selectedPatient = null;
                    this.signupWindow = {
                        patient_id: patient.id,
                        email: patient.email,
                        name: patient.name,
                    };
                }
            };
            PatientSignupSidebarController.prototype.save = function () {
                var _this = this;
                this.errors = null;
                this.saving = true;
                this.windowService.create(this.signupWindow['patient_id'], this.signupWindow['email']).then(function (newWindow) {
                    _this.saving = false;
                    _this.signupWindow = null;
                    _this.logger.success('Patient window created');
                }, function (resp) {
                    _this.saving = false;
                    _this.errors = resp.data.errors;
                });
            };
            PatientSignupSidebarController.prototype.cancel = function () {
                this.signupWindow = null;
            };
            PatientSignupSidebarController.$inject = ['PatientWindowsSignupService', 'logger', 'Lookup'];
            return PatientSignupSidebarController;
        }());
        angular
            .module('consolo')
            .controller('PatientSignupSidebarController', PatientSignupSidebarController);
    })(patientSignupWindows = consolo.patientSignupWindows || (consolo.patientSignupWindows = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientSignupWindows;
    (function (patientSignupWindows) {
        var PatientSignupWindowsController = /** @class */ (function () {
            function PatientSignupWindowsController(windowService, $scope) {
                var _this = this;
                this.windowService = windowService;
                this.loading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
                this.loadPage(1);
                windowService.onCreate(function (newWindow) {
                    _this.currentPage.items.unshift(newWindow);
                }, $scope);
            }
            PatientSignupWindowsController.prototype.loadPage = function (n) {
                var _this = this;
                this.loading = true;
                this.windowService.query(n).then(function (resp) {
                    _this.loading = false;
                    _this.currentPage = {
                        page: parseInt(resp.headers('X-Page'), 10),
                        totalPages: parseInt(resp.headers('X-Total-Pages'), 10),
                        items: resp.data,
                    };
                }, function (resp) {
                    _this.loading = false;
                });
            };
            PatientSignupWindowsController.prototype.delete = function (signupWindow) {
                if (confirm("Are you sure you want to cancel the signup window for " + signupWindow.patient_name + "?")) {
                    this.windowService.delete(signupWindow.id);
                    var idx = this.currentPage.items.indexOf(signupWindow);
                    this.currentPage.items.splice(idx, 1);
                }
            };
            PatientSignupWindowsController.$inject = ['PatientWindowsSignupService', '$scope'];
            return PatientSignupWindowsController;
        }());
        angular
            .module('consolo')
            .controller('PatientSignupWindowsController', PatientSignupWindowsController);
    })(patientSignupWindows = consolo.patientSignupWindows || (consolo.patientSignupWindows = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientSignupWindows;
    (function (patientSignupWindows) {
        var PatientWindowsSignupService = /** @class */ (function () {
            function PatientWindowsSignupService($http) {
                this.$http = $http;
                this.createListeners = [];
            }
            PatientWindowsSignupService.prototype.query = function (page) {
                return this.$http.get('/api/v2/patient_signup_windows', { params: { page: page } });
            };
            PatientWindowsSignupService.prototype.create = function (patientId, patientEmail) {
                var _this = this;
                return this.$http
                    .post('/api/v2/patient_signup_windows', {
                    patient_id: patientId,
                    email: patientEmail,
                })
                    .then(function (resp) {
                    _this.createListeners.forEach(function (f) {
                        f(resp.data);
                    });
                    return resp.data;
                });
            };
            PatientWindowsSignupService.prototype.onCreate = function (f, $scope) {
                var _this = this;
                this.createListeners.push(f);
                $scope.$on('$destroy', function () {
                    var idx = _this.createListeners.indexOf(f);
                    _this.createListeners.splice(idx, 1);
                });
            };
            PatientWindowsSignupService.prototype.delete = function (id) {
                return this.$http.delete("/api/v2/patient_signup_windows/" + id);
            };
            PatientWindowsSignupService.$inject = ['$http'];
            return PatientWindowsSignupService;
        }());
        patientSignupWindows.PatientWindowsSignupService = PatientWindowsSignupService;
        angular.module('consolo').service('PatientWindowsSignupService', PatientWindowsSignupService);
    })(patientSignupWindows = consolo.patientSignupWindows || (consolo.patientSignupWindows = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var PatientSignupWindowsState = /** @class */ (function () {
        function PatientSignupWindowsState($stateProvider) {
            $stateProvider.state('patient-signup-windows', {
                url: '/patient-signup-windows',
                data: { title: 'Add a patient' },
                views: {
                    'main-content': {
                        templateUrl: '/y/templates/patient-signup-windows/patient-signup-windows.html',
                        controller: 'PatientSignupWindowsController as vm',
                    },
                    sidebar: {
                        templateUrl: '/y/templates/patient-signup-windows/patient-signup-sidebar.html',
                        controller: 'PatientSignupSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('view', 'patient_signup_windows');
                        },
                    ],
                },
            });
        }
        PatientSignupWindowsState.$inject = ['$stateProvider'];
        return PatientSignupWindowsState;
    }());
    angular.module('consolo').config(PatientSignupWindowsState);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientSignupWindows;
    (function (patientSignupWindows) {
        'use strict';
        function signupWindowStatusFactory($sce) {
            return function signupWindowStatus(x) {
                if (x.signed_up_at) {
                    return $sce.trustAsHtml('Complete');
                }
                else if (x.expired) {
                    return $sce.trustAsHtml('Expired');
                }
                else {
                    return $sce.trustAsHtml('Pending');
                }
            };
        }
        patientSignupWindows.signupWindowStatusFactory = signupWindowStatusFactory;
        angular
            .module('consolo')
            .filter('signupWindowStatus', ['$sce', consolo.patientSignupWindows.signupWindowStatusFactory]);
    })(patientSignupWindows = consolo.patientSignupWindows || (consolo.patientSignupWindows = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CreateReferralAccountModalController = /** @class */ (function () {
            function CreateReferralAccountModalController($scope, $mdDialog, $mdStepper, referralAccountType) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.$mdStepper = $mdStepper;
                $scope.$on('referral-account.created', function (event, referralAccount) {
                    $mdDialog.hide(referralAccount);
                });
                $scope.referralAccountType = referralAccountType;
            }
            CreateReferralAccountModalController.prototype.cancelClicked = function () {
                this.$mdDialog.cancel();
            };
            CreateReferralAccountModalController.prototype.nextClicked = function () {
                this.$mdStepper('referral-account-stepper').next();
            };
            CreateReferralAccountModalController.prototype.submitClicked = function () {
                this.$scope.$broadcast('referral-account.create');
            };
            CreateReferralAccountModalController.$inject = ['$scope', '$mdDialog', '$mdStepper', 'referralAccountType'];
            return CreateReferralAccountModalController;
        }());
        angular
            .module('consolo')
            .controller('CreateReferralAccountModalController', CreateReferralAccountModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CreateReferralContactModalController = /** @class */ (function () {
            function CreateReferralContactModalController($scope, $mdDialog, referralContactType, referralAccount) {
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                $scope.$on('referral-contact.created', function (event, referralContact) {
                    $mdDialog.hide(referralContact);
                });
                $scope.referralContactType = referralContactType;
                $scope.referralAccount = referralAccount;
                console.log('CreateReferralContactModalController referral account: %O', referralAccount);
            }
            CreateReferralContactModalController.prototype.cancelClicked = function () {
                this.$mdDialog.hide();
            };
            CreateReferralContactModalController.prototype.submitClicked = function () {
                this.$scope.$broadcast('referral-contact.create');
            };
            CreateReferralContactModalController.$inject = [
                '$scope',
                '$mdDialog',
                'referralContactType',
                'referralAccount',
            ];
            return CreateReferralContactModalController;
        }());
        angular
            .module('consolo')
            .controller('CreateReferralContactModalController', CreateReferralContactModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CurrentPatientService = /** @class */ (function () {
            function CurrentPatientService(Patient, cache, $q, NetworkManager, logger, $http, $stateParams) {
                var _this = this;
                this.Patient = Patient;
                this.cache = cache;
                this.$q = $q;
                this.NetworkManager = NetworkManager;
                this.logger = logger;
                this.$http = $http;
                this.$stateParams = $stateParams;
                this.refreshingPatient = false;
                this.fetchingPatientId = 0;
                this.today = new Date();
                NetworkManager.onlineHandler.onEvent('CurrentPatientService', function () {
                    if (_this.patient_id) {
                        _this.refreshPatient();
                    }
                });
            }
            CurrentPatientService.prototype.getPatient = function (id, force) {
                var _this = this;
                if (!this.patient || this.patient_id !== id || force) {
                    var patientCache = this.cache(this.Patient, 'patients', { encrypt: true, force: true });
                    if (this.fetchingPatientId === id) {
                        console.log('already fetching this patient?', this.fetchingPatientId, id);
                        return this.fetchingPatientPromise.promise;
                    }
                    else {
                        this.fetchingPatientId = id;
                        this.fetchingPatientPromise = this.$q.defer();
                        return patientCache
                            .getComplete({ id: id })
                            .$promise.then(function (item) {
                            var _a;
                            _this.patient_id = id;
                            _this.patient = item.patient;
                            _this.empi_id = ((_a = item.empi) === null || _a === void 0 ? void 0 : _a.empi_id) || null;
                            _this.patient.dashboard = item.dashboard;
                            _this.patient.menu = item.menu;
                            _this.patient.misc_data = {};
                            _this.patient.misc_data.missed_visits = [];
                            _this.fetchingPatientPromise.resolve(_this.patient);
                            console.log('getPatient', _this.patient);
                            return _this.patient;
                        }, function () {
                            //patient.getComplete not in the cache, check for patient.get before erroring
                            return patientCache.get({ id: id }).$promise.then(function (item) {
                                _this.patient_id = id;
                                _this.patient = item;
                                _this.patient.dashboard = null;
                                _this.patient.menu = null;
                                _this.fetchingPatientPromise.resolve(_this.patient);
                                return _this.patient;
                            }, function (error) {
                                _this.fetchingPatientPromise.reject(error);
                                throw error;
                            });
                        })
                            .finally(function () {
                            _this.fetchingPatientId = 0;
                        });
                    }
                }
                else {
                    return this.$q(function (resolve) {
                        resolve(_this.patient);
                    });
                }
            };
            CurrentPatientService.prototype.refreshPatient = function () {
                var _this = this;
                //Handle multiple calls to refreshPatient using the same promise, ie when coming online
                if (!this.refreshingPatient) {
                    this.refreshingPatient = true;
                    this.refreshPromise = this.$q.defer();
                    this.getPatient(this.patient_id, true)
                        .then(function () {
                        _this.refreshPromise.resolve(_this.patient);
                    }, function (e) {
                        _this.refreshPromise.reject(e);
                    })
                        .finally(function () {
                        _this.refreshingPatient = false;
                    });
                }
                return this.refreshPromise.promise;
            };
            CurrentPatientService.prototype.refreshTasks = function (task, deleting) {
                if (deleting === void 0) { deleting = false; }
                var tasks = this.patient.dashboard.tasks;
                for (var i = 0; i < tasks.length; i++) {
                    if (tasks[i].id === task.id) {
                        if (deleting) {
                            tasks.splice(i, 1);
                            return true;
                        }
                        else {
                            tasks[i] = task;
                            return true;
                        }
                    }
                }
                tasks.push(task);
            };
            CurrentPatientService.prototype.setConfigured = function (type) {
                switch (type) {
                    case PatientConfigureTopics.care_plan_problem: // 0
                        this.patient.menu.configured_care_plan_problem = true;
                        break;
                    case PatientConfigureTopics.assignment: // 1
                        this.patient.menu.configured_assignment = true;
                        break;
                }
            };
            CurrentPatientService.prototype.refreshMissedVisits = function (missed_visit, deleting) {
                if (deleting === void 0) { deleting = false; }
                if (missed_visit.patient_time) {
                    // subtable 1
                    missed_visit.patient_time_in_date = consolo.common.dateOnly(new Date(missed_visit.patient_time.in_datetime));
                    missed_visit.patient_time_in = consolo.common.timeOnly(new Date(missed_visit.patient_time.in_datetime));
                    missed_visit.patient_time_out = consolo.common.timeOnly(new Date(missed_visit.patient_time.out_datetime));
                    // subtable 2
                    if (missed_visit.patient_time.travel_start_datetime) {
                        missed_visit.patient_time_travel_start = consolo.common.timeOnly(new Date(missed_visit.patient_time.travel_start_datetime));
                    }
                    if (missed_visit.patient_time.travel_stop_datetime) {
                        missed_visit.patient_time_travel_stop = consolo.common.timeOnly(new Date(missed_visit.patient_time.travel_stop_datetime));
                    }
                    missed_visit.patient_time_clinical_care_type = missed_visit.patient_time.clinical_care_type;
                    // subtable 3
                    missed_visit.patient_time_mileage = missed_visit.patient_time.mileage.toString();
                    missed_visit.patient_time_out_of_pocket = missed_visit.patient_time.out_of_pocket.toString();
                    missed_visit.patient_time_comments = missed_visit.patient_time.comments;
                }
                var missed_visits = this.patient.misc_data.missed_visits;
                for (var i = 0; i < missed_visits.length; i++) {
                    if (missed_visits[i].id === missed_visit.id) {
                        if (deleting) {
                            missed_visits.splice(i, 1);
                            return true;
                        }
                        else {
                            missed_visits[i] = missed_visit;
                            return true;
                        }
                    }
                }
                missed_visits.push(missed_visit);
            };
            CurrentPatientService.prototype.isPatientPresent = function () {
                return this.$stateParams.patientId;
            };
            CurrentPatientService.prototype.get = function (patientId) {
                return this.$http.get('/api/v1/patients/' + patientId, {}).then(function (data) {
                    return data.data;
                }, function (errors) {
                    console.warn(errors);
                    return null;
                });
            };
            CurrentPatientService.prototype.getFormPatient = function (patientId) {
                var _this = this;
                return this.$http.get('/api/v1/patients/' + patientId + '/view', {}).then(function (data) {
                    _this.formPatient = new consolo.domain.FormPatient(data.data);
                    return _this.formPatient;
                }, function (errors) {
                    console.warn(errors);
                    return null;
                });
            };
            CurrentPatientService.$inject = [
                'Patient',
                'cache',
                '$q',
                'NetworkManager',
                'logger',
                '$http',
                '$stateParams',
            ];
            return CurrentPatientService;
        }());
        services.CurrentPatientService = CurrentPatientService;
        var PatientConfigureTopics;
        (function (PatientConfigureTopics) {
            PatientConfigureTopics[PatientConfigureTopics["care_plan_problem"] = 0] = "care_plan_problem";
            PatientConfigureTopics[PatientConfigureTopics["assignment"] = 1] = "assignment";
        })(PatientConfigureTopics = services.PatientConfigureTopics || (services.PatientConfigureTopics = {}));
        angular.module('consolo').service('CurrentPatient', CurrentPatientService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientDashboardController = /** @class */ (function () {
            function PatientDashboardController(CurrentPatient, $mdMedia, $mdSidenav, NetworkManager, $state, $mdDialog, Task, PdfGenerator, Session, reconciliationService, $http, $timeout) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.NetworkManager = NetworkManager;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.Task = Task;
                this.PdfGenerator = PdfGenerator;
                this.Session = Session;
                this.reconciliationService = reconciliationService;
                this.$http = $http;
                this.$timeout = $timeout;
                this.showCareInsights = false;
                this.careInsightsApi = '/api/v1/careinsights'; // PLACEHOLDER api endpoint currently unknown
                this.online = NetworkManager.isOnline();
                NetworkManager.onlineHandler.onEvent('PatientDashboardController', function () {
                    _this.online = true;
                    _this.CurrentPatient.refreshPatient().then(function () {
                        _this.setPatient();
                    });
                });
                NetworkManager.offlineHandler.onEvent('PatientDashboardController', function () {
                    _this.online = false;
                });
                this.setPatient();
                this.setUserDashboardUrl();
                this.setCareInsights();
                // TODO: make this into an object w/ icons & colors
                this.icons = {
                    Charts: 'action:assignment',
                    'Care Plans': 'social:poll',
                    Claims: 'editor:attach_money',
                    Medications: 'maps:local_pharmacy',
                    Volunteer: 'action:assignment_ind',
                    Bereavement: 'maps:local_florist',
                };
                this.colors = {
                    Charts: '#FFC107',
                    'Care Plans': '#BA68C8',
                    Claims: '#81C784',
                    Medications: '#64B5F6',
                    Volunteer: '#FF8A65',
                    Bereavement: '#AED581',
                };
                this.background_colors = {
                    Charts: '#FFF8E1',
                    'Care Plans': '#F3E5f5',
                    Claims: '#E8F5E9',
                    Medications: '#E3F2FD',
                    Volunteer: '#FBE9E7',
                    Bereavement: '#F1F8E9',
                };
                // Check for IO Hub Updates
                if (this.online && this.patient.io_hub) {
                    this.reconciliationService
                        .checkIOHubUpdates(this.patient.id)
                        .then(function (response) {
                        if (response.data.available) {
                            _this.ioHubUpdateAvailable = response.data.available;
                        }
                    })
                        .catch(function (error) {
                        console.log(error);
                        throw error;
                    });
                }
            }
            PatientDashboardController.prototype.setUserDashboardUrl = function () {
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
            };
            PatientDashboardController.prototype.setCareInsights = function () {
                var _this = this;
                this.showCareInsights = this.Session.get().agency.has_careinsights;
                if (this.showCareInsights) {
                    this.revocationLikelihood = { value: 'Loading', level: 'loading' };
                    this.mortalityLikelihood = { value: 'Loading', level: 'loading' };
                    // Currently unsure what this response will look like and whether or not we will get a color level
                    //    straight from the API or if we will need to do a conversion
                    //    When this API completes place the values in to the revocationLikilihood.value and mortalityLIkelihood.value
                    //    The level then needs to be set to 'normal', 'red', 'green', 'orange' for the CSS class to be properly applied
                    // this.$http.get(this.careInsightsApi, {params: {patient_id: this.CurrentPatient.patient_id}}).then(resp => {
                    // });
                    // Test to replicate what the API will do once the API has finished loading
                    // Remove when API implemented
                    this.$timeout(function () {
                        _this.revocationLikelihood.value = 'Coming Soon';
                        _this.mortalityLikelihood.value = 'Coming Soon';
                        _this.revocationLikelihood.level = _this.mortalityLikelihood.level = 'orange';
                    }, 5000);
                }
            };
            PatientDashboardController.prototype.setPatient = function () {
                this.patient = this.CurrentPatient.patient;
                this.dashboard = this.CurrentPatient.patient.dashboard;
                if (this.dashboard) {
                    this.dashboard.dashboard_alerts.forEach(function (item) {
                        if (item.category === 'CarePlans') {
                            item.category = 'Care Plans';
                        }
                    });
                }
            };
            PatientDashboardController.prototype.parseCarePlan = function (text, isType) {
                var pos = text.indexOf(" '");
                if (isType) {
                    if (pos > 0) {
                        return text.substring(0, pos);
                    }
                    else {
                        return this.parseGrammar(text);
                    }
                }
                else {
                    return this.parseGrammar(text.substring(pos));
                }
            };
            PatientDashboardController.prototype.parseGrammar = function (text) {
                text = text.replace('a A', 'an A');
                text = text.replace('a E', 'an E');
                text = text.replace('a I', 'an I');
                text = text.replace('a O', 'an O');
                text = text.replace('a U', 'an U');
                return text;
            };
            PatientDashboardController.prototype.editTask = function (id, idx, markComplete) {
                var _this = this;
                this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/sections/tasks/task.html',
                    controller: 'PatientTaskController as vm',
                    locals: { markComplete: markComplete, taskId: id },
                })
                    .then(function (task) {
                    if (task) {
                        if (markComplete) {
                            _this.dashboard.tasks.splice(idx, 1);
                        }
                        else {
                            _this.dashboard.tasks[idx] = task;
                        }
                    }
                });
            };
            PatientDashboardController.prototype.dischargeSummaryEdit = function (id) {
                this.$state.go('patients.discharge-summary.main', {
                    patientId: this.patient.id,
                    dischargeId: id,
                });
            };
            PatientDashboardController.prototype.dischargeSummaryPDF = function (id) {
                this.PdfGenerator.printDischargeSummaries([id], this.patient.id);
            };
            PatientDashboardController.prototype.dischargeSummaryFax = function (id) {
                this.$state.go('patients.fax.discharge-summaries', {
                    patientId: this.patient.id,
                    dischargeSummaryId: id,
                });
            };
            PatientDashboardController.prototype.openIOHubModal = function (patient, $event) {
                var _this = this;
                this.reconciliationService
                    .createIOHubImport(patient.id)
                    .then(function (response) {
                    _this.$state.go('reconciliation.data-reconciliation', { importId: response.data.id });
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            PatientDashboardController.$inject = [
                'CurrentPatient',
                '$mdMedia',
                '$mdSidenav',
                'NetworkManager',
                '$state',
                '$mdDialog',
                'Task',
                'PdfGenerator',
                'SessionService',
                'reconciliationService',
                '$http',
                '$timeout'
            ];
            return PatientDashboardController;
        }());
        angular.module('consolo').controller('PatientDashboardController', PatientDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientExportController = /** @class */ (function () {
            function PatientExportController($stateParams, $http, $filter, $mdDialog, logger, Session) {
                this.$stateParams = $stateParams;
                this.$http = $http;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.Session = Session;
                this.problemListEnabled = false;
                this.pdfOptions = {
                    admission_notes: 0,
                    bereavement_care_plans: 0,
                    care_level_changes: 0,
                    claims: 0,
                    indicators: 0,
                    medication_administration_records: 0,
                    volunteer_care_notes: 0,
                    volunteer_request_notes: 0,
                    assignments: 0,
                    bereavement_discharges: 0,
                    care_plan_problems: 0,
                    clinical_charts: 0,
                    physician_orders: 0,
                    idg_snapshots: 0,
                    missed_visits: 0,
                    volunteer_care_plans: 0,
                    audits: 0,
                    billing_demographics: 0,
                    certifications: 0,
                    dme_orders: 0,
                    medications: 0,
                    payer_groups: 0,
                    volunteer_requests: 0,
                    admin_notes: 0,
                    unrelated_explanations: 0,
                    eligibility_inquiries: 0,
                    visit_task_lists: 0,
                    smoking_statuses: 0,
                    problem_list: 0,
                };
                this.problemListEnabled = this.Session.get().agency.problem_list_enabled;
            }
            PatientExportController.prototype.exportPdf = function () {
                var _this = this;
                if (this.pdfStartDate) {
                    this.pdfOptions.start_date = this.$filter('date')(this.pdfStartDate, 'yyyy-MM-dd');
                }
                if (this.pdfEndDate) {
                    this.pdfOptions.end_date = this.$filter('date')(this.pdfEndDate, 'yyyy-MM-dd');
                }
                this.pdfOptions.patient_id = this.$stateParams.patientId;
                this.$http.post('/api/v1/export/patient/pdf', this.pdfOptions).then(function () {
                    _this.logger.success('Job has been queued for processing');
                    _this.$mdDialog.hide();
                }, function (data) {
                    _this.logger.error(data.data.errors.join('. '));
                });
            };
            PatientExportController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            PatientExportController.prototype.selectAll = function () {
                var _this = this;
                Object.keys(this.pdfOptions).forEach(function (attr) {
                    _this.pdfOptions[attr] = 'true';
                });
            };
            PatientExportController.prototype.selectNone = function () {
                var _this = this;
                Object.keys(this.pdfOptions).forEach(function (attr) {
                    _this.pdfOptions[attr] = 0;
                });
            };
            PatientExportController.$inject = ['$stateParams', '$http', '$filter', '$mdDialog', 'logger', 'SessionService'];
            return PatientExportController;
        }());
        angular.module('consolo').controller('PatientExportController', PatientExportController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ConsoloPatientSectionManagerService = /** @class */ (function () {
            function ConsoloPatientSectionManagerService(cache, Patient, $filter, Session, $state, $stateParams, $http, logger, $mdDialog, DeviceSettings, CurrentPatient, PdfGenerator, UnrelatedExplanationService, PlanOfCareService, SmokingStatusService, permissionsService, $q, PatientMainService, persistenceStore, $mdMedia, $mdSidenav, BeaconService, pdfer) {
                var _a, _b, _c;
                this.cache = cache;
                this.Patient = Patient;
                this.$filter = $filter;
                this.Session = Session;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.DeviceSettings = DeviceSettings;
                this.CurrentPatient = CurrentPatient;
                this.PdfGenerator = PdfGenerator;
                this.UnrelatedExplanationService = UnrelatedExplanationService;
                this.PlanOfCareService = PlanOfCareService;
                this.SmokingStatusService = SmokingStatusService;
                this.permissionsService = permissionsService;
                this.$q = $q;
                this.PatientMainService = PatientMainService;
                this.persistenceStore = persistenceStore;
                this.$mdMedia = $mdMedia;
                this.$mdSidenav = $mdSidenav;
                this.BeaconService = BeaconService;
                this.pdfer = pdfer;
                this.patientSections = Array(0);
                this.offlineSections = Array(0);
                this.permissions = {};
                this.has_encounter_charting = false;
                this.problemListEnabled = false;
                this.hasImplantableDevices = false;
                this.hasAmendments = false;
                this.hasBillableCodeOffice = false;
                this.showEHiExportReport = false;
                if (CurrentPatient.patient_id &&
                    this.$stateParams.patientId &&
                    CurrentPatient.patient_id !== parseInt(this.$stateParams.patientId, 10)) {
                    this.logger.error('Invalid Patient Loaded');
                }
                this.session = this.Session.get();
                this.agency = this.session.agency;
                this.serviceLine = this.session.agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                this.hasElectronicDocuments = this.session.agency.has_electronic_documents;
                this.problemListEnabled = this.session.agency.problem_list_enabled;
                this.hasImplantableDevices = this.session.agency.has_implantable_devices;
                this.hasAmendments = this.session.agency.has_amendments;
                this.hasBillableCodeOffice = this.session.agency.has_billable_code_office;
                this.user = this.Session.getUser();
                if (this.user.flags.has_encounter_charting) {
                    this.charting_title = 'Encounter Charting';
                    this.has_encounter_charting = true;
                }
                else {
                    this.charting_title = 'Clinical Charts';
                }
                this.showEHiExportReport = ((_c = (_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.patient_documents) === null || _c === void 0 ? void 0 : _c.indexOf("electronic_health_information_export")) !== -1;
            }
            ConsoloPatientSectionManagerService.prototype.refreshMenu = function (id) {
                var _this = this;
                var patientId = id ? id : this.CurrentPatient.patient_id;
                this.PatientMainService.getMenu(patientId).then(function (menu) {
                    _this.CurrentPatient.patient.menu = menu;
                    _this.loadSections();
                });
            };
            ConsoloPatientSectionManagerService.prototype.navigateTo = function (section, type, $event) {
                var params = { patientId: this.$stateParams.patientId };
                if (section.routeParams) {
                    angular.extend(params, section.routeParams);
                }
                if (this.$mdSidenav('sidebar').isOpen()) {
                    //Always try to close this when clicked, if it's a large screen it will have a locked set and will stay open
                    this.$mdSidenav('sidebar').toggle();
                }
                if (type === 'primary') {
                    if (typeof section.route === 'function') {
                        section.route = section.route();
                    }
                    if (typeof section.url === 'function') {
                        section.url = section.url();
                    }
                    if (section.route) {
                        this.$state.go(section.route, params);
                    }
                    else if (section.url) {
                        document.location.href = this.urlParse(section.url);
                    }
                    else if (section.action) {
                        section.action();
                    }
                }
                else if (type === 'secondary') {
                    if (typeof section.secondaryRoute === 'function') {
                        section.secondaryRoute = section.secondaryRoute();
                    }
                    if (typeof section.secondaryUrl === 'function') {
                        section.secondaryUrl = section.secondaryUrl();
                    }
                    if (section.secondaryRoute) {
                        if (section.secondaryRoute === 'patients.clinical-chart-entry-new.section') {
                            params['sectionName'] = 'general';
                        }
                        this.$state.go(section.secondaryRoute, params);
                    }
                    else if (section.secondaryUrl) {
                        document.location.href = this.urlParse(section.secondaryUrl);
                    }
                    else if (section.secondaryAction) {
                        section.secondaryAction($event);
                    }
                }
            };
            ConsoloPatientSectionManagerService.prototype.getHref = function (section, type) {
                var params = { patientId: this.$stateParams.patientId };
                if (section.routeParams) {
                    angular.extend(params, section.routeParams);
                }
                var href = '';
                if (type === 'primary') {
                    if (typeof section.route === 'function' || typeof section.url === 'function') {
                        href = section.route();
                        if (href) {
                            href = this.$state.href(href, params);
                        }
                        else {
                            href = this.urlParse(section.url());
                        }
                    }
                    else if (section.route) {
                        href = this.$state.href(section.route, params);
                    }
                    else if (section.url) {
                        href = this.urlParse(section.url);
                    }
                    else if (section.action && section.defaultRoute) {
                        href = this.$state.href(section.defaultRoute, params);
                    }
                }
                else if (type === 'secondary') {
                    if (typeof section.secondaryRoute === 'function' ||
                        typeof section.secondaryUrl === 'function') {
                        href = section.secondaryRoute();
                        if (href) {
                            if (href === 'patients.clinical-chart-entry-new.section') {
                                params['sectionName'] = 'general';
                            }
                            href = this.$state.href(href, params);
                        }
                        else {
                            href = this.urlParse(section.secondaryUrl());
                        }
                    }
                    else if (section.secondaryRoute) {
                        if (section.secondaryRoute === 'patients.clinical-chart-entry-new.section') {
                            params['sectionName'] = 'general';
                        }
                        href = this.$state.href(section.secondaryRoute, params);
                    }
                    else if (section.secondaryUrl) {
                        href = this.urlParse(section.secondaryUrl);
                    }
                    else if (section.secondaryAction && section.defaultSecondaryRoute) {
                        href = this.$state.href(section.defaultSecondaryRoute, params);
                    }
                }
                return href;
            };
            ConsoloPatientSectionManagerService.prototype.urlParse = function (url) {
                url = url.replace(':patientId', this.$stateParams.patientId);
                return url;
            };
            ConsoloPatientSectionManagerService.prototype.loadSections = function () {
                if (this.CurrentPatient.patient.menu) {
                    this.patientSections = this.getSections();
                }
                else {
                    this.patientSections = [];
                }
                this.offlineSections = this.loadOfflineMenu();
                return { online: this.patientSections, offline: this.offlineSections };
            };
            ConsoloPatientSectionManagerService.prototype.chartingCookie = function () {
                return this.DeviceSettings.getValue(consolo.deviceSettings.Pages.cookie, consolo.deviceSettings.Settings.charting_tablet_mode);
            };
            ConsoloPatientSectionManagerService.prototype.referralThumbDownTooltip = function () {
                if (this.isPalliative) {
                    return 'Payer Information must have a primary payer configured to admit a patient';
                }
                else {
                    return ('Payer Information must have one or more HIS Payers selected in order to ' +
                        'submit HIS Records and primary payer needs to be configured to admit a patient and for a ' +
                        "patient's certification to become available.");
                }
            };
            ConsoloPatientSectionManagerService.prototype.changeInCareThumbDownTooltip = function () {
                if (this.isPalliative) {
                    return 'You must complete Payer Information before admitting a patient';
                }
                else {
                    return ('You must complete Payer Information before admitting a patient and ' +
                        ' you must select one or more HIS Payers in order to submit HIS Records.');
                }
            };
            ConsoloPatientSectionManagerService.prototype.administrationThumbDownTooltip = function () {
                if (this.isPalliative) {
                    '';
                }
                else {
                    'Hospice Assignment is incomplete and needs to be configured for a patient\'s certification to become available.';
                }
            };
            ConsoloPatientSectionManagerService.prototype.certificationThumbDown = function () {
                if (this.isPalliative) {
                    return false;
                }
                else {
                    return (!this.CurrentPatient.patient.menu.configured_certification ||
                        !this.CurrentPatient.patient.menu.configured_care_plan_problem ||
                        !this.CurrentPatient.patient.menu.configured_indicator);
                }
            };
            ConsoloPatientSectionManagerService.prototype.certificationThumbDownTooltip = function () {
                if (this.isPalliative) {
                    return 'Must complete at least one Care Plan Problem and Clinical Indicator & Diagnosis';
                }
                else {
                    return 'Must complete Certification, and at least one Care Plan Problem and Clinical Indicator & Diagnosis';
                }
            };
            ConsoloPatientSectionManagerService.prototype.loadPermissions = function () {
                var defer = this.$q.defer();
                var neededPermissions = [
                    { category: 'amendments', action: 'show' },
                    { category: 'bereavement_discharges', action: 'create' },
                    { category: 'bereavement_discharges', action: 'show' },
                    { category: 'billing_demographics', action: 'show' },
                    { category: 'care_level_changes', action: 'create' },
                    { category: 'care_plan_problems', action: 'create' },
                    { category: 'care_plan_problems', action: 'show' },
                    { category: 'clinical_charts', action: 'show_care_summary' },
                    { category: 'cqms', action: 'show' },
                    { category: 'direct_secure_messages', action: 'show' },
                    { category: 'direct_secure_messages', action: 'create' },
                    { category: 'families', action: 'create' },
                    { category: 'families', action: 'show' },
                    { category: 'hospice_aide_manager', action: 'show' },
                    { category: 'labs', action: 'show' },
                    { category: 'labs', action: 'create' },
                    { category: 'medication_administration_records', action: 'show' },
                    { category: 'patients', action: 'cc_tracking_sheet' },
                    { category: 'patients', action: 'comprehensive_assessment' },
                    { category: 'patients', action: 'fax' },
                    { category: 'patients', action: 'physician_order' },
                    { category: 'patients', action: 'postpone' },
                    { category: 'patients', action: 'referral_admission_sheet' },
                    { category: 'patients', action: 'show' },
                    { category: 'payer_groups', action: 'show' },
                    { category: 'payer_groups', action: 'create' },
                    { category: 'physician_orders', action: 'create' },
                    { category: 'physician_orders', action: 'show' },
                    { category: 'plan_of_cares', action: 'create' },
                    { category: 'plan_of_cares', action: 'show' },
                    { category: 'reports', action: 'activity_reports' },
                    { category: 'reports', action: 'patient_data_export' },
                    { category: 'reports', action: 'patient_pdf_export' },
                    { category: 'smoking_statuses', action: 'show' },
                    { category: 'smoking_statuses', action: 'create' },
                    { category: 'unrelated_explanations', action: 'create' },
                    { category: 'unrelated_explanations', action: 'show' },
                    { category: 'prior_authorizations', action: 'create' },
                    { category: 'prior_authorizations', action: 'show' },
                    { category: 'care_program_enrollments', action: 'show' },
                    { category: 'care_program_enrollments', action: 'update' },
                    { category: 'care_program_enrollments', action: 'destroy' },
                    { category: 'patient_devices', action: 'create' },
                    { category: 'patient_devices', action: 'destroy' },
                    { category: 'patient_devices', action: 'show' },
                    { category: 'patient_devices', action: 'update' },
                    { category: 'patient_devices', action: 'restore' },
                    { category: 'diagnostic_image_results', action: 'create' },
                    { category: 'diagnostic_image_results', action: 'destroy' },
                    { category: 'diagnostic_image_results', action: 'show' },
                    { category: 'diagnostic_image_results', action: 'update' },
                    { category: 'vaccinations', action: 'show' },
                    { category: 'procedures', action: 'create' },
                    { category: 'procedures', action: 'destroy' },
                    { category: 'procedures', action: 'show' },
                    { category: 'procedures', action: 'update' },
                ];
                for (var _i = 0, neededPermissions_1 = neededPermissions; _i < neededPermissions_1.length; _i++) {
                    var p = neededPermissions_1[_i];
                    this.permissions[p.category + '_' + p.action] = this.permissionsService.can(p.action, p.category);
                }
                if (!this.session.user.role_names) {
                    this.session.user.role_names = [this.session.user.default_role.name];
                }
                this.permissions.physician_drawer = this.session.user.role_names.filter(function (role) {
                    return role === 'Admin' || role === 'MD Director' || role === 'Physician';
                }).length;
                defer.resolve(true);
                return defer.promise;
            };
            ConsoloPatientSectionManagerService.prototype.loadOfflineMenu = function () {
                return [
                    {
                        title: 'Offline Menu',
                        name: 'offlinemenu',
                        icon: 'notification:sync_disabled',
                        children: [
                            {
                                title: 'Clinical Charts',
                                route: 'patients.clinical-chart',
                                secondaryRoute: 'patients.clinical-chart-entry-new.section',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Physician/Provider Orders',
                                route: 'patients.physician-orders',
                                secondaryRoute: 'patients.physician-orders.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: !this.permissions.physician_orders_show,
                                conditional_hide_new: !this.permissions.physician_orders_create,
                            },
                            {
                                title: 'Medication Info',
                                route: 'patients.medications',
                            },
                        ],
                    },
                ];
            };
            ConsoloPatientSectionManagerService.prototype.getSections = function () {
                var _this = this;
                var physicianOrderSection = {
                    title: 'Physician/Provider Orders',
                    route: 'patients.physician-orders',
                    secondaryRoute: 'patients.physician-orders.new',
                    icon: 'action:note_add',
                    iconRight: true,
                    conditional_hide: !this.permissions.physician_orders_show,
                    conditional_hide_new: !this.permissions.physician_orders_create,
                };
                var subdomain = location.hostname.split('.')[0];
                return [
                    {
                        title: 'Referral Info',
                        name: 'referralinfo',
                        rootNode: true,
                        icon: 'action:account_box',
                        thumb_down: !this.CurrentPatient.patient.menu.payer_thumb_info,
                        thumb_down_tooltip: this.referralThumbDownTooltip(),
                        children: [
                            {
                                title: 'Personal Information',
                                icon: 'image:edit',
                                iconClass: 'md-secondary',
                                iconRight: true,
                                titleClass: 'margin-left-57',
                                route: 'patients.view',
                                secondaryRoute: 'patients.edit',
                            },
                            {
                                title: 'Recent Referral Information',
                                icon: 'image:edit',
                                iconClass: 'md-secondary',
                                iconRight: true,
                                titleClass: 'margin-left-57',
                                url: '/patients/:patientId/care_level_changes',
                                secondaryUrl: '/patients/:patientId/care_level_changes/' +
                                    (this.CurrentPatient.patient.menu.recent_referral_information > -1
                                        ? this.CurrentPatient.patient.menu.recent_referral_information + '/edit'
                                        : 'new'),
                            },
                            {
                                title: 'Payer Information',
                                titleClass: 'margin-left-57',
                                conditional_hide: !this.permissions.payer_groups_show,
                                conditional_hide_new: !this.permissions.payer_groups_create,
                                route: 'patients.payer-groups',
                                secondaryRoute: 'patients.payer-groups.new',
                                thumb: { value: this.CurrentPatient.patient.menu.payer_thumb_info },
                            },
                            {
                                title: 'Eligibility Inquiries',
                                titleClass: 'margin-left-57',
                                url: '/patients/:patientId/eligibility_inquiries',
                            },
                            {
                                title: 'Admission Notes',
                                icon: 'content:add_box ',
                                iconRight: true,
                                iconClass: 'md-secondary',
                                titleClass: 'margin-left-57',
                                url: '/patients/:patientId/admission_notes',
                                secondaryUrl: '/patients/:patientId/admission_notes/new',
                            },
                            {
                                title: 'Episodes of Care',
                                titleClass: 'margin-left-57',
                                url: "/emr/" + subdomain + "/patients/:patientId/episodes-of-care",
                                conditional_hide: this.isPalliative,
                            },
                        ],
                    },
                    {
                        title: 'Clinical Charting',
                        icon: 'action:assignment',
                        name: 'clinical-chart',
                        children: [
                            {
                                title: this.charting_title,
                                route: 'patients.clinical-chart',
                                secondaryRoute: function () {
                                    return (_this.chartingCookie() || _this.agency.has_encounter_charting) &&
                                        !_this.has_encounter_charting
                                        ? 'patients.clinical-chart-entry-new.section'
                                        : '';
                                },
                                secondaryUrl: function () {
                                    return !_this.chartingCookie() &&
                                        !_this.agency.has_encounter_charting &&
                                        !_this.has_encounter_charting
                                        ? '/patients/:patientId/clinical_charts/new'
                                        : '';
                                },
                                secondaryAction: function ($event) {
                                    if (_this.has_encounter_charting) {
                                        _this.persistenceStore
                                            .get('clinical_chart_entry', { patient_id: _this.$stateParams.patientId })
                                            .then(function (item) {
                                            var model = item.model;
                                            var obj = {
                                                selectedPatient: model.patient,
                                                encounter_type_id: model.encounter_type_id,
                                                encounter_type: model.encounter_type,
                                                charting_client: model.encounter_type.charting_type,
                                                initial_section_name: model.initial_section_name || 'encounter_charting',
                                            };
                                            _this.$state.go('patients.clinical-chart-entry-new.section', {
                                                patientId: model.patient_id,
                                                sectionName: obj.initial_section_name,
                                                initObj: obj,
                                            });
                                        })
                                            .catch(function () {
                                            _this.$mdDialog.show({
                                                targetEvent: $event,
                                                clickOutsideToClose: false,
                                                bindToController: true,
                                                templateUrl: '/y/templates/clinical-charts/encounter-types/selector-modal.html',
                                                controller: 'EncounterSelectorModalController as $ctrl',
                                                locals: {
                                                    currentPatient: _this.CurrentPatient.patient,
                                                    clinical_trip_stop_point_id: null,
                                                },
                                            });
                                        });
                                    }
                                },
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            physicianOrderSection,
                            {
                                title: 'Hospice Aide Manager',
                                route: 'aide-manager.patients.tasks',
                                conditional_hide: !this.permissions.hospice_aide_manager_show || this.isPalliative,
                            },
                            {
                                title: 'IDG Comprehensive Assessment',
                                url: '/patients/:patientId/idg_snapshots',
                                secondaryUrl: '/patients/:patientId/idg_snapshots/new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Missed Visits',
                                url: "/emr/" + subdomain + "/patients/:patientId/missed-visits",
                                secondaryAction: function () {
                                    _this.$mdDialog.show({
                                        clickOutsideToClose: false,
                                        bindToController: true,
                                        templateUrl: '/y/templates/clinical-charts/sections/missed-visits/missed-visit.html',
                                        controller: 'MissedVisitController as $ctrl',
                                    });
                                },
                                defaultSecondaryRoute: '',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Explanation of Unrelatedness',
                                url: "/emr/" + subdomain + "/patients/:patientId/unrelated-explanations",
                                conditional_hide: !this.permissions.unrelated_explanations_create || this.isPalliative,
                            },
                            {
                                title: 'Smoking Status',
                                route: 'patients.smoking-statuses',
                                secondaryAction: function () {
                                    _this.$mdDialog.show({
                                        clickOutsideToClose: false,
                                        bindToController: true,
                                        templateUrl: '/y/templates/smoking-statuses/smoking-status.html',
                                        controller: 'SmokingStatusController as vm',
                                        locals: { smokingStatus: _this.SmokingStatusService.new() },
                                    });
                                },
                                defaultSecondaryRoute: '',
                                conditional_hide: !this.permissions.smoking_statuses_show,
                                conditional_hide_new: !this.permissions.smoking_statuses_create,
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                        ],
                    },
                    {
                        title: 'Provider Charting',
                        name: 'physician',
                        icon: 'maps:local_pharmacy',
                        conditional_hide: !this.permissions.physician_drawer,
                        children: [
                            physicianOrderSection,
                            {
                                title: 'Explanation of Unrelatedness',
                                url: "/emr/" + subdomain + "/patients/:patientId/unrelated-explanations",
                                conditional_hide: !this.permissions.unrelated_explanations_show || this.isPalliative,
                            },
                            {
                                title: 'MIPS / CQM',
                                route: 'patients.cqms',
                                conditional_hide: !this.permissions.cqms_show || !this.agency.has_cqm,
                                locals: { currentPatient: this.CurrentPatient.patient },
                            },
                        ],
                    },
                    {
                        title: 'Medication Info',
                        name: 'medications',
                        icon: 'maps:local_pharmacy',
                        children: [
                            {
                                title: 'Allergies',
                                route: 'patients.allergies',
                            },
                            {
                                title: 'Medication Record',
                                route: 'patients.medications',
                                secondaryRoute: 'patients.medications.new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Medication History',
                                route: 'patients.medication-history',
                                conditional_hide: !this.agency.medication_history_enabled,
                            },
                            {
                                title: 'Med Administration Records',
                                route: 'patients.medication-administration-records',
                            },
                            {
                                title: 'Med Administration History',
                                url: '/patients/:patientId/medication_administration_records/history',
                                conditional_hide: !this.permissions.medication_administration_record_show,
                            },
                            {
                                title: 'Medication Notes',
                                url: '/patients/:patientId/medication_notes',
                                secondaryAction: function () {
                                    var url = "'/y/templates/patients/sections/medications/add-medication-note.html'";
                                    return _this.$mdDialog.show({
                                        clickOutsideToClose: true,
                                        template: '<md-dialog flex="80" aria-label="Add Medication Note">' +
                                            '  <md-dialog-content>' +
                                            '   <div ng-include="' +
                                            url +
                                            '"/>' +
                                            '  </md-dialog-content>' +
                                            '</md-dialog>',
                                        controller: 'PatientMedicationNoteController as vm',
                                        bindToController: true,
                                        locals: {
                                            currentPatient: { id: _this.$stateParams.patientId },
                                            medication: {},
                                            modal: true,
                                        },
                                    });
                                },
                                defaultSecondaryRoute: '',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Medication Fill Record',
                                url: '/patients/:patientId/medication_fills',
                            },
                            {
                                title: 'Immunizations',
                                route: 'patients.vaccinations',
                                conditional_hide: !this.permissions.vaccinations_show || !this.agency.has_immunizations,
                            },
                        ],
                    },
                    {
                        title: 'Diagnostics and Devices',
                        icon: 'social:poll',
                        children: [
                            {
                                title: 'Labs',
                                icon: 'action:note_add',
                                iconClass: 'md-secondary',
                                iconRight: true,
                                titleClass: 'margin-left-57',
                                route: 'patients.labs',
                                secondaryRoute: 'patients.labs.new',
                                conditional_hide: !this.permissions.labs_show || !this.agency.has_labs,
                                conditional_hide_new: !this.permissions.labs_create,
                            },
                            {
                                title: 'Implantable Devices',
                                route: 'patients.implantable-devices',
                                secondaryRoute: 'patients.implantable-devices.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: !this.hasImplantableDevices || !this.permissions.patient_devices_show,
                                conditional_hide_new: !this.hasImplantableDevices || !this.permissions.patient_devices_create,
                            },
                            {
                                title: 'Diagnostic Image Results',
                                icon: 'action:note_add',
                                iconClass: 'md-secondary',
                                iconRight: true,
                                titleClass: 'margin-left-57',
                                route: 'patients.diagnostic-image-results',
                                secondaryRoute: 'patients.diagnostic-image-results.new',
                                conditional_hide: !this.permissions.diagnostic_image_results_show ||
                                    !this.agency.has_diagnostic_image_results,
                                conditional_hide_new: !this.permissions.diagnostic_image_results_create,
                            },
                        ],
                    },
                    {
                        title: 'Administration Info',
                        icon: 'social:people',
                        thumb_down: !this.CurrentPatient.patient.menu.configured_patient_record_default,
                        thumb_down_tooltip: this.administrationThumbDownTooltip(),
                        children: [
                            {
                                title: 'Audits',
                                url: '/patients/:patientId/audits',
                            },
                            {
                                title: 'Contacts/Family Members',
                                url: "/y/family-members?patient_id=:patientId",
                                secondaryUrl: "/y/patients/:patientId/family-members/new",
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: !this.permissions.families_show,
                                conditional_hide_new: !this.permissions.families_create,
                            },
                            {
                                title: 'Custom Alerts',
                                url: "/emr/" + subdomain + "/patients/:patientId/custom-alerts",
                                secondaryAction: function () {
                                    _this.$mdDialog.show({
                                        clickOutsideToClose: false,
                                        bindToController: true,
                                        templateUrl: '/y/templates/patients/sections/custom-alerts/custom-alert.html',
                                        controller: 'CustomAlertController as $ctrl',
                                        locals: {
                                            item: {},
                                        },
                                    });
                                },
                                defaultSecondaryRoute: '',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Assignments & Defaults',
                                route: 'patients.assignments-and-defaults',
                                thumb: {
                                    value: this.CurrentPatient.patient.menu.configured_patient_record_default &&
                                        this.CurrentPatient.patient.menu.configured_assignment,
                                },
                            },
                            {
                                title: 'Scheduled Visits',
                                url: this.agency.has_new_scheduler
                                    ? "/scheduler/patients/:patientId/scheduled-visits"
                                    : '/patients/:patientId/scheduled_visits',
                                secondaryUrl: this.agency.has_new_scheduler
                                    ? null
                                    : '/patients/:patientId/scheduled_visits/new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Claim Notes',
                                url: '/patients/:patientId/forum_threads',
                                secondaryUrl: '/patients/:patientId/forum_threads/new?primary_tag=Claims',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Admin Notes',
                                url: '/patients/:patientId/forum_threads',
                                secondaryUrl: '/patients/:patientId/forum_threads/new?primary_tag=Administrative',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Prior Authorizations',
                                route: 'patients.prior-authorizations',
                                secondaryRoute: 'patients.prior-authorizations.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: !this.permissions.prior_authorizations_show,
                                conditional_hide_new: !this.permissions.prior_authorizations_create,
                            },
                            {
                                title: 'Tasks',
                                url: "/emr/" + subdomain + "/patients/:patientId/tasks",
                                secondaryAction: function () {
                                    _this.$mdDialog.show({
                                        clickOutsideToClose: false,
                                        bindToController: true,
                                        templateUrl: '/y/templates/patients/sections/tasks/task.html',
                                        controller: 'PatientTaskController as vm',
                                    });
                                },
                                defaultSecondaryRoute: '',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Physician/Provider Charges',
                                route: 'patients.physician-charges',
                                secondaryRoute: 'patients.physician-charges.new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Export All Patient Data (Zip)',
                                conditional_hide: !this.permissions.reports_patient_data_export,
                                action: function () {
                                    var confirm = _this.$mdDialog
                                        .confirm()
                                        .title('Export')
                                        .textContent('Are you sure you want to export all patient data?')
                                        .ok('Okay')
                                        .cancel('Cancel');
                                    _this.$mdDialog
                                        .show(confirm)
                                        .then(function () {
                                        return _this.$http.post('/api/v1/export/patient/zip', {
                                            patient_id: _this.$stateParams.patientId,
                                        });
                                    })
                                        .then(function () {
                                        _this.logger.success('Patient data is being exported. You will be notified when the export is ready.');
                                    }, function (resp) {
                                        _this.logger.error('Enable to export patient: ' + resp.data.errors.join('; '));
                                    });
                                },
                            },
                            {
                                title: 'Export All Patient Data (PDF)',
                                conditional_hide: !this.permissions.reports_patient_pdf_export,
                                action: function () {
                                    _this.$mdDialog.show({
                                        clickOutsideToClose: false,
                                        bindToController: true,
                                        templateUrl: '/y/templates/patients/patient-export-pdf-modal.html',
                                        controller: 'PatientExportController as vm',
                                    });
                                },
                            },
                        ],
                    },
                    {
                        title: this.isPalliative ? 'Diagnoses and Care Plans ' : 'Certification / Care Plans ',
                        icon: 'social:poll',
                        thumb_down: this.certificationThumbDown(),
                        thumb_down_tooltip: this.certificationThumbDownTooltip(),
                        children: [
                            {
                                title: 'Certifications',
                                url: '/patients/:patientId/certifications',
                                secondaryUrl: '/patients/:patientId/certifications/new',
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: this.isPalliative || this.CurrentPatient.patient.menu.pending_never_admitted,
                                thumb: { value: this.CurrentPatient.patient.menu.configured_certification },
                            },
                            {
                                title: 'Bereavement Care Plans',
                                url: '/patients/:patientId/bereavement_care_plans',
                                secondaryUrl: '/patients/:patientId/bereavement_care_plans/new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Care Plan Problems ',
                                route: 'patients.care-plan-problem',
                                secondaryRoute: 'patients.care-plan-problem.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                thumb: {
                                    value: this.isPalliative
                                        ? null
                                        : this.CurrentPatient.patient.menu.configured_care_plan_problem,
                                },
                            },
                            {
                                title: 'View Current Care Plans',
                                route: 'patients.care-plan-problem.current',
                                conditional_hide: !this.permissions.care_plan_problems_show,
                            },
                            {
                                title: 'Upcoming Interventions',
                                url: '/patients/:patientId/care_plan_problems/upcoming_interventions',
                                conditional_hide: !this.permissions.care_plan_problems_show,
                            },
                            {
                                title: 'Clinical Indicators',
                                route: 'patients.indicators',
                                secondaryRoute: 'patients.indicators.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                thumb: { value: this.CurrentPatient.patient.menu.configured_indicator },
                                conditional_hide: this.problemListEnabled,
                            },
                            {
                                title: 'Problems & Diagnoses',
                                route: 'patients.problem-list-and-diagnoses',
                                conditional_hide: !this.problemListEnabled,
                            },
                            {
                                title: 'Procedures',
                                route: 'patients.procedures',
                                secondaryRoute: 'patients.procedures.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: !this.permissions.procedures_show || !this.agency.has_procedures,
                                conditional_hide_new: !this.permissions.procedures_create,
                            },
                            {
                                title: 'DME Orders',
                                url: '/patients/:patientId/dme_orders',
                                secondaryUrl: '/patients/:patientId/dme_orders/new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Plan of Care',
                                route: 'patients.plan-of-cares-list',
                                secondaryAction: function () {
                                    _this.PlanOfCareService.clearCurrentPlanOfCare();
                                    _this.$state.go('patients.plan-of-care-new.main');
                                },
                                defaultSecondaryRoute: '',
                                conditional_hide: !this.permissions.plan_of_cares_show,
                                conditional_hide_new: !this.permissions.plan_of_cares_create,
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Care Programs',
                                route: 'patients.care-programs',
                                secondaryRoute: 'patients.care-programs.new',
                                icon: 'action:note_add',
                                iconRight: true,
                                conditional_hide: !this.permissions.care_program_enrollments_show,
                                conditional_hide_new: !this.permissions.care_program_enrollments_update,
                            },
                        ],
                    },
                    {
                        title: 'Change in Care Info ',
                        icon: 'maps:transfer_within_a_station',
                        thumb_down: !this.CurrentPatient.patient.menu.payer_thumb_info,
                        thumb_down_tooltip: this.changeInCareThumbDownTooltip(),
                        children: [
                            {
                                title: 'View Summary',
                                url: '/patients/:patientId/care_level_changes',
                            },
                            {
                                title: 'Admit Patient',
                                url: '/patients/:patientId/care_level_changes/new',
                                conditional_hide: !this.permissions.care_level_changes_create ||
                                    !this.CurrentPatient.patient.menu.admit,
                            },
                            {
                                title: 'Postpone Admission',
                                url: '/patients/:patientId/postpone',
                                conditional_hide: !this.permissions.patients_postpone ||
                                    !this.CurrentPatient.patient.menu.postpone_admission,
                            },
                            {
                                title: 'Cancel Admission',
                                url: '/patients/:patientId/cancel',
                                conditional_hide: !this.permissions.patients_postpone ||
                                    !this.CurrentPatient.patient.menu.cancel_admission,
                            },
                            {
                                title: 'New Referral',
                                url: '/patients/:patientId/care_level_changes/new?referral=true',
                                conditional_hide: !this.permissions.care_level_changes_create ||
                                    !this.CurrentPatient.patient.menu.new_referral,
                            },
                            {
                                title: 'Bereavement Discharge',
                                url: '/patients/:patientId/bereavement_discharges/' +
                                    this.CurrentPatient.patient.menu.bereavement_discharge,
                                conditional_hide: !this.permissions.bereavement_discharges_show ||
                                    !this.CurrentPatient.patient.menu.has_death_date ||
                                    this.CurrentPatient.patient.menu.bereavement_discharge < 1,
                            },
                            {
                                title: 'Create Bereavement Discharge',
                                url: '/patients/:patientId/bereavement_discharges/new',
                                conditional_hide: !this.permissions.bereavement_discharges_create ||
                                    !this.CurrentPatient.patient.menu.has_death_date ||
                                    this.CurrentPatient.patient.menu.bereavement_discharge > 0,
                            },
                            {
                                title: 'Billing Demographics',
                                route: 'patients.billing-demographics',
                                conditional_hide: !this.permissions.billing_demographics_show,
                            },
                            {
                                title: 'Discharge Summaries',
                                route: 'patients.discharge_summaries',
                            },
                            {
                                title: 'Activity Report',
                                url: '/reports/activity_report?filter[patient_id]=:patientId&filter[patient_name_for_patient_id]=' +
                                    this.CurrentPatient.patient.name,
                                conditional_hide: !this.permissions.reports_activity_report ||
                                    !this.CurrentPatient.patient.menu.activity_report,
                            },
                        ],
                    },
                    {
                        title: 'Documents ',
                        icon: 'action:description',
                        children: [
                            {
                                title: 'Fax/Print Patient Documents',
                                route: function () { return (_this.pdfer.enabled ? 'patients.fax' : ''); },
                                url: function () { return (!_this.pdfer.enabled ? '/patients/:patientId/fax' : ''); },
                            },
                            {
                                title: 'Continuous Care Tracking Sheet',
                                url: '/patients/cc_tracking_sheet',
                                conditional_hide: !this.permissions.patients_cc_tracking_sheet ||
                                    !this.CurrentPatient.patient.menu.continuous,
                            },
                            {
                                title: 'Customized CC Tracking Sheet',
                                url: '/patients/:patientId/cc_tracking_sheet',
                                conditional_hide: !this.permissions.patients_cc_tracking_sheet ||
                                    !this.CurrentPatient.patient.menu.continuous,
                            },
                            {
                                title: 'Patient Face Sheet PDF',
                                action: function () {
                                    _this.PdfGenerator.print({ patient_face_sheet: _this.$stateParams.patientId });
                                },
                            },
                            {
                                title: 'Initial Comprehensive Assessment',
                                url: '/patients/:patientId/comprehensive_assessment',
                                conditional_hide: this.isPalliative,
                            },
                            {
                                title: 'Amendments',
                                url: "/emr/" + subdomain + "/patients/:patientId/amendments",
                                conditional_hide: !this.permissions.amendments_show || !this.hasAmendments,
                            },
                            {
                                title: 'Patient Documents',
                                route: 'patients.documents',
                            },
                            {
                                title: 'Electronic Documents',
                                route: 'patients.electronic-documents',
                                conditional_hide: !this.hasElectronicDocuments,
                            },
                            {
                                title: 'Electronic Health Information (EHI) Export',
                                route: 'patients.ehi_export',
                            },
                            {
                                title: 'CCDA Export',
                                route: 'patients.ccda_export',
                                conditional_hide: !this.permissions.reports_patient_data_export,
                            },
                        ],
                    },
                    {
                        title: 'Clinical Summaries ',
                        icon: 'image:style',
                        children: [
                            {
                                title: 'Bereavement Assessments',
                                url: "/emr/" + subdomain + "/patients/:patientId/bereavement-assessments",
                            },
                            {
                                title: 'Care Summary',
                                url: "/emr/" + subdomain + "/patients/:patientId/cares",
                                conditional_hide: !this.permissions.clinical_charts_show_care_summary
                            },
                            {
                                title: 'Hospice Item Sets',
                                url: '/patients/:patientId/hospice_item_sets',
                                conditional_hide: this.isPalliative,
                            },
                            {
                                title: 'Edmonton Assessments',
                                url: "/emr/" + subdomain + "/patients/:patientId/edmonton-assessments",
                                conditional_hide: !this.has_encounter_charting,
                            },
                            {
                                title: 'Fall Events',
                                url: "/emr/" + subdomain + "/patients/:patientId/fall-events",
                            },
                            {
                                title: 'Inpatient Unit Notes',
                                url: "/emr/" + subdomain + "/patients/:patientId/inpatient-unit-notes",
                                conditional_hide: this.isPalliative,
                            },
                            {
                                title: 'Mini Nutritional Assessments',
                                url: "/emr/" + subdomain + "/patients/:patientId/mini-nutritional-assessments",
                            },
                            {
                                title: 'Morse Fall Assessments',
                                url: "/emr/" + subdomain + "/patients/:patientId/morse-fall-assessments",
                            },
                            {
                                title: 'Pain Observations',
                                url: "/emr/" + subdomain + "/patients/:patientId/pain-observations",
                            },
                            {
                                title: 'Skin Integrity Assessments',
                                url: "/emr/" + subdomain + "/patients/:patientId/skin-integrity-assessments",
                            },
                            {
                                title: 'Vital Sign Measurements',
                                url: "/emr/" + subdomain + "/patients/:patientId/vital-sign-measurements",
                            },
                            {
                                title: 'Wound Assessments',
                                url: "/emr/" + subdomain + "/patients/:patientId/wound-assessments",
                            },
                        ],
                    },
                    {
                        title: 'Volunteer Info ',
                        icon: 'action:assignment_ind',
                        children: [
                            {
                                title: 'Volunteer Care Plans',
                                url: "/emr/" + subdomain + "/patients/:patientId/volunteer-care-plans"
                            },
                            {
                                title: 'Volunteer Requests',
                                url: '/patients/:patientId/volunteer_requests',
                                secondaryUrl: '/patients/:patientId/volunteer_requests/new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                            {
                                title: 'Volunteer Care Notes',
                                url: '/patients/:patientId/volunteer_care_notes',
                                secondaryUrl: '/patients/:patientId/volunteer_care_notes/new',
                                icon: 'action:note_add',
                                iconRight: true,
                            },
                        ],
                    },
                    {
                        title: 'Messaging',
                        icon: 'communication:message',
                        conditional_hide: (!this.permissions.direct_secure_messages_show ||
                            !this.agency.has_direct_secure_messaging) &&
                            !this.agency.has_patient_portal &&
                            !this.BeaconService.hasBeacon,
                        children: [
                            {
                                title: 'Direct Secure Messaging',
                                url: "/emr/" + subdomain + "/patients/:patientId/direct-secure-messages",
                                conditional_hide: !this.permissions.direct_secure_messages_show ||
                                    !this.agency.has_direct_secure_messaging,
                            },
                            {
                                title: 'Patient Messages',
                                url: "/emr/" + subdomain + "/patients/:patientId/patient-messages",
                                conditional_hide: !this.agency.has_patient_portal,
                            },
                            {
                                title: 'Internal Messages',
                                route: 'patients.messages-internal',
                                conditional_hide: !this.BeaconService.hasBeacon,
                            },
                        ],
                    },
                ];
            };
            ConsoloPatientSectionManagerService.$inject = [
                'cache',
                'Patient',
                '$filter',
                'SessionService',
                '$state',
                '$stateParams',
                '$http',
                'logger',
                '$mdDialog',
                'DeviceSettingsService',
                'CurrentPatient',
                'PdfGenerator',
                'UnrelatedExplanationService',
                'PlanOfCareService',
                'SmokingStatusService',
                'PermissionsService',
                '$q',
                'PatientMainService',
                'persistenceStore',
                '$mdMedia',
                '$mdSidenav',
                'BeaconService',
                'pdfer',
            ];
            return ConsoloPatientSectionManagerService;
        }());
        controllers.ConsoloPatientSectionManagerService = ConsoloPatientSectionManagerService;
        angular
            .module('consolo')
            .service('ConsoloPatientSectionManagerService', ConsoloPatientSectionManagerService);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientDashboardSidebarController = /** @class */ (function () {
            function PatientDashboardSidebarController(CurrentPatient, NetworkManager, PatientSidebarSections, menu) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.NetworkManager = NetworkManager;
                this.PatientSidebarSections = PatientSidebarSections;
                this.menu = menu;
                this.PatientSidebarSections.loadPermissions().then(function () {
                    _this.PatientSidebarSections.loadSections();
                });
                this.isOnline = this.NetworkManager.isOnline();
                NetworkManager.onlineHandler.onEvent('PatientDashboardSidebarController', function () {
                    _this.isOnline = true;
                    _this.CurrentPatient.refreshPatient().then(function () {
                        _this.PatientSidebarSections.loadPermissions().then(function () {
                            _this.PatientSidebarSections.loadSections();
                        });
                    });
                });
                NetworkManager.offlineHandler.onEvent('PatientDashboardSidebarController', function () {
                    _this.toggleOpen(_this.PatientSidebarSections.offlineSections[0]);
                    _this.isOnline = false;
                });
            }
            PatientDashboardSidebarController.prototype.navigateTo = function (section, type) {
                this.PatientSidebarSections.navigateTo(section, type);
            };
            PatientDashboardSidebarController.prototype.navigatePrimary = function (item) {
                return this.PatientSidebarSections.navigateTo(item, 'primary');
            };
            PatientDashboardSidebarController.prototype.navigateSecondary = function (item, $event) {
                return this.PatientSidebarSections.navigateTo(item, 'secondary', $event);
            };
            PatientDashboardSidebarController.prototype.getHref = function (item, position) {
                return this.PatientSidebarSections.getHref(item, position);
            };
            PatientDashboardSidebarController.prototype.isOpen = function (section) {
                return this.menu.isSectionSelected(section);
            };
            PatientDashboardSidebarController.prototype.toggleOpen = function (section) {
                if (!this.isOnline && section.name !== 'offlinemenu') {
                    return;
                }
                this.menu.toggleSelectSection(section);
            };
            PatientDashboardSidebarController.$inject = [
                'CurrentPatient',
                'NetworkManager',
                'ConsoloPatientSectionManagerService',
                'menu',
            ];
            return PatientDashboardSidebarController;
        }());
        angular
            .module('consolo')
            .controller('PatientDashboardSidebarController', PatientDashboardSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var FormPatient = consolo.domain.FormPatient;
    var PatientComponent = /** @class */ (function () {
        function PatientComponent() {
            this.templateUrl = '/y/templates/patients/patient.html';
            this.controller = PatientController;
            this.require = { ngModelCtrl: '?ngModel' };
            this.bindings = {
                isEditing: '@',
                isPartial: '<',
            };
        }
        return PatientComponent;
    }());
    var PatientController = /** @class */ (function () {
        function PatientController($mdDialog, $scope, officeService, serviceService, referralContactService, Lookup, options, patientService, $state, logger, Session, CurrentPatient, familyMembersService, cdsPatientDemographicsService, $timeout) {
            this.$mdDialog = $mdDialog;
            this.$scope = $scope;
            this.officeService = officeService;
            this.serviceService = serviceService;
            this.referralContactService = referralContactService;
            this.Lookup = Lookup;
            this.options = options;
            this.patientService = patientService;
            this.$state = $state;
            this.logger = logger;
            this.Session = Session;
            this.CurrentPatient = CurrentPatient;
            this.familyMembersService = familyMembersService;
            this.cdsPatientDemographicsService = cdsPatientDemographicsService;
            this.$timeout = $timeout;
            this.loading = false;
            this.homeAddressSameAsFacility = false;
            this.referralContactTypes = ['Physician', 'Family', 'Referral Individual'];
            this.referralAccountTypes = ['Facility', 'Community Group', 'Medical Company', 'Referral Account'];
            this.cdsItemId = '47';
            this.cdsItemUrl = null;
            this.today = new Date();
            this.yesNoOptions = [
                { id: 1, description: '' },
                { id: 2, description: 'No' },
                { id: 3, description: 'Yes' },
            ];
            this.hasVisitFrequencyEnabled = false;
            this.isPermittedToUnmatchMasterPatientIndex = false;
            this.considerInReleaseBranch = false;
            if (this.$state.current.data.military) {
                this.$timeout(function () {
                    var searchItemContainer = document.getElementById('military-info');
                    if (searchItemContainer) {
                        searchItemContainer.scrollIntoView();
                    }
                }, 250);
            }
            if (this.$state.current.data.patientCare) {
                this.$timeout(function () {
                    var searchItemContainer = document.getElementById('patient-care');
                    if (searchItemContainer) {
                        searchItemContainer.scrollIntoView();
                    }
                }, 250);
            }
        }
        PatientController.prototype.$onInit = function () {
            var sess = this.Session.get();
            this.user_dashboard_url = sess.user.dashboard_url;
            this.displayCommonWell = sess.agency.has_commonwell_opt_in;
            this.hasPatientPortal = sess.agency.has_patient_portal;
            this.hasVisitFrequencyEnabled = this.Session.get().agency.has_visit_frequency;
            if (this.isExistingPatient()) {
                this.patient = this.CurrentPatient.formPatient;
                if (this.patient.part_of_multiple_birth === true) {
                    this.part_of_multiplebirth = 3;
                }
                else if (this.patient.part_of_multiple_birth === false) {
                    this.part_of_multiplebirth = 2;
                }
                else {
                    this.part_of_multiplebirth = 1;
                }
                if (this.CurrentPatient.empi_id) {
                    this.isPermittedToUnmatchMasterPatientIndex = true;
                }
                else {
                    this.isPermittedToUnmatchMasterPatientIndex = false;
                }
                if (!this.patient.declined_cdc_race) {
                    this.onPrimaryRacesChange(this.patient.cdc_race_ids);
                }
                if (!this.patient.declined_ethnicity) {
                    this.populatePrimaryEthnicity(this.patient);
                }
            }
            else {
                this.patient = new FormPatient(null);
                this.part_of_multiplebirth = 1;
                this.patient.cdc_race_ids = [];
                this.patient.ethnicity_ids = [];
                this.patient.hie_opt_in = !!sess.agency.hie_default_opt_in;
            }
            this.fetchOffices();
            this.fetchOptions();
            this.isPalliative = sess.agency.service_line === 'palliative';
            this.cdsEnabled = this.Session.get().agency.has_cds;
            this.getCdsItemLink();
            this.assigneesLayout = {
                id_field: 'id',
                options: {
                    showExpandCollapseAll: false,
                    hideFooter: true,
                    hideTextFilter: true,
                },
                columns: this.hasVisitFrequencyEnabled ? [
                    { title: 'Discipline', field: 'discipline' },
                    { title: 'Effective Date', field: 'assignment_effective_date' },
                    { title: 'Visits', field: 'visit_frequency_string' },
                    { title: 'PRN Visits', field: 'prn_visit_frequency_string' },
                ] : [
                    { title: 'Discipline', field: 'discipline' },
                    { title: 'Name', field: 'name' },
                    { title: 'Email', field: 'email' },
                ],
            };
        };
        PatientController.prototype.partOfMultiplebirthChange = function (value) {
            switch (value.toString()) {
                case '1':
                    this.patient.part_of_multiple_birth = null;
                    break;
                case '2':
                    this.patient.part_of_multiple_birth = false;
                    break;
                case '3':
                    this.patient.part_of_multiple_birth = true;
                    break;
            }
        };
        PatientController.prototype.getCdsItemLink = function () {
            var _this = this;
            if (this.cdsEnabled) {
                this.cdsPatientDemographicsService
                    .getCdsForPatientDemographics(this.cdsItemId)
                    .then(function (response) {
                    var cdsItem = response.data;
                    _this.cdsItemUrl = cdsItem.doc_link;
                });
            }
        };
        PatientController.prototype.showHIS = function (show) {
            if (this.isPalliative) {
                return false;
            }
            else {
                return show;
            }
        };
        PatientController.prototype.showSameAsFacilityCheck = function () {
            if (this.isExistingPatient()) {
                return false;
            }
            return this.patient.care_level_change.facility_id;
        };
        PatientController.prototype.onSameAsFacilityChange = function () {
            var _this = this;
            if (this.homeAddressSameAsFacility) {
                this.loading = true;
                this.serviceService.getFacility(this.patient.care_level_change.facility_id).then(function (response) {
                    var facility = response.data;
                    _this.patient.address_1 = facility.address.address_1;
                    _this.patient.address_2 = facility.address.address_2;
                    _this.patient.city = facility.address.city;
                    _this.patient.state = facility.address.state;
                    _this.patient.county = facility.county;
                    _this.patient.county_id = facility.county_id;
                    _this.patient.zipcode = facility.address.zipcode;
                    _this.loading = false;
                }, function (response) {
                    _this.loading = false;
                    _this.logger.error('Could not find facility address');
                });
            }
            else {
                this.patient.address_1 = null;
                this.patient.address_2 = null;
                this.patient.city = null;
                this.patient.state = null;
                this.patient.zipcode = null;
                this.patient.county = null;
                this.patient.county_id = null;
            }
        };
        PatientController.prototype.onDeclinedEthnicityChange = function () {
            if (this.patient.declined_ethnicity) {
                this.patient.ethnicity_ids = [];
                this.primaryEthnicities = [];
                this.secondaryEthnicities = [];
            }
            else {
                this.loadEthnicityOptions();
            }
        };
        PatientController.prototype.onDeclinedCdcRaceChange = function () {
            if (this.patient.declined_cdc_race) {
                this.patient.cdc_race_ids = [];
                this.primaryCdcRaces = [];
                this.secondaryCdcRaces = [];
            }
            else {
                this.loadCdcRaceOptions();
            }
        };
        PatientController.prototype.onLanguageDeclinedChange = function () {
            if (this.patient.language_declined) {
                this.patient.language_id = null;
                this.patient.language = null;
            }
        };
        PatientController.prototype.onPrefferedLanguageSelected = function (item) {
            this.patient.language = item;
        };
        PatientController.prototype.currentPatient = function () {
            return this.CurrentPatient.patient;
        };
        PatientController.prototype.isEditingPatient = function () {
            return !!this.$scope.$ctrl.isEditing;
        };
        PatientController.prototype.isExistingPatient = function () {
            return !!this.currentPatient() && !(this.$state.current.name === 'add-patient');
        };
        PatientController.prototype.isReadOnly = function () {
            if (this.isExistingPatient()) {
                if (this.isEditingPatient()) {
                    // todo return for specific fields
                    return false;
                }
                return true;
            }
            else {
                return false;
            }
        };
        PatientController.prototype.auditMessage = function (label) {
            return 'Clicked Patient ' + label + ' CDS Item: ' + this.cdsItemUrl;
        };
        PatientController.prototype.showOnView = function (field) {
            var is_not_empty = field instanceof Array ? field.length !== 0 : field;
            return !this.isReadOnly() || is_not_empty;
        };
        PatientController.prototype.sexualOrientationRequiresOther = function () {
            var _this = this;
            if (!this.patient.sexual_orientation_id) {
                return false;
            }
            if (this.selectOptions.sexual_orientations) {
                var sexual_orientation = this.selectOptions.sexual_orientations.find(function (orientation) {
                    return orientation.id === _this.patient.sexual_orientation_id;
                });
                return sexual_orientation.description === 'Something Else (please describe)';
            }
            return false;
        };
        PatientController.prototype.genderIdentityRequiresOther = function () {
            var _this = this;
            if (!this.patient.gender_identity_id) {
                return false;
            }
            if (this.selectOptions.gender_identities) {
                var gender_identity = this.selectOptions.gender_identities.find(function (identity) {
                    return identity.id === _this.patient.gender_identity_id;
                });
                return (gender_identity.description === 'Additional Gender Category or Other (please specify)');
            }
            return false;
        };
        PatientController.prototype.fetchOptions = function () {
            var _this = this;
            this.selectOptions = {};
            [
                'referral_statuses',
                'religions',
                'marital_statuses',
                'languages',
                'genders',
                'gender_identities',
                'birth_sexes',
                'cdc_races',
                'ethnicities',
                'sexual_orientations',
                'races',
                'states',
                'code_statuses',
                'disaster_acuities',
                'military_branches',
                'military_eras',
                'enrolled_in_va_status',
                'yes_no',
            ].forEach(function (option) {
                _this.options.get(option).$promise.then(function (data) {
                    if (option === 'disaster_acuities') {
                        data.forEach(function (da) {
                            var displayName = da.code + " - " + da.description;
                            da.displayName = displayName;
                        });
                    }
                    _this.selectOptions[option] = data;
                });
            });
            if (this.patient.declined_cdc_race) {
                this.primaryCdcRaces = [];
                this.secondaryCdcRaces = [];
            }
            else {
                this.loadCdcRaceOptions();
            }
            if (this.patient.declined_ethnicity) {
                this.primaryEthnicities = [];
                this.secondaryEthnicities = [];
            }
            else {
                this.loadEthnicityOptions();
            }
            if (this.isExistingPatient()) {
                this.familyMembersService.selectPatient(this.currentPatient().id).then(function (response) {
                    _this.patientFamilyMembers = response.data;
                    _this.cahpsEligibleFamilyMembers = _this.patientFamilyMembers.filter(function (member) {
                        return _this.isFamilyMemberCahpsEligible(member);
                    });
                });
            }
            this.counties = this.options.get('counties');
        };
        PatientController.prototype.loadEthnicityOptions = function () {
            var _this = this;
            this.options.get('ethnicities').$promise.then(function (response) {
                _this.primaryEthnicities = response.filter(function (value) { return value.primary; });
            });
        };
        PatientController.prototype.loadCdcRaceOptions = function () {
            var _this = this;
            this.options.get('cdc_races').$promise.then(function (response) {
                _this.primaryCdcRaces = response.filter(function (value) { return value.primary; });
            });
        };
        PatientController.prototype.onPrimaryRacesChange = function (raceIds) {
            var _this = this;
            this.options.get('cdc_races').$promise.then(function (response) {
                var primaries = response.filter(function (r) { return raceIds.indexOf(r.id) >= 0 && r.primary; });
                // find codes that aren't primary and rollup to a selected race ID
                _this.secondaryCdcRaces = _this.sortEthnicityOrRaces(response.filter(function (value) { return !value.primary && primaries.find(function (p) { return p.cdc_id === value.rollup_id; }) != null; }));
                // Remove any races that aren't in the options for secondary races
                var valid_options = primaries.map(function (e) { return e.id; }).concat(_this.secondaryCdcRaces.map(function (e) { return e.id; }));
                _this.patient.cdc_race_ids = raceIds.filter(function (race_id) { return valid_options.includes(race_id); });
            });
        };
        PatientController.prototype.onPrimaryEthnicityChange = function (ethnicityId) {
            this.patient.ethnicity_ids = [];
            this.patient.ethnicity_ids.push(ethnicityId);
            this.loadSecondaryEthnicityOptions(ethnicityId);
        };
        PatientController.prototype.loadSecondaryEthnicityOptions = function (ethnicityId) {
            var _this = this;
            // Load secondary ethnicity options based on selection.
            this.options.get('ethnicities').$promise.then(function (response) {
                var primary = response.find(function (e) { return e.id === ethnicityId; });
                // find codes that aren't primary and rollup to a selected race ID
                _this.secondaryEthnicities = _this.sortEthnicityOrRaces(response.filter(function (value) { return !value.primary && value.hierarchal_code.startsWith(primary.hierarchal_code); }));
            });
        };
        PatientController.prototype.sortEthnicityOrRaces = function (list) {
            return list.sort(function (a, b) {
                if (a.description > b.description) {
                    return 1;
                }
                if (b.description > a.description) {
                    return -1;
                }
                return 0;
            });
        };
        PatientController.prototype.populatePrimaryEthnicity = function (patient) {
            var _this = this;
            this.options.get('ethnicities').$promise.then(function (response) {
                var ethnicity = response.find(function (e) { return patient.ethnicity_ids.indexOf(e.id) >= 0 && e.primary === true; });
                if (ethnicity) {
                    _this.primaryEthnicityId = ethnicity.id;
                    _this.loadSecondaryEthnicityOptions(_this.primaryEthnicityId);
                }
            });
        };
        PatientController.prototype.fetchOffices = function () {
            var _this = this;
            this.officeService
                .select()
                .then(function (response) {
                _this.referralOffices = response.data.map(function (officeData) { return new consolo.domain.Office(officeData); });
            })
                .catch(function (err) { return console.log(err); });
        };
        //#region lookup
        PatientController.prototype.referralAccountLookup = function (query) {
            return this.serviceService
                .lookup(query)
                .then(function (response) {
                return response.data.map(function (serviceData) { return new consolo.domain.Service(serviceData); });
            })
                .catch(function (err) { return console.log(err); });
        };
        PatientController.prototype.referralContactLookup = function (query) {
            if (!this.hasSelectedReferralAccount()) {
                return [];
            }
            return this.referralContactService
                .referralContactLookup(this.selectedReferralAccount.id, query)
                .catch(function (err) { return console.log(err); });
        };
        PatientController.prototype.communityLiasonLookup = function (query) {
            return this.referralContactService
                .communityLiasonLookup(query)
                .catch(function (err) { return console.log(err); });
        };
        PatientController.prototype.facilityLookup = function (query) {
            return this.Lookup.facilities({ q: query }).$promise;
        };
        PatientController.prototype.physiciansLookup = function (query) {
            return this.Lookup.physicians({ q: query }).$promise;
        };
        //#endregion
        PatientController.prototype.languagesLookup = function (query) {
            return this.Lookup.languages({ q: query }).$promise;
        };
        PatientController.prototype.hasSelectedReferralAccount = function () {
            if (!this.selectedReferralAccount) {
                this.logger.error('You must first select a Referral Account');
                return false;
            }
            return true;
        };
        PatientController.prototype.checkForExistingPatientSsn = function () {
            var _this = this;
            if (!this.patient.ssn) {
                return;
            }
            this.patientService.checkForDuplicateSsn(this.patient).then(function (response) {
                console.log(response.data);
                if (response.data.length > 0) {
                    _this.showDuplicatePatientDialog(response.data[0], true);
                }
            });
        };
        PatientController.prototype.checkForExistingPatient = function () {
            var _this = this;
            if (!this.patient.first_name || !this.patient.last_name) {
                return;
            }
            this.patientService.checkForDuplicateName(this.patient).then(function (response) {
                console.log(response.data);
                if (response.data.length > 0) {
                    _this.showDuplicatePatientDialog(response.data[0]);
                }
            });
        };
        PatientController.prototype.showDuplicatePatientDialog = function (duplicatePatient, isForSsn) {
            if (isForSsn === void 0) { isForSsn = false; }
            this.$scope.duplicatePatient = duplicatePatient;
            this.$scope.duplicateMessage = "There is a Patient with a similar name already in Consolo.\n                You may want to use that Patient, or, you may want to add a middle name or suffix to distinguish this patient.";
            this.$scope.duplicateType = 'Name';
            if (isForSsn) {
                this.$scope.duplicateMessage = "There is a Patient with a similar SSN already in Consolo.\n                You may want to use that Patient.";
                this.$scope.duplicateType = 'SSN';
            }
            this.$mdDialog.show({
                clickOutsideToClose: false,
                preserveScope: true,
                template: "\n        <md-dialog>\n          <md-toolbar>\n            <div class=\"md-toolbar-tools\">\n              Duplicate {{vm.duplicateType}} Warning\n            </div>\n          </md-toolbar>\n          <md-dialog-content>\n            <div class=\"text-display-container layout-padding\">\n              <p>\n                {{vm.message}}\n              </p>\n              <a ng-click=\"vm.close()\" ui-sref=\"patients.dashboard({patientId: vm.duplicatePatient.id})\">\n              {{vm.duplicatePatient.last_name}}, {{vm.duplicatePatient.first_name}}\n            </a>\n            </div>\n          </md-dialog-content>\n          <md-dialog-actions>\n            <md-button ng-click=\"vm.close()\">Okay</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n        ",
                controller: 'DuplicatePatientDialogController as vm',
                locals: {
                    duplicatePatient: this.$scope.duplicatePatient,
                    message: this.$scope.duplicateMessage,
                    duplicateType: this.$scope.duplicateType,
                },
            });
        };
        PatientController.prototype.getAddress = function (addressType) {
            if (addressType === 'current') {
                return {
                    address_1: this.patient.address_1,
                    address_2: this.patient.address_2,
                    city: this.patient.city,
                    state: this.patient.state,
                    zipcode: this.patient.zipcode,
                };
            }
            else if (addressType === 'previous') {
                return {
                    address_1: this.patient.previous_address_1,
                    address_2: this.patient.previous_address_2,
                    city: this.patient.previous_city,
                    state: this.patient.previous_state,
                    zipcode: this.patient.previous_zipcode,
                };
            }
        };
        PatientController.prototype.addressConfirmed = function (addressType, address) {
            if (addressType === 'current') {
                this.patient.zipcode = address.zipcode;
                this.patient.city = address.city;
                this.patient.state = address.state;
                this.patient.address_1 = address.address_1;
                if (address.county) {
                    this.patient.county = address.county;
                    this.patient.county_id = address.county.id;
                }
            }
            else if (addressType === 'previous') {
                this.patient.previous_zipcode = address.zipcode;
                this.patient.previous_city = address.city;
                this.patient.previous_state = address.state;
                this.patient.previous_address_1 = address.address_1;
                if (address.county) {
                    this.patient.previous_county = address.county;
                    this.patient.previous_county_id = address.county.id;
                }
            }
        };
        PatientController.prototype.addReferralAccountMenuItemClicked = function (index) {
            var _this = this;
            this.$scope.referralAccountType = this.referralAccountTypes[index];
            this.$mdDialog
                .show({
                clickOutsideToClose: false,
                preserveScope: true,
                templateUrl: '/y/templates/patients/create-referral-account-modal.html',
                controller: 'CreateReferralAccountModalController as vm',
                locals: {
                    referralAccountType: this.$scope.referralAccountType,
                },
            })
                .then(function (referralAccount) {
                _this.selectedReferralAccount = referralAccount;
            });
        };
        PatientController.prototype.addReferralContactMenuItemClicked = function (index) {
            var _this = this;
            if (!this.hasSelectedReferralAccount()) {
                return;
            }
            this.$scope.referralContactType = this.referralContactTypes[index];
            this.$scope.referralAccount = this.selectedReferralAccount;
            this.$mdDialog
                .show({
                clickOutsideToClose: false,
                preserveScope: true,
                templateUrl: '/y/templates/patients/create-referral-contact-modal.html',
                controller: 'CreateReferralContactModalController as vm',
                locals: {
                    referralContactType: this.referralContactTypes[index],
                    referralAccount: this.selectedReferralAccount,
                },
            })
                .then(function (referralContact) {
                _this.selectedReferralContact = referralContact;
            });
        };
        PatientController.prototype.addressForDisplay = function (address) {
            return [address.address_1, address.address_2, address.city, address.state, address.zipcode]
                .filter(function (element) { return element; })
                .join(' ');
        };
        PatientController.prototype.submit = function () {
            var _this = this;
            if (this.selectedReferralAccount) {
                this.patient.care_level_change.referral_account_id = this.selectedReferralAccount.id;
            }
            else {
                delete this.patient.care_level_change.referral_account_id;
            }
            if (this.selectedReferralContact) {
                this.patient.care_level_change.referral_contact_id = this.selectedReferralContact.id;
            }
            else {
                delete this.patient.care_level_change.referral_contact_id;
            }
            this.errors = [];
            this.loading = true;
            var request = this.isExistingPatient()
                ? this.patientService.update(this.patient)
                : this.patientService.create(this.patient);
            request.then(function (response) {
                _this.loading = false;
                if (_this.isExistingPatient()) {
                    return _this.CurrentPatient.refreshPatient().then(function (success) {
                        return _this.$state.go('patients.view', { patientId: response.data.id });
                    });
                }
                else {
                    _this.$state.go('patients.view', { patientId: response.data.id });
                }
            }, function (response) {
                _this.loading = false;
                _this.logger.error('Could not save patient. Please check form errors and try again.');
                _this.errors = response.data.errors;
            });
        };
        PatientController.prototype.goToEdit = function () {
            this.$state.go('patients.edit', { patientId: this.currentPatient().id });
        };
        PatientController.prototype.isInvalid = function (key) {
            if (!this.errors) {
                return false;
            }
            return this.errors.some(function (value) {
                return value.includes(key);
            });
        };
        PatientController.prototype.onPcgChange = function () {
            if (!this.patient.pcg_id) {
                this.patient.cahps_survey_care_giver_id = null;
                this.patient.no_pcg = true;
                return;
            }
            this.patient.no_pcg = false;
            if (!this.isFamilyMemberCahpsEligible(this.getFamilyMember(this.patient.pcg_id))) {
                this.showCahpsWarning();
            }
            else {
                this.patient.cahps_survey_care_giver_id = null;
            }
        };
        PatientController.prototype.onNoPcgChange = function () {
            if (!this.patient.no_pcg) {
                this.patient.no_pcg = false;
            }
            else {
                this.patient.no_pcg = true;
                this.patient.pcg_id = null;
                this.patient.cahps_survey_care_giver_id = null;
            }
        };
        PatientController.prototype.isFamilyMemberCahpsEligible = function (family) {
            return !(family.patient_relationship === 'Facility' ||
                family.patient_relationship === 'Paid Care Giver');
        };
        PatientController.prototype.unmatchMasterPatientIndex = function () {
            var _this = this;
            this.patientService.unmatchMasterPatientIndex(this.currentPatient().id).then(function (response) {
                if (response.status === 204) {
                    _this.logger.success('Patient unmatched from EMPI successfully.');
                }
                else if (response.status === 404) {
                    _this.logger.info('Patient already unmatched from EMPI');
                }
                else {
                    _this.logger.info('Some unexpected action happened with status code :' + response.status);
                }
            }).catch(function (error) {
                console.log(error);
                _this.logger.error('Something went wrong.');
                throw error;
            }).finally(function () {
                _this.loading = false;
            });
        };
        PatientController.prototype.showCahpsWarning = function () {
            var warning = 'You have selected a Primary Care Giver that is a Facility or Paid Care Giver. ' +
                'These relationships are not eligible for the CAHPS Survey. ' +
                'If the patient has additional eligible contacts, ' +
                'they will display in the CAHPS Survey Care Giver box below and you may choose one to survey.';
            alert(warning);
        };
        PatientController.prototype.shouldShowCahps = function () {
            if (!this.patient) {
                return false;
            }
            if (this.patient.cahps_survey_care_giver_id) {
                return true;
            }
            if (!this.patient.pcg_id) {
                return false;
            }
            var family = this.getFamilyMember(this.patient.pcg_id);
            if (!family) {
                return false;
            }
            return !this.isFamilyMemberCahpsEligible(family);
        };
        PatientController.prototype.getFamilyMember = function (id) {
            if (!this.patientFamilyMembers || this.patientFamilyMembers.length === 0) {
                return null;
            }
            return this.patientFamilyMembers.filter(function (member) { return member.id === id; })[0];
        };
        PatientController.prototype.handleCommonWell = function (optIn) {
            if (optIn) {
                // TODO: Run commonwell opt-in on patient
            }
            else {
                // TODO: Run commonwell opt-out on patient
            }
        };
        PatientController.$inject = [
            '$mdDialog',
            '$scope',
            'officeService',
            'serviceService',
            'referralContactService',
            'Lookup',
            'options',
            'PatientMainService',
            '$state',
            'logger',
            'SessionService',
            'CurrentPatient',
            'familyMembersService',
            'cdsPatientDemographicsService',
            '$timeout'
        ];
        return PatientController;
    }());
    var DuplicatePatientDialogController = /** @class */ (function () {
        function DuplicatePatientDialogController($mdDialog, duplicatePatient, message, duplicateType) {
            this.$mdDialog = $mdDialog;
            this.duplicatePatient = duplicatePatient;
            this.message = message;
            this.duplicateType = duplicateType;
        }
        DuplicatePatientDialogController.prototype.close = function () {
            this.$mdDialog.hide();
        };
        DuplicatePatientDialogController.$inject = ['$mdDialog', 'duplicatePatient', 'message', 'duplicateType'];
        return DuplicatePatientDialogController;
    }());
    consolo.DuplicatePatientDialogController = DuplicatePatientDialogController;
    // ugly hack until we sort out what we want to do with loading indicator and if this problem is elsewhere
    var MatchHeightDirective = /** @class */ (function () {
        function MatchHeightDirective($timeout) {
            var _this = this;
            this.$timeout = $timeout;
            this.link = function ($scope, element, attrs) {
                _this.$timeout(function () {
                    var matchId = attrs.matchHeight;
                    var matchedHeight = angular.element(document.querySelectorAll('#' + matchId)[0])[0]
                        .clientHeight;
                    element.css({
                        height: matchedHeight + 'px',
                    });
                }, 2000);
            };
        }
        return MatchHeightDirective;
    }());
    consolo.MatchHeightDirective = MatchHeightDirective;
    angular
        .module('consolo')
        .component('consoloPatient', new PatientComponent())
        .directive('matchHeight', ['$timeout', function ($timeout) { return new MatchHeightDirective($timeout); }])
        .controller('DuplicatePatientDialogController', DuplicatePatientDialogController);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var PatientMainService = /** @class */ (function () {
            function PatientMainService($http, WebCache, Session, $q, logger) {
                this.$http = $http;
                this.WebCache = WebCache;
                this.Session = Session;
                this.$q = $q;
                this.logger = logger;
                this.patientApi = '/api/v1/patients';
                this.classicDashPatientsApi = '/api/v1/dashboard/patients';
                this.classicDashboardApi = '/api/v1/dashboard';
                this.page_size = 10;
                this.loadedPatients = [];
                this.defaults = this.WebCache.getType('patient');
                this.loadedPatientsDeferred = this.$q.defer();
            }
            PatientMainService.prototype.getAllFromCache = function (ids, force) {
                var _this = this;
                var sess = this.Session.get();
                return this.WebCache.fetchMatches('patient', ids).then(function (data) {
                    return _this.clean(data);
                });
            };
            PatientMainService.prototype.getClassicDashboardPage = function (page) {
                var _this = this;
                return this.$http
                    .get(this.classicDashboardApi + '/patients', {
                    params: { page: page, page_size: this.page_size },
                })
                    .then(function (resp) {
                    return _this.WebCache.extendArray('patient', 'id', resp.data, {
                        encrypt: true,
                        partial: 'alerts',
                    });
                })
                    .catch(function (e) {
                    _this.logger.error(e.data.errors[0], 'Error retrieving data');
                    throw e;
                });
            };
            PatientMainService.prototype.getClassicDashboardById = function (ids) {
                var _this = this;
                return this.$http
                    .post(this.classicDashboardApi + '/classic_dashboard_patients_by_id', {
                    patient_ids: ids,
                })
                    .then(function (resp) {
                    return _this.WebCache.extendArray('patient', 'id', resp.data, {
                        encrypt: true,
                        partial: 'alerts',
                    });
                })
                    .catch(function (e) {
                    _this.logger.error(e.data.errors.join(', '), 'Error retrieving patients');
                    throw e;
                });
            };
            PatientMainService.prototype.getClassicDashboardInfo = function (force) {
                return this.WebCache.cachableQuery(this.classicDashboardApi, null, { page_size: this.page_size, include_ids: true }, { force: force, expires: 5 });
            };
            PatientMainService.prototype.get = function (id, forceOverride) {
                var _this = this;
                var key = this.defaults.buildKey(id);
                return this.WebCache.fetch(key)
                    .then(function (patient) {
                    if ((patient && !forceOverride) ||
                        (forceOverride === undefined && !_this.defaults.force)) {
                        return patient;
                    }
                    patient = patient || {};
                    return _this.$http.get(_this.patientApi + '/' + id).then(function (resp) {
                        angular.extend(patient, resp.data);
                        return _this.WebCache.cache(key, patient, { encrypt: true })
                            .then(function (data) {
                            return data;
                        })
                            .catch(function (e) {
                            throw e;
                        });
                    });
                })
                    .catch(function (e) {
                    if (e.data && e.data.errors) {
                        _this.logger.error(e.data.errors[0]);
                    }
                    else {
                        _this.logger.error('Error retrieving data ' + id + ': ' + e);
                    }
                    throw e;
                });
            };
            PatientMainService.prototype.select = function (id, force) { };
            PatientMainService.prototype.getMenu = function (id, force) {
                return this.$http.get(this.patientApi + '/' + id + '/menu').then(function (data) {
                    return data.data;
                });
            };
            PatientMainService.prototype.getAlerts = function (id, force) { };
            PatientMainService.prototype.getHeaders = function (id, force) { };
            PatientMainService.prototype.getClinicalSummary = function (id, date) {
                var _this = this;
                var params = {
                    date: date
                };
                return this.$http.get(this.patientApi + "/" + id + "/clinical_summary", {
                    params: params
                }).catch(function (e) {
                    _this.logger.error(e.data.errors[0], 'Error retrieving data');
                    throw e;
                });
            };
            PatientMainService.prototype.cache = function (patient) {
                this.WebCache.cache(this.defaults.buildKey(patient.id), patient, { encrypt: true });
            };
            PatientMainService.prototype.extend = function (patient) {
                return this.WebCache.extend(this.defaults.buildKey(patient.id), patient, { encrypt: true });
            };
            PatientMainService.prototype.clean = function (data) {
                var now = Date.now();
                var oneDay = 1000 * 60 * 60 * 24 * 1;
                var cacheItem = false;
                for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                    var item = data_1[_i];
                    if (item.isNew && (!item.__isNew_timestamp || now - item.isNew_timestamp > oneDay)) {
                        cacheItem = true;
                        item.isNew = false;
                    }
                    if (cacheItem) {
                        this.cache(item);
                    }
                }
                return data;
            };
            PatientMainService.prototype.create = function (patient) {
                return this.$http.post(this.patientApi, patient);
            };
            PatientMainService.prototype.update = function (patient) {
                return this.$http.patch(this.patientApi + "/" + patient.id, patient);
            };
            PatientMainService.prototype.checkForDuplicateName = function (patient) {
                var q = { first_name: patient.first_name, last_name: patient.last_name };
                if (patient.middle_name) {
                    q.middle_name = patient.middle_name;
                }
                if (patient.suffix_name) {
                    q.suffix_name = patient.suffix_name;
                }
                return this.$http({
                    url: this.patientApi + '/duplicate_names',
                    params: q,
                    method: 'GET',
                });
            };
            PatientMainService.prototype.checkForDuplicateSsn = function (patient) {
                var q = { patient_ssn: patient.ssn };
                return this.$http({
                    url: this.patientApi + '/duplicate_ssn',
                    params: q,
                    method: 'GET',
                });
            };
            PatientMainService.prototype.unmatchMasterPatientIndex = function (id) {
                return this.$http.delete(this.patientApi + '/' + id + '/unmatch_empi');
            };
            PatientMainService.$inject = ['$http', 'WebCache', 'SessionService', '$q', 'logger'];
            return PatientMainService;
        }());
        services.PatientMainService = PatientMainService;
        angular.module('consolo').service('PatientMainService', PatientMainService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var about;
    About.$inject = ['AboutService'];
    function About(AboutService) {
        AboutService.get().then(function (a) {
            about = a;
        });
    }
    var PatientRootState = /** @class */ (function () {
        function PatientRootState($stateProvider) {
            $stateProvider
                .state('patients', {
                abstract: true,
                url: '/patients/:patientId',
                resolve: {
                    currentPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        'logger',
                        'SessionService',
                        '$state',
                        function ($params, CurrentPatient, logger, Session, $state) {
                            return CurrentPatient.getPatient(parseInt($params.patientId, 10)).then(function () {
                                var user = Session.get().user;
                                if (user.offices.filter(function (office) { return office.id === CurrentPatient.patient.office.id; })
                                    .length) {
                                    return CurrentPatient.patient;
                                }
                                else {
                                    logger.error('You do not have authorization to this patient.');
                                    $state.go('unauthorized');
                                }
                            }, function (e) {
                                console.log('Error Resolving currentPatient');
                                console.log(e);
                                logger.error('Patient id ' +
                                    $params.patientId +
                                    ' could not be retrieved from the server and is not in the cache', 'Error retrieving patient');
                                throw e;
                            });
                        },
                    ],
                },
            })
                .state('patients.edit', {
                url: '/edit',
                data: { title: 'Update Patient', hideSidebar: false },
                views: {
                    'main-content@': {
                        template: "<consolo-patient is-editing=\"true\" />",
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    formPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        function ($params, CurrentPatient) {
                            return CurrentPatient.getFormPatient($params.patientId);
                        },
                    ],
                },
            })
                // Edit Military Info
                .state('patients.edit-military', {
                url: '/edit-military',
                data: { title: 'Update Patient Military Info', hideSidebar: false, military: true },
                views: {
                    'main-content@': {
                        template: "<consolo-patient is-editing=\"true\" />",
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    formPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        function ($params, CurrentPatient) {
                            return CurrentPatient.getFormPatient($params.patientId);
                        },
                    ],
                },
            })
                // End of edit Military Info
                // Edit Patient Care Directives
                .state('patients.edit-patient-care', {
                url: '/edit-patient-care',
                data: { title: 'Update Patient Care Directives', hideSidebar: false, patientCare: true },
                views: {
                    'main-content@': {
                        template: "<consolo-patient is-editing=\"true\" />",
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    formPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        function ($params, CurrentPatient) {
                            return CurrentPatient.getFormPatient($params.patientId);
                        },
                    ],
                },
            })
                // End of edit Patient Care Directives
                .state('patients.view', {
                url: '/view',
                data: { title: 'View Patient', hideSidebar: false },
                views: {
                    'main-content@': {
                        template: "<consolo-patient/>",
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    formPatient: [
                        '$stateParams',
                        'CurrentPatient',
                        function ($params, CurrentPatient) {
                            return CurrentPatient.getFormPatient($params.patientId);
                        },
                    ],
                },
            })
                // Assignments and defaults
                .state('patients.assignments-defaults', {
                url: '/assignments',
                data: { title: 'Assignment Details' },
                views: {
                    'main-content@': {
                        template: '<assignments-index></assignments-index>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('add-patient', {
                url: '/patients/new',
                data: { title: 'Create a new Patient', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: "<consolo-patient></consolo-patient>",
                    },
                },
            })
                .state('patients.dashboard', {
                url: '/dashboard',
                data: { title: 'Patient Info', lockOpenPatientSidenav: true, allowSwUpdate: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/patient-dashboard.html',
                        controller: 'PatientDashboardController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.medications', {
                url: '/medications',
                data: { title: 'Medications' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/medications/medications.html',
                        controller: 'PatientMedicationsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.medications.new', {
                url: '/new',
                data: { title: 'Add Medication', allowSwUpdate: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/medications/medication.html',
                        controller: 'PatientMedicationController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    medication: [
                        function () {
                            return null;
                        },
                    ],
                    redirect: [
                        function () {
                            return true;
                        },
                    ],
                },
            })
                .state('patients.medications.edit', {
                url: '/:medicationId/edit',
                data: { title: 'Medication', allowSwUpdate: true },
                params: { allergyInteractions: null, drugInteractions: null },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/medications/medication.html',
                        controller: 'PatientMedicationController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    medication: [
                        '$stateParams',
                        'MedicationService',
                        function ($params, MedicationService) {
                            return MedicationService.get($params.medicationId).then(function (medication) {
                                if ($params.drugInteractions) {
                                    medication.drug_interactions = $params.drugInteractions;
                                }
                                if ($params.allergyInteractions) {
                                    medication.allergy_interactions = $params.allergyInteractions;
                                }
                                return medication;
                            });
                        },
                    ],
                    redirect: [
                        function () {
                            return true;
                        },
                    ],
                },
            })
                .state('patients.medications.dispose', {
                url: '/dispose',
                data: { title: 'Dispose Medications' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/medications/dispose.html',
                        controller: 'DisposeController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    activeMedications: [
                        'MedicationService',
                        '$filter',
                        'currentPatient',
                        function (MedicationService, $filter, currentPatient) {
                            return MedicationService.getActiveMedications().then(function (activeMedications) {
                                return activeMedications;
                            });
                        },
                    ],
                },
            })
                .state('patients.medications.refill', {
                url: '/refill',
                data: { title: 'Refill Medications' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/medications/refill.html',
                        controller: 'RefillController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    refillMedications: [
                        'MedicationService',
                        '$filter',
                        'currentPatient',
                        function (MedicationService, $filter, currentPatient) {
                            return MedicationService.getActiveMedications().then(function (activeMedications) {
                                return $filter('filter')(activeMedications, function (medication) {
                                    if (medication.isValidForRefill()) {
                                        return true;
                                    }
                                    return false;
                                });
                            });
                        },
                    ],
                },
            })
                .state('patients.tasks', {
                url: '/tasks',
                data: { title: 'Tasks' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/tasks/tasks.html',
                        controller: 'PatientTasksController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.tasks.new', {
                url: '/new',
                data: { title: 'Add Task' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/tasks/task.html',
                        controller: 'PatientTaskController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.custom-alerts', {
                url: '/custom-alerts',
                data: { title: 'Custom Alerts', allowSwUpdate: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/custom-alerts/custom-alerts.html',
                        controller: 'CustomAlertsController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.custom-alerts.new', {
                url: '/new',
                data: { title: 'Add Custom Alert' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/custom-alerts/custom-alert.html',
                        controller: 'CustomAlertController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.missed-visits', {
                url: '/missed-visits?clinical_trip_stop_point_id',
                data: { title: 'Missed Visits', allowSwUpdate: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/sections/missed-visits/missed-visits.html',
                        controller: 'MissedVisitsController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.missed-visits.new', {
                url: '/new',
                data: { title: 'Create a new Missed Visit' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/clinical-charts/sections/missed-visit/missed-visit.html',
                        controller: 'MissedVisitController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.medications.view', {
                url: '/:medicationId/view?rph_signature&physician_signature',
                data: { title: 'Medication', allowSwUpdate: true },
                views: {
                    'main-content@': {
                        template: '<view-medication current-patient="currentPatient" id="medication_id"/>',
                        controller: [
                            '$scope',
                            '$stateParams',
                            'currentPatient',
                            function ($scope, $stateParams, currentPatient) {
                                $scope.currentPatient = currentPatient;
                                $scope.medication_id = $stateParams.medicationId;
                            },
                        ],
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.documents', {
                url: '/documents',
                data: { title: 'Patient Documents', allowSwUpdate: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/documents/documents.html',
                        controller: 'PatientDocumentsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.documents.edit', {
                url: '/:documentId/edit',
                data: { title: 'Patient Document' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/documents/edit.html',
                        controller: 'EditPatientDocumentsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    patientDocument: [
                        '$http',
                        '$stateParams',
                        function ($http, $params) {
                            return $http.get("/api/v1/patient_documents/" + $params.documentId).then(function (resp) {
                                return resp.data;
                            });
                        },
                    ],
                },
            })
                .state('patients.ehi_export', {
                url: '/ehi_export',
                data: { title: 'EHI Export' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/ehi-export/ehi-export-new.html',
                        controller: 'EhiExportController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.ccda_export', {
                url: '/ccda_export',
                data: { title: 'CCDA Export' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/ccda-export/ccda-export-new.html',
                        controller: 'CcdaExportController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.electronic-documents', {
                url: '/electronic-documents?event',
                data: { title: 'Electronic Documents' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/electronic-documents/electronic-documents.html',
                        controller: 'ElectronicDocumentsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.electronic-documents.sign', {
                url: '/:electronicDocumentId/sign',
                data: { title: 'Sign an Electronic Document' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/electronic-documents/sign-electronic-document.html',
                        controller: 'SignElectronicDocumentController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.pharmacy-history', {
                url: '/pharmacy_history',
                data: { title: 'Pharmacy History', allowSwUpdate: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/medications/pharmacy-history.html',
                        controller: 'PharmacyHistoryController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    pharmacyHistory: [
                        '$stateParams',
                        '$http',
                        function ($stateParams, $http) {
                            return $http
                                .get('/api/v1/medications/pharmacy_history?patient_id=' + $stateParams.patientId)
                                .then(function (data) {
                                return data.data;
                            });
                        },
                    ],
                },
            })
                .state('patients.family-members', {
                url: '/family-members?name&address&email&referral_type&include_deleted&office_ids',
                data: {
                    hideSidebar: true,
                    allowSwUpdate: true,
                },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/family-members/family-members.html',
                        controller: 'FamilyMembersController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'families');
                        },
                    ],
                },
            })
                .state('patients.family-members.new', {
                url: '/new',
                data: {
                    hideSidebar: true,
                },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/family-members/family-members-edit.html',
                        controller: 'FamilyMembersEditController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'families');
                        },
                    ],
                },
            })
                .state('patients.family-members.view', {
                url: '/:familyMemberId',
                data: {
                    hideSidebar: true,
                    allowSwUpdate: true,
                },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/family-members/family-members-view.html',
                        controller: 'FamilyMembersViewController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'families');
                        },
                    ],
                },
            })
                .state('patients.family-members.edit', {
                url: '/:familyMemberId/edit',
                data: {
                    hideSidebar: true,
                },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/family-members/family-members-edit.html',
                        controller: 'FamilyMembersEditController as vm',
                    },
                    resolve: {
                        authorized: [
                            'RouteAuthService',
                            function (RouteAuth) {
                                return RouteAuth.canOrAlert('update', 'families');
                            },
                        ],
                    },
                },
            })
                .state('patients.messages-internal', {
                url: '/messages-internal',
                data: { title: 'Internal Messages' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/messages/internal-messages.html',
                        controller: 'InternalMessagesController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.billing-demographics', {
                url: '/billing_demographics',
                data: {
                    title: 'Billing Demographics',
                },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/billing-demographics/billing-demographics.html',
                        controller: 'BillingDemographicsController as vm',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.BillingDemographicsDataTableService" layout="column" flex="" ></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
            })
                .state('patients.edmonton_assessments', {
                url: '/edmonton_assessments',
                data: { title: 'Edmonton Assessments' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/edmonton-assessments/edmonton-assessments.html',
                        controller: 'EdmontonAssessmentsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.allergies', {
                url: '/allergies',
                data: { title: 'Allergies' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/sections/allergies/patient-allergy.html',
                        controller: 'PatientAllergyController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.indicators', {
                url: '/indicators',
                data: { title: 'Clinical Indicators' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicators.html',
                        controller: 'IndicatorsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.indicators.edit', {
                url: '/:indicatorId/edit',
                data: { title: 'Clinical Indicators' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-detail.html',
                        controller: 'IndicatorDetailController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.indicators.new', {
                url: '/new',
                data: { title: 'Clinical Indicators' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-detail.html',
                        controller: 'IndicatorDetailController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.indicators.view', {
                url: '/:indicatorId/view',
                data: { title: 'Clinical Indicators' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-detail.html',
                        controller: 'IndicatorDetailController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.care-programs', {
                url: '/care-programs',
                data: { title: 'Care Programs' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/care-programs/care-programs.html',
                        controller: 'CareProgramsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.care-programs.new', {
                url: '/new',
                data: { title: 'Care Programs' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/care-programs/care-programs.html',
                        controller: 'CareProgramsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.implantable-devices', {
                url: '/implantable-devices',
                data: { title: 'Implantable Devices' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/implantable-devices/implantable-devices.html',
                        controller: 'ImplantableDevicesController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'patient_devices');
                        },
                    ],
                },
            })
                .state('patients.implantable-devices.new', {
                url: '/new',
                data: { title: 'Implantable Devices' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/implantable-devices/implantable-devices.html',
                        controller: 'ImplantableDevicesController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'patient_devices');
                        },
                    ],
                },
            })
                .state('patients.diagnostic-image-results', {
                url: '/diagnostic_image_results',
                data: { title: 'Diagnostic Image Results' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/diagnostic-image-results/diagnostic-image-results.html',
                        controller: 'DiagnosticImageResultsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.diagnostic-image-results.new', {
                url: '/new',
                data: { title: 'Diagnostic Image Results' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/patients/diagnostic-image-results/diagnostic-image-results.html',
                        controller: 'DiagnosticImageResultsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'diagnostic_image_results');
                        },
                    ],
                },
            })
                .state('patients.vaccinations', {
                url: '/vaccinations',
                data: { title: 'Vaccinations' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/vaccinations/vaccinations.html',
                        controller: 'VaccinationsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    vaccination: function () { return false; },
                    historical: function () { return false; },
                    patientId: function () { return false; },
                    readonly: function () { return false; },
                },
            })
                .state('patients.fax', {
                url: '/fax',
                data: { title: 'Patient Fax' },
                views: {
                    'main-content@': {
                        template: '<patient-fax patient-id="patientId"></patient-fax>',
                        controller: [
                            '$scope',
                            '$stateParams',
                            function ($scope, $stateParams) {
                                $scope.patientId = $stateParams.patientId;
                            },
                        ],
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.fax.discharge-summaries', {
                url: '/discharge-summaries/:dischargeSummaryId',
                data: { title: 'Patient Fax' },
                views: {
                    'main-content@': {
                        template: '<patient-fax patient-id="patientId"></patient-fax>',
                        controller: [
                            '$scope',
                            '$stateParams',
                            function ($scope, $stateParams) {
                                $scope.patientId = $stateParams.patientId;
                            },
                        ],
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            });
        }
        PatientRootState.$inject = ['$stateProvider'];
        return PatientRootState;
    }());
    angular
        .module('consolo')
        .config(PatientRootState)
        .run(About);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PayerGroupController = /** @class */ (function () {
            function PayerGroupController(PayerGroupService, payerGroup, CurrentPatient, $state, logger, PayerService, PatientSectionManagerService, $mdDialog, Session) {
                var _this = this;
                this.PayerGroupService = PayerGroupService;
                this.payerGroup = payerGroup;
                this.CurrentPatient = CurrentPatient;
                this.$state = $state;
                this.logger = logger;
                this.PayerService = PayerService;
                this.PatientSectionManagerService = PatientSectionManagerService;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.showInternalFacilityRate = false;
                this.pickPayer = 'Please pick a Payer';
                this.saving = false;
                this.hasManagedCareOrganizations = false;
                this.selectedManagedCareOrganizations = [];
                this.noSecondaryPayer = true;
                this.noOtherPayers = true;
                this.insurancePoliciesCount = 0;
                this.insurancePoliciesCount = payerGroup.insurance_policies.length;
                if (this.insurancePoliciesCount > 1)
                    this.noSecondaryPayer = false;
                if (this.insurancePoliciesCount > 2)
                    this.noOtherPayers = false;
                this.types = [
                    { id: 'hospice', description: 'Hospice' },
                    { id: 'room_and_board', description: 'Room and Board' },
                    { id: 'encounter', description: 'Encounter' },
                ];
                var sess = this.Session.get();
                this.showInternalFacilityRate = sess.agency.has_internal_facility_rate;
                this.hasManagedCareOrganizations = sess.agency.has_managed_care_organizations;
                if (this.hasManagedCareOrganizations && this.payerGroup.id) {
                    this.payerGroup.insurance_policies.forEach(function (insurancePolicy, i) {
                        _this.PayerGroupService.selectOptions['managed_care_organizations'].some(function (org) {
                            if (org.description === insurancePolicy.managed_care_organization) {
                                _this.selectedManagedCareOrganizations[i] = org;
                                return true;
                            }
                        });
                    });
                }
            }
            //First Payer field selected - Medicaid/Medicare/Private/Charitable care
            PayerGroupController.prototype.payerTypeSelected = function (insurancePolicy, index) {
                if (insurancePolicy.position === undefined) {
                    this.insurancePoliciesCount++;
                    if (this.insurancePoliciesCount > 1) {
                        this.noSecondaryPayer = false;
                    }
                    if (this.insurancePoliciesCount > 2) {
                        this.noOtherPayers = false;
                    }
                }
                insurancePolicy = new consolo.domain.InsurancePolicy(insurancePolicy);
                insurancePolicy.position = index;
                this.payerGroup.insurance_policies[index] = insurancePolicy;
                insurancePolicy.will_pay = [];
                if (insurancePolicy.will_pay && !insurancePolicy.id) {
                    //If it's a new policy then set the will_pay from it's payer configuration
                    this.payerGroup.syncWillPay(insurancePolicy);
                }
                insurancePolicy.clearOutExistingPayerInfo();
            };
            PayerGroupController.prototype.deleteInsurancePolicy = function (index) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you would like to delete this Payer?');
                return this.$mdDialog.show(confirm).then(function () {
                    _this.insurancePoliciesCount--;
                    if (_this.insurancePoliciesCount <= 1)
                        _this.noSecondaryPayer = true;
                    if (_this.insurancePoliciesCount <= 2)
                        _this.noOtherPayers = true;
                    return _this.payerGroup.deleteInsurancePolicy(index);
                });
            };
            PayerGroupController.prototype.lookupMCO = function (query) {
                if (query) {
                    query = query.toLowerCase();
                    return this.PayerGroupService.selectOptions['managed_care_organizations'].filter(function (mco) {
                        return mco.description.toLowerCase().indexOf(query) > -1;
                    });
                }
                return this.PayerGroupService.selectOptions['managed_care_organizations'];
            };
            PayerGroupController.prototype.setDescription = function (insurancePolicy, item) {
                insurancePolicy.managed_care_organization = item.description;
            };
            PayerGroupController.prototype.save = function () {
                var _this = this;
                var errors = this.payerGroup.isValid();
                if (errors.length === 0) {
                    this.saving = true;
                    var newPayerGroup_1 = this.payerGroup.id ? false : true;
                    this.PayerGroupService.save(this.payerGroup)
                        .then(function (data) {
                        _this.logger.success('Payer group was saved.');
                        _this.PatientSectionManagerService.refreshMenu();
                        _this.payerGroup = new consolo.domain.PayerGroup(data.data);
                        if (newPayerGroup_1) {
                            _this.PayerGroupService.payerGroups.unshift(_this.payerGroup);
                        }
                        else {
                            for (var i = 0; i < _this.PayerGroupService.payerGroups.length; i++) {
                                if (_this.payerGroup.id === _this.PayerGroupService.payerGroups[i].id) {
                                    _this.PayerGroupService.payerGroups[i] = _this.payerGroup;
                                    i = _this.PayerGroupService.payerGroups.length;
                                }
                            }
                        }
                        _this.errors = null;
                        _this.$state.go('patients.payer-groups.view', {
                            patientId: _this.CurrentPatient.patient_id,
                            id: _this.payerGroup.id,
                        });
                    }, function (data) {
                        _this.errors = data.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.errors = errors;
                }
            };
            PayerGroupController.prototype.setWillPayOnPayerSelection = function (index, payer) {
                var _this = this;
                if (!this.payerGroup.insurance_policies[index].insurance_carrier_id) {
                    //Something with the autcomplete is setting the payer to undefined, the insurance carrier id should never be blank
                    //So delete it
                    this.payerGroup.deleteInsurancePolicy(index);
                }
                else {
                    var insurancePolicy = this.payerGroup.insurance_policies[index];
                    if (!payer) {
                        payer = insurancePolicy.getPayer();
                    }
                    if (payer) {
                        this.payerGroup.updateInsurancePolicy(insurancePolicy, payer.payer_configuration);
                        if (!insurancePolicy.payer_number) {
                            this.PayerGroupService.editDetails(insurancePolicy);
                        }
                        //If it comes back and there is combined AND primary hospice/room_and_board the combined needs to be changed
                        // to secondary whatever
                        if (this.payerGroup.willPay['combined_hospice_room_and_board'] &&
                            (this.payerGroup.willPay['primary_hospice'] ||
                                this.payerGroup.willPay['primary_room_and_board'])) {
                            //Find the insurance policy that has combined and have it run the code to set will_pay again
                            this.payerGroup.insurance_policies.forEach(function (insurancePolicy) {
                                if (insurancePolicy.will_pay.indexOf('combined_hospice_room_and_board') > -1) {
                                    _this.payerGroup.updateInsurancePolicy(insurancePolicy, insurancePolicy.getPayer().payer_configuration);
                                }
                            });
                        }
                        insurancePolicy.clearOutExistingPayerInfo();
                    }
                    else {
                        insurancePolicy.will_pay = [];
                        this.payerGroup.syncWillPay(insurancePolicy);
                    }
                }
            };
            PayerGroupController.$inject = [
                'PayerGroupService',
                'payerGroup',
                'CurrentPatient',
                '$state',
                'logger',
                'PayerService',
                'ConsoloPatientSectionManagerService',
                '$mdDialog',
                'SessionService',
            ];
            return PayerGroupController;
        }());
        angular.module('consolo').controller('PayerGroupController', PayerGroupController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PayerGroup = consolo.domain.PayerGroup;
        var PayerGroupService = /** @class */ (function () {
            function PayerGroupService($mdDialog, $http, logger, options, Session, PermissionsService, CurrentPatient) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.logger = logger;
                this.options = options;
                this.Session = Session;
                this.PermissionsService = PermissionsService;
                this.CurrentPatient = CurrentPatient;
                this.url = '/api/v1/payer_groups';
                this.showInternalFacilityRate = false;
                this.hasUpdatePermission = false;
                this.hasCreatePermission = false;
                this.hasDestroyPermission = false;
                var optionsList = [
                    'hospice_item_set_insurance_carriers',
                    'medicare_secondary_value_codes',
                    'relationships',
                    'managed_care_organizations',
                ];
                this.selectOptions = {};
                var sess = this.Session.get();
                this.showInternalFacilityRate = sess.agency.has_internal_facility_rate;
                optionsList.forEach(function (option) {
                    _this.options.get(option).$promise.then(function (data) {
                        _this.selectOptions[option] = data;
                    });
                });
                this.hasUpdatePermission = this.PermissionsService.can('update', 'payer_groups');
                this.hasCreatePermission = this.PermissionsService.can('create', 'payer_groups');
                this.hasDestroyPermission = this.PermissionsService.can('destroy', 'payer_groups');
                this.payers = [
                    { id: 1, description: 'Medicare' },
                    { id: 2, description: 'Medicaid' },
                    { id: 3, description: 'Private Insurance' },
                    { id: 4, description: 'Charitable' },
                ];
                this.agency = Session.get().agency;
            }
            PayerGroupService.prototype.editDetails = function (insurancePolicy) {
                insurancePolicy.setName();
                var dialog = {
                    clickOutsideToClose: true,
                    bindToController: true,
                    templateUrl: '/y/templates/payer-groups/payer-details.html',
                    controller: [
                        '$mdDialog',
                        'PayerGroupService',
                        function ($mdDialog, PayerGroupService) {
                            this.$mdDialog = $mdDialog;
                            this.PayerGroupService = PayerGroupService;
                        },
                    ],
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                    controllerAs: 'vm',
                    locals: {
                        insurancePolicy: insurancePolicy,
                        showInternalFacilityRate: this.showInternalFacilityRate,
                    },
                };
                this.$mdDialog.show(dialog);
            };
            PayerGroupService.prototype.byDate = function (patient_id, date) {
                return this.$http
                    .get(this.url + '/before_or_on', { params: { patient_id: patient_id, date: date } })
                    .then(function (data) {
                    return new PayerGroup(data.data);
                });
            };
            PayerGroupService.prototype.get = function (id) {
                return this.$http.get(this.url + '/' + id).then(function (data) {
                    return new PayerGroup(data.data);
                });
            };
            PayerGroupService.prototype.getByPatient = function (patientId) {
                return this.$http.get(this.url + '?patient_id=' + patientId).then(function (data) {
                    return data.data.map(function (payerGroup) {
                        return new PayerGroup(payerGroup);
                    });
                });
            };
            PayerGroupService.prototype.getNewPayerGroupDetails = function (patient_id) {
                return this.$http
                    .get(this.url + '/new_payer_group_details?patient_id=' + patient_id)
                    .then(function (data) {
                    return new consolo.domain.PayerGroup(data.data);
                });
            };
            PayerGroupService.prototype.delete = function (id) {
                var _this = this;
                return this.$http.delete(this.url + '/' + id).then(function (patientRecordDefault) {
                    _this.CurrentPatient.refreshPatient();
                });
            };
            PayerGroupService.prototype.getPayerDescription = function (id) {
                this.payers.forEach(function (payer) {
                    if (payer.id === id) {
                        return payer.description;
                    }
                });
                return '';
            };
            PayerGroupService.prototype.remove = function (id) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete this Payer Group?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.delete(id);
                });
            };
            PayerGroupService.prototype.save = function (payerGroup) {
                if (payerGroup.id) {
                    return this.update(payerGroup);
                }
                else {
                    return this.create(payerGroup);
                }
            };
            PayerGroupService.prototype.create = function (payerGroup) {
                return this.$http.post(this.url, payerGroup);
            };
            PayerGroupService.prototype.update = function (payerGroup) {
                return this.$http.patch(this.url + '/' + payerGroup.id, payerGroup);
            };
            PayerGroupService.$inject = [
                '$mdDialog',
                '$http',
                'logger',
                'options',
                'SessionService',
                'PermissionsService',
                'CurrentPatient',
            ];
            return PayerGroupService;
        }());
        services.PayerGroupService = PayerGroupService;
        angular.module('consolo').service('PayerGroupService', PayerGroupService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PayerGroupsIndexController = /** @class */ (function () {
            function PayerGroupsIndexController($state, PayerGroupService, currentPatient, $filter) {
                var _this = this;
                this.$state = $state;
                this.PayerGroupService = PayerGroupService;
                this.currentPatient = currentPatient;
                this.$filter = $filter;
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Type',
                            field: 'will_pay_display',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Payer',
                            field: 'payer',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (insurancePolicy) {
                                var payer = '';
                                switch (insurancePolicy.insurance_carrier_id) {
                                    case 1:
                                        payer = 'Medicare';
                                        break;
                                    case 2:
                                        payer = 'Medicaid';
                                        break;
                                    case 3:
                                        payer = 'Private Insurance';
                                        break;
                                    case 4:
                                        payer = 'Charitable';
                                }
                                return payer;
                            },
                        },
                        {
                            title: 'Name',
                            field: 'name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Member Number',
                            field: 'payer_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Suspended',
                            field: 'suspend_claims',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        {
                            title: 'Note(Link)',
                            field: 'note',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (insurancePolicy) {
                                if (insurancePolicy.forum_threads.length) {
                                    var admin_notes = '/patients/' + _this.currentPatient.id + '/forum_threads?note_type=Insurance';
                                    var note = insurancePolicy.forum_threads[0];
                                    return ('<a href="' +
                                        admin_notes +
                                        '">' +
                                        _this.$filter('date')(note.effective_date, 'MM/dd/yyyy') +
                                        '</a><label>' +
                                        note.note +
                                        '</label>');
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                        {
                            title: 'Precert',
                            field: 'precert',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Patient Liability',
                            field: 'patient_liability',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (policy) {
                                    return _this.$state.go('patients.payer-groups.view', {
                                        patientId: _this.currentPatient.id,
                                        id: policy.payer_group_id,
                                    });
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (policy) {
                                    return _this.$state.go('patients.payer-groups.edit', {
                                        patientId: _this.currentPatient.id,
                                        id: policy.payer_group_id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.PayerGroupService.hasUpdatePermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (policy) {
                                    return _this.PayerGroupService.remove(policy.payer_group_id).then(function () {
                                        for (var i = 0; i < _this.PayerGroupService.payerGroups.length; i++) {
                                            if (_this.PayerGroupService.payerGroups[i].id === policy.payer_group_id) {
                                                _this.PayerGroupService.payerGroups.splice(i, 1);
                                                i = _this.PayerGroupService.payerGroups.length;
                                                return true;
                                            }
                                        }
                                    }, function () {
                                        return false;
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.PayerGroupService.hasDestroyPermission;
                                },
                            },
                        ],
                    },
                };
            }
            PayerGroupsIndexController.prototype.newPayerGroup = function () {
                this.$state.go('patients.payer-groups.new');
            };
            PayerGroupsIndexController.$inject = ['$state', 'PayerGroupService', 'currentPatient', '$filter'];
            return PayerGroupsIndexController;
        }());
        angular.module('consolo').controller('PayerGroupsIndexController', PayerGroupsIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var PayerGroupsState = /** @class */ (function () {
        function PayerGroupsState($stateProvider) {
            $stateProvider
                .state('patients.payer-groups', {
                url: '/payer_groups',
                data: { title: 'Payer Groups' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/payer-groups/index.html',
                        controller: 'PayerGroupsIndexController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    payerGroups: [
                        'currentPatient',
                        'PayerGroupService',
                        function (currentPatient, PayerGroupService) {
                            return PayerGroupService.getByPatient(currentPatient.id).then(function (payerGroups) {
                                PayerGroupService.payerGroups = payerGroups;
                                return payerGroups;
                            });
                        },
                    ],
                },
            })
                .state('patients.payer-groups.new', {
                url: '/new',
                data: { title: 'Add Payer Group' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/payer-groups/edit.html',
                        controller: 'PayerGroupController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    payerGroup: [
                        'currentPatient',
                        'PayerService',
                        'PayerGroupService',
                        function (currentPatient, PayerService, PayerGroupService) {
                            return PayerService.payersReady.then(function () {
                                return PayerGroupService.getNewPayerGroupDetails(currentPatient.id).then(function (payerGroup) {
                                    payerGroup.id = null;
                                    payerGroup.insurance_policies.forEach(function (policy) {
                                        policy.payer_group_id = null;
                                        policy.forum_threads = [];
                                        policy.id = null;
                                        PayerService.setPayerFromService(policy);
                                    });
                                    //TODO this is a hack for effective date to not jump to tomorrow, long term fix is
                                    //find date issue in csg components
                                    payerGroup.effective_date = new Date(new Date().toDateString());
                                    payerGroup.insurance_policy_ids = [];
                                    return payerGroup;
                                }, function () {
                                    //This is not a true error, purposely return a 422 to know to do this.  It means this is the first payer_group for the patient
                                    return new consolo.domain.PayerGroup({
                                        effective_date: new Date(new Date().toDateString()),
                                        patient_id: currentPatient.id,
                                        insurance_policies: [],
                                    });
                                });
                            });
                        },
                    ],
                },
            })
                .state('patients.payer-groups.edit', {
                url: '/:id/edit',
                data: { title: 'Add Payer Group' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/payer-groups/edit.html',
                        controller: 'PayerGroupController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    payerGroup: [
                        '$stateParams',
                        'PayerGroupService',
                        'PayerService',
                        function ($stateParams, PayerGroupService, PayerService) {
                            return PayerGroupService.get($stateParams.id).then(function (payerGroup) {
                                return PayerService.payersReady.then(function () {
                                    payerGroup.insurance_policies.forEach(function (insurancePolicy) {
                                        PayerService.setPayerFromService(insurancePolicy);
                                    });
                                    return payerGroup;
                                });
                            });
                        },
                    ],
                },
            })
                .state('patients.payer-groups.view', {
                url: '/:id/view',
                data: { title: 'Add Payer Group' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/payer-groups/view.html',
                        controller: 'PayerGroupController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    payerGroup: [
                        '$stateParams',
                        'PayerGroupService',
                        'PayerService',
                        function ($stateParams, PayerGroupService, PayerService) {
                            return PayerGroupService.get($stateParams.id).then(function (payerGroup) {
                                return PayerService.payersReady.then(function () {
                                    return payerGroup;
                                });
                            });
                        },
                    ],
                },
            });
        }
        PayerGroupsState.$inject = ['$stateProvider'];
        return PayerGroupsState;
    }());
    angular.module('consolo').config(PayerGroupsState);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PayerService = /** @class */ (function () {
            function PayerService($http, $q, options) {
                var _this = this;
                this.$http = $http;
                this.$q = $q;
                this.options = options;
                this.url = '/api/v1/payers';
                this.payersReady = $q.all([
                    this.getMedicarePayers(true).then(function (medicarePayers) {
                        _this.medicarePayers = medicarePayers;
                    }),
                    this.getCharitableCarePayers(true).then(function (charitableCarePayers) {
                        _this.charitableCarePayers = charitableCarePayers;
                    }),
                ]);
                this.willPay = [];
                this.options.get('will_pay').$promise.then(function (data) {
                    _this.willPay = data;
                });
                this.willPayReadonly = [];
                this.options.get('will_pay_readonly').$promise.then(function (data) {
                    _this.willPayReadonly = data;
                });
                this.allWillPay = this.willPay.concat(this.willPayReadonly);
            }
            PayerService.prototype.getMedicarePayers = function (payer_group_selectable) {
                if (!payer_group_selectable) {
                    payer_group_selectable = false;
                }
                return this.$http
                    .get(this.url + '/medikare_payers?payer_group_selectable=' + payer_group_selectable)
                    .then(function (data) {
                    return data.data.map(function (medicarePayer) {
                        return medicarePayer;
                    });
                });
            };
            PayerService.prototype.getCharitableCarePayers = function (payer_group_selectable) {
                if (!payer_group_selectable) {
                    payer_group_selectable = false;
                }
                return this.$http
                    .get(this.url + '/charitable_care_payers?payer_group_selectable=' + payer_group_selectable)
                    .then(function (data) {
                    return data.data.map(function (charitableCarePayer) {
                        return charitableCarePayer;
                    });
                });
            };
            PayerService.prototype.getMedicaidPayers = function (compact, hasLocalPayer) {
                return this.$http
                    .get(this.url + '/medikaid_payers?', {
                    params: { compact: compact, payer_group_selectable: hasLocalPayer },
                })
                    .then(function (data) {
                    return data.data.map(function (medicaidPayer) {
                        return medicaidPayer;
                    });
                });
            };
            PayerService.prototype.lookupMedicaidPayers = function (name, payer_group_selectable) {
                return this.$http
                    .get(this.url + '/medikaid_payers', {
                    params: { name: name, payer_group_selectable: payer_group_selectable },
                })
                    .then(function (data) {
                    var payers = data.data.map(function (payer) {
                        return payer;
                    });
                    return payers;
                });
            };
            PayerService.prototype.lookupPrivateInsurancePayers = function (name, payer_group_selectable) {
                return this.$http
                    .get(this.url + '/private_insurance_payers', {
                    params: { name: name, payer_group_selectable: payer_group_selectable },
                })
                    .then(function (data) {
                    var payers = data.data.map(function (payer) {
                        return payer;
                    });
                    return payers;
                });
            };
            PayerService.prototype.getPrivateInsurancePayers = function (compact, hasLocalPayer) {
                return this.$http
                    .get(this.url + '/private_insurance_payers', {
                    params: { compact: compact, payer_group_selectable: hasLocalPayer },
                })
                    .then(function (data) {
                    return data.data.map(function (privateInsurancePayer) {
                        return privateInsurancePayer;
                    });
                });
            };
            PayerService.prototype.getPayerConfiguration = function (id) {
                return this.$http.get(this.url + '/payer_configurations/' + id).then(function (data) {
                    return data.data;
                });
            };
            PayerService.prototype.setPayerFromService = function (insurancePolicy) {
                if (insurancePolicy.medicare_payer_uuid) {
                    var payer = this.medicarePayers.filter(function (payer) {
                        return payer.id === insurancePolicy.medicare_payer_uuid;
                    })[0];
                    if (payer) {
                        insurancePolicy.medicare_payer = payer;
                    }
                }
                else {
                    var payer = this.charitableCarePayers.filter(function (payer) {
                        return payer.id === insurancePolicy.charitable_care_payer_uuid;
                    })[0];
                    if (payer) {
                        insurancePolicy.charitable_care_payer = payer;
                    }
                }
            };
            PayerService.prototype.getPayerConfigurationIdFromPayer = function (type, id) {
                if (type === 'medicare') {
                    return this.findPayer(this.medicarePayers, id);
                }
                else if (type === 'charitable_care') {
                    return this.findPayer(this.charitableCarePayers, id);
                }
            };
            PayerService.prototype.findPayer = function (payers, id) {
                for (var index = 0; index < payers.length; index++) {
                    if (payers[index].id === id) {
                        return payers[index].payer_configuration_id;
                    }
                }
            };
            PayerService.$inject = ['$http', '$q', 'options'];
            return PayerService;
        }());
        services.PayerService = PayerService;
        angular.module('consolo').service('PayerService', PayerService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClinicalChartSelectionController = /** @class */ (function () {
            function ClinicalChartSelectionController($mdDialog, clinicalCharts, physicianCharge) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.clinicalCharts = clinicalCharts;
                this.physicianCharge = physicianCharge;
                clinicalCharts.forEach(function (chart) {
                    if (physicianCharge.clinical_chart_id === chart.id) {
                        _this.selectedChart = chart;
                    }
                    chart.description =
                        chart.role_name +
                            ' Clinical Chart (' +
                            chart.id +
                            ') by ' +
                            chart.user.name +
                            ' Dated ' +
                            chart.effective_date;
                });
            }
            ClinicalChartSelectionController.$inject = ['$mdDialog', 'clinicalCharts', 'physicianCharge'];
            return ClinicalChartSelectionController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartSelectionController', ClinicalChartSelectionController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var Indicator = consolo.domain.Indicator;
        'use strict';
        angular.module('consolo').component('physicianCharge', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/physician-charges/physician-charge.html',
            controller: 'PhysicianChargeController as $ctrl',
        });
        var PhysicianChargeController = /** @class */ (function () {
            function PhysicianChargeController(PayerService, $state, PayerGroupService, PhysicianChargesService, logger, $http, MedicationService, CurrentPatient, Session, BillableCodesService, $mdDialog, CPTCodingService, IndicatorsService, DiagnosesService, IcdService) {
                var _this = this;
                this.PayerService = PayerService;
                this.$state = $state;
                this.PayerGroupService = PayerGroupService;
                this.PhysicianChargesService = PhysicianChargesService;
                this.logger = logger;
                this.$http = $http;
                this.MedicationService = MedicationService;
                this.CurrentPatient = CurrentPatient;
                this.Session = Session;
                this.BillableCodesService = BillableCodesService;
                this.$mdDialog = $mdDialog;
                this.CPTCodingService = CPTCodingService;
                this.IndicatorsService = IndicatorsService;
                this.DiagnosesService = DiagnosesService;
                this.IcdService = IcdService;
                this.saving = false;
                this.showModifierField = true;
                this.showModifierSelectionsField = false;
                this.selectedAddOnCode = [];
                this.loadingSpinner = false;
                this.diagnoses = [];
                this.placeExists = false;
                this.revenue_code_locked = false;
                this.addOnRevenueCodes = [];
                this.disableAddOnBtn = false;
                this.addOnCodeArrayCount = 8;
                this.getIcdTabularSearch();
                if ($state.params.physicianChargeId) {
                    PhysicianChargesService.get($state.params.physicianChargeId).then(function (physicianCharge) {
                        var _a;
                        _this.placeOfService = _this.PhysicianChargesService.placesOfService.filter(function (pos) {
                            return pos.code === physicianCharge.place_of_service;
                        })[0];
                        _this.physicianCharge = physicianCharge;
                        _this.physicianCharge.add_on_codes = (_a = physicianCharge.add_on_codes, (_a !== null && _a !== void 0 ? _a : []));
                        _this.physicianCharge.amount = Number(physicianCharge.amount);
                        _this.selectedCode = {
                            code: _this.physicianCharge.code,
                            amount: _this.physicianCharge.amount,
                            combined_code_modifier: _this.physicianCharge.code,
                            rate: _this.physicianCharge.amount,
                        };
                        _this.diagnoses = _this.physicianCharge.physician_charge_diagnoses;
                        _this.selectedAddOnCode = [];
                        for (var i = 0; i < _this.physicianCharge.add_on_codes.length; i++) {
                            _this.selectedAddOnCode.push({
                                code: _this.physicianCharge.add_on_codes[i].code,
                                amount: _this.physicianCharge.add_on_codes[i].amount,
                                combined_code_modifier: _this.physicianCharge.add_on_codes[i].code,
                                rate: _this.physicianCharge.add_on_codes[i].amount,
                            });
                        }
                        var firstSpaceIndex = _this.physicianCharge.code.indexOf(' ');
                        _this.physicianCharge.modifiers = _this.physicianCharge.code.substring(firstSpaceIndex + 1);
                        _this.physicianCharge.code = _this.agencyFeeFlag ? _this.physicianCharge.code.split(' ')[0] : physicianCharge.code.split(',')[0];
                    });
                }
                else {
                    var physician = Session.get().user.physician
                        ? Session.get().user.physician
                        : null;
                    this.physicianCharge = {
                        effective_date: new Date(),
                        physician: physician,
                        physician_id: physician ? physician.id : null,
                        physician_charge_diagnoses: [],
                        add_on_codes: [],
                        office: { id: null, name: '', corporate: null, region: null, area: null },
                        net_amount: 0.00,
                        adjustment_amount: 0.00
                    };
                    this.diagnoses = this.physicianCharge.physician_charge_diagnoses;
                    if (this.CurrentPatient.patient_id && this.CurrentPatient.isPatientPresent()) {
                        this.getPayers(this.CurrentPatient.patient);
                        this.physicianCharge.patient = this.CurrentPatient.patient;
                        this.physicianCharge.patient_id = this.CurrentPatient.patient_id;
                        this.dateOfDeath = this.CurrentPatient.patient.date_of_death;
                        this.physicianCharge.office = this.CurrentPatient.patient.office;
                    }
                }
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Priority',
                            field: 'label',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'ICD Code',
                            field: 'code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Move Up',
                            field: 'move_up',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (diagnosisItem) {
                                return diagnosisItem.position > 0 ? '<span class="material-icons">expand_less</span>' : '';
                            },
                            callbackFn: function (diagnosisItem) {
                                _this.diagnoses = _this.moveObjectInArray(_this.diagnoses, diagnosisItem.position, 'up');
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.PhysicianChargesService.updatePermission || _this.readonly;
                            },
                        },
                        {
                            title: 'Move Down',
                            field: 'move_down',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (diagnosisItem) {
                                var _a;
                                return diagnosisItem.position < ((_a = _this.diagnoses) === null || _a === void 0 ? void 0 : _a.length) - 1 ? '<span class="material-icons">expand_more</span>' : '';
                            },
                            callbackFn: function (diagnosisItem) {
                                _this.diagnoses = _this.moveObjectInArray(_this.diagnoses, diagnosisItem.position, 'down');
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.PhysicianChargesService.updatePermission || _this.readonly;
                            },
                        },
                        {
                            title: 'Delete',
                            field: 'delete',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (diagnosisItem) {
                                return '<span class="material-icons">delete</span>';
                            },
                            callbackFn: function (diagnosisItem) {
                                _this.diagnoses = _this.removeObjectFromArray(_this.diagnoses, diagnosisItem.position);
                                return true;
                            },
                            columnHideFn: function () {
                                return !_this.PhysicianChargesService.updatePermission || _this.readonly;
                            },
                        },
                    ],
                };
                this.session = Session.get();
                this.serviceLine = this.session.agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                this.agencyFeeFlag = this.session.agency.has_updated_agency_fee_schedule;
            }
            PhysicianChargeController.prototype.removeObjectFromArray = function (array, fromIndex) {
                array.splice(fromIndex, 1);
                this.setPositions(array);
                return [].concat(array);
            };
            PhysicianChargeController.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
                this.setPositions(array);
                return [].concat(array);
            };
            PhysicianChargeController.prototype.setPOSOnSelection = function () {
                if (this.placeOfService) {
                    this.physicianCharge.place_of_service = this.placeOfService.code;
                }
                else {
                    this.physicianCharge.place_of_service = null;
                }
            };
            PhysicianChargeController.prototype.appendObjectToArray = function (array, element) {
                array.splice(array.length, 0, element);
                this.setPositions(array);
                return [].concat(array);
            };
            PhysicianChargeController.prototype.setPositions = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i;
                    array[i].label = this.DiagnosesService.diagnosisLabel(i) + ' ICD10';
                }
            };
            PhysicianChargeController.prototype.checkCode = function () {
                if (this.physicianCharge.rate_locked) {
                    this.physicianCharge.rate_locked = false;
                    this.physicianCharge.amount = null;
                    this.physicianCharge.code = null;
                    this.selectedCode = null;
                }
                // if (this.physicianCharge.add_on_rate_locked) {
                //   this.physicianCharge.add_on_rate_locked = false;
                //   this.physicianCharge.add_on_amount = null;
                //   this.physicianCharge.add_on_code = null;
                //   this.physicianCharge.add_on_quantity = null;
                //   this.selectedAddOnCode = null;
                // }
            };
            PhysicianChargeController.prototype.getItemText = function (code) {
                return this.agencyFeeFlag ? code.code : code.combined_code_modifier;
            };
            PhysicianChargeController.prototype.setAdjAmount = function () {
                // When agencyFeeFlag is true and adjustment amount is zero - net charge should be equal to amount
                if (this.agencyFeeFlag && this.physicianCharge.adjustment_amount === 0) {
                    this.physicianCharge.amount = parseFloat(this.physicianCharge.amount.toFixed(2));
                    this.physicianCharge.net_amount = this.physicianCharge.amount ? this.physicianCharge.amount : 0;
                }
            };
            PhysicianChargeController.prototype.getPayersAndDeathDate = function (patient) {
                var _this = this;
                if (patient) {
                    this.CurrentPatient.getPatient(patient.id, true).then(function (patient) {
                        _this.dateOfDeath = patient.date_of_death;
                    });
                }
                else {
                    this.dateOfDeath = null;
                }
            };
            PhysicianChargeController.prototype.getPayers = function (patient) {
                var _this = this;
                this.physicianCharge.unified_payer_id = null;
                this.physicianCharge.payer_name = null;
                this.PayerGroupService.getNewPayerGroupDetails(patient.id).then(function (payerGroup) {
                    //At this point we have the current payer group, we need to get the payer that pays physician charges
                    payerGroup.insurance_policies.some(function (insurancePolicy) {
                        if ((insurancePolicy.will_pay.indexOf('primary_encounter') > -1 &&
                            insurancePolicy.getPayer().payer_configuration.claim_configurations.encounter &&
                            insurancePolicy.getPayer().payer_configuration.claim_configurations.encounter
                                .physician_charges) ||
                            (insurancePolicy.will_pay.indexOf('primary_hospice') > -1 &&
                                insurancePolicy.getPayer().payer_configuration.claim_configurations.hospice &&
                                insurancePolicy.getPayer().payer_configuration.claim_configurations.hospice
                                    .physician_charges)) {
                            _this.selectedPolicy = insurancePolicy;
                            _this.physicianCharge.unified_payer_id = insurancePolicy.getPayer().id;
                            _this.physicianCharge.payer_name = insurancePolicy.getPayer().name;
                            return true;
                        }
                    });
                });
            };
            PhysicianChargeController.prototype.lookupCptCodes = function (q) {
                if (!this.selectedPolicy) {
                    return this.CPTCodingService.getAllCPTCodes(q, null, null, this.physicianCharge.effective_date, this.physicianCharge.patient_id);
                }
                else {
                    return this.CPTCodingService.getAllCPTCodes(q, this.selectedPolicy.getPayer().id, this.selectedPolicy.insurance_carrier_id, this.physicianCharge.effective_date, this.physicianCharge.patient_id);
                }
            };
            PhysicianChargeController.prototype.lookupBillableCodes = function (q) {
                var _this = this;
                if (!this.selectedPolicy) {
                    return this.BillableCodesService.lookupCodes(q).then(function (codes) {
                        return codes;
                    });
                }
                else if (this.selectedPolicy.insurance_carrier_id === 1) {
                    return this.BillableCodesService.lookupMedicareCodes(q, this.selectedPolicy.getPayer().id).then(function (codes) {
                        return codes;
                    });
                }
                else if (this.selectedPolicy.insurance_carrier_id === 2) {
                    return this.BillableCodesService.lookupMedicaidCodes(q, this.selectedPolicy.getPayer().id).then(function (codes) {
                        return codes;
                    });
                }
                else if (this.selectedPolicy.insurance_carrier_id === 3) {
                    return this.BillableCodesService.lookupPrivateInsuranceCodes(q, this.selectedPolicy.getPayer().id).then(function (codes) {
                        return codes;
                    });
                }
                else if (this.selectedPolicy.insurance_carrier_id === 4) {
                    return this.BillableCodesService.lookupCharitableCareCodes(q, this.selectedPolicy.getPayer().id).then(function (codes) {
                        return _this.BillableCodesService.lookupCharitableCareCodes(q, _this.selectedPolicy.getPayer().id).then(function (codes) {
                            return codes;
                        });
                    });
                }
            };
            PhysicianChargeController.prototype.importPatientDiagnoses = function () {
                var _this = this;
                if (!this.physicianCharge || !this.physicianCharge.effective_date) {
                    this.logger.error('You need to select an effective date first.');
                }
                else {
                    if (this.physicianCharge.patient_id) {
                        this.DiagnosesService.getPatientDiagnoses(this.physicianCharge.patient_id)
                            .then(function (currentDiagnoses) {
                            currentDiagnoses.forEach(function (diagnosis) {
                                _this.IcdService.lookupIcd10(diagnosis.code).then(function (matchingIcd10s) {
                                    var _a;
                                    if (matchingIcd10s && matchingIcd10s.length > 0) {
                                        var icd10_1 = matchingIcd10s[0];
                                        // If the icd10 isn't already on the list, add it
                                        if (!((_a = _this.diagnoses) === null || _a === void 0 ? void 0 : _a.some(function (diagnosis) { return diagnosis.code === icd10_1.code; }))) {
                                            _this.addIcd10Code(icd10_1);
                                        }
                                    }
                                });
                                _this.logger.success('Imported patient diagnoses.');
                                return;
                            });
                        });
                    }
                    else {
                        this.logger.error('Unable to import patient diagnoses', 'Current patient inaccessible');
                    }
                }
            };
            PhysicianChargeController.prototype.getIcdTabularSearch = function () {
                var _this = this;
                this.IndicatorsService.getIcdTabularSearch()
                    .then(function (response) {
                    _this.icdTabularSearchTree = response.data;
                })
                    .catch(function (error) {
                    console.log('TABULAR SEARCH ERROR ===================');
                    console.log(error);
                });
            };
            PhysicianChargeController.prototype.showDiagnosisSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    multiple: true,
                    locals: {
                        indicator: new Indicator(),
                        icdTabularSearchTree: this.icdTabularSearchTree,
                    },
                })
                    .then(function (selectedIcd10) {
                    console.log(selectedIcd10);
                    _this.checkAndAddValidIcd(selectedIcd10);
                });
            };
            PhysicianChargeController.prototype.checkAndAddValidIcd = function (selectedIcd10) {
                var _a, _b;
                var icd10Prefix = selectedIcd10.code.split('')[0].toUpperCase();
                if (((_a = this.diagnoses) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    if (!((_b = this.diagnoses) === null || _b === void 0 ? void 0 : _b.some(function (diagnosis) { return diagnosis.code === selectedIcd10.code; }))) {
                        // If new selected ICD10 isn't a duplicate, add it to the list
                        this.addIcd10Code(selectedIcd10);
                    }
                    else {
                        // If new selected ICD10 is a duplicate throw error toast
                        this.logger.error('You may not have duplicated ICD-10 codes', 'ICD Already Exists');
                    }
                }
                else {
                    this.addIcd10Code(selectedIcd10);
                }
            };
            PhysicianChargeController.prototype.addIcd10Code = function (selectedIcd10) {
                var _a;
                var position = (_a = this.diagnoses) === null || _a === void 0 ? void 0 : _a.length;
                var physician_charge_diagnosis = {
                    icd10_id: selectedIcd10.icd10_id || selectedIcd10.id,
                    code: selectedIcd10.code,
                    label: this.DiagnosesService.diagnosisLabel(position) + ' ICD10',
                    description: selectedIcd10.description,
                    physician_charge_id: this.physicianCharge.id,
                    position: position
                };
                this.diagnoses = this.appendObjectToArray(this.diagnoses, physician_charge_diagnosis);
            };
            PhysicianChargeController.prototype.setModifiers = function (modifiers) {
                // the modifiers field from the physicianCharge model is used by the view to transform the data selected to something we can create/update for the physician charge
                this.physicianCharge.modifiers = modifiers.join();
            };
            PhysicianChargeController.prototype.assignClinicalChart = function () {
                var _this = this;
                if (!this.physicianCharge || !this.physicianCharge.effective_date) {
                    this.logger.error('You need to select an effective date first.');
                }
                else {
                    this.loadingSpinner = true;
                    this.PhysicianChargesService.getClinicalCharts(this.CurrentPatient.patient_id, this.physicianCharge.effective_date)
                        .then(function (clinicalCharts) {
                        _this.loadingSpinner = false;
                        if (clinicalCharts.length) {
                            return _this.$mdDialog
                                .show({
                                clickOutsideToClose: true,
                                template: "\n                  <md-dialog class=\"md-hue-1\" aria-label=\"Previous Chart Dialog\">\n                    <div ng-include=\"'/y/templates/physician-charges/clinical-chart-selection.modal.html'\"/>\n                  </md-dialog>",
                                controller: 'ClinicalChartSelectionController as $ctrl',
                                bindToController: true,
                                locals: { clinicalCharts: clinicalCharts, physicianCharge: _this.physicianCharge },
                            })
                                .then(function (selectedChart) {
                                _this.physicianCharge.clinical_chart_id = selectedChart.id;
                                _this.physicianCharge.clinical_chart_description = selectedChart.description;
                            });
                        }
                        else {
                            _this.logger.error('There are no clinical charts created today that match criteria to attach.');
                        }
                    })
                        .catch(function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    })
                        .finally(function () {
                        _this.loadingSpinner = false;
                    });
                }
            };
            PhysicianChargeController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.physicianCharge.code) {
                    this.errors.push('You must enter a code.');
                }
                if (!this.physicianCharge.amount) {
                    this.errors.push('You must enter an amount.');
                }
                // Check for modifier fields
                if (this.physicianCharge.modifiers) {
                    if (this.physicianCharge.modifiers.indexOf(' ') !== -1) {
                        this.errors.push('You must enter correct modifier format (separated by commas).');
                    }
                }
                else {
                    this.physicianCharge.modifiers = '';
                }
                if (this.agencyFeeFlag) {
                    this.physicianCharge.code = this.physicianCharge.code.split(' ')[0];
                    this.physicianCharge.code = this.physicianCharge.code + ' ' + this.physicianCharge.modifiers;
                }
                this.physicianCharge.revenue_code = this.revenue_code ? this.revenue_code.ub92_code : '';
                if (!this.saving && this.errors.length === 0) {
                    this.saving = true;
                    this.physicianCharge.physician_charge_diagnoses = this.diagnoses;
                    this.PhysicianChargesService.upsert(this.physicianCharge)
                        .then(function (response) {
                        _this.logger.success('The Physician Charge has been saved.');
                        if (_this.PhysicianChargesService.global) {
                            _this.$state.go('physician-charges', {});
                        }
                        else {
                            _this.$state.go('patients.physician-charges', {
                                patientId: _this.physicianCharge.patient_id,
                            });
                        }
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else if (this.errors.length > 0) {
                    this.logger.error(this.errors.join(', '));
                }
            };
            PhysicianChargeController.prototype.searchTextChange = function (searchText) {
                this.physicianCharge.code = searchText;
                if (!searchText && searchText.length === 0) {
                    this.physicianCharge.modifiers = '';
                    this.physicianCharge.amount = 0;
                    if (this.agencyFeeFlag && this.physicianCharge.adjustment_amount === 0) {
                        this.physicianCharge.net_amount = this.physicianCharge.amount ? this.physicianCharge.amount : 0;
                    }
                }
            };
            PhysicianChargeController.prototype.searchAddOnTextChange = function (index, searchText) {
                this.physicianCharge.add_on_codes[index].code = searchText;
                if (!searchText && searchText.length === 0) {
                    this.physicianCharge.add_on_codes[index].amount = null;
                }
            };
            PhysicianChargeController.prototype.updateCptCodes = function () {
                var _this = this;
                if (this.selectedCode) {
                    this.cpt_modifiers = [];
                    this.showModifierField = true;
                    if (this.selectedCode.cpt_code_rates) {
                        if (this.selectedCode.modifier) {
                            this.cpt_modifiers.push({ id: this.selectedCode.modifier, description: this.selectedCode.modifier });
                        }
                        if (this.selectedCode.modifier_2) {
                            this.cpt_modifiers.push({ id: this.selectedCode.modifier_2, description: this.selectedCode.modifier_2 });
                        }
                        if (this.selectedCode.modifier_3) {
                            this.cpt_modifiers.push({ id: this.selectedCode.modifier_3, description: this.selectedCode.modifier_3 });
                        }
                        this.selected_modifiers = this.cpt_modifiers.map(function (item) { return item.id; });
                        this.physicianCharge.modifiers = this.selected_modifiers.join(",");
                        this.showModifierSelectionsField = true;
                        this.showModifierField = false;
                    }
                    if (this.selectedCode.rate_allowable_amount) {
                        if (this.selectedCode.rate_percent) {
                            this.physicianCharge.adjustment_amount = parseFloat(((this.selectedCode.rate_allowable_amount / 100) * this.selectedCode.rate).toFixed(2));
                        }
                        else {
                            this.physicianCharge.adjustment_amount = parseFloat(this.selectedCode.rate_allowable_amount.toFixed(2));
                        }
                    }
                    if (!this.physicianCharge.modifiers) {
                        var firstSpaceIndex = this.physicianCharge.code.indexOf(' ');
                        this.physicianCharge.modifiers = this.physicianCharge.code.substring(firstSpaceIndex + 1);
                    }
                    this.physicianCharge.code = this.agencyFeeFlag ? this.selectedCode.code : this.selectedCode.combined_code_modifier;
                    this.physicianCharge.amount = parseFloat(this.selectedCode.rate.toFixed(2));
                    this.physicianCharge.net_amount = this.selectedCode.rate_value ? parseFloat(this.selectedCode.rate_value.toFixed(2)) : this.physicianCharge.net_amount;
                    // When agencyFeeFlag is true and adjustment amount is zero - net charge should be equal to amount
                    if (this.agencyFeeFlag && this.physicianCharge.adjustment_amount === 0) {
                        this.physicianCharge.net_amount = this.selectedCode.rate;
                    }
                    this.physicianCharge.rate_locked = true;
                    if (this.selectedCode.revenue_code) {
                        this.revenue_code = this.CPTCodingService.level_of_cares.find(function (loc) { return loc.ub92_code === _this.selectedCode.revenue_code; });
                        this.revenue_code_locked = true;
                    }
                    else if (!this.physicianCharge.id) {
                        this.revenue_code = '';
                        this.revenue_code_locked = false;
                    }
                    else {
                        this.revenue_code = this.CPTCodingService.level_of_cares.find(function (loc) { return loc.ub92_code === _this.physicianCharge.revenue_code; });
                        this.revenue_code_locked = true;
                    }
                }
                else {
                    //When there is no code and user has to add manually modifiers
                    this.showModifierField = true;
                    this.showModifierSelectionsField = false;
                    this.physicianCharge.amount = null;
                    this.physicianCharge.modifiers = '';
                    this.physicianCharge.rate_locked = false;
                    this.physicianCharge.net_amount = 0.0;
                    this.physicianCharge.adjustment_amount = 0.0;
                }
            };
            PhysicianChargeController.prototype.updateAddOnCptCodes = function (index) {
                if (this.selectedAddOnCode[index]) {
                    this.physicianCharge.add_on_codes[index].code = this.selectedAddOnCode[index].combined_code_modifier;
                    this.physicianCharge.add_on_codes[index].amount = Number(this.selectedAddOnCode[index].rate);
                    this.physicianCharge.add_on_codes[index].quantity = 1;
                    this.physicianCharge.add_on_codes[index].locked = true;
                    if (this.selectedAddOnCode[index].revenue_code) {
                        this.physicianCharge.add_on_codes[index].revenue_code = this.selectedAddOnCode[index].revenue_code;
                        this.addOnRevenueCodes[index] = this.CPTCodingService.levelOfCareLookup(this.physicianCharge.add_on_codes[index].revenue_code)[0];
                    }
                }
                else {
                    this.physicianCharge.add_on_codes[index].code = null;
                    this.physicianCharge.add_on_codes[index].amount = null;
                    this.physicianCharge.add_on_codes[index].quantity = null;
                    this.physicianCharge.add_on_codes[index].locked = false;
                    this.physicianCharge.add_on_codes[index].revenue_code = null;
                }
            };
            PhysicianChargeController.prototype.patientLookup = function (q) {
                return this.$http
                    .get('/api/v1/patients', { params: { q: q, active: true, all_offices: true } })
                    .then(function (response) {
                    return response.data;
                });
            };
            PhysicianChargeController.prototype.placeOfServiceLookup = function (q) {
                return this.PhysicianChargesService.placesOfService.filter(function (place) { return place.displayName.toLowerCase().includes(q.toLowerCase()); });
            };
            PhysicianChargeController.prototype.addAddOnCode = function () {
                if (this.physicianCharge.add_on_codes.length <= this.addOnCodeArrayCount) {
                    this.physicianCharge.add_on_codes.push({});
                    this.selectedAddOnCode.push(null);
                    this.addOnRevenueCodes.push(null);
                    this.disableAddOnButton();
                }
            };
            PhysicianChargeController.prototype.delete = function (index) {
                this.selectedAddOnCode.splice(index, 1);
                this.physicianCharge.add_on_codes.splice(index, 1);
                this.disableAddOnButton();
                this.addOnRevenueCodes.splice(index, 1);
            };
            PhysicianChargeController.prototype.disableAddOnButton = function () {
                if (this.physicianCharge.add_on_codes.length <= this.addOnCodeArrayCount) {
                    this.disableAddOnBtn = false;
                }
                else {
                    this.disableAddOnBtn = true;
                }
            };
            PhysicianChargeController.prototype.setRevenueCode = function (code) {
                if (code && code.description) {
                    this.revenue_code = code;
                }
            };
            PhysicianChargeController.prototype.setAddOnRevenue = function (item, index) {
                this.addOnRevenueCodes[index] = item;
                this.physicianCharge.add_on_codes[index].revenue_code = null;
                if (item) {
                    this.physicianCharge.add_on_codes[index].revenue_code = item.ub92_code;
                }
            };
            PhysicianChargeController.$inject = [
                'PayerService',
                '$state',
                'PayerGroupService',
                'PhysicianChargesService',
                'logger',
                '$http',
                'MedicationService',
                'CurrentPatient',
                'SessionService',
                'BillableCodesService',
                '$mdDialog',
                'CPTCodingService',
                'IndicatorsService',
                'DiagnosesService',
                'IcdService',
            ];
            return PhysicianChargeController;
        }());
        common.PhysicianChargeController = PhysicianChargeController;
        angular.module('consolo').controller('PhysicianChargeController', PhysicianChargeController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PhysicianChargesDataTableService = /** @class */ (function (_super) {
            __extends(PhysicianChargesDataTableService, _super);
            function PhysicianChargesDataTableService($http, $interval, PhysicianChargesService, $state, CurrentPatient, logger, $timeout, $mdDialog, Session, $sce) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.PhysicianChargesService = PhysicianChargesService;
                _this.$state = $state;
                _this.CurrentPatient = CurrentPatient;
                _this.logger = logger;
                _this.$timeout = $timeout;
                _this.$mdDialog = $mdDialog;
                _this.Session = Session;
                _this.$sce = $sce;
                _this.loadingData = false;
                _this.title = 'Physician/Provider Charges';
                _this.hideSearchTerm = false;
                _this.showFilters = true;
                _this.codeRyteEnabled = false;
                _this.http = $http;
                _this.activate();
                if (CurrentPatient.isPatientPresent()) {
                    _this.PhysicianChargesService.global = false;
                }
                return _this;
            }
            PhysicianChargesDataTableService.prototype.exportChargesToCSV = function () {
                this.PhysicianChargesService
                    .exportChargesToCSV(this.filters.getFilters())
                    .then(function (response) { return console.log(response); })
                    .catch(function (error) { return console.log(error); });
            };
            PhysicianChargesDataTableService.prototype.activate = function () {
                var _this = this;
                this.codeRyteEnabled = this.Session.get().agency.has_coderyte;
                this.agencyFeeFlag = this.Session.get().agency.has_updated_agency_fee_schedule;
                this.apiSelectUrl = '/api/v1/physician_charges';
                var columns = [
                    {
                        title: 'CodeRyte Status',
                        field: 'coderyte_status',
                        columnHideFn: function () {
                            return !_this.codeRyteEnabled;
                        },
                        formatValueFn: function (physicianCharge) {
                            if (!physicianCharge.coderyte_status) {
                                return '<span class="margin-right-xs label label-default">Not Sent</span>';
                            }
                            else if (physicianCharge.coderyte_status === 'Submitted') {
                                return '<span class="margin-right-xs label label-warning">Submitted</span>';
                            }
                            else if (physicianCharge.coderyte_status === 'Success') {
                                return '<span class="margin-right-xs label label-success">Success</span>';
                            }
                            else if (physicianCharge.coderyte_status === 'Error') {
                                return '<span class="margin-right-xs label label-danger">Error</span>';
                            }
                            else if (physicianCharge.coderyte_status === 'Code Not Found') {
                                return '<span class="margin-right-xs label label-danger">Code Not Found</span>';
                            }
                        },
                    },
                    {
                        title: 'Effective Date',
                        field: 'effective_date',
                        type: csg.datatable.ColumnTypes.date,
                        canSort: true,
                    },
                    {
                        title: 'Patient',
                        field: 'patient_name',
                        type: csg.datatable.ColumnTypes.link,
                        linkRoute: 'patients.dashboard({ patientId: $patient_id })',
                        formatValueFn: function (physicianCharge) {
                            return physicianCharge.patient.name;
                        },
                        canSort: true,
                    },
                    {
                        title: 'Date of Death',
                        field: 'death_date',
                        type: 'short-text',
                        formatValueFn: function (physicianCharge) {
                            if (physicianCharge.patient.date_of_death) {
                                return consolo.common.dateFormatMonthDayYear(physicianCharge.patient.date_of_death);
                            }
                            else {
                                return '';
                            }
                        }
                    },
                    {
                        title: 'Physician/Provider',
                        field: 'physician_name',
                        type: 'short-text',
                        canSort: true,
                        formatValueFn: function (physicianCharge) {
                            return physicianCharge.physician.name;
                        },
                    },
                    {
                        title: 'Payer Name',
                        field: 'payer_name',
                        type: csg.datatable.ColumnTypes.short_text,
                    },
                    {
                        title: 'Code',
                        field: 'code',
                        type: 'short-text',
                        formatValueFn: function (physicianCharge) {
                            if (_this.agencyFeeFlag) {
                                return physicianCharge.code.split(' ')[0];
                            }
                            else {
                                return physicianCharge.code.split(',')[0];
                            }
                        },
                        canSort: true,
                    },
                    {
                        title: 'Modifier 1',
                        field: 'modifier1',
                        type: csg.datatable.ColumnTypes.short_text,
                        columnHideFn: function () {
                            return !_this.agencyFeeFlag;
                        },
                        formatValueFn: function (physicianCharge) {
                            var firstSpaceIndex = physicianCharge.code.indexOf(' ');
                            if (firstSpaceIndex === -1) {
                                return '';
                            }
                            var modifiersString = physicianCharge.code.substring(firstSpaceIndex + 1);
                            var modifiersArray = modifiersString.split(',');
                            return modifiersArray[0] ? modifiersArray[0].trim() : '';
                        },
                        canSort: true,
                    },
                    {
                        title: 'Modifier 2',
                        field: 'modifier2',
                        type: csg.datatable.ColumnTypes.short_text,
                        columnHideFn: function () {
                            return !_this.agencyFeeFlag;
                        },
                        formatValueFn: function (physicianCharge) {
                            var firstSpaceIndex = physicianCharge.code.indexOf(' ');
                            if (firstSpaceIndex === -1) {
                                return '';
                            }
                            var modifiersString = physicianCharge.code.substring(firstSpaceIndex + 1);
                            var modifiersArray = modifiersString.split(',');
                            return modifiersArray[1] ? modifiersArray[1].trim() : '';
                        },
                        canSort: true,
                    },
                    {
                        title: 'Modifier 3',
                        field: 'modifier3',
                        type: csg.datatable.ColumnTypes.short_text,
                        columnHideFn: function () {
                            return !_this.agencyFeeFlag;
                        },
                        formatValueFn: function (physicianCharge) {
                            var firstSpaceIndex = physicianCharge.code.indexOf(' ');
                            if (firstSpaceIndex === -1) {
                                return '';
                            }
                            var modifiersString = physicianCharge.code.substring(firstSpaceIndex + 1);
                            var modifiersArray = modifiersString.split(',');
                            return modifiersArray[2] ? modifiersArray[2].trim() : '';
                        },
                        canSort: true,
                    },
                    {
                        title: 'Rate',
                        field: 'rate',
                        type: csg.datatable.ColumnTypes.short_text,
                        formatValueFn: function (physicianCharge) {
                            return consolo.common.moneyDisplay(physicianCharge.amount);
                        },
                        canSort: true,
                    },
                    {
                        title: 'Adjustment Amount',
                        field: 'adjustment_amount',
                        type: csg.datatable.ColumnTypes.short_text,
                        columnHideFn: function () {
                            return !_this.agencyFeeFlag;
                        },
                        formatValueFn: function (physicianCharge) {
                            if (physicianCharge.adjustment_amount !== 0) {
                                return consolo.common.moneyDisplay(physicianCharge.adjustment_amount);
                            }
                            else {
                                return "$0";
                            }
                        },
                        canSort: true,
                    },
                    {
                        title: 'Net Charge',
                        field: 'net_amount',
                        type: csg.datatable.ColumnTypes.short_text,
                        columnHideFn: function () {
                            return !_this.agencyFeeFlag;
                        },
                        formatValueFn: function (physicianCharge) {
                            if (physicianCharge.net_amount !== 0) {
                                return consolo.common.moneyDisplay(physicianCharge.net_amount);
                            }
                            else {
                                return "$0";
                            }
                        },
                        canSort: true,
                    },
                    {
                        title: 'Office',
                        field: 'office.name',
                        type: 'short-text',
                    },
                    {
                        title: 'Claim(s)',
                        field: 'claims',
                        type: csg.datatable.ColumnTypes.linkArray,
                        linkUrl: '/claims/$id',
                        arrayField: 'display_date_range',
                        arrayDelimiter: ',',
                    },
                    {
                        title: 'Sent',
                        field: 'sent',
                        type: 'short-text',
                        formatValueFn: function (physicianCharge) {
                            if (physicianCharge.claims && physicianCharge.claims.length) {
                                return physicianCharge.claims
                                    .map(function (claim) {
                                    if (claim.sent) {
                                        return consolo.common.dateFormatMonthDayYear(claim.sent);
                                    }
                                    else {
                                        return '';
                                    }
                                })
                                    .join(', ');
                            }
                            else {
                                return '';
                            }
                        },
                    },
                    {
                        title: 'Billed Amount(s)',
                        field: 'billed',
                        type: 'short-text',
                        formatValueFn: function (physicianCharge) {
                            if (physicianCharge.claim_line_items && physicianCharge.claim_line_items.length) {
                                return physicianCharge.claim_line_items
                                    .map(function (cli) {
                                    return consolo.common.moneyDisplay(cli.total_charge_amount);
                                })
                                    .join(', ');
                            }
                            else {
                                return '';
                            }
                        },
                    },
                    {
                        title: 'Place of Service',
                        field: 'place_of_service',
                        type: csg.datatable.ColumnTypes.short_text,
                    },
                    {
                        title: 'Payment(s)',
                        field: 'payments',
                        type: csg.datatable.ColumnTypes.linkArray,
                        linkUrl: '/payments/$id',
                        arrayField: 'check_date',
                        arrayDelimiter: ',',
                    },
                    {
                        title: 'Related ERA(s)',
                        field: 'claim_document_payments',
                        type: csg.datatable.ColumnTypes.linkArray,
                        linkUrl: '/claim_document_payments/$id',
                        arrayField: 'label',
                        arrayDelimiter: ',',
                    },
                ];
                if (!this.agencyFeeFlag) {
                    columns = columns.filter(function (column) { return column.field !== 'modifier1' && column.field !== 'modifier2' && column.field !== 'modifier3'; });
                }
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {},
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.default,
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    _this.exportChargesToCSV();
                                },
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: columns,
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (physicianCharge) {
                                    return _this.$state.go('patients.physician-charges.edit', {
                                        physicianChargeId: physicianCharge.id,
                                        patientId: physicianCharge.patient_id,
                                    });
                                },
                                conditionalHideFn: function (physicianCharge) {
                                    return !_this.PhysicianChargesService.updatePermission;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (physicianCharge) {
                                    return _this.$state.go('patients.physician-charges.view', {
                                        physicianChargeId: physicianCharge.id,
                                        patientId: physicianCharge.patient_id,
                                    });
                                },
                            },
                            {
                                label: 'Assign Clinical Chart',
                                icon: 'content_copy',
                                callbackFn: function (physicianCharge) {
                                    return _this.PhysicianChargesService.getClinicalCharts(physicianCharge.patient_id, physicianCharge.effective_date)
                                        .then(function (clinicalCharts) {
                                        if (clinicalCharts.length) {
                                            return _this.$mdDialog
                                                .show({
                                                clickOutsideToClose: true,
                                                template: "\n                      <md-dialog class=\"md-hue-1\" aria-label=\"Previous Chart Dialog\">\n                        <div ng-include=\"'/y/templates/physician-charges/clinical-chart-selection.modal.html'\"/>\n                       </md-dialog>",
                                                controller: 'ClinicalChartSelectionController as $ctrl',
                                                bindToController: true,
                                                locals: {
                                                    clinicalCharts: clinicalCharts,
                                                    physicianCharge: physicianCharge,
                                                },
                                            })
                                                .then(function (selectedChart) {
                                                physicianCharge.clinical_chart_id = selectedChart.id;
                                                return _this.PhysicianChargesService.upsert(physicianCharge).then(function (physicianCharge) {
                                                    _this.logger.success('The physician charge has had the clinical chart updated.');
                                                });
                                            });
                                        }
                                        else {
                                            _this.logger.error('There are no clinical charts created today that match criteria to attach.');
                                        }
                                    })
                                        .catch(function (errors) { });
                                },
                                conditionalHideFn: function (physicianCharge) {
                                    return !_this.PhysicianChargesService.updatePermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (physicianCharge) {
                                    return _this.PhysicianChargesService.deletePhysicianCharge(physicianCharge).then(function () {
                                        _this.PhysicianChargesService.delete(physicianCharge).then(function (updatedPhysicianCharge) {
                                            angular.copy(updatedPhysicianCharge, physicianCharge);
                                            _this.search();
                                            _this.logger.success('The physician charge has been deleted.');
                                            return true;
                                        }, function () {
                                            return false;
                                        });
                                    });
                                },
                                conditionalHideFn: function (physicianCharge) {
                                    return !_this.PhysicianChargesService.deletePermission ||
                                        (physicianCharge.claims && physicianCharge.claims.length > 0) ||
                                        (physicianCharge.claim_line_items && physicianCharge.claim_line_items.length > 0);
                                },
                            },
                            {
                                label: 'Repair Billable Amount',
                                icon: 'refresh',
                                callbackFn: function (physicianCharge) {
                                    return _this.PhysicianChargesService.updateAmount(physicianCharge)
                                        .then(function (updatedPhysicianCharge) {
                                        _this.search();
                                        _this.logger.success('The physician charge has had the amount updated.');
                                        return true;
                                    })
                                        .catch(function (errors) {
                                        _this.logger.error(errors.data.errors.join(', '));
                                    });
                                },
                                conditionalHideFn: function (physicianCharge) {
                                    return !_this.PhysicianChargesService.updatePermission || !_this.codeRyteEnabled;
                                },
                            },
                        ],
                    },
                };
            };
            PhysicianChargesDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = this.filters.getFilters();
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            PhysicianChargesDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.PhysicianChargesFilter();
                this.search();
            };
            PhysicianChargesDataTableService.$inject = [
                '$http',
                '$interval',
                'PhysicianChargesService',
                '$state',
                'CurrentPatient',
                'logger',
                '$timeout',
                '$mdDialog',
                'SessionService',
                '$sce',
            ];
            return PhysicianChargesDataTableService;
        }(csg.datatable.ApiService));
        services.PhysicianChargesDataTableService = PhysicianChargesDataTableService;
        angular
            .module('consolo')
            .service('PhysicianChargesDataTableService', PhysicianChargesDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('physicianCharges', {
            bindings: {},
            templateUrl: '/y/templates/physician-charges/physician-charges.html',
            controller: 'PhysicianChargesIndexController as $ctrl',
        });
        var PhysicianChargesIndexController = /** @class */ (function () {
            function PhysicianChargesIndexController(PhysicianChargesDataTableService, PhysicianChargesService, CurrentPatient, SidebarService, FilterSearchService, logger) {
                var _this = this;
                this.PhysicianChargesDataTableService = PhysicianChargesDataTableService;
                this.PhysicianChargesService = PhysicianChargesService;
                this.CurrentPatient = CurrentPatient;
                this.SidebarService = SidebarService;
                this.FilterSearchService = FilterSearchService;
                this.logger = logger;
                this.PhysicianChargesDataTableService.filters = new consolo.domain.PhysicianChargesFilter();
                if (!this.CurrentPatient.isPatientPresent()) {
                    this.PhysicianChargesDataTableService.showFilters = true;
                    this.SidebarService.close();
                    this.PhysicianChargesService.global = true;
                }
                else {
                    this.PhysicianChargesDataTableService.showFilters = false;
                    this.SidebarService.open();
                    this.PhysicianChargesService.global = false;
                    this.PhysicianChargesDataTableService.filters.patient_id = this.CurrentPatient.patient_id;
                }
                this.FilterSearchService.isReady().then(function () {
                    _this.PhysicianChargesDataTableService.search();
                });
            }
            PhysicianChargesIndexController.prototype.sendToCodeRyte = function () {
                var _this = this;
                return this.PhysicianChargesService.sendToCodeRyte().then(function (response) {
                    _this.logger.success('The unsent physician charges have been sent.');
                });
            };
            PhysicianChargesIndexController.$inject = [
                'PhysicianChargesDataTableService',
                'PhysicianChargesService',
                'CurrentPatient',
                'SidebarService',
                'FilterSearchService',
                'logger',
            ];
            return PhysicianChargesIndexController;
        }());
        common.PhysicianChargesIndexController = PhysicianChargesIndexController;
        angular
            .module('consolo')
            .controller('PhysicianChargesIndexController', PhysicianChargesIndexController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PhysicianChargesService = /** @class */ (function () {
            function PhysicianChargesService($http, PermissionsService, $mdDialog, logger, DiagnosesService) {
                var _this = this;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.DiagnosesService = DiagnosesService;
                this.apiUrl = '/api/v1/physician_charges';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.importPermission = false;
                this.global = false;
                this.updatePermission = this.PermissionsService.can('update', 'physician_charges');
                this.createPermission = this.PermissionsService.can('create', 'physician_charges');
                this.deletePermission = this.PermissionsService.can('destroy', 'physician_charges');
                this.viewPermission = this.PermissionsService.can('show', 'physician_charges');
                this.importPermission = this.PermissionsService.can('import', 'physician_charges');
                this.getPlacesOfService().then(function (response) {
                    _this.placesOfService = response.data;
                    _this.placesOfService = _this.placesOfService.concat({ code: null, displayName: 'Patient Care Level' });
                });
            }
            PhysicianChargesService.prototype.delete = function (physicianCharge) {
                return this.$http.delete(this.apiUrl + '/' + physicianCharge.id).then(function (response) {
                    return response.data;
                });
            };
            PhysicianChargesService.prototype.upsert = function (physicianCharge) {
                physicianCharge.effective_date = new Date(physicianCharge.effective_date).toDateString();
                if (physicianCharge.id) {
                    return this.$http
                        .patch(this.apiUrl + '/' + physicianCharge.id, physicianCharge)
                        .then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, physicianCharge).then(function (response) {
                        return response.data;
                    });
                }
            };
            PhysicianChargesService.prototype.updateAmount = function (physicianCharge) {
                return this.$http
                    .post(this.apiUrl + '/' + physicianCharge.id + '/update_amount', {})
                    .then(function (response) {
                    return response.data;
                });
            };
            PhysicianChargesService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            PhysicianChargesService.prototype.getClinicalCharts = function (patientId, effectiveDate) {
                return this.$http
                    .get('/api/v1/clinical_charts/headers', {
                    params: {
                        patient_id: patientId,
                        effective_date: effectiveDate,
                        has_signature: true,
                    },
                })
                    .then(function (response) {
                    return response.data.items;
                });
            };
            PhysicianChargesService.prototype.sendToCodeRyte = function () {
                return this.$http.get(this.apiUrl + '/submit_to_coderyte', {});
            };
            PhysicianChargesService.prototype.deletePhysicianCharge = function (physicianCharge) {
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirm Physician Charge Deletion')
                    .textContent('Are you sure you want to delete this physician charge?')
                    .ok('Delete')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirm);
            };
            PhysicianChargesService.prototype.getPhysicianCharges = function (start_date, end_date, patientId) {
                return this.$http
                    .get(this.apiUrl, {
                    params: { start_date: start_date, end_date: end_date, patient_id: patientId },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            PhysicianChargesService.prototype.getPlacesOfService = function () {
                return this.$http
                    .get('/api/v1/places_of_service')
                    .then(function (response) {
                    return response;
                });
            };
            PhysicianChargesService.prototype.checkAndAddValidIcd = function (selectedIcd10, arrDiagnoses, charge_id) {
                var _a, _b;
                var icd10Prefix = selectedIcd10.code.split('')[0].toUpperCase();
                if (((_a = arrDiagnoses) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    if (!((_b = arrDiagnoses) === null || _b === void 0 ? void 0 : _b.some(function (diagnosis) { return diagnosis.code === selectedIcd10.code; }))) {
                        // If new selected ICD10 isn't a duplicate, add it to the list
                        this.addIcd10Code(selectedIcd10, arrDiagnoses, charge_id);
                    }
                    else {
                        // If new selected ICD10 is a duplicate throw error toast
                        this.logger.error('You may not have duplicated ICD-10 codes', 'ICD Already Exists');
                    }
                }
                else {
                    this.addIcd10Code(selectedIcd10, arrDiagnoses, charge_id);
                }
            };
            PhysicianChargesService.prototype.addIcd10Code = function (selectedIcd10, arrDiagnoses, charge_id) {
                var _a;
                var position = (_a = arrDiagnoses) === null || _a === void 0 ? void 0 : _a.length;
                var physician_charge_diagnosis = {
                    icd10_id: selectedIcd10.icd10_id || selectedIcd10.id,
                    code: selectedIcd10.code,
                    label: this.DiagnosesService.diagnosisLabel(position) + ' ICD10',
                    description: selectedIcd10.description,
                    physician_charge_id: charge_id,
                    position: position
                };
                arrDiagnoses = this.appendObjectToArray(arrDiagnoses, physician_charge_diagnosis);
                return arrDiagnoses;
            };
            PhysicianChargesService.prototype.appendObjectToArray = function (array, element) {
                array.splice(array.length, 0, element);
                this.setPositions(array);
                return [].concat(array);
            };
            PhysicianChargesService.prototype.setPositions = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i;
                    array[i].label = this.DiagnosesService.diagnosisLabel(i) + ' ICD10';
                }
            };
            PhysicianChargesService.prototype.exportChargesToCSV = function (params) {
                return this.$http
                    .get('/api/v1/physician_charges/export', { params: params });
            };
            PhysicianChargesService.$inject = ['$http', 'PermissionsService', '$mdDialog', 'logger', 'DiagnosesService'];
            return PhysicianChargesService;
        }());
        services.PhysicianChargesService = PhysicianChargesService;
        angular.module('consolo').service('PhysicianChargesService', PhysicianChargesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('physician-charges', {
                url: '/physician_charges',
                data: { title: 'Physician/Provider Charges' },
                views: {
                    'main-content@': {
                        template: '<physician-charges></physician-charges>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.PhysicianChargesDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
            })
                .state('patients.physician-charges', {
                url: '/physician_charges',
                data: { title: 'New Physician/Provider Charges' },
                views: {
                    'main-content@': {
                        template: '<physician-charges><physicians-charge>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.PhysicianChargesDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
            })
                .state('physician-charges.new', {
                url: '/new',
                data: { title: 'New Physician/Provider Charges', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<physician-charge readonly="false"><physician-charge>',
                    },
                },
            })
                .state('patients.physician-charges.new', {
                url: '/new',
                data: { title: 'New Physician/Provider Charges' },
                views: {
                    'main-content@': {
                        template: '<physician-charge readonly="false"><physician-charge>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.physician-charges.edit', {
                url: '/:physicianChargeId/edit',
                data: { title: 'Edit Physician Charge' },
                views: {
                    'main-content@': {
                        template: '<physician-charge readonly="false"><physician-charge>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.physician-charges.view', {
                url: '/:physicianChargeId',
                data: { title: 'View Physician Charge' },
                views: {
                    'main-content@': {
                        template: '<physician-charge readonly="true"><physician-charge>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var PhysicianOrderSectionService = /** @class */ (function () {
            function PhysicianOrderSectionService(MedicationService, MedicationKitsService) {
                this.MedicationService = MedicationService;
                this.MedicationKitsService = MedicationKitsService;
                this.sections = {
                    generalDetails: { title: 'General Details', anchor: 'generalDetails', show: true },
                    orderedMedications: {
                        title: 'Ordered Medications',
                        selected: 0,
                        total: 0,
                        anchor: 'orderedMedications',
                        show: true,
                    },
                    openOrderMedications: {
                        title: 'Open Orders',
                        anchor: 'openOrderMedications',
                        show: false,
                        total: 0,
                        selected: 0,
                    },
                    distributionMedicationKits: {
                        title: 'Needs Distribution Order',
                        anchor: 'distributionMedicationKits',
                        selected: 0,
                        total: 0,
                        show: MedicationKitsService.combined_medication_kits,
                    },
                    utilizationMedicationKits: {
                        title: 'Needs Utilization Order',
                        anchor: 'utilizationMedicationKits',
                        selected: 0,
                        total: 0,
                        show: true,
                    },
                    discontinuedMedications: {
                        title: 'Discontinued Medications',
                        selected: 0,
                        total: 0,
                        anchor: 'discontinuedMedications',
                        show: MedicationKitsService.combined_medication_kits,
                    },
                    assignees: { title: 'Assignees', anchor: 'assignees', show: true, selected: 0, total: 0 },
                };
            }
            PhysicianOrderSectionService.prototype.setOrderedMedicationsCounts = function (selected, total) {
                this.sections.orderedMedications.selected = selected;
                this.sections.orderedMedications.total = total;
            };
            PhysicianOrderSectionService.prototype.setDiscontinuedMedicationsCounts = function (selected, total) {
                this.sections.discontinuedMedications.selected = selected;
                this.sections.discontinuedMedications.total = total;
            };
            PhysicianOrderSectionService.prototype.setAssigneesCounts = function (selected, total) {
                this.sections.assignees.selected = selected;
                this.sections.assignees.total = total;
            };
            PhysicianOrderSectionService.prototype.setDistributionCounts = function (selected, total) {
                this.sections.distributionMedicationKits.selected = selected;
                this.sections.distributionMedicationKits.total = total;
                this.sections.distributionMedicationKits.show = total > 0;
            };
            PhysicianOrderSectionService.prototype.setUtilizationCounts = function (selected, total) {
                this.sections.utilizationMedicationKits.selected = selected;
                this.sections.utilizationMedicationKits.total = total;
                // this.sections.utilizationMedicationKits.show = total > 0;
            };
            PhysicianOrderSectionService.prototype.setOpenOrderMedicationCounts = function (selected, total) {
                this.sections.openOrderMedications.selected = selected;
                this.sections.openOrderMedications.total = total;
                this.sections.openOrderMedications.show = total > 0;
            };
            PhysicianOrderSectionService.$inject = ['MedicationService', 'MedicationKitsService'];
            return PhysicianOrderSectionService;
        }());
        services.PhysicianOrderSectionService = PhysicianOrderSectionService;
        angular.module('consolo').service('PhysicianOrderSectionService', PhysicianOrderSectionService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Medication = consolo.domain.Medication;
        var PhysicianOrderController = /** @class */ (function () {
            function PhysicianOrderController($state, $window, logger, currentUser, CurrentPatient, physicianOrderStore, MedicationService, $mdDialog, NetworkManager, DraftSyncService, $filter, FaxGenerator, PhysicianOrdersService, PhysicianOrdersDataTableService, $stateParams, MedicationFavoritesService, MedicationKitsService, PhysicianOrderSectionService, Session, $scope, $document, $http) {
                var _this = this;
                this.$state = $state;
                this.$window = $window;
                this.logger = logger;
                this.currentUser = currentUser;
                this.CurrentPatient = CurrentPatient;
                this.physicianOrderStore = physicianOrderStore;
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.NetworkManager = NetworkManager;
                this.DraftSyncService = DraftSyncService;
                this.$filter = $filter;
                this.FaxGenerator = FaxGenerator;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.PhysicianOrdersDataTableService = PhysicianOrdersDataTableService;
                this.$stateParams = $stateParams;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.MedicationKitsService = MedicationKitsService;
                this.PhysicianOrderSectionService = PhysicianOrderSectionService;
                this.Session = Session;
                this.$http = $http;
                this.today = new Date();
                this.saving = false;
                this.assignees = [];
                this.users = [];
                this.loadingAssignees = false;
                this.isPhysician = false;
                this.selectedNewMedicationTransactions = [];
                this.selectedNewMedicationKitTransactions = [];
                this.selectedDiscontinuedMedicationTransactions = [];
                this.openOrderTransactions = [];
                this.selectedOpenOrderTransactions = [];
                this.loadingMedications = false;
                this.utilizationMedicationCount = 0;
                this.hasVisitFrequencyEnabled = false;
                this.has_resource_manager = this.Session.get().agency['has_resource_manager'];
                this.hasVisitFrequencyEnabled = this.PhysicianOrdersService.hasVisitFrequencyEnabled;
                this.medicationsLayout = {
                    options: {
                        expandCollapseAll: false,
                        hideSearch: true,
                    },
                    header: [
                        {
                            header: true,
                            title: 'Medication',
                            field: 'name',
                        },
                        {
                            header: true,
                            title: 'Dosage',
                            field: 'frequency',
                        },
                        {
                            header: true,
                            title: 'Instructions',
                            field: 'notes',
                        },
                        {
                            header: true,
                            title: 'Quantity',
                            field: 'quantity',
                        },
                        {
                            header: true,
                            title: 'Refills',
                            field: 'refills',
                            formatCustom: function (action, physicianOrder) {
                                if (!physicianOrder.refills) {
                                    return '';
                                }
                                return physicianOrder.refills.toString();
                            },
                        },
                    ],
                };
                if (NetworkManager.isOnline()) {
                    //Once medications & assignees are in the cache this will always be true
                    this.loadingAssignees = false;
                }
                this.currentMedicationTransactions = [];
                this.hasVitasEncounterCharting = this.Session.get().agency.has_vitas_encounter_charting;
                this.physicianOrderStore
                    .getWorkingCopy()
                    .then(function (copy) {
                    return copy.draft ? _this.askToUseDraft(copy) : _this.physicianOrderStore.useCopy(copy);
                })
                    .then(function () { return _this.setPhysicianOrder(); })
                    .catch(function () { return _this.setPhysicianOrder(); });
                this.users.push({ id: this.currentUser.id, description: this.currentUser.name });
                this.isPhysician =
                    this.currentUser.physician !== null && this.currentUser.physician.id !== null;
                this.MedicationService.getActiveCurrentMedications().then(function (medications) {
                    medications.forEach(function (medication) {
                        var transaction = _this.MedicationService.createMedicationTransaction('discontinue', medication);
                        _this.currentMedicationTransactions.push(_this.MedicationService.createMedicationTransaction('discontinue', medication));
                        if (medication.is_open_order) {
                            var resupply_transaction = _this.MedicationService.createMedicationTransaction('resupply', medication);
                            _this.openOrderTransactions.push(resupply_transaction);
                        }
                    });
                    _this.setOpenOrderCount();
                    _this.setDiscontinuedCount();
                });
            }
            PhysicianOrderController.prototype.changeDiscontinuedMedications = function () {
                var _this = this;
                if (!this.physicianOrder.orders) {
                    this.physicianOrder.orders = '';
                }
                this.changeMedications(this.currentMedicationTransactions, this.selectedDiscontinuedMedicationTransactions).then(function (selectedMedicationTransactions) {
                    _this.selectedDiscontinuedMedicationTransactions.forEach(function (medTransaction) {
                        _this.physicianOrder.orders = _this.physicianOrder.orders.replace(_this.discontinueMedicationDisplay(medTransaction), '');
                    });
                    _this.selectedDiscontinuedMedicationTransactions = selectedMedicationTransactions;
                    if (_this.selectedDiscontinuedMedicationTransactions.length) {
                        _this.selectedDiscontinuedMedicationTransactions.forEach(function (medTransaction) {
                            _this.physicianOrder.orders += _this.discontinueMedicationDisplay(medTransaction);
                        });
                    }
                    _this.setDiscontinuedCount();
                });
            };
            PhysicianOrderController.prototype.validateQuantity = function (medicationTransaction) {
                if (!medicationTransaction || medicationTransaction.quantity !== undefined) {
                    medicationTransaction.quantity = this.MedicationService.validateQuantity(medicationTransaction.quantity);
                }
            };
            PhysicianOrderController.prototype.discontinueMedicationDisplay = function (medicationTransaction) {
                return ('Discontinue Medication: ' + medicationTransaction.medication.display_name.trim() + '\n');
            };
            PhysicianOrderController.prototype.changeNewMedications = function () {
                var _this = this;
                this.changeMedications(this.pendingMedicationTransactions, this.selectedNewMedicationTransactions).then(function (updatedNewMedicationTransactions) {
                    _this.selectedNewMedicationTransactions = updatedNewMedicationTransactions;
                    _this.setOrderedCount();
                });
            };
            PhysicianOrderController.prototype.changeOpenOrders = function () {
                var _this = this;
                this.changeMedications(this.openOrderTransactions, this.selectedOpenOrderTransactions).then(function (updatedNewMedicationTransactions) {
                    _this.selectedOpenOrderTransactions = updatedNewMedicationTransactions;
                    _this.setOpenOrderCount();
                });
            };
            PhysicianOrderController.prototype.changeMedications = function (selectableMedicationTransactions, selectedMedicationTransactions) {
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/physician-order/medication-selection-modal.html',
                    controller: [
                        'MedicationService',
                        '$mdDialog',
                        'selectedMedicationTransactions',
                        'medicationTransactions',
                        function (MedicationService, $mdDialog, selectedMedicationTransactions, medicationTransactions) {
                            this.MedicationService = MedicationService;
                            this.$mdDialog = $mdDialog;
                            this.medicationTransactions = medicationTransactions;
                            this.selectedMedicationTransactions = selectedMedicationTransactions;
                        },
                    ],
                    controllerAs: '$ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: {
                        medicationTransactions: selectableMedicationTransactions,
                        selectedMedicationTransactions: angular.copy(selectedMedicationTransactions),
                    },
                });
            };
            PhysicianOrderController.prototype.addMedicationFavorites = function () {
                var _this = this;
                var antiInfective = [];
                if (!this.physicianOrder.physician_id) {
                    this.logger.error('You must select a prescriber first.');
                    this.$mdDialog.cancel();
                }
                else {
                    return this.MedicationFavoritesService.addMedicationFavorites(true, this.physicianOrder.physician_id).then(function (medications) {
                        medications.forEach(function (medication) {
                            var newTransaction = _this.MedicationService.createMedicationTransaction('newrx', new consolo.domain.Medication(medication));
                            _this.MedicationService.setPendingMedicationValues(_this.physicianOrder, newTransaction);
                            if (newTransaction.quantity !== null && newTransaction.quantity !== undefined) {
                                newTransaction.quantity = newTransaction.quantity.toString();
                            }
                            _this.selectedNewMedicationTransactions.push(newTransaction);
                            _this.pendingMedicationTransactions.push(newTransaction);
                            if (medication.is_anti_infective) {
                                antiInfective.push(medication.name);
                            }
                            _this.setOrderedCount();
                        });
                        _this.physicianOrder.newMedicationTransactions.sort(function (a, b) {
                            if (a.medication.name > b.medication.name) {
                                return 1;
                            }
                            if (a.medication.name < b.medication.name) {
                                return -1;
                            }
                            return 0;
                        });
                        if (antiInfective.length) {
                            confirm(antiInfective.join(', ') +
                                ' medication(s) have anti-infective checked by default, please ensure you fill out the anti-infective form by editing.');
                        }
                    });
                }
            };
            PhysicianOrderController.prototype.setPhysicianOrder = function () {
                var _this = this;
                this.physicianOrder = this.physicianOrderStore.model;
                this.frequencyData = this.physicianOrderStore.model.frequency_data;
                this.PhysicianOrdersService.initializePhysicianOrder(this.physicianOrder);
                this.selectedDiscontinuedMedicationTransactions = this.physicianOrder.discontinuedMedicationTransactions;
                this.physicianOrder.discontinuedMedicationTransactions.forEach(function (transaction) {
                    _this.currentMedicationTransactions.push(transaction);
                });
                this.selectedNewMedicationTransactions = this.physicianOrder.newMedicationTransactions;
                this.verbalReadBack = this.PhysicianOrdersService.getVerbalOnLoad(this.physicianOrder);
                this.PhysicianOrdersService.loadAssignees(this.physicianOrder).then(function (assignees) {
                    _this.assignees = assignees;
                    _this.loadingAssignees = false;
                    _this.setAssigneesCount();
                })
                    .catch(function (errors) {
                    _this.loadingAssignees = false;
                });
                this.setMedications();
                this.setPatientMedicationKits();
            };
            PhysicianOrderController.prototype.setShowSignature = function (physicianOrder) {
                var _this = this;
                physicianOrder.showAddSignature = true;
                physicianOrder.showAddPhysicianSignature = false;
                //If either no physician or the physician is different then they can sign for the physician on the Order
                if (!this.currentUser.physician ||
                    (this.currentUser.physician &&
                        this.currentUser.physician.id !== physicianOrder.physician_id)) {
                    physicianOrder.showAddPhysicianSignature = true;
                }
                if (physicianOrder.signatures && physicianOrder.signatures.length) {
                    physicianOrder.signatures.forEach(function (signature) {
                        //Check if the user still needs to sign for themself
                        if (signature.user_id === _this.currentUser.id && !signature.physician_id) {
                            physicianOrder.showAddSignature = false;
                        }
                        else if (_this.currentUser.physician &&
                            signature.user_id === _this.currentUser.id &&
                            signature.physician_id === _this.currentUser.physician.id) {
                            physicianOrder.showAddSignature = false;
                        }
                        //Check if it's not the Physician on this Order, and that the order still needs to sign for the physician
                        if (physicianOrder.showAddPhysicianSignature &&
                            signature.physician_id === physicianOrder.physician_id) {
                            physicianOrder.showAddPhysicianSignature = false;
                        }
                    });
                }
            };
            PhysicianOrderController.prototype.askToUseDraft = function (draft) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Draft Found')
                    .textContent('A draft was found for this Physician Order. Do you want to use it?')
                    .ok('Yes')
                    .cancel('No, discard it');
                return this.$mdDialog.show(confirmation).then(function () {
                    draft.model.effective_date = new Date(draft.model.effective_date);
                    _this.physicianOrder = _this.physicianOrderStore.useCopy(draft);
                }, function () {
                    var reallySure = _this.$mdDialog
                        .confirm()
                        .title('Are you sure?')
                        .textContent('Do you really want to delete this draft? This action cannot be undone.')
                        .ok('Yes')
                        .cancel('No');
                    return _this.$mdDialog.show(reallySure).then(function () {
                        return _this.physicianOrderStore.removeCopy(draft);
                    }, function () {
                        return _this.askToUseDraft(draft);
                    });
                });
            };
            PhysicianOrderController.prototype.setPatientMedicationKits = function () {
                var _this = this;
                this.needsDistributionTransactions = [];
                this.needsUtilizationKits = [];
                if (this.physicianOrder.id) {
                    this.physicianOrder.patient_medication_kit_transactions.forEach(function (transaction) {
                        if (transaction.physician_order_id === _this.physicianOrder.id) {
                            _this.needsDistributionTransactions.push(transaction);
                        }
                    });
                }
                this.MedicationService.getPatientMedicationKits(this.CurrentPatient.patient_id, true).then(function (patientMedicationKits) {
                    patientMedicationKits.forEach(function (patientMedicationKit) {
                        //If kit was clicked to create an order, add it
                        if (_this.$stateParams.patientMedicationKitId === patientMedicationKit.id &&
                            patientMedicationKit.needsDistributionOrder()) {
                            var transaction = _this.MedicationService.createPatientMedicationKitTransaction('distribution', patientMedicationKit);
                            _this.physicianOrder.patient_medication_kit_transactions.push(transaction);
                            _this.needsDistributionTransactions.push(transaction);
                        }
                        else if (patientMedicationKit.needsDistributionOrder()) {
                            //Add it to med kits that need distribution
                            _this.needsDistributionTransactions.push(_this.MedicationService.createPatientMedicationKitTransaction('distribution', patientMedicationKit));
                        }
                        else if (patientMedicationKit.distribution_physician_order_id === _this.physicianOrder.id) {
                            //Do Nothing, get it from the physician order itself
                        }
                        else if (patientMedicationKit.has_remaining_medications_to_administer) {
                            patientMedicationKit.medicationTransactions = [];
                            patientMedicationKit.medications.forEach(function (medication) {
                                var transaction = _this.MedicationService.createMedicationTransaction('newrx', medication);
                                if (medication.needsUtilizationOrder()) {
                                    patientMedicationKit.medicationTransactions.push(transaction);
                                    if (patientMedicationKit.id === _this.$stateParams.patientMedicationKitId) {
                                        _this.selectedNewMedicationKitTransactions.push(transaction);
                                    }
                                }
                                if (medication.id === parseInt(_this.$stateParams.medicationId, 10)) {
                                    //It was the medication clicked on profile page, add it as selected
                                    _this.selectedNewMedicationKitTransactions.push(transaction);
                                }
                                _this.utilizationMedicationCount += 1;
                            });
                            _this.needsUtilizationKits.push(patientMedicationKit);
                        }
                    });
                    _this.setDistributionCount();
                    _this.setUtilizationCount();
                });
            };
            PhysicianOrderController.prototype.editMedication = function (medicationTransaction, index) {
                var _this = this;
                this.MedicationService.editMedicationModal(medicationTransaction.medication).then(function (updatedMedication) {
                    if (medicationTransaction.id) {
                        _this.$http
                            .get('/api/v1/medication_transactions/' + medicationTransaction.id)
                            .then(function (response) {
                            var updatedMedicationTransaction = response.data;
                            var medication = new Medication(updatedMedicationTransaction.medication);
                            _this.selectedNewMedicationTransactions[index].medication = medication;
                            _this.selectedNewMedicationTransactions[index].notes =
                                updatedMedicationTransaction.notes;
                            _this.selectedNewMedicationTransactions[index].notes_to_pharmacist =
                                updatedMedicationTransaction.notes_to_pharmacist;
                            _this.selectedNewMedicationTransactions[index].eprescribe_instructions =
                                updatedMedicationTransaction.eprescribe_instructions;
                            _this.selectedNewMedicationTransactions[index].pharmacy_id =
                                updatedMedicationTransaction.pharmacy_id;
                            _this.selectedNewMedicationTransactions[index].pharmacy =
                                updatedMedicationTransaction.pharmacy;
                            _this.selectedNewMedicationTransactions[index].surescripts_pharmacy =
                                updatedMedicationTransaction.surescripts_pharmacy;
                            _this.selectedNewMedicationTransactions[index].surescripts_pharmacy_id =
                                updatedMedicationTransaction.surescripts_pharmacy_id;
                        })
                            .catch(function (errors) { });
                    }
                    else {
                        _this.selectedNewMedicationTransactions[index].medication = updatedMedication;
                        _this.selectedNewMedicationTransactions[index].notes = updatedMedication.notes;
                        _this.selectedNewMedicationTransactions[index].notes_to_pharmacist =
                            updatedMedication.notes_to_pharmacist;
                        _this.selectedNewMedicationTransactions[index].eprescribe_instructions =
                            updatedMedication.eprescribe_instructions;
                        _this.selectedNewMedicationTransactions[index].pharmacy_id =
                            updatedMedication.pharmacy_id;
                        _this.selectedNewMedicationTransactions[index].pharmacy = updatedMedication.pharmacy;
                        _this.selectedNewMedicationTransactions[index].surescripts_pharmacy =
                            updatedMedication.surescripts_pharmacy;
                        _this.selectedNewMedicationTransactions[index].surescripts_pharmacy_id =
                            updatedMedication.surescripts_pharmacy_id;
                    }
                });
            };
            PhysicianOrderController.prototype.setMedications = function () {
                var _this = this;
                if (!this.loadingMedications) {
                    var _physicianOrder_1 = this.physicianOrder;
                    this.pendingMedicationTransactions = [];
                    this.selectedNewMedicationTransactions = [];
                    this.selectedDiscontinuedMedicationTransactions = [];
                    this.loadingMedications = true;
                    if (this.physicianOrder.id) {
                        this.physicianOrder.medication_transactions.forEach(function (transaction) {
                            transaction.quantity = _this.MedicationService.validateQuantity(transaction.quantity).toString();
                            if (transaction.transaction_type === 'discontinue') {
                                _this.selectedDiscontinuedMedicationTransactions.push(transaction);
                            }
                            else if (transaction.transaction_type === 'resupply') {
                                _this.openOrderTransactions.push(transaction);
                                _this.selectedOpenOrderTransactions.push(transaction);
                            }
                            else {
                                _this.selectedNewMedicationTransactions.push(transaction);
                                _this.pendingMedicationTransactions.push(transaction);
                            }
                        });
                    }
                    this.MedicationService.getActiveNewMedications().then(function (medications) {
                        medications.forEach(function (medication) {
                            if (medication.physician_id === _this.physicianOrder.physician_id) {
                                var transaction = _this.MedicationService.createMedicationTransaction('newrx', medication);
                                if (transaction.quantity !== null && transaction.quantity !== undefined) {
                                    transaction.quantity = transaction.quantity.toString();
                                }
                                //If it's a new order and medication needs an order and physicians match, auto add
                                if (transaction.medication.needsPhysicianOrder() &&
                                    transaction.medication.physician_id === _physicianOrder_1.physician_id &&
                                    !_physicianOrder_1.id) {
                                    if (medication.is_open_order && medication.current_medication_transaction) {
                                        _this.openOrderTransactions.push(transaction);
                                        _this.selectedOpenOrderTransactions.push(transaction);
                                    }
                                    else {
                                        _this.pendingMedicationTransactions.push(transaction);
                                        _this.selectedNewMedicationTransactions.push(transaction);
                                    }
                                }
                                else if (transaction.medication.needsPhysicianOrder() &&
                                    transaction.medication.physician_id === _physicianOrder_1.physician_id) {
                                    _this.pendingMedicationTransactions.push(transaction);
                                }
                            }
                        });
                        _this.setOrderedCount();
                        _this.setOpenOrderCount();
                        _this.updateInteractions();
                        _this.loadingMedications = false;
                    });
                }
            };
            PhysicianOrderController.prototype.setOrderedCount = function () {
                this.PhysicianOrderSectionService.setOrderedMedicationsCounts(this.selectedNewMedicationTransactions.length, this.pendingMedicationTransactions.length);
            };
            PhysicianOrderController.prototype.setDiscontinuedCount = function () {
                this.PhysicianOrderSectionService.setDiscontinuedMedicationsCounts(this.selectedDiscontinuedMedicationTransactions.length, this.currentMedicationTransactions.length);
            };
            PhysicianOrderController.prototype.setOpenOrderCount = function () {
                this.PhysicianOrderSectionService.setOpenOrderMedicationCounts(this.selectedOpenOrderTransactions.length, this.openOrderTransactions.length);
            };
            PhysicianOrderController.prototype.assigneeChanged = function (assignee) {
                if (this.physicianOrder.assignee_ids &&
                    this.physicianOrder.assignee_ids.indexOf(assignee.id) > -1) {
                    this.PhysicianOrderSectionService.setAssigneesCounts(this.physicianOrder.assignee_ids.length - 1, this.assignees.length);
                }
                else if (this.physicianOrder.assignee_ids) {
                    this.PhysicianOrderSectionService.setAssigneesCounts(this.physicianOrder.assignee_ids.length + 1, this.assignees.length);
                }
            };
            PhysicianOrderController.prototype.utilizationMedicationsChanged = function (transaction) {
                if (this.selectedNewMedicationKitTransactions &&
                    this.selectedNewMedicationKitTransactions.indexOf(transaction) > -1) {
                    this.PhysicianOrderSectionService.setUtilizationCounts(this.selectedNewMedicationKitTransactions.length - 1, this.utilizationMedicationCount);
                }
                else if (this.selectedNewMedicationKitTransactions) {
                    this.PhysicianOrderSectionService.setUtilizationCounts(this.selectedNewMedicationKitTransactions.length + 1, this.utilizationMedicationCount);
                }
            };
            PhysicianOrderController.prototype.setAssigneesCount = function () {
                this.PhysicianOrderSectionService.setAssigneesCounts(this.physicianOrder.assignee_ids ? this.physicianOrder.assignee_ids.length : 0, this.assignees.length);
            };
            PhysicianOrderController.prototype.setDistributionCount = function () {
                this.PhysicianOrderSectionService.setDistributionCounts(this.physicianOrder.patient_medication_kit_transactions.length, this.needsDistributionTransactions.length);
            };
            PhysicianOrderController.prototype.setUtilizationCount = function () {
                this.PhysicianOrderSectionService.setUtilizationCounts(this.selectedNewMedicationKitTransactions.length, this.utilizationMedicationCount);
            };
            PhysicianOrderController.prototype.fieldClick = function (e) {
                e.stopPropagation();
            };
            PhysicianOrderController.prototype.faxOrder = function () {
                this.FaxGenerator.fax(this.physicianOrder.patient_id, {
                    physician_orders: [this.physicianOrder.id],
                    id: this.physicianOrder.patient_id,
                });
            };
            PhysicianOrderController.prototype.save = function () {
                var _this = this;
                if (!this.saving) {
                    this.saving = true;
                    if (!this.physicianOrder.id &&
                        this.physicianOrder.physician &&
                        this.physicianOrder.physician.epcs_enabled &&
                        this.physicianOrder.medication_transactions) {
                        var medispan = false;
                        var non_medispan = false;
                        this.physicianOrder.medication_transactions.forEach(function (medicationTransaction) {
                            if (medicationTransaction.medication.authoritative_source === 'medispan') {
                                medispan = true;
                            }
                            else {
                                non_medispan = true;
                            }
                        });
                        if (medispan && non_medispan) {
                            var confirmation = this.$mdDialog
                                .confirm()
                                .title('Are you sure?')
                                .textContent('You have items that can not be e-prescribed, would you like to have them split out into two orders?')
                                .ok('Yes - Split')
                                .cancel('No - Save');
                            this.$mdDialog.show(confirmation).then(function () {
                                if (_this.NetworkManager.isOnline()) {
                                    _this.physicianOrderStore.model.$splitOutOrder({}).then(function (data) {
                                        var ids = [];
                                        data.orders.forEach(function (po) {
                                            if (po.physician_order) {
                                                ids.push(po.physician_order.id);
                                            }
                                            else {
                                                ids.push(po.id);
                                            }
                                        });
                                        _this.$state.go('patients.physician-orders', {
                                            patientId: _this.$state.params.patientId,
                                            ids: ids.join(','),
                                        });
                                    });
                                }
                                else {
                                    _this.DraftSyncService.setModelFlag(_this.physicianOrderStore, 'syncSplitOrder', true);
                                }
                            }, function () {
                                _this.savePhysicianOrder();
                            });
                        }
                        else {
                            this.savePhysicianOrder();
                        }
                    }
                    else {
                        this.savePhysicianOrder();
                    }
                }
            };
            PhysicianOrderController.prototype.savePhysicianOrder = function () {
                var _this = this;
                if (this.NetworkManager.isOnline()) {
                    if (!this.isPhysician &&
                        !this.physicianOrder.non_verbal_order &&
                        !this.physicianOrder.read_back) {
                        this.errors = { errors: ['You must select Non Verbal or Read Back'] };
                        this.saving = false;
                    }
                    else {
                        var newOrder_1 = this.physicianOrderStore.model.id ? false : true;
                        this.physicianOrder.medication_transactions = this.selectedDiscontinuedMedicationTransactions
                            .concat(this.selectedNewMedicationTransactions)
                            .concat(this.selectedNewMedicationKitTransactions)
                            .concat(this.selectedOpenOrderTransactions);
                        this.physicianOrderStore
                            .save()
                            .then(function (physicianOrder) {
                            _this.logger.success('Saved');
                            if (newOrder_1) {
                                _this.PhysicianOrdersDataTableService.addPhysicianOrder(physicianOrder);
                            }
                            _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                            _this.PhysicianOrdersService.showSignatureDialog(physicianOrder);
                        }, function (errors) {
                            _this.errors = errors;
                        })
                            .catch(function (e) {
                            console.log(e);
                        })
                            .finally(function () {
                            _this.saving = false;
                        });
                    }
                }
                else {
                    var text = 'Would you like to save this Physician Order to the server when you come online?';
                    text += ' It is currently saved as a draft on your device';
                    var confirm = this.$mdDialog
                        .confirm()
                        .title('Sync Physician Order when Online?')
                        .textContent(text)
                        .ariaLabel('Confirm Sync Physician Order')
                        .ok('Sync Online')
                        .cancel('No');
                    this.$mdDialog.show(confirm).then(function () {
                        _this.DraftSyncService.setSyncFlag(_this.physicianOrderStore);
                        _this.logger.info('Will sync when online', 'Saved to device');
                    }, function () {
                        _this.logger.info('Saved to device');
                    });
                    this.saving = false;
                }
            };
            //Anytime a medication is added/removed we need to keep the interactions on the order up to date
            //Requested this so that physicians can see if they are signing an order that has any possible issues clearly
            PhysicianOrderController.prototype.updateInteractions = function (updated_medication_id) {
                var _this = this;
                this.drugInteractions = [];
                this.allergyInteractions = [];
                if (!this.physicianOrder.medication_transactions) {
                    this.physicianOrder.medication_transactions = [];
                }
                if (this.physicianOrder.medication_transactions.length) {
                    this.physicianOrder.medication_transactions.forEach(function (medicationTransaction) {
                        if (medicationTransaction.transaction_type === 'newrx') {
                            _this.addInteractions(medicationTransaction.medication);
                        }
                    });
                }
            };
            PhysicianOrderController.prototype.addInteractions = function (medication) {
                var _this = this;
                //populate all drug interactions
                if (medication.drug_interactions) {
                    medication.drug_interactions.forEach(function (interaction) {
                        _this.drugInteractions.push(interaction);
                    });
                }
                //populate all allergy interactions
                if (medication.allergy_interactions) {
                    medication.allergy_interactions.forEach(function (interaction) {
                        _this.allergyInteractions.push(interaction);
                    });
                }
            };
            PhysicianOrderController.prototype.addMedication = function () {
                var _this = this;
                this.MedicationService.addMedicationOnPhysicianOrder(this.physicianOrder).then(function (newMedication) {
                    if (newMedication.physician_id === _this.physicianOrder.physician_id) {
                        var newTransaction = _this.MedicationService.createMedicationTransaction('newrx', newMedication);
                        if (newTransaction.quantity !== null && newTransaction.quantity !== undefined) {
                            newTransaction.quantity = newTransaction.quantity.toString();
                        }
                        _this.pendingMedicationTransactions.push(newTransaction);
                        _this.selectedNewMedicationTransactions.push(newTransaction);
                        _this.setOrderedCount();
                    }
                });
            };
            PhysicianOrderController.prototype.setPhysician = function (physician) {
                this.physicianOrder.physician = physician;
                this.physicianOrder.physician_id = physician ? physician.id : null;
                this.setMedications();
            };
            PhysicianOrderController.prototype.redirectToMedicationRecord = function () {
                var url = this.$state.href('patients.medications', {
                    patientId: this.$state.params.patientId,
                });
                this.$window.open(url, '_blank');
            };
            PhysicianOrderController.prototype.redirectToAddMedication = function () {
                this.$window.open(this.$state.href('patients.medications.new', { patientId: this.$state.params.patientId }), '_blank');
            };
            PhysicianOrderController.$inject = [
                '$state',
                '$window',
                'logger',
                'currentUser',
                'CurrentPatient',
                'physicianOrderStore',
                'MedicationService',
                '$mdDialog',
                'NetworkManager',
                'DraftSyncService',
                '$filter',
                'FaxGenerator',
                'PhysicianOrdersService',
                'PhysicianOrdersDataTableService',
                '$stateParams',
                'MedicationFavoritesService',
                'MedicationKitsService',
                'PhysicianOrderSectionService',
                'SessionService',
                '$scope',
                '$document',
                '$http',
            ];
            return PhysicianOrderController;
        }());
        angular.module('consolo').controller('PhysicianOrderController', PhysicianOrderController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('physician-orders', {
                url: '/physician_orders?ids',
                data: { title: 'All Physician/Provider Orders' },
                views: {
                    'main-content@': {
                        template: '<view-physician-orders />',
                        controller: [
                            '$scope',
                            '$stateParams',
                            function ($scope, $stateParams) { },
                        ],
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.PhysicianOrdersDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'physician_orders');
                        },
                    ],
                },
            })
                .state('patients.physician-orders', {
                url: '/physician_orders?ids',
                data: { title: 'Physician/Provider Orders' },
                views: {
                    'main-content@': {
                        template: '<view-physician-orders />',
                        controller: [
                            '$scope',
                            '$stateParams',
                            function ($scope, $stateParams) { },
                        ],
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.PhysicianOrdersDataTableService" layout="column"  flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'physician_orders');
                        },
                    ],
                    currentUser: [
                        'SessionService',
                        function (Session) {
                            var session = Session.get();
                            return session.user;
                        },
                    ],
                },
            })
                .state('patients.physician-orders.new', {
                url: '/new?physician_id?medicationId?patientMedicationKitId',
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/physician-order/edit.html',
                        controller: 'PhysicianOrderController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/physician-order/sidebar.html',
                        controller: 'PhysicianOrderSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'physician_orders');
                        },
                    ],
                    physicianOrderStore: [
                        '$filter',
                        '$stateParams',
                        'PhysicianOrderEntry',
                        'PersistedItem',
                        'SessionService',
                        'Physician',
                        function ($filter, $params, PhysicianOrderEntry, PersistedItem, Session, Physician) {
                            var persisted_item = new PersistedItem('physician_order_entry', new PhysicianOrderEntry({ patient_id: parseInt($params.patientId, 10) }));
                            persisted_item.model.assessment_date = new Date().toDateString();
                            persisted_item.model.time_of_event = $filter('date')(new Date(), 'HHmm');
                            var user = Session.getUser();
                            if (user.physician === null || user.physician.id === null) {
                                persisted_item.model.nurse_id = user.id;
                            }
                            else {
                                persisted_item.model.physician = user.physician;
                            }
                            persisted_item.model.medication_transactions = [];
                            persisted_item.model.patient_medication_kit_transactions = [];
                            if ($params.physician_id) {
                                return Physician.get({ id: $params.physician_id }).$promise.then(function (physician) {
                                    persisted_item.model.physician = physician;
                                    return persisted_item;
                                }, function () {
                                    return persisted_item;
                                });
                            }
                            else {
                                return persisted_item;
                            }
                        },
                    ],
                },
            })
                .state('patients.physician-orders.view', {
                url: '/:id?rph_signature&physician_signature&include_last_page_signature_line',
                data: { lockOpenPatientSidenav: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/physician-order/view.html',
                        controller: 'ViewPhysicianOrderController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'physician_orders');
                        },
                    ],
                },
            })
                .state('patients.reorder-medications', {
                url: '/reorder_medications',
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/physician-order/reorder-medications.html',
                        controller: 'ReorderMedicationsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'medications');
                        },
                    ],
                    medications: [
                        'MedicationService',
                        function (MedicationService) {
                            return MedicationService.orderMedications;
                        },
                    ],
                    currentUser: [
                        'SessionService',
                        function (Session) {
                            return Session.get().user;
                        },
                    ],
                },
            })
                .state('patients.physician-orders.edit', {
                url: '/:id/edit',
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/physician-order/edit.html',
                        controller: 'PhysicianOrderController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/physician-order/sidebar.html',
                        controller: 'PhysicianOrderSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'physician_orders');
                        },
                    ],
                    physicianOrderStore: [
                        '$stateParams',
                        'PhysicianOrdersService',
                        'PersistedItem',
                        'PhysicianOrderEntry',
                        function ($params, PhysicianOrdersService, PersistedItem, PhysicianOrderEntry) {
                            if (PhysicianOrdersService.currentPhysicianOrder &&
                                PhysicianOrdersService.currentPhysicianOrder.id === $params.id) {
                                return new PersistedItem('physician_order_entry', PhysicianOrdersService.currentPhysicianOrder);
                            }
                            else {
                                return PhysicianOrderEntry.getById($params.id).then(function (physicianOrder) {
                                    return new PersistedItem('physician_order_entry', physicianOrder);
                                });
                            }
                        },
                    ],
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PhysicianOrdersDataTableService = /** @class */ (function (_super) {
            __extends(PhysicianOrdersDataTableService, _super);
            function PhysicianOrdersDataTableService($http, $interval, PhysicianOrdersService, $state, CurrentPatient, PdfGenerator, FaxGenerator, NetworkManager, persistenceStore, logger, $mdDialog) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.PhysicianOrdersService = PhysicianOrdersService;
                _this.$state = $state;
                _this.CurrentPatient = CurrentPatient;
                _this.PdfGenerator = PdfGenerator;
                _this.FaxGenerator = FaxGenerator;
                _this.NetworkManager = NetworkManager;
                _this.persistenceStore = persistenceStore;
                _this.logger = logger;
                _this.$mdDialog = $mdDialog;
                _this.loadingData = false;
                _this.title = 'Physician Orders';
                _this.showFilters = false;
                _this.searchOnReset = true;
                _this.activate();
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        return data.data;
                    }).catch(function () {
                        if (NetworkManager.isOffline()) {
                            return _this.persistenceStore.get("physician_order_entry", { patient_id: _this.CurrentPatient.patient_id }).then(function (result) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
                                if (result) {
                                    _this.data = [{
                                            assessment_date: ((_b = (_a = result) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.assessment_date) || null,
                                            patient_id: _this.CurrentPatient.patient_id || null,
                                            patient: {
                                                id: _this.CurrentPatient.patient_id || null,
                                                name: _this.CurrentPatient.patient.name || null,
                                            },
                                            time_of_event: ((_d = (_c = result) === null || _c === void 0 ? void 0 : _c.model) === null || _d === void 0 ? void 0 : _d.time_of_event) || null,
                                            physician_id: ((_g = (_f = (_e = result) === null || _e === void 0 ? void 0 : _e.model) === null || _f === void 0 ? void 0 : _f.physician) === null || _g === void 0 ? void 0 : _g.id) || null,
                                            physician: {
                                                id: ((_k = (_j = (_h = result) === null || _h === void 0 ? void 0 : _h.model) === null || _j === void 0 ? void 0 : _j.physician) === null || _k === void 0 ? void 0 : _k.id) || null,
                                                name: ((_o = (_m = (_l = result) === null || _l === void 0 ? void 0 : _l.model) === null || _m === void 0 ? void 0 : _m.physician) === null || _o === void 0 ? void 0 : _o.name) || null,
                                            },
                                            orders: ((_q = (_p = result) === null || _p === void 0 ? void 0 : _p.model) === null || _q === void 0 ? void 0 : _q.orders) || null,
                                            medication_transactions: [],
                                            locked_discontinued_medications: null,
                                            patient_medication_kit_transactions: [],
                                            oversight: ((_s = (_r = result) === null || _r === void 0 ? void 0 : _r.model) === null || _s === void 0 ? void 0 : _s.oversight) || false,
                                            non_verbal_order: ((_u = (_t = result) === null || _t === void 0 ? void 0 : _t.model) === null || _u === void 0 ? void 0 : _u.read_back) === true ? false : true,
                                            assignee_ids: [],
                                            assignees: [],
                                            locked: false,
                                            e_prescribe: false,
                                            e_prescribe_available: false,
                                            validate_e_prescribe: [],
                                            deleted_at: null,
                                            read_back: ((_w = (_v = result) === null || _v === void 0 ? void 0 : _v.model) === null || _w === void 0 ? void 0 : _w.read_back) === true ? true : false
                                        }];
                                    return _this.data;
                                }
                                else {
                                    _this.logger.info('No offline physician orders found to display');
                                    _this.data = [];
                                    return _this.data;
                                }
                            }).catch(function () {
                                _this.logger.info('No offline physician orders found to display');
                                _this.data = [];
                                return _this.data;
                            });
                        }
                    }).finally(function () {
                        _this.loadingData = false;
                    });
                };
                return _this;
            }
            PhysicianOrdersDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v2/physician_orders';
                this.filters = new consolo.domain.PhysicianOrderFilter();
                this.deviceSettingsPageName = consolo.deviceSettings.Pages.physician_order_search;
                var getSubtableColumns = function () {
                    if (!_this.PhysicianOrdersService.hasVisitFrequencyEnabled) {
                        return [
                            {
                                title: 'Assignees',
                                field: 'assignees',
                                formatValueFn: function (physicianOrder) {
                                    var assigneeDisplay = '';
                                    physicianOrder.assignees.forEach(function (assignee) {
                                        assigneeDisplay += assignee.description + '<br>';
                                    });
                                    return assigneeDisplay;
                                },
                                size: 3,
                            },
                        ];
                    }
                    else {
                        return [
                            {
                                title: 'Assignments',
                                field: 'discipline',
                                formatValueFn: function (physicianOrder) {
                                    console.log(physicianOrder);
                                    var subTableHtml = '';
                                    subTableHtml = "<div>\n                                  <table class=\"simple-table\">\n                                    <tr>\n                                      <th>Discipline</th>\n                                      <th>Effective Date</th>\n                                      <th>Visits</th>\n                                      <th >PRN Visits</th>\n                                    </tr>";
                                    physicianOrder.assignees.forEach(function (assignee) {
                                        subTableHtml += "<tr>\n                                    <td>" + assignee.discipline + "</td>\n                                    <td> " + (assignee.assignment_effective_date || "") + "</td>\n                                    <td>" + (assignee.visit_frequency_string || "") + "</td>\n                                    <td>" + (assignee.prn_frequency_string || "") + "</td>\n                                  </tr>";
                                    });
                                    subTableHtml = subTableHtml + "</table></div>";
                                    return subTableHtml;
                                }
                            }
                        ];
                    }
                };
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: true,
                        hideTextFilter: true,
                        allowRowSelect: true,
                        cssClassFn: function (physicianOrder) {
                            if (physicianOrder.deleted_at) {
                                return 'deleted-order';
                            }
                            else {
                                return '';
                            }
                        },
                    },
                    headerMenu: {
                        items: [
                            {
                                label: 'Print Physician Orders',
                                icon: 'print',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    var physicianOrderIdSets = data.map(function (selectedOrder) {
                                        return { orderId: selectedOrder.id, patientId: selectedOrder.patient_id };
                                    });
                                    return _this.printFilteredOrders(physicianOrderIdSets);
                                },
                                showCount: true,
                            },
                            {
                                label: 'Fax Physician Orders',
                                icon: 'send',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    var patientId = null;
                                    var physicianOrderIdSets = data.map(function (selectedOrder) {
                                        if (!patientId) {
                                            patientId = selectedOrder.patient_id;
                                        }
                                        return { orderId: selectedOrder.id, patientId: patientId };
                                    });
                                    return _this.FaxGenerator.fax(patientId, {
                                        physician_orders: physicianOrderIdSets,
                                    });
                                },
                                showCount: true,
                                conditionalHideFn: function () {
                                    return !_this.CurrentPatient.isPatientPresent();
                                },
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                        csvContents: true,
                    },
                    columns: [
                        {
                            title: 'Order Date',
                            field: 'assessment_date',
                            shortTitle: 'Order Date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Time of Event',
                            field: 'time_of_event',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                        {
                            title: 'Patient',
                            field: 'patient_name',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({patientId: $patient_id})',
                            linkOpenNewWindow: true,
                            canSort: true,
                            formatValueFn: function (physicianOrder) {
                                return physicianOrder.patient.name;
                            },
                        },
                        {
                            title: 'Clinician',
                            field: 'nurse_name',
                            shortTitle: 'Nurse',
                            type: 'short-text',
                            canSort: true,
                            formatValueFn: function (physicianOrder) {
                                if (physicianOrder.nurse) {
                                    return physicianOrder.nurse.name;
                                }
                                return '';
                            },
                        },
                        {
                            title: 'Physician',
                            field: 'physician_name',
                            shortTitle: 'Physician',
                            type: 'short-text',
                            canSort: true,
                            formatValueFn: function (physicianOrder) {
                                var phys = physicianOrder.physician;
                                return phys ? phys.name : '';
                            },
                        },
                        {
                            title: 'Orders',
                            field: 'orders',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Medications',
                            field: 'medication',
                            formatValueFn: function (physicianOrder) {
                                var medicationDisplay = '';
                                physicianOrder.medication_transactions.forEach(function (medicationTransaction) {
                                    var medication = medicationTransaction.medication;
                                    if (medication) {
                                        medicationDisplay += medication.name + ' ' + medication.frequency;
                                        if (medication.sliding_scale) {
                                            var dose_level = ((medication.sliding_scale.dose_level || medication.sliding_scale.dose_level != null) ? medication.sliding_scale.dose_level : "");
                                            medicationDisplay += ' Per Sliding Scale ' + dose_level;
                                        }
                                        medicationDisplay += '<br>';
                                    }
                                });
                                return medicationDisplay;
                            },
                            type: 'short-text',
                        },
                        {
                            title: 'Medication Kits',
                            field: 'patient_medication_kits',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (physicianOrder) {
                                return physicianOrder.patient_medication_kit_transactions
                                    .map(function (patientMedicationKitTransaction) {
                                    return patientMedicationKitTransaction.patient_medication_kit.name;
                                })
                                    .join(', ');
                            },
                        },
                        {
                            title: 'Oversight',
                            field: 'oversight',
                            type: csg.datatable.ColumnTypes.boolean,
                            canSort: true,
                        },
                        {
                            title: 'Verbal Order',
                            field: 'read_back',
                            type: csg.datatable.ColumnTypes.boolean,
                            canSort: true,
                        },
                        {
                            title: 'Non-Verbal Order',
                            field: 'non_verbal_order',
                            type: csg.datatable.ColumnTypes.boolean,
                            canSort: true,
                        },
                    ],
                    subTable: [
                        {
                            columns: getSubtableColumns(),
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit Physician Order',
                                disableOffline: false,
                                icon: 'edit',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.currentPhysicianOrder = physicianOrder;
                                    if (_this.PhysicianOrdersService.hasWrmManagerFlag() && physicianOrder.signatures != undefined && physicianOrder.signatures.length > 0) {
                                        _this.PhysicianOrdersService.getOrderStatus(_this.PhysicianOrdersService.currentPhysicianOrder).then(function (result) {
                                            if (result) {
                                                _this.PhysicianOrdersService.goToRoute(physicianOrder, 'patients.physician-orders.edit', { patientId: physicianOrder.patient_id, id: physicianOrder.id });
                                                return false;
                                            }
                                            else {
                                                var alert_1 = _this.$mdDialog
                                                    .alert()
                                                    .title('Unable to edit')
                                                    .htmlContent('<br/>Order cannot be edited as appointment are scheduled<br/>')
                                                    .ok('OK');
                                                _this.$mdDialog.show(alert_1);
                                            }
                                        });
                                    }
                                    else {
                                        _this.PhysicianOrdersService.goToRoute(physicianOrder, 'patients.physician-orders.edit', { patientId: physicianOrder.patient_id, id: physicianOrder.id });
                                        return false;
                                    }
                                },
                                conditionalHideFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.initializePhysicianOrder(physicianOrder);
                                    return physicianOrder.locked || !physicianOrder.currentUserCanEdit;
                                },
                            },
                            {
                                label: 'Create Physician Order',
                                icon: 'add',
                                callbackFn: function (physicianOrder) {
                                    _this.$state.go('patients.physician-orders.new', {
                                        patientId: physicianOrder.patient_id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.CurrentPatient.isPatientPresent();
                                },
                            },
                            {
                                label: 'View Physician Order',
                                icon: 'remove_red_eye',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.currentPhysicianOrder = physicianOrder;
                                    _this.PhysicianOrdersService.goToRoute(physicianOrder, 'patients.physician-orders.view', { patientId: physicianOrder.patient_id, id: physicianOrder.id }, true);
                                    return false;
                                },
                            },
                            {
                                label: 'Print Physician Order',
                                icon: 'print',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.print([{ orderId: physicianOrder.id, patientId: physicianOrder.patient_id }]);
                                    return false;
                                },
                            },
                            {
                                label: 'Fax Physician Order',
                                icon: 'send',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.faxOrder(physicianOrder);
                                    return false;
                                },
                                conditionalHideFn: function (physicianOrder) {
                                    return physicianOrder.deleted_at !== undefined || physicianOrder.e_prescribe;
                                },
                            },
                            {
                                label: 'Delete Physician Order',
                                icon: 'delete',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.deletePhysicianOrder(physicianOrder).then(function () {
                                        _this.PhysicianOrdersService.delete(physicianOrder).then(function (updatedPhysicianOrder) {
                                            angular.copy(updatedPhysicianOrder, physicianOrder);
                                            _this.search();
                                            return true;
                                        }, function () {
                                            return false;
                                        });
                                    }, function () {
                                        return false;
                                    });
                                },
                                conditionalHideFn: function (physicianOrder) {
                                    return physicianOrder.locked;
                                },
                            },
                        ],
                    },
                };
            };
            PhysicianOrdersDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = this.filters.getFilters();
                    if (this.CurrentPatient.isPatientPresent() &&
                        (!this.params.patient_id || this.params.patient_id !== this.CurrentPatient.patient_id)) {
                        this.params.patient_id = this.CurrentPatient.patient_id;
                    }
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            PhysicianOrdersDataTableService.prototype.addPhysicianOrder = function (physicianOrder) {
                if (this.data) {
                    this.data.splice(0, 0, physicianOrder);
                }
                else {
                    this.data = [physicianOrder];
                }
            };
            PhysicianOrdersDataTableService.prototype.reset = function () {
                var _this = this;
                if (!this.CurrentPatient.patient_id ||
                    this.patientId !== this.CurrentPatient.patient_id ||
                    !this.data) {
                    this.patientId = this.CurrentPatient.patient_id;
                    this.filters = new consolo.domain.PhysicianOrderFilter();
                    this.filters.patient_id = this.CurrentPatient.patient_id;
                    this.filters.patient = this.CurrentPatient.patient;
                    this.search();
                }
                else {
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.filters.page = 1;
                            _this.params = _this.filters;
                            _this.tableControl.loadCurrentData();
                        }
                    }, 500, 20);
                }
            };
            PhysicianOrdersDataTableService.prototype.printFilteredOrders = function (ids) {
                this.PdfGenerator.printPhysicianOrders(ids);
            };
            PhysicianOrdersDataTableService.$inject = [
                '$http',
                '$interval',
                'PhysicianOrdersService',
                '$state',
                'CurrentPatient',
                'PdfGenerator',
                'FaxGenerator',
                'NetworkManager',
                'persistenceStore',
                'logger',
                '$mdDialog'
            ];
            return PhysicianOrdersDataTableService;
        }(csg.datatable.ApiService));
        services.PhysicianOrdersDataTableService = PhysicianOrdersDataTableService;
        angular
            .module('consolo')
            .service('PhysicianOrdersDataTableService', PhysicianOrdersDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ViewPhysicianOrdersComponent = /** @class */ (function () {
            function ViewPhysicianOrdersComponent() {
                this.templateUrl = '/y/templates/physician-order/physician-orders.html';
                this.controller = PhysicianOrdersController;
                this.controllerAs = 'vm';
            }
            return ViewPhysicianOrdersComponent;
        }());
        var PhysicianOrdersController = /** @class */ (function () {
            function PhysicianOrdersController(CurrentPatient, PhysicianOrderEntry, $state, PhysicianOrdersService, PhysicianOrdersDataTableService, $scope) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.PhysicianOrderEntry = PhysicianOrderEntry;
                this.$state = $state;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.PhysicianOrdersDataTableService = PhysicianOrdersDataTableService;
                this.$scope = $scope;
                this.hasVisitFrequencyEnabled = false;
                this.activate();
                this.$scope.$on('PhysicianOrdersSynced', function () {
                    _this.activate();
                });
            }
            PhysicianOrdersController.prototype.activate = function () {
                this.PhysicianOrdersDataTableService.showFilters = false;
                this.PhysicianOrdersDataTableService.search();
                if (this.$state.params.ids) {
                    this.tableLabel = 'Split Physician Orders';
                }
                else {
                    this.tableLabel = 'Physician Orders';
                }
                if (this.CurrentPatient.patient) {
                    this.tableLabel =
                        ' <i class="material-icons">keyboard_arrow_right</i> ' +
                            this.CurrentPatient.patient.first_name +
                            ' ' +
                            this.CurrentPatient.patient.last_name;
                }
                if (this.$state.params.ids) {
                    this.initializeSplitOrders();
                }
            };
            PhysicianOrdersController.prototype.urlTo = function (url) {
                document.location.href = '/patients/' + this.CurrentPatient.patient_id + '/' + url;
            };
            PhysicianOrdersController.prototype.initializeSplitOrders = function () {
                var _this = this;
                this.PhysicianOrderEntry.getMultipleOrders(this.CurrentPatient.patient_id, this.$state.params.ids.split(',')).$promise.then(function (data) {
                    _this.physicianOrders = data;
                    _this.physicianOrders.forEach(function (po) {
                        if (po.physician) {
                            po.physician_name = po.physician.name;
                        }
                        else {
                            po.physician_name = ' ';
                        }
                        if (po.nurse) {
                            po.nurse_name = po.nurse.name;
                        }
                        else {
                            po.nurse_name = ' ';
                        }
                        po.medication_string = '';
                        po.order_status_string = '';
                        po.medication_transactions.forEach(function (medTransaction) {
                            var med = medTransaction.medication;
                            po.medication_string += med.name + ', ';
                            if (po.order_status_string.indexOf(med.order_status_description) < 0) {
                                po.order_status_string += med.order_status_description + ', ';
                            }
                        });
                        if (po.medication_string.length) {
                            po.medication_string = po.medication_string.substr(0, po.medication_string.length - 2);
                        }
                        else {
                            po.medication_string = ' ';
                        }
                        if (po.order_status_string.length) {
                            po.order_status_string = po.order_status_string.substr(0, po.order_status_string.length - 2);
                        }
                        else {
                            po.order_status_string = ' ';
                        }
                    });
                });
                this.splitOrderLayout = {
                    options: { expandCollapseAll: false, hideSearch: true },
                    header: [
                        {
                            title: 'Order Date',
                            field: 'assessment_date',
                            header: true,
                            isDate: true,
                        },
                        {
                            title: 'Nurse',
                            field: 'nurse_name',
                            header: true,
                        },
                        {
                            title: 'Physician',
                            field: 'physician_name',
                            header: true,
                        },
                        {
                            title: 'Medications',
                            field: 'medication_string',
                            header: true,
                        },
                        {
                            title: 'Orders',
                            field: 'orders',
                            header: true,
                        },
                        {
                            title: 'Order Status',
                            field: 'order_status_string',
                            header: true,
                            formatCustom: function (column, item) {
                                if (item.order_status === 4) {
                                    column.disableAction = true;
                                }
                                return item[column.field];
                            },
                            callbackFn: function ($event, item) {
                                $event.stopPropagation();
                                if (item.order_status === 4) {
                                    return null;
                                }
                                else {
                                    return _this.$state.transitionTo('patients.physician-orders.view', {
                                        patientId: _this.CurrentPatient.patient_id,
                                        id: item.id,
                                    });
                                }
                            },
                        },
                        {
                            header: true,
                            title: '',
                            menu: true,
                            shortTitle: 'Actions',
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            itemMenuItems: [
                                {
                                    icon: 'image:edit',
                                    text: 'Edit Physician Order',
                                    callbackFn: function ($event, item) {
                                        return _this.$state.transitionTo('patients.physician-orders.edit', {
                                            patientId: _this.CurrentPatient.patient_id,
                                            id: item.id,
                                        });
                                    },
                                },
                                {
                                    icon: 'image:remove_red_eye',
                                    text: 'View Physician Order',
                                    callbackFn: function ($event, item) {
                                        _this.$state.transitionTo('patients.physician-orders.view', {
                                            patientId: _this.CurrentPatient.patient_id,
                                            id: item.id,
                                        });
                                    },
                                },
                            ],
                        },
                    ],
                };
            };
            PhysicianOrdersController.$inject = [
                'CurrentPatient',
                'PhysicianOrderEntry',
                '$state',
                'PhysicianOrdersService',
                'PhysicianOrdersDataTableService',
                '$scope',
            ];
            return PhysicianOrdersController;
        }());
        angular.module('consolo').component('viewPhysicianOrders', new ViewPhysicianOrdersComponent);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PhysicianOrdersService = /** @class */ (function () {
            function PhysicianOrdersService(NetworkManager, $filter, $mdDialog, Session, CurrentPatient, logger, $state, $window, PdfGenerator, FaxGenerator, $http, $q, MedicationService, Lookup, Assignee, Physician, PermissionsService, cache) {
                var _this = this;
                this.NetworkManager = NetworkManager;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.logger = logger;
                this.$state = $state;
                this.$window = $window;
                this.PdfGenerator = PdfGenerator;
                this.FaxGenerator = FaxGenerator;
                this.$http = $http;
                this.$q = $q;
                this.MedicationService = MedicationService;
                this.Lookup = Lookup;
                this.Assignee = Assignee;
                this.Physician = Physician;
                this.PermissionsService = PermissionsService;
                this.cache = cache;
                this.ePrescribeHide = true;
                this.showEPCSErrors = false;
                this.ePrescribeEnabled = false;
                this.today = new Date();
                this.isPhysician = false;
                this.addingSignature = false;
                this.editAnyPermission = false;
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.angularIndexPage = false;
                this.hasVisitFrequencyEnabled = false;
                var session = Session.get();
                this.currentUser = session.user;
                this.angularIndexPage = session.agency.angular_physician_order_index_enabled;
                this.isPhysician =
                    this.currentUser.physician !== null && this.currentUser.physician.id !== null;
                this.hasVisitFrequencyEnabled = this.Session.get().agency.has_visit_frequency;
                this.nurse = this.currentUser;
                this.user_dashboard_url = session.user.dashboard_url;
                this.ePrescribeHide = !session.agency.epcs_on;
                this.datesChanged = false;
                this.verbalReadBackOptions = [
                    { id: 'readBack', description: 'Read Back to Physician' },
                    { id: 'nonVerbal', description: 'Non-Verbal Order' },
                ];
                this.medicationTransactionsLayout = {
                    rowHideFn: function (medicationTransaction) {
                        if (medicationTransaction.transaction_type === 'discontinue') {
                            return true;
                        }
                        return false;
                    },
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                        defaultPageSize: 100,
                    },
                    columns: [
                        {
                            title: 'Medication',
                            field: 'medication.name',
                            formatValueFn: function (medicationTransaction) {
                                var display = medicationTransaction.medication.name;
                                if (medicationTransaction.transaction_type === 'split_rx') {
                                    display += '<span class="margin-left-xs med-split-rx label">SplitRx</span>';
                                }
                                return display;
                            },
                        },
                        {
                            title: 'Dosage',
                            field: 'medication.frequency',
                            formatValueFn: function (medicationTransaction) {
                                if (medicationTransaction.medication.sliding_scale) {
                                    var dose_level = ((medicationTransaction.medication.sliding_scale.dose_level || medicationTransaction.medication.sliding_scale.dose_level != null) ? medicationTransaction.medication.sliding_scale.dose_level : "");
                                    return medicationTransaction.medication.frequency + "Per Sliding Scale" + " " + dose_level;
                                }
                                else {
                                    return medicationTransaction.medication.frequency;
                                }
                            }
                        },
                        {
                            title: 'Instructions',
                            field: 'instructions',
                            formatValueFn: function (medicationTransaction) {
                                return medicationTransaction.medication.all_instructions_plus_ss_nar;
                            },
                        },
                        {
                            title: 'Quantity',
                            field: 'quantity',
                            formatValueFn: function (medicationTransaction) {
                                return _this.MedicationService.validateQuantity(medicationTransaction.quantity);
                            },
                        },
                        {
                            title: 'Refills',
                            field: 'refills',
                        },
                        {
                            title: 'PRN Reasons',
                            field: 'prn_reasons',
                            formatValueFn: function (medicationTransaction) {
                                return medicationTransaction.medication.prn_reasons.map(function (reason) {
                                    return reason.description;
                                }).join(', ');
                            }
                        }
                    ],
                };
                this.editAnyPermission = this.PermissionsService.can('edit_any_physician_order', 'physician_orders');
                this.updatePermission = this.PermissionsService.can('update', 'physician_orders');
                this.createPermission = this.PermissionsService.can('create', 'physician_orders');
                this.deletePermission = this.PermissionsService.can('destroy', 'physician_orders');
                this.viewPermission = this.PermissionsService.can('show', 'physician_orders');
                this.medicationKitTableLayout = {
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_medication_kit.name',
                            formatValueFn: function (medKitTransaction) {
                                return medKitTransaction.patient_medication_kit.display_name;
                            },
                        },
                        {
                            title: 'Directions For Use',
                            field: 'directions_for_use',
                        },
                        {
                            title: 'Notes to Pharmacist',
                            field: 'notes_to_pharmacist',
                        },
                    ],
                };
            }
            PhysicianOrdersService.prototype.addPhysicianSignature = function (physicianOrder, effectiveDate) {
                var _this = this;
                var currentDate = effectiveDate;
                var formattedDate = currentDate.getFullYear() +
                    '-' +
                    csg.util.addZero(currentDate.getMonth() + 1) +
                    '-' +
                    csg.util.addZero(currentDate.getDate());
                this.addingSignature = true;
                return this.$http
                    .post('/api/v1/physician_orders/' + physicianOrder.id + '/add_physician_signature ', {
                    effective_date: formattedDate,
                })
                    .then(function (response) {
                    _this.logger.success('Signature added successfully.');
                    _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                    return response.data;
                }, function (errors) {
                    _this.logger.error(errors);
                    console.warn(errors);
                })
                    .finally(function () {
                    _this.addingSignature = false;
                });
            };
            PhysicianOrdersService.prototype.addSignature = function (physicianOrder) {
                var _this = this;
                this.addingSignature = true;
                return this.$http
                    .post('/api/v1/physician_orders/' + physicianOrder.id + '/signatures', {})
                    .then(function (response) {
                    _this.logger.success('Signature added successfully.');
                    _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                    return response.data;
                }, function (errors) {
                    _this.logger.error(errors);
                    console.warn(errors);
                    return null;
                })
                    .finally(function () {
                    _this.addingSignature = false;
                });
            };
            PhysicianOrdersService.prototype.create = function (physicianOrder) {
                physicianOrder.assessment_date = physicianOrder.assessment_date.toDateString();
                return this.$http.post('/api/v1/physician_orders', physicianOrder).then(function (response) {
                    return response.data;
                });
            };
            PhysicianOrdersService.prototype.sortMedicationTransactions = function (physicianOrder) {
                physicianOrder.newMedicationTransactions = [];
                physicianOrder.discontinuedMedicationTransactions = [];
                if (physicianOrder.medication_transactions.length) {
                    physicianOrder.medication_transactions.forEach(function (med_transaction) {
                        med_transaction.medication = new consolo.domain.Medication(med_transaction.medication);
                        if (['newrx', 'reorder'].indexOf(med_transaction.transaction_type) > -1) {
                            physicianOrder.newMedicationTransactions.push(med_transaction);
                        }
                        else if (med_transaction.transaction_type === 'discontinue') {
                            physicianOrder.discontinuedMedicationTransactions.push(med_transaction);
                        }
                    });
                }
            };
            PhysicianOrdersService.prototype.signAndFax = function (physicianOrder) {
                var _this = this;
                return this.addSignature(physicianOrder).then(function (updatedPhysicianOrder) {
                    _this.faxOrder(physicianOrder);
                    return updatedPhysicianOrder;
                });
            };
            PhysicianOrdersService.prototype.showEPCSOptions = function (physicianOrder) {
                return (!this.ePrescribeHide &&
                    this.currentUser.epcs_on &&
                    (physicianOrder.new_medication_transaction_ids.length > 0 ||
                        physicianOrder.patient_medication_kit_transactions.length > 0));
            };
            PhysicianOrdersService.prototype.createNewOrder = function (medications) {
                var _this = this;
                var medication_ids = medications.map(function (medication) {
                    return medication.id;
                });
                return this.$http
                    .post('/api/v1/physician_orders/create_new_order_from_medications', {
                    medication_ids: medication_ids,
                    patient_id: this.CurrentPatient.patient_id,
                })
                    .then(function (response) {
                    var physicianOrder = response.data;
                    _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                    return physicianOrder;
                }, function (errors) {
                    _this.logger.error(errors);
                    console.warn(errors);
                    return null;
                });
            };
            PhysicianOrdersService.prototype.physicianLookup = function (q) {
                var cachedLookup = this.cache(this.Lookup, 'physicians', { maxAge: 10 });
                return cachedLookup['physicians']({ limit: -1 }).$promise.then(function (physicians) {
                    if (q) {
                        q = q.toLowerCase();
                        return physicians.filter(function (x) { return x.name.toLowerCase().indexOf(q) >= 0; });
                    }
                    return physicians;
                });
            };
            PhysicianOrdersService.prototype.goToRoute = function (physicianOrder, route, params, newTab) {
                if (newTab === void 0) { newTab = false; }
                if (newTab) {
                    var url = this.$state.href(route, params);
                    this.$window.open(url, '_blank');
                }
                else {
                    this.$state.go(route, params);
                }
            };
            PhysicianOrdersService.prototype.findIndexById = function (array, id) {
                for (var i = 0; i < array.length; i += 1) {
                    if (array[i].id === id) {
                        return i;
                    }
                }
                return -1;
            };
            PhysicianOrdersService.prototype.deletePhysicianOrder = function (physicianOrder) {
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirm Physician Order Deletion')
                    .textContent('Are you sure you want to delete this physician order?')
                    .ok('Delete')
                    .cancel('Keep Physician Order');
                return this.$mdDialog.show(confirm);
            };
            PhysicianOrdersService.prototype.loadAssignees = function (physicianOrder) {
                var _this = this;
                var assignees = [];
                return this.Assignee.query({ patient_id: this.CurrentPatient.patient_id }).$promise.then(function (data) {
                    var medicalDirector;
                    data.forEach(function (assignee) {
                        assignees.push(assignee);
                        //If there is an existing MD Director on the assignment and there is no physician_id being passed in then assign from this
                        if (!physicianOrder.physician &&
                            assignee.role_symbol === 'medical_director' &&
                            !medicalDirector) {
                            medicalDirector = assignee;
                        }
                    });
                    if (medicalDirector && !physicianOrder.physician) {
                        _this.Physician.get({ id: medicalDirector.assigned_person_id }).$promise.then(function (physician) {
                            physicianOrder.physician = physician;
                        });
                    }
                    return assignees;
                });
            };
            PhysicianOrdersService.prototype.delete = function (physicianOrder) {
                var _this = this;
                return this.$http.delete('/api/v1/physician_orders/' + physicianOrder.id).then(function (data) {
                    _this.logger.success('The Physician Order ID=' + physicianOrder.id + ' has been deleted.');
                    return data.data;
                });
            };
            PhysicianOrdersService.prototype.get = function (id) {
                var _this = this;
                var params = { patient_id: this.CurrentPatient.patient_id, page_size: 100 };
                return this.$http.get('/api/v1/physician_orders/' + id, { params: params }).then(function (response) {
                    var physicianOrder = response.data;
                    physicianOrder.assessmentDate = _this.$filter('date')(new Date(physicianOrder.assessment_date), 'MM/dd/yyyy');
                    if (physicianOrder.medication_transactions &&
                        physicianOrder.medication_transactions.length) {
                        physicianOrder.medication_transactions = physicianOrder.medication_transactions.map(function (medicationTransaction) {
                            medicationTransaction.medication = new consolo.domain.Medication(medicationTransaction.medication);
                            return medicationTransaction;
                        });
                    }
                    return physicianOrder;
                });
            };
            PhysicianOrdersService.prototype.urlTo = function (url) {
                document.location.href = '/patients/' + this.CurrentPatient.patient_id + '/' + url;
            };
            PhysicianOrdersService.prototype.faxOrder = function (physicianOrder) {
                this.FaxGenerator.fax(physicianOrder.patient_id, {
                    physician_orders: [{ orderId: physicianOrder.id, patientId: physicianOrder.patient_id }],
                    id: physicianOrder.patient_id,
                });
            };
            PhysicianOrdersService.prototype.print = function (ids) {
                this.PdfGenerator.printPhysicianOrders(ids);
            };
            PhysicianOrdersService.prototype.setEPrescribeVariables = function (physicianOrder) {
                this.ePrescribeEnabled =
                    physicianOrder.e_prescribe_available &&
                        this.currentUser.epcs_on &&
                        this.currentUser.physician &&
                        this.currentUser.physician.id === physicianOrder.physician_id;
                this.showEPCSErrors =
                    this.currentUser.epcs_on &&
                        this.currentUser.physician &&
                        this.currentUser.physician.id === physicianOrder.physician_id;
            };
            PhysicianOrdersService.prototype.initializePhysicianOrder = function (physicianOrder) {
                this.setEPrescribeVariables(physicianOrder);
                this.setShowSignatureVariables(physicianOrder);
                this.setUserAllowedToEdit(physicianOrder);
                this.sortMedicationTransactions(physicianOrder);
            };
            PhysicianOrdersService.prototype.hasWrmManagerFlag = function () {
                return this.Session.get().agency['has_resource_manager'];
            };
            PhysicianOrdersService.prototype.getOrderStatus = function (physicianOrder) {
                return __awaiter(this, void 0, void 0, function () {
                    var resp, result, i, assignee, data, data_freq;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                resp = [];
                                result = '';
                                if (!this.CurrentPatient.empi_id) return [3 /*break*/, 7];
                                i = 0;
                                _a.label = 1;
                            case 1:
                                if (!(i < physicianOrder.assignees.length)) return [3 /*break*/, 6];
                                assignee = physicianOrder.assignees[i];
                                if (!assignee["prn_frequency_string"]) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.$http.get('/api/v1/wrm/' + this.CurrentPatient.patient_id + '/physician_order_status', { params: { physicianOrder: physicianOrder, assignee: assignee, prn_value: true } })];
                            case 2:
                                data = _a.sent();
                                resp.push(data.data["status"]);
                                _a.label = 3;
                            case 3:
                                if (!assignee["visit_frequency_string"]) return [3 /*break*/, 5];
                                return [4 /*yield*/, this.$http.get('/api/v1/wrm/' + this.CurrentPatient.patient_id + '/physician_order_status', { params: { physicianOrder: physicianOrder, assignee: assignee, prn_value: false } })];
                            case 4:
                                data_freq = _a.sent();
                                resp.push(data_freq.data["status"]);
                                _a.label = 5;
                            case 5:
                                i++;
                                return [3 /*break*/, 1];
                            case 6: return [2 /*return*/, (!(resp.indexOf('scheduled') > -1))];
                            case 7: 
                            // Patient doesnt have empi id and hence will not be synced to WRM. Order should be editable
                            return [2 /*return*/, true];
                        }
                    });
                });
            };
            PhysicianOrdersService.prototype.setShowSignatureVariables = function (physicianOrder) {
                var _this = this;
                physicianOrder.showAddSignature = true;
                physicianOrder.showAddPhysicianSignature = false;
                //If either no physician or the physician is different then they can sign for the physician on the Order
                if (!this.currentUser.physician ||
                    (this.currentUser.physician &&
                        this.currentUser.physician.id !== physicianOrder.physician_id)) {
                    physicianOrder.showAddPhysicianSignature = true;
                }
                if (physicianOrder.signatures && physicianOrder.signatures.length) {
                    physicianOrder.signatures.forEach(function (signature) {
                        //Check if the user still needs to sign for themself
                        if (signature.user_id === _this.currentUser.id && !signature.physician_id) {
                            physicianOrder.showAddSignature = false;
                        }
                        else if (_this.currentUser.physician &&
                            signature.user_id === _this.currentUser.id &&
                            signature.physician_id === _this.currentUser.physician.id) {
                            physicianOrder.showAddSignature = false;
                        }
                        //Check if it's not the Physician on this Order, and that the order still needs to sign for the physician
                        if (physicianOrder.showAddPhysicianSignature &&
                            signature.physician_id === physicianOrder.physician_id) {
                            physicianOrder.showAddPhysicianSignature = false;
                        }
                    });
                }
            };
            PhysicianOrdersService.prototype.setOrderVerbalReadBack = function (physicianOrder, verbalReadBack) {
                if (verbalReadBack === 'readBack') {
                    physicianOrder.read_back = true;
                    physicianOrder.non_verbal_order = false;
                }
                else {
                    physicianOrder.read_back = false;
                    physicianOrder.non_verbal_order = true;
                }
            };
            PhysicianOrdersService.prototype.getVerbalOnLoad = function (physicianOrder) {
                if (physicianOrder.read_back) {
                    return 'readBack';
                }
                else if (physicianOrder.non_verbal_order) {
                    return 'nonVerbal';
                }
                else {
                    return null;
                }
            };
            PhysicianOrdersService.prototype.setUserAllowedToEdit = function (physicianOrder) {
                physicianOrder.currentUserCanEdit = false;
                if (physicianOrder.id) {
                    if (this.editAnyPermission) {
                        physicianOrder.currentUserCanEdit = true;
                    }
                    else if (this.updatePermission &&
                        Date.parse(physicianOrder.created_at) > this.today.getTime() - 24 * 3600) {
                        if (physicianOrder.user.id === this.currentUser.id ||
                            physicianOrder.physician.id === this.currentUser.id) {
                            physicianOrder.currentUserCanEdit = true;
                        }
                    }
                }
            };
            PhysicianOrdersService.prototype.reloadAndShowSignatureDialog = function (physicianOrder) {
                var _this = this;
                this.get(physicianOrder.id).then(function (updatedPhysicianOrder) {
                    _this.showSignatureDialog(updatedPhysicianOrder);
                });
            };
            PhysicianOrdersService.prototype.showSignatureDialog = function (physicianOrder) {
                if (this.NetworkManager.isOnline()) {
                    this.initializePhysicianOrder(physicianOrder);
                    var url = "'/y/templates/physician-order/signature.html'";
                    return this.$mdDialog.show({
                        template: '<md-dialog flex="50" aria-label="Add Signature dialog">' +
                            '  <md-dialog-content>' +
                            '   <div ng-include="' +
                            url +
                            '"/>' +
                            '  </md-dialog-content>' +
                            '</md-dialog>',
                        controller: 'PhysicianOrderSignatureController as vm',
                        bindToController: true,
                        locals: {
                            currentPatient: this.CurrentPatient.patient,
                            physicianOrder: physicianOrder,
                            currentUser: this.currentUser,
                            PhysicianOrdersService: this,
                        },
                    });
                }
            };
            PhysicianOrdersService.prototype.showTwoFactorDialog = function (physicianOrder) {
                var _this = this;
                if (!this.ePrescribeEnabled || !physicianOrder.dea_number) {
                    return;
                }
                physicianOrder.validate_e_prescribe = null;
                this.currentPatient = this.CurrentPatient.patient;
                var patientExistsPromise = this.$q.defer();
                if (!this.currentPatient || this.currentPatient.id !== physicianOrder.patient_id) {
                    this.$http.get('/api/v1/patients/' + physicianOrder.patient_id, {}).then(function (data) {
                        _this.currentPatient = data.data;
                        patientExistsPromise.resolve();
                    });
                }
                else {
                    patientExistsPromise.resolve();
                }
                return patientExistsPromise.promise.then(function (data) {
                    var url = "'/y/templates/physician-order/two-factor-signature.html'";
                    return _this.$mdDialog.show({
                        template: '<md-dialog flex="90" flex-gt-xs="80" aria-label="Add Signature dialog">' +
                            '  <md-dialog-content>' +
                            '   <div ng-include="' +
                            url +
                            '"/>' +
                            '  </md-dialog-content>' +
                            '</md-dialog>',
                        controller: 'TwoFactorSignatureController as vm',
                        clickOutsideToClose: true,
                        bindToController: true,
                        locals: { physicianOrder: physicianOrder, currentPatient: _this.currentPatient },
                    });
                });
            };
            PhysicianOrdersService.$inject = [
                'NetworkManager',
                '$filter',
                '$mdDialog',
                'SessionService',
                'CurrentPatient',
                'logger',
                '$state',
                '$window',
                'PdfGenerator',
                'FaxGenerator',
                '$http',
                '$q',
                'MedicationService',
                'Lookup',
                'Assignee',
                'Physician',
                'PermissionsService',
                'cache',
            ];
            return PhysicianOrdersService;
        }());
        services.PhysicianOrdersService = PhysicianOrdersService;
        angular.module('consolo').service('PhysicianOrdersService', PhysicianOrdersService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ReorderMedicationsController = /** @class */ (function () {
            function ReorderMedicationsController($state, logger, currentPatient, MedicationService, PhysicianOrdersService, $filter, currentUser, Session) {
                var _this = this;
                this.$state = $state;
                this.logger = logger;
                this.currentPatient = currentPatient;
                this.MedicationService = MedicationService;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.$filter = $filter;
                this.currentUser = currentUser;
                this.Session = Session;
                this.today = new Date();
                this.saving = false;
                this.assignees = [];
                this.users = [];
                this.loadingAssignees = false;
                this.isPhysician = false;
                this.angularIndexPage = false;
                this.angularIndexPage = Session.get().agency.angular_physician_order_index_enabled;
                var today = new Date();
                this.physicianOrder = {
                    patient_id: this.currentPatient.id,
                    assessment_date: today,
                    time_of_event: $filter('date')(today, 'HHmm'),
                };
                this.loadingAssignees = true;
                this.PhysicianOrdersService.loadAssignees(this.physicianOrder).then(function (assignees) {
                    _this.assignees = assignees;
                    _this.loadingAssignees = false;
                });
                if (!this.physicianOrder.physician && this.PhysicianOrdersService.currentUser.physician) {
                    var physician = this.PhysicianOrdersService.currentUser.physician;
                    this.physicianOrder.physician = {
                        id: physician.id,
                        name: physician.name,
                    };
                }
                this.isPhysician =
                    this.currentUser.physician !== null && this.currentUser.physician.id !== null;
                if (!this.isPhysician) {
                    this.physicianOrder.nurse_id = this.currentUser.id;
                }
                this.MedicationService.setPharmacy(currentPatient.id, this.physicianOrder);
                this.verbalReadBack = this.PhysicianOrdersService.getVerbalOnLoad(this.physicianOrder);
            }
            ReorderMedicationsController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.MedicationService.reorderMedicationTransactions ||
                    !this.MedicationService.reorderMedicationTransactions.length) {
                    this.errors = ['You need to select a medication.'];
                }
                if (!this.physicianOrder.physician && !this.physicianOrder.physician_id) {
                    this.errors = ['You must select a physician.'];
                }
                if (!this.isPhysician &&
                    !this.physicianOrder.non_verbal_order &&
                    !this.physicianOrder.read_back) {
                    this.errors = ['You must select Non Verbal or Read Back'];
                }
                if (this.errors.length === 0) {
                    this.saving = true;
                    this.physicianOrder.medication_transactions = this.MedicationService.reorderMedicationTransactions;
                    if (this.physicianOrder.pharmacy_id || this.physicianOrder.surescripts_pharmacy_id) {
                        this.physicianOrder.medication_transactions.forEach(function (transaction) {
                            transaction.pharmacy_id = _this.physicianOrder.pharmacy_id;
                            transaction.surescripts_pharmacy_id = _this.physicianOrder.surescripts_pharmacy_id;
                        });
                    }
                    this.PhysicianOrdersService.create(this.physicianOrder)
                        .then(function (physicianOrder) {
                        _this.logger.success('Physician order created.');
                        _this.$state.go('patients.physician-orders.view', {
                            patientId: _this.currentPatient.id,
                            id: physicianOrder.id,
                        });
                        _this.MedicationService.reorderMedicationTransactions.forEach(function (element) {
                            console.log(element.medication.status);
                            if (element.medication.status === 'fileOnly') {
                                element.medication.status = '';
                                _this.MedicationService.update(element.medication);
                            }
                        });
                        _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                    }, function (errorResponse) {
                        _this.errors = errorResponse.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            ReorderMedicationsController.prototype.fieldClick = function (e) {
                e.stopPropagation();
            };
            ReorderMedicationsController.$inject = [
                '$state',
                'logger',
                'currentPatient',
                'MedicationService',
                'PhysicianOrdersService',
                '$filter',
                'currentUser',
                'SessionService',
            ];
            return ReorderMedicationsController;
        }());
        angular
            .module('consolo')
            .controller('ReorderMedicationsController', ReorderMedicationsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var PhysicianOrderSidebarController = /** @class */ (function () {
        function PhysicianOrderSidebarController(PhysicianOrderSectionService, physicianOrderStore, MedicationKitsService) {
            this.PhysicianOrderSectionService = PhysicianOrderSectionService;
            this.physicianOrderStore = physicianOrderStore;
            this.MedicationKitsService = MedicationKitsService;
            this.PhysicianOrderSectionService.activeSection = this.PhysicianOrderSectionService.sections['generalDetails'];
        }
        PhysicianOrderSidebarController.prototype.showSection = function (section) {
            this.PhysicianOrderSectionService.activeSection = section;
        };
        PhysicianOrderSidebarController.$inject = [
            'PhysicianOrderSectionService',
            'physicianOrderStore',
            'MedicationKitsService',
        ];
        return PhysicianOrderSidebarController;
    }());
    angular
        .module('consolo')
        .controller('PhysicianOrderSidebarController', PhysicianOrderSidebarController);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PhysicianOrderSignatureController = /** @class */ (function () {
            function PhysicianOrderSignatureController($mdDialog, currentPatient, currentUser, PhysicianOrdersService, $state, $filter, physicianOrder, $http) {
                this.$mdDialog = $mdDialog;
                this.currentPatient = currentPatient;
                this.currentUser = currentUser;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.$state = $state;
                this.$filter = $filter;
                this.physicianOrder = physicianOrder;
                this.$http = $http;
                this.ePrescribe = false;
                this.today = new Date();
                this.signatureDate = new Date();
                this.PhysicianOrdersService.setShowSignatureVariables(physicianOrder);
                this.ePrescribe = false;
                if (!this.physicianOrder.dea_number && this.currentUser.active_dea_numbers.length) {
                    var defaultNumber = this.$filter('filter')(this.currentUser.active_dea_numbers, function (deaNumber) {
                        return deaNumber.default;
                    });
                    if (defaultNumber.length) {
                        this.physicianOrder.dea_number = defaultNumber[0].number;
                    }
                }
            }
            PhysicianOrderSignatureController.prototype.completePhysicianSignature = function (physicianOrder) {
                var _this = this;
                this.PhysicianOrdersService.addPhysicianSignature(physicianOrder, this.signatureDate).then(function (signedPhysicianOrder) {
                    angular.copy(signedPhysicianOrder, physicianOrder);
                    _this.PhysicianOrdersService.initializePhysicianOrder(physicianOrder);
                    _this.$mdDialog.hide(physicianOrder);
                    _this.$state.go('patients.physician-orders', { patient_id: physicianOrder.patient_id });
                }, function (errors) {
                    _this.errors = errors.errors;
                });
            };
            PhysicianOrderSignatureController.prototype.ePrescribeOrder = function () {
                if (this.physicianOrder.dea_number) {
                    this.$mdDialog.hide();
                    return this.PhysicianOrdersService.showTwoFactorDialog(this.physicianOrder);
                }
                else {
                    if (!this.physicianOrder.validate_e_prescribe) {
                        this.physicianOrder.validate_e_prescribe = [];
                    }
                    this.physicianOrder.validate_e_prescribe.push('You need to select a DEA Number.');
                }
            };
            PhysicianOrderSignatureController.prototype.signPhysicianOrder = function (physicianOrder) {
                var _this = this;
                if (this.ePrescribe) {
                    if (physicianOrder.dea_number) {
                        this.$mdDialog.hide();
                        return this.PhysicianOrdersService.showTwoFactorDialog(physicianOrder);
                    }
                    else {
                        if (!physicianOrder.validate_e_prescribe) {
                            physicianOrder.validate_e_prescribe = [];
                        }
                        physicianOrder.validate_e_prescribe.push('You need to select a DEA Number.');
                    }
                }
                else {
                    this.PhysicianOrdersService.addSignature(physicianOrder).then(function (signedPhysicianOrder) {
                        angular.copy(signedPhysicianOrder, physicianOrder);
                        _this.PhysicianOrdersService.initializePhysicianOrder(physicianOrder);
                        if (_this.$state.current.name === 'patients.physician-orders.new' ||
                            _this.$state.current.name === 'patients.physician-orders.edit' ||
                            _this.$state.current.name === 'patients.reorder-medications') {
                            _this.$state.go('patients.physician-orders.view', {
                                patient_id: physicianOrder.patient_id,
                                id: physicianOrder.id,
                            });
                        }
                        return _this.$mdDialog.hide(physicianOrder);
                    }, function (errors) {
                        _this.errors = errors.errors;
                    });
                }
            };
            PhysicianOrderSignatureController.prototype.splitOutByPharmacy = function () {
                var _this = this;
                this.$http
                    .post('/api/v1/physician_orders/' + this.physicianOrder.id + '/split_out_by_pharmacies', {})
                    .then(function (response) {
                    _this.physicianOrder = response.data[0];
                    _this.PhysicianOrdersService.initializePhysicianOrder(_this.physicianOrder);
                    _this.$state.go('patients.physician-orders.view', {
                        patientId: _this.physicianOrder.patient_id,
                        id: _this.physicianOrder.id,
                    });
                });
            };
            PhysicianOrderSignatureController.prototype.closeDialog = function () {
                if (this.$state.current.name === 'patients.physician-orders.new' ||
                    this.$state.current.name === 'patients.reorder-medications') {
                    this.$state.go('patients.physician-orders.view', {
                        patientId: this.currentPatient.id,
                        id: this.physicianOrder.id,
                    });
                }
                return this.$mdDialog.cancel();
            };
            PhysicianOrderSignatureController.$inject = [
                '$mdDialog',
                'currentPatient',
                'currentUser',
                'PhysicianOrdersService',
                '$state',
                '$filter',
                'physicianOrder',
                '$http',
            ];
            return PhysicianOrderSignatureController;
        }());
        angular
            .module('consolo')
            .controller('PhysicianOrderSignatureController', PhysicianOrderSignatureController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var TwoFactorSignatureController = /** @class */ (function () {
            function TwoFactorSignatureController($state, currentPatient, physicianOrder, logger, $mdDialog, $http, PhysicianOrdersService, MedicationService, idProofService, $mdMedia) {
                var _this = this;
                this.$state = $state;
                this.currentPatient = currentPatient;
                this.physicianOrder = physicianOrder;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.MedicationService = MedicationService;
                this.idProofService = idProofService;
                this.$mdMedia = $mdMedia;
                this.ePrescribing = false;
                this.consoloPw = null;
                this.tokenType = null;
                this.idProofId = null;
                this.hasHwToken = false;
                this.hasSwToken = false;
                this.idProofService.getProven().then(function (proofs) { return _this.setIdentityProof(proofs[0]); }, // Someday we may need to add a drop-down picker
                function (// Someday we may need to add a drop-down picker
                errors) { return _this.logger.error(errors.join('; ')); });
                this.ePrescribing = false;
                this.errors = [];
                this.medicationsTable = {
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Drug Name',
                            field: 'orderdate',
                            formatValueFn: function (transaction) {
                                if (transaction.medication) {
                                    //It's a Medication Transaction
                                    var medication = transaction.medication;
                                    if (medication.is_compound) {
                                        return medication.name;
                                    }
                                    else {
                                        return medication.name + ' ' + medication.strength + ' ' + medication.form;
                                    }
                                }
                                else {
                                    //It's a combined Medication Kit
                                    return transaction.patient_medication_kit.name;
                                }
                            },
                        },
                        {
                            title: 'Directions for Use',
                            field: 'medication.directions_for_use',
                            formatValueFn: function (transaction) {
                                if (transaction.medication) {
                                    //It's a Medication Transaction
                                    return transaction.medication.directions_for_use;
                                }
                                else {
                                    //It's a combined Medication Kit
                                    return transaction.directions_for_use;
                                }
                            },
                        },
                        {
                            title: 'Notes to Pharmacist',
                            field: 'medication.notes_to_pharmacist',
                            formatValueFn: function (transaction) {
                                if (transaction.medication) {
                                    //It's a Medication Transaction
                                    return transaction.medication.notes_to_pharmacist;
                                }
                                else {
                                    //It's a combined Medication Kit
                                    return transaction.notes_to_pharmacist;
                                }
                            },
                        },
                        {
                            title: 'Quantity',
                            field: 'quantity_display',
                            formatValueFn: function (transaction) {
                                if (transaction.medication) {
                                    var medication = transaction.medication;
                                    return transaction.quantity + ' ' + medication.quantity_unit_of_measure;
                                }
                                else {
                                    return '1';
                                }
                            },
                        },
                        {
                            title: 'Refills',
                            field: 'refills',
                            formatValueFn: function (transaction) {
                                if (transaction.refills) {
                                    return transaction.refills;
                                }
                                else {
                                    return '0';
                                }
                            },
                        },
                        {
                            title: 'DAW',
                            field: 'dispense_as_written',
                            formatValueFn: function (transaction) {
                                if (transaction.medication) {
                                    return transaction.dispense_as_written ? 'Y' : 'N';
                                }
                                else {
                                    return 'Y';
                                }
                            },
                        },
                    ],
                };
                this.medKitMedicationsTable = {
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Drug Name',
                            field: 'orderdate',
                            formatValueFn: function (medication) {
                                return medication.name + ' ' + medication.strength + ' ' + medication.form;
                            },
                        },
                        {
                            title: 'Directions for Use',
                            field: 'directions_for_use',
                        },
                        {
                            title: 'Notes to Pharmacist',
                            field: 'notes_to_pharmacist',
                        },
                        {
                            title: 'Quantity',
                            field: 'quantity_display',
                            formatValueFn: function (medication) {
                                return medication.quantity_display + ' ' + medication.quantity_unit_of_measure;
                            },
                        },
                        {
                            title: 'Refills',
                            field: 'refills',
                            formatValueFn: function (medication) {
                                if (medication.refills) {
                                    return medication.refills;
                                }
                                return 0;
                            },
                        },
                        {
                            title: 'DAW',
                            field: 'dispense_as_written',
                            formatValueFn: function (medication) {
                                return medication.dispense_as_written ? 'Y' : 'N';
                            },
                        },
                    ],
                };
                var pharmacyMedications = {};
                this.physicianOrder.medication_transactions.forEach(function (medicationTransaction) {
                    medicationTransaction.medFormDisplay = '';
                    if (medicationTransaction.transaction_type !== 'discontinue') {
                        _this.MedicationService.setMedFormDisplay(medicationTransaction);
                        if (!pharmacyMedications[medicationTransaction.surescripts_pharmacy_id]) {
                            pharmacyMedications[medicationTransaction.surescripts_pharmacy_id] = {
                                medicationTransactions: [medicationTransaction],
                                patientMedicationKitTransactions: [],
                                surescriptsPharmacy: medicationTransaction.surescripts_pharmacy,
                            };
                        }
                        else {
                            pharmacyMedications[medicationTransaction.surescripts_pharmacy_id].medicationTransactions.push(medicationTransaction);
                        }
                    }
                });
                this.physicianOrder.patient_medication_kit_transactions.forEach(function (medKitTransaction) {
                    if (!pharmacyMedications[medKitTransaction.surescripts_pharmacy_id]) {
                        pharmacyMedications[medKitTransaction.surescripts_pharmacy_id] = {
                            medicationTransactions: [],
                            patientMedicationKitTransactions: [medKitTransaction],
                            surescriptsPharmacy: medKitTransaction.surescripts_pharmacy,
                        };
                    }
                    else {
                        pharmacyMedications[medKitTransaction.surescripts_pharmacy_id].patientMedicationKitTransactions.push(medKitTransaction);
                    }
                });
                this.transactionsGroupedByPharmacy = [];
                for (var _i = 0, _a = Object.keys(pharmacyMedications); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this.transactionsGroupedByPharmacy.push(pharmacyMedications[key]);
                }
            }
            TwoFactorSignatureController.prototype.addTwoFactorSignature = function () {
                var _this = this;
                if (this.otp && this.consoloPw) {
                    this.ePrescribing = true;
                    this.$http
                        .post('/api/v1/physician_orders/' + this.physicianOrder.id + '/signatures', {
                        consolo_password: this.consoloPw,
                        two_factor_password: this.otp,
                        token_type: this.tokenType,
                        identity_proof_id: this.idProofId,
                        dea_number: this.physicianOrder.dea_number,
                    })
                        .then(function (response) {
                        _this.logger.success('Physician order signed and medications sent to pharmacy.');
                        angular.copy(response.data, _this.physicianOrder);
                        _this.MedicationService.replaceMedicationsFromOrder(_this.physicianOrder);
                        _this.ePrescribing = false;
                        _this.navigateToPage();
                        return _this.$mdDialog.hide(_this.physicianOrder);
                    }, function (resp) {
                        _this.PhysicianOrdersService.get(_this.physicianOrder.id).then(function (updatedPhysicianOrder) {
                            angular.copy(updatedPhysicianOrder, _this.physicianOrder);
                            _this.MedicationService.replaceMedicationsFromOrder(_this.physicianOrder);
                            _this.ePrescribing = false;
                        });
                        _this.errors = resp.data.errors;
                    });
                }
                else {
                    this.errors = ['The One Time Password and Consolo Password must both be entered.'];
                }
            };
            TwoFactorSignatureController.prototype.closeDialog = function () {
                this.navigateToPage();
                return this.$mdDialog.cancel();
            };
            TwoFactorSignatureController.prototype.navigateToPage = function () {
                if (this.$state.current.name === 'patients.physician-orders.new') {
                    this.$state.go('patients.physician-orders.view', {
                        patientId: this.currentPatient.id,
                        id: this.physicianOrder.id,
                    });
                }
            };
            TwoFactorSignatureController.prototype.labelClass = function () {
                if (this.$mdMedia('gt-sm')) {
                    return 'text-right margin-right-xs';
                }
                else {
                    return 'margin-right-xs';
                }
            };
            TwoFactorSignatureController.prototype.setIdentityProof = function (proof) {
                this.idProofId = proof.id;
                this.tokenType = proof.default_token_type;
                this.hasHwToken = proof.token_types.indexOf('hw') > -1;
                this.hasSwToken = proof.token_types.indexOf('sw') > -1;
            };
            TwoFactorSignatureController.$inject = [
                '$state',
                'currentPatient',
                'physicianOrder',
                'logger',
                '$mdDialog',
                '$http',
                'PhysicianOrdersService',
                'MedicationService',
                'IdentityProofService',
                '$mdMedia',
            ];
            return TwoFactorSignatureController;
        }());
        angular
            .module('consolo')
            .controller('TwoFactorSignatureController', TwoFactorSignatureController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ViewPhysicianOrderController = /** @class */ (function () {
            function ViewPhysicianOrderController(CurrentPatient, PhysicianOrdersService, $state, MedicationService) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.$state = $state;
                this.MedicationService = MedicationService;
                this.today = new Date();
                this.rphSignature = false;
                this.physicianSignature = false;
                this.hasVisitFrequencyEnabled = false;
                this.hasVisitFrequencyEnabled = this.PhysicianOrdersService.hasVisitFrequencyEnabled;
                this.PhysicianOrdersService.get($state.params.id).then(function (physicianOrder) {
                    _this.physicianOrder = physicianOrder;
                    _this.PhysicianOrdersService.initializePhysicianOrder(_this.physicianOrder);
                    _this.addInteractions();
                });
                this.assigneesLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        { title: 'Discipline', field: 'discipline' },
                        { title: 'Effective Date', field: 'assignment_effective_date' },
                        { title: 'Visits', field: 'visit_frequency_string' },
                        { title: 'PRN Visits', field: 'prn_visit_frequency_string' },
                    ]
                };
            }
            ViewPhysicianOrderController.prototype.addInteractions = function () {
                var _this = this;
                this.drugInteractions = [];
                this.allergyInteractions = [];
                if (this.physicianOrder.newMedicationTransactions) {
                    this.physicianOrder.newMedicationTransactions.forEach(function (medicationTransaction) {
                        var medication = medicationTransaction.medication;
                        //populate all drug interactions
                        if (medication.drug_interactions) {
                            medication.drug_interactions.forEach(function (interaction) {
                                _this.drugInteractions.push(interaction);
                            });
                        }
                        //populate all allergy interactions
                        if (medication.allergy_interactions) {
                            medication.allergy_interactions.forEach(function (interaction) {
                                _this.allergyInteractions.push(interaction);
                            });
                        }
                    });
                }
            };
            ViewPhysicianOrderController.$inject = [
                'CurrentPatient',
                'PhysicianOrdersService',
                '$state',
                'MedicationService',
            ];
            return ViewPhysicianOrderController;
        }());
        angular
            .module('consolo')
            .controller('ViewPhysicianOrderController', ViewPhysicianOrderController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PlanOfCareDataTableService = /** @class */ (function (_super) {
            __extends(PlanOfCareDataTableService, _super);
            function PlanOfCareDataTableService($http, PlanOfCareService, $interval, $state, $window, CurrentPatient, pdfer) {
                var _this = _super.call(this, $http) || this;
                _this.PlanOfCareService = PlanOfCareService;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.$window = $window;
                _this.CurrentPatient = CurrentPatient;
                _this.pdfer = pdfer;
                _this.loadingData = false;
                _this.showFilters = true;
                _this.apiSelectUrl = '/api/v1/plan_of_cares/paginated';
                _this.filters = new consolo.domain.PlanOfCareFilter();
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        var planOfCareData = data.data.map(function (planOfCare) {
                            return new consolo.domain.PlanOfCare(planOfCare);
                        });
                        _this.data = planOfCareData;
                        return planOfCareData;
                    });
                };
                _this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                    },
                    rowHideFn: function (planOfCare) {
                        return planOfCare.deleted_at !== undefined;
                    },
                    columns: [
                        {
                            title: 'Patient',
                            field: 'patient.name',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({patientId: $patient_id})',
                            columnHideFn: function () {
                                return !_this.global;
                            },
                        },
                        {
                            title: 'MRN',
                            field: 'patient.mrn',
                            type: csg.datatable.ColumnTypes.short_text,
                            columnHideFn: function () {
                                return !_this.global;
                            },
                        },
                        {
                            title: 'Office',
                            field: 'office.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Team',
                            field: 'team.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.date,
                            cssClass: 'no-wrap',
                        },
                        {
                            title: 'Notes',
                            field: 'notes',
                            formatValueFn: function (planOfCare) {
                                return planOfCare.notes ? planOfCare.notes.substring(0, 400) : '';
                            },
                        },
                        {
                            title: 'Signatures',
                            field: 'signatureString',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Last Updated',
                            field: 'updated_at',
                            type: csg.datatable.ColumnTypes.timeAgo,
                            cssClass: 'no-wrap',
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (planOfCare) {
                                if (planOfCare.isPastPlanOfCare()) {
                                    return '<div class="center-gt-sm"><i class="material-icons">lock</i></div>';
                                }
                                else if (planOfCare.is_current) {
                                    if (planOfCare.signatures.length) {
                                        return ('<div><span class="box-highlight-green box-highlight-lock-margin">' +
                                            'current</span><span><i class="material-icons">lock</i></span></div>');
                                    }
                                    return '<span class="center-gt-sm box-highlight-green">current</span>';
                                }
                                else {
                                    return '<span class="box-highlight-blue center-gt-sm">future</span>';
                                }
                            },
                            cssClass: 'no-wrap',
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (planOfCare, column, $event) {
                                    $http
                                        .get('/api/v1/plan_of_cares/' + planOfCare.id)
                                        .then(function (response) {
                                        var fullPlanOfCare = new consolo.domain.PlanOfCare(response.data);
                                        return _this.PlanOfCareService.view(fullPlanOfCare, $event);
                                    })
                                        .catch(function (error) {
                                        // Catch picks up as error when view modal is cancelled, this checks for that case
                                        if (error !== undefined) {
                                            return console.log(error);
                                        }
                                    });
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (planOfCare) {
                                    _this.$state.go('patients.plan-of-care-edit.main', { id: planOfCare.id });
                                },
                                conditionalHideFn: function (planOfCare) {
                                    return !_this.PlanOfCareService.allowedToEdit(planOfCare);
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (planOfCare) {
                                    return _this.PlanOfCareService.remove(planOfCare).then(function (updatedPlanOfCare) {
                                        angular.copy(updatedPlanOfCare, planOfCare);
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                },
                                conditionalHideFn: function (planOfCare) {
                                    return !_this.PlanOfCareService.allowedToDelete(planOfCare);
                                },
                            },
                            {
                                label: 'Download PDF',
                                icon: 'print',
                                callbackFn: function (planOfCare) {
                                    if (_this.pdfer.enabled) {
                                        return _this.PlanOfCareService.pdferPrint(planOfCare.id, planOfCare.patient_id);
                                    }
                                    return _this.$window.open(planOfCare.pdf_link);
                                },
                                conditionalHideFn: function (planOfCare) {
                                    return !planOfCare.pdf_id;
                                },
                            },
                            {
                                label: 'Print',
                                icon: 'print',
                                callbackFn: function (planOfCare) {
                                    return _this.PlanOfCareService.createPdf(planOfCare.id, planOfCare.patient_id);
                                },
                                conditionalHideFn: function (planOfCare) {
                                    return _this.pdfGenerated(planOfCare);
                                },
                            },
                            {
                                label: 'Add Signature',
                                icon: 'add',
                                callbackFn: function (planOfCare) {
                                    return _this.PlanOfCareService.addSignature(planOfCare).then(function (updatedPlanOfCare) {
                                        angular.copy(updatedPlanOfCare, planOfCare);
                                        return true;
                                    });
                                },
                                conditionalHideFn: function (planOfCare) {
                                    return _this.pdfGenerated(planOfCare);
                                },
                            },
                            {
                                label: 'Add Physician/Medical Director Signature',
                                icon: 'add',
                                callbackFn: function (planOfCare) {
                                    return _this.PlanOfCareService.signatureModal(planOfCare).then(function (updatedPlanOfCare) {
                                        angular.copy(updatedPlanOfCare, planOfCare);
                                        return true;
                                    });
                                },
                                conditionalHideFn: function (planOfCare) {
                                    return _this.pdfGenerated(planOfCare);
                                },
                            },
                        ],
                    },
                };
                return _this;
            }
            PlanOfCareDataTableService.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            PlanOfCareDataTableService.prototype.pdfGenerated = function (planOfCare) {
                if (planOfCare.pdf_id) {
                    return true;
                }
                return false;
            };
            PlanOfCareDataTableService.prototype.setPatientId = function (id) {
                this.filters.patient_id = id;
            };
            PlanOfCareDataTableService.prototype.initialize = function (global) {
                this.global = global;
                this.tableLabel = 'Plan Of Care History';
                if (this.global) {
                    this.filters.patient_id = null;
                    this.showFilters = true;
                }
                else {
                    this.showFilters = false;
                    this.tableLabel += ': ' + this.CurrentPatient.patient.name;
                }
            };
            PlanOfCareDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.PlanOfCareFilter();
                if (this.CurrentPatient.patient_id) {
                    this.filters.patient_id = this.CurrentPatient.patient_id;
                }
                this.search();
            };
            PlanOfCareDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            PlanOfCareDataTableService.$inject = [
                '$http',
                'PlanOfCareService',
                '$interval',
                '$state',
                '$window',
                'CurrentPatient',
                'pdfer',
            ];
            return PlanOfCareDataTableService;
        }(csg.datatable.ApiService));
        services.PlanOfCareDataTableService = PlanOfCareDataTableService;
        angular.module('consolo').service('PlanOfCareDataTableService', PlanOfCareDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var PlanOfCareEditController = /** @class */ (function () {
            function PlanOfCareEditController(Session, $state, PlanOfCareService, logger, $stateParams, currentPlanOfCare) {
                this.Session = Session;
                this.$state = $state;
                this.PlanOfCareService = PlanOfCareService;
                this.logger = logger;
                var vm = this;
                vm.save_disabled = false;
                vm.items = $stateParams.items;
                vm.currentPlanOfCare = currentPlanOfCare;
                vm.currentPlanOfCare.effective_date = vm.getDateFromString(vm.currentPlanOfCare.effective_date);
                vm.sections = [
                    'main',
                    'assignments',
                    'care-plan-problems',
                    'clinical-charts',
                    'dme-orders',
                    'indicators',
                    'medications',
                    'medication-notes',
                    'physician-orders',
                    'volunteer-care-plans',
                ];
                vm.setUserDashboardUrl();
            }
            PlanOfCareEditController.prototype.setUserDashboardUrl = function () {
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
            };
            PlanOfCareEditController.prototype.save = function (planOfCare) {
                var _this = this;
                this.save_disabled = true;
                var func;
                if (planOfCare.id) {
                    func = this.PlanOfCareService.update(planOfCare);
                }
                else {
                    func = this.PlanOfCareService.create(planOfCare);
                }
                func.then(function (resp) {
                    _this.logger.success('Plan of Care saved');
                    _this.$state.go('patients.plan-of-cares-list');
                    _this.save_disabled = false;
                }, function (data) {
                    _this.logger.error(data.data.errors.join());
                    _this.save_disabled = false;
                    return;
                });
            };
            PlanOfCareEditController.prototype.getDateFromString = function (myDate) {
                if (myDate) {
                    var tempDate = new Date(myDate);
                    return new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());
                }
            };
            PlanOfCareEditController.prototype.next = function () {
                var curr_section = '', next_section = '';
                curr_section = this.$state.current.name.split('.').slice(-1)[0];
                if (curr_section === this.sections[this.sections.length - 1]) {
                    next_section = this.sections[0];
                }
                else {
                    next_section = this.sections[this.sections.indexOf(curr_section) + 1];
                }
                this.$state.go('patients.plan-of-care-edit.' + next_section);
            };
            PlanOfCareEditController.prototype.prev = function () {
                var curr_section = '', prev_section = '';
                curr_section = this.$state.current.name.split('.').slice(-1)[0];
                if (curr_section === this.sections[0]) {
                    prev_section = this.sections[this.sections.length - 1];
                }
                else {
                    prev_section = this.sections[this.sections.indexOf(curr_section) - 1];
                }
                this.$state.go('patients.plan-of-care-edit.' + prev_section);
            };
            PlanOfCareEditController.$inject = [
                'SessionService',
                '$state',
                'PlanOfCareService',
                'logger',
                '$stateParams',
                'currentPlanOfCare',
            ];
            return PlanOfCareEditController;
        }());
        angular
            .module('consolo')
            .controller('PlanOfCareEditController', PlanOfCareEditController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var PlanOfCareListController = /** @class */ (function () {
            function PlanOfCareListController($state, $mdDialog, $window, $http, logger, currentPatient, PlanOfCareService, Permissions, $q, global, PlanOfCareDataTableService) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$window = $window;
                this.logger = logger;
                this.currentPatient = currentPatient;
                this.PlanOfCareService = PlanOfCareService;
                this.Permissions = Permissions;
                this.$q = $q;
                this.global = global;
                this.PlanOfCareDataTableService = PlanOfCareDataTableService;
                this.errorMessage = '';
                this.PlanOfCareDataTableService.initialize(global);
                if ($state.params.patientId) {
                    this.patient_name = currentPatient.name;
                    this.PlanOfCareDataTableService.setPatientId($state.params.patientId);
                }
                this.activate();
            }
            PlanOfCareListController.prototype.activate = function () {
                if (this.Permissions.can('show', 'plan_of_cares')) {
                    this.PlanOfCareDataTableService.search();
                }
                else {
                    this.logger.error('You do not have permission to view Plans of Care');
                    this.$state.go('patients.dashboard');
                }
            };
            PlanOfCareListController.prototype.newPOC = function () {
                this.PlanOfCareService.clearCurrentPlanOfCare();
                this.$state.go('patients.plan-of-care-new.main');
            };
            PlanOfCareListController.$inject = [
                '$state',
                '$mdDialog',
                '$window',
                '$http',
                'logger',
                'currentPatient',
                'PlanOfCareService',
                'PermissionsService',
                '$q',
                'global',
                'PlanOfCareDataTableService',
            ];
            return PlanOfCareListController;
        }());
        angular
            .module('consolo')
            .controller('PlanOfCareListController', PlanOfCareListController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PlanOfCarePdfOptionsController = /** @class */ (function () {
            function PlanOfCarePdfOptionsController($http, logger, $mdDialog, PlanOfCareService, planOfCareId, patientId, pdfer) {
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.PlanOfCareService = PlanOfCareService;
                this.planOfCareId = planOfCareId;
                this.patientId = patientId;
                this.pdfer = pdfer;
                this.pdf_options = {};
            }
            PlanOfCarePdfOptionsController.prototype.print = function () {
                var _this = this;
                if (this.pdfer.enabled) {
                    this.PlanOfCareService.pdferPrint(this.planOfCareId, this.patientId, this.pdf_options);
                    this.$mdDialog.hide();
                }
                else {
                    return this.$http
                        .post(this.PlanOfCareService.url + '/pdf', {
                        id: this.planOfCareId,
                        pdf_options: this.pdf_options,
                    })
                        .then(function (data) {
                        _this.logger.success('Your PDF is being generated and you will be notified when it is complete.');
                        _this.$mdDialog.hide();
                    });
                }
            };
            PlanOfCarePdfOptionsController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            PlanOfCarePdfOptionsController.$inject = [
                '$http',
                'logger',
                '$mdDialog',
                'PlanOfCareService',
                'planOfCareId',
                'patientId',
                'pdfer',
            ];
            return PlanOfCarePdfOptionsController;
        }());
        angular
            .module('consolo')
            .controller('PlanOfCarePdfOptionsController', PlanOfCarePdfOptionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var PlanOfCareSectionController = /** @class */ (function () {
            function PlanOfCareSectionController($state, PlanOfCareService, logger, items) {
                this.$state = $state;
                this.logger = logger;
                this.hasVisitFrequencyEnabled = false;
                var vm = this;
                vm.items = items;
                vm.currentPlanOfCare = PlanOfCareService.currentPlanOfCare;
                vm.hasVisitFrequencyEnabled = PlanOfCareService.hasVisitFrequencyEnabled;
            }
            PlanOfCareSectionController.$inject = ['$state', 'PlanOfCareService', 'logger', 'items'];
            return PlanOfCareSectionController;
        }());
        angular
            .module('consolo')
            .controller('PlanOfCareSectionController', PlanOfCareSectionController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var PlanOfCareController = /** @class */ (function () {
            function PlanOfCareController($state, PlanOfCareService, logger, currentPatient) {
                this.$state = $state;
                this.PlanOfCareService = PlanOfCareService;
                this.logger = logger;
                this.currentPatient = currentPatient;
                var vm = this;
                vm.save_disabled = false;
                vm.currentPlanOfCare = PlanOfCareService.getCurrentPlanOfCare();
                vm.sections = [
                    'main',
                    'assignments',
                    'care-plan-problems',
                    'clinical-charts',
                    'dme-orders',
                    'indicators',
                    'medications',
                    'medication-notes',
                    'physician-orders',
                    'volunteer-care-plans',
                ];
            }
            PlanOfCareController.prototype.save = function (plan_of_care) {
                var _this = this;
                this.save_disabled = true;
                this.PlanOfCareService.create(plan_of_care).then(function (resp) {
                    _this.logger.success('Plan of Care saved');
                    _this.$state.go('patients.plan-of-cares-list');
                    _this.save_disabled = false;
                }, function (data) {
                    _this.logger.error(data.data.errors.join());
                    _this.save_disabled = false;
                    return;
                });
            };
            PlanOfCareController.prototype.next = function () {
                var curr_section = '', next_section = '';
                curr_section = this.$state.current.name.split('.').slice(-1)[0];
                if (curr_section === this.sections[this.sections.length - 1]) {
                    next_section = this.sections[0];
                }
                else {
                    next_section = this.sections[this.sections.indexOf(curr_section) + 1];
                }
                this.$state.go('patients.plan-of-care-new.' + next_section);
            };
            PlanOfCareController.prototype.prev = function () {
                var curr_section = '', prev_section = '';
                curr_section = this.$state.current.name.split('.').slice(-1)[0];
                if (curr_section === this.sections[0]) {
                    prev_section = this.sections[this.sections.length - 1];
                }
                else {
                    prev_section = this.sections[this.sections.indexOf(curr_section) - 1];
                }
                this.$state.go('patients.plan-of-care-new.' + prev_section);
            };
            PlanOfCareController.$inject = ['$state', 'PlanOfCareService', 'logger', 'currentPatient'];
            return PlanOfCareController;
        }());
        angular.module('consolo').controller('PlanOfCareController', PlanOfCareController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PlanOfCareService = /** @class */ (function () {
            function PlanOfCareService($http, $state, Permissions, $q, WindowStatus, Session, logger, $mdDialog, PdfGenerator, CurrentPatient, pdfer) {
                this.$http = $http;
                this.$state = $state;
                this.Permissions = Permissions;
                this.$q = $q;
                this.WindowStatus = WindowStatus;
                this.Session = Session;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.PdfGenerator = PdfGenerator;
                this.CurrentPatient = CurrentPatient;
                this.pdfer = pdfer;
                this.loadingData = false;
                this.hasUpdatePermission = false;
                this.hasCreatePermission = false;
                this.hasDestroyPermission = false;
                this.hasVisitFrequencyEnabled = false;
                this.url = '/api/v1/plan_of_cares';
                this.hasUpdatePermission = this.Permissions.can('update', 'plan_of_cares');
                this.hasCreatePermission = this.Permissions.can('create', 'plan_of_cares');
                this.hasDestroyPermission = this.Permissions.can('destroy', 'plan_of_cares');
                this.hasVisitFrequencyEnabled = this.Session.get().agency.has_visit_frequency;
                var user = this.Session.getUser();
                if (user) {
                    this.user_dashboard_url = user.dashboard_url;
                }
                this.formModelMeds = {
                    options: {
                        hideFilter: true,
                        hideSearch: true,
                    },
                    header: [
                        {
                            title: 'Order Date',
                            field: 'orderdate',
                        },
                        {
                            title: 'Start Date',
                            field: 'dosage_frequency_start_datetime',
                            isDate: true,
                        },
                        {
                            title: 'DC Date',
                            field: 'dc',
                        },
                        {
                            title: 'Medication',
                            field: 'name',
                        },
                        {
                            title: 'Dosage',
                            field: 'frequency_for_display',
                        },
                        {
                            title: 'Instructions',
                            field: 'notes',
                        },
                        {
                            title: 'Covered',
                            field: 'is_covered',
                            isBoolean: true,
                        },
                        {
                            title: 'Reasons',
                            field: 'prn_reason',
                        },
                    ],
                };
            }
            PlanOfCareService.prototype.getDestroyPermission = function () {
                return this.hasDestroyPermission;
            };
            PlanOfCareService.prototype.getUpdatePermission = function () {
                return this.hasUpdatePermission;
            };
            PlanOfCareService.prototype.clearCurrentPlanOfCare = function () {
                this.currentPlanOfCare = null;
            };
            PlanOfCareService.prototype.getCurrentPlanOfCare = function () {
                if (!this.currentPlanOfCare) {
                    this.currentPlanOfCare = this.new(this.CurrentPatient.patient_id);
                }
                return this.currentPlanOfCare;
            };
            PlanOfCareService.prototype.createPdf = function (planOfCareId, patientId) {
                if (this.pdfer.enabled) {
                    return this.pdferPrint(planOfCareId, patientId);
                }
                var url = '\'/y/templates/plan-of-care/plan-of-care-pdf-options.html\'';
                var modal = {
                    template: '<md-dialog flex="50" aria-label="PDF Options">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'PlanOfCarePdfOptionsController',
                    controllerAs: 'vm',
                    hasBackdrop: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                    locals: { planOfCareId: planOfCareId, patientId: patientId },
                };
                return this.$mdDialog.show(modal);
            };
            PlanOfCareService.prototype.signatureModal = function (planOfCare) {
                return this.$mdDialog.show({
                    locals: { item: planOfCare, patientId: planOfCare.patient_id },
                    templateUrl: '/y/templates/plan-of-care/signature-modal.html',
                    controller: 'PlanOfCareSignatureController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                });
            };
            PlanOfCareService.prototype.setCurrentPlanOfCare = function (planOfCare) {
                this.currentPlanOfCare = planOfCare;
            };
            PlanOfCareService.prototype.new = function (patientId) {
                this.currentPlanOfCare = new consolo.domain.PlanOfCare({ patient_id: patientId });
                return this.currentPlanOfCare;
            };
            PlanOfCareService.prototype.create = function (plan_of_care) {
                var _this = this;
                return this.$http.post(this.url, plan_of_care).then(function (data) {
                    _this.currentPlanOfCare = new consolo.domain.PlanOfCare(data.data);
                    return _this.currentPlanOfCare;
                });
            };
            PlanOfCareService.prototype.view = function (planOfCare, $event) {
                this.setCurrentPlanOfCare(planOfCare);
                return this.$mdDialog
                    .show({
                    locals: { currentPlanOfCare: planOfCare },
                    targetEvent: $event,
                    fullScreen: true,
                    templateUrl: '/y/templates/plan-of-care/view-modal.html',
                    controller: 'PlanOfCareViewController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                })
                    .then(function (resp) {
                    return angular.copy(resp, planOfCare);
                });
            };
            PlanOfCareService.prototype.delete = function (planOfCare) {
                var _this = this;
                return this.$http.delete(this.url + '/' + planOfCare.id).then(function (data) {
                    _this.logger.success('Care of Plan deleted.');
                    _this.currentPlanOfCare = new consolo.domain.PlanOfCare(data.data);
                    return _this.currentPlanOfCare;
                });
            };
            PlanOfCareService.prototype.get = function (id) {
                var _this = this;
                return this.$http.get(this.url + '/' + id).then(function (data) {
                    _this.currentPlanOfCare = new consolo.domain.PlanOfCare(data.data);
                    return _this.currentPlanOfCare;
                });
            };
            PlanOfCareService.prototype.paginated = function (params) {
                console.log('START poc params');
                console.log(params);
                console.log('REAL END poc params');
                return this.$http.get(this.url + '/paginated', {
                    params: params,
                }).then(function (data) {
                    return data.data.map(function (planOfCare) {
                        return new consolo.domain.PlanOfCare(planOfCare);
                    });
                });
            };
            PlanOfCareService.prototype.update = function (planOfCare) {
                var _this = this;
                return this.$http.patch(this.url + '/' + planOfCare.id, planOfCare).then(function (data) {
                    _this.currentPlanOfCare = new consolo.domain.PlanOfCare(planOfCare);
                    _this.logger.success('Plan Of Care Updated');
                    return _this.currentPlanOfCare;
                });
            };
            PlanOfCareService.prototype.addSignature = function (planOfCare) {
                var _this = this;
                return this.$http
                    .post('/api/v1/plan_of_cares/signatures', { id: planOfCare.id })
                    .then(function (data) {
                    _this.currentPlanOfCare = new consolo.domain.PlanOfCare(data.data);
                    _this.logger.success('Signature Was Added');
                    return _this.currentPlanOfCare;
                });
            };
            PlanOfCareService.prototype.sortByDate = function (items, date_field) {
                return items.sort(function (a, b) {
                    if (a[date_field] < b[date_field]) {
                        return 1;
                    }
                    if (a[date_field] > b[date_field]) {
                        return -1;
                    }
                    if (a.created_at < b.created_at) {
                        return 1;
                    }
                    if (a.created_at > b.created_at) {
                        return -1;
                    }
                    return 0;
                });
            };
            PlanOfCareService.prototype.remove = function (planOfCare) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete plan of care?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.delete(planOfCare);
                });
            };
            PlanOfCareService.prototype.dateOnly = function (datetime) {
                datetime = new Date(datetime.toString());
                return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
            };
            PlanOfCareService.prototype.activate = function () {
                var _this = this;
                var _a;
                if (Object.keys(this.currentPlanOfCare.static_json_attributes).length === 0) {
                    var promiseArray = [];
                    this.loadingData = true;
                    if (this.currentPlanOfCare.assignment_ids &&
                        this.currentPlanOfCare.assignment_ids.length &&
                        !this.currentPlanOfCare.assignments) {
                        promiseArray.push(this.$http
                            .get('/api/v1/assignments', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.assignment_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.assignments = _this.sortByDate(data.data, 'effective_date');
                        }));
                    }
                    if (this.currentPlanOfCare.care_plan_problem_ids &&
                        this.currentPlanOfCare.care_plan_problem_ids.length &&
                        !this.currentPlanOfCare.carePlanProblems) {
                        promiseArray.push(this.$http
                            .get('/api/v1/care_plan_problems', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.care_plan_problem_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.carePlanProblems = data.data;
                        }));
                    }
                    if (this.currentPlanOfCare.medication_ids &&
                        this.currentPlanOfCare.medication_ids.length &&
                        !this.currentPlanOfCare.medications) {
                        promiseArray.push(this.$http
                            .get('/api/v1/medications', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.medication_ids,
                                include_dc: this.CurrentPatient.patient.care_status === 'Deceased'
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.medications = data.data;
                        }));
                    }
                    this.canEdit =
                        this.currentPlanOfCare.is_current &&
                            !this.currentPlanOfCare.signatures.length &&
                            this.hasUpdatePermission;
                    if (this.currentPlanOfCare.indicator_ids &&
                        this.currentPlanOfCare.indicator_ids.length &&
                        !this.currentPlanOfCare.indicators) {
                        promiseArray.push(this.$http
                            .get('/api/v1/indicators', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.indicator_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.indicators = _this.sortByDate(data.data, 'effective_date');
                        }));
                    }
                    this.currentPlanOfCare.clinicalCharts = [];
                    if (this.$state.current.name === 'patients.plan-of-care-print') {
                        this.currentPlanOfCare.clinical_chart_ids.forEach(function (id) {
                            promiseArray.push(_this.$http
                                .get('/patients/' +
                                _this.currentPlanOfCare.patient_id +
                                '/clinical_charts/' +
                                id +
                                '.html')
                                .then(function (data) {
                                _this.currentPlanOfCare.clinicalCharts.push(data.data);
                            }));
                        });
                    }
                    else {
                        this.currentPlanOfCare.clinical_chart_ids.forEach(function (id) {
                            promiseArray.push(_this.$http.get('/api/v1/clinical_charts/' + id).then(function (data) {
                                _this.currentPlanOfCare.clinicalCharts.push(data.data);
                            }));
                        });
                    }
                    if (this.currentPlanOfCare.clinicalCharts) {
                        this.currentPlanOfCare.clinicalCharts = this.sortByDate(this.currentPlanOfCare.clinicalCharts, 'effective_date');
                    }
                    if (this.currentPlanOfCare.dme_order_ids &&
                        this.currentPlanOfCare.dme_order_ids.length &&
                        !this.currentPlanOfCare.dmeOrders) {
                        promiseArray.push(this.$http
                            .get('/api/v1/dme_orders', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.dme_order_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.dmeOrders = _this.sortByDate(data.data, 'effective_date');
                        }));
                    }
                    if (this.currentPlanOfCare.medication_note_ids &&
                        this.currentPlanOfCare.medication_note_ids.length &&
                        !this.currentPlanOfCare.medicationNotes) {
                        promiseArray.push(this.$http
                            .get('/api/v1/medication_notes', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.medication_note_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.medicationNotes = _this.sortByDate(data.data, 'effective_date');
                        }));
                    }
                    if (this.currentPlanOfCare.recurring_task_set_ids &&
                        this.currentPlanOfCare.recurring_task_set_ids.length &&
                        !this.currentPlanOfCare.recurringTaskSets) {
                        promiseArray.push(this.$http
                            .get('/api/v1/recurring_task_sets/get_by_ids', {
                            params: { 'ids[]': this.currentPlanOfCare.recurring_task_set_ids },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.recurringTaskSets = data.data;
                        }));
                    }
                    if (this.currentPlanOfCare.volunteer_care_plan_ids &&
                        this.currentPlanOfCare.volunteer_care_plan_ids.length &&
                        !this.currentPlanOfCare.volunteerCarePlans) {
                        promiseArray.push(this.$http
                            .get('/api/v1/volunteer_care_plans', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.volunteer_care_plan_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.volunteerCarePlans = _this.sortByDate(data.data, 'effective_date');
                        }));
                    }
                    if (this.currentPlanOfCare.physician_order_ids &&
                        this.currentPlanOfCare.physician_order_ids.length &&
                        !this.currentPlanOfCare.physicianOrders) {
                        promiseArray.push(this.$http
                            .get('/api/v1/physician_orders', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.physician_order_ids,
                                page_size: 100
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.physicianOrders = _this.sortByDate(data.data, 'assessment_date');
                        }));
                    }
                    if (this.currentPlanOfCare.unrelated_explanation_ids.length) {
                        promiseArray.push(this.$http
                            .get('/api/v1/unrelated_explanations', {
                            params: {
                                patient_id: this.currentPlanOfCare.patient_id,
                                'ids[]': this.currentPlanOfCare.unrelated_explanation_ids,
                            },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.unrelatedExplanations = data.data;
                        }));
                    }
                    this.WindowStatus.readyOnPromise(this.$q.all(promiseArray));
                }
                else {
                    var data = this.currentPlanOfCare.static_json_attributes;
                    var lockedPromiseArray = [];
                    this.loadingData = true;
                    this.currentPlanOfCare.assignments = data['assignments'] ? data['assignments'] : {};
                    this.currentPlanOfCare.carePlanProblems = data['care_plan_problems']
                        ? data['care_plan_problems']
                        : {};
                    this.currentPlanOfCare.dmeOrders = data['dme_orders'] ? data['dme_orders'] : {};
                    this.currentPlanOfCare.indicators = data['indicators'] ? data['indicators'] : {};
                    this.currentPlanOfCare.medications = data['medications'] ? data['medications'] : {};
                    this.currentPlanOfCare.medicationNotes = data['medication_notes']
                        ? data['medication_notes']
                        : {};
                    this.currentPlanOfCare.physicianOrders = data['physician_orders']
                        ? data['physician_orders']
                        : {};
                    this.currentPlanOfCare.recurringTaskSets = data['recurring_task_sets']
                        ? data['recurring_task_sets']
                        : {};
                    if (((_a = this.currentPlanOfCare.recurring_task_set_ids) === null || _a === void 0 ? void 0 : _a.length) &&
                        this.hasMissingTasks(this.currentPlanOfCare.recurringTaskSets)) {
                        lockedPromiseArray.push(this.$http
                            .get('/api/v1/recurring_task_sets/get_by_ids', {
                            params: { 'ids[]': this.currentPlanOfCare.recurring_task_set_ids },
                        })
                            .then(function (data) {
                            _this.currentPlanOfCare.recurringTaskSets = data.data;
                        }));
                    }
                    this.currentPlanOfCare.unrelatedExplanations = data['unrelated_explanations']
                        ? data['unrelated_explanations']
                        : {};
                    this.currentPlanOfCare.volunteerCarePlans = data['volunteer_care_plans']
                        ? data['volunteer_care_plans']
                        : {};
                    if (this.$state.current.name === 'patients.plan-of-care-print' && data['clinical_charts']) {
                        this.currentPlanOfCare.clinicalCharts = [];
                        data['clinical_charts'].forEach(function (chart) {
                            _this.$http
                                .post('/clinical_charts/ng_print.html', { clinical_chart: chart })
                                .then(function (data) {
                                _this.currentPlanOfCare.clinicalCharts.push(data.data);
                            });
                        });
                    }
                    else {
                        this.currentPlanOfCare.clinicalCharts = data['clinical_charts']
                            ? data['clinical_charts']
                            : {};
                    }
                    this.WindowStatus.readyOnPromise(this.$q.all(lockedPromiseArray));
                }
                this.currentPlanOfCare.setSignatureString();
                this.WindowStatus.readyOnPromise(this.$q.all(promiseArray).then(function () {
                    _this.loadingData = false;
                }));
            };
            PlanOfCareService.prototype.allowedToEdit = function (planOfCare) {
                return (!planOfCare.isPastPlanOfCare() &&
                    planOfCare.signatures.length === 0 &&
                    this.getUpdatePermission());
            };
            PlanOfCareService.prototype.allowedToDelete = function (planOfCare) {
                return (!planOfCare.isPastPlanOfCare() &&
                    planOfCare.signatures.length === 0 &&
                    this.getDestroyPermission());
            };
            PlanOfCareService.prototype.pdferPrint = function (id, patient_id, options) {
                return this.pdfer.generatePdfFromStates(['patients.plan-of-care-view'], [{ patient_id: patient_id, id: id }], id, 'PlanOfCare', false, options, false, false, '', '', patient_id, false, '#plan-of-care-view');
            };
            PlanOfCareService.prototype.pdferPrintCurrent = function (options) {
                return this.pdferPrint(this.currentPlanOfCare.id, this.currentPatientId, options);
            };
            PlanOfCareService.prototype.hasMissingTasks = function (recurringTaskSets) {
                var _a;
                if (((_a = recurringTaskSets) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    var missing = recurringTaskSets.filter(function (recurringTaskSet) {
                        return !recurringTaskSet.tasks;
                    });
                    if (missing.length > 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            };
            PlanOfCareService.$inject = [
                '$http',
                '$state',
                'PermissionsService',
                '$q',
                'WindowStatus',
                'SessionService',
                'logger',
                '$mdDialog',
                'PdfGenerator',
                'CurrentPatient',
                'pdfer',
            ];
            return PlanOfCareService;
        }());
        services.PlanOfCareService = PlanOfCareService;
        angular.module('consolo').service('PlanOfCareService', PlanOfCareService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Routes]);
    function Routes($stateProvider) {
        $stateProvider
            .state('plan-of-cares', {
            data: { title: 'Plans of Care', hideSidebar: true },
            url: '/plan_of_cares',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/plan-of-care/plan-of-care-list.html',
                    controller: 'PlanOfCareListController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('show', 'plan_of_cares');
                    },
                ],
                global: [
                    function () {
                        return true;
                    },
                ],
                currentPatient: [
                    function () {
                        return null;
                    },
                ],
            },
        })
            .state('patients.plan-of-cares-list', {
            data: { title: 'Plan of Care' },
            url: '/plan_of_cares?id',
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/patients/patient-sidebar.html',
                    controller: 'PatientDashboardSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/plan-of-care/plan-of-care-list.html',
                    controller: 'PlanOfCareListController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('show', 'plan_of_cares');
                    },
                ],
                global: [
                    function () {
                        return false;
                    },
                ],
            },
        })
            .state('patients.plan-of-care-view', {
            url: '/plan_of_care/:id?include_last_page_signature_line',
            data: { hideSidebar: true },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/patients/patient-sidebar.html',
                    controller: 'PatientDashboardSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/plan-of-care/view.html',
                    controller: 'PlanOfCareViewController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('show', 'plan_of_cares');
                    },
                ],
                currentPlanOfCare: [
                    'PlanOfCareService',
                    '$stateParams',
                    function (PlanOfCareService, $stateParams) {
                        return PlanOfCareService.get($stateParams.id, $stateParams.patientId).then(function (data) {
                            return data;
                        });
                    },
                ],
            },
        })
            .state('patients.plan-of-care-print', {
            url: '/plan_of_care/:id/print?include_last_page_signature_line',
            data: { hideSidebar: true },
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/plan-of-care/print.html',
                    controller: 'PlanOfCarePrintController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('show', 'plan_of_cares');
                    },
                ],
                currentPlanOfCare: [
                    'PlanOfCareService',
                    '$stateParams',
                    function (PlanOfCareService, $stateParams) {
                        return PlanOfCareService.get($stateParams.id, $stateParams.patientId).then(function (data) {
                            return data;
                        });
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new', {
            data: { title: 'Plan of Care' },
            abstract: true,
            url: '/plan_of_care/new',
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/plan-of-care/sidebar.html',
                    controller: 'PlanOfCareSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/plan-of-care/editor.html',
                    controller: 'PlanOfCareController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('create', 'plan_of_cares');
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.main', {
            url: '/main',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/main/main.html',
                    controller: 'PlanOfCareController as vm',
                },
            },
        })
            .state('patients.plan-of-care-new.medications', {
            url: '/medications',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/medications/medications.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'Medication',
                    '$stateParams',
                    function (Medication, $stateParams) {
                        return Medication.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.assignments', {
            url: '/assignments',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/assignments/assignments.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'Assignment',
                    '$stateParams',
                    function (Assignment, $stateParams) {
                        return Assignment.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.indicators', {
            url: '/indicators',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/indicators/indicators.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'Indicator',
                    '$stateParams',
                    function (Indicator, $stateParams) {
                        return Indicator.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.care-plan-problems', {
            url: '/care_plan_problems',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/care-plan-problems/care-plan-problems.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'CarePlanProblemEntry',
                    '$stateParams',
                    'PlanOfCareService',
                    function (CarePlanProblemEntry, $stateParams, PlanOfCareService) {
                        return PlanOfCareService.sortByDate(CarePlanProblemEntry.loadByPatientId($stateParams.patientId), 'effective_date');
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.clinical-charts', {
            url: '/clinical_charts',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/clinical-charts/clinical-charts.html',
                    controller: 'ClinicalChartsSectionController as vm',
                },
            },
        })
            .state('patients.plan-of-care-new.physician-orders', {
            url: '/physician_orders',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/physician-orders/physician-orders.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'PhysicianOrderEntry',
                    '$stateParams',
                    '$filter',
                    function (PhysicianOrderEntry, $stateParams) {
                        return PhysicianOrderEntry.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.dme-orders', {
            url: '/dme_orders',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/dme-orders/dme-orders.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'DmeOrder',
                    '$stateParams',
                    '$filter',
                    function (DmeOrder, $stateParams) {
                        return DmeOrder.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.volunteer-care-plans', {
            url: '/volunteer_care_plans',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/volunteer-care-plans/volunteer-care-plans.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'VolunteerCarePlan',
                    '$stateParams',
                    '$filter',
                    function (VolunteerCarePlan, $stateParams) {
                        return VolunteerCarePlan.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.medication-notes', {
            url: '/medication_notes',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/medication-notes/medication-notes.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'MedicationNote',
                    '$stateParams',
                    '$filter',
                    function (MedicationNote, $stateParams) {
                        return MedicationNote.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.unrelated-explanations', {
            url: '/unrelated_explanations',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/unrelated-explanations/unrelated-explanations.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'UnrelatedExplanation',
                    '$stateParams',
                    function (UnrelatedExplanation, $stateParams) {
                        return UnrelatedExplanation.query({
                            patient_id: $stateParams.patientId,
                            header: true,
                        });
                    },
                ],
            },
        })
            .state('patients.plan-of-care-new.visit-task-lists', {
            url: '/visit_task_lists',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/visit-task-lists/visit-task-lists.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'VisitTaskList',
                    '$stateParams',
                    '$filter',
                    function (VisitTaskList, $stateParams) {
                        return VisitTaskList.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit', {
            data: { title: 'Plan of Care' },
            url: '/plan_of_care/:id/edit',
            abstract: true,
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/plan-of-care/edit-sidebar.html',
                    controller: 'PlanOfCareSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/plan-of-care/editor.html',
                    controller: 'PlanOfCareEditController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    function (RouteAuth) {
                        return RouteAuth.canOrAlert('update', 'plan_of_cares');
                    },
                ],
                currentPlanOfCare: [
                    'PlanOfCareService',
                    '$stateParams',
                    function (PlanOfCareService, $stateParams) {
                        return PlanOfCareService.get($stateParams.id, $stateParams.patientId).then(function (data) {
                            return data;
                        });
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.main', {
            url: '/main',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/main/main.html',
                    controller: 'PlanOfCareEditController as vm',
                },
            },
        })
            .state('patients.plan-of-care-edit.medications', {
            url: '/medications',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/medications/medications.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'Medication',
                    '$stateParams',
                    function (Medication, $stateParams) {
                        return Medication.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.assignments', {
            url: '/assignments',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/assignments/assignments.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'Assignment',
                    '$stateParams',
                    function (Assignment, $stateParams) {
                        return Assignment.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.unrelated-explanations', {
            url: '/unrelated_explanations',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/unrelated-explanations/unrelated-explanations.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'UnrelatedExplanation',
                    '$stateParams',
                    function (UnrelatedExplanation, $stateParams) {
                        return UnrelatedExplanation.query({
                            patient_id: $stateParams.patientId,
                            header: true,
                        });
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.indicators', {
            url: '/indicators',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/indicators/indicators.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'Indicator',
                    '$stateParams',
                    function (Indicator, $stateParams) {
                        return Indicator.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.care-plan-problems', {
            url: '/care_plan_problems',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/care-plan-problems/care-plan-problems.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'CarePlanProblemEntry',
                    '$stateParams',
                    function (CarePlanProblemEntry, $stateParams) {
                        return CarePlanProblemEntry.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.clinical-charts', {
            url: '/clinical_charts',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/clinical-charts/clinical-charts.html',
                    controller: 'ClinicalChartsSectionController as vm',
                },
            },
        })
            .state('patients.plan-of-care-edit.physician-orders', {
            url: '/physician_orders',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/physician-orders/physician-orders.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'PhysicianOrderEntry',
                    '$stateParams',
                    '$filter',
                    function (PhysicianOrderEntry, $stateParams) {
                        return PhysicianOrderEntry.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.dme-orders', {
            url: '/dme_orders',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/dme-orders/dme-orders.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'DmeOrder',
                    '$stateParams',
                    '$filter',
                    function (DmeOrder, $stateParams) {
                        return DmeOrder.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.volunteer-care-plans', {
            url: '/volunteer_care_plans',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/volunteer-care-plans/volunteer-care-plans.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'VolunteerCarePlan',
                    '$stateParams',
                    '$filter',
                    function (VolunteerCarePlan, $stateParams) {
                        return VolunteerCarePlan.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.medication-notes', {
            url: '/medication_notes',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/medication-notes/medication-notes.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'MedicationNote',
                    '$stateParams',
                    '$filter',
                    function (MedicationNote, $stateParams) {
                        return MedicationNote.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        })
            .state('patients.plan-of-care-edit.visit-task-lists', {
            url: '/visit_task_lists',
            views: {
                editor: {
                    templateUrl: '/y/templates/plan-of-care/sections/visit-task-lists/visit-task-lists.html',
                    controller: 'PlanOfCareSectionController as vm',
                },
            },
            resolve: {
                items: [
                    'VisitTaskList',
                    '$stateParams',
                    '$filter',
                    function (VisitTaskList, $stateParams) {
                        return VisitTaskList.loadByPatientId($stateParams.patientId);
                    },
                ],
            },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('PlanOfCarePrintController', [
        '$http',
        '$stateParams',
        '$state',
        'currentPlanOfCare',
        '$mdDialog',
        'CurrentPatient',
        'PlanOfCareService',
        Controller,
    ]);
    function Controller($http, $stateParams, $state, currentPlanOfCare, $mdDialog, CurrentPatient, PlanOfCareService) {
        var vm = this;
        vm.planOfCare = currentPlanOfCare;
        vm.patientId = $stateParams.patientId;
        vm.includeSignature = $stateParams.include_last_page_signature_line;
        vm.assignments = [];
        vm.carePlanProblems = [];
        vm.clinicalCharts = [];
        vm.indicators = [];
        vm.medications = [];
        vm.medicationNotes = [];
        vm.volunteerCarePlans = [];
        vm.dmeOrders = [];
        vm.physicianOrders = [];
        vm.visitTaskLists = [];
        vm.unrelatedExplanations = [];
        vm.formModelMeds = {};
        vm.CurrentPatient = CurrentPatient;
        vm.patientData = CurrentPatient.patient;
        vm.closeDialog = closeDialog;
        vm.edit = edit;
        vm.ssn = safeSSN();
        PlanOfCareService.activate();
        function safeSSN() {
            var ssn = vm.planOfCare.patient.ssn;
            return (ssn || '').slice(-4);
        }
        function closeDialog() {
            $mdDialog.cancel();
        }
        function edit() {
            $mdDialog.cancel();
            $state.go('patients.plan-of-care-edit.main', { id: vm.planOfCare.id });
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('PlanOfCareSidebarController', ['$state', 'currentPatient', Controller]);
    function Controller($state, currentPatient) {
        var vm = this;
        //members
        vm.patient = currentPatient;
        vm.state = $state;
        vm.navigateTo = navigateTo;
        function navigateTo(item) {
            $state.go(item);
        }
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var PlanOfCareSignatureController = /** @class */ (function () {
            function PlanOfCareSignatureController($filter, $state, PlanOfCareService, logger, item, $http, patientId, $mdDialog) {
                this.$filter = $filter;
                this.$state = $state;
                this.PlanOfCareService = PlanOfCareService;
                this.logger = logger;
                this.$http = $http;
                this.patientId = patientId;
                this.$mdDialog = $mdDialog;
                this.patientId = patientId;
                this.item = item;
                this.today = new Date();
                this.signature_date = this.today;
            }
            PlanOfCareSignatureController.prototype.addPhysicianSignature = function (planOfCare, date) {
                var _this = this;
                return this.$http
                    .post('/api/v1/plan_of_cares/physician_signature?id=' +
                    planOfCare.id +
                    '&patient_id=' +
                    this.patientId +
                    '&signed_date="' +
                    this.$filter('date')(date, 'yyyy/MM/dd') +
                    '"')
                    .then(function (data) {
                    planOfCare = new consolo.domain.PlanOfCare(data.data);
                    _this.$mdDialog.hide(planOfCare);
                    _this.logger.success('Signature Was Added');
                }, function (data) {
                    _this.$mdDialog.cancel(data.data.errors);
                    _this.logger.error(data.data.errors.join());
                });
            };
            PlanOfCareSignatureController.prototype.addMdSignature = function (planOfCare, date) {
                var _this = this;
                this.$http
                    .post('/api/v1/plan_of_cares/md_signature?id=' +
                    planOfCare.id +
                    '&patient_id=' +
                    this.patientId +
                    '&signed_date="' +
                    this.$filter('date')(date, 'yyyy/MM/dd') +
                    '"')
                    .then(function (data) {
                    planOfCare = new consolo.domain.PlanOfCare(data.data);
                    _this.$mdDialog.hide(planOfCare);
                    _this.logger.success('Signature Was Added');
                }, function (data) {
                    _this.$mdDialog.cancel();
                    _this.logger.error(data.data.errors.join());
                });
            };
            PlanOfCareSignatureController.prototype.closeDialog = function () {
                this.$mdDialog.cancel();
            };
            PlanOfCareSignatureController.$inject = [
                '$filter',
                '$state',
                'PlanOfCareService',
                'logger',
                'item',
                '$http',
                'patientId',
                '$mdDialog',
            ];
            return PlanOfCareSignatureController;
        }());
        angular
            .module('consolo')
            .controller('PlanOfCareSignatureController', PlanOfCareSignatureController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('PlanOfCareViewController', [
        '$http',
        '$stateParams',
        '$state',
        'currentPlanOfCare',
        '$mdDialog',
        'PlanOfCareService',
        'ChartingTemplateService',
        'ChartUtilService',
        Controller,
    ]);
    function Controller($http, $stateParams, $state, currentPlanOfCare, $mdDialog, PlanOfCareService, ChartingTemplateService, chartUtilService) {
        var vm = this;
        vm.$state = $state;
        PlanOfCareService.currentPlanOfCare = currentPlanOfCare;
        vm.currentPlanOfCare = currentPlanOfCare;
        vm.PlanOfCareService = PlanOfCareService;
        vm.closeDialog = closeDialog;
        vm.edit = edit;
        vm.sections = ChartingTemplateService.getAllBaseSections(false);
        vm.ssn = safeSSN();
        vm.chartUtilService = chartUtilService;
        PlanOfCareService.activate();
        function safeSSN() {
            var ssn = vm.currentPlanOfCare.patient.ssn;
            return (ssn || '').slice(-4);
        }
        function closeDialog() {
            $mdDialog.cancel();
        }
        function edit() {
            $mdDialog.cancel();
            $state.go('patients.plan-of-care-edit.main', { id: vm.currentPlanOfCare.id });
        }
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('priorAuthorization', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/prior-authorizations/prior-authorization.html',
            controller: 'PriorAuthorizationController as $ctrl',
        });
        var PriorAuthorizationController = /** @class */ (function () {
            function PriorAuthorizationController(CurrentPatient, PriorAuthorizationService, PayerGroupService, $state, logger, PhysicianChargesService) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.PriorAuthorizationService = PriorAuthorizationService;
                this.PayerGroupService = PayerGroupService;
                this.$state = $state;
                this.logger = logger;
                this.PhysicianChargesService = PhysicianChargesService;
                this.saving = false;
                this.careLevelChanges = [];
                this.physicianCharges = [];
                this.siaVisits = [];
                this.statuses = [{ id: 'Approved' }, { id: 'Pending' }, { id: 'Denied' }];
                this.siaVisitsLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        { title: 'Role', field: 'role_name', type: csg.datatable.ColumnTypes.short_text },
                        { title: 'User', field: 'user.name', type: csg.datatable.ColumnTypes.short_text },
                    ],
                };
                this.physicianChargesLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: { showExpandCollapseAll: false, hideTextFilter: true, hideFooter: true },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Physician/Provider',
                            field: 'physician_name',
                            type: 'short-text',
                            canSort: true,
                            formatValueFn: function (physicianCharge) {
                                return physicianCharge.physician.name;
                            },
                        },
                        {
                            title: 'Payer Name',
                            field: 'payer_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Code',
                            field: 'code',
                            type: 'short-text',
                            canSort: true,
                        },
                        {
                            title: 'Amount',
                            field: 'amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (physicianCharge) {
                                return common.moneyDisplay(physicianCharge.amount);
                            },
                            canSort: true,
                        },
                        {
                            title: 'Office',
                            field: 'office.name',
                            type: 'short-text',
                        },
                    ],
                };
                this.careLevelChangesLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: { showExpandCollapseAll: false, hideTextFilter: true, hideFooter: true },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Level of Care',
                            field: 'level_of_care',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                };
                if ($state.params.priorAuthorizationId) {
                    this.PriorAuthorizationService.get($state.params.priorAuthorizationId).then(function (response) {
                        _this.priorAuthorization = response;
                        _this.getPayers();
                        _this.getAppropriateObjects();
                    });
                }
                else {
                    this.priorAuthorization = {
                        start_date: new Date(new Date().toDateString()),
                        patient_id: this.CurrentPatient.patient_id,
                        is_renewal_required: true,
                    };
                    this.getPayers();
                }
            }
            PriorAuthorizationController.prototype.getPayers = function () {
                var _this = this;
                this.payers = [];
                this.PayerGroupService.byDate(this.CurrentPatient.patient_id, this.priorAuthorization.start_date).then(function (payerGroup) {
                    //At this point we have the current payer group, we need to get the payer that pays physician charges
                    payerGroup.insurance_policies.forEach(function (insurancePolicy) {
                        _this.payers.push(insurancePolicy.getPayer());
                    });
                    if (!_this.priorAuthorization.id && _this.payers.length === 1) {
                        _this.priorAuthorization.payer_id = _this.payers[0].id;
                    }
                });
            };
            PriorAuthorizationController.prototype.getAppropriateObjects = function () {
                this.getPayers();
                if (!this.priorAuthorization.authorization_type ||
                    this.priorAuthorization.authorization_type === 'all') {
                    this.getPhysicianCharges();
                    this.getSIAVisits();
                    this.getCareLevelChanges();
                }
                else if (this.priorAuthorization.authorization_type === 'sia_visits') {
                    this.getSIAVisits();
                }
                else if (this.priorAuthorization.authorization_type === 'physician_charges') {
                    this.getPhysicianCharges();
                }
                else {
                    this.getCareLevelChanges();
                }
            };
            PriorAuthorizationController.prototype.getCareLevelChanges = function () {
                var _this = this;
                var authorization_type = null;
                if (['ROUTINE', 'INPATIENT', 'CONT', 'RESPITE'].indexOf(this.priorAuthorization.authorization_type) > -1) {
                    authorization_type = this.priorAuthorization.authorization_type;
                }
                this.PriorAuthorizationService.getCareLevelChanges(this.priorAuthorization.start_date, this.priorAuthorization.end_date, authorization_type, this.CurrentPatient.patient_id).then(function (careLevelChanges) {
                    _this.careLevelChanges = careLevelChanges;
                });
            };
            PriorAuthorizationController.prototype.getPhysicianCharges = function () {
                var _this = this;
                this.PhysicianChargesService.getPhysicianCharges(this.priorAuthorization.start_date, this.priorAuthorization.end_date, this.CurrentPatient.patient_id).then(function (physicianCharges) {
                    _this.physicianCharges = physicianCharges;
                });
            };
            PriorAuthorizationController.prototype.getSIAVisits = function () {
                var _this = this;
                this.PriorAuthorizationService.getSIAVisits(this.priorAuthorization.start_date, this.priorAuthorization.end_date, this.CurrentPatient.patient_id).then(function (siaVisits) {
                    _this.siaVisits = siaVisits;
                });
            };
            PriorAuthorizationController.prototype.save = function () {
                var _this = this;
                var errors = [];
                if (!this.priorAuthorization.start_date) {
                    errors.push('Start Date must be present.');
                }
                if (!this.priorAuthorization.end_date) {
                    errors.push('End Date must be present.');
                }
                if (!this.priorAuthorization.number) {
                    errors.push('Authorization Number must be entered.');
                }
                if (!this.priorAuthorization.payer_id) {
                    errors.push('Payer must be selected.');
                }
                if (errors.length === 0) {
                    this.saving = true;
                    var message_1 = this.priorAuthorization.id ? 'updated.' : 'created.';
                    this.PriorAuthorizationService.upsert(this.priorAuthorization)
                        .then(function (response) {
                        _this.logger.success('The Prior Authorization has been ' + message_1);
                        _this.$state.go('patients.prior-authorizations');
                    })
                        .catch(function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.errors = errors;
                    this.logger.error(errors.join(', '));
                }
            };
            PriorAuthorizationController.$inject = [
                'CurrentPatient',
                'PriorAuthorizationService',
                'PayerGroupService',
                '$state',
                'logger',
                'PhysicianChargesService',
            ];
            return PriorAuthorizationController;
        }());
        common.PriorAuthorizationController = PriorAuthorizationController;
        angular
            .module('consolo')
            .controller('PriorAuthorizationController', PriorAuthorizationController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PriorAuthorizationService = /** @class */ (function () {
            function PriorAuthorizationService(PermissionsService, $http) {
                this.PermissionsService = PermissionsService;
                this.$http = $http;
                this.updatePermission = false;
                this.deletePermission = false;
                this.viewPermission = false;
                this.createPermission = false;
                this.apiUrl = '/api/v1/prior_authorizations';
                this.updatePermission = this.PermissionsService.can('update', 'prior_authorizations');
                this.createPermission = this.PermissionsService.can('create', 'prior_authorizations');
                this.deletePermission = this.PermissionsService.can('destroy', 'prior_authorizations');
                this.viewPermission = this.PermissionsService.can('show', 'prior_authorizations');
                this.priorAuthorizationTypes = [
                    { id: 'all', description: 'All' },
                    { id: 'ROUTINE', description: 'Routine' },
                    { id: 'CONT', description: 'Continuous' },
                    { id: 'INPATIENT', description: 'Inpatient' },
                    { id: 'RESPITE', description: 'Respite' },
                    { id: 'room_and_board', description: 'Room and Board' },
                    { id: 'sia_visits', description: 'SIA Visit' },
                    { id: 'physician_charges', description: 'Physician Charge' },
                ];
            }
            PriorAuthorizationService.prototype.delete = function (id) {
                return this.$http.delete(this.apiUrl + '/' + id);
            };
            PriorAuthorizationService.prototype.deleteAll = function () {
                return this.$http.post(this.apiUrl + '/delete_all', {});
            };
            PriorAuthorizationService.prototype.deleteMultiple = function (ids) {
                return this.$http.post(this.apiUrl + '/delete', { ids: ids });
            };
            PriorAuthorizationService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            PriorAuthorizationService.prototype.getCareLevelChanges = function (startDate, endDate, levelTo, patientId) {
                return this.$http
                    .get('/api/v1/care_level_changes', {
                    params: {
                        before_on: startDate,
                        end_date: endDate,
                        level_to: levelTo,
                        patient_id: patientId,
                    },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            PriorAuthorizationService.prototype.getSIAVisits = function (startDate, endDate, patientId) {
                return this.$http
                    .get('/api/v1/clinical_charts', {
                    params: {
                        start_date: startDate,
                        end_date: endDate,
                        sia_visit: true,
                        patient_id: patientId,
                    },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            PriorAuthorizationService.prototype.import = function (fileKey) {
                return this.$http.post(this.apiUrl + '/import', {
                    s3_tmp_key: fileKey,
                });
            };
            PriorAuthorizationService.prototype.upsert = function (priorAuthorization) {
                if (priorAuthorization.id) {
                    return this.$http.patch(this.apiUrl + '/' + priorAuthorization.id, priorAuthorization);
                }
                else {
                    return this.$http.post(this.apiUrl, priorAuthorization);
                }
            };
            PriorAuthorizationService.$inject = ['PermissionsService', '$http'];
            return PriorAuthorizationService;
        }());
        services.PriorAuthorizationService = PriorAuthorizationService;
        angular.module('consolo').service('PriorAuthorizationService', PriorAuthorizationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('patients.prior-authorizations', {
                url: '/prior_authorizations',
                data: { title: 'Prior Authorizations' },
                views: {
                    'main-content@': {
                        template: '<prior-authorizations flex=""></prior-authorizations>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.PriorAuthorizationsDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
            })
                .state('patients.prior-authorizations.new', {
                url: '/new',
                data: { title: 'New Prior Authorization' },
                views: {
                    'main-content@': {
                        template: '<prior-authorization flex="" readonly="false"></prior-authorization>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.prior-authorizations.edit', {
                url: '/:priorAuthorizationId/edit',
                data: { title: 'Edit Prior Authorization' },
                views: {
                    'main-content@': {
                        template: '<prior-authorization flex="" readonly="false"></prior-authorization>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.prior-authorizations.view', {
                url: '/:priorAuthorizationId',
                data: { title: 'Edit Prior Authorization' },
                views: {
                    'main-content@': {
                        template: '<prior-authorization flex="" readonly="true"></prior-authorization>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PriorAuthorizationsDataTableService = /** @class */ (function (_super) {
            __extends(PriorAuthorizationsDataTableService, _super);
            function PriorAuthorizationsDataTableService($http, $interval, $state, logger, $mdDialog, PriorAuthorizationService, CurrentPatient) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.logger = logger;
                _this.$mdDialog = $mdDialog;
                _this.PriorAuthorizationService = PriorAuthorizationService;
                _this.CurrentPatient = CurrentPatient;
                _this.loadingData = false;
                _this.showFilters = false;
                _this.hideSearchTerm = true;
                _this.searchOnReset = true;
                _this.activate();
                return _this;
            }
            PriorAuthorizationsDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/prior_authorizations';
                this.filters = new consolo.domain.PriorAuthorizationsFilter();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: true,
                    },
                    columns: [
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'End Date',
                            field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Authorization Type',
                            field: 'authorization_type',
                            type: 'short-text',
                            formatValueFn: function (priorAutorization) {
                                if (priorAutorization.authorization_type === 'physician_charges') {
                                    return 'Physician Charges';
                                }
                                else if (priorAutorization.authorization_type === 'sia_visits') {
                                    return 'SIA Visits';
                                }
                                else {
                                    return priorAutorization.authorization_type.toUpperCase();
                                }
                            },
                        },
                        {
                            title: 'Number',
                            field: 'number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Payer',
                            field: 'payer.payer_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Quantity',
                            field: 'quantity',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Comments',
                            field: 'comments',
                            type: 'medium-text',
                        },
                        {
                            title: 'Renewal Required',
                            field: 'is_renewal_required',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (priorAuthorization) {
                                    _this.$state.go('patients.prior-authorizations.edit', {
                                        priorAuthorizationId: priorAuthorization.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.PriorAuthorizationService.updatePermission;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (priorAuthorization) {
                                    _this.$state.go('patients.prior-authorizations.view', {
                                        priorAuthorizationId: priorAuthorization.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.PriorAuthorizationService.viewPermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (priorAuthorization) {
                                    var confirm = _this.$mdDialog
                                        .confirm()
                                        .title('Confirmation Required')
                                        .htmlContent('Are you sure want to remove this authorization?')
                                        .ariaLabel('Removal Confirmation')
                                        .ok('Ok')
                                        .cancel('Cancel');
                                    return _this.$mdDialog.show(confirm).then(function () {
                                        return _this.PriorAuthorizationService.delete(priorAuthorization.id).then(function () {
                                            _this.logger.success('The Prior Authorization has been removed.');
                                            _this.search();
                                        });
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.PriorAuthorizationService.deletePermission;
                                },
                            },
                        ],
                    },
                };
            };
            PriorAuthorizationsDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.PriorAuthorizationsFilter();
                this.search();
            };
            PriorAuthorizationsDataTableService.prototype.search = function () {
                var _this = this;
                this.params = this.filters.getFilters();
                if (this.CurrentPatient.isPatientPresent() &&
                    (!this.params.patient_id || this.params.patient_id !== this.CurrentPatient.patient_id)) {
                    this.params.patient_id = this.CurrentPatient.patient_id;
                }
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl && _this.tableControl.loadSelectData && !_this.loadingData) {
                        _this.loadingData = true;
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            PriorAuthorizationsDataTableService.$inject = [
                '$http',
                '$interval',
                '$state',
                'logger',
                '$mdDialog',
                'PriorAuthorizationService',
                'CurrentPatient',
            ];
            return PriorAuthorizationsDataTableService;
        }(csg.datatable.ApiService));
        services.PriorAuthorizationsDataTableService = PriorAuthorizationsDataTableService;
        angular
            .module('consolo')
            .service('PriorAuthorizationsDataTableService', PriorAuthorizationsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('priorAuthorizations', {
            bindings: {},
            templateUrl: '/y/templates/prior-authorizations/prior-authorizations.html',
            controller: 'PriorAuthorizationsController as $ctrl',
        });
        var PriorAuthorizationsController = /** @class */ (function () {
            function PriorAuthorizationsController(logger, $state, Session, CurrentPatient, PriorAuthorizationsDataTableService) {
                this.logger = logger;
                this.$state = $state;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.PriorAuthorizationsDataTableService = PriorAuthorizationsDataTableService;
                var user = this.Session.getUser();
                this.PriorAuthorizationsDataTableService.search();
            }
            PriorAuthorizationsController.$inject = [
                'logger',
                '$state',
                'SessionService',
                'CurrentPatient',
                'PriorAuthorizationsDataTableService',
            ];
            return PriorAuthorizationsController;
        }());
        common.PriorAuthorizationsController = PriorAuthorizationsController;
        angular
            .module('consolo')
            .controller('PriorAuthorizationsController', PriorAuthorizationsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('indicators', {
            bindings: {
                isLoading: '<',
                indicatorId: '<',
                indicator: '<',
                patientId: '<',
            },
            templateUrl: '/y/templates/problem-lists/indicators.html',
            controller: 'IndicatorsIndexController as $ctrl',
        });
        var IndicatorsIndexController = /** @class */ (function () {
            function IndicatorsIndexController($state, $location, $mdDialog, patientService, Session, logger, IndicatorsService, IndicatorPrintService, PastIndicatorDataTableService, $interval, SectionManagerService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.patientService = patientService;
                this.Session = Session;
                this.logger = logger;
                this.IndicatorsService = IndicatorsService;
                this.IndicatorPrintService = IndicatorPrintService;
                this.PastIndicatorDataTableService = PastIndicatorDataTableService;
                this.$interval = $interval;
                this.SectionManagerService = SectionManagerService;
                this.serviceLine = this.Session.get().agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                // this.$onInit();
            }
            IndicatorsIndexController.prototype.$onInit = function () {
                var _this = this;
                this.patientId = this.patientId || this.$state.params.patientId;
                this.individualIndicator = this.indicator || this.indicatorId;
                this.isLoading = true;
                this.currentIndicatorisLoading = true;
                this.PastIndicatorDataTableService.search();
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.patientIndicatorsLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    // this.exportIndicatorsToCSV(this.patientId);
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View Indicator',
                                icon: 'remove_red_eye',
                                callbackFn: function (data, item) {
                                    _this.$state.go('patients.indicators.view', {
                                        patientId: _this.patientId,
                                        indicatorId: data.id,
                                    });
                                },
                            },
                            {
                                label: 'Edit Indicator',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.$state.go('patients.indicators.edit', {
                                        patientId: _this.patientId,
                                        indicatorId: data.id,
                                    });
                                },
                                conditionalHideFn: function (data, item) {
                                    return data.signatures.length > 0;
                                },
                            },
                            {
                                label: 'Add Your Signature',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.signatureDialog('personal', data, _this.currentUser().id, _this.patientId);
                                },
                            },
                            {
                                label: 'Add Physician Signature',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.signatureDialog('physician', data, _this.defaultPhysician.id, _this.patientId);
                                },
                                conditionalHideFn: function (data, item) {
                                    return _this.currentUser().physician || !_this.defaultPhysician;
                                },
                            },
                            {
                                label: 'Print Indicator',
                                icon: 'print',
                                callbackFn: function (data, item) {
                                    _this.printIndicator(data);
                                },
                            },
                            {
                                label: 'Delete Indicator',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.deleteIndicatorDialog(data);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Created By', field: 'user_name' },
                        { title: 'Effective Date', field: 'effective_date', type: 'date' },
                        { title: 'ICD10', field: 'icds' },
                        {
                            title: 'Is Locked?',
                            field: 'signatures',
                            type: csg.datatable.ColumnTypes.boolean,
                            formatValueFn: function (indicator) {
                                return indicator.signatures.length > 0 ? 'Yes' : 'No';
                            },
                        },
                    ],
                };
                if (!this.isPalliative) {
                    this.patientIndicatorsLayout.columns.unshift({
                        title: 'LCD Worksheets',
                        field: 'diagnoses_names',
                    });
                }
                this.getPatient();
                this.getDefaultPhysician(this.patientId);
                if (this.individualIndicator) {
                    this.getIndividualIndicator(this.indicatorId, this.patientId);
                }
                else {
                    this.getAllIndicators();
                }
            };
            IndicatorsIndexController.prototype.getPatient = function () {
                var _this = this;
                this.patientService.getExtra(this.patientId).then(function (patientObj) {
                    _this.patient = patientObj.data;
                });
            };
            IndicatorsIndexController.prototype.getDefaultPhysician = function (patientId) {
                var _this = this;
                this.patientService
                    .getDefaultPhysician(patientId)
                    .then(function (response) {
                    _this.defaultPhysician = response.data;
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            IndicatorsIndexController.prototype.viewIndicator = function (indicator) {
                this.$state.go('patients.indicators.view', {
                    patientId: this.patientId,
                    indicatorId: indicator.id,
                });
            };
            IndicatorsIndexController.prototype.editIndicator = function (indicator) {
                this.$state.go('patients.indicators.edit', {
                    patientId: this.patientId,
                    indicatorId: indicator.id,
                });
            };
            IndicatorsIndexController.prototype.newIndicator = function () {
                this.$state.go('patients.indicators.new', {
                    patientId: this.patientId,
                });
            };
            IndicatorsIndexController.prototype.getIndividualIndicator = function (id, patientId) {
                var _this = this;
                this.currentIndicatorisLoading = true;
                return this.IndicatorsService.getSingleIndicator(id, patientId).then(function (indicator) {
                    _this.currentIndicatorisLoading = false;
                    return _this.updateIndicator(indicator.data);
                });
            };
            IndicatorsIndexController.prototype.setCurrentIndicator = function () {
                var _this = this;
                return this.IndicatorsService.getCurrentIndicator(this.patientId).then(function (indicator) {
                    return _this.updateIndicator(indicator);
                });
            };
            IndicatorsIndexController.prototype.updateIndicator = function (indicator) {
                var currentIndicator = indicator;
                if (Object.keys(currentIndicator).length) {
                    var arr = currentIndicator.diagnoses.map(function (d) {
                        return d.name;
                    });
                    var icd10s = currentIndicator.icd10s.map(function (icd) {
                        return icd.code;
                    });
                    currentIndicator.diagnoses_names = arr.join(', ');
                    currentIndicator.icds = icd10s.join(', ');
                    this.currentPatientIndicator = currentIndicator;
                }
                else {
                    this.currentPatientIndicator = null;
                }
            };
            IndicatorsIndexController.prototype.deletePatientIndicator = function (indicatorId, patientId) {
                var _this = this;
                this.IndicatorsService.deleteIndicator(indicatorId, patientId)
                    .then(function (response) {
                    _this.SectionManagerService.refreshMenu();
                    _this.getAllIndicators();
                })
                    .catch(function (error) {
                    _this.logger.error(error, 'Error:');
                });
            };
            IndicatorsIndexController.prototype.currentUser = function () {
                return this.Session.getUser();
            };
            IndicatorsIndexController.prototype.printIndicator = function (indicator) {
                var _this = this;
                this.showPdfOptions().then(function (pdfSettings) {
                    if (pdfSettings !== 'cancel') {
                        _this.IndicatorPrintService.openIndicatorPdf(_this.patient, indicator, _this.currentUser().name, pdfSettings);
                    }
                });
            };
            IndicatorsIndexController.prototype.diagnosisDialog = function ($event, indicator) {
                this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicators-dialog.html',
                    controller: 'IndicatorsDialogController as vm',
                    locals: {
                        indicator: indicator,
                    },
                });
            };
            IndicatorsIndexController.prototype.signatureDialog = function (signatureType, indicator, userId, patientId) {
                var _this = this;
                this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/indicator-signature-dialog.html',
                    controller: 'IndicatorSignatureDialogController as vm',
                    locals: {
                        indicator: indicator,
                        userId: userId,
                        patientId: patientId,
                        signatureType: signatureType,
                    },
                })
                    .then(function (response) {
                    _this.isLoading = true;
                    var signatureObj = {
                        user_id: _this.Session.getUser().id,
                        effective_date: response.effective_date,
                        physician_id: response.signature_type === 'physician' ? _this.defaultPhysician.id : null,
                    };
                    var signatureCall = response.signature_type === 'personal'
                        ? _this.IndicatorsService.addCurrentUserSignature(indicator.id, patientId, signatureObj)
                        : _this.IndicatorsService.addPhysicianSignature(indicator.id, patientId, signatureObj);
                    signatureCall
                        .then(function (response) {
                        _this.logger.success('You have successfully applied a signature for the Indicator dated effective ' +
                            response.data.effective_date);
                        _this.getAllIndicators();
                    })
                        .catch(function (error) {
                        _this.logger.error(error, 'Error:');
                    });
                });
            };
            IndicatorsIndexController.prototype.deleteIndicatorDialog = function (data) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Delete Indicator')
                    .textContent('Are you sure you wish to delete this indicator?')
                    .ok('Yes')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirmation).then(function (response) {
                    _this.isLoading = true;
                    _this.deletePatientIndicator(data.id, data.patient_id);
                });
            };
            IndicatorsIndexController.prototype.openMenu = function ($mdMenu, $event) {
                $mdMenu.open($event);
                $event.stopPropagation();
            };
            IndicatorsIndexController.prototype.getAllIndicators = function () {
                var _this = this;
                this.currentIndicatorisLoading = true;
                this.setCurrentIndicator().then(function (response) {
                    _this.currentIndicatorisLoading = false;
                });
            };
            IndicatorsIndexController.prototype.showPdfOptions = function () {
                var url = "'/y/templates/common/services/pdf-generator/pdf-options.html'";
                var dialogOptions = {
                    template: '<md-dialog flex="50" aria-label="PDF Options">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'IndicatorPdfOptionsController as vm',
                    hasBackdrop: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                };
                return this.$mdDialog.show(dialogOptions);
            };
            IndicatorsIndexController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                'patientService',
                'SessionService',
                'logger',
                'IndicatorsService',
                'IndicatorPrintService',
                'PastIndicatorDataTableService',
                '$interval',
                'ConsoloPatientSectionManagerService',
            ];
            return IndicatorsIndexController;
        }());
        common.IndicatorsIndexController = IndicatorsIndexController;
        angular.module('consolo').controller('IndicatorsIndexController', IndicatorsIndexController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PastIndicatorDataTableService = /** @class */ (function (_super) {
            __extends(PastIndicatorDataTableService, _super);
            function PastIndicatorDataTableService($httpService, $interval, CurrentPatient) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.$interval = $interval;
                _this.CurrentPatient = CurrentPatient;
                _this.loadingData = false;
                _this.apiSelectUrl = '/api/v1/indicators';
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        var indicators = data.data;
                        indicators.forEach(function (indicator) {
                            // Setup lcd worksheets and icd10s for viewing inside of the datatable
                            var arr = indicator.diagnoses.map(function (d) {
                                return d.name;
                            });
                            var icd10s = indicator.icd10s.map(function (icd) {
                                return icd.code;
                            });
                            indicator.diagnoses_names = arr.join(', ');
                            indicator.icds = icd10s.join(', ');
                        });
                        return indicators;
                    });
                };
                return _this;
            }
            PastIndicatorDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData &&
                    (!this.params || this.params.patient_id !== this.CurrentPatient.patient_id)) {
                    this.params = { patient_id: this.CurrentPatient.patient_id };
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                            _this.loadingData = true;
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            PastIndicatorDataTableService.prototype.reset = function () {
                this.search();
            };
            PastIndicatorDataTableService.$inject = ['$http', '$interval', 'CurrentPatient'];
            return PastIndicatorDataTableService;
        }(csg.datatable.ApiService));
        services.PastIndicatorDataTableService = PastIndicatorDataTableService;
        angular.module('consolo').service('PastIndicatorDataTableService', PastIndicatorDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('problemListAndDiagnoses', {
            bindings: {},
            templateUrl: '/y/templates/problem-lists/problem-list-and-diagnoses.html',
            controller: 'ProblemListAndDiagnosesController as $ctrl',
        });
        var ProblemListAndDiagnosesController = /** @class */ (function () {
            function ProblemListAndDiagnosesController($mdDialog, ProblemtListService, CurrentPatient, Session) {
                this.$mdDialog = $mdDialog;
                this.ProblemtListService = ProblemtListService;
                this.CurrentPatient = CurrentPatient;
                this.Session = Session;
                if (!this.problemListItem) {
                    this.problemListItem = this.ProblemtListService.currentProblemListItem;
                }
                this.user_dashboard_url = this.Session.getUser().dashboard_url;
            }
            ProblemListAndDiagnosesController.prototype.submit = function () {
                return this.$mdDialog.hide(this.problemListItem);
            };
            ProblemListAndDiagnosesController.$inject = [
                '$mdDialog',
                'ProblemListItemService',
                'CurrentPatient',
                'SessionService',
            ];
            return ProblemListAndDiagnosesController;
        }());
        common.ProblemListAndDiagnosesController = ProblemListAndDiagnosesController;
        angular
            .module('consolo')
            .controller('ProblemListAndDiagnosesController', ProblemListAndDiagnosesController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('problemListItem', {
            bindings: { problemListItem: '<' },
            templateUrl: '/y/templates/problem-lists/problem-list-item.html',
            controller: 'ProblemListItemController as $ctrl',
        });
        var ProblemListItemController = /** @class */ (function () {
            function ProblemListItemController($mdDialog, ProblemtListService, SessionService) {
                this.$mdDialog = $mdDialog;
                this.ProblemtListService = ProblemtListService;
                this.SessionService = SessionService;
                if (!this.problemListItem) {
                    this.problemListItem = this.ProblemtListService.currentProblemListItem;
                }
                if (!this.problemListItem.user_id) {
                    this.problemListItem.user = SessionService.getUser();
                    this.problemListItem.user_id = this.problemListItem.user.id;
                }
            }
            ProblemListItemController.prototype.submit = function () {
                return this.$mdDialog.hide(this.problemListItem);
            };
            ProblemListItemController.$inject = ['$mdDialog', 'ProblemListItemService', 'SessionService'];
            return ProblemListItemController;
        }());
        common.ProblemListItemController = ProblemListItemController;
        angular.module('consolo').controller('ProblemListItemController', ProblemListItemController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ProblemListItemService = /** @class */ (function () {
            function ProblemListItemService($http, PermissionsService, $mdDialog, logger) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.problemListItemUrl = '/api/v1/problem_list_items';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.pastProblemListItems = [];
                this.currentProblemListItems = [];
                this.updatePermission = this.PermissionsService.can('update', 'problem_list_items');
                this.createPermission = this.PermissionsService.can('create', 'problem_list_items');
                this.deletePermission = this.PermissionsService.can('destroy', 'problem_list_items');
                this.viewPermission = this.PermissionsService.can('show', 'problem_list_items');
            }
            ProblemListItemService.prototype.delete = function (id) {
                var _this = this;
                return this.$http.delete(this.problemListItemUrl + '/' + id).then(function (response) {
                    _this.logger.success('The Problem List has been delete successfully.');
                    return response.data;
                });
            };
            ProblemListItemService.prototype.editProblemListItem = function (problemListItem) {
                this.currentProblemListItem = problemListItem;
                return this.$mdDialog.show({
                    template: '<md-dialog flex="50" aria-label="Diagnosis darch">' +
                        '  <problem-list-item problem-list-item="problemListItem" flex=""></problem-list-item>' +
                        '</md-dialog>',
                });
            };
            ProblemListItemService.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                // toIndex determines where the icd10 object is moving based on whether up or down arrows are clicked
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
                this.setPosition(array);
                return [].concat(array);
            };
            ProblemListItemService.prototype.setPosition = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i;
                }
            };
            ProblemListItemService.prototype.getItems = function (patientId, pastOnly, presentOnly) {
                return this.$http
                    .get('/api/v1/problem_list_items', {
                    params: { patient_id: patientId, past_only: pastOnly, present_only: presentOnly },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            ProblemListItemService.prototype.getSnomedProblems = function (query) {
                return this.$http.get('/api/v1/snomed_codes', { params: { q: query } }).then(function (response) {
                    return response.data['items'];
                });
            };
            ProblemListItemService.prototype.searchProblems = function (searchText) {
                var results = [];
                searchText = searchText.toLowerCase();
                if (this.pastProblemListItems.length) {
                    var pastProblems = this.pastProblemListItems.filter(function (problem) {
                        return problem.snomed_problem.description.toLowerCase().indexOf(searchText) !== -1;
                    });
                    if (pastProblems.length) {
                        results.push({ header: 'Past Problems' });
                        results = results.concat(pastProblems.map(function (pastProblem) {
                            return pastProblem.snomed_problem;
                        }));
                    }
                }
                return this.getSnomedProblems(searchText).then(function (problems) {
                    if (results.length) {
                        results.push({ header: 'Snomed Problems' });
                    }
                    results = results.concat(problems);
                    return results;
                });
            };
            ProblemListItemService.prototype.setCurrentPastProblemsForPatient = function (patientId) {
                var _this = this;
                return this.getItems(patientId).then(function (problemListItems) {
                    _this.pastProblemListItems = [];
                    _this.currentProblemListItems = [];
                    problemListItems.forEach(function (problemListItem) {
                        if (problemListItem.snomed_problem) {
                            problemListItem.description = problemListItem.snomed_problem.description;
                        }
                        else {
                            problemListItem.description = 'Unknown';
                        }
                        if (!problemListItem.end_date || new Date(problemListItem.end_date) > new Date()) {
                            _this.currentProblemListItems.push(problemListItem);
                        }
                        else {
                            _this.pastProblemListItems.push(problemListItem);
                        }
                    });
                });
            };
            ProblemListItemService.prototype.updateProblemListItem = function (problemListItem) {
                return this.$http.patch(this.problemListItemUrl, problemListItem).then(function (response) {
                    return response.data;
                });
            };
            ProblemListItemService.prototype.upsert = function (problemListItem) {
                var _this = this;
                if (problemListItem.id) {
                    return this.$http
                        .patch(this.problemListItemUrl + '/' + problemListItem.id, problemListItem)
                        .then(function (response) {
                        _this.logger.success('The Problem List Item has been updated successfully.');
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.problemListItemUrl, problemListItem).then(function (response) {
                        _this.logger.success('The Problem List has been created successfully.');
                        return response.data;
                    });
                }
            };
            ProblemListItemService.prototype.editAndUpsertProblemListItem = function (problem) {
                var _this = this;
                return this.editProblemListItem(problem).then(function (updatedProblem) {
                    return _this.upsert(updatedProblem).then(function () { return _this.setCurrentPastProblemsForPatient(problem.patient_id); });
                });
            };
            ProblemListItemService.$inject = ['$http', 'PermissionsService', '$mdDialog', 'logger'];
            return ProblemListItemService;
        }());
        services.ProblemListItemService = ProblemListItemService;
        angular.module('consolo').service('ProblemListItemService', ProblemListItemService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ProblemListPrintService = /** @class */ (function () {
            function ProblemListPrintService(jsPDF) {
                this.jsPDF = jsPDF;
            }
            ProblemListPrintService.prototype.openProblemListPdf = function (patient, problemListItems, currentUser, pdfSettings) {
                var problemListItemsDocument = this.buildProblemListTemplate(patient, problemListItems, this.docDefinition(currentUser, patient, problemListItems), currentUser, pdfSettings);
                this.jsPDF.showPdf(problemListItemsDocument);
            };
            ProblemListPrintService.prototype.downloadProblemListPdf = function (patient, problemListItems, currentUser, pdfSettings) {
                var problemListItemsDocument = this.buildProblemListTemplate(patient, problemListItems, this.docDefinition(currentUser, patient, problemListItems), currentUser, pdfSettings);
                this.jsPDF.downloadPdf(problemListItemsDocument, this.generateDefaultFileName(patient, problemListItems));
            };
            ProblemListPrintService.prototype.buildProblemListTemplate = function (patient, problemListItems, docDefinition, currentUser, pdfSettings) {
                this.setPatientHeader(docDefinition, problemListItems, patient);
                this.setProblemListItems(docDefinition, problemListItems);
                this.jsPDF.addFooter(docDefinition, pdfSettings);
                return docDefinition;
            };
            ProblemListPrintService.prototype.setPatientHeader = function (docDefinition, problemListItems, patient) {
                docDefinition.header.push({
                    layout: 'noBorders',
                    margin: [30, 30, 30, 30],
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                            [
                                [
                                    {
                                        image: 'consoloNameLogo',
                                        width: 100,
                                        alignment: 'left',
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: [
                                            { text: 'Patient Name: \n', style: 'headerLabel' },
                                            { text: patient.name + '\n', style: 'headerField' },
                                            { text: 'DOB: ', style: 'headerLabel' },
                                            {
                                                text: this.jsPDF.dateFilter(patient.dob_display) + '\n',
                                                style: 'headerField',
                                            },
                                        ],
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: [
                                            { text: 'ID: ', style: 'headerLabel' },
                                            {
                                                text: patient.id + '\n',
                                                style: 'headerField',
                                            },
                                            { text: 'MRN: ', style: 'headerLabel' },
                                            {
                                                text: (patient.mrn ? patient.mrn : 'N/A') + '\n',
                                                style: 'headerField',
                                            },
                                            {
                                                text: 'Status: ',
                                                style: 'headerLabel',
                                            },
                                            { text: patient.care_status + '\n', style: 'headerField' },
                                        ],
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: [
                                            { text: 'Document Type: \n', style: 'headerLabel' },
                                            { text: 'Problem List \n', style: 'headerField' },
                                            { text: 'Office: ', style: 'headerLabel' },
                                            { text: patient.office.name + '\n', style: 'headerField' },
                                        ],
                                    },
                                ],
                            ],
                        ],
                    },
                });
                docDefinition.header.push({
                    margin: [30, -15, 0, 0],
                    text: 'Problem List for ' + patient.name,
                    style: 'pageHeading',
                    alignment: 'left',
                });
            };
            ProblemListPrintService.prototype.setPageHeading = function (docDefinition, problemListItems, patient) {
                docDefinition.content.push({
                    text: 'Problem List for ' + patient.name,
                    style: 'pageHeading',
                    alignment: 'left',
                });
            };
            ProblemListPrintService.prototype.setProblemListItems = function (docDefinition, problemListItems) {
                docDefinition.content.push({
                    text: 'Problem List',
                    style: 'subsectionHeader',
                    margin: [0, 15, 0, 5],
                });
                if (problemListItems.length > 0) {
                    var problemListItemTable_1 = {
                        table: {
                            headerRows: 1,
                            widths: ['*', '*', 'auto', '*'],
                            body: [['Code', 'Description', 'Start Date', 'End Date']],
                        },
                    };
                    problemListItems.forEach(function (problemListItem, index) {
                        problemListItemTable_1.table.body.push([
                            problemListItem.snomed_problem.id,
                            problemListItem.snomed_problem.description,
                            problemListItem.start_date,
                            problemListItem.end_date,
                        ]);
                    });
                    docDefinition.content.push(problemListItemTable_1);
                }
                else {
                    docDefinition.content.push({
                        text: 'This patient has no current Problems.',
                        style: 'formInput',
                    });
                }
            };
            ProblemListPrintService.prototype.docDefinition = function (author, patient, problemListItems) {
                return {
                    header: [],
                    content: [],
                    footer: [],
                    pageSize: 'LETTER',
                    pageMargins: [30, 150, 30, 30],
                    info: {
                        title: this.generateDefaultFileName(patient, problemListItems),
                        author: author,
                        producer: 'Consolo Services Group',
                    },
                    defaultStyle: {
                        fontSize: 12,
                        font: 'Roboto',
                    },
                    images: {
                        consoloLogo: this.jsPDF.consoloLogoUri,
                        consoloNameLogo: this.jsPDF.consoloLogoNameUri,
                    },
                    styles: {
                        pageHeading: {
                            fontSize: 20,
                            bold: true,
                        },
                        subsectionHeader: {
                            fontSize: 16,
                            bold: true,
                            margin: [0, 16, 0, 0],
                        },
                        headerLabel: {
                            fontSize: 11,
                            bold: true,
                        },
                        headerField: {
                            fontSize: 13,
                        },
                        formInputHeader: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 8, 0, 0],
                        },
                        formInput: {
                            fontSize: 12,
                        },
                        formInputBold: {
                            fontSize: 12,
                            bold: true,
                        },
                    },
                };
            };
            ProblemListPrintService.prototype.generateDefaultFileName = function (patient, problemListItems) {
                var fileName = patient.name
                    .trim()
                    .replace(/,/, '')
                    .replace(/ /, '_');
                fileName += 'problemListItems_' + patient.first_name + '_' + patient.last_name;
                return fileName;
            };
            ProblemListPrintService.prototype.patientAddress = function (patient) {
                var address = '';
                if (patient.current_address) {
                    address =
                        patient.current_address.address_1 +
                            '\n' +
                            patient.current_address.city +
                            ', ' +
                            patient.current_address.state +
                            ' ' +
                            patient.current_address.zipcode;
                }
                return address;
            };
            ProblemListPrintService.$inject = ['jsPDF', '$q', '$filter'];
            return ProblemListPrintService;
        }());
        services.ProblemListPrintService = ProblemListPrintService;
        angular.module('consolo').service('ProblemListPrintService', ProblemListPrintService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('problemLists', {
            bindings: {},
            templateUrl: '/y/templates/problem-lists/problem-lists.html',
            controller: 'ProblemListsController as $ctrl',
        });
        var ProblemListsController = /** @class */ (function () {
            function ProblemListsController(logger, CurrentPatient, ProblemListItemService, $state, $stateParams, $mdDialog, ProblemListPrintService, Session, $window, AboutService) {
                var _this = this;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.ProblemListItemService = ProblemListItemService;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdDialog = $mdDialog;
                this.ProblemListPrintService = ProblemListPrintService;
                this.Session = Session;
                this.$window = $window;
                this.AboutService = AboutService;
                this.errors = [];
                this.loading = false;
                this.showHistory = false;
                this.reordering = false;
                this.includeInactive = false;
                this.loading = true;
                this.ProblemListItemService.setCurrentPastProblemsForPatient(this.$stateParams.patientId).then(function (response) {
                    _this.problemListItems = _this.ProblemListItemService.currentProblemListItems;
                });
                this.cdsEnabled = this.Session.get().agency.has_cds;
                this.currentUser = Session.getUser();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    headerMenu: {
                        csvContents: true,
                        items: [
                            {
                                label: 'Reorder Problem List',
                                icon: 'add',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    _this.reordering = true;
                                    _this.includeInactive = false;
                                    _this.problemListItems = [].concat(_this.ProblemListItemService.currentProblemListItems);
                                    return true;
                                },
                                showCount: false,
                                conditionalHideFn: function () {
                                    return _this.reordering || !_this.ProblemListItemService.updatePermission;
                                },
                            },
                            {
                                label: 'Save',
                                icon: 'add',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    _this.reordering = false;
                                    _this.problemListItems.forEach(function (problemListItem) {
                                        _this.ProblemListItemService.upsert(problemListItem);
                                    });
                                    _this.problemListItems = [].concat(_this.ProblemListItemService.currentProblemListItems);
                                    return true;
                                },
                                showCount: false,
                                conditionalHideFn: function () {
                                    return !_this.reordering;
                                },
                            },
                            {
                                label: 'Print',
                                icon: 'print',
                                callbackFn: function (data) {
                                    return _this.ProblemListPrintService.openProblemListPdf(_this.CurrentPatient.patient, _this.problemListItems, _this.currentUser.name, null);
                                },
                            },
                        ],
                    },
                    subTable: [
                        {
                            columns: [
                                { title: 'Snomed Code', field: 'snomed_problem.id', size: 1 },
                                {
                                    title: 'Created By',
                                    field: 'user.name',
                                    size: 1,
                                },
                                {
                                    title: 'Created Date',
                                    field: 'created_at',
                                    type: csg.datatable.ColumnTypes.date,
                                    size: 1,
                                },
                                {
                                    title: 'Encounter Date(s)',
                                    field: 'dates',
                                    formatValueFn: function (problemListItem) {
                                        if (problemListItem.clinical_charts.length === 0) {
                                            return '';
                                        }
                                        else {
                                            return problemListItem.clinical_charts.map(function (chart) {
                                                return moment(chart.effective_date).format('MM/D/YYYY');
                                            });
                                        }
                                    },
                                    size: 4,
                                },
                            ],
                        },
                    ],
                    columns: [
                        {
                            title: 'Description',
                            field: 'snomed_problem.description',
                        },
                        {
                            title: 'CDS',
                            field: '_addSomeText',
                            columnHideFn: function () {
                                return !_this.cdsEnabled;
                            },
                            formatValueFn: function (problemListItem) {
                                if (problemListItem.cds_url) {
                                    var cds_url = problemListItem.cds_url;
                                    return '<div class="cds-column"><span class="material-icons">info</span></div>';
                                }
                                else {
                                    return '';
                                }
                            },
                            callbackFn: function (problemListItem, $this, $event) {
                                if (problemListItem.cds_url) {
                                    var event_1 = "Clinical Decision Support Item";
                                    var message = "Clicked Problem List CDS Item: " + problemListItem.cds_url;
                                    var patient_id = _this.CurrentPatient.patient_id;
                                    _this.AboutService.createNewAuditLog(event_1, message, patient_id)
                                        .then(function () {
                                        _this.$window.open(problemListItem.cds_url, '_blank');
                                    });
                                    $event.stopPropagation();
                                }
                            },
                        },
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'End Date',
                            field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Chronicity',
                            field: 'chronicity',
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            formatValueFn: function (problemListItem) {
                                if (!problemListItem.end_date || new Date(problemListItem.end_date) > new Date()) {
                                    return 'Active';
                                }
                                else {
                                    return 'Inactive';
                                }
                            },
                        },
                        {
                            title: 'Most Recent Encounter',
                            field: 'most_recent',
                            formatValueFn: function (problemListItem) {
                                if (problemListItem.clinical_charts.length) {
                                    var latestDate_1 = problemListItem.clinical_charts[0].effective_date;
                                    problemListItem.clinical_charts.forEach(function (chart) {
                                        if (chart.effective_date > latestDate_1) {
                                            latestDate_1 = chart.effective_date;
                                        }
                                    });
                                    return moment(latestDate_1).format('MM/D/YYYY');
                                }
                                else {
                                    return '';
                                }
                            },
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Move Up',
                            field: 'move_up',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (problemListItem) {
                                return problemListItem.position > 0 ? 'Up' : '';
                            },
                            callbackFn: function (problemListItem) {
                                _this.ProblemListItemService.moveObjectInArray(_this.ProblemListItemService.currentProblemListItems, problemListItem.position, 'up');
                                _this.problemListItems = [].concat(_this.ProblemListItemService.currentProblemListItems);
                                return true;
                            },
                            columnHideFn: function (problemListItem) {
                                return !_this.reordering || problemListItem.position === 0;
                            },
                        },
                        {
                            title: 'Move Down',
                            field: 'move_down',
                            type: csg.datatable.ColumnTypes.button,
                            formatValueFn: function (problemListItem) {
                                return problemListItem.position + 1 <
                                    _this.ProblemListItemService.currentProblemListItems.length
                                    ? 'Down'
                                    : '';
                            },
                            callbackFn: function (problemListItem) {
                                _this.moveObjectInArray(_this.ProblemListItemService.currentProblemListItems, problemListItem.position, 'down');
                                _this.problemListItems = [].concat(_this.ProblemListItemService.currentProblemListItems);
                                return true;
                            },
                            columnHideFn: function (problemListItem) {
                                return (!_this.reordering ||
                                    problemListItem.position ===
                                        _this.ProblemListItemService.currentProblemListItems.length - 1);
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                disableOffline: false,
                                icon: 'delete',
                                callbackFn: function (problemListItem) {
                                    _this.ProblemListItemService.delete(problemListItem.id).then(function (deletedItem) {
                                        _this.ProblemListItemService.setCurrentPastProblemsForPatient(_this.$stateParams.patientId);
                                    });
                                },
                                conditionalHideFn: function (problemListItem) {
                                    return (problemListItem.clinical_charts.length > 0 ||
                                        !_this.ProblemListItemService.deletePermission);
                                },
                            },
                            {
                                label: 'Edit',
                                disableOffline: false,
                                icon: 'edit',
                                callbackFn: function (problemListItem) {
                                    return _this.editProblemList(problemListItem);
                                },
                                conditionalHideFn: function (problemListItem) {
                                    return (problemListItem.clinical_charts.length > 0 ||
                                        !_this.ProblemListItemService.updatePermission);
                                },
                            },
                        ],
                    },
                };
            }
            ProblemListsController.prototype.addNewProblem = function (newProblem) {
                if (newProblem) {
                    var problem = {
                        position: this.ProblemListItemService.currentProblemListItems.length,
                        start_date: new Date(),
                        snomed_problem: angular.copy(newProblem),
                        snomed_problem_id: newProblem.snomed_cid,
                        patient_id: this.CurrentPatient.patient.id,
                        health_conditions: [],
                        end_date: null,
                        chronicity: null,
                        snomed_problem_description: newProblem,
                    };
                    newProblem = null;
                    this.newProblem = null;
                    this.editProblemList(problem);
                }
            };
            ProblemListsController.prototype.editProblemList = function (problem) {
                var _this = this;
                return this.ProblemListItemService.editAndUpsertProblemListItem(problem).then(function () {
                    _this.problemListItems = _this.ProblemListItemService.currentProblemListItems;
                });
            };
            ProblemListsController.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                // toIndex determines where the icd10 object is moving based on whether up or down arrows are clicked
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
                this.setPosition(array);
                return [].concat(array);
            };
            ProblemListsController.prototype.setPosition = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i;
                }
            };
            ProblemListsController.prototype.updateProblemLists = function () {
                if (this.includeInactive) {
                    this.problemListItems = []
                        .concat(this.ProblemListItemService.currentProblemListItems)
                        .concat(this.ProblemListItemService.pastProblemListItems);
                }
                else {
                    this.problemListItems = [].concat(this.ProblemListItemService.currentProblemListItems);
                }
            };
            ProblemListsController.$inject = [
                'logger',
                'CurrentPatient',
                'ProblemListItemService',
                '$state',
                '$stateParams',
                '$mdDialog',
                'ProblemListPrintService',
                'SessionService',
                '$window',
                'AboutService',
            ];
            return ProblemListsController;
        }());
        common.ProblemListsController = ProblemListsController;
        angular.module('consolo').controller('ProblemListsController', ProblemListsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider.state('patients.problem-list-and-diagnoses', {
                url: '/problem_lists',
                data: { title: 'Patients Problem Lists & Diagnoses' },
                views: {
                    'main-content@': {
                        template: '<problem-list-and-diagnoses></problem-list-and-diagnoses>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('selectDiagnosis', {
            bindings: { problemListItem: '<' },
            templateUrl: '/y/templates/problem-lists/select-diagnosis.html',
            controller: 'SelectDiagnosisController as $ctrl',
        });
        var SelectDiagnosisController = /** @class */ (function () {
            function SelectDiagnosisController($mdDialog, ProblemListItemService) {
                this.$mdDialog = $mdDialog;
                this.ProblemListItemService = ProblemListItemService;
                if (!this.problemListItem && this.ProblemListItemService.currentProblemListItem) {
                    this.problemListItem = this.ProblemListItemService.currentProblemListItem;
                }
            }
            SelectDiagnosisController.prototype.add = function () {
                return this.$mdDialog.hide(this.selectedIcd10s);
            };
            SelectDiagnosisController.$inject = ['$mdDialog', 'ProblemListItemService'];
            return SelectDiagnosisController;
        }());
        common.SelectDiagnosisController = SelectDiagnosisController;
        angular.module('consolo').controller('SelectDiagnosisController', SelectDiagnosisController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('procedureItem', {
            bindings: {},
            templateUrl: '/y/templates/procedures/procedure-item.html',
            controller: 'ProcedureItemController as vm',
        });
        var ProcedureItemController = /** @class */ (function () {
            function ProcedureItemController($state, $stateParams, procedureService, options) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.procedureService = procedureService;
                this.options = options;
                this.saving = false;
                this.action = 'new';
                this.validStatuses = [{ id: "Normal" }, { id: "Aborted" }, { id: "Active" }, { id: "Cancelled" }, { id: "Completed" },
                    { id: "Held" }, { id: "New" }, { id: "Suspended" }, { id: "Nullified" }, { id: "Obsolete" }];
                this.action = this.$state.current.data.action;
                this.procedure = procedureService.currentProcedure;
            }
            ProcedureItemController.prototype.goToProcedures = function () {
                this.$state.go("patients.procedures");
            };
            ProcedureItemController.prototype.setIds = function () {
                if (this.procedure.snomed_object) {
                    this.procedure.snomed_code = this.procedure.snomed_object.code;
                }
                if (this.procedure.location) {
                    this.procedure.location_id = this.procedure.location.id;
                }
            };
            ProcedureItemController.prototype.save = function (procedure) {
                var _this = this;
                this.saving = true;
                this.setIds();
                var act;
                if (this.action === 'edit') {
                    act = this.procedureService.update(procedure);
                }
                else {
                    procedure.patient_id = this.$stateParams.patientId;
                    act = this.procedureService.create(procedure);
                }
                act.then(function (_proc) {
                    _this.procedure = null;
                    _this.procedureService.currentProcedure = null;
                    _this.goToProcedures();
                });
                act.finally(function () { return _this.saving = false; });
            };
            ProcedureItemController.prototype.snomedAutocompleteLookup = function (term) {
                return this.procedureService.snomedProcedureLookup(term).then(function (results) {
                    return results.data.items.map(function (concept) {
                        return { name: concept.fsn.term, code: concept.conceptId };
                    });
                });
            };
            ProcedureItemController.prototype.locationAutocompleteLookup = function (term) {
                return this.options.get('locations').$promise
                    .then(function (locations) { return locations.filter(function (l) { return !term || l.preferred_concept_name.indexOf(term) >= 0; }); });
            };
            ProcedureItemController.$inject = [
                '$state',
                '$stateParams',
                'procedureService',
                'options'
            ];
            return ProcedureItemController;
        }());
        components.ProcedureItemController = ProcedureItemController;
        angular.module('consolo').controller('ProcedureItemController', ProcedureItemController);
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ProcedureService = /** @class */ (function () {
            function ProcedureService($http, PermissionsService, $mdDialog, Session, logger) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.logger = logger;
                this.proceduresUrl = '/api/v1/procedures';
                this.updatePermission = false;
                this.viewPermission = false;
                this.destroyPermission = false;
                this.createPermission = false;
                this.updatePermission = this.PermissionsService.can('update', 'procedures');
                this.createPermission = this.PermissionsService.can('create', 'procedures');
                this.destroyPermission = this.PermissionsService.can('destroy', 'procedures');
                this.viewPermission = this.PermissionsService.can('show', 'procedures');
            }
            ProcedureService.prototype.getProcedures = function (patientId) {
                return this.$http
                    .get(this.proceduresUrl, {
                    params: { patient_id: patientId },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            ProcedureService.prototype.get = function (procedureId) {
                var _this = this;
                return this.$http
                    .get(this.proceduresUrl + "/" + procedureId)
                    .then(function (response) {
                    _this.currentProcedure = response.data;
                    return _this.currentProcedure;
                });
            };
            ProcedureService.prototype.update = function (procedure) {
                var _this = this;
                return this.$http
                    .patch(this.proceduresUrl + "/" + procedure.id, procedure)
                    .then(function (response) {
                    _this.logger.success('The Procedure has been updated successfully.');
                    _this.currentProcedure = response.data;
                    return _this.currentProcedure;
                });
            };
            ProcedureService.prototype.create = function (procedure) {
                var _this = this;
                return this.$http.post(this.proceduresUrl, procedure).then(function (response) {
                    _this.logger.success('The Procedure has been created successfully.');
                    _this.currentProcedure = response.data;
                    return _this.currentProcedure;
                });
            };
            ProcedureService.prototype.delete = function (procedure) {
                var _this = this;
                return this.$http.delete(this.proceduresUrl + '/' + procedure.id).then(function (response) {
                    _this.logger.success('The Procedure has been deleted successfully.');
                    _this.currentProcedure = null;
                    return response.data;
                });
            };
            ProcedureService.prototype.snomedProcedureLookup = function (term) {
                return this.$http.get(this.proceduresUrl + "/snomed_procedures_lookup", { params: { term: term } });
            };
            ProcedureService.prototype.locationLookup = function (term) {
                return this.$http.get(this.proceduresUrl + "/location_procedures_lookup", { params: { term: term } });
            };
            ProcedureService.$inject = [
                '$http',
                'PermissionsService',
                '$mdDialog',
                'SessionService',
                'logger',
            ];
            return ProcedureService;
        }());
        services.ProcedureService = ProcedureService;
        angular.module('consolo').service('procedureService', ProcedureService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('procedures', {
            bindings: {},
            templateUrl: '/y/templates/procedures/procedures.html',
            controller: 'ProceduresController as vm',
        });
        var ProceduresController = /** @class */ (function () {
            function ProceduresController(logger, CurrentPatient, procedureService, $state, $stateParams, $mdDialog, session, $window) {
                var _this = this;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.procedureService = procedureService;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdDialog = $mdDialog;
                this.session = session;
                this.$window = $window;
                this.loading = false;
                this.showHistory = false;
                this.reordering = false;
                this.loading = true;
                this.procedureService.getProcedures(this.$stateParams.patientId).then(function (e) { return _this.procedures = e; });
                this.currentUser = this.session.getUser();
                this.setLayout();
            }
            ProceduresController.prototype.newProcedure = function (procedure) {
                this.$state.go("patients.procedures.new");
            };
            ProceduresController.prototype.editProcedure = function (procedure) {
                this.procedureService.currentProcedure = procedure;
                this.$state.go("patients.procedures.edit", { procedureId: procedure.id });
            };
            ProceduresController.prototype.viewProcedure = function (procedure) {
                this.procedureService.currentProcedure = procedure;
                this.$state.go("patients.procedures.view", { procedureId: procedure.id });
            };
            ProceduresController.prototype.deleteProcedure = function (procedure) {
                var _this = this;
                var dialog = this.$mdDialog
                    .confirm()
                    .title('Delete procedure?')
                    .htmlContent("Are you sure you want to delete '" + procedure.name + "'?")
                    .ariaLabel('Delete Procedure')
                    .ok('Yes')
                    .cancel('No');
                this.$mdDialog.show(dialog).then(function () {
                    _this.procedureService.delete(procedure).then(function (_deletedItem) {
                        _this.procedures = _this.procedures.filter(function (p) { return p.id !== procedure.id; });
                    });
                });
            };
            ProceduresController.prototype.setLayout = function () {
                var _this = this;
                this.tableLayout = {
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            canSort: true,
                        },
                        {
                            title: 'Snomed Code',
                            field: 'snomed_code',
                            canSort: true,
                        },
                        {
                            title: 'Date',
                            field: 'performed_at',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            canSort: true,
                        },
                        {
                            title: 'Notes',
                            field: 'notes',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Location',
                            field: 'location.preferred_concept_name',
                            canSort: true,
                        },
                        {
                            title: 'Provider',
                            field: 'provider_name',
                            canSort: true,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                disableOffline: false,
                                icon: 'remove_red_eye',
                                callbackFn: function (procedure) { return _this.viewProcedure(procedure); }
                            },
                            {
                                label: 'Edit',
                                disableOffline: false,
                                icon: 'edit',
                                callbackFn: function (procedure) { return _this.editProcedure(procedure); },
                                conditionalHideFn: function (procedure) { return !_this.procedureService.updatePermission; },
                            },
                            {
                                label: 'Delete',
                                disableOffline: false,
                                icon: 'delete',
                                callbackFn: function (procedure) { return _this.deleteProcedure(procedure); },
                                conditionalHideFn: function (procedure) { return !_this.procedureService.destroyPermission; },
                            },
                        ],
                    },
                };
            };
            ProceduresController.$inject = [
                'logger',
                'CurrentPatient',
                'procedureService',
                '$state',
                '$stateParams',
                '$mdDialog',
                'SessionService',
                '$window',
            ];
            return ProceduresController;
        }());
        components.ProceduresController = ProceduresController;
        angular.module('consolo').controller('ProceduresController', ProceduresController);
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes);
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider.state('patients.procedures', {
                url: '/procedures',
                data: { title: 'Patients Procedures' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/procedures/procedures.html',
                        controller: 'ProceduresController as vm'
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.procedures.new', {
                url: '/new',
                data: { title: 'New Procedure', action: 'new' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/procedures/procedure-item.html',
                        controller: 'ProcedureItemController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                }
            })
                .state('patients.procedures.view', {
                url: '/:procedureId',
                data: { title: 'View Procedure', action: 'view' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/procedures/procedure-item.html',
                        controller: 'ProcedureItemController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                }
            })
                .state('patients.procedures.edit', {
                url: '/:procedureId/edit',
                data: { title: 'Edit Procedure', action: 'edit' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/procedures/procedure-item.html',
                        controller: 'ProcedureItemController as vm'
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    procedure: [
                        'procedureService',
                        '$stateParams',
                        function (procServ, $stateParams) {
                            return procServ.get($stateParams['procedureId']);
                        }
                    ]
                }
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var ReconciliationStates = /** @class */ (function () {
            function ReconciliationStates($stateProvider) {
                $stateProvider
                    .state('reconciliation', {
                    url: '/reconciliation',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/reconciliation/ccda-file-upload/ccda-file-import.html',
                            controller: 'CCDAFileImportController as vm',
                        },
                    },
                })
                    .state('reconciliation.import-options', {
                    url: '/:importId/import-options',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/reconciliation/ccda-patient-select/patient-import-options.html',
                            controller: 'PatientImportOptionsController as vm',
                        },
                    },
                })
                    .state('reconciliation.data-reconciliation', {
                    url: '/:importId/data-reconciliation',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/reconciliation/ccda-data-reconciliation/ccda-data-reconciliation.html',
                            controller: 'PatientDataReconciliationController as vm',
                        },
                    },
                });
            }
            ReconciliationStates.$inject = ['$stateProvider'];
            return ReconciliationStates;
        }());
        angular.module('consolo').config(ReconciliationStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('RecurringTaskSetTemplate', ['$resource', 'ROLESYMBOL', Model]);
    function Model($resource, ROLESYMBOL) {
        var RecurringTaskSetTemplate = $resource('/api/v1/recurring_task_set_templates/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            apply: {
                method: 'POST',
                url: '/api/v1/recurring_task_set_templates/:id/apply',
            },
        });
        RecurringTaskSetTemplate.baseTasks = [
            {
                description: 'Delegated Tasks',
                role_symbol: ROLESYMBOL,
                tasks: [
                    { description: 'TPR and BP (RN notification parameters)' },
                    { description: 'Height and Weight (RN notification parameters)' },
                    { description: 'Special Task (specify)' },
                ],
            },
            {
                description: 'Elimination',
                role_symbol: ROLESYMBOL,
                tasks: [
                    { description: 'Patient Independent' },
                    { description: 'Incontinent (specify)' },
                    { description: 'Barrier Products in Use (specify)' },
                    { description: 'Bathroom Assistance' },
                    { description: 'Draw Sheet' },
                    { description: 'Incontinence Pads (specify)' },
                    { description: 'Document Last BM' },
                    { description: 'Urinary Catheter (specify)' },
                    { description: 'Document Output' },
                ],
            },
            {
                description: 'Nutrition',
                role_symbol: ROLESYMBOL,
                tasks: [
                    { description: 'Diet (specify)' },
                    { description: 'Assist with Feeding' },
                    { description: 'Encourage Fluids' },
                    { description: 'Document Intake Food/Fluids' },
                ],
            },
            {
                description: 'Activity',
                role_symbol: ROLESYMBOL,
                tasks: [
                    { description: 'Ambulatory with Assistance (specify)' },
                    { description: 'Chair as Tolerated' },
                    { description: 'Bed Bound with Scheduled Repositioning' },
                    { description: 'Durable Medical Equipment present (specify)' },
                    { description: 'Fall Precautions (specify)' },
                    { description: 'Range of Motion (specify)' },
                ],
            },
            {
                description: 'Personal Care',
                role_symbol: ROLESYMBOL,
                tasks: [
                    { description: 'Complete Bed Bath' },
                    { description: 'Partial Bed Bath (Patient Assist)' },
                    { description: 'Shower' },
                    { description: 'Perineal Care' },
                    { description: 'Oral Care' },
                    { description: 'Denture Care' },
                    { description: 'Skin Care' },
                    { description: 'Shave' },
                    { description: 'Shampoo' },
                    { description: 'Foot Care' },
                    { description: 'Clothing Assist' },
                    { description: 'Nail Care' },
                ],
            },
        ];
        return RecurringTaskSetTemplate;
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('TaskSetController', [
        '$scope',
        '$state',
        '$timeout',
        'logger',
        'RecurringTaskSet',
        'RecurringTaskSetTemplate',
        Controller,
    ]);
    function Controller($scope, $state, $timeout, logger, TaskSet, RecurringTaskSetTemplate) {
        var vm = this;
        vm.focused = true;
        vm.isEditing = $scope.set.id ? false : true;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;
        vm.remove = remove;
        vm.addTask = addTask;
        vm.removeTask = removeTask;
        var resource;
        activate();
        return vm;
        function activate() {
            if ($state.current.name === 'aide-manager.recurring-task-templates.template-editor') {
                resource = RecurringTaskSetTemplate;
            }
            else {
                resource = TaskSet;
            }
        }
        function addTask() {
            $scope.set.tasks.push({ task_set_id: $scope.set.id });
        }
        function cancel() {
            if ($scope.set.id) {
                $scope.set.description = vm.oldDesc;
                vm.isEditing = false;
            }
            else {
                $scope.$parent.removeTaskSet($scope.set);
            }
        }
        function edit() {
            vm.oldDesc = $scope.set.description;
            vm.isEditing = true;
        }
        function remove() {
            if (confirm('Are you sure you want to delete this category and all its tasks?')) {
                resource.remove({ id: $scope.set.id }).$promise.then(function () {
                    $timeout(function () {
                        $scope.$parent.removeTaskSet($scope.set);
                    });
                }, showErrors);
            }
        }
        function removeTask(task) {
            $scope.set.tasks = $scope.set.tasks.filter(function (t) {
                return t !== task;
            });
        }
        function save() {
            if ($scope.set.id) {
                resource.update($scope.set).$promise.catch(showErrors);
            }
            else {
                resource.save($scope.set).$promise.then(function (set) {
                    $scope.set.id = set.id;
                }, showErrors);
            }
            vm.isEditing = false;
        }
        function showErrors(resp) {
            logger.error(resp.data.errors.join('. '), 'Error');
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('RecurringTaskSet', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/recurring_task_sets/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('consoloRecurringTaskSetsEditor', Directive);
    function Directive() {
        return {
            restrict: 'E',
            scope: {
                patient: '=',
                taskSets: '=sets',
                title: '@',
                role: '@',
                displayReview: '<'
            },
            templateUrl: '/y/templates/recurring-task-sets-editor/recurring-task-sets-editor.html',
            controller: ['$scope', '$http', 'PermissionsService', Controller],
            controllerAs: 'vm',
        };
    }
    function Controller($scope, $http, PermissionsService) {
        var vm = $scope;
        vm.$http = $http;
        vm.lastReviewed = null;
        vm.lastCreated = null;
        vm.addTaskSet = addTaskSet;
        vm.removeTaskSet = removeTaskSet;
        vm.displayName = displayName;
        vm.review = review;
        onLoad();
        vm.canReview = PermissionsService.can('review', 'hospice_aide_manager') && (vm.displayReview === undefined ? true : vm.displayReview);
        function removeTaskSet(taskSet) {
            vm.taskSets = vm.taskSets.filter(function (ts) {
                return ts !== taskSet;
            });
        }
        function review() {
            var tempSet = vm.taskSets.concat([]);
            vm.taskSets = [];
            tempSet.forEach(function (set) {
                vm.$http.patch("/api/v1/recurring_task_sets/" + set.id, set).then(function (response) {
                    vm.taskSets.push(response.data);
                    vm.displayName(vm.taskSets);
                });
            });
        }
        function addTaskSet() {
            var patientId = vm.patient ? vm.patient.id : null;
            vm.taskSets.unshift({ patient_id: patientId, role_symbol: vm.role, tasks: [] });
        }
        function onLoad() {
            vm.taskSets.$promise.then(function (data) {
                vm.displayName(vm.taskSets);
            });
        }
        function displayName(sets) {
            var latestReviewedEntry = null;
            var latestCreatedEntry = null;
            vm.lastReviewed = 'Last Reviewed: ';
            vm.lastCreated = 'Last Created: ';
            sets.forEach(function (set) {
                if (latestReviewedEntry === null || latestReviewedEntry.updated_at < set.updated_at) {
                    latestReviewedEntry = set;
                }
                set.tasks.forEach(function (task) {
                    if (latestReviewedEntry.updated_at < task.updated_at) {
                        latestReviewedEntry = task;
                    }
                });
                if (latestCreatedEntry === null || latestCreatedEntry.created_at < set.created_at) {
                    latestCreatedEntry = set;
                }
                set.tasks.forEach(function (task) {
                    if (latestCreatedEntry.created_at < task.created_at) {
                        latestCreatedEntry = task;
                    }
                });
            });
            if (latestReviewedEntry) {
                vm.lastReviewed += (latestReviewedEntry.last_updated_by && latestReviewedEntry.last_updated_by.credentials) ?
                    latestReviewedEntry.last_updated_by.credentials + ' ' : '';
                vm.lastReviewed += latestReviewedEntry.last_updated_by ? latestReviewedEntry.last_updated_by.name + ' ' : '';
                vm.lastReviewed += new Date(latestReviewedEntry.updated_at).toLocaleString();
            }
            if (latestCreatedEntry) {
                vm.lastCreated += (latestCreatedEntry.created_by && latestCreatedEntry.created_by.credentials) ?
                    latestCreatedEntry.created_by.credentials + ' ' : '';
                vm.lastCreated += latestCreatedEntry.created_by ? latestCreatedEntry.created_by.name + ' ' : '';
                vm.lastCreated += new Date(latestCreatedEntry.created_at).toLocaleString();
            }
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('RecurringTaskTemplate', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/recurring_task_set_templates/:task_set_id/tasks/:id', { task_set_id: '@task_set_id', id: '@id' }, {
            update: { method: 'PATCH' },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('TaskController', [
        '$stateParams',
        '$scope',
        '$timeout',
        'RecurringTask',
        'RecurringTaskTemplate',
        Controller,
    ]);
    function Controller($params, $scope, $timeout, Task, RecurringTaskTemplate) {
        var vm = this;
        vm.focused = true;
        vm.isEditing = $scope.task.id ? false : true;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.remove = remove;
        vm.save = save;
        var resource;
        activate();
        function activate() {
            if ($params.patientId) {
                resource = Task;
            }
            else {
                resource = RecurringTaskTemplate;
            }
        }
        function cancel() {
            if ($scope.task.id) {
                $scope.task.description = vm.oldDesc;
                vm.isEditing = false;
            }
            else {
                $scope.setVm.removeTask($scope.task);
            }
        }
        function edit() {
            vm.oldDesc = $scope.task.description;
            vm.isEditing = true;
        }
        function remove() {
            if (confirm('Are you sure you want to delete this task?')) {
                $timeout(function () {
                    $scope.setVm.removeTask($scope.task);
                });
                resource.remove($scope.task);
            }
        }
        function save() {
            if ($scope.task.id) {
                resource.update($scope.task);
            }
            else {
                resource.save($scope.task, function (task) {
                    $scope.task.id = task.id;
                });
            }
            vm.isEditing = false;
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .factory('RecurringTask', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/recurring_task_sets/:task_set_id/tasks/:id', { task_set_id: '@task_set_id', id: '@id' }, {
            update: { method: 'PATCH' },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('consoloSelectTemplate', [
        'RecurringTaskSetTemplate',
        'RecurringTaskTemplate',
        '$q',
        'logger',
        Directive,
    ]);
    function Directive(RecurringTaskSetTemplate, RecurringTaskTemplate, $q, logger) {
        return {
            restrict: 'E',
            scope: {
                patientId: '=',
                roleSymbol: '=',
                taskSets: '=',
                templateApplied: '&',
            },
            templateUrl: '/y/templates/recurring-task-sets-editor/select-template.html',
            link: link,
        };
        function link(vm) {
            // members
            vm.allSelected = false;
            vm.selected = [];
            vm.loading = false;
            // functions
            vm.applyTemplate = applyTemplate;
            vm.checkSet = checkSet;
            vm.isSelected = isSelected;
            vm.selectAll = selectAll;
            vm.toggle = toggle;
            function applyTemplate() {
                vm.loading = true;
                var chain = $q(function (resolve) {
                    logger.info('Please wait while we apply your templates', 'Saving...');
                    resolve();
                });
                vm.taskSets.forEach(function (taskSet) {
                    if (taskSet.checked) {
                        chain = chain.then(function () {
                            if (vm.patientId) {
                                return RecurringTaskSetTemplate.apply({ id: taskSet.id, patient_id: vm.patientId })
                                    .$promise;
                            }
                            else {
                                return RecurringTaskSetTemplate.save(taskSet).$promise;
                            }
                        });
                    }
                });
                chain.then(function () {
                    logger.success('Template applied');
                    vm.templateApplied();
                    vm.loading = false;
                });
            }
            function checkSet(set, $event) {
                $event.stopPropagation();
            }
            function isSelected(set) {
                return vm.selected.indexOf(set) > -1;
            }
            function selectAll() {
                vm.taskSets.forEach(function (item) {
                    item.checked = !vm.allSelected;
                });
            }
            function toggle(set) {
                var idx = vm.selected.indexOf(set);
                if (idx > -1) {
                    vm.selected.splice(idx, 1);
                }
                else {
                    vm.selected.push(set);
                }
            }
        }
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('TemplateEditorController', ['RecurringTaskSetTemplate', 'ROLESYMBOL', Controller]);
    function Controller(RecurringTaskSetTemplate, ROLESYMBOL) {
        var vm = this;
        //members
        vm.taskSets = [];
        vm.roleSymbol = ROLESYMBOL;
        activate();
        function activate() {
            vm.taskSets = RecurringTaskSetTemplate.query({ role_symbol: ROLESYMBOL });
        }
    }
})();

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('reportListItem', {
            bindings: { report: '<' },
            templateUrl: '/y/templates/reports/report-list-item.html',
            controller: 'ReportListItemController as vm',
        });
        var ReportListItemController = /** @class */ (function () {
            function ReportListItemController(ReportsService, logger) {
                this.ReportsService = ReportsService;
                this.logger = logger;
            }
            ReportListItemController.prototype.getIcon = function (category) {
                switch (category) {
                    case 'Clinical':
                        return 'maps:local_pharmacy';
                    case 'Financial':
                        return 'editor:attach_money';
                    case 'Human Resource':
                        return 'social:people';
                    case 'Management':
                        return 'action:work';
                    case 'Navigator':
                        return 'action:explore';
                    case 'PreBilling':
                        return 'action:assignment';
                    case 'QAPI':
                        return 'maps:local_hospital';
                    case 'MIPs':
                        return 'maps:local_hospital';
                    default:
                        return '';
                }
            };
            ReportListItemController.prototype.reportHref = function () {
                if (this.report.path) {
                    return this.report.path;
                }
                else {
                    return "/reports/" + this.report.code;
                }
            };
            ReportListItemController.prototype.toggleFavorite = function (report) {
                this.ReportsService.isLoading = true;
                if (report.is_favorite) {
                    this.toggleRemove(report);
                }
                else {
                    this.toggleAdd(report);
                }
            };
            ReportListItemController.prototype.toggleRemove = function (report) {
                var _this = this;
                this.ReportsService.removeFavoriteReport(report.id)
                    .then(function (favoriteObj) {
                    report.is_favorite = false;
                    report.favorite_id = null;
                    _this.ReportsService.favoriteReports.splice(_this.ReportsService.favoriteIndex(report), 1);
                    _this.ReportsService.isLoading = false;
                    _this.logger.success('Successfully removed ' + report.title + ' from favorites list.', 'Success:');
                })
                    .catch(function (error) {
                    _this.ReportsService.isLoading = false;
                    _this.logger.error('Unable to remove ' + report.title + ' from favorites list.', 'Error:');
                    console.error(error);
                });
            };
            ReportListItemController.prototype.toggleAdd = function (report) {
                var _this = this;
                this.ReportsService.addFavoriteReport(report.id)
                    .then(function (favoriteObj) {
                    report.is_favorite = true;
                    report.favorite_id = favoriteObj.id;
                    _this.ReportsService.favoriteReports.push(favoriteObj);
                    _this.ReportsService.isLoading = false;
                    _this.logger.success('Successfully added ' + favoriteObj.report.title + ' to favorites list.', 'Success:');
                })
                    .catch(function (error) {
                    _this.ReportsService.isLoading = false;
                    _this.logger.error('Unable to add ' + report.title + ' to favorites list.', 'Error:');
                    console.error(error);
                });
            };
            ReportListItemController.$inject = ['ReportsService', 'logger'];
            return ReportListItemController;
        }());
        components.ReportListItemController = ReportListItemController;
        angular.module('consolo').controller('ReportListItemController', ReportListItemController);
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ReportsSidebarController = /** @class */ (function () {
            function ReportsSidebarController(ReportsService) {
                this.ReportsService = ReportsService;
                this.categories = [
                    'Clinical',
                    'Financial',
                    'Human Resource',
                    'Management',
                    'Navigator',
                    'PreBilling',
                    'QAPI',
                    'MIPs',
                ];
            }
            ReportsSidebarController.prototype.getIcon = function (category) {
                switch (category) {
                    case 'Clinical':
                        return 'maps:local_pharmacy';
                    case 'Financial':
                        return 'editor:attach_money';
                    case 'Human Resource':
                        return 'social:people';
                    case 'Management':
                        return 'action:work';
                    case 'Navigator':
                        return 'action:explore';
                    case 'PreBilling':
                        return 'action:assignment';
                    case 'QAPI':
                        return 'maps:local_hospital';
                    case 'MIPs':
                        return 'maps:local_hospital';
                    default:
                        return '';
                }
            };
            ReportsSidebarController.prototype.toggleCategory = function (category) {
                var idx = this.ReportsService.categories.indexOf(category);
                if (idx > -1) {
                    this.ReportsService.categories.splice(idx, 1);
                }
                else {
                    this.ReportsService.categories.push(category);
                }
            };
            ReportsSidebarController.prototype.exists = function (category) {
                return this.ReportsService.categories.indexOf(category) > -1;
            };
            ReportsSidebarController.$inject = ['ReportsService'];
            return ReportsSidebarController;
        }());
        angular.module('consolo').controller('ReportsSidebarController', ReportsSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ReportsController = /** @class */ (function () {
            function ReportsController($q, ReportsService, Session) {
                this.$q = $q;
                this.ReportsService = ReportsService;
                this.Session = Session;
                this.initPageData();
            }
            ReportsController.prototype.initPageData = function () {
                var _this = this;
                this.ReportsService.isLoading = true;
                this.isPalliative = this.Session.get().agency.service_line === 'palliative';
                this.isMips = this.Session.get().agency.has_mips;
                var promiseChain = [
                    this.ReportsService.getReports(),
                    this.ReportsService.getRecentReports(),
                    this.ReportsService.getFavoriteReports(),
                ];
                this.$q.all(promiseChain).then(function (response) {
                    _this.ReportsService.reports = response[0];
                    _this.ReportsService.recentReports = response[1];
                    _this.ReportsService.favoriteReports = response[2];
                    _this.ReportsService.mapReports();
                    _this.ReportsService.isLoading = false;
                });
            };
            ReportsController.prototype.hideReport = function (report) {
                var pallativeCondition = this.isPalliative && !report.service_lines.includes('palliative');
                var mipsCondition = !this.isMips && report.categories.includes('MIPs');
                return pallativeCondition || mipsCondition;
            };
            ReportsController.$inject = ['$q', 'ReportsService', 'SessionService'];
            return ReportsController;
        }());
        angular.module('consolo').controller('ReportsController', ReportsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ReportsService = /** @class */ (function () {
            function ReportsService($http, Session, $timeout) {
                this.$http = $http;
                this.Session = Session;
                this.$timeout = $timeout;
                this.reports = [];
                this.recentReports = [];
                this.favoriteReports = [];
                this.reportApiUrl = '/api/v2/reports';
                this.searchText = '';
                this.categories = [];
                this.isLoading = false;
                this.userId = this.Session.get().user.id;
                this.reportHistoryApiUrl = '/api/v2/report_history/users/' + this.userId + '/last_three';
                this.favoriteReportsApiUrl = '/api/v2/favorite_reports';
            }
            ReportsService.prototype.getReports = function () {
                return this.$http
                    .get(this.reportApiUrl)
                    .then(function (response) {
                    return response.data;
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            ReportsService.prototype.getRecentReports = function () {
                return this.$http
                    .get(this.reportHistoryApiUrl)
                    .then(function (response) {
                    return response.data;
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            ReportsService.prototype.lookupReports = function () {
                var _this = this;
                this.isLoading = true;
                if (!this.pendingQuery) {
                    this.pendingQuery = this.$timeout(function () {
                        return _this.$http
                            .get(_this.reportApiUrl, {
                            params: { 'categories[]': _this.categories },
                        })
                            .then(function (response) {
                            _this.reports = response.data;
                            _this.mapReports();
                            _this.isLoading = false;
                        })
                            .catch(function (error) {
                            throw error;
                        });
                    }, 1000);
                }
                else if (this.pendingQuery) {
                    this.$timeout.cancel(this.pendingQuery);
                    this.pendingQuery = null;
                    this.lookupReports();
                }
            };
            ReportsService.prototype.getFavoriteReports = function () {
                return this.$http
                    .get(this.favoriteReportsApiUrl)
                    .then(function (response) {
                    return response.data;
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            ReportsService.prototype.addFavoriteReport = function (reportId) {
                return this.$http
                    .post(this.favoriteReportsApiUrl + '/' + reportId)
                    .then(function (response) {
                    return response.data;
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            ReportsService.prototype.removeFavoriteReport = function (reportId) {
                return this.$http
                    .delete(this.favoriteReportsApiUrl + '/' + reportId)
                    .then(function (response) {
                    return response.data;
                })
                    .catch(function (error) {
                    throw error;
                });
            };
            ReportsService.prototype.mapReports = function () {
                var _this = this;
                this.reports.map(function (report) {
                    report.is_favorite = _this.favoriteIndex(report) !== -1 ? true : false;
                    report.is_recent = _this.lastThreeIndex(report) !== -1 ? true : false;
                    report.favorite_id =
                        _this.favoriteIndex(report) !== -1
                            ? _this.favoriteReports[_this.favoriteIndex(report)].id
                            : null;
                });
            };
            ReportsService.prototype.favoriteIndex = function (report) {
                // @ts-ignore
                return this.favoriteReports.findIndex(function (favorite) { return favorite.report.id === report.id; });
            };
            ReportsService.prototype.lastThreeIndex = function (report) {
                // @ts-ignore
                return this.recentReports.findIndex(function (recent) { return recent.report.id === report.id; });
            };
            ReportsService.$inject = ['$http', 'SessionService', '$timeout'];
            return ReportsService;
        }());
        services.ReportsService = ReportsService;
        angular.module('consolo').service('ReportsService', ReportsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular.module('consolo').config(Routes);
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider.state('reports', {
                url: '/reports',
                data: { title: 'Consolo Reports', hideSidebar: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/reports/reports.html',
                        controller: 'ReportsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/reports/reports-sidebar.html',
                        controller: 'ReportsSidebarController as vm',
                    },
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var SmokingStatusController = /** @class */ (function () {
            function SmokingStatusController(logger, SmokingStatusService, $window, $mdDialog, smokingStatus, CurrentPatient, options, $state) {
                this.logger = logger;
                this.SmokingStatusService = SmokingStatusService;
                this.$window = $window;
                this.$mdDialog = $mdDialog;
                this.smokingStatus = smokingStatus;
                this.CurrentPatient = CurrentPatient;
                this.options = options;
                this.$state = $state;
                this.today = new Date();
                this.SmokingStatusService.currentSmokingStatus = smokingStatus;
                this.SmokingStatusService.currentSmokingStatus.patient_id = this.CurrentPatient.patient_id;
                this.status_options = options.get('smoking_statuses');
                this.smoking_implement_options = options.get('smoking_implements');
                this.save_disabled = false;
            }
            SmokingStatusController.prototype.save = function () {
                var _this = this;
                this.save_disabled = true;
                if (this.SmokingStatusService.currentSmokingStatus.id) {
                    //update
                    this.SmokingStatusService.update(this.SmokingStatusService.currentSmokingStatus).then(function (d) {
                        _this.CurrentPatient.refreshPatient();
                        _this.SmokingStatusService.updateSmokingStatuses();
                        _this.SmokingStatusService.currentSmokingStatus = d.data;
                        if (_this.$state.current.name !== 'patients.smoking-statuses') {
                            _this.$state.go('patients.smoking-statuses');
                        }
                        _this.closeDialog();
                    }, function (e) {
                        _this.save_disabled = false;
                        console.log(e);
                        _this.errors = e.data;
                    });
                }
                else {
                    //new
                    this.SmokingStatusService.create(this.SmokingStatusService.currentSmokingStatus).then(function (d) {
                        _this.CurrentPatient.refreshPatient();
                        _this.SmokingStatusService.currentSmokingStatus = d.data;
                        _this.SmokingStatusService.add(_this.SmokingStatusService.currentSmokingStatus);
                        if (_this.$state.current.name !== 'patients.smoking-statuses') {
                            _this.$state.go('patients.smoking-statuses');
                        }
                        _this.closeDialog();
                    }, function (e) {
                        _this.save_disabled = false;
                        console.log(e);
                        _this.errors = e.data;
                    });
                }
            };
            SmokingStatusController.prototype.smokingImplementIf = function () {
                if (this.SmokingStatusService.currentSmokingStatus.status === 'Every Day Smoker' ||
                    this.SmokingStatusService.currentSmokingStatus.status === 'Some Days (Occasional) Smoker') {
                    return true;
                }
                else {
                    return false;
                }
            };
            SmokingStatusController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            SmokingStatusController.prototype.addSnomedCode = function (smokingStatus) {
                var snomedIndex = this.status_options.map(function (option) {
                    return option.description;
                }).indexOf(smokingStatus.status);
                smokingStatus.snomed_code = this.status_options[snomedIndex].id;
            };
            SmokingStatusController.$inject = [
                'logger',
                'SmokingStatusService',
                '$window',
                '$mdDialog',
                'smokingStatus',
                'CurrentPatient',
                'options',
                '$state',
            ];
            return SmokingStatusController;
        }());
        angular.module('consolo').controller('SmokingStatusController', SmokingStatusController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var SmokingStatusService = /** @class */ (function () {
            function SmokingStatusService(SmokingStatus, $state, $filter, $mdDialog, logger, Permissions, CurrentPatient, Session, $http) {
                this.SmokingStatus = SmokingStatus;
                this.$state = $state;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.Permissions = Permissions;
                this.CurrentPatient = CurrentPatient;
                this.Session = Session;
                this.$http = $http;
                this.smokingStatuses = Array(0);
                this.hasDestroyPermission = this.Permissions.can('destroy', 'smoking_statuses');
                this.hasShowPermission = this.Permissions.can('show', 'smoking_statuses');
                this.hasUpdatePermission = this.Permissions.can('update', 'smoking_statuses');
                this.hasCreatePermission = this.Permissions.can('create', 'smoking_statuses');
                var user = this.Session.getUser();
                if (user) {
                    this.user_dashboard_url = user.dashboard_url;
                }
            }
            SmokingStatusService.prototype.modal = function () {
                return this.$mdDialog.show({
                    clickOutsideToClose: true,
                    templateUrl: '/y/templates/smoking-statuses/smoking-status.html',
                    controller: 'SmokingStatusController as vm',
                    bindToController: true,
                    locals: { smokingStatus: this.currentSmokingStatus },
                });
            };
            SmokingStatusService.prototype.new = function () {
                this.currentSmokingStatus = new consolo.domain.SmokingStatus();
                return this.currentSmokingStatus;
            };
            SmokingStatusService.prototype.add = function (smokingStatus) {
                this.smokingStatuses.push(smokingStatus);
                this.smokingStatuses = [].concat(this.smokingStatuses).sort(function (a, b) {
                    if (a.effective_date === b.effective_date) {
                        if (a.created_at < b.created_at) {
                            return 1;
                        }
                        if (a.created_at > b.created_at) {
                            return -1;
                        }
                    }
                    if (b.effective_date > a.effective_date) {
                        return 1;
                    }
                    if (b.effective_date < a.effective_date) {
                        return -1;
                    }
                    return 0;
                });
            };
            SmokingStatusService.prototype.remove = function (smokingStatus) {
                var index = this.smokingStatuses.indexOf(smokingStatus, 0);
                if (index > -1) {
                    this.smokingStatuses.splice(index, 1);
                }
                this.currentSmokingStatus = null;
            };
            SmokingStatusService.prototype.edit = function (smokingStatus) {
                smokingStatus.readonly = false;
                this.currentSmokingStatus = smokingStatus;
                return this.modal();
            };
            SmokingStatusService.prototype.updateSmokingStatuses = function () {
                var _this = this;
                this.loadingData = true;
                this.SmokingStatus.query({ patient_id: this.CurrentPatient.patient.id }, function (smokingStatuses) {
                    _this.smokingStatuses = smokingStatuses;
                    _this.loadingData = false;
                });
            };
            SmokingStatusService.prototype.view = function (smokingStatus) {
                smokingStatus.readonly = true;
                this.currentSmokingStatus = smokingStatus;
                return this.modal();
            };
            SmokingStatusService.prototype.delete = function (smokingStatus) {
                this.remove(smokingStatus);
                return this.$http.delete('/api/v1/smoking_statuses' +
                    '/' +
                    smokingStatus.id +
                    '?patient_id=' +
                    smokingStatus.patient_id);
            };
            SmokingStatusService.prototype.create = function (smokingStatus) {
                return this.$http.post('/api/v1/smoking_statuses', smokingStatus);
            };
            SmokingStatusService.prototype.update = function (smokingStatus) {
                return this.$http.patch('/api/v1/smoking_statuses' + '/' + smokingStatus.id, smokingStatus);
            };
            SmokingStatusService.$inject = [
                'SmokingStatus',
                '$state',
                '$filter',
                '$mdDialog',
                'logger',
                'PermissionsService',
                'CurrentPatient',
                'SessionService',
                '$http',
            ];
            return SmokingStatusService;
        }());
        services.SmokingStatusService = SmokingStatusService;
        angular.module('consolo').service('SmokingStatusService', SmokingStatusService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var SmokingStatusesController = /** @class */ (function () {
            function SmokingStatusesController(currentPatient, $mdDialog, smokingStatusService, $scope) {
                this.currentPatient = currentPatient;
                this.$mdDialog = $mdDialog;
                this.smokingStatusService = smokingStatusService;
                this.$scope = $scope;
                this.smokingStatusService.updateSmokingStatuses();
                this.loadModel();
            }
            SmokingStatusesController.prototype.loadModel = function () {
                var _this = this;
                this.smokingStatusesTable = {
                    options: { expandCollapseAll: false },
                    header: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            header: true,
                            isDate: true,
                        },
                        {
                            title: 'Packs/Day',
                            field: 'packs_per_day',
                            header: true,
                        },
                        {
                            title: 'Smoking Status',
                            field: 'status',
                            header: true,
                        },
                        {
                            title: 'Additional Info',
                            field: 'additional_info',
                            header: true,
                        },
                        {
                            header: true,
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            showExpandCollapse: false,
                            itemMenuItems: [
                                {
                                    icon: 'image:remove_red_eye',
                                    text: 'View',
                                    callbackFn: function ($event, item) {
                                        return _this.smokingStatusService.view(item);
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.smokingStatusService.hasShowPermission;
                                    },
                                },
                                {
                                    icon: 'image:edit',
                                    text: 'Edit',
                                    callbackFn: function ($event, item) {
                                        return _this.smokingStatusService.edit(item);
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.smokingStatusService.hasUpdatePermission;
                                    },
                                },
                                {
                                    icon: 'action:delete',
                                    text: 'Delete',
                                    callbackFn: function ($event, item) {
                                        return _this.smokingStatusService.delete(item);
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.smokingStatusService.hasDestroyPermission;
                                    },
                                },
                            ],
                        },
                    ],
                    subTable: [
                        [
                            {
                                title: 'Smoking Implement',
                                field: 'smoking_implement',
                                singleColumnOrdinal: 1,
                            },
                        ],
                    ],
                };
            };
            SmokingStatusesController.prototype.newSmokingStatus = function () {
                return this.$mdDialog.show({
                    locals: { smokingStatus: this.smokingStatusService.new() },
                    templateUrl: '/y/templates/smoking-statuses/smoking-status.html',
                    controller: 'SmokingStatusController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                });
            };
            SmokingStatusesController.$inject = ['currentPatient', '$mdDialog', 'SmokingStatusService', '$scope'];
            return SmokingStatusesController;
        }());
        angular
            .module('consolo')
            .controller('SmokingStatusesController', SmokingStatusesController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('patients.smoking-statuses', {
                url: '/smoking_statuses',
                data: { title: 'Smoking Statuses', lockOpenPatientSidenav: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/smoking-statuses/smoking-statuses.html',
                        controller: 'SmokingStatusesController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.smoking-statuses.print', {
                url: '/print',
                data: { title: 'Smoking Statuses', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/smoking-statuses/print.html',
                        controller: 'SmokingStatusesController as vm',
                    },
                },
            })
                .state('patients.smoking-statuses.view', {
                url: '/:smokingStatusId',
                data: { title: 'Smoking Status', lockOpenPatientSidenav: false },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/smoking-statuses/smoking-status.html',
                        controller: 'SmokingStatusController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    smokingStatus: [
                        '$stateParams',
                        'SmokingStatus',
                        function ($params, SmokingStatus) {
                            return SmokingStatus.getById($params.smokingStatusId).then(function (ss) {
                                ss.readonly = true;
                                return ss;
                            });
                        },
                    ],
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var NewTeamDialogController = /** @class */ (function () {
            function NewTeamDialogController($mdDialog) {
                this.$mdDialog = $mdDialog;
            }
            NewTeamDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.$mdDialog.hide(this.teamName);
            };
            NewTeamDialogController.$inject = ['$mdDialog'];
            return NewTeamDialogController;
        }());
        angular.module('consolo').controller('NewTeamDialogController', NewTeamDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var TeamMember = consolo.domain.TeamMember;
        var NewTeamMemberDialogController = /** @class */ (function () {
            function NewTeamMemberDialogController($mdDialog, UserService) {
                this.$mdDialog = $mdDialog;
                this.UserService = UserService;
                this.teamMember = new TeamMember();
            }
            NewTeamMemberDialogController.prototype.teamMemberAutocompleteLookup = function (query) {
                return this.UserService.lookup(query).then(function (results) { return results.data; });
            };
            NewTeamMemberDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.$mdDialog.hide(this.teamMember);
            };
            NewTeamMemberDialogController.$inject = ['$mdDialog', 'UserService'];
            return NewTeamMemberDialogController;
        }());
        angular
            .module('consolo')
            .controller('NewTeamMemberDialogController', NewTeamMemberDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Team = consolo.domain.Team;
        var TeamsEditController = /** @class */ (function () {
            function TeamsEditController($state, TeamsService, $mdDialog, logger) {
                this.$state = $state;
                this.TeamsService = TeamsService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.team = new Team();
                this.teamId = this.$state.params['teamId'] ? +this.$state.params['teamId'] : null;
                this.$onInit();
            }
            TeamsEditController.prototype.$onInit = function () {
                this.isLoading = true;
                this.getSingleTeam(this.teamId);
            };
            TeamsEditController.prototype.getSingleTeam = function (teamId) {
                var _this = this;
                this.TeamsService.getSingleTeam(teamId).then(function (team) {
                    _this.team = new Team(team.data, true);
                    _this.teamName = _this.team.name;
                    _this.initializeTeamsGrid();
                });
            };
            TeamsEditController.prototype.addTeamMember = function (teamId, teamMember) {
                var _this = this;
                this.isLoading = true;
                this.TeamsService.addTeamMember(teamId, teamMember.id).then(function (response) {
                    _this.getSingleTeam(_this.teamId);
                    _this.logger.success(teamMember.name + ' was successfully added to ' + _this.teamName, 'Success');
                });
            };
            TeamsEditController.prototype.removeTeamMember = function (teamId, teamMember) {
                var _this = this;
                this.isLoading = true;
                this.TeamsService.removeTeamMember(teamId, teamMember.id).then(function (response) {
                    _this.getSingleTeam(_this.teamId);
                    _this.logger.success(teamMember.name + ' was successfully removed from ' + _this.teamName, 'Success');
                });
            };
            TeamsEditController.prototype.initializeTeamsGrid = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.teamMembersLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete ' + data.name + ' from this team?'))
                                        .then(function () {
                                        _this.removeTeamMember(_this.teamId, data);
                                    });
                                    return true;
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Default Role', field: 'default_role_name', canSort: true },
                        { title: 'All Roles', field: 'role_names', canSort: true },
                    ],
                };
                this.isLoading = false;
            };
            TeamsEditController.prototype.updateTeam = function (name) {
                var _this = this;
                this.isLoading = true;
                this.TeamsService.updateTeam(this.teamId, name).then(function (response) {
                    _this.getSingleTeam(_this.teamId);
                    _this.logger.success('Team name was successfully updated to ' + response.data.name, 'Success');
                });
            };
            TeamsEditController.prototype.editDisabled = function () {
                if (this.team.name !== this.teamName) {
                    return false;
                }
                else {
                    return true;
                }
            };
            TeamsEditController.prototype.showAddTeamMemberDialog = function ($event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/teams/new-team-member-dialog.html',
                    controller: 'NewTeamMemberDialogController as vm',
                    bindToController: true,
                })
                    .then(function (teamMember) {
                    if (teamMember) {
                        _this.addTeamMember(_this.teamId, teamMember);
                    }
                });
            };
            TeamsEditController.$inject = ['$state', 'TeamsService', '$mdDialog', 'logger'];
            return TeamsEditController;
        }());
        angular.module('consolo').controller('TeamsEditController', TeamsEditController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var TeamsFilter = consolo.domain.TeamsFilters;
        var QueryStringFilter = consolo.domain.QueryStringFilter;
        var Team = consolo.domain.Team;
        var TeamsController = /** @class */ (function (_super) {
            __extends(TeamsController, _super);
            function TeamsController($state, TeamsService, TeamsDataTableService, $mdDialog, $interval, logger, CSV) {
                var _this = _super.call(this) || this;
                _this.$state = $state;
                _this.TeamsService = TeamsService;
                _this.TeamsDataTableService = TeamsDataTableService;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.logger = logger;
                _this.CSV = CSV;
                _this.teams = [];
                _this.importTitle = 'Update Sub Accounts';
                _this.$onInit();
                _this.uploadPath = '/api/v1/teams/import';
                _this.csvFields = ['Team Name', 'Team Sub Account'];
                return _this;
            }
            TeamsController.prototype.$onInit = function () {
                this.isLoading = true;
                this.getAllTeams();
            };
            TeamsController.prototype.getAllTeams = function () {
                var _this = this;
                this.TeamsService.getAllTeams().then(function (teams) {
                    // Instantiates Team objects without users, to allow for accurate quick filter search
                    _this.teams = teams.data.map(function (team) { return new Team(team, false); });
                    _this.initializeTeamsGrid();
                });
            };
            TeamsController.prototype.addNewTeam = function (teamName) {
                var _this = this;
                this.isLoading = true;
                this.TeamsService.addNewTeam(teamName).then(function (response) {
                    _this.isLoading = false;
                    _this.$state.go('teams.edit', { teamId: response.data.id });
                });
            };
            TeamsController.prototype.deleteTeam = function (teamId) {
                var _this = this;
                this.isLoading = true;
                this.TeamsService.deleteTeam(teamId)
                    .then(function (response) {
                    _this.getAllTeams();
                    _this.logger.success(response.data.name + ' was successfully deleted', 'Success');
                })
                    .catch(function (error) {
                    _this.getAllTeams();
                });
            };
            TeamsController.prototype.exportTeamsToCSV = function () {
                this.TeamsService.exportTeamsToCSV();
            };
            TeamsController.prototype.initializeTeamsGrid = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.teamsLayout = {
                    id_field: 'id',
                    options: { hideTextFilter: true },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            // {
                            //   menuItemType: 'default',
                            //   label: 'All Items',
                            //   indentItem: true,
                            //   callbackFn: (data, item) => {
                            //     this.exportTeamsToCSV();
                            //   },
                            // },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete ' + data.name + ' ?'))
                                        .then(function () {
                                        _this.deleteTeam(data.id);
                                    });
                                    return true;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (item, $event) {
                                    _this.$state.go('teams.edit', { teamId: item.id });
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Team Name',
                            field: 'name',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'teams.edit({teamId: $id})',
                        },
                        { title: 'Team Members', field: 'user_count' },
                    ],
                };
                this.isLoading = false;
            };
            TeamsController.prototype.reset = function () {
                this.filters = new TeamsFilter();
                this.applyInitialFiltersIfNeeded();
                this.TeamsDataTableService.params = this.filters;
                this.query();
            };
            TeamsController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.updateFilterQueryStringIfNeeded();
                this.apiInterval = this.$interval(function () {
                    if (_this.TeamsDataTableService.tableControl &&
                        _this.TeamsDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.TeamsDataTableService.tableControl.loadSelectData().finally(function () {
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            TeamsController.prototype.showAddTeamDialog = function ($event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/teams/new-team-dialog.html',
                    controller: 'NewTeamDialogController as vm',
                    bindToController: true,
                })
                    .then(function (teamName) {
                    if (teamName) {
                        _this.addNewTeam(teamName);
                    }
                });
            };
            TeamsController.$inject = [
                '$state',
                'TeamsService',
                'TeamsDataTableService',
                '$mdDialog',
                '$interval',
                'logger',
                'CSV',
            ];
            return TeamsController;
        }(QueryStringFilter));
        angular.module('consolo').controller('TeamsController', TeamsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var TeamsStates = /** @class */ (function () {
            function TeamsStates($stateProvider) {
                $stateProvider
                    .state('teams', {
                    url: '/teams',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/teams/teams.html',
                            controller: 'TeamsController as vm',
                        },
                    },
                })
                    .state('teams.new', {
                    url: '/new',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/teams/teams-edit.html',
                            controller: 'TeamsEditController as vm',
                        },
                    },
                })
                    .state('teams.edit', {
                    url: '/:teamId/edit',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/teams/teams-edit.html',
                            controller: 'TeamsEditController as vm',
                        },
                    },
                });
            }
            TeamsStates.$inject = ['$stateProvider'];
            return TeamsStates;
        }());
        angular.module('consolo').config(TeamsStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var UnrelatedExplanationController = /** @class */ (function () {
            function UnrelatedExplanationController(logger, UnrelatedExplanationService, $window, $mdDialog, CurrentPatient, UnrelatedExplanation, WindowStatus, unrelatedExplanationId) {
                var _this = this;
                this.logger = logger;
                this.UnrelatedExplanationService = UnrelatedExplanationService;
                this.$window = $window;
                this.$mdDialog = $mdDialog;
                this.CurrentPatient = CurrentPatient;
                if (unrelatedExplanationId) {
                    var promise = UnrelatedExplanation.getById(unrelatedExplanationId).then(function (ue) {
                        _this.unrelatedExplanation = ue;
                        ue.readonly =
                            _this.UnrelatedExplanationService.currentUnrelatedExplanation.id ===
                                unrelatedExplanationId
                                ? _this.UnrelatedExplanationService.currentUnrelatedExplanation.readonly
                                : true;
                        _this.UnrelatedExplanationService.currentUnrelatedExplanation = ue;
                    });
                    WindowStatus.readyOnPromise(promise);
                }
                else {
                    UnrelatedExplanation.getNewUnrelatedExplanation(CurrentPatient.patient_id).then(function (ue) {
                        _this.unrelatedExplanation = ue;
                        _this.UnrelatedExplanationService.currentUnrelatedExplanation = ue;
                    });
                }
            }
            UnrelatedExplanationController.prototype.save = function () {
                var _this = this;
                var save = this.unrelatedExplanation.id
                    ? this.unrelatedExplanation.$update()
                    : this.unrelatedExplanation.$save();
                save.then(function (d) {
                    _this.logger.success('Saved');
                    _this.closeDialog(true);
                    _this.unrelatedExplanation.physician_name = _this.unrelatedExplanation.physician.name;
                    _this.UnrelatedExplanationService.add(_this.unrelatedExplanation);
                    _this.UnrelatedExplanationService.addSignature(_this.unrelatedExplanation);
                }, function (e) {
                    console.log(e);
                    _this.errors = e.data;
                });
            };
            UnrelatedExplanationController.prototype.deleteExplanation = function () {
                var _this = this;
                this.unrelatedExplanation.$delete().then(function (unrelatedExplanation) {
                    _this.logger.success('Deleted');
                    _this.closeDialog(true);
                    _this.UnrelatedExplanationService.remove(_this.unrelatedExplanation);
                }, function (e) {
                    console.log(e);
                    _this.errors = e;
                });
            };
            UnrelatedExplanationController.prototype.closeDialog = function (forceRefresh) {
                this.$mdDialog.hide(forceRefresh);
            };
            UnrelatedExplanationController.$inject = [
                'logger',
                'UnrelatedExplanationService',
                '$window',
                '$mdDialog',
                'CurrentPatient',
                'UnrelatedExplanation',
                'WindowStatus',
                'unrelatedExplanationId',
            ];
            return UnrelatedExplanationController;
        }());
        angular
            .module('consolo')
            .controller('UnrelatedExplanationController', UnrelatedExplanationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var UnrelatedExplanation = /** @class */ (function () {
            function UnrelatedExplanation(id, patient_id, effective_date, locked) {
                this.id = id;
                this.patient_id = patient_id;
                this.effective_date = effective_date;
                this.locked = locked;
            }
            return UnrelatedExplanation;
        }());
        domain.UnrelatedExplanation = UnrelatedExplanation;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var UnrelatedExplanationService = /** @class */ (function () {
            function UnrelatedExplanationService(UnrelatedExplanation, $state, PdfGenerator, $filter, $mdDialog, logger, Permissions, AddSignatureService, CurrentPatient, Session) {
                this.UnrelatedExplanation = UnrelatedExplanation;
                this.$state = $state;
                this.PdfGenerator = PdfGenerator;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.Permissions = Permissions;
                this.AddSignatureService = AddSignatureService;
                this.CurrentPatient = CurrentPatient;
                this.Session = Session;
                this.unrelatedExplanations = Array(0);
                this.redirectOnSignature = true;
                this.isPhysician = false;
                this.hasDestroyPermission = this.Permissions.can('destroy', 'unrelated_explanations');
                this.hasShowPermission = this.Permissions.can('show', 'unrelated_explanations');
                this.hasUpdatePermission = this.Permissions.can('update', 'unrelated_explanations');
                var user = this.Session.getUser();
                if (user) {
                    this.user_dashboard_url = user.dashboard_url;
                    this.isPhysician = user.physician !== null && user.physician.id !== null;
                }
            }
            UnrelatedExplanationService.prototype.addSignature = function (unrelatedExplanation) {
                var _this = this;
                if (this.redirectOnSignature) {
                    this.$state.go('patients.unrelated-explanations', {
                        patientId: unrelatedExplanation.patient_id,
                    });
                }
                this.AddSignatureService.addSignature(unrelatedExplanation).then(function () {
                    _this.UnrelatedExplanation.getById(unrelatedExplanation.id).then(function (updatedUnrelatedExplanation) {
                        unrelatedExplanation = updatedUnrelatedExplanation;
                        _this.unrelatedExplanations = [].concat(_this.unrelatedExplanations);
                    });
                });
            };
            UnrelatedExplanationService.prototype.create = function () {
                var _this = this;
                this.UnrelatedExplanation.newUnrelatedExplanation({ patient_id: this.CurrentPatient.patient.id }, function (newExplanation) {
                    _this.currentUnrelatedExplanation = newExplanation;
                    _this.currentUnrelatedExplanation.readonly = false;
                    return _this.modal();
                });
            };
            UnrelatedExplanationService.prototype.createByPatientId = function (id) {
                var _this = this;
                this.UnrelatedExplanation.newUnrelatedExplanation({ patient_id: id }, function (newExplanation) {
                    _this.currentUnrelatedExplanation = newExplanation;
                    _this.currentUnrelatedExplanation.readonly = false;
                    return _this.modal();
                });
            };
            UnrelatedExplanationService.prototype.getById = function (id) {
                var _this = this;
                this.UnrelatedExplanation.getById(id).then(function (ue) {
                    _this.currentUnrelatedExplanation = ue;
                    return ue;
                });
            };
            UnrelatedExplanationService.prototype.updateUnrelatedExplanations = function () {
                var _this = this;
                this.loadingData = true;
                return this.UnrelatedExplanation.query({ patient_id: this.CurrentPatient.patient.id }, function (unrelatedExplanations) {
                    _this.unrelatedExplanations = [].concat(unrelatedExplanations);
                    _this.loadingData = false;
                }).$promise;
            };
            UnrelatedExplanationService.prototype.print = function (unrelatedExplanation, patientId) {
                this.PdfGenerator.printWithoutOptions({ unrelated_explanations: [unrelatedExplanation.id] }, patientId);
            };
            UnrelatedExplanationService.prototype.edit = function (unrelatedExplanation) {
                unrelatedExplanation.readonly = false;
                this.currentUnrelatedExplanation = unrelatedExplanation;
                return this.modal(true);
            };
            UnrelatedExplanationService.prototype.view = function (unrelatedExplanation) {
                unrelatedExplanation.readonly = true;
                this.currentUnrelatedExplanation = unrelatedExplanation;
                return this.modal();
            };
            UnrelatedExplanationService.prototype.destroy = function (unrelatedExplanation) {
                var _this = this;
                this.currentUnrelatedExplanation = unrelatedExplanation;
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    templateUrl: '/y/templates/unrelated-explanations/delete-unrelated-explanation.html',
                    controller: 'UnrelatedExplanationController as vm',
                    bindToController: true,
                    locals: { unrelatedExplanationId: unrelatedExplanation.id },
                })
                    .then(function (val) {
                    if (val) {
                        return _this.updateUnrelatedExplanations();
                    }
                });
            };
            UnrelatedExplanationService.prototype.modal = function (updateOnClose) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    templateUrl: '/y/templates/unrelated-explanations/modal.html',
                    controller: 'UnrelatedExplanationController as vm',
                    bindToController: true,
                    locals: { unrelatedExplanationId: this.currentUnrelatedExplanation.id },
                })
                    .then(function (val) {
                    if (updateOnClose && val) {
                        return _this.updateUnrelatedExplanations();
                    }
                });
            };
            UnrelatedExplanationService.prototype.getEffectiveDatetimeFormatted = function () {
                return this.$filter('date')(this.currentUnrelatedExplanation.effective_datetime, 'MM/dd/yyyy h:mm a');
            };
            UnrelatedExplanationService.prototype.add = function (unrelatedExplanation) {
                this.unrelatedExplanations.unshift(unrelatedExplanation);
                this.unrelatedExplanations = [].concat(this.unrelatedExplanations);
            };
            UnrelatedExplanationService.prototype.remove = function (unrelatedExplanation) {
                var index = this.unrelatedExplanations.indexOf(unrelatedExplanation, 0);
                if (index > -1) {
                    this.unrelatedExplanations.splice(index, 1);
                }
                this.currentUnrelatedExplanation = null;
            };
            UnrelatedExplanationService.$inject = [
                'UnrelatedExplanation',
                '$state',
                'PdfGenerator',
                '$filter',
                '$mdDialog',
                'logger',
                'PermissionsService',
                'AddSignatureService',
                'CurrentPatient',
                'SessionService',
            ];
            return UnrelatedExplanationService;
        }());
        services.UnrelatedExplanationService = UnrelatedExplanationService;
        angular.module('consolo').service('UnrelatedExplanationService', UnrelatedExplanationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('patients.unrelated-explanations', {
                url: '/unrelated_explanations',
                data: { title: 'Unrelated Explanations', lockOpenPatientSidenav: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/unrelated-explanations/unrelated-explanations.html',
                        controller: 'UnrelatedExplanationsController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.unrelated-explanations.view', {
                url: '/:unrelatedExplanationId?layout',
                data: { title: 'Unrelated Explanation', lockOpenPatientSidenav: false },
                views: {
                    'main-content@': {
                        templateUrl: function ($stateParams) {
                            if ($stateParams.layout && $stateParams.layout === 'pdf') {
                                return '/y/templates/unrelated-explanations/print.html';
                            }
                            else {
                                return '/y/templates/unrelated-explanations/unrelated-explanation.html';
                            }
                        },
                        controller: 'UnrelatedExplanationController as vm',
                    },
                },
                resolve: {
                    unrelatedExplanationId: [
                        '$stateParams',
                        function ($stateParams) {
                            if ($stateParams && $stateParams.unrelatedExplanationId) {
                                return $stateParams.unrelatedExplanationId;
                            }
                            else {
                                return null;
                            }
                        },
                    ],
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var UnrelatedExplanationsController = /** @class */ (function () {
            function UnrelatedExplanationsController(currentPatient, $mdDialog, unrelatedExplanationService, $scope, FaxGenerator, WindowStatus) {
                this.currentPatient = currentPatient;
                this.$mdDialog = $mdDialog;
                this.unrelatedExplanationService = unrelatedExplanationService;
                this.$scope = $scope;
                this.FaxGenerator = FaxGenerator;
                var promise = this.unrelatedExplanationService.updateUnrelatedExplanations();
                WindowStatus.readyOnPromise(promise);
                this.loadModel();
            }
            UnrelatedExplanationsController.prototype.loadModel = function () {
                var _this = this;
                this.unrelatedExplanationsTable = {
                    options: { expandCollapseAll: false },
                    header: [
                        {
                            title: 'Effective Datetime',
                            field: 'effective_datetime',
                            header: true,
                            isDatetime: true,
                        },
                        {
                            title: 'Physician',
                            field: 'physician_name',
                            header: true,
                        },
                        {
                            title: 'Signed Date',
                            field: 'signed_date',
                            header: true,
                        },
                        {
                            title: 'Office',
                            field: 'office_name',
                            header: true,
                        },
                        {
                            header: true,
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            showExpandCollapse: false,
                            itemMenuItems: [
                                {
                                    icon: 'image:remove_red_eye',
                                    text: 'View',
                                    callbackFn: function ($event, item) {
                                        return _this.unrelatedExplanationService.view(item);
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.unrelatedExplanationService.hasShowPermission;
                                    },
                                },
                                {
                                    icon: 'image:edit',
                                    text: 'Edit',
                                    callbackFn: function ($event, item) {
                                        _this.unrelatedExplanationService.redirectOnSignature = false;
                                        return _this.unrelatedExplanationService.edit(item);
                                    },
                                    conditionalHideFn: function ($event, item) {
                                        return !_this.unrelatedExplanationService.hasUpdatePermission || item.signed_date;
                                    },
                                },
                                {
                                    icon: 'action:delete',
                                    text: 'Delete',
                                    callbackFn: function ($event, item) {
                                        return _this.unrelatedExplanationService.destroy(item);
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.unrelatedExplanationService.hasDestroyPermission;
                                    },
                                },
                                {
                                    text: 'Fax',
                                    icon: 'content:send',
                                    callbackFn: function ($event, unrelatedExplanation) {
                                        return _this.FaxGenerator.fax(unrelatedExplanation.patient_id, {
                                            unrelated_explanations: [unrelatedExplanation.id],
                                            id: unrelatedExplanation.patient_id,
                                        });
                                    },
                                },
                                {
                                    icon: 'action:print',
                                    text: 'Print',
                                    callbackFn: function ($event, item) {
                                        return _this.unrelatedExplanationService.print(item, item.patient_id);
                                    },
                                    conditionalHideFn: function () {
                                        return !_this.unrelatedExplanationService.hasShowPermission;
                                    },
                                },
                                {
                                    icon: 'action:note_add',
                                    text: 'Add Signature',
                                    callbackFn: function ($event, item) {
                                        return _this.unrelatedExplanationService.addSignature(item);
                                    },
                                    conditionalHideFn: function ($event, item) {
                                        return item.locked;
                                    },
                                },
                            ],
                        },
                    ],
                };
            };
            UnrelatedExplanationsController.$inject = [
                'currentPatient',
                '$mdDialog',
                'UnrelatedExplanationService',
                '$scope',
                'FaxGenerator',
                'WindowStatus',
            ];
            return UnrelatedExplanationsController;
        }());
        angular
            .module('consolo')
            .controller('UnrelatedExplanationsController', UnrelatedExplanationsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var uploads;
    (function (uploads) {
        fileDropDirective.$inject = ['$timeout'];
        function fileDropDirective($timeout) {
            return {
                restrict: 'E',
                scope: { onDrop: '&' },
                link: link,
            };
            function link(scope, element, attrs) {
                element.addClass('file-drop');
                var dropTarget = element[0];
                var leaveTimeout;
                dropTarget.addEventListener('dragenter', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    clearTimeout();
                    element.addClass('active');
                }, false);
                dropTarget.addEventListener('dragleave', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    clearTimeout();
                    leaveTimeout = $timeout(function () {
                        element.removeClass('active');
                    }, 100);
                }, false);
                dropTarget.addEventListener('dragover', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    clearTimeout();
                    element.addClass('active');
                }, false);
                dropTarget.addEventListener('drop', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    element.removeClass('active');
                    var files = fileListToArray(e.dataTransfer.files);
                    $timeout(function () {
                        scope.onDrop({ files: files });
                    });
                }, false);
                function clearTimeout() {
                    if (leaveTimeout) {
                        $timeout.cancel(leaveTimeout);
                        leaveTimeout = null;
                    }
                }
            }
        }
        function fileListToArray(fileList) {
            var a = [];
            for (var i = 0; i < fileList.length; i++) {
                a.push(fileList[i]);
            }
            return a;
        }
        angular.module('consolo').directive('fileDrop', fileDropDirective);
    })(uploads = consolo.uploads || (consolo.uploads = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var uploads;
    (function (uploads) {
        filePickDirective.$inject = ['$timeout'];
        function filePickDirective($timeout) {
            return {
                restrict: 'E',
                scope: { onPick: '&' },
                link: link,
            };
            function link(scope, element, attrs) {
                element.addClass('file-pick');
                element.append(template());
                var picker = element.find('input')[0];
                picker.addEventListener('change', function (e) {
                    var files = fileListToArray(e.target.files);
                    $timeout(function () {
                        scope.onPick({ files: files });
                        picker.value = '';
                    });
                }, false);
            }
        }
        function template() {
            return '<input type="file" multiple="true" />';
        }
        function fileListToArray(fileList) {
            var a = [];
            for (var i = 0; i < fileList.length; i++) {
                a.push(fileList[i]);
            }
            return a;
        }
        angular.module('consolo').directive('filePick', filePickDirective);
    })(uploads = consolo.uploads || (consolo.uploads = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var uploads;
    (function (uploads) {
        var MaxConcurrentUploads = 2;
        var UploadState;
        (function (UploadState) {
            UploadState[UploadState["Queued"] = 0] = "Queued";
            UploadState[UploadState["Uploading"] = 1] = "Uploading";
            UploadState[UploadState["Complete"] = 2] = "Complete";
            UploadState[UploadState["Failed"] = 3] = "Failed";
            UploadState[UploadState["Canceled"] = 4] = "Canceled";
        })(UploadState = uploads.UploadState || (uploads.UploadState = {}));
        var Queue = /** @class */ (function () {
            function Queue(logger) {
                this.logger = logger;
                this.queue = [];
                this.inProgress = [];
            }
            Queue.prototype.push = function (upload) {
                this.queue.push(upload);
                this.startNext();
            };
            Queue.prototype.startNext = function () {
                var _this = this;
                var _loop_1 = function () {
                    var upload = this_1.queue.shift();
                    if (upload.state === UploadState.Canceled) {
                        return "continue";
                    }
                    this_1.inProgress.push(upload);
                    upload.perform().then(function () {
                        _this.cleanup(upload);
                    }, function (e) {
                        if (upload.state !== UploadState.Canceled) {
                            _this.logger.error(e.message, upload.name + " could not be uploaded");
                        }
                        _this.cleanup(upload);
                    });
                };
                var this_1 = this;
                while (this.queue.length > 0 && this.inProgress.length < MaxConcurrentUploads) {
                    _loop_1();
                }
            };
            Queue.prototype.cleanup = function (upload) {
                this.inProgress.splice(this.inProgress.indexOf(upload), 1);
                this.startNext();
            };
            Queue.$inject = ['logger'];
            return Queue;
        }());
        uploads.Queue = Queue;
        angular.module('consolo').service('UploadQueue', Queue);
    })(uploads = consolo.uploads || (consolo.uploads = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var uploads;
    (function (uploads) {
        var S3UploadFactory = /** @class */ (function () {
            function S3UploadFactory($q, $http, XHR) {
                this.$q = $q;
                this.$http = $http;
                this.XHR = XHR;
            }
            S3UploadFactory.prototype.build = function (file) {
                return new S3Upload(this.$q, this.$http, this.XHR, file);
            };
            S3UploadFactory.$inject = ['$q', '$http', 'XHR'];
            return S3UploadFactory;
        }());
        uploads.S3UploadFactory = S3UploadFactory;
        var S3Upload = /** @class */ (function () {
            function S3Upload($q, $http, XHR, file) {
                var _this = this;
                this.$http = $http;
                this.XHR = XHR;
                this.file = file;
                this.state = uploads.UploadState.Queued;
                this.percentComplete = 0;
                this.name = file.name;
                this.type = file.type; // NOTE this mime type is based only on the file extension!
                this.size = file.size;
                this.promise = $q(function (resolve, reject) {
                    _this.resolve = resolve;
                    _this.reject = reject;
                });
            }
            S3Upload.prototype.perform = function () {
                var _this = this;
                this.state = uploads.UploadState.Uploading;
                var filename = encodeURIComponent(this.name);
                var mimeType = encodeURIComponent(this.type);
                this.$http
                    .get("/api/v1/s3_presigned_post?filename=" + filename + "&mime_type=" + mimeType)
                    .then(function (resp) {
                    _this.s3Key = resp.data['fields']['key'];
                    var form = _this.buildForm(resp.data['fields']);
                    _this.xhr = _this.XHR.start(resp.data['url'], form, {
                        onSuccess: function () {
                            _this.onSuccess();
                        },
                        onFailure: function (e) {
                            _this.onFailure(e);
                        },
                        onProgress: function (n) {
                            _this.percentComplete = n;
                        },
                    });
                }, function (resp) {
                    console.log(resp.data);
                    var msg;
                    if (resp.data && resp.data['errors']) {
                        msg = resp.data['errors'].join(', ');
                    }
                    else {
                        msg = "Could not generate a presigned POST (status " + resp.status + ")";
                    }
                    _this.reject(new Error(msg));
                });
                return this.promise;
            };
            S3Upload.prototype.cancel = function () {
                if (this.state === uploads.UploadState.Queued || this.state === uploads.UploadState.Uploading) {
                    this.state = uploads.UploadState.Canceled;
                    this.percentComplete = 0;
                    if (this.xhr) {
                        this.xhr.abort();
                        this.xhr = null;
                    }
                    this.reject(new Error('Canceled'));
                }
            };
            S3Upload.prototype.status = function () {
                return uploads.UploadState[this.state];
            };
            S3Upload.prototype.onSuccess = function () {
                this.state = uploads.UploadState.Complete;
                this.percentComplete = 100;
                this.xhr = null;
                this.resolve();
            };
            S3Upload.prototype.onFailure = function (e) {
                this.state = uploads.UploadState.Failed;
                this.percentComplete = 0;
                this.xhr = null;
                this.reject(e);
            };
            S3Upload.prototype.buildForm = function (fields) {
                var form = new FormData();
                Object.keys(fields).forEach(function (fieldName) {
                    form.append(fieldName, fields[fieldName]);
                });
                form.append('file', this.file);
                return form;
            };
            return S3Upload;
        }());
        uploads.S3Upload = S3Upload;
        angular.module('consolo').service('S3UploadFactory', S3UploadFactory);
    })(uploads = consolo.uploads || (consolo.uploads = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var uploads;
    (function (uploads) {
        // $http doesn't have a very good API for handing uploads
        var XHR = /** @class */ (function () {
            function XHR($timeout) {
                this.$timeout = $timeout;
            }
            XHR.prototype.start = function (url, data, options) {
                var xhr = new XHRWrapper(this.$timeout, url, options);
                xhr.send(data);
                return xhr;
            };
            XHR.$inject = ['$timeout'];
            return XHR;
        }());
        uploads.XHR = XHR;
        var XHRWrapper = /** @class */ (function () {
            function XHRWrapper($timeout, url, options) {
                this.$timeout = $timeout;
                this.url = url;
                this.options = options;
                this.aborted = false;
                this.xhr = new XMLHttpRequest();
            }
            XHRWrapper.prototype.send = function (data) {
                var _this = this;
                this.xhr.onreadystatechange = function (e) {
                    if (_this.xhr.readyState === 4) {
                        _this.$timeout(function () {
                            _this.onComplete();
                        });
                    }
                };
                if (this.xhr.upload) {
                    this.xhr.upload.addEventListener('progress', function (e) {
                        _this.$timeout(function () {
                            _this.onProgress(e);
                        });
                    });
                }
                this.xhr.open('POST', this.url);
                this.xhr.send(data);
            };
            XHRWrapper.prototype.abort = function () {
                this.aborted = true;
                this.xhr.abort();
            };
            XHRWrapper.prototype.onComplete = function () {
                switch (this.xhr.status) {
                    case 200:
                    case 201:
                    case 204:
                        this.options.onSuccess();
                        break;
                    default:
                        if (!this.aborted) {
                            this.options.onFailure(new Error("Upload failed (status " + this.xhr.status + "): " + this.xhr.responseText));
                        }
                        break;
                }
            };
            XHRWrapper.prototype.onProgress = function (e) {
                if (e.lengthComputable && !this.aborted) {
                    var percent = Math.round((e.loaded / e.total) * 100);
                    this.options.onProgress(percent > 100 ? 100 : percent);
                }
            };
            return XHRWrapper;
        }());
        uploads.XHRWrapper = XHRWrapper;
        angular.module('consolo').service('XHR', XHR);
    })(uploads = consolo.uploads || (consolo.uploads = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('forecastResult', {
            bindings: {},
            templateUrl: '/y/templates/vaccinations/forecast-result.html',
            controller: 'ForecastResultController as $ctrl'
        });
        var ForecastResultController = /** @class */ (function () {
            function ForecastResultController(patient, vaccinations, $mdDialog) {
                this.patient = patient;
                this.vaccinations = vaccinations;
                this.$mdDialog = $mdDialog;
                this.readonly = true;
            }
            ForecastResultController.$inject = [
                'patient',
                'vaccinations',
                '$mdDialog'
            ];
            return ForecastResultController;
        }());
        common.ForecastResultController = ForecastResultController;
        angular.module('consolo').controller('ForecastResultController', ForecastResultController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('immunizationRegistryStatus', {
            bindings: {},
            templateUrl: '/y/templates/vaccinations/immunization-registry-status.html',
            controller: 'ImmunizationRegistryStatusController as $ctrl'
        });
        var ImmunizationRegistryStatusController = /** @class */ (function () {
            function ImmunizationRegistryStatusController($state, $mdDialog, logger, VaccinationService, immunizationRegistryStatus) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.VaccinationService = VaccinationService;
                this.immunizationRegistryStatus = immunizationRegistryStatus;
            }
            ImmunizationRegistryStatusController.prototype.save = function () {
                var _this = this;
                return this.VaccinationService.upsertImmunizationRegistryStatus(this.immunizationRegistryStatus).then(function (updatedStatus) {
                    _this.$mdDialog.hide(updatedStatus);
                }).catch(function (errors) {
                    _this.logger.error(errors.data.errors.join(', '));
                    _this.errors = errors.data.errors;
                });
            };
            ImmunizationRegistryStatusController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ImmunizationRegistryStatusController.$inject = [
                '$state',
                '$mdDialog',
                'logger',
                'VaccinationService',
                'immunizationRegistryStatus'
            ];
            return ImmunizationRegistryStatusController;
        }());
        common.ImmunizationRegistryStatusController = ImmunizationRegistryStatusController;
        angular.module('consolo').controller('ImmunizationRegistryStatusController', ImmunizationRegistryStatusController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('vaccinationObservation', {
            bindings: { observation: '<', readonly: '<', vaccination: '<', index: '<' },
            templateUrl: '/y/templates/vaccinations/vaccination-observation.html',
            controller: 'VaccinationObservationController as $ctrl'
        });
        var VaccinationObservationController = /** @class */ (function () {
            function VaccinationObservationController(VaccinationService) {
                this.VaccinationService = VaccinationService;
            }
            VaccinationObservationController.prototype.lookupDocumentType = function (searchText) {
                return this.VaccinationService.selectOptions['vis_barcodes'].filter(function (visBarcode) {
                    return searchText.length === 0 || visBarcode.description.toLowerCase().includes(searchText.toLowerCase());
                });
            };
            VaccinationObservationController.prototype.deleteObservation = function () {
                this.vaccination.observations.splice(this.index, 1);
            };
            VaccinationObservationController.prototype.valueSetsLookup = function (q) {
                var _this = this;
                return this.VaccinationService.isReady().then(function () {
                    return _this.VaccinationService.selectOptions['observation_value_sets'].filter(function (unit) {
                        return q.length === 0 || unit.description.toLowerCase().includes(q.toLowerCase());
                    });
                });
            };
            VaccinationObservationController.$inject = [
                'VaccinationService'
            ];
            return VaccinationObservationController;
        }());
        common.VaccinationObservationController = VaccinationObservationController;
        angular.module('consolo').controller('VaccinationObservationController', VaccinationObservationController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('vaccinationSection', {
            bindings: { readonly: '<', medication: '<' },
            templateUrl: '/y/templates/vaccinations/vaccination-section.html',
            controller: 'VaccinationSectionController as $ctrl',
        });
        var VaccinationSectionController = /** @class */ (function () {
            function VaccinationSectionController($mdDialog, CurrentPatient, VaccinationService, $q, Session) {
                this.$mdDialog = $mdDialog;
                this.CurrentPatient = CurrentPatient;
                this.VaccinationService = VaccinationService;
                this.$q = $q;
                this.Session = Session;
            }
            VaccinationSectionController.prototype.editVaccinationPage = function () {
                var _this = this;
                var selectedVaccine;
                if (this.medication && this.medication.vaccination) {
                    this.VaccinationService.vaccineLookup(this.medication.vaccination.name, this.medication.orderdate, null).then(function (vaccines) {
                        if (vaccines.length > 0) {
                            selectedVaccine = vaccines[0];
                        }
                    }).then(function () {
                        _this.medication.vaccination.vaccine = selectedVaccine || {};
                        _this.$mdDialog.show({
                            templateUrl: '/y/templates/vaccinations/vaccination.html',
                            controller: 'VaccinationController as vm',
                            locals: {
                                patientId: _this.CurrentPatient.patient_id,
                                vaccination: _this.medication.vaccination,
                                readonly: false,
                                onMedication: true
                            }
                        }).then(function (vaccination) {
                            _this.medication.vaccination = vaccination;
                        });
                    });
                }
            };
            VaccinationSectionController.prototype.createVaccinationPage = function () {
                var _this = this;
                var vaccinePromise = this.$q.defer();
                var selectedVaccine;
                if (this.medication && this.medication.ndc) {
                    this.VaccinationService.vaccineLookup(null, this.medication.orderdate, this.medication.ndc).then(function (vaccines) {
                        if (vaccines.length > 0) {
                            selectedVaccine = vaccines[0];
                            vaccinePromise.resolve(_this.populateVaccineFromMed(selectedVaccine));
                        }
                        else {
                            vaccinePromise.resolve(_this.populateVaccineFromMed(null));
                        }
                    });
                }
                else {
                    vaccinePromise.resolve(this.populateVaccineFromMed(null));
                }
                vaccinePromise.promise.then(function (vaccination) {
                    _this.$mdDialog.show({
                        templateUrl: '/y/templates/vaccinations/vaccination.html',
                        controller: 'VaccinationController as vm',
                        locals: {
                            patientId: _this.CurrentPatient.patient_id,
                            vaccination: vaccination,
                            readonly: false,
                            onMedication: true
                        }
                    }).then(function (vaccination) {
                        _this.medication.vaccination = vaccination;
                    });
                });
            };
            VaccinationSectionController.prototype.populateVaccineFromMed = function (vaccine) {
                var newVaccine = {
                    name: vaccine ? vaccine.prop_name : null,
                    start_of_administration: this.medication.orderdate,
                    user_id: this.Session.get().user.id,
                    administration_amount: this.medication.dosage_amount_low,
                    route: this.medication.route_of_admin,
                    medication_id: this.medication.id,
                    patient_id: this.CurrentPatient.patient_id,
                    cvx_code: vaccine ? vaccine.cvx_code : null,
                    ndc: this.medication.ndc,
                    cvx_short_description: vaccine ? vaccine.cvx_short_description : null,
                    labeler_name: vaccine ? vaccine.labeler_name : null,
                    pack_form: vaccine ? vaccine.pack_form : null,
                    product: vaccine ? vaccine.product : null,
                    package: vaccine ? vaccine.package : null,
                    vaccine: vaccine,
                    entered_by_id: this.Session.get().user.id,
                    entered_by: this.Session.get().user
                };
                return newVaccine;
            };
            VaccinationSectionController.$inject = [
                '$mdDialog',
                'CurrentPatient',
                'VaccinationService',
                '$q',
                'SessionService'
            ];
            return VaccinationSectionController;
        }());
        common.VaccinationSectionController = VaccinationSectionController;
        angular.module('consolo').controller('VaccinationSectionController', VaccinationSectionController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('vaccinations', {
            bindings: {},
            templateUrl: '/y/templates/vaccinations/vaccination.html',
            controller: 'VaccinationController as vm'
        });
        var VaccinationController = /** @class */ (function () {
            function VaccinationController($state, $mdDialog, logger, VaccinationService, MedicationService, patientId, vaccination, readonly, onMedication) {
                var _this = this;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.VaccinationService = VaccinationService;
                this.MedicationService = MedicationService;
                this.patientId = patientId;
                this.vaccination = vaccination;
                this.readonly = readonly;
                this.onMedication = onMedication;
                this.isLoading = false;
                if (!vaccination.observations) {
                    vaccination.observations = [];
                }
                else if (Array.isArray(vaccination.observations) === false) {
                    vaccination.observations = [];
                }
                else {
                    vaccination.observations.forEach(function (observation) {
                        _this.setObservationData(observation);
                    });
                }
                if (vaccination.dosage_unit) {
                    this.VaccinationService.isReady().then(function () {
                        _this.VaccinationService.selectOptions['administered_units'].some(function (unit) {
                            if (unit.description.toLowerCase() === vaccination.dosage_unit.toLowerCase()) {
                                vaccination.dosage_unit_selected = unit;
                            }
                        });
                    });
                }
            }
            VaccinationController.prototype.setVaccine = function (vaccine) {
                if (vaccine) {
                    if (vaccine.ndc) {
                        this.vaccination.name = vaccine.prop_name;
                        this.vaccination.cvx_code = vaccine.cvx_code;
                        this.vaccination.cvx_short_description = vaccine.cvx_short_description;
                        this.vaccination.ndc = vaccine.ndc;
                        this.vaccination.pack_form = vaccine.pack_form;
                        this.vaccination.labeler_name = vaccine.labeler_name;
                        this.vaccination.package = vaccine.package;
                    }
                    else {
                        this.vaccination.name = vaccine.description;
                        this.vaccination.cvx_code = vaccine.code;
                        this.vaccination.cvx_short_description = vaccine.description;
                        this.vaccination.ndc = null;
                        this.vaccination.pack_form = null;
                        this.vaccination.labeler_name = null;
                        this.vaccination.package = null;
                    }
                }
            };
            VaccinationController.prototype.administeredUnitsLookup = function (q) {
                var _this = this;
                return this.VaccinationService.isReady().then(function () {
                    return _this.VaccinationService.selectOptions['administered_units'].filter(function (unit) {
                        return unit.description &&
                            (unit.id === 2 || unit.id === 97) && (q.length === 0 ||
                            unit.description.toLowerCase().includes(q.toLowerCase()) ||
                            unit.code.toLowerCase().includes(q.toLowerCase()));
                    });
                });
            };
            VaccinationController.prototype.setObservationData = function (observation) {
                if (observation.observation_identifier) {
                    this.VaccinationService.selectOptions.observation_identifiers.some(function (identifier) {
                        if (identifier.code === observation.observation_identifier.code) {
                            observation.observation_identifier = identifier;
                            return true;
                        }
                    });
                }
                if (observation.observation_value && observation.observation_identifier) {
                    if (observation.observation_identifier.code === '64994-7' && observation.observation_value_type_code === 'CE') {
                        this.VaccinationService.selectOptions.patient_eligibility_categories.some(function (category) {
                            if (category.code === observation.observation_value.code) {
                                observation.observation_value = category;
                                return true;
                            }
                        });
                    }
                    else if (observation.observation_identifier.code === '69764-9' && observation.observation_value_type_code === 'CE') {
                        this.VaccinationService.selectOptions.vis_barcodes.some(function (source) {
                            if (source.code === observation.observation_value.code) {
                                observation.observation_value = source;
                                return true;
                            }
                        });
                    }
                    else {
                        this.VaccinationService.selectOptions.immunization_funding_sources.some(function (source) {
                            if (source.code === observation.observation_value.code) {
                                observation.observation_value = source;
                                return true;
                            }
                        });
                    }
                }
            };
            VaccinationController.prototype.vaccineLookup = function (q, date) {
                var _this = this;
                return this.VaccinationService.vaccineLookup(q, date).then(function (response) {
                    var cvxCodes = _this.VaccinationService.selectOptions['cvx_codes'].filter(function (unit) {
                        return unit.description && (q.length === 0 || unit.description.toLowerCase().includes(q.toLowerCase()));
                    });
                    cvxCodes.forEach(function (code) {
                        code.prop_name = code.description;
                        response.push(code);
                    });
                    return response;
                });
            };
            VaccinationController.prototype.setPhysician = function (physician) {
                this.vaccination.ordered_by = physician;
                if (physician) {
                    this.vaccination.ordered_by_id = physician.id;
                }
                else {
                    this.vaccination.ordered_by_id = null;
                }
            };
            VaccinationController.prototype.addObservation = function () {
                this.vaccination.observations.push({ date: this.vaccination.start_of_administration });
            };
            VaccinationController.prototype.validateObservations = function () {
                var _this = this;
                this.errors = [];
                var index = 1;
                this.vaccination.observations.forEach(function (observation) {
                    if (!observation.observation_identifier) {
                        _this.errors.push('Observation ' + index + ' requires an Identifier to be selected.');
                    }
                    if (!observation.observation_value_type_code) {
                        _this.errors.push('Observation ' + index + ' requires a Value Type to be selected.');
                    }
                    if (!observation.observation_value &&
                        observation.observation_identifier &&
                        ['29768-9', '29769-7'].indexOf(observation.observation_identifier.code) === -1) {
                        _this.errors.push('Observation ' + index + ' requires a Value to be selected.');
                    }
                });
            };
            VaccinationController.prototype.save = function (vaccination) {
                var _this = this;
                this.validateObservations();
                if (this.errors.length === 0) {
                    if (vaccination.id) {
                        this.VaccinationService.updateVaccination(vaccination).then(function () {
                            _this.$mdDialog.hide();
                            _this.logger.success('Vaccination updated.', 'Success:');
                        }).catch(function (error) {
                            _this.errors = error.data.errors;
                            _this.logger.error(error.data.errors.join(', '), 'Error:');
                        });
                    }
                    else {
                        vaccination.patient_id = this.patientId;
                        this.VaccinationService.createVaccination(vaccination).then(function () {
                            _this.$mdDialog.hide();
                            _this.logger.success('Vaccination created.', 'Success:');
                        }).catch(function (errors) {
                            _this.errors = errors.data.errors;
                            _this.logger.error(errors.data.errors.join(', '), 'Error:');
                        });
                    }
                }
                else {
                    this.logger.error(this.errors.join(', '));
                }
            };
            VaccinationController.prototype.complete = function () {
                return this.$mdDialog.hide(this.vaccination);
            };
            VaccinationController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            VaccinationController.$inject = [
                '$state',
                '$mdDialog',
                'logger',
                'VaccinationService',
                'MedicationService',
                'patientId',
                'vaccination',
                'readonly',
                'onMedication'
            ];
            return VaccinationController;
        }());
        common.VaccinationController = VaccinationController;
        angular.module('consolo').controller('VaccinationController', VaccinationController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var VaccinationService = /** @class */ (function () {
            function VaccinationService($http, PermissionsService, MedicationService, options, $q) {
                var _this = this;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.MedicationService = MedicationService;
                this.options = options;
                this.$q = $q;
                this.createPermission = false;
                this.updatePermission = false;
                this.viewPermission = false;
                this.destroyPermission = false;
                this.statusURL = '/api/v1/current_immunization_registry_statuses';
                this.vaccinationURL = '/api/v1/vaccinations';
                this.lookupsReady = this.$q.defer();
                this.createPermission = this.PermissionsService.can('create', 'vaccinations');
                this.updatePermission = this.PermissionsService.can('update', 'vaccinations');
                this.viewPermission = this.PermissionsService.can('show', 'vaccinations');
                this.destroyPermission = this.PermissionsService.can('destroy', 'vaccinations');
                var promiseChain = [];
                this.selectOptions = {};
                [
                    'completion_statuses',
                    'manufacturers',
                    'refusal_reasons',
                    'publicity_codes',
                    'vaccination_routes',
                    'family_relationships',
                    'immunization_registry_statuses',
                    'immunization_information_sources',
                    'administered_units',
                    'action_codes',
                    'substance_manufacturer_names',
                    'substance_refusal_reasons',
                    'administration_sites',
                    'observation_identifiers',
                    'observation_value_types',
                    'patient_eligibility_categories',
                    'observation_value_sets',
                    'vis_barcodes',
                    'immunization_funding_sources',
                    'funding_eligibility_obs_methods',
                    'cvx_codes'
                ].forEach(function (option) {
                    promiseChain.push(_this.options.get(option).$promise.then(function (data) {
                        _this.selectOptions[option] = data;
                        return _this.$q.when(true);
                    }));
                });
                this.selectOptions['yes_no'] = [
                    { id: true, description: 'Yes' },
                    { id: false, description: 'No' }
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.lookupsReady.resolve(true);
                });
            }
            VaccinationService.prototype.isReady = function () {
                return this.lookupsReady.promise.then(function () {
                    return true;
                });
            };
            VaccinationService.prototype.getHl7Data = function (patientId) {
                return this.$http.post(this.vaccinationURL + '/get_hl7', { patient_id: patientId })
                    .then(function (response) {
                    return response.data;
                });
            };
            VaccinationService.prototype.sendHl7Data = function (patientId) {
                return this.$http.post(this.vaccinationURL + '/send_hl7', { patient_id: patientId })
                    .then(function (response) {
                    return response.data;
                });
            };
            VaccinationService.prototype.requestForecast = function (patientId) {
                return this.$http.post(this.vaccinationURL + '/request_forecast', { patient_id: patientId })
                    .then(function (response) {
                    return response.data;
                });
            };
            VaccinationService.prototype.vaccineLookup = function (q, date, ndc) {
                return this.$http({
                    url: "/api/v1/vaccines",
                    method: 'GET',
                    params: { q: q, date: date, ndc: ndc }
                }).then(function (response) {
                    return response.data;
                });
            };
            VaccinationService.prototype.getVaccinations = function (patientId) {
                return this.$http({
                    url: "/api/v1/vaccinations",
                    method: 'GET',
                    params: { patient_id: patientId }
                });
            };
            VaccinationService.prototype.getCurrentImmunizationRegistryStatus = function (patientId) {
                return this.$http.get(this.statusURL, { params: { patient_id: patientId } }).then(function (response) {
                    return response.data;
                });
            };
            VaccinationService.prototype.getVaccination = function (id) {
                return this.$http({
                    url: "api/v1/vaccinations/" + id,
                    method: 'GET'
                });
            };
            VaccinationService.prototype.createVaccination = function (vaccination) {
                return this.$http({
                    url: "/api/v1/vaccinations",
                    method: 'POST',
                    data: vaccination
                });
            };
            VaccinationService.prototype.updateVaccination = function (vaccination) {
                return this.$http({
                    url: "/api/v1/vaccinations/" + vaccination.id,
                    method: 'PATCH',
                    data: vaccination
                });
            };
            VaccinationService.prototype.deleteVaccination = function (id) {
                return this.$http({
                    url: "/api/v1/vaccinations/" + id,
                    method: 'DELETE'
                });
            };
            VaccinationService.prototype.upsertImmunizationRegistryStatus = function (status) {
                if (status.id) {
                    return this.$http.patch(this.statusURL + '/' + status.id, status).then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.statusURL, status).then(function (response) {
                        return response.data;
                    });
                }
            };
            VaccinationService.$inject = [
                '$http',
                'PermissionsService',
                'MedicationService',
                'options',
                '$q'
            ];
            return VaccinationService;
        }());
        services.VaccinationService = VaccinationService;
        angular.module('consolo').service('VaccinationService', VaccinationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('vaccinations', {
            bindings: {},
            templateUrl: '/y/templates/vaccinations/vaccinations.html',
            controller: 'VaccinationsController as vm'
        });
        var VaccinationsController = /** @class */ (function () {
            function VaccinationsController(Session, $state, $mdDialog, logger, currentPatient, VaccinationService, vaccination) {
                var _this = this;
                this.Session = Session;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.currentPatient = currentPatient;
                this.VaccinationService = VaccinationService;
                this.vaccination = vaccination;
                this.isLoading = false;
                var session = Session.get();
                this.currentUser = session.user;
                this.user_dashboard_url = session.user.dashboard_url;
                this.VaccinationService.getCurrentImmunizationRegistryStatus(currentPatient.id).
                    then(function (immunizationRegistryStatus) {
                    _this.currentImmunizationRegistryStatus = immunizationRegistryStatus;
                });
                this.setupLayouts();
                this.getVaccinations();
            }
            VaccinationsController.prototype.getVaccinations = function () {
                var _this = this;
                this.isLoading = true;
                this.VaccinationService.getVaccinations(this.currentPatient.id).then(function (response) {
                    _this.isLoading = false;
                    _this.vaccines = response.data;
                });
            };
            VaccinationsController.prototype.createVaccination = function () {
                var _this = this;
                this.$mdDialog.show({
                    templateUrl: '/y/templates/vaccinations/vaccination.html',
                    controller: 'VaccinationController as vm',
                    locals: {
                        patientId: this.currentPatient.id,
                        vaccination: { entered_by_id: this.currentUser.id, entered_by: this.currentUser, observations: [], start_of_administration: new Date() },
                        readonly: false,
                        onMedication: false
                    },
                    clickOutsideToClose: true
                }).then(function () {
                    _this.getVaccinations();
                });
            };
            VaccinationsController.prototype.editImmunizationRegistryStatus = function () {
                var _this = this;
                var currentImmunizationRegistryStatus = this.currentImmunizationRegistryStatus;
                if (!this.currentImmunizationRegistryStatus) {
                    currentImmunizationRegistryStatus = { patient_id: this.currentPatient.id };
                }
                this.$mdDialog.show({
                    templateUrl: '/y/templates/vaccinations/immunization-registry-status.html',
                    controller: 'ImmunizationRegistryStatusController as $ctrl',
                    locals: {
                        immunizationRegistryStatus: currentImmunizationRegistryStatus
                    },
                    clickOutsideToClose: true
                }).then(function (updatedStatus) {
                    _this.currentImmunizationRegistryStatus = updatedStatus;
                });
            };
            VaccinationsController.prototype.viewVaccination = function (vaccination) {
                this.$mdDialog.show({
                    templateUrl: '/y/templates/vaccinations/vaccination.html',
                    controller: 'VaccinationController as vm',
                    locals: {
                        patientId: this.currentPatient.id,
                        vaccination: vaccination,
                        readonly: true,
                        onMedication: false
                    },
                    clickOutsideToClose: true
                });
            };
            VaccinationsController.prototype.editVaccination = function (vaccination) {
                var _this = this;
                this.$mdDialog.show({
                    templateUrl: '/y/templates/vaccinations/vaccination.html',
                    controller: 'VaccinationController as vm',
                    locals: {
                        patientId: this.currentPatient.id,
                        vaccination: vaccination,
                        readonly: false,
                        onMedication: false
                    }
                }).then(function () {
                    _this.getVaccinations();
                });
            };
            VaccinationsController.prototype.deleteVaccination = function (vaccination) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Delete Vaccination?')
                    .textContent('Delete Vaccination ' + vaccination.id + '?')
                    .ok('Delete')
                    .cancel('Cancel')
                    .clickOutsideToClose(true);
                this.$mdDialog.show(confirm).then(function () {
                    _this.VaccinationService.deleteVaccination(vaccination.id).then(function () {
                        _this.logger.success('Vaccination deleted.', 'Success:');
                        _this.getVaccinations();
                    });
                });
            };
            VaccinationsController.prototype.getHl7Data = function () {
                var _this = this;
                this.isLoading = true;
                this.VaccinationService.getHl7Data(this.currentPatient.id).then(function (data) {
                    _this.hl7Data = data;
                }).finally(function () {
                    _this.isLoading = false;
                });
            };
            VaccinationsController.prototype.sendHl7Data = function () {
                var _this = this;
                this.isLoading = true;
                this.VaccinationService.sendHl7Data(this.currentPatient.id).then(function (data) {
                    _this.logger.success("Submitted: " + data);
                }).catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '));
                    _this.errors = error.data.errors;
                }).finally(function () {
                    _this.isLoading = false;
                });
            };
            VaccinationsController.prototype.requestForecast = function () {
                var _this = this;
                this.isLoading = true;
                this.VaccinationService.requestForecast(this.currentPatient.id).then(function (response) {
                    _this.$mdDialog.show({
                        templateUrl: '/y/templates/vaccinations/forecast-result.html',
                        controller: 'ForecastResultController as $ctrl',
                        locals: {
                            patient: response.patient,
                            vaccinations: response.vaccinations
                        },
                        clickOutsideToClose: true
                    });
                }).catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '));
                    _this.errors = error.data.errors;
                }).finally(function () {
                    _this.isLoading = false;
                });
            };
            VaccinationsController.prototype.setupLayouts = function () {
                var _this = this;
                this.vaccinesLayout = {
                    id_field: 'id',
                    rowHideFn: function (vaccination) {
                        return vaccination.deleted_at && vaccination.deleted_at < new Date();
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (vaccination) {
                                    return _this.viewVaccination(vaccination);
                                }
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (vaccination) {
                                    return _this.editVaccination(vaccination);
                                },
                                conditionalHideFn: function () {
                                    return !_this.VaccinationService.updatePermission;
                                }
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (vaccination) {
                                    _this.deleteVaccination(vaccination);
                                },
                                conditionalHideFn: function () {
                                    return !_this.VaccinationService.destroyPermission;
                                }
                            }
                        ]
                    },
                    columns: [
                        {
                            title: 'Administration Date',
                            field: 'start_of_administration',
                            type: 'date'
                        },
                        {
                            title: 'Vaccine',
                            field: 'name'
                        },
                        {
                            title: 'Administer Amount (Dosage / Units)',
                            field: 'administration_amount'
                        },
                        {
                            title: 'Administration Site',
                            field: 'administration_site'
                        }
                    ]
                };
            };
            VaccinationsController.$inject = [
                'SessionService',
                '$state',
                '$mdDialog',
                'logger',
                'currentPatient',
                'VaccinationService',
                'vaccination'
            ];
            return VaccinationsController;
        }());
        common.VaccinationsController = VaccinationsController;
        angular.module('consolo').controller('VaccinationsController', VaccinationsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var VisitTaskListsController = /** @class */ (function () {
            function VisitTaskListsController($state, patientService, Session, VisitTaskListsService) {
                this.$state = $state;
                this.patientService = patientService;
                this.Session = Session;
                this.VisitTaskListsService = VisitTaskListsService;
                this.recurringTaskSets = [];
                this.patientId = this.$state.params['patientId'];
                this.visitTaskListId = this.$state.params['visitTaskListId'] ? this.$state.params['visitTaskListId'] : null;
                this.init();
            }
            VisitTaskListsController.prototype.init = function () {
                this.getPatient();
                if (this.visitTaskListId) {
                    this.getVisitTaskList();
                }
                else {
                    this.getVisitTaskLists();
                }
                this.user_dashboard_url = this.Session.getUser().dashboard_url;
            };
            VisitTaskListsController.prototype.getPatient = function () {
                var _this = this;
                this.patientService
                    .get(this.patientId)
                    .then(function (response) {
                    _this.patient = response.data;
                })
                    .catch(function (err) { return console.log(err); });
            };
            VisitTaskListsController.prototype.getVisitTaskLists = function () {
                var _this = this;
                this.VisitTaskListsService.getSinglePatientsVisitTaskLists(this.patientId)
                    .then(function (response) {
                    _this.taskLists = response;
                    _this.taskLists.forEach(function (taskList) {
                        _this.fixupTaskList(taskList);
                    });
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            VisitTaskListsController.prototype.getVisitTaskList = function () {
                var _this = this;
                this.VisitTaskListsService.getVisitTaskList(this.patientId, this.visitTaskListId)
                    .then(function (response) {
                    _this.taskLists = response;
                    _this.fixupTaskList(_this.taskLists[0]);
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            VisitTaskListsController.prototype.fixupTaskList = function (taskList) {
                var _this = this;
                var recurringTaskSetIds = taskList.recurring_task_actions.map(function (action) { return action.recurring_task_set_id; });
                recurringTaskSetIds = recurringTaskSetIds.filter(function (a, b) { return recurringTaskSetIds.indexOf(a) === b; });
                recurringTaskSetIds.forEach(function (id) {
                    var recurringTaskActions = taskList.recurring_task_actions.map(function (action) {
                        if (action.recurring_task_set_id === id) {
                            return action;
                        }
                    }).filter(function (action) { return action; });
                    _this.recurringTaskSets.push({
                        set_description: recurringTaskActions[0].set_description,
                        recurring_task_set_id: id,
                        recurring_task_actions: recurringTaskActions,
                        visit_task_list_effective_date: taskList.effective_date
                    });
                });
            };
            VisitTaskListsController.$inject = ['$state', 'patientService', 'SessionService', 'VisitTaskListsService'];
            return VisitTaskListsController;
        }());
        angular.module('consolo').controller('VisitTaskListsController', VisitTaskListsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var VisitTaskListsService = /** @class */ (function () {
            function VisitTaskListsService($http) {
                this.$http = $http;
                this.visitTaskListURL = '/api/v1/visit_task_lists';
            }
            VisitTaskListsService.prototype.getSinglePatientsVisitTaskLists = function (patientId) {
                return this.$http.get(this.visitTaskListURL, {
                    params: { 'patient_id': patientId },
                }).then(function (response) {
                    return response.data;
                });
            };
            VisitTaskListsService.prototype.getVisitTaskList = function (patientId, id) {
                return this.$http.get(this.visitTaskListURL, {
                    params: { 'patient_id': patientId, 'ids[]': [id] },
                }).then(function (response) {
                    return response.data;
                });
            };
            VisitTaskListsService.$inject = ['$http'];
            return VisitTaskListsService;
        }());
        services.VisitTaskListsService = VisitTaskListsService;
        angular.module('consolo').service('VisitTaskListsService', VisitTaskListsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var VisitTaskListsStates = /** @class */ (function () {
            function VisitTaskListsStates($stateProvider) {
                $stateProvider
                    .state('patients.visit-task-lists', {
                    url: '/visit_task_lists',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/visit-task-lists/print.html',
                            controller: 'VisitTaskListsController as vm',
                        },
                    },
                })
                    .state('patients.visit-task-list', {
                    url: '/visit_task_lists/:visitTaskListId',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/visit-task-lists/print.html',
                            controller: 'VisitTaskListsController as vm',
                        },
                    },
                });
            }
            VisitTaskListsStates.$inject = ['$stateProvider'];
            return VisitTaskListsStates;
        }());
        angular.module('consolo').config(VisitTaskListsStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('AddStopPointController', ['$mdDialog', 'ScheduledVisit', 'user', Controller]);
    function Controller($mdDialog, ScheduledVisit, user) {
        var vm = this;
        vm.showLocForm = false;
        vm.user = user;
        vm.visits = ScheduledVisit.query();
        vm.add = add;
        vm.cancel = $mdDialog.cancel;
        function add(id, type) {
            $mdDialog.hide({ destination_id: id, destination_type: type });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('ClinicalTrip', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/clinical_trips/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            today: {
                method: 'GET',
                url: '/api/v1/clinical_trips/today',
            },
            future: {
                method: 'POST',
                url: '/api/v1/clinical_trips',
            },
            unavailable_dates: {
                method: 'GET',
                url: '/api/v1/clinical_trips/unavailable_dates',
                isArray: true,
            },
            order: {
                method: 'PUT',
                url: '/api/v1/clinical_trips/:id/stop_points/order',
                isArray: true,
            },
            send_link: {
                method: 'POST',
                url: '/api/v1/clinical_trips/:id/send_link',
            },
        });
    }
})();

var consolo;
(function (consolo) {
    var visitTracker;
    (function (visitTracker) {
        var VisitTrackerFutureController = /** @class */ (function () {
            function VisitTrackerFutureController($state, ClinicalTrip, $mdDialog, $filter) {
                var _this = this;
                this.$state = $state;
                this.ClinicalTrip = ClinicalTrip;
                this.$mdDialog = $mdDialog;
                this.$filter = $filter;
                this.unavailableDatesPredicate = function (date) {
                    return !_this.unavailableDates.includes(_this.$filter('date')(date, 'yyyy-MM-dd'));
                };
                this.ClinicalTrip.unavailable_dates().$promise.then(function (data) {
                    _this.unavailableDates = data;
                });
            }
            VisitTrackerFutureController.prototype.select = function () {
                var _this = this;
                this.$mdDialog.cancel();
                this.ClinicalTrip.future({ effective_date: this.dateOnly(this.effDate) }).$promise.then(function (result) {
                    _this.$state.go('visit-tracker.trip.plan', { tripId: result.id });
                });
            };
            VisitTrackerFutureController.prototype.closeDialog = function () {
                this.$mdDialog.cancel();
            };
            VisitTrackerFutureController.prototype.dateOnly = function (datetime) {
                return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
            };
            VisitTrackerFutureController.$inject = ['$state', 'ClinicalTrip', '$mdDialog', '$filter'];
            return VisitTrackerFutureController;
        }());
        angular
            .module('consolo')
            .controller('VisitTrackerFutureController', VisitTrackerFutureController);
    })(visitTracker = consolo.visitTracker || (consolo.visitTracker = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('mappableResourceLookup', Directive);
    function Directive() {
        return {
            restrict: 'E',
            scope: { onSelect: '&', user: '=', showLocForm: '=' },
            templateUrl: '/y/templates/visit-tracker/mappable-resource-lookup.html',
            controller: ['$scope', 'SavedLocation', 'cache', 'Lookup', 'Patient', '$timeout', Controller],
            controllerAs: 'lookupVm',
        };
        function Controller($scope, SavedLocation, cache, Lookup, Patient, $timeout) {
            var vm = this;
            vm.hasHome =
                $scope.user.address_1 && $scope.user.city && $scope.user.state && $scope.user.zipcode;
            vm.locErrors = [];
            vm.newLocation = {};
            vm.user = $scope.user;
            vm.selectedPatient = null;
            vm.closeLocForm = closeLocForm;
            vm.openLocForm = openLocForm;
            vm.saveLocation = saveLocation;
            vm.select = select;
            function openLocForm() {
                $scope.showLocForm = vm.showLocForm = true;
            }
            function closeLocForm() {
                $scope.showLocForm = vm.showLocForm = false;
            }
            function saveLocation() {
                vm.locErrors = [];
                SavedLocation.save(vm.newLocation).$promise.then(function (savedLocation) {
                    select(savedLocation.id, 'UserLocation');
                }, function (resp) {
                    vm.locErrors = resp.data.errors;
                });
            }
            function select(id, type) {
                $timeout($scope.onSelect({ id: id, type: type }), 0);
                // Using $timeout seems quite hackish, but was the best way I found:
                // http://stackoverflow.com/questions/24754005/how-to-implement-an-ng-change-for-a-custom-directive
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var visitTracker;
    (function (visitTracker) {
        'use strict';
        var VisitTrackerPlanMainController = /** @class */ (function () {
            function VisitTrackerPlanMainController($mdDialog, RouteManager, StopPoint, user) {
                this.$mdDialog = $mdDialog;
                this.RouteManager = RouteManager;
                this.StopPoint = StopPoint;
                this.user = user;
                this.data = RouteManager.data;
            }
            VisitTrackerPlanMainController.prototype.openStopPointModal = function ($event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    locals: { user: this.user },
                    clickOutsideToClose: true,
                    targetEvent: $event,
                    templateUrl: '/y/templates/visit-tracker/add-stop-point.html',
                    controller: 'AddStopPointController as addVm',
                })
                    .then(function (point) {
                    _this.RouteManager.addStopPoint(point);
                });
            };
            VisitTrackerPlanMainController.$inject = ['$mdDialog', 'RouteManagerService', 'StopPoint', 'user'];
            return VisitTrackerPlanMainController;
        }());
        angular
            .module('consolo')
            .controller('VisitTrackerPlanMainController', VisitTrackerPlanMainController);
    })(visitTracker = consolo.visitTracker || (consolo.visitTracker = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('planPoint', Directive);
    function Directive() {
        return {
            restrict: 'E',
            scope: { point: '=', index: '=', route: '=', moveDown: '&', moveUp: '&', remove: '&' },
            templateUrl: '/y/templates/visit-tracker/plan-point.html',
            controller: ['$scope', 'StopPoint', 'logger', Controller],
            controllerAs: 'stopVm',
        };
    }
    function Controller($scope, StopPoint, logger) {
        var vm = this, index = $scope.index + 1, route = $scope.route;
        vm.point = $scope.point;
        vm.getDirections = getDirections;
        vm.getMapDistance = getMapDistance;
        vm.getStopDistance = getStopDistance;
        vm.getTime = getTime;
        vm.getWarning = getWarning;
        vm.moveDown = $scope.moveDown;
        vm.moveUp = $scope.moveUp;
        vm.remove = $scope.remove;
        vm.update = update;
        vm.getStopLetter = getStopLetter;
        $scope.$watch('route', function () {
            route = $scope.route;
        });
        $scope.$watch('vm.point', function () {
            vm.point = $scope.point;
        });
        function getDirections() {
            return route.stops[index] ? route.stops[index].directions : null;
        }
        function getMapDistance() {
            return route.stops[index] ? route.stops[index].distance : null;
        }
        function getStopDistance() {
            return vm.point
                ? parseFloat(vm.point.raw_mileage)
                    .toFixed(1)
                    .toString() + ' mi'
                : null;
        }
        function getTime() {
            return route.stops[index] ? route.stops[index].time : null;
        }
        function getWarning() {
            return route.stops[index] ? route.stops[index].warning : null;
        }
        function showErrors(resp) {
            logger.error(resp.data.errors.join('. '), 'Error');
        }
        function update() {
            StopPoint.update({ trip_id: vm.point.clinical_trip_id, id: vm.point.id }, vm.point).$promise.catch(showErrors);
        }
        function getStopLetter() {
            var alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
            return alphabet[index].toUpperCase();
        }
    }
})();

var consolo;
(function (consolo) {
    var visitTracker;
    (function (visitTracker) {
        'use strict';
        var VisitTrackerPlanController = /** @class */ (function () {
            function VisitTrackerPlanController($mdDialog, logger, Session, ClinicalTrip, RouteManager, StopPoint, DeviceSettings, user) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.Session = Session;
                this.ClinicalTrip = ClinicalTrip;
                this.RouteManager = RouteManager;
                this.StopPoint = StopPoint;
                this.user = user;
                this.showErrors = function (resp) {
                    _this.logger.error(resp.data.errors.join('. '), 'Error');
                };
                this.calculating = false;
                this.data = RouteManager.data;
                this.data.routeType = Session.get()
                    ? Session.get().agency.visit_tracker_route_type
                    : 'shortest';
                this.showOriginForm = !this.data.trip.origin_latitude;
                var chartSetting = DeviceSettings.getValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode);
                this.tabletCharting = chartSetting ? true : false;
            }
            VisitTrackerPlanController.prototype.moveDown = function (point) {
                this.RouteManager.moveDown(point).catch(this.showErrors);
            };
            VisitTrackerPlanController.prototype.moveUp = function (point) {
                this.RouteManager.moveUp(point).catch(this.showErrors);
            };
            VisitTrackerPlanController.prototype.printRoute = function ($event) {
                window.print();
                // this.$mdDialog.show({
                //   locals: {
                //     stop_points: this.data.trip.stop_points,
                //     route: this.data.route,
                //     trip: this.data.trip
                //   },
                //   templateUrl: '/y/templates/visit-tracker/print.html',
                //   controllerAs: 'vm',
                //   onComplete: () => {
                //     window.print();
                //     this.$mdDialog.hide();
                //   },
                //   controller: ['stop_points', 'route', 'trip', function(stop_points, route, trip) {
                //     this.trip = trip;
                //     this.route = route;
                //     this.stop_points = stop_points;
                //   }]
                // });
            };
            VisitTrackerPlanController.prototype.remove = function (point) {
                this.RouteManager.remove(point).catch(this.showErrors);
            };
            VisitTrackerPlanController.prototype.sendLinkTo = function (email_or_sms) {
                var _this = this;
                this.ClinicalTrip.send_link({ id: this.data.trip.id, to: email_or_sms }).$promise.then(function () {
                    _this.logger.info('Check your device and click on the link.', 'Link sent');
                }, this.showErrors);
            };
            VisitTrackerPlanController.prototype.setOrigin = function (id, type) {
                var _this = this;
                this.RouteManager.setOrigin(id, type).then(function () {
                    _this.showOriginForm = false;
                }, this.showErrors);
            };
            VisitTrackerPlanController.prototype.isFutureEffectiveDate = function (trip) {
                return new Date(trip.effective_date) > new Date();
            };
            VisitTrackerPlanController.$inject = [
                '$mdDialog',
                'logger',
                'SessionService',
                'ClinicalTrip',
                'RouteManagerService',
                'StopPoint',
                'DeviceSettingsService',
                'user',
            ];
            return VisitTrackerPlanController;
        }());
        angular
            .module('consolo')
            .controller('VisitTrackerPlanController', VisitTrackerPlanController);
    })(visitTracker = consolo.visitTracker || (consolo.visitTracker = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var RecentTripsController = /** @class */ (function () {
            function RecentTripsController($state, ClinicalTrip, user, $filter) {
                this.$state = $state;
                this.ClinicalTrip = ClinicalTrip;
                this.user = user;
                this.$filter = $filter;
                this.ngOnInit();
            }
            RecentTripsController.prototype.ngOnInit = function () {
                var start_date = new Date();
                start_date.setDate(new Date().getDate() - 7);
                var end_date = new Date();
                end_date.setDate(new Date().getDate() + 14);
                this.trips = this.ClinicalTrip.query({
                    user_id: this.user.id,
                    start_date: this.dateOnly(start_date),
                    end_date: this.dateOnly(end_date),
                });
            };
            RecentTripsController.prototype.createTrip = function () {
                var _this = this;
                this.ClinicalTrip.today().$promise.then(function (trip) {
                    _this.$state.go('visit-tracker.trip.plan', { tripId: trip.id });
                });
            };
            RecentTripsController.prototype.navigateTo = function (id) {
                this.$state.go('visit-tracker.trip', { tripId: id });
            };
            RecentTripsController.prototype.isCurrent = function (trip_date) {
                var nd = new Date();
                var month = '';
                if (nd.getMonth() + 1 <= 9) {
                    month = ('0' + (nd.getMonth() + 1)).toString();
                }
                var today = nd.getFullYear() + '-' + month + '-' + nd.getDate();
                return trip_date === today;
            };
            RecentTripsController.prototype.dateOnly = function (datetime) {
                return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
            };
            RecentTripsController.prototype.isFutureEffectiveDate = function (trip) {
                return new Date(trip.effective_date) > new Date();
            };
            RecentTripsController.$inject = ['$state', 'ClinicalTrip', 'user', '$filter'];
            return RecentTripsController;
        }());
        angular.module('consolo').controller('RecentTripsController', RecentTripsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var visitTracker;
    (function (visitTracker) {
        var VisitTrackerReportsSidebarController = /** @class */ (function () {
            function VisitTrackerReportsSidebarController() {
            }
            return VisitTrackerReportsSidebarController;
        }());
        angular
            .module('consolo')
            .controller('VisitTrackerReportsSidebarController', VisitTrackerReportsSidebarController);
    })(visitTracker = consolo.visitTracker || (consolo.visitTracker = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var visitTracker;
    (function (visitTracker) {
        var VisitTrackerReportsController = /** @class */ (function () {
            function VisitTrackerReportsController(SidebarService, LogiService) {
                this.SidebarService = SidebarService;
                this.LogiService = LogiService;
                LogiService.getLogiUrl('nav').then(function (resp) {
                    var options = {
                        applicationUrl: resp.data['url'],
                        report: 'DropPage.DashboardButtons',
                        secureKey: resp.data['secure_key'],
                    };
                    EmbeddedReporting.create('logi-report', options);
                });
            }
            VisitTrackerReportsController.prototype.toggleSidebar = function () {
                this.SidebarService.toggle();
            };
            VisitTrackerReportsController.$inject = ['SidebarService', 'LogiService'];
            return VisitTrackerReportsController;
        }());
        angular
            .module('consolo')
            .controller('VisitTrackerReportsController', VisitTrackerReportsController);
    })(visitTracker = consolo.visitTracker || (consolo.visitTracker = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var RouteManagerService = /** @class */ (function () {
            function RouteManagerService($stateParams, ClinicalTrip, StopPoint) {
                var _this = this;
                this.$stateParams = $stateParams;
                this.ClinicalTrip = ClinicalTrip;
                this.StopPoint = StopPoint;
                this.ngOnInit = function (tripId) {
                    _this.data.loading = true;
                    return _this.ClinicalTrip.get({ id: tripId }).$promise.then(function (data) {
                        _this.data.loading = false;
                        _this.data.trip = data;
                        _this._setMapAddresses();
                        _this._setStopCoordinates();
                        _this._setMapBounds();
                        console.log(data);
                        return _this;
                    });
                };
                this.data = {
                    checkAddresses: [],
                    mapAddresses: [],
                    route: { distance: null, time: null, stops: [] },
                    routeType: '',
                    trip: {},
                    loading: true,
                    addressCoordinates: [],
                    mapBounds: new google.maps.LatLngBounds(),
                };
            }
            RouteManagerService.prototype.addStopPoint = function (attrs) {
                var _this = this;
                this.data.loading = true;
                attrs.trip_id = this.data.trip.id;
                return this.StopPoint.save(attrs).$promise.then(function (point) {
                    _this.data.trip.stop_points.push(point);
                    _this._setMapAddresses();
                    _this._setStopCoordinates();
                    _this._setMapBounds();
                    _this.data.loading = false;
                });
            };
            RouteManagerService.prototype.moveDown = function (point) {
                var points = this.data.trip.stop_points;
                var index = points.indexOf(point);
                if (index === points.length - 1) {
                    points.pop();
                    points.unshift(point);
                }
                else {
                    var destPoint = points[index + 1];
                    points[index + 1] = point;
                    points[index] = destPoint;
                }
                this._setMapAddresses();
                return this._updateOrder(points);
            };
            RouteManagerService.prototype.moveUp = function (point) {
                var points = this.data.trip.stop_points;
                var index = points.indexOf(point);
                if (index === 0) {
                    points.shift();
                    points.push(point);
                }
                else {
                    var destPoint = points[index - 1];
                    points[index - 1] = point;
                    points[index] = destPoint;
                }
                this._setMapAddresses();
                return this._updateOrder(points);
            };
            RouteManagerService.prototype.remove = function (point) {
                var _this = this;
                this.data.loading = true;
                return this.StopPoint.remove({ trip_id: point.clinical_trip_id, id: point.id }).$promise.then(function () {
                    _this.data.trip.stop_points = _this.data.trip.stop_points.filter(function (p) {
                        return p.id !== point.id;
                    });
                    _this.moveUp(point);
                    _this._setMapAddresses();
                    _this._setStopCoordinates();
                    _this._setMapBounds();
                    _this.data.loading = false;
                });
            };
            RouteManagerService.prototype.setOrigin = function (id, type) {
                var _this = this;
                this.data.loading = true;
                var attrs = { id: this.data.trip.id, origin_id: id, origin_type: type };
                return this.ClinicalTrip.update(attrs).$promise.then(function (trip) {
                    angular.extend(_this.data.trip, trip);
                    _this._setMapAddresses();
                    _this._setStopCoordinates();
                    _this._setMapBounds();
                    _this.data.loading = false;
                });
            };
            RouteManagerService.prototype._setMapAddresses = function () {
                var origin = this.data.trip.origin_latitude + ',' + this.data.trip.origin_longitude;
                this.data.mapAddresses = [origin].concat(this.data.trip.stop_points.map(this._toAddress));
                this.data.checkAddresses = [origin].concat(this.data.trip.stop_points.map(this._toAddress));
            };
            RouteManagerService.prototype._setStopCoordinates = function () {
                var _this = this;
                var trip = this.data.trip;
                if (trip.origin_latitude && trip.origin_longitude) {
                    this.data.addressCoordinates = trip.stop_points.map(function (point) {
                        return _this._toGoogleMapsCoords(point);
                    });
                }
            };
            RouteManagerService.prototype._setMapBounds = function () {
                var _this = this;
                var trip = this.data.trip;
                if (trip.origin_latitude && trip.origin_longitude) {
                    trip.stop_points.forEach(function (point) {
                        _this.data.mapBounds.extend({
                            lat: parseFloat(point.latitude),
                            lng: parseFloat(point.longitude),
                        });
                    });
                }
            };
            RouteManagerService.prototype._toAddress = function (point) {
                return point.address_1 + ', ' + point.city + ', ' + point.state + ', ' + point.zipcode;
            };
            RouteManagerService.prototype._toCoords = function (point) {
                if (point.latitude && point.longitude) {
                    return point.latitude + ',' + point.longitude;
                }
                else {
                    return this._toAddress(point);
                }
            };
            RouteManagerService.prototype._toGoogleMapsCoords = function (point) {
                return new google.maps.LatLng(parseFloat(point.latitude), parseFloat(point.longitude));
            };
            RouteManagerService.prototype._updateOrder = function (points) {
                var _this = this;
                this.data.loading = true;
                var newOrder = points.map(function (point) {
                    return point.id;
                });
                return this.ClinicalTrip.order({ id: this.data.trip.id }, { ids: newOrder }).$promise.then(function (stop_points) {
                    _this.data.trip.stop_points = stop_points;
                    _this._setStopCoordinates();
                    _this._setMapBounds();
                    _this.data.loading = false;
                });
            };
            RouteManagerService.$inject = ['$stateParams', 'ClinicalTrip', 'StopPoint'];
            return RouteManagerService;
        }());
        services.RouteManagerService = RouteManagerService;
        angular.module('consolo').service('RouteManagerService', RouteManagerService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('RouteController', [
        'GoogleMapsService',
        '$state',
        '$scope',
        '$interval',
        '$timeout',
        'ClinicalTrip',
        'OfflineStore',
        'persistenceStore',
        'SessionTimerService',
        'trip',
        'user',
        'SessionService',
        'offline',
        'DeviceSettingsService',
        Controller,
    ]);
    function Controller(GoogleMapsService, $state, $scope, $interval, $timeout, ClinicalTrip, OfflineStore, persistenceStore, SessionTimer, trip, user, Session, offline, DeviceSettings) {
        var vm = this, offlineStore, pollId, pollInterval = 15000;
        vm.calculating = true;
        vm.legacyOffline = offline;
        vm.ready = offline ? false : true;
        vm.route = { distance: null, time: null, stops: [] };
        vm.trip = trip;
        vm.finish = finish;
        vm.readyNow = readyNow;
        vm.toAddress = toAddress;
        vm.originCoords = originCoords;
        activate();
        /* jshint browser: true */
        function activate() {
            if (vm.legacyOffline) {
                SessionTimer.stop();
            }
            pollId = $interval(pollForUpdates, pollInterval);
            document.addEventListener('visibilitychange', visibilityChange, false);
            $scope.$on('$destroy', destruct);
            console.log(vm.trip);
            recalculateRoute();
        }
        function destruct() {
            if (vm.legacyOffline) {
                SessionTimer.start(3600);
            }
            $interval.cancel(pollId);
            document.removeEventListener('visibilitychange', visibilityChange, false);
        }
        function finish() {
            if (vm.legacyOffline) {
                window.location.href = '/offline/sync';
            }
            else {
                $state.go('visit-tracker.trips');
            }
        }
        function originCoords() {
            return vm.trip.origin_latitude + ',' + vm.trip.origin_longitude;
        }
        function pollForUpdates() {
            if (vm.legacyOffline) {
                pollForLegacyUpdatesOffline();
            }
            else {
                pollForUpdatesOffline();
                pollForUpdatesOnline();
            }
        }
        function pollForUpdatesOffline() {
            var stops = vm.trip.stop_points.reduce(function (a, stop) {
                if (!stop.completed) {
                    a[stop.id] = stop;
                }
                return a;
            }, {});
            if (Object.keys(stops).length === 0) {
                return;
            }
            persistenceStore.forEach('clinical_chart_entry', function (item) {
                var stop = !item.draft && item.model.clinical_trip_stop_point_id
                    ? stops[item.model.clinical_trip_stop_point_id]
                    : null;
                if (stop) {
                    $timeout(function () {
                        stop.completed = true;
                    });
                    delete stops[stop.id];
                }
            });
        }
        function pollForLegacyUpdatesOffline() {
            var incomplete_points = vm.trip.stop_points.filter(function (p) {
                return !p.completed;
            });
            if (!offlineStore) {
                offlineStore = new OfflineStore('user_' + user.id);
            }
            offlineStore.forEach(function (key, contents) {
                incomplete_points.forEach(function (point) {
                    var numMatching = point.buttons.filter(function (b) {
                        return b.url === contents.form_url;
                    }).length;
                    if (numMatching > 0) {
                        point.completed = true;
                    }
                });
            }, false);
        }
        function pollForUpdatesOnline() {
            var refreshList = true;
            if (!vm.trip || !vm.trip.id) {
                return;
            }
            ClinicalTrip.get({ id: vm.trip.id }, function (t) {
                vm.trip.stop_points = t.stop_points.map(function (point) {
                    // It may be completed locally but not yet synced
                    if (!point.completed) {
                        var localPoint = vm.trip.stop_points.filter(function (p) {
                            return p.id === point.id;
                        })[0];
                        if (localPoint && localPoint.completed) {
                            point.completed = true;
                            refreshList = true;
                        }
                    }
                    return point;
                });
            });
            if (refreshList) {
                recalculateRoute();
            }
        }
        function recalculateRoute() {
            vm.calculating = true;
            var googleMapsCoords = [gmapsOriginPoint()].concat(vm.trip.stop_points.map(toGmapsStopPoints));
            GoogleMapsService.getDirections(googleMapsCoords).then(function (result) {
                console.log(result);
                vm.route = GoogleMapsService.getRoute(vm.trip.stop_points.map(toGmapsStopPoints), result.routes[0]);
                vm.calculating = false;
            });
        }
        function readyNow() {
            vm.ready = true;
        }
        function toAddress(point) {
            if (point.latitude && point.longitude && false) {
                // Disable b/c MapQuest mangles lat/lng floats
                return point.latitude + ',' + point.longitude;
            }
            else {
                return point.address_1 + ', ' + point.city + ', ' + point.state + ', ' + point.zipcode;
            }
        }
        function visibilityChange() {
            if (document.hidden) {
                $interval.cancel(pollId);
            }
            else {
                pollForUpdates();
                pollId = $interval(pollForUpdates, pollInterval);
            }
        }
        function gmapsOriginPoint() {
            return new google.maps.LatLng(vm.trip.origin_latitude, vm.trip.origin_longitude); //jshint ignore:line
        }
        function toGmapsStopPoints(point) {
            // return new google.maps.LatLng(point.latitude, point.longitude);
            return point.address_1 + ', ' + point.city + ', ' + point.state + ', ' + point.zipcode;
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('SavedLocation', ['$resource', Factiry]);
    function Factiry($resource) {
        return $resource('/api/v1/saved_locations/:id', null, {
            update: { method: 'PATCH' },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('VisitTrackerSidebarController', [
        '$state',
        'ClinicalTrip',
        '$mdDialog',
        Controller,
    ]);
    function Controller($state, ClinicalTrip, $mdDialog) {
        var vm = this;
        vm.planToday = planToday;
        vm.planFuture = planFuture;
        function planToday() {
            ClinicalTrip.today().$promise.then(function (trip) {
                $state.go('visit-tracker.trip.plan', { tripId: trip.id });
            });
        }
        function planFuture() {
            $mdDialog.show({
                templateUrl: '/y/templates/visit-tracker/future.html',
                controller: 'VisitTrackerFutureController as vm',
                bindToController: true,
                clickOutsideToClose: true,
                locals: { $state: $state },
            });
        }
    }
})();

/* jshint browser: true */
(function () {
    'use strict';
    angular.module('consolo').directive('stopPoint', Directive);
    function Directive() {
        return {
            restrict: 'E',
            scope: {
                point: '=',
                index: '=',
                prevPoint: '=',
                route: '=',
                offline: '=',
            },
            templateUrl: '/y/templates/visit-tracker/stop-point.html',
            controller: ['$scope', '$q', 'SessionService', 'PermissionsService', Controller],
            controllerAs: 'stopVm',
        };
    }
    function Controller($scope, $q, Session, Permissions) {
        var vm = this;
        var index = $scope.index + 1, route = $scope.route, prevPoint = $scope.prevPoint, user;
        vm.directionsVisible = false;
        vm.offline = $scope.offline;
        vm.point = $scope.point;
        vm.point.buttons = null;
        vm.getDirections = getDirections;
        vm.getMapDistance = getMapDistance;
        vm.getStopDistance = getStopDistance;
        vm.getTime = getTime;
        vm.getWarning = getWarning;
        vm.mapUrl = mapUrl;
        vm.toggleDirections = toggleDirections;
        $scope.$watch('route', function () {
            route = $scope.route;
        });
        activate();
        function activate() {
            user = Session.getUser();
            vm.point.buttons = buttons();
        }
        function buttons() {
            var _buttons = [];
            if (vm.point.destination_type === 'Patient') {
                if (Permissions.can('create', 'clinical_charts')) {
                    _buttons.push({
                        url: '/y/patients/' + vm.point.destination_id + '/clinical_chart/new/general',
                        title: 'Chart',
                    });
                }
                if (Permissions.can('create', 'missed_visits')) {
                    _buttons.push({
                        url: '/y/patients/' + vm.point.destination_id + '/missed-visits',
                        title: 'Missed Visit',
                    });
                }
            }
            else if (vm.point.destination_type === 'Office') {
                _buttons.push({
                    url: '/users/' + user.id + '/non_clinical_times/new',
                    title: 'Time Sheet',
                    params: [['non_clinical_time[office_id]', vm.point.destination_id]],
                });
            }
            else if (vm.point.destination_type === 'Service') {
                _buttons.push({
                    url: '/users/' + user.id + '/non_clinical_times/new',
                    title: 'Time Sheet',
                    params: [['non_clinical_time[service_id]', vm.point.destination_id]],
                });
            }
            else if (vm.point.billable) {
                _buttons.push({
                    url: '/users/' + user.id + '/non_clinical_times/new',
                    title: 'Time Sheet',
                });
            }
            else {
                _buttons.push({
                    url: '/clinical_trips/' +
                        vm.point.clinical_trip_id +
                        '/stop_points/' +
                        vm.point.id +
                        '/complete',
                    title: 'Complete',
                });
            }
            for (var i = 0; i < _buttons.length; i++) {
                var button = _buttons[i];
                if (!button.params) {
                    button.params = [];
                }
                button.params.push(['clinical_trip_stop_point_id', vm.point.id]);
                button.params.push(['offline', vm.offline]);
                button.url += '?' + button.params.map(buttonToParam).join('&');
                delete button.params;
            }
            return _buttons;
        }
        function buttonToParam(pair) {
            return pair[0] + '=' + encodeURIComponent(pair[1]);
        }
        function getDirections() {
            return route.stops[index] ? route.stops[index].directions : null;
        }
        function getMapDistance() {
            return route.stops[index] ? route.stops[index].distance : null;
        }
        function getStopDistance() {
            return vm.point
                ? parseFloat(vm.point.raw_mileage)
                    .toFixed(1)
                    .toString() + ' mi'
                : null;
        }
        function getTime() {
            return route.stops[index] ? route.stops[index].time : null;
        }
        function getWarning() {
            return route.stops[index] ? route.stops[index].warning : null;
        }
        /*
         * Returns a platform-appropriate URL for driving directions.
         * iOS: a URL that will open Apple Maps.
         * Android: Google Maps URL. It will ask you if you want to open it with Google Maps or a browser.
         * Other: a valid Google Maps url.
         */
        function mapUrl() {
            var saddr = prevPoint.address_1
                ? [prevPoint.address_1, prevPoint.city, prevPoint.state, prevPoint.zipcode].join(', ')
                : prevPoint.origin_latitude + ',' + prevPoint.origin_longitude;
            var daddr = [vm.point.address_1, vm.point.city, vm.point.state, vm.point.zipcode].join(', ');
            if (navigator.userAgent.match(/i(OS|Pad|Phone|Pod)/)) {
                return ('http://maps.apple.com/?saddr=' +
                    encodeURIComponent(saddr) +
                    '&daddr=' +
                    encodeURIComponent(daddr));
            }
            else {
                return ('http://maps.google.com/?saddr=' +
                    encodeURIComponent(saddr) +
                    '&daddr=' +
                    encodeURIComponent(daddr));
            }
        }
        function toggleDirections() {
            vm.directionsVisible = !vm.directionsVisible;
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('StopPoint', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/clinical_trips/:trip_id/stop_points/:id', { id: '@id', trip_id: '@trip_id' }, {
            update: { method: 'PATCH' },
        });
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('TripController', ['trip', 'DeviceSettingsService', Controller]);
    function Controller(trip, DeviceSettings) {
        var vm = this;
        vm.trip = trip;
        vm.isFutureEffectiveDate = isFutureEffectiveDate;
        var chartSetting = DeviceSettings.getValue(DeviceSettings.pages.cookie, DeviceSettings.settings.charting_tablet_mode);
        vm.tabletCharting = chartSetting ? true : false;
        function isFutureEffectiveDate(trip) {
            return new Date(trip.effective_date) > new Date();
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').config(['$stateProvider', Routes]);
    function Routes($stateProvider) {
        $stateProvider
            .state('visit-tracker', {
            abstract: true,
            url: '/visit_tracker',
            data: { title: 'Visit Tracker' },
            views: {
                sidebar: {
                    templateUrl: '/y/templates/visit-tracker/sidebar.html',
                    controller: 'VisitTrackerSidebarController as vm',
                },
            },
            resolve: {
                authorized: [
                    'RouteAuthService',
                    '$state',
                    function (RouteAuth, $state) {
                        return RouteAuth.checkOrAlert(function (sess) {
                            return $state.next.name === 'visit-tracker.reports'
                                ? sess.user.flags.has_navigator_reports && sess.agency.has_visit_tracker
                                : sess.user.flags.has_visit_tracker && sess.agency.has_visit_tracker;
                        });
                    },
                ],
                user: [
                    'SessionService',
                    function (Session) {
                        return Session.getUser();
                    },
                ],
            },
        })
            .state('visit-tracker.trips', {
            url: '',
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/visit-tracker/recent-trips.html',
                    controller: 'RecentTripsController as vm',
                },
            },
        })
            .state('visit-tracker.trip', {
            url: '/trip/:tripId',
            resolve: {
                trip: [
                    '$stateParams',
                    'ClinicalTrip',
                    function ($params, ClinicalTrip) {
                        return ClinicalTrip.get({ id: $params.tripId }).$promise;
                    },
                ],
            },
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/visit-tracker/trip.html',
                    controller: 'TripController as vm',
                },
            },
        })
            .state('visit-tracker.trip.plan', {
            url: '/plan',
            data: { backAction: 'visit-tracker.trips' },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/visit-tracker/plan.html',
                    controller: 'VisitTrackerPlanController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/visit-tracker/plan-main.html',
                    controller: 'VisitTrackerPlanMainController as vm',
                },
            },
            resolve: {
                RouteManager: [
                    '$stateParams',
                    'RouteManagerService',
                    function ($stateParams, RouteManagerService) {
                        return RouteManagerService.ngOnInit($stateParams.tripId);
                    },
                ],
                user: [
                    'User',
                    function (User) {
                        return User.current().$promise;
                    },
                ],
            },
        })
            .state('visit-tracker.trip.route', {
            url: '/route',
            resolve: {
                trip: [
                    'trip',
                    function (trip) {
                        return trip.$get({ id: trip.id });
                    },
                ],
                offline: function () {
                    return false;
                },
            },
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/visit-tracker/route.html',
                    controller: 'RouteController as vm',
                },
            },
        })
            .state('visit-tracker.trip.offline', {
            url: '/offline',
            resolve: {
                trip: [
                    'trip',
                    function (trip) {
                        return trip.$get({ id: trip.id });
                    },
                ],
                offline: function () {
                    return true;
                },
            },
            views: {
                'main-content@': {
                    templateUrl: '/y/templates/visit-tracker/route.html',
                    controller: 'RouteController as vm',
                },
            },
        })
            .state('visit-tracker.reports', {
            url: '/reports',
            data: { title: 'WellSky Mileage Reports' },
            views: {
                'sidebar@': {
                    templateUrl: '/y/templates/visit-tracker/reports-sidebar.html',
                    controller: 'VisitTrackerReportsSidebarController as vm',
                },
                'main-content@': {
                    templateUrl: '/y/templates/visit-tracker/reports.html',
                    controller: 'VisitTrackerReportsController as vm',
                },
            },
        });
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var WhitelistIpAddressEditController = /** @class */ (function () {
            function WhitelistIpAddressEditController($mdDialog, WhitelistIpAddressService, $scope, logger, whitelistIpAddress) {
                this.$mdDialog = $mdDialog;
                this.WhitelistIpAddressService = WhitelistIpAddressService;
                this.$scope = $scope;
                this.logger = logger;
                this.whitelistIpAddress = whitelistIpAddress;
            }
            WhitelistIpAddressEditController.prototype.save = function () {
                return this.$mdDialog.hide(this.whitelistIpAddress);
            };
            WhitelistIpAddressEditController.prototype.closeDialog = function () {
                this.$mdDialog.cancel();
            };
            WhitelistIpAddressEditController.prototype.validateForm = function () {
                this.validForm = true;
                this.badFormat = false;
                this.emptyDescription = false;
                if (!this.validIpAddress(this.whitelistIpAddress.ip_address)) {
                    this.badFormat = true;
                    this.validForm = false;
                }
                if (!this.whitelistIpAddress.description) {
                    this.emptyDescription = true;
                    this.validForm = false;
                }
                return this.validForm;
            };
            WhitelistIpAddressEditController.prototype.validIpAddress = function (ipAddress) {
                var validIpAddress = true;
                if (ipAddress && !ipAddress.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/)) {
                    validIpAddress = false;
                }
                else {
                    if (ipAddress) {
                        var ipSegments = ipAddress.split('.');
                        if (ipSegments.length !== 4) {
                            validIpAddress = false;
                        }
                        ipSegments.forEach(function (a) {
                            if (a < 0 || a > 255) {
                                validIpAddress = false;
                            }
                        });
                    }
                    else {
                        validIpAddress = false;
                    }
                }
                return validIpAddress;
            };
            WhitelistIpAddressEditController.$inject = [
                '$mdDialog',
                'WhitelistIpAddressService',
                '$scope',
                'logger',
                'whitelistIpAddress',
            ];
            return WhitelistIpAddressEditController;
        }());
        angular
            .module('consolo')
            .controller('WhitelistIpAddressEditController', WhitelistIpAddressEditController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var WhitelistIpAddressService = /** @class */ (function () {
            function WhitelistIpAddressService($mdDialog, Permissions, logger, $http) {
                this.$mdDialog = $mdDialog;
                this.Permissions = Permissions;
                this.logger = logger;
                this.$http = $http;
                this.hasDestroyPermission = this.Permissions.can('index', 'whitelist_ip_addresses');
                this.hasShowPermission = this.Permissions.can('index', 'whitelist_ip_addresses');
                this.hasUpdatePermission = this.Permissions.can('index', 'whitelist_ip_addresses');
                this.hasCreatePermission = this.Permissions.can('index', 'whitelist_ip_addresses');
            }
            WhitelistIpAddressService.prototype.get = function () {
                return this.$http.get('/api/v1/whitelist_ips');
            };
            WhitelistIpAddressService.prototype.delete = function (whitelistIpAddress) {
                return this.$http.delete('/api/v1/whitelist_ips' + '/' + whitelistIpAddress.id);
            };
            WhitelistIpAddressService.prototype.create = function (whitelistIpAddress) {
                return this.$http.post('/api/v1/whitelist_ips', whitelistIpAddress);
            };
            WhitelistIpAddressService.prototype.update = function (whitelistIpAddress) {
                return this.$http.patch('/api/v1/whitelist_ips' + '/' + whitelistIpAddress.id, whitelistIpAddress);
            };
            WhitelistIpAddressService.$inject = ['$mdDialog', 'PermissionsService', 'logger', '$http'];
            return WhitelistIpAddressService;
        }());
        services.WhitelistIpAddressService = WhitelistIpAddressService;
        angular
            .module('consolo')
            .service('WhitelistIpAddressService', WhitelistIpAddressService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var WhitelistIpAddressesListController = /** @class */ (function () {
            function WhitelistIpAddressesListController($mdDialog, WhitelistIpAddressService, $scope, logger) {
                this.$mdDialog = $mdDialog;
                this.WhitelistIpAddressService = WhitelistIpAddressService;
                this.$scope = $scope;
                this.logger = logger;
                this.whitelistIpAddresses = Array(0);
                this.loadingData = false;
                this.loadModel();
                this.refresh();
            }
            WhitelistIpAddressesListController.prototype.refresh = function () {
                var _this = this;
                this.loadingData = true;
                this.WhitelistIpAddressService.get().then(function (result) {
                    _this.whitelistIpAddresses = result.data;
                    _this.loadingData = false;
                });
            };
            WhitelistIpAddressesListController.prototype.loadModel = function () {
                var _this = this;
                this.whitelistIpTable = {
                    id_field: 'id',
                    options: {},
                    columns: [
                        {
                            title: 'IP Address',
                            field: 'ip_address',
                            canSort: true,
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            canSort: true,
                        },
                        {
                            title: 'Created By',
                            field: 'created_by.name',
                        },
                        {
                            title: 'Created At',
                            field: 'created_at',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                icon: 'edit',
                                label: 'Edit',
                                callbackFn: function (item) {
                                    return _this.edit(item);
                                },
                                conditionalHideFn: function () {
                                    return !_this.WhitelistIpAddressService.hasUpdatePermission;
                                },
                            },
                            {
                                icon: 'delete',
                                label: 'Delete',
                                callbackFn: function (item) {
                                    return _this.delete(item);
                                },
                                conditionalHideFn: function () {
                                    return !_this.WhitelistIpAddressService.hasDestroyPermission;
                                },
                            },
                        ],
                    },
                };
            };
            WhitelistIpAddressesListController.prototype.new = function () {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/whitelist-ip-addresses/whitelist-ip-address-edit.html',
                    controller: 'WhitelistIpAddressEditController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { whitelistIpAddress: {} },
                })
                    .then(function (result) {
                    _this.WhitelistIpAddressService.create(result).then(function (e) {
                        _this.refresh();
                        _this.logger.success('Created IP Address.');
                    });
                });
            };
            WhitelistIpAddressesListController.prototype.edit = function (whitelistIp) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    templateUrl: '/y/templates/whitelist-ip-addresses/whitelist-ip-address-edit.html',
                    controller: 'WhitelistIpAddressEditController as vm',
                    bindToController: true,
                    locals: { whitelistIpAddress: whitelistIp },
                })
                    .then(function (result) {
                    _this.WhitelistIpAddressService.update(result).then(function (e) {
                        _this.refresh();
                        _this.logger.success('Updated IP Address.');
                    });
                });
            };
            WhitelistIpAddressesListController.prototype.delete = function (whitelistIp) {
                var _this = this;
                // var logger = this.logger;
                var confirm = this.$mdDialog.confirm({
                    title: 'Confirm Whitelist IP Address Deletion',
                    textContent: 'Are you sure you want to delete this IP Address?',
                    ok: 'Delete',
                    cancel: 'Keep IP Address',
                });
                this.$mdDialog.show(confirm).then(function () {
                    _this.WhitelistIpAddressService.delete(whitelistIp).then(function () {
                        _this.refresh();
                        _this.logger.success('Deleted IP Address.');
                    });
                });
            };
            WhitelistIpAddressesListController.$inject = ['$mdDialog', 'WhitelistIpAddressService', '$scope', 'logger'];
            return WhitelistIpAddressesListController;
        }());
        angular
            .module('consolo')
            .controller('WhitelistIpAddressesListController', WhitelistIpAddressesListController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular.module('consolo').config(Routes);
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider.state('whitelist-ip-addresses', {
                url: '/whitelist_ip_addresses',
                data: { title: 'Whitelist IP Addresses', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/whitelist-ip-addresses/whitelist-ip-addresses-list.html',
                        controller: 'WhitelistIpAddressesListController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'PermissionsService',
                        'SessionService',
                        function (Permissions, Session) {
                            var session = Session.get();
                            return (session.agency.whitelist_ips && Permissions.can('index', 'whitelist_ip_addresses'));
                        },
                    ],
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        'use strict';
        angular
            .module('consolo')
            .config(Routes)
            .run(Run);
        var about;
        Run.$inject = ['AboutService'];
        function Run(AboutService) {
            AboutService.get().then(function (a) {
                about = a;
            });
        }
        Routes.$inject = ['$stateProvider'];
        function Routes($stateProvider) {
            $stateProvider
                .state('patients.medication-administration-records', {
                url: '/medication_administration_records',
                data: { title: 'eMAR', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-administration-record/emar.html',
                        controller: 'EMARController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_administration_records');
                        },
                    ],
                },
            })
                .state('patients.medication-administration-records-history', {
                url: '/medication_administration_records_history',
                data: { title: 'Medication Administration Records History' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-administration-record/history.html',
                        controller: 'MedicationAdministrationRecordsHistoryController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            // Possibilities
                            // medication_administration_records:show
                            // clinical_reports:medication_history_requests
                            // medication_histories:show
                            return RouteAuth.canOrAlert('show', 'medication_administration_records');
                        },
                    ],
                },
            })
                .state('patients.medications.resupply', {
                url: '/resupply',
                data: { title: 'Resupply Medications' },
                views: {
                    'main-content@': {
                        template: '<resupply flex=""></resupply>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('medication-kits', {
                url: '/medication_kits',
                data: { title: 'Medication Kits', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-kits/index.html',
                        controller: 'MedicationKitIndexController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_kits');
                        },
                    ],
                },
            })
                .state('medication-kits/new', {
                url: '/medication_kits/new',
                data: { title: 'Medication Kit Details', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-kits/medication-kit.html',
                        controller: 'MedicationKitController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'medication_kits');
                        },
                    ],
                    buttonTitle: [
                        function () {
                            return 'Create';
                        },
                    ],
                    medicationKit: [
                        function () {
                            return {};
                        },
                    ],
                },
            })
                .state('medication-kits/edit', {
                url: '/medication_kits/:id/edit',
                data: { title: 'Medication Kit Details', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-kits/medication-kit.html',
                        controller: 'MedicationKitController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'medication_kits');
                        },
                    ],
                    buttonTitle: [
                        function () {
                            return 'Update';
                        },
                    ],
                    medicationKit: [
                        '$stateParams',
                        'MedicationKitsService',
                        function ($stateParams, MedicationKitsService) {
                            if (!MedicationKitsService.currentMedicationKit ||
                                MedicationKitsService.currentMedicationKit.id.toString() !== $stateParams.id) {
                                return MedicationKitsService.get($stateParams.id);
                            }
                            else {
                                return MedicationKitsService.currentMedicationKit;
                            }
                        },
                    ],
                },
            })
                .state('medication-kits/view', {
                url: '/medication_kits/:id',
                data: { title: 'Medication Kit Details', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-kits/view.html',
                        controller: 'MedicationKitController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_kits');
                        },
                    ],
                    buttonTitle: [
                        function () {
                            return 'Update';
                        },
                    ],
                    medicationKit: [
                        '$stateParams',
                        'MedicationKitsService',
                        function ($stateParams, MedicationKitsService) {
                            if (!MedicationKitsService.currentMedicationKit ||
                                MedicationKitsService.currentMedicationKit.id !== $stateParams.id) {
                                return MedicationKitsService.get($stateParams.id);
                            }
                            else {
                                return MedicationKitsService.currentMedicationKit;
                            }
                        },
                    ],
                },
            })
                .state('medication-kits/clone', {
                url: '/medication_kits/:id/clone',
                data: { title: 'Medication Kit Details', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-kits/clone.html',
                        controller: 'CloneMedicationKitController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('clone', 'medication_kits');
                        },
                    ],
                    medicationKit: [
                        '$stateParams',
                        'MedicationKitsService',
                        function ($stateParams, MedicationKitsService) {
                            if (!MedicationKitsService.currentMedicationKit ||
                                MedicationKitsService.currentMedicationKit.id !== $stateParams.id) {
                                return MedicationKitsService.get($stateParams.id);
                            }
                            else {
                                return MedicationKitsService.currentMedicationKit;
                            }
                        },
                    ],
                },
            })
                .state('patients.medication-history', {
                url: '/medication_history',
                data: { title: 'Medication History' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-history/index.html',
                        controller: 'MedicationHistoryIndexController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
            })
                .state('patients.medication-fills', {
                url: '/medication_fills',
                data: { title: 'Medication Fills' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/index.html',
                        controller: 'MedicationFillsIndexController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_fills');
                        },
                    ],
                },
            })
                .state('patients.medication-fills.new', {
                url: '/new',
                data: { title: 'New Medication Fill' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/medication-fill.html',
                        controller: 'MedicationFillController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'medication_fills');
                        },
                    ],
                    medicationFill: [
                        'CurrentPatient',
                        function (CurrentPatient) {
                            var id = CurrentPatient.fetchingPatientId > 0
                                ? CurrentPatient.fetchingPatientId
                                : CurrentPatient.patient_id;
                            return { patient_id: id, prescription: true };
                        },
                    ],
                    readonly: [
                        function () {
                            return false;
                        },
                    ],
                },
            })
                .state('patients.medication-history.view', {
                url: '/:medHistoryId',
                data: {},
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-history/view.html',
                        controller: 'MedicationHistoryController as vm',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    medicationHistory: [
                        '$http',
                        '$stateParams',
                        'MedicationHistoryService',
                        function ($http, $stateParams, MedicationHistoryService) {
                            return $http
                                .get('/api/v1/medication_histories/' + $stateParams.medHistoryId)
                                .then(function (response) {
                                return MedicationHistoryService.initializeMedicationHistory(response.data);
                            });
                        },
                    ],
                },
            })
                .state('patients.medication-fills.view', {
                url: '/:medicationFillId',
                data: { title: 'View Medication Fill' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/view.html',
                        controller: 'MedicationFillController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_fills');
                        },
                    ],
                    medicationFill: [
                        '$stateParams',
                        'MedicationFillService',
                        function ($stateParams, MedicationFillService) {
                            return MedicationFillService.getMedicationFill($stateParams.medicationFillId);
                        },
                    ],
                },
            })
                .state('patients.medication-fills.edit', {
                url: '/:medicationFillId/edit',
                data: { title: 'Edit Medication Fill' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/medication-fill.html',
                        controller: 'MedicationFillController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'medication_fills');
                        },
                    ],
                    medicationFill: [
                        '$stateParams',
                        'MedicationFillService',
                        function ($stateParams, MedicationFillService) {
                            return MedicationFillService.getMedicationFill($stateParams.medicationFillId);
                        },
                    ],
                },
            })
                .state('patients.medication-fills.reverse', {
                url: '/:medicationFillId/reverse',
                data: { title: 'Reverse Medication Fill' },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/reverse.html',
                        controller: 'MedicationFillController as $ctrl',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('reversal', 'medication_fills');
                        },
                    ],
                    medicationFill: [
                        '$stateParams',
                        'MedicationFillService',
                        function ($stateParams, MedicationFillService) {
                            return MedicationFillService.getMedicationFill($stateParams.medicationFillId);
                        },
                    ],
                },
            })
                .state('medication-fills', {
                url: '/medication_fills',
                data: { title: 'All Medication Fills', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/index.html',
                        controller: 'MedicationFillIndexController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_fills');
                        },
                    ],
                },
            })
                .state('medication-fills.new', {
                url: '/new',
                data: { title: 'New Medication Fill', hideSidebar: true },
                views: {
                    'main-content@': {
                        templateUrl: '/y/templates/medications/medication-fills/medication-fill.html',
                        controller: 'MedicationFillController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'medication_fills');
                        },
                    ],
                    medicationFill: [
                        function () {
                            return { prescription: true };
                        },
                    ],
                },
            })
                .state('medication-favorites', {
                url: '/medication_favorites',
                data: { title: 'Medication Favorites', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-favorites flex=""></medication-favorites>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_favorites');
                        },
                    ],
                },
            })
                .state('medication-favorites.new', {
                url: '/new?medication',
                data: { title: 'New Medication Favorite', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-favorite readonly="false" flex=""></medication-favorite>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'medication_favorites');
                        },
                    ],
                },
            })
                .state('medication-favorites.view', {
                url: '/:medicationFavoriteId',
                data: { title: 'View Medication Favorite', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-favorite readonly="true" flex=""></medication-favorite>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_favorites');
                        },
                    ],
                },
            })
                .state('medication-favorites.edit', {
                url: '/:medicationFavoriteId/edit',
                data: { title: 'Edit Medication Favorite', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-favorite readonly="false" flex=""></medication-favorite>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'medication_favorites');
                        },
                    ],
                },
            })
                .state('medication-formulary', {
                url: '/medication_formulary',
                data: { title: 'Medication Formulary', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-formulary></medication-formulary>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.MedicationFormularyDataTableService" layout="column" flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_formulary');
                        },
                    ],
                },
            })
                .state('medication-formulary.new', {
                url: '/new',
                data: { title: 'Medication Formulary Entry', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-formulary-entry readonly="false"></medication-formulary-entry>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'medication_formulary');
                        },
                    ],
                },
            })
                .state('medication-formulary.edit', {
                url: '/:medicationFormularyEntryId/edit',
                data: { title: 'Medication Formulary Entry', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-formulary-entry readonly="false"></medication-formulary-entry>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'medication_formulary');
                        },
                    ],
                },
            })
                .state('medication-formulary.view', {
                url: '/:medicationFormularyEntryId',
                data: { title: 'Medication Formulary Entry', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<medication-formulary-entry readonly="true"></medication-formulary-entry>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_formulary');
                        },
                    ],
                },
            })
                .state('patients.formulary-exception-requests', {
                url: '/formulary_exception_requests',
                data: { title: 'Formulary Exception Requests', hideSidebar: false },
                views: {
                    'main-content@': {
                        template: '<formulary-exception-requests><formulary-exeption-requests>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.FormularyExceptionRequestsDataTableService" layout="column"  flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'formulary_exception_requests');
                        },
                    ],
                },
            })
                .state('patients.formulary-exception-requests.review', {
                url: '/:formularyExceptionRequestId/review',
                data: { title: 'Review Formulary Exception Request', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<review-formulary-exception-request readonly="true"></review-formulary-exception-request>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'formulary_exception_requests');
                        },
                    ],
                },
            })
                .state('patients.formulary-exception-requests.new', {
                url: '/new?physicianId',
                data: { title: 'New Formulary Exception Request', hideSidebar: false },
                views: {
                    'main-content@': {
                        template: '<formulary-exception-request readonly="false"></formulary-exception-request>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'formulary_exception_requests');
                        },
                    ],
                },
            })
                .state('formulary-exception-requests', {
                url: '/formulary_exception_requests',
                data: { title: 'Formulary Exception Request', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<formulary-exception-requests></formulary-exception-requests>',
                    },
                    'sidebar@': {
                        template: '<sidebar-filters api-service="$ctrl.FormularyExceptionRequestsDataTableService" layout="column"  flex=""></sidebar-filters>',
                        controller: 'FilterSidebarController as $ctrl',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'formulary_exception_requests');
                        },
                    ],
                },
            })
                .state('compounds', {
                url: '/compounds',
                data: { title: 'Compounds', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<compounds></compounds>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'compounds');
                        },
                    ],
                },
            })
                .state('compounds.new', {
                url: '/new',
                data: { title: 'New Compound', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<compound readonly="false"></compound>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'compounds');
                        },
                    ],
                },
            })
                .state('compounds.edit', {
                url: '/:compoundId/edit',
                data: { title: 'Edit Compound', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<compound readonly="false"></compound>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'compounds');
                        },
                    ],
                },
            })
                .state('compounds.view', {
                url: '/:compoundId',
                data: { title: 'View Compound', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<compound readonly="true"></compound>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'compounds');
                        },
                    ],
                },
            })
                .state('compounds.clone', {
                url: '/:compoundId/clone',
                data: { title: 'Clone Compound', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<compound readonly="false"><compound>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'compounds');
                        },
                    ],
                },
            })
                .state('notes-to-pharmacists', {
                url: '/notes_to_pharmacists',
                data: { title: 'Notes To Pharmacist', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<notes-to-pharmacists></notes-to-pharmacists>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'notes_to_pharmacists');
                        },
                    ],
                },
            })
                .state('notes-to-pharmacists.new', {
                url: '/new',
                data: { title: 'Notes to Pharmacist', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<notes-to-pharmacist readonly="false"></notes-to-pharmacist>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('create', 'notes_to_pharmacists');
                        },
                    ],
                },
            })
                .state('notes-to-pharmacists.edit', {
                url: '/:notesToPharmacistId/edit',
                data: { title: 'Notes to Pharmacist', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<notes-to-pharmacist readonly="false"></notes-to-pharmacist>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('update', 'notes_to_pharmacists');
                        },
                    ],
                },
            })
                .state('notes-to-pharmacists.view', {
                url: '/:notesToPharmacistId',
                data: { title: 'Notes to Pharmacist', hideSidebar: true },
                views: {
                    'main-content@': {
                        template: '<notes-to-pharmacist readonly="true"></notes-to-pharmacist>',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'notes_to_pharmacists');
                        },
                    ],
                },
            })
                .state('patients.medications.view-rxfill', {
                url: '/view-rxfill/:medicationTransactionId',
                data: { title: 'View RxFill Transaction' },
                views: {
                    'main-content@': {
                        template: '<view-rxfill></view-rxfill>',
                    },
                    'sidebar@': {
                        templateUrl: '/y/templates/patients/patient-sidebar.html',
                        controller: 'PatientDashboardSidebarController as vm',
                    },
                },
                resolve: {
                    authorized: [
                        'RouteAuthService',
                        function (RouteAuth) {
                            return RouteAuth.canOrAlert('show', 'medication_fills');
                        },
                    ],
                },
            });
        }
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('AssigneeController', [
        '$stateParams',
        '$filter',
        'Assignee',
        'CurrentPatient',
        'logger',
        'ROLESYMBOL',
        Controller,
    ]);
    function Controller($stateParams, $filter, Assignee, CurrentPatient, logger, ROLESYMBOL) {
        var vm = this, frequencyTypeOriginal, currentAssignmentId;
        vm.assignees = [];
        vm.currentPatient = {};
        vm.frequencyTypes = Assignee.frequencyTypes;
        vm.frequencyType = '';
        vm.patientId = $stateParams.patientId;
        vm.showPhysOrders = false;
        vm.role = ROLESYMBOL;
        vm.addVisit = addVisit;
        vm.appendAssignee = appendAssignee;
        vm.deleteAssignee = deleteAssignee;
        vm.deleteVisit = deleteVisit;
        vm.frequencyTypeChanged = frequencyTypeChanged;
        vm.prependAssignee = prependAssignee;
        vm.saveAll = saveAll;
        vm.setUser = setUser;
        vm.showDatePicker = showDatePicker;
        vm.showDayInput = showDayInput;
        vm.showSpanInput = showSpanInput;
        vm.unselectOthers = unselectOthers;
        activate();
        vm.loadingSpinner = true;
        return vm;
        function activate() {
            vm.currentPatient = CurrentPatient.patient;
            Assignee.query({ role_symbol: vm.role, patient_id: vm.patientId }, function (assignees) {
                if (assignees.length > 0) {
                    currentAssignmentId = assignees[0].assignment_id;
                    vm.frequencyType = vm.frequencyTypes.filter(function (a) {
                        return a.field === assignees[0].frequency_type;
                    })[0];
                    frequencyTypeOriginal = vm.frequencyType;
                    assignees.forEach(function (assignee) {
                        if (assignee.frequency_times.length > 1) {
                            assignee.frequency_times.sort(function (a, b) {
                                return a.date_offset - b.date_offset;
                            });
                        }
                    });
                    vm.assignees = $filter('orderBy')(assignees, '-primary');
                }
                vm.loadingSpinner = false;
            });
        }
        function addVisit(assignee) {
            assignee.frequency_times.push({ date_offset: null, effective_time: null, focused: true });
        }
        function appendAssignee() {
            vm.assignees.push(new Assignee({
                role_symbol: vm.role,
                patient_id: vm.patientId,
                focused: true,
                frequency_type: vm.frequencyType.field,
                frequency_times: [],
                isAdding: true,
            }));
        }
        function prependAssignee() {
            vm.assignees.unshift(new Assignee({
                role_symbol: vm.role,
                patient_id: vm.patientId,
                focused: true,
                frequency_type: vm.frequencyType.field,
                frequency_times: [],
                isAdding: true,
            }));
        }
        function deleteAssignee(assignee) {
            if (!assignee.id || confirm('Are you sure you want to remove this assignee?')) {
                vm.assignees = vm.assignees.filter(function (a) {
                    return a !== assignee;
                });
            }
        }
        function deleteVisit(assignee, idx) {
            if (confirm('Are you sure you want to remove this visit?')) {
                assignee.frequency_times.splice(idx, 1);
            }
        }
        function frequencyTypeChanged() {
            //Have to change it for every assignee. Only one frequency type per area
            if (numVisits() < 1 ||
                confirm('Changing frequency types will remove all existing visits. Do you want to continue?')) {
                vm.assignees.forEach(function (a) {
                    a.frequency_type = vm.frequencyType.field;
                    a.frequency_times = [];
                });
            }
            else {
                vm.frequencyType = frequencyTypeOriginal;
            }
        }
        function numVisits() {
            return vm.assignees
                .map(function (a) {
                return a.frequency_times.length;
            })
                .reduce(function (a, n) {
                return a + n;
            }, 0);
        }
        function saveAll() {
            vm.assignees = vm.assignees.filter(function (item) {
                return item.isAdding !== true;
            });
            Assignee.update({ patient_id: vm.patientId, assignees: vm.assignees }, function (result) {
                if (result[0].assignment_id !== currentAssignmentId) {
                    currentAssignmentId = result[0].assignment_id;
                    vm.showPhysOrders = true;
                }
                logger.success('Saved');
                CurrentPatient.setConfigured(1);
            }, function (resp) {
                logger.error(resp.data.errors.join('. '));
            });
        }
        function setUser(assignee, user) {
            if (user) {
                assignee.assigned_person = user;
                assignee.assigned_person_id = user.id;
                assignee.assigned_person_type = 'User';
                assignee.isAdding = false;
            }
        }
        function showDatePicker() {
            return (vm.frequencyType &&
                (vm.frequencyType.field === 'weekly' || vm.frequencyType.field === 'biweekly'));
        }
        function showDayInput() {
            return vm.frequencyType && vm.frequencyType.field === 'monthly';
        }
        function showSpanInput() {
            return vm.frequencyType && vm.frequencyType.field === 'quarterly';
        }
        function unselectOthers(user) {
            angular.forEach(vm.assignees, function (a) {
                if (user.id !== a.id) {
                    a.primary = false;
                }
            });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Assignee', ['$resource', Model]);
    function Model($resource) {
        var Assignee = $resource('/api/v1/assignments/current/assignees', null, {
            update: {
                method: 'POST',
                isArray: true,
            },
        });
        Assignee.frequencyTypes = [
            { id: 0, name: 'Daily', field: 'daily' },
            {
                id: 1,
                name: 'Weekly',
                field: 'weekly',
                dayOptions: [
                    { id: 0, name: 'Sunday' },
                    { id: 1, name: 'Monday' },
                    { id: 2, name: 'Tuesday' },
                    { id: 3, name: 'Wednesday' },
                    { id: 4, name: 'Thursday' },
                    { id: 5, name: 'Friday' },
                    { id: 6, name: 'Saturday' },
                ],
            },
            {
                id: 2,
                name: 'Bi-Weekly',
                field: 'biweekly',
                dayOptions: [
                    { id: 0, name: 'Week 1 - Sunday' },
                    { id: 1, name: 'Week 1 - Monday' },
                    { id: 2, name: 'Week 1 - Tuesday' },
                    { id: 3, name: 'Week 1 - Wednesday' },
                    { id: 4, name: 'Week 1 - Thursday' },
                    { id: 5, name: 'Week 1 - Friday' },
                    { id: 6, name: 'Week 1 - Saturday' },
                    { id: 7, name: 'Week 2 - Sunday' },
                    { id: 8, name: 'Week 2 - Monday' },
                    { id: 9, name: 'Week 2 - Tuesday' },
                    { id: 10, name: 'Week 2 - Wednesday' },
                    { id: 11, name: 'Week 2 - Thursday' },
                    { id: 12, name: 'Week 2 - Friday' },
                    { id: 13, name: 'Week 1 - Saturday' },
                ],
            },
            { id: 3, name: 'Monthly', field: 'monthly' },
            { id: 4, name: 'Quarterly', field: 'quarterly' },
        ];
        return Assignee;
    }
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('AssignmentHistoryController', [
        '$state',
        '$q',
        '$http',
        'ROLESYMBOL',
        AssignmentHistoryController,
    ]);
    function AssignmentHistoryController($state, $q, $http, ROLESYMBOL) {
        var vm = this;
        vm.history = [];
        vm.selected = [];
        vm.isSelected = isSelected;
        vm.toggle = toggle;
        vm.loadingSpinner = true;
        vm.displayDates = displayDates;
        activate();
        function activate() {
            var patientId = $state.params.patientId;
            return $http
                .get('/api/v1/recurring_task_assignments/history', { params: { patient_id: patientId, role_symbol: ROLESYMBOL } })
                .then(function (resp) {
                vm.history = resp.data;
                vm.loadingSpinner = false;
                if (vm.history.length > 0) {
                    toggle(vm.history[0]);
                }
            });
        }
        function displayDates(sets) {
            var latestReviewedEntry = null;
            var latestCreatedEntry = null;
            var lastReviewed = 'Last Reviewed: ';
            var lastCreated = 'Last Created: ';
            sets.forEach(function (set) {
                if (latestReviewedEntry === null || latestReviewedEntry.updated_at < set.updated_at) {
                    latestReviewedEntry = set;
                }
                set.tasks.forEach(function (task) {
                    if (latestReviewedEntry.updated_at < task.updated_at) {
                        latestReviewedEntry = task;
                    }
                });
                if (latestCreatedEntry === null || latestCreatedEntry.created_at < set.created_at) {
                    latestCreatedEntry = set;
                }
                set.tasks.forEach(function (task) {
                    if (latestCreatedEntry.created_at < task.created_at) {
                        latestCreatedEntry = task;
                    }
                });
            });
            if (latestReviewedEntry) {
                lastReviewed += (latestReviewedEntry.last_updated_by && latestReviewedEntry.last_updated_by.credentials) ?
                    latestReviewedEntry.last_updated_by.credentials + ' ' : '';
                lastReviewed += latestReviewedEntry.last_updated_by ? latestReviewedEntry.last_updated_by.name + ' ' : '';
                lastReviewed += new Date(latestReviewedEntry.updated_at).toLocaleString();
            }
            if (latestCreatedEntry) {
                lastCreated += (latestReviewedEntry.created_by && latestReviewedEntry.created_by.credentials) ? latestReviewedEntry.created_by.credentials + ' ' : '';
                lastCreated += latestReviewedEntry.created_by ? latestReviewedEntry.created_by.name + ' ' : '';
                lastCreated += new Date(latestReviewedEntry.created_at).toLocaleString();
            }
            return lastReviewed;
        }
        function isSelected(item) {
            return vm.selected.indexOf(item) > -1;
        }
        function toggle(item) {
            var idx = vm.selected.indexOf(item);
            if (idx > -1) {
                vm.selected.splice(idx, 1);
            }
            else {
                vm.selected.push(item);
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AssignmentSettingsController = /** @class */ (function () {
            function AssignmentSettingsController($params, logger, $http, currentPatient) {
                var _this = this;
                this.$params = $params;
                this.logger = logger;
                this.$http = $http;
                this.currentPatient = currentPatient;
                this.$http
                    .get('/api/v1/patient_record_defaults/current?patient_id=' + currentPatient.id)
                    .then(function (response) {
                    _this.settings = response.data;
                });
            }
            AssignmentSettingsController.prototype.save = function () {
                var _this = this;
                if (this.settings) {
                    return this.$http
                        .patch('/api/v1/patient_record_defaults/' + this.settings.id, this.settings)
                        .then(function (response) {
                        _this.logger.success('Saved.');
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    });
                }
                else {
                    this.logger.error('There is no Patient Record Default for this patient.');
                }
            };
            AssignmentSettingsController.$inject = ['$stateParams', 'logger', '$http', 'currentPatient'];
            return AssignmentSettingsController;
        }());
        angular
            .module('consolo')
            .controller('AssignmentSettingsController', AssignmentSettingsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('LegacyAssignment', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/hospice_aide_assignments');
    }
})();

var consolo;
(function (consolo) {
    var cpp;
    (function (cpp) {
        'use strict';
        var CarePlanProblemTemplateEditController = /** @class */ (function () {
            function CarePlanProblemTemplateEditController(CppTemplateService, ChartingTemplateService, options, $timeout, $mdDialog, $state, Session, template, readOnly, modal) {
                var _this = this;
                this.CppTemplateService = CppTemplateService;
                this.options = options;
                this.$timeout = $timeout;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.Session = Session;
                this.template = template;
                this.readOnly = readOnly;
                this.modal = modal;
                // private modal: boolean;
                this.base_roles = [];
                this.categories = [];
                this.sections = [];
                this.loadingInterventions = false;
                this.newCategory = false;
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                });
                this.options.get('care_plan_category').$promise.then(function (data) {
                    _this.categories = data;
                });
                // Remove deprecated skin_integrity_assessment (braden)
                this.sections = ChartingTemplateService.getScreens('tablet_optimized').filter(function (screen) { return screen.name !== 'skin_integrity_assessment'; });
                this.title = this.template.id ? 'Edit' : 'Create';
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                if (readOnly) {
                    this.loadingInterventions = true;
                    this.CppTemplateService.get(this.template.id).then(function (t) {
                        _this.loadingInterventions = false;
                        _this.template.intervention_templates = t.intervention_templates;
                    });
                }
            }
            CarePlanProblemTemplateEditController.prototype.$onInit = function () { };
            CarePlanProblemTemplateEditController.prototype.addIntervention = function () {
                this.template.intervention_templates.push({
                    care_plan_template_id: this.template.id,
                    id: null,
                    ordinal: this.template.intervention_templates.length,
                });
            };
            CarePlanProblemTemplateEditController.prototype.removeIntervention = function (index, item, $event) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to remove this intervention?')
                    .ariaLabel('Remove Intervention Confirmation')
                    .targetEvent($event)
                    .ok('Remove Intervention')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    var removedTemplateIndex = _this.template.intervention_templates.indexOf(item);
                    _this.template.intervention_templates.splice(removedTemplateIndex, 1);
                    for (var _i = 0, _a = _this.template.intervention_templates; _i < _a.length; _i++) {
                        var it_1 = _a[_i];
                        if (it_1.ordinal > item.ordinal) {
                            it_1.ordinal -= 1;
                        }
                    }
                });
            };
            CarePlanProblemTemplateEditController.prototype.updateOrder = function (item, val, $event) {
                var newOrdinal = item.ordinal + val;
                var replace = this.template.intervention_templates.filter(function (intervention) { return intervention.ordinal === newOrdinal; })[0];
                replace.ordinal = item.ordinal;
                item.ordinal = newOrdinal;
                this.$timeout(function () {
                    $event.path[4].scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'nearest' });
                });
            };
            CarePlanProblemTemplateEditController.prototype.save = function () {
                var _this = this;
                if (this.newCategory) {
                    this.template.category = this.template.category.trim();
                }
                if (this.template.id) {
                    this.CppTemplateService.update(this.template).then(function () {
                        if (_this.newCategory) {
                            _this.updateCategoryOptions();
                        }
                        _this.$state.go('cppTemplates', null, { reload: true });
                    });
                }
                else {
                    this.CppTemplateService.create(this.template).then(function () {
                        if (_this.newCategory) {
                            _this.updateCategoryOptions();
                        }
                        _this.$state.go('cppTemplates', null, { reload: true });
                    });
                }
            };
            CarePlanProblemTemplateEditController.prototype.edit = function () {
                this.$state.go('cppTemplates.edit', { id: this.template.id });
                this.$mdDialog.hide();
            };
            CarePlanProblemTemplateEditController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            CarePlanProblemTemplateEditController.prototype.updateCategoryOptions = function () {
                this.options.get('care_plan_category', true).$promise.then(function (data) {
                    console.log('care_plan_category', data);
                });
            };
            CarePlanProblemTemplateEditController.prototype.addCategory = function () {
                var _this = this;
                this.$timeout(function () {
                    if (_this.newCategory) {
                        _this.template.category = _this.tempValue;
                        _this.newCategory = false;
                    }
                    else {
                        _this.tempValue = _this.template.category;
                        _this.template.category = '';
                        _this.newCategory = true;
                    }
                });
            };
            CarePlanProblemTemplateEditController.$inject = [
                'CarePlanProblemTemplateService',
                'ChartingTemplateService',
                'options',
                '$timeout',
                '$mdDialog',
                '$state',
                'SessionService',
                'template',
                'readOnly',
                'modal',
            ];
            return CarePlanProblemTemplateEditController;
        }());
        angular
            .module('consolo')
            .controller('CarePlanProblemTemplateEditController', CarePlanProblemTemplateEditController);
    })(cpp = consolo.cpp || (consolo.cpp = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var cpp;
    (function (cpp) {
        'use strict';
        var CarePlanProblemTemplateListController = /** @class */ (function () {
            function CarePlanProblemTemplateListController(cppService, $mdDialog, $state, Session, logger) {
                var _this = this;
                this.cppService = cppService;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.Session = Session;
                this.logger = logger;
                this.cppService.select(true).then(function (data) {
                    _this.templates = data;
                });
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.layout = {
                    id_field: 'id',
                    callbackFn: function (item, $event) {
                        var url = '/y/templates/care-plans/templates/template-edit.html';
                        var dialogOptions = {
                            controller: 'CarePlanProblemTemplateEditController as $ctrl',
                            templateUrl: url,
                            targetEvent: $event,
                            fullscreen: true,
                            clickOutsideToClose: true,
                            locals: { readOnly: true, template: item, modal: true },
                        };
                        _this.$mdDialog.show(dialogOptions);
                    },
                    rowHideFn: function (item) {
                        return item.hideRow;
                    },
                    columns: [
                        { title: 'Name', field: 'name' },
                        { title: 'Roles', field: 'role_names' },
                        { title: 'Category', field: 'category' },
                    ],
                    headerMenu: {
                        csvContents: true,
                        items: [],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (item, $event) {
                                    _this.$state.go('cppTemplates.edit', { id: item.id });
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (item, $event) {
                                    return _this.deleteTemplate(item, $event);
                                },
                            },
                        ],
                    },
                };
            }
            CarePlanProblemTemplateListController.prototype.create = function () {
                this.$state.go('cppTemplates.new');
            };
            CarePlanProblemTemplateListController.prototype.deleteTemplate = function (template, $event) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to delete this template?')
                    .ariaLabel('Delete Template Confirmation')
                    .targetEvent($event)
                    .ok('Delete Template')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.cppService.delete(template).then(function () {
                        template['hideRow'] = true;
                        return true;
                    });
                });
            };
            CarePlanProblemTemplateListController.$inject = [
                'CarePlanProblemTemplateService',
                '$mdDialog',
                '$state',
                'SessionService',
                'logger',
            ];
            return CarePlanProblemTemplateListController;
        }());
        cpp.CarePlanProblemTemplateListController = CarePlanProblemTemplateListController;
        angular
            .module('consolo')
            .controller('CarePlanProblemTemplateListController', CarePlanProblemTemplateListController);
    })(cpp = consolo.cpp || (consolo.cpp = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var cpp;
    (function (cpp) {
        'use strict';
        var CarePlanProblemTemplateService = /** @class */ (function () {
            function CarePlanProblemTemplateService($http, logger) {
                this.$http = $http;
                this.logger = logger;
            }
            CarePlanProblemTemplateService.prototype.get = function (id) {
                return this.$http({ method: 'GET', url: '/api/v1/care_plan_templates/' + id }).then(function (resp) {
                    resp.data['role_names'] = resp.data['roles'].map(function (r) {
                        return r.name;
                    });
                    resp.data['intervention_templates'].forEach(function (t, index) {
                        if (t.ordinal === null || t.ordinal === undefined) {
                            t.ordinal = index;
                        }
                    });
                    return resp.data;
                });
            };
            CarePlanProblemTemplateService.prototype.select = function (headers) {
                return this.$http
                    .get('/api/v1/care_plan_templates', { params: { headers: headers } })
                    .then(function (resp) {
                    var data = resp.data;
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var template = data_1[_i];
                        template.role_names = template.roles.map(function (r) {
                            return r.name;
                        });
                    }
                    return resp.data;
                });
            };
            CarePlanProblemTemplateService.prototype.create = function (template) {
                var _this = this;
                return this.$http
                    .post('/api/v1/care_plan_templates', template)
                    .then(function () {
                    _this.logger.success("Template '" + template.name + "' created.");
                })
                    .catch(function (err) {
                    _this.logger.error(err);
                    throw err;
                });
            };
            CarePlanProblemTemplateService.prototype.update = function (template) {
                var _this = this;
                return this.$http
                    .patch('/api/v1/care_plan_templates/' + template.id, template)
                    .then(function () {
                    _this.logger.success("Template '" + template.name + "' updated.");
                })
                    .catch(function (err) {
                    _this.logger.error(err);
                    throw err;
                });
            };
            CarePlanProblemTemplateService.prototype.delete = function (template) {
                var _this = this;
                return this.$http
                    .delete('/api/v1/care_plan_templates/' + template.id)
                    .then(function () {
                    _this.logger.success("Template '" + template.name + "' deleted.");
                })
                    .catch(function (err) {
                    _this.logger.error(err);
                    throw err;
                });
            };
            CarePlanProblemTemplateService.$inject = ['$http', 'logger'];
            return CarePlanProblemTemplateService;
        }());
        angular
            .module('consolo')
            .service('CarePlanProblemTemplateService', CarePlanProblemTemplateService);
    })(cpp = consolo.cpp || (consolo.cpp = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var ClaimLineItemService = /** @class */ (function () {
            function ClaimLineItemService($http, logger, Session, PermissionsService) {
                this.$http = $http;
                this.logger = logger;
                this.Session = Session;
                this.PermissionsService = PermissionsService;
                this.url = '/api/v1/claim_line_items';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                var session = Session.get();
                this.user_dashboard_url = session.user.dashboard_url;
                this.updatePermission = this.PermissionsService.can('update', 'claim_line_items');
                this.createPermission = this.PermissionsService.can('create', 'claim_line_items');
                this.deletePermission = this.PermissionsService.can('destroy', 'claim_line_items');
                this.viewPermission = this.PermissionsService.can('show', 'claim_line_items');
            }
            ClaimLineItemService.prototype.create = function (claimLineItem) {
                var _this = this;
                return this.$http.post(this.url, claimLineItem).then(function (response) {
                    _this.logger.success('The Claim Line Item has been created successfully.');
                    return response.data;
                });
            };
            ClaimLineItemService.prototype.getByClaimId = function (claimId) {
                return this.$http.get(this.url, { params: { claim_id: claimId } }).then(function (response) {
                    return response.data;
                });
            };
            ClaimLineItemService.prototype.deleteClaimLineItems = function (ids) {
                var _this = this;
                return this.$http.post(this.url + '/delete', { ids: ids }).then(function (response) {
                    _this.logger.success('The Claim Line Items have been removed.');
                    return response.data;
                });
            };
            ClaimLineItemService.prototype.update = function (claimLineItem) {
                var _this = this;
                return this.$http.patch(this.url + '/' + claimLineItem.id, claimLineItem).then(function (response) {
                    _this.logger.success('The Claim Line Item has been updated successfully.');
                    return response.data;
                });
            };
            ClaimLineItemService.$inject = ['$http', 'logger', 'SessionService', 'PermissionsService'];
            return ClaimLineItemService;
        }());
        services.ClaimLineItemService = ClaimLineItemService;
        angular.module('consolo').service('ClaimLineItemService', ClaimLineItemService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClaimLineItemsController = /** @class */ (function () {
            function ClaimLineItemsController(claim, ClaimLineItemService, $mdDialog, $state, ClaimService) {
                var _this = this;
                this.claim = claim;
                this.ClaimLineItemService = ClaimLineItemService;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.ClaimService = ClaimService;
                this.claimLineItems = claim.claim_line_items;
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: true,
                        hideFooter: true,
                        defaultPageSize: 1000,
                    },
                    headerMenu: {
                        items: [
                            {
                                label: 'Delete Selected',
                                icon: 'delete',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    var ids = data.map(function (selectedItem) {
                                        return selectedItem.id;
                                    });
                                    return _this.deleteClaimLineItems(ids);
                                },
                                conditionalHideFn: function () {
                                    return !_this.ClaimLineItemService.deletePermission;
                                },
                                showCount: true,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Revenue Code',
                            field: 'revenue_code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Revenue Description',
                            field: 'revenue_description',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'HCPCS Rates',
                            field: 'hcpcs_rates',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Service Date',
                            field: 'service_date',
                            type: csg.datatable.ColumnTypes.dateOrdered,
                            formatValueFn: function (claimLineItem) {
                                var date = new Date(claimLineItem.service_date);
                                return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
                            },
                        },
                        {
                            title: 'Service Units',
                            field: 'service_units',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Total Charge Amount',
                            field: 'total_charge_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (claimLineItem) {
                                return consolo.common.moneyDisplay(claimLineItem.total_charge_amount);
                            },
                        },
                        {
                            title: 'Covered?',
                            field: 'covered',
                            type: csg.datatable.ColumnTypes.boolean,
                            formatValueFn: function (claimLineItem) {
                                return consolo.common.yesNo(!claimLineItem.non_covered);
                            },
                        },
                        {
                            title: 'Non Covered Amount',
                            field: 'non_covered_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (claimLineItem) {
                                return consolo.common.moneyDisplay(claimLineItem.non_covered_amount);
                            },
                        },
                        {
                            title: 'Procedure Code Description',
                            field: 'procedure_code_description',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (claimLineItem) {
                                    _this.$state.go('patients.claims.view.claim-line-items.edit', {
                                        patientId: claim.patient_id,
                                        claimId: _this.claim.id,
                                        claimLineItemId: claimLineItem.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.ClaimLineItemService.updatePermission;
                                },
                            },
                        ],
                    },
                };
            }
            ClaimLineItemsController.prototype.goToClaim = function () {
                document.location.href = '/claims/' + this.claim.id;
            };
            ClaimLineItemsController.prototype.deleteClaimLineItems = function (ids) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you would like to delete these Line Items?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.ClaimLineItemService.deleteClaimLineItems(ids).then(function (items) {
                        return _this.ClaimService.get(_this.claim.id).then(function (claim) {
                            angular.copy(claim, _this.claim);
                            _this.claimLineItems = _this.claim.claim_line_items;
                        });
                    });
                });
            };
            ClaimLineItemsController.$inject = [
                'claim',
                'ClaimLineItemService',
                '$mdDialog',
                '$state',
                'ClaimService',
            ];
            return ClaimLineItemsController;
        }());
        angular.module('consolo').controller('ClaimLineItemsController', ClaimLineItemsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ClaimLineItemEditController = /** @class */ (function () {
            function ClaimLineItemEditController(claimLineItem, ClaimLineItemService, $state, $filter, $window, revenueCodes, claim, ClaimService) {
                this.claimLineItem = claimLineItem;
                this.ClaimLineItemService = ClaimLineItemService;
                this.$state = $state;
                this.$filter = $filter;
                this.$window = $window;
                this.revenueCodes = revenueCodes;
                this.claim = claim;
                this.ClaimService = ClaimService;
                this.saving = false;
                if (this.claimLineItem) {
                    var count = $filter('filter')(this.revenueCodes, function (revenueCode) {
                        return revenueCode.code === claimLineItem.revenue_code;
                    }).length;
                    if (count === 0) {
                        this.revenueCodes.push({
                            id: claimLineItem.revenue_code,
                            description: claimLineItem.revenue_code,
                            code: claimLineItem.revenue_code,
                        });
                    }
                }
                else {
                    this.claimLineItem = {
                        claim_id: claim.id,
                        service_date: new Date(),
                        office_id: claim.office_id,
                    };
                }
            }
            ClaimLineItemEditController.prototype.upsert = function () {
                var _this = this;
                if (!this.saving) {
                    this.saving = true;
                    var up = this.claimLineItem.id
                        ? this.ClaimLineItemService.update(this.claimLineItem)
                        : this.ClaimLineItemService.create(this.claimLineItem);
                    up.then(function (claimLineItem) {
                        _this.ClaimService.get(_this.claim.id).then(function (claim) {
                            angular.copy(claim, _this.claim);
                            _this.goToSummary();
                        });
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                        _this.saving = false;
                    });
                }
            };
            ClaimLineItemEditController.prototype.goToWiki = function () {
                this.$window.open('https://redmine.consoloservices.com/projects/consolosupport/wiki/claim_line_items');
            };
            ClaimLineItemEditController.prototype.goToSummary = function () {
                this.$state.go('patients.claims.view.claim-line-items', {
                    claimId: this.claimLineItem.claim_id,
                });
            };
            ClaimLineItemEditController.prototype.setCode = function () {
                var _this = this;
                this.revenueCodes.some(function (revenueCode) {
                    if (revenueCode.description === _this.claimLineItem.revenue_description) {
                        _this.claimLineItem.revenue_code = revenueCode.ub92_code;
                        return true;
                    }
                });
            };
            ClaimLineItemEditController.$inject = [
                'claimLineItem',
                'ClaimLineItemService',
                '$state',
                '$filter',
                '$window',
                'revenueCodes',
                'claim',
                'ClaimService',
            ];
            return ClaimLineItemEditController;
        }());
        angular.module('consolo').controller('ClaimLineItemEditController', ClaimLineItemEditController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartingAssessmentPlanSection = /** @class */ (function () {
            function ChartingAssessmentPlanSection() {
                this.bindings = {
                    chartStore: '=',
                    formModel: '=',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/assessment-plan.html';
                this.controller = ChartingAssessmentPlanController;
            }
            return ChartingAssessmentPlanSection;
        }());
        var ChartingAssessmentPlanController = /** @class */ (function () {
            function ChartingAssessmentPlanController(Indicator, CurrentPatient, $mdDialog) {
                this.Indicator = Indicator;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.plan_indicators = [];
            }
            ChartingAssessmentPlanController.prototype.$onInit = function () {
                var _this = this;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                var patient = this.chartStore.model.patient || this.CurrentPatient.patient;
                this.Indicator.loadByPatientId(patient.id).$promise.then(function (data) {
                    _this.indicators = data;
                    _this.indicators.forEach(function (element) {
                        var arr = element.diagnoses.map(function (d) {
                            return d.name;
                        });
                        var icd10s = element.icd10s.map(function (icd) {
                            return icd.code;
                        });
                        element.diagnoses_names = arr.join(', ');
                        element.icds = icd10s.join(', ');
                    });
                });
                this.diagnosisLayout = {
                    options: {
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Diagnosis', field: 'diagnoses_names' },
                        { title: 'ICD10', field: 'icds' },
                        { title: 'Created By', field: 'user_name' },
                        {
                            title: '',
                            field: '_addText',
                            formatValueFn: function () {
                                return 'Add';
                            },
                            callbackFn: function (data) {
                                _this.plan_indicators.push(data);
                                _this.plan_indicators = [].concat(_this.plan_indicators);
                            },
                            type: 'button',
                        },
                    ],
                };
                this.planLayout = {
                    options: {
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Diagnosis', field: 'diagnoses_names' },
                        { title: 'ICD10', field: 'icds' },
                        { title: 'Created By', field: 'user_name' },
                        {
                            title: '',
                            field: 'remove',
                            formatValueFn: function () {
                                return 'Remove';
                            },
                            callbackFn: function (data) {
                                var pos = -1;
                                _this.plan_indicators.forEach(function (ind, idx) {
                                    if (ind.id === data.id) {
                                        pos = idx;
                                    }
                                });
                                if (pos > -1) {
                                    _this.plan_indicators.splice(pos, 1);
                                    _this.plan_indicators = [].concat(_this.plan_indicators);
                                }
                            },
                            type: 'button',
                        },
                    ],
                };
            };
            ChartingAssessmentPlanController.prototype.addDiagnosis = function ($event) {
                this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/indicators/indicator-modal.html',
                    controller: 'IndicatorModalController as $ctrl',
                });
            };
            ChartingAssessmentPlanController.$inject = ['Indicator', 'CurrentPatient', '$mdDialog'];
            return ChartingAssessmentPlanController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingAssessmentPlan', new ChartingAssessmentPlanSection());
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var BaseCustomComponentController = /** @class */ (function () {
            function BaseCustomComponentController($scope) {
                this.$scope = $scope;
            }
            BaseCustomComponentController.prototype.$onInit = function () { };
            BaseCustomComponentController.prototype.saveDraft = function () {
                if (this.callback) {
                    this.$scope.$eval(this.callback);
                }
                this.chartStore.saveDraft();
            };
            BaseCustomComponentController.$inject = ['$scope'];
            return BaseCustomComponentController;
        }());
        controllers.BaseCustomComponentController = BaseCustomComponentController;
        angular
            .module('consolo')
            .controller('BaseCustomComponentController', BaseCustomComponentController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartingBodySite = /** @class */ (function () {
            function ChartingBodySite() {
                this.bindings = {
                    patientId: '@',
                    readonly: '<',
                    ngModel: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/body-site.html';
                this.controller = ChartingBodySiteController;
                this.require = { ngModelCtrl: '?ngModel' };
            }
            return ChartingBodySite;
        }());
        var ChartingBodySiteController = /** @class */ (function () {
            function ChartingBodySiteController($scope, $elem, $attrs, $timeout, $interval, options, cache, BodySite) {
                var _this = this;
                this.$scope = $scope;
                this.$elem = $elem;
                this.$attrs = $attrs;
                this.$timeout = $timeout;
                this.$interval = $interval;
                this.options = options;
                this.cache = cache;
                this.BodySite = BodySite;
                this.bodySites = [];
                this.bodySiteXChange = function (newVal, oldVal) {
                    _this.adjust();
                    if (newVal !== oldVal) {
                        _this.ngModelCtrl.$setDirty();
                    }
                };
            }
            ChartingBodySiteController.prototype.$onInit = function () {
                var _this = this;
                this.chart = this.ngModel;
                this.image = this.$elem.find('img')[0];
                this.cache(this.BodySite, 'body_site', { encrypt: true, force: true })
                    .query({ patient_id: parseInt(this.patientId, 10) })
                    .$promise.then(function (data) {
                    _this.bodySites = [];
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var site = data_1[_i];
                        site.site_description = site.location + ' - ' + site.location_type_description;
                        if (site.resolved) {
                            if (site.id === _this.chart.body_site_id) {
                                _this.bodySites.push(site);
                            }
                        }
                        else {
                            _this.bodySites.push(site);
                        }
                    }
                });
                this.locationTypes = this.options.get('location_types');
                this.readonly = this.readonly;
                this.selectedBodySite = null;
                if (this.chart.body_site_id) {
                    this.selectedBodySite = this.chart.body_site;
                }
                this.$scope.$watch('$ctrl.chart.body_site.x_offset', this.bodySiteXChange);
            };
            ChartingBodySiteController.prototype.$onDestroy = function () {
                if (this.imageInterval) {
                    this.$interval.cancel(this.imageInterval);
                    this.imageInterval = null;
                }
            };
            ChartingBodySiteController.prototype.adjust = function () {
                var _this = this;
                if (!this.chart.body_site) {
                    return;
                }
                if (!this.image || !this.image.naturalWidth) {
                    if (!this.imageInterval) {
                        this.imageInterval = this.$interval(function () { return _this.adjust(); }, 250, 20);
                    }
                    return;
                }
                else if (this.imageInterval) {
                    this.$interval.cancel(this.imageInterval);
                    this.imageInterval = null;
                }
                var p = this.image.width / this.image.naturalWidth;
                var y_offset = (this.chart.body_site.y_offset * p).toFixed();
                var x_offset = (this.chart.body_site.x_offset * p).toFixed();
                this.imageStyle = {
                    top: y_offset.toString() + 'px',
                    left: x_offset.toString() + 'px',
                };
            };
            ChartingBodySiteController.prototype.movePoint = function (e) {
                if (this.readonly) {
                    return;
                }
                if (!this.chart.body_site) {
                    this.chart.body_site = {};
                    this.chart.body_site.patient_id = this.patientId;
                }
                else if (!this.chart.body_site.patient_id) {
                    this.chart.body_site.patient_id = this.patientId;
                }
                if (!this.chart.body_site.id) {
                    var iconAdj = 11;
                    if (this.image.width === this.image.naturalWidth &&
                        this.image.height === this.image.naturalHeight) {
                        this.chart.body_site.y_offset = (e.layerY - iconAdj).toFixed();
                        this.chart.body_site.x_offset = (e.layerX - iconAdj).toFixed();
                    }
                    else {
                        this.chart.body_site.y_offset = (e.layerY * (this.image.naturalHeight / this.image.height) -
                            iconAdj).toFixed();
                        this.chart.body_site.x_offset = (e.layerX * (this.image.naturalWidth / this.image.width) -
                            iconAdj).toFixed();
                    }
                }
                this.adjust();
            };
            ChartingBodySiteController.prototype.clearBodySite = function () {
                delete this.ngModel.body_site;
                this.chart.body_site_id = null;
            };
            ChartingBodySiteController.prototype.newBodySite = function () {
                this.clearBodySite();
                this.chart.body_site = { id: null, patient_id: this.patientId };
            };
            ChartingBodySiteController.prototype.selectBodySite = function () {
                var _this = this;
                this.$timeout(function () {
                    if (_this.selectedBodySite) {
                        _this.chart.body_site = _this.selectedBodySite;
                        _this.chart.body_site_id = _this.selectedBodySite.id;
                    }
                }, 1000);
            };
            ChartingBodySiteController.$inject = [
                '$scope',
                '$element',
                '$attrs',
                '$timeout',
                '$interval',
                'options',
                'cache',
                'BodySite',
            ];
            return ChartingBodySiteController;
        }());
        angular.module('consolo').component('consoloBodySite', new ChartingBodySite());
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ChartingCarePlanProblem = /** @class */ (function () {
            function ChartingCarePlanProblem() {
                this.bindings = {
                    chartStore: '=',
                    formModel: '=',
                    viewOnly: '<',
                    isDiscontinue: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/care-plan-problem.html';
                this.controller = ChartingCarePlanProblemSectionControllerUpdate;
                this.controllerAs = '$ctrl';
            }
            return ChartingCarePlanProblem;
        }());
        var ChartingCarePlanProblemSectionControllerUpdate = /** @class */ (function (_super) {
            __extends(ChartingCarePlanProblemSectionControllerUpdate, _super);
            function ChartingCarePlanProblemSectionControllerUpdate($filter, $scope, $state, $mdDialog, options, SectionManager, ChartUtilService, ChartingTemplateService) {
                var _this = _super.call(this, $scope) || this;
                _this.$filter = $filter;
                _this.$scope = $scope;
                _this.$state = $state;
                _this.$mdDialog = $mdDialog;
                _this.options = options;
                _this.SectionManager = SectionManager;
                _this.ChartUtilService = ChartUtilService;
                _this.ChartingTemplateService = ChartingTemplateService;
                _this.cpp = {};
                _this.yesNoNa = [{ id: 'Yes' }, { id: 'No' }, { id: 'N/A' }];
                return _this;
            }
            ChartingCarePlanProblemSectionControllerUpdate.prototype.$onInit = function () {
                this.loadFormDefinition(null);
                this.intervention_outcomes = this.options.get('outcomes');
                this.intervention_patientFamilyEngagements = this.options.get('patient_family_engagements');
                if (this.viewOnly !== undefined && this.viewOnly !== null) {
                    this.readonly = this.viewOnly;
                }
            };
            ChartingCarePlanProblemSectionControllerUpdate.prototype.editIntervention = function (index) {
                var _this = this;
                this.$mdDialog
                    .show({
                    controller: 'CarePlanProblemModalController as vm',
                    templateUrl: '/y/templates/care-plans/care-plan-problem-modal.html',
                    locals: {
                        intervention: this.formModel.interventions[index],
                        templateId: this.formModel.care_plan_template_id,
                    },
                })
                    .then(function (intervention) {
                    if (intervention) {
                        if (index !== undefined) {
                            _this.formModel.interventions[index] = intervention;
                        }
                        else {
                            _this.formModel.interventions.push(intervention);
                        }
                        _this.chartStore.saveDraft();
                        // TODO Add associated section to chart view
                        // TODO Remove associated section from chart view
                    }
                });
            };
            ChartingCarePlanProblemSectionControllerUpdate.prototype.loadFormDefinition = function (errors) {
                var _this = this;
                var isOffline = isNaN(this.$state.params.cppid);
                if (!this.formModel) {
                    var newItem = {
                        care_plan_problem_id: '',
                        care_plan_problem_uuid: '',
                        interventions: [],
                        legacy: false,
                    };
                    if (!isOffline) {
                        newItem.care_plan_problem_id = this.$state.params.cppid;
                    }
                    else {
                        newItem.care_plan_problem_uuid = this.$state.params.cppid;
                    }
                    this.chartStore.model.care_plan_problem_updates.push(newItem);
                    this.formModel = newItem;
                }
                this.ChartUtilService.loadCarePlanProblem(this.formModel, this.$state.params.cppid ||
                    this.formModel.care_plan_problem_id ||
                    this.formModel.care_plan_problem_uuid, this.cpp).then(function () {
                    _this.start_date = _this.$filter('date')(_this.cpp.start_date, 'short');
                    _this.end_date = _this.$filter('date')(_this.cpp.end_date, 'short');
                    _this.last_updated = _this.$filter('date')(_this.cpp.updated_at, 'short');
                    _this.participant_list = '';
                    for (var _i = 0, _a = _this.cpp.participants; _i < _a.length; _i++) {
                        var p = _a[_i];
                        _this.participant_list += p.description + '; ';
                    }
                    _this.participant_list = _this.participant_list.substr(0, _this.participant_list.length - 2);
                    var temp = _this.ChartingTemplateService.getCarePlanProblemSection(_this.cpp.problem);
                    _this.SectionManager.currentSection.title = temp.title;
                });
                this.SectionManager.currentActiveCarePlanProblem = this.formModel;
            };
            ChartingCarePlanProblemSectionControllerUpdate.prototype.reloadFormDefinition = function (errors) {
                // Since the care_plan_problem_updates come back in any order, we need to make sure we're
                // editing the right one.
                var item;
                item = this.$filter('filter')(this.chartStore.model.care_plan_problem_updates, function (b) {
                    if (isNaN(this.$state.params.cppid)) {
                        return (b.care_plan_problem_id === this.$state.params.cppid ||
                            b.care_plan_problem_uuid === this.$state.paramseParams.cppid);
                    }
                    return parseInt(b.care_plan_problem_id, 10) === parseInt(this.$state.params.cppid, 10);
                })[0];
                if (item !== this.formModel) {
                    angular.copy(item, this.formModel);
                }
            };
            ChartingCarePlanProblemSectionControllerUpdate.prototype.actionPerformedChanged = function (item) {
                if (item.action_performed !== 'Yes') {
                    item.resolved = false;
                }
            };
            ChartingCarePlanProblemSectionControllerUpdate.$inject = [
                '$filter',
                '$scope',
                '$state',
                '$mdDialog',
                'options',
                'ChartingSectionManager',
                'ChartUtilService',
                'ChartingTemplateService',
            ];
            return ChartingCarePlanProblemSectionControllerUpdate;
        }(consolo.controllers.BaseCustomComponentController));
        angular
            .module('consolo')
            .component('consoloChartingCarePlanProblemUpdate', new ChartingCarePlanProblem());
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('chartingProblemList', {
            bindings: {
                chartStore: '=',
                formModel: '<',
                formDefinition: '<',
                viewOnly: '<',
            },
            templateUrl: '/y/templates/clinical-charts/components/charting-problem-list.html',
            controller: 'ChartingProblemListController as $ctrl',
        });
        var ChartingProblemListController = /** @class */ (function () {
            function ChartingProblemListController(logger, CurrentPatient, ProblemListItemService, $state, $stateParams, $mdDialog, IcdService) {
                var _this = this;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.ProblemListItemService = ProblemListItemService;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdDialog = $mdDialog;
                this.IcdService = IcdService;
                this.errors = [];
                this.loading = false;
                if (!this.formModel.problem_list_items) {
                    this.formModel.problem_list_items = [];
                }
                this.ProblemListItemService.setCurrentPastProblemsForPatient(this.$stateParams.patientId);
                this.currentProblemsTableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                        allowRowSelect: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                label: 'Add to Chart',
                                icon: 'add',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    data.map(function (selectedItem) {
                                        _this.formModel.problem_list_items = _this.formModel.problem_list_items.concat(selectedItem);
                                    });
                                },
                                showCount: true,
                            },
                        ],
                    },
                    columns: [
                        { title: 'Code', field: 'snomed_problem.snomed_cid' },
                        {
                            title: 'Problem Description',
                            field: 'snomed_problem.description',
                        },
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'End Date',
                            field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Chronicity',
                            field: 'chronicity',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                };
                this.problemsTableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Code', field: 'snomed_problem.snomed_cid' },
                        {
                            title: 'Problem Description',
                            field: 'snomed_problem.description',
                        },
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'End Date',
                            field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Chronicity',
                            field: 'chronicity',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (problem) {
                                    return _this.ProblemListItemService.editProblemListItem(problem).then(function (updatedProblem) {
                                        return true;
                                    });
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (problem) {
                                    _this.formModel.problem_list_items.splice(problem.position, 1);
                                    return true;
                                },
                                conditionalHideFn: function (problemListItem) {
                                    return problemListItem.id;
                                },
                            },
                        ],
                    },
                };
            }
            ChartingProblemListController.prototype.addNewProblem = function (newProblem) {
                var _this = this;
                if (newProblem) {
                    var problem = {
                        position: this.formModel.problem_list_items.length,
                        start_date: new Date(),
                        snomed_problem: angular.copy(newProblem),
                        snomed_problem_id: newProblem.snomed_cid,
                        patient_id: this.CurrentPatient.patient.id,
                        health_conditions: [],
                        end_date: null,
                        chronicity: null,
                        snomed_problem_description: newProblem,
                    };
                    return this.ProblemListItemService.editProblemListItem(problem).then(function (updatedProblem) {
                        var existingProblems = _this.formModel.problem_list_items.filter(function (item) {
                            return (item.snomed_problem.snomed_cid === updatedProblem.snomed_problem.snomed_cid &&
                                common.dateStringFormat(item.start_date) ===
                                    common.dateStringFormat(updatedProblem.start_date));
                        });
                        if (existingProblems.length === 0) {
                            _this.formModel.problem_list_items.push(updatedProblem);
                            _this.formModel.problem_list_items = [].concat(_this.formModel.problem_list_items);
                        }
                        else {
                            _this.logger.error('There is already an existing current problem that matches this.');
                        }
                        newProblem = null;
                        _this.newProblem = null;
                        return updatedProblem;
                    });
                }
            };
            ChartingProblemListController.prototype.addExistingProblem = function () {
                if (this.selectedItem) {
                    this.formModel.problem_list_items.push(this.selectedItem);
                    this.formModel.problem_list_items = [].concat(this.formModel.problem_list_items);
                    this.selectedItem = null;
                }
            };
            ChartingProblemListController.prototype.pastProblemModal = function () {
                this.ProblemListItemService;
                return this.$mdDialog.show({
                    template: '<md-dialog flex="80" aria-label="Past Problems">' +
                        '  <past-problems flex=""></past-problems>' +
                        '</md-dialog>',
                    clickOutsideToClose: true,
                });
            };
            ChartingProblemListController.prototype.setPosition = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i;
                }
            };
            ChartingProblemListController.$inject = [
                'logger',
                'CurrentPatient',
                'ProblemListItemService',
                '$state',
                '$stateParams',
                '$mdDialog',
                'IcdService',
            ];
            return ChartingProblemListController;
        }());
        common.ChartingProblemListController = ChartingProblemListController;
        angular
            .module('consolo')
            .controller('ChartingProblemListController', ChartingProblemListController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var consoloChartingControlledSubstanceCountSection = /** @class */ (function () {
            function consoloChartingControlledSubstanceCountSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '=',
                    viewOnly: '<',
                    formDefinition: '<',
                    showUnmodifiedSections: '='
                };
                this.templateUrl = '/y/templates/clinical-charts/components/controlled-substance-count.html';
                this.controller = ConsoloChartingControlledSubstanceCountController;
            }
            return consoloChartingControlledSubstanceCountSection;
        }());
        var ConsoloChartingControlledSubstanceCountController = /** @class */ (function () {
            function ConsoloChartingControlledSubstanceCountController() {
            }
            ConsoloChartingControlledSubstanceCountController.prototype.$onInit = function () {
                this.readonly = this.viewOnly;
                if (!this.formModel.controlled_substance_drug_data || !this.formModel.controlled_substance_drug_data.length) {
                    this.formModel.controlled_substance_drug_data = [];
                    this.addDrugData();
                }
            };
            ConsoloChartingControlledSubstanceCountController.prototype.$onChanges = function () { };
            ConsoloChartingControlledSubstanceCountController.prototype.addDrugData = function () {
                this.formModel.controlled_substance_drug_data.push({
                    controlled_substance_drug: '',
                    controlled_substance_count_start: '',
                    controlled_substance_count_end: ''
                });
            };
            ConsoloChartingControlledSubstanceCountController.prototype.removeDrugData = function (index) {
                this.formModel.controlled_substance_drug_data.splice(index, 1);
            };
            ConsoloChartingControlledSubstanceCountController.prototype.checkIfDataExist = function () {
                var data_exists = false;
                this.formModel.controlled_substance_drug_data.forEach(function (row) {
                    if (row.controlled_substance_drug || row.controlled_substance_count_start || row.controlled_substance_count_end) {
                        data_exists = true;
                    }
                });
                return data_exists;
            };
            ConsoloChartingControlledSubstanceCountController.$inject = [
                '$filter',
                '$scope',
                'ChartingSectionManager',
                'options',
                '$parse',
                'EncounterType',
                'cache',
                'SessionService',
                '$mdPanel',
            ];
            return ConsoloChartingControlledSubstanceCountController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingControlledSubstanceCount', new consoloChartingControlledSubstanceCountSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ChartingCPTCodingSection = /** @class */ (function () {
            function ChartingCPTCodingSection() {
                this.bindings = {
                    formModel: '<',
                    chartStore: '=',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/cpt-coding.html';
                this.controller = ChartingCPTCodingSectionController;
            }
            return ChartingCPTCodingSection;
        }());
        var ChartingCPTCodingSectionController = /** @class */ (function () {
            function ChartingCPTCodingSectionController($mdDialog, CPTCodingService, IndicatorsService) {
                this.$mdDialog = $mdDialog;
                this.CPTCodingService = CPTCodingService;
                this.IndicatorsService = IndicatorsService;
                this.codes = [];
                this.patientId = this.chartStore.model.patient_id;
                if (this.chartStore.model.cpt_coding &&
                    this.chartStore.model.cpt_coding[0] &&
                    angular.equals(this.chartStore.model.cpt_coding[0], { isEmpty: true })) {
                    this.chartStore.model.cpt_coding.pop();
                }
            }
            ChartingCPTCodingSectionController.prototype.$onInit = function () {
                this.getCPTCodes();
            };
            ChartingCPTCodingSectionController.prototype.showProcedureModal = function (procedure, readOnly) {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/clinical-charts/sections/cpt-coding-procedure-modal.html',
                    controller: 'CptProcedureModalController as vm',
                    bindToController: true,
                    locals: {
                        patientId: this.patientId,
                        readOnly: readOnly,
                        procedure: procedure,
                        codes: this.codes,
                    },
                })
                    .then(function (procedure) {
                    _this.chartStore.model.cpt_coding.push(procedure);
                    _this.chartStore.saveDraft();
                });
            };
            ChartingCPTCodingSectionController.prototype.getCPTCodes = function () {
                var _this = this;
                this.CPTCodingService.getAllCPTCodes().then(function (cptCodes) {
                    _this.codes = cptCodes;
                    /*
                    this.chartStore.model.cpt_coding = [
                      {
                        name: 'Procedure 1',
                        effective_date: new Date(),
                        role_id: 16,
                        cpt_code: cptCodes[0],
                        pre_election: 'Pre-Election 1',
                        icd10_health_conditions: [
                          {
                            label: 'Primary ICD10',
                            code: 'I50.22',
                            description: 'Chronic systolic (congestive) heart failure',
                          },
                          {
                            label: 'Secondary ICD10',
                            code: 'G30.1',
                            description: 'Alzheimers disease with late onset',
                          },
                        ],
                        modifiers: [
                          {
                            code: '12345',
                            descriptor: 'This is the test descriptor',
                          },
                          {
                            code: '678910',
                            descriptor: 'This is the test descriptor',
                          },
                        ],
                      },
                    ];*/
                });
            };
            ChartingCPTCodingSectionController.$inject = ['$mdDialog', 'CPTCodingService', 'IndicatorsService'];
            return ChartingCPTCodingSectionController;
        }());
        angular.module('consolo').component('consoloChartingCptCoding', new ChartingCPTCodingSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ChartingDiagnosisSection = /** @class */ (function () {
            function ChartingDiagnosisSection() {
                this.bindings = {
                    chartStore: '=',
                    // formModel: '=',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/diagnosis.html';
                this.controller = ChartingDiagnosisController;
            }
            return ChartingDiagnosisSection;
        }());
        var ChartingDiagnosisController = /** @class */ (function () {
            function ChartingDiagnosisController(Indicator, CurrentPatient, $mdDialog) {
                this.Indicator = Indicator;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
            }
            ChartingDiagnosisController.prototype.$onInit = function () {
                var _this = this;
                var patient = this.chartStore.model.patient || this.CurrentPatient.patient;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                this.Indicator.loadByPatientId(patient.id).$promise.then(function (data) {
                    _this.indicators = data;
                    _this.indicators.forEach(function (element) {
                        var arr = element.diagnoses.map(function (d) {
                            return d.name;
                        });
                        var icd10s = element.icd10s.map(function (icd) {
                            return icd.code;
                        });
                        element.diagnoses_names = arr.join(', ');
                        element.icds = icd10s.join(', ');
                    });
                });
                var layout = {
                    options: {
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        { title: 'Diagnosis', field: 'diagnoses_names' },
                        { title: 'Created By', field: 'user_name' },
                        { title: 'Effective Date', field: 'effective_date', type: 'date' },
                        { title: 'ICD10', field: 'icds' },
                    ],
                };
                if (!this.viewOnly) {
                    layout.rowMenu = {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'action:edit',
                                callbackFn: function () {
                                    alert('Not yet implemented');
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'action:delete',
                                callbackFn: function () {
                                    alert('Not yet implemented');
                                },
                            },
                        ],
                    };
                }
                this.diagnosisLayout = layout;
            };
            ChartingDiagnosisController.prototype.addDiagnosis = function ($event) {
                this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/indicators/indicator-modal.html',
                    controller: 'IndicatorModalController as $ctrl',
                });
            };
            ChartingDiagnosisController.$inject = ['Indicator', 'CurrentPatient', '$mdDialog'];
            return ChartingDiagnosisController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingDiagnosis', new ChartingDiagnosisSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingDicontinueCarePlanProblem = /** @class */ (function () {
            function ConsoloChartingDicontinueCarePlanProblem() {
                this.bindings = {
                    carePlanProblemId: '<',
                    isDiscontinue: '<',
                    isPartial: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/discontinue-cpp.component.html';
                this.controller = ConsoloChartingDicontinueCarePlanProblemController;
            }
            return ConsoloChartingDicontinueCarePlanProblem;
        }());
        var ConsoloChartingDicontinueCarePlanProblemController = /** @class */ (function () {
            function ConsoloChartingDicontinueCarePlanProblemController(CarePlanProblemsService, Session, SectionManager, ClinicalChartEntry, CarePlanProblemEntry, PersistedItem, ReleaseVersion, CurrentPatient, options, logger, $http, $state) {
                this.CarePlanProblemsService = CarePlanProblemsService;
                this.Session = Session;
                this.SectionManager = SectionManager;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.PersistedItem = PersistedItem;
                this.ReleaseVersion = ReleaseVersion;
                this.CurrentPatient = CurrentPatient;
                this.options = options;
                this.logger = logger;
                this.$http = $http;
                this.$state = $state;
                this.isLoading = true;
                this.isSaving = false;
                this.createCopy = false;
            }
            ConsoloChartingDicontinueCarePlanProblemController.prototype.$onInit = function () {
                var _this = this;
                this.CarePlanProblemsService.getSingleCPP(this.carePlanProblemId).then(function (cpp) {
                    _this.carePlanProblem = cpp;
                    _this.user = _this.Session.getUser();
                    _this.userDashboardUrl = _this.user.dashboard_url;
                    var defEncType = {
                        id: 'encounter_charting',
                        name: 'Default Encounter Charting',
                        charting_type: 'encounter_charting',
                        lookup_name: 'encounter_charting',
                        display_name: 'Default Encounter Charting',
                    };
                    var now = new Date();
                    var chartEntry = {
                        patient_id: _this.carePlanProblem.patient_id,
                        patient: _this.CurrentPatient.patient,
                        effective_date: new Date(now.toDateString()),
                        time_of_visit: now,
                        charting_client: 'encounter_charting',
                        encounter_type: defEncType,
                        user: _this.user,
                        user_id: _this.user.id,
                    };
                    _this.chartStore = new _this.PersistedItem('clinical_chart_entry', new _this.ClinicalChartEntry(chartEntry));
                    _this.chartStore.noServerDraft = true;
                    _this.SectionManager.init(_this.chartStore);
                    _this.SectionManager.addCarePlanProblemUpdate(_this.carePlanProblem, _this.chartStore);
                    _this.options.get('base_roles').$promise.then(function (roles) {
                        var role = roles.filter(function (r) { return r.role_symbol === 'system_generated'; })[0];
                        _this.chartStore.model.role_name = role.description;
                        _this.chartStore.model.role_id = role.id;
                        _this.cppFormModel = _this.chartStore.model.care_plan_problem_updates[0];
                        _this.cppFormModel.resolved = true;
                        _this.isLoading = false;
                    });
                });
            };
            ConsoloChartingDicontinueCarePlanProblemController.prototype.save = function () {
                var _this = this;
                this.isSaving = true;
                var cppCopy = angular.copy(this.carePlanProblem);
                cppCopy.interventions = this.cppFormModel.interventions.filter(function (i) { return !i.resolved; });
                this.chartStore.save().then(function (item) {
                    var esig = {};
                    if (_this.user.physician) {
                        esig.physician_id = _this.user.physician.id;
                    }
                    _this.$http.post('/api/v1/clinical_charts/' + item.id + '/signatures', esig).then(function () {
                        _this.logger.success('Care Plan Problem Discontinued');
                        if (_this.createCopy) {
                            cppCopy.id = null;
                            cppCopy.source = 'Agency Generated';
                            cppCopy.care_plan_problem_updates = null;
                            cppCopy.created_at = null;
                            cppCopy.updated_at = null;
                            cppCopy.effective_date = null;
                            cppCopy.start_date = null;
                            cppCopy.end_date = null;
                            var newCpp = new _this.PersistedItem('care_plan_problem', new _this.CarePlanProblemEntry(cppCopy));
                            newCpp.model.uuid = newCpp.generateUUID();
                            for (var _i = 0, _a = newCpp.model.interventions; _i < _a.length; _i++) {
                                var i = _a[_i];
                                i.id = null;
                                i.uuid = newCpp.generateUUID();
                            }
                            for (var _b = 0, _c = newCpp.model.participants; _b < _c.length; _b++) {
                                var p = _c[_b];
                                p.id = null;
                            }
                            newCpp.save().then(function (savedCpp) {
                                _this.isSaving = false;
                                _this.$state.go('patients.care-plan-problem.edit', {
                                    patientId: _this.CurrentPatient.patient_id,
                                    id: savedCpp.id,
                                });
                            });
                        }
                        else {
                            _this.isSaving = false;
                            _this.$state.go('patients.care-plan-problem', { patientId: _this.CurrentPatient.patient_id });
                        }
                    }, function (errors) {
                        _this.logger.error('Unable to sign chart and discontinue Care Plan');
                        console.log(errors);
                        _this.isSaving = false;
                    });
                }, function (errors) {
                    _this.logger.error('Unable to save chart to discontinue Care Plan');
                    console.log(errors);
                    _this.isSaving = false;
                });
            };
            ConsoloChartingDicontinueCarePlanProblemController.$inject = [
                'CarePlanProblemsService',
                'SessionService',
                'ChartingSectionManager',
                'ClinicalChartEntry',
                'CarePlanProblemEntry',
                'PersistedItem',
                'EmrWebRelease',
                'CurrentPatient',
                'options',
                'logger',
                '$http',
                '$state',
            ];
            return ConsoloChartingDicontinueCarePlanProblemController;
        }());
        components.ConsoloChartingDicontinueCarePlanProblemController = ConsoloChartingDicontinueCarePlanProblemController;
        angular
            .module('consolo')
            .component('discontinueCarePlanProblem', new ConsoloChartingDicontinueCarePlanProblem());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingDocumentUpload = /** @class */ (function () {
            function ConsoloChartingDocumentUpload() {
                this.bindings = {
                    chartStore: '=',
                    formModel: '<',
                    viewOnly: '<',
                    formDefinition: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/document-upload.html';
                this.controller = DocumentUploadController;
            }
            return ConsoloChartingDocumentUpload;
        }());
        var DocumentUploadController = /** @class */ (function () {
            function DocumentUploadController($params, $timeout, uploadQueue, S3UploadFactory) {
                this.$timeout = $timeout;
                this.uploadQueue = uploadQueue;
                this.S3UploadFactory = S3UploadFactory;
                this.patientId = Number($params.patientId);
            }
            DocumentUploadController.prototype.$onInit = function () {
                this.evaluateFormModel();
                if (this.formModel['uploads'].length === 0) {
                    // Check chart store for uploads
                    this.loadUploadsFromChartStore();
                }
            };
            DocumentUploadController.prototype.handleFiles = function (files) {
                var _this = this;
                files.forEach(function (file) {
                    var upload = _this.S3UploadFactory.build(file);
                    _this.uploadQueue.push(upload);
                    _this.formModel['uploads'].push(upload);
                    upload.promise.catch(function () {
                        _this.removeUpload(upload, 5000);
                    });
                });
            };
            DocumentUploadController.prototype.afterRemove = function (upload) {
                this.removeUpload(upload, 500);
            };
            DocumentUploadController.prototype.removeUpload = function (upload, time) {
                var _this = this;
                this.$timeout(function () {
                    var idx = _this.formModel['uploads'].indexOf(upload);
                    var idc = _this.chartStore.model.document_upload['uploads'].indexOf(upload);
                    if (idx > -1) {
                        _this.formModel['uploads'].splice(idx, 1);
                    }
                    if (idc > -1) {
                        _this.chartStore.model.document_upload['uploads'].splice(idc, 1);
                    }
                }, time);
            };
            DocumentUploadController.prototype.evaluateFormModel = function () {
                if (!this.formModel) {
                    this.formModel = {
                        isEmpty: true,
                        uploads: []
                    };
                }
                else if (!this.formModel['uploads']) {
                    this.formModel['uploads'] = [];
                }
            };
            DocumentUploadController.prototype.loadUploadsFromChartStore = function () {
                var _this = this;
                if (this.chartStore.model.document_upload && this.chartStore.model.document_upload['uploads'].length > 0) {
                    this.chartStore.model.document_upload['uploads'].forEach(function (upload) {
                        _this.formModel['uploads'].push(upload);
                    });
                }
            };
            DocumentUploadController.$inject = ['$stateParams', '$timeout', 'UploadQueue', 'S3UploadFactory'];
            return DocumentUploadController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingDocumentUpload', new ConsoloChartingDocumentUpload());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingEdmontonAssessmentSection = /** @class */ (function () {
            function ConsoloChartingEdmontonAssessmentSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    viewOnly: '<',
                    formDefinition: '<',
                    showUnmodifiedSections: '=',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/edmonton-assessment.html';
                this.controller = ChartingEdmontonAssessmentController;
            }
            return ConsoloChartingEdmontonAssessmentSection;
        }());
        var ChartingEdmontonAssessmentController = /** @class */ (function () {
            function ChartingEdmontonAssessmentController($http, $scope, $mdDialog, logger, ChartingTemplateService, options, cache, FamilyMember, ChartUtilService, $timeout, $window) {
                var _this = this;
                this.$http = $http;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.ChartingTemplateService = ChartingTemplateService;
                this.options = options;
                this.cache = cache;
                this.ChartUtilService = ChartUtilService;
                this.$timeout = $timeout;
                this.$window = $window;
                this.layoutContainerId = (Math.random() * 1000).toFixed(0).toString();
                this.edmontonValues = [
                    { id: 0, description: '0 - Best Possible', filled: false },
                    { id: 1 },
                    { id: 2 },
                    { id: 3 },
                    { id: 4 },
                    { id: 5 },
                    { id: 6 },
                    { id: 7 },
                    { id: 8 },
                    { id: 9 },
                    { id: 10, description: '10 - Worst Possible' },
                ];
                this.$window.addEventListener('resize', function () { _this.getLayoutSize(_this.layoutContainerId); });
                this.$scope.$on('$destroy', function () {
                    _this.$window.removeEventListener('resize', function () { _this.getLayoutSize(_this.layoutContainerId); });
                });
                for (var _i = 0, _a = this.edmontonValues; _i < _a.length; _i++) {
                    var i = _a[_i];
                    i.description = i.description || i.id.toString();
                }
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                this.loadFormDefinition();
                $scope.$on('reloadFormDefinition', function () {
                    _this.loadFormDefinition();
                });
                this.familyList = cache(FamilyMember, 'family_members', { encrypt: true }).query({
                    patient_id: this.chartStore.model.patient_id,
                });
                this.completedByOptions = this.options.get('edmonton_completed_by_options');
                this.slider = {
                    value: 2,
                    options: {
                        showTicksValues: true,
                        stepsArray: [
                            { value: 0, description: '0 - Best Possible' },
                            { value: 2 },
                            { value: 3 },
                            { value: 4 },
                            { value: 5 },
                            { value: 6 },
                            { value: 7 },
                            { value: 8 },
                            { value: 9 },
                            { value: 10, description: '10 - Worst Possible' },
                        ],
                    },
                };
            }
            ChartingEdmontonAssessmentController.prototype.toggle = function (question, idx) {
                this.formModel[question.field] = idx >= 0 ? idx : null;
                if (this.formModel[question.field] >= 0) {
                    this.formModel[question.field + '_unable'] = false;
                }
                this.updateStars(question);
                this.chartStore.saveDraft();
            };
            ChartingEdmontonAssessmentController.prototype.updateStars = function (question) {
                var stars = angular.copy(this.edmontonValues);
                for (var i = 0; i < this.edmontonValues.length; i++) {
                    stars[i].filled =
                        this.formModel[question.field] !== null &&
                            this.formModel[question.field] !== undefined &&
                            this.formModel[question.field] > -1 &&
                            i <= this.formModel[question.field];
                }
                var q = this.assessmentFields.find(function (x) { return x.field === question.field; });
                q.stars = stars;
                this.setDescription(question);
            };
            ChartingEdmontonAssessmentController.prototype.setDescription = function (question, index) {
                if (index >= 0) {
                    question.description = this.edmontonValues[index]
                        ? ' - ' + this.edmontonValues[index].description
                        : '';
                }
                else if (this.formModel[question.field + '_unable']) {
                    question.description = ' - Unable to Assess';
                }
                else if (this.formModel[question.field] >= 0 &&
                    this.formModel[question.field] !== undefined &&
                    this.formModel[question.field] !== null) {
                    question.description =
                        ' - ' + this.edmontonValues[this.formModel[question.field]].description;
                }
                else {
                    question.description = this.viewOnly ? '~' : '';
                }
            };
            ChartingEdmontonAssessmentController.prototype.$onInit = function () {
                var _this = this;
                this.$timeout(function () { return _this.getLayoutSize(_this.layoutContainerId); }, 500);
            };
            ChartingEdmontonAssessmentController.prototype.getLayoutSize = function (id) {
                var el = document.getElementById('esas-container-' + id);
                this.largeLayout = el ? el.offsetWidth >= 1280 : this.largeLayout;
                this.smallLayout = el ? el.offsetWidth < 600 : this.smallLayout;
            };
            ;
            ChartingEdmontonAssessmentController.prototype.loadFormDefinition = function () {
                this.formDefinition = this.ChartingTemplateService.getFormDefinition('edmonton_assessment', this.chartStore.model.encounter_type_id || 'encounter_charting', this.chartStore.model.patient_id, this.chartStore.model.encounter_type);
                this.assessmentFields = this.formDefinition[0].field_definition[0].fields.slice(0, 10);
                this.createdBy = this.formDefinition[0].field_definition[0].fields.find(function (x) { return x.field == "completed_by"; });
                console.log('assessmentFields', this.assessmentFields);
                for (var _i = 0, _a = this.assessmentFields; _i < _a.length; _i++) {
                    var i = _a[_i];
                    i.stars = angular.copy(this.edmontonValues);
                    this.updateStars(i);
                }
            };
            ChartingEdmontonAssessmentController.$inject = [
                '$http',
                '$scope',
                '$mdDialog',
                'logger',
                'ChartingTemplateService',
                'options',
                'cache',
                'FamilyMember',
                'ChartUtilService',
                '$timeout',
                '$window',
            ];
            return ChartingEdmontonAssessmentController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingEdmontonAssessment', new ConsoloChartingEdmontonAssessmentSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingEvvSection = /** @class */ (function () {
            function ConsoloChartingEvvSection() {
                this.bindings = {
                    chartStore: '=',
                    viewOnly: '<',
                    callback: '&?',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/evv.html';
                this.controller = ChartingEvvController;
            }
            return ConsoloChartingEvvSection;
        }());
        var ChartingEvvController = /** @class */ (function (_super) {
            __extends(ChartingEvvController, _super);
            function ChartingEvvController($filter, $timeout, cache, EncounterType, logger, $window, $scope) {
                var _this = _super.call(this, $scope) || this;
                _this.$filter = $filter;
                _this.$timeout = $timeout;
                _this.cache = cache;
                _this.EncounterType = EncounterType;
                _this.logger = logger;
                _this.$window = $window;
                _this.searching = false;
                _this.numGeoTries = 0;
                _this.maxGeoTries = 5;
                _this.chartExistsWithNoEVV = false;
                _this.encType = {};
                _this.requiredFields = {};
                _this.saveEVVDraft = function () {
                    if (!_this.chartEntry.disable_evv) {
                        _this.saveDraft();
                    }
                };
                _this.getCoords = function () {
                    if (!_this.chartEntry.disable_evv) {
                        _this.searching = true;
                        _this.$window.navigator.geolocation.getCurrentPosition(_this.setCoords, _this.showGeoError, {
                            enableHighAccuracy: true,
                            maximumAge: 0,
                        });
                        _this.numGeoTries += 1;
                    }
                    else {
                        _this.$timeout(_this.getCoords, 500);
                    }
                };
                _this.setCoords = function (result) {
                    _this.searching = false;
                    if (!_this.chartEntry.electronic_visit_verification) {
                        _this.chartEntry.electronic_visit_verification = {};
                        _this.evv = _this.chartEntry.electronic_visit_verification;
                    }
                    if (_this.numGeoTries === 1 || result.coords.accuracy < _this.evv.accuracy) {
                        _this.evv.latitude = result.coords.latitude;
                        _this.evv.longitude = result.coords.longitude;
                        _this.evv.accuracy = result.coords.accuracy;
                        _this.evv.timestamp = new Date().toISOString();
                        _this.saveDraft();
                    }
                    if (result.coords.accuracy > 5 && _this.numGeoTries < _this.maxGeoTries) {
                        setTimeout(_this.getCoords, 5 * 1000);
                    }
                    console.log('found your location (' +
                        result.coords.latitude +
                        ', ' +
                        result.coords.longitude +
                        ') accuracy ' +
                        result.coords.accuracy);
                };
                _this.showGeoError = function (error) {
                    _this.searching = false;
                    switch (error.code) {
                        case 2:
                            _this.logger.error('Network location is unavailable');
                            break;
                        case 3:
                            _this.logger.error('Timed out checking for your location');
                            break;
                        default:
                            _this.logger.error('Unknown error: ' + error.message);
                    }
                };
                return _this;
            }
            ChartingEvvController.prototype.$onInit = function () {
                this.chartEntry = this.chartStore.model;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                this.activate();
            };
            ChartingEvvController.prototype.accuracyToFeet = function () {
                return this.evv.accuracy * 3.281;
            };
            ChartingEvvController.prototype.activate = function () {
                if (this.chartEntry.id && this.chartEntry.electronic_visit_verification === null) {
                    this.chartExistsWithNoEVV = true;
                }
                else {
                    if (!this.chartEntry.electronic_visit_verification) {
                        this.chartEntry.electronic_visit_verification = { signature_pad_png_base64: '' };
                    }
                    this.evv = this.chartEntry.electronic_visit_verification;
                    if (!this.evv.latitude && !this.evv.id && !this.viewOnly) {
                        this.getCoords();
                    }
                }
                this.highlightFields();
            };
            ChartingEvvController.prototype.highlightFields = function () {
                var _this = this;
                if (this.chartStore.model.encounter_type_id) {
                    this.cache(this.EncounterType, 'encounter_types')
                        .query({ active: true })
                        .$promise.then(function (a) {
                        _this.encType = _this.$filter('filter')(a, function (b) {
                            return b.id === _this.chartStore.model.encounter_type_id;
                        })[0];
                        var tempReq = _this.encType &&
                            _this.encType.required_fields &&
                            _this.encType.required_fields.electronic_visit_verification
                            ? _this.encType.required_fields.electronic_visit_verification
                            : [];
                        _this.requiredFields = {};
                        for (var _i = 0, tempReq_1 = tempReq; _i < tempReq_1.length; _i++) {
                            var f = tempReq_1[_i];
                            _this.requiredFields[f] = true;
                        }
                    });
                }
            };
            ChartingEvvController.$inject = [
                '$filter',
                '$timeout',
                'cache',
                'EncounterType',
                'logger',
                '$window',
                '$scope',
            ];
            return ChartingEvvController;
        }(consolo.controllers.BaseCustomComponentController));
        components.ChartingEvvController = ChartingEvvController;
        angular.module('consolo').component('consoloChartingEvv', new ConsoloChartingEvvSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ChartingFaceToFaceVisitSection = /** @class */ (function () {
            function ChartingFaceToFaceVisitSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                    showUnmodifiedSections: '=',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/face-to-face-visit.html';
                this.controller = ChartingFaceToFaceVisitController;
            }
            return ChartingFaceToFaceVisitSection;
        }());
        var ChartingFaceToFaceVisitController = /** @class */ (function () {
            function ChartingFaceToFaceVisitController($http, $scope, logger, ChartingTemplateService, Session, CurrentPatient) {
                this.$http = $http;
                this.$scope = $scope;
                this.logger = logger;
                this.ChartingTemplateService = ChartingTemplateService;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.canSign = false;
            }
            ChartingFaceToFaceVisitController.prototype.$onInit = function () {
                var _this = this;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                this.templateName = this.ChartingTemplateService.getTemplateName(this.chartStore.model);
                this.section = this.ChartingTemplateService.sectionSearchBy('face_to_face_visit', 'name', this.templateName, 'encounter_charting');
                this.loadFormDefinition();
                this.$scope.$on('reloadFormDefinition', function () {
                    _this.loadFormDefinition();
                });
                this.$http
                    .get('/api/v1/patient_documents', {
                    params: { patient_id: this.CurrentPatient.patient_id, tags: 'Face To Face Visits' },
                })
                    .then(function (response) {
                    _this.patientDocuments = response.data;
                });
                this.currentUser = this.Session.getUser();
                if (this.chartStore.model.locked &&
                    this.currentUser.physician &&
                    this.currentUser.physician.id === this.formModel.physician_id) {
                    this.canSign = true;
                    for (var _i = 0, _a = this.formModel.signatures; _i < _a.length; _i++) {
                        var signature = _a[_i];
                        if (signature.physician_id === this.currentUser.physician.id) {
                            this.canSign = false;
                        }
                    }
                }
            };
            ChartingFaceToFaceVisitController.prototype.loadFormDefinition = function () {
                this.formDefinition = this.ChartingTemplateService.getFormDefinition('face_to_face_visit', this.chartStore.model.encounter_type_id, this.chartStore.model.patient_id, this.chartStore.model.encounter_type);
            };
            ChartingFaceToFaceVisitController.prototype.addFaceToFaceSignature = function () {
                var _this = this;
                var physician_signature = {
                    user_id: this.currentUser.id,
                    physician_id: this.currentUser.physician.id,
                    effective_date: new Date(new Date().toDateString()),
                };
                this.$http
                    .post('/api/v1/clinical_charts/' +
                    this.chartStore.model.id +
                    '/face_to_face_visit_signature/' +
                    this.formModel.id, physician_signature)
                    .then(function (resp) {
                    _this.logger.success('Face to Face Visit signed');
                    _this.canSign = false;
                    _this.formModel.signatures.push(resp.data);
                }, function (errors) {
                    _this.logger.error('Unable to sign Face to Face Visit');
                });
            };
            ChartingFaceToFaceVisitController.$inject = [
                '$http',
                '$scope',
                'logger',
                'ChartingTemplateService',
                'SessionService',
                'CurrentPatient',
            ];
            return ChartingFaceToFaceVisitController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingFaceToFaceVisit', new ChartingFaceToFaceVisitSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ChartingFamilyDiagnosisHistorySection = /** @class */ (function () {
            function ChartingFamilyDiagnosisHistorySection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/family-diagnosis-history.html';
                this.controller = ChartingFamilyDiagnosisHistoryController;
            }
            return ChartingFamilyDiagnosisHistorySection;
        }());
        var ChartingFamilyDiagnosisHistoryController = /** @class */ (function () {
            function ChartingFamilyDiagnosisHistoryController($http, $filter, $parse, $scope, $state, $mdDialog, logger, cache, EncounterType, CarePlanProblemEntry, PersistedItem, options) {
                var _this = this;
                this.$http = $http;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.options = options;
                $scope.$on('reloadFormDefinition', function () {
                    _this.loadFormDefinition();
                });
            }
            ChartingFamilyDiagnosisHistoryController.prototype.$onInit = function () {
                this.url = '/api/v1/family_diagnoses';
                this.loadFormDefinition();
                this.diagnosisOptions = this.options.get('diagnosis_templates');
                this.familyDiagnosisLayout = {
                    columns: [
                        { title: 'Family Member', field: 'famName', canSort: true },
                        { title: 'Relationship', field: 'relationship', canSort: true },
                        { title: 'Diagnosis', field: 'diagnosis', canSort: true },
                        {
                            title: 'Recorded Date',
                            field: 'diagnosis_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                    ],
                };
            };
            ChartingFamilyDiagnosisHistoryController.prototype.create = function (diagnoses) {
                return this.$http.post(this.url, diagnoses);
            };
            ChartingFamilyDiagnosisHistoryController.prototype.getDiagnosesFromServer = function () {
                var _this = this;
                var diagnoses = [];
                return this.$http
                    .get('/api/v1/family_members/with_diagnoses?patient_id=' + this.patientId)
                    .then(function (data) {
                    var pid = _this.patientId;
                    _this.familyMembers = data.data;
                    _this.familyMembers.forEach(function (famMember) {
                        var relationship = '';
                        var r = famMember.patient_family_relationships.filter(function (r) {
                            return r.patient_id === pid;
                        })[0];
                        if (r) {
                            relationship = r.relationship_description;
                        }
                        famMember.family_diagnoses.forEach(function (diagnosis) {
                            diagnoses.push({
                                famName: famMember.name,
                                relationship: relationship,
                                diagnosis: diagnosis.name,
                                diagnosis_date: diagnosis.date_of_diagnosis,
                            });
                        });
                    });
                    return diagnoses;
                }, function (error) {
                    return 'error';
                });
            };
            ChartingFamilyDiagnosisHistoryController.prototype.clickGetDiagnosesFromServer = function ($event) {
                var _this = this;
                this.getDiagnosesFromServer().then(function (data) {
                    _this.formModel.diagnoses = data;
                    _this.formModel.updated_at = Date.now();
                    _this.lastUpdated = _this.formModel.updated_at;
                    _this.pullUpdates = true;
                });
            };
            // add a new family member
            ChartingFamilyDiagnosisHistoryController.prototype.showFamilyMemberAddtionDialog = function ($event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/family-members/family-members-edit.html',
                    controller: 'FamilyMembersEditController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                })
                    .then(function (familyMember) {
                    _this.$http
                        .get('/api/v1/family_members/with_diagnoses?patient_id=' + _this.patientId)
                        .then(function (data) {
                        var pid = _this.patientId;
                        _this.familyMembers = data.data;
                    });
                });
            };
            //add new diagnoses for selected family member
            ChartingFamilyDiagnosisHistoryController.prototype.showFamilyDiagnosisAdditionDialog = function ($event, familyMember) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/clinical-charts/sections/family-diagnosis-history-modal.html',
                    controller: 'FamilyDiagnosisHistoryModalController as vm',
                    bindToController: true,
                    locals: {
                        diagnosisOptions: this.diagnosisOptions,
                        familyMember: familyMember,
                        diagnosisDate: this.chartStore.model.effective_date,
                    },
                })
                    .then(function (diagnoses) {
                    if (!diagnoses) {
                        return false;
                    }
                    var ds = { diagnoses: [] };
                    _this.pullUpdates = true;
                    diagnoses.forEach(function (diagnosis) {
                        var famMember = _this.familyMembers.filter(function (f) {
                            return f.id === diagnosis.family_id;
                        })[0];
                        ds.diagnoses.push({
                            name: diagnosis.name,
                            date_of_diagnosis: diagnosis.date_of_diagnosis,
                            family_id: famMember.id,
                        });
                    });
                    //this.formModel.diagnoses = [].concat(this.formModel.diagnoses);
                    _this.create(ds).then(function (post_data) {
                        _this.getDiagnosesFromServer().then(function (data) {
                            _this.formModel.diagnoses = data;
                            _this.formModel.updated_at = Date.now();
                            _this.lastUpdated = _this.formModel.updated_at;
                            _this.pullUpdates = true;
                            _this.chartStore.model.family_diagnosis_histories = _this.formModel;
                            _this.chartStore.saveDraft();
                        });
                    });
                    return true;
                });
            };
            ChartingFamilyDiagnosisHistoryController.prototype.loadFormDefinition = function () {
                var _this = this;
                this.patientId = this.chartStore.model.patient_id;
                var diagnoses = [];
                this.getDiagnosesFromServer().then(function (data) {
                    diagnoses = data;
                    //if (this.$state.current.data.action === 'edit' && this.formModel.isEmpty) {
                    if (_this.chartStore.model.family_diagnosis_histories &&
                        _this.chartStore.model.family_diagnosis_histories.diagnoses) {
                        _this.formModel = _this.chartStore.model.family_diagnosis_histories;
                        _this.lastUpdated = _this.formModel.updated_at;
                        _this.pullUpdates = false;
                    }
                    else if (_this.$state.current.data.action === 'edit') {
                        _this.formModel = _this.chartStore.model.model.family_diagnosis_histories;
                        _this.lastUpdated = _this.formModel.updated_at;
                        _this.pullUpdates = false;
                    }
                    else {
                        //if (this.formModel.diagnoses) {
                        _this.formModel.diagnoses = [];
                        _this.lastUpdated = Date.now();
                        _this.pullUpdates = true;
                        var newItem = {
                            patient_id: _this.chartStore.model.patient_id,
                            diagnoses: {},
                            updated_at: _this.lastUpdated,
                        };
                        //this.chartStore.model.family_diagnosis_histories.isEmpty = false;
                        newItem.diagnoses = diagnoses;
                        _this.chartStore.model.family_diagnosis_histories = newItem;
                        _this.formModel = _this.chartStore.model.family_diagnosis_histories;
                    }
                });
            };
            ChartingFamilyDiagnosisHistoryController.$inject = [
                '$http',
                '$filter',
                '$parse',
                '$scope',
                '$state',
                '$mdDialog',
                'logger',
                'cache',
                'EncounterType',
                'CarePlanProblemEntry',
                'PersistedItem',
                'options',
            ];
            return ChartingFamilyDiagnosisHistoryController;
        }());
        components.ChartingFamilyDiagnosisHistoryController = ChartingFamilyDiagnosisHistoryController;
        angular
            .module('consolo')
            .component('consoloChartingFamilyDiagnosisHistory', new ChartingFamilyDiagnosisHistorySection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingGeneralSection = /** @class */ (function () {
            function ConsoloChartingGeneralSection() {
                this.bindings = {
                    chartStore: '=',
                    formModel: '=',
                    formDefinition: '<',
                    currentPatient: '<',
                    viewOnly: '<',
                    callback: '&?',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/general.html';
                this.controller = ChartingGeneralController;
            }
            return ConsoloChartingGeneralSection;
        }());
        var ChartingGeneralController = /** @class */ (function (_super) {
            __extends(ChartingGeneralController, _super);
            function ChartingGeneralController($filter, $q, $mdDialog, $scope, cache, options, EncounterType, patientService, SectionManager, ChartDateTimeInteraction, Session, ChartingTemplateService, Permissions, CurrentPatient, ChartUtilService, EncounterTypes, $state, $window, MileageAppIntegrationService, logger, serviceService) {
                var _this = _super.call(this, $scope) || this;
                _this.$filter = $filter;
                _this.$q = $q;
                _this.$mdDialog = $mdDialog;
                _this.cache = cache;
                _this.options = options;
                _this.EncounterType = EncounterType;
                _this.patientService = patientService;
                _this.SectionManager = SectionManager;
                _this.ChartDateTimeInteraction = ChartDateTimeInteraction;
                _this.Session = Session;
                _this.ChartingTemplateService = ChartingTemplateService;
                _this.Permissions = Permissions;
                _this.CurrentPatient = CurrentPatient;
                _this.ChartUtilService = ChartUtilService;
                _this.EncounterTypes = EncounterTypes;
                _this.$state = $state;
                _this.$window = $window;
                _this.MileageAppIntegrationService = MileageAppIntegrationService;
                _this.logger = logger;
                _this.serviceService = serviceService;
                _this.allEncounterTypes = [];
                _this.encounterTypes = [];
                _this.baseRoles = [];
                _this.carePlanProblemList = [];
                _this.opened = false;
                _this.today = new Date();
                _this.hiddenFields = {};
                _this.requiredFields = {};
                _this.source_of_information = [];
                _this.purpose_of_visit = [];
                _this.hasVisitFrequencyEnabled = false;
                return _this;
            }
            ChartingGeneralController.prototype.$onInit = function () {
                var _this = this;
                var _a, _b, _c;
                if (this.viewOnly !== undefined && this.viewOnly !== null) {
                    this.readonly = this.viewOnly;
                }
                this.currentPatient = this.chartStore.model.patient || this.CurrentPatient.patient;
                this.isEncounterCharting = this.ChartingTemplateService.isEncounterCharting(this.chartStore.model.charting_client);
                this.user = this.Session.getUser();
                this.hasVisitFrequencyEnabled = (_c = (_b = (_a = this.Session) === null || _a === void 0 ? void 0 : _a.get()) === null || _b === void 0 ? void 0 : _b.agency) === null || _c === void 0 ? void 0 : _c.has_visit_frequency;
                this.$q.all([this.options.get('base_roles').$promise]).then(function (data) {
                    _this.baseRoles = data[0];
                    var exists = _this.baseRoles.filter(function (role) {
                        return role.id === _this.chartStore.model.role_id;
                    });
                    if (!exists.length || !_this.chartStore.model.role_id) {
                        //user default role is not a chart base_role
                        _this.chartStore.model.role_id = null;
                    }
                    // else if (this.chartStore.model.role_id && !this.chartStore.model.role_name) {
                    //   // this.chartStore.model.role_name = exists[0].name;
                    //   this.chartStore.model.role_name = this.baseRoles.filter((role) => {
                    //     return role.id === this.chartStore.model.role_id;
                    //   })[0].description;
                    // }
                    _this.activate();
                    _this.set_source_of_information();
                });
                this.setPurposeOfVisitOptions();
                if (this.formDefinition && Array.isArray(this.formDefinition) && this.formDefinition.length) {
                    for (var _i = 0, _d = this.formDefinition[0].fields; _i < _d.length; _i++) {
                        var field = _d[_i];
                        this.hiddenFields[field.field] = field._hidden;
                    }
                }
                this.visitClassification = this.options.warmCachePromise
                    ? this.options.warmCachePromise.then(function (p) { return _this.options.get('visit_classification'); })
                    : this.options.get('visit_classification');
                if (this.chartStore.model.create_new_care_plan_problem) {
                    this.createCarePlanProblem(null);
                }
            };
            ChartingGeneralController.prototype.activate = function () {
                var _this = this;
                if (this.chartStore.model.time_of_visit) {
                    this.chartStore.model.time_of_visit = new Date(this.chartStore.model.time_of_visit);
                    this.chartStore.model.effective_date = new Date(this.chartStore.model.time_of_visit.toDateString());
                }
                else if (this.chartStore.model.effective_date) {
                    this.chartStore.model.effective_date = new Date(this.chartStore.model.effective_date);
                }
                else {
                    this.chartStore.model.effective_date = new Date(new Date().toDateString());
                }
                if (!this.chartStore.model.locked && !this.chartStore.model.time_of_visit) {
                    this.chartStore.model.time_of_visit = new Date(this.chartStore.model.effective_date);
                }
                this.SectionManager.updateHasEncounterType(this.chartStore.model.encounter_type_id, this.chartStore.model.encounter_type);
                if (this.chartStore.model.role_id) {
                    this.readonly =
                        this.readonly || !this.ChartUtilService.checkChartEditable(this.chartStore.model);
                    this.chartStore.model.role_name = this.baseRoles.filter(function (role) {
                        return role.id === _this.chartStore.model.role_id;
                    })[0].description;
                }
                this.EncounterTypes.getAll(true).then(function (encounterTypes) {
                    var charting_types = [_this.chartStore.model.charting_client];
                    switch (charting_types[0]) {
                        case 'bootstrap_charting':
                            charting_types.push('tablet_optimized');
                            break;
                        case 'tablet_optimzied':
                            break;
                        case 'custom':
                            charting_types.push('encounter_charting');
                            break;
                        case 'encounter_charting':
                            charting_types.push('custom');
                    }
                    if (encounterTypes && encounterTypes.length) {
                        for (var _i = 0, encounterTypes_1 = encounterTypes; _i < encounterTypes_1.length; _i++) {
                            var et = encounterTypes_1[_i];
                            if (et.active) {
                                if (charting_types.indexOf(et.charting_type) >= 0) {
                                    _this.allEncounterTypes.push(et);
                                }
                            }
                        }
                    }
                    if (charting_types.indexOf('encounter_charting') >= 0) {
                        _this.allEncounterTypes.splice(0, 0, {
                            id: 'encounter_charting',
                            name: 'Default Encounter Charting',
                            display_name: 'Default Encounter Charting',
                            role_ids: [],
                        });
                    }
                    else if (_this.chartStore.model.encounter_type && _this.chartStore.model.encounter_type.lookup_name === 'tablet_optimized') {
                        // Add a 'Default' encounter type for converted optimized/classic charts without an encounter type.
                        // See clinical-charts.states.ts patients.clinical-chart-view and patients.clinical-chart-entry-edit states for where this is set
                        _this.allEncounterTypes.splice(0, 0, angular.copy(_this.chartStore.model.encounter_type));
                    }
                    _this.setEncounterTypes();
                    _this.getRequiredFields();
                    if (_this.$state.params.encounter_type_id &&
                        !_this.user.flags.has_encounter_charting &&
                        !_this.chartStore.model.encounter_type) {
                        _this.confirmEncounterType();
                    }
                });
                this.carePlanProblemList = this.SectionManager.carePlanProblemList;
                this.$scope.$watch('$ctrl.chartStore.model.role_id', function () {
                    _this.setEncounterTypes();
                    if (_this.chartStore.model.role_id) {
                        _this.chartStore.model.role_name = _this.baseRoles.filter(function (role) {
                            return role.id === _this.chartStore.model.role_id;
                        })[0].description;
                        _this.SectionManager.getChartLocked(_this.chartStore);
                    }
                    else {
                        _this.chartStore.model.role_name = null;
                    }
                });
            };
            ChartingGeneralController.prototype.setEncounterTypes = function () {
                var _this = this;
                if (!this.chartStore.model.role_id || this.allEncounterTypes.length === 0) {
                    return;
                }
                this.encounterTypes = this.$filter('filter')(this.allEncounterTypes, function (et) {
                    return (et.role_ids.indexOf(_this.chartStore.model.role_id) > -1 ||
                        et.id === 'encounter_charting' ||
                        et.id === -1);
                });
            };
            ChartingGeneralController.prototype.confirmEncounterType = function () {
                var _this = this;
                var encType = this.$filter('filter')(this.encounterTypes, function (b) {
                    return b.id === _this.formModel.encounter_type_id;
                });
                var missingPermissions = [];
                var sectionActions = ['create', 'update'];
                var section = null;
                Object.getOwnPropertyNames(encType[0].required_fields).forEach(function (a) {
                    section = _this.ChartingTemplateService.sectionSearchBy(a, 'name', _this.ChartingTemplateService.getTemplateName(_this.formModel));
                    if (!section) {
                        missingPermissions.push('Cannot find section for: ' + a);
                    }
                    else {
                        for (var _i = 0, sectionActions_1 = sectionActions; _i < sectionActions_1.length; _i++) {
                            var action = sectionActions_1[_i];
                            if (section.childSections) {
                                for (var _a = 0, _b = section.childSections; _a < _b.length; _a++) {
                                    var child = _b[_a];
                                    if (!child.noPermissions &&
                                        !_this.Permissions.can(action, child.model) &&
                                        !child.additionalSection) {
                                        missingPermissions.push(child.title);
                                    }
                                }
                            }
                            else {
                                if (!section.noPermissions &&
                                    !_this.Permissions.can(action, section.model) &&
                                    !section.additionalSection) {
                                    if (missingPermissions.indexOf(section.title) < 0) {
                                        missingPermissions.push(section.title);
                                    }
                                }
                            }
                        }
                    }
                });
                if (missingPermissions.length) {
                    this.formModel.encounter_type_id = null;
                    var sectionNames = '';
                    for (var _i = 0, missingPermissions_1 = missingPermissions; _i < missingPermissions_1.length; _i++) {
                        var s = missingPermissions_1[_i];
                        sectionNames += '<li>' + s + '</li>';
                    }
                    var alert_1 = this.$mdDialog
                        .alert()
                        .title('Missing Permissions')
                        .htmlContent('<br/>You cannot use this Encounter Type Template because you do not have permission for the following sections:<br/><ul>' +
                        sectionNames +
                        '</ul>Please choose another Encounter Type Template or contact your agency for assistance.')
                        .ok('OK');
                    this.$mdDialog.show(alert_1);
                    return;
                }
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Encounter Type')
                    .textContent('If you select an encounter type, you cannot change it. Are you sure?')
                    .ok('Yes')
                    .cancel('No');
                return this.$mdDialog.show(confirmation).then(function () {
                    _this.chartStore.model.encounter_type = encType[0];
                    _this.SectionManager.updateHasEncounterType(encType[0].id, encType[0]);
                    Object.getOwnPropertyNames(encType[0].required_fields).forEach(function (a) {
                        var section = _this.SectionManager.getByName(a);
                        _this.SectionManager.activateSection(section, false, _this.chartStore);
                        _this.ChartingTemplateService.chartStoreSectionInit(section, _this.chartStore, _this.currentPatient);
                        _this.saveDraft();
                    });
                    _this.getRequiredFields();
                    Object.getOwnPropertyNames(encType[0].patient_time_fields).forEach(function (a) {
                        var section = _this.SectionManager.getByName(a);
                        _this.SectionManager.activateSection(section, false, _this.chartStore);
                        _this.formModel[section.model] = {};
                        encType[0].patient_time_fields[a].forEach(function (b) {
                            if (b === 'in_date' || b === 'in_time') {
                                _this.formModel[section.model]['in_datetime'] = '';
                            }
                            if (b === 'out_date' || b === 'out_time') {
                                _this.formModel[section.model]['out_datetime'] = '';
                            }
                        });
                        _this.saveDraft();
                    });
                    Object.getOwnPropertyNames(encType[0].evv_fields).forEach(function (a) {
                        var section = _this.SectionManager.getByName(a);
                        _this.SectionManager.activateSection(section, false, _this.chartStore);
                        _this.formModel[section.model] = { signature_pad_png_base64: '' };
                        _this.saveDraft();
                    });
                }, function () {
                    _this.formModel.encounter_type_id = null;
                });
            };
            // checkChartPermissions() {
            // if (this.chartStore.model.locked || this.viewOnly) {
            //   this.readonly = true;
            // } else if (
            //   this.chartStore.model.user &&
            //   this.user &&
            //   this.user.id !== this.chartStore.model.user.id &&
            //   this.user.id !== this.chartStore.model.created_by.id
            // ) {
            //   let permission_role_symbol = this.chartStore.model.role_name
            //     ? this.baseRoles.filter(role => {
            //         return role.description === this.chartStore.model.role_name;
            //       })[0]['role_symbol']
            //     : '';
            //   let permission_action = 'edit_any_' + permission_role_symbol + '_chart';
            //   if (
            //     !this.Permissions.can(permission_action, 'clinical_charts') &&
            //     !this.Permissions.can('edit_any_clinical_chart', 'clinical_charts')
            //   ) {
            //     this.readonly = true;
            //   }
            // }
            // }
            ChartingGeneralController.prototype.selectUser = function () {
                if (this.chartStore.model.user) {
                    this.chartStore.model.user_id = this.chartStore.model.user.id;
                }
            };
            ChartingGeneralController.prototype.selectFacility = function () {
                if (this.chartStore.model.facility) {
                    this.chartStore.model.facility_id = this.chartStore.model.facility.id;
                }
            };
            ChartingGeneralController.prototype.checkFacility = function () {
                var _this = this;
                this.serviceService
                    .getCurrentFacility(this.currentPatient.id, new Date(this.chartStore.model.effective_date))
                    .then(function (response) {
                    if (JSON.stringify(response.data) !== '{}') {
                        _this.chartStore.model.facility = response.data;
                    }
                });
            };
            ChartingGeneralController.prototype.addToChart = function (cpp, chartStore) {
                this.SectionManager.addCarePlanProblemUpdate(cpp, chartStore);
            };
            ChartingGeneralController.prototype.createCarePlanProblem = function ($event) {
                var _this = this;
                this.showDialog($event, null).then(function (data) {
                    _this.SectionManager.carePlanProblemList.unshift(data);
                    if (_this.chartStore.model.create_new_care_plan_problem) {
                        _this.chartStore.model.create_new_care_plan_problem = false;
                        _this.SectionManager.addCarePlanProblemUpdate(data, _this.chartStore);
                    }
                });
            };
            ChartingGeneralController.prototype.viewCarePlanProblem = function ($event, cpp) {
                this.showDialog($event, cpp);
            };
            ChartingGeneralController.prototype.timeOfVisitChanged = function () {
                this.ChartDateTimeInteraction.constructor.changeToTimeOfVisit(this.chartStore);
            };
            ChartingGeneralController.prototype.effectiveDateChanged = function () {
                this.ChartDateTimeInteraction.constructor.changeToGeneralEffectiveDate(this.chartStore);
            };
            ChartingGeneralController.prototype.getRequiredFields = function () {
                var _this = this;
                if (this.chartStore.model.encounter_type_id) {
                    this.cache(this.EncounterType, 'encounter_types')
                        .query()
                        .$promise.then(function (data) {
                        var encType = _this.$filter('filter')(data, function (a) {
                            return a.id === _this.chartStore.model.encounter_type_id;
                        })[0];
                        if (encType && encType.field_logic && encType.field_logic['general']) {
                            for (var s in encType.field_logic['general']) {
                                if (encType.field_logic['general'].hasOwnProperty(s)) {
                                    if (encType.field_logic['general'][s].required) {
                                        _this.requiredFields[s] = true;
                                    }
                                    else if (encType.field_logic['general'][s].hidden) {
                                        _this.hiddenFields[s] = true;
                                    }
                                }
                            }
                        }
                    });
                }
            };
            ChartingGeneralController.prototype.showDialog = function ($event, cpp) {
                var url = '/y/templates/care-plans/care-plan-problem.html'; //tslint ignore:line
                return this.$mdDialog.show({
                    targetEvent: $event,
                    template: "\n          <md-dialog flex=\"80\" aria-label=\"Care Plan Problem Dialog\">\n            <md-dialog-content>\n             <div ng-include=\"'" + url + "'\"/>\n            </md-dialog-content>\n          </md-dialog>\n        ",
                    controller: 'CarePlanProblemController as vm',
                    bindToController: true,
                    locals: { problem: cpp, modal: true },
                    fullscreen: true,
                });
            };
            ChartingGeneralController.prototype.set_source_of_information = function () {
                this.source_of_information = this.ChartingTemplateService.source_of_information;
            };
            ChartingGeneralController.prototype.setPurposeOfVisitOptions = function () {
                var _this = this;
                this.$q.all([this.options.get('purpose_of_visit_options').$promise]).then(function (response) {
                    _this.purpose_of_visit = response[0];
                });
            };
            ChartingGeneralController.$inject = [
                '$filter',
                '$q',
                '$mdDialog',
                '$scope',
                'cache',
                'options',
                'EncounterType',
                'patientService',
                'ChartingSectionManager',
                'ChartDateTimeInteraction',
                'SessionService',
                'ChartingTemplateService',
                'PermissionsService',
                'CurrentPatient',
                'ChartUtilService',
                'EncounterTypes',
                '$state',
                '$window',
                'MileageAppIntegrationService',
                'logger',
                'serviceService'
            ];
            return ChartingGeneralController;
        }(consolo.controllers.BaseCustomComponentController));
        components.ChartingGeneralController = ChartingGeneralController;
        angular
            .module('consolo')
            .component('consoloChartingGeneral', new ConsoloChartingGeneralSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ChartingHistoryOfPresentIllnessSection = /** @class */ (function () {
            function ChartingHistoryOfPresentIllnessSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    viewOnly: '<',
                    formDefinition: '<',
                    showUnmodifiedSections: '=',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/history-of-present-illness.html';
                this.controller = ChartingHistoryOfPresentIllnessController;
            }
            return ChartingHistoryOfPresentIllnessSection;
        }());
        var ChartingHistoryOfPresentIllnessController = /** @class */ (function () {
            function ChartingHistoryOfPresentIllnessController($filter, $scope, SectionManager, options, $parse, EncounterType, cache, Session, $mdPanel, chartingTemplateService) {
                this.$filter = $filter;
                this.$scope = $scope;
                this.SectionManager = SectionManager;
                this.$parse = $parse;
                this.EncounterType = EncounterType;
                this.cache = cache;
                this.Session = Session;
                this.$mdPanel = $mdPanel;
                this.chartingTemplateService = chartingTemplateService;
                this.requiredFields = {};
                this.hiddenFields = {};
                this.diagnosis_information_obtained_options = [];
                this.allergy_reactions_options = [];
                this.allergyTableHasData = false;
                this.errorFields = [];
                this.allergy_reactions_options = options.get('allergy_reactions_options');
                this.diagnosis_information_obtained_options = options.get('diagnosis_information_obtained_options');
            }
            ChartingHistoryOfPresentIllnessController.prototype.$onInit = function () {
                var _this = this;
                this.readonly = this.chartStore.model.locked || this.viewOnly;
                this.loadFormDefinition(null);
                this.$scope.$on('reloadFormDefinition', function (caller, errors) {
                    _this.loadFormDefinition(errors);
                });
            };
            ChartingHistoryOfPresentIllnessController.prototype.loadFormDefinition = function (errors) {
                var _this = this;
                var errorList = this.$parse('errors')(errors);
                if (errorList) {
                    this.errorFields = errorList['history_of_present_illness'];
                }
                if (!this.formModel.allergy_table || !this.formModel.allergy_table.length) {
                    this.formModel.allergy_table = [];
                    this.addAllergy();
                }
                if (this.chartStore.model.encounter_type_id) {
                    this.cache(this.EncounterType, 'encounter_types')
                        .query()
                        .$promise.then(function (data) {
                        var encType = _this.$filter('filter')(data, function (a) {
                            return a.id === _this.chartStore.model.encounter_type_id;
                        })[0];
                        if (encType && encType.field_logic && encType.field_logic['history_of_present_illness']) {
                            for (var s in encType.field_logic['history_of_present_illness']) {
                                if (encType.field_logic['history_of_present_illness'].hasOwnProperty(s)) {
                                    if (encType.field_logic['history_of_present_illness'][s].required) {
                                        _this.requiredFields[s] = true;
                                    }
                                    else if (encType.field_logic['history_of_present_illness'][s].hidden) {
                                        _this.hiddenFields[s] = true;
                                    }
                                }
                            }
                        }
                        if (encType && encType.required_fields['history_of_present_illness']) {
                            encType.required_fields['history_of_present_illness'].forEach(function (s) {
                                if (!_this.requiredFields) {
                                    _this.requiredFields = {};
                                }
                                _this.requiredFields[s] = true;
                            });
                        }
                        _this.allergyTableHasData = _this.chartingTemplateService.checkIfDataExistinTable(_this.formModel.allergy_table);
                    });
                }
            };
            ChartingHistoryOfPresentIllnessController.prototype.$onChanges = function () { };
            ChartingHistoryOfPresentIllnessController.prototype.addAllergy = function () {
                // Add a new allergy object to the allergies array
                this.formModel.allergy_table.push({
                    allergiesReported: '',
                    selectedAllergyReactions: ''
                });
            };
            ChartingHistoryOfPresentIllnessController.prototype.deleteAllergy = function (index) {
                // Remove the allergy at the specified index from the allergies array
                this.formModel.allergy_table.splice(index, 1);
            };
            ChartingHistoryOfPresentIllnessController.$inject = [
                '$filter',
                '$scope',
                'ChartingSectionManager',
                'options',
                '$parse',
                'EncounterType',
                'cache',
                'SessionService',
                '$mdPanel',
                'ChartingTemplateService'
            ];
            return ChartingHistoryOfPresentIllnessController;
        }());
        angular.module('consolo')
            .component('consoloChartingHistoryOfPresentIllness', new ChartingHistoryOfPresentIllnessSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var ChartingInpatientUnitNoteSection = /** @class */ (function () {
            function ChartingInpatientUnitNoteSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/inpatient-unit-note.html';
                this.controller = ChartingInpatientUnitNoteController;
            }
            return ChartingInpatientUnitNoteSection;
        }());
        var ChartingInpatientUnitNoteController = /** @class */ (function () {
            function ChartingInpatientUnitNoteController($scope, ChartingTemplateService, chartUtilService) {
                this.$scope = $scope;
                this.ChartingTemplateService = ChartingTemplateService;
                this.chartUtilService = chartUtilService;
                this.readOnlySections = [];
                this.yes_but = 'Yes But Patient/Responsible Party Refused to Discuss';
            }
            ChartingInpatientUnitNoteController.prototype.$onInit = function () {
                var _this = this;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                this.loadFormDefinition();
                if (!this.formModel.in_datetime) {
                    consolo.services.ClinicalChartDateTimeInteraction.initializeInpatientUnitNote(this.chartStore);
                }
                this.$scope.$watch('$ctrl.formModel.in_datetime', function (newValue, oldValue) {
                    if (!oldValue) {
                        return;
                    }
                    if (_this.formModel.in_datetime &&
                        new Date(newValue).getTime() !== new Date(oldValue).getTime()) {
                        consolo.services.ClinicalChartDateTimeInteraction.changeToInpatientUnitNoteTimeIn(_this.chartStore);
                    }
                });
                this.$scope.$watch('$ctrl.formModel.out_datetime', function (newValue, oldValue) {
                    if (!oldValue) {
                        return;
                    }
                    if (_this.formModel.out_datetime &&
                        new Date(newValue).getTime() !== new Date(oldValue).getTime()) {
                        consolo.services.ClinicalChartDateTimeInteraction.changeToInpatientUnitNoteTimeOut(_this.chartStore);
                    }
                });
            };
            ChartingInpatientUnitNoteController.prototype.loadFormDefinition = function () {
                this.formDefinition = this.ChartingTemplateService.getFormDefinition('inpatient_unit_note', this.chartStore.model.encounter_type_id, this.chartStore.model.patient_id, this.chartStore.model.encounter_type);
                this.readOnlySections = this.chartUtilService.getReadOnlyFields('inpatient_unit_notes', this.chartStore);
            };
            ChartingInpatientUnitNoteController.$inject = ['$scope', 'ChartingTemplateService', 'ChartUtilService'];
            return ChartingInpatientUnitNoteController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingInpatientUnitNote', new ChartingInpatientUnitNoteSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var ChartingMiniNutritionalAssessmentSection = /** @class */ (function () {
            function ChartingMiniNutritionalAssessmentSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/mini-nutritional-assessment.html';
                this.controller = ChartingMiniNutritionalAssessmentController;
            }
            return ChartingMiniNutritionalAssessmentSection;
        }());
        var ChartingMiniNutritionalAssessmentController = /** @class */ (function () {
            function ChartingMiniNutritionalAssessmentController() {
                this.mnaFields = [
                    'mna_lives_independent',
                    'mna_prescription_drugs',
                    'mna_sores',
                    'mna_full_meals',
                    'mna_consumption_dairy',
                    'mna_consumption_legumes',
                    'mna_consumption_meat',
                    'mna_vegetables',
                    'mna_fluid',
                    'mna_feeding_mode',
                    'mna_self_view',
                    'mna_self_view_comparison',
                    'mna_mac_score',
                    'mna_cc',
                ];
                this.screeningFields = [
                    'screening_food_intake',
                    'screening_weight_loss',
                    'screening_mobility',
                    'screening_stress',
                    'screening_neuropsychological',
                    'screening_bmi',
                ];
                this.foodIntakes = [
                    { id: 0, description: 'Severe loss of appetite' },
                    { id: 1, description: 'Moderate loss of appetite' },
                    { id: 2, description: 'No loss of appetite' },
                ];
                this.weightLoss = [
                    { id: 0, description: 'Weight loss greater than 6.6 lb' },
                    { id: 1, description: 'Does not know' },
                    { id: 2, description: 'Weight loss between 2.2 and 4.4 lb' },
                    { id: 3, description: 'No weight loss' },
                ];
                this.mobility = [
                    { id: 0, description: 'Bed or chair bound' },
                    { id: 1, description: 'Able to get out of bed/chair but does not go out' },
                    { id: 2, description: 'Goes out' },
                ];
                this.psychStress = [{ id: 0, description: 'Yes' }, { id: 2, description: 'No' }];
                this.neuroPsych = [
                    { id: 0, description: 'Severe dementia or depression' },
                    { id: 1, description: 'Mild dementia' },
                    { id: 2, description: 'No problems' },
                ];
                this.bmis = [
                    { id: 0, description: 'BMI less than 19' },
                    { id: 1, description: 'BMI 19 to less than 21' },
                    { id: 2, description: 'BMI 21 to less than 23' },
                    { id: 3, description: 'BMI 23 or greater' },
                ];
                this.livesIndependent = [{ id: 0, description: 'No' }, { id: 1, description: 'Yes' }];
                this.threePlusRx = [{ id: 0, description: 'Yes' }, { id: 1, description: 'No' }];
                this.skinSores = [{ id: 0, description: 'Yes' }, { id: 1, description: 'No' }];
                this.fullMeals = [
                    { id: 0, description: '1' },
                    { id: 1, description: '2' },
                    { id: 2, description: '3' },
                ];
                this.dairy = [{ id: 0.0, description: 'No' }, { id: 0.5, description: 'Yes' }];
                this.legumes = [{ id: 0.0, description: 'No' }, { id: 0.5, description: 'Yes' }];
                this.meat = [{ id: 0.0, description: 'No' }, { id: 0.5, description: 'Yes' }];
                this.plants = [{ id: 0, description: 'No' }, { id: 1, description: 'Yes' }];
                this.fluids = [
                    { id: 0.0, description: 'Less than 3 cups' },
                    { id: 0.5, description: '3 to 5 cups' },
                    { id: 1.0, description: 'More than 5 cups' },
                ];
                this.feedingMode = [
                    { id: 0, description: 'Unable to eat without assistance' },
                    { id: 1, description: 'Self-fed with some difficulty' },
                    { id: 2, description: 'Self-fed without any problems' },
                ];
                this.selfView = [
                    { id: 0, description: 'Views self as being subject to excessive weight loss' },
                    { id: 1, description: 'Is uncertain of nutritional state' },
                    { id: 2, description: 'Views self as having no nutritional problem' },
                ];
                this.selfViewComparison = [
                    { id: 0.0, description: 'Not as good' },
                    { id: 0.5, description: 'Does not know' },
                    { id: 1.0, description: 'As good' },
                    { id: 2.0, description: 'Better' },
                ];
                this.midArmCirc = [
                    { id: 0.0, description: 'MAC less than 21' },
                    { id: 0.5, description: 'MAC 21 to 22' },
                    { id: 1.0, description: 'MAC 22 or greater' },
                ];
                this.calfCirc = [
                    { id: 0, description: 'CC less than 31' },
                    { id: 1, description: 'CC 31 or greater' },
                ];
                // super.loadFormDefinition(null);
                // $scope.$on('reloadFormDefinition', () => {
                //   super.loadFormDefinition(null);
                // });
            }
            ChartingMiniNutritionalAssessmentController.prototype.$onInit = function () {
                this.viewOnly = this.viewOnly || this.chartStore.model.locked;
                this.miniNutritionalFormModel = this.formModel;
            };
            ChartingMiniNutritionalAssessmentController.prototype.total = function (fields) {
                var _this = this;
                return fields
                    .map(function (field) {
                    return _this.formModel[field] || 0;
                })
                    .reduce(function (a, n) {
                    return a + n;
                }, 0);
            };
            ChartingMiniNutritionalAssessmentController.prototype.updateTotals = function () {
                this.formModel.total_screening = this.total(this.screeningFields);
                this.formModel.total_mna = this.total(this.mnaFields);
                this.formModel.total_assessment = this.formModel.total_screening + this.formModel.total_mna;
            };
            ChartingMiniNutritionalAssessmentController.$inject = [];
            return ChartingMiniNutritionalAssessmentController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingMiniNutritionalAssessment', new ChartingMiniNutritionalAssessmentSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('oxygenSafetyAssessment', {
            bindings: {
                chartStore: '=',
                formModel: '<',
                formDefinition: '<',
                viewOnly: '<',
            },
            templateUrl: '/y/templates/clinical-charts/components/oxygen-safety-assessment.html',
            controller: 'OxygenSafetyAssessmentController as $ctrl',
        });
        var OxygenSafetyAssessmentController = /** @class */ (function () {
            function OxygenSafetyAssessmentController(CurrentPatient, PermissionsService) {
                this.CurrentPatient = CurrentPatient;
                this.PermissionsService = PermissionsService;
                this.showPermission = PermissionsService.can('show', 'oxygen_safety_assessment');
                this.updatePermission = PermissionsService.can('update', 'oxygen_safety_assessment');
                this.createPermission = PermissionsService.can('create', 'oxygen_safety_assessment');
                if ((!this.updatePermission && this.chartStore.model.id) || (!this.createPermission && !this.chartStore.model.id)) {
                    this.viewOnly = true;
                }
            }
            OxygenSafetyAssessmentController.$inject = ['CurrentPatient', 'PermissionsService'
            ];
            return OxygenSafetyAssessmentController;
        }());
        common.OxygenSafetyAssessmentController = OxygenSafetyAssessmentController;
        angular
            .module('consolo')
            .controller('OxygenSafetyAssessmentController', OxygenSafetyAssessmentController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var ConsoloChartingPainObservationSection = /** @class */ (function () {
            function ConsoloChartingPainObservationSection() {
                this.bindings = {
                    chartStore: '=',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/pain-observation.html';
                this.controller = ConsoloChartingPainObservationController;
            }
            return ConsoloChartingPainObservationSection;
        }());
        var ConsoloChartingPainObservationController = /** @class */ (function () {
            function ConsoloChartingPainObservationController($filter, $scope, SectionManager, options, $parse, EncounterType, cache, Session, $mdPanel) {
                this.$filter = $filter;
                this.$scope = $scope;
                this.SectionManager = SectionManager;
                this.options = options;
                this.$parse = $parse;
                this.EncounterType = EncounterType;
                this.cache = cache;
                this.Session = Session;
                this.$mdPanel = $mdPanel;
                this.requiredFields = {};
                this.hiddenFields = {};
                this.painIndicatorsAddressed = [];
                // this.formModel = this.chartStore.model.pain_observations;
                this.careTypes = options.get('clinical_care_types');
                this.discomfort = options.get('discomfort');
                this.discomfortNoAnswerReasons = options.get('discomfort_no_answer_reasons');
                this.errorFields = [];
                this.flaccFace = options.get('flacc_face');
                this.flaccLegs = options.get('flacc_legs');
                this.flaccActivity = options.get('flacc_activity');
                this.flaccCry = options.get('flacc_cry');
                this.flaccConsolability = options.get('flacc_consolability');
                this.painadBodyLang = options.get('painad_body_lang');
                this.painadBreathingRatings = options.get('painadd_breathing_ratings');
                this.painadConsolability = options.get('painadd_consolability');
                this.painadFacial = options.get('painadd_facial');
                this.painadNegVoc = options.get('painadd_neg_voc');
                this.painFrequencies = options.get('pain_frequencies');
                this.painTypes = options.get('pain_types');
                this.other_indications_of_pain = options.get('other_indications_of_pain');
                // this.requiredFields = [];
                this.scaleTypes = [
                    { id: 'Verbal', description: 'Verbal' },
                    { id: 'PAINAD', description: 'PAINAD' },
                    { id: 'FLACC', description: 'FLACC' },
                ];
                this.yesNo = [{ id: '' }, { id: 'Yes' }, { id: 'No' }];
                this.today = new Date();
                this.comprehensiveAssessmentRequired = false;
            }
            ConsoloChartingPainObservationController.prototype.$onInit = function () {
                var _this = this;
                this.readonly = this.chartStore.model.locked || this.viewOnly;
                this.loadFormDefinition(null);
                this.$scope.$on('reloadFormDefinition', function (caller, errors) {
                    _this.loadFormDefinition(errors);
                });
                if (this.formModel.scale_type === 'Faces' || this.Session.get().agency.has_wong_license) {
                    this.scaleTypes.push({ id: 'Faces', description: 'Wong-Baker FACES' });
                }
            };
            ConsoloChartingPainObservationController.prototype.$onChanges = function () { };
            ConsoloChartingPainObservationController.prototype.activePainChanged = function (item) {
                if (item.active_pain === 'Yes') {
                    item.comprehensiveAssessmentRequired = true;
                }
                else {
                    item.comprehensiveAssessmentRequired = false;
                }
            };
            ConsoloChartingPainObservationController.prototype.updatePainIndicators = function (item, name) {
                if (!this.painIndicatorsAddressed) {
                    this.painIndicatorsAddressed = [];
                }
                var index = this.painIndicatorsAddressed.indexOf(name);
                var arr_name = '';
                if (name === 'relieved_by' || name === 'worsened_by') {
                    arr_name = 'relieved_worsened_by';
                    index = this.painIndicatorsAddressed.indexOf(arr_name);
                }
                if (item[name] && index === -1) {
                    this.painIndicatorsAddressed.push(arr_name ? arr_name : name);
                }
                else if (!item[name] && index !== -1) {
                    if (name === 'relieved_by' && item.worsened_by) {
                        return;
                    }
                    else if (name === 'worsened_by' && item.relieved_by) {
                        return;
                    }
                    else {
                        this.painIndicatorsAddressed.splice(index, 1);
                    }
                }
            };
            ConsoloChartingPainObservationController.prototype.checkPainIndicators = function () {
                if (this.formModel) {
                    var arrPainIndicators = [
                        'location',
                        'severity',
                        'character',
                        'duration',
                        'frequency',
                        'relieved_by',
                        'worsened_by',
                        'effect_on_quality_of_life_note',
                    ];
                    for (var _i = 0, arrPainIndicators_1 = arrPainIndicators; _i < arrPainIndicators_1.length; _i++) {
                        var indicator = arrPainIndicators_1[_i];
                        this.updatePainIndicators(this.formModel, indicator);
                    }
                    this.activePainChanged(this.formModel);
                }
            };
            ConsoloChartingPainObservationController.prototype.isAideChart = function () {
                return (this.chartStore.model.charting_client === 'bootstrap_aide_charting' ||
                    this.chartStore.model.charting_client === 'ios_aide_app');
            };
            ConsoloChartingPainObservationController.prototype.aideChartIsVerbal = function () {
                return (this.formModel.present_intensity !== null &&
                    typeof this.formModel.present_intensity !== 'undefined');
            };
            ConsoloChartingPainObservationController.prototype.loadFormDefinition = function (errors) {
                var _this = this;
                var errorList = this.$parse('errors')(errors);
                if (errorList) {
                    this.errorFields = errorList['pain_observations'];
                }
                if (this.chartStore.model.encounter_type_id) {
                    this.cache(this.EncounterType, 'encounter_types')
                        .query()
                        .$promise.then(function (data) {
                        var encType = _this.$filter('filter')(data, function (a) {
                            return a.id === _this.chartStore.model.encounter_type_id;
                        })[0];
                        if (encType && encType.field_logic && encType.field_logic['pain_observation']) {
                            for (var s in encType.field_logic['pain_observation']) {
                                if (encType.field_logic['pain_observation'].hasOwnProperty(s)) {
                                    if (encType.field_logic['pain_observation'][s].required) {
                                        _this.requiredFields[s] = true;
                                    }
                                    else if (encType.field_logic['pain_observation'][s].hidden) {
                                        _this.hiddenFields[s] = true;
                                    }
                                }
                            }
                        }
                        if (encType && encType.required_fields['pain_observation']) {
                            encType.required_fields['pain_observation'].forEach(function (s) {
                                if (!_this.requiredFields) {
                                    _this.requiredFields = {};
                                }
                                _this.requiredFields[s] = true;
                            });
                        }
                    });
                }
                this.checkPainIndicators();
            };
            ConsoloChartingPainObservationController.prototype.faceClick = function (face) {
                if (!this.readonly) {
                    this.formModel.present_intensity = face;
                }
            };
            ConsoloChartingPainObservationController.prototype.facesInfoPanel = function ($event) {
                var infoPanelTemplate = "\n      <div class=\"info-panel faces-info-panel\" md-whiteframe=\"6\" flex-xs=\"100\" flex-gt-xs=\"75\" flex-lg=\"50\">\n        <h2>Wong-Baker Faces Pain Rating Scale</h2>\n        <h3 style=\"text-decoration: underline;\">Instructions for Usage</h3>\n        <p>\n          Explain to the person that each face represents a person who has no pain (hurt), or some,\n          or a lot of pain.\n        </p>\n        <p>\n          Face 0 doesn't hurt at all. Face 2 hurts just a little bit. Face 4 hurts a little bit\n          more. Face 6 hurts even more. Face 8 hurt a whole lot. Face 10 hurts as much as you can\n          imagine, although you don't have to be crying to have this worst pain\n        </p>\n        <p>Ask the person to choose the face that best depicts the pain they are experiencing</p>\n        <div style=\"font-size:8pt;\">\n          &copy;1983 Wong-Baker FACES Foundation.\n          <a href=\"http://www.wongbakerfaces.org\" target=\"_blank\">www.WongBakerFACES.org</a>\n          <br />\n          Used with permission.\n        </div>\n      </div>";
                function InfoPanelControler() { }
                var panelPosition = this.$mdPanel
                    .newPanelPosition()
                    .absolute()
                    .center();
                var config = {
                    targetEvent: $event,
                    id: 'painObservationInfoPanel',
                    attachTo: angular.element(document.body),
                    controller: InfoPanelControler,
                    controllerAs: '$ctrl',
                    template: infoPanelTemplate,
                    position: panelPosition,
                    panelClass: 'info-panel-container',
                    zIndex: 100,
                    hasBackdrop: false,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                };
                this.$mdPanel.open(config);
            };
            ConsoloChartingPainObservationController.$inject = [
                '$filter',
                '$scope',
                'ChartingSectionManager',
                'options',
                '$parse',
                'EncounterType',
                'cache',
                'SessionService',
                '$mdPanel',
            ];
            return ConsoloChartingPainObservationController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingPainObservation', new ConsoloChartingPainObservationSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('pastProblems', {
            bindings: {},
            templateUrl: '/y/templates/clinical-charts/components/past-problems-modal.html',
            controller: 'PastProblemsController as $ctrl',
        });
        var PastProblemsController = /** @class */ (function () {
            function PastProblemsController($mdDialog, ProblemListItemService) {
                this.$mdDialog = $mdDialog;
                this.ProblemListItemService = ProblemListItemService;
                this.problemsTableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Code', field: 'snomed_problem.snomed_cid' },
                        {
                            title: 'Problem Description',
                            field: 'snomed_problem.description',
                        },
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'End Date',
                            field: 'end_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Chronicity',
                            field: 'chronicity',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'ICD 10(s)',
                            field: 'health_conditions',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (item) {
                                if (item.health_conditions) {
                                    return item.health_conditions.map(function (icd10) {
                                        return icd10.code;
                                    });
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                    ],
                };
            }
            PastProblemsController.$inject = ['$mdDialog', 'ProblemListItemService'];
            return PastProblemsController;
        }());
        common.PastProblemsController = PastProblemsController;
        angular.module('consolo').controller('PastProblemsController', PastProblemsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientDocs;
    (function (patientDocs) {
        var ConsoloChartingPatientDocumentUpload = /** @class */ (function () {
            function ConsoloChartingPatientDocumentUpload() {
                this.bindings = {
                    chartStore: '<',
                    viewOnly: '<',
                    upload: '=',
                    patientId: '<',
                    afterRemove: '&',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/patient-document-upload.html';
                this.controller = ChartingPatientDocumentUploadController;
            }
            return ConsoloChartingPatientDocumentUpload;
        }());
        var ChartingPatientDocumentUploadController = /** @class */ (function () {
            function ChartingPatientDocumentUploadController($filter, $http, options, logger) {
                this.$filter = $filter;
                this.$http = $http;
                this.options = options;
                this.logger = logger;
                this.documentDate = new Date();
                this.saving = false;
            }
            ChartingPatientDocumentUploadController.prototype.$onInit = function () {
                var _this = this;
                if (this.upload.state === consolo.uploads.UploadState.Complete) {
                    this.model = this.upload;
                }
                else {
                    this.model = {
                        patient_id: this.patientId,
                        name: this.upload.name.replace(/\.\w+$/, ''),
                        tags: [],
                    };
                    this.upload['patient_id'] = this.patientId;
                    this.upload['name'] = this.upload.name.replace(/\.\w+$/, '');
                    this.upload['tags'] = [];
                    this.upload.promise.then(function () {
                        _this.upload['document_date'] = _this.documentDate;
                    });
                }
                this.tags = this.options.get('document_types');
            };
            ChartingPatientDocumentUploadController.prototype.remove = function () {
                if (this.upload.state !== consolo.uploads.UploadState.Complete) {
                    this.saving = false;
                    this.upload.cancel();
                }
                this.afterRemove(this.upload);
            };
            ChartingPatientDocumentUploadController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            ChartingPatientDocumentUploadController.$inject = ['$filter', '$http', 'options', 'logger'];
            return ChartingPatientDocumentUploadController;
        }());
        patientDocs.ChartingPatientDocumentUploadController = ChartingPatientDocumentUploadController;
        angular
            .module('consolo')
            .component('consoloChartingPatientDocumentUpload', new ConsoloChartingPatientDocumentUpload());
    })(patientDocs = consolo.patientDocs || (consolo.patientDocs = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingPatientTimeSection = /** @class */ (function () {
            function ConsoloChartingPatientTimeSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                    showChartInfo: '<',
                    callback: '&?',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/patient-time.html';
                this.controller = ChartingPatientTimeController;
            }
            return ConsoloChartingPatientTimeSection;
        }());
        var ChartingPatientTimeController = /** @class */ (function (_super) {
            __extends(ChartingPatientTimeController, _super);
            function ChartingPatientTimeController($scope, options, SectionManager, $filter, cache, EncounterType, Session) {
                var _this = _super.call(this, $scope) || this;
                _this.options = options;
                _this.SectionManager = SectionManager;
                _this.$filter = $filter;
                _this.cache = cache;
                _this.EncounterType = EncounterType;
                _this.Session = Session;
                _this.showContCareCheckbox = false;
                _this.today = new Date();
                _this.hasLoaded = false;
                _this.requiredFields = {};
                _this.hiddenFields = {};
                return _this;
            }
            ChartingPatientTimeController.prototype.$onInit = function () {
                var _this = this;
                if (this.chartStore.model.encounter_type_id) {
                    this.cache(this.EncounterType, 'encounter_types')
                        .query()
                        .$promise.then(function (data) {
                        var encType = _this.$filter('filter')(data, function (a) {
                            return a.id === _this.chartStore.model.encounter_type_id;
                        })[0];
                        var tempReq = encType && encType.patient_time_fields && encType.patient_time_fields.patient_time
                            ? encType.patient_time_fields.patient_time
                            : [];
                        if (encType && encType.required_fields && encType.required_fields.patient_time) {
                            tempReq = tempReq.concat(encType.required_fields.patient_time);
                        }
                        _this.requiredFields = {};
                        for (var _i = 0, tempReq_1 = tempReq; _i < tempReq_1.length; _i++) {
                            var f = tempReq_1[_i];
                            _this.requiredFields[f] = true;
                        }
                    });
                }
                var user = this.Session.get().user;
                if (this.Session.get().agency.automatic_continuous_care_calculations &&
                    (["RN", "LPN/LVN", "Hospice Aide"].indexOf(this.chartStore.model.role_name) > -1 ||
                        (["Case Manager", "Skilled Nurse"].indexOf(this.chartStore.model.role_name) > -1 &&
                            user.role_names.indexOf("RN") > -1 || user.role_names.indexOf("LPN/LVN") > -1))) {
                    this.showContCareCheckbox = true;
                    if (this.chartStore.model.patient.level_of_care === "CONT") {
                        this.formModel.counts_for_cont_care = true;
                    }
                }
                if (this.formDefinition && Array.isArray(this.formDefinition) && this.formDefinition.length) {
                    for (var _i = 0, _a = this.formDefinition[0].fields; _i < _a.length; _i++) {
                        var field = _a[_i];
                        this.hiddenFields[field.field] = field._hidden;
                    }
                }
            };
            ChartingPatientTimeController.prototype.$onChanges = function (changeObj) {
                if (this.formModel && !this.hasLoaded) {
                    //   if (this.formModel.out_datetime && (new Date(newValue)).getTime() !== (new Date(oldValue)).getTime()) {
                    //     consolo.services.ClinicalChartDateTimeInteraction.changeToPatientTimeOut(this.chartStore);
                    //   }
                    this.old_dateIn = this.formModel.in_datetime;
                    this.old_dateOut = this.formModel.out_datetime;
                    if (!this.formModel.in_datetime) {
                        consolo.services.ClinicalChartDateTimeInteraction.initializePatientTime(this.chartStore);
                    }
                    // this.formModel = this.chartStore.model.patient_time;
                    if (this.formModel.out_of_pocket) {
                        this.out_of_pocket = this.formModel.out_of_pocket;
                    }
                    // All travel time is before the visit
                    this.travel_start_date = this.formModel.in_datetime
                        ? this.formModel.in_datetime
                        : new Date();
                    this.travel_stop_date = this.formModel.in_datetime
                        ? this.formModel.in_datetime
                        : new Date();
                    // $scope.$watch('$ctrl.formModel.in_datetime', (newValue, oldValue) => {
                    //   if (!oldValue) { return; }
                    //   if (this.formModel.in_datetime && (new Date(newValue)).getTime() !== (new Date(oldValue)).getTime()) {
                    //     // consolo.services.ClinicalChartDateTimeInteraction.changeToPatientTimeIn(this.chartStore);
                    //     this.travel_start_date = this.formModel.in_datetime;
                    //     if (this.formModel.travel_start_datetime) {
                    //       this.travel_start = this.formModel.travel_start_datetime;
                    //     }
                    //     if (this.formModel.travel_start_datetime) {
                    //       this.travel_stop = this.formModel.travel_stop_datetime;
                    //     }
                    //   }
                    // });
                    // $scope.$watch('$ctrl.formModel.out_datetime', (newValue, oldValue) => {
                    //   if (!oldValue) { return; }
                    //   if (this.formModel.out_datetime && (new Date(newValue)).getTime() !== (new Date(oldValue)).getTime()) {
                    //     consolo.services.ClinicalChartDateTimeInteraction.changeToPatientTimeOut(this.chartStore);
                    //   }
                    // });
                    if (this.formModel.travel_start_datetime) {
                        this.travel_start = this.travel_start_date = this.formModel.travel_start_datetime;
                    }
                    if (this.formModel.travel_stop_datetime) {
                        this.travel_stop = this.travel_stop_date = this.formModel.travel_stop_datetime;
                    }
                    this.careTypes = this.options.get('clinical_care_types');
                    this.showMileage = !this.chartStore.model.clinical_trip_stop_point_id;
                    this.hasLoaded = true;
                }
            };
            ChartingPatientTimeController.prototype.travelStartChange = function () {
                if (this.travel_start) {
                    this.formModel.travel_start_datetime = consolo.common.combineDateTime(this.travel_start_date, this.travel_start);
                    this.saveDraft();
                }
                else {
                    this.formModel.travel_start_datetime = null;
                    this.saveDraft();
                }
            };
            ChartingPatientTimeController.prototype.travelStopChange = function () {
                if (this.travel_stop) {
                    this.formModel.travel_stop_datetime = consolo.common.combineDateTime(this.travel_stop_date, this.travel_stop);
                }
                else {
                    this.formModel.travel_stop_datetime = null;
                    this.saveDraft();
                }
            };
            ChartingPatientTimeController.prototype.dateOutChanged = function () {
                if (this.formModel.out_datetime &&
                    new Date(this.formModel.out_datetime).getTime() !== new Date(this.old_dateOut).getTime()) {
                    consolo.services.ClinicalChartDateTimeInteraction.changeToPatientTimeOut(this.chartStore);
                    this.old_dateOut = this.formModel.out_datetime.toString();
                }
            };
            ChartingPatientTimeController.prototype.dateInChanged = function () {
                if (this.formModel.in_datetime &&
                    new Date(this.formModel.in_datetime).getTime() !== new Date(this.old_dateIn).getTime()) {
                    consolo.services.ClinicalChartDateTimeInteraction.changeToPatientTimeIn(this.chartStore);
                    this.travel_start_date = this.formModel.in_datetime;
                    this.travel_stop_date = this.formModel.in_datetime;
                    if (this.formModel.travel_start_datetime) {
                        this.travel_start = this.formModel.travel_start_datetime;
                        this.travel_stop = this.formModel.travel_stop_datetime;
                    }
                    this.old_dateIn = this.formModel.in_datetime.toString();
                }
            };
            ChartingPatientTimeController.prototype.removeDollarSign = function () {
                this.formModel.out_of_pocket = this.out_of_pocket.replace('$', '');
            };
            ChartingPatientTimeController.$inject = [
                '$scope',
                'options',
                'ChartingSectionManager',
                '$filter',
                'cache',
                'EncounterType',
                'SessionService'
            ];
            return ChartingPatientTimeController;
        }(consolo.controllers.BaseCustomComponentController));
        components.ChartingPatientTimeController = ChartingPatientTimeController;
        angular
            .module('consolo')
            .component('consoloChartingPatientTime', new ConsoloChartingPatientTimeSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('pediatricFallRiskAssessment', {
            bindings: {
                chartStore: '=',
                formModel: '<',
                formDefinition: '<',
                viewOnly: '<',
            },
            templateUrl: '/y/templates/clinical-charts/components/pediatric-fall-risk-assessment.html',
            controller: 'PediatricFallRiskAssessmentController as $ctrl',
        });
        var PediatricFallRiskAssessmentController = /** @class */ (function () {
            function PediatricFallRiskAssessmentController(PermissionsService) {
                this.PermissionsService = PermissionsService;
                this.options = [{ id: true, description: 'Yes' }, { id: false, description: 'No' }];
                this.showPermission = PermissionsService.can('show', 'pediatric_fall_risk_assessment');
                this.updatePermission = PermissionsService.can('update', 'pediatric_fall_risk_assessment');
                this.createPermission = PermissionsService.can('create', 'pediatric_fall_risk_assessment');
                if ((!this.updatePermission && this.chartStore.model.id) || (!this.createPermission && !this.chartStore.model.id)) {
                    this.viewOnly = true;
                }
            }
            PediatricFallRiskAssessmentController.$inject = ['PermissionsService'];
            return PediatricFallRiskAssessmentController;
        }());
        components.PediatricFallRiskAssessmentController = PediatricFallRiskAssessmentController;
        angular
            .module('consolo')
            .controller('PediatricFallRiskAssessmentController', PediatricFallRiskAssessmentController);
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingProblemListItem = /** @class */ (function () {
            function ConsoloChartingProblemListItem() {
                this.bindings = {
                    chartStore: '=',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                    showUnmodifiedSections: '=',
                    callback: '&?',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/problem-list-item.component.html';
                this.controller = ConsoloChartingProblemListItemController;
            }
            return ConsoloChartingProblemListItem;
        }());
        var ConsoloChartingProblemListItemController = /** @class */ (function (_super) {
            __extends(ConsoloChartingProblemListItemController, _super);
            function ConsoloChartingProblemListItemController($filter, $timeout, cache, EncounterType, logger, $window, $scope, ProblemListItemService, MedicationService) {
                var _this = _super.call(this, $scope) || this;
                _this.$filter = $filter;
                _this.$timeout = $timeout;
                _this.cache = cache;
                _this.EncounterType = EncounterType;
                _this.logger = logger;
                _this.$window = $window;
                _this.$scope = $scope;
                _this.ProblemListItemService = ProblemListItemService;
                _this.MedicationService = MedicationService;
                _this.problemTreatmentHidden = false;
                _this.problemResolved = false;
                return _this;
            }
            ConsoloChartingProblemListItemController.prototype.$onInit = function () {
                var _this = this;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                if (this.viewOnly &&
                    (!this.formModel.medication_ids || !this.formModel.medication_ids.length) &&
                    !this.formModel.current_treatment &&
                    !this.formModel.effectiveness_of_treatment &&
                    !this.formModel.current_interventions) {
                    this.problemTreatmentHidden = true;
                }
                if (this.formModel.problem_resolved) {
                    this.problemResolved = true;
                }
                this.formModel.medication_ids = this.formModel.medication_ids || [];
                this.ProblemListItemService.setCurrentPastProblemsForPatient(this.chartStore.model.patient_id).then(function (items) {
                    if (_this.formModel.id && _this.formModel.problem_list_item_id) {
                        var idx = _this.ProblemListItemService.currentProblemListItems.findIndex(function (i) { return i.id === _this.formModel.problem_list_item_id; });
                        if (idx === null || idx < 0) {
                            var oldProblem = _this.ProblemListItemService.pastProblemListItems.find(function (i) { return i.id === _this.formModel.problem_list_item_id; });
                            _this.ProblemListItemService.currentProblemListItems = [oldProblem].concat(_this.ProblemListItemService.currentProblemListItems);
                        }
                    }
                });
                this.MedicationService.getMedications().then(function () {
                    _this.medicationList = [];
                    for (var _i = 0, _a = _this.MedicationService.medications; _i < _a.length; _i++) {
                        var m = _a[_i];
                        var idx = _this.formModel.medication_ids.indexOf(m.id);
                        if (idx >= 0) {
                            m.isChecked = true;
                            _this.medicationList.push(m);
                        }
                        else if (m.isActive()) {
                            m.isChecked = false;
                            _this.medicationList.push(m);
                        }
                    }
                });
            };
            ConsoloChartingProblemListItemController.prototype.toggleMedication = function (m) {
                var idx = this.formModel.medication_ids.indexOf(m.id);
                if (idx >= 0) {
                    this.formModel.medication_ids.splice(idx, 1);
                }
                else {
                    this.formModel.medication_ids.push(m.id);
                }
                this.chartStore.saveDraft();
            };
            ConsoloChartingProblemListItemController.prototype.toggleAllMedications = function () {
                if (!this.areAllMedicationsChecked()) {
                    for (var _i = 0, _a = this.medicationList; _i < _a.length; _i++) {
                        var m = _a[_i];
                        if (!m.isChecked) {
                            this.toggleMedication(m);
                            m.isChecked = true;
                        }
                    }
                }
                else {
                    for (var _b = 0, _c = this.medicationList; _b < _c.length; _b++) {
                        var m = _c[_b];
                        if (m.isChecked) {
                            this.toggleMedication(m);
                            m.isChecked = false;
                        }
                    }
                }
            };
            ConsoloChartingProblemListItemController.prototype.areAllMedicationsChecked = function () {
                return (this.medicationList &&
                    this.formModel.medication_ids &&
                    this.formModel.medication_ids.length === this.medicationList.length);
            };
            ConsoloChartingProblemListItemController.prototype.areSomeMedicationsChecked = function () {
                return (this.medicationList &&
                    this.formModel.medication_ids &&
                    this.formModel.medication_ids.length !== 0 &&
                    this.formModel.medication_ids.length !== this.medicationList.length);
            };
            ConsoloChartingProblemListItemController.$inject = [
                '$filter',
                '$timeout',
                'cache',
                'EncounterType',
                'logger',
                '$window',
                '$scope',
                'ProblemListItemService',
                'MedicationService',
            ];
            return ConsoloChartingProblemListItemController;
        }(consolo.controllers.BaseCustomComponentController));
        components.ConsoloChartingProblemListItemController = ConsoloChartingProblemListItemController;
        angular
            .module('consolo')
            .component('consoloChartingProblemListItem', new ConsoloChartingProblemListItem());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ReviewOfSystemsModalController = /** @class */ (function () {
            function ReviewOfSystemsModalController(section, searchItem, $mdDialog, $timeout) {
                this.section = section;
                this.searchItem = searchItem;
                this.$mdDialog = $mdDialog;
                this.$timeout = $timeout;
                if (!this.section.comments) {
                    this.section.comments = '';
                }
                this.section.fields.forEach(function (item) {
                    if (!(item.value === true || item.value === false)) {
                        item.value = null;
                    }
                });
                if (this.searchItem) {
                    this.$timeout(function () {
                        var searchItemContainer = document.querySelector('.search-item-container');
                        if (searchItemContainer) {
                            searchItemContainer.scrollIntoView();
                        }
                    }, 250);
                }
            }
            ReviewOfSystemsModalController.prototype.close = function () {
                this.$mdDialog.cancel();
            };
            ReviewOfSystemsModalController.prototype.save = function (section) {
                this.$mdDialog.hide(this.section);
            };
            ReviewOfSystemsModalController.$inject = ['section', 'searchItem', '$mdDialog', '$timeout'];
            return ReviewOfSystemsModalController;
        }());
        angular
            .module('consolo')
            .controller('ReviewOfSystemsModalController', ReviewOfSystemsModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ConsoloChartingReviewOfSystemsSection = /** @class */ (function () {
            function ConsoloChartingReviewOfSystemsSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                    callback: '&?',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/review-of-systems.html';
                this.controller = ClinicalChartingReviewOfSystemsController;
            }
            return ConsoloChartingReviewOfSystemsSection;
        }());
        var ClinicalChartingReviewOfSystemsController = /** @class */ (function (_super) {
            __extends(ClinicalChartingReviewOfSystemsController, _super);
            function ClinicalChartingReviewOfSystemsController($mdDialog, $timeout, $scope, ChartUtilService) {
                var _this = _super.call(this, $scope) || this;
                _this.$mdDialog = $mdDialog;
                _this.$timeout = $timeout;
                _this.ChartUtilService = ChartUtilService;
                _this.step = 0;
                _this.textFilter = '';
                _this.codifiedResponses = [
                    { description: 'Unable to obtain due to clinical condition' },
                    { description: "Unable to obtain due to patient's mental status" },
                ];
                return _this;
            }
            ClinicalChartingReviewOfSystemsController.prototype.$onInit = function () {
                var _a;
                for (var _i = 0, _b = this.formDefinition; _i < _b.length; _i++) {
                    var section = _b[_i];
                    if (section.name !== 'unable_to_obtain') {
                        if (!this.formModel[section.name]) {
                            this.formModel[section.name] = { display_text: 'Not reviewed' };
                        }
                        else {
                            for (var _c = 0, _d = section.fields; _c < _d.length; _c++) {
                                var item = _d[_c];
                                if (this.formModel[section.name][item.field] !== undefined) {
                                    item.value = !!this.formModel[section.name][item.field];
                                }
                            }
                            this.updateDisplayText(section.name);
                        }
                    }
                    else if (!!((_a = this.formModel.unable_to_obtain) === null || _a === void 0 ? void 0 : _a.display_text)) {
                        this.formModel.unable_to_obtain = null;
                    }
                }
            };
            ClinicalChartingReviewOfSystemsController.prototype.updateDisplayText = function (section) {
                this.ChartUtilService.updateDisplayText(section, this.formModel);
            };
            ClinicalChartingReviewOfSystemsController.prototype.gotoStep = function (to, fromSection, set_normal) {
                this.step = to;
                if (set_normal) {
                    this.setNormal(fromSection);
                }
                else {
                    this.updateDisplayText(fromSection);
                }
            };
            ClinicalChartingReviewOfSystemsController.prototype.resetToNormal = function (section) {
                var _this = this;
                if (this.viewOnly || this.formModel[section.name].display_text === 'Normal') {
                    return;
                }
                var resetValues = function () {
                    section.comments = '';
                    section.fields.forEach(function (item) { return (item.value = null); });
                };
                if (this.formModel[section.name].display_text === 'Not reviewed') {
                    this.setNormal(section.name);
                    resetValues();
                }
                else {
                    var confirm_1 = this.$mdDialog
                        .confirm()
                        .title('Confirm')
                        .textContent('Are you sure you want to reset this section to Normal?')
                        .ok('Yes')
                        .cancel('No');
                    this.$mdDialog.show(confirm_1).then(function () {
                        _this.setNormal(section.name);
                        resetValues();
                    });
                }
            };
            ClinicalChartingReviewOfSystemsController.prototype.setNormal = function (section) {
                var _this = this;
                if (this.viewOnly) {
                    return;
                }
                this.$timeout(function () {
                    _this.formModel[section]['normal'] = 1;
                    for (var val in _this.formModel[section]) {
                        if (val !== 'display_text' && val !== 'normal') {
                            _this.formModel[section][val] = null;
                        }
                    }
                    _this.updateDisplayText(section);
                    _this.saveDraft();
                });
            };
            ClinicalChartingReviewOfSystemsController.prototype.headerClick = function (step) {
                if (this.viewOnly) {
                    return;
                }
                this.step = this.step !== step ? step : -1;
            };
            ClinicalChartingReviewOfSystemsController.prototype.thumbClick = function (section, field, value) {
                this.formModel[section].normal = null;
                if (this.formModel[section][field] === value) {
                    this.formModel[section][field] = null;
                }
                else {
                    this.formModel[section][field] = value;
                }
                this.updateDisplayText(section);
            };
            ClinicalChartingReviewOfSystemsController.prototype.yesNoClick = function (section, field, value) {
                if (this.viewOnly) {
                    return;
                }
                this.formModel[section].normal = null;
                if (this.formModel[section][field] === value) {
                    this.formModel[section][field] = null;
                }
                else {
                    this.formModel[section][field] = value;
                }
                this.updateDisplayText(section);
            };
            ClinicalChartingReviewOfSystemsController.prototype.filterSections = function () {
                var searchText = this.textFilter ? this.textFilter.toLowerCase() : '';
                this.formDefinition.forEach(function (section) {
                    console.log(section);
                    section._hide = section.label ? section.label.toLowerCase().indexOf(searchText) < 0 : false;
                    section.fields.forEach(function (item) {
                        item._matchesSearch =
                            searchText.length && item.label.toLowerCase().indexOf(searchText) > -1;
                        if (item._matchesSearch && section._hide) {
                            section._hide = false;
                        }
                    });
                });
            };
            ClinicalChartingReviewOfSystemsController.prototype.editSection = function (section, $event, searchItem) {
                var _this = this;
                if (searchItem === void 0) { searchItem = null; }
                if (this.viewOnly) {
                    return;
                }
                this.$mdDialog
                    .show({
                    controller: 'ReviewOfSystemsModalController as $ctrl',
                    templateUrl: '/y/templates/clinical-charts/components/review-of-systems-modal.html',
                    fullscreen: true,
                    clickOutsideToClose: false,
                    targetEvent: $event,
                    locals: { section: section, searchItem: searchItem },
                })
                    .then(function (updatedSection) {
                    updatedSection.fields.forEach(function (item) {
                        if (item.value === true || item.value === false) {
                            _this.formModel[updatedSection.name].normal = null;
                            _this.formModel[updatedSection.name][item.field] = item.value ? 1 : 0;
                        }
                    });
                    _this.formModel[updatedSection.name]['comments'] = updatedSection.comments;
                    _this.updateDisplayText(updatedSection.name);
                    _this.saveDraft();
                    _this.textFilter = '';
                    _this.filterSections();
                });
            };
            ClinicalChartingReviewOfSystemsController.$inject = ['$mdDialog', '$timeout', '$scope', 'ChartUtilService'];
            return ClinicalChartingReviewOfSystemsController;
        }(consolo.controllers
            .BaseCustomComponentController));
        angular
            .module('consolo')
            .component('consoloChartingReviewOfSystems', new ConsoloChartingReviewOfSystemsSection());
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        // class ChartingScreenSearch implements ng.IComponentOptions {
        //   public bindings: any;
        //   templateUrl: string;
        //   controller: any;
        //   constructor() {
        //     this.bindings = {
        //       allScreens: '<',
        //       flattenedScreens: '<',
        //       missingScreens: '<',
        //     };
        //     this.templateUrl = '/y/templates/clinical-charts/components/section-search.html';
        //     this.controller = ChartingScreenSearchController;
        //   }
        // }
        var ChartingScreenSearchController = /** @class */ (function () {
            function ChartingScreenSearchController($mdDialog, ChartingTemplateService, allScreens, encounter_type) {
                this.$mdDialog = $mdDialog;
                this.ChartingTemplateService = ChartingTemplateService;
                this.allScreens = allScreens;
                this.encounter_type = encounter_type;
                this.$onInit();
            }
            ChartingScreenSearchController.prototype.$onInit = function () {
                this.fieldTemplate = this.ChartingTemplateService.getFieldDefinitions();
                var exists = false;
                var allOther = [];
                for (var _i = 0, _a = this.allScreens; _i < _a.length; _i++) {
                    var section = _a[_i];
                    if (section.name === '_menu_all_other') {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    var missing = this.ChartingTemplateService.getMissingSections(this.encounter_type);
                    if (missing) {
                        for (var _b = 0, missing_1 = missing; _b < missing_1.length; _b++) {
                            var section = missing_1[_b];
                            section.parent_title = 'All Other Screens';
                            section.parent_name = '_menu_all_other';
                        }
                        allOther.push({
                            title: 'All Other Screens',
                            name: '_menu_all_other',
                            childSections: missing,
                        });
                    }
                }
                this.screens = [].concat(this.allScreens, allOther);
                this.textFilter = '';
                this.screenSearch();
            };
            ChartingScreenSearchController.prototype.close = function (val) {
                this.cleanup();
                this.$mdDialog.hide(val);
            };
            ChartingScreenSearchController.prototype.cancel = function () {
                this.cleanup();
                this.$mdDialog.cancel();
            };
            ChartingScreenSearchController.prototype.cleanup = function () {
                // let allScreens = [].concat(this.allScreens, this.missingScreens);
                for (var _i = 0, _a = this.screens; _i < _a.length; _i++) {
                    var screen_1 = _a[_i];
                    if (!screen_1.childSections) {
                        delete screen_1.tempHide;
                        delete screen_1.tempFields;
                    }
                }
            };
            ChartingScreenSearchController.prototype.screenSearch = function () {
                for (var _i = 0, _a = this.screens; _i < _a.length; _i++) {
                    var screen_2 = _a[_i];
                    if (!screen_2.childSections) {
                        this.checkScreen(screen_2);
                    }
                    else {
                        for (var _b = 0, _c = screen_2.childSections; _b < _c.length; _b++) {
                            var child = _c[_b];
                            var index = 0;
                            if (child.name === 'skin_integrity_assessment') {
                                index = screen_2.childSections.indexOf(child);
                                screen_2.childSections.splice(index, 1);
                            }
                            this.checkScreen(child);
                        }
                    }
                }
            };
            ChartingScreenSearchController.prototype.checkScreen = function (screen) {
                if (this.textFilter.length) {
                    screen.tempHide = true;
                    screen.tempFields = [];
                    if (screen.title.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0 ||
                        (screen.description &&
                            screen.description.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0) ||
                        screen.title.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0 ||
                        (screen.parent_title &&
                            screen.parent_title.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0) ||
                        screen.title.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0 ||
                        (screen.grandparent_title &&
                            screen.grandparent_title.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0)) {
                        screen.tempHide = false;
                    }
                    var tempSections = screen.sections ? screen.sections : [screen];
                    for (var _i = 0, tempSections_1 = tempSections; _i < tempSections_1.length; _i++) {
                        var section = tempSections_1[_i];
                        for (var _a = 0, _b = this.fieldTemplate[section.model]; _a < _b.length; _a++) {
                            var fieldSection = _b[_a];
                            for (var _c = 0, _d = fieldSection.fields; _c < _d.length; _c++) {
                                var field = _d[_c];
                                if (field.label.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0) {
                                    screen.tempFields.push(field.label);
                                    screen.tempHide = false;
                                }
                            }
                        }
                    }
                }
                else {
                    screen.tempHide = false;
                }
            };
            ChartingScreenSearchController.prototype.goto = function (screen, isChild) {
                screen.active = true;
                this.close({ screen: screen, isChild: isChild });
            };
            ChartingScreenSearchController.$inject = ['$mdDialog', 'ChartingTemplateService', 'allScreens', 'encounter_type'];
            return ChartingScreenSearchController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartingScreenSearchController', ChartingScreenSearchController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ConsoloChartingVeteransAssessmentSection = /** @class */ (function () {
            function ConsoloChartingVeteransAssessmentSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    viewOnly: '<',
                    formDefinition: '<',
                    showUnmodifiedSections: '=',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/veterans-assessment.html';
                this.controller = ChartingVeteransAssessmentController;
            }
            return ConsoloChartingVeteransAssessmentSection;
        }());
        var ChartingVeteransAssessmentController = /** @class */ (function () {
            function ChartingVeteransAssessmentController($filter, $scope, SectionManager, options, $parse, EncounterType, cache, Session, $mdPanel, logger, patientService) {
                this.$filter = $filter;
                this.$scope = $scope;
                this.SectionManager = SectionManager;
                this.options = options;
                this.$parse = $parse;
                this.EncounterType = EncounterType;
                this.cache = cache;
                this.Session = Session;
                this.$mdPanel = $mdPanel;
                this.logger = logger;
                this.patientService = patientService;
                this.requiredFields = {};
                this.hiddenFields = {};
                this.readonlyFields = {};
                this.today = new Date();
                this.any_exposure_options = [];
                this.war_era_options = [];
                this.additional_veteran_assessment_symptom_options = [];
                this.veteran_assessment_come_to_serve_options = [];
                this.military_experience_options = [];
                this.prisoner_of_war_solitary_with_others_options = [];
                this.enrolled_in_va_status_options = [];
                this.prisoner_of_war_duration_of_captivity_days = [];
                this.prisoner_of_war_duration_of_captivity_months = [];
                this.prisoner_of_war_duration_of_captivity_years = [];
                this.yes_no_blank = [];
                this.show_family_military_service_specify = false;
                this.date_label = 'Select Date';
                this.describe_label = 'Describe';
                this.sectionEmptyObj = {};
                this.errorFields = [];
                this.any_exposure_options = options.get('any_exposure_options');
                this.additional_veteran_assessment_symptom_options = options.get('additional_veteran_assessment_symptom_options');
                this.veteran_assessment_come_to_serve_options = options.get('veteran_assessment_come_to_serve_options');
                this.military_experience_options = options.get('military_experience_options');
                this.prisoner_of_war_duration_of_captivity_days = options.get('prisoner_of_war_duration_of_captivity_days');
                this.prisoner_of_war_duration_of_captivity_months = options.get('prisoner_of_war_duration_of_captivity_months');
                this.prisoner_of_war_duration_of_captivity_years = options.get('prisoner_of_war_duration_of_captivity_years');
                this.prisoner_of_war_solitary_with_others_options = options.get('prisoner_of_war_solitary_with_others_options');
                this.yes_no_blank = options.get('yes_no_blank');
                this.enrolled_in_va_status_options = options.get('enrolled_in_va_status');
            }
            ChartingVeteransAssessmentController.prototype.$onInit = function () {
                var _this = this;
                this.readonly = this.chartStore.model.locked || this.viewOnly;
                this.date_label = this.readonly ? '' : 'Select Date';
                this.describe_label = this.readonly ? '' : 'Describe';
                this.loadFormDefinition(null);
                this.$scope.$on('reloadFormDefinition', function (caller, errors) {
                    _this.loadFormDefinition(errors);
                });
                this.toggle_family_military_service_specify();
                this.checkIfSectionIsEmpty();
                this.fetchOptions();
                this.getPatientMilitaryServiceInfo();
            };
            ChartingVeteransAssessmentController.prototype.loadFormDefinition = function (errors) {
                var _this = this;
                var errorList = this.$parse('errors')(errors);
                if (errorList) {
                    this.errorFields = errorList['veterans_assessment'];
                }
                if (this.chartStore.model.encounter_type_id) {
                    this.cache(this.EncounterType, 'encounter_types')
                        .query()
                        .$promise.then(function (data) {
                        var encType = _this.$filter('filter')(data, function (a) {
                            return a.id === _this.chartStore.model.encounter_type_id;
                        })[0];
                        if (encType && encType.field_logic && encType.field_logic['veterans_assessment']) {
                            for (var s in encType.field_logic['veterans_assessment']) {
                                if (encType.field_logic['veterans_assessment'].hasOwnProperty(s)) {
                                    if (encType.field_logic['veterans_assessment'][s].required) {
                                        _this.requiredFields[s] = true;
                                    }
                                    else if (encType.field_logic['veterans_assessment'][s].hidden) {
                                        _this.hiddenFields[s] = true;
                                    }
                                }
                            }
                        }
                        if (encType && encType.required_fields['veterans_assessment']) {
                            encType.required_fields['veterans_assessment'].forEach(function (s) {
                                if (!_this.requiredFields) {
                                    _this.requiredFields = {};
                                }
                                _this.requiredFields[s] = true;
                            });
                        }
                    });
                }
            };
            ChartingVeteransAssessmentController.prototype.getPatientMilitaryServiceInfo = function () {
                var _this = this;
                this.patientService
                    .getPatientMillitaryServiceInfo(this.chartStore.model.patient_id)
                    .then(function (response) {
                    var military_info = response.data;
                    if (military_info.military_era_id) {
                        _this.readonlyFields['war_era_period'] = true;
                        _this.formModel.war_era_period = military_info.military_era_id;
                    }
                    if (military_info.military_rank) {
                        _this.readonlyFields['highest_rank'] = true;
                        _this.formModel.highest_rank = military_info.military_rank;
                    }
                    if (military_info.military_comments) {
                        _this.readonlyFields['war_era_period_other_describe'] = true;
                        _this.formModel.war_era_period_other_describe = military_info.military_comments;
                    }
                    if (military_info.enrolled_in_va_status) {
                        if (military_info.enrolled_in_va_status.indexOf('Yes') !== -1) {
                            _this.readonlyFields['receiving_va_compensation_benefits'] = true;
                            _this.formModel.receiving_va_compensation_benefits = true;
                        }
                        if (military_info.enrolled_in_va_status.indexOf('Refused/Declined VBA Benefits') !== -1) {
                            _this.readonlyFields['applied_for_va_benefits_and_denied'] = true;
                            _this.formModel.applied_for_va_benefits_and_denied = true;
                        }
                    }
                }).catch(function (error) {
                    _this.logger.error('Unable to fetch and sync Patient\'s Military Service Info' + error);
                });
            };
            ChartingVeteransAssessmentController.prototype.validateLengthOfService = function () {
                if (this.formModel.length_of_service_start_date &&
                    this.formModel.length_of_service_end_date) {
                    if (this.formModel.length_of_service_start_date < this.formModel.length_of_service_end_date) {
                        this.formModel.length_of_service =
                            this.formModel.length_of_service_start_date +
                                '~' +
                                this.formModel.length_of_service_end_date;
                    }
                }
                else {
                    this.formModel.length_of_service = '';
                }
            };
            ChartingVeteransAssessmentController.prototype.validateDurationOfCaptivity = function () {
                this.formModel.prisoner_of_war_duration_of_captivity = '';
                if (this.formModel.prisoner_of_war_duration_of_captivity_year) {
                    this.formModel.prisoner_of_war_duration_of_captivity =
                        this.formModel.prisoner_of_war_duration_of_captivity +
                            this.formModel.prisoner_of_war_duration_of_captivity_year;
                }
                if (this.formModel.prisoner_of_war_duration_of_captivity_month) {
                    this.formModel.prisoner_of_war_duration_of_captivity =
                        this.formModel.prisoner_of_war_duration_of_captivity +
                            this.formModel.prisoner_of_war_duration_of_captivity_month;
                }
                if (this.formModel.prisoner_of_war_duration_of_captivity_day) {
                    this.formModel.prisoner_of_war_duration_of_captivity =
                        this.formModel.prisoner_of_war_duration_of_captivity +
                            this.formModel.prisoner_of_war_duration_of_captivity_day;
                }
            };
            ChartingVeteransAssessmentController.prototype.checkIfSectionIsEmpty = function () {
                this.sectionEmptyObj = {
                    military_service: false,
                    services_issue: false,
                    medication_table: false,
                    war_details: false,
                    add_information: false,
                };
                if (this.readonly) {
                    this.sectionEmptyObj = {
                        military_service: true,
                        medication_table: true,
                        services_issue: true,
                        war_details: true,
                        add_information: true,
                    };
                    var fields_arr = [
                        { field: 'patient' },
                        { field: 'active_duty_service' },
                        { field: 'combat_assignment' },
                        { field: 'dd214_copy' },
                        { field: 'spouse_active_duty' },
                        { field: 'family_military_service' },
                    ];
                    for (var i = 0; i < fields_arr.length; i++) {
                        if (this.formModel && this.formModel[fields_arr[i]['field']]) {
                            this.sectionEmptyObj['military_service'] = false;
                            break;
                        }
                    }
                    var table_arr = [
                        { field: 'ptsd_diagnosis_date' },
                        { field: 'ptsd_medications_and_how_helpful' },
                        { field: 'ptsd_mental_health_counseling_when_and_how_helpful_reconciliation' },
                        { field: 'anxiety_diagnosis_date' },
                        { field: 'anxiety_medications_and_how_helpful' },
                        { field: 'anxiety_mental_health_counseling_when_and_how_helpful' },
                        { field: 'depression_diagnosis_date' },
                        { field: 'depression_medications_and_how_helpful' },
                        { field: 'depression_mental_health_counseling_when_and_how_helpful' },
                        { field: 'psychosis_diagnosis_date' },
                        { field: 'psychosis_medications_and_how_helpful' },
                        { field: 'psychosis_mental_health_counseling_when_and_how_helpful' },
                        { field: 'suicidal_ideation_diagnosis_date' },
                        { field: 'suicidal_ideation_medications_and_how_helpful' },
                        { field: 'suicidal_ideation_mental_health_counseling_when_and_how_helpful' },
                        { field: 'substance_abuse_diagnosis_date' },
                        { field: 'substance_abuse_medications_and_how_helpful' },
                        { field: 'substance_abuse_mental_health_counseling_when_and_how_helpful' },
                        { field: 'military_related_sexual_trauma_diagnosis_date' },
                        { field: 'military_related_sexual_trauma_medications_and_how_helpful' },
                        { field: 'military_related_sexual_trauma_mental_health_counseling_when_and_how_helpful' },
                        { field: 'frostbite_cold_injury_diagnosis_date' },
                        { field: 'frostbite_cold_injury_medications_and_how_helpful' },
                        { field: 'frostbite_cold_injury_mental_health_counseling_when_and_how_helpful' },
                        { field: 'malignant_diseases_diagnosis_date' },
                        { field: 'malignant_diseases_medications_and_how_helpful' },
                        { field: 'malignant_diseases_mental_health_counseling_when_and_how_helpful' },
                        { field: 'traumatic_injuries_diagnosis_date' },
                        { field: 'traumatic_injuries_medications_and_how_helpful' },
                        { field: 'traumatic_injuries_mental_health_counseling_when_and_how_helpful' },
                    ];
                    for (var i = 0; i < table_arr.length; i++) {
                        if (this.formModel && this.formModel[table_arr[i]['field']]) {
                            this.sectionEmptyObj['medication_table'] = false;
                            break;
                        }
                    }
                    var services_issue_fields = [
                        { field: 'psychological_service_related_issues_history_describe' },
                        { field: 'psychological_service_related_issues_current_describe' },
                        { field: 'physical_service_related_issues_history_describe' },
                        { field: 'physical_service_related_issues_current_describe' },
                        { field: 'spiritual_service_related_issues_history_describe' },
                        { field: 'spiritual_service_related_issues_current_describe' },
                        { field: 'if_no_which_areas_do_we_need_to_focus_on' },
                    ];
                    for (var i = 0; i < services_issue_fields.length; i++) {
                        if (this.formModel && this.formModel[services_issue_fields[i]['field']]) {
                            this.sectionEmptyObj['services_issue'] = false;
                            break;
                        }
                    }
                    var war_details_fields = [
                        { field: 'war_era_period' },
                        { field: 'war_era_period_other_describe' },
                        { field: 'how_did_you_come_to_serve' },
                        { field: 'length_of_service' },
                        { field: 'highest_rank' },
                        { field: 'any_exposures' },
                        { field: 'prisoner_of_war_duration_of_captivity' },
                        { field: 'prisoner_of_war_location_of_captivity' },
                        { field: 'prisoner_of_war_solitary_or_with_others' },
                        { field: 'overall_how_do_you_view_your_experience_in_the_military' },
                    ];
                    for (var i = 0; i < war_details_fields.length; i++) {
                        if (this.formModel && this.formModel[war_details_fields[i]['field']]) {
                            this.sectionEmptyObj['war_details'] = false;
                            break;
                        }
                    }
                    var add_information_fields = [
                        { field: 'additional_veteran_assessment_symptoms' },
                        { field: 'additional_veteran_assessment_symptoms_other_describe' },
                        { field: 'receiving_va_compensation_benefits' },
                        { field: 'applied_for_va_benefits_and_denied' },
                        { field: 'homeless_veteran' },
                        { field: 'in_contact_with_other_war_veterans_from_troop' },
                        { field: 'service_connected_condition' },
                        { field: 'va_medications' },
                        { field: 'va_hospital_or_clinic' },
                        { field: 'va_physician_info' },
                        { field: 'benefits_eligibility' },
                        { field: 'staff_volunteers_to_have_military_experience' },
                        { field: 'veteran_resources_needed' },
                    ];
                    for (var i = 0; i < add_information_fields.length; i++) {
                        if (this.formModel && this.formModel[add_information_fields[i]['field']]) {
                            this.sectionEmptyObj['war_details'] = false;
                            break;
                        }
                    }
                }
            };
            ChartingVeteransAssessmentController.prototype.toggle_family_military_service_specify = function () {
                if (this.formModel.family_military_service === 'Yes') {
                    this.show_family_military_service_specify = true;
                }
                else {
                    this.show_family_military_service_specify = false;
                }
            };
            ChartingVeteransAssessmentController.prototype.checkIfValueExist = function (fieldArr) {
                var _this = this;
                if (!this.readonly) {
                    return true;
                }
                else {
                    var isnonEmpty = fieldArr.some(function (field) { return _this.formModel[field]; });
                    return isnonEmpty;
                }
            };
            ChartingVeteransAssessmentController.prototype.$onChanges = function () { };
            ChartingVeteransAssessmentController.prototype.fetchOptions = function () {
                var _this = this;
                this.selectOptions = {};
                ['military_eras'].forEach(function (option) {
                    _this.options
                        .get(option)
                        .$promise.then(function (data) {
                        _this.selectOptions[option] = data;
                    })
                        .catch(function (error) {
                        _this.logger.error(error);
                    });
                });
            };
            ChartingVeteransAssessmentController.$inject = [
                '$filter',
                '$scope',
                'ChartingSectionManager',
                'options',
                '$parse',
                'EncounterType',
                'cache',
                'SessionService',
                '$mdPanel',
                'logger',
                'patientService'
            ];
            return ChartingVeteransAssessmentController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingVeteransAssessment', new ConsoloChartingVeteransAssessmentSection());
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ConsoloChartingView = /** @class */ (function () {
            function ConsoloChartingView() {
                this.bindings = {
                    clinicalChartId: '<',
                    chartStore: '<',
                    currentPatient: '<',
                    showUnmodifiedSections: '=',
                    currentScreen: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/sections/common-section.html';
                this.controller = ConsoloChartingViewController;
                this.controllerAs = 'vm';
            }
            return ConsoloChartingView;
        }());
        var ConsoloChartingViewController = /** @class */ (function () {
            function ConsoloChartingViewController($compile, $element, $scope, $timeout, ChartingTemplateService, PersistedItem, CurrentPatient, ChartUtilService) {
                this.$compile = $compile;
                this.$element = $element;
                this.$scope = $scope;
                this.$timeout = $timeout;
                this.ChartingTemplateService = ChartingTemplateService;
                this.PersistedItem = PersistedItem;
                this.CurrentPatient = CurrentPatient;
                this.ChartUtilService = ChartUtilService;
                this.readonly = true;
                this.viewMode = true;
                this.screenList = [];
                this.sectionList = [];
                this.missingSections = [];
            }
            ConsoloChartingViewController.prototype.$onInit = function () {
                var _this = this;
                if (!this.chartStore) {
                    this.ChartUtilService.getChartStoreById(this.clinicalChartId).then(function (chartstore) {
                        _this.chartStore = chartstore;
                        _this.init();
                    });
                }
                else {
                    this.init();
                }
            };
            ConsoloChartingViewController.prototype.init = function () {
                var _this = this;
                this.buildTemplate();
                if (!this.currentPatient) {
                    this.currentPatient = this.model.patient || this.CurrentPatient.patient;
                }
                if (this.currentScreen) {
                    this.$scope.$watch(function () { return _this.currentScreen.name; }, function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            _this.buildTemplate();
                        }
                    });
                }
            };
            ConsoloChartingViewController.prototype.buildTemplate = function () {
                if (!this.chartStore.model || this.currentScreen) {
                    this.model = this.chartStore;
                }
                else {
                    this.model = this.chartStore.model;
                }
                this.chartEntry = this.PersistedItem('clinical_chart_entry', this.model);
                this.model = this.chartEntry.model;
                var templateName = this.ChartingTemplateService.getTemplateName(this.model);
                if (this.ChartingTemplateService.isEncounterCharting(this.model.charting_client)) {
                    if (this.model.encounter_type && !isNaN(this.model.encounter_type_id)) {
                        this.missingSections = this.ChartingTemplateService.getMissingSections(this.model.encounter_type);
                    }
                    else {
                        this.model.encounter_type_id = 'encounter_charting';
                    }
                }
                this.screenList = this.ChartingTemplateService.getScreens(templateName);
                var flattened = this.ChartingTemplateService.getFlattenedSections(templateName);
                var modelList = [];
                this.sectionList = [];
                for (var _i = 0, flattened_1 = flattened; _i < flattened_1.length; _i++) {
                    var section = flattened_1[_i];
                    if (section.rootNode) {
                        this.sectionList.push(section);
                        if (this.model.care_plan_problem_updates &&
                            this.model.care_plan_problem_updates.length) {
                            var temp = this.ChartingTemplateService.getCarePlanProblemSection();
                            temp.active = true;
                            this.sectionList.push(temp);
                        }
                    }
                    else if (this.model[section.model]) {
                        if (section.singleNode || this.model[section.model].length) {
                            if (modelList.indexOf(section.model) < 0) {
                                modelList.push(section.model);
                                section.active = true;
                                this.sectionList.push(section);
                            }
                        }
                    }
                }
                for (var _a = 0, _b = this.missingSections; _a < _b.length; _a++) {
                    var section = _b[_a];
                    if (!section.rootNode && this.model[section.model]) {
                        if (section.singleNode || this.model[section.model].length) {
                            if (modelList.indexOf(section.model) < 0) {
                                modelList.push(section.model);
                                section.active = true;
                                this.sectionList.push(section);
                            }
                        }
                    }
                }
                var definitions = [];
                var def;
                var _loop_1 = function (section) {
                    def = this_1.ChartingTemplateService.getFormDefinition(section.name, this_1.model.encounter_type_id, this_1.model.patient.id, this_1.model.encounter_type);
                    //if there are Care Plan Problems, find the index of the general section and put them after
                    if (def && this_1.checkCurrentSection(section.name)) {
                        if (this_1.model[def.model]) {
                            if (Array.isArray(this_1.model[def.model])) {
                                for (var _i = 0, _a = this_1.model[def.model]; _i < _a.length; _i++) {
                                    var model = _a[_i];
                                    model.isEmpty = this_1.ChartUtilService.checkEmptySections(def, model);
                                }
                            }
                            else {
                                this_1.model[def.model].isEmpty = this_1.ChartUtilService.checkEmptySections(def, this_1.model[def.model]);
                            }
                        }
                        if (!this_1.pageIsEmpty(section)) {
                            definitions.push(def.find(function (d) { return d.name === section.name; }));
                        }
                    }
                    else if (section.name === 'care_plan_problem_update' &&
                        this_1.checkCurrentSection(section.name)) {
                        var temp = this_1.ChartingTemplateService.getCarePlanProblemSection();
                        definitions.push(temp);
                    }
                };
                var this_1 = this;
                for (var _c = 0, _d = this.sectionList; _c < _d.length; _c++) {
                    var section = _d[_c];
                    _loop_1(section);
                }
                this.formDefinition = definitions;
                this.formModel = this.model;
            };
            ConsoloChartingViewController.prototype.pageIsEmpty = function (page) {
                return this.ChartUtilService.pageIsEmpty(this.model, page);
            };
            ConsoloChartingViewController.prototype.checkCurrentSection = function (name) {
                if (this.currentScreen) {
                    if (this.currentScreen.sections) {
                        var exists = this.currentScreen.sections.filter(function (x) { return name === x.name; });
                        return exists.length;
                    }
                    else {
                        return name === this.currentScreen.name;
                    }
                }
                else {
                    return true;
                }
            };
            ConsoloChartingViewController.$inject = [
                '$compile',
                '$element',
                '$scope',
                '$timeout',
                'ChartingTemplateService',
                'PersistedItem',
                'CurrentPatient',
                'ChartUtilService',
            ];
            return ConsoloChartingViewController;
        }());
        angular.module('consolo').component('consoloChartingViewChart', new ConsoloChartingView());
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingVisitTaskListSection = /** @class */ (function () {
            function ConsoloChartingVisitTaskListSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    viewOnly: '<',
                    formDefinition: '<',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/visit-task-list.html';
                this.controller = ChartingVisitTaskListController;
            }
            return ConsoloChartingVisitTaskListSection;
        }());
        var ChartingVisitTaskListController = /** @class */ (function () {
            function ChartingVisitTaskListController(patientMainService, visitTaskList, Session, ChartingTemplateService, $mdMedia) {
                this.patientMainService = patientMainService;
                this.visitTaskList = visitTaskList;
                this.Session = Session;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$mdMedia = $mdMedia;
                this.fieldDefinitions = this.ChartingTemplateService.getFieldDefinitions();
                this.sections = this.generateInstructionSections();
                // section names are keys from this.instructions
                this.nursingSummaryFieldLookup = this.fieldLookup('nursing_summary');
                this.nursingSummarySubsectionHasData = this.subsectionHasData('nursing_summary');
                this.generateNursingSummarySubsectionString = this.generateSubsectionString('nursing_summary');
                this.musculoskeletalFieldLookup = this.fieldLookup('body_systems_activity_musculoskeletal_finding');
                this.musculoskeletalSubsectionHasData = this.subsectionHasData('body_systems_activity_musculoskeletal_finding');
                this.generateMusculoskeletalSubsectionString = this.generateSubsectionString('body_systems_activity_musculoskeletal_finding');
            }
            ChartingVisitTaskListController.prototype.$onInit = function () {
                var _this = this;
                this.isLoading = true;
                this.readonly = this.viewOnly;
                var instructionsPromise = !this.chartStore.model.hasOwnProperty('visit_task_lists_instructions')
                    ? this.patientMainService
                        .getClinicalSummary(this.chartStore.model.patient_id, this.chartStore.model.effective_date)
                        .then(function (resp) {
                        _this.chartStore.model.visit_task_lists_instructions = __assign({ open: false }, resp.data);
                    })
                    : Promise.resolve();
                var tasksPromise = !(this.chartStore.model.visit_task_lists &&
                    this.chartStore.model.visit_task_lists.length &&
                    (this.chartStore.model.visit_task_lists[0].id ||
                        this.chartStore.model.visit_task_lists[0].task_assignment_id))
                    ? this.visitTaskList
                        .loadByPatientId(this.chartStore.model.patient_id)
                        .$promise.then(function (visitTasks) {
                        var _a;
                        var today = new Date();
                        var tempTasks = [];
                        visitTasks.forEach(function (v, i) {
                            var visitTask = {
                                open: false,
                                patient_id: v.patient_id,
                                task_assignment_id: v.task_assignment_id,
                                user_id: _this.Session.getUser().id,
                                effective_date: today.getFullYear() + "-" + (today.getMonth() +
                                    1) + "-" + today.getDate(),
                                description: v.description,
                                recurring_task_actions: v.tasks.map(function (t) {
                                    return {
                                        recurring_task_id: t.id,
                                        description: t.description,
                                        completed: false,
                                        acknowledged: false,
                                    };
                                }),
                            };
                            tempTasks.push(visitTask);
                        });
                        if (((_a = _this.chartStore.model.visit_task_lists) === null || _a === void 0 ? void 0 : _a.length) && tempTasks.length) {
                            tempTasks[0] = Object.assign({}, _this.chartStore.model.visit_task_lists[0], tempTasks[0]);
                        }
                        _this.chartStore.model.visit_task_lists = tempTasks;
                    })
                    : Promise.resolve();
                Promise.all([instructionsPromise, tasksPromise].map(function (p) { return p.catch(function (e) { return e; }); })).then(function () {
                    _this.isLoading = false;
                    _this.chartStore.model.visit_task_lists.forEach(function (taskList) {
                        taskList.open = false;
                        if (!taskList.description &&
                            taskList.recurring_task_actions &&
                            taskList.recurring_task_actions.length) {
                            taskList.description = taskList.recurring_task_actions[0].set_description;
                        }
                    });
                });
                if (!this.chartStore.model.visit_task_lists_instructions) {
                    this.chartStore.model.visit_task_lists_instructions = { open: false };
                }
            };
            ChartingVisitTaskListController.prototype.hasTasks = function () {
                var hasTasks = false;
                this.chartStore.model.visit_task_lists.forEach(function (taskList) {
                    if (taskList.recurring_task_actions && taskList.recurring_task_actions.length) {
                        hasTasks = true;
                    }
                });
                return hasTasks;
            };
            ChartingVisitTaskListController.prototype.getCompletedCount = function (item) {
                return item.recurring_task_actions
                    ? item.recurring_task_actions.filter(function (t) { return t.completed; }).length
                    : 0;
            };
            ChartingVisitTaskListController.prototype.onCompleted = function (task) {
                if (task.completed) {
                    task.acknowledged = true;
                }
            };
            ChartingVisitTaskListController.prototype.generateInstructionSections = function () {
                var sections = {};
                // nursing summary
                sections.nursingSummary = {};
                var nursingSummarySubsectionLookup = this.subsectionLookup('nursing_summaries');
                sections.nursingSummary.imminenceOfDeath = nursingSummarySubsectionLookup('Imminence of Death');
                sections.nursingSummary.nursingSummaryNotes = nursingSummarySubsectionLookup('Nursing Summary Notes');
                sections.nursingSummary.understandOwnCare = nursingSummarySubsectionLookup('Patient Ability to Understand and Participate in Own Care');
                sections.nursingSummary.familyWillingnessToCare = nursingSummarySubsectionLookup('Family/Representative/CG Willingness and Capability to Care For Patient');
                sections.nursingSummary.visitFrequencyDiscussion = nursingSummarySubsectionLookup('Visit Frequency Discussion');
                sections.nursingSummary.dischargePlanning = nursingSummarySubsectionLookup('Discharge Planning');
                // musculoskeletal
                sections.musculoskeletal = {};
                var musculoskeletalSubsectionLookup = this.subsectionLookup('body_systems_activity_musculoskeletal_findings');
                sections.musculoskeletal.general = musculoskeletalSubsectionLookup('General');
                sections.musculoskeletal.complaintsNoReport = musculoskeletalSubsectionLookup('Complaints or No Report');
                sections.musculoskeletal.activitiesDailyLiving = musculoskeletalSubsectionLookup('Activities in Daily Living');
                sections.musculoskeletal.otherActivities = musculoskeletalSubsectionLookup('Other Activities');
                sections.musculoskeletal.mobility = musculoskeletalSubsectionLookup('Mobility');
                sections.musculoskeletal.developmentalLevelOfFunction = musculoskeletalSubsectionLookup('Developmental/Pediatric Level of Function');
                sections.musculoskeletal.other = musculoskeletalSubsectionLookup('Other');
                sections.musculoskeletal.mobilityEquipment = musculoskeletalSubsectionLookup('Patient Used Mobility Equipment and Devices');
                sections.musculoskeletal.summary = musculoskeletalSubsectionLookup('Summary');
                return sections;
            };
            ChartingVisitTaskListController.prototype.subsectionLookup = function (sectionName) {
                var section = this.fieldDefinitions[sectionName];
                var emptySubsection = { fields: [] };
                return function (label) { return section.filter(function (s) { return s.label === label; })[0] || emptySubsection; };
            };
            ChartingVisitTaskListController.prototype.fieldLookup = function (section) {
                var _this = this;
                return function (field) {
                    return _this.chartStore.model.visit_task_lists_instructions[section][_this.mapField(field)];
                };
            };
            ChartingVisitTaskListController.prototype.subsectionHasData = function (section) {
                var _this = this;
                return function (fields) {
                    return !!fields.filter(function (item) {
                        return _this.chartStore.model.visit_task_lists_instructions[section].hasOwnProperty(_this.mapField(item.field)) &&
                            _this.chartStore.model.visit_task_lists_instructions[section][_this.mapField(item.field)] !== null &&
                            _this.chartStore.model.visit_task_lists_instructions[section][_this.mapField(item.field)] !== undefined &&
                            _this.chartStore.model.visit_task_lists_instructions[section][_this.mapField(item.field)] !== '';
                    }).length;
                };
            };
            ChartingVisitTaskListController.prototype.generateSubsectionString = function (section) {
                var _this = this;
                var fieldFormatter = this.getFieldFormatter(section);
                return function (items) {
                    return items.reduce(function (acc, curr, i) {
                        if (!_this.chartStore.model.visit_task_lists_instructions[section].hasOwnProperty(_this.mapField(curr.field))) {
                            return '';
                        }
                        return i === 0 ? fieldFormatter(curr) + " " : acc + ", " + fieldFormatter(curr);
                    }, '');
                };
            };
            ChartingVisitTaskListController.prototype.getFieldFormatter = function (section) {
                var _this = this;
                return function (item) {
                    return item.label + " (" + _this.chartStore.model.visit_task_lists_instructions[section][_this.mapField(item.field)] + ")";
                };
            };
            ChartingVisitTaskListController.prototype.mapField = function (field) {
                var res;
                // if the instructions field name differs from the form field name we need to reconcile
                switch (field) {
                    case 'prognosis_terminal':
                    case 'dp_goals_met':
                    case 'dp_patient_expires':
                    case 'dp_other':
                    case 'dp_5_day_notice':
                    case 'dp_transfer_form_complete':
                    case 'dp_revocation_form_complete':
                    case 'dp_continuity_of_care_transition':
                    case 'activity_no_report':
                    case 'activity_patient_complaint':
                    case 'activity_alteration_in_endurance':
                    case 'activity_poor_coordination_and_balance':
                    case 'activity_gait_disturbance':
                    case 'activity_bed_or_chair_bound':
                    case 'activity_plegia':
                    case 'activity_missing_limbs':
                    case 'activity_history_of_fractures':
                    case 'activity_contractures':
                    case 'activity_cane':
                    case 'activity_hoyer_lift':
                    case 'activity_walker':
                    case 'activity_crutches':
                    case 'activity_recliner':
                    case 'activity_wheelchair':
                    case 'activity_shower_seat':
                    case 'activity_bedside_commode':
                    case 'activity_adjustable_bed':
                    case 'activity_toilet_seat_riser':
                    case 'activity_bed_rails':
                    case 'activity_trapeze':
                    case 'activity_other_mobility_equipment':
                        res = "note_" + field;
                        break;
                    default:
                        res = field;
                }
                // when there's an id stored we want to show the description and the naming pattern allows us to do a simple replace
                if (res.endsWith('_id')) {
                    res = res.substring(0, res.length - 3) + "_description";
                }
                return res;
            };
            ChartingVisitTaskListController.$inject = [
                'PatientMainService',
                'VisitTaskList',
                'SessionService',
                'ChartingTemplateService',
                '$mdMedia',
            ];
            return ChartingVisitTaskListController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingVisitTaskList', new ConsoloChartingVisitTaskListSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingVitalSignMeasurementSection = /** @class */ (function () {
            function ConsoloChartingVitalSignMeasurementSection() {
                this.bindings = {
                    chartStore: '<',
                    formModel: '<',
                    viewOnly: '<',
                    formDefinition: '<',
                    showUnmodifiedSections: '=',
                };
                this.templateUrl = '/y/templates/clinical-charts/components/vital-sign-measurement.html';
                this.controller = ChartingVitalSignMeasurementController;
            }
            return ConsoloChartingVitalSignMeasurementSection;
        }());
        var ChartingVitalSignMeasurementController = /** @class */ (function () {
            function ChartingVitalSignMeasurementController($http, $scope, $mdDialog, logger, ChartingTemplateService, options, ChartUtilService) {
                this.$http = $http;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.ChartingTemplateService = ChartingTemplateService;
                this.options = options;
                this.ChartUtilService = ChartUtilService;
                this.scale = {};
                this.scales = [
                    { description: '', link: '' },
                    { description: 'ALSFRS', link: '/performance_scales/alsfrs' },
                    { description: 'ECOG', link: '/performance_scales/ecog' },
                    { description: 'Edmonton', link: '/performance_scales/edmonton' },
                    { description: 'FAST', link: '/performance_scales/fast' },
                    { description: 'Geriatric Depression', link: '/performance_scales/gds' },
                    { description: 'Glasgow Coma', link: '/performance_scales/glasgow' },
                    { description: 'Karnofsky', link: '/performance_scales/karnofsky' },
                    { description: 'Mitchel Mortality Risk', link: '/performance_scales/mitchel' },
                    { description: 'Morse', link: '/performance_scales/morse' },
                    { description: 'NYHA', link: '/performance_scales/nyha' },
                    { description: 'Palliative', link: '/performance_scales/palliative' },
                ];
            }
            ChartingVitalSignMeasurementController.prototype.$onInit = function () {
                var _this = this;
                this.viewOnly = this.chartStore.model.locked || this.viewOnly;
                this.loadFormDefinition();
                this.$scope.$on('reloadFormDefinition', function () {
                    _this.loadFormDefinition();
                });
            };
            ChartingVitalSignMeasurementController.prototype.loadFormDefinition = function () {
                this.formDefinition = this.ChartingTemplateService.getFormDefinition('vital_sign_measurement', this.chartStore.model.encounter_type_id, this.chartStore.model.patient_id, this.chartStore.model.encounter_type);
            };
            ChartingVitalSignMeasurementController.prototype.scaleChanged = function ($event) {
                var _this = this;
                if (this.scale.link !== '') {
                    this.$http.get(this.scale.link + '.html').then(function (data) {
                        _this.$mdDialog
                            .show(_this.$mdDialog
                            .alert()
                            .parent(angular.element(document.querySelector('#popupContainer')))
                            .clickOutsideToClose(true)
                            .htmlContent(data.data)
                            .ariaLabel(_this.scale.description + ' Scale')
                            .ok('Close')
                            .targetEvent($event))
                            .then(function () {
                            _this.scale = null;
                        });
                    });
                }
            };
            ChartingVitalSignMeasurementController.$inject = [
                '$http',
                '$scope',
                '$mdDialog',
                'logger',
                'ChartingTemplateService',
                'options',
                'ChartUtilService',
            ];
            return ChartingVitalSignMeasurementController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingVitalSignMeasurement', new ConsoloChartingVitalSignMeasurementSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesBasicEditController = /** @class */ (function () {
            function EncounterTypesBasicEditController($scope, Permissions, EncounterTypesSidebar, ChartingTemplateService, options, HijackFindService, encounterType, $mdDialog, $state, $window, SidebarService) {
                var _this = this;
                this.$scope = $scope;
                this.Permissions = Permissions;
                this.EncounterTypesSidebar = EncounterTypesSidebar;
                this.ChartingTemplateService = ChartingTemplateService;
                this.options = options;
                this.HijackFindService = HijackFindService;
                this.encounterType = encounterType;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.$window = $window;
                this.SidebarService = SidebarService;
                this.role_names = '';
                this.filteredFields = {};
                // this.$window.addEventListener('resize', this.isWindowSmall);
                this.EncounterTypesSidebar.templateSections = ChartingTemplateService.getFieldDefinitions();
                for (var _i = 0, _a = this.EncounterTypesSidebar.templateSections; _i < _a.length; _i++) {
                    var section = _a[_i];
                    for (var _b = 0, section_1 = section; _b < section_1.length; _b++) {
                        var field = section_1[_b];
                        if (field.type === 'booltext') {
                            field.field = 'note_' + field.field;
                        }
                    }
                }
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                    _this.updateRoleNames();
                });
                this.HijackFindService.register('#quickFilter', '#quickFilter');
                this.$scope.$on('$destroy', function () {
                    _this.HijackFindService.unregister();
                });
                this.EncounterTypesSidebar.encounter_type = this.encounterType;
                this.loadEncounterType();
                $scope.$on('$destroy', function () {
                    _this.EncounterTypesSidebar.resetET();
                });
                // $scope.$watch(() => {
                //   return this.isWindowSmall();
                // }, function (newVal, oldVal) {
                //   $scope.$ctrl.setSideNavigation(newVal);
                // });
                // angular.element(document).ready(function () {
                //   console.log('page loading completed');
                //   $scope.$ctrl.screenWidth();
                // });
            }
            EncounterTypesBasicEditController.prototype.loadEncounterType = function () {
                var _this = this;
                if (this.encounterType.id) {
                    this.EncounterTypesSidebar.onStyleChange(true);
                }
                if (this.encounterType.patient_time_fields &&
                    this.encounterType.patient_time_fields.patient_time) {
                    var s = this.sectionSearchBy('patient_time', 'name');
                    s.active = true;
                    this.EncounterTypesSidebar.active_sections.push(s);
                    var pt_required_1 = [];
                    this.encounterType.patient_time_fields.patient_time.forEach(function (field) {
                        if (field === 'in_date' || field === 'in_time') {
                            if (pt_required_1.indexOf('in_datetime') < 0) {
                                pt_required_1.push('in_datetime');
                            }
                        }
                        else if (field === 'out_date' || field === 'out_time') {
                            if (pt_required_1.indexOf('out_datetime') < 0) {
                                pt_required_1.push('out_datetime');
                            }
                        }
                        else {
                            pt_required_1.push(field);
                        }
                    });
                    this.encounterType.required_fields.patient_time = pt_required_1;
                }
                if (this.encounterType.evv_fields &&
                    this.encounterType.evv_fields.electronic_visit_verification) {
                    var s = this.sectionSearchBy('electronic_visit_verification', 'name');
                    s.active = true;
                    this.EncounterTypesSidebar.active_sections.push(s);
                    this.encounterType.required_fields.electronic_visit_verification = this.encounterType.evv_fields.electronic_visit_verification;
                }
                Object.keys(this.encounterType.required_fields).forEach(function (key) {
                    if (_this.encounterType.required_fields[key]) {
                        var s_1 = _this.sectionSearchBy(key, 'name');
                        _this.EncounterTypesSidebar.active_sections.push(s_1.title);
                        s_1.active = true;
                        _this.encounterType.required_fields[key].forEach(function (required_field) {
                            _this.EncounterTypesSidebar.templateSections[s_1.model].forEach(function (section) {
                                section.fields.forEach(function (field) {
                                    if (field.field === required_field ||
                                        (field.type === 'booltext' && 'note_' + field.field === required_field) ||
                                        (field.type === 'booltext' && 'has_' + field.field === required_field)) {
                                        field.required = true;
                                    }
                                });
                            });
                        });
                    }
                });
            };
            EncounterTypesBasicEditController.prototype.updateRoleNames = function () {
                var _this = this;
                var tempArr = [];
                this.encounterType.role_ids.forEach(function (id) {
                    _this.base_roles.forEach(function (role) {
                        if (role.id === id) {
                            tempArr.push(role.description);
                        }
                    });
                });
                if (tempArr.length) {
                    tempArr.sort();
                    this.role_names = tempArr.join(', ');
                }
                else {
                    this.role_names = 'No roles selected';
                }
            };
            EncounterTypesBasicEditController.prototype.filteredCheckboxToggle = function (current_section_model, item) {
                var section = this.sectionSearchByModel(current_section_model);
                this.EncounterTypesSidebar.checkboxToggle(section, item);
            };
            EncounterTypesBasicEditController.prototype.initiateTemplateSearch = function () {
                var _this = this;
                this.templateSearch();
                this.$mdDialog
                    .show({
                    fullscreen: true,
                    scope: this.$scope,
                    preserveScope: true,
                    controller: 'FieldFilterModalController as $modalCtrl',
                    templateUrl: '/y/templates/clinical-charts/encounter-types/field-filter-modal.html',
                })
                    .then(function () {
                    _this.EncounterTypesSidebar.textFilter = '';
                });
            };
            EncounterTypesBasicEditController.prototype.templateSearch = function () {
                var _this = this;
                var searchModel;
                var searchSection;
                var tempFiltered = {};
                Object.keys(this.EncounterTypesSidebar.templateSections).forEach(function (key) {
                    tempFiltered[key] = { fields: [] };
                    _this.EncounterTypesSidebar.templateSections[key].forEach(function (subsection) {
                        subsection.fields.forEach(function (field) {
                            if (field.label
                                .toLowerCase()
                                .indexOf(_this.EncounterTypesSidebar.textFilter.toLowerCase()) >= 0) {
                                tempFiltered[key].fields.push(field);
                            }
                        });
                    });
                    if (tempFiltered[key].fields.length) {
                        searchSection = _this.sectionSearchByModel(key);
                        if (searchSection) {
                            tempFiltered[key].title = searchSection.title;
                        }
                        else {
                            delete tempFiltered[key];
                        }
                    }
                    else {
                        searchSection = null;
                        delete tempFiltered[key];
                    }
                });
                this.filteredFields = tempFiltered;
            };
            EncounterTypesBasicEditController.prototype.sectionSearchByModel = function (model_name) {
                var _this = this;
                var searchSection;
                Object.keys(this.EncounterTypesSidebar.allSections).forEach(function (key) {
                    if (_this.EncounterTypesSidebar.allSections[key].model === model_name) {
                        searchSection = _this.EncounterTypesSidebar.allSections[key];
                    }
                });
                return searchSection;
            };
            EncounterTypesBasicEditController.prototype.sectionSearchByTitle = function (title) {
                var _this = this;
                var searchSection;
                Object.keys(this.EncounterTypesSidebar.allSections).forEach(function (key) {
                    if (_this.EncounterTypesSidebar.allSections[key].title === title) {
                        searchSection = _this.EncounterTypesSidebar.allSections[key];
                    }
                });
                return searchSection;
            };
            EncounterTypesBasicEditController.prototype.sectionSearchByName = function (name) {
                var _this = this;
                var searchSection;
                Object.keys(this.EncounterTypesSidebar.allSections).forEach(function (key) {
                    if (_this.EncounterTypesSidebar.allSections[key].name === name) {
                        searchSection = _this.EncounterTypesSidebar.allSections[key];
                    }
                });
                return searchSection;
            };
            EncounterTypesBasicEditController.prototype.sectionSearchBy = function (text, by) {
                var _this = this;
                var searchSection;
                Object.keys(this.EncounterTypesSidebar.allSections).forEach(function (key) {
                    if (_this.EncounterTypesSidebar.allSections[key][by] === text) {
                        searchSection = _this.EncounterTypesSidebar.allSections[key];
                    }
                });
                return searchSection;
            };
            EncounterTypesBasicEditController.prototype.navigateTo = function (title) {
                var section = this.sectionSearchByTitle(title);
                this.EncounterTypesSidebar.changeSection(section);
            };
            EncounterTypesBasicEditController.prototype.screenModal = function (event) {
                this.EncounterTypesSidebar.screenModal(event);
            };
            EncounterTypesBasicEditController.prototype.editCurrentSection = function () {
                this.EncounterTypesSidebar.screenModal(event);
            };
            EncounterTypesBasicEditController.prototype.removeCurrentSection = function () {
                var _this = this;
                var currentSection = this.EncounterTypesSidebar.current_section;
                this.$mdDialog
                    .show(this.generateConfirmationRequest('Are you sure you want to remove ' + currentSection.title + '?'))
                    .then(function () {
                    _this.EncounterTypesSidebar.deleteCurrentSection();
                });
                return true;
            };
            EncounterTypesBasicEditController.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            EncounterTypesBasicEditController.prototype.showHelpDialog = function (ev) {
                this.$mdDialog.show({
                    contentElement: '#helpDialog',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                });
            };
            EncounterTypesBasicEditController.prototype.screenWidth = function () {
                if (typeof window.innerWidth !== 'undefined') {
                    this.viewportwidth = window.innerWidth;
                    this.viewportheight = window.innerHeight;
                }
                else if (typeof document.documentElement !== 'undefined' &&
                    typeof document.documentElement.clientWidth !== 'undefined' &&
                    document.documentElement.clientWidth !== 0) {
                    this.viewportwidth = document.documentElement.clientWidth;
                    this.viewportheight = document.documentElement.clientHeight;
                }
                else {
                    this.viewportwidth = document.getElementsByTagName('body')[0].clientWidth;
                    this.viewportheight = document.getElementsByTagName('body')[0].clientHeight;
                }
                this.setSidenav();
            };
            EncounterTypesBasicEditController.prototype.setSidenav = function () {
                // if (this.viewportwidth > 960) {
                //   this.$state.current.data.hideSidebar = true;
                // } else {
                //   this.$state.current.data.hideSidebar = false;
                // }
                if (this.viewportwidth > 960) {
                    this.SidebarService.close();
                }
                else {
                    this.SidebarService.open();
                }
            };
            EncounterTypesBasicEditController.prototype.isWindowSmall = function () {
                if (window.innerWidth < 960) {
                    return true;
                }
                else {
                    return false;
                }
            };
            EncounterTypesBasicEditController.prototype.setSideNavigation = function (newVal) {
                if (newVal) {
                    this.SidebarService.open();
                }
                else {
                    this.SidebarService.close();
                }
            };
            EncounterTypesBasicEditController.prototype.activateVersion = function ($event) {
                var _this = this;
                this.EncounterTypesSidebar.EncounterTypes.activateModal(this.encounterType, $event).then(function () {
                    _this.$state.go('encounter-types');
                });
            };
            EncounterTypesBasicEditController.$inject = [
                '$scope',
                'PermissionsService',
                'EncounterTypesSidebarService',
                'ChartingTemplateService',
                'options',
                'hijackFindService',
                'encounterType',
                '$mdDialog',
                '$state',
                '$window',
                'SidebarService',
            ];
            return EncounterTypesBasicEditController;
        }());
        controllers.EncounterTypesBasicEditController = EncounterTypesBasicEditController;
        angular
            .module('consolo')
            .controller('EncounterTypesBasicEditController', EncounterTypesBasicEditController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesBasicEditorModalController = /** @class */ (function () {
            function EncounterTypesBasicEditorModalController($mdDialog, EncounterTypesSidebarService, options, ChartingTemplateService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.EncounterTypesSidebarService = EncounterTypesSidebarService;
                this.options = options;
                this.ChartingTemplateService = ChartingTemplateService;
                this.templateSections = {};
                this.textFilter = '';
                this.name = '';
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                });
                for (var _i = 0, _a = this.EncounterTypesSidebarService.allSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.tempActive = s.active;
                }
                this.types = [
                    { id: '', description: '' },
                    { id: 'optimized', description: 'Optimized' },
                    { id: 'encounter_charting', description: 'Encounter' },
                ];
                this.templateSections = ChartingTemplateService.getFieldDefinitions();
                for (var _b = 0, _c = this.templateSections; _b < _c.length; _b++) {
                    var section = _c[_b];
                    for (var _d = 0, section_1 = section; _d < section_1.length; _d++) {
                        var field = section_1[_d];
                        if (field.type === 'booltext') {
                            field.field = 'note_' + field.field;
                        }
                    }
                }
            }
            EncounterTypesBasicEditorModalController.prototype.addSections = function () {
                var hasActive = false;
                for (var _i = 0, _a = this.EncounterTypesSidebarService.allSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.active = s.tempActive;
                    delete s._hide;
                    delete s.tempActive;
                }
                this.EncounterTypesSidebarService.save();
                this.$mdDialog.hide();
            };
            EncounterTypesBasicEditorModalController.prototype.close = function () {
                this.EncounterTypesSidebarService.allSections.forEach(function (section) {
                    section.active = section.tempActive;
                    delete section._hide;
                    delete section.tempActive;
                });
                this.EncounterTypesSidebarService.current_section = this.EncounterTypesSidebarService.allSections[0];
                this.$mdDialog.hide();
            };
            EncounterTypesBasicEditorModalController.prototype.sectionSearch = function () {
                var _this = this;
                this.EncounterTypesSidebarService.allSections.forEach(function (section) {
                    if (section.title.toLowerCase().indexOf(_this.textFilter.toLowerCase()) < 0) {
                        section._hide = true;
                    }
                    else {
                        section._hide = false;
                    }
                });
            };
            EncounterTypesBasicEditorModalController.$inject = [
                '$mdDialog',
                'EncounterTypesSidebarService',
                'options',
                'ChartingTemplateService',
            ];
            return EncounterTypesBasicEditorModalController;
        }());
        controllers.EncounterTypesBasicEditorModalController = EncounterTypesBasicEditorModalController;
        angular
            .module('consolo')
            .controller('EncounterTypesBasicEditorModalController', EncounterTypesBasicEditorModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesBasicModalController = /** @class */ (function () {
            function EncounterTypesBasicModalController($scope, $mdDialog, EncounterTypesSidebarService, options, HijackFindService // private encounterTypePages
            ) {
                var _this = this;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.EncounterTypesSidebarService = EncounterTypesSidebarService;
                this.options = options;
                this.HijackFindService = HijackFindService;
                this.textFilter = '';
                this.name = '';
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                });
                for (var _i = 0, _a = this.EncounterTypesSidebarService.allSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.tempActive = s.active;
                }
                this.types = [
                    { id: '', description: '' },
                    { id: 'optimized', description: 'Optimized' },
                    { id: 'encounter_charting', description: 'Encounter' },
                ];
                // this.HijackFindService.register('#quickFilter', '#quickFilter');
                // this.$scope.$on('$destroy', () => {
                //   this.HijackFindService.unregister();
                // });
            }
            EncounterTypesBasicModalController.prototype.selectChartingStyle = function () { };
            EncounterTypesBasicModalController.prototype.addSections = function () {
                var hasActive = false;
                for (var _i = 0, _a = this.EncounterTypesSidebarService.allSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.active = s.tempActive;
                    delete s._hide;
                    delete s.tempActive;
                }
                this.close();
            };
            EncounterTypesBasicModalController.prototype.close = function () {
                this.EncounterTypesSidebarService.allSections.forEach(function (section) {
                    delete section._hide;
                    delete section.tempActive;
                });
                this.$mdDialog.hide();
            };
            EncounterTypesBasicModalController.prototype.sectionSearch = function () {
                var _this = this;
                this.EncounterTypesSidebarService.allSections.forEach(function (section) {
                    if (section.title.toLowerCase().indexOf(_this.textFilter.toLowerCase()) < 0) {
                        section._hide = true;
                    }
                    else {
                        section._hide = false;
                    }
                });
            };
            EncounterTypesBasicModalController.$inject = [
                '$scope',
                '$mdDialog',
                'EncounterTypesSidebarService',
                'options',
                'hijackFindService',
            ];
            return EncounterTypesBasicModalController;
        }());
        controllers.EncounterTypesBasicModalController = EncounterTypesBasicModalController;
        angular
            .module('consolo')
            .controller('EncounterTypesBasicModalController', EncounterTypesBasicModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesBasicSidebarController = /** @class */ (function () {
            function EncounterTypesBasicSidebarController(Permissions, EncounterTypesSidebarService, $mdDialog) {
                this.Permissions = Permissions;
                this.EncounterTypesSidebarService = EncounterTypesSidebarService;
                this.$mdDialog = $mdDialog;
            }
            EncounterTypesBasicSidebarController.prototype.changeSection = function (section) {
                this.EncounterTypesSidebarService.changeSection(section);
            };
            EncounterTypesBasicSidebarController.prototype.screenModal = function (event) {
                this.EncounterTypesSidebarService.screenModal(event);
                // this.$mdDialog.show({
                //   targetEvent: event,
                //   clickOutsideToClose: true,
                //   bindToController: true,
                //   fullscreen: true,
                //   controller: 'EncounterTypesBasicModalController as $ctrl',
                //   templateUrl: '/y/templates/clinical-charts/encounter-types/encounter-types-basic-modal.html'
                // });
            };
            EncounterTypesBasicSidebarController.$inject = [
                'PermissionsService',
                'EncounterTypesSidebarService',
                '$mdDialog',
            ];
            return EncounterTypesBasicSidebarController;
        }());
        controllers.EncounterTypesBasicSidebarController = EncounterTypesBasicSidebarController;
        angular
            .module('consolo')
            .controller('EncounterTypesBasicSidebarController', EncounterTypesBasicSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesIndexController = /** @class */ (function () {
            function EncounterTypesIndexController(Permissions, EncounterTypes, $state, ChartingTemplateService, $mdDialog, //ng.material.IDialogService,
            Session) {
                var _this = this;
                this.Permissions = Permissions;
                this.EncounterTypes = EncounterTypes;
                this.$state = $state;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.includeBasicDiscontinued = false;
                this.includeAdvancedDiscontinued = false;
                var session = this.Session.get();
                this.hasTemplateBuilder = session.agency.has_encounter_charting;
                this.etLayout = {
                    id_field: 'id',
                    callbackFn: function (data, $event) {
                        _this.showViewModal(data, $event);
                    },
                    headerMenu: {
                        csvContents: true,
                        items: [],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (data, $event) {
                                    _this.showViewModal(data, $event);
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data) {
                                    var stateName = 'encounter-types.basic-edit';
                                    var stateParams = { encounterTypeId: data.id };
                                    if (data.etTypeName === 'Advanced') {
                                        stateParams = { templateId: data.id };
                                        stateName = 'encounter-types.template-edit';
                                    }
                                    _this.$state.go(stateName, stateParams);
                                },
                                conditionalDisableFn: function (data) {
                                    return !data.editable;
                                },
                            },
                            {
                                label: 'Create New Version',
                                icon: 'unarchive',
                                callbackFn: function (data, $event) {
                                    _this.updateVersion(data, _this.basicData, $event);
                                },
                                conditionalHideFn: function (data) {
                                    return !data.active;
                                },
                            },
                            {
                                label: 'Activate Version',
                                icon: 'check_circle',
                                callbackFn: function (data, $event) {
                                    _this.activateVersion(data, $event);
                                },
                                conditionalHideFn: function (data) {
                                    return !!data.active;
                                },
                            },
                            {
                                label: 'Create Copy',
                                icon: 'content_copy',
                                callbackFn: function (data) {
                                    var stateName = data.etTypeName === 'Advanced'
                                        ? 'encounter-types.template-new'
                                        : 'encounter-types.basic-new';
                                    _this.$state.go(stateName, { cloneId: data.id });
                                },
                            },
                            {
                                label: 'Discontinue',
                                icon: 'delete',
                                conditionalHideFn: function (data) {
                                    return !data.discontinueable;
                                },
                                callbackFn: function (data, $event) {
                                    return _this.discontinue(data, $event);
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete_forever',
                                conditionalHideFn: function (data) {
                                    return !data.editable;
                                },
                                callbackFn: function (data, $event) {
                                    return _this.deleteEncounterType(data, $event);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Version', field: 'version' },
                        { title: 'Roles', field: 'roles' },
                        {
                            title: 'Active',
                            field: 'active',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        { title: 'Created', field: 'created_at', canSort: true, type: 'dateTime' },
                        { title: 'Discontinued', field: 'discontinue_date', type: 'dateTime' },
                    ],
                };
                this.customLayout = {
                    id_field: 'id',
                    callbackFn: function (data, $event) {
                        _this.showViewModal(data, $event);
                    },
                    headerMenu: {
                        csvContents: true,
                        items: [],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (data, $event) {
                                    _this.showViewModal(data, $event);
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data) {
                                    _this.$state.go('encounter-types.template-edit', { templateId: data.id });
                                },
                                conditionalDisableFn: function (data) {
                                    return !data.editable;
                                },
                            },
                            {
                                label: 'Create New Version',
                                icon: 'unarchive',
                                callbackFn: function (data, $event) {
                                    _this.updateVersion(data, _this.customData, $event);
                                },
                                conditionalHideFn: function (data) {
                                    return !data.active;
                                },
                            },
                            {
                                label: 'Activate Version',
                                icon: 'check_circle',
                                callbackFn: function (data, $event) {
                                    _this.activateVersion(data, $event);
                                },
                                conditionalHideFn: function (data) {
                                    return !!data.active;
                                },
                            },
                            {
                                label: 'Create Copy',
                                icon: 'content_copy',
                                callbackFn: function (data) {
                                    _this.$state.go('encounter-types.template-new', { cloneId: data.id });
                                },
                            },
                            {
                                label: 'Discontinue',
                                icon: 'delete',
                                conditionalHideFn: function (data) {
                                    return !data.discontinueable;
                                },
                                callbackFn: function (data, $event) {
                                    return _this.discontinue(data, $event);
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete_forever',
                                conditionalHideFn: function (data) {
                                    return !data.editable;
                                },
                                callbackFn: function (data, $event) {
                                    return _this.deleteEncounterType(data, $event);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'name', canSort: true },
                        { title: 'Version', field: 'version' },
                        { title: 'Roles', field: 'roles' },
                        // { title: 'Sections', field: 'sections' },
                        {
                            title: 'Active',
                            field: 'active',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        { title: 'Created', field: 'created_at', canSort: true, type: 'dateTime' },
                        { title: 'Discontinued', field: 'discontinue_date', type: 'dateTime' },
                    ],
                };
                this.updateData(true);
            }
            EncounterTypesIndexController.prototype.updateData = function (active, chartingType) {
                var _this = this;
                this.updateIndexLoaders(chartingType, true);
                this.EncounterTypes.select(active, null, chartingType).then(function (data) {
                    var _a, _b;
                    _this.updateIndexLoaders(chartingType, true, true);
                    data.sort(_this.sortEt);
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var item = data_1[_i];
                        item.roles = item.role_names ? item.role_names.join(', ') : '';
                        var etName = void 0;
                        switch (item.charting_type) {
                            case 'custom':
                                etName = item.name
                                    .toLowerCase()
                                    .replace(/ /g, '_')
                                    .replace(/([^a-zA-Z0-9_])*/g, '');
                                item.etTypeName = 'Advanced';
                                break;
                            case 'tablet_optimized':
                                etName = item.charting_type;
                                item.etTypeName = 'Optimized';
                                break;
                            case 'encounter_charting':
                                etName = item.charting_type;
                                item.etTypeName = 'Encounter';
                                break;
                            default:
                                etName = 'tablet_optimized';
                                item.etTypeName = 'Optimized';
                                break;
                        }
                        var temp = [];
                        if (item.patient_time_fields.patient_time) {
                            temp.push('Patient Time');
                        }
                        if (item.evv_fields.electronic_visit_verification) {
                            temp.push('EVV');
                        }
                        item.sections = temp.join(', ');
                        if (item.charting_type === 'custom' || item.charting_type === 'encounter_charting') {
                            var screens = ((_b = (_a = item) === null || _a === void 0 ? void 0 : _a.section_template) === null || _b === void 0 ? void 0 : _b.screens) ? item.section_template.screens : [];
                            for (var _c = 0, screens_1 = screens; _c < screens_1.length; _c++) {
                                var s = screens_1[_c];
                                _this.ChartingTemplateService.setDefaultSectionTitle(s);
                            }
                            _this.customData.push(item);
                        }
                        else {
                            _this.basicData.push(item);
                        }
                    }
                    _this.updateIndexLoaders(chartingType, false);
                });
            };
            EncounterTypesIndexController.prototype.updateIndexLoaders = function (chartingType, isLoading, clearArray) {
                switch (chartingType) {
                    case 'custom':
                        this.customData = clearArray ? [] : this.customData;
                        this.advancedIsLoading = isLoading;
                        break;
                    case 'tablet_optimized':
                        this.basicData = clearArray ? [] : this.basicData;
                        this.basicIsLoading = isLoading;
                        break;
                    default:
                        this.basicIsLoading = isLoading;
                        this.advancedIsLoading = isLoading;
                        this.customData = clearArray ? [] : this.customData;
                        this.basicData = clearArray ? [] : this.basicData;
                        break;
                }
            };
            EncounterTypesIndexController.prototype.sortEt = function (a, b) {
                var nameA = a.name.toUpperCase(), nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                if (a.version < b.version) {
                    return -1;
                }
                if (a.version > b.version) {
                    return 1;
                }
                return 0;
            };
            EncounterTypesIndexController.prototype.deleteEncounterType = function (et, $event) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Delete Encounter Type?')
                    .textContent('Delete Encounter Type ' + et.name + ' v' + et.version + '? ')
                    .targetEvent($event)
                    .ok('Delete')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.EncounterTypes.delete(et).then(function () {
                        _this.refreshData(et.charting_type);
                    });
                });
            };
            EncounterTypesIndexController.prototype.discontinue = function (et, $event) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    targetEvent: $event,
                    clickOutsideToClose: true,
                    fullscreen: true,
                    template: "\n          <md-dialog class=\"md-hue-1\" aria-label=\"Clinical Chart\" flex=\"75\">\n            <md-toolbar>\n              <div class=\"md-toolbar-tools\">\n                Discontinue Encounter Type\n                <span flex=\"\"></span>\n                <md-button class=\"md-icon-button\" ng-click=\"$mdDialog.cancel();\">\n                  <md-icon class=\"material-icons\" aria-label=\"Close dialog\" md-svg-icon=\"navigation:close\"></md-icon>\n                </md-button>\n              </div>\n            </md-toolbar>\n            <md-dialog-content layout-padding>\n              <div class=\"md-subhead\">Are you sure you want to discontinue Encounter Type '{{encounterType.name}}'?</div>\n              <div>\n                <csg-datepicker label=\"Discontinue Date\" ng-model=\"discontinueDate\" csg-type=\"date\" max-date=\"today\" csg-md></csg-datepicker>\n              </div>\n            </md-dialog-content>\n            <md-dialog-actions>\n              <span flex></span>\n              <md-button class=\"md-warn\" ng-click=\"$mdDialog.cancel()\">Cancel</md-button>\n              <md-button ng-click=\"$mdDialog.hide(discontinueDate)\">Discontinue</md-button>\n            </md-dialog-actions>\n          </md-dialog>",
                    controller: [
                        '$scope',
                        '$mdDialog',
                        'encounterType',
                        function ($scope, $mdDialog, encounterType) {
                            $scope.discontinueDate = new Date();
                            $scope.encounterType = encounterType;
                            $scope.$mdDialog = $mdDialog;
                            $scope.today = new Date();
                        },
                    ],
                    bindToController: true,
                    locals: {
                        encounterType: et,
                    },
                })
                    .then(function (val) {
                    et.discontinue_date = val;
                    return _this.EncounterTypes.update(et, true)
                        .then(function (updatedEt) {
                        et = updatedEt;
                        _this.refreshData(et.charting_type);
                        return true;
                    })
                        .catch(function () {
                        return false;
                    });
                })
                    .catch(function (val) { return val; });
            };
            EncounterTypesIndexController.prototype.showViewModal = function (template, $event) {
                var url = '/y/templates/clinical-charts/encounter-types/encounter-types-view-modal.html';
                var dialogOptions = {
                    controller: 'EncounterTypesViewModalController as $ctrl',
                    templateUrl: url,
                    targetEvent: $event,
                    fullscreen: true,
                    clickOutsideToClose: true,
                    locals: { encounter_template: template },
                };
                this.$mdDialog.show(dialogOptions);
            };
            EncounterTypesIndexController.prototype.updateVersion = function (et, etDataSet, $event) {
                var _this = this;
                var t = etDataSet.filter(function (i) { return i.parent_id === et.parent_id && i.version === et.version + 1; });
                if (t.length) {
                    var exists = this.$mdDialog
                        .alert()
                        .title('New Version Already Exists')
                        .textContent("Version " + (et.version + 1) + " of '" + et.name + "' already exists.  Please edit that version and activate it when ready for use.")
                        .ok('OK');
                    this.$mdDialog.show(exists);
                    return;
                }
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Create New Version?')
                    .textContent('Create version ' +
                    (et.version + 1) +
                    " of '" +
                    et.name +
                    "' from version " +
                    et.version +
                    '?')
                    .targetEvent($event)
                    .ok('Create New Version')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    var stateName = et.etTypeName === 'Advanced'
                        ? 'encounter-types.template-new'
                        : 'encounter-types.basic-new';
                    _this.$state.go(stateName, { updateId: et.id });
                });
            };
            EncounterTypesIndexController.prototype.activateVersion = function (et, $event) {
                var _this = this;
                this.EncounterTypes.activateModal(et, $event).then(function () {
                    _this.refreshData(et.charting_type);
                });
            };
            EncounterTypesIndexController.prototype.refreshData = function (chartingType) {
                switch (chartingType) {
                    case 'custom':
                        this.updateData(!this.includeAdvancedDiscontinued, 'custom');
                        break;
                    case 'tablet_optimized':
                        this.updateData(!this.includeBasicDiscontinued, 'tablet_optimized');
                        break;
                    default:
                        this.updateData(!this.includeAdvancedDiscontinued, 'custom');
                        this.updateData(!this.includeBasicDiscontinued, 'tablet_optimized');
                        break;
                }
            };
            EncounterTypesIndexController.$inject = [
                'PermissionsService',
                'EncounterTypes',
                '$state',
                'ChartingTemplateService',
                '$mdDialog',
                'SessionService',
            ];
            return EncounterTypesIndexController;
        }());
        controllers.EncounterTypesIndexController = EncounterTypesIndexController;
        angular
            .module('consolo')
            .controller('EncounterTypesIndexController', EncounterTypesIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var EncounterTypesSidebarService = /** @class */ (function () {
            function EncounterTypesSidebarService(ChartingTemplateService, $mdDialog, EncounterTypes, $state) {
                this.ChartingTemplateService = ChartingTemplateService;
                this.$mdDialog = $mdDialog;
                this.EncounterTypes = EncounterTypes;
                this.$state = $state;
                this.allSections = [];
                this.otherSections = [];
                this.optimizedSections = [];
                this.optimizedFlattenedSections = [];
                this.encounterSections = [];
                this.encounter_type = {};
                this.styleChosen = true;
                this.templateSections = {};
                this.saving = false;
                this.active_sections = ['General Details'];
                this.allSections = this.ChartingTemplateService.getScreens('tablet_optimized').filter(function (s) {
                    return s.model !== 'care_plan_problem_updates';
                });
                this.encounter_type['charting_type'] = 'tablet_optimized';
                if (!this.current_section) {
                    this.current_section = this.allSections[0];
                }
            }
            EncounterTypesSidebarService.prototype.resetET = function () {
                this.encounter_type = {};
                this.active_sections = ['General Details'];
                this.allSections = this.ChartingTemplateService.getScreens('tablet_optimized');
                this.encounter_type['charting_type'] = 'tablet_optimized';
                this.current_section = this.allSections[0];
            };
            EncounterTypesSidebarService.prototype.changeSection = function (item) {
                this.current_section = item;
                this.textFilter = '';
            };
            EncounterTypesSidebarService.prototype.sectionSearchBy = function (text, by) {
                var _this = this;
                var searchSection;
                Object.keys(this.allSections).forEach(function (key) {
                    if (_this.allSections[key][by] === text) {
                        searchSection = _this.allSections[key];
                    }
                });
                return searchSection;
            };
            EncounterTypesSidebarService.prototype.screenModal = function (event) {
                return this.$mdDialog.show({
                    targetEvent: event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    fullscreen: true,
                    controller: 'EncounterTypesBasicEditorModalController as $ctrl',
                    templateUrl: '/y/templates/clinical-charts/encounter-types/encounter-types-basic-editor-modal.html',
                });
            };
            EncounterTypesSidebarService.prototype.onStyleChange = function (style) {
                this.styleChosen = true;
                this.allSections =
                    this.encounter_type['charting_type'] === 'encounter_charting'
                        ? __spreadArrays(this.allSections, this.encounterSections) : __spreadArrays(this.allSections, this.optimizedSections);
            };
            EncounterTypesSidebarService.prototype.getFlattened = function (arr) {
                var len = arr.length;
                var tempAll = [];
                for (var i = 0; i < len; i++) {
                    if (arr[i].childSections) {
                        var childLength = arr[i].childSections.length;
                        for (var j = 0; j < childLength; j++) {
                            tempAll.push(arr[i].childSections[j]);
                        }
                    }
                    else {
                        tempAll.push(arr[i]);
                    }
                }
                return tempAll;
            };
            EncounterTypesSidebarService.prototype.checkboxToggle = function (current_section, item) {
                if (!this.encounter_type.required_fields[current_section.name]) {
                    this.encounter_type.required_fields[current_section.name] = [];
                }
                var field_pos = this.encounter_type.required_fields[current_section.name].indexOf(item.field);
                if (field_pos >= 0) {
                    this.encounter_type.required_fields[current_section.name].splice(field_pos, 1);
                }
                else {
                    this.encounter_type.required_fields[current_section.name].push(item.field);
                }
                var section_pos = this.active_sections.indexOf(current_section.title);
                if (!item.required &&
                    section_pos >= 0 &&
                    !this.encounter_type.required_fields[current_section.name].length) {
                    this.active_sections.splice(section_pos, 1);
                    current_section.active = false;
                    delete this.encounter_type.required_fields[current_section.name];
                }
                else if (item.required && section_pos < 0) {
                    this.active_sections.push(current_section.title);
                    current_section.active = true;
                }
            };
            EncounterTypesSidebarService.prototype.toggleSectionCheckbox = function (section, isActive) {
                if (isActive) {
                    this.encounter_type.required_fields[section.name] = [];
                }
                else {
                    delete this.encounter_type.required_fields[section.name];
                    for (var _i = 0, _a = this.templateSections[section.model]; _i < _a.length; _i++) {
                        var subsection = _a[_i];
                        for (var _b = 0, _c = subsection.fields; _b < _c.length; _b++) {
                            var field = _c[_b];
                            if (field.required && !field.locked && !field.dependencies) {
                                field.required = false;
                            }
                        }
                    }
                }
            };
            EncounterTypesSidebarService.prototype.save = function () {
                var _this = this;
                this.saving = true;
                this.encounter_type.charting_type = 'tablet_optimized';
                if (this.encounter_type.required_fields.patient_time) {
                    var pt_required_1 = [];
                    this.encounter_type.required_fields.patient_time.forEach(function (field) {
                        if (field === 'in_datetime' && pt_required_1.indexOf('in_date') < 0) {
                            pt_required_1.push('in_date');
                            pt_required_1.push('in_time');
                        }
                        else if (field === 'out_datetime' && pt_required_1.indexOf('out_date') < 0) {
                            pt_required_1.push('out_date');
                            pt_required_1.push('out_time');
                        }
                        else {
                            pt_required_1.push(field);
                        }
                    });
                    this.encounter_type.patient_time_fields = { patient_time: pt_required_1 };
                }
                else {
                    this.encounter_type.patient_time_fields = {};
                }
                if (this.encounter_type.required_fields.electronic_visit_verification) {
                    this.encounter_type.evv_fields = {
                        electronic_visit_verification: this.encounter_type.required_fields
                            .electronic_visit_verification,
                    };
                }
                else {
                    this.encounter_type.evv_fields = {};
                }
                if (this.encounter_type.id) {
                    this.EncounterTypes.update(this.encounter_type)
                        .then(function () { })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.EncounterTypes.create(this.encounter_type)
                        .then(function (et) {
                        _this.$state.go('encounter-types.basic-edit', { encounterTypeId: et.id });
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            EncounterTypesSidebarService.prototype.resetCurrentSection = function () {
                this.current_section = this.allSections[0];
            };
            EncounterTypesSidebarService.prototype.deleteCurrentSection = function () {
                var section_pos = this.active_sections.indexOf(this.current_section.title);
                this.active_sections.splice(section_pos, 1);
                this.current_section.active = false;
                delete this.encounter_type.required_fields[this.current_section.name];
                this.save();
                this.resetCurrentSection();
            };
            EncounterTypesSidebarService.$inject = [
                'ChartingTemplateService',
                '$mdDialog',
                'EncounterTypes',
                '$state',
            ];
            return EncounterTypesSidebarService;
        }());
        services.EncounterTypesSidebarService = EncounterTypesSidebarService;
        angular.module('consolo').service('EncounterTypesSidebarService', EncounterTypesSidebarService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var EncounterTypesTemplateController = /** @class */ (function () {
            function EncounterTypesTemplateController($scope, cache, ChartingTemplateService, EncounterTypes, EncounterType, options, encounterType, $state, $mdDialog, $timeout, Session) {
                var _this = this;
                this.$scope = $scope;
                this.cache = cache;
                this.ChartingTemplateService = ChartingTemplateService;
                this.EncounterTypes = EncounterTypes;
                this.EncounterType = EncounterType;
                this.options = options;
                this.encounterType = encounterType;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$timeout = $timeout;
                this.Session = Session;
                this.textFilter = '';
                this.page_bag = {};
                this.section_bag = {};
                this.template = {
                    pages: [],
                    screens: [],
                    version: 1,
                    active: false,
                    code_ryte_enabled: false,
                    require_care_plan_problem: false
                };
                this.modelAsJson = {};
                this.showHidden = false;
                this.deletedItems = [];
                this.errors = [];
                this.saving = false;
                this.invalidRole = false;
                this.invalidName = false;
                this.showDelete = false;
                this.hasVitasEncounterCharting = false;
                this.required_fields = {};
                this.baseSections = [];
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                });
                this.cache(this.EncounterType, 'encounter_types', { maxAge: 10 })
                    .query({ active: true })
                    .$promise.then(function (data) {
                    _this.allEncounterTypes = data;
                });
                this.buildBaseTemplate();
                // this.newScreen = { title: 'New Screen', sections: [] };
                // this.newScreen = { title: 'New Grouping', childSections: [] };
                this.newGrouping = [{ title: 'New Navigation Menu', type: 'new_groupding', expanded: true }];
                this.newItems = [{ title: 'New Screen', type: 'screen' }];
                $scope.$watch('$ctrl.template', function (model) {
                    _this.modelAsJson = angular.toJson(model.screens, true);
                }, true);
                this.codeRyteEnabled = this.Session.get().agency.has_coderyte;
                this.hasVitasEncounterCharting = this.Session.get().agency.has_vitas_encounter_charting;
            }
            EncounterTypesTemplateController.prototype.selectPage = function (screen, screenSection, $event) {
                var _this = this;
                $event.stopPropagation();
                var s;
                if (screenSection) {
                    if (!screenSection.name) {
                        screenSection.name = screenSection.sections[0].name;
                    }
                    s = this.ChartingTemplateService.getBaseSection(screenSection);
                    if (this.required_fields[s.name]) {
                        this.selected_page = this.required_fields[s.name];
                    }
                    else {
                        var template = this.ChartingTemplateService.getFieldDefinitions();
                        this.selected_page = {
                            title: screenSection.title,
                            sections: template[s.model],
                            cannot_require: s.cannot_require,
                        };
                        this.required_fields[s.name] = this.selected_page;
                        // }
                    }
                    for (var _i = 0, _a = this.selected_page.sections; _i < _a.length; _i++) {
                        var section = _a[_i];
                        for (var _b = 0, _c = section.fields; _b < _c.length; _b++) {
                            var field = _c[_b];
                            if (field.locked || field.requiredFn || field.hiddenFn) {
                                field._locked = true;
                            }
                            if (field.required || field.requiredFn) {
                                field._style = 'required';
                            }
                            else if (field.hidden || field.hiddenFn) {
                                field._style = 'hidden';
                            }
                            else if (field.type === 'select' && !field.options) {
                                this.ChartingTemplateService.setFieldOptions(field, null);
                            }
                        }
                    }
                }
                else {
                    this.selected_page = null;
                }
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/clinical-charts/encounter-types/section-edit-modal.html',
                    controller: 'EncounterTypesSectionEditModalController as $ctrl',
                    fullscreen: true,
                    clickOutsideToClose: false,
                    locals: { section: this.selected_page, screen: screen },
                })
                    .then(function (updates) {
                    if (updates) {
                        var updatedFields = updates.section;
                        var updatedScreen = updates.screen;
                        if (updatedScreen) {
                            if (updatedScreen.active) {
                                for (var _i = 0, _a = updatedScreen.sections; _i < _a.length; _i++) {
                                    var section = _a[_i];
                                    section.active = true;
                                }
                            }
                            angular.extend(screen, updatedScreen);
                        }
                        if (updatedFields) {
                            // console.log(updates, updatedFields);
                            _this.required_fields[s.name] = updatedFields;
                            if (updatedFields.hasRequired) {
                                var sec = {};
                                sec = _this.ChartingTemplateService.getSectionFromScreens(s.name, _this.template.screens);
                                // sec = this.getSectionByName(this.template.screens, s.name, sec);
                                sec.active = true;
                                sec.has_required = true;
                                if (sec.parent_name) {
                                    var p = {};
                                    // p = this.getSectionByName(this.template.screens, sec.parent, p);
                                    p = _this.ChartingTemplateService.getSectionFromScreens(sec.parent_name, _this.template.screens);
                                    p.has_required = true;
                                }
                                if (sec.grandparent_name) {
                                    var gp = {};
                                    // gp = this.getSectionByName(this.template.screens, sec.grandParent, gp);
                                    gp = _this.ChartingTemplateService.getSectionFromScreens(sec.grandparent_name, _this.template.screens);
                                    gp.has_required = true;
                                }
                            }
                        }
                    }
                });
            };
            EncounterTypesTemplateController.prototype.screenEdit = function (screen, $event) {
                $event.stopPropagation();
                if (screen.childSections) {
                    this.$mdDialog
                        .show({
                        targetEvent: $event,
                        templateUrl: '/y/templates/clinical-charts/encounter-types/screen-edit-modal.html',
                        controller: 'EncounterTypesScreenEditModalController as $ctrl',
                        fullscreen: true,
                        clickOutsideToClose: false,
                        locals: { screen: screen },
                    })
                        .then(function (updatedScreen) {
                        if (updatedScreen) {
                            angular.extend(screen, updatedScreen);
                        }
                    });
                }
                else {
                    if (screen.sections && screen.sections.length === 1) {
                        this.selectPage(screen, screen.sections[0], $event);
                    }
                    else {
                        this.selectPage(screen, null, $event);
                    }
                }
            };
            EncounterTypesTemplateController.prototype.save = function () {
                var _this = this;
                this.saving = true;
                if (!this.validateEncounterType()) {
                    this.saving = false;
                    return;
                }
                var saveTemplate = angular.copy(this.template);
                saveTemplate.charting_type = 'custom';
                saveTemplate.screens.forEach(function (screen) {
                    console.log(screen);
                    if (screen.childSections) {
                        screen.childSections.forEach(function (child) {
                            if (child.sections.length === 1) {
                                if (child.sections[0].sections && child.sections[0].sections.length) {
                                    child.name = child.sections[0].sections[0].name;
                                    child.active = child.sections[0].sections[0].active;
                                    child.has_required = child.sections[0].sections[0].has_required;
                                }
                                else {
                                    child.name = child.sections[0].name;
                                    child.active = child.sections[0].active;
                                    child.has_required = child.sections[0].has_required;
                                }
                                delete child.sections;
                            }
                            else {
                                child.name = _this.customScreenName(child.title);
                                var isActive = false;
                                for (var _i = 0, _a = child.sections; _i < _a.length; _i++) {
                                    var sub = _a[_i];
                                    if (sub.sections) {
                                        // child.name = sub.name;
                                        if (sub.sections[0].sections && sub.sections[0].sections.length) {
                                            sub.name = sub.sections[0].sections[0].name;
                                            if (sub.sections[0].sections[0].active) {
                                                isActive = true;
                                            }
                                        }
                                        else {
                                            sub.name = sub.sections[0].name;
                                            if (sub.sections[0].active) {
                                                isActive = true;
                                            }
                                        }
                                        delete sub.sections;
                                    }
                                    else if (sub.active) {
                                        isActive = true;
                                    }
                                }
                                child.active = isActive;
                            }
                        });
                    }
                    else {
                        if (screen.sections.length === 1) {
                            screen.name = screen.sections[0].name;
                            screen.active = screen.sections[0].active || screen.active;
                            delete screen.sections;
                        }
                        else {
                            var tempSections = [];
                            var isActive_1 = false;
                            screen.sections.forEach(function (section) {
                                if (section.sections && section.sections.length === 1) {
                                    section.name = section.sections[0].name;
                                    if (section.sections[0].active) {
                                        isActive_1 = true;
                                    }
                                    delete section.sections;
                                }
                                else if (section.active) {
                                    isActive_1 = true;
                                }
                            });
                            screen.name = _this.customScreenName(screen.title);
                            screen.active = isActive_1;
                        }
                    }
                });
                if (this.required_fields) {
                    var tempRequired = {};
                    var tempLogic = {};
                    var keys = Object.keys(this.required_fields);
                    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                        var name_1 = keys_1[_i];
                        for (var _a = 0, _b = this.required_fields[name_1].sections; _a < _b.length; _a++) {
                            var section = _b[_a];
                            for (var _c = 0, _d = section.fields; _c < _d.length; _c++) {
                                var field = _d[_c];
                                if (field._style === 'required' &&
                                    !field._locked &&
                                    !this.ChartingTemplateService.isAdditionalSection(section.name)) {
                                    if (!tempRequired[name_1]) {
                                        tempRequired[name_1] = [];
                                    }
                                    tempRequired[name_1].push(field.field);
                                }
                                if (!field._locked &&
                                    (field._style === 'required' ||
                                        field._style === 'hidden' ||
                                        field._style === 'common')) {
                                    if (!tempLogic[name_1]) {
                                        tempLogic[name_1] = {};
                                    }
                                    if (field._style) {
                                        tempLogic[name_1][field.field] = {};
                                        switch (field._style) {
                                            case 'hidden':
                                                tempLogic[name_1][field.field].hidden = true;
                                                break;
                                            case 'required':
                                                tempLogic[name_1][field.field].required = true;
                                                break;
                                            case 'common':
                                                tempLogic[name_1][field.field].common = true;
                                                break;
                                        }
                                        if (field.defaultValue) {
                                            tempLogic[name_1][field.field].default = true;
                                            tempLogic[name_1][field.field].defaultValue = field.defaultValue;
                                        }
                                    }
                                    // tempLogic[name][field.field] = {
                                    //   hidden: field._style === 'hidden',
                                    //   required: field._style === 'required',
                                    //   common: field._style === 'common',
                                    // };
                                }
                            }
                        }
                    }
                    saveTemplate.required_fields = tempRequired;
                    saveTemplate.field_logic = tempLogic;
                    // setting patient time fields -  used for validation in backend.
                    if (saveTemplate.required_fields.patient_time) {
                        var pt_required_1 = [];
                        saveTemplate.required_fields.patient_time.forEach(function (field) {
                            if (field === 'in_datetime' && pt_required_1.indexOf('in_date') < 0) {
                                pt_required_1.push('in_date');
                                pt_required_1.push('in_time');
                            }
                            else if (field === 'out_datetime' && pt_required_1.indexOf('out_date') < 0) {
                                pt_required_1.push('out_date');
                                pt_required_1.push('out_time');
                            }
                            else {
                                pt_required_1.push(field);
                            }
                        });
                        saveTemplate.patient_time_fields = { patient_time: pt_required_1 };
                    }
                    else {
                        saveTemplate.patient_time_fields = {};
                    }
                    // setting evv fields -  used for validation in backend.
                    if (saveTemplate.required_fields.electronic_visit_verification) {
                        saveTemplate.evv_fields = {
                            electronic_visit_verification: saveTemplate.required_fields.electronic_visit_verification,
                        };
                    }
                    else {
                        saveTemplate.evv_fields = {};
                    }
                }
                else {
                    saveTemplate.required_fields = {};
                }
                console.log('saveTemplate', saveTemplate);
                saveTemplate.section_template = { screens: saveTemplate.screens };
                if (saveTemplate.id) {
                    this.EncounterTypes.update(saveTemplate)
                        .then(function (et) {
                        _this.ChartingTemplateService.buildETSections(et, et.section_template.screens);
                    })
                        .catch(function (err) {
                        if (err.data && err.data.errors) {
                            _this.errors = err.data.errors;
                            for (var _i = 0, _a = _this.errors; _i < _a.length; _i++) {
                                var e = _a[_i];
                                if (e.indexOf("undefined local variable or method `encounter_type'") >= 0) {
                                    _this.errors.push('name must be unique');
                                }
                            }
                        }
                        else if (err.errors) {
                            _this.errors = err.errors;
                        }
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.EncounterTypes.create(saveTemplate)
                        .then(function (et) {
                        _this.errors = [];
                        _this.template.id = et.id;
                        _this.ChartingTemplateService.buildETSections(et, et.section_template.screens);
                        _this.$state.go('encounter-types.template-edit', { templateId: et.id });
                    })
                        .catch(function (err) {
                        if (err.data && err.data.errors) {
                            _this.errors = err.data.errors;
                            for (var _i = 0, _a = _this.errors; _i < _a.length; _i++) {
                                var e = _a[_i];
                                if (e.indexOf("undefined local variable or method `encounter_type'") >= 0) {
                                    _this.errors.push('name must be unique');
                                }
                            }
                        }
                        else if (err.errors) {
                            _this.errors = err.errors;
                        }
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            EncounterTypesTemplateController.prototype.buildFieldLogic = function () { };
            EncounterTypesTemplateController.prototype.customScreenName = function (name) {
                return ('custom_' +
                    name
                        .toLowerCase()
                        .replace(/ /g, '_')
                        .replace(/([^a-zA-Z0-9_])*/g, ''));
            };
            EncounterTypesTemplateController.prototype.validateEncounterType = function () {
                var _this = this;
                var hasGeneral = false;
                var customScreens = [];
                var allCustomNames = [];
                var duplicateNames = [];
                var name;
                this.invalidName = false;
                this.invalidRole = false;
                this.errors = [];
                for (var _i = 0, _a = this.template.screens; _i < _a.length; _i++) {
                    var screen_1 = _a[_i];
                    screen_1.hasError = false;
                    if (screen_1.childSections) {
                        name = this.customScreenName(screen_1.title);
                        customScreens.push({ name: name, screen: screen_1 });
                        if (allCustomNames.indexOf(name) >= 0) {
                            if (duplicateNames.indexOf(name) < 0) {
                                duplicateNames.push(name);
                            }
                        }
                        else {
                            allCustomNames.push(name);
                        }
                        for (var _b = 0, _c = screen_1.childSections; _b < _c.length; _b++) {
                            var child = _c[_b];
                            child.hasError = false;
                            if (child.sections && child.sections.length > 1) {
                                name = this.customScreenName(child.title);
                                customScreens.push({ name: name, screen: child });
                                if (allCustomNames.indexOf(name) >= 0) {
                                    if (duplicateNames.indexOf(name) < 0) {
                                        duplicateNames.push(name);
                                    }
                                }
                                else {
                                    allCustomNames.push(name);
                                }
                                for (var _d = 0, _e = child.sections; _d < _e.length; _d++) {
                                    var sub = _e[_d];
                                    if (sub.name === 'general') {
                                        hasGeneral = true;
                                    }
                                }
                            }
                            else if (child.name === 'general') {
                                hasGeneral = true;
                            }
                        }
                    }
                    else {
                        screen_1.hasError = false;
                        if (screen_1.sections.length >= 1) {
                            name = this.customScreenName(screen_1.title);
                            customScreens.push({ name: name, screen: screen_1 });
                            if (allCustomNames.indexOf(name) >= 0) {
                                if (duplicateNames.indexOf(name) < 0) {
                                    duplicateNames.push(name);
                                }
                            }
                            else {
                                allCustomNames.push(name);
                            }
                            for (var _f = 0, _g = screen_1.sections; _f < _g.length; _f++) {
                                var sub = _g[_f];
                                if (sub.name === 'general') {
                                    hasGeneral = true;
                                }
                            }
                        }
                        else if (screen_1.name === 'general') {
                            hasGeneral = true;
                        }
                    }
                }
                if (this.template.name) {
                    var nameConflict = this.allEncounterTypes.filter(function (et) {
                        return (et.name === _this.template.name &&
                            et.id !== _this.template.id &&
                            et.version === _this.template.version);
                    })[0];
                    if (nameConflict) {
                        this.errors.push('Encounter Type Name must be unique.');
                        this.invalidName = true;
                    }
                }
                else {
                    this.errors.push('You must enter a name for the Encounter Type');
                    this.invalidName = true;
                }
                if (!this.template.role_ids || !this.template.role_ids.length) {
                    this.invalidRole = true;
                    this.errors.push('You must select at least one role');
                }
                if (!this.template.screens || !this.template.screens.length) {
                    this.errors.push('You must have at least one screen');
                }
                if (!hasGeneral) {
                    this.errors.push('All Encounter Types must contain a General Details section');
                }
                if (duplicateNames.length) {
                    this.errors.push('Custom Screen names must be unique after all spaces and special characters are removed.');
                }
                var _loop_1 = function (dupe) {
                    var dupeScreens = customScreens.filter(function (item) {
                        return item.name === dupe;
                    });
                    var invalidScreenNames = [];
                    for (var _i = 0, dupeScreens_1 = dupeScreens; _i < dupeScreens_1.length; _i++) {
                        var screen_2 = dupeScreens_1[_i];
                        screen_2.screen.hasError = true;
                        invalidScreenNames.push(screen_2.screen.title);
                    }
                    this_1.errors.push('Screen name conflict between: ' + invalidScreenNames.join('; '));
                };
                var this_1 = this;
                for (var _h = 0, duplicateNames_1 = duplicateNames; _h < duplicateNames_1.length; _h++) {
                    var dupe = duplicateNames_1[_h];
                    _loop_1(dupe);
                }
                return !this.errors.length;
            };
            EncounterTypesTemplateController.prototype.buildBaseTemplate = function () {
                this.encounterScreens = this.ChartingTemplateService.getScreens('encounter_charting').map(function (screen) {
                    if (screen.childSections) {
                        screen.childSections = screen.childSections.filter(function (field) { return !field.deprecated; });
                    }
                    return screen;
                });
                this.optimized_template = this.ChartingTemplateService.getFieldDefinitions();
                this.base_template = [];
                // this.optimized_sections.forEach( (section) => {
                //   let page = { title: section.title, sections: [] };
                //   this.optimized_template[section.model].forEach( (subsection) => {
                //     subsection.model = section.model;
                //     subsection.name = section.name;
                //     page.sections.push(subsection);
                //   });
                //   this.base_template.push(page);
                // });
                // this.original_template = angular.copy(this.base_template);
                this.baseSections = [];
                var base_encounter = this.ChartingTemplateService.getScreens('base_encounter');
                this.baseSections = angular.copy(this.encounterScreens);
                for (var _i = 0, _a = this.baseSections; _i < _a.length; _i++) {
                    var section = _a[_i];
                    section.expanded = true;
                }
                var baseScreen;
                var baseTitle;
                if (this.encounterType) {
                    this.template = angular.copy(this.encounterType);
                    this.template.screens = [];
                    if (this.encounterType.section_template && this.encounterType.section_template.screens) {
                        for (var _b = 0, _c = this.encounterType.section_template.screens; _b < _c.length; _b++) {
                            var screen_3 = _c[_b];
                            // if (screen.sections) {
                            //   let newSections = [];
                            //   for (let s of screen.sections {
                            //   });
                            // } else
                            var isActive = false;
                            screen_3.expanded = true;
                            if (screen_3.childSections) {
                                var children = [];
                                for (var _d = 0, _e = screen_3.childSections; _d < _e.length; _d++) {
                                    var child = _e[_d];
                                    if (child.sections) {
                                        for (var _f = 0, _g = child.sections; _f < _g.length; _f++) {
                                            var sub = _g[_f];
                                            baseScreen = this.ChartingTemplateService.getSectionFromScreens(sub.name, this.baseSections, 'name');
                                            if (baseScreen) {
                                                baseScreen.hidden = true;
                                            }
                                            if (sub.active) {
                                                child.active = true;
                                                child.has_required = true;
                                            }
                                        }
                                        children.push(child);
                                    }
                                    else {
                                        baseScreen = this.ChartingTemplateService.getSectionFromScreens(child.name, this.baseSections, 'name');
                                        children.push({
                                            title: child.title,
                                            sections: [{ name: child.name, title: child.title, active: child.active }],
                                            active: child.active,
                                            has_required: child.has_required,
                                        });
                                    }
                                }
                                screen_3.childSections = children;
                                this.template.screens.push(screen_3);
                            }
                            else if (!screen_3.sections) {
                                baseScreen = this.ChartingTemplateService.getSectionFromScreens(screen_3.name, this.baseSections, 'name');
                                baseTitle = screen_3.title;
                                if (baseScreen) {
                                    baseScreen.hidden = true;
                                    baseTitle = baseScreen.title;
                                }
                                this.template.screens.push({
                                    title: screen_3.title,
                                    sections: [{ name: screen_3.name, title: baseTitle, active: screen_3.active }],
                                    active: screen_3.active,
                                    expanded: true,
                                });
                            }
                            else {
                                for (var _h = 0, _j = screen_3.sections; _h < _j.length; _h++) {
                                    var section = _j[_h];
                                    baseScreen = this.ChartingTemplateService.getSectionFromScreens(section.name, this.baseSections, 'name');
                                    if (baseScreen) {
                                        baseScreen.hidden = true;
                                    }
                                    if (section.active) {
                                        screen_3.active = true;
                                    }
                                }
                                this.template.screens.push(screen_3);
                            }
                        }
                    }
                    this.required_fields = {};
                    var cleanup = [];
                    var template = this.ChartingTemplateService.getFieldDefinitions();
                    try {
                        if (this.encounterType.field_logic) {
                            for (var name_2 in this.encounterType.field_logic) {
                                if (this.encounterType.field_logic.hasOwnProperty(name_2)) {
                                    var section = this.ChartingTemplateService.sectionSearchBy(name_2, 'name', 'base_encounter');
                                    if (!section) {
                                        section = this.ChartingTemplateService.sectionSearchBy(name_2, 'model', 'base_encounter');
                                        cleanup.push({ old: section.model, new: section.name });
                                    }
                                    this.required_fields[section.name] = {
                                        title: section.title,
                                        sections: angular.copy(template[section.model]),
                                        cannot_require: section.cannot_require,
                                    };
                                    // if (this.encounterType.field_logic[name]) {
                                    // for (let s of this.required_fields[section.name].sections) {
                                    for (var f in this.encounterType.field_logic[name_2]) {
                                        if (this.encounterType.field_logic[name_2].hasOwnProperty(f)) {
                                            // for (let field of section.fields) {
                                            var field = this.fieldSearch(f, this.required_fields[section.name].sections);
                                            angular.extend(field, this.encounterType.field_logic[name_2][f]);
                                            if (field.required) {
                                                field._style = 'required';
                                            }
                                            else if (field.hidden) {
                                                field._style = 'hidden';
                                            }
                                            else if (field.common) {
                                                field._style = 'common';
                                            }
                                            // if (this.encounterType.field_logic[name][f].required) {
                                            //   field._style = 'required';
                                            // } else if (this.encounterType.field_logic[name][f].hidden) {
                                            //   field._style = 'hidden';
                                            // }
                                            if (!field._locked && field.type === 'select') {
                                                // this.$timeout(() => {
                                                //   this.ChartingTemplateService.setFieldOptions(field, null);
                                                // }, 1000);
                                                this.ChartingTemplateService.setFieldOptions(field, null);
                                            }
                                        }
                                        // }
                                    }
                                    // }
                                    // }
                                }
                            }
                        }
                        for (var _k = 0, cleanup_1 = cleanup; _k < cleanup_1.length; _k++) {
                            var lookup = cleanup_1[_k];
                            if (lookup.old !== lookup.new) {
                                var cpy = angular.copy(this.encounterType.field_logic[lookup.old]);
                                this.encounterType.field_logic[lookup.new] = cpy;
                                delete this.encounterType.field_logic[lookup.old];
                            }
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                    finally {
                        console.log('build end: ', angular.copy(this.template));
                    }
                }
            };
            EncounterTypesTemplateController.prototype.addPage = function () { };
            EncounterTypesTemplateController.prototype.addNewScreen = function () {
                this.template.screens.push({ title: 'New Screen', sections: [], expanded: true });
            };
            EncounterTypesTemplateController.prototype.addNewNavigationMenu = function () {
                this.template.screens.push({
                    title: 'New Navigation Menu',
                    childSections: [],
                    expanded: true,
                });
            };
            EncounterTypesTemplateController.prototype.fieldSearch = function (fieldName, sections) {
                for (var _i = 0, sections_1 = sections; _i < sections_1.length; _i++) {
                    var s = sections_1[_i];
                    // if (s.label === sectionName) {
                    for (var _a = 0, _b = s.fields; _a < _b.length; _a++) {
                        var f = _b[_a];
                        if (f.field === fieldName) {
                            return f;
                        }
                    }
                    // }
                }
            };
            EncounterTypesTemplateController.prototype.dropScreen = function (index, item, type) {
                this.cleanSection(item);
                // console.log('drop', type);
                if (type === 'new_grouping') {
                    return { title: 'New Navigation Menu', childSections: [], expanded: true };
                }
                else if (type === 'new_screen') {
                    return { title: 'New Screen', sections: [], expanded: true };
                }
                else if (item.childSections) {
                    var temp_1 = [];
                    item.childSections.forEach(function (child) {
                        if (!child.sections) {
                            temp_1.push({ title: child.title, sections: [{ name: child.name, title: child.title }] });
                        }
                        else {
                            temp_1.push(child);
                        }
                    });
                    return { title: item.title, name: item.name, childSections: temp_1, expanded: true };
                }
                else {
                    if (!item.sections) {
                        item.sections = [{ name: item.name, title: item.title }]; // [angular.copy(item)];
                    }
                    else {
                        var temp_2 = [];
                        item.sections.forEach(function (section) {
                            var c = { name: section.name, title: section.title };
                            temp_2.push({ title: section.title, name: section.name, sections: [c] });
                        });
                        item.sections = temp_2;
                    }
                    if (item.sections.length === 1) {
                        item.title = item.sections[0].title;
                    }
                    return {
                        title: item.title,
                        name: item.name,
                        sections: angular.copy(item.sections),
                        expanded: true,
                    };
                }
            };
            EncounterTypesTemplateController.prototype.addScreen = function (list, item, index, parent) {
                // console.log('here');
                item.hidden = true;
                item.onTemplate = true;
                if (item.sections) {
                    for (var _i = 0, _a = item.sections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        s.hidden = true;
                        s.onTemplate = true;
                    }
                }
                if (item.childSections) {
                    for (var _b = 0, _c = item.childSections; _b < _c.length; _b++) {
                        var c = _c[_b];
                        c.hidden = true;
                        c.onTemplate = true;
                    }
                }
            };
            EncounterTypesTemplateController.prototype.cleanSection = function (section) {
                var section_keep = [
                    'name',
                    'title',
                    'sections',
                    'childSections',
                    'expanded',
                    'active',
                    'has_required',
                    'cannot_require',
                    'additional_section',
                ];
                var keys = Object.keys(section);
                for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
                    var key = keys_2[_i];
                    if (section_keep.indexOf(key) < 0) {
                        delete section[key];
                    }
                }
                // delete section.icon;
                // delete section.templateUrl;
                // delete section.controller;
                // delete section.rootNode;
                // delete section.ordinal;
                // delete section.common;
                // delete section.noPermissions;
                // delete section.singleNode;
                // delete section.model;
            };
            EncounterTypesTemplateController.prototype.moveScreen = function (list, item, index, parent, caller) {
                // console.log('move', item, list, caller);
                list.splice(index, 1);
            };
            EncounterTypesTemplateController.prototype.checkEmpty = function (obj, arrayName) { };
            EncounterTypesTemplateController.prototype.deleteSection = function (list, index) {
                list.splice(index, 1);
            };
            EncounterTypesTemplateController.prototype.deleteScreen = function (callback) {
                if (!callback) {
                    return;
                }
                console.log('delete start: ', angular.copy(this.template));
                var src = callback();
                var obj = src.list.splice(src.idx, 1)[0];
                var sec = {};
                console.log('src', angular.copy(src));
                console.log('obj', angular.copy(obj));
                console.log(this.required_fields);
                if (obj.sections) {
                    for (var _i = 0, _a = obj.sections; _i < _a.length; _i++) {
                        var sub = _a[_i];
                        sec = {};
                        sec = this.getSectionByName(this.baseSections, sub.name, sec);
                        sec.hidden = false;
                        delete this.required_fields[sub.name];
                    }
                }
                else if (obj.childSections) {
                    for (var _b = 0, _c = obj.childSections; _b < _c.length; _b++) {
                        var child = _c[_b];
                        if (child.sections) {
                            for (var _d = 0, _e = child.sections; _d < _e.length; _d++) {
                                var childSec = _e[_d];
                                sec = {};
                                sec = this.getSectionByName(this.baseSections, childSec.name, sec);
                                sec.hidden = false;
                                delete this.required_fields[childSec.name];
                            }
                        }
                    }
                }
                else if (obj.name) {
                    var sec_1 = {};
                    sec_1 = this.getSectionByName(this.baseSections, obj.name, sec_1);
                    sec_1.hidden = false;
                    delete this.required_fields[obj.name];
                }
                this.toggleParentSections(this.baseSections);
                console.log('delete end: ', angular.copy(this.template));
            };
            EncounterTypesTemplateController.prototype.dropInserted = function (index, item, type) {
                // console.log('inserted');
                // console.log(this.template.pages);
            };
            EncounterTypesTemplateController.prototype.sectionMoved = function (page, idx) {
                page.sections.splice(idx, 1);
            };
            EncounterTypesTemplateController.prototype.toggleSectionFields = function (page) {
                page._showSectionFields = !page._showSectionFields;
                page.sections.forEach(function (section) {
                    section._showFields = page._showSectionFields;
                });
            };
            EncounterTypesTemplateController.prototype.screenTypeStyle = function (screen) {
                if (screen.sections && screen.sections.length <= 1) {
                    return 'simple-screen';
                }
                else if (screen.sections && screen.sections.length > 1) {
                    return 'complex-screen';
                }
                else if (screen.childSections) {
                    return 'nav-menu';
                }
            };
            EncounterTypesTemplateController.prototype.getSectionByName = function (sectionList, name, retSection) {
                if (!name) {
                    return;
                }
                for (var _i = 0, sectionList_1 = sectionList; _i < sectionList_1.length; _i++) {
                    var section = sectionList_1[_i];
                    if (section.name === name) {
                        retSection = section;
                    }
                    if (section.childSections) {
                        for (var _a = 0, _b = section.childSections; _a < _b.length; _a++) {
                            var child = _b[_a];
                            retSection = this.getSectionByName([child], name, retSection);
                        }
                    }
                    else if (section.sections) {
                        retSection = this.getSectionByName(section.sections, name, retSection);
                    }
                }
                return retSection;
            };
            EncounterTypesTemplateController.prototype.toggleParentSections = function (list) {
                for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
                    var section = list_1[_i];
                    if (section.sections || section.childSections) {
                        if (section.sections) {
                            var sectionHidden = true;
                            for (var _a = 0, _b = section.sections; _a < _b.length; _a++) {
                                var s = _b[_a];
                                if (!s.hidden) {
                                    sectionHidden = false;
                                }
                            }
                            section.hidden = sectionHidden;
                        }
                        else {
                            var menuHidden = true;
                            for (var _c = 0, _d = section.childSections; _c < _d.length; _c++) {
                                var child = _d[_c];
                                var childHidden = true;
                                if (child.sections) {
                                    for (var _e = 0, _f = child.sections; _e < _f.length; _e++) {
                                        var childSection = _f[_e];
                                        if (!childSection.hidden) {
                                            menuHidden = false;
                                            childHidden = false;
                                        }
                                    }
                                }
                                else if (!child.hidden) {
                                    childHidden = false;
                                    menuHidden = false;
                                }
                            }
                            section.hidden = menuHidden;
                        }
                    }
                }
            };
            EncounterTypesTemplateController.prototype.addToBottom = function (callback) {
                if (!callback) {
                    return;
                }
                var src = callback();
                var obj;
                if (src.type === 'palette') {
                    obj = angular.copy(src.list[src.idx]);
                    src.list[src.idx].hidden = true;
                }
                else {
                    obj = src.list.splice(src.idx, 1)[0];
                }
                var newScreen = this.dropScreen(-1, obj, null);
                this.template.screens.push(newScreen);
                var bottom = angular.element(document.getElementById('new-screen-container'));
                var side = angular.element(document.getElementById('et-sidenav'));
                side[0].scrollTop = side[0].scrollHeight;
            };
            EncounterTypesTemplateController.prototype.confirmActivateVersion = function ($event) {
                var _this = this;
                this.EncounterTypes.activateModal(this.template, $event).then(function () {
                    _this.template.active = true;
                });
            };
            EncounterTypesTemplateController.$inject = [
                '$scope',
                'cache',
                'ChartingTemplateService',
                'EncounterTypes',
                'EncounterType',
                'options',
                'encounterType',
                '$state',
                '$mdDialog',
                '$timeout',
                'SessionService',
            ];
            return EncounterTypesTemplateController;
        }());
        charting.EncounterTypesTemplateController = EncounterTypesTemplateController;
        angular
            .module('consolo')
            .controller('EncounterTypesTemplateController', EncounterTypesTemplateController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesViewModalController = /** @class */ (function () {
            function EncounterTypesViewModalController(ChartingTemplateService, encounter_template, options, $mdDialog) {
                var _this = this;
                this.ChartingTemplateService = ChartingTemplateService;
                this.encounter_template = encounter_template;
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.templateSections = this.ChartingTemplateService.getFieldDefinitions();
                this.base_roles = this.options.get('base_roles');
                var tempFiltered = {};
                var template = 'tablet_optimized';
                if (this.encounter_template.charting_type === 'custom') {
                    template = 'encounter_charting';
                }
                Object.keys(this.encounter_template.required_fields).forEach(function (key) {
                    var sectionMeta = _this.ChartingTemplateService.sectionSearchBy(key, 'name', template);
                    tempFiltered[key] = { title: sectionMeta.title, fields: [] };
                    _this.encounter_template.required_fields[key].forEach(function (req_field) {
                        _this.templateSections[sectionMeta.model].forEach(function (subSection) {
                            subSection.fields.forEach(function (field) {
                                if (req_field === field.field) {
                                    tempFiltered[key].fields.push({
                                        label: field.label,
                                        required: true,
                                        service_line_tag: field.service_line_tag ? true : false,
                                    });
                                }
                            });
                        });
                    });
                });
                this.viewList = tempFiltered;
            }
            EncounterTypesViewModalController.$inject = [
                'ChartingTemplateService',
                'encounter_template',
                'options',
                '$mdDialog',
            ];
            return EncounterTypesViewModalController;
        }());
        controllers.EncounterTypesViewModalController = EncounterTypesViewModalController;
        angular
            .module('consolo')
            .controller('EncounterTypesViewModalController', EncounterTypesViewModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var EncounterTypesService = /** @class */ (function () {
            function EncounterTypesService($http, logger, cache, EncounterType, $q, $mdDialog) {
                this.$http = $http;
                this.logger = logger;
                this.cache = cache;
                this.EncounterType = EncounterType;
                this.$q = $q;
                this.$mdDialog = $mdDialog;
                this.etEndpoint = '/api/v1/encounter_types';
            }
            EncounterTypesService.prototype.getAll = function (active) {
                var _this = this;
                var encounterTypesPresent = this.$q.defer();
                if (this.encounterTypes) {
                    encounterTypesPresent.resolve(this.encounterTypes);
                }
                else {
                    this.cache(this.EncounterType, 'encounter_types', { maxAge: 0 })
                        .query({
                        active: active,
                    })
                        .$promise.then(function (data) {
                        _this.encounterTypes = data.filter(function (et) {
                            var _a;
                            return (
                            // Remove template options with a SIA/braden section or an instruments section with SIA/braden as a child section
                            !((_a = et.section_template['screens']) === null || _a === void 0 ? void 0 : _a.some(function (screen) { return screen.name === 'skin_integrity_assessment' ||
                                (screen.name === 'instruments' &&
                                    screen.childSections.some(function (child) { return child.name === 'skin_integrity_assessment'; })); })));
                        });
                        encounterTypesPresent.resolve(_this.encounterTypes);
                    });
                }
                return encounterTypesPresent.promise;
            };
            EncounterTypesService.prototype.select = function (active, role_ids, charting_type) {
                return this.$http({
                    url: this.etEndpoint,
                    method: 'GET',
                    params: { active: active, role_ids: role_ids, charting_type: charting_type },
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                }).then(function (data) {
                    return data.data;
                });
            };
            EncounterTypesService.prototype.get = function (id) {
                return this.$http({
                    url: this.etEndpoint + '/' + id.toString(),
                    method: 'GET',
                }).then(function (data) {
                    return data.data;
                });
            };
            EncounterTypesService.prototype.create = function (encounter_type) {
                var _this = this;
                return this.$http.post(this.etEndpoint, encounter_type).then(function (data) {
                    _this.logger.success('Encounter Type: ' + encounter_type.name + ' created');
                    _this.cache(_this.EncounterType, 'encounter_types', { force: true }).query();
                    return data.data;
                }, function (err) {
                    _this.logger.error('Error creating encounter type: ' + err);
                    console.log(err);
                    throw err;
                });
            };
            EncounterTypesService.prototype.update = function (encounter_type, discontinue) {
                var _this = this;
                return this.$http.patch(this.etEndpoint + '/' + encounter_type.id, encounter_type).then(function (data) {
                    var message = discontinue ? 'discontinued' : 'updated';
                    _this.logger.success('Encounter Type: ' + encounter_type.name + ' ' + message);
                    _this.cache(_this.EncounterType, 'encounter_types', { force: true }).query();
                    return data.data;
                }, function (err) {
                    console.log(err);
                    throw err;
                });
            };
            EncounterTypesService.prototype.delete = function (encounter_type) {
                var _this = this;
                return this.$http.delete(this.etEndpoint + '/' + encounter_type.id).then(function (data) {
                    _this.logger.success('Encounter Type: ' + encounter_type.name + ' deleted');
                    return data.data;
                }, function (err) {
                    console.log(err);
                });
            };
            EncounterTypesService.prototype.activate = function (encounter_type) {
                var _this = this;
                return this.$http.get(this.etEndpoint + "/" + encounter_type.id + "/activate").then(function (data) {
                    _this.logger.success('Encounter Type: ' +
                        encounter_type.name +
                        ' version ' +
                        encounter_type.version +
                        ' activated');
                }, function (err) {
                    console.log(err);
                });
            };
            EncounterTypesService.prototype.activateModal = function (et, $event) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Activate Version ' + et.version + '?')
                    .textContent('Activating Version ' +
                    et.version +
                    ' will discontinue the previous version and it will no longer be usable on new charts.  Would you activate this version?')
                    .targetEvent($event)
                    .ok('Activate Version')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.activate(et);
                });
            };
            EncounterTypesService.$inject = [
                '$http',
                'logger',
                'cache',
                'EncounterType',
                '$q',
                '$mdDialog',
            ];
            return EncounterTypesService;
        }());
        charting.EncounterTypesService = EncounterTypesService;
        angular.module('consolo').service('EncounterTypes', EncounterTypesService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var EncounterTypesStates = /** @class */ (function () {
            function EncounterTypesStates($stateProvider) {
                $stateProvider
                    .state('encounter-types', {
                    url: '/encounter-types',
                    data: { hideSidebar: true },
                    views: {
                        'main-content': {
                            templateUrl: '/y/templates/clinical-charts/encounter-types/encounter-types-index.html',
                            controller: 'EncounterTypesIndexController as $ctrl',
                        },
                    },
                })
                    .state('encounter-types.template-new', {
                    url: '/template/new',
                    data: { hideSidebar: true, title: 'Encounter Types' },
                    params: { cloneId: null, updateId: null },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/clinical-charts/encounter-types/encounter-types-template-edit.html',
                            controller: 'EncounterTypesTemplateController as $ctrl',
                        },
                    },
                    resolve: {
                        encounterType: [
                            '$stateParams',
                            'EncounterTypes',
                            function ($stateParams, EncounterTypes) {
                                if ($stateParams.cloneId) {
                                    return EncounterTypes.get($stateParams.cloneId).then(function (et) {
                                        et.name = 'Copy of ' + et.name;
                                        et.id = null;
                                        et.version = 1;
                                        et.active = false;
                                        et.parent_id = null;
                                        et.discontinue_date = null;
                                        et.editable = true;
                                        et.discontinueable = false;
                                        return et;
                                    });
                                }
                                else if ($stateParams.updateId) {
                                    return EncounterTypes.get($stateParams.updateId).then(function (et) {
                                        et.id = null;
                                        et.version += 1;
                                        et.active = false;
                                        et.discontinue_date = null;
                                        et.editable = true;
                                        et.discontinueable = false;
                                        return et;
                                    });
                                }
                                else {
                                    return {
                                        required_fields: {},
                                        patient_time_fields: {},
                                        evv_fields: {},
                                        template: {},
                                        version: 1,
                                        editable: true,
                                        discontinueable: false,
                                    };
                                }
                            },
                        ],
                    },
                })
                    .state('encounter-types.template-edit', {
                    url: '/template/:templateId/edit',
                    data: { hideSidebar: true, title: 'Encounter Types' },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/clinical-charts/encounter-types/encounter-types-template-edit.html',
                            controller: 'EncounterTypesTemplateController as $ctrl',
                        },
                    },
                    resolve: {
                        encounterType: [
                            '$stateParams',
                            'EncounterTypes',
                            'ChartingTemplateService',
                            function ($stateParams, EncounterTypes, ChartingTemplateService) {
                                return EncounterTypes.get($stateParams.templateId).then(function (encounterType) {
                                    if (encounterType.charting_type === 'custom' || encounterType.charting_type === 'encounter_charting') {
                                        var screens = encounterType.section_template.screens;
                                        for (var _i = 0, screens_1 = screens; _i < screens_1.length; _i++) {
                                            var s = screens_1[_i];
                                            ChartingTemplateService.setDefaultSectionTitle(s);
                                        }
                                    }
                                    return encounterType;
                                });
                            },
                        ],
                    },
                });
            }
            EncounterTypesStates.$inject = ['$stateProvider'];
            return EncounterTypesStates;
        }());
        angular.module('consolo').config(EncounterTypesStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var FieldFilterModalController = /** @class */ (function () {
            function FieldFilterModalController($scope) {
                this.$scope = $scope;
            }
            FieldFilterModalController.$inject = ['$scope'];
            return FieldFilterModalController;
        }());
        controllers.FieldFilterModalController = FieldFilterModalController;
        angular
            .module('consolo')
            .controller('FieldFilterModalController', FieldFilterModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var EncounterTypesScreenEditModalController = /** @class */ (function () {
            function EncounterTypesScreenEditModalController($scope, $mdDialog, EncounterTypesSidebarService, options, HijackFindService, $timeout, screen) {
                var _this = this;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.EncounterTypesSidebarService = EncounterTypesSidebarService;
                this.options = options;
                this.HijackFindService = HijackFindService;
                this.$timeout = $timeout;
                this.screen = screen;
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                });
                for (var _i = 0, _a = this.EncounterTypesSidebarService.allSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.tempActive = s.active;
                }
                this.screenCopy = angular.copy(this.screen);
                if (this.screenCopy.childSections && this.screenCopy.childSections.length) {
                    for (var _b = 0, _c = this.screenCopy.childSections; _b < _c.length; _b++) {
                        var child = _c[_b];
                        var has_required = void 0;
                        if (child.sections) {
                            for (var _d = 0, _e = child.sections; _d < _e.length; _d++) {
                                var sub = _e[_d];
                                if (sub.has_required) {
                                    has_required = true;
                                }
                            }
                        }
                        child.has_required = has_required || child.has_required;
                    }
                }
            }
            EncounterTypesScreenEditModalController.prototype.close = function (p) {
                this.$mdDialog.hide(p);
            };
            EncounterTypesScreenEditModalController.$inject = [
                '$scope',
                '$mdDialog',
                'EncounterTypesSidebarService',
                'options',
                'hijackFindService',
                '$timeout',
                'screen',
            ];
            return EncounterTypesScreenEditModalController;
        }());
        charting.EncounterTypesScreenEditModalController = EncounterTypesScreenEditModalController;
        angular
            .module('consolo')
            .controller('EncounterTypesScreenEditModalController', EncounterTypesScreenEditModalController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterTypesSectionEditModalController = /** @class */ (function () {
            function EncounterTypesSectionEditModalController($scope, $mdDialog, EncounterTypesSidebarService, options, HijackFindService, $timeout, ChartingTemplateService, section, screen) {
                var _this = this;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.EncounterTypesSidebarService = EncounterTypesSidebarService;
                this.options = options;
                this.HijackFindService = HijackFindService;
                this.$timeout = $timeout;
                this.ChartingTemplateService = ChartingTemplateService;
                this.section = section;
                this.screen = screen;
                this.screenEdit = false;
                this.fieldEdit = false;
                this.options.get('base_roles').$promise.then(function (data) {
                    _this.base_roles = data;
                });
                for (var _i = 0, _a = this.EncounterTypesSidebarService.allSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.tempActive = s.active;
                }
                if (section) {
                    this.sectionCopy = angular.copy(this.section);
                    this.fieldEdit = !this.section.cannot_require;
                    this.title = 'Fields: ' + this.sectionCopy.title;
                    for (var _b = 0, _c = this.sectionCopy.sections; _b < _c.length; _b++) {
                        var section_1 = _c[_b];
                        for (var _d = 0, _e = section_1.fields; _d < _e.length; _d++) {
                            var field = _e[_d];
                            if (field.defaultValueFn) {
                                field.patientMatches = [];
                                field.modelMatches = [];
                                var fn = field.defaultValueFn.toString();
                                var rePatient = /patient\.(\w*)/g;
                                var reModel = /model\.(\w*)/g;
                                var matches = fn.match(rePatient);
                                if (!matches) {
                                    matches = [];
                                }
                                for (var _f = 0, matches_1 = matches; _f < matches_1.length; _f++) {
                                    var m = matches_1[_f];
                                    m = m.replace('patient.', '').replace(/_/g, ' ');
                                    field.patientMatches.push(m);
                                }
                                matches = fn.match(reModel);
                                if (!matches) {
                                    matches = [];
                                }
                                for (var _g = 0, matches_2 = matches; _g < matches_2.length; _g++) {
                                    var m = matches_2[_g];
                                    m = m.replace('patient.', '').replace('_', ' ');
                                    field.modelMatches.push(m);
                                }
                            }
                            else if (field.optionsType && !field.options && (field.type === 'select' || field.type === 'multiselect')) {
                                this.ChartingTemplateService.setFieldOptions(field, null);
                            }
                        }
                    }
                }
                if (screen) {
                    this.screenCopy = angular.copy(this.screen);
                    this.screenEdit = true;
                    this.title = 'Screen: ' + this.screenCopy.title;
                    this.updateDisplayText();
                }
            }
            EncounterTypesSectionEditModalController.prototype.styleChanged = function (field) {
                if (field._locked) {
                    return;
                }
                this.$timeout(function () {
                    if (field._style === 'required') {
                        // field.required = true;
                        if (field.defaultValue) {
                            field.default = true;
                        }
                        else {
                            delete field.default;
                            delete field.defaultValue;
                        }
                    }
                    else if (field.defaultValue) {
                        delete field.default;
                        delete field.defaultValue;
                    }
                });
            };
            EncounterTypesSectionEditModalController.prototype.updateSection = function () {
                if (this.sectionCopy) {
                    this.sectionCopy.active = false;
                    for (var _i = 0, _a = this.sectionCopy.sections; _i < _a.length; _i++) {
                        var sub = _a[_i];
                        for (var _b = 0, _c = sub.fields; _b < _c.length; _b++) {
                            var field = _c[_b];
                            if (field._style === 'required' && !field._locked) {
                                this.sectionCopy.hasRequired = true;
                            }
                        }
                    }
                }
                this.close({ screen: this.screenCopy, section: this.sectionCopy });
            };
            EncounterTypesSectionEditModalController.prototype.updateDisplayText = function () {
                var _this = this;
                this.$timeout(function () {
                    var temp = '';
                    if (_this.screenCopy.required_only) {
                        temp += 'required_only_';
                        _this.screenCopy.his_required_only = false;
                    }
                    if (_this.screenCopy.common_only) {
                        temp += 'common_only_';
                        _this.screenCopy.his_required_only = false;
                    }
                    if (_this.screenCopy.his_only) {
                        temp += 'his_only_';
                        _this.screenCopy.his_required_only = false;
                    }
                    if (_this.screenCopy.his_required_only) {
                        temp += 'required_his_only_';
                        _this.screenCopy.his_only = false;
                        _this.screenCopy.required_only = false;
                    }
                    if (_this.screenCopy.his_all) {
                        temp += 'his_all_';
                        // this.screen.required_
                        _this.screenCopy.his_only = false;
                    }
                    if (_this.screenCopy.required_all) {
                        temp += 'required_all_';
                        _this.screenCopy.his_only = false;
                        _this.screenCopy.required_only = false;
                    }
                    switch (temp) {
                        case 'required_only_':
                            _this.display_text =
                                'Only Required fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'common_only_':
                            _this.display_text =
                                'Only Common fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'his_only_':
                            _this.display_text =
                                'Only HIS fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'required_his_only_':
                            _this.display_text = 'Only Required HIS fields will be displayed on this screen.';
                            break;
                        case 'his_all_':
                            _this.display_text = 'All HIS fields on the chart will be displayed on this screen.';
                            break;
                        case 'required_all_':
                            _this.display_text =
                                'All Required fields on the chart will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'required_only_common_only_':
                            _this.display_text =
                                'Only Required fields and Common fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'required_only_common_only_his_only_':
                            _this.display_text =
                                'Only Required fields, Common fields, and HIS fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'required_only_his_only_':
                            _this.display_text =
                                'Only Required fields & HIS fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case 'his_all_required_all_':
                            _this.display_text =
                                'All Required fields and All HIS fields on the chart will be displayed on this screen.';
                            break;
                        case 'common_only_his_only_':
                            _this.display_text =
                                'Only Common fields & HIS fields will be displayed on this screen. All other fields will be hidden.';
                            break;
                        case '':
                            _this.display_text = 'All section fields will be displayed on this screen.';
                            break;
                        default:
                            _this.display_text = 'Invalid combo?';
                            break;
                    }
                });
            };
            EncounterTypesSectionEditModalController.prototype.close = function (p) {
                this.$mdDialog.hide(p);
            };
            EncounterTypesSectionEditModalController.$inject = [
                '$scope',
                '$mdDialog',
                'EncounterTypesSidebarService',
                'options',
                'hijackFindService',
                '$timeout',
                'ChartingTemplateService',
                'section',
                'screen',
            ];
            return EncounterTypesSectionEditModalController;
        }());
        controllers.EncounterTypesSectionEditModalController = EncounterTypesSectionEditModalController;
        angular
            .module('consolo')
            .controller('EncounterTypesSectionEditModalController', EncounterTypesSectionEditModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var EncounterSelectorModalController = /** @class */ (function () {
            function EncounterSelectorModalController(options, Session, $q, $state, $filter, ClinicalChartEntry, $mdDialog, cache, EncounterType, CarePlanProblemEntry, ChartingTemplateService, currentPatient, Patient, $timeout, clinical_trip_stop_point_id, logger, $window, $scope, patientService) {
                var _this = this;
                this.options = options;
                this.Session = Session;
                this.$q = $q;
                this.$state = $state;
                this.$filter = $filter;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.$mdDialog = $mdDialog;
                this.cache = cache;
                this.EncounterType = EncounterType;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.ChartingTemplateService = ChartingTemplateService;
                this.currentPatient = currentPatient;
                this.Patient = Patient;
                this.$timeout = $timeout;
                this.clinical_trip_stop_point_id = clinical_trip_stop_point_id;
                this.logger = logger;
                this.$window = $window;
                this.$scope = $scope;
                this.patientService = patientService;
                this.chart_history = [];
                this.loadingBaseRoles = true;
                this.loadingEncounterTypes = true;
                this.loadingPatient = true;
                this.etLoadingError = false;
                this.time_of_visit = new Date();
                this.encounter_types = [];
                this.all_encounter_types = [];
                this.care_plan_problems = [];
                this.care_plan_problem_options = [];
                this.hasResourceManagerFlag = false;
                this.hasResourceManagerFlag = this.Session.get().agency['has_resource_manager'];
                this.user = this.Session.getUser();
                this.$q.all([this.getPatient(), this.getBaseRoles(), this.getEncounterTypes()]).then(function () {
                    // If the role_id is passed in the params set it
                    if (_this.user.flags.has_encounter_charting && _this.$state.params.role_id) {
                        _this.role_id = parseInt(_this.$state.params.role_id, 10);
                    }
                    // Then set the available encounter types based on the global role_id if it exists.
                    // If it doesn't then the available encounter types will be empty and require
                    // the user to manually set the role via the UI.
                    _this.setEncounterTypes();
                    // Now that the patient id is set get all care plan problems for the patient
                    _this.setCarePlanProblems();
                    // Now that the role_id and encounter types are set, set the correct encounter type
                    // based on the encounter_type_id passed in the params if it exists.
                    // If it's valid, which it should be, set the initial screen and create
                    // the chartObj for the chart editor.
                    if (_this.user.flags.has_encounter_charting && _this.$state.params.encounter_type_id) {
                        var encounterTypeId = parseInt(_this.$state.params.encounter_type_id, 10);
                        _this.setEncounterType(encounterTypeId);
                        if (_this.encounter_type) {
                            _this.setInitialScreen(_this.encounter_type);
                            _this.create();
                        }
                    }
                    // If mileage_chart_init is passed in the params, this is a chart object coming from the
                    // iOS mileage app. Parse the object out then follow the steps above plus some
                    // inside of setMileageChart()
                    if (_this.user.flags.has_encounter_charting && _this.$state.params.mileage_chart_init) {
                        var mileageChartObj = JSON.parse(_this.$state.params.mileage_chart_init);
                        if (Object.keys(mileageChartObj).length) {
                            _this.setMileageChart(mileageChartObj);
                        }
                    }
                });
                this.chart_history_layout = {
                    options: {
                        hideFooter: true,
                    },
                    callbackFn: function () { },
                    columns: [
                        { title: 'Date', field: 'effective_date', type: 'date', canSort: true },
                        { title: 'Discipline', field: 'role_name' },
                        { title: 'Encounter Type', field: 'encounter_type', canSort: true },
                        { title: 'Owner', field: 'user.name', canSort: true },
                    ],
                };
            }
            EncounterSelectorModalController.prototype.$onInit = function () { };
            EncounterSelectorModalController.prototype.getPatient = function () {
                var _this = this;
                this.loadingPatient = true;
                if (this.currentPatient) {
                    this.patient_id = this.currentPatient.id;
                    this.selectedPatient = this.currentPatient;
                    if (!this.currentPatient.current_address) {
                        var patientCache = this.cache(this.Patient, 'patients', { encrypt: true, force: true });
                        return patientCache.get({ id: this.currentPatient.id }).$promise.then(function (data) {
                            _this.currentPatient = data;
                            _this.selectPatient(_this.currentPatient);
                            _this.loadingPatient = false;
                            return;
                        });
                    }
                    else {
                        this.selectPatient(this.currentPatient);
                        this.loadingPatient = false;
                        return this.$q.when(true);
                    }
                }
                else {
                    this.loadingPatient = false;
                    return this.$q.when(true);
                }
            };
            EncounterSelectorModalController.prototype.getBaseRoles = function () {
                var _this = this;
                this.loadingBaseRoles = true;
                return this.options.get('base_roles').$promise.then(function (data) {
                    _this.baseRoles = _this.remove_non_user_roles(data);
                    if (_this.user.default_role) {
                        var exists = _this.baseRoles.filter(function (role) {
                            return role.id === _this.user.default_role.id;
                        });
                        if (exists.length) {
                            _this.role_id = _this.user.default_role.id;
                        }
                        else {
                            _this.role_id = null;
                        }
                    }
                    _this.loadingBaseRoles = false;
                    return _this.$q.when(true);
                });
            };
            EncounterSelectorModalController.prototype.getEncounterTypes = function () {
                var _this = this;
                this.loadingEncounterTypes = true;
                return this.cache(this.EncounterType, 'encounter_types', { maxAge: 10, force: true })
                    .query({ active: true })
                    .$promise.then(function (data) {
                    _this.all_encounter_types = [];
                    data.forEach(function (et) {
                        if (et.charting_type === 'custom' || et.charting_type === 'encounter_charting') {
                            if (et.charting_type === 'custom') {
                                _this.ChartingTemplateService.buildETSections(et, et.section_template.screens);
                            }
                            if (et.active) {
                                _this.all_encounter_types.push(et);
                            }
                        }
                    });
                    _this.loadingEncounterTypes = false;
                    return;
                })
                    .catch(function (e) {
                    console.log(e);
                    _this.loadingEncounterTypes = false;
                    _this.etLoadingError = true;
                    _this.etLoadingErrorText =
                        'There was an error when loading encounter types: ' + e.toString();
                    _this.all_encounter_types = [];
                });
            };
            EncounterSelectorModalController.prototype.getPatientVisitFrequencies = function (role_id, startDate, endDate) {
                var _this = this;
                var sDate = startDate || new Date();
                var eDate = endDate || new Date();
                this.patientService
                    .getPatientVisitFrequencies(this.patient_id, sDate.toDateString(), eDate.toDateString(), role_id)
                    .then(function (response) {
                    _this.visitFrequency = response.data;
                }).catch(function (error) {
                    _this.logger.error('Unable to fetch visit frequencies' + error);
                });
            };
            EncounterSelectorModalController.prototype.setCarePlanProblems = function () {
                var _this = this;
                this.cache(this.CarePlanProblemEntry, 'care_plan_problems', {
                    encrypt: true,
                    maxAge: 10,
                    force: true,
                })
                    .query({ patient_id: this.patient_id })
                    .$promise.then(function (data) {
                    _this.care_plan_problems = [];
                    _this.care_plan_problem_options = [];
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var item = data_1[_i];
                        if (item.care_plan_status.toLowerCase() === 'active' || item.care_plan_status.toLowerCase() === 'expired') {
                            _this.care_plan_problems.push(item);
                            _this.care_plan_problem_options.push({
                                id: item.id,
                                description: item.problem.length > 49 ? item.problem.slice(0, 49) + '...' : item.problem
                            });
                        }
                    }
                });
            };
            EncounterSelectorModalController.prototype.close = function () {
                if (this.$state.current.name === 'patients.clinical-chart-entry-new.section') {
                    this.$state.go('patients.clinical-chart', { patientId: this.patient_id });
                }
                this.$mdDialog.hide();
            };
            EncounterSelectorModalController.prototype.setEncounterTypes = function () {
                var _this = this;
                var defEncType = {
                    id: 'encounter_charting',
                    name: 'Default Encounter Charting',
                    charting_type: 'encounter_charting',
                    lookup_name: 'encounter_charting',
                    display_name: 'Default Encounter Charting',
                };
                if (!this.role_id) {
                    this.encounter_types = [];
                    return;
                }
                else {
                    this.getPatientVisitFrequencies(this.role_id);
                    this.role_name = this.baseRoles.filter(function (role) {
                        return role.id === _this.role_id;
                    })[0].description;
                    this.encounter_types = this.all_encounter_types.filter(function (et) {
                        return (et.role_ids.indexOf(_this.role_id) >= 0 &&
                            (et.charting_type === 'custom' || et.charting_type === 'encounter_charting') &&
                            // Remove template options with a SIA/braden section or a section with a deprecated child section
                            !(et.section_template.screens.some(function (screen) { return screen.name === 'skin_integrity_assessment'; }) ||
                                et.section_template.screens.some(function (screen) { return screen.childSections &&
                                    screen.childSections.some(function (child) { return child.deprecated === true; }); }) ||
                                et.section_template.screens.some(function (screen) { return screen.childSections &&
                                    screen.childSections.some(function (child) { return child.name.includes('custom_skin_integrity'); }); })));
                    });
                    this.encounter_types.splice(0, 0, defEncType);
                }
                this.encounter_type = null;
            };
            EncounterSelectorModalController.prototype.setInitialScreen = function (item) {
                if (!item) {
                    this.initial_section_name = 'encounter_charting';
                }
                else if (item.charting_type === 'encounter_charting') {
                    this.initial_section_name = 'encounter_charting';
                }
                else {
                    if (item.section_template.screens[0].childSections) {
                        this.initial_section_name = item.section_template.screens[0].childSections[0].name;
                    }
                    else {
                        this.initial_section_name = item.section_template.screens[0].name;
                    }
                }
            };
            EncounterSelectorModalController.prototype.disableCreate = function () {
                if (!this.encounter_type || !this.time_of_visit || !this.role_id || !this.selectedPatient) {
                    return true;
                }
                if (this.encounter_type.require_care_plan_problem) {
                    if (!this.care_plan_problem_id && !this.create_new_care_plan_problem) {
                        return true;
                    }
                }
                return false;
            };
            EncounterSelectorModalController.prototype.create = function (chartObj) {
                var _this = this;
                var obj = {
                    selectedPatient: this.selectedPatient,
                    user: this.user,
                    role_id: this.role_id,
                    role_name: this.role_name,
                    effective_date: new Date(this.time_of_visit.toDateString()),
                    time_of_visit: this.time_of_visit,
                    encounter_type_id: this.encounter_type_id,
                    encounter_type: this.encounter_type,
                    charting_client: this.encounter_type.charting_type,
                    initial_section_name: this.initial_section_name,
                    clinical_trip_stop_point_id: this.clinical_trip_stop_point_id,
                    // Use filter() instead of find because es5 does not have the find() method.
                    care_plan_problem: this.care_plan_problem_id ? this.care_plan_problems.filter(function (cpp) { return cpp.id === _this.care_plan_problem_id; })[0] : null,
                    create_new_care_plan_problem: this.create_new_care_plan_problem
                };
                if (chartObj) {
                    obj['evv_uuid'] = chartObj.evv_uuid ? chartObj.evv_uuid : '';
                    obj['electronic_visit_verification'] = chartObj.electronic_visit_verification
                        ? chartObj.electronic_visit_verification
                        : {};
                    obj['patient_time'] = chartObj.patient_time ? chartObj.patient_time : {};
                    obj['patient_time_uuid'] = chartObj.patient_time_uuid ? chartObj.patient_time_uuid : '';
                }
                this.$state.go('patients.clinical-chart-entry-new.section', {
                    patientId: this.patient_id,
                    initObj: obj,
                    sectionName: this.initial_section_name,
                });
                this.$mdDialog.hide();
            };
            EncounterSelectorModalController.prototype.selectPatient = function (item) {
                if (item) {
                    this.selectedPatient = item;
                    this.patient_id = this.selectedPatient.id;
                    this.getServerCharts();
                    this.setCarePlanProblems();
                }
                else {
                    this.patient_id = -1;
                    this.selectedPatient = null;
                    this.chart_history = [];
                }
            };
            EncounterSelectorModalController.prototype.getServerCharts = function () {
                var _this = this;
                this.ClinicalChartEntry.getHeadersByPatientId(this.selectedPatient.id, 1, 100).$promise.then(function (data) {
                    for (var i = data.items.length - 1; i >= 0; --i) {
                        if (data.items[i].locked) {
                            data.items.splice(i, 1);
                        }
                    }
                    _this.chart_history = data.items;
                });
            };
            EncounterSelectorModalController.prototype.editChart = function (item) {
                var firstSection = this.ChartingTemplateService.getFirstSection(this.ChartingTemplateService.getTemplateName(item));
                this.$state.go('patients.clinical-chart-entry-edit.section', {
                    patientId: this.patient_id,
                    chartEntryId: item.id,
                    sectionName: firstSection,
                });
                this.close();
            };
            EncounterSelectorModalController.prototype.searchEncounterTypes = function (term) {
                term = term.toLowerCase();
                return this.encounter_types.filter(function (item) {
                    return item.name.toLowerCase().indexOf(term) > -1;
                });
            };
            EncounterSelectorModalController.prototype.setEncounterType = function (encounterTypeId) {
                var _this = this;
                this.encounter_type_id = encounterTypeId;
                // @ts-ignore
                this.encounter_type = this.encounter_types.find(function (encounterType) {
                    return encounterType.id === _this.encounter_type_id;
                });
            };
            EncounterSelectorModalController.prototype.setMileageChart = function (mileageChartObj) {
                this.role_id = mileageChartObj.role_id;
                this.time_of_visit = new Date(mileageChartObj.patient_time.in_datetime);
                this.clinical_trip_stop_point_id = mileageChartObj.clinical_trip_stop_point_id;
                this.setEncounterTypes();
                this.setEncounterType(mileageChartObj.encounter_type_id);
                this.setInitialScreen(this.encounter_type);
                this.create(mileageChartObj);
            };
            // Remove any roles that a user cannot assign to a chart
            EncounterSelectorModalController.prototype.remove_non_user_roles = function (roles) {
                return roles.filter(function (e) { return e['description'] !== 'System Generated'; });
            };
            EncounterSelectorModalController.prototype.openPhysicianOrders = function () {
                var url = document.location.origin + "/y/patients/" + this.patient_id + "/physician_orders";
                this.$window.open(url, '_blank');
            };
            EncounterSelectorModalController.prototype.timeOfVisitChanged = function () {
                this.getPatientVisitFrequencies(this.role_id, this.time_of_visit, this.time_of_visit);
            };
            EncounterSelectorModalController.$inject = [
                'options',
                'SessionService',
                '$q',
                '$state',
                '$filter',
                'ClinicalChartEntry',
                '$mdDialog',
                'cache',
                'EncounterType',
                'CarePlanProblemEntry',
                'ChartingTemplateService',
                'currentPatient',
                'Patient',
                '$timeout',
                'clinical_trip_stop_point_id',
                'logger',
                '$window',
                '$scope',
                'patientService'
            ];
            return EncounterSelectorModalController;
        }());
        angular
            .module('consolo')
            .controller('EncounterSelectorModalController', EncounterSelectorModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var CalendarPanelController = /** @class */ (function () {
            function CalendarPanelController(filters, show, panelRef) {
                this.filters = filters;
                this.panelRef = panelRef;
                this.showStart = true;
                this.showEnd = true;
                this.today = new Date();
                this.title = 'Select Date Range';
                switch (show) {
                    case 'start':
                        this.title = 'All Days After...';
                        this.showEnd = false;
                        break;
                    case 'end':
                        this.title = 'All Days Before...';
                        this.showStart = false;
                        break;
                }
            }
            CalendarPanelController.prototype.startChanged = function () {
                if (this.showEnd) {
                    this.filters.range_type = 'custom';
                }
            };
            CalendarPanelController.prototype.endChanged = function () {
                if (this.showStart) {
                    this.filters.range_type = 'custom';
                }
            };
            CalendarPanelController.prototype.close = function () {
                this.panelRef.close();
            };
            CalendarPanelController.$inject = ['filters', 'show', 'mdPanelRef'];
            return CalendarPanelController;
        }());
        angular
            .module('consolo')
            .controller('CalendarPanelController', CalendarPanelController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ChartSearchDialogController = /** @class */ (function () {
            function ChartSearchDialogController($mdDialog, $state, ChartingPrintService, ChartUtilService, chart, logger) {
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.ChartingPrintService = ChartingPrintService;
                this.ChartUtilService = ChartUtilService;
                this.chart = chart;
                this.logger = logger;
                this.voided = [
                    'This chart have been voided and does not count for billable times or countable visits.',
                    "If you wish to recover this chart, please click the 'Recover' button below",
                ];
            }
            ChartSearchDialogController.prototype.close = function (val) {
                this.$mdDialog.hide(val);
            };
            ChartSearchDialogController.prototype.void = function ($event) {
                var _this = this;
                this.ChartUtilService.voidChart(this.chart, $event).then(function () { return _this.close('callback'); });
            };
            ChartSearchDialogController.prototype.recover = function ($event) {
                var _this = this;
                this.ChartUtilService.recoverChart(this.chart, $event).then(function () { return _this.close('callback'); });
            };
            ChartSearchDialogController.prototype.addAddendum = function (chart, $event) {
                this.ChartUtilService.addAddendum(chart, $event);
            };
            ChartSearchDialogController.prototype.addSignature = function (chart, $event) {
                this.ChartUtilService.addSignature(chart, $event).then(function () {
                    chart.signed = true;
                });
            };
            ChartSearchDialogController.prototype.addPhysicianSignature = function (chart, $event) {
                this.ChartUtilService.addPhysicianSignature(chart, $event).then(function () {
                    chart.signed = true;
                });
            };
            ChartSearchDialogController.prototype.tagAsComprehensive = function (chart) {
                this.ChartUtilService.tagAsComprehensive(chart).then(function () {
                    chart.comprehensive_nursing = true;
                    chart.can_tag_as_comprehensive = false;
                });
            };
            ChartSearchDialogController.prototype.linkCopied = function () {
                this.logger.success('Chart link copied to clipboard');
            };
            ChartSearchDialogController.prototype.getUrl = function () {
                return this.chart._viewUrl;
            };
            ChartSearchDialogController.prototype.addPatientTime = function () {
                var _this = this;
                this.ChartUtilService.getChartStoreById(this.chart.id).then(function (cs) {
                    _this.ChartUtilService.patientTimeDialog(cs);
                });
            };
            ChartSearchDialogController.$inject = [
                '$mdDialog',
                '$state',
                'ChartingPrintService',
                'ChartUtilService',
                'chart',
                'logger',
            ];
            return ChartSearchDialogController;
        }());
        angular.module('consolo').controller('ChartSearchDialogController', ChartSearchDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartSearchFilterService = /** @class */ (function (_super) {
            __extends(ChartSearchFilterService, _super);
            function ChartSearchFilterService($http, deviceSettingsService, $stateParams, officeFilterService, options, cache, lookupFactory, EncounterType, ChartingTemplateService, $filter, $q, Session, currentPatient, ChartUtilService, Permissions, logger, $mdDialog, $timeout, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.deviceSettingsService = deviceSettingsService;
                _this.$stateParams = $stateParams;
                _this.officeFilterService = officeFilterService;
                _this.options = options;
                _this.cache = cache;
                _this.lookupFactory = lookupFactory;
                _this.EncounterType = EncounterType;
                _this.ChartingTemplateService = ChartingTemplateService;
                _this.$filter = $filter;
                _this.$q = $q;
                _this.Session = Session;
                _this.currentPatient = currentPatient;
                _this.ChartUtilService = ChartUtilService;
                _this.Permissions = Permissions;
                _this.logger = logger;
                _this.$mdDialog = $mdDialog;
                _this.$timeout = $timeout;
                _this.$interval = $interval;
                _this.pageTracker = {
                    page: 0,
                    totalPages: 0,
                    totalRows: 0,
                    items: [],
                };
                _this.chips = [];
                _this.chipToggles = {};
                _this.selectedItems = {
                    patient_display_name: null,
                    discipline: null,
                    office_id: null,
                    team_id: null,
                    created_by_id: null,
                    user_id: null,
                };
                _this.ignoreChips = ['page_size', 'page', 'sort_by', 'sort_dir', 'office_ids', 'patient_id'];
                _this.checkboxTypes = ['encounter_types', 'clinical_chart_types'];
                _this.chipLabels = {
                    office_id: 'Office',
                    created_by_id: 'Created By',
                    user_id: 'Chart Owner',
                    discipline: 'Discipline',
                    team_id: 'Team',
                    start_date: 'From',
                    end_date: 'To',
                };
                _this.date_range_options = [
                    { name: 'Custom', value: 'custom' },
                    { name: 'Today', value: 'today' },
                    { name: 'Last 7 Days', value: 'last_7' },
                    { name: 'Last 30 Days', value: 'last_30' },
                    { name: 'Month to Date', value: 'month_to_date' },
                    { name: 'Previous Month', value: 'previous_month' },
                    { name: 'Year to Date', value: 'year_to_date' },
                    { name: 'All Days Before', value: 'all_before' },
                    { name: 'All Days After', value: 'all_after' },
                ];
                _this.lookups = {};
                _this.saveParams = true;
                _this.isLoading = false;
                _this.anyYesNoTypes = {
                    has_signature: 'Has Signature',
                    has_physician_signature: 'Has Physician Signature',
                    has_patient_time: 'Has Patient Time',
                    has_evv: 'Has Evv',
                    has_postmortem: 'Has Postmortem',
                    submitted_to_coderyte: 'Submitted to CodeRyte',
                    processed_by_coderyte: 'Processed by CodeRyte',
                    deleted: 'Voided',
                };
                _this.etDiscontinued = false;
                _this.lookupsReady = _this.$q.defer();
                _this.filtersLoaded = _this.$q.defer();
                _this.user = _this.Session.getUser();
                _this.downloadProgress = {};
                _this.apiSelectUrl = '/api/v2/clinical_chart_search';
                _this.displayToggles =
                    _this.deviceSettingsService.getValue(consolo.deviceSettings.Pages.chart_search, consolo.deviceSettings.Settings.display_toggles) || {};
                var promiseChain = [];
                promiseChain.push(_this.options.get('base_roles').$promise.then(function (data) {
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var role = data_1[_i];
                        role.name = role.description;
                    }
                    _this.lookups['disciplines'] = data;
                    return _this.$q.when(true);
                }));
                var lookupCache = _this.cache(_this.lookupFactory, 'lookup', { maxAge: 10 });
                var encryptedLookupCache = _this.cache(_this.lookupFactory, 'lookup', {
                    encrypt: true,
                    maxAge: 10,
                });
                promiseChain.push(lookupCache['teams']().$promise.then(function (data) {
                    _this.lookups['teams'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(encryptedLookupCache['patients']({ limit: -1, active: true, deceased: true }).$promise.then(function (data) {
                    _this.lookups['patients'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(lookupCache['users']({ limit: -1 }).$promise.then(function (data) {
                    _this.lookups['users'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(_this.cache(_this.EncounterType, 'encounter_types', { maxAge: 10 })
                    .query({})
                    .$promise.then(function (data) {
                    _this.lookups['all_encounter_types'] = data;
                    if (!_this.lookups['all_encounter_types'] ||
                        !_this.lookups['all_encounter_types'].length) {
                        _this.lookups['all_encounter_types'] = [];
                    }
                    return _this.$q.when(true);
                }));
                promiseChain.push(_this.officeFilterService
                    .getInstance()
                    .then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                    _this.lookups.offices = _this.officeFilterServiceInstance.offices;
                    return _this.$q.when(true);
                }));
                _this.$q.all(promiseChain).finally(function () {
                    _this.lookups['clinical_chart_types'] = _this.ChartingTemplateService.getAllBaseSections(true);
                    _this.lookups['clinical_chart_types'].push(_this.ChartingTemplateService.getCarePlanProblemSection());
                    _this.lookups['clinical_chart_types'].sort(function (a, b) {
                        if (a.title < b.title) {
                            return -1;
                        }
                        else if (a.title > b.title) {
                            return 1;
                        }
                        else {
                            return 0;
                        }
                    });
                    _this.lookupsReady.resolve(true);
                });
                return _this;
            }
            ChartSearchFilterService.prototype.isReady = function () {
                return this.filtersLoaded.promise.then(function () {
                    return true;
                });
            };
            ChartSearchFilterService.prototype.setFiltersToParam = function (paramObj) {
                angular.extend(this.filters, paramObj);
                if (paramObj.patient_id) {
                    this.filters.patient_display_name = {
                        id: paramObj.patient_id,
                        value: paramObj.patient_id,
                        name: this.currentPatient.patient.name,
                        display: this.currentPatient.patient.name,
                    };
                }
                if (paramObj.start_date && paramObj.end_date) {
                    this.filters.range_type = 'custom';
                }
                else {
                    this.filters.range_type = 'year_to_date';
                    this.filters.update_range();
                }
                this.saveParams = false;
            };
            ChartSearchFilterService.prototype.setFilters = function (paramObj) {
                var _this = this;
                return this.lookupsReady.promise.then(function () {
                    _this.chips = [];
                    for (var k in _this.chipToggles) {
                        if (_this.chipToggles.hasOwnProperty(k)) {
                            delete _this.chipToggles[k];
                        }
                    }
                    var getData = !_this.data;
                    _this.filters = new consolo.domain.ChartSearchFilters();
                    if (paramObj) {
                        _this.setFiltersToParam(paramObj);
                        getData = true;
                    }
                    else {
                        var prevFilters = _this.deviceSettingsService.getValue(consolo.deviceSettings.Pages.chart_search, consolo.deviceSettings.Settings.previous_filters);
                        if (prevFilters) {
                            angular.extend(_this.filters, prevFilters);
                        }
                    }
                    var lookupKey = '', lookupId, filterDisplay, result, selectedItem = false;
                    if (_this.filters.encounter_types.length) {
                        var hasDiscontinued = false;
                        var et = _this.lookups['all_encounter_types'].filter(function (et) {
                            return et.discontinue_date && _this.filters.encounter_types.indexOf(et.id) >= 0;
                        });
                        if (et && et.length) {
                            _this.etDiscontinued = true;
                        }
                    }
                    if (!_this.etDiscontinued) {
                        var temp = _this.lookups['all_encounter_types'].filter(function (et) {
                            return !et.discontinue_date;
                        });
                        _this.lookups['encounter_types'] = temp;
                    }
                    else {
                        _this.lookups['encounter_types'] = _this.lookups['all_encounter_types'];
                    }
                    var _loop_1 = function (key) {
                        if (_this.filters.hasOwnProperty(key)) {
                            result = null;
                            lookupKey = lookupId = filterDisplay = '';
                            switch (key) {
                                case 'office_ids':
                                    lookupKey = 'offices';
                                case 'clinical_chart_types':
                                    lookupId = 'name';
                                    filterDisplay = 'title';
                                case 'encounter_types':
                                    lookupId = lookupId || 'id';
                                    filterDisplay = filterDisplay || 'name';
                                    lookupKey = key;
                                    filterDisplay = filterDisplay || 'name';
                                    _this.chipToggles[key] = {};
                                    if (_this.filters[key].length) {
                                        var _loop_2 = function (item) {
                                            result = _this.lookups[lookupKey].filter(function (lookup) {
                                                return lookup[lookupId] === item;
                                            })[0];
                                            result = result ? result[filterDisplay] : item;
                                            var obj = { value: true, display: result };
                                            obj[filterDisplay] = result;
                                            _this.chipToggles[key][item] = obj;
                                        };
                                        for (var _i = 0, _a = _this.filters[key]; _i < _a.length; _i++) {
                                            var item = _a[_i];
                                            _loop_2(item);
                                        }
                                    }
                                    break;
                                case 'patient_id':
                                    result = _this.lookups['patients'].filter(function (item) {
                                        return item.id === parseInt(_this.filters[key], 10);
                                    })[0];
                                    //If the result is not found and it's on a Patient page, it's probably discharged
                                    // and only active patients are being returned.  So manually add it from currentPatient
                                    if (!result && _this.currentPatient.patient) {
                                        result = _this.currentPatient.patient;
                                    }
                                    if (result) {
                                        var obj = {
                                            id: result.id,
                                            value: result.id,
                                            display: result.name,
                                            name: result.name,
                                        };
                                        _this.chipToggles['patient_display_name'] = obj;
                                        _this.filters['patient_display_name'] = angular.copy(obj);
                                        _this.selectedItems.patient_display_name = angular.copy(obj);
                                    }
                                    else {
                                        _this.filters[key] = null;
                                        _this.filters['patient_display_name'] = null;
                                    }
                                    break;
                                case 'patient_display_name':
                                    // if (this.filters[key] && this.filters[key].display) {
                                    //   this.chipToggles[key] = {
                                    //     id: this.filters.patient_id,
                                    //     value: this.filters.patient_id,
                                    //     display: this.filters[key].display,
                                    //     name: this.filters[key].display,
                                    //   };
                                    //   this.selectedItems.patient_display_name = angular.copy(this.chipToggles[key]);
                                    // } else {
                                    //   this.chipToggles[key] = null;
                                    // }
                                    break;
                                case 'start_date':
                                case 'end_date':
                                    _this.chipToggles[key] = new Date(_this.filters[key].toString());
                                    break;
                                default:
                                    selectedItem = true;
                                    switch (key) {
                                        case 'office_id':
                                            lookupKey = 'offices';
                                            break;
                                        case 'created_by_id':
                                        case 'user_id':
                                            lookupKey = 'users';
                                            break;
                                        case 'discipline':
                                            lookupId = 'role_symbol';
                                            lookupKey = 'disciplines';
                                            filterDisplay = 'description';
                                            break;
                                        case 'team_id':
                                            lookupKey = 'teams';
                                            break;
                                        default:
                                            selectedItem = false;
                                            lookupKey = key;
                                            break;
                                    }
                                    if (_this.filters[key]) {
                                        if (_this.lookups[lookupKey]) {
                                            lookupId = lookupId || 'id';
                                            filterDisplay = filterDisplay || 'name';
                                            result = _this.lookups[lookupKey].filter(function (item) {
                                                return item[lookupId] === _this.filters[key];
                                            })[0];
                                            result = result ? result[filterDisplay] : _this.filters[key];
                                            _this.chipToggles[key] = { value: _this.filters[key], display: result };
                                            _this.chipToggles[key][filterDisplay] = result;
                                            _this.chipToggles[key][lookupId] = _this.filters[key];
                                        }
                                        else {
                                            _this.chipToggles[key] = angular.copy(_this.filters[key]);
                                        }
                                        if (selectedItem) {
                                            _this.selectedItems[key] = angular.copy(_this.chipToggles[key]);
                                        }
                                    }
                                    break;
                            }
                        }
                    };
                    for (var key in _this.filters) {
                        _loop_1(key);
                    }
                    _this.defaultSort = _this.filters['sort_by'];
                    _this.defaultDir = _this.filters['sort_dir'];
                    _this.toggleMultiple(_this.chipToggles);
                    _this.filtersLoaded.resolve(true);
                    if (getData) {
                        return _this.callback();
                    }
                    else {
                        return _this.$q.when();
                    }
                });
            };
            ChartSearchFilterService.prototype.select = function (q) {
                var _this = this;
                return _super.prototype.select.call(this, q).then(function (data) {
                    for (var _i = 0, _a = data.data; _i < _a.length; _i++) {
                        var item = _a[_i];
                        var editUrl = void 0, viewUrl = void 0, target = void 0;
                        var railsUrl = "/patients/" + item.patient_id + "/clinical_charts/" + item.id;
                        var ngUrl = "/y/patients/" + item.patient_id + "/clinical_chart/" + item.id;
                        item.charting_client = item.charting_client || 'bootstrap_charting';
                        viewUrl = ngUrl;
                        editUrl = ngUrl + '/edit/encounter_charting';
                        item._chart_type = _this.ChartingTemplateService.getChartingClient(item.charting_client).description;
                        item._viewUrl = document.location.origin + viewUrl;
                        item._editUrl = editUrl;
                        item._target = target;
                        var d = new Date(item.created_at);
                        item._tzName = d
                            .toLocaleString('en', { timeZoneName: 'short' })
                            .split(' ')
                            .pop();
                        item._tzOffset = d.getTimezoneOffset();
                        item.user = item.user || { id: item.chart_owner_id };
                        item.created_by = item.created_by || { id: item.created_by_id };
                        item.role_name = item.discipline;
                        item.editable =
                            _this.ChartUtilService.checkChartPermissions(item) &&
                                _this.Permissions.can('update', 'clinical_charts');
                        item.canCreateAddendum = _this.Permissions.can('create', 'addendums');
                    }
                    _this.pageTracker.page = _this.currentPage;
                    _this.pageTracker.totalPages = _this.totalPages;
                    _this.pageTracker.totalRows = _this.totalRows;
                    _this.pageTracker.items = data.data;
                    return data.data;
                });
            };
            ChartSearchFilterService.prototype.updateChips = function (type, value, remove, name) {
                if (this.ignoreChips.indexOf(type) >= 0) {
                    return;
                }
                var chip, found = false, ordinal = 5;
                if (type === 'range_type') {
                    name = this.date_range_options.filter(function (o) { return o.value === value; })[0].name;
                    ordinal = 10;
                    switch (value) {
                        case 'custom':
                            remove = true;
                        case 'all_before':
                            name = name + ': ' + this.$filter('date')(this.filters.end_date, 'MM/dd/yyyy');
                            break;
                        case 'all_after':
                            name = name + ': ' + this.$filter('date')(this.filters.start_date, 'MM/dd/yyyy');
                            break;
                        default:
                            break;
                    }
                }
                else if (type === 'start_date' || type === 'end_date') {
                    ordinal = 10;
                    if (this.filters.range_type !== 'custom') {
                        remove = true;
                    }
                    else {
                        // if (type === 'start_date') {
                        //   ordinal = 9;
                        //   name = 'From: ';
                        // } else {
                        //   ordinal = 10;
                        //   name = 'To: ';
                        // }
                        name = this.$filter('date')(value, 'MM/dd/yyyy');
                    }
                }
                else if (this.anyYesNoTypes[type]) {
                    if ((value === 'any' && type !== 'deleted') ||
                        !value ||
                        (type === 'deleted' && value === 'no')) {
                        remove = true;
                    }
                    else {
                        name =
                            this.anyYesNoTypes[type] +
                                ': ' +
                                value.substring(0, 1).toUpperCase() +
                                value.substring(1);
                    }
                }
                else if (type === 'clinical_chart_types') {
                    ordinal = 9;
                }
                else if (type === 'patient_display_name') {
                    ordinal = 1;
                }
                for (var i = this.chips.length - 1; i >= 0; i--) {
                    chip = this.chips[i];
                    if (chip.type === type) {
                        found = true;
                        if (remove || !value) {
                            remove = true;
                            this.chips.splice(i, 1);
                        }
                        else {
                            chip.value = value;
                            chip.name = name ? name : value;
                            if (this.chipLabels[type]) {
                                chip.name = this.chipLabels[type] + ': ' + chip.name;
                            }
                        }
                    }
                }
                if (!found && !remove) {
                    var n = name ? name : value;
                    if (this.chipLabels[type]) {
                        n = this.chipLabels[type] + ': ' + n;
                    }
                    this.chips.push({
                        type: type,
                        value: value,
                        name: n,
                        ordinal: ordinal,
                    });
                }
            };
            ChartSearchFilterService.prototype.removeChip = function (type) {
                for (var i = this.chips.length - 1; i >= 0; i--) {
                    if (this.chips[i].type === type) {
                        this.chips.splice(i, 1);
                        break;
                    }
                }
            };
            ChartSearchFilterService.prototype.setTerm = function (term) { };
            ChartSearchFilterService.prototype.updateFilters = function (type, setting, name, remove) {
                if (this.checkboxTypes.indexOf(type) >= 0) {
                    if (this.filters[type].indexOf(setting) > -1) {
                        this.filters[type].splice(this.filters[type].indexOf(setting), 1);
                        remove = true;
                    }
                    else {
                        this.filters[type].push(setting);
                    }
                }
                else {
                    if (remove || angular.equals({}, this.filters[type])) {
                        setting = null;
                        remove = true;
                        // delete this.filters[type];
                    }
                    else {
                        this.filters[type] = setting;
                    }
                }
                this.updateChips(type, setting, remove, name);
                this.sortChips();
            };
            ChartSearchFilterService.prototype.toggle = function (type, setting, name, remove) {
                this.updateFilters(type, setting, name, remove);
                this.callback();
            };
            ChartSearchFilterService.prototype.toggleMultiple = function (settings) {
                for (var type in settings) {
                    if (settings.hasOwnProperty(type)) {
                        if (this.checkboxTypes.indexOf(type) >= 0) {
                            for (var value in settings[type]) {
                                if (settings[type].hasOwnProperty(value)) {
                                    if (this.filters[type].indexOf(value) > -1) {
                                        if (!settings[type][value].value) {
                                            this.filters[type].splice(this.filters[type].indexOf(value), 1);
                                        }
                                    }
                                    else if (settings[type][value].value) {
                                        this.filters[type].push(value);
                                    }
                                }
                            }
                        }
                        else {
                            var v = void 0;
                            if (settings[type] && settings[type].value !== undefined) {
                                v = settings[type].value;
                            }
                            else {
                                v = settings[type];
                            }
                            this.filters[type] = v ? v : null;
                        }
                    }
                }
                //Don't update the chips until all filters have been updated
                for (var type in settings) {
                    if (settings.hasOwnProperty(type)) {
                        if (this.checkboxTypes.indexOf(type) >= 0) {
                            for (var value in settings[type]) {
                                if (settings[type].hasOwnProperty(value)) {
                                    this.updateChips(type + '_' + value.toString(), value, !settings[type][value].value, settings[type][value].display);
                                }
                            }
                        }
                        else {
                            if (settings[type] && settings[type].value !== undefined) {
                                this.updateChips(type, settings[type].value, !settings[type].value, settings[type].display);
                            }
                            else {
                                this.updateChips(type, settings[type], !settings[type]);
                            }
                        }
                    }
                }
                this.sortChips();
                this.callback();
            };
            ChartSearchFilterService.prototype.sortChips = function () {
                this.chips.sort(function (a, b) {
                    if (a.ordinal < b.ordinal) {
                        return -1;
                    }
                    else if (a.ordinal > b.ordinal) {
                        return 1;
                    }
                    else if (a.name < b.name) {
                        return -1;
                    }
                    else if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            };
            ChartSearchFilterService.prototype.callback = function () {
                var _this = this;
                this.isLoading = true;
                //not sorting or changing pages so set the page to 1
                if (this.params &&
                    this.params.page === this.filters.page &&
                    this.params.sort_by &&
                    this.params.sort_by === this.filters.sort_by &&
                    this.params.sort_dir === this.filters.sort_dir) {
                    this.filters.page = 1;
                }
                this.params = angular.copy(this.filters);
                for (var p in this.params) {
                    if (this.params.hasOwnProperty(p)) {
                        if (this.params[p] === null) {
                            delete this.params[p];
                        }
                    }
                }
                return this.select().then(function () {
                    if (_this.saveParams) {
                        _this.deviceSettingsService.setValue(consolo.deviceSettings.Pages.chart_search, consolo.deviceSettings.Settings.previous_filters, _this.filters);
                    }
                    _this.isLoading = false;
                });
            };
            ChartSearchFilterService.prototype.getSearchResults = function (searchTerm) { };
            ChartSearchFilterService.prototype.toggleDiscontinuedEncounterTypes = function () {
                this.etDiscontinued = !this.etDiscontinued;
                if (!this.etDiscontinued) {
                    var temp = this.lookups['all_encounter_types'].filter(function (et) {
                        return !et.discontinue_date;
                    });
                    this.lookups['encounter_types'] = temp;
                }
                else {
                    this.lookups['encounter_types'] = this.lookups['all_encounter_types'];
                }
            };
            ChartSearchFilterService.prototype.toggleDisplaySetting = function (setting) {
                if (!this.displayToggles[setting]) {
                    this.displayToggles[setting] = false;
                }
                this.displayToggles[setting] = !this.displayToggles[setting];
                this.deviceSettingsService.setValue(consolo.deviceSettings.Pages.chart_search, consolo.deviceSettings.Settings.display_toggles, this.displayToggles);
            };
            ChartSearchFilterService.prototype.filterResults = function (type, searchTerm) {
                searchTerm = searchTerm.toLowerCase();
                if (type === 'term') {
                    var ret = [];
                    var temp = [];
                    var _loop_3 = function (t) {
                        if (this_1.lookups.hasOwnProperty(t) && t !== 'encounter_types') {
                            temp = this_1.lookups[t].filter(function (item) {
                                return item.name.toLowerCase().indexOf(searchTerm) >= 0;
                            });
                            ret = ret.concat(temp.map(function (item) {
                                item.type = t;
                                switch (t) {
                                    case 'patients':
                                        item._icon = 'action:face';
                                        break;
                                    case 'disciplines':
                                        item._icon = 'maps:local_offer';
                                        break;
                                    case 'offices':
                                        item._icon = 'communication:business';
                                        break;
                                    case 'teams':
                                        item._icon = 'action:supervisor_account';
                                        break;
                                    case 'users':
                                        item._icon = 'action:account_circle';
                                        break;
                                    default:
                                        item._icon = 'editor:title';
                                        break;
                                }
                                return item;
                            }));
                        }
                    };
                    var this_1 = this;
                    for (var t in this.lookups) {
                        _loop_3(t);
                    }
                    return ret;
                }
                else if (type === 'patients') {
                    // return this.lookups[type].filter(item => {
                    //   return item.name.toLowerCase().indexOf(searchTerm) >= 0;
                    // });
                    //Patient cache does not include MRN
                    return this.lookupFactory.patients({ q: searchTerm }).$promise.then(function (data) {
                        for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
                            var item = data_2[_i];
                            item.type = 'patients';
                            item._icon = 'action:face';
                        }
                        return data;
                    });
                }
                return this.lookups[type].filter(function (item) {
                    return item.name.toLowerCase().indexOf(searchTerm) >= 0;
                });
            };
            ChartSearchFilterService.prototype.resetFilters = function (id) {
                this.deviceSettingsService.setValue(consolo.deviceSettings.Pages.chart_search, consolo.deviceSettings.Settings.previous_filters, null);
                this.selectedItems = {
                    patient_display_name: null,
                    discipline: null,
                    office_id: null,
                    team_id: null,
                    created_by_id: null,
                    user_id: null,
                };
                this.setFilters({ patient_id: id });
            };
            ChartSearchFilterService.prototype.csvWrapper = function ($event) {
                var _this = this;
                this.downloadProgress = {
                    totalNeeded: 0,
                    totalComplete: 0,
                    totalTime: 0,
                    estimatedRemaining: 0,
                    percentComplete: 0,
                    increment: 0,
                };
                this.downloadProgress.totalNeeded = Math.ceil(this.totalRows / 100);
                if (this.downloadProgress.totalNeeded > 5) {
                    var confirm_1 = this.$mdDialog
                        .confirm()
                        .title('Confirmation Needed')
                        .textContent('This will generate a CSV file with ' +
                        this.totalRows.toString() +
                        ' records and make take a few moments.  Would you like to continue? ')
                        .targetEvent($event)
                        .ok('Generate CSV')
                        .cancel('Cancel');
                    this.$mdDialog.show(confirm_1).then(function () {
                        _this.isLoading = true;
                        _this.downloadCSV($event).finally(function () {
                            _this.isLoading = false;
                        });
                    });
                }
                else {
                    this.isLoading = true;
                    this.downloadCSV($event).finally(function () {
                        _this.isLoading = false;
                    });
                }
            };
            ChartSearchFilterService.prototype.downloadCSV = function ($event) {
                var _this = this;
                var q = this.$q.defer();
                var columns = [
                    { label: 'Chart Id', field: 'id' },
                    { label: 'Patient Name', field: 'patient_name' },
                    { label: 'Discipline', field: 'discipline' },
                    { label: 'Effective Date', field: 'effective_date', type: 'date' },
                    { label: 'Created Date', field: 'created_at', type: 'datetime' },
                    { label: 'Chart Type', field: '_chart_type' },
                    { label: 'Encounter Type', field: 'encounter_type_name' },
                    { label: 'Chart Owner', field: 'chart_owner_name' },
                    { label: 'Created By', field: 'created_by_name' },
                    { label: 'Office', field: 'office_name' },
                    { label: 'Team', field: 'team_name' },
                    { label: 'Signed', field: 'signed', type: 'boolean' },
                    { label: 'EVV', field: 'has_evv', type: 'boolean' },
                    { label: 'Time Units', field: 'time_units' },
                    { label: 'Chart Locked', field: 'locked', type: 'boolean' },
                    { label: 'Billable Time Locked', field: 'destroyable', type: 'booleanReverse' },
                    { label: 'Comprehensive Nursing Chart', field: '', type: 'boolean' },
                    { label: 'Has Vital Sign Measurement', field: 'has_vitals', type: 'boolean' },
                    { label: 'Has Addendum', field: 'addendum_count', type: 'boolean' },
                    { label: 'Nursing Visit', field: 'nursing_assessment', type: 'boolean' },
                    { label: 'Face to Face Visit', field: 'has_face_to_face', type: 'boolean' },
                    { label: 'Postmortem Visit', field: 'postmortem', type: 'boolean' },
                    { label: 'Countable Visit', field: 'countable', type: 'boolean' },
                    { label: 'Billable Visit', field: 'billable_visit', type: 'boolean' },
                    { label: 'Voided', field: 'deleted', type: 'boolean' },
                    { label: 'Chart URL', field: '_viewUrl' },
                    {
                        label: 'Completed Sections',
                        field: 'completed_sections',
                        type: 'array',
                        subfield: 'description',
                    },
                ];
                var rows = [];
                rows.push(this.buildCSVHeaders(columns));
                var fileName = 'clinical_charts_list_' + this.$filter('date')(new Date(), 'MMddyyyy_HHmm');
                if (this.totalPages === 1) {
                    rows = rows.concat(this.buildCSVRows(columns, this.data));
                    this.downloadFile(fileName, rows.join('\n'));
                    this.logger.success('Your CSV file has been generated and sent to your device');
                    q.resolve();
                }
                else {
                    var tempParams_1 = angular.copy(this.params);
                    this.params.page_size = 100;
                    this.downloadProgress.increment = Math.ceil(((1 / this.downloadProgress.totalNeeded) * 100) / 6);
                    this.updateProgressPercent();
                    this.progressInterval();
                    this.progressDialog($event);
                    this.params.page = 1;
                    var allData_1 = [];
                    var selectPromise = this.select();
                    for (var i = 2; i <= this.downloadProgress.totalNeeded; i++) {
                        selectPromise = selectPromise
                            .then(function (data) {
                            _this.downloadProgress.totalComplete++;
                            _this.updateProgressPercent();
                            allData_1 = allData_1.concat(data);
                            _this.params.page++;
                            return _this.select();
                        })
                            .catch(function (e) {
                            _this.csvCleanup(tempParams_1, q);
                            _this.logger.error('There was an error trying to generate your CSV file');
                        });
                    }
                    selectPromise
                        .then(function (data) {
                        _this.downloadProgress.totalComplete++;
                        _this.updateProgressPercent();
                        allData_1 = allData_1.concat(data);
                        rows = rows.concat(_this.buildCSVRows(columns, allData_1));
                        _this.downloadFile(fileName, rows.join('\n'));
                        _this.csvCleanup(tempParams_1, q);
                        _this.logger.success('Your CSV file has been generated and sent to your device');
                    })
                        .catch(function (e) {
                        _this.csvCleanup(tempParams_1, q);
                        _this.logger.error('There was an error trying to generate your CSV file');
                    });
                }
                return q.promise;
            };
            ChartSearchFilterService.prototype.csvCleanup = function (tempParams, q) {
                var _this = this;
                this.params = angular.copy(tempParams);
                this.$timeout(function () { return _this.$mdDialog.hide(); }, 1000);
                if (this.downloadProgress.progressInterval) {
                    this.$interval.cancel(this.downloadProgress.progressInterval);
                    this.downloadProgress.progressInterval = null;
                }
                this.downloadProgress = null;
                this.isLoading = true;
                this.select().then(function () {
                    q.resolve();
                    _this.isLoading = false;
                });
            };
            ChartSearchFilterService.prototype.progressInterval = function () {
                var _this = this;
                if (this.downloadProgress.progressInterval) {
                    this.$interval.cancel(this.downloadProgress.progressInterval);
                    this.downloadProgress.progressInterval = null;
                }
                this.downloadProgress.progressInterval = this.$interval(function () { return _this.updateProgressPercent(); }, 750);
            };
            ChartSearchFilterService.prototype.updateProgressPercent = function () {
                var currentComplete = Math.max(2, Math.ceil((this.downloadProgress.totalComplete / this.downloadProgress.totalNeeded) * 100));
                var nextComplete = Math.min(100, Math.ceil(((this.downloadProgress.totalComplete + 1) / this.downloadProgress.totalNeeded) * 100));
                var increment = this.downloadProgress.percentComplete + this.downloadProgress.increment;
                this.downloadProgress.percentComplete = Math.max(Math.min(nextComplete, increment), currentComplete);
            };
            ChartSearchFilterService.prototype.progressDialog = function ($event) {
                var dlg = {
                    controller: [
                        '$scope',
                        'ChartSearchFilterService',
                        '$mdDialog',
                        function ($scope, ChartSearchFilterService, $mdDialog) {
                            $scope.$mdDialog = $mdDialog;
                            $scope.downloadProgress = ChartSearchFilterService.downloadProgress;
                        },
                    ],
                    template: "\n          <md-dialog style=\"width:300px;height:150px;\">\n            <md-toolbar>\n              <div class=\"md-toolbar-tools\" layout=\"row\">\n                <div flex>\n                  Generating CSV\n                </div>\n                <div>\n                  {{downloadProgress.percentComplete}}%\n                </div>\n              </div>\n            </md-toolbar>\n            <div class=\"margin-top-md\">\n              <md-progress-circular style=\"margin:auto\" md-mode=\"determinate\" value=\"{{downloadProgress.percentComplete}}\">\n            </div>\n          </md-dialog>\n          ",
                    targetEvent: $event,
                    fullscreen: false,
                    clickOutsideToClose: false,
                };
                this.isLoading = false;
                this.$mdDialog.show(dlg);
            };
            ChartSearchFilterService.prototype.buildCSVRows = function (columns, data) {
                var rows = [];
                var r = [], val = '';
                for (var _i = 0, data_3 = data; _i < data_3.length; _i++) {
                    var row = data_3[_i];
                    (r = []), (val = '');
                    for (var _a = 0, columns_1 = columns; _a < columns_1.length; _a++) {
                        var c = columns_1[_a];
                        val = csg.util.resolve(row, c.field);
                        if (val === undefined || val === null) {
                            val = '';
                        }
                        r.push(this.csvCleanValue(val, c.type, false, c.subfield));
                    }
                    rows.push(r.join(','));
                }
                return rows;
            };
            ChartSearchFilterService.prototype.buildCSVHeaders = function (columns) {
                var headers = [];
                var label = '';
                for (var _i = 0, columns_2 = columns; _i < columns_2.length; _i++) {
                    var h = columns_2[_i];
                    headers.push(this.csvCleanValue(h.label, 'string'));
                }
                return headers.join(',');
            };
            ChartSearchFilterService.prototype.csvCleanValue = function (value, type, ignoreComma, subfield) {
                switch (type) {
                    case 'boolean':
                        value = !!value ? 'Yes' : 'No';
                        break;
                    case 'booleanReverse':
                        value = !value ? 'Yes' : 'No';
                        break;
                    case 'date':
                        value = this.$filter('date')(value, 'MM/dd/yyyy');
                        break;
                    case 'datetime':
                        value = this.$filter('date')(value, 'MM/dd/yyyy HH:mm');
                        break;
                    case 'array':
                        var tempArr = [];
                        for (var _i = 0, value_1 = value; _i < value_1.length; _i++) {
                            var i = value_1[_i];
                            if (subfield) {
                                tempArr.push(this.csvCleanValue(i[subfield], 'string', true));
                            }
                            else {
                                tempArr.push(this.csvCleanValue(i, 'string', true));
                            }
                        }
                        value = '"' + tempArr.join('; ') + '"';
                    default:
                        value = value.toString().replace(/<(?:[^>=]|='[^']*'|="[^"]*"|=[^'"][^\s>]*)*>/g, '');
                        break;
                }
                value = value.replace(/(?:\r\n|\r|\n)/g, ' '); //.replace(/,/g , '');
                return value.indexOf(',') >= 0 && !ignoreComma ? '"' + value + '"' : value;
            };
            ChartSearchFilterService.prototype.downloadFile = function (filename, text) {
                var a = document.createElement('a');
                a.style.setProperty('display', 'none');
                a['download'] = filename.replace('.csv', '') + '.csv';
                document.body.appendChild(a);
                var blob = new Blob([text], { type: 'text/csv' });
                var url = URL.createObjectURL(blob);
                a.href = url;
                a.click();
                this.$timeout(function () {
                    URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 30 * 1000);
            };
            ChartSearchFilterService.$inject = [
                '$http',
                'DeviceSettingsService',
                '$stateParams',
                'officeFilterService',
                'options',
                'cache',
                'Lookup',
                'EncounterType',
                'ChartingTemplateService',
                '$filter',
                '$q',
                'SessionService',
                'CurrentPatient',
                'ChartUtilService',
                'PermissionsService',
                'logger',
                '$mdDialog',
                '$timeout',
                '$interval',
            ];
            return ChartSearchFilterService;
        }(csg.datatable.ApiService));
        charting.ChartSearchFilterService = ChartSearchFilterService;
        angular.module('consolo').service('ChartSearchFilterService', ChartSearchFilterService);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartSearchSidebarComponent = /** @class */ (function () {
            function ChartSearchSidebarComponent(chartSearchFilterService, $timeout, $mdPanel, //: ng.material.IPanelService typings not up to date
            $stateParams, lookupFactory, CurrentPatient, $location, ChartUtilService, Session) {
                var _this = this;
                this.chartSearchFilterService = chartSearchFilterService;
                this.$timeout = $timeout;
                this.$mdPanel = $mdPanel;
                this.$stateParams = $stateParams;
                this.lookupFactory = lookupFactory;
                this.CurrentPatient = CurrentPatient;
                this.$location = $location;
                this.ChartUtilService = ChartUtilService;
                this.Session = Session;
                this.chipToggles = {};
                this.indeterminates = { org_ids: {}, area_ids: {}, region_ids: {} };
                this.expanded = false;
                this.testClassArray = ['md-primary', 'md-raised'];
                this.officeTextFilter = { name: '' };
                this.officeSearch = false;
                this.sectionSearch = false;
                this.etSearch = false;
                this.chartSearchFilterService.isLoading = true;
                this.chartSearchFilterService
                    .setFilters(this.queryFilters)
                    .then(function () {
                    _this.chipToggles = _this.chartSearchFilterService.chipToggles;
                    _this.lookups = _this.chartSearchFilterService.lookups;
                    if (_this.$location.search() && _this.$location.search().chartId) {
                        // for test, will eventually pass real chart id
                        var chartId = void 0;
                        if (_this.chartSearchFilterService.data && _this.chartSearchFilterService.data.length) {
                            chartId = _this.$location.search().chartId;
                        }
                        _this.viewChart(chartId, null);
                    }
                })
                    .finally(function () {
                    _this.chartSearchFilterService.isLoading = false;
                });
                this.codeRyteEnabled = this.Session.get().agency.has_coderyte;
            }
            ChartSearchSidebarComponent.prototype.getResults = function (type, searchTerm) {
                return this.chartSearchFilterService.filterResults(type, searchTerm);
            };
            ChartSearchSidebarComponent.prototype.selectPatient = function (item) {
                if (item) {
                    if (!this.chipToggles.patient_display_name ||
                        this.chipToggles.patient_display_name.id !== item.id) {
                        // if (item) {
                        this.chipToggles.patient_display_name = {
                            id: item.id,
                            value: item.id,
                            name: item.name,
                            display: item.name,
                        };
                        this.chipToggles.patient_id = item.id;
                        this.chartSearchFilterService.toggleMultiple(this.chipToggles);
                    }
                    else {
                        return;
                    }
                    // if (!item) {
                    //   this.chartSearchFilterService.chipToggles['patient_display_name'] = null;
                    // }
                    // } else {
                    //     this.chartSearchFilterService.toggleMultiple({
                    //       patient_id: null,
                    //       patient_display_name:
                    //     })
                    //   }
                }
                else {
                    if (this.chipToggles.patient_display_name) {
                        this.chipToggles.patient_id = null;
                        this.chipToggles.patient_display_name.value = false;
                        this.chartSearchFilterService.toggleMultiple(this.chipToggles);
                        this.chartSearchFilterService.chipToggles['patient_display_name'] = null;
                    }
                }
                // console.log('post patient', this.chartSearchFilterService.chipToggles);
                // this.CurrentPatient.getPatient(item.id);
            };
            ChartSearchSidebarComponent.prototype.selectItem = function (type, item, display_name, id_field) {
                var _this = this;
                if (!id_field) {
                    id_field = 'id';
                }
                if (!item) {
                    angular.element(document.activeElement).blur();
                }
                else if (this.chipToggles[type] && this.chipToggles[type].value === item[id_field]) {
                    return;
                }
                this.$timeout(function () {
                    if (item) {
                        // console.log('select item', item, type, display_name, id_field);
                        _this.chipToggles[type] = {
                            value: item[id_field],
                            display: item[display_name],
                        };
                    }
                    else {
                        _this.chipToggles[type] = { value: false, display: '' };
                    }
                    _this.chartSearchFilterService.toggleMultiple(_this.chipToggles);
                });
            };
            ChartSearchSidebarComponent.prototype.selectHasItem = function (type) {
                var _this = this;
                this.$timeout(function () {
                    // console.log('selectHasItem', type, this.filters[type]);
                    _this.chartSearchFilterService.toggleMultiple(_this.chipToggles);
                });
            };
            ChartSearchSidebarComponent.prototype.toggleCheckbox = function (type, value, display) {
                var _this = this;
                this.$timeout(function () {
                    _this.chipToggles[type][value].display = display;
                    _this.chartSearchFilterService.toggleMultiple(_this.chipToggles);
                });
            };
            // toggleCareStatus(value, name) {
            //   this.chartSearchFilterService.toggle('patientCareStatus', value, name);
            // }
            // toggleOffices(value, type) {
            //   this.$timeout(() => {
            //     // console.log('toggleOffices', value, type);
            //     switch (type) {
            //       case 'org_ids':
            //         // console.log('org_ids', this.toggles.org_ids[value]);
            //         if (this.toggles.org_ids[value]) {
            //           // this.toggles.org_ids[value] = false;
            //           this.toggleCorporate(value, true);
            //         } else {
            //           // this.toggles.org_ids[value] = true;
            //           this.toggleCorporate(value, false);
            //         }
            //         break;
            //       case 'region_ids':
            //         // console.log('region_ids', this.toggles.region_ids[value]);
            //         if (this.toggles.region_ids[value]) {
            //           // this.toggles.region_ids[value] = false;
            //           this.toggleRegion(value, true);
            //         } else {
            //           // this.toggles.region_ids[value] = true;
            //           this.toggleRegion(value, false);
            //         }
            //         break;
            //       case 'area_ids':
            //         // console.log('area_ids', this.toggles.area_ids[value]);
            //         if (this.toggles.area_ids[value]) {
            //           // this.toggles.area_ids[value] = false;
            //           this.toggleArea(value, true);
            //         } else {
            //           // this.toggles.area_ids[value] = true;
            //           this.toggleArea(value, false);
            //         }
            //         break;
            //       case 'office':
            //       default:
            //         break;
            //     }
            //     // this.$timeout(() => {
            //     //   this.toggleIndeterminate();
            //     // });
            //     this.chartSearchFilterService.toggleMultiple(this.toggles);
            //   });
            // }
            // toggleCorporate(id, value) {
            //   for (let r of this.officeFilterServiceInstance.regionOptions) {
            //     if (r.parent_id === id) {
            //       this.toggles.region_ids[r.value] = value;
            //       this.toggleRegion(r.value, value);
            //     }
            //   }
            // }
            // toggleRegion(id, value) {
            //   for (let a of this.officeFilterServiceInstance.areaOptions) {
            //     if (a.parent_id === id) {
            //       this.toggles.area_ids[a.value] = value;
            //       this.toggleArea(a.value, value);
            //     }
            //   }
            // }
            // toggleArea(id, value) {
            //   for (let o of this.officeFilterServiceInstance.offices) {
            //     // console.log(o, o.area, o.region, id);
            //     if (o.area === id) {
            //       this.toggles.office_ids[o.id] = value;
            //       // this.toggleOffice(o.id, value);
            //     }
            //   }
            // }
            ChartSearchSidebarComponent.prototype.focusOfficeSearch = function () {
                this.officeSearch = true;
                var e = document.getElementById('officeTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            ChartSearchSidebarComponent.prototype.focusETSearch = function ($event) {
                $event.stopPropagation();
                this.etSearch = true;
                this.chartSearchFilterService.displayToggles.expandEncounterTypes = true;
                var e = document.getElementById('etTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            ChartSearchSidebarComponent.prototype.focusChartSectionSearch = function ($event) {
                $event.stopPropagation();
                this.sectionSearch = true;
                this.chartSearchFilterService.displayToggles.expandChartTypes = true;
                var e = document.getElementById('chartSectionTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            ChartSearchSidebarComponent.prototype.rangeTypeChange = function ($event) {
                switch (this.chipToggles.range_type) {
                    case 'custom': //open the modal
                        this.openCalendarPanel($event);
                        break;
                    case 'all_after':
                        this.chipToggles.end_date = new Date(new Date().toDateString());
                        this.openCalendarPanel($event, 'start');
                        break;
                    case 'all_before':
                        this.chipToggles.start_date = new Date('01/01/2006');
                        this.openCalendarPanel($event, 'end');
                        break;
                    case 'today':
                    case 'last_7':
                    case 'last_30':
                    case 'month_to_date':
                    case 'previous_month':
                    case 'year_to_date':
                        this.chartSearchFilterService.filters.range_type = this.chipToggles.range_type;
                        this.chartSearchFilterService.filters.update_range();
                        this.chipToggles.end_date = this.chartSearchFilterService.filters.end_date;
                        this.chipToggles.start_date = this.chartSearchFilterService.filters.start_date;
                        this.chartSearchFilterService.toggleMultiple(this.chipToggles);
                        break;
                }
            };
            ChartSearchSidebarComponent.prototype.openCalendarPanel = function ($event, show) {
                var _this = this;
                var panelPosition = this.$mdPanel
                    .newPanelPosition()
                    .relativeTo('#fromBox')
                    // .top('0px')
                    // .left('40px');
                    .addPanelPosition('offset-start', 'center');
                // console.log(
                //   this.$mdPanel.xPosition,
                //   this.$mdPanel.yPosition,
                //   this.$mdPanel.animation,
                //   panelPosition
                // );
                var panelAnimation = this.$mdPanel
                    .newPanelAnimation()
                    .openFrom('#fromBox')
                    .duration(300)
                    .closeTo('#fromBox')
                    .withAnimation('md-panel-animate-scale'); //this.$mdPanel.animation.SCALE
                var initialStart = new Date(this.chipToggles.start_date);
                var initialEnd = new Date(this.chipToggles.end_date);
                var config = {
                    attachTo: angular.element(document.body),
                    controller: 'CalendarPanelController',
                    controllerAs: '$ctrl',
                    position: panelPosition,
                    animation: panelAnimation,
                    targetEvent: $event,
                    hasBackdrop: true,
                    templateUrl: '/y/templates/clinical-charts/search/calendar-panel.html',
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: true,
                    locals: { show: show, filters: this.chipToggles },
                    onCloseSuccess: function (panel, reason) {
                        if (initialStart.getTime() !== _this.chipToggles.start_date.getTime() ||
                            initialEnd.getTime() !== _this.chipToggles.end_date.getTime()) {
                            _this.chartSearchFilterService.toggleMultiple(_this.chipToggles);
                        }
                    },
                };
                this.$mdPanel.open(config);
            };
            ChartSearchSidebarComponent.prototype.viewChart = function (id, $event) {
                var _this = this;
                this.chartSearchFilterService.isLoading = true;
                this.ChartUtilService.getChartStoreById(id).then(function (chartStore) {
                    _this.ChartUtilService.viewChartModal(chartStore, $event);
                    _this.chartSearchFilterService.isLoading = false;
                });
            };
            ChartSearchSidebarComponent.$inject = [
                'ChartSearchFilterService',
                '$timeout',
                '$mdPanel',
                '$stateParams',
                'Lookup',
                'CurrentPatient',
                '$location',
                'ChartUtilService',
                'SessionService',
            ];
            return ChartSearchSidebarComponent;
        }());
        angular.module('consolo').component('csgChartSearchSidebar', {
            templateUrl: '/y/templates/clinical-charts/search/chart-search-sidebar.component.html',
            controller: ChartSearchSidebarComponent,
            bindings: {
                currentPatient: '<',
                queryFilters: '<',
            },
        });
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var ChartSearchController = /** @class */ (function () {
            function ChartSearchController(chartSearchFilterService, Logger, $state, $mdPanel, $timeout, $mdDialog, ChartingPrintService, ClinicalChartEntry, Permissions, ChartUtilService, PdfGenerator, $q, $window, $httpParamSerializer, Session, pdfer) {
                var _this = this;
                this.chartSearchFilterService = chartSearchFilterService;
                this.Logger = Logger;
                this.$state = $state;
                this.$mdPanel = $mdPanel;
                this.$timeout = $timeout;
                this.$mdDialog = $mdDialog;
                this.ChartingPrintService = ChartingPrintService;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.Permissions = Permissions;
                this.ChartUtilService = ChartUtilService;
                this.PdfGenerator = PdfGenerator;
                this.$q = $q;
                this.$window = $window;
                this.$httpParamSerializer = $httpParamSerializer;
                this.Session = Session;
                this.pdfer = pdfer;
                this.defaultRange = 'last_30';
                this.checkedCharts = [];
                this.toggleAllCharts = false;
                this.sorts = [
                    {
                        title: 'Effective Date',
                        sort_dir: 'DESC',
                        sort_by: 'effective_date',
                        default_dir: 'DESC',
                        hidden: true,
                    },
                    { title: 'Effective Date', sort_dir: 'ASC', sort_by: 'effective_date', default_dir: 'DESC' },
                    { title: 'Patient', sort_by: 'patient.name', sort_dir: 'ASC', default_dir: 'ASC' },
                    {
                        title: 'Patient',
                        sort_by: 'patient.name',
                        sort_dir: 'DESC',
                        default_dir: 'ASC',
                        hidden: true,
                    },
                    { title: 'Discipline', sort_by: 'discipline', sort_dir: 'ASC', default_dir: 'ASC' },
                    {
                        title: 'Discipline',
                        sort_by: 'discipline',
                        sort_dir: 'DESC',
                        default_dir: 'ASC',
                        hidden: true,
                    },
                    { title: 'Encounter Type', sort_by: 'encounter_type', sort_dir: 'ASC', default_dir: 'ASC' },
                    {
                        title: 'Encounter Type',
                        sort_by: 'encounter_type',
                        sort_dir: 'DESC',
                        default_dir: 'ASC',
                        hidden: true,
                    },
                    { title: 'Owner', sort_by: 'chart_owner', sort_dir: 'ASC', default_dir: 'ASC' },
                    {
                        title: 'Owner',
                        sort_by: 'chart_owner',
                        sort_dir: 'DESC',
                        default_dir: 'ASC',
                        hidden: true,
                    },
                    { title: 'Created Date', sort_by: 'created_at', sort_dir: 'DESC', default_dir: 'DESC' },
                    {
                        title: 'Created Date',
                        sort_by: 'created_at',
                        sort_dir: 'ASC',
                        default_dir: 'DESC',
                        hidden: true,
                    },
                ];
                this.currentPage = this.chartSearchFilterService.pageTracker;
                this.deletePermission = this.Permissions.can('destroy', 'clinical_charts');
                this.recoverPermission = this.Permissions.can('recover', 'clinical_charts');
                this.chartSearchFilterService.isReady().then(function () {
                    var sort = _this.sorts.filter(function (s) {
                        return (s.sort_by === _this.chartSearchFilterService.filters.sort_by &&
                            s.sort_dir === _this.chartSearchFilterService.filters.sort_dir);
                    })[0];
                    if (sort) {
                        _this.sortItem = sort;
                    }
                });
                this.agencyEncounterCharting = this.Session.get().agency.has_encounter_charting;
                this.codeRyteEnabled = this.Session.get().agency.has_coderyte;
                this.printClassicText = this.agencyEncounterCharting ? 'Print (Classic)' : 'Print';
                this.printEncounterText = this.pdfer.enabled ? 'Print' : 'Print (Encounter)';
                this.downloadIndividualText = this.pdfer.enabled
                    ? 'Print Multiple Charts'
                    : 'Download Individual Charts';
            }
            ChartSearchController.prototype.isLocked = function () {
                this.Logger.info('The chart you are trying to edit is locked', 'Chart Locked');
            };
            ChartSearchController.prototype.construction = function () {
                this.Logger.info('Feature not yet implemented', 'In Development');
            };
            ChartSearchController.prototype.sort = function (field, dir) {
                var s = { sort_by: field, sort_dir: dir.toUpperCase() };
                this.chartSearchFilterService.defaultSort = '';
                this.chartSearchFilterService.defaultDir = '';
                this.chartSearchFilterService.toggleMultiple(s);
            };
            ChartSearchController.prototype.loadPage = function (n) {
                this.chartSearchFilterService.toggle('page', n);
            };
            ChartSearchController.prototype.addChip = function (item) {
                var selectedItem = false;
                var type = item.type;
                switch (item.type) {
                    case 'patients':
                        this.chartSearchFilterService.toggleMultiple({
                            patient_id: item.id,
                            patient_display_name: item.name,
                        });
                        item.value = item.id;
                        type = 'patient_display_name';
                        break;
                    case 'office':
                        type = 'office_id';
                        item.value = item.id;
                        this.chartSearchFilterService.toggle('office_id', item.id, item.name);
                        break;
                    case 'all_encounter_types':
                        type = 'encounter_types';
                        item.value = item.id;
                        this.chartSearchFilterService.toggle('encounter_types', item.id, item.name);
                        break;
                    case 'disciplines':
                        item.value = item.role_symbol;
                        type = 'discipline';
                        this.chartSearchFilterService.toggle(type, item.role_symbol, item.description);
                        break;
                    case 'users':
                        type = 'user_id';
                        item.value = item.id;
                        this.chartSearchFilterService.toggle('user_id', item.id, item.name);
                        break;
                    default:
                        selectedItem = false;
                        this.chartSearchFilterService.toggle(item.type, item.value, item.name);
                        break;
                }
                if (selectedItem) {
                    this.chartSearchFilterService.selectedItems[type] = angular.copy(this.chartSearchFilterService.chipToggles[type]);
                }
            };
            ChartSearchController.prototype.removeChip = function (item) {
                var remove = false;
                if (item.type.indexOf('clinical_chart_types') === 0) {
                    this.chartSearchFilterService.chipToggles['clinical_chart_types'][item.value].value = false;
                }
                else if (item.type.indexOf('encounter_types') === 0) {
                    this.chartSearchFilterService.chipToggles['encounter_types'][item.value].value = false;
                }
                else if (item.type.indexOf('has_') === 0) {
                    this.chartSearchFilterService.chipToggles[item.type] = 'any';
                }
                else if (item.type === 'deleted') {
                    this.chartSearchFilterService.chipToggles[item.type] = 'no';
                }
                else if (item.type === 'patient_display_name') {
                    this.chartSearchFilterService.chipToggles[item.type].value = false;
                    this.chartSearchFilterService.chipToggles['patient_id'] = null;
                    if (this.$state.params.patientId) {
                        this.$state.go('clinical-chart', { patientId: null }, { reload: false });
                    }
                    remove = true;
                }
                else {
                    if (item.type === 'range_type') {
                        this.resetRangeTo(this.defaultRange);
                    }
                    else if (item.type === 'start_date') {
                        if (!this.chartSearchFilterService.chipToggles['end_date']) {
                            this.resetRangeTo(this.defaultRange);
                        }
                        else {
                            this.chartSearchFilterService.chipToggles['range_type'] = 'all_before';
                            this.chartSearchFilterService.filters.range_type = 'all_before';
                            this.chartSearchFilterService.filters.start_date = new Date('01/01/2006');
                            this.chartSearchFilterService.chipToggles['start_date'] = this.chartSearchFilterService.filters.start_date;
                        }
                    }
                    else if (item.type === 'end_date') {
                        if (!this.chartSearchFilterService.chipToggles['start_date']) {
                            this.resetRangeTo(this.defaultRange);
                        }
                        else {
                            this.chartSearchFilterService.chipToggles['range_type'] = 'all_after';
                            this.chartSearchFilterService.filters.range_type = 'all_after';
                            this.chartSearchFilterService.filters.end_date = new Date();
                            this.chartSearchFilterService.chipToggles['end_date'] = this.chartSearchFilterService.filters.end_date;
                        }
                    }
                    else {
                        remove = true;
                        this.chartSearchFilterService.chipToggles[item.type].value = false;
                    }
                }
                this.chartSearchFilterService.toggleMultiple(this.chartSearchFilterService.chipToggles);
                if (remove) {
                    this.chartSearchFilterService.chipToggles[item.type] = null;
                    if (this.chartSearchFilterService.selectedItems[item.type]) {
                        this.chartSearchFilterService.selectedItems[item.type] = null;
                    }
                }
            };
            ChartSearchController.prototype.resetRangeToLast7 = function () {
                this.chartSearchFilterService.filters.range_type = 'last_7';
                this.chartSearchFilterService.filters.update_range();
                this.chartSearchFilterService.chipToggles['range_type'] = 'last_7';
                this.chartSearchFilterService.chipToggles['start_date'] = this.chartSearchFilterService.filters.start_date;
                this.chartSearchFilterService.chipToggles['end_date'] = this.chartSearchFilterService.filters.end_date;
            };
            ChartSearchController.prototype.resetRangeTo = function (range) {
                this.chartSearchFilterService.filters.range_type = range;
                this.chartSearchFilterService.filters.update_range();
                this.chartSearchFilterService.chipToggles['range_type'] = range;
                this.chartSearchFilterService.chipToggles['start_date'] = this.chartSearchFilterService.filters.start_date;
                this.chartSearchFilterService.chipToggles['end_date'] = this.chartSearchFilterService.filters.end_date;
            };
            ChartSearchController.prototype.showCompletedPanel = function (chart, $event) {
                $event.stopPropagation();
                var sections = chart.completed_sections.length
                    ? chart.completed_sections
                    : [{ description: 'No sections completed!' }];
                var panelTemplate = "\n        <md-content class=\"simple-panel md-whiteframe-4dp\">\n          <md-list>\n            <md-list-item><h3>Completed Sections</h3></md-list-item>\n            <md-list-item ng-repeat=\"item in $ctrl.sections | orderBy: item track by $index\">\n              {{item.description}}\n            </md-list-item>\n          </md-list>\n        </md-content>\n      ";
                var panelPosition = this.$mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW);
                var config = {
                    targetEvent: $event,
                    id: chart.id + '_sectionPanel',
                    attachTo: angular.element(document.body),
                    controller: SectionPanelController,
                    controllerAs: '$ctrl',
                    template: panelTemplate,
                    position: panelPosition,
                    panelClass: 'simple-panel-container',
                    locals: {
                        sections: sections,
                    },
                    zIndex: 100,
                    hasBackdrop: false,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                };
                this.$mdPanel.open(config);
            };
            ChartSearchController.prototype.showMenu = function ($mdMenu, $event) {
                $mdMenu.open($event);
            };
            ChartSearchController.prototype.changeSort = function () {
                var _this = this;
                this.$timeout(function () {
                    // this.sortDisplay = this.sortItem.title + ': ' + this.sortItem.sort_dir;
                    _this.chartSearchFilterService.toggleMultiple({
                        sort_by: _this.sortItem.sort_by,
                        sort_dir: _this.sortItem.sort_dir,
                    });
                    // let newDir = this.sortItem.sort_dir === 'ASC' ? 'DESC' : 'ASC';
                    // let pos = this.sorts.indexOf(this.sortItem);
                    // let temp = angular.copy(this.sortItem);
                    // temp.sort_dir = newDir;
                    // for (let item of this.sorts) {
                    //   item.sort_dir = item.default_dir;
                    // }
                    // if (pos >= 0) {
                    //   this.sorts.splice(pos, 1, temp);
                    // }
                });
            };
            ChartSearchController.prototype.showDialog = function (chart, $event) {
                var _this = this;
                var ev = angular.copy($event);
                this.$mdDialog
                    .show({
                    targetEvent: event,
                    clickOutsideToClose: true,
                    fullscreen: true,
                    controller: 'ChartSearchDialogController',
                    controllerAs: '$ctrl',
                    templateUrl: '/y/templates/clinical-charts/search/chart-dialog.html',
                    locals: { chart: chart },
                })
                    .then(function (ret) {
                    switch (ret) {
                        case 'view':
                            _this.viewChart(chart.id, $event);
                            break;
                        case 'print':
                            _this.printChart(chart.id);
                            break;
                        case 'callback':
                            _this.chartSearchFilterService.callback();
                            break;
                        default:
                            break;
                        // this.ChartingPrintService.viewChartModal(chartStore, event);
                    }
                });
            };
            ChartSearchController.prototype.stopProp = function ($event) {
                $event.stopPropagation();
                this.$mdDialog.hide();
            };
            ChartSearchController.prototype.viewChart = function (id, $event) {
                var _this = this;
                this.chartSearchFilterService.isLoading = true;
                this.ChartUtilService.getChartStoreById(id).then(function (chartStore) {
                    _this.ChartUtilService.viewChartModal(chartStore, $event);
                    _this.chartSearchFilterService.isLoading = false;
                });
            };
            ChartSearchController.prototype.printChart = function (id) {
                this.PdfGenerator.printClinicalCharts([id]);
                // this.chartSearchFilterService.isLoading = true;
                // this.ChartUtilService.getChartStoreById(id).then(chartStore => {
                //   this.chartSearchFilterService.isLoading = false;
                //   this.ChartingPrintService.printChart(chartStore);
                // });
            };
            ChartSearchController.prototype.printClientClinicalChart = function (clinicalChartId, patientId) {
                if (this.pdfer.enabled) {
                    this.pdfer.generatePdfFromStates(['patients.clinical-chart-view'], [{ patientId: patientId, id: clinicalChartId }], clinicalChartId, 'ClinicalChart', false, {}, false, false, '', '', patientId, false, '#chart-wait-for-selector');
                }
                else {
                    this.ChartingPrintService.fetchAndPrintChart(clinicalChartId);
                }
            };
            ChartSearchController.prototype.faxChart = function (chartId, patientId) {
                this.ChartingPrintService.faxChart(chartId, patientId);
            };
            ChartSearchController.prototype.voidChart = function (chart, $event) {
                var _this = this;
                this.ChartUtilService.voidChart(chart, $event).then(function (success) {
                    if (success) {
                        _this.chartSearchFilterService.data.splice(_this.chartSearchFilterService.data.indexOf(chart), 1);
                    }
                });
            };
            ChartSearchController.prototype.recoverChart = function (chart, $event) {
                this.ChartUtilService.recoverChart(chart, $event).then(function (success) {
                    if (success) {
                        chart.deleted = false;
                        chart.locked = false;
                    }
                });
            };
            ChartSearchController.prototype.viewCodeRyteReport = function (chart, $event) {
                var url = document.location.origin + "/patients/" + chart.patient_id + "/clinical_charts/" + chart.id + "/coderyte_report";
                this.$window.open(url, '_blank');
            };
            ChartSearchController.prototype.resetFilters = function () {
                var id = this.$state.params.patientId || null;
                this.chartSearchFilterService.resetFilters(id);
            };
            ChartSearchController.prototype.addAddendum = function (chart, $event) {
                this.ChartUtilService.addAddendum(chart, $event);
            };
            ChartSearchController.prototype.addSignature = function (chart, $event) {
                this.ChartUtilService.addSignature(chart, $event).then(function () {
                    chart.signed = true;
                });
            };
            ChartSearchController.prototype.addPhysicianSignature = function (chart, $event) {
                this.ChartUtilService.addPhysicianSignature(chart, $event).then(function () {
                    chart.signed = true;
                });
            };
            ChartSearchController.prototype.tagAsComprehensive = function (chart) {
                var _this = this;
                this.ChartUtilService.tagAsComprehensive(chart).then(function () {
                    for (var _i = 0, _a = _this.chartSearchFilterService.data; _i < _a.length; _i++) {
                        var item = _a[_i];
                        if (item.comprehensive_nursing && item.patient_id === chart.patient_id) {
                            item.comprehensive_nursing = false;
                            item.can_tag_as_comprehensive = true;
                        }
                    }
                    chart.comprehensive_nursing = true;
                    chart.can_tag_as_comprehensive = false;
                });
            };
            ChartSearchController.prototype.toggleAll = function () {
                var _this = this;
                this.$timeout(function () {
                    if (_this.toggleAllCharts) {
                        _this.checkedCharts = _this.chartSearchFilterService.data.map(function (i) {
                            i.checked = true;
                            return { id: i.id, patientId: i.patient_id };
                        });
                    }
                    else {
                        _this.checkedCharts = [];
                        for (var _i = 0, _a = _this.chartSearchFilterService.data; _i < _a.length; _i++) {
                            var item = _a[_i];
                            item.checked = false;
                        }
                    }
                });
            };
            ChartSearchController.prototype.toggleChecked = function (item, $event) {
                var _this = this;
                $event.stopPropagation();
                this.$timeout(function () {
                    if (item.checked) {
                        if (_this.checkedCharts.findIndex(function (x) { return x.id === item.id; }) < 0) {
                            _this.checkedCharts.push({ id: item.id, patientId: item.patient_id });
                        }
                    }
                    else {
                        var pos = _this.checkedCharts.findIndex(function (x) { return x.id === item.id; });
                        if (pos >= 0) {
                            _this.checkedCharts.splice(pos, 1);
                        }
                    }
                });
            };
            ChartSearchController.prototype.printSelected = function () {
                // It should be this, but there's a bug somewhere else
                // let clinical_chart_ids = this.checkedCharts.map(c => c.id);
                var ids = this.checkedCharts.map(function (x) { return x.id; });
                if (this.pdfer.enabled) {
                    var arrPdfs = [];
                    var pdf = {};
                    for (var _i = 0, _a = this.checkedCharts; _i < _a.length; _i++) {
                        var item = _a[_i];
                        pdf = {
                            state: 'patients.clinical-chart-view',
                            stateParams: { patientId: item.patientId, id: item.id },
                            pdf_object_id: item.id,
                            pdf_object_type: 'ClinicalChart',
                            wait_for_selector: '#editorPaneTop',
                        };
                        arrPdfs.push(pdf);
                    }
                    this.pdfer.generateCombinedPdf(arrPdfs);
                }
                else {
                    this.PdfGenerator.printClinicalCharts(ids);
                }
            };
            ChartSearchController.prototype.downloadSelected = function () {
                var _this = this;
                this.chartSearchFilterService.isLoading = true;
                var promiseChain = [];
                for (var _i = 0, _a = this.checkedCharts; _i < _a.length; _i++) {
                    var item = _a[_i];
                    promiseChain.push(this.ChartingPrintService.printChart(item.id, item.patientId, false, true));
                }
                this.$q.all(promiseChain).finally(function () {
                    _this.chartSearchFilterService.isLoading = false;
                });
            };
            ChartSearchController.prototype.addPatientTime = function (item, event) {
                var _this = this;
                event.stopPropagation();
                this.chartSearchFilterService.isLoading = true;
                this.ChartUtilService.getChartStoreById(item.id).then(function (cs) {
                    _this.chartSearchFilterService.isLoading = false;
                    _this.ChartUtilService.patientTimeDialog(cs).then(function (pt) {
                        if (pt) {
                            item.time_units = pt.units_of_time;
                        }
                    });
                });
            };
            ChartSearchController.prototype.getSearchLink = function () {
                var cpy = angular.copy(this.chartSearchFilterService.filters);
                for (var p in cpy) {
                    if (cpy.hasOwnProperty(p)) {
                        if (cpy[p] === undefined ||
                            cpy[p] === null ||
                            angular.equals(cpy[p], {}) ||
                            cpy[p] === '') {
                            delete cpy[p];
                        }
                    }
                }
                var url = document.location.origin +
                    document.location.pathname +
                    '?' +
                    this.$httpParamSerializer(cpy);
                return url;
            };
            ChartSearchController.prototype.linkCopied = function () {
                this.Logger.success('Search URL copied to clipboard');
            };
            ChartSearchController.$inject = [
                'ChartSearchFilterService',
                'logger',
                '$state',
                '$mdPanel',
                '$timeout',
                '$mdDialog',
                'ChartingPrintService',
                'ClinicalChartEntry',
                'PermissionsService',
                'ChartUtilService',
                'PdfGenerator',
                '$q',
                '$window',
                '$httpParamSerializer',
                'SessionService',
                'pdfer',
            ];
            return ChartSearchController;
        }());
        var SectionPanelController = /** @class */ (function () {
            function SectionPanelController(mdPanelRef, sections) {
                this.mdPanelRef = mdPanelRef;
                this.sections = sections;
            }
            SectionPanelController.$inject = ['mdPanelRef', 'sections'];
            return SectionPanelController;
        }());
        angular.module('consolo').controller('ChartSearchController', ChartSearchController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));


var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var BaseChartSectionController = /** @class */ (function () {
            function BaseChartSectionController($filter, $parse, $scope, $state, $mdDialog, logger, cache, chartStore, EncounterType, ChartingTemplateService, CarePlanProblemEntry, SectionManager, ChartUtilService, PersistedItem, formModel, options, $timeout, formDefinition, currentSection) {
                this.$filter = $filter;
                this.$parse = $parse;
                this.$scope = $scope;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.cache = cache;
                this.chartStore = chartStore;
                this.EncounterType = EncounterType;
                this.ChartingTemplateService = ChartingTemplateService;
                this.CarePlanProblemEntry = CarePlanProblemEntry;
                this.SectionManager = SectionManager;
                this.ChartUtilService = ChartUtilService;
                this.PersistedItem = PersistedItem;
                this.formModel = formModel;
                this.options = options;
                this.$timeout = $timeout;
                this.formDefinition = formDefinition;
                this.currentSection = currentSection;
                this.errorFields = [];
                this.requiredFields = [];
                this.arrInterventions = [];
                this.yesNoNa = [{ id: 'Yes' }, { id: 'No' }, { id: 'N/A' }];
                //Only run in the Super constructor what you want the children to run
                //ie running this.loadFormDefinition is equivalent to super.loadFormDefinition
                this.patientId = $state.params.patientId;
                this.currentSection = SectionManager.currentSection;
                this.getReadOnly();
                this.intervention_outcomes = options.get('outcomes');
                this.intervention_patientFamilyEngagements = options.get('patient_family_engagements');
            }
            BaseChartSectionController.prototype.onChange = function (screen) {
                var _this = this;
                if (this.SectionManager.isEncounterCharting) {
                    this.$timeout(function () {
                        _this.SectionManager.checkRequiredFields(_this.chartStore, screen);
                        _this.formModel[screen.model].isEmpty = _this.SectionManager.checkEmptySections(screen, _this.formModel[screen.model]);
                    });
                }
            };
            BaseChartSectionController.prototype.isInvalid = function (field) {
                return !!this.errorFields[field];
            };
            BaseChartSectionController.prototype.isRequired = function (field) {
                return this.requiredFields.indexOf(field) >= 0;
            };
            BaseChartSectionController.prototype.getReadOnly = function () {
                //CPP Updates handled in loadCarePlans
                //TODO: Handle create/update
                // var canEdit = (this.currentSection.update && !!this.chartStore.model.id) || (this.currentSection.create && !this.chartStore.model.id);
                var _this = this;
                if (!this.currentSection ||
                    !this.currentSection.name ||
                    angular.equals({}, this.currentSection)) {
                    this.$timeout(function () {
                        _this.getReadOnly();
                    }, 250);
                }
                else {
                    // console.log(
                    //   this.chartStore.model.locked,
                    //   this.currentSection,
                    //   this.currentSection.update,
                    //   this.currentSection.create
                    // );
                    this.readonly =
                        this.chartStore.model.locked ||
                            (!this.currentSection.update && !this.currentSection.create) ||
                            !this.ChartUtilService.checkChartPermissions(this.chartStore.model);
                }
            };
            BaseChartSectionController.prototype.loadFormDefinition = function (errors) {
                // var curState = this.$state.current.name;
                // var stateName = this.currentSection && this.currentSection.model ?
                // this.currentSection.model : curState.substr(curState.lastIndexOf('.') + 1);
                var sectionName = this.$state.params.sectionName;
                if (sectionName) {
                    this.getReadOnly();
                    if (!this.formModel.patient_id) {
                        this.formModel.patient_id = this.chartStore.model.patient_id;
                    }
                    //handle refresh on the page before the draft loads
                    if ((!this.chartStore.model.encounter_type || this.chartStore.model.encounter_type.lookup_name === 'tablet_optimized') &&
                        this.ChartingTemplateService.isEncounterCharting(this.chartStore.model.charting_client)) {
                        this.formDefinition = this.ChartingTemplateService.getFormDefinition(sectionName, 'encounter_charting', this.chartStore.model.patient_id, { id: 'encounter_charting', name: 'Default Encounter Charting' });
                    }
                    else {
                        this.formDefinition = this.ChartingTemplateService.getFormDefinition(sectionName, this.chartStore.model.encounter_type_id, this.chartStore.model.patient_id, this.chartStore.model.encounter_type);
                    }
                    if (!this.formDefinition) {
                        return;
                    }
                    if (Array.isArray(this.formDefinition)) {
                        for (var _i = 0, _a = this.formDefinition; _i < _a.length; _i++) {
                            var formDef = _a[_i];
                            if (this.formModel[formDef.model]) {
                                this.formModel[formDef.model].isEmpty = this.SectionManager.checkEmptySections(formDef, this.formModel[formDef.model]);
                            }
                        }
                    }
                    else {
                        if (this.formModel[this.formDefinition.model]) {
                            this.formModel[this.formDefinition.model].isEmpty = this.SectionManager.checkEmptySections(this.formDefinition, this.formModel[this.formDefinition.model]);
                        }
                    }
                }
                else if (this.$state.current.name === 'patients.clinical-chart-view') {
                }
                else if (this.$state.current.name.indexOf('care-plan-problem') >= 0) {
                    var def = angular.copy(this.SectionManager.currentSection);
                    this.formDefinition = [def];
                }
            };
            BaseChartSectionController.prototype.underConstruction = function () {
                this.logger.info('The feature you tried to use is currently in development.', 'Under Construction');
            };
            // public actionPerformedChanged(item: any) {
            //   if (item.action_performed !== 'Yes') {
            //     item.resolved = false;
            //   }
            // }
            BaseChartSectionController.prototype.deleteMultipleSection = function ($event, idx) {
                var _this = this;
                $event.stopPropagation();
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Are you sure?')
                    .content('Do you really want to delete this section? This action cannot be undone.')
                    .ok('Yes')
                    .cancel('No')
                    .targetEvent($event);
                this.$mdDialog.show(confirmation).then(function () {
                    _this.SectionManager.deleteMultipleSection(_this.chartStore, idx);
                });
            };
            BaseChartSectionController.prototype.loadCarePlanProblem = function (cppid, cpp) {
                var _this = this;
                this.ChartUtilService.loadCarePlanProblem(this.formModel, cppid, cpp).then(function () {
                    _this.readonly = _this.chartStore.model.locked || cpp.readonly;
                });
                //TODO: CHECK CHANGES TO THIS
                // var isOffline = isNaN(this.$state.params.cppid);
                // if (isOffline) {
                //   var cppResource = new this.CarePlanProblemEntry({ uuid: this.$state.params.cppid });
                //   var cppPi = new this.PersistedItem('care_plan_problem', cppResource);
                //   return cppPi.getWorkingCopy().then(data => {
                //     this.setupInterventions(data.model, cpp);
                //     return;
                //   });
                // } else {
                //   if (!cppid) {
                //     this.logger.info('Invalid care plan ID');
                //   } else {
                //     return this.CarePlanProblemEntry.get({ id: cppid }).$promise.then(data => {
                //       this.setupInterventions(data, cpp);
                //       //TODO: need to account for new section create permission.
                //       // var canEdit = (this.SectionManager.cppUpdatePermissions.update && !!this.chartStore.model.id) ||
                //       //   (this.SectionManager.cppUpdatePermissions.create && !this.chartStore.model.id);
                //       this.readonly =
                //         this.chartStore.model.locked ||
                //         (!this.SectionManager.cppUpdatePermissions.create &&
                //           !this.SectionManager.cppUpdatePermissions.update);
                //       return;
                //     });
                //   }
                // }
            };
            BaseChartSectionController.prototype.pageIsEmpty = function (page) {
                return this.ChartUtilService.pageIsEmpty(this.chartStore.model, page);
            };
            BaseChartSectionController.prototype.setupInterventions = function (data, cpp) {
                var _this = this;
                //TODO CHECK CHANGES TO THIS
                // Go through list of interventions for this CPP and add the ones they've already filled out,
                // otherwise, add a blank intervention to fill out.
                data.interventions.forEach(function (intervention) {
                    var actingId = intervention.id ? intervention.id : intervention.uuid;
                    var interventionData = _this.$filter('filter')(_this.formModel.interventions, function (a) {
                        return !!a.care_plan_problem_intervention_id
                            ? a.care_plan_problem_intervention_id === actingId
                            : a.care_plan_problem_intervention_uuid === actingId;
                    })[0];
                    if (interventionData) {
                        interventionData.name = intervention.name;
                        interventionData.care_plan_problem_update_id = _this.formModel.id;
                        interventionData.care_plan_problem_intervention_id = intervention.id;
                        interventionData.care_plan_problem_intervention_uuid = intervention.uuid;
                        interventionData.due_date = intervention.due_date;
                        interventionData.chart_section = intervention.chart_section;
                        interventionData.name_editable = intervention.name_editable;
                    }
                    else {
                        if (!intervention.end_date) {
                            if (!_this.formModel.arrInterventions) {
                                _this.formModel.arrInterventions = [];
                            }
                            _this.formModel.arrInterventions.push({
                                care_plan_problem_update_id: _this.formModel.id,
                                name: intervention.name,
                                care_plan_problem_intervention_id: intervention.id,
                                care_plan_problem_intervention_uuid: intervention.uuid,
                                due_date: intervention.due_date,
                                chart_section: intervention.chart_section,
                            });
                        }
                    }
                });
            };
            BaseChartSectionController.$inject = [
                '$filter',
                '$parse',
                '$scope',
                '$state',
                '$mdDialog',
                'logger',
                'cache',
                'chartStore',
                'EncounterType',
                'ChartingTemplateService',
                'CarePlanProblemEntry',
                'ChartingSectionManager',
                'ChartUtilService',
                'PersistedItem',
                'formModel',
                'options',
                '$timeout',
            ];
            return BaseChartSectionController;
        }());
        controllers.BaseChartSectionController = BaseChartSectionController;
        angular.module('consolo').controller('BaseChartSectionController', BaseChartSectionController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CarePlanProblemReviewModalController = /** @class */ (function () {
            function CarePlanProblemReviewModalController($mdDialog, carePlanProblems) {
                this.$mdDialog = $mdDialog;
                this.carePlanProblems = carePlanProblems;
                this.selectedCarePlanProblem = null;
                carePlanProblems.forEach(function (carePlanProblem) { return (carePlanProblem._updated = false); });
            }
            CarePlanProblemReviewModalController.prototype.anyUpdated = function () {
                return this.carePlanProblems.filter(function (cpp) { return cpp._updated; }).length;
            };
            CarePlanProblemReviewModalController.prototype.save = function () {
                this.$mdDialog.hide(this.carePlanProblems);
            };
            CarePlanProblemReviewModalController.$inject = ['$mdDialog', 'carePlanProblems'];
            return CarePlanProblemReviewModalController;
        }());
        angular
            .module('consolo')
            .controller('CarePlanProblemReviewModalController', CarePlanProblemReviewModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ClinicalChartsCarePlanProblemController = /** @class */ (function (_super) {
            __extends(ClinicalChartsCarePlanProblemController, _super);
            function ClinicalChartsCarePlanProblemController($filter, $parse, $scope, $state, $mdDialog, logger, cache, chartStore, EncounterType, ChartingTemplateService, CarePlanProblemEntry, SectionManager, ChartUtilService, PersistedItem, formModel, options, $timeout, formDefinition, currentSection) {
                var _this = _super.call(this, $filter, $parse, $scope, $state, $mdDialog, logger, cache, chartStore, EncounterType, ChartingTemplateService, CarePlanProblemEntry, SectionManager, ChartUtilService, PersistedItem, formModel, options, $timeout, formDefinition, currentSection) || this;
                _this.cpp = {};
                _this.$filter = $filter;
                return _this;
                // this.loadFormDefinition(null);
            }
            ClinicalChartsCarePlanProblemController.prototype.editIntervention = function (index) {
                var _this = this;
                this.$mdDialog
                    .show({
                    controller: 'CarePlanProblemModalController as vm',
                    templateUrl: '/y/templates/care-plans/care-plan-problem-modal.html',
                    locals: {
                        intervention: this.formModel.interventions[index],
                        templateId: this.formModel.care_plan_template_id,
                    },
                })
                    .then(function (intervention) {
                    if (intervention) {
                        if (index !== undefined) {
                            _this.formModel.interventions[index] = intervention;
                        }
                        else {
                            _this.formModel.interventions.push(intervention);
                        }
                        _this.chartStore.saveDraft();
                    }
                });
            };
            ClinicalChartsCarePlanProblemController.prototype.loadFormDefinition = function (errors) {
                var _this = this;
                var isOffline = isNaN(this.$state.params.cppid);
                if (!this.formModel) {
                    var newItem = {
                        care_plan_problem_id: '',
                        care_plan_problem_uuid: '',
                        interventions: [],
                        legacy: false,
                    };
                    if (!isOffline) {
                        newItem.care_plan_problem_id = this.$state.params.cppid;
                    }
                    else {
                        newItem.care_plan_problem_uuid = this.$state.params.cppid;
                    }
                    this.chartStore.model.care_plan_problem_updates.push(newItem);
                    this.formModel = newItem;
                }
                _super.prototype.loadCarePlanProblem.call(this, this.$state.params.cppid, this.cpp).then(function () {
                    _this.start_date = _this.$filter('date')(_this.cpp.start_date, 'short');
                    _this.end_date = _this.$filter('date')(_this.cpp.end_date, 'short');
                    _this.last_updated = _this.$filter('date')(_this.cpp.updated_at, 'short');
                    _this.participant_list = '';
                    for (var _i = 0, _a = _this.cpp.participants; _i < _a.length; _i++) {
                        var p = _a[_i];
                        _this.participant_list += p.description + '; ';
                    }
                    _this.participant_list = _this.participant_list.substr(0, _this.participant_list.length - 2);
                });
                this.SectionManager.currentActiveCarePlanProblem = this.formModel;
            };
            ClinicalChartsCarePlanProblemController.prototype.reloadFormDefinition = function (errors) {
                // Since the care_plan_problem_updates come back in any order, we need to make sure we're
                // editing the right one.
                var item;
                item = this.$filter('filter')(this.chartStore.model.care_plan_problem_updates, function (b) {
                    if (isNaN(this.$state.params.cppid)) {
                        return (b.care_plan_problem_id === this.$state.params.cppid ||
                            b.care_plan_problem_uuid === this.$state.paramseParams.cppid);
                    }
                    return parseInt(b.care_plan_problem_id, 10) === parseInt(this.$state.params.cppid, 10);
                })[0];
                if (item !== this.formModel) {
                    angular.copy(item, this.formModel);
                }
            };
            return ClinicalChartsCarePlanProblemController;
        }(consolo.controllers
            .BaseChartSectionController));
        angular
            .module('consolo')
            .controller('ClinicalChartsCarePlanProblemController', ClinicalChartsCarePlanProblemController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ClinicalChartsCommonSectionController = /** @class */ (function (_super) {
            __extends(ClinicalChartsCommonSectionController, _super);
            function ClinicalChartsCommonSectionController($filter, $parse, $scope, $state, $mdDialog, logger, cache, chartStore, EncounterType, ChartingTemplateService, CarePlanProblemEntry, SectionManager, ChartUtilService, PersistedItem, formModel, options, $timeout, ChartingPrintService, Session, showSectionDetails, pdfer, Permissions, formDefinition, currentSection) {
                var _this = _super.call(this, $filter, $parse, $scope, $state, $mdDialog, logger, cache, chartStore, EncounterType, ChartingTemplateService, CarePlanProblemEntry, SectionManager, ChartUtilService, PersistedItem, formModel, options, $timeout, formDefinition, currentSection) || this;
                _this.ChartingPrintService = ChartingPrintService;
                _this.Session = Session;
                _this.showSectionDetails = showSectionDetails;
                _this.pdfer = pdfer;
                _this.Permissions = Permissions;
                _this.showUnmodifiedSections = false;
                _this.showWaitForSelector = false;
                _this.agencySignature = false;
                _this.requireSignature = false;
                _this.roleSignature = false;
                _this.isPAEView = false;
                _super.prototype.loadFormDefinition.call(_this, null);
                $scope.$on('reloadFormDefinition', function () {
                    _super.prototype.loadFormDefinition.call(_this, null);
                });
                _this.editorPane = angular.element(document.getElementById('editorPane'));
                _this.user = _this.Session.getUser();
                _this.agencySignature = _this.Session.get().agency.require_signatures;
                _this.roleSignature = _this.Permissions.has('require_signature', 'clinical_charts');
                _this.requireSignature = _this.agencySignature || _this.roleSignature;
                var hasVitasEncounterCharting = _this.Session.get().agency.has_vitas_encounter_charting;
                _this.isPAEView = (hasVitasEncounterCharting && _this.chartStore.model && _this.chartStore.model.encounter_type && _this.chartStore.model.encounter_type.is_pae_template);
                $timeout(function () { return (_this.showWaitForSelector = true); }, 3000);
                return _this;
            }
            ClinicalChartsCommonSectionController.prototype.endOfVisit = function ($event) {
                var _this = this;
                if (this.chartStore.model.patient_time) {
                    var confirmPTUpdate = {
                        clickOutsideToClose: false,
                        multiple: true,
                        targetEvent: $event,
                        fullscreen: true,
                        locals: {
                            in_datetime: this.chartStore.model.patient_time.in_datetime,
                            out_datetime: this.chartStore.model.patient_time.out_datetime,
                        },
                        template: "\n              <md-dialog flex-md=\"60\" flex-gt-md=\"50\" class=\"md-hue-1\">\n                <md-dialog-content class=\"layout-padding\">\n                  <h2 class=\"md-title\">Update Patient Time</h2>\n                  <div layout=\"row\" style=\"min-width: 450px;\" layout-padding layout-wrap>\n                    <csg-datepicker ng-model=\"in_datetime\" label=\"Time In\"\n                      is-required=\"true\" csg-md\n                      max-date=\"today\" csg-type=\"datetime\">\n                    </csg-datepicker>\n                    <csg-datepicker ng-model=\"out_datetime\" label=\"Time Out\"\n                      is-required=\"true\" csg-md\n                      max-date=\"today\" csg-type=\"datetime\">\n                    </csg-datepicker>\n                  </div>\n                  <div ng-if=\"now\" style=\"color:#d32f2f;padding-left:16px;\">\n                    Time in over 12 hours ago, Set Time Out to Now disabled\n                  </div>\n                </md-dialog-content>\n                <md-dialog-actions>\n                  <md-button ng-click=\"close()\">Cancel</md-button>\n                  <md-button ng-disabled=\"now\" ng-click=\"setNow()\">Set Time Out to Now</md-button>\n                  <md-button ng-click=\"hide()\">Update</md-button>\n                </md-dialog-actions>\n              </md-dialog>\n            ",
                        controller: [
                            '$scope',
                            '$mdDialog',
                            'in_datetime',
                            'out_datetime',
                            function ($scope, $mdDialog, in_datetime, out_datetime) {
                                $scope.in_datetime = in_datetime;
                                out_datetime = new Date(out_datetime);
                                var emptyTime = consolo.common.dateOnly(out_datetime);
                                var currentMS = new Date().getTime();
                                var inMS = new Date(in_datetime).getTime();
                                if (currentMS - inMS > 12 * 60 * 60 * 1000) {
                                    $scope.out_datetime = out_datetime;
                                    $scope.now = true;
                                }
                                else {
                                    if (out_datetime.getTime() === emptyTime.getTime()) {
                                        $scope.out_datetime = new Date();
                                    }
                                    else {
                                        $scope.out_datetime = out_datetime;
                                    }
                                    $scope.now = false;
                                }
                                $scope.today = new Date();
                                $scope.close = function () {
                                    _this.$mdDialog.cancel();
                                };
                                $scope.hide = function () {
                                    _this.$mdDialog.hide({ out: $scope.out_datetime, in: $scope.in_datetime });
                                };
                                $scope.setNow = function () {
                                    $scope.out_datetime = new Date();
                                };
                            },
                        ],
                    };
                    return this.$mdDialog
                        .show(confirmPTUpdate)
                        .then(function (time) {
                        _this.chartStore.model.patient_time.out_datetime = time.out;
                        _this.chartStore.model.patient_time.in_datetime = time.in;
                        return _this.chartStore.saveDraft().then(function () { return _this.syncConfirm($event); });
                    })
                        .catch(function () { return _this.syncConfirm($event); });
                }
            };
            ClinicalChartsCommonSectionController.prototype.syncConfirm = function ($event) {
                var _this = this;
                var title = 'Sync Chart?';
                var text = 'Would you like to save the chart to the server? ';
                var confirm = 'Sync Chart';
                if (this.requireSignature) {
                    title = 'Sync Chart & Sign?';
                    text += this.agencySignature ? 'Your agency requires a signature when syncing charts.' : 'One of your roles requires a signature when syncing charts.';
                    text += ' If you sync this chart now, you will be signing it as well. Signing this chart will lock it and prevent future editing.';
                    confirm = 'Sync & Sign Chart';
                }
                var confirmSync = this.$mdDialog
                    .confirm()
                    .clickOutsideToClose(false)
                    .title(title)
                    .textContent(text)
                    .ok(confirm)
                    .cancel('No')
                    .multiple(true)
                    .targetEvent($event);
                return this.$mdDialog
                    .show(confirmSync)
                    .then(function () {
                    if (_this.requireSignature) {
                        _this.chartStore.model.apply_signature = true;
                    }
                    return true;
                })
                    .catch(function () { return false; });
            };
            ClinicalChartsCommonSectionController.prototype.printChart = function () {
                if (this.pdfer.enabled && !this.chartStore.model.local) {
                    this.ChartingPrintService.printChart(this.chartStore.model.id, this.chartStore.model.patient_id);
                }
                else {
                    this.ChartingPrintService.printChartClient(this.chartStore);
                }
            };
            ClinicalChartsCommonSectionController.prototype.faxChart = function () {
                if (this.chartStore.model.local) {
                    this.logger.error('Cannot fax a draft');
                }
                else {
                    this.ChartingPrintService.faxChart(this.chartStore.model.id, this.chartStore.model.patient_id);
                }
            };
            ClinicalChartsCommonSectionController.prototype.scrollUpFrom = function (name, idx) {
                name = idx || idx === 0 ? name + '_' + idx : name;
                var next = angular.element(document.getElementById(this.SectionManager.getUpLocation(name)));
                this.SectionManager.goUp(name);
                this.editorPane[0].scrollTop += next.offset().top - this.editorPane.offset().top;
            };
            ClinicalChartsCommonSectionController.prototype.scrollDownFrom = function (name, idx) {
                name = idx !== null && idx !== undefined ? name + '_' + idx : name;
                var next = angular.element(document.getElementById(this.SectionManager.getDownLocation(name)));
                this.SectionManager.goDown(name);
                this.editorPane[0].scrollTop += next.offset().top - this.editorPane.offset().top;
            };
            ClinicalChartsCommonSectionController.prototype.canScrollUp = function (name) {
                return this.SectionManager.getUpLocation(name) !== 'editorPaneTop';
            };
            ClinicalChartsCommonSectionController.prototype.canScrollDown = function (name) {
                return this.SectionManager.getDownLocation(name) !== 'editorPaneBottom';
            };
            ClinicalChartsCommonSectionController.prototype.getViewUrl = function () {
                var id = this.chartStore.model.id || 'new';
                var ngUrl = "/y/patients/" + this.chartStore.model.patient_id + "/clinical_chart/" + id;
                var viewUrl = ngUrl;
                return document.location.origin + viewUrl;
            };
            ClinicalChartsCommonSectionController.prototype.linkCopied = function () {
                this.logger.success('Chart link copied to clipboard');
            };
            ClinicalChartsCommonSectionController.prototype.viewPreviousDetails = function (item) {
                if (this.showSectionDetails) {
                    var effDate = void 0, createdAt = void 0, name_1;
                    if (Array.isArray(item) && (item[0].effective_date || item[0].assessment_date)) {
                        effDate = this.$filter('date')(item[0].effective_date || item[0].assessment_date, 'MM/dd/yyyy');
                        createdAt = this.$filter('date')(item[0].created_at, 'MM/dd/yyyy h:mma');
                        name_1 = item[0].user_name
                            ? item[0].user_name
                            : item[0].last_name + ', ' + item[0].first_name;
                    }
                    else if (item.effective_date || item.assessment_date) {
                        effDate = this.$filter('date')(item.effective_date || item.assessment_date, 'MM/dd/yyyy');
                        createdAt = this.$filter('date')(item.created_at, 'MM/dd/yyyy h:mma');
                        name_1 = item.user_name ? item.user_name : item.last_name + ', ' + item.first_name;
                    }
                    if (effDate) {
                        return effDate + " | " + createdAt + " | " + name_1;
                    }
                    else {
                        return '';
                    }
                }
                else if (this.showSectionDetails === undefined) {
                    return null;
                }
                else {
                    return '';
                }
            };
            ClinicalChartsCommonSectionController.$inject = [
                '$filter',
                '$parse',
                '$scope',
                '$state',
                '$mdDialog',
                'logger',
                'cache',
                'chartStore',
                'EncounterType',
                'ChartingTemplateService',
                'CarePlanProblemEntry',
                'ChartingSectionManager',
                'ChartUtilService',
                'PersistedItem',
                'formModel',
                'options',
                '$timeout',
                'ChartingPrintService',
                'SessionService',
                'showSectionDetails',
                'pdfer',
                'PermissionsService'
            ];
            return ClinicalChartsCommonSectionController;
        }(consolo.controllers
            .BaseChartSectionController));
        angular
            .module('consolo')
            .controller('ClinicalChartsCommonSectionController', ClinicalChartsCommonSectionController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CptModifiersModalController = /** @class */ (function () {
            function CptModifiersModalController(options, $mdDialog, IndicatorsService, DiagnosesService, $q) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.DiagnosesService = DiagnosesService;
                this.$q = $q;
                this.modifiers = [{ code: 12345 }, { code: 678910 }, { code: 879384 }, { code: 22389 }];
                this.$onInit();
            }
            CptModifiersModalController.prototype.$onInit = function () { };
            CptModifiersModalController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            CptModifiersModalController.prototype.save = function () {
                this.$mdDialog.hide(this.modifier);
            };
            CptModifiersModalController.$inject = ['options', '$mdDialog', 'IndicatorsService', 'DiagnosesService', '$q'];
            return CptModifiersModalController;
        }());
        angular.module('consolo').controller('CptModifiersModalController', CptModifiersModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CptProcedureModalController = /** @class */ (function () {
            function CptProcedureModalController($mdDialog, IndicatorsService, DiagnosesService, $q, Session, CurrentPatient, CPTCodingService) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.DiagnosesService = DiagnosesService;
                this.$q = $q;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.CPTCodingService = CPTCodingService;
                this.preElections = [
                    { preElection: 'Pre-Election 1' },
                    { preElection: 'Pre-Election 2' },
                    { preElection: 'Pre-Election 3' },
                    { preElection: 'Pre-Election 4' },
                ];
                this.selectedCode = null;
                this.$onInit();
                this.user = this.Session.get().user;
                if (this.procedure === null) {
                    this.procedure = {
                        role_id: this.user.default_role.id,
                        effective_date: new Date(),
                        preElection: null,
                        icd10_health_conditions: [],
                        modifiers: [],
                    };
                    IndicatorsService.getIndicatorBeforeOrOn(CurrentPatient.patient_id, this.procedure.effective_date).then(function (indicator) {
                        if (indicator) {
                            _this.healthConditionsToSelect = indicator.icd10_health_conditions;
                        }
                    });
                }
                else {
                    this.selectedCode = this.procedure.cpt_code.code;
                }
                console.log(this.procedure);
            }
            CptProcedureModalController.prototype.$onInit = function () {
                this.getIcdTabularSearch();
                this.getCurrentDiagnoses();
            };
            CptProcedureModalController.prototype.logProcedure = function () {
                console.log(this.procedure);
            };
            CptProcedureModalController.prototype.searchCPTCodes = function (q) {
                if (q && q.length) {
                    return this.CPTCodingService.getAllCPTCodes(q, null, null, this.procedure.effective_date).then(function (cptCodes) {
                        return cptCodes;
                    });
                }
                else {
                    return [];
                }
            };
            CptProcedureModalController.prototype.getCurrentDiagnoses = function () {
                var _this = this;
                this.DiagnosesService.getPatientDiagnoses(this.patientId)
                    .then(function (response) {
                    _this.currentDiagnoses = response.data;
                    console.log(response);
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            CptProcedureModalController.prototype.addDiagnosis = function (patientId, diagnosisObj) {
                return this.DiagnosesService.addDiagnosis(patientId, diagnosisObj);
            };
            CptProcedureModalController.prototype.getIcdTabularSearch = function () {
                var _this = this;
                this.IndicatorsService.getIcdTabularSearch()
                    .then(function (response) {
                    console.log('TABULAR SEARCH SUCCESS ===================');
                    console.log(response);
                    _this.icdTabularSearchTree = response.data;
                })
                    .catch(function (error) {
                    console.log('TABULAR SEARCH ERROR ===================');
                    console.log(error);
                });
            };
            CptProcedureModalController.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            CptProcedureModalController.prototype.icd10AutocompleteLookup = function (query) {
                return this.IndicatorsService.lookupIcd10(query);
            };
            CptProcedureModalController.prototype.showDiagnosisSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    multiple: true,
                    locals: {
                        // indicator: this.indicator,
                        icdTabularSearchTree: this.icdTabularSearchTree,
                    },
                })
                    .then(function (selectedIcd10) {
                    console.log(selectedIcd10);
                    var icd10 = {
                        icd10_id: selectedIcd10.id,
                        patient_id: _this.patientId,
                        position: _this.currentDiagnoses.length,
                    };
                    _this.addDiagnosis(_this.patientId, icd10)
                        .then(function (response) {
                        console.log(response);
                        _this.getCurrentDiagnoses();
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                    // if (this.lcdWorksheet.icd10_health_conditions.length === 0) {
                    //   this.lcdWorksheet.icd10_health_conditions = [icd10];
                    // } else {
                    //   this.lcdWorksheet.icd10_health_conditions.push(icd10);
                    //   this.lcdWorksheet.icd10_health_conditions = [].concat(this.lcdWorksheet.icd10_health_conditions);
                    // }
                    // console.log(this.lcdWorksheet.icd10_health_conditions);
                });
            };
            CptProcedureModalController.prototype.isDiagnosisChecked = function (icd10) {
                if (this.procedure.icd10_health_conditions.map(function (icd10) { return icd10.code; }).indexOf(icd10.code) !== -1) {
                    return true;
                }
                else {
                    return false;
                }
            };
            CptProcedureModalController.prototype.addDiagnosisToCPT = function (icd10) {
                console.log(this.procedure.icd10_health_conditions);
                if (this.procedure.icd10_health_conditions.map(function (icd10) { return icd10.code; }).indexOf(icd10.code) === -1) {
                    this.procedure.icd10_health_conditions.push(icd10);
                }
                else {
                    console.log(icd10);
                    console.log('EXISTS++++++++++++++');
                }
            };
            CptProcedureModalController.prototype.openModifierModal = function (ev) {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    multiple: true,
                })
                    .then(function (selectedModifier) {
                    _this.procedure.modifiers.push(selectedModifier);
                });
            };
            CptProcedureModalController.prototype.cptCodeChanged = function () {
                var selectedCodeIndex = this.codes
                    .map(function (code) {
                    return code.code;
                })
                    .indexOf(this.selectedCode);
                var selectedCode = this.codes[selectedCodeIndex];
                this.procedure.cpt_code = selectedCode;
                // this.procedure.cpt_code = this.codes[selectedCodeIndex];
                console.log(selectedCodeIndex);
                console.log(this.codes[selectedCodeIndex]);
            };
            CptProcedureModalController.prototype.close = function () {
                this.$mdDialog.cancel(null);
            };
            CptProcedureModalController.prototype.save = function () {
                this.$mdDialog.hide(this.procedure);
            };
            CptProcedureModalController.$inject = [
                '$mdDialog',
                'IndicatorsService',
                'DiagnosesService',
                '$q',
                'SessionService',
                'CurrentPatient',
                'CPTCodingService',
            ];
            return CptProcedureModalController;
        }());
        angular.module('consolo').controller('CptProcedureModalController', CptProcedureModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var FamilyDiagnosisHistoryModalController = /** @class */ (function () {
            function FamilyDiagnosisHistoryModalController($mdDialog, familyMember, diagnosisOptions, diagnosisDate) {
                this.$mdDialog = $mdDialog;
                //working with a family member
                this.familyMember = familyMember;
                this.diagnosisOptions = diagnosisOptions;
                this.date_of_diagnosis = diagnosisDate;
                this.diagnoses = [];
                this.familyDiagnosisHistoryLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    columns: [
                        { title: 'Diagnosis', field: 'name', canSort: true },
                        {
                            title: 'Recorded Date',
                            field: 'date_of_diagnosis',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                    ],
                };
                this.addDiagnosis(null, null);
            }
            FamilyDiagnosisHistoryModalController.prototype.addDiagnosis = function (name, date_of_diagnosis) {
                this.diagnoses.push({
                    family_id: this.familyMember.id,
                    name: name,
                    date_of_diagnosis: date_of_diagnosis,
                });
            };
            FamilyDiagnosisHistoryModalController.prototype.removeDiagnosis = function ($event, item) {
                var index = this.diagnoses.indexOf(item);
                this.diagnoses.splice(index, 1);
                return;
            };
            FamilyDiagnosisHistoryModalController.prototype.generateUUID = function () {
                var d = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
                });
                return uuid;
            };
            FamilyDiagnosisHistoryModalController.prototype.closeDialog = function (save) {
                var _this = this;
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                else {
                    this.diagnoses.forEach(function (element) {
                        console.log('closeDialog in modal controller');
                        console.log(element);
                        if (!element.name) {
                            _this.removeDiagnosis(null, element);
                        }
                    });
                    this.$mdDialog.hide(this.diagnoses);
                }
            };
            FamilyDiagnosisHistoryModalController.$inject = ['$mdDialog', 'familyMember', 'diagnosisOptions', 'diagnosisDate'];
            return FamilyDiagnosisHistoryModalController;
        }());
        angular
            .module('consolo')
            .controller('FamilyDiagnosisHistoryModalController', FamilyDiagnosisHistoryModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var MultipleSectionModalController = /** @class */ (function () {
            function MultipleSectionModalController($mdDialog, sections, style) {
                this.$mdDialog = $mdDialog;
                this.sections = sections;
                this.style = style;
                if (style === 'delete') {
                    this.header = 'Delete Section';
                    this.buttonText = 'Delete';
                }
                else {
                    this.header = 'Add Section';
                    this.buttonText = 'Add';
                }
            }
            MultipleSectionModalController.prototype.close = function () {
                this.$mdDialog.hide(this.selectedSection);
            };
            MultipleSectionModalController.prototype.hide = function () {
                this.$mdDialog.hide();
            };
            MultipleSectionModalController.$inject = ['$mdDialog', 'sections', 'style'];
            return MultipleSectionModalController;
        }());
        angular
            .module('consolo')
            .controller('MultipleSectionModalController', MultipleSectionModalController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        var PatientTimeDialogController = /** @class */ (function () {
            function PatientTimeDialogController($mdDialog, PatientTimeService, logger, options, chartStore) {
                // time_units ||= link_to "None", new_user_patient_time_path(chart.user, {
                // patient_time: { clinical_chart_id: chart.id, effective_date: chart.effective_date
                //, in_date: chart.effective_date,
                //, out_date: chart.effective_date  } } )
                this.$mdDialog = $mdDialog;
                this.PatientTimeService = PatientTimeService;
                this.logger = logger;
                this.options = options;
                this.chartStore = chartStore;
                this.loading = false;
                this.loggerText = 'Missed Visit Saved';
                this.careTypes = options.get('clinical_care_types');
                this.effective_date = new Date(new Date(this.chartStore.model.effective_date).toDateString());
                if (!this.chartStore.model.patient_time) {
                    this.chartStore.model.patient_time = {
                        clinical_chart_id: this.chartStore.model.id,
                        effective_date: this.chartStore.model.effective_date,
                        in_dateTime: this.effective_date,
                        out_dateTime: this.effective_date,
                        clinical_trip_stop_point_id: this.chartStore.model.clinical_trip_stop_point_id,
                        user_id: this.chartStore.model.user_id,
                    };
                }
                this.formModel = this.chartStore.model.patient_time;
            }
            PatientTimeDialogController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            PatientTimeDialogController.prototype.cancelDialog = function () {
                this.$mdDialog.cancel();
            };
            PatientTimeDialogController.prototype.save = function () {
                var _this = this;
                this.loading = true;
                var save = this.formModel.id
                    ? this.PatientTimeService.update
                    : this.PatientTimeService.create;
                save(this.formModel, true)
                    .then(function (pt) {
                    _this.logger.success('Patient Time saved');
                    _this.$mdDialog.hide(pt);
                })
                    .catch(function (e) {
                    _this.logger.error(e);
                    console.log(e);
                })
                    .finally(function () {
                    _this.loading = false;
                });
            };
            PatientTimeDialogController.prototype.saveDisabled = function () {
                if (this.loading) {
                    return true;
                }
                return (new Date(this.formModel.in_datetime).toDateString() !==
                    this.effective_date.toDateString() ||
                    this.formModel.in_datetime >= this.formModel.out_datetime);
            };
            PatientTimeDialogController.$inject = ['$mdDialog', 'PatientTimeService', 'logger', 'options', 'chartStore'];
            return PatientTimeDialogController;
        }());
        angular.module('consolo').controller('PatientTimeDialogController', PatientTimeDialogController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ClinicalChartsSectionPickerModalController = /** @class */ (function () {
            function ClinicalChartsSectionPickerModalController(CurrentPatient, options, Session, $q, $state, ClinicalChartEntry, $mdDialog, HijackFindService, $scope, ChartingTemplateService, section, encounterType, missingSections) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.options = options;
                this.Session = Session;
                this.$q = $q;
                this.$state = $state;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.$mdDialog = $mdDialog;
                this.HijackFindService = HijackFindService;
                this.$scope = $scope;
                this.ChartingTemplateService = ChartingTemplateService;
                this.section = section;
                this.encounterType = encounterType;
                this.missingSections = missingSections;
                this.childSectionsCopy = [];
                this.addText = 'Add Screens';
                this.currentlyActiveSections = [];
                this.textFilter = '';
                this.alternateView = false;
                this.HijackFindService.register('#selectorQuickFilter', '#selectorQuickFilter');
                this.$scope.$on('$destroy', function () {
                    _this.HijackFindService.unregister();
                });
                this.fieldTemplate = ChartingTemplateService.getFieldDefinitions();
                var hasActive = false;
                if (this.missingSections) {
                }
                else {
                    if (this.section.title.indexOf('Screens') >= 0 ||
                        this.section.title === 'Additional Charting Options') {
                        this.title = this.section.title;
                    }
                    else {
                        this.title = this.section.title + ' Screens';
                    }
                    for (var _i = 0, _a = this.section.childSections; _i < _a.length; _i++) {
                        var s = _a[_i];
                        s.tempActive = s.active;
                        if (s.active) {
                            hasActive = true;
                            this.currentlyActiveSections.push(s);
                        }
                    }
                    if (hasActive) {
                        this.addText = 'Update Screens';
                    }
                    this.screen = this.section;
                }
                this.textFilter = '';
                this.screenSearch();
            }
            ClinicalChartsSectionPickerModalController.prototype.$onInit = function () { };
            ClinicalChartsSectionPickerModalController.prototype.$onDestroy = function () { };
            ClinicalChartsSectionPickerModalController.prototype.close = function (newSections) {
                this.$mdDialog.hide(newSections);
            };
            ClinicalChartsSectionPickerModalController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            ClinicalChartsSectionPickerModalController.prototype.addSections = function () {
                var hasActive = false;
                var newSections = [];
                for (var _i = 0, _a = this.section.childSections; _i < _a.length; _i++) {
                    var s = _a[_i];
                    s.active = s.tempActive;
                    delete s.tempActive;
                    delete s.tempHide;
                    delete s.tempFields;
                    delete s.tempAltHide;
                    if (s.active) {
                        if (this.currentlyActiveSections.indexOf(s) < 0) {
                            newSections.push(s);
                        }
                        hasActive = true;
                    }
                }
                this.section.active = this.section.expanded = hasActive;
                this.close(newSections);
            };
            ClinicalChartsSectionPickerModalController.prototype.screenSearch = function () {
                var fields;
                for (var _i = 0, _a = this.section.childSections; _i < _a.length; _i++) {
                    var child = _a[_i];
                    if (this.alternateView) {
                        if (this.textFilter.length) {
                            child.tempAltHide = true;
                            child.tempFields = [];
                            if (child.title.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0) {
                                child.tempAltHide = false;
                            }
                            for (var _b = 0, _c = this.fieldTemplate[child.model]; _b < _c.length; _b++) {
                                var section = _c[_b];
                                for (var _d = 0, _e = section.fields; _d < _e.length; _d++) {
                                    var field = _e[_d];
                                    if (field.label.toLowerCase().indexOf(this.textFilter.toLowerCase()) >= 0) {
                                        child.tempFields.push(field.label);
                                        child.tempAltHide = false;
                                    }
                                }
                            }
                        }
                        else {
                            child.tempAltHide = false;
                        }
                    }
                    else {
                        if (child.title.toLowerCase().indexOf(this.textFilter.toLowerCase()) < 0) {
                            child.tempHide = true;
                        }
                        else {
                            child.tempHide = false;
                        }
                    }
                }
            };
            // section: any;
            // encounterType: any;
            ClinicalChartsSectionPickerModalController.$inject = [
                'CurrentPatient',
                'options',
                'SessionService',
                '$q',
                '$state',
                'ClinicalChartEntry',
                '$mdDialog',
                'hijackFindService',
                '$scope',
                'ChartingTemplateService',
                'section',
                'encounterType',
                'missingSections',
            ];
            return ClinicalChartsSectionPickerModalController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsSectionPickerModalController', ClinicalChartsSectionPickerModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

// module consolo.controllers {
//   'use strict';
//   class VisitTaskListController extends consolo.controllers.BaseCustomChartSectionController {
//     sections = this.generateInstructionSections();
//     isLoading = false;
//     static $inject = ['$filter', '$parse', '$scope', '$stateParams', 'cache', 'chartStore', 'SectionManager',
//       'EncounterType', 'formModel', 'options', 'logger', 'VisitTaskList', 'PatientMainService', 'ChartingFormManager',
//       'SessionService'];
//     constructor(
//       protected $filter,
//       protected $parse,
//       protected $scope,
//       protected $stateParams,
//       protected cache,
//       protected chartStore,
//       protected SectionManager,
//       protected EncounterType,
//       protected formModel,
//       protected options,
//       protected logger: consolo.common.Logger,
//       private visitTaskList,
//       private patientMainService,
//       private FormManager,
//       private Session: csg.session.SessionService<consolo.common.ISessionData>
//     ) {
//       super($filter, $parse, $scope, $stateParams, cache, chartStore,
//         EncounterType, SectionManager, formModel, SectionManager.currentSection);
//       this.isLoading = true;
//       console.log('chartStore', this.chartStore);
//       console.log('formModel', this.formModel);
//       const instructionsPromise = !this.chartStore.model.hasOwnProperty('visit_task_lists_instructions') ?
//         this.patientMainService.getClinicalSummary(this.patientId).then(resp => {
//           this.chartStore.model.visit_task_lists_instructions = { ...{ open: false }, ...resp.data };
//         }) : Promise.resolve();
//       const tasksPromise = !(this.formModel && this.formModel.length && (this.formModel[0].id || this.formModel[0].assignment_id)) ?
//         this.visitTaskList.loadByPatientId(this.patientId).$promise.then(visitTasks => {
//           const today = new Date();
//           visitTasks.forEach((v, i) => {
//             const visitTask = {
//               open: false,
//               patient_id: v.patient_id,
//               assignment_id: v.assignment_id,
//               user_id: this.Session.getUser().id,
//               effective_date: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
//               description: v.description,
//               recurring_task_actions: v.tasks.map(t => {
//                 return {
//                   recurring_task_id: t.id,
//                   description: t.description,
//                   completed: false,
//                   acknowledged: false
//                 };
//               })
//             };
//             if (i === 0 && this.formModel[0]) {
//               this.formModel[i] = (Object as any).assign(this.formModel[i], visitTask);
//             } else {
//               this.formModel.push(visitTask);
//             }
//           });
//         }) : Promise.resolve();
//       Promise.all([instructionsPromise, tasksPromise].map(p => p.catch(e => e)))
//         .then(() => this.isLoading = false);
//       if (!this.chartStore.model.visit_task_lists_instructions) {
//         this.chartStore.model.visit_task_lists_instructions = { open: false };
//       }
//       if (!this.formModel) {
//         this.formModel = [];
//       }
//     }
//     getCompletedCount(item) {
//       return item.recurring_task_actions.filter(t => t.completed).length;
//     }
//     onCompleted(task) {
//       if (task.completed) {
//         task.acknowledged = true;
//       }
//     }
//     generateInstructionSections() {
//       const sections = <any>{};
//       // nursing summary
//       sections.nursingSummary = {};
//       const nursingSummarySubsectionLookup = this.subsectionLookup('nursing_summaries');
//       sections.nursingSummary.imminenceOfDeath =
//         nursingSummarySubsectionLookup('Imminence of Death');
//       sections.nursingSummary.nursingSummaryNotes =
//         nursingSummarySubsectionLookup('Nursing Summary Notes');
//       sections.nursingSummary.understandOwnCare =
//         nursingSummarySubsectionLookup('Patient Ability to Understand and Participate in Own Care');
//       sections.nursingSummary.familyWillingnessToCare =
//         nursingSummarySubsectionLookup('Family/Representative/CG Willingness and Capability to Care For Patient');
//       sections.nursingSummary.visitFrequencyDiscussion =
//         nursingSummarySubsectionLookup('Visit Frequency Discussion');
//       sections.nursingSummary.dischargePlanning =
//         nursingSummarySubsectionLookup('Discharge Planning');
//       // musculoskeletal
//       sections.musculoskeletal = {};
//       const musculoskeletalSubsectionLookup = this.subsectionLookup('body_systems_activity_musculoskeletal_findings');
//       sections.musculoskeletal.general =
//         musculoskeletalSubsectionLookup('General');
//       sections.musculoskeletal.complaintsNoReport =
//         musculoskeletalSubsectionLookup('Complaints or No Report');
//       sections.musculoskeletal.activitiesDailyLiving =
//         musculoskeletalSubsectionLookup('Activities in Daily Living');
//       sections.musculoskeletal.otherActivities =
//         musculoskeletalSubsectionLookup('Other Activities');
//       sections.musculoskeletal.mobility =
//         musculoskeletalSubsectionLookup('Mobility');
//       sections.musculoskeletal.developmentalLevelOfFunction =
//         musculoskeletalSubsectionLookup('Developmental/Pediatric Level of Function');
//       sections.musculoskeletal.other =
//         musculoskeletalSubsectionLookup('Other');
//       sections.musculoskeletal.mobilityEquipment =
//         musculoskeletalSubsectionLookup('Patient Used Mobility Equipment and Devices');
//       sections.musculoskeletal.summary =
//         musculoskeletalSubsectionLookup('Summary');
//       return sections;
//     }
//     subsectionLookup(sectionName) {
//       const section = this.FormManager.getSections()[sectionName];
//       const emptySubsection = { fields: [] };
//       return label => section.find(s => s.label === label) || emptySubsection;
//     }
//     // section names are keys from this.instructions
//     nursingSummaryFieldLookup = this.fieldLookup('nursing_summary');
//     nursingSummarySubsectionHasData = this.subsectionHasData('nursing_summary');
//     generateNursingSummarySubsectionString = this.generateSubsectionString('nursing_summary');
//     musculoskeletalFieldLookup = this.fieldLookup('body_systems_activity_musculoskeletal_finding');
//     musculoskeletalSubsectionHasData = this.subsectionHasData('body_systems_activity_musculoskeletal_finding');
//     generateMusculoskeletalSubsectionString = this.generateSubsectionString('body_systems_activity_musculoskeletal_finding');
//     private fieldLookup(section) {
//       return field => this.chartStore.model.visit_task_lists_instructions[section][this.mapField(field)];
//     }
//     private subsectionHasData(section) {
//       return fields => !!fields.filter(item =>
//         this.chartStore.model.visit_task_lists_instructions[section].hasOwnProperty(this.mapField(item.field))).length;
//     }
//     private generateSubsectionString(section) {
//       const fieldFormatter = this.getFieldFormatter(section);
//       return items => items.reduce((acc, curr, i) => {
//         if (!this.chartStore.model.visit_task_lists_instructions[section].hasOwnProperty(this.mapField(curr.field))) {
//           return '';
//         }
//         return i === 0 ? `${fieldFormatter(curr)} ` : `${acc}, ${fieldFormatter(curr)}`;
//       }, '');
//     }
//     private getFieldFormatter(section) {
//       return item => `${item.label} (${this.chartStore.model.visit_task_lists_instructions[section][this.mapField(item.field)]})`;
//     }
//     private mapField(field) {
//       let res;
//       // if the instructions field name differs from the form field name we need to reconcile
//       switch (field) {
//         case 'prognosis_terminal':
//         case 'dp_goals_met':
//         case 'dp_patient_expires':
//         case 'dp_other':
//         case 'dp_5_day_notice':
//         case 'dp_transfer_form_complete':
//         case 'dp_revocation_form_complete':
//         case 'dp_continuity_of_care_transition':
//         case 'activity_no_report':
//         case 'activity_patient_complaint':
//         case 'activity_alteration_in_endurance':
//         case 'activity_poor_coordination_and_balance':
//         case 'activity_gait_disturbance':
//         case 'activity_bed_or_chair_bound':
//         case 'activity_plegia':
//         case 'activity_missing_limbs':
//         case 'activity_history_of_fractures':
//         case 'activity_contractures':
//         case 'activity_cane':
//         case 'activity_hoyer_lift':
//         case 'activity_walker':
//         case 'activity_crutches':
//         case 'activity_recliner':
//         case 'activity_wheelchair':
//         case 'activity_shower_seat':
//         case 'activity_bedside_commode':
//         case 'activity_adjustable_bed':
//         case 'activity_toilet_seat_riser':
//         case 'activity_bed_rails':
//         case 'activity_trapeze':
//         case 'activity_other_mobility_equipment':
//           res = `note_${field}`;
//           break;
//         default:
//           res = field;
//       }
//       // when there's an id stored we want to show the description and the naming pattern allows us to do a simple replace
//       if (res.endsWith('_id')) {
//         res = `${res.substring(0, res.length - 3)}_description`;
//       }
//       return res;
//     }
//   }
//   angular.module('consolo')
//     .controller('ClinicalChartsVisitTaskListController', VisitTaskListController);
// }

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ClinicalChartsPatientSearchSidebarController = /** @class */ (function () {
            function ClinicalChartsPatientSearchSidebarController($state, cache, Patient, Lookup, ChartDraftsSearchFilterService) {
                this.$state = $state;
                this.cache = cache;
                this.Patient = Patient;
                this.Lookup = Lookup;
                this.ChartDraftsSearchFilterService = ChartDraftsSearchFilterService;
                this.activate();
            }
            ClinicalChartsPatientSearchSidebarController.prototype.activate = function () {
                this.assignedPatients = this.cache(this.Patient, 'patients', {
                    maxAge: 10,
                    force: true,
                }).assigned();
            };
            ClinicalChartsPatientSearchSidebarController.prototype.addChartEntry = function (patient) {
                this.$state.go('patients.clinical-chart-entry-new.section', {
                    patientId: patient.id,
                    sectionName: 'general',
                });
            };
            ClinicalChartsPatientSearchSidebarController.prototype.goToPatient = function (patient) {
                this.$state.go('patients.clinical-chart', { patientId: patient.id });
            };
            ClinicalChartsPatientSearchSidebarController.prototype.filterPatients = function (term) {
                var _this = this;
                return this.cache(this.Lookup, 'lookup', { force: true, encrypt: true })
                    .fetch('patients', {
                    limit: -1,
                    active: true,
                    deceased: true,
                    pending_never_admitted: true,
                })
                    .then(function (results) {
                    if (results) {
                        var lowerTerm = term.toLowerCase();
                        return results.filter(function (result) {
                            return result.name.toLowerCase().search(lowerTerm) > -1;
                        });
                    }
                    else {
                        return _this.Lookup.patients({ q: term }).$promise.then(function (results) {
                            return results;
                        });
                    }
                }, function () {
                    console.warn('Unable to fetch cached results');
                    return _this.Lookup.patients({ q: term }).$promise.then(function (results) {
                        return results;
                    });
                });
            };
            ClinicalChartsPatientSearchSidebarController.$inject = [
                '$state',
                'cache',
                'Patient',
                'Lookup',
                'ChartDraftsSearchFilterService',
            ];
            return ClinicalChartsPatientSearchSidebarController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsPatientSearchSidebarController', ClinicalChartsPatientSearchSidebarController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var charting;
    (function (charting) {
        'use strict';
        var ClinicalChartsPatientSidebarController = /** @class */ (function () {
            function ClinicalChartsPatientSidebarController($state, ChartingSectionManager, currentPatient) {
                this.$state = $state;
                this.ChartingSectionManager = ChartingSectionManager;
                this.currentPatient = currentPatient;
                this.patient = currentPatient;
                this.activate();
            }
            ClinicalChartsPatientSidebarController.prototype.activate = function () { };
            ClinicalChartsPatientSidebarController.prototype.addChartEntry = function (patient) {
                this.$state.go('patients.clinical-chart-entry-new.section', {
                    patientId: patient.id,
                    sectionName: 'general',
                });
            };
            ClinicalChartsPatientSidebarController.prototype.closeNav = function () {
                this.ChartingSectionManager.closeNav();
            };
            ClinicalChartsPatientSidebarController.prototype.navigateTo = function (chartEntry) {
                if (chartEntry.model.id) {
                    this.$state.go('patients.clinical-chart-entry-edit.section', {
                        patientId: chartEntry.model.patient_id,
                        chartEntryId: chartEntry.model.id,
                        sectionName: 'general',
                    });
                }
                else {
                    this.$state.go('patients.clinical-chart-entry-new.section', {
                        patientId: chartEntry.model.patient_id,
                        sectionName: 'general',
                    });
                }
            };
            ClinicalChartsPatientSidebarController.$inject = ['$state', 'ChartingSectionManager', 'currentPatient'];
            return ClinicalChartsPatientSidebarController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsPatientSidebarController', ClinicalChartsPatientSidebarController);
    })(charting = consolo.charting || (consolo.charting = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var app = angular.module('consolo');
        var ConsoloAccordion = /** @class */ (function () {
            function ConsoloAccordion() {
                this.restrict = 'E';
                this.replace = true;
                this.templateUrl = '/y/templates/common/accordion/consolo-accordion.html';
                this.transclude = true;
                this.scope = {
                    autoOpen: '=',
                };
            }
            return ConsoloAccordion;
        }());
        common.ConsoloAccordion = ConsoloAccordion;
        var ConsoloAccordionPane = /** @class */ (function () {
            function ConsoloAccordionPane() {
                this.restrict = 'E';
                this.replace = true;
                this.transclude = true;
                this.templateUrl = '/y/templates/common/accordion/consolo-accordion-pane.html';
                this.controller = 'ConsoloAccordionPaneController';
                this.controllerAs = 'Ctrl';
                this.scope = {
                    paneLabel: '=?',
                };
                this.link = function ($scope, elem, attr) {
                    if ($scope.$parent.$parent.autoOpen !== undefined) {
                        $scope.isOpen = true;
                    }
                    else {
                        $scope.isOpen =
                            attr.isOpen === '' || attr.isOpen === 'true' || attr.isOpen === 'is-open' ? true : false;
                    }
                    $scope.paneLabel;
                };
            }
            ConsoloAccordionPane.prototype.contructor = function () { };
            return ConsoloAccordionPane;
        }());
        common.ConsoloAccordionPane = ConsoloAccordionPane;
        var ConsoloAccordionPaneController = /** @class */ (function () {
            function ConsoloAccordionPaneController($scope, $animate, $element, attr) {
                this.$scope = $scope;
            }
            ConsoloAccordionPaneController.prototype.togglePane = function () {
                this.$scope.isOpen = !this.$scope.isOpen;
            };
            ConsoloAccordionPaneController.$inject = ['$scope', '$animate'];
            return ConsoloAccordionPaneController;
        }());
        common.ConsoloAccordionPaneController = ConsoloAccordionPaneController;
        app
            .directive('consoloAccordion', function () { return new consolo.common.ConsoloAccordion(); })
            .directive('consoloAccordionPane', function () { return new consolo.common.ConsoloAccordionPane(); })
            .controller('ConsoloAccordionPaneController', consolo.common.ConsoloAccordionPaneController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AddressGeocoderService = /** @class */ (function () {
            function AddressGeocoderService($http) {
                this.$http = $http;
                this.geocoderApi = '/api/v1/geocode';
            }
            AddressGeocoderService.prototype.get = function (address) {
                return this.$http.get(this.geocoderApi, { params: address });
            };
            AddressGeocoderService.$inject = ['$http'];
            return AddressGeocoderService;
        }());
        services.AddressGeocoderService = AddressGeocoderService;
        angular.module('consolo').service('AddressGeocoder', AddressGeocoderService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var CheckAddressComponent = /** @class */ (function () {
        function CheckAddressComponent() {
            this.bindings = {
                address: '&',
                onConfirm: '&',
            };
            this.template = "\n        <md-button class=\"md-primary\" flex=\"100\" ng-click=\"$ctrl.checkAddress()\">\n          <md-icon class=\"material-icons\">check</md-icon>\n          Check Address\n        </md-button>";
            this.controller = CheckAddressController;
        }
        return CheckAddressComponent;
    }());
    var CheckAddressController = /** @class */ (function () {
        function CheckAddressController(addressGeocoder, $mdDialog, $scope) {
            this.addressGeocoder = addressGeocoder;
            this.$mdDialog = $mdDialog;
            this.$scope = $scope;
        }
        CheckAddressController.prototype.checkAddress = function () {
            var _this = this;
            var addr = this.address();
            if (addr.address_1) {
                this.addressGeocoder.get(this.address()).then(function (response) {
                    _this.showConfirmAddressDialog(response.data);
                });
            }
        };
        CheckAddressController.prototype.showConfirmAddressDialog = function (address) {
            this.$scope.onConfirm = this.onConfirm;
            this.$scope.address = address;
            this.$mdDialog.show({
                clickOutsideToClose: false,
                preserveScope: true,
                template: "\n        <md-dialog>\n          <md-toolbar>\n            <div class=\"md-toolbar-tools\">\n              Does the address information look correct?\n            </div>\n          </md-toolbar>\n          <md-dialog-content>\n            <div class=\"text-display-container layout-padding\">\n              {{vm.address.address_1}}\n              <br>\n              {{vm.address.city}} {{vm.address.state}} {{vm.address.zipcode}}\n              <br>\n              {{vm.address.county_name}}\n            </div>\n          </md-dialog-content>\n          <md-dialog-actions>\n            <md-button ng-click=\"vm.onConfirmClicked()\">Yes</md-button>\n            <md-button ng-click=\"vm.onCancel()\">No</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n        ",
                controller: 'ConfirmAddressController as vm',
                locals: { onConfirm: this.$scope.onConfirm, address: this.$scope.address },
            });
        };
        CheckAddressController.$inject = ['AddressGeocoder', '$mdDialog', '$scope'];
        return CheckAddressController;
    }());
    var ConfirmAddressController = /** @class */ (function () {
        function ConfirmAddressController($mdDialog, address, onConfirm) {
            this.$mdDialog = $mdDialog;
            this.address = address;
            this.onConfirm = onConfirm;
        }
        ConfirmAddressController.prototype.onCancel = function () {
            this.$mdDialog.hide();
        };
        ConfirmAddressController.prototype.onConfirmClicked = function () {
            this.onConfirm({ address: this.address });
            this.$mdDialog.hide();
        };
        ConfirmAddressController.$inject = ['$mdDialog', 'address', 'onConfirm'];
        return ConfirmAddressController;
    }());
    angular
        .module('consolo')
        .controller('ConfirmAddressController', ConfirmAddressController)
        .component('consoloAddressChecker', new CheckAddressComponent());
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        angular.module('consolo').component('consoloSystemAlerts', {
            bindings: {},
            templateUrl: '/y/templates/common/alerts/system-alerts.html',
            controller: 'SystemAlertsController as $ctrl',
        });
        var SystemAlertsController = /** @class */ (function () {
            function SystemAlertsController(SystemAlerts) {
                this.SystemAlerts = SystemAlerts;
                this.alerts = [];
                this.alerts = SystemAlerts.unread;
            }
            SystemAlertsController.prototype.dismiss = function (alert) {
                this.SystemAlerts.dismiss(alert);
            };
            SystemAlertsController.$inject = ['SystemAlertsService'];
            return SystemAlertsController;
        }());
        common.SystemAlertsController = SystemAlertsController;
        angular.module('consolo').controller('SystemAlertsController', SystemAlertsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var SystemAlertsService = /** @class */ (function () {
            function SystemAlertsService(Hosts, $http) {
                this.Hosts = Hosts;
                this.$http = $http;
                this.MAX_HISTORY = 100;
                // All alerts that aren't expired
                this.all = [];
                // All alerts that haven't been dismissed yet
                this.unread = [];
                this.extensionName = 'SystemAlert';
            }
            SystemAlertsService.prototype.onSessionVisible = function (sess) {
                this.userId = sess.user.uuid;
                this.refresh(sess);
            };
            SystemAlertsService.prototype.onSessionClear = function () {
                this.userId = null;
                this.all.splice(0, this.all.length);
                this.unread.splice(0, this.unread.length);
            };
            // Dismiss the given alert for the given user
            SystemAlertsService.prototype.dismiss = function (alert) {
                this.unread.splice(this.unread.indexOf(alert), 1);
                var dismissed = this.getDismissed();
                dismissed.unshift({ id: alert.id, ts: new Date().toISOString() });
                var history = dismissed.slice(0, this.MAX_HISTORY);
                try {
                    var raw = JSON.stringify(history);
                    localStorage.setItem("dismissed-system-alerts:" + this.userId, raw);
                }
                catch (e) {
                    console.log('Error saving dismissed system alerts');
                    console.log(e);
                }
            };
            SystemAlertsService.prototype.refresh = function (sess) {
                var _this = this;
                return this.fetch(sess).then(function (alerts) {
                    _this.all.splice(0, _this.all.length);
                    alerts.forEach(function (x) { return _this.all.push(x); });
                    var dismissedIds = _this.getDismissed().map(function (x) {
                        return x.id;
                    });
                    _this.unread.splice(0, _this.unread.length);
                    alerts.forEach(function (x) {
                        if (dismissedIds.indexOf(x.id) === -1) {
                            _this.unread.push(x);
                        }
                    });
                });
            };
            // Fetch alerts from the API
            SystemAlertsService.prototype.fetch = function (sess) {
                return this.$http
                    .get(sess.hosts.manager + "/api/v1/login_messages", {
                    params: {
                        app: 'emr',
                        environment: this.Hosts.env,
                        service_line: sess.agency.service_line,
                        touch: false,
                    },
                })
                    .then(function (resp) {
                    return resp.data.map(function (m) {
                        var icon;
                        switch (m.category) {
                            case 'warning':
                                icon = 'alert:warning';
                                break;
                            default:
                                icon = 'action:info';
                                break;
                        }
                        return {
                            id: m.id,
                            subject: m.subject,
                            html_body: m.html_body,
                            category: m.category ? m.category : 'info',
                            icon: icon,
                        };
                    });
                });
            };
            SystemAlertsService.prototype.getDismissed = function () {
                try {
                    var raw = localStorage.getItem("dismissed-system-alerts:" + this.userId) || '[]';
                    return JSON.parse(raw);
                }
                catch (e) {
                    console.log('Error fetching dismissed system alerts');
                    console.log(e);
                    return [];
                }
            };
            SystemAlertsService.$inject = ['Hosts', '$http'];
            return SystemAlertsService;
        }());
        common.SystemAlertsService = SystemAlertsService;
        angular.module('consolo').service('SystemAlertsService', SystemAlertsService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').factory('Assignment', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/assignments/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            current: {
                url: '/api/v1/assignments/current',
                method: 'GET',
            },
        });
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('BodySite', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/body_sites', null, {
            batch: {
                method: 'POST',
                url: '/api/v1/body_sites/batch',
                isArray: true,
            },
        });
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('CarePlanProblemEntry', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/care_plan_problems/:id', { id: '@id' }, {
            update: { method: 'PATCH', isArray: false },
            list: {
                method: 'POST',
                url: '/api/v1/care_plan_problems/list',
                isArray: true,
            },
        });
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('CarePlanTemplate', ['$resource', Model]);
    function Model($resource) {
        return $resource('/api/v1/care_plan_templates/:id');
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('ClinicalChartEntry', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/clinical_charts/:id', { id: '@id' }, {
            addendum: {
                method: 'POST',
                url: '/api/v1/clinical_charts/:id/addendum',
            },
            update: { method: 'PATCH' },
            tagAsComprehensive: {
                method: 'POST',
                url: '/api/v1/clinical_charts/:id/tag_as_comprehensive',
            },
            voidChart: { method: 'DELETE' },
            addSignature: {
                method: 'POST',
                url: '/api/v1/clinical_charts/:id/signatures',
            },
            recoverChart: {
                method: 'POST',
                url: '/api/v1/clinical_charts/recover',
            },
            headers: {
                method: 'GET',
                url: '/api/v1/clinical_charts/headers',
            },
        });
        res.getById = getById;
        res.getByPatientId = getByPatientId;
        res.getHeadersByPatientId = getHeadersByPatientId;
        return res;
        function getById(id) {
            return res.get({ id: id }).$promise;
        }
        function getByPatientId(id, extraParams) {
            var params = extraParams || {};
            params.patient_id = id;
            return res.query(params);
        }
        function getHeadersByPatientId(patient_id, page, pageSize, extraParams) {
            var params = extraParams || {};
            params.patient_id = patient_id;
            params.page = page;
            params.page_size = pageSize;
            return res.headers(params);
        }
    }
})();


(function () {
    'use strict';
    angular.module('consolo').factory('DmeOrder', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/dme_orders/:id');
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('DosageDateTemplate', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/dosage_date_templates/:id', { id: '@id' });
        return res;
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('EncounterType', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/encounter_types/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
        });
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('FamilyMember', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/family_members/:id', { id: '@id' }, {
            batch: {
                method: 'POST',
                url: '/api/v1/family_members/batch',
                isArray: true,
            },
            update: { method: 'PUT' },
        });
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Indicator', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/indicators/:id');
        return {
            loadByPatientId: loadByPatientId,
        };
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('MedicationNote', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/medication_notes/:id');
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Medication', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/medications/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            addNote: {
                method: 'POST',
                url: '/api/v1/medications/medication_notes',
            },
            newInteractions: {
                method: 'GET',
                url: '/api/v1/medications/new_interactions',
            },
            manualIntervention: {
                method: 'POST',
                url: '/api/v1/medications/:id/manual_intervention',
            },
            reconcile: {
                method: 'POST',
                url: '/api/v1/medications/reconcile',
            },
            reconciliations: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/medications/reconciliations',
            },
            addSignature: {
                method: 'POST',
                url: '/api/v1/medications/user_signature',
            },
            addPhysicianSignature: {
                method: 'POST',
                url: '/api/v1/medications/physician_signature',
            },
            signatures: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/medications/signatures',
            },
            getMedicationSet: {
                method: 'GET',
                url: '/api/v1/medications/current_medication_set',
            },
            defaultNewMedication: {
                method: 'GET',
                url: '/api/v1/medications/default_new_medication_values',
            },
        });
        res.loadByPatientId = loadByPatientId;
        res.getById = getById;
        res.loadAllByPatientId = loadAllByPatientId;
        res.loadActiveByPatientId = loadActiveByPatientId;
        return res;
        function getById(id) {
            return res.get({ id: id }).$promise;
        }
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
        function loadAllByPatientId(id) {
            return res.query({
                patient_id: id,
                include_dc: true,
                include_deleted: true,
                include_disposed: true,
            });
        }
        function loadActiveByPatientId(id) {
            return res.query({
                patient_id: id,
                include_dc: false,
                include_deleted: false,
                include_disposed: false,
            });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Patient', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/patients/:id', null, {
            clinical_summary: {
                method: 'GET',
                url: '/api/v1/patients/:id/clinical_summary',
            },
            assigned: {
                method: 'GET',
                url: '/api/v1/patients/assigned',
                isArray: true,
            },
            visit_frequency: {
                method: 'GET',
                url: '/api/v1/patients/:id/visit_frequency',
                isArray: true,
            },
            getExtra: {
                method: 'GET',
                url: '/api/v1/patients/:id?extra=true',
            },
            getMenu: {
                method: 'GET',
                url: '/api/v1/patients/:id/menu',
            },
            getComplete: {
                method: 'GET',
                url: '/api/v1/patients/:id/complete',
            },
            getOfflinePatients: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/patients/offline_dashboard_patients',
            },
            idgSummaryHeaders: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/patients/:id/idg_snapshot_headers',
            },
            dmeOrders: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/dme_orders',
            },
            patientDocuments: {
                method: 'GET',
                isArray: true,
                url: '/api/v1/patient_documents',
            },
        });
        res.getClinicalSummary = getClinicalSummary;
        res.getIdgSummaryHeaders = getIdgSummaryHeaders;
        res.getDmeOrders = getDmeOrders;
        res.getPatientDocuments = getPatientDocuments;
        return res;
        function getClinicalSummary(patientId, sections) {
            console.log(patientId, sections);
            return res.clinical_summary({ id: patientId, 'sections[]': sections });
        }
        function getIdgSummaryHeaders(patientId) {
            return res.idgSummaryHeaders({ id: patientId });
        }
        function getDmeOrders(patientId) {
            return res.dmeOrders({ patient_id: patientId });
        }
        function getPatientDocuments(patientId, page, pageSize, faxable) {
            var p = { patient_id: patientId };
            if (faxable) {
                p['faxable'] = true;
            }
            if (pageSize) {
                p['page_size'] = pageSize;
            }
            if (page) {
                p['page'] = page;
            }
            return res.patientDocuments(p);
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('PhysicianOrderEntry', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/physician_orders/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            addSignature: {
                method: 'POST',
                url: '/api/v1/physician_orders/:id/signatures',
            },
            addPhysicianSignature: {
                method: 'POST',
                url: '/api/v1/physician_orders/:id/add_physician_signature',
            },
            splitOutOrder: {
                method: 'POST',
                url: '/api/v1/physician_orders/split_out_by_medications',
            },
        });
        res.getById = getById;
        res.getMultipleOrders = getMultipleOrders;
        res.loadByPatientId = loadByPatientId;
        return res;
        function getById(id) {
            return res.get({ id: id }).$promise;
        }
        function getMultipleOrders(patientId, ids) {
            return res.query({ patient_id: patientId, 'ids[]': ids });
        }
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Physician', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/physicians/:id', { id: '@id' }, {
            update: { method: 'PATCH', isArray: false },
        });
        return res;
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('ProblemListItemsFactory', ['$resource', Factory]);
    function Factory($resource) {
        return $resource('/api/v1/problem_list_items', null, {
            get: {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    var snomed_problem;
                    data.forEach(function (item) {
                        if (item.snomed_problem) {
                            item.description = item.snomed_problem.description;
                        }
                        else {
                            item.description = 'Unknown';
                        }
                        item.name = item.description;
                    });
                    return data;
                },
            },
            query: {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    var snomed_problem;
                    data.forEach(function (item) {
                        if (item.snomed_problem) {
                            item.description = item.snomed_problem.description;
                        }
                        else {
                            item.description = 'Unknown';
                        }
                        item.name = item.description;
                    });
                    return data;
                },
            },
        });
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('SmokingStatus', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/smoking_statuses/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            current: {
                url: '/api/v1/smoking_statuses/current',
                method: 'GET',
            },
        });
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('Task', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/tasks/:id', { id: '@id' }, {
            update: { method: 'PATCH' },
            markComplete: {
                method: 'POST',
                url: '/api/v1/tasks/:id/complete',
            },
        });
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('UnrelatedExplanation', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/unrelated_explanations/:id', { id: '@id' }, {
            get: {
                transformResponse: function (data) {
                    var ue = angular.fromJson(data);
                    if (!ue.unrelated_check) {
                        ue.unrelated_check = {};
                    }
                    if (!ue.unrelated_checked.health_condition_ids) {
                        ue.unrelated_checked.health_condition_ids = [];
                    }
                    if (!ue.unrelated_checked.medication_ids) {
                        ue.unrelated_checked.medication_ids = [];
                    }
                    if (!ue.unrelated_checked.dme_supplies) {
                        ue.unrelated_checked.dme_supplies = [];
                    }
                    return ue;
                },
            },
            update: { method: 'PATCH' },
            addSignature: {
                method: 'POST',
                url: '/api/v1/unrelated_explanations/:id/signatures',
            },
            newUnrelatedExplanation: {
                method: 'GET',
                url: '/api/v1/unrelated_explanations/details_for_new',
            },
        });
        res.getById = getById;
        res.getNewUnrelatedExplanation = getNewUnrelatedExplanation;
        return res;
        function getById(id) {
            return res.get({ id: id }).$promise;
        }
        function getNewUnrelatedExplanation(patientId) {
            return res.newUnrelatedExplanation({ patient_id: patientId }).$promise;
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('VisitTaskList', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/recurring_task_sets/:id');
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').factory('VolunteerCarePlan', ['$resource', Model]);
    function Model($resource) {
        var res = $resource('/api/v1/volunteer_care_plans/:id');
        res.loadByPatientId = loadByPatientId;
        return res;
        function loadByPatientId(id) {
            return res.query({ patient_id: id });
        }
    }
})();

var consolo;
(function (consolo) {
    var directives;
    (function (directives) {
        'use strict';
        var app = angular.module('consolo');
        var ConsoloDataTable = /** @class */ (function () {
            function ConsoloDataTable() {
                this.restrict = 'E';
                this.replace = true;
                this.transclude = true;
                this.templateUrl = '/y/templates/common/data-table/consolo-data-table.html';
                this.controller = 'ConsoloDataTableController';
                this.controllerAs = 'dt';
                this.scope = {
                    emptyLabel: '@',
                    tableModel: '=',
                    tableData: '=?',
                    tableFilter: '=',
                    tableLabel: '@',
                    options: '=?',
                    online: '=',
                };
                this.link = function ($scope, elem, attr) { };
            }
            return ConsoloDataTable;
        }());
        directives.ConsoloDataTable = ConsoloDataTable;
        var ConsoloDataTableController = /** @class */ (function () {
            function ConsoloDataTableController($filter, $scope, $mdDialog) {
                this.$filter = $filter;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.options = $scope.options;
            }
            ConsoloDataTableController.prototype.pageClick = function (p) {
                this.options.pagingOptions.currentPage = p.page;
                this.options.pagingOptions.changePage(p.page);
            };
            ConsoloDataTableController.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            ConsoloDataTableController.prototype.formatCellValue = function (column, item) {
                if (column.formatCustom) {
                    return column.formatCustom.apply(this, arguments);
                }
                else if (column.isBoolean) {
                    if (item[column.field]) {
                        return 'YES';
                    }
                    else {
                        return 'NO';
                    }
                }
                else if (column.isDatetime) {
                    if (!!item[column.field]) {
                        return this.$filter('date')(item[column.field], 'MM/dd/yyyy h:mm a');
                    }
                }
                else if (column.isTimeAgo) {
                    if (!!item[column.field]) {
                        return this.$filter('timeAgo')(item[column.field]);
                    }
                }
                else if (column.isDate) {
                    if (!!item[column.field]) {
                        return new Date(item[column.field]).toISOString().slice(0, 10);
                    }
                }
                else {
                    if (item[column.field]) {
                        return item[column.field].length > 0 ? item[column.field] : ' ';
                    }
                    else {
                        return ' ';
                    }
                }
            };
            ConsoloDataTableController.prototype.formatActionText = function (action, item) {
                if (action.formatCustom) {
                    return action.formatCustom.apply(this, arguments);
                }
                else {
                    return action.text;
                }
            };
            ConsoloDataTableController.prototype.applyCellCss = function (column, item) {
                var css = '';
                if (column.customCss) {
                    css = column.customCss.apply(this, arguments) + ' ';
                }
                if (column.itemClass) {
                    css += column.itemClass;
                }
                if (css.length > 0) {
                    return css.trim();
                }
                else {
                    return '';
                }
            };
            ConsoloDataTableController.prototype.expandAll = function (itemSet, val) {
                this.$scope.tableData.forEach(function (item) {
                    item.showExpanded = val;
                });
            };
            ConsoloDataTableController.$inject = ['$filter', '$scope', '$mdDialog'];
            return ConsoloDataTableController;
        }());
        directives.ConsoloDataTableController = ConsoloDataTableController;
        app
            .directive('consoloDataTable', function () { return new consolo.directives.ConsoloDataTable(); })
            .controller('ConsoloDataTableController', consolo.directives.ConsoloDataTableController);
    })(directives = consolo.directives || (consolo.directives = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').filter('capitalize', function () {
        return function (input) {
            if (input) {
                input = input.toLowerCase();
                return input.substring(0, 1).toUpperCase() + input.substring(1);
            }
        };
    });
})();


var consolo;
(function (consolo) {
    var filters;
    (function (filters) {
        'use strict';
        function DateWithTimezone(sessionService) {
            return function (date, format) {
                if (date === undefined || date === null || date === '') {
                    return '';
                }
                return moment(date).tz(sessionService.getUser().timezone).format((format || 'MM/DD/YYYY hh:mm A') + " (z)");
            };
        }
        DateWithTimezone.$inject = ['SessionService'];
        angular.module('consolo').filter('dateWithTimezone', DateWithTimezone);
    })(filters = consolo.filters || (consolo.filters = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('filters', {
            bindings: { apiService: '<' },
            templateUrl: '/y/templates/common/filters/filters.html',
            controller: [
                'FiltersService',
                'CurrentPatient',
                function (FiltersService, CurrentPatient) {
                    this.FiltersService = FiltersService;
                    this.CurrentPatient = CurrentPatient;
                    this.search = search;
                    this.reset = reset;
                    this.toggleFilters = toggleFilters;
                    function search() {
                        this.apiService.filters.office_ids = this.FiltersService.getOfficeIds();
                        if (this.CurrentPatient.patient_id) {
                            this.apiService.filters.patient_id = this.CurrentPatient.patient_id;
                        }
                        this.apiService.search();
                    }
                    function toggleFilters() {
                        this.apiService.showFilters = !this.apiService.showFilters;
                    }
                    function reset() {
                        this.FiltersService.reset();
                        this.apiService.reset();
                    }
                },
            ],
            controllerAs: 'vm',
        });
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var FiltersService = /** @class */ (function () {
            function FiltersService(officeFilterService, $http) {
                this.officeFilterService = officeFilterService;
                this.$http = $http;
                this.activate();
            }
            FiltersService.prototype.activate = function () {
                var _this = this;
                this.officeFilterService.getInstance().then(function (instance) {
                    _this.officeFilter = instance;
                });
                this.$http.get('/api/v1/teams').then(function (teams) {
                    _this.teamSelection = [{ id: null, description: '' }];
                    teams.data.forEach(function (team) {
                        _this.teamSelection.push({ id: team.id, description: team.name });
                    });
                });
                this.yesNo = [
                    { id: null, description: '' },
                    { id: '1', description: 'Yes' },
                    { id: '0', description: 'No' },
                ];
                this.scheduleSelection = [
                    { id: null, description: '' },
                    { id: '1', description: 'Schedule 1' },
                    { id: '2', description: 'Schedule 2' },
                    { id: '3', description: 'Schedule 3' },
                    { id: '4', description: 'Schedule 4' },
                    { id: '5', description: 'Schedule 5' },
                ];
                this.signatureSelection = [
                    { id: null, description: '' },
                    { id: 'needs_nurse_sig', description: 'Needs Nurse Signature' },
                    { id: 'needs_physician_sig', description: 'Needs Physician Signature' },
                ];
                this.planOfCareSections = [
                    { id: 'assignments', description: 'Assignments' },
                    { id: 'physician_orders', description: 'Physician Orders' },
                    { id: 'medications', description: 'Medications' },
                    { id: 'indicators', description: 'Indicators' },
                    { id: 'care_plan_problems', description: 'Care Plan Problems' },
                    { id: 'recurring_task_sets', description: 'Visit Task Lists' },
                    { id: 'volunteer_care_plans', description: 'Volunteer Care Plans' },
                    { id: 'unrelated_explanations', description: 'Explanations of Unrelatedness' },
                ];
                this.payerSelection = [
                    { id: 'medicare', description: 'Medicare' },
                    { id: 'medicaid', description: 'Medicaid' },
                    { id: 'private_insurance', description: 'Private Insurance' },
                    { id: 'charitable_care', description: 'Charitable Care' },
                ];
            };
            FiltersService.prototype.reset = function () {
                if (this.officeFilter) {
                    this.officeFilter.reset();
                }
            };
            FiltersService.prototype.getOfficeIds = function () {
                return this.officeFilter.getSelectedIds();
            };
            FiltersService.$inject = ['officeFilterService', '$http'];
            return FiltersService;
        }());
        services.FiltersService = FiltersService;
        angular.module('consolo').service('FiltersService', FiltersService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').filter('nameSearch', function () {
        return function (input, query) {
            if (!query) {
                return input;
            }
            var result = [];
            input.forEach(function (item) {
                if (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(item);
                }
            });
            return result;
        };
    });
})();

var consolo;
(function (consolo) {
    var filters;
    (function (filters) {
        'use strict';
        function PhoneFilter() {
            return function (msg) {
                if (msg && msg.length >= 10) {
                    return '(' + msg.substr(0, 3) + ') ' + msg.substr(3, 3) + '-' + msg.substr(6, 10);
                }
                else {
                    return msg;
                }
            };
        }
        angular.module('consolo').filter('phone', PhoneFilter);
    })(filters = consolo.filters || (consolo.filters = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var filters;
    (function (filters) {
        'use strict';
        function TimeWithTimezone(sessionService) {
            return function (date, format) {
                if (date === undefined || date === null || date === '') {
                    return '';
                }
                return moment(date).tz(sessionService.getUser().timezone).format((format || 'hh:mm A') + " (z)");
            };
        }
        TimeWithTimezone.$inject = ['SessionService'];
        angular.module('consolo').filter('timeWithTimezone', TimeWithTimezone);
    })(filters = consolo.filters || (consolo.filters = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var filters;
    (function (filters) {
        function TitleizeFilter() {
            return function (input) {
                if (input) {
                    var values = input.split(',');
                    var result = '';
                    values.forEach(function (item, index) {
                        var words = item.split('_');
                        words.forEach(function (word, i) {
                            result = result + word.substring(0, 1).toUpperCase() + word.substring(1);
                            if (i + 1 !== words.length) {
                                result = result + ' ';
                            }
                        });
                        if (index + 1 !== values.length) {
                            result = result + ', ';
                        }
                    });
                    return result;
                }
            };
        }
        angular.module('consolo').filter('csgTitleize', TitleizeFilter);
    })(filters = consolo.filters || (consolo.filters = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').filter('toDate', Filter);
    // Converts a UNIX timestamp into a proper Date object
    function Filter() {
        return function (unixTimestamp) {
            var timestamp = parseFloat(unixTimestamp) * 1000;
            return new Date(timestamp);
        };
    }
})();

(function () {
    'use strict';
    angular.module('consolo').filter('yes_no', function () {
        return function (text) {
            if (text) {
                return 'Yes';
            }
            return 'No';
        };
    });
})();

// DO NOT USE FOR ANYTHING OTHER THAN CLINICAL CHARTS!! -JM
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingFormField = /** @class */ (function () {
            function ConsoloChartingFormField() {
                this.bindings = {
                    field: '<',
                    formModel: '=',
                    viewOnly: '<',
                    isRequired: '<',
                    onChange: '&',
                    formDefinition: '<'
                };
                // this.templateUrl = '/y/templates/common/form-manager/consolo-charting-form-field.html';
                this.template = "";
                this.controller = ConsoloChartingFormFieldController;
            }
            return ConsoloChartingFormField;
        }());
        var ConsoloChartingFormFieldController = /** @class */ (function () {
            function ConsoloChartingFormFieldController(SectionManager, $element, $attrs, $scope, $compile, $timeout, CurrentPatient, clinicalChartSameQuestionInteraction) {
                this.SectionManager = SectionManager;
                this.$element = $element;
                this.$attrs = $attrs;
                this.$scope = $scope;
                this.$compile = $compile;
                this.$timeout = $timeout;
                this.CurrentPatient = CurrentPatient;
                this.clinicalChartSameQuestionInteraction = clinicalChartSameQuestionInteraction;
                this.currentlyHidden = false;
            }
            ConsoloChartingFormFieldController.prototype.$onInit = function () {
                var _this = this;
                var componentModel = '$ctrl.formModel[$ctrl.field.field]';
                var componentSize = 'csg-sm';
                var componentTransclude = '';
                var valueField = this.field.value_field ? this.field.value_field : 'id';
                var textField = this.field.text_field ? this.field.text_field : 'description';
                var toFixed = 2;
                var isViewMode = localStorage.getItem('ViewEncounterChart');
                if (isViewMode === 'true') {
                    if (this.field.field === 'mitchel_risk') {
                        this.field.type = 'text';
                        localStorage.removeItem('ViewEncounterChart');
                    }
                }
                switch (this.field.type) {
                    case 'checkbox':
                        this.componentName = 'csg-checkbox';
                        break;
                    case 'date':
                        this.componentName = 'csg-datepicker';
                        this.extraAttributes = "csg-type=\"date\" max-date=\"'" + this.field.maxDate + "'\"";
                        if (this.field.minDate) {
                            this.extraAttributes += " min-date=\"'" + this.field.minDate + "'\"";
                        }
                        componentSize = 'csg-md';
                        break;
                    case 'time':
                        this.componentName = 'csg-datepicker';
                        this.extraAttributes = "csg-type=\"time\" max-date=\"'" + this.field.maxDate + "'\" view-filter=\"timeWithTimezone\"";
                        componentSize = 'csg-md';
                        break;
                    case 'datetime':
                        this.componentName = 'csg-datepicker';
                        this.extraAttributes = "csg-type=\"datetime\" max-date=\"'" + this.field.maxDate + "'\" min-date=\"'" + this.field.minDate + "'\" view-filter=\"dateWithTimezone\"";
                        componentSize = 'csg-md';
                        break;
                    case 'text':
                        this.componentName = 'csg-textarea';
                        componentSize = 'csg-lg';
                        break;
                    case 'booltext':
                        this.componentName = 'csg-booltext';
                        componentModel = "$ctrl.formModel['note_'+$ctrl.field.field]";
                        this.extraAttributes = "ng-checkbox-model=\"$ctrl.formModel['has_' + $ctrl.field.field]\" auto-check=\"true\" input-type=" + this.field.inputType;
                        break;
                    case 'select':
                        this.componentName = 'csg-select';
                        this.extraAttributes = "options=\"$ctrl.field.options\"\n            value-field=\"" + valueField + "\"\n            text-field=\"" + textField + "\"\n            has-empty=\"true\"";
                        break;
                    case 'multiselect':
                        this.componentName = 'csg-select';
                        this.extraAttributes = "options=\"$ctrl.field.options\"\n            value-field=\"" + valueField + "\"\n            text-field=\"" + textField + "\"\n            multiple=\"true\"";
                        break;
                    case 'checklist':
                        this.componentName = 'csg-checklist';
                        this.extraAttributes = "value-field=\"id\" text-field=\"description\" options=\"$ctrl.field.options\" csg-sizing=\"csg-sm\"";
                        componentSize = 'csg-lg';
                        break;
                    case 'rating':
                        this.componentName = 'csg-star-rating';
                        this.extraAttributes = "values=\"$ctrl.SectionManager[$ctrl.field.optionsType]\" max=\"::$ctrl.field.max\"";
                        componentSize = 'csg-md';
                        break;
                    case 'bmi':
                        toFixed = 1;
                        // added the below code to calculate the BMI history fields
                        var height = this.field.bmiHeightModel || 'height';
                        var weight = this.field.bmiWeightModel || 'weight';
                        this.componentName = 'csg-container';
                        if (this.field.labelFn) {
                            this.extraAttributes = "dynamic-label=\"$ctrl.field._label\"";
                        }
                        componentTransclude = "\n            <md-input-container flex=\"\" style=\"margin-top:-8px;\">\n              <input ng-model=\"$ctrl.formModel[$ctrl.field.field]\"\n                bound-model=\"$ctrl.formModel['" + height + "'] && $ctrl.formModel['" + weight + "']\n                ? ($ctrl.formModel['" + weight + "'] / ($ctrl.formModel['" + height + "'] * $ctrl.formModel['" + height + "']) * 703.0).toFixed(" + toFixed + ")\n                : null\"\n              readonly=\"readonly\" class=\"" + (this.viewOnly && "input-read-only") + "\"/>\n            </md-input-container>\n          ";
                        break;
                    case 'autocomplete':
                        this.componentName = 'consolo-autocomplete';
                        this.extraAttributes = "\n            lookup=\"$ctrl.field.lookup\"\n            placeholder=\"" + this.field._label + "\"\n            template=\"form-field\"\n            on-select=\"$ctrl.formModel[$ctrl.field.field] = item.id; $ctrl.formModel[$ctrl.field.object] = item;\"\n            selected-item=\"$ctrl.formModel[$ctrl.field.object]\"";
                        break;
                    case 'static':
                        this.componentName = 'csg-input';
                        this.field.readonly = true;
                        break;
                    case 'phonemask':
                        this.componentName = 'csg-input';
                        this.extraAttributes = "csg-type=\"phone\"";
                        break;
                    case "number":
                        this.componentName = 'csg-container';
                        if (this.field.labelFn) {
                            this.extraAttributes = "dynamic-label=\"$ctrl.field._label\"";
                        }
                        componentTransclude = "\n            <md-input-container flex=\"\" style=\"margin-top:-8px;\">\n              <input ng-model=\"$ctrl.formModel[$ctrl.field.field]\" type=\"text\" only-numbers " + (this.viewOnly ? 'readonly="readonly" class="input-read-only"' : '') + "/>\n            </md-input-container>\n          ";
                        break;
                    case "custom":
                        // label is made blank because its already been implemented in the custom component
                        this.field._label = '';
                        this.field.label = '';
                        this.componentName = 'csg-container';
                        componentTransclude = "\n            <" + this.field.customComponentName + " view-only=\"" + this.viewOnly + "\"  form-model='$ctrl.formModel'></" + this.field.customComponentName + ">\n          ";
                        break;
                    default:
                        this.componentName = 'csg-input';
                        if (this.field.labelFn) {
                            this.extraAttributes = "dynamic-label=\"$ctrl.field._label\"";
                        }
                        if (this.field.inputType) {
                            this.extraAttributes = (this.extraAttributes ? this.extraAttributes + ' ' : '') + ("type=" + this.field.inputType);
                        }
                        break;
                }
                if (this.field.isDependency || this.field.changeFn) {
                    if (this.field.type === 'rating') {
                        this.extraAttributes = 'on-rating-selected="$ctrl.runOnChange()" ' + this.extraAttributes;
                    }
                    else {
                        this.extraAttributes = 'on-change="$ctrl.runOnChange()" ' + this.extraAttributes;
                    }
                }
                if (!this.field._required) {
                    this.field._required = this.field.required;
                }
                if (this.field._hidden === null || this.field._hidden === undefined) {
                    this.field._hidden = !this.field._required && this.field.hidden;
                }
                if (this.field.labelFn) {
                    this.extraAttributes += ' dynamic-label="true" ';
                }
                if (!this.field._label) {
                    if (this.field.labelFn) {
                        this.field._label = this.field.labelFn(this.formModel) || this.field.label;
                    }
                    else {
                        this.field._label = this.field.label;
                    }
                }
                if (!this.field.service_line_tag) {
                    this.field.service_line_tag = false;
                }
                if (this.viewOnly) {
                    this.field.readonly = true;
                }
                else {
                    this.field.reaonly = false;
                }
                if (this.field.infoLink) {
                    this.extraAttributes += " info-link=\"" + this.field.infoLink + "\"";
                }
                if (this.field.auditEvent) {
                    this.extraAttributes += " audit-event=\"'" + this.field.auditEvent + "'\"";
                }
                if (this.field.auditMessage) {
                    this.extraAttributes += " audit-message=\"'" + this.field.auditMessage + "'\"";
                }
                if (this.field.auditEvent) {
                    this.extraAttributes += " audit-patient-id=\"'" + this.CurrentPatient.patient_id + "'\"";
                }
                var gtlg = this.field.type === 'text' || this.field.type === 'checklist' ? '100' : '33';
                var gtxs = this.field.type === 'text' || this.field.type === 'checklist' ? '100' : '50';
                var md = this.field.type === 'datetime' ||
                    this.field.type === 'rating' ||
                    this.field.type === 'text' ||
                    this.field.type === 'checklist'
                    ? '100'
                    : '50';
                var csgSize;
                switch (this.field.type) {
                    case 'custom':
                    case 'text':
                    case 'checklist':
                        csgSize = 'csg-lg';
                        break;
                    case 'datetime':
                    case 'rating':
                    default:
                        csgSize = 'csg-sm';
                        break;
                }
                var sizeClasses = "flex-gt-lg-" + gtlg + " flex-xs-100 flex-gt-xs-" + gtxs + " flex-md-" + md;
                this.componentString = "\n        <" + this.componentName + " label=\"" + this.field._label + "\"\n          ng-model=\"" + componentModel + "\"\n          is-required=\"$ctrl.field._required\" is-invalid=\"$ctrl.field.invalid\"\n          hospice-item-set=\"" + this.field.service_line_tag + "\"\n          readonly=\"$ctrl.field.readonly\"\n          readonly-show-value=\"'true'\"\n          ng-class=\"{'charting-empty-field': $ctrl.currentlyHidden, 'read-only':" + this.viewOnly + "}\"\n          " + csgSize + "\n          ng-if=\"!$ctrl.field._hidden && (" + this.field.service_line_tag + " || $ctrl.field._required || !$ctrl.SectionManager.tagOnly)\"\n          " + this.extraAttributes + ">" + componentTransclude + "\n        </" + this.componentName + ">\n      ";
                //ng-show="!$ctrl.field._hidden && (${this.field.service_line_tag} || $ctrl.field._required || !$ctrl.SectionManager.tagOnly)"
                var el = this.$compile(this.componentString)(this.$scope);
                // $element.addClass('flex-gt-lg-' + gtlg);
                // $element.addClass('flex-xs-100');
                // $element.addClass('flex-gt-xs-' + gtxs);
                // $element.addClass('flex-md-' + md);
                // $element.append(el);
                this.$element.replaceWith(el);
                //datetime: gt-xs: 50, md:100, gt-lg: 33
                //text/CL: flex-100
                //other: gt-xs: 50, md: 50, gt-lg: 33
                this.$scope.$watch('formModel', function (newVal, oldVal) {
                    _this.checkHideShow();
                });
            };
            ConsoloChartingFormFieldController.prototype.$onChanges = function (changeObj) {
                // this.checkRequired();
                // this.checkHidden();
                this.checkHideShow();
            };
            ConsoloChartingFormFieldController.prototype.runOnChange = function () {
                var _this = this;
                this.$timeout(function () {
                    if (_this.field.changeFn) {
                        _this.field.changeFn(_this.formModel, _this);
                    }
                    _this.checkDependencies();
                });
            };
            ConsoloChartingFormFieldController.prototype.checkDependencies = function () {
                var tempDefinition = Array.isArray(this.formDefinition)
                    ? this.formDefinition
                    : [this.formDefinition];
                for (var _i = 0, tempDefinition_1 = tempDefinition; _i < tempDefinition_1.length; _i++) {
                    var section = tempDefinition_1[_i];
                    for (var _a = 0, _b = section.fields; _a < _b.length; _a++) {
                        var field = _b[_a];
                        if (field.requiredFn) {
                            field._required = field.required || field.requiredFn(this.formModel);
                        }
                        else {
                            field._required = field.required;
                        }
                        if (field.hideFn) {
                            field._hidden =
                                !field._required && field.hideFn(this.formModel, this.CurrentPatient.patient);
                        }
                        else {
                            field._hidden = !field._required && field.hidden;
                        }
                        if (field.labelFn) {
                            field._label = field.labelFn(this.formModel) || field.label;
                        }
                        else {
                            field._label = field.label;
                        }
                    }
                }
            };
            ConsoloChartingFormFieldController.prototype.checkRequired = function () {
                var tempDefinition = Array.isArray(this.formDefinition)
                    ? this.formDefinition
                    : [this.formDefinition];
                for (var _i = 0, tempDefinition_2 = tempDefinition; _i < tempDefinition_2.length; _i++) {
                    var section = tempDefinition_2[_i];
                    for (var _a = 0, _b = section.fields; _a < _b.length; _a++) {
                        var field = _b[_a];
                        if (field.requiredFn) {
                            field._required =
                                field.required || field.requiredFn(this.formModel, this.CurrentPatient.patient);
                        }
                        else {
                            field._required = field.required;
                        }
                    }
                }
            };
            ConsoloChartingFormFieldController.prototype.checkHidden = function () {
                // if (this.field.requiredFn) {
                //   return !this.field.required && this.field.hiddenFn(this.formModel);
                // } else {
                //   return !this.field.required && this.field.hidden;
                // }
            };
            ConsoloChartingFormFieldController.prototype.checkHideShow = function () {
                if (this.field.showOnEmpty || !this.viewOnly) {
                    this.showField();
                    return;
                }
                if (!this.formModel && this.viewOnly) {
                    this.hideField();
                    return;
                }
                if (this.field.type === 'booltext') {
                    if (!this.formModel['has_' + this.field.field] &&
                        !this.formModel['note_' + this.field.field]) {
                        this.hideField();
                    }
                    else {
                        this.showField();
                    }
                }
                else if (this.field.type === 'checklist' || this.field.type === 'multiselect') {
                    if (!this.formModel[this.field.field] || !this.formModel[this.field.field].length) {
                        this.hideField();
                    }
                    else {
                        this.showField();
                    }
                }
                else if (!this.formModel[this.field.field]) {
                    this.hideField();
                }
                else {
                    this.showField();
                }
            };
            ConsoloChartingFormFieldController.prototype.hideField = function () {
                if (!this.currentlyHidden) {
                    this.currentlyHidden = true;
                }
            };
            ConsoloChartingFormFieldController.prototype.showField = function () {
                if (this.currentlyHidden) {
                    this.currentlyHidden = false;
                }
            };
            ConsoloChartingFormFieldController.$inject = [
                'ChartingSectionManager',
                '$element',
                '$attrs',
                '$scope',
                '$compile',
                '$timeout',
                'CurrentPatient',
                'ClinicalChartSameQuestionInteraction'
            ];
            return ConsoloChartingFormFieldController;
        }());
        components.ConsoloChartingFormFieldController = ConsoloChartingFormFieldController;
        angular.module('consolo').component('consoloChartingFormField', new ConsoloChartingFormField());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

// DO NOT USE FOR ANYTHING OTHER THAN CLINICAL CHARTS!! -JM
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingFormSection = /** @class */ (function () {
            function ConsoloChartingFormSection() {
                this.bindings = {
                    section: '<',
                    formModel: '=',
                    formModelName: '<',
                    formComponentName: '<',
                    formDefinition: '<',
                    viewOnly: '<',
                    callback: '&?',
                    chartStore: '<',
                    currentPatient: '<',
                    showUnmodifiedSections: '=',
                };
                this.template = "";
                this.controller = ConsoloChartingFormSectionController;
            }
            return ConsoloChartingFormSection;
        }());
        var ConsoloChartingFormSectionController = /** @class */ (function () {
            function ConsoloChartingFormSectionController($element, $compile, $scope, $timeout, SectionManager, ChartUtilService) {
                var _this = this;
                this.$element = $element;
                this.$compile = $compile;
                this.$scope = $scope;
                this.$timeout = $timeout;
                this.SectionManager = SectionManager;
                this.ChartUtilService = ChartUtilService;
                this.zeroIndex = '';
                this.allFieldsAreHidden = false;
                this.SectionManager.onRequiredFilterChange(function () { return _this.checkRequiredFilters(); });
                this.SectionManager.onHisFilterChange(function () { return _this.checkHisFilters(); });
                if (this.section && !this.section.singleNode && Array.isArray(this.formModel)) {
                    // Handle situations where formModel is an array but other pieces of code are expecting a chart object.
                    // formModel is a 2 way binding so we do not want to change it directly
                    this.zeroIndex = '[0]';
                    this.formModelZero = this.formModel[0];
                }
                this.fixOldWoundAssessmentStage();
            }
            ConsoloChartingFormSectionController.prototype.$onInit = function () {
                this.initialize();
            };
            ConsoloChartingFormSectionController.prototype.initialize = function () {
                this.viewOnly = !!this.viewOnly;
                if (!this.currentPatient) {
                    if (this.chartStore) {
                        this.currentPatient = this.chartStore.model.patient || {};
                    }
                    else {
                        this.currentPatient = {};
                    }
                }
                this.checkDependencies();
                this.checkRequiredFilters();
                this.checkHisFilters();
                this.allFieldsAreHidden = this.allFieldsHidden();
                var componentTemplate = "\n        <div ng-show=\"$ctrl.allFieldsAreHidden\">\n          <div class=\"charting-empty-field\" ng-show=\"$ctrl.formComponentName === '_individualSection'\"></div>\n          <div class=\"all-fields-hidden text-muted padding-sm\" ng-show=\"$ctrl.formComponentName !== '_individualSection'\">\n            No fields match the current focus filters.\n          </div>\n        </div>\n      ";
                var includeBodySite = "";
                if (this.section && this.section.hasBodySite) {
                    includeBodySite = "\n      <div ng-if=\"::(!$ctrl.viewOnly || $ctrl.formModel" + this.zeroIndex + ".body_site_id)\">\n        <div class=\"md-subheader subsection-header\">Location</div>\n        <consolo-body-site ng-model=\"$ctrl.formModel" + this.zeroIndex + "\"\n          readonly=\"::$ctrl.viewOnly\"\n          patient-id=\"" + this.chartStore.model.patient_id + "\">\n        </consolo-body-site>\n      </div>";
                }
                if (this.formComponentName) {
                    if (this.formComponentName === '_individualSection') {
                        componentTemplate += "\n        <div ng-class=\"{'charting-empty-field' : $ctrl.viewOnly &&\n          !$ctrl.showUnmodifiedSections &&\n          $ctrl.ChartUtilService.checkEmptySubsection($ctrl.section, $ctrl.formDefinition, $ctrl.formModel)}\"\n          ng-show=\"!$ctrl.allFieldsAreHidden\">\n          <div class=\"md-subhead subsection-header\"\n            ng-if=\"$ctrl.formDefinition.label\n              && ($ctrl.formDefinition.has_required || $ctrl.formDefinition.has_service_line_tag || !$ctrl.SectionManager.tagOnly)\"\n          >\n            {{::$ctrl.formDefinition.label}}\n          </div>\n          <div layout=\"row\" layout-wrap layout-padding>\n            <consolo-charting-form-field ng-repeat=\"field in $ctrl.formDefinition.fields\" field=\"field\"\n              view-only=\"::$ctrl.viewOnly\"\n              form-model=\"$ctrl.formModel" + this.zeroIndex + "\"\n              form-definition=\"$ctrl.formDefinition\"\n            />\n          </div>\n        </div>\n        ";
                    }
                    else {
                        componentTemplate += "\n        <" + this.formComponentName + " chart-store=\"$ctrl.chartStore\"\n          current-patient=\"$ctrl.currentPatient\" view-only=\"$ctrl.viewOnly\"\n          form-model=\"$ctrl.formModel" + this.zeroIndex + "\" form-definition=\"$ctrl.formDefinition\"\n          ng-class=\"::{'charting-empty-field' : $ctrl.viewOnly && $ctrl.formModel" + this.zeroIndex + ".isEmpty}\"\n          show-unmodified-sections=\"$ctrl.showUnmodifiedSections\"\n          callback=\"$ctrl.callback(page)\"\n          ng-show=\"!$ctrl.allFieldsAreHidden\"\n        >\n        </" + this.formComponentName + ">\n      ";
                    }
                }
                else {
                    componentTemplate += "\n      <div ng-class=\"{'charting-empty-field' : $ctrl.viewOnly && !$ctrl.showUnmodifiedSections && $ctrl.formModel" + this.zeroIndex + ".isEmpty}\"\n        ng-show=\"!$ctrl.allFieldsAreHidden\">\n        <div ng-repeat=\"sec in $ctrl.formDefinition\" ng-class=\"{'charting-empty-field' : $ctrl.viewOnly &&\n          !$ctrl.showUnmodifiedSections &&\n          $ctrl.ChartUtilService.checkEmptySubsection($ctrl.section, sec, $ctrl.formModel" + this.zeroIndex + ")}\">\n          <div ng-if=\"sec.label && (sec.has_required || sec.has_service_line_tag || !$ctrl.SectionManager.tagOnly)\"\n            class=\"md-subhead subsection-header\">{{::sec.label}}</div>\n          <div layout=\"row\" layout-wrap layout-padding>\n            <consolo-charting-form-field ng-repeat=\"field in sec.fields\" field=\"field\"\n              view-only=\"::$ctrl.viewOnly\"\n              form-model=\"$ctrl.formModel" + this.zeroIndex + "\"\n              form-definition=\"$ctrl.formDefinition\"\n            />\n          </div>\n        </div>\n        " + includeBodySite + "\n      </div>\n    ";
                }
                this.$element.empty();
                var el = this.$compile(componentTemplate)(this.$scope);
                this.$element.append(el);
            };
            ConsoloChartingFormSectionController.prototype.checkDependencies = function () {
                if (this.formModel && !angular.equals(this.formModel, {})) {
                    var modelArray = void 0;
                    if (!Array.isArray(this.formModel)) {
                        modelArray = [this.formModel];
                    }
                    else {
                        modelArray = this.formModel;
                    }
                    for (var _i = 0, modelArray_1 = modelArray; _i < modelArray_1.length; _i++) {
                        var model = modelArray_1[_i];
                        if (this.formDefinition) {
                            var formDefArray = Array.isArray(this.formDefinition)
                                ? this.formDefinition
                                : [this.formDefinition];
                            for (var _a = 0, formDefArray_1 = formDefArray; _a < formDefArray_1.length; _a++) {
                                var section = formDefArray_1[_a];
                                for (var _b = 0, _c = section.fields; _b < _c.length; _b++) {
                                    var field = _c[_b];
                                    if (field.requiredFn) {
                                        field._required = field.required || field.requiredFn(model, this.currentPatient);
                                    }
                                    else {
                                        field._required = field.required;
                                    }
                                    if (field.hideFn) {
                                        field._hidden = !field._required && field.hideFn(model, this.currentPatient);
                                    }
                                    else {
                                        field._hidden = !field._required && field.hidden;
                                    }
                                    if (field.labelFn) {
                                        field._label = field.labelFn(model);
                                    }
                                    else {
                                        field._label = field.label;
                                    }
                                }
                            }
                        }
                    }
                }
            };
            ConsoloChartingFormSectionController.prototype.checkRequiredFilters = function () {
                if (this.formDefinition) {
                    var formDefArray = Array.isArray(this.formDefinition)
                        ? this.formDefinition
                        : [this.formDefinition];
                    for (var _i = 0, formDefArray_2 = formDefArray; _i < formDefArray_2.length; _i++) {
                        var section = formDefArray_2[_i];
                        for (var _a = 0, _b = section.fields; _a < _b.length; _a++) {
                            var field = _b[_a];
                            if (this.SectionManager.filterRequired) {
                                if (this.SectionManager.filterRequired && !field._required) {
                                    field._hidden = true;
                                }
                            }
                            else {
                                field._hidden = this.checkHidden(field);
                                if (this.SectionManager.filterHIS) {
                                    this.checkHisFilters();
                                }
                            }
                        }
                    }
                }
                this.allFieldsAreHidden = this.allFieldsHidden();
            };
            ConsoloChartingFormSectionController.prototype.checkHisFilters = function () {
                if (this.formDefinition) {
                    var formDefArray = Array.isArray(this.formDefinition)
                        ? this.formDefinition
                        : [this.formDefinition];
                    for (var _i = 0, formDefArray_3 = formDefArray; _i < formDefArray_3.length; _i++) {
                        var section = formDefArray_3[_i];
                        for (var _a = 0, _b = section.fields; _a < _b.length; _a++) {
                            var field = _b[_a];
                            if (this.SectionManager.filterHIS) {
                                if (this.SectionManager.filterHIS && !field.service_line_tag) {
                                    field._hidden = true;
                                }
                            }
                            else {
                                field._hidden = this.checkHidden(field);
                                if (this.SectionManager.filterRequired) {
                                    this.checkRequiredFilters();
                                }
                            }
                        }
                    }
                }
                this.allFieldsAreHidden = this.allFieldsHidden();
            };
            ConsoloChartingFormSectionController.prototype.allFieldsHidden = function () {
                if (this.viewOnly) {
                    return false;
                }
                if (!(this.SectionManager.filterRequired || this.SectionManager.filterHIS)) {
                    return false;
                }
                if (!this.formDefinition) {
                    return false;
                }
                var formDefArray = Array.isArray(this.formDefinition)
                    ? this.formDefinition
                    : [this.formDefinition];
                for (var _i = 0, formDefArray_4 = formDefArray; _i < formDefArray_4.length; _i++) {
                    var section = formDefArray_4[_i];
                    for (var _a = 0, _b = section.fields; _a < _b.length; _a++) {
                        var field = _b[_a];
                        if (!field._hidden) {
                            return false;
                        }
                    }
                }
                return true;
            };
            ConsoloChartingFormSectionController.prototype.fieldChanged = function (section) {
                var _this = this;
                this.$timeout(function () {
                    for (var _i = 0, _a = section.fields; _i < _a.length; _i++) {
                        var f = _a[_i];
                        f._required = _this.checkRequired(f);
                        f._hidden = _this.checkHidden(f);
                        f._label = _this.checkLabel(f);
                    }
                }, 1000);
            };
            ConsoloChartingFormSectionController.prototype.checkRequired = function (field) {
                if (field.requiredFn) {
                    return !!field.required || field.requiredFn(this.formModelZero || this.formModel, this.currentPatient);
                }
                else {
                    return !!field.required;
                }
            };
            ConsoloChartingFormSectionController.prototype.checkHidden = function (field) {
                if (field.hideFn) {
                    return !field._required && field.hideFn(this.formModelZero || this.formModel, this.currentPatient);
                }
                else {
                    return !field._required && field.hidden;
                }
            };
            ConsoloChartingFormSectionController.prototype.checkLabel = function (field) {
                if (field.labelFn) {
                    return field.labelFn(this.formModel);
                }
                else {
                    return field.label;
                }
            };
            ConsoloChartingFormSectionController.prototype.fixOldWoundAssessmentStage = function () {
                var _this = this;
                if (this.formModelName === 'wound_assessments' &&
                    this.formModel['stage'] &&
                    !isNaN(this.formModel['stage']) &&
                    this.formModel['stage'] < 100) {
                    var stageOldValue = '';
                    // Unable to get the options via the options service in time, so hardcoding the values.
                    // Since this is going to be old values this should be fine.
                    var woundStages = [
                        {
                            description: '1 - Non-blanchable erythema of intact skin',
                            id: 1,
                        },
                        {
                            description: '2 - Partial thickness skin loss involving epidermis, dermis, or both',
                            id: 2,
                        },
                        {
                            description: '3 - Full thickness skin loss involving damage to or necrosis of subcutaneous tissue',
                            id: 3,
                        },
                        {
                            description: '4 - Full thickness skin loss with extensive destruction tissue necrosis',
                            id: 4,
                        },
                        { description: '5 - Unstageable', id: 5 },
                        { description: '6 - Not Pressure Related', id: 6 },
                    ];
                    var filteredData = woundStages.filter(function (option) { return option.id === _this.formModel['stage']; });
                    stageOldValue = filteredData.length > 0 ? filteredData[0]['description'] : undefined;
                    this.formModel['stage'] = stageOldValue;
                    var section = this.formDefinition.find(function (section) {
                        return section.fields.find(function (field) { return field.field === 'stage'; });
                    });
                    // Find the "stage" field within that section
                    var stageField = section.fields.find(function (field) { return field.field === 'stage'; });
                    stageField.label = 'Stage';
                    // Delete the options from the "stage" field
                    delete stageField.value_field;
                    delete stageField.type;
                    delete stageField.optionsType;
                    delete stageField.options;
                }
            };
            ConsoloChartingFormSectionController.$inject = [
                '$element',
                '$compile',
                '$scope',
                '$timeout',
                'ChartingSectionManager',
                'ChartUtilService',
            ];
            return ConsoloChartingFormSectionController;
        }());
        angular
            .module('consolo')
            .component('consoloChartingFormSection', new ConsoloChartingFormSection());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('consoloForm', [Directive]);
    function Directive() {
        return {
            require: ['^form'],
            restrict: 'A',
            scope: {
                formSection: '=',
                formDefinition: '=?',
                formModel: '=',
                formModelName: '=?',
                formComponentName: '=?',
                persistedItem: '=',
                viewOnly: '=?',
                currentPatient: '=?',
                callback: '&?',
                showUnmodifiedSections: '=',
            },
            templateUrl: '/y/templates/common/form-manager/form.html',
            link: link,
            transclude: true,
        };
        function getMaxDate(date) {
            if (!date) {
                date = new Date();
                date = date.setFullYear(date.getFullYear() + 2);
            }
            return date;
        }
        function link($scope, el, attrs, ctrls) {
            var form = ctrls[0];
            $scope.today = new Date();
            $scope.getMaxDate = getMaxDate;
            $scope.form = form;
            if (!$scope.viewOnly) {
                $scope.$watch(function () {
                    if ($scope.form.$dirty) {
                        if (!$scope.persistedItem.disableSave) {
                            $scope.persistedItem.saveDraft();
                        }
                        if ($scope.callback) {
                            $scope.$eval($scope.callback);
                        }
                        form.$setPristine();
                    }
                });
            }
        }
    }
})();

(function () {
    'use strict';
    angular.module('consolo').directive('onlyNumbers', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                ngModel: '='
            },
            link: function (scope, elm, attrs, ngModel) {
                //Validation to not allow values such as ".", "123." and ".123"
                elm.on('focusout', function (event) {
                    elm.val(elm.val().replace(/[^\d.]/g, ''));
                    updateView(parseFloat(elm.val()).toFixed(2));
                });
                function updateView(value) {
                    if (value == "NaN") {
                        value = null;
                    }
                    scope.ngModel = value;
                    ngModel.$modelValue = value;
                    ngModel.$viewValue = value;
                    ngModel.$render();
                }
                ;
                //Validation to only allow number and navigational keypresses
                elm.on('keydown', function (event) {
                    if (event.code.match(/\d$/) || event.code.includes("Arrow") || ["Backspace", "Delete", "NumLock", "Home", "End", "PageDown", "PageUp"].indexOf(event.code) > -1) {
                        return true;
                    }
                    else if (["Period", "NumpadDecimal"].indexOf(event.code) > -1 && elm.val().split('.').length < 2) {
                        return true;
                    }
                    else if ((event.metaKey || event.ctrlKey) && event.code.includes("Key") && (["A", "C", "V", "X"].indexOf(event.code.slice(-1)) > -1)) {
                        return true;
                    }
                    else {
                        event.preventDefault();
                        return false;
                    }
                });
            }
        };
    });
})();

(function () {
    'use strict';
    angular.module('consolo').factory('menu', [
        '$location',
        '$rootScope',
        function () {
            var openedSection;
            return {
                toggleSelectSection: toggleSelectSection,
                isSectionSelected: isSectionSelected,
            };
            function toggleSelectSection(section) {
                openedSection = openedSection === section ? null : section;
            }
            function isSectionSelected(section) {
                return openedSection === section;
            }
        },
    ]);
})();

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('menuToggle', [
        '$state',
        function ($state) {
            return {
                scope: {
                    section: '=',
                },
                templateUrl: '/y/templates/common/menu/menu-toggle.html',
                link: function (scope, element) {
                    var controller = element.parent().controller();
                    scope.state = $state.current;
                    scope.isOpen = function () {
                        return controller.isOpen(scope.section);
                    };
                    scope.toggle = function () {
                        controller.toggleOpen(scope.section);
                    };
                    var parentNode = element[0].parentNode.parentNode.parentNode;
                    if (parentNode.classList.contains('parent-list-item')) {
                        var heading = parentNode.querySelector('h2');
                        element[0].firstChild.setAttribute('aria-describedby', heading.id);
                    }
                },
            };
        },
    ])
        //take all whitespace out of string
        .filter('nospace', function () {
        return function (value) {
            return !value ? '' : value.replace(/ /g, '');
        };
    });
})();

(function () {
    'use strict';
    angular.module('consolo').directive('menuLink', function () {
        return {
            scope: {
                section: '=',
            },
            templateUrl: '/y/templates/common/menu/menu-link.html',
            link: function ($scope, $element) {
                var controller = $element.parent().controller();
                $scope.navigatePrimary = function (section) {
                    controller.navigatePrimary(section);
                };
                $scope.navigateSecondary = function (section, $event) {
                    controller.navigateSecondary(section, $event);
                };
                $scope.getHref = function (section, position) {
                    return controller.getHref(section, position);
                };
            },
        };
    });
})();

var consolo;
(function (consolo) {
    var pagination;
    (function (pagination) {
        'use strict';
        angular.module('consolo').component('pagingControls', {
            bindings: {
                loading: '<',
                loadPage: '&',
                currentPage: '<',
            },
            templateUrl: '/y/templates/common/pagination/paging-controls.html',
            controller: 'PagingControlsController as $ctrl',
        });
        var PagingControlsController = /** @class */ (function () {
            function PagingControlsController() {
            }
            PagingControlsController.prototype.hasPrev = function () {
                return !this.loading && this.currentPage && this.currentPage.page > 1;
            };
            PagingControlsController.prototype.hasNext = function () {
                return (!this.loading && this.currentPage && this.currentPage.page < this.currentPage.totalPages);
            };
            PagingControlsController.prototype.prevPage = function () {
                this.loadPage({ n: this.currentPage.page - 1 });
            };
            PagingControlsController.prototype.nextPage = function () {
                this.loadPage({ n: this.currentPage.page + 1 });
            };
            PagingControlsController.$inject = [];
            return PagingControlsController;
        }());
        angular.module('consolo').controller('PagingControlsController', PagingControlsController);
    })(pagination = consolo.pagination || (consolo.pagination = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('consoloPaging', {
            bindings: {
                maxPages: '<',
                currentPage: '<',
                pageCallback: '<',
                showPages: '@',
                position: '@',
            },
            templateUrl: '/y/templates/common/paging/consolo-paging.html',
            controller: [
                '$scope',
                function ($scope) {
                    var _this = this;
                    this.$onInit = function () {
                        this.showPages = parseInt(this.showPages, 10);
                        if (this.showPages > this.maxPages) {
                            this.showPages = this.maxPages;
                        }
                        this.index = 0;
                        this.pageDiff = Math.floor(this.showPages / 2);
                        this.startPage = 0;
                        this.endPage = this.showPages;
                        this.buttons = [];
                        for (var i = 0; i < this.showPages; i++) {
                            this.buttons.push(i);
                        }
                        this.pages = [];
                        for (var i = 1; i <= this.maxPages; i++) {
                            this.pages.push(i);
                        }
                    };
                    this.gotoPage = function (i) {
                        if (_this.currentPage === i) {
                            return;
                        }
                        _this.currentPage = i;
                        _this.startPage = i - _this.pageDiff < 0 ? 0 : i - _this.pageDiff;
                        _this.endPage = i + _this.pageDiff > _this.maxPages ? _this.maxPages : i + _this.pageDiff;
                        if (_this.startPage < _this.endPage - _this.showPages) {
                            _this.startPage = Math.max(0, _this.endPage - _this.showPages);
                        }
                        else if (_this.endPage + 1 < _this.startPage + _this.showPages) {
                            _this.startPage = _this.maxPages - _this.showPages + 1;
                        }
                        _this.index = Math.max(_this.startPage - 1, 0);
                        _this.pageCallback(_this.currentPage);
                    };
                    this.firstPage = function () {
                        if (_this.currentPage === 1) {
                            return;
                        }
                        _this.gotoPage(1);
                    };
                    this.lastPage = function () {
                        if (_this.currentPage === _this.maxPages) {
                            return;
                        }
                        _this.gotoPage(_this.maxPages);
                    };
                    this.prevPage = function () {
                        if (_this.currentPage > 1) {
                            _this.gotoPage(_this.currentPage - 1);
                        }
                    };
                    this.nextPage = function () {
                        if (_this.currentPage < _this.maxPages) {
                            _this.gotoPage(_this.currentPage + 1);
                        }
                    };
                },
            ],
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('patientDisplay', {
            bindings: {
                patient: '<',
                showContextMenu: '@',
                menuClass: '@',
                showAddress: '@',
                showOffice: '@',
                displayStyle: '@',
                actionIcon: '@',
                actionSref: '@',
                actionLabel: '@',
                navigateSref: '@',
            },
            templateUrl: '/y/templates/common/patient-display/patient-display.html',
            controller: [
                '$state',
                'AboutService',
                'DeviceSettingsService',
                function ($state, AboutService, DeviceSettings) {
                    var _this = this;
                    this.showMenu = showMenu;
                    this.navigateTo = navigateTo;
                    this.gotoDashboard = gotoDashboard;
                    this.$state = $state;
                    this.chartingCookie = DeviceSettings.getValue(consolo.deviceSettings.Pages.cookie, consolo.deviceSettings.Settings.charting_tablet_mode);
                    this.about = {};
                    AboutService.get().then(function (a) {
                        _this.about = a;
                    });
                    if (!this.navigateSref) {
                        this.navigateSref = 'patients.dashboard';
                    }
                    this.errImage = '/y/assets/images/not_provided.jpg';
                    function navigateTo($event, sref) {
                        this.$state.go('patients.' + sref, { patientId: this.patient.id });
                        $event.stopPropagation();
                    }
                    function urlTo(url) {
                        document.location.href = '/patients/' + this.patient.id + '/' + url;
                    }
                    function showMenu($event, $mdOpenMenu) {
                        $event.stopPropagation();
                        $mdOpenMenu($event);
                    }
                    function newChart($event) {
                        if (this.chartingCookie) {
                            this.navigateTo($event, 'patients.clinical-chart-entry-new.section');
                        }
                        else {
                            this.urlTo('clinical_charts/new');
                        }
                    }
                    function gotoDashboard() {
                        this.$state.go('patients.dashboard', { patientId: this.patient.id });
                    }
                },
            ],
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var JsPdfPrintService = /** @class */ (function () {
            function JsPdfPrintService($filter, $rootScope, $mdDialog, browserDetectionService, $q, $window, Hosts) {
                var _this = this;
                this.$filter = $filter;
                this.$rootScope = $rootScope;
                this.$mdDialog = $mdDialog;
                this.browserDetectionService = browserDetectionService;
                this.$q = $q;
                this.$window = $window;
                this.Hosts = Hosts;
                this.underline = {
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 550,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#757575',
                    lineCap: 'round',
                };
                this.letterSizeWidth = 612;
                this.letterSizeHeight = 692;
                this.legalSizeHeight = 1008;
                this.getDataUri('/y/assets/images/favicon-96x96.png', function (data) {
                    _this.consoloLogoUri = data;
                });
                this.getDataUri('/y/assets/images/wellsky-color-logo.png', function (data) {
                    _this.consoloLogoNameUri = data;
                });
            }
            JsPdfPrintService.prototype.getDataUri = function (url, callback) {
                var image = new Image();
                image.onload = function () {
                    var canvas = document.createElement('canvas');
                    canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
                    canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size
                    canvas.getContext('2d').drawImage(image, 0, 0);
                    // Get raw image data
                    // callback(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));
                    // ... or get as Data URI
                    callback(canvas.toDataURL('image/png'));
                };
                image.src = url;
            };
            JsPdfPrintService.prototype.getBaseDoc = function (title, author, watermark) {
                var docDefinition = {
                    pageSize: 'LETTER',
                    pageMargins: [30, 30, 30, 30],
                    info: {
                        title: title,
                        author: author,
                        producer: 'Consolo Services Group',
                    },
                    defaultStyle: {
                        fontSize: 12,
                        font: 'Roboto',
                        columnGap: 20,
                    },
                    images: {
                        consoloLogo: this.consoloLogoUri,
                        consoloNameLogo: this.consoloLogoNameUri,
                    },
                    styles: {
                        sectionHeader: {
                            fontSize: 16,
                            margin: [0, 40, 0, 0],
                            alignment: 'center',
                            bold: true,
                        },
                        paeSectionHeader: {
                            fontSize: 14,
                            margin: [0, 20, 0, 0],
                            alignment: 'left',
                            bold: true,
                        },
                        subsectionHeader: {
                            fontSize: 12,
                            bold: true,
                            margin: [0, 16, 0, 0],
                        },
                        paeSubsectionHeader: {
                            fontSize: 12,
                            bold: true,
                            alignment: 'left',
                            margin: [0, 10, 0, 0],
                        },
                        tableHeader: {
                            bold: true,
                            fontSize: 13,
                            color: 'black'
                        },
                        formInputHeader: {
                            fontSize: 12,
                            bold: true,
                            margin: [0, 8, 0, 0],
                        },
                        formInput: {
                            fontSize: 12,
                        },
                    },
                };
                if (watermark) {
                    docDefinition.watermark = {
                        text: watermark,
                        color: 'red',
                        opacity: '.1',
                        bold: true,
                    };
                }
                return docDefinition;
            };
            JsPdfPrintService.prototype.addHeader = function (docDefinition, title) {
                this.checkContent(docDefinition);
                docDefinition.header = function (currentPage, pageCount, pageSize) {
                    return [
                        {
                            columns: [
                                {
                                    text: title,
                                    margin: [5, 5, 0, 0],
                                },
                            ],
                        },
                        {
                            canvas: [{ type: 'line', x1: 2, y1: 5, x2: pageSize.width - 2, y2: 5, lineWidth: 0.5 }],
                        },
                    ];
                };
            };
            JsPdfPrintService.prototype.addFooter = function (docDefinition, pdfSettings) {
                var _this = this;
                var hasFooterSignature = pdfSettings && pdfSettings['include_footer_signature_lines'];
                docDefinition.footer = function (currentPage, pageCount, pageSize) {
                    // Width on left and right is changed to optimize the sizing of
                    // columns based on footer layout
                    var left = {
                        text: _this.dateTimeFilter(new Date()),
                        margin: [10, 7, 0, 0],
                        alignment: 'left',
                        width: hasFooterSignature ? 'auto' : '*',
                    };
                    var right = {
                        text: currentPage.toString() + ' of ' + pageCount,
                        margin: [0, 7, 10, 0],
                        alignment: 'right',
                        width: hasFooterSignature ? 'auto' : '*',
                    };
                    var center = {};
                    // If pdfSettings for footer signature are included then
                    // display them, otherwise just display the author name
                    // and environment instead
                    if (hasFooterSignature) {
                        center = {
                            columns: [
                                {
                                    columns: [
                                        { text: 'Signed:', width: 'auto' },
                                        {
                                            canvas: [
                                                angular.copy({
                                                    type: 'line',
                                                    x1: 5,
                                                    y1: 12,
                                                    x2: 110,
                                                    y2: 12,
                                                    lineWidth: 1,
                                                    lineColor: '#757575',
                                                    lineCap: 'round',
                                                }),
                                            ],
                                            width: 'auto',
                                        },
                                    ],
                                    width: '*',
                                    margin: [5, 0],
                                },
                                {
                                    columns: [
                                        { text: 'Title:', width: 'auto' },
                                        {
                                            canvas: [
                                                angular.copy({
                                                    type: 'line',
                                                    x1: 5,
                                                    y1: 12,
                                                    x2: 100,
                                                    y2: 12,
                                                    lineWidth: 1,
                                                    lineColor: '#757575',
                                                    lineCap: 'round',
                                                }),
                                            ],
                                            width: 'auto',
                                        },
                                    ],
                                    width: 'auto',
                                    margin: [5, 0],
                                },
                                {
                                    columns: [
                                        { text: 'Date:', width: 'auto' },
                                        {
                                            canvas: [
                                                angular.copy({
                                                    type: 'line',
                                                    x1: 5,
                                                    y1: 12,
                                                    x2: 100,
                                                    y2: 12,
                                                    lineWidth: 1,
                                                    lineColor: '#757575',
                                                    lineCap: 'round',
                                                }),
                                            ],
                                            width: 'auto',
                                        },
                                    ],
                                    margin: [5, 0],
                                    width: 'auto',
                                },
                            ],
                            margin: [5, 7, 5, 0],
                            alignment: 'center',
                            width: '*',
                        };
                    }
                    else {
                        center.text = docDefinition.info.author ? docDefinition.info.author + ' - ' : '';
                        if (_this.Hosts.env && _this.Hosts.env !== 'production') {
                            center.text += _this.Hosts.env.toUpperCase();
                            center.alignment = 'center';
                            center.color = 'red';
                            center.bold = true;
                            center.margin = [0, 7, 0, 0];
                        }
                    }
                    return [
                        {
                            canvas: [{ type: 'line', x1: 2, y1: 0, x2: pageSize.width - 2, y2: 0, lineWidth: 0.5 }],
                        },
                        {
                            columns: [left, center, right],
                        },
                    ];
                };
            };
            JsPdfPrintService.prototype.addPatientHeader = function (docDefinition, patient) {
                this.checkContent(docDefinition);
                var u = this.getUnderline();
                u.x2 = this.letterSizeWidth - 275;
                var address = '';
                if (patient.current_address) {
                    address =
                        patient.current_address.address_1 +
                            '\n' +
                            patient.current_address.city +
                            ', ' +
                            patient.current_address.state +
                            ' ' +
                            patient.current_address.zipcode;
                }
                var row = {
                    columns: [
                        [
                            {
                                text: 'Status: ' + patient.care_status,
                                margin: [0, 0, 0, 0],
                                alignment: 'center',
                            },
                            { text: patient.name, margin: [0, 0, 0, 0], alignment: 'center' },
                            {
                                text: address,
                                margin: [0, 0, 0, 0],
                                alignment: 'center',
                            },
                        ],
                    ],
                };
                docDefinition.content.push(row);
            };
            JsPdfPrintService.prototype.addSignatures = function (docDefinition, signatures, showHeader) {
                var _this = this;
                if (showHeader === void 0) { showHeader = true; }
                this.checkContent(docDefinition);
                if (signatures.length) {
                    if (showHeader) {
                        docDefinition.content.push({
                            text: 'Signatures',
                            style: 'sectionHeader',
                        });
                    }
                    signatures.forEach(function (signature) {
                        docDefinition.content.push({
                            text: signature.static_role +
                                ' ' +
                                signature.static_name +
                                ' (' +
                                signature.signed_label +
                                ') signed on ' +
                                _this.dateTimeFilter(signature.signed_date) +
                                '.  Recorded by ' +
                                signature.recorded_by_static_name +
                                ' on ' +
                                _this.dateTimeFilter(signature.created_at) +
                                '.',
                            style: 'formInput',
                        });
                    });
                }
            };
            JsPdfPrintService.prototype.logoColumn = function () {
                return {
                    width: 200,
                    image: 'consoloNameLogo',
                    margin: [0, 10, 20, 0],
                    align: 'left',
                };
            };
            JsPdfPrintService.prototype.checkContent = function (dd) {
                if (!dd.content) {
                    dd.content = [];
                }
            };
            JsPdfPrintService.prototype.downloadPdf = function (docDefinition, filename) {
                pdfMake.createPdf(docDefinition).download(filename);
            };
            JsPdfPrintService.prototype.showPdf = function (docDefinition) {
                var _this = this;
                var pdf = pdfMake.createPdf(docDefinition);
                var win = window.open('', '_self');
                if (this.browserDetectionService.isSafari()) {
                    pdfMake.createPdf(docDefinition).print({}, win);
                }
                else {
                    pdf.getDataUrl(function (result) {
                        _this.$mdDialog.show({
                            template: "<md-dialog flex=\"75\" flex-gt-md=\"50\" style=\"height: 90%;\" ><md-dialog-content style=\"height:100%;width:100%\">\n                <div class=\"pdf-dialog\" ng-if=\"!content\">Loading Data...</div>\n                <embed ng-if=\"content\" ng-src=\"{{content}}\" style=\"width: 100%; height: 100%\"></embed>\n              </md-dialog-content>\n              <md-dialog-actions hide-gt-sm style=\"background-color:#525659\">\n                <md-button class=\"md-raised\" ng-click=\"$mdDialog.hide()\">Close</md-button>\n              </md-dialog-actions>\n              </md-dialog>",
                            clickOutsideToClose: true,
                            locals: { data: result },
                            fullscreen: true,
                            controller: [
                                '$scope',
                                '$sce',
                                '$mdDialog',
                                'data',
                                function ($scope, $sce, $mdDialog, data) {
                                    $scope.content = $sce.trustAsResourceUrl(data);
                                    $scope.$mdDialog = $mdDialog;
                                },
                            ],
                        });
                    });
                }
            };
            JsPdfPrintService.prototype.dateFilter = function (date) {
                return this.$filter('date')(new Date(date), 'MM/dd/yyyy');
            };
            JsPdfPrintService.prototype.dateTimeFilter = function (date) {
                return this.$filter('date')(new Date(date), 'MM/dd/yyyy hh:mm a');
            };
            JsPdfPrintService.prototype.getUnderline = function () {
                return angular.copy(this.underline);
            };
            JsPdfPrintService.$inject = [
                '$filter',
                '$rootScope',
                '$mdDialog',
                'browserDetectionService',
                '$q',
                '$window',
                'Hosts',
            ];
            return JsPdfPrintService;
        }());
        common.JsPdfPrintService = JsPdfPrintService;
        angular.module('consolo').service('jsPDF', JsPdfPrintService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PdferService = /** @class */ (function () {
            function PdferService($http, $window, $state, $mdDialog, logger, session) {
                this.$http = $http;
                this.$window = $window;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.enabled = false;
                this.invalidV2 = [
                    'missed_visit',
                    'idg_snapshot_summary',
                    'continuous_care_tracking_sheet',
                    'med_education_sheets',
                    'patient_cc_tracking_sheet',
                    'generic_cc_tracking_sheet',
                    'current_care_plan_problems',
                ];
                this.agency = session.get().agency;
                if (this.agency.pdf_v2_enabled) {
                    this.enabled = true;
                }
                this.userId = session.getUser().id;
            }
            PdferService.prototype.validV2 = function (items) {
                var keys = Object.keys(items);
                for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                    var k = keys_1[_i];
                    if (this.invalidV2.indexOf(k) >= 0) {
                        return false;
                    }
                }
                return true;
            };
            // TODO: Allow faxGeneratorService to call generatePdfFromStates if/when we need to fax something not in a documentList
            PdferService.prototype.generatePdfFromStates = function (states, stateParams, objectId, objectType, autoDownload, options, updatePdf, isReport, faxableId, faxableType, patientId, printApi, waitForSelector) {
                var _this = this;
                if (autoDownload === void 0) { autoDownload = false; }
                if (options === void 0) { options = {}; }
                if (updatePdf === void 0) { updatePdf = false; }
                if (isReport === void 0) { isReport = false; }
                if (faxableId === void 0) { faxableId = ''; }
                if (faxableType === void 0) { faxableType = ''; }
                if (patientId === void 0) { patientId = ''; }
                if (printApi === void 0) { printApi = false; }
                if (waitForSelector === void 0) { waitForSelector = null; }
                var urls;
                if (!Array.isArray(states)) {
                    states = [states];
                }
                if (!Array.isArray(stateParams)) {
                    stateParams = [stateParams];
                }
                var urlPrefix = this.$window.location.protocol + "//" + this.$window.location.host;
                urls = states.map(function (state, idx) { return "" + urlPrefix + _this.$state.href(state, stateParams[idx]); });
                return this.generateSinglePdf(urls, objectId, objectType, autoDownload, options, updatePdf, isReport, faxableId, faxableType, patientId, printApi, waitForSelector);
            };
            PdferService.prototype.generateCombinedPdf = function (pdfs, baseParams, 
            // isReport = false, // is this necessary? will you ever create a combined PDF that is a report?
            updatePdf, autoDownload, options, patientId, faxableId, faxableType) {
                var _this = this;
                if (baseParams === void 0) { baseParams = {}; }
                if (updatePdf === void 0) { updatePdf = false; }
                if (autoDownload === void 0) { autoDownload = false; }
                if (options === void 0) { options = {}; }
                if (!Array.isArray(pdfs)) {
                    pdfs = [pdfs];
                }
                var urlPrefix = this.$window.location.protocol + "//" + this.$window.location.host;
                var temp = [];
                var objectIds = [];
                var objectTypes = [];
                for (var _i = 0, pdfs_1 = pdfs; _i < pdfs_1.length; _i++) {
                    var p = pdfs_1[_i];
                    if (p.state) {
                        p.url = "" + urlPrefix + this.$state.href(p.state, p.stateParams);
                        delete p.state;
                        delete p.stateParams;
                    }
                    else if (p.url) {
                        p.url = urlPrefix + p.url;
                    }
                    temp.push(Object.assign({}, baseParams, p));
                    objectIds.push(p.pdf_object_id ? p.pdf_object_id.toString() : p.pdf_object_type);
                    objectTypes.push(p.pdf_object_type);
                }
                console.log('PDF Array to Print: ', pdfs);
                var railsPdfOptions = encodeURIComponent(JSON.stringify(options));
                return this.$http
                    .post('/api/v2/pdfer', {
                    pdfs: temp,
                    patient_id: patientId,
                    pdf_object_id: objectIds.join(','),
                    pdf_object_type: objectTypes.join(','),
                    user_id: this.userId,
                    agency_id: this.agency.id,
                    update_pdf: updatePdf,
                    faxable_id: faxableId,
                    faxable_type: faxableType,
                    pdf_options: railsPdfOptions,
                })
                    .then(function (resp) {
                    _this.handleResponse(resp, faxableId, autoDownload);
                });
            };
            PdferService.prototype.generateSinglePdfFromPost = function (server_template, server_template_args, pdf_options, objectId, objectType, autoDownload, updatePdf, isReport, faxableId, faxableType, patientId) {
                var _this = this;
                if (autoDownload === void 0) { autoDownload = false; }
                if (updatePdf === void 0) { updatePdf = false; }
                if (isReport === void 0) { isReport = false; }
                if (faxableId === void 0) { faxableId = ''; }
                if (faxableType === void 0) { faxableType = ''; }
                if (patientId === void 0) { patientId = ''; }
                return this.$http
                    .post('/api/v2/pdfer', {
                    pdfs: [
                        {
                            server_template: server_template,
                            server_template_args: server_template_args || [],
                            pdf_object_type: objectType,
                        },
                    ],
                    pdf_options: encodeURIComponent(JSON.stringify(pdf_options)),
                    patient_id: patientId,
                    pdf_object_id: objectId,
                    pdf_object_type: objectType,
                    user_id: this.userId,
                    agency_id: this.agency.id,
                    update_pdf: updatePdf,
                    faxable_id: faxableId,
                    faxable_type: faxableType,
                })
                    .then(function (resp) {
                    _this.handleResponse(resp, faxableId, autoDownload);
                });
            };
            PdferService.prototype.generateSinglePdf = function (urls, objectId, objectType, autoDownload, options, updatePdf, isReport, faxableId, faxableType, patientId, printApi, waitForSelector) {
                var _this = this;
                if (autoDownload === void 0) { autoDownload = false; }
                if (options === void 0) { options = {}; }
                if (updatePdf === void 0) { updatePdf = false; }
                if (isReport === void 0) { isReport = false; }
                if (faxableId === void 0) { faxableId = ''; }
                if (faxableType === void 0) { faxableType = ''; }
                if (patientId === void 0) { patientId = ''; }
                if (printApi === void 0) { printApi = false; }
                if (waitForSelector === void 0) { waitForSelector = null; }
                if (!Array.isArray(urls)) {
                    urls = [urls];
                }
                var waitForParam = '';
                if (waitForSelector) {
                    waitForParam = "&wait_for_selector=" + encodeURIComponent(waitForSelector);
                }
                objectId = objectId || '';
                urls = urls.map(function (url) { return 'urls[]=' + encodeURIComponent(url); });
                var urlString = urls.join('&');
                // Need to do this for GET, for a POST this is not necessary
                var railsPdfOptions = encodeURIComponent(JSON.stringify(options));
                return this.$http
                    .get("/api/v2/pdfer?" + urlString + "&pdf_object_id=" + objectId + "&pdf_object_type=" + objectType + "&user_id=" + this.userId +
                    ("&agency_id=" + this.agency.id + "&faxable_id=" + faxableId + "&faxable_type=" + faxableType + "&pdf_options=" + railsPdfOptions) +
                    ("&is_report=" + isReport + "&update_pdf=" + updatePdf + "&patient_id=" + patientId + "&print_api=" + printApi + waitForParam))
                    .then(function (resp) {
                    _this.handleResponse(resp, faxableId, autoDownload);
                });
            };
            PdferService.prototype.handleResponse = function (resp, faxableId, autoDownload) {
                if (autoDownload === void 0) { autoDownload = false; }
                var text = '';
                var title = '';
                switch (resp.data.status) {
                    case 'READY':
                        if (faxableId) {
                            this.logger.success('Your fax is being sent.  You will receive a notification when it is complete.', 'Faxing PDF');
                        }
                        else {
                            if (autoDownload) {
                                // this.$http.get(resp.data.pdf_public_url, { responseType: 'application/pdf' });
                                console.log('Open: ', resp.data.pdf_public_url);
                                this.$window.open(resp.data.pdf_public_url, '_blank');
                                return;
                            }
                            this.$mdDialog.show({
                                template: "<md-dialog flex=\"75\" flex-gt-md=\"50\" style=\"height: 90%;\" ><md-dialog-content style=\"height:100%;width:100%\">\n                    <div ng-if=\"!content\" class=\"pdf-dialog\">Please wait for your PDF to finish loading...</div>\n                    <embed ng-if=\"content\" ng-src=\"{{content}}\" style=\"width: 100%; height: 100%\" download=\"{{fileName}}\"></embed>\n                  </md-dialog-content>\n                  <md-dialog-actions style=\"background-color:#525659\">\n                    <md-button class=\"md-raised\" ng-click=\"$mdDialog.hide()\">Close</md-button>\n                  </md-dialog-actions>\n                  </md-dialog>",
                                clickOutsideToClose: true,
                                locals: { pdf_public_url: resp.data.pdf_public_url },
                                fullscreen: true,
                                controller: [
                                    '$scope',
                                    '$sce',
                                    '$http',
                                    '$mdDialog',
                                    'pdf_public_url',
                                    function ($scope, $sce, $http, $mdDialog, pdf_public_url) {
                                        var path = new URL(pdf_public_url).pathname.split('/');
                                        $scope.fileName = path[path.length - 1];
                                        $http.get(pdf_public_url, { responseType: 'blob' }).then(function (data) {
                                            var blob = data.data;
                                            var reader = new FileReader();
                                            reader.onloadend = function () {
                                                var s = reader.result;
                                                var buffer = btoa(s.toString()); // Base64.encode(s);
                                                var data = 'data:application/pdf;base64,' + buffer;
                                                $scope.content = $sce.trustAsResourceUrl(data);
                                            };
                                            reader.readAsBinaryString(blob);
                                        });
                                        $scope.$mdDialog = $mdDialog;
                                    },
                                ],
                            });
                        }
                        break;
                    case 'UPDATING':
                        text = faxableId
                            ? 'An updated PDF for your fax is being generated.  '
                            : 'An updated PDF is being generated.  ';
                        title = faxableId ? 'Faxing PDF' : 'Updating PDF';
                        break;
                    case 'CREATING':
                        text = faxableId
                            ? 'The PDF for your fax is being generated.  '
                            : 'Your PDF is being generated.  ';
                        title = faxableId ? 'Faxing PDF' : 'Creating PDF';
                        break;
                    case 'ERROR':
                        this.logger.error('There was an error when generating your PDF: ' + resp.data.error_message, 'PDF Error');
                        break;
                }
                if (title) {
                    this.logger.info(text + 'You will receive a notification when it is complete.', title);
                }
                return;
            };
            PdferService.prototype.generatePdfFromDocumentList = function (patientId, documentList, pdfOptions, faxSelection, sendFax) {
                var _this = this;
                if (pdfOptions === void 0) { pdfOptions = {}; }
                if (sendFax === void 0) { sendFax = false; }
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                var arrPdfs = [];
                faxSelection = faxSelection || {};
                if (sendFax && !faxSelection.faxable_id) {
                    this.logger.error('No fax recipient set');
                    return;
                }
                if (sendFax || pdfOptions.include_cover_sheet) {
                    var agencySubdomain = this.agency.subdomain;
                    var query_string = this.buildQueryStringForCoverSheet(faxSelection);
                    arrPdfs.push({
                        url: "/emr/" + agencySubdomain + "/patients/" + patientId + "/patient-documents-cover-sheet?" + query_string,
                        pdf_object_id: 0,
                        pdf_object_type: 'Cover Sheet',
                    });
                }
                if (pdfOptions.include_face_sheet || documentList.patient_face_sheet) {
                    patientId = ((_a = documentList) === null || _a === void 0 ? void 0 : _a.patient_face_sheet) || patientId;
                    var field_use = pdfOptions.field_use ? '&pdf_options%5Bfield_use%5D=1' : '';
                    arrPdfs.push({
                        url: "/patients/" + patientId + "/referral_admission_sheet?layout=pdf" + field_use,
                        pdf_object_id: 0,
                        pdf_object_type: 'Patient Face Sheet',
                    });
                }
                if (pdfOptions.include_problems) {
                    arrPdfs.push({
                        state: 'patients.problem-list-and-diagnoses',
                        stateParams: { patientId: patientId },
                        pdf_object_id: 0,
                        pdf_object_type: 'ProblemList',
                        wait_for_selector: '#problem-list-view',
                    });
                }
                if (pdfOptions.include_allergies) {
                    arrPdfs.push({
                        state: 'patients.allergies',
                        stateParams: { patientId: patientId },
                        pdf_object_id: 0,
                        pdf_object_type: 'Allergies',
                    });
                }
                if (((_b = documentList.medications) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    var opts = {};
                    if (pdfOptions.include_last_page_signature_line) {
                        opts['include_last_page_signature_line'] = true;
                    }
                    if (pdfOptions.rph_signature) {
                        opts['signature'] = 'rph';
                    }
                    else if (pdfOptions.physician_signature) {
                        opts['signature'] = 'physician';
                    }
                    else {
                        opts['signature'] = 'none';
                    }
                    opts['med_ids'] = documentList.medications;
                    arrPdfs.push({
                        server_template: pdfOptions.include_short_medications ? 'PatientMeds' : 'PatientMedsLong',
                        server_template_args: [patientId, opts],
                        pdf_object_id: (_c = documentList.medications) === null || _c === void 0 ? void 0 : _c[0],
                        pdf_object_type: pdfOptions.include_short_medications ? 'Medication' : 'LongMedication',
                    });
                }
                arrPdfs = arrPdfs.concat(((_d = documentList.clinical_charts) === null || _d === void 0 ? void 0 : _d.map(function (id) {
                    return {
                        state: 'patients.clinical-chart-view',
                        stateParams: { patientId: patientId, id: id },
                        pdf_object_id: id,
                        pdf_object_type: 'ClinicalChart',
                        wait_for_selector: '#chart-wait-for-selector',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_e = documentList.unrelated_explanations) === null || _e === void 0 ? void 0 : _e.map(function (id) {
                    return {
                        state: 'patients.unrelated-explanations.view',
                        stateParams: { patientId: patientId, unrelatedExplanationId: id, layout: 'pdf' },
                        pdf_object_id: id,
                        pdf_object_type: 'UnrelatedExplanation',
                        wait_for_selector: '#unrelated-explanation-view',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_f = documentList.care_plan_problems) === null || _f === void 0 ? void 0 : _f.map(function (id) {
                    return {
                        state: 'patients.care-plan-problem.view',
                        stateParams: { patientId: patientId, id: id, print: true },
                        pdf_object_id: id,
                        pdf_object_type: 'CarePlanProblem',
                        wait_for_selector: '#care-plan-problem-view',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_g = documentList.physician_orders) === null || _g === void 0 ? void 0 : _g.map(function (ids) {
                    return _this.generatePhysicianOrderState(ids.orderId, patientId ? patientId : ids.patientId, pdfOptions.physician_signature, pdfOptions.rph_signature);
                })) || []);
                console.log(arrPdfs);
                arrPdfs = arrPdfs.concat(((_h = documentList.plan_of_cares) === null || _h === void 0 ? void 0 : _h.map(function (id) {
                    return {
                        state: 'patients.plan-of-care-view',
                        stateParams: { patientId: patientId, id: id, print: true },
                        pdf_object_id: id,
                        pdf_object_type: 'PlanOfCare',
                        wait_for_selector: '#plan-of-care-view',
                    };
                })) || []);
                documentList.certifications =
                    documentList.certification && !documentList.certifications
                        ? documentList.certification
                        : documentList.certifications;
                arrPdfs = arrPdfs.concat(((_j = documentList.certifications) === null || _j === void 0 ? void 0 : _j.map(function (id) {
                    return {
                        url: "/patients/" + patientId + "/certifications/" + id + "?layout=pdf&show_attending_physician_signature_line=" + pdfOptions.show_attending_physician_signature_line,
                        pdf_object_id: id,
                        pdf_object_type: 'Certification',
                        wait_for_selector: '#certification_alert_info',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_k = documentList.discharge_summaries) === null || _k === void 0 ? void 0 : _k.map(function (id) {
                    return {
                        state: 'patients.discharge-summary.discharge-summary-view',
                        stateParams: {
                            patientId: patientId,
                            dischargeId: id,
                            print: true,
                            layout: 'pdf'
                        },
                        wait_for_selector: '#summaryLoaded',
                        pdf_object_id: id,
                        pdf_object_type: 'DischargeSummary',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_l = documentList.dme_orders) === null || _l === void 0 ? void 0 : _l.map(function (id) {
                    return {
                        url: "/patients/" + patientId + "/dme_orders/" + id + "?layout=pdf",
                        pdf_object_id: id,
                        pdf_object_type: 'DmeOrder',
                        wait_for_selector: '#common_alert_info',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_m = documentList.idg_summaries) === null || _m === void 0 ? void 0 : _m.map(function (id) {
                    return {
                        url: "/patients/" + patientId + "/idg_snapshots/" + id + "/summary?layout=pdf",
                        pdf_object_id: id,
                        pdf_object_type: 'IdgSnapshot',
                    };
                })) || []);
                arrPdfs = arrPdfs.concat(((_o = documentList.patient_documents) === null || _o === void 0 ? void 0 : _o.map(function (id) {
                    return {
                        url: "/api/v1/patient_documents/" + id + "/download",
                        pdf_object_id: id,
                        pdf_object_type: 'PatientDocument',
                    };
                })) || []);
                if (pdfOptions.include_last_page_signature_line) {
                    for (var _i = 0, arrPdfs_1 = arrPdfs; _i < arrPdfs_1.length; _i++) {
                        var s = arrPdfs_1[_i];
                        if (s.stateParams) {
                            s.stateParams['include_last_page_signature_line'] = 1;
                        }
                    }
                }
                if (sendFax) {
                    return this.generateCombinedPdf(arrPdfs, null, null, null, pdfOptions, patientId, faxSelection.faxable_id, faxSelection.faxable_type);
                }
                else {
                    return this.generateCombinedPdf(arrPdfs, null, true, null, pdfOptions, patientId);
                }
            };
            PdferService.prototype.generatePhysicianOrderState = function (id, patientId, physician_signature, rph_signature) {
                var p = {
                    state: 'patients.physician-orders.view',
                    stateParams: { patientId: patientId, id: id, print: true },
                    pdf_object_id: id,
                    pdf_object_type: 'PhysicianOrder',
                    wait_for_selector: '#physician-order-view',
                };
                if (rph_signature) {
                    p.stateParams['rph_signature'] = 1;
                }
                if (physician_signature) {
                    p.stateParams['physician_signature'] = 1;
                }
                return p;
            };
            PdferService.prototype.buildQueryStringForCoverSheet = function (faxSelection) {
                var result_query_string = '';
                var use_office_for_cover_sheet = faxSelection.use_office_for_cover_sheet ? '1' : '0';
                result_query_string = result_query_string.concat("use_office_for_cover_sheet=" + use_office_for_cover_sheet);
                var faxable_id = faxSelection.faxable_id ? faxSelection.faxable_id : '0';
                result_query_string = result_query_string.concat("&faxable_id=" + faxable_id);
                var faxable_type = faxSelection.faxable_type ? faxSelection.faxable_type : '';
                result_query_string = result_query_string.concat("&faxable_type=" + faxable_type);
                var additional_instructions = faxSelection.additional_instructions
                    ? encodeURIComponent(faxSelection.additional_instructions)
                    : '';
                result_query_string = result_query_string.concat("&additional_instructions=" + additional_instructions);
                return result_query_string;
            };
            PdferService.$inject = ['$http', '$window', '$state', '$mdDialog', 'logger', 'SessionService'];
            return PdferService;
        }());
        services.PdferService = PdferService;
        angular.module('consolo').service('pdfer', PdferService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('ratingList', {
            require: {
                ngModelCtrl: 'ngModel',
            },
            bindings: { max: '<', ngModel: '<', label: '@', starIcon: '<', unableModel: '<' },
            templateUrl: '/y/templates/common/rating-list/rating-list.html',
            controller: 'RatingListController',
        });
        var RatingListController = /** @class */ (function () {
            function RatingListController() {
            }
            RatingListController.$inject = [];
            return RatingListController;
        }());
        components.RatingListController = RatingListController;
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var CahpsTrackingService = /** @class */ (function () {
            function CahpsTrackingService($resource) {
                this.$resource = $resource;
            }
            CahpsTrackingService.prototype.get = function () {
                return this.$resource('/api/v1/cahps_tracking');
            };
            CahpsTrackingService.$inject = ['$resource'];
            return CahpsTrackingService;
        }());
        services.CahpsTrackingService = CahpsTrackingService;
        angular.module('consolo').service('CahpsTrackingService', CahpsTrackingService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var DataAccessService = /** @class */ (function () {
            function DataAccessService($resource) {
                this.$resource = $resource;
            }
            DataAccessService.prototype.getCarePlanProblemResource = function () {
                return this.$resource('/api/v1/care_plan_problems/:id', { id: '@id' }, {
                    update: { method: 'PATCH', isArray: false },
                });
            };
            DataAccessService.prototype.getOfflineDashboardResource = function () {
                return this.$resource('/api/v1/patients/offline_dashboard', {
                    office_ids: '@office_ids',
                    care_statuses: '@care_statuses',
                    assigned_patients: '@assigned_patients',
                }, {
                    offline_dashboard_counts: {
                        method: 'GET',
                        url: '/api/v1/patients/offline_dashboard_counts',
                    },
                });
            };
            DataAccessService.prototype.getOfficeResource = function () {
                return this.$resource('/api/v1/offices', {
                    assigned: '@assigned',
                    active_patient_count: '@active_patient_count',
                    pending_never_admitted_patient_count: '@pending_never_admitted_patient_count',
                });
            };
            DataAccessService.prototype.getUserDashboard = function () {
                return this.$resource('/api/v1/dashboard', { include_first_page: '@include_first_page', include_ids: true }, {
                    query: { method: 'GET', isArray: false },
                });
            };
            DataAccessService.prototype.getUserDashboardPatients = function () {
                return this.$resource('/api/v1/dashboard/patients', {
                    page: '@page',
                    pageLimit: '@pageLimit',
                });
            };
            DataAccessService.prototype.getAssignedPatientCount = function () {
                return this.$resource('/api/v1/patients/assigned_count');
            };
            DataAccessService.prototype.getMDDashboardPatients = function () {
                return this.$resource('/api/v1/dashboard/patients_by_role', {
                    role: 'MD Director',
                });
            };
            DataAccessService.prototype.getMDDashboardPatientsById = function () {
                return (this.$resource('/api/v1/dashboard/md_dashboard_patients_by_id', { role: 'MD Director' }));
            };
            DataAccessService.prototype.getMDDashboardPatientsCount = function () {
                return this.$resource('/api/v1/dashboard/patients_by_role_count', { role: 'MD Director' }, {
                    query: { method: 'GET', isArray: false },
                });
            };
            DataAccessService.prototype.getPatientDashboard = function () {
                return this.$resource('/api/v1/patients/:id/dashboard', { id: '@id' }, {
                    query: { method: 'GET', isArray: false },
                });
            };
            DataAccessService.$inject = ['$resource'];
            return DataAccessService;
        }());
        services.DataAccessService = DataAccessService;
        angular.module('consolo').service('dataAccessService', DataAccessService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var FilterService = /** @class */ (function () {
            function FilterService($http) {
                var _this = this;
                this.$http = $http;
                this.$http.get('/api/v1/teams').then(function (teams) {
                    _this.teamSelection = [{ id: null, description: '' }];
                    teams.data.forEach(function (team) {
                        _this.teamSelection.push({ id: team.id, description: team.name });
                    });
                });
                this.scheduleSelection = [
                    { id: null, description: '' },
                    { id: '1', description: 'Schedule 1' },
                    { id: '2', description: 'Schedule 2' },
                    { id: '3', description: 'Schedule 3' },
                    { id: '4', description: 'Schedule 4' },
                    { id: '5', description: 'Schedule 5' },
                ];
                this.signatureSelection = [
                    { id: null, description: '' },
                    { id: 'needs_nurse_sig', description: 'Needs Nurse Signature' },
                    { id: 'needs_physician_sig', description: 'Needs Physician Signature' },
                ];
            }
            FilterService.$inject = ['$http'];
            return FilterService;
        }());
        services.FilterService = FilterService;
        angular.module('consolo').service('FilterService', FilterService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        // If you change this for testing, return to 30 before committing!
        common.OfflineSessionLength = 30 * 60;
        var OfflineAccessTokenExtension = /** @class */ (function () {
            function OfflineAccessTokenExtension(offlineSession, networkManager, debugLog, $q) {
                this.offlineSession = offlineSession;
                this.networkManager = networkManager;
                this.debugLog = debugLog;
                this.$q = $q;
                this.extensionName = 'OfflineAccessToken';
            }
            OfflineAccessTokenExtension.prototype.onAccessTokenSet = function (token, expiresAt, claims) {
                var ssoTtl = claims['ssottl'];
                if (ssoTtl) {
                    this.offlineSession.setTtl(ssoTtl);
                }
            };
            OfflineAccessTokenExtension.prototype.interceptAccessTokenRefresh = function (refresh) {
                var _this = this;
                if (this.networkManager.isOnline()) {
                    return refresh;
                }
                else {
                    var resetTtl_1 = refresh.extraParams && !!refresh.extraParams['reset_ttl'];
                    return {
                        extraParams: refresh.extraParams,
                        result: function () { return _this.refreshOffline(resetTtl_1); },
                    };
                }
            };
            OfflineAccessTokenExtension.prototype.refreshOffline = function (resetTtl) {
                var ttl = this.offlineSession.getTtl();
                if (ttl <= 0) {
                    this.debugLog.info('OSE.refreshOffline: OFFLINE session has expired');
                    return this.$q.reject({ status: 401 });
                }
                else if (resetTtl) {
                    this.debugLog.info('OSE.refreshOffline: Resetting TTL on cached session');
                    return this.$q.when(this.tokenPack(common.OfflineSessionLength));
                }
                else {
                    this.debugLog.info("OSE.refreshOffline: " + ttl + "s TTL");
                    return this.$q.when(this.tokenPack(ttl));
                }
            };
            OfflineAccessTokenExtension.prototype.tokenPack = function (ssoTtl) {
                var iat = Math.round(new Date().getTime() / 1000) - 1;
                var claims = { ssottl: ssoTtl, iat: iat };
                return {
                    access_token: '_.' + btoa(JSON.stringify(claims)) + '._',
                    expires_in: 1,
                    id_token: null,
                };
            };
            OfflineAccessTokenExtension.$inject = [
                'OfflineSessionService',
                'NetworkManager',
                'DebugLogService',
                '$q',
            ];
            return OfflineAccessTokenExtension;
        }());
        common.OfflineAccessTokenExtension = OfflineAccessTokenExtension;
        angular.module('consolo').service('OfflineAccessTokenExtension', OfflineAccessTokenExtension);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var OfflineSessionExtension = /** @class */ (function () {
            function OfflineSessionExtension(session, offlineSession, networkManager, LoginModal, debugLog, $q) {
                this.session = session;
                this.offlineSession = offlineSession;
                this.networkManager = networkManager;
                this.LoginModal = LoginModal;
                this.debugLog = debugLog;
                this.$q = $q;
                this.extensionName = 'OSE';
            }
            OfflineSessionExtension.prototype.onSessionStart = function (sess) {
                this.debugLog.info('OSE.onSessionStart: Storing username and caching session for offline use');
                return [
                    'async',
                    this.$q.all([
                        this.offlineSession.setUsername(sess.user.email),
                        this.offlineSession.set(sess),
                    ]),
                ];
            };
            OfflineSessionExtension.prototype.onSessionResume = function (sess) {
                this.debugLog.info('OSE.onSessionResume: Caching session for offline use');
                return ['async', this.offlineSession.set(sess)];
            };
            OfflineSessionExtension.prototype.onSessionUpdate = function (sess) {
                this.debugLog.info('OSE.onSessionUpdate: Caching session for offline use');
                return ['async', this.offlineSession.set(sess)];
            };
            OfflineSessionExtension.prototype.onSessionClear = function () {
                this.debugLog.info('OSE.onSessionClear: Clearing offline session cache');
                return ['async', this.offlineSession.clear()];
                // NOTE leave username alone so that the re-login modal can use it
            };
            OfflineSessionExtension.prototype.interceptSessionCheck = function (check) {
                var _this = this;
                // Parent deemed that a check couldn't be performed (probably a missing token). We'll trust it.
                if (!check.called) {
                    return check;
                    // App is in offline mode. Attempt to return the session from storage.
                }
                else if (this.networkManager.isOffline()) {
                    this.debugLog.info('OSE.interceptSessionCheck: Consolo is OFFLINE; the cached session will be returned');
                    return {
                        called: true,
                        result: function () { return _this.checkOffline(); },
                    };
                    // The app is just coming back from offline mode and the session might have expired. Perform
                    // the normal check, but if it fails pop up a login modal and use its response as the check's.
                }
                else if (this.networkManager.wentOffline) {
                    this.debugLog.info('OSE.interceptSessionCheck: Consolo came back ONLINE; a session check will be attempted');
                    return {
                        called: true,
                        result: function () { return check.result().catch(function (resp) { return _this.allowResume(resp); }); },
                    };
                    // Everything seems normal so run the normal check.
                }
                else {
                    this.debugLog.info('OSE.interceptSessionCheck: Consolo is ONLINE; normal check commencing');
                    return check;
                }
            };
            /**
             * Fetch the offline cached session and check the timeout. If it's passed, reject with a 401 just
             * like the server would. If not, resolve with the offline session, updating the TTL as requested.
             */
            OfflineSessionExtension.prototype.checkOffline = function () {
                var _this = this;
                return this.offlineSession.get().catch(function (e) {
                    _this.debugLog.warn('OSE.checkOffline: Cached session could not be loaded', e);
                    return _this.$q.reject({ status: 500 });
                });
            };
            /**
             * Check the session on the server. If it's expired, ask the user if they want to log back in. If yes,
             * they'll be shown a login prompt. If no, the cached offline session will be returned.
             */
            OfflineSessionExtension.prototype.allowResume = function (resp) {
                var _this = this;
                if (resp.status === 401) {
                    this.debugLog.info('OSE.allowResume: Session has expired; ask if user wants to log back in');
                    return this.LoginModal.showReconnectModal().catch(function () {
                        _this.debugLog.warn('OSE.allowResume: User has chosen to remain in OFFLINE mode');
                        return _this.checkOffline();
                    });
                }
                else {
                    this.debugLog.warn("OSE.allowResume: The session check failed with a " + (resp.status || '<unknown>'), resp);
                    return this.$q.reject(resp);
                }
            };
            OfflineSessionExtension.$inject = [
                'SessionService',
                'OfflineSessionService',
                'NetworkManager',
                'LoginModal',
                'DebugLogService',
                '$q',
            ];
            return OfflineSessionExtension;
        }());
        common.OfflineSessionExtension = OfflineSessionExtension;
        angular.module('consolo').service('OfflineSessionExtension', OfflineSessionExtension);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var OfflineSessionService = /** @class */ (function () {
            function OfflineSessionService(debugLog, crypto, $q, storeService) {
                this.debugLog = debugLog;
                this.crypto = crypto;
                this.$q = $q;
                this.appStore = storeService.async('app');
            }
            // Returns the username of the most recent session
            OfflineSessionService.prototype.getUsername = function () {
                return this.appStore.getItem('username');
            };
            // Returns cached session data
            OfflineSessionService.prototype.get = function () {
                var _this = this;
                return this.appStore.getItem('session:v2').then(function (item) {
                    if (!item) {
                        return _this.$q.reject();
                    }
                    return _this.crypto.decrypt(item);
                });
            };
            // Set the username from the most recent session. This is ONLY intended to be used by the OfflineSessionExtension!
            OfflineSessionService.prototype.setUsername = function (username) {
                return this.appStore.setItem('username', username);
            };
            // Cache session data for use offline. This is ONLY intended to be used by the OfflineSessionExtension!
            OfflineSessionService.prototype.set = function (sess) {
                var _this = this;
                this.debugLog.info("Offline session cache is being set for " + sess.user.email);
                var offlineSess = (Object['assign']({ cached_at: new Date().getTime() }, sess));
                return this.crypto.encrypt(offlineSess).then(function (cryptedSession) {
                    return _this.appStore.setItem('session:v2', cryptedSession);
                }, function (e) {
                    _this.debugLog.warn("Offline session caching failed", e);
                    return _this.$q.reject(e);
                });
            };
            // Clear cached session data. This is ONLY intended to be used by the OfflineSessionExtension!
            OfflineSessionService.prototype.clear = function () {
                this.debugLog.info('Offline session cache is being cleared');
                localStorage.removeItem('offlineExpAt');
                return this.appStore.removeItem('session:v2');
            };
            OfflineSessionService.prototype.getTtl = function () {
                var now = new Date().getTime();
                var expAt = parseInt(localStorage.getItem('offlineExpAt') || '0', 10);
                return Math.round((expAt - now) / 1000);
            };
            OfflineSessionService.prototype.setTtl = function (ttl) {
                var now = new Date().getTime();
                var expAt = now + (ttl * 1000);
                localStorage.setItem('offlineExpAt', expAt.toString());
            };
            OfflineSessionService.$inject = ['DebugLogService', 'crypto', '$q', 'Store'];
            return OfflineSessionService;
        }());
        common.OfflineSessionService = OfflineSessionService;
        angular.module('consolo').service('OfflineSessionService', OfflineSessionService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var PermissionsSessionExtension = /** @class */ (function () {
            function PermissionsSessionExtension(permissions) {
                this.permissions = permissions;
                this.extensionName = 'Permissions';
            }
            PermissionsSessionExtension.prototype.onSessionData = function (sess) {
                this.permissions.set(sess.permissions);
            };
            PermissionsSessionExtension.prototype.onSessionClear = function () {
                this.permissions.clear();
            };
            PermissionsSessionExtension.$inject = ['PermissionsService'];
            return PermissionsSessionExtension;
        }());
        common.PermissionsSessionExtension = PermissionsSessionExtension;
        angular
            .module('consolo')
            .service('PermissionsSessionExtension', PermissionsSessionExtension);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var PermissionsService = /** @class */ (function () {
            function PermissionsService(Hosts, logger) {
                this.logger = logger;
                this.perms = {};
                this.isProd = Hosts.env === 'production';
            }
            // Returns true if the the action:category are allowed
            PermissionsService.prototype.can = function (action, category) {
                return !!this.perms[category] && this.perms[category].indexOf(action) > -1;
            };
            // Returns true if *any* of the action:category pairs are allowed
            PermissionsService.prototype.canAny = function (pairs) {
                var action, category;
                for (var i = 0; i < pairs.length; i++) {
                    (action = pairs[i][0]), (category = pairs[i][1]);
                    if (!!this.perms[category] && this.perms[category].indexOf(action) > -1) {
                        return true;
                    }
                }
                return false;
            };
            // Alternate callers for can and canAny for improved readability when using permissions that act as negatives
            PermissionsService.prototype.has = function (action, category) {
                return this.can(action, category);
            };
            PermissionsService.prototype.hasAny = function (pairs) {
                return this.canAny(pairs);
            };
            PermissionsService.prototype.set = function (permissions) {
                this.perms = permissions;
            };
            PermissionsService.prototype.clear = function () {
                this.perms = {};
            };
            PermissionsService.$inject = ['Hosts', 'logger'];
            return PermissionsService;
        }());
        common.PermissionsService = PermissionsService;
        angular.module('consolo').service('PermissionsService', PermissionsService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var PubSubSessionExtension = /** @class */ (function () {
            function PubSubSessionExtension(pubSub, debugLog) {
                var _this = this;
                this.pubSub = pubSub;
                this.debugLog = debugLog;
                this.extensionName = 'PubSub';
                this.pubSub.onOpen(function () { return _this.debugLog.info('PubSub connection established'); });
                this.pubSub.onError(function (e) {
                    return _this.debugLog.warn('PubSub connection failed or died unexpectedly', e);
                });
                this.pubSub.onClose(function () { return _this.debugLog.info('PubSub connection closed'); });
            }
            PubSubSessionExtension.prototype.onSessionVisible = function (sess) {
                if (sess.pubsub) {
                    this.pubSub.connect('/xsolo/socket', sess.pubsub.token);
                }
            };
            PubSubSessionExtension.prototype.onSessionClear = function () {
                this.pubSub.disconnect();
            };
            PubSubSessionExtension.$inject = ['PubSubService', 'DebugLogService'];
            return PubSubSessionExtension;
        }());
        common.PubSubSessionExtension = PubSubSessionExtension;
        angular.module('consolo').service('PubSubSessionExtension', PubSubSessionExtension);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        /**
         * "Remembers" the current Access Token after navigating away from emr-web.
         *
         * NOTE this goes against best practices, but at present users switch so much among Rails,
         * emr-web, and Nu EMR Web, constantly losing the AT is a usability issue.
         */
        var RememberTokenExtension = /** @class */ (function () {
            function RememberTokenExtension(AccessTokenService) {
                this.AccessTokenService = AccessTokenService;
                this.extensionName = 'RememberToken';
            }
            RememberTokenExtension.prototype.onAccessTokenSet = function (newToken, expiresAt, claims) {
                sessionStorage.setItem('atVal', newToken);
                sessionStorage.setItem('atExp', expiresAt.toString());
            };
            RememberTokenExtension.prototype.onAccessTokenGet = function (currentToken, expiresAt, claims) {
                if (!currentToken) {
                    var atVal = sessionStorage.getItem('atVal');
                    var atExp = parseInt(sessionStorage.getItem('atExp') || '0', 10);
                    var now = new Date().getTime();
                    if (atVal && atExp > now) {
                        var ttl = Math.round((atExp - now) / 1000);
                        this.AccessTokenService.setToken(atVal, ttl);
                    }
                }
            };
            RememberTokenExtension.prototype.clear = function () {
                sessionStorage.removeItem('atVal');
                sessionStorage.removeItem('atExp');
            };
            RememberTokenExtension.$inject = [
                'AccessTokenService',
            ];
            return RememberTokenExtension;
        }());
        common.RememberTokenExtension = RememberTokenExtension;
        angular.module('consolo').service('RememberTokenExtension', RememberTokenExtension);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var ResumeSessionModalController = /** @class */ (function () {
            function ResumeSessionModalController(ssoService, sessionService, tokenService, sessionTimer, oidcService, $q, NetworkManager, offlineSession, Pincode) {
                var _this = this;
                this.ssoService = ssoService;
                this.sessionService = sessionService;
                this.tokenService = tokenService;
                this.sessionTimer = sessionTimer;
                this.oidcService = oidcService;
                this.$q = $q;
                this.NetworkManager = NetworkManager;
                this.offlineSession = offlineSession;
                this.Pincode = Pincode;
                this.active = false;
                this.error = null;
                this.pincode = '';
                this.offlineSession.getUsername().then(function (x) { return (_this.username = x); });
                this.phase = NetworkManager.isOnline() ? 'discovery' : 'pincode';
                NetworkManager.onlineHandler.onEvent('ResumeSessionModalController', function () {
                    if (_this.phase === 'pincode') {
                        _this.error = null;
                        _this.phase = 'discovery';
                    }
                });
                NetworkManager.offlineHandler.onEvent('ResumeSessionModalController', function () {
                    if (_this.phase !== 'pincode') {
                        _this.error = null;
                        _this.phase = 'pincode';
                    }
                });
            }
            ResumeSessionModalController.prototype.logout = function () {
                this.ssoService.logout();
            };
            ResumeSessionModalController.prototype.pincodeLogin = function () {
                var _this = this;
                this.Pincode.checkPincode(this.pincode).then(function (match) {
                    if (match) {
                        _this.offlineSession.setTtl(consolo.common.OfflineSessionLength);
                        _this.offlineSession.get().then(function (sess) {
                            _this.sessionService.resume(sess);
                        }, function () {
                            _this.error = 'Unable to resume offline session. Please reconnect to the Internet.';
                            _this.active = false;
                        });
                    }
                    else {
                        _this.error = 'Incorrect pincode. Please try again';
                        _this.active = false;
                    }
                });
            };
            /** Everything below this point is a straight copy from csg-components/src/session/login-modal.controller.ts **/
            ResumeSessionModalController.prototype.checkThenDiscover = function () {
                var _this = this;
                this.error = null;
                this.active = true;
                this.tokenService
                    .refreshToken()
                    .then(function () {
                    _this.sessionTimer.set();
                    return _this.sessionService.resumeFromAccessToken('onSessionVisible');
                }, function () { return _this.discover(); })
                    .catch(function (resp) { return console.error('SSO session check failed', resp); });
            };
            ResumeSessionModalController.prototype.login = function () {
                var _this = this;
                this.error = null;
                this.active = true;
                this.ssoService
                    .login(this.username, this.password)
                    .then(function () { return _this.oidcService.silentAuth(); })
                    .then(function (tokenPack) {
                    _this.tokenService.setToken(tokenPack.access_token);
                    _this.sessionTimer.set();
                    return _this.sessionService.resumeFromAccessToken('onSessionVisible');
                })
                    .catch(function (resp) {
                    if (typeof resp === 'string') {
                        _this.error = resp;
                    }
                    else if (resp.data && resp.data['errors']) {
                        _this.error = resp.data['errors'].join('. ');
                    }
                    else {
                        _this.error = 'Unknown error';
                    }
                    _this.active = false;
                    _this.password = null;
                });
            };
            ResumeSessionModalController.prototype.cancelFed = function () {
                this.ssoService.cancelPollForToken();
                this.phase = 'discovery';
            };
            ResumeSessionModalController.prototype.discover = function () {
                var _this = this;
                this.ssoService
                    .discover(this.username)
                    .then(function (config) {
                    _this.active = false;
                    if (config.federated) {
                        _this.phase = 'federated';
                        _this.initFederatedLogin();
                    }
                    else {
                        _this.phase = 'login';
                    }
                }, function (resp) {
                    _this.error = resp.data.errors.join('. ');
                    _this.active = false;
                });
            };
            ResumeSessionModalController.prototype.initFederatedLogin = function () {
                var _this = this;
                this.oidcService.authConfig().then(function (config) {
                    if (_this.isWebView()) {
                        window.location.href = config.sso;
                    }
                    else {
                        _this.ssoUrl = config.sso + '/login/resume-federated?email=' + encodeURIComponent(_this.username);
                        _this.ssoService
                            .pollForToken()
                            .then(function () { return _this.active = true; })
                            .then(function () { return _this.oidcService.silentAuth(); })
                            .then(function (tokenPack) {
                            _this.tokenService.setToken(tokenPack.access_token);
                            _this.sessionTimer.set();
                            return _this.sessionService.resumeFromAccessToken('onSessionVisible');
                        })
                            .catch(function (error) {
                            _this.error = error;
                            _this.active = false;
                            _this.phase = 'discovery';
                        });
                    }
                });
            };
            ResumeSessionModalController.prototype.isWebView = function () {
                var userAgent = window.navigator.userAgent.toLowerCase();
                var ios = /iphone|ipod|ipad/.test(userAgent);
                if (ios) {
                    var safari = /safari/.test(userAgent);
                    return !safari;
                }
                else {
                    return false;
                }
            };
            ResumeSessionModalController.$inject = [
                'SsoService',
                'SessionService',
                'AccessTokenService',
                'SessionTimerService',
                'OidcService',
                '$q',
                'NetworkManager',
                'OfflineSessionService',
                'PincodeService',
            ];
            return ResumeSessionModalController;
        }());
        common.ResumeSessionModalController = ResumeSessionModalController;
        angular
            .module('consolo')
            .controller('ResumeSessionModalController', ResumeSessionModalController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        /**
         * Permissions helpers useful for route-level checking. They return promises that UI Router can use
         * to grant or block access.
         */
        var RouteAuthService = /** @class */ (function () {
            function RouteAuthService(permissions, Session, logger, $q) {
                this.permissions = permissions;
                this.Session = Session;
                this.logger = logger;
                this.$q = $q;
            }
            /**
             * If the current user has the permission, a promise is returned that resolves to true.
             * If not, a promise is returned that rejects with an exception. Additioanlly, a toast
             * tells the user that they're lacking authorization.
             */
            RouteAuthService.prototype.canOrAlert = function (action, category, alertMsg) {
                console.log("Checking route permission for " + action + ":" + category);
                return this.canOrAlertByValue(this.permissions.can(action, category), "Unauthorized: the " + action + ":" + category + " permission is required.", alertMsg);
            };
            /**
             * If the value passed is true, a promise is returned that resolves to true.
             * If not, a promise is returned that rejects with the provided error message. Additioanlly, a toast
             * tells the user that they're lacking authorization.
             */
            RouteAuthService.prototype.canOrAlertByValue = function (value, errorMsg, alertMsg) {
                if (value) {
                    return this.$q.when(true);
                }
                else {
                    this.logger.error(alertMsg || 'You do not have permission to access this area.');
                    return this.$q.reject(new Error(errorMsg));
                }
            };
            /**
             * Your callback will be passed the current session (if any). If your callback returns true,
             * a promise is returned that resolves to true. Otherwise a promise is returned that rejects
             * with an exception. Same if there's no active session.
             */
            RouteAuthService.prototype.checkOrAlert = function (check) {
                var sess = this.Session.get();
                if (!sess) {
                    return this.$q.reject(new Error('No session'));
                }
                else if (check(sess) === true) {
                    return this.$q.when(true);
                }
                else {
                    this.logger.error('You do not have permission to access this area.');
                    return this.$q.reject(new Error('Unauthorized'));
                }
            };
            RouteAuthService.$inject = ['PermissionsService', 'SessionService', 'logger', '$q'];
            return RouteAuthService;
        }());
        common.RouteAuthService = RouteAuthService;
        angular.module('consolo').service('RouteAuthService', RouteAuthService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var SessionDataMungerExtension = /** @class */ (function () {
            function SessionDataMungerExtension(session) {
                this.session = session;
                this.extensionName = 'SessionDataMunger';
            }
            SessionDataMungerExtension.prototype.onSessionData = function (sess) {
                sess.agency = new common.SessionAgency(sess.agency);
                if (!sess.agency.service_line) {
                    sess.agency.service_line = 'hospice';
                }
                sess.user.physician = sess.physician;
            };
            SessionDataMungerExtension.$inject = ['SessionService'];
            return SessionDataMungerExtension;
        }());
        common.SessionDataMungerExtension = SessionDataMungerExtension;
        angular
            .module('consolo')
            .service('SessionDataMungerExtension', SessionDataMungerExtension);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var SessionEnforcerService = /** @class */ (function () {
            function SessionEnforcerService($state, $rootScope, $location, NetworkManager) {
                this.$state = $state;
                this.$rootScope = $rootScope;
                this.$location = $location;
                this.NetworkManager = NetworkManager;
            }
            SessionEnforcerService.prototype.initialize = function () {
                var _this = this;
                // Check for session on FIRST route change
                this.$rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState) {
                    var isPublic = !!toState['public'];
                    if (!isPublic && !_this.$rootScope['_authChecked']) {
                        if (_this.NetworkManager.isOffline()) {
                            _this.$rootScope['_authChecked'] = true;
                            return;
                        }
                        e.preventDefault();
                        _this.$rootScope['_toState'] = toState.name;
                        _this.$rootScope['_toParams'] = toParams;
                        _this.$rootScope['_toSearch'] = _this.$location.search();
                        _this.$state.go('auth-check');
                    }
                });
            };
            SessionEnforcerService.$inject = [
                '$state',
                '$rootScope',
                '$location',
                'NetworkManager',
            ];
            return SessionEnforcerService;
        }());
        common.SessionEnforcerService = SessionEnforcerService;
        angular.module('consolo').service('SessionEnforcer', SessionEnforcerService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var SessionAgency = /** @class */ (function () {
            function SessionAgency(sessionAgency) {
                for (var _i = 0, _a = Object.keys(sessionAgency); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this[key] = sessionAgency[key];
                }
            }
            SessionAgency.prototype.isPalliative = function () {
                if (this.service_line) {
                    return this.service_line.toLowerCase() === 'palliative';
                }
                else {
                    return false;
                }
            };
            return SessionAgency;
        }());
        common.SessionAgency = SessionAgency;
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var WRMSsoAuthService = /** @class */ (function () {
            function WRMSsoAuthService($http, Session) {
                this.$http = $http;
                this.Session = Session;
                this.apiEndPoint = "/api/v1/wrm/single-sign-on";
            }
            WRMSsoAuthService.prototype.getToken = function () {
                return this.$http.get(this.apiEndPoint).then(function (response) {
                    return response;
                });
            };
            WRMSsoAuthService.prototype.setWRMUrl = function () {
                this.resource_manager_url = this.Session.get().hosts.resource_manager_url;
                this.wrmSchedulerURL = this.resource_manager_url + '/' + 'SpectraWeb/login.aspx?SingleSignOnJsonWebToken=';
            };
            WRMSsoAuthService.$inject = ['$http', 'SessionService'];
            return WRMSsoAuthService;
        }());
        common.WRMSsoAuthService = WRMSsoAuthService;
        angular.module('consolo').service('WRMSsoAuthService', WRMSsoAuthService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AlertsDashboardController = /** @class */ (function () {
            function AlertsDashboardController(alertsService) {
                var _this = this;
                this.alertsService = alertsService;
                this.initializeAlertsGrid();
                this.alertsService
                    .select()
                    .then(function (response) {
                    _this.alerts = response.data;
                })
                    .catch(function (err) {
                    console.log(err);
                });
            }
            AlertsDashboardController.prototype.initializeAlertsGrid = function () {
                this.alertsLayout = {
                    id_field: 'id',
                    headerMenu: {
                        csvContents: true,
                        items: [],
                    },
                    rowMenu: {
                        items: [],
                    },
                    columns: [
                        { title: 'Office', field: 'office_name', canSort: true, canFilter: true },
                        { title: 'Patient', field: 'patient_name', canSort: true, canFilter: true },
                        {
                            title: 'Expiration Date',
                            field: 'expiration_date',
                            canSort: true,
                            canFilter: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'Alert', field: 'message', canSort: false, canFilter: false },
                    ],
                };
            };
            AlertsDashboardController.$inject = ['alertsService'];
            return AlertsDashboardController;
        }());
        angular.module('consolo').controller('AlertsDashboardController', AlertsDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CptGroupingModalController = /** @class */ (function () {
            function CptGroupingModalController(options, $mdDialog, IndicatorsService, DiagnosesService, $q) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.DiagnosesService = DiagnosesService;
                this.$q = $q;
                this.$onInit();
                if (this.grouping === null) {
                    // this.code = {
                    //   setting: '',
                    //   code: null,
                    //   time: null,
                    //   rate: null,
                    //   key_criteria_1: '',
                    //   key_criteria_2: '',
                    //   key_criteria_3: '',
                    //   visit_type: '',
                    // };
                }
                console.log(this.grouping);
            }
            CptGroupingModalController.prototype.$onInit = function () { };
            CptGroupingModalController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            CptGroupingModalController.prototype.save = function () {
                this.$mdDialog.hide(this.grouping);
            };
            CptGroupingModalController.$inject = ['options', '$mdDialog', 'IndicatorsService', 'DiagnosesService', '$q'];
            return CptGroupingModalController;
        }());
        angular.module('consolo').controller('CptGroupingModalController', CptGroupingModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CPTCode = consolo.domain.CPTCode;
        var CptModalController = /** @class */ (function () {
            function CptModalController(options, $mdDialog, IndicatorsService, DiagnosesService, $q, PayerService, FilterSearchService, PayerGroupService) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.DiagnosesService = DiagnosesService;
                this.$q = $q;
                this.PayerService = PayerService;
                this.FilterSearchService = FilterSearchService;
                this.PayerGroupService = PayerGroupService;
                this.visitTypes = [
                    { visit_type: 'Initial Hospital' },
                    { visit_type: 'Subsequent Hospital' },
                    { visit_type: 'New Patient' },
                    { visit_type: 'Existing Patient' },
                    { visit_type: 'Exiting Patient' },
                ];
                this.$onInit();
                if (!this.code) {
                    this.code = new CPTCode();
                    this.code.cpt_group_id = this.grouping.id;
                }
                else {
                    this.code.cpt_group_id = this.grouping.id;
                    delete this.code.cpt_group;
                }
            }
            CptModalController.prototype.$onInit = function () { };
            CptModalController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            CptModalController.prototype.save = function () {
                this.$mdDialog.hide(this.code);
            };
            CptModalController.prototype.clearOutPayers = function () {
                this.code.unified_payer_ids = [];
            };
            CptModalController.$inject = [
                'options',
                '$mdDialog',
                'IndicatorsService',
                'DiagnosesService',
                '$q',
                'PayerService',
                'FilterSearchService',
                'PayerGroupService',
            ];
            return CptModalController;
        }());
        angular.module('consolo').controller('CptModalController', CptModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('cptCode', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/dashboard/cpt-coding/cpt-code.html',
            controller: 'CptCodeController as $ctrl',
        });
        var CptCodeController = /** @class */ (function () {
            function CptCodeController(PayerService, FilterSearchService, PayerGroupService, $state, CPTCodingService, logger, options) {
                var _this = this;
                this.PayerService = PayerService;
                this.FilterSearchService = FilterSearchService;
                this.PayerGroupService = PayerGroupService;
                this.$state = $state;
                this.CPTCodingService = CPTCodingService;
                this.logger = logger;
                this.options = options;
                this.visitTypes = [
                    { visit_type: 'Initial Hospital' },
                    { visit_type: 'Subsequent Hospital' },
                    { visit_type: 'New Patient' },
                    { visit_type: 'Existing Patient' },
                    { visit_type: 'Exiting Patient' },
                ];
                if ($state.params.cptCodeId) {
                    CPTCodingService.getCptCode($state.params.cptCodeId).then(function (cptCode) {
                        _this.cptCode = cptCode;
                        _this.cptCode.rate = Number(_this.cptCode.rate);
                    });
                }
                else {
                    this.cptCode = new consolo.domain.CPTCode();
                }
                this.ratesTableLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Rate',
                            field: 'rate',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (cptCodeRate) {
                                return common.moneyDisplay(cptCodeRate.rate);
                            },
                        },
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (cptCodeRate) { },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (cptCodeRate) { },
                            },
                        ],
                    },
                };
            }
            CptCodeController.prototype.removeRate = function ($index, rate) {
                this.cptCode.cpt_code_rates.splice($index, 1);
                if (rate.id) {
                    this.cptCode.cpt_code_rate_ids.splice(this.cptCode.cpt_code_rate_ids.indexOf(rate.id), 1);
                }
            };
            CptCodeController.prototype.addRate = function () {
                this.cptCode.cpt_code_rates.unshift({ effective_date: new Date() });
            };
            CptCodeController.prototype.save = function () {
                var _this = this;
                this.cptCode.revenue_code = this.revenue_code ? this.revenue_code.ub92_code : '';
                this.CPTCodingService.upsertCPTCode(this.cptCode)
                    .then(function () {
                    _this.logger.success('The cpt code was saved successfully.');
                    _this.$state.go('cpt-codes');
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            CptCodeController.prototype.clearOutPayers = function () {
                this.cptCode.unified_payer_ids = [];
            };
            CptCodeController.prototype.setRevenueCode = function (code) {
                if (code) {
                    this.revenue_code = code;
                }
            };
            CptCodeController.$inject = [
                'PayerService',
                'FilterSearchService',
                'PayerGroupService',
                '$state',
                'CPTCodingService',
                'logger',
                'options'
            ];
            return CptCodeController;
        }());
        angular.module('consolo').controller('CptCodeController', CptCodeController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CptCodesDataTableService = /** @class */ (function (_super) {
            __extends(CptCodesDataTableService, _super);
            function CptCodesDataTableService($http, $interval, $state, logger, CPTCodingService) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.logger = logger;
                _this.CPTCodingService = CPTCodingService;
                _this.loadingData = false;
                _this.title = 'CPT Codes';
                _this.showFilters = true;
                _this.hideSearchTerm = true;
                _this.searchOnReset = true;
                _this.activate();
                return _this;
            }
            CptCodesDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/cpt_codes';
                this.filters = new consolo.domain.BillableCodesFilter();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {},
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Code',
                            field: 'code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        { title: 'Modifier', field: 'modifier', type: csg.datatable.ColumnTypes.short_text },
                        {
                            title: 'Amount',
                            field: 'amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (cptCode) {
                                return consolo.common.moneyDisplay(cptCode.rate);
                            },
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            type: csg.datatable.ColumnTypes.long_text,
                        },
                        {
                            title: 'Payer Type',
                            field: 'insurance_carrier.name',
                            type: 'short-text',
                        },
                        {
                            title: 'Payer Name(s)',
                            field: 'insurance',
                            type: 'short-text',
                            formatValueFn: function (cptCode) {
                                if (cptCode.unified_payers.length) {
                                    var names_1 = {};
                                    cptCode.unified_payers.forEach(function (payer) {
                                        if (!names_1[payer.payer_name.toLowerCase()]) {
                                            names_1[payer.payer_name.toLowerCase()] = payer.payer_name;
                                        }
                                    });
                                    var keys = Object.keys(names_1);
                                    return keys
                                        .map(function (key) {
                                        return names_1[key];
                                    })
                                        .join(', ');
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (cptCode) {
                                    return _this.$state.go('cpt-codes.edit', { cptCodeId: cptCode.id });
                                },
                                conditionalHideFn: function (cptCode) {
                                    return !_this.CPTCodingService.updatePermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (cptCode) {
                                    return _this.CPTCodingService.deleteCPTCode(cptCode.id)
                                        .then(function (deletedCode) {
                                        _this.search();
                                        _this.logger.success('The code has been deleted.');
                                    })
                                        .catch(function (errors) {
                                        _this.logger.error('There were errors encountered');
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.CPTCodingService.deletePermission;
                                },
                            },
                        ],
                    },
                };
            };
            CptCodesDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.params = this.filters.getFilters();
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                            _this.loadingData = true;
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            CptCodesDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.BillableCodesFilter();
                this.search();
            };
            CptCodesDataTableService.$inject = ['$http', '$interval', '$state', 'logger', 'CPTCodingService'];
            return CptCodesDataTableService;
        }(csg.datatable.ApiService));
        services.CptCodesDataTableService = CptCodesDataTableService;
        angular.module('consolo').service('CptCodesDataTableService', CptCodesDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('cptCodes', {
            bindings: {},
            templateUrl: '/y/templates/dashboard/cpt-coding/cpt-codes.html',
            controller: 'CptCodesIndexController as $ctrl',
        });
        var CptCodesIndexController = /** @class */ (function () {
            function CptCodesIndexController(CptCodesDataTableService, CPTCodingService, logger) {
                this.CptCodesDataTableService = CptCodesDataTableService;
                this.CPTCodingService = CPTCodingService;
                this.logger = logger;
                this.title = 'Import ' + this.CPTCodingService.label + 's';
                this.csvFields = [
                    'Rate',
                    'Code',
                    'Description',
                    'Payer Type',
                    'Payer Name (To import multiple use ; as a delimiter)',
                    'Effective Date',
                    'Expiration Date',
                    'Modifier',
                ];
                this.uploadPath = '/api/v1/cpt_codes/import';
                this.showImport = false;
            }
            CptCodesIndexController.$inject = ['CptCodesDataTableService', 'CPTCodingService', 'logger'];
            return CptCodesIndexController;
        }());
        common.CptCodesIndexController = CptCodesIndexController;
        angular.module('consolo').controller('CptCodesIndexController', CptCodesIndexController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CptDashboardController = /** @class */ (function () {
            function CptDashboardController($http, FiltersService, EmarService, $state, $window, CPTCodingService, $q, $mdDialog, logger) {
                this.$http = $http;
                this.FiltersService = FiltersService;
                this.EmarService = EmarService;
                this.$state = $state;
                this.$window = $window;
                this.CPTCodingService = CPTCodingService;
                this.$q = $q;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.cptGroups = [];
                this.cptCodes = [];
                this.cptGroupList = {};
                this.isLoading = false;
                this.title = 'Import CPT Codes';
                this.csvFields = [
                    'CPT Group',
                    'Visit Type',
                    'Rate',
                    'Code',
                    'Payer Type',
                    'Payer Name',
                    'Time',
                    'Effective Date',
                ];
                this.importPath = '/api/v1/cpt_codes/import';
                this.initializePageData();
            }
            CptDashboardController.prototype.initializePageData = function () {
                var _this = this;
                this.isLoading = true;
                var promiseChain = [this.getAllCPTCodes(), this.getAllCPTCodeGroups()];
                this.$q.all(promiseChain).finally(function () {
                    _this.setCPTCodes(_this.cptGroups, _this.cptCodes).then(function (response) {
                        _this.isLoading = false;
                    });
                });
            };
            CptDashboardController.prototype.getAllCPTCodes = function () {
                var _this = this;
                return this.CPTCodingService.getAllCPTCodes()
                    .then(function (cptCodes) {
                    _this.cptCodes = cptCodes;
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            CptDashboardController.prototype.setCPTCodes = function (groups, cptCodes) {
                var _this = this;
                return this.$q.resolve(groups.forEach(function (group) {
                    _this.cptGroupList[group.visit_type] = cptCodes.filter(function (code) {
                        return code.cpt_group.id === group.id;
                    });
                }));
            };
            CptDashboardController.prototype.getAllCPTCodeGroups = function () {
                var _this = this;
                return this.CPTCodingService.getAllCPTCodeGroups()
                    .then(function (cptGroups) {
                    _this.cptGroups = cptGroups;
                })
                    .catch(function (error) {
                    _this.logger.error(error);
                });
            };
            CptDashboardController.prototype.deleteCPT = function (cpt) {
                var _this = this;
                this.CPTCodingService.deleteCPTCode(cpt.id)
                    .then(function (response) {
                    _this.initializePageData();
                })
                    .catch(function (error) {
                    _this.logger.error(error, 'Error:');
                });
            };
            CptDashboardController.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            CptDashboardController.prototype.showCPTModal = function (code, cptGroup) {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/dashboard/cpt-coding/add-edit-cpt-modal.html',
                    controller: 'CptModalController as vm',
                    bindToController: true,
                    multiple: true,
                    locals: {
                        code: code,
                        grouping: cptGroup,
                    },
                })
                    .then(function (code) {
                    console.log(code);
                    var save = code.id
                        ? _this.CPTCodingService.updateCPTCode(code)
                        : _this.CPTCodingService.addCPTCode(code);
                    save
                        .then(function (response) {
                        console.log(response);
                        _this.initializePageData();
                    })
                        .catch(function (error) {
                        console.log(error);
                        _this.logger.error(error, 'Error:');
                    });
                });
            };
            CptDashboardController.prototype.showCPTGroupingModal = function (cptSection) {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/dashboard/cpt-coding/add-edit-cpt-grouping-modal.html',
                    controller: 'CptGroupingModalController as vm',
                    bindToController: true,
                    multiple: true,
                    locals: {
                        grouping: cptSection,
                    },
                })
                    .then(function (grouping) {
                    console.log(grouping);
                    var save = grouping.id
                        ? _this.CPTCodingService.updateCPTGrouping(grouping)
                        : _this.CPTCodingService.addCPTGrouping(grouping);
                    save
                        .then(function (response) {
                        console.log(response);
                        _this.initializePageData();
                    })
                        .catch(function (error) {
                        console.log(error);
                        _this.logger.error(error, 'Error:');
                    });
                });
            };
            CptDashboardController.$inject = [
                '$http',
                'FiltersService',
                'EmarService',
                '$state',
                '$window',
                'CPTCodingService',
                '$q',
                '$mdDialog',
                'logger',
            ];
            return CptDashboardController;
        }());
        angular.module('consolo').controller('CptDashboardController', CptDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var CPTCodingService = /** @class */ (function () {
            function CPTCodingService($q, $http, PermissionsService, options, Session, hasUpdatedAgencyFeeSchedule) {
                var _a;
                this.$q = $q;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.options = options;
                this.Session = Session;
                this.hasUpdatedAgencyFeeSchedule = hasUpdatedAgencyFeeSchedule;
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.label = 'Billable Code';
                this.updatePermission = this.PermissionsService.can('update', 'billable_codes');
                this.createPermission = this.PermissionsService.can('create', 'billable_codes');
                this.deletePermission = this.PermissionsService.can('destroy', 'billable_codes');
                this.viewPermission = this.PermissionsService.can('index', 'billable_codes');
                this.hasUpdatedAgencyFeeSchedule = (_a = this.Session.get().agency.has_updated_agency_fee_schedule, (_a !== null && _a !== void 0 ? _a : false));
                this.level_of_cares = options.get('level_of_cares');
            }
            CPTCodingService.prototype.getCptCode = function (id) {
                return this.$http.get('/api/v1/cpt_codes/' + id).then(function (response) {
                    return response.data;
                });
            };
            CPTCodingService.prototype.getAllCPTCodes = function (q, unified_payer_id, insurance_carrier_id, effective_date, patient_id) {
                return this.$http
                    .get('/api/v1/cpt_codes', {
                    params: {
                        q: q,
                        unified_payer_id: unified_payer_id,
                        insurance_carrier_id: insurance_carrier_id,
                        effective_date: effective_date,
                        patient_id: patient_id
                    },
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            CPTCodingService.prototype.getAllCPTCodesAndModifiers = function (q, unified_payer_id, insurance_carrier_id, effective_date, patient_id) {
                var _this = this;
                return this.$http
                    .get('/api/v1/cpt_codes', {
                    params: {
                        q: q,
                        unified_payer_id: unified_payer_id,
                        insurance_carrier_id: insurance_carrier_id,
                        effective_date: effective_date,
                        patient_id: patient_id,
                    },
                })
                    .then(function (response) {
                    var results = response.data;
                    var billableCodes = [];
                    results.forEach(function (code) {
                        billableCodes.push({ id: code.id, code: code.code, description: code.description, modifier: "", order: 0 });
                        // if using the updated agency fee schedule, show options for each modifier
                        if (_this.hasUpdatedAgencyFeeSchedule == true) {
                            if (code.modifier) {
                                billableCodes.push({ id: code.id, code: code.code, description: code.description, modifier: code.modifier, order: 1 });
                            }
                            if (code.modifier_2) {
                                billableCodes.push({ id: code.id, code: code.code, description: code.description, modifier: code.modifier_2, order: 2 });
                            }
                            if (code.modifier_3) {
                                billableCodes.push({ id: code.id, code: code.code, description: code.description, modifier: code.modifier_3, order: 3 });
                            }
                        }
                    });
                    return billableCodes;
                });
            };
            CPTCodingService.prototype.getAllCPTCodeGroups = function () {
                return this.$http.get('/api/v1/cpt_groups').then(function (response) {
                    return response.data;
                });
            };
            CPTCodingService.prototype.addCPTCode = function (code) {
                return this.$http.post('/api/v1/cpt_codes', code).then(function (response) {
                    return response;
                });
            };
            CPTCodingService.prototype.addCPTGrouping = function (group) {
                return this.$http.post('/api/v1/cpt_groups', group).then(function (response) {
                    return response;
                });
            };
            CPTCodingService.prototype.updateCPTCode = function (code) {
                return this.$http.patch('/api/v1/cpt_codes/' + code.id, code).then(function (response) {
                    return response;
                });
            };
            CPTCodingService.prototype.upsertCPTCode = function (code) {
                if (code.id) {
                    return this.updateCPTCode(code);
                }
                else {
                    return this.addCPTCode(code);
                }
            };
            CPTCodingService.prototype.updateCPTGrouping = function (group) {
                return this.$http.patch('/api/v1/cpt_groups/' + group.id, group).then(function (response) {
                    return response;
                });
            };
            CPTCodingService.prototype.deleteCPTCode = function (codeId) {
                return this.$http.delete('/api/v1/cpt_codes/' + codeId).then(function (response) {
                    return response.data;
                });
            };
            CPTCodingService.prototype.deleteCPTGrouping = function (groupId) {
                return this.$http.delete('/api/v1/cpt_groups/' + groupId).then(function (response) {
                    return response.data;
                });
            };
            CPTCodingService.prototype.levelOfCareLookup = function (q) {
                return this.level_of_cares.filter(function (level_of_care) {
                    return q.length === 0 ||
                        level_of_care.description.toLowerCase().includes(q.toLowerCase()) ||
                        level_of_care.code.toLowerCase().includes(q.toLowerCase()) ||
                        (level_of_care.short_code && level_of_care.short_code.toLowerCase().includes(q.toLowerCase())) ||
                        level_of_care.ub92_code.toLowerCase().includes(q.toLowerCase());
                });
            };
            CPTCodingService.$inject = ['$q', '$http', 'PermissionsService', 'options', 'SessionService'];
            return CPTCodingService;
        }());
        common.CPTCodingService = CPTCodingService;
        angular.module('consolo').service('CPTCodingService', CPTCodingService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var EmarDashboardController = /** @class */ (function () {
            function EmarDashboardController($http, FiltersService, EmarService, $state, $window) {
                this.$http = $http;
                this.FiltersService = FiltersService;
                this.EmarService = EmarService;
                this.$state = $state;
                this.$window = $window;
                this.teamSelection = null;
                this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                this.detailMode = false;
                this.scrollableContent = document.getElementById('emar-content');
                this.$window.addEventListener('resize', this.setHourWidthMultiplier);
                // angular.element($window).on('scroll', () => {
                //   this.getVerticalScrollPosition;
                // });
                window.onscroll = this.getVerticalScrollPosition;
                this.screenWidth();
                this.initializeEmarService();
            }
            EmarDashboardController.prototype.initializeEmarService = function () {
                this.EmarService.getAssignedPatientsOnEmar();
                this.EmarService.emarLoader = true;
            };
            EmarDashboardController.prototype.getPatientsByHourTimeFrame = function (timeFrameIndex) {
                var filteredPatients = [];
                this.EmarService.patients.forEach(function (patient) {
                    if (patient.meds_by_hour[timeFrameIndex].count) {
                        filteredPatients.push(patient);
                    }
                });
                return filteredPatients;
            };
            EmarDashboardController.prototype.screenWidth = function () {
                if (typeof window.innerWidth !== 'undefined') {
                    this.viewportwidth = window.innerWidth;
                    this.viewportheight = window.innerHeight;
                }
                else if (typeof document.documentElement !== 'undefined' &&
                    typeof document.documentElement.clientWidth !== 'undefined' &&
                    document.documentElement.clientWidth !== 0) {
                    this.viewportwidth = document.documentElement.clientWidth;
                    this.viewportheight = document.documentElement.clientHeight;
                }
                else {
                    this.viewportwidth = document.getElementsByTagName('body')[0].clientWidth;
                    this.viewportheight = document.getElementsByTagName('body')[0].clientHeight;
                }
                this.setSidenav();
            };
            EmarDashboardController.prototype.setSidenav = function () {
                if (this.viewportwidth > 600) {
                    this.$state.current.data.hideSidebar = true;
                }
                else {
                    this.$state.current.data.hideSidebar = false;
                }
            };
            EmarDashboardController.prototype.setHourWidthMultiplier = function () {
                var viewportwidth = window.innerWidth;
                if (viewportwidth <= 600) {
                    return 12;
                }
                else if (viewportwidth > 600 && viewportwidth <= 700) {
                    return 6;
                }
                else if (viewportwidth > 700 && viewportwidth <= 900) {
                    return 4;
                }
                else if (viewportwidth > 900 && viewportwidth <= 1024) {
                    return 2;
                }
                else {
                    return 1;
                }
            };
            EmarDashboardController.prototype.getVerticalScrollPosition = function () {
                if (window.pageYOffset > 88) {
                    return 'Yes';
                }
                else if (window.pageYOffset < 88) {
                    return 'No';
                }
            };
            EmarDashboardController.$inject = ['$http', 'FiltersService', 'EmarService', '$state', '$window'];
            return EmarDashboardController;
        }());
        angular.module('consolo').controller('EmarDashboardController', EmarDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ApprovePriorAuthorizationController = /** @class */ (function () {
            function ApprovePriorAuthorizationController($mdDialog, medicationTransaction) {
                this.$mdDialog = $mdDialog;
                this.medicationTransaction = medicationTransaction;
            }
            ApprovePriorAuthorizationController.prototype.update = function () {
                if (this.medicationTransaction.prior_authorization_number) {
                    return this.$mdDialog.hide(this.medicationTransaction);
                }
                else {
                    this.errors = ['You must enter a Prior Authorization Number.'];
                }
            };
            ApprovePriorAuthorizationController.$inject = ['$mdDialog', 'medicationTransaction'];
            return ApprovePriorAuthorizationController;
        }());
        angular
            .module('consolo')
            .controller('ApprovePriorAuthorizationController', ApprovePriorAuthorizationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DenyRenewalController = /** @class */ (function () {
            function DenyRenewalController($mdDialog, medicationTransaction, MedicationService) {
                this.$mdDialog = $mdDialog;
                this.medicationTransaction = medicationTransaction;
                this.MedicationService = MedicationService;
            }
            DenyRenewalController.prototype.update = function () {
                if (this.medicationTransaction.reason_for_denial) {
                    return this.$mdDialog.hide(this.medicationTransaction);
                }
                else {
                    this.errors = ['You must select a reason for denial.'];
                }
            };
            DenyRenewalController.prototype.setDenial = function () {
                this.medicationTransaction.denial_code = this.selectedCode.id;
                this.medicationTransaction.reason_for_denial = this.selectedCode.description;
            };
            DenyRenewalController.$inject = ['$mdDialog', 'medicationTransaction', 'MedicationService'];
            return DenyRenewalController;
        }());
        angular.module('consolo').controller('DenyRenewalController', DenyRenewalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('eprescribeDashboard', {
            bindings: {},
            templateUrl: '/y/templates/dashboard/eprescribe/eprescribe-dashboard.html',
            controller: 'EprescribeDashboardController as $ctrl',
        });
        var EprescribeDashboardController = /** @class */ (function () {
            function EprescribeDashboardController(logger, $http, $mdMenu, $mdDialog, EprescribeService, MedicationService, Session) {
                this.logger = logger;
                this.$http = $http;
                this.$mdMenu = $mdMenu;
                this.$mdDialog = $mdDialog;
                this.EprescribeService = EprescribeService;
                this.MedicationService = MedicationService;
                this.Session = Session;
                this.loading = false;
                var user = Session.get().user;
                if (user.physician) {
                    this.physician = user.physician;
                }
                this.search();
                this.transactionTypes = [
                    { value: null, description: '' },
                    { value: 'rx_renewal', description: 'Renewal' },
                    { value: 'rx_change', description: 'Change' },
                ];
            }
            EprescribeDashboardController.prototype.search = function () {
                var _this = this;
                var physician_id = this.physician ? this.physician.id : null;
                var transactionType = this.selectedTransactionType
                    ? this.selectedTransactionType.value
                    : null;
                this.loading = true;
                this.$http
                    .get('/api/v1/dashboard/eprescribe', {
                    params: {
                        physician_id: physician_id,
                        query: this.searchTerm,
                        transaction_type: transactionType,
                    },
                })
                    .then(function (response) {
                    _this.patients = response.data.map(function (patient) {
                        patient.medication_transactions = patient.medication_transactions.map(function (transaction) {
                            transaction = new consolo.domain.MedicationTransaction(transaction);
                            transaction.medication = new consolo.domain.Medication(transaction.medication);
                            return transaction;
                        });
                        return patient;
                    });
                })
                    .finally(function () {
                    _this.loading = false;
                });
            };
            EprescribeDashboardController.prototype.approveWithChanges = function (medicationTransaction) {
                var _this = this;
                return this.EprescribeService.approveWithChanges(medicationTransaction)
                    .then(function (response) {
                    _this.removeTransaction(medicationTransaction);
                    _this.logger.success('The medication message has been sent with the refill change.');
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            EprescribeDashboardController.prototype.approveWithEdit = function (medicationTransaction) {
                return this.MedicationService.editMedicationModal(medicationTransaction.medication).then();
            };
            EprescribeDashboardController.prototype.approvePriorAuthorization = function (medicationTransaction) {
                var _this = this;
                var url = "'/y/templates/dashboard/eprescribe/prior-authorization.modal.html'";
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    bindToController: true,
                    template: '<md-dialog flex="80" aria-label="Prior Auth">' +
                        ' <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        ' </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'MedicationPriorAuthorizationController as $ctrl',
                    locals: {
                        medicationTransaction: medicationTransaction,
                    },
                })
                    .then(function (response) {
                    _this.removeTransaction(medicationTransaction);
                });
            };
            EprescribeDashboardController.prototype.deny = function (medicationTransaction) {
                var _this = this;
                return this.EprescribeService.deny(medicationTransaction)
                    .then(function (response) {
                    _this.removeTransaction(medicationTransaction);
                    _this.logger.success('The denial medication message has been sent.');
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            EprescribeDashboardController.prototype.filterPhysicians = function (searchTerm) {
                searchTerm = searchTerm.toLowerCase();
                return this.EprescribeService.physicians.filter(function (physician) {
                    return physician.name.toLowerCase().indexOf(searchTerm) >= 0;
                });
            };
            EprescribeDashboardController.prototype.filterSearchTerm = function (searchTerm) {
                this.searchTerm = searchTerm;
                this.search();
            };
            EprescribeDashboardController.prototype.approve = function (medicationTransaction) {
                var _this = this;
                return this.EprescribeService.approve(medicationTransaction)
                    .then(function (response) {
                    _this.removeTransaction(medicationTransaction);
                    _this.logger.success('The approval medication message has been sent.');
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            EprescribeDashboardController.prototype.removeTransaction = function (medicationTransaction) {
                //Loop over all patients
                for (var p = 0; p < this.patients.length; p++) {
                    //Loop over the patient medication transactions to find the one to remove
                    for (var t = 0; this.patients[p] && t < this.patients[p].medication_transactions.length; t++) {
                        if (medicationTransaction.id === this.patients[p].medication_transactions[t].id) {
                            //Remove the transaction if it matches
                            this.patients[p].medication_transactions.splice(t, 1);
                            //Do this to kick out of loop
                            t = this.patients[p].medication_transactions.length;
                            if (this.patients[p].medication_transactions.length === 0) {
                                //Splice out a patient who has no more med transactions to review
                                this.patients.splice(p, 1);
                            }
                            //Kick out if found
                            p = this.patients.length;
                        }
                    }
                }
                this.patients = [].concat(this.patients);
            };
            EprescribeDashboardController.prototype.view = function (medicationTransaction) {
                var _this = this;
                //Get the patient
                var selectedPatient;
                this.patients.some(function (patient) {
                    if (patient.id === medicationTransaction.patient_id) {
                        selectedPatient = patient;
                        return true;
                    }
                });
                this.EprescribeService.view(medicationTransaction, selectedPatient).then(function () {
                    _this.removeTransaction(medicationTransaction);
                });
            };
            EprescribeDashboardController.prototype.formatStatus = function (medicationTransaction) {
                if (medicationTransaction.transaction_type === 'rxRenewalRequest') {
                    return 'Renewal';
                }
                else {
                    return medicationTransaction.messageDisplay() + 'Change';
                }
            };
            EprescribeDashboardController.$inject = [
                'logger',
                '$http',
                '$mdMenu',
                '$mdDialog',
                'EprescribeService',
                'MedicationService',
                'SessionService',
            ];
            return EprescribeDashboardController;
        }());
        common.EprescribeDashboardController = EprescribeDashboardController;
        angular
            .module('consolo')
            .controller('EprescribeDashboardController', EprescribeDashboardController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var EprescribeService = /** @class */ (function () {
            function EprescribeService(logger, $http, $mdDialog, Session) {
                var _this = this;
                this.logger = logger;
                this.$http = $http;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.userEpcsEnabled = false;
                this.$http.get('/api/v1/physicians').then(function (response) {
                    _this.physicians = response.data;
                });
                this.userEpcsEnabled = Session.get().user.epcs_on;
                this.ingredientsTableLayout = {
                    id_field: 'name',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'display_name',
                            type: csg.datatable.ColumnTypes.long_text,
                            formatValueFn: function (ingredient) {
                                var display = ingredient.name;
                                if (ingredient.strength && ingredient.strength_unit) {
                                    display += ' ' + ingredient.strength + ' ' + ingredient.strength_unit;
                                }
                                if (ingredient.form) {
                                    display += ' ' + ingredient.form;
                                }
                                if (ingredient.fromFormulary()) {
                                    display +=
                                        '<span class="margin-left-xs margin-right-xs label formulary">Formulary</span>';
                                }
                                if (ingredient.fromFavorites()) {
                                    display +=
                                        '<span class="margin-left-xs margin-right-xs label favorite">Favorite</span>';
                                }
                                if (ingredient.fromMedispan()) {
                                    display +=
                                        '<span class="margin-left-xs margin-right-xs label medispan">Medispan</span>';
                                }
                                if (ingredient.isControlledSubstance()) {
                                    display += '<span class="margin-left-xs label controlled">Class 2</span>';
                                }
                                else if (ingredient.controlled_substance_code) {
                                    display +=
                                        '<span class="margin-left-xs label non-controlled">Class ' +
                                            ingredient.controlled_substance_code +
                                            '</span>';
                                }
                                return display;
                            },
                        },
                        {
                            title: 'Dosage',
                            field: 'dosage',
                            formatValueFn: function (ingredient) {
                                return ingredient.quantity + ' ' + ingredient.quantity_unit_of_measure.description;
                            },
                        },
                    ],
                };
            }
            EprescribeService.prototype.approve = function (medicationTransaction) {
                return this.$http.post('/api/v1/medication_transactions/' + medicationTransaction.id + '/approve', {});
            };
            EprescribeService.prototype.approveWithMedication = function (medicationTransaction, requestedMedicationIndex) {
                return this.$http.post('/api/v1/medication_transactions/' + medicationTransaction.id + '/approve', { requested_medication_index: requestedMedicationIndex });
            };
            EprescribeService.prototype.approveWithChanges = function (medicationTransaction) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/dashboard/eprescribe/approve-with-changes.html',
                    controller: [
                        '$mdDialog',
                        function ($mdDialog) {
                            this.$mdDialog = $mdDialog;
                        },
                    ],
                    controllerAs: '$ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { medicationTransaction: medicationTransaction },
                })
                    .then(function (updatedMedicationTransaction) {
                    return _this.$http.post('/api/v1/medication_transactions/' + medicationTransaction.id + '/approve', {
                        refills: updatedMedicationTransaction.refills,
                        notes: updatedMedicationTransaction.notes,
                    });
                });
            };
            EprescribeService.prototype.deny = function (medicationTransaction) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/dashboard/eprescribe/deny.html',
                    controller: 'DenyRenewalController as $ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { medicationTransaction: medicationTransaction },
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                })
                    .then(function (updatedMedicationTransaction) {
                    return _this.$http.post('/api/v1/medication_transactions/' + medicationTransaction.id + '/deny', updatedMedicationTransaction);
                });
            };
            EprescribeService.prototype.approvePriorAuthorization = function (medicationTransaction) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/dashboard/eprescribe/approve-prior-authorization.html',
                    controller: 'ApprovePriorAuthorizationController as $ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { medicationTransaction: medicationTransaction },
                })
                    .then(function (updatedMedicationTransaction) {
                    return _this.$http.post('/api/v1/medication_transactions/' +
                        medicationTransaction.id +
                        '/approve_prior_authorization', updatedMedicationTransaction);
                });
            };
            EprescribeService.prototype.view = function (medicationTransaction, patient) {
                this.currentMedicationTransaction = medicationTransaction;
                this.currentPatient = patient;
                return this.$mdDialog.show({
                    template: '<md-dialog flex="80" aria-label="View">' +
                        '<view-eprescribe></view-eprescribe>' +
                        '</md-dialog>',
                    clickOutsideToClose: true,
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                });
            };
            EprescribeService.$inject = ['logger', '$http', '$mdDialog', 'SessionService'];
            return EprescribeService;
        }());
        services.EprescribeService = EprescribeService;
        angular.module('consolo').service('EprescribeService', EprescribeService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationPriorAuthorizationController = /** @class */ (function () {
            function MedicationPriorAuthorizationController($http, logger, $mdDialog, medicationTransaction) {
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.medicationTransaction = medicationTransaction;
            }
            MedicationPriorAuthorizationController.prototype.submit = function () {
                var _this = this;
                if (this.medicationTransaction.prior_authorization_number) {
                    return this.$http
                        .post('/api/v1/medication_transactions/' +
                        this.medicationTransaction.id +
                        '/approve_prior_authorization', {
                        prior_authorization_number: this.medicationTransaction.prior_authorization_number,
                        prior_authorization_notes: this.medicationTransaction.prior_authorization_notes,
                    })
                        .then(function (response) {
                        _this.logger.success('The prior authorization approval has been sent.');
                        return _this.$mdDialog.hide();
                    })
                        .catch(function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    });
                }
                else {
                    this.errors = ['You must enter a Prior Authorization Number.'];
                    this.logger.error('You must enter the number.');
                }
            };
            MedicationPriorAuthorizationController.$inject = ['$http', 'logger', '$mdDialog', 'medicationTransaction'];
            return MedicationPriorAuthorizationController;
        }());
        angular
            .module('consolo')
            .controller('MedicationPriorAuthorizationController', MedicationPriorAuthorizationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('twoFactor', {
            bindings: {},
            templateUrl: '/y/templates/dashboard/eprescribe/two-factor.modal.html',
            controller: 'TwoFactorController as $ctrl',
        });
        var TwoFactorController = /** @class */ (function () {
            function TwoFactorController(logger, $mdDialog, $http) {
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.consoloPw = null;
                this.tokenType = null;
                this.idProofId = null;
                this.hasHwToken = false;
                this.hasSwToken = false;
            }
            TwoFactorController.prototype.addTwoFactorSignature = function () {
                if (this.otp && this.consoloPw) {
                    this.$http.post('/api/v1/signatures/', {
                        consolo_password: this.consoloPw,
                        two_factor_password: this.otp,
                        token_type: this.tokenType,
                        identity_proof_id: this.idProofId,
                    });
                }
                else {
                    this.errors = ['The One Time Password and Consolo Password must both be entered.'];
                }
            };
            TwoFactorController.prototype.setIdentityProof = function (proof) {
                this.idProofId = proof.id;
                this.tokenType = proof.default_token_type;
                this.hasHwToken = proof.token_types.indexOf('hw') > -1;
                this.hasSwToken = proof.token_types.indexOf('sw') > -1;
            };
            TwoFactorController.$inject = ['logger', '$mdDialog', '$http'];
            return TwoFactorController;
        }());
        common.TwoFactorController = TwoFactorController;
        angular.module('consolo').controller('TwoFactorController', TwoFactorController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('viewEprescribe', {
            bindings: {},
            templateUrl: '/y/templates/dashboard/eprescribe/view.html',
            controller: 'ViewEprescribeController as $ctrl',
        });
        var ViewEprescribeController = /** @class */ (function () {
            function ViewEprescribeController($mdDialog, EprescribeService, logger, MedicationService) {
                this.$mdDialog = $mdDialog;
                this.EprescribeService = EprescribeService;
                this.logger = logger;
                this.MedicationService = MedicationService;
                this.medicationTransaction = this.EprescribeService.currentMedicationTransaction;
                this.patient = this.EprescribeService.currentPatient;
            }
            ViewEprescribeController.prototype.approveWithChanges = function () {
                var _this = this;
                return this.EprescribeService.approveWithChanges(this.medicationTransaction)
                    .then(function (response) {
                    _this.logger.success('The medication has been approved.');
                    return _this.updateMedication().then(function () {
                        return _this.$mdDialog.hide();
                    });
                })
                    .catch(function (errors) {
                    _this.logger.error(errors.data.errors.join(', '));
                });
            };
            ViewEprescribeController.prototype.approveWithMedication = function (medicationRequestedIndex) {
                var _this = this;
                return this.EprescribeService.approveWithMedication(this.medicationTransaction, medicationRequestedIndex)
                    .then(function (response) {
                    _this.logger.success('The medication has been approved.');
                    return _this.updateMedication().then(function () {
                        return _this.$mdDialog.hide();
                    });
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            ViewEprescribeController.prototype.deny = function () {
                var _this = this;
                return this.EprescribeService.deny(this.medicationTransaction).then(function (updatedMedTransaction) {
                    _this.logger.success('The request has been denied.');
                    return _this.$mdDialog.hide(updatedMedTransaction);
                });
            };
            ViewEprescribeController.prototype.approvePriorAuthorization = function (medicationTransaction) {
                var _this = this;
                var url = "'/y/templates/dashboard/eprescribe/prior-authorization.modal.html'";
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    bindToController: true,
                    template: '<md-dialog flex="80" aria-label="Prior Auth">' +
                        ' <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        ' </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'MedicationPriorAuthorizationController as $ctrl',
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                    locals: {
                        medicationTransaction: medicationTransaction,
                    },
                })
                    .then(function () {
                    return _this.updateMedication().then(function () {
                        return _this.$mdDialog.hide();
                    });
                });
            };
            ViewEprescribeController.prototype.approve = function () {
                var _this = this;
                return this.EprescribeService.approve(this.medicationTransaction)
                    .then(function (response) {
                    _this.logger.success('The medication has been approved.');
                    return _this.updateMedication().then(function () {
                        return _this.$mdDialog.hide();
                    });
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            ViewEprescribeController.prototype.updateMedication = function () {
                var _this = this;
                return this.MedicationService.get(this.medicationTransaction.medication_id).then(function (medication) {
                    return _this.MedicationService.replaceMedications([medication]);
                });
            };
            ViewEprescribeController.$inject = ['$mdDialog', 'EprescribeService', 'logger', 'MedicationService'];
            return ViewEprescribeController;
        }());
        common.ViewEprescribeController = ViewEprescribeController;
        angular.module('consolo').controller('ViewEprescribeController', ViewEprescribeController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var QueryStringFilter = consolo.domain.QueryStringFilter;
        var HumanResourcesDashboardFilters = consolo.domain.HumanResourcesDashboardFilters;
        var HumanResourcesDashboardController = /** @class */ (function (_super) {
            __extends(HumanResourcesDashboardController, _super);
            function HumanResourcesDashboardController(officeFilterService, humanResourcesDashboardExpiredDataTableService, humanResourcesDashboardExpiringDataTableService, $location, $interval, $state) {
                var _this = _super.call(this) || this;
                _this.humanResourcesDashboardExpiredDataTableService = humanResourcesDashboardExpiredDataTableService;
                _this.humanResourcesDashboardExpiringDataTableService = humanResourcesDashboardExpiringDataTableService;
                _this.$location = $location;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.initializeHumanResourcesGrid();
                officeFilterService.getInstance().then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                    _this.reset();
                });
                return _this;
            }
            HumanResourcesDashboardController.prototype.applyInitialFilters = function () {
                var _this = this;
                _super.prototype.applyInitialFilters.call(this);
                this.officeFilterServiceInstance.offices.forEach(function (office) {
                    if (_this.filters.organization_ids.indexOf(office.id.toString()) > -1) {
                        office.selected = true;
                    }
                });
            };
            HumanResourcesDashboardController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.filters.organization_ids = this.officeFilterServiceInstance.getSelectedIds();
                if (this.filters.organization_ids.length) {
                    this.filters.organization_type = 'Office';
                }
                this.updateFilterQueryStringIfNeeded();
                this.apiInterval = this.$interval(function () {
                    if (_this.humanResourcesDashboardExpiredDataTableService.tableControl &&
                        _this.humanResourcesDashboardExpiredDataTableService.tableControl.loadSelectData &&
                        _this.humanResourcesDashboardExpiringDataTableService.tableControl &&
                        _this.humanResourcesDashboardExpiringDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        Promise.all([
                            _this.humanResourcesDashboardExpiredDataTableService.tableControl.loadSelectData(),
                            _this.humanResourcesDashboardExpiringDataTableService.tableControl.loadSelectData(),
                        ])
                            .then(function () {
                            _this.isLoading = false;
                        })
                            .catch(function (err) {
                            console.log(err);
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            HumanResourcesDashboardController.prototype.reset = function () {
                this.filters = new HumanResourcesDashboardFilters();
                this.officeFilterServiceInstance.reset();
                this.applyInitialFiltersIfNeeded();
                this.humanResourcesDashboardExpiredDataTableService.params = this.filters;
                this.humanResourcesDashboardExpiringDataTableService.params = this.filters;
                this.query();
            };
            HumanResourcesDashboardController.prototype.initializeHumanResourcesGrid = function () {
                this.humanResourcesLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [],
                    },
                    rowMenu: {
                        items: [],
                    },
                    options: {
                        hideTextFilter: true,
                    },
                    columns: [
                        { title: 'Topic', field: 'human_resource_topic_description', canSort: false },
                        { title: 'User Name', field: 'user.name', canSort: true },
                        { title: 'Assignment', field: 'assignment', canSort: false },
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Expiration Date',
                            field: 'expiration_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'Notes', field: 'notes', canSort: false },
                    ],
                };
            };
            HumanResourcesDashboardController.$inject = [
                'officeFilterService',
                'humanResourcesDashboardExpiredDataTableService',
                'humanResourcesDashboardExpiringDataTableService',
                '$location',
                '$interval',
                '$state',
            ];
            return HumanResourcesDashboardController;
        }(QueryStringFilter));
        angular
            .module('consolo')
            .controller('HumanResourcesDashboardController', HumanResourcesDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var F2FInstructionsController = /** @class */ (function () {
            function F2FInstructionsController($window, Store, Session, $mdDialog) {
                this.$window = $window;
                this.Store = Store;
                this.Session = Session;
                this.$mdDialog = $mdDialog;
                this.suppress_f2f_instructions = false;
            }
            F2FInstructionsController.prototype.cancel = function () {
                this.$mdDialog.hide();
            };
            F2FInstructionsController.prototype.continue = function () {
                this.$mdDialog.hide();
                this.setSuppressF2FInstructions(this.suppress_f2f_instructions);
                this.$window.open('/patients/' + this.patient.id + '/' + this.object_name + '/' + this.object_id, 'consolo_rails');
            };
            F2FInstructionsController.prototype.setSuppressF2FInstructions = function (preference) {
                if (!preference) {
                    preference = false;
                }
                var u = this.Session.getUser();
                return this.Store.local('app')
                    .setItem('suppress_f2f_instructions:' + u.id, preference)
                    .then(function (data) {
                    return data;
                });
            };
            F2FInstructionsController.$inject = ['$window', 'Store', 'SessionService', '$mdDialog'];
            return F2FInstructionsController;
        }());
        angular.module('consolo').controller('F2FInstructionsController', F2FInstructionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MDDashboardFilterService = /** @class */ (function () {
            function MDDashboardFilterService($timeout, dataAccessService, $q) {
                this.$timeout = $timeout;
                this.dataAccessService = dataAccessService;
                this.$q = $q;
                this.pageSize = 5;
                this.arrFilters = [
                    'all_unsigned_certifications',
                    'all_unsigned_orders',
                    'f2f_visit_required',
                    'certs_requiring_completion',
                    'has_unsigned_orders',
                    'unsigned_unrelated_explanations',
                ];
                this.filterCounts = {
                    patients: { ids: [], count: 0 },
                    certs_requiring_completion: { ids: [], count: 0 },
                    f2f_visit_required: { ids: [], count: 0 },
                    has_unsigned_orders: { ids: [], count: 0 },
                    unsigned_unrelated_explanations: { ids: [], count: 0 },
                    all_unsigned_certifications: { ids: [], count: '-' },
                    all_unsigned_orders: { ids: [], count: '-' },
                };
                this.countsLoaded = false;
                this.patientsLoaded = false;
                this.errors = [];
                this.patients = [];
                this.updatePatients = 0;
                this.filterChoice = '';
                this.filterChipText = [];
                this.chipText = {
                    certs_requiring_completion: 'Total Patients Requiring Certification Completion',
                    f2f_visit_required: 'Total Patients Requiring F2F Completion',
                    has_unsigned_orders: 'Total Patients With Unsigned Orders',
                    unsigned_unrelated_explanations: 'Total Patients With Unsigned EOUs',
                };
            }
            MDDashboardFilterService.prototype.getAllPatients = function () {
                var _this = this;
                if (!this.patientsLoaded) {
                    this.$timeout(function () {
                        _this.loading = true;
                    });
                    return this.getOverallPatientCounts().then(function () {
                        var arrPromise = [];
                        arrPromise.push(_this.fetchPatients());
                        arrPromise.push(_this.getFilteredPatientCounts());
                        _this.$q.all(arrPromise).then(function () {
                            _this.patientsLoaded = true;
                            _this.setPatientFilters();
                            _this.loading = false;
                        });
                    });
                }
                else {
                    this.filterPatientsDelay();
                }
            };
            MDDashboardFilterService.prototype.refreshPatients = function () {
                this.loading = false;
                this.patientsLoaded = false;
                this.countsLoaded = false;
                this.filteredPatients = [].concat([]);
                this.getAllPatients();
            };
            MDDashboardFilterService.prototype.getOverallPatientCounts = function () {
                var _this = this;
                if (this.countsLoaded) {
                    return this.$q.when(true);
                }
                else {
                    return this.dataAccessService.getMDDashboardPatientsCount().get(function (result) {
                        _this.filterCounts.patients.ids = result.patients;
                        _this.filterCounts.patients.count = result.patients.length;
                        _this.countsLoaded = true;
                        return result;
                    }).$promise;
                }
            };
            MDDashboardFilterService.prototype.getFilteredPatientCounts = function () {
                var _this = this;
                var arrPromise = [];
                this.arrFilters.forEach(function (f) {
                    var p = _this.$q.defer();
                    arrPromise.push(p.promise);
                    _this.dataAccessService.getMDDashboardPatientsCount().get({ type: f }, function (result) {
                        _this.filterCounts[f].ids = result[f];
                        _this.filterCounts[f].count = result[f].length;
                        p.resolve();
                    });
                });
                return this.$q.all(arrPromise).finally(function () {
                    return _this.$q.when(true);
                });
            };
            // Set the patients and let the main controller know it needs to update its patients
            MDDashboardFilterService.prototype.fetchPatients = function () {
                var _this = this;
                var p = this.$q.defer();
                this.errors = [].concat([]);
                this.patients = [].concat([]);
                var ids = this.filterCounts.patients.ids;
                var numPages = Math.ceil(ids.length / this.pageSize);
                var promise = this.$q.when(true);
                if (numPages === 0) {
                    this.loading = false;
                    p.resolve();
                }
                var _loop_1 = function (i) {
                    promise = promise.finally(function () {
                        var pageIds = ids.slice(i * _this.pageSize, i * _this.pageSize + _this.pageSize);
                        return _this.dataAccessService
                            .getMDDashboardPatientsById()
                            .query({ 'patient_ids[]': pageIds, page_size: _this.pageSize }, function (patients) {
                            _this.patients = [].concat(_this.patients, patients);
                            if (!_this.filterChoice) {
                                _this.filteredPatients = [].concat(_this.patients);
                            }
                            _this.updatePatients += 1;
                            if (i + 1 === numPages) {
                                p.resolve();
                            }
                        }, function (error) {
                            _this.errors.push({ ids: pageIds, error: error });
                            if (i + 1 === numPages) {
                                p.resolve();
                            }
                        })
                            .$promise.then(function () { });
                    });
                };
                for (var i = 0; i < numPages; i++) {
                    _loop_1(i);
                }
                return p.promise;
            };
            MDDashboardFilterService.prototype.setPatientFilters = function () {
                var _this = this;
                this.patients.forEach(function (p) {
                    _this.arrFilters.forEach(function (f) {
                        var pos = _this.filterCounts[f].ids.indexOf(p.id);
                        if (pos >= 0) {
                            p.filters.push(f);
                        }
                        else {
                            var pos_1 = p.filters.indexOf(f);
                            if (pos_1 >= 0) {
                                p.filters.splice(pos_1, 1);
                            }
                        }
                    });
                });
            };
            MDDashboardFilterService.prototype.processRadioFilter = function () {
                var _this = this;
                this.$timeout(function () {
                    _this.loading = true;
                    _this.filterChipText = [_this.chipText[_this.filterChoice]];
                    _this.filterPatientsDelay();
                });
            };
            MDDashboardFilterService.prototype.removeChip = function () {
                var _this = this;
                this.$timeout(function () {
                    _this.filterChoice = '';
                    _this.filterChipText = [];
                    _this.loading = true;
                    _this.filteredPatients = [];
                    _this.filterPatientsDelay();
                });
            };
            MDDashboardFilterService.prototype.filterPatientsDelay = function () {
                var _this = this;
                if (this.patients.length) {
                    this.$timeout(function () {
                        _this.loading = true;
                        _this.filteredPatients = [];
                    });
                    this.$timeout(function () {
                        var tempFiltered = [];
                        var filterSize = 5;
                        for (var i = 0; i < _this.patients.length + filterSize; i += filterSize) {
                            var arr = _this.patients.slice(i, i + filterSize);
                            tempFiltered = tempFiltered.concat(arr.filter(function (p) {
                                return !_this.filterChoice || p.filters.indexOf(_this.filterChoice) >= 0;
                            }));
                        }
                        _this.$timeout(function () {
                            _this.filteredPatients = [].concat(tempFiltered);
                        });
                        _this.$timeout(function () {
                            _this.loading = false;
                        }, 1000);
                    }, 100);
                }
                else {
                    this.loading = false;
                }
            };
            MDDashboardFilterService.$inject = ['$timeout', 'dataAccessService', '$q'];
            return MDDashboardFilterService;
        }());
        services.MDDashboardFilterService = MDDashboardFilterService;
        angular.module('consolo').service('MDDashboardFilterService', MDDashboardFilterService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicalDirectorDashboardSidebarController = /** @class */ (function () {
            function MedicalDirectorDashboardSidebarController(filterService, Session, $scope) {
                //These are the filters on the sidebar watched here to query the patients anytime they are changed
                // $scope.$watchGroup(['vm.filterService.filter.certs_requiring_completion',
                //                     'vm.filterService.filter.f2f_visit_required',
                //                     'vm.filterService.filter.has_unsigned_orders',
                //                     'vm.filterService.filter.unsigned_unrelated_explanations'],
                //   () => {
                //     this.filterService.setPatients();
                // });
                // this.filterService.setPatients();
                this.filterService = filterService;
                this.Session = Session;
                this.$scope = $scope;
                var session = Session.get();
                this.serviceLine = session.agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
            }
            MedicalDirectorDashboardSidebarController.$inject = ['MDDashboardFilterService', 'SessionService', '$scope'];
            return MedicalDirectorDashboardSidebarController;
        }());
        angular
            .module('consolo')
            .controller('MedicalDirectorDashboardSidebarController', MedicalDirectorDashboardSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicalDirectorDashboardController = /** @class */ (function () {
            function MedicalDirectorDashboardController(unrelatedExplanationService, Store, Session, Patient, filterService, $scope, $mdDialog, $state, $window, DeviceSettings, logger, HijackFindService) {
                var _this = this;
                this.unrelatedExplanationService = unrelatedExplanationService;
                this.Store = Store;
                this.Session = Session;
                this.Patient = Patient;
                this.filterService = filterService;
                this.$scope = $scope;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.$window = $window;
                this.DeviceSettings = DeviceSettings;
                this.logger = logger;
                this.HijackFindService = HijackFindService;
                var session = Session.get();
                this.serviceLine = session.agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                this.subdomain = session.agency.subdomain;
                this.HijackFindService.register('#quickFilter', '#quickFilter');
                this.$scope.$on('$destroy', function () {
                    _this.HijackFindService.unregister();
                });
                this.user = Session.getUser();
                if (!this.user.physician || !this.user.physician.is_medical_director) {
                    this.logger.error('Only users associated to a Medical Director may view the Medical Director Dashboard');
                    this.$state.go('dashboard');
                }
                else {
                    this.filterService.loading = true;
                    this.filterService.getAllPatients();
                }
                // TODO: make this into an object w/ icons & colors
                this.icons = {
                    Charts: 'action:assignment',
                    'Care Plans': 'social:poll',
                    Claims: 'editor:attach_money',
                    Medications: 'maps:local_pharmacy',
                    Volunteer: 'action:assignment_ind',
                    Bereavement: 'maps:local_florist',
                };
                this.colors = {
                    Charts: '#FFC107',
                    'Care Plans': '#BA68C8',
                    Claims: '#81C784',
                    Medications: '#64B5F6',
                    Volunteer: '#FF8A65',
                    Bereavement: '#AED581',
                };
                this.background_colors = {
                    Charts: '#FFF8E1',
                    'Care Plans': '#F3E5f5',
                    Claims: '#E8F5E9',
                    Medications: '#E3F2FD',
                    Volunteer: '#FBE9E7',
                    Bereavement: '#F1F8E9',
                    'Custom Alerts': 'rgb(161, 136, 127)',
                };
            }
            MedicalDirectorDashboardController.prototype.navigateTo = function (page, patient) {
                this.$state.go('patients.' + page, { patientId: patient.id });
            };
            MedicalDirectorDashboardController.prototype.goToRailsPage = function (patient, object_name) {
                this.$window.open('/patients/' + patient.id + '/' + object_name, 'consolo_rails');
            };
            /**
             *
             * @param patient
             * @param object_name
             * @summary navigate to new emr console web
             */
            MedicalDirectorDashboardController.prototype.goToNewEmrWeb = function (patient, object_name) {
                this.$window.open('/emr/' + this.subdomain + '/patients/' + patient.id + '/' + object_name, 'consolo_rails');
            };
            MedicalDirectorDashboardController.prototype.goToRailsObjectPage = function (patient, object_name, object_id) {
                if (object_id) {
                    this.$window.open('/patients/' + patient.id + '/' + object_name + '/' + object_id, 'consolo_rails');
                }
                else {
                    this.$window.open('/patients/' + patient.id + '/' + object_name, 'consolo_rails');
                }
            };
            MedicalDirectorDashboardController.prototype.goToF2F = function ($event, patient, object_name, object_id) {
                var _this = this;
                this.getSuppressF2FInstructions().then(function (suppress_f2f_instructions) {
                    if (!suppress_f2f_instructions) {
                        _this.$mdDialog.show({
                            clickOutsideToClose: false,
                            bindToController: true,
                            templateUrl: '/y/templates/dashboard/medical-director/md-dashboard-f2f-instructions.html',
                            controller: 'F2FInstructionsController as $ctrl',
                            locals: { patient: patient, object_name: object_name, object_id: object_id },
                        });
                    }
                    else {
                        _this.goToRailsObjectPage(patient, object_name, object_id);
                    }
                });
            };
            MedicalDirectorDashboardController.prototype.getSuppressF2FInstructions = function () {
                return this.Store.local('app')
                    .getItem('suppress_f2f_instructions:' + this.user.id)
                    .then(function (data) {
                    return data;
                });
            };
            MedicalDirectorDashboardController.prototype.goToMedicalDirectorDashboard = function (anchor) {
                this.$window.open('/y/dashboard/physician?anchor=' + anchor);
            };
            MedicalDirectorDashboardController.prototype.updateAlert = function (patient) {
                if (patient.showAlert) {
                    patient.showAlert = !patient.showAlert;
                }
                else {
                    patient.showAlert = true;
                }
            };
            /*
             * Returns a platform-appropriate URL for patient location
             * iOS: a URL that will open Apple Maps.
             * Android: Google Maps URL. It will ask you if you want to open it with Google Maps or a browser.
             * Other: a valid Google Maps url.
             */
            MedicalDirectorDashboardController.prototype.mapUrl = function (address) {
                this.address = [address.address_1, address.city, address.state, address.zipcode].join(', ');
                if (navigator.userAgent.match(/i(OS|Pad|Phone|Pod)/)) {
                    return 'http://maps.apple.com/?saddr=' + encodeURIComponent(this.address);
                }
                else {
                    return 'http://maps.google.com/?saddr=' + encodeURIComponent(this.address);
                }
            };
            // Copied from dashboard/dashboard.controller.ts
            // This is for the vert-more/context menu
            MedicalDirectorDashboardController.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            MedicalDirectorDashboardController.prototype.showAllPatientCards = function () {
                this.cardMode = true;
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.md_dashboard, consolo.deviceSettings.Settings.card_mode, this.cardMode);
                for (var i = 0; i < this.filterService.patients.length; i++) {
                    this.filterService.filteredPatients[i].cardMode = true;
                }
            };
            MedicalDirectorDashboardController.prototype.hideAllPatientCards = function () {
                this.cardMode = false;
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.md_dashboard, consolo.deviceSettings.Settings.card_mode, this.cardMode);
                for (var i = 0; i < this.filterService.patients.length; i++) {
                    this.filterService.filteredPatients[i].cardMode = false;
                }
            };
            MedicalDirectorDashboardController.prototype.sendRnEmail = function (patient) {
                this.$window.open('mailto:' + patient.primary_rn.email, '_self');
            };
            MedicalDirectorDashboardController.prototype.sendPcgEmail = function (patient, $event) {
                this.$window.open('mailto:' + patient.primary_care_giver.email, '_self');
            };
            MedicalDirectorDashboardController.prototype.formatZipcode = function (zip) {
                if (zip.length === 9) {
                    var newZip = zip.substring(0, 5) + '-' + zip.substring(5);
                    return newZip;
                }
                return zip;
            };
            MedicalDirectorDashboardController.$inject = [
                'UnrelatedExplanationService',
                'Store',
                'SessionService',
                'Patient',
                'MDDashboardFilterService',
                '$scope',
                '$mdDialog',
                '$state',
                '$window',
                'DeviceSettingsService',
                'logger',
                'hijackFindService',
            ];
            return MedicalDirectorDashboardController;
        }());
        angular
            .module('consolo')
            .controller('MedicalDirectorDashboardController', MedicalDirectorDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var OfflineDashboardController = /** @class */ (function () {
            function OfflineDashboardController($mdDialog, $q, SyncManager, ChartingPrefetch) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.SyncManager = SyncManager;
                this.ChartingPrefetch = ChartingPrefetch;
                this.showProgress = false;
                this.percent = 0;
                this.loading = true;
                SyncManager.activate().then(function (sync) {
                    _this.syncData = sync.data;
                    _this.patientList = sync.patientList;
                    SyncManager.updateList().then(function () {
                        _this.loading = false;
                    });
                });
            }
            OfflineDashboardController.prototype.sync = function ($event) {
                var _this = this;
                var promise;
                if (this.patientList.length > 100) {
                    var confirm = this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .htmlContent('You have more than 100 offline patients. This could take a while. Are you sure?')
                        .targetEvent($event)
                        .ok('Ok')
                        .cancel('Cancel');
                    promise = this.$mdDialog.show(confirm);
                }
                else {
                    promise = this.$q.when(true);
                }
                promise.then(function () {
                    var counter = 1;
                    var total = _this.patientList.length + Math.ceil(_this.patientList.length / 5) + 5;
                    if (_this.syncData.includeMedications) {
                        total += _this.patientList.length;
                    }
                    _this.percent = 0;
                    _this.loading = true;
                    _this.showProgress = true;
                    _this.ChartingPrefetch.cachePatients(function (status) {
                        _this.status = status;
                        _this.percent = Math.round((counter / total) * 100);
                        counter++;
                    }, true).then(function () {
                        _this.percent = 100;
                        _this.status = 'Done!';
                        _this.loading = false;
                    }, function (reason) {
                        _this.status = 'Error: ' + reason;
                        _this.loading = false;
                    });
                });
            };
            OfflineDashboardController.$inject = ['$mdDialog', '$q', 'SyncManager', 'ChartingPrefetch'];
            return OfflineDashboardController;
        }());
        angular.module('consolo').controller('OfflineDashboardController', OfflineDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var OfflineDashboardSidebarController = /** @class */ (function () {
            function OfflineDashboardSidebarController($http, $timeout, cache, dataAccessService, SyncManager, ChartingPrefetch, Lookup) {
                var _this = this;
                this.$timeout = $timeout;
                this.cache = cache;
                this.dataAccessService = dataAccessService;
                this.SyncManager = SyncManager;
                this.ChartingPrefetch = ChartingPrefetch;
                this.Lookup = Lookup;
                this.selectedOffices = [];
                SyncManager.activate().then(function (sync) {
                    _this.isAllPatients = sync.data.isAllPatients;
                    _this.pending_never_admitted = sync.data.pending_never_admitted;
                    _this.isAssignedPatients = sync.data.isAssignedPatients;
                    _this.selectedOffices = sync.data.selectedOffices;
                    _this.includeExtraPatientData = sync.data.includeExtraPatientData;
                    _this.includeMedications = sync.data.includeMedications;
                    _this.includePhysicians = sync.data.includePhysicians;
                    _this.alwaysRefresh = sync.data.alwaysRefresh;
                    _this.updatePhysicians();
                    _this.refreshPatientCounts();
                });
                var officeResource = dataAccessService.getOfficeResource();
                this.cache(officeResource, 'office', { maxAge: 10 })
                    .query({
                    assigned: true,
                    active_patient_count: true,
                    pending_never_admitted_patient_count: true,
                })
                    .$promise.then(function (data) {
                    _this.offices = data;
                    _this.updateOfficeCounts();
                });
            }
            OfflineDashboardSidebarController.prototype.refreshPatientCounts = function () {
                var _this = this;
                var offlineDashboardResource = this.dataAccessService.getOfflineDashboardResource();
                this.cache(offlineDashboardResource, 'offline_dashboard_counts', { maxAge: 10 })
                    .offline_dashboard_counts(this.SyncManager.params)
                    .$promise.then(function (data) {
                    _this.allPatientCount = data.all_patient_count;
                    _this.assignedPatientCount = data.assigned_patient_count;
                });
            };
            OfflineDashboardSidebarController.prototype.updateOfficeCounts = function () {
                for (var _i = 0, _a = this.offices; _i < _a.length; _i++) {
                    var o = _a[_i];
                    o.officePatientCount = this.SyncManager.data.pending_never_admitted
                        ? o.active_patient_count + o.pending_never_admitted_patient_count
                        : o.active_patient_count;
                }
            };
            OfflineDashboardSidebarController.prototype.updatePhysicians = function () {
                var _this = this;
                if (this.includePhysicians) {
                    var physicianCacheLookup = this.cache(this.Lookup, 'physicians', { maxAge: 10 });
                    physicianCacheLookup['physicians']({ limit: -1 }).$promise.then(function (physicians) {
                        return _this.physicians = physicians;
                    });
                }
            };
            OfflineDashboardSidebarController.prototype.updatePendingCheckbox = function () {
                this.onChange(this);
                this.updateOfficeCounts();
                this.refreshPatientCounts();
            };
            OfflineDashboardSidebarController.prototype.onChangePhysicians = function (item) {
                this.updatePhysicians();
                this.onChangeData(item);
            };
            OfflineDashboardSidebarController.prototype.onChange = function (item) {
                this.SyncManager.data.isAssignedPatients = this.isAssignedPatients;
                this.SyncManager.data.pending_never_admitted = this.pending_never_admitted;
                this.SyncManager.data.isAllPatients = this.isAllPatients;
                this.SyncManager.data.selectedOffices = this.selectedOffices;
                this.SyncManager.data.includeExtraPatientData = this.includeExtraPatientData;
                this.SyncManager.data.includeMedications = this.includeMedications;
                this.SyncManager.data.includePhysicians = this.includePhysicians;
                this.SyncManager.data.alwaysRefresh = this.alwaysRefresh;
                this.SyncManager.save();
                item.loading = true;
                this.SyncManager.updateList().then(function () {
                    item.loading = false;
                });
            };
            OfflineDashboardSidebarController.prototype.onChangeData = function (item) {
                if (this.SyncManager.data.alwaysRefresh !== this.alwaysRefresh) {
                    if (!this.alwaysRefresh) {
                        this.ChartingPrefetch.cacheStatus();
                    }
                    else {
                        this.ChartingPrefetch.expires = 'Next Login';
                    }
                }
                this.SyncManager.data.pending_never_admitted = this.pending_never_admitted;
                this.SyncManager.data.isAssignedPatients = this.isAssignedPatients;
                this.SyncManager.data.isAllPatients = this.isAllPatients;
                this.SyncManager.data.selectedOffices = this.selectedOffices;
                this.SyncManager.data.includeExtraPatientData = this.includeExtraPatientData;
                this.SyncManager.data.includeMedications = this.includeMedications;
                this.SyncManager.data.includePhysicians = this.includePhysicians;
                this.SyncManager.data.alwaysRefresh = this.alwaysRefresh;
                this.SyncManager.save();
            };
            OfflineDashboardSidebarController.prototype.exists = function (id, list) {
                return list.indexOf(id) > -1;
            };
            OfflineDashboardSidebarController.prototype.toggle = function (item, loading) {
                var _this = this;
                this.$timeout(function () {
                    // workaround for bug in material design
                    // https://github.com/angular/material/issues/5072
                    _this.onChange(item);
                });
            };
            OfflineDashboardSidebarController.$inject = [
                '$http',
                '$timeout',
                'cache',
                'dataAccessService',
                'SyncManager',
                'ChartingPrefetch',
                'Lookup'
            ];
            return OfflineDashboardSidebarController;
        }());
        angular
            .module('consolo')
            .controller('OfflineDashboardSidebarController', OfflineDashboardSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var SyncManagerService = /** @class */ (function () {
            function SyncManagerService($filter, $q, cache, Store, dataAccessService, EmbeddedHelper) {
                this.$filter = $filter;
                this.$q = $q;
                this.cache = cache;
                this.dataAccessService = dataAccessService;
                this.EmbeddedHelper = EmbeddedHelper;
                this.patientList = [];
                this.isGettingList = false;
                this.pendingStatus = 'Pending - Never Admitted';
                this.activeStatus = 'Active';
                this.data = new consolo.domain.SyncedData(this.EmbeddedHelper.skipCache);
                this._appStore = Store.local('app');
                this._offlineDashboardResource = this.dataAccessService.getOfflineDashboardResource();
                this.params = {
                    active: true,
                    pending_never_admitted: true,
                    assigned_patients: true,
                    'office_ids[]': [],
                    includeExtraPatientData: false,
                    includeMedications: false,
                    includePhysicians: true,
                    alwaysRefresh: false,
                };
            }
            SyncManagerService.prototype.activate = function () {
                var _this = this;
                return this._appStore.getItem('synced_patients').then(function (data) {
                    data = data || new consolo.domain.SyncedData();
                    _this.data.isAllPatients = data.isAllPatients;
                    _this.data.isAssignedPatients = data.isAssignedPatients;
                    _this.data.selectedOffices = data.selectedOffices;
                    _this.data.includeExtraPatientData = data.includeExtraPatientData;
                    _this.data.includeMedications = data.includeMedications;
                    _this.data.includePhysicians = data.includePhysicians;
                    _this.data.alwaysRefresh = data.alwaysRefresh;
                    _this.data.pending_never_admitted = data.pending_never_admitted;
                    return _this;
                });
            };
            SyncManagerService.prototype.save = function () {
                this.setParams();
                this._appStore.setItem('synced_patients', this.data);
            };
            SyncManagerService.prototype.toggleOffice = function (id) {
                var idx = this.data.selectedOffices.indexOf(id);
                if (idx > -1) {
                    this.data.selectedOffices.splice(idx, 1);
                }
                else {
                    this.data.selectedOffices.push(id);
                }
            };
            SyncManagerService.prototype.updateList = function () {
                var _this = this;
                this.setParams();
                if (!this.data.isAllPatients &&
                    !this.data.isAssignedPatients &&
                    this.data.selectedOffices.length === 0) {
                    this.patientList.length = 0;
                    return this.$q.when(true);
                }
                if (!this.isGettingList) {
                    this.isGettingList = true;
                    this.isGettingListPromise = this.$q.defer();
                    this.cache(this._offlineDashboardResource, 'offline_dashboard', {
                        maxAge: 12 * 60,
                        force: true,
                    })
                        .query({}, this.params)
                        .$promise.then(function (data) {
                        _this.patientList.length = 0;
                        angular.copy(data, _this.patientList);
                        _this.isGettingListPromise.resolve(_this.patientList);
                        _this.isGettingList = false;
                    });
                }
                return this.isGettingListPromise.promise;
            };
            SyncManagerService.prototype._addPatient = function (patient, list) {
                var item = this.$filter('filter')(list, { id: patient.id })[0];
                if (!item) {
                    list.push(patient);
                }
                return;
            };
            SyncManagerService.prototype.getPatientList = function () {
                var _this = this;
                this.setParams();
                return this.cache(this._offlineDashboardResource, 'offline_dashboard', {
                    maxAge: 12 * 60,
                    force: false,
                })
                    .fetch('query', {}, this.params)
                    .then(function (data) {
                    if (!data) {
                        return _this.updateList().then(function () {
                            return _this.patientList;
                        });
                    }
                    else {
                        _this.patientList.length = 0;
                        angular.copy(data, _this.patientList);
                        _this.isGettingList = false;
                        return _this.$q.when(true).then(function () {
                            return _this.patientList;
                        });
                    }
                });
            };
            SyncManagerService.prototype.setParams = function () {
                if (this.data.isAllPatients) {
                    this.params.assigned_patients = false;
                    this.params['office_ids[]'] = [];
                }
                else {
                    this.params.assigned_patients = this.data.isAssignedPatients;
                    this.params['office_ids[]'] = this.data.selectedOffices;
                }
                this.params.active = true;
                this.params.pending_never_admitted = this.data.pending_never_admitted;
                this.params.includeExtraPatientData = this.data.includeExtraPatientData;
                this.params.includeMedications = this.data.includeMedications;
                this.params.includePhysicians = this.data.includePhysicians;
                this.params.alwaysRefresh = this.data.alwaysRefresh;
            };
            SyncManagerService.$inject = ['$filter', '$q', 'cache', 'Store', 'dataAccessService', 'EmbeddedHelper'];
            return SyncManagerService;
        }());
        services.SyncManagerService = SyncManagerService;
        angular.module('consolo').service('SyncManager', SyncManagerService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var SyncedData = /** @class */ (function () {
            function SyncedData(noOfflineCache) {
                if (noOfflineCache === void 0) { noOfflineCache = false; }
                this.isAllPatients = false;
                this.isAssignedPatients = true;
                this.selectedOffices = [];
                this.includeExtraPatientData = false;
                this.includeMedications = false;
                this.includePhysicians = true;
                this.alwaysRefresh = false;
                this.pending_never_admitted = true;
                this.active = true;
                if (noOfflineCache) {
                    this.isAssignedPatients = false;
                    this.pending_never_admitted = false;
                    this.active = false;
                }
            }
            return SyncedData;
        }());
        domain.SyncedData = SyncedData;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PhysicianDashboardController = /** @class */ (function () {
            function PhysicianDashboardController(PhysicianOrdersService, CertificationService, Session, $stateParams, $location, PhysicianUnsignedOrdersDataTableService, currentUser, $state) {
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.CertificationService = CertificationService;
                this.Session = Session;
                this.$stateParams = $stateParams;
                this.$location = $location;
                this.PhysicianUnsignedOrdersDataTableService = PhysicianUnsignedOrdersDataTableService;
                this.currentUser = currentUser;
                this.$state = $state;
                this.hasMedications = false;
                this.hasControlledMedications = false;
                this.agency = Session.get().agency;
                if (!currentUser.physician) {
                    this.$state.go('dashboard');
                }
                else {
                    CertificationService.getUnsignedCertifications();
                    if ($stateParams.anchor) {
                        $location.hash($stateParams.anchor);
                    }
                    this.faceToFaceLink =
                        '/reports/face_to_face_visit_summary?filter%5Bphysician%5D=' +
                            this.currentUser.physician.name +
                            '&filter%5Bphysician_id%5D=' +
                            this.currentUser.physician.id;
                }
            }
            PhysicianDashboardController.prototype.search = function () {
                this.PhysicianUnsignedOrdersDataTableService.search({
                    has_medications: this.hasMedications,
                    has_controlled_medications: this.hasControlledMedications,
                });
            };
            PhysicianDashboardController.$inject = [
                'PhysicianOrdersService',
                'CertificationService',
                'SessionService',
                '$stateParams',
                '$location',
                'PhysicianUnsignedOrdersDataTableService',
                'currentUser',
                '$state',
            ];
            return PhysicianDashboardController;
        }());
        angular
            .module('consolo')
            .controller('PhysicianDashboardController', PhysicianDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PhysicianUnsignedOrdersDataTableService = /** @class */ (function (_super) {
            __extends(PhysicianUnsignedOrdersDataTableService, _super);
            function PhysicianUnsignedOrdersDataTableService($http, PhysicianOrdersService, $state, $q, MedicationService, $mdDialog, $interval, Session) {
                var _this = _super.call(this, $http) || this;
                _this.PhysicianOrdersService = PhysicianOrdersService;
                _this.$state = $state;
                _this.$q = $q;
                _this.MedicationService = MedicationService;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.Session = Session;
                _this.showFilters = true;
                _this.loadingData = false;
                _this.apiSelectUrl = '/api/v1/physician_orders/unsigned_for_user';
                _this.unsignedOrdersLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    rowHideFn: function (physician_order) {
                        return !physician_order.needs_physician_signature;
                    },
                    options: {
                        showExpandCollapseAll: false,
                    },
                    columns: [
                        {
                            title: 'Patient',
                            field: 'patient.name',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({patientId: $patient_id})',
                        },
                        {
                            title: 'Order Date',
                            field: 'assessment_date',
                            shortTitle: 'Order Date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Orders',
                            field: 'orders',
                        },
                        {
                            title: 'Related',
                            field: 'is_related',
                            type: 'boolean',
                        },
                        {
                            title: 'Medications',
                            field: 'medication_transactions',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.medications({patientId: $patient_id})',
                            conditionalHideFn: function (physicianOrder) {
                                return (physicianOrder.medication_transactions.filter(function (mt) { return mt.transaction_type !== 'discontinue'; }).length === 0);
                            },
                            formatValueFn: function (physicianOrder) {
                                return physicianOrder.medication_transactions
                                    .filter(function (medicationTransaction) { return medicationTransaction.transaction_type !== 'discontinue'; })
                                    .map(function (x) { return x.medication.name; })
                                    .join(', ');
                            },
                        },
                        {
                            title: 'Medication Kits',
                            field: 'patient_medication_kits',
                            type: csg.datatable.ColumnTypes.short_text,
                            columnHideFn: function () {
                                var session = Session.get();
                                return !session.agency.combined_medication_kits;
                            },
                            formatValueFn: function (physicianOrder) {
                                return physicianOrder.patient_medication_kit_transactions
                                    .map(function (patientMedicationKitTransaction) {
                                    return patientMedicationKitTransaction.patient_medication_kit.name;
                                })
                                    .join(', ');
                            },
                        },
                        {
                            title: '',
                            field: 'view',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.physician-orders.view({patientId: $patient_id, id: $id})',
                            formatValueFn: function (physicianOrder) {
                                return 'View';
                            },
                        },
                    ],
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'User',
                                    field: 'user.name',
                                    type: csg.datatable.ColumnTypes.short_text,
                                },
                                {
                                    title: 'Order Date',
                                    field: 'assessment_date',
                                    type: csg.datatable.ColumnTypes.date,
                                },
                                {
                                    title: 'Time of Event',
                                    field: 'time_of_event',
                                    type: csg.datatable.ColumnTypes.short_text,
                                },
                            ],
                        },
                        {
                            len: 2,
                            columns: [
                                {
                                    title: 'Physician',
                                    field: 'physician.name',
                                    type: csg.datatable.ColumnTypes.short_text,
                                },
                                {
                                    title: 'Clinician',
                                    field: 'nurse.name',
                                    type: csg.datatable.ColumnTypes.short_text,
                                },
                                {
                                    title: 'Oversight',
                                    field: 'read_back',
                                    type: csg.datatable.ColumnTypes.boolean,
                                },
                            ],
                        },
                        {
                            len: 1,
                            columns: [
                                {
                                    title: 'Orders',
                                    field: 'orders',
                                    type: csg.datatable.ColumnTypes.short_text,
                                },
                            ],
                        },
                        {
                            len: 3,
                            columns: [
                                {
                                    title: 'Read Back',
                                    field: 'read_back',
                                    type: csg.datatable.ColumnTypes.boolean,
                                },
                                {
                                    title: 'Non Verbal Order',
                                    field: 'non_verbal_order',
                                    type: csg.datatable.ColumnTypes.boolean,
                                },
                                {
                                    title: 'E-Prescription',
                                    field: 'e_prescribe',
                                    type: csg.datatable.ColumnTypes.boolean,
                                },
                            ],
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'E-Prescribe',
                                disableOffline: true,
                                icon: 'add',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.initializePhysicianOrder(physicianOrder);
                                    return _this.$mdDialog
                                        .show({
                                        templateUrl: '/y/templates/physician-order/e-prescribe-modal.html',
                                        controller: 'PhysicianOrderSignatureController as vm',
                                        bindToController: true,
                                        locals: {
                                            currentPatient: physicianOrder.patient,
                                            currentUser: _this.PhysicianOrdersService.currentUser,
                                            physicianOrder: physicianOrder,
                                        },
                                    })
                                        .then(function (updatedPhysicianOrder) {
                                        angular.copy(updatedPhysicianOrder, physicianOrder);
                                        _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                },
                                formatValueFn: function (physicianOrder) {
                                    if (!physicianOrder.e_prescribe_available) {
                                        return 'E-Prescribe - Errors Present';
                                    }
                                    else {
                                        return 'E-Prescribe';
                                    }
                                },
                                conditionalHideFn: function (physicianOrder) {
                                    return !_this.PhysicianOrdersService.showEPCSOptions(physicianOrder);
                                },
                            },
                            {
                                label: 'Add Your Signature',
                                disableOffline: true,
                                icon: 'add',
                                callbackFn: function (physicianOrder) {
                                    return _this.showEPCSConfirmationBeforeSigningDialog(physicianOrder).then(function (updatedPhysicianOrder) {
                                        angular.copy(updatedPhysicianOrder, physicianOrder);
                                        _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                },
                            },
                            {
                                label: 'Sign & Fax',
                                disableOffline: true,
                                icon: 'add',
                                callbackFn: function (physicianOrder) {
                                    return _this.PhysicianOrdersService.signAndFax(physicianOrder).then(function (updatedPhysicianOrder) {
                                        angular.copy(updatedPhysicianOrder, physicianOrder);
                                        _this.MedicationService.replaceMedicationsFromOrder(physicianOrder);
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                },
                            },
                            {
                                label: 'View Physician Order',
                                icon: 'remove_red_eye',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.currentPhysicianOrder = physicianOrder;
                                    _this.$state.go('patients.physician-orders.view', {
                                        patientId: physicianOrder.patient_id,
                                        id: physicianOrder.id,
                                    });
                                    return false;
                                },
                            },
                            {
                                label: 'Fax Physician Order',
                                icon: 'send',
                                callbackFn: function (physicianOrder) {
                                    _this.PhysicianOrdersService.faxOrder(physicianOrder);
                                    return false;
                                },
                                conditionalHideFn: function (physicianOrder) {
                                    return physicianOrder.deleted_at !== undefined;
                                },
                            },
                        ],
                    },
                };
                return _this;
            }
            PhysicianUnsignedOrdersDataTableService.prototype.search = function (params) {
                var _this = this;
                this.loadingData = true;
                this.params = params;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            PhysicianUnsignedOrdersDataTableService.prototype.showEPCSConfirmationBeforeSigningDialog = function (physicianOrder) {
                var _this = this;
                if (this.PhysicianOrdersService.showEPCSOptions(physicianOrder)) {
                    return this.$mdDialog
                        .show({
                        clickOutsideToClose: false,
                        bindToController: true,
                        templateUrl: '/y/templates/dashboard/physician/signature-confirmation-modal.html',
                        controller: [
                            '$mdDialog',
                            function ($mdDialog) {
                                this.$mdDialog = $mdDialog;
                            },
                        ],
                        controllerAs: 'vm',
                    })
                        .then(function () {
                        return _this.PhysicianOrdersService.addSignature(physicianOrder);
                    });
                }
                else {
                    return this.PhysicianOrdersService.addSignature(physicianOrder);
                }
            };
            PhysicianUnsignedOrdersDataTableService.$inject = [
                '$http',
                'PhysicianOrdersService',
                '$state',
                '$q',
                'MedicationService',
                '$mdDialog',
                '$interval',
                'SessionService',
            ];
            return PhysicianUnsignedOrdersDataTableService;
        }(csg.datatable.ApiService));
        services.PhysicianUnsignedOrdersDataTableService = PhysicianUnsignedOrdersDataTableService;
        angular
            .module('consolo')
            .service('PhysicianUnsignedOrdersDataTableService', PhysicianUnsignedOrdersDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var AllRecentChangesDataTableService = /** @class */ (function (_super) {
            __extends(AllRecentChangesDataTableService, _super);
            function AllRecentChangesDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            AllRecentChangesDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?all_recent_changes=true';
            };
            AllRecentChangesDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            AllRecentChangesDataTableService.$inject = ['$http', '$interval'];
            return AllRecentChangesDataTableService;
        }(csg.datatable.ApiService));
        services.AllRecentChangesDataTableService = AllRecentChangesDataTableService;
        angular
            .module('consolo')
            .service('AllRecentChangesDataTableService', AllRecentChangesDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('allRecentChanges', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no recent care level changes found." ' +
                'table-layout="$ctrl.allRecentChangesLayout" ' +
                'api-service="$ctrl.AllRecentChangesDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'AllRecentChangesController as $ctrl',
        });
        var AllRecentChangesController = /** @class */ (function () {
            function AllRecentChangesController(AllRecentChangesDataTableService) {
                this.AllRecentChangesDataTableService = AllRecentChangesDataTableService;
                this.allRecentChangesLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        hideFooter: true,
                        defaultPageSize: 1000,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $id })',
                            linkOpenNewWindow: true,
                            formatValueFn: function (item) {
                                return item.last_name + ', ' + item.first_name;
                            },
                            isSorted: true,
                            sortDirection: 1,
                        },
                        {
                            title: 'Insurance',
                            field: 'current_payer_group',
                            shortTitle: 'Insurance',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (item.current_payer_group && item.current_payer_group.insurance_policies.length) {
                                    return item.current_payer_group.insurance_policies[0].name;
                                }
                            },
                        },
                        {
                            title: 'Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                        {
                            title: 'Location',
                            field: 'location',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (!item.location) {
                                    return 'Home';
                                }
                                return item.location;
                            },
                        },
                        {
                            title: 'L.O.C.',
                            field: 'level_to',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (item.level_to) {
                                    return item.level_to;
                                }
                                else if (item.level_from) {
                                    return item.level_from;
                                }
                                return '';
                            },
                        },
                    ],
                };
            }
            AllRecentChangesController.$inject = ['AllRecentChangesDataTableService'];
            return AllRecentChangesController;
        }());
        common.AllRecentChangesController = AllRecentChangesController;
        angular
            .module('consolo')
            .controller('AllRecentChangesController', AllRecentChangesController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var ExpiringCertificationsDataTableService = /** @class */ (function (_super) {
            __extends(ExpiringCertificationsDataTableService, _super);
            function ExpiringCertificationsDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            ExpiringCertificationsDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?expiring_certifications=true';
            };
            ExpiringCertificationsDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            ExpiringCertificationsDataTableService.$inject = ['$http', '$interval'];
            return ExpiringCertificationsDataTableService;
        }(csg.datatable.ApiService));
        services.ExpiringCertificationsDataTableService = ExpiringCertificationsDataTableService;
        angular
            .module('consolo')
            .service('ExpiringCertificationsDataTableService', ExpiringCertificationsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('expiringCertifications', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no expiring certifications found." ' +
                'table-layout="$ctrl.expiringCertificationsLayout" ' +
                'api-service="$ctrl.ExpiringCertificationsDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'ExpiringCertificationsController as $ctrl',
        });
        var ExpiringCertificationsController = /** @class */ (function () {
            function ExpiringCertificationsController(ExpiringCertificationsDataTableService) {
                this.ExpiringCertificationsDataTableService = ExpiringCertificationsDataTableService;
                this.expiringCertificationsLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 1000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $patient.id })',
                            linkOpenNewWindow: true,
                            formatValueFn: function (item) {
                                return item.patient.name;
                            },
                            isSorted: true,
                            sortDirection: 1,
                        },
                        {
                            title: 'Expires',
                            field: 'certification_end_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                    ],
                };
            }
            ExpiringCertificationsController.$inject = ['ExpiringCertificationsDataTableService'];
            return ExpiringCertificationsController;
        }());
        common.ExpiringCertificationsController = ExpiringCertificationsController;
        angular
            .module('consolo')
            .controller('ExpiringCertificationsController', ExpiringCertificationsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var IncompleteEmpiDataTableService = /** @class */ (function (_super) {
            __extends(IncompleteEmpiDataTableService, _super);
            function IncompleteEmpiDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            IncompleteEmpiDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?patient_empi_empty=true';
            };
            IncompleteEmpiDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            IncompleteEmpiDataTableService.$inject = ['$http', '$interval'];
            return IncompleteEmpiDataTableService;
        }(csg.datatable.ApiService));
        services.IncompleteEmpiDataTableService = IncompleteEmpiDataTableService;
        angular
            .module('consolo')
            .service('IncompleteEmpiDataTableService', IncompleteEmpiDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('incompleteEmpi', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no patients who does not have EMPI Id." ' +
                'table-layout="$ctrl.referralsLayout" ' +
                'api-service="$ctrl.IncompleteEmpiDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'IncompleteEmpiController as $ctrl',
        });
        var IncompleteEmpiController = /** @class */ (function () {
            function IncompleteEmpiController(IncompleteEmpiDataTableService) {
                this.IncompleteEmpiDataTableService = IncompleteEmpiDataTableService;
                this.referralsLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 1000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $id })',
                            linkOpenNewWindow: true,
                        },
                        {
                            title: 'Referral Date',
                            field: 'referral_date',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                        {
                            title: 'Current Status',
                            field: 'care_status',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                    ],
                };
            }
            IncompleteEmpiController.$inject = ['IncompleteEmpiDataTableService'];
            return IncompleteEmpiController;
        }());
        common.IncompleteEmpiController = IncompleteEmpiController;
        angular
            .module('consolo')
            .controller('IncompleteEmpiController', IncompleteEmpiController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MissingCodeStatusDataTableService = /** @class */ (function (_super) {
            __extends(MissingCodeStatusDataTableService, _super);
            function MissingCodeStatusDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            MissingCodeStatusDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?missing_code_status=true';
            };
            MissingCodeStatusDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    if (!this.params.sort_dir) {
                        this.params.sort_dir = 'ASC';
                        this.params.sort_field = 'patient_name';
                    }
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            MissingCodeStatusDataTableService.$inject = ['$http', '$interval'];
            return MissingCodeStatusDataTableService;
        }(csg.datatable.ApiService));
        services.MissingCodeStatusDataTableService = MissingCodeStatusDataTableService;
        angular
            .module('consolo')
            .service('MissingCodeStatusDataTableService', MissingCodeStatusDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('missingCodeStatus', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no admissions found." ' +
                'table-layout="$ctrl.missingCodeStatusLayout" ' +
                'api-service="$ctrl.MissingCodeStatusDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'MissingCodeStatusController as $ctrl',
        });
        var MissingCodeStatusController = /** @class */ (function () {
            function MissingCodeStatusController(MissingCodeStatusDataTableService) {
                this.MissingCodeStatusDataTableService = MissingCodeStatusDataTableService;
                this.missingCodeStatusLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 1000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $patient.id })',
                            linkOpenNewWindow: true,
                            formatValueFn: function (item) {
                                return item.patient.name;
                            },
                            isSorted: true,
                            sortDirection: 1,
                        },
                        {
                            title: 'Referred',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                    ],
                };
            }
            MissingCodeStatusController.$inject = ['MissingCodeStatusDataTableService'];
            return MissingCodeStatusController;
        }());
        common.MissingCodeStatusController = MissingCodeStatusController;
        angular
            .module('consolo')
            .controller('MissingCodeStatusController', MissingCodeStatusController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PendingAdmissionChartsDataTableService = /** @class */ (function (_super) {
            __extends(PendingAdmissionChartsDataTableService, _super);
            function PendingAdmissionChartsDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            PendingAdmissionChartsDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?pending_admission_charts=true';
            };
            PendingAdmissionChartsDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            PendingAdmissionChartsDataTableService.$inject = ['$http', '$interval'];
            return PendingAdmissionChartsDataTableService;
        }(csg.datatable.ApiService));
        services.PendingAdmissionChartsDataTableService = PendingAdmissionChartsDataTableService;
        angular
            .module('consolo')
            .service('PendingAdmissionChartsDataTableService', PendingAdmissionChartsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('pendingAdmissionCharts', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no charts found." ' +
                'table-layout="$ctrl.pendingAdmissionChartsLayout" ' +
                'api-service="$ctrl.PendingAdmissionChartsDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'PendingAdmissionChartsController as $ctrl',
        });
        var PendingAdmissionChartsController = /** @class */ (function () {
            function PendingAdmissionChartsController(PendingAdmissionChartsDataTableService) {
                this.PendingAdmissionChartsDataTableService = PendingAdmissionChartsDataTableService;
                this.pendingAdmissionChartsLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 2000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: false,
                            linkRoute: 'patients.dashboard({ patientId: $id })',
                            linkOpenNewWindow: true,
                            isSorted: true,
                            sortDirection: 1,
                        },
                        {
                            title: 'Missing Charts',
                            field: 'missing',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: false,
                            formatValueFn: function (patient) {
                                return patient.missing.join(', ');
                            },
                        },
                        {
                            title: 'Start of Care',
                            field: 'start_of_care',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: false,
                        },
                    ],
                };
            }
            PendingAdmissionChartsController.$inject = ['PendingAdmissionChartsDataTableService'];
            return PendingAdmissionChartsController;
        }());
        common.PendingAdmissionChartsController = PendingAdmissionChartsController;
        angular
            .module('consolo')
            .controller('PendingAdmissionChartsController', PendingAdmissionChartsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var RecentAdmissionsDataTableService = /** @class */ (function (_super) {
            __extends(RecentAdmissionsDataTableService, _super);
            function RecentAdmissionsDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            RecentAdmissionsDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?care_level_change_type=admissions';
            };
            RecentAdmissionsDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            RecentAdmissionsDataTableService.$inject = ['$http', '$interval'];
            return RecentAdmissionsDataTableService;
        }(csg.datatable.ApiService));
        services.RecentAdmissionsDataTableService = RecentAdmissionsDataTableService;
        angular
            .module('consolo')
            .service('RecentAdmissionsDataTableService', RecentAdmissionsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('recentAdmissions', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no admissions found." ' +
                'table-layout="$ctrl.admissionsLayout" ' +
                //'data="$ctrl.RecentAdmissionsDataTableService.data" auto-load="false"',
                'api-service="$ctrl.RecentAdmissionsDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'RecentAdmissionsController as $ctrl',
        });
        var RecentAdmissionsController = /** @class */ (function () {
            function RecentAdmissionsController(RecentAdmissionsDataTableService, $http) {
                this.RecentAdmissionsDataTableService = RecentAdmissionsDataTableService;
                this.$http = $http;
                this.admissionsLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: '$patient.id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 1000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $patient.id })',
                            linkOpenNewWindow: true,
                            formatValueFn: function (item) {
                                return item.patient.name;
                            },
                            isSorted: true,
                            sortDirection: 1,
                        },
                        {
                            title: 'Admission Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                        {
                            title: 'Reason',
                            field: 'description',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                        {
                            title: 'HIS Completion Deadline',
                            field: 'hospice_item_set_completion_deadline',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (item.hospice_item_set) {
                                    return item.hospice_item_set.completion_deadline;
                                }
                                else {
                                    return null;
                                }
                            },
                        },
                        {
                            title: 'HIS Submission Deadline',
                            field: 'hospice_item_set_submission_deadline',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (item.hospice_item_set) {
                                    return item.hospice_item_set.submission_deadline;
                                }
                                else {
                                    return null;
                                }
                            },
                        },
                        {
                            title: 'Verified?',
                            field: 'signatures',
                            type: csg.datatable.ColumnTypes.boolean,
                            canSort: true,
                            formatValueFn: function (patient) {
                                if (patient.hospice_item_set) {
                                    return patient.hospice_item_set.signatures.length ? 'Yes' : 'No';
                                }
                                else {
                                    return null;
                                }
                            },
                        },
                        {
                            title: 'Submitted?',
                            field: 'hospice_item_set.submitted',
                            type: csg.datatable.ColumnTypes.boolean,
                            canSort: true,
                        },
                        {
                            title: '',
                            field: 'hospice_item_set.id',
                            type: csg.datatable.ColumnTypes.link,
                            linkUrl: '/patients/$patient.id/hospice_item_sets/$hospice_item_set.id',
                            linkOpenNewWindow: true,
                            formatValueFn: function () {
                                return 'View HIS';
                            },
                            canSort: true,
                        },
                    ],
                };
            }
            RecentAdmissionsController.$inject = ['RecentAdmissionsDataTableService', '$http'];
            return RecentAdmissionsController;
        }());
        common.RecentAdmissionsController = RecentAdmissionsController;
        angular
            .module('consolo')
            .controller('RecentAdmissionsController', RecentAdmissionsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var RecentDischargesDataTableService = /** @class */ (function (_super) {
            __extends(RecentDischargesDataTableService, _super);
            function RecentDischargesDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            RecentDischargesDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?care_level_change_type=discharges';
            };
            RecentDischargesDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            RecentDischargesDataTableService.$inject = ['$http', '$interval'];
            return RecentDischargesDataTableService;
        }(csg.datatable.ApiService));
        services.RecentDischargesDataTableService = RecentDischargesDataTableService;
        angular
            .module('consolo')
            .service('RecentDischargesDataTableService', RecentDischargesDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('recentDischarges', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no discharges found." ' +
                'table-layout="$ctrl.dischargesLayout" ' +
                'api-service="$ctrl.RecentDischargesDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'RecentDischargesController as $ctrl',
        });
        var RecentDischargesController = /** @class */ (function () {
            function RecentDischargesController(RecentDischargesDataTableService) {
                this.RecentDischargesDataTableService = RecentDischargesDataTableService;
                this.dischargesLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 1000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $patient.id })',
                            linkOpenNewWindow: true,
                            formatValueFn: function (item) {
                                return item.patient.name;
                            },
                            isSorted: true,
                            sortDirection: 1,
                        },
                        {
                            title: 'Discharge Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                        {
                            title: 'Reason',
                            field: 'description',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                        {
                            title: 'HIS Completion Deadline',
                            field: 'hospice_item_set_completion_deadline',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (item.hospice_item_set) {
                                    return item.hospice_item_set.completion_deadline;
                                }
                                else {
                                    return null;
                                }
                            },
                        },
                        {
                            title: 'HIS Submission Deadline',
                            field: 'hospice_item_set_submission_deadline',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                            formatValueFn: function (item) {
                                if (item.hospice_item_set) {
                                    return item.hospice_item_set.submission_deadline;
                                }
                                else {
                                    return null;
                                }
                            },
                        },
                        {
                            title: 'Verified?',
                            field: 'signatures',
                            type: csg.datatable.ColumnTypes.boolean,
                            formatValueFn: function (patient) {
                                if (patient.hospice_item_set) {
                                    return patient.hospice_item_set.signatures.length ? 'Yes' : 'No';
                                }
                                else {
                                    return null;
                                }
                            },
                            canSort: true,
                        },
                        {
                            title: 'Submitted?',
                            field: 'hospice_item_set.submitted',
                            type: csg.datatable.ColumnTypes.boolean,
                            canSort: true,
                        },
                        {
                            title: '',
                            field: 'hospice_item_set.id',
                            type: csg.datatable.ColumnTypes.link,
                            linkUrl: '/patients/$patient.id/hospice_item_sets/$hospice_item_set.id',
                            linkOpenNewWindow: true,
                            formatValueFn: function () {
                                return 'View HIS';
                            },
                        },
                    ],
                };
            }
            RecentDischargesController.$inject = ['RecentDischargesDataTableService'];
            return RecentDischargesController;
        }());
        common.RecentDischargesController = RecentDischargesController;
        angular
            .module('consolo')
            .controller('RecentDischargesController', RecentDischargesController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var RecentReferralsDataTableService = /** @class */ (function (_super) {
            __extends(RecentReferralsDataTableService, _super);
            function RecentReferralsDataTableService($http, $interval) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            RecentReferralsDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/dashboard/trckr?care_level_change_type=referrals';
            };
            RecentReferralsDataTableService.prototype.search = function (filters) {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.params = filters;
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            RecentReferralsDataTableService.$inject = ['$http', '$interval'];
            return RecentReferralsDataTableService;
        }(csg.datatable.ApiService));
        services.RecentReferralsDataTableService = RecentReferralsDataTableService;
        angular
            .module('consolo')
            .service('RecentReferralsDataTableService', RecentReferralsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('recentReferrals', {
            bindings: {},
            template: '<csg-data-table empty-label="There were no referrals found." ' +
                'table-layout="$ctrl.referralsLayout" ' +
                'api-service="$ctrl.RecentReferralsDataTableService" sort-front-end="true" flex></csg-data-table>',
            controller: 'RecentReferralsController as $ctrl',
        });
        var RecentReferralsController = /** @class */ (function () {
            function RecentReferralsController(RecentReferralsDataTableService) {
                this.RecentReferralsDataTableService = RecentReferralsDataTableService;
                this.referralsLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideTextFilter: true,
                        allowRowSelect: false,
                        defaultPageSize: 1000,
                        hideFooter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'patient_name',
                            shortTitle: 'Name',
                            type: csg.datatable.ColumnTypes.link,
                            canSort: true,
                            linkRoute: 'patients.dashboard({ patientId: $patient.id })',
                            linkOpenNewWindow: true,
                            formatValueFn: function (item) {
                                return item.patient.name;
                            },
                        },
                        {
                            title: 'Referral Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                    ],
                };
            }
            RecentReferralsController.$inject = ['RecentReferralsDataTableService'];
            return RecentReferralsController;
        }());
        common.RecentReferralsController = RecentReferralsController;
        angular
            .module('consolo')
            .controller('RecentReferralsController', RecentReferralsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var TrackerDashboardDataTableService = /** @class */ (function () {
            function TrackerDashboardDataTableService($http, $interval, IncompleteEmpiDataTableService, RecentReferralsDataTableService, RecentAdmissionsDataTableService, RecentDischargesDataTableService, AllRecentChangesDataTableService, MissingCodeStatusDataTableService, ExpiringCertificationsDataTableService, PendingAdmissionChartsDataTableService) {
                this.$interval = $interval;
                this.IncompleteEmpiDataTableService = IncompleteEmpiDataTableService;
                this.RecentReferralsDataTableService = RecentReferralsDataTableService;
                this.RecentAdmissionsDataTableService = RecentAdmissionsDataTableService;
                this.RecentDischargesDataTableService = RecentDischargesDataTableService;
                this.AllRecentChangesDataTableService = AllRecentChangesDataTableService;
                this.MissingCodeStatusDataTableService = MissingCodeStatusDataTableService;
                this.ExpiringCertificationsDataTableService = ExpiringCertificationsDataTableService;
                this.PendingAdmissionChartsDataTableService = PendingAdmissionChartsDataTableService;
                this.loadingData = false;
                this.title = 'Tracker Dashboard';
                this.showFilters = true;
                this.hideSearchTerm = true;
                this.currentTab = 'referrals';
                this.storePreviousFilters = false;
                this.searchOnReset = true;
                this.activate();
            }
            TrackerDashboardDataTableService.prototype.activate = function () {
                this.filters = new consolo.domain.TrackerDashboardFilter();
            };
            TrackerDashboardDataTableService.prototype.search = function () {
                this.searchCurrentTab();
            };
            TrackerDashboardDataTableService.prototype.searchCurrentTab = function () {
                if (this.currentTab === 'incomplete_empi') {
                    this.IncompleteEmpiDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'referrals') {
                    this.RecentReferralsDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'admissions') {
                    this.RecentAdmissionsDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'discharges') {
                    this.RecentDischargesDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'all_recent_changes') {
                    this.AllRecentChangesDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'missing_code_status') {
                    this.MissingCodeStatusDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'expiring_certifications') {
                    this.ExpiringCertificationsDataTableService.search(this.filters.getFilters());
                }
                else if (this.currentTab === 'pending_admission_charts') {
                    this.PendingAdmissionChartsDataTableService.search(this.filters.getFilters());
                }
            };
            TrackerDashboardDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.TrackerDashboardFilter();
                this.search();
            };
            TrackerDashboardDataTableService.$inject = [
                '$http',
                '$interval',
                'IncompleteEmpiDataTableService',
                'RecentReferralsDataTableService',
                'RecentAdmissionsDataTableService',
                'RecentDischargesDataTableService',
                'AllRecentChangesDataTableService',
                'MissingCodeStatusDataTableService',
                'ExpiringCertificationsDataTableService',
                'PendingAdmissionChartsDataTableService',
            ];
            return TrackerDashboardDataTableService;
        }());
        services.TrackerDashboardDataTableService = TrackerDashboardDataTableService;
        angular
            .module('consolo')
            .service('TrackerDashboardDataTableService', TrackerDashboardDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('trackerDashboard', {
            bindings: {},
            templateUrl: '/y/templates/dashboard/trckr/trckr-dashboard.html',
            controller: 'TrackerDashboardController as $ctrl',
        });
        var TrackerDashboardController = /** @class */ (function () {
            function TrackerDashboardController(logger, $http, Session, $state, TrackerDashboardDataTableService) {
                this.logger = logger;
                this.$http = $http;
                this.Session = Session;
                this.$state = $state;
                this.TrackerDashboardDataTableService = TrackerDashboardDataTableService;
                this.selectedTab = 0;
                this.isPalliative = false;
                this.HasEmpiManager = false;
                this.HasEmpiManager = this.Session.get().agency['has_empi_manager'];
            }
            TrackerDashboardController.prototype.$onInit = function () {
                this.TrackerDashboardDataTableService.reset();
                this.user_dashboard_url = this.Session.getUser().dashboard_url;
                this.isPalliative = this.Session.get().agency.isPalliative();
            };
            TrackerDashboardController.prototype.search = function () {
                if (this.selectedTab === 0) {
                    this.TrackerDashboardDataTableService.currentTab = 'referrals';
                }
                else if (this.selectedTab === 1) {
                    this.TrackerDashboardDataTableService.currentTab = 'admissions';
                }
                else if (this.selectedTab === 2) {
                    this.TrackerDashboardDataTableService.currentTab = 'discharges';
                }
                else if (this.selectedTab === 3) {
                    this.TrackerDashboardDataTableService.currentTab = 'missing_code_status';
                }
                else if (this.selectedTab === 4) {
                    this.TrackerDashboardDataTableService.currentTab = 'all_recent_changes';
                }
                else if (this.selectedTab === 5) {
                    this.TrackerDashboardDataTableService.currentTab = 'expiring_certifications';
                }
                else if (this.selectedTab === 6) {
                    this.TrackerDashboardDataTableService.currentTab = 'pending_admission_charts';
                }
                else if (this.selectedTab === 7) {
                    this.TrackerDashboardDataTableService.currentTab = 'incomplete_empi';
                }
                this.TrackerDashboardDataTableService.search();
            };
            TrackerDashboardController.$inject = [
                'logger',
                '$http',
                'SessionService',
                '$state',
                'TrackerDashboardDataTableService',
            ];
            return TrackerDashboardController;
        }());
        common.TrackerDashboardController = TrackerDashboardController;
        angular
            .module('consolo')
            .controller('TrackerDashboardController', TrackerDashboardController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var WarBoardDataTableService = /** @class */ (function (_super) {
            __extends(WarBoardDataTableService, _super);
            function WarBoardDataTableService($http, $state, $q, $mdDialog, $interval, Session) {
                var _this = _super.call(this, $http) || this;
                _this.$state = $state;
                _this.$q = $q;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.Session = Session;
                _this.showFilters = true;
                _this.loadingData = false;
                _this.apiSelectUrl = '/api/v1/dashboard/war_board/patients_for_office';
                _this.warBoardTableLayout = {
                    id_field: 'patient_id',
                    options: {
                        showExpandCollapseAll: true,
                        cssClass: 'row-3-line',
                        defaultPageSize: 500,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: csg.datatable.MenuItemType.csvHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.csvAll,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyHeader,
                            },
                            {
                                menuItemType: csg.datatable.MenuItemType.copyAll,
                            },
                        ],
                        csvContents: true,
                        csvFilename: 'Consolo Warboard.csv',
                    },
                    columns: [
                        {
                            title: 'Patient',
                            field: 'patient_name',
                            type: csg.datatable.ColumnTypes.link,
                            formatValueFn: function (dashboardPatient) {
                                return dashboardPatient.patient.name;
                            },
                            linkRoute: 'patients.dashboard({ patientId: $patient_id})',
                            linkOpenNewWindow: true,
                            canSort: true,
                        },
                        {
                            title: 'Consolo Unique ID',
                            field: 'patient_id',
                            canSort: true,
                        },
                        {
                            title: 'M.R.N.',
                            field: 'medical_record_number',
                            canSort: true,
                        },
                        {
                            title: 'Admission Date',
                            field: 'admission_datetime',
                            type: csg.datatable.ColumnTypes.dateTimeOrdered,
                            canSort: true,
                        },
                        {
                            title: 'Cert Start',
                            field: 'current_certification_start_date',
                            type: 'date',
                            canSort: true,
                            formatValueFn: function (dashboardPatient) {
                                if (dashboardPatient.current_certification) {
                                    return dashboardPatient.current_certification.start_date;
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                        {
                            title: 'Cert End',
                            field: 'current_certification_end_date',
                            formatValueFn: function (dashboardPatient) {
                                if (dashboardPatient.current_certification) {
                                    return dashboardPatient.current_certification.end_date;
                                }
                                else {
                                    return '';
                                }
                            },
                            type: 'date',
                            canSort: true,
                        },
                        {
                            title: 'Diagnosis',
                            field: 'primary_icd',
                            canSort: true,
                        },
                        {
                            title: 'Location',
                            field: 'current_location',
                            canSort: true,
                        },
                        {
                            title: 'Pharmacy',
                            field: 'pharmacy_name',
                            formatValueFn: function (dashboardPatient) {
                                if (dashboardPatient.pharmacy) {
                                    return dashboardPatient.pharmacy.name;
                                }
                                else {
                                    return '';
                                }
                            },
                            canSort: true,
                        },
                        {
                            title: 'Hospice Payer',
                            field: 'primary_hospice_policy_name',
                            canSort: true,
                            formatValueFn: function (dashboardPatient) {
                                if (dashboardPatient.primary_hospice_policy) {
                                    return dashboardPatient.primary_hospice_policy.name;
                                }
                                else {
                                    return '';
                                }
                            },
                            columnHideFn: function (dashboardPatient) {
                                return Session.get().agency.isPalliative();
                            },
                        },
                        {
                            title: 'Encounter Payer',
                            field: 'primary_encounter_policy_name',
                            canSort: true,
                            formatValueFn: function (dashboardPatient) {
                                if (dashboardPatient.primary_encounter_policy) {
                                    return dashboardPatient.primary_encounter_policy.name;
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                    ],
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Funeral Home',
                                    field: 'funeral_home.name',
                                },
                                {
                                    title: 'Team',
                                    field: 'team.name',
                                },
                                {
                                    title: 'RN',
                                    field: 'rn.name',
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Medical Director',
                                    field: 'medical_director.name',
                                },
                                {
                                    title: 'Skilled Nurse',
                                    field: 'skilled_nurse.name',
                                },
                                {
                                    title: 'LPN',
                                    field: 'lpn.name',
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Physician',
                                    field: 'physician.name',
                                },
                                {
                                    title: 'Hospice Aide',
                                    field: 'hospice_aide.name',
                                },
                                {
                                    title: 'Case Manager',
                                    field: 'case_manager.name',
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Social Worker',
                                    field: 'social_worker.name',
                                },
                                {
                                    title: 'Volunteer Coordinator',
                                    field: 'volunteer_coordinator.name',
                                },
                                {
                                    title: 'Chaplain',
                                    field: 'chaplain.name',
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Bereavement',
                                    field: 'bereavement.name',
                                },
                                {
                                    title: 'Physical Therapy',
                                    field: 'physical_therapy.name',
                                },
                                {
                                    title: 'Occupational Therapy',
                                    field: 'occupational_therapy.name',
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Speech Pathologist',
                                    field: 'speech_pathologist.name',
                                },
                                {
                                    title: 'Address',
                                    field: 'current_address.address_1',
                                    formatValueFn: function (patient) {
                                        return (patient.current_address.address_1 +
                                            ', ' +
                                            patient.current_address.city +
                                            ', ' +
                                            patient.current_address.state +
                                            ' ' +
                                            patient.current_address.zipcode);
                                    },
                                },
                                { title: 'Cert BP', field: 'current_certification.benefit_period' },
                            ],
                        },
                    ],
                };
                return _this;
            }
            WarBoardDataTableService.prototype.search = function (office_id) {
                var _this = this;
                this.loadingData = true;
                this.params.office_id = office_id;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            WarBoardDataTableService.$inject = ['$http', '$state', '$q', '$mdDialog', '$interval', 'SessionService'];
            return WarBoardDataTableService;
        }(csg.datatable.ApiService));
        services.WarBoardDataTableService = WarBoardDataTableService;
        angular.module('consolo').service('WarBoardDataTableService', WarBoardDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var WarBoardSidebarController = /** @class */ (function () {
            function WarBoardSidebarController(WarBoardService, WarBoardDataTableService, SidebarService) {
                this.WarBoardService = WarBoardService;
                this.WarBoardDataTableService = WarBoardDataTableService;
                this.SidebarService = SidebarService;
            }
            WarBoardSidebarController.prototype.officeSelection = function (office) {
                this.SidebarService.close();
                this.WarBoardService.currentOffice = office;
                this.WarBoardDataTableService.search(office.id);
            };
            WarBoardSidebarController.$inject = [
                'WarBoardService',
                'WarBoardDataTableService',
                'SidebarService',
            ];
            return WarBoardSidebarController;
        }());
        angular.module('consolo').controller('WarBoardSidebarController', WarBoardSidebarController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var WarBoardDashboardController = /** @class */ (function () {
            function WarBoardDashboardController(WarBoardService, $mdMedia, WarBoardDataTableService) {
                this.WarBoardService = WarBoardService;
                this.$mdMedia = $mdMedia;
                this.WarBoardDataTableService = WarBoardDataTableService;
                this.WarBoardService.getCensusCount();
            }
            WarBoardDashboardController.$inject = ['WarBoardService', '$mdMedia', 'WarBoardDataTableService'];
            return WarBoardDashboardController;
        }());
        angular.module('consolo').controller('WarBoardDashboardController', WarBoardDashboardController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var WarBoardService = /** @class */ (function () {
            function WarBoardService($http, $state, $filter, Session, SidebarService) {
                this.$http = $http;
                this.$state = $state;
                this.$filter = $filter;
                this.Session = Session;
                this.SidebarService = SidebarService;
                this.loadingPatients = false;
                this.allPatients = {};
                var user = this.Session.getUser();
                if (user) {
                    this.availableOffices = user.offices;
                }
            }
            WarBoardService.prototype.getCensusCount = function () {
                var _this = this;
                this.$http.get('/api/v1/dashboard/war_board/census').then(function (data) {
                    _this.censusCount = data.data;
                });
            };
            WarBoardService.prototype.toggleSidebar = function () {
                this.SidebarService.toggle();
            };
            WarBoardService.$inject = [
                '$http',
                '$state',
                '$filter',
                'SessionService',
                'SidebarService',
            ];
            return WarBoardService;
        }());
        services.WarBoardService = WarBoardService;
        angular.module('consolo').service('WarBoardService', WarBoardService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientGenerator = consolo.domain.PatientGenerator;
        var PatientGeneratorController = /** @class */ (function () {
            function PatientGeneratorController($state, $location, $mdDialog, $timeout, Session, logger, PatientGeneratorService, $http, options, $q, officeService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.$timeout = $timeout;
                this.Session = Session;
                this.logger = logger;
                this.PatientGeneratorService = PatientGeneratorService;
                this.$http = $http;
                this.options = options;
                this.$q = $q;
                this.officeService = officeService;
                this.isLoading = false;
                this.disableNOE = true;
                this.disableCert = true;
                this.admissionSettings = [
                    { status: 'Postponed' },
                    { status: 'Cancelled' },
                    { status: 'Admitted' },
                    { status: 'Discharged Alive' },
                    { status: 'Readmitted' },
                    { status: 'Readmitted On Same Day' },
                    { status: 'Deceased' },
                    { status: 'With Interoffice Transfer' },
                ];
                this.payerGroups = [
                    { payer: 'On Medicare' },
                    { payer: 'On Medicaid' },
                    { payer: 'On Private Insurance' },
                    { payer: 'On Charitable Care' },
                    { payer: 'Medicare Primary Medicaid Secondary' },
                    { payer: 'Private Primary Medicare Secondary' },
                ];
                this.initializeData();
            }
            PatientGeneratorController.prototype.initializeData = function () {
                var _this = this;
                this.isLoading = true;
                this.generatorSettings = new PatientGenerator();
                this.options.get('cdc_races').$promise.then(function (response) {
                    _this.cdc_races = response.filter(function (value) { return value.primary; });
                });
                this.officeService.get().then(function (response) {
                    _this.offices = response.data;
                    _this.isLoading = false;
                });
            };
            PatientGeneratorController.prototype.save = function () {
                var _this = this;
                return this.$mdDialog
                    .show(this.generateConfirmationRequest('Are you sure you want to generate patients?'))
                    .then(function () {
                    _this.isLoading = true;
                    _this.PatientGeneratorService.generatePatients(_this.generatorSettings)
                        .then(function (response) {
                        _this.logger.success('You have successfully generated fake patients. You will recieve a notification when patient generation is complete.', 'Success:');
                        _this.isLoading = false;
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        console.warn(error);
                        _this.logger.error(error.data.errors.join(', '), 'Error:');
                    });
                });
            };
            PatientGeneratorController.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            PatientGeneratorController.prototype.deletePatients = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    controller: 'DeleteGeneratorDataDialog as vm',
                    templateUrl: '/y/templates/data-generators/delete-generator-data-dialog.html',
                    locals: {
                        generatorType: 'Patient',
                    },
                })
                    .then(function (dataType) {
                    if (dataType) {
                        var allPatients_1 = dataType === 'all' ? true : false;
                        _this.isLoading = true;
                        _this.PatientGeneratorService.getPatientCount(allPatients_1).then(function (response) {
                            _this.isLoading = false;
                            var patientCount = response.data.count;
                            return _this.$mdDialog
                                .show(_this.generateConfirmationRequest('Are you sure you want to delete ' +
                                patientCount +
                                ' ' +
                                dataType +
                                ' patients? This change is irreversible and ' +
                                dataType +
                                ' patient data will be lost!'))
                                .then(function () {
                                _this.isLoading = true;
                                _this.PatientGeneratorService.deletePatients(allPatients_1)
                                    .then(function (response) {
                                    _this.logger.success('You have successfully deleted ' + dataType + ' patients.', 'Success:');
                                    _this.isLoading = false;
                                })
                                    .catch(function (error) {
                                    console.warn(error);
                                    _this.isLoading = false;
                                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                                });
                            });
                        });
                    }
                });
            };
            PatientGeneratorController.prototype.clearEligibilityFields = function (value) {
                if (!value) {
                    this.generatorSettings.eligibility_inquiry = false;
                    this.generatorSettings.home_health = false;
                }
            };
            PatientGeneratorController.prototype.checkField = function (field) {
                var payerGroup = this.generatorSettings.payer_group;
                var validAdmission = this.generatorSettings.admission === 'Admitted' ||
                    this.generatorSettings.admission === 'Readmitted' ||
                    this.generatorSettings.admission === 'Readmitted On Same Day' ||
                    this.generatorSettings.admission === 'With Interoffice Transfer';
                if (field === 'certification') {
                    this.checkCert(validAdmission);
                }
                else if (field === 'notice_of_election') {
                    this.checkNOE(payerGroup, validAdmission);
                }
            };
            PatientGeneratorController.prototype.checkCert = function (validAdmission) {
                if (validAdmission) {
                    this.disableCert = false;
                }
                else {
                    this.generatorSettings.certification = false;
                    this.disableCert = true;
                }
            };
            PatientGeneratorController.prototype.checkNOE = function (payerGroup, validAdmission) {
                if (payerGroup && validAdmission) {
                    this.disableNOE = false;
                }
                else {
                    this.generatorSettings.notice_of_election = false;
                    this.disableNOE = true;
                }
            };
            PatientGeneratorController.prototype.showNoeHelpDialog = function () {
                this.$mdDialog.show({
                    contentElement: '#noe-help-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    openFrom: '#help-button',
                    closeTo: '#help-button',
                });
            };
            PatientGeneratorController.prototype.showCertHelpDialog = function () {
                this.$mdDialog.show({
                    contentElement: '#cert-help-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    openFrom: '#help-button',
                    closeTo: '#help-button',
                });
            };
            PatientGeneratorController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                '$timeout',
                'SessionService',
                'logger',
                'PatientGeneratorService',
                '$http',
                'options',
                '$q',
                'officeService',
            ];
            return PatientGeneratorController;
        }());
        angular.module('consolo').controller('PatientGeneratorController', PatientGeneratorController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PatientGeneratorService = /** @class */ (function () {
            function PatientGeneratorService($http) {
                this.$http = $http;
            }
            PatientGeneratorService.prototype.generatePatients = function (pgSettingsObj) {
                return this.$http.post('/api/v2/fake_data_gen/patients', pgSettingsObj);
            };
            PatientGeneratorService.prototype.deletePatients = function (allPatients) {
                return this.$http.delete('/api/v2/fake_data_gen/patients', {
                    params: { all_patients: allPatients },
                });
            };
            PatientGeneratorService.prototype.getPatientCount = function (allPatients) {
                return this.$http.get('/api/v2/fake_data_gen/patients/count', {
                    params: { all_patients: allPatients },
                });
            };
            PatientGeneratorService.$inject = ['$http'];
            return PatientGeneratorService;
        }());
        services.PatientGeneratorService = PatientGeneratorService;
        angular.module('consolo').service('PatientGeneratorService', PatientGeneratorService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('consoloListBuilder', ['$http', 'ConfirmModal', Directive]);
    function Directive($http, ConfirmModal) {
        return {
            replace: true,
            scope: {
                items: '=',
                itemType: '@',
                itemSummaryTemplateUrl: '@',
                patientId: '@',
                selectedIds: '=',
                onChange: '&',
            },
            templateUrl: '/y/templates/discharge-summary/list-builder/list-builder.html',
            controller: ['$scope', Controller],
            controllerAs: 'vm',
        };
        /* jshint browser: true */
        function Controller(scope) {
            var vm = this;
            vm.loading = true;
            vm.items = scope.items;
            vm.selectAll = selectAll;
            vm.show = show;
            vm.toggleItem = toggleItem;
            activate();
            function activate() {
                vm.items.$promise.then(function (data) {
                    data.forEach(function (item) {
                        item.selected = scope.selectedIds.indexOf(item.id) >= 0;
                    });
                    vm.loading = false;
                });
            }
            function selectAll() {
                scope.selectedIds = [];
                scope.items.forEach(function (item) {
                    item.selected = true;
                    scope.selectedIds.push(item.id);
                });
            }
            function show(item, $event) {
                item.loading = true;
                $event.stopPropagation();
                $http
                    .get('/patients/' + scope.patientId + '/' + scope.itemType + '/' + item.id + '.html')
                    .then(function (details) {
                    ConfirmModal.show(details, '/y/templates/discharge-summary/list-builder/details.html', $event).finally(function () {
                        item.loading = false;
                    });
                });
            }
            function toggleItem(item) {
                if (item.selected) {
                    scope.selectedIds.splice(scope.selectedIds.indexOf(item.id), 1);
                }
                else {
                    scope.selectedIds.push(item.id);
                }
                item.selected = !item.selected;
                scope.onChange();
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var EpcsConfirmUserController = /** @class */ (function () {
        function EpcsConfirmUserController(sessionService, userService, physConfirmationService, logger, $state) {
            var _this = this;
            this.userService = userService;
            this.physConfirmationService = physConfirmationService;
            this.logger = logger;
            this.$state = $state;
            this.saving = false;
            this.currentUser = sessionService.getUser();
            this.userService
                .getUser($state.params.userId)
                .then(function (user) { return (_this.user = user); }, function (errors) { return (_this.loadErrors = errors); });
        }
        EpcsConfirmUserController.prototype.save = function () {
            var _this = this;
            this.saving = true;
            this.saveErrors = [];
            this.userService
                .confirmUser(this.user.id, this.otp, this.user.epcs_physician_confirmation)
                .then(function () {
                _this.logger.success('EPCS settings updated');
                _this.$state.go('epcs.users');
            }, function (errors) {
                _this.saving = false;
                _this.saveErrors = errors;
                _this.otp = null;
                _this.user.epcs_physician_confirmation = false;
            });
        };
        EpcsConfirmUserController.prototype.canChangeConfirmation = function () {
            if (!this.user || !this.user.epcs_enabled) {
                return false;
            }
            else if (!this.user.epcs_enabled_user ||
                this.currentUser.id === this.user.epcs_enabled_user.id) {
                return false;
            }
            else {
                return (this.currentUser.epcs_on ||
                    (this.currentUser.id === this.user.id && this.user.self_confirm_epcs));
            }
        };
        EpcsConfirmUserController.prototype.twoFactorAuth = function () {
            var _this = this;
            if (this.user.epcs_physician_confirmation) {
                this.physConfirmationService.auth().then(function (token) {
                    _this.otp = token;
                }, function () {
                    _this.user.epcs_physician_confirmation = false;
                });
            }
        };
        EpcsConfirmUserController.$inject = [
            'SessionService',
            'EpcsUserService',
            'PhysicianConfirmationService',
            'logger',
            '$state',
        ];
        return EpcsConfirmUserController;
    }());
    consolo.EpcsConfirmUserController = EpcsConfirmUserController;
    angular.module('consolo').controller('EpcsConfirmUserController', EpcsConfirmUserController);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var EpcsEditUserController = /** @class */ (function () {
        function EpcsEditUserController(sessionService, userService, permissionsService, Lookup, logger, $state, $filter, readOnly) {
            var _this = this;
            this.userService = userService;
            this.permissionsService = permissionsService;
            this.Lookup = Lookup;
            this.logger = logger;
            this.$state = $state;
            this.$filter = $filter;
            this.canUpdate = false;
            this.canUpdateDeaNumbers = false;
            this.saving = false;
            this.settings = {};
            this.currentUser = sessionService.getUser();
            this.canUpdate = permissionsService.can('update', 'user_epcs_settings') && !readOnly;
            this.canUpdateDeaNumbers = permissionsService.can('update', 'dea_numbers');
            this.userService.getUser($state.params.userId).then(function (user) {
                _this.user = user;
                _this.checkForErrors();
                _this.settings = {
                    physician_id: user.physician ? user.physician.id : null,
                    epcs_enabled: user.epcs_enabled,
                    dea_numbers: (user.physician ? user.physician.dea_numbers : null) || [],
                    has_notifications: user.has_notifications,
                    only_class_2_notifications: user.only_class_2_notifications,
                };
                _this.settings.dea_numbers.forEach(function (n) {
                    if (n.expires_on) {
                        n.expiresOnDate = _this.parseDate(n.expires_on);
                    }
                });
            }, function (errors) { return (_this.loadErrors = errors); });
        }
        EpcsEditUserController.prototype.save = function () {
            var _this = this;
            this.saving = true;
            this.saveErrors = [];
            this.userService.updateUser(this.user.id, this.settings).then(function () {
                _this.logger.success('EPCS settings updated');
                _this.$state.go('epcs.users');
            }, function (errors) {
                _this.saving = false;
                _this.saveErrors = errors;
            });
        };
        EpcsEditUserController.prototype.searchPhys = function (term) {
            return this.Lookup.physicians({ q: term, limit: 26 }).$promise;
        };
        EpcsEditUserController.prototype.loadPhysician = function (physicianId) {
            var _this = this;
            if (physicianId === this.settings.physician_id) {
                return;
            }
            this.settings.physician_id = physicianId;
            this.settings.dea_numbers = [];
            if (physicianId) {
                this.userService.queryPhysician(physicianId).then(function (phys) {
                    _this.user.physician = phys;
                    _this.checkForErrors();
                    if (phys.dea_numbers) {
                        phys.dea_numbers.forEach(function (n) {
                            if (n.expires_on) {
                                n.expiresOnDate = _this.parseDate(n.expires_on);
                            }
                            if (_this.settings.dea_numbers.filter(function (x) { return x.id === n.id; }).length === 0) {
                                _this.settings.dea_numbers.push(n);
                            }
                        });
                    }
                });
            }
        };
        EpcsEditUserController.prototype.checkForErrors = function () {
            if (!this.physicianErrors) {
                this.physicianErrors = [];
            }
            if (!this.user.physician.fax) {
                this.physicianErrors.push('Fax number needs to be entered.');
            }
            if (!this.user.physician.valid_npi) {
                this.physicianErrors.push('The NPI is not valid.');
            }
            if (!this.user.physician.dea_numbers) {
                this.physicianErrors.push('An active DEA number with an expiration date is required.');
            }
            else {
                var validDeaNumber_1 = false;
                this.user.physician.dea_numbers.forEach(function (deaNumber) {
                    if (deaNumber.expires_on && new Date(deaNumber.expires_on) >= new Date()) {
                        validDeaNumber_1 = true;
                    }
                });
                if (!validDeaNumber_1) {
                    this.physicianErrors.push('An active DEA number with an expiration date is required.');
                }
            }
        };
        EpcsEditUserController.prototype.enabledChanged = function () {
            if (!this.settings.epcs_enabled) {
                this.user.epcs_physician_confirmation = false;
            }
        };
        EpcsEditUserController.prototype.canChangeEnabled = function () {
            if (!this.settings) {
                return false;
            }
            else if (this.settings.epcs_enabled) {
                return true;
            }
            else {
                return (this.settings.physician_id &&
                    this.settings.dea_numbers &&
                    this.settings.dea_numbers.length > 0);
            }
        };
        EpcsEditUserController.prototype.switchDefaultDeaNum = function (n) {
            if (n.default) {
                for (var i = 0; i < this.settings.dea_numbers.length; i++) {
                    var n2 = this.settings.dea_numbers[i];
                    if (n2.default && n2 !== n) {
                        n2.default = false;
                    }
                }
            }
        };
        EpcsEditUserController.prototype.setExpDate = function (n) {
            n.expires_on = n.expiresOnDate ? this.$filter('date')(n.expiresOnDate, 'yyyy-MM-dd') : null;
        };
        EpcsEditUserController.prototype.addNumber = function () {
            this.settings.dea_numbers.push({});
        };
        EpcsEditUserController.prototype.removeNumber = function (n) {
            if (!n.id || confirm('Are you sure you want to delete this DEA Number?')) {
                var idx = this.settings.dea_numbers.indexOf(n);
                this.settings.dea_numbers.splice(idx, 1);
            }
        };
        EpcsEditUserController.prototype.parseDate = function (dateStr) {
            var d = new Date(dateStr);
            return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
        };
        EpcsEditUserController.$inject = [
            'SessionService',
            'EpcsUserService',
            'PermissionsService',
            'Lookup',
            'logger',
            '$state',
            '$filter',
            'readOnly',
        ];
        return EpcsEditUserController;
    }());
    consolo.EpcsEditUserController = EpcsEditUserController;
    angular.module('consolo').controller('EpcsEditUserController', EpcsEditUserController);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var PhysicianConfirmationService = /** @class */ (function () {
        function PhysicianConfirmationService($mdDialog) {
            this.$mdDialog = $mdDialog;
        }
        PhysicianConfirmationService.prototype.auth = function () {
            return this.$mdDialog.show({
                templateUrl: '/y/templates/epcs/users/physician-confirmation-modal.html',
                controller: PhysicianConfirmationModelController,
                controllerAs: 'confirmVm',
                hasBackdrop: true,
                clickOutsideToClose: false,
                escapeToClose: false,
            });
        };
        PhysicianConfirmationService.$inject = ['$mdDialog'];
        return PhysicianConfirmationService;
    }());
    consolo.PhysicianConfirmationService = PhysicianConfirmationService;
    var PhysicianConfirmationModelController = /** @class */ (function () {
        function PhysicianConfirmationModelController(idProofService, logger, $mdDialog, $http) {
            var _this = this;
            this.idProofService = idProofService;
            this.logger = logger;
            this.$mdDialog = $mdDialog;
            this.$http = $http;
            this.busy = false;
            this.hasHwToken = false;
            this.hasSwToken = false;
            this.idProofService.getProven().then(function (proofs) {
                _this.proof = proofs[0]; // Someday we may need to add a drop-down picker
                _this.tokenType = _this.proof.default_token_type;
                _this.hasHwToken = _this.proof.token_types.indexOf('hw') > -1;
                _this.hasSwToken = _this.proof.token_types.indexOf('sw') > -1;
            }, function (errors) { return _this.logger.error(errors.join('; ')); });
        }
        PhysicianConfirmationModelController.prototype.auth = function () {
            var _this = this;
            this.busy = true;
            this.errors = [];
            // TODO stick into some service somewhere
            this.$http
                .post('/api/v1/2fa', {
                password: this.pw,
                otp: this.otp,
                token_type: this.tokenType,
                identity_proof_id: this.proof.id,
            })
                .then(function (resp) {
                _this.$mdDialog.hide(resp.data['token']);
            }, function (resp) {
                _this.busy = false;
                _this.errors =
                    resp.data && resp.data['errors'] ? resp.data['errors'] : ['Unknown error'];
            });
        };
        PhysicianConfirmationModelController.prototype.cancel = function () {
            this.$mdDialog.cancel();
        };
        PhysicianConfirmationModelController.$inject = ['IdentityProofService', 'logger', '$mdDialog', '$http'];
        return PhysicianConfirmationModelController;
    }());
    angular.module('consolo').service('PhysicianConfirmationService', PhysicianConfirmationService);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var EpcsUsersSidebarController = /** @class */ (function () {
        function EpcsUsersSidebarController(Lookup, $state) {
            this.Lookup = Lookup;
            this.$state = $state;
        }
        EpcsUsersSidebarController.prototype.search = function (term) {
            return this.Lookup.users({ q: term, limit: 25 }).$promise;
        };
        EpcsUsersSidebarController.prototype.goToEdit = function (userId) {
            if (userId) {
                this.term = null;
                this.user = null;
                this.$state.go('epcs.users.edit', { userId: userId });
            }
        };
        EpcsUsersSidebarController.$inject = ['Lookup', '$state'];
        return EpcsUsersSidebarController;
    }());
    consolo.EpcsUsersSidebarController = EpcsUsersSidebarController;
    angular.module('consolo').controller('EpcsUsersSidebarController', EpcsUsersSidebarController);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var EpcsUserService = /** @class */ (function () {
        function EpcsUserService($http) {
            this.$http = $http;
        }
        EpcsUserService.prototype.query = function (filter) {
            return this.$http.get('/api/v2/epcs/users', { params: filter }).then(function (resp) {
                return {
                    page: parseInt(resp.headers('X-Page'), 10),
                    totalPages: parseInt(resp.headers('X-Total-Pages'), 10),
                    items: resp.data,
                };
            }, function (resp) {
                return Promise.reject(resp.data.errors);
            });
        };
        EpcsUserService.prototype.queryPhysician = function (physicianId) {
            return this.$http
                .get("/api/v2/epcs/physicians/" + physicianId)
                .then(function (resp) { return resp.data; }, function (resp) { return Promise.reject(resp.data.errors); });
        };
        EpcsUserService.prototype.getUser = function (userId) {
            return this.$http
                .get("/api/v2/epcs/users/" + userId)
                .then(function (resp) { return resp.data; }, function (resp) { return Promise.reject(resp.data.errors); });
        };
        EpcsUserService.prototype.updateUser = function (userId, settings) {
            return this.$http
                .patch("/api/v2/epcs/users/" + userId, settings)
                .then(function (resp) { return resp.data; }, function (resp) { return Promise.reject(resp.data.errors); });
        };
        EpcsUserService.prototype.confirmUser = function (userId, otp, confirmed) {
            var settings = { ot_2fa_token: otp, epcs_physician_confirmation: confirmed };
            return this.$http
                .patch("/api/v2/epcs/users/" + userId + "/confirm", settings)
                .then(function (resp) { return resp.data; }, function (resp) { return Promise.reject(resp.data.errors); });
        };
        EpcsUserService.$inject = ['$http'];
        return EpcsUserService;
    }());
    consolo.EpcsUserService = EpcsUserService;
    angular.module('consolo').service('EpcsUserService', EpcsUserService);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var EpcsUsersController = /** @class */ (function () {
        function EpcsUsersController(userService, permissionsService, Session, PubSubService) {
            this.userService = userService;
            this.permissionsService = permissionsService;
            this.Session = Session;
            this.PubSubService = PubSubService;
            this.loading = false;
            this.loadPage(1);
            this.subscribe();
            this.canUpdate = permissionsService.can('update', 'user_epcs_settings');
            this.canConfirm = permissionsService.can('confirm', 'user_epcs_settings');
        }
        EpcsUsersController.prototype.loadPage = function (n) {
            var _this = this;
            this.loading = true;
            this.userService.query({ page: n, page_size: 25 }).then(function (page) {
                _this.currentPage = page;
                _this.loading = false;
            }, function () {
                _this.loading = false;
            });
        };
        EpcsUsersController.prototype.subscribe = function () {
            var _this = this;
            this.unsubscribe();
            this.PubSubService.channel("emr:epcs:users").then(function (chan) {
                chan.on('epcs_users_reload', function () {
                    _this.loadPage(_this.currentPage.page);
                });
                _this.channel = chan;
            });
        };
        EpcsUsersController.prototype.unsubscribe = function () {
            if (this.channel) {
                this.channel.leave();
                this.channel = null;
            }
        };
        EpcsUsersController.$inject = [
            'EpcsUserService',
            'PermissionsService',
            'SessionService',
            'PubSubService'
        ];
        return EpcsUsersController;
    }());
    consolo.EpcsUsersController = EpcsUsersController;
    angular.module('consolo').controller('EpcsUsersController', EpcsUsersController);
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        var Exostar = /** @class */ (function () {
            function Exostar($http, $location, $q) {
                this.$http = $http;
                this.$location = $location;
                this.$q = $q;
            }
            Exostar.prototype.get = function (proofId) {
                var _this = this;
                return this.$http
                    .get("/api/v1/identity_proofs/exostar/" + proofId)
                    .then(function (resp) { return resp.data; }, function (resp) { return _this.$q.reject(resp.data ? resp.data.errors : ['Unknown error']); });
            };
            Exostar.prototype.getSession = function (proofId) {
                var _this = this;
                return this.$http
                    .post("/api/v1/identity_proofs/exostar/" + proofId + "/session")
                    .then(function (resp) { return resp.data.redirect_url; }, function (resp) { return _this.$q.reject(resp.data ? resp.data.errors : ['Unknown error']); });
            };
            Exostar.prototype.create = function (attrs) {
                var _this = this;
                return this.$http
                    .post('/api/v1/identity_proofs/exostar', attrs)
                    .then(function (resp) { return resp.data; }, function (resp) { return _this.$q.reject(resp.data ? resp.data.errors : ['Unknown error']); });
            };
            Exostar.prototype.update = function (proofId, data) {
                var _this = this;
                return this.$http
                    .patch("/api/v1/identity_proofs/exostar/" + proofId, data)
                    .then(function (resp) { return resp.data; }, function (resp) { return _this.$q.reject(resp.data ? resp.data.errors : ['Unknown error']); });
            };
            Exostar.prototype.resume = function (proofId) {
                var _this = this;
                return this.$http
                    .post("/api/v1/identity_proofs/exostar/" + proofId + "/resume")
                    .then(function (resp) { return resp.data.redirect_url; }, function (resp) { return _this.$q.reject(resp.data ? resp.data.errors : ['Unknown error']); });
            };
            Exostar.prototype.storeUrl = function () {
                if (this.$location.host().split('.').length === 3) {
                    return 'http://www4.exostar.com/consolo';
                }
                else {
                    return 'http://www4.exostartest.com/consolo';
                }
            };
            Exostar.prototype.deviceName = function (device) {
                switch (device) {
                    case 'hw':
                        return 'Hardware';
                    case 'sw':
                        return 'Software';
                }
            };
            Exostar.prototype.proofName = function (proof) {
                switch (proof) {
                    case 'web':
                        return 'Standard';
                    case 'webcam':
                        return 'Webcam';
                }
            };
            Exostar.$inject = ['$http', '$location', '$q'];
            return Exostar;
        }());
        idProofing.Exostar = Exostar;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular.module('consolo').service('Exostar', consolo.idProofing.Exostar);

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        angular.module('consolo').component('exostarProof', {
            bindings: { proof: '=' },
            templateUrl: '/y/templates/id-proofing/exostar/proof.html',
            controller: 'ExostarProofController as vm',
        });
        var ExostarProofController = /** @class */ (function () {
            function ExostarProofController(logger, exostarService, $window) {
                this.logger = logger;
                this.exostarService = exostarService;
                this.$window = $window;
                this.hasHwToken = false;
                this.hasSwToken = false;
                this.updating = false;
                this.readyToResume = false;
            }
            ExostarProofController.prototype.$onInit = function () {
                if (this.proof.completed) {
                    this.hasHwToken = this.proof.token_types.indexOf('hw') > -1;
                    this.hasSwToken = this.proof.token_types.indexOf('sw') > -1;
                }
                this.readyToResume = this.proof.vendor_proofing_status === 'wtg_resume';
            };
            ExostarProofController.prototype.update = function () {
                var _this = this;
                this.updating = true;
                this.exostarService
                    .update(this.proof.id, {
                    default_token_type: this.proof.default_token_type,
                })
                    .then(function () {
                    _this.updating = false;
                }, function (errors) {
                    _this.updating = false;
                    _this.logger.error(errors.join('; '));
                });
            };
            ExostarProofController.prototype.launchSession = function () {
                var _this = this;
                this.exostarService
                    .getSession(this.proof.id)
                    .then(function (url) { return (window.location.href = url); }, function (errors) { return _this.logger.error(errors.join('. ')); });
            };
            ExostarProofController.prototype.resume = function () {
                var _this = this;
                this.exostarService
                    .resume(this.proof.id)
                    .then(function (url) { return (_this.$window.location.href = url); }, function (errors) { return (_this.errors = errors); });
            };
            ExostarProofController.prototype.openWebcamCalendar = function () {
                if (!this.proof.redirect_url) {
                    this.logger.error('Scheduling URL could not be found');
                    return;
                }
                this.logger.info('Opening calendar in new window...');
                window.open(this.proof.redirect_url, 'webcamSched');
            };
            ExostarProofController.$inject = ['logger', 'Exostar', '$window'];
            return ExostarProofController;
        }());
        idProofing.ExostarProofController = ExostarProofController;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('ExostarProofController', consolo.idProofing.ExostarProofController);

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var mapComponents;
    (function (mapComponents) {
        angular.module('consolo').component('googleBreadcrumbMap', {
            bindings: { locations: '<', address: '<', calculating: '<' },
            templateUrl: '/y/templates/maps/google-maps/breadcrumb-map.html',
            controller: 'BreadcrumbMap as vm',
        });
        var BreadcrumbMap = /** @class */ (function () {
            function BreadcrumbMap($state, $filter, $http, logger, $mdDialog, RouteManager, GoogleMapsService, clinicalTripsService, DispatchViewService, $scope, $interval, Breadcrumb, $q) {
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.RouteManager = RouteManager;
                this.GoogleMapsService = GoogleMapsService;
                this.clinicalTripsService = clinicalTripsService;
                this.DispatchViewService = DispatchViewService;
                this.$scope = $scope;
                this.$interval = $interval;
                this.Breadcrumb = Breadcrumb;
                this.$q = $q;
                this.infoWindow = new google.maps.InfoWindow();
                this.breadcrumbMarkers = [];
                this.breadcrumbRoutes = [];
                this.evvMarkers = [];
                this.stopMarkers = [];
                this.breadcrumbColor = '#d9534f';
                this.stopColor = '#3f51b5';
                this.poiMap = new google.maps.Map(document.getElementById('map'));
                this.mapDiv = document.querySelector('div#map');
                this.mapDimensions = {
                    width: this.mapDiv.clientWidth,
                    height: this.mapDiv.clientHeight,
                };
                $scope.$watch(function () {
                    return DispatchViewService.breadcrumbs;
                }, function (newVal, oldVal) {
                    $scope.vm.addBreadcrumbs(newVal);
                });
                $scope.$watch(function () {
                    return DispatchViewService.currentStop;
                }, function (newVal, oldVal) {
                    $scope.vm.selectLocation(newVal);
                });
                $scope.$watch(function () {
                    return DispatchViewService.currentStopPoints;
                }, function (newVal, oldVal) {
                    $scope.vm.addLocations(newVal);
                });
            }
            BreadcrumbMap.prototype.$onInit = function () {
                this.initMap();
            };
            BreadcrumbMap.prototype.initMap = function () {
                var mapOptions = {
                    zoom: 4,
                    center: { lat: 39, lng: -95 },
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                };
                this.poiMap.setOptions(mapOptions);
            };
            BreadcrumbMap.prototype.addBreadcrumbs = function (points) {
                var _this = this;
                var currentLocationBreadcrumb;
                var tripCoordinates = [];
                var currentTrip = 0;
                this.breadcrumbMarkers.forEach(function (marker) {
                    marker.setMap(null);
                });
                this.breadcrumbMarkers = [];
                this.breadcrumbRoutes.forEach(function (route) {
                    route.setMap(null);
                });
                this.breadcrumbRoutes = [];
                var evvPoints = points.filter(function (point) { return point.location_type === 'ElectronicVisitVerification'; });
                this.addEvvPoints(evvPoints);
                var breadcrumbPoints = points.filter(function (point) { return point.location_type !== 'ElectronicVisitVerification'; });
                breadcrumbPoints.forEach(function (point) {
                    var needsPin = (point.location_id &&
                        currentLocationBreadcrumb &&
                        point.location_id === currentLocationBreadcrumb.location_id) ||
                        (point.location_uuid &&
                            currentLocationBreadcrumb &&
                            point.location_uuid === currentLocationBreadcrumb.location_uuid)
                        ? false
                        : true;
                    var location = new google.maps.LatLng(parseFloat(point.latitude), parseFloat(point.longitude));
                    if (needsPin) {
                        var title = "Start Travel: " + _this.breadcrumbTime(point.breadcrumb_datetime);
                        var marker = _this.createAndRegisterMarker(location, title, '', title, _this.breadcrumbColor);
                        _this.breadcrumbMarkers.push(marker);
                        if (currentLocationBreadcrumb) {
                            var arrivalLocation = new google.maps.LatLng(parseFloat(currentLocationBreadcrumb.latitude), parseFloat(currentLocationBreadcrumb.longitude));
                            var arrivalTitle = "Mark Arrival: " + _this.breadcrumbTime(currentLocationBreadcrumb.breadcrumb_datetime);
                            _this.breadcrumbMarkers.push(_this.createAndRegisterMarker(arrivalLocation, arrivalTitle, '', arrivalTitle, _this.breadcrumbColor));
                            currentTrip += 1;
                        }
                    }
                    if (!tripCoordinates[currentTrip]) {
                        tripCoordinates[currentTrip] = [];
                    }
                    tripCoordinates[currentTrip].push(location);
                    currentLocationBreadcrumb = point;
                });
                if (currentLocationBreadcrumb) {
                    var lastLocation = new google.maps.LatLng(parseFloat(currentLocationBreadcrumb.latitude), parseFloat(currentLocationBreadcrumb.longitude));
                    var lastTitle = "Mark Arrival: " + this.breadcrumbTime(currentLocationBreadcrumb.breadcrumb_datetime);
                    this.breadcrumbMarkers.push(this.createAndRegisterMarker(lastLocation, lastTitle, '', lastTitle, this.breadcrumbColor));
                }
                tripCoordinates.forEach(function (trip) {
                    var route = new google.maps.Polyline({
                        path: trip,
                        geodesic: true,
                        strokeColor: _this.breadcrumbColor,
                        strokeWeight: 4,
                    });
                    route.setMap(_this.poiMap);
                    _this.breadcrumbRoutes.push(route);
                });
                if (this.breadcrumbMarkers.length) {
                    this.setBounds();
                }
            };
            BreadcrumbMap.prototype.addEvvPoints = function (points) {
                var _this = this;
                this.evvMarkers.forEach(function (marker) {
                    marker.setMap(null);
                });
                this.evvMarkers = [];
                points.forEach(function (point) {
                    var pointNumber = _this.evvMarkers.length + 1;
                    var location = new google.maps.LatLng(parseFloat(point.latitude), parseFloat(point.longitude));
                    var title = "Stop " + pointNumber + ": " + _this.breadcrumbTime(point.breadcrumb_datetime);
                    var marker = _this.createAndRegisterMarker(location, title, '', title, _this.breadcrumbColor);
                    _this.evvMarkers.push(marker);
                });
                if (this.evvMarkers.length) {
                    this.setBounds();
                }
            };
            BreadcrumbMap.prototype.addLocations = function (points) {
                var _this = this;
                this.stopMarkers.forEach(function (marker) {
                    marker.setMap(null);
                });
                this.stopMarkers = [];
                points.forEach(function (point, i) {
                    var location = new google.maps.LatLng(parseFloat(point.lat), parseFloat(point.lng));
                    var title = point.destination_name
                        ? point.destination_name + " - " + point.fullAddress
                        : point.fullAddress;
                    var label = (i + 1).toString();
                    var marker = _this.createAndRegisterMarker(location, title, label, title, _this.stopColor);
                    _this.stopMarkers.push(marker);
                });
                if (this.stopMarkers.length) {
                    this.setBounds();
                }
            };
            BreadcrumbMap.prototype.selectLocation = function (location) {
                if (location && location.hasOwnProperty('_index') && this.stopMarkers.length) {
                    var selectedMarker = this.stopMarkers.filter(function (marker) {
                        return parseInt(marker.getLabel().text, 10) === location._index + 1;
                    })[0];
                    this.infoWindow.setContent(selectedMarker.getTitle());
                    this.infoWindow.open(this.poiMap, selectedMarker);
                }
            };
            BreadcrumbMap.prototype.createAndRegisterMarker = function (location, title, label, info, color) {
                var _this = this;
                var marker = new google.maps.Marker({
                    map: this.poiMap,
                    position: location,
                    label: label
                        ? {
                            text: label,
                            color: '#fff',
                            fontWeight: 'bold',
                        }
                        : '',
                    title: title,
                    icon: {
                        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
                        fillColor: color,
                        fillOpacity: 1,
                        strokeColor: '#fff',
                        strokeWeight: 2,
                        scale: 1.25,
                        labelOrigin: new google.maps.Point(0, -28),
                    },
                });
                marker.addListener('click', function (event) {
                    _this.infoWindow.setContent(info);
                    _this.infoWindow.open(_this.poiMap, marker);
                });
                return marker;
            };
            BreadcrumbMap.prototype.setBounds = function () {
                var markers = __spreadArrays(this.stopMarkers, this.breadcrumbMarkers, this.evvMarkers);
                if (markers) {
                    this.GoogleMapsService.setBounds(markers, this.poiMap, this.mapDimensions);
                }
            };
            BreadcrumbMap.prototype.staticMapURL = function () {
                return this.GoogleMapsService.staticMapURL;
            };
            BreadcrumbMap.prototype.breadcrumbTime = function (dateTime) {
                var d = new Date(dateTime), timeStr;
                var hour = d.getHours();
                var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
                if (hour > 12) {
                    timeStr = hour - 12 + ':' + minutes + ' PM';
                }
                else if (hour === 12) {
                    timeStr = '12:' + minutes + ' PM';
                }
                else if (hour === 0) {
                    timeStr = '12:' + minutes + ' AM';
                }
                else {
                    timeStr = hour + ':' + minutes + ' AM';
                }
                return timeStr;
            };
            BreadcrumbMap.$inject = [
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'RouteManagerService',
                'GoogleMapsService',
                'clinicalTripsService',
                'DispatchViewService',
                '$scope',
                '$interval',
                'Breadcrumb',
                '$q',
            ];
            return BreadcrumbMap;
        }());
        mapComponents.BreadcrumbMap = BreadcrumbMap;
        angular.module('consolo').controller('BreadcrumbMap', BreadcrumbMap);
    })(mapComponents = consolo.mapComponents || (consolo.mapComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var mapComponents;
    (function (mapComponents) {
        angular.module('consolo').component('clinicalTripsMap', {
            bindings: { trip: '<', tripLatLng: '<', clinicalTrip: '<' },
            templateUrl: '/y/templates/maps/google-maps/clinical-trips-map.html',
            controller: 'ClinicalTripsMap as vm',
        });
        var ClinicalTripsMap = /** @class */ (function () {
            function ClinicalTripsMap($state, $filter, $http, logger, $mdDialog, RouteManager, GoogleMapsService, clinicalTripsService) {
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.RouteManager = RouteManager;
                this.GoogleMapsService = GoogleMapsService;
                this.clinicalTripsService = clinicalTripsService;
                this.breadcrumbs = [
                    new google.maps.LatLng(38.009351599999995, -84.579539),
                    new google.maps.LatLng(38.0094121, -84.5795339),
                ];
                this.stopNumbers = document.getElementsByClassName('stop-number');
                this.directionsService = new google.maps.DirectionsService();
                this.directionsDisplay = new google.maps.DirectionsRenderer({
                    preserveViewport: true,
                    suppressMarkers: true,
                });
                this.clinicalTripMap = new google.maps.Map(document.getElementById('map'));
                this.bounds = new google.maps.LatLngBounds();
                this.mapDiv = document.querySelector('div#map');
                this.mapDimensions = {
                    width: this.mapDiv.clientWidth,
                    height: this.mapDiv.clientHeight,
                };
            }
            ClinicalTripsMap.prototype.$onInit = function () {
                this.initMap();
            };
            ClinicalTripsMap.prototype.$onChanges = function (changes) {
                if (changes.trip.currentValue.length > 0) {
                    this.setBounds(this.tripLatLng);
                    this.calculateRoute(this.trip);
                    this.getBreadcrumbs();
                }
            };
            ClinicalTripsMap.prototype.$onDestroy = function () { };
            ClinicalTripsMap.prototype.initMap = function () {
                var mapOptions = {
                    zoom: 4,
                    center: { lat: 39, lng: -95 },
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                };
                this.clinicalTripMap.setOptions(mapOptions);
                this.directionsDisplay.setMap(this.clinicalTripMap);
            };
            ClinicalTripsMap.prototype.setBounds = function (latLngAddresses) {
                var _this = this;
                latLngAddresses.forEach(function (address) {
                    _this.bounds.extend(address);
                });
                this.setMapZoom();
            };
            ClinicalTripsMap.prototype.setOriginMarker = function (point) {
                if (this.marker) {
                    this.resetMap();
                }
                this.bounds.extend(point);
                this.marker = new google.maps.Marker({
                    position: point,
                    map: this.clinicalTripMap,
                });
                this.setMapZoom();
            };
            ClinicalTripsMap.prototype.resetMap = function () {
                this.directionsDisplay.set('directions', null);
                if (this.marker) {
                    this.marker.setMap(null);
                }
                this.bounds = new google.maps.LatLngBounds();
            };
            ClinicalTripsMap.prototype.setMapZoom = function () {
                this.clinicalTripMap.setCenter(this.bounds.getCenter());
                this.clinicalTripMap.setZoom(this.GoogleMapsService.getOptimalZoomLevel(this.bounds, this.mapDimensions));
            };
            ClinicalTripsMap.prototype.calculateRoute = function (addresses) {
                var _this = this;
                this.GoogleMapsService.getDirections(addresses)
                    .then(function (result) {
                    console.log(result);
                    _this.directionsDisplay.setDirections(result);
                    _this.setRouteMarkers(result.routes[0]);
                    _this.GoogleMapsService.getStaticMapURL(result);
                })
                    .catch(function (error) {
                    _this.logger.error(error);
                });
            };
            ClinicalTripsMap.prototype.setRouteMarkers = function (route) {
                var _this = this;
                var currentRoute = route.legs;
                currentRoute.forEach(function (step, index) {
                    if (index === 0) {
                        var startingMarker_1 = new google.maps.Marker({
                            position: step.start_location,
                            map: _this.clinicalTripMap,
                            animation: google.maps.Animation.DROP,
                        });
                        var infowindow_1 = new google.maps.InfoWindow({
                            content: step.start_address,
                        });
                        startingMarker_1.addListener('click', function () {
                            infowindow_1.open(this.clinicalTripMap, startingMarker_1);
                        });
                    }
                    var label = (index + 1).toString();
                    var marker = new google.maps.Marker({
                        position: step.end_location,
                        map: _this.clinicalTripMap,
                        label: label,
                        animation: google.maps.Animation.DROP,
                    });
                    var content = _this.clinicalTrip.stop_points[index].destination_name + '<br>' + step.end_address;
                    var infowindow = new google.maps.InfoWindow({
                        content: content,
                    });
                    marker.addListener('click', function () {
                        infowindow.open(this.clinicalTripMap, marker);
                    });
                    _this.setStopPositionClick(_this.clinicalTripMap, marker, index, infowindow);
                });
            };
            ClinicalTripsMap.prototype.setStopPositionClick = function (map, marker, stop_number, infowindow) {
                var _this = this;
                console.log(this.stopNumbers[stop_number]);
                this.stopNumbers[stop_number].addEventListener('click', function () {
                    _this.setBounds([marker.getPosition()]);
                    infowindow.open(_this.clinicalTripMap, marker);
                });
            };
            ClinicalTripsMap.prototype.staticMapURL = function () {
                return this.GoogleMapsService.staticMapURL;
            };
            ClinicalTripsMap.prototype.getBreadcrumbs = function () {
                var _this = this;
                this.clinicalTripsService
                    .getClinicalTripBreadcrumbs(this.clinicalTrip.user_id, this.clinicalTrip.effective_date)
                    .then(function (response) {
                    console.log(response);
                    _this.setBreadCrumbMarkers(response.data);
                })
                    .catch(function (error) {
                    error.data.errors.forEach(function (error) {
                        _this.logger.error(error);
                    });
                });
            };
            ClinicalTripsMap.prototype.setBreadCrumbMarkers = function (breadcrumbs) {
                var _this = this;
                console.log(breadcrumbs);
                if (breadcrumbs.length > 0) {
                    breadcrumbs.forEach(function (breadcrumb) {
                        var latitude = parseFloat(breadcrumb.latitude);
                        var longitude = parseFloat(breadcrumb.longitude);
                        var position = {
                            lat: latitude,
                            lng: longitude,
                        };
                        var marker = new google.maps.Marker({
                            position: position,
                            map: _this.clinicalTripMap,
                            animation: google.maps.Animation.DROP,
                        });
                        var infowindow = new google.maps.InfoWindow({
                            content: _this.breadcrumbTime(breadcrumb.breadcrumb_datetime),
                        });
                        marker.addListener('click', function () {
                            infowindow.open(this.clinicalTripMap, marker);
                        });
                    });
                }
            };
            ClinicalTripsMap.prototype.breadcrumbTime = function (breadcrumb) {
                var d = new Date(breadcrumb), timeStr;
                var hour = d.getHours();
                var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
                if (hour > 12) {
                    return (timeStr = hour - 12 + ':' + minutes + 'pm');
                }
                else if (hour === 12) {
                    return (timeStr = '12:' + minutes + 'pm');
                }
                else if (hour === 0) {
                    return (timeStr = '12:' + minutes + 'am');
                }
                else {
                    return (timeStr = hour + ':' + minutes + 'am');
                }
            };
            ClinicalTripsMap.$inject = [
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'RouteManagerService',
                'GoogleMapsService',
                'clinicalTripsService',
            ];
            return ClinicalTripsMap;
        }());
        mapComponents.ClinicalTripsMap = ClinicalTripsMap;
        angular.module('consolo').controller('ClinicalTripsMap', ClinicalTripsMap);
    })(mapComponents = consolo.mapComponents || (consolo.mapComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var GoogleMapsService = /** @class */ (function () {
            function GoogleMapsService($q) {
                this.$q = $q;
                this.staticMapURL = '';
                this.searchAddressMarker = null;
            }
            GoogleMapsService.prototype.getDirections = function (allStopPoints, geocode) {
                var _this = this;
                if (geocode === void 0) { geocode = false; }
                var directionsService = new google.maps.DirectionsService();
                var request = {
                    origin: this.getTripOrigin(allStopPoints),
                    destination: this.getTripDestination(allStopPoints),
                    travelMode: google.maps.TravelMode.DRIVING,
                };
                if (allStopPoints.length > 2) {
                    request.waypoints = this.getTripWayPoints(allStopPoints);
                }
                return this.$q(function (resolve, reject) {
                    directionsService.route(request, function (result, status) {
                        if (status === google.maps.DirectionsStatus.OK) {
                            return resolve(result);
                        }
                        else if (status === google.maps.DirectionsStatus.NOT_FOUND && !geocode) {
                            // Try again and geocode stop points
                            _this.geocodeStopPoints(allStopPoints).then(function (geocodedStopPoints) {
                                _this.getDirections(geocodedStopPoints, true).then(function (result) {
                                    return resolve(result);
                                });
                            });
                        }
                        else {
                            return reject(status);
                        }
                    });
                });
            };
            GoogleMapsService.prototype.geocodeStopPoints = function (allStopPoints) {
                var _this = this;
                var promises = [];
                allStopPoints.forEach(function (stopPoint) {
                    promises.push(_this.geocodeStopPoint(stopPoint));
                });
                return this.$q.all(promises).then(function (geocodedStopPoints) {
                    return geocodedStopPoints;
                });
            };
            GoogleMapsService.prototype.geocodeStopPoint = function (stopPoint) {
                var _this = this;
                return this.$q(function (resolve, reject) {
                    if (stopPoint && stopPoint.lat && stopPoint.lng) {
                        return resolve(stopPoint);
                    }
                    else if (stopPoint.match(/^[-\d\.]+,[-\d\.]+$/)) {
                        return resolve(stopPoint);
                    }
                    else {
                        _this.geocodeAddress(stopPoint)
                            .then(function (result) {
                            return resolve(stopPoint);
                        })
                            .catch(function (error) {
                            var cityStateZip = stopPoint
                                .split(',')
                                .slice(1)
                                .join(',')
                                .trim();
                            _this.geocodeAddress(cityStateZip)
                                .then(function (result) {
                                return resolve(cityStateZip);
                            })
                                .catch(function (error) {
                                return reject(error);
                            });
                        });
                    }
                });
            };
            GoogleMapsService.prototype.getTripOrigin = function (allStopPoints) {
                if (allStopPoints.length >= 1) {
                    return allStopPoints[0];
                }
            };
            GoogleMapsService.prototype.getTripDestination = function (allStopPoints) {
                if (allStopPoints.length > 1) {
                    return allStopPoints[allStopPoints.length - 1];
                }
            };
            GoogleMapsService.prototype.getTripWayPoints = function (allStopPoints) {
                var wayPoints = allStopPoints
                    .filter(function (point, index) {
                    if (index !== 0 && index !== allStopPoints.length - 1) {
                        return point;
                    }
                })
                    .map(function (stop, index) {
                    return {
                        location: stop,
                        stopover: true,
                    };
                });
                return wayPoints;
            };
            GoogleMapsService.prototype.getRoute = function (addresses, route) {
                var routeDetails = {
                    distance: '',
                    time: null,
                    stops: [],
                };
                routeDetails.distance = this.meterToMileConverter(this.getTotalRouteMileage(route.legs));
                routeDetails.time = this.getTotalRouteTime(route.legs);
                routeDetails.stops = this.getRouteStops(addresses, route.legs);
                // Add empty first space to make up for origin position that isn't included in array
                routeDetails.stops.unshift(null);
                return routeDetails;
            };
            GoogleMapsService.prototype.getRouteStops = function (addresses, legs) {
                var _this = this;
                var routeStops;
                routeStops = legs.map(function (leg, index) {
                    return {
                        distance: _this.meterToMileConverter(leg.distance.value),
                        time: leg.duration.value,
                        directions: leg.steps.map(function (step) {
                            var doc = new DOMParser().parseFromString(step.instructions, 'text/html');
                            return doc.body.innerText;
                        }),
                        warning: _this.getRouteWarnings(addresses[index], leg),
                    };
                });
                return routeStops;
            };
            GoogleMapsService.prototype.setStartandEndingDirections = function (directions, leg) {
                directions.unshift('Starting at: ' + leg.start_address);
                directions.push('Arrived at: ' + leg.end_address);
                return directions;
            };
            GoogleMapsService.prototype.getTotalRouteTime = function (legs) {
                return legs
                    .map(function (leg) {
                    return leg.duration.value;
                })
                    .reduce(function (accumulator, currentValue) { return accumulator + currentValue; }, 0);
            };
            GoogleMapsService.prototype.getTotalRouteMileage = function (legs) {
                return legs
                    .map(function (leg) {
                    return leg.distance.value;
                })
                    .reduce(function (accumulator, currentValue) { return accumulator + currentValue; }, 0);
            };
            GoogleMapsService.prototype.meterToMileConverter = function (totalMeterMileage) {
                return (totalMeterMileage * 0.000621371192).toFixed(1).toString() + ' mi';
            };
            GoogleMapsService.prototype.getOptimalZoomLevel = function (bounds, mapDim) {
                var WORLD_DIM = { height: 256, width: 256 };
                var ZOOM_MAX = 21;
                var ZOOM_PADDING = 1;
                function latRad(lat) {
                    var sin = Math.sin((lat * Math.PI) / 180);
                    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
                    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
                }
                function zoom(mapPx, worldPx, fraction) {
                    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
                }
                var ne = bounds.getNorthEast();
                var sw = bounds.getSouthWest();
                var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
                var lngDiff = ne.lng() - sw.lng();
                var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
                var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
                var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
                return Math.min(Math.min(latZoom, lngZoom) - ZOOM_PADDING, ZOOM_MAX);
            };
            GoogleMapsService.prototype.getStaticMapURL = function (directionsDisplay) {
                var gmapsKey = '&key=AIzaSyDlUjo4ao3_TX5aMpacLzsSITTx5-2ou6E';
                var request = directionsDisplay.request;
                var start = request.origin.location.lat() + ',' + request.origin.location.lng();
                var end = request.destination.query;
                // let end = request.destination.location.lat() + ',' + request.destination.location.lng();
                var path = directionsDisplay.routes[0].overview_polyline;
                var markers = [];
                var waypoints_labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
                var waypoints_label_iter = 0;
                markers.push('markers=color:green|label:' + waypoints_labels[waypoints_label_iter] + '|' + start);
                if (request.waypoints !== undefined) {
                    for (var i = 0; i < request.waypoints.length; i++) {
                        // If I have waypoints that are not stopovers I dont want to display them
                        if (request.waypoints[i].stopover === true) {
                            markers.push('markers=color:blue|label:' +
                                waypoints_labels[++waypoints_label_iter] +
                                '|' +
                                request.waypoints[i].location.query);
                            // '|' + request.waypoints[i].location.location.lat() + ',' + request.waypoints[i].location.location.lng());
                        }
                    }
                }
                markers.push('markers=color:red|label:' + waypoints_labels[++waypoints_label_iter] + '|' + end);
                var joinedMarkers = markers.join('&');
                this.staticMapURL =
                    'https://maps.googleapis.com/maps/api/staticmap?size=640x200&scale=2&maptype=roadmap&path=enc:' +
                        path +
                        '&' +
                        joinedMarkers +
                        gmapsKey;
            };
            GoogleMapsService.prototype.geocodeAddress = function (address) {
                var geocoder = new google.maps.Geocoder();
                return this.$q(function (resolve, reject) {
                    geocoder.geocode({ address: address }, function (result, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            return resolve(result);
                        }
                        else {
                            return reject(status);
                        }
                    });
                });
            };
            GoogleMapsService.prototype.addMapMarker = function (geocoderResult, map) {
                if (this.searchAddressMarker) {
                    this.searchAddressMarker.setMap(null);
                    this.searchAddressMarker = null;
                }
                var bounds = new google.maps.LatLngBounds();
                var position = geocoderResult[0].geometry.location;
                var popupContent = geocoderResult[0].formatted_address;
                var marker = new google.maps.Marker({
                    map: map,
                    position: position,
                    label: '',
                    icon: {
                        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
                        fillColor: '#3f51b5',
                        fillOpacity: 1,
                        strokeColor: '#fff',
                        strokeWeight: 2,
                        scale: 1.25,
                        labelOrigin: new google.maps.Point(0, -28),
                    },
                });
                bounds.extend(position);
                map.setCenter(geocoderResult[0].geometry.location);
                map.fitBounds(bounds);
                var infowindow = new google.maps.InfoWindow({
                    content: popupContent,
                });
                marker.addListener('click', function () {
                    infowindow.open(map, marker);
                });
                this.searchAddressMarker = marker;
                return this.$q(function (resolve, reject) {
                    return resolve(marker);
                });
            };
            GoogleMapsService.prototype.setBounds = function (markers, map, dimensions) {
                var bounds = new google.maps.LatLngBounds();
                markers.forEach(function (marker) {
                    bounds.extend(marker.getPosition());
                });
                map.setCenter(bounds.getCenter());
                map.setZoom(this.getOptimalZoomLevel(bounds, dimensions));
            };
            /*
             * Check route.locations against the original addresses.
             * (MapQuest has an annoying habbit of returning addresses from different cities,
             * and now we are looking up these addresses in Google Maps).
             */
            GoogleMapsService.prototype.getRouteWarnings = function (address, leg) {
                // Look up each address in Google Maps JS (locationSequence map in MapQuest JS)
                // Compare sought to found
                // Pull individual fields from each route location (found)
                // If they don't match, show the error
                // If can't even find the zip code, show generic error
                // return addresses.map(function(address, i) {
                var sought = address, // 2072 Lantern Ridge Dr, Richmond, KY, 40475-6010
                found = leg; // end_address - 2072 Lantern Ridge Dr, Richmond, KY 40475, USA
                if (found.end_address) {
                    // Split addresses into pieces
                    var soughtElements = sought.split(',');
                    var soughtAddress = soughtElements[0] ? soughtElements[0].trim() : '';
                    var soughtCity = soughtElements[1] ? soughtElements[1].trim() : '';
                    var soughtState = soughtElements[2] ? soughtElements[2].trim() : '';
                    var soughtZip = soughtElements[3] ? soughtElements[3].trim().substring(0, 5) : '';
                    var foundElements = found.end_address.split(',');
                    var foundAddress = foundElements[0] ? foundElements[0].trim() : '';
                    var foundCity = foundElements[1] ? foundElements[1].trim() : '';
                    var foundStateZip = foundElements[2] ? foundElements[2].trim().split(' ') : '';
                    var foundState = foundStateZip[0] ? foundStateZip[0].trim() : '';
                    var foundZip = foundStateZip[1] ? foundStateZip[1].trim().substring(0, 5) : '';
                    if (soughtAddress.toLowerCase() !== foundAddress.toLowerCase() ||
                        soughtCity.toLowerCase() !== foundCity.toLowerCase() ||
                        soughtState.toLowerCase() !== foundState.toLowerCase() ||
                        soughtZip !== foundZip) {
                        return ('The closest address that could be found was "' +
                            found.end_address +
                            '". It\'s possible that ' +
                            "the address service's information is out of date. But first, please verify that this stop's " +
                            'address was entered correctly into Consolo.');
                    }
                }
                else {
                    return 'Consolo was not able to find this address.';
                }
            };
            GoogleMapsService.$inject = ['$q'];
            return GoogleMapsService;
        }());
        services.GoogleMapsService = GoogleMapsService;
        angular.module('consolo').service('GoogleMapsService', GoogleMapsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var mapComponents;
    (function (mapComponents) {
        angular.module('consolo').component('googlePoiMap', {
            bindings: { locations: '<', address: '<', calculating: '<' },
            templateUrl: '/y/templates/maps/google-maps/poi-map.html',
            controller: 'PoiMap as vm',
        });
        var PoiMap = /** @class */ (function () {
            function PoiMap($state, $filter, $http, logger, $mdDialog, RouteManager, GoogleMapsService, clinicalTripsService, DispatchViewService, $scope, $interval, Breadcrumb, $q) {
                var _this = this;
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.RouteManager = RouteManager;
                this.GoogleMapsService = GoogleMapsService;
                this.clinicalTripsService = clinicalTripsService;
                this.DispatchViewService = DispatchViewService;
                this.$scope = $scope;
                this.$interval = $interval;
                this.Breadcrumb = Breadcrumb;
                this.$q = $q;
                this.directionsService = new google.maps.DirectionsService();
                this.directionsDisplay = new google.maps.DirectionsRenderer({
                    preserveViewport: true,
                });
                this.userList = [];
                this.infoWindow = new google.maps.InfoWindow();
                this.markers = [];
                this.poiMap = new google.maps.Map(document.getElementById('map'));
                this.bounds = new google.maps.LatLngBounds();
                this.mapDiv = document.querySelector('div#map');
                this.mapDimensions = {
                    width: this.mapDiv.clientWidth,
                    height: this.mapDiv.clientHeight,
                };
                this.poi = {};
                this.updateTimer = $interval(function () {
                    _this.updateAllLocations(true);
                }, 10000);
                $scope.$watch(function () {
                    return DispatchViewService.addressSearchTerm;
                }, function (newVal, oldVal) {
                    $scope.vm.address = newVal;
                    this.address = newVal;
                    $scope.vm.addAddress(newVal);
                });
                $scope.$watchCollection(function () {
                    return DispatchViewService.locations;
                }, function (newVal, oldVal) {
                    if (newVal) {
                        $scope.vm.locations = newVal;
                        $scope.vm.updateAllLocations();
                    }
                });
                $scope.$watchCollection(function () {
                    return DispatchViewService.userList;
                }, function (newVal, oldVal) {
                    if (newVal) {
                        $scope.vm.userList = newVal.map(function (user) { return parseInt(user.id, 10); });
                        $scope.vm.updateAllLocations();
                    }
                });
            }
            PoiMap.prototype.$onInit = function () {
                console.log('Poi map working');
                this.initMap();
            };
            PoiMap.prototype.$onDestroy = function () {
                this.$interval.cancel(this.updateTimer);
            };
            PoiMap.prototype.initMap = function () {
                var mapOptions = {
                    zoom: 4,
                    center: { lat: 39, lng: -95 },
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                };
                this.poiMap.setOptions(mapOptions);
            };
            PoiMap.prototype.updateAllLocations = function (freezeViewport) {
                var _this = this;
                var userIds = Object.keys(this.locations);
                var clearMarkers = function () {
                    _this.markers.forEach(function (marker) {
                        marker.setMap(null);
                    });
                    _this.markers = [];
                };
                if (userIds.length) {
                    this.Breadcrumb.current({ 'user_ids[]': userIds, max_hours: 3000 }, function (breadcrumbs) {
                        if (_this.markers.length === breadcrumbs.length) {
                            var changeDetected = false;
                            for (var i = 0; i < breadcrumbs.length; i++) {
                                if (_this.markers[i]
                                    .getPosition()
                                    .lat()
                                    .toFixed(5) !== breadcrumbs[i].latitude.toFixed(5) ||
                                    _this.markers[i]
                                        .getPosition()
                                        .lng()
                                        .toFixed(5) !== breadcrumbs[i].longitude.toFixed(5)) {
                                    changeDetected = true;
                                    break;
                                }
                            }
                            if (!changeDetected) {
                                return;
                            }
                        }
                        clearMarkers();
                        breadcrumbs.forEach(function (breadcrumb) {
                            var time = breadcrumb['breadcrumb_datetime'] || breadcrumb['updated_at'];
                            var location = new google.maps.LatLng(parseFloat(breadcrumb.latitude), parseFloat(breadcrumb.longitude));
                            var title = _this.locations[breadcrumb.user_id].name + " - " + _this.breadcrumbTime(time);
                            var label = (_this.userList.indexOf(parseInt(breadcrumb.user_id, 10)) + 1).toString();
                            var marker = _this.createAndRegisterMarker(location, title, label, title);
                            _this.markers.push(marker);
                        });
                        if (_this.markers.length && !freezeViewport) {
                            _this.setBounds();
                        }
                    });
                }
                else {
                    clearMarkers();
                }
            };
            PoiMap.prototype.createAndRegisterMarker = function (location, title, label, info) {
                var _this = this;
                var marker = new google.maps.Marker({
                    map: this.poiMap,
                    position: location,
                    label: label
                        ? {
                            text: label,
                            color: '#fff',
                            fontWeight: 'bold',
                        }
                        : '',
                    title: title,
                    icon: {
                        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
                        fillColor: '#d9534f',
                        fillOpacity: 1,
                        strokeColor: '#fff',
                        strokeWeight: 2,
                        scale: 1.25,
                        labelOrigin: new google.maps.Point(0, -28),
                    },
                });
                marker.addListener('click', function (event) {
                    _this.infoWindow.setContent(info);
                    _this.infoWindow.open(_this.poiMap, marker);
                });
                return marker;
            };
            PoiMap.prototype.setBounds = function () {
                var markers = this.addressMarker ? __spreadArrays(this.markers, [this.addressMarker]) : this.markers;
                if (markers.length) {
                    this.GoogleMapsService.setBounds(markers, this.poiMap, this.mapDimensions);
                }
            };
            PoiMap.prototype.addAddress = function (address) {
                var _this = this;
                if (address) {
                    this.GoogleMapsService.geocodeAddress(address)
                        .then(function (geoCoderResult) {
                        _this.GoogleMapsService.addMapMarker(geoCoderResult, _this.poiMap).then(function (marker) {
                            _this.addressMarker = marker;
                            _this.setBounds();
                        });
                    })
                        .catch(function (error) {
                        console.log(error);
                        _this.logger.error(error, 'Error Finding Address: ');
                    });
                }
            };
            PoiMap.prototype.resetMap = function () {
                this.directionsDisplay.set('directions', null);
                if (this.marker) {
                    this.marker.setMap(null);
                }
                this.bounds = new google.maps.LatLngBounds();
            };
            PoiMap.prototype.calculateRoute = function (addresses) {
                var _this = this;
                this.GoogleMapsService.getDirections(addresses)
                    .then(function (result) {
                    _this.directionsDisplay.setDirections(result);
                    _this.GoogleMapsService.getStaticMapURL(result);
                })
                    .catch(function (error) {
                    _this.logger.error(error);
                });
            };
            PoiMap.prototype.staticMapURL = function () {
                return this.GoogleMapsService.staticMapURL;
            };
            PoiMap.prototype.getCurrentBreadcrumbs = function (user_ids, maxHours) {
                return this.clinicalTripsService.getCurrentClinicalTripBreadcrumbs(user_ids, maxHours);
            };
            PoiMap.prototype.setBreadCrumbMarkers = function (breadcrumbs) {
                var _this = this;
                if (breadcrumbs.length > 0) {
                    breadcrumbs.forEach(function (breadcrumb) {
                        var position = {
                            lat: breadcrumb.latitude,
                            lng: breadcrumb.longitude,
                        };
                        var marker = new google.maps.Marker({
                            position: position,
                            map: _this.poiMap,
                            animation: google.maps.Animation.DROP,
                        });
                        var infowindow = new google.maps.InfoWindow({
                            content: _this.breadcrumbTime(breadcrumb.breadcrumb_datetime),
                        });
                        marker.addListener('click', function () {
                            infowindow.open(this.poiMap, marker);
                        });
                    });
                }
            };
            PoiMap.prototype.breadcrumbTime = function (dateTime) {
                var d = new Date(dateTime), timeStr;
                var hour = d.getHours();
                var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
                if (hour > 12) {
                    timeStr = hour - 12 + ':' + minutes + ' PM';
                }
                else if (hour === 12) {
                    timeStr = '12:' + minutes + ' PM';
                }
                else if (hour === 0) {
                    timeStr = '12:' + minutes + ' AM';
                }
                else {
                    timeStr = hour + ':' + minutes + ' AM';
                }
                return timeStr;
            };
            PoiMap.$inject = [
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'RouteManagerService',
                'GoogleMapsService',
                'clinicalTripsService',
                'DispatchViewService',
                '$scope',
                '$interval',
                'Breadcrumb',
                '$q',
            ];
            return PoiMap;
        }());
        mapComponents.PoiMap = PoiMap;
        angular.module('consolo').controller('PoiMap', PoiMap);
    })(mapComponents = consolo.mapComponents || (consolo.mapComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var mapComponents;
    (function (mapComponents) {
        angular.module('consolo').component('googleRouteMap', {
            bindings: {
                origin: '<',
                addresses: '<',
                checkAddresses: '<',
                route: '=',
                routeType: '<',
                calculating: '<',
                trip: '<',
                stopPoints: '<',
                mapBounds: '<',
            },
            templateUrl: '/y/templates/maps/google-maps/route-map.html',
            controller: 'RouteMap as vm',
        });
        var RouteMap = /** @class */ (function () {
            function RouteMap($state, $filter, $http, logger, $mdDialog, RouteManager, GoogleMapsService) {
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.RouteManager = RouteManager;
                this.GoogleMapsService = GoogleMapsService;
                this.directionsService = new google.maps.DirectionsService();
                this.directionsDisplay = new google.maps.DirectionsRenderer({
                    preserveViewport: true,
                });
                this.routeMap = new google.maps.Map(document.getElementById('map'));
                this.bounds = new google.maps.LatLngBounds();
                this.mapDiv = document.querySelector('div#map');
                this.mapDimensions = {
                    width: this.mapDiv.clientWidth,
                    height: this.mapDiv.clientHeight,
                };
            }
            RouteMap.prototype.$onInit = function () {
                document.querySelector('html').classList.add('visit-tracker-print');
                this.setVisitPoints();
                this.initMap();
            };
            RouteMap.prototype.$onChanges = function (changes) {
                if (this.trip.origin_latitude && this.trip.origin_longitude) {
                    console.log(changes);
                    if (changes.addresses !== undefined &&
                        changes.addresses.currentValue.length <= 1 &&
                        changes.addresses.previousValue.length > 1) {
                        this.resetMap();
                        this.setOriginMarker(this.startPoint);
                    }
                    else if (changes.addresses !== undefined && changes.addresses.currentValue.length === 1) {
                        this.setVisitPoints();
                        this.setOriginMarker(this.startPoint);
                    }
                    else if (changes.addresses !== undefined && changes.addresses.currentValue.length > 1) {
                        if (this.bounds) {
                            this.bounds = new google.maps.LatLngBounds();
                        }
                        this.setVisitPoints();
                        this.calculateRoute(this.addresses);
                    }
                    else if (changes.stopPoints &&
                        !changes.addresses &&
                        changes.stopPoints.currentValue.length === changes.stopPoints.previousValue.length) {
                        if (this.bounds) {
                            this.bounds = new google.maps.LatLngBounds();
                        }
                        this.setVisitPoints();
                        this.calculateRoute(this.addresses);
                    }
                }
            };
            RouteMap.prototype.$onDestroy = function () {
                document.querySelector('html').classList.remove('visit-tracker-print');
                document.querySelector('html').removeAttribute('class');
            };
            RouteMap.prototype.initMap = function () {
                var mapOptions = {
                    zoom: this.trip.origin_latitude && this.trip.origin_longitude
                        ? this.GoogleMapsService.getOptimalZoomLevel(this.bounds, this.mapDimensions) - 1
                        : 4,
                    center: this.trip.origin_latitude && this.trip.origin_longitude
                        ? this.bounds.getCenter()
                        : { lat: 39, lng: -95 },
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                };
                this.routeMap.setOptions(mapOptions);
                this.directionsDisplay.setMap(this.routeMap);
            };
            RouteMap.prototype.setOriginMarker = function (point) {
                if (this.marker) {
                    this.resetMap();
                }
                this.bounds.extend(point);
                this.marker = new google.maps.Marker({
                    position: point,
                    map: this.routeMap,
                });
                this.setMapZoom();
            };
            RouteMap.prototype.resetMap = function () {
                this.directionsDisplay.set('directions', null);
                if (this.marker) {
                    this.marker.setMap(null);
                }
                this.bounds = new google.maps.LatLngBounds();
            };
            RouteMap.prototype.setVisitPoints = function () {
                var _this = this;
                if (this.trip.origin_latitude && this.trip.origin_longitude) {
                    this.startPoint = new google.maps.LatLng(this.trip.origin_latitude, this.trip.origin_longitude);
                    this.bounds.extend(this.startPoint);
                    if (this.stopPoints.length === 1) {
                        this.destination = this.stopPoints[0];
                        this.bounds.extend(this.destination);
                    }
                    else if (this.stopPoints.length > 1) {
                        this.destination = this.stopPoints[this.stopPoints.length - 1];
                        this.bounds.extend(this.destination);
                        this.wayPoints = this.stopPoints.map(function (point, index) {
                            _this.bounds.extend(point);
                            return {
                                location: point,
                                stopover: true,
                            };
                        });
                        this.wayPoints.pop();
                    }
                    else {
                        return;
                    }
                }
                this.setMapZoom();
            };
            RouteMap.prototype.setMapZoom = function () {
                this.routeMap.setCenter(this.bounds.getCenter());
                this.routeMap.setZoom(this.GoogleMapsService.getOptimalZoomLevel(this.bounds, this.mapDimensions));
            };
            RouteMap.prototype.calculateRoute = function (addresses) {
                var _this = this;
                this.GoogleMapsService.getDirections(addresses)
                    .then(function (result) {
                    console.log(result);
                    _this.directionsDisplay.setDirections(result);
                    _this.GoogleMapsService.getStaticMapURL(result);
                    _this.RouteManager.data.route = _this.GoogleMapsService.getRoute(addresses.slice(1), result.routes[0]);
                })
                    .catch(function (error) {
                    _this.logger.error(error);
                });
                this.setMapZoom();
            };
            RouteMap.prototype.staticMapURL = function () {
                return this.GoogleMapsService.staticMapURL;
            };
            RouteMap.$inject = [
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'RouteManagerService',
                'GoogleMapsService',
            ];
            return RouteMap;
        }());
        mapComponents.RouteMap = RouteMap;
        angular.module('consolo').controller('RouteMap', RouteMap);
    })(mapComponents = consolo.mapComponents || (consolo.mapComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientImageModalController = /** @class */ (function () {
            function PatientImageModalController($mdDialog, $upload, $http) {
                this.$mdDialog = $mdDialog;
                this.$upload = $upload;
                this.$http = $http;
            }
            PatientImageModalController.prototype.cancel = function () {
                this.$mdDialog.hide();
            };
            PatientImageModalController.prototype.upload = function () {
                var _this = this;
                this.$upload
                    .http({
                    url: '/api/v1/patients/' + this.currentPatient.id + '/picture',
                    headers: { 'Content-Type': 'application/octet-stream' },
                    method: 'POST',
                    data: this.picFile,
                })
                    .then(function (p) {
                    _this.$mdDialog.hide(p);
                });
            };
            PatientImageModalController.$inject = ['$mdDialog', 'Upload', '$http'];
            return PatientImageModalController;
        }());
        angular.module('consolo').controller('PatientImageModalController', PatientImageModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('patientSidebarDetail', {
            bindings: {
                label: '@',
                value: '<',
            },
            template: "\n      <div class=\"text-display-container\" layout=\"row\" layout-wrap>\n        <label ng-bind-html=\"::$ctrl.label\" flex=\"100\"></label>\n        <div ng-if=\"$ctrl.value !== true && $ctrl.value !== false\" ng-bind-html=\"$ctrl.value\" flex=\"100\"></div>\n        <div ng-if=\"$ctrl.value === true || $ctrl.value === false\" flex=\"100\">{{ $ctrl.value | yes_no }}</div>\n        <div ng-if=\"$ctrl.value === '' || $ctrl.value == null || $ctrl.value === undefined\" flex=\"100\">~</div>\n      </div>\n    ",
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular.module('consolo').directive('consoloPatientSidebar', Directive);
    function Directive() {
        return {
            restrict: 'E',
            templateUrl: '/y/templates/navbar/patient-sidebar/patient-sidebar.html',
            controller: [
                '$mdSidenav',
                '$scope',
                '$rootScope',
                '$state',
                'cache',
                'CurrentPatient',
                'Lookup',
                '$mdDialog',
                'PermissionsService',
                Controller,
            ],
            controllerAs: 'vm',
        };
    }
    function Controller($mdSidenav, $scope, $root, $state, cache, CurrentPatient, Lookup, $mdDialog, PermissionsService) {
        var vm = this;
        vm.$state = $state;
        vm.currentPatient = null;
        vm.selectedPatient = null;
        vm.discipline = null;
        vm.lockOpen = false;
        vm.getResults = getResults;
        vm.getDashboard = getDashboard;
        vm.selectPatient = selectPatient;
        vm.yesNo = yesNo;
        // vm.lockOpen = lockOpen;
        vm.imageDialog = imageDialog;
        activate();
        function activate() {
            $root.$on('$stateChangeSuccess', function (e, toState, toParams) {
                if (toState.data && toState.data.lockOpenPatientSidenav) {
                    vm.lockOpen = true;
                }
                else {
                    vm.lockOpen = false;
                }
                if (toParams.patientId) {
                    setPatient();
                }
                else {
                    clearPatient();
                }
            });
        }
        function imageDialog() {
            $mdDialog
                .show({
                clickOutsideToClose: false,
                bindToController: true,
                templateUrl: '/y/templates/navbar/patient-sidebar/patient-image-modal.html',
                controller: 'PatientImageModalController as vm',
                locals: { currentPatient: vm.currentPatient },
            })
                .then(function (p) {
                if (p) {
                    vm.currentPatient.picture_url = p.data.picture_url;
                    vm.currentPatient.picture_thumb_url = p.data.picture_thumb_url;
                }
            });
        }
        function clearPatient() {
            vm.currentPatient = null;
            vm.selectedPatient = null;
            vm.searchTerm = '';
        }
        function selectPatient(patient) {
            /* jshint browser: true */
            if (patient) {
                $state.go('patients.dashboard', { patientId: patient.id });
            }
        }
        function getDashboard() {
            if (CurrentPatient.patient.dashboard) {
                vm.dashboard = CurrentPatient.patient.dashboard;
                vm.discipline = CurrentPatient.patient.dashboard.discipline;
                vm.vitals = CurrentPatient.patient.recent_vitals;
                vm.showVitals =
                    ['rn', 'lpn', 'skilled_nurse', 'medical_director', 'physician', 'hospice_aide'].indexOf(vm.discipline) > -1;
                vm.showSpiritual = ['chaplain', 'social_worker', 'bereavement'].indexOf(vm.discipline) > -1;
                vm.showAdmin = vm.discipline === 'admin';
                vm.showHospiceAide = vm.discipline === 'hospice_aide';
                vm.searchTerm = CurrentPatient.patient.name;
                vm.showSmokingStatus = PermissionsService.can('show', 'smoking_statuses');
            }
        }
        function setPatient() {
            vm.currentPatient = CurrentPatient.patient;
            getDashboard();
        }
        function getResults(term) {
            return cache(Lookup, 'lookup', { force: true, encrypt: true })
                .fetch('patients', {
                limit: -1,
                active: true,
                deceased: true,
                pending_never_admitted: true,
            })
                .then(function (results) {
                if (results) {
                    // All lookup results were pre-cached - filter them and return
                    var lowerTerm = term.toLowerCase();
                    return results.filter(function (r) {
                        return r.name.toLowerCase().search(lowerTerm) > -1;
                    });
                }
                else {
                    // Hit the server but *don't* cache results
                    return Lookup.patients({ q: term }).$promise.then(function (results) {
                        return results;
                    });
                }
            }, function () {
                console.warn('Unable to fetch cached results');
                return Lookup.patients({ q: term }).$promise.then(function (results) {
                    return results;
                });
            });
        }
        function yesNo(term) {
            return !term ? 'No' : 'Yes';
        }
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var BillingDemographicsController = /** @class */ (function () {
            function BillingDemographicsController(BillingDemographicsDataTableService, $window, $scope, currentPatient, SidebarService) {
                this.BillingDemographicsDataTableService = BillingDemographicsDataTableService;
                this.$window = $window;
                this.$scope = $scope;
                this.currentPatient = currentPatient;
                this.SidebarService = SidebarService;
                this.wideTableScroll = {};
                this.onResize();
                this.$window.addEventListener('resize', this.onResize);
                this.BillingDemographicsDataTableService.reset();
                this.BillingDemographicsDataTableService.showFilters = false;
                this.SidebarService.open();
            }
            BillingDemographicsController.prototype.onResize = function () {
                if (this.$window) {
                    this.wideTableScroll.height = this.$window.innerHeight - 200 + 'px';
                }
                if (this.$scope && this.$scope.$root && !this.$scope.$root.$$phase) {
                    this.$scope.$digest();
                }
            };
            BillingDemographicsController.$inject = [
                'BillingDemographicsDataTableService',
                '$window',
                '$scope',
                'currentPatient',
                'SidebarService',
            ];
            return BillingDemographicsController;
        }());
        angular
            .module('consolo')
            .controller('BillingDemographicsController', BillingDemographicsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var BillingDemographicsDataTableService = /** @class */ (function (_super) {
            __extends(BillingDemographicsDataTableService, _super);
            function BillingDemographicsDataTableService($httpService, $interval, $state, CurrentPatient, logger) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.CurrentPatient = CurrentPatient;
                _this.logger = logger;
                _this.loadingData = false;
                _this.showFilters = false;
                _this.hideSearchTerm = false;
                _this.searchOnReset = true;
                _this.activate();
                return _this;
            }
            BillingDemographicsDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/billing_demographics';
                this.filters = new consolo.domain.BillingDemographicsFilter();
                this.tableLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [
                            { menuItemType: csg.datatable.MenuItemType.csvHeader },
                            { menuItemType: csg.datatable.MenuItemType.csvAll },
                            { menuItemType: csg.datatable.MenuItemType.copyHeader },
                            { menuItemType: csg.datatable.MenuItemType.copyAll },
                            {
                                label: 'Force Recalculate',
                                icon: 'autorenew',
                                callbackFn: function () {
                                    return _this.recalculate();
                                },
                            },
                        ],
                    },
                    options: {
                        borderStyle: 3,
                        hideTextFilter: true,
                        defaultPageSize: 100,
                    },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'effective_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'Significant', field: 'significant', type: csg.datatable.ColumnTypes.boolean },
                        {
                            title: 'EOC Index',
                            field: 'episode_of_care_index',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        { title: 'Office', field: 'office.name', type: csg.datatable.ColumnTypes.short_text },
                        { title: 'Team', field: 'team.name', type: csg.datatable.ColumnTypes.short_text },
                        {
                            title: 'MRN',
                            field: 'medical_record_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Primary ICD',
                            field: 'primary_icd',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'County',
                            field: 'county.description',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        { title: 'Facility', field: 'facility.name', type: csg.datatable.ColumnTypes.short_text },
                        {
                            title: 'Level Of Care',
                            field: 'level_of_care',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Admission Date',
                            field: 'admission_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Discharge Date',
                            field: 'discharge_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Hospice Payer',
                            field: 'primary_hospice_policy.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Primary Payer Number',
                            field: 'primary_payer_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Hospice Revenue Code',
                            field: 'hospice_revenue_code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Hospice Amount',
                            field: 'hospice_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (billingDemographic) {
                                return consolo.common.moneyDisplay(billingDemographic.hospice_amount);
                            },
                        },
                        {
                            title: 'Adjustment Amount',
                            field: 'adjustment_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (billingDemographic) {
                                return consolo.common.moneyDisplay(billingDemographic.adjustment_amount);
                            },
                        },
                        {
                            title: 'Net Amount',
                            field: 'net_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (billingDemographic) {
                                return consolo.common.moneyDisplay(billingDemographic.net_amount);
                            },
                        },
                        {
                            title: 'Hospice HCPCS',
                            field: 'hospice_hcpcs',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Procedure Code Description',
                            field: 'procedure_code_description',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Secondary Payer',
                            field: 'secondary_hospice_policy.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Secondary Payer Number',
                            field: 'secondary_hospice_payer_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Secondary Revenue Code',
                            field: 'secondary_hospice_revenue_code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Secondary Amount',
                            field: 'secondary_hospice_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (billingDemographic) {
                                return _this.formatMoney(billingDemographic.secondary_hospice_amount);
                            },
                        },
                        {
                            title: 'Secondary HCPCS',
                            field: 'secondary_hospice_hcpcs',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Room And Board Payer',
                            field: 'room_and_board_policy.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Room And Board Payer Number',
                            field: 'room_and_board_payer_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Room And Board Revenue Code',
                            field: 'room_and_board_revenue_code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Room And Board Amount',
                            field: 'room_and_board_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Room And Board HCPCS',
                            field: 'room_and_board_hcpcs',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Subordinate Room And Board Payer',
                            field: 'subordinate_room_and_board_policy.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Subordinate Room And Board Payer Number',
                            field: 'subordinate_room_and_board_payer_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Subordinate Room And Board Revenue Code',
                            field: 'subordinate_room_and_board_revenue_code',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Subordinate Room And Board Amount',
                            field: 'subordinate_room_and_board_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (billingDemographic) {
                                return _this.formatMoney(billingDemographic.subordinate_room_and_board_amount);
                            },
                        },
                        {
                            title: 'Subordinate Room And Board HCPCS',
                            field: 'subordinate_room_and_board_hcpcs',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Primary Encounter Payer',
                            field: 'primary_encounter_policy.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Primary Encounter Payer Number',
                            field: 'primary_encounter_payer_number',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                };
            };
            BillingDemographicsDataTableService.prototype.search = function () {
                var _this = this;
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.filters.patient_id = this.CurrentPatient.patient_id;
                    this.params = this.filters.getFilters();
                    this.apiInterval = this.$interval(function () {
                        if (_this.tableControl) {
                            _this.$interval.cancel(_this.apiInterval);
                            _this.tableControl.loadSelectData().finally(function () {
                                _this.loadingData = false;
                            });
                        }
                    }, 500, 20);
                }
            };
            BillingDemographicsDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.BillingDemographicsFilter();
                this.search();
            };
            BillingDemographicsDataTableService.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            BillingDemographicsDataTableService.prototype.recalculate = function () {
                var _this = this;
                this.$httpService
                    .post('/api/v1/billing_demographics/recalculate_demographics', {
                    patient_id: this.CurrentPatient.patient_id,
                })
                    .then(function (response) {
                    var text = 'A job has been queued to recalculate the demographics.  Wait a few seconds and then refresh this page to see the changes.';
                    _this.logger.success(text);
                    _this.data = [];
                });
            };
            BillingDemographicsDataTableService.prototype.formatMoney = function (value) {
                if (value) {
                    var roundedValue = Math.ceil(value * 100) / 100;
                    return '$' + roundedValue.toString();
                }
                else {
                    return '';
                }
            };
            BillingDemographicsDataTableService.$inject = ['$http', '$interval', '$state', 'CurrentPatient', 'logger'];
            return BillingDemographicsDataTableService;
        }(csg.datatable.ApiService));
        services.BillingDemographicsDataTableService = BillingDemographicsDataTableService;
        angular
            .module('consolo')
            .service('BillingDemographicsDataTableService', BillingDemographicsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CareProgramDialogController = /** @class */ (function () {
            function CareProgramDialogController($mdDialog) {
                this.$mdDialog = $mdDialog;
            }
            CareProgramDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.$mdDialog.hide(this.cpEnrollment);
            };
            CareProgramDialogController.prototype.setStartedBy = function () {
                this.cpEnrollment.started_by_id = this.currentUser.id;
            };
            CareProgramDialogController.prototype.setEndedBy = function () {
                this.cpEnrollment.ended_by_id = this.currentUser.id;
            };
            CareProgramDialogController.$inject = ['$mdDialog'];
            return CareProgramDialogController;
        }());
        angular.module('consolo').controller('CareProgramDialogController', CareProgramDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CareProgramEnrollment = consolo.domain.CareProgramEnrollment;
        var CareProgramsController = /** @class */ (function () {
            function CareProgramsController($state, $location, $mdDialog, $q, CPEnrollmentService, Session, logger, CurrentPatient, SectionManagerService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.CPEnrollmentService = CPEnrollmentService;
                this.Session = Session;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.SectionManagerService = SectionManagerService;
                this.carePrograms = [];
                this.patientId = null;
                this.isLoading = false;
                this.patientId = $state.params.patientId;
                this.currentUser = Session.getUser();
                this.initializeData();
            }
            CareProgramsController.prototype.initializeData = function () {
                var _this = this;
                this.isLoading = true;
                var promiseChain = [
                    this.getAllCarePrograms(),
                    this.getCPEnrollments(),
                    this.SectionManagerService.loadPermissions(),
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.canUpdate = _this.SectionManagerService.permissions.care_program_enrollments_update;
                    _this.canDestroy = _this.SectionManagerService.permissions.care_program_enrollments_destroy;
                    if (_this.$state.current.url === '/new') {
                        _this.cpDialog(null, 'add', null);
                    }
                    _this.initializeCpeTable();
                    _this.isLoading = false;
                });
            };
            CareProgramsController.prototype.getAllCarePrograms = function () {
                var _this = this;
                return this.CPEnrollmentService.getAllCarePrograms()
                    .then(function (response) {
                    _this.carePrograms = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    throw error;
                });
            };
            CareProgramsController.prototype.getCPEnrollments = function () {
                var _this = this;
                return this.CPEnrollmentService.getCPEnrollments(this.patientId)
                    .then(function (response) {
                    _this.cpEnrollments = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    throw error;
                });
            };
            CareProgramsController.prototype.deleteCpEnrollment = function (id) {
                var _this = this;
                this.isLoading = true;
                this.CPEnrollmentService.deleteIndicator(id)
                    .then(function (response) {
                    _this.getCPEnrollments().finally(function () {
                        _this.isLoading = false;
                    });
                    _this.logger.success('You have successfully deleted ' + response.data.name + '.', 'Success:');
                })
                    .catch(function (error) {
                    _this.isLoading = false;
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    throw error;
                });
            };
            CareProgramsController.prototype.cpDialog = function (cpEnrollment, action, $event) {
                var _this = this;
                (cpEnrollment = cpEnrollment
                    ? new CareProgramEnrollment(cpEnrollment)
                    : new CareProgramEnrollment({ patient_id: this.patientId })),
                    this.$mdDialog
                        .show({
                        targetEvent: $event,
                        clickOutsideToClose: true,
                        hasBackdrop: true,
                        templateUrl: '/y/templates/patients/care-programs/care-program-dialog.html',
                        controller: 'CareProgramDialogController as vm',
                        bindToController: true,
                        locals: {
                            carePrograms: this.carePrograms,
                            cpEnrollment: cpEnrollment,
                            action: action,
                            currentUser: this.currentUser,
                        },
                    })
                        .then(function (cpEnrollment) {
                        if (cpEnrollment) {
                            _this.isLoading = true;
                            var successAction_1 = action === 'add' ? 'added' : 'updated';
                            var apiCall = action === 'add'
                                ? _this.CPEnrollmentService.addCPEnrollment(cpEnrollment)
                                : _this.CPEnrollmentService.updateCPEnrollment(cpEnrollment);
                            apiCall
                                .then(function (response) {
                                _this.getCPEnrollments().finally(function () {
                                    _this.logger.success('You have successfully ' + successAction_1 + ' ' + response.data.name, 'Success:');
                                    _this.isLoading = false;
                                });
                            })
                                .catch(function (error) {
                                _this.logger.error(error.data.errors.join(', '), 'Error:');
                                _this.isLoading = false;
                                throw error;
                            });
                        }
                    });
            };
            CareProgramsController.prototype.initializeCpeTable = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.cpEnrollmentLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (data, item) {
                                    _this.cpDialog(data, 'view', null);
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.cpDialog(data, 'edit', null);
                                },
                                conditionalHideFn: function () {
                                    return !_this.canUpdate;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete ' + data.name + '?'))
                                        .then(function () {
                                        _this.deleteCpEnrollment(data.id);
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.canDestroy;
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Care Program',
                            field: 'name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Start Date',
                            field: 'start_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'End Date',
                            field: 'end_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'Started By', field: 'started_by.name', canSort: true },
                        {
                            title: 'Created At',
                            field: 'created_at',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                    ],
                    subTable: [
                        {
                            columns: [
                                { title: 'Comments', field: 'comments', type: csg.datatable.ColumnTypes.short_text },
                            ],
                        },
                    ],
                };
                this.isLoading = false;
            };
            CareProgramsController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                '$q',
                'CPEnrollmentService',
                'SessionService',
                'logger',
                'CurrentPatient',
                'ConsoloPatientSectionManagerService',
            ];
            return CareProgramsController;
        }());
        angular.module('consolo').controller('CareProgramsController', CareProgramsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var CreateReferralAccountForm = /** @class */ (function () {
        function CreateReferralAccountForm() {
            this.templateUrl = '/y/templates/patients/components/create-referral-account.html';
            this.controller = CreateReferralAccountController;
            this.require = { ngModelCtrl: '?ngModel' };
            this.bindings = {
                referralAccountType: '@',
            };
        }
        return CreateReferralAccountForm;
    }());
    var CreateReferralAccountController = /** @class */ (function () {
        function CreateReferralAccountController($scope, options, serviceService, officeService, referralContactService, $mdStepper) {
            var _this = this;
            this.$scope = $scope;
            this.options = options;
            this.serviceService = serviceService;
            this.officeService = officeService;
            this.referralContactService = referralContactService;
            this.$mdStepper = $mdStepper;
            this.referralAccount = {
                referral_representations: [],
            };
            //click coming from modal wrapper
            $scope.$on('referral-account.create', function () {
                _this.onCreateReferralAccount();
            });
        }
        CreateReferralAccountController.prototype.onCreateReferralAccount = function () {
            var _this = this;
            this.loading = true;
            this.errors = [];
            this.serviceService.create(this.$scope.$ctrl.referralAccountType, this.referralAccount).then(function (response) {
                _this.loading = false;
                _this.$scope.$emit('referral-account.created', response.data);
            }, function (response) {
                _this.loading = false;
                _this.errors = response.data.errors;
            });
        };
        CreateReferralAccountController.prototype.$onInit = function () {
            this.counties = this.options.get('counties');
            this.states = this.options.get('states');
            this.contactTypes = this.options.get('referral_contact_types');
            this.referralAccountTypes = this.options.get('referral_account_types');
            this.facilityTypes = this.options.get('facility_types');
            this.procedureCodeDescriptions = this.options.get('procedure_code_descriptions');
        };
        CreateReferralAccountController.prototype.createNewReferralContact = function () {
            this.referralAccount.referral_representations.push({});
        };
        CreateReferralAccountController.prototype.referralContactLookup = function (q) {
            return this.referralContactService
                .referralContactLookup(null, q)
                .catch(function (err) { return console.log(err); });
        };
        CreateReferralAccountController.$inject = [
            '$scope',
            'options',
            'serviceService',
            'officeService',
            'referralContactService',
            '$mdStepper',
        ];
        return CreateReferralAccountController;
    }());
    angular
        .module('consolo')
        .component('consoloCreateReferralAccount', new CreateReferralAccountForm());
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var ReferralAccount = consolo.domain.ReferralAccount;
    var DeaNumber = consolo.domain.DeaNumber;
    var PhysicianDocument = consolo.domain.PhysicianDocument;
    var CreateReferralContactForm = /** @class */ (function () {
        function CreateReferralContactForm() {
            this.templateUrl = '/y/templates/patients/components/create-referral-contact.html';
            this.controller = CreateReferralContactController;
            this.require = { ngModelCtrl: '?ngModel' };
            this.bindings = {
                referralContactType: '@',
            };
        }
        return CreateReferralContactForm;
    }());
    var CreateReferralContactController = /** @class */ (function () {
        function CreateReferralContactController($scope, options, Lookup, officeService, serviceService, nonPlayerCharactersService) {
            var _this = this;
            this.$scope = $scope;
            this.options = options;
            this.Lookup = Lookup;
            this.officeService = officeService;
            this.serviceService = serviceService;
            this.nonPlayerCharactersService = nonPlayerCharactersService;
            $scope.$on('referral-contact.create', function () {
                _this.onCreateReferralContact();
            });
        }
        CreateReferralContactController.prototype.$onInit = function () {
            var _this = this;
            this.contactTypes = this.options.get('referral_contact_types');
            this.referralTypes = this.options.get('referral_types');
            this.genders = this.options.get('genders');
            this.races = this.options.get('races');
            this.states = this.options.get('states');
            this.officeService
                .select()
                .then(function (response) {
                _this.offices = response.data.map(function (officeData) { return new consolo.domain.Office(officeData); });
            })
                .catch(function (err) { return console.log(err); });
            this.referralContact = this.nonPlayerCharactersService.newModel(this.getContactType());
            this.referralContact.referral_representations.push(new ReferralAccount({ service_id: this.parentReferralAccount().id }));
        };
        CreateReferralContactController.prototype.serviceLookup = function (query) {
            return this.serviceService
                .lookup(query)
                .then(function (response) {
                return response.data.map(function (serviceData) { return new consolo.domain.Service(serviceData); });
            })
                .catch(function (err) { return console.log(err); });
        };
        CreateReferralContactController.prototype.getContactType = function () {
            return this.$scope.$ctrl.referralContactType;
        };
        CreateReferralContactController.prototype.isRequired = function (field) {
            return this.nonPlayerCharactersService.isRequired(this.getContactType(), field);
        };
        CreateReferralContactController.prototype.createNewReferralAccount = function () {
            this.referralContact.referral_representations.push(new ReferralAccount({}));
        };
        CreateReferralContactController.prototype.createNewDeaNumber = function () {
            this.referralContact.dea_numbers.push(new DeaNumber());
        };
        CreateReferralContactController.prototype.createNewDocument = function () {
            this.referralContact.physician_documents.push(new PhysicianDocument());
        };
        CreateReferralContactController.prototype.handleFiles = function (files) {
            for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                var new_document = files_1[_i];
                var physician_document = new PhysicianDocument();
                physician_document.file = new_document;
                this.referralContact.physician_documents.push(physician_document);
            }
        };
        CreateReferralContactController.prototype.onCreateReferralContact = function () {
            var _this = this;
            var referralContactType = this.getContactType();
            console.log('creating referralContact: %O with type: %s', this.referralContact, referralContactType);
            this.loading = true;
            this.errors = [];
            this.nonPlayerCharactersService.create(referralContactType, this.referralContact).then(function (npc) {
                _this.loading = false;
                console.log('referral contact created: %O', npc);
                _this.$scope.$emit('referral-contact.created', npc);
            }, function (errorResponse) {
                _this.loading = false;
                console.log('error creating referral contact: %O', errorResponse);
                _this.errors = errorResponse.data.errors;
            });
        };
        CreateReferralContactController.prototype.initialReferralAccount = function (isFirst) {
            return isFirst ? this.parentReferralAccount() : null;
        };
        CreateReferralContactController.prototype.parentReferralAccount = function () {
            return this.$scope.$parent.referralAccount;
        };
        CreateReferralContactController.$inject = [
            '$scope',
            'options',
            'Lookup',
            'officeService',
            'serviceService',
            'nonPlayerCharactersService',
        ];
        return CreateReferralContactController;
    }());
    angular
        .module('consolo')
        .component('consoloCreateReferralContact', new CreateReferralContactForm());
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Indicator = consolo.domain.Indicator;
        var DiagnosesController = /** @class */ (function () {
            function DiagnosesController($q, options, $mdDialog, $state, patientService, IndicatorsService, logger, DiagnosesService, Session) {
                this.$q = $q;
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.patientService = patientService;
                this.IndicatorsService = IndicatorsService;
                this.logger = logger;
                this.DiagnosesService = DiagnosesService;
                this.Session = Session;
                this.isLoading = false;
                this.tempIcd10HealthConditions = [];
                this.indicatorIsNew = false;
                this.patientId = this.$state.params['patientId'] ? +this.$state.params['patientId'] : null;
                this.indicatorId = this.$state.params['indicatorId']
                    ? +this.$state.params['indicatorId']
                    : null;
                this.userId = this.Session.getUser().id;
                this.$onInit();
            }
            DiagnosesController.prototype.$onInit = function () {
                this.isLoading = true;
                this.initializePageData();
            };
            DiagnosesController.prototype.initializePageData = function () {
                var _this = this;
                var promiseChain = [
                    this.getPatient(),
                    this.getIndicator(this.patientId),
                    this.getIcdTabularSearch(),
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.isLoading = false;
                });
            };
            DiagnosesController.prototype.getPatient = function () {
                var _this = this;
                return this.patientService.get(this.patientId).then(function (patientObj) {
                    _this.patient = patientObj.data;
                });
            };
            DiagnosesController.prototype.getIndicator = function (patientId) {
                var _this = this;
                return this.IndicatorsService.getIndicators(patientId)
                    .then(function (response) {
                    _this.indicator = response.data[0];
                    if (_this.indicator === undefined) {
                        _this.indicator = new Indicator();
                        _this.indicator.user_id = _this.userId;
                        _this.indicator.patient_id = _this.patientId;
                        _this.indicatorIsNew = true;
                    }
                    else {
                        _this.indicatorId = response.data[0].id;
                        _this.tempIcd10HealthConditions = response.data[0].icd10_health_conditions.slice();
                    }
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Error:');
                });
            };
            DiagnosesController.prototype.getSingleIndicator = function (indicatorId, patientId) {
                var _this = this;
                return this.IndicatorsService.getSingleIndicator(indicatorId, patientId)
                    .then(function (response) {
                    _this.indicator = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Error:');
                });
            };
            DiagnosesController.prototype.updateIndicator = function () {
                var _this = this;
                this.isLoading = true;
                this.DiagnosesService.setDiagnosesForUpdate(this.indicator, this.tempIcd10HealthConditions).then(function () {
                    _this.IndicatorsService.updateIndicator(_this.indicatorId, _this.patientId, _this.indicator)
                        .then(function () {
                        _this.logger.success('Indicator was successfully updated.', 'Success');
                        _this.getSingleIndicator(_this.indicatorId, _this.patientId).finally(function () {
                            _this.isLoading = false;
                            _this.formatIndicator();
                        });
                    })
                        .catch(function (error) {
                        _this.logger.error(error.data.errors[0], 'Error Updating Indicator');
                        _this.isLoading = false;
                    });
                });
            };
            DiagnosesController.prototype.getIcdTabularSearch = function () {
                var _this = this;
                return this.IndicatorsService.getIcdTabularSearch()
                    .then(function (response) {
                    _this.icdTabularSearchTree = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Tabular Search Error:');
                });
            };
            DiagnosesController.prototype.showDiagnosisSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    locals: {
                        icdTabularSearchTree: this.icdTabularSearchTree,
                        indicator: this.indicator,
                    },
                })
                    .then(function (selectedIcd10) {
                    _this.DiagnosesService.checkAndAddValidIcd(selectedIcd10, _this.indicator, _this.tempIcd10HealthConditions);
                });
            };
            DiagnosesController.prototype.rankDiagnoses = function (diagnosisIndex, direction) {
                this.DiagnosesService.rankDiagnoses(diagnosisIndex, direction, this.indicator, this.tempIcd10HealthConditions);
            };
            DiagnosesController.prototype.deleteDiagnosis = function (icd10, index) {
                this.DiagnosesService.deleteDiagnosis(icd10, index, this.indicator, this.tempIcd10HealthConditions).then(function (resolve, reject) { });
            };
            DiagnosesController.prototype.formatIndicator = function () {
                this.tempIcd10HealthConditions = this.indicator.icd10_health_conditions.slice();
            };
            DiagnosesController.prototype.addIndicator = function () {
                var _this = this;
                this.isLoading = true;
                var newIndicator = new Indicator(this.indicator);
                this.IndicatorsService.addIndicator(this.patientId, newIndicator)
                    .then(function (response) {
                    var newIndicator = response.data;
                    _this.indicator = newIndicator;
                    _this.indicatorId = _this.indicator.id;
                    _this.formatIndicator();
                    _this.logger.success('Indicator was successfully updated.', 'Success');
                    _this.isLoading = false;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Error Adding Indicator:');
                });
            };
            DiagnosesController.$inject = [
                '$q',
                'options',
                '$mdDialog',
                '$state',
                'patientService',
                'IndicatorsService',
                'logger',
                'DiagnosesService',
                'SessionService',
            ];
            return DiagnosesController;
        }());
        angular.module('consolo').controller('DiagnosesController', DiagnosesController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var IcdHealthCondition = consolo.domain.Icd10HealthCondition;
        var DiagnosesService = /** @class */ (function () {
            function DiagnosesService(logger, $mdDialog, $q, Session, $http) {
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.Session = Session;
                this.$http = $http;
                this.serviceLine = this.Session.get().agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
            }
            DiagnosesService.prototype.addIcd10Code = function (selectedIcd10, indicator, tempIcd10HealthConditions) {
                var icd10 = {
                    id: selectedIcd10.id ? selectedIcd10.id : null,
                    icd10_id: selectedIcd10.icd10_id ? selectedIcd10.icd10_id : null,
                    position: indicator.icd10_health_conditions.length,
                    code: selectedIcd10.code,
                    diagnosis_id: selectedIcd10.diagnosis_id ? selectedIcd10.diagnosis_id : null,
                };
                indicator.icd10_health_conditions.push(icd10);
                indicator.icd10s.push(icd10);
                tempIcd10HealthConditions.push(selectedIcd10);
            };
            DiagnosesService.prototype.setDiagnosesForUpdate = function (indicator, tempIcd10HealthConditions) {
                // Updates the icd10_health_conditions array everytime a change is made to Diagnoses list
                // This map function ensures that the icd10_health_conditions array is formatted for PATCH call on Indicator
                return this.$q.when((indicator.icd10_health_conditions = tempIcd10HealthConditions
                    .map(function (icd10, index) {
                    var icd10_id = icd10.icd10_id ? icd10.icd10_id : null;
                    if (icd10.diagnosis_id !== 'temp') {
                        var id = indicator.icd10_health_conditions[index].id
                            ? indicator.icd10_health_conditions[index].id
                            : null;
                        if (!icd10.icd10_id) {
                            icd10_id = indicator.icd10s[index].id ? indicator.icd10s[index].id : null;
                        }
                        return new IcdHealthCondition(id, icd10_id, index, icd10.diagnosis_id);
                    }
                })
                    .filter(function (icd10) {
                    return icd10 !== undefined;
                })));
            };
            DiagnosesService.prototype.rankDiagnoses = function (diagnosisIndex, direction, indicator, tempIcd10HealthConditions) {
                var firstIcd10Prefix = indicator.icd10s[diagnosisIndex].code.split('')[0].toUpperCase();
                var secondIcd10Prefix = indicator.icd10s[1].code.split('')[0].toUpperCase();
                // Check to see if code is prefixed with V W X Y Z, and prevent adding as primary if it is
                if (direction === 'up' &&
                    diagnosisIndex - 1 === 0 &&
                    !this.validPrimaryIcd10(firstIcd10Prefix)) {
                    this.logger.error('Codes prefixed with ' + firstIcd10Prefix + ' cannot be set to primary', 'Error: ');
                }
                else if (direction === 'down' &&
                    diagnosisIndex === 0 &&
                    !this.validPrimaryIcd10(secondIcd10Prefix)) {
                    this.logger.error('Codes prefixed with ' + secondIcd10Prefix + ' cannot be set to primary', 'Error: ');
                }
                else {
                    // Move icd10 object for both temp array and icd10s array so everything is in sync
                    this.moveObjectInArray(tempIcd10HealthConditions, diagnosisIndex, direction);
                    this.moveObjectInArray(indicator.icd10s, diagnosisIndex, direction);
                }
            };
            DiagnosesService.prototype.deleteDiagnosis = function (icd10, index, indicator, tempIcd10HealthConditions) {
                var _this = this;
                return this.$mdDialog
                    .show(this.generateConfirmationRequest('Are you sure you want to delete ' + icd10.code + ': ' + icd10.description + ' ?'))
                    .then(function () {
                    var mainListLength = indicator.icd10_health_conditions.length;
                    var secondIcd10Prefix = mainListLength > 1 ? indicator.icd10s[1].code.split('')[0].toUpperCase() : '';
                    if (index === 0 && !_this.validPrimaryIcd10(secondIcd10Prefix)) {
                        _this.logger.error('Codes prefixed with ' + secondIcd10Prefix + ' cannot be set to primary', 'Error: ');
                    }
                    else {
                        tempIcd10HealthConditions.splice(index, 1);
                        indicator.icd10s.splice(index, 1);
                        indicator.icd10_health_conditions.splice(index, 1);
                        if (icd10.diagnosis_id) {
                            var tempDiagnosesList = indicator.diagnoses.slice();
                            var diagnosisIndexPosition = tempDiagnosesList.findIndex(function (diagnosis) {
                                return diagnosis.id === icd10.diagnosis_id;
                            });
                            if (diagnosisIndexPosition !== -1) {
                                var icd10IndexPosition = tempDiagnosesList[diagnosisIndexPosition].icd10_health_conditions.findIndex(function (diagnosisIcd10) {
                                    return diagnosisIcd10.code === icd10.code;
                                });
                                tempDiagnosesList[diagnosisIndexPosition].icd10_health_conditions.splice(icd10IndexPosition, 1);
                            }
                            indicator.diagnoses = tempDiagnosesList;
                        }
                        return _this.setDiagnosesForUpdate(indicator, tempIcd10HealthConditions);
                    }
                });
            };
            DiagnosesService.prototype.moveObjectInArray = function (array, fromIndex, direction) {
                // toIndex determines where the icd10 object is moving based on whether up or down arrows are clicked
                var toIndex = direction === 'down' ? fromIndex + 1 : fromIndex - 1;
                var element = array[fromIndex];
                array.splice(fromIndex, 1);
                array.splice(toIndex, 0, element);
            };
            DiagnosesService.prototype.validPrimaryIcd10 = function (icd10Prefix) {
                if (!this.isPalliative) {
                    switch (icd10Prefix) {
                        case 'V': {
                            return false;
                        }
                        case 'W': {
                            return false;
                        }
                        case 'X': {
                            return false;
                        }
                        case 'Y': {
                            return false;
                        }
                        case 'Z': {
                            return false;
                        }
                        default: {
                            return true;
                        }
                    }
                }
                else {
                    return true;
                }
            };
            DiagnosesService.prototype.diagnosisLabel = function (index) {
                var label = '';
                switch (index) {
                    case 0:
                        label = 'Primary';
                        break;
                    case 1:
                        label = 'Secondary';
                        break;
                    case 2:
                        label = 'Tertiary';
                        break;
                    default:
                        label = index + 1 + 'th';
                        break;
                }
                return label;
            };
            DiagnosesService.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            DiagnosesService.prototype.checkAndAddValidIcd = function (selectedIcd10, indicator, tempIcd10HealthConditions) {
                console.log(indicator);
                var icd10Prefix = selectedIcd10.code.split('')[0].toUpperCase();
                if (indicator.icd10s.length > 0) {
                    if (!indicator.icd10s.some(function (icd10) { return icd10.code === selectedIcd10.code; })) {
                        // If new selected ICD10 isn't a duplicate, add it to the list
                        this.addIcd10Code(selectedIcd10, indicator, tempIcd10HealthConditions);
                    }
                    else {
                        // If new selected ICD10 is a duplicate throw error toast
                        this.logger.error('You may not have duplicated ICD-10 codes', 'ICD Already Exists');
                    }
                }
                if (indicator.icd10s.length === 0 && this.validPrimaryIcd10(icd10Prefix)) {
                    // If icd10 list is empty, and isn't prefixed with V W X Y Z then save
                    this.addIcd10Code(selectedIcd10, indicator, tempIcd10HealthConditions);
                }
                else if (indicator.icd10s.length === 0 && this.validPrimaryIcd10(selectedIcd10)) {
                    this.logger.error('Codes prefixed with ' + icd10Prefix + ' cannot be set to primary', 'Error: ');
                }
            };
            DiagnosesService.prototype.getPatientDiagnoses = function (patientId) {
                //return this.$http.get('/api/v1/icd10_health_conditions/?patient_id=' + patientId);
                return this.$http.get('/api/v1/indicators/current?patient_id=' + patientId).then(function (response) {
                    return response.data.icd10_health_conditions;
                });
            };
            DiagnosesService.prototype.addDiagnosis = function (patientId, diagnosisObj) {
                return this.$http.post('/api/v1/icd10_health_conditions/?patient_id=' + patientId, diagnosisObj);
            };
            DiagnosesService.$inject = ['logger', '$mdDialog', '$q', 'SessionService', '$http'];
            return DiagnosesService;
        }());
        services.DiagnosesService = DiagnosesService;
        angular.module('consolo').service('DiagnosesService', DiagnosesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DiagnosticImageResultDialogController = /** @class */ (function () {
            function DiagnosticImageResultDialogController($mdDialog, DiagnosticImageResultService, logger, $q) {
                this.$mdDialog = $mdDialog;
                this.DiagnosticImageResultService = DiagnosticImageResultService;
                this.logger = logger;
                this.$q = $q;
            }
            DiagnosticImageResultDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.$mdDialog.hide(this.result);
            };
            DiagnosticImageResultDialogController.$inject = ['$mdDialog', 'DiagnosticImageResultService', 'logger', '$q'];
            return DiagnosticImageResultDialogController;
        }());
        angular.module('consolo').controller('DiagnosticImageResultDialogController', DiagnosticImageResultDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DiagnosticImageResultsController = /** @class */ (function () {
            function DiagnosticImageResultsController($state, $location, $mdDialog, $q, Session, logger, CurrentPatient, SectionManagerService, DiagnosticImageResultService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.Session = Session;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.SectionManagerService = SectionManagerService;
                this.DiagnosticImageResultService = DiagnosticImageResultService;
                this.results = [];
                this.patientId = null;
                this.isLoading = false;
                this.initializeData();
            }
            DiagnosticImageResultsController.prototype.initializeData = function () {
                var _this = this;
                this.isLoading = false;
                this.filters = new consolo.domain.DiagnosticImageResultFilter();
                this.isLoading = true;
                this.includeInactive = false;
                this.filters.patient_id = this.CurrentPatient.patient_id;
                this.currentUser = this.Session.getUser();
                var promiseChain = [
                    this.getDiagnosticImageResults(),
                    this.SectionManagerService.loadPermissions(),
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.canShow = _this.SectionManagerService.permissions.diagnostic_image_results_show;
                    _this.canCreate = _this.SectionManagerService.permissions.diagnostic_image_results_create;
                    _this.canUpdate = _this.SectionManagerService.permissions.diagnostic_image_results_update;
                    _this.canDestroy = _this.SectionManagerService.permissions.diagnostic_image_results_destroy;
                    if (_this.$state.current.url === '/new' && _this.canCreate) {
                        _this.diagnosticImageResultDialog(null, 'add', null);
                    }
                    _this.isLoading = false;
                });
            };
            DiagnosticImageResultsController.prototype.getDiagnosticImageResults = function () {
                var _this = this;
                return this.DiagnosticImageResultService.lookupDiagnosticImageResults(this.filters).then(function (response) {
                    _this.results = response.data;
                    _this.initializeResultTable();
                }).catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    console.log(error);
                    _this.initializeResultTable();
                });
            };
            DiagnosticImageResultsController.prototype.diagnosticImageResultDialog = function (result, action, $event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    clickOutsideToClose: true,
                    templateUrl: '/y/templates/patients/diagnostic-image-results/diagnostic-image-result-dialog.html',
                    controller: 'DiagnosticImageResultDialogController',
                    controllerAs: 'vm',
                    bindToController: true,
                    locals: {
                        result: result,
                        action: action,
                        currentUser: this.currentUser,
                    },
                })
                    .then(function (result) {
                    if (result) {
                        _this.isLoading = true;
                        result.patient_id = _this.CurrentPatient.patient_id;
                        var successAction_1 = action === 'add' ? 'added' : 'updated';
                        var apiCall = action === 'add'
                            ? _this.DiagnosticImageResultService.createDiagnosticImageResult(result)
                            : _this.DiagnosticImageResultService.updateDiagnosticImageResult(result);
                        apiCall
                            .then(function (response) {
                            var result = response.data;
                            _this.logger.success('You have successfully ' + successAction_1 + ' result: ' + result.examination, 'Success:');
                            _this.getDiagnosticImageResults();
                        })
                            .catch(function (error) {
                            _this.logger.error(error.data.errors.join(', '), 'Error:');
                            _this.isLoading = false;
                        });
                    }
                });
            };
            DiagnosticImageResultsController.prototype.initializeResultTable = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.resultLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (data) {
                                    _this.diagnosticImageResultDialog(data, 'view');
                                },
                                conditionalHideFn: function () {
                                    return !_this.canShow;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data) {
                                    _this.diagnosticImageResultDialog(data, 'edit');
                                },
                                conditionalHideFn: function () {
                                    return !_this.canUpdate;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete result: ' + data.examination + '?'))
                                        .then(function () {
                                        _this.DiagnosticImageResultService.deleteDiagnosticImageResult(data).then(function (response) {
                                            _this.getDiagnosticImageResults();
                                            _this.logger.success('You have successfully removed result: ' + data.examination, 'Success:');
                                        }).catch(function (error) {
                                            _this.logger.error(error.data.errors.join(', '));
                                            console.log(error);
                                        });
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.canDestroy;
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Exam Date',
                            field: 'examination_date',
                            type: csg.datatable.ColumnTypes.date,
                            canSort: true,
                        },
                        {
                            title: 'Examination',
                            field: 'examination',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (item) {
                                return (item.examination || '') + (item.examination_text ? ' - ' + item.examination_text : '');
                            },
                            canSort: true,
                        },
                        {
                            title: 'Technique',
                            field: 'technique',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                        {
                            title: 'Indication',
                            field: 'indication',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                        {
                            title: 'Comparison',
                            field: 'comparison',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                        {
                            title: 'Encounter',
                            field: 'encounter',
                            type: csg.datatable.ColumnTypes.short_text,
                            canSort: true,
                        },
                    ],
                };
                this.isLoading = false;
            };
            DiagnosticImageResultsController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                '$q',
                'SessionService',
                'logger',
                'CurrentPatient',
                'ConsoloPatientSectionManagerService',
                'DiagnosticImageResultService'
            ];
            return DiagnosticImageResultsController;
        }());
        angular.module('consolo').controller('DiagnosticImageResultsController', DiagnosticImageResultsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var EdmontonAssessmentsDataTableService = /** @class */ (function (_super) {
            __extends(EdmontonAssessmentsDataTableService, _super);
            function EdmontonAssessmentsDataTableService($httpService, $interval, $state, CurrentPatient, logger) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.CurrentPatient = CurrentPatient;
                _this.logger = logger;
                _this.loadingData = false;
                _this.showFilters = false;
                _this.hideSearchTerm = true;
                _this.paginatorPage = { page: 0, totalPages: 0, items: [] };
                _this.activate();
                return _this;
            }
            EdmontonAssessmentsDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/edmonton_assessments';
                this.filters = new consolo.domain.EdmontonAssessmentsFilter();
                this.tableLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [
                            { menuItemType: csg.datatable.MenuItemType.csvHeader },
                            { menuItemType: csg.datatable.MenuItemType.csvAll },
                            { menuItemType: csg.datatable.MenuItemType.copyHeader },
                            { menuItemType: csg.datatable.MenuItemType.copyAll },
                        ],
                    },
                    options: {
                        borderStyle: 3,
                        hideTextFilter: true,
                        defaultPageSize: 100,
                    },
                    columns: [
                        {
                            title: 'Effective Date',
                            field: 'assessment_date',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.clinical-chart-view({ patient_id: $patient_id, id: $clinical_chart_id })',
                            cssClass: 'no-wrap',
                            linkOpenNewWindow: true,
                        },
                        {
                            title: 'Completed By',
                            field: 'completed_by',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data) {
                                if (data.completed_by_name) {
                                    return data.completed_by_name + "<br/>(" + data.completed_by + ")";
                                }
                                else {
                                    return data.completed_by;
                                }
                            },
                        },
                        {
                            title: 'Pain',
                            field: 'pain',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Tiredness',
                            field: 'tiredness',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Drowsiness',
                            field: 'drowsiness',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Nausea',
                            field: 'nausea',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Lack of Appetite',
                            field: 'lack_of_appetite',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Shortness of Breath',
                            field: 'shortness_of_breath',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Depression',
                            field: 'depression',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Anxiety',
                            field: 'anxiety',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Wellbeing',
                            field: 'wellbeing',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Constipation',
                            field: 'constipation',
                            headerCssClass: 'diagonalHeader',
                            cellCssClass: 'centerValue',
                            formatValueFn: this.formatValue,
                        },
                        {
                            title: 'Created Date',
                            field: 'created_at',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Created By',
                            field: 'user_name',
                        },
                    ],
                };
            };
            EdmontonAssessmentsDataTableService.prototype.formatValue = function (data, col, value) {
                var field = col.field;
                if (data[field + '_unable']) {
                    return 'Unable to assess';
                }
                else if (data[field] === null || data[field] === undefined) {
                    return '~';
                }
                else {
                    return data[field].toString();
                }
            };
            EdmontonAssessmentsDataTableService.prototype.getPage = function (page) {
                console.log(page);
                this.params.page = page;
                this.search();
            };
            EdmontonAssessmentsDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = this.filters.getFilters();
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                            _this.data.sort(function (a, b) {
                                if (a.effective_date < b.effective_date) {
                                    return -1;
                                }
                                else if (a.effective_date > b.effective_date) {
                                    return 1;
                                }
                                else {
                                    return 0;
                                }
                            });
                            for (var i = _this.data.length; i < 6; i++) {
                                _this.data.push({});
                            }
                            _this.paginatorPage = {
                                page: _this.currentPage,
                                totalPages: _this.totalPages,
                                items: _this.data,
                            };
                            console.log('paginatorPage', _this.paginatorPage);
                        });
                    }
                }, 500, 20);
            };
            EdmontonAssessmentsDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.EdmontonAssessmentsFilter();
                this.filters.patient_id = this.CurrentPatient.patient_id;
                this.search();
            };
            EdmontonAssessmentsDataTableService.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            EdmontonAssessmentsDataTableService.$inject = ['$http', '$interval', '$state', 'CurrentPatient', 'logger'];
            return EdmontonAssessmentsDataTableService;
        }(csg.datatable.ApiService));
        services.EdmontonAssessmentsDataTableService = EdmontonAssessmentsDataTableService;
        angular
            .module('consolo')
            .service('EdmontonAssessmentsDataTableService', EdmontonAssessmentsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var EdmontonAssessmentsController = /** @class */ (function () {
            function EdmontonAssessmentsController(EdmontonAssessmentsDataTableService, $window, $scope, currentPatient, SidebarService) {
                this.EdmontonAssessmentsDataTableService = EdmontonAssessmentsDataTableService;
                this.$window = $window;
                this.$scope = $scope;
                this.currentPatient = currentPatient;
                this.SidebarService = SidebarService;
                this.wideTableScroll = {};
                this.onResize();
                this.$window.addEventListener('resize', this.onResize);
                this.EdmontonAssessmentsDataTableService.reset();
                this.EdmontonAssessmentsDataTableService.showFilters = false;
                this.SidebarService.open();
            }
            EdmontonAssessmentsController.prototype.onResize = function () {
                if (this.$window) {
                    this.wideTableScroll.height = this.$window.innerHeight - 200 + 'px';
                }
                if (this.$scope && this.$scope.$root && !this.$scope.$root.$$phase) {
                    this.$scope.$digest();
                }
            };
            EdmontonAssessmentsController.$inject = [
                'EdmontonAssessmentsDataTableService',
                '$window',
                '$scope',
                'currentPatient',
                'SidebarService',
            ];
            return EdmontonAssessmentsController;
        }());
        angular
            .module('consolo')
            .controller('EdmontonAssessmentsController', EdmontonAssessmentsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CompleteElectronicDocumentDialogController = /** @class */ (function () {
            function CompleteElectronicDocumentDialogController($mdDialog, $window, logger, docusignService) {
                this.$mdDialog = $mdDialog;
                this.$window = $window;
                this.logger = logger;
                this.docusignService = docusignService;
            }
            CompleteElectronicDocumentDialogController.prototype.closeDialog = function (send) {
                this.$mdDialog.hide(send);
            };
            CompleteElectronicDocumentDialogController.$inject = ['$mdDialog', '$window', 'logger', 'docusignService'];
            return CompleteElectronicDocumentDialogController;
        }());
        angular
            .module('consolo')
            .controller('CompleteElectronicDocumentDialogController', CompleteElectronicDocumentDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('electronicDocumentSigner', {
            bindings: {
                signer: '<',
                patient: '<',
            },
            templateUrl: '/y/templates/patients/electronic-documents/electronic-document-signer.html',
            controller: 'ElectronicDocumentSignerController as $ctrl',
        });
        var ElectronicDocumentSignerController = /** @class */ (function () {
            function ElectronicDocumentSignerController() {
                this.patientRole = this.signer.role === 'Patient' ? true : false;
                this.patientNameReadonly = this.signer.role === 'Patient' ? true : false;
                if (this.patientRole) {
                    this.signer.name = this.patient.first_name + ' ' + this.patient.last_name;
                }
            }
            ElectronicDocumentSignerController.prototype.$onInit = function () { };
            ElectronicDocumentSignerController.$inject = [];
            return ElectronicDocumentSignerController;
        }());
        angular
            .module('consolo')
            .controller('ElectronicDocumentSignerController', ElectronicDocumentSignerController);
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DocusignFilters = consolo.domain.DocusignFilters;
        var DocusignTemplateOptions = consolo.domain.DocusignTemplateOptions;
        var QueryStringFilter = consolo.domain.QueryStringFilter;
        var ElectronicDocumentTemplateDialogController = /** @class */ (function (_super) {
            __extends(ElectronicDocumentTemplateDialogController, _super);
            function ElectronicDocumentTemplateDialogController($state, $location, $timeout, docusignService, docusignTemplateDataTableService, options, $mdDialog, $interval, permissionsService, patientService, logger, $q) {
                var _this = _super.call(this) || this;
                _this.$state = $state;
                _this.$location = $location;
                _this.$timeout = $timeout;
                _this.docusignService = docusignService;
                _this.docusignTemplateDataTableService = docusignTemplateDataTableService;
                _this.options = options;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.permissionsService = permissionsService;
                _this.patientService = patientService;
                _this.logger = logger;
                _this.$q = $q;
                _this.permissions = {};
                _this.selectedTemplates = [];
                _this.patientId = _this.$state.params.patientId;
                _this.loadPermissions();
                _this.initializeDocusignGrid();
                if (_this.patientId) {
                    _this.query();
                }
                if (_this.patientId) {
                    patientService.get(_this.patientId).then(function (response) {
                        _this.patient = response.data;
                    });
                }
                return _this;
            }
            ElectronicDocumentTemplateDialogController.prototype.applyInitialFilters = function () {
                _super.prototype.applyInitialFilters.call(this);
            };
            ElectronicDocumentTemplateDialogController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.filters = new DocusignFilters(this.patientId);
                this.docusignTemplateDataTableService.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    if (_this.docusignTemplateDataTableService.tableControl &&
                        _this.docusignTemplateDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.docusignTemplateDataTableService.tableControl.loadSelectData().finally(function () {
                            console.log(_this.docusignTemplateDataTableService.data);
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            ElectronicDocumentTemplateDialogController.prototype.reset = function () {
                this.docusignTemplateDataTableService.params = this.filters;
                this.query();
            };
            ElectronicDocumentTemplateDialogController.prototype.closeDialog = function (send) {
                this.$mdDialog.hide(send);
            };
            ElectronicDocumentTemplateDialogController.prototype.loadPermissions = function () {
                var permission_chain = [];
                var defer = this.$q.defer();
                this.permissions.templateShow = this.permissionsService.can('show', 'electronic_document_templates');
                this.permissions.envelopeShow = this.permissionsService.can('show', 'electronic_documents');
                this.permissions.envelopeCreate = this.permissionsService.can('create', 'electronic_documents');
                this.permissions.envelopeDestroy = this.permissionsService.can('destroy', 'electronic_documents');
                this.$q.all(permission_chain).finally(function () {
                    defer.resolve(true);
                });
                return defer.promise;
            };
            ElectronicDocumentTemplateDialogController.prototype.createDocuments = function () {
                var _this = this;
                this.isLoading = true;
                this.logger.success('Creating Electronic Documents');
                var updateArray = [];
                this.selectedTemplates.forEach(function (selectedTemplate) {
                    console.log('Selected Template ' + selectedTemplate);
                    var templatePromise = _this.$q.defer();
                    updateArray.push(templatePromise.promise);
                    _this.createEnvelope(selectedTemplate, templatePromise);
                });
                this.$q.all(updateArray).then(function () {
                    _this.$mdDialog.hide(updateArray.length);
                    _this.isLoading = false;
                });
            };
            // showCompleteElectronicDocumentDialog($event: any): void {
            ElectronicDocumentTemplateDialogController.prototype.showCompleteElectronicDocumentDialog = function (template, templatePromise) {
                var _this = this;
                // Replace with constant email
                template.signers.forEach(function (signer) {
                    signer.email = 'jgrace@consoloservices.com';
                });
                var options = {
                    templateUrl: '/y/templates/patients/electronic-documents/complete-electronic-document-dialog.html',
                    controller: 'CompleteElectronicDocumentDialogController as vm',
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                    bindToController: true,
                    locals: {
                        patient: this.patient,
                        template: template,
                    },
                };
                this.$mdDialog.show(options).then(function (result) {
                    if (result) {
                        _this.createEnvelope(template, templatePromise);
                    }
                    else {
                        templatePromise.resolve();
                    }
                });
            };
            ElectronicDocumentTemplateDialogController.prototype.createEnvelope = function (template, templatePromise) {
                var _this = this;
                var templateOptions = new DocusignTemplateOptions(this.patientId, [template.id]);
                this.docusignService
                    .create_envelope(templateOptions)
                    .then(function () {
                    // Create the signers from the templates
                    _this.logger.success("Electronic Document " + template.description + " created successfully.");
                    templatePromise.resolve();
                    // this.$mdDialog.hide(ids.length);
                })
                    .catch(function () {
                    _this.logger.error("Unable to create Electronic Document " + template.description + ".");
                    templatePromise.resolve();
                });
            };
            ElectronicDocumentTemplateDialogController.prototype.initializeDocusignGrid = function () {
                this.docusignTemplateLayout = {
                    id_field: 'id',
                    options: {
                        allowRowSelect: true,
                        hideFooter: true,
                    },
                    columns: [{ title: 'Template', field: 'description', canSort: false }],
                };
            };
            ElectronicDocumentTemplateDialogController.$inject = [
                '$state',
                '$location',
                '$timeout',
                'docusignService',
                'docusignTemplateDataTableService',
                'options',
                '$mdDialog',
                '$interval',
                'PermissionsService',
                'patientService',
                'logger',
                '$q',
            ];
            return ElectronicDocumentTemplateDialogController;
        }(QueryStringFilter));
        angular
            .module('consolo')
            .controller('ElectronicDocumentTemplateDialogController', ElectronicDocumentTemplateDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DocusignEnvelope = consolo.domain.DocusignEnvelope;
        var DocusignFilters = consolo.domain.DocusignFilters;
        var QueryStringFilter = consolo.domain.QueryStringFilter;
        var ElectronicDocumentsController = /** @class */ (function (_super) {
            __extends(ElectronicDocumentsController, _super);
            function ElectronicDocumentsController($state, $window, $timeout, docusignService, docusignEnvelopeDataTableService, options, $mdDialog, $interval, permissionsService, patientService, SidebarService, Session, AboutService, logger, $q, $sce, tokenService) {
                var _this = _super.call(this) || this;
                _this.$state = $state;
                _this.$window = $window;
                _this.$timeout = $timeout;
                _this.docusignService = docusignService;
                _this.docusignEnvelopeDataTableService = docusignEnvelopeDataTableService;
                _this.options = options;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.permissionsService = permissionsService;
                _this.patientService = patientService;
                _this.SidebarService = SidebarService;
                _this.AboutService = AboutService;
                _this.logger = logger;
                _this.$q = $q;
                _this.$sce = $sce;
                _this.tokenService = tokenService;
                _this.permissions = {};
                _this.generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                _this.patientId = _this.$state.params.patientId;
                _this.event = _this.$state.params.event;
                _this.agency = Session.get().agency;
                _this.loadPermissions();
                _this.initializeDocusignGrid();
                _this.initializeOptions();
                _this.isSigning = false;
                if (_this.patientId) {
                    _this.query();
                }
                if (_this.patientId) {
                    patientService.get(_this.patientId).then(function (response) {
                        _this.patient = response.data;
                    });
                }
                angular.element(document.getElementById('signing_iframe')).on('load', function () {
                    var elem = document.getElementById('signing_iframe');
                    var url = elem.contentWindow.location.href;
                    if (/event=/.test(url)) {
                        _this.isSigning = false;
                        _this.SidebarService.open();
                        _this.electronicDocumentSigningUrl = '';
                    }
                });
                AboutService.citus_web_url().then(function (resp) {
                    _this.citusWebUrl = resp;
                });
                _this.updateTemplateApiInterval = _this.$interval(function () {
                    _this.$interval.cancel(_this.updateTemplateApiInterval);
                    _this.docusignService.refresh_templates(_this.patientId).finally(function () { });
                }, 500, 20);
                return _this;
            }
            ElectronicDocumentsController.prototype.applyInitialFilters = function () {
                _super.prototype.applyInitialFilters.call(this);
            };
            ElectronicDocumentsController.prototype.signElectronicDocumentDialog = function (envelope) {
                var _this = this;
                this.$mdDialog
                    .show({
                    // targetEvent: $event,
                    templateUrl: '/y/templates/patients/electronic-documents/sign-electronic-document-dialog.html',
                    controller: 'SignElectronicDocumentDialogController',
                    controllerAs: '$ctrl',
                    bindToController: true,
                    locals: {
                        patient: this.patient,
                        envelope: envelope,
                    },
                })
                    .then(function (data) {
                    if (data) {
                        _this.electronicDocumentSigningUrl = _this.$sce.trustAsResourceUrl(data.data);
                        _this.SidebarService.close();
                        _this.isSigning = true;
                    }
                })
                    .catch(function () {
                    _this.logger.error('Unable to Open Electronic Document for signing.');
                });
            };
            ElectronicDocumentsController.prototype.showElectronicDocumentTemplateDialog = function ($event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/electronic-documents/electronic-document-template-dialog.html',
                    controller: 'ElectronicDocumentTemplateDialogController as vm',
                    bindToController: true,
                    locals: {
                        patient: this.patient,
                    },
                })
                    .then(function (result) {
                    if (result) {
                        _this.query();
                    }
                });
            };
            ElectronicDocumentsController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.filters = new DocusignFilters(this.patientId);
                this.filters.sort_by = 'last_modified_date_time';
                this.filters.sort_dir = csg.datatable.ISort.desc;
                this.docusignEnvelopeDataTableService.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    if (_this.docusignEnvelopeDataTableService.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.docusignEnvelopeDataTableService.loadEnvelopes().finally(function () {
                            console.log(_this.docusignEnvelopeDataTableService.data);
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            ElectronicDocumentsController.prototype.reset = function () {
                this.docusignEnvelopeDataTableService.params = this.filters;
                this.query();
            };
            ElectronicDocumentsController.prototype.loadPermissions = function () {
                var permission_chain = [];
                var defer = this.$q.defer();
                this.permissions.templateShow = this.permissionsService.can('show', 'electronic_document_templates');
                this.permissions.envelopeShow = this.permissionsService.can('show', 'electronic_documents');
                this.permissions.envelopeCreate = this.permissionsService.can('create', 'electronic_documents');
                this.permissions.envelopeDestroy = this.permissionsService.can('destroy', 'electronic_documents');
                this.$q.all(permission_chain).finally(function () {
                    defer.resolve(true);
                });
                return defer.promise;
            };
            ElectronicDocumentsController.prototype.initializeOptions = function () { };
            ElectronicDocumentsController.prototype.openFile = function (doc) {
                var _this = this;
                this.tokenService.getToken().then(function (tok) {
                    window.open(_this.newLink(doc, tok), '_blank');
                });
            };
            ElectronicDocumentsController.prototype.newLink = function (data, token) {
                return "/api/v1/patient_documents/" + data.patient_document_id + "/download?access_token=" + token;
            };
            ElectronicDocumentsController.prototype.initializeDocusignGrid = function () {
                var _this = this;
                this.docusignEnvelopeLayout = {
                    id_field: 'envelope_id',
                    options: {
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'description',
                                callbackFn: function (data, item) {
                                    _this.openFile(data);
                                    // window.open(
                                    //   `/api/v1/patient_documents/${data.patient_document_id}/download`,
                                    //   '_blank'
                                    // );
                                    return true;
                                },
                                conditionalHideFn: function (data) {
                                    return !_this.permissions.envelopeShow || data.status.toLowerCase() !== 'completed';
                                },
                            },
                            {
                                label: 'Sign',
                                icon: 'create',
                                callbackFn: function (envelope) {
                                    _this.docusignService
                                        .postPatientDemographics(_this.patientId)
                                        .then(function (response) {
                                        window.open(_this.citusWebUrl +
                                            '?clientId=' +
                                            _this.agency.uuid +
                                            '&patientMRN=' +
                                            _this.patient.uuid +
                                            (envelope.document_type === 'documentCategories'
                                                ? '&documentCategoryId=' +
                                                    envelope.electronic_document_template.template_id +
                                                    '&type=' +
                                                    envelope.document_type
                                                : '') +
                                            (envelope.document_type === 'forms'
                                                ? '&formId=' +
                                                    envelope.electronic_document_template.template_id +
                                                    '&type=Forms'
                                                : '') +
                                            '&externalDocumentId=' +
                                            envelope.id, '_blank');
                                        return true;
                                    })
                                        .catch(function (errors) {
                                        _this.logger.error(errors.data.errors.join(','));
                                        return false;
                                    });
                                },
                                conditionalHideFn: function (data) {
                                    return (!_this.permissions.envelopeShow ||
                                        !['ready to sign', 'pending', 'delivered'].some(function (status) {
                                            return data.status.toLowerCase().includes(status);
                                        }));
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(_this.generateConfirmationRequest('Are you sure you want to delete this document?'))
                                        .then(function () {
                                        _this.docusignEnvelope = new DocusignEnvelope(_this.patientId, data.envelope_id);
                                        _this.docusignService
                                            .delete_envelope(_this.docusignEnvelope)
                                            .then(function () {
                                            console.log(data);
                                            _this.docusignEnvelopeDataTableService.loadEnvelopes();
                                        })
                                            .catch(function (err) { return console.log(err); });
                                    });
                                    return true;
                                },
                                conditionalHideFn: function (data) {
                                    return (!_this.permissions.envelopeDestroy ||
                                        !['ready to sign', 'pending', 'delivered'].some(function (status) {
                                            return data.status.toLowerCase().includes(status);
                                        }));
                                },
                            },
                            {
                                label: 'Void',
                                icon: 'cancel',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(_this.generateConfirmationRequest('Are you sure you want to void this document?'))
                                        .then(function () {
                                        _this.docusignEnvelope = new DocusignEnvelope(_this.patientId, data.envelope_id);
                                        _this.docusignService
                                            .void_envelope(_this.docusignEnvelope)
                                            .then(function () {
                                            console.log(data);
                                        })
                                            .catch(function (err) { return console.log(err); });
                                    });
                                    return true;
                                },
                                conditionalHideFn: function (data) {
                                    return (!_this.permissions.envelopeDestroy ||
                                        !['completed'].some(function (status) {
                                            return data.status.toLowerCase().includes(status);
                                        }));
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Document Name',
                            field: 'description',
                            canSort: true,
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            canSort: true,
                        },
                        {
                            title: 'Last Updated',
                            field: 'last_modified_date_time',
                            type: csg.datatable.ColumnTypes.dateTime,
                            canSort: true,
                        },
                    ],
                };
            };
            ElectronicDocumentsController.$inject = [
                '$state',
                '$window',
                '$timeout',
                'docusignService',
                'docusignEnvelopeDataTableService',
                'options',
                '$mdDialog',
                '$interval',
                'PermissionsService',
                'patientService',
                'SidebarService',
                'SessionService',
                'AboutService',
                'logger',
                '$q',
                '$sce',
                'AccessTokenService',
            ];
            return ElectronicDocumentsController;
        }(QueryStringFilter));
        angular
            .module('consolo')
            .controller('ElectronicDocumentsController', ElectronicDocumentsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var SendElectronicDocumentDialogController = /** @class */ (function () {
            function SendElectronicDocumentDialogController($mdDialog, $window, logger, docusignService) {
                this.$mdDialog = $mdDialog;
                this.$window = $window;
                this.logger = logger;
                this.docusignService = docusignService;
            }
            SendElectronicDocumentDialogController.prototype.closeDialog = function (send) {
                if (!send) {
                    this.$mdDialog.hide(null);
                }
            };
            SendElectronicDocumentDialogController.$inject = ['$mdDialog', '$window', 'logger', 'docusignService'];
            return SendElectronicDocumentDialogController;
        }());
        angular
            .module('consolo')
            .controller('SendElectronicDocumentDialogController', SendElectronicDocumentDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DocusignEnvelope = consolo.domain.DocusignEnvelope;
        var SignElectronicDocumentDialogController = /** @class */ (function () {
            function SignElectronicDocumentDialogController($mdDialog, $window, logger, docusignService) {
                this.$mdDialog = $mdDialog;
                this.$window = $window;
                this.logger = logger;
                this.docusignService = docusignService;
            }
            SignElectronicDocumentDialogController.prototype.closeDialog = function (sign) {
                var _this = this;
                if (!sign) {
                    this.$mdDialog.hide(null);
                }
                else {
                    this.docusignEnvelope = new DocusignEnvelope(this.patient.id, this.envelope.envelope_id);
                    this.docusignService
                        .get_envelope_url(this.docusignEnvelope, this.signerId)
                        .then(function (data) {
                        _this.$mdDialog.hide(data);
                    })
                        .catch(function () {
                        _this.logger.error('Unable to Open Electronic Document for signing.');
                    });
                }
            };
            SignElectronicDocumentDialogController.$inject = ['$mdDialog', '$window', 'logger', 'docusignService'];
            return SignElectronicDocumentDialogController;
        }());
        angular
            .module('consolo')
            .controller('SignElectronicDocumentDialogController', SignElectronicDocumentDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var FamilyDiagnosis = consolo.domain.FamilyDiagnosis;
        var FamilyDiagnosisDialogController = /** @class */ (function () {
            function FamilyDiagnosisDialogController($mdDialog, familyMembersService) {
                this.$mdDialog = $mdDialog;
                this.familyMembersService = familyMembersService;
                this.familyMembersService = familyMembersService;
                this.addText = this.familyMember.id ? 'Save' : 'Add';
                this.diagnosis = new FamilyDiagnosis();
                this.diagnosis.date_of_diagnosis = new Date(new Date().toDateString());
            }
            FamilyDiagnosisDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.diagnosis.family_id = this.familyMember.id;
                this.diagnosis.name = this.snomedCode.name;
                this.diagnosis.snomed_code = this.snomedCode.code;
                this.$mdDialog.hide(this.diagnosis);
            };
            FamilyDiagnosisDialogController.prototype.snomedAutocompleteLookup = function (term) {
                return this.familyMembersService.snomedDiagnosisLookup(term).then(function (results) {
                    return results.data.items.map(function (concept) {
                        return { name: concept.fsn.term, code: concept.conceptId };
                    });
                });
            };
            FamilyDiagnosisDialogController.$inject = ['$mdDialog', 'familyMembersService'];
            return FamilyDiagnosisDialogController;
        }());
        angular
            .module('consolo')
            .controller('FamilyDiagnosisDialogController', FamilyDiagnosisDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var FamilyMembersDataTableService = /** @class */ (function (_super) {
            __extends(FamilyMembersDataTableService, _super);
            function FamilyMembersDataTableService($httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.apiSelectUrl = '/api/v2/family_search';
                return _this;
            }
            FamilyMembersDataTableService.prototype.export = function () {
                return this.$httpService({
                    url: '/api/v2/family_search/export',
                    method: 'GET',
                    params: this.params,
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                });
            };
            FamilyMembersDataTableService.$inject = ['$http'];
            return FamilyMembersDataTableService;
        }(csg.datatable.ApiService));
        services.FamilyMembersDataTableService = FamilyMembersDataTableService;
        angular
            .module('consolo')
            .service('familyMembersDataTableService', FamilyMembersDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Patient = consolo.domain.Patient;
        var FamilyMember = consolo.domain.FamilyMember;
        var optionToDropdownItem = consolo.domain.optionToDropdownItem;
        var Relationship = consolo.domain.Relationship;
        var Address = consolo.domain.Address;
        var FamilyMembersEditController = /** @class */ (function () {
            function FamilyMembersEditController($state, $mdDialog, Session, patientService, familyMembersService, officeFilterService, options, $timeout, logger, $q) {
                var _this = this;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.patientService = patientService;
                this.familyMembersService = familyMembersService;
                this.options = options;
                this.$timeout = $timeout;
                this.logger = logger;
                this.$q = $q;
                this.isSaving = false;
                this.fromClinicalChart = false;
                this.errors = [];
                this.hasPatientPortal = false;
                this.fromClinicalChart = $state.current.data.title === 'Clinical Chart Entry';
                this.patientId = this.$state.params['patientId'] ? +this.$state.params['patientId'] : null;
                this.familyMemberId = this.$state.params['familyMemberId']
                    ? +this.$state.params['familyMemberId']
                    : null;
                this.patient = new Patient();
                this.familyMember = new FamilyMember();
                this.relationship = new Relationship();
                this.familyMember.patient_id = this.relationship.patient_id = this.patientId;
                this.otherPatientFamilyMembers = new Array();
                this.hasPatientPortal = this.Session.get().agency.has_patient_portal;
                this.loadAllOptions();
                this.initializeReferralAccountsTable();
                this.initializeFamilyRelationshipsTable();
                if (!this.fromClinicalChart) {
                    this.initializeFamilyDiagnosisTable();
                }
                var officeFilterServiceInstancePromise = officeFilterService
                    .getInstance()
                    .then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                })
                    .catch(function (err) { return console.log(err); });
                if (this.patientId) {
                    patientService
                        .get(this.patientId)
                        .then(function (response) {
                        _this.patient = response.data;
                        _this.copyAddressOptions = __spreadArrays([
                            { name: 'Patient', value: 'Patient' }
                        ], _this.copyAddressOptions);
                    })
                        .catch(function (err) { return console.log(err); });
                    familyMembersService
                        .selectPatient(this.patientId)
                        .then(function (response) {
                        _this.otherPatientFamilyMembers = response.data.filter(function (familyMember) { return familyMember.id !== _this.familyMemberId; });
                        _this.otherPatientFamilyMembers.forEach(function (familyMember) {
                            var name = familyMember.first_name + " " + familyMember.last_name;
                            _this.copyAddressOptions = _this.copyAddressOptions.concat([
                                { name: name, value: name },
                            ]);
                        });
                    })
                        .catch(function (err) { return console.log(err); });
                }
                if (this.familyMemberId) {
                    this.relationship.family_id = this.familyMemberId;
                    var familyMemberPromise = this.familyMembersService
                        .get(this.familyMemberId)
                        .then(function (response) {
                        _this.familyMember = response.data;
                        _this.familyMember.referral_representations.forEach(function (val, i) { return (val.ui_id = i + 1); });
                        _this.familyMember.patient_family_relationships.forEach(function (relationship, i) {
                            relationship.ui_id = i + 1;
                            if (!_this.patientId) {
                                relationship.validate_responsible_party = true;
                            }
                            else if (_this.patientId === relationship.patient_id) {
                                _this.relationship = relationship;
                                relationship.validate_responsible_party = true;
                            }
                            else {
                                relationship.validate_responsible_party = false;
                            }
                        });
                        //this.familyMember.family_diagnoses.forEach((val, i) => val.id = i + 1);
                    })
                        .catch(function (err) { return console.log(err); });
                    Promise.all([officeFilterServiceInstancePromise, familyMemberPromise]).then(function () {
                        _this.familyMember.offices.forEach(function (familyOffice) {
                            _this.officeFilterServiceInstance.offices.filter(function (office) { return office.id === familyOffice.id; })[0].selected = true;
                        });
                    });
                }
            }
            FamilyMembersEditController.prototype.copyAddressChange = function () {
                var _this = this;
                if (!this.copyAddressFrom) {
                    return;
                }
                if (this.copyAddressFrom === 'Patient') {
                    this.familyMember.address.address_1 = this.patient.current_address.address_1;
                    this.familyMember.address.address_2 = this.patient.current_address.address_2;
                    this.familyMember.address.city = this.patient.current_address.city;
                    this.familyMember.address.state = this.patient.current_address.state;
                    this.familyMember.address.zipcode = this.patient.current_address.zipcode;
                    return;
                }
                var copyFamilyMember = this.otherPatientFamilyMembers.filter(function (family) { return family.first_name + " " + family.last_name === _this.copyAddressFrom; })[0];
                if (copyFamilyMember) {
                    this.familyMember.address.address_1 = copyFamilyMember.address.address_1;
                    this.familyMember.address.address_2 = copyFamilyMember.address.address_2;
                    this.familyMember.address.city = copyFamilyMember.address.city;
                    this.familyMember.address.state = copyFamilyMember.address.state;
                    this.familyMember.address.zipcode = copyFamilyMember.address.zipcode;
                }
            };
            FamilyMembersEditController.prototype.removeAddress = function () {
                this.familyMember.address = new Address();
                this.copyAddressFrom = '';
            };
            FamilyMembersEditController.prototype.showReferralAccountDialog = function ($event, referralAccountToEdit) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/family-members/referral-account-dialog.html',
                    controller: 'ReferralAccountDialogController as vm',
                    bindToController: true,
                    locals: {
                        referralAccount: referralAccountToEdit,
                        contactTypeOptions: this.contactTypeOptions,
                    },
                })
                    .then(function (referralAccount) {
                    if (referralAccount) {
                        if (referralAccount.ui_id) {
                            //edit
                            var existingReferralAccount = _this.familyMember.referral_representations.filter(function (acc) { return acc.ui_id === referralAccount.ui_id; })[0];
                            var index = _this.familyMember.referral_representations.indexOf(existingReferralAccount);
                            var editedReferralAccounts = __spreadArrays(_this.familyMember.referral_representations);
                            editedReferralAccounts[index] = referralAccount;
                            _this.familyMember.referral_representations = __spreadArrays(editedReferralAccounts);
                        }
                        else {
                            //new
                            referralAccount.ui_id =
                                (_this.familyMember.referral_representations.reduce(function (acc, val) { return Math.max(acc, val.ui_id); }, 0) || 0) + 1;
                            referralAccount.service_id = referralAccount.service.id;
                            _this.familyMember.referral_representations = __spreadArrays(_this.familyMember.referral_representations, [
                                referralAccount,
                            ]);
                        }
                    }
                });
            };
            FamilyMembersEditController.prototype.showFamilyDiagnosisDialog = function ($event, familyDiagnosis) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/family-members/family-diagnosis-dialog.html',
                    controller: 'FamilyDiagnosisDialogController as vm',
                    bindToController: true,
                    locals: {
                        familyDiagnosis: familyDiagnosis,
                        familyMember: this.familyMember,
                    },
                })
                    .then(function (familyDiagnosis) {
                    if (familyDiagnosis) {
                        if (familyDiagnosis.id) {
                            //edit
                            //this.familyMember.family_diagnoses = [...editedReferralAccounts];
                        }
                        else {
                            //new
                            _this.familyMember.family_diagnoses = __spreadArrays(_this.familyMember.family_diagnoses, [
                                familyDiagnosis,
                            ]);
                            if (_this.familyMemberId) {
                                if (!_this.editDisabled()) {
                                    _this.edit(false);
                                }
                                else {
                                    _this.logger.error('Cannot Auto-Save new diagnosis. Please fill out the required fields and click Save', 'Cannot Auto-Save');
                                }
                            }
                        }
                    }
                });
            };
            FamilyMembersEditController.prototype.showFamilyMemberRelationshipDialog = function ($event, relationshipToEdit) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/family-members/new-relationship-dialog.html',
                    controller: 'NewRelationshipDialogController as vm',
                    bindToController: true,
                    locals: {
                        relationship: relationshipToEdit,
                        patientService: this.patientService,
                        relationshipOptions: this.relationshipOptions,
                        familyMemberId: this.familyMemberId,
                        FamilyMembersService: this.familyMembersService
                    },
                })
                    .then(function (relationship) {
                    if (relationship) {
                        if (relationship.ui_id) {
                            //edit
                            var existingRelationship = _this.familyMember.patient_family_relationships.filter(function (rel) { return rel.ui_id === relationship.ui_id; })[0];
                            var index = _this.familyMember.patient_family_relationships.indexOf(existingRelationship);
                            var editedRelationships = __spreadArrays(_this.familyMember.patient_family_relationships);
                            editedRelationships[index] = relationship;
                            _this.familyMember.patient_family_relationships = __spreadArrays(editedRelationships);
                        }
                        else {
                            //new
                            relationship.ui_id =
                                (_this.familyMember.patient_family_relationships.reduce(function (acc, val) { return Math.max(acc, val.ui_id); }, 0) || 0) + 1;
                            _this.familyMember.patient_family_relationships = __spreadArrays(_this.familyMember.patient_family_relationships, [
                                relationship,
                            ]);
                        }
                    }
                });
            };
            FamilyMembersEditController.prototype.noSurveysChange = function () {
                if (this.familyMember.no_surveys) {
                    var alert_1 = this.$mdDialog
                        .alert()
                        .title('Warning')
                        .textContent("Updating a contact/family member for No Surveys makes them ineligible to be selected as the CAHPS Survey Care Giver.\n            Please review the Patient\u2019s Personal Information.\n            If the patient has additional eligible contacts, they will display in the CAHPS Survey Care Giver box and you may choose one to survey.")
                        .ok('Ok');
                    this.$mdDialog.show(alert_1);
                }
            };
            FamilyMembersEditController.prototype.edit = function (doRedirect) {
                var _this = this;
                if (doRedirect === void 0) { doRedirect = true; }
                this.isSaving = true;
                this.familyMember.offices = this.officeFilterServiceInstance.getSelected();
                this.familyMember.office_ids = this.familyMember.offices.map(function (office) { return office.id.toString(); });
                this.familyMember.family_diagnoses = this.familyMember.family_diagnoses;
                if (this.patientId) {
                    var existingRelationship = this.familyMember.patient_family_relationships.filter(function (relationship) { return relationship.patient_id === _this.patientId; })[0];
                    if (existingRelationship) {
                        this.familyMember.patient_family_relationships[this.familyMember.patient_family_relationships.indexOf(existingRelationship)] = this.relationship;
                    }
                    else {
                        this.familyMember.patient_family_relationships.push(this.relationship);
                        this.familyMember.patient_family_relationships = [].concat(this.familyMember.patient_family_relationships);
                    }
                }
                if (!this.fromClinicalChart) {
                    var redirect_1 = function (familyMemberId) {
                        return doRedirect
                            ? _this.patientId
                                ? _this.$state.go('patients.family-members.view', {
                                    patientId: _this.patientId,
                                    familyMemberId: familyMemberId,
                                })
                                : _this.$state.go('family-members.view', { familyMemberId: familyMemberId })
                            : _this.logger.success('Family Member Updated');
                    };
                    if (this.familyMemberId) {
                        this.familyMembersService
                            .update(this.familyMember)
                            .then(function (response) { return redirect_1(_this.familyMemberId); })
                            .catch(function (err) {
                            console.log(err);
                            _this.errors = err.data.errors;
                        })
                            .finally(function () { return _this.$timeout(function () { return (_this.isSaving = false); }, 2500); });
                    }
                    else {
                        this.familyMembersService
                            .add(this.familyMember)
                            .then(function (response) { return redirect_1(response.data.id); })
                            .catch(function (err) {
                            console.log(err);
                            _this.errors = err.data.errors;
                        })
                            .finally(function () { return _this.$timeout(function () { return (_this.isSaving = false); }, 2500); });
                    }
                }
                else {
                    this.familyMembersService
                        .add(this.familyMember)
                        .then(function (response) { return null; })
                        .catch(function (err) {
                        console.log(err);
                        _this.errors = err;
                    })
                        .finally(function () {
                        _this.$timeout(function () { return (_this.isSaving = false); }, 2500);
                        _this.$mdDialog.hide();
                    });
                }
            };
            FamilyMembersEditController.prototype.editDisabled = function () {
                var disabled = !(this.familyMember.first_name &&
                    this.familyMember.last_name &&
                    (!this.patientId || this.relationship.relationship) &&
                    this.officeFilterServiceInstance &&
                    this.officeFilterServiceInstance.anySelected());
                if (!disabled &&
                    (this.familyMember.address.address_1 ||
                        this.familyMember.address.address_2 ||
                        this.familyMember.address.city ||
                        this.familyMember.address.state ||
                        this.familyMember.address.zipcode)) {
                    disabled = !(this.familyMember.address.address_1 &&
                        this.familyMember.address.city &&
                        this.familyMember.address.state &&
                        this.familyMember.address.zipcode);
                }
                return disabled;
            };
            FamilyMembersEditController.prototype.initializeReferralAccountsTable = function () {
                var _this = this;
                this.referralAccountsLayout = {
                    id_field: 'name',
                    headerMenu: {},
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.showReferralAccountDialog(null, data);
                                },
                            },
                            {
                                label: 'Remove',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    var referralAccount = _this.familyMember.referral_representations.filter(function (acc) { return acc.ui_id === data.ui_id; })[0];
                                    var updatedReferralAccounts = __spreadArrays(_this.familyMember.referral_representations);
                                    updatedReferralAccounts.splice(updatedReferralAccounts.indexOf(referralAccount), 1);
                                    _this.familyMember.referral_representations = __spreadArrays(updatedReferralAccounts);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Name', field: 'service.name', canSort: true },
                        { title: 'Contact Type', field: 'contact_type', canSort: true },
                        { title: 'Phone', field: 'phone', canSort: true },
                        { title: 'Email', field: 'email', canSort: true },
                    ],
                };
            };
            FamilyMembersEditController.prototype.initializeFamilyDiagnosisTable = function () {
                var _this = this;
                this.familyDiagnosisLayout = {
                    id_field: 'name',
                    headerMenu: {},
                    rowMenu: {
                        items: [
                            {
                                label: 'Remove',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    var familyDiagnosis = _this.familyMember.family_diagnoses.filter(function (diag) { return diag.id === data.id; })[0];
                                    var updatedFamilyDiagnoses = __spreadArrays(_this.familyMember.family_diagnoses);
                                    updatedFamilyDiagnoses.splice(updatedFamilyDiagnoses.indexOf(familyDiagnosis), 1);
                                    _this.familyMember.family_diagnoses = __spreadArrays(updatedFamilyDiagnoses);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Diagnosis Name', field: 'name', canSort: true },
                        { title: 'SNOMED Code', field: 'snomed_code', canSort: true },
                        {
                            title: 'Recorded Date',
                            field: 'date_of_diagnosis',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                    ],
                };
            };
            FamilyMembersEditController.prototype.initializeFamilyRelationshipsTable = function () {
                var _this = this;
                this.familyMemberRelationshipsLayout = {
                    id_field: 'id',
                    headerMenu: {},
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.showFamilyMemberRelationshipDialog(null, data);
                                },
                            },
                            {
                                label: 'Remove Relationship',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    var relationship = _this.familyMember.patient_family_relationships.filter(function (rel) { return rel.ui_id === data.ui_id; })[0];
                                    var updatedRelationships = __spreadArrays(_this.familyMember.patient_family_relationships);
                                    updatedRelationships.splice(updatedRelationships.indexOf(relationship), 1);
                                    _this.familyMember.patient_family_relationships = __spreadArrays(updatedRelationships);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Patient Name', field: 'patient_name', canSort: true },
                        { title: 'Relationship', field: 'relationship_description', canSort: true },
                        { title: 'Other', field: 'other', canSort: true },
                        { title: 'Responsible Party', field: 'responsible_party' }
                    ],
                };
            };
            FamilyMembersEditController.prototype.loadAllOptions = function () {
                var _this = this;
                this.copyAddressOptions = new Array();
                this.options.get('title').$promise.then(function (response) {
                    _this.titleOptions = response.map(function (option) { return optionToDropdownItem(option, 'id'); });
                });
                this.options.get('contact_type').$promise.then(function (response) {
                    _this.contactTypeOptions = response.map(function (option) { return optionToDropdownItem(option, 'id'); });
                });
                this.options.get('genders').$promise.then(function (response) {
                    _this.genderOptions = response.map(function (option) { return optionToDropdownItem(option, 'id'); });
                });
                this.options.get('languages').$promise.then(function (response) {
                    _this.languageOptions = response.map(function (option) { return optionToDropdownItem(option, 'id'); });
                });
                this.options.get('relationships').$promise.then(function (response) {
                    _this.relationshipOptions = response.map(function (option) { return optionToDropdownItem(option, 'code'); });
                });
                this.options.get('states').$promise.then(function (response) {
                    _this.stateOptions = response.map(function (option) { return optionToDropdownItem(option, 'code'); });
                });
                this.options.get('referral_types').$promise.then(function (response) {
                    _this.referralTypeOptions = response.map(function (option) { return optionToDropdownItem(option, 'code'); });
                });
            };
            FamilyMembersEditController.$inject = [
                '$state',
                '$mdDialog',
                'SessionService',
                'patientService',
                'familyMembersService',
                'officeFilterService',
                'options',
                '$timeout',
                'logger',
                '$q'
            ];
            return FamilyMembersEditController;
        }());
        angular.module('consolo').controller('FamilyMembersEditController', FamilyMembersEditController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Patient = consolo.domain.Patient;
        var FamilyMember = consolo.domain.FamilyMember;
        var Relationship = consolo.domain.Relationship;
        var FamilyMembersViewController = /** @class */ (function () {
            function FamilyMembersViewController($state, patientService, familyMembersService, options, Session) {
                var _this = this;
                this.$state = $state;
                this.Session = Session;
                this.menuIsOpen = false;
                this.hasPatientPortal = false;
                this.patientId = $state.params['patientId'] ? +$state.params['patientId'] : null;
                this.familyMemberId = $state.params['familyMemberId']
                    ? +$state.params['familyMemberId']
                    : null;
                this.patient = new Patient();
                this.familyMember = new FamilyMember();
                this.relationship = new Relationship();
                this.hasPatientPortal = this.Session.get().agency.has_patient_portal;
                this.initializeReferralAccountsTable();
                this.initializeFamilyMemberRelationshipsTable();
                this.initializeFamilyDiagnosisTable();
                var familyMembersPromise = familyMembersService.get(this.familyMemberId).then(function (response) {
                    _this.familyMember = response.data;
                    if (_this.familyMember.gender_id) {
                        options.get('genders').$promise.then(function (options) {
                            _this.genderText = options.filter(function (option) { return option.id === +_this.familyMember.gender_id; })[0].description;
                        });
                    }
                    if (_this.familyMember.language_id) {
                        options.get('language').$promise.then(function (options) {
                            _this.languageText = options.filter(function (option) { return option.id === +_this.familyMember.language_id; })[0].description;
                        });
                    }
                    _this.officeList = _this.familyMember.offices.reduce(function (acc, val) { return (acc ? acc + ", " + val.name : val.name); }, '');
                });
                if (this.patientId) {
                    patientService.get(this.patientId).then(function (response) {
                        _this.patient = response.data;
                    });
                    familyMembersPromise.then(function () {
                        _this.relationship = _this.familyMember.patient_family_relationships.filter(function (relationship) { return relationship.patient_id === _this.patientId; })[0];
                    });
                }
            }
            FamilyMembersViewController.prototype.initializeReferralAccountsTable = function () {
                this.referralAccountsLayout = {
                    id_field: 'name',
                    columns: [
                        { title: 'Name', field: 'service.name', canSort: true },
                        { title: 'Contact Type', field: 'contact_type', canSort: true },
                        { title: 'Phone', field: 'phone', canSort: true },
                        { title: 'Email', field: 'email', canSort: true },
                    ],
                };
            };
            FamilyMembersViewController.prototype.initializeFamilyMemberRelationshipsTable = function () {
                this.familyMemberRelationshipsLayout = {
                    id_field: 'id',
                    columns: [
                        { title: 'Patient Name', field: 'patient_name', canSort: true },
                        { title: 'Relationship', field: 'relationship_description', canSort: true },
                        { title: 'Other', field: 'other', canSort: true },
                    ],
                };
            };
            FamilyMembersViewController.prototype.initializeFamilyDiagnosisTable = function () {
                this.familyDiagnosisLayout = {
                    id_field: 'id',
                    columns: [
                        { title: 'Diagnosis', field: 'name', canSort: true },
                        { title: 'SNOMED Code', field: 'snomed_code', canSort: true },
                        {
                            title: 'Recorded Date',
                            field: 'date_of_diagnosis',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                    ],
                };
            };
            FamilyMembersViewController.$inject = [
                '$state',
                'patientService',
                'familyMembersService',
                'options',
                'SessionService'
            ];
            return FamilyMembersViewController;
        }());
        angular.module('consolo').controller('FamilyMembersViewController', FamilyMembersViewController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var FamilyMemberFilters = consolo.domain.FamilyMemberFilters;
        var QueryStringFilter = consolo.domain.QueryStringFilter;
        var optionToDropdownItem = consolo.domain.optionToDropdownItem;
        var FamilyMembersController = /** @class */ (function (_super) {
            __extends(FamilyMembersController, _super);
            function FamilyMembersController($state, $location, familyMembersService, familyMembersDataTableService, options, officeFilterService, $mdDialog, $interval, patientService, permissionsService, $q) {
                var _this = _super.call(this) || this;
                _this.$state = $state;
                _this.$location = $location;
                _this.familyMembersService = familyMembersService;
                _this.familyMembersDataTableService = familyMembersDataTableService;
                _this.options = options;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.patientService = patientService;
                _this.permissionsService = permissionsService;
                _this.$q = $q;
                _this.permissions = {};
                _this.patientId = _this.$state.params['patient_id'] ? +_this.$state.params['patient_id'] : null;
                _this.loadPermissions();
                _this.initializeFamilyMembersGrid();
                _this.initializeOptions();
                officeFilterService.getInstance().then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                    _this.reset();
                });
                if (_this.patientId) {
                    patientService.get(_this.patientId).then(function (response) {
                        _this.patient = response.data;
                    });
                }
                return _this;
            }
            FamilyMembersController.prototype.applyInitialFilters = function () {
                var _this = this;
                _super.prototype.applyInitialFilters.call(this);
                this.officeFilterServiceInstance.offices.forEach(function (office) {
                    if (_this.filters.office_ids.indexOf(office.id.toString()) > -1) {
                        office.selected = true;
                    }
                });
            };
            FamilyMembersController.prototype.patientAutocompleteLookup = function (query) {
                return this.patientService.lookup(query).then(function (results) { return results.data; });
            };
            FamilyMembersController.prototype.query = function () {
                var _this = this;
                this.isLoading = true;
                this.filters.office_ids = this.officeFilterServiceInstance.getSelectedIds();
                this.updateFilterQueryStringIfNeeded();
                this.apiInterval = this.$interval(function () {
                    if (_this.familyMembersDataTableService.tableControl &&
                        _this.familyMembersDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        console.log('query', _this.filters);
                        _this.familyMembersDataTableService.tableControl.loadSelectData().finally(function () {
                            console.log(_this.familyMembersDataTableService.data);
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            FamilyMembersController.prototype.reset = function () {
                this.filters = new FamilyMemberFilters(this.patientId);
                console.log(this.filters);
                this.officeFilterServiceInstance.reset();
                this.applyInitialFiltersIfNeeded();
                this.familyMembersDataTableService.params = this.filters;
                this.query();
            };
            FamilyMembersController.prototype.importFamilyMembers = function () {
                document.location.href = '/families/import';
            };
            FamilyMembersController.prototype.newFamilyMember = function () {
                if (this.patientId) {
                    this.$state.go('patients.family-members.new', { patientId: this.patientId });
                }
                else {
                    this.$state.go('family-members.new');
                }
            };
            FamilyMembersController.prototype.showRelateFamilyMemberDialog = function ($event) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/family-members/relate-existing-dialog.html',
                    controller: 'RelateExistingDialogController as vm',
                    bindToController: true,
                    locals: {
                        patient: this.patient,
                    },
                }).then(function (relationship) {
                    _this.reset();
                });
            };
            FamilyMembersController.prototype.loadPermissions = function () {
                var permission_chain = [];
                var defer = this.$q.defer();
                this.permissions.familyCreate = this.permissionsService.can('create', 'families');
                this.permissions.familyRelate = this.permissionsService.can('create', 'patient_family_relationships');
                this.permissions.familyExport = this.permissionsService.can('export', 'families');
                this.$q.all(permission_chain).finally(function () {
                    defer.resolve(true);
                });
                return defer.promise;
            };
            FamilyMembersController.prototype.initializeOptions = function () {
                var _this = this;
                this.options.get('referral_types').$promise.then(function (response) {
                    _this.referralTypeOptions = response.map(function (option) { return optionToDropdownItem(option, 'code'); });
                });
            };
            FamilyMembersController.prototype.initializeFamilyMembersGrid = function () {
                var _this = this;
                this.familyMembersLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: this.permissions.familyExport
                            ? [
                                {
                                    menuItemType: csg.datatable.MenuItemType.csvHeader,
                                },
                                {
                                    menuItemType: csg.datatable.MenuItemType.csvAll,
                                },
                                {
                                    menuItemType: csg.datatable.MenuItemType.default,
                                    label: 'All Items',
                                    indentItem: true,
                                    callbackFn: function (data, item) {
                                        _this.familyMembersDataTableService.export();
                                    },
                                },
                                {
                                    menuItemType: csg.datatable.MenuItemType.copyHeader,
                                },
                                {
                                    menuItemType: csg.datatable.MenuItemType.copyAll,
                                },
                            ]
                            : [],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    if (_this.patientId) {
                                        _this.$state.go('patients.family-members.edit', {
                                            patientId: _this.patientId,
                                            familyMemberId: data.id,
                                        });
                                    }
                                    else {
                                        _this.$state.go('family-members.edit', { familyMemberId: data.id });
                                    }
                                    return true;
                                },
                            },
                            {
                                label: 'Email',
                                icon: 'email',
                                callbackFn: function (data, item) {
                                    window.location.href = "mailto:" + data.email;
                                    return true;
                                },
                                conditionalDisableFn: function (data, item) {
                                    return !data.email;
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: this.patientId
                                ? "patients.family-members.view({patientId: " + this.patientId + ", familyMemberId: $id})"
                                : 'family-members.view({familyMemberId: $id})',
                        },
                        { title: 'Assignment', field: 'assignment', canSort: false },
                        { title: 'Referral Type', field: 'referral_type', canSort: true },
                        {
                            title: 'Address',
                            field: 'address.address_1',
                            canSort: false,
                            formatValueFn: function (data, item) {
                                console.log(data);
                                if (!data.address || !data.address.address_1) {
                                    return '';
                                }
                                if (!data.address.address_2) {
                                    return data.address.address_1;
                                }
                                return data.address.address_1 + " " + data.address.address_2;
                            },
                        },
                        { title: 'City', field: 'address.city', canSort: true },
                        { title: 'State', field: 'address.state', canSort: true },
                        { title: 'Zipcode', field: 'address.zipcode', canSort: true },
                        {
                            title: 'Work Phone',
                            field: 'work_phone',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.phone,
                        },
                        {
                            title: 'Mobile Phone',
                            field: 'mobile_phone',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.phone,
                        },
                        {
                            title: 'Home Phone',
                            field: 'home_phone',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.phone,
                        },
                        {
                            title: 'Related To',
                            field: 'patient_family_relationships',
                            arrayField: 'patient_name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.linkArray,
                            linkRoute: 'patients.dashboard({patientId: $patient_id})',
                        },
                        {
                            title: 'Relationship',
                            field: 'patient_relationship',
                            canSort: false,
                            type: csg.datatable.ColumnTypes.list_array,
                        },
                    ],
                };
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                if (this.patientId) {
                    this.familyMembersLayout.rowMenu.items.push({
                        label: 'Remove Relationship',
                        icon: 'swap_horiz',
                        callbackFn: function (data, item) {
                            _this.$mdDialog
                                .show(generateConfirmationRequest('Are you sure you want to remove this relationship?'))
                                .then(function () {
                                var relationship = data.patient_family_relationships.filter(function (relationship) { return relationship.patient_id === _this.patientId; })[0];
                                _this.familyMembersService
                                    .unrelate(relationship.id)
                                    .then(function () { return _this.query(); })
                                    .catch(function (err) { return console.log(err); });
                            });
                            return true;
                        },
                    });
                }
                else {
                    this.familyMembersLayout.rowMenu.items.push({
                        label: 'Delete',
                        icon: 'delete',
                        callbackFn: function (data, item) {
                            _this.$mdDialog
                                .show(generateConfirmationRequest('Are you sure you want to delete this family member?'))
                                .then(function () {
                                _this.familyMembersService
                                    .delete(data.id)
                                    .then(function () { return _this.query(); })
                                    .catch(function (err) { return console.log(err); });
                            });
                            return true;
                        },
                    });
                }
            };
            FamilyMembersController.$inject = [
                '$state',
                '$location',
                'familyMembersService',
                'familyMembersDataTableService',
                'options',
                'officeFilterService',
                '$mdDialog',
                '$interval',
                'patientService',
                'PermissionsService',
                '$q',
            ];
            return FamilyMembersController;
        }(QueryStringFilter));
        angular.module('consolo').controller('FamilyMembersController', FamilyMembersController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var FamilyMembersService = /** @class */ (function () {
            function FamilyMembersService($http, $q) {
                this.$http = $http;
                this.$q = $q;
                this.responsibilityOptions = [
                    { id: null, description: "" },
                    { id: 'primary', description: 'Primary Responsible Party' },
                    { id: 'secondary', description: 'Secondary Responsible Party' },
                    { id: 'denies_responsibility', description: 'Not a Responsible Party' }
                ];
                this.requiredFields = ['first_name', 'last_name', 'offices'];
            }
            FamilyMembersService.prototype.create = function (npcData) {
                return this.add(npcData).then(function (response) {
                    return response.data;
                });
            };
            FamilyMembersService.prototype.selectPatient = function (patientId) {
                return this.$http({
                    url: '/api/v2/family_search',
                    method: 'GET',
                    params: { patient_id: patientId },
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                });
            };
            FamilyMembersService.prototype.get = function (id) {
                return this.$http({
                    url: "/api/v1/family_members/" + id,
                    method: 'GET',
                });
            };
            FamilyMembersService.prototype.getResponsibility = function (patientId) {
                return this.selectPatient(patientId).then(function (response) {
                    var currentResponsibilities = {};
                    response.data.forEach(function (familyMember) {
                        familyMember.patient_family_relationships.some(function (relationship) {
                            if (relationship.patient_id === patientId) {
                                currentResponsibilities[relationship.responsible_party] = familyMember.id;
                                return true;
                            }
                        });
                    });
                    return currentResponsibilities;
                });
            };
            FamilyMembersService.prototype.snomedDiagnosisLookup = function (term) {
                return this.$http({
                    url: '/api/v1/family_members/snomed_diagnosis_lookup',
                    method: 'GET',
                    params: { term: term }
                });
            };
            FamilyMembersService.prototype.lookup = function (name) {
                return this.$http({
                    url: '/api/v2/family_search',
                    method: 'GET',
                    params: { name: name },
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                });
            };
            FamilyMembersService.prototype.add = function (familyMember) {
                return this.$http({
                    url: '/api/v1/family_members',
                    method: 'POST',
                    data: familyMember,
                });
            };
            FamilyMembersService.prototype.update = function (familyMember) {
                return this.$http({
                    url: "/api/v1/family_members/" + familyMember.id,
                    method: 'PATCH',
                    data: familyMember,
                });
            };
            FamilyMembersService.prototype.delete = function (id) {
                return this.$http({
                    url: "/api/v1/family_members/" + id,
                    method: 'DELETE',
                });
            };
            FamilyMembersService.prototype.relate = function (relationship) {
                return this.$http({
                    url: '/api/v1/family_members/relationships',
                    method: 'POST',
                    data: relationship,
                });
            };
            FamilyMembersService.prototype.unrelate = function (relationshipId) {
                return this.$http({
                    url: "/api/v1/family_members/relationships/" + relationshipId,
                    method: 'DELETE',
                });
            };
            FamilyMembersService.$inject = ['$http', '$q'];
            return FamilyMembersService;
        }());
        services.FamilyMembersService = FamilyMembersService;
        angular.module('consolo').service('familyMembersService', FamilyMembersService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var FamilyMembersStates = /** @class */ (function () {
            function FamilyMembersStates($stateProvider) {
                $stateProvider
                    .state('family-members', {
                    url: '/family-members?name&patient_id&address&email&referral_type&include_deleted&office_ids',
                    reloadOnSearch: false,
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/patients/family-members/family-members.html',
                            controller: 'FamilyMembersController as vm',
                        },
                    },
                    resolve: {
                        authorized: [
                            'RouteAuthService',
                            function (RouteAuth) {
                                return RouteAuth.canOrAlert('show', 'families');
                            },
                        ],
                    },
                })
                    .state('family-members.new', {
                    url: '/new',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/patients/family-members/family-members-edit.html',
                            controller: 'FamilyMembersEditController as vm',
                        },
                    },
                    resolve: {
                        authorized: [
                            'RouteAuthService',
                            function (RouteAuth) {
                                return RouteAuth.canOrAlert('create', 'families');
                            },
                        ],
                    },
                })
                    .state('family-members.view', {
                    url: '/:familyMemberId',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/patients/family-members/family-members-view.html',
                            controller: 'FamilyMembersViewController as vm',
                        },
                    },
                    resolve: {
                        authorized: [
                            'RouteAuthService',
                            function (RouteAuth) {
                                return RouteAuth.canOrAlert('show', 'families');
                            },
                        ],
                    },
                })
                    .state('family-members.edit', {
                    url: '/:familyMemberId/edit',
                    data: {
                        hideSidebar: true,
                    },
                    views: {
                        'main-content@': {
                            templateUrl: '/y/templates/patients/family-members/family-members-edit.html',
                            controller: 'FamilyMembersEditController as vm',
                        },
                    },
                    resolve: {
                        authorized: [
                            'RouteAuthService',
                            function (RouteAuth) {
                                return RouteAuth.canOrAlert('update', 'families');
                            },
                        ],
                    },
                });
            }
            FamilyMembersStates.$inject = ['$stateProvider'];
            return FamilyMembersStates;
        }());
        angular.module('consolo').config(FamilyMembersStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Relationship = consolo.domain.Relationship;
        var Patient = consolo.domain.Patient;
        var NewRelationshipDialogController = /** @class */ (function () {
            function NewRelationshipDialogController($mdDialog, $q) {
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                if (this.relationship) {
                    this.patient = new Patient();
                    this.patient.id = this.relationship.patient_id;
                    this.patient.name = this.relationship.patient_name;
                }
                else {
                    this.relationship = new Relationship();
                    if (this.familyMemberId) {
                        this.relationship.family_id = this.familyMemberId;
                    }
                }
            }
            NewRelationshipDialogController.prototype.patientAutocompleteLookup = function (query) {
                return this.patientService.lookup(query).then(function (results) { return results.data; });
            };
            NewRelationshipDialogController.prototype.closeDialog = function (save) {
                var _this = this;
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.relationship.patient_id = this.patient.id;
                this.relationship.patient_name = this.patient.name;
                this.relationship.relationship_description = this.relationshipOptions.filter(function (opt) { return opt.value === _this.relationship.relationship; })[0].name;
                this.$mdDialog.hide(this.relationship);
            };
            NewRelationshipDialogController.$inject = ['$mdDialog', '$q'];
            return NewRelationshipDialogController;
        }());
        angular
            .module('consolo')
            .controller('NewRelationshipDialogController', NewRelationshipDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ReferralAccount = consolo.domain.ReferralAccount;
        var ReferralAccountDialogController = /** @class */ (function () {
            function ReferralAccountDialogController($mdDialog, serviceService) {
                this.$mdDialog = $mdDialog;
                this.serviceService = serviceService;
                this.referralAccount = this.referralAccount || new ReferralAccount({});
            }
            ReferralAccountDialogController.prototype.serviceAutocompleteLookup = function (query) {
                return this.serviceService.lookup(query).then(function (results) { return results.data; });
            };
            ReferralAccountDialogController.prototype.closeDialog = function (save) {
                this.$mdDialog.hide(save ? this.referralAccount : null);
            };
            ReferralAccountDialogController.$inject = ['$mdDialog', 'serviceService'];
            return ReferralAccountDialogController;
        }());
        angular
            .module('consolo')
            .controller('ReferralAccountDialogController', ReferralAccountDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Relationship = consolo.domain.Relationship;
        var optionToDropdownItem = consolo.domain.optionToDropdownItem;
        var RelateExistingDialogController = /** @class */ (function () {
            function RelateExistingDialogController($mdDialog, familyMembersService, options, $q) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.familyMembersService = familyMembersService;
                this.options = options;
                this.$q = $q;
                this.relationship = new Relationship();
                this.options.get('relationships').$promise.then(function (response) {
                    _this.relationshipOptions = response.map(function (option) { return optionToDropdownItem(option, 'code'); });
                });
            }
            RelateExistingDialogController.prototype.familyMemberLookup = function (query) {
                return this.familyMembersService.lookup(query).then(function (results) { return results.data; });
            };
            RelateExistingDialogController.prototype.closeDialog = function (save) {
                var _this = this;
                if (!save) {
                    this.$mdDialog.cancel(null);
                    return;
                }
                this.relationship.patient_id = this.patient.id;
                this.relationship.patient_name = this.patient.name;
                this.relationship.family_id = this.familyMember.id;
                this.relationship.relationship_description = this.relationshipOptions.filter(function (opt) { return opt.value === _this.relationship.relationship; })[0].name;
                this.familyMembersService.relate(this.relationship).then(function (res) {
                    _this.$mdDialog.hide(res);
                }).catch(function (errors) {
                    _this.errors = errors.data.errors;
                });
            };
            RelateExistingDialogController.$inject = ['$mdDialog', 'familyMembersService', 'options', '$q'];
            return RelateExistingDialogController;
        }());
        angular
            .module('consolo')
            .controller('RelateExistingDialogController', RelateExistingDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var FamilyMemberFilters = consolo.domain.FamilyMemberFilters;
        var RelatedFamilyMembersDialogController = /** @class */ (function () {
            function RelatedFamilyMembersDialogController($state, $mdDialog, familyMembersService, familyMembersDataTableService) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.familyMembersService = familyMembersService;
                this.familyMembersDataTableService = familyMembersDataTableService;
                this.patientId = this.$state.params['patientId'] ? +this.$state.params['patientId'] : null;
                var filters = new FamilyMemberFilters();
                filters.patient_id = this.patientId;
                this.familyMembersDataTableService.params = filters;
                this.initializeFamilyMembersGrid();
            }
            RelatedFamilyMembersDialogController.prototype.initializeFamilyMembersGrid = function () {
                var _this = this;
                this.familyMembersLayout = {
                    id_field: 'id',
                    options: {
                        defaultPageSize: 10,
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.$state.go('patients.family-members.edit', { familyMemberId: data.id });
                                    _this.closeDialog();
                                    return true;
                                },
                            },
                            {
                                label: 'Email',
                                icon: 'email',
                                callbackFn: function (data, item) {
                                    window.location.href = "mailto:" + data.email;
                                    return true;
                                },
                                conditionalDisableFn: function (data, item) {
                                    return !data.email;
                                },
                            },
                            {
                                label: 'Remove Relationship',
                                icon: 'swap_horiz',
                                callbackFn: function (data, item) {
                                    var relationship = data.patient_family_relationships.filter(function (relationship) { return relationship.patient_id === _this.patientId; })[0];
                                    _this.familyMembersService
                                        .unrelate(relationship.id)
                                        .then(function () { return _this.familyMembersDataTableService.tableControl.loadSelectData(); })
                                        .catch(function (err) { return console.log(err); });
                                    return true;
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.link,
                            callbackFn: function (data) {
                                _this.$state.go('patients.family-members.view', { familyMemberId: data.id });
                                _this.closeDialog();
                            },
                        },
                        {
                            title: 'Relationship',
                            field: 'relationship',
                            canSort: true,
                            formatValueFn: function (data, col, value) {
                                var relationship = data.patient_family_relationships.filter(function (relationship) { return relationship.patient_id === _this.patientId; })[0];
                                return relationship.relationship === 'OTH'
                                    ? relationship.relationship_description + " (" + relationship.other + ")"
                                    : relationship.relationship_description;
                            },
                        },
                        { title: 'Assignment', field: 'assignment', canSort: true },
                        { title: 'Referral Type', field: 'referral_type', canSort: true },
                        {
                            title: 'Home Phone',
                            field: 'home_phone',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.phone,
                        },
                        { title: 'Responsible Party',
                            field: 'responsibility_party',
                            formatValueFn: function (data, col, value) {
                                var relationship = data.patient_family_relationships.filter(function (relationship) { return relationship.patient_id === _this.patientId; })[0];
                                return relationship.responsible_party;
                            },
                        },
                    ],
                };
            };
            RelatedFamilyMembersDialogController.prototype.openDetailedView = function () {
                this.$state.go('family-members', { patient_id: this.patientId });
                this.closeDialog();
            };
            RelatedFamilyMembersDialogController.prototype.addNewFamilyMember = function () {
                this.$state.go('patients.family-members.new');
                this.closeDialog();
            };
            RelatedFamilyMembersDialogController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            RelatedFamilyMembersDialogController.$inject = [
                '$state',
                '$mdDialog',
                'familyMembersService',
                'familyMembersDataTableService',
            ];
            return RelatedFamilyMembersDialogController;
        }());
        angular
            .module('consolo')
            .controller('RelatedFamilyMembersDialogController', RelatedFamilyMembersDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var PatientFaxComponent = /** @class */ (function () {
            function PatientFaxComponent() {
                this.templateUrl = '/y/templates/patients/fax/patient-fax.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    pdfOptions: '<',
                    faxSelection: '<',
                };
                this.controller = PatientFaxComponentController;
            }
            return PatientFaxComponent;
        }());
        var PatientFaxComponentController = /** @class */ (function () {
            function PatientFaxComponentController(PdfGenerator, FaxGenerator, CurrentPatient, pdfer, $mdDialog) {
                this.PdfGenerator = PdfGenerator;
                this.FaxGenerator = FaxGenerator;
                this.CurrentPatient = CurrentPatient;
                this.pdfer = pdfer;
                this.$mdDialog = $mdDialog;
                this.mostRecentFaxRecipientId = '';
                this.documentLists = {
                    face_sheet: false,
                    medications: [],
                    care_plan_problems: [],
                    certifications: [],
                    clinical_charts: [],
                    idg_summaries: [],
                    discharge_summaries: [],
                    patient_documents: [],
                    plan_of_cares: [],
                    physician_orders: [],
                    dme_orders: [],
                };
            }
            PatientFaxComponentController.prototype.$onInit = function () {
                this.pdfOptions = this.pdfOptions || {};
                this.faxSelection = this.faxSelection || {};
                this.FaxGenerator.init(this.patientId, this.faxSelection);
                this.currentPatient = this.CurrentPatient.patient;
                console.log(this.patientId, this.pdfOptions, this.faxSelection);
            };
            PatientFaxComponentController.prototype.sendFax = function (asFax) {
                var _this = this;
                console.log(this.pdfOptions);
                console.log(this.faxSelection);
                console.log(this.documentLists);
                if (this.mostRecentFaxRecipientId === this.faxSelection.faxable_id && asFax) {
                    var confirm_1 = this.$mdDialog.confirm()
                        .title("Send another fax to the same recipient?")
                        .textContent('You are about to send another fax to the same recipient. Are you sure you want to do this?')
                        .ariaLabel('Send another fax')
                        .ok('Send Another Fax')
                        .cancel('Cancel');
                    this.$mdDialog.show(confirm_1).then(function () {
                        _this.generatePdfFromDocumentList(asFax);
                    });
                }
                else {
                    this.generatePdfFromDocumentList(asFax);
                }
                if (this.faxSelection.faxable_id && asFax) {
                    this.mostRecentFaxRecipientId = this.faxSelection.faxable_id;
                }
            };
            PatientFaxComponentController.prototype.generatePdfFromDocumentList = function (asFax) {
                var updatedDocumentList = this.convertPhysicianOrders();
                this.pdfer.generatePdfFromDocumentList(this.currentPatient.id, updatedDocumentList, this.pdfOptions, this.faxSelection, asFax);
            };
            PatientFaxComponentController.prototype.convertPhysicianOrders = function () {
                var _this = this;
                var updatedDocumentList = Object.assign({}, this.documentLists);
                updatedDocumentList.physician_orders = this.documentLists.physician_orders.map(function (orderId) {
                    return { orderId: orderId, patientId: _this.patientId };
                });
                return updatedDocumentList;
            };
            PatientFaxComponentController.$inject = ['PdfGenerator', 'FaxGenerator', 'CurrentPatient', 'pdfer', '$mdDialog'];
            return PatientFaxComponentController;
        }());
        angular.module('consolo').component('patientFax', new PatientFaxComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ImplantableDeviceDialogController = /** @class */ (function () {
            function ImplantableDeviceDialogController($mdDialog, ImplantableDeviceService, logger, $q) {
                this.$mdDialog = $mdDialog;
                this.ImplantableDeviceService = ImplantableDeviceService;
                this.logger = logger;
                this.$q = $q;
                this.params = null;
                this.searchOptions = [
                    { type: 'Primary Device Identifier' },
                    { type: 'Unique Device Identifier' },
                    { type: 'Public Device Record Key' }
                ];
                this.searchType = 'Primary Device Identifier';
                this.searchMessage = "Please search for a device using the Unique Device Identifier code.";
            }
            ImplantableDeviceDialogController.prototype.verifyDevice = function (params) {
                var _this = this;
                this.isLoading = true;
                this.searchMessage = 'Searching the Global Unique Device Identification Database for a potential match...';
                var promiseChain = [
                    this.lookupImplantableDevice(params),
                    this.lookupImplantableDeviceSnomed(params)
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.isLoading = false;
                });
            };
            ImplantableDeviceDialogController.prototype.lookupImplantableDevice = function (params) {
                var _this = this;
                return this.ImplantableDeviceService.lookupImplantableDevice(params).then(function (response) {
                    var lookupData = response.data;
                    if (lookupData) {
                        _this.device.lookup_data = lookupData;
                        _this.device.description = lookupData.gudid.device.deviceDescription;
                        if (params.udi) {
                            _this.device.udi = params.udi;
                        }
                    }
                    else {
                        _this.searchMessage = 'Unable to retrieve a matching device from the Global Unique Device Identification Database.';
                    }
                }).catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    _this.searchMessage = 'We apologize, there was an error while trying to ' +
                        'search the Global Unique Device Identification Database for your device. Please retry in a few moments.';
                    console.log(error);
                });
            };
            ImplantableDeviceDialogController.prototype.lookupImplantableDeviceSnomed = function (params) {
                var _this = this;
                return this.ImplantableDeviceService.lookupImplantableDeviceSnomed(params).then(function (response) {
                    var snomedData = response.data;
                    if (snomedData) {
                        _this.device.snomed_data = snomedData;
                        _this.device.snomed_code = snomedData.concepts[0].snomedIdentifier;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            };
            ImplantableDeviceDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                this.$mdDialog.hide(this.device);
            };
            ImplantableDeviceDialogController.$inject = ['$mdDialog', 'ImplantableDeviceService', 'logger', '$q'];
            return ImplantableDeviceDialogController;
        }());
        angular.module('consolo').controller('ImplantableDeviceDialogController', ImplantableDeviceDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ImplantableDevice = consolo.domain.ImplantableDevice;
        var ImplantableDevicesController = /** @class */ (function () {
            function ImplantableDevicesController($state, $location, $mdDialog, $q, Session, logger, CurrentPatient, SectionManagerService, ImplantableDeviceService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.Session = Session;
                this.logger = logger;
                this.CurrentPatient = CurrentPatient;
                this.SectionManagerService = SectionManagerService;
                this.ImplantableDeviceService = ImplantableDeviceService;
                this.devices = [];
                this.patientId = null;
                this.isLoading = false;
                this.initializeData();
            }
            ImplantableDevicesController.prototype.initializeData = function () {
                var _this = this;
                this.isLoading = true;
                this.includeInactive = false;
                this.patientId = this.$state.params.patientId;
                this.currentUser = this.Session.getUser();
                var promiseChain = [
                    this.getImplantableDevices(),
                    this.SectionManagerService.loadPermissions(),
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.canShow = _this.SectionManagerService.permissions.patient_devices_show;
                    _this.canCreate = _this.SectionManagerService.permissions.patient_devices_create;
                    _this.canUpdate = _this.SectionManagerService.permissions.patient_devices_update;
                    _this.canDestroy = _this.SectionManagerService.permissions.patient_devices_destroy;
                    if (_this.$state.current.url === '/new' && _this.canCreate) {
                        _this.deviceDialog(null, 'add', null);
                    }
                    _this.isLoading = false;
                });
            };
            ImplantableDevicesController.prototype.getImplantableDevices = function () {
                var _this = this;
                return this.ImplantableDeviceService.getAllImplantableDevices(this.patientId, this.includeInactive).then(function (response) {
                    _this.devices = response.data;
                    _this.initializeDeviceTable();
                }).catch(function (error) {
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    console.log(error);
                    _this.initializeDeviceTable();
                });
            };
            ImplantableDevicesController.prototype.deviceDialog = function (device, action, $event) {
                var _this = this;
                (device = device
                    ? new ImplantableDevice(device)
                    : new ImplantableDevice({ patient_id: this.patientId })),
                    this.$mdDialog
                        .show({
                        targetEvent: $event,
                        clickOutsideToClose: true,
                        hasBackdrop: true,
                        templateUrl: '/y/templates/patients/implantable-devices/implantable-device-dialog.html',
                        controller: 'ImplantableDeviceDialogController',
                        controllerAs: 'vm',
                        bindToController: true,
                        locals: {
                            device: device,
                            action: action,
                            currentUser: this.currentUser,
                        },
                        fullscreen: true
                    })
                        .then(function (device) {
                        if (device) {
                            _this.isLoading = true;
                            var successAction_1 = action === 'add' ? 'added' : 'updated';
                            var apiCall = action === 'add'
                                ? _this.ImplantableDeviceService.createImplantableDevice(device)
                                : _this.ImplantableDeviceService.updateImplantableDevice(device);
                            apiCall
                                .then(function (response) {
                                var device = response.data;
                                _this.logger.success('You have successfully ' + successAction_1 + ' device: ' + device.udi, 'Success:');
                                _this.getImplantableDevices();
                            })
                                .catch(function (error) {
                                _this.logger.error(error.data.errors.join(', '), 'Error:');
                                _this.isLoading = false;
                            });
                        }
                    });
            };
            ImplantableDevicesController.prototype.initializeDeviceTable = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.deviceLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (data) {
                                    _this.deviceDialog(data, 'view');
                                },
                                conditionalHideFn: function () {
                                    return !_this.canShow;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data) {
                                    _this.deviceDialog(data, 'edit');
                                },
                                conditionalHideFn: function () {
                                    return !_this.canUpdate;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete device: ' + data.udi + '?'))
                                        .then(function () {
                                        _this.ImplantableDeviceService.deleteImplantableDevice(data.id).then(function (response) {
                                            _this.getImplantableDevices();
                                            _this.logger.success('You have successfully removed device: ' + data.udi, 'Success:');
                                        }).catch(function (error) {
                                            _this.logger.error(error.data.errors.join(', '));
                                            console.log(error);
                                        });
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.canDestroy;
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Brand Name',
                            field: 'lookup_data.gudid.device.brandName',
                            type: csg.datatable.ColumnTypes.short_text
                        },
                        {
                            title: 'GMDN PT Name',
                            field: 'lookup_data.gudid.device.gmdnTerms.gmdn[0].gmdnPTName',
                            type: csg.datatable.ColumnTypes.long_text,
                            formatValueFn: function (data) {
                                return data.lookup_data.gudid.device.gmdnTerms.gmdn[0].gmdnPTName;
                            },
                        },
                        {
                            title: 'Implant Date',
                            field: 'implant_date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Device Status',
                            field: 'active',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (data, col, value) {
                                return value ? 'Active' : 'Inactive';
                            },
                        },
                    ],
                };
                this.isLoading = false;
            };
            ImplantableDevicesController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                '$q',
                'SessionService',
                'logger',
                'CurrentPatient',
                'ConsoloPatientSectionManagerService',
                'ImplantableDeviceService'
            ];
            return ImplantableDevicesController;
        }());
        angular.module('consolo').controller('ImplantableDevicesController', ImplantableDevicesController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var IndicatorModalController = /** @class */ (function () {
            function IndicatorModalController(CurrentPatient, options, Session, $q, $state, $mdDialog) {
                this.CurrentPatient = CurrentPatient;
                this.options = options;
                this.Session = Session;
                this.$q = $q;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.diagnosis_templates = this.options.get('diagnosis_templates');
            }
            IndicatorModalController.prototype.$onInit = function () { };
            IndicatorModalController.prototype.close = function () {
                this.$mdDialog.hide();
            };
            IndicatorModalController.prototype.create = function () {
                this.$mdDialog.hide();
            };
            IndicatorModalController.$inject = ['CurrentPatient', 'options', 'SessionService', '$q', '$state', '$mdDialog'];
            return IndicatorModalController;
        }());
        angular.module('consolo').controller('IndicatorModalController', IndicatorModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var IndicatorDataTableService = /** @class */ (function (_super) {
            __extends(IndicatorDataTableService, _super);
            function IndicatorDataTableService($httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.apiSelectUrl = '/api/v1/indicators';
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        var indicators = data.data;
                        indicators.forEach(function (indicator) {
                            // Setup lcd worksheets and icd10s for viewing inside of the datatable
                            var arr = indicator.diagnoses.map(function (d) {
                                return d.name;
                            });
                            var icd10s = indicator.icd10s.map(function (icd) {
                                return icd.code;
                            });
                            indicator.diagnoses_names = arr.join(', ');
                            indicator.icds = icd10s.join(', ');
                        });
                        return indicators;
                    });
                };
                return _this;
            }
            IndicatorDataTableService.$inject = ['$http'];
            return IndicatorDataTableService;
        }(csg.datatable.ApiService));
        services.IndicatorDataTableService = IndicatorDataTableService;
        angular.module('consolo').service('IndicatorDataTableService', IndicatorDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Indicator = consolo.domain.Indicator;
        var LCDWorksheet = consolo.domain.LCDWorksheet;
        var IndicatorDetailController = /** @class */ (function () {
            function IndicatorDetailController(options, $mdDialog, IndicatorsService, $state, patientService, logger, $q, DiagnosesService, Session, SectionManagerService) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.$state = $state;
                this.patientService = patientService;
                this.logger = logger;
                this.$q = $q;
                this.DiagnosesService = DiagnosesService;
                this.Session = Session;
                this.SectionManagerService = SectionManagerService;
                this.readonly = false;
                this.firstIndicator = false;
                this.comorbids = '';
                this.tempIcd10HealthConditions = [];
                this.isLoading = false;
                this.comorbid_list = this.options.get('comorbids');
                this.patientId = this.$state.params['patientId'] ? +this.$state.params['patientId'] : null;
                this.indicatorId = this.$state.params['indicatorId']
                    ? +this.$state.params['indicatorId']
                    : null;
                this.readonly = this.$state.current.name === 'patients.indicators.view' ? true : false;
                this.userId = this.Session.getUser().id;
                this.serviceLine = this.Session.get().agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                this.$onInit();
            }
            IndicatorDetailController.prototype.$onInit = function () {
                this.isLoading = true;
                this.initializeIndicatorTables();
                this.initializePageData();
            };
            IndicatorDetailController.prototype.initializePageData = function () {
                var _this = this;
                var promiseChain = [
                    this.getPatient(),
                    this.setIndicator(),
                    this.getDiagnosisTemplates(),
                    this.getIcdTabularSearch(),
                ];
                this.$q.all(promiseChain).finally(function () {
                    if (_this.firstIndicator) {
                        var tempDate = new Date();
                        _this.indicator.effective_date = _this.patient.admission_date
                            ? _this.patient.admission_date
                            : new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
                        _this.logger.persistentToast('The effective date has been automatically set to the patients admission date, since this is the patients first indicator.', 'Initial Indicator Notice:', 'info');
                    }
                    _this.isLoading = false;
                });
            };
            IndicatorDetailController.prototype.isNewIndicator = function () {
                if (this.$state.$current.includes['patients.indicators.new']) {
                    return true;
                }
                else {
                    return false;
                }
            };
            IndicatorDetailController.prototype.getPatient = function () {
                var _this = this;
                return this.patientService.get(this.patientId).then(function (patientObj) {
                    _this.patient = patientObj.data;
                });
            };
            IndicatorDetailController.prototype.setIndicator = function () {
                var _this = this;
                var indicatorCall;
                if (this.isNewIndicator()) {
                    indicatorCall = this.getCurrentIndicator(this.patientId);
                }
                else {
                    indicatorCall = this.getIndicator(this.indicatorId, this.patientId);
                }
                indicatorCall.then(function () {
                    if (_this.indicator) {
                        _this.formatIndicator();
                    }
                });
            };
            IndicatorDetailController.prototype.getIndicator = function (indicatorId, patientId) {
                var _this = this;
                return this.IndicatorsService.getSingleIndicator(indicatorId, patientId)
                    .then(function (response) {
                    _this.indicator = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Error:');
                });
            };
            IndicatorDetailController.prototype.getCurrentIndicator = function (patientId) {
                var _this = this;
                return this.IndicatorsService.getIndicators(patientId).then(function (response) {
                    var patientIndicators = response.data;
                    var currentIndicator = patientIndicators[0];
                    if (currentIndicator) {
                        delete currentIndicator['id'];
                        delete currentIndicator['user_name'];
                        delete currentIndicator['signatures'];
                        currentIndicator.effective_date = new Date(new Date().toDateString());
                        currentIndicator.user_id = _this.userId;
                    }
                    else {
                        _this.firstIndicator = true;
                        currentIndicator = new Indicator();
                        currentIndicator.user_id = _this.userId;
                        currentIndicator.patient_id = _this.patientId;
                    }
                    _this.indicator = currentIndicator;
                });
            };
            IndicatorDetailController.prototype.formatIndicator = function () {
                if (this.indicator.icd10_health_conditions.length > 0) {
                    this.tempIcd10HealthConditions = this.indicator.icd10_health_conditions.slice();
                }
                if (this.indicator.diagnoses.length > 0) {
                    this.formatLCDWorksheets(this.indicator.diagnoses);
                }
            };
            IndicatorDetailController.prototype.getIcdTabularSearch = function () {
                var _this = this;
                return this.IndicatorsService.getIcdTabularSearch()
                    .then(function (response) {
                    _this.icdTabularSearchTree = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Tabular Search Error:');
                });
            };
            IndicatorDetailController.prototype.updateIndicator = function () {
                var _this = this;
                this.isLoading = true;
                this.DiagnosesService.setDiagnosesForUpdate(this.indicator, this.tempIcd10HealthConditions).then(function () {
                    _this.IndicatorsService.updateIndicator(_this.indicatorId, _this.patientId, _this.indicator)
                        .then(function () {
                        _this.SectionManagerService.refreshMenu();
                        _this.logger.success('Indicator was successfully updated.', 'Success');
                        _this.getIndicator(_this.indicatorId, _this.patientId).finally(function () {
                            _this.isLoading = false;
                            _this.formatIndicator();
                        });
                    })
                        .catch(function (error) {
                        _this.logger.error(error.data.errors[0], 'Error Updating Indicator');
                        _this.isLoading = false;
                    });
                });
            };
            IndicatorDetailController.prototype.addIndicator = function () {
                var _this = this;
                this.isLoading = true;
                var newIndicator = new Indicator(this.indicator);
                this.IndicatorsService.addIndicator(this.patientId, newIndicator)
                    .then(function (response) {
                    var newIndicator = response.data;
                    _this.indicator = newIndicator;
                    _this.indicatorId = _this.indicator.id;
                    _this.formatIndicator();
                    _this.indicator.diagnoses.forEach(function (LCDWorksheet) {
                        LCDWorksheet.icd10s.forEach(function (lcdIcd10) {
                            var index = _this.indicator.icd10s.findIndex(function (element) { return element.id === lcdIcd10.id; });
                            _this.indicator.icd10_health_conditions[index].diagnosis_id = LCDWorksheet.id;
                        });
                    });
                    _this.IndicatorsService.updateIndicator(_this.indicatorId, _this.patientId, _this.indicator)
                        .then(function () {
                        _this.SectionManagerService.refreshMenu();
                        _this.$state.go('patients.indicators', {
                            patientId: _this.patientId,
                        });
                    })
                        .catch(function (error) {
                        _this.logger.error(error.data, 'Error Updating Indicator:');
                        _this.isLoading = false;
                    });
                })
                    .catch(function (error) {
                    _this.logger.error(error.data, 'Error Adding Indicator:');
                    _this.isLoading = false;
                });
            };
            IndicatorDetailController.prototype.getDiagnosisTemplates = function () {
                var _this = this;
                return this.IndicatorsService.getDiagnosisTemplates()
                    .then(function (response) {
                    _this.diagnosis_templates = response.data;
                })
                    .catch(function (error) {
                    _this.logger.error(error, 'Error');
                });
            };
            IndicatorDetailController.prototype.formatLCDWorksheets = function (lcdWorksheets) {
                lcdWorksheets.map(function (lcdWorksheet) {
                    lcdWorksheet.diagnosis_template_id = lcdWorksheet.diagnosis_template.id;
                });
            };
            IndicatorDetailController.prototype.addComorbid = function () {
                if (this.indicator.other_comorbid) {
                    this.indicator.other_comorbid =
                        this.indicator.other_comorbid + ', ' + this.comorbidSelection;
                }
                else {
                    this.indicator.other_comorbid = this.comorbidSelection;
                }
            };
            IndicatorDetailController.prototype.initializeIndicatorTables = function () {
                var _this = this;
                this.lcdWorksheetsLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    // this.exportIndicatorsToCSV(this.patientId);
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Symptom Descriptions',
                                    field: 'symptom_descriptions',
                                    size: 1,
                                },
                            ],
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (data, item) {
                                    _this.showLcdWorksheetModal(data, true);
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.showLcdWorksheetModal(data, false);
                                },
                                conditionalHideFn: function () {
                                    return _this.readonly;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(_this.generateConfirmationRequest('Are you sure you want to delete ' + data.name + '?'))
                                        .then(function () {
                                        var tempDiagnosesList = _this.indicator.diagnoses.slice();
                                        var diagnosisId = data.id;
                                        tempDiagnosesList.splice(_this.indicator.diagnoses.findIndex(function (element) { return element.name === data.name; }), 1);
                                        _this.indicator.diagnoses = tempDiagnosesList;
                                        _this.indicator.icd10_health_conditions.map(function (icd10) {
                                            if (icd10.diagnosis_id === diagnosisId) {
                                                return (icd10.diagnosis_id = null);
                                            }
                                        });
                                    });
                                    return true;
                                },
                                conditionalHideFn: function () {
                                    return _this.readonly;
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Worksheet', field: 'name' },
                        { title: 'Narrative', field: 'narrative' },
                        {
                            title: 'Diagnosis Codes',
                            field: 'icd10_health_conditions',
                            formatValueFn: function (data, col, value) {
                                return value.map(function (icd10) {
                                    return ' ' + icd10.code;
                                });
                            },
                        },
                    ],
                };
            };
            IndicatorDetailController.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            IndicatorDetailController.prototype.close = function () {
                this.$mdDialog.hide();
            };
            IndicatorDetailController.prototype.create = function () {
                this.$mdDialog.hide();
            };
            IndicatorDetailController.prototype.showDiagnosisSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    locals: {
                        icdTabularSearchTree: this.icdTabularSearchTree,
                        indicator: this.indicator,
                    },
                })
                    .then(function (selectedIcd10) {
                    _this.DiagnosesService.checkAndAddValidIcd(selectedIcd10, _this.indicator, _this.tempIcd10HealthConditions, _this.isLoading);
                });
            };
            IndicatorDetailController.prototype.rankDiagnoses = function (diagnosisIndex, direction) {
                this.DiagnosesService.rankDiagnoses(diagnosisIndex, direction, this.indicator, this.tempIcd10HealthConditions);
            };
            IndicatorDetailController.prototype.deleteDiagnosis = function (icd10, index) {
                this.DiagnosesService.deleteDiagnosis(icd10, index, this.indicator, this.tempIcd10HealthConditions).then(function (resolve, reject) { });
            };
            IndicatorDetailController.prototype.showLcdSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/lcd-worksheet-search-dialog.html',
                    controller: 'LcdWorksheetSearchDialogController as vm',
                    bindToController: true,
                    locals: {
                        diagnosis_templates: this.diagnosis_templates,
                    },
                })
                    .then(function (diagnosisTemplate) {
                    var lcdWorksheet = new LCDWorksheet(diagnosisTemplate);
                    _this.showLcdWorksheetModal(lcdWorksheet, false);
                });
            };
            IndicatorDetailController.prototype.showLcdWorksheetModal = function (lcdWorksheet, readOnly) {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/lcd-worksheet-dialog.html',
                    controller: 'LcdWorksheetDialogController as vm',
                    bindToController: true,
                    locals: {
                        lcdWorksheet: lcdWorksheet,
                        readOnly: readOnly,
                        indicator: this.indicator,
                        icdTabularSearchTree: this.icdTabularSearchTree,
                    },
                })
                    .then(function (lcdWorksheet) {
                    if (lcdWorksheet) {
                        if (lcdWorksheet.icd10_health_conditions.length > 0) {
                            lcdWorksheet.icd10_health_conditions.forEach(function (icd10, index) {
                                var indexPosition = _this.icd10IndexOnIndicator(icd10);
                                if (indexPosition === -1) {
                                    var selectedIcd10 = _this.formatIcd10(icd10, lcdWorksheet);
                                    if (lcdWorksheet.icd10_health_conditions.length > 0) {
                                        _this.DiagnosesService.checkAndAddValidIcd(selectedIcd10, _this.indicator, _this.tempIcd10HealthConditions);
                                    }
                                }
                                else {
                                    // If no diagnosis id add one to existing code
                                    if (_this.indicator.icd10_health_conditions[indexPosition].diagnosis_id === null) {
                                        _this.indicator.icd10_health_conditions[indexPosition].diagnosis_id =
                                            lcdWorksheet.id;
                                    }
                                    // else do nothing
                                }
                            });
                        }
                        _this.addLCDWorksheetToIndicator(lcdWorksheet);
                    }
                });
            };
            IndicatorDetailController.prototype.formatIcd10 = function (icd10, lcdWorksheet) {
                var selectedIcd10 = JSON.parse(angular.toJson(icd10));
                selectedIcd10.diagnosis_id = lcdWorksheet.id;
                selectedIcd10.icd10_id = selectedIcd10.icd10_id
                    ? selectedIcd10.icd10_id
                    : lcdWorksheet.icd10s[0].id;
                delete selectedIcd10.id;
                return selectedIcd10;
            };
            IndicatorDetailController.prototype.icd10IndexOnIndicator = function (icd10) {
                return this.indicator.icd10_health_conditions.findIndex(function (indicatorIcd10) {
                    return indicatorIcd10.code === icd10.code;
                });
            };
            IndicatorDetailController.prototype.addLCDWorksheetToIndicator = function (lcdWorksheet) {
                var tempDiagnosesList = this.indicator.diagnoses.slice();
                var lcdWorksheetIndex = this.indicator.diagnoses
                    .map(function (diagnosis) {
                    return diagnosis.diagnosis_template_id;
                })
                    .indexOf(lcdWorksheet.diagnosis_template_id);
                if (lcdWorksheetIndex !== -1) {
                    tempDiagnosesList[lcdWorksheetIndex] = lcdWorksheet;
                }
                else {
                    tempDiagnosesList.push(lcdWorksheet);
                }
                this.indicator.diagnoses = tempDiagnosesList;
            };
            IndicatorDetailController.$inject = [
                'options',
                '$mdDialog',
                'IndicatorsService',
                '$state',
                'patientService',
                'logger',
                '$q',
                'DiagnosesService',
                'SessionService',
                'ConsoloPatientSectionManagerService',
            ];
            return IndicatorDetailController;
        }());
        angular.module('consolo').controller('IndicatorDetailController', IndicatorDetailController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var IndicatorPdfOptionsController = /** @class */ (function () {
            function IndicatorPdfOptionsController(PdfGenerator, $mdDialog) {
                this.PdfGenerator = PdfGenerator;
                this.$mdDialog = $mdDialog;
                this.setPdfOptions();
            }
            IndicatorPdfOptionsController.prototype.setPdfOptions = function () {
                this.PdfGenerator.showAllOptions = false;
                this.PdfGenerator.pdfOptionsToShow = {
                    include_last_page_signature_line: true,
                    include_allergies: true,
                    include_footer_signature_lines: true,
                };
            };
            IndicatorPdfOptionsController.prototype.print = function () {
                this.$mdDialog.hide(this.pdf_options);
            };
            IndicatorPdfOptionsController.prototype.closeDialog = function () {
                this.$mdDialog.hide('cancel');
            };
            IndicatorPdfOptionsController.$inject = ['PdfGenerator', '$mdDialog'];
            return IndicatorPdfOptionsController;
        }());
        angular
            .module('consolo')
            .controller('IndicatorPdfOptionsController', IndicatorPdfOptionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var IndicatorPrintService = /** @class */ (function () {
            function IndicatorPrintService(jsPDF, $q, $filter) {
                this.jsPDF = jsPDF;
                this.$q = $q;
                this.$filter = $filter;
            }
            IndicatorPrintService.prototype.openIndicatorPdf = function (patient, indicator, currentUser, pdfSettings) {
                var indicatorDocument = this.buildIndicatorTemplate(patient, indicator, this.docDefinition(indicator.user_name, patient, indicator), currentUser, pdfSettings);
                this.jsPDF.showPdf(indicatorDocument);
            };
            IndicatorPrintService.prototype.downloadIndicatorPdf = function (patient, indicator, currentUser, pdfSettings) {
                var indicatorDocument = this.buildIndicatorTemplate(patient, indicator, this.docDefinition(indicator.user_name, patient, indicator), currentUser, pdfSettings);
                this.jsPDF.downloadPdf(indicatorDocument, this.generateDefaultFileName(patient, indicator));
            };
            IndicatorPrintService.prototype.buildIndicatorTemplate = function (patient, indicator, docDefinition, currentUser, pdfSettings) {
                this.setPatientHeader(docDefinition, indicator, patient);
                if (pdfSettings && pdfSettings['include_allergies']) {
                    this.setPatientallergies(docDefinition, patient);
                }
                this.setLcdWorksheets(docDefinition, indicator);
                this.setDiagnoses(docDefinition, indicator);
                this.setAdditionalNotes(docDefinition, indicator);
                this.setSignatures(docDefinition, indicator);
                if (pdfSettings && pdfSettings['include_last_page_signature_line']) {
                    this.setLastPageSignature(docDefinition);
                }
                this.jsPDF.addFooter(docDefinition, pdfSettings);
                return docDefinition;
            };
            IndicatorPrintService.prototype.setPatientHeader = function (docDefinition, indicator, patient) {
                docDefinition.header.push({
                    layout: 'noBorders',
                    margin: [30, 30, 30, 30],
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                            [
                                [
                                    {
                                        image: 'consoloNameLogo',
                                        width: 100,
                                        alignment: 'left',
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: [
                                            { text: 'Patient Name: \n', style: 'headerLabel' },
                                            { text: patient.name + '\n', style: 'headerField' },
                                            { text: 'DOB: ', style: 'headerLabel' },
                                            {
                                                text: this.jsPDF.dateFilter(patient.dob_display) + '\n',
                                                style: 'headerField',
                                            },
                                        ],
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: [
                                            { text: 'ID: ', style: 'headerLabel' },
                                            {
                                                text: patient.id + '\n',
                                                style: 'headerField',
                                            },
                                            { text: 'MRN: ', style: 'headerLabel' },
                                            {
                                                text: (patient.mrn ? patient.mrn : 'N/A') + '\n',
                                                style: 'headerField',
                                            },
                                            {
                                                text: 'Status: ',
                                                style: 'headerLabel',
                                            },
                                            { text: patient.care_status + '\n', style: 'headerField' },
                                        ],
                                    },
                                ],
                                [
                                    {
                                        alignment: 'left',
                                        text: [
                                            { text: 'Document Type: \n', style: 'headerLabel' },
                                            { text: 'Clinical Indicator \n', style: 'headerField' },
                                            { text: 'Office: ', style: 'headerLabel' },
                                            { text: patient.office.name + '\n', style: 'headerField' },
                                        ],
                                    },
                                ],
                            ],
                        ],
                    },
                });
                docDefinition.header.push({
                    margin: [30, -15, 0, 0],
                    text: 'Indicator effective on ' +
                        this.jsPDF.dateFilter(indicator.effective_date) +
                        ' for ' +
                        patient.name,
                    style: 'pageHeading',
                    alignment: 'left',
                });
            };
            IndicatorPrintService.prototype.setPageHeading = function (docDefinition, indicator, patient) {
                docDefinition.content.push({
                    text: 'Indicator effective on ' +
                        this.jsPDF.dateFilter(indicator.effective_date) +
                        ' for ' +
                        patient.name,
                    style: 'pageHeading',
                    alignment: 'left',
                });
            };
            IndicatorPrintService.prototype.setLcdWorksheets = function (docDefinition, indicator) {
                var _this = this;
                docDefinition.content.push({
                    text: 'LCD Worksheets',
                    style: 'subsectionHeader',
                    margin: [0, 0, 0, 5],
                });
                docDefinition.content.push({
                    canvas: [angular.copy(this.jsPDF.getUnderline())],
                    margin: [0, 0, 0, 5],
                });
                if (indicator.diagnoses.length > 0) {
                    indicator.diagnoses.forEach(function (diagnosis, index) {
                        var diagnosisCodes = diagnosis.icd10s
                            .map(function (icd10) {
                            return ' ' + icd10.code;
                        })
                            .toString();
                        var symptoms = diagnosis.symptom_descriptions.toString();
                        docDefinition.content.push({
                            text: [
                                { text: 'Worksheet: ', style: 'formInputBold' },
                                diagnosis.name + '\n\n',
                                { text: 'Narrative: ', style: 'formInputBold' },
                                diagnosis.narrative + '\n\n',
                                { text: 'Diagnosis Codes: ', style: 'formInputBold' },
                                diagnosisCodes + '\n\n',
                                { text: 'Symptoms: ', style: 'formInputBold' },
                                symptoms,
                            ],
                            margin: [0, 0, 0, 10],
                        });
                        docDefinition.content.push({
                            canvas: [angular.copy(_this.jsPDF.getUnderline())],
                            margin: [0, 0, 0, 5],
                        });
                    });
                }
                else {
                    docDefinition.content.push({
                        text: 'This clinical indicator currently has no documented LCD Worksheets.',
                        style: 'formInput',
                    });
                }
            };
            IndicatorPrintService.prototype.setDiagnoses = function (docDefinition, indicator) {
                docDefinition.content.push({
                    text: 'Diagnoses',
                    style: 'subsectionHeader',
                    margin: [0, 15, 0, 5],
                });
                if (indicator.icd10_health_conditions.length > 0) {
                    var diagnosisCodeTable_1 = {
                        table: {
                            headerRows: 1,
                            widths: ['*', '*', 'auto', '*'],
                            body: [['Priority', 'ICD Code', 'Description', 'LCD Worksheet']],
                        },
                    };
                    indicator.icd10_health_conditions.forEach(function (icd10, index) {
                        var hasLCDWorksheet = !icd10.diagnosis_id ? 'No' : 'Yes';
                        diagnosisCodeTable_1.table.body.push([
                            icd10.label,
                            icd10.code,
                            icd10.description,
                            hasLCDWorksheet,
                        ]);
                    });
                    docDefinition.content.push(diagnosisCodeTable_1);
                }
                else {
                    docDefinition.content.push({
                        text: 'This clinical indicator currently has no documented Diagnoses.',
                        style: 'formInput',
                    });
                }
            };
            IndicatorPrintService.prototype.setAdditionalNotes = function (docDefinition, indicator) {
                docDefinition.content.push({
                    text: 'Additional Notes',
                    style: 'subsectionHeader',
                });
                docDefinition.content.push({
                    canvas: [angular.copy(this.jsPDF.getUnderline())],
                    margin: [0, 0, 0, 0],
                });
                // Treatments To Date
                var treatmentsToDate = indicator.treatments_to_date
                    ? indicator.treatments_to_date
                    : 'This clinical indicator currently has no documented Treatments to Date.';
                docDefinition.content.push([
                    { text: 'Treatments To Date: ', style: 'formInputHeader' },
                    { text: treatmentsToDate, style: 'formInput' },
                ]);
                // Other Health Problems
                var otherHealthProblems = indicator.other_health_problems
                    ? indicator.treatments_to_date
                    : 'This clinical indicator currently has no Other Health Problems documented.';
                docDefinition.content.push({ text: 'Other Health Problems: ', style: 'formInputHeader' }, { text: otherHealthProblems, style: 'formInput' });
                // Other Comorbid
                var otherComorbid = indicator.other_comorbid
                    ? indicator.treatments_to_date
                    : 'This clinical indicator currently has no Other Comorbid documented.';
                docDefinition.content.push({ text: 'Other Comorbid: ', style: 'formInputHeader' }, { text: otherComorbid, style: 'formInput' });
            };
            IndicatorPrintService.prototype.setSignatures = function (docDefinition, indicator) {
                var _this = this;
                docDefinition.content.push({
                    text: 'Signatures',
                    style: 'subsectionHeader',
                });
                docDefinition.content.push({
                    canvas: [angular.copy(this.jsPDF.getUnderline())],
                    margin: [0, 0, 0, 5],
                });
                if (indicator.signatures.length > 0) {
                    indicator.signatures.forEach(function (signature) {
                        docDefinition.content.push({
                            text: signature.static_role +
                                ' ' +
                                signature.static_name +
                                ' (' +
                                signature.signed_label +
                                ') signed on ' +
                                _this.jsPDF.dateFilter(signature.signed_date) +
                                '.  Recorded by ' +
                                signature.recorded_by_static_name +
                                ' on ' +
                                _this.jsPDF.dateFilter(signature.created_at) +
                                '.',
                            style: 'formInput',
                        });
                    });
                }
                else {
                    docDefinition.content.push({
                        text: 'This clinical indicator currently has no signatures present.',
                        style: 'formInput',
                    });
                }
            };
            IndicatorPrintService.prototype.docDefinition = function (author, patient, indicator) {
                return {
                    header: [],
                    content: [],
                    footer: [],
                    pageSize: 'LETTER',
                    pageMargins: [30, 150, 30, 30],
                    info: {
                        title: this.generateDefaultFileName(patient, indicator),
                        author: author,
                        producer: 'Consolo Services Group',
                    },
                    defaultStyle: {
                        fontSize: 12,
                        font: 'Roboto',
                    },
                    images: {
                        consoloLogo: this.jsPDF.consoloLogoUri,
                        consoloNameLogo: this.jsPDF.consoloLogoNameUri,
                    },
                    styles: {
                        pageHeading: {
                            fontSize: 20,
                            bold: true,
                        },
                        subsectionHeader: {
                            fontSize: 16,
                            bold: true,
                            margin: [0, 16, 0, 0],
                        },
                        headerLabel: {
                            fontSize: 11,
                            bold: true,
                        },
                        headerField: {
                            fontSize: 13,
                        },
                        formInputHeader: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 8, 0, 0],
                        },
                        formInput: {
                            fontSize: 12,
                        },
                        formInputBold: {
                            fontSize: 12,
                            bold: true,
                        },
                    },
                };
            };
            IndicatorPrintService.prototype.generateDefaultFileName = function (patient, indicator) {
                var fileName = patient.name
                    .trim()
                    .replace(/,/, '')
                    .replace(/ /, '_');
                fileName += '_clinical_indicator_' + indicator.id;
                fileName += '_effective_' + this.jsPDF.dateFilter(indicator.effective_date);
                return fileName;
            };
            IndicatorPrintService.prototype.patientAddress = function (patient) {
                var address = '';
                if (patient.current_address) {
                    address =
                        patient.current_address.address_1 +
                            '\n' +
                            patient.current_address.city +
                            ', ' +
                            patient.current_address.state +
                            ' ' +
                            patient.current_address.zipcode;
                }
                return address;
            };
            IndicatorPrintService.prototype.setPatientallergies = function (docDefinition, patient) {
                docDefinition.content.push({
                    text: 'Allergies',
                    style: 'subsectionHeader',
                    margin: [0, 0, 0, 5],
                });
                docDefinition.content.push({
                    canvas: [angular.copy(this.jsPDF.getUnderline())],
                    margin: [0, 0, 0, 5],
                });
                docDefinition.content.push({
                    text: patient.allergies,
                    margin: [0, 0, 0, 10],
                });
            };
            IndicatorPrintService.prototype.setLastPageSignature = function (docDefinition) {
                docDefinition.content.push({
                    columns: [
                        { width: 220, text: '' },
                        [
                            {
                                text: 'Signature: ',
                                style: 'formInputBold',
                                margin: [0, 50, 0, 0],
                            },
                            {
                                canvas: [
                                    angular.copy({
                                        type: 'line',
                                        x1: 0,
                                        y1: 0,
                                        x2: 250,
                                        y2: 0,
                                        lineWidth: 1,
                                        lineColor: '#757575',
                                        lineCap: 'round',
                                    }),
                                ],
                                margin: [60, 0, 0, 20],
                            },
                            {
                                text: 'Date: ',
                                style: 'formInputBold',
                            },
                            {
                                canvas: [
                                    angular.copy({
                                        type: 'line',
                                        x1: 0,
                                        y1: 0,
                                        x2: 278,
                                        y2: 0,
                                        lineWidth: 1,
                                        lineColor: '#757575',
                                        lineCap: 'round',
                                    }),
                                ],
                                margin: [32, 0, 0, 5],
                            },
                        ],
                    ],
                });
            };
            IndicatorPrintService.$inject = ['jsPDF', '$q', '$filter'];
            return IndicatorPrintService;
        }());
        common.IndicatorPrintService = IndicatorPrintService;
        angular.module('consolo').service('IndicatorPrintService', IndicatorPrintService);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var IndicatorFilters = consolo.domain.IndicatorFilters;
        var IndicatorsController = /** @class */ (function () {
            function IndicatorsController($state, $location, $mdDialog, patientService, Session, logger, IndicatorsService, IndicatorPrintService, IndicatorDataTableService, $interval, PdfGenerator, SectionManagerService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.patientService = patientService;
                this.Session = Session;
                this.logger = logger;
                this.IndicatorsService = IndicatorsService;
                this.IndicatorPrintService = IndicatorPrintService;
                this.IndicatorDataTableService = IndicatorDataTableService;
                this.$interval = $interval;
                this.PdfGenerator = PdfGenerator;
                this.SectionManagerService = SectionManagerService;
                this.patientId = this.$state.params.patientId;
                this.serviceLine = this.Session.get().agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
                this.$onInit();
            }
            IndicatorsController.prototype.$onInit = function () {
                this.isLoading = true;
                this.currentIndicatorisLoading = true;
                this.getPatient();
                this.getDefaultPhysician(this.patientId);
                this.getAllIndicators();
                this.initializePatientIndicatorTable();
            };
            IndicatorsController.prototype.getPatient = function () {
                var _this = this;
                this.patientService.getExtra(this.patientId).then(function (patientObj) {
                    _this.patient = patientObj.data;
                });
            };
            IndicatorsController.prototype.getDefaultPhysician = function (patientId) {
                var _this = this;
                this.patientService
                    .getDefaultPhysician(patientId)
                    .then(function (response) {
                    _this.defaultPhysician = response.data;
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            IndicatorsController.prototype.viewIndicator = function (indicator) {
                this.$state.go('patients.indicators.view', {
                    patientId: this.patientId,
                    indicatorId: indicator.id,
                });
            };
            IndicatorsController.prototype.editIndicator = function (indicator) {
                this.$state.go('patients.indicators.edit', {
                    patientId: this.patientId,
                    indicatorId: indicator.id,
                });
            };
            IndicatorsController.prototype.newIndicator = function () {
                this.$state.go('patients.indicators.new', {
                    patientId: this.patientId,
                });
            };
            IndicatorsController.prototype.setCurrentIndicator = function () {
                var _this = this;
                return this.IndicatorsService.getCurrentIndicator(this.patientId).then(function (indicator) {
                    var currentIndicator = indicator;
                    if (Object.keys(currentIndicator).length) {
                        var arr = currentIndicator.diagnoses.map(function (d) {
                            return d.name;
                        });
                        var icd10s = currentIndicator.icd10s.map(function (icd) {
                            return icd.code;
                        });
                        currentIndicator.diagnoses_names = arr.join(', ');
                        currentIndicator.icds = icd10s.join(', ');
                        _this.currentPatientIndicator = currentIndicator;
                    }
                    else {
                        _this.currentPatientIndicator = null;
                    }
                });
            };
            IndicatorsController.prototype.deletePatientIndicator = function (indicatorId, patientId) {
                var _this = this;
                this.IndicatorsService.deleteIndicator(indicatorId, patientId)
                    .then(function (response) {
                    _this.SectionManagerService.refreshMenu();
                    _this.getAllIndicators();
                })
                    .catch(function (error) {
                    _this.logger.error(error, 'Error:');
                });
            };
            IndicatorsController.prototype.currentUser = function () {
                return this.Session.getUser();
            };
            IndicatorsController.prototype.initializePatientIndicatorTable = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.patientIndicatorsLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    // this.exportIndicatorsToCSV(this.patientId);
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View Indicator',
                                icon: 'remove_red_eye',
                                callbackFn: function (data, item) {
                                    _this.$state.go('patients.indicators.view', {
                                        patientId: _this.patientId,
                                        indicatorId: data.id,
                                    });
                                },
                            },
                            {
                                label: 'Edit Indicator',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.$state.go('patients.indicators.edit', {
                                        patientId: _this.patientId,
                                        indicatorId: data.id,
                                    });
                                },
                                conditionalHideFn: function (data, item) {
                                    return data.signatures.length > 0;
                                },
                            },
                            {
                                label: 'Add Your Signature',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.signatureDialog('personal', data, _this.currentUser().id, _this.patientId);
                                },
                            },
                            {
                                label: 'Add Physician Signature',
                                icon: 'edit',
                                callbackFn: function (data, item) {
                                    _this.signatureDialog('physician', data, _this.defaultPhysician.id, _this.patientId);
                                },
                                conditionalHideFn: function (data, item) {
                                    return _this.currentUser().physician || !_this.defaultPhysician;
                                },
                            },
                            {
                                label: 'Print Indicator',
                                icon: 'print',
                                callbackFn: function (data, item) {
                                    _this.printIndicator(data);
                                },
                            },
                            {
                                label: 'Delete Indicator',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.deleteIndicatorDialog(data);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Created By', field: 'user_name' },
                        { title: 'Effective Date', field: 'effective_date', type: 'date' },
                        { title: 'ICD10', field: 'icds' },
                        {
                            title: 'Is Locked?',
                            field: 'signatures',
                            type: csg.datatable.ColumnTypes.boolean,
                            formatValueFn: function (indicator) {
                                return indicator.signatures.length > 0 ? 'Yes' : 'No';
                            },
                        },
                    ],
                };
                if (!this.isPalliative) {
                    this.patientIndicatorsLayout.columns.unshift({
                        title: 'LCD Worksheets',
                        field: 'diagnoses_names',
                    });
                }
            };
            IndicatorsController.prototype.printIndicator = function (indicator) {
                var _this = this;
                this.showPdfOptions().then(function (pdfSettings) {
                    if (pdfSettings !== 'cancel') {
                        _this.IndicatorPrintService.openIndicatorPdf(_this.patient, indicator, _this.currentUser().name, pdfSettings);
                    }
                });
            };
            IndicatorsController.prototype.diagnosisDialog = function ($event, indicator) {
                this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicators-dialog.html',
                    controller: 'IndicatorsDialogController as vm',
                    locals: {
                        indicator: indicator,
                    },
                });
            };
            IndicatorsController.prototype.signatureDialog = function (signatureType, indicator, userId, patientId) {
                var _this = this;
                this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/indicator-signature-dialog.html',
                    controller: 'IndicatorSignatureDialogController as vm',
                    locals: {
                        indicator: indicator,
                        userId: userId,
                        patientId: patientId,
                        signatureType: signatureType,
                    },
                })
                    .then(function (response) {
                    _this.isLoading = true;
                    var signatureObj = {
                        user_id: _this.Session.getUser().id,
                        effective_date: response.effective_date,
                        physician_id: response.signature_type === 'physician' ? _this.defaultPhysician.id : null,
                    };
                    var signatureCall = response.signature_type === 'personal'
                        ? _this.IndicatorsService.addCurrentUserSignature(indicator.id, patientId, signatureObj)
                        : _this.IndicatorsService.addPhysicianSignature(indicator.id, patientId, signatureObj);
                    signatureCall
                        .then(function (response) {
                        _this.logger.success('You have successfully applied a signature for the Indicator dated effective ' +
                            response.data.effective_date);
                        _this.getAllIndicators();
                    })
                        .catch(function (error) {
                        _this.logger.error(error, 'Error:');
                    });
                });
            };
            IndicatorsController.prototype.deleteIndicatorDialog = function (data) {
                var _this = this;
                var confirmation = this.$mdDialog
                    .confirm()
                    .title('Delete Indicator')
                    .textContent('Are you sure you wish to delete this indicator?')
                    .ok('Yes')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirmation).then(function (response) {
                    _this.isLoading = true;
                    _this.deletePatientIndicator(data.id, data.patient_id);
                });
            };
            IndicatorsController.prototype.openMenu = function ($mdMenu, $event) {
                $mdMenu.open($event);
                $event.stopPropagation();
            };
            IndicatorsController.prototype.getIndicatorHistory = function () {
                var _this = this;
                this.isLoading = true;
                this.apiInterval = this.$interval(function () {
                    if (_this.IndicatorDataTableService.tableControl &&
                        _this.IndicatorDataTableService.tableControl.loadSelectData) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.IndicatorDataTableService.tableControl.loadSelectData().finally(function () {
                            _this.isLoading = false;
                        });
                    }
                }, 500, 20);
            };
            IndicatorsController.prototype.getAllIndicators = function () {
                var _this = this;
                this.filters = new IndicatorFilters(this.patientId);
                this.IndicatorDataTableService.params = this.filters;
                this.currentIndicatorisLoading = true;
                this.setCurrentIndicator().then(function (response) {
                    _this.currentIndicatorisLoading = false;
                    _this.getIndicatorHistory();
                });
            };
            IndicatorsController.prototype.showPdfOptions = function () {
                var url = "'/y/templates/common/services/pdf-generator/pdf-options.html'";
                var dialogOptions = {
                    template: '<md-dialog flex="50" aria-label="PDF Options">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'IndicatorPdfOptionsController as vm',
                    hasBackdrop: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                };
                return this.$mdDialog.show(dialogOptions);
            };
            IndicatorsController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                'patientService',
                'SessionService',
                'logger',
                'IndicatorsService',
                'IndicatorPrintService',
                'IndicatorDataTableService',
                '$interval',
                'PdfGenerator',
                'ConsoloPatientSectionManagerService',
            ];
            return IndicatorsController;
        }());
        angular.module('consolo').controller('IndicatorsController', IndicatorsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var PatientCardComponent = /** @class */ (function () {
            //  = {
            //   id: 2,
            //   name: 'My Patient',
            //   first_name: 'John',
            //   last_name: 'Doe',
            //   current_address: <domain.IAddress>{},
            //   picture_url: '',
            //   picture_thumb_url: '/not_provided.jpg',
            //   agency_id: 123,
            //   dob_display: '02/14/1979',
            //   gender: 'M',
            //   office: <domain.IOffice>{},
            //   misc_data: '',
            //   care_status: 'Active',
            //   mrn: '1231123',
            // };
            function PatientCardComponent() {
                if (!this.patient.picture_thumb_url) {
                    this.patient.picture_thumb_url = '/not_provided.jpg';
                }
                // console.log(this.patient);
            }
            return PatientCardComponent;
        }());
        angular.module('consolo').component('csgPatientCard', {
            templateUrl: '/y/templates/patients/search/patient-card.component.html',
            controller: PatientCardComponent,
            bindings: {
                patient: '<',
            },
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var PatientSearchDataTableService = /** @class */ (function (_super) {
            __extends(PatientSearchDataTableService, _super);
            function PatientSearchDataTableService($httpService) {
                var _this = _super.call(this, $httpService) || this;
                _this.$httpService = $httpService;
                _this.params = new consolo.domain.PatientSearchFilters();
                _this.params.page = 1;
                _this.data = [];
                _this.apiSelectUrl = '/api/v2/patient_search';
                _this.select = function () {
                    return _super.prototype.select.call(_this).then(function (data) {
                        for (var _i = 0, _a = data.data; _i < _a.length; _i++) {
                            var row = _a[_i];
                            if (row.care_status === 'Active') {
                                row.care_status_badge = 'active';
                            }
                            else if (row.care_status === 'Cancelled') {
                                row.care_status_badge = 'warn';
                            }
                            else if (row.care_status === 'Deceased') {
                                row.care_status_badge = '';
                            }
                            else {
                                row.care_status_badge = 'caution';
                            }
                        }
                        return data.data;
                    });
                };
                return _this;
            }
            PatientSearchDataTableService.prototype.termSearch = function (term) {
                var tempParams = angular.copy(this.params);
                tempParams.term = term;
                tempParams.name = tempParams.id = tempParams.mrn = null;
                return this.$httpService({
                    url: this.apiSelectUrl,
                    method: 'GET',
                    params: tempParams,
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                }).then(function (result) {
                    return result.data;
                });
            };
            PatientSearchDataTableService.prototype.export = function () {
                return this.$httpService({
                    url: '/api/v2/patient_search/export',
                    method: 'GET',
                    params: this.params,
                    paramSerializer: 'csgHttpParamSerializerJQLike',
                });
            };
            PatientSearchDataTableService.$inject = ['$http'];
            return PatientSearchDataTableService;
        }(csg.datatable.ApiService));
        services.PatientSearchDataTableService = PatientSearchDataTableService;
        angular
            .module('consolo')
            .service('patientSearchDataTableService', PatientSearchDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var PatientSearchFilterService = /** @class */ (function () {
            function PatientSearchFilterService(deviceSettingsService) {
                this.deviceSettingsService = deviceSettingsService;
                this.patientCareStatusOptions = [
                    { name: 'Active', value: 'active' },
                    { name: 'Cancelled', value: 'cancelled' },
                    { name: 'Deceased', value: 'deceased' },
                    { name: 'Postponed', value: 'postponed' },
                    { name: 'Pending - Never Admitted', value: 'pending_never_admitted' },
                    { name: 'Pending - Discharged', value: 'pending_discharged' },
                ];
                this.chips = [];
                this.chipToggles = {
                    patientCareStatus: {},
                    org_ids: {},
                    area_ids: {},
                    region_ids: {},
                    office_ids: {},
                };
                var prevFilters = this.deviceSettingsService.getValue(consolo.deviceSettings.Pages.patient_search, consolo.deviceSettings.Settings.previous_filters);
                this.filters = prevFilters || {};
                for (var _i = 0, _a = ['patientCareStatus', 'area_ids', 'region_ids', 'org_ids', 'office_ids']; _i < _a.length; _i++) {
                    var type = _a[_i];
                    if (!this.filters[type]) {
                        if (type === 'patientCareStatus') {
                            this.filters[type] = ['active', 'pending_never_admitted'];
                        }
                        else {
                            this.filters[type] = [];
                        }
                    }
                }
                for (var key in this.chipToggles) {
                    if (this.chipToggles.hasOwnProperty(key)) {
                        if (this.filters[key]) {
                            for (var _b = 0, _c = this.filters[key]; _b < _c.length; _b++) {
                                var val = _c[_b];
                                this.chipToggles[key][val] = true;
                            }
                        }
                    }
                }
                this.buildChips();
            }
            PatientSearchFilterService.prototype.callback = function () {
                this.deviceSettingsService.setValue(consolo.deviceSettings.Pages.patient_search, consolo.deviceSettings.Settings.previous_filters, this.filters);
                this.callbackFn();
            };
            PatientSearchFilterService.prototype.onChange = function (callback) {
                this.callbackFn = callback;
            };
            PatientSearchFilterService.prototype.updateFilters = function (type, setting, remove) {
                switch (type) {
                    case 'patientCareStatus':
                    case 'area_ids':
                    case 'region_ids':
                    case 'org_ids':
                    case 'office_ids':
                        if (this.filters[type].indexOf(setting) > -1) {
                            this.filters[type].splice(this.filters[type].indexOf(setting), 1);
                        }
                        else {
                            this.filters[type].push(setting);
                        }
                        break;
                    default:
                        if (remove) {
                            setting = null;
                            delete this.filters[type];
                        }
                        else {
                            this.filters[type] = setting;
                        }
                }
            };
            PatientSearchFilterService.prototype.toggle = function (value, setting, remove) {
                this.updateFilters(value, setting, remove);
                this.buildChips();
                this.callback();
            };
            PatientSearchFilterService.prototype.toggleMultiple = function (settings) {
                for (var type in settings) {
                    if (settings.hasOwnProperty(type)) {
                        switch (type) {
                            case 'patientCareStatus':
                            case 'area_ids':
                            case 'region_ids':
                            case 'org_ids':
                            case 'office_ids':
                                for (var value in settings[type]) {
                                    if (settings[type].hasOwnProperty(value)) {
                                        if (this.filters[type].indexOf(value) > -1) {
                                            if (!settings[type][value]) {
                                                this.filters[type].splice(this.filters[type].indexOf(value), 1);
                                            }
                                        }
                                        else if (settings[type][value]) {
                                            this.filters[type].push(value);
                                        }
                                    }
                                }
                                break;
                            default:
                                this.filters[type] = settings[type];
                                break;
                        }
                    }
                }
                this.buildChips();
                this.callback();
            };
            PatientSearchFilterService.prototype.clearOffices = function () {
                this.filters.office_ids = [];
                this.buildChips();
                this.callback();
            };
            PatientSearchFilterService.prototype.clearFilters = function () {
                var _this = this;
                delete this.filters.name;
                this.filters['term'] = '';
                this.filters['patient_display_name'] = '';
                this.filters['patientCareStatus'] = [];
                this.filters.office_ids = [];
                this.filters.area_ids = [];
                this.filters.region_ids = [];
                this.filters.org_ids = [];
                this.chipToggles.office_ids = {};
                this.chipToggles.area_ids = {};
                this.chipToggles.region_ids = {};
                this.chipToggles.org_ids = {};
                this.toggle('patient_id', '', true);
                Object.getOwnPropertyNames(this.chipToggles['patientCareStatus']).forEach(function (a) { return (_this.chipToggles['patientCareStatus'][a] = false); });
                this.buildChips();
                this.callback();
            };
            PatientSearchFilterService.prototype.setTerm = function (term) {
                if (term.type === 'patient') {
                    this.filters['patient_id'] = term.id;
                    this.filters['patient_display_name'] = term.name;
                }
                else {
                    this.filters[term.type] = term.name;
                }
                this.buildChips();
                this.callback();
            };
            PatientSearchFilterService.prototype.buildChips = function () {
                var chips = [];
                var _loop_1 = function (item) {
                    var patCareChip = this_1.patientCareStatusOptions
                        .filter(function (status) {
                        return status.value === item;
                    })
                        .map(function (status) {
                        return { name: status.name, type: 'patientCareStatus', value: status.value };
                    })[0];
                    if (patCareChip) {
                        chips.push(patCareChip);
                    }
                };
                var this_1 = this;
                // Build patient care chips
                for (var _i = 0, _a = this.filters['patientCareStatus']; _i < _a.length; _i++) {
                    var item = _a[_i];
                    _loop_1(item);
                }
                // Build office chip
                var numOfficeIds = this.filters['office_ids'] ? this.filters['office_ids'].length : 0;
                var officeChip = {
                    type: 'office_ids',
                    name: "Offices: " + (numOfficeIds ? numOfficeIds.toString() : 'All'),
                    value: this.filters['office_ids'],
                };
                chips.push(officeChip);
                // Build patient, name, and term chips
                for (var _b = 0, _c = ['patient_display_name', 'term']; _b < _c.length; _b++) {
                    var type = _c[_b];
                    if (this.filters[type]) {
                        chips.push({
                            name: this.filters[type],
                            value: this.filters[type],
                            type: type,
                        });
                    }
                }
                this.chips = chips;
            };
            PatientSearchFilterService.$inject = ['DeviceSettingsService'];
            return PatientSearchFilterService;
        }());
        services.PatientSearchFilterService = PatientSearchFilterService;
        angular
            .module('consolo')
            .service('PatientSearchFilterService', PatientSearchFilterService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var PatientSearchSidebarComponent = /** @class */ (function () {
            function PatientSearchSidebarComponent(officeFilterService, patientSearchFilterService, PatientSearchDataTableService, $timeout) {
                this.officeFilterService = officeFilterService;
                this.patientSearchFilterService = patientSearchFilterService;
                this.PatientSearchDataTableService = PatientSearchDataTableService;
                this.$timeout = $timeout;
                this.officeTextFilter = { name: '' };
                this.officeSearch = false;
            }
            PatientSearchSidebarComponent.prototype.$onInit = function () {
                var _this = this;
                this.toggles = this.patientSearchFilterService.chipToggles;
                this.officeFilterService
                    .getInstance()
                    .then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                });
            };
            PatientSearchSidebarComponent.prototype.getResults = function (searchTerm) {
                return this.PatientSearchDataTableService.termSearch(searchTerm).then(function (data) {
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var item = data_1[_i];
                        item.type = 'patient';
                    }
                    if (isNaN(searchTerm.trim())) {
                        data.splice(0, 0, { name: searchTerm, type: 'term' });
                    }
                    return data;
                });
            };
            PatientSearchSidebarComponent.prototype.selectItem = function (item) {
                if (item) {
                    this.patientSearchFilterService.setTerm(item);
                    this.selectedItem = null;
                }
            };
            PatientSearchSidebarComponent.prototype.toggleCareStatus = function (value) {
                this.patientSearchFilterService.toggle('patientCareStatus', value);
            };
            PatientSearchSidebarComponent.prototype.toggleOffices = function (value, type) {
                var _this = this;
                this.$timeout(function () {
                    switch (type) {
                        case 'org_ids':
                            if (_this.toggles.org_ids[value]) {
                                _this.toggleCorporate(value, true);
                            }
                            else {
                                _this.toggleCorporate(value, false);
                            }
                            break;
                        case 'region_ids':
                            if (_this.toggles.region_ids[value]) {
                                _this.toggleRegion(value, true);
                            }
                            else {
                                _this.toggleRegion(value, false);
                            }
                            break;
                        case 'area_ids':
                            if (_this.toggles.area_ids[value]) {
                                _this.toggleArea(value, true);
                            }
                            else {
                                _this.toggleArea(value, false);
                            }
                            break;
                        case 'office':
                        default:
                            break;
                    }
                    _this.patientSearchFilterService.toggleMultiple(_this.toggles);
                });
            };
            PatientSearchSidebarComponent.prototype.toggleCorporate = function (id, value) {
                for (var _i = 0, _a = this.officeFilterServiceInstance.regionOptions; _i < _a.length; _i++) {
                    var r = _a[_i];
                    if (r.parent_id === id) {
                        this.toggles.region_ids[r.value] = value;
                        this.toggleRegion(r.value, value);
                    }
                }
            };
            PatientSearchSidebarComponent.prototype.toggleRegion = function (id, value) {
                for (var _i = 0, _a = this.officeFilterServiceInstance.areaOptions; _i < _a.length; _i++) {
                    var a = _a[_i];
                    if (a.parent_id === id) {
                        this.toggles.area_ids[a.value] = value;
                        this.toggleArea(a.value, value);
                    }
                }
            };
            PatientSearchSidebarComponent.prototype.toggleArea = function (id, value) {
                for (var _i = 0, _a = this.officeFilterServiceInstance.offices; _i < _a.length; _i++) {
                    var o = _a[_i];
                    if (o.area === id) {
                        this.toggles.office_ids[o.id] = value;
                    }
                }
            };
            PatientSearchSidebarComponent.prototype.focusOfficeSearch = function () {
                this.officeSearch = true;
                var e = document.getElementById('officeTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            PatientSearchSidebarComponent.prototype.clearFilters = function () {
                this.patientSearchFilterService.clearFilters();
                this.selectedItem = null;
            };
            PatientSearchSidebarComponent.$inject = [
                'officeFilterService',
                'PatientSearchFilterService',
                'patientSearchDataTableService',
                '$timeout',
            ];
            return PatientSearchSidebarComponent;
        }());
        angular.module('consolo').component('csgPatientSearchSidebar', {
            templateUrl: '/y/templates/patients/search/patient-search-sidebar.component.html',
            controller: PatientSearchSidebarComponent,
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var contollers;
    (function (contollers) {
        var SearchController = /** @class */ (function (_super) {
            __extends(SearchController, _super);
            function SearchController($mdDialog, $state, $scope, $location, patientSearchDataTableService, officeFilterService, $interval, hijackFindService, patientSearchFilterService, $timeout, Session, persistenceStore, DeviceSettings, NetworkManager) {
                var _this = _super.call(this) || this;
                _this.$mdDialog = $mdDialog;
                _this.$state = $state;
                _this.$scope = $scope;
                _this.$location = $location;
                _this.patientSearchDataTableService = patientSearchDataTableService;
                _this.$interval = $interval;
                _this.hijackFindService = hijackFindService;
                _this.patientSearchFilterService = patientSearchFilterService;
                _this.$timeout = $timeout;
                _this.Session = Session;
                _this.persistenceStore = persistenceStore;
                _this.DeviceSettings = DeviceSettings;
                _this.NetworkManager = NetworkManager;
                _this.currentPage = { page: 0, totalPages: 0, items: [] };
                _this.view = 'grid';
                _this.patientSearchFilterService.onChange(function () {
                    _this.query();
                });
                _this.online = _this.NetworkManager.isOnline();
                _this.user = _this.Session.getUser();
                _this.agency = _this.Session.get().agency;
                _this.chartingCookie = DeviceSettings.getValue(consolo.deviceSettings.Pages.cookie, _this.DeviceSettings.settings.charting_tablet_mode);
                _this.NetworkManager.onlineHandler.onEvent('RootDashboardController', function () {
                    _this.online = true;
                    _this.updateChartingLink();
                });
                _this.NetworkManager.offlineHandler.onEvent('RootDashboardController', function () {
                    _this.online = false;
                    _this.updateChartingLink();
                });
                _this.updateChartingLink();
                _this.initializeSearchGrid();
                _this.initializeAllOptions();
                officeFilterService
                    .getInstance()
                    .then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                    _this.reset();
                });
                _this.$scope.$watch(function () { return _this.patientSearchDataTableService.params.page; }, function (newVal) {
                    _this.currentPage.page = newVal ? newVal : _this.currentPage.page;
                });
                _this.$scope.$watch(function () { return _this.patientSearchDataTableService.totalPages; }, function (newVal) {
                    _this.currentPage.totalPages = newVal;
                });
                _this.$scope.$watch(function () { return _this.patientSearchDataTableService.data; }, function (newVal) {
                    _this.currentPage.items = newVal;
                }, true);
                _this.$scope.$watch(function () { return _this.patientSearchDataTableService.params.sort_by; }, function (newVal) {
                    _this.sortBy = newVal;
                });
                _this.$scope.$watch(function () { return _this.patientSearchDataTableService.params.sort_dir; }, function (newVal) {
                    _this.sortDirection = newVal;
                });
                _this.$scope.$on('$destroy', function () {
                    hijackFindService.unregister();
                });
                return _this;
            }
            SearchController.prototype.filterExcluded = function (key, val) {
                // these are defaulted to true so we only care if they were changed to false
                return (_super.prototype.filterExcluded.call(this, key, val) &&
                    (['active', 'pending_never_admitted', 'pending_discharged'].indexOf(key) === -1 ||
                        val === false));
            };
            SearchController.prototype.applyInitialFilters = function () {
                var searchParams = this.$location.search();
                if (!Object.keys(searchParams).length) {
                    var previousFilters = this.DeviceSettings.getValue(consolo.deviceSettings.Pages.patient_search, consolo.deviceSettings.Settings.previous_filters);
                    if (previousFilters) {
                        this.filters = previousFilters;
                        _super.prototype.updateFilterQueryString.call(this);
                    }
                }
                _super.prototype.applyInitialFilters.call(this);
            };
            SearchController.prototype.updateFilterQueryString = function () {
                var _this = this;
                _super.prototype.updateFilterQueryString.call(this);
                // these default to true so we care if they've been changed to false
                ['active', 'pending_never_admitted', 'pending_discharged'].forEach(function (status) {
                    if (_this.filters[status] === false) {
                        _this.isFiltered = true;
                        _this.$location.search(status, _this.filters[status]);
                    }
                    else {
                        _this.$location.search(status, null);
                    }
                });
                this.DeviceSettings.setValue(consolo.deviceSettings.Pages.patient_search, consolo.deviceSettings.Settings.previous_filters, this.patientSearchDataTableService.params);
            };
            SearchController.prototype.query = function () {
                var _this = this;
                this.patientSearchDataTableService.params = this.patientSearchFilterService.filters;
                this.patientSearchDataTableService.params.page = 1;
                this.patientSearchFilterService.patientCareStatusOptions
                    .map(function (option) { return option.value; })
                    .forEach(function (status) {
                    return _this.patientSearchFilterService.filters.patientCareStatus.indexOf(status) > -1
                        ? (_this.patientSearchFilterService.filters[status] = true)
                        : (_this.patientSearchFilterService.filters[status] = false);
                });
                this.updateFilterQueryStringIfNeeded();
                if (this.apiInterval) {
                    this.$interval.cancel(this.apiInterval);
                }
                this.runQuery();
            };
            SearchController.prototype.runQuery = function () {
                var _this = this;
                //If not loading run the query, otherwise wait for the previous query to finish before running again
                if (!this.isLoading) {
                    this.isLoading = true;
                    if (this.apiInterval) {
                        clearInterval(this.apiInterval);
                        this.apiInterval = null;
                    }
                    this.patientSearchDataTableService.select().finally(function () {
                        // this.$timeout(() => {
                        _this.isLoading = false;
                        // }, 3000);
                    });
                }
                else {
                    if (!this.apiInterval) {
                        this.apiInterval = setInterval(function () {
                            _this.runQuery();
                        }, 500);
                    }
                    else {
                        console.log('interval already running');
                    }
                }
            };
            SearchController.prototype.reset = function () {
                this.filters = new consolo.domain.PatientSearchFilters();
                this.filters.patientCareStatus = ['active', 'pending_never_admitted', 'pending_discharged'];
                this.officeFilterServiceInstance.reset();
                this.applyInitialFiltersIfNeeded();
                this.patientSearchDataTableService.params = this.filters;
                this.query();
            };
            SearchController.prototype.toggleView = function (view) {
                this.view = view;
            };
            SearchController.prototype.loadPage = function (n) {
                this.patientSearchDataTableService.tableControl.updatePageParams(n, this.patientSearchDataTableService.params.sort_by, this.patientSearchDataTableService.params.sort_dir);
                this.patientSearchDataTableService.tableControl.loadSelectData();
            };
            SearchController.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            SearchController.prototype.sort = function (col, dir) {
                this.sortBy = col;
                this.sortDirection = dir.toUpperCase();
                this.sortByChange();
            };
            SearchController.prototype.sortByChange = function () {
                if (this.sortBy) {
                    this.patientSearchDataTableService.tableControl.updatePageParams(this.patientSearchDataTableService.params.page, this.sortBy, this.sortDirection);
                    this.patientSearchDataTableService.tableControl.loadSelectData();
                }
            };
            SearchController.prototype.initializeSearchGrid = function () {
                var _this = this;
                this.searchLayout = {
                    id_field: 'id',
                    options: {
                        defaultPageSize: 25,
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    _this.patientSearchDataTableService.export();
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    columns: [
                        { title: 'Care Status', field: 'care_status', canSort: true, canFilter: false },
                        {
                            title: 'Patient Name',
                            field: 'name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({patientId: $id})',
                            linkOpenNewWindow: true,
                        },
                        { title: 'Medical Record No', field: 'mrn', canSort: false },
                        { title: 'Office', field: 'office_name', canSort: true, sortField: 'office' },
                        { title: 'Team', field: 'team_name', canSort: true, sortField: 'team' },
                        {
                            title: 'Care Level',
                            field: 'level_of_care',
                            canSort: true,
                            sortField: 'current_level_to',
                        },
                        { title: 'Code Status', field: 'code_status', canSort: true },
                        { title: 'DOB', field: 'dob', canSort: true, type: csg.datatable.ColumnTypes.date },
                        {
                            title: 'Referral Date',
                            field: 'referral_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Admission Date',
                            field: 'admission_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Discharge Date',
                            field: 'discharge_date',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'Discharge Reason', field: 'discharge_reason', canSort: true },
                    ],
                };
            };
            SearchController.prototype.initializeAllOptions = function () {
                this.sortByOptions = [
                    { name: 'Care Status', value: 'care_status' },
                    { name: 'Patient Name', value: 'name' },
                    { name: 'Office', value: 'office' },
                    { name: 'Team', value: 'team' },
                    { name: 'Level of Care', value: 'current_level_to' },
                    { name: 'Code Status', value: 'code_status' },
                    { name: 'Date of Birth', value: 'dob' },
                    { name: 'Referral Date', value: 'referral_date' },
                    { name: 'Admission Date', value: 'admission_date' },
                    { name: 'Discharge Date', value: 'discharge_date' },
                    { name: 'Discharge Reason', value: 'discharge_reason' },
                ];
                this.sortDirectionOptions = [
                    { name: 'Ascending', value: csg.datatable.ISort.asc },
                    { name: 'Descending', value: csg.datatable.ISort.desc },
                ];
            };
            SearchController.prototype.chipSearch = function (text) {
                var ret = [
                    {
                        name: text,
                        value: text,
                        type: 'term',
                        display_name: 'Text Search',
                        icon: 'editor:title',
                    },
                ];
                text = text.toLowerCase();
                var o = this.officeFilterServiceInstance.offices
                    .filter(function (item) {
                    return item.name.toLowerCase().indexOf(text) >= 0;
                })
                    .map(function (item) {
                    return {
                        name: item.name,
                        value: item.id,
                        type: 'office_ids',
                        display_name: 'Office',
                        icon: 'communication:business',
                    };
                });
                var s = this.patientSearchFilterService.patientCareStatusOptions
                    .filter(function (item) {
                    return item.name.toLowerCase().indexOf(text) >= 0;
                })
                    .map(function (item) {
                    return {
                        name: item.name,
                        value: item.value,
                        type: 'patientCareStatus',
                        display_name: 'Status',
                        icon: 'maps:local_hospital',
                    };
                });
                return ret.concat(s, o);
            };
            SearchController.prototype.addChip = function (item) {
                if (item.type === 'patientCareStatus') {
                    if (!this.patientSearchFilterService.chipToggles[item.type]) {
                        this.patientSearchFilterService.chipToggles[item.type] = {};
                    }
                    this.patientSearchFilterService.chipToggles[item.type][item.value] = true;
                }
                this.patientSearchFilterService.toggle(item.type, item.value);
            };
            SearchController.prototype.removeChip = function (item) {
                if (item.type === 'patient_display_name') {
                    this.patientSearchFilterService.toggle('patient_id', item.value, true);
                }
                if (item.type === 'office_ids') {
                    this.patientSearchFilterService.chipToggles.office_ids = {};
                    this.patientSearchFilterService.clearOffices();
                }
                else {
                    if (item.type === 'patientCareStatus') {
                        if (this.patientSearchFilterService.chipToggles[item.type]) {
                            this.patientSearchFilterService.chipToggles[item.type][item.value] = false;
                        }
                    }
                    this.patientSearchFilterService.toggle(item.type, item.value, true);
                }
            };
            SearchController.prototype.updateChartingLink = function () {
                if (this.user.flags.has_encounter_charting) {
                    this.createChartTitle = 'Create Encounter Chart';
                    this.createChartLink = '';
                }
                else {
                    this.createChartTitle = 'Create Clinical Chart';
                    if (!this.online || this.chartingCookie || this.agency.has_encounter_charting) {
                        this.createChartLink = '/y/patients/:patientId/clinical_chart/new/general';
                    }
                    else {
                        this.createChartLink = '/patients/:patientId/clinical_charts/new';
                    }
                }
            };
            SearchController.prototype.encounterModal = function ($event, patient) {
                var _this = this;
                if (this.user.flags.has_encounter_charting) {
                    this.persistenceStore
                        .get('clinical_chart_entry', { patient_id: patient.id })
                        .then(function (item) {
                        var model = item.model;
                        var obj = {
                            selectedPatient: model.patient,
                            encounter_type_id: model.encounter_type_id,
                            encounter_type: model.encounter_type,
                            charting_client: model.encounter_type.charting_type,
                            initial_section_name: model.initial_section_name || 'encounter_charting',
                        };
                        _this.$state.go('patients.clinical-chart-entry-new.section', {
                            patientId: model.patient_id,
                            sectionName: obj.initial_section_name,
                            initObj: obj,
                        });
                    })
                        .catch(function () {
                        _this.$mdDialog.show({
                            targetEvent: $event,
                            clickOutsideToClose: false,
                            bindToController: true,
                            templateUrl: '/y/templates/clinical-charts/encounter-types/selector-modal.html',
                            controller: 'EncounterSelectorModalController as $ctrl',
                            locals: { currentPatient: patient, clinical_trip_stop_point_id: null },
                        });
                    });
                }
            };
            SearchController.$inject = [
                '$mdDialog',
                '$state',
                '$scope',
                '$location',
                'patientSearchDataTableService',
                'officeFilterService',
                '$interval',
                'hijackFindService',
                'PatientSearchFilterService',
                '$timeout',
                'SessionService',
                'persistenceStore',
                'DeviceSettingsService',
                'NetworkManager',
            ];
            return SearchController;
        }(consolo.domain.QueryStringFilter));
        angular.module('consolo').controller('SearchController', SearchController);
    })(contollers = consolo.contollers || (consolo.contollers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var states;
    (function (states) {
        var SearchStates = /** @class */ (function () {
            function SearchStates($stateProvider) {
                $stateProvider.state('search', {
                    url: '/patients',
                    reloadOnSearch: false,
                    views: {
                        'sidebar@': {
                            template: '<csg-patient-search-sidebar flex="" layout="column"></csg-patient-search-sidebar>',
                        },
                        'main-content@': {
                            templateUrl: '/y/templates/patients/search/search.html',
                            controller: 'SearchController as vm',
                        },
                    },
                });
            }
            SearchStates.$inject = ['$stateProvider'];
            return SearchStates;
        }());
        angular.module('consolo').config(SearchStates);
    })(states = consolo.states || (consolo.states = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .directive('consoloSectionBuilder', ['$http', 'ConfirmModal', Directive]);
    function Directive() {
        return {
            replace: true,
            scope: {
                items: '=',
                itemType: '@',
                sectionName: '@',
                itemSummaryTemplateUrl: '@',
                patientId: '@',
                selectedIds: '=',
                onChange: '&',
                hasVisitFrequencyEnabled: '=',
            },
            templateUrl: '/y/templates/plan-of-care/section-builder/section-builder.html',
            controller: ['$scope', Controller],
            controllerAs: 'vm',
        };
        /* jshint browser: true */
        function Controller(scope) {
            var vm = this;
            vm.loading = true;
            vm.items = scope.items;
            vm.sectionName = scope.sectionName;
            vm.hasVisitFrequencyEnabled = scope.hasVisitFrequencyEnabled;
            vm.selectAll = selectAll;
            vm.toggleItem = toggleItem;
            activate();
            function activate() {
                vm.items.$promise.then(function (data) {
                    data.forEach(function (item) {
                        item.selected = scope.selectedIds && scope.selectedIds.indexOf(item.id) >= 0;
                    });
                    vm.loading = false;
                });
            }
            function selectAll() {
                scope.selectedIds = [];
                scope.items.forEach(function (item) {
                    item.selected = true;
                    scope.selectedIds.push(item.id);
                });
            }
            function toggleItem(item, $event, toggleCheckbox) {
                if (item.selected) {
                    while (scope.selectedIds.indexOf(item.id) > -1) {
                        scope.selectedIds.splice(scope.selectedIds.indexOf(item.id), 1);
                    }
                }
                else {
                    if (!scope.selectedIds) {
                        scope.selectedIds = [];
                    }
                    scope.selectedIds.push(item.id);
                }
                if (toggleCheckbox) {
                    item.selected = !item.selected;
                }
                scope.onChange();
                $event.stopPropagation();
            }
        }
    }
})();

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientDataReconciliationController = /** @class */ (function () {
            function PatientDataReconciliationController($state, $mdDialog, $interval, logger, reconciliationService) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$interval = $interval;
                this.logger = logger;
                this.reconciliationService = reconciliationService;
                this.importedItems = [
                    {
                        name: 'Allergy',
                        list: [],
                        allChecked: { green: false, yellow: false, red: false },
                        inChart: 0,
                        unreconcilied: 0,
                        notSupported: 0,
                        supported: 0,
                    },
                    {
                        name: 'Diagnosis',
                        list: [],
                        allChecked: { green: false, yellow: false, red: false },
                        inChart: 0,
                        unreconcilied: 0,
                        notSupported: 0,
                        supported: 0,
                    },
                    {
                        name: 'Medications',
                        list: [],
                        allChecked: { green: false, yellow: false, red: false },
                        inChart: 0,
                        unreconcilied: 0,
                        notSupported: 0,
                        supported: 0,
                    },
                ];
                this.checked = true;
                this.importId = this.$state.params['importId'] ? +this.$state.params['importId'] : null;
                this.$onInit();
            }
            PatientDataReconciliationController.prototype.$onInit = function () {
                this.getSinglePatientImport(this.importId);
                // this.reconcilePatientImport(this.importId);
            };
            PatientDataReconciliationController.prototype.getSinglePatientImport = function (importId) {
                var _this = this;
                this.reconciliationService.getSinglePatientImport(importId).then(function (response) {
                    _this.xmlContents = response.data.import_xml;
                    _this.importDocument = response.data;
                    _this.patientOnFile = response.data.patient;
                    _this.importedPatient = response.data.import_json.ccd.patient;
                    _this.hasDemographics =
                        _this.importedPatient &&
                            !!(_this.importedPatient.given_name ||
                                _this.importedPatient.givenName ||
                                _this.importedPatient.name ||
                                _this.importedPatient.family_name ||
                                _this.importedPatient.familyName ||
                                _this.importedPatient.dob ||
                                _this.importedPatient.dob_display ||
                                _this.importedPatient.ssn ||
                                _this.importedPatient.address_1 ||
                                _this.importedPatient.addr1 ||
                                _this.importedPatient.address_2 ||
                                _this.importedPatient.current_address ||
                                _this.importedPatient.gender);
                    _this.setReconciliableItems(response.data.import_json.ccd);
                });
            };
            PatientDataReconciliationController.prototype.setReconciliableItems = function (importedItems) {
                this.importedItems[0].list = importedItems.allergens;
                this.importedItems[1].list = importedItems.diagnosis.icd10s;
                this.importedItems[2].list = importedItems.medicines;
                this.importedItems.forEach(this.setDataHeadings);
            };
            PatientDataReconciliationController.prototype.setDataHeadings = function (importItem, index) {
                importItem.supported = importItem.list.filter(function (listItem) { return listItem.import_supported === 'true'; }).length;
                importItem.notSupported = importItem.list.filter(function (listItem) { return listItem.import_supported === 'false'; }).length;
                importItem.inChart = importItem.list.filter(function (listItem) { return listItem.import_in_chart === 'true'; }).length;
                importItem.unreconciled = importItem.list.filter(function (listItem) { return listItem.import_in_chart === 'false'; }).length;
            };
            // reconcilePatientImport(importId: number) {
            //   this.reconciliationService.reconcilePatientImport(importId)
            //     .then(response => {
            //       this.xmlContents = response.data.import_xml;
            //       this.importDocument = response.data;
            //       this.patientOnFile = new PatientImport(response.data.patient);
            //       this.importedPatient = new PatientImport(response.data.import_json.ccd.patient);
            //       console.log(response);
            //     }).catch(error => {
            //       console.log(error);
            //     });
            // }
            PatientDataReconciliationController.prototype.showActionDialog = function () {
                this.$mdDialog.show({
                    contentElement: '#reconciliation-action-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    openFrom: '#action-button',
                    closeTo: '#action-button',
                });
            };
            PatientDataReconciliationController.prototype.checkAllItems = function (index, importStatus) {
                if (!this.importedItems[index].allChecked[importStatus]) {
                    this.importedItems[index].list.map(function (item) {
                        if (item.import_supported === 'true' ||
                            importStatus === 'yellow' ||
                            importStatus === 'red') {
                            return (item.import_status = importStatus);
                        }
                        else if (item.import_supported === 'false') {
                            return (item.import_status = '');
                        }
                    });
                    this.uncheckAllOtherItems(index, importStatus);
                }
                else {
                    this.importedItems[index].list.map(function (item) { return (item.import_status = ''); });
                    this.uncheckAllOtherItems(index, importStatus);
                }
            };
            PatientDataReconciliationController.prototype.uncheckAllOtherItems = function (index, importStatus) {
                var _this = this;
                var allImportStatuses = ['green', 'yellow', 'red'];
                var unusedImportStatuses = allImportStatuses.filter(function (status) { return status !== importStatus; });
                unusedImportStatuses.forEach(function (status) {
                    _this.importedItems[index].allChecked[status] = false;
                });
            };
            PatientDataReconciliationController.prototype.checkSingleItem = function (parent, child, color) {
                this.importedItems[parent].allChecked.green = false;
                this.importedItems[parent].allChecked.yellow = false;
                this.importedItems[parent].allChecked.red = false;
            };
            PatientDataReconciliationController.prototype.updatePatientImport = function () {
                var _this = this;
                var importJSON = JSON.stringify(this.importDocument.import_json);
                var patientImport = { import_json: importJSON };
                this.reconciliationService
                    .updatePatientImport(patientImport, this.importId)
                    .then(function (response) {
                    _this.$state.go('reconciliation');
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            PatientDataReconciliationController.prototype.importToPatientRecord = function () {
                var _this = this;
                if (!this.patientOnFile) {
                    this.importDocument.import_json.ccd.patient.genderCode = 'M';
                }
                var importJSON = JSON.stringify(this.importDocument.import_json);
                var patientImport = {
                    status: 'Completed',
                    import_json: importJSON,
                };
                this.reconciliationService
                    .updatePatientImport(patientImport, this.importId)
                    .then(function () {
                    if (_this.patientOnFile) {
                        _this.reconciliationService
                            .importPatientData(_this.importId, false)
                            .then(function () {
                            _this.logger.persistentToast('Import for was succesful!', 'Success:', 'success');
                            _this.$state.go('patients.dashboard', {
                                patientId: _this.patientOnFile.id,
                            });
                        })
                            .catch(function (errors) {
                            _this.displayErrors(errors);
                        });
                    }
                    else if (!_this.patientOnFile) {
                        _this.reconciliationService
                            .importPatientData(_this.importId, true)
                            .then(function () {
                            _this.logger.persistentToast('Import for was succesful!', 'Success:', 'success');
                            _this.$state.go('patients.dashboard', {
                                patientId: _this.patientOnFile.id,
                            });
                        })
                            .catch(function (errors) {
                            _this.displayErrors(errors);
                        });
                    }
                })
                    .catch(function (errors) {
                    _this.displayErrors(errors);
                });
            };
            PatientDataReconciliationController.prototype.displayErrors = function (errorArray) {
                var errorMessage = errorArray.data.errors
                    .map(function (error, index) {
                    return '(' + (index + 1) + ') ' + error;
                })
                    .toString();
                this.logger.persistentToast(errorMessage, 'Your import contains the following errors:', 'error');
            };
            PatientDataReconciliationController.prototype.cancelReconciliation = function () {
                this.$state.go('reconciliation');
            };
            PatientDataReconciliationController.$inject = ['$state', '$mdDialog', '$interval', 'logger', 'reconciliationService'];
            return PatientDataReconciliationController;
        }());
        controllers.PatientDataReconciliationController = PatientDataReconciliationController;
        angular
            .module('consolo')
            .controller('PatientDataReconciliationController', PatientDataReconciliationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var reconciliationComponents;
    (function (reconciliationComponents) {
        angular.module('consolo').component('reconciliationDataTable', {
            bindings: { patientImport: '=', importedItems: '=', showActionDialog: '&', checkAllItems: '&', checkSingleItem: '&' },
            templateUrl: '/y/templates/reconciliation/ccda-data-reconciliation/reconciliation-data-table.html',
            controller: 'ReconciliationDataTable as vm',
        });
        var ReconciliationDataTable = /** @class */ (function () {
            function ReconciliationDataTable($state, $filter, $http, logger) {
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.listClass = true;
                this.checked = true;
                this.$onInit();
            }
            ReconciliationDataTable.prototype.$onInit = function () {
            };
            ReconciliationDataTable.prototype.logItemStatus = function (item, index) { };
            ReconciliationDataTable.prototype.$onChanges = function () { };
            ReconciliationDataTable.$inject = ['$state', '$filter', '$http', 'logger'];
            return ReconciliationDataTable;
        }());
        reconciliationComponents.ReconciliationDataTable = ReconciliationDataTable;
        angular.
            module('consolo').
            controller('ReconciliationDataTable', ReconciliationDataTable);
    })(reconciliationComponents = consolo.reconciliationComponents || (consolo.reconciliationComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientDocs;
    (function (patientDocs) {
        angular.module('consolo').component('ccdaDocumentUpload', {
            bindings: { upload: '<', patientId: '<', afterCancel: '&', afterSave: '&' },
            templateUrl: '/y/templates/reconciliation/ccda-file-upload/ccda-document-upload.html',
            controller: 'CCDADocumentUploadController as vm'
        });
        var CCDADocumentUploadController = /** @class */ (function () {
            function CCDADocumentUploadController($filter, $http, options, logger, $mdDialog, Session) {
                this.$filter = $filter;
                this.$http = $http;
                this.options = options;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.documentDate = new Date();
                this.saving = false;
                this.currentDateTime = new Date();
                this.model = {
                    user_id: this.currentUser.id,
                    name: this.currentUser.name + ' ' + this.currentDateTime.toLocaleDateString() + ' ' + this.currentDateTime.toLocaleTimeString(),
                    tags: ['CCDA File Upload']
                };
            }
            CCDADocumentUploadController.prototype.$onInit = function () {
                this.tags = this.options.get('document_types');
            };
            Object.defineProperty(CCDADocumentUploadController.prototype, "currentUser", {
                get: function () {
                    return this.Session.getUser();
                },
                enumerable: true,
                configurable: true
            });
            CCDADocumentUploadController.prototype.save = function () {
                var _this = this;
                if (this.saving || this.model['id']) {
                    return;
                }
                this.saving = true;
                this.showFileProgressDialog();
                this.upload.promise.then(function () {
                    _this.model.s3_tmp_key = _this.upload.s3Key;
                    if (_this.documentDate) {
                        _this.model['effective_datetime'] = _this.documentDate;
                    }
                    _this.$http.post('/api/v1/patient_imports', _this.model).then(function (resp) {
                        _this.model['id'] = resp.data['id'];
                        _this.saving = false;
                        _this.afterSave({ upload: _this.upload, doc: resp.data });
                    }, function (resp) {
                        _this.saving = false;
                        _this.logger.error(resp.data.errors.join(', '));
                        _this.hideFileProgressDialog();
                    }).catch(function (error) { _this.hideFileProgressDialog(); });
                });
            };
            CCDADocumentUploadController.prototype.cancel = function () {
                this.saving = false;
                this.hideFileProgressDialog();
                this.upload.cancel();
                this.afterCancel(this.upload);
            };
            CCDADocumentUploadController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            CCDADocumentUploadController.prototype.showFileProgressDialog = function () {
                this.$mdDialog.show({
                    contentElement: '#reconciliation-progress-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false
                });
            };
            CCDADocumentUploadController.prototype.hideFileProgressDialog = function () {
                this.$mdDialog.hide();
            };
            CCDADocumentUploadController.$inject = ['$filter', '$http', 'options', 'logger', '$mdDialog', 'SessionService'];
            return CCDADocumentUploadController;
        }());
        patientDocs.CCDADocumentUploadController = CCDADocumentUploadController;
        angular.
            module('consolo').
            controller('CCDADocumentUploadController', CCDADocumentUploadController);
    })(patientDocs = consolo.patientDocs || (consolo.patientDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientImportDocument = consolo.domain.PatientImportDocument;
        var CCDAFileImportController = /** @class */ (function () {
            function CCDAFileImportController($state, $mdDialog, $interval, logger, reconciliationService, $timeout, uploadQueue, S3UploadFactory, Session) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$interval = $interval;
                this.logger = logger;
                this.reconciliationService = reconciliationService;
                this.$timeout = $timeout;
                this.uploadQueue = uploadQueue;
                this.S3UploadFactory = S3UploadFactory;
                this.Session = Session;
                this.patientImports = {
                    completed: [],
                    pending: [],
                };
                this.uploads = [];
                this.filterPrepends = [];
                this.$onInit();
            }
            CCDAFileImportController.prototype.$onInit = function () {
                this.isLoading = true;
                this.getAllPatientImports();
            };
            CCDAFileImportController.prototype.getAllPatientImports = function () {
                var _this = this;
                this.reconciliationService.getAllPatientImports().then(function (response) {
                    _this.sortImports(response.data);
                });
                this.initializeImportsGrid();
            };
            Object.defineProperty(CCDAFileImportController.prototype, "currentUser", {
                get: function () {
                    return this.Session.getUser();
                },
                enumerable: true,
                configurable: true
            });
            CCDAFileImportController.prototype.sortImports = function (patientImports) {
                this.patientImports.completed = patientImports.filter(function (patientImport) {
                    return patientImport.status === 'Completed';
                });
                this.patientImports.pending = patientImports.filter(function (patientImport) {
                    return patientImport.status === 'Pending';
                });
            };
            CCDAFileImportController.prototype.deletePatientImport = function (importId) {
                var _this = this;
                this.isLoading = true;
                this.reconciliationService.deletePatientImport(importId).then(function (response) {
                    _this.getAllPatientImports();
                });
            };
            CCDAFileImportController.prototype.handleFiles = function (files) {
                var _this = this;
                if (this.uploads.length === 0) {
                    files.forEach(function (file) {
                        // let extn = file.name.split('.').pop();
                        // if (extn === 'xml') {
                        var upload = _this.S3UploadFactory.build(file);
                        _this.uploadQueue.push(upload);
                        _this.uploads.push(upload);
                        upload.promise.catch(function (e) {
                            _this.removeUpload(upload, 5000);
                        });
                        // } else if (extn !== 'xml') {
                        //   this.logger.error('Invalid file type. Please upload valid .xml file.');
                        // }
                    });
                }
            };
            CCDAFileImportController.prototype.afterCancel = function (upload) {
                this.removeUpload(upload, 500);
            };
            CCDAFileImportController.prototype.afterSave = function (upload, doc) {
                this.removeUpload(upload, 100);
                this.filterPrepends.unshift(doc);
                this.$mdDialog.hide();
                this.setNewPatientImport(upload, doc.id);
            };
            CCDAFileImportController.prototype.setNewPatientImport = function (s3ReturnObject, importId) {
                var newPatientImport = new PatientImportDocument();
                var currentDateTime = new Date();
                newPatientImport.effective_datetime =
                    currentDateTime.toLocaleDateString() + ' ' + currentDateTime.toLocaleTimeString();
                newPatientImport.status = 'Pending';
                newPatientImport.name = this.currentUser.name + ' ' + newPatientImport.effective_datetime;
                newPatientImport.tags = ['Clinical'];
                this.addNewPatientImport(newPatientImport, importId);
            };
            CCDAFileImportController.prototype.removeUpload = function (upload, time) {
                var _this = this;
                this.$mdDialog.hide();
                this.$timeout(function () {
                    var idx = _this.uploads.indexOf(upload);
                    if (idx > -1) {
                        _this.uploads.splice(idx, 1);
                    }
                }, time);
            };
            CCDAFileImportController.prototype.addNewPatientImport = function (importObject, importId) {
                var _this = this;
                this.reconciliationService.updatePatientImport(importObject, importId).then(function (response) {
                    _this.$state.go('reconciliation.import-options', { importId: importId });
                });
            };
            CCDAFileImportController.prototype.initializeImportsGrid = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.patientPendingImportsLayout = {
                    id_field: 'id',
                    options: { hideTextFilter: false },
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete ' + data.name + ' ?'))
                                        .then(function () {
                                        _this.deletePatientImport(data.id);
                                    });
                                    return true;
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Imported By', field: 'user.name', canSort: true },
                        {
                            title: 'Patient Name',
                            field: 'patient.name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.medium_text,
                            formatValueFn: function (data, col, value) {
                                if (!value) {
                                    return 'No Associated Patient';
                                }
                                else {
                                    return value;
                                }
                            },
                        },
                        {
                            title: 'Date',
                            field: 'created_at',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        { title: 'Status', field: 'status', canSort: true },
                        {
                            title: 'Source',
                            field: 'name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: function (data) {
                                console.log(data);
                                if (data.patient) {
                                    return 'reconciliation.data-reconciliation({importId: $id})';
                                }
                                else if (!data.import_json.ccd.allergens[0].hasOwnProperty('import_status')) {
                                    return 'reconciliation.import-options({importId: $id})';
                                }
                                else if (!data.patient &&
                                    data.import_json.ccd.allergens[0].hasOwnProperty('import_status')) {
                                    return 'reconciliation.data-reconciliation({importId: $id})';
                                }
                            },
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return 'File';
                                }
                            },
                        },
                    ],
                };
                this.patientCompletedImportsLayout = {
                    id_field: 'id',
                    options: { hideTextFilter: false },
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.$mdDialog
                                        .show(generateConfirmationRequest('Are you sure you want to delete ' + data.name + ' ?'))
                                        .then(function () {
                                        _this.deletePatientImport(data.id);
                                    });
                                    return true;
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Imported By', field: 'user.name', canSort: true },
                        {
                            title: 'Patient Name',
                            field: 'patient.name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.medium_text,
                            formatValueFn: function (data, col, value) {
                                if (!value) {
                                    return 'No Associated Patient';
                                }
                                else {
                                    return value;
                                }
                            },
                        },
                        {
                            title: 'Date',
                            field: 'created_at',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        { title: 'Status', field: 'status', canSort: true },
                        {
                            title: 'Source',
                            field: 'name',
                            canSort: true,
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'reconciliation.data-reconciliation({importId: $id})',
                            formatValueFn: function (data, col, value) {
                                if (value) {
                                    return 'File';
                                }
                            },
                        },
                    ],
                };
                this.isLoading = false;
            };
            CCDAFileImportController.$inject = [
                '$state',
                '$mdDialog',
                '$interval',
                'logger',
                'reconciliationService',
                '$timeout',
                'UploadQueue',
                'S3UploadFactory',
                'SessionService',
            ];
            return CCDAFileImportController;
        }());
        angular.module('consolo').controller('CCDAFileImportController', CCDAFileImportController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientImport = consolo.domain.PatientImport;
        var PatientImportOptionsController = /** @class */ (function () {
            function PatientImportOptionsController($state, $mdDialog, $interval, logger, reconciliationService, Session) {
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.$interval = $interval;
                this.logger = logger;
                this.reconciliationService = reconciliationService;
                this.Session = Session;
                this.loadingHeader = 'CCDA Patient Select';
                this.importId = this.$state.params['importId'] ? +this.$state.params['importId'] : null;
                this.$onInit();
            }
            PatientImportOptionsController.prototype.$onInit = function () {
                this.getSinglePatientImport(this.importId);
            };
            Object.defineProperty(PatientImportOptionsController.prototype, "currentUser", {
                get: function () {
                    return this.Session.getUser();
                },
                enumerable: true,
                configurable: true
            });
            PatientImportOptionsController.prototype.getSinglePatientImport = function (importId) {
                var _this = this;
                this.reconciliationService.getSinglePatientImport(importId).then(function (response) {
                    _this.importDocument = response.data;
                    _this.importedPatient = new PatientImport(response.data.import_json.ccd.patient);
                    _this.getPatientImportMatch(_this.importedPatient);
                });
            };
            PatientImportOptionsController.prototype.getPatientImportMatch = function (patientImportObj) {
                var _this = this;
                this.reconciliationService
                    .getPatientImportMatch(patientImportObj)
                    .then(function (response) {
                    _this.setPatientMatches(response.data);
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            PatientImportOptionsController.prototype.setPatientMatches = function (patientMatches) {
                if (patientMatches.length === 1) {
                    this.singlePatientMatch = patientMatches[0];
                    this.chosenPatientMatchId = this.singlePatientMatch.id;
                }
                else if (patientMatches.length > 1) {
                    this.multiPatientMatches = patientMatches;
                }
            };
            PatientImportOptionsController.prototype.choosePatientMatch = function (id) {
                console.log(id);
                this.chosenPatientMatchId = id;
            };
            PatientImportOptionsController.prototype.importToSelectedPatient = function () {
                var _this = this;
                this.loadingMessage = 'Getting Reconcilable Data from Patients CCDA File';
                this.showFileProgressDialog();
                this.reconciliationService
                    .setPatientId(this.importId, this.chosenPatientMatchId)
                    .then(function (response) {
                    _this.reconcilePatientImport(_this.importId);
                    // this.$state.go('reconciliation.data-reconciliation', { importId: this.importId });
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            PatientImportOptionsController.prototype.reconcilePatientImport = function (importId) {
                var _this = this;
                this.reconciliationService
                    .reconcilePatientImport(importId)
                    .then(function (response) {
                    _this.$state.go('reconciliation.data-reconciliation', { importId: _this.importId });
                })
                    .catch(function (error) {
                    // this.$state.go('reconciliation.data-reconciliation', { importId: this.importId });
                });
            };
            PatientImportOptionsController.prototype.showFileProgressDialog = function () {
                this.$mdDialog.show({
                    contentElement: '#reconciliation-progress-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                });
            };
            PatientImportOptionsController.prototype.importAsNewPatient = function () {
                this.reconcilePatientImport(this.importId);
            };
            PatientImportOptionsController.prototype.cancelImport = function () {
                this.$state.go('reconciliation');
            };
            PatientImportOptionsController.$inject = [
                '$state',
                '$mdDialog',
                '$interval',
                'logger',
                'reconciliationService',
                'SessionService',
            ];
            return PatientImportOptionsController;
        }());
        controllers.PatientImportOptionsController = PatientImportOptionsController;
        angular
            .module('consolo')
            .controller('PatientImportOptionsController', PatientImportOptionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var IOHubUpdatesModal = /** @class */ (function () {
            function IOHubUpdatesModal($mdDialog, reconciliationService, logger, $state) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.reconciliationService = reconciliationService;
                this.logger = logger;
                this.$state = $state;
                this.statusMessage = '';
                if (!this.updateAvailable) {
                    this.isChecking = true;
                    this.statusMessage = 'Checking IO Hub updates for ' + this.patient.name + ' .';
                    this.checkForUpdates(this.patient.id).finally(function () {
                        _this.isChecking = false;
                    });
                }
                else {
                    this.closeDialog(true);
                }
            }
            IOHubUpdatesModal.prototype.closeDialog = function (save) {
                var _this = this;
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                else {
                    this.isChecking = true;
                    this.statusMessage = 'Creating patient import record for ' + this.patient.name + '.';
                    this.reconciliationService
                        .createIOHubImport(this.patient.id)
                        .then(function (response) {
                        _this.isChecking = false;
                        _this.$mdDialog.hide(null);
                        _this.$state.go('reconciliation.data-reconciliation', { importId: response.data.id });
                    })
                        .catch(function (error) {
                        console.log(error);
                        _this.isChecking = false;
                        _this.logger.error(error.data.errors.toString(), 'Error:');
                        throw error;
                    });
                }
            };
            IOHubUpdatesModal.prototype.checkForUpdates = function (patientId) {
                var _this = this;
                return this.reconciliationService
                    .checkIOHubUpdates(patientId)
                    .then(function (response) {
                    console.log(response);
                    _this.updateAvailable = response.data.available;
                    if (_this.updateAvailable) {
                        _this.statusMessage =
                            'IO Hub updates are available for ' +
                                _this.patient.name +
                                '.\n Would you like to import them now?';
                    }
                    else {
                        _this.statusMessage =
                            'IO Hub updates are currently unavailable for ' + _this.patient.name + '.';
                    }
                })
                    .catch(function (error) {
                    console.log(error);
                    _this.logger.error(error.data.errors.toString(), 'Error:');
                    throw error;
                });
            };
            IOHubUpdatesModal.$inject = ['$mdDialog', 'reconciliationService', 'logger', '$state'];
            return IOHubUpdatesModal;
        }());
        angular.module('consolo').controller('IOHubUpdatesModal', IOHubUpdatesModal);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var reconciliationComponents;
    (function (reconciliationComponents) {
        angular.module('consolo').component('reconciliationDocumentHeader', {
            bindings: { patientImport: '=', testBindings: '&' },
            templateUrl: '/y/templates/reconciliation/reconciliation-reusable-components/reconciliation-document-header.html',
            controller: 'ReconciliationDocumentHeader as vm',
        });
        var ReconciliationDocumentHeader = /** @class */ (function () {
            function ReconciliationDocumentHeader($filter, $http, logger) {
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
            }
            ReconciliationDocumentHeader.prototype.$onInit = function () {
                this.testBindings('Bindings are working');
            };
            ReconciliationDocumentHeader.$inject = ['$filter', '$http', 'logger'];
            return ReconciliationDocumentHeader;
        }());
        reconciliationComponents.ReconciliationDocumentHeader = ReconciliationDocumentHeader;
        angular.
            module('consolo').
            controller('ReconciliationDocumentHeader', ReconciliationDocumentHeader);
    })(reconciliationComponents = consolo.reconciliationComponents || (consolo.reconciliationComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var reconciliationComponents;
    (function (reconciliationComponents) {
        angular.module('consolo').component('reconciliationPatientDemographics', {
            bindings: { header: '=', patient: '<', demographicType: '=', choosePatientMatch: '&' },
            templateUrl: '/y/templates/reconciliation/reconciliation-reusable-components/reconciliation-patient-demographics.html',
            controller: 'ReconciliationPatientDemographics as vm',
        });
        var ReconciliationPatientDemographics = /** @class */ (function () {
            function ReconciliationPatientDemographics($filter, $http, logger) {
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
            }
            ReconciliationPatientDemographics.prototype.$onInit = function () { };
            Object.defineProperty(ReconciliationPatientDemographics.prototype, "headerStyle", {
                get: function () {
                    if (this.demographicType === 'single-existing') {
                        return {
                            'background-color': 'rgb(96,125,139)',
                            'color': 'white'
                        };
                    }
                    else if (this.demographicType === 'multiple-existing') {
                        return {
                            'background-color': 'rgb(96,125,139)',
                            'color': 'white'
                        };
                    }
                    else { }
                },
                enumerable: true,
                configurable: true
            });
            ReconciliationPatientDemographics.$inject = ['$filter', '$http', 'logger'];
            return ReconciliationPatientDemographics;
        }());
        reconciliationComponents.ReconciliationPatientDemographics = ReconciliationPatientDemographics;
        angular.
            module('consolo').
            controller('ReconciliationPatientDemographics', ReconciliationPatientDemographics);
    })(reconciliationComponents = consolo.reconciliationComponents || (consolo.reconciliationComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var reconciliationComponents;
    (function (reconciliationComponents) {
        angular.module('consolo').component('reconciliationProgressLoader', {
            bindings: { importStatus: '<', loadingHeader: '<', loadingMessage: '<' },
            templateUrl: '/y/templates/reconciliation/reconciliation-reusable-components/reconciliation-progress-loader.html',
            controller: 'ReconciliationProgressLoader as vm',
        });
        var ReconciliationProgressLoader = /** @class */ (function () {
            function ReconciliationProgressLoader($filter, $http, logger, $mdDialog) {
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
            }
            ReconciliationProgressLoader.prototype.$onInit = function () {
                // this.showProgressLoaderDialog();
            };
            ReconciliationProgressLoader.prototype.$onDestroy = function () {
                this.$mdDialog.hide(null);
            };
            ReconciliationProgressLoader.$inject = ['$filter', '$http', 'logger', '$mdDialog'];
            return ReconciliationProgressLoader;
        }());
        reconciliationComponents.ReconciliationProgressLoader = ReconciliationProgressLoader;
        angular.
            module('consolo').
            controller('ReconciliationProgressLoader', ReconciliationProgressLoader);
    })(reconciliationComponents = consolo.reconciliationComponents || (consolo.reconciliationComponents = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('SuggestedTemplatesController', ['$state', 'RecurringTaskSetTemplate', Controller]);
    function Controller($state, RecurringTaskSetTemplate) {
        var vm = this;
        vm.taskSets = [];
        vm.templateApplied = templateApplied;
        activate();
        function activate() {
            vm.taskSets = RecurringTaskSetTemplate.baseTasks;
        }
        function templateApplied() {
            $state.go('aide-manager.recurring-task-templates.template-editor');
        }
    }
})();

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('compound', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/medications/compounds/compound.html',
            controller: 'CompoundController as $ctrl',
        });
        var CompoundController = /** @class */ (function () {
            function CompoundController(logger, $state, Session, CompoundsService, MedicationService, $q, MedicationFavoritesService, MedicationFormularyService) {
                var _this = this;
                this.logger = logger;
                this.$state = $state;
                this.Session = Session;
                this.CompoundsService = CompoundsService;
                this.MedicationService = MedicationService;
                this.$q = $q;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.MedicationFormularyService = MedicationFormularyService;
                this.saving = false;
                var session = Session.get();
                if (session.agency.formulary_enabled && session.agency.formulary_enforced) {
                    this.filterFormularyOnly = true;
                }
                else {
                    this.filterMedispanOnly =
                        session.agency.epcs_on || session.agency.medispan_only_meds_checked_by_default;
                }
                if ($state.params.compoundId) {
                    this.CompoundsService.get($state.params.compoundId).then(function (compound) {
                        _this.compound = compound;
                        _this.compound.ingredients = _this.compound.ingredients.map(function (ingredient) {
                            return new consolo.domain.CompoundIngredient(ingredient);
                        });
                        _this.setIngredientDisplay();
                        if (_this.$state.current.name === 'compounds.clone') {
                            _this.compound.id = null;
                            _this.compound.name = null;
                        }
                    });
                }
                else {
                    this.compound = { ingredients: [] };
                }
            }
            CompoundController.prototype.delete = function (index, ingredient) {
                this.compound.ingredients.splice(index, 1);
                this.setIngredientDisplay();
            };
            CompoundController.prototype.formatName = function (ingredient) {
                var display = ingredient.name;
                if (ingredient.strength && ingredient.strength_unit) {
                    display += ' ' + ingredient.strength + ' ' + ingredient.strength_unit;
                }
                if (ingredient.form) {
                    display += ' ' + ingredient.form;
                }
                if (ingredient.fromFormulary()) {
                    display += '<span class="margin-left-xs margin-right-xs label formulary">Formulary</span>';
                }
                if (ingredient.fromFavorites()) {
                    display += '<span class="margin-left-xs margin-right-xs label favorite">Favorite</span>';
                }
                if (ingredient.fromMedispan()) {
                    display += '<span class="margin-left-xs margin-right-xs label medispan">Medispan</span>';
                }
                if (ingredient.isControlledSubstance()) {
                    display += '<span class="margin-left-xs label controlled">Class 2</span>';
                }
                else if (ingredient.controlled_substance_code) {
                    display +=
                        '<span class="margin-left-xs label non-controlled">Class ' +
                            ingredient.controlled_substance_code +
                            '</span>';
                }
                return display;
            };
            CompoundController.prototype.addIngredient = function () {
                if (!this.selectedMedication) {
                    this.errors = ['Please search and select an ingredient to add.'];
                    this.logger.error(this.errors.join(','));
                }
                else if (!this.selectedMedication.quantity || this.selectedMedication.quantity < 0) {
                    this.errors = ['Please enter a valid quantity to add this ingredient.'];
                    this.logger.error(this.errors.join(','));
                }
                else if (!this.selectedMedication.quantity_unit_of_measure) {
                    this.errors = ['Please select a QUOM for this ingredient.'];
                    this.logger.error(this.errors.join(','));
                }
                else {
                    this.selectedMedication = new consolo.domain.CompoundIngredient(this.selectedMedication);
                    this.compound.ingredients.push(this.selectedMedication);
                    this.compound.ingredients = [].concat(this.compound.ingredients);
                    this.setIngredientDisplay();
                    this.selectedMedication = null;
                }
            };
            CompoundController.prototype.setIngredientDisplay = function () {
                this.compound.ingredientsDisplay = this.compound.ingredients
                    .map(function (ingredient) {
                    return ingredient.name;
                })
                    .join(', ');
            };
            CompoundController.prototype.setMedicationOnSelection = function () {
                var _this = this;
                if (this.selectedMedication) {
                    this.CompoundsService.strengthForms.some(function (strengthForm) {
                        if (_this.selectedMedication.form === strengthForm.description) {
                            _this.selectedMedication.strength_form = strengthForm;
                            return true;
                        }
                    });
                    this.CompoundsService.quantityUnitOfMeasures.some(function (quom) {
                        if (_this.selectedMedication.form === quom.description) {
                            _this.selectedMedication.quantity_unit_of_measure = quom;
                            return true;
                        }
                    });
                }
            };
            CompoundController.prototype.sortIngredients = function () {
                /*if (this.compound.ingredients.length) {
                  this.compound.ingredients.sort(
                    (a: domain.CompoundIngredient, b: domain.CompoundIngredient) => {
                      if (a.controlled_substance_code && !b.controlled_substance_code) {
                        return -1;
                      } else if (!a.controlled_substance_code && b.controlled_substance_code) {
                        return 1;
                      } else if (
                        parseInt(a.controlled_substance_code, 10) < parseInt(b.controlled_substance_code, 10)
                      ) {
                        return -1;
                      } else if (
                        parseInt(a.controlled_substance_code, 10) > parseInt(b.controlled_substance_code, 10)
                      ) {
                        return 1;
                      } else {
                        return b.quantity - a.quantity;
                      }
                    }
                  );
                  this.compound.ingredients = [].concat(this.compound.ingredients);
                } else {
                  this.compound.ingredients = [];
                }
                */
                this.compound.ingredients = [].concat(this.compound.ingredients);
                this.setIngredientDisplay();
            };
            CompoundController.prototype.moveDown = function (index) {
                var ingredient = this.compound.ingredients[index];
                this.compound.ingredients[index] = this.compound.ingredients[index + 1];
                this.compound.ingredients[index + 1] = ingredient;
                this.setIngredientDisplay();
            };
            CompoundController.prototype.moveUp = function (index) {
                var ingredient = this.compound.ingredients[index];
                this.compound.ingredients[index] = this.compound.ingredients[index - 1];
                this.compound.ingredients[index - 1] = ingredient;
                this.setIngredientDisplay();
            };
            CompoundController.prototype.removeIngredient = function (index) {
                this.compound.ingredients.splice(index, 1);
                this.setIngredientDisplay();
            };
            CompoundController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.compound.strength_form_id) {
                    this.errors.push('Preparation must be selected.');
                }
                if (!this.compound.quantity_unit_of_measure_id) {
                    this.errors.push('Quantity Unit of Measure must be selected.');
                }
                if (this.compound.ingredients.length === 0) {
                    this.errors.push('You must select at least one ingredient.');
                }
                if (this.errors.length) {
                    this.logger.error(this.errors.join(', '));
                }
                else {
                    var loggerMessage_1 = this.compound.id ? 'updated' : 'created';
                    this.saving = true;
                    this.CompoundsService.upsert(this.compound)
                        .then(function () {
                        _this.logger.success('The compound has been ' + loggerMessage_1 + ' successfully.');
                        _this.$state.go('compounds');
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            CompoundController.$inject = [
                'logger',
                '$state',
                'SessionService',
                'CompoundsService',
                'MedicationService',
                '$q',
                'MedicationFavoritesService',
                'MedicationFormularyService',
            ];
            return CompoundController;
        }());
        common.CompoundController = CompoundController;
        angular.module('consolo').controller('CompoundController', CompoundController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CompoundsDataTableService = /** @class */ (function (_super) {
            __extends(CompoundsDataTableService, _super);
            function CompoundsDataTableService($http, $interval, $state, $mdDialog, CompoundsService, logger) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.$state = $state;
                _this.$mdDialog = $mdDialog;
                _this.CompoundsService = CompoundsService;
                _this.logger = logger;
                _this.loadingData = false;
                _this.showFilters = false;
                _this.hideSearchTerm = true;
                _this.activate();
                return _this;
            }
            CompoundsDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/compounds';
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            type: 'short-text',
                        },
                        {
                            title: 'Ingredients',
                            field: 'ingredients',
                            type: 'short-text',
                            formatValueFn: function (compound) {
                                return compound.ingredients.map(function (ingredient) {
                                    return ingredient.name;
                                });
                            },
                        },
                        {
                            title: 'All Ingredients On Formulary',
                            field: 'on_formulary',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Clone',
                                icon: 'call_split',
                                callbackFn: function (compound) {
                                    _this.$state.go('compounds.clone', {
                                        compoundId: compound.id,
                                    });
                                },
                                conditionalHideFn: function (compound) {
                                    return !_this.CompoundsService.createPermission;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (compound) {
                                    _this.$state.go('compounds.edit', {
                                        compoundId: compound.id,
                                    });
                                },
                                conditionalHideFn: function (compound) {
                                    return !_this.CompoundsService.updatePermission || compound.used_as_medication;
                                },
                            },
                            {
                                label: 'Discontinue',
                                icon: 'delete_sweep',
                                callbackFn: function (compound) {
                                    return _this.CompoundsService.discontinue(compound.id)
                                        .then(function (discontinuedCompound) {
                                        _this.logger.success('The compound has been discontinued.');
                                        _this.search();
                                        return true;
                                    })
                                        .catch(function (errors) {
                                        _this.logger.error(errors.data.errors.join(', '));
                                        return false;
                                    });
                                },
                                conditionalHideFn: function (compound) {
                                    return !_this.CompoundsService.createPermission;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (compound) {
                                    _this.$state.go('compounds.view', {
                                        compoundId: compound.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.CompoundsService.viewPermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (compound) {
                                    var confirm = _this.$mdDialog
                                        .confirm()
                                        .title('Confirmation Required')
                                        .htmlContent('Are you sure want to remove this compound?')
                                        .ariaLabel('Removal Confirmation')
                                        .ok('Ok')
                                        .cancel('Cancel');
                                    return _this.$mdDialog.show(confirm).then(function () {
                                        return _this.CompoundsService.delete(compound.id).then(function () {
                                            _this.logger.success('The Compound has been removed.');
                                            _this.search();
                                            return true;
                                        });
                                    });
                                },
                                conditionalHideFn: function (compound) {
                                    return !_this.CompoundsService.deletePermission || compound.used_as_medication;
                                },
                            },
                        ],
                    },
                };
            };
            CompoundsDataTableService.prototype.reset = function () {
                this.search();
            };
            CompoundsDataTableService.prototype.search = function () {
                var _this = this;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl && _this.tableControl.loadSelectData && !_this.loadingData) {
                        _this.loadingData = true;
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                            _this.data = _this.data.map(function (compound) {
                                compound.ingredients = compound.ingredients.map(function (ingredient) {
                                    return new consolo.domain.CompoundIngredient(ingredient);
                                });
                                return compound;
                            });
                        });
                    }
                }, 500, 20);
            };
            CompoundsDataTableService.$inject = ['$http', '$interval', '$state', '$mdDialog', 'CompoundsService', 'logger'];
            return CompoundsDataTableService;
        }(csg.datatable.ApiService));
        services.CompoundsDataTableService = CompoundsDataTableService;
        angular.module('consolo').service('CompoundsDataTableService', CompoundsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('compounds', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/medications/compounds/compounds.html',
            controller: 'CompoundsController as $ctrl',
        });
        var CompoundsController = /** @class */ (function () {
            function CompoundsController(logger, $state, Session, CompoundsDataTableService, CompoundsService) {
                this.logger = logger;
                this.$state = $state;
                this.Session = Session;
                this.CompoundsDataTableService = CompoundsDataTableService;
                this.CompoundsService = CompoundsService;
                this.readonly = true;
                this.CompoundsDataTableService.search();
            }
            CompoundsController.$inject = [
                'logger',
                '$state',
                'SessionService',
                'CompoundsDataTableService',
                'CompoundsService',
            ];
            return CompoundsController;
        }());
        common.CompoundsController = CompoundsController;
        angular.module('consolo').controller('CompoundsController', CompoundsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CompoundsService = /** @class */ (function () {
            function CompoundsService(PermissionsService, $http, options) {
                this.PermissionsService = PermissionsService;
                this.$http = $http;
                this.options = options;
                this.updatePermission = false;
                this.deletePermission = false;
                this.viewPermission = false;
                this.createPermission = false;
                this.importPermission = false;
                this.apiUrl = '/api/v1/compounds';
                this.updatePermission = this.PermissionsService.can('update', 'compounds');
                this.createPermission = this.PermissionsService.can('create', 'compounds');
                this.deletePermission = this.PermissionsService.can('destroy', 'compounds');
                this.viewPermission = this.PermissionsService.can('show', 'compounds');
                this.quantityUnitOfMeasures = options.get('quantity_unit_of_measure');
                this.strengthForms = options.get('strength_form');
            }
            CompoundsService.prototype.delete = function (id) {
                return this.$http.delete(this.apiUrl + '/' + id);
            };
            CompoundsService.prototype.discontinue = function (id) {
                return this.$http.post(this.apiUrl + '/' + id + '/discontinue', {});
            };
            CompoundsService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    var compound = response.data;
                    compound.ingredients = compound.ingredients.map(function (ingredient) {
                        return new consolo.domain.CompoundIngredient(ingredient);
                    });
                    return compound;
                });
            };
            CompoundsService.prototype.upsert = function (compound) {
                if (compound.id) {
                    return this.$http.patch(this.apiUrl + '/' + compound.id, compound);
                }
                else {
                    return this.$http.post(this.apiUrl, compound);
                }
            };
            CompoundsService.$inject = ['PermissionsService', '$http', 'options'];
            return CompoundsService;
        }());
        services.CompoundsService = CompoundsService;
        angular.module('consolo').service('CompoundsService', CompoundsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('emar', {
            bindings: {
                medication: '<',
            },
            transclude: true,
            templateUrl: '/y/templates/medications/emar/emar-summary.html',
            controller: [
                '$mdDialog',
                'MedicationService',
                function ($mdDialog, MedicationService) {
                    this.$mdDialog = $mdDialog;
                    this.MedicationService = MedicationService;
                    this.edit = edit;
                    this.startDate = null;
                    this.endDate = null;
                    this.getDisplay = getDisplay;
                    this.display = this.getDisplay();
                    function edit() {
                        var _this = this;
                        return this.$mdDialog
                            .show({
                            clickOutsideToClose: true,
                            templateUrl: '/y/templates/medications/emar/emar-details.html',
                            controller: [
                                '$mdDialog',
                                'MedicationService',
                                'medication',
                                function ($mdDialog, MedicationService, medication) {
                                    this.$mdDialog = $mdDialog;
                                    this.MedicationService = MedicationService;
                                },
                            ],
                            controllerAs: 'vm',
                            bindToController: true,
                            multiple: true,
                            skipHide: true,
                            preserveScope: true,
                            locals: { medication: this.medication },
                        })
                            .finally(function () {
                            _this.display = _this.getDisplay();
                        });
                    }
                    function getDisplay() {
                        if (!this.medication || !this.medication.is_scheduled) {
                            this.startDate = null;
                            this.endDate = null;
                        }
                        else if (this.medication.dosage_frequency_end_datetime &&
                            this.medication.dosage_frequency_start_datetime) {
                            this.startDate = new Date(this.medication.dosage_frequency_start_datetime).toDateString();
                            this.endDate = new Date(this.medication.dosage_frequency_end_datetime).toDateString();
                        }
                    }
                },
            ],
            controllerAs: 'vm',
        });
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var FormularyExceptionRequestsDataTableService = /** @class */ (function (_super) {
            __extends(FormularyExceptionRequestsDataTableService, _super);
            function FormularyExceptionRequestsDataTableService($http, $state, $q, $mdDialog, $interval, FormularyExceptionRequestService, logger, MedicationService) {
                var _this = _super.call(this, $http) || this;
                _this.$state = $state;
                _this.$q = $q;
                _this.$mdDialog = $mdDialog;
                _this.$interval = $interval;
                _this.FormularyExceptionRequestService = FormularyExceptionRequestService;
                _this.logger = logger;
                _this.MedicationService = MedicationService;
                _this.showFilters = true;
                _this.loadingData = false;
                _this.title = 'Formulary Exception Requests';
                _this.hideSearchTerm = true;
                _this.searchOnReset = true;
                _this.apiSelectUrl = '/api/v1/formulary_exception_requests';
                _this.filters = new consolo.domain.FormularyExceptionRequestsFilter();
                _this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    rowHideFn: function (FormularyExceptionRequest) {
                        return FormularyExceptionRequest.approved || FormularyExceptionRequest.declined;
                    },
                    options: {
                        showExpandCollapseAll: false,
                        defaultPageSize: 50,
                    },
                    columns: [
                        {
                            title: 'Patient',
                            field: 'patient.name',
                            type: csg.datatable.ColumnTypes.link,
                            linkRoute: 'patients.dashboard({patientId: $patient_id})',
                        },
                        {
                            title: 'Office',
                            field: 'office.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Medication',
                            field: 'name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Requested Date',
                            field: 'created_at',
                            shortTitle: 'Order Date',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'User',
                            field: 'user.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Reason for Request',
                            field: 'reason_for_request',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: '',
                            field: 'approved',
                            type: csg.datatable.ColumnTypes.button,
                            callbackFn: function (formularyExceptionRequest) {
                                if (!formularyExceptionRequest.saving) {
                                    _this.MedicationService.clearMedications();
                                    formularyExceptionRequest.saving = true;
                                    return _this.FormularyExceptionRequestService.approve(formularyExceptionRequest)
                                        .then(function (updatedFormularyExceptionRequest) {
                                        angular.copy(updatedFormularyExceptionRequest, formularyExceptionRequest);
                                        _this.logger.success('The request has been approved.');
                                        return true;
                                    })
                                        .finally(function () {
                                        formularyExceptionRequest.saving = false;
                                    });
                                }
                                else {
                                    return false;
                                }
                            },
                            formatValueFn: function () {
                                return 'Approve';
                            },
                            conditionalHideFn: function (formularyExceptionRequest) {
                                return (formularyExceptionRequest.saving ||
                                    !_this.FormularyExceptionRequestService.approvePermission);
                            },
                        },
                        {
                            title: '',
                            field: 'declined',
                            type: csg.datatable.ColumnTypes.button,
                            callbackFn: function (formularyExceptionRequest) {
                                if (!formularyExceptionRequest.saving) {
                                    _this.MedicationService.clearMedications();
                                    formularyExceptionRequest.saving = true;
                                    return _this.FormularyExceptionRequestService.decline(formularyExceptionRequest)
                                        .then(function (updatedFormularyExceptionRequest) {
                                        angular.copy(updatedFormularyExceptionRequest, formularyExceptionRequest);
                                        _this.logger.success('The request has been declined.');
                                        return true;
                                    })
                                        .finally(function () {
                                        formularyExceptionRequest.saving = false;
                                    });
                                }
                                else {
                                    return false;
                                }
                            },
                            formatValueFn: function () {
                                return 'Decline';
                            },
                            conditionalHideFn: function (formularyExceptionRequest) {
                                return (formularyExceptionRequest.saving ||
                                    !_this.FormularyExceptionRequestService.approvePermission);
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'add',
                                callbackFn: function (FormularyExceptionRequest) {
                                    return _this.$state.go('patients.formulary-exception-requests.review', {
                                        formularyExceptionRequestId: FormularyExceptionRequest.id,
                                        patientId: FormularyExceptionRequest.patient_id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.FormularyExceptionRequestService.viewPermission;
                                },
                            },
                        ],
                    },
                };
                return _this;
            }
            FormularyExceptionRequestsDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.FormularyExceptionRequestsFilter();
                this.search();
            };
            FormularyExceptionRequestsDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = this.filters.getFilters();
                this.params['needs_decision'] = true;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl && _this.tableControl.loadSelectData !== undefined) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            FormularyExceptionRequestsDataTableService.$inject = [
                '$http',
                '$state',
                '$q',
                '$mdDialog',
                '$interval',
                'FormularyExceptionRequestService',
                'logger',
                'MedicationService',
            ];
            return FormularyExceptionRequestsDataTableService;
        }(csg.datatable.ApiService));
        services.FormularyExceptionRequestsDataTableService = FormularyExceptionRequestsDataTableService;
        angular
            .module('consolo')
            .service('FormularyExceptionRequestsDataTableService', FormularyExceptionRequestsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('formularyExceptionRequest', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/medications/formulary-exception-requests/formulary-exception-request.html',
            controller: 'FormularyExceptionRequestController as $ctrl',
        });
        var FormularyExceptionRequestController = /** @class */ (function () {
            function FormularyExceptionRequestController(MedicationService, logger, IndicatorsService, CurrentPatient, $stateParams, $mdDialog, $http, $state, FormularyExceptionRequestService) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.logger = logger;
                this.IndicatorsService = IndicatorsService;
                this.CurrentPatient = CurrentPatient;
                this.$stateParams = $stateParams;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.$state = $state;
                this.FormularyExceptionRequestService = FormularyExceptionRequestService;
                this.filterMedispanOnly = true;
                this.errors = [];
                this.saving = false;
                this.showDosageTemplate = false;
                this.FormularyExceptionRequest = {
                    icd10s: [],
                    physician_id: this.MedicationService.currentMedication
                        ? this.MedicationService.currentMedication.physician_id
                        : null,
                    medication_values: this.MedicationService.currentMedication,
                    patient_id: CurrentPatient.patient_id
                        ? CurrentPatient.patient_id
                        : CurrentPatient.patient.id,
                };
                if (this.MedicationService.currentMedication &&
                    this.MedicationService.currentMedication.name) {
                    this.selectedMedication = this.MedicationService.currentMedication;
                    this.selectedMedication.display_name = this.MedicationService.currentMedication.name;
                }
                if (!this.FormularyExceptionRequest.medication_values) {
                    this.FormularyExceptionRequest.medication_values = { orderdate: new Date() };
                }
                if (!this.FormularyExceptionRequest.medication_values.orderdate) {
                    this.FormularyExceptionRequest.medication_values.orderdate = new Date();
                }
                if (this.FormularyExceptionRequest.physician_id) {
                    this.$http
                        .get('/api/v1/physicians/' + this.FormularyExceptionRequest.physician_id)
                        .then(function (response) {
                        _this.FormularyExceptionRequest.physician = response.data;
                    });
                }
            }
            FormularyExceptionRequestController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (this.FormularyExceptionRequest.physician) {
                    this.FormularyExceptionRequest.physician_id = this.FormularyExceptionRequest.physician.id;
                }
                if (!this.FormularyExceptionRequest.physician_id) {
                    this.errors.push('You must select a Physician.');
                }
                if (!this.FormularyExceptionRequest.medication_values.name) {
                    this.errors.push('You must select a Medication.');
                }
                else {
                    this.FormularyExceptionRequest.name = this.FormularyExceptionRequest.medication_values.name;
                }
                if (!this.FormularyExceptionRequest.reason_for_request) {
                    this.errors.push('You must select a reason for this request.');
                }
                if (!this.FormularyExceptionRequest.medication_values.dosageRoute) {
                    this.errors.push('You must supply a Dosage Route.');
                }
                if (!this.FormularyExceptionRequest.medication_values.dosage_unit) {
                    this.errors.push('You must supply a Dosage Unit.');
                }
                if (!this.FormularyExceptionRequest.medication_values.dosage_amount_low) {
                    this.errors.push('You must supply a Low Dose Amount.');
                }
                if (this.errors.length) {
                    this.logger.error(this.errors.join(', '));
                }
                else {
                    this.saving = true;
                    this.$http
                        .post('/api/v1/formulary_exception_requests', this.FormularyExceptionRequest)
                        .then(function (response) {
                        _this.logger.success('The request has been created.');
                        _this.$state.go('patients.dashboard');
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            FormularyExceptionRequestController.prototype.resetDosageTimes = function () {
                this.MedicationService.populateDosageTimes(this.FormularyExceptionRequest.medication_values);
                this.setShowDosageTemplate(false);
            };
            FormularyExceptionRequestController.prototype.updateFrequency = function () {
                var _this = this;
                this.MedicationService.getFrequency(this.FormularyExceptionRequest.medication_values).then(function (frequency) {
                    _this.FormularyExceptionRequest.medication_values.frequency = frequency;
                });
            };
            FormularyExceptionRequestController.prototype.setShowDosageTemplate = function (loadingMedication) {
                var _this = this;
                switch (this.FormularyExceptionRequest.medication_values.dosage_frequency_unit) {
                    case 'hour':
                    case 'day':
                    case 'week':
                        this.showDosageTemplate = this.FormularyExceptionRequest.medication_values.is_scheduled;
                        break;
                    case 'one_time_dose':
                        if (!loadingMedication) {
                            this.$mdDialog
                                .show({
                                templateUrl: '/y/templates/patients/sections/medications/one-time-dose-modal.html',
                                controller: 'OneTimeDoseController as vm',
                                locals: { medication: this.FormularyExceptionRequest.medication_values },
                                multiple: true,
                                skipHide: true,
                                preserveScope: true,
                            })
                                .then(function () {
                                _this.logger.success('Medication automatically scheduled to go on EMAR.');
                            });
                        }
                        break;
                    default:
                        this.showDosageTemplate = false;
                }
            };
            FormularyExceptionRequestController.$inject = [
                'MedicationService',
                'logger',
                'IndicatorsService',
                'CurrentPatient',
                '$stateParams',
                '$mdDialog',
                '$http',
                '$state',
                'FormularyExceptionRequestService',
            ];
            return FormularyExceptionRequestController;
        }());
        emarComponents.FormularyExceptionRequestController = FormularyExceptionRequestController;
        angular
            .module('consolo')
            .controller('FormularyExceptionRequestController', FormularyExceptionRequestController);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var FormularyExceptionRequestService = /** @class */ (function () {
            function FormularyExceptionRequestService(logger, $http, $q, PermissionsService, Session) {
                this.logger = logger;
                this.$http = $http;
                this.$q = $q;
                this.PermissionsService = PermissionsService;
                this.Session = Session;
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.approvePermission = false;
                this.apiUrl = '/api/v1/formulary_exception_requests';
                this.updatePermission = this.PermissionsService.can('update', 'formulary_exception_requests');
                this.createPermission = this.PermissionsService.can('create', 'formulary_exception_requests');
                this.deletePermission = this.PermissionsService.can('destroy', 'formulary_exception_requests');
                this.viewPermission = this.PermissionsService.can('show', 'formulary_exception_requests');
                this.approvePermission = this.PermissionsService.can('approve', 'formulary_exception_requests');
                var session = Session.get();
                this.user_dashboard_url = session.user.dashboard_url;
            }
            FormularyExceptionRequestService.prototype.approve = function (FormularyExceptionRequest) {
                return this.$http
                    .post(this.apiUrl + '/' + FormularyExceptionRequest.id + '/approve', {
                    reason_for_decision: FormularyExceptionRequest.reason_for_decision,
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            FormularyExceptionRequestService.prototype.decline = function (FormularyExceptionRequest) {
                return this.$http
                    .post(this.apiUrl + '/' + FormularyExceptionRequest.id + '/decline', {
                    reason_for_decision: FormularyExceptionRequest.reason_for_decision,
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            FormularyExceptionRequestService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            FormularyExceptionRequestService.prototype.upsert = function (FormularyExceptionRequest) {
                var _this = this;
                if (FormularyExceptionRequest.id) {
                    return this.$http
                        .patch(this.apiUrl + '/' + FormularyExceptionRequest.id, FormularyExceptionRequest)
                        .then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, FormularyExceptionRequest).then(function (response) {
                        _this.logger.success('The request has been created.');
                        return response.data;
                    });
                }
            };
            FormularyExceptionRequestService.prototype.populateDosageTimes = function (request) {
                request.medication_values.dosage_times = [];
                if (request.medication_values.dosage_frequency && request.medication_values.is_scheduled) {
                    var count = request.medication_values.dosage_frequency;
                    if (request.medication_values.dosage_frequency_unit === 'hour') {
                        count = 24 / request.medication_values.dosage_frequency;
                    }
                    else {
                        count = request.medication_values.dosage_frequency;
                    }
                    for (var i = 0; i < count; i++) {
                        request.medication_values.dosage_times.push({});
                    }
                }
            };
            FormularyExceptionRequestService.$inject = [
                'logger',
                '$http',
                '$q',
                'PermissionsService',
                'SessionService',
            ];
            return FormularyExceptionRequestService;
        }());
        services.FormularyExceptionRequestService = FormularyExceptionRequestService;
        angular
            .module('consolo')
            .service('FormularyExceptionRequestService', FormularyExceptionRequestService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('formularyExceptionRequests', {
            bindings: {},
            templateUrl: '/y/templates/medications/formulary-exception-requests/formulary-exception-requests.html',
            controller: 'FormularyExceptionRequestsController as $ctrl',
        });
        var FormularyExceptionRequestsController = /** @class */ (function () {
            function FormularyExceptionRequestsController(CurrentPatient, FormularyExceptionRequestsDataTableService, FormularyExceptionRequestService) {
                this.CurrentPatient = CurrentPatient;
                this.FormularyExceptionRequestsDataTableService = FormularyExceptionRequestsDataTableService;
                this.FormularyExceptionRequestService = FormularyExceptionRequestService;
                this.FormularyExceptionRequestsDataTableService.search();
            }
            FormularyExceptionRequestsController.$inject = [
                'CurrentPatient',
                'FormularyExceptionRequestsDataTableService',
                'FormularyExceptionRequestService',
            ];
            return FormularyExceptionRequestsController;
        }());
        emarComponents.FormularyExceptionRequestsController = FormularyExceptionRequestsController;
        angular
            .module('consolo')
            .controller('FormularyExceptionRequestsController', FormularyExceptionRequestsController);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('reviewFormularyExceptionRequest', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/medications/formulary-exception-requests/review-process.html',
            controller: 'ReviewRequestController as $ctrl',
        });
        var ReviewRequestController = /** @class */ (function () {
            function ReviewRequestController(logger, $stateParams, $http, $state, FormularyExceptionRequestService, CurrentPatient, MedicationService) {
                var _this = this;
                this.logger = logger;
                this.$stateParams = $stateParams;
                this.$http = $http;
                this.$state = $state;
                this.FormularyExceptionRequestService = FormularyExceptionRequestService;
                this.CurrentPatient = CurrentPatient;
                this.MedicationService = MedicationService;
                this.errors = [];
                this.saving = false;
                this.MedicationService.isReady().then(function () {
                    _this.FormularyExceptionRequestService.get($stateParams.formularyExceptionRequestId).then(function (formularyExceptionRequest) {
                        _this.formularyExceptionRequest = formularyExceptionRequest;
                    });
                });
            }
            ReviewRequestController.prototype.approve = function () {
                var _this = this;
                this.saving = true;
                this.FormularyExceptionRequestService.approve(this.formularyExceptionRequest)
                    .then(function (response) {
                    _this.$state.go('formulary-exception-requests');
                }, function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                })
                    .finally(function () {
                    _this.saving = false;
                });
            };
            ReviewRequestController.prototype.decline = function () {
                var _this = this;
                this.saving = true;
                this.FormularyExceptionRequestService.decline(this.formularyExceptionRequest)
                    .then(function (resposne) {
                    _this.$state.go('formulary-exception-requests');
                }, function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                })
                    .finally(function () {
                    _this.saving = false;
                });
            };
            ReviewRequestController.$inject = [
                'logger',
                '$stateParams',
                '$http',
                '$state',
                'FormularyExceptionRequestService',
                'CurrentPatient',
                'MedicationService',
            ];
            return ReviewRequestController;
        }());
        emarComponents.ReviewRequestController = ReviewRequestController;
        angular.module('consolo').controller('ReviewRequestController', ReviewRequestController);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CancelMedicationModalController = /** @class */ (function () {
            function CancelMedicationModalController($mdDialog, medication, MedicationService, $http, logger, CurrentPatientService) {
                this.$mdDialog = $mdDialog;
                this.medication = medication;
                this.MedicationService = MedicationService;
                this.$http = $http;
                this.logger = logger;
                this.CurrentPatientService = CurrentPatientService;
                this.medicationTransactions = [];
                this.selectableTransactions = [];
                this.showSummary = false;
                this.loadingSpinner = false;
                this.prescriber = this.MedicationService.currentUser.physician;
                var pharmacies = {};
                if (medication.medication_transactions.length) {
                    medication.medication_transactions.forEach(function (transaction) {
                        //Only check if there is a surescripts pharmacy for this transaction, don't care if not
                        if (transaction.surescripts_pharmacy_id &&
                            (!pharmacies[transaction.surescripts_pharmacy_id] ||
                                pharmacies[transaction.surescripts_pharmacy_id].effective_datetime >
                                    transaction.effective_datetime)) {
                            pharmacies[transaction.surescripts_pharmacy_id] = transaction;
                        }
                    });
                }
                for (var _i = 0, _a = Object.keys(pharmacies); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this.selectableTransactions.push(pharmacies[key]);
                }
                this.ingredientsTableLayout = {
                    id_field: 'name',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            type: csg.datatable.ColumnTypes.long_text,
                            formatValueFn: function (ingredient) {
                                return (ingredient.name +
                                    ' ' +
                                    ingredient.strength +
                                    ingredient.strength_unit +
                                    ' ' +
                                    ingredient.form);
                            },
                        },
                        {
                            title: 'Dosage',
                            field: 'dosage',
                            formatValueFn: function (ingredient) {
                                return ingredient.quantity + ' ' + ingredient.quantity_unit_of_measure.description;
                            },
                        },
                    ],
                };
            }
            CancelMedicationModalController.prototype.continue = function () {
                if (this.medicationTransactions.length === 0) {
                    this.errors = ['You must select a pharmacy to send the cancel message to.'];
                }
                else {
                    this.showSummary = true;
                }
            };
            CancelMedicationModalController.prototype.rxCancel = function () {
                var _this = this;
                var medication_transaction_ids = this.medicationTransactions.map(function (transaction) {
                    return transaction.id;
                });
                this.loadingSpinner = true;
                return this.$http
                    .post('/api/v1/medications/' + this.medication.id + '/rx_cancel', {
                    medication_transaction_ids: medication_transaction_ids,
                    prescriber_id: this.prescriber.id,
                })
                    .then(function (response) {
                    _this.logger.success('The medication has sent a cancellation.');
                    _this.$mdDialog.hide(response.data);
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(errors.data.errors.join(', '));
                })
                    .finally(function () {
                    _this.loadingSpinner = false;
                });
            };
            CancelMedicationModalController.$inject = [
                '$mdDialog',
                'medication',
                'MedicationService',
                '$http',
                'logger',
                'CurrentPatient',
            ];
            return CancelMedicationModalController;
        }());
        angular
            .module('consolo')
            .controller('CancelMedicationModalController', CancelMedicationModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('dosageRoute', {
            bindings: { object: '<', readonly: '<' },
            templateUrl: '/y/templates/medications/medication/dosage-route.html',
            controller: 'DosageRouteController as $ctrl',
        });
        var DosageRouteController = /** @class */ (function () {
            function DosageRouteController(MedicationService, $scope) {
                this.MedicationService = MedicationService;
                this.$scope = $scope;
            }
            DosageRouteController.$inject = ['MedicationService', '$scope'];
            return DosageRouteController;
        }());
        common.DosageRouteController = DosageRouteController;
        angular.module('consolo').controller('DosageRouteController', DosageRouteController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('dosageUnit', {
            bindings: { object: '<', readonly: '<' },
            templateUrl: '/y/templates/medications/medication/dosage-unit.html',
            controller: 'DosageUnitController as $ctrl',
        });
        var DosageUnitController = /** @class */ (function () {
            function DosageUnitController(MedicationService, $scope) {
                this.MedicationService = MedicationService;
                this.$scope = $scope;
            }
            DosageUnitController.$inject = ['MedicationService', '$scope'];
            return DosageUnitController;
        }());
        common.DosageUnitController = DosageUnitController;
        angular.module('consolo').controller('DosageUnitController', DosageUnitController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('glucoseScaleModal', {
            bindings: { object: '<', readonly: '<' },
            templateUrl: '/y/templates/medications/medication/glucose-scale-modal.html',
            controller: 'GlucoseScaleModalController as $ctrl',
        });
        var GlucoseScaleModalController = /** @class */ (function () {
            function GlucoseScaleModalController($mdDialog) {
                this.$mdDialog = $mdDialog;
            }
            GlucoseScaleModalController.prototype.close = function () {
                this.$mdDialog.hide();
            };
            GlucoseScaleModalController.$inject = ['$mdDialog'];
            return GlucoseScaleModalController;
        }());
        common.GlucoseScaleModalController = GlucoseScaleModalController;
        angular.module('consolo').controller('GlucoseScaleModalController', GlucoseScaleModalController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var SlidingScaleRange = consolo.domain.SlidingScaleRange;
        'use strict';
        angular.module('consolo').component('glucoseSlidingScale', {
            bindings: { object: '<', readonly: '<' },
            templateUrl: '/y/templates/medications/medication/glucose-sliding-scale.html',
            controller: 'GlucoseSlidingScaleController as $ctrl',
        });
        var GlucoseSlidingScaleController = /** @class */ (function () {
            function GlucoseSlidingScaleController(MedicationService, $mdDialog, $scope) {
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.$scope = $scope;
                this.medication = this.object;
                if (this.medication.sliding_scale) {
                    if (this.medication.sliding_scale.sliding_scale_ranges.length <= 2) {
                        this.deleteDisabled = true;
                    }
                }
            }
            GlucoseSlidingScaleController.prototype.isLastLine = function (index) {
                return (index === this.medication.sliding_scale.sliding_scale_ranges.length - 1);
            };
            GlucoseSlidingScaleController.prototype.isFirstLine = function (index) {
                return (index === 0);
            };
            GlucoseSlidingScaleController.prototype.lowRangeChanged = function (index, value) {
                // adjust high range of previous row
                if (index > 0) {
                    this.medication.sliding_scale.sliding_scale_ranges[index - 1].high_range = value - 1;
                }
                this.setDoses();
            };
            GlucoseSlidingScaleController.prototype.setDoses = function () {
                var ss = this.medication.sliding_scale;
                var ss_length = ss['sliding_scale_ranges'].length;
                var low_unit = ss['sliding_scale_ranges'][0]['units'];
                var high_unit = ss['sliding_scale_ranges'][ss_length - 1]['units'];
                this.medication.dosage_amount_low = low_unit;
                this.medication.dosage_amount_high = high_unit;
                this.updateFrequency();
            };
            GlucoseSlidingScaleController.prototype.updateFrequency = function () {
                var _this = this;
                this.MedicationService.getFrequency(this.medication).then(function (frequency) {
                    _this.medication.frequency = frequency;
                });
            };
            GlucoseSlidingScaleController.prototype.highRangeChanged = function (index, value) {
                // adjust low range of next row
                if (!this.isLastLine(index)) {
                    var range = this.medication.sliding_scale.sliding_scale_ranges[index + 1];
                    if (this.isLastLine(index + 1)) {
                        range.high_range = value;
                    }
                    else {
                        range.low_range = value + 1;
                        // adjust high range of next row if it is under the low range
                        if (range.high_range < range.low_range) {
                            range.high_range = value + 1;
                        }
                    }
                }
            };
            // Always inserts a new row before the last element
            GlucoseSlidingScaleController.prototype.addSlidingScaleElement = function () {
                var index = (this.medication.sliding_scale.sliding_scale_ranges.length > 0) ? this.medication.sliding_scale.sliding_scale_ranges.length - 1 : 0;
                var prev_row = this.medication.sliding_scale.sliding_scale_ranges[index - 1];
                var newElement = new SlidingScaleRange({ order: 0, low_range: prev_row.high_range + 1, high_range: prev_row.high_range + 1, units: 0, comments: "" });
                this.medication.sliding_scale.sliding_scale_ranges[index].high_range = prev_row.high_range + 1;
                this.medication.sliding_scale.sliding_scale_ranges.splice(index, 0, newElement);
                this.medication.sliding_scale.reorderRanges();
                if (this.medication.sliding_scale.sliding_scale_ranges.length > 2) {
                    this.deleteDisabled = false;
                }
            };
            GlucoseSlidingScaleController.prototype.deleteSlidingScaleElement = function (index) {
                if (this.isLastLine(index))
                    return;
                var ss = this.medication.sliding_scale;
                ss.sliding_scale_ranges.splice(index, 1);
                ss.reorderRanges();
                if (index === (ss.sliding_scale_ranges.length - 1)) {
                    // Next to last item was deleted so reassign the high range value for the last item
                    ss.sliding_scale_ranges[index].low_range = null;
                    ss.sliding_scale_ranges[index].high_range = ss.sliding_scale_ranges[index - 1].high_range;
                }
                else if (index > 0) {
                    ss.sliding_scale_ranges[index].low_range = ss.sliding_scale_ranges[index - 1].high_range + 1;
                }
                else {
                    this.medication.dosage_amount_low = ss.sliding_scale_ranges[0].units;
                }
                if (ss.sliding_scale_ranges.length <= 2) {
                    this.deleteDisabled = true;
                }
            };
            GlucoseSlidingScaleController.$inject = [
                'MedicationService',
                '$mdDialog',
                '$scope'
            ];
            return GlucoseSlidingScaleController;
        }());
        common.GlucoseSlidingScaleController = GlucoseSlidingScaleController;
        angular.module('consolo').controller('GlucoseSlidingScaleController', GlucoseSlidingScaleController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('medicationTitle', {
            bindings: { medication: '<', showFavorite: '<' },
            templateUrl: '/y/templates/medications/medication/medication-title.html',
            controller: 'MedicationTitleController as $ctrl',
        });
        var MedicationTitleController = /** @class */ (function () {
            function MedicationTitleController(logger, $mdMedia) {
                this.logger = logger;
                this.$mdMedia = $mdMedia;
                if (this.showFavorite === null || this.showFavorite === undefined) {
                    this.showFavorite = true;
                }
            }
            MedicationTitleController.$inject = ['logger', '$mdMedia'];
            return MedicationTitleController;
        }());
        common.MedicationTitleController = MedicationTitleController;
        angular.module('consolo').controller('MedicationTitleController', MedicationTitleController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('reorderModal', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication/reorder-modal.html',
            controller: 'ReorderModalController as $ctrl',
        });
        var ReorderModalController = /** @class */ (function () {
            function ReorderModalController(logger, MedicationService, $mdDialog, $state) {
                var _this = this;
                this.logger = logger;
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.medicationTransactions = [];
                this.MedicationService.getActiveCurrentMedications().then(function (medications) {
                    medications.forEach(function (medication) {
                        var medicationTransaction = _this.MedicationService.createReorderMedicationTransaction(medication);
                        _this.medicationTransactions.push(medicationTransaction);
                    });
                });
            }
            ReorderModalController.$inject = ['logger', 'MedicationService', '$mdDialog', '$state'];
            return ReorderModalController;
        }());
        common.ReorderModalController = ReorderModalController;
        angular
            .module('consolo')
            .controller('ReorderModalController', ReorderModalController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('resupplyConfirmationModal', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication/resupply-confirmation.modal.html',
            controller: 'ResupplyConfirmationModalController as $ctrl',
        });
        var ResupplyConfirmationModalController = /** @class */ (function () {
            function ResupplyConfirmationModalController(logger, MedicationService, $mdDialog, $state, CurrentPatient, $http) {
                var _this = this;
                this.logger = logger;
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                this.CurrentPatient = CurrentPatient;
                this.$http = $http;
                this.loading = false;
                this.medicationTransactionsLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Drug Description',
                            field: 'medication.display_name',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (medicationTransaction) {
                                _this.MedicationService.setMedFormDisplay(medicationTransaction);
                                var medication = medicationTransaction.medication;
                                return (medication.name +
                                    ' ' +
                                    medication.strength +
                                    ' ' +
                                    medicationTransaction.medFormDisplay);
                            },
                        },
                        {
                            title: 'Quantity Unit Of Measurement',
                            field: 'medication.quantity_unit_of_measure',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Sig',
                            field: 'notes',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (medicationTransaction) {
                                return (medicationTransaction.medication.frequency +
                                    ' ' +
                                    (medicationTransaction.notes ? medicationTransaction.notes : ''));
                            },
                        },
                        {
                            title: 'Notes',
                            field: 'notes_to_pharmacist',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Effective Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.date,
                        },
                    ],
                };
            }
            ResupplyConfirmationModalController.prototype.sendResupply = function () {
                var _this = this;
                this.loading = true;
                return this.$http
                    .post('/api/v1/medications/resupply', {
                    medication_transactions: this.MedicationService.resupplyMedicationTransactions,
                })
                    .then(function (response) {
                    _this.logger.success('The resupply message(s) have been sent.');
                    _this.$mdDialog.hide();
                    _this.$state.go('patients.medications');
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                })
                    .finally(function () {
                    _this.loading = false;
                });
            };
            ResupplyConfirmationModalController.$inject = [
                'logger',
                'MedicationService',
                '$mdDialog',
                '$state',
                'CurrentPatient',
                '$http',
            ];
            return ResupplyConfirmationModalController;
        }());
        common.ResupplyConfirmationModalController = ResupplyConfirmationModalController;
        angular
            .module('consolo')
            .controller('ResupplyConfirmationModalController', ResupplyConfirmationModalController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('resupplyModal', {
            bindings: { redoTransactions: '<' },
            templateUrl: '/y/templates/medications/medication/resupply-modal.html',
            controller: 'ResupplyModalController as $ctrl',
        });
        var ResupplyModalController = /** @class */ (function () {
            function ResupplyModalController(logger, MedicationService, $mdDialog, $state) {
                var _this = this;
                this.logger = logger;
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.$state = $state;
                if (this.redoTransactions ||
                    !this.MedicationService.medicationTransactions ||
                    this.MedicationService.medicationTransactions.length === 0) {
                    this.MedicationService.medicationTransactions = [];
                    this.MedicationService.getActiveMedications().then(function (medications) {
                        //If there is a newrx that is eprescribed then this medication is valid for resupply
                        medications.forEach(function (medication) {
                            if (medication.is_open_order && medication.current_medication_transaction) {
                                _this.MedicationService.medicationTransactions.push(_this.MedicationService.createMedicationTransactionFromTransaction('resupply', medication.current_medication_transaction, medication));
                            }
                            else {
                                //Loop through all transactions looking for a newrx that was e-prescribed
                                medication.medication_transactions.some(function (transaction) {
                                    if (['newrx', 'reorder'].indexOf(transaction.transaction_type) > -1 &&
                                        transaction.is_eprescribe) {
                                        _this.MedicationService.medicationTransactions.push(_this.MedicationService.createMedicationTransactionFromTransaction('resupply', transaction, medication));
                                        return true;
                                    }
                                });
                            }
                        });
                    });
                }
            }
            ResupplyModalController.$inject = ['logger', 'MedicationService', '$mdDialog', '$state'];
            return ResupplyModalController;
        }());
        common.ResupplyModalController = ResupplyModalController;
        angular.module('consolo').controller('ResupplyModalController', ResupplyModalController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('resupply', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication/resupply.html',
            controller: 'ResupplyController as $ctrl',
        });
        var ResupplyController = /** @class */ (function () {
            function ResupplyController(MedicationService, CurrentPatient, $mdDialog, logger) {
                this.MedicationService = MedicationService;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
            }
            ResupplyController.prototype.resupply = function () {
                this.validate();
                if (this.errors.length === 0) {
                    return this.$mdDialog.show({
                        template: '<md-dialog flex="90" flex-gt-sm="80" aria-label="Add Medication Favorites">' +
                            '    <resupply-confirmation-modal></resupply-confirmation-modal>' +
                            '</md-dialog>',
                    });
                }
                else {
                    this.logger.error(this.errors.join(', '));
                }
            };
            ResupplyController.prototype.validate = function () {
                var _this = this;
                this.errors = [];
                this.MedicationService.resupplyMedicationTransactions.forEach(function (medicationTransaction) {
                    if (!medicationTransaction.medication.is_open_order &&
                        (isNaN(medicationTransaction.quantity) ||
                            medicationTransaction.quantity === undefined ||
                            medicationTransaction.quantity <= 0)) {
                        _this.errors.push(medicationTransaction.medication.name + ' requires a valid quantity.');
                    }
                    if (!medicationTransaction.needed_no_later_than) {
                        _this.errors.push('You must enter a Needed No Later Than date.');
                    }
                    if (medicationTransaction.medication.is_open_order) {
                        return null;
                    }
                    if (medicationTransaction.quantity > medicationTransaction.originalQuantity &&
                        !medicationTransaction.medication.isNonControlledSubstance()) {
                        _this.errors.push(medicationTransaction.medication.name +
                            ' quantity can not be greater than the original quantity of ' +
                            medicationTransaction.originalQuantity);
                    }
                });
            };
            ResupplyController.$inject = ['MedicationService', 'CurrentPatient', '$mdDialog', 'logger'];
            return ResupplyController;
        }());
        common.ResupplyController = ResupplyController;
        angular.module('consolo').controller('ResupplyController', ResupplyController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('splitRx', {
            bindings: {
                medicationId: '<',
                physicianId: '<',
            },
            templateUrl: '/y/templates/medications/medication/split-rx.html',
            controller: 'SplitRxController as $ctrl',
        });
        var SplitRxController = /** @class */ (function () {
            function SplitRxController(MedicationService, $mdDialog, $http, CurrentPatient) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.CurrentPatient = CurrentPatient;
                this.pharmacy = {};
                this.$http
                    .get('/api/v1/patient_record_defaults/current?patient_id=' + CurrentPatient.patient.id)
                    .then(function (response) {
                    var patientRecordDefault = response.data;
                    if (patientRecordDefault.secondary_pharmacy) {
                        _this.pharmacy['pharmacy'] = patientRecordDefault.secondary_pharmacy;
                        _this.pharmacy['pharmacy_id'] = patientRecordDefault.secondary_pharmacy_id;
                    }
                    else if (patientRecordDefault.secondary_surescripts_pharmacy) {
                        _this.pharmacy['surescripts_pharmacy'] =
                            patientRecordDefault.secondary_surescripts_pharmacy;
                        _this.pharmacy['surescripts_pharmacy_id'] =
                            patientRecordDefault.secondary_surescripts_pharmacy.id;
                    }
                });
                if (this.CurrentPatient.patient.secondary_pharmacy) {
                    this.pharmacy.pharmacy_id = this.CurrentPatient.patient.secondary_pharmacy.id;
                    this.pharmacy.pharmacy = this.CurrentPatient.patient.secondary_pharmacy;
                }
                if (this.CurrentPatient.patient.secondary_surescripts_pharmacy) {
                    this.pharmacy.surescripts_pharmacy_id = this.CurrentPatient.patient.secondary_surescripts_pharmacy.id;
                    this.pharmacy.surescripts_pharmacy = this.CurrentPatient.patient.secondary_surescripts_pharmacy;
                }
                this.MedicationService.get(this.medicationId).then(function (medication) {
                    _this.medication = medication;
                });
                this.option = '';
                if (this.physicianId) {
                    this.$http.get('/api/v1/physicians/' + this.physicianId).then(function (response) {
                        _this.physician = response.data;
                    });
                }
            }
            SplitRxController.prototype.split = function () {
                var _this = this;
                return this.MedicationService.splitRx(this.medicationId, this.quantity, this.pharmacy.pharmacy_id, this.pharmacy.surescripts_pharmacy_id, this.medication.notes_to_pharmacist, 0).then(function (splitMedication) {
                    return _this.$mdDialog.hide(splitMedication);
                }, function (errors) {
                    _this.errors = errors.data.errors;
                });
            };
            SplitRxController.$inject = ['MedicationService', '$mdDialog', '$http', 'CurrentPatient'];
            return SplitRxController;
        }());
        common.SplitRxController = SplitRxController;
        angular.module('consolo').controller('SplitRxController', SplitRxController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AdministerMedicationController = /** @class */ (function () {
            function AdministerMedicationController(MedicationService, $mdDialog, medication, dosageTime, Lookup, $http, logger, $state, $window, EmarLogicService, options) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.medication = medication;
                this.dosageTime = dosageTime;
                this.Lookup = Lookup;
                this.$http = $http;
                this.logger = logger;
                this.$state = $state;
                this.$window = $window;
                this.EmarLogicService = EmarLogicService;
                this.options = options;
                this.updatingStatus = false;
                this.exception = false;
                this.dosageIssue = false;
                this.timeIssue = false;
                this.differentDay = false;
                this.saving = false;
                this.bloodSugarOutOfRange = false;
                this.frequencyResults = "";
                if (dosageTime && dosageTime.medication_administration_record) {
                    this.medicationAdministrationRecord = (dosageTime.medication_administration_record);
                    this.medicationAdministrationRecord.administration_datetime = new Date(this.medicationAdministrationRecord.administration_datetime);
                    if (this.medicationAdministrationRecord.not_able) {
                        this.statusSelection = 'Not Able';
                    }
                    else if (this.medicationAdministrationRecord.not_needed) {
                        this.statusSelection = 'Not Needed';
                    }
                    else if (this.medicationAdministrationRecord.declined) {
                        this.statusSelection = 'Declined';
                    }
                    else {
                        this.statusSelection = 'Administer';
                    }
                }
                else {
                    this.medicationAdministrationRecord = {
                        patient_id: medication.patient_id,
                        user_id: MedicationService.currentUser.id,
                        medication_id: this.medication.id,
                        blood_sugar: 0
                    };
                    if (dosageTime) {
                        this.medicationAdministrationRecord.dosage_time_id = dosageTime.id;
                        this.medicationAdministrationRecord.administration_datetime =
                            dosageTime.effective_datetime;
                    }
                }
                this.setDisplay();
                this.validateTime();
                this.checkDosageAmount();
                if (this.medicationAdministrationRecord.id && (this.timeIssue || this.dosageIssue)) {
                    this.exception = true;
                }
                this.options.get('mar_reasons').$promise.then(function (data) {
                    _this.marReasons = data;
                });
                this.statusOptions = [
                    { description: 'Administer' },
                    { description: 'Not Able' },
                    { description: 'Not Needed' },
                    { description: 'Declined' },
                ];
                this.MedicationService.getAdverseEffects(medication.gpi).then(function (effects) {
                    _this.adverseEffects = effects;
                });
                this.Lookup['users']({}).$promise.then(function (users) {
                    _this.usersSelection = users;
                });
                this.adverseEffectsLayout = {
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                        hideHeader: true,
                    },
                    columns: [
                        { title: 'Incident Level', field: 'incident_level' },
                        { title: 'Effects', field: 'effects' },
                    ],
                };
                this.setFrequencyResults();
            }
            AdministerMedicationController.prototype.setDisplay = function () {
                if (this.dosageTime && !this.medication.prn) {
                    var effectiveDatetime = this.dosageTime.effective_datetime;
                    this.futureDateCheck = new Date(effectiveDatetime.getTime() + 30 * 60 * 1000);
                    this.pastDateCheck = new Date(effectiveDatetime.getTime() - 30 * 60 * 1000);
                    if (this.MedicationService.one_hour_medication_administration_alert) {
                        //Agency setting that will make the check 60 minutes into future
                        this.futureDateCheck = new Date(effectiveDatetime.getTime() + 60 * 60 * 1000);
                        this.pastDateCheck = new Date(effectiveDatetime.getTime() - 60 * 60 * 1000);
                    }
                    var pastTime = this.pastDateCheck.getMonth() +
                        1 +
                        '/' +
                        this.pastDateCheck.getDate() +
                        ' ' +
                        this.formatTime(this.pastDateCheck.getHours()) +
                        ':' +
                        this.formatTime(this.pastDateCheck.getMinutes());
                    var futureTime = this.formatTime(this.futureDateCheck.getHours()) +
                        ':' +
                        this.formatTime(this.futureDateCheck.getMinutes());
                    if (this.futureDateCheck.getDay() !== this.pastDateCheck.getDay()) {
                        futureTime =
                            this.futureDateCheck.getMonth() +
                                1 +
                                '/' +
                                this.futureDateCheck.getDate() +
                                ' ' +
                                futureTime;
                    }
                    this.timeDisplay = pastTime + ' - ' + futureTime;
                }
                else if (this.dosageTime) {
                    //Existing PRN Medication
                    var effectiveDatetime = new Date(this.dosageTime.effective_datetime);
                    this.futureDateCheck = effectiveDatetime;
                    this.pastDateCheck = effectiveDatetime;
                    this.timeDisplay =
                        this.formatTime(this.pastDateCheck.getHours()) +
                            ':' +
                            this.formatTime(this.pastDateCheck.getMinutes());
                }
                else if (this.medication.next_dosage) {
                    this.pastDateCheck = this.medication.next_dosage;
                    this.timeDisplay =
                        this.pastDateCheck.getMonth() +
                            1 +
                            '/' +
                            this.pastDateCheck.getDate() +
                            ' ' +
                            this.formatTime(this.pastDateCheck.getHours()) +
                            ':' +
                            this.formatTime(this.pastDateCheck.getMinutes());
                }
                else {
                    this.timeDisplay = 'Not Administered Yet';
                }
            };
            AdministerMedicationController.prototype.formatTime = function (value) {
                if (value.toString().length < 2) {
                    value = '0' + value;
                }
                return value;
            };
            AdministerMedicationController.prototype.validateTime = function () {
                this.timeDifferenceInHours = this.EmarLogicService.getTimeDifference(this.medicationAdministrationRecord, this.dosageTime);
                this.timeIssue = this.EmarLogicService.hasTimeIssue(this.pastDateCheck, this.futureDateCheck, this.medicationAdministrationRecord);
                this.differentDay = this.EmarLogicService.isDifferentDay(this.medicationAdministrationRecord, this.dosageTime);
            };
            AdministerMedicationController.prototype.createException = function () {
                this.dosageAmountMessage = null;
                this.exception = true;
            };
            AdministerMedicationController.prototype.checkDosageAmount = function () {
                var amount = this.medicationAdministrationRecord.dosage_amount;
                if (amount) {
                    var scale = this.medication.sliding_scale;
                    if (scale) {
                        var expectedDosage = this.getExpectedDosage(this.medicationAdministrationRecord.blood_sugar);
                        if (scale && scale.sliding_scale_ranges.length > 0) {
                            if (expectedDosage != null && amount != expectedDosage) {
                                this.setDosageInfo('', expectedDosage);
                            }
                            else {
                                this.dosageAmountMessage = null;
                                this.dosageIssue = false;
                            }
                        }
                    }
                    else {
                        if (amount < this.medication.dosage_amount_low) {
                            this.setDosageInfo('The dosage being given is lower than the medication instructions.', this.medication.dosage_amount_low);
                        }
                        else if (this.medication.dosage_amount_high &&
                            amount > this.medication.dosage_amount_high) {
                            this.setDosageInfo('The dosage being given is higher than the medication instructions.', this.medication.dosage_amount_high);
                        }
                        else if (!this.medication.dosage_amount_high &&
                            amount > this.medication.dosage_amount_low) {
                            this.setDosageInfo('The dosage being given is higher than the medication instructions.', this.medication.dosage_amount_low);
                        }
                        else {
                            this.dosageAmountMessage = null;
                            this.dosageIssue = false;
                        }
                    }
                }
            };
            AdministerMedicationController.prototype.setDosageInfo = function (exceptionMessage, dosageAmount) {
                if (this.exception) {
                    this.dosageAmountMessage = exceptionMessage;
                }
                else {
                    this.dosageAmountMessage = null;
                    this.dosageIssue = true;
                }
            };
            AdministerMedicationController.prototype.setFrequencyResults = function () {
                if (this.medication.sliding_scale) {
                    this.frequencyResults = this.medication.frequency + " Per Sliding Scale ";
                }
                else {
                    this.frequencyResults = this.medication.frequency;
                }
            };
            AdministerMedicationController.prototype.upsert = function () {
                var _this = this;
                if (this.bloodSugarOutOfRange) {
                    this.errors = ['Patient glucose level is outside the sliding scale parameters for this medication. Please update the medication scale and re-enter your glucose level.'];
                }
                else if (this.exception && !this.exceptionReason) {
                    this.errors = ['You must enter a reason for this exception.'];
                    this.logger.error('You must enter a reason for this exception.');
                }
                else if (!this.exception && (this.timeIssue || this.dosageIssue)) {
                    this.errors = [];
                    if (this.timeIssue) {
                        this.errors.push('The time is outside the allowed time frame.  Please correct it or click Create Exception and enter the reason.');
                    }
                    if (this.dosageIssue) {
                        this.errors.push('The dosage amount is outside the allowed amount.  Please correct it or click Create Exception and enter the reason.');
                    }
                }
                else if (!this.medicationAdministrationRecord.administration_datetime) {
                    this.errors = ['You must enter an administration time.'];
                    this.logger.error('You must enter an administration time.');
                }
                else if (this.medicationAdministrationRecord.administration_datetime > new Date()) {
                    //Not allowed legally to document administration of a medication in the future
                    this.errors = ['You may not administer a medication in the future.'];
                    this.logger.error('You may not administer a medication in the future.');
                }
                else if (!this.saving) {
                    var timeIssue = this.outsideAllowedAgencyTimeFrame();
                    this.saving = true;
                    if (this.exception) {
                        if (!this.medicationAdministrationRecord.comments) {
                            this.medicationAdministrationRecord.comments = '';
                        }
                        this.medicationAdministrationRecord.comments +=
                            ' Acknowledgement Note: ' + this.exceptionReason;
                        this.medicationAdministrationRecord.needs_signature = true;
                    }
                    var func = this.medicationAdministrationRecord.id ? this.update() : this.create();
                    return func
                        .then(function () {
                        if (_this.exception) {
                            return _this.showCreatePhysicianOrder();
                        }
                        else {
                            return _this.$mdDialog.hide();
                        }
                    }, function (errors) {
                        _this.logger.error(errors.data.errors.join(','));
                        _this.errors = errors.data.errors;
                        return false;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            AdministerMedicationController.prototype.outsideAllowedAgencyTimeFrame = function () {
                return (this.medicationAdministrationRecord.administration_datetime &&
                    ((this.futureDateCheck &&
                        this.medicationAdministrationRecord.administration_datetime > this.futureDateCheck) ||
                        (this.pastDateCheck &&
                            this.medicationAdministrationRecord.administration_datetime < this.pastDateCheck)));
            };
            // next_dosage will be set if it's scheduled for hourly or every number of minutes.
            // This checks that the time it's being administered is outside the window, so if give every 2 hours and it
            // was last administered at 11 it is not allowed to give until 1 or after
            AdministerMedicationController.prototype.prnOutsideFrequencyTime = function () {
                if (!this.medication.next_dosage) {
                    return false;
                }
                else if (this.medicationAdministrationRecord.administration_datetime >= this.medication.next_dosage) {
                    return false;
                }
                return true;
            };
            AdministerMedicationController.prototype.showCreatePhysicianOrder = function () {
                var _this = this;
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    preserveScope: true,
                    templateUrl: '/y/templates/medications/medication-administration-record/create-physician-order-modal.html',
                    controller: [
                        '$mdDialog',
                        function ($mdDialog) {
                            this.$mdDialog = $mdDialog;
                        },
                    ],
                    controllerAs: 'vm',
                    multiple: true,
                    skipHide: true,
                })
                    .then(function () {
                    return _this.$mdDialog.hide(_this.$window.open('/y/patients/' +
                        _this.medication.patient_id +
                        '/physician_orders/new?physician_id=' +
                        _this.medication.physician_id +
                        '&medication_id=' +
                        _this.medication.id));
                }, function () {
                    return _this.$mdDialog.hide();
                });
            };
            AdministerMedicationController.prototype.create = function () {
                var _this = this;
                return this.$http
                    .post('/api/v1/medication_administration_records', this.medicationAdministrationRecord)
                    .then(function (response) {
                    angular.copy(response.data, _this.medicationAdministrationRecord);
                    _this.logger.success('Created successfully.');
                    return true;
                });
            };
            AdministerMedicationController.prototype.update = function () {
                var _this = this;
                return this.$http
                    .patch('/api/v1/medication_administration_records/' + this.medicationAdministrationRecord.id, this.medicationAdministrationRecord)
                    .then(function (response) {
                    _this.dosageTime.medication_administration_record = response.data;
                    _this.logger.success('Updated successfully.');
                    return _this.dosageTime;
                });
            };
            AdministerMedicationController.prototype.setStatus = function () {
                this.medicationAdministrationRecord.not_able =
                    this.statusSelection === 'Not Able' ? true : false;
                this.medicationAdministrationRecord.not_needed =
                    this.statusSelection === 'Not Needed' ? true : false;
                this.medicationAdministrationRecord.declined =
                    this.statusSelection === 'Declined' ? true : false;
                this.updatingStatus = false;
            };
            AdministerMedicationController.prototype.openGlucoseScale = function () {
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/medications/medication/glucose-scale-modal.html',
                    controller: 'GlucoseScaleModalController as $ctrl',
                    locals: { object: this.medication, readonly: true },
                    bindToController: true,
                    multiple: true,
                    clickOutsideToClose: true
                });
            };
            AdministerMedicationController.prototype.bloodSugarChanged = function (blood_sugar) {
                this.bloodSugarOutOfRange = blood_sugar < this.medication.sliding_scale.sliding_scale_ranges[0].low_range;
                var expectedDosage = this.getExpectedDosage(blood_sugar);
                if (expectedDosage) {
                    this.medicationAdministrationRecord.dosage_amount = expectedDosage;
                    this.dosageIssue = false;
                    this.dosageAmountMessage = null;
                }
            };
            AdministerMedicationController.prototype.getExpectedDosage = function (blood_sugar) {
                // Retrieve the dosage amount that matches the blood sugar on the sliding scale
                for (var _i = 0, _a = this.medication.sliding_scale.sliding_scale_ranges; _i < _a.length; _i++) {
                    var range = _a[_i];
                    if ((range.low_range && blood_sugar >= range.low_range && blood_sugar <= range.high_range) ||
                        (!range.low_range && blood_sugar > range.high_range)) {
                        return range.units;
                    }
                }
            };
            AdministerMedicationController.$inject = [
                'MedicationService',
                '$mdDialog',
                'medication',
                'dosageTime',
                'Lookup',
                '$http',
                'logger',
                '$state',
                '$window',
                'EmarLogicService',
                'options',
            ];
            return AdministerMedicationController;
        }());
        angular
            .module('consolo')
            .controller('AdministerMedicationController', AdministerMedicationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var EmarLogicService = /** @class */ (function () {
            function EmarLogicService() {
            }
            EmarLogicService.prototype.getTimeDifference = function (medicationAdministrationRecord, dosageTime) {
                if (!medicationAdministrationRecord.administration_datetime || !dosageTime) {
                    return null;
                }
                else {
                    var adminDatetime = new Date(medicationAdministrationRecord.administration_datetime);
                    var dosageTimeEffDate = new Date(dosageTime.effective_datetime);
                    return Math.abs(Math.floor((adminDatetime.getTime() - dosageTimeEffDate.getTime()) / (1000 * 3600)));
                }
            };
            EmarLogicService.prototype.hasTimeIssue = function (pastDateCheck, futureDateCheck, medicationAdministrationRecord) {
                if (futureDateCheck &&
                    medicationAdministrationRecord.administration_datetime > futureDateCheck) {
                    return true;
                }
                else if (medicationAdministrationRecord.administration_datetime < pastDateCheck) {
                    return true;
                }
                else {
                    return false;
                }
            };
            EmarLogicService.prototype.isDifferentDay = function (medicationAdministrationRecord, dosageTime) {
                if (!dosageTime || !medicationAdministrationRecord.administration_datetime) {
                    return false;
                }
                if (new Date(dosageTime.effective_datetime).getDay() !==
                    medicationAdministrationRecord.administration_datetime.getDay()) {
                    return true;
                }
                else {
                    return false;
                }
            };
            EmarLogicService.$inject = [];
            return EmarLogicService;
        }());
        services.EmarLogicService = EmarLogicService;
        angular.module('consolo').service('EmarLogicService', EmarLogicService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var EMARController = /** @class */ (function () {
            function EMARController(MedicationService, patientService, $mdDialog, SidebarService, $mdSidenav, $state, $window, EmarService, $scope, $timeout, logger) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.patientService = patientService;
                this.$mdDialog = $mdDialog;
                this.SidebarService = SidebarService;
                this.$mdSidenav = $mdSidenav;
                this.$state = $state;
                this.$window = $window;
                this.EmarService = EmarService;
                this.$scope = $scope;
                this.$timeout = $timeout;
                this.logger = logger;
                this.patientId = null;
                this.patientId = this.$state.params.patientId;
                this.MedicationService.getMedicationNotes(this.patientId).then(function (medicationNotes) {
                    _this.medicationNotes = medicationNotes;
                });
                this.getCurrentPatient();
                this.EmarService.initialize();
                this.$window.addEventListener('resize', this.setHourWidthMultiplier);
                this.reload();
            }
            EMARController.prototype.getScheduledMedications = function () {
                var _this = this;
                this.EmarService.getScheduledMedicationsForPatient(this.patientId).then(function () {
                    _this.hackSetOpacity();
                }, function (errors) {
                    _this.logger.error(errors.data.errors.join(', '));
                });
            };
            EMARController.prototype.getCurrentPatient = function () {
                var _this = this;
                this.patientService
                    .getExtra(this.patientId)
                    .then(function (response) {
                    _this.currentPatient = response.data;
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            EMARController.prototype.nextDay = function () {
                this.EmarService.dateFilter = moment(this.EmarService.dateFilter).add(1, 'day').toDate();
                this.getScheduledMedications();
            };
            EMARController.prototype.previousDay = function () {
                this.EmarService.dateFilter = moment(this.EmarService.dateFilter).subtract(1, 'day').toDate();
                this.getScheduledMedications();
            };
            EMARController.prototype.screenWidth = function () {
                if (typeof window.innerWidth !== 'undefined') {
                    this.viewportwidth = window.innerWidth;
                    this.viewportheight = window.innerHeight;
                }
                else if (typeof document.documentElement !== 'undefined' &&
                    typeof document.documentElement.clientWidth !== 'undefined' &&
                    document.documentElement.clientWidth !== 0) {
                    this.viewportwidth = document.documentElement.clientWidth;
                    this.viewportheight = document.documentElement.clientHeight;
                }
                else {
                    this.viewportwidth = document.getElementsByTagName('body')[0].clientWidth;
                    this.viewportheight = document.getElementsByTagName('body')[0].clientHeight;
                }
                this.setSidenav();
            };
            EMARController.prototype.setSidenav = function () {
                if (this.viewportwidth > 600) {
                    this.$state.current.data.hideSidebar = true;
                }
                else {
                    this.$state.current.data.hideSidebar = false;
                }
            };
            EMARController.prototype.setHourWidthMultiplier = function () {
                var viewportwidth = window.innerWidth;
                if (viewportwidth <= 600) {
                    return 12;
                }
                else if (viewportwidth > 600 && viewportwidth <= 700) {
                    return 6;
                }
                else if (viewportwidth > 700 && viewportwidth <= 900) {
                    return 4;
                }
                else if (viewportwidth > 900 && viewportwidth <= 1024) {
                    return 2;
                }
                else {
                    return 1;
                }
            };
            EMARController.prototype.getSchedulingMedications = function () {
                var _this = this;
                this.EmarService.getMedicationsSchedulingOnEmar(this.patientId).then(function (medications) {
                    _this.medicationsUpdating = medications;
                    _this.hackSetOpacity();
                });
            };
            EMARController.prototype.hackSetOpacity = function () {
                this.$timeout(function () {
                    // Once this is fixed, we can remove this hack
                    // https://bugs.chromium.org/p/chromium/issues/detail?id=1191192
                    document.getElementById('emar-data-table').style.opacity = "0.99";
                }, 1000);
            };
            EMARController.prototype.reload = function () {
                this.getScheduledMedications();
                this.screenWidth();
                this.getSchedulingMedications();
            };
            EMARController.$inject = [
                'MedicationService',
                'patientService',
                '$mdDialog',
                'SidebarService',
                '$mdSidenav',
                '$state',
                '$window',
                'EmarService',
                '$scope',
                '$timeout',
                'logger',
            ];
            return EMARController;
        }());
        angular.module('consolo').controller('EMARController', EMARController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var EmarService = /** @class */ (function () {
            function EmarService($state, $http, $mdDialog, MedicationService) {
                this.$state = $state;
                this.$http = $http;
                this.$mdDialog = $mdDialog;
                this.MedicationService = MedicationService;
                this.currentDay = new Date();
                this.currentHour = this.currentDay.getHours();
                this.teamSelection = null;
                this.$state.current.data.hideSidebar = true;
                this.resetAllMedications();
            }
            EmarService.prototype.initialize = function () {
                this.dateFilter = new Date();
                this.setDayDisplay();
            };
            EmarService.prototype.setDayDisplay = function () {
                if (this.dateFilter) {
                    var today = new Date();
                    var utc1 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
                    var utc2 = Date.UTC(this.dateFilter.getFullYear(), this.dateFilter.getMonth(), this.dateFilter.getDate());
                    var diffDays = Math.floor((utc1 - utc2) / (1000 * 3600 * 24));
                    if (diffDays === 0) {
                        this.dayDisplay = 'Today';
                    }
                    else if (diffDays === 1) {
                        this.dayDisplay = 'Yesterday';
                    }
                    else if (diffDays === -1) {
                        this.dayDisplay = 'Tomorrow';
                    }
                    else if (diffDays > 0) {
                        this.dayDisplay = diffDays + ' Days Ago';
                    }
                    else {
                        this.dayDisplay = Math.abs(diffDays) + ' Days in Future';
                    }
                }
            };
            EmarService.prototype.getAssignedPatientsOnEmar = function () {
                var _this = this;
                this.emarLoader = true;
                this.setDayDisplay();
                this.$http
                    .get('/api/v1/dashboard/emar', {
                    params: { team_id: this.teamSelection, date: this.dateFilter },
                })
                    .then(function (response) {
                    _this.patients = response.data.map(function (patient) {
                        patient.meds_by_hour = [];
                        patient.meds_by_hour.length = 24;
                        patient.medications = patient.medications.map(function (medication) {
                            if (patient.time_zone) {
                                medication.timeZone = patient.time_zone;
                            }
                            medication = new consolo.domain.Medication(medication);
                            var dosageTimesByHour = _this.getDosageTimesByHour(medication.dosage_date);
                            _this.insertDosageTimesInArray(dosageTimesByHour, patient.meds_by_hour);
                            return medication;
                        });
                        return patient;
                    });
                    _this.emarLoader = false;
                });
            };
            EmarService.prototype.getDosageTimesByHour = function (dosageDate) {
                var hours = {};
                dosageDate.dosage_times.forEach(function (dosageTime) {
                    var doseHour = dosageTime.getHours();
                    var dosePosition = doseHour.toString();
                    if (!hours[parseInt(dosePosition, 10)]) {
                        hours[parseInt(dosePosition, 10)] = [];
                    }
                    hours[parseInt(dosePosition, 10)].push(dosageTime);
                });
                return hours;
            };
            EmarService.prototype.insertDosageTimesInArray = function (hoursToBeInserted, hours) {
                var _loop_1 = function (hour) {
                    //if array is empty, set it
                    if (hours[parseInt(hour, 10)] === undefined) {
                        hours[parseInt(hour, 10)] = [];
                    }
                    //Loop over each hour that's present and insert all dosageTimes into the appropriate hour
                    hoursToBeInserted[hour].forEach(function (dosageTime) {
                        hours[parseInt(hour, 10)].push(dosageTime);
                    });
                };
                for (var _i = 0, _a = Object.keys(hoursToBeInserted); _i < _a.length; _i++) {
                    var hour = _a[_i];
                    _loop_1(hour);
                }
            };
            EmarService.prototype.administerMedication = function (medication, dosageTime) {
                var _this = this;
                var url = '/y/templates/medications/medication-administration-record/administer-medication.html';
                var controller = 'AdministerMedicationController as vm';
                if (dosageTime && dosageTime.is_prn_followup) {
                    url = '/y/templates/medications/medication-administration-record/prn-followup-modal.html';
                    controller = 'PrnFollowupController as vm';
                }
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    bindToController: true,
                    templateUrl: url,
                    controller: controller,
                    locals: { medication: medication, dosageTime: dosageTime },
                    skipHide: true,
                    preserveScope: true,
                    multiple: true,
                })
                    .then(function () {
                    _this.MedicationService.getScheduledMedications(medication.patient_id, _this.dateFilter, medication.id).then(function (patient) {
                        var updatedMedication = patient.medications.filter(function (med) {
                            return med.id === medication.id;
                        })[0];
                        if (patient.time_zone) {
                            updatedMedication.timeZone = patient.time_zone;
                        }
                        updatedMedication = new consolo.domain.Medication(updatedMedication);
                        angular.copy(updatedMedication, medication);
                        _this.setMedAdminTimes(medication);
                    });
                });
            };
            EmarService.prototype.getScheduledMedicationsForPatient = function (patient_id) {
                var _this = this;
                this.dateFilter.setHours(0, 0, 0, 0);
                this.emarLoader = true;
                this.setDayDisplay();
                this.resetAllMedications();
                return this.MedicationService.getScheduledMedications(patient_id, this.dateFilter).then(function (patient) {
                    _this.setMedications(patient.medications);
                    _this.emarLoader = false;
                });
            };
            EmarService.prototype.setMedications = function (medications) {
                var _this = this;
                this.resetAllMedications();
                this.totalMedCount = medications.length;
                medications.forEach(function (medication) {
                    _this.populateAcknowledgements(medication);
                    if (medication.dc &&
                        csg.util.dateOnly(medication.dc).toString() === csg.util.dateOnly(new Date()).toString()) {
                        _this.allMedications.discontinued.medications.push(medication);
                    }
                    else if (medication.deleted &&
                        csg.util.dateOnly(medication.deleted).toString() ===
                            csg.util.dateOnly(new Date()).toString()) {
                        _this.allMedications.deleted.medications.push(medication);
                    }
                    else if (medication.prn) {
                        _this.allMedications.prn.medications.push(medication);
                    }
                    else if (medication.isContinuous()) {
                        _this.allMedications.continuous.medications.push(medication);
                    }
                    else {
                        _this.allMedications.routine.medications.push(medication);
                    }
                });
                this.totalMedCount =
                    this.allMedications.routine.medications.length +
                        this.allMedications.prn.medications.length +
                        this.allMedications.continuous.medications.length +
                        this.allMedications.discontinued.medications.length;
            };
            EmarService.prototype.populateAcknowledgements = function (medication) {
                var _this = this;
                if (medication.dosage_date.dosage_times.length) {
                    medication.dosage_date.dosage_times.forEach(function (dosageTime) {
                        if (dosageTime.medication_administration_record &&
                            dosageTime.medication_administration_record.signatures.length) {
                            dosageTime.medication_administration_record.signatures.forEach(function (signature) {
                                _this.futureAcknowledgements.push('Medication ' +
                                    medication.name +
                                    ': Administered Outside of the allowable parameters and acknowledged by ' +
                                    signature.static_user_name +
                                    ' at ' +
                                    signature.signed_date);
                            });
                        }
                    });
                }
            };
            EmarService.prototype.resetAllMedications = function () {
                this.allMedications = {
                    routine: { name: 'Routine Medications', medications: [] },
                    continuous: { name: 'Continuous Medications', medications: [] },
                    prn: { name: 'PRN Medications', medications: [] },
                    discontinued: { name: 'Medications Discontinued Today', medications: [] },
                    deleted: { name: 'Medications Deleted Today', medications: [] },
                };
                this.futureAcknowledgements = [];
                this.totalMedCount = 0;
            };
            EmarService.prototype.setMedAdminTimes = function (medication) {
                var adminTimes = [];
                adminTimes.length = 24;
                medication.dosage_date.dosage_times.forEach(function (dosageTime) {
                    var doseHour = dosageTime.getHours();
                    var dosePosition = doseHour.toString();
                    if (adminTimes[parseInt(dosePosition, 10)] === undefined) {
                        adminTimes[parseInt(dosePosition, 10)] = [];
                    }
                    adminTimes[parseInt(dosePosition, 10)].push(dosageTime);
                });
                medication.full_dosage_list = adminTimes;
            };
            Object.defineProperty(EmarService.prototype, "hourWidthMultiplier", {
                // Hour width multiplier helps to determine the full width of medication time blocks
                // based on time display and the width of the hour container
                // SEARCH hourwidth() function to see it in use
                // Example. for 24 Hour View (Multiplier = 1) because all hours should be shown
                // Example. for 12 Hour View (Multiplier = 2) because only 12 hours are seen at a time
                get: function () {
                    return this._hourWidthMultiplier;
                },
                set: function (hour) {
                    this._hourWidthMultiplier = hour;
                    if (typeof hour === 'string') {
                        this._hourWidthMultiplier = parseInt(hour, 10);
                    }
                },
                enumerable: true,
                configurable: true
            });
            EmarService.prototype.showEmarLegendModal = function () {
                this.$mdDialog.show({
                    contentElement: '#legend-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    openFrom: '#legend-action-button',
                    closeTo: '#legend-action-button',
                });
            };
            EmarService.prototype.showMedNotesModal = function () {
                this.$mdDialog.show({
                    contentElement: '#med-notes-modal',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    openFrom: '#med-notes-action-button',
                    closeTo: '#med-notes-action-button',
                });
            };
            EmarService.prototype.getMedicationsSchedulingOnEmar = function (patientId) {
                return this.$http
                    .get('/api/v1/sidekiq/errand_schedule_medication/all_jobs_by_patient?patient_id=' + patientId)
                    .then(function (response) {
                    return response.data;
                });
            };
            EmarService.prototype.cssDosageTimeStatus = function (dosageTime) {
                if (!dosageTime) {
                    return null;
                }
                else if (!dosageTime.medication_administration_record) {
                    if (dosageTime.is_prn_followup) {
                        if (dosageTime.prn_followup) {
                            return 'prnFollowupAdministered';
                        }
                        else if (new Date(dosageTime.effective_datetime) >= new Date()) {
                            return 'prnFollowupScheduled';
                        }
                        else if (new Date(dosageTime.effective_datetime) < new Date()) {
                            return 'prnFollowupMissed';
                        }
                    }
                    else if (new Date(dosageTime.effective_datetime) >= new Date()) {
                        return 'scheduledMed';
                    }
                    else {
                        return 'missedMed';
                    }
                }
                else if (dosageTime.medication_administration_record.declined) {
                    return 'declinedMed';
                }
                else if (dosageTime.medication_administration_record.not_able) {
                    return 'notAbleMed';
                }
                else if (dosageTime.medication_administration_record.not_needed) {
                    return 'notNeededMed';
                }
                else {
                    return 'administeredMed';
                }
            };
            EmarService.prototype.dosageIcon = function (dosageTime) {
                var timeStatus = this.cssDosageTimeStatus(dosageTime);
                if (timeStatus === 'scheduledMed') {
                    return 'action:alarm';
                }
                else if (timeStatus === 'missedMed') {
                    return 'alert:error';
                }
                else if (timeStatus === 'declinedMed') {
                    return 'action:thumb_down';
                }
                else if (timeStatus === 'notAbleMed') {
                    return 'action:help';
                }
                else if (timeStatus === 'notNeededMed') {
                    return 'content:remove_circle';
                }
                else if (timeStatus === 'administeredMed') {
                    return 'action:check_circle';
                }
                else if (['prnFollowupScheduled', 'prnFollowupMissed', 'prnFollowupAdministered'].indexOf(timeStatus) > -1) {
                    return 'action:assignment_ind';
                }
                else {
                    return null;
                }
            };
            EmarService.prototype.statusMessage = function (dosageTime) {
                var timeStatus = this.cssDosageTimeStatus(dosageTime);
                if (timeStatus === 'scheduledMed') {
                    return 'Scheduled';
                }
                else if (timeStatus === 'missedMed') {
                    return 'Missed';
                }
                else if (timeStatus === 'declinedMed') {
                    return 'Declined';
                }
                else if (timeStatus === 'notAbleMed') {
                    return 'Not Able';
                }
                else if (timeStatus === 'notNeededMed') {
                    return 'Not Needed';
                }
                else if (timeStatus === 'administeredMed') {
                    return 'Administered';
                }
                else if (['prnFollowupScheduled', 'prnFollowupMissed', 'prnFollowupAdministered'].indexOf(timeStatus) > -1) {
                    return 'PRN Followup';
                }
                else {
                    return null;
                }
            };
            EmarService.prototype.tooltipTimeStatus = function (dosageTime) {
                var timeStatus = this.cssDosageTimeStatus(dosageTime);
                if (dosageTime.medication_administration_record &&
                    dosageTime.medication_administration_record.comments) {
                    return dosageTime.medication_administration_record.comments;
                }
                else if (timeStatus === 'scheduledMed') {
                    return 'Scheduled';
                }
                else if (timeStatus === 'missedMed') {
                    return 'Missed';
                }
                else if (timeStatus === 'declinedMed') {
                    return 'Declined';
                }
                else if (timeStatus === 'notAbleMed') {
                    return 'Not Able';
                }
                else if (timeStatus === 'notNeededMed') {
                    return 'Not Needed';
                }
                else if (timeStatus === 'administeredMed') {
                    return 'Administered';
                }
                else if (['prnFollowupScheduled', 'prnFollowupMissed', 'prnFollowupAdministered'].indexOf(timeStatus) > -1) {
                    return 'PRN Followup';
                }
                else {
                    return null;
                }
            };
            EmarService.prototype.scrollToCurrentHour = function () {
                var hourContainerWidth = this.hourContainer.offsetWidth;
                var currentHourPosition = this.currentHour * ((hourContainerWidth * this._hourWidthMultiplier) / 24);
                var lastFullScrollPosition = hourContainerWidth * this._hourWidthMultiplier - hourContainerWidth;
                if (currentHourPosition > lastFullScrollPosition) {
                    // Current hour can't be set to first position, due to
                    // not having enough scrolling room left at end of table
                    // Scroll to end of table instead
                    this.currentScrollPosition = lastFullScrollPosition;
                }
                else {
                    // Set current hour to first position in table
                    this.currentScrollPosition = currentHourPosition;
                }
            };
            EmarService.prototype.scrollMedications = function (direction) {
                var hourContainerWidth = this.hourContainer.offsetWidth;
                var lastFullScrollPosition = hourContainerWidth * this._hourWidthMultiplier - hourContainerWidth;
                if (direction === 'left' && this.currentScrollPosition >= hourContainerWidth) {
                    // Scrolls left until a full scroll is no longer possible
                    this.currentScrollPosition -= hourContainerWidth;
                }
                else if (direction === 'left' && this.currentScrollPosition <= hourContainerWidth) {
                    // Scrolls left to the 0 starting point after full left scroll is no longer possible
                    this.currentScrollPosition = 0;
                }
                else if (direction === 'right' &&
                    this.currentScrollPosition <= lastFullScrollPosition &&
                    hourContainerWidth + this.currentScrollPosition <= lastFullScrollPosition) {
                    // Scrolls right until a full right scroll is no longer possible
                    this.currentScrollPosition += hourContainerWidth;
                }
                else if (direction === 'right' &&
                    hourContainerWidth + this.currentScrollPosition >= lastFullScrollPosition) {
                    // Scrolls right to the 23 ending point after full scroll is no longer possible
                    this.currentScrollPosition += lastFullScrollPosition - this.currentScrollPosition;
                }
            };
            EmarService.$inject = ['$state', '$http', '$mdDialog', 'MedicationService'];
            return EmarService;
        }());
        services.EmarService = EmarService;
        angular.module('consolo').service('EmarService', EmarService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MARHistoryDataTableService = /** @class */ (function (_super) {
            __extends(MARHistoryDataTableService, _super);
            function MARHistoryDataTableService($http, $interval, CurrentPatient) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.CurrentPatient = CurrentPatient;
                _this.loadingData = false;
                _this.activate();
                return _this;
            }
            MARHistoryDataTableService.prototype.activate = function () {
                this.apiSelectUrl = '/api/v1/medication_administration_records';
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: true,
                        hideTextFilter: true,
                    },
                    headerMenu: {
                        items: [],
                    },
                    columns: [
                        {
                            title: 'Medication',
                            field: 'medication.name',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (medicationAdministrationRecord) {
                                var medication = medicationAdministrationRecord.medication;
                                return medication.name + ' ' + medication.frequency;
                            },
                        },
                        {
                            title: 'Order Date',
                            field: 'medication.orderdate',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        {
                            title: 'Scheduled Time',
                            field: 'dosage_time.effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Administered Time',
                            field: 'administration_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Amount',
                            field: 'dosage_amount',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (mar) {
                                return mar.dosage_amount + ' ' + mar.medication.dosage_unit_display;
                            },
                        },
                        {
                            title: 'Reason',
                            field: 'mar_reasons_string',
                            type: csg.datatable.ColumnTypes.short_text,
                            formatValueFn: function (mar) {
                                return mar.mar_reasons
                                    .map(function (reason) {
                                    return reason.description;
                                })
                                    .join(', ');
                            },
                        },
                        {
                            title: 'Site',
                            field: 'topical_site.description',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Comments',
                            field: 'comments',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Administered By',
                            field: 'user.name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                    ],
                };
            };
            MARHistoryDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = {
                    start_date: this.startDate,
                    end_date: this.endDate,
                    medication_id: this.medicationId,
                    patient_id: this.CurrentPatient.patient_id,
                };
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            MARHistoryDataTableService.$inject = ['$http', '$interval', 'CurrentPatient'];
            return MARHistoryDataTableService;
        }(csg.datatable.ApiService));
        services.MARHistoryDataTableService = MARHistoryDataTableService;
        angular
            .module('consolo')
            .service('MARHistoryDataTableService', MARHistoryDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationAdministrationRecordsHistoryController = /** @class */ (function () {
            function MedicationAdministrationRecordsHistoryController(MedicationService, MARHistoryDataTableService, CurrentPatient, logger) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.MARHistoryDataTableService = MARHistoryDataTableService;
                this.CurrentPatient = CurrentPatient;
                this.logger = logger;
                var today = new Date();
                //Set the start date to 7 days previous
                MARHistoryDataTableService.startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                MARHistoryDataTableService.endDate = today;
                MARHistoryDataTableService.search();
                this.MedicationService.getScheduledMedications(this.CurrentPatient.patient_id, null, null, true).then(function (emarPatient) {
                    _this.emarPatient = emarPatient;
                });
            }
            MedicationAdministrationRecordsHistoryController.prototype.search = function () {
                if (this.MARHistoryDataTableService.startDate && this.MARHistoryDataTableService.endDate) {
                    this.MARHistoryDataTableService.search();
                }
                else {
                    this.logger.error('The start and end date are both required.');
                    this.errors = ['The start and end date are both required.'];
                }
            };
            MedicationAdministrationRecordsHistoryController.$inject = [
                'MedicationService',
                'MARHistoryDataTableService',
                'CurrentPatient',
                'logger',
            ];
            return MedicationAdministrationRecordsHistoryController;
        }());
        angular
            .module('consolo')
            .controller('MedicationAdministrationRecordsHistoryController', MedicationAdministrationRecordsHistoryController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PrnFollowupController = /** @class */ (function () {
            function PrnFollowupController(MedicationService, $mdDialog, medication, dosageTime, logger, $http, $state) {
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.medication = medication;
                this.dosageTime = dosageTime;
                this.logger = logger;
                this.$http = $http;
                this.$state = $state;
                this.scaleTypes = [{ id: 'Verbal' }, { id: 'PAINAD' }, { id: 'FLACC' }];
                this.yesNo = [{ id: true, description: 'Yes' }, { id: false, description: 'No' }];
                if (dosageTime && dosageTime.prn_followup) {
                    this.prnFollowup = dosageTime.prn_followup;
                    this.prnFollowup.effective_datetime = new Date(this.prnFollowup.effective_datetime);
                }
                else {
                    this.prnFollowup = {
                        effective_datetime: new Date(),
                        patient_id: medication.patient_id,
                        medication_id: medication.id,
                        dosage_time_id: dosageTime.id,
                    };
                }
            }
            PrnFollowupController.prototype.upsert = function (createPhysicianOrder) {
                var _this = this;
                var upsert = this.prnFollowup.id ? this.update() : this.create();
                return upsert.then(function () {
                    _this.$mdDialog.hide();
                    if (createPhysicianOrder) {
                        return _this.$state.go('patients.physician-orders.new', {
                            patientId: _this.medication.patient_id,
                        });
                    }
                }, function (errors) {
                    _this.logger.error(errors.data.errors.join(', '));
                    _this.errors = errors.data.errors;
                    return false;
                });
            };
            PrnFollowupController.prototype.create = function () {
                var _this = this;
                return this.$http.post('/api/v1/prn_followups', this.prnFollowup).then(function (response) {
                    angular.copy(response.data, _this.prnFollowup);
                    _this.logger.success('Created successfully.');
                    return true;
                });
            };
            PrnFollowupController.prototype.update = function () {
                var _this = this;
                return this.$http
                    .patch('/api/v1/prn_followups/' + this.prnFollowup.id, this.prnFollowup)
                    .then(function (response) {
                    _this.dosageTime.prn_followup = response.data;
                    _this.logger.success('Updated successfully.');
                    return _this.dosageTime;
                });
            };
            PrnFollowupController.$inject = [
                'MedicationService',
                '$mdDialog',
                'medication',
                'dosageTime',
                'logger',
                '$http',
                '$state',
            ];
            return PrnFollowupController;
        }());
        angular.module('consolo').controller('PrnFollowupController', PrnFollowupController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('addMedFavoritesToPatient', {
            bindings: { onPhysicianOrder: '<', physicianId: '<' },
            templateUrl: '/y/templates/medications/medication-favorites/add-med-favorites-to-patient.html',
            controller: 'AddMedFavoritesToPatientController as $ctrl',
        });
        var AddMedFavoritesToPatientController = /** @class */ (function () {
            function AddMedFavoritesToPatientController(logger, Session, $http, MedicationFavoritesService, MedicationService, $timeout, $state, CurrentPatient, $mdDialog, $q) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.MedicationService = MedicationService;
                this.$timeout = $timeout;
                this.$state = $state;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.$q = $q;
                this.medicationFavorites = [];
                this.filteredMedicationFavorites = [];
                this.selectedMedicationFavorites = [];
                this.saving = false;
                this.loading = false;
                this.pharmacy = {};
                this.filterFormularyOnly = false;
                this.formularyLocked = false;
                this.interactionsRecord = {};
                this.interactionsAcknowledged = false;
                this.cancelButtonClicked = true;
                this.loadingInteractions = false;
                this.oldMedication = [];
                var user = this.Session.getUser();
                this.currentUser = user;
                this.user_dashboard_url = user.dashboard_url;
                if (!this.physicianId && user.physician) {
                    this.physicianId = user.physician.id;
                }
                var session = Session.get();
                if (session.agency.formulary_enabled && session.agency.formulary_enforced) {
                    this.filterFormularyOnly = true;
                    this.formularyLocked = true;
                }
                this.MedicationFavoritesService.getPhysiciansWithFavorites().then(function (physicians) {
                    _this.physicians = physicians;
                });
                if (this.physicianId) {
                    this.$http.get('/api/v1/physicians/' + this.physicianId).then(function (response) {
                        _this.orderingPhysician = response.data;
                    });
                    this.getMedicationFavorites();
                }
                this.MedicationService.setPharmacy(this.CurrentPatient.patient.id, this.pharmacy);
                this.orderDate = new Date();
                this.medicationFavoriteTableLayout = {
                    id_field: 'id',
                    options: {
                        allowRowSelect: true,
                        defaultPageSize: 10,
                    },
                    columns: [
                        {
                            title: 'Medication',
                            field: 'name',
                        },
                        {
                            title: 'Frequency',
                            field: 'frequency',
                            formatValueFn: function (medicationFavorite) {
                                if (medicationFavorite.sliding_scale) {
                                    var dose_level = ((medicationFavorite.sliding_scale.dose_level || medicationFavorite.sliding_scale.dose_level != null) ? medicationFavorite.sliding_scale.dose_level : "");
                                    return medicationFavorite.frequency + "Per Sliding Scale" + " " + dose_level;
                                }
                                else {
                                    return medicationFavorite.frequency;
                                }
                            }
                        },
                        {
                            title: 'Instructions',
                            field: 'notes',
                        },
                        { title: 'Notes to Pharmacist', field: 'notes_to_pharmacist' },
                    ],
                };
            }
            AddMedFavoritesToPatientController.prototype.filter = function () {
                if (this.filterQuery) {
                    var _filterQuery_1 = new RegExp(this.filterQuery.toLowerCase());
                    this.filteredMedicationFavorites = this.medicationFavorites.filter(function (medFavorite) {
                        return medFavorite.fullDescription.toLowerCase().search(_filterQuery_1) !== -1;
                    });
                }
                else {
                    this.filteredMedicationFavorites = this.medicationFavorites;
                }
            };
            AddMedFavoritesToPatientController.prototype.getMedicationFavorites = function () {
                var _this = this;
                if (this.physicianId) {
                    this.loading = true;
                    this.MedicationFavoritesService.getMedicationFavorites(this.physicianId, null, this.filterFormularyOnly)
                        .then(function (medicationFavorites) {
                        _this.medicationFavorites = medicationFavorites;
                        _this.filter();
                    })
                        .finally(function () {
                        _this.loading = false;
                    });
                }
            };
            AddMedFavoritesToPatientController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.selectedMedicationFavorites.length) {
                    this.errors.push('You must select at least one medication.');
                }
                if (!this.orderingPhysician) {
                    this.errors.push('You must select an Ordering Prescriber.');
                }
                if (!this.orderDate) {
                    this.errors.push('You must select an Order Date.');
                }
                var missing = false;
                if (this.oldMedication.length != 0) {
                    missing = this.checkforChanges();
                }
                if (missing) {
                    this.cancelButtonClicked = true;
                    this.interactionsAcknowledged = false;
                    this.interactionsRecord = {};
                }
                if (this.cancelButtonClicked) {
                    this.getAndSetInteraction();
                }
                if (!this.errors.length && (this.interactionsAcknowledged && !this.cancelButtonClicked)) {
                    this.saving = true;
                    return this.MedicationFavoritesService.addToPatient(this.selectedMedicationFavorites.map(function (favorite) {
                        return favorite.id;
                    }), this.CurrentPatient.patient_id, this.orderingPhysician.id, this.orderDate, this.status, this.pharmacy, this.interactionsRecord)
                        .then(function (medications) {
                        _this.errors = null;
                        _this.logger.success('The medications have been added.');
                        _this.MedicationService.replaceMedications(medications);
                        return _this.$mdDialog.hide(medications);
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else if (!!this.errors.length) {
                    this.logger.error(this.errors.join(', '));
                }
            };
            AddMedFavoritesToPatientController.prototype.getAndSetInteraction = function () {
                var _this = this;
                var defer = this.$q.defer();
                this.cancelButtonClicked = false;
                if (this.selectedMedicationFavorites) {
                    angular.copy(this.selectedMedicationFavorites, this.oldMedication);
                    this.selectedMedicationFavorites.forEach(function (item) {
                        if (item.ndc) {
                            _this.loadingInteractions = true;
                            _this.$http
                                .get('/api/v1/medications/new_interactions', {
                                params: {
                                    new_medication_name: item.name,
                                    new_medication_ndc: item.ndc,
                                    patient_id: _this.CurrentPatient.patient_id,
                                },
                            })
                                .then(function (response) {
                                var data = response.data;
                                if (data.drug_interactions && data.drug_interactions.length != 0) {
                                    _this.interactionsAcknowledged = false;
                                    data.drug_interactions.forEach(function (drugInteraction) {
                                        drugInteraction.user = _this.currentUser;
                                    });
                                }
                                if (data.allergy_interactions && data.allergy_interactions.length != 0) {
                                    _this.interactionsAcknowledged = false;
                                    data.allergy_interactions.forEach(function (allergyInteraction) {
                                        allergyInteraction.user = _this.currentUser;
                                    });
                                }
                                item.drug_interactions = data.drug_interactions;
                                item.allergy_interactions = data.allergy_interactions;
                                var ndc = item.ndc;
                                _this.interactionsRecord[ndc] = {
                                    drug_interactions: data.drug_interactions,
                                    allergy_interactions: data.allergy_interactions
                                };
                            })
                                .catch(function (errors) {
                                _this.logger.error(errors.join(', '));
                            })
                                .finally(function () {
                                if (!item.drug_interactions) {
                                    item.drug_interactions = [];
                                }
                                if (!item.allergy_interactions) {
                                    item.allergy_interactions = [];
                                }
                                if (item.drug_interactions.length !== 0 ||
                                    item.allergy_interactions.length !== 0) {
                                    _this.$mdDialog
                                        .show({
                                        preserveScope: true,
                                        templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                        controller: 'AcknowledgeInteractionsController as vm',
                                        multiple: true,
                                        skipHide: true,
                                        locals: { medication: item },
                                    })
                                        .then(function (response) {
                                        if (response) {
                                            _this.interactionsAcknowledged = true;
                                        }
                                        else {
                                            _this.cancelButtonClicked = true;
                                        }
                                        defer.resolve();
                                    });
                                }
                                else {
                                    _this.interactionsAcknowledged = true;
                                    defer.resolve();
                                }
                                _this.loadingInteractions = false;
                            });
                        }
                        else if (item.compound_id) {
                            _this.loadingInteractions = true;
                            var allergyInteractions_1 = [];
                            var drugInteractions_1 = [];
                            var interactionPromises_1 = [];
                            item.ingredients.forEach(function (ingredient) {
                                if (ingredient.name && ingredient.ndc) {
                                    interactionPromises_1.push(_this.$http
                                        .get('/api/v1/medications/new_interactions', {
                                        params: {
                                            new_medication_name: ingredient.name,
                                            new_medication_ndc: ingredient.ndc,
                                            patient_id: _this.CurrentPatient.patient_id,
                                        },
                                    })
                                        .then(function (response) {
                                        var data = response.data;
                                        if (data.drug_interactions && data.drug_interactions.length != 0) {
                                            _this.interactionsAcknowledged = false;
                                            data.drug_interactions.forEach(function (drugInteraction) {
                                                drugInteraction.user = _this.currentUser;
                                            });
                                            drugInteractions_1 = drugInteractions_1.concat(data.drug_interactions);
                                        }
                                        if (data.allergy_interactions && data.allergy_interactions.length != 0) {
                                            _this.interactionsAcknowledged = false;
                                            data.allergy_interactions.forEach(function (allergyInteraction) {
                                                allergyInteraction.user = _this.currentUser;
                                            });
                                            allergyInteractions_1 = allergyInteractions_1.concat(data.allergy_interactions);
                                        }
                                        var ndc = item.ndc;
                                        _this.interactionsRecord[ndc] = {
                                            drug_interactions: data.drug_interactions,
                                            allergy_interactions: data.allergy_interactions
                                        };
                                    }));
                                }
                            });
                            _this.$q.all(interactionPromises_1).then(function () {
                                item.drug_interactions = drugInteractions_1;
                                item.allergy_interactions = allergyInteractions_1;
                                if (item.drug_interactions.length !== 0 ||
                                    item.allergy_interactions.length !== 0) {
                                    _this.$mdDialog
                                        .show({
                                        preserveScope: true,
                                        templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                        controller: 'AcknowledgeInteractionsController as vm',
                                        multiple: true,
                                        skipHide: true,
                                        locals: { medication: item },
                                    })
                                        .then(function (response) {
                                        if (response) {
                                            _this.interactionsAcknowledged = true;
                                        }
                                        else {
                                            _this.cancelButtonClicked = true;
                                        }
                                        defer.resolve();
                                    });
                                }
                                else {
                                    _this.interactionsAcknowledged = true;
                                    defer.resolve();
                                }
                                _this.loadingInteractions = false;
                            });
                        }
                        else {
                            defer.resolve();
                        }
                    });
                }
            };
            AddMedFavoritesToPatientController.prototype.checkforChanges = function () {
                var _this = this;
                var med;
                var foundSomething = false;
                if (this.oldMedication.length != this.selectedMedicationFavorites.length) {
                    return true;
                }
                this.selectedMedicationFavorites.forEach(function (item) {
                    med = _this.oldMedication.find(function (om) { return om.id == item.id; });
                    if (med == null) {
                        foundSomething = true;
                    }
                });
                return foundSomething;
            };
            AddMedFavoritesToPatientController.$inject = [
                'logger',
                'SessionService',
                '$http',
                'MedicationFavoritesService',
                'MedicationService',
                '$timeout',
                '$state',
                'CurrentPatient',
                '$mdDialog',
                '$q'
            ];
            return AddMedFavoritesToPatientController;
        }());
        common.AddMedFavoritesToPatientController = AddMedFavoritesToPatientController;
        angular
            .module('consolo')
            .controller('AddMedFavoritesToPatientController', AddMedFavoritesToPatientController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('addToMedication', {
            bindings: { id: '<', description: '<' },
            templateUrl: '/y/templates/medications/medication-favorites/add-to-medication.html',
            controller: 'AddToMedicationController as $ctrl',
        });
        var AddToMedicationController = /** @class */ (function () {
            function AddToMedicationController(logger, MedicationFavoritesService, $state, Session, $mdDialog, MedicationService) {
                var _this = this;
                this.logger = logger;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.$state = $state;
                this.Session = Session;
                this.$mdDialog = $mdDialog;
                this.MedicationService = MedicationService;
                this.readonly = true;
                var user = this.Session.getUser();
                this.MedicationFavoritesService.getPhysiciansWithFavorites().then(function (physicians) {
                    _this.physicians = physicians;
                    if (user.physician &&
                        _this.physicians.filter(function (physician) {
                            return physician.id === user.physician.id;
                        }).length) {
                        _this.physician_id = user.physician.id;
                    }
                });
            }
            AddToMedicationController.prototype.searchMedicationFavorites = function (q) {
                if (!this.physician_id) {
                    this.errors = ['You must select a physician.'];
                    this.logger.error('You must select a physician.');
                }
                else {
                    this.errors = null;
                    return this.MedicationFavoritesService.getMedicationFavorites(this.physician_id, q);
                }
            };
            AddToMedicationController.prototype.add = function () {
                if (this.medicationFavorite) {
                    return this.$mdDialog.hide(this.medicationFavorite);
                }
                else {
                    this.errors = ['You must select a medication favorite.'];
                    this.logger.error('You must select a medication favorite.');
                }
            };
            AddToMedicationController.$inject = [
                'logger',
                'MedicationFavoritesService',
                '$state',
                'SessionService',
                '$mdDialog',
                'MedicationService',
            ];
            return AddToMedicationController;
        }());
        common.AddToMedicationController = AddToMedicationController;
        angular
            .module('consolo')
            .controller('AddToMedicationController', AddToMedicationController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var SlidingScale = consolo.domain.SlidingScale;
        var SlidingScaleRange = consolo.domain.SlidingScaleRange;
        'use strict';
        angular.module('consolo').component('medicationFavorite', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/medications/medication-favorites/medication-favorite.html',
            controller: 'MedicationFavoriteController as $ctrl',
        });
        var MedicationFavoriteController = /** @class */ (function () {
            function MedicationFavoriteController(logger, Session, $http, MedicationFavoritesService, MedicationService, $timeout, $state, $stateParams, $mdDialog) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.MedicationService = MedicationService;
                this.$timeout = $timeout;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.$mdDialog = $mdDialog;
                this.saving = false;
                this.createFromMedication = false;
                this.filterFormularyOnly = false;
                this.formularyLocked = false;
                this.formularyEnabled = false;
                this.slidingScaleDosageLevels = [];
                this.isPerSlidingScale = false;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.slidingScaleDosageLevels = [{ code: "Low Dose", description: "Low Dose" }, { code: "Moderate Dose", description: "Moderate Dose" }, { code: "High Dose", description: "High Dose" }];
                var session = Session.get();
                this.formularyEnabled = session.agency.formulary_enabled;
                if (session.agency.formulary_enabled && session.agency.formulary_enforced) {
                    this.filterFormularyOnly = true;
                    this.formularyLocked = true;
                }
                if ($stateParams.medicationFavoriteId) {
                    this.MedicationService.isReady().then(function () {
                        _this.MedicationFavoritesService.get($stateParams.medicationFavoriteId).then(function (medicationFavorite) {
                            medicationFavorite.display_name =
                                medicationFavorite.name + ' ' + medicationFavorite.strength;
                            _this.medicationFavorite = medicationFavorite;
                            if (_this.medicationFavorite.sliding_scale) {
                                _this.medicationFavorite.sliding_scale = new consolo.domain.SlidingScale(_this.medicationFavorite["sliding_scale"]);
                            }
                            _this.MedicationService.setDosageUnitId(_this.medicationFavorite);
                            _this.MedicationService.setDosageRouteFDA(_this.medicationFavorite, _this.medicationFavorite.route_of_admin);
                            if (medicationFavorite.sliding_scale) {
                                _this.isPerSlidingScale = true;
                            }
                        });
                    });
                }
                else {
                    if (MedicationFavoritesService.medicationToCreateFrom) {
                        this.medicationFavorite = MedicationFavoritesService.getMedicationValues(MedicationFavoritesService.medicationToCreateFrom);
                        this.MedicationService.setDosageUnitId(this.medicationFavorite);
                        this.MedicationService.setDosageRouteFDA(this.medicationFavorite, this.medicationFavorite.route_of_admin);
                        this.createFromMedication = true;
                        MedicationFavoritesService.medicationToCreateFrom = null;
                    }
                    if (!this.medicationFavorite) {
                        this.medicationFavorite = {};
                    }
                    // Set the physician based off user physician if present
                    if (user.physician && !this.medicationFavorite.physician_id) {
                        this.medicationFavorite.physician = user.physician;
                        this.medicationFavorite.physician_id = user.physician.id;
                    }
                    if (!this.medicationFavorite.physician_id &&
                        this.MedicationFavoritesService.currentPhysicianId) {
                        this.medicationFavorite.physician = this.MedicationFavoritesService.findPhysician(this.MedicationFavoritesService.currentPhysicianId);
                        if (this.medicationFavorite.physician) {
                            this.medicationFavorite.physician_id = this.MedicationFavoritesService.currentPhysicianId;
                        }
                    }
                    if (this.medicationFavorite.sliding_scale) {
                        this.isPerSlidingScale = true;
                    }
                }
            }
            MedicationFavoriteController.prototype.cancel = function () {
                if (this.$stateParams.patientId) {
                    //If there is a patient ID it will be on a medication page in a dialog
                    return this.$mdDialog.cancel();
                }
                else {
                    //If no patient ID then it's on the global med favorites page, go back to the index page
                    this.$state.go('medication-favorites');
                }
            };
            MedicationFavoriteController.prototype.clearMedicationFavorite = function () {
                this.medicationFavorite.name = null;
                this.medicationFavorite.display_name = null;
                this.medicationsList = null;
            };
            MedicationFavoriteController.prototype.save = function () {
                var _this = this;
                this.errors = [];
                if (!this.isPerSlidingScale) {
                    this.medicationFavorite.sliding_scale = null;
                }
                if (!this.medicationFavorite.physician_id) {
                    this.errors.push('You must select a physician');
                }
                if (!this.medicationFavorite.name) {
                    this.errors.push('You must select a medication');
                }
                if (!this.medicationFavorite.dosage_amount_low && !this.isPerSlidingScale) {
                    this.errors.push('You must enter the Dosage Amount Low');
                }
                if (!this.errors.length) {
                    this.saving = true;
                    var newMedication_1 = this.medicationFavorite.id ? false : true;
                    return this.MedicationFavoritesService.upsert(this.medicationFavorite)
                        .then(function (medicationFavorite) {
                        _this.errors = null;
                        var message = _this.medicationFavorite.name + ' was successfully';
                        message += newMedication_1 ? ' created.' : ' updated.';
                        _this.logger.success(message);
                        if (!_this.createFromMedication) {
                            _this.$state.go('medication-favorites');
                        }
                        else if (_this.$mdDialog) {
                            return _this.$mdDialog.hide(medicationFavorite);
                        }
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.logger.error(this.errors.join(', '));
                }
            };
            MedicationFavoriteController.prototype.search = function (q, medispanOnly, formularyOnly) {
                var _this = this;
                return this.MedicationService.searchAllSources(q, medispanOnly, formularyOnly).then(function (medications) {
                    _this.medicationsList = medications;
                    return _this.medicationsList;
                });
            };
            MedicationFavoriteController.prototype.setValues = function (selectedMedication) {
                var low_dose = this.medicationFavorite.dosage_amount_low;
                var high_dose = this.medicationFavorite.dosage_amount_high;
                var ss = this.medicationFavorite.sliding_scale;
                if (selectedMedication && selectedMedication.name) {
                    if (this.medicationFavorite.physician_id) {
                        selectedMedication.physician_id = this.medicationFavorite.physician_id;
                    }
                    this.medicationFavorite = this.MedicationFavoritesService.getMedicationValues(selectedMedication);
                    // selectedMedication.physician_id = this.medicationFavorite.physician_id;
                    this.MedicationService.setDosageRoute(this.medicationFavorite, selectedMedication.route);
                    this.MedicationService.setDosageUnitId(this.medicationFavorite);
                }
                if (this.isPerSlidingScale) {
                    this.medicationFavorite.dosage_amount_low = low_dose;
                    this.medicationFavorite.dosage_amount_high = high_dose;
                    this.medicationFavorite.sliding_scale = ss;
                }
            };
            MedicationFavoriteController.prototype.updateSlidingScale = function () {
                if (this.isPerSlidingScale) {
                    this.medicationFavorite.dosage_amount_low = 0;
                    this.medicationFavorite.dosage_amount_high = 0;
                    if (!this.medicationFavorite.sliding_scale) {
                        this.medicationFavorite.sliding_scale = this.newSlidingScale();
                    }
                }
                else {
                    this.medicationFavorite.sliding_scale = null;
                }
            };
            MedicationFavoriteController.prototype.newSlidingScale = function () {
                var sliding_scale = new SlidingScale({});
                sliding_scale.sliding_scale_ranges = [];
                // Per ticket, start with 5 blank rows/ranges
                for (var i = 0; i < 4; i++) {
                    sliding_scale.sliding_scale_ranges.push(new SlidingScaleRange({ order: i, low_range: 0, high_range: 0, units: 0, comments: "" }));
                }
                // The last has no low range
                sliding_scale.sliding_scale_ranges.push(new SlidingScaleRange({ order: 4, low_range: null, high_range: 0, units: 0, comments: "" }));
                sliding_scale.reorderRanges();
                return sliding_scale;
            };
            MedicationFavoriteController.$inject = [
                'logger',
                'SessionService',
                '$http',
                'MedicationFavoritesService',
                'MedicationService',
                '$timeout',
                '$state',
                '$stateParams',
                '$mdDialog',
            ];
            return MedicationFavoriteController;
        }());
        common.MedicationFavoriteController = MedicationFavoriteController;
        angular
            .module('consolo')
            .controller('MedicationFavoriteController', MedicationFavoriteController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('medicationFavorites', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication-favorites/medication-favorites.html',
            controller: 'MedicationFavoritesController as $ctrl',
        });
        var MedicationFavoritesController = /** @class */ (function () {
            function MedicationFavoritesController(logger, Session, $http, MedicationFavoritesService, $state, $mdDialog) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
                this.loading = false;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.medicationFavorites = [];
                if (user.physician && user.physician.has_medication_favorites) {
                    this.physician_id = user.physician.id;
                    this.getMedicationFavorites();
                }
                if (this.MedicationFavoritesService.currentPhysicianId && !this.physician_id) {
                    this.physician_id = this.MedicationFavoritesService.currentPhysicianId;
                    this.getMedicationFavorites();
                }
                this.medicationFavoriteTableLayout = {
                    id_field: 'id',
                    options: {
                        allowRowSelect: true,
                    },
                    columns: [
                        {
                            title: 'Medication',
                            field: 'fullDescription',
                            formatValueFn: function (medicationFavorite) {
                                if (medicationFavorite.sliding_scale) {
                                    var dose_level = ((medicationFavorite.sliding_scale.dose_level || medicationFavorite.sliding_scale.dose_level != null) ? medicationFavorite.sliding_scale.dose_level : "");
                                    return medicationFavorite.fullDescription + "Per Sliding Scale" + " " + dose_level;
                                }
                                else {
                                    return medicationFavorite.fullDescription;
                                }
                            }
                        },
                        {
                            title: 'On Formulary',
                            field: 'on_formulary',
                            type: csg.datatable.ColumnTypes.boolean,
                            columnHideFn: function () {
                                var session = Session.get();
                                return !session.agency.formulary_enabled;
                            },
                        },
                    ],
                    headerMenu: {
                        items: [
                            {
                                label: 'Clone To Other Provider',
                                icon: 'add',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                callbackFn: function (data) {
                                    var ids = data.map(function (selectedFavorite) {
                                        return selectedFavorite.id;
                                    });
                                    return _this.$mdDialog
                                        .show({
                                        template: '<md-dialog flex="80" aria-label="Add Signature dialog">' +
                                            '  <md-dialog-content>' +
                                            '<select-physician flex=""></select-physician>' +
                                            '  </md-dialog-content>' +
                                            '</md-dialog>',
                                    })
                                        .then(function (physician_id) {
                                        _this.MedicationFavoritesService.clone(ids, physician_id);
                                        return true;
                                    });
                                },
                                showCount: true,
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (medicationFavorite) {
                                    return _this.$state.go('medication-favorites.view', {
                                        medicationFavoriteId: medicationFavorite.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationFavoritesService.viewPermission;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (medicationFavorite) {
                                    return _this.$state.go('medication-favorites.edit', {
                                        medicationFavoriteId: medicationFavorite.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationFavoritesService.updatePermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (medicationFavorite) {
                                    if (confirm('Are you sure you would like to delete this Medication Favorite?')) {
                                        return _this.MedicationFavoritesService.delete(medicationFavorite).then(function (deletedMedicationFavorite) {
                                            _this.logger.success('The medication favorite has been deleted.');
                                            _this.getMedicationFavorites();
                                        });
                                    }
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationFavoritesService.deletePermission;
                                },
                            },
                        ],
                    },
                };
            }
            MedicationFavoritesController.prototype.getMedicationFavorites = function () {
                var _this = this;
                this.loading = true;
                this.selectedMedicationFavorites = [];
                this.MedicationFavoritesService.currentPhysicianId = this.physician_id;
                this.MedicationFavoritesService.getMedicationFavorites(this.physician_id, null, false)
                    .then(function (medicationFavorites) {
                    _this.medicationFavorites = medicationFavorites;
                })
                    .finally(function () {
                    _this.loading = false;
                });
            };
            MedicationFavoritesController.$inject = [
                'logger',
                'SessionService',
                '$http',
                'MedicationFavoritesService',
                '$state',
                '$mdDialog',
            ];
            return MedicationFavoritesController;
        }());
        common.MedicationFavoritesController = MedicationFavoritesController;
        angular
            .module('consolo')
            .controller('MedicationFavoritesController', MedicationFavoritesController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MedicationFavoritesService = /** @class */ (function () {
            function MedicationFavoritesService($http, PermissionsService, $mdDialog, Session) {
                var _this = this;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.apiUrl = '/api/v1/medication_favorites';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.formularyEnforced = false;
                this.formularyEnabled = false;
                this.$http.get('/api/v1/physicians?has_medication_favorites=true').then(function (response) {
                    _this.physicians = response.data;
                });
                this.updatePermission = this.PermissionsService.can('update', 'medication_favorites');
                this.createPermission = this.PermissionsService.can('create', 'medication_favorites');
                this.deletePermission = this.PermissionsService.can('destroy', 'medication_favorites');
                this.viewPermission = this.PermissionsService.can('show', 'medication_favorites');
                var session = Session.get();
                this.formularyEnabled = session.agency.formulary_enabled;
                this.formularyEnforced = session.agency.formulary_enforced;
            }
            MedicationFavoritesService.prototype.addMedicationFavorites = function (onPhysicianOrder, physician_id) {
                return this.$mdDialog.show({
                    template: '<md-dialog flex="80" aria-label="Add Medication Favorites">' +
                        '  <md-dialog-content>' +
                        '    <add-med-favorites-to-patient physician-id="' +
                        physician_id +
                        '" on-physician-order="' +
                        onPhysicianOrder +
                        '"></add-med-favorites-to-patient>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                });
            };
            MedicationFavoritesService.prototype.addToPhysicianModal = function (medication) {
                this.medicationToCreateFrom = medication;
                return this.$mdDialog.show({
                    template: '<medication-favorite readonly="false" flex=""></medication-favorite>',
                });
            };
            MedicationFavoritesService.prototype.addToPhysician = function (medication_id, physician_id) {
                return this.$http
                    .post(this.apiUrl + '/add_to_physician', {
                    medication_id: medication_id,
                    physician_id: physician_id,
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            MedicationFavoritesService.prototype.clone = function (medicationFavoriteIds, physicianId) {
                return this.$http
                    .post(this.apiUrl + '/clone', {
                    medication_favorite_ids: medicationFavoriteIds,
                    physician_id: physicianId,
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            MedicationFavoritesService.prototype.delete = function (medicationFavorite) {
                return this.$http.delete(this.apiUrl + '/' + medicationFavorite.id).then(function (response) {
                    return response.data;
                });
            };
            MedicationFavoritesService.prototype.findPhysician = function (physicainId) {
                if (this.physicians) {
                    return this.physicians.filter(function (physician) {
                        return physician.id === physicainId;
                    })[0];
                }
                else {
                    return null;
                }
            };
            MedicationFavoritesService.prototype.get = function (medicationFavoriteId) {
                var _this = this;
                return this.$http.get(this.apiUrl + '/' + medicationFavoriteId).then(function (response) {
                    _this.setDescription(response.data);
                    return response.data;
                });
            };
            MedicationFavoritesService.prototype.getPhysiciansWithFavorites = function () {
                return this.$http
                    .get('/api/v1/physicians?medication_favorites_present=true')
                    .then(function (response) {
                    return response.data;
                });
            };
            MedicationFavoritesService.prototype.getMedicationFavorites = function (physician_id, q, onFormulary, sliding_scale) {
                var _this = this;
                if ((onFormulary === null || onFormulary === undefined) &&
                    this.formularyEnabled &&
                    this.formularyEnforced) {
                    onFormulary = true;
                }
                return this.$http
                    .get(this.apiUrl, {
                    params: {
                        physician_id: physician_id,
                        q: q,
                        on_formulary: onFormulary,
                        sliding_scale: sliding_scale,
                    },
                })
                    .then(function (response) {
                    return response.data.map(function (medFavorite) {
                        _this.setDescription(medFavorite);
                        _this.setSlidingScale(medFavorite);
                        return medFavorite;
                    });
                });
            };
            MedicationFavoritesService.prototype.setDescription = function (medicationFavorite) {
                medicationFavorite.fullDescription = medicationFavorite.name + ' ' + medicationFavorite.frequency;
            };
            MedicationFavoritesService.prototype.setSlidingScale = function (medicationFavorite) {
                medicationFavorite.slidingScaleDoseLevel = "";
                if (medicationFavorite.sliding_scale) {
                    if (medicationFavorite.sliding_scale.dose_level) {
                        medicationFavorite.slidingScaleDoseLevel = medicationFavorite.sliding_scale.dose_level;
                    }
                }
            };
            MedicationFavoritesService.prototype.upsert = function (medicationFavorite) {
                if (medicationFavorite.id) {
                    return this.$http
                        .patch(this.apiUrl + '/' + medicationFavorite.id, medicationFavorite)
                        .then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, medicationFavorite).then(function (response) {
                        return response.data;
                    });
                }
            };
            MedicationFavoritesService.prototype.addToPatient = function (medicationFavoriteIds, patientId, physicianId, orderDate, status, pharmacy, interactionsRecord) {
                return this.$http
                    .post(this.apiUrl + '/add_to_patient', {
                    medication_favorite_ids: medicationFavoriteIds,
                    patient_id: patientId,
                    physician_id: physicianId,
                    order_date: orderDate,
                    status: status,
                    pharmacy_id: pharmacy.id,
                    surescripts_pharmacy_id: pharmacy.surescripts_pharmacy_id,
                    interaction_records: interactionsRecord
                })
                    .then(function (response) {
                    return response.data.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                });
            };
            MedicationFavoritesService.prototype.getMedicationValues = function (medication) {
                var medicationFavorite = {};
                medicationFavorite.name = medication.name;
                if (medication.physician_id) {
                    medicationFavorite.physician_id = medication.physician_id;
                }
                medicationFavorite.dose = medication.strength;
                medicationFavorite.doses = medication.strength_unit;
                medicationFavorite.frequency = medication.frequency;
                medicationFavorite.form = medication.form;
                medicationFavorite.dosage_frequency_unit = medication.dosage_frequency_unit;
                medicationFavorite.dosage_unit_id = medication.dosage_unit_id;
                medicationFavorite.dosage_unit_display = medication.dosage_unit_display;
                medicationFavorite.dosage_amount_low = medication.dosage_amount_low;
                medicationFavorite.dosage_amount_high = medication.dosage_amount_high;
                medicationFavorite.dosage_frequency = medication.dosage_frequency;
                medicationFavorite.dea_class = medication.dea_class ? medication.dea_class : medication.controlled_substance_code;
                medicationFavorite.drug_class = medication.drug_class;
                medicationFavorite.ndc = medication.ndc;
                medicationFavorite.route_of_admin = medication.route;
                medicationFavorite.prn_reason_ids = medication.prn_reason_ids;
                medicationFavorite.prn = medication.prn;
                medicationFavorite.potency_code = medication.potency_code;
                medicationFavorite.quantity = medication.quantity;
                if (medication.quantity_unit_of_measure) {
                    medicationFavorite.quantity_unit_of_measure =
                        typeof medication.quantity_unit_of_measure === 'string'
                            ? medication.quantity_unit_of_measure
                            : medication.quantity_unit_of_measure.description;
                }
                medicationFavorite.refills = medication.refills;
                medicationFavorite.sliding_scale = medication.sliding_scale;
                medicationFavorite.notes_to_pharmacist = medication.notes_to_pharmacist;
                medicationFavorite.notes = medication.notes;
                medicationFavorite.eprescribe_instructions = medication.eprescribe_instructions;
                medicationFavorite.dosage_frequency_multiplier = medication.dosage_frequency_multiplier;
                medicationFavorite.authoritative_source = medication.authoritative_source;
                medicationFavorite.display_name = medication.display_name;
                this.setDescription(medicationFavorite);
                medicationFavorite.is_compound = medication.is_compound || medication.type === 'compound';
                if (medicationFavorite.is_compound) {
                    medicationFavorite.ndc = medication.ingredients[0]['ndc'];
                    medicationFavorite.ingredients = medication.ingredients;
                    medicationFavorite.compound_id = medication.compound_id;
                }
                return medicationFavorite;
            };
            MedicationFavoritesService.$inject = ['$http', 'PermissionsService', '$mdDialog', 'SessionService'];
            return MedicationFavoritesService;
        }());
        services.MedicationFavoritesService = MedicationFavoritesService;
        angular.module('consolo').service('MedicationFavoritesService', MedicationFavoritesService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('selectPhysician', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication-favorites/select-physician-modal.html',
            controller: 'SelectPhysicianController as $ctrl',
        });
        var SelectPhysicianController = /** @class */ (function () {
            function SelectPhysicianController(MedicationFavoritesService, $mdDialog) {
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.$mdDialog = $mdDialog;
            }
            SelectPhysicianController.prototype.add = function () {
                return this.$mdDialog.hide(this.physician_id);
            };
            SelectPhysicianController.$inject = ['MedicationFavoritesService', '$mdDialog'];
            return SelectPhysicianController;
        }());
        common.SelectPhysicianController = SelectPhysicianController;
        angular
            .module('consolo')
            .controller('SelectPhysicianController', SelectPhysicianController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationFillsIndexController = /** @class */ (function () {
            function MedicationFillsIndexController(CurrentPatient) {
                this.CurrentPatient = CurrentPatient;
                this.saving = false;
            }
            MedicationFillsIndexController.$inject = ['CurrentPatient'];
            return MedicationFillsIndexController;
        }());
        angular
            .module('consolo')
            .controller('MedicationFillsIndexController', MedicationFillsIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationFillController = /** @class */ (function () {
            function MedicationFillController(CurrentPatient, MedicationFillService, Session, MedicationService, medicationFill, $state, logger, $mdDialog, PharmacyService, $q) {
                this.CurrentPatient = CurrentPatient;
                this.MedicationFillService = MedicationFillService;
                this.Session = Session;
                this.MedicationService = MedicationService;
                this.medicationFill = medicationFill;
                this.$state = $state;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.PharmacyService = PharmacyService;
                this.$q = $q;
                this.saving = false;
                this.requiredBasedOnDate = false;
                //Set the autocomplete fields, don't like having to do this
                if (medicationFill.id) {
                    if (medicationFill.ndc) {
                        this.selectedMedication = new consolo.domain.Medication(medicationFill);
                        this.selectedMedication.fullDescription =
                            this.medicationFill.medication_name +
                                ' [ ' +
                                this.medicationFill.dosage +
                                ' ' +
                                this.medicationFill.dosage_unit +
                                ' ] ' +
                                this.medicationFill.form +
                                ' - ' +
                                this.medicationFill.ndc;
                    }
                    if (medicationFill.hcpcs) {
                        this.selectedHCPCS = {
                            hcpcs: medicationFill.hcpcs,
                            short_description: medicationFill.hcpcs_description,
                            description: medicationFill.hcpcs + ' ' + medicationFill.hcpcs_description,
                        };
                    }
                    if (medicationFill.service) {
                        this.selectedService = medicationFill.service;
                    }
                    this.medicationFill.effective_date = new Date(this.medicationFill.effective_date);
                    this.setRequired();
                }
            }
            MedicationFillController.prototype.createMedication = function () {
                var _this = this;
                this.saving = true;
                return this.MedicationService.getNewMedication()
                    .then(function (medication) {
                    return _this.setMedicationValues(medication, _this.medicationFill).then(function () {
                        medication.orderdate = _this.medicationFill.effective_date;
                        var pharmacyPromise = _this.$q.defer();
                        if (_this.medicationFill.service && _this.medicationFill.service.type === 'Pharmacy') {
                            medication.pharmacy_id = _this.medicationFill.service_id;
                            _this.PharmacyService.getConsoloPharmacyById(_this.medicationFill.service_id).then(function (pharmacy) {
                                if (pharmacy) {
                                    medication.pharmacy = pharmacy;
                                    pharmacyPromise.resolve();
                                }
                            });
                        }
                        else {
                            pharmacyPromise.resolve();
                        }
                        pharmacyPromise.promise.then(function () {
                            var url = "'/y/templates/patients/sections/medications/medication.html'";
                            return _this.$mdDialog
                                .show({
                                clickOutsideToClose: true,
                                bindToController: true,
                                template: '<md-dialog flex="80" aria-label="Create New Medication">' +
                                    '  <md-dialog-content>' +
                                    '   <div ng-include="' +
                                    url +
                                    '"/>' +
                                    '  </md-dialog-content>' +
                                    '</md-dialog>',
                                controller: 'PatientMedicationController as vm',
                                locals: {
                                    medication: medication,
                                    currentPatient: _this.CurrentPatient.patient,
                                    redirect: false,
                                },
                                multiple: true,
                                skipHide: true,
                                preserveScope: true,
                            })
                                .then(function (newMedication) {
                                _this.MedicationService.setMedicationAndReplace(newMedication);
                                return _this.$mdDialog.hide(newMedication);
                            });
                        });
                    });
                })
                    .finally(function () {
                    _this.saving = false;
                });
            };
            MedicationFillController.prototype.reverse = function () {
                var _this = this;
                this.saving = true;
                this.MedicationFillService.reverse(this.medicationFill.id)
                    .then(function (reversal) {
                    _this.logger.info('The medication fill has been reversed.');
                    _this.$state.go('patients.medication-fills.view', {
                        patientId: reversal.patient_id,
                        medicationFillId: reversal.id,
                    });
                }, function (errors) {
                    _this.logger.error('There were errors encountered trying to create a reversal.');
                    _this.errors = errors.data.errors;
                })
                    .finally(function () {
                    _this.saving = false;
                });
            };
            MedicationFillController.prototype.setRequired = function () {
                if (this.medicationFill.effective_date < this.MedicationFillService.logicDateChange) {
                    this.requiredBasedOnDate = true;
                }
                else {
                    this.requiredBasedOnDate = false;
                }
            };
            MedicationFillController.prototype.searchMedications = function (searchValue) {
                return this.MedicationService.searchMedications({ q: searchValue }, false).then(function (medications) {
                    return medications;
                });
            };
            MedicationFillController.prototype.save = function () {
                var _this = this;
                if (!this.medicationFill.patient_id) {
                    this.errors = ['You must select a patient.'];
                }
                else {
                    this.saving = true;
                    var newMedication_1 = this.medicationFill.id ? false : true;
                    this.MedicationFillService.upsert(this.medicationFill)
                        .then(function (medicationFill) {
                        _this.errors = null;
                        var message = _this.MedicationFillService.medicationFillDisplay(medicationFill) +
                            ' was successfully';
                        message += newMedication_1 ? ' created.' : ' updated.';
                        _this.logger.success(message);
                        if (newMedication_1) {
                            _this.medicationFill = {
                                patient_id: medicationFill.patient_id,
                                effective_date: medicationFill.effective_date,
                                revenue_code: medicationFill.revenue_code,
                                prescription: true,
                            };
                            _this.selectedHCPCS = null;
                            _this.selectedMedication = null;
                            _this.selectedService = null;
                        }
                        else {
                            _this.$state.go('patients.medication-fills.view', {
                                patientId: _this.medicationFill.patient_id,
                                medicationFillId: _this.medicationFill.id,
                            });
                        }
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            MedicationFillController.prototype.setHCPCS = function (selectedHCPCS) {
                var _this = this;
                if (selectedHCPCS) {
                    if (selectedHCPCS.hcpcs_code) {
                        this.medicationFill.hcpcs = selectedHCPCS.hcpcs_code;
                        if (this.medicationFill.amount === undefined || this.medicationFill.amount === null) {
                            this.medicationFill.amount = selectedHCPCS.amount;
                        }
                        if (!this.medicationFill.ndc) {
                            this.MedicationService.medicationLookup(selectedHCPCS.ndc, true).then(function (results) {
                                if (results && results.length > 0) {
                                    _this.setMedicationOnSelection(results[0]);
                                }
                            });
                        }
                    }
                    else {
                        this.medicationFill.hcpcs = selectedHCPCS.code;
                        this.medicationFill.hcpcs_description = selectedHCPCS.short_description;
                    }
                }
                else {
                    this.medicationFill.hcpcs = null;
                    this.medicationFill.hcpcs_description = null;
                }
            };
            MedicationFillController.prototype.searchHCPCS = function (q) {
                var searchPromises = [];
                var hcpcsResults = [];
                searchPromises.push(this.MedicationService.searchHCPCS(q).then(function (results) {
                    hcpcsResults = hcpcsResults.concat(results);
                }));
                searchPromises.push(this.MedicationFillService.lookupVaccineCodes(q).then(function (results) {
                    hcpcsResults = hcpcsResults.concat(results.map(function (result) {
                        result.description = result.hcpcs_code;
                        return result;
                    }));
                }));
                return this.$q.all(searchPromises).then(function () {
                    return hcpcsResults;
                });
            };
            MedicationFillController.prototype.setService = function (selectedService) {
                if (selectedService) {
                    this.medicationFill.service = selectedService;
                    this.medicationFill.service_id = selectedService.id;
                }
                else {
                    this.medicationFill.service_id = null;
                    this.medicationFill.service = null;
                }
            };
            MedicationFillController.prototype.setMedicationOnSelection = function (selectedMedication) {
                var _this = this;
                if (selectedMedication) {
                    this.medicationFill.form = selectedMedication.form;
                    this.medicationFill.dosage = selectedMedication.strength;
                    this.medicationFill.dosage_unit = selectedMedication.strength_unit;
                    this.medicationFill.gpi = selectedMedication.gpi;
                    this.medicationFill.medication_name = selectedMedication.name;
                    this.medicationFill.ndc = selectedMedication.ndc;
                    this.medicationFill.route = selectedMedication.route;
                    this.MedicationFillService.medicationFillDisplay(this.medicationFill);
                    if (!this.medicationFill.hcpcs) {
                        this.MedicationFillService.lookupVaccineCodes(this.medicationFill.ndc).then(function (results) {
                            if (results && results.length > 0) {
                                _this.medicationFill.hcpcs = results[0].hcpcs_code;
                            }
                            if (!_this.medicationFill.amount) {
                                _this.medicationFill.amount = results[0].amount;
                            }
                        });
                    }
                }
                else {
                    this.medicationFill.form = null;
                    this.medicationFill.dosage = null;
                    this.medicationFill.dosage_unit = null;
                    this.medicationFill.gpi = null;
                    this.medicationFill.medication_name = null;
                    this.medicationFill.ndc = null;
                    this.medicationFill.route = null;
                    this.MedicationFillService.medicationFillDisplay(this.medicationFill);
                }
            };
            MedicationFillController.prototype.setMedicationValues = function (medication, medicationFill) {
                var _this = this;
                var item = { name: medicationFill.medication_name };
                if (medicationFill.ndc) {
                    item = { name: medicationFill.ndc };
                }
                return this.MedicationService.searchMedications(item, false).then(function (medications) {
                    if (medications.length) {
                        _this.MedicationService.setMedicationValues(medication, medications[0]);
                    }
                });
            };
            MedicationFillController.$inject = [
                'CurrentPatient',
                'MedicationFillService',
                'SessionService',
                'MedicationService',
                'medicationFill',
                '$state',
                'logger',
                '$mdDialog',
                'PharmacyService',
                '$q',
            ];
            return MedicationFillController;
        }());
        angular.module('consolo').controller('MedicationFillController', MedicationFillController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var MedicationFillService = /** @class */ (function () {
            function MedicationFillService($http, PermissionsService) {
                var _this = this;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.apiUrl = '/api/v1/medication_fills';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.reversePermission = false;
                this.vaccineAdministrationCodes = [
                    { id: 'G0008', description: 'G0008 Administration of influenza virus vaccine' },
                    { id: 'G0009', description: 'G0009 Administration of pneumococcal vaccine' },
                    { id: 'G0010', description: 'G0010 Administration of hepatitis B vaccine' },
                ];
                this.$http.get(this.apiUrl + '/select_options').then(function (response) {
                    _this.revenueCodes = response.data.revenue_codes;
                    _this.unitOfMeasures = response.data.unit_of_measures;
                    _this.logicDateChange = new Date(response.data.logic_date_change);
                });
                this.updatePermission = this.PermissionsService.can('update', 'medication_fills');
                this.createPermission = this.PermissionsService.can('create', 'medication_fills');
                this.deletePermission = this.PermissionsService.can('destroy', 'medication_fills');
                this.viewPermission = this.PermissionsService.can('show', 'medication_fills');
                this.reversePermission = this.PermissionsService.can('reversal', 'medication_fills');
            }
            MedicationFillService.prototype.medicationFillDisplay = function (medicationFill) {
                medicationFill.medication_summary =
                    medicationFill.medication_name +
                        ' [ ' +
                        medicationFill.dosage +
                        ' ' +
                        medicationFill.dosage_unit +
                        ' ] ' +
                        medicationFill.form;
                if (medicationFill.ndc) {
                    medicationFill.medication_summary += ' - ' + medicationFill.ndc;
                }
                if (medicationFill.id) {
                    return medicationFill.medication_summary + ' (' + medicationFill.id + ')';
                }
                return medicationFill.medication_summary;
            };
            MedicationFillService.prototype.lookupVaccineCodes = function (q, date) {
                if (date === undefined) {
                    date = new Date();
                }
                return this.$http.get('/api/v1/vaccine_codes', { params: { q: q, date: date } }).then(function (resp) {
                    return resp.data;
                });
            };
            MedicationFillService.prototype.pharmacyDmeLookup = function (q) {
                return this.$http
                    .get('/api/v1/lookup/services', {
                    params: { q: q, 'types[]': ['Pharmacy', 'DmeProvider'] },
                })
                    .then(function (resp) {
                    return resp.data;
                });
            };
            MedicationFillService.prototype.patientAutocompleteLookup = function (query) {
                return this.$http.get('/api/v1/patients', { params: { q: query, all_offices: true } }).then(function (response) {
                    return response.data;
                });
            };
            MedicationFillService.prototype.reverse = function (id) {
                return this.$http.post(this.apiUrl + '/' + id + '/reverse').then(function (response) {
                    return response.data;
                });
            };
            MedicationFillService.prototype.upsert = function (medicationFill) {
                if (medicationFill.id) {
                    return this.$http
                        .patch(this.apiUrl + '/' + medicationFill.id, medicationFill)
                        .then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, medicationFill).then(function (response) {
                        return response.data;
                    });
                }
            };
            MedicationFillService.prototype.getMedicationFill = function (id) {
                return this.$http.get(this.apiUrl, { params: { id: id } }).then(function (response) {
                    return response.data[0];
                });
            };
            MedicationFillService.$inject = ['$http', 'PermissionsService'];
            return MedicationFillService;
        }());
        services.MedicationFillService = MedicationFillService;
        angular.module('consolo').service('MedicationFillService', MedicationFillService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MedicationFormularyDataTableService = /** @class */ (function (_super) {
            __extends(MedicationFormularyDataTableService, _super);
            function MedicationFormularyDataTableService($http, $interval, MedicationFormularyService, $state, logger, $mdDialog, PubSubService) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.MedicationFormularyService = MedicationFormularyService;
                _this.$state = $state;
                _this.logger = logger;
                _this.$mdDialog = $mdDialog;
                _this.PubSubService = PubSubService;
                _this.loadingData = false;
                _this.showFilters = false;
                _this.hideSearchTerm = true;
                _this.searchOnReset = true;
                _this.activate();
                return _this;
            }
            MedicationFormularyDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/medication_formulary_entries';
                this.filters = new consolo.domain.MedicationFormularyFilter();
                this.tableLayout = {
                    // options, headerMenu, rowMenu, columns, subtable
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: true,
                        allowRowSelect: this.MedicationFormularyService.deletePermission,
                    },
                    headerMenu: {
                        items: [
                            {
                                label: 'Delete Formulary Entries',
                                icon: 'delete',
                                dataset: csg.datatable.ICallbackDataSet.selected,
                                conditionalHideFn: function () {
                                    return !_this.MedicationFormularyService.deletePermission;
                                },
                                callbackFn: function (data) {
                                    var ids = data.map(function (selectedEntry) {
                                        return selectedEntry.id;
                                    });
                                    return _this.MedicationFormularyService.deleteMultiple(ids).then(function () {
                                        _this.logger.success('The entries have been deleted.');
                                        _this.search();
                                        return true;
                                    });
                                },
                                showCount: true,
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            type: 'short-text',
                        },
                        {
                            title: 'Strength',
                            field: 'strength',
                            type: 'short-text',
                        },
                        {
                            title: 'Strength Unit',
                            field: 'strength_unit',
                            type: 'short-text',
                        },
                        {
                            title: 'Form',
                            field: 'form',
                            type: 'short-text',
                        },
                        {
                            title: 'Route',
                            field: 'route',
                            type: 'short-text',
                        },
                        {
                            title: 'Dosage Form',
                            field: 'dosage_form',
                            type: 'short-text',
                        },
                        {
                            title: 'Approval Required?',
                            field: 'approval_required',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        {
                            title: 'Additive Only?',
                            field: 'used_only_for_compounds',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        {
                            title: 'Reason Required?',
                            field: 'reason_required_to_add',
                            type: csg.datatable.ColumnTypes.boolean,
                        },
                        {
                            title: 'NDCs',
                            field: 'ndcs',
                            type: 'short-text',
                            formatValueFn: function (medicationFormularyEntry) {
                                return (medicationFormularyEntry.ndcs[0].ndc +
                                    (medicationFormularyEntry.ndcs.length > 1
                                        ? ' (' + medicationFormularyEntry.ndcs.length + ')'
                                        : ''));
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (medicationFormularyEntry) {
                                    _this.$state.go('medication-formulary.edit', {
                                        medicationFormularyEntryId: medicationFormularyEntry.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationFormularyService.updatePermission;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (medicationFormularyEntry) {
                                    _this.$state.go('medication-formulary.view', {
                                        medicationFormularyEntryId: medicationFormularyEntry.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationFormularyService.viewPermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (medicationFormularyEntry) {
                                    var confirm = _this.$mdDialog
                                        .confirm()
                                        .title('Confirmation Required')
                                        .htmlContent('Are you sure want to remove this medication?')
                                        .ariaLabel('Removal Confirmation')
                                        .ok('Ok')
                                        .cancel('Cancel');
                                    return _this.$mdDialog.show(confirm).then(function () {
                                        return _this.MedicationFormularyService.delete(medicationFormularyEntry.id).then(function () {
                                            _this.logger.success('The Medication has been removed from the formulary.');
                                            _this.search();
                                        });
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationFormularyService.deletePermission;
                                },
                            },
                        ],
                    },
                };
            };
            MedicationFormularyDataTableService.prototype.reset = function () {
                this.filters = new consolo.domain.MedicationFormularyFilter();
                this.search();
            };
            MedicationFormularyDataTableService.prototype.search = function () {
                var _this = this;
                this.params = this.filters.getFilters();
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl && _this.tableControl.loadSelectData && !_this.loadingData) {
                        _this.loadingData = true;
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            MedicationFormularyDataTableService.$inject = [
                '$http',
                '$interval',
                'MedicationFormularyService',
                '$state',
                'logger',
                '$mdDialog',
                'PubSubService',
            ];
            return MedicationFormularyDataTableService;
        }(csg.datatable.ApiService));
        services.MedicationFormularyDataTableService = MedicationFormularyDataTableService;
        angular
            .module('consolo')
            .service('MedicationFormularyDataTableService', MedicationFormularyDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var medications;
    (function (medications_1) {
        'use strict';
        var MedicationFormularyEntryComponent = /** @class */ (function () {
            function MedicationFormularyEntryComponent() {
                this.bindings = { readonly: '<' };
                this.templateUrl =
                    '/y/templates/medications/medication-formulary/medication-formulary-entry.html';
                this.controller = MedicationFormularyEntryController;
            }
            return MedicationFormularyEntryComponent;
        }());
        var MedicationFormularyEntryController = /** @class */ (function (_super) {
            __extends(MedicationFormularyEntryController, _super);
            function MedicationFormularyEntryController($scope, MedicationFormularyService, logger, MedicationService, $stateParams, $state) {
                var _this = _super.call(this, $scope) || this;
                _this.MedicationFormularyService = MedicationFormularyService;
                _this.logger = logger;
                _this.MedicationService = MedicationService;
                _this.$stateParams = $stateParams;
                _this.$state = $state;
                _this.loading = false;
                _this.chips = [];
                if ($stateParams.medicationFormularyEntryId) {
                    _this.loading = true;
                    _this.MedicationFormularyService.get($stateParams.medicationFormularyEntryId)
                        .then(function (medicationFormularyEntry) {
                        _this.medicationFormularyEntry = medicationFormularyEntry;
                        _this.setDisplayName(_this.medicationFormularyEntry);
                        _this.chips = _this.medicationFormularyEntry.ndcs;
                    })
                        .finally(function () {
                        _this.loading = false;
                    });
                }
                return _this;
            }
            MedicationFormularyEntryController.prototype.$onInit = function () { };
            MedicationFormularyEntryController.prototype.addChip = function (chip) {
                var existingNdc = this.medicationFormularyEntry.ndcs.filter(function (ndc) {
                    return ndc.ndc === chip.ndc;
                });
                if (existingNdc.length) {
                    this.logger.error('This NDC is already present.');
                }
                else {
                    this.medicationFormularyEntry.ndcs.push({ ndc: chip.ndc });
                }
            };
            MedicationFormularyEntryController.prototype.removeChip = function (chip) {
                for (var i = 0; i < this.medicationFormularyEntry.ndcs.length; i++) {
                    if (this.medicationFormularyEntry.ndcs[i].ndc === chip.ndc) {
                        this.medicationFormularyEntry.ndcs.splice(i, 1);
                        i = this.medicationFormularyEntry.ndcs.length;
                    }
                }
            };
            /*
              Searches the medication database based on either the name of the medication or the NDC
            */
            MedicationFormularyEntryController.prototype.searchMedications = function (q) {
                var _this = this;
                return this.MedicationService.medicationDetailsLookup(q, true).then(function (medications) {
                    return medications.map(function (med) {
                        _this.setDisplayName(med);
                        return med;
                    });
                });
            };
            /*
              Searches the medication database based on either the name of the medication or the NDC
              It will then narrow down the results that match the name/strength/strength unit/form/dosage form
            */
            MedicationFormularyEntryController.prototype.addMedication = function (q) {
                var _this = this;
                return this.searchMedications(q).then(function (medications) {
                    var formularyEntry = _this.medicationFormularyEntry;
                    var matchingMedications = _this.medicationFormularyEntry
                        ? medications.filter(function (medication) {
                            return (medication.strength === formularyEntry.strength &&
                                medication.strength_unit === formularyEntry.strength_unit &&
                                medication.form === formularyEntry.form &&
                                medication.name === formularyEntry.name &&
                                medication.dosage_form === formularyEntry.dosage_form);
                        })
                        : [];
                    if (matchingMedications.length === 0) {
                        _this.logger.info('No matching medications found.');
                    }
                    else {
                        //Remove any medications that the NDC is already present
                        matchingMedications = matchingMedications.filter(function (medication) {
                            var ndcExists = formularyEntry.ndcs.filter(function (ndc) {
                                return ndc.ndc === medication.ndc;
                            });
                            if (ndcExists.length) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        });
                    }
                    return matchingMedications;
                });
            };
            MedicationFormularyEntryController.prototype.setDosageUnit = function () {
                for (var _i = 0, _a = this.MedicationService.select_options.dosage_units; _i < _a.length; _i++) {
                    var dosage_unit = _a[_i];
                    if (dosage_unit.code === this.medicationFormularyEntry.dosage_form) {
                        this.medicationFormularyEntry.dosage_unit_id = dosage_unit.id;
                        this.medicationFormularyEntry.dosage_unit_display = dosage_unit.description;
                        break;
                    }
                }
            };
            MedicationFormularyEntryController.prototype.setNdcs = function (selectedMedication) {
                if (!this.medicationFormularyEntry) {
                    this.medicationFormularyEntry = selectedMedication;
                    this.medicationFormularyEntry.medispan_name = selectedMedication.name;
                }
                if (!this.medicationFormularyEntry.ndcs) {
                    if (selectedMedication && selectedMedication.ndc) {
                        this.medicationFormularyEntry.ndcs = [{ ndc: selectedMedication.ndc }];
                        this.chips = this.medicationFormularyEntry.ndcs;
                    }
                    else {
                        this.medicationFormularyEntry = null;
                        this.chips = null;
                    }
                }
            };
            MedicationFormularyEntryController.prototype.setDisplayName = function (medication) {
                medication.display_name =
                    medication.name +
                        ' ' +
                        (medication.strength ? medication.strength : '') +
                        ' ' +
                        (medication.strength_unit ? medication.strength_unit : '') +
                        ' ' +
                        medication.route +
                        ' ' +
                        medication.form;
            };
            MedicationFormularyEntryController.prototype.save = function () {
                var _this = this;
                if (!this.medicationFormularyEntry ||
                    !this.medicationFormularyEntry.ndcs ||
                    this.medicationFormularyEntry.ndcs.length === 0) {
                    this.errors = ['You must select a medication with a valid NDC.'];
                    this.logger.error(this.errors.join(', '));
                }
                else {
                    var loggerMessage_1 = this.medicationFormularyEntry.id ? 'updated' : 'created';
                    this.MedicationFormularyService.upsert(this.medicationFormularyEntry).then(function (medicationFormularyEntry) {
                        _this.logger.success('The Medication Formulary was successfully ' + loggerMessage_1);
                        _this.$state.go('medication-formulary');
                    }, function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    });
                }
            };
            MedicationFormularyEntryController.$inject = [
                '$scope',
                'MedicationFormularyService',
                'logger',
                'MedicationService',
                '$stateParams',
                '$state',
            ];
            return MedicationFormularyEntryController;
        }(consolo.controllers.BaseCustomComponentController));
        angular
            .module('consolo')
            .component('medicationFormularyEntry', new MedicationFormularyEntryComponent());
    })(medications = consolo.medications || (consolo.medications = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var MedicationFormularyComponent = /** @class */ (function () {
            function MedicationFormularyComponent() {
                this.bindings = {};
                this.templateUrl = '/y/templates/medications/medication-formulary/index.html';
                this.controller = MedicationFormularyController;
            }
            return MedicationFormularyComponent;
        }());
        var MedicationFormularyController = /** @class */ (function (_super) {
            __extends(MedicationFormularyController, _super);
            function MedicationFormularyController($scope, MedicationFormularyService, MedicationFormularyDataTableService, S3UploadFactory, UploadQueue, $timeout, logger, SidebarService, CSV, $http, $mdDialog) {
                var _this = _super.call(this, $scope) || this;
                _this.MedicationFormularyService = MedicationFormularyService;
                _this.MedicationFormularyDataTableService = MedicationFormularyDataTableService;
                _this.S3UploadFactory = S3UploadFactory;
                _this.UploadQueue = UploadQueue;
                _this.$timeout = $timeout;
                _this.logger = logger;
                _this.SidebarService = SidebarService;
                _this.CSV = CSV;
                _this.$http = $http;
                _this.$mdDialog = $mdDialog;
                _this.saving = false;
                _this.showImport = false;
                _this.showImportHistory = false;
                _this.loadingHistory = false;
                _this.completeStatus = consolo.uploads.UploadState.Complete;
                _this.MedicationFormularyDataTableService.search();
                _this.importHistoryTableLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Import Date',
                            field: 'updated_at',
                            shortTitle: 'Import Date',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Failures',
                            field: 'failure_count',
                        },
                        {
                            title: 'Import File Name',
                            field: 'file_name',
                            type: csg.datatable.ColumnTypes.short_text,
                        },
                        {
                            title: 'Detailed File',
                            field: 'failure_file_link',
                            type: csg.datatable.ColumnTypes.link,
                            formatValueFn: function (history) {
                                return 'Download Failure File';
                            },
                        },
                        {
                            title: 'User',
                            field: 'user.name',
                        },
                    ],
                };
                return _this;
            }
            MedicationFormularyController.prototype.$onInit = function () { };
            MedicationFormularyController.prototype.handleFiles = function (files) {
                var _this = this;
                files.forEach(function (file) {
                    // TODO throw warning if file too large?
                    var upload = _this.S3UploadFactory.build(file);
                    _this.UploadQueue.push(upload);
                    _this.upload = upload;
                });
            };
            MedicationFormularyController.prototype.csvTemplate = function () {
                var csv = 'data:text/csv;charset-utf-8,' +
                    this.CSV.generate([], ['NDC', 'Name', 'Additive Only', 'Approval Required', 'Reason Required', 'Delete']);
                var data = encodeURI(csv);
                var link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', 'FormularyImportTemplate.csv');
                link.click();
            };
            MedicationFormularyController.prototype.deleteAll = function () {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to delete all Formulary Entries?')
                    .ariaLabel('Delete All Formulary Entries')
                    .ok('Delete All')
                    .cancel('Cancel');
                return this.$mdDialog.show(confirm).then(function () {
                    _this.MedicationFormularyService.deleteAll();
                    _this.$timeout(function () {
                        _this.MedicationFormularyDataTableService.search();
                    }, 1000);
                });
            };
            MedicationFormularyController.prototype.importHistory = function () {
                var _this = this;
                this.showImportHistory = !this.showImportHistory;
                if (this.showImportHistory) {
                    this.loadingHistory = true;
                    this.$http
                        .get('/api/v1/medication_formulary_import_histories')
                        .then(function (response) {
                        _this.importHistoryData = response.data;
                    })
                        .finally(function () {
                        _this.loadingHistory = false;
                    });
                }
            };
            MedicationFormularyController.prototype.import = function () {
                var _this = this;
                if (this.saving) {
                    return;
                }
                else {
                    this.saving = true;
                    this.errors = null;
                    this.upload.promise.then(function () {
                        var fileKey = _this.upload.s3Key;
                        _this.MedicationFormularyService.import(fileKey)
                            .then(function (resp) {
                            _this.logger.success('Your import has been queued up to import.  You will receive a notification when complete.');
                            _this.showImport = false;
                        }, function (resp) {
                            _this.logger.error(resp.data.errors.join(', '));
                        })
                            .finally(function () {
                            _this.upload = null;
                            _this.saving = false;
                        });
                    });
                }
            };
            MedicationFormularyController.prototype.cancel = function () {
                this.saving = false;
                this.upload = null;
            };
            MedicationFormularyController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            MedicationFormularyController.$inject = [
                '$scope',
                'MedicationFormularyService',
                'MedicationFormularyDataTableService',
                'S3UploadFactory',
                'UploadQueue',
                '$timeout',
                'logger',
                'SidebarService',
                'CSV',
                '$http',
                '$mdDialog',
            ];
            return MedicationFormularyController;
        }(consolo.controllers.BaseCustomComponentController));
        angular
            .module('consolo')
            .component('medicationFormulary', new MedicationFormularyComponent());
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MedicationFormularyService = /** @class */ (function () {
            function MedicationFormularyService(PermissionsService, $http) {
                this.PermissionsService = PermissionsService;
                this.$http = $http;
                this.updatePermission = false;
                this.deletePermission = false;
                this.viewPermission = false;
                this.createPermission = false;
                this.importPermission = false;
                this.apiUrl = '/api/v1/medication_formulary_entries';
                this.updatePermission = this.PermissionsService.can('update', 'medication_formulary');
                this.createPermission = this.PermissionsService.can('create', 'medication_formulary');
                this.deletePermission = this.PermissionsService.can('destroy', 'medication_formulary');
                this.viewPermission = this.PermissionsService.can('show', 'medication_formulary');
                this.importPermission = this.PermissionsService.can('import', 'medication_formulary');
            }
            MedicationFormularyService.prototype.delete = function (id) {
                return this.$http.delete(this.apiUrl + '/' + id);
            };
            MedicationFormularyService.prototype.deleteAll = function () {
                return this.$http.post(this.apiUrl + '/delete_all', {});
            };
            MedicationFormularyService.prototype.deleteMultiple = function (ids) {
                return this.$http.post(this.apiUrl + '/delete', { ids: ids });
            };
            MedicationFormularyService.prototype.get = function (id) {
                return this.$http.get(this.apiUrl + '/' + id).then(function (response) {
                    return response.data;
                });
            };
            MedicationFormularyService.prototype.import = function (fileKey) {
                return this.$http.post(this.apiUrl + '/import', {
                    s3_tmp_key: fileKey,
                });
            };
            MedicationFormularyService.prototype.upsert = function (medicationFormularyEntry) {
                if (medicationFormularyEntry.id) {
                    return this.$http.patch(this.apiUrl + '/' + medicationFormularyEntry.id, medicationFormularyEntry);
                }
                else {
                    return this.$http.post(this.apiUrl, medicationFormularyEntry);
                }
            };
            MedicationFormularyService.$inject = ['PermissionsService', '$http'];
            return MedicationFormularyService;
        }());
        services.MedicationFormularyService = MedicationFormularyService;
        angular
            .module('consolo')
            .service('MedicationFormularyService', MedicationFormularyService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationHistoryConsentController = /** @class */ (function () {
            function MedicationHistoryConsentController(currentPatient, $http, $mdDialog, MedicationHistoryService) {
                var _this = this;
                this.currentPatient = currentPatient;
                this.$http = $http;
                this.$mdDialog = $mdDialog;
                this.MedicationHistoryService = MedicationHistoryService;
                this.requestingHistory = false;
                this.$http.get('/api/v1/family_members?patient_id=' + currentPatient.id).then(function (response) {
                    _this.familyMembers = response.data;
                });
            }
            MedicationHistoryConsentController.prototype.requestMedicationHistory = function (consentValue) {
                var _this = this;
                this.requestingHistory = true;
                return this.MedicationHistoryService.requestMedicationHistory(this.currentPatient.id, consentValue, this.family_id)
                    .then(function (medicationHistory) {
                    return _this.$mdDialog.hide(medicationHistory);
                }, function (errors) {
                    _this.errors = errors.data;
                })
                    .finally(function () {
                    _this.requestingHistory = false;
                });
            };
            MedicationHistoryConsentController.$inject = ['currentPatient', '$http', '$mdDialog', 'MedicationHistoryService'];
            return MedicationHistoryConsentController;
        }());
        angular
            .module('consolo')
            .controller('MedicationHistoryConsentController', MedicationHistoryConsentController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationHistoryIndexController = /** @class */ (function () {
            function MedicationHistoryIndexController($mdDialog, currentPatient, $state, MedicationHistoryService, $http, logger) {
                var _this = this;
                this.$mdDialog = $mdDialog;
                this.currentPatient = currentPatient;
                this.$state = $state;
                this.MedicationHistoryService = MedicationHistoryService;
                this.$http = $http;
                this.logger = logger;
                this.newRequestAllowed = true;
                this.loadingSpinner = false;
                this.$http
                    .get('/api/v1/medication_histories?patient_id=' + currentPatient.id)
                    .then(function (response) {
                    _this.medicationHistories = response.data;
                    _this.medicationHistories.forEach(function (medicationHistory) {
                        _this.MedicationHistoryService.initializeMedicationHistory(medicationHistory);
                    });
                    if (_this.medicationHistories.length) {
                        _this.newRequestAllowed = _this.medicationHistories[0].allowed_to_request;
                    }
                });
                this.MedicationHistoryService.getMedicationDefaultsForPatient(currentPatient.id);
                this.tableLayout = {
                    id_field: 'id',
                    options: {
                        borderStyle: 3,
                        defaultPageSize: 100,
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: 'Request Date',
                            field: 'created_at',
                            type: csg.datatable.ColumnTypes.date,
                        },
                        { title: 'User Requested', field: 'user.name' },
                        { title: 'Consent', field: 'consent_display' },
                        { title: 'Parent/Guardian', field: 'family.name' },
                        { title: 'Status', field: 'message_status' },
                        {
                            title: 'Unreviewed',
                            field: 'unreviewed_medications',
                            formatValueFn: function (medicationHistory) {
                                if (medicationHistory.unreviewedEntries.length) {
                                    return medicationHistory.unreviewedEntries.length.toString();
                                }
                                else {
                                    return '0';
                                }
                            },
                        },
                        {
                            title: 'Added',
                            field: 'added_medications',
                            formatValueFn: function (medicationHistory) {
                                if (medicationHistory.addedEntries.length) {
                                    return medicationHistory.addedEntries.length.toString();
                                }
                                else {
                                    return '0';
                                }
                            },
                        },
                        {
                            title: 'Reviewed',
                            field: 'reviewed_medications',
                            formatValueFn: function (medicationHistory) {
                                if (medicationHistory.reviewedEntries.length) {
                                    return medicationHistory.reviewedEntries.length.toString();
                                }
                                else {
                                    return '0';
                                }
                            },
                        },
                        {
                            title: '',
                            field: '',
                            type: csg.datatable.ColumnTypes.button,
                            callbackFn: function (medicationHistory) {
                                return _this.$state.go('patients.medication-history.view', {
                                    patientId: _this.currentPatient.id,
                                    medHistoryId: medicationHistory.id,
                                });
                            },
                            formatValueFn: function () {
                                return 'View';
                            },
                        },
                    ],
                };
            }
            MedicationHistoryIndexController.prototype.requestMedicationHistory = function () {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/medications/medication-history/consent-modal.html',
                    controller: 'MedicationHistoryConsentController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { currentPatient: this.currentPatient },
                })
                    .then(function (medicationHistory) {
                    _this.medicationHistories.unshift(medicationHistory);
                    return _this.$state.go('patients.medication-history.view', {
                        medHistoryId: medicationHistory.id,
                        patientId: _this.currentPatient.id,
                    });
                });
            };
            MedicationHistoryIndexController.prototype.requestStaticMedicationHistory = function () {
                var _this = this;
                this.loadingSpinner = true;
                return this.MedicationHistoryService.requestStaticMedicationHistory(this.currentPatient.id)
                    .then(function (medicationHistory) {
                    _this.medicationHistories.unshift(_this.MedicationHistoryService.initializeMedicationHistory(medicationHistory));
                    _this.medicationHistories = [].concat(_this.medicationHistories);
                    _this.logger.success('Medication History was returned.');
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                })
                    .finally(function () {
                    _this.loadingSpinner = false;
                });
            };
            MedicationHistoryIndexController.$inject = [
                '$mdDialog',
                'currentPatient',
                '$state',
                'MedicationHistoryService',
                '$http',
                'logger',
            ];
            return MedicationHistoryIndexController;
        }());
        angular
            .module('consolo')
            .controller('MedicationHistoryIndexController', MedicationHistoryIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var Medication = consolo.domain.Medication;
        // Store and query connectivity status
        var MedicationHistoryService = /** @class */ (function () {
            function MedicationHistoryService($http, $q, Session, $filter) {
                this.$http = $http;
                this.$q = $q;
                this.Session = Session;
                this.$filter = $filter;
                this.medicationHistoryEnabled = false;
                this.staticMedicationHistoryEnabled = false;
                this.apiUrl = '/api/v1/medication_histories';
                this.entryApiUrl = '/api/v1/medication_history_entries/';
                this.medicationHistoryEnabled = Session.get().agency.medication_history_enabled;
                this.staticMedicationHistoryEnabled = Session.get().agency.static_medication_history;
            }
            //Get the values that a new medication should be populated, this pulls from things like assignments
            MedicationHistoryService.prototype.getMedicationDefaultsForPatient = function (patientId) {
                var _this = this;
                var medicationPresent = this.$q.defer();
                if (!this.defaultMedicationValues || this.defaultMedicationValues.patient_id !== patientId) {
                    this.$http
                        .get('/api/v1/medications/default_new_medication_values?patient_id=' + patientId)
                        .then(function (response) {
                        _this.defaultMedicationValues = new Medication(response.data);
                        medicationPresent.resolve();
                    });
                }
                else {
                    medicationPresent.resolve();
                }
                return medicationPresent.promise.then(function () {
                    return _this.defaultMedicationValues;
                });
            };
            //Get all requested medication histories for the given patient
            MedicationHistoryService.prototype.getStaticPatientMedicationHistory = function (patientId) {
                return this.$http.get(this.apiUrl, { params: { patient_id: patientId } }).then(function (response) {
                    return response.data;
                });
            };
            //Used to keep the view in sync, will update the medication history with the most current information on the entry
            MedicationHistoryService.prototype.replaceEntry = function (medicationHistory, entry) {
                for (var i = 0; i < medicationHistory.medication_history_entries.length; i++) {
                    if (entry.id === medicationHistory.medication_history_entries[i].id) {
                        medicationHistory.medication_history_entries[i] = entry;
                        i = medicationHistory.medication_history_entries.length;
                    }
                    this.initializeMedicationHistory(medicationHistory);
                }
            };
            //Sends a request from core -> surescripts to get the patients medication history for the last year
            MedicationHistoryService.prototype.requestMedicationHistory = function (patientId, consentValue, familyId) {
                return this.$http
                    .post(this.apiUrl + '/request_medication_history', {
                    patient_id: patientId,
                    consent: consentValue,
                    family_id: familyId,
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            //Sends a request from core -> surescripts to get the patients medication history for the last year
            MedicationHistoryService.prototype.requestStaticMedicationHistory = function (patientId) {
                return this.$http
                    .post(this.apiUrl + '/request_static_medication_history', {
                    patient_id: patientId,
                })
                    .then(function (response) {
                    return response.data;
                });
            };
            //Used to find the matching medications retrieved from Surescripts against Medispan
            MedicationHistoryService.prototype.getMedicationByNDC = function (ndc) {
                return this.$http
                    .get('/api/v1/medispan/med_by_ndc', { params: { ndc: ndc } })
                    .then(function (response) {
                    return response.data.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                });
            };
            //Used to find the matching medications retrieved from Surescripts against Medispan
            MedicationHistoryService.prototype.getMedicationsByNDC = function (ndcs) {
                return this.$http
                    .get('/api/v1/medispan/meds_by_ndc', { params: { 'ndcs[]': ndcs } })
                    .then(function (response) {
                    return response.data.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                });
            };
            //Can be used either from Unreviewed or Reviewed Medication History Entries on Medication History View screen
            MedicationHistoryService.prototype.addEntry = function (medicationHistory, medicationHistoryEntry, medication) {
                var _this = this;
                //Sets the status correctly and the medication
                medicationHistoryEntry.status = 'added';
                medicationHistoryEntry.medication = medication;
                medicationHistoryEntry.medication_id = medication.id;
                //Updates the database with information
                return this.updateMedicationHistoryEntry(medicationHistoryEntry).then(function (updatedEntry) {
                    //So that the view will update correctly
                    _this.replaceEntry(medicationHistory, medicationHistoryEntry);
                });
            };
            //Updates a Medication History Entry
            MedicationHistoryService.prototype.updateMedicationHistoryEntry = function (medicationHistoryEntry) {
                return this.$http
                    .patch(this.entryApiUrl + medicationHistoryEntry.id, medicationHistoryEntry)
                    .then(function (response) {
                    return response.data;
                });
            };
            //Returns all medication_history_entries that match the status
            //Current status options: reviewed, unreviewed, added
            MedicationHistoryService.prototype.filterEntries = function (medicationType, medicationHistory) {
                return this.$filter('filter')(medicationHistory.medication_history_entries, function (medicationHistoryEntry) {
                    return medicationHistoryEntry.status === medicationType;
                });
            };
            //Sets the medicationHistory entries for the 3 possible statuses for medication history view page
            MedicationHistoryService.prototype.initializeMedicationHistory = function (medicationHistory) {
                medicationHistory.unreviewedEntries = this.filterEntries('unreviewed', medicationHistory);
                medicationHistory.reviewedEntries = this.filterEntries('reviewed', medicationHistory);
                medicationHistory.addedEntries = this.filterEntries('added', medicationHistory);
                return medicationHistory;
            };
            //Updates a medication history entry, this may not be needed.  Once it's created the only thing that will change
            // are the underlying entries
            MedicationHistoryService.prototype.updateMedicationHistory = function (medicationHistory) {
                return this.$http
                    .patch('/api/v1/medication_histories/' + medicationHistory.id, medicationHistory)
                    .then(function (data) {
                    return data.data;
                });
            };
            MedicationHistoryService.$inject = ['$http', '$q', 'SessionService', '$filter'];
            return MedicationHistoryService;
        }());
        services.MedicationHistoryService = MedicationHistoryService;
        angular
            .module('consolo')
            .service('MedicationHistoryService', MedicationHistoryService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationQuickEntryController = /** @class */ (function () {
            function MedicationQuickEntryController(medicationHistory, MedicationService, CurrentPatient, $mdDialog, MedicationHistoryService, medicationHistoryEntry, logger) {
                var _this = this;
                this.medicationHistory = medicationHistory;
                this.MedicationService = MedicationService;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.MedicationHistoryService = MedicationHistoryService;
                this.medicationHistoryEntry = medicationHistoryEntry;
                this.logger = logger;
                this.saving = false;
                this.showMedicationSelection = false;
                this.searchingMedications = false;
                this.medication = new consolo.domain.Medication({});
                this.MedicationHistoryService.getMedicationDefaultsForPatient(CurrentPatient.patient_id).then(function (medication) {
                    angular.copy(medication, _this.medication);
                });
                this.searchingMedications = true;
                if (medicationHistoryEntry.ndcs.length > 1) {
                    this.MedicationHistoryService.getMedicationsByNDC(medicationHistoryEntry.ndcs)
                        .then(function (medications) {
                        if (medications.length) {
                            _this.setMedications(medications);
                        }
                        else {
                            _this.searchByName();
                        }
                    }, function (errors) {
                        _this.logger.error(errors.data.errors);
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.searchingMedications = false;
                    });
                }
                else if (medicationHistoryEntry.ndcs.length === 1) {
                    this.MedicationHistoryService.getMedicationByNDC(medicationHistoryEntry.ndcs[0])
                        .then(function (medications) {
                        if (medications.length) {
                            _this.setMedications(medications);
                        }
                        else {
                            _this.searchByName();
                        }
                    }, function (errors) {
                        _this.logger.error(errors.data.errors);
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.searchingMedications = false;
                    });
                }
                else {
                    this.searchByName();
                }
                this.medicationLayout = {
                    options: {
                        hideTextFilter: true,
                        showExpandCollapseAll: false,
                        hideFooter: true,
                    },
                    columns: [
                        {
                            title: '',
                            field: 'select',
                            type: 'button',
                            callbackFn: function (medication) {
                                _this.showMedicationSelection = false;
                                _this.MedicationService.setMedicationValues(_this.medication, medication);
                            },
                            formatValueFn: function (medication) {
                                return 'Select';
                            },
                        },
                        { title: 'Name', field: 'name' },
                        { title: 'Route', field: 'route' },
                        { title: 'Form', field: 'form' },
                        {
                            title: 'Strength',
                            field: 'strength',
                            formatValueFn: function (medication) {
                                return ((medication.strength ? medication.strength + ' ' : null) +
                                    (medication.strength_unit ? medication.strength_unit : null));
                            },
                        },
                    ],
                };
            }
            MedicationQuickEntryController.prototype.searchByName = function () {
                var _this = this;
                var drugName = this.medicationHistoryEntry.drug_description.split(' ')[0].split('/')[0];
                this.MedicationService.medicationDetailsLookup(drugName, true)
                    .then(function (medications) {
                    _this.setMedications(medications);
                })
                    .finally(function () {
                    _this.searchingMedications = false;
                });
            };
            MedicationQuickEntryController.prototype.setPhysician = function (physician) {
                if (physician) {
                    this.medication.physician_id = physician.id;
                }
                else {
                    this.medication.physician_id = null;
                }
            };
            MedicationQuickEntryController.prototype.saveMedication = function () {
                var _this = this;
                if (this.medication.isValid()) {
                    this.saving = true;
                    return this.MedicationService.create(this.medication)
                        .then(function (medication) {
                        //Add Entry will set it to added, reset the history with the entry and initalize
                        return _this.MedicationHistoryService.addEntry(_this.medicationHistory, _this.medicationHistoryEntry, medication).then(function () {
                            return _this.$mdDialog.hide(_this.medicationHistoryEntry);
                        });
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
                else {
                    this.errors = this.medication.errors;
                    this.logger.error(this.errors.join(', '));
                }
            };
            MedicationQuickEntryController.prototype.updateFrequency = function () {
                var _this = this;
                this.MedicationService.getFrequency(this.medication).then(function (frequency) {
                    _this.medication.frequency = frequency;
                });
            };
            MedicationQuickEntryController.prototype.setMedications = function (medications) {
                this.medications = medications;
                if (this.medications && this.medications.length === 1) {
                    this.MedicationService.setMedicationValues(this.medication, this.medications[0]);
                    this.updateFrequency();
                }
                if (this.medications.length > 1) {
                    this.showMedicationSelection = true;
                }
                this.setFromStrength();
            };
            MedicationQuickEntryController.prototype.setFromStrength = function () {
                var _this = this;
                if (this.medicationHistoryEntry.strength) {
                    var fields = this.medicationHistoryEntry.strength.split(' ');
                    if (fields[1]) {
                        this.MedicationService.select_options['dosage_units'].some(function (dosage_unit) {
                            if (dosage_unit.code === fields[1].toLowerCase()) {
                                _this.medication.dosage_unit_id = dosage_unit.id;
                                _this.medication.dosage_unit_display = dosage_unit.description;
                                return true;
                            }
                        });
                    }
                    this.updateFrequency();
                }
            };
            MedicationQuickEntryController.$inject = [
                'medicationHistory',
                'MedicationService',
                'CurrentPatient',
                '$mdDialog',
                'MedicationHistoryService',
                'medicationHistoryEntry',
                'logger',
            ];
            return MedicationQuickEntryController;
        }());
        angular
            .module('consolo')
            .controller('MedicationQuickEntryController', MedicationQuickEntryController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationHistoryController = /** @class */ (function () {
            function MedicationHistoryController(medicationHistory, MedicationService, currentPatient, $mdDialog, $filter, MedicationHistoryService, logger, $q) {
                var _this = this;
                this.medicationHistory = medicationHistory;
                this.MedicationService = MedicationService;
                this.currentPatient = currentPatient;
                this.$mdDialog = $mdDialog;
                this.$filter = $filter;
                this.MedicationHistoryService = MedicationHistoryService;
                this.logger = logger;
                this.$q = $q;
                this.updating = false;
                this.filteredMedications = this.medicationHistory.unreviewedEntries;
                this.medicationHistoryLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        defaultPageSize: 100,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Drug Description', field: 'drug_description' },
                        { title: 'Quantity', field: 'quantity' },
                        { title: 'Directions for Use', field: 'directions' },
                        { title: 'Last Fill', field: 'last_fill_date' },
                        { title: 'First Fill', field: 'first_fill_date' },
                        {
                            title: '',
                            field: 'add_translated_medication',
                            type: 'button',
                            callbackFn: function (medicationHistoryEntry) {
                                if (!_this.updating) {
                                    return _this.addMedicationHistoryToMedRecord(medicationHistoryEntry);
                                }
                            },
                            formatValueFn: function (medicationHistoryEntry) {
                                return 'Add';
                            },
                        },
                        {
                            title: '',
                            field: 'review_translated_medication',
                            type: 'button',
                            callbackFn: function (medicationHistoryEntry) {
                                if (!_this.updating) {
                                    _this.updating = true;
                                    return _this.reviewEntry(medicationHistoryEntry)
                                        .then(function () {
                                        return true;
                                    })
                                        .finally(function () {
                                        _this.updating = false;
                                    });
                                }
                            },
                            formatValueFn: function () {
                                return 'Reviewed';
                            },
                        },
                    ],
                };
                this.addedMedicationsLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        defaultPageSize: 100,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Drug Description', field: 'drug_description' },
                        {
                            title: 'Medication Added',
                            field: 'medication.name',
                            formatValueFn: function (medicationHistoryEntry) {
                                return (medicationHistoryEntry.medication.name +
                                    ' ' +
                                    medicationHistoryEntry.medication.frequency);
                            },
                        },
                        {
                            title: '',
                            field: 'edit_medication',
                            type: 'button',
                            callbackFn: function (medicationHistoryEntry) {
                                return _this.MedicationService.get(medicationHistoryEntry.medication_id).then(function (medication) {
                                    medicationHistoryEntry.medication = medication;
                                    return _this.MedicationService.editMedicationModal(medicationHistoryEntry.medication).then(function () {
                                        _this.MedicationHistoryService.replaceEntry(_this.medicationHistory, medicationHistoryEntry);
                                        return true;
                                    });
                                });
                            },
                            formatValueFn: function () {
                                return 'Edit';
                            },
                            conditionalHideFn: function (medicationHistoryEntry) {
                                if (medicationHistoryEntry.medication) {
                                    medicationHistoryEntry.medication = new consolo.domain.Medication(medicationHistoryEntry.medication);
                                }
                                return (!_this.MedicationService.updatePermission ||
                                    (medicationHistoryEntry.medication && medicationHistoryEntry.medication.isCurrent()));
                            },
                        },
                        {
                            title: '',
                            field: 'view_medication',
                            type: 'button',
                            callbackFn: function (medicationHistoryEntry) {
                                var medicationPresent = _this.$q.defer();
                                if (medicationHistoryEntry.medication &&
                                    medicationHistoryEntry.medication.physician) {
                                    medicationPresent.resolve();
                                }
                                else {
                                    _this.MedicationService.get(medicationHistoryEntry.medication_id).then(function (medication) {
                                        medicationHistoryEntry.medication = medication;
                                        medicationPresent.resolve();
                                    });
                                }
                                return medicationPresent.promise.then(function () {
                                    return _this.$mdDialog.show({
                                        clickOutsideToClose: true,
                                        preserveScope: true,
                                        templateUrl: '/y/templates/patients/sections/medications/view.html',
                                        controller: 'ViewMedicationController as vm',
                                        locals: {
                                            medication: medicationHistoryEntry.medication,
                                            currentPatient: currentPatient,
                                        },
                                        multiple: true,
                                        skipHide: true,
                                    });
                                });
                            },
                            formatValueFn: function () {
                                return 'View';
                            },
                            conditionalHideFn: function () {
                                return !_this.MedicationService.viewPermission;
                            },
                        },
                    ],
                };
                this.reviewedMedicationsLayout = {
                    id_field: 'id',
                    options: {
                        showExpandCollapseAll: false,
                        defaultPageSize: 100,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Drug Description', field: 'drug_description' },
                        { title: 'Quantity', field: 'quantity' },
                        { title: 'Directions for Use', field: 'directions' },
                        { title: 'Last Fill', field: 'last_fill_date' },
                        { title: 'First Fill', field: 'first_fill_date' },
                        {
                            title: '',
                            field: 'add_reviewed',
                            type: 'button',
                            callbackFn: function (medicationHistoryEntry) {
                                if (!_this.updating) {
                                    return _this.addMedicationHistoryToMedRecord(medicationHistoryEntry);
                                }
                            },
                            formatValueFn: function () {
                                return 'Add';
                            },
                        },
                    ],
                };
                var now = new Date();
                var threeMonths = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
                var sixMonths = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
                var nineMonths = new Date(now.getFullYear(), now.getMonth() - 9, now.getDate());
                var twelveMonths = new Date(now.getFullYear(), now.getMonth() - 12, now.getDate());
                this.dateOptions = [
                    { id: threeMonths, description: '3 Months' },
                    { id: sixMonths, description: '6 Months' },
                    { id: nineMonths, description: '9 Months' },
                    { id: twelveMonths, description: '12 Months' },
                ];
                this.selectedRange = twelveMonths;
            }
            MedicationHistoryController.prototype.addMedicationHistoryToMedRecord = function (medicationHistoryEntry) {
                var _this = this;
                this.updating = true;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/medications/medication-history/medication-quick-entry.html',
                    controller: 'MedicationQuickEntryController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                    locals: {
                        medicationHistoryEntry: medicationHistoryEntry,
                        medicationHistory: this.medicationHistory,
                    },
                })
                    .then(function () {
                    _this.applyFilter();
                    return true;
                }, function (errors) {
                    return false;
                })
                    .finally(function () {
                    _this.updating = false;
                });
            };
            MedicationHistoryController.prototype.reviewEntry = function (medicationHistoryEntry) {
                var _this = this;
                medicationHistoryEntry.status = 'reviewed';
                return this.MedicationHistoryService.updateMedicationHistoryEntry(medicationHistoryEntry).then(function (updatedEntry) {
                    angular.copy(updatedEntry, medicationHistoryEntry);
                    _this.logger.info('The medication has been reviewed.');
                    _this.MedicationHistoryService.replaceEntry(_this.medicationHistory, medicationHistoryEntry);
                    _this.applyFilter();
                });
            };
            MedicationHistoryController.prototype.applyFilter = function () {
                var _this = this;
                if (this.selectedRange && this.medicationHistory.unreviewedEntries.length) {
                    this.filteredMedications = this.$filter('filter')(this.medicationHistory.unreviewedEntries, function (medicationHistoryEntry) {
                        return new Date(medicationHistoryEntry.last_fill_date) > _this.selectedRange;
                    });
                }
                else {
                    this.filteredMedications = this.medicationHistory.unreviewedEntries;
                }
            };
            MedicationHistoryController.$inject = [
                'medicationHistory',
                'MedicationService',
                'currentPatient',
                '$mdDialog',
                '$filter',
                'MedicationHistoryService',
                'logger',
                '$q',
            ];
            return MedicationHistoryController;
        }());
        angular.module('consolo').controller('MedicationHistoryController', MedicationHistoryController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AddMedicationKitItemController = /** @class */ (function () {
            function AddMedicationKitItemController(MedicationService, medicationKit, MedicationKitsService, $mdDialog, $timeout, logger, SessionService) {
                this.MedicationService = MedicationService;
                this.medicationKit = medicationKit;
                this.MedicationKitsService = MedicationKitsService;
                this.$mdDialog = $mdDialog;
                this.$timeout = $timeout;
                this.logger = logger;
                this.SessionService = SessionService;
                this.prnReasonsRequired = false;
                this.filterFormularyOnly = false;
                this.formularyCheckboxDisabled = false;
                this.medicationKitItem = { medication_kit_id: medicationKit.id };
                this.prnReasonsRequired = this.SessionService.get().agency.reasons_required_for_prn_medication;
                if (MedicationService.medicationFormularyEnabled &&
                    MedicationService.medicationFormularyEnforced) {
                    this.filterFormularyOnly = true;
                    this.filterMediSpanOnly = false;
                    this.formularyCheckboxDisabled = true;
                }
                else {
                    this.filterMediSpanOnly = true;
                }
            }
            AddMedicationKitItemController.prototype.addMedicationKitItem = function () {
                var _this = this;
                if (!this.medicationKitItem.medication_kit_id) {
                    this.medicationKitItem.medication_kit_id = this.medicationKit.id;
                }
                return this.MedicationKitsService.addMedicationKitItem(this.medicationKitItem).then(function (medicationKitItem) {
                    return _this.$mdDialog.hide(medicationKitItem);
                }, function (errors) {
                    _this.logger.error(errors.data.errors.join(', '));
                    _this.errors = errors.data.errors;
                });
            };
            AddMedicationKitItemController.prototype.filterSync = function (medispanFlag) {
                if (medispanFlag) {
                    if (this.filterFormularyOnly && this.filterMediSpanOnly) {
                        this.filterFormularyOnly = false;
                    }
                }
                else if (this.filterFormularyOnly) {
                    this.filterMediSpanOnly = false;
                }
            };
            AddMedicationKitItemController.prototype.setMedicationValues = function () {
                this.MedicationService.setMedicationValues(this.medicationKitItem, this.selectedMedication);
            };
            AddMedicationKitItemController.$inject = [
                'MedicationService',
                'medicationKit',
                'MedicationKitsService',
                '$mdDialog',
                '$timeout',
                'logger',
                'SessionService',
            ];
            return AddMedicationKitItemController;
        }());
        angular
            .module('consolo')
            .controller('AddMedicationKitItemController', AddMedicationKitItemController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CloneMedicationKitController = /** @class */ (function () {
            function CloneMedicationKitController(medicationKit, MedicationKitsService, $state) {
                this.medicationKit = medicationKit;
                this.MedicationKitsService = MedicationKitsService;
                this.$state = $state;
                //Clean out information for cloning
                medicationKit.id = null;
                medicationKit.name = null;
                medicationKit.office_id = null;
                medicationKit.medication_kit_item_ids = [];
                medicationKit.medication_kit_items.forEach(function (medicationKitItem) {
                    medicationKitItem.id = null;
                });
            }
            CloneMedicationKitController.prototype.createClone = function () {
                var _this = this;
                this.MedicationKitsService.create(this.medicationKit).then(function (newMedicationKit) {
                    _this.$state.go('medication-kits/view', { id: newMedicationKit.id });
                });
            };
            CloneMedicationKitController.$inject = ['medicationKit', 'MedicationKitsService', '$state'];
            return CloneMedicationKitController;
        }());
        angular
            .module('consolo')
            .controller('CloneMedicationKitController', CloneMedicationKitController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('medicationKitItem', {
            bindings: { medicationKitItemId: '<' },
            templateUrl: '/y/templates/medications/medication-kits/medication-kit-item.html',
            controller: 'MedicationKitItemController as $ctrl',
        });
        var MedicationKitItemController = /** @class */ (function () {
            function MedicationKitItemController(MedicationService, MedicationFavoritesService, $mdDialog, logger, SessionService, $http) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.SessionService = SessionService;
                this.$http = $http;
                this.prnReasonsRequired = false;
                this.filterFormularyOnly = false;
                this.formularyCheckboxDisabled = false;
                this.loading = false;
                this.prnReasonsRequired = this.SessionService.get().agency.reasons_required_for_prn_medication;
                if (MedicationService.medicationFormularyEnabled &&
                    MedicationService.medicationFormularyEnforced) {
                    this.filterFormularyOnly = true;
                    this.filterMediSpanOnly = false;
                    this.formularyCheckboxDisabled = true;
                }
                else {
                    this.filterMediSpanOnly = true;
                }
                this.loading = true;
                this.MedicationService.isReady().then(function () {
                    _this.$http
                        .get('/api/v1/medication_kit_items/' + _this.medicationKitItemId)
                        .then(function (response) {
                        _this.medicationKitItem = response.data;
                        _this.selectedMedication = _this.medicationKitItem;
                        _this.MedicationService.setDosageRoute(_this.medicationKitItem, _this.medicationKitItem.route);
                        _this.MedicationService.setDosageUnitId(_this.medicationKitItem);
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                    })
                        .finally(function () {
                        _this.loading = false;
                    });
                });
            }
            MedicationKitItemController.prototype.setMedicationValues = function (selectedMedication) {
                if (!this.loading && selectedMedication && selectedMedication !== this.medicationKitItem) {
                    var medKitId = this.medicationKitItem.medication_kit_id;
                    this.MedicationService.setMedicationValues(this.medicationKitItem, selectedMedication);
                    this.medicationKitItem.medication_kit_id = medKitId;
                    this.medicationKitItem.id = this.medicationKitItemId;
                }
            };
            MedicationKitItemController.prototype.save = function () {
                var _this = this;
                this.loading = true;
                return this.$http
                    .patch('/api/v1/medication_kit_items/' + this.medicationKitItem.id, this.medicationKitItem)
                    .then(function (response) {
                    _this.logger.success('The Medication was updated.');
                    return _this.$mdDialog.hide(response.data);
                })
                    .catch(function (errors) {
                    _this.logger.error(errors.data.errors.join(', '));
                    _this.errors = errors.data.errors;
                })
                    .finally(function () {
                    _this.loading = false;
                });
            };
            MedicationKitItemController.$inject = [
                'MedicationService',
                'MedicationFavoritesService',
                '$mdDialog',
                'logger',
                'SessionService',
                '$http',
            ];
            return MedicationKitItemController;
        }());
        common.MedicationKitItemController = MedicationKitItemController;
        angular.module('consolo').controller('MedicationKitItemController', MedicationKitItemController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationKitController = /** @class */ (function () {
            function MedicationKitController(buttonTitle, medicationKit, MedicationKitsService, logger, $state, $mdDialog) {
                this.buttonTitle = buttonTitle;
                this.medicationKit = medicationKit;
                this.MedicationKitsService = MedicationKitsService;
                this.logger = logger;
                this.$state = $state;
                this.$mdDialog = $mdDialog;
            }
            MedicationKitController.prototype.upsert = function () {
                var _this = this;
                var promise = this.medicationKit.id
                    ? this.MedicationKitsService.update(this.medicationKit)
                    : this.MedicationKitsService.create(this.medicationKit);
                promise.then(function (medicationKit) {
                    _this.MedicationKitsService.currentMedicationKit = medicationKit;
                    _this.$state.go('medication-kits/view', { id: medicationKit.id });
                }, function (error) {
                    _this.logger.error(error);
                });
            };
            MedicationKitController.prototype.addMedicationKitItem = function () {
                var _this = this;
                this.MedicationKitsService.addMedicationKitItemModal(this.medicationKit).then(function (newMedicationKitItem) {
                    _this.medicationKit.medication_kit_items.push(newMedicationKitItem);
                });
            };
            MedicationKitController.prototype.editMedicationKitItem = function (medicationKitItem) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    clickOutsideToClose: true,
                    bindToController: true,
                    template: '<md-dialog flex="80" aria-label="Edit Medication Kit Item">' +
                        '  <md-dialog-content>' +
                        '   <medication-kit-item medication-kit-item-id="' +
                        medicationKitItem.id +
                        '"></medication-kit-item>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                })
                    .then(function (updatedMedicationKit) {
                    angular.copy(updatedMedicationKit, medicationKitItem);
                })
                    .catch(function (errors) {
                    if (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                    }
                });
            };
            MedicationKitController.prototype.deleteMedicationKitItem = function (medicationKitItem) {
                var _this = this;
                this.MedicationKitsService.deleteMedicationKitItem(this.medicationKit, medicationKitItem).then(function (updatedMedicationKit) {
                    _this.medicationKit = updatedMedicationKit;
                });
            };
            MedicationKitController.$inject = [
                'buttonTitle',
                'medicationKit',
                'MedicationKitsService',
                'logger',
                '$state',
                '$mdDialog',
            ];
            return MedicationKitController;
        }());
        angular.module('consolo').controller('MedicationKitController', MedicationKitController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var MedicationKitsDataTableService = /** @class */ (function (_super) {
            __extends(MedicationKitsDataTableService, _super);
            function MedicationKitsDataTableService($http, $interval, MedicationKitsService, $state) {
                var _this = _super.call(this, $http) || this;
                _this.$interval = $interval;
                _this.MedicationKitsService = MedicationKitsService;
                _this.$state = $state;
                _this.loadingData = false;
                _this.showFilters = false;
                _this.activate();
                return _this;
            }
            MedicationKitsDataTableService.prototype.activate = function () {
                var _this = this;
                this.apiSelectUrl = '/api/v1/medication_kits';
                this.filters = new consolo.domain.MedicationKitFilter();
                this.tableLayout = {
                    id_field: 'id',
                    headerMenu: {
                        csvContents: true,
                        items: [],
                    },
                    columns: [
                        { title: 'Name', field: 'name', type: 'text', canSort: true },
                        { title: 'Office', field: 'office.name', type: 'text', canSort: true },
                        {
                            title: 'Meds',
                            field: 'meds_count',
                            type: 'text',
                            canSort: true,
                            formatValueFn: function (medicationKit) {
                                return medicationKit.medication_kit_item_ids.length.toString();
                            },
                        },
                        {
                            title: 'Included Medications',
                            field: 'included_medications',
                            formatValueFn: function (medicationKit) {
                                return medicationKit.medication_kit_items
                                    .map(function (item) {
                                    return item.display;
                                })
                                    .join(', ');
                            },
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'Add Medication',
                                icon: 'note_add',
                                callbackFn: function (medicationKit) {
                                    return _this.MedicationKitsService.addMedicationKitItemModal(medicationKit).then(function (newMedicationKitItem) {
                                        medicationKit.medication_kit_items.push(newMedicationKitItem);
                                        return true;
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationKitsService.updatePermission;
                                },
                            },
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (medicationKit) {
                                    _this.MedicationKitsService.currentMedicationKit = medicationKit;
                                    _this.$state.go('medication-kits/view', { id: medicationKit.id });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationKitsService.viewPermission;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (medicationKit) {
                                    _this.MedicationKitsService.currentMedicationKit = medicationKit;
                                    _this.$state.go('medication-kits/edit', { id: medicationKit.id });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationKitsService.updatePermission;
                                },
                            },
                            {
                                label: 'Delete Medication Kit',
                                icon: 'delete',
                                callbackFn: function (medicationKit) {
                                    return _this.MedicationKitsService.deleteModal(medicationKit).then(function (deletedMedicationKit) {
                                        _this.search();
                                        return true;
                                    }, function (error) {
                                        return false;
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationKitsService.deletePermission;
                                },
                            },
                            {
                                label: 'Discontinue',
                                icon: 'error',
                                callbackFn: function (medicationKit) {
                                    return _this.MedicationKitsService.discontinueModal(medicationKit).then(function (discontinuedMedication) {
                                        _this.search();
                                        return true;
                                    }, function (error) {
                                        return false;
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationKitsService.discontinuePermission;
                                },
                            },
                            {
                                label: 'Clone',
                                icon: 'replay',
                                callbackFn: function (medicationKit) {
                                    _this.$state.go('medication-kits/clone', { id: medicationKit.id });
                                },
                                conditionalHideFn: function () {
                                    return !_this.MedicationKitsService.clonePermission;
                                },
                            },
                        ],
                    },
                };
            };
            MedicationKitsDataTableService.prototype.search = function () {
                var _this = this;
                this.loadingData = true;
                this.params = this.filters;
                this.apiInterval = this.$interval(function () {
                    if (_this.tableControl) {
                        _this.$interval.cancel(_this.apiInterval);
                        _this.tableControl.loadSelectData().finally(function () {
                            _this.loadingData = false;
                        });
                    }
                }, 500, 20);
            };
            MedicationKitsDataTableService.prototype.toggleFilters = function () {
                this.showFilters = !this.showFilters;
            };
            MedicationKitsDataTableService.$inject = ['$http', '$interval', 'MedicationKitsService', '$state'];
            return MedicationKitsDataTableService;
        }(csg.datatable.ApiService));
        services.MedicationKitsDataTableService = MedicationKitsDataTableService;
        angular
            .module('consolo')
            .service('MedicationKitsDataTableService', MedicationKitsDataTableService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MedicationKitIndexController = /** @class */ (function () {
            function MedicationKitIndexController(MedicationKitsService, $state, MedicationKitsDataTableService) {
                this.MedicationKitsService = MedicationKitsService;
                this.$state = $state;
                this.MedicationKitsDataTableService = MedicationKitsDataTableService;
            }
            MedicationKitIndexController.$inject = [
                'MedicationKitsService',
                '$state',
                'MedicationKitsDataTableService',
            ];
            return MedicationKitIndexController;
        }());
        angular
            .module('consolo')
            .controller('MedicationKitIndexController', MedicationKitIndexController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var MedicationKitsService = /** @class */ (function () {
            function MedicationKitsService(logger, $http, PermissionsService, $mdDialog, Session) {
                var _this = this;
                this.logger = logger;
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.url = '/api/v1/medication_kits';
                this.viewPermission = false;
                this.updatePermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.discontinuePermission = false;
                this.clonePermission = false;
                this.updatePermission = this.PermissionsService.can('update', 'medication_kits');
                this.createPermission = this.PermissionsService.can('create', 'medication_kits');
                this.deletePermission = this.PermissionsService.can('destroy', 'medication_kits');
                this.viewPermission = this.PermissionsService.can('show', 'medication_kits');
                this.discontinuePermission = this.PermissionsService.can('discontinue', 'medication_kits');
                this.clonePermission = this.PermissionsService.can('clone', 'medication_kits');
                $http.get('/api/v1/offices').then(function (data) {
                    _this.offices = data.data;
                });
                var user = this.Session.getUser();
                this.combined_medication_kits = this.Session.get().agency.combined_medication_kits;
                if (user) {
                    this.user_dashboard_url = user.dashboard_url;
                }
            }
            MedicationKitsService.prototype.addMedicationKitItemModal = function (medicationKit) {
                var url = "'/y/templates/medications/medication-kits/add-medication-kit-item.html'";
                return this.$mdDialog.show({
                    clickOutsideToClose: true,
                    bindToController: true,
                    template: '<md-dialog flex="80" aria-label="Create New Medication">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'AddMedicationKitItemController as vm',
                    locals: { medicationKit: medicationKit },
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                });
            };
            MedicationKitsService.prototype.addMedicationKitItem = function (medicationKitItem) {
                return this.$http.post('/api/v1/medication_kit_items', medicationKitItem).then(function (response) {
                    return response.data;
                });
            };
            MedicationKitsService.prototype.addMedicationKitToPatient = function (patientId, medicationKitId, physician_id, pharmacy_id, surescripts_pharmacy_id, interactionsRecord) {
                var _this = this;
                return this.$http
                    .post(this.url + '/' + medicationKitId + '/add_to_patient', {
                    patient_id: patientId,
                    physician_id: physician_id,
                    pharmacy_id: pharmacy_id,
                    surescripts_pharmacy_id: surescripts_pharmacy_id,
                    interaction_records: interactionsRecord
                })
                    .then(function (response) {
                    _this.logger.success('Added ' + response.data.medications.length + ' medications.');
                    return response.data;
                }, function (errors) {
                    return errors;
                });
            };
            MedicationKitsService.prototype.getMedicationKits = function (patient_id) {
                return this.$http
                    .get(this.url, { params: { patient_id: patient_id } })
                    .then(function (response) {
                    return response.data;
                });
            };
            MedicationKitsService.prototype.create = function (medicationKit) {
                var _this = this;
                return this.$http.post(this.url, medicationKit).then(function (response) {
                    _this.logger.success('Medication Kit created');
                    return response.data;
                });
            };
            MedicationKitsService.prototype.delete = function (medicationKit) {
                var _this = this;
                return this.$http.delete(this.url + '/' + medicationKit.id).then(function (response) {
                    _this.logger.success('Medication Kit deleted.');
                    return response.data;
                });
            };
            MedicationKitsService.prototype.deleteModal = function (medicationKit) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete this Medication Kit?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.delete(medicationKit);
                });
            };
            MedicationKitsService.prototype.deleteMedicationKitItem = function (medicationKit, medicationKitItem) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .ok('OK')
                    .cancel('Cancel')
                    .textContent('Are you sure you want to delete this Medication?');
                return this.$mdDialog.show(confirm).then(function () {
                    return _this.$http
                        .post(_this.url + '/' + medicationKit.id + '/delete_medication_kit_item', {
                        medication_kit_item: medicationKitItem,
                    })
                        .then(function (response) {
                        _this.logger.success('Medication Kit Item removed successfully');
                        return response.data;
                    }, function (error) {
                        _this.logger.error(error);
                    });
                });
            };
            MedicationKitsService.prototype.discontinue = function (medicationKit) {
                var _this = this;
                return this.$http
                    .post(this.url + '/' + medicationKit.id + '/discontinue', medicationKit)
                    .then(function (response) {
                    _this.logger.success('Medication Kit discontinued');
                    return response.data;
                });
            };
            MedicationKitsService.prototype.discontinueModal = function (medicationKit) {
                return this.$mdDialog.show({
                    clickOutsideToClose: true,
                    bindToController: true,
                    templateUrl: '/y/templates/medications/medication-kits/discontinue-modal.html',
                    controllerAs: 'vm',
                    controller: [
                        'medicationKit',
                        'MedicationKitsService',
                        '$mdDialog',
                        function (medicationKit, MedicationKitsService, $mdDialog) {
                            this.MedicationKitsService = MedicationKitsService;
                            this.medicationKit = medicationKit;
                            this.$mdDialog = $mdDialog;
                            this.discontinue = discontinue;
                            function discontinue() {
                                var _this = this;
                                return this.MedicationKitsService.discontinue(this.medicationKit).then(function (medicationKit) {
                                    _this.medicationKit = medicationKit;
                                    return _this.$mdDialog.hide(medicationKit);
                                });
                            }
                        },
                    ],
                    locals: { medicationKit: medicationKit },
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                });
            };
            MedicationKitsService.prototype.update = function (medicationKit) {
                var _this = this;
                return this.$http
                    .patch(this.url + '/' + medicationKit.id, medicationKit)
                    .then(function (response) {
                    _this.logger.success('Medication Kit successfully updated.');
                    return response.data;
                });
            };
            MedicationKitsService.prototype.get = function (id) {
                var _this = this;
                return this.$http.get(this.url + '/' + id).then(function (response) {
                    _this.currentMedicationKit = response.data;
                    return _this.currentMedicationKit;
                });
            };
            MedicationKitsService.$inject = [
                'logger',
                '$http',
                'PermissionsService',
                '$mdDialog',
                'SessionService',
            ];
            return MedicationKitsService;
        }());
        services.MedicationKitsService = MedicationKitsService;
        angular.module('consolo').service('MedicationKitsService', MedicationKitsService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientMedicationKitController = /** @class */ (function () {
            function PatientMedicationKitController(patientMedicationKit, $mdDialog, $http) {
                this.patientMedicationKit = patientMedicationKit;
                this.$mdDialog = $mdDialog;
                this.$http = $http;
                this.saving = false;
            }
            PatientMedicationKitController.prototype.save = function () {
                var _this = this;
                this.saving = true;
                this.$http
                    .patch('/api/v1/patient_medication_kits/' + this.patientMedicationKit.id, this.patientMedicationKit)
                    .then(function (response) {
                    _this.$mdDialog.hide(_this.patientMedicationKit);
                })
                    .catch(function (errors) { })
                    .finally(function () {
                    _this.saving = false;
                });
            };
            PatientMedicationKitController.$inject = ['patientMedicationKit', '$mdDialog', '$http'];
            return PatientMedicationKitController;
        }());
        angular
            .module('consolo')
            .controller('PatientMedicationKitController', PatientMedicationKitController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('viewRxfill', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication-transactions/view-rxfill.html',
            controller: 'ViewRxfillController as $ctrl',
        });
        var ViewRxfillController = /** @class */ (function () {
            function ViewRxfillController($state, CurrentPatient, $http) {
                var _this = this;
                this.$state = $state;
                this.CurrentPatient = CurrentPatient;
                this.$http = $http;
                this.$http
                    .get('/api/v1/medication_transactions/' + $state.params.medicationTransactionId)
                    .then(function (response) {
                    _this.medicationTransaction = response.data;
                })
                    .catch(function (errors) { });
            }
            ViewRxfillController.$inject = ['$state', 'CurrentPatient', '$http'];
            return ViewRxfillController;
        }());
        common.ViewRxfillController = ViewRxfillController;
        angular.module('consolo').controller('ViewRxfillController', ViewRxfillController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('notesToPharmacistEntry', {
            bindings: { readonly: '<', model: '<', reorder: '<' },
            templateUrl: '/y/templates/medications/notes-to-pharmacists/notes-to-pharmacist-entry.html',
            controller: 'NotesToPharmacistEntryController as $ctrl',
        });
        var NotesToPharmacistEntryController = /** @class */ (function () {
            function NotesToPharmacistEntryController(MedicationService) {
                this.MedicationService = MedicationService;
            }
            NotesToPharmacistEntryController.prototype.$onInit = function () {
                var _this = this;
                if (this.reorder === undefined) {
                    this.reorder = false;
                }
                this.notesToSelect = [];
                this.MedicationService.isReady().then(function () {
                    if (_this.reorder && _this.model && _this.model.oldNotes) {
                        _this.notesToSelect.push({ fullDescription: 'Previous Notes to Pharmacist' });
                        _this.notesToSelect.push({ notes: _this.model.oldNotes });
                    }
                    if (_this.MedicationService.notesToPharmacists.length) {
                        _this.notesToSelect.push({ fullDescription: 'Quick Notes' });
                        _this.MedicationService.notesToPharmacists.forEach(function (note) {
                            _this.notesToSelect.push(note);
                        });
                    }
                });
            };
            NotesToPharmacistEntryController.prototype.$onChanges = function (changeObject) {
                if (changeObject.model.currentValue) {
                    this.model = changeObject.model.currentValue;
                    if (this.reorder) {
                        this.model.notes_to_pharmacist = null;
                    }
                }
            };
            NotesToPharmacistEntryController.prototype.noteLookup = function (searchText) {
                var results = [];
                if (this.notesToSelect.length === 0) {
                    return results;
                }
                var index = 0;
                if (this.notesToSelect[0].fullDescription === 'Previous Notes to Pharmacist' &&
                    this.notesToSelect[1].notes.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                    results.push({ fullDescription: 'Previous Notes to Pharmacist' });
                    results.push(this.notesToSelect[1]);
                    index = 2;
                }
                var found = false;
                for (var i = index + 1; i < this.notesToSelect.length; i++) {
                    if (this.notesToSelect[i] &&
                        this.notesToSelect[i].notes &&
                        this.notesToSelect[i].notes.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                        if (!found) {
                            results.push({ fullDescription: 'Quick Notes' });
                            found = true;
                        }
                        results.push(this.notesToSelect[i]);
                    }
                }
                return results;
            };
            NotesToPharmacistEntryController.prototype.searchTextChange = function (searchText) {
                this.model.notes_to_pharmacist = searchText;
            };
            NotesToPharmacistEntryController.prototype.updateNotes = function () {
                if (this.model.notes_to_pharmacist && this.model.notes_to_pharmacist.notes) {
                    this.model.notes_to_pharmacist = this.model.notes_to_pharmacist.notes;
                }
            };
            NotesToPharmacistEntryController.$inject = ['MedicationService'];
            return NotesToPharmacistEntryController;
        }());
        common.NotesToPharmacistEntryController = NotesToPharmacistEntryController;
        angular
            .module('consolo')
            .controller('NotesToPharmacistEntryController', NotesToPharmacistEntryController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('notesToPharmacist', {
            bindings: { readonly: '<' },
            templateUrl: '/y/templates/medications/notes-to-pharmacists/notes-to-pharmacist.html',
            controller: 'NotesToPharmacistController as $ctrl',
        });
        var NotesToPharmacistController = /** @class */ (function () {
            function NotesToPharmacistController(logger, Session, $stateParams, $http, NotesToPharmacistService, $state) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.$stateParams = $stateParams;
                this.$http = $http;
                this.NotesToPharmacistService = NotesToPharmacistService;
                this.$state = $state;
                this.saving = false;
                this.createFromMedication = false;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                if ($stateParams.notesToPharmacistId) {
                    this.NotesToPharmacistService.get($stateParams.notesToPharmacistId).then(function (note) {
                        _this.notesToPharmacist = note;
                    });
                }
            }
            NotesToPharmacistController.prototype.save = function () {
                var _this = this;
                if (!this.notesToPharmacist.notes || this.notesToPharmacist.notes.length === 0) {
                    this.errors = ['You must enter a note.'];
                }
                else {
                    this.saving = true;
                    var newNotes_1 = this.notesToPharmacist.id ? false : true;
                    return this.NotesToPharmacistService.upsert(this.notesToPharmacist)
                        .then(function (notesToPharmacist) {
                        _this.errors = null;
                        var message = 'The note was successfully';
                        message += newNotes_1 ? ' created.' : ' updated.';
                        _this.logger.success(message);
                        _this.$state.go('notes-to-pharmacists');
                    }, function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    })
                        .finally(function () {
                        _this.saving = false;
                    });
                }
            };
            NotesToPharmacistController.prototype.cancel = function () {
                this.$state.go('notes-to-pharmacists');
            };
            NotesToPharmacistController.$inject = [
                'logger',
                'SessionService',
                '$stateParams',
                '$http',
                'NotesToPharmacistService',
                '$state',
            ];
            return NotesToPharmacistController;
        }());
        common.NotesToPharmacistController = NotesToPharmacistController;
        angular
            .module('consolo')
            .controller('NotesToPharmacistController', NotesToPharmacistController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('notesToPharmacists', {
            bindings: {},
            templateUrl: '/y/templates/medications/notes-to-pharmacists/notes-to-pharmacists.html',
            controller: 'NotesToPharmacistsController as $ctrl',
        });
        var NotesToPharmacistsController = /** @class */ (function () {
            function NotesToPharmacistsController(logger, Session, $http, $state, NotesToPharmacistService) {
                var _this = this;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.$state = $state;
                this.NotesToPharmacistService = NotesToPharmacistService;
                this.loading = false;
                var user = this.Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.notesToPharmacists = [];
                this.getNotesToPharmacists();
                this.notesToPharmacistsTableLayout = {
                    id_field: 'id',
                    columns: [
                        {
                            title: 'Notes',
                            field: 'notes',
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View',
                                icon: 'remove_red_eye',
                                callbackFn: function (notesToPharmacist) {
                                    return _this.$state.go('notes-to-pharmacists.view', {
                                        notesToPharmacistId: notesToPharmacist.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.NotesToPharmacistService.viewPermission;
                                },
                            },
                            {
                                label: 'Edit',
                                icon: 'edit',
                                callbackFn: function (notesToPharmacist) {
                                    return _this.$state.go('notes-to-pharmacists.edit', {
                                        notesToPharmacistId: notesToPharmacist.id,
                                    });
                                },
                                conditionalHideFn: function () {
                                    return !_this.NotesToPharmacistService.updatePermission;
                                },
                            },
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (notesToPharmacist) {
                                    if (confirm('Are you sure you would like to delete this Note?')) {
                                        return _this.NotesToPharmacistService.delete(notesToPharmacist).then(function (deletedMedicationFavorite) {
                                            _this.logger.success('The note has been deleted.');
                                            _this.getNotesToPharmacists();
                                        });
                                    }
                                },
                                conditionalHideFn: function () {
                                    return !_this.NotesToPharmacistService.deletePermission;
                                },
                            },
                        ],
                    },
                };
            }
            NotesToPharmacistsController.prototype.getNotesToPharmacists = function () {
                var _this = this;
                this.loading = true;
                this.notesToPharmacists = [];
                if (this.NotesToPharmacistService.viewPermission) {
                    this.NotesToPharmacistService.getAll()
                        .then(function (notesToPharmacists) {
                        _this.notesToPharmacists = notesToPharmacists;
                    })
                        .finally(function () {
                        _this.loading = false;
                    });
                }
            };
            NotesToPharmacistsController.$inject = [
                'logger',
                'SessionService',
                '$http',
                '$state',
                'NotesToPharmacistService',
            ];
            return NotesToPharmacistsController;
        }());
        common.NotesToPharmacistsController = NotesToPharmacistsController;
        angular
            .module('consolo')
            .controller('NotesToPharmacistsController', NotesToPharmacistsController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var NotesToPharmacistService = /** @class */ (function () {
            function NotesToPharmacistService($http, PermissionsService) {
                this.$http = $http;
                this.PermissionsService = PermissionsService;
                this.apiUrl = '/api/v1/notes_to_pharmacists';
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.updatePermission = this.PermissionsService.can('update', 'notes_to_pharmacists');
                this.createPermission = this.PermissionsService.can('create', 'notes_to_pharmacists');
                this.deletePermission = this.PermissionsService.can('destroy', 'notes_to_pharmacists');
                this.viewPermission = this.PermissionsService.can('show', 'notes_to_pharmacists');
            }
            NotesToPharmacistService.prototype.delete = function (notesToPharmacist) {
                return this.$http.delete(this.apiUrl + '/' + notesToPharmacist.id).then(function (response) {
                    return response.data;
                });
            };
            NotesToPharmacistService.prototype.get = function (notesToPharmacistId) {
                return this.$http.get(this.apiUrl + '/' + notesToPharmacistId).then(function (response) {
                    return response.data;
                });
            };
            NotesToPharmacistService.prototype.getAll = function () {
                return this.$http.get(this.apiUrl).then(function (response) {
                    return response.data;
                });
            };
            NotesToPharmacistService.prototype.upsert = function (notesToPharmacist) {
                if (notesToPharmacist.id) {
                    return this.$http
                        .patch(this.apiUrl + '/' + notesToPharmacist.id, notesToPharmacist)
                        .then(function (response) {
                        return response.data;
                    });
                }
                else {
                    return this.$http.post(this.apiUrl, notesToPharmacist).then(function (response) {
                        return response.data;
                    });
                }
            };
            NotesToPharmacistService.$inject = ['$http', 'PermissionsService', '$mdDialog'];
            return NotesToPharmacistService;
        }());
        services.NotesToPharmacistService = NotesToPharmacistService;
        angular
            .module('consolo')
            .service('NotesToPharmacistService', NotesToPharmacistService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingChartHistory = /** @class */ (function () {
            function ConsoloChartingChartHistory() {
                this.bindings = {
                    chartStore: '<',
                    sectionManager: '<',
                    online: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/chart-history.component.html';
                this.controller = ConsoloChartingChartHistoryController;
            }
            return ConsoloChartingChartHistory;
        }());
        var ConsoloChartingChartHistoryController = /** @class */ (function () {
            function ConsoloChartingChartHistoryController($mdDialog, Session, $http, options, chartUtil, ChartDeviceDraftsService, permissionsService, $q) {
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.$http = $http;
                this.options = options;
                this.chartUtil = chartUtil;
                this.ChartDeviceDraftsService = ChartDeviceDraftsService;
                this.permissionsService = permissionsService;
                this.$q = $q;
                this.pageSize = 5;
                this.comprehensiveToggled = false;
                // Currently only setup to work with clinical summaries section.
                // To add more mimic the clinical summaries section setup in the HTML.
                this.expandedSections = {};
                this.clinicalSummaryLinks = [];
                this.permissions = {};
                this.loadPermissions();
            }
            ConsoloChartingChartHistoryController.prototype.$onInit = function () {
                var _this = this;
                var subdomain = location.hostname.split('.')[0];
                this.patientId = this.chartStore.model.patient_id;
                this.chartOptions = {
                    patient_id: this.patientId,
                    page_size: this.pageSize,
                    page: 1,
                    exclude_comprehensive: true,
                    sort_by: 'effective_date',
                    sort_dir: csg.datatable.ISort.desc
                };
                this.clinicalSummaryLinks = [
                    { name: 'Bereavement Assessment', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/bereavement-assessments" },
                    { name: 'Edmonton Assessment', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/edmonton-assessments" },
                    { name: 'Fall Event', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/fall-events" },
                    { name: 'Inpatient Unit Note', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/inpatient-unit-notes" },
                    { name: 'Mini Nutritional Assessment', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/mini-nutritional-assessments" },
                    { name: 'Morse Fall Assessment', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/morse-fall-assessments" },
                    { name: 'Pain Observation', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/pain-observations" },
                    { name: 'Vital Sign Measurement', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/vital-sign-measurements" },
                    { name: 'Wound Assessment', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/wound-assessments" },
                ];
                if (this.permissions.clinical_charts_show_care_summary) {
                    this.clinicalSummaryLinks.splice(1, 0, { name: 'Care Summary', url: "/emr/" + subdomain + "/patients/" + this.patientId + "/cares" });
                }
                if (this.chartStore.model.id) {
                    this.chartOptions.exclude_id = this.chartStore.model.id;
                }
                this.options.get('base_roles').$promise.then(function (baseRoles) {
                    var _a, _b, _c;
                    var roleSymbol = ((_b = (_a = _this.chartStore) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.role_id) ? ((_c = baseRoles.find(function (role) {
                        return role.id === _this.chartStore.model.role_id;
                    })) === null || _c === void 0 ? void 0 : _c.role_symbol) || 0 : 0;
                    if (roleSymbol) {
                        _this.chartOptions.discipline = roleSymbol;
                    }
                    _this.getComprehensive().then(function (c) {
                        _this.loadRecentCharts();
                        _this.loadServerDrafts();
                    });
                });
                this.ChartDeviceDraftsService.getDrafts(this.patientId);
            };
            ConsoloChartingChartHistoryController.prototype.getComprehensive = function () {
                var _this = this;
                return this.$http.get("/api/v1/patients/" + this.patientId + "/comprehensive_chart").then(function (response) {
                    _this.comprehensiveChart = response.data;
                    console.log(_this.comprehensiveChart);
                });
            };
            ConsoloChartingChartHistoryController.prototype.loadRecentCharts = function () {
                var _this = this;
                var tempOptions = angular.copy(this.chartOptions);
                delete tempOptions.user;
                this.$http.get('/api/v1/clinical_charts', { params: tempOptions }).then(function (response) {
                    _this.recentCharts = response.data;
                });
            };
            ConsoloChartingChartHistoryController.prototype.loadPermissions = function () {
                var defer = this.$q.defer();
                var neededPermissions = [
                    { category: 'clinical_charts', action: 'show_care_summary' }
                ];
                for (var _i = 0, neededPermissions_1 = neededPermissions; _i < neededPermissions_1.length; _i++) {
                    var p = neededPermissions_1[_i];
                    this.permissions[p.category + '_' + p.action] = this.permissionsService.can(p.action, p.category);
                }
                defer.resolve(true);
                return defer.promise;
            };
            ConsoloChartingChartHistoryController.prototype.loadServerDrafts = function () {
                var _this = this;
                var user = this.Session.getUser();
                var params = { patient_id: this.patientId, user_id: user.id };
                this.$http.get('/api/v2/clinical_chart_drafts/extended', { params: params }).then(function (response) {
                    _this.serverDrafts = response.data;
                });
            };
            ConsoloChartingChartHistoryController.prototype.toggleChart = function (id) {
                this.currentChart = this.currentChart !== id ? id : 0;
            };
            ConsoloChartingChartHistoryController.prototype.showChartOptions = function ($event) {
                var _this = this;
                var co = angular.copy(this.chartOptions);
                return this.$mdDialog.show({
                    targetEvent: $event,
                    clickOutsideToClose: true,
                    fullscreen: true,
                    template: "\n        <md-dialog class=\"md-hue-1\" aria-label=\"Clinical Chart\" style=\"max-width: 500px;width:500px;min-width:400px;\">\n          <md-toolbar>\n            <div class=\"md-toolbar-tools\">\n              Chart Search Options\n              <span flex></span>\n              <md-button class=\"md-icon-button\" ng-click=\"$mdDialog.cancel();\">\n                <md-icon class=\"material-icons\" aria-label=\"Close dialog\" md-svg-icon=\"navigation:close\"></md-icon>\n              </md-button>\n            </div>\n          </md-toolbar>\n          <md-dialog-content>\n            <consolo-charting-search-options search-options=\"chartOptions\"></consolo-charting-search-options>\n          </md-dialog-content>\n          <md-dialog-actions>\n            <md-button ng-click=\"$mdDialog.cancel()\">Cancel</md-button>\n            <md-button ng-click=\"$mdDialog.hide(chartOptions)\" class=\"md-primary md-raised\">Search</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n        ",
                    controller: ['$scope', '$mdDialog', 'chartOptions', function ($scope, $mdDialog, chartOptions) {
                            $scope.$mdDialog = $mdDialog;
                            $scope.chartOptions = chartOptions;
                        }],
                    bindToController: true,
                    locals: {
                        chartOptions: co
                    }
                }).then(function (options) {
                    if (options) {
                        _this.chartOptions = options;
                        _this.loadRecentCharts();
                        _this.loadServerDrafts();
                    }
                });
            };
            ConsoloChartingChartHistoryController.prototype.toggleSection = function (section) {
                this.expandedSections[section] = !this.expandedSections[section];
            };
            ConsoloChartingChartHistoryController.$inject = [
                '$mdDialog',
                'SessionService',
                '$http',
                'options',
                'ChartUtilService',
                'ChartDeviceDraftsService',
                'PermissionsService',
                '$q',
            ];
            return ConsoloChartingChartHistoryController;
        }());
        components.ConsoloChartingChartHistoryController = ConsoloChartingChartHistoryController;
        angular
            .module('consolo')
            .component('consoloChartingChartHistory', new ConsoloChartingChartHistory());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingSearchOptions = /** @class */ (function () {
            function ConsoloChartingSearchOptions() {
                this.bindings = {
                    searchOptions: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/chart-search-options.component.html';
                this.controller = ConsoloChartingSearchOptionsController;
            }
            return ConsoloChartingSearchOptions;
        }());
        var ConsoloChartingSearchOptionsController = /** @class */ (function () {
            function ConsoloChartingSearchOptionsController(options) {
                this.options = options;
            }
            ConsoloChartingSearchOptionsController.prototype.$onInit = function () {
                var _this = this;
                this.options.get('base_roles').$promise.then(function (br) {
                    console.log(br);
                    _this.baseRoles = br;
                });
            };
            ConsoloChartingSearchOptionsController.prototype.selectUser = function () {
                if (this.searchOptions.user) {
                    this.searchOptions.user_id = this.searchOptions.user.id;
                }
                else {
                    delete this.searchOptions.user_id;
                }
            };
            ConsoloChartingSearchOptionsController.$inject = ['options'];
            return ConsoloChartingSearchOptionsController;
        }());
        components.ConsoloChartingSearchOptionsController = ConsoloChartingSearchOptionsController;
        angular
            .module('consolo')
            .component('consoloChartingSearchOptions', new ConsoloChartingSearchOptions());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingChartSidebar = /** @class */ (function () {
            function ConsoloChartingChartSidebar() {
                this.bindings = {
                    chartStore: '<',
                    sectionManager: '<',
                    online: '<',
                    patientId: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/chart-sidebar.component.html';
                this.controller = ConsoloChartingChartSidebarController;
            }
            return ConsoloChartingChartSidebar;
        }());
        var ConsoloChartingChartSidebarController = /** @class */ (function () {
            function ConsoloChartingChartSidebarController(ClinicalChartEntry, ChartUtilService) {
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.ChartUtilService = ChartUtilService;
                this.summarySection = 'chart';
                console.log(this.chartStore);
                console.log(this.sectionManager);
            }
            ConsoloChartingChartSidebarController.prototype.$onInit = function () { };
            ConsoloChartingChartSidebarController.prototype.viewSummary = function (section) {
                this.summarySection = section;
            };
            ConsoloChartingChartSidebarController.prototype.viewChartModal = function ($event) {
                this.ChartUtilService.viewChartModal(this.chartStore, $event).then(function (val) {
                    if (val) { }
                });
            };
            ConsoloChartingChartSidebarController.$inject = ['ClinicalChartEntry', 'ChartUtilService'];
            return ConsoloChartingChartSidebarController;
        }());
        components.ConsoloChartingChartSidebarController = ConsoloChartingChartSidebarController;
        angular
            .module('consolo')
            .component('consoloChartingSidebar', new ConsoloChartingChartSidebar());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingChartSummary = /** @class */ (function () {
            function ConsoloChartingChartSummary() {
                this.bindings = {
                    chartStore: '<',
                    sectionManager: '<',
                    online: '<',
                    patientId: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/chart-summary.component.html';
                this.controller = ConsoloChartingChartSummaryController;
            }
            return ConsoloChartingChartSummary;
        }());
        var ConsoloChartingChartSummaryController = /** @class */ (function () {
            function ConsoloChartingChartSummaryController() {
            }
            ConsoloChartingChartSummaryController.prototype.$onInit = function () {
            };
            ConsoloChartingChartSummaryController.$inject = [];
            return ConsoloChartingChartSummaryController;
        }());
        components.ConsoloChartingChartSummaryController = ConsoloChartingChartSummaryController;
        angular
            .module('consolo')
            .component('consoloChartingChartSummary', new ConsoloChartingChartSummary());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingFamilyHistory = /** @class */ (function () {
            function ConsoloChartingFamilyHistory() {
                this.bindings = {
                    chartStore: '<',
                    sectionManager: '<',
                    online: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/family-history.component.html';
                this.controller = ConsoloChartingFamilyHistoryController;
            }
            return ConsoloChartingFamilyHistory;
        }());
        var ConsoloChartingFamilyHistoryController = /** @class */ (function () {
            function ConsoloChartingFamilyHistoryController($http, CurrentPatient, PermissionService, PatientRecordDefaultsService) {
                this.$http = $http;
                this.CurrentPatient = CurrentPatient;
                this.PatientRecordDefaultsService = PatientRecordDefaultsService;
                this.showPermission = false;
                this.updatePermission = false;
                this.createPermission = false;
                this.showUpdatePatient = false;
                this.updatePatient = false;
                this.expandedSections = {};
                this.historyPatientHistoryRecordDefaults = [];
                this.getDiagnoses();
                this.showPermission = PermissionService.can('show', 'families');
                this.updatePermission = PermissionService.can('update', 'families');
                this.createPermission = PermissionService.can('create', 'families');
                this.showUpdatePatient = PermissionService.can('show', 'patients');
                this.updatePatient = PermissionService.can('update', 'patients');
                this.getPatientRecordDefaults();
            }
            ConsoloChartingFamilyHistoryController.prototype.$onInit = function () { };
            ConsoloChartingFamilyHistoryController.prototype.getDiagnoses = function () {
                var _this = this;
                var diagnoses = [];
                var config;
                return this.$http
                    .get('/api/v1/family_members/with_diagnoses?patient_id=' + this.CurrentPatient.patient_id)
                    .then(function (data) {
                    _this.familyMembers = data.data;
                    _this.familyMembers.forEach(function (famMember) {
                        var _a;
                        var r = famMember.patient_family_relationships.filter(function (r) {
                            return r.patient_id === _this.CurrentPatient.patient_id;
                        })[0];
                        if (r) {
                            famMember.patient_relationship = r.relationship_description;
                        }
                        famMember.family_diagnoses = ((_a = famMember.family_diagnoses) === null || _a === void 0 ? void 0 : _a.concat([])) || [];
                    });
                }, function (error) {
                    throw error;
                });
            };
            ConsoloChartingFamilyHistoryController.prototype.refreshPatient = function () {
                this.CurrentPatient.refreshPatient();
            };
            ConsoloChartingFamilyHistoryController.prototype.getPatientRecordDefaults = function () {
                var _this = this;
                this.recordDefaults = [];
                this.PatientRecordDefaultsService.getRecordDefaults(this.CurrentPatient.patient_id).then(function (recordDefaults) {
                    _this.currentPatientRecordDefault = [];
                    if (recordDefaults.length) {
                        _this.currentPatientRecordDefault = [].concat(recordDefaults[0]);
                        recordDefaults.splice(0, 1);
                    }
                    _this.recordDefaults = [].concat(recordDefaults);
                    _this.formatPatientHistoryRecord();
                });
            };
            ConsoloChartingFamilyHistoryController.prototype.formatPatientHistoryRecord = function () {
                var patientRecord = {
                    pharmacy: { type: "Pharmacy", data: {} },
                    funeral_home: { type: "Funeral Home", data: {} },
                    dme_provider: { type: "DME Provider", data: {} }
                };
                for (var _i = 0, _a = this.recordDefaults; _i < _a.length; _i++) {
                    var record = _a[_i];
                    var fields = ["pharmacy", "funeral_home", "dme_provider"];
                    for (var _b = 0, fields_1 = fields; _b < fields_1.length; _b++) {
                        var field = fields_1[_b];
                        if (record[field]) {
                            if (!patientRecord[field]["data"][record[field + "_id"]]) {
                                patientRecord[field]["data"][record[field + "_id"]] = {
                                    name: record[field].name,
                                    address: [record[field].address_1, record[field].address_2, record[field].city, record[field].state, record[field].zipcode],
                                    effective_date: record.effective_date
                                };
                            }
                            else {
                                var date = patientRecord[field]["data"][record[field + "_id"]].effective_date;
                                patientRecord[field]["data"][record[field + "_id"]].effective_date = (moment(record.effective_date) < moment(date)) ? record.effective_date : date;
                            }
                        }
                    }
                }
                this.historyPatientHistoryRecordDefaults = [];
                for (var type in patientRecord) {
                    for (var value in patientRecord[type].data) {
                        var record = patientRecord[type].data[value];
                        this.historyPatientHistoryRecordDefaults.push({
                            type: patientRecord[type].type,
                            value: record.name,
                            effective_date: moment(record.effective_date).format('MM/DD/YYYY')
                        });
                    }
                }
            };
            ConsoloChartingFamilyHistoryController.prototype.toggleSection = function (section) {
                this.expandedSections[section] = !this.expandedSections[section];
            };
            ConsoloChartingFamilyHistoryController.prototype.yesOrNo = function (value) {
                return value ? 'Yes' : 'No';
            };
            ConsoloChartingFamilyHistoryController.$inject = [
                '$http',
                'CurrentPatient',
                'PermissionsService',
                'PatientRecordDefaultsService',
            ];
            return ConsoloChartingFamilyHistoryController;
        }());
        components.ConsoloChartingFamilyHistoryController = ConsoloChartingFamilyHistoryController;
        angular
            .module('consolo')
            .component('consoloChartingFamilyHistory', new ConsoloChartingFamilyHistory());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingPatientSummary = /** @class */ (function () {
            function ConsoloChartingPatientSummary() {
                this.bindings = {
                    chartStore: '<',
                    sectionManager: '<',
                    online: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/patient-summary.component.html';
                this.controller = ConsoloChartingPatientSummaryController;
            }
            return ConsoloChartingPatientSummary;
        }());
        var ConsoloChartingPatientSummaryController = /** @class */ (function () {
            function ConsoloChartingPatientSummaryController(CurrentPatient, ProblemListItemService, Medication, MedicationService, Session) {
                this.CurrentPatient = CurrentPatient;
                this.ProblemListItemService = ProblemListItemService;
                this.Medication = Medication;
                this.MedicationService = MedicationService;
                this.Session = Session;
                this.currentSection = { patientInfo: true };
                this.pendingMedications = [];
                this.activeMedications = [];
                this.medicationsLoading = false;
                this.screenedAllergies = [];
            }
            ConsoloChartingPatientSummaryController.prototype.$onInit = function () {
                var _this = this;
                this.patient = this.CurrentPatient.patient;
                this.medicationsLoading = true;
                this.session = this.Session.get();
                this.Medication.loadActiveByPatientId(this.patient.id).$promise.then(function (meds) {
                    for (var _i = 0, meds_1 = meds; _i < meds_1.length; _i++) {
                        var m = meds_1[_i];
                        if (m.order_status) {
                            _this.pendingMedications.push(m);
                        }
                        else {
                            _this.activeMedications.push(m);
                        }
                    }
                    _this.medicationsLoading = false;
                });
                for (var _i = 0, _a = this.patient.patient_allergies; _i < _a.length; _i++) {
                    var a = _a[_i];
                    this.screenedAllergies.push(a.description);
                }
                this.ProblemListItemService.setCurrentPastProblemsForPatient(this.CurrentPatient.patient_id);
                this.ProblemListItemService.getItems(this.CurrentPatient.patient_id, false, true).then(function (items) {
                    _this.problemList = items;
                    if (_this.chartStore.model.reviewed_problem_list_items &&
                        _this.chartStore.model.reviewed_problem_list_items.length) {
                        var problem_ids = _this.chartStore.model.reviewed_problem_list_items.map(function (item) { return item.problem_list_item_id; });
                        for (var _i = 0, _a = _this.problemList; _i < _a.length; _i++) {
                            var i = _a[_i];
                        }
                    }
                    else {
                    }
                });
            };
            ConsoloChartingPatientSummaryController.prototype.addNewProblem = function (newProblem) {
                var _this = this;
                if (newProblem) {
                    var problem = {
                        position: this.problemList.length,
                        start_date: new Date(),
                        snomed_problem: angular.copy(newProblem),
                        snomed_problem_id: newProblem.snomed_cid,
                        patient_id: this.CurrentPatient.patient_id,
                        health_conditions: [],
                        end_date: null,
                        chronicity: null,
                        snomed_problem_description: newProblem,
                    };
                    return this.ProblemListItemService.editAndUpsertProblemListItem(problem).then(function () {
                        _this.problemList = _this.ProblemListItemService.currentProblemListItems;
                    });
                }
            };
            ConsoloChartingPatientSummaryController.prototype.toggleSection = function (section) {
                this.currentSection[section] = !this.currentSection[section];
            };
            ConsoloChartingPatientSummaryController.$inject = ['CurrentPatient', 'ProblemListItemService', 'Medication', 'MedicationService', 'SessionService'];
            return ConsoloChartingPatientSummaryController;
        }());
        components.ConsoloChartingPatientSummaryController = ConsoloChartingPatientSummaryController;
        angular
            .module('consolo')
            .component('consoloChartingPatientSummary', new ConsoloChartingPatientSummary());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var Indicator = consolo.domain.Indicator;
        var ProviderBillableCodeEntry = /** @class */ (function () {
            function ProviderBillableCodeEntry() {
                this.bindings = {
                    chartStore: '<',
                    online: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/provider-billable-entry.component.html';
                this.controller = ProviderBillableCodeEntryController;
            }
            return ProviderBillableCodeEntry;
        }());
        var ProviderBillableCodeEntryController = /** @class */ (function () {
            function ProviderBillableCodeEntryController(CurrentPatient, Permissions, DiagnosesService, PayerGroupService, CPTCodingService, $mdDialog, logger, BillableCodes, Indicators, Session) {
                this.CurrentPatient = CurrentPatient;
                this.Permissions = Permissions;
                this.DiagnosesService = DiagnosesService;
                this.PayerGroupService = PayerGroupService;
                this.CPTCodingService = CPTCodingService;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.BillableCodes = BillableCodes;
                this.Indicators = Indicators;
                this.Session = Session;
                this.searchText = '';
                this.categories = [
                    {
                        id: 1,
                        description: 'Telephone',
                    }, {
                        id: 2,
                        description: 'Hospital Consult',
                    }, {
                        id: 3,
                        description: 'Facility Visit',
                    },
                ];
            }
            ProviderBillableCodeEntryController.prototype.$onInit = function () {
                this.patient = this.chartStore.model.patient || this.CurrentPatient.patient;
                this.hasPatientPayerAssigned = this.patient.has_payer_assigned;
                this.showPermission = this.Permissions.can('show', 'provider_billable_code_entry');
                this.updatePermission = this.Permissions.can('update', 'provider_billable_code_entry');
                this.createPermission = this.Permissions.can('create', 'provider_billable_code_entry');
                this.destroyPermission = this.Permissions.can('destroy', 'provider_billable_code_entry');
                this.viewOnly = this.chartStore.model.locked;
                this.currentUser = this.Session.getUser();
                this.hasPermission = this.updatePermission || this.createPermission;
                if (this.hasPermission) {
                    if (!this.chartStore.model.provider_billable_codes) {
                        this.chartStore.model.provider_billable_codes = { unbillable: false, billable_codes: [] };
                    }
                    else {
                        if (!this.chartStore.model.provider_billable_codes.unbillable && !this.chartStore.model.provider_billable_codes.billable_codes) {
                            this.chartStore.model.provider_billable_codes.billable_codes = [];
                        }
                    }
                }
                if (this.showPermission && this.chartStore.model.provider_billable_codes) {
                    this.formModel = this.chartStore.model.provider_billable_codes;
                }
                this.getPayerType(this.patient);
                this.refreshIndicator();
                this.getIcdTabularSearch();
                // TODO: Uncomment once the categories API is implemented in future ticket
                // this.BillableCodes.getCategories(this.patient.id).then((c) => {
                //   this.categories = c;
                // });
            };
            ProviderBillableCodeEntryController.prototype.refreshIndicator = function () {
                var _this = this;
                this.Indicators.getCurrentIndicator(this.patient.id).then(function (indicator) {
                    _this.currentIndicator = indicator;
                });
            };
            ProviderBillableCodeEntryController.prototype.showDiagnosisSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    multiple: true,
                    locals: {
                        indicator: new Indicator(),
                        icdTabularSearchTree: this.icdTabularSearchTree,
                    },
                })
                    .then(function (selectedIcd10) {
                    console.log(selectedIcd10);
                    _this.checkAndAddValidIcd(selectedIcd10);
                });
            };
            ProviderBillableCodeEntryController.prototype.getIcdTabularSearch = function () {
                var _this = this;
                this.Indicators.getIcdTabularSearch()
                    .then(function (response) {
                    _this.icdTabularSearchTree = response.data;
                })
                    .catch(function (error) {
                    console.log('TABULAR SEARCH ERROR ===================');
                    console.log(error);
                });
            };
            ProviderBillableCodeEntryController.prototype.checkAndAddValidIcd = function (selectedIcd10) {
                var _a, _b, _c;
                var icd10Prefix = selectedIcd10.code.split('')[0].toUpperCase();
                if (((_a = this.currentIndicator) === null || _a === void 0 ? void 0 : _a.icd10_health_conditions.length) > 0) {
                    if (!((_c = (_b = this.currentIndicator) === null || _b === void 0 ? void 0 : _b.icd10_health_conditions) === null || _c === void 0 ? void 0 : _c.some(function (diagnosis) { return diagnosis.code === selectedIcd10.code; }))) {
                        // If new selected ICD10 isn't a duplicate, add it to the list
                        this.addIcd10Code(selectedIcd10);
                    }
                    else {
                        // If new selected ICD10 is a duplicate throw error toast
                        this.logger.error('You may not have duplicated ICD-10 codes', 'ICD Already Exists');
                    }
                }
                else {
                    this.addIcd10Code(selectedIcd10);
                }
            };
            ProviderBillableCodeEntryController.prototype.addIcd10Code = function (selectedIcd10) {
                var _this = this;
                var _a;
                var position = (_a = this.currentIndicator.icd10_health_conditions) === null || _a === void 0 ? void 0 : _a.length;
                var physician_charge_diagnosis = {
                    icd10_id: selectedIcd10.icd10_id || selectedIcd10.id,
                    code: selectedIcd10.code,
                    label: this.DiagnosesService.diagnosisLabel(position) + ' ICD10',
                    description: selectedIcd10.description,
                    position: position
                };
                this.currentIndicator.icd10_health_conditions = this.appendObjectToArray(this.currentIndicator.icd10_health_conditions, physician_charge_diagnosis);
                this.currentIndicator.icd10s = this.appendObjectToArray(this.currentIndicator.icd10s, physician_charge_diagnosis);
                this.currentIndicator.user_id = this.currentUser.id;
                var newDate = new Date();
                this.currentIndicator.effective_date = newDate.toString();
                delete this.currentIndicator["id"];
                var newIndicator = new Indicator(this.currentIndicator);
                this.Indicators.addIndicator(this.patient.id, newIndicator).then(function (indicator) {
                    _this.currentIndicator = indicator.data;
                    _this.logger.success("Diagnosis " + physician_charge_diagnosis.code + " added.", 'Current Indicator Updated');
                });
            };
            ProviderBillableCodeEntryController.prototype.appendObjectToArray = function (array, element) {
                array.splice(array.length, 0, element);
                this.setPositions(array);
                return [].concat(array);
            };
            ProviderBillableCodeEntryController.prototype.setPositions = function (array) {
                for (var i = 0; i < array.length; i++) {
                    array[i].position = i;
                    array[i].label = this.DiagnosesService.diagnosisLabel(i) + ' ICD10';
                }
            };
            // Get patient's current payer type (Medicare, Medicaid, etc.)
            ProviderBillableCodeEntryController.prototype.getPayerType = function (patient) {
                var _this = this;
                this.PayerGroupService.getNewPayerGroupDetails(patient.id).then(function (payerGroup) {
                    payerGroup.insurance_policies.some(function (insurancePolicy) {
                        if (insurancePolicy) {
                            _this.payerType = insurancePolicy.insurance_carrier_id;
                            return true;
                        }
                    });
                });
            };
            // Get billable codes based on search text and patient's payer type
            ProviderBillableCodeEntryController.prototype.getBillableCodes = function (text) {
                return this.CPTCodingService.getAllCPTCodesAndModifiers(text, null, this.payerType || null, this.chartStore.model.effective_date, this.patient.id);
            };
            ProviderBillableCodeEntryController.prototype.addBillableCode = function (billableCode) {
                if (!billableCode || !billableCode.id) {
                    return;
                }
                console.log(billableCode);
                // If the billable code is already selected, don't add it again
                var existingCode = this.formModel.billable_codes.find(function (bc) { return billableCode.id === bc.id; });
                // also check if the modiier was already selected
                if (existingCode && existingCode.modifier === billableCode.modifier) {
                    return;
                }
                if (!this.formModel.billable_codes.length) {
                    billableCode.primary = true;
                }
                this.formModel.billable_codes.push(billableCode);
                this.chartStore.saveDraft();
                this.searchText = '';
                this.code = null;
            };
            ProviderBillableCodeEntryController.prototype.removeBillableCode = function (billableCode) {
                billableCode.primary = false;
                if (this.formModel.billable_codes.length) {
                    this.formModel.billable_codes[0].primary = true;
                }
                this.chartStore.saveDraft();
            };
            ProviderBillableCodeEntryController.prototype.removeDiagnosis = function (diagnosis) {
                var _this = this;
                var idxHC = this.currentIndicator.icd10_health_conditions.findIndex(function (icd10) { return icd10.code === diagnosis.code; });
                var idxIcd = this.currentIndicator.icd10_health_conditions.findIndex(function (icd10) { return icd10.code === diagnosis.code; });
                if (idxHC >= 0 && idxIcd >= 0) {
                    this.currentIndicator.icd10_health_conditions.splice(idxHC, 1);
                    this.currentIndicator.icd10s.splice(idxIcd, 1);
                    this.Indicators.updateIndicator(this.currentIndicator.id, this.patient.id, this.currentIndicator).then(function (indicator) {
                        _this.currentIndicator = indicator.data;
                        _this.logger.success("Diagnosis " + diagnosis.code + " removed.", 'Current Indicator Updated');
                    });
                }
                else {
                    this.logger.error('Could not find diagnosis in array');
                }
            };
            ProviderBillableCodeEntryController.prototype.toggleUnbillable = function () {
                this.chartStore.saveDraft();
            };
            ProviderBillableCodeEntryController.$inject = ['CurrentPatient', 'PermissionsService', 'DiagnosesService', 'PayerGroupService', 'CPTCodingService', '$mdDialog', 'logger', 'BillableCodesService', 'IndicatorsService', 'SessionService'];
            return ProviderBillableCodeEntryController;
        }());
        components.ProviderBillableCodeEntryController = ProviderBillableCodeEntryController;
        angular
            .module('consolo')
            .component('providerBillableCodeEntry', new ProviderBillableCodeEntry());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var ConsoloChartingTimelineSummary = /** @class */ (function () {
            function ConsoloChartingTimelineSummary() {
                this.bindings = {
                    chartStore: '<',
                    sectionManager: '<',
                    online: '<',
                };
                this.templateUrl =
                    '/y/templates/clinical-charts/components/charting-sidebar/timeline-summary.component.html';
                this.controller = ConsoloChartingTimelineSummaryController;
            }
            return ConsoloChartingTimelineSummary;
        }());
        var ConsoloChartingTimelineSummaryController = /** @class */ (function () {
            function ConsoloChartingTimelineSummaryController(currentPatient, ProblemListItemService, logger) {
                this.currentPatient = currentPatient;
                this.ProblemListItemService = ProblemListItemService;
                this.logger = logger;
            }
            ConsoloChartingTimelineSummaryController.prototype.$onInit = function () { };
            ConsoloChartingTimelineSummaryController.$inject = ['CurrentPatient', 'ProblemListItemService', 'logger'];
            return ConsoloChartingTimelineSummaryController;
        }());
        components.ConsoloChartingTimelineSummaryController = ConsoloChartingTimelineSummaryController;
        angular
            .module('consolo')
            .component('consoloChartingTimelineSummary', new ConsoloChartingTimelineSummary());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MissedVisitController = /** @class */ (function () {
            function MissedVisitController(MissedVisitService, CurrentPatient, Session, $mdDialog, logger, $window, options) {
                var _this = this;
                this.MissedVisitService = MissedVisitService;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.$window = $window;
                this.options = options;
                this.today = new Date(new Date().toDateString());
                this.saveText = 'Save';
                this.loggerText = 'Missed Visit Saved';
                this.errors = null;
                this.roles = options.get('base_roles');
                this.missed_visit_reasons = options.get('missed_visit_reasons');
                this.care_types = options.get('clinical_care_types');
                if (!this.itemId) {
                    this.pageTitle = 'Missed Visit Details';
                    this.missed_visit = {};
                    this.missed_visit.patient_id = this.CurrentPatient.patient_id;
                    this.missed_visit.effective_date = this.today;
                    this.missed_visit.clinical_trip_stop_point_id = this.clinical_trip_stop_point_id;
                    var user = Session.getUser();
                    this.missed_visit.user_id = user.id;
                    this.missed_visit.patient_time = {
                        user_id: this.missed_visit.user_id,
                        effective_date: this.missed_visit.effective_date,
                        patient_id: this.missed_visit.patient_id,
                    };
                }
                else {
                    this.loading = true;
                    this.MissedVisitService.get(this.item.id).then(function (missed_visit) {
                        _this.loading = false;
                        _this.missed_visit = missed_visit.data;
                        _this.pageTitle = 'Edit Missed Visit';
                    }, function () {
                        _this.logger.error('Error retrieving missed visit: ' + _this.item.id);
                        _this.$mdDialog.hide();
                        return false;
                    });
                }
            }
            MissedVisitController.prototype.createMissedVisit = function () {
                var _this = this;
                if (this.missed_visit.patient_time.travel_start_datetime) {
                    this.missed_visit.patient_time.travel_start_datetime = consolo.common.combineDateTime(this.missed_visit.patient_time.in_datetime, this.missed_visit.patient_time.travel_start_datetime);
                }
                if (this.missed_visit.patient_time.travel_stop_datetime) {
                    this.missed_visit.patient_time.travel_stop_datetime = consolo.common.combineDateTime(this.missed_visit.patient_time.in_datetime, this.missed_visit.patient_time.travel_stop_datetime);
                }
                var save = !this.missed_visit.id
                    ? this.MissedVisitService.create(this.missed_visit)
                    : this.MissedVisitService.update(this.missed_visit);
                save.then(function (missed_visit) {
                    _this.logger.success(_this.loggerText);
                    if (missed_visit) {
                        // massage missed_visit data layout to work with consolo-data-table
                        _this.MissedVisitService.setDataTableFields(missed_visit.data);
                        var index = _this.CurrentPatient.patient.misc_data.missed_visits.indexOf(_this.item);
                        if (index === -1) {
                            _this.CurrentPatient.patient.misc_data.missed_visits.push(missed_visit.data);
                        }
                        else {
                            _this.CurrentPatient.patient.misc_data.missed_visits[index] = missed_visit.data;
                        }
                    }
                    _this.$mdDialog.hide(missed_visit.data);
                    _this.$window.location.href = _this.$window.location.href.split('?')[0];
                }, function (e) {
                    console.log('Error creating missed visit');
                    console.log(e);
                    _this.errors = e.data.errors;
                });
            };
            MissedVisitController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            MissedVisitController.prototype.saveDisabled = function () {
                if (this.loading) {
                    return false;
                }
                return (!this.missed_visit.effective_date ||
                    !this.missed_visit.user_id ||
                    !this.missed_visit.role_id ||
                    !this.missed_visit.missed_visit_reason_id);
            };
            MissedVisitController.$inject = [
                'MissedVisitService',
                'CurrentPatient',
                'SessionService',
                '$mdDialog',
                'logger',
                '$window',
                'options',
            ];
            return MissedVisitController;
        }());
        angular.module('consolo').controller('MissedVisitController', MissedVisitController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var MissedVisitsController = /** @class */ (function () {
            function MissedVisitsController(MissedVisitService, CurrentPatient, CSV, $mdDialog, logger, options, PdfGenerator, $state) {
                var _this = this;
                this.MissedVisitService = MissedVisitService;
                this.CurrentPatient = CurrentPatient;
                this.CSV = CSV;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.options = options;
                this.PdfGenerator = PdfGenerator;
                this.$state = $state;
                this.showExpired = false;
                this.today = new Date();
                this.currentFilter = function (value, index, array) {
                    if (value.isExpired && !_this.showExpired) {
                        return false;
                    }
                    return true;
                };
                this.currentOptions = {
                    enablePaging: true,
                    pagingOptions: {
                        currentPage: 1,
                        rowsPerPage: 2,
                        changePage: function (page) {
                            console.log(_this.currentOptions.pagingOptions.currentPage);
                        },
                    },
                };
                if ($state.params.clinical_trip_stop_point_id) {
                    this.showMissedVisitDialog(null, null);
                }
                MissedVisitService.loadByPatientId(CurrentPatient.patient_id).then(function (response) {
                    // stick Missed Visit data into misc_data container in Current Patient service
                    _this.CurrentPatient.patient.misc_data.missed_visits = response.data;
                    _this.CurrentPatient.patient.misc_data.missed_visits.forEach(function (missed_visit) {
                        if (missed_visit) {
                            _this.MissedVisitService.setDataTableFields(missed_visit);
                        }
                    });
                });
                this.formModel = {
                    options: {
                        expandCollapseAll: false,
                        noExpand: true,
                    },
                    header: [
                        {
                            header: true,
                            title: 'Effective Date',
                            is_date: true,
                            field: 'effective_date',
                        },
                        {
                            header: true,
                            title: 'Role',
                            field: 'role_name',
                        },
                        {
                            header: true,
                            title: 'Missed Visit Reason',
                            field: 'missed_visit_reason_description',
                        },
                        {
                            header: true,
                            title: 'Comments',
                            field: 'comments',
                        },
                        {
                            header: true,
                            title: 'Created By',
                            field: 'created_by_name',
                        },
                        {
                            header: true,
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            showExpandCollapse: false,
                            csvContents: function () {
                                return _this.missedVisitCsv(_this.CurrentPatient.patient.misc_data.missed_visits);
                            },
                            csvFilename: 'Patient ' +
                                this.CurrentPatient.patient_id +
                                ' Missed Visits ' +
                                new Date().toISOString().slice(0, 10) +
                                '.csv',
                            itemMenuItems: [
                                {
                                    icon: 'image:edit',
                                    text: 'Edit Missed Visit',
                                    conditionalHideFn: function (action, item) {
                                        if (item.signatures.length > 0) {
                                            return true;
                                        }
                                    },
                                    callbackFn: function ($event, item) {
                                        _this.showMissedVisitDialog($event, item);
                                    },
                                },
                                {
                                    icon: 'editor:border_color',
                                    text: 'Add Your Signature',
                                    callbackFn: function ($event, item) {
                                        _this.MissedVisitService.addUserSignature($event, item);
                                    },
                                },
                                {
                                    icon: 'action:print',
                                    text: 'Print',
                                    callbackFn: function ($event, item) {
                                        _this.printMissedVisit($event, item);
                                    },
                                },
                                {
                                    icon: 'action:delete',
                                    text: 'Delete Missed Visit',
                                    conditionalHideFn: function (action, item) {
                                        if (item.signatures.length > 0) {
                                            return true;
                                        }
                                    },
                                    callbackFn: function ($event, item) {
                                        _this.MissedVisitService.deleteMissedVisit($event, item);
                                    },
                                },
                            ],
                        },
                        {
                            header: true,
                            formatCustom: function (action, item) {
                                if (item.signatures.length > 0) {
                                    return '<i class="material-icons">lock</i>';
                                }
                            },
                        },
                    ],
                    subTable: [
                        [
                            {
                                title: 'Date In',
                                field: 'patient_time_in_date',
                                singleColumnOrdinal: 1,
                                isDate: true,
                            },
                            {
                                title: 'Time In',
                                field: 'patient_time_in',
                                singleColumnOrdinal: 1,
                            },
                            {
                                title: 'Time Out',
                                field: 'patient_time_out',
                                singleColumnOrdinal: 1,
                            },
                        ],
                        [
                            {
                                title: 'Mileage',
                                field: 'patient_time_mileage',
                                singleColumnOrdinal: 1,
                            },
                            {
                                title: 'Travel Start',
                                field: 'patient_time_travel_start',
                                singleColumnOrdinal: 1,
                            },
                            {
                                title: 'Travel Stop',
                                field: 'patient_time_travel_stop',
                                singleColumnOrdinal: 1,
                            },
                        ],
                        [
                            {
                                title: 'Clinical Care Type',
                                field: 'patient_time_clinical_care_type',
                                singleColumnOrdinal: 1,
                            },
                            {
                                title: 'Out of Pocket Expense',
                                field: 'patient_time_out_of_pocket',
                                singleColumnOrdinal: 1,
                            },
                            {
                                title: 'Patient Time Comments',
                                field: 'patient_time_comments',
                                singleColumnOrdinal: 1,
                            },
                        ],
                        [
                            {
                                title: 'Signatures',
                                field: 'data_table_signatures',
                            },
                        ],
                    ],
                };
            }
            MissedVisitsController.prototype.missedVisitCsv = function (missed_visits) {
                var headers = ['Comments', 'Effective Date', 'Created By'];
                var date = this.CSV.date, yn = this.CSV.yn;
                return this.CSV.generate(missed_visits.map(function (missed_visit) {
                    return [
                        missed_visit.comments,
                        date(missed_visit.effective_date),
                        missed_visit.created_by_name,
                    ];
                }), headers);
            };
            MissedVisitsController.prototype.showMissedVisitDialog = function ($event, item) {
                if (!item) {
                    item = {};
                    item.id = null;
                }
                this.$mdDialog.show({
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/clinical-charts/sections/missed-visits/missed-visit.html',
                    controller: 'MissedVisitController as $ctrl',
                    locals: {
                        item: item,
                        itemId: item.id,
                        clinical_trip_stop_point_id: this.$state.params.clinical_trip_stop_point_id,
                    },
                });
            };
            MissedVisitsController.prototype.printMissedVisit = function ($event, item) {
                this.PdfGenerator.printMissedVisit(item.id, this.CurrentPatient.patient.id);
            };
            MissedVisitsController.$inject = [
                'MissedVisitService',
                'CurrentPatient',
                'CSV',
                '$mdDialog',
                'logger',
                'options',
                'PdfGenerator',
                '$state',
            ];
            return MissedVisitsController;
        }());
        angular.module('consolo').controller('MissedVisitsController', MissedVisitsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

/*
  To use the default filter search you must do the following
  1. create a service that extends csg.datatable.ApiService
  2. Have methods inside the service for search/reset
  3. Add a main-filters-page(api-service="$ctrl.YourDataTableService") right before your csg-data-table in a jade file
  4. Make your sidebar in the states file look like this
      'sidebar@': {
        template:
          '<sidebar-filters api-service="$ctrl.YourDataTableService" flex=""></sidebar-filters>',
        controller: 'FilterSidebarController as $ctrl',
      }
  5. Can't figure out how to get the Service to be injected without actually calling the FilterSidebarController, so
      add YourDataTableService to the FilterSidebarController
  6. Create a filter in filter.ts assigning the filters you want to show in applicableFilters
    export class PhysicianOrderFilter extends Filter {
      constructor() {
        super();
        this.applicableFilters = {
          date_range: true,
          office_id: true,
          patientSearch: true,
          include_deleted: true,
          only_deleted: true,
          physician_id: true,
          team_id: true,
          physician_order_status: true,
          care_plan_oversight: true,
          drug_schedule: true,
          nurse_id: true,
          has_medications: true,
          has_controlled_medications: true,
          patient_id: true,
        };
      }
    }
  7. If you want to store the filters, create a new field for your object in device-settings export enum Pages
*/
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var FilterSearchService = /** @class */ (function () {
            function FilterSearchService($http, deviceSettingsService, $stateParams, officeFilterService, options, cache, lookupFactory, ChartingTemplateService, $filter, $q, Session, CurrentPatient, $timeout, PayerService, SidebarService, BillableCodesService) {
                var _this = this;
                this.$http = $http;
                this.deviceSettingsService = deviceSettingsService;
                this.$stateParams = $stateParams;
                this.officeFilterService = officeFilterService;
                this.options = options;
                this.cache = cache;
                this.lookupFactory = lookupFactory;
                this.ChartingTemplateService = ChartingTemplateService;
                this.$filter = $filter;
                this.$q = $q;
                this.Session = Session;
                this.CurrentPatient = CurrentPatient;
                this.$timeout = $timeout;
                this.PayerService = PayerService;
                this.SidebarService = SidebarService;
                this.BillableCodesService = BillableCodesService;
                this.resettingFilters = false;
                this.pageTracker = {
                    page: 0,
                    totalPages: 0,
                    totalRows: 0,
                    items: [],
                };
                this.chips = [];
                this.date_range_options = [
                    { name: 'Custom', value: 'custom' },
                    { name: 'Today', value: 'today' },
                    { name: 'Last 7 Days', value: 'last_7' },
                    { name: 'Last 30 Days', value: 'last_30' },
                    { name: 'Month to Date', value: 'month_to_date' },
                    { name: 'Previous Month', value: 'previous_month' },
                    { name: 'Year to Date', value: 'year_to_date' },
                    { name: 'All Days Before', value: 'all_before' },
                    { name: 'All Days After', value: 'all_after' },
                ];
                this.date_range_display = {
                    custom: 'Custom',
                    today: 'Today',
                    last_7: 'Last 7 Days',
                    last_30: 'Last 30 Days',
                    month_to_date: 'Month To Date',
                    previous_month: 'Previous Month',
                    year_to_date: 'Year to Date',
                    all_before: 'All Days Before',
                    all_after: 'All Days After',
                };
                this.lookups = {};
                this.saveParams = true;
                this.isLoading = false;
                this.methodAdded = [
                    { name: '', value: null },
                    { name: 'CSV Upload', value: 'csv' },
                    { name: 'Manual', value: 'manual' },
                ];
                this.anyYesNoTypes = {
                    has_signature: 'Has Signature',
                    has_physician_signature: 'Has Physician Signature',
                    has_patient_time: 'Has Patient Time',
                    has_evv: 'Has Evv',
                    has_postmortem: 'Has Postmortem',
                    submitted_to_coderyte: 'Submitted to CodeRyte',
                    processed_by_coderyte: 'Processed by CodeRyte',
                    deleted: 'Voided',
                };
                this.lookupsReady = this.$q.defer();
                this.filtersLoaded = this.$q.defer();
                this.user = this.Session.getUser();
                this.showFilters = true;
                this.scheduleSelection = [
                    { id: '1', description: 'Schedule 1' },
                    { id: '2', description: 'Schedule 2' },
                    { id: '3', description: 'Schedule 3' },
                    { id: '4', description: 'Schedule 4' },
                    { id: '5', description: 'Schedule 5' },
                ];
                this.signatureSelection = [
                    { id: 'needs_nurse_sig', description: 'Needs Nurse Signature' },
                    { id: 'needs_physician_sig', description: 'Needs Physician Signature' },
                    { id: 'signed', description: 'Signed' },
                ];
                this.planOfCareSections = [
                    { id: 'assignments', description: 'Assignments' },
                    { id: 'physician_orders', description: 'Physician Orders' },
                    { id: 'medications', description: 'Medications' },
                    { id: 'indicators', description: 'Indicators' },
                    { id: 'care_plan_problems', description: 'Care Plan Problems' },
                    { id: 'recurring_task_sets', description: 'Visit Task Lists' },
                    { id: 'volunteer_care_plans', description: 'Volunteer Care Plans' },
                    { id: 'unrelated_explanations', description: 'Explanations of Unrelatedness' },
                ];
                this.lookups['payerSelection'] = [
                    { id: 1, description: 'Medicare' },
                    { id: 2, description: 'Medicaid' },
                    { id: 3, description: 'Private Insurance' },
                    { id: 4, description: 'Charitable Care' },
                ];
                var promiseChain = [];
                promiseChain.push(this.options.get('base_roles').$promise.then(function (data) {
                    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                        var role = data_1[_i];
                        role.name = role.description;
                    }
                    _this.lookups['disciplines'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(this.$http.get('/api/v1/counties/for_user').then(function (response) {
                    _this.lookups['counties'] = response.data;
                    return _this.$q.when(true);
                }));
                var lookupCache = this.cache(this.lookupFactory, 'lookup', { maxAge: 10 });
                promiseChain.push(lookupCache['teams']().$promise.then(function (data) {
                    _this.lookups['teams'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(lookupCache['users']({ limit: -1 }).$promise.then(function (data) {
                    _this.lookups['users'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(this.PayerService.getMedicaidPayers(true, true).then(function (medicaidPayers) {
                    _this.lookups['medicaidPayers'] = medicaidPayers;
                    return _this.$q.when(true);
                }));
                promiseChain.push(this.PayerService.getPrivateInsurancePayers(true, true).then(function (privateInsurancePayers) {
                    _this.lookups['privateInsurancePayers'] = privateInsurancePayers;
                    return _this.$q.when(true);
                }));
                promiseChain.push(lookupCache['physicians']({ limit: -1 }).$promise.then(function (data) {
                    _this.lookups['physicians'] = data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(this.$http
                    .get('/api/v1/pbm_configurations')
                    .then(function (response) {
                    _this.pbmConfigurations = response.data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(this.$http.get('/api/v1/patients').then(function (response) {
                    _this.lookups['patients'] = response.data;
                    return _this.$q.when(true);
                }));
                promiseChain.push(this.officeFilterService
                    .getInstance()
                    .then(function (instance) {
                    _this.officeFilterServiceInstance = instance;
                    _this.lookups.offices = _this.officeFilterServiceInstance.offices;
                    return _this.$q.when(true);
                }));
                this.$q.all(promiseChain).finally(function () {
                    _this.lookups['clinical_chart_types'] = _this.ChartingTemplateService.getAllBaseSections(true);
                    _this.lookupsReady.resolve(true);
                });
            }
            FilterSearchService.prototype.isReady = function () {
                return this.lookupsReady.promise.then(function () {
                    return true;
                });
            };
            FilterSearchService.prototype.getItemFromLookup = function (lookupName, key, value) {
                if (lookupName && this.lookups[lookupName]) {
                    return this.lookups[lookupName].filter(function (lookup) {
                        return lookup[key] === value;
                    })[0];
                }
                return null;
            };
            FilterSearchService.prototype.resetChips = function () {
                this.chips = [];
            };
            FilterSearchService.prototype.getFilters = function (page, key) {
                return this.deviceSettingsService.getValue(page, key);
            };
            FilterSearchService.prototype.setFilters = function (page, key, value) {
                this.deviceSettingsService.setValue(page, key, value);
            };
            FilterSearchService.prototype.addChip = function (filterType, value, displayValue) {
                //You can add chips from the auto complete on the main page or the sidebar.
                // Try to find if an existing chip already exists
                var existingChip = this.chips.filter(function (chip) {
                    return chip.type === filterType;
                })[0];
                //Do not want to duplicate, so only add it if not found
                if (!existingChip) {
                    this.chips.push({ type: filterType, value: value, name: displayValue });
                }
            };
            FilterSearchService.prototype.removeChip = function (filterType) {
                for (var i = this.chips.length - 1; i >= 0; i--) {
                    if (this.chips[i].type === filterType) {
                        this.chips.splice(i, 1);
                        break;
                    }
                }
            };
            FilterSearchService.prototype.lookupBillableCodes = function (searchTerm) {
                return this.BillableCodesService.lookupCodes(searchTerm);
            };
            FilterSearchService.prototype.lookupPayers = function (searchTerm) {
                return this.$http
                    .get('/api/v1/payers/unified_payers', { params: { q: searchTerm } })
                    .then(function (response) {
                    return response.data;
                });
            };
            FilterSearchService.prototype.toggleDisplaySetting = function (setting, key) {
                if (!this.displayToggles) {
                    this.displayToggles = {};
                }
                //Set to false if it's undefined
                if (!this.displayToggles[setting]) {
                    this.displayToggles[setting] = false;
                }
                //Flip it
                this.displayToggles[setting] = !this.displayToggles[setting];
                if (key) {
                    //Store the settings for the user if a key is passed in
                    this.deviceSettingsService.setValue(key, consolo.deviceSettings.Settings.display_toggles, this.displayToggles);
                }
            };
            FilterSearchService.prototype.filterResults = function (type, searchTerm) {
                searchTerm = searchTerm.toLowerCase();
                if (type === 'term') {
                    var ret = [];
                    var temp = [];
                    var _loop_1 = function (t) {
                        if (this_1.lookups.hasOwnProperty(t) && t !== 'encounter_types') {
                            temp = this_1.lookups[t].filter(function (item) {
                                return item.name.toLowerCase().indexOf(searchTerm) >= 0;
                            });
                            ret = ret.concat(temp.map(function (item) {
                                item.type = t;
                                switch (t) {
                                    case 'patients':
                                        item._icon = 'action:face';
                                        item.type = 'patient_id';
                                        break;
                                    case 'disciplines':
                                        item._icon = 'maps:local_offer';
                                        break;
                                    case 'offices':
                                        item._icon = 'communication:business';
                                        item.type = 'office_id';
                                        break;
                                    case 'teams':
                                        item._icon = 'action:supervisor_account';
                                        item.type = 'team_id';
                                        break;
                                    case 'users':
                                        item._icon = 'action:account_circle';
                                        item.type = 'user_id';
                                        break;
                                    default:
                                        item._icon = 'editor:title';
                                        break;
                                }
                                return item;
                            }));
                        }
                    };
                    var this_1 = this;
                    for (var t in this.lookups) {
                        _loop_1(t);
                    }
                    return ret;
                }
                else if (type === 'patients') {
                    // return this.lookups[type].filter(item => {
                    //   return item.name.toLowerCase().indexOf(searchTerm) >= 0;
                    // });
                    //Patient cache does not include MRN
                    return this.lookupFactory.patients({ q: searchTerm, all_offices: true }).$promise.then(function (data) {
                        for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
                            var item = data_2[_i];
                            item.type = 'patients';
                            item._icon = 'action:face';
                        }
                        return data;
                    });
                }
                if (this.lookups[type]) {
                    return this.lookups[type].filter(function (item) {
                        if (item.name) {
                            return item.name.toLowerCase().indexOf(searchTerm) >= 0;
                        }
                        else {
                            return item.description.toLowerCase().indexOf(searchTerm) >= 0;
                        }
                    });
                }
                else {
                    return [];
                }
            };
            FilterSearchService.$inject = [
                '$http',
                'DeviceSettingsService',
                '$stateParams',
                'officeFilterService',
                'options',
                'cache',
                'Lookup',
                'ChartingTemplateService',
                '$filter',
                '$q',
                'SessionService',
                'CurrentPatient',
                '$timeout',
                'PayerService',
                'SidebarService',
                'BillableCodesService',
            ];
            return FilterSearchService;
        }());
        services.FilterSearchService = FilterSearchService;
        angular.module('consolo').service('FilterSearchService', FilterSearchService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        var FilterSidebarController = /** @class */ (function () {
            function FilterSidebarController(PhysicianOrdersDataTableService, menu, ConsoloPatientSectionManagerService, FilterSearchService, TrackerDashboardDataTableService, HospiceRatesDataTableService, BillingDemographicsDataTableService, CurrentPatient, MedicationFormularyDataTableService, FormularyExceptionRequestsDataTableService, PhysicianChargesDataTableService, CptCodesDataTableService, PriorAuthorizationsDataTableService, PatientCqmDataTableService) {
                this.PhysicianOrdersDataTableService = PhysicianOrdersDataTableService;
                this.menu = menu;
                this.ConsoloPatientSectionManagerService = ConsoloPatientSectionManagerService;
                this.FilterSearchService = FilterSearchService;
                this.TrackerDashboardDataTableService = TrackerDashboardDataTableService;
                this.HospiceRatesDataTableService = HospiceRatesDataTableService;
                this.BillingDemographicsDataTableService = BillingDemographicsDataTableService;
                this.CurrentPatient = CurrentPatient;
                this.MedicationFormularyDataTableService = MedicationFormularyDataTableService;
                this.FormularyExceptionRequestsDataTableService = FormularyExceptionRequestsDataTableService;
                this.PhysicianChargesDataTableService = PhysicianChargesDataTableService;
                this.CptCodesDataTableService = CptCodesDataTableService;
                this.PriorAuthorizationsDataTableService = PriorAuthorizationsDataTableService;
                this.PatientCqmDataTableService = PatientCqmDataTableService;
                this.isOnline = true;
            }
            FilterSidebarController.prototype.isOpen = function (section) {
                return this.menu.isSectionSelected(section);
            };
            FilterSidebarController.prototype.navigateTo = function (section, type) {
                this.ConsoloPatientSectionManagerService.navigateTo(section, type);
            };
            FilterSidebarController.prototype.navigatePrimary = function (item) {
                return this.ConsoloPatientSectionManagerService.navigateTo(item, 'primary');
            };
            FilterSidebarController.prototype.navigateSecondary = function (item, $event) {
                return this.ConsoloPatientSectionManagerService.navigateTo(item, 'secondary', $event);
            };
            FilterSidebarController.prototype.getHref = function (item, position) {
                return this.ConsoloPatientSectionManagerService.getHref(item, position);
            };
            FilterSidebarController.prototype.toggleOpen = function (section) {
                if (!this.isOnline && section.name !== 'offlinemenu') {
                    return;
                }
                this.menu.toggleSelectSection(section);
            };
            FilterSidebarController.$inject = [
                'PhysicianOrdersDataTableService',
                'menu',
                'ConsoloPatientSectionManagerService',
                'FilterSearchService',
                'TrackerDashboardDataTableService',
                'HospiceRatesDataTableService',
                'BillingDemographicsDataTableService',
                'CurrentPatient',
                'MedicationFormularyDataTableService',
                'FormularyExceptionRequestsDataTableService',
                'PhysicianChargesDataTableService',
                'CptCodesDataTableService',
                'PriorAuthorizationsDataTableService',
                'PatientCqmDataTableService',
            ];
            return FilterSidebarController;
        }());
        common.FilterSidebarController = FilterSidebarController;
        angular.module('consolo').controller('FilterSidebarController', FilterSidebarController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var SidebarFilterController = /** @class */ (function () {
            function SidebarFilterController() {
            }
            SidebarFilterController.$inject = [];
            return SidebarFilterController;
        }());
        angular.module('consolo').controller('SidebarFilterController', SidebarFilterController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var FilterMainSearchComponent = /** @class */ (function () {
            function FilterMainSearchComponent() {
                this.bindings = {
                    apiService: '<',
                };
                this.templateUrl = '/y/templates/common/filters/generic-search/top-chip-search.html';
                this.controller = FilterMainSearchController;
            }
            return FilterMainSearchComponent;
        }());
        var FilterMainSearchController = /** @class */ (function (_super) {
            __extends(FilterMainSearchController, _super);
            function FilterMainSearchController($scope, FilterSearchService, CurrentPatient, logger, SidebarService, $mdSidenav, $mdMedia, $timeout) {
                var _this = _super.call(this, $scope) || this;
                _this.FilterSearchService = FilterSearchService;
                _this.CurrentPatient = CurrentPatient;
                _this.logger = logger;
                _this.SidebarService = SidebarService;
                _this.$mdSidenav = $mdSidenav;
                _this.$mdMedia = $mdMedia;
                _this.$timeout = $timeout;
                return _this;
            }
            FilterMainSearchController.prototype.$onInit = function () {
                if (this.CurrentPatient.isPatientPresent()) {
                    this.apiService.filters['patient_id'] = this.CurrentPatient.patient_id;
                    this.apiService.filters['patient'] = this.CurrentPatient.patient;
                }
            };
            FilterMainSearchController.prototype.removeChip = function (chip) {
                var _this = this;
                if (this.CurrentPatient.isPatientPresent() && chip.type === 'patient_id') {
                    this.logger.info('The patient filter must remain when on a patient page.');
                    this.FilterSearchService.chips.push(chip);
                    this.search();
                }
                else {
                    this.apiService.filters[chip.type] = null;
                    //selectedItem is the actual object that displays in the Filter Sidebar, so if chip.type is patient_id the
                    // the selectedItem will be patient so it knows to remove off the filter object
                    var selectedItem = this.apiService.filters.filterDisplayInformation[chip.type].selectedItem;
                    if (selectedItem) {
                        this.apiService.filters[selectedItem] = null;
                    }
                    //Used for the date_range, if you remove the chip then you need to know to remove the range_type, start and end dates
                    var selectedItems = this.apiService.filters.filterDisplayInformation[chip.type]
                        .selectedItems;
                    if (selectedItems && selectedItems.length) {
                        selectedItems.forEach(function (element) {
                            _this.apiService.filters[element] = null;
                        });
                    }
                    this.search();
                }
            };
            FilterMainSearchController.prototype.addChipToFilter = function (chip) {
                //Add the newly added chip to the apiService filter so that it correctly filters when it searches
                this.apiService.filters[chip.type] =
                    chip[this.apiService.filters.filterDisplayInformation[chip.type].lookupKey];
                //Add the object to the filters to make it show up on the sidebar
                this.apiService.filters[this.apiService.filters.filterDisplayInformation[chip.type].selectedItem] = chip;
            };
            FilterMainSearchController.prototype.addChip = function (chip) {
                this.selectedItem = null;
                this.addChipToFilter(chip);
                this.search();
            };
            FilterMainSearchController.prototype.search = function () {
                if (this.apiService.deviceSettingsPageName) {
                    this.FilterSearchService.setFilters(this.apiService.deviceSettingsPageName, consolo.deviceSettings.Settings.previous_filters, this.apiService.filters);
                }
                if (!this.SidebarService.showSidebar ||
                    !this.apiService.showFilters ||
                    this.apiService.searchOnReset) {
                    this.apiService.search();
                }
            };
            FilterMainSearchController.prototype.toggleFilters = function () {
                this.apiService.showFilters = !this.apiService.showFilters;
                if (this.apiService.showFilters) {
                    this.$mdSidenav('sidebar').open();
                    this.SidebarService.open();
                }
            };
            FilterMainSearchController.prototype.toggleSidebar = function () {
                this.apiService.showFilters = true;
                this.SidebarService.toggle();
            };
            FilterMainSearchController.prototype.resetFilters = function () {
                var _this = this;
                this.FilterSearchService.resettingFilters = true;
                var patientChip = this.FilterSearchService.chips.filter(function (chip) {
                    return chip.type === 'patient_id';
                })[0];
                this.FilterSearchService.chips = [];
                this.FilterSearchService.officeFilterServiceInstance.reset();
                this.FilterSearchService.toggles = {};
                for (var _i = 0, _a = Object.keys(this.apiService.filters); _i < _a.length; _i++) {
                    var key = _a[_i];
                    if ([
                        'applicableFilters',
                        'title',
                        'patient',
                        'patient_id',
                        'filterDisplayInformation',
                    ].indexOf(key) === -1) {
                        this.apiService.filters[key] = null;
                    }
                    else if (['patient', 'patient_id'].indexOf(key) > -1) {
                        if (this.CurrentPatient.isPatientPresent() && key === 'patient_id') {
                            if (patientChip) {
                                this.FilterSearchService.chips.push(patientChip);
                            }
                        }
                        else if (!this.CurrentPatient.isPatientPresent()) {
                            this.apiService.filters[key] = null;
                        }
                    }
                }
                this.search();
                this.$timeout(function () {
                    _this.FilterSearchService.resettingFilters = false;
                }, 1000);
                if (this.apiService.deviceSettingsPageName) {
                    this.FilterSearchService.setFilters(this.apiService.deviceSettingsPageName, consolo.deviceSettings.Settings.previous_filters, this.apiService.filters);
                }
            };
            FilterMainSearchController.$inject = [
                '$scope',
                'FilterSearchService',
                'CurrentPatient',
                'logger',
                'SidebarService',
                '$mdSidenav',
                '$mdMedia',
                '$timeout',
            ];
            return FilterMainSearchController;
        }(consolo.controllers.BaseCustomComponentController));
        angular.module('consolo').component('mainFiltersPage', new FilterMainSearchComponent());
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        var SidebarFilterSearchComponent = /** @class */ (function () {
            function SidebarFilterSearchComponent() {
                this.bindings = {
                    apiService: '<',
                };
                this.templateUrl = '/y/templates/common/filters/generic-search/filter-sidebar.html';
                this.controller = SidebarFilterSearchController;
            }
            return SidebarFilterSearchComponent;
        }());
        var SidebarFilterSearchController = /** @class */ (function (_super) {
            __extends(SidebarFilterSearchController, _super);
            function SidebarFilterSearchController($timeout, $mdPanel, //: ng.material.IPanelService typings not up to date
            CurrentPatient, FilterSearchService, $scope, ConsoloPatientSectionManagerService, SidebarService) {
                var _this = _super.call(this, $scope) || this;
                _this.$timeout = $timeout;
                _this.$mdPanel = $mdPanel;
                _this.CurrentPatient = CurrentPatient;
                _this.FilterSearchService = FilterSearchService;
                _this.ConsoloPatientSectionManagerService = ConsoloPatientSectionManagerService;
                _this.SidebarService = SidebarService;
                _this.indeterminates = { org_ids: {}, area_ids: {}, region_ids: {} };
                _this.expanded = false;
                _this.isOnline = true;
                _this.loadingFilters = false;
                _this.officeTextFilter = { name: '' };
                _this.officeSearch = false;
                _this.sectionSearch = false;
                _this.etSearch = false;
                return _this;
            }
            SidebarFilterSearchController.prototype.$onInit = function () {
                var _this = this;
                if (this.CurrentPatient.isPatientPresent()) {
                    this.SidebarService.open();
                    this.apiService.showFilters = false;
                }
                else {
                    this.SidebarService.close();
                }
                this.setFilters();
                if (this.CurrentPatient.patient_id) {
                    this.ConsoloPatientSectionManagerService.loadPermissions().then(function () {
                        _this.ConsoloPatientSectionManagerService.loadSections();
                    });
                }
            };
            SidebarFilterSearchController.prototype.focusOfficeSearch = function () {
                this.officeSearch = true;
                var e = document.getElementById('officeTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            SidebarFilterSearchController.prototype.focusETSearch = function ($event) {
                $event.stopPropagation();
                this.etSearch = true;
                this.FilterSearchService.displayToggles.expandEncounterTypes = true;
                var e = document.getElementById('etTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            SidebarFilterSearchController.prototype.focusChartSectionSearch = function ($event) {
                $event.stopPropagation();
                this.sectionSearch = true;
                this.FilterSearchService.displayToggles.expandChartTypes = true;
                var e = document.getElementById('chartSectionTextFilter');
                this.$timeout(function () {
                    e.focus();
                    e.select();
                }, 25);
            };
            SidebarFilterSearchController.prototype.getResults = function (type, searchTerm) {
                var _this = this;
                return this.FilterSearchService.isReady().then(function () {
                    return _this.FilterSearchService.filterResults(type, searchTerm);
                });
            };
            SidebarFilterSearchController.prototype.rangeTypeChange = function ($event) {
                var initialStart = new Date(this.apiService.filters.start_date);
                var initialEnd = new Date(this.apiService.filters.end_date);
                this.FilterSearchService.removeChip('date_range');
                switch (this.apiService.filters.range_type) {
                    case 'custom': //open the modal
                        this.openCalendarPanel($event);
                        break;
                    case 'all_after':
                        this.apiService.filters.end_date = new Date(new Date().toDateString());
                        this.openCalendarPanel($event, 'start');
                        break;
                    case 'all_before':
                        this.apiService.filters.start_date = new Date('01/01/2006');
                        this.openCalendarPanel($event, 'end');
                        break;
                    case 'today':
                    case 'last_7':
                    case 'last_30':
                    case 'month_to_date':
                    case 'previous_month':
                    case 'year_to_date':
                        this.apiService.filters.update_range();
                        this.FilterSearchService.addChip('date_range', this.apiService.filters.range_type, this.FilterSearchService.date_range_display[this.apiService.filters.range_type]);
                        break;
                }
                this.search();
            };
            /*
              Should be called anytime a filter/index page is loaded.  It will pull the previously stored filters and populate the sidebar
              To only search once, the loadingFilters boolean is set to true while it's going through all the keys
            */
            SidebarFilterSearchController.prototype.setFilters = function () {
                var _this = this;
                this.FilterSearchService.chips = [];
                this.loadingFilters = true;
                if (this.apiService && this.apiService.deviceSettingsPageName) {
                    this.FilterSearchService.displayToggles =
                        this.FilterSearchService.getFilters(this.apiService.deviceSettingsPageName, consolo.deviceSettings.Settings.display_toggles) || {};
                    return this.FilterSearchService.isReady()
                        .then(function () {
                        var prevFilters = _this.FilterSearchService.getFilters(_this.apiService.deviceSettingsPageName, consolo.deviceSettings.Settings.previous_filters);
                        if (prevFilters) {
                            for (var key in _this.apiService.filters.applicableFilters) {
                                //Add all filters except the Patient ID if it's not the current patient
                                if (prevFilters[key] && key !== 'patient_id') {
                                    _this.apiService.filters[key] = prevFilters[key];
                                    _this.addChip(key);
                                }
                            }
                        }
                    })
                        .finally(function () {
                        _this.setPatientFilter();
                        _this.loadingFilters = false;
                        _this.search();
                    });
                }
                else {
                    this.setPatientFilter();
                    this.loadingFilters = false;
                    this.search();
                }
            };
            SidebarFilterSearchController.prototype.setPatientFilter = function () {
                if (this.CurrentPatient.isPatientPresent()) {
                    this.apiService.filters.patient_id = this.CurrentPatient.patient_id;
                    this.apiService.filters.patient = this.CurrentPatient.patient;
                    this.FilterSearchService.addChip('patient_id', this.apiService.filters.patient_id.toString(), this.apiService.filters.patient.name);
                }
            };
            SidebarFilterSearchController.prototype.toggleDisplaySetting = function (section) {
                this.FilterSearchService.toggleDisplaySetting(section, this.apiService.deviceSettingsPageName);
            };
            SidebarFilterSearchController.prototype.toggleOffices = function (value, type) {
                var _this = this;
                this.$timeout(function () {
                    switch (type) {
                        case 'org_ids':
                            if (_this.FilterSearchService.toggles.org_ids[value]) {
                                _this.toggleCorporate(value, true);
                            }
                            else {
                                _this.toggleCorporate(value, false);
                            }
                            break;
                        case 'region_ids':
                            if (_this.FilterSearchService.toggles.region_ids[value]) {
                                _this.toggleRegion(value, true);
                            }
                            else {
                                _this.toggleRegion(value, false);
                            }
                            break;
                        case 'area_ids':
                            if (_this.FilterSearchService.toggles.area_ids[value]) {
                                _this.toggleArea(value, true);
                            }
                            else {
                                _this.toggleArea(value, false);
                            }
                            break;
                        case 'office':
                            if (_this.FilterSearchService.toggles.office_ids[value]) {
                                _this.keepOfficesInSync(value, true);
                            }
                            else {
                                _this.keepOfficesInSync(value, false);
                            }
                        default:
                            break;
                    }
                    _this.search();
                });
            };
            SidebarFilterSearchController.prototype.toggleCorporate = function (id, value) {
                for (var _i = 0, _a = this.FilterSearchService.officeFilterServiceInstance.regionOptions; _i < _a.length; _i++) {
                    var r = _a[_i];
                    if (r.parent_id === id) {
                        if (!this.FilterSearchService.toggles.region_ids) {
                            this.FilterSearchService.toggles.region_ids = {};
                        }
                        this.FilterSearchService.toggles.region_ids[r.value] = value;
                        this.toggleRegion(r.value, value);
                    }
                }
            };
            SidebarFilterSearchController.prototype.toggleRegion = function (id, value) {
                for (var _i = 0, _a = this.FilterSearchService.officeFilterServiceInstance.areaOptions; _i < _a.length; _i++) {
                    var a = _a[_i];
                    if (a.parent_id === id) {
                        if (!this.FilterSearchService.toggles.area_ids) {
                            this.FilterSearchService.toggles.area_ids = {};
                        }
                        this.FilterSearchService.toggles.area_ids[a.value] = value;
                        this.toggleArea(a.value, value);
                    }
                }
            };
            SidebarFilterSearchController.prototype.toggleArea = function (id, value) {
                for (var _i = 0, _a = this.FilterSearchService.officeFilterServiceInstance.offices; _i < _a.length; _i++) {
                    var o = _a[_i];
                    if (o.area === id) {
                        if (!this.FilterSearchService.toggles.office_ids) {
                            this.FilterSearchService.toggles.office_ids = {};
                        }
                        this.FilterSearchService.toggles.office_ids[o.id] = value;
                        this.keepOfficesInSync(o.id, value);
                    }
                }
            };
            SidebarFilterSearchController.prototype.keepOfficesInSync = function (id, value) {
                var indexOf = this.apiService.filters.office_ids.indexOf(id);
                if (value) {
                    if (indexOf === -1) {
                        this.apiService.filters.office_ids.push(id);
                    }
                }
                else if (indexOf > -1) {
                    this.apiService.filters.office_ids.splice(indexOf, 1);
                }
            };
            SidebarFilterSearchController.prototype.addChip = function (filter) {
                var _this = this;
                var filterKeyInfo = this.apiService.filters.filterDisplayInformation[filter];
                //Times that it's not necessary to use the filterDisplayInformation, like the Corporate/Region/Area selection
                if (filterKeyInfo) {
                    if (filterKeyInfo.usesLookup) {
                        var result = this.FilterSearchService.getItemFromLookup(filterKeyInfo.lookupValue, filterKeyInfo.lookupKey, this.apiService.filters[filter]);
                        if (result) {
                            this.apiService.filters[filterKeyInfo.selectedItem] = {
                                value: result[filterKeyInfo.lookupKey],
                            };
                            this.apiService.filters[filterKeyInfo.selectedItem][filterKeyInfo.displayName] =
                                result[filterKeyInfo.displayName];
                            this.FilterSearchService.addChip(filter, this.apiService.filters[filter], result[filterKeyInfo.displayName]);
                        }
                    }
                    else if (filterKeyInfo.isArray) {
                        this.FilterSearchService.removeChip(filter);
                        if (this.apiService.filters[filter].length) {
                            var displayValue = filterKeyInfo.chipDisplay + this.apiService.filters[filter].join(', ');
                            this.FilterSearchService.addChip(filter, this.apiService.filters[filter], displayValue);
                        }
                    }
                    else if (filterKeyInfo.selectValues) {
                        var description_1 = null;
                        this.FilterSearchService[filterKeyInfo.selectValues].some(function (value) {
                            if (value.id === _this.apiService.filters[filter]) {
                                description_1 = value.description;
                                return true;
                            }
                        });
                        this.FilterSearchService.addChip(filter, filterKeyInfo.chipDisplay, description_1);
                    }
                    else {
                        var displayValue = filterKeyInfo.appendValue
                            ? filterKeyInfo.chipDisplay + ': ' + this.apiService.filters[filter]
                            : filterKeyInfo.chipDisplay;
                        this.FilterSearchService.addChip(filter, displayValue, filterKeyInfo.chipDisplay);
                    }
                }
            };
            SidebarFilterSearchController.prototype.filterChange = function (filter) {
                if (this.apiService.filters[filter] ||
                    this.apiService.filters.filterDisplayInformation[filter].isArray) {
                    this.addChip(filter);
                }
                else {
                    this.FilterSearchService.removeChip(filter);
                }
                this.search();
            };
            SidebarFilterSearchController.prototype.openCalendarPanel = function ($event, show) {
                var _this = this;
                var panelPosition = this.$mdPanel
                    .newPanelPosition()
                    .relativeTo('#fromBox')
                    .addPanelPosition('offset-start', 'center');
                var panelAnimation = this.$mdPanel
                    .newPanelAnimation()
                    .openFrom('#fromBox')
                    .duration(300)
                    .closeTo('#fromBox')
                    .withAnimation('md-panel-animate-scale'); //this.$mdPanel.animation.SCALE
                var initialStart = new Date(this.apiService.filters.start_date);
                var initialEnd = new Date(this.apiService.filters.end_date);
                var config = {
                    attachTo: angular.element(document.body),
                    controller: 'CalendarPanelController',
                    controllerAs: '$ctrl',
                    position: panelPosition,
                    animation: panelAnimation,
                    targetEvent: $event,
                    hasBackdrop: true,
                    templateUrl: '/y/templates/clinical-charts/search/calendar-panel.html',
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    focusOnOpen: true,
                    locals: { show: show, filters: this.apiService.filters },
                    onCloseSuccess: function (panel, reason) {
                        _this.FilterSearchService.addChip('date_range', _this.apiService.filters.range_type, _this.FilterSearchService.date_range_display[_this.apiService.filters.range_type]);
                        if (initialStart.getTime() !== _this.apiService.filters.start_date.getTime() ||
                            initialEnd.getTime() !== _this.apiService.filters.end_date.getTime()) {
                            _this.search();
                        }
                    },
                };
                this.$mdPanel.open(config);
            };
            SidebarFilterSearchController.prototype.selectItem = function (type, item, display_name, id_field) {
                var _this = this;
                if (!id_field) {
                    id_field = 'id';
                }
                if (!item) {
                    angular.element(document.activeElement).blur();
                }
                this.$timeout(function () {
                    if (item) {
                        _this.apiService.filters[type] = item[id_field];
                        _this.FilterSearchService.addChip(type, item[id_field], item[display_name]);
                    }
                    else {
                        //Need to keep the patient_id filtered if it's on a patient page
                        if (type !== 'patient_id' || !_this.CurrentPatient.isPatientPresent()) {
                            _this.apiService.filters[type] = null;
                            _this.FilterSearchService.removeChip(type);
                        }
                    }
                    _this.search();
                });
            };
            SidebarFilterSearchController.prototype.search = function () {
                if (!this.loadingFilters &&
                    !this.apiService.loadingData &&
                    this.apiService.showFilters &&
                    !this.FilterSearchService.resettingFilters) {
                    if (this.apiService.deviceSettingsPageName) {
                        //Store the settings for the user if a key is passed in and it's not set to not
                        if (this.apiService.storePreviousFilters === undefined ||
                            this.apiService.storePreviousFilters) {
                            this.FilterSearchService.setFilters(this.apiService.deviceSettingsPageName, consolo.deviceSettings.Settings.previous_filters, this.apiService.filters);
                        }
                    }
                    if (this.SidebarService.showSidebar && this.apiService.showFilters) {
                        this.apiService.search();
                    }
                }
            };
            SidebarFilterSearchController.prototype.selectPatient = function (item) {
                if (item) {
                    this.apiService.filters.patient_id = item.id;
                    this.FilterSearchService.addChip('patient_id', item.id, item.name);
                }
                else {
                    this.apiService.filters.patient_id = null;
                    this.apiService.filters.patient = null;
                    this.FilterSearchService.removeChip('patient_id');
                }
                this.search();
            };
            SidebarFilterSearchController.prototype.toggleFilters = function () {
                this.apiService.showFilters = !this.apiService.showFilters;
            };
            SidebarFilterSearchController.prototype.reset = function () {
                this.FilterSearchService.toggles = {};
                this.apiService.reset();
            };
            SidebarFilterSearchController.$inject = [
                '$timeout',
                '$mdPanel',
                'CurrentPatient',
                'FilterSearchService',
                '$scope',
                'ConsoloPatientSectionManagerService',
                'SidebarService',
            ];
            return SidebarFilterSearchController;
        }(consolo.controllers.BaseCustomComponentController));
        angular.module('consolo').component('sidebarFilters', new SidebarFilterSearchComponent());
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PrintConfirmationController = /** @class */ (function () {
            function PrintConfirmationController(printConfirmationService, print, pdfOptions) {
                this.printConfirmationService = printConfirmationService;
                this.print = print;
                this.pdfOptions = pdfOptions;
            }
            PrintConfirmationController.prototype.doPrint = function () {
                var _a, _b;
                (_b = (_a = this).print) === null || _b === void 0 ? void 0 : _b.call(_a, this.pdfOptions || {});
                this.closeDialog();
            };
            PrintConfirmationController.prototype.closeDialog = function () {
                this.printConfirmationService.hide();
            };
            PrintConfirmationController.$inject = [
                'PrintConfirmationService',
                'print',
            ];
            return PrintConfirmationController;
        }());
        angular.module('consolo').controller('PrintConfirmationController', PrintConfirmationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var PrintConfirmationService = /** @class */ (function () {
            function PrintConfirmationService($mdDialog) {
                this.$mdDialog = $mdDialog;
            }
            PrintConfirmationService.prototype.hide = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!this.modal) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.$mdDialog.hide(this.modal)];
                            case 1:
                                _a.sent();
                                this.modal = null;
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                });
            };
            PrintConfirmationService.prototype.show = function (fn) {
                if (this.modal) {
                    return;
                }
                var url = "'/y/templates/common/printing/print-confirmation/print-confirmation.html'";
                this.modal = {
                    template: '<md-dialog flex="50" aria-label="PDF Options">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'PrintConfirmationController',
                    controllerAs: 'vm',
                    resolve: {
                        print: function () { return fn; },
                    },
                    hasBackdrop: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                };
                return this.$mdDialog.show(this.modal);
            };
            PrintConfirmationService.$inject = ['$mdDialog'];
            return PrintConfirmationService;
        }());
        services.PrintConfirmationService = PrintConfirmationService;
        angular.module('consolo').service('PrintConfirmationService', PrintConfirmationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxCarePlanComponent = /** @class */ (function () {
            function FaxCarePlanComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-care-plan.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    chartId: '<',
                    faxSelection: '<',
                    selectedItems: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxCarePlanComponentController;
            }
            return FaxCarePlanComponent;
        }());
        var FaxCarePlanComponentController = /** @class */ (function () {
            function FaxCarePlanComponentController(carePlanProblems) {
                this.carePlanProblems = carePlanProblems;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxCarePlanComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                if (!this.itemId) {
                    // Get all items to list
                    this.carePlanProblems.getAllPatientCPPs(this.patientId).then(function (cpps) {
                        _this.items = cpps.filter(function (problem) { return problem.care_plan_status !== 'Inactive'; });
                        console.log(cpps);
                        _this.isLoading = false;
                    });
                }
                else {
                    // Get data on the single item
                }
            };
            FaxCarePlanComponentController.prototype.$onChanges = function (changeObj) { };
            FaxCarePlanComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxCarePlanComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxCarePlanComponentController.$inject = ['CarePlanProblemsService'];
            return FaxCarePlanComponentController;
        }());
        angular.module('consolo').component('faxCarePlan', new FaxCarePlanComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxCertificationComponent = /** @class */ (function () {
            function FaxCertificationComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-certification.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    certificationId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxCertificationComponentController;
            }
            return FaxCertificationComponent;
        }());
        var FaxCertificationComponentController = /** @class */ (function () {
            function FaxCertificationComponentController(certifications) {
                this.certifications = certifications;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxCertificationComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                if (!this.certificationId) {
                    this.certifications.getCertifications(this.patientId).then(function (data) {
                        _this.items = data;
                        _this.isLoading = false;
                    });
                }
                else {
                    this.certifications.get(this.certificationId).then(function (cert) {
                        _this.item = cert;
                        _this.items = [cert];
                        if (!_this.isChecked(_this.certificationId)) {
                            _this.selectedItems.push(_this.certificationId);
                        }
                        _this.isLoading = false;
                    });
                }
            };
            FaxCertificationComponentController.prototype.$onChanges = function (changeObj) { };
            FaxCertificationComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxCertificationComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxCertificationComponentController.$inject = ['CertificationService'];
            return FaxCertificationComponentController;
        }());
        angular.module('consolo').component('faxCertification', new FaxCertificationComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxChartComponent = /** @class */ (function () {
            function FaxChartComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-chart.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    chartId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxChartComponentController;
            }
            return FaxChartComponent;
        }());
        var FaxChartComponentController = /** @class */ (function () {
            function FaxChartComponentController(chartUtilService, clinicalChartEntry, permissions, options) {
                this.chartUtilService = chartUtilService;
                this.clinicalChartEntry = clinicalChartEntry;
                this.permissions = permissions;
                this.options = options;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
                this.base_roles = this.options.get('base_roles');
            }
            FaxChartComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                this.getServerCharts(this.patientId)
                    .then(function (items) {
                    if (_this.itemId) {
                        if (items.length > 0) {
                            _this.items = items.filter(function (x) { return x.id === _this.itemId; });
                            _this.selectedItems = [_this.items[0].id];
                        }
                        else {
                            _this.errorMessage = "Chart " + _this.itemId + " not found.";
                        }
                    }
                    else {
                        _this.items = items;
                    }
                    _this.isLoading = false;
                })
                    .catch(function () { });
                // if (!this.itemId) {
                //   this.getServerCharts(this.patientId).catch((e) => {
                //   }).finally(() => {
                //     this.isLoading = false;
                //   });
                //   // Get all charts to list
                // } else {
                //   // Get data on the single chart
                //   this.getServerCharts(this.patientId);
                // }
            };
            FaxChartComponentController.prototype.$onChanges = function (changeObj) {
                // if (changeObj.patientId && changeObj.patientId.currentValue) {
                //   this.FaxGenerator.init(this.patientId, this.faxSelection, this.pdfer.enabled);
                //   console.log('fax-options changes');
                // }
            };
            FaxChartComponentController.prototype.getMoreCharts = function () {
                var _this = this;
                this.getServerCharts(this.patientId).then(function (items) {
                    _this.isLoading = false;
                    if (!_this.itemId) {
                        _this.items = items;
                        return;
                    }
                    if (items.length > 0) {
                        _this.items = items.filter(function (x) { return x.id === _this.itemId; });
                        _this.selectedItems = [_this.items[0].id];
                    }
                    else {
                        _this.errorMessage = "Chart " + _this.itemId + " not found.";
                    }
                })
                    .catch(function (_err) { });
            };
            FaxChartComponentController.prototype.getServerCharts = function (patientId) {
                var _this = this;
                var page = this.currentPage.page + 1;
                return this.clinicalChartEntry.getHeadersByPatientId(patientId, page, 50).$promise.then(function (data) {
                    _this.errorMessage = 'No previously synced chart entries exist.';
                    _this.base_roles.$promise.then(function () {
                        for (var _i = 0, _a = data.items; _i < _a.length; _i++) {
                            var chart = _a[_i];
                            chart.editable = _this.chartUtilService.checkChartPermissions(chart);
                            chart.canCreateAddendum = _this.permissions.can('create', 'addendums');
                            _this.chartUtilService.getClientName(chart);
                        }
                    });
                    _this.currentPage.page = data.page;
                    _this.currentPage.totalPages = data.pages;
                    _this.currentPage.items = __spreadArrays(_this.currentPage.items, data.items);
                    return _this.currentPage.items;
                }, function (error) {
                    _this.errorMessage = 'Existing chart entries unavailable';
                    return false;
                });
            };
            FaxChartComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxChartComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxChartComponentController.$inject = ['ChartUtilService', 'ClinicalChartEntry', 'PermissionsService', 'options'];
            return FaxChartComponentController;
        }());
        angular.module('consolo').component('faxChart', new FaxChartComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxDischargeSummaryComponent = /** @class */ (function () {
            function FaxDischargeSummaryComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-discharge-summary.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    dischargeSummaryId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxDischargeSummaryComponentController;
            }
            return FaxDischargeSummaryComponent;
        }());
        var FaxDischargeSummaryComponentController = /** @class */ (function () {
            function FaxDischargeSummaryComponentController(dischargeSummary, stateParams) {
                this.dischargeSummary = dischargeSummary;
                this.stateParams = stateParams;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxDischargeSummaryComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.dischargeSummaryId = this.stateParams.dischargeSummaryId;
                this.isLoading = true;
                if (!this.dischargeSummaryId) {
                    this.dischargeSummary.getSummaries(this.patientId).$promise.then(function (data) {
                        _this.items = data;
                        _this.isLoading = false;
                    });
                }
                else {
                    this.dischargeSummary
                        .getById(this.patientId, this.dischargeSummaryId)
                        .$promise.then(function (data) {
                        _this.items = [data];
                        _this.item = data;
                        if (!_this.isChecked(_this.dischargeSummaryId)) {
                            _this.selectedItems.push(_this.dischargeSummaryId);
                        }
                        _this.isLoading = false;
                    });
                }
            };
            FaxDischargeSummaryComponentController.prototype.$onChanges = function (changeObj) { };
            FaxDischargeSummaryComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxDischargeSummaryComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxDischargeSummaryComponentController.$inject = ['DischargeSummary', '$stateParams'];
            return FaxDischargeSummaryComponentController;
        }());
        angular.module('consolo').component('faxDischargeSummary', new FaxDischargeSummaryComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxDmeOrderComponent = /** @class */ (function () {
            function FaxDmeOrderComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-dme-order.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    dmeOrderId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxDmeOrderComponentController;
            }
            return FaxDmeOrderComponent;
        }());
        var FaxDmeOrderComponentController = /** @class */ (function () {
            function FaxDmeOrderComponentController(patientFactory) {
                this.patientFactory = patientFactory;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxDmeOrderComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                this.patientFactory.getDmeOrders(this.patientId).$promise.then(function (resp) {
                    if (_this.itemId) {
                        for (var _i = 0, resp_1 = resp; _i < resp_1.length; _i++) {
                            var i = resp_1[_i];
                            if (i.id === _this.itemId) {
                                _this.selectedItems = [i];
                                _this.item = i;
                            }
                        }
                    }
                    else {
                        _this.items = resp;
                    }
                    _this.isLoading = false;
                });
            };
            FaxDmeOrderComponentController.prototype.$onChanges = function (changeObj) { };
            FaxDmeOrderComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxDmeOrderComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxDmeOrderComponentController.$inject = ['Patient'];
            return FaxDmeOrderComponentController;
        }());
        angular.module('consolo').component('faxDmeOrder', new FaxDmeOrderComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var FaxGeneratorService = /** @class */ (function () {
            function FaxGeneratorService($http, logger, $mdDialog, pdfer) {
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.pdfer = pdfer;
            }
            //id -  "Patient ID" - Required
            //certifications - "Array of clinical chart IDs"
            //clinical_charts - "Array of clinical chart IDs"
            //physician_orders - "Array of Physician Order IDs"
            //medications - "Array of Medication IDs"
            //care_plan_problems - "Array of Care Plan Problem IDs"
            //idg_snapshots - "Array of IDG Snapshot IDs"
            //dme_orders - "Array of DME Order IDs"
            //pdf_options - "PDF Options on what to fax"
            //unrelated_explanations - Array of Unrelated Explanation
            FaxGeneratorService.prototype.init = function (patientId, params) {
                this.faxSelection = params;
                this.faxSelection.pdfOptions = params.pdf_options || {};
                this.getPatientFaxInformation(patientId);
                this.patientId = patientId;
                if (!this.patientFaxInfo || this.patientFaxInfo.patient_id !== patientId) {
                    this.getPatientFaxInformation(patientId);
                }
            };
            FaxGeneratorService.prototype.fax = function (patientId, params) {
                this.init(patientId, params);
                return this.show();
            };
            FaxGeneratorService.prototype.serviceLookup = function (q) {
                return this.$http.get('/api/v1/lookup/fax_compliant_services?q=' + q).then(function (resp) {
                    return resp.data;
                });
            };
            FaxGeneratorService.prototype.getPatientFaxInformation = function (patientId) {
                var _this = this;
                if (!this.faxSelection) {
                    this.faxSelection = {};
                }
                this.patientId = patientId;
                this.faxSelection.id = patientId;
                this.$http
                    .get('/api/v1/patients/' + patientId + '/fax_information')
                    .then(function (patientFaxInformation) {
                    _this.patientFaxInfo = patientFaxInformation.data;
                });
            };
            FaxGeneratorService.prototype.setFaxType = function (item) {
                this.faxSelection.faxable_type = item.type;
            };
            FaxGeneratorService.prototype.sendFax = function () {
                var _this = this;
                if (!this.faxSelection.id) {
                    this.faxSelection.id = this.patientId;
                }
                if (this.pdfer.enabled && this.pdfer.validV2(this.faxSelection)) {
                    this.pdfer.generatePdfFromDocumentList(this.patientId, this.faxSelection, this.faxSelection.pdfOptions, this.faxSelection, true);
                    return this.hide(this.faxSelection);
                }
                else {
                    this.$http.post('/api/v1/fax', this.faxSelection).then(function () {
                        _this.logger.info('Your Fax is being generated and you will be notified when it is complete.');
                        _this.faxSelection = {};
                    });
                    this.hide();
                }
            };
            FaxGeneratorService.prototype.show = function () {
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/common/services/fax-generator/fax-options.html',
                    controller: 'FaxOptionsController as vm',
                    hasBackdrop: true,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                })
                    .then(function (params) { return params; });
            };
            FaxGeneratorService.prototype.hide = function (params) {
                this.$mdDialog.hide(params);
            };
            FaxGeneratorService.$inject = ['$http', 'logger', '$mdDialog', 'pdfer'];
            return FaxGeneratorService;
        }());
        services.FaxGeneratorService = FaxGeneratorService;
        angular.module('consolo').service('FaxGenerator', FaxGeneratorService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxIdgSummaryComponent = /** @class */ (function () {
            function FaxIdgSummaryComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-idg-summary.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    idgSummaryId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxIdgSummaryComponentController;
            }
            return FaxIdgSummaryComponent;
        }());
        var FaxIdgSummaryComponentController = /** @class */ (function () {
            function FaxIdgSummaryComponentController(patientFactory) {
                this.patientFactory = patientFactory;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxIdgSummaryComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                this.patientFactory.getIdgSummaryHeaders(this.patientId).$promise.then(function (resp) {
                    if (_this.itemId) {
                        for (var _i = 0, resp_1 = resp; _i < resp_1.length; _i++) {
                            var i = resp_1[_i];
                            if (i.id === _this.itemId) {
                                _this.selectedItems = [i];
                                _this.item = i;
                            }
                        }
                    }
                    else {
                        _this.items = resp;
                    }
                    _this.isLoading = false;
                });
            };
            FaxIdgSummaryComponentController.prototype.$onChanges = function (changeObj) { };
            FaxIdgSummaryComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxIdgSummaryComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxIdgSummaryComponentController.$inject = ['Patient'];
            return FaxIdgSummaryComponentController;
        }());
        angular.module('consolo').component('faxIdgSummary', new FaxIdgSummaryComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxOptionsComponent = /** @class */ (function () {
            function FaxOptionsComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-options.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '=',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxOptionsComponentController;
            }
            return FaxOptionsComponent;
        }());
        var FaxOptionsComponentController = /** @class */ (function () {
            function FaxOptionsComponentController(FaxGenerator, pdfer) {
                this.FaxGenerator = FaxGenerator;
                this.pdfer = pdfer;
            }
            FaxOptionsComponentController.prototype.$onInit = function () {
                if (this.patientId) {
                    this.FaxGenerator.init(this.patientId, this.faxSelection);
                }
                this.pdfOptions = this.pdfOptions || {};
            };
            FaxOptionsComponentController.prototype.$onChanges = function (changeObj) {
                if (changeObj.patientId && changeObj.patientId.currentValue) {
                    this.FaxGenerator.init(this.patientId, this.faxSelection);
                    console.log('fax-options changes');
                }
            };
            FaxOptionsComponentController.$inject = ['FaxGenerator', 'pdfer'];
            return FaxOptionsComponentController;
        }());
        angular.module('consolo').component('faxOptions', new FaxOptionsComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var FaxOptionsController = /** @class */ (function () {
            function FaxOptionsController(FaxGenerator, pdfer) {
                this.FaxGenerator = FaxGenerator;
                this.pdfer = pdfer;
            }
            FaxOptionsController.prototype.sendFax = function () {
                this.FaxGenerator.sendFax();
            };
            FaxOptionsController.prototype.closeDialog = function () {
                this.FaxGenerator.hide();
            };
            FaxOptionsController.$inject = ['FaxGenerator', 'pdfer'];
            return FaxOptionsController;
        }());
        angular.module('consolo').controller('FaxOptionsController', FaxOptionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxPatientDocumentComponent = /** @class */ (function () {
            function FaxPatientDocumentComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-patient-documents.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    patientDocumentId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxPatientDocumentComponentController;
            }
            return FaxPatientDocumentComponent;
        }());
        var FaxPatientDocumentComponentController = /** @class */ (function () {
            function FaxPatientDocumentComponentController(patientFactory, $http) {
                this.patientFactory = patientFactory;
                this.$http = $http;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxPatientDocumentComponentController.prototype.$onInit = function () {
                this.selectedItems = this.selectedItems || [];
                this.loadPage();
            };
            FaxPatientDocumentComponentController.prototype.loadPage = function (n) {
                var _this = this;
                if (n === void 0) { n = 1; }
                this.isLoading = true;
                this.$http
                    .get("/api/v1/patient_documents?patient_id=" + this.patientId + "&page_size=50&page=" + n + "&faxable=true")
                    .then(function (resp) {
                    if (_this.itemId) {
                        for (var _i = 0, resp_1 = resp; _i < resp_1.length; _i++) {
                            var i = resp_1[_i];
                            if (i.id === _this.itemId) {
                                _this.selectedItems = [i];
                                _this.item = i;
                            }
                        }
                    }
                    else {
                        _this.items = resp.data;
                    }
                    _this.currentPage = {
                        page: parseInt(resp.headers('X-Page'), 10),
                        totalPages: parseInt(resp.headers('X-Total-Pages'), 10),
                        items: resp.data,
                    };
                    _this.isLoading = false;
                });
            };
            FaxPatientDocumentComponentController.prototype.$onChanges = function (changeObj) { };
            FaxPatientDocumentComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxPatientDocumentComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxPatientDocumentComponentController.$inject = ['Patient', '$http'];
            return FaxPatientDocumentComponentController;
        }());
        angular.module('consolo').component('faxPatientDocument', new FaxPatientDocumentComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxPatientInformationComponent = /** @class */ (function () {
            function FaxPatientInformationComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-patient-information.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    selectedMedications: '=',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxPatientInformationComponentController;
            }
            return FaxPatientInformationComponent;
        }());
        var FaxPatientInformationComponentController = /** @class */ (function () {
            function FaxPatientInformationComponentController(permissions, $http, medication, $timeout) {
                this.permissions = permissions;
                this.$http = $http;
                this.medication = medication;
                this.$timeout = $timeout;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
                this.showPermissions = {};
                this.medications = [];
                this.medicationsLoaded = false;
                this.interactionSignature = 'none';
            }
            FaxPatientInformationComponentController.prototype.$onInit = function () {
                this.selectedMedications = this.selectedMedications || [];
                this.showPermissions.showFaceSheet = this.permissions.can('referral_admission_sheet', 'patients');
                this.showPermissions.showMedications = this.permissions.can('show', 'medications');
                this.showPermissions.showProblemList = this.permissions.can('show', 'problem_list_items');
            };
            FaxPatientInformationComponentController.prototype.$onChanges = function (changeObj) { };
            FaxPatientInformationComponentController.prototype.toggleFaceSheet = function () {
                var _this = this;
                this.$timeout(function () {
                    _this.pdfOptions.field_use = _this.pdfOptions.include_face_sheet;
                });
            };
            FaxPatientInformationComponentController.prototype.toggleMedicationChoice = function () {
                if (this.showMedicationChoice) { // reversed; this happens before var update
                    this.medicationListType = null;
                    delete this.pdfOptions.rph_signature;
                    delete this.pdfOptions.physician_signature;
                    this.interactionSignature = 'none';
                    this.selectedMedications = [];
                }
                this.loadMedications();
                this.changeInteractionSignature();
                this.changeMedicationListType();
            };
            FaxPatientInformationComponentController.prototype.loadMedications = function () {
                var _this = this;
                if (!this.medicationsLoaded) {
                    this.isLoading = true;
                    this.medication.loadActiveByPatientId(this.patientId).$promise.then(function (data) {
                        _this.isLoading = false;
                        _this.medicationsLoaded = true;
                        _this.medications = data;
                    });
                }
            };
            FaxPatientInformationComponentController.prototype.isChecked = function (id, list) {
                return consolo.common.isChecked(id, list);
            };
            FaxPatientInformationComponentController.prototype.toggle = function (id, list, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, list);
            };
            FaxPatientInformationComponentController.prototype.toggleAll = function ($event) {
                var _this = this;
                $event.stopPropagation();
                this.medications.forEach(function (med) {
                    if (!_this.isChecked(med.id, _this.selectedMedications)) {
                        consolo.common.toggle(med.id, _this.selectedMedications);
                    }
                });
            };
            FaxPatientInformationComponentController.prototype.unToggleAll = function ($event) {
                var _this = this;
                $event.stopPropagation();
                this.medications.forEach(function (med) {
                    if (_this.isChecked(med.id, _this.selectedMedications)) {
                        consolo.common.toggle(med.id, _this.selectedMedications);
                    }
                });
            };
            FaxPatientInformationComponentController.prototype.changeInteractionSignature = function () {
                if (this.interactionSignature === 'rph') {
                    delete this.pdfOptions.physician_signature;
                    this.pdfOptions.rph_signature = true;
                }
                else if (this.interactionSignature === 'physician') {
                    delete this.pdfOptions.rph_signature;
                    this.pdfOptions.physician_signature = true;
                }
                else {
                    delete this.pdfOptions.rph_signature;
                    delete this.pdfOptions.physician_signature;
                }
            };
            FaxPatientInformationComponentController.prototype.changeMedicationListType = function () {
                switch (this.medicationListType) {
                    case "long":
                        delete this.pdfOptions.include_short_medications;
                        this.pdfOptions.include_medications = "1";
                        break;
                    case "short":
                        delete this.pdfOptions.include_medications;
                        this.pdfOptions.include_short_medications = "1";
                        break;
                    default:
                        delete this.pdfOptions.include_short_medications;
                        delete this.pdfOptions.include_medications;
                        break;
                }
            };
            FaxPatientInformationComponentController.$inject = ['PermissionsService', '$http', 'Medication', '$timeout'];
            return FaxPatientInformationComponentController;
        }());
        angular
            .module('consolo')
            .component('faxPatientInformation', new FaxPatientInformationComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxPhysicianOrderComponent = /** @class */ (function () {
            function FaxPhysicianOrderComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-physician-order.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    physicianOrderId: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                    selectedItems: '<',
                };
                this.controller = FaxPhysicianOrderComponentController;
            }
            return FaxPhysicianOrderComponent;
        }());
        var FaxPhysicianOrderComponentController = /** @class */ (function () {
            function FaxPhysicianOrderComponentController(physicianOrders) {
                this.physicianOrders = physicianOrders;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxPhysicianOrderComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                if (!this.physicianOrderId) {
                    this.physicianOrders.loadByPatientId(this.patientId).$promise.then(function (data) {
                        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
                            var po = data_1[_i];
                            po.medicationDisplay = [];
                            var _loop_1 = function (mt) {
                                var string = (mt.medication.name + ' ' + mt.medication.frequency_for_display);
                                var index = po.medicationDisplay.findIndex(function (e) { return e === string; });
                                if (index === -1) {
                                    po.medicationDisplay.push(string);
                                }
                                else {
                                    po.medicationDisplay.push(string + ' ');
                                }
                            };
                            for (var _a = 0, _b = po.medication_transactions; _a < _b.length; _a++) {
                                var mt = _b[_a];
                                _loop_1(mt);
                            }
                        }
                        _this.optimizeData(data);
                    });
                }
                else {
                    this.physicianOrders.getById(this.physicianOrderId).$promise.then(function (data) {
                        data.medicationDisplay = [];
                        for (var _i = 0, data_2 = data; _i < data_2.length; _i++) {
                            var po = data_2[_i];
                            po.medicationDisplay = [];
                            var _loop_2 = function (mt) {
                                var string = mt.medication.name + ' ' + mt.medication.frequency_for_display;
                                var index = po.medicationDisplay.findIndex(function (e) { return e === string; });
                                if (index === -1) {
                                    po.medicationDisplay.push(string);
                                }
                                else {
                                    po.medicationDisplay.push(string + ' ');
                                }
                            };
                            for (var _a = 0, _b = po.medication_transactions; _a < _b.length; _a++) {
                                var mt = _b[_a];
                                _loop_2(mt);
                            }
                        }
                        _this.optimizeData([data]);
                        _this.item = data;
                        if (!_this.isChecked(_this.physicianOrderId)) {
                            _this.selectedItems.push(_this.physicianOrderId);
                        }
                        _this.isLoading = false;
                    });
                }
            };
            FaxPhysicianOrderComponentController.prototype.optimizeData = function (items) {
                if (items && items.length) {
                    items.forEach(function (element, index) {
                        if (element.medicationDisplay && element.medicationDisplay.length) {
                            var verticalHeight = (element.medicationDisplay.length * 4) + 30;
                            items[index]['style'] = {
                                'height': verticalHeight + 'vh',
                            };
                        }
                        else {
                            items[index]['style'] = {
                                'height': '5vh',
                            };
                        }
                    });
                    this.items = items;
                    console.log('Items : ' + JSON.stringify(this.items));
                }
                this.isLoading = false;
            };
            FaxPhysicianOrderComponentController.prototype.$onChanges = function (changeObj) { };
            FaxPhysicianOrderComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxPhysicianOrderComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxPhysicianOrderComponentController.$inject = ['PhysicianOrderEntry'];
            return FaxPhysicianOrderComponentController;
        }());
        angular.module('consolo').component('faxPhysicianOrder', new FaxPhysicianOrderComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var FaxPocComponent = /** @class */ (function () {
            function FaxPocComponent() {
                this.templateUrl = '/y/templates/common/services/fax-generator/fax-poc.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    chartId: '<',
                    selectedItems: '<',
                    faxSelection: '<',
                    pdfOptions: '<',
                };
                this.controller = FaxPocComponentController;
            }
            return FaxPocComponent;
        }());
        var FaxPocComponentController = /** @class */ (function () {
            function FaxPocComponentController(pocService) {
                this.pocService = pocService;
                this.isLoading = false;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
            }
            FaxPocComponentController.prototype.$onInit = function () {
                var _this = this;
                this.selectedItems = this.selectedItems || [];
                this.isLoading = true;
                this.pocService.paginated({ patient_id: this.patientId }).then(function (data) {
                    _this.items = data;
                    _this.isLoading = false;
                });
                if (!this.itemId) {
                    // Get all items to list
                }
                else {
                    // Get data on the single item
                }
            };
            FaxPocComponentController.prototype.$onChanges = function (changeObj) { };
            FaxPocComponentController.prototype.isChecked = function (id) {
                return consolo.common.isChecked(id, this.selectedItems);
            };
            FaxPocComponentController.prototype.toggle = function (id, $event) {
                $event.stopPropagation();
                consolo.common.toggle(id, this.selectedItems);
            };
            FaxPocComponentController.$inject = ['PlanOfCareService', 'options'];
            return FaxPocComponentController;
        }());
        angular.module('consolo').component('faxPoc', new FaxPocComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('fileUploader', {
            bindings: {
                uploadPath: '<',
                csvFields: '<',
                title: '<',
                hideImportButton: '<',
                showImport: '@',
            },
            templateUrl: '/y/templates/common/services/file-upload/file-upload.html',
            controller: 'FileUploaderController as $ctrl',
        });
        var FileUploaderController = /** @class */ (function () {
            function FileUploaderController(S3UploadFactory, UploadQueue, $timeout, logger, CSV, $http) {
                this.S3UploadFactory = S3UploadFactory;
                this.UploadQueue = UploadQueue;
                this.$timeout = $timeout;
                this.logger = logger;
                this.CSV = CSV;
                this.$http = $http;
                this.saving = false;
                this.errors = [];
                this.completeStatus = consolo.uploads.UploadState.Complete;
            }
            FileUploaderController.prototype.$onInit = function () { };
            FileUploaderController.prototype.handleFiles = function (files) {
                var _this = this;
                files.forEach(function (file) {
                    // TODO throw warning if file too large?
                    var upload = _this.S3UploadFactory.build(file);
                    _this.UploadQueue.push(upload);
                    _this.upload = upload;
                });
            };
            FileUploaderController.prototype.csvTemplate = function () {
                if (this.csvFields) {
                    var csv = 'data:text/csv;charset-utf-8,' + this.CSV.generate([], this.csvFields);
                    var data = encodeURI(csv);
                    var link = document.createElement('a');
                    link.setAttribute('href', data);
                    link.setAttribute('download', this.title + '.csv');
                    link.click();
                }
            };
            FileUploaderController.prototype.import = function () {
                var _this = this;
                if (this.saving) {
                    return;
                }
                else {
                    this.saving = true;
                    this.errors = null;
                    this.upload.promise.then(function () {
                        var fileKey = _this.upload.s3Key;
                        _this.$http
                            .post(_this.uploadPath, { s3_tmp_key: fileKey })
                            .then(function (resp) {
                            _this.logger.success('Your import has been queued up to import.  You will receive a notification when complete.');
                            _this.showImport = false;
                        }, function (resp) {
                            _this.logger.error(resp.data.errors.join(', '));
                        })
                            .finally(function () {
                            _this.upload = null;
                            _this.saving = false;
                        });
                    });
                }
            };
            FileUploaderController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            FileUploaderController.$inject = ['S3UploadFactory', 'UploadQueue', '$timeout', 'logger', 'CSV', '$http'];
            return FileUploaderController;
        }());
        common.FileUploaderController = FileUploaderController;
        angular.module('consolo').controller('FileUploaderController', FileUploaderController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var PdfGeneratorService = /** @class */ (function () {
            function PdfGeneratorService($http, logger, $q, $mdDialog, pdfer) {
                this.$http = $http;
                this.logger = logger;
                this.$q = $q;
                this.$mdDialog = $mdDialog;
                this.pdfer = pdfer;
                this.showAllOptions = true;
            }
            PdfGeneratorService.prototype.printClinicalCharts = function (clinical_chart_ids) {
                this.showAllOptions = true;
                this.params = { clinical_charts: clinical_chart_ids };
                this.show();
            };
            PdfGeneratorService.prototype.printMedicationProfile = function (patient_id, medicationIds) {
                this.showAllOptions = true;
                this.params = { medication_profile: patient_id };
                if (medicationIds) {
                    this.params['medication_ids'] = medicationIds;
                }
                this.show();
            };
            PdfGeneratorService.prototype.printDischargeSummaries = function (discharge_summary_ids, patientId) {
                this.params = { discharge_summaries: discharge_summary_ids };
                this.createPdf({}, patientId);
            };
            PdfGeneratorService.prototype.printPlanOfCares = function (plan_of_care_ids) {
                this.showAllOptions = false;
                this.pdfOptionsToShow = {
                    include_last_page_signature_line: true,
                    include_footer_signature_lines: true,
                };
                this.params = { plan_of_cares: plan_of_care_ids };
                this.show();
            };
            PdfGeneratorService.prototype.printMissedVisit = function (missed_visit_id, patientId) {
                this.params = { missed_visit: missed_visit_id };
                this.createPdf({}, patientId);
            };
            PdfGeneratorService.prototype.printPhysicianOrders = function (physicianOrderIdSets) {
                this.params = { physician_orders: physicianOrderIdSets };
                this.show(); // PDF options modal required
            };
            // To print multiple things at once, create a hash of the objects you want to print.
            //  clinical_charts,            type: Array[Integer], desc: "Array of clinical chart IDs"
            //  discharge_summaries,        type: Array[Integer], desc: "Array of discharge_summary IDs"
            //  physician_orders,           type: Array[Integer], desc: "Array of Physician Order IDs"
            //  medication_profile,         type: Integer, desc: "Patient ID" - Prints all Medications for patient
            //  missed_visit,               type: Integer, desc: "Missed Visit ID"
            //  med_education_sheets,       type: Integer, desc: "Patient ID"
            //  patient_face_sheet,         type: Integer, desc: "Patient ID"
            //  patient_cc_tracking_sheet, type: Integer, desc: "Patient ID for a Continuous Care Tracking Sheet"
            //  generic_cc_tracking_sheet, type: Boolean, desc: "true for a generic, blank CC Tracking Sheet"
            PdfGeneratorService.prototype.print = function (params) {
                this.showAllOptions = true;
                this.params = params;
                this.show();
            };
            PdfGeneratorService.prototype.printWithoutOptions = function (params, patientId) {
                this.params = params;
                this.createPdf({}, patientId);
            };
            PdfGeneratorService.prototype.createPdf = function (pdfOptions, patientId) {
                var _this = this;
                if (this.pdfer.enabled && this.pdfer.validV2(this.params)) {
                    var params = { pdf_options: pdfOptions };
                    this.pdfer.generatePdfFromDocumentList(patientId, this.params, pdfOptions);
                }
                else {
                    this.params.pdf_options = pdfOptions;
                    this.$http.post('/api/v1/pdf', this.params).then(function () {
                        _this.logger.info('Your PDF is being generated and you will be notified when it is complete.');
                    });
                }
                this.hide();
            };
            PdfGeneratorService.prototype.hide = function () {
                var _this = this;
                if (this.modal) {
                    return this.$mdDialog.hide(this.modal).then(function () {
                        _this.modal = null;
                    });
                }
                else {
                    return this.$q.when(true);
                }
            };
            PdfGeneratorService.prototype.show = function () {
                if (this.modal) {
                    return;
                }
                var url = "'/y/templates/common/services/pdf-generator/pdf-options.html'";
                this.modal = {
                    template: '<md-dialog flex="50" aria-label="PDF Options">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'PdfOptionsController',
                    controllerAs: 'vm',
                    hasBackdrop: true,
                    clickOutsideToClose: false,
                    escapeToClose: false,
                };
                return this.$mdDialog.show(this.modal);
            };
            PdfGeneratorService.$inject = ['$http', 'logger', '$q', '$mdDialog', 'pdfer'];
            return PdfGeneratorService;
        }());
        services.PdfGeneratorService = PdfGeneratorService;
        angular.module('consolo').service('PdfGenerator', PdfGeneratorService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var PDFOptionsComponent = /** @class */ (function () {
            function PDFOptionsComponent() {
                this.templateUrl = '/y/templates/common/services/pdf-generator/pdf-options.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    pdfOptions: '=',
                };
                this.controller = PDFOptionsComponentController;
            }
            return PDFOptionsComponent;
        }());
        var PDFOptionsComponentController = /** @class */ (function () {
            function PDFOptionsComponentController(PdfGenerator) {
                this.PdfGenerator = PdfGenerator;
            }
            PDFOptionsComponentController.prototype.$onInit = function () {
                this.pdfOptions = this.pdfOptions || {};
            };
            PDFOptionsComponentController.$inject = ['PdfGenerator'];
            return PDFOptionsComponentController;
        }());
        angular.module('consolo').component('pdfOptions', new PDFOptionsComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PdfOptionsController = /** @class */ (function () {
            function PdfOptionsController(PdfGenerator, CurrentPatient, $stateParams) {
                this.PdfGenerator = PdfGenerator;
                this.CurrentPatient = CurrentPatient;
                this.$stateParams = $stateParams;
            }
            PdfOptionsController.prototype.print = function () {
                var _a, _b;
                var patient = this.$stateParams.patient_id || this.$stateParams.patientId ? (_b = (_a = this.CurrentPatient) === null || _a === void 0 ? void 0 : _a.patient) === null || _b === void 0 ? void 0 : _b.id : null;
                this.PdfGenerator.createPdf(this.pdf_options, patient);
            };
            PdfOptionsController.prototype.closeDialog = function () {
                this.PdfGenerator.hide();
            };
            PdfOptionsController.$inject = ['PdfGenerator', 'CurrentPatient', '$stateParams'];
            return PdfOptionsController;
        }());
        angular.module('consolo').controller('PdfOptionsController', PdfOptionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        'use strict';
        var RphSignatureComponent = /** @class */ (function () {
            function RphSignatureComponent() {
                this.templateUrl = '/y/templates/common/services/pdf-generator/rph-signature.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    rphSignature: '<',
                    physicianSignature: '<',
                };
                this.controller = RphSignatureComponentController;
            }
            return RphSignatureComponent;
        }());
        var RphSignatureComponentController = /** @class */ (function () {
            function RphSignatureComponentController() {
            }
            RphSignatureComponentController.prototype.$onInit = function () {
                if (typeof this.rphSignature !== 'boolean') {
                    this.rphSignature =
                        this.rphSignature === 'true' || this.rphSignature === '1' || this.rphSignature === 1;
                }
                if (typeof this.physicianSignature !== 'boolean') {
                    this.physicianSignature =
                        this.physicianSignature === 'true' ||
                            this.physicianSignature === '1' ||
                            this.physicianSignature === 1;
                }
            };
            RphSignatureComponentController.$inject = [];
            return RphSignatureComponentController;
        }());
        angular.module('consolo').component('rphSignature', new RphSignatureComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('condensedPharmacy', {
            bindings: { model: '<', primaryPharmacy: '<', showDefault: '<' },
            templateUrl: '/y/templates/common/services/pharmacy-search/condensed-display.html',
            controller: 'CondensedPharmacyController as $ctrl',
        });
        var CondensedPharmacyController = /** @class */ (function () {
            function CondensedPharmacyController(PharmacyService, $mdDialog, CurrentPatient) {
                var _this = this;
                this.PharmacyService = PharmacyService;
                this.$mdDialog = $mdDialog;
                this.CurrentPatient = CurrentPatient;
                if (this.model === undefined && this.PharmacyService.model) {
                    this.model = this.PharmacyService.model;
                }
                if (this.primaryPharmacy === undefined) {
                    this.primaryPharmacy = true;
                }
                if (this.showDefault === undefined) {
                    this.showDefault = true;
                }
                this.defaultPharmacies = [];
                [
                    'pharmacy',
                    'surescripts_pharmacy',
                    'secondary_pharmacy',
                    'secondary_surescripts_pharmacy',
                ].forEach(function (pharmacyName) {
                    if (_this.CurrentPatient.patient[pharmacyName]) {
                        _this.defaultPharmacies.push(_this.CurrentPatient.patient[pharmacyName]);
                    }
                });
            }
            CondensedPharmacyController.prototype.$onChanges = function (changes) {
                if (changes.model && changes.model.currentValue) {
                    this.model = changes.model.currentValue;
                }
            };
            CondensedPharmacyController.prototype.show = function (pharmacySelection) {
                var _this = this;
                this.PharmacyService.pharmacySearch = pharmacySelection;
                return this.$mdDialog
                    .show({
                    template: "\n          <md-dialog flex=\"80\" aria-label=\"Pharmacy\">\n            <md-dialog-content>\n              <pharmacy-modal-search ></pharmacy-modal-search>\n            </md-dialog-content>\n          </md-dialog>\n        ",
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                })
                    .then(function (pharmacy) {
                    if (pharmacySelection === 'consolo') {
                        _this.setConsoloPharmacy(pharmacy);
                    }
                    else {
                        _this.setSurescriptsPharmacy(pharmacy);
                    }
                });
            };
            CondensedPharmacyController.prototype.setPharmacy = function () {
                if (this.defaultPharmacy && typeof this.defaultPharmacy.id === 'number') {
                    this.setConsoloPharmacy(this.defaultPharmacy);
                    this.defaultPharmacy = null;
                }
                else {
                    this.setSurescriptsPharmacy(this.defaultPharmacy);
                    this.defaultPharmacy = null;
                }
            };
            CondensedPharmacyController.prototype.setSurescriptsPharmacy = function (pharmacy) {
                if (this.primaryPharmacy) {
                    this.model.pharmacy = null;
                    this.model.pharmacy_id = null;
                    this.model.surescripts_pharmacy = pharmacy;
                    this.model.surescripts_pharmacy_id = pharmacy.id;
                }
                else {
                    this.model.secondary_pharmacy = null;
                    this.model.secondary_pharmacy_id = null;
                    this.model.secondary_surescripts_pharmacy = pharmacy;
                    this.model.secondary_surescripts_pharmacy_id = pharmacy.id;
                }
            };
            CondensedPharmacyController.prototype.setConsoloPharmacy = function (pharmacy) {
                if (this.primaryPharmacy) {
                    this.model.pharmacy = pharmacy;
                    this.model.pharmacy_id = pharmacy.id;
                    this.model.surescripts_pharmacy = null;
                    this.model.surescripts_pharmacy_id = null;
                }
                else {
                    this.model.secondary_pharmacy = pharmacy;
                    this.model.secondary_pharmacy_id = pharmacy.id;
                    this.model.secondary_surescripts_pharmacy = null;
                    this.model.secondary_surescripts_pharmacy_id = null;
                }
            };
            CondensedPharmacyController.prototype.clearSecondaryPharmacy = function () {
                this.model.secondary_pharmacy = null;
                this.model.secondary_pharmacy_id = null;
                this.model.secondary_surescripts_pharmacy = null;
                this.model.secondary_surescripts_pharmacy_id = null;
            };
            CondensedPharmacyController.prototype.clearPharmacy = function () {
                this.model.pharmacy = null;
                this.model.pharmacy_id = null;
                this.model.surescripts_pharmacy_id = null;
                this.model.surescripts_pharmacy = null;
                this.PharmacyService.pharmacy = null;
            };
            CondensedPharmacyController.$inject = ['PharmacyService', '$mdDialog', 'CurrentPatient'];
            return CondensedPharmacyController;
        }());
        common.CondensedPharmacyController = CondensedPharmacyController;
        angular
            .module('consolo')
            .controller('CondensedPharmacyController', CondensedPharmacyController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var common;
    (function (common) {
        'use strict';
        angular.module('consolo').component('pharmacyModalSearch', {
            bindings: { pharmacySearch: '<' },
            templateUrl: '/y/templates/common/services/pharmacy-search/pharmacy-modal-search.html',
            controller: 'PharmacyModalSearchController as $ctrl',
        });
        var PharmacyModalSearchController = /** @class */ (function () {
            function PharmacyModalSearchController(PharmacyService, $mdDialog) {
                var _this = this;
                this.PharmacyService = PharmacyService;
                this.$mdDialog = $mdDialog;
                this.pharmacySearchResultsModel = {
                    options: {
                        defaultPageSize: 10,
                    },
                    columns: [
                        {
                            title: '',
                            field: 'pharmacy',
                            type: 'button',
                            callbackFn: function (surescriptsPharmacy) {
                                return _this.$mdDialog.hide(surescriptsPharmacy);
                            },
                            formatValueFn: function () {
                                return 'Select';
                            },
                        },
                        { title: 'Name', field: 'name' },
                        { title: 'Address', field: 'address_1' },
                        { title: 'City', field: 'city' },
                        { title: 'State', field: 'state' },
                        { title: 'Zip', field: 'zipcode' },
                        { title: 'Phone Number', field: 'phone' },
                        { title: 'E-Prescribe?', field: 'accepts_e_prescriptions_display' },
                        { title: 'Accept Controlled', field: 'accepts_controlled_substances_display' },
                        { title: 'Specialty Type', field: 'speciality_type' },
                    ],
                };
            }
            PharmacyModalSearchController.$inject = ['PharmacyService', '$mdDialog'];
            return PharmacyModalSearchController;
        }());
        common.PharmacyModalSearchController = PharmacyModalSearchController;
        angular
            .module('consolo')
            .controller('PharmacyModalSearchController', PharmacyModalSearchController);
    })(common = consolo.common || (consolo.common = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        angular.module('consolo').component('pharmacy', {
            bindings: {
                model: '<',
            },
            transclude: true,
            templateUrl: '/y/templates/common/services/pharmacy-search/pharmacy-information.html',
            controller: 'PharmacyComponentController as vm',
        });
        var PharmacyComponentController = /** @class */ (function () {
            function PharmacyComponentController(PharmacyService) {
                this.PharmacyService = PharmacyService;
            }
            PharmacyComponentController.prototype.$onInit = function () { };
            PharmacyComponentController.prototype.$onChanges = function (changeObject) {
                if (changeObject.model.currentValue) {
                    this.PharmacyService.model = this.model;
                    if (this.model && this.model.pharmacy) {
                        this.PharmacyService.pharmacy = this.model.pharmacy;
                    }
                    if (this.model && this.model.surescripts_pharmacy) {
                        this.PharmacyService.pharmacy = this.model.surescripts_pharmacy;
                    }
                }
            };
            PharmacyComponentController.$inject = ['PharmacyService'];
            return PharmacyComponentController;
        }());
        components.PharmacyComponentController = PharmacyComponentController;
        angular
            .module('consolo')
            .controller('PharmacyComponentController', PharmacyComponentController);
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        var PharmacyService = /** @class */ (function () {
            function PharmacyService($http, Lookup, options, Session, $mdDialog, CurrentPatient) {
                this.$http = $http;
                this.Lookup = Lookup;
                this.options = options;
                this.Session = Session;
                this.$mdDialog = $mdDialog;
                this.CurrentPatient = CurrentPatient;
                this.pharmacySearchFilter = { miles: 'any' };
                this.loadingPharmacies = false;
                this.epcsOn = false;
                this.primaryPharmacy = true;
                this.pharmacyTypes = [
                    { id: 'Retail', description: 'Retail' },
                    { id: 'MailOrder', description: 'Mail Order' },
                ];
                this.milesWithin = [
                    { id: 'any', description: 'Any' },
                    { id: '25', description: '25' },
                    { id: '10', description: '10' },
                    { id: '5', description: '5' },
                ];
                this.stateSelect = options.get('states');
                this.pharmacyResults = [];
                var session = Session.get();
                this.epcsOn = session.agency.epcs_on;
                this.pharmacySearchFilter.patient_id = CurrentPatient.patient_id;
            }
            PharmacyService.prototype.view = function (pharmacy) {
                this.pharmacy = pharmacy;
                return this.$mdDialog.show({
                    clickOutsideToClose: true,
                    preserveScope: true,
                    templateUrl: '/y/templates/common/services/pharmacy-search/view-details.html',
                    controller: [
                        'PharmacyService',
                        function (PharmacyService) {
                            this.PharmacyService = PharmacyService;
                        },
                    ],
                    controllerAs: 'vm',
                    multiple: true,
                    skipHide: true,
                });
            };
            PharmacyService.prototype.hide = function () {
                this.$mdDialog.hide();
            };
            PharmacyService.prototype.getConsoloPharmacyById = function (id) {
                return this.$http({
                    method: 'GET',
                    headers: { 'Content-type': 'application/json' },
                    url: '/api/v1/pharmacies/consolo_pharmacies',
                    params: { id: id },
                }).then(function (response) {
                    return response.data[0];
                });
            };
            PharmacyService.prototype.pharmacyLookup = function (q) {
                return this.$http({
                    method: 'GET',
                    headers: { 'Content-type': 'application/json' },
                    url: '/api/v1/pharmacies/consolo_pharmacies',
                    params: { name: q },
                }).then(function (pharmacies) {
                    return pharmacies.data;
                });
            };
            PharmacyService.prototype.surescriptsSearch = function () {
                var _this = this;
                if (!this.pharmacySearchFilter.pharmacy_name &&
                    !this.pharmacySearchFilter.zipcode &&
                    !this.pharmacySearchFilter.city &&
                    !this.pharmacySearchFilter.state &&
                    this.pharmacySearchFilter.miles === 'any') {
                    this.error = [
                        'You need to enter filter criteria.  Miles Within, City, State or Zip can all be selected individually,' +
                            ' Pharmacy Name needs to be selected with an additional filter.',
                    ];
                }
                else if (this.pharmacySearchFilter.pharmacy_name &&
                    !this.pharmacySearchFilter.zipcode &&
                    !this.pharmacySearchFilter.city &&
                    !this.pharmacySearchFilter.state &&
                    this.pharmacySearchFilter.miles === 'any') {
                    this.error = ['Please enter a City, Zip, or select a State to narrow results down.'];
                }
                else {
                    this.error = null;
                    this.loadingPharmacies = true;
                    this.$http({
                        method: 'GET',
                        headers: { 'Content-type': 'application/json' },
                        url: '/api/v1/pharmacies/surescripts_pharmacies',
                        params: this.pharmacySearchFilter,
                    })
                        .then(function (pharmacyResults) {
                        _this.pharmacyResults = pharmacyResults.data;
                    })
                        .finally(function () {
                        _this.loadingPharmacies = false;
                        if (!_this.pharmacyResults) {
                            _this.pharmacyResults = [];
                        }
                    });
                }
            };
            PharmacyService.$inject = [
                '$http',
                'Lookup',
                'options',
                'SessionService',
                '$mdDialog',
                'CurrentPatient',
            ];
            return PharmacyService;
        }());
        services.PharmacyService = PharmacyService;
        angular.module('consolo').service('PharmacyService', PharmacyService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var AddSignatureService = /** @class */ (function () {
            function AddSignatureService($http, logger, $q, $mdDialog, Session) {
                this.$http = $http;
                this.logger = logger;
                this.$q = $q;
                this.$mdDialog = $mdDialog;
                this.Session = Session;
                this.effective_date = new Date(new Date().toDateString());
                this.userIsPhysician = false;
            }
            AddSignatureService.prototype.addSignature = function (model) {
                var user = this.Session.getUser();
                if (user) {
                    this.model = model;
                    this.signatureText = 'Are you sure you would like to add your Signature?';
                    if (model.physician_id) {
                        this.physician_id = model.physician_id;
                        if (user.physician && user.physician.id === model.physician_id) {
                            this.signatureText =
                                'Are you sure you would like to add your signature, this will lock it and allow no further editing?';
                            this.userIsPhysician = true;
                        }
                        else {
                            this.userIsPhysician = false;
                            this.signatureText =
                                'Adding physician signature will lock this and prohibit further editing.';
                        }
                    }
                }
                return this.show();
            };
            AddSignatureService.prototype.sign = function () {
                var _this = this;
                //The addSignature API will need to return this.model instead of a Signature because it's an instance and it will
                //set the this.model to a signature
                this.model.$addSignature({ id: this.model.id, effective_date: this.effective_date }, function (signature) {
                    if (!_this.model.signatures) {
                        _this.model.signatures = [];
                    }
                    _this.model.signatures.push(signature);
                    _this.logger.success('Signature added.');
                    _this.hide();
                }, function (errors) {
                    _this.logger.error('Unable to add signature');
                    _this.errors = errors;
                });
            };
            AddSignatureService.prototype.addPhysicianSignature = function () {
                var _this = this;
                this.model.$addSignature({
                    id: this.model.id,
                    signing_physician_id: this.physician_id,
                    effective_date: this.effective_date,
                }, function () {
                    _this.logger.success('Signature added.');
                    _this.hide();
                }, function (errors) {
                    _this.logger.error('Unable to add signature');
                    _this.errors = errors;
                });
            };
            AddSignatureService.prototype.hide = function () {
                this.$mdDialog.hide();
            };
            AddSignatureService.prototype.show = function () {
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/common/services/signature/signature.html',
                    controller: 'SignatureController as vm',
                    hasBackdrop: true,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                });
            };
            AddSignatureService.$inject = ['$http', 'logger', '$q', '$mdDialog', 'SessionService'];
            return AddSignatureService;
        }());
        services.AddSignatureService = AddSignatureService;
        angular.module('consolo').service('AddSignatureService', AddSignatureService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var SignatureController = /** @class */ (function () {
            function SignatureController(AddSignatureService) {
                this.AddSignatureService = AddSignatureService;
            }
            SignatureController.prototype.sign = function () {
                this.AddSignatureService.sign();
            };
            SignatureController.prototype.addPhysicianSignature = function () {
                this.AddSignatureService.addPhysicianSignature();
            };
            SignatureController.prototype.closeDialog = function () {
                this.AddSignatureService.hide();
            };
            SignatureController.$inject = ['AddSignatureService'];
            return SignatureController;
        }());
        angular.module('consolo').controller('SignatureController', SignatureController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('emarDashboardTable', {
            bindings: {
                patients: '<',
                singlePatient: '<',
                medicationGroups: '<',
                hourWidthOnResize: '<',
                medicationCount: '<',
                master: '<',
                detail: '<',
                teams: '=',
                getAssignedPatients: '&',
                vertScrollPosition: '<',
            },
            templateUrl: '/y/templates/dashboard/emar/emar-dashboard-table/emar-dashboard-table.html',
            controller: 'EmarDashboardTable as vm',
        });
        var EmarDashboardTable = /** @class */ (function () {
            function EmarDashboardTable(MedicationService, $state, $filter, $http, logger, $mdDialog, FiltersService, EmarService, $window) {
                this.MedicationService = MedicationService;
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.FiltersService = FiltersService;
                this.EmarService = EmarService;
                this.$window = $window;
                this.listClass = true;
                this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                this.scrollableContent = document.getElementById('emar-content');
                this.isScrolled = false;
                this.EmarService.dateFilter = new Date();
                this.EmarService.hourContainer = document.getElementById('hour-container');
            }
            EmarDashboardTable.prototype.$onInit = function () {
                this.hourWidthOnResize = this.EmarService.hourWidthMultiplier;
            };
            EmarDashboardTable.prototype.$postLink = function () {
                var _this = this;
                // Gives UI enough time to build before attempting to scroll to current hour by default
                setTimeout(function () {
                    _this.EmarService.scrollToCurrentHour();
                }, 1000);
                this.medNameList = document.getElementsByClassName('med-hour-list');
                this.medHourContainer = document.getElementsByClassName('medication-admin-container');
            };
            EmarDashboardTable.prototype.$onChanges = function (changes) {
                if (changes.hourWidthOnResize) {
                    this.EmarService.hourWidthMultiplier = changes.hourWidthOnResize.currentValue;
                    this.EmarService.scrollToCurrentHour();
                }
                if (changes.vertScrollPosition) {
                    if (changes.vertScrollPosition.currentValue === 'Yes') {
                        this.isScrolled = true;
                    }
                    else {
                        this.isScrolled = false;
                    }
                }
            };
            EmarDashboardTable.prototype.$onDestroy = function () {
                this.EmarService.teamSelection = null;
                this.EmarService.patients = null;
                this.EmarService.hourContainer = null;
            };
            EmarDashboardTable.prototype.setUpdate = function () {
                var _this = this;
                // Fixes timing issues when trying to run update meds method directly on blur
                setTimeout(function () {
                    _this.EmarService.getAssignedPatientsOnEmar();
                }, 0);
            };
            EmarDashboardTable.prototype.updateTeam = function () {
                var _this = this;
                // Fixes timing issues when trying to run update teams method directly on blur
                setTimeout(function () {
                    _this.getAssignedPatients();
                }, 0);
            };
            EmarDashboardTable.prototype.scrollPosition = function () {
                if (this.EmarService.hourWidthMultiplier !== 1) {
                    return { transform: 'translateX(-' + this.EmarService.currentScrollPosition + 'px)' };
                }
            };
            EmarDashboardTable.prototype.currentHourStyle = function (index) {
                if (index === this.EmarService.currentHour) {
                    return 'md-primary';
                }
                else {
                    return 'admin-time';
                }
            };
            EmarDashboardTable.prototype.hourwidth = function () {
                var width = this.EmarService.hourContainer.offsetWidth;
                return { width: width * this.EmarService.hourWidthMultiplier + 'px' };
            };
            EmarDashboardTable.prototype.dashboardDisplay = function () {
                if (this.master) {
                    return { display: 'inline-flex' };
                }
            };
            EmarDashboardTable.prototype.dashboardMasterDisplay = function () {
                if (this.master) {
                    return 'master-heading';
                }
            };
            EmarDashboardTable.prototype.dashboardContainerStyle = function () {
                if (this.vertScrollPosition === 'Yes') {
                    return {
                        overflow: 'auto',
                        'background-color': 'red',
                    };
                }
            };
            EmarDashboardTable.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            EmarDashboardTable.$inject = [
                'MedicationService',
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'FiltersService',
                'EmarService',
                '$window',
            ];
            return EmarDashboardTable;
        }());
        emarComponents.EmarDashboardTable = EmarDashboardTable;
        angular.module('consolo').controller('EmarDashboardTable', EmarDashboardTable);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var components;
    (function (components) {
        var DischargeSummaryMainViewComponent = /** @class */ (function () {
            function DischargeSummaryMainViewComponent() {
                this.templateUrl = '/y/templates/discharge-summary/sections/main/main-view.component.html';
                this.controllerAs = '$ctrl';
                this.bindings = {
                    patientId: '<',
                    dischargeId: '<',
                    dischargeSummary: '<',
                };
                this.controller = DischargeSummaryMainViewController;
            }
            return DischargeSummaryMainViewComponent;
        }());
        var DischargeSummaryMainViewController = /** @class */ (function () {
            function DischargeSummaryMainViewController(dischargeSummaryFactory) {
                this.dischargeSummaryFactory = dischargeSummaryFactory;
            }
            DischargeSummaryMainViewController.prototype.$onInit = function () {
                var _this = this;
                if (!this.dischargeSummary) {
                    this.dischargeSummaryFactory.getById(this.patientId, this.dischargeId).$promise.then(function (ds) {
                        _this.dischargeSummary = ds;
                    });
                }
            };
            DischargeSummaryMainViewController.$inject = ['DischargeSummary'];
            return DischargeSummaryMainViewController;
        }());
        components.DischargeSummaryMainViewController = DischargeSummaryMainViewController;
        angular
            .module('consolo')
            .component('dischargeSummaryMainView', new DischargeSummaryMainViewComponent());
    })(components = consolo.components || (consolo.components = {}));
})(consolo || (consolo = {}));

(function () {
    'use strict';
    angular
        .module('consolo')
        .controller('DischargeMainController', ['$state', 'currentSummary', Controller]);
    function Controller($state, currentSummary) {
        var vm = this;
        //members
        vm.dischargeSummary = currentSummary;
        vm.save = save;
        activate();
        function activate() { }
        function save() {
            currentSummary.$update();
        }
    }
})();

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        var PickDeviceController = /** @class */ (function () {
            function PickDeviceController(Exostar) {
                this.Exostar = Exostar;
                this.storeUrl = Exostar.storeUrl();
            }
            PickDeviceController.$inject = ['Exostar'];
            return PickDeviceController;
        }());
        idProofing.PickDeviceController = PickDeviceController;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('IdProofingExostarPickDeviceController', consolo.idProofing.PickDeviceController);

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        'use strict';
        var PickProofController = /** @class */ (function () {
            function PickProofController(exostarService, $stateParams) {
                this.device = $stateParams.device;
                this.deviceName = exostarService.deviceName(this.device);
            }
            PickProofController.$inject = ['Exostar', '$stateParams'];
            return PickProofController;
        }());
        idProofing.PickProofController = PickProofController;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('IdProofingExostarPickProofController', consolo.idProofing.PickProofController);

var consolo;
(function (consolo) {
    var idProofing;
    (function (idProofing) {
        var ExostarStartController = /** @class */ (function () {
            function ExostarStartController(exostarService, sessionService, crypto, storeService, $stateParams) {
                var _this = this;
                this.exostarService = exostarService;
                this.sessionService = sessionService;
                this.crypto = crypto;
                this.storeService = storeService;
                this.saving = false;
                this.device = $stateParams.device;
                this.deviceName = exostarService.deviceName(this.device);
                this.proof = $stateParams.proof;
                this.proofName = exostarService.proofName(this.proof);
                this.store = storeService.async('app');
                var currentUser = this.sessionService.get().user;
                this.idProofingStateKey = "id-proofing-state:" + currentUser.id;
                this.store
                    .getItem(this.idProofingStateKey)
                    .then(function (data) { return (data ? _this.crypto.decrypt(data) : null); })
                    .then(function (state) {
                    if (state) {
                        _this.tokenRef = state['token'];
                        _this.licenseKey = state['activation_code'];
                        _this.phone = state['phone'];
                    }
                });
            }
            ExostarStartController.prototype.start = function () {
                var _this = this;
                this.saving = true;
                this.errors = [];
                var phone = this.device === 'sw' ? this.tokenRef : this.phone;
                var attrs = {
                    token: this.tokenRef,
                    token_type: this.device,
                    activation_code: this.licenseKey,
                    proof_type: this.proof,
                    phone: phone,
                };
                this.crypto
                    .encrypt(attrs)
                    .then(function (data) { return _this.store.setItem(_this.idProofingStateKey, attrs); }, function () {
                    /* ignore failure */
                })
                    .then(function () { return _this.exostarService.create(attrs); })
                    .then(function (proof) { return (window.location.href = proof.redirect_url); }, function (errors) {
                    _this.saving = false;
                    _this.errors = errors;
                });
            };
            ExostarStartController.$inject = ['Exostar', 'SessionService', 'crypto', 'Store', '$stateParams'];
            return ExostarStartController;
        }());
        idProofing.ExostarStartController = ExostarStartController;
    })(idProofing = consolo.idProofing || (consolo.idProofing = {}));
})(consolo || (consolo = {}));
angular
    .module('consolo')
    .controller('IdProofingExostarStartController', consolo.idProofing.ExostarStartController);

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DiagnosisSearchDialogController = /** @class */ (function () {
            function DiagnosisSearchDialogController(options, $mdDialog, IndicatorsService, $timeout, Session, $filter) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.$timeout = $timeout;
                this.Session = Session;
                this.$filter = $filter;
                this.icd10SearchFilter = '';
                this.icd9SearchFilter = '';
                this.selectedIcd10 = null;
                this.selectedIcd9 = '';
                this.icd10SearchLoader = false;
                this.icd9SearchLoader = false;
                this.effectiveDate = this.$filter('date')(this.indicator.effective_date, 'yyyy-MM-dd');
                this.isPalliative = false;
                this.icd10HealthConditions = new Array(6);
                this.getIcdTabularSearchSection(6);
                this.$onInit();
            }
            DiagnosisSearchDialogController.prototype.$onInit = function () {
                this.serviceLine = this.Session.get().agency.service_line;
                this.isPalliative = this.serviceLine === 'palliative';
            };
            DiagnosisSearchDialogController.prototype.lookupIcd10 = function (query) {
                var _this = this;
                this.icd10HealthConditions = [];
                this.icd10SearchLoader = true;
                if (query.length > 2) {
                    // if query is at least 3 characters long
                    if (!this.pendingIcdSearch) {
                        // Start 1 sec. timer to make lookup call
                        this.pendingIcdSearch = this.$timeout(function () {
                            _this.IndicatorsService.lookupIcd10(query, _this.effectiveDate)
                                .then(function (response) {
                                _this.icd10HealthConditions = response.filter(function (icd) { return icd.active !== false; });
                                _this.clearPendingIcd10Lookup();
                            })
                                .catch(function (error) {
                                _this.clearPendingIcd10Lookup();
                                console.log(error);
                            });
                        }, 1000);
                    }
                    else if (this.pendingIcdSearch) {
                        // Cancel and reset lookup if user changes
                        // query before the lookup call starts
                        this.clearPendingIcd10Lookup();
                        this.lookupIcd10(query);
                    }
                }
                else {
                    // If user clears out query,
                    // cancel any existing lookups
                    this.clearPendingIcd10Lookup();
                }
            };
            DiagnosisSearchDialogController.prototype.clearPendingIcd10Lookup = function () {
                this.icd10SearchLoader = false;
                this.$timeout.cancel(this.pendingIcdSearch);
                this.pendingIcdSearch = null;
                this.selectedIcd10 = null;
            };
            DiagnosisSearchDialogController.prototype.lookupIcd9 = function (query) {
                var _this = this;
                this.icd9HealthConditions = [];
                this.icd9SearchLoader = true;
                if (query.length > 2) {
                    // if query is at least 3 characters long
                    if (!this.pendingIcdSearch) {
                        // Start 1 sec. timer to make lookup call
                        this.pendingIcdSearch = this.$timeout(function () {
                            _this.IndicatorsService.lookupIcd9(query, _this.effectiveDate)
                                .then(function (response) {
                                _this.icd9HealthConditions = response.data;
                                _this.clearPendingIcd9Lookup();
                            })
                                .catch(function (error) {
                                _this.clearPendingIcd9Lookup();
                                console.log(error);
                            });
                        }, 1000);
                    }
                    else if (this.pendingIcdSearch) {
                        // Cancel and reset lookup if user changes
                        // query before the lookup call starts
                        this.clearPendingIcd9Lookup();
                        this.lookupIcd9(query);
                    }
                }
                else {
                    // If user clears out query,
                    // cancel any existing lookups
                    this.clearPendingIcd9Lookup();
                }
            };
            DiagnosisSearchDialogController.prototype.clearPendingIcd9Lookup = function () {
                this.icd9SearchLoader = false;
                this.$timeout.cancel(this.pendingIcdSearch);
                this.pendingIcdSearch = null;
                this.selectedIcd9 = null;
            };
            DiagnosisSearchDialogController.prototype.parentDetailsExist = function (icdParentTree) {
                var a = icdParentTree.use_additional_code;
                var b = icdParentTree.excludes_1;
                var c = icdParentTree.excludes_2;
                var d = icdParentTree.includes;
                if (a || b || c || d) {
                    return true;
                }
                else {
                    return false;
                }
            };
            DiagnosisSearchDialogController.prototype.setIcd10 = function (icd10) {
                if (this.selectedIcd10 === null || this.selectedIcd10.id !== icd10.id) {
                    this.selectedIcd10 = icd10;
                }
                else {
                    this.selectedIcd10 = null;
                }
            };
            DiagnosisSearchDialogController.prototype.isSelected = function (icd10) {
                if (this.selectedIcd10 && this.selectedIcd10.id === icd10.id) {
                    return true;
                }
                else {
                    return false;
                }
            };
            DiagnosisSearchDialogController.prototype.clearSearch = function () {
                this.selectedIcd10 = null;
                this.selectedIcd9 = null;
            };
            DiagnosisSearchDialogController.prototype.getIcdTabularSearchSection = function (chapterId) {
                var _this = this;
                return this.IndicatorsService.getIcdTabularSearchSection(chapterId)
                    .then(function (response) {
                    var tabularSection = response.data[0];
                    _this.getIcdTabularSearchIcd(tabularSection.id, tabularSection.icd10_chapter_id);
                })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            DiagnosisSearchDialogController.prototype.getIcdTabularSearchIcd = function (sectionId, chapterId) {
                this.IndicatorsService.getIcdTabularSearchIcd(this.effectiveDate, sectionId, chapterId)
                    .then(function (response) { })
                    .catch(function (error) {
                    console.log(error);
                });
            };
            DiagnosisSearchDialogController.prototype.setIcd10Sections = function (parentSection) {
                if (!parentSection.icd10_sections) {
                    this.IndicatorsService.getIcdTabularSearchSection(parentSection.id)
                        .then(function (response) {
                        var chapterSections = response.data;
                        parentSection.icd10_sections = chapterSections;
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            };
            DiagnosisSearchDialogController.prototype.setTopLevelIcd10s = function (parentSection) {
                if (!parentSection.icd10s) {
                    this.IndicatorsService.getIcdTabularSearchIcd(this.effectiveDate, parentSection.id, parentSection.icd10_chapter_id)
                        .then(function (response) {
                        var sectionIcd10s = response.data;
                        parentSection.icd10s = sectionIcd10s;
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            };
            DiagnosisSearchDialogController.prototype.setIcd10s = function (currentSection, parentSection) {
                if (!currentSection.icd10s || !currentSection.billable) {
                    this.IndicatorsService.getIcdTabularSearchNestedIcd(this.effectiveDate, parentSection.icd10_chapter_id, currentSection.id)
                        .then(function (response) {
                        var sectionIcd10s = response.data;
                        currentSection.icd10s = sectionIcd10s;
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                }
            };
            DiagnosisSearchDialogController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            DiagnosisSearchDialogController.prototype.save = function () {
                this.selectedIcd10.icd10_id = this.selectedIcd10.id;
                delete this.selectedIcd10.id;
                this.$mdDialog.hide(this.selectedIcd10);
            };
            DiagnosisSearchDialogController.$inject = [
                'options',
                '$mdDialog',
                'IndicatorsService',
                '$timeout',
                'SessionService',
                '$filter',
            ];
            return DiagnosisSearchDialogController;
        }());
        angular
            .module('consolo')
            .controller('DiagnosisSearchDialogController', DiagnosisSearchDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var IndicatorSignatureDialogController = /** @class */ (function () {
            function IndicatorSignatureDialogController($mdDialog) {
                this.$mdDialog = $mdDialog;
                this.effectiveDate = new Date();
                this.$onInit();
            }
            IndicatorSignatureDialogController.prototype.$onInit = function () { };
            IndicatorSignatureDialogController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            IndicatorSignatureDialogController.prototype.signIndicator = function () {
                var response = {
                    signature_type: this.signatureType,
                    effective_date: this.effectiveDate,
                };
                this.$mdDialog.hide(response);
            };
            IndicatorSignatureDialogController.$inject = ['$mdDialog'];
            return IndicatorSignatureDialogController;
        }());
        angular
            .module('consolo')
            .controller('IndicatorSignatureDialogController', IndicatorSignatureDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var LcdWorksheetDialogController = /** @class */ (function () {
            function LcdWorksheetDialogController(options, $mdDialog, IndicatorsService, $q, $window, logger) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.$q = $q;
                this.$window = $window;
                this.logger = logger;
                this.symptomTemplates = [];
                this.worksheetScaleList = [];
                this.performanceScaleUrl = '';
                this.$onInit();
            }
            LcdWorksheetDialogController.prototype.$onInit = function () {
                this.symptomTemplates = this.lcdWorksheet.symptoms.slice();
                this.worksheetScaleList = [
                    { name: 'ALSFRS Scale', url: '/alsfrs' },
                    { name: 'FAST Scale', url: '/fast' },
                    { name: 'Geriatric Depression Scale', url: '/gds' },
                    { name: 'Glasgow Coma Scale', url: '/glasgow' },
                    { name: 'Karnofsky Performance Scale', url: '/karnofsky' },
                    { name: 'New York Heart Association Classification', url: '/nyha' },
                    { name: 'Palliative Performance Scale', url: '/palliative' },
                ];
                this.initializeLcdTable();
                this.performanceScaleUrl =
                    this.$window.location.protocol + '//' + this.$window.location.host + '/performance_scales';
            };
            LcdWorksheetDialogController.prototype.initializeLcdTable = function () {
                var _this = this;
                this.additionalDiagnosesLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.lcdWorksheet.icd10_health_conditions.splice(_this.lcdWorksheet.icd10_health_conditions.findIndex(function (element) { return element.code === data.code; }), 1);
                                    _this.lcdWorksheet.icd10_health_conditions = [].concat(_this.lcdWorksheet.icd10_health_conditions);
                                    var icd10IndicatorPosition = _this.indicator.icd10_health_conditions.findIndex(function (icd10) {
                                        return icd10.code === data.code && icd10.diagnosis_id === _this.lcdWorksheet.id;
                                    });
                                    if (icd10IndicatorPosition !== -1) {
                                        _this.indicator.icd10_health_conditions[icd10IndicatorPosition].diagnosis_id = null;
                                    }
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'ICD10 Code', field: 'code' },
                        { title: 'Description', field: 'description' },
                    ],
                };
            };
            LcdWorksheetDialogController.prototype.generateConfirmationRequest = function (text) {
                return this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .textContent(text)
                    .ok('Yes')
                    .cancel('No');
            };
            LcdWorksheetDialogController.prototype.setSymptom = function (symptom) {
                if (this.symptomTemplates.some(function (element) { return element.symptom_template_id === symptom.id; })) {
                    // Remove exisiting symptom from worksheet
                    var existingSymptomTemplateIndex = this.lcdWorksheet.symptoms
                        .map(function (symptomTemplate) {
                        return symptomTemplate.symptom_template_id;
                    })
                        .indexOf(symptom.id);
                    this.symptomTemplates.splice(existingSymptomTemplateIndex, 1);
                }
                else if (this.symptomTemplates.some(function (element) { return element.id === symptom.id; })) {
                    // Remove new symptom from temporary array
                    var symptomTemplateIndex = this.symptomTemplates
                        .map(function (symptomTemplate) {
                        return symptomTemplate.id;
                    })
                        .indexOf(symptom.id);
                    this.symptomTemplates.splice(symptomTemplateIndex, 1);
                }
                else if (this.lcdWorksheet.symptoms.some(function (element) { return element.symptom_template_id === symptom.id; })) {
                    var existingSymptomTemplateIndex = this.lcdWorksheet.symptoms
                        .map(function (symptomTemplate) {
                        return symptomTemplate.symptom_template_id;
                    })
                        .indexOf(symptom.id);
                    this.symptomTemplates.push(this.lcdWorksheet.symptoms[existingSymptomTemplateIndex]);
                }
                else {
                    // Add symptom to temp array to be created as part of the lcdworksheet
                    this.symptomTemplates.push(symptom);
                }
            };
            LcdWorksheetDialogController.prototype.symptomExists = function (symptom) {
                if (this.symptomTemplates.some(function (element) { return element.symptom_template_id === symptom.id; }) ||
                    this.symptomTemplates.some(function (element) { return element.id === symptom.id; })) {
                    return true;
                }
                else {
                    return false;
                }
            };
            LcdWorksheetDialogController.prototype.icd10AutocompleteLookup = function (query) {
                return this.IndicatorsService.lookupIcd10(query).then(function (results) { return results; });
            };
            LcdWorksheetDialogController.prototype.showDiagnosisSearchModal = function () {
                var _this = this;
                this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/indicators-and-diagnoses/indicator-dialogs/diagnosis-search-dialog.html',
                    controller: 'DiagnosisSearchDialogController as vm',
                    bindToController: true,
                    multiple: true,
                    locals: {
                        indicator: this.indicator,
                        icdTabularSearchTree: this.icdTabularSearchTree,
                    },
                })
                    .then(function (selectedIcd10) {
                    var icd10 = {
                        code: selectedIcd10.code,
                        description: selectedIcd10.description,
                        icd10_id: selectedIcd10.icd10_id,
                    };
                    if (!_this.lcdWorksheet.icd10_health_conditions.some(function (icd10) { return icd10.code === selectedIcd10.code; })) {
                        if (_this.lcdWorksheet.icd10_health_conditions.length === 0) {
                            _this.lcdWorksheet.icd10_health_conditions = [icd10];
                        }
                        else {
                            _this.lcdWorksheet.icd10_health_conditions.push(icd10);
                            _this.lcdWorksheet.icd10_health_conditions = [].concat(_this.lcdWorksheet.icd10_health_conditions);
                        }
                    }
                    else {
                        _this.logger.error('You may not have duplicated ICD-10 codes', 'ICD Already Exists');
                    }
                });
            };
            LcdWorksheetDialogController.prototype.setDiagnosesObjects = function () {
                var _this = this;
                return this.$q.when((this.lcdWorksheet.icd10_health_conditions = this.lcdWorksheet.icd10_health_conditions.map(function (icd10, index) {
                    if (icd10.label === undefined) {
                        // icd10 has just been added
                        return {
                            icd10_id: icd10.icd10_id,
                            position: index,
                            diagnosis_id: _this.lcdWorksheet.id ? _this.lcdWorksheet.id : null,
                            code: icd10.code,
                            description: icd10.description,
                        };
                    }
                    else {
                        // icd10 already existed
                        return icd10;
                    }
                })));
            };
            LcdWorksheetDialogController.prototype.setSymptomObjects = function () {
                var _this = this;
                return this.$q.when((this.lcdWorksheet.symptoms = this.symptomTemplates.map(function (symptom) {
                    if (symptom.description !== undefined) {
                        // Symptom has just been added
                        _this.lcdWorksheet.symptom_descriptions.push(symptom.description);
                        return {
                            symptom_template_id: symptom.id,
                            diagnosis_template_id: symptom.diagnosis_template_id,
                        };
                    }
                    else {
                        // Symptom already existed
                        return symptom;
                    }
                })));
            };
            LcdWorksheetDialogController.prototype.setAndSaveWorksheet = function () {
                var _this = this;
                var promiseChain = [this.setDiagnosesObjects(), this.setSymptomObjects()];
                this.$q.all(promiseChain).finally(function () {
                    if (_this.lcdWorksheet.id || !_this.indicator.id) {
                        // LCD Worksheet Already Exists or New Indicator is being created
                        if (!_this.indicator.id) {
                            _this.lcdWorksheet.id = 'temp';
                        }
                        _this.$mdDialog.hide(JSON.parse(angular.toJson(_this.lcdWorksheet)));
                    }
                    else {
                        // Create New LCD Worksheet
                        _this.lcdWorksheet.indicator_id = _this.indicator.id;
                        _this.IndicatorsService.addLCDWorksheet(_this.indicator.patient_id, _this.indicator.id, _this.lcdWorksheet).then(function (response) {
                            var newLcdWorksheet = response.data;
                            newLcdWorksheet.diagnosis_template_id = newLcdWorksheet.diagnosis_template.id;
                            newLcdWorksheet.icd10_health_conditions = newLcdWorksheet.icd10_health_conditions.map(function (icd10, index) {
                                var newIcd10 = {
                                    icd10_id: newLcdWorksheet.icd10s[index].id,
                                    code: icd10.code,
                                    description: icd10.description,
                                    diagnosis_id: newLcdWorksheet.id,
                                    position: icd10.position,
                                };
                                return newIcd10;
                            });
                            _this.$mdDialog.hide(JSON.parse(angular.toJson(newLcdWorksheet)));
                        });
                    }
                });
            };
            LcdWorksheetDialogController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            LcdWorksheetDialogController.prototype.save = function () {
                this.setAndSaveWorksheet();
            };
            LcdWorksheetDialogController.$inject = ['options', '$mdDialog', 'IndicatorsService', '$q', '$window', 'logger'];
            return LcdWorksheetDialogController;
        }());
        angular
            .module('consolo')
            .controller('LcdWorksheetDialogController', LcdWorksheetDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var LcdWorksheetSearchDialogController = /** @class */ (function () {
            function LcdWorksheetSearchDialogController(options, $mdDialog, IndicatorsService) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.lcdSearchFilter = '';
                this.$onInit();
            }
            LcdWorksheetSearchDialogController.prototype.$onInit = function () { };
            LcdWorksheetSearchDialogController.prototype.quickFilterChanged = function () {
                // console.log('Quick filter changed');
                // console.log(this.lcdSearchFilter);
                // this.diagnosis_templates.indexOf(template => template.name === this.lcdSearchFilter);
            };
            LcdWorksheetSearchDialogController.prototype.setTemplate = function (template) {
                this.selectedDiagnosis = template;
            };
            LcdWorksheetSearchDialogController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            LcdWorksheetSearchDialogController.prototype.save = function () {
                this.$mdDialog.hide(this.selectedDiagnosis);
            };
            LcdWorksheetSearchDialogController.$inject = ['options', '$mdDialog', 'IndicatorsService'];
            return LcdWorksheetSearchDialogController;
        }());
        angular
            .module('consolo')
            .controller('LcdWorksheetSearchDialogController', LcdWorksheetSearchDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var TabularSearchDialogController = /** @class */ (function () {
            function TabularSearchDialogController(options, $mdDialog, IndicatorsService) {
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.IndicatorsService = IndicatorsService;
                this.$onInit();
            }
            TabularSearchDialogController.prototype.$onInit = function () { };
            TabularSearchDialogController.prototype.parentDetailsExist = function (icdParentTree) {
                var a = icdParentTree.use_additional_code;
                var b = icdParentTree.excludes_1;
                var c = icdParentTree.excludes_2;
                var d = icdParentTree.includes;
                if (a || b || c || d) {
                    return true;
                }
                else {
                    return false;
                }
            };
            TabularSearchDialogController.prototype.setIcd10Code = function (icd10) {
                if (this.chosenIcd10Id !== icd10) {
                    this.chosenIcd10Id = icd10;
                }
                else {
                    this.chosenIcd10Id = null;
                }
            };
            TabularSearchDialogController.prototype.isSelected = function (icd10) {
                if (this.chosenIcd10Id === icd10) {
                    return true;
                }
                else {
                    return false;
                }
            };
            TabularSearchDialogController.prototype.close = function () {
                this.$mdDialog.hide(null);
            };
            TabularSearchDialogController.prototype.save = function () {
                this.$mdDialog.hide(this.chosenIcd10Id);
            };
            TabularSearchDialogController.$inject = ['options', '$mdDialog', 'IndicatorsService'];
            return TabularSearchDialogController;
        }());
        angular
            .module('consolo')
            .controller('TabularSearchDialogController', TabularSearchDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Allergy = consolo.domain.Allergy;
        var CreateUpdateAllergyDialogController = /** @class */ (function () {
            function CreateUpdateAllergyDialogController($mdDialog, patientService, ProblemListItemService) {
                this.$mdDialog = $mdDialog;
                this.patientService = patientService;
                this.ProblemListItemService = ProblemListItemService;
                if (!this.item) {
                    this.allergy = new Allergy();
                }
            }
            CreateUpdateAllergyDialogController.prototype.allergyAutocompleteLookup = function (query) {
                return this.patientService.lookupAllergens(query).then(function (results) {
                    var allergens = results.data;
                    allergens = allergens.map(function (allergen) {
                        var formattedAllergen = allergen;
                        formattedAllergen.description = allergen.description.trim();
                        return formattedAllergen;
                    });
                    return allergens;
                });
            };
            CreateUpdateAllergyDialogController.prototype.setAllergenKeys = function (allergy) {
                if (!this.item) {
                    this.allergy.code_type = this.medispanAllergen.code_type;
                    this.allergy.code_category = this.medispanAllergen.code_category;
                    this.allergy.name_type = this.medispanAllergen.name_type;
                    this.allergy.code_value = this.medispanAllergen.code_value;
                    this.allergy.description = this.medispanAllergen.description;
                    this.allergy.patient_id = this.patientId;
                }
                this.closeDialog(true);
            };
            CreateUpdateAllergyDialogController.prototype.snomedAutocompleteLookup = function (query) {
                return this.patientService.getSideEffects(query).then(function (response) {
                    return response.data.items.map(function (concept) {
                        return { name: concept.fsn.term, code: concept.conceptId };
                    });
                });
            };
            CreateUpdateAllergyDialogController.prototype.closeDialog = function (save) {
                if (!save) {
                    this.$mdDialog.hide(null);
                    return;
                }
                if (this.snomedCode) {
                    this.allergy.snomed_side_effect = this.snomedCode.description;
                    this.allergy.snomed_side_effect_code = this.snomedCode.id;
                }
                this.$mdDialog.hide(this.allergy);
            };
            CreateUpdateAllergyDialogController.prototype.severityRequired = function (allergen) {
                if (!allergen) {
                    return true;
                }
                if (allergen.description === "No Known Drug Allergy" ||
                    allergen.description === "No Known Environmental Allergy" ||
                    allergen.description === "No Known Food Allergy" ||
                    allergen.description === "No Known Latex Allergy") {
                    return false;
                }
                else {
                    return true;
                }
            };
            CreateUpdateAllergyDialogController.$inject = [
                '$mdDialog',
                'patientService',
                'ProblemListItemService'
            ];
            return CreateUpdateAllergyDialogController;
        }());
        angular
            .module('consolo')
            .controller('CreateUpdateAllergyDialogController', CreateUpdateAllergyDialogController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientAllergyController = /** @class */ (function () {
            function PatientAllergyController($state, $location, $mdDialog, $timeout, patientService, Session, logger, $window, options, AboutService) {
                this.$state = $state;
                this.$location = $location;
                this.$mdDialog = $mdDialog;
                this.$timeout = $timeout;
                this.patientService = patientService;
                this.Session = Session;
                this.logger = logger;
                this.$window = $window;
                this.options = options;
                this.AboutService = AboutService;
                this.edit = false;
                this.isLoading = false;
                this.patientId = this.$state.params.patientId;
                this.$onInit();
                this.cdsEnabled = this.Session.get().agency.has_cds;
            }
            PatientAllergyController.prototype.$onInit = function () {
                this.isLoading = true;
                this.getPatient();
            };
            PatientAllergyController.prototype.getPatient = function () {
                var _this = this;
                this.patientService.getPatient(this.patientId).then(function (patientObj) {
                    _this.patient = patientObj.data;
                    _this.unscreenedAllergies = patientObj.data.patient_allergies_unscreened;
                    _this.patientAllergies = patientObj.data.patient_allergies;
                    _this.loadOptions();
                    _this.initializePatientAllergyTable();
                    _this.isLoading = false;
                });
            };
            Object.defineProperty(PatientAllergyController.prototype, "currentUser", {
                get: function () {
                    return this.Session.getUser();
                },
                enumerable: true,
                configurable: true
            });
            PatientAllergyController.prototype.getPatientAllergies = function (id) {
                var _this = this;
                this.patientService.getPatientAllergies(id).then(function (allergies) {
                    _this.isLoading = false;
                    _this.patientAllergies = allergies.data;
                });
            };
            PatientAllergyController.prototype.addPatientAllergy = function (allergy) {
                var _this = this;
                this.patientService.addPatientAllergy(allergy).then(function (response) {
                    _this.logger.success(response.data.description + ' was successfully added', 'Success');
                    _this.getPatientAllergies(_this.patientId);
                });
            };
            PatientAllergyController.prototype.deletePatientAllergy = function (id) {
                var _this = this;
                this.isLoading = true;
                this.patientService.deletePatientAllergy(id).then(function (response) {
                    _this.logger.success(response.data.description + ' was successfully deleted', 'Success');
                    _this.getPatientAllergies(_this.patientId);
                });
            };
            PatientAllergyController.prototype.editUnscreenedAllergies = function () {
                this.$timeout(function () {
                    document.getElementById('unscreened-allergies').focus();
                }, 0);
            };
            PatientAllergyController.prototype.updateUnscreenedAllergies = function (unscreenedAllergies) {
                var _this = this;
                if (this.unscreenedAllergies !== unscreenedAllergies) {
                    this.isLoading = true;
                    var unscreenedObj = {
                        patient_id: this.patientId,
                        unscreened_allergies: unscreenedAllergies,
                    };
                    this.patientService
                        .updateUnscreenedAllergies(unscreenedObj)
                        .then(function (response) {
                        _this.isLoading = false;
                        _this.logger.success('Unscreened allergies were successfully updated', 'Success');
                        _this.unscreenedAllergies = response.data.patient_allergies_unscreened;
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this.logger.error('Unscreened allergies were unsuccessfully updated', 'Error');
                    });
                }
            };
            // cancelUnscreenedUpdate() {
            //   this.patient.patient_allergies_unscreened = this.unscreenedAllergies;
            // }
            PatientAllergyController.prototype.exportAllergensToCSV = function (patientId) {
                this.patientService
                    .exportAllergensToCSV(patientId)
                    .then(function (response) { return console.log(response); })
                    .catch(function (error) { return console.log(error); });
            };
            PatientAllergyController.prototype.showPatientAllergyDialog = function ($event, item) {
                var _this = this;
                this.$mdDialog
                    .show({
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/sections/allergies/create-update-allergy-dialog.html',
                    controller: 'CreateUpdateAllergyDialogController as vm',
                    bindToController: true,
                    locals: {
                        patientAllergies: this.patientAllergies,
                        allergy: $event,
                        patientId: this.patientId,
                        item: item,
                        symptomSeverityOptions: this.symptomSeverityOptions,
                    },
                })
                    .then(function (allergy) {
                    if (allergy && allergy.description) {
                        _this.isLoading = true;
                        allergy.patient_id = _this.patientId;
                        allergy.user_id = _this.currentUser.id;
                        _this.addPatientAllergy(allergy);
                    }
                });
            };
            PatientAllergyController.prototype.initializePatientAllergyTable = function () {
                var _this = this;
                var generateConfirmationRequest = function (text) {
                    return _this.$mdDialog
                        .confirm()
                        .title('Confirmation Required')
                        .textContent(text)
                        .ok('Yes')
                        .cancel('No');
                };
                this.patientAllergyLayout = {
                    id_field: 'id',
                    headerMenu: {
                        items: [
                            {
                                menuItemType: 'csvHeader',
                            },
                            {
                                menuItemType: 'csvAll',
                            },
                            {
                                menuItemType: 'default',
                                label: 'All Items',
                                indentItem: true,
                                callbackFn: function (data, item) {
                                    _this.exportAllergensToCSV(_this.patientId);
                                },
                            },
                            {
                                menuItemType: 'copyHeader',
                            },
                            {
                                menuItemType: 'copyAll',
                            },
                        ],
                    },
                    rowMenu: {
                        items: [
                            {
                                label: 'Delete',
                                icon: 'delete',
                                callbackFn: function (data, item) {
                                    _this.deletePatientAllergy(data.id);
                                },
                            },
                        ],
                    },
                    columns: [
                        { title: 'Description', field: 'description', canSort: true },
                        {
                            title: 'CDS',
                            field: '_addSomeText',
                            columnHideFn: function () {
                                return !_this.cdsEnabled;
                            },
                            formatValueFn: function (allergyItem) {
                                if (allergyItem.cds_url) {
                                    var cds_url = allergyItem.cds_url;
                                    return '<div class="cds-column"><span class="material-icons">info</span></div>';
                                }
                                else {
                                    return '';
                                }
                            },
                            callbackFn: function (allergyItem, $this, $event) {
                                if (allergyItem.cds_url) {
                                    var event_1 = "Clinical Decision Support Item";
                                    var message = "Clicked Allergy CDS Item: " + allergyItem.cds_url;
                                    var patient_id = _this.patient.id;
                                    _this.AboutService.createNewAuditLog(event_1, message, patient_id)
                                        .then(function () {
                                        _this.$window.open(allergyItem.cds_url, '_blank');
                                    });
                                    $event.stopPropagation();
                                }
                            },
                        },
                        { title: 'Reaction Severity', field: 'severity', canSort: true },
                        { title: 'Reaction/Side Effects', field: 'snomed_side_effect', canSort: true },
                        {
                            title: 'Code Type',
                            field: 'code_type',
                            formatValueFn: function (data, col, value) {
                                switch (value) {
                                    case 'C':
                                        return 'PAR Class';
                                    case 'M':
                                        return 'Moiety';
                                    case 'T':
                                        return 'Trade';
                                    case 'E':
                                        return 'Environmental/Food';
                                    default:
                                        return 'N/A';
                                }
                            },
                            canSort: true,
                        },
                        {
                            title: 'Name Type',
                            field: 'name_type',
                            formatValueFn: function (data, col, value) {
                                switch (value) {
                                    case '1':
                                        return 'Primary Name';
                                    case '2':
                                        return 'Secondary/Synonym Name';
                                    case '0':
                                        return 'Undetermined';
                                    default:
                                        return 'N/A';
                                }
                            },
                            canSort: true,
                        },
                        {
                            title: 'Code Category',
                            field: 'code_category',
                            formatValueFn: function (data, col, value) {
                                switch (value) {
                                    case '1':
                                        return 'Active Ingredient';
                                    case '2':
                                        return 'Significant Inactive Ingredient';
                                    case '3':
                                        return 'Food Allergy';
                                    case '4':
                                        return 'Environmental Allergy';
                                    case '0':
                                        return 'Undetermined';
                                    default:
                                        return 'N/A';
                                }
                            },
                            canSort: true,
                        },
                    ],
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Comments',
                                    field: 'side_effects'
                                }
                            ]
                        }
                    ]
                };
                this.isLoading = false;
            };
            PatientAllergyController.prototype.loadOptions = function () {
                var _this = this;
                this.options.get('allergy_severities').$promise.then(function (data) {
                    _this.symptomSeverityOptions = data.filter(function (severity) { return severity.code; });
                });
            };
            PatientAllergyController.$inject = [
                '$state',
                '$location',
                '$mdDialog',
                '$timeout',
                'patientService',
                'SessionService',
                'logger',
                '$window',
                'options',
                'AboutService',
            ];
            return PatientAllergyController;
        }());
        angular.module('consolo').controller('PatientAllergyController', PatientAllergyController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var CcdaExportOptions = /** @class */ (function () {
            function CcdaExportOptions() {
            }
            return CcdaExportOptions;
        }());
        domain.CcdaExportOptions = CcdaExportOptions;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var CcdaExportController = /** @class */ (function () {
            function CcdaExportController(currentPatient, ccdaExportService, options, logger, Session, $http) {
                this.currentPatient = currentPatient;
                this.ccdaExportService = ccdaExportService;
                this.options = options;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.loading = false;
                this.launching = false;
                this.agency = this.Session.get().agency;
                this.ccdaExportOptions = new consolo.domain.CcdaExportOptions();
                this.dhitExportOptions = new consolo.domain.DhitExportOptions();
                this.dhitApiEnabled = this.agency.has_dhit_integration;
                this.fetchOptions();
            }
            CcdaExportController.prototype.fetchOptions = function () {
                var _this = this;
                this.selectOptions = {};
                [
                    'dsm_document_types',
                ].forEach(function (option) {
                    _this.options.get(option).$promise.then(function (data) {
                        _this.selectOptions[option] = data;
                    });
                });
            };
            CcdaExportController.prototype.downloadCcdaDisabled = function () {
                return this.ccdaExportOptions.doc_type === undefined;
            };
            CcdaExportController.prototype.sendCCdaToDHIT = function () {
                var _this = this;
                console.log("current ccdaExportOptions: ", this.ccdaExportOptions);
                this.dhitExportOptions.external_practice_id = this.agency.external_practice_id;
                this.dhitExportOptions.patient_id = this.currentPatient.patient_id;
                this.dhitExportOptions.start_date = this.ccdaExportOptions.start_date;
                this.dhitExportOptions.end_date = this.ccdaExportOptions.end_date;
                this.dhitExportOptions.doc_type = this.ccdaExportOptions.doc_type;
                this.dhitExportOptions.note = this.ccdaExportOptions.note;
                this.loading = true;
                this.ccdaExportService.get_jwt_token(this.dhitExportOptions.external_practice_id).then(function (response) {
                    _this.jwtToken = response.data;
                    console.log("Got jwt_token as:", response.data);
                    _this.ccdaExportService.get_dhit_public_key(_this.dhitExportOptions.external_practice_id, _this.jwtToken).then(function (response) {
                        _this.publicKeyResponse = response.data;
                        console.log("Got publicKeyResponse as:", response.data);
                        if (_this.publicKeyResponse.TokenValidated == false) {
                            _this.logger.error('Token is not validated due to following reason: ' + _this.publicKeyResponse.ValidationMessage);
                            _this.loading = false;
                            return;
                        }
                        _this.ccdaExportService.send(_this.dhitExportOptions).then(function (response) {
                            _this.loading = false;
                            console.log("Got following response after sending encrypted ccda data: ", response.data);
                        }, function (errorResponse) {
                            _this.loading = false;
                            _this.logger.error('Error while sending ccda to dhit. Please contact an administrator for more information.');
                        });
                    }, function (errorResponse) {
                        _this.loading = false;
                        _this.logger.error('Error while getting dhit public key. Please contact an administrator for more information.');
                    });
                }, function (errorResponse) {
                    _this.loading = false;
                    _this.logger.error('Error while getting jwt token. Please contact an administrator for more information.');
                });
            };
            CcdaExportController.prototype.downloadCcda = function () {
                var _this = this;
                this.ccdaExportOptions.patient_id = this.currentPatient.patient_id;
                this.loading = true;
                this.ccdaExportService.generate(this.ccdaExportOptions).then(function (response) {
                    _this.loading = false;
                    var url = URL.createObjectURL(new Blob([response.data.toString()], { type: 'text/xml' }));
                    var link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', _this.currentPatient.patient.last_name + "_CCDA_Export.xml");
                    link.click();
                }, function (errorResponse) {
                    _this.loading = false;
                    _this.logger.error('Error Generating File. Please contact an administrator for more information.');
                });
            };
            CcdaExportController.prototype.ehrLaunch = function () {
                var _this = this;
                this.launching = true;
                this.$http.get('/api/v2/ccda/launch_url', {
                    params: { patient_id: this.currentPatient.patient_id },
                }).then(function (resp) {
                    _this.launching = false;
                    if (resp.data.url) {
                        window.open(resp.data.url, '_blank', 'noreferrer');
                    }
                    else {
                        _this.logger.error('Unable to launch');
                        console.error(resp.data);
                    }
                }, function (resp) {
                    _this.launching = false;
                    _this.logger.error('Unable to launch');
                    console.error(resp.error);
                });
            };
            CcdaExportController.$inject = ['CurrentPatient', 'ccdaExportService', 'options', 'logger', 'SessionService', '$http'];
            return CcdaExportController;
        }());
        angular.module('consolo').controller('CcdaExportController', CcdaExportController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        var CcdaExportService = /** @class */ (function () {
            function CcdaExportService($http, $httpParamSerializer) {
                this.$http = $http;
                this.$httpParamSerializer = $httpParamSerializer;
            }
            CcdaExportService.prototype.generate = function (options) {
                var query = this.$httpParamSerializer(options);
                return this.$http({
                    method: 'GET',
                    url: "/api/v2/ccda/generate?" + query,
                    headers: { 'Content-type': 'application/json', 'Accept': 'application/xml' },
                });
            };
            CcdaExportService.prototype.get_jwt_token = function (external_practice_id) {
                return this.$http({
                    method: 'GET',
                    url: "/api/v2/ccda/get_jwt_token?external_practice_id=" + external_practice_id,
                    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
                });
            };
            CcdaExportService.prototype.get_dhit_public_key = function (external_practice_id, jwt_token) {
                return this.$http({
                    method: 'GET',
                    url: "/api/v2/ccda/get_dhit_public_key?external_practice_id=" + external_practice_id + "&jwt_token=" + jwt_token,
                    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
                });
            };
            CcdaExportService.prototype.send = function (options) {
                var query = this.$httpParamSerializer(options);
                return this.$http({
                    method: 'GET',
                    url: "/api/v2/ccda/send?" + query,
                    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
                });
            };
            CcdaExportService.$inject = ['$http', '$httpParamSerializer'];
            return CcdaExportService;
        }());
        services.CcdaExportService = CcdaExportService;
        angular.module('consolo').service('ccdaExportService', CcdaExportService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var domain;
    (function (domain) {
        var DhitExportOptions = /** @class */ (function () {
            function DhitExportOptions() {
            }
            return DhitExportOptions;
        }());
        domain.DhitExportOptions = DhitExportOptions;
    })(domain = consolo.domain || (consolo.domain = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CustomAlertController = /** @class */ (function () {
            function CustomAlertController($stateParams, CustomAlertService, CurrentPatient, Session, $mdDialog, logger, $window, options) {
                var _this = this;
                this.$stateParams = $stateParams;
                this.CustomAlertService = CustomAlertService;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.$window = $window;
                this.options = options;
                this.today = new Date();
                this.saveText = 'Save';
                this.loggerText = 'Custom Alert Saved';
                this.roles = [
                    { id: null, description: 'Owner Only' },
                    { id: -1, description: 'All Assigned Roles' },
                ];
                options.get('base_roles').$promise.then(function (data) {
                    data.forEach(function (role) {
                        _this.roles.push(role);
                    });
                    _this.roles = [].concat(_this.roles);
                });
                if (!this.item.id) {
                    this.pageTitle = 'Create a Custom Alert';
                    this.alert = {}; // could create a customAlert object based on interface.
                    this.alert.patient_id = this.CurrentPatient.patient_id;
                    this.alert.office_id = this.CurrentPatient.patient.office.id;
                    this.alert.expiration_date = this.today;
                    var user = Session.getUser();
                    this.alert.user_id = user.id;
                }
                else {
                    this.loading = true;
                    this.CustomAlertService.get(this.item.id).then(function (alert) {
                        _this.loading = false;
                        _this.alert = alert.data;
                        _this.pageTitle = 'Edit Custom Alert';
                    }, function () {
                        _this.logger.error('Error retrieving custom alert: ' + _this.item.id);
                        _this.$mdDialog.hide();
                        return false;
                    });
                }
            }
            CustomAlertController.prototype.createCustomAlert = function () {
                var _this = this;
                var save = !this.alert.id
                    ? this.CustomAlertService.create(this.alert)
                    : this.CustomAlertService.patch(this.alert);
                save.then(function (response) {
                    _this.logger.success(_this.loggerText);
                    _this.CustomAlertService.loadByPatientId(_this.CurrentPatient.patient.id).then(function (alerts) {
                        var today = new Date(new Date().toDateString());
                        _this.CurrentPatient.patient.dashboard.patient_alerts = alerts.filter(function (a) {
                            return new Date(a.expiration_date.toString()) >= today;
                        });
                    });
                    _this.$mdDialog.hide(true);
                }, function (e) {
                    console.log('Error creating custom alert');
                    console.log(e);
                    _this.errors = e.errors;
                });
            };
            CustomAlertController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            CustomAlertController.prototype.saveDisabled = function () {
                if (this.loading) {
                    return false;
                }
                return !this.alert.expiration_date || !this.alert.user_id || !this.alert.message;
            };
            CustomAlertController.$inject = [
                '$stateParams',
                'CustomAlertService',
                'CurrentPatient',
                'SessionService',
                '$mdDialog',
                'logger',
                '$window',
                'options',
            ];
            return CustomAlertController;
        }());
        angular.module('consolo').controller('CustomAlertController', CustomAlertController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var CustomAlertsController = /** @class */ (function () {
            function CustomAlertsController(CustomAlertService, CurrentPatient, CSV, $mdDialog, logger, options) {
                var _this = this;
                this.CustomAlertService = CustomAlertService;
                this.CurrentPatient = CurrentPatient;
                this.CSV = CSV;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.options = options;
                this.showExpired = false;
                this.today = new Date();
                this.currentFilter = function (value, index, array) {
                    if (value.isExpired && !_this.showExpired) {
                        return false;
                    }
                    return true;
                };
                this.patient = CurrentPatient.patient;
                this.currentOptions = {
                    enablePaging: true,
                    pagingOptions: {
                        currentPage: 1,
                        rowsPerPage: 2,
                        changePage: function (page) {
                            console.log(_this.currentOptions.pagingOptions.currentPage);
                        },
                    },
                };
                this.loadAlerts();
                this.formModel = {
                    options: {
                        expandCollapseAll: false,
                        noExpand: true,
                    },
                    header: [
                        {
                            header: true,
                            title: 'Synopsis',
                            field: 'message',
                        },
                        {
                            header: true,
                            title: 'Expiration Date',
                            is_date: true,
                            field: 'expiration_date',
                            customCss: function (column, item) {
                                if (consolo.common.dateOnly(item.expiration_date) < consolo.common.dateOnly(_this.today)) {
                                    return 'colorNo min-width-120';
                                }
                                return 'min-width-120';
                            },
                        },
                        {
                            header: true,
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            showExpandCollapse: false,
                            csvContents: function () {
                                return _this.customAlertCsv(_this.CurrentPatient.patient.dashboard.patient_alerts);
                            },
                            csvFilename: 'Patient ' +
                                this.CurrentPatient.patient_id +
                                ' Custom Alerts ' +
                                new Date().toISOString().slice(0, 10) +
                                '.csv',
                            itemMenuItems: [
                                {
                                    icon: 'image:edit',
                                    text: 'Edit Custom Alert',
                                    callbackFn: function ($event, item) {
                                        _this.showCustomAlertDialog($event, item);
                                    },
                                },
                                {
                                    icon: 'action:delete',
                                    text: 'Delete Custom Alert',
                                    callbackFn: function ($event, item) {
                                        _this.CustomAlertService.deleteCustomAlert($event, item);
                                    },
                                },
                            ],
                        },
                    ],
                    subTable: [
                        [
                            {
                                title: 'Role',
                                field: 'role_name',
                            },
                            {
                                title: 'Created By',
                                field: 'created_by_name',
                            },
                        ],
                    ],
                };
            }
            CustomAlertsController.prototype.loadAlerts = function () {
                var _this = this;
                this.CustomAlertService.loadByPatientId(this.CurrentPatient.patient_id, true).then(function (alerts) {
                    _this.allAlerts = alerts.sort(function (a, b) {
                        if (a.expiration_date < b.expiration_date) {
                            return 1;
                        }
                        if (a.expiration_date > b.expiration_date) {
                            return -1;
                        }
                        return 0;
                    });
                    for (var _i = 0, _a = _this.allAlerts; _i < _a.length; _i++) {
                        var alert_1 = _a[_i];
                        if (alert_1) {
                            _this.CustomAlertService.setDataTableFields(alert_1);
                        }
                    }
                });
            };
            CustomAlertsController.prototype.customAlertCsv = function (alerts) {
                var headers = ['Message', 'Expiration Date', 'Created By'];
                var date = this.CSV.date, yn = this.CSV.yn;
                return this.CSV.generate(alerts.map(function (alert) {
                    return [alert.message, date(alert.expiration_date), alert.created_by_name];
                }), headers);
            };
            CustomAlertsController.prototype.urlTo = function (url) {
                document.location.href = '/patients/' + this.patient.id + '/' + url;
            };
            CustomAlertsController.prototype.showCustomAlertDialog = function ($event, item) {
                var _this = this;
                var edit = true;
                if (!item) {
                    item = {};
                    item.id = null;
                    edit = false;
                }
                this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    bindToController: true,
                    targetEvent: $event,
                    templateUrl: '/y/templates/patients/sections/custom-alerts/custom-alert.html',
                    controller: 'CustomAlertController as $ctrl',
                    locals: {
                        item: item,
                    },
                })
                    .then(function (alertUpdated) {
                    if (alertUpdated) {
                        _this.loadAlerts();
                    }
                });
            };
            CustomAlertsController.$inject = [
                'CustomAlertService',
                'CurrentPatient',
                'CSV',
                '$mdDialog',
                'logger',
                'options',
            ];
            return CustomAlertsController;
        }());
        angular.module('consolo').controller('CustomAlertsController', CustomAlertsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientDocs;
    (function (patientDocs) {
        var PatientDocumentsController = /** @class */ (function () {
            function PatientDocumentsController($params, $timeout, uploadQueue, S3UploadFactory) {
                this.$timeout = $timeout;
                this.uploadQueue = uploadQueue;
                this.S3UploadFactory = S3UploadFactory;
                this.filterPrepends = [];
                this.uploads = [];
                this.patientId = Number($params.patientId);
            }
            PatientDocumentsController.prototype.handleFiles = function (files) {
                var _this = this;
                files.forEach(function (file) {
                    // TODO throw warning if file too large?
                    var upload = _this.S3UploadFactory.build(file);
                    _this.uploadQueue.push(upload);
                    _this.uploads.push(upload);
                    upload.promise.catch(function (e) {
                        _this.removeUpload(upload, 5000);
                    });
                });
            };
            PatientDocumentsController.prototype.afterCancel = function (upload) {
                this.removeUpload(upload, 500);
            };
            PatientDocumentsController.prototype.afterSave = function (upload, doc) {
                this.removeUpload(upload, 100);
                this.filterPrepends.unshift(doc);
            };
            PatientDocumentsController.prototype.removeUpload = function (upload, time) {
                var _this = this;
                this.$timeout(function () {
                    var idx = _this.uploads.indexOf(upload);
                    if (idx > -1) {
                        _this.uploads.splice(idx, 1);
                    }
                }, time);
            };
            PatientDocumentsController.$inject = ['$stateParams', '$timeout', 'UploadQueue', 'S3UploadFactory'];
            return PatientDocumentsController;
        }());
        patientDocs.PatientDocumentsController = PatientDocumentsController;
        angular.module('consolo').controller('PatientDocumentsController', PatientDocumentsController);
    })(patientDocs = consolo.patientDocs || (consolo.patientDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientDocs;
    (function (patientDocs) {
        var EditPatientDocumentsController = /** @class */ (function () {
            function EditPatientDocumentsController($http, $filter, $state, logger, options, doc) {
                this.$http = $http;
                this.$filter = $filter;
                this.$state = $state;
                this.logger = logger;
                this.options = options;
                this.doc = doc;
                this.patientId = parseInt($state.params.patientId, 10);
                var dateChunks = doc.document_date.split('-').map(function (str) {
                    return parseInt(str, 10);
                });
                this.documentDate = new Date(dateChunks[0], dateChunks[1] - 1, dateChunks[2]);
                this.tags = this.options.get('document_types');
            }
            EditPatientDocumentsController.prototype.save = function () {
                var _this = this;
                if (this.documentDate) {
                    this.doc.document_date = this.$filter('date')(this.documentDate, 'yyyy-MM-dd');
                }
                this.$http.patch("/api/v1/patient_documents/" + this.doc.id, this.doc).then(function (resp) {
                    _this.logger.success('Document saved');
                    _this.$state.go('patients.documents', { patientId: _this.patientId });
                }, function (resp) {
                    _this.errors = (resp.data ? resp.data['errors'] : null) || [
                        "Unknown error (status " + resp.status + ")",
                    ];
                });
            };
            EditPatientDocumentsController.$inject = [
                '$http',
                '$filter',
                '$state',
                'logger',
                'options',
                'patientDocument',
            ];
            return EditPatientDocumentsController;
        }());
        patientDocs.EditPatientDocumentsController = EditPatientDocumentsController;
        angular
            .module('consolo')
            .controller('EditPatientDocumentsController', EditPatientDocumentsController);
    })(patientDocs = consolo.patientDocs || (consolo.patientDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientDocs;
    (function (patientDocs) {
        angular.module('consolo').component('patientDocumentsFilter', {
            bindings: { patientId: '<', pageSize: '<?', prepends: '<?' },
            templateUrl: '/y/templates/patients/sections/documents/filter.html',
            controller: 'PatientDocumentsFilterController as vm',
        });
        var PatientDocumentsFilterController = /** @class */ (function () {
            function PatientDocumentsFilterController($http, $httpParamSerializer, $filter, $mdDialog, options, logger, tokenService) {
                this.$http = $http;
                this.$httpParamSerializer = $httpParamSerializer;
                this.$filter = $filter;
                this.$mdDialog = $mdDialog;
                this.options = options;
                this.logger = logger;
                this.tokenService = tokenService;
                this.currentPage = { page: 0, totalPages: 0, items: [] };
                this.loading = false;
            }
            PatientDocumentsFilterController.prototype.$onInit = function () {
                this.filter = { patient_id: this.patientId, sort_by: 'name', sort_dir: 'asc' };
                this.loadPage(1);
                this.tags = this.options.get('document_types');
            };
            PatientDocumentsFilterController.prototype.allDocs = function () {
                this.prepends.forEach(function (doc) {
                    doc['justUploaded'] = true;
                });
                return this.prepends.concat(this.currentPage.items);
            };
            PatientDocumentsFilterController.prototype.delete = function (doc) {
                var _this = this;
                var dialog = this.$mdDialog
                    .confirm()
                    .title('Delete document?')
                    .textContent("Are you sure you want to delete '" + doc.name + "'?")
                    .ok('Yes')
                    .cancel('No');
                this.$mdDialog.show(dialog).then(function () {
                    _this.$http.delete("/api/v1/patient_documents/" + doc.id).then(function () {
                        _this.logger.success('Document deleted');
                        if (_this.currentPage.items.indexOf(doc) > -1) {
                            _this.currentPage.items.splice(_this.currentPage.items.indexOf(doc), 1);
                        }
                        else if (_this.prepends.indexOf(doc) > -1) {
                            _this.prepends.splice(_this.prepends.indexOf(doc), 1);
                        }
                    }, function (resp) {
                        _this.logger.error(resp.data.errors.join('; '));
                    });
                });
            };
            PatientDocumentsFilterController.prototype.getFile = function (doc) {
                var _this = this;
                this.tokenService.getToken().then(function (tok) {
                    window.location.href = _this.newLink(doc, tok);
                });
            };
            PatientDocumentsFilterController.prototype.newLink = function (doc, token) {
                return "/api/v1/patient_documents/" + doc.id + "/download?access_token=" + token;
            };
            PatientDocumentsFilterController.prototype.sort = function (col, dir) {
                this.filter['sort_by'] = col;
                this.filter['sort_dir'] = dir;
                this.loadPage(this.currentPage.page);
            };
            PatientDocumentsFilterController.prototype.loadPage = function (n) {
                var _this = this;
                this.loading = true;
                var query = this.$httpParamSerializer(this.filter);
                this.$http
                    .get("/api/v1/patient_documents?page_size=" + this.pageSize + "&page=" + n + "&" + query)
                    .then(function (resp) {
                    _this.currentPage = {
                        page: parseInt(resp.headers('X-Page'), 10),
                        totalPages: parseInt(resp.headers('X-Total-Pages'), 10),
                        items: resp.data,
                    };
                    if (_this.prepends) {
                        _this.prepends.splice(0, _this.prepends.length);
                    }
                    _this.loading = false;
                }, function (resp) {
                    _this.loading = false;
                });
            };
            PatientDocumentsFilterController.$inject = [
                '$http',
                '$httpParamSerializer',
                '$filter',
                '$mdDialog',
                'options',
                'logger',
                'AccessTokenService',
            ];
            return PatientDocumentsFilterController;
        }());
        patientDocs.PatientDocumentsFilterController = PatientDocumentsFilterController;
        angular
            .module('consolo')
            .controller('PatientDocumentsFilterController', PatientDocumentsFilterController);
    })(patientDocs = consolo.patientDocs || (consolo.patientDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var patientDocs;
    (function (patientDocs) {
        angular.module('consolo').component('patientDocumentUpload', {
            bindings: { upload: '<', patientId: '<', afterCancel: '&', afterSave: '&' },
            templateUrl: '/y/templates/patients/sections/documents/patient-document-upload.html',
            controller: 'PatientDocumentUploadController as vm',
        });
        var PatientDocumentUploadController = /** @class */ (function () {
            function PatientDocumentUploadController($filter, $http, options, logger) {
                this.$filter = $filter;
                this.$http = $http;
                this.options = options;
                this.logger = logger;
                this.documentDate = new Date();
                this.saving = false;
            }
            PatientDocumentUploadController.prototype.$onInit = function () {
                this.model = {
                    patient_id: this.patientId,
                    name: this.upload.name.replace(/\.\w+$/, ''),
                    tags: [],
                };
                this.tags = this.options.get('document_types');
            };
            PatientDocumentUploadController.prototype.save = function () {
                var _this = this;
                if (this.saving || this.model['id']) {
                    return;
                }
                this.saving = true;
                this.upload.promise.then(function () {
                    _this.model.s3_tmp_key = _this.upload.s3Key;
                    if (_this.documentDate) {
                        _this.model['document_date'] = _this.$filter('date')(_this.documentDate, 'yyyy-MM-dd');
                    }
                    _this.$http.post('/api/v1/patient_documents', _this.model).then(function (resp) {
                        _this.model['id'] = resp.data['id'];
                        _this.saving = false;
                        _this.afterSave({ upload: _this.upload, doc: resp.data });
                    }, function (resp) {
                        _this.saving = false;
                        _this.logger.error(resp.data.errors.join(', '));
                    });
                });
            };
            PatientDocumentUploadController.prototype.cancel = function () {
                this.saving = false;
                this.upload.cancel();
                this.afterCancel(this.upload);
            };
            PatientDocumentUploadController.prototype.status = function () {
                switch (this.upload.state) {
                    case consolo.uploads.UploadState.Uploading:
                        return this.upload.percentComplete + "%";
                    case consolo.uploads.UploadState.Complete:
                        return '100%';
                    default:
                        return this.upload.status();
                }
            };
            PatientDocumentUploadController.$inject = ['$filter', '$http', 'options', 'logger'];
            return PatientDocumentUploadController;
        }());
        patientDocs.PatientDocumentUploadController = PatientDocumentUploadController;
        angular
            .module('consolo')
            .controller('PatientDocumentUploadController', PatientDocumentUploadController);
    })(patientDocs = consolo.patientDocs || (consolo.patientDocs = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var EhiExportController = /** @class */ (function () {
            function EhiExportController($scope, currentPatient, options, logger, Session, $http) {
                var _a, _b, _c;
                this.$scope = $scope;
                this.currentPatient = currentPatient;
                this.options = options;
                this.logger = logger;
                this.Session = Session;
                this.$http = $http;
                this.loading = false;
                this.launching = false;
                this.session = this.Session.get();
                this.agency = this.session.agency;
                $scope.showEHiExportReport = ((_c = (_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.patient_documents) === null || _c === void 0 ? void 0 : _c.indexOf("electronic_health_information_export")) !== -1;
                $scope.displayMesage = $scope.showEHiExportReport ? null : 'You do not have permission to download the file';
            }
            EhiExportController.prototype.generateEHIReport = function () {
                var _this = this;
                this.$http.post('/api/v2/ehi/b10_bulk_generate', { patient_ids: ["" + this.currentPatient.patient.id] }).then(function (response) {
                    _this.logger.info('Your EHI file is being generated. You will receive a notification when it is complete.');
                }, function (error) {
                    _this.logger.error('Error Generating report. Please contact an administrator for more information.');
                });
            };
            EhiExportController.$inject = ['$scope', 'CurrentPatient', 'options', 'logger', 'SessionService', '$http'];
            return EhiExportController;
        }());
        angular
            .module('consolo')
            .controller('EhiExportController', EhiExportController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AcknowledgeInteractionsController = /** @class */ (function () {
            function AcknowledgeInteractionsController($mdDialog, MedicationService, medication) {
                this.$mdDialog = $mdDialog;
                this.MedicationService = MedicationService;
                this.medication = medication;
                this.drugInteractionsModel = {
                    options: {
                        hideFilter: true,
                        hideSearch: true,
                    },
                    header: [
                        { title: 'Source', field: 'medication_name', header: true },
                        { title: 'Adverse', field: 'adverse_medication_name', header: true },
                        { title: 'Severity', field: 'severity', header: true },
                        { title: 'Effects', field: 'effects', header: true },
                    ],
                };
                this.allergyInteractionsModel = {
                    options: {
                        hideFilter: true,
                        hideSearch: true,
                    },
                    header: [
                        { title: 'Allergen', field: 'allergy_name', header: true },
                        { title: 'Medication', field: 'medication_name', header: true },
                        { title: 'Severity', field: 'severity', header: true },
                        { title: 'Effects', field: 'effects', header: true },
                        { title: 'Discussion', field: 'discussion', header: true },
                    ],
                };
            }
            AcknowledgeInteractionsController.prototype.acknowledgeInteractions = function () {
                return this.$mdDialog.hide(this.medication);
            };
            AcknowledgeInteractionsController.prototype.cancel = function () {
                return this.$mdDialog.hide(null);
            };
            AcknowledgeInteractionsController.$inject = ['$mdDialog', 'MedicationService', 'medication'];
            return AcknowledgeInteractionsController;
        }());
        angular
            .module('consolo')
            .controller('AcknowledgeInteractionsController', AcknowledgeInteractionsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var AddMedicationKitController = /** @class */ (function () {
            function AddMedicationKitController(SessionService, medicationKits, currentPatient, MedicationKitsService, $mdDialog, MedicationService, Medication, $http, logger, $q, $state, AboutService, $window) {
                var _this = this;
                this.SessionService = SessionService;
                this.medicationKits = medicationKits;
                this.currentPatient = currentPatient;
                this.MedicationKitsService = MedicationKitsService;
                this.$mdDialog = $mdDialog;
                this.MedicationService = MedicationService;
                this.Medication = Medication;
                this.$http = $http;
                this.logger = logger;
                this.$q = $q;
                this.$state = $state;
                this.AboutService = AboutService;
                this.$window = $window;
                this.adding = false;
                this.loadingInteractions = false;
                this.interactionsAcknowledged = false;
                this.reasonRequired = false;
                this.remainingCharacters = 1000;
                this.cancelButtonClicked = false;
                this.interactionsRecord = {};
                var session = SessionService.get();
                this.errors = [];
                this.pharmacy = {};
                this.currentUser = session.user;
                if (currentPatient.pharmacy) {
                    this.pharmacy.pharmacy = currentPatient.pharmacy;
                    this.pharmacy.pharmacy_id = currentPatient.pharmacy.id;
                }
                if (currentPatient.surescripts_pharmacy) {
                    this.pharmacy.surescripts_pharmacy = currentPatient.surescripts_pharmacy;
                    this.pharmacy.surescripts_pharmacy_id = currentPatient.surescripts_pharmacy.id;
                }
                if (this.MedicationService.currentUser.physician) {
                    this.physician = this.MedicationService.currentUser.physician;
                }
                else {
                    this.$http
                        .get('/api/v1/patients/' + currentPatient.id + '/default_physician')
                        .then(function (response) {
                        _this.physician = response.data;
                    });
                }
            }
            AddMedicationKitController.prototype.addMedicationKit = function () {
                var _this = this;
                this.errors = [];
                if (!this.medicationKitId) {
                    this.errors.push('You must select a Medication Kit.');
                }
                if (!this.physician) {
                    this.errors.push('You must select a physician.');
                }
                if (this.cancelButtonClicked) {
                    this.setMedicationOnSelection();
                }
                if (this.errors.length === 0 && this.interactionsAcknowledged && !this.cancelButtonClicked) {
                    this.adding = true;
                    return this.MedicationKitsService.addMedicationKitToPatient(this.currentPatient.id, this.medicationKitId, this.physician.id, this.pharmacy.pharmacy_id, this.pharmacy.surescripts_pharmacy_id, this.interactionsRecord)
                        .then(function (response) {
                        return _this.$mdDialog.hide(response);
                    }, function (errors) {
                        _this.errors = errors.data;
                    })
                        .finally(function () {
                        _this.adding = false;
                    });
                }
            };
            AddMedicationKitController.prototype.setMedicationOnSelection = function () {
                var _this = this;
                this.selectedMedicationKit = this.medicationKits.find(function (m) { return m.id == _this.medicationKitId; });
                this.cancelButtonClicked = false;
                var defer = this.$q.defer();
                if (this.selectedMedicationKit) {
                    this.selectedMedicationKit.medication_kit_items.forEach(function (item) {
                        if (item.ndc) {
                            _this.interactionsAcknowledged = false;
                            _this.loadingInteractions = true;
                            _this.$http
                                .get('/api/v1/medications/new_interactions', {
                                params: {
                                    new_medication_name: item.name,
                                    new_medication_ndc: item.ndc,
                                    patient_id: _this.currentPatient.id,
                                },
                            })
                                .then(function (response) {
                                var data = response.data;
                                if (data.drug_interactions && data.drug_interactions.length != 0) {
                                    data.drug_interactions.forEach(function (drugInteraction) {
                                        drugInteraction.user = _this.currentUser;
                                    });
                                }
                                if (data.allergy_interactions && data.allergy_interactions.length != 0) {
                                    data.allergy_interactions.forEach(function (allergyInteraction) {
                                        allergyInteraction.user = _this.currentUser;
                                    });
                                }
                                item.drug_interactions = data.drug_interactions;
                                item.allergy_interactions = data.allergy_interactions;
                                var ndc = item.ndc;
                                _this.interactionsRecord[ndc] = {
                                    drug_interactions: data.drug_interactions,
                                    allergy_interactions: data.allergy_interactions
                                };
                            })
                                .catch(function (errors) {
                                _this.logger.error(errors.join(', '));
                            })
                                .finally(function () {
                                if (!item.drug_interactions) {
                                    item.drug_interactions = [];
                                }
                                if (!item.allergy_interactions) {
                                    item.allergy_interactions = [];
                                }
                                _this.reasonRequired = item.reason_required_to_add;
                                if (item.drug_interactions.length !== 0 ||
                                    item.allergy_interactions.length !== 0) {
                                    _this.$mdDialog
                                        .show({
                                        preserveScope: true,
                                        templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                        controller: 'AcknowledgeInteractionsController as vm',
                                        multiple: true,
                                        skipHide: true,
                                        locals: { medication: item },
                                    })
                                        .then(function (response) {
                                        console.log(response);
                                        if (response) {
                                            _this.interactionsAcknowledged = true;
                                        }
                                        else {
                                            _this.cancelButtonClicked = true;
                                        }
                                        defer.resolve();
                                    });
                                }
                                else {
                                    _this.interactionsAcknowledged = true;
                                    defer.resolve();
                                }
                                _this.loadingInteractions = false;
                            });
                        }
                        else if (item.compound_id) {
                            _this.interactionsAcknowledged = false;
                            _this.loadingInteractions = true;
                            var allergyInteractions_1 = [];
                            var drugInteractions_1 = [];
                            var interactionPromises_1 = [];
                            item.ingredients.forEach(function (ingredient) {
                                if (ingredient.name && ingredient.ndc) {
                                    interactionPromises_1.push(_this.$http
                                        .get('/api/v1/medications/new_interactions', {
                                        params: {
                                            new_medication_name: ingredient.name,
                                            new_medication_ndc: ingredient.ndc,
                                            patient_id: _this.currentPatient.id,
                                        },
                                    })
                                        .then(function (response) {
                                        var data = response.data;
                                        if (data.drug_interactions && data.drug_interactions.length != 0) {
                                            data.drug_interactions.forEach(function (drugInteraction) {
                                                drugInteraction.user = _this.currentUser;
                                            });
                                            drugInteractions_1 = drugInteractions_1.concat(data.drug_interactions);
                                        }
                                        if (data.allergy_interactions && data.allergy_interactions.length != 0) {
                                            data.allergy_interactions.forEach(function (allergyInteraction) {
                                                allergyInteraction.user = _this.currentUser;
                                            });
                                            allergyInteractions_1 = allergyInteractions_1.concat(data.allergy_interactions);
                                        }
                                        var ndc = item.ndc;
                                        _this.interactionsRecord[ndc] = {
                                            drug_interactions: data.drug_interactions,
                                            allergy_interactions: data.allergy_interactions
                                        };
                                    }));
                                }
                            });
                            _this.$q.all(interactionPromises_1).then(function () {
                                item.drug_interactions = drugInteractions_1;
                                item.allergy_interactions = allergyInteractions_1;
                                if (item.drug_interactions.length !== 0 ||
                                    item.allergy_interactions.length !== 0) {
                                    _this.$mdDialog
                                        .show({
                                        preserveScope: true,
                                        templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                        controller: 'AcknowledgeInteractionsController as vm',
                                        multiple: true,
                                        skipHide: true,
                                        locals: { medication: item },
                                    })
                                        .then(function (response) {
                                        if (response) {
                                            _this.interactionsAcknowledged = true;
                                        }
                                        else {
                                            _this.cancelButtonClicked = true;
                                        }
                                        defer.resolve();
                                    });
                                }
                                else {
                                    _this.interactionsAcknowledged = true;
                                    defer.resolve();
                                }
                                _this.loadingInteractions = false;
                            });
                        }
                        else {
                            _this.interactionsAcknowledged = true;
                            defer.resolve();
                        }
                    });
                }
            };
            AddMedicationKitController.prototype.updateFrequency = function () {
                var _this = this;
                this.MedicationService.getFrequency(this.medication).then(function (frequency) {
                    _this.medication.frequency = frequency;
                    _this.updateRemainingCharacters();
                });
            };
            AddMedicationKitController.prototype.updateRemainingCharacters = function () {
                this.remainingCharacters = 1000;
                if (this.medication.frequency) {
                    this.remainingCharacters -= this.medication.frequency.length;
                }
                else {
                    this.medication.frequency = '';
                }
                if (this.medication.eprescribe_instructions) {
                    this.remainingCharacters -= this.medication.eprescribe_instructions.length;
                }
                else {
                    this.medication.eprescribe_instructions = '';
                }
            };
            AddMedicationKitController.prototype.goToExceptionPage = function () {
                if (this.medication.name) {
                    this.MedicationService.currentMedication = this.medication;
                }
                else {
                    this.MedicationService.currentMedication = {
                        orderdate: this.medication.orderdate,
                        physician_id: this.medication.physician_id,
                    };
                }
                this.$state.go('patients.formulary-exception-requests.new');
            };
            AddMedicationKitController.$inject = [
                'SessionService',
                'medicationKits',
                'currentPatient',
                'MedicationKitsService',
                '$mdDialog',
                'MedicationService',
                'Medication',
                '$http',
                'logger',
                '$q',
                '$state',
                'AboutService',
                '$window'
            ];
            return AddMedicationKitController;
        }());
        angular.module('consolo').controller('AddMedicationKitController', AddMedicationKitController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientMedicationNoteController = /** @class */ (function () {
            function PatientMedicationNoteController(currentPatient, Session, $filter, options, cache, Lookup, $mdDialog, Medication, logger, medication) {
                var _this = this;
                this.$filter = $filter;
                this.options = options;
                this.$mdDialog = $mdDialog;
                this.Medication = Medication;
                this.logger = logger;
                this.medication = medication;
                this.today = new Date(new Date().toDateString());
                var user = Session.getUser();
                this.currentUser = user;
                this.currentUserId = user.id;
                if (!this.medication_note_id) {
                    this.medication_note = {
                        effective_date: this.today,
                        medication_id: this.medication.id,
                        patient_id: currentPatient.id,
                        user_id: this.currentUserId,
                        patient_time: {
                            user: this.currentUser,
                            user_id: this.currentUserId,
                            patient_id: currentPatient.id,
                            in_datetime: this.today,
                            out_datetime: this.today,
                        },
                    };
                }
                else {
                    var noteFilter = $filter('filter')(this.medication.medication_notes, function (n) {
                        return (n.id = _this.medication_note_id);
                    });
                    if (noteFilter.length > 0) {
                        this.medication_note = noteFilter[0];
                        this.medication_note.effective_date = new Date(this.medication_note.effective_date);
                    }
                }
                options.get('priorities').$promise.then(function (data) {
                    _this.priorities = data;
                });
                options.get('clinical_care_types').$promise.then(function (data) {
                    _this.clinical_care_types = data;
                });
                options.get('pain_ratings').$promise.then(function (data) {
                    _this.pain_intensity = data;
                });
            }
            PatientMedicationNoteController.prototype.applyOptions = function (field, options) {
                if (!field.values) {
                    field.values = [];
                }
                if (field.denormalized) {
                    field.options = options.map(function (a) {
                        return { id: a.name, description: a.name };
                    });
                }
                else {
                    field.options = options.map(function (a) {
                        return { id: a.id, description: a.name };
                    });
                }
                field.options = field.options.concat(field.values);
            };
            PatientMedicationNoteController.prototype.saveNote = function () {
                var _this = this;
                this.Medication.addNote(this.medication_note)
                    .$promise.then(function (response) {
                    _this.logger.success('Medication Note created.');
                    _this.$mdDialog.cancel();
                })
                    .catch(function (response) {
                    _this.logger.error(response.data.errors.join(', '));
                    _this.errors = response.data.errors;
                });
            };
            PatientMedicationNoteController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            PatientMedicationNoteController.$inject = [
                'currentPatient',
                'SessionService',
                '$filter',
                'options',
                'cache',
                'Lookup',
                '$mdDialog',
                'Medication',
                'logger',
                'medication',
            ];
            return PatientMedicationNoteController;
        }());
        angular
            .module('consolo')
            .controller('PatientMedicationNoteController', PatientMedicationNoteController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var DiscontinueController = /** @class */ (function () {
            function DiscontinueController(MedicationService, $mdDialog, medication, title, onlyDiscontinue, Session) {
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.medication = medication;
                this.title = title;
                this.onlyDiscontinue = onlyDiscontinue;
                this.Session = Session;
                this.createOrder = true;
                this.currentUser = this.Session.getUser();
                this.physician = this.currentUser.physician || this.medication.physician;
                if (!medication.dc) {
                    medication.dc = new Date().toDateString();
                }
                else {
                    this.createOrder = false;
                }
            }
            DiscontinueController.prototype.discontinue = function (createOrder) {
                if (!this.MedicationService.isPhysician && !this.option && this.createOrder) {
                    this.errors = ['You must select one of the options'];
                }
                else if (!this.medication.dc) {
                    this.errors = ['You must select a DC date.'];
                }
                else {
                    this.errors = null;
                    return this.$mdDialog.hide(this.MedicationService.discontinue(this.medication, this.physician, this.option, createOrder));
                }
            };
            DiscontinueController.prototype.discontinueAndCreate = function () {
                if (!this.MedicationService.isPhysician && !this.option && this.createOrder) {
                    this.errors = ['You must select one of the options'];
                }
                else if (!this.medication.dc) {
                    this.errors = ['You must select a DC date.'];
                }
                else {
                    this.errors = null;
                    return this.$mdDialog.hide(this.MedicationService.discontinueAndCreate(this.medication, this.physician, this.option));
                }
            };
            DiscontinueController.prototype.cancel = function () {
                this.medication.dc = null;
                this.$mdDialog.cancel();
            };
            DiscontinueController.$inject = [
                'MedicationService',
                '$mdDialog',
                'medication',
                'title',
                'onlyDiscontinue',
                'SessionService'
            ];
            return DiscontinueController;
        }());
        angular.module('consolo').controller('DiscontinueController', DiscontinueController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Disposal = /** @class */ (function () {
            function Disposal(medications, patientId) {
                var _this = this;
                this.patient_id = patientId;
                this.dispose = {};
                this.medication = { dc: null, disposition_method: null, disposed_comment: null };
                medications.forEach(function (medication) {
                    _this.dispose[medication.id] = { dispose: false, disposed_amount: null };
                });
            }
            return Disposal;
        }());
        var DisposeController = /** @class */ (function () {
            function DisposeController(currentPatient, MedicationService, activeMedications, $http, logger) {
                this.currentPatient = currentPatient;
                this.MedicationService = MedicationService;
                this.activeMedications = activeMedications;
                this.$http = $http;
                this.logger = logger;
                this.disposal = new Disposal(activeMedications, currentPatient.id);
                this.methodSelect = [
                    { id: null },
                    { id: 'Disposed according to federal guidelines' },
                    { id: 'Destroyed' },
                    { id: 'Returned' },
                    { id: 'Therapy Completed' },
                ];
            }
            DisposeController.prototype.dispose = function () {
                var _this = this;
                if (!this.disposal.medication.dc || !this.disposal.medication.disposition_method) {
                    this.errors = {
                        errors: ['You must select a discontinue date and disposal method before proceeding.'],
                    };
                }
                else {
                    this.$http.post(this.MedicationService.medicationUrl + '/dispose', this.disposal).then(function (data) {
                        _this.logger.success('Medications disposed.');
                        _this.MedicationService.replaceMedications(data.data);
                        _this.MedicationService.navigateTo('medications');
                    }, function (data) {
                        _this.errors = data.data;
                    });
                }
            };
            DisposeController.prototype.selectAll = function () {
                for (var _i = 0, _a = Object.keys(this.disposal.dispose); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this.disposal.dispose[key].dispose = true;
                }
            };
            DisposeController.prototype.clearAll = function () {
                for (var _i = 0, _a = Object.keys(this.disposal.dispose); _i < _a.length; _i++) {
                    var key = _a[_i];
                    this.disposal.dispose[key].dispose = false;
                }
            };
            DisposeController.$inject = [
                'currentPatient',
                'MedicationService',
                'activeMedications',
                '$http',
                'logger',
            ];
            return DisposeController;
        }());
        angular.module('consolo').controller('DisposeController', DisposeController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var EPrescribeMedicationController = /** @class */ (function () {
            function EPrescribeMedicationController(MedicationService, $mdDialog, medication) {
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.medication = medication;
                this.options = [
                    { id: 'moveToCurrent', description: 'Move To Current' },
                    { id: 'delete', description: 'Delete Medication' },
                ];
                this.medication.deleted_reason = 'E-Prescribe Stuck in Pending';
            }
            EPrescribeMedicationController.prototype.handleMedication = function () {
                if (this.option === 'moveToCurrent') {
                    return this.$mdDialog.hide(this.MedicationService.moveToCurrent(this.medication));
                }
                else if (this.option === 'delete') {
                    return this.$mdDialog.hide(this.MedicationService.delete(this.medication));
                }
                else {
                    this.error = ['You must select an option.'];
                }
            };
            EPrescribeMedicationController.$inject = ['MedicationService', '$mdDialog', 'medication'];
            return EPrescribeMedicationController;
        }());
        angular
            .module('consolo')
            .controller('EPrescribeMedicationController', EPrescribeMedicationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientMedicationController = /** @class */ (function () {
            function PatientMedicationController(currentPatient, Session, $filter, options, Lookup, $mdDialog, Medication, $http, SectionManager, medication, $window, logger, $state, MedicationService, PharmacyService, $timeout, $location, $anchorScroll, redirect, $q, $mdMedia, MedicationFavoritesService, cdsMedicationAllergiesService, IndicatorsService, AboutService, VaccinationService) {
                var _this = this;
                this.currentPatient = currentPatient;
                this.Session = Session;
                this.$filter = $filter;
                this.options = options;
                this.Lookup = Lookup;
                this.$mdDialog = $mdDialog;
                this.Medication = Medication;
                this.$http = $http;
                this.medication = medication;
                this.$window = $window;
                this.logger = logger;
                this.$state = $state;
                this.MedicationService = MedicationService;
                this.PharmacyService = PharmacyService;
                this.$timeout = $timeout;
                this.$location = $location;
                this.$anchorScroll = $anchorScroll;
                this.redirect = redirect;
                this.$q = $q;
                this.$mdMedia = $mdMedia;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.cdsMedicationAllergiesService = cdsMedicationAllergiesService;
                this.IndicatorsService = IndicatorsService;
                this.AboutService = AboutService;
                this.VaccinationService = VaccinationService;
                this.today = new Date();
                this.readonly = false;
                this.filterMediSpanOnly = false;
                this.filterFormularyOnly = false;
                this.showExceptionRequest = false;
                this.showEMARSection = false;
                this.showMiscDetailsSection = false;
                this.showDosageTemplate = false;
                this.interactionsAcknowledged = false;
                this.reasonRequired = false;
                this.remainingCharacters = 1000;
                this.loadingInteractions = false;
                this.prnReasonFilter = null;
                this.selectedItem = [];
                this.prnReasonsRequired = false;
                this.favoritesAvailable = false;
                this.splitRx = false;
                this.splitPharmacy = {};
                this.splitRefills = 0;
                this.cdsItemId = '130';
                this.cdsItemUrl = null;
                this.icdsToSelectFrom = [];
                this.vaccinationsOn = false;
                this.slidingScaleDosageLevels = [];
                this.isPerSlidingScale = false;
                var session = Session.get();
                this.currentUser = session.user;
                this.MedicationService.currentUser = session.user;
                this.user_dashboard_url = session.user.dashboard_url;
                this.prnReasonsRequired = session.agency.reasons_required_for_prn_medication;
                this.slidingScaleDosageLevels = [{ code: "Low Dose", description: "Low Dose" }, { code: "Moderate Dose", description: "Moderate Dose" }, { code: "High Dose", description: "High Dose" }];
                this.vaccinationsOn = session.agency.has_immunizations && VaccinationService.updatePermission;
                this.IndicatorsService.getCurrentIndicator(this.currentPatient.id).then(function (indicator) {
                    _this.icdsToSelectFrom = indicator.icd10s.map(function (icd10) {
                        icd10.display = icd10.code + ': ' + icd10.description;
                        return icd10;
                    });
                });
                if (session.agency.formulary_enabled && session.agency.formulary_enforced) {
                    this.filterFormularyOnly = true;
                    this.showExceptionRequest = true;
                }
                else {
                    this.filterMediSpanOnly =
                        session.agency.epcs_on || session.agency.medispan_only_meds_checked_by_default;
                }
                if (session.agency.has_cds) {
                    this.getCdsItemLink();
                }
                this.startTime = new Date();
                if (medication) {
                    this.pageTitle = 'Edit Medication';
                    this.setDosageUnit();
                    this.MedicationService.setDosageRoute(medication, medication.route_of_admin);
                    this.interactionsAcknowledged = true;
                    if (medication.isCurrent()) {
                        this.readonly = true;
                    }
                    this.setShowDosageTemplate(true);
                    this.reasonRequired = !!medication.formulary_explanation;
                    if (medication.sliding_scale) {
                        this.isPerSlidingScale = true;
                    }
                }
                else {
                    this.pageTitle = 'New Medication';
                    this.MedicationService.getNewMedication().then(function (medication) {
                        medication.name = '';
                        medication.frequency = '';
                        if (!medication.creation_source) {
                            medication.creation_source = 'manual';
                        }
                        //Set ordering MD to current user if they are a physician
                        if (_this.currentUser.physician && !_this.currentUser.physician.social_worker) {
                            medication.physician = _this.currentUser.physician;
                            _this.favoritesAvailable = _this.currentUser.physician.has_medication_favorites;
                        }
                        _this.medication = medication;
                        _this.updateRemainingCharacters();
                        _this.readonly = false;
                    });
                }
                if (MedicationService.epcsOn) {
                    this.splitNotesToPharmacist = { notes_to_pharmacist: null };
                    if (currentPatient.secondary_pharmacy) {
                        this.splitPharmacy.pharmacy_id = currentPatient.secondary_pharmacy.id;
                        this.splitPharmacy.pharmacy = currentPatient.secondary_pharmacy;
                    }
                    if (currentPatient.secondary_surescripts_pharmacy) {
                        this.splitPharmacy.surescripts_pharmacy_id =
                            currentPatient.secondary_surescripts_pharmacy.id;
                        this.splitPharmacy.surescripts_pharmacy = currentPatient.secondary_surescripts_pharmacy;
                    }
                }
                this.numberOfDays = [];
                this.numberOfDays.push({ id: '', description: '' });
                for (var i = 1; i < 31; i++) {
                    var num_date = new Date();
                    this.numberOfDays.push({
                        id: i,
                        description: i + ' Days',
                    });
                }
            }
            PatientMedicationController.prototype.acknowledgeRequired = function () {
                return (this.medication &&
                    ((this.medication.drug_interactions && this.medication.drug_interactions.length > 0) ||
                        (this.medication.allergy_interactions &&
                            this.medication.allergy_interactions.length > 0)) &&
                    !this.interactionsAcknowledged);
            };
            PatientMedicationController.prototype.findIcd = function (description) {
                var foundIcd;
                this.icdsToSelectFrom.some(function (icd) {
                    if (icd.description === description) {
                        foundIcd = icd;
                        return true;
                    }
                });
                return foundIcd;
            };
            PatientMedicationController.prototype.addFromFavorites = function () {
                var _this = this;
                return this.$mdDialog
                    .show({
                    template: '<md-dialog flex="80" aria-label="Add Signature dialog">' +
                        '  <md-dialog-content>' +
                        '<add-to-medication flex=""></add-to-medication>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                })
                    .then(function (medicationFavorite) {
                    medicationFavorite.physician_id = null;
                    medicationFavorite.id = null;
                    var orderdate = _this.medication.orderdate;
                    angular.copy(medicationFavorite, _this.medication);
                    _this.medication.patient_id = _this.currentPatient.id;
                    _this.medication.orderdate = orderdate;
                });
            };
            PatientMedicationController.prototype.addToFavorites = function () {
                var _this = this;
                return this.$mdDialog
                    .show({
                    template: '<md-dialog flex="80" aria-label="Add Signature dialog">' +
                        '  <md-dialog-content>' +
                        '<select-physician flex=""></select-physician>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                })
                    .then(function (physician_id) {
                    var copyMed = angular.copy(_this.medication);
                    copyMed.physician_id = physician_id;
                    copyMed.id = null;
                    _this.MedicationFavoritesService.upsert(copyMed).then(function () {
                        _this.logger.success('The Medication Favorite has been added.');
                    }, function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    });
                });
            };
            PatientMedicationController.prototype.goToExceptionPage = function () {
                if (this.medication.name) {
                    this.MedicationService.currentMedication = this.medication;
                }
                else {
                    this.MedicationService.currentMedication = {
                        orderdate: this.medication.orderdate,
                        physician_id: this.medication.physician_id,
                    };
                }
                this.$state.go('patients.formulary-exception-requests.new');
            };
            PatientMedicationController.prototype.medicationLookup = function (q) {
                return this.MedicationService.searchAllSources(q, this.filterMediSpanOnly, this.filterFormularyOnly, this.medication ? this.medication.physician_id : null, true, false);
            };
            PatientMedicationController.prototype.optionsSort = function (a, b) {
                if (a.code < b.code) {
                    return -1;
                }
                else if (a.code > b.code) {
                    return 1;
                }
                else {
                    return 0;
                }
            };
            PatientMedicationController.prototype.cancel = function () {
                this.$mdDialog.cancel();
            };
            PatientMedicationController.prototype.resetDosageTimes = function () {
                this.MedicationService.populateDosageTimes(this.medication);
                this.setShowDosageTemplate(false);
            };
            PatientMedicationController.prototype.filterPrnReasons = function (searchTerm) {
                var _this = this;
                return this.MedicationService.isReady().then(function () {
                    return _this.MedicationService.select_options['prn_reasons'].filter(function (prnReason) {
                        return prnReason.description.toLowerCase().match(searchTerm.toLowerCase());
                    });
                });
            };
            PatientMedicationController.prototype.selectPrnReason = function (prnReason) {
                if (prnReason && this.medication.prn_reason_ids.indexOf(prnReason.id) === -1) {
                    this.medication.prn_reason_ids.push(prnReason.id);
                    this.medication.prn_reasons.push(prnReason);
                    this.selectedItem = [];
                }
            };
            PatientMedicationController.prototype.setShowDosageTemplate = function (loadingMedication) {
                var _this = this;
                switch (this.medication.dosage_frequency_unit) {
                    case 'hour':
                    case 'day':
                    case 'week':
                        this.showDosageTemplate = this.medication.is_scheduled;
                        break;
                    case 'one_time_dose':
                        if (!loadingMedication) {
                            this.$mdDialog
                                .show({
                                templateUrl: '/y/templates/patients/sections/medications/one-time-dose-modal.html',
                                controller: 'OneTimeDoseController as vm',
                                locals: { medication: this.medication },
                                multiple: true,
                                skipHide: true,
                                preserveScope: true,
                            })
                                .then(function () {
                                _this.logger.success('Medication automatically scheduled to go on EMAR.');
                            });
                        }
                        break;
                    default:
                        this.showDosageTemplate = false;
                }
            };
            PatientMedicationController.prototype.setDCDate = function () {
                if (this.medication.orderdate) {
                    var dcDate = new Date();
                    dcDate.setDate(this.medication.orderdate.getDate() + this.numberOfDaysSelected);
                    this.MedicationService.setDCDate(this.medication, dcDate);
                }
                else {
                    this.logger.error('You must have an order date entered to use this feature.');
                }
            };
            PatientMedicationController.prototype.changeDosageTemplate = function () {
                if (!this.readonly) {
                    switch (this.medication.dosage_frequency_unit) {
                        case 'hour':
                            this.medication.dosage_frequency = 24 / this.dosage_template.dosage_time_templates.length;
                            break;
                        case 'day':
                        case 'week':
                            this.medication.dosage_frequency = this.dosage_template.dosage_time_templates.length;
                            break;
                        default:
                    }
                }
                this.medication.dosage_times = [];
                for (var _i = 0, _a = this.dosage_template.dosage_time_templates; _i < _a.length; _i++) {
                    var dosage_time = _a[_i];
                    this.medication.dosage_times.push({ time: dosage_time.time });
                }
            };
            PatientMedicationController.prototype.save = function () {
                var _this = this;
                if (!this.isPerSlidingScale) {
                    this.medication.sliding_scale = null;
                }
                else {
                    this.medication.dosage_amount_low = this.medication.sliding_scale.sliding_scale_ranges[0].units;
                    this.medication.dosage_amount_high = this.medication.sliding_scale.sliding_scale_ranges[this.medication.sliding_scale.sliding_scale_ranges.length - 1].units;
                }
                if (this.medication.isValid() && (!this.splitRx || this.splitQuantity > 0)) {
                    var endTime = new Date();
                    var timeOnScreen = (endTime - this.startTime) / 1000;
                    if (!this.medication.id) {
                        this.medication.creation_time_in_seconds = timeOnScreen;
                    }
                    this.medication.time_on_edit_screen_in_seconds += timeOnScreen;
                    return this.MedicationService.save(this.medication)
                        .then(function (savedMedication) {
                        var addFavoritePromise = _this.$q.defer();
                        if (_this.medication.add_to_physician_favorites) {
                            _this.MedicationFavoritesService.upsert(_this.MedicationFavoritesService.getMedicationValues(savedMedication))
                                .then(function (medicationFavorite) {
                                _this.logger.success('The medication favorite has been created.');
                            })
                                .catch(function (errors) {
                                _this.logger.error(errors.data.errors.join(', '));
                                _this.errors = errors.data.errors;
                            })
                                .finally(function () {
                                addFavoritePromise.resolve();
                            });
                        }
                        else {
                            addFavoritePromise.resolve();
                        }
                        return addFavoritePromise.promise.then(function () {
                            var splitRxPromise = _this.$q.defer();
                            if (_this.splitRx) {
                                _this.MedicationService.splitRx(savedMedication.id, _this.splitQuantity, _this.splitPharmacy.pharmacy_id, _this.splitPharmacy.surescripts_pharmacy_id, _this.splitNotesToPharmacist.notes_to_pharmacist, _this.splitRefills)
                                    .then(function (response) {
                                    _this.logger.success('The splitRx has been created.');
                                    _this.MedicationService.get(savedMedication.id).then(function (updatedMedication) {
                                        savedMedication = updatedMedication;
                                        _this.MedicationService.replaceMedications([updatedMedication]);
                                        splitRxPromise.resolve();
                                    });
                                })
                                    .catch(function (errors) {
                                    _this.logger.error(errors.data.errors.join(', '));
                                });
                            }
                            else {
                                splitRxPromise.resolve();
                            }
                            return splitRxPromise.promise.then(function () {
                                if (_this.redirect) {
                                    _this.$state.go('patients.medications', {
                                        patientId: _this.medication.patient_id,
                                    });
                                }
                                else {
                                    angular.copy(savedMedication, _this.medication);
                                    return _this.$mdDialog.hide(_this.medication);
                                }
                            });
                        });
                    }, function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                        _this.errors = errors.data.errors;
                    })
                        .finally(function () {
                        _this.MedicationService.saving = false;
                    });
                }
                else if (this.splitRx &&
                    (this.splitQuantity === undefined || this.splitQuantity <= 0) &&
                    (this.medication.quantity === undefined || this.medication.quantity <= 0)) {
                    this.errors = ['You must enter a quantity for the medication and for the split rx.'];
                    this.logger.error(this.errors.join(', '));
                }
                else {
                    this.errors = this.medication.errors;
                    this.logger.error(this.errors.join(', '));
                }
            };
            PatientMedicationController.prototype.setDosageUnit = function () {
                var _this = this;
                if (this.medication.dosage_unit_id) {
                    this.MedicationService.select_options['dosage_units'].forEach(function (dosage_unit) {
                        if (_this.medication.dosage_unit_id === dosage_unit.id) {
                            _this.medication.dosage_unit_display = dosage_unit.description;
                            _this.medication.dosage_unit = dosage_unit;
                            return true;
                        }
                    });
                }
                return false;
            };
            PatientMedicationController.prototype.filterSync = function (medispanFlag) {
                if (medispanFlag) {
                    if (this.filterFormularyOnly && this.filterMediSpanOnly) {
                        this.filterFormularyOnly = false;
                    }
                }
                else if (this.filterFormularyOnly) {
                    this.filterMediSpanOnly = false;
                }
                this.selectedMedication = null;
            };
            PatientMedicationController.prototype.setMedicationOnSelection = function () {
                var _this = this;
                var defer = this.$q.defer();
                var low_dose = this.medication.dosage_amount_low;
                var high_dose = this.medication.dosage_amount_high;
                var ss = this.medication.sliding_scale;
                if (this.selectedMedication && this.selectedMedication.sliding_scale) {
                    this.isPerSlidingScale = true;
                }
                if (this.selectedMedication && this.selectedMedication.ndc) {
                    this.interactionsAcknowledged = false;
                    this.loadingInteractions = true;
                    this.$http
                        .get('/api/v1/medications/new_interactions', {
                        params: {
                            new_medication_name: this.selectedMedication.name,
                            new_medication_ndc: this.selectedMedication.ndc,
                            patient_id: this.currentPatient.id,
                        },
                    })
                        .then(function (response) {
                        var data = response.data;
                        if (data.drug_interactions) {
                            data.drug_interactions.forEach(function (drugInteraction) {
                                drugInteraction.user = _this.currentUser;
                            });
                        }
                        if (data.allergy_interactions) {
                            data.allergy_interactions.forEach(function (allergyInteraction) {
                                allergyInteraction.user = _this.currentUser;
                            });
                        }
                        _this.medication.drug_interactions = data.drug_interactions;
                        _this.medication.allergy_interactions = data.allergy_interactions;
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.join(', '));
                    })
                        .finally(function () {
                        if (!_this.medication.drug_interactions) {
                            _this.medication.drug_interactions = [];
                        }
                        if (!_this.medication.allergy_interactions) {
                            _this.medication.allergy_interactions = [];
                        }
                        _this.reasonRequired = _this.selectedMedication.reason_required_to_add;
                        if (_this.medication.drug_interactions.length !== 0 ||
                            _this.medication.allergy_interactions.length !== 0) {
                            _this.$mdDialog
                                .show({
                                preserveScope: true,
                                templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                controller: 'AcknowledgeInteractionsController as vm',
                                multiple: true,
                                skipHide: true,
                                locals: { medication: _this.medication },
                            })
                                .then(function (response) {
                                if (response) {
                                    _this.interactionsAcknowledged = true;
                                    defer.resolve();
                                }
                            });
                        }
                        else {
                            defer.resolve();
                        }
                        _this.loadingInteractions = false;
                    });
                }
                else if (this.selectedMedication && this.selectedMedication.compound_id) {
                    this.interactionsAcknowledged = false;
                    this.loadingInteractions = true;
                    var allergyInteractions_1 = [];
                    var drugInteractions_1 = [];
                    var interactionPromises_1 = [];
                    this.selectedMedication.ingredients.forEach(function (ingredient) {
                        if (ingredient.name && ingredient.ndc) {
                            interactionPromises_1.push(_this.$http
                                .get('/api/v1/medications/new_interactions', {
                                params: {
                                    new_medication_name: ingredient.name,
                                    new_medication_ndc: ingredient.ndc,
                                    patient_id: _this.currentPatient.id,
                                },
                            })
                                .then(function (response) {
                                var data = response.data;
                                if (data.drug_interactions) {
                                    data.drug_interactions.forEach(function (drugInteraction) {
                                        drugInteraction.user = _this.currentUser;
                                    });
                                    drugInteractions_1 = drugInteractions_1.concat(data.drug_interactions);
                                }
                                if (data.allergy_interactions) {
                                    data.allergy_interactions.forEach(function (allergyInteraction) {
                                        allergyInteraction.user = _this.currentUser;
                                    });
                                    allergyInteractions_1 = allergyInteractions_1.concat(data.allergy_interactions);
                                }
                            }));
                        }
                    });
                    this.$q.all(interactionPromises_1).then(function () {
                        _this.medication.drug_interactions = drugInteractions_1;
                        _this.medication.allergy_interactions = allergyInteractions_1;
                        if (_this.medication.drug_interactions.length !== 0 ||
                            _this.medication.allergy_interactions.length !== 0) {
                            _this.$mdDialog
                                .show({
                                preserveScope: true,
                                templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                controller: 'AcknowledgeInteractionsController as vm',
                                multiple: true,
                                skipHide: true,
                                locals: { medication: _this.medication },
                            })
                                .then(function (response) {
                                if (response) {
                                    _this.interactionsAcknowledged = true;
                                    defer.resolve();
                                }
                            });
                        }
                        else {
                            defer.resolve();
                        }
                        _this.loadingInteractions = false;
                    });
                }
                else if (!this.selectedMedication && !this.medication.id) {
                    this.medication = new consolo.domain.Medication({
                        orderdate: this.medication.orderdate,
                        physician: this.medication.physician,
                        physician_id: this.medication.physician_id,
                        patient_id: this.medication.patient_id,
                    });
                }
                else {
                    defer.resolve();
                }
                defer.promise.then(function () {
                    _this.MedicationService.setMedicationValues(_this.medication, _this.selectedMedication);
                    _this.medication.setRefillsDisabled();
                    _this.updateFrequency();
                    if (_this.selectedMedication && _this.selectedMedication.approval_required) {
                        _this.goToExceptionPage();
                    }
                });
                if (this.isPerSlidingScale && !this.selectedMedication.sliding_scale) {
                    this.medication.dosage_amount_low = low_dose;
                    this.medication.dosage_amount_high = high_dose;
                    this.medication.sliding_scale = ss;
                }
            };
            PatientMedicationController.prototype.setPhysician = function (physician) {
                this.medication.physician = physician;
                if (physician) {
                    this.medication.physician_id = physician.id;
                    this.favoritesAvailable = physician.has_medication_favorites;
                }
                else {
                    this.favoritesAvailable = false;
                    this.medication.physician_id = null;
                }
            };
            PatientMedicationController.prototype.updateSlidingScale = function () {
                if (this.isPerSlidingScale) {
                    this.medication.dosage_amount_low = 0;
                    this.medication.dosage_amount_high = 0;
                    if (!this.medication.sliding_scale) {
                        this.medication.sliding_scale = this.medication.newSlidingScale();
                    }
                }
                else {
                    this.medication.sliding_scale = null;
                }
            };
            PatientMedicationController.prototype.updateFrequency = function () {
                var _this = this;
                this.MedicationService.getFrequency(this.medication).then(function (frequency) {
                    _this.medication.frequency = frequency;
                    _this.updateRemainingCharacters();
                });
            };
            PatientMedicationController.prototype.updateRemainingCharacters = function () {
                this.remainingCharacters = 1000;
                if (this.medication.frequency) {
                    this.remainingCharacters -= this.medication.frequency.length;
                }
                else {
                    this.medication.frequency = '';
                }
                if (this.medication.eprescribe_instructions) {
                    this.remainingCharacters -= this.medication.eprescribe_instructions.length;
                }
                else {
                    this.medication.eprescribe_instructions = '';
                }
            };
            PatientMedicationController.prototype.getCdsItemLink = function () {
                var _this = this;
                this.cdsMedicationAllergiesService
                    .getCdsForMedicationAllergies(this.cdsItemId)
                    .then(function (response) {
                    var cdsItem = response.data;
                    _this.cdsItemUrl = cdsItem.doc_link;
                });
            };
            PatientMedicationController.prototype.openLink = function (url) {
                var _this = this;
                var event = "Clinical Decision Support Item";
                var message = "Clicked Medication Allergy CDS Item: " + url;
                var patient_id = this.currentPatient.id;
                this.AboutService.createNewAuditLog(event, message, patient_id)
                    .then(function () {
                    _this.$window.open(url);
                });
            };
            PatientMedicationController.prototype.getSSLength = function () {
                var sslength = 0;
                if (this.medication) {
                    var ss = this.medication.sliding_scale ? this.medication.sliding_scale : null;
                    if (ss) {
                        var ssl = "Per Sliding Scale";
                        ssl += ss.dose_level ? " " + ss.dose_level : "";
                        ssl += ", ";
                        var var_dif = 2;
                        var ranges = ss.sliding_scale_ranges;
                        var len = ss.sliding_scale_ranges.length;
                        var name = this.medication.name ? " " + this.medication.name : "";
                        var route = this.medication.route ? " " + this.medication.route : "";
                        for (var i = 0; i < len; i++) {
                            if (i < len - 1) {
                                ssl += "For BG " + ranges[i].low_range;
                                ssl += " to " + ranges[i].high_range;
                                ssl += " give " + ranges[i].units + " units" + name + route + ", ";
                            }
                            else {
                                ssl += "For BG greater than " + ranges[i].high_range;
                                ssl += " give " + ranges[i].units + " units" + name + route;
                                if (ranges[i].comments) {
                                    ssl += ", " + ranges[i].comments;
                                }
                            }
                        }
                        sslength = ssl.length + var_dif;
                    }
                }
                return sslength;
            };
            PatientMedicationController.prototype.getFreqLength = function () {
                var frequencyLength = 0;
                if (this.medication) {
                    frequencyLength = this.medication.frequency && this.medication.frequency !== ' ( ) ' ? this.medication.frequency.replace(/\(.*/, '').length : 0;
                }
                return frequencyLength;
            };
            PatientMedicationController.$inject = [
                'currentPatient',
                'SessionService',
                '$filter',
                'options',
                'Lookup',
                '$mdDialog',
                'Medication',
                '$http',
                'ConsoloPatientSectionManagerService',
                'medication',
                '$window',
                'logger',
                '$state',
                'MedicationService',
                'PharmacyService',
                '$timeout',
                '$location',
                '$anchorScroll',
                'redirect',
                '$q',
                '$mdMedia',
                'MedicationFavoritesService',
                'cdsMedicationAllergiesService',
                'IndicatorsService',
                'AboutService',
                'VaccinationService'
            ];
            return PatientMedicationController;
        }());
        angular.module('consolo').controller('PatientMedicationController', PatientMedicationController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var services;
    (function (services) {
        'use strict';
        // Store and query connectivity status
        var MedicationService = /** @class */ (function () {
            function MedicationService($filter, $timeout, SyncManager, Session, Medication, NetworkManager, CurrentPatient, $mdDialog, cache, DosageDateTemplate, Lookup, logger, $state, $http, options, CSV, PdfGenerator, $q, PermissionsService, MedicationFavoritesService, PubSubService, CompoundsService) {
                var _this = this;
                this.$filter = $filter;
                this.$timeout = $timeout;
                this.SyncManager = SyncManager;
                this.Session = Session;
                this.Medication = Medication;
                this.NetworkManager = NetworkManager;
                this.CurrentPatient = CurrentPatient;
                this.$mdDialog = $mdDialog;
                this.cache = cache;
                this.DosageDateTemplate = DosageDateTemplate;
                this.Lookup = Lookup;
                this.logger = logger;
                this.$state = $state;
                this.$http = $http;
                this.options = options;
                this.CSV = CSV;
                this.PdfGenerator = PdfGenerator;
                this.$q = $q;
                this.PermissionsService = PermissionsService;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.PubSubService = PubSubService;
                this.CompoundsService = CompoundsService;
                this.epcsOn = false;
                this.today = new Date();
                this.saving = false;
                this.onPhysicianOrderPage = false;
                this.loadingSpinner = false;
                this.spinnerText = '';
                this.one_hour_medication_administration_alert = false;
                this.menuIsOpen = false;
                this.showTooltip = false;
                this.online = false;
                this.offlineText = 'You are currently offline.  Available actions & data are limited.';
                this.medicationUrl = '/api/v1/medications';
                this.isPhysician = false;
                this.updatePermission = false;
                this.viewPermission = false;
                this.deletePermission = false;
                this.createPermission = false;
                this.cancelPermission = false;
                this.viewFormularyPermission = false;
                this.viewCompoundsPermission = false;
                this.viewFavoritesPermission = false;
                this.lookupsReady = this.$q.defer();
                this.medicationFormularyEnabled = false;
                this.medicationFormularyEnforced = false;
                this.compoundsEnabled = false;
                this.reapplyFilters = false;
                var promiseChain = [];
                this.reasonCodes = [
                    { id: 'AA', description: 'Patient unknown to the Provider' },
                    { id: 'AB', description: 'Patient never under Provider' },
                    { id: 'AC', description: 'Patient no longer under Provider care' },
                    { id: 'AE', description: 'Medication never prescribed for the patient' },
                    { id: 'AF', description: 'Patient should contact Provider first' },
                    { id: 'AL', description: 'Change not appropriate' },
                    { id: 'AM', description: 'Patient needs appointment' },
                    { id: 'AN', description: 'Prescriber not associated with this practice or location' },
                    { id: 'AO', description: 'No attempt will be made to obtain Prior Authorization' },
                    {
                        id: 'AP',
                        description: 'Request already responded to by other means (e.g. phone or fax)',
                    },
                    {
                        id: 'AR',
                        description: 'Unable to cancel prescription; prescription was transferred to another pharmacy',
                    },
                    { id: 'BE', description: 'Medication denied at patient request' },
                ];
                var options_list = [
                    'dosage_units',
                    'intensities',
                    'prn_reasons',
                    'topical_sites',
                    'dosage_forms',
                ];
                this.select_options = {};
                options_list.forEach(function (option) {
                    promiseChain.push(_this.options.get(option).$promise.then(function (data) {
                        _this.select_options[option] = data;
                        return _this.$q.when(true);
                    }));
                });
                promiseChain.push(this.$http.get('/api/v1/dosage_routes').then(function (response) {
                    var allDosageRoutes = response.data;
                    _this.select_options['dosage_routes'] = [];
                    allDosageRoutes.forEach(function (route) {
                        if (route.fda_description) {
                            _this.select_options['dosage_routes'].push(route);
                        }
                    });
                }));
                if (this.PermissionsService.can('show', 'notes_to_pharmacists')) {
                    promiseChain.push(this.$http
                        .get('/api/v1/notes_to_pharmacists')
                        .then(function (response) {
                        _this.notesToPharmacists = response.data;
                    })
                        .catch(function (errors) {
                        return null;
                    }));
                }
                this.disposeMethod = [
                    { description: '' },
                    { description: 'Diposed according to federal guidelines' },
                    { description: 'Destroyed' },
                    { description: 'Returned' },
                    { description: 'Therapy Completed' },
                ];
                this.medicationCache = cache(Medication, 'medications', { encrypt: true, force: true });
                this.drugInteractionsModel = {
                    id_field: 'drugInteractions',
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Source', field: 'medication_name' },
                        { title: 'Adverse', field: 'adverse_medication_name' },
                        { title: 'Severity', field: 'severity' },
                        { title: 'Effects', field: 'effects' },
                        { title: 'Acknowledged By', field: 'user.name' },
                    ],
                };
                cache(DosageDateTemplate, 'dosage_date_templates', {})
                    .query()
                    .$promise.then(function (templates) {
                    _this.dosageTemplates = templates;
                });
                this.disposeMethod = [
                    { description: '' },
                    { description: 'Diposed according to federal guidelines' },
                    { description: 'Destroyed' },
                    { description: 'Returned' },
                    { description: 'Therapy Completed' },
                ];
                this.dosageFrequency = [
                    { id: 'one_time_dose', description: 'One Time Dose' },
                    { id: 'continuous', description: 'Continuous' },
                    { id: 'minute', description: 'Minute' },
                    { id: 'hour', description: 'Hour' },
                    { id: 'day', description: 'Day' },
                    { id: 'week', description: 'Week' },
                    { id: 'month', description: 'Month' },
                ];
                this.daysOfWeek = [
                    { id: 'Monday' },
                    { id: 'Tuesday' },
                    { id: 'Wednesday' },
                    { id: 'Thursday' },
                    { id: 'Friday' },
                    { id: 'Saturday' },
                    { id: 'Sunday' },
                ];
                this.allergyInteractionsModel = {
                    id_field: 'allergyInteractions',
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Allergen', field: 'allergy_name' },
                        { title: 'Medication', field: 'medication_name' },
                        { title: 'Severity', field: 'severity' },
                        { title: 'Effects', field: 'effects' },
                        { title: 'Acknowledged By', field: 'user.name' },
                    ],
                };
                this.numberOfDays = [];
                this.numberOfDays.push({ id: '', description: '' });
                for (var i = 1; i < 31; i++) {
                    var num_date = new Date();
                    this.numberOfDays.push({
                        id: num_date.setDate(num_date.getDate() + i),
                        description: i + ' Days',
                    });
                }
                this.online = this.NetworkManager.isOnline();
                this.NetworkManager.offlineHandler.onEvent('MedicationsController', function () {
                    _this.online = false;
                });
                this.NetworkManager.onlineHandler.onEvent('MedicationsController', function () {
                    _this.online = true;
                    _this.loadMedicationData();
                });
                var session = Session.get();
                this.currentUser = session.user;
                this.user_dashboard_url = session.user.dashboard_url;
                this.epcsOn = session.agency.epcs_on;
                this.isPhysician =
                    this.currentUser.physician !== null && this.currentUser.physician.id !== null;
                this.one_hour_medication_administration_alert =
                    session.agency.one_hour_medication_administration_alert;
                this.medicationFormularyEnabled = session.agency.formulary_enabled;
                this.medicationFormularyEnforced = session.agency.formulary_enforced;
                this.compoundsEnabled = session.agency.compounds_enabled;
                this.updatePermission = this.PermissionsService.can('update', 'medications');
                this.createPermission = this.PermissionsService.can('create', 'medications');
                this.deletePermission = this.PermissionsService.can('destroy', 'medications');
                this.viewPermission = this.PermissionsService.can('show', 'medications');
                this.cancelPermission = this.PermissionsService.can('cancel', 'medications');
                this.viewFormularyPermission = this.PermissionsService.can('show', 'medication_formulary');
                this.viewCompoundsPermission = this.PermissionsService.can('show', 'compounds');
                this.viewFavoritesPermission = this.PermissionsService.can('show', 'medication_favorites');
                this.select_options['yes_no_na'] = [
                    { value: '' },
                    { value: 'Yes' },
                    { value: 'No' },
                    { value: 'N/A' },
                ];
                this.select_options['other_status'] = [
                    { id: 'stat', description: 'ASAP' },
                    { id: 'fileOnly', description: 'File Only' },
                    { id: '', description: 'None' },
                    { id: 'notInUse', description: 'Not in Use' },
                    { id: 'sameDay', description: 'Same Day' },
                    { id: 'tomorrow', description: 'Tomorrow' },
                ];
                this.select_options['other_delivery'] = [
                    { id: 'officeDelivery', description: 'Hospice Office' },
                    { id: '', description: 'None' },
                    { id: 'patientDelivery', description: 'Patient' },
                    { id: 'pickup', description: 'Pick-up' },
                ];
                this.dosageUnitsToConvertToML = [
                    'Concentrate',
                    'Elixir',
                    'Emulsion',
                    'Extract',
                    'Gel Form Soln',
                    'Liquid',
                    'Liquid ER',
                    'IN Oil',
                    'Pudding',
                    'Solution',
                    'For Solution',
                    'Suspension',
                    'For Suspension',
                    'Syrup',
                    'Tincture',
                ];
                this.$q.all(promiseChain).finally(function () {
                    _this.lookupsReady.resolve(true);
                });
            }
            MedicationService.prototype.clearMedications = function () {
                this.medications = null;
            };
            MedicationService.prototype.isReady = function () {
                return this.lookupsReady.promise.then(function () {
                    return true;
                });
            };
            MedicationService.prototype.frequencyDisplay = function (medication) {
                if (medication) {
                    switch (medication.dosage_frequency_unit) {
                        case 'hour':
                            return 'Hours';
                        case 'day':
                            return 'Times a Day';
                        case 'week':
                            return 'Times a Week';
                        case 'minute':
                            return 'Minutes';
                        case 'month':
                            return 'Times a Month';
                        default:
                            return '';
                    }
                }
                return '';
            };
            MedicationService.prototype.loadMedicationData = function () {
                var _this = this;
                this.getSignatures();
                this.getCurrentMedicationSet();
                if (!this.online) {
                    this.SyncManager.activate().then(function (data) {
                        if (!data.data.includeMedications) {
                            _this.offlineText =
                                'You are currently offline and do not have "Include Patient Medications" enabled in the Offline Dashboard. ' +
                                    'Most medication data will be unavailable.  To enable, next time you are online go to Device Settings ' +
                                    '-> Offline Dashboard -> Enable Include Patient Medications -> Click the "Refresh" button';
                        }
                    });
                }
                return this.getMedications();
            };
            MedicationService.prototype.setPharmacy = function (patient_id, object) {
                var _this = this;
                this.$http
                    .get('/api/v1/patient_record_defaults/current?patient_id=' + patient_id)
                    .then(function (response) {
                    var patientRecordDefault = response.data;
                    if (!object) {
                        object = {};
                    }
                    if (patientRecordDefault.pharmacy) {
                        object['pharmacy'] = _this.CurrentPatient.patient.pharmacy;
                        object['pharmacy_id'] = _this.CurrentPatient.patient.pharmacy.id;
                    }
                    else if (patientRecordDefault.surescripts_pharmacy) {
                        object['surescripts_pharmacy'] = patientRecordDefault.surescripts_pharmacy;
                        object['surescripts_pharmacy_id'] = patientRecordDefault.surescripts_pharmacy.id;
                    }
                });
            };
            MedicationService.prototype.getAdverseEffects = function (gpi) {
                return this.$http
                    .get(this.medicationUrl + '/adverse_effects_by_medication?gpi=' + gpi)
                    .then(function (data) {
                    return data.data;
                });
            };
            MedicationService.prototype.get = function (id) {
                return this.$http.get(this.medicationUrl + '/' + id).then(function (response) {
                    return new consolo.domain.Medication(response.data);
                });
            };
            MedicationService.prototype.getFrequency = function (medication) {
                var frequencyParams = {
                    name: medication.name,
                    dose: medication.dose,
                    doses: medication.doses,
                    route: medication.route,
                    frequency: medication.frequency,
                    dosage_frequency_unit: medication.dosage_frequency_unit,
                    dosage_frequency: medication.dosage_frequency,
                    dosage_unit_id: medication.dosage_unit_id,
                    dosage_amount_high: medication.dosage_amount_high,
                    dosage_amount_low: medication.dosage_amount_low,
                    route_of_admin: medication.route_of_admin,
                    form: medication.form,
                };
                return this.$http
                    .get(this.medicationUrl + '/frequency/value', { params: frequencyParams })
                    .then(function (response) {
                    return response.data;
                });
            };
            MedicationService.prototype.getMedicationsByIds = function (ids) {
                var patient_id = this.CurrentPatient.patient_id;
                if (!patient_id) {
                    patient_id = this.CurrentPatient.fetchingPatientId;
                }
                return this.$http
                    .get(this.medicationUrl +
                    '?patient_id=' +
                    patient_id +
                    '&ids[]=' +
                    ids +
                    '&include_dc=true&include_deleted=true&include_disposed=true')
                    .then(function (response) {
                    var medications = response.data.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                    return medications;
                });
            };
            MedicationService.prototype.getMedications = function () {
                var _this = this;
                var medicationPresent = this.$q.defer();
                if (this.patient_id !== this.CurrentPatient.patient_id ||
                    !this.medications ||
                    this.patient_id !== this.CurrentPatient.fetchingPatientId) {
                    this.patient_id = this.CurrentPatient.fetchingPatientId
                        ? this.CurrentPatient.fetchingPatientId
                        : this.CurrentPatient.patient_id;
                    this.medications = [];
                    if (!this.patient_id) {
                        this.patient_id = this.CurrentPatient.fetchingPatientId;
                    }
                    this.subscribe(this.CurrentPatient.patient.uuid);
                    this.loadingSpinner = true;
                    this.medicationCache.loadAllByPatientId(this.patient_id).$promise.then(function (data) {
                        console.log('MedicationService medicationCache.loadAllByPatientId', data);
                        _this.medications = data.map(function (medication) { return new consolo.domain.Medication(medication); });
                        medicationPresent.resolve();
                    });
                    // Keeping this for now, BK cannot recall why we switched from using medicationCache.loadAllByPatientId
                    // this.$http
                    //   .get(this.medicationUrl, {
                    //     params: {
                    //       patient_id: this.patient_id,
                    //       include_dc: true,
                    //       include_deleted: true,
                    //       include_disposed: true,
                    //     },
                    //   })
                    //   .then(response => {
                    //     this.medications = response.data.map(
                    //       medication => new consolo.domain.Medication(medication)
                    //     );
                    //     medicationPresent.resolve();
                    //   });
                }
                else {
                    medicationPresent.resolve();
                }
                return medicationPresent.promise.then(function () {
                    _this.loadingSpinner = false;
                    return _this.medications;
                });
            };
            MedicationService.prototype.createFrom = function (medication) {
                var _this = this;
                return this.$http.post(this.medicationUrl + '/' + medication.id + '/create_from', {}).then(function (response) {
                    return _this.setMedicationAndReplace(response.data);
                }, function (errors) {
                    _this.logger.error(errors.errors.join(', '));
                    return errors;
                });
            };
            MedicationService.prototype.getActiveMedications = function () {
                var _this = this;
                return this.getMedications().then(function () {
                    return _this.$filter('filter')(_this.medications, function (medication) {
                        if (medication.isActive()) {
                            return true;
                        }
                        return false;
                    });
                });
            };
            MedicationService.prototype.getActiveCurrentMedications = function () {
                var _this = this;
                return this.getMedications().then(function () {
                    return _this.$filter('filter')(_this.medications, function (medication) {
                        if (medication.isActive() && medication.isCurrent()) {
                            return true;
                        }
                        return false;
                    });
                });
            };
            MedicationService.prototype.getActiveNewMedications = function () {
                var _this = this;
                return this.getMedications().then(function () {
                    return _this.$filter('filter')(_this.medications, function (medication) {
                        if (medication.isActive() && medication.needsPhysicianOrder()) {
                            return true;
                        }
                        return false;
                    });
                });
            };
            MedicationService.prototype.getPatientMedicationKits = function (patient_id, has_remaining_medications_to_administer) {
                return this.$http
                    .get('/api/v1/patient_medication_kits', {
                    params: {
                        patient_id: patient_id,
                        has_remaining_medications_to_administer: has_remaining_medications_to_administer,
                    },
                })
                    .then(function (response) {
                    return response.data.map(function (medKit) {
                        return new consolo.domain.PatientMedicationKit(medKit);
                    });
                });
            };
            MedicationService.prototype.getSignatures = function () {
                var _this = this;
                this.Medication.signatures({ patient_id: this.CurrentPatient.patient_id }).$promise.then(function (data) {
                    delete data.$promise;
                    delete data.$resolved;
                    _this.signatures = data;
                });
            };
            MedicationService.prototype.getCurrentMedicationSet = function () {
                var _this = this;
                this.Medication.getMedicationSet({
                    patient_id: this.CurrentPatient.patient_id,
                }).$promise.then(function (data) {
                    _this.medicationSet = data;
                    _this.signatures = _this.medicationSet.signatures;
                    if (_this.medicationSet.medication_reconciliations.length) {
                        _this.reconciliation = _this.medicationSet.medication_reconciliations[0];
                    }
                    else {
                        _this.reconciliation = null;
                    }
                });
            };
            MedicationService.prototype.dosageUnitLookup = function (q) {
                return this.select_options['dosage_units'].filter(function (dosage_unit) {
                    return q.length === 0 || dosage_unit.description.toLowerCase().includes(q.toLowerCase());
                });
            };
            MedicationService.prototype.dosageRouteLookup = function (q) {
                return this.select_options['dosage_routes'].filter(function (dosage_route) {
                    return q.length === 0 || dosage_route.fda_description.toLowerCase().includes(q.toLowerCase());
                });
            };
            MedicationService.prototype.getCurrentMedications = function () {
                return this.$filter('filter')(this.medications, function (medication) {
                    medication.order_status === consolo.domain.OrderStatus.current;
                });
            };
            MedicationService.prototype.moveToCurrent = function (medication) {
                var _this = this;
                return this.$http
                    .post(this.medicationUrl + '/' + medication.id + '/move_to_current')
                    .then(function (response) {
                    angular.copy(new consolo.domain.Medication(response.data), medication);
                    _this.replaceMedications([medication]);
                    return medication;
                });
            };
            MedicationService.prototype.navigateTo = function (route) {
                this.$state.go('patients.' + route, { patientId: this.CurrentPatient.patient_id });
            };
            MedicationService.prototype.urlTo = function (url) {
                document.location.href = '/patients/' + this.CurrentPatient.patient_id + '/' + url;
            };
            MedicationService.prototype.editMedication = function (medication) {
                return this.$state.go('patients.medications.edit', {
                    patientId: medication.patient_id,
                    medicationId: medication.id,
                    allergyInteractions: medication.allergy_interactions,
                    drugInteractions: medication.drug_interactions
                });
            };
            MedicationService.prototype.updateFrequency = function (object) {
                this.getFrequency(object).then(function (frequency) {
                    object.frequency = frequency;
                });
            };
            MedicationService.prototype.editMedicationModal = function (medication) {
                var _this = this;
                return this.get(medication.id).then(function (updatedMedication) {
                    medication = updatedMedication;
                    var url = "'/y/templates/patients/sections/medications/medication.html'";
                    return _this.$mdDialog.show({
                        clickOutsideToClose: true,
                        bindToController: true,
                        template: '<md-dialog flex="80" aria-label="Edit Medication">' +
                            '  <md-dialog-content>' +
                            '   <div ng-include="' +
                            url +
                            '"/>' +
                            '  </md-dialog-content>' +
                            '</md-dialog>',
                        controller: 'PatientMedicationController as vm',
                        locals: {
                            medication: medication,
                            currentPatient: _this.CurrentPatient.patient,
                            redirect: false,
                        },
                        multiple: true,
                        skipHide: true,
                        preserveScope: true,
                    });
                });
            };
            MedicationService.prototype.create = function (medication) {
                var _this = this;
                return this.$http.post(this.medicationUrl, medication).then(function (data) {
                    _this.logger.success('Medication created successfully');
                    return _this.setMedicationAndReplace(data.data);
                });
            };
            MedicationService.prototype.reconcile = function ($event) {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Confirmation Required')
                    .htmlContent('Are you sure you want to reconcile this Medication Set?')
                    .ariaLabel('Removal Confirmation')
                    .targetEvent($event)
                    .ok('Ok')
                    .cancel('Cancel');
                this.$mdDialog.show(confirm).then(function () {
                    _this.Medication.reconcile({ patient_id: _this.CurrentPatient.patient_id }).$promise.then(function (data) {
                        _this.logger.success("Patient's medications have been reconciled!");
                        _this.reconciliation = data;
                    }, function (resp) {
                        _this.logger.error('Errors were encountered while reconciling medications');
                    });
                });
            };
            MedicationService.prototype.addPhysicianSignature = function ($event) {
                var _this = this;
                var confirm = {
                    targetEvent: $event,
                    clickOutsideToClose: true,
                    bindToController: true,
                    controller: 'PatientMedicationsPhysicianSignatureModalController as vm',
                    templateUrl: '/y/templates/patients/sections/medications/medications-physician-signature-modal.html',
                };
                return this.$mdDialog.show(confirm).then(function (data) {
                    if (data) {
                        var signature = {
                            patient_id: _this.CurrentPatient.patient_id,
                            effective_date: data.effective_date,
                        };
                        if (data.physician.id) {
                            signature.physician_id = data.physician.id;
                        }
                        _this.Medication.addPhysicianSignature(signature, function (signature) {
                            _this.logger.success('Physician Signature Added');
                            _this.signatures.push(signature);
                            return true;
                        }, function () {
                            _this.logger.error('Error Saving Signature');
                            return false;
                        });
                    }
                });
            };
            MedicationService.prototype.validateQuantity = function (quantity) {
                if (quantity) {
                    var quantityString = quantity.toString();
                    var quantitySplit = quantityString.split('.');
                    if (quantityString[0] === '.') {
                        quantityString = '0' + quantityString;
                    }
                    if (quantityString.length > 11) {
                        quantity = parseFloat(quantityString.slice(0, 10));
                    }
                    else if (quantitySplit.length > 1 && parseInt(quantitySplit[1], 10) === 0) {
                        quantity = parseFloat(quantitySplit[0]);
                    }
                    else {
                        quantity = quantityString;
                    }
                    return quantity;
                }
                else {
                    return '0';
                }
            };
            MedicationService.prototype.addUserSignature = function () {
                var _this = this;
                var confirm = this.$mdDialog
                    .confirm()
                    .title('Add Your Signature')
                    .textContent('Are you sure you want to sign this Medication Set?')
                    .ok('Sign')
                    .cancel('No thanks');
                return this.$mdDialog.show(confirm).then(function () {
                    var signature = { patient_id: _this.CurrentPatient.patient_id };
                    if (_this.medicationSet) {
                        if (_this.medicationSet.has_signature && _this.medicationSet.medications_same_as_active) {
                            signature.retrack = true;
                        }
                    }
                    _this.Medication.addSignature(signature, function (signature) {
                        _this.logger.success('Signature Added');
                        _this.signatures.push(signature);
                        return true;
                    }, function () {
                        _this.logger.error('Error Saving Signature');
                        return false;
                    });
                });
            };
            MedicationService.prototype.populateDosageTimes = function (medication) {
                medication.dosage_times = [];
                if (medication.dosage_frequency && medication.is_scheduled) {
                    var count = medication.dosage_frequency;
                    if (medication.dosage_frequency_unit === 'hour') {
                        count = 24 / medication.dosage_frequency;
                    }
                    else {
                        count = medication.dosage_frequency;
                    }
                    for (var i = 0; i < count; i++) {
                        medication.dosage_times.push({});
                    }
                }
            };
            MedicationService.prototype.refill = function (refillParams) {
                var _this = this;
                return this.$http.post(this.medicationUrl + '/refill', refillParams).then(function (data) {
                    var medicationNames = data.data.map(function (med) {
                        return med.name;
                    });
                    _this.logger.info('The medications ' + medicationNames.join(', ') + ' were refilled.');
                    if (refillParams.refills.length) {
                        _this.replaceMedications(data.data);
                    }
                    _this.navigateTo('medications');
                    return data.data;
                }, function (errorResponse) {
                    return errorResponse.data;
                });
            };
            MedicationService.prototype.replaceMedicationsFromOrder = function (physicianOrder) {
                var _this = this;
                //Physician Order entity doesn't get the full medication information necessary on the medications page
                // query the full Medication api to get all the fields and update
                var ids = physicianOrder.medication_transactions.map(function (transaction) {
                    return transaction.medication.id;
                });
                if (ids.length) {
                    this.$http
                        .get(this.medicationUrl, {
                        params: {
                            patient_id: physicianOrder.patient_id,
                            'ids[]': ids,
                            include_dc: true,
                            include_deleted: true,
                            included_disposed: true,
                        },
                    })
                        .then(function (response) {
                        _this.replaceMedications(response.data);
                    });
                }
            };
            MedicationService.prototype.replaceMedications = function (newMedications) {
                if (!newMedications || !newMedications.length) {
                    return [];
                }
                if (this.medications && this.medications.length && newMedications && newMedications.length) {
                    var currentMedicationsLength = this.medications.length;
                    newMedications = newMedications.map(function (medication) { return new consolo.domain.Medication(medication); });
                    //Loop over the medications that need to be replaced looking for existing
                    for (var medIndex = 0; medIndex < newMedications.length; medIndex++) {
                        for (var i = 0; i < currentMedicationsLength; i++) {
                            if (this.medications[i].id === newMedications[medIndex].id) {
                                this.medications[i] = newMedications[medIndex];
                                break;
                            }
                            else if (i === currentMedicationsLength - 1) {
                                //Medication is not in the current list, add it
                                this.medications.push(newMedications[medIndex]);
                            }
                        }
                    }
                    this.sortMedications();
                    return newMedications;
                }
                else {
                    newMedications = newMedications.map(function (medication) { return new consolo.domain.Medication(medication); });
                    this.medications = newMedications;
                    this.sortMedications();
                    return newMedications;
                }
            };
            MedicationService.prototype.sortMedications = function () {
                this.medications.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
            };
            MedicationService.prototype.update = function (medication) {
                var _this = this;
                return this.$http
                    .patch(this.medicationUrl + '/' + medication.id, medication)
                    .then(function (data) {
                    _this.logger.success('Medication updated successfully');
                    return _this.setMedicationAndReplace(data.data);
                });
            };
            MedicationService.prototype.subscribe = function (patientUUID) {
                var _this = this;
                this.unsubscribe();
                this.PubSubService.channel("emr:patient:" + patientUUID + ":agency:" + this.Session.get().agency.code).then(function (chan) {
                    chan.on('medication_reload', function (data) {
                        console.log('Reloading medication');
                        _this.get(data.medication_id_to_reload).then(function (medication) {
                            _this.replaceMedications([medication]);
                            _this.reapplyFilters = true;
                        });
                    });
                    _this.channel = chan;
                });
            };
            MedicationService.prototype.unsubscribe = function () {
                if (this.channel) {
                    this.channel.leave();
                    this.channel = null;
                }
            };
            MedicationService.prototype.updateMedications = function (medicationIds) {
                var _this = this;
                return this.$http
                    .get('/api/v1/medications', {
                    params: { patient_id: this.CurrentPatient.patient.id, 'ids[]': medicationIds },
                })
                    .then(function (data) {
                    _this.replaceMedications(data.data);
                    return data.data;
                });
            };
            MedicationService.prototype.getScheduledMedications = function (patientId, date, medicationId, ever_scheduled) {
                return this.$http
                    .get(this.medicationUrl + '/scheduled_on', {
                    params: {
                        patient_id: patientId,
                        date: date,
                        medication_id: medicationId,
                    },
                })
                    .then(function (response) {
                    var patient = response.data;
                    if (patient.medications && patient.medications.length) {
                        patient.medications = patient.medications.map(function (medication) {
                            medication.timeZone = patient.time_zone;
                            return new consolo.domain.Medication(medication);
                        });
                    }
                    else {
                        patient.medications = [];
                    }
                    return patient;
                });
            };
            MedicationService.prototype.getMedicationNotes = function (patientId) {
                return this.$http.get('/api/v1/medication_notes?patient_id=' + patientId).then(function (response) {
                    return response.data;
                });
            };
            MedicationService.prototype.medicationLookup = function (q, filterMediSpanOnly) {
                return this.Lookup['med_names']({ q: q, epcs: filterMediSpanOnly }).$promise.then(function (medications) {
                    return medications.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                });
            };
            MedicationService.prototype.formularyLookup = function (q, used_only_for_compounds) {
                return this.$http
                    .get('/api/v1/medication_formulary_entries', {
                    params: { q: q, used_only_for_compounds: used_only_for_compounds },
                })
                    .then(function (response) {
                    return response.data.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                });
            };
            MedicationService.prototype.medicationDetailsLookup = function (q, filterMediSpanOnly) {
                return this.Lookup['meds']({ q: q, epcs: filterMediSpanOnly }).$promise.then(function (medications) {
                    return medications.map(function (medication) {
                        return new consolo.domain.Medication(medication);
                    });
                });
            };
            MedicationService.prototype.medicationCsv = function (medications) {
                var headers = [
                    'Medication',
                    'Dosage',
                    'Status',
                    'Order Date',
                    'Start Date',
                    'DC Date',
                    'Ordering MD',
                    'Unrelated to Terminal Dx',
                    'Anti-Infective',
                    'Drug Class',
                    'Covered',
                    'Pain',
                    'Admin By',
                    'Disposed',
                    'Lab',
                    'Pharmacy',
                    'Deleted',
                    'Results',
                    'Delivery',
                    'Status',
                    'Instructions',
                ];
                var date = this.CSV.date, yn = this.CSV.yn;
                return this.CSV.generate(medications.map(function (med) {
                    return [
                        med.name,
                        med.frequency,
                        med.medication_status,
                        date(med.orderdate),
                        date(med.dosage_frequency_start_datetime),
                        date(med.dc),
                        med.physician_name,
                        yn(med.is_unrelated),
                        yn(med.is_anti_infective),
                        med.drug_class,
                        yn(med.is_covered),
                        yn(med.is_pain_and_level),
                        med.administered_by_text,
                        med.disposed_string,
                        yn(med.has_laboratory_monitoring),
                        med.pharmacy.name,
                        med.deleted_string,
                        med.results,
                        med.pretty_delivery,
                        med.medication_status,
                        med.notes,
                    ];
                }), headers);
            };
            MedicationService.prototype.physicianLookup = function (q, include_social_workers) {
                return this.$http.get('/api/v1/physicians', { params: { q: q, include_social_workers: include_social_workers } }).then(function (response) {
                    return response.data;
                });
            };
            MedicationService.prototype.getNewMedication = function () {
                return this.Medication.defaultNewMedication({
                    patient_id: this.CurrentPatient.patient.id,
                }).$promise.then(function (medication) {
                    medication.drug_interactions = [];
                    medication.allergy_interactions = [];
                    return new consolo.domain.Medication(medication);
                });
            };
            MedicationService.prototype.delete = function (medication) {
                var _this = this;
                return this.$http
                    .delete('/api/v1/medications/' + medication.id + '?deleted_reason=' + medication.deleted_reason)
                    .then(function (data) {
                    _this.logger.success('Medication deleted successfully.');
                    _this.getCurrentMedicationSet();
                    var newMedication = _this.setMedicationAndReplace(data.data);
                    angular.copy(newMedication, medication);
                    return newMedication;
                });
            };
            MedicationService.prototype.discontinue = function (medication, physician, status, createOrder) {
                var _this = this;
                return this.$http
                    .post(this.medicationUrl + '/' + medication.id + '/discontinue', {
                    dc: medication.dc,
                    status: status,
                    create_order: createOrder,
                    physician_id: physician.id,
                })
                    .then(function (response) {
                    return _this.setMedicationAndReplace(response.data);
                });
            };
            MedicationService.prototype.discontinueAndCreate = function (medication, physician, status) {
                var _this = this;
                return this.$http
                    .post(this.medicationUrl + '/' + medication.id + '/discontinue_and_create', {
                    status: status,
                    dc: medication.dc,
                    physician_id: physician.id,
                })
                    .then(function (response) {
                    return _this.getMedicationsByIds([medication.id]).then(function (medications) {
                        _this.replaceMedications(medications);
                        var newlyCreatedMed = _this.setMedicationAndReplace(response.data);
                        return _this.editMedication(newlyCreatedMed);
                    });
                }, function (errors) {
                    return _this.logger.error(errors.data.errors.join(', '));
                });
            };
            MedicationService.prototype.deleteAndRecreate = function (medication) {
                var _this = this;
                this.saving = true;
                return this.$http
                    .delete('/api/v1/medications/' + medication.id, {
                    params: { deleted_reason: medication.deleted_reason, recreate: true },
                })
                    .then(function (data) {
                    angular.copy(new consolo.domain.Medication(data.data), medication);
                    _this.replaceMedications([medication]);
                    //New medication has been created based on previous one, get it and add it to the medications to keep everything up to date
                    return _this.$http
                        .get(_this.medicationUrl, {
                        params: {
                            patient_id: medication.patient_id,
                            previous_medication_id: medication.id,
                        },
                    })
                        .then(function (data) {
                        return _this.setMedicationAndReplace(data.data[0]);
                    });
                }, function (errors) {
                    return false;
                })
                    .finally(function () {
                    _this.saving = false;
                });
            };
            MedicationService.prototype.physicianOrderMedicationsDialog = function (medication) {
                this.orderMedications = [];
                if (medication) {
                    var reorderedMed = angular.copy(medication);
                    reorderedMed.notes_to_pharmacist = null;
                    this.orderMedications.push(reorderedMed);
                }
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/physician-order/medications-selection-modal.html',
                    controller: [
                        'MedicationService',
                        '$mdDialog',
                        '$state',
                        function (MedicationService, $mdDialog, $state) {
                            var _this = this;
                            this.MedicationService = MedicationService;
                            this.$mdDialog = $mdDialog;
                            this.MedicationService.getActivePendingMedications().then(function (activeMeds) {
                                _this.medications = activeMeds.filter(function (medication) {
                                    return medication.needsPhysicianOrder();
                                });
                            });
                            this.$state = $state;
                        },
                    ],
                    controllerAs: 'vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                });
            };
            MedicationService.prototype.resetToDefault = function (medication) {
                var _this = this;
                var test = medication.patient_id;
                return this.$http
                    .get('/api/v1/patient_record_defaults/current?patient_id=' + medication.patient_id)
                    .then(function (data) {
                    var setting = data.data;
                    medication.has_administered_by_family = setting.has_administered_by_family;
                    medication.has_administered_by_staff = setting.has_administered_by_staff;
                    medication.has_administered_by_nurse = setting.has_administered_by_nurse;
                    medication.has_administered_by_patient = setting.has_administered_by_patient;
                    medication.has_administered_by_other = setting.has_administered_by_other;
                    medication.note_administered_by_family = setting.note_administered_by_family;
                    medication.note_administered_by_staff = setting.note_administered_by_staff;
                    medication.note_administered_by_nurse = setting.note_administered_by_nurse;
                    medication.note_administered_by_patient = setting.note_administered_by_patient;
                    medication.note_administered_by_other = setting.note_administered_by_other;
                }, function (error) {
                    _this.logger.error(error.data.errors.join(', '));
                });
            };
            MedicationService.prototype.setAsDefault = function (medication) {
                var _this = this;
                return this.$http
                    .post('/api/v1/patient_record_defaults/current/update', {
                    patient_id: medication.patient_id,
                    has_administered_by_family: medication.has_administered_by_family,
                    has_administered_by_staff: medication.has_administered_by_staff,
                    has_administered_by_nurse: medication.has_administered_by_nurse,
                    has_administered_by_patient: medication.has_administered_by_patient,
                    has_administered_by_other: medication.has_administered_by_other,
                    note_administered_by_family: medication.note_administered_by_family,
                    note_administered_by_staff: medication.note_administered_by_staff,
                    note_administered_by_nurse: medication.note_administered_by_nurse,
                    note_administered_by_patient: medication.note_administered_by_patient,
                    note_administered_by_other: medication.note_administered_by_other,
                })
                    .then(function (response) {
                    medication.current_administered_by_default =
                        response.data.administered_by_default_string;
                    _this.logger.success('Administered by set as default');
                }, function (error) {
                    _this.logger.error(error.data.errors.join(', '));
                });
            };
            MedicationService.prototype.reorder = function (clearMedications) {
                if (clearMedications || !this.reorderMedicationTransactions) {
                    this.reorderMedicationTransactions = [];
                }
                return this.$mdDialog.show({
                    template: '<md-dialog flex="90" flex-gt-sm="50" aria-label="Add Medication Favorites">' +
                        '    <reorder-modal></reorder-modal>' +
                        '</md-dialog>',
                });
            };
            MedicationService.prototype.setMedFormDisplay = function (medicationTransaction) {
                if (medicationTransaction.medication.route_of_admin &&
                    medicationTransaction.medication.route_of_admin.toLowerCase() !== 'xx') {
                    this.select_options['dosage_routes'].some(function (dosage_route) {
                        if (dosage_route.fda_description.toLowerCase() ===
                            medicationTransaction.medication.route_of_admin.toLowerCase()) {
                            medicationTransaction.medFormDisplay = dosage_route.fda_description;
                            return true;
                        }
                    });
                }
                if (medicationTransaction.medication.form) {
                    this.select_options['dosage_forms'].some(function (dosage_form) {
                        if (dosage_form.code.toLowerCase() === medicationTransaction.medication.form.toLowerCase()) {
                            medicationTransaction.medFormDisplay =
                                medicationTransaction.medFormDisplay + ' ' + dosage_form.description;
                            return true;
                        }
                    });
                }
            };
            MedicationService.prototype.resupply = function (clearMedications) {
                if (clearMedications || !this.resupplyMedicationTransactions) {
                    this.resupplyMedicationTransactions = [];
                }
                return this.$mdDialog.show({
                    template: '<md-dialog flex="90" flex-gt-sm="50" aria-label="Add Medication Favorites">' +
                        '    <resupply-modal redo-transactions="' +
                        clearMedications +
                        '"></resupply-modal>' +
                        '</md-dialog>',
                });
            };
            MedicationService.prototype.setDCDate = function (medication, date) {
                if (date) {
                    medication.dc = new Date(date);
                    medication.dosage_frequency_end_datetime = medication.dc;
                }
                else {
                    medication.dc = null;
                    medication.dosage_frequency_end_datetime = null;
                }
            };
            MedicationService.prototype.searchMedications = function (item, filterMediSpanOnly) {
                var _this = this;
                this.loadingSpinner = true;
                return this.Lookup['meds']({
                    name: item.name,
                    q: item.q,
                    epcs: filterMediSpanOnly,
                    patient_id: this.CurrentPatient.patient_id,
                }).$promise.then(function (medications) {
                    _this.loadingSpinner = false;
                    return medications.map(function (med) {
                        return new consolo.domain.Medication(med);
                    });
                });
            };
            MedicationService.prototype.compoundLookup = function (q, on_formulary) {
                return this.$http
                    .get('/api/v1/compounds', { params: { q: q, on_formulary: on_formulary } })
                    .then(function (response) {
                    return response.data.map(function (compound) {
                        compound.compound_id = compound.id;
                        return compound;
                    });
                });
            };
            MedicationService.prototype.searchFormularySources = function (q, used_only_for_compounds) {
                var combinedMedications = [];
                var medicationsPromise = [];
                medicationsPromise.push(this.$http
                    .get('/api/v1/medication_favorites', { params: { q: q, on_formulary: true } })
                    .then(function (medicationFavorites) {
                    if (medicationFavorites.length) {
                        combinedMedications.push({ display_name: 'Favorites' });
                        combinedMedications = combinedMedications.concat(medicationFavorites);
                    }
                }));
                medicationsPromise.push(this.formularyLookup(q, used_only_for_compounds).then(function (formularyMedications) {
                    if (formularyMedications.length) {
                        combinedMedications.push({ display_name: 'Formulary' });
                        combinedMedications = combinedMedications.concat(formularyMedications);
                    }
                }));
                if (this.compoundsEnabled) {
                    medicationsPromise.push(this.compoundLookup(q, true).then(function (compounds) {
                        if (compounds.length) {
                            combinedMedications.push({ display_name: 'Compounds' });
                            combinedMedications = combinedMedications.concat(compounds);
                        }
                    }));
                }
                return this.$q.all(medicationsPromise).then(function () {
                    return combinedMedications;
                });
            };
            MedicationService.prototype.searchAllSources = function (q, filterMediSpanOnly, filterFormularyOnly, physician_id, searchCompounds, usedOnlyForCompounds) {
                var combinedMedications = [];
                var medicationsPromise = [];
                //Search Favorites
                if (this.viewFavoritesPermission && physician_id) {
                    medicationsPromise.push(this.MedicationFavoritesService.getMedicationFavorites(physician_id, q).then(function (medicationFavorites) {
                        if (medicationFavorites.length) {
                            combinedMedications.push({ display_name: 'Favorites' });
                            combinedMedications = combinedMedications.concat(medicationFavorites.map(function (favorite) {
                                return new consolo.domain.Medication(favorite);
                            }));
                        }
                    }));
                }
                //Search medispan
                if ((!this.medicationFormularyEnabled || !this.medicationFormularyEnforced) &&
                    !filterFormularyOnly) {
                    medicationsPromise.push(this.searchMedications({ q: q }, filterMediSpanOnly).then(function (medications) {
                        if (medications.length) {
                            combinedMedications.push({ display_name: 'Medispan' });
                            combinedMedications = combinedMedications.concat(medications);
                        }
                    }));
                }
                //Search Formulary
                if (this.medicationFormularyEnabled && this.viewFormularyPermission) {
                    medicationsPromise.push(this.formularyLookup(q, usedOnlyForCompounds).then(function (formularyMedications) {
                        if (formularyMedications.length) {
                            combinedMedications.push({ display_name: 'Formulary' });
                            combinedMedications = combinedMedications.concat(formularyMedications);
                        }
                    }));
                }
                //Search Compounds
                if (this.compoundsEnabled &&
                    this.viewCompoundsPermission &&
                    (searchCompounds === undefined || searchCompounds)) {
                    medicationsPromise.push(this.compoundLookup(q).then(function (compounds) {
                        if (compounds.length) {
                            combinedMedications.push({ display_name: 'Compounds' });
                            combinedMedications = combinedMedications.concat(compounds);
                        }
                    }));
                }
                return this.$q.all(medicationsPromise).then(function () {
                    return combinedMedications;
                });
            };
            MedicationService.prototype.createMedicationTransaction = function (type, medication) {
                return {
                    medication_id: medication.id,
                    medication: medication,
                    physician_id: medication.physician_id,
                    physician: medication.physician,
                    pharmacy_id: medication.pharmacy_id,
                    surescripts_pharmacy_id: medication.surescripts_pharmacy_id,
                    transaction_type: type,
                    refills: medication.refills ? medication.refills : 0,
                    quantity: medication.is_open_order ? 0 : medication.quantity,
                    notes_to_pharmacist: medication.notes_to_pharmacist,
                    eprescribe_instructions: medication.eprescribe_instructions,
                    notes: medication.notes,
                };
            };
            MedicationService.prototype.createReorderMedicationTransaction = function (medication) {
                return {
                    medication_id: medication.id,
                    medication: medication,
                    physician_id: medication.physician_id,
                    physician: medication.physician,
                    pharmacy_id: medication.pharmacy_id,
                    pharmacy: medication.pharmacy,
                    surescripts_pharmacy_id: medication.surescripts_pharmacy_id,
                    surescripts_pharmacy: medication.surescripts_pharmacy,
                    transaction_type: 'reorder',
                    refills: 0,
                    quantity: medication.current_medication_transaction
                        ? this.validateQuantity(medication.current_medication_transaction.quantity)
                        : this.validateQuantity(medication.quantity),
                    notes: medication.notes,
                    eprescribe_instructions: medication.eprescribe_instructions,
                    oldNotes: medication.notes_to_pharmacist,
                    effective_datetime: new Date(),
                };
            };
            MedicationService.prototype.createMedicationTransactionFromTransaction = function (type, medicationTransaction, medication) {
                return {
                    medication_id: medication.id,
                    medication: medication,
                    physician_id: medicationTransaction.physician_id,
                    physician: medicationTransaction.physician,
                    pharmacy_id: medicationTransaction.pharmacy_id,
                    surescripts_pharmacy_id: medicationTransaction.surescripts_pharmacy_id,
                    surescripts_pharmacy: medicationTransaction.surescripts_pharmacy,
                    transaction_type: type,
                    refills: 0,
                    quantity: parseFloat(medicationTransaction.quantity),
                    notes_to_pharmacist: medicationTransaction.notes_to_pharmacist,
                    eprescribe_instructions: medicationTransaction.eprescribe_instructions,
                    notes: medicationTransaction.notes,
                    originating_medication_transaction_id: medicationTransaction.id,
                    effective_datetime: new Date(),
                    originalQuantity: parseFloat(medicationTransaction.quantity),
                };
            };
            MedicationService.prototype.createPatientMedicationKitTransaction = function (type, patientMedicationKit) {
                return {
                    patient_medication_kit_id: patientMedicationKit.id,
                    patient_medication_kit: patientMedicationKit,
                    transaction_type: type,
                    patient_id: this.CurrentPatient.patient_id,
                    pharmacy_id: patientMedicationKit.pharmacy_id,
                    surescripts_pharmacy_id: patientMedicationKit.surescripts_pharmacy_id,
                    refills: 0,
                    quantity: 1,
                };
            };
            MedicationService.prototype.searchHCPCS = function (q) {
                return this.$http.get('/api/v1/medispan/hcpcs', { params: { q: q } }).then(function (response) {
                    return response.data.map(function (result) {
                        result.description = result.code + ' - ' + result.short_description;
                        return result;
                    });
                });
            };
            MedicationService.prototype.save = function (medication) {
                var _this = this;
                this.saving = true;
                var save = !medication.id ? this.create(medication) : this.update(medication);
                var newMedication = !medication.id;
                return save.then(function (medication) {
                    _this.saving = false;
                    return medication;
                });
            };
            MedicationService.prototype.setMedicationAndReplace = function (medication) {
                var med = new consolo.domain.Medication(medication);
                this.replaceMedications([med]);
                return med;
            };
            MedicationService.prototype.setFromCompound = function (medication, selectedCompound) {
                medication.ingredients = selectedCompound.ingredients;
                medication.name = selectedCompound.name;
                medication.is_compound = true;
                medication.on_formulary = selectedCompound.on_formulary;
                medication.compound_id = selectedCompound.id;
                //Compounds are stored with quantity unit of measure, need to convert to dosage units
                if (selectedCompound.quantity_unit_of_measure || selectedCompound.strength_form) {
                    this.select_options['dosage_units'].some(function (dosage_unit) {
                        if (selectedCompound.quantity_unit_of_measure &&
                            selectedCompound.quantity_unit_of_measure.description === dosage_unit.description) {
                            medication.dosage_unit_id = dosage_unit.id;
                            medication.dosage_unit_display = dosage_unit.description;
                            medication.dosage_unit = dosage_unit;
                            return true;
                        }
                        else if (selectedCompound.strength_form &&
                            selectedCompound.strength_form.description === dosage_unit.description) {
                            medication.dosage_unit_id = dosage_unit.id;
                            medication.dosage_unit_display = dosage_unit.description;
                            medication.dosage_unit = dosage_unit;
                            return true;
                        }
                        medication.quantity_unit_of_measure =
                            selectedCompound.quantity_unit_of_measure.description;
                        medication.potency_code = selectedCompound.quantity_unit_of_measure.code;
                    });
                }
            };
            MedicationService.prototype.setDosageRoute = function (medication, route) {
                var _this = this;
                this.isReady().then(function () {
                    _this.select_options['dosage_routes'].some(function (dosage_route) {
                        if (dosage_route.code && route &&
                            (dosage_route.code.toLowerCase() === route.toLowerCase() ||
                                dosage_route.fda_description.toLowerCase() === route.toLowerCase() ||
                                dosage_route.other_codes.includes(route))) {
                            medication.route_of_admin = dosage_route.fda_description;
                            medication.route = dosage_route.fda_description;
                            medication.dosageRoute = dosage_route;
                            return true;
                        }
                    });
                });
            };
            MedicationService.prototype.setDosageRouteFDA = function (medicationFavorite, route) {
                var _this = this;
                this.isReady().then(function () {
                    _this.select_options['dosage_routes'].some(function (dosage_route) {
                        if (dosage_route.fda_description && route && dosage_route.fda_description.toLowerCase() === route.toLowerCase()) {
                            medicationFavorite.route_of_admin = dosage_route.fda_description;
                            medicationFavorite.dosageRoute = dosage_route;
                            return true;
                        }
                    });
                });
            };
            MedicationService.prototype.setFromMedication = function (medication, selectedMedication) {
                medication.dose = selectedMedication.strength;
                medication.doses = selectedMedication.strength_unit;
                medication.dea_class = selectedMedication.controlled_substance_code;
                medication.strength = selectedMedication.strength;
                medication.strength_unit = selectedMedication.strength_unit;
                medication.name = selectedMedication.name;
                medication.ndc = selectedMedication.ndc;
                medication.form = selectedMedication.dosage_form;
                medication.is_rx = selectedMedication.is_rx;
                medication.gpi = selectedMedication.gpi;
                medication.rx_norm_code = selectedMedication.rx_norm_code;
                medication.drug_class = selectedMedication.drug_class;
                this.setDosageRoute(medication, selectedMedication.route);
                if (selectedMedication.source) {
                    medication.authoritative_source = selectedMedication.source;
                }
                else {
                    medication.authoritative_source = selectedMedication.authoritative_source;
                }
            };
            MedicationService.prototype.setFromFavorite = function (medication, selectedMedicationFavorite) {
                [
                    'dose',
                    'doses',
                    'route_of_admin',
                    'dea_class',
                    'name',
                    'ndc',
                    'form',
                    'dosage_amount_low',
                    'dosage_amount_high',
                    'quantity',
                    'quantity_unit_of_measure',
                    'refills',
                    'notes',
                    'eprescribe_instructions',
                    'notes_to_pharmacist',
                    'ingredients',
                    'is_compound',
                    'compound_id',
                    'dosage_frequency',
                    'dosage_unit_display',
                    'dosage_unit_id',
                    'dosage_unit',
                    'display_name',
                    'prn',
                    'prn_reason_ids',
                    'potency_code',
                    'dosage_frequency',
                    'dosage_frequency_multiplier',
                    'is_anti_infective',
                    'dosage_frequency_unit',
                    'creation_source',
                    'rx_norm_code',
                    'sliding_scale',
                ].forEach(function (key) {
                    medication[key] = selectedMedicationFavorite[key];
                });
                medication.authoritative_source = 'medispan';
                medication.route = selectedMedicationFavorite.route_of_admin;
                medication.creation_source = 'favorite';
                this.setDosageUnitId(medication);
                this.setDosageRoute(medication, medication.route);
            };
            MedicationService.prototype.setDosageUnitId = function (medication) {
                var _this = this;
                this.isReady().then(function () {
                    _this.select_options.dosage_units.some(function (dosageUnit) {
                        if (!_this.mlDosageUnit && dosageUnit.code === 'ml') {
                            _this.mlDosageUnit = dosageUnit;
                        }
                        if (dosageUnit.code === medication.form || dosageUnit.description === medication.form) {
                            _this.setDosageUnitInfo(medication, dosageUnit);
                            return true;
                        }
                    });
                    _this.checkForMLDosageUnit(medication);
                });
            };
            MedicationService.prototype.checkForMLDosageUnit = function (medication) {
                var _this = this;
                this.isReady().then(function () {
                    if (!_this.mlDosageUnit) {
                        _this.select_options.dosage_units.some(function (dosageUnit) {
                            if (!_this.mlDosageUnit && dosageUnit.code === 'ml') {
                                _this.mlDosageUnit = dosageUnit;
                            }
                        });
                    }
                    if (medication && medication.dosage_unit && _this.dosageUnitsToConvertToML.indexOf(medication.dosage_unit.description) > -1 &&
                        medication.route_of_admin === 'ORAL' && medication.creation_source !== 'favorite') {
                        _this.setDosageUnitInfo(medication, _this.mlDosageUnit);
                    }
                });
            };
            MedicationService.prototype.setDosageUnitDisplay = function (medication) {
                var _this = this;
                if (medication.dosage_unit_id) {
                    this.select_options['dosage_units'].forEach(function (dosage_unit) {
                        if (medication.dosage_unit_id === dosage_unit.id) {
                            _this.setDosageUnitInfo(medication, dosage_unit);
                            _this.checkForMLDosageUnit(medication);
                            return true;
                        }
                    });
                }
                return false;
            };
            MedicationService.prototype.setDosageUnitInfo = function (medication, dosageUnit) {
                medication.dosage_unit_display = dosageUnit.description;
                medication.dosage_unit = dosageUnit;
                medication.dosage_unit_id = dosageUnit.id;
            };
            MedicationService.prototype.setMedicationValues = function (medication, selectedMedication) {
                if (selectedMedication) {
                    if (medication === undefined) {
                        medication = new consolo.domain.Medication({});
                    }
                    if (selectedMedication.type === 'compound') {
                        this.setFromCompound(medication, selectedMedication);
                    }
                    else if (selectedMedication.type === 'favorite') {
                        this.setFromFavorite(medication, selectedMedication);
                    }
                    else if (selectedMedication.type === 'formulary') {
                        this.setFromMedication(medication, selectedMedication);
                        medication.on_formulary = true;
                        medication.authoritative_source = 'medispan';
                        medication.creation_source = 'formulary';
                    }
                    else {
                        this.setFromMedication(medication, selectedMedication);
                    }
                    this.setDosageUnitId(medication);
                    medication.medication_summary =
                        selectedMedication.name +
                            ' [ ' +
                            selectedMedication.strength +
                            ' ' +
                            selectedMedication.strength_unit +
                            ' ] ' +
                            selectedMedication.form;
                    if (medication.dea_class === '2' ||
                        (medication.is_compound &&
                            medication.ingredients &&
                            medication.ingredients.filter(function (a) {
                                return a.dea_class === '2';
                            }).length > 0)) {
                        medication.refills = 0;
                        medication.refills_disabled = true;
                    }
                    else {
                        medication.refills_disabled = false;
                    }
                }
                else {
                    this.clearMedication(medication);
                }
            };
            MedicationService.prototype.clearMedication = function (medication) {
                medication.dose = null;
                medication.doses = null;
                medication.route_of_admin = null;
                medication.medication_summary = null;
                medication.dea_class = null;
                medication.dosage_unit_id = null;
                medication.dosage_unit_display = null;
                medication.strength = null;
                medication.strength_unit = null;
                medication.authoritative_source = null;
                medication.name = null;
                medication.ndc = null;
                medication.rx_norm_code = null;
            };
            MedicationService.prototype.setPendingMedicationValues = function (physicianOrder, medicationTransaction) {
                var disabled = medicationTransaction.physician_order_id &&
                    medicationTransaction.physician_order_id !== physicianOrder.id;
                if (physicianOrder.physician) {
                    disabled =
                        disabled ||
                            (physicianOrder.physician.id &&
                                physicianOrder.physician.id !== medicationTransaction.medication.physician_id);
                }
                if (disabled && physicianOrder.new_medication_transaction_ids !== undefined) {
                    var index = physicianOrder.new_medication_transaction_ids.indexOf(medicationTransaction.id);
                    if (index > -1) {
                        physicianOrder.new_medication_transaction_ids.splice(index, 1);
                    }
                }
                var medication = medicationTransaction.medication;
                medication.refills_disabled = medication.dea_class === '2';
                medication.refills = medication.dea_class === '2' ? 0 : medication.refills;
                medication.disabled = disabled;
                medication.selected =
                    physicianOrder.new_medication_transaction_ids &&
                        physicianOrder.new_medication_transaction_ids.indexOf(medicationTransaction.id) > -1
                        ? true
                        : false;
                return medication;
            };
            MedicationService.prototype.splitRx = function (medication_id, quantity, pharmacy_id, surescripts_pharmacy_id, notesToPharmacist, refills) {
                return this.$http
                    .post(this.medicationUrl + '/' + medication_id + '/split_rx', {
                    quantity: quantity,
                    pharmacy_id: pharmacy_id,
                    surescripts_pharmacy_id: surescripts_pharmacy_id,
                    notes_to_pharmacist: notesToPharmacist,
                    refills: refills,
                })
                    .then(function (response) {
                    return new consolo.domain.Medication(response.data);
                });
            };
            MedicationService.prototype.addMedicationOnPhysicianOrder = function (physicianOrder) {
                var _this = this;
                return this.Medication.defaultNewMedication({
                    patient_id: this.CurrentPatient.patient.id,
                }).$promise.then(function (medication) {
                    if (physicianOrder.physician) {
                        medication.physician = physicianOrder.physician;
                        medication.physician_id = physicianOrder.physician.id;
                    }
                    medication = new consolo.domain.Medication(medication);
                    medication.drug_interactions = [];
                    medication.allergy_interactions = [];
                    medication.creation_source = 'manual';
                    var url = "'/y/templates/patients/sections/medications/medication.html'";
                    return _this.$mdDialog.show({
                        clickOutsideToClose: true,
                        bindToController: true,
                        template: '<md-dialog flex="80" aria-label="Create New Medication">' +
                            '  <md-dialog-content>' +
                            '   <div ng-include="' +
                            url +
                            '"/>' +
                            '  </md-dialog-content>' +
                            '</md-dialog>',
                        controller: 'PatientMedicationController as vm',
                        locals: {
                            medication: medication,
                            currentPatient: _this.CurrentPatient.patient,
                            redirect: false,
                        },
                        multiple: true,
                        skipHide: true,
                        preserveScope: true,
                    });
                });
            };
            MedicationService.$inject = [
                '$filter',
                '$timeout',
                'SyncManager',
                'SessionService',
                'Medication',
                'NetworkManager',
                'CurrentPatient',
                '$mdDialog',
                'cache',
                'DosageDateTemplate',
                'Lookup',
                'logger',
                '$state',
                '$http',
                'options',
                'CSV',
                'PdfGenerator',
                '$q',
                'PermissionsService',
                'MedicationFavoritesService',
                'PubSubService',
                'CompoundsService',
            ];
            return MedicationService;
        }());
        services.MedicationService = MedicationService;
        angular.module('consolo').service('MedicationService', MedicationService);
    })(services = consolo.services || (consolo.services = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientMedicationsPhysicianSignatureModalController = /** @class */ (function () {
            function PatientMedicationsPhysicianSignatureModalController(mdDialog) {
                this.mdDialog = mdDialog;
                this.physician = {};
                this.today = new Date(new Date().toDateString());
                this.effective_date = this.today;
            }
            PatientMedicationsPhysicianSignatureModalController.prototype.cancel = function () {
                this.mdDialog.hide();
            };
            PatientMedicationsPhysicianSignatureModalController.prototype.acknowledge = function () {
                this.mdDialog.hide({ effective_date: this.effective_date, physician: this.physician });
            };
            PatientMedicationsPhysicianSignatureModalController.$inject = ['$mdDialog'];
            return PatientMedicationsPhysicianSignatureModalController;
        }());
        angular
            .module('consolo')
            .controller('PatientMedicationsPhysicianSignatureModalController', PatientMedicationsPhysicianSignatureModalController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var OrderStatus = consolo.domain.OrderStatus;
        var PatientMedicationsController = /** @class */ (function () {
            function PatientMedicationsController($scope, $window, currentPatient, Medication, CSV, $mdDialog, $filter, Session, $state, $timeout, $http, logger, PdfGenerator, NetworkManager, SyncManager, cache, MedicationService, MedicationKitsService, MedicationFavoritesService, PharmacyService, EprescribeService, PubSubService, AboutService, PdferService, printConfirmationService, $q) {
                var _this = this;
                this.$window = $window;
                this.currentPatient = currentPatient;
                this.Medication = Medication;
                this.CSV = CSV;
                this.$mdDialog = $mdDialog;
                this.$filter = $filter;
                this.Session = Session;
                this.$state = $state;
                this.$timeout = $timeout;
                this.$http = $http;
                this.logger = logger;
                this.PdfGenerator = PdfGenerator;
                this.NetworkManager = NetworkManager;
                this.SyncManager = SyncManager;
                this.cache = cache;
                this.MedicationService = MedicationService;
                this.MedicationKitsService = MedicationKitsService;
                this.MedicationFavoritesService = MedicationFavoritesService;
                this.PharmacyService = PharmacyService;
                this.EprescribeService = EprescribeService;
                this.PubSubService = PubSubService;
                this.AboutService = AboutService;
                this.PdferService = PdferService;
                this.printConfirmationService = printConfirmationService;
                this.$q = $q;
                this.menuIsOpen = false;
                this.showTooltip = false;
                this.online = false;
                this.offlineText = 'You are currently offline.  Available actions & data are limited.';
                this.discontinued = false;
                this.disposed = false;
                this.deleted = false;
                this.today = new Date();
                this.MedicationService.loadMedicationData().then(function (medications) {
                    _this.applyFilters();
                });
                if (!this.MedicationService.channel) {
                    this.MedicationService.subscribe(currentPatient.uuid);
                }
                this.online = this.NetworkManager.isOnline();
                this.NetworkManager.offlineHandler.onEvent('MedicationsController', function () {
                    _this.online = false;
                });
                this.NetworkManager.onlineHandler.onEvent('MedicationsController', function () {
                    _this.online = true;
                });
                $scope.$watch('vm.menuIsOpen', function (isOpen) {
                    if (isOpen) {
                        $timeout(function () {
                            $scope.vm.showTooltip = isOpen;
                        }, 400);
                    }
                    else {
                        $scope.vm.showTooltip = isOpen;
                    }
                });
                $scope.$watch('vm.MedicationService.reapplyFilters', function (reapply) {
                    if (reapply) {
                        _this.applyFilters();
                        _this.MedicationService.reapplyFilters = false;
                    }
                });
                var session = Session.get();
                this.currentUser = session.user;
                this.cdsEnabled = session.agency.has_cds;
                this.options = {
                    enablePaging: true,
                    pagingOptions: {
                        currentPage: 1,
                        rowsPerPage: 3,
                        changePage: function (page) {
                            console.log(page);
                        },
                    },
                };
                this.tableOptions = {
                    footerHeight: false,
                    scrollbarV: false,
                    selectable: false,
                    sortType: 'single',
                };
                this.getPatientMedicationKits();
                if (this.MedicationService.medicationFormularyEnabled &&
                    this.MedicationService.medicationDetailsLookup) {
                    this.$http
                        .get('/api/v1/formulary_exception_requests', {
                        params: { patient_id: currentPatient.id, needs_decision: true },
                    })
                        .then(function (response) {
                        _this.exceptionMedicationRequests = (response.data);
                    });
                }
                this.formularyExceptionLayout = {
                    id_field: 'id',
                    options: {
                        borderStyle: 3,
                        defaultPageSize: 100,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    rowMenu: {
                        items: [],
                    },
                    columns: [
                        {
                            title: 'Created At',
                            field: 'created_at',
                            type: 'date',
                        },
                        {
                            title: 'Medication',
                            field: 'name',
                            canSort: true,
                        },
                        { title: 'Reason for Request', field: 'reason_for_request' },
                        { title: 'Created By', field: 'user.name' },
                    ],
                };
                this.currentMedicationsLayout = {
                    id_field: 'id',
                    headerMenu: {
                        csvContents: true,
                        items: [
                            {
                                label: 'Add Physician Signature',
                                icon: 'edit',
                                callbackFn: function (medication, menuItem, event) {
                                    _this.MedicationService.addPhysicianSignature(medication);
                                },
                            },
                            {
                                label: 'Add Your Signature',
                                formatValueFn: function () {
                                    var s = 'Add Your Signature';
                                    if (_this.MedicationService.medicationSet) {
                                        if (_this.MedicationService.medicationSet.has_signature &&
                                            _this.MedicationService.medicationSet.medications_same_as_active) {
                                            s = 'Resign This Medication Set';
                                        }
                                    }
                                    return s;
                                },
                                icon: 'edit',
                                callbackFn: function () {
                                    _this.MedicationService.addUserSignature();
                                },
                            },
                        ],
                    },
                    options: {
                        borderStyle: 3,
                        defaultPageSize: 100,
                        cssClassFn: function (medication) {
                            return _this.highlightRow(medication);
                        },
                    },
                    subTable: [
                        {
                            columns: [
                                {
                                    title: 'Ordering MD(s)',
                                    field: 'physician.name',
                                    size: 1,
                                },
                                {
                                    title: 'Order Dates',
                                    field: 'orderDates',
                                    formatValueFn: function (medication) {
                                        if (medication.status && medication.status.toLowerCase() === 'fileonly') {
                                            return 'Profiled On: ' + consolo.common.dateFormatMonthDayYear(medication.orderdate);
                                        }
                                        else if (medication.medication_transactions &&
                                            medication.medication_transactions.length) {
                                            return medication.medication_transactions
                                                .map(function (transaction) {
                                                if (['newrx', 'reorder'].indexOf(transaction.transaction_type) > -1) {
                                                    return consolo.common.dateFormatMonthDayYear(transaction.effective_datetime);
                                                }
                                                else {
                                                    return null;
                                                }
                                            })
                                                .filter(function (value) {
                                                return value != null;
                                            })
                                                .join(', ');
                                        }
                                    },
                                    size: 1,
                                },
                                {
                                    title: 'Unrelated to Terminal Dx ',
                                    field: 'is_unrelated',
                                    type: 'boolean',
                                    cellCssClassFn: function (medication) {
                                        return _this.colorYesNo(medication.is_unrelated);
                                    },
                                    size: 1,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Drug Class',
                                    field: 'drug_class',
                                    size: 1,
                                },
                                {
                                    title: 'Covered?',
                                    field: 'is_covered',
                                    type: 'boolean',
                                    cellCssClassFn: function (medication) {
                                        return _this.colorYesNo(medication.is_covered);
                                    },
                                    size: 1,
                                },
                                {
                                    title: 'Pain?',
                                    field: 'is_pain',
                                    type: 'boolean',
                                    size: 1,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Admin By',
                                    field: 'administered_by_text',
                                    size: 1,
                                },
                                {
                                    title: 'Pharmacy',
                                    field: 'pharmacy.name',
                                    size: 1,
                                },
                                {
                                    title: 'Surescripts Pharmacy',
                                    field: 'surescriptPharmacies',
                                    size: 1,
                                    columnHideFn: function () {
                                        return !_this.MedicationService.epcsOn;
                                    },
                                },
                                {
                                    title: 'Lab?',
                                    field: 'has_laboratory_monitoring',
                                    type: 'boolean',
                                    cellCssClassFn: function (medication) {
                                        return _this.colorYesNo(medication.has_laboratory_monitoring);
                                    },
                                    size: 1,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Reasons',
                                    field: 'prn_reasons',
                                    formatValueFn: function (medication) {
                                        if (medication.prn_reason_ids) {
                                            return medication.prn_reasons
                                                .map(function (prn_reason) {
                                                return prn_reason.description;
                                            })
                                                .join(', ');
                                        }
                                        else {
                                            return '';
                                        }
                                    },
                                    size: 1,
                                },
                                {
                                    title: 'Disposed',
                                    field: 'disposed_string',
                                    size: 1,
                                },
                                {
                                    title: 'Disposed Amount',
                                    field: 'disposed_amount',
                                    size: 1,
                                },
                                {
                                    title: 'Disposal Method',
                                    field: 'disposition_method',
                                    size: 1,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Deleted',
                                    field: 'deleted_string',
                                    size: 1,
                                },
                                {
                                    title: 'Results',
                                    field: 'results',
                                    size: 1,
                                },
                                {
                                    title: 'Delivery',
                                    field: 'pretty_delivery',
                                    size: 1,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    title: 'Last Refill Date',
                                    field: 'last_refill_date',
                                    type: 'date',
                                    size: 1,
                                },
                                {
                                    title: 'Refills Remaining',
                                    field: 'refills_remaining',
                                    size: 1,
                                },
                                {
                                    title: 'Anti-infective?',
                                    field: 'is_anti_infective',
                                    type: 'boolean',
                                    cellCssClassFn: function (medication) {
                                        return _this.colorYesNo(medication.is_anti_infective);
                                    },
                                    size: 1,
                                },
                                {
                                    title: 'Status',
                                    field: 'medication_status',
                                    size: 1,
                                },
                            ],
                        },
                    ],
                    rowMenu: {
                        items: [
                            {
                                label: 'View Medication',
                                icon: 'remove_red_eye',
                                callbackFn: function (medication) {
                                    return _this.$state.go('patients.medications.view', {
                                        patientId: medication.patient_id,
                                        medicationId: medication.id,
                                    });
                                },
                                conditionalHideFn: function (medication) {
                                    return !_this.MedicationService.viewPermission;
                                },
                            },
                            {
                                label: 'Add Note',
                                icon: 'note_add',
                                callbackFn: function (medication, menuItem, event) {
                                    _this.showAddNoteDialog(medication);
                                },
                            },
                            {
                                label: 'Edit Medication',
                                icon: 'edit',
                                callbackFn: function (medication) {
                                    _this.MedicationService.editMedication(medication).then(function (updatedMedication) {
                                        angular.copy(updatedMedication, medication);
                                        return true;
                                    });
                                },
                                conditionalHideFn: function (medication) {
                                    return !_this.MedicationService.updatePermission;
                                },
                            },
                            {
                                label: 'Cancel E-Prescription',
                                icon: 'remove_red_eye',
                                callbackFn: function (medication) {
                                    return _this.cancelRx(medication);
                                },
                                conditionalHideFn: function (medication) {
                                    return (!_this.MedicationService.epcsOn ||
                                        !(medication.canCancel() && _this.MedicationService.updatePermission));
                                },
                            },
                            {
                                label: 'Delete Medication',
                                icon: 'delete',
                                callbackFn: function (medication) {
                                    return _this.deleteMedicationModal(medication).then(function (deletedMedication) {
                                        _this.applyFilters();
                                    });
                                },
                                conditionalHideFn: function (medication) {
                                    return !_this.MedicationService.deletePermission;
                                },
                            },
                            {
                                label: 'Create From',
                                icon: 'note_add',
                                callbackFn: function (medication) {
                                    var defer = _this.$q.defer();
                                    var hasErrored = false;
                                    if (medication && medication.ndc) {
                                        _this.$http
                                            .get('/api/v1/medications/new_interactions', {
                                            params: {
                                                new_medication_name: medication.name,
                                                new_medication_ndc: medication.ndc,
                                                patient_id: _this.currentPatient.id,
                                            },
                                        })
                                            .then(function (response) {
                                            var data = response.data;
                                            if (data.drug_interactions) {
                                                data.drug_interactions.forEach(function (drugInteraction) {
                                                    drugInteraction.user = _this.currentUser;
                                                });
                                            }
                                            if (data.allergy_interactions) {
                                                data.allergy_interactions.forEach(function (allergyInteraction) {
                                                    allergyInteraction.user = _this.currentUser;
                                                });
                                            }
                                            medication.drug_interactions = data.drug_interactions;
                                            medication.allergy_interactions = data.allergy_interactions;
                                        })
                                            .catch(function (errors) {
                                            hasErrored = true;
                                            _this.logger.error(errors.join(', '));
                                        })
                                            .finally(function () {
                                            if (!hasErrored) {
                                                if (!medication.drug_interactions) {
                                                    medication.drug_interactions = [];
                                                }
                                                if (!medication.allergy_interactions) {
                                                    medication.allergy_interactions = [];
                                                }
                                                if (medication.drug_interactions.length !== 0 ||
                                                    medication.allergy_interactions.length !== 0) {
                                                    _this.$mdDialog
                                                        .show({
                                                        preserveScope: true,
                                                        templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                                        controller: 'AcknowledgeInteractionsController as vm',
                                                        multiple: true,
                                                        skipHide: true,
                                                        locals: { medication: medication },
                                                    })
                                                        .then(function (response) {
                                                        if (response) {
                                                            _this.createFrom(medication);
                                                        }
                                                        defer.resolve();
                                                    });
                                                }
                                                else {
                                                    _this.createFrom(medication);
                                                    defer.resolve();
                                                }
                                            }
                                        });
                                    }
                                    else if (medication && medication.compound_id) {
                                        var allergyInteractions_1 = [];
                                        var drugInteractions_1 = [];
                                        var interactionPromises_1 = [];
                                        medication.ingredients.forEach(function (ingredient) {
                                            if (ingredient.name && ingredient.ndc) {
                                                interactionPromises_1.push(_this.$http
                                                    .get('/api/v1/medications/new_interactions', {
                                                    params: {
                                                        new_medication_name: ingredient.name,
                                                        new_medication_ndc: ingredient.ndc,
                                                        patient_id: _this.currentPatient.id,
                                                    },
                                                })
                                                    .then(function (response) {
                                                    var data = response.data;
                                                    if (data.drug_interactions) {
                                                        data.drug_interactions.forEach(function (drugInteraction) {
                                                            drugInteraction.user = _this.currentUser;
                                                        });
                                                        drugInteractions_1 = drugInteractions_1.concat(data.drug_interactions);
                                                    }
                                                    if (data.allergy_interactions) {
                                                        data.allergy_interactions.forEach(function (allergyInteraction) {
                                                            allergyInteraction.user = _this.currentUser;
                                                        });
                                                        allergyInteractions_1 = allergyInteractions_1.concat(data.allergy_interactions);
                                                    }
                                                }));
                                            }
                                        });
                                        _this.$q.all(interactionPromises_1).then(function () {
                                            medication.drug_interactions = drugInteractions_1;
                                            medication.allergy_interactions = allergyInteractions_1;
                                            if (medication.drug_interactions.length !== 0 ||
                                                medication.allergy_interactions.length !== 0) {
                                                _this.$mdDialog
                                                    .show({
                                                    preserveScope: true,
                                                    templateUrl: '/y/templates/patients/sections/medications/acknowledge-interactions.html',
                                                    controller: 'AcknowledgeInteractionsController as vm',
                                                    multiple: true,
                                                    skipHide: true,
                                                    locals: { medication: medication },
                                                })
                                                    .then(function (response) {
                                                    if (response) {
                                                        _this.createFrom(medication);
                                                    }
                                                    defer.resolve();
                                                });
                                            }
                                            else {
                                                _this.createFrom(medication);
                                                defer.resolve();
                                            }
                                        });
                                    }
                                    else {
                                        _this.createFrom(medication);
                                        defer.resolve();
                                    }
                                },
                                conditionalHideFn: function (medication) {
                                    return !_this.MedicationService.createPermission;
                                },
                            },
                            {
                                label: 'Discontinue Medication',
                                icon: 'delete',
                                callbackFn: function (medication) {
                                    return _this.discontinueMedication(medication);
                                },
                                conditionalHideFn: function (medication) {
                                    return !_this.MedicationService.updatePermission;
                                },
                            },
                            {
                                label: 'Add to Favorites',
                                icon: 'note_add',
                                callbackFn: function (medication) {
                                    return _this.MedicationFavoritesService.addToPhysicianModal(medication);
                                },
                                conditionalHideFn: function (medication) {
                                    return !_this.MedicationFavoritesService.createPermission;
                                },
                            },
                        ],
                    },
                    columns: [
                        {
                            title: 'Latest Order Date',
                            field: 'current_medication_transaction.effective_datetime',
                            type: 'date',
                            canSort: true,
                            formatValueFn: function (medication) {
                                if (medication.current_medication_transaction) {
                                    return consolo.common.dateFormatMonthDayYear(medication.current_medication_transaction.effective_datetime);
                                }
                                else {
                                    return consolo.common.dateFormatMonthDayYear(medication.orderdate);
                                }
                            },
                            columnHideFn: function () {
                                return !_this.MedicationService.epcsOn;
                            },
                        },
                        {
                            title: 'CDS',
                            field: 'cds_url',
                            columnHideFn: function () {
                                return !_this.cdsEnabled;
                            },
                            callbackFn: function (data) {
                                var event = "Clinical Decision Support Item";
                                var message = "Clicked Medication CDS Item: " + data.cds_url;
                                var patient_id = _this.currentPatient.id;
                                _this.AboutService.createNewAuditLog(event, message, patient_id)
                                    .then(function () {
                                    _this.openLink(data.cds_url);
                                });
                            },
                            formatValueFn: function (data) {
                                if (data.cds_url) {
                                    return '<span class="material-icons">info</span>';
                                }
                                else {
                                    return '';
                                }
                            },
                            cellCssClassFn: function () {
                                return 'med-cds-column';
                            }
                        },
                        {
                            title: 'Start Date',
                            field: 'dosage_frequency_start_datetime',
                            type: 'date',
                            canSort: true,
                        },
                        {
                            title: 'DC Date',
                            field: 'dc',
                            type: 'date',
                            canSort: true,
                            columnHideFn: function () {
                                return !_this.discontinued;
                            },
                        },
                        {
                            title: 'Disposed Date',
                            field: 'disposed',
                            type: 'date',
                            canSort: true,
                            columnHideFn: function () {
                                return !_this.disposed;
                            },
                        },
                        {
                            title: 'Deleted Date',
                            field: 'deleted',
                            type: 'date',
                            canSort: true,
                            columnHideFn: function () {
                                return !_this.deleted;
                            },
                        },
                        {
                            title: 'Medication',
                            field: 'name',
                            canSort: true,
                            formatValueFn: function (medication) {
                                var display = medication.name + ' ' + medication.strength;
                                if (medication.patient_medication_kit_name) {
                                    display =
                                        display +
                                            '<span class="margin-left-xs med-kit-source label">' +
                                            medication.patient_medication_kit_name +
                                            '</span>';
                                }
                                if (medication.current_medication_transaction &&
                                    medication.current_medication_transaction.transaction_type === 'rxfill' &&
                                    !medication.current_medication_transaction.viewed) {
                                    display =
                                        display + '<span class="margin-left-xs med-disposed label">RxFill Awaits</span>';
                                }
                                if (medication.current_medication_transaction &&
                                    medication.current_medication_transaction.isRxChangeRequest() &&
                                    !medication.current_medication_transaction.viewed) {
                                    display =
                                        display +
                                            '<span class="margin-left-xs med-disposed label">RxChange Awaits</span>';
                                }
                                if (medication.current_medication_transaction &&
                                    medication.current_medication_transaction.isRxCancelResponse() &&
                                    !medication.current_medication_transaction.viewed) {
                                    display =
                                        display +
                                            '<span class="margin-left-xs med-disposed label">Rx Cancel Response Awaits</span>';
                                }
                                if (medication.current_medication_transaction &&
                                    medication.current_medication_transaction.isRxRenewalRequest() &&
                                    !medication.current_medication_transaction.viewed) {
                                    display =
                                        display +
                                            '<span class="margin-left-xs med-disposed label">RxRenewal Awaits</span>';
                                }
                                return display;
                            },
                        },
                        {
                            title: 'Dosage',
                            field: 'frequency',
                            formatValueFn: function (medication) {
                                if (medication.sliding_scale) {
                                    var dose_level = ((medication.sliding_scale.dose_level || medication.sliding_scale.dose_level != null) ? medication.sliding_scale.dose_level : "");
                                    return medication.frequency + "Per Sliding Scale" + " " + dose_level;
                                }
                                else {
                                    return medication.frequency;
                                }
                            }
                        },
                        {
                            title: 'Instructions',
                            field: 'instructions',
                            formatValueFn: function (medication) {
                                return medication.all_instructions_plus_ss_nar;
                            }
                        },
                        {
                            title: 'Status',
                            type: 'button',
                            field: 'order_status_description',
                            canSort: true,
                            cssClassFn: function (medication) {
                                return _this.highlightRow(medication);
                            },
                            formatValueFn: function (medication) {
                                if (medication.isCurrent()) {
                                    return '';
                                }
                                else {
                                    return medication.order_status_description;
                                }
                            },
                            callbackFn: function (medication) {
                                if (medication.order_status === OrderStatus.transmissionFailed &&
                                    medication.isDeleted()) {
                                    return null;
                                }
                                else if (medication.order_status === OrderStatus.ePrescribe) {
                                    return _this.$mdDialog
                                        .show({
                                        templateUrl: '/y/templates/patients/sections/medications/eprescribe-medication.html',
                                        controller: 'EPrescribeMedicationController as vm',
                                        hasBackdrop: true,
                                        clickOutsideToClose: true,
                                        escapeToClose: true,
                                        locals: { medication: medication },
                                    })
                                        .then(function (updatedMedication) {
                                        _this.applyFilters();
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                }
                                else if (medication.order_status === OrderStatus.transmissionFailed) {
                                    return _this.$mdDialog
                                        .show({
                                        templateUrl: '/y/templates/patients/sections/medications/transmission-failed.html',
                                        controller: 'TransmissionFailedController as vm',
                                        hasBackdrop: true,
                                        clickOutsideToClose: true,
                                        escapeToClose: true,
                                        locals: { medication: medication },
                                    })
                                        .then(function (deletedMedication) {
                                        _this.applyFilters();
                                        return true;
                                    }, function () {
                                        return false;
                                    });
                                }
                                else if (medication.order_status === OrderStatus.needsNurseSignature ||
                                    medication.order_status === OrderStatus.needsPhysicianSignature) {
                                    _this.$state.transitionTo('patients.physician-orders.view', {
                                        patientId: _this.currentPatient.id,
                                        id: medication.current_medication_transaction.physician_order_id,
                                    });
                                }
                                else {
                                    return _this.$state.go('patients.physician-orders.new', {
                                        patientId: _this.currentPatient.id,
                                        physician_id: medication.physician_id,
                                        medicationId: medication.id,
                                    });
                                }
                            },
                        },
                    ],
                };
            }
            PatientMedicationsController.prototype.createFrom = function (oldmedication) {
                var _this = this;
                this.MedicationService.createFrom(oldmedication).then(function (medication) {
                    medication.allergy_interactions = oldmedication.allergy_interactions;
                    medication.drug_interactions = oldmedication.drug_interactions;
                    _this.MedicationService.editMedication(medication);
                }, function (errors) {
                    _this.logger.error(errors.join(','));
                });
            };
            PatientMedicationsController.prototype.viewRxRenewalRequest = function (medication) {
                var _this = this;
                medication.current_medication_transaction.medication = new consolo.domain.Medication({
                    quantity_unit_of_measure: medication.quantity_unit_of_measure,
                    is_compound: medication.is_compound,
                    ingredients: medication.ingredients,
                });
                medication.current_medication_transaction.medication.display_name = medication.display_name;
                return this.EprescribeService.view(medication.current_medication_transaction, this.currentPatient).then(function (updatedMedicationTransaction) {
                    _this.logger.success('The response has been sent.');
                    _this.MedicationService.get(medication.id).then(function (updatedMedication) {
                        _this.MedicationService.replaceMedications([updatedMedication]);
                        _this.applyFilters();
                    });
                });
            };
            PatientMedicationsController.prototype.openLink = function (url) {
                this.$window.open(url);
            };
            PatientMedicationsController.prototype.patientMedicationKitAction = function (patientMedicationKit) {
                if (patientMedicationKit.needsDistributionOrder() ||
                    patientMedicationKit.needsUtilizationOrder()) {
                    //Go to the new physician order page and auto add
                    this.$state.go('patients.physician-orders.new', {
                        patientMedicationKitId: patientMedicationKit.id,
                    });
                }
                else if (patientMedicationKit.needsPhysicianSignature()) {
                    //Go to the current physician order to sign
                    this.$state.go('patients.physician-orders.view', {
                        id: patientMedicationKit.distribution_physician_order_id,
                    });
                }
            };
            PatientMedicationsController.prototype.splitRx = function (medication) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    template: '<md-dialog flex="80" aria-label="Split Rx">' +
                        '  <split-rx medication-id="' +
                        medication.id +
                        '" physician-id="' +
                        medication.physician_id +
                        '"></split-rx>' +
                        '</md-dialog>',
                    multiple: true,
                    skipHide: true,
                    preserveScope: true,
                })
                    .then(function (splitMedication) {
                    _this.MedicationService.replaceMedications([splitMedication]);
                    _this.applyFilters();
                    _this.logger.success('Medication successfully split.');
                });
            };
            PatientMedicationsController.prototype.cancelRx = function (medication) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/medications/medication/cancel-modal.html',
                    controller: 'CancelMedicationModalController as $ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: {
                        medication: medication,
                    },
                })
                    .then(function (cancelTransactions) {
                    medication.medication_transactions = medication.medication_transactions.concat(cancelTransactions);
                    _this.logger.success('The cancel message(s) have been sent.');
                });
            };
            PatientMedicationsController.prototype.editMedkit = function (patientMedicationKit) {
                var _this = this;
                this.PharmacyService.model = patientMedicationKit;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/medications/medication-kits/patient-medication-kit.html',
                    controller: 'PatientMedicationKitController as $ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: {
                        patientMedicationKit: patientMedicationKit,
                    },
                })
                    .then(function (updatedPatientMedicationKit) {
                    _this.$http
                        .patch('/api/v1/patient_medication_kits/' + patientMedicationKit.id, updatedPatientMedicationKit)
                        .then(function (response) {
                        _this.logger.success('The medication has been updated.');
                        _this.$mdDialog.hide();
                    })
                        .catch(function (errors) {
                        _this.logger.error(errors.data.errors.join(', '));
                    });
                });
            };
            PatientMedicationsController.prototype.discontinueMedication = function (medication) {
                var _this = this;
                var templateUrl = '/y/templates/patients/sections/medications/discontinue-modal.html';
                if (medication.dc || medication.discontinued_physician_order_id) {
                    templateUrl = '/y/templates/patients/sections/medications/discontinue-modal-no-order.html';
                }
                var discontinued = medication.dc;
                return this.$mdDialog
                    .show({
                    templateUrl: templateUrl,
                    controller: 'DiscontinueController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: {
                        medication: medication,
                        title: 'Discontinue Medication',
                        onlyDiscontinue: true,
                    },
                })
                    .then(function (discontinuedMedication) {
                    _this.logger.success('The medication has been discontinued');
                    //If the medication was already discontinued, stay on the medications page otherwise go to the newly created order
                    if (!discontinued && discontinuedMedication.discontinued_physician_order_id) {
                        _this.$state.go('patients.physician-orders.view', {
                            patientId: medication.patient_id,
                            id: discontinuedMedication.discontinued_physician_order_id,
                        });
                    }
                    _this.applyFilters();
                    return true;
                });
            };
            PatientMedicationsController.prototype.discontinueAndCreateMedicationModal = function (medication) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/sections/medications/discontinue-modal.html',
                    controller: 'DiscontinueController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: {
                        medication: medication,
                        title: 'Discontinue And Create Medication',
                        onlyDiscontinue: false,
                    },
                })
                    .then(function (discontinuedMedication) {
                    _this.applyFilters();
                    return medication;
                });
            };
            PatientMedicationsController.prototype.disposeMedKit = function (patientMedicationKit) {
                var _this = this;
                return this.$mdDialog
                    .show({
                    templateUrl: '/y/templates/patients/sections/medications/dispose-date.modal.html',
                    controller: [
                        '$mdDialog',
                        'patientMedicationKit',
                        function ($mdDialog, patientMedicationKit) {
                            this.$mdDialog = $mdDialog;
                            this.patientMedicationKit = patientMedicationKit;
                            this.dispose_date = new Date();
                        },
                    ],
                    controllerAs: '$ctrl',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { patientMedicationKit: patientMedicationKit },
                })
                    .then(function (dispose_date) {
                    return _this.$http
                        .post('/api/v1/patient_medication_kits/' + patientMedicationKit.id + '/dispose', {
                        dispose_date: dispose_date,
                    })
                        .then(function (response) {
                        _this.logger.success('The combined medication kit ' +
                            patientMedicationKit.name +
                            ' medications have been disposed.');
                        _this.getPatientMedicationKits();
                        var updatedPatientMedicationKit = new consolo.domain.PatientMedicationKit(response.data);
                        _this.MedicationService.replaceMedications(updatedPatientMedicationKit.medications);
                        _this.applyFilters();
                    })
                        .catch(function (errors) {
                        _this.errors = errors.data.errors;
                        _this.logger.error(_this.errors.join(', '));
                    });
                });
            };
            PatientMedicationsController.prototype.deleteMedKit = function (patientMedicationKit) {
                var _this = this;
                return this.$http
                    .delete('/api/v1/patient_medication_kits/' + patientMedicationKit.id, {})
                    .then(function (response) {
                    _this.logger.success('The combined medication kit ' + patientMedicationKit.name + ' has been deleted.');
                    _this.getPatientMedicationKits();
                    _this.applyFilters();
                })
                    .catch(function (errors) {
                    _this.errors = errors.data.errors;
                    _this.logger.error(_this.errors.join(', '));
                });
            };
            PatientMedicationsController.prototype.setDCValues = function (sourceMedication, destinationMedication) {
                destinationMedication.dc = sourceMedication.dc;
                destinationMedication.discontinued_physician_order_id =
                    sourceMedication.discontinued_physician_order_id;
            };
            PatientMedicationsController.prototype.highlightRow = function (medication) {
                if (medication.isDeleted()) {
                    return 'med-deleted';
                }
                if (medication.isDisposed()) {
                    return 'med-disposed';
                }
                if (medication.isDiscontinued()) {
                    return 'med-discontinued';
                }
                return '';
            };
            PatientMedicationsController.prototype.colorYesNo = function (itemVal) {
                if (itemVal === 'Yes') {
                    return 'colorYes';
                }
                else {
                    return 'colorNo';
                }
            };
            PatientMedicationsController.prototype.applyFilters = function () {
                this.filterNeedsAction();
                this.filterCurrentMedications();
            };
            PatientMedicationsController.prototype.filterNeedsAction = function () {
                this.needsActionMedications = this.$filter('filter')(this.MedicationService.medications, function (medication) {
                    if (medication.isCurrent()) {
                        return false;
                    }
                    if (medication.isActive()) {
                        return true;
                    }
                    return false;
                });
            };
            PatientMedicationsController.prototype.filterCurrentMedications = function () {
                var _this = this;
                this.currentMedications = this.$filter('filter')(this.MedicationService.medications, function (medication) {
                    if (_this.discontinued && medication.isDiscontinued()) {
                        return true;
                    }
                    if (_this.disposed && medication.isDisposed()) {
                        return true;
                    }
                    if (_this.deleted && medication.isDeleted()) {
                        return true;
                    }
                    if (!medication.isCurrent()) {
                        return false;
                    }
                    if (medication.isActive()) {
                        return true;
                    }
                    return false;
                });
            };
            PatientMedicationsController.prototype.printMedications = function () {
                this.sendPrintMedications('PatientMedsLong', 'Long Medication');
            };
            PatientMedicationsController.prototype.printShortMedications = function () {
                this.sendPrintMedications('PatientMeds', 'Short Medication');
            };
            PatientMedicationsController.prototype.sendPrintMedications = function (templateName, objectType) {
                var _this = this;
                this.printConfirmationService.show(function (options) {
                    options.patient_id = _this.currentPatient.id;
                    _this.PdferService.generateSinglePdfFromPost(templateName, [_this.currentPatient.id, options], options, '0', objectType, false, false, false, '', '', _this.currentPatient.id);
                });
            };
            PatientMedicationsController.prototype.deleteMedicationModal = function (medication) {
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/patients/sections/medications/delete-modal.html',
                    controller: [
                        'MedicationService',
                        '$mdDialog',
                        'medication',
                        function (MedicationService, $mdDialog, medication) {
                            this.MedicationService = MedicationService;
                            this.$mdDialog = $mdDialog;
                            this.medication = medication;
                            this.delete = function () {
                                return this.$mdDialog.hide(this.MedicationService.delete(this.medication));
                            };
                        },
                    ],
                    controllerAs: 'vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { medication: medication },
                });
            };
            PatientMedicationsController.prototype.addMedicationFavorites = function () {
                var _this = this;
                this.MedicationFavoritesService.addMedicationFavorites(false).then(function (medications) {
                    _this.applyFilters();
                });
            };
            PatientMedicationsController.prototype.addMedicationKit = function () {
                var _this = this;
                this.MedicationKitsService.getMedicationKits(this.currentPatient.id).then(function (medicationKits) {
                    var dialogOptions = {
                        controller: 'AddMedicationKitController as vm',
                        templateUrl: '/y/templates/patients/sections/medications/add-medication-kit-modal.html',
                        fullscreen: true,
                        clickOutsideToClose: true,
                        locals: { medicationKits: medicationKits, currentPatient: _this.currentPatient },
                    };
                    return _this.$mdDialog.show(dialogOptions).then(function (response) {
                        if (response.errors.length) {
                            _this.logger.error(response.errors);
                        }
                        _this.getPatientMedicationKits();
                        _this.MedicationService.replaceMedications(response.medications);
                        _this.applyFilters();
                    });
                });
            };
            PatientMedicationsController.prototype.getPatientMedicationKits = function () {
                var _this = this;
                this.MedicationService.getPatientMedicationKits(this.currentPatient.id, true).then(function (patientMedKits) {
                    _this.patientMedicationKits = patientMedKits;
                });
            };
            PatientMedicationsController.prototype.requestMedicationHistory = function () {
                return this.$mdDialog.show({
                    templateUrl: '/y/templates/medications/medication-history/consent-modal.html',
                    controller: 'MedicationHistoryConsentController as vm',
                    bindToController: true,
                    clickOutsideToClose: true,
                    locals: { currentPatient: this.currentPatient },
                });
            };
            PatientMedicationsController.prototype.showAddNoteDialog = function (medication) {
                var url = "'/y/templates/patients/sections/medications/add-medication-note.html'";
                return this.$mdDialog.show({
                    clickOutsideToClose: true,
                    template: '<md-dialog flex="80" aria-label="Add Medication Note">' +
                        '  <md-dialog-content>' +
                        '   <div ng-include="' +
                        url +
                        '"/>' +
                        '  </md-dialog-content>' +
                        '</md-dialog>',
                    controller: 'PatientMedicationNoteController as vm',
                    bindToController: true,
                    locals: { currentPatient: this.currentPatient, medication: medication, modal: true },
                });
            };
            PatientMedicationsController.prototype.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };
            PatientMedicationsController.prototype.formatOrderStatus = function (medication) {
                var s;
                switch (medication.order_status) {
                    case 1:
                        s = 'Create Physician Order';
                        break;
                    case 2:
                        s = 'Add Nurse Signature';
                        break;
                    case 3:
                        s = 'Add Physician Signature';
                        break;
                    case 7:
                        s = 'Transmission Failed';
                        break;
                    case 8:
                        s = 'Needs Utilization Order';
                        break;
                    case 4:
                        s = medication.order_status_description;
                        break;
                    case 10:
                        s = 'Rx Renewal Request';
                        break;
                    case 11:
                        s = 'Rx Change Request';
                        break;
                }
                return s;
            };
            PatientMedicationsController.prototype.processOrderStatus = function (medication) {
                var _this = this;
                if (medication.order_status === OrderStatus.transmissionFailed && medication.isDeleted()) {
                    return null;
                }
                else if (medication.order_status === OrderStatus.ePrescribe) {
                    return this.$mdDialog
                        .show({
                        templateUrl: '/y/templates/patients/sections/medications/eprescribe-medication.html',
                        controller: 'EPrescribeMedicationController as vm',
                        hasBackdrop: true,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        locals: { medication: medication },
                    })
                        .then(function (updatedMedication) {
                        _this.applyFilters();
                        return true;
                    }, function () {
                        return false;
                    });
                }
                else if (medication.order_status === OrderStatus.transmissionFailed) {
                    return this.$mdDialog
                        .show({
                        templateUrl: '/y/templates/patients/sections/medications/transmission-failed.html',
                        controller: 'TransmissionFailedController as vm',
                        hasBackdrop: true,
                        clickOutsideToClose: true,
                        escapeToClose: true,
                        locals: { medication: medication },
                    })
                        .then(function (updatedMedication) {
                        _this.applyFilters();
                        return true;
                    }, function () {
                        return false;
                    });
                }
                else if (medication.current_medication_transaction &&
                    medication.current_medication_transaction.physician_order_id) {
                    this.$state.transitionTo('patients.physician-orders.view', {
                        patientId: this.currentPatient.id,
                        id: medication.current_medication_transaction.physician_order_id,
                    });
                }
                else {
                    return this.$state.go('patients.physician-orders.new', {
                        patientId: medication.patient_id,
                        physician_id: medication.physician_id,
                        medicationId: medication.id,
                    });
                }
            };
            PatientMedicationsController.prototype.editMedication = function (medication) {
                return this.MedicationService.editMedication(medication).then(function (updatedMedication) {
                    angular.copy(updatedMedication, medication);
                    return true;
                }, function () {
                    return false;
                });
            };
            PatientMedicationsController.prototype.hideMedicationEdit = function (medication) {
                if (medication.order_status === OrderStatus.ePrescribe ||
                    (medication.order_status === OrderStatus.transmissionFailed && medication.isDeleted())) {
                    return true;
                }
                return !this.MedicationService.update;
            };
            PatientMedicationsController.prototype.deleteMedication = function (medication) {
                var _this = this;
                return this.deleteMedicationModal(medication).then(function (updatedMedication) {
                    _this.applyFilters();
                    return true;
                }, function () {
                    return false;
                });
            };
            PatientMedicationsController.prototype.hideDeleteMedication = function (medication) {
                if (medication.order_status === OrderStatus.ePrescribe) {
                    return true;
                }
                return !this.MedicationService.deletePermission;
            };
            PatientMedicationsController.prototype.viewMedication = function (medication) {
                return this.$state.go('patients.medications.view', {
                    patientId: medication.patient_id,
                    medicationId: medication.id,
                });
            };
            PatientMedicationsController.prototype.formatSignatureItem = function () {
                var s = 'Add Your Signature';
                if (this.MedicationService.medicationSet) {
                    if (this.MedicationService.medicationSet.has_signature &&
                        this.MedicationService.medicationSet.medications_same_as_active) {
                        s = 'Resign This Medication Set';
                    }
                }
                return s;
            };
            PatientMedicationsController.prototype.logMedkit = function (medkit) {
                console.log(medkit);
            };
            PatientMedicationsController.prototype.logMedication = function (medication) {
                console.log(medication);
            };
            PatientMedicationsController.$inject = [
                '$scope',
                '$window',
                'currentPatient',
                'Medication',
                'CSV',
                '$mdDialog',
                '$filter',
                'SessionService',
                '$state',
                '$timeout',
                '$http',
                'logger',
                'PdfGenerator',
                'NetworkManager',
                'SyncManager',
                'cache',
                'MedicationService',
                'MedicationKitsService',
                'MedicationFavoritesService',
                'PharmacyService',
                'EprescribeService',
                'PubSubService',
                'AboutService',
                'pdfer',
                'PrintConfirmationService',
                '$q'
            ];
            return PatientMedicationsController;
        }());
        angular
            .module('consolo')
            .controller('PatientMedicationsController', PatientMedicationsController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var OneTimeDoseController = /** @class */ (function () {
            function OneTimeDoseController(medication, $mdDialog) {
                this.medication = medication;
                this.$mdDialog = $mdDialog;
            }
            OneTimeDoseController.prototype.setOneTimeDose = function () {
                if (this.medication.dosage_frequency_start_datetime) {
                    this.medication.is_scheduled = true;
                    this.medication.dosage_frequency_end_datetime = this.medication.dosage_frequency_start_datetime;
                    return this.$mdDialog.hide();
                }
                else {
                    this.errors = ['You must enter an Administer time to continue'];
                }
            };
            OneTimeDoseController.$inject = ['medication', '$mdDialog'];
            return OneTimeDoseController;
        }());
        angular.module('consolo').controller('OneTimeDoseController', OneTimeDoseController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PharmacyHistoryController = /** @class */ (function () {
            function PharmacyHistoryController($stateParams, $http, currentPatient, $state, pharmacyHistory) {
                var _this = this;
                this.$stateParams = $stateParams;
                this.$http = $http;
                this.currentPatient = currentPatient;
                this.$state = $state;
                this.pharmacyHistory = pharmacyHistory;
                this.pharmacyLayout = {
                    options: {
                        hideTextFilter: true,
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        defaultPageSize: 250,
                    },
                    columns: [
                        { title: 'Quantity', field: 'quantity' },
                        { title: 'Refills', field: 'refills' },
                        { title: 'Physician', field: 'physician' },
                        { title: 'Pharmacy', field: 'pharmacy' },
                        { title: 'Action', field: 'action' },
                        {
                            title: 'Timestamp',
                            field: 'timestamp',
                            formatValueFn: function (history) {
                                return new Date(history.timestamp).toLocaleString().replace(',', '');
                            },
                        },
                        { title: 'ID', field: 'id' },
                    ],
                    rowMenu: {
                        items: [
                            {
                                icon: 'content_paste',
                                label: 'View Medication',
                                callbackFn: function (medicationHistory) {
                                    if (medicationHistory.id.length === 36) {
                                        return _this.$http
                                            .get('/api/v1/medication_transactions/' + medicationHistory.id)
                                            .then(function (response) {
                                            return _this.$state.go('patients.medications.view', {
                                                patientId: _this.currentPatient.id,
                                                medicationId: response.data.medication_id,
                                            });
                                        });
                                    }
                                    else {
                                        return _this.$state.go('patients.medications.view', {
                                            patientId: _this.currentPatient.id,
                                            medicationId: medicationHistory.id,
                                        });
                                    }
                                },
                            },
                        ],
                    },
                };
            }
            PharmacyHistoryController.$inject = ['$stateParams', '$http', 'currentPatient', '$state', 'pharmacyHistory'];
            return PharmacyHistoryController;
        }());
        angular.module('consolo').controller('PharmacyHistoryController', PharmacyHistoryController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var Refill = /** @class */ (function () {
            function Refill(patientId) {
                this.patient_id = patientId;
                this.refills = [];
                this.medication = {
                    status: null,
                    delivery: null,
                    has_administered_by_family: false,
                    has_administered_by_staff: false,
                    has_administered_by_nurse: false,
                    has_administered_by_patient: false,
                    has_administered_by_other: false,
                    note_administered_by_family: null,
                    note_administered_by_staff: null,
                    note_administered_by_nurse: null,
                    note_administered_by_patient: null,
                    note_administered_by_other: null,
                };
            }
            return Refill;
        }());
        controllers.Refill = Refill;
        var RefillController = /** @class */ (function () {
            function RefillController(currentPatient, MedicationService, refillMedications, $mdDialog) {
                this.currentPatient = currentPatient;
                this.MedicationService = MedicationService;
                this.refillMedications = refillMedications;
                this.$mdDialog = $mdDialog;
                this.saving = false;
                this.refillParams = new Refill(currentPatient.id);
                refillMedications.forEach(function (medication) {
                    medication.selected = false;
                });
            }
            RefillController.prototype.refill = function () {
                var _this = this;
                this.saving = true;
                this.medicationsWithNoRefills = [];
                /* COMMENTING OUT THIS CODE, THIS FUNCTIONALITY WAS ASKED FOR AT ONE TIME BUT NOT TESTED
                //Set the selected medications with refills and selected medications that are controlled or no more refills
                this.refillMedications.forEach((medication) => {
                  if (medication.selected && medication.refills_remaining > 0) {
                    this.refillParams.refills.push(medication);
                  } else if (medication.selected && medication.refills_remaining === 0) {
                    this.medicationsWithNoRefills.push(medication);
                  }
                });
                */
                this.refillMedications.forEach(function (medication) {
                    if (medication.selected) {
                        _this.refillParams.refills.push(medication);
                    }
                });
                if (this.medicationsWithNoRefills.length === 0) {
                    //All refills are good, send to the refill API
                    this.MedicationService.refill(this.refillParams).then(function () {
                        _this.saving = false;
                    });
                }
                else {
                    //Need to display dialog asking if medications with no refills need a new order
                    var dialog = {
                        templateUrl: '/y/templates/patients/sections/medications/refill-and-create-new-order.html',
                        controller: [
                            'refillParams',
                            'medicationsWithNoRefills',
                            '$mdDialog',
                            'MedicationService',
                            'PhysicianOrdersService',
                            function (refillParams, medicationsWithNoRefills, $mdDialog, MedicationService, PhysicianOrdersService) {
                                this.refillParams = refillParams;
                                this.medicationsWithNoRefills = medicationsWithNoRefills;
                                this.$mdDialog = $mdDialog;
                                this.MedicationService = MedicationService;
                                this.PhysicianOrdersService = PhysicianOrdersService;
                                this.refill = refill;
                                this.refillAndCreatePhysicianOrder = refillAndCreatePhysicianOrder;
                                function refill() {
                                    this.MedicationService.refill(this.refillParams);
                                    this.$mdDialog.hide();
                                }
                                function refillAndCreatePhysicianOrder() {
                                    this.MedicationService.refill(this.refillParams);
                                    this.PhysicianOrdersService.createNewOrder(this.medicationsWithNoRefills);
                                    this.$mdDialog.hide();
                                }
                            },
                        ],
                        clickOutsideToClose: true,
                        controllerAs: 'vm',
                        locals: {
                            refillParams: this.refillParams,
                            medicationsWithNoRefills: this.medicationsWithNoRefills,
                        },
                    };
                    this.$mdDialog.show(dialog).finally(function () {
                        _this.saving = false;
                    });
                }
            };
            RefillController.prototype.selectAll = function () {
                this.refillMedications.forEach(function (medication) {
                    medication.selected = true;
                });
            };
            RefillController.prototype.clearAll = function () {
                this.refillMedications.forEach(function (medication) {
                    medication.selected = false;
                });
            };
            RefillController.$inject = ['currentPatient', 'MedicationService', 'refillMedications', '$mdDialog'];
            return RefillController;
        }());
        angular.module('consolo').controller('RefillController', RefillController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var TransmissionFailedController = /** @class */ (function () {
            function TransmissionFailedController(MedicationService, $mdDialog, medication) {
                this.MedicationService = MedicationService;
                this.$mdDialog = $mdDialog;
                this.medication = medication;
                this.medication.deleted_reason = 'E-Prescribe Failed Transmission';
            }
            TransmissionFailedController.prototype.handleFailedMedication = function () {
                if (!this.option) {
                    this.error = ['You must select an option.'];
                }
                else if (!this.medication.deleted_reason) {
                    this.error = ['You must have a reason for deleting filled out.'];
                }
                else if (this.option === 'deleteAndRecreate') {
                    return this.$mdDialog.hide(this.MedicationService.deleteAndRecreate(this.medication));
                }
                else {
                    return this.$mdDialog.hide(this.MedicationService.delete(this.medication));
                }
            };
            TransmissionFailedController.$inject = ['MedicationService', '$mdDialog', 'medication'];
            return TransmissionFailedController;
        }());
        angular
            .module('consolo')
            .controller('TransmissionFailedController', TransmissionFailedController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var ViewMedicationComponent = /** @class */ (function () {
            function ViewMedicationComponent() {
                this.templateUrl = '/y/templates/patients/sections/medications/view.html';
                this.controller = ViewMedicationController;
                this.controllerAs = 'vm';
                this.bindings = {
                    currentPatient: '<',
                    id: '<',
                    isPartial: '<',
                };
            }
            return ViewMedicationComponent;
        }());
        var ViewMedicationController = /** @class */ (function () {
            function ViewMedicationController(MedicationService, PhysicianOrdersService, $http, $state, $q, EprescribeService) {
                var _this = this;
                this.MedicationService = MedicationService;
                this.PhysicianOrdersService = PhysicianOrdersService;
                this.$http = $http;
                this.$state = $state;
                this.$q = $q;
                this.EprescribeService = EprescribeService;
                this.loadingSpinner = false;
                this.getMedication(this.id || this.$state.params.medicationId);
                this.medicationTransactionsLayout = {
                    id_field: 'id',
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                        defaultPageSize: 250,
                    },
                    columns: [
                        {
                            title: 'Order Date',
                            field: 'effective_datetime',
                            type: csg.datatable.ColumnTypes.dateTime,
                        },
                        {
                            title: 'Type',
                            field: 'transaction_type',
                            type: csg.datatable.ColumnTypes.long_text,
                            formatValueFn: function (medicationTransaction) {
                                var display = '';
                                switch (medicationTransaction.transaction_type) {
                                    case 'newrx': {
                                        display = 'NewRx';
                                        break;
                                    }
                                    case 'split_rx': {
                                        display = 'SplitRx';
                                        break;
                                    }
                                    case 'reorder': {
                                        display = 'Reorder';
                                        break;
                                    }
                                    case 'rx_cancel': {
                                        display = 'CancelRx';
                                        break;
                                    }
                                    case 'rxChangeRequest': {
                                        display = 'Change Request';
                                        break;
                                    }
                                    case 'rxRenewalResponse': {
                                        display = 'Renewal Response';
                                        if (medicationTransaction.is_approved) {
                                            display += ': Approved';
                                        }
                                        else {
                                            display += ': Denied';
                                        }
                                        break;
                                    }
                                    case 'rxChangeResponse': {
                                        display = 'RxChange Response';
                                        if (medicationTransaction.is_approved) {
                                            display += ': Approved';
                                        }
                                        else {
                                            display += ': Denied';
                                        }
                                        break;
                                    }
                                    case 'rxCancelResponse': {
                                        display = 'RxCancel Response';
                                        if (medicationTransaction.is_approved) {
                                            display +=
                                                ': Approved' + ' ' + medicationTransaction.pharmacy_message.Approved.Note;
                                        }
                                        else {
                                            display +=
                                                ': Denied ' +
                                                    (medicationTransaction.pharmacy_message.Denied.ReasonCode
                                                        ? medicationTransaction.pharmacy_message.Denied.ReasonCode
                                                        : '') +
                                                    ' - ' +
                                                    medicationTransaction.pharmacy_message.Denied.DenialReason;
                                        }
                                        break;
                                    }
                                    case 'rxRenewalRequest': {
                                        display = 'RxRenewal Request';
                                        break;
                                    }
                                    case 'rxfill': {
                                        display = 'RxFill';
                                        break;
                                    }
                                    case 'resupply': {
                                        display = 'Resupply';
                                        break;
                                    }
                                    default:
                                        break;
                                }
                                return display;
                            },
                        },
                        { title: 'Ordering Physician/Provider', field: 'physician.name' },
                        {
                            title: 'Pharmacy',
                            field: 'pharmacy',
                            formatValueFn: function (medicationTransaction) {
                                if (medicationTransaction.pharmacy) {
                                    return medicationTransaction.pharmacy.name;
                                }
                                else if (medicationTransaction.surescripts_pharmacy) {
                                    return medicationTransaction.surescripts_pharmacy.name;
                                }
                                else {
                                    return '';
                                }
                            },
                        },
                        {
                            title: 'Quantity',
                            field: 'quantity',
                            formatValueFn: function (medicationTransaction) {
                                return _this.MedicationService.validateQuantity(medicationTransaction.quantity);
                            },
                        },
                        { title: 'Refills', field: 'refills' },
                    ],
                    rowMenu: {
                        items: [
                            /*
                            {
                              label: 'Create Follow Up Response',
                              icon: 'remove_red_eye',
                              callbackFn: (medicationTransaction: domain.IMedicationTransaction) => {
                                return this.$http
                                  .post(
                                    '/api/v1/medication_transactions/' +
                                      medicationTransaction.id +
                                      '/create_follow_up',
                                    {}
                                  )
                                  .then(response => {
                                    this.getMedication(medicationTransaction.medication_id);
                                    return true;
                                  })
                                  .catch(errors => {
                                    this.errors = errors.data.errors;
                                    return false;
                                  })
                                  .finally(() => {
                                    this.loadingSpinner = false;
                                  });
                              },
                              conditionalHideFn: (medicationTransaction: domain.IMedicationTransaction) => {
                                return (
                                  medicationTransaction.transaction_type !== 'resupply' ||
                                  medicationTransaction.pharmacy_response_received ||
                                  new Date(
                                    new Date(medicationTransaction.effective_datetime).getTime() +
                                      60 * 60 * 24 * 1000
                                  ) < new Date()
                                );
                              },
                            },*/
                            {
                                label: 'View RxFill Details',
                                icon: 'remove_red_eye',
                                callbackFn: function (medicationTransaction) {
                                    return _this.$state.go('patients.medications.view-rxfill', {
                                        medicationTransactionId: medicationTransaction.id,
                                        patientId: medicationTransaction.patient_id,
                                    });
                                },
                                conditionalHideFn: function (medicationTransaction) {
                                    return medicationTransaction.transaction_type !== 'rxfill';
                                },
                            },
                            {
                                label: 'View Details',
                                icon: 'remove_red_eye',
                                callbackFn: function (medicationTransaction) {
                                    return _this.$http
                                        .get('/api/v1/medication_transactions/' + medicationTransaction.id)
                                        .then(function (response) {
                                        medicationTransaction = new consolo.domain.MedicationTransaction(response.data);
                                        medicationTransaction.medication = new consolo.domain.Medication(medicationTransaction.medication);
                                        return _this.EprescribeService.view(medicationTransaction, medicationTransaction.patient);
                                    });
                                },
                                conditionalHideFn: function (medicationTransaction) {
                                    return !medicationTransaction.isRxChangeRequest();
                                },
                            },
                        ],
                    },
                };
                this.adverseEffectsLayout = {
                    options: {
                        hideTextFilter: true,
                        hideFooter: true,
                    },
                    columns: [
                        { title: 'Incident Level', field: 'incident_level' },
                        { title: 'Effects', field: 'effects' },
                    ],
                };
                this.ingredientsTableLayout = {
                    id_field: 'name',
                    options: {
                        showExpandCollapseAll: false,
                        hideFooter: true,
                        hideTextFilter: true,
                    },
                    columns: [
                        {
                            title: 'Name',
                            field: 'name',
                            type: csg.datatable.ColumnTypes.long_text,
                            formatValueFn: function (ingredient) {
                                return (ingredient.name +
                                    ' ' +
                                    ingredient.strength +
                                    ingredient.strength_unit +
                                    ' ' +
                                    ingredient.form);
                            },
                        },
                        {
                            title: 'Dosage',
                            field: 'dosage',
                            formatValueFn: function (ingredient) {
                                return ingredient.quantity + ' ' + ingredient.quantity_unit_of_measure.description;
                            },
                        },
                    ],
                };
            }
            ViewMedicationController.prototype.$onInit = function () {
                this.modal = this.isPartial;
            };
            ViewMedicationController.prototype.getMedication = function (medicationId) {
                var _this = this;
                this.loadingSpinner = true;
                var defer = this.$q.defer();
                this.MedicationService.get(medicationId).then(function (medication) {
                    if (medication.physician_order_id) {
                        _this.PhysicianOrdersService.get(medication.physician_order_id).then(function (physicianOrder) {
                            medication.physician_order = physicianOrder;
                            _this.medication = medication;
                            defer.resolve();
                        });
                    }
                    else {
                        _this.medication = medication;
                        defer.resolve();
                    }
                });
                defer.promise.then(function () {
                    _this.loadingSpinner = false;
                    _this.MedicationService.getAdverseEffects(_this.medication.gpi).then(function (effects) {
                        _this.adverseEffects = effects;
                    });
                    if (_this.medication.physician_order_id) {
                        _this.physicianOrder = _this.medication.physician_order;
                    }
                });
            };
            ViewMedicationController.$inject = [
                'MedicationService',
                'PhysicianOrdersService',
                '$http',
                '$state',
                '$q',
                'EprescribeService',
            ];
            return ViewMedicationController;
        }());
        angular.module('consolo').component('viewMedication', new ViewMedicationComponent());
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var InternalMessagesController = /** @class */ (function () {
            function InternalMessagesController(CurrentPatient, Session, $sce, BeaconService) {
                var _this = this;
                this.CurrentPatient = CurrentPatient;
                this.Session = Session;
                this.$sce = $sce;
                this.BeaconService = BeaconService;
                this.messagesLoading = false;
                this.messages = [];
                this.originalMessages = [];
                this.page = 1;
                this.containerOffsetHeight = 0;
                this.endOfConversation = false;
                this.user = this.Session.getUser();
                this.getMessages().then(function () {
                    var messageContainer = angular.element(document.querySelector('#message-content'));
                    messageContainer.on('scroll', function (event) {
                        if (messageContainer.scrollTop() + messageContainer[0].offsetHeight ===
                            messageContainer[0].scrollHeight &&
                            !_this.endOfConversation) {
                            _this.getMessages();
                        }
                    });
                });
            }
            InternalMessagesController.prototype.getMessages = function () {
                var _this = this;
                this.messagesLoading = true;
                return this.BeaconService.getPatientMessages(this.CurrentPatient.patient_id, this.page)
                    .then(function (res) {
                    if (res.data && res.data.length) {
                        _this.originalMessages = __spreadArrays(_this.originalMessages, res.data);
                        _this.messages = _this.originalMessages;
                        _this.page++;
                    }
                    else {
                        _this.endOfConversation = true;
                    }
                    _this.messagesLoading = false;
                })
                    .catch(function (e) {
                    console.log(e);
                    _this.messagesLoading = false;
                });
            };
            InternalMessagesController.prototype.renderBody = function (body) {
                return this.$sce.trustAsHtml(body);
            };
            InternalMessagesController.prototype.messageIsMine = function (message) {
                return message.from === this.user.first_name + " " + this.user.last_name;
            };
            InternalMessagesController.prototype.onSearchChange = function () {
                var _this = this;
                this.messages = this.textFilter
                    ? this.originalMessages.filter(function (message) { return message.text_body.toLowerCase().indexOf(_this.textFilter) > -1; })
                    : this.originalMessages;
            };
            InternalMessagesController.$inject = ['CurrentPatient', 'SessionService', '$sce', 'BeaconService', '$timeout'];
            return InternalMessagesController;
        }());
        angular.module('consolo').controller('InternalMessagesController', InternalMessagesController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientTaskController = /** @class */ (function () {
            function PatientTaskController($stateParams, Task, Session, $mdDialog, logger, $window, CurrentPatient) {
                var _this = this;
                this.$stateParams = $stateParams;
                this.Task = Task;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.$window = $window;
                this.CurrentPatient = CurrentPatient;
                this.today = new Date(new Date().toDateString());
                this.saveText = 'Save';
                this.loggerText = 'Task Saved';
                if (!this.taskId) {
                    this.pageTitle = 'Create a Task';
                    this.task = new Task();
                    this.task.patient_id = this.$stateParams.patientId;
                    this.task.effective_date = this.today;
                    var user = Session.getUser();
                    this.task.user = user;
                    this.task.user_id = user.id;
                    this.task.created_by_id = user.id;
                }
                else {
                    this.loading = true;
                    this.Task.get({ id: this.taskId }, function (t) {
                        _this.loading = false;
                        _this.task = t;
                        if (_this.markComplete) {
                            _this.pageTitle = 'Mark Task Complete';
                            _this.saveText = 'Complete';
                            _this.loggerText = 'Task marked complete';
                            _this.task.completed_date = _this.today;
                        }
                        else {
                            _this.pageTitle = 'Edit Task';
                        }
                    }, function () {
                        _this.logger.error('Error retrieving task: ' + _this.taskId);
                        _this.$mdDialog.hide();
                        return false;
                    });
                }
            }
            PatientTaskController.prototype.createTask = function () {
                var _this = this;
                var tempUser = this.task.user;
                this.task.user = null;
                var save = !this.task.id
                    ? this.task.$save()
                    : !this.markComplete
                        ? this.task.$update()
                        : this.task.$markComplete();
                save.then(function (d) {
                    _this.logger.success(_this.loggerText);
                    d.assigned_user = tempUser;
                    _this.$mdDialog.hide(d);
                    _this.CurrentPatient.refreshTasks(_this.task);
                }, function (e) {
                    console.log('Error creating Task');
                    console.log(e);
                    _this.errors = e.errors;
                });
            };
            PatientTaskController.prototype.closeDialog = function () {
                this.$mdDialog.hide();
            };
            PatientTaskController.prototype.saveDisabled = function () {
                if (this.loading) {
                    return false;
                }
                if (this.markComplete) {
                    return !this.task.completed_date;
                }
                else {
                    return (!this.task.effective_date || !this.task.due_date || !this.task.user_id || !this.task.task);
                }
            };
            PatientTaskController.$inject = [
                '$stateParams',
                'Task',
                'SessionService',
                '$mdDialog',
                'logger',
                '$window',
                'CurrentPatient',
            ];
            return PatientTaskController;
        }());
        angular.module('consolo').controller('PatientTaskController', PatientTaskController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        var PatientTasksController = /** @class */ (function () {
            function PatientTasksController(Session, Task, CurrentPatient, CSV, $mdDialog, logger) {
                var _this = this;
                this.Session = Session;
                this.Task = Task;
                this.CurrentPatient = CurrentPatient;
                this.CSV = CSV;
                this.$mdDialog = $mdDialog;
                this.logger = logger;
                this.today = new Date();
                this.currentFilter = function (value, index, array) {
                    if (!value.completed_date) {
                        return true;
                    }
                    return false;
                };
                this.completedFilter = function (value, index, array) {
                    if (value.completed_date) {
                        if (new Date(value.completed_date) < _this.today) {
                            return true;
                        }
                    }
                    return false;
                };
                var user = Session.getUser();
                this.user_dashboard_url = user.dashboard_url;
                this.patient = CurrentPatient.patient;
                this.currentOptions = {
                    enablePaging: true,
                    pagingOptions: {
                        currentPage: 1,
                        rowsPerPage: 2,
                        changePage: function (page) {
                            console.log(_this.currentOptions.pagingOptions.currentPage);
                        },
                    },
                };
                this.completedOptions = {
                    enablePaging: true,
                    pagingOptions: {
                        currentPage: 1,
                        rowsPerPage: 2,
                        changePage: function (page) {
                            console.log(_this.completedOptions.pagingOptions.currentPage);
                        },
                    },
                };
                Task.loadByPatientId(CurrentPatient.patient_id).$promise.then(function (data) {
                    data.forEach(function (item) {
                        item.created_by_name = item.created_by.name;
                        item.assigned_to_name = item.user.name;
                        if (item['completed_by_id']) {
                            item.completed_by_name = item['completed_by'].name;
                        }
                        if (item['completed_date']) {
                            item.completed_date = item['completed_date'];
                        }
                    });
                    _this.tasks = data;
                });
                this.formModel = {
                    options: {
                        expandCollapseAll: false,
                        noExpand: true,
                    },
                    header: [
                        {
                            header: true,
                            title: 'Task',
                            field: 'task',
                        },
                        {
                            header: true,
                            title: 'Assigned To',
                            field: 'assigned_to_name',
                        },
                        {
                            header: true,
                            title: 'Due Date',
                            is_date: true,
                            field: 'due_date',
                        },
                        {
                            header: true,
                            title: 'Created By',
                            field: 'created_by_name',
                        },
                        {
                            header: true,
                            title: 'Created Date',
                            is_date: true,
                            field: 'effective_date',
                        },
                        {
                            header: true,
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            showExpandCollapse: false,
                            csvContents: function () {
                                return _this.taskCsv(_this.tasks);
                            },
                            csvFilename: 'Patient ' +
                                this.CurrentPatient.patient_id +
                                ' Tasks ' +
                                new Date().toISOString().slice(0, 10) +
                                '.csv',
                            itemMenuItems: [
                                {
                                    icon: 'image:edit',
                                    text: 'Edit Task',
                                    callbackFn: function ($event, item) {
                                        _this.showTaskDialog($event, item, false);
                                    },
                                },
                                {
                                    icon: 'navigation:check',
                                    text: 'Mark Complete',
                                    callbackFn: function ($event, item) {
                                        _this.showTaskDialog($event, item, true);
                                    },
                                },
                                {
                                    icon: 'action:delete',
                                    text: 'Delete Task',
                                    callbackFn: function ($event, item) {
                                        _this.deleteTask($event, item);
                                    },
                                },
                            ],
                        },
                    ],
                };
                this.formModelCompleted = {
                    options: {
                        expandCollapseAll: false,
                        noExpand: true,
                    },
                    header: [
                        {
                            header: true,
                            title: 'Task',
                            field: 'task',
                        },
                        {
                            header: true,
                            title: 'Completed By',
                            field: 'completed_by_name',
                        },
                        {
                            header: true,
                            title: 'Completed Date',
                            is_date: true,
                            field: 'completed_date',
                        },
                        {
                            header: true,
                            title: 'Follow Up',
                            field: 'follow_up',
                        },
                        {
                            header: true,
                            title: '',
                            shortTitle: 'Actions',
                            menu: true,
                            headerClass: 'text-right',
                            itemClass: 'text-right',
                            showExpandCollapse: false,
                            csvContents: function () {
                                return _this.taskCsv(_this.tasks);
                            },
                            csvFilename: 'Patient ' +
                                this.CurrentPatient.patient_id +
                                ' Tasks ' +
                                new Date().toISOString().slice(0, 10) +
                                '.csv',
                            itemMenuItems: [],
                        },
                    ],
                };
            }
            PatientTasksController.prototype.taskCsv = function (tasks) {
                var headers = [
                    'Task',
                    'Assigned To',
                    'Due Date',
                    'Created By',
                    'Created Date',
                    'Completed Date',
                ];
                var date = this.CSV.date, yn = this.CSV.yn;
                return this.CSV.generate(tasks.map(function (task) {
                    return [
                        task.task,
                        task.assigned_to_name,
                        date(task.due_date),
                        task.created_by_name,
                        date(task.effective_date),
                        date(task.completed_date),
                    ];
                }), headers);
            };
            PatientTasksController.prototype.urlTo = function (url) {
                document.location.href = '/patients/' + this.patient.id + '/' + url;
            };
            PatientTasksController.prototype.deleteTask = function ($event, item) {
                var _this = this;
                var confirm = this.$mdDialog.confirm({
                    title: 'Confirm Task Deletion',
                    textContent: 'Are you sure you want to delete the task ' + item.task + '?',
                    ok: 'Delete',
                    cancel: 'Keep Task',
                });
                this.$mdDialog.show(confirm).then(function () {
                    item.$delete().then(function () {
                        _this.tasks.splice(_this.tasks.indexOf(item), 1);
                        _this.CurrentPatient.refreshTasks(item, true);
                    });
                }, function () {
                    _this.logger.success('Kept task ' + item.task);
                });
            };
            PatientTasksController.prototype.showTaskDialog = function ($event, item, markComplete) {
                var _this = this;
                if (!item) {
                    item = new this.Task();
                }
                this.$mdDialog
                    .show({
                    clickOutsideToClose: false,
                    bindToController: true,
                    templateUrl: '/y/templates/patients/sections/tasks/task.html',
                    controller: 'PatientTaskController as vm',
                    locals: {
                        markComplete: markComplete,
                        taskId: item.id,
                    },
                })
                    .then(function (task) {
                    if (task) {
                        var index = _this.tasks.indexOf(item);
                        task.created_by_name = task.created_by.name;
                        task.assigned_to_name = task.user.name;
                        if (markComplete) {
                            task.completed_by_name = task.completed_by.name;
                            // remove item identified in params from Current Tasks
                            var completed = _this.tasks.splice(index, 1);
                            // push task into Completed Tasks data-table
                            _this.tasks.push(task);
                        }
                        else {
                            if (index === -1) {
                                _this.tasks.push(task);
                            }
                            else {
                                _this.tasks[index] = task;
                            }
                        }
                    }
                });
            };
            PatientTasksController.$inject = [
                'SessionService',
                'Task',
                'CurrentPatient',
                'CSV',
                '$mdDialog',
                'logger',
            ];
            return PatientTasksController;
        }());
        angular.module('consolo').controller('PatientTasksController', PatientTasksController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var controllers;
    (function (controllers) {
        'use strict';
        var ClinicalChartsSectionController = /** @class */ (function () {
            function ClinicalChartsSectionController(PlanOfCareService, logger, ClinicalChartEntry, $stateParams, $filter) {
                this.PlanOfCareService = PlanOfCareService;
                this.logger = logger;
                this.ClinicalChartEntry = ClinicalChartEntry;
                this.$stateParams = $stateParams;
                this.$filter = $filter;
                this.selectedIds = [];
                this.items = [];
                this.currentPage = 0;
                this.pageSize = 50;
                this.getCharts();
            }
            ClinicalChartsSectionController.prototype.getCharts = function () {
                var _this = this;
                this.loading = true;
                var since = new Date();
                since.setDate(since.getDate() - 30);
                return this.ClinicalChartEntry.getHeadersByPatientId(this.$stateParams.patientId, this.currentPage + 1, this.pageSize, {
                    start_date: this.$filter('date')(since, 'yyyy-MM-dd'),
                }).$promise.then(function (chartData) {
                    _this.setCharts(chartData);
                }).catch(function (error) {
                    console.log(error.data.errors.join(', '));
                    _this.logger.error(error.data.errors.join(', '), 'Error:');
                    _this.loading = false;
                });
            };
            ClinicalChartsSectionController.prototype.setCharts = function (chartData) {
                this.items = this.items.concat(chartData.items);
                this.currentPage = chartData.page;
                this.totalPages = chartData.pages;
                this.setSelected();
            };
            ClinicalChartsSectionController.prototype.setSelected = function () {
                var _this = this;
                this.items.forEach(function (item) {
                    item.selected = _this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids
                        && _this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids.indexOf(item.id) >= 0;
                });
                this.loading = false;
            };
            ClinicalChartsSectionController.prototype.selectAll = function () {
                var _this = this;
                this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids = [];
                this.items.forEach(function (item) {
                    item.selected = true;
                    _this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids.push(item.id);
                });
            };
            ClinicalChartsSectionController.prototype.toggleItem = function (item, $event, toggleCheckbox) {
                if (item.selected) {
                    while (this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids.indexOf(item.id) > -1) {
                        this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids.splice(this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids.indexOf(item.id), 1);
                    }
                }
                else {
                    if (!this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids) {
                        this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids = [];
                    }
                    this.PlanOfCareService.currentPlanOfCare.clinical_chart_ids.push(item.id);
                }
                if (toggleCheckbox) {
                    item.selected = !item.selected;
                }
                $event.stopPropagation();
            };
            ClinicalChartsSectionController.$inject = [
                'PlanOfCareService',
                'logger',
                'ClinicalChartEntry',
                '$stateParams',
                '$filter',
            ];
            return ClinicalChartsSectionController;
        }());
        angular
            .module('consolo')
            .controller('ClinicalChartsSectionController', ClinicalChartsSectionController);
    })(controllers = consolo.controllers || (consolo.controllers = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('emarDataTable', {
            bindings: {
                patients: '<',
                singlePatient: '<',
                medicationGroups: '<',
                hoursShown: '<',
                medicationCount: '<',
                updateMedications: '&',
                master: '<',
                detail: '<',
                teams: '=',
                getAssignedPatients: '&',
            },
            templateUrl: '/y/templates/medications/medication-administration-record/emar-data-table/emar-data-table.html',
            controller: 'EmarDataTable as vm',
        });
        var EmarDataTable = /** @class */ (function () {
            function EmarDataTable(MedicationService, $state, $filter, $http, logger, $mdDialog, FiltersService, EmarService, $window) {
                this.MedicationService = MedicationService;
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.FiltersService = FiltersService;
                this.EmarService = EmarService;
                this.$window = $window;
                this.listClass = true;
                this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                this.currentDay = new Date();
                this.currentHour = this.currentDay.getHours();
                this.hourContainer = document.getElementById('med-data-col');
            }
            EmarDataTable.prototype.$onInit = function () {
                this.hoursShown = this.EmarService.hourWidthMultiplier;
            };
            EmarDataTable.prototype.$postLink = function () {
                var _this = this;
                // Gives UI enough time to build before attempting to scroll to current hour by default
                setTimeout(function () {
                    _this.scrollToCurrentHour();
                }, 1000);
                this.medNameList = document.getElementsByClassName('med-hour-list');
                this.EmarService.emarLoader = false;
            };
            EmarDataTable.prototype.$onChanges = function (changes) {
                if (changes.hoursShown) {
                    this.EmarService.hourWidthMultiplier = changes.hoursShown.currentValue;
                    this.scrollToCurrentHour();
                }
            };
            EmarDataTable.prototype.$onDestroy = function () {
                this.EmarService.teamSelection = null;
                this.EmarService.patients = null;
            };
            EmarDataTable.prototype.setUpdate = function () {
                var _this = this;
                // Fixes timing issues when trying to run update meds method directly on blur
                setTimeout(function () {
                    _this.updateMedications();
                }, 0);
            };
            EmarDataTable.prototype.updateTeam = function () {
                var _this = this;
                // Fixes timing issues when trying to run update teams method directly on blur
                setTimeout(function () {
                    _this.getAssignedPatients();
                }, 0);
            };
            EmarDataTable.prototype.administerMedication = function (medication, dosageTime) {
                this.EmarService.administerMedication(medication, dosageTime);
            };
            EmarDataTable.prototype.showActionDialog = function () {
                this.$mdDialog.show({
                    contentElement: '#legend-action-dialog',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    openFrom: '#legend-action-button',
                    closeTo: '#legend-action-button',
                });
            };
            EmarDataTable.prototype.scrollMedications = function (direction) {
                if (direction === 'left') {
                    this.hourContainer.scrollLeft -= this.hourContainer.offsetWidth;
                }
                else if (direction === 'right') {
                    this.hourContainer.scrollLeft += this.hourContainer.offsetWidth;
                }
            };
            EmarDataTable.prototype.scrollToCurrentHour = function () {
                this.hourContainer.scrollLeft =
                    this.currentHour *
                        ((this.hourContainer.offsetWidth * this.EmarService.hourWidthMultiplier) / 24);
            };
            EmarDataTable.prototype.currentHourStyle = function (index) {
                if (index === this.currentHour) {
                    return 'md-primary';
                }
                else {
                    return 'admin-time';
                }
            };
            EmarDataTable.prototype.hourwidth = function () {
                var width = this.hourContainer.offsetWidth;
                return { width: width * this.EmarService.hourWidthMultiplier + 'px' };
            };
            EmarDataTable.prototype.headingCol = function () {
                if (this.hourContainer.scrollLeft !== 0) {
                    return { 'box-shadow': '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)' };
                }
            };
            EmarDataTable.prototype.medNameHeight = function (index, medicationGroupName) {
                var medNameHeight;
                if (this.medNameList.length !== 0) {
                    if (medicationGroupName === 'Routine Medications') {
                        medNameHeight = this.medNameList[index].getBoundingClientRect().height;
                    }
                    else if (medicationGroupName === 'Continuous Medications') {
                        medNameHeight = this.medNameList[index + this.medicationGroups.routine.medications.length].getBoundingClientRect().height;
                    }
                    else if (medicationGroupName === 'PRN Medications') {
                        medNameHeight = this.medNameList[index +
                            this.medicationGroups.routine.medications.length +
                            this.medicationGroups.continuous.medications.length].getBoundingClientRect().height;
                    }
                    else {
                        if (this.medNameList[index] !== undefined) {
                            medNameHeight = this.medNameList[index].getBoundingClientRect().height;
                        }
                    }
                    return { height: medNameHeight + 'px' };
                }
            };
            EmarDataTable.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            EmarDataTable.$inject = [
                'MedicationService',
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'FiltersService',
                'EmarService',
                '$window',
            ];
            return EmarDataTable;
        }());
        emarComponents.EmarDataTable = EmarDataTable;
        angular.module('consolo').controller('EmarDataTable', EmarDataTable);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('emarHour', {
            bindings: { medication: '<', currentHour: '<', medsByHour: '<', patient: '<', dateFilter: '<' },
            templateUrl: '/y/templates/medications/medication-administration-record/emar-data-table/emar-hour.html',
            controller: 'EmarHour as vm',
        });
        var EmarHour = /** @class */ (function () {
            function EmarHour(MedicationService, $state, $filter, $http, logger, $mdDialog, EmarService) {
                this.MedicationService = MedicationService;
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.EmarService = EmarService;
                this.fullDosageList = [];
            }
            EmarHour.prototype.$onInit = function () {
                if (this.medication) {
                    this.EmarService.setMedAdminTimes(this.medication);
                }
            };
            EmarHour.prototype.formatDosageTime = function (dosageTime) {
                var effective_time = new Date(dosageTime.effective_datetime);
                var scheduledTime = consolo.common.timeOnly(effective_time);
                var administeredTime = null;
                if (dosageTime.medication_administration_record) {
                    administeredTime = new Date(dosageTime.medication_administration_record.administration_datetime);
                }
                else if (dosageTime.prn_followup) {
                    administeredTime = new Date(dosageTime.prn_followup.effective_datetime);
                }
                if (administeredTime) {
                    scheduledTime += ' - (' + consolo.common.timeOnly(administeredTime) + ')';
                }
                if (this.EmarService.hourWidthMultiplier === 1) {
                    scheduledTime = this.addZero(this.minutesOnly(effective_time));
                    if (scheduledTime === '00') {
                        scheduledTime = '';
                    }
                    if (administeredTime) {
                        administeredTime = this.addZero(this.minutesOnly(administeredTime));
                        scheduledTime += ' (' + administeredTime + ')';
                    }
                }
                if ((dosageTime.medication_administration_record &&
                    dosageTime.medication_administration_record.comments) ||
                    (dosageTime.prn_followup && dosageTime.prn_followup.comments)) {
                    if (!scheduledTime) {
                        scheduledTime = '*';
                    }
                    else {
                        scheduledTime += ' *';
                    }
                }
                return scheduledTime;
            };
            EmarHour.prototype.formatDosageTimePrint = function (dosageTime) {
                var effective_time = new Date(dosageTime.effective_datetime);
                var scheduledTime = consolo.common.timeOnly(effective_time);
                scheduledTime = this.addZero(this.minutesOnly(effective_time));
                if (scheduledTime === '00') {
                    scheduledTime = null;
                }
                return scheduledTime;
            };
            EmarHour.prototype.formatAdminTime = function (dosageTime) {
                if (dosageTime.medication_administration_record) {
                    var administeredTime = new Date(dosageTime.medication_administration_record.administration_datetime);
                    return this.timeOnly(administeredTime);
                }
                else if (dosageTime.prn_followup) {
                    var administeredTime = new Date(dosageTime.prn_followup.effective_datetime);
                    return this.timeOnly(administeredTime);
                }
            };
            EmarHour.prototype.administerMedication = function (medication, dosageTime) {
                this.EmarService.administerMedication(medication, dosageTime);
            };
            EmarHour.prototype.currentHourStyle = function (index) {
                if (index === this.currentHour) {
                    return { 'background-color': '#F0F0F0' };
                }
            };
            EmarHour.prototype.minutesOnly = function (d) {
                return d.getMinutes().toString();
            };
            EmarHour.prototype.addZero = function (i) {
                if (i < 10) {
                    i = '0' + i;
                }
                return i;
            };
            EmarHour.prototype.timeOnly = function (d) {
                return (this.addZero(d.getHours()).toString() + '\n:\n' + this.addZero(d.getMinutes()).toString());
            };
            EmarHour.$inject = [
                'MedicationService',
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'EmarService',
            ];
            return EmarHour;
        }());
        emarComponents.EmarHour = EmarHour;
        angular.module('consolo').controller('EmarHour', EmarHour);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('emarLegend', {
            bindings: {},
            templateUrl: '/y/templates/medications/medication-administration-record/emar-legend/emar-legend.html',
            controller: 'EmarLegend as vm',
        });
        var EmarLegend = /** @class */ (function () {
            function EmarLegend() {
            }
            EmarLegend.$inject = [];
            return EmarLegend;
        }());
        emarComponents.EmarLegend = EmarLegend;
        angular.module('consolo').controller('EmarLegend', EmarLegend);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('emarMedlistDetail', {
            bindings: { medications: '<', medsByHour: '<', patient: '<' },
            templateUrl: '/y/templates/medications/medication-administration-record/emar-medlist-detail/emar-medlist-detail.html',
            controller: 'EmarMedlistDetail as vm',
        });
        var EmarMedlistDetail = /** @class */ (function () {
            function EmarMedlistDetail(MedicationService, $state, $filter, $http, logger, $mdDialog, EmarService) {
                this.MedicationService = MedicationService;
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.EmarService = EmarService;
                this.fullDosageList = [];
                this.dateFilter = new Date();
            }
            EmarMedlistDetail.prototype.$onInit = function () { };
            EmarMedlistDetail.prototype.formatDosageTime = function (dosageTime) {
                var effective_time = dosageTime.effective_datetime;
                var scheduledTime = consolo.common.timeOnly(effective_time);
                if (dosageTime.medication_administration_record) {
                    var administeredTime = new Date(dosageTime.medication_administration_record.administration_datetime);
                    scheduledTime += ' - (' + consolo.common.timeOnly(administeredTime) + ')';
                }
                return scheduledTime;
            };
            EmarMedlistDetail.prototype.formatAdminTime = function (dosageTime) {
                if (dosageTime.medication_administration_record) {
                    var administeredTime = new Date(dosageTime.medication_administration_record.administration_datetime);
                    return '(' + consolo.common.timeOnly(administeredTime) + ')';
                }
            };
            EmarMedlistDetail.$inject = [
                'MedicationService',
                '$state',
                '$filter',
                '$http',
                'logger',
                '$mdDialog',
                'EmarService',
            ];
            return EmarMedlistDetail;
        }());
        emarComponents.EmarMedlistDetail = EmarMedlistDetail;
        angular.module('consolo').controller('EmarMedlistDetail', EmarMedlistDetail);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));

var consolo;
(function (consolo) {
    var emarComponents;
    (function (emarComponents) {
        angular.module('consolo').component('singlePatientEmar', {
            bindings: {
                patients: '<',
                singlePatient: '<',
                medicationGroups: '<',
                hourWidthOnResize: '<',
                medicationCount: '<',
                dateFilter: '=',
                updateMedications: '&',
                master: '<',
                detail: '<',
                teams: '=',
                getAssignedPatients: '&',
            },
            templateUrl: '/y/templates/medications/medication-administration-record/single-patient-emar/single-patient-emar.html',
            controller: 'SinglePatientEmar as vm',
        });
        var SinglePatientEmar = /** @class */ (function () {
            function SinglePatientEmar(MedicationService, $state, $filter, $http, $scope, logger, $mdDialog, FiltersService, EmarService, $window) {
                this.MedicationService = MedicationService;
                this.$state = $state;
                this.$filter = $filter;
                this.$http = $http;
                this.$scope = $scope;
                this.logger = logger;
                this.$mdDialog = $mdDialog;
                this.FiltersService = FiltersService;
                this.EmarService = EmarService;
                this.$window = $window;
                this.listClass = true;
                this.hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
                this.hourContainer = document.getElementById('hour-container');
                this.EmarService.emarLoader = false;
                this.EmarService.hourContainer = document.getElementById('hour-container');
            }
            SinglePatientEmar.prototype.$onInit = function () {
                this.hourWidthOnResize = this.EmarService.hourWidthMultiplier;
            };
            SinglePatientEmar.prototype.$postLink = function () {
                var _this = this;
                // Gives UI enough time to build before attempting to scroll to current hour by default
                setTimeout(function () {
                    _this.EmarService.scrollToCurrentHour();
                }, 1000);
                this.medNameList = document.getElementsByClassName('med-hour-list');
                this.medHourContainer = document.getElementsByClassName('medication-admin-container');
            };
            SinglePatientEmar.prototype.$onChanges = function (changes) {
                if (changes.hourWidthOnResize) {
                    this.EmarService.hourWidthMultiplier = changes.hourWidthOnResize.currentValue;
                    this.EmarService.scrollToCurrentHour();
                }
            };
            SinglePatientEmar.prototype.$onDestroy = function () {
                this.EmarService.teamSelection = null;
                this.EmarService.patients = null;
                this.EmarService.hourContainer = null;
            };
            SinglePatientEmar.prototype.setUpdate = function () {
                var _this = this;
                // Fixes timing issues when trying to run update meds method directly on blur
                setTimeout(function () {
                    _this.updateMedications();
                }, 0);
            };
            SinglePatientEmar.prototype.updateTeam = function () {
                var _this = this;
                // Fixes timing issues when trying to run update teams method directly on blur
                setTimeout(function () {
                    _this.getAssignedPatients();
                }, 0);
            };
            SinglePatientEmar.prototype.administerMedication = function (medication, dosageTime) {
                this.EmarService.administerMedication(medication, dosageTime);
            };
            SinglePatientEmar.prototype.scrollPosition = function () {
                if (this.EmarService.hourWidthMultiplier !== 1) {
                    return { transform: 'translateX(-' + this.EmarService.currentScrollPosition + 'px)' };
                }
            };
            SinglePatientEmar.prototype.currentHourStyle = function (index) {
                if (index === this.EmarService.currentHour) {
                    return 'md-primary';
                }
                else {
                    return 'admin-time';
                }
            };
            SinglePatientEmar.prototype.hourwidth = function () {
                var width = this.hourContainer.offsetWidth;
                return { width: width * this.EmarService.hourWidthMultiplier + 'px' };
            };
            SinglePatientEmar.prototype.showMenu = function ($event, $mdOpenMenu) {
                $event.stopPropagation();
                $mdOpenMenu($event);
            };
            SinglePatientEmar.prototype.printPatientMAR = function () {
                this.$window.print();
            };
            SinglePatientEmar.$inject = [
                'MedicationService',
                '$state',
                '$filter',
                '$http',
                '$scope',
                'logger',
                '$mdDialog',
                'FiltersService',
                'EmarService',
                '$window',
            ];
            return SinglePatientEmar;
        }());
        emarComponents.SinglePatientEmar = SinglePatientEmar;
        angular.module('consolo').controller('SinglePatientEmar', SinglePatientEmar);
    })(emarComponents = consolo.emarComponents || (consolo.emarComponents = {}));
})(consolo || (consolo = {}));
